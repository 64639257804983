/**
 * Author: Md Jahir Uddin
 * Date: 13-01-2023
 * Description: Add or Edit Rating System
 * @param {{changePopUpHandler: ()=>{}, setSubmitClick:()=>{}, authUser: number, openPopup: string, masterItemId: number, masterId: number, saveRatingSystemData: ()=>{}}} props 
 */
import React, { useEffect, useState } from "react";
import { FormValidator, TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import okrServices from "../../services/okr/okrservices";
import { saveRatingSystemData, updateOKRData} from "../../redux/actions/okr/okractions";
import { connect } from "react-redux";
import { characterCountLimit, pleaseEnterRatingSystemName, regexOnlyLettersAndSpaces } from "../../assets/constants/constants";
import ProgressBar from "../progressbar/progressbar";
const RatingSystemMaster = (props) => {
  const {
    changePopUpHandler,
    setSubmitClick,
    authUser,
    openPopup,
    masterItemId = 0,
    masterId,
    saveRatingSystemData, //NOSONAR
    loading,
    updateOKRData } = props //NOSONAR
  const [state, setState] = useState({id:null, name:""})

  useEffect(() => {
    if (props.okrMasterFlag === "updated") {
      changePopUpHandler("close");
    }
  }, [props.okrMasterFlag]);

  const getData = () => {
    updateOKRData({loading: true});
    if (openPopup === "add") {
      setState({id:null, name:""});
      document.getElementById("ratingSystemErr").innerHTML = "";
      updateOKRData({loading: false});
    } else if (openPopup === "edit") {
      document.getElementById("ratingSystemErr").innerHTML = "";
      (async () => {
        const ratingSystemData = await okrServices.getOKRMaserData(
          authUser,
          masterId,
          masterItemId
        );
        const {
          masterItemId: id,
          name,
        } = ratingSystemData?.Result[0];
        setState({id, name });
        updateOKRData({loading: false});
      })();
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setSubmitClick(onSaveData);
    }, 100);
  }, [state, masterId]);

  useEffect(() => {
    getData();
  }, [masterItemId]);

  const onSaveData = async () => {
    let options = {
      rules:{
        ratingSystemErr: {
          required: [true, pleaseEnterRatingSystemName],
          maxLength: [50, characterCountLimit],
          regex: [regexOnlyLettersAndSpaces, "Please enter appropriate rating scale name (Alphabets only)"],
        }
      }
    }
    setTimeout(() => {
      const addFormObject = new FormValidator(
        "#ratingSystemForm",
        options
      );
      if (addFormObject.validate()) {
        let data = {
          userId: authUser,
          ratingSystemId: state.id ? state.id  : 0,
          name: state.name,
          type: state.id ? 'EDIT' : 'ADD'
        };
      updateOKRData({loading: true});
        saveRatingSystemData(data);
      }
    }, 300);
  };

const handleChange = (e)=>{
  let options = {
    rules: {
      ratingSystemErr: {
        required: [true, pleaseEnterRatingSystemName],
        maxLength: [50, characterCountLimit],
        regex: [regexOnlyLettersAndSpaces, "Please enter appropriate rating scale name (Alphabets only)"],
      },
    },
  };

  setState(prevState =>({id: prevState.id, name: e.value}))

  setTimeout(() => {
    const addFormObject = new FormValidator("#ratingSystemForm", options);
    addFormObject.validate();
  }, 100);
}

  return (
    <div>
      <div style={{ padding: "16px" }} id="ratingSystemForm">
        <div className="col-xs-12 col-sm-12 col-lg-12 pt-sm mb-sm atc-no-paddding">
          <TextBoxComponent
            data-msg-containerid="ratingSystemErr"
            id="ratingSystemName"
            value={state.name}
            name="ratingSystemErr"
            placeholder="Rating Scale *"
            floatLabelType="Auto"
            cssClass="e-outline"
            htmlAttributes={{ maxlength: 50 }}
            onChange={handleChange}
          />
          <div id="ratingSystemErr" className="error-div" />
        </div>
      </div>
    {loading && <ProgressBar/>}
    </div>
  );
};


const mapStateToProps = ({ auth, okrreducer }) => {
  const { authUser } = auth;
  const { okrMasterFlag, loading } = okrreducer;

  return {
    authUser,
    okrreducer,
    okrMasterFlag,
    loading
  };
};

export default connect(mapStateToProps, {
  saveRatingSystemData,
  updateOKRData
})(RatingSystemMaster);

