/**
 * Author By :- Prashant Waphare
 * Date : 20-04-2020
 * Description :- This file use for load onboarding Tab
 */
import React, { Component } from "react";
import NotificationContainer from "../../../../components/notification/notifycontainer";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Progressbar from '../../../../components/progressbar/progressbar';
import CardViewToBeOfferCandidate from "../../../../components/tobeoffercondicate/tobeoffer";
import CardViewOfferCandidate from "../../../../components/onboarding/offerCandidate/offerCandidate";
import PreJoining from "../../../../components/prejoining/prejoining";
import RevokedCandidate from "../../../../components/onboarding/revokedCandidate/revokedCandidate";
import "./onboarding.css";
import OnbordingToBeOffer from "../../../../services/entryexit/onbordingServices";
import { updateStatusOnBoardingResucer, syncUser } from "../../../../redux/actions/entryexit/onboardingaction";
import { updateCandidateData } from "../../../../redux/actions/entryexit/candidateaction";
import getEnployeeBasicInformation from "../../../../services/services";
import { appId } from '../../../../config/config';
import { syncUserLocal } from '../../../other/syncuser'

class OnBoarding extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toBeData: null,
      offerData: null,
      readyToJoinData: null,
      revokedDatalist: null,
      allTabCount: null,
      changedData: false,
    };
  }

  componentDidMount() {
    const categary = localStorage.getItem('SelectedTabAction');
    if (categary === 'To Be Offered' || categary === 'Offer Candidate' || categary === 'Ready To Join' || categary === 'Revoke Candidate') {
      localStorage.setItem('SelectedTabAction', categary);
    } else {
      localStorage.removeItem('SelectedTabAction');
    }
    this.refreshtabs()
    this.getMasterData();
  }
  // eslint-disable-next-line
  async UNSAFE_componentWillReceiveProps(newProps) {
    const { changedData } = this.state;
    const { authUser } = this.props;
    if (newProps.status === "updated") {
      let emp_id = newProps.empid;
      if (newProps.empid > 0) {
        let data = await syncUserLocal(emp_id, authUser, 0);
        await this.props.syncUser(data);
      }
      this.refreshtabs()
      this.setState({ changedData: !changedData });
      // eslint-disable-next-line
      this.props.updateStatusOnBoardingResucer({ status: "", empid: 0 });
    }
  }
  getMasterData = () => {
    const { authUser } = this.props;
    const getMasterData = Promise.resolve(
      OnbordingToBeOffer.getMasterData("All", authUser)
    );
    getMasterData.then((resultData) => {
      if (resultData !== null) {
        const sourceCategoryData = resultData.filter((item) => {
          return item.Type === "Source Category";
        });
        const jobPortalData = resultData.filter((item) => {
          return item.Type === "Job Portal";
        });
        const jobTitleData = resultData.filter((item) => {
          return item.Type === "Job Title";
        });
        this.props.updateCandidateData({
          sourceCategoryData: sourceCategoryData,
          jobPortalData: jobPortalData,
          jobTitleData: jobTitleData
        })
      }
    });
  }
  // Sync User for Common access rights
  syncUserLocal = async (emp_id) => {
    const { authUser } = this.props;
    const emp_info = await getEnployeeBasicInformation.getEnployeeBasicInformation(emp_id, authUser);
    if (typeof emp_info !== "undefined" && emp_info.length > 0) {
      var joiningDate = new Date(emp_info[0].JoiningDate);
      joiningDate = joiningDate.getFullYear() + '-' + ('0' + (joiningDate.getMonth() + 1).toString()).slice(-2) + '-' + ('0' + joiningDate.getDate().toString()).slice(-2);
      let data = {};
      data.app_id = appId;
      data.authUser = authUser;
      data.user_first_name = emp_info[0].FirstName;
      data.user_last_name = emp_info[0].LastName;
      data.user_email = emp_info[0].EmailAddress;
      data.emp_code = emp_info[0].EmployeeCode;
      data.emp_id = emp_info[0].EmployeeId;
      data.user_job_title = emp_info[0].Role;
      data.user_pnl = emp_info[0].Pnl;
      data.user_role = [{ "app_id": appId, "role_id": "60004c44966b4018063d9cb2", "role_name": "All User" }];
      data.user_id = authUser;
      data.designation_id = emp_info[0].fk_role_id;
      data.job_title = emp_info[0].job_title;
      data.fk_division_function_id = emp_info[0].fk_division_function_id;
      data.service_division = emp_info[0].var_service_division_name;
      data.gradeName = emp_info[0].gradeName;
      data.fk_reporting_to = emp_info[0].fk_reporting_to;
      data.JoiningDate = joiningDate;
    }
  }
  // this finction use for load tab data on click
  openTab = (evt, tabName) => {
    this.getAllTabCount();
    localStorage.setItem('SelectedTabAction', tabName);
    const data = evt;
    if (tabName === "To Be Offered") {
      this.getToBeTabData();
    }
    if (tabName === "Offer Candidate") {
      this.getOfferTabData();
    }
    if (tabName === "Ready To Join") {
      this.getReadyToJoinTabData();
    }
    if (tabName === "Revoke Candidate") {
      this.getRevokeTabData();
    }

    let i;
    const tabcontent = document.getElementsByClassName("tabcontent");
    // eslint-disable-next-line
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }
    const onboardingtab = document.getElementsByClassName("onboardingtab");
    // eslint-disable-next-line
    for (i = 0; i < onboardingtab.length; i++) {
      onboardingtab[i].className = onboardingtab[i].className.replace("active", "");
    }
    document.getElementById(tabName).style.display = "block";
    data.currentTarget.className += "active";
  };

  refreshtabs() {
    this.getAllTabCount();
    const getTabActive = localStorage.getItem('SelectedTabAction');
    if (getTabActive !== null && getTabActive === 'Offer Candidate') {
      this.getOfferTabData();
      document.getElementById(`${getTabActive}`).style.display = "block";
    } else if (getTabActive !== null && getTabActive === 'Ready To Join') {
      this.getReadyToJoinTabData();
      document.getElementById(`${getTabActive}`).style.display = "block";
    } else if (getTabActive !== null && getTabActive === 'Revoke Candidate') {
      this.getRevokeTabData();
      document.getElementById(`${getTabActive}`).style.display = "block";
    } else if (getTabActive === null || getTabActive === 'To Be Offered') {
      localStorage.setItem('SelectedTabAction', 'To Be Offered');
      this.getToBeTabData();
      document.getElementById("To Be Offered").style.display = "block";
    }
  }

  getAllTabCount = () => {
    const { authUser } = this.props;
    try {
      const getAllTabCount = Promise.resolve(
        OnbordingToBeOffer.getAllTabCandidateDetails(-1, authUser)
      );
      getAllTabCount.then((result) => {        
        if (result !== null) {
          this.setState({ allTabCount: result });
        } else {
          this.data = "error";
        }
      });
    } catch (e) {
      // eslint-disable-next-line
      console.log(e);
    }
  };

  getToBeTabData = () => {
    const { authUser } = this.props;
    try {
      const getToBeOfferCandidateDetails = Promise.resolve(
        OnbordingToBeOffer.getAllTabCandidateDetails(1, authUser)
      );
      getToBeOfferCandidateDetails.then((result) => {
        if (result !== null) {
          this.setState({ toBeData: result });
        } else {
          this.data = "error";
        }
      });
    } catch (e) {
      // eslint-disable-next-line
      console.log(e);
    }
  };

  getOfferTabData = () => {
    const { authUser } = this.props;
    try {
      const getToBeOfferCandidateDetails = Promise.resolve(
        OnbordingToBeOffer.getAllTabCandidateDetails(2, authUser)
      );
      getToBeOfferCandidateDetails.then((result) => {
        if (result !== null) {
          this.setState({ offerData: result });
        } else {
          this.data = "error";
        }
      });
    } catch (e) {
      // eslint-disable-next-line
      console.log(e);
    }
  };

  getReadyToJoinTabData = () => {
    const { authUser } = this.props;
    try {
      const getReadyToJoinCandidateList = Promise.resolve(
        OnbordingToBeOffer.getAllTabCandidateDetails(3, authUser)
      );
      getReadyToJoinCandidateList.then((result) => {
        if (result !== null) {
          this.setState({ readyToJoinData: result });
        } else {
          this.data = "error";
        }
      });
    } catch (e) {
      // eslint-disable-next-line
      console.log(e);
    }
  };

  getRevokeTabData = () => {
    const { authUser } = this.props;
    try {
      const getRevokeCandidateList = Promise.resolve(
        OnbordingToBeOffer.getAllTabCandidateDetails(4, authUser)
      );      
      getRevokeCandidateList.then((result) => {
        if (result !== null) {
          this.setState({ revokedDatalist: result });
        } else {
          this.data = "error";
        }
      });
    } catch (e) {
      // eslint-disable-next-line
      console.log(e);
    }
  };

  render() {
    const {
      allTabCount,
      toBeData,
      offerData,
      readyToJoinData,
      revokedDatalist,
    } = this.state;
    return (
      <div
        className="control-pane control-section card-control-section row"
        style={{
          paddingBottom: "10px",
          paddingTop: "10px",
          paddingLeft: "25px",
          paddingRight: "25px",
          background: "#eee",
        }}
      >
        <NotificationContainer />
        <div className="tab row-xs-12 row-sm-12 row-lg-12 row-md-12">
          {allTabCount !== null && (
            <>
              <button
                type="button"
                className={`col-lg-3 onboardingtab onboarding-tab ${(localStorage.getItem('SelectedTabAction') === "To Be Offered" || localStorage.getItem('SelectedTabAction') === null) ? 'active' : ''}`}
                onClick={(e) => this.openTab(e, "To Be Offered")}
              >
                <b>TO BE OFFERED</b>{" "}
                <span className="active-tab-count-dot">
                  {allTabCount[1]?.Count}
                </span>
              </button>
              <button
                type="button"
                className={`col-lg-3 onboardingtab onboarding-tab ${localStorage.getItem('SelectedTabAction') === "Offer Candidate" ? 'active' : ''}`}
                onClick={(e) => this.openTab(e, "Offer Candidate")}
              >
                <b>OFFERED </b>
                <span className="active-tab-count-dot">
                  {allTabCount[2]?.Count}
                </span>
              </button>
              <button
                type="button"
                className={`col-lg-3 onboardingtab onboarding-tab ${localStorage.getItem('SelectedTabAction') === "Ready To Join" ? 'active' : ''}`}
                onClick={(e) => this.openTab(e, "Ready To Join")}
              >
                <b>READY TO JOIN </b>
                <span className="active-tab-count-dot">
                  {allTabCount[3]?.Count}
                </span>
              </button>
              <button
                type="button"
                className={`col-lg-3 onboardingtab onboarding-tab ${localStorage.getItem('SelectedTabAction') === "Revoke Candidate" ? 'active' : ''}`}
                onClick={(e) => this.openTab(e, "Revoke Candidate")}
              >
                <b>OFFER REVOKED </b>
                <span className="active-tab-count-dot">
                  {allTabCount[4]?.Count}
                </span>
              </button>
            </>
          )}
        </div>

        <div id="To Be Offered" className="tabcontent">
          {toBeData !== null ? (
            <CardViewToBeOfferCandidate
              getToBeTabData={this.getToBeTabData} getRevokeTabData={this.getRevokeTabData}
              toBeData={toBeData}
            />
          )
            :
            <div className='progress-bar-div'>
              <Progressbar />
            </div>
          }
        </div>
        <div id="Offer Candidate" className="tabcontent">
          {offerData !== null ? (
            <CardViewOfferCandidate
              getOfferTabData={this.getOfferTabData}
              offerData={offerData}
            />
          )
            :
            <div className='progress-bar-div'>
              <Progressbar />
            </div>
          }
        </div>
        <div id="Ready To Join" className="tabcontent">
          {readyToJoinData !== null ? (
            <PreJoining
              getReadyToJoinTabData={this.getReadyToJoinTabData}
              readyToJoinData={readyToJoinData}
            />
          )
            :
            <div className='progress-bar-div'>
              <Progressbar />
            </div>
          }
        </div>
        <div id="Revoke Candidate" className="tabcontent">
          {revokedDatalist !== null ? (
            <RevokedCandidate
              getRevokeTabData={this.getRevokeTabData}
              revokedDatalist={revokedDatalist}
            />
          )
            :
            <div className='progress-bar-div'>
              <Progressbar />
            </div>
          }
        </div>
      </div>
    );
  }
}
OnBoarding.propTypes = {
  // eslint-disable-next-line
  status: PropTypes.string,
  authUser: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  empid: PropTypes.number,
};
OnBoarding.defaultProps = {
  authUser: "",
  status: "",
  empid: 0,
};

const mapStateToProps = ({ auth, onboardingreducer }) => {
  const { status, empid } = onboardingreducer;
  const { authUser } = auth;
  return { authUser, status, empid };
};
export default connect(mapStateToProps, { updateStatusOnBoardingResucer, syncUser, updateCandidateData })(
  OnBoarding
);
