import React, { Component } from 'react'
import HolidaylistContainer from '../../containers/holidaylist/holidaylistcontainer';
export default class HolidayListLayout extends Component {
  render() {
    return (
      <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12" style={{ backgroundColor: "#F3F3F6", height: "100vh" }}>
          <HolidaylistContainer history={this.props.history} />
      </div>
    )
  }
}
