import { ABSCONDING, ABSCONDINGHR, ABSCONDINGMANAGER, ACTUAL_MEETING, CALENDAR_REPORT, CONFIRMATION, DASHBOARD, DOWNLOAD_USER_ACTIVITY_DATA, EMPLOYEE_LIST, EMPLOYEE_PROFILE, EXITCLEARANCEGRID, EXIT_CLEARANCE_FUNCTION, EXIT_CLEARANCE_MANAGER, HOME_OFFICE, HRAPPROVAL, MEETING_SET_BY_ME, MY_MEETING, OKR_COST_SHARING, OKR_OBJECTIVE_KPI, OKR_PRODUCTIVE_ALLOCATION, OKR_RATING_AND_REVIEW, OKR_REVIEW, OKR_SELF_REVIEW, OKR_SIDA, OKR_SUPERVISOR_REVIEW, OKR_THIRD_PART_REVIEW, ON_BOARDING, ON_BOARDING_FUNCTION, ORG_CHART, ORG_CHART_MASTERS, ORG_CHART_NEW, OTHERS_CALENDAR, OTHERS_CHECK_AVAILABILITY, PLAN_MEETING, PLAN_VS_ACTUAL_MEETING, PRE_OFFERE, RESIGNATION, RESIGNATIONMANAGERAPPROVAL, RESIGNATIONPNLAPPROVAL, TERMINATION, TERMINATIONHR, TERMINATIONMANAGER, TIB_AVERAGE_REPORT, TIME_IN_BUSINESS, UPLOAD_DAILY_DATA, UTILIZATION_OF_TIME_REPORT } from "./meetingcategoryconstant";

//Auth const
export const SIGNIN_USER = 'signin_user';
export const SIGNIN_USER_SUCCESS = 'signin_user_success';
export const SIGNOUT_USER = 'signout_user';
export const SIGNOUT_USER_SUCCESS = 'signout_user_success';
export const INIT_URL = 'init_url';
export const CHANGE_AUTH_USER = 'Change_auth_user';
export const CHECK_USER_AVAILABILITY = 'check_user_availability';
export const UPDATE_AUTH_USER_INFO = 'update_auth_user_info';



// Chat Module const

export const ON_SHOW_LOADER = 'on_show_loader';
export const ON_HIDE_LOADER = 'on_hide_loader';

//Contact Module const

export const SHOW_MESSAGE = 'show_message';
export const HIDE_MESSAGE = 'hide_message';

//// Mail Module const
export const MEETING_TYPE = "meeting_type";
export const MEETING_VIEW_TYPE = "meeting_view_type";

export const sourceToCategoryMapping = {
    // Calendar
    [PLAN_VS_ACTUAL_MEETING]: {function: 3, category:8},
    [MEETING_SET_BY_ME]: {function: 3, category:8},
    [PLAN_MEETING]: {function: 3, category:8},
    [ACTUAL_MEETING]: {function: 3, category:8},
    [OTHERS_CALENDAR]: {function: 3, category:8},
    [OTHERS_CHECK_AVAILABILITY]: {function: 3, category:8},
    [MY_MEETING]: {function: 3, category:8},
    // Others
    [TIME_IN_BUSINESS]: {function: 3, category:16},
    [UPLOAD_DAILY_DATA]: {function: 3, category:16},
    [TIB_AVERAGE_REPORT]: {function: 3, category:16},
    [CALENDAR_REPORT]: {function: 3, category:16},
    [DOWNLOAD_USER_ACTIVITY_DATA]: {function: 3, category:16},
    [UTILIZATION_OF_TIME_REPORT]: {function: 3, category:16},
    [HOME_OFFICE]: {function: 3, category:16},
    // TMLC
    [ON_BOARDING]: {function: 3, category:11},
    [CONFIRMATION]: {function: 3, category:11},
    [EMPLOYEE_PROFILE]: {function: 3, category:11},
    [PRE_OFFERE]: {function: 3, category:11},
    [ON_BOARDING_FUNCTION]: {function: 3, category:11},
    [RESIGNATIONMANAGERAPPROVAL]: {function: 3, category:11},
    [RESIGNATIONPNLAPPROVAL]: {function: 3, category:11},
    [RESIGNATION]: {function: 3, category:11},
    [HRAPPROVAL]: {function: 3, category:11},
    [ABSCONDING]: {function: 3, category:11},
    [TERMINATION]: {function: 3, category:11},
    [EXITCLEARANCEGRID]: {function: 3, category:11},
    [ABSCONDINGMANAGER]: {function: 3, category:11},
    [ABSCONDINGHR]: {function: 3, category:11},
    [TERMINATIONMANAGER]: {function: 3, category:11},
    [TERMINATIONHR]: {function: 3, category:11},
    [EXIT_CLEARANCE_MANAGER]: {function: 3, category:11},
    [EXIT_CLEARANCE_FUNCTION]: {function: 3, category:11},
    [EMPLOYEE_LIST]: {function: 3, category:11},
    [ORG_CHART]: {function: 3, category:11},
    [ORG_CHART_MASTERS]: {function: 3, category:11},
    [ORG_CHART_NEW]: {function: 3, category:11},
    // OKR
    [OKR_OBJECTIVE_KPI]: {function: 3, category:14},
    [OKR_PRODUCTIVE_ALLOCATION]: {function: 3, category:14},
    [OKR_COST_SHARING]: {function: 3, category:14},
    [OKR_SELF_REVIEW]: {function: 3, category:14},
    [OKR_SUPERVISOR_REVIEW]: {function: 3, category:14},
    [OKR_THIRD_PART_REVIEW]: {function: 3, category:14},
    [OKR_REVIEW]: {function: 3, category:14},
    [OKR_RATING_AND_REVIEW]: {function: 3, category:14},
    [DASHBOARD]: {function: 3, category:14},
    // SIDA
    [OKR_SIDA]: {function: 3, category:14},
}