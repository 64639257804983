import React, { useState, useEffect } from 'react';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import okrServices from "../../../services/okr/okrservices";

function OKRLockRatingMonth(props) {
    const { setLockMonthsHandler, financialYearId, defaultMonthState } = props;
    const defaultRatingStatusState = {
        "1": null,
        "2": null,
        "3": null,
        "4": null,
        "5": null,
        "6": null,
        "7": null,
        "8": null,
        "9": null,
        "10": null,
        "11": null,
        "12": null,
    }
    const [okrLockMonthsData, setOkrLockMonthsData] = useState([]);
    const [okrDisableMonthsData, setOkrDisableMonthsData] = useState([]);
    const [ratingStatus, setRatingStatus] = useState(defaultRatingStatusState);

    useEffect(() => {
        setOkrLockMonthsData([]);
        setLockMonthsHandler("defaultState", defaultMonthState, null);
        setOkrDisableMonthsData([]);
        setRatingStatus(defaultRatingStatusState);

        const lockMonthsData = Promise.resolve(okrServices.getOKRLockTMRatingMonth(props.empId, financialYearId, "LockMonths"));
        const openedOrClosedData = Promise.resolve(okrServices.getOKRLockTMRatingMonth(props.empId, financialYearId, "OpenOrClosed"));

        lockMonthsData.then((result) => {
            if (result && result[0].okrLockMonths) {
                const lockMonths = result[0].okrLockMonths.split(",");
                setOkrLockMonthsData(lockMonths);

                lockMonths.forEach(month => {
                    if (month) {
                        setLockMonthsHandler("updateState", month, null)
                    }
                })
            }
        })

        openedOrClosedData.then(result => {
            if (result.length > 0) {
                const disableMonths = [];
                result.forEach(month => {
                    if (month.RatingMonth) {
                        const ratingMonth = month.RatingMonth;
                        setRatingStatus(prevState => ({ ...prevState, [ratingMonth]: month.RatingStatus }))
                        disableMonths.push(month.RatingMonth);
                    }
                })

                setOkrDisableMonthsData(disableMonths);
            }
        })

    }, [financialYearId]);

    const getValueOfCheckBox = (e, type) => {
        if (type) {
            if (e.target) {
                setLockMonthsHandler("getCheckBoxes", type, e);
                const lockMonths = okrLockMonthsData;
                if (e.target.checked) {
                    lockMonths.push(type)
                    setOkrLockMonthsData(lockMonths);
                } else {
                    setOkrLockMonthsData(lockMonths.filter(element => element !== type));
                }
            }
        }
    }

    return (
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 pb-md">
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 mr-3">
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mr-3 pt-sm">
                    <CheckBoxComponent
                        checked={okrLockMonthsData.includes("4") ? true : false}
                        id={"LockApr"}
                        value={"4"}
                        label={okrDisableMonthsData.includes("4") ? `Apr-${props.selectedFyYears.year} (${ratingStatus["4"]})` : `Apr-${props.selectedFyYears.year}`}
                        disabled={okrDisableMonthsData.includes("4") ? true : false}
                        onChange={(e) => getValueOfCheckBox(e, "4")}
                    />
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mr-3 pt-sm">
                    <CheckBoxComponent
                        checked={okrLockMonthsData.includes("5") ? true : false}
                        id={"LockMay"}
                        value={"5"}
                        label={okrDisableMonthsData.includes("5") ? `May-${props.selectedFyYears.year} (${ratingStatus["5"]})` : `May-${props.selectedFyYears.year}`}
                        disabled={okrDisableMonthsData.includes("5") ? true : false}
                        onChange={(e) => getValueOfCheckBox(e, "5")}
                    />
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mr-3 pt-sm">
                    <CheckBoxComponent
                        checked={okrLockMonthsData.includes("6") ? true : false}
                        id={"LockJun"}
                        value={"6"}
                        label={okrDisableMonthsData.includes("6") ? `Jun-${props.selectedFyYears.year} (${ratingStatus["6"]})` : `Jun-${props.selectedFyYears.year}`}
                        disabled={okrDisableMonthsData.includes("6") ? true : false}
                        onChange={(e) => getValueOfCheckBox(e, "6")}
                    />
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mr-3 pt-sm">
                    <CheckBoxComponent
                        checked={okrLockMonthsData.includes("7") ? true : false}
                        id={"LockJuly"}
                        value={"7"}
                        label={okrDisableMonthsData.includes("7") ? `Jul-${props.selectedFyYears.year} (${ratingStatus["7"]})` : `Jul-${props.selectedFyYears.year}`}
                        disabled={okrDisableMonthsData.includes("7") ? true : false}
                        onChange={(e) => getValueOfCheckBox(e, "7")}
                    />
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mr-3 pt-sm">
                    <CheckBoxComponent
                        checked={okrLockMonthsData.includes("8") ? true : false}
                        id={"LockAug"}
                        value={"8"}
                        label={okrDisableMonthsData.includes("8") ? `Aug-${props.selectedFyYears.year} (${ratingStatus["8"]})` : `Aug-${props.selectedFyYears.year}`}
                        disabled={okrDisableMonthsData.includes("8") ? true : false}
                        onChange={(e) => getValueOfCheckBox(e, "8")}
                    />
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mr-3 pt-sm">
                    <CheckBoxComponent
                        checked={okrLockMonthsData.includes("9") ? true : false}
                        id={"LockSep"}
                        value={"9"}
                        label={okrDisableMonthsData.includes("9") ? `Sep-${props.selectedFyYears.year} (${ratingStatus["9"]})` : `Sep-${props.selectedFyYears.year}`}
                        disabled={okrDisableMonthsData.includes("9") ? true : false}
                        onChange={(e) => getValueOfCheckBox(e, "9")}
                    />
                </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-6 mr-3" style={{ paddingLeft: 0 }}>
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mr-3 pt-sm">
                    <CheckBoxComponent
                        checked={okrLockMonthsData.includes("10") ? true : false}
                        id={"LockOct"}
                        value={"10"}
                        label={okrDisableMonthsData.includes("10") ? `Oct-${props.selectedFyYears.year} (${ratingStatus["10"]})` : `Oct-${props.selectedFyYears.year}`}
                        disabled={okrDisableMonthsData.includes("10") ? true : false}
                        onChange={(e) => getValueOfCheckBox(e, "10")}
                    />
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mr-3 pt-sm">
                    <CheckBoxComponent
                        checked={okrLockMonthsData.includes("11") ? true : false}
                        id={"LockNov"}
                        value={"11"}
                        label={okrDisableMonthsData.includes("11") ? `Nov-${props.selectedFyYears.year} (${ratingStatus["11"]})` : `Nov-${props.selectedFyYears.year}`}
                        disabled={okrDisableMonthsData.includes("11") ? true : false}
                        onChange={(e) => getValueOfCheckBox(e, "11")}
                    />
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mr-3 pt-sm">
                    <CheckBoxComponent
                        checked={okrLockMonthsData.includes("12") ? true : false}
                        id={"LockDec"}
                        value={"12"}
                        label={okrDisableMonthsData.includes("12") ? `Dec-${props.selectedFyYears.year} (${ratingStatus["12"]})` : `Dec-${props.selectedFyYears.year}`}
                        disabled={okrDisableMonthsData.includes("12") ? true : false}
                        onChange={(e) => getValueOfCheckBox(e, "12")}
                    />
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mr-3 pt-sm">
                    <CheckBoxComponent
                        checked={okrLockMonthsData.includes("1") ? true : false}
                        id={"LockJan"}
                        value={"1"}
                        label={okrDisableMonthsData.includes("1") ? `Jan-${props.selectedFyYears.nextYear} (${ratingStatus["1"]})` : `Jan-${props.selectedFyYears.nextYear}`}
                        disabled={okrDisableMonthsData.includes("1") ? true : false}
                        onChange={(e) => getValueOfCheckBox(e, "1")}
                    />
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mr-3 pt-sm">
                    <CheckBoxComponent
                        checked={okrLockMonthsData.includes("2") ? true : false}
                        id={"LockFeb"}
                        value={"2"}
                        label={okrDisableMonthsData.includes("2") ? `Feb-${props.selectedFyYears.nextYear} (${ratingStatus["2"]})` : `Feb-${props.selectedFyYears.nextYear}`}
                        // label={"February (Rating Submitted)"}
                        disabled={okrDisableMonthsData.includes("2") ? true : false}
                        onChange={(e) => getValueOfCheckBox(e, "2")}
                    />
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mr-3 pt-sm">
                    <CheckBoxComponent
                        checked={okrLockMonthsData.includes("3") ? true : false}
                        id={"LockMar"}
                        value={"3"}
                        label={okrDisableMonthsData.includes("3") ? `Mar-${props.selectedFyYears.nextYear} (${ratingStatus["3"]})` : `Mar-${props.selectedFyYears.nextYear}`}
                        disabled={okrDisableMonthsData.includes("3") ? true : false}
                        onChange={(e) => getValueOfCheckBox(e, "3")}
                    />
                </div>
            </div>
        </div>
    )
}

export default OKRLockRatingMonth;