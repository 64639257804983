import React, { useEffect, useState } from 'react';
import { TextBoxComponent, ColorPickerComponent } from "@syncfusion/ej2-react-inputs";
import { Picker } from "@material-ui/pickers";
import LnAServices from '../../services/lna/lnaservice';
import { FormValidator } from "@syncfusion/ej2-inputs";
import { connect } from 'react-redux'
import ProgressBar from "../progressbar/progressbar";
import { addEditColourClusterData, updateLnAData } from '../../redux/actions/lna/lnaaction'
import { pleasechoosecolor, pleaseenterlabel, regexOnlyLettersAndSpaces } from '../../assets/constants/constants';
const SAMPLECSS = `
#color-picker{
height:28px;
}
`
const ColourCluster = (props) => {//NOSONAR
    const { authUser, openPopup, editClusterId } = props;
    const [state, setState] = useState({
        colorName: null,
        colorHexCode: null,
        isActive: '1',
        colorUid: null
    })
    const [initialLoad, setInitialLoad] = useState(false)
    const validOptions = {
        rules: {
            colorNameErr: {
             required: [true, pleaseenterlabel],
              regex:[regexOnlyLettersAndSpaces,pleaseenterlabel],
              minLength: [1, pleaseenterlabel],
              maxLength: [50, "Should be maximum of 50 characters only."]
            },
            // colorCodeErr: {
            //     required: [true, 'Please choose color.'],
            //    },
        },
    };

    useEffect(() => {
        props.updateLnAData({ popUpSource: 'Color Master' })
        getData()
    }, [])

    useEffect(() => {
        getData()
    }, [editClusterId, openPopup])

    useEffect(() => {
        if (props.lnaMasterFlag === "updated")
            props.changePopUpHandler("close");
    }, [props.lnaMasterFlag]);

    useEffect(() => {
        setTimeout(() => {
            props.setSubmitClick(saveColorData);
        }, 100);
    }, [state]);

    const getData = () => {
        if (openPopup === 'add') {
            setState({
                colorName: null,
                colorHexCode: null,
                isActive: '1',
                colorUid: null

            })
        }
        else if (openPopup === 'edit') {
            editClusterId !== null &&
                (async () => {
                    setInitialLoad(true)
                    const colorCluster = await LnAServices.getUniqueColourClusterData(authUser, editClusterId)
                    setInitialLoad(false)
                    setState({
                        isActive: colorCluster.Result[0].isActive,
                        colorHexCode: colorCluster.Result[0].colorHexCode,
                        colorName: colorCluster.Result[0].colorName,
                        colorUid: colorCluster.Result[0].colorUid

                    })
                    setTimeout(() => {
                        const addFormObject = new FormValidator("#colourClusterForm", validOptions);
                        addFormObject.validate();
                        document.getElementById("colorCodeErr").innerText = ""
                      }, 100)
                      
                })()
        }

    }
    const onChangeWorkingDayClusterData = (e, unit) => {
        let options = {};
        switch (unit) {
            case "colour":
                setState((prevState) => ({ ...prevState, colorName:e.value.trim() }));
                options = validOptions.rules['colorNameErr'];
                break;
            case "hexCode":
                setState({
                    ...state,
                    colorHexCode: e.value,
                });
                if (document.getElementById("colorCodeErr").innerText)
                    document.getElementById("colorCodeErr").innerText= ""
                break;
            case "isActive":
                setState({
                    ...state,
                    isActive: e.checked === true ? '1' : '0',
                });
                break;

            default:
                break;
        }
        setTimeout(() => {
            const addFormObject = new FormValidator("#colourClusterForm", options);
            addFormObject.validate();
        }, 100);

    }
    const saveColorData = () => {
        const { colorName, colorHexCode, isActive, colorUid } = state
       

        let validateCheck = true
        if (colorHexCode === null || colorHexCode === "") {
            document.getElementById("colorCodeErr").innerText = pleasechoosecolor
            validateCheck = false
        }

        const addFormObject = new FormValidator('#colourClusterForm', validOptions);
        if (addFormObject.validate() && validateCheck) {
            setInitialLoad(true)
            let colourClusterSaveData
            colourClusterSaveData = {
                userId: authUser,
                type: colorUid > 0 ? 'EDIT' : 'ADD',
                isActive: isActive,
                colorHexCode: colorHexCode,
                colorName: colorName,
                colorUid
            }
            props.addEditColourClusterData(colourClusterSaveData);
            setTimeout(() => {
                setInitialLoad(false)
            }, 500);
        }
    }
    
    return (

        <div id='colourClusterForm' style={{ height: "61.5vh" }}>
            <div style={{ padding: "20px" }}>
                <div
                    className="col-xs-12 col-sm-12 col-lg-12 col-md-12  mt-lg atc-no-paddding"
                    style={{ marginBottom: "15px" }}
                >
                    <TextBoxComponent
                        data-msg-containerid="colorNameErr"
                        id="colorName"
                        value={state.colorName}
                        autocomplete={'off'}
                        name="colorNameErr"
                        placeholder="Legend Type *"
                        floatLabelType="Auto"
                        cssClass="e-outline"
                        onChange={(e) => onChangeWorkingDayClusterData(e, "colour")}
                        // blur={(e) => this.editBasicInFormation(e, "FirstName")}
                        htmlAttributes={{ maxlength: 100 }}
                    />
                    <div
                        id="colorNameErr"
                        className="e-input-group e-float-icon-left error-div"
                    />
                </div>
                <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 atc-no-paddding">
                    <div style={{ display: 'flex' }}>
                        <div className="atc-switch-heading" >Choose a Color</div>
                    </div>
                    <ColorPickerComponent
                        id="color-picker"
                        value={state.colorHexCode}
                        mode={Picker}
                        change={(e) => onChangeWorkingDayClusterData(e, "hexCode")}
                    ></ColorPickerComponent>
                    <div
                        id="colorCodeErr"
                        className="e-input-group e-float-icon-left error-div"
                    />
                </div>

            </div>

            <div className="loader-new1" id="spinnerContainer" >
                {initialLoad && <ProgressBar />}
            </div>
        </div>
    )
}

const mapStateToProps = ({ okrratingreducer, auth, lnareducer }) => {

    const { authUser } = auth
    const { fyId } = okrratingreducer
    const { officeCountry, officeLocation, dayBreakData, lnaMasterFlag } = lnareducer
    return { authUser, officeCountry, officeLocation, fyId, dayBreakData, lnaMasterFlag };
};

export default connect(mapStateToProps, { addEditColourClusterData, updateLnAData })(ColourCluster)