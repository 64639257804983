/**
 * Author Name :- Prashant Waphare
 * Date : 27-05-2020
 * Description :- This file use for resignation employee list
 */
import * as React from "react";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Filter,
  Inject,
  VirtualScroll,
  Sort,
  Page,
} from "@syncfusion/ej2-react-grids";
import TextField from "@material-ui/core/TextField";

import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import "../../../app/containers/tmlc/onboarding/onboarding.css";
import "../../../app/containers/tmlc/tmlc.css";
import "../../../../src/components/separation/resignationcomponent/resignationcomponent.css"
import { submiteLWDResignationCompanyrequestandNPS, approveResignation, addUpdateSeparationDetails} from '../../../redux/actions/entryexit/separationaction';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import DateFnsUtils from "@date-io/date-fns";
import { connect } from "react-redux";
import { Button, Dialog } from "@material-ui/core";
import { Redirect } from "react-router-dom";
import { ComboBoxComponent } from "@syncfusion/ej2-react-dropdowns";
import {
  DialogContent,
  DialogActions,
  DialogTitle,
} from "../../customdialog/customdialog";
import EmployeeNameTemplate from "../employeeNameTemplate";

import { PnlStatus, HrStatus, ReportingMgrStatus, AllDateFormat } from "../allcommoncomponents";
import { DateFilterformat } from '../../../../src/config/config';
import {
  getGridColumn,
  SEP_EMPLOYEE_NAME,
  REPORTING_SUPERVISOR_STATUS,
  PL_STATUS,
  HR_STATUS,
  LWD_COMPANY_REQUEST,
  ACTION,
  JOINING_DATE,
  DATE_OF_RESIGNATION,
  LWD_OFFICIAL,
  LWD_EMPLOYEE_REQUESTED,
  REASON,
  DAYS_DIFFERENCE,
  NOTICE_PERIOD,
  DAYS_TO_LWD,
  SEP_NOTICE_PERIOD_STATUS,
  EMP_REMARK
} from "../../../app/other/gridcolumns";
import { setMeetingCategory } from '../../../redux/actions/meetingcategoryaction';
import { RESIGNATIONPNLAPPROVAL, RESIGNATIONMANAGERAPPROVAL } from "../../../redux/constant/meetingcategoryconstant";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPlayCircle, faCheckCircle } from '@fortawesome/pro-light-svg-icons'
library.add(faPlayCircle, faCheckCircle);

class Resignation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: null,
      actionState: false,
      getLWDCompanyRequestDate: Date.parse(new Date()) / 1000,
      status: "",
      changeData: false,
      // height:
      // (window.innerHeight ||
      //   document.documentElement.clientHeight ||
      //   document.body.clientHeight) - 188,
    };
    this.height =
      (window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight) - 253;
    this.width =
      (window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth) - 100;
    this.dReady = false;
    this.dtTime = false;
    this.isDataBound = false;
    this.isDataChanged = true;
    this.dropSlectedIndex = null;
    this.check = {
      type: "CheckBox",
    };
    this.select = {
      persistSelection: true,
      type: "Multiple",
      checkboxOnly: true,
    };
    this.Filter = {
      type: "Menu",
    };
    this.pageOptions = {
      pageSize:
        (window.innerHeight ||
          document.documentElement.clientHeight ||
          document.body.clientHeight) > 700
          ? 12
          : 6,
    };
    this.name = {
      type: "CheckBox",
    };
    this.employeeNameTemplate = this.employeeNameTemplate.bind(this);
    this.onQueryCellInfoPreJoining = this.onQueryCellInfoPreJoining.bind(this);
    this.onDataBound = this.onDataBound.bind(this);
    this.onLoad = this.onLoad.bind(this);
    this.lwdCompanyRequest = this.lwdCompanyRequest.bind(this);
    this.handleConfirmedYes = this.handleConfirmedYes.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.resgnId = '';
    this.taskId = '';
    this.empId = '';
  }
  // shouldComponentUpdate(nextProps, nextState) {
  //   if (this.state.changedData !== nextState.changedData || this.state.progress !== nextState.progress) {
  //       return true
  //   }
  //   else {
  //       return false
  //   }
  // }
  componentDidMount() {
    this.props.getResignationTabData();
  }
  setCssHeader = () =>{
    setTimeout(() => {
      const ele = document.getElementsByClassName("e-headercelldiv");
      // eslint-disable-next-line

      for(let i in ele) {
        if(!isNaN(parseInt(i, 10))){
          let index = parseInt(i, 10);
          if (
            ele[index].innerText === JOINING_DATE ||
            ele[index].innerText === DATE_OF_RESIGNATION ||
            ele[index].innerText === LWD_OFFICIAL ||
            ele[index].innerText === LWD_EMPLOYEE_REQUESTED ||
            ele[index].innerText === DAYS_DIFFERENCE ||
            ele[index].innerText === NOTICE_PERIOD ||
            ele[index].innerText === ACTION
          ) {
            ele[index].classList.add("grid-center-header");
          } else {
            ele[index].classList.add("grid-left-header");
          }
        }
      }
    }, 500);
  }
  // updateDimensions() {
  //   let updateGridHeight = window.innerHeight < 576 ? window.innerHeight - 50 : window.innerHeight - 253;
  //   // let updateGridHeight = window.innerHeight < 576 ? window.innerHeight - 50 : window.innerHeight - 253;
  //   this.setState({ height: updateGridHeight });
  // }
  // eslint-disable-next-line
  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.status === "update") {
      this.setState({ changeData: !this.state.changeData });
      this.setState({ status: "" });
    }
    if (newProps.resignationData.length > 0) {
      this.setState({ changeData: !this.state.changeData });
    }
  }
  onQueryCellInfoPreJoining = (args) => {
    const { cell } = args;
    cell.setAttribute(
      "style",
      "font-size:14px !important; text-align: left !important; padding-left:18px !important"
    );
  }

  componentWillReceiveProps(newProps) {
    if (newProps.UpdateResignationStatus === 'LWDorNPSData' || newProps.UpdateResignationStatus === 'StatusUpdate') {
      this.props.getResignationTabData();
      this.props.addUpdateSeparationDetails({ UpdateResignationStatus: '' })
    }
  }

  onDataBound() {
    clearTimeout(this.clrIntervalFun);
    clearInterval(this.intervalFun);
    this.dtTime = true;
  }

  onLoad() {
    document
      .getElementById("overviewgrid")
      .ej2_instances[0].on("data-ready", () => {
        this.dReady = true;
        this.stTime = performance.now();
      });
    document
      .getElementById("overviewgrid")
      .addEventListener("DOMSubtreeModified", () => {
        if (this.dReady && this.stTime && this.isDataChanged) {
          this.stTime = null;
          this.dReady = false;
          this.dtTime = false;
          this.isDataChanged = false;
        }
      });
  }

  navigateToAddBasicInformation = async (args) => {
    // try {
    //   localStorage.setItem('last_meeting_category', localStorage.getItem('meeting_category'))
    //   localStorage.setItem("meeting_category", EMPLOYEE_PROFILE);

    //   const path = `/onboard?empId=${encrypt(args.employeeId)}`;
    //   this.setState({
    //     redirect: path
    //   }, () => this.props.setMeetingCategory(EMPLOYEE_PROFILE));
    // } catch (error) {
    //   console.log("error", error);
    // }
  };

  actionToggle = (props) => {
    const { resgnUid, taskId, employeeId } = props;
    this.resgnId = resgnUid;
    this.taskId = taskId;
    this.empId = employeeId

    const { actionState } = this.state;
    this.setState({ actionState: !actionState });
  };

  handleAction = (props) => {
    if (props.actioned === 'N')
      return (
        <div style={{ paddingLeft: '10px' }}>
          <TooltipComponent
            closeOn="Click"
            content="Accept"
            position="BottomCenter"
            isSticky={false}
            className="pointer-cursor"
            cssClass='customtooltip'>
            <FontAwesomeIcon icon={faCheckCircle} style={{ fontSize: "20px" }} onClick={() => this.actionToggle(props)} />
            {/* <i
              className="fa fa-check-circle"
              aria-hidden="true"
              style={{ fontSize: "25px", color: "rgba(0, 0, 0, 0.6)" }}
              onClick={() => this.actionToggle(props)}
            /> */}
          </TooltipComponent>
        </div>
      );
    else
      return (
        <div style={{ paddingLeft: '10px' }}>
          <TooltipComponent
            closeOn="Click"
            content="Accepted"
            position="BottomCenter"
            isSticky={false}
            cssClass='customtooltip'>
            <FontAwesomeIcon icon={faCheckCircle} style={{ fontSize: "20px", color: "green" }} />

            {/* <i
            className="fa fa-check-circle"
            aria-hidden="true"
            style={{ fontSize: "25px", color: "green" }}
          /> */}
          </TooltipComponent>
        </div>
      );

  };
  getLWDCompanyRequestDate = (e, props) => {
    const { authUser } = this.props;
    const { employeeId } = props;
    const { changeData } = this.state;
    this.setState({ getLWDCompanyRequestDate: Math.floor(Date.UTC(e.getFullYear(), e.getMonth(), e.getDate())) / 1000, status: "update", changeData: !changeData });

    this.props.submiteLWDResignationCompanyrequestandNPS({
      resgnId: props.resgnUid,
      userId: authUser,
      type: 'LWDC',
      empId: employeeId,
      dtLWDCompanyRequested: Math.floor(Date.UTC(e.getFullYear(), e.getMonth(), e.getDate())) / 1000
    })

  }

  lwdCompanyRequest = (props) => {
    const { lastWorkingDayCompanyRequested } = props
    const { categoryName } = this.props
    if (categoryName === RESIGNATIONMANAGERAPPROVAL) {
      return (
        <AllDateFormat date={lastWorkingDayCompanyRequested} />
      );
    } else {
      return (
        <div className="col-xs-2 col-sm-2 col-lg-12 col-md-12 ">
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              style={{ width: "160px" }}
              id="lwdCompanyRequest"
              name="lwdCompanyRequest"
              format="dd-MMM-yy"

              KeyboardButtonProps={{ "aria-label": "change date" }}
              value={new Date(lastWorkingDayCompanyRequested)}
              // maxDate={new Date(lastWorkingDayOfficial)}
              // minDate={new Date(dateOfResignation)}
              onChange={(e) => this.getLWDCompanyRequestDate(e, props)}
              TextFieldComponent={this.TextFieldComponent}
              inputVariant="outlined"
              variant="inline"
              InputAdornmentProps={{ position: "start" }}
              autoOk
              minDate={new Date()}
            />
          </MuiPickersUtilsProvider>
        </div>
      );
    }

  };

  TextFieldComponent = (props) => {
    return (
      // eslint-disable-next-line
      <TextField {...props} disabled />
    );
  };

  reportingStatus = (props) => {
    const { reportingMgrStatus } = props;
    return (
      <ReportingMgrStatus reportingMgrStatus={reportingMgrStatus}
      />
    );
  };

  pnlStatus = (props) => {
    const { pnlHeadStatus } = props;
    return (
      <PnlStatus pnlHeadStatus={pnlHeadStatus}
      />
    );
  };

  hrStatus = (props) => {
    const { hrStatus } = props;
    return (
      <HrStatus hrStatus={hrStatus}
      />
    );
  };

  resignationReason = (props) => {
    const { resgnReason } = props;
    return (
      <div>
        <TooltipComponent
          content={resgnReason.replace(/(?:\r\n|\r|\n|,)/g, '<br>')}
          position="BottomCenter"
          isSticky={false}
          cssClass='customtooltip'
        >
          <span
            style={{
              fontSize: "14px",
              display: "block",
              width: "100px",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {resgnReason}
          </span>
        </TooltipComponent>
      </div>
    );
  };

  joiningDateFormat = (props) => {
    const { joiningDate } = props
    return (
      <AllDateFormat date={joiningDate} />
    );
  };

  resignationDateFormat = (props) => {
    const { dateOfResignation } = props
    return (
      <AllDateFormat date={dateOfResignation} />
    );
  };

  lwdDateFormat = (props) => {
    const { lastWorkingDayOfficial } = props
    return (
      <AllDateFormat date={lastWorkingDayOfficial} />
    );
  };

  noticePeriodDays = (props) => {
    const { noticePeriodInDays } = props;
    return (
      <div style={{ textAlign: "center" }}>
        <span>{noticePeriodInDays}{" Days"}</span>
      </div>
    );
  };

  lwdEmpRequestedDateFormat = (props) => {
    const { lastworkingEmpRequested } = props
    return (
      <AllDateFormat date={lastworkingEmpRequested} />
    );
  };
  daysDifference = (props) => {
    const { daysDiffJoinSeparation } = props
    return (
      <div style={{ textAlign: "center" }}>
        <span>{daysDiffJoinSeparation}</span>
      </div>
    );
  };
  mgrRemarks = (props) => {
    const { remarks } = props;
    return (
      <div>
        <TooltipComponent
          content={remarks.replace(/(?:\r\n|\r|\n)/g, '<br>')}
          position="BottomCenter"
          isSticky={true}
          cssClass='customtooltip'
          opensOn="Click"
        >
          <span
            style={{
              fontSize: "14px",
              display: "block",
              width: "100px",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              cursor: "pointer"
            }}
          >
            {remarks.replace(/[&\\#,+()$~%.'":*?<>{}]/g, '')}
          </span>
        </TooltipComponent>
      </div>
    );
  }
  getNoticeValue = (e, props) => {
    const { authUser } = this.props;
    this.setState({ selectedNoticeValue: e.itemData.Id }, () =>
      this.props.submiteLWDResignationCompanyrequestandNPS({
        resgnId: props.resgnUid,
        userId: authUser,
        type: 'NPS',
        noticePeriodStatus: e.itemData.Id,
        empId: props.employeeId
      })
    );
  }

  noticePeriod = (props) => {
    const { noticePeriodStatus, noticePeriodStatusId, lastWorkingDayOfficial, lastWorkingDayCompanyRequested } = props
    const { categoryName, noticePeriodMasterData } = this.props
    if (categoryName === RESIGNATIONPNLAPPROVAL) {
      var noticePeriodMasterDataModified = [];
      if(lastWorkingDayCompanyRequested.getTime() <= lastWorkingDayOfficial.getTime()){
        noticePeriodMasterDataModified = noticePeriodMasterData.filter(function( obj ) {
          return obj.Id !== 691; // Remove 'NA' option from dropdown
        });
      }
      else{
        noticePeriodMasterDataModified = noticePeriodMasterData;
      }
      return (<div className="col-xs-2 col-sm-2 col-lg-12 col-md-12 ">
        <ComboBoxComponent
          id="noticePeriodValue"
          fields={{ text: "Name", value: "Id" }}
          name="noticePeriodValue"
          value={noticePeriodStatusId}
          allowCustom={false}
          cssClass="e-outline"
          dataSource={noticePeriodMasterDataModified}
          select={(e) => this.getNoticeValue(e, props)}
        />
      </div>
      )
    } else {
      return (
        <div>
          <span>{noticePeriodStatus}</span>
        </div>
      );
    }
  };

  daysToLwd = (props) => {
    const { daysToLastWorkingDay } = props
    return (
      <div style={{ textAlign: "center" }}>
        <span>{daysToLastWorkingDay}{" Days"}</span>
      </div>
    );
  };
  employeeNameTemplate(props) {
    return (
      <EmployeeNameTemplate
        props={props}
        data="test"
        navigateToAddBasicInformation={this.navigateToAddBasicInformation}
      />
    );
  }

  handleConfirmedYes = () => {
    try {
      const { authUser } = this.props;
      const approveData = {
        userId: authUser,
        resgnId: this.resgnId,
        taskId: this.taskId,
        empId: this.empId,
        type: "APPR"
      };
      this.props.approveResignation(approveData);
    } catch (error) {
    }
    this.setState({ actionState: false });
  };

  handleClose = () => {
    this.setState({ actionState: false });
  }
  render() {
    const { redirect } = this.state;
    const { resignationData, categoryName } = this.props;
    if (redirect !== null) {
      // eslint-disable-next-line
      return <Redirect to={redirect} />;
    }
      this.setCssHeader();
    return (
      <div
        className="row resignation-manager-hr-grid-height"
      // className="row" style={{height:`${this.state.height}`}}
      >
        <div>
          <div className="row-xs-12 row-sm-12 row-lg-12 row-md-12">
            <div
              style={{
                paddingLeft: "15px",
                paddingRight: "15px",
                paddingTop: "5px",
              }}
            >
              <GridComponent
                className="grid-head-custome"
                id="overviewgrid"
                allowPaging
                pageSettings={this.pageOptions}
                dataSource={resignationData}
                enableHover={false}
                enableVirtualization={false}
                rowHeight={30}
                height={this.height}
                ref={(g) => {
                  this.gridInstance = g;
                }}

                load={this.onLoad}
                queryCellInfo={this.onQueryCellInfoPreJoining}
                dataBound={this.onDataBound}
                filterSettings={this.Filter}
                allowFiltering
                allowSorting
                allowSelection
                selectionSettings={this.select}
                allowTextWrap={true}

              >
                <ColumnsDirective>
                  {getGridColumn("resignation").map((item) => {
                    switch (item.colName) {
                      case SEP_EMPLOYEE_NAME:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.employeeNameTemplate}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                            filter={this.Filter}
                          />
                        );
                      case REPORTING_SUPERVISOR_STATUS:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.reportingStatus}
                            filter={this.check}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                          />
                        );
                      case PL_STATUS:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.pnlStatus}
                            filter={this.check}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                          />
                        );
                      case HR_STATUS:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.hrStatus}
                            filter={this.check}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                          />
                        );
                      case LWD_COMPANY_REQUEST:
                        {
                          return (
                            categoryName === RESIGNATIONMANAGERAPPROVAL ?
                              <ColumnDirective
                                key={item.field}
                                template={this.lwdCompanyRequest}
                                // allowFiltering={true}
                                filter={this.check}
                                textAlign={`${item.align} !important`}
                                field={item.field}
                                headerText={item.colName}
                                width={150}
                                format={DateFilterformat}
                              /> :
                              <ColumnDirective
                                key={item.field}
                                template={this.lwdCompanyRequest}
                                // allowFiltering={false}
                                filter={this.check}
                                textAlign={`${item.align} !important`}
                                field={item.field}
                                headerText={item.colName}
                                width={item.width}
                                format={DateFilterformat}
                              />
                          );
                        }

                      case REASON:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.resignationReason}
                            allowFiltering={false}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                          />
                        );
                      case JOINING_DATE:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.joiningDateFormat}
                            // allowFiltering={false}
                            filter={this.check}
                            // filter={this.Filter}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                            format={DateFilterformat}
                          />
                        );
                      case DATE_OF_RESIGNATION:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.resignationDateFormat}
                            // allowFiltering={false}
                            // filter={this.Filter}
                            filter={this.check}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                            format={DateFilterformat}
                          />
                        );
                      case LWD_OFFICIAL:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.lwdDateFormat}
                            // allowFiltering={false}
                            // filter={this.Filter}
                            filter={this.check}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                            format={DateFilterformat}
                          />
                        );
                      case SEP_NOTICE_PERIOD_STATUS:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.noticePeriod}
                            // allowFiltering={false}
                            filter={this.check}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                          />
                        );
                      case NOTICE_PERIOD:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.noticePeriodDays}
                            // allowFiltering={false}
                            filter={this.check}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                          />
                        );
                      case LWD_EMPLOYEE_REQUESTED:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.lwdEmpRequestedDateFormat}
                            // allowFiltering={false}
                            // filter={this.Filter}
                            filter={this.check}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                            format={DateFilterformat}
                          />
                        );
                      case DAYS_TO_LWD:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.daysToLwd}
                            // allowFiltering={false}
                            filter={this.check}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                          />
                        );
                      case DAYS_DIFFERENCE:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.daysDifference}
                            // allowFiltering={false}
                            filter={this.check}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                          />
                        );
                      case EMP_REMARK:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.mgrRemarks}
                            allowFiltering={false}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                          />
                        );
                      case ACTION:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.handleAction}
                            allowFiltering={false}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                          />
                        );
                      default:
                        return (
                          <ColumnDirective
                            key={item.field}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                            filter={this.check}
                          />
                        );
                    }
                  })}
                </ColumnsDirective>
                <Inject services={[Page, Filter, VirtualScroll, Sort]} />
              </GridComponent>
            </div>
          </div>


          <div style={{ width: "350px" }}>
            <Dialog open={this.state.actionState}>
              <DialogTitle> Accept Resignation</DialogTitle>
              <DialogContent>
                Are you sure you want to accept the resignation?
                </DialogContent>
              <DialogActions>
                <Button onClick={this.handleConfirmedYes}> Yes </Button>
                <Button onClick={this.handleClose}> No </Button>
              </DialogActions>
            </Dialog>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ auth, meetingcategory, separationreducer }) => {
  const { authUser } = auth;
  const { UpdateResignationStatus } = separationreducer
  const { categoryName } = meetingcategory;
  return { authUser, categoryName, UpdateResignationStatus };
};
export default connect(mapStateToProps, { submiteLWDResignationCompanyrequestandNPS, approveResignation, addUpdateSeparationDetails, setMeetingCategory })(Resignation);
