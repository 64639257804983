import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from "react-router-dom";
import { monthOfReviewData } from '../../assets/constants/constants';
import {addUpdateOkrDashboardRedux} from '../../redux/actions/okrdashboardaction/okrdashboardaction';
function OKRRatingAndTeamStatus(props) {
  const {teamStatusSelected,directReporteesData,teamSizeData}=props;
  const statusChartData = teamStatusSelected==='team-size' ? teamSizeData:directReporteesData;


  const getMonthName = (Id) => {
    let data = monthOfReviewData?.filter((t) => t.Id === Id)
    return data.length>0 ? data[0].Name :" "
  }


const getStyle = () =>{
  const{showMonths}=props


  if(window.innerHeight === 625 && !showMonths ){
    return{height:287}
  }
  if(window.innerHeight < 670){
    return {height:303}
  }
  
}

  return (
    <>
      <div className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-lg pt-lg pr-md pb-lg pl-md okr-rating-status" >
        
        <div className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <p className="okr-rating-status-title">OKR Score</p>
        </div>

        <div className="row card-layout">
          <div className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pt-md okr-average">
            <div className="col-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 okr-company-average">
              <p className="okr-company-average-title">Company Median</p>
              <p className="okr-company-average-number">{props.companyMedian}</p>
            </div>
            <div className="col-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 okr-team-average">
              <p className="okr-company-average-title">Team Median</p>
              <p className="okr-company-average-number">{props.teamMedian}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-md mr-md mb-md ml-md okr-team-status" style={getStyle()}>
        <div className="row card-layout">
          <div className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-sm mb-sm okr-team-status-title">
            <div className="col-5 col-lg-5 col-md-5 col-sm-5 col-xs-5 team-status-title">Team Status</div>
            <div className="col-5 col-lg-5 col-md-5 col-sm-5 col-xs-5 compliance-report" ></div>
          </div>
        </div>

        <div className="row card-layout ml-md mr-md">
          <div className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 team-size-direct-reportees">
            <div className={`col-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 ${teamStatusSelected==='team-size' ? 'team-status-selected':'team-status-not-selected' }`}
              onClick={()=>props.addUpdateOkrDashboardRedux({teamStatusSelected:'team-size', dashboardTeamRatingStatus:'updated'})}
              style={{ cursor:'pointer' }}
            >
              <div className="number-and-view">
                <p className="okr-company-average-number">{teamSizeData.teamSize}</p>
                {
                teamStatusSelected==='team-size' &&
                <p>View</p>
                }
              </div>
              <div className="team-and-reportees">Team Size</div>
            </div>
            <div className={`col-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 ${teamStatusSelected==='direct-reportee' ? 'team-status-selected':'team-status-not-selected' }`} 
                onClick={()=>props.addUpdateOkrDashboardRedux({teamStatusSelected:'direct-reportee', dashboardTeamRatingStatus:'updated'})}
                style={{ marginRight: 0, cursor:'pointer' }}
              >
              <div className="number-and-view">
                <p className="okr-company-average-number">{directReporteesData.directReporteesCount}</p>
                {
                  teamStatusSelected==='direct-reportee'&&
                <p>View</p>
                }
              </div>
              <div className="team-and-reportees">Direct Reportees</div>
            </div>
          </div>
        </div>

      <div className="row card-layout status-chart-hight">
        <div className="col-11 col-lg-11 col-md-11 col-sm-11 col-xs-11 status-chart-container">
          <table className="table" style={{ fontSize: ".9em" }}>
            <thead>
              <tr>
                <th className="status-chart-title" scope="col">Status Chart</th>
                <th className="status-chart-data status-chart-title" scope="col">Data</th>
              </tr>
            </thead>
              <tbody>
                <tr>
                  <td className="status-chart-title">Month</td>
                  <td className="status-chart-data">{getMonthName(statusChartData.monthNum)}</td>
                  {/* <td className="status-chart-data">{statusChartData.monthNum}</td> */}
                </tr>
                <tr>
                  <td className="status-chart-title">Eligible</td>
                  <td className="status-chart-data">{statusChartData.eligibleCount}</td>
                </tr>
                <tr>
                  <td className="status-chart-title">Completed</td>
                  <td className="status-chart-data">{statusChartData.completedCount}</td>
                </tr>
                <tr>
                  <td className="status-chart-title">Pending</td>
                  <td className="status-chart-data">{statusChartData.pendingCount}</td>
                </tr>
                <tr>
                  <td className="status-chart-title">Locked</td>
                  <td className="status-chart-data">{statusChartData.lockedCount}</td>
                </tr>
                <tr>
                  <td className="status-chart-title">Not Applicable</td>
                  <td className="status-chart-data">{statusChartData.notApplicable}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  )
}



const mapStateToProps = ({ auth, okrdashboardreducer }) => {
  const { authUser } = auth;
  const {teamStatusSelected,directReporteesData,teamSizeData, teamMedian,companyMedian }=  okrdashboardreducer;
  return {
    teamStatusSelected,directReporteesData,teamSizeData,authUser, teamMedian,companyMedian
  }
};
export default connect(mapStateToProps, { addUpdateOkrDashboardRedux })(
  OKRRatingAndTeamStatus
);
