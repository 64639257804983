
import * as React from "react";
import { connect } from "react-redux";
import { MainClass } from "../../../mainclass";
import queryString from "query-string";
import {changeLeaveApprovalStatus} from '../../../redux/actions/lna/lnaaction'
import {dencrypt} from '../../other/commonfunction';
import ErrorTabMessage from '../../../components/errormessage/errormessage'
class LeaveEmailApprove extends MainClass {
  constructor(props) {
    super(props)
  
    this.state = {
       message:""
    }
  }
  componentDidMount = () => {
    const params = queryString.parse(this.props.location.search);
    setTimeout(()=>{
      if(params.status==="approve" && params.type==="leaveApproval"){
        this.leaveApproval(dencrypt(params.id), dencrypt(params.requestId))
      }  
      if(params.status==="reject" && params.type==="leaveApproval"){
        this.leaveRejection(dencrypt(params.id),dencrypt(params.requestId))
      }  
      if(params.status==="approve" && params.type==="compOffApproval"){
        this.compOffApproval(dencrypt(params.id), dencrypt(params.requestId))
      }  
      if(params.status==="reject" && params.type==="compOffApproval"){
        this.compOffRejection(dencrypt(params.id),dencrypt(params.requestId))
      }  
      if(params.status==="approve" && params.type==="leaveTransferApproval"){
        this.leaveTransferApproval(dencrypt(params.id), dencrypt(params.requestId))
      }  
      if(params.status==="reject" && params.type==="leaveTransferApproval"){
        this.leaveTransferRejection(dencrypt(params.id),dencrypt(params.requestId))
      }  
      if(params.status==="approve" && params.type==="swipeApproval"){
        this.swipeApproval(dencrypt(params.id), dencrypt(params.requestId))
      }  
      if(params.status==="reject" && params.type==="swipeApproval"){
        this.swipeRejection(dencrypt(params.id),dencrypt(params.requestId))
      }  
    },1000)
  };
  swipeApproval = (id, leaveId)=>{
    this.props.changeLeaveApprovalStatus({
        "leaveId":leaveId,
        "type":"swipeApproval",
        "userId": id,
        "status": "2",
        "reason": "from email."
    }) 
    this.setState({
      message:"Leave swipe approved successfully."
    })  
  }
  swipeRejection = (id, leaveId)=>{
    this.props.changeLeaveApprovalStatus({
        "leaveId":leaveId,
        "type":"swipeApproval",
        "userId": id,
        "status": "3",
        "reason": "from email."
    }) 
    this.setState({
      message:"Leave swipe rejected successfully."
    })  
  }
  leaveTransferApproval = (id, leaveId)=>{
    this.props.changeLeaveApprovalStatus({
        "leaveId":leaveId,
        "type":"leaveTransferApproval",
        "userId": id,
        "status": "2",
        "reason": "from email."
    }) 
    this.setState({
      message:"Leave transfer approved successfully."
    })  
  }
  leaveTransferRejection = (id, leaveId)=>{
    this.props.changeLeaveApprovalStatus({
        "leaveId":leaveId,
        "type":"leaveTransferApproval",
        "userId": id,
        "status": "3",
        "reason": "from email."
    }) 
    this.setState({
      message:"Leave transfer rejected successfully."
    })  
  }
  compOffApproval = (id, leaveId)=>{
    this.props.changeLeaveApprovalStatus({
        "leaveId":leaveId,
        "type":"compOffApproval",
        "userId": id,
        "status": "2",
        "reason": "from email."
    }) 
    this.setState({
      message:"Comp. off approved successfully."
    })  
  }
  compOffRejection = (id, leaveId)=>{
    this.props.changeLeaveApprovalStatus({
        "leaveId":leaveId,
        "type":"compOffApproval",
        "userId": id,
        "status": "3",
        "reason": "from email."
    }) 
    this.setState({
      message:"Comp. off rejected successfully."
    })  
  }
  leaveApproval = (id, leaveId)=>{
    this.props.changeLeaveApprovalStatus({
        "leaveId":leaveId,
        "type":"leaveApproval",
        "userId": id,
        "status": "2",
        "reason": "from email."
    }) 
    this.setState({
      message:"Leave approved successfully."
    })  
  }
  leaveRejection = (id,leaveId)=>{
      this.props.changeLeaveApprovalStatus({
        "leaveId":leaveId,
        "type":"leaveApproval",
        "userId":id,
        "status": "3",
        "reason": "from email."
    })
    this.setState({
        message:"Leave rejected successfully."
      })  
  }
  render() { // NOSONAR
    const{message}=this.state;
    return (
      <div className="control-section">
        <div style={{marginTop:"15%"}}>
            <ErrorTabMessage 
              message={message}
              />
        </div>
      </div>
    );
  }  
}

const mapStateToProps = ({ auth, onboardingreducer }) => {
  const { candidateid, isDocsSubmitted, open_document_preview, openDocumentPopup } = onboardingreducer;
  const { authUser } = auth;
  return { authUser, candidateid, isDocsSubmitted, open_document_preview, openDocumentPopup };
};
export default connect(mapStateToProps, {
  changeLeaveApprovalStatus
})(LeaveEmailApprove);
