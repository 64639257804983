import React from 'react'
import PropTypes from "prop-types";
import Button from './buttoncomponent'
import { Dialog } from "@material-ui/core";
import { DialogContent } from "../components/customdialog/customdialog";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faX } from '@fortawesome/pro-light-svg-icons';
const ConfirmationPopup = (props) => {
  const { headerMessage, subMessage, button1Text, button2Text, showButton, open, } = props;

  return (
    <div>
      <Dialog
        open={open}
        maxWidth="md"
      >
        <DialogContent style={{ borderTop: "5px solid #F94F5E" }}>
          <div>
            <div className='common-confirm-popup' style={{
              display: 'flex',
              justifyContent: 'space-between',

            }}>
              <div style={{ width: '90%' }}>
                {headerMessage && headerMessage !== "" && <div className='common-confirm-popup-header-message' style={{
                  fontWeight: subMessage && subMessage !== "" ? 'bold' : '',
                }} >{headerMessage}</div>}
                {subMessage && subMessage !== "" && <div className='common-confirm-popup-header-message' style={{
                  marginTop: 10
                }} >{subMessage}</div>}
              </div>
              <FontAwesomeIcon icon={faX} style={{ cursor: 'pointer', fontSize: 12 }} onClick={props.handleConfirmClose} />
            </div>
            <div className='buttons-alignment'>
              <div>
                {(showButton.type === "2" || button2Text !== "") && (
                  <Button
                    id=""
                    style={{ fontSize: "1rem" }}
                    content={button2Text}
                    variant="contained"
                    click={() => props.button2function()}
                  >
                  </Button>
                )}
                {(showButton.type === "1" || button1Text !== "") && (
                  <Button
                    id=""
                    type="primary"
                    style={{ fontSize: "1rem" }}
                    content={button1Text}
                    variant="contained"
                    click={() => props.button1function()}
                  >
                  </Button>
                )}
              </div>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
ConfirmationPopup.propTypes = {
  headerMessage: PropTypes.string.isRequired,
  subMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(Object)]),
  button1Text: PropTypes.string.isRequired,
  button2Text: PropTypes.string,
  showButton: PropTypes.string,
  open: PropTypes.bool,
  handleConfirmClose: PropTypes.func
};

export default ConfirmationPopup;

