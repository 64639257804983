import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { FormValidator, TextBoxComponent, NumericTextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { ComboBoxComponent } from "@syncfusion/ej2-react-dropdowns";
import okrServices from '../../services/okr/okrservices';
import { saveAxisMaster, updateOKRData } from "../../redux/actions/okr/okractions";
import { getNotify } from "../../app/other/commonfunction";
import {
  ERROR,
  pleaseEnterFY,
  pleaseEnterWeightagedVariable,
  pleaseEnterWeightAllocation,
  // pleaseEnterGroupName,
  pleaseEnterXAxisMeaning,
  pleaseEnterYAxisMeaning,
  characterCountLimit,
  regexOnlyLettersAndSpaces,
  weightedAllocationValidation
} from "../../assets/constants/constants";
import ProgressBar from "../progressbar/progressbar";

function AxisMasterSidebar(props) {
  const { finacialYearData, changePopUpHandler, setSubmitClick, authUser, masterItemId = 0, masterId, openPopup, categoryName, loading, updateOKRData } = props; //NOSONAR

  const [state, setState] = useState({
    // groupName: "",
    xAxisName: "X",
    xMeaningName: null,
    xWeightAllocation: null,
    xWeightagedVariable: null,
    yAxisName: "Y",
    yMeaningName: null,
    yWeightAllocation: null,
    yWeightagedVariable: null,
    axisMasterFY: null,
    axisMasterFYId: null,
  });

  const [collapse, setCollapse] = useState({
    xCollapsed: false,
    yCollapsed: false,
  })

  const [updateSave, setUpdateSave] = useState(false)

  useEffect(() => {
    setState({
      // groupName: "",
      xAxisName: "X",
      xMeaningName: null,
      xWeightAllocation: null,
      xWeightagedVariable: null,
      yAxisName: "Y",
      yMeaningName: null,
      yWeightAllocation: null,
      yWeightagedVariable: null,
      axisMasterFY: null,
      axisMasterFYId: null,
    });
    (async () => {
      try {
        if (openPopup === 'edit') {
          updateOKRData({ loading: true });
          const okrMasterItemData = await okrServices.getOKRMaserData(
            authUser,
            masterId,
            masterItemId
          );


          const filterData = finacialYearData.filter(item => item.Name === okrMasterItemData.Result[0]?.fyId);

          setState(prevState => ({/*NoSONAR*/
            ...prevState,
            // groupName: okrMasterItemData.Result[0]?.groupName,
            xWeightAllocation: parseInt(okrMasterItemData.Result[0]?.weightAllocation),
            xWeightagedVariable: okrMasterItemData.Result[0]?.variable,
            yWeightAllocation: parseInt(okrMasterItemData.Result[1]?.weightAllocation),
            yWeightagedVariable: okrMasterItemData.Result[1]?.variable,
            axisMasterFY: okrMasterItemData.Result[0]?.fyId,
            axisMasterFYId: filterData[0]?.Id,
            xMeaningName: okrMasterItemData.Result[0]?.axisMeaning,
            yMeaningName: okrMasterItemData.Result[1]?.axisMeaning,
          }));
          updateOKRData({ loading: false });
        } else {
          updateOKRData({ loading: false });
        }
      } catch (e) {
        console.log(e);
        updateOKRData({ loading: false });
      }
    })();
  }, [masterId, masterItemId]);

  useEffect(() => {
    if (props.okrMasterFlag === "updated") {
      changePopUpHandler("close");
    }
  }, [props.okrMasterFlag]);

  useEffect(() => {
    setTimeout(() => {
      setSubmitClick(onSaveData);
    }, 100);
  }, [state, masterId]);

  useEffect(() => {
    if (updateSave === true) {
      const options = {
        rules: {
          // groupNameErr: {
          //   required: [true, pleaseEnterGroupName],
          //   regex: [
          //     "^[a-zA-Z].*",
          //     "Please enter valid group name.",
          //   ],
          // },
          xMeaningNameErr: {
            required: [true, pleaseEnterXAxisMeaning],
          },
          yMeaningNameErr: {
            required: [true, pleaseEnterYAxisMeaning],
          },
          xWeightAllocationErr: {
            required: [true, pleaseEnterWeightAllocation],
          },
          xWeightagedVariableErr: {
            required: [true, pleaseEnterWeightagedVariable],
          },
          yWeightAllocationErr: {
            required: [true, pleaseEnterWeightAllocation],
          },
          yWeightagedVariableErr: {
            required: [true, pleaseEnterWeightagedVariable],
          },
          axisMasterFYErr: {
            required: [true, pleaseEnterFY],
          },
        }
      }

      let totalWeightAllocation;
      if (xWeightAllocation + "" && yWeightAllocation + "") {
        totalWeightAllocation = parseInt(xWeightAllocation) + parseInt(yWeightAllocation);
      }

      let totalWeightagedVariable = 0;
      if (xWeightagedVariable + "" && yWeightagedVariable + "") {
        totalWeightagedVariable = parseInt(xWeightagedVariable) + parseInt(yWeightagedVariable);
      }

      if ((xWeightAllocation + "" && yWeightAllocation + "") && totalWeightAllocation !== 100) {
        if (xWeightAllocation !== null)
          document.getElementById("xWeightAllocationErr").innerHTML = "Sum of weighted allocation of the X and Y axis must be 100.";
        if (yWeightAllocation !== null)
          document.getElementById("yWeightAllocationErr").innerHTML = "Sum of weighted allocation of the X and Y axis must be 100.";
      }

      if ((xWeightAllocation + "" && yWeightAllocation + "") && totalWeightAllocation === 100) {
        document.getElementById("xWeightAllocationErr").innerHTML = ""
        document.getElementById("yWeightAllocationErr").innerHTML = ""
      }

      if ((xWeightagedVariable + "" && yWeightagedVariable + "") && (totalWeightagedVariable !== 100 || totalWeightagedVariable !== 0)) {
        if (xWeightagedVariable !== null)
          document.getElementById("xWeightagedVariableErr").innerHTML = "Sum of weighted variable of the X and Y axis must be either 0 or 100.";
        if (yWeightagedVariable !== null)
          document.getElementById("yWeightagedVariableErr").innerHTML = "Sum of weighted variable of the X and Y axis must be either 0 or 100.";
      }


      if ((xWeightagedVariable + "" && yWeightagedVariable + "") && (totalWeightagedVariable == 100 || totalWeightagedVariable == 0)) {
        document.getElementById("xWeightagedVariableErr").innerHTML = "";
        document.getElementById("yWeightagedVariableErr").innerHTML = "";
      }

      const addFormObject = new FormValidator("#AxisMasterForm", options);

      if (addFormObject && addFormObject.validate() === false) {
        getNotify(ERROR, "Please fill all the required fields");
      }

      if (addFormObject && addFormObject.validate() && totalWeightAllocation === 100 && (totalWeightagedVariable === 100 || totalWeightagedVariable === 0)) {
        setTimeout(() => {
          try {
            const data = {
              type: openPopup,
              userId: authUser,
              fyId: axisMasterFYId,
              // groupName: groupName,
              xMeaningName,
              yMeaningName,
              performancePercent: xWeightAllocation + "" ? (xWeightAllocation + "").toString() : "",
              potentialPercent: yWeightAllocation + "" ? (yWeightAllocation + "").toString() : "",
              performanceVariable: xWeightagedVariable + "" ? (xWeightagedVariable + "").toString() : "",
              potentialVariable: yWeightagedVariable + "" ? (yWeightagedVariable + "").toString() : "",
            }
            props.saveAxisMaster(data);
            changePopUpHandler("close");
            setUpdateSave(false)
          } catch (e) {
            console.log(e)
            updateOKRData({ loading: false });
          }
        }, 1000)
      } else {
        setUpdateSave(false)
      }
    }
  }, [updateSave === true])


  const handleStateChange = (type, field, value) => {
    if (type === "axisMaster") {
      if (field === "axisMasterFY") {
        const filterData = finacialYearData.filter(item => item.Name === value);
        setState(prevState => ({ ...prevState, [field]: value, axisMasterFYId: filterData[0]?.Id }))
      } else {
        setState(prevState => ({ ...prevState, [field]: value }))
      }
    }
  }


  const axisMasterFieldChange = (e, stateOf) => {
    let options = {}
    handleStateChange("axisMaster", `${stateOf}`, e.value);
    switch (stateOf) {
      // case 'groupName':
      //   document.getElementById("groupNameErr").innerHTML = ""
      //   options = {
      //     rules: {
      //       groupNameErr: {
      //         required: [true, "Group name is required"],
      //         maxLength: [100, characterCountLimit],
      //         regex: [regexOnlyLettersAndSpaces, "Please enter appropriate group name (Alphabets only)."],
      //       },
      //     },
      //   };
      //   break;
      case 'xMeaningName':
        document.getElementById("xMeaningNameErr").innerHTML = ""
        options = {
          rules: {
            xMeaningNameErr: {
              required: [true, "Axis X meaning is required"],
              maxLength: [50, characterCountLimit],
              regex: [regexOnlyLettersAndSpaces, "Please enter appropriate axis X meaning."],
            },
          },
        };
        break;
      case 'xWeightAllocation':
        document.getElementById("xWeightAllocationErr").innerHTML = ""
        options = {
          rules: {
            xWeightAllocationErr: {
              required: [true, "Weight allocation for X is required."],
              number: [true, "Weight allocation for X must be a number."],
              min: 0,
              max: 100,
            },
          },
        };
        break;
      case 'xWeightagedVariable':
        document.getElementById("xWeightagedVariableErr").innerHTML = ""
        options = {
          rules: {
            xWeightagedVariableErr: {
              required: [true, "Weightage Variable for X is required."],
              number: [true, "Weightage Variable for X must be a number."],
              min: 0,
              max: 100,
            },
          },
        };
        break;
      case 'yMeaningName':
        document.getElementById("yMeaningNameErr").innerHTML = ""
        options = {
          rules: {
            yMeaningNameErr: {
              required: [true, "Axis Y meaning is required"],
              maxLength: [50, characterCountLimit],
              regex: [regexOnlyLettersAndSpaces, "Please enter appropriate axis Y meaning."],
            },
          },
        };
        break;
      case 'yWeightAllocation':
        document.getElementById("yWeightAllocationErr").innerHTML = ""
        options = {
          rules: {
            yWeightAllocationErr: {
              required: [true, "Weightage allocation for Y is required."],
              number: [true, "Weightage allocation for Y must be a number."],
              min: 0,
              max: 100,
            },
          },
        };
        break;
      case 'yWeightagedVariable':
        document.getElementById("yWeightagedVariableErr").innerHTML = ""
        options = {
          rules: {
            yWeightagedVariableErr: {
              required: [true, "Weightage Variable for Y is required."],
              number: [true, "Weightage Variable for Y must be a number."],
              min: 0,
              max: 100,
            },
          },
        };
        break;
    }
    setTimeout(() => {
      const addFormObject = new FormValidator("#OKRAxisMaster", options);
      addFormObject.validate();
    }, 100);
    handleStateChange("axisMaster", `${stateOf}`, e.value);
  }

  const handleCollapse = (type) => {
    if (type === 'x') {
      setCollapse(prevState => {
        return { ...prevState, xCollapsed: !prevState.xCollapsed }
      })
    }
    if (type === 'y') {
      setCollapse(prevState => {
        return { ...prevState, yCollapsed: !prevState.yCollapsed }
      })
    }
  }

  const onSaveData = () => {
    const xWeightAllocationValue = document.getElementById('xWeightAllocation')?.ej2_instances[0]?.value
    const xWeightagedVariableValue = document.getElementById('xWeightagedVariable')?.ej2_instances[0]?.value
    const yWeightAllocationValue = document.getElementById('yWeightAllocation')?.ej2_instances[0]?.value
    const yWeightagedVariableValue = document.getElementById('yWeightagedVariable')?.ej2_instances[0]?.value

    if (xWeightAllocationValue !== null && xWeightagedVariableValue !== null && yWeightAllocationValue !== null && yWeightagedVariableValue !== null) {
      setState(prevState => ({ ...prevState, xWeightAllocation: xWeightAllocationValue, xWeightagedVariable: xWeightagedVariableValue, yWeightAllocation: yWeightAllocationValue, yWeightagedVariable: yWeightagedVariableValue }))
    }
    setUpdateSave(true)
  }

  const { xMeaningName, xWeightAllocation, xWeightagedVariable, yMeaningName, yWeightAllocation,
    yWeightagedVariable, axisMasterFY, axisMasterFYId } = state;

  return (
    <>
      <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12" id="OKRAxisMaster">
        {/* <div className="pt-md pr-md pl-md">
          <TextBoxComponent
            id="groupName"
            placeholder="Group Name *"
            cssClass="e-outline"
            floatLabelType="Auto"
            data-msg-containerid="groupNameErr"
            name="groupNameErr"
            value={groupName}
            onChange={(e) => axisMasterFieldChange(e, 'groupName')}
          />
          <div
            id="groupNameErr"
            className="error-div"
          />
        </div> */}

        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
          <div className="axis-master-axis-title pl-md mt-md pb-md"
            style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <span>Axis: X</span>
            <span className="pr-md">
              <i className={`fas fa-chevron-up ${collapse.xCollapsed ? 'fa-rotate-180' : ''}`} style={{ color: '#B7B7BB', cursor: 'pointer', transition: 'transform 0.5s', }}
                onClick={() => handleCollapse('x')}
              ></i>
            </span>
          </div>
          <div style={{ ...(collapse.xCollapsed ? { display: 'none', opacity: 0 } : { display: 'block', opacity: 1 }), transition: "visibility 0.5s, opacity 0.5s" }}>

            <div className="pt-md pr-md pl-md">
              <TextBoxComponent
                id="xAxisName"
                placeholder="Axis Name"
                cssClass="e-outline"
                floatLabelType="Auto"
                data-msg-containerid="xAxisNameErr"
                name="xAxisNameErr"
                // value={xAxisName}
                value={"X"}
                // disabled={true}
                enabled={false}
                readOnly
                change={(e) => axisMasterFieldChange(e, 'xAxisName')}
              />
              <div
                id="xAxisNameErr"
                className="error-div"
              />
            </div>

            <div className="pt-md pr-md pl-md">
              <TextBoxComponent
                id="xMeaningName"
                placeholder="Meaning *"
                cssClass="e-outline"
                floatLabelType="Auto"
                data-msg-containerid="xMeaningNameErr"
                name="xMeaningNameErr"
                value={xMeaningName}
                // value={"Performance"}
                // readOnly
                change={(e) => axisMasterFieldChange(e, 'xMeaningName')}
              />
              <div
                id="xMeaningNameErr"
                className="error-div"
              />
            </div>

            <div className="pt-md pb-md ml-md mr-md">
              <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 pb-md ">
                <div
                  className="col-xs-6 col-sm-6 col-lg-6 col-md-6"
                  style={{ padding: 0 }}
                >
                  <NumericTextBoxComponent
                    floatLabelType="Auto"
                    value={xWeightAllocation}
                    onChange={(e) => axisMasterFieldChange(e, 'xWeightAllocation')}
                    min={0}
                    number
                    max={100}
                    decimals={0}
                    validateDecimalOnType={true}
                    id="xWeightAllocation"
                    placeholder="Weight Allocation *"
                    cssClass="e-outline"
                    data-msg-containerid="xWeightAllocationErr"
                    name="xWeightAllocationErr"
                    format={"###"}
                  />
                  <div
                    id="xWeightAllocationErr"
                    className="error-div"
                  />
                </div>
                <div
                  className="col-xs-6 col-sm-6 col-lg-6 col-md-6"
                  style={{ paddingRight: 0 }}
                >
                  <NumericTextBoxComponent
                    floatLabelType="Auto"
                    value={xWeightagedVariable}
                    onChange={(e) => axisMasterFieldChange(e, 'xWeightagedVariable')}
                    min={0}
                    number
                    max={100}
                    decimals={0}
                    validateDecimalOnType={true}
                    id="xWeightagedVariable"
                    placeholder="Weightage Variable *"
                    cssClass="e-outline"
                    data-msg-containerid="xWeightagedVariableErr"
                    name="xWeightagedVariableErr"
                    format={"###"}
                  />
                  <div
                    id="xWeightagedVariableErr"
                    className="error-div"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
          <div className="axis-master-axis-title pl-md pt-md pb-md axis-master-border"
            style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <span>Axis: Y</span>
            <span className="pr-md">
              <i className={`fas fa-chevron-up ${collapse.yCollapsed ? 'fa-rotate-180' : ''}`} style={{ color: '#B7B7BB', cursor: 'pointer', transition: 'transform 0.5s', }}
                onClick={() => handleCollapse('y')}
              ></i>
            </span>
          </div>

          <div style={{ ...(collapse.yCollapsed ? { display: 'none', opacity: 0 } : { display: 'block', opacity: 1 }), transition: "visibility 0.5s, opacity 0.5s" }}>
            <div className="pt-md pr-md pl-md">
              <TextBoxComponent
                id="yAxisName"
                placeholder="Axis Name"
                cssClass="e-outline"
                floatLabelType="Auto"
                data-msg-containerid="yAxisNameErr"
                name="yAxisNameErr"
                // value={yAxisName}
                value={"Y"}
                readOnly
                enabled={false}
                change={(e) => axisMasterFieldChange(e, 'yAxisName')}
              />
              <div
                id="yAxisNameErr"
                className="error-div"
              />
            </div>

            <div className="pt-md pr-md pl-md">
              <TextBoxComponent
                id="yMeaningName"
                placeholder="Meaning *"
                cssClass="e-outline"
                floatLabelType="Auto"
                data-msg-containerid="yMeaningNameErr"
                name="yMeaningNameErr"
                value={yMeaningName}
                // value={"Potential"}
                // readOnly
                change={(e) => axisMasterFieldChange(e, 'yMeaningName')}
              />
              <div
                id="yMeaningNameErr"
                className="error-div"
              />
            </div>

            <div className="pt-md pb-md ml-md mr-md">
              <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 pb-md">
                <div
                  className="col-xs-6 col-sm-6 col-lg-6 col-md-6"
                  style={{ padding: 0 }}
                >
                  <NumericTextBoxComponent
                    floatLabelType="Auto"
                    value={yWeightAllocation}
                    onChange={(e) => axisMasterFieldChange(e, 'yWeightAllocation')}
                    min={0}
                    number
                    max={100}
                    decimals={0}
                    validateDecimalOnType={true}
                    id="yWeightAllocation"
                    placeholder="Weight Allocation *"
                    cssClass="e-outline"
                    data-msg-containerid="yWeightAllocationErr"
                    name="yWeightAllocationErr"
                    format={"###"}
                  />
                  <div
                    id="yWeightAllocationErr"
                    className="error-div"
                  />
                </div>
                <div
                  className="col-xs-6 col-sm-6 col-lg-6 col-md-6"
                  style={{ paddingRight: 0 }}
                >
                  <NumericTextBoxComponent
                    floatLabelType="Auto"
                    value={yWeightagedVariable}
                    onChange={(e) => axisMasterFieldChange(e, 'yWeightagedVariable')}
                    min={0}
                    number
                    max={100}
                    decimals={0}
                    validateDecimalOnType={true}
                    id="yWeightagedVariable"
                    placeholder="Weightage Variable *"
                    cssClass="e-outline"
                    data-msg-containerid="yWeightagedVariableErr"
                    name="yWeightagedVariableErr"
                    format={"###"}
                  />
                  <div
                    id="yWeightagedVariableErr"
                    className="error-div"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 pl-md pr-md pt-md pb-md axis-master-border">
          <ComboBoxComponent
            id="axisMasterFY"
            fields={{ text: "Name", value: "Name" }}
            data-msg-containerid="axisMasterFYErr"
            name="axisMasterFYErr"
            cssClass="e-outline"
            floatLabelType="Auto"
            placeholder="Financial Year *"
            dataSource={finacialYearData}
            value={axisMasterFY}
            change={(e) => axisMasterFieldChange(e, 'axisMasterFY')}
          />
          <div
            id="axisMasterFYErr"
            className="error-div"
          />
        </div>
        {loading && <ProgressBar />}
      </div>
    </>
  );
}

const mapStateToProps = ({ auth, meetingmaster, okrreducer }) => {
  const { finacialYearData } = meetingmaster;
  const { authUser } = auth;
  const { loading } = okrreducer;
  return {
    authUser,
    finacialYearData,
    loading
  };
};
export default connect(mapStateToProps, { saveAxisMaster, updateOKRData })(AxisMasterSidebar);
