/**
 * Author: Vivek Khodade.
 * Last Modify:08-May-2020
 * Modified By:Vivek Khodade
 */
import React, { Component } from "react";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import { connect } from "react-redux";
import "./multiselectionbuttoncomponent.css";
import { saveCandidateData } from "../../../redux/actions/entryexit/onboardingaction";

class MultiSelectionButtonComponent extends Component {
  constructor(props) {
    super(props);
    const { tabData } = this.props;
    this.state = {
      selectAll: false,
      roleMaster: tabData,
      height:
        (window.innerHeight ||
          document.documentElement.clientHeight ||
          document.body.clientHeight) - 188,
      width:
        (window.innerWidth ||
          document.documentElement.clientWidth ||
          document.body.clientWidth) - 71,
    };
  }

  selectAllByCategories = (index, bool) => {
    const { roleMaster } = this.state;
    if (!bool) {
      const categoryData = roleMaster;
      const temp = categoryData;
      categoryData[index].data.map(
        // eslint-disable-next-line
        (item, i) => (temp[index].data[i].selected = true)
      );
      temp[index].all = true;
      this.setState({
        roleMaster: temp,
      });
      this.checkSelectAll();
    } else {
      const categoryData = roleMaster;
      const temp = categoryData;
      categoryData[index].data.map(
        // eslint-disable-next-line
        (item, i) => (temp[index].data[i].selected = false)
      );
      temp[index].all = false;
      this.setState({
        roleMaster: temp,
      });
      this.checkSelectAll();
    }
  };

  onSave = async () => {
    const { roleMaster } = this.state;
    const { tabid, employeeId, authUser } = this.props;
    const finalData = [];
    if (roleMaster.length > 0) {
      // eslint-disable-next-line
      await roleMaster.map((data, i) => {
        const selectedcategoriesId = [];
        // eslint-disable-next-line
        data.data.map((item, index) => {
          if (item.selected) {
            selectedcategoriesId.push(item.id);
          }
        });
        if (selectedcategoriesId.length > 0) {
          finalData.push({
            categoryId: data.categoryId,
            SelectedIds: selectedcategoriesId.toString(),
          });
        }
      });
      if (finalData.length > 0) {
        let employeeIds = "";
        // eslint-disable-next-line
        finalData.forEach((data, i) => {
          if (i > 0) {
            employeeIds = `${employeeIds},${data.SelectedIds}`;
          } else {
            employeeIds += data.SelectedIds;
          }
        });
        const employeeData = {
          onboard_info: employeeIds,
          tabId: tabid,
          employeeId,
          authUserid: authUser,
        };
        // eslint-disable-next-line
        this.props.saveCandidateData(employeeData);
      }
      // else {
      // console.log("Kingly select mandetory fields");
      // alert("Kingly select mandetory fields");
      // getNotify("error","Kingly Select At lest One Fields");
      // }
    }
  };
  /**
* Calculate & Update state of new dimensions
*/
  updateDimensions() {
    let update_width = window.innerWidth - 100;
    let update_height = window.innerHeight - 420;
    this.setState({ width: update_width, height: update_height });
  }
  componentDidMount = async () => {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
  };
  checkSelectAll = () => {
    const { roleMaster } = this.state;
    let select = 0;
    // eslint-disable-next-line
    roleMaster.map((item, index) => {
      if (item.all) {
        // eslint-disable-next-line
        select++;
      }
    });
    if (select === roleMaster.length) {
      this.setState({
        selectAll: true,
      });
    } else {
      this.setState({
        selectAll: false,
      });
    }
  };

  onSelectAll = (bool) => {
    const { roleMaster } = this.state;
    if (!bool) {
      const saveData = roleMaster;
      const temp = saveData;
      // eslint-disable-next-line
      saveData.map((data, i) => {
        // eslint-disable-next-line
        data.data.map((item, index) => {
          temp[i].data[index].selected = true;
        });
        temp[i].all = true;
      });
      this.setState({ roleMaster: temp, selectAll: true });
    } else {
      const saveData = roleMaster;
      const temp = saveData;
      // eslint-disable-next-line
      saveData.map((data, i) => {
        // eslint-disable-next-line
        data.data.map((item, index) => {
          temp[i].data[index].selected = false;
        });
        temp[i].all = false;
      });
      this.setState({ roleMaster: temp, selectAll: false });
    }
  };

  render() {
    const { selectAll, roleMaster } = this.state;
    return (
      <div>
        <div className="col-12 col-xs-12 col-sm-12 col-lg-12 col-md-12 row">
          <div style={{ textAlign: "right", marginTop: 7, marginBottom: 7 }}>
            {/* <div style={{position:'absolute', textAlign:'center', marginLeft:'47.5%', marginTop:'-0.5%'}}><h5><span style={{color:'#EB5465'}}>Work In Progress</span></h5></div> */}
            <Button
              color="primary"
              variant="contained"
              style={{ padding: "0px 0px 0px 0px", margin: "3px 5px 0px 0px" }}
              onClick={() => this.onSave()}
            >
              <TooltipComponent
                closeOn="Click"
                content='Save'
                position="BottomCenter"
                isSticky={false}
                cssClass='customtooltip'
              >
                <span style={{ fontSize: 14 }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;Save &nbsp;&nbsp;&nbsp;&nbsp;
                    </span>
              </TooltipComponent>
            </Button>
            <Button
              className={
                selectAll
                  ? "select-all-button-active"
                  : "select-all-button-deactive"
              }
              style={{
                padding: "0px 0px 0px 0px",
                margin: "3px 5px 0px 0px",
                border: "1px solid",
              }}
              onClick={() => this.onSelectAll(selectAll)}
            >
              <TooltipComponent
                closeOn="Click"
                content='Save All'
                position="BottomCenter"
                isSticky={false}
                cssClass='customtooltip'
              >
                <span style={{ fontSize: 14 }}>
                  SELECT ALL
                    </span>
              </TooltipComponent>
            </Button>
          </div>
        </div>
        <div className="col-12 col-xs-12 col-sm-12 col-lg-12 col-md-12">
          <div className="multiselectionbuttoncomponent-width row"
            style={{ height: this.state.height, overflowY: 'auto', overflowX: 'hidden', width: '101%', paddingBottom: 20 }}
          >
            {/* <div className="scroll-initiate force-overflow multiselectionbuttoncomponent-width row"> */}
            {roleMaster.map((data, i) => (
              <div
                // eslint-disable-next-line
                key={i}
                className="col-3 col-xs-3 col-sm-3 col-lg-3 col-md-3"
              >
                <div className="button-sections-width">
                  <div
                    className="button-header-width row"
                    style={{ marginLeft: "0px" }}
                  >
                    <div
                      style={{ float: "left", textAlign: "center", padding: 5 }}
                    >
                      {data.categoryName}
                    </div>
                    <div style={{ textAlign: "right" }}>
                      <Button
                        // eslint-disable-next-line
                        key={i}
                        className={
                          data.all
                            ? "all-via-categories-select"
                            : "all-via-categories-unselect"
                        }
                        style={{
                          padding: "0px 0px 0px 0px",
                          margin: "3px 2px 3px 0px",
                          border: "1px solid",
                        }}
                        // title="Select All by category"
                        onClick={() => this.selectAllByCategories(i, data.all)}
                      >
                        <TooltipComponent
                          closeOn="Click"
                          content="Select All by category"
                          position="BottomCenter"
                          isSticky={false}
                          cssClass='customtooltip'
                        >
                          <span style={{ fontSize: 14 }}>
                            ALL
                        </span>
                        </TooltipComponent>
                      </Button>
                    </div>
                  </div>
                  <div style={{ height: 210, overflowY: "auto" }}>
                    {data.data.map((item, index) => (
                      // eslint-disable-next-line
                      <div
                        key={item.id}
                        className={
                          item.selected
                            ? "button-selected-width row"
                            : "button-unselected-width row"
                        }
                        onClick={() => {
                          const temp = roleMaster[i].data;
                          temp[index].selected = !temp[index].selected;
                          const totalLen = temp.length;
                          const selectedItemslen = temp.filter(
                            (t) => t.selected
                          );
                          if (totalLen === selectedItemslen.length) {
                            roleMaster[i].all = true;
                          } else {
                            roleMaster[i].all = false;
                          }
                          this.setState({
                            roleMaster,
                          });
                          this.checkSelectAll();
                        }}
                      >
                        <div
                          style={{
                            float: "left",
                            textAlign: "center",
                            padding: 5,
                            letterSpacing: 0.6
                          }}
                        >
                          {item.buttonText}
                        </div>
                        <div style={{ textAlign: "right" }} />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}
MultiSelectionButtonComponent.propTypes = {
  tabData: PropTypes.arrayOf(PropTypes.object).isRequired,
  authUser: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  tabid: PropTypes.number.isRequired,
  employeeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};
MultiSelectionButtonComponent.defaultProps = {
  authUser: "",
};
const mapStateToProps = ({ auth }) => {
  const { authUser } = auth;
  return { authUser };
};
export default connect(mapStateToProps, {
  saveCandidateData,
})(MultiSelectionButtonComponent);
