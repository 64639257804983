/**
 * Author By :- Vivek Khodade
 * Date : 27-05-2020
 * Description :- This file use for load saperation resigantion components
 */

import React, { Component } from "react";
import { AccordionComponent } from "@syncfusion/ej2-react-navigations";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import NotificationContainer from "../../../../../components/notification/notifycontainer";
import BasicInfoResignation from '../../../../../components/separation/resignationcomponent/basicinforesignation';
import Status from '../../../../../components/separation/resignationcomponent/status';
import ReasonForResignation from '../../../../../components/separation/resignationcomponent/reasonforresignation';
import FooterButtons from './../../../../../components/separation/footerbuttons/footerbuttons';
class Resignation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      height:
        (window.innerHeight ||
          document.documentElement.clientHeight ||
          document.body.clientHeight) - 188,
      width:
        (window.innerWidth ||
          document.documentElement.clientWidth ||
          document.body.clientWidth) - 71,
    }
    this.onCreated = this.onCreated.bind(this);
  }

  onCreated() {
    const { employeeStatus,clearanceInitiated } = this.props;
    setTimeout(() => { // NOSONAR
      if (this.acrdnbasic !== null) {
        // eslint-disable-next-line
        for (let i = 0; i <= this.acrdnbasic.items.length - 1; i++) {
          this.acrdnbasic.items[i].expanded = true;
        }
      }
      if (this.acrdnResignationcomponent !== null) {
        // eslint-disable-next-line
        for (let i = 0; i <= this.acrdnResignationcomponent.items.length - 1; i++) {
          this.acrdnResignationcomponent.items[i].expanded = true;
        }
      }
      if (this.acrdnResignatioStaus !== null && employeeStatus === "Resigned") {
        // eslint-disable-next-line
        for (let i = 0; i <= this.acrdnResignatioStaus.items.length - 1; i++) {
          this.acrdnResignatioStaus.items[i].expanded = true;
        }
      }
      if (this.acrdnClearanceStatus !== null && clearanceInitiated==="Y") {
        // eslint-disable-next-line
        for (let i = 0; i <= this.acrdnClearanceStatus.items.length - 1; i++) {
          this.acrdnClearanceStatus.items[i].expanded = true;
        }
      }
      if (this.acrdnWithdrawalStatus !== null && employeeStatus === "Resignation - Withdrawal") {
        // eslint-disable-next-line
        for (let i = 0; i <= this.acrdnWithdrawalStatus.items.length - 1; i++) {
          this.acrdnWithdrawalStatus.items[i].expanded = true;
        }
      }
    }, 500);
  }
  /**
* Calculate & Update state of new dimensions
*/
  updateDimensions() {
    let update_width = window.innerWidth - 100;    
    let update_height = window.innerHeight - 110;
    this.setState({ width: update_width, height: update_height });
  }
  componentDidMount = async () => {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
  };
  render() {
    const { employeeStatus, clearanceInitiated } = this.props;
    return (
      <div>
        <div
          style={{ padding: 10, backgroundColor: "#F4F3F5", height: this.state.height, overflowY: 'auto' }}
        // className='resignation-scroll'
        >
          <NotificationContainer />
          <div>
            <AccordionComponent
              ref={(accordion) => {
                this.acrdnbasic = accordion;
              }}
              created={this.onCreated}
            >
              <BasicInfoResignation />
            </AccordionComponent>
          </div>
          <div style={{ marginTop: 10 }}>
            <AccordionComponent
              ref={(accordion) => {
                this.acrdnResignationcomponent = accordion;
              }}
              created={this.onCreated}
            >
              <ReasonForResignation />
            </AccordionComponent>
          </div>
          {
            employeeStatus === "Resigned" &&
            <div style={{ marginTop: 10 }}>
              <AccordionComponent
                ref={(accordion) => {
                  this.acrdnResignatioStaus = accordion;
                }}
                created={this.onCreated}
              >
                <Status title={"RESIGNATION STATUS"} />
              </AccordionComponent>
            </div>
          }
        { 
          clearanceInitiated === 'Y'&&
          <div style={{marginTop:10}}>
          <AccordionComponent
            ref={(accordion) => {
              this.acrdnClearanceStatus = accordion;
            }}
            created={this.onCreated}
          >
            <Status title={"CLEARANCE STATUS"}/>
          </AccordionComponent>
          </div> 
        }        
          {
            employeeStatus === "Resignation - Withdrawal" &&
            <div style={{ marginTop: 10, }}>
              <AccordionComponent
                ref={(accordion) => {
                  this.acrdnWithdrawalStatus = accordion;
                }}
                created={this.onCreated}
              >
                <Status title={"RESIGNATION WITHDRAWAL STATUS"} />
              </AccordionComponent>
            </div>
          }
        </div>
        <FooterButtons />
      </div>
    );
  }
}
Resignation.propTypes = {
  employeeStatus: PropTypes.string,
};
Resignation.defaultProps = {
  employeeStatus: "",

};
const mapStateToProps = ({ separationreducer }) => {
  const { employeeStatus, clearanceInitiated } = separationreducer;
  return { employeeStatus, clearanceInitiated };
};

export default connect(mapStateToProps, {})(Resignation);
