import React, { useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp, faAngleDown } from "@fortawesome/pro-light-svg-icons";
import "../../app/layout/okrratinglayout/okrrating.css";
import { getOKRQuestionRatingData } from '../../app/other/commonfunction'

import { TooltipComponent } from '@syncfusion/ej2-react-popups';
function PastRatingTableItems(props) {
    const { item, inx } = props;
    const [openQuestion, setOpenQuestion] = useState(false);

    const handleLeadershipQuestionsInfo = () => {
        setOpenQuestion(prevState => !prevState);
    }

    return (
        <tbody className="okr-past-rating-font">
            <tr style={{ textAlign: 'center' }} key={`tablerow_${inx}`}>
                <td style={{ textAlign: 'left' }}>
                    <div style={{ display: "flex" }}>
                        <div style={{ marginLeft: ".5rem", opacity: item.leadershipQuestions?.length > 0 ? '1' : 0 }}>
                            <FontAwesomeIcon
                                style={{ color: '#939399', fontSize: 20, cursor: item.leadershipQuestions?.length > 0 ? "pointer" : '' }}
                                icon={openQuestion ? faAngleUp : faAngleDown}
                                onClick={handleLeadershipQuestionsInfo}
                            />
                        </div>
                        <div style={{ marginLeft: "1rem" }}>{item.ratingMonth}</div>
                    </div>
                </td>
                <td>
                    <div>{item.selfRating}</div>
                </td>
                <td className="">
                    <TooltipComponent closeOn="Click" position="BottomCenter" isSticky={false}
                        content={item.selfAchievedRemarks}
                        cssClass='customtooltip-past'>
                        <div className="text-overflow-css-past">{item.selfAchievedRemarks}</div>
                    </TooltipComponent>
                </td>
                <td><div>{item.supRating}</div></td>
                <td className="">
                    <TooltipComponent closeOn="Click" position="BottomCenter" isSticky={false}
                        content={item.supAchievedRemarks}
                        cssClass='customtooltip-past'>
                        <div className="text-overflow-css-past">{item.supAchievedRemarks}</div>
                    </TooltipComponent>
                </td>
                <td><div> {item.selfSupSync === 'Y' && <span className='past-review-yes'>{item.selfSupSync}</span>} {item.selfSupSync === 'N' && <span className='past-review-no'>{item.selfSupSync}</span>}</div></td>
                <td><div>{item.finalRating}</div></td>
                <td className="">
                    <TooltipComponent closeOn="Click" position="BottomCenter" isSticky={false}
                        content={item.finalRemarks}
                        cssClass='customtooltip-past'>
                        <div className="text-overflow-css-past">{item.finalRemarks}</div>
                    </TooltipComponent>
                </td>
                <td><div>{item.achieved === "Y" && <span>Yes</span>} {item.achieved === "N" && <span>No</span>}</div></td>
            </tr>
            {openQuestion && item.leadershipQuestions.length > 0 && <tr>
                <td colSpan={12} style={{ padding: 0, textAlign: "left" }}>
                    <table id="past-rating-leadership-questions" style={{ border: "none" }}>
                        <tr>
                            <th>Questionnaires</th>
                            <th>Self Declaration</th>
                            <th>Supervisor Declaration</th>
                            <th>Final Declaration</th>
                        </tr>
                        {item.leadershipQuestions.length > 0 && item.leadershipQuestions.map((questionArr, id) => (
                            <tr>
                                <td>{questionArr.question}</td>
                                <td>{getOKRQuestionRatingData(questionArr.selfRating)}</td>
                                <td>{getOKRQuestionRatingData(questionArr.supervisorRating)}</td>
                                <td>{getOKRQuestionRatingData(questionArr.finalRating)}</td>
                            </tr>
                        ))}
                    </table>
                </td>
            </tr>}
        </tbody>
    )
}

export default PastRatingTableItems;