import React, { Component } from 'react';
import { Button } from "@material-ui/core";
import { connect } from 'react-redux'
import RatingKR from './ratingkr';
import ReviewKR from './reviewkr'
import ViewkrModel from './viewkrmodal'
import OKRRatingServices from '../../services/okrrating/okrratingservices'
import AddRatingModalkr from './addratingmodalkr'
import { addUpdateOkrRatingRedux, submitOKRRating } from '../../redux/actions/okrrating/okrratingaction'
import ViewRatingModalKr from './viewratingmodalkr'
import { getNotify } from "../../app/other/commonfunction";
import { ERROR, OKRRatingSubmitWarning, OKRRatingSubmitedMessage, OKRFinalRatingSubmitWarning } from "../../assets/constants/constants";
import PastRatingModal from './pastratingmodal'
import AddEditInterimReviewModal from './addeditinterimreviewmodal'
import ViewkrInterimFeedbackModel from './viewkrinterimfeedbackmodel'
import InterimFeedBack from './interimfeedback'
import ConfirmationPopup from '../../utilities/confirmationpopup';
const cssForScroll = `
::-webkit-scrollbar {
    width: 24px;
  }

  ::-webkit-scrollbar-thumb {
    background: white;
    border-right: 13px #8080804f  solid;
    background-clip: padding-box;
  }
`
class RatingKRContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            height: null,
            windowAdjust: null,
            viewKrDetailsPopup: false,
            KRData: {},
            viewKrRatingPopup: false,
            viewLpRatingPopup: false,
            ratingType: "",
            actualKRData: {},
            reviewKRData: [],
            viewReviewKrPopup: false,
            KRReviewData: {},
            confirmDialog: false,
            ratingSubmitted: false,
            pastRatingPopup: false,
            pastRatingKRData: {},
            addEditInterimReviewPopup: false,
            viewKrInterimFeedbackPopup: false,
            objId: null,
            selectedColType: null,
            viewInterimReviewPopup: false,
            objName: null,
            objectiveName: '',
            informationMsg: false,
            isHighlightForSubmit: false
        };
        this.updateDimensions = this.updateDimensions.bind(this)
        this.confirmTitle = "";
        this.confirmMessage = "";
    }
    componentDidMount = () => {
        this.refreshContent()
    };

    refreshContent() {
        window.addEventListener("resize", this.updateDimensions());
        setTimeout(() => {
            const { ratingTabs } = this.props;
            if (ratingTabs === 'self-rating' || ratingTabs === 'supervisor-rating' || ratingTabs === 'thirdparty-rating') {
                this.getDashboardRatingData();
            }
            if (ratingTabs === 'okr-review') {
                this.getDashboardReviewData();
            }
        }, 400)
    }

    ratingType = () => {
        const { ratingTabs } = this.props
        let type = ''
        switch (ratingTabs) {
            case "self-rating":
                type = "self"; break;
            case "supervisor-rating":
                type = "sup"; break;
            case "thirdparty-rating":
                type = "tp"; break;
            case "okr-review":
                type = "review"; break;
            default:
        }
        return type;
    }

    okrRatingDashboardDataHelperFunction = (empId, fyId, monthOfRating, authUser, ratingType, OKRType) => {
        const { isHighlightForSubmit } = this.state
        const okrRatingDashboardData = Promise.resolve(
            OKRRatingServices.getDashboardData(authUser, empId, fyId, monthOfRating, OKRType, ratingType, 'details')
        );
        okrRatingDashboardData.then((resultData) => {

            if (resultData.length > 0) {
                if (OKRType === '3') {
                    this.props.addUpdateOkrRatingRedux({ ratingLPOkr: resultData[0] })
                }
                if (OKRType === '4') {
                    this.props.addUpdateOkrRatingRedux({ ratingBrandOkr: resultData[0] })
                }
                const isRatingSubmitted = this.checkSelfSupRatingDone(resultData[0], ratingType)
                this.setState({ ratingSubmitted: isRatingSubmitted })
                if (isHighlightForSubmit) {
                    this.setState({ isHighlightForSubmit: false })
                    this.submitOKRRating(true)
                }
            } else {
                if (OKRType === '3') {
                    this.props.addUpdateOkrRatingRedux({ ratingLPOkr: {} })
                }
                if (OKRType === '4') {
                    this.props.addUpdateOkrRatingRedux({ ratingBrandOkr: {} })
                }
                this.setState({ ratingSubmitted: false })
            }
        });
    }

    getDashboardRatingData = () => {
        const { empId, fyId, monthOfRating, authUser, ratingTabs, OKRType } = this.props;
        let ratingType = '';
        if (ratingTabs === 'self-rating') {
            ratingType = 'self';
        } else if (ratingTabs === 'supervisor-rating') {
            ratingType = 'sup';
        } else if (ratingTabs === 'thirdparty-rating') {
            ratingType = 'tp';
        } else if (ratingTabs === 'okr-review') {
            ratingType = 'review';
        }
        try {
            if (ratingType !== '') {
                this.okrRatingDashboardDataHelperFunction(empId, fyId, monthOfRating, authUser, ratingType, OKRType);
            }
        } catch (err) {
            console.log("OKRRatingServices", err)
        }
    }
    getDashboardReviewData = () => {
        const { empId, fyId, monthOfRating, authUser, OKRType } = this.props;
        try {
            const okrRatingDashboardData = Promise.resolve(
                OKRRatingServices.getReviewDashboardData(authUser, empId, fyId, monthOfRating, OKRType, "rating")
            );
            okrRatingDashboardData.then((resultData) => {
                if (OKRType === '3') {
                    this.props.addUpdateOkrRatingRedux({ reviewLPOkr: resultData })
                }
                if (OKRType === '4') {
                    this.props.addUpdateOkrRatingRedux({ reviewBrandOkr: resultData })
                }
                const isRatingSubmitted = this.checkSelfSupRatingDone(resultData[0], 'review')
                this.setState({ ratingSubmitted: isRatingSubmitted })
                this.setState({ isHighlightForSubmit: false })
                this.submitOKRRating(true)
            });
        } catch (err) {
            console.log("OKRRatingServices", err)
        }
    }

    checkSelfSupRatingDone(pOKRData, ratingType) {
        try {
            // check the self / Supervisor rating are done
            if (typeof pOKRData !== 'undefined') {
                let filterdData = false;
                if (ratingType === 'sup')
                    return pOKRData.supRatingSubmitted === "Y"
                else if (ratingType === 'self')
                    return pOKRData.selfRatingSubmitted === "Y"
                else if (ratingType === 'review')
                    return pOKRData.reviewSubmitted === "Y"

                return filterdData
            }
            return false
        } catch (error) {

        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        const { showStatus, refreshChildVar } = this.props;
        if (refreshChildVar !== prevProps.refreshChildVar) {
            if (this.submitOKRRating(true)) {
                // this.callActiveTab();
            }
        }
        if (showStatus !== prevProps.showStatus) {
            this.updateDimensions()
        }
    };
    updateDimensions() {
        const updateheight = window.innerHeight - 430;
        const windowAdjust = window.innerHeight - 338;
        this.setState({
            height: updateheight,
            windowAdjust: windowAdjust,
        });
    }
    opneKrDetailsPopup = (data) => {
        this.setState({ viewKrDetailsPopup: true, KRData: data })
    }
    closeKrDetailsPopup = () => {
        this.setState({ viewKrDetailsPopup: false, KRData: {} })
    }
    openKrRatingPopup = (data, ratingType) => {
        this.setState({ viewKrRatingPopup: true, KRData: data, ratingType })
    }
    closeKrRatingPopup = () => {
        this.setState({ viewKrRatingPopup: false })
    }

    openLpRatingPopup = (data, ratingType) => {

        this.setState({ viewLpRatingPopup: true, KRData: data, ratingType })
    }
    closeLpRatingPopup = () => {

        this.setState({ viewLpRatingPopup: false })
    }

    componentWillReceiveProps(nextProps) {//NOSONAR
        const { OKRType, LPOKRCounter, ratingTabs, ratingStatusData } = this.props
        if (
            (nextProps.LPOKRCounter !== LPOKRCounter
                && OKRType === '3'
                && typeof nextProps.LPOKRCounter !== 'undefined'
                && LPOKRCounter !== 'undefined'
            )
            ||
            nextProps.refreshRatingDataFlag === 'ratingSubmitted' + OKRType
        ) {
            setTimeout(() => {
                if (ratingTabs === 'okr-review') {
                    this.getDashboardReviewData()
                } else {
                    this.getDashboardRatingData();
                }
            }, 10)
            if (nextProps.refreshRatingDataFlag === 'ratingSubmitted' + OKRType) {
                this.props.addUpdateOkrRatingRedux({ refreshRatingDataFlag: 'updateSummary' })
                let OKRTypeName = '';
                switch (OKRType) {
                    case "1": OKRTypeName = "Business OKR"; break;
                    case "2": OKRTypeName = "Central OKR"; break;
                    case "3": OKRTypeName = "Leadership Principles"; break;
                    default: OKRTypeName = ''
                }
                let pendingAllocations = '';
                if (ratingStatusData.length > 0) {
                    ratingStatusData[0].Allocations.forEach(item => {
                        if (item.allocationStatus === 'Pending' && OKRTypeName !== item.allocationName) {
                            pendingAllocations = pendingAllocations + item.allocationName + ', '
                        }
                    })
                }
                this.confirmTitle = "Information"
                if (pendingAllocations !== '') {
                    this.confirmMessage = "Thanks for submitting your inputs. Please complete the inputs for " +
                        pendingAllocations.substr(0, pendingAllocations.length - 2) + " as well.";
                } else {
                    this.confirmMessage = "Thanks for completing your inputs on the OKR Rating module.";
                }
                this.setState({ informationMsg: true })
            }
        }
        if (nextProps.refreshRatingDataFlag === 'ratingUpdated' + OKRType) {
            this.setState({ isHighlightForSubmit: true }, () => {
                this.closeKrRatingPopup();
                this.getDashboardRatingData();
                this.props.addUpdateOkrRatingRedux({ refreshRatingDataFlag: '' })
            })

        }
        if (nextProps.refreshRatingDataFlag === 'reviewUpdate' + OKRType) {
            this.closeKrRatingPopup();
            this.getDashboardReviewData();
            this.props.addUpdateOkrRatingRedux({ refreshRatingDataFlag: '' })
        }
        if (nextProps.refreshInterimDataFlag === "interimUpdated" + OKRType) {
            this.closeAddEditIntrimFeedbackPopup();
            this.getDashboardRatingData();
            this.props.addUpdateOkrRatingRedux({ refreshInterimDataFlag: "" });
        }
        if ((nextProps.ratingCategoryStatus === 'updated' + OKRType) && nextProps.ratingCategoryStatus === 'updated3') {
            this.props.addUpdateOkrRatingRedux({ ratingCategoryStatus: '' })
            setTimeout(() => {
                this.refreshContent()
            }, 300)
        }
    }
    viewKrReviewPopup = (data, type) => {
        this.setState({
            KRReviewData: data,
            viewReviewKrPopup: true,
            type: type
        })
    }
    closeKrReviewPopup = () => {
        this.setState({
            KRReviewData: {},
            viewReviewKrPopup: false,
            type: ''
        })
    }

    ratingType = () => {
        const { ratingTabs } = this.props
        let type;
        switch (ratingTabs) {
            case "self-rating":
                type = "self"; break;
            case "supervisor-rating":
                type = "sup"; break;
            case "thirdparty-rating":
                type = "tp"; break;
            case "okr-review":
                type = "review"; break;
            default:
        }
        return type;
    }

    submitOKRRating = (isHighlightForSubmit) => {
        try {
            const { OKRType, ratingLPOkr, ratingBrandOkr, reviewLPOkr, reviewBrandOkr } = this.props
            if (OKRType === '3' || OKRType === '4') {
                let checkBool;
                if (this.ratingType() === 'review')
                    checkBool = this.checkActualRatingDone(OKRType === '3' ? reviewLPOkr[0] : reviewBrandOkr[0], isHighlightForSubmit)
                else
                    checkBool = this.checkActualRatingDone(OKRType === '3' ? ratingLPOkr : ratingBrandOkr, isHighlightForSubmit)
                if (checkBool && !isHighlightForSubmit) {
                    this.openConfirmDialog("Submit OKR Ratings")
                }
            }
        } catch (error) {
            console.log(error, 'submitOKRRating')
        }
    }
    checkActualRatingDone(pOKRData, isHighlightForSubmit) {//NOSONAR
        try {
            const { OKRType } = this.props;
            // check all the actual ratings are updated
            if (typeof pOKRData !== 'undefined') {
                const ratingType = this.ratingType()
                let filterdData = 0;
                if (ratingType === 'review') {
                    filterdData = pOKRData.krData.filter(subItem => { return subItem.krFinalRating.Rating === "" }).length
                } else if (ratingType === 'self') {
                    filterdData = pOKRData.krData.filter(subItem => { return subItem.krSelfRecommendationRating.Rating === "" }).length
                } else if (ratingType === 'sup') {
                    filterdData = pOKRData.krData.filter(subItem => { return subItem.krSupervisorRecommendationRating.Rating === "" }).length
                }
                if (isHighlightForSubmit) {
                    if (filterdData === 0) {
                        setTimeout(() => { window.submitRating(`submitKRRating${OKRType}`, OKRType, ratingType) }, 0)
                    }
                } else {
                    if (filterdData > 0) {
                        getNotify(ERROR, ratingType !== 'review' ? OKRRatingSubmitWarning : OKRFinalRatingSubmitWarning)
                        return false
                    }
                }
            }
            return true
        } catch (error) {

        }
    }

    handleConfirmClose = () => {
        this.setState({ confirmDialog: false })
        this.confirmTitle = ''
        this.confirmMessage = ""
    };

    handleConfirmNo = () => {
        this.handleConfirmClose();
    };

    openConfirmDialog = (title) => {
        this.confirmTitle = title
        if (title === "Submit OKR Ratings") {
            this.confirmMessage = "Once submitted the inputs cannot be edited. Are you sure, you want to submit your inputs?"
        }
        this.setState({ confirmDialog: true })
    }

    handleConfirmYesSubmitOKR = () => {
        const { empId, fyId, monthOfRating, authUser, OKRType } = this.props;
        const submitData = {
            userId: authUser,
            empId: empId,
            fyId: fyId,
            mnth: monthOfRating,
            OKRType,
            ratingReviewType: this.ratingType()
        }
        this.props.submitOKRRating(submitData)
        this.setState({ confirmDialog: false })
    }

    handleConfirmYesTitleInformation = () => {
        const { ratingStatusData, OKRType } = this.props;
        let OKRTypeName = '';
        switch (OKRType) {
            case "1": OKRTypeName = "Business OKR"; break;
            case "2": OKRTypeName = "Central OKR"; break;
            case "3": OKRTypeName = "Leadership Principles"; break;
            default: OKRTypeName = ''
        }
        let pendingAllocations = '';
        if (ratingStatusData.length > 0) {
            ratingStatusData[0].Allocations.forEach(item => {
                if (item.allocationStatus === 'Pending' && OKRTypeName !== item.allocationName) {
                    if (pendingAllocations === '')
                        pendingAllocations = item.allocationName;
                }
            })
        }
        if (pendingAllocations !== '') {
            this.props.addUpdateOkrRatingRedux({ pendingOKRs: pendingAllocations })
        }
        this.setState({ informationMsg: false })
    };

    handleConfirmYes = () => {
        try {
            if (this.confirmTitle === "Submit OKR Ratings") {
                this.handleConfirmYesSubmitOKR();
            } else if (this.confirmTitle === "Information") {
                this.handleConfirmYesTitleInformation();
            }
        } catch (error) {
        }
    }
    openPastRatingPopup = (data) => {
        this.setState({ pastRatingPopup: true, pastRatingKRData: data })
    }
    closePastRatingPopup = () => {
        this.setState({ pastRatingPopup: false, pastRatingKRData: {} })
    }
    openAddEditIntrimFeedbackPopup = (data,) => {
        this.setState({
            addEditInterimReviewPopup: true,
            KRData: data,

        });
    };
    closeAddEditIntrimFeedbackPopup = () => {
        this.setState({
            addEditInterimReviewPopup: false,
            KRData: {},
        });
    };
    openKrIntrimFeedbackPopup = (data, userObjectiveId, objectiveName) => {
        this.setState({
            viewKrInterimFeedbackPopup: true,
            KRData: data,
            objId: userObjectiveId,
            objectiveName: objectiveName
        });
    };
    closeKrIntrimFeedbackPopup = () => {
        this.setState({
            viewKrInterimFeedbackPopup: false,
            KRData: {},
            objectiveName: ''
        });
    };

    openViewIntrimFeedbackPopup = (data, userObjectiveId, selectedType, isDraftClick) => {
        this.setState({
            KRData: data,
            viewInterimReviewPopup: true,
            objId: userObjectiveId,
            selectedType: selectedType
        });
        this.props.addUpdateOkrRatingRedux({ krData: data, interimType: selectedType, isDraftClick: isDraftClick })
    };
    closeViewIntrimFeedbackPopup = () => {
        this.setState({
            viewInterimReviewPopup: false,
            objId: null,
            selectedType: null,
            KRData: {}
        });
        this.props.addUpdateOkrRatingRedux({ krData: {}, interimType: "", isDraftClick: null })

    };

    render() {//NOSONAR
        const { height, windowAdjust, viewKrRatingPopup, KRData, ratingSubmitted, confirmDialog, viewLpRatingPopup,
            viewReviewKrPopup, KRReviewData, type, pastRatingPopup, pastRatingKRData, addEditInterimReviewPopup, viewKrInterimFeedbackPopup, objId, viewInterimReviewPopup, objectiveName, selectedType
            , informationMsg } = this.state;
        const { ratingTabs, OKRType, ratingLPOkr, reviewLPOkr, ratingStatusData, showStatus, authUser, empId } = this.props;
        let OKRAllocationStatus = "";

        if (ratingStatusData.length > 0) {
            ratingStatusData[0].Allocations.forEach(item => {
                if (item.allocationName.includes('Leadership Principle') && OKRType === '3') {
                    OKRAllocationStatus = item.allocationStatus
                }
                if (item.allocationName.includes('Brand Values') && OKRType === '4') {
                    OKRAllocationStatus = item.allocationStatus
                }
            })
        }
        let totalKRs = 0;
        if (ratingTabs === 'okr-review') {
            if (document.getElementById('lpOkrCount') !== null) {
                setTimeout(() => {
                    if (reviewLPOkr.length > 0)
                        document.getElementById('lpOkrCount').innerHTML = `(${reviewLPOkr[0].krData !== undefined && reviewLPOkr[0].krData.length > 0 ? reviewLPOkr[0].krData.length : 0})`;
                    else
                        document.getElementById('lpOkrCount').innerHTML = "(0)"
                }, 100)
            }
            totalKRs = Object.keys(reviewLPOkr).length;
        }
        else {
            if (document.getElementById('lpOkrCount') !== null) {
                setTimeout(() => {
                    if (ratingLPOkr.krData !== null)
                        document.getElementById('lpOkrCount').innerHTML = `(${ratingLPOkr.krData !== undefined && ratingLPOkr.krData.length > 0 ? ratingLPOkr.krData.length : 0})`;
                    else
                        document.getElementById('lpOkrCount').innerHTML = "(0)"
                }, 100)
            }
            totalKRs = Object.keys(ratingLPOkr).length;
        }
        let showButtonForSubmit = true;
        if (authUser === empId && ratingTabs == 'okr-review') {
            showButtonForSubmit = false
        }
        if(ratingStatusData.length > 0 && ratingStatusData[1].Ratings[0].allocationStatus === "Pending" && ratingTabs === 'supervisor-rating'){
            // showButtonForSubmit = false
        }

        let isRatingEndDateCompleted = "N";
        if (document.getElementById('lpOkrCount') !== null) {
            if (ratingTabs === 'okr-review') {
                if (reviewLPOkr?.length > 0) {
                    isRatingEndDateCompleted = reviewLPOkr[0]?.isRatingEndDateCompleted
                }
            } else {
                if (ratingLPOkr?.krData !== null) {
                    isRatingEndDateCompleted = ratingLPOkr.isRatingEndDateCompleted
                }
            }
        }
        return (
            <div>
                <style>{cssForScroll} </style>
                <div
                    style={{ height: showStatus ? height : windowAdjust, overflowY: 'auto' }}
                >                {/* Rating Componets load */}
                    {
                        (ratingTabs === 'self-rating' || ratingTabs === 'supervisor-rating' || ratingTabs === 'thirdparty-rating') &&
                        ratingStatusData !== null &&
                        typeof ratingStatusData !== 'undefined' &&
                        ratingStatusData.length > 0 &&
                        <RatingKR ratingData={ratingLPOkr}
                            ratingTabs={ratingTabs}
                            OKRType={OKRType}
                            openKrRatingPopup={this.openKrRatingPopup}
                            openLpRatingPopup={this.openLpRatingPopup}
                            openPastRatingPopup={this.openPastRatingPopup}
                            openAddEditIntrimFeedbackPopup={this.openAddEditIntrimFeedbackPopup}
                            openKrIntrimFeedbackPopup={this.openKrIntrimFeedbackPopup}
                            openViewIntrimFeedbackPopup={this.openViewIntrimFeedbackPopup}
                            OKRAllocationStatus={OKRAllocationStatus}
                            viewKrReviewPopup={this.viewKrReviewPopup}
                        />
                    }
                    {/* Review Components load */}
                    {
                        (ratingTabs === 'okr-review') && (reviewLPOkr.length > 0) &&
                        ratingStatusData !== null &&
                        typeof ratingStatusData !== 'undefined' &&
                        ratingStatusData.length > 0 &&
                        <ReviewKR reviewData={reviewLPOkr} ratingTabs={ratingTabs}
                            viewKrReviewPopup={this.viewKrReviewPopup}
                            openKrRatingPopup={this.openKrRatingPopup}
                            openPastRatingPopup={this.openPastRatingPopup}
                            openAddEditIntrimFeedbackPopup={this.openAddEditIntrimFeedbackPopup}
                            openKrIntrimFeedbackPopup={this.openKrIntrimFeedbackPopup}
                            openViewIntrimFeedbackPopup={this.openViewIntrimFeedbackPopup}
                            OKRAllocationStatus={OKRAllocationStatus}
                            openLpRatingPopup={this.openLpRatingPopup}
                        />
                    }
                    {
                        viewKrRatingPopup &&
                        <AddRatingModalkr
                            closeKrRatingPopup={this.closeKrRatingPopup}
                            OKRType={OKRType}
                            ratingTabs={ratingTabs}
                            KRData={KRData}
                        />
                    }
                    {
                        viewReviewKrPopup &&
                        <ViewRatingModalKr
                            OKRType={OKRType}
                            KRReviewData={KRReviewData}
                            closeKrReviewPopup={this.closeKrReviewPopup}
                            type={type}
                        />
                    }
                    {/* />} */}
                    <ConfirmationPopup
                        showButton={{ type: "1" }}
                        open={confirmDialog}
                        button1function={() => this.handleConfirmYes()}
                        button2function={() => this.handleConfirmNo()}
                        headerMessage={this.confirmMessage}
                        button1Text={"Yes"}
                button2Text={"No"}
                handleConfirmClose={this.handleConfirmClose}
                    /> 

                    {ratingSubmitted && OKRAllocationStatus === 'Pending' &&
                        <div style={{ float: 'right', marginRight: 20, padding: '5px 0px 5px 0px', }}>
                            {OKRRatingSubmitedMessage}
                        </div>
                    }
                    {
                        viewLpRatingPopup &&
                        <ViewkrModel closeLpRatingPopup={this.closeLpRatingPopup} KRData={KRData} />
                    }
                    {
                        pastRatingPopup &&
                        <PastRatingModal
                            OKRType={OKRType}
                            KRData={pastRatingKRData}
                            closePastRatingPopup={this.closePastRatingPopup}
                        />
                    }
                    {addEditInterimReviewPopup && (
                        <AddEditInterimReviewModal
                            closeAddEditIntrimFeedbackPopup={this.closeAddEditIntrimFeedbackPopup}
                            type={"Add"}
                            KRData={KRData}
                            OKRType={OKRType}
                            ratingTabs={ratingTabs}
                        />
                    )}
                    {viewKrInterimFeedbackPopup &&
                        <ViewkrInterimFeedbackModel
                            KRData={KRData}
                            objId={objId}
                            OKRType={OKRType}
                            ratingTabs={ratingTabs}
                            closeKrIntrimFeedbackPopup={this.closeKrIntrimFeedbackPopup}
                            objectiveName={objectiveName}
                        />
                    }
                    {viewInterimReviewPopup &&
                        <InterimFeedBack
                            closeViewIntrimFeedbackPopup={this.closeViewIntrimFeedbackPopup}
                            ratingTabs={ratingTabs}
                            OKRType={OKRType}
                            KRData={KRData}
                            objId={objId}
                            selectedType={selectedType}
                        />}

                    {
                        informationMsg &&
                        <>
                            <ConfirmationPopup
                                showButton={{ type: "1" }}
                                open={informationMsg}
                                button1function={() => this.handleConfirmYes()}
                                button2function={() => this.handleConfirmNo()}
                                headerMessage={this.confirmMessage}
                                button1Text={"OK"}
                    button2Text={""}
                    handleConfirmClose={this.handleConfirmClose}
                            />  
                        </>
                    }
                </div>
                {!ratingSubmitted && OKRAllocationStatus === 'Pending' && totalKRs > 0 && showButtonForSubmit &&
                    <>
                        <div
                            // style={{ float: 'right', marginRight: 20, padding: '5px 0px 5px 0px', }}
                            style={{ display: 'flex', justifyContent: 'flex-end', margin: '.6em 1.2em' }}
                        >
                            <Button
                                style={{ width: "120px", color: isRatingEndDateCompleted === "N" ? "#fff" : "#c1baba" }}
                                className="remove-button-shadow"
                                variant="contained"
                                id={`submitKRRating${OKRType}`}
                                onClick={(e) => this.submitOKRRating(false)}
                                color={isRatingEndDateCompleted === "N" ? "primary" : "default"}
                                disabled={isRatingEndDateCompleted === "Y"}
                            >
                                Submit
                            </Button>
                        </div>
                    </>}
            </div>
        )
    }
}

const mapStateToProps = ({ okrratingreducer, auth }) => {
    const { authUser } = auth;
    const { empId, fyId, monthOfRating, refreshRatingDataFlag, ratingLPOkr, reviewLPOkr, ratingBrandOkr,
        refreshInterimDataFlag, ratingStatusData, ratingCategoryStatus, ratingTabs } = okrratingreducer;
    return {
        empId, fyId, monthOfRating, authUser, refreshRatingDataFlag, ratingLPOkr, reviewLPOkr, ratingBrandOkr,
        refreshInterimDataFlag, ratingStatusData, ratingCategoryStatus, ratingTabs
    }
}
export default connect(mapStateToProps, { addUpdateOkrRatingRedux, submitOKRRating })(
    RatingKRContainer
);