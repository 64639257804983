/* eslint-disable no-unused-expressions */
/**
 * @author Vivek Khodade 
 * @description Apply Leave Form
 * Last Modify Date : 18-12-2023
 * Modify by Chandrashekhar Bhagat.
 */
import React, { useRef, useEffect, useState } from 'react'
import { faClock } from "@fortawesome/pro-light-svg-icons";
import { ComboBoxComponent } from "@syncfusion/ej2-react-dropdowns";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { FormValidator } from "@syncfusion/ej2-inputs";
import { connect } from 'react-redux'
import { cracterLimit } from '../../../../app/other/commonfunction';
import { updateLnAData, applyLeave, saveRegularizationData } from '../../../../redux/actions/lna/lnaaction';
import Leavetabs from '../leavesbalance/leavetabs';
import { library } from "@fortawesome/fontawesome-svg-core";
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import SwipeDetails from '../swipe/regularizationswipe';
import NotificationContainer from "../../../../components/notification/notifycontainer";
import UploadDocuments from '../../../okrratingcomponents/uploaddocuments';
import RegularizationGrid from './regularizationgrid';
import { getLnAData } from '../../../../app/other/commonlayoutfunction';
import tibrservices from '../../../../services/tibservices';
import ProgressBar from '../../../progressbar/progressbar';
import { Dialog } from "@material-ui/core";
import ErrorMessage from '../../../../app/containers/tibr/errorMessage';
import BREServices from "../../../../services/breservice";
import { BREModuleActions } from '../../../../assets/constants/constants'
import ConfirmationPopup from "../../../../utilities/confirmationpopup";

library.add(
    faClock,
);

const sampleCss = `.e-outline textarea, textarea.e-outline, .e-outline.e-float-input textarea, .e-outline.e-float-input.e-control-wrapper textarea, .e-outline.e-float-input.e-input-group textarea {
       box-sizing: border-box;
       min-height: 100px;
     }
     @media screen and (max-width: 1366px) and (max-height: 768px),
screen and (max-width: 1440px) and (max-height: 900px) {
    .e-timepicker{
        font-size:0.7rem !important
    }
}
     `

const popupCss = ` .regularization-popup .MuiDialog-scrollPaper{
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}  
.MuiDialog-paper {
    margin: 32px 12px;
}

.MuiDialog-paperWidthSm {
    max-width: 690px !important;
}
`
export const Regularization = (props) => {
    const { authUser, regularizationType } = props
    const [tibrData, setTibrData] = useState([]);
    const [selectedAttendance, setSelectedAttendance] = useState([]);
    const [regFiles, setRegFiles] = useState([])
    const [emptyIds, setEmptyIds] = useState([]);
    const [changedData, setChangedData] = useState(false);
    const [loading, setLoading] = useState(false);
    const [date, setDate] = useState({
        dependDate: null,
        maxDate: null
    });

    const [state, setState] = useState({
        height: null,
        containerHeight: null,
        innerContainerHeight: null,
        tabHeight: null,
        isEdit: false,
        open: false,
        message: '',
        attendanceId: null,
        regularizationAppliedUid: 0,
        gridWidth: document.getElementById('regularization-container-root')?.clientWidth
    });

    const [regularisationData, setregularisationData] = useState({
        userId: authUser,
        regularizationTypeId: 0,
        appliedEndDate: null,
        appliedStartDate: null,
        enumStatus: 1,
        swipeReason: ''
    })

    const [viewMode, setViewMode] = useState(false)
    const [dataToView, setDataToView] = useState({})
    const [viewType, setviewType] = useState('')
    const showViewMode = (flag, data, type) => {
        setViewMode(flag)
        setDataToView(data)
        setviewType(type)
        if (type === "close") {
            setregularisationData({
                ...regularisationData
            })
        }
    }
    const options = {
        rules: {
            regularizationTypeErr: {
                required: [true, 'Please select regularization type.'],
            },
            appliedStartDateErr: {
                required: [true, 'Please select from date.'],
            },
            appliedEndDateErr: {
                required: [true, 'Please select to date.'],
            },
            regularizationAppReasonErr: {
                required: [true, 'Please enter reason.'],
            },
        },
    };

    const textareaObj = useRef(null);
    useEffect(() => {
        window.addEventListener("resize", updateDimensions()); // NOSONAR
        const setdate = getMaxDate('SUBSTRACT', 2);
        setDate({...date, maxDate: setdate });
        getMasterData();
    }, [])

    const updateDimensions = () => {
        const isThirteenHundred = window.innerHeight < 658; // NOSONAR
        const containerHeight = window.innerHeight - 96
        const innerContainerHeight = window.innerHeight - 190
        const minTabHeight = isThirteenHundred ? 231 : 234
        const tabHeight = window.innerHeight - minTabHeight

        const updateheight = window.innerHeight - 125
        const lavContHeight = window.innerHeight - 235
        setState(preState => ({
            ...preState, notifiresPopupHeight: updateheight,
            height: lavContHeight,
            containerHeight: containerHeight,
            innerContainerHeight: innerContainerHeight,
            tabHeight: tabHeight
        }))
    }

    const clearErrorMessages = () => {
        const errorFields = [
            "regularizationAppReasonErr",
            "regularizationTypeErr",
            "appliedStartDateErr",
            "appliedEndDateErr"
        ];

        errorFields.forEach(field => {
            const elem = document.getElementById(field);
            if (elem) {
                elem.innerHTML = "";
            }
        });
    };

    useEffect(() => {
        if (props.leaveRegularizationFlag === "updated" || props.leaveRegularizationFlag === 'errorMsg') {
            props.updateLnAData({ leaveRegularizationFlag: '' })
        }
    }, [props.leaveRegularizationFlag]);


    const onInput = (e) => {
        try {
            if (textareaObj !== null && typeof textareaObj !== 'undefined') {
                textareaObj.current.respectiveElement.style.height = "auto";
                textareaObj.current.respectiveElement.style.minHeight = "100px";
                textareaObj.current.respectiveElement.style.height = `${textareaObj.current.respectiveElement.scrollHeight}px`;
                textareaObj.current.respectiveElement.style.maxHeight = "100px";
                cracterLimit(e, 'regularizationAppReasonCount', '500')
            }
        } catch (error) {
        }
    };

    /**
     * event handling
     * @param {*} e 
     * @param {*} type 
     */
    const onChange = (e, type) => {
        switch (type) {
            case 'regularizationType':
                setregularisationData(prevState => ({ ...prevState, regularizationTypeId: e.itemData !== null ? e.itemData.Id : '' }));
                if (document.getElementById("regularizationTypeErr")) {
                    document.getElementById("regularizationTypeErr").innerHTML = ""
                }
                break;
            case 'appliedStartDate':
                setregularisationData(prevState => ({ ...prevState, appliedStartDate: e.value, appliedEndDate: null }));
                if(e.value){
                    setDate({...date, dependDate: e.value })
                }
                if (document.getElementById("appliedStartDateErr")) {
                    document.getElementById("appliedStartDateErr").innerHTML = ""
                }
                break;  
            case 'appliedEndDate':
                setregularisationData(prevState => ({ ...prevState, appliedEndDate: e.value }));
                if (document.getElementById("appliedEndDateErr")) {
                    document.getElementById("appliedEndDateErr").innerHTML = ""
                }
                break;
            case 'swipeReason':
                setregularisationData(prevState => ({ ...prevState, swipeReason: e.value }));
                if (document.getElementById("regularizationAppReasonErr")) {
                    document.getElementById("regularizationAppReasonErr").innerHTML = ""
                }
                break;
            default:
        }
    }

    const getMasterData = async () => {
        try {
            const lnaMastersData = await getLnAData(authUser, "regularization");
            if (lnaMastersData.regData) {
                const regType = lnaMastersData.regData.filter((item) => { return item.Type === "Regularization Type"; });
                const attendType = lnaMastersData.regData.filter((item) => { return item.Type === "Attendance Type"; });
                props.updateLnAData({ regularizationType: regType, attendanceType: attendType })
            }
        } catch (error) {
        }
    }

    const getChildData = (data) => {
        clearErrorMessages();
        setSelectedAttendance([data.dateDayBreak]);
        setRegFiles(data.attachments ? data.attachments.split(',') : [])
        setregularisationData({
            appliedStartDate: new Date(data.appliedFor),
            appliedEndDate: new Date(data.appliedFor),
            swipeReason: data.regularizationReason,
            regularizationTypeId: data.regularizationTypeId
        });
        document.getElementById("appliedStartDate").disabled = true;
        document.getElementById("appliedEndDate").disabled = true;
        setState((prevState) => ({ ...prevState, isEdit: true, attendanceId: data.attendanceId, regularizationAppliedUid: data.regularizationApplicationId }));
        getTIBRData(new Date(data.appliedFor), new Date(data.appliedFor), data);
    }

    const setInitial = () => {
        setregularisationData({
            ...regularisationData,
            regularizationTypeId: 0,
            appliedEndDate: null,
            appliedStartDate: null,
            swipeReason: ''
        })
        setState({
            ...state,
            isEdit: false,
            open: false,
            attendanceId: null,
            regularizationAppliedUid: 0,
        });
        setTibrData([]);
        setRegFiles([]);
        setSelectedAttendance([]);
        clearErrorMessages();
        document.getElementById("appliedStartDate").disabled = false;
        cracterLimit({ value: "" }, 'regularizationAppReasonCount', '500')
    }

    /**
     * Author: Chandrashekhar Bhagat.
     * Created:11-December-2023
     * @description Converting date format to - 2023-12-12.
     */
    const dateFormat = (inputDateString) =>{
        const inputDate = new Date(inputDateString);
        const year = inputDate.getFullYear();
        const month = (inputDate.getMonth() + 1).toString().padStart(2, '0');
        const day = inputDate.getDate().toString().padStart(2, '0');
        return `${year}-${month}-${day}`
    }

    const getBREDataForRegularization = async (pAttendanceId) => {
        const objData = { "int_attendance_id": pAttendanceId }
        return BREServices.getBREData(authUser, BREModuleActions.regularizationApplication, objData);
    }

    /**
     * Author: Chandrashekhar Bhagat.
     * Created:13-December-2023
     * @description Check whether for all dates attandanceChangedTo is Selected or Not.
     */
    const validateAttendanceChangedTo = () => {
        const ignoredList = tibrData.filter(obj => obj.tempSelected === '' && obj.attendanceType !== 'WO' && obj.attendanceType !== 'HO').map(obj => `attendanceChangedTo${obj.Id}`);
        setEmptyIds(ignoredList);
        if(ignoredList.length > 0) {
            ignoredList.forEach(item => {
                document.getElementById(item).style.border = '1px solid red';
            });
        }
        return;
    }

    /**
     * Author: Chandrashekhar Bhagat.
     * Created:11-December-2023
     * @description Add/Edit Data.
     */
    const onSave = async () => {
        try {
            let RegularizationSaveData = {
                userId: authUser,
                type: state.isEdit ? 'EDIT' : 'ADD',
                appliedStartDate: dateFormat(regularisationData.appliedStartDate),
                appliedEndDate: dateFormat(regularisationData.appliedEndDate),
                dateDayBreak: selectedAttendance.join(','),
                swipeReason: regularisationData.swipeReason,
                regularizationTypeId: regularisationData.regularizationTypeId,
                regularizationAttachment: regFiles.toString(),
                enumStatus: regularisationData.enumStatus,
                regularizationAppliedUid: state.regularizationAppliedUid
            }
            const getBREData = await getBREDataForRegularization(state.attendanceId ? state.attendanceId : 1);
            RegularizationSaveData = {
                ...RegularizationSaveData,
                supervisorApprovalRequired: getBREData.enum_supervisor_approval,
                hrApprovalRequired: getBREData.enum_HR_approval,
                autoApprovalRequired: getBREData.enum_auto_approval,
                noOfHrsToAutoApprove: getBREData.int_no_hrs_to_approval
            }
            props.saveRegularizationData(RegularizationSaveData)
            setInitial();
        } catch (err) {
            console.log(err)
        }
    }

    /**
     * Author: Chandrashekhar Bhagat.
     * Created:11-December-2023
     * @description Getting TIBR Data after selection.
     */
    const getTIBRData = async (appliedStartDate, appliedEndDate, data) => {
        try {
            if (appliedStartDate instanceof Date && appliedEndDate instanceof Date) {
                const mNoStartDate = Math.floor(appliedStartDate.getTime() / 1000.0);
                const mNoEndDate = Math.floor(appliedEndDate.getTime() / 1000.0);
                setLoading(true);
                const userTibData = await tibrservices.getUserTibData(authUser, mNoStartDate, mNoEndDate);
                if(userTibData.length > 0) {
                    // Need a filter as the array is containing more two records after appliedEndDate. 
                    const filteredData = userTibData.filter(item => new Date(item.date).setHours(0,0,0,0) <= appliedEndDate.setHours(0,0,0,0));
                    filteredData.sort((a, b) => new Date(b.date) - new Date(a.date));
                    // Adding temporary Dropdown holding value Key.
                    const newArrayWithKey = filteredData.map(obj => ({ ...obj, tempSelected: '' }));
                    //Edit case updating breakup dropdown.
                    if (Object.keys(data).length > 0) {
                        newArrayWithKey[0] = { ...newArrayWithKey[0], tempSelected: parseInt(data.regularizationDayBreakId)};
                        setTibrData(newArrayWithKey);
                        cracterLimit({ value: data.regularizationReason }, 'regularizationAppReasonCount', '500');
                    } else {
                        setTibrData(newArrayWithKey);
                        cracterLimit({value: ''}, 'regularizationAppReasonCount', '500')
                    }
                    setLoading(false);
                } else {
                    setTibrData(userTibData);
                    setLoading(false);
                }
            } else {
                console.error("Please check selected Dates.");
            }
        } catch (error) {
            console.log('error',error)
        }
    }

    /**
     * Author: Chandrashekhar Bhagat.
     * Created:05-December-2023
     * @params type and count.
     * @description to get the dates 2 days before the current date.
     */
    const getMaxDate = (type, count) => {
        if(type.toUpperCase() === 'ADD'){
            const maxDate = new Date();
            maxDate.setDate(maxDate.getDate() + count);
            return maxDate;
        } else {
            const maxDate = new Date();
            maxDate.setDate(maxDate.getDate() - count);
            return maxDate;
        }
    }

    /**
     * Author: Chandrashekhar Bhagat.
     * Created:11-December-2023
     * @description Add Files
     */
    const selectedFiles = (fileNms) => {
        try {            
          setRegFiles([...fileNms]);
          setTimeout(() => {
            setChangedData(!changedData);
          });
        } catch (error) {
          console.log("🚀 ~ file: regularization.jsx:126 ~ selectedFiles ~ error", error)
        }
    };

    /**
     * Author: Chandrashekhar Bhagat.
     * Created:14-December-2023
     * @description Validate Add/Edit Data.
     */
    const validateData = () => {
        clearErrorMessages();
        validateAttendanceChangedTo();
        const addFormObject = new FormValidator("#regularization", options);
        try {
            addFormObject.validate();
        } catch (errr) {
        }
        if (addFormObject.validate() && emptyIds.length === 0) {
            setState({...state, open: true})
        }
    }

    return (
        <div id='regularization' className="col-xs-12 col-sm-12 col-lg-12 col-md-12 leave-application-body circular-regular"
            style={{
                backgroundColor: "#ffffff",
                height: state.containerHeight,
                borderRadius: "5px",
            }}>
            <NotificationContainer />
            <div
                className="col-xs-12 col-sm-12 col-lg-12 col-md-12 circular-regular"
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                }}>
                <div className='col-sm-12 col-md-12 col-lg-12 circular-regular'>
                    <div style={{
                        backgroundColor: '#343A40',
                        borderStartEndRadius: "0.4em",
                        borderStartStartRadius: "0.4em",
                        height: "2.5rem",
                        display: "flex",
                        alignItems: "center",
                        paddingLeft: "1rem",
                        fontWeight: 600,
                    }}>
                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 leave-application-header circular-bold">
                            Regularization Application
                        </div>
                    </div>
                </div>
                <div className="container circular-regular pl-sm pr-sm" style={{ display: 'flex', height: state.innerContainerHeight, width: '100%', overflowY: 'hidden', overflowX: 'hidden' }}>
                    <div
                        style={{
                            border: '1px solid #ccc',
                            borderRadius: "5px",
                            boxShadow: "0.5px 0.5px #ccc",
                            overflowY: 'auto'
                        }}
                        className='col-lg-7 col-md-7 col-sm-7 col-xs-7 mt-sm leave-application-body circular-regular'>
                        <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 pt-md circular-regular'>
                            <div className='col-xs-4 col-sm-4 col-lg-4 col-md-4 circular-regular' style={{ paddingLeft: 0 }}>
                                <ComboBoxComponent
                                    id="typeOfRegularization"
                                    fields={{ text: "Name", value: "Id" }}
                                    cssClass="e-outline numberInputFont circular-regular"
                                    data-msg-containerid="regularizationTypeErr"
                                    name="regularizationTypeErr"
                                    floatLabelType="Auto"
                                    placeholder="Regularization Type"
                                    htmlAttributes={{ maxlength: 100 }}
                                    select={(e) => onChange(e, 'regularizationType')}
                                    value={regularisationData.regularizationTypeId}
                                    dataSource={regularizationType}
                                    allowCustom={false}
                                />
                                <div
                                    id="regularizationTypeErr"
                                    className="e-input-group e-float-icon-left error-div"
                                />

                            </div>
                            <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4 circular-regular" style={{ paddingLeft: 0 }} >
                                <DatePickerComponent
                                    id="appliedStartDate"
                                    name="appliedStartDateErr"
                                    data-msg-containerid="appliedStartDateErr"
                                    cssClass="e-outline circular-regular"
                                    allowFiltering={false}
                                    allowCustom={true}
                                    floatLabelType="Auto"
                                    placeholder="From Date"
                                    showClearButton={true}
                                    format='dd-MMM-yyyy'
                                    type="date"
                                    popupWidth={296}
                                    max={date.maxDate}
                                    onChange={(e) => onChange(e, 'appliedStartDate')}
                                    value={regularisationData.appliedStartDate}
                                ></DatePickerComponent>
                                <div
                                    id="appliedStartDateErr"
                                    className="e-input-group e-float-icon-left error-div mb-xl"
                                />
                            </div>
                            <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4 circular-regular" style={{ paddingLeft: 0, paddingRight: 0 }} >
                                <DatePickerComponent
                                    id="appliedEndDate"
                                    name="appliedEndDateErr"
                                    data-msg-containerid="appliedEndDateErr"
                                    cssClass="e-outline circular-regular"
                                    allowFiltering={false}
                                    allowCustom={true}
                                    floatLabelType="Auto"
                                    placeholder="To Date"
                                    showClearButton={true}
                                    format='dd-MMM-yyyy'
                                    type="date"
                                    popupWidth={296}
                                    min={date.dependDate}
                                    max={date.maxDate}
                                    onChange={(e) => { onChange(e, 'appliedEndDate'); getTIBRData(regularisationData.appliedStartDate, e.target.value, {}) }}
                                    value={regularisationData.appliedEndDate}
                                    enabled={!!regularisationData.appliedStartDate}
                                ></DatePickerComponent>
                                <div
                                    id="appliedEndDateErr"
                                    className="e-input-group e-float-icon-left error-div mb-xl"
                                />
                            </div>
                        </div>
                        {loading && <ProgressBar />}
                        {tibrData.length > 0 && (
                                <>
                                    <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 regularizationGrid'>
                                        <RegularizationGrid tibrData={tibrData} setTibrData={setTibrData} selectedAttendance={selectedAttendance} setSelectedAttendance={setSelectedAttendance} />
                                    </div>
                                    {
                                        emptyIds.length > 0 && <div style={{color:'red'}}>Please fill the missing data or non-actionable record.</div>
                                    }
                                    <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 pt-sm circular-regular' style={{ paddingLeft: 0, marginTop:'20px' }}>
                                        <style>{sampleCss}</style>
                                        <TextBoxComponent
                                            style={{ minHeight: 100 }}
                                            id='swipeReason'
                                            placeholder="Reason *"
                                            cssClass="e-outline textarea circular-regular"
                                            floatLabelType="Auto"
                                            input={onInput}
                                            ref={textareaObj}
                                            name="regularizationAppReasonErr"
                                            data-msg-containerid="regularizationAppReasonErr"
                                            multiline
                                            inputProps={{
                                                minLength: 7,
                                            }}
                                            htmlAttributes={{ minLength: 0, maxlength: 1000 }}
                                            onChange={(e) => onChange(e, 'swipeReason')}
                                            value={regularisationData.swipeReason}
                                        />
                                        <div 
                                            id="regularizationAppReasonErr" 
                                            className="e-input-group e-float-icon-left error-div"
                                        />

                                        <div className='regularization-character'>Character limit : <span style={{ color: "#DBDBDD" }}>&nbsp;<div id='regularizationAppReasonCount' style={{ float: 'right', fontSize: 14 }} /></span></div>
                                    </div>
                                    <div className="circular-regular" style={{ paddingLeft: 0 }}>
                                        <div
                                            className="attachNote"
                                            style={{
                                                fontSize: ".95rem",
                                                color: "#939399",
                                                fontWeight: 500,
                                                marginTop: "0rem",
                                            }}
                                            >
                                            Attachment
                                        </div>

                                        <UploadDocuments
                                            type={"regularization"}
                                            allowmultiple={true}
                                            selectedFiles={selectedFiles}
                                            fileList={regFiles}
                                            apiUrl={"lnaApiUrl"}
                                        />
                                        
                                    </div>
                                </>
                            )
                        }
                        {
                          regularisationData.appliedStartDate && regularisationData.appliedEndDate && tibrData.length === 0 && loading === false && <ErrorMessage message={"No Data Available"} position= {'unset'} /> 
                        }
                    </div>
                    <div
                        className='col-lg-5 col-md-5 col-sm-5 col-xs-5 leave-application-body mt-sm ml-md circular-regular'
                        style={{
                            border: '1px solid #ccc',
                            borderRadius: "5px",
                            boxShadow: "0.5px 0.5px #ccc",
                            fontFamily: "Arial, Helvetica, sans-serif",
                            color: "#848484",
                            padding: 0,
                            // overflowY: 'auto'
                        }} >
                        <Leavetabs selectedUserId={authUser} type={'Regularization'} getChildData={getChildData} showViewMode={showViewMode} />
                    </div>
                </div >
            </div>
            <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 pb-sm pt-sm pl-md circular-regular' style={{ paddingRight: 0 }}>
                <div className="pr-sm" style={{ float: "right" }}>
                    <button
                        className="leave-cancel-apply-button"
                        onClick={setInitial}
                    >
                        Cancel
                    </button>
                    <button
                        className="remove-button-shadow-save"
                        onClick={validateData}>
                        Submit
                    </button>
                </div>
            </div>

            <ConfirmationPopup
                headerMessage={`Are you sure want to ${state.isEdit ? 'Edit' : 'Add'} Regularization?`}
                button1Text={"Yes"}
                button2Text={"No"}
                button2function={()=>setState({...state, open: false})}
                button1function={onSave}
                showButton={{ type: "1" }}
          open={state.open}
          handleConfirmClose={() => setState({ ...state, open: false })}
            />

            {/* View PopUp */}
            <style>{popupCss}</style>
            <Dialog open={viewMode} className='regularization-popup' >
                <SwipeDetails viewType={viewType} dataToView={dataToView} showViewMode={showViewMode} />
            </Dialog>
        </div>
    )
}

const mapStateToProps = ({ meetingcategory, lnareducer, auth, meetingmaster }) => {
    const { authUser } = auth;
    const { categoryName } = meetingcategory;
    const { othersCheckAvailablity } = meetingmaster;
    const { leaveType, leaveStartDate, leaveEndDate, notificationTo, reliever, leaveTypeData, relieverData, leaveApplyStatus, isStreched, isWeekendCounted, regularizationType, leaveRegularizationFlag } = lnareducer;
    return {
        categoryName,
        leaveType, leaveStartDate, leaveEndDate, notificationTo, reliever, authUser, leaveTypeData, othersCheckAvailablity, relieverData,
        leaveApplyStatus, isStreched, isWeekendCounted, regularizationType, leaveRegularizationFlag
    }
}

export default connect(mapStateToProps, { updateLnAData, applyLeave, saveRegularizationData })(Regularization)