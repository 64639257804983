import React, { Component } from 'react'
import { connect } from 'react-redux';
import { Dialog, DialogTitle } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import ReactHtmlParser from 'react-html-parser'
import BenchMarkTable from '../okrproductiveallocation/benchmarktable';
import AddNewEmployeeInGrid from '../okrproductiveallocation/addnewemployeeingrid'
import fileIcon from "../../../src/assets/svg/fileIcon_jpeg.png"
import {
    DialogContent,
    DialogActions,
} from "../customdialog/customdialog";
import { urlConfig } from '../../config/config';
import {
    MeasuremenrUnit, MeasuremenrSortBy
} from "../../assets/constants/constants";
import { createImageFilepath } from './ratingcommonfun'
class ViewKRDetailsModal extends Component {
    constructor(props) {
        super(props)
        this.state = {
            benchMarkData: []
        }
    }

    componentDidMount() {
        try {
            const { KRData } = this.props
            if (typeof KRData !== 'undefined') {
                let actBenchMarkData = []
                const cnstbenchmark = KRData.benchmark.split('$$')
                cnstbenchmark.forEach(item => {
                    let ind = item.split('_')
                    if (typeof ind[1] !== 'undefined') {
                        actBenchMarkData.push({
                            Id: ind[0],
                            Type: ind[1],
                            From: ind[2],
                            To: ind[3],
                            benchMarkForYes: ind[4],
                            sortBy: ind[5],
                            currency: ind[6] === 'null' ? "" : ind[6]
                        })
                    }
                })
                this.setState({ benchMarkData: actBenchMarkData })
            }
        } catch (error) {
        }
    }

    getUnitName = (unit) => {
        let data = MeasuremenrUnit.filter((t) => t.Value === unit)
        return data[0].Name
    }
    getSortByName = (sortBy) => {
        let data = MeasuremenrSortBy.filter((t) => t.Value === sortBy)
        return data[0].Name
    }
    getFileExtension = (filename) => {
        let ext = filename.split('.').pop();
        if (ext === filename) return "";
        return ext;
    }
    viewUploadedAttachment(filepath) {
        const { employeeId } = this.props;
        return (
            <>
                {typeof filepath !== 'undefined' &&
                    filepath.split(",").map((attachedFile, attInd) => (
                        <> {attachedFile !== "" && <span key={`attachment${attInd}`}>
                            <a
                                target={this.getFileExtension(attachedFile) === "txt" || this.getFileExtension(attachedFile) === "pdf" ||
                                    this.getFileExtension(attachedFile) === "jpeg" || this.getFileExtension(attachedFile) === "JPEG" || this.getFileExtension(attachedFile) === "jpg" ||
                                    this.getFileExtension(attachedFile) === "JPG" || this.getFileExtension(attachedFile) === "png" || this.getFileExtension(attachedFile) === "PNG" ||
                                    this.getFileExtension(attachedFile) === "svg" || this.getFileExtension(attachedFile) === "SVG" || this.getFileExtension(attachedFile) === "EPS" ||
                                    this.getFileExtension(attachedFile) === "eps" || this.getFileExtension(attachedFile) === "GIF" || this.getFileExtension(attachedFile) === "gif" ||
                                    this.getFileExtension(attachedFile) === "BMP" || this.getFileExtension(attachedFile) === "bmp" || this.getFileExtension(attachedFile) === "TIF" ||
                                    this.getFileExtension(attachedFile) === "tif" || this.getFileExtension(attachedFile) === "TIFF" || this.getFileExtension(attachedFile) === "tiff" ? "_blank" : null}
                                href={
                                    attachedFile !== null && attachedFile !== ""
                                        ? `${urlConfig.tmlcApiUrl}document/kpi/${employeeId}/${attachedFile}`
                                        : ""}
                                rel="noopener noreferrer"
                                download
                                style={{ textDecoration: 'none' }}
                            >
                                <img
                                    alt=""
                                    src={fileIcon}
                                    key={`img${attInd}`}
                                />
                                <span className='file-text' style={{ padding: "0px 4px 12px", color: '#F94F5E' }} title={attachedFile.substring(11, attachedFile.length).length > 35 ? attachedFile.substring(11, attachedFile.length).substr(0, 32) + '...' : attachedFile.substring(11, attachedFile.length)}>
                                    {attachedFile.substring(11, attachedFile.length).length > 35 ? attachedFile.substring(11, attachedFile.length).substr(0, 32) + '...' : attachedFile.substring(11, attachedFile.length)}
                                </span>
                            </a>
                        </span>
                        }
                        </>

                    ))
                }
            </>
        )
    }
    getFrequencyName = (type, data, frq) => {/**NOSONAR */
        try {
            const { OkrMasters } = this.props;
            let returnText = '';
            if (type === 'scope') {
                const scopeType = OkrMasters.filter((item) => {
                    return item.var_list_value === "Scope Type";
                });
                // eslint-disable-next-line
                returnText = scopeType.filter((item) => {
                    if (item.Id === data) {
                        return item.Name
                    }
                })
                if (data !== null && returnText.length > 0) {
                    returnText = returnText[0].var_optional1
                }
            }

            return (
                <span>{returnText}</span>
            );
        } catch (err) {
            console.log('error===>', err);
        }
    }
    render() {
        const { KRData, authUser } = this.props
        const { benchMarkData } = this.state
        return (
            <div>
                <Dialog
                    open={true}
                    fullWidth
                    maxWidth="lg"
                    onEscapeKeyDown={() => this.props.closeKrDetailsPopup()}>
                    <DialogTitle>
                        <span className="add-new-self-header" style={{ marginTop: 0 }}>{"Key Result"} </span>
                        <span style={{ position: 'absolute', right: 20, cursor: 'pointer' }}><FontAwesomeIcon onClick={() => this.props.closeKrDetailsPopup()}
                            style={{ color: '#B7B7BB', fontSize: 20 }} icon={faTimes} /></span>
                    </DialogTitle>
                    <DialogContent>
                        <div className='col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                            <div className='col-6 col-lg-6 col-md-6 col-sm-6 col-xs-6'>
                                <div className="view-kr-modal-header-text">Objective</div>
                                <div className="view-kr-modal-body-text">
                                    {
                                        KRData.objectiveName === undefined ? KRData.objectiveName : KRData.objectiveName //NOSONAR
                                    }
                                </div>
                                <div className="view-kr-modal-header-text">Key Result</div>
                                <div className="view-kr-modal-body-text">{KRData.krName === undefined ? KRData.kpiName : KRData.krName}</div>
                                <div className="view-kr-modal-header-text pt-sm">Description</div>
                                <div className="view-kr-modal-body-text ">{ReactHtmlParser(KRData.libDescription)}</div>
                            </div>
                            <div className='col-6 col-lg-6 col-md-6 col-sm-6 col-xs-6'>
                                <div className='row'>
                                    <div className='col-2 col-lg-2 col-md-2 col-sm-2 col-xs-2' style={{padding: '0px 0px 0px 15px'}}>
                                        <div className="view-kr-modal-header-text">Variable Link</div>
                                        <div className="view-kr-modal-body-text pl-xs">
                                            {KRData.varibleLink === 1 && 'Yes'}
                                            {KRData.varibleLink === 0 && 'No'}
                                            {KRData.varibleLink === null && '-'}
                                        </div>
                                    </div>
                                    <div className='col-2 col-lg-2 col-md-2 col-sm-2 col-xs-2' style={{padding: '0px 0px 0px 15px'}}>
                                        <div className="view-kr-modal-header-text">Weightage %</div>
                                        <div className="view-kr-modal-body-text">{KRData.weightage}</div>
                                    </div>
                                    <div className='col-2 col-lg-2 col-md-2 col-sm-2 col-xs-2'>
                                        <div className="view-kr-modal-header-text">Frequency</div>
                                        <div className="view-kr-modal-body-text">{KRData.reviewFrequency}</div>
                                    </div>
                                </div>
                                <div>
                                    <div className="view-kr-modal-header-text pt-sm">Reference 1</div>
                                    <div className="view-kr-modal-body-text">{KRData.lable1}</div>
                                </div>
                                <div>
                                    <div className="view-kr-modal-header-text pt-sm">Reference 2</div>
                                    <div className="view-kr-modal-body-text ">{KRData.lable2}</div>
                                </div>
                            </div>
                            <div className='col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pt-sm pl-md'>
                                {KRData.kpiAttachment !== null ? createImageFilepath(KRData.kpiAttachment.split(','), `document/kpi/${authUser}`) : ''}
                            </div>
                            <div className='col-12 col-lg-12 col-md-12 col-sm-12'>
                                {
                                    benchMarkData.length > 0 &&
                                    <div className='col-6 col-lg-6 col-md-6 col-sm-6' style={{ borderRight: '#DBDBDD' }}>
                                        <div className="view-kr-modal-body-text pt-lg">Measurement Matrix For The Key Result</div>
                                        <div className="row">
                                            <div className='col-4 col-lg-4 col-md-4 col-sm-4'>
                                                <div className="view-kr-modal-header-text pt-sm">Unit of Measurement</div>
                                                <div className="view-kr-modal-body-text ">{benchMarkData.length > 0 && this.getUnitName(benchMarkData[0].Type)}</div>
                                            </div>
                                            <div className='col-4 col-lg-4 col-md-4 col-sm-4'>
                                                {/* <div className="view-kr-modal-header-text pt-sm">Reference 1</div>
                                            <div className="view-kr-modal-body-text pt-sm">Place holder text</div> */}
                                            </div>
                                            <div className='col-4 col-lg-4 col-md-4 col-sm-4'>
                                                <div className="view-kr-modal-header-text pt-sm">Sort Achievement</div>
                                                <div className="view-kr-modal-body-text ">{benchMarkData.length > 0 && this.getSortByName(benchMarkData[0].sortBy)}</div>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div>
                                                <span className='pl-md view-kr-modal-body-text'>Note: Rating 1 - Least Performance & Rating 5 - Best Performance</span>
                                                <BenchMarkTable benchMarkData={benchMarkData} unit={'view'} sortBy={''} />
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className='col-6 col-lg-6 col-md-6 col-sm-6'>
                                    <div className="view-kr-modal-body-text pt-lg" style={{ color: 'transparent' }}>.</div>
                                    <div className="row">
                                        <div className='col-4 col-lg-4 col-md-4 col-sm-4'>
                                            <div className="view-kr-modal-header-text pt-sm">Scope</div>
                                            <div className="view-kr-modal-body-text">{this.getFrequencyName('scope', KRData.scopeTypeId)}</div>
                                        </div>
                                    </div>
                                    {
                                        (KRData.scopeTypeId === 726 || KRData.scopeTypeId === 727) &&
                                        <div className='row pl-md'>
                                            <div>
                                                <AddNewEmployeeInGrid
                                                    userSelectedData={KRData.userList}
                                                    showHideColumn={'hide'}
                                                    removeDataFromGrid={() => {/**nosonar*/ }} userList={KRData.userList} />
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>

                    </DialogActions>
                </Dialog>
            </div>
        )
    }
}

const mapStateToProps = ({ okrreducer, auth }) => {
    const { OkrMasters } = okrreducer
    const { authUser } = auth
    return { OkrMasters, authUser }
};
export default connect(mapStateToProps, {})(
    ViewKRDetailsModal
);


