import React, { Component } from 'react';
import { connect } from 'react-redux';
import OKRDashboardHeaderTabs from '../../../../components/okrdashboardcomponents/okrdashboardheadertabs';
import TeamView from '../../../../components/okrdashboardcomponents/teamview';
import OKRDashboardServices from '../../../../services/okrdashboardservices/okrdashboardservices';
import SelfView from '../../../../components/okrdashboardcomponents/selfview';
import { addUpdateOkrDashboardRedux } from '../../../../redux/actions/okrdashboardaction/okrdashboardaction';
import PerformancePotential from '../../../../components/okrdashboardcomponents/performancepotential';
import OKRServices from '../../../../services/okr/okrservices';
import { decodeString } from "../../../../app/other/commonfunction";
import { ComboBoxComponent } from "@syncfusion/ej2-react-dropdowns";
import Breadcrumb from '../../../../components/okrdashboardcomponents/breadcrum';
import { landingMessageSelfRatingView, landingMessageTeamRatingView } from '../../../../assets/constants/constants';

class OKRDashboardContainer extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showMonths: true,
      crumbs: [],
      height: null,
      windowAdjust: null
    };
  }
  onChangeShowMonth = (flag) => {
    const { dashboardTabs, isPeopleLeader } = this.props;
    if (dashboardTabs === 'self-rating' && isPeopleLeader) {
      this.setState({
        showMonths: false
      })
    } else {
      this.setState({
        showMonths: flag
      })
    }
  }
  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions());
    this.getMasterList();
    this.getDashboardDropdownUserList();

    this.setState({ crumbs: ["OKR Dashboard"] });
  }

  /** New changes for compUpdate with less complexity */
  componentDidUpdate(prevProps) {
    const { dashboardTabs, empName } = this.props;
    const okrDashboardCrumbs = ["OKR Dashboard"];

    if (prevProps.dashboardTabs !== dashboardTabs) {
      if (dashboardTabs === 'team-member-rating') {
        this.setState({ crumbs: [...okrDashboardCrumbs, "My Team", empName] });
      } else if (dashboardTabs === "team-rating") {
        this.props.addUpdateOkrDashboardRedux({ empName: '', isEmpName: true });
        this.setState({ crumbs: [...okrDashboardCrumbs, "My Team"] });
      } else if (dashboardTabs === "self-rating") {
        this.setState({ crumbs: [...okrDashboardCrumbs, "Self"] });
        if (empName) {
          this.setState({ crumbs: [...okrDashboardCrumbs, "My Team", empName] });
        }
      } else if (dashboardTabs === "performance-potential") {
        if (empName) {
          this.setState({ crumbs: [...okrDashboardCrumbs, "My Team", empName, "Rating"] });
        } else {
          this.setState({ crumbs: [...okrDashboardCrumbs, "Self", "Rating"] });
        }
      }
    }
  }


  componentWillReceiveProps(nextProps) {
    const { isPeopleLeader } = this.props;
    if (!isPeopleLeader) {
      let updateheight = window.innerHeight - 300;
      let windowAdjusts = window.innerHeight - 210;
      if (nextProps.dashboardTabs === "self-rating") {
        updateheight += 35;
        windowAdjusts += 35;

      }
      this.setState({
        height: updateheight,
        windowAdjust: windowAdjusts
      });
    }
  }
  updateDimensions = () => {
    const { isPeopleLeader, dashboardTabs } = this.props;
    let updateheight = window.innerHeight - 300;
    let windowAdjusts = window.innerHeight - 210;
    if (!isPeopleLeader && dashboardTabs === "self-rating") {
      updateheight += 35;
      windowAdjusts += 35;

    }
    this.setState({
      height: updateheight,
      windowAdjust: windowAdjusts
    });
  }
  getDashboardDropdownUserList = async () => { // NOSONAR
    const { authUser } = this.props;
    const dashboardDropdownUserList = await OKRDashboardServices.getDashboardUserList(authUser);
    let isPeopleLeader = false;
    dashboardDropdownUserList?.map((level1) => {
      if (level1.subChild.length > 0) {
        level1.subChild?.map((level2) => {
          if (level2.subChild.length > 0) {
            if (level2.subChild.length > 0) {
              level2.subChild?.map((level3) => {
                if (level3.subChild.length > 1) {
                  isPeopleLeader = true;
                }
              })
            }
          }
        })
      }
    })
    this.props.addUpdateOkrDashboardRedux({ dashboardUserList: dashboardDropdownUserList, isPeopleLeader: isPeopleLeader, dashboardTabs: isPeopleLeader ? 'team-rating' : 'self-rating', empId: !isPeopleLeader ? authUser : 0 })
  }
  getMasterList = () => {
    const { authUser } = this.props;
    try {
      const getMasterData = Promise.resolve(OKRServices.getOKRMaster(authUser));
      getMasterData.then((resultData) => {
        if (resultData !== null && resultData.Result !== false) {
          const reviewTP = resultData.Result.filter((item) => { return item.var_list_name === "Review"; });
          this.props.addUpdateOkrDashboardRedux({ teamMemberList: reviewTP, empId: authUser })
        }
      });
    } catch (e) {
      console.log(e);
    }
  }
  getFinancialYear = () => {
    let financialYear = ""
    const { finacialYearData, fyId } = this.props;
    finacialYearData.length &&
      // eslint-disable-next-line
      finacialYearData.map((item) => {
        if (item.Id === fyId) {
          financialYear = item.Name
        }
      })
    return financialYear;
  }
  onChangeData = (e, type) => {
    if (type === 'financial') {
      if (e.itemData !== null) {
        this.props.addUpdateOkrDashboardRedux({ fyId: e.itemData.Id, monthlyOkrMonthStatus: 'updated', graphStatus: 'updated', dashboardStatus: 'updated' })
      } else {
        this.props.addUpdateOkrDashboardRedux({ fyId: e.itemData, monthlyOkrMonthStatus: 'updated', graphStatus: "updated", dashboardStatus: 'updated' })
      }
    }
  }
  selected = (crumb) => {
    const { empName, isEmpName, isPeopleLeader, dashboardTabs, authUser, empId } = this.props; // NOSONAR
    if (crumb === "My Team") {
      this.props.addUpdateOkrDashboardRedux({ dashboardTabs: 'team-rating', isEmpName: true });
    }
    if (isEmpName && authUser !== empId && crumb !== "My Team") {
      this.props.addUpdateOkrDashboardRedux({ dashboardTabs: 'team-member-rating', isEmpName: false });
    }
    if (isEmpName && authUser === empId && crumb !== "My Team") {
      this.props.addUpdateOkrDashboardRedux({ dashboardTabs: 'self-rating', isEmpName: false });
    }
    if (crumb === "Rating") {
      this.props.addUpdateOkrDashboardRedux({ dashboardTabs: 'performance-potential', isEmpName: true });
    }
    if (crumb === "Self") {
      this.props.addUpdateOkrDashboardRedux({ navToSelfRatingFlag: 'updated', dashboardTabs: "self-rating" });
    }
    if (crumb === 'OKR Dashboard' && isPeopleLeader && dashboardTabs === 'self-rating') {
      this.props.addUpdateOkrDashboardRedux({ dashboardTabs: 'self-rating' });
    }


  }

  render() { // NOSONAR
    const { showMonths, crumbs, height, windowAdjust } = this.state;
    const { dashboardTabs, monthOfRating, fyId, teamMemberIds, empName, isPeopleLeader, finacialYearData, empId, authUser, currentFinancialYearId } = this.props;
    const userName = isPeopleLeader && (empId !== authUser) ? empName : decodeString(localStorage.getItem("user_nm"));
    const financialYear = currentFinancialYearId === fyId ? "Current" : "Past";
    const financialYears = this.getFinancialYear(fyId);
    const financialYearStatus = currentFinancialYearId === fyId ? "Current" : "Past";
    const heightAdjustment = !isPeopleLeader ? showMonths ? height + 55 : windowAdjust + 4 : showMonths ? height : windowAdjust;
    const landingMessage = dashboardTabs === 'self-rating' ? landingMessageSelfRatingView : landingMessageTeamRatingView;
    const subHeader = <div className='new-productivity-allocation-no-data-sub-header pt-lg'>{landingMessage}</div>;

    return (
      <div className="okr-dashboard-container">
        {
          (
            // dashboardTabs !=='team-rating' || 
            dashboardTabs === 'performance-potential' || dashboardTabs === 'team-member-rating') && (isPeopleLeader || dashboardTabs === 'performance-potential') &&
          <Breadcrumb crumbs={crumbs} selected={this.selected} dashboardTabs={dashboardTabs} empName={empName} isPeopleLeader={isPeopleLeader} />
        }
        {
          (dashboardTabs === 'team-rating' || dashboardTabs === 'self-rating') && isPeopleLeader &&
          <div className='pt-md pr-md pb-md pl-md'>
            <OKRDashboardHeaderTabs showMonths={showMonths} onChangeShowMonth={this.onChangeShowMonth} />
          </div>
        }
        {/* Self view  and performance and potential view header */}
        {
          (((dashboardTabs === 'self-rating' || dashboardTabs === 'performance-potential') && !isPeopleLeader) || ((dashboardTabs === 'team-member-rating' || dashboardTabs === 'performance-potential') && isPeopleLeader)) &&
          // (dashboardTabs ==='team-member-rating' || dashboardTabs ==='performance-potential') && !isPeopleLeader &&
          <div className='pt-md pr-md pb-md pl-md'>
            <div>
              <div className="dashboard-header-tabs" style={{ minHeight: showMonths ? 80 : 60 }}>
                {
                  showMonths ?
                    <div className='pt-lg'>
                      <div className='mb-sm self-rating-header' style={{ fontSize: 16 }}>
                        <div className='col-2 col-lg-2 col-md-6 col-sm-6 col-xs-12' style={{ marginBottom: -22 }}>
                          <ComboBoxComponent
                            allowCustom={false}
                            fields={{ value: "Id", text: "Name" }}
                            floatLabelType="Auto"
                            dataSource={finacialYearData}
                            name={`fyIdrError`}
                            id={`cmbfyId`}
                            data-msg-containerid={`fyIdrError`}
                            change={(e) => this.onChangeData(e, 'financial')}
                            cssClass="e-outline"
                            placeholder="Financial Year  *"
                            value={fyId}
                          />
                          <div
                            id="fyIdrError"
                            className="e-input-group e-float-icon-left error-div"
                          />
                        </div>
                        <div style={{ paddingTop: ".5rem" }}>
                          {userName} - {financialYear} Rating
                        </div>
                      </div>
                    </div>
                    :
                    <div className='pt-lg'>
                      <span className='ml-lg mb-sm' style={{ fontSize: 16 }}>
                        Financial Yearrrr <span
                          style={{ fontWeight: 'bold' }}> {financialYears}
                        </span>
                        <span>
                          {" | "}
                          {userName} - {financialYearStatus} Rating
                        </span>
                      </span>
                    </div>
                }

                <div style={{ cursor: 'pointer', position: 'absolute', right: 30, top: 15, zIndex: 10 }} onClick={() => this.onChangeShowMonth(!showMonths)}>
                  {
                    showMonths ?
                      <>
                        <span style={{ color: '#5A5A5D' }}>Close</span> <i className="fas fa-chevron-up" style={{ color: '#B7B7BB' }}></i>
                      </>
                      :
                      <>
                        <span style={{ color: '#5A5A5D' }}>Click to open filters &nbsp;</span>
                        <i className="fas fa-chevron-down" style={{ color: '#B7B7BB' }}></i>
                      </>
                  }
                </div>
              </div>
            </div>
          </div>
        }
        {
          //No Data found!
          (((dashboardTabs === 'self-rating') && (fyId === null || empId === '')) || (dashboardTabs === 'team-rating' && (monthOfRating === 0 || fyId === null || teamMemberIds === ''))) ?
            <div className="dashboard-components mr-md mb-md ml-md">
              <div
                id='NoData'
                style={{
                  height: heightAdjustment,
                  overflowY: "hidden",
                  overflowX: "hidden",
                  borderRadius: 6
                }}
              >
                <div style={{ marginTop: '8%' }}>
                  <div className='new-productivity-allocation-no-data-header'>Welcome! This is where your Team members performance journey begins!</div>
                  <div>{subHeader}</div>
                </div>
              </div>
            </div>

            :
            <>
              {
                dashboardTabs === 'team-rating' &&
                <div className="dashboard-components mr-md mb-md ml-md" style={{ height: showMonths ? height : windowAdjust }}>
                  <TeamView showMonths={showMonths} />
                </div>
              }
              {
                (dashboardTabs === 'self-rating' || dashboardTabs === 'team-member-rating') &&
                <div className="dashboard-components mr-md mb-md ml-md"
                  style={{ height: showMonths ? height + 74 : windowAdjust - 19 }}
                >
                  <SelfView showMonths={showMonths} />
                </div>
              }
              {
                dashboardTabs === 'performance-potential' &&
                <div className="dashboard-components mr-md mb-md ml-md" style={{ height: showMonths ? height + 75 : windowAdjust + 4 }}>
                  <PerformancePotential showMonths={showMonths} />
                </div>
              }
            </>
        }
      </div>
    )
  }
}

const mapStateToProps = ({ okrdashboardreducer, auth, meetingmaster }) => {
  const { authUser } = auth;
  const { finacialYearData, currentFinancialYearId } = meetingmaster;
  const { dashboardTabs, fyId, teamMemberIds, monthOfRating, empId, empName, dashboardUserList, isPeopleLeader, isEmpName, navToSelfRatingFlag, performanceType } = okrdashboardreducer;
  return { dashboardTabs, authUser, monthOfRating, fyId, teamMemberIds, empId, finacialYearData, currentFinancialYearId, empName, dashboardUserList, isPeopleLeader, isEmpName, navToSelfRatingFlag, performanceType }
};
export default connect(mapStateToProps, { addUpdateOkrDashboardRedux })(
  OKRDashboardContainer
);

