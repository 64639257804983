import React, { useEffect, useState } from "react";
import { ComboBoxComponent } from "@syncfusion/ej2-react-dropdowns";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import { connect } from "react-redux";
import { addEditExceptionalClusterData,updateLnAData } from "../../redux/actions/lna/lnaaction";
import LnAServices from "../../services/lna/lnaservice";
import { FormValidator } from "@syncfusion/ej2-inputs";
import UploadDocuments from "../okrratingcomponents/uploaddocuments";
import { getMySqlDateFormat } from "../../app/other/commonfunction";
import { createImageFilepathForLPView } from "../okrratingcomponents/ratingcommonfun";
import {
  enterSymbol,
  enterDate,
  enterCoutnry,
  pleaseEnterAttendanceType,
  pleaseEnterDayBreak,
  enterDay,pleaseEnterExceptionalAttendanceName,
  pleaseEnterSymbol,
  regexOnlyLettersAndSpaces,
  selectDayBreak,
} from "../../assets/constants/constants";
import ProgressBar from "../progressbar/progressbar";
const SAMPLECSS = `
input.e-input, textarea.e-input, .e-input-group, .e-input-group.e-control-wrapper {
  font-size: 12px;
}
`
const ExceptionalCluster = (props) => {
  //NOSONAR
  let {
    officeCountry,
    exceptionalAttdData,
    officeLocation,
    dayBreakData,
    authUser,
    openPopup,
    editClusterId,
  } = props;


  const [changedData, setChangedData] = useState(false);
  const [cityList, setCityList] = useState([]);
  const [initialLoad, setInitialLoad] = useState(false)
  const [state, setState] = useState({
    cityId: null,
    // cityName: null,
    countryId: null,
    // countryName: null,
    date: null,
    day: null,
    // dayBreak: null,
    dayBreakId: null,
    isActive: "1",
    exceptMasterUid: null,
    exceptDescription: null,
    exceptMasterName: null,
    exceptSymbol: null,
    exceptionalType: "",
    exceptionalDoc: null,
    exceptionalPolicyDoc: [],
    fileName: "",
    oldFileStatus: "",
  });

  let validOptions = {
    rules: {
      exceptionalNameErr: {
        required: [true, pleaseEnterExceptionalAttendanceName],
        regex: [regexOnlyLettersAndSpaces, pleaseEnterExceptionalAttendanceName],
        minLength: [1, pleaseEnterExceptionalAttendanceName],
        maxLength: [50, "Should be minimum of 50 characters only."]
      },
      symbolErr: {
        required: [true, pleaseEnterSymbol],
      },
      dateErr: {
        required: [true, enterDate],
      },
      dayErr: {
        required: [true, enterDay],
      },
      countryErr: {
        required: [true, enterCoutnry],
      },
      selectTypeErr: {
        required: [true, pleaseEnterAttendanceType],
      },
      selectDayBreakErr: {
        required: [true, selectDayBreak],
      },
    },
  };


  useEffect(() => {
    props.updateLnAData({ popUpSource: 'Exceptional Attendance Master' })
    getData();
  }, []);

  useEffect(() => {
    getData();
  }, [editClusterId, openPopup]);

  useEffect(() => {
    if (props.lnaMasterFlag === "updated")
      props.changePopUpHandler("close");
  }, [props.lnaMasterFlag]);


  useEffect(() => {
    setTimeout(() => {
      props.setSubmitClick(saveExceptionalClusterData);
    }, 100);
  }, [state]);

  useEffect(() => {
    getCityList(state.countryId);
  }, [state.countryId]);

  const getData = () => {
    if (openPopup === "add") {
      resetData()
    }
    else {
      editClusterId !== null &&
        (async () => {
          setInitialLoad(true)
          const ExceptionalClusterData =
            await LnAServices.getUniqueExceptionalClusterData(
              authUser,
              editClusterId
            );

          const {
            cityId,
            countryId,
            date,
            day,
            dayBreakId,
            isActive,
            exceptDescription,
            exceptMasterName,
            exceptSymbolId,
            tType,

            exceptionalDoc,
            fileName,
          } = ExceptionalClusterData.Result[0];


          cityId && getCityList(countryId)

          let data = {
            cityId,
            countryId,
            date,
            day,
            dayBreakId,
            isActive,
            exceptMasterUid: editClusterId,
            exceptDescription,
            exceptMasterName,
            exceptSymbol: exceptSymbolId,
            exceptionalType:tType,
            exceptionalDoc,
            fileName,
            oldFileStatus: fileName,
          };
          setInitialLoad(false)
          setState((preState) => ({ ...preState, ...data }));

          setTimeout(() => {
            const addFormObject = new FormValidator("#exceptionalclusterform", validOptions);
            addFormObject.validate();
          }, 100)
        })();
    }
  };

  const resetData = () => {
    let data = {
      cityId: null,
      countryId: null,
      date: null,
      day: null,
      dayBreakId: null,
      isActive: "1",
      exceptMasterUid: null,
      exceptDescription: null,
      exceptMasterName: null,
      exceptSymbol: null,
      exceptionalType: "",
      exceptionalDoc: null,
      exceptionalPolicyDoc: [],
      fileName: "",
      oldFileStatus: "",
    };
    setState((preState) => ({ ...preState, ...data }));
  }

  const getCityList = (pCountryId) => {
    const cityListArray = [];
    officeLocation.forEach((item) => {
      if ((item.optional1) === (pCountryId !== null && pCountryId.toString())) {
        cityListArray.push(item);
      }
    });
    setCityList(cityListArray);
  };

  const setDay = (e) => {
    let index = new Date(e.value).getDay();

    const weekDay = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    let day = weekDay[index];

    setState((preState) => ({ ...preState, day }));
  };

  const selectedFiles = (fileNms) => {
    try {
      setState((preState) => ({ ...preState, exceptionalPolicyDoc: fileNms }));
      setTimeout(() => {
        setChangedData(!changedData);
      });
    } catch (error) { }
  };
  const removeFile = () => {
    setState(preState => ({ ...preState, fileName: "" }))
  }
  const documentDetails = (currentTime, file) => {
    setState((prevState) => ({ ...prevState, fileName: file }));
  };

  const inputChangeHandler = (e, type) => {
    try {
      let options = {};
      switch (type) {
        case "exceptional":
          setState((prevState) => ({
            ...prevState,
            exceptMasterName: e.value.trim(),
          }));
          options = validOptions.rules["exceptionalNameErr"]
          break;
        case "isActive":
          setState({
            ...state,
            isActive: e.checked === true ? "1" : "0",
          });
          break;
        case "symbol":
          setState((preState) => ({
            ...preState,
            // exceptSymbol: e.itemData.Id,
            exceptSymbol: e.value,
          }));
          options = {
            rules: {
              symbolErr: {
                required: [true, enterSymbol],
              },
            },
          };
          break;
        case "description":
          setState((prevState) => ({
            ...prevState,
            exceptDescription: e.value && e.value.trim(),
          }));
          break;
        case "date":
          setState({
            ...state,
            date: e.value,
          });
          options = {
            rules: {
              dateErr: {
                required: [true, enterDate],
              },
            },
          };
          setDay(e);
          break;
        case "day":
          setState((prevState) => ({ ...prevState, day: e.value }));
          break;
        case "country":
          setState({
            ...state,
            countryId: e.value !== null ? e.value : "",
            cityId: null
          });
          getCityList(e.value);


          options = {
            rules: {
              countryErr: {
                required: [true, enterCoutnry],
              },
            },
          };
          break;
        case "location":
          setState({
            ...state,
            cityId: e.value !== null ? e.value : null,
          });
          break;
        case "exceptionalType":
          setState({
            ...state,
            exceptionalType: e.value,
          });
          options = {
            rules: {
              selectTypeErr: {
                required: [true, pleaseEnterAttendanceType],
              },
            },
          };
          break;
        case "daybreak":
          setState({
            ...state,
            dayBreakId: e.value !== null ? e.value : "",
          });
          options = {
            rules: {
              selectDayBreakErr: {
                required: [true, pleaseEnterDayBreak],
              },
            },
          };
          break;
        default:
          options = {};
      }
          
      setTimeout(() => {
        const addFormObject = new FormValidator("#exceptionalclusterform", options);
        addFormObject.validate();
      }, 100);
    } catch (error) {
      console.log("🚀 ~ file: exceptionalcluster.jsx:307 ~ inputChangeHandler ~ error", error);
    }
  };

  const saveExceptionalClusterData = () => {
    const {
      cityId,
      // cityName,
      countryId,
      // countryName,
      date,
      day,
      // dayBreak,
      dayBreakId,
      // fyId,
      // hrApprovalRequired,
      isActive,
      exceptMasterUid,
      exceptDescription,
      exceptMasterName,
      exceptSymbol,
      exceptionalType,
      // supervisorApprovalRequired,
      exceptionalDoc,
      fileName,
    } = state;

    const addFormObject = new FormValidator("#exceptionalclusterform", validOptions);

    if (addFormObject.validate()) {
      setInitialLoad(true);

      let exceptionalClusterSaveData = {
        userId: authUser,
        type: exceptMasterUid > 0 ? "EDIT" : "ADD",
        cityId,
        countryId,
        date: getMySqlDateFormat(date),
        day,
        dayBreakId,
        isActive,
        exceptMasterUid,
        exceptDescription,
        exceptMasterName,
        exceptSymbol,
        exceptionalType,
        // supervisorApprovalRequired,
        exceptionalDoc,
        fileName,
      };
      props.addEditExceptionalClusterData(exceptionalClusterSaveData);
      setTimeout(() => {
        setInitialLoad(false)
      }, 10)
    }


  };

  const descriptionTextBox = `.e-outline textarea, textarea.e-outline, .e-outline.e-float-input textarea, .e-outline.e-float-input.e-control-wrapper textarea, .e-outline.e-float-input.e-input-group textarea {
    box-sizing: border-box;
    min-height: 100px;
  }`
  return (
    <div
      id="exceptionalclusterform"
      style={{ height: "61.5vh", overflowY: "scroll" }}
    >
      <style>{SAMPLECSS}</style>
      {/* <div className='col-sm-12 col-md-12 col-lg-12' style={{ marginBottom: "0px" }} > */}
      <div style={{ padding: "16px" }}>
        <div
          className="col-xs-12 col-sm-12 col-lg-12 col-md-12   atc-no-paddding"
          style={{ marginBottom: "0" }}
        >
          <TextBoxComponent
            data-msg-containerid="exceptionalNameErr"
            id="exceptionalName"
            value={state.exceptMasterName}
            name="exceptionalNameErr"
            autocomplete={'off'}
            placeholder="Attendance Name *"
            floatLabelType="Auto"
            cssClass="e-outline"
            onChange={(e) => inputChangeHandler(e, "exceptional")}
            htmlAttributes={{ maxlength: 50 }}
          />
          <div id="exceptionalNameErr" className="error-div" />
        </div>
        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 mt-lg atc-no-paddding">
          <ComboBoxComponent
            // <TextBoxComponent
            id="symbolName"
            data-msg-containerid="symbolErr"
            name="symbolErr"
            dataSource={exceptionalAttdData}
            fields={{ text: "Name", value: "Id" }}
            value={state.exceptSymbol}
            autocomplete={'off'}
            placeholder="Symbol *"
            floatLabelType="Auto"
            cssClass="e-outline"
            allowCustom={false}
            onChange={(e) => inputChangeHandler(e, "symbol")}
            htmlAttributes={{ maxlength: 100 }}
          />
          <div id="symbolErr" className="error-div" />
        </div>

        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 mt-lg atc-no-paddding weekdayDescription">
          <style>{descriptionTextBox}</style>
          <TextBoxComponent
            style={{ minHeight: 100 }}
            id={'description'}
            placeholder={"Description "}
            cssClass="e-outline textarea"
            floatLabelType="Auto"
            autocomplete={'off'}
            name="descriptionErr"
            data-msg-containerid="descriptionErr"
            multiline
            inputProps={{
              required: true,
              minLength: 0,
            }}
            htmlAttributes={{ maxlength: 500 }}
            onChange={(e) => inputChangeHandler(e, "description")}
            value={state.exceptDescription}
          />
        </div>
      </div>

      <div className="col-xs-12 col-sm-12 col-lg-12 col-sm-12 mt-lg">
        <div
          className="col-xs-6 col-sm-6 col-lg-6 col-md-6"
          style={{ paddingLeft: "0px", paddingRight: "0px" }}
        >


          <DatePickerComponent
            style={{ width: "100%" }}
            id="date"
            name="dateErr"
            data-msg-containerid="dateErr"
            format="dd-MMM-yyyy"
            value={state.date}
            floatLabelType="Auto"
            cssClass="e-outline"
            firstDayOfWeek={1}
            placeholder="Date *"
            onChange={(e) => inputChangeHandler(e, "date")}
          />
          <div
            id="dateErr"
            className="e-input-group e-float-icon-left error-div"
          />
        </div>
        <div
          className="col-xs-6 col-sm-6 col-lg-6 col-md-6"
          style={{ paddingRight: "0px" }}
        >
          <TextBoxComponent
            // <ComboBoxComponent
            data-msg-containerid="dayErr"
            id="day"
            autocomplete={'off'}
            // value={getDaysFromDate(date)}
            value={state.date && state.day}
            readonly={true}
            name="dayErr"
            placeholder="Day *"
            floatLabelType="Auto"
            cssClass="e-outline"
            onChange={(e) => inputChangeHandler(e, "day")}
            htmlAttributes={{ maxlength: 100 }}
          />
          <div
            id="dayErr"
            className="e-input-group e-float-icon-left error-div"
          />
        </div>
      </div>

      <div
        className="col-xs-12 col-sm-12 col-lg-12 col-sm-12"
        style={{ marginBottom: "0px" }}
      >
        <div
          className="col-xs-6 col-sm-6 col-lg-6 col-md-6"
          style={{ paddingLeft: "0px", paddingRight: "0px" }}
        >
          {officeCountry && (
            <ComboBoxComponent
              id="country"
              dataSource={officeCountry}
              fields={{ text: "Name", value: "Id" }}
              value={state.countryId && parseInt(state.countryId)}
              data-msg-containerid="countryErr"
              name="countryErr"
              // allowFiltering={true}
              // allowCustom={false}
              floatLabelType="Auto"
              cssClass="e-outline"
              placeholder="Country *"
              allowCustom={false}
              onChange={(e) => inputChangeHandler(e, "country")}
            />
          )}
          <div
            id="countryErr"
            className="e-input-group e-float-icon-left error-div"
          />
        </div>

        <div
          className="col-xs-6 col-sm-6 col-lg-6 col-md-6"
          style={{ paddingRight: "0px" }}
        >
          {(cityList.length > 0 || props.openPopup === 'add') && <ComboBoxComponent
            id="locationCity"
            // dataSource={state.countryId != null && cityList.length > 0 ? cityList : officeLocation}
            dataSource={cityList.length > 0 && cityList}
            fields={{ text: "Name", value: "Id" }}
            value={state.cityId}
            data-msg-containerid="locationCityErr"
            name="locationCityErr"
            // allowFiltering={true}
            // allowCustom={false}
            floatLabelType="Auto"
            cssClass="e-outline"
            placeholder="Location/City "
            allowCustom={false}
            onChange={(e) => inputChangeHandler(e, "location")}
          />}
        </div>
      </div>

      <div className="col-xs-12 col-sm-12 col-lg-12 col-xl-12">
        <div className="col-sm-12 col-md-12 col-lg-12">
          <ComboBoxComponent
            id="selectType"
            dataSource={[
              { Name: "Fixed", Id: "1" },
              { Name: "Optional", Id: "0" },
            ]}
            fields={{ text: "Name", value: "Id" }}
            value={state.exceptionalType}
            data-msg-containerid="selectTypeErr"
            name="selectTypeErr"
            // allowFiltering={true}
            // allowCustom={false}
            floatLabelType="Auto"
            cssClass="e-outline"
            placeholder="Select Type *"
            allowCustom={false}
            onChange={(e) => inputChangeHandler(e, "exceptionalType")}
          />
          <div id="selectTypeErr" className="error-div" />
        </div>

        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12  mt-lg atc-no-paddding">
          <ComboBoxComponent
            id="selectDayBreak"
            dataSource={dayBreakData}
            fields={{ text: "Name", value: "Id" }}
            value={state.dayBreakId}
            data-msg-containerid="selectDayBreakErr"
            name="selectDayBreakErr"
            // allowFiltering={true}
            // allowCustom={false}
            floatLabelType="Auto"
            cssClass="e-outline"
            placeholder="Select Day Break *"
            allowCustom={false}
            onChange={(e) => inputChangeHandler(e, "daybreak")}
          />
          <div id="selectDayBreakErr" className=" error-div" />
        </div>
      </div>

      <div
        className="col-xs-12 col-sm-12 col-lg-12 col-xl-12"
        style={{ marginBottom: "15px" }}
      >
        <div
          className="col-xs-12 col-sm-12 col-lg-12 col-md-12"
          style={{ paddingLeft: 0 }}
        >
          <div
            style={{
              fontSize: ".95rem",
              color: "#939399",
              fontWeight: 500,
              padding: ".5rem 0",
              marginTop: "0rem",
            }}
          >
            Attach Policy
          </div>

          <UploadDocuments
            type={"exceptional"}
            module={'lna'}
            allowmultiple={false}
            selectedFiles={selectedFiles}
            // fileList={
            //   !state.exceptionalPolicyDoc ? [] : state.exceptionalPolicyDoc
            // }
            fileList={[]}
            apiUrl={"lnaApiUrl"}
            documentDetails={documentDetails}
          />
        </div>
        {state.fileName !== null && state.oldFileStatus !== null ? (
          <div style={{ paddingLeft: 8 }}>
            {createImageFilepathForLPView(
              [].concat(state.fileName),
              "document/lna/exceptional",
              "lnaApiUrl", removeFile
            )}
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="loader-new1" id="spinnerContainer" >
        {initialLoad && <ProgressBar />}
      </div>
    </div>
  );
};

const mapStateToProps = ({ auth, lnareducer, meetingmaster }) => {
  const { authUser } = auth;

  const { officeCountry, officeLocation, dayBreakData, symbolData, lnaMasterFlag } =
    lnareducer;
  const { exceptionalAttdData } = symbolData;

  return {
    authUser,
    officeCountry,
    officeLocation,
    dayBreakData,
    exceptionalAttdData, lnaMasterFlag
  };
};

export default connect(mapStateToProps, {
  addEditExceptionalClusterData,
  updateLnAData,
})(ExceptionalCluster);
