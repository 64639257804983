import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import axios from "axios";
import {
  MEETING_SAVE,
  MEETING_ACCEPTANCE,
  SAVE_MEETING_TEMPLATE,
  MEETING_CANCEL,
} from "../constant/meetingbookingconstant";
import {
  meetingSaved,
  getMeetingDetails,
  meetingSaveError,
} from "../actions/meetingbookingaction";
import { urlConfig } from "../../config/config";
import { getNotify } from "../../app/other/commonfunction";
import {
  ERROR,
  SUCCESS,
  meetingSavedSuccessfully,
  partialAttendeeMarkedFullAttendee,
  templateSavedSuccessfully,
  templateSaveError,
  meetingDidNotAttendedSuccessfully,
  MeetingSlotNotAvailable,
  MeetingSlotNotAvailableOneOrMore,
  WARNING,
} from "../../assets/constants/constants";

const saveMeetingDetailsRequest = async (data) => {
  const meetingmode =
    data.meetingmode === "A" ? "actualmeeting" : "planmeeting";
  // eslint-disable-next-line
  delete data.meetingmode;

  if (data.meeting_id > 0) {
    const mId = data.meeting_id;
    // eslint-disable-next-line
    delete data.meeting_id;
    // eslint-disable-next-line
    return await axios //NOSONAR
      .post(`${urlConfig.apiurl + meetingmode}/${mId}`, data, {
        headers: {
          authorization: localStorage.getItem("jwtToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((result) => result)
     
      .catch((error) => error.response);
  }
  // eslint-disable-next-line
  delete data.meeting_id;
  // eslint-disable-next-line
  return await axios //NOSONAR
    .post(urlConfig.apiurl + meetingmode, data, {
      headers: {
        authorization: localStorage.getItem("jwtToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((result) => result)
    .catch((error) => error.response);
};

const meetingAcceptanceActionRequest = async (payload) => {
  let data;
  if (payload.meetingmode === "A") {
    // eslint-disable-next-line
    data = `actualmeeting/decline/${payload.user_id}/${payload.meetingId}/${payload.status}`;
  } else {
    // eslint-disable-next-line
    data = `planmeeting/accept/${payload.user_id}/${payload.meetingId}/${payload.status
      }/${payload.volume}${payload.status === 3 ? `/${payload.starttime}` : ""}`; // NOSONAR
  }
  // eslint-disable-next-line
  return await axios //NOSONAR
    .post(
      urlConfig.apiurl + data,
      {},
      {
        headers: {
          authorization: localStorage.getItem("jwtToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((result) => result)
    .catch((error) => error.response);
};

const meetingCancelActionRequest = async (data) => {
  const meetingmode =
    data.meetingmode === "A" ? "actualmeeting/delete/" : "planmeeting/delete/";
  // eslint-disable-next-line
  delete data.meetingmode;
  const mId = data.meeting_id;
  // eslint-disable-next-line
  delete data.meeting_id;
  // eslint-disable-next-line
  return await axios //NOSONAR
    .post(urlConfig.apiurl + meetingmode + mId, data, {
      headers: {
        authorization: localStorage.getItem("jwtToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((result) => result)
    .catch((error) => error.response);
};

function* saveMeetingDetails({ payload }) { // NOSONAR
  // eslint-disable-next-line
  const meetingMode = payload.meetingmode == undefined ? "P" : payload.meetingmode
  const meetingId = payload.meeting_id == undefined ? 0 : payload.meeting_id
  const saveMeetingData = yield call(saveMeetingDetailsRequest, payload);
  if (saveMeetingData.status === 200) {
    if (saveMeetingData.data.Result === 'Unavailable') {
      const msg = saveMeetingData.data.UnavailableUsers === "single" ? MeetingSlotNotAvailable : MeetingSlotNotAvailableOneOrMore
      setTimeout(() => {
        getNotify(WARNING, msg);
      }, 100);
      yield put(meetingSaveError());
    } else {
      yield put(
        meetingSaved({
          outlookActionType: meetingId > 0 ? meetingMode + "EDIT" : meetingMode + "CREATE",
          meetingStatus: "updated",
          meetingId: saveMeetingData.data.isLeaveType === 998 ? 0 : saveMeetingData.data.MeetingId,
          seriesId: saveMeetingData.data.isLeaveType === 998 ? 0 : saveMeetingData.data.SeriesId,
          meetingmode: saveMeetingData.data.MeetingMode,
          bulkAttendeesFlag: false
        })
      );
      getMeetingNotification(saveMeetingData, payload)
    }


  } else {
    getNotify(ERROR, saveMeetingData.data.message.replace("payload: ", ""));
    yield put(meetingSaveError());
  }
}
const getMeetingNotification = (saveMeetingData, payload) => {
  if (typeof payload.is_drag_meeting === "undefined" || payload.is_drag_meeting === null) {
    setTimeout(() => {

      getNotify(SUCCESS, meetingSavedSuccessfully);
    }, 100);
  } else if (
    // eslint-disable-next-line
    typeof saveMeetingData.data.RoomChange !== undefined &&
    saveMeetingData.data.RoomChange !== ""
  ) {
    setTimeout(() => {
      getNotify(SUCCESS, saveMeetingData.data.RoomChange);
    }, 100);
  } else {
    if (saveMeetingData.data.PartialAttendee === 'Yes') {
      setTimeout(() => {
        getNotify(SUCCESS, partialAttendeeMarkedFullAttendee);
      }, 110);
    } else {
      setTimeout(() => {
        getNotify(SUCCESS, meetingSavedSuccessfully);
      }, 110);
    }
  }
}
function* meetingAcceptanceAction({ payload }) {
  const status = payload.status
  const mode = payload.meetingmode
  const meetingAction = yield call(meetingAcceptanceActionRequest, payload);
  if (meetingAction.status === 200) {
    if (meetingAction.data.MeetingMode === "A") {
      getNotify(SUCCESS, meetingDidNotAttendedSuccessfully);

      yield put(
        getMeetingDetails({
          meetingStatus: "updated",
          meetingId: meetingAction.data.PlanMeetingId,
          seriesId: 0,
          meetingmode: "P",
          outlookActionType: "",
        })
      );
    }
    let typeOfAction = ''
    if (status === 1 && mode === "P") {
      typeOfAction = "accept"
    }
    else if (status === 2 && mode === "P") {
      typeOfAction = "decline"
    }
    else if (status === 3 && mode === "P") {
      typeOfAction = "suggestTime"
    }

    yield put(
      getMeetingDetails({
        meetingStatus: "updated",
        meetingId: meetingAction.data.MeetingId,
        seriesId: meetingAction.data.SeriesId,
        meetingmode: meetingAction.data.MeetingMode,
        outlookActionType: typeOfAction
      })
    );
  } else {
    getNotify(ERROR, meetingAction.data.message);
  }
}
const saveMeetingTemplateActionRequest = async (payload) => {
  // eslint-disable-next-line
  return await axios //NOSONAR
    .post(`${urlConfig.apiurl}meetingtemplate/${payload.user_id}`, payload, {
      headers: {
        authorization: localStorage.getItem("jwtToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((result) => result)
    .catch((error) => error.response);
};
function* saveMeetingTemplateAction({ payload }) {
  // eslint-disable-next-line
  const saveMeetingTemplateData = yield call(
    saveMeetingTemplateActionRequest,
    payload
  );
  if (saveMeetingTemplateData.status === 200) {
    if (saveMeetingTemplateData.data.result[0].Result === "Success") {
      setTimeout(() => {
        getNotify(SUCCESS, templateSavedSuccessfully);
      }, 100);
    } else {
      setTimeout(() => {
        getNotify(ERROR, saveMeetingTemplateData.data.result[0].Reason);
      }, 100);
    }
  } else {
    setTimeout(() => {
      getNotify(ERROR, templateSaveError);
    }, 100);
  }
}

function* meetingCancelAction({ payload }) {
  const meetingAction = yield call(meetingCancelActionRequest, payload);

  if (meetingAction.status === 200) {
    if (typeof payload.series_id !== "undefined") {
      yield put(getMeetingDetails({ meetingStatus: "updated" }));
    }
  } else {
    getNotify(ERROR, meetingAction.data.message);
  }
}

export function* saveMeeting() {
  yield takeEvery(MEETING_SAVE, saveMeetingDetails);
}

export function* saveMeetingTemplate() {
  yield takeEvery(SAVE_MEETING_TEMPLATE, saveMeetingTemplateAction);
}

export function* meetingAcceptance() {
  yield takeEvery(MEETING_ACCEPTANCE, meetingAcceptanceAction);
}

export function* meetingCancel() {
  yield takeEvery(MEETING_CANCEL, meetingCancelAction);
}

export default function* rootSaga() {
  yield all([
    fork(saveMeeting),
    fork(meetingAcceptance),
    fork(meetingCancel),
    fork(saveMeetingTemplate),
  ]);
}
