import React, { Component } from 'react'
import  NotificationContainer  from "../../../components/notification/notifycontainer";
import LayerMapping from '../../../components/separation/orgchartadmin/layermapping'
import SubLayerMapping from '../../../components/separation/orgchartadmin/sublayermapping'
import '../tmlc/onboarding/onboarding.css'
import '../../../components/okrproductiveallocation/productiveallocation.css';
class Orgchartmapping extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      height: 182,
      isActive:false
    };
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
    try {
      this.setState({ isLoaded: true }, () => { document.getElementById("LayerMapping").style.display = "block" })

    } catch (error) {
      console.log(error, 'error')
    }
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));

  }


  updateDimensions() {
    // let update_width = window.innerWidth - 10; width: update_width,
    let update_height = window.innerHeight - 100;
    this.setState({ height: update_height });
  }


  openTab = (evt, tabName) => {
    if (tabName==="SubLayerMapping") 
      {
        this.setState({isActive:true})
      }
    else{
      this.setState({isActive:false})
    }
    let i;
    const event = evt;
    const tabcontent = document.getElementsByClassName("tabcontent");
    // eslint-disable-next-line
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }
    const tablinks = document.getElementsByClassName("tablinks");
    // eslint-disable-next-line
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }
    document.getElementById(tabName).style.display = "block";
    event.currentTarget.className += " active";
  };

  render() {
    return (
      <div
        className="col-12"
        style={{
          // paddingBottom: "16px",
          // paddingTop: "16px",
          // paddingLeft: "16px",
          // paddingRight: "16px",
          background: "#eee",
        }}
      >
       
        <div className="col-lg-12 col-md-12 col-sm-12">
          <div className="col-12" >
            <>
              <div className="it-tab">
                <button className={'col-lg-6 onboardingtab onboarding-tab tablinks active'}
                  onClick={(e) => this.openTab(e, "LayerMapping")}
                >
                  <b>Layer Mapping</b>
                </button>

                <button className={'col-lg-6 onboardingtab onboarding-tab tablinks'}
                  onClick={(e) => this.openTab(e, "SubLayerMapping")}
                >
                  <b> Sub Layer Mapping</b>
                </button>

              </div>
              <div id="SubLayerMapping" className="tabcontent">
                {this.state.isActive &&  <SubLayerMapping />}
              </div> 
              <div id="LayerMapping" className="tabcontent" >
                 {!this.state.isActive && <LayerMapping />}
              </div>

            </>
          </div>
          <NotificationContainer style={{ position: 'absolute' }} />
        </div>

      </div>
    )
  }
}

export default Orgchartmapping