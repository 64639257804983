/* Author : Prashant Waphare
 * Date : 17-04-2020
 * Description: This file use for pre offer candidate container.
 */

import * as React from "react";
import queryString from "query-string";
import { AccordionComponent } from "@syncfusion/ej2-react-navigations";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { connect } from "react-redux";
import NotificationContainer from "../../../../components/notification/notifycontainer";
import { Button, Dialog } from "@material-ui/core";
import { FormValidator } from "@syncfusion/ej2-inputs";
import { Redirect } from "react-router-dom";
import PropTypes from "prop-types";

import JSZip from 'jszip';
import JSZipUtils from 'jszip-utils';
import saveAs from 'save-as';
import { urlConfig } from '../../../../config/config'
import PreOfferBasicInformation from "../../../../components/preoffer/preofferbasicinformation";
import "../../../../components/preoffer/preoffer.css";
import {
  saveBasicInformation,
  saveBasicInformationInRedux,
} from "../../../../redux/actions/entryexit/onboardingaction";
import OnbordingToBeOffer from "../../../../services/entryexit/onbordingServices";
import {
  getNotify,
  // addDays
  encodeString,
  dencrypt,
  encrypt
  // decodeString
} from "../../../other/commonfunction";
import {
  SUCCESS,
  ERROR,
  pleaseEnterFirstName,
  pleaseEnterLastName,
  pleaseEnterMobileName,
  pleaseEnterEmailName,
  pleaseEnterValidEmailId,
  pleaseSelectHighestEducationQualification,
  // pleaseEnterHighestEducationQualificationSpecial,
  // please_enter_work_experiance,
  pleaseEnterAddress,
  pleaseEnterJobTitle,
  pleaseSelectEmployeeType,
  pleaseSelectBaseCountry,
  // pleaseEnterBaseLocation,
  pleaseSelectEntity,
  pleaseSelectServceDivisionSalesUnit,
  pleaseSelectReportingSupervisor,
  pleaseSelectRecruitmentManager,
  pleaseSelectGender,
  pleaseSelectSalutation,
  // pleaseUploadImage,
  pleaseEnterLegalName,
  pleaseEnterState,
  pleaseEnterCity,
  documentIntemation,
  WARNING,
  pleaseSelectTenureEndDate,
  pleaseSelectLayer,
  pleaseSelectSubLayer,
  pleaseSelectCurrency,
  pleaseSelectCTCFrequency
} from "../../../../assets/constants/constants";
import Checklist from "../../../../components/employeeprofiletabs/checklist/checklist";
import {
  NEW_CANDIDATE,
  EXISTING_CANDIDATE, EMPLOYEE_UPLOAD, NEW_CANDIDATE_UPLOAD
} from "../../../../redux/constant/entryexit/candidateconstant";
import {
  DialogContent,
  DialogActions,
  DialogTitle,
} from "../../../../components/customdialog/customdialog";
import { ON_BOARDING } from "../../../../redux/constant/meetingcategoryconstant";
import { setMeetingCategory } from '../../../../redux/actions/meetingcategoryaction';

class PreOffer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      openDialog: false,
      redirect: null,
      docIntemationDialog: false,
      docData: [],
      expanded: true
    };
    this.count = 0;
    this.downLoadAllFiles = this.downLoadAllFiles.bind(this);
  }


  // eslint-disable-next-line
  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.status === "updated") {
      const params = queryString.parse(this.props.location.search);
      // eslint-disable-next-line
      this.props.saveBasicInformationInRedux({ status: "" });
      localStorage.setItem("meeting_category", encodeString("Onboarding"));
      const path = `/onboard?id=${encrypt(parseInt(ON_BOARDING))}&appId=${params.appId}`;
      this.setState({ redirect: path });
    }
  }

  onCreated = () => {
    setTimeout(() => {
      if (this.load !== null) {
        // eslint-disable-next-line
        for (let i = 0; i <= this.load.items.length - 1; i++) {
          this.load.items[i].expanded = true;
        }
      }
    }, 500);
  };

  saveSendLink = () => {
    const {
      authUser,
      candidateid,
      salutation,
      firstName,
      lastName,
      servceDivisionSalesUnit,
      reportingSupervisor,
      recruitmentManagerId,
      readyToJoinDate,
      entity,
      role,
      emailId,
      empType,
      baseCounty,
      baseLocation,
      mobileNumber,
      alternetNumber,
      band,
      middleName,
      gender,
      dateOfBirth,
      panNumber,
      adharNumber,
      uanNumber,
      highestEducationQualification,
      highestEducationQualificationSpecial,
      workExperiance,
      costSharing,
      address,
      title,
      imageName,
      // eslint-disable-next-line
      send_links,
      address2,
      city,
      pincode,
      state,
      other,
      persCountryCode,
      altCountryCode,
      functionId,
      legalName,
      sourceCategory,
      jobPortalId,
      referralId,
      agencyName,
      tctc,
      oti,
      fixedCTC,
      layerId,
      subLayerId,
      addressCountryId,
      tenureEndDate,
      currencyId,
      ctcFrequency,
      subFunction,
      recruiterName,
      referralName,
      reportingSup } = this.props;
    const addBasicInformation = {
      candidate_id: candidateid,
      userId: authUser,
      salutationId: salutation,
      varCandFirstName: firstName,
      varCandLastName: lastName,
      departmentId: servceDivisionSalesUnit,
      reportingToId: reportingSupervisor,
      recruitmentManagerId: recruitmentManagerId,
      dtOfferedDate: Date.parse(new Date()) / 1000,
      dtJoiningDate: readyToJoinDate,
      pnlId: entity,
      subPnlId: subFunction,
      roleId: role,
      varContactNo: "987654321",
      varEmailAddress: emailId,
      candJoinStatus: 1,
      employeeTypeId: empType,
      baseCountryId: baseCounty,
      varBaseLocation: baseLocation,
      varMobileNo: mobileNumber.toString(),
      varAltMobileNo: alternetNumber === null ? "" : alternetNumber.toString(),
      dtReadyToJoinDate: readyToJoinDate,
      gradeId: band,
      txtDocumentComments: "Pending",
      varCandMiddleName: middleName,
      genderId: gender,
      dtDob: dateOfBirth,
      varPanNumber: panNumber,
      varAadharNumber: adharNumber,
      varUanNumber: uanNumber,
      highestEducationQualificationId: highestEducationQualification,
      varHighestEducationQualificationSpecial: highestEducationQualificationSpecial,
      decWorkExperience: workExperiance,
      decCostSharing: parseFloat(costSharing),
      txtContactAddresss: address,
      varJobTitle: title,
      varPhotoNm: imageName,
      varDocCartegories: send_links.toString(),
      varContactAddresss2: address2,
      varContactCity: city,
      varContactPincode: pincode,
      varAddressState: state,
      varOtherIdentity: other,
      varCCMobileNo: persCountryCode,
      varCCAltMobileNo: altCountryCode,
      functionId: functionId,
      varLegalName: legalName,
      sourceCategoryId: sourceCategory,
      jobPortalId: jobPortalId,
      referralId: referralId,
      varAgencyName: agencyName,
      decTCTC: tctc,
      decOTI: oti,
      decFixedCTC: fixedCTC,
      layerId: layerId,
      subLayerId: subLayerId,
      addressCountryId: addressCountryId,
      dtTenureEndDate: tenureEndDate,
      currencyId,
      ctcFrequency,
      varRecruiterName: recruitmentManagerId ? null : recruiterName,
      varReferralName: referralId ? null : referralName,
      varReportingToName: reportingSupervisor ? null : reportingSup
    };
    // eslint-disable-next-line
    this.props.saveBasicInformation(addBasicInformation);
    this.setState({ openDialog: false });
  };

  openDialog = () => { // NOSONAR
    const {
      salutation, firstName, lastName, servceDivisionSalesUnit, reportingSupervisor, recruitmentManagerId, entity, emailId, empType, baseCounty, workExperiance, mobileNumber,
      gender, tenureEndDate, layerId, subLayerId,
      panNumber, adharNumber,
      highestEducationQualification, address, title, city, pincode, state, readyToJoinDate, dateOfBirth, legalName, sourceCategory, addressCountryId, candidateid, docsSubmitted } = this.props;
    if (document.getElementById("readytojoindateErr") !== null) {
      document.getElementById("readytojoindateErr").innerHTML = "";
    }
    if (document.getElementById("dateofbirthErr") !== null) {
      document.getElementById("dateofbirthErr").innerHTML = "";
    }
    if (this.props.empType === 677 || this.props.empType === 747 || this.props.empType === 799 || this.props.empType === 798 || this.props.empType === 748 || this.props.empType === 749) {
      document.getElementById("tenureEndDateErr").innerHTML = "";
    }

    const options = {
      rules: {
        emptypeErr: { required: [true, pleaseSelectEmployeeType] },
        entityErr: { required: [true, "Please select business unit / group function"] },
        salesunitErr: {
          required: [true, "Please select practice area / sales unit / sub function"],
        },
        subFunction: {
          required: [true, "Please select sub BU / function"],
        },
        reportingtoErr: {
          required: [true, "Please select administrative reporting supervisor"],
        },
        basecountryErr: { required: [true, "Please select country of work"] },
        //   bandErr: { required: [true, pleaseSelectBand] },
        //   roleErr: { required: [true, pleaseEnterValidRole] },
        titleErr: { required: [true, "Please enter role / title"] },
        genderErr: { required: [true, pleaseSelectGender] },
        salutationErr: { required: [true, pleaseSelectSalutation] },
        firstnameErr: {
          required: [true, pleaseEnterFirstName],
          regex: ["^[A-z]+$", "Please enter valid name"],
        },
        middlenameErr: {
          regex: ["^[A-z]+$", "Please enter valid middle name"],
        },
        lastnameErr: {
          required: [true, pleaseEnterLastName],
          regex: ["^[A-z]+$", "Please enter valid last name"],
        },
        mobilenumberErr: {
          required: [true, pleaseEnterMobileName],
          number: [true, "Please enter valid mobile number"],
          // minLength: [10, "Please enter valid mobile number"],
          // maxLength: [10, "Please enter valid mobile number"],
        },
        alternatenumberErr: {
          number: [true, "Please enter valid alternate mobile number"],
          // minLength: [10, "Please enter valid alternate mobile number"],
          // maxLength: [10, "Please enter valid alternate mobile number"],
        },
        emailidErr: {
          required: [true, pleaseEnterEmailName],
          regex: [
            "^[a-zA-Z0-9_+&*-]+(?:\\." +
            "[a-zA-Z0-9_+&*-]+)*@" +
            "(?:[a-zA-Z0-9-]+\\.)+[a-z" +
            "A-Z]{2,7}$",
            pleaseEnterValidEmailId,
          ],
        },
        address1Err: { required: [true, pleaseEnterAddress] },
        pincodeErr: {
          required: [true, "Please enter pin code"],
        },
        cityErr: {
          required: [true, pleaseEnterCity],
        },
        stateErr: {
          required: [true, pleaseEnterState],
        },
        highesteduErr: {
          required: [true, pleaseSelectHighestEducationQualification],
        },
        //   functionErr: {
        //     required: [true, "Please select function"],
        //   },
        recruiterErr: {
          required: [true, pleaseSelectRecruitmentManager],
        },
        LegalNameErr: {
          required: [true, pleaseEnterLegalName],
          regex: ["^[A-z ]+$", "Please enter legal name"],
        },
        sourceCategoryErr: {
          required: [true, "Please select source category"],
        },
        tctcErr: {
          required: [true, "Please enter TCTC / OTE "],
          regex: ['^[0-9]+$', 'Please enter value in number format']
        },
        otiErr: {
          required: [true, "Please enter on target incentive (OTI)"],
          regex: ['^[0-9]+$', 'Please enter value in number format']
        },
        fixedCtcErr: {
          required: [true, "Please enter fixed ctc"],
          regex: ['^[0-9]+$', 'Please enter value in number format']
        },
        layerErr: {
          required: [true, "Please select layer"],
        },
        subLayerErr: {
          required: [true, "Please select sub layer"],
        },
        addressCountryErr: {
          required: [true, "Please select address country"],
        },
        persCountryCodeErr: {
          required: [true, "Please enter ISD code"]
        },
        currencyErr: {
          required: [true, pleaseSelectCurrency]
        },
        ctcFrequencyErr: {
          required: [true, pleaseSelectCTCFrequency]
        }
      },
    };

    if (!readyToJoinDate) {
      document.getElementById("readytojoindateErr").innerHTML = "Please select date of joining";
    }
    if (!dateOfBirth) {
      document.getElementById("dateofbirthErr").innerHTML = "Please select date of birth";
    }
    if (this.props.empType === 677 || this.props.empType === 748 || this.props.empType === 749 || this.props.empType === 747 || this.props.empType === 799 || this.props.empType === 798) {
      if (!tenureEndDate) {
        document.getElementById("tenureEndDateErr").innerHTML = "Please select tenure end date";
      }
    }
    if (addressCountryId === 101 && (empType === 677 || empType === 747 || empType === 746 || empType === 798) && (candidateid > 0 && candidateid !== undefined && candidateid !== null) && (docsSubmitted !== undefined && docsSubmitted !== null && docsSubmitted === '1')) {
      if (!panNumber) {
        document.getElementById("PANNumberErr").innerHTML = "Please enter PAN number";
      }
    }
    if (addressCountryId === 101 && (empType === 677 || empType === 747 || empType === 746 || empType === 798) && (candidateid > 0 && candidateid !== undefined && candidateid !== null) && (docsSubmitted !== undefined && docsSubmitted !== null && docsSubmitted === '1')) {
      if (!adharNumber) {
        document.getElementById("AadhaarNumberErr").innerHTML = "Please enter Aadhaar number";
      }
    }
    if (sourceCategory === 729) {
      options.rules["jobPortalErr"] = {
        required: [true, 'Please select job portal']
      }
    }
    if (sourceCategory === 730) {
      options.rules["referralErr"] = {
        required: [true, 'Please select refferal']
      }
    }
    if (sourceCategory === 731) {
      options.rules["agencyNameErr"] = {
        required: [true, 'Please enter agency name']
      }
    }

    if ((addressCountryId === 101 && (empType === 677 || empType === 747 || empType === 746 || empType === 798))) {
      options.rules.AadhaarNumberErr = {
        // required: [true, "Please enter Aadhaar number"],
        regex: [
          "^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$",
          "Please enter valid Aadhaar number",
        ],
      }
      options.rules.PANNumberErr = {
        regex: ["[A-Z]{5}[0-9]{4}[A-Z]{1}", "Please enter valid PAN number"],
        maxLength: [10, "Please enter valid PAN number"],
      }
      options.rules.UANNumberErr = {
        regex: ["^[0-9]{12}$", "Please enter valid UAN number"],
      }
    }

    const addFormObject = new FormValidator("#addCandidateForm", options);
    addFormObject.validate();
    if (empType === "") {
      getNotify(ERROR, pleaseSelectEmployeeType);
      return;
    }
    if (entity === "") {
      getNotify(ERROR, pleaseSelectEntity);
      return;
    }
    if (servceDivisionSalesUnit === "") {
      getNotify(ERROR, pleaseSelectServceDivisionSalesUnit);
      return;
    }
    if (reportingSupervisor === "") {
      getNotify(ERROR, pleaseSelectReportingSupervisor);
      return;
    }
    if (this.props.empType === 677 || this.props.empType === 747 || this.props.empType === 799 || this.props.empType === 798) {
      if (tenureEndDate === "" || tenureEndDate === null) {
        getNotify(ERROR, pleaseSelectTenureEndDate);
        return;
      }
    }
    if (baseCounty === "") {
      getNotify(ERROR, pleaseSelectBaseCountry);
      return;
    }
    if (readyToJoinDate === "" || readyToJoinDate === null) {
      getNotify(ERROR, "Please select date of joining");
      return;
    }
    if (layerId === "" || layerId === null) {
      getNotify(ERROR, pleaseSelectLayer);
      return;
    }
    if (subLayerId === "" || subLayerId === null) {
      getNotify(ERROR, pleaseSelectSubLayer);
      return;
    }
    if (title === "") {
      getNotify(ERROR, pleaseEnterJobTitle);
      return;
    }
    if (gender === "") {
      getNotify(ERROR, pleaseSelectGender);
      return;
    }
    if (salutation === "") {
      getNotify(ERROR, pleaseSelectSalutation);
      return;
    }
    if (firstName === "") {
      getNotify(ERROR, pleaseEnterFirstName);
      return;
    }
    if (lastName === "") {
      getNotify(ERROR, pleaseEnterLastName);
      return;
    }
    if (dateOfBirth === "" || dateOfBirth === null) {
      getNotify(ERROR, "Please select date of birth");
      return;
    }
    if (mobileNumber === "") {
      getNotify(ERROR, pleaseEnterMobileName);
      return;
    }
    if (emailId === "") {
      getNotify(ERROR, pleaseEnterEmailName);
      return;
    }
    if (address === "") {
      getNotify(ERROR, pleaseEnterAddress);
      return;
    }
    if (pincode === "") {
      getNotify(ERROR, "Please enter pin/zip code");
      return;
    }
    if (city === "") {
      getNotify(ERROR, pleaseEnterCity);
      return;
    }
    if (state === "") {
      getNotify(ERROR, pleaseEnterState);
      return;
    }
    if (highestEducationQualification === "") {
      getNotify(ERROR, pleaseSelectHighestEducationQualification);
      return;
    }
    if (workExperiance > 720 || workExperiance < 0) {
      getNotify(ERROR, "Please enter valid experience");
      return;
    }
    if (recruitmentManagerId === '' || recruitmentManagerId === 0) {
      getNotify(ERROR, pleaseSelectRecruitmentManager);
      return;
    }
    if (legalName === "") {
      getNotify(ERROR, pleaseEnterLegalName);
      return;
    }

    if (addFormObject && addFormObject.validate()) {
      this.setState({ openDialog: true });
    }

  };
  closeDialog = () => {
    this.setState({ openDialog: false });
  };
  backPage = () => {
    try {
      if (ON_BOARDING === (localStorage.getItem('last_meeting_category'))) {
        localStorage.setItem("meeting_category", encodeString(ON_BOARDING));
        const params = queryString.parse(this.props.location.search);
        const path = `/onboard?id=${params.id}&appId=${params.appId}`;
        this.setState({
          redirect: path,
        }, () => this.props.setMeetingCategory(ON_BOARDING));
      }
    } catch (error) {
      // eslint-disable-next-line
      console.log("error", error);
    }
  }
  documentInformation = () => {
    const { authUser, candidateid, emailId, firstName } = this.props;
    try {//NOSONAR
      const reasponse = Promise.resolve(OnbordingToBeOffer.sendCandidateDocValidateInfo(firstName, emailId, candidateid, authUser));
      reasponse.then((resultData) => {
        if (resultData !== null && resultData !== false && resultData === true) {
          getNotify(SUCCESS, documentIntemation);
          this.closeDocIntimationDialog();
        }
      });
    } catch (e) {
      // eslint-disable-next-line
      console.log(e);
    }
  }
  openDocIntimationDialog = () => {
    this.setState({ docIntemationDialog: true });
  }
  closeDocIntimationDialog = () => {
    this.setState({ docIntemationDialog: false });
  }
  getDocData = (docData) => {
    this.setState({
      docData: docData
    })
  }
  downLoadAllFiles = async () => { // NOSONAR
    const { docData } = this.state;
    const { legalName } = this.props;
    var docsTest = docData;
    let urls = [];
    let candSubmitted = false;
    await docsTest.forEach((item1) => {
      item1.Data.forEach((item2) => {
        if (item2.HasFiles && item2.docSubmitted === "Y") {
          if (item2.candSubmitted !== undefined && item2.candSubmitted === "N") {
            candSubmitted = true;
          }
          if (item2.Rejected === "Y") {
            candSubmitted = true;
          }
          let fileurl = urlConfig.tmlcApiUrl + 'document/' + (this.props.candidateType === EMPLOYEE_UPLOAD ? 'ED' : 'D') + '/' + (this.props.candidateType === NEW_CANDIDATE_UPLOAD ? '0' : this.props.authUser) + '/' + item2.FolderPath.replace(/\//g, '~') + item2.Files[0].DocName;
          let data = { DocumenytType: item2.DocumenytType, fileUrl: fileurl }
          urls.push(data);
        }
      })
    })
    if (candSubmitted || urls.length === 0) {
      await getNotify(WARNING, "Candidate haven't submitted documents.");
      return;
    }
    const zip = new JSZip();
    let count = 0;
    const zipFilename = legalName + " Document.zip";
    urls.forEach(async function (url) {
      const urlArr = url.fileUrl.split('/');
      const filename = url.DocumenytType.replace(/\//g, ' ') + "_" + urlArr[urlArr.length - 1];
      try {

        JSZipUtils.getBinaryContent(url.fileUrl, function (errr, data) {
          if (errr) {
            console.log(errr)
          }
          zip.file(filename, data, { binary: true });
          count++;
          try {
            if (count === urls.length) {
              zip.generateAsync({ type: 'blob' }).then(function (content) {
                saveAs(content, zipFilename);
              });
            }
          } catch (er) {
            console.log('err', er)
          }
        })
      } catch (err) {
        console.log('downLoadAllFiles error=>', err);
      }
    });
  }
  onChange = (args) => {
    this.setState({ expanded: args.isExpanded });
  }
  render() {
    const { redirect, docIntemationDialog } = this.state;
    if (redirect) {
      return <Redirect to={redirect} />;
    }
    const {
      openDialog,

    } = this.state;
    const {
      openDocumentPopup,
      open_document_preview,
      openDocumentComments,
      candidateid,
      // baseCounty,
      bandMaster,
      highestEducationQualifcationMaster,
      entityMaster,
      roleMaster,
      employeeTypeMaster,
      countryMaster,
      genderMaster,
      salutationStateMaster,
      servceDivisionSalesUnitMaster,
      stateMasterListMaster,
      stateFunctionListMaster,
      addressCountryId,
      docLinkForExternal
    } = this.props;
    return (
      <div>
        {
          (
            <div
              style={{
                padding: "10px 13px 50px 10px",
                backgroundColor: "#eee",
              }}
            >
              {!openDocumentPopup &&
                // eslint-disable-next-line
                !open_document_preview &&
                !openDocumentComments && <NotificationContainer />}

              <div className="pre-offer-page-height">
                {
                  <form id="addCandidateForm" method="post">
                    <AccordionComponent
                      // eslint-disable-next-line
                      ref={(accordion) => (this.load = accordion)}
                      created={this.onCreated}
                      expanded={(args) => this.onChange(args)}
                    >
                      <PreOfferBasicInformation
                        bandstate={bandMaster}
                        highestEducationQualifcation={highestEducationQualifcationMaster}
                        entitystate={entityMaster}
                        employeeType={employeeTypeMaster}
                        bacecountry={countryMaster}
                        genderstate={genderMaster}
                        salutationState={salutationStateMaster}
                        servceDivisionSalesUnit={servceDivisionSalesUnitMaster}
                        stateMasterList={stateMasterListMaster}
                        stateFunctionList={stateFunctionListMaster}
                        roleMasterState={roleMaster}
                      />

                    </AccordionComponent>
                  </form>
                }


                {/* {baseCounty !== '' && <Checklist */}
                {addressCountryId !== '' && addressCountryId !== null &&
                  <Checklist
                    candidateType={
                      candidateid > 0 && docLinkForExternal === "Y" ? EXISTING_CANDIDATE : NEW_CANDIDATE
                    }
                    getDocData={this.getDocData}
                    expanded={this.state.expanded}
                  />
                }
              </div>



              <>
                {(candidateid <= 0 || docLinkForExternal === "N") ? (
                  <ButtonComponent
                    style={{
                      margin: "0px 20px 20px",
                      position: "absolute",
                      bottom: 0,
                      right: -10,
                      background: "#f94f5e",
                      color: "#fff",
                      borderRadius: "4px",
                    }}
                    onClick={() => this.openDialog()}
                  >
                    Save - Send Link
                  </ButtonComponent>
                ) : (
                  <div>
                    {
                      dencrypt(localStorage.getItem("downloadDocAccess")) === 1 &&
                      <ButtonComponent
                        style={{
                          borderRadius: "4px", margin: "0px 345px 20px",
                          position: "absolute",
                          bottom: 0,
                          right: 0,
                        }}
                        onClick={this.downLoadAllFiles}
                      >
                        Download All Docs
                      </ButtonComponent>
                    }
                    <ButtonComponent
                      style={{
                        borderRadius: "4px", margin: "0px 203px 20px",
                        position: "absolute",
                        bottom: 0,
                        right: -10,
                      }}
                      onClick={this.openDocIntimationDialog}
                    >
                      Doc Intimation
                    </ButtonComponent>
                    <ButtonComponent
                      style={{
                        borderRadius: "4px", margin: "0px 110px 20px",
                        position: "absolute",
                        bottom: 0,
                        right: -10,
                      }}
                      onClick={() => this.backPage()}
                    >
                      cancel
                    </ButtonComponent>
                    <ButtonComponent
                      style={{
                        margin: "0px 20px 20px",
                        position: "absolute",
                        bottom: 0,
                        right: -10,
                        background: "#f94f5e",
                        color: "#fff",
                        borderRadius: "4px",
                      }}
                      onClick={this.openDialog}
                    >
                      Update
                    </ButtonComponent>
                  </div>
                )}

              </>

              <div style={{ width: "350px" }}>
                <Dialog open={openDialog}>
                  {candidateid > 0 ? (
                    <>
                      <DialogTitle> Update Candidate Information </DialogTitle>
                      <DialogContent>
                        Are you sure you want to update the Candidate
                        information?
                      </DialogContent>
                    </>
                  ) : (
                    <>
                      <DialogTitle> Add Candidate Information </DialogTitle>
                      <DialogContent>
                        Are you sure you want to save Candidate's information?
                      </DialogContent>
                    </>
                  )}
                  <DialogActions>
                    <Button id="confirm" onClick={() => this.saveSendLink()}>
                      {" "}
                      Yes{" "}
                    </Button>
                    <Button onClick={this.closeDialog}> No </Button>
                  </DialogActions>
                </Dialog>
              </div>
              <div style={{ width: "350px" }}>
                <Dialog open={docIntemationDialog}>
                  <DialogTitle> Document Intimation </DialogTitle>
                  <DialogContent>
                    Are you sure, you want to send document intimation to the candidate ?
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => this.documentInformation()}>
                      {" "}
                      Yes{" "}
                    </Button>
                    <Button onClick={this.closeDocIntimationDialog}> No </Button>
                  </DialogActions>
                </Dialog>
              </div>
            </div>
          )
        }
      </div>
    );
  }
}
PreOffer.propTypes = {
  // eslint-disable-next-line
  salutation: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  firstName: PropTypes.string,
  middleName: PropTypes.string,
  lastName: PropTypes.string,
  gender: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mobileNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  alternetNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  emailId: PropTypes.string,
  panNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  adharNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  uanNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  highestEducationQualification: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  highestEducationQualificationSpecial: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  workExperiance: PropTypes.number,
  title: PropTypes.string,
  role: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  band: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  empType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  baseCounty: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  addressCountryId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  baseLocation: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  entity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  reportingSupervisor: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  recruitmentManagerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  costSharing: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  address: PropTypes.string,
  dateOfBirth: PropTypes.number,
  readyToJoinDate: PropTypes.number,
  // openDocumentPopup: PropTypes.string,
  candidateid: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  // open_document_preview: PropTypes.string,
  imageName: PropTypes.string,
  servceDivisionSalesUnit: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  // send_links: PropTypes.string,
  // openDocumentComments: PropTypes.string,
  // eslint-disable-next-line
  status: PropTypes.string,
  address2: PropTypes.string,
  city: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  pincode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  state: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  authUser: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  other: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  countryType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  functionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  legalName: PropTypes.string,
  tenureEndDate: PropTypes.number,
  currencyId: PropTypes.number,
  ctcFrequency: PropTypes.number,
};
PreOffer.defaultProps = {
  salutation: "",
  firstName: "",
  middleName: "",
  lastName: "",
  gender: "",
  mobileNumber: "",
  alternetNumber: "",
  emailId: "",
  panNumber: "",
  adharNumber: "",
  uanNumber: "",
  highestEducationQualification: "",
  highestEducationQualificationSpecial: "",
  workExperiance: 0,
  title: "",
  role: "",
  band: "",
  empType: "",
  baseCounty: "",
  addressCountryId: "",
  baseLocation: "",
  entity: "",
  reportingSupervisor: "",
  recruitmentManagerId: 0,
  costSharing: 0,
  address: "",
  // dateOfBirth: Date.parse(addDays(new Date(), - 6570)) / 1000,
  // readyToJoinDate: Date.parse(new Date()) / 1000,
  dateOfBirth: null,
  readyToJoinDate: null,
  // openDocumentPopup: false,
  candidateid: 0,
  // open_document_preview: false,
  imageName: "",
  servceDivisionSalesUnit: "",
  // send_links: [],
  // openDocumentComments: false,
  status: "",
  address2: "",
  city: "",
  pincode: "",
  state: "",
  authUser: "",
  other: "",
  countryType: "",
  functionId: "",
  legalName: "",
  tenureEndDate: null,
  currencyId: null,
  ctcFrequency: "",
};
const mapStateToProps = ({ auth, onboardingreducer, candidateReducer, router }) => {
  const { authUser } = auth;
  const { location } = router;
  const {
    salutation,
    firstName,
    middleName,
    lastName,
    gender,
    mobileNumber,
    alternetNumber,
    emailId,
    panNumber,
    adharNumber,
    uanNumber,
    highestEducationQualification,
    highestEducationQualificationSpecial,
    workExperiance,
    title,
    role,
    band,
    empType,
    baseCounty,
    addressCountryId,
    baseLocation,
    entity,
    reportingSupervisor,
    recruitmentManagerId,
    costSharing,
    address,
    dateOfBirth,
    readyToJoinDate,
    openDocumentPopup,
    candidateid,
    // eslint-disable-next-line
    open_document_preview,
    imageName,
    servceDivisionSalesUnit,
    // eslint-disable-next-line
    send_links,
    openDocumentComments,
    status,
    address2,
    city,
    pincode,
    state,
    other,
    persCountryCode,
    altCountryCode,
    countryType,
    functionId,
    legalName,
    sourceCategory,
    jobPortalId,
    referralId,
    agencyName,
    tctc,
    oti,
    fixedCTC,
    layerId,
    subLayerId,
    tenureEndDate,
    currencyId,
    ctcFrequency,
    subFunction,
    docsSubmitted,
    docLinkForExternal,
    recruiterName,
    referralName,
    reportingSup
  } = onboardingreducer;
  const { bandMaster,
    highestEducationQualifcationMaster,
    entityMaster,
    roleMaster,
    employeeTypeMaster,
    countryMaster,
    genderMaster,
    salutationStateMaster,
    servceDivisionSalesUnitMaster,
    stateMasterListMaster,
    stateFunctionListMaster } = candidateReducer;
  return {
    authUser,
    salutation,
    firstName,
    middleName,
    lastName,
    gender,
    mobileNumber,
    alternetNumber,
    emailId,
    panNumber,
    adharNumber,
    uanNumber,
    highestEducationQualification,
    highestEducationQualificationSpecial,
    workExperiance,
    title,
    role,
    band,
    empType,
    baseCounty,
    baseLocation,
    entity,
    reportingSupervisor,
    recruitmentManagerId,
    costSharing,
    address,
    dateOfBirth,
    readyToJoinDate,
    openDocumentPopup,
    candidateid,
    open_document_preview,
    imageName,
    servceDivisionSalesUnit,
    send_links,
    openDocumentComments,
    status,
    address2,
    city,
    pincode,
    state,
    other,
    persCountryCode,
    altCountryCode,
    countryType,
    functionId,
    legalName,
    bandMaster,
    highestEducationQualifcationMaster,
    entityMaster,
    roleMaster,
    employeeTypeMaster,
    countryMaster,
    genderMaster,
    salutationStateMaster,
    servceDivisionSalesUnitMaster,
    stateMasterListMaster,
    stateFunctionListMaster,
    sourceCategory,
    jobPortalId,
    referralId,
    agencyName,
    tctc,
    oti,
    fixedCTC,
    layerId,
    subLayerId,
    addressCountryId,
    tenureEndDate,
    currencyId,
    ctcFrequency, location,
    subFunction,
    docsSubmitted,
    docLinkForExternal,
    recruiterName,
    referralName,
    reportingSup
  };
};

export default connect(mapStateToProps, {
  saveBasicInformation,
  saveBasicInformationInRedux,
  setMeetingCategory
})(PreOffer);
