import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "./homeofficebasicinformation.css";
import { addEditHomeOfficeDetails } from "../../redux/actions/homeoffice/homeofficeaction";

import  Holding from "../../assets/images/Holding.png";
import  Deskimage from "../../assets/images/Deskimage.png";
import  Chair from "../../assets/images/Chair.png";
import  Table31 from "../../assets/images/other_asset_desk.png";


// eslint-disable-next-line
class OtherAssets extends Component {
  componentDidMount() {
    const { homeOfficeList } = this.props
    if (homeOfficeList !== undefined && homeOfficeList.length > 0) {
      const { assetReqWorkDesk, assetReqScreenArm, assetReqChair } = homeOfficeList[0];
      let assWrkDesk;
      let assetReqScrArm;
      if(assetReqWorkDesk === '1'){
        assWrkDesk = true;
      }else if(assetReqWorkDesk === '2'){
        assWrkDesk = false;
      }else{
        assWrkDesk = null;
      }
      if(assetReqScreenArm === '1'){
        assetReqScrArm = true;
      }else if(assetReqScreenArm === '2'){
        assetReqScrArm = false;
      }else{
        assetReqScrArm = null;
      }
      this.props.addEditHomeOfficeDetails({
        requestForWorkDesk: assWrkDesk, // assetReqWorkDesk === '1' ? true : assetReqWorkDesk === '2' ? false : null,
        requestForHoldingArm: assetReqScrArm, // assetReqScreenArm === '1' ? true : assetReqScreenArm === '2' ? false : null,
        requestForOfficeChair: assetReqChair === '1' ? true : assetReqChair === '0' ? false : null,
      });
    }
  }
  render() {
    const {
      requestForWorkDesk,
      requestForHoldingArm,
      requestForOfficeChair,
    } = this.props;
    return (
      <div>
        <div className="accordian-header-padding-left">OTHER ASSETS *</div>
        <div className="control-section col-md-12 ">
          <div className="row">
            <div className="col-xs-12 col-sm-2 col-lg-12">
              <div className="col-xs-6 col-sm-2 col-lg-6">
                <div className="row" style={{ padding: 0 }}>
                  <div
                    className="col-xs-2 col-sm-2 col-lg-2"
                    style={{
                      padding: 0,
                      // border: '1px solid #DBDBDB'
                    }}
                  >
                    <img
                      // eslint-disable-next-line
                      src={Holding}
                      style={{ objectFit: "contain" }}
                      alt=""
                      className="col-xs-12 col-sm-2 col-lg-12"
                    />
                  </div>
                  <div className="col-xs-10 col-sm-2 col-lg-10">
                    <div className="other-asset-text">
                      {" "}
                      1. My own desk can accommodate the vertical screen holding
                      arm that would be provided by the organisation
                    </div>
                    <div className="row1" style={{ paddingTop: 10 }}>
                      {/* eslint-disable-next-line */}
                      <div
                        className={`col-xs-6 col-sm-2 col-lg-4 col-md-2 ${requestForHoldingArm === true
                            ? "button-yes"
                            : "button-unselect"
                          }`}
                        onClick={() => {
                          document.getElementById("otherAssets").innerHTML = "";
                            this.props.addEditHomeOfficeDetails({
                              requestForHoldingArm: true,
                              requestForWorkDesk: false
                            });
                          // }
                        }}
                      >
                        YES
                      </div>
                      {/* eslint-disable-next-line */}
                      <div
                        className={`col-xs-6 col-sm-2 col-lg-4 col-md-2 ${requestForHoldingArm === false
                            ? "button-no"
                            : "button-unselect"
                          }`}
                        onClick={() => {
                          document.getElementById("otherAssets").innerHTML = "";
                          // eslint-disable-next-line
                          this.props.addEditHomeOfficeDetails({
                            requestForHoldingArm: false,
                            // requestForWorkDesk: null
                          });
                        }}
                      >
                        NO
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xs-6 col-sm-2 col-lg-6 col-md-2">
                <div className="row" style={{ padding: 0 }}>
                  <div
                    className="col-xs-4 col-sm-2 col-lg-4"
                    style={{
                      padding: 0,
                      // border: '1px solid #DBDBDB'
                    }}
                  >
                    <img
                      // eslint-disable-next-line
                      src={Deskimage}
                      style={{ objectFit: "contain" }}
                      className="col-xs-12 col-sm-2 col-lg-12"
                      alt=""
                    />
                  </div>
                  <div className="col-xs-8 col-sm-2 col-lg-8">
                    <div className="other-asset-text">
                      2. I want an office provided work-desk with the vertical
                      screen-holding arm to be issued to me
                    </div>
                    <div className="row1" style={{ paddingTop: 10 }}>
                      {/* eslint-disable-next-line */}
                      <div
                        className={`col-xs-6 col-sm-2 col-lg-4 col-md-2 ${requestForWorkDesk === true
                            ? "button-yes"
                            : "button-unselect"
                          }`}
                        onClick={() => {
                          document.getElementById("otherAssets").innerHTML = "";
                            this.props.addEditHomeOfficeDetails({
                              requestForWorkDesk: true,
                              requestForHoldingArm: false
                            });
                          // }
                        }}
                      >
                        YES
                      </div>
                      {/* eslint-disable-next-line */}
                      <div
                        className={`col-xs-6 col-sm-2 col-lg-4 col-md-2 ${requestForWorkDesk === false
                            ? "button-no"
                            : "button-unselect"
                          }`}
                        onClick={() => {
                          document.getElementById("otherAssets").innerHTML = "";
                          // eslint-disable-next-line
                          this.props.addEditHomeOfficeDetails({
                            requestForWorkDesk: false,
                            // requestForHoldingArm: null
                          });
                        }}
                      >
                        NO
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* second row */}
            <div className="col-xs-12 col-sm-2 col-lg-12">
              <div className="col-xs-6 col-sm-2 col-lg-6 col-md-2">
                <div className="row" style={{ paddingTop: 15 }}>
                  <div
                    className="col-xs-2 col-sm-2 col-lg-2"
                    style={{
                      padding: 0,
                      // border: '1px solid #DBDBDB'
                    }}
                  >
                    <img
                      // eslint-disable-next-line
                      src={Chair}
                      style={{ objectFit: "contain" }}
                      className="col-xs-12 col-sm-2 col-lg-12"
                      alt=""
                    />
                  </div>
                  <div className="col-xs-10 col-sm-2 col-lg-10">
                    <div className="other-asset-text">
                      3. I want an office provided ergonomic chair to be issued
                      to me
                    </div>
                    <div className="row1" style={{ paddingTop: 10 }}>
                      {/* eslint-disable-next-line */}
                      <div
                        className={`col-xs-6 col-sm-2 col-lg-4 col-md-2 ${requestForOfficeChair === true
                            ? "button-yes"
                            : "button-unselect"
                          }`}
                        onClick={() => {
                          document.getElementById("otherAssets").innerHTML = "";
                          // eslint-disable-next-line
                          this.props.addEditHomeOfficeDetails({
                            requestForOfficeChair: true,
                          });
                        }}
                      >
                        YES
                      </div>
                      {/* eslint-disable-next-line */}
                      <div
                        className={`col-xs-6 col-sm-2 col-lg-4 col-md-2 ${requestForOfficeChair === false
                            ? "button-no"
                            : "button-unselect"
                          }`}
                        onClick={() => {
                          document.getElementById("otherAssets").innerHTML = "";
                          // eslint-disable-next-line
                          this.props.addEditHomeOfficeDetails({
                            requestForOfficeChair: false,
                          });
                        }}
                      >
                        NO
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xs-6 col-sm-2 col-lg-6 col-md-2">
                <div className="row" style={{ paddingTop: 15 }}>
                  <div
                    className="col-xs-4 col-sm-2 col-lg-4"
                    style={{
                      padding: 0,
                      // border: '1px solid #DBDBDB'
                    }}
                  >
                    <img
                      // eslint-disable-next-line
                      src={Table31}
                      style={{ objectFit: "contain" }}
                      className="col-xs-12 col-sm-2 col-lg-12"
                      title=""
                      alt=""
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* Third Row*/}
            {/* <div className='row1'>
          <div className="col-xs-12 col-sm-2 col-lg-12" style={{ paddingTop: 15 , paddingBottom:0 }}> */}
            <div className="col-xs-12 col-sm-2 col-lg-12">
              <div className="col-xs-12 col-sm-2 col-lg-12 col-md-2">
                <div className="row" style={{ paddingTop: 15, paddingLeft: 15 }}>
                  <div className="other-asset-text" style={{ fontWeight: 'bold', color: '#000' }}>
                    Please Note: The Home-Office must have five feet of free-space width-wise (left to right) available behind the office-chair for Company branded backdrop standee.
          </div>
                </div>
              </div>
            </div>
          </div>
          <div
            style={{ marginLeft: 15 }}
            id="otherAssets"
            className="e-input-group e-float-icon-left error-div"
          />
        </div>
      </div>
    );
  }
}
OtherAssets.propTypes = {
  requestForWorkDesk: PropTypes.bool,
  requestForHoldingArm: PropTypes.bool,
  requestForOfficeChair: PropTypes.bool,
};
OtherAssets.defaultProps = {
  requestForWorkDesk: null,
  requestForHoldingArm: null,
  requestForOfficeChair: null,
};
const mapStateToProps = ({ homeoffice }) => {
  const {
    requestForWorkDesk,
    requestForHoldingArm,
    requestForOfficeChair,
  } = homeoffice;
  return { requestForWorkDesk, requestForHoldingArm, requestForOfficeChair };
};
export default connect(mapStateToProps, {
  addEditHomeOfficeDetails,
})(OtherAssets);
