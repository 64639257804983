import React, { Component } from 'react';
import { connect } from 'react-redux';
import SetupContainer from "../../containers/okr/okrsetupcontainer"
import MasterSetup from "../../containers/okr/okrclustersetuppopupcontainer"
import { dencrypt } from '../../other/commonfunction';
import { updateOKRData } from '../../../redux/actions/okr/okractions';
import queryString from "query-string";
import { getOKRRatingasterData } from '../../other/commonlayoutfunction'
import okrServices from '../../../services/okr/okrservices';

class SetupLayout extends Component {
  constructor(props) {
    super(props)
    this.state = {
      masterId: 0,
      openPopup: "close",
	  selectYear: null,
      teammember: [],
      teamMemberMatrix: {
        userId: null,
        fyId: null,
        month: null
      },
      editMasterId: null,
      fyId: null,
    }
  }

  async componentDidMount() {
    this.getData()

  }

  getData = async () => {
    try {
      this.setMasterId()
      const { authUser } = this.props;
      const ratingSystem = await getOKRRatingasterData(authUser);

      const getOKRAdminMasterDataResult = await okrServices.getOKRAdminMasterData('3', "GETSUBPOTENTIALVALUE")

      const okrAdminMasterData = getOKRAdminMasterDataResult.data.reduce((acc, item) => {
        const { Type, Name, Id, Optional1 } = item;
        if (Type === 'OKRType') {
          return { ...acc, [Id]: [] }
        }

        if (acc.hasOwnProperty(Optional1) && Type === 'KPIs') {
          return { ...acc, [Optional1]: [...acc[Optional1], { Id, Name }] }
        }
      }, {})

      if (ratingSystem && okrAdminMasterData) {
        this.props.updateOKRData({ ...ratingSystem, okrAdminMasterData })
      }

      this.setState({ isLoaded: true })

    } catch (error) {
      console.log(error)
    }
  }



  setMasterId = () => {
    try {
      const { masterId } = this.state
      const params = queryString.parse(this.props.location.search);
      if (typeof params.masterId !== 'undefined' && params.masterId !== null) {
        if (masterId !== dencrypt(params.masterId))
          this.setState({ masterId: dencrypt(params.masterId) })
          // props.updateOKRData({ okrMasterFlag: 'updated' })

      }
    } catch (error) {
    }
  }

  async componentDidUpdate(prevProps, prevState) {
    try {
      this.setMasterId();
    } catch (error) {

    }
  }
 
  changePopUpHandler = (type = "close", editMasterId = null, teammember, masterId = null) => {//NOSONAR
    if (type === "open") {
      this.setState({ openPopup: "add",teammember: teammember, editMasterId});
    } else if (type === "close") {
      this.setState({ openPopup: "close"});
    } else if (type === "edit") {  
      if (this.state.masterId === 15) {
        this.setState({ openPopup: "edit", editMasterId, fyId: teammember });
      } else {
        this.setState({ openPopup: "edit", editMasterId, teammember: teammember });
      }
    }
  }

  componentWillUnmount() {
    this.setState({ openPopup: "close" })
  }
  render() {
    const { openPopup, masterId, editMasterId } = this.state
    const { history } = this.props
    return (
      <>
        <div>
          <SetupContainer
            popUpHandler={this.popUpHandler}
            changePopUpHandler={this.changePopUpHandler}
            openPopup={openPopup}
            masterItemId={editMasterId}
            masterId={masterId}
            history={history}
			      teamMemberMatrix={this.state.teamMemberMatrix}
          />
        </div>

        {(openPopup === "add" || openPopup === "edit") &&
          <div>
            <MasterSetup
              popUpHandler={this.popUpHandler}
              changePopUpHandler={this.changePopUpHandler}
              openPopup={openPopup}
              masterItemId={editMasterId}
              masterId={masterId}
              history={history}
			        teamMemberMatrix={this.state.teamMemberMatrix}
              teammemberData={this.state.teammember}
            />
          </div>}

      </>
    );
  }
}
const mapStateToProps = ({ meetingcategory, auth, router,okrreducer }) => {
  const { authUser } = auth;
  const { location } = router;
  const {okrMasterFlag} =okrreducer
  return {
    authUser,
    location,
    okrMasterFlag
  }
}
export default connect(mapStateToProps,{updateOKRData})(SetupLayout);
