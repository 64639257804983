import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import React from 'react';
import { UNUTILIZED_LEAVES, CARRY_FORWARD, TO_BE_CARRY_FORWARD, LAPSE, YEAR } from '../../../../../src/assets/constants/leaveConstants';

const UnutilizedLeavesDetail = ({ carryForwardData }) => {
  const carryForwardInfo = carryForwardData.filter((e) => e.type === 'CarryForward')
  const currentLeaveInfo = carryForwardData.filter((e) => e.type === 'Leaves')

  const leavesCss = `
  .leavesBox{
    display: flex;
    flex: 1;
    border: 1px solid #F94F5E;
    border-radius: 3px;
    background-color: #FEDCDF;
    padding: 4px 10px;
    margin-right: 6px;
  }

  .innerBox{
    display: flex;
    flex: 1;
    gap: 10px;
    justify-content: space-between;
  }

  .untilizedLeavesBox{
    display: flex;
    flex: 1.8;
    gap: 10px;
    border-right: 1px solid #F94F5E;
    align-items: center;
  }

  .untilizedLeaves{
    font-size: 14px;
    font-weight: normal;
    font-family: 'Circular TT Book';
    color: #000000;
  }

  .unutilizedCount{
    font-size: 14px;
    font-weight: 700;
    color: #F94F5E;
    font-family: 'Circular TT Book';
  }

  .carryForwardBox{
    display: flex;
    flex: 0.85;
    padding-right:10px;
    gap: 10px;
    border-right: 1px solid #F94F5E;
    align-items: center;
  }

  .carryForward{
    font-size: 14px;
    font-weight: normal;
    font-family: 'Circular TT Book';
    color: #000000;
  }

  .carryForwardCount{
    font-size: 14px;
    font-weight: 700;
    color: #F94F5E;
    font-family: 'Circular TT Book';
  }

  .lapseBox{
    display: flex;
    flex: 1;
    gap: 10px;
    align-items: center;
  }

  .lapse{
    font-size: 14px;
    font-weight: normal;
    font-family: 'Circular TT Book';
    color: #000000;
  }

  .lapseCount{
    font-size: 14px;
    font-weight: 700;
    color: #F94F5E;
    font-family: 'Circular TT Book';
  }

  @media screen and (max-width: 1366px) and (max-height: 768px), screen and (max-width: 1440px) and (max-height: 900px) and (-webkit-min-device-pixel-ratio: 1.5){
    .leavesBox{
      padding: 1px 0px;
      margin-right: 2px;
    }

    .lapseBox{
      gap: 2;
      align-items: left !important;
    }

    .innerBox{
      gap: 2
    }

    .untilizedLeavesBox{
      gap: 2;
      padding-right: 2px;
    }

    .untilizedLeaves{
      font-size: 10px;
      padding-left:3px;
    }

    .unutilizedCount{
      font-size: 10px;
    }

    .carryForward{
      font-size: 10px;
    }

    .carryForwardCount{
      font-size: 10px;
    }

    .lapseBox{
      gap: 2;
    }

    .lapse{
      font-size: 10px;
    }

    .lapseCount{
      font-size: 10px;
    }
  }
  @media screen and (max-width: 1920px) and (max-height: 1080px) and (min-resolution: 96dpi){
   
    .untilizedLeavesBox{
      display: flex;
      flex: 1.3;
      gap: 10px;
      border-right: 1px solid #F94F5E;
      align-items: center;
    }
    .lapseBox{
      display: flex;
      flex: 0.5;
      gap: 10px;
      align-items: center;
    }
  }
  @media screen and (max-width: 1920px) and (max-height: 1080px) and (min-resolution: 120dpi){
   
    .untilizedLeavesBox{
      display: flex;
      flex: 1.6;
      gap: 10px;
      border-right: 1px solid #F94F5E;
      align-items: center;
      padding-right:10px;
    }
    .carryForwardBox{
      display: flex;
      flex: 1;
      padding-right:10px;
      gap: 10px;
      border-right: 1px solid #F94F5E;
      align-items: center;
    }
    .lapseBox{
      display: flex;
      flex: 0.7;
      gap: 10px;
      align-items: center;
    }
  }
    @media screen and (max-width: 1440px) and (max-height: 900px) and (min-resolution: 96dpi){
      .untilizedLeavesBox{
        display: flex;
        flex: 1.8;
        gap: 10px;
        border-right: 1px solid #F94F5E;
        align-items: center;
        padding-right:10px
      }
      .carryForwardBox{
        display: flex;
        flex: 1.2;
        padding-right:10px;
        gap: 10px;
        border-right: 1px solid #F94F5E;
        align-items: center;
      }
      .lapseBox{
        display: flex;
        flex: 1;
        gap: 10px;
        align-items: center;
      }
    }
    @media screen and (max-width: 1440px) and (max-height: 900px) and (min-resolution: 120dpi){
      .untilizedLeavesBox{
        display: flex;
        flex: 1.6;
        gap: 10px;
        border-right: 1px solid #F94F5E;
        align-items: center;
        padding-right:10px
      }
      .carryForwardBox{
        display: flex;
        flex: 1.2;
        padding-right:10px;
        gap: 10px;
        border-right: 1px solid #F94F5E;
        align-items: center;
      }
      .lapseBox{
        display: flex;
        flex: 0.8;
        gap: 10px;
        align-items: center;
        padding-right:10px
      }
    }
    @media screen and (max-width: 1920px) and (max-height: 1080px) and (min-resolution: 144dpi){
      .untilizedLeavesBox{
        display: flex;
        flex: 1.6;
        gap: 10px;
        border-right: 1px solid #F94F5E;
        align-items: center;
      }
      .carryForwardBox{
        display: flex;
        flex: 1;
        padding-right:10px;
        gap: 10px;
        border-right: 1px solid #F94F5E;
        align-items: center;
      }
      .lapseBox{
        display: flex;
        flex: 0.7;
        gap: 10px;
        align-items: center;
        padding-right:10px;
      }
    }
`

  const toolTipContent = () => {

    return (
      <span id='tooltipContent' >
        {currentLeaveInfo.length > 0 && <div style={{ marginBottom: '5px', display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}>
          <span style={{ fontWeight: 600, fontSize: '12px', textAlign: 'start', }}>Year: {currentLeaveInfo[0].leaveYear}</span>
          <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 8 }}>
            {currentLeaveInfo.map((each, ind) => (
              <span style={{ fontWeight: 500, fontSize: '12px', borderRight: (ind !== currentLeaveInfo.length - 1 ? '1px solid #c5bdbd' : ""), textAlign: "center", paddingRight: 10, paddingLeft: 10 }}>{each.leaveSymbol}: {each.unUtilised}</span>
            ))}
          </div>
        </div>}
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', }}>
          {carryForwardInfo.length > 0 && carryForwardInfo.map((eachCarry, ind) =>
            <>
              <span style={{
                borderTop: (currentLeaveInfo.length > 0 && ind !== carryForwardInfo.length ? '1px solid #c5bdbd' : ""),
                paddingTop: 5, fontWeight: 600, fontSize: '12px', textAlign: 'start', marginBottom: '3px'
              }}>{YEAR}: {eachCarry.leaveYear}</span >
              <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 5 }}>
                <span style={{ fontWeight: 500, fontSize: '12px', borderRight: '1px solid #c5bdbd', paddingRight: '15px' }}>{CARRY_FORWARD} {eachCarry.leaveSymbol}: {eachCarry.leavesCount}</span>
                <span style={{ fontWeight: 500, fontSize: '12px', paddingLeft: '15px' }}>Lapse: {eachCarry.lapsed}</span>
              </div>
            </>
          )}
        </div>
      </span >
    )
  }

  return (

    <>
      <style>{leavesCss}</style>
      <div className='leavesBox'>
        <div className='innerBox'>
          <div className='untilizedLeavesBox'>
            <span className='untilizedLeaves' >
              {UNUTILIZED_LEAVES} :
            </span>
            <span className='unutilizedCount' >
              {currentLeaveInfo?.[0]?.totalUnUtilised ? currentLeaveInfo[0].totalUnUtilised : 0}
            </span>
            <TooltipComponent
              position="BottomLeft"
              className="pointer-cursor"
              isSticky={false}
              content={toolTipContent}
              cssClass='customtooltip'
              tooltipShowDelay={1000000}
            >
              <span className="tool-tip-hover-efect " style={{ color: '#4B4B55' }}>
                <i className="fa fa-info-circle info-circle" aria-hidden="true"></i>
              </span>
            </TooltipComponent>
          </div>
          <div className='carryForwardBox' >

            <span className='carryForward' >
              {TO_BE_CARRY_FORWARD} :
            </span>
            <span className='carryForwardCount' >
              {currentLeaveInfo?.[0]?.totalCarryForward ? currentLeaveInfo[0].totalCarryForward : 0}
            </span>
          </div>
          <div className='lapseBox'>

            <span className='lapse' >
              {LAPSE} :
            </span>

            <span className='lapseCount' >
              {currentLeaveInfo?.[0]?.totalLapsed ? currentLeaveInfo[0].totalLapsed : 0}
            </span>
          </div>
        </div>

      </div>
    </>
  )
};

export default React.memo(UnutilizedLeavesDetail);