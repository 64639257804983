import React from 'react';
import {
    DialogContent
} from "../../customdialog/customdialog";

function EmployeeCheckStatus(props) {
  return (
    <DialogContent>
        <div className='row1 '>
            <p className="pb-md pt-sm" style={{ fontWeight: 600, fontSize: "1em" }}>Below are the <strong>Practice Area / Sales Unit / Sub Function</strong> and <strong>Team Members</strong> list where he/she acting as a head or team members reporting to him.</p>
            <p style={{ fontWeight: 600, fontSize: "1em", color: "#f94f5e", marginTop: "-.5em" }}>Please update these details first</p>
            <div className='col-lg-12 col-md-12 col-sm-12' style={{ height: '42px', backgroundColor: '#F6F6F6', }}>
            <div className='col-lg-6 col-md-6 col-sm-6 pt-sm'>Name</div>
            <div className='col-lg-6 col-md-6 col-sm-6 pt-sm'>Type</div>
            </div>
            <div className='height424'>
            {props.checkStatusEmployeeData.length > 0 && props.checkStatusEmployeeData.map((item) => {
                return (
                    <div className='col-lg-12 col-md-12 col-sm-12'
                        style={{
                            height: '35px',
                            borderBottom: '1px solid #F6F6F6',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                        key={"checkStatus" + item.Name}>
                        <div className='col-lg-6 col-md-6 col-sm-6'>{item.Name}</div>
                        <div className='col-lg-6 col-md-6 col-sm-6'>{item.Type}</div>
                    </div>
                )
            })}
            </div>
        </div>
    </DialogContent>
  )
}

export default EmployeeCheckStatus;