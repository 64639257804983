/**
 * Author: Abdulla Shaikh.
 * Created:22-Feb-2023
 * @description to view all HelpDesk Add New request
 */

import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux'

import "./helpdeskservice.css";

import { FormValidator } from "@syncfusion/ej2-react-inputs";

import UploadDocuments from "../okrratingcomponents/uploaddocuments";
import { getSubCategories } from '../../services/helpdesk/helpdeskServices';
import { HtmlEditor, QuickToolbar } from '@syncfusion/ej2-react-richtexteditor';
import Switch from '../../utilities/switchcomponent';
import { pleaseSelectFunctionTemp, pleaseSelectCategory, pleaseEnterSubject, pleaseSelectPriority, pleaseEnterDescription, subjectLength, pleaseSelectImpact, priorityData, regexOnlyLettersAndSpaces, confirmationRequest, confirmationIdea, WARNING, pleaseSelectSubCategory, SUCCESS, minDiscription } from "../../assets/constants/constants";
import TextBox from "../../utilities/textboxcomponent"
import ComboBox from "../../utilities/comboboxcomponent"
import RichTextEditor from "../../utilities/richtexteditorcomponent"
import { addUpdateHelpDeskRedux } from '../../redux/actions/helpdesk/helpdeskaction'
import LabelComponent from '../../utilities/lablecomponent';
import { dencrypt, getActualContent, getNotify, isContentEmpty, refreshRitchTextBox } from '../../app/other/commonfunction';
import services from '../../services/services';
import ConfirmationPopup from '../../utilities/confirmationpopup'
import ProgressBar from '../progressbar/progressbar';


const isFourteenHundred = window.innerHeight < 782 && window.innerWidth < 1441;
const isThirteenHundred = window.innerHeight < 658;

function AddServiceRequest(props) {
	const [state, setState] = useState({
		function: props.functionId,
		category: props.categoryId,
		subCategory: null,
		customSubCategoryId: props.selectedTopTicket.JitbitId ? props.selectedTopTicket.JitbitId : null,
		subject: null,
		priority: '0',
		clientImpact: false,
		description: "",
		uploadDocument: null,
		categoryData: [],
		subCategoryData: [],
		fileList: [],
		fileNameList: [],
		openPopup: false
	})
	const [loading, setLoading] = useState(false)
	const focusFunction = useRef(null);
	const [impactArea, setImpactArea] = useState([])

	const [errorMessageMargin, setErrorMessageMargin] = useState({
		functionMsg: false,
		categoryMsg: false,
		subCategoryMsg: false,
		subjectMsg: false,
		priorityMsg: false,
		impactMsg: false,
	});
	useEffect(() => {
		setTimeout(() => {
			props.setSubmitClick(onSaveData);
			props.setCancelClick(onCancel);
		}, 100);

	}, [state]);
	useEffect(() => {
		if (props.submitNewRequestStatus === 'updated') {
			setState({
				function: null,
				category: null,
				subCategory: null,
				customSubCategoryId: null,
				subject: null,
				priority: '0',
				clientImpact: false,
				description: "",
				uploadDocument: null,
				categoryData: [],
				subCategoriesData: [],
				fileList: [],
				fileNameList: [],
				impact: null
			});
			setIdeaDoc([]);
			props.addUpdateHelpDeskRedux({ submitNewRequestStatus: '', isRequestFormDataAdded: false })
		}
	}, [props.submitNewRequestStatus === 'updated']);

	useEffect(() => {
		handleTopTicketChange();
	}, [props.selectedTopTicket]);

	const handleTopTicketChange = async () => {
		setLoading(true);
		if (Object.keys(props.selectedTopTicket).length !== 0) {
			await getSubCategoriesData(props.selectedTopTicket.CategoryId);
			const categoryData = props.categoriesAndSections?.filter((item) => {
				return item.SectionID === props.selectedTopTicket.FunctionId;
			});
			setTimeout(() => {
				setState((prevState) => ({
					...prevState,
					function: props.selectedTopTicket.FunctionId,
					subject: props.selectedTopTicket.TicketSub,
					description: props.selectedTopTicket.TicketDesc,
					category: props.selectedTopTicket.CategoryId,
					categoryData: categoryData,
					subCategory: props.selectedTopTicket.SubCategoryName,
					customSubCategoryId: props.selectedTopTicket.JitbitId
				}));
			}, 500);
		}
		setLoading(false);
	};

	useEffect(() => {
		if (props) {
			getImpactArea();
		}
	}, []);

	useEffect(() => {
		if (props.sectionData?.length > 0 && props.functionId !== null) {
			setTimeout(() => {
				handleChange({ value: props.functionId }, "function");
			}, 1000);
		}
	}, [props.sectionData]);


	const getImpactArea = async () => {
		const masterAllData = await services.getMasterData(dencrypt(localStorage.getItem('user_id')), "IMPACT");
		setImpactArea(masterAllData)
	}

	const getSubCategoriesData = async (categoryId) => {
		setLoading(true)
		try {
			const subCategories = await getSubCategories(categoryId, 0)
			setState(prevState => ({
				...prevState,
				subCategoryData: subCategories
			}))
		} catch (error) {
			console.log("🚀 ~ file: addservicerequest.jsx:94 ~ getSubCategoriesData ~ error:", error)
		}
		setLoading(false)
	}
	const onSaveData = async () => {
		let options = {
			rules: {
				functionErr: {
					required: [true, pleaseSelectFunctionTemp],
				},
				categoryErr: {
					required: [true, pleaseSelectCategory],
				},
				subCategoryErr: {
					required: [true, pleaseSelectSubCategory],
				},
				subjectErr: {
					required: [true, pleaseEnterSubject],
					maxLength: [100, subjectLength],
					minLength: [1, pleaseEnterSubject],
					regex: [regexOnlyLettersAndSpaces, "Please enter valid subject"],
				},
				priorityErr: {
					required: [true, pleaseSelectPriority],
				},
				// impactErr: {
				//   required: [true, pleaseSelectImpact],
				// },
				// descripionErr: {
				//     required: [true, pleaseEnterDescription],
				//   },
			},
		}
		if (props.type === 'idea') {
			options['rules']['impactErr'] = {
				required: [true, pleaseSelectImpact],
			}
		}
		let descriptionValidation = true;
		let desc = "";
		desc = getActualContent(state.description);
		if (desc < 50 && desc > 0) {
			document.getElementById("descriptionErr").innerHTML = minDiscription;
		} else if (
			state.description === "" ||
			state.description === null ||
			isContentEmpty(state.description) ||
			document.getElementById("description").ej2_instances[0].value ===
			null ||
			document.getElementById("description").ej2_instances[0].value === ""
		) {
			document.getElementById("descriptionErr").innerHTML =
				pleaseEnterDescription;
			descriptionValidation = false;
		}
		setTimeout(() => {
			const addFormObject = new FormValidator(
				"#applyServiceHelpDesk",
				options
			);
			let fileUploadValidation;
			let totalFileSize = state.fileList?.reduce((accumulator, file) => accumulator + file.size, 0) || 0;
			if (totalFileSize >= 20480000 && props.type !== 'idea') {
				fileUploadValidation = false;
				getNotify(WARNING, "Total document(s) files size should not be more than 20MB.");
			} else {
				fileUploadValidation = true;
			}
			if (addFormObject.validate() && descriptionValidation && fileUploadValidation && desc > 50) {
				if (document.getElementById("descriptionErr") !== null) {
					document.getElementById("descriptionErr").innerHTML = "";
				}
				setState(prevState => ({ ...prevState, openPopup: true }))
			}
		}, 100);

	}

	const onSubmitYes = () => {
		if (props.type === "idea") {
			const data = {
				userId: dencrypt(localStorage.getItem('user_id')),
				functionId: state.function,
				functionName: props.sectionData.filter(sec => sec.SectionID === state.function)[0]['Section'],
				categoryID: state.category,
				categoryName: state.categoryData.filter(sec => sec.CategoryID === state.category)[0]['Name'],
				priorityId: state.priority,
				subCategoryId: state.subCategoryData.filter(sec => sec.SubCategoryName === state.subCategory)[0]?.['SubCategoryId'],
				subCategoryName: state.subCategory,
				impactId: state.impact,
				subjectName: state.subject,
				descName: state.description,
				docLink: ideaDoc.join()
			}
			props.getSubmitData(data);
		} else {
			props.getSubmitData(state);
		}
		onSubmitCancel();
	}

	const onSubmitCancel = () => {
		setState(prevState => ({ ...prevState, openPopup: false }))
	}
	const handleChange = (data, type) => {
		let options = {}
		switch (type) {
			case "function":
				options = {
					rules: {
						functionErr: {
							required: [true, pleaseSelectFunctionTemp],
						},
					},
				};
				const categoryData = props.categoriesAndSections?.filter((item) => {
					return item.SectionID === data.value && item.ForTechsOnly === false;
				});
				setState((prevState) => ({
					...prevState,
					categoryData: categoryData,
					category: null,
					subCategory: null,
					customSubCategoryId: null,
					subject: null,
					priority: '0',
					clientImpact: false,
					description: "",
					uploadDocument: null,
					fileList: [],
					fileNameList: [],
				}));
				props.setFunctionName(data.value);
				if (props.categoryId !== null) {
					handleChange({ value: props.categoryId }, "category");
				}
				props.addUpdateHelpDeskRedux({ isRequestFormDataAdded: true })
				break;
			case "category":
				getSubCategoriesData(data.value);
				options = {
					rules: {
						categoryErr: {
							required: [true, pleaseSelectCategory],
						},
					},
				};
				setState((prevState) => ({ ...prevState, subCategory: null }));
				props.addUpdateHelpDeskRedux({ isRequestFormDataAdded: true })
				break;
			case "subCategory":
				options = {
					rules: {
						subCategoryErr: {
							required: [true, pleaseSelectSubCategory],
						},
					},
				};
				setState((prevState) => ({ ...prevState, customSubCategoryId: data.target.itemData.JitbitId }));
				props.addUpdateHelpDeskRedux({ isRequestFormDataAdded: true })
				break;
			case "subject":
				setState((prevState) => ({ ...prevState, subject: data.value.trim() }));
				options = {
					rules: {
						subjectErr: {
							required: [true, pleaseEnterSubject],
							maxLength: [100, subjectLength],
							minLength: [1, pleaseEnterSubject],
							regex: [regexOnlyLettersAndSpaces, "Please enter valid subject"],
						},
					},
				};
				props.addUpdateHelpDeskRedux({ isRequestFormDataAdded: true })
				break;
			case "priority":
				options = {
					rules: {
						priorityErr: {
							required: [true, pleaseSelectPriority],
						},
					},
				};

				if (
					[
						"Please enter valid subject",
						`<label class="e-error" for="subjectErr" id="subjectErr-info" style="display: block;">Please enter valid subject</label>`,
						`<label class="e-error" for="subjectErr" id="subjectErr-info" style="display: block;">${pleaseEnterSubject}</label>`,
						`<label class="e-error" for="subjectErr" id="subjectErr-info" style="display: block;">${subjectLength}</label>`,
					].includes(document.getElementById("subjectErr").innerHTML) &&
					options["rules"]
				) {
					options["rules"]["subjectErr"] = {
						required: [true, pleaseEnterSubject],
						maxLength: [100, subjectLength],
						minLength: [1, pleaseEnterSubject],
						regex: [regexOnlyLettersAndSpaces, "Please enter valid subject"],
					};
				}
				break;
			case "impact":
				options = {
					rules: {
						impactErr: {
							required: [true, pleaseSelectImpact],
						},
					},
				};
				if (
					[
						"Please enter valid subject",
						`<label class="e-error" for="subjectErr" id="subjectErr-info" style="display: block;">Please enter valid subject</label>`,
						`<label class="e-error" for="subjectErr" id="subjectErr-info" style="display: block;">${pleaseEnterSubject}</label>`,
						`<label class="e-error" for="subjectErr" id="subjectErr-info" style="display: block;">${subjectLength}</label>`,
					].includes(document.getElementById("subjectErr").innerHTML) &&
					options["rules"]
				) {
					options["rules"]["subjectErr"] = {
						required: [true, pleaseEnterSubject],
						maxLength: [100, subjectLength],
						minLength: [1, pleaseEnterSubject],
						regex: [regexOnlyLettersAndSpaces, "Please enter valid subject"],
					};
				}
				break;
			case "clientImpact":
				if (
					[
						"Please enter valid subject",
						`<label class="e-error" for="subjectErr" id="subjectErr-info" style="display: block;">Please enter valid subject</label>`,
						`<label class="e-error" for="subjectErr" id="subjectErr-info" style="display: block;">${pleaseEnterSubject}</label>`,
						`<label class="e-error" for="subjectErr" id="subjectErr-info" style="display: block;">${subjectLength}</label>`,
					].includes(document.getElementById("subjectErr").innerHTML)
				) {
					options = {
						rules: {
							subjectErr: {
								required: [true, pleaseEnterSubject],
								maxLength: [100, subjectLength],
								minLength: [1, pleaseEnterSubject],
								regex: [
									regexOnlyLettersAndSpaces,
									"Please enter valid subject",
								],
							},
						},
					};
				}
				setState({ ...state, clientImpact: data.checked });
				break;

			case "description":
				if (data.value && !isContentEmpty(data.value)) {
					refreshRitchTextBox(null, "description");
					setState({ ...state, description: data.value });
				} else {
					refreshRitchTextBox(null, "description");
					data.value = "";
					setState({ ...state, description: data.value });
				}
				if (
					data.value === "" ||
					data.value === null ||
					document.getElementById("description").ej2_instances[0].value ===
					null ||
					document.getElementById("description").ej2_instances[0].value === ""
				) {
					document.getElementById("descriptionErr").innerHTML =
						pleaseEnterDescription;
				}
				else {
					document.getElementById("descriptionErr").innerHTML = "";
				}
				if (
					[
						"Please enter valid subject",
						`<label class="e-error" for="subjectErr" id="subjectErr-info" style="display: block;">Please enter valid subject</label>`,
						`<label class="e-error" for="subjectErr" id="subjectErr-info" style="display: block;">${pleaseEnterSubject}</label>`,
						`<label class="e-error" for="subjectErr" id="subjectErr-info" style="display: block;">${subjectLength}</label>`,
					].includes(document.getElementById("subjectErr").innerHTML)
				) {
					options = {
						rules: {
							subjectErr: {
								required: [true, pleaseEnterSubject],
								maxLength: [100, subjectLength],
								minLength: [1, pleaseEnterSubject],
								regex: [
									regexOnlyLettersAndSpaces,
									"Please enter valid subject",
								],
							},
						},
					};
				}
				props.addUpdateHelpDeskRedux({ isRequestFormDataAdded: true })
				break;
			default:
		}
		if (type !== "clientImpact" && type !== "description" && type !== "subject") {
			setState(prevState => ({ ...prevState, [type]: typeof data.value === 'string' ? data.value.trim() : data.value }))
		}
		setTimeout(() => {
			const addFormObject = new FormValidator("#applyServiceHelpDesk", options);
			if (addFormObject.validated.includes("functionErr")) {
				setErrorMessageMargin(prevState => ({
					...prevState,
					functionMsg: true
				}))
			}

			if (addFormObject.validated.includes("categoryErr")) {
				setErrorMessageMargin(prevState => ({
					...prevState,
					categoryMsg: true
				}))
			}

			if (addFormObject.validated.includes("subCategoryErr")) {
				setErrorMessageMargin(prevState => ({
					...prevState,
					subCategoryMsg: true
				}))
			}
			if (addFormObject.validated.includes("subjectErr")) {
				setErrorMessageMargin(prevState => ({
					...prevState,
					subjectMsg: true
				}))
			}

			if (addFormObject.validated.includes("priorityErr")) {
				setErrorMessageMargin(prevState => ({
					...prevState,
					priorityMsg: true
				}))
			}

			if (addFormObject.validated.includes("impactErr") && props.type === 'idea') {
				setErrorMessageMargin(prevState => ({
					...prevState,
					impactMsg: true
				}))
			}

			addFormObject.validate();
		}, 100);
	}

	const onCancel = () => {
		setErrorMessageMargin(prevState => ({ //NOSONAR
			...prevState,
			functionMsg: false,
			categoryMsg: false,
			subCategoryMsg: false,
			subjectMsg: false,
			priorityMsg: false,
			impactMsg: false,
			priorityErr: false
		}));
		props.setFunctionName("");
		try {
			if (document.getElementById("functionErr") !== null) {
				document.getElementById("functionErr").innerHTML = "";
			}
			if (document.getElementById("categoryErr") !== null) {
				document.getElementById("categoryErr").innerHTML = "";
			}
			if (document.getElementById("subCategoryErr") !== null) {
				document.getElementById("subCategoryErr").innerHTML = "";
			}
			if (document.getElementById("subjectErr") !== null) {
				document.getElementById("subjectErr").innerHTML = "";
			}
			if (document.getElementById("priorityErr") !== null) {
				document.getElementById("priorityErr").innerHTML = "";
			}
			if (document.getElementById("impactErr") !== null) {
				document.getElementById("impactErr").innerHTML = "";
			}
			if (document.getElementById("descriptionErr") !== null) {
				document.getElementById("descriptionErr").innerHTML = "";
			}
		} catch (err) {
			console.log("🚀 ~ file: addservicerequest.jsx:343 ~ onCancel ~ err:", err)
		}

		setState({
			function: null,
			category: null,
			subCategory: null,
			subject: null,
			priority: '0',
			clientImpact: false,
			description: "",
			uploadDocument: null,
			categoryData: [],
			subCategoriesData: [],
			fileNameList: [],
			fileList: [],
		});
		if (props.type === 'idea') {
			setIdeaDoc([]);
		}
		props.addUpdateHelpDeskRedux({ isRequestFormDataAdded: false })
	}

	const [ideaDoc, setIdeaDoc] = useState([])

	const getFileDetails = (type, file) => {
		if (type === 'add' && file) {
			setState(prevState => ({ ...prevState, fileList: [...prevState.fileList, file], fileNameList: [...prevState.fileNameList, file.name] }));
			getNotify(SUCCESS, "Document added.");
		}

		if (type === 'remove') {
			const files = state.fileList.filter((s, idx) => idx !== file);
			const fileNames = state.fileNameList.filter((s, idx) => idx !== file);
			setState(prevState => ({ ...prevState, fileList: [...files], fileNameList: [...fileNames] }));
		}
	}

	const selectedFiles = (fileNms) => {
		setIdeaDoc([...fileNms]);
	};

	document.querySelector('#upload-document-links')?.addEventListener('focus', () => {
		if (document.querySelector('.upload-document-link ')) {
			document.querySelector('.upload-document-link ').classList.add('focused');
		}
	})

	document.querySelector('#upload-document-links')?.addEventListener('blur', () => {
		if (document.querySelector('.upload-document-link ')) {
			document.querySelector('.upload-document-link ').classList.remove('focused');
		}
	})

	return (
		<>
			<form id='applyServiceHelpDesk'>
				<div className='row1'>
					<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
						<div
							className='col-xs-6 col-sm-6 col-lg-6 col-md-6 pt-md'
							style={{ paddingLeft: 0 }}
						>
							{props.sectionData.length > 0 && (
								<ComboBox
									ref={focusFunction}
									id='function'
									fields={{ text: "Section", value: "SectionID" }}
									value={state.function}
									dataSource={props.sectionData}
									placeholder='Function *'
									floatLabelType='Auto'
									cssClass='e-outline circular-regular'
									onChange={(e) => handleChange(e, "function")}
								/>
							)}
						</div>
						<div
							className='col-xs-6 col-sm-6 col-lg-6 col-md-6 pt-md'
							style={{ padding: 0 }}
						>
							<ComboBox
								id='category'
								data-msg-containerid='categoryErr'
								name='categoryErr'
								dataSource={state.categoryData}
								fields={{ text: "Name", value: "CategoryID" }}
								value={state.category}
								placeholder='Category  *'
								floatLabelType='Auto'
								cssClass='e-outline circular-regular'
								onChange={(e) => handleChange(e, "category")}
							// htmlAttributes={{ maxlength: 100 }}
							/>
						</div>
					</div>
				</div>

				<div className='row1'>
					<div
						className={`col-lg-12 col-md-12 col-sm-12 col-xs-12  pt-xs`}
						style={{
							marginTop:
								(isThirteenHundred || isFourteenHundred) &&
									!errorMessageMargin.subCategoryMsg
									? "-.6rem"
									: null,
						}}
					>
						<ComboBox
							id='subCategory'
							dataSource={state.subCategoryData}
							fields={{ text: "SubCategoryName", value: "SubCategoryName" }}
							value={state.subCategory}
							placeholder='Sub-Category  * '
							floatLabelType='Auto'
							cssClass='e-outline circular-regular'
							onChange={(e) => handleChange(e, "subCategory")}
							// htmlAttributes={{ maxlength: 100 }}
							name='subCategoryErr'
							data-msg-containerid='subCategoryErr'
						/>
					</div>
				</div>
				<div className='row1'>
					<div
						className={`col-lg-12 col-md-12 col-sm-12 col-xs-12  pt-xs`}
						style={{
							marginTop:
								(isThirteenHundred || isFourteenHundred) &&
									!errorMessageMargin.subjectMsg
									? "-.6rem"
									: null,
						}}
					>
						<TextBox
							id='subject'
							value={state.subject}
							placeholder='Subject *'
							floatLabelType='Auto'
							cssClass='e-outline circular-regular'
							// htmlAttributes={{ maxlength: 100, minlength: 1 }}
							onChange={(e) => handleChange(e, "subject")}
						/>
					</div>
				</div>
				<div className='row1'>
					<div
						className='col-lg-12 col-md-12 col-sm-12 col-xs-12 '
						style={{
							marginTop:
								(isThirteenHundred || isFourteenHundred) &&
									!errorMessageMargin.priorityMsg
									? "-.6rem"
									: null,
						}}
					>
						<div
							className={`col-xs-6 col-sm-6 col-lg-6 col-md-6 pt-xs`}
							style={{ paddingLeft: 0 }}
						>
							<ComboBox
								id='priority'
								dataSource={priorityData}
								fields={{ text: "Name", value: "Id" }}
								value={state.priority}
								placeholder='Priority *'
								floatLabelType='Auto'
								cssClass='e-outline circular-regular'
								onChange={(e) => handleChange(e, "priority")}
							/>
						</div>

						{props.type == "idea" ? (
							<div
								className={`col-xs-6 col-sm-6 col-lg-6 col-md-6 pt-xs`}
								style={{ padding: 0 }}
							>
								<ComboBox
									id='impact'
									dataSource={impactArea}
									fields={{ text: "Name", value: "Id" }}
									value={state.priority}
									placeholder='Impact *'
									floatLabelType='Auto'
									cssClass='e-outline circular-regular'
									onChange={(e) => handleChange(e, "impact")}
								/>
								<div id='impactErr' className='error-div' />
							</div>
						) : (
							<div
								className={`col-xs-6 col-sm-6 col-lg-6 col-md-6`}
								style={{ paddingLeft: 0, marginTop: "0.2rem" }}
							>
								<Switch
									id='clientImpact'
									title='Client Impact *'
									name='clientImpact'
									cssClass='atc-switch-height'
									fontStyle="Circular Std Book"
									change={(e) => handleChange(e, "clientImpact")}
									checked={state.clientImpact}
								/>
							</div>
						)}
					</div>
				</div>
				<div className='row1'>
					<div
						className={`col-lg-12 col-md-12 col-sm-12 col-xs-12 leaveAppReasonContainer  `}
						style={{ padding: 0 }}
					>
						<div
							className='pl-md pb-xs' // service-description
							style={{ marginLeft: "-0.9rem" }}
						>
							<LabelComponent
								content={"Description *"}
								color={"brand02-2"}
								fontSize={isFourteenHundred ? "sm" : "md"}
								isBold={false}
								fontStyle={"Circular Std Book"}
							/>
						</div>
						<div>
							<RichTextEditor
								cssClass='refreshUIRichextBox circular-regular'
								id='description'
								services={[HtmlEditor, QuickToolbar]}
								value={state.description}
								height={200}
								maxLength={1000}
								minLength={50}
								onChange={(e) => handleChange(e, "description")}
								showCharCount={true}
								// beforePasteCleanup={beforePasteCleanup}
								actionBegin={(e) => {
									if (e.requestType === "Image") {
										e.cancel = true;
									}
								}}
								toolbarSettings={{
									items: [
										"Formats",
										"Alignments",
										"|",
										"OrderedList",
										"UnorderedList",
										"|",
										"Bold",
										"Italic",
										"Underline",
										"|",
										"ClearFormat",
									],
									type: "Expand",
								}}
							/>
						</div>
					</div>
				</div>

				{props.uploadDocsLoading && <ProgressBar />}

				<div className={`col-lg-12 col-md-12 col-sm-12 col-xs-12`}>
					<div
						className={`pt-xs`}
						style={{ paddingLeft: 0, paddingBottom: ".3rem" }}
					>
						<LabelComponent
							content={"Upload Document(s)"}
							color={"brand02-2"}
							fontSize={isFourteenHundred ? "sm" : "md"}
							isBold={false}
							fontStyle={"Circular Std Book"}
						/>
					</div>
					<div
						className='col-lg-12 col-md-12 col-sm-12 col-xs-12 pb-sm'
						style={{ marginLeft: "0px", paddingLeft: 0 }}
						id='uploadDoc'
					>
						{props.type === "idea" ? (
							<UploadDocuments
								type={"idea"}
								allowmultiple={true}
								selectedFiles={selectedFiles}
								fileList={ideaDoc}
								apiUrl={"tmlcApiUrl"}
							/>
						) : (
							<UploadDocuments
								type={"ticket"}
								allowmultiple={false}
								getFileDetails={getFileDetails}
								// authUser={props.authUser}
								fileList={state.fileNameList}
								apiUrl={"ApiUrl"}
								files={state.fileList}
							// documentDetails={documentDetails}
							/>
						)}
					</div>
				</div>
				{state.openPopup && (
					<ConfirmationPopup
						headerMessage={
							props.type === "idea" ? confirmationIdea : confirmationRequest
						}
						subMessage={""}
						button1Text={"Submit"}
						button2Text={"Cancel"}
						button2function={() => onSubmitCancel()}
						button1function={() => onSubmitYes()}
						showButton={{ type: "1" }}
						open={state.openPopup}
						handleConfirmClose={onSubmitCancel}
					/>
				)}
			</form>
			{loading && <ProgressBar />}
		</>
	);
}
const mapStateToProps = ({ helpdeskreducer, router }) => {
	const { submitNewRequestStatus, uploadDocsLoading } = helpdeskreducer;
	const { location } = router;
	return {
		submitNewRequestStatus, uploadDocsLoading, location
	}
}
export default connect(mapStateToProps, { addUpdateHelpDeskRedux })(AddServiceRequest)
