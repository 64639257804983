import React, { useEffect, useState } from 'react'
import { EMAIL_MAPPING_MASTER } from '../../redux/constant/meetingcategoryconstant'
import { getAdminEmailMappingData } from "../../services/adminservices"
import { connect } from 'react-redux'
import DisabledEffect from '../../utilities/disabledeffect'
import DataGrid from '../../utilities/datagrid'
import { GridComponent, ColumnDirective, ColumnsDirective, Filter, Inject, Page, DetailRow, Freeze } from '@syncfusion/ej2-react-grids'
import { ADMIN_MODULE_ACTION, NOTIFY_USER_NAME, getGridColumn } from '../../app/other/gridcolumns'
import { TooltipComponent } from '@syncfusion/ej2-react-popups'
import { DropDownButtonComponent } from "@syncfusion/ej2-react-splitbuttons";
import { addEditSubCategory, helpdeskReduxUpdate } from '../../redux/actions/helpdesk/helpdeskaction'
import ProgressBar from '../progressbar/progressbar'
import { emailMappingReduxUpdate } from '../../redux/actions/admin/adminaction'

const EmailMappingAdminGrid = ({ masterId, changePopUpHandler, gridName, openPopup, emailMappingStatus, loading }) => { // NOSONAR
    const [data, setData] = useState([])

    useEffect(() => {
        initialLoad();
    }, [])

    useEffect(() => {
        if (emailMappingStatus == 'updated') {
            initialLoad()
            changePopUpHandler({ type: 'close' })
        }
    }, [emailMappingStatus]);

    const initialLoad = async () => {
        emailMappingReduxUpdate({ loading: true })
        if (masterId == EMAIL_MAPPING_MASTER) {
            let res = await getAdminEmailMappingData('ALL', 0)
            setData(res.result);

        }
        emailMappingReduxUpdate({ loading: false })
    }


    const gridHeight = (window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight) - 295

    let widthColumnConfirm = 0;
    getGridColumn(gridName)?.forEach((rowData) => {
        widthColumnConfirm = widthColumnConfirm + 20 + Number(rowData.width);
    });

    const itemActive = [
        {
            text: "Edit",
            iconCss: "e-icons e-edit",
        }

    ];
    const actionTemplate = (rowData) => {
        try {
            const onSelect = (args) => {
                if (args.item.text === "Edit") {
                    //Open Popup for update
                    changePopUpHandler({ type: 'edit', editItemId: rowData.emailMappingId })
                }

            };
            return (
                <div
                    className="col-12"
                    style={{
                        textAlign: "center",
                        padding: "0px 20px 0px 0px",
                        fontSize: '14px',
                        color: '#000 !important',
                        fontFamily: 'Arial !important',
                        fontWeight: 500,
                        letterSpacing: '0.03rem',
                    }}
                >
                    <div className="row1">
                        <DropDownButtonComponent
                            items={itemActive}
                            select={onSelect}
                            iconCss="e-icons e-more-vertical-2"
                            cssClass="e-caret-hide e-custom e-vertical"
                            iconPosition="Top"
                        ></DropDownButtonComponent>
                    </div>
                </div>
            );
        } catch (error) {
        }
    };
    const notifyUserNameTemplate = (rowData) => {
        try {
            const isShowTooltip = rowData?.notifyUserName?.split(',').length > 4 ? true : false;
            return (
                isShowTooltip ? (
                    <TooltipComponent
                        content={rowData.notifyUserName}
                        cssClass="customtooltip"
                        position="BottomCenter"
                        isSticky={false}
                    >
                        <div id='notifyUserName' className='column-content'>{rowData.notifyUserName}</div>
                    </TooltipComponent>)
                    : (<div id='notifyUserName' className='column-content'>{rowData.notifyUserName}</div>)
            )
        } catch (error) {
        }
    };

    const dataBound = () => {
        emailMappingReduxUpdate({ loading: false })

    };

    const onDataBinding = (pComponentId) => {
        // spinner(true)
        emailMappingReduxUpdate({ loading: true })
        try {
            setTimeout(() => {
                document.getElementById(pComponentId).ej2_instances[0].refresh();
            });
        } catch (error) {
            console.log(error);
        }
    };
    const cssStyles = `.e-grid .e-filtermenudiv::before {
        margin: 0 1px !important;
        position: relative;
        left: -20px !important;
        top: 14px !important;
    }
    .e-headertext {
        padding-left: 5.5px !important;
    }
    .e-grid .e-headercelldiv{
        margin:0;
    }
    `
    return (
        <>
            <style>{cssStyles}</style>
            {(openPopup === 'add' || openPopup === 'edit') &&
                <DisabledEffect
                    height={document.getElementById('email-mapping-admin-container-root')?.clientHeight}
                    width={document.getElementById('email-mapping-admin-container-root')?.clientWidth}
                    cssClass='helpdesk-admin-disabled-screen'
                />}
            <div
                id='email-mapping-admin-grid'
                className="col-xs-12 col-sm-12 col-lg-12 col-md-12"
            >


                {(data.length > 0) && <GridComponent
                    id={"email-mapping-admin-grid"}
                    // width={650}
                    gridLines="Both"
                    dataSource={data}
                    // frozenColumns={2}
                    height={gridHeight}
                    allowFiltering
                    filterSettings={{
                        type: "CheckBox",
                    }}
                    dataBound={dataBound}
                    onDataBinding={() => onDataBinding("email-mapping-admin-grid")}
                    //created={onFirstGridCreated}
                    allowTextWrap
                    allowPaging
                    pageSettings={{
                        pageCount: 5,
                        pageSize: (window.innerHeight ||
                            document.documentElement.clientHeight ||
                            document.body.clientHeight) > 767
                            ? 15
                            : 10,
                    }}
                >
                    <ColumnsDirective>
                        {getGridColumn(gridName).map((item) => {
                            switch (item.colName) {
                                case ADMIN_MODULE_ACTION:
                                    return (
                                        <ColumnDirective
                                            template={actionTemplate}
                                            filter={false}
                                            textAlign={item.align}
                                            field={item.field}
                                            headerText={item.colName}
                                            width={item.width}
                                            allowFiltering={false}
                                        // freeze='Left'
                                        />
                                    );
                                case NOTIFY_USER_NAME:
                                    return (
                                        <ColumnDirective
                                            template={notifyUserNameTemplate}
                                            filter={false}
                                            textAlign={item.align}
                                            field={item.field}
                                            headerText={item.colName}
                                            width={item.width}
                                            allowFiltering={false}
                                        // freeze='Left'
                                        />
                                    );

                                default:
                                    return (
                                        <ColumnDirective
                                            field={item.field}
                                            width={item.width}
                                            headerText={item.colName}
                                            textAlign={item.align}
                                        />
                                    );
                            }
                        })}
                    </ColumnsDirective>
                    <Inject services={[Page, DetailRow, Filter, Freeze]} />
                </GridComponent>}
            </div>
            {
                loading && (
                    <div
                        className='loader-new'
                    >
                        <ProgressBar />
                    </div>
                )
            }
        </>
    )
}

const mapStateToProps = ({ adminreducer }) => {
    const { openPopup, emailMappingStatus, loading } = adminreducer;

    return {
        openPopup,
        emailMappingStatus,
        loading
    }
}

export default connect(mapStateToProps, { addEditSubCategory, helpdeskReduxUpdate })(EmailMappingAdminGrid)