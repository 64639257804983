import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ReactHtmlParser from 'react-html-parser'
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { withStyles } from '@material-ui/core/styles';
import { CheckBoxComponent } from "@syncfusion/ej2-react-buttons";
import { enableRipple } from '@syncfusion/ej2-base';
import { addUpdateObjectveKpi, submitApprovalAction, setCentralOkrKPIRedux } from "../../../src/redux/actions/okr/okractions";
import fileIcon from "../../../src/assets/svg/fileIcon_jpeg.png";
import editIcon from "../../../src/assets/svg/ic-Edit.svg";
import { Dialog } from "@material-ui/core";
import { DialogContent } from "../../components/customdialog/customdialog";
import { urlConfig } from "../../config/config";
import UpdateCommonKPI from "./updatecommonkpi";
import "../okrcreateobjectivekpi/newobjectkpi.css";
enableRipple(true);
withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));
withStyles((theme) => ({
    root: {
        '&:focus': {
            backgroundColor: theme.palette.primary.main,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white,
            },
        },
    },
}))(MenuItem);
const cssForScroll = `
::-webkit-scrollbar {
    width: 24px;
  }

  ::-webkit-scrollbar-thumb {
    background: white;
    border-right: 13px #8080804f  solid;
    background-clip: padding-box;
  }
`
class NewOkrObjectiveCommon extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openMenuKPI: false,
            anchorElKPI: false,
            commonUpdateKPI: false,
            currentKpiIndex: 0,
            currentKpiDetails: [],
            selectedKpiList: [],
            refreshMe: true,
            idx: 0,
            currentKpiData: [],
        }
        this.totalWeightageVal = 0;
    }
    updateDimensions() {
        const updateHeight = window.innerHeight - 750;
        this.setState({ heightLayout: updateHeight });
    }
    componentDidMount() {
        const { refreshMe } = this.props;
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
        this.setState({ refreshMe: refreshMe })
    }
    UNSAFE_componentWillReceiveProps = (newProps) => {
        if (newProps.userObjectStatus === "closepopup" || newProps.userObjectStatus === "Savedundefined") {
            this.props.setCentralOkrKPIRedux({ userObjectStatus: "" });
            this.setState({ selectedKpiList: [], commonUpdateKPI: false, refreshMe: !this.state.refreshMe });
        }
    }

    // KPI Attachment show
    createDraftimageFilepath = (filepath) => {
        const { employeeId } = this.props;
        let attachmentFiles = [];
        if (filepath !== "" && filepath !== null) {
            attachmentFiles = filepath.split(",");
        }
        return (
            <>
                {
                    attachmentFiles.map((attachedFile, indDraft) => (
                        <span key={`draft${indDraft}`}>
                            <span>
                                <a
                                    // eslint-disable-next-line
                                    key={`url${indDraft}`}
                                    href={`${urlConfig.tmlcApiUrl}document/kpi/${employeeId}/${attachedFile}`} target="_blank" rel="noopener noreferrer" className="attachment-file-name" download
                                    style={{ textDecoration: 'none' }}
                                >
                                    <img alt="" src={fileIcon} key={`img${indDraft}`} />
                                    <span style={{ padding: "8px 8px 12px 4px", color: "#f94f5e" }} title={attachedFile}>
                                        {attachedFile.substring(11, attachedFile.length).length > 35 ? attachedFile.substring(11, attachedFile.length).substr(0, 32) + '...' : attachedFile.substring(11, attachedFile.length)}
                                    </span>
                                </a>
                            </span>
                        </span>
                    ))
                }
            </>
        )
    }
    fillKPIDetails = (e, ObjKpiDetails, index, type) => {
        if (type === 'link') this.setState({ commonUpdateKPI: true, currentKpiDetails: ObjKpiDetails, currentKpiIndex: index });
        else if (type === 'menu') this.setState({ commonUpdateKPI: true, currentKpiDetails: this.state.currentKpiData, currentKpiIndex: this.state.currentKpiIndex, openMenuKPI: false });
    }
    fillKPIDetailsPopupClose = () => {
        this.setState({ commonUpdateKPI: false });
    }
    openMenuPopup = (e, kpiData, idx) => {
        this.setState({ openMenuKPI: true, anchorElKPI: e.currentTarget, currentKpiData: kpiData, currentKpiIndex: idx });
    }
    handleClose = () => {
        this.setState({ openMenuKPI: false, currentKpiData: [], currentKpiIndex: 0 });
    }
    editKPIDetails = () => {
        this.setState({ commonUpdateKPI: true, openMenuKPI: false });
    }
    handleKpiCheck = (e, ObjKpiDetails) => {
        const { selectedKpiList } = this.state;
        let kpiList = selectedKpiList;
        if (e.target.checked === true) {
            var param = {};
            param.userKpiId = ObjKpiDetails.userKpiId;
            param.weightage = ObjKpiDetails.weightage;
            kpiList.push(param);
        }
        else {
            kpiList = kpiList.filter((t) => t.userKpiId !== ObjKpiDetails.userKpiId);
        }
        this.setState({ selectedKpiList: kpiList, currentKpiDetails: ObjKpiDetails });
        if (kpiList.length > 0) {
            this.totalWeightageVal = kpiList.reduce(function (prev, cur) {
                return prev + cur.weightage;
            }, 0);
            this.props.renderCallback('enable', kpiList, this.totalWeightageVal);
        }
        else if (kpiList.length === 0) {
            this.totalWeightageVal = 0;
            this.props.renderCallback('disable', kpiList, this.totalWeightageVal);
        }        
    }

    listKpi = (ObjKpiDetails, idx) => {// NOSONAR
        const { variablePerc, currentTab, objectType, importObjectiveKPI, enableAction } = this.props;
        const { selectedKpiList, anchorElKPI, openMenuKPI } = this.state;
        let checkedList = selectedKpiList.filter((t) => t.userKpiId === ObjKpiDetails.userKpiId);
        let checkboxState = checkedList.length > 0 ? true : false;
        let stripColor;

        if (currentTab === 'draftTab') {
            stripColor = variablePerc > 0 && (ObjKpiDetails.varibleLink === "" || ObjKpiDetails.varibleLink === null) ? '4px solid #FDD141' : '4px solid #48B8E7';
        }
        else if (currentTab === 'activeTab') {
            stripColor = ObjKpiDetails.userKpiId > 0 && ObjKpiDetails.isKpiDraft === "0" ? '4px solid #36C17C' : '4px solid #DBDBDD';
        }
        // F2F2F2 -> Gray Background, FDD141 -> Yellow Pending, 48B8E7 -> Blue complted, 36C17C -> Green Active, DBDBDD -> Dark Gray Disable color
        return (
            <>
                <div key={`commonObj${ObjKpiDetails.kpiId}`}>
                    <Accordion key={`kpiAcc${ObjKpiDetails.kpiId}`} className="kpi-border-css"
                        style={{
                            marginTop: "8px",
                            marginBottom: importObjectiveKPI.length === idx + 1 ? '0px' : "16px",
                            // margin: "16px 0px 16px 0px", 
                            boxShadow: "none", position: "initial"
                        }}>
                        <AccordionSummary
                            style={{
                                background: "#F2F2F2", paddingLeft: "0px", borderLeft: stripColor,
                                minHeight: "30px", height: 40
                            }}
                            expandIcon={<ExpandMoreIcon />}
                            aria-label="Expand"
                            aria-controls="additional-actions1-content"
                            id="additional-actions1-header"
                        >
                            {currentTab === 'draftTab' && objectType !== '2' && objectType !== '3' && objectType !== '4' &&
                                <span key={`checkKpi${ObjKpiDetails.userKpiId}`} style={{ margin: "8px 6px 10px -32px" }}>
                                    <CheckBoxComponent
                                        id={`kpiId${ObjKpiDetails.userKpiId}`}
                                        key={`kpiId${ObjKpiDetails.userKpiId}`}
                                        checked={checkboxState}
                                        onChange={(e) => this.handleKpiCheck(e, ObjKpiDetails)}
                                    />
                                </span>
                            }
                            <div className="col-xs-12 col-sm-8 col-lg-7 col-md-7 bold-header-obj-kpi" style={{ padding: "14px 14px 14px 16px" }}>
                                {ObjKpiDetails.kpiName}
                            </div>

                            <div className="col-xs-12 col-sm-4 col-lg-5 col-md-5 tags-obj-kpi" style={{ textAlign: "right", fontSize: variablePerc > 0 && (ObjKpiDetails.varibleLink === "" || ObjKpiDetails.varibleLink === null) ? '12px' : '24px' }}>

                                {
                                    <>
                                        <span className="normal-visivbility-level-obj-kpi"><span className="normal-visivbility-level-obj-kpi">OKR Validity:</span></span>
                                        <span className="objective-validaty-text" > {ObjKpiDetails.periodFromHdr} </span>
                                    </>

                                }
                                {variablePerc > 0 && ObjKpiDetails.varibleLink !== "" && ObjKpiDetails.varibleLink !== null &&
                                    <>
                                        <span className="normal-visivbility-level-obj-kpi">Variable Link :</span><span className="kpi-footer-details" > {ObjKpiDetails.varibleLink === 0 ? "No" : "Yes"} </span>
                                    </>
                                }
                                {
                                    ObjKpiDetails.weightage !== "" && ObjKpiDetails.weightage !== null &&
                                    <>
                                        <span className="normal-visivbility-level-obj-kpi">Weightage :</span><span className="kpi-footer-details" > {ObjKpiDetails.weightage}% </span>
                                    </>
                                }
                                {variablePerc > 0 && (ObjKpiDetails.varibleLink === "" || ObjKpiDetails.varibleLink === null) && enableAction &&
                                    <FormControlLabel
                                        control={
                                            <span style={{ color: "#F94F5E", padding: "14px" }} onClick={(e) => this.fillKPIDetails(e, ObjKpiDetails, idx, 'link')}>
                                                <span className='hoverEffect-common'>
                                                    Fill Details
                                                </span>

                                            </span>
                                        }
                                        onClick={(event) => { event.stopPropagation() }}
                                        onFocus={(event) => event.stopPropagation()}
                                    />
                                }
                                {currentTab === 'draftTab' && enableAction &&
                                    <FormControlLabel style={{ margin: "0px", float: "right" }}
                                        control={
                                            <div>
                                                <IconButton id={`${ObjKpiDetails.kpiId}`} onClick={(e) => this.openMenuPopup(e, ObjKpiDetails, idx)} >
                                                    <MoreVertIcon />
                                                </IconButton>
                                                <Menu
                                                    id={`kpi${ObjKpiDetails.kpiId}`}
                                                    keepMounted
                                                    anchorEl={anchorElKPI}
                                                    open={Boolean(openMenuKPI)}
                                                    onClose={this.handleClose}
                                                    style={{ margin: "50px 0px 0px -80px" }}
                                                >
                                                    <MenuItem key={`kpiEditMenuItem${ObjKpiDetails.kpiId}`} onClick={(e) => this.fillKPIDetails(e, ObjKpiDetails, ObjKpiDetails.kpiId, 'menu')}>
                                                        <span style={{ padding: "8px 8px 8px 0px" }}><img alt="" src={editIcon} /></span>
                                                        <span style={{ padding: "0px 32px 0px 0px" }}>Edit</span>
                                                    </MenuItem>
                                                </Menu>
                                            </div>
                                        }
                                        onClick={(event) => event.stopPropagation()}
                                        onFocus={(event) => event.stopPropagation()}
                                    />
                                }
                            </div>
                        </AccordionSummary>
                        <AccordionDetails style={{ padding: "8px 18px 8px 18px" }}>
                            <div className="row">
                                <div className="col-xs-12 col-sm-12 col-lg-12" style={{ borderBottom: ObjKpiDetails.description !== null && ObjKpiDetails.description !== "" && objectType === '2' && "1px solid #8080802e" }}>
                                    <div style={{ color: "#939399", paddingBottom: "8px" }}> Description </div>
                                    <div className="description-all-common" style={{ paddingBottom: "12px" }}>
                                        {ReactHtmlParser(ObjKpiDetails.libDescription)}
                                    </div>
                                </div>
                                {
                                    ObjKpiDetails.description !== "" && ObjKpiDetails.description !== null && objectType === '2' &&
                                    <>
                                        <div className="col-xs-12 col-sm-12 col-lg-12" style={{ color: "#939399", paddingBottom: "8px", paddingTop: "8px" }}>Business Description</div>
                                        <div className="col-xs-12 col-sm-12 col-lg-12 description-all-common">
                                            {ReactHtmlParser(ObjKpiDetails.description)}
                                        </div>
                                    </>
                                }
                                {
                                    ObjKpiDetails.kpiAttachment !== "" && ObjKpiDetails.kpiAttachment !== null &&
                                    <div className="col-xs-12 col-sm-12 col-lg-12" style={{ padding: "8px 14px 0px 14px" }}>
                                        {this.createDraftimageFilepath(ObjKpiDetails.kpiAttachment)}
                                    </div>
                                }
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>
            </>

        )
    }
    getHeading = (objectType) => {
        switch (objectType) {
            case '2':
                return 'Central';
            case '3':
                return 'Leadership Principles';
            case '4':
                return 'Brand Value';
            default:
                return 'NA'
        }
    }
    render() {
        const { importObjectiveKPI, objectType, variablePerc } = this.props;
        const { commonUpdateKPI, currentKpiDetails, currentKpiIndex } = this.state;
        let okrHeading = this.getHeading(objectType);
        return (
            <>
                <div>
                    <style>{cssForScroll} </style>
                    {
                        importObjectiveKPI.map((ObjKpiDetails, index) => {
                            return (
                                <>
                                    {this.listKpi(ObjKpiDetails, index)}
                                </>
                            )
                        })
                    }

                </div>

                {/* Edit KPI Dialog */}
                <div>
                    <Dialog open={commonUpdateKPI} fullWidth width="500" maxWidth="sm" >
                        <DialogContent>
                            <div>
                                <div>
                                    <span className="add-new-self-header"> {okrHeading} OKR - {currentKpiDetails.kpiName} </span>
                                    <UpdateCommonKPI
                                        currentKpiDetails={currentKpiDetails}
                                        currentKpiIndex={currentKpiIndex}
                                        fillKPIDetailsPopupClose={() => this.fillKPIDetailsPopupClose}
                                        objectType={objectType}
                                        refreshMe={this.state.refreshMe}
                                        variablePerc={variablePerc}
                                    />
                                </div>
                            </div>
                        </DialogContent>
                    </Dialog>
                </div>
            </>
        );
    }
}
NewOkrObjectiveCommon.propTypes = {
    authUser: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    employeeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
NewOkrObjectiveCommon.defaultProps = {
    authUser: "",
    employeeId: 0,
};
const mapStateToProps = ({ auth, okrreducer }) => {
    const { authUser } = auth;
    const { userObjectStatus, employeeId } = okrreducer;
    return { authUser, userObjectStatus, employeeId }
}
export default connect(mapStateToProps, { addUpdateObjectveKpi, submitApprovalAction, setCentralOkrKPIRedux })(NewOkrObjectiveCommon);