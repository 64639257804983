import React, { Component } from 'react'
import { connect } from "react-redux";
import PropTypes, { object } from "prop-types";
import { setCentralOkrKPIRedux, addUpdateObjectveKpi } from '../../redux/actions/okr/okractions';
import { addUpdateOkrRatingRedux } from '../../redux/actions/okrrating/okrratingaction';
import { TabComponent } from '@syncfusion/ej2-react-navigations';
import ImportBusinessOKR from "./importbusinessokr";
import OKRImportObj from './okrimportobj';
import infoIcon from "../../../src/assets/svg/infoIcon.svg";
import { Button } from "@material-ui/core";
import { variablePercentageNote, TotalBusinessUserTotalariableImpact } from '../../assets/constants/constants';
import ConfirmationPopup from "../../utilities/confirmationpopup";
import OKRRatingServices from '../../services/okrrating/okrratingservices';
import ReviewObjectivKR from '../okrratingcomponents/reviewobjectivekr';
import PastRatingModal from '../okrratingcomponents/pastratingmodal'
import ActualAchivementModal from '../okrratingcomponents/actualachievementmodal';
import AddRatingModalkr from '../okrratingcomponents/addratingmodalkr';
import InterimFeedBack from '../okrratingcomponents/interimfeedback';
import ViewRatingModalKr from '../okrratingcomponents/viewratingmodalkr';
import ViewKRDetailsModal from '../okrratingcomponents/viewkrdetailsmodal';
import ClosedTabObjHeader from './closedtabobjheader';

class ImportActiveComponent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      masterObjKpiList: [],
      heightupdateheight: window.innerHeight - 440,
      isLoading: true,
      draftCount: 0,
      activeCount: 0,
      currWindowAdjust: window.innerHeight - 440,
      open: false,
      title: "",
      message: "",
      subMessage: "",
      viewKrDetailsPopup: false,
      finalRatingPopup: false,
      pastRatingPopup: false,
      viewActualAchivementPopup: false,
      viewKrRatingPopup: false,
      viewInterimReviewPopup: false,
      viewReviewKrPopup: false,
      KRData: [],
      finalRatingData: [],
      pastRatingKRData: [],
      actualKRData: [],
      categoryType: '',
      actionType: '',
      objId: '',
      selectedType: '',
      KRReviewData: [],
      type: '',
      currencyInfo: [],
      popType: "",
      childApiCall: false,
    };
    this.hideDraft = false;
    this.child = React.createRef();
    this.createObjectiveFun = this.createObjectiveFun.bind(this)
  }
  createObjectiveFun = () => { } //nosonar  initial value for getAlert

  setChildComponentMethod = (newMethod) => {
    this.createObjectiveFun = newMethod;
  }
  componentDidMount = async () => {
    window.addEventListener("resize", this.updateDimensions);
    this.updateDimensions();
    const currencyData = this.props.OkrMasters.filter((t) => t.var_list_name === 'Currency')
    this.setState({ currencyInfo: currencyData })

    if (this.props.enableAction)
      this.props.setCentralOkrKPIRedux({ activeTabFlag: false, });

    if (!this.props.enableAction) {
      this.setState({
        childApiCall: true
      })
      setTimeout(() => {
        this.setState({
          childApiCall: false
        })
      }, 1000)
    }

  };

  updateDimensions() {
    if (this.props !== undefined) {
      const { windowAdjust } = this.props;
      try {
        const updateheight = window.innerHeight - 405;
        const currWindowAdjust = windowAdjust - 184;
        this.setState({
          heightupdateheight: updateheight,
          currWindowAdjust: currWindowAdjust
        });
      } catch (err) { }
    }
  }
  //WARNING! To be deprecated in React v17. Use new lifecycle static getDerivedStateFromProps instead.
  componentWillReceiveProps(nextProps) { // NOSONAR
    window.addEventListener("resize", this.updateDimensions);
    if (nextProps.updateBuActiveFlag === 'updated') {
      if (nextProps.updateDefaultSelectionTabFlag === 'updated') {
        this.getDashboardReviewData(nextProps.closedMonthOfReview);
        this.props.setCentralOkrKPIRedux({ updateDefaultSelectionTabFlag: "" });
      }
      if (nextProps.submittedBusinessObjective.length > 0) {
        let cnt = 0;
        // eslint-disable-next-line
        nextProps.submittedBusinessObjective.map((item) => {
          if (item.disableEnableFlag === '1')
            cnt += 1;
        })
        if (document.getElementById('draftTextCount'))
          document.getElementById('draftTextCount').innerHTML = `(${nextProps.draftedBusinessObjKpi.length})`;
        document.getElementById('closeTextCount').innerHTML = `(${nextProps.closedBusinessOkr.length})`;
        document.getElementById('activeTextCount').innerHTML = `(${nextProps.submittedBusinessObjective.length - cnt})`;
      } else {
        if (document.getElementById('draftTextCount'))
          document.getElementById('draftTextCount').innerHTML = `(${nextProps.draftedBusinessObjKpi.length})`;
        document.getElementById('activeTextCount').innerHTML = `(${nextProps.submittedBusinessObjective.length})`;
        document.getElementById('closeTextCount').innerHTML = `(${nextProps.closedBusinessOkr.length})`;
      }
      this.props.setCentralOkrKPIRedux({ updateBuActiveFlag: "" });

      if (!nextProps.enableAction) {
        this.hideDraft = true;
        this.props.addUpdateObjectveKpi({ activeTabFlag: true });
      }
      if (nextProps.isNewObjCreated === "Y") {
        this.setState({
          popType: "NewObj",
          open: true,
          title: "New Objective is created",
          message: 'A copy of this OKR has been created in "Draft".',
          subMessage: "You may now add/edit details of the OKR and submit to activate the OKR."
        })
      }

      if (nextProps.isObjDisabled === "Y") {
        this.setState({
          popType: "RemoveObj",
          open: true,
          title: 'Objective removed',
          message: 'The OKR has been removed & moved to "Draft".',
          subMessage: "You may now add/edit details of the OKR and submit to reactivate the OKR."
        })
        this.props.setCentralOkrKPIRedux({ isObjDisabled: "N" });
      }
    }
    if (nextProps.moveToActive === 'updated' && this.tabObj !== undefined) {
      if (nextProps.enableAction && nextProps.submittedBusinessObjective.length > 0) {
        this.tabObj.selectedItem = 2;
      }
      this.props.setCentralOkrKPIRedux({ moveToActive: "" });
    }
    if (nextProps.closedTabStatus === 'updated' && nextProps.okrAllocationTabs === 'closed') {
      this.getDashboardReviewData(nextProps.closedMonthOfReview);
      this.props.addUpdateObjectveKpi({ closedTabStatus: "" })
      //nosonar this.tabObj.selectedItem = 3;
    }
  }

  changeTab = (args) => {
    const { enableAction } = this.props;
    if (args.selectedIndex === 2) {
      this.props.addUpdateObjectveKpi({ activeTabFlag: true, okrAllocationTabs: enableAction ? "active" : "closed" })
    } else {
      this.props.addUpdateObjectveKpi({ activeTabFlag: false })
    }
    if (args.selectedIndex === 0) {
      this.props.addUpdateObjectveKpi({ okrAllocationTabs: "library" })
      window.open("/okr?goto=library", '_blank')
      this.tabObj.selectedItem = args.previousIndex;
    }
    if (args.selectedIndex === 1) {
      this.props.addUpdateObjectveKpi({ okrAllocationTabs: enableAction ? "draft" : "active", activeTabFlag: enableAction ? false : true })
    }
    if (args.selectedIndex === 3) {
      this.props.addUpdateObjectveKpi({ okrAllocationTabs: "closed" })
    }

  }
  handleConfirmYes = () => {
    const { popType } = this.state

    this.setState({
      open: false,
      title: "",
      message: "",
      subMessage: ""
    })
    if (popType === "NewObj") {
      this.tabObj.selectedItem = 1;
      this.props.addUpdateObjectveKpi({ newObjStartMonth: null, newObjFreqency: null, isNewObjCreated: null, activeTabFlag: false, updateBuDraftFlag: "updated", updateimportStatus: 'updated' });
      setTimeout(() => { window.tour() }, 500)
    }
  }

  handleConfirmClose = () => {
    this.setState({
      open: false,
      title: "",
      message: "",
      subMessage: ""
    })
    this.tabObj.selectedItem = 0;
    this.props.addUpdateObjectveKpi({ newObjStartMonth: null, newObjFreqency: null, isNewObjCreated: null, activeTabFlag: false, updateBuDraftFlag: "updated", updateimportStatus: 'updated' });
  }
  getFrequencyName = (type, data, frq) => {/**NOSONAR */
    try {
      const { OkrMasters } = this.props;
      const frequency = OkrMasters.filter((item) => {
        return item.var_list_name === "Frequency";
      });
      const effectiveqtr = OkrMasters.filter((item) => {
        return item.var_list_name === "EffectiveQtrView";
      });
      const effectiveMnt = OkrMasters.filter((item) => {
        return item.var_list_name === "EffectiveMnthView";
      });
      const effectiveHlpYear = OkrMasters.filter((item) => {
        return item.var_list_name === "EffectiveHYView";
      });
      const effectiveYear = OkrMasters.filter((item) => {
        return item.var_list_name === "EffectiveFYView";
      });

      let returnText = '';
      if (type === 'frequency') {
        // eslint-disable-next-line
        returnText = frequency.filter((item) => {
          if (item.Id === data) {
            return item.var_list_value
          }
        })
        if (data !== null && returnText.length > 0) {
          returnText = returnText[0].var_list_value
        }
      }

      if (type === 'effectiveMonth') {
        if (frq === 1) {
          // eslint-disable-next-line
          returnText = effectiveMnt.filter((item) => {/**NOSONAR */
            if (item.Id === data) {
              return item.var_list_value
            }
          })
          if (data !== null && returnText.length > 0) {/**NOSONAR */
            returnText = returnText[0].var_list_value
          }
        }
        if (frq === 2) {
          // eslint-disable-next-line
          returnText = effectiveqtr.filter((item) => {/**NOSONAR */
            if (item.Id === data) {
              return item.var_list_value
            }
          })
          if (data !== null && returnText.length > 0) {
            returnText = returnText[0].var_list_value
          }
        }
        if (frq === 3) {
          // eslint-disable-next-line
          returnText = effectiveHlpYear.filter((item) => {/**NOSONAR */
            if (item.Id === data) {
              return item.var_list_value
            }
          })
          if (data !== null && returnText.length > 0) {
            returnText = returnText[0].var_list_value
          }
        }
        if (frq === 4) {
          // eslint-disable-next-line
          returnText = effectiveYear.filter((item) => {/**NOSONAR */
            if (item.Id === data) {
              return item.var_list_value
            }
          })
          if (data !== null && returnText.length > 0) {
            returnText = returnText[0].var_list_value
          }
        }
      }
      return (
        <span>{returnText}</span>
      );
    } catch (err) {
      console.log('error===>', err);
    }
  }
  getFrequencysName = (type) => {
    let frequenyName = '';
    switch (type) {
      case 1:
        frequenyName = "Month";
        break;
      case 2:
        frequenyName = "Quater";
        break;
      case 3:
        frequenyName = "Half Year";
        break;
      case 4:
        frequenyName = "Year";
        break;
      default:
    }
    return frequenyName;
  }

  getDashboardReviewData = (closedMonthOfReview) => {
    const { employeeId, fyid, authUser, submittedBusinessObjective, draftedBusinessObjKpi } = this.props;
    try {//NOSONAR
      const okrRatingDashboardData = Promise.resolve(
        OKRRatingServices.getReviewDashboardData(authUser, employeeId, fyid, closedMonthOfReview, '1')
      );
      okrRatingDashboardData.then((resultData) => {
        this.props.addUpdateObjectveKpi({ closedBusinessOkr: resultData });
        if (document.getElementById('closeTextCount') !== null)
          document.getElementById('closeTextCount').innerHTML = `(${resultData.length})`;

        setTimeout(() => {
          if (this.tabObj !== undefined) {
            if (authUser === employeeId) {
              if (submittedBusinessObjective.length === 0 && resultData.length > 0) {
                this.tabObj.selectedItem = 2;//close tab
              } else {
                this.tabObj.selectedItem = 1;//Active tab
              }
            } else {
              if (submittedBusinessObjective.length === 0 && draftedBusinessObjKpi.length === 0 && resultData.length > 0) {
                this.tabObj.selectedItem = 3;//Close tab
              }
              else if (submittedBusinessObjective.length > 0) {
                this.tabObj.selectedItem = 2;//Active tab
              }
              else {
                this.tabObj.selectedItem = 1;//Draft tab
              }
            }
          }
        }, 100)

      });
    } catch (err) {
      console.log("OKRRatingServices", err)
    }
  }
  opneKrDetailsPopup = (data) => {
    this.setState({ viewKrDetailsPopup: true, KRData: data })
  }
  openFinalRatingPopup = (data) => {
    this.setState({ finalRatingPopup: true, finalRatingData: data })
  }
  openPastRatingPopup = (data) => {
    this.setState({ pastRatingPopup: true, pastRatingKRData: data })
  }
  openActualAchivementPopup = (data, categoryType, actionType) => {
    this.setState({ viewActualAchivementPopup: true, actualKRData: data, categoryType: categoryType, actionType: actionType })
  }
  openAddEditIntrimFeedbackPopup = (data) => {
    this.setState({
      addEditInterimReviewPopup: true,
      KRData: data,

    });
  };
  openKrIntrimFeedbackPopup = (data, userObjectiveId, objectiveName) => {
    this.setState({
      viewKrInterimFeedbackPopup: true,
      KRData: data,
      objId: userObjectiveId,
      objectiveName: objectiveName
    });
  };
  openViewIntrimFeedbackPopup = (data, userObjectiveId, selectedType, draftClick) => {

    this.setState({
      KRData: data,
      viewInterimReviewPopup: true,
      objId: userObjectiveId,
      selectedType: selectedType
    });
    this.props.addUpdateOkrRatingRedux({ krData: data, interimType: selectedType, isDraftClick: draftClick })
  };
  viewKrReviewPopup = (data, type) => {
    this.setState({
      KRReviewData: data,
      viewReviewKrPopup: true,
      type: type
    })
  }
  closeKrDetailsPopup = () => {
    this.setState({ viewKrDetailsPopup: false, KRData: {} })
  }
  closeFinalRatingPopup = () => {
    this.setState({ finalRatingPopup: false, finalRatingData: {} })
  }
  closePastRatingPopup = () => {
    this.setState({ pastRatingPopup: false, pastRatingKRData: {} })
  }
  closeActualAchivementPopup = () => {
    this.setState({ viewActualAchivementPopup: false, actualKRData: {}, categoryType: '', actionType: '', userType: '' })
  }
  closeKrRatingPopup = () => {
    this.setState({ viewKrRatingPopup: false })
  }
  closeViewIntrimFeedbackPopup = () => {
    this.setState({
      viewInterimReviewPopup: false,
      objId: null,
      selectedType: null,
      KRData: {}
    });
    this.props.addUpdateOkrRatingRedux({ krData: {}, interimType: "", isDraftClick: null })
  };
  closeKrReviewPopup = () => {
    this.setState({
      KRReviewData: {},
      viewReviewKrPopup: false,
      type: ''
    })
  }


  render() { // NOSONAR
    const { heightupdateheight, currWindowAdjust, viewKrDetailsPopup, finalRatingPopup, pastRatingPopup, viewActualAchivementPopup, viewKrRatingPopup,
      viewInterimReviewPopup, viewReviewKrPopup,
      KRData, finalRatingData, pastRatingKRData, actualKRData, categoryType, actionType, objId, selectedType, KRReviewData, type, currencyInfo, childApiCall
    } = this.state;
    const { submittedBusinessObjective, setHeightFlag, activeTabFlag, businessUserVariableImpact, draftedBusinessObjKpi, monthOfReview, enableAction,
      closedBusinessOkr, categoryName, okrAllocationTabs } = this.props;
    let smallResolution = (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight) > 767 ? 0 : + 20;

    let showVriableNote = false;
    let totalActiveMoth = 0;
    if (businessUserVariableImpact > 0 && submittedBusinessObjective.length > 0) {
      // eslint-disable-next-line 
      submittedBusinessObjective.map((item) => {
        if (item.disableEnableFlag !== '1') {
          totalActiveMoth += item.variable;
        }
      })
      if (totalActiveMoth !== TotalBusinessUserTotalariableImpact && monthOfReview !== 0) {
        showVriableNote = true;
      }
    }
    let activeTabMargin = 10;
    if (!enableAction)
      activeTabMargin = 3;

    return (
      <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12' style={{ padding: "12px 12px 12px 12px", paddingTop: okrAllocationTabs === 'draft' ? '12px' : '2px' }}>
        {
          !activeTabFlag && draftedBusinessObjKpi.length > 0 && enableAction && okrAllocationTabs === 'draft' &&
          <>
            <div className='col-lg-11 col-md-12 col-sm-12 col-xs-12' style={{ textAlign: "center", padding: "5px 0px 0px 100px", position: 'absolute' }}>
              <span className="business-okr-note"> <img alt="" src={infoIcon} /> Click on "Fill Details" to enter specific inputs for each<strong> Objective</strong> and <strong>Key Result </strong></span>
            </div>
            <Button
              style={{
                background: "#FFF",
                color: "#fff",
                width: 120,
                height: 32,
                float: "right",
                cursor: 'pointer',
                zIndex: 1111
              }}
              className="e-event-details ml-sm remove-button-shadow "
              variant="contained"
              size="small"
              color="primary"
              onClick={this.createObjectiveFun}
            // onClick={() => this.createObjectiveFun()}
            >
              Import
            </Button>
          </>
        }
        {
          activeTabFlag && showVriableNote &&
          <div className='col-lg-11 col-md-12 col-sm-12 col-xs-12' style={{ textAlign: "center", padding: "8px 0px 0px 100px", position: 'absolute' }}>
            <span style={{ fontSize: 12, textAlign: "center", color: 'red', padding: '8px 12px' }}>{variablePercentageNote} </span>
          </div>
        }
        <TabComponent id="defaultTab"
          style={{ left: '0px', right: '100px' }}
          animation={{ previous: { effect: "SlideLeftIn", duration: 0, easing: "ease" }, next: { effect: "SlideRightIn", duration: 0, easing: "ease" } }}
          selected={(arg) => this.changeTab(arg)}
          ref={(tabObj) => (this.tabObj = tabObj)}
          allowDragAndDrop={true}>
          <div className="e-tab-header">

            <div >
              <span style={{ marginLeft: activeTabMargin, textTransform: 'none', fontWeight: 600, fontSize: "14px", font: 'normal normal bold 14px/25px Arial' }}>Library</span>
              <span id='libraryTextCount' style={{ paddingLeft: 5, textTransform: 'none', font: 'normal normal bold 14px/25px Arial' }}> </span>
            </div>

            {enableAction &&
              <div>
                <span style={{ textTransform: 'none', fontWeight: 600, fontSize: "14px", font: 'normal normal bold 14px/25px Arial' }}>Draft</span>
                <span id='draftTextCount' style={{ paddingLeft: 5, textTransform: 'none', font: 'normal normal bold 14px/25px Arial' }}> </span>
              </div>
            }
            <div>

              <span style={{ marginLeft: activeTabMargin, textTransform: 'none', fontWeight: 600, fontSize: "14px", font: 'normal normal bold 14px/25px Arial' }}>Active</span>
              <span id='activeTextCount' style={{ paddingLeft: 5, textTransform: 'none', font: 'normal normal bold 14px/25px Arial' }}> </span>

            </div>
            <div>
              <span style={{ marginLeft: activeTabMargin, textTransform: 'none', fontWeight: 600, fontSize: "14px", font: 'normal normal bold 14px/25px Arial' }}>Closed</span>
              <span id='closeTextCount' style={{ paddingLeft: 5, textTransform: 'none', font: 'normal normal bold 14px/25px Arial' }}> </span>
            </div>
          </div>
          <hr style={{ margin: 0, border: "1px solid #F2F2F2" }} />
          <div className="e-content">
            {/* Library */}
            <div>
              .
            </div>

            {
              enableAction && (!this.hideDraft &&
                <div>
                  <ImportBusinessOKR setChildComponentMethod={this.setChildComponentMethod} setHeightFlag={setHeightFlag} currWindowAdjust={currWindowAdjust} enableAction={enableAction} />
                </div>
              )
            }

            {/* Active */}

            <div>
              <div
                id='activeTabBusinessOkr'
                style={{
                  // height: 300,
                  height: setHeightFlag === true ? currWindowAdjust + 50 + smallResolution : heightupdateheight + smallResolution,
                  padding: '0px 0px 0px 0px',
                  overflowY: "auto",
                  overflowX: "hidden",
                  position: "relative",
                  marginTop: '18px',
                  marginBottom: '-6px',
                }}
              >
                {
                  childApiCall &&
                  <ImportBusinessOKR setChildComponentMethod={this.setChildComponentMethod} setHeightFlag={setHeightFlag} currWindowAdjust={currWindowAdjust} enableAction={enableAction} />

                }
                {
                  submittedBusinessObjective.length > 0 ?
                    <OKRImportObj masterObjKpiList={submittedBusinessObjective} showHideComponent="activeBusinessOKR" enableAction={enableAction} />
                    :
                    <div style={{ marginTop: '8%' }}>
                      <div className='new-productivity-allocation-no-data-header'>No Items</div>
                      <div className='new-productivity-allocation-no-data-sub-header pt-lg'>You don't have any items here. Select employee</div>
                      <div className='new-productivity-allocation-no-data-sub-header'>and financial year to continue.</div>
                    </div>
                }
              </div>
              <div style={{ padding: "10px 0px 0px 0px" }}>
                {
                  submittedBusinessObjective.length > 0 &&
                  <div className="col-xs-12 col-sm-8 col-lg-11" style={{ padding: "8px 0px 0px" }}>
                    <span className="footer-status" style={{ background: "#36C17C" }}></span>
                    <span style={{ padding: "0px 24px 0px 8px" }}>Active</span>
                    <span className="footer-status" style={{ background: "#f47a7a" }}></span>
                    <span style={{ padding: "0px 24px 0px 8px" }}>Discrepancy</span>
                    {businessUserVariableImpact > 0 && monthOfReview !== 0 && <div style={{ float: 'right', marginRight: '-2%' }}><span className="normal-visivbility-level-obj-kpi" style={{ textAlign: 'right', fontSize: 16 }}>Total of OKR's Variable Impact %: </span><span className="objective-validaty-text" style={{ color: totalActiveMoth === TotalBusinessUserTotalariableImpact ? "#36C17C" : '#f47a7a', fontSize: 16 }} >{totalActiveMoth}% </span></div>}
                  </div>
                }
              </div>
            </div>
            {/* closed okr */}
            <div
              style={{
                // height: 300,
                height: setHeightFlag === true ? currWindowAdjust + 50 : heightupdateheight,
                padding: '0px 0px 0px 0px',
                overflowY: "auto",
                overflowX: "hidden",
                position: "relative",
                marginTop: '0px',
                marginBottom: '-6px',
              }}
            >
              {
                closedBusinessOkr.map((item, inx) => (
                  <div key={`reviewBusinessOKR_${inx}`} id={`reviewBusinessOKR_${inx}`}>
                    <>
                      <ClosedTabObjHeader objectiveData={item} />
                    </>
                    <ReviewObjectivKR objectiveData={item}
                      opneKrDetailsPopup={this.opneKrDetailsPopup}
                      openFinalRatingPopup={this.openFinalRatingPopup}
                      openPastRatingPopup={this.openPastRatingPopup}
                      openActualAchivementPopup={this.openActualAchivementPopup}
                      openAddEditIntrimFeedbackPopup={this.openAddEditIntrimFeedbackPopup}
                      openKrIntrimFeedbackPopup={this.openKrIntrimFeedbackPopup}
                      openViewIntrimFeedbackPopup={this.openViewIntrimFeedbackPopup}
                      ratingTabs={"sup"}
                      OKRType={"1"}
                      currencyInfo={currencyInfo}
                      viewKrReviewPopup={this.viewKrReviewPopup}
                      OKRAllocationStatus={""}
                      categoryName={categoryName}
                      VariableLinked={"No"} />
                  </div>
                ))
              }
              {
                viewKrDetailsPopup &&
                <ViewKRDetailsModal
                  closeKrDetailsPopup={this.closeKrDetailsPopup}
                  OKRType={"1"}
                  ratingTabs={"sup"}
                  ratingType={"review"}
                  KRData={KRData}

                />
              }
              {
                finalRatingPopup &&
                <AddRatingModalkr
                  closeKrRatingPopup={this.closeFinalRatingPopup}
                  OKRType={"1"}
                  ratingTabs={"sup"}
                  KRData={finalRatingData}
                  type={"finalRaing"}
                />
              }
              {
                pastRatingPopup &&
                <PastRatingModal
                  KRData={pastRatingKRData}
                  OKRType={'1'}
                  closePastRatingPopup={this.closePastRatingPopup}
                />
              }
              {
                viewActualAchivementPopup &&
                <ActualAchivementModal
                  closeActualAchivementPopup={this.closeActualAchivementPopup}
                  OKRType={"1"}
                  ratingTabs={"sup"}
                  actualKRData={actualKRData}
                  currencyInfo={currencyInfo}
                  OKRAllocationStatus={""}
                  categoryType={categoryType}
                  actionType={actionType}
                />
              }

              {
                viewKrRatingPopup &&
                <AddRatingModalkr
                  closeKrRatingPopup={this.closeKrRatingPopup}
                  OKRType={"1"}
                  ratingTabs={"sup"}
                  KRData={KRData}
                />}
              {viewInterimReviewPopup &&
                <InterimFeedBack
                  closeViewIntrimFeedbackPopup={this.closeViewIntrimFeedbackPopup}
                  ratingTabs={"sup"}
                  OKRType={"1"}
                  KRData={KRData}
                  objId={objId}
                  selectedType={selectedType}
                />}
              {
                viewReviewKrPopup &&
                <ViewRatingModalKr
                  OKRType={"1"}
                  KRReviewData={KRReviewData}
                  closeKrReviewPopup={this.closeKrReviewPopup}
                  type={type}
                />
              }
            </div>
          </div>
        </TabComponent>
        <ConfirmationPopup
          open={this.state.open}
          headerMessage={this.state.message}
          subMessage={this.state.subMessage}
          button1Text={"OK"}
          button2Text={""}
          button1function={this.handleConfirmYes}
          button2function={this.handleConfirmClose}
          handleConfirmClose={this.handleConfirmClose}
          showButton={{ type: "1" }}
        />
      </div>
    )
  }
}

ImportActiveComponent.propTypes = {
  authUser: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  employeeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  moveToActive: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  updateBuActiveFlag: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  draftedBusinessObjKpi: PropTypes.oneOfType([PropTypes.arrayOf(object), PropTypes.array]),
  submittedBusinessObjective: PropTypes.oneOfType([PropTypes.arrayOf(object), PropTypes.array]),
  setHeightFlag: PropTypes.bool.isRequired,
  windowAdjust: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  activeTabFlag: PropTypes.bool,
  businessUserVariableImpact: PropTypes.number,
  monthOfReview: PropTypes.number,
  enableAction: PropTypes.bool,
};
ImportActiveComponent.defaultProps = {
  authUser: "",
  updateBuActiveFlag: "",
  moveToActive: "",
  employeeId: 0,
  businessUserVariableImpact: 0,
  draftedBusinessObjKpi: [],
  submittedBusinessObjective: [],
  activeTabFlag: false,
  monthOfReview: 0,
  enableAction: true,
};
const mapStateToProps = ({ auth, okrreducer, meetingcategory }) => {
  const { categoryName } = meetingcategory;
  const { authUser } = auth;
  const { employeeId, submittedBusinessObjective, updateBuActiveFlag, draftedBusinessObjKpi, activeTabFlag, moveToActive,
    businessUserVariableImpact, monthOfReview, isNewObjCreated, newObjFreqency, newObjStartMonth, OkrMasters, fyid, closedBusinessOkr, okrAllocationTabs,
    closedMonthOfReview, closedTabStatus, isObjDisabled, updateDefaultSelectionTabFlag } = okrreducer;
  return {
    authUser, employeeId, submittedBusinessObjective, updateBuActiveFlag, draftedBusinessObjKpi, activeTabFlag, moveToActive,
    businessUserVariableImpact, monthOfReview, isNewObjCreated, newObjFreqency, newObjStartMonth, OkrMasters, fyid,
    closedBusinessOkr, categoryName, okrAllocationTabs, closedMonthOfReview, closedTabStatus, isObjDisabled, updateDefaultSelectionTabFlag
  };
};
export default connect(mapStateToProps, { setCentralOkrKPIRedux, addUpdateObjectveKpi, addUpdateOkrRatingRedux })(
  ImportActiveComponent
);
