import React, { Component } from 'react';
import ComplianceReport from '../../../../components/okrdashboardcomponents/compliancereport';

export class ComplienceReportContainer extends Component {
  render() {
    return (
      <>
        <ComplianceReport />
      </>
    )
  }
}

export default ComplienceReportContainer;