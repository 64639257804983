import React, { useState, useEffect } from "react";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import { ComboBoxComponent } from "@syncfusion/ej2-react-dropdowns";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import TextField from "@material-ui/core/TextField";
import { connect } from "react-redux";
import LnAServices from "../../services/lna/lnaservice";
import { saveHolidayCluster, updateLnAData } from "../../redux/actions/lna/lnaaction";
import { getMySqlDateFormat, } from "../../app/other/commonfunction";
import UploadDocuments from "../okrratingcomponents/uploaddocuments";
import { FormValidator } from "@syncfusion/ej2-inputs";
import { createImageFilepathForLPView } from "../okrratingcomponents/ratingcommonfun";
import {
  pleaseEnterHolidayName,
  pleaseSelectBaseCountry,
  pleaseEnterLeaveType,
  regexForHoliday, selectholidaydate
} from "../../assets/constants/constants";
import ProgressBar from "../progressbar/progressbar";

const SAMPLECSS = `
input.e-input, textarea.e-input, .e-input-group, .e-input-group.e-control-wrapper {
  font-size: 12px;
}
`
const HolidayCluster = (props) => {
  //NOSONAR
  const {
    authUser,
    openPopup,
    officeCountry,
    officeLocation,
    editClusterId,
    allCountry,
    allState
  } = props;

  const [state, setState] = useState({
    title: null,
    officeLocation: null,
    allCountry: null,
    holidayMapID: null,
    holidayName: null,
    holidayDate: null,
    holidayDay: null,
    countryId: null,
    cityId: null,
    holidayType: "1",
    holidayResetDate: null,
    holidayDoc: null,
    isActive: "1",
    editIsActive: null, //when you edit
    holidayPolicyDoc: [],
    allowmultiple: null,
    fileName: "",
    oldFileStatus: "",
  });

  const [changedData, setChangedData] = useState(false);
  const [cityList, setCityList] = useState([]);
  const [initialLoad, setInitialLoad] = useState(false)

  let validOptions = {
    rules: {
      holidayNameErr: {
        required: [true, pleaseEnterHolidayName],
        regex: [regexForHoliday, pleaseEnterHolidayName],
      },
      countryErr: {
        required: [true, pleaseSelectBaseCountry],
      },
      selectTypeErr: {
        required: [true, pleaseEnterLeaveType],
      },
      holidayDateErr: {
        required: [true, selectholidaydate],
      },
      // resetDateErr: {
      //   required: [true, pleaseEnterResetDate],
      // },
    },
  };


  useEffect(() => {
    props.updateLnAData({ popUpSource: 'Holiday Master' })
    getData();
  }, []);
  useEffect(() => {
    getData();
  }, [editClusterId, openPopup]);

  useEffect(() => {
    setTimeout(() => {
      props.setSubmitClick(saveHolidayData);
    }, 100);
  }, [state]);

  useEffect(() => {
    if (props.lnaMasterFlag === "updated") {
      props.changePopUpHandler("close");
    }
  }, [props.lnaMasterFlag]);


  useEffect(() => {
    getCityList(state.countryId);
  }, [state.countryId]);

  useEffect(() => {
    if (!props.holidayLoader && props.lnaMasterFlag !== "updated") {
      setInitialLoad(false);
    }
  }, [props.holidayLoader, props.lnaMasterFlag])
  const getData = () => {
    if (openPopup === "add") {
      resetData()
    } else {
      editClusterId !== null &&
        (async () => {
          setInitialLoad(true)
          if (props.currentFinancialYearId != null) {
            const holidayClusterData = await LnAServices.getHolidayClusterData(
              authUser,
              props.currentFinancialYearId,
              editClusterId
            );

            const {
              ResetDate,
              countryId,
              date,
              holidayDayName,
              holidayName,
              holidayType,
              isActive,
              cityId,
              fileName,
            } = holidayClusterData.Result[0];
            cityId && getCityList(countryId)
            let data = {
              holidayName,
              holidayMapID: editClusterId,
              holidayDate: date,
              holidayDay: holidayDayName,
              countryId: countryId,
              cityId: cityId,
              holidayType: holidayType,
              holidayResetDate: ResetDate,
              holidayDoc: null,
              isActive,
              officeLocation,
              allCountry,
              fileName: fileName,
              oldFileStatus: fileName,
            };
            setInitialLoad(false)
            setState((preState) => ({ ...preState, ...data }));

            setTimeout(() => {
              const addFormObject = new FormValidator("#holidayClusterForum", validOptions);
              addFormObject.validate();
              let elements = document.getElementsByClassName('e-float-input e-control-wrapper e-input-group e-outline e-date-wrapper e-valid-input e-error')
              if (elements.length > 0) {

                elements[0].classList.remove('e-error')
              }

            }, 100)
          }
        })();

    }
  };
  const resetData = () => {
    let data = {
      officeLocation: null,
      allCountry: null,
      holidayMapID: null,
      holidayName: null,
      holidayDate: null,
      holidayDay: null,
      countryId: null,
      cityId: null,
      holidayType: null,
      holidayResetDate: null,
      holidayDoc: null,
      isActive: "1",
      holidayPolicyDoc: [],
      allowmultiple: null,
      fileName: "",
      oldFileStatus: "",
    };
    setState((preState) => ({ ...preState, ...data }));
  }

  const TextFieldComponent = (prop) => {
    return <TextField {...prop} color="primary" disabled />;
  };

  const getCityList = (pCountryId) => {
    const cityListArray = [];
    officeLocation.forEach((item) => {
      // allCountry.forEach((item) => {
      if (item.optional1 === pCountryId) {
        cityListArray.push(item);
      }
    });
    setCityList(cityListArray);
  };

  const setDay = (e) => {
    let index = new Date(e.value).getDay();

    const weekDay = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    let holidayDay = weekDay[index];
    setState((preState) => ({ ...preState, holidayDay: holidayDay }));
  };

  const inputChangeHandler = (e, type) => {
    let options = {};
    switch (type) {
      case "holidayName":
        setState((prevState) => ({ ...prevState, holidayName: e.value.trim() }));
        options = {
          rules: {
            holidayNameErr: {
              required: [true, pleaseEnterHolidayName],
              regex: [regexForHoliday, pleaseEnterHolidayName],
              minLength: [1, pleaseEnterHolidayName],
              maxLength: [50, "Should be maximum of 50 characters only."]
            },
          },
        };

        break;
      case "holidayDate":
        setState((prevState) => ({ ...prevState, holidayDate: e.value }));
        document.getElementById("holidayDateErr").innerHTML = "";
        setDay(e);

        break;
      case "holidayDay":
        setState((prevState) => ({ ...prevState, holidayDay: e.value }));

        break;
      case "countryId":
        setState({
          ...state,
          countryId: e.value !== null ? e.value : "",
          cityId: null
        });
        getCityList(e.value);
        options = {
          rules: {
            countryErr: {
              required: [true, pleaseSelectBaseCountry],
            },
          },
        };

        break;
      case "cityId":
        setState((prevState) => ({
          ...prevState,
          cityId: e.value !== null ? e.value : null,
        }));


        break;
      case "holidayType":
        setState({ ...state, holidayType: e.itemData !== null ? e.itemData.Id.toString() : "", })
        document.getElementById("selectTypeErr").innerHTML = ""
        options = {
          rules: {
            selectTypeErr: {
              required: [true, pleaseEnterLeaveType],
            },
          },
        };

        break;
      // case "holidayResetDate":
      //   setState((prevState) => ({ ...prevState, holidayResetDate: e.value }));
      // document.getElementById("resetDateErr").innerHTML = "";
      // options = {
      //   rules: {
      //     resetDateErr: {
      //       required: [true, pleaseEnterResetDate],
      //     },
      //   },
      // };

      // break;
      case "isActive":
        setState((prevState) => ({
          ...prevState,
          isActive: e.checked === true ? "1" : "0",
        }));

        break;
      default:
        options = {};
    }
    setTimeout(() => {
      const addFormObject = new FormValidator("#holidayClusterForum", options);
      addFormObject.validate();
    }, 100);
  };

  const saveHolidayData = async () => {
    const {
      holidayName,
      holidayDate,
      countryId,
      cityId,
      holidayResetDate,
      holidayType,
      holidayDoc,
      isActive,
      holidayMapID,
      fileName,
    } = state;
    let validateDates = true;
    const addFormObject = new FormValidator("#holidayClusterForum", validOptions);
    if (addFormObject.validate() && validateDates) {
      setInitialLoad(true)
      let hD = getMySqlDateFormat(holidayDate); //output 2020-12-12 00:00:00
      let hRD = getMySqlDateFormat(holidayResetDate); //output 2020-12-12 00:00:00
      let holidayClusterSaveData = {
        userId: authUser,
        type: holidayMapID > 0 ? "EDIT" : "ADD",
        holidayMapID,
        holidayName,
        holidayDate: hD,
        countryId,
        cityId,
        holidayType,
        holidayResetDate: hRD, //null allowed
        holidayDoc, //null allowed
        isActive,
        fileName,
      };
      props.updateLnAData({ holidayLoader: true })
      props.saveHolidayCluster(holidayClusterSaveData);
      // props.saveHolidayCluster(holidayClusterSaveData)
      // setTimeout(() => {
      //   setInitialLoad(false)
      // }, 1000)
    }
  };

  const selectedFiles = (fileNms) => {
    try {
      setState((preState) => ({ ...preState, holidayPolicyDoc: fileNms }));
      setTimeout(() => {
        setChangedData(!changedData);
      });
    } catch (error) { }
  };
  const removeFile = () => {
    setState(preState => ({ ...preState, fileName: "" }))
  }

  const documentDetails = (currentTime, file) => {
    setState((prevState) => ({
      ...prevState,
      fileName: file,
      oldFileStatus: file,
    }));
  };
  // useEffect (()=>{
  //    if(props.holidayLoader){
  //     setInitialLoad(false);
  //     props.updateLnAData({holidayLoader:false})
  //    }
  // }, props.holidayLoader===true)

  const { countryId } = state
  return (
    <>
      <div
        id="holidayClusterForum"
        style={{ height: "61.5vh", overflowY: 'auto', overflowX: 'hidden' }}
      >
        <style>{SAMPLECSS}</style>
        <div className="col-xs-12 col-sm-12 col-lg-12 col-xl-12 mt-lg">
          <div className="col-sm-12 col-md-12 col-lg-12">
            <TextBoxComponent
              data-msg-containerid="holidayNameErr"
              id="holidayName"
              value={state.holidayName}
              name="holidayNameErr"
              placeholder="Holiday Name *"
              floatLabelType="Auto"
              cssClass="e-outline"
              htmlAttributes={{ maxlength: 50 }}
              onChange={(e) => inputChangeHandler(e, "holidayName")}
            />
            <div id="holidayNameErr" className="error-div" />
          </div>
        </div>

        <div className="col-xs-12 col-sm-12 col-lg-12 col-xl-12 mt-lg">
          <div
            className="col-xs-6 col-sm-6 col-lg-6 col-md-6"
            style={{ paddingLeft: "0px", paddingRight: "0px" }}
          >
            <DatePickerComponent
              style={{ width: "100%" }}
              allowEdit={false}
              id="holidayDate"
              name="holidayDateErr"
              data-msg-containerid="holidayDateErr"
              format="dd-MMM-yyyy"
              value={state.holidayDate}
              onChange={(e) => inputChangeHandler(e, "holidayDate")}
              TextFieldComponent={TextFieldComponent}
              color="black"
              autoOk
              min={new Date()}
              cssClass="e-outline"
              variant="inline"
              floatLabelType="Auto"
              label="dd-MMM-yyyy"
              placeholder="Date *"
              KeyboardButtonProps={{ "aria-label": "change date" }}
              InputLabelProps={{ shrink: true }}
            />
            <div
              id="holidayDateErr"
              className="e-input-group e-float-icon-left error-div"
            />

          </div>

          <div
            className="col-xs-6 col-sm-6 col-lg-6 col-md-6"
            style={{ paddingRight: "0px" }}
          >
            <ComboBoxComponent
              data-msg-containerid="dayErr"
              id="day"
              value={state.holidayDate && state.holidayDay}
              autocomplete={'off'}
              readonly={true}
              name="dayErr"
              placeholder="Day "
              floatLabelType="Auto"
              cssClass="e-outline"
              htmlAttributes={{ maxlength: 100 }}
            />
          </div>
        </div>

        <div
          className="col-xs-12 col-sm-12 col-lg-12 col-xl-12"
          style={{ marginTop: "0px" }}
        >
          <div
            className="col-xs-12 col-sm-12 col-lg-12 col-md-12"
            style={{ paddingLeft: "0px", paddingRight: "0px" }}
          >
            <ComboBoxComponent
              id="countryName"
              dataSource={allCountry}
              // dataSource={officeCountry}
              fields={{ text: "Name", value: "Id" }}
              value={state.countryId}
              data-msg-containerid="countryErr"
              name="countryErr"
              floatLabelType="Auto"
              cssClass="e-outline"
              placeholder="Country *"
              allowCustom={false}
              onChange={(e) => inputChangeHandler(e, "countryId")}
            />
            <div
              id="countryErr"
              className="e-input-group e-float-icon-left error-div"
            />
          </div>
          {/* <div
            className="col-xs-6 col-sm-6 col-lg-6 col-md-6"
            style={{ paddingRight: "0px" }}
          >
            {(cityList.length > 0 || props.openPopup === 'add') && <ComboBoxComponent
              id="locationCity"
              // dataSource={cityList}
              dataSource={allState.filter(state => +state.optional1 === countryId)}
              fields={{ text: "Name", value: "Id" }}
              value={state.cityId}
              data-msg-containerid="locationCityErr"
              name="locationCityErr"
              floatLabelType="Auto"
              cssClass="e-outline"
              placeholder="Location/City "
              allowCustom={false}
              onChange={(e) => inputChangeHandler(e, "cityId")}
            />}

          </div> */}
        </div>

        <div className="col-xs-12 col-sm-12 col-lg-12 col-xl-12">
          <ComboBoxComponent
            id="selectType"
            dataSource={[
              { Name: "Fixed", Id: "1" },
              { Name: "Optional", Id: "0" },
            ]}
            fields={{ text: "Name", value: "Id" }}
            value={state.holidayType}
            data-msg-containerid="selectTypeErr"
            name="selectTypeErr"
            floatLabelType="Auto"
            cssClass="e-outline"
            placeholder="Select Type *"
            allowCustom={false}
            select={(e) => inputChangeHandler(e, "holidayType")}
          />
          <div
            id="selectTypeErr"
            className="e-input-group e-float-icon-left error-div"
          />
        </div>

        <div className="col-xs-12 col-sm-12 col-lg-12 col-xl-12">
          {/* <DatePickerComponent
            id="resetDate"
            value={state.holidayResetDate}
            data-msg-containerid="resetDateErr"
            name="resetDateErr"
            color="black"
            autoOk
            variant="inline"
            format="dd-MMM-yyyy"
            floatLabelType="Auto"
            cssClass="e-outline"
            inputVariant="outlined"
            placeholder="Reset Date *"
            label="dd-MMM-yyyy"
            min={new Date()}
            KeyboardButtonProps={{ "aria-label": "change date" }}
            InputLabelProps={{ shrink: true }}
            onChange={(e) => inputChangeHandler(e, "holidayResetDate")}
            TextFieldComponent={TextFieldComponent}
          />
          <div
            id="resetDateErr"
            className="e-input-group e-float-icon-left error-div"
          /> */}


          {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              style={{ width: "100%", height: "50px" }}
              id="resetDate"
              name="resetDateErr"
              format="dd-MMM-yyyy"
              value={state.holidayResetDate}
              onChange={(e) => inputChangeHandler(e, "holidayResetDate")}
              TextFieldComponent={TextFieldComponent}
              // minDate={new Date()}
              color="black"
              // disablePast
              autoOk
              variant="inline"
              inputVariant="outlined"
              placeholder="dd-MMM-yyyy"
              label="Reset Date *"
              KeyboardButtonProps={{ "aria-label": "change date" }}
              InputLabelProps={{ shrink: true }}
              min={new Date()}
            />
          </MuiPickersUtilsProvider>
          <div
            id="resetDateErr"
            className="e-input-group e-float-icon-left error-div  "
            style={{ marginTop: "-15px" }}
          /> */}
        </div>

        <div
          className="col-xs-12 col-sm-12 col-lg-12 col-xl-12 mt-lg"
          style={{ marginRight: "2rem", marginTop: "-1.5rem" }}
        >
          <div
            className="col-xs-12 col-sm-12 col-lg-12 col-md-12"
            style={{ paddingLeft: 8 }}
          >
            <div
              style={{
                fontSize: ".95rem",
                color: "#939399",
                fontWeight: 500,
                padding: ".5rem 0",
                marginTop: "-2rem",
              }}
            >
              Attach Policy
            </div>

            <UploadDocuments
              type={"holiday"}
              module={'lna'}
              allowmultiple={false}
              selectedFiles={selectedFiles}
              fileList={[]}
              apiUrl={"lnaApiUrl"}
              documentDetails={documentDetails}
            />
          </div>
          {state.fileName !== null && state.oldFileStatus !== null ? (
            <div style={{ paddingLeft: 8 }}>
              {createImageFilepathForLPView(
                [].concat(state.fileName),
                "document/lna/holiday",
                "lnaApiUrl", removeFile
              )}
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="loader-new1" id="spinnerContainer" >
        {initialLoad && <ProgressBar />}
      </div>
    </>
  );
};

const mapStateToProps = ({ auth, meetingmaster, lnareducer }) => {
  const { authUser } = auth;
  const { currentFinancialYearId } = meetingmaster;
  const { officeCountry, officeLocation, lnaMasterFlag, allCountry, allState, holidayLoader } = lnareducer;

  return {
    authUser,
    currentFinancialYearId,
    officeCountry,
    officeLocation,
    lnaMasterFlag,
    allCountry,
    allState,
    holidayLoader
  };
};
export default connect(mapStateToProps, {
  saveHolidayCluster,
  updateLnAData,
})(HolidayCluster);
