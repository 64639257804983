/**
 * Author: Keval Charla.
 * Created:11-July-2022
 * @description to view all list of holidays for user
 */

 import React, { useState, useEffect, useRef } from 'react';
 import { 
     // DropDownListComponent,
     ComboBoxComponent
 } from '@syncfusion/ej2-react-dropdowns';
 import { connect } from 'react-redux';
 import dataService from '../../services/services';
 
 function CountryDropdownMenu(props) {
     const [countries, setCountries] = useState([]);
 
     const countryDropdown = useRef();
 
     /**
    * Author: Keval Charla  *
    * @param {*} authUser
    * @param {*} type
    * @description fetch country dropdown data and user base country
    */
 
     useEffect(() => {
         async function fetchData() {
             const data = await (dataService.getMasterData(props.authUser, "Country"));
             
             if (data.length > 0) {
                 setCountries(data);
                 if (countryDropdown.current) {
                     countryDropdown.current.value = props.selectedCountry
                 }
             }
         }
         fetchData();
     }, []);
 
     const onChange = (args) => {
         if (args.itemData) {
             props.handleCountryChange(args.itemData.Id);
         }
     }
     
     return (
         <>
             <div>
                 <ComboBoxComponent
                     id="holidayListCountries"
                     fields={{ text: "Country", value: "Id" }}
                     dataSource={countries}                  
                     change={onChange}
                     // select={onChange}
                     ref={countryDropdown}
                     value={countries.length > 0 ? props.selectedCountry : "" /** NOSONAR */}
                     popupHeight="220px"
                 />
             </div>
         </>
     )
 }
 
 const mapStateToProps = ({ auth }) => {
     const { authUser } = auth;
     return {
         authUser,
     };
 };
 export default connect(mapStateToProps, {
 })(CountryDropdownMenu);
 