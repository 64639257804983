import React from "react";
import PropTypes from "prop-types";
import { Button } from "@material-ui/core";
import { CSSProperties } from 'react'
import "./component.css"
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
/**
 * The ButtonComponent component allows the user submit Data.
 *
 * @example
 * <ButtonComponent id="btn" content="Submit"  />
 */
const ButtonComponent = (props) => {
    const { style, cssClass,  id,  content, disabled } = props

    /**
     * Triggers when an item  mouse/tap or with keyboard navigation.
     * @event click
     */

    const onClick = (e) => {
        props.click(e);
    }
    return (
        < >
            {props.type === "primary" ?
                // <Button
                //     name={`${id}Err`}
                //     variant={variant}
                //     onClick={onClick}
                //     style={style}
                //     className={cssClass + " remove-button-shadow-save"}
                //     color="primary"
                //     id={id}
                //     disabled={disabled}
                // >
                //     {content}
                // </Button>
                <button
                className={cssClass + " remove-button-shadow-save"}
                style={style}
                  onClick={onClick}
                disabled={disabled}
                id={id}
                >
				 {content}
                </button>

                
                :
                props.type === "add"?
                <button
                    className={cssClass +" common-add-btn" }
                    style={style}
                    onClick={onClick}
                    id={id}
                    disabled={disabled}
                    >
                    <FontAwesomeIcon icon={faPlus} /> 
                    <span>Add</span>
                </button>
                :
                // <Button
                //     className={cssClass + "cancel-apply-button"}
                //     style={style}
                //     variant={variant}
                //     onClick={onClick}
                //     id={id}
                //     disabled={disabled}
                // >
                //     {content}
                // </Button>
                <button
                    className={cssClass + " leave-cancel-apply-button"}
                    onClick={onClick}
                    style={style}
                    disabled={disabled}
                    id={id}
                >
                    {content}
                </button>

            }
        </>
    );
};

ButtonComponent.propTypes = {
    id: PropTypes.string.isRequired,
    ref: PropTypes.object,
    color: PropTypes.string,
    variant: PropTypes.string,
    content: PropTypes.string.isRequired,
    style: {},
    cssClass: PropTypes.string,

};

ButtonComponent.defaultProps = {
    click: () => { },//NOSONAR
    cssClass: ""
};

export default ButtonComponent;
