import {
  USER_SETTING_CONFIG_SUCCESS,
  GET_USER_SETTING_CONFIG,
  ADDEDITWORKDAYSETTINGS,
  ADD_NOTIFICATION_SETTINGS,
  ADD_UPDATE_NOTIFICATION_SETTINGS,
  SET_MODULE_ATTRIBUTE_SETTING,
} from "../constant/settingconstant";

export const saveSettingConfig = (data) => {
  return {
    type: USER_SETTING_CONFIG_SUCCESS,
    payload: data,
  };
};
export const getDefaltSettingConfig = (data) => {
  return {
    type: GET_USER_SETTING_CONFIG,
    payload: data,
  };
};
export const addEditWorkDaySettings = (data) => {
  return {
    type: ADDEDITWORKDAYSETTINGS,
    payload: data,
  };
};
export const addNotificationSettings = (data) => {
  return {
    type: ADD_NOTIFICATION_SETTINGS,
    payload: data,
  };
};
export const addUpdateNotificationSettings = (data) => {
  return {
    type: ADD_UPDATE_NOTIFICATION_SETTINGS,
    payload: data,
  };
};
export const setModuleAttributeSettings = (data) => {
  return {
    type: SET_MODULE_ATTRIBUTE_SETTING,
    payload: data,
  };
};