/**
 * Author: Mukesh.
 * Created:11-Jan-2023
 * Last Modify:11-Jan-2023
 * Modified By:Mukesh
 */

import * as React from 'react';
import { makeStyles } from "@material-ui/core/styles";
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from "react-redux";
import { setOrhChartDetaila } from '../../redux/actions/entryexit/orgchartaction'
const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
}));
const HeaderFilter = (props) => {
  const classes = useStyles();
  const [filteData, setFileterData] = React.useState(props.filterData)
  const GetDate = (update_date) => {
    try {
      let dateObj = new Date(update_date);
      let MonthArr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
      let mont = (parseInt(dateObj.getMonth()) + 1)
      return MonthArr[mont - 1] + "-" + dateObj.getFullYear();
    } catch (err) {
      console.log(err)
    }
  }
  React.useEffect(() => {
    setFileterData(props.filterData)
  }, [props.filterData])  
  return (
    <>
      {props.hideShow === false && props.orgView && <div className={classes.root}>
        <div className='org-selection-box'>
          <div className='org-second-tabs'>
            {filteData.value != 2 && filteData.teamName && <div className='team-static'>
              <span className='content-static'>Team Member :</span>
              <span className='title-static'>{filteData.teamName}</span>
            </div>}

            {filteData.groupName && <div className='team-static'>
              <span className='content-static'>Business Unit / Group Function :</span>
              <span className='title-static'>{filteData.groupName}</span>
            </div>}

            {filteData.value != 2 && filteData.dateVal && <div className='team-static'>
              <span className='content-static'>Org Chart Month :</span>
              <span className='title-static'>{GetDate(filteData.dateVal)}</span>
            </div>}
            {filteData.value != 2 &&
              <div className='team-static'>
                <span className='content-static'>Upcoming Team Member :</span>
                <span className='title-static'>{(filteData.upComTeam && filteData.upComTeam == 1) ? "Show" : "Hide"}</span>
              </div>
            }
          </div>
          <div className="up-arrow" style={{ cursor: "pointer" }} onClick={props.handleViewChange}>
            <FontAwesomeIcon title="Show" icon={faAngleDown} />
          </div>

        </div>
      </div>
      }
    </>
  )
}
const mapStateToProps = ({ auth, orgchartreducer }) => {
  const { authUser } = auth;
  const { orgchartupdateStatus } = orgchartreducer;
  return { authUser, orgchartupdateStatus };
};
export default connect(mapStateToProps, { setOrhChartDetaila })(
  HeaderFilter
);
