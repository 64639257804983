/**
 * Author: Vivek Khodade.
 * Created:11-May-2020
 * Last Modify:11-May-2020
 * Modified By:Vivek Khodade
 */
import axios from "axios";
import { urlConfig } from "../../config/config";

function errorHandler(reject, error) {
  // eslint-disable-next-line
  console.dir(error);
  reject("operation failed");
}


/**
 * @author Vinayak Jadhav
 * @param {*} empId 
 * @param {*} authUserid 
 * @description get the basic information of selected candidate
 */
function notifyManagerForConfirmationDue(authUserid, data) {
  var empId = data.EmployeeId;
  return new Promise(
      async function onThen(resolve, reject) {
          // eslint-disable-next-line
          await axios.get(urlConfig.tmlcApiUrl + 'notifyManager/' + authUserid + '/' + empId,
              {
                  headers: {
                      'authorization': localStorage.getItem('jwtToken'),
                      'Accept': 'application/json',
                      'Content-Type': 'application/json'
                  }
              }
          ).then(function (response) {
              if (response != null) {                  
                  return resolve(response.data)
              }
          }).catch(function (error) {
              errorHandler(reject, error.response.data.message)
              return null;
          });
      }
  )
}

/**
 * Author:Vivek Khodade
 * @param {*} typeid type id
 * @param {*} authUserid user id
 * get the Employee Confirmation List.
 */
function getEmployeeConfirmationList(typeid, authUserid) {
  return new Promise(function onThen(resolve, reject) {
    axios
      .get(`${urlConfig.tmlcApiUrl}employee/confirm/${typeid}/${authUserid}`, {
        headers: {
          authorization: localStorage.getItem("jwtToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      // eslint-disable-next-line
      .then((response) => {
        if (response != null) {
          return resolve(response.data);
        }
      })
      .catch((error) => {
        errorHandler(reject, error.response.data.message);
        return null;
      });
  });  
}
const confirmationServices = {
  getEmployeeConfirmationList,
  notifyManagerForConfirmationDue
};

export default confirmationServices;
