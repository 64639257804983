/**
 * Author Name :- Prashant Waphare
 * Date : 23-11-2020
 * Description :- This file use for show Team Shared Grid.
 */
import * as React from "react";
import { closest, isNullOrUndefined } from "@syncfusion/ej2-base";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Filter,
  Inject,
  VirtualScroll,
  Sort,
  Page,
  Resize,
} from "@syncfusion/ej2-react-grids";
import "../../app/containers/tmlc/onboarding/onboarding.css";
import "../../app/containers/tmlc/tmlc.css";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import { Redirect } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Dialog } from "@material-ui/core";
import { setMeetingCategory } from '../../redux/actions/meetingcategoryaction';
import { encrypt,encodeString } from "../../app/other/commonfunction";
import { OKR_PRODUCTIVE_ALLOCATION } from '../../redux/constant/meetingcategoryconstant';
import NotificationContainer from "../../components/notification/notifycontainer";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faEye, faTimesCircle } from "@fortawesome/pro-light-svg-icons";
import {
  ACTIONOKR,
  getGridColumn,
  OKRSTATUS,
  DESCRIPTIONOKR,
  FROM,
  TO,
  SHAREDPER,
  SHAREDAMT,
  FUNCTIONNAME,
} from "../../app/other/gridcolumns";
import {
  DialogTitle,
  DialogContent,
  DialogActions,
} from "../customdialog/customdialog";
import {
  appceptRevokeRequest,
  addUpdateObjectveKpi,
} from "../../redux/actions/okr/okractions";

library.add(faEye, faTimesCircle);

class SharedWithMe extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirestCategary: "",
      revokeState: false,
      redirect: null,
      height:
        (window.innerHeight ||
          document.documentElement.clientHeight ||
          document.body.clientHeight) - 290,
      gridHeight:
        (window.innerHeight ||
          document.documentElement.clientHeight ||
          document.body.clientHeight) - 290,
    };
    this.onComplete = this.onComplete.bind(this);
    this.onLoad = this.onLoad.bind(this);
    this.onQueryCellInfoPreJoining = this.onQueryCellInfoPreJoining.bind(this);
    this.onDataBound = this.onDataBound.bind(this);
    this.width =
      (window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth) - 100;
    this.dReady = false;
    this.dtTime = false;
    this.isDataBound = false;
    this.isDataChanged = true;
    this.dropSlectedIndex = null;
    this.check = {
      type: "CheckBox",
    };
    this.select = {
      persistSelection: true,
      type: "Multiple",
      checkboxOnly: true,
    };
    this.Filter = {
      type: "Menu",
    };
    this.status = {
      type: "CheckBox",
      // itemTemplate: empTemplate
    };
    this.pageOptions = {
      pageSize:
        (window.innerHeight ||
          document.documentElement.clientHeight ||
          document.body.clientHeight) > 700
          ? 15
          : 10,
      pageCount: 5,
    };
    this.name = {
      type: "CheckBox",
    };
  }

  /**
   * Calculate & Update state of new dimensions
   */
  updateDimensions() {
    const updateHeight = window.innerHeight - 145;
    const gridHeight = window.innerHeight - 320;
    this.setState({
      //   width: updateWidth,
      height: updateHeight,
      gridHeight,
    });
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
    setTimeout(() => {
      const ele = document.getElementsByClassName("e-headercelldiv");
      for(let i in ele) {
        if(!isNaN(parseInt(i, 10))){
          let index = parseInt(i, 10);
          ele[index].classList.add("grid-left-header");
        }
      }
    }, 1000);
  }

  onQueryCellInfoPreJoining = (args) => {
    const { cell } = args;
    cell.setAttribute(
      "style",
      "font-size:14px !important; text-align: left !important; padding-left:19px !important"
    );
  };

  onDataBound() {
    clearTimeout(this.clrIntervalFun);
    clearInterval(this.intervalFun);
    this.dtTime = true;
  }

  onComplete = (args) => {
    if (args.requestType === "filterchoicerequest") {
      if (
        args.filterModel.options.field === "Trustworthiness" ||
        args.filterModel.options.field === "Rating" ||
        args.filterModel.options.field === "Status"
      ) {
        const span = args.filterModel.dialogObj.element.querySelectorAll(
          ".e-selectall"
        )[0];
        if (!isNullOrUndefined(span)) {
          closest(span, ".e-ftrchk").classList.add("e-hide");
        }
      }
    }
  };

  onChange() {
    this.ddObj.hidePopup();
    this.gridInstance.showSpinner();
    this.dropSlectedIndex = null;
    clearTimeout(this.clrIntervalFun2);
    this.clrIntervalFun2 = setTimeout(() => {
      this.isDataChanged = true;
      this.stTime = null;
      const contentElement = this.gridInstance.contentModule.getPanel()
        .firstChild;
      contentElement.scrollLeft = 0;
      contentElement.scrollTop = 0;
      this.gridInstance.pageSettings.currentPage = 1;
      this.stTime = performance.now();
      this.gridInstance.hideSpinner();
    }, 100);
  }

  onLoad = () => {
    document
      .getElementById("overviewgrid")
      .ej2_instances[0].on("data-ready", () => {
        this.dReady = true;
        this.stTime = performance.now();
      });
    document
      .getElementById("overviewgrid")
      .addEventListener("DOMSubtreeModified", () => {
        if (this.dReady && this.stTime && this.isDataChanged) {
          this.stTime = null;
          this.dReady = false;
          this.dtTime = false;
          this.isDataChanged = false;
        }
      });
  };

  created = () => {
    setTimeout(() => {
      if (document.getElementById("right-pane")) {
        document
          .getElementById("right-pane")
          .addEventListener("click", this.onClick.bind(this));
        document
          .getElementById("right-pane")
          .addEventListener("scroll", this.onScroll.bind(this));
      }
    }, 1000);
  };

  deacriptionTemp = (props) => {
    return (
      <div>
        <TooltipComponent
          content={props.description.replace(/(?:\r\n|\r|\n)/g, "<br>")}
          position="BottomCenter"
          created={this.created}
          isSticky={false} // if use false tooltip auto cancel
          // isSticky={true} // if use true tooltip we naualt need to cancel it.
          className="tooltip-box"
          cssClass="customtooltip"
        >
          <span
            style={{
              fontSize: "14px",
              display: "block",
              width: "200px",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {props.description}
          </span>
        </TooltipComponent>
      </div>
    );
  };

  statusTemp = (props) => {
    const { enumAcceptanceStatus } = props;
    if (
      enumAcceptanceStatus === "0" ||
      enumAcceptanceStatus === null ||
      enumAcceptanceStatus === ""
    ) {
      return (
        <div>
          <span>Pending</span>
        </div>
      );
    }
    if (enumAcceptanceStatus === "1") {
      return (
        <div>
          <span style={{ color: "green" }}>Shared</span>
        </div>
      );
    }
    if (enumAcceptanceStatus === "2") {
      return (
        <div>
          <span style={{ color: "red" }}>Revoke</span>
        </div>
      );
    }
    if (enumAcceptanceStatus === "3") {
      return (
        <div>
          <span style={{ color: "red" }}>Reject</span>
        </div>
      );
    }
  };

  fromDateTemp = (props) => {
    const { startDate } = props;
    return (
      <div className="date-format-all-grid">
        <span>{startDate}</span>
      </div>
    );
  };

  toDateTemp = (props) => {
    const { endDate } = props;
    return (
      <div className="date-format-all-grid">
        <span>{endDate}</span>
      </div>
    );
  };

  sharePerTemp = (props) => {
    const { percSalaryShared } = props;
    return (
      <div className="date-format-all-grid" style={{ textAlign: "center" }}>
        <span>{`${percSalaryShared} %`}</span>
      </div>
    );
  };

  sharedAmountTemp = (props) => {
    const { amountShared } = props;
    return (
      <div className="date-format-all-grid" style={{ textAlign: "center" }}>
        <span>{`${amountShared}`}</span>
      </div>
    );
  };

  shareWithDeprtment = (props) => {
    const { sharedWith, pnlHead } = props;
    return (
      <div className="date-format-all-grid">
        <span>{`${
          sharedWith !== "" && sharedWith !== null ? sharedWith : pnlHead
        }`}</span>
      </div>
    );
  };

  dataBound = () => {
    if (this.treegrid) {
      this.treegrid.autoFitColumns(["taskName"]);
    }
  };

  actionTemplate = (props) => {
    const { sharedWithId } = props;
    if (sharedWithId === 0 || sharedWithId === null || sharedWithId === "") {
      return (
        <div className="date-format-all-grid" style={{ color: "red" }}>
          <span> Not done </span>
        </div>
      );
    }
    return (
      <div className="row" style={{ paddingLeft: "15px" }}>
        <div className="col-4">
          <TooltipComponent
            position="BottomCenter"
            className="pointer-cursor"
            isSticky={false}
            content="View OKR"
            cssClass="customtooltip"
          >
            <FontAwesomeIcon
              icon={faEye}
              className="pointer-cursor"
              style={{ fontSize: "20px" }}
              onClick={() => this.navigateToProductiveAllocationScreens(props)}
            />
          </TooltipComponent>
        </div>
        <div className="col-4">
          <TooltipComponent
            closeOn="Click"
            content="Revoke"
            position="BottomCenter"
            isSticky={false}
            cssClass="customtooltip"
            className="pointer-cursor"
          >
            <FontAwesomeIcon
              icon={faTimesCircle}
              style={{ fontSize: "20px" }}
              onClick={() => this.revokeAction(props, "2")}
            />
          </TooltipComponent>
        </div>
      </div>
    );
  };

  navigateToProductiveAllocationScreens = (args) => {
    try {
      localStorage.setItem(
        "last_meeting_category",
        localStorage.getItem("meeting_category")
      );
      // eslint-disable-next-line
            this.props.addUpdateObjectveKpi({
        okrreloadStatus: "updated",
        employeeId: args.userId,
      });
      localStorage.setItem(
        "meeting_category",
        encodeString(OKR_PRODUCTIVE_ALLOCATION)
      );
      const path = `/okr?empId=${encrypt(args.userId)}`;
      this.setState({
        redirect: path,
        redirestCategary: OKR_PRODUCTIVE_ALLOCATION,
      });
    } catch (error) {
      // eslint-disable-next-line
            console.log("error", error);
    }
  };

  revokeAction = (props, type) => {
    const { costSharingId } = props;
    this.costSharingId = costSharingId;
    this.acceptanceType = type;
    this.setState({ revokeState: true });
  };

  revokeOKRRequest = () => {
    const { authUser } = this.props;
    try {
      const approveData = {
        userId: authUser,
        sharingId: this.costSharingId,
        acceptanceStatus: this.acceptanceType,
      };
      // eslint-disable-next-line
            this.props.appceptRevokeRequest(approveData);
    } catch (error) {
      // eslint-disable-next-line
            console.log(error);
    }
    this.setState({ revokeState: false });
  };

  closeRevoke = () => {
    this.setState({ revokeState: false });
  };

  render() {
    const { sharedWithMe } = this.props;
    const { result } = sharedWithMe;
    const {
      revokeState,
      redirestCategary,
      redirect,
      height,
      gridHeight,
    } = this.state;
    if (redirect !== null) {
      // eslint-disable-next-line
            this.props.setMeetingCategory(redirestCategary);
      return <Redirect to={redirect} />;
    }
    let widthColumn = 0;
    let finalWidth = 0;
    // eslint-disable-next-line
    getGridColumn("teamshared").forEach((data) => {
      widthColumn += data.width;
    });
    if (widthColumn <= this.width) {
      finalWidth = widthColumn + 120; // 40
    } else {
      finalWidth = this.width;
    }
    return (
      <div
        className="row"
        style={{
          height,
          overflowY: "hidden",
          overflowX: "hidden",
        }}
      >
        <NotificationContainer />
        <div>
          <div className="row-xs-12 row-sm-12 row-lg-12 row-md-12">
            <div
              style={{
                padding: "1.20rem 1.20rem",
              }}
            >
              <GridComponent
                className="grid-head-custome"
                id="overviewgrid"
                allowPaging
                dataSource={result}
                pageSettings={this.pageOptions}
                enableHover={false}
                enableVirtualization={false}
                rowHeight={38}
                height={gridHeight}
                ref={(g) => {
                  this.gridInstance = g;
                }}
                actionComplete={this.onComplete}
                load={this.onLoad}
                queryCellInfo={this.onQueryCellInfoPreJoining}
                dataBound={this.onDataBound}
                filterSettings={this.Filter}
                allowFiltering
                allowSorting
                allowSelection
                selectionSettings={this.select}
                allowTextWrap
                width={`${finalWidth}`}
              >
                <ColumnsDirective>
                  {getGridColumn("sharedWithMe").map((item) => {
                    switch (item.colName) {
                      case ACTIONOKR:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.actionTemplate}
                            filter={this.check}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                          />
                        );
                      case OKRSTATUS:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.statusTemp}
                            filter={this.check}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                          />
                        );
                      case DESCRIPTIONOKR:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.deacriptionTemp}
                            filter={this.check}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                          />
                        );
                      case FROM:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.fromDateTemp}
                            filter={this.check}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                          />
                        );
                      case TO:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.toDateTemp}
                            filter={this.check}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                          />
                        );
                      case SHAREDPER:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.sharePerTemp}
                            filter={this.check}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                          />
                        );
                      case SHAREDAMT:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.sharedAmountTemp}
                            filter={this.check}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                          />
                        );
                      case FUNCTIONNAME:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.shareWithDeprtment}
                            filter={this.check}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                          />
                        );
                      default:
                        return (
                          <ColumnDirective
                            key={item.field}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                            filter={this.check}
                          />
                        );
                    }
                  })}
                </ColumnsDirective>
                <Inject
                  services={[Page, Filter, VirtualScroll, Sort, Resize]}
                />
              </GridComponent>
            </div>
          </div>
          <div style={{ width: "350px" }}>
            <Dialog open={revokeState}>
              <DialogTitle> Revoke Request</DialogTitle>
              <DialogContent>
                Are you sure you want to revoke the request?
              </DialogContent>
              <DialogActions>
                <Button onClick={this.revokeOKRRequest}> Yes </Button>
                <Button onClick={this.closeRevoke}> No </Button>
              </DialogActions>
            </Dialog>
          </div>
        </div>
      </div>
    );
  }
}
SharedWithMe.propTypes = {
  authUser: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  sharedWithMe: PropTypes.arrayOf(PropTypes.object),
  result: PropTypes.arrayOf(PropTypes.object),
};
SharedWithMe.defaultProps = {
  authUser: "",
  sharedWithMe: {},
  result: {},
};
const mapStateToProps = ({ auth }) => {
  const { authUser } = auth;
  return { authUser };
};
export default connect(mapStateToProps, {
  appceptRevokeRequest,
  addUpdateObjectveKpi,
  setMeetingCategory,
})(SharedWithMe);
