import React from 'react'
import AppChangeHeader from '../appchangepages/appchangeheader'
import AppChangeBody from '../appchangepages/appchangepagebody'

const InvalidUrl = () => {
  return (
    <div style = {{ height: 'calc(100vh - 96px)' }}>
      <AppChangeHeader />
      <AppChangeBody imgType='invalidURL'
        heading='Invalid URL.'
      />
      <div style={{ display: 'flex', height: '36px', alignItems: 'center', backgroundColor: '#4B4B55' }}>
        <div style={{ marginLeft: '51px', color: '#FFFFFF', fontSize: '12px' }}>© {new Date().getFullYear()} Cheers Interactive. All rights reserved.</div>
      </div>
    </div>
  )
}

export default InvalidUrl