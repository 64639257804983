// Author by:- Prashant Waphare | 31-08-2020 | This component use for add internet connectivity details.

import * as React from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import { Button } from "@material-ui/core";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { urlConfig } from "../../config/config";
import { getNotify } from "../../app/other/commonfunction";
import { SUCCESS } from "../../assets/constants/constants";
import { saveHomeBasicInfoRedux } from "../../redux/actions/homeoffice/homeofficeaction";
import { RadioButtonComponent, CheckBoxComponent } from "@syncfusion/ej2-react-buttons";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
library.add(faDownload);

class InternetConnectivity extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      chengeData: false,
      clientBrodBandList: [{ Id: 1, Name: "< 500 Mbps" },
      { Id: 2, Name: " >= 500 Mbps" }],
      nonClientBrodBandList: [{ Id: 3, Name: "< 200 Mbps" },
      { Id: 4, Name: ">= 200 Mbps" }],
      internetConnectivityst: 0
    };
    this.fileInputRef = React.createRef();
    this.fileInputRef2 = React.createRef();
    this.uploadInternetSpeedTestFile = this.uploadInternetSpeedTestFile.bind(this);
  }
  componentDidMount() { //NOSONAR
    const { homeOfficeList } = this.props;
    if (homeOfficeList !== undefined && homeOfficeList.length > 0) {
      const { internetType, primaryBroadbandPlan, primaryBroadbandSpeed, secondaryBrodbandPlan, secondaryBrodbandSpeed, internetFlag, primBrodbandSpeedTestScreen, secondaryBrodbandSpeedTestScreen } = homeOfficeList[0];
      let intType = "";
      if(internetType === '1'){
        intType = 1;
      }else if(internetType === '2'){
        intType = 2;
      }else{
        intType = false;
      }
      this.props.saveHomeBasicInfoRedux({
        planProvider1: primaryBroadbandPlan,
        brodbandSpeed1: primaryBroadbandSpeed,
        planProvider2: secondaryBrodbandPlan,
        brodbandSpeed2: secondaryBrodbandSpeed,
        internetConnectivity: intType, // internetType === '1' ? 1 : internetType === '2' ? 2 : false,
        internetConnectivityState: intType, // internetType === '1' ? 1 : internetType === '2' ? 2 : false,
        internetFlag: internetFlag === '1' ? true : false,
        filepath1: primBrodbandSpeedTestScreen,
        filepath2: secondaryBrodbandSpeedTestScreen
      })
      this.setState({ internetConnectivityst: intType
        //  internetType === '1' ? 1 : internetType === '2' ? 2 : false 
        });
    }
  }
  openFileDialog = () => {
    this.fileInputRef.current.click();
  };

  openFileDialog2 = () => {
    this.fileInputRef2.current.click();
  };

  uploadInternetSpeedTestFile = (evt, type) => {
    const { authUser } = this.props;
    try {
      if (typeof evt.target.files !== 'undefined') {
        if (evt.target.files.length > 0 && evt.target.files[0] !== undefined) {
          const currentDate = Date.parse(new Date()) / 1000;
          const fileObj = evt.target.files[0];
          const fileNm = `${currentDate.toString()}_${fileObj.name}`;
          if (type === "file1") {
            // eslint-disable-next-line
            this.props.saveHomeBasicInfoRedux({ filepath1: fileNm });
          } else {
            // eslint-disable-next-line
            this.props.saveHomeBasicInfoRedux({ filepath2: fileNm });
          }
          try {
            const req = new XMLHttpRequest();
            const formData = new FormData();
            formData.append("file", fileObj, fileNm);
            req.open(
              "POST",
              `${urlConfig.apiurl}uploadinternetspeedtestdoctm/${authUser}`
            );
            req.setRequestHeader("Authorization", localStorage.getItem("jwtToken"));
            req.send(formData);
            getNotify(SUCCESS, "Attachment upload successfully");
          } catch (error) {
            console.log('error', error);
          }
        }
      }


    } catch (e) {
      console.log(e);
    }
  };

  addInternetInformation = (e, type) => {
    switch (type) {
      case "planProvider1":
        // eslint-disable-next-line
        this.props.saveHomeBasicInfoRedux({ planProvider1: e.value });
        break;
      case "brodbandSpeed1":
        // eslint-disable-next-line
        this.props.saveHomeBasicInfoRedux({ brodbandSpeed1: e.itemData.Name });
        break;
      case "planProvider2":
        // eslint-disable-next-line
        this.props.saveHomeBasicInfoRedux({ planProvider2: e.value });
        break;
      case "brodbandSpeed2":
        // eslint-disable-next-line
        this.props.saveHomeBasicInfoRedux({ brodbandSpeed2: e.itemData.Name });
        break;
      default:
    }
  };
  internetType = (e) => {
    // eslint-disable-next-line
    this.props.saveHomeBasicInfoRedux({ internetConnectivity: e.value, brodbandSpeed1: "", brodbandSpeed2: "", filepath1: "", filepath2: "", planProvider1: "", planProvider2: "", internetFlag: false });
    this.setState({ internetConnectivityst: e.value });
    document.getElementById("internetConnectivityErr").innerHTML = "";
  };
  render() { // NOSONAR
    const {
      planProvider1,
      brodbandSpeed1,
      planProvider2,
      brodbandSpeed2,
      // internetConnectivityState,
      internetFlag,
      filepath1,
      filepath2,
      internetConnectivity
    } = this.props;
    const { clientBrodBandList, nonClientBrodBandList, internetConnectivityst } = this.state;
    return (
      <div>
        <div>INTERNET CONNECTIVITY</div>
        <form
          id="internetConnectivity"
          method="post"
          className="control-section col-md-12"
        >
          <div
            className="col-md-12"
            style={{ marginBottom: "-6px" }}
          >
            <div className="col-xs-2 col-sm-2 col-lg-3 col-md-2">
              <div>
                <RadioButtonComponent
                  style={{ color: "#fff" }}
                  checked={internetConnectivity === 1 ? true : false}
                  change={this.internetType}
                  label="My role is client facing"
                  name="internet"
                  value={1}
                />
              </div>
            </div>
            <div className="col-xs-2 col-sm-2 col-lg-3 col-md-2">
              <div>
                <RadioButtonComponent
                  style={{ color: "#fff" }}
                  checked={internetConnectivity === 2 ? true : false}
                  change={this.internetType}
                  label="My role is non-client facing"
                  name="internet"
                  value={2}
                />
              </div>
            </div>
            <div
              style={{ padding: "5px 0px 5px 17px" }}
              id="internetConnectivityErr"
              className="e-input-group e-float-icon-left error-div"
            />
          </div>
          <>{
            internetConnectivityst > 0 &&
            <>
              <div
                className="col-md-12"
                style={{ height: "40px", fontWeight: "bold" }}
              >
                <div className="col-xs-12 col-sm-12 col-lg-12">
                  PRIMARY CONNECTION
              <hr style={{ marginTop: "5px", marginBottom: "5px" }} />
                </div>
              </div>
              <div className="col-md-12">
                <div className="col-xs-12 col-sm-6 col-lg-3 col-md-3">
                  <div>
                    <TextBoxComponent
                      id="brodbandProvider"
                      fields={{ text: "Name", value: "Id" }}
                      value={planProvider1}
                      data-msg-containerid="brodbandProviderErr"
                      name="brodbandProviderErr"
                      allowCustom={false}
                      floatLabelType="Auto"
                      cssClass="e-outline"
                      placeholder="Broadband Provider / Plan *"
                      change={(e) =>
                        this.addInternetInformation(e, "planProvider1")
                      }
                    />
                    <div
                      id="brodbandProviderErr"
                      className="e-input-group e-float-icon-left error-div"
                    />
                  </div>
                </div>
                <div className="col-xs-12 col-sm-6 col-lg-3 col-md-3">
                  <div>
                    {
                      internetConnectivityst !== 0 &&
                      <DropDownListComponent
                        id="brodbandSubscription"
                        fields={{ text: "Name", value: "Id" }}
                        data-msg-containerid="brodbandSubscriptionErr"
                        name="brodbandSubscriptionErr"
                        dataSource={internetConnectivityst === 1 ? clientBrodBandList : internetConnectivityst === 2 ? nonClientBrodBandList : ""} // NOSONAR
                        allowCustom={false}
                        text={brodbandSpeed1}
                        cssClass="e-outline"
                        floatLabelType="Auto"
                        placeholder="Broadband Subscription Speed (Mbps) *"
                        select={(e) =>
                          this.addInternetInformation(e, "brodbandSpeed1")
                        }
                      />
                    }
                    <div
                      id="brodbandSubscriptionErr"
                      className="e-input-group e-float-icon-left error-div"
                    />
                  </div>
                </div>
                <div className="col-xs-12 col-sm-12 col-lg-3 col-md-6" style={{ padding: '0px' }}>
                  <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4"
                  // style={{ padding: filepath1 === "" || filepath1 === null ? "0px 0px 0px 16px" : "0px 0px 0px 32px" }}
                  >
                    <div
                      onClick={this.openFileDialog}
                      onKeyDown={this.openFileDialog}
                      role="button"
                    >
                      <input
                        ref={this.fileInputRef}
                        className="FileInput"
                        style={{ display: "none" }}
                        type="file"
                        accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"
                        onChange={(e) => this.uploadInternetSpeedTestFile(e, "file1")}
                      />
                      {/* <TooltipComponent
                        id="uploadAgenda"
                        content="Upload screenshot of your internet subscription app, or scanned copy of your internet subscription order"
                        // content="Click to download and view previous document"
                        cssClass="customtooltip"
                        position="BottomCenter"
                        isSticky={false}
                        showTipPointer={false}
                      > */}
                      <Button
                        style={{
                          background: "#4b4b55",
                          color: "white",
                          height: "40px",
                          borderRadius: "5px",
                        }}
                        title="Upload screenshot of your internet subscription app, or scanned copy of your internet subscription order"
                        onClick={(e) => this.uploadInternetSpeedTestFile(e, "file1")}
                      >
                        Upload
                  </Button>
                      {/* </TooltipComponent> */}
                    </div>
                  </div>
                  {
                    filepath1 !== "" && filepath1 !== null &&
                    <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4 button-down-uplod"
                    // style={{ paddingRight: "96px" }}
                    >
                      {/* <TooltipComponent
                        // content="Download screenshot of your internet subscription app, or scanned copy of your internet subscription order"
                        content='Click to download and view previous document'
                        cssClass="customtooltip"
                        position="BottomCenter"
                        isSticky={false}
                        showTipPointer={false}
                      > */}
                      <a style={{ color: "white", textDecoration: 'none' }} href={`${urlConfig.apiurl}getHomeOfficeDocumenttm/${filepath1}`} download>
                        <Button
                          style={{
                            background: "#4b4b55",
                            color: "white",
                            height: "40px",
                            borderRadius: "5px",
                            width: 80
                          }}
                          title="Click to download and view previous document"
                          disabled={filepath1 !== "" && filepath1 !== null ? false : true}
                        >
                          <span style={{ fontSize: 14, fontWeight: '100' }}>
                            <FontAwesomeIcon
                              className="fas fa-download"
                              icon={faDownload}
                              style={{ color: "white", fontSize: 14 }}
                            />
                             &nbsp;View
                          </span>
                        </Button>
                      </a>
                      {/* </TooltipComponent> */}
                    </div>
                  }
                </div>
                <div className="col-xs-4 col-sm-4 col-lg-3 col-md-4 home-office-upload-note" style={{ padding: "0px" }}>
                </div>
              </div>
              {
                brodbandSpeed1 === '< 500 Mbps' &&
                <div className="col-xs-12 col-sm-12 col-lg-12" style={{ height: "40px", fontWeight: "bold", paddingLeft: "15px", marginTop: '-10px' }}>
                  <CheckBoxComponent
                    change={(e) => {
                      this.props.saveHomeBasicInfoRedux({ internetFlag: e.checked });
                    }}
                    checked={internetFlag}
                    label="Currently no supplier provides a 500Mbps internet connection at my address. I shall subscribe for the same as soon as it becomes available. Please approve my request with a 200 Mbps connection for now."
                  />
                </div>
              }
              <div
                className="col-md-12"
                style={{ height: "40px", fontWeight: "bold", "marginLeft": "15px" }}
              >
                <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                  SECONDARY CONNECTION (OPTIONAL)
              <hr style={{ marginTop: "5px", marginBottom: "5px" }} />
                </div>
              </div>
              <div className="col-md-12">
                <div className="col-xs-12 col-sm-6 col-lg-3 col-md-3">
                  <div>
                    <TextBoxComponent
                      id="brodbandProvider1"
                      fields={{ text: "Name", value: "Id" }}
                      data-msg-containerid="brodbandProviderErr1"
                      value={planProvider2}
                      name="brodbandProviderErr1"
                      allowCustom={false}
                      floatLabelType="Auto"
                      cssClass="e-outline"
                      placeholder="Broadband Provider / Plan"
                      change={(e) =>
                        this.addInternetInformation(e, "planProvider2")
                      }
                    />
                    <div
                      id="brodbandProviderErr1"
                      className="e-input-group e-float-icon-left error-div"
                    />
                  </div>
                </div>
                <div className="col-xs-12 col-sm-6 col-lg-3 col-md-3">
                  <div>
                    {
                      internetConnectivityst !== 0 &&
                      <DropDownListComponent
                        id="brodbandSubscription1"
                        fields={{ text: "Name", value: "Id" }}
                        data-msg-containerid="brodbandSubscriptionErr1"
                        name="brodbandSubscriptionErr1"
                        allowCustom={false}
                        text={brodbandSpeed2}
                        cssClass="e-outline"
                        floatLabelType="Auto"
                        dataSource={internetConnectivityst === 1 ? clientBrodBandList : internetConnectivityst === 2 ? nonClientBrodBandList : ""} // NOSONAR
                        placeholder="Broadband Subscription Speed (Mbps)"
                        select={(e) =>
                          this.addInternetInformation(e, "brodbandSpeed2")
                        }
                      />
                    }
                    <div
                      id="brodbandSubscriptionErr1"
                      className="e-input-group e-float-icon-left error-div"
                    />
                  </div>
                </div>
                <div className="col-xs-12 col-sm-6 col-lg-3 col-md-3" style={{ padding: "0px" }}>
                  <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4"
                  // style={{padding:filepath2 === "" || filepath2 === null ? "0px 0px 0px 16px" : "0px 0px 0px 32px"}}
                  >
                    <div>
                      <div
                        onClick={this.openFileDialog2}
                        onKeyDown={this.openFileDialog2}
                        role="button"
                      >
                        <input
                          ref={this.fileInputRef2}
                          className="FileInput"
                          style={{ display: "none" }}
                          type="file"
                          accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"
                          onChange={(e) =>
                            this.uploadInternetSpeedTestFile(e, "file2")
                          }
                        />
                        {/* <TooltipComponent
                          id="uploadAgenda"
                          content='Upload screenshot of your internet subscription app, or scanned copy of your internet subscription order'
                          // content='Click to download and view previous document'
                          cssClass="customtooltip"
                          position="BottomCenter"
                          isSticky={false}
                          showTipPointer={false}
                        > */}
                        <Button
                          style={{
                            background: "#4b4b55",
                            color: "white",
                            height: "40px",
                            borderRadius: "5px",
                          }}
                          title="Upload screenshot of your internet subscription app, or scanned copy of your internet subscription order"
                          onClick={(e) => this.uploadInternetSpeedTestFile(e, "file2")}
                        >
                          Upload
                    </Button>
                        {/* </TooltipComponent> */}
                      </div>
                    </div>
                  </div>
                  {
                    filepath2 !== "" && filepath2 !== null &&
                    <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4 button-down-uplod"
                    // style={{paddingRight:"96px"}}
                    >
                      {/* <TooltipComponent
                        // content="Download screenshot of your internet subscription app, or scanned copy of your internet subscription order"
                        content='Click to download and view previous document'
                        cssClass="customtooltip"
                        position="BottomCenter"
                        isSticky={false}
                        showTipPointer={false}
                      > */}
                      <a style={{ color: "white", textDecoration: 'none' }} href={`${urlConfig.apiurl}getHomeOfficeDocumenttm/${filepath2}`} download
                        title="Click to download and view previous document"
                      >
                        <Button
                          style={{
                            background: "#4b4b55",
                            color: "white",
                            height: "40px",
                            borderRadius: "5px",
                            width: 80
                          }}

                          disabled={filepath2 !== "" && filepath2 !== null ? false : true}
                        >
                          <span style={{ fontSize: 14, fontWeight: '100' }}>
                            <FontAwesomeIcon
                              className="fas fa-download"
                              icon={faDownload}
                              style={{ color: "white", fontSize: 14 }}
                            />
                             &nbsp;View
                          </span>
                        </Button>
                      </a>
                      {/* </TooltipComponent> */}
                    </div>
                  }
                </div>
                <div className="col-xs-4 col-sm-4 col-lg-3 col-md-4 home-office-upload-note" style={{ padding: "0px" }}>
                </div>
              </div>
            </>
          }
          </>
        </form>
      </div>
    );
  }
}
InternetConnectivity.propTypes = {
  // eslint-disable-next-line
  authUser: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  planProvider1: PropTypes.string,
  brodbandSpeed1: PropTypes.string,
  planProvider2: PropTypes.string,
  brodbandSpeed2: PropTypes.string,
  internetConnectivityState: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool
  ]),
  internetFlag: PropTypes.bool,
  internetConnectivity: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
};
InternetConnectivity.defaultProps = {
  authUser: "",
  planProvider1: "",
  brodbandSpeed1: "",
  planProvider2: "",
  brodbandSpeed2: "",
  internetConnectivityState: false,
  internetFlag: false,
  internetConnectivity: 0
};
const mapStateToProps = ({ auth, homeoffice }) => {
  const { authUser } = auth;
  const {
    planProvider1,
    brodbandSpeed1,
    planProvider2,
    brodbandSpeed2,
    filepath1,
    filepath2,
    internetConnectivityState,
    internetFlag,
    internetConnectivity
  } = homeoffice;
  return {
    authUser,
    planProvider1,
    brodbandSpeed1,
    planProvider2,
    brodbandSpeed2,
    filepath1,
    filepath2,
    internetConnectivityState,
    internetFlag,
    internetConnectivity
  };
};
export default connect(mapStateToProps, {
  saveHomeBasicInfoRedux,
})(InternetConnectivity);
