/**
 * Author Name :- Prashant Waphare
 * Date : 21-04-2020
 * Description :- This file use for show Revoed  candidate list
 * Modified By :- Chandrashekhar Bhagat
 * Date : 18-12-2023
 */
import * as React from "react";
import queryString from "query-string";
import { closest, isNullOrUndefined } from "@syncfusion/ej2-base";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Filter,
  Inject,
  VirtualScroll,
  Sort,
  Page,
  Resize,
  ExcelExport,
  Toolbar
} from "@syncfusion/ej2-react-grids";
import {
  ButtonComponent,
  CheckBoxComponent,
} from "@syncfusion/ej2-react-buttons";
import "../../../app/containers/tmlc/onboarding/onboarding.css";
import "../../../app/containers/tmlc/tmlc.css";

import { connect } from "react-redux";
import { Button, Dialog } from "@material-ui/core";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import { Redirect } from "react-router-dom";
import { initiateAllcandidate } from "../../../redux/actions/entryexit/onboardingaction";
import {
  DialogContent,
  DialogActions,
  DialogTitle,
} from "../../customdialog/customdialog";
import {
  getNotify,
  getDisplayDateOnly,
  getFormatDateForGrid,
  encrypt,
  decodeString,
  encodeString
} from "../../../app/other/commonfunction";
import { WARNING } from "../../../assets/constants/constants";
import { setMeetingCategory } from '../../../redux/actions/meetingcategoryaction';
import DateFnsUtils from "@date-io/date-fns";

import Progressbar from "../../progressbar/progressbar";
import ErrorMessage from "../../errormessage/errormessage";
import EmployeeNameTemplate from "../../tobeoffercondicate/employeenametemplate";
import { PRE_OFFERE } from "../../../redux/constant/meetingcategoryconstant";
import { DateFilterformat } from '../../../config/config';
import { STAGE, REVOKE_COMMENTS, getGridColumn, CANDIDATE_NAME, CANDIDATE_STATUS, ADMIN, IT, PEOPLE_TEAM, FINANCE, SOFTWARE, DATE_OF_JOINING, BAND, REVOKE_DATE } from "../../../app/other/gridcolumns";

function adminTemplate(props) {
  const { AdminCheckListStatus, UpdatedAdminChecklist, AdminChecklist } = props;

  let borderColor;
  switch (AdminCheckListStatus) {
    case "Completed":
      borderColor = "green";
      break;
    case "Partial":
      borderColor = "#f68104";
      break;
    default:
      borderColor = "gray";
      break;
  }

  return (
    <div style={{ borderLeft: `3px solid ${borderColor}`, paddingLeft: "5px" }}>
      <span>{`${UpdatedAdminChecklist}/${AdminChecklist}`}</span>
      <br />
      <span>{AdminCheckListStatus}</span>
    </div>
  );
}

function itTemplate(props) {
  const { ITCheckListStatus, UpdatedITChecklist, ITChecklist } = props;
  let borderColor = "";
  switch (ITCheckListStatus) {
    case "Completed":
      borderColor = "green";
      break;
    case "Pending":
      borderColor = "gray";
      break;
    case "Partial":
      borderColor = "#f68104";
      break;
    default:
      return <></>;
  }

  return (
    <div style={{ borderLeft: `3px solid ${borderColor}`, paddingLeft: "5px" }}>
      <span id="">{`${UpdatedITChecklist}/${ITChecklist}`}</span>
      <br />
      <span id="">{ITCheckListStatus}</span>
    </div>
  );
}
function peopleTeamTemplate(props) {
  const {
    PeopleCheckListStatus,
    UpdatedPeopleChecklist,
    PeopleChecklist,
  } = props;

  let borderColor;
  switch (PeopleCheckListStatus) {
    case "Completed":
      borderColor = "green";
      break;
    case "Pending":
      borderColor = "gray";
      break;
    case "Partial":
      borderColor = "#f68104";
      break;
    default:
      return null;
  }

  return (
    <div style={{ borderLeft: `3px solid ${borderColor}`, paddingLeft: "5px" }}>
      <span>{`${UpdatedPeopleChecklist}/${PeopleChecklist}`}</span>
      <br />
      <span>{PeopleCheckListStatus}</span>
    </div>
  );
}
function financeTemplate(props) {
  const {
    FinanceCheckListStatus,
    UpdatedFinanceChecklist,
    FinanceChecklist,
  } = props;

  let borderColor;
  switch (FinanceCheckListStatus) {
    case "Completed":
      borderColor = "green";
      break;
    case "Pending":
      borderColor = "gray";
      break;
    case "Partial":
      borderColor = "#f68104";
      break;
    default:
      return null;
  }

  return (
    <div style={{ borderLeft: `3px solid ${borderColor}`, paddingLeft: "5px" }}>
      <span>{`${UpdatedFinanceChecklist}/${FinanceChecklist}`}</span>
      <br />
      <span>{FinanceCheckListStatus}</span>
    </div>
  );
}

function softwareTemplate(props) {
  const {
    SoftwareCheckListStatus,
    UpdatedSoftwareChecklist,
    SoftwareChecklist,
  } = props;
  let borderColor;
  switch (SoftwareCheckListStatus) {
    case "Completed":
      borderColor = "green";
      break;
    case "Pending":
      borderColor = "gray";
      break;
    case "Partial":
      borderColor = "#f68104";
      break;
    default:
      return null;
  }
  return (
    <div style={{ borderLeft: `3px solid ${borderColor}`, paddingLeft: "5px" }}>
      <span id="">{`${UpdatedSoftwareChecklist}/${SoftwareChecklist}`}</span>
      <br />
      <span id="">{SoftwareCheckListStatus}</span>
    </div>
  );


}
function statusdetails(props) {
  const { CandidateCheckListStatus } = props;

  let borderColor;
  switch (CandidateCheckListStatus) {
    case "Select All":
      return <span>Select All</span>;
    case "Completed":
      borderColor = "green";
      break;
    case "Pending":
      borderColor = "gray";
      break;
    case "Partial":
      borderColor = "#f68104";
      break;
    default:
      return null;
  }

  return (
    <div style={{ borderLeft: `3px solid ${borderColor}`, padding: "7px 0px 8px 5px" }}>
      <span>{CandidateCheckListStatus}</span>
    </div>
  );
}

function Stagedetails(props) {
  const { StageId, Stage } = props;

  let borderColor1, borderColor2, borderColor3;
  switch (StageId) {
    case 3:
      borderColor1 = borderColor2 = borderColor3 = "green";
      break;
    case 2:
      borderColor1 = borderColor2 = "green";
      borderColor3 = "gray";
      break;
    case 1:
      borderColor1 = "green";
      borderColor2 = borderColor3 = "gray";
      break;
    default:
      return null;
  }

  return (
    <div>
      <span id="Trusttext">{Stage}</span>
      <br />
      <div className="col-md-12">
        <div className="row remove-padding-stage card-layout" style={{ marginTop: "8px" }}>
          <div
            className="col-xs-2 col-sm-2 col-lg-3 col-md-2"
            style={{ borderBottom: `3px solid ${borderColor1}`, margin: "1px" }}
          />
          <div
            className="col-xs-2 col-sm-2 col-lg-3 col-md-2"
            style={{ borderBottom: `3px solid ${borderColor2}`, margin: "1px" }}
          />
          <div
            className="col-xs-2 col-sm-2 col-lg-3 col-md-2"
            style={{ borderBottom: `3px solid ${borderColor3}`, margin: "1px" }}
          />
        </div>
      </div>
    </div>
  );
}

class RevokedCandidate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      //   revokedDatalist: null,
      offerChangeStatus: [],
      openDialog: false,
      redirect: null,
      height: (window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight) - 290,
      gridHeight: (window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight) - 290
    };
    this.height =
      (window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight) - 290;
    this.width =
      (window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth) - 100;
    this.dReady = false;
    this.dtTime = false;
    this.isDataBound = false;
    this.isDataChanged = true;
    this.dropSlectedIndex = null;
    this.check = {
      type: "CheckBox",
    };
    this.select = {
      persistSelection: true,
      type: "Multiple",
      checkboxOnly: true,
    };
    this.Filter = {
      type: "Menu",
    };
    this.status = {
      type: "CheckBox",
      // itemTemplate: empTemplate
    };
    this.pageOptions = {
      pageSize:
        (window.innerHeight ||
          document.documentElement.clientHeight ||
          document.body.clientHeight) > 700
          ? 10
          : 5,
      pageCount: 5,
    };
    this.name = {
      type: "CheckBox",
    };
    this.toolbarOptions = [
      // 'ExcelExport',
      { text: 'Excel Export', tooltipText: 'Excel Export', id: 'ExcelExport', align: 'Right', prefixIcon: 'e-export-excel' }
    ];
    this.employeeNameTemplate = this.employeeNameTemplate.bind(this);
    this.navigateToAddBasicInformation = this.navigateToAddBasicInformation.bind(
      this
    );
  }

  navigateToAddBasicInformation = async (args) => {
    try {
      localStorage.setItem('last_meeting_category', decodeString(localStorage.getItem('meeting_category')))
      localStorage.setItem("meeting_category", encodeString(PRE_OFFERE));
      const params = queryString.parse(this.props.location.search);
      const path = `/preoffer?candid=${encrypt(args.CandidateId)}&id=${params.id}&appId=${params.appId}`;
      this.setState({
        redirect: path,
        // changedData: !changedData,
      }, () => this.props.setMeetingCategory(PRE_OFFERE));
    } catch (error) {
      console.log("error", error);
    }
  };

  employeeNameTemplate(props) {
    return (
      <EmployeeNameTemplate
        props={props}
        data="test"
        navigateToAddBasicInformation={this.navigateToAddBasicInformation}
      />
    );
  }
  /**
  * Calculate & Update state of new dimensions
  */
  updateDimensions() {
    let update_width = window.innerWidth - 100;
    let update_height = window.innerHeight - 140;
    let gridHeight = window.innerHeight - 345;
    this.setState({ width: update_width, height: update_height, gridHeight: gridHeight });
  }
  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));

    this.props.getRevokeTabData();
    setTimeout(() => {
      const ele = document.getElementsByClassName("e-headercelldiv");
      for (let i of ele) {
        i.classList.add("grid-left-header");
      }
    }, 1000);
  }

  onQueryCellInfoPreJoining(args) {
    const { cell } = args;
    cell.setAttribute(
      "style",
      "font-size:14px !important; text-align: left !important; padding-left:18px !important"
    );
  }

  onDataBound() {
    clearTimeout(this.clrIntervalFun);
    clearInterval(this.intervalFun);
    this.dtTime = true;
  }

  onComplete(args) {
    if (args.requestType === "filterchoicerequest") {
      if (
        args.filterModel.options.field === "Trustworthiness" ||
        args.filterModel.options.field === "Rating" ||
        args.filterModel.options.field === "Status"
      ) {
        const span = args.filterModel.dialogObj.element.querySelectorAll(
          ".e-selectall"
        )[0];
        if (!isNullOrUndefined(span)) {
          closest(span, ".e-ftrchk").classList.add("e-hide");
        }
      }
    }
  }

  onChange() {
    this.ddObj.hidePopup();
    this.gridInstance.showSpinner();
    this.dropSlectedIndex = null;
    clearTimeout(this.clrIntervalFun2);
    this.clrIntervalFun2 = setTimeout(() => {
      this.isDataChanged = true;
      this.stTime = null;
      const contentElement = this.gridInstance.contentModule.getPanel()
        .firstChild;
      contentElement.scrollLeft = 0;
      contentElement.scrollTop = 0;
      this.gridInstance.pageSettings.currentPage = 1;
      this.stTime = performance.now();
      this.gridInstance.hideSpinner();
    }, 100);
  }

  onLoad(args) {
    document
      .getElementById("overviewgrid")
      .ej2_instances[0].on("data-ready", () => {
        this.dReady = true;
        this.stTime = performance.now();
      });
    document
      .getElementById("overviewgrid")
      .addEventListener("DOMSubtreeModified", () => {
        if (this.dReady && this.stTime && this.isDataChanged) {
          this.stTime = null;
          this.dReady = false;
          this.dtTime = false;
          this.isDataChanged = false;
        }
      });
  }

  checkedBoxRevokeTemplate = (props) => {
    return (
      <div className="check-box-alignment-all-candidate-grid">
        <CheckBoxComponent
          checked={false}
          id={`candidateID${props.CandidateId}`}
          // ref={(scope) => { this.checkboxObj = scope; }}
          value={props.CandidateId}
          onChange={(e) => this.getValueOfcheckBox(e)}
        />
      </div>
    );
  };

  getValueOfcheckBox = (e) => {
    let arr = [];
    arr = this.state.offerChangeStatus;
    if (!arr.includes(e.target.properties.value)) {
      arr.push(e.target.properties.value);
      this.setState({
        offerChangeStatus: arr,
      });
    } else {
      arr = arr.filter((t) => t !== e.target.properties.value);
      this.setState({
        offerChangeStatus: arr,
      });
    }
  };

  initiateReadyToJoin = () => {
    if (this.state.offerChangeStatus.toString().length > 0) {
      const revokeToReadyToJoin = {
        authUser: this.props.auth.authUser,
        candIds: this.state.offerChangeStatus.toString(),
        statusId: 1,
        revokeType: "revokeJoin"

      };
      this.props.initiateAllcandidate(revokeToReadyToJoin);
    }
    this.closeDialog();
    this.setState({ offerChangeStatus: [] });
  };

  dateFormat = (props) => {
    return (
      <div className="date-format-all-grid">
        <span>{getDisplayDateOnly(new Date(props.JoiningDate))}</span>
      </div>
    );
  };
  revokeDateFormat = (props) => {
    return (
      <div className="date-format-all-grid">
        <span>{getDisplayDateOnly(new Date(props.RevokeDate))}</span>
      </div>
    );
  };

  openDialog = () => {
    if (this.state.offerChangeStatus.toString().length > 0) {
      this.setState({ openDialog: true });
    } else {
      getNotify(WARNING, "Please select at least one candidate! ");
    }
  };

  closeDialog = () => {
    this.setState({ openDialog: false });
  };
  created = () => {
    setTimeout(() => {
      if (document.getElementById("right-pane")) {
        document.getElementById("right-pane").addEventListener("click", this.onClick.bind(this));
        document.getElementById("right-pane").addEventListener("scroll", this.onScroll.bind(this));
      }
    }, 1000);
  }

  revokeComment = (props) => {
    return (
      <div>
        <TooltipComponent
          content={props.RevokeComments.replace(/(?:\r\n|\r|\n)/g, '<br>')}
          position="BottomCenter"
          created={this.created}
          isSticky={false} // if use false tooltip auto cancel
          // isSticky={true} // if use true tooltip we naualt need to cancel it.
          className="tooltip-box"
          cssClass='customtooltip'
        >
          <span
            style={{
              fontSize: "14px",
              display: "block",
              width: "100px",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {props.RevokeComments}
          </span>
        </TooltipComponent>
      </div>
    );
  };
  band = (props) => {
    const { Band } = props;
    return (
      <div>
        <span className="band-alignment" >
          {Band}
        </span>
      </div>
    );
  };
  dataBound = () => {
    if (this.treegrid) {
      this.treegrid.autoFitColumns(['taskName']);
    }
  }

  toolbarClick = (args) => {
    if (args.item.text === 'Excel Export' && this.gridInstance) {
      if (this.gridInstance.getColumnByField("checkbox")) {
        this.gridInstance.getColumnByField("checkbox").visible = false;
      }

      this.gridInstance.excelExport(this.getExcelExportProperties());
    }
  }

  excelExportComplete = () => {
    if (this.gridInstance) {
      if (this.gridInstance.getColumnByField("checkbox")) {
        this.gridInstance.getColumnByField("checkbox").visible = true;
      }
    }
  }

  getExcelExportProperties = () => {
    const dateFns = new DateFnsUtils();
    var currentDate = dateFns.format(new Date(), 'yyyyMMdd');
    return {
      fileName: `${currentDate}_offer-revoked.xlsx`
    }
  }

  render() {
    if (this.state.redirect !== null) {
      return <Redirect to={this.state.redirect} />;
    }
    var widthColumn = 0;
    var finalWidth;
    // eslint-disable-next-line
    getGridColumn("Revoke").forEach((data) => {
      widthColumn = widthColumn + data.width;

    });
    if (widthColumn <= this.width) {
      finalWidth = widthColumn;
    } else {
      finalWidth = this.width
    }

    return (
      <div
        className="row"
        style={{ height: this.state.height, overflowY: "hidden", overflowX: 'hidden' }}
      >
        {this.props.revokedDatalist !== null &&
          this.props.revokedDatalist !== undefined &&
          this.props.revokedDatalist !== "" &&
          this.props.revokedDatalist !== "error" &&
          this.props.revokedDatalist.length > 0 ? (
          <div>
            <div className="row-xs-12 row-sm-12 row-lg-12 row-md-12">
              <div
                style={{
                  paddingLeft: "15px",
                  paddingRight: "15px",
                  paddingTop: "5px",
                }}
              >
                <GridComponent
                  className="grid-head-custome"
                  id="overviewgrid"
                  allowPaging
                  pageSettings={this.pageOptions}
                  dataSource={getFormatDateForGrid(this.props.revokedDatalist)}
                  enableHover={false}
                  enableVirtualization={false}
                  rowHeight={30}
                  height={this.state.gridHeight}
                  // width = {this.width}
                  ref={(g) => {
                    this.gridInstance = g;
                  }}
                  actionComplete={this.onComplete.bind(this)}
                  load={this.onLoad.bind(this)}
                  queryCellInfo={this.onQueryCellInfoPreJoining.bind(this)}
                  dataBound={this.onDataBound.bind(this)}
                  filterSettings={this.Filter}
                  allowFiltering
                  allowSorting
                  allowSelection
                  selectionSettings={this.select}
                  allowTextWrap={true}
                  // overflowX={'auto'}
                  width={`${finalWidth}`}
                  allowExcelExport={true}
                  toolbar={this.toolbarOptions}
                  toolbarClick={this.toolbarClick}
                  excelExportComplete={this.excelExportComplete}
                >
                  <ColumnsDirective>
                    <ColumnDirective
                      template={this.checkedBoxRevokeTemplate}
                      width="50"
                      field={"checkbox"}
                      headerText={""}
                      allowFiltering={false}
                    />
                    {
                      getGridColumn("Revoke").map(item => {
                        switch (item.colName) {
                          case CANDIDATE_NAME:
                            return <ColumnDirective key={item.field} template={this.employeeNameTemplate} textAlign={item.align + ' !important'} field={item.field} headerText={item.colName} width={item.width} filter={this.Filter} />
                          case CANDIDATE_STATUS:
                            return <ColumnDirective key={item.field} template={statusdetails} filter={this.check} textAlign={item.align + ' !important'} field={item.field} headerText={item.colName} width={item.width} />
                          case STAGE:
                            return <ColumnDirective key={item.field} template={Stagedetails} filter={this.check} textAlign={item.align + ' !important'} field={item.field} headerText={item.colName} width={item.width} />
                          case ADMIN:
                            return <ColumnDirective key={item.field} template={adminTemplate} filter={this.check} textAlign={item.align + ' !important'} field={item.field} headerText={item.colName} width={item.width} />
                          case IT:
                            return <ColumnDirective key={item.field} template={itTemplate} filter={this.check} textAlign={item.align + ' !important'} field={item.field} headerText={item.colName} width={item.width} />
                          case PEOPLE_TEAM:
                            return <ColumnDirective key={item.field} template={peopleTeamTemplate} filter={this.check} textAlign={item.align + ' !important'} field={item.field} headerText={item.colName} width={item.width} />
                          case FINANCE:
                            return <ColumnDirective key={item.field} template={financeTemplate} filter={this.check} textAlign={item.align + ' !important'} field={item.field} headerText={item.colName} width={item.width} />
                          case SOFTWARE:
                            return <ColumnDirective key={item.field} template={softwareTemplate} filter={this.check} textAlign={item.align + ' !important'} field={item.field} headerText={item.colName} width={item.width} />
                          case DATE_OF_JOINING:
                            return <ColumnDirective key={item.field} format={DateFilterformat} template={this.dateFormat} textAlign={item.align + ' !important'} filter={this.check} field={item.field} headerText={item.colName} width={item.width} />
                          case REVOKE_DATE:
                            return <ColumnDirective key={item.field} format={DateFilterformat} template={this.revokeDateFormat} textAlign={item.align + ' !important'} filter={this.check} field={item.field} headerText={item.colName} width={item.width} />
                          case REVOKE_COMMENTS:
                            return <ColumnDirective key={item.field} template={this.revokeComment} filter={this.Filter} textAlign={item.align + ' !important'} field={item.field} headerText={item.colName} width={item.width} />
                          case BAND:
                            return <ColumnDirective key={item.field} template={this.band} filter={this.check} textAlign={item.align + ' !important'} field={item.field} headerText={item.colName} width={item.width} />
                          default:
                            return <ColumnDirective key={item.field} textAlign={item.align + ' !important'} field={item.field} headerText={item.colName} width={item.width} filter={this.check} />
                        }
                      }
                      )
                    }
                  </ColumnsDirective>
                  <Inject services={[Page, Filter, VirtualScroll, Sort, Resize, ExcelExport, Toolbar]} />
                </GridComponent>
              </div>
            </div>
            <div className="control-section col-md-12">
              <div
                className="row card-layout"
                style={{
                  paddingRight: "30px",
                  paddingTop: "15px",
                  textAlign: "end",
                }}
              >
                <ButtonComponent
                  style={{ background: "#f94f5e", color: "#fff" }}
                  onClick={this.openDialog}
                >
                  {" "}
                  INITIATE TO BE OFFERED
                </ButtonComponent>
              </div>
            </div>
            <div style={{ width: "350px" }}>
              <Dialog open={this.state.openDialog}>
                <DialogTitle> To Be Offered</DialogTitle>
                <DialogContent>
                  Are you sure you want to initiate joining process for selected Candidate(s)?
                </DialogContent>
                <DialogActions>
                  <Button onClick={this.initiateReadyToJoin}> Yes </Button>
                  <Button onClick={this.closeDialog}> No </Button>
                </DialogActions>
              </Dialog>
            </div>
          </div>
        ) : (
          <div className="control-section col-md-12" style={{ height: 75 }}>
            {this.props.revokedDatalist === "error" && // NOSONAR
              this.props.revokedDatalist.length === 0 ? (
              <Progressbar />
            ) : (
              <ErrorMessage message="No Data Available" />
            )}
          </div>
        )}
      </div>
    );
  }
}
const mapStateToProps = ({ auth, router }) => {
  const { location } = router;
  return { auth, location };
};
export default connect(mapStateToProps, { initiateAllcandidate, setMeetingCategory })(
  RevokedCandidate
);
