/**
 * @author ML Vivek Khodade
 * 
 */
import {ADD_EDIT_SUB_CATEGORY, 
  ADD_NEW_SERVICE_REQUEST, 
  ADD_UPDATE_HELP_DESK_REDUX, 
  HANDLE_HELPDESK_ADMIN_POPUP,
  HELPDESK_CREATE_USER,
  ADD_TICKET_UPDATE,
  HELPDESK_REDUX_UPDATE,
  UPDATEHELPDESK_USER_INFO,
  ADD_NEW_IDEA
} from '../../constant/helpdesk/helpdeskconstant'

export const addNewServiceRequest = (data) => {
    return {
      type: ADD_NEW_SERVICE_REQUEST,
      payload: data,
    };
  };

export const addNewIdeas = (data) => {
  return {
    type: ADD_NEW_IDEA,
    payload: data,
  };
};

export const createHelpDeskUser = (data) => {
    return {
      type: HELPDESK_CREATE_USER,
      payload: data,
    };
  };
export const addUpdateHelpDeskRedux = (data) => {
    return {
      type: ADD_UPDATE_HELP_DESK_REDUX,
      payload: data,
    };
  };

  /**
   * Author: Md Jahir Uddin
   * Date: 17-04-2023
   * Description: The function returns an object with a type and payload property for adding or editing a
   * subcategory.
   * @param data - The data parameter is an object that contains information about a subcategory. This
   * information could include the subcategory's name, description, and any other relevant details. The
   * addEditSubCategory function returns an action object with a type of ADD_EDIT_SUB_CATEGORY and a
   * payload of the data object. This action
   * @returns A function that returns an object with a type property set to the value of
   * ADD_EDIT_SUB_CATEGORY and a payload property set to the value of the data parameter.
   */
  export const addEditSubCategory = (data) => {
    return{
      type: ADD_EDIT_SUB_CATEGORY,
      payload: data
    }
  }

  export const handlePopup = (data) => {
    return {
      type: HANDLE_HELPDESK_ADMIN_POPUP,
      payload: {openPopup: data}
    }
  }

  export const helpdeskReduxUpdate = (data) => {
    return {
      type: HELPDESK_REDUX_UPDATE,
      payload: data
    }
  }
  
  export const addTicketUpdate = (data) => {
    return {
      type: ADD_TICKET_UPDATE,
      payload: data
    }
  }
  export const updateHelpDeskUserInfo = (data) => {
    return {
      type: UPDATEHELPDESK_USER_INFO,
      payload: data
    }
  }