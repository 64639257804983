/**
 * Author Name :  Sanket Bari
 * Created Date: 05 March 2024
 * Modified Date: - .
 * Last Modified Name: - .
 */
// Last Modified: -

import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import excel from '../../../assets/images/excel.png';
import fileclose from '../../../assets/svg/close.svg'


const Customprogressbar = ({filename}) =>{

    const percentage = 100;
    const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
        height: 12,
        borderRadius: 5,
        [`&.${linearProgressClasses.colorPrimary}`]: {
          backgroundColor: '#DBDBDD',
        },
        [`& .${linearProgressClasses.bar}`]: {
          borderRadius: 5,
          backgroundColor: '#f9505f',
          width: '100%',
        },
      }));

    return(<>
        <div style={{border: '2px solid rgba(0, 0, 0, .1)', borderRadius: '5px', width: '300px',height:'80px', padding: '20px',display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        
            <div style={{ marginRight: '10px' }}>
                <img style={{ height: '25px' }} src={excel} alt="excel icon" />
            </div>
                <div style={{width: '100%',paddingTop: '10px'}}>
                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                        <span style={{width: '55px',flexShrink: '0',
                                        color: '#4B4B55',fontFamily: "Circular TT Book",
                                        fontSize: '14px',fontStyle: 'normal',fontWeight: '450',lineHeight: 'normal'}}>
                                        {filename}
                        </span>
                        {/* <img src={fileclose} alt="close" style={{ marginLeft: '10px' }} /> */}
                    </div>
                    <span>
                            
                            <BorderLinearProgress variant="determinate" value={percentage} />
                    </span>
             
                    <div>
                        <TooltipComponent
                            style={{
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            cursor: 'pointer',
                            display: 'inline-block',
                            maxWidth: '100%',
                            fontFamily: 'unset',
                            fontSize: '7px',
                        }}
                        title={`${percentage}%`}
                        >
                            <div style={{width: '60px',height: '12px',flexShrink: '0',
                            color: '#4B4B55',fontFamily: "Circular TT Book",
                            fontSize: '12px',fontStyle: 'normal',fontWeight: '450',lineHeight: 'normal'}}>{percentage}% Done</div>
                            
                        </TooltipComponent>
                    </div>
                </div>
        </div>
        </>
    );
}

export default Customprogressbar;
