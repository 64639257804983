import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Button } from "@material-ui/core";
import { ATTENDANCE, WORKDAY_WINDOW, WEEKDAY, DAYBREAK, ATTENDANCE_SOURCE, LEAVE, HOLIDAY, OPTIONAL_HOLIDAY, STATUTORY, EXCEPTIONAL_ATTENDANCE, WORKING_DAY, COLOUR, ROLE, SYMBOL, LOCK_UNLOCK, LEAVE_YEAR_CONFIG, WORKDAY_DAYBREAK_MAPPING, LEAVE_YEAR } from "../../../redux/constant/meetingcategoryconstant";
import HolidayClusterPopup from '../../../components/lnacomponents/holidaycluster';
import AttendanceClusterPopup from '../../../components/lnacomponents/attendancecluster';
import WeekdayClusterPopup from '../../../components/lnacomponents/weekdaycluster';
import DayBreakClusterPopup from '../../../components/lnacomponents/daybreakcluster';
import SourceClusterPopup from '../../../components/lnacomponents/sourcecluster';
import WorkdayWindowPopup from '../../../components/lnacomponents/workdaywindow';
import LeaveClusterPopup from '../../../components/lnacomponents/leavecluster';
import StatutoryClusterPopup from '../../../components/lnacomponents/statutorycluster';
import WorkingDayClusterPopup from '../../../components/lnacomponents/workingdaycluster';
import OptionalclusterPopup from '../../../components/lnacomponents/optionalholidaycluster';
import RoleClusterPopup from '../../../components/lnacomponents/roleCluster';
import ColourClusterPopup from '../../../components/lnacomponents/colourCluster';
import SymbolClusterPopup from '../../../components/lnacomponents/symbolcluster';
import WorkDayWindowMappingPopup from '../../../components/lnacomponents/workdayBreakMapping';
import LeaveYearPopup from '../../../components/lnacomponents/leaveYearcluster';
import Exceptionalcluster from '../../../components/lnacomponents/exceptionalcluster';
import Lockunlockpopup from '../../../components/lnacomponents/lockunlockpopup';
import Leaveyearconfigcluster from '../../../components/lnacomponents/leaveyearconfigcluster';
import '../../layout/lna/lnaReport.css';
class ClusterSetup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      height: (window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight) - 145
    }
    this.clickChild = null
    this.clickChild = null
  }

  componentDidMount() {
    this.setState({
      height: (window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight) - 145
    })
  }

  popUpHandler = () => {
    const { categoryName, changePopUpHandler, masterId, history, openPopup, editClusterId } = this.props
    let activeMasterId = (masterId == 0 ? categoryName : masterId).toString();
    {openPopup === 'add' ?changePopUpHandler("open") : changePopUpHandler("edit",editClusterId); }
    // 
    switch (activeMasterId) {
      case ATTENDANCE:
        return <AttendanceClusterPopup
          categoryName={activeMasterId}
          history={history}
          changePopUpHandler={changePopUpHandler}
          setSubmitClick={click => this.clickChild = click}
          openPopup={openPopup}
          editClusterId={editClusterId}
        />;
      case WORKDAY_WINDOW:
        return <WorkdayWindowPopup
          categoryName={activeMasterId}
          history={history}
          changePopUpHandler={changePopUpHandler}
          setSubmitClick={click => this.clickChild = click}
          openPopup={openPopup}
          editClusterId={editClusterId}
        />;
      case WEEKDAY:
        return <WeekdayClusterPopup
          categoryName={activeMasterId}
          history={history}
          changePopUpHandler={changePopUpHandler}
          setSubmitClick={click => this.clickChild = click}
          openPopup={openPopup}
          editClusterId={editClusterId}
        />;
      case DAYBREAK:
        return <DayBreakClusterPopup
          categoryName={activeMasterId}
          history={history}
          changePopUpHandler={changePopUpHandler}
          setSubmitClick={click => this.clickChild = click}
          openPopup={openPopup}
          editClusterId={editClusterId}
        />;
      case ATTENDANCE_SOURCE:
        return <SourceClusterPopup
          categoryName={activeMasterId}
          history={history}
          changePopUpHandler={changePopUpHandler}
          setSubmitClick={click => this.clickChild = click}
          openPopup={openPopup}
          editClusterId={editClusterId}
        />;
      case LEAVE:
        return <LeaveClusterPopup
          categoryName={activeMasterId}
          history={history}
          changePopUpHandler={changePopUpHandler}
          setSubmitClick={click => this.clickChild = click}
          openPopup={openPopup}
          editClusterId={editClusterId}
        />;
      case HOLIDAY:
        return <HolidayClusterPopup
          categoryName={activeMasterId}
          history={history}
          changePopUpHandler={changePopUpHandler}
          setSubmitClick={click => this.clickChild = click}
          openPopup={openPopup}
          editClusterId={editClusterId}
        />;
      case OPTIONAL_HOLIDAY:
        return <OptionalclusterPopup
          categoryName={activeMasterId}
          history={history}
          changePopUpHandler={changePopUpHandler}
          setSubmitClick={click => this.clickChild = click}
          openPopup={openPopup}
          editClusterId={editClusterId}
        />;
      case STATUTORY:
        return <StatutoryClusterPopup
          categoryName={activeMasterId}
          history={history}
          changePopUpHandler={changePopUpHandler}
          setSubmitClick={click => this.clickChild = click}
          openPopup={openPopup}
          editClusterId={editClusterId}
        />;
      case EXCEPTIONAL_ATTENDANCE:
        return <Exceptionalcluster
          categoryName={activeMasterId}
          history={history}
          changePopUpHandler={changePopUpHandler}
          setSubmitClick={click => this.clickChild = click}
          openPopup={openPopup}
          editClusterId={editClusterId}
        />;
      case WORKING_DAY:
        return <WorkingDayClusterPopup
          categoryName={activeMasterId}
          history={history}
          changePopUpHandler={changePopUpHandler}
          setSubmitClick={click => this.clickChild = click}
          openPopup={openPopup}
          editClusterId={editClusterId}
        />;
      case COLOUR:
        return <ColourClusterPopup
          categoryName={activeMasterId}
          history={history}
          changePopUpHandler={changePopUpHandler}
          setSubmitClick={click => this.clickChild = click}
          openPopup={openPopup}
          editClusterId={editClusterId}
        />;
      case ROLE:
        return <RoleClusterPopup
          categoryName={activeMasterId}
          history={history}
          changePopUpHandler={changePopUpHandler}
          setSubmitClick={click => this.clickChild = click}
          openPopup={openPopup}
          editClusterId={editClusterId}
        />;
      case SYMBOL:
        return <SymbolClusterPopup
          categoryName={activeMasterId}
          history={history}
          changePopUpHandler={changePopUpHandler}
          setSubmitClick={click => this.clickChild = click}
          openPopup={openPopup}
          editClusterId={editClusterId}
        />;
      case LOCK_UNLOCK:
        return <Lockunlockpopup
          categoryName={activeMasterId}
          history={history}
          changePopUpHandler={changePopUpHandler}
          setSubmitClick={click => this.clickChild = click}
          openPopup={openPopup}
          editClusterId={editClusterId}
        />;
      case LEAVE_YEAR_CONFIG:
        return <Leaveyearconfigcluster
          categoryName={activeMasterId}
          history={history}
          changePopUpHandler={changePopUpHandler}
          setSubmitClick={click => this.clickChild = click}
          openPopup={openPopup}
          editClusterId={editClusterId}
        />;
      case LEAVE_YEAR:
        return <LeaveYearPopup
          categoryName={activeMasterId}
          history={history}
          changePopUpHandler={changePopUpHandler}
          setSubmitClick={click => this.clickChild = click}
          openPopup={openPopup}
          editClusterId={editClusterId}
        />;
      case WORKDAY_DAYBREAK_MAPPING:
        return <WorkDayWindowMappingPopup
          categoryName={activeMasterId}
          history={history}
          changePopUpHandler={changePopUpHandler}
          setSubmitClick={click => this.clickChild = click}
          openPopup={openPopup}
          editClusterId={editClusterId}
        />;
      default:
        return <AttendanceClusterPopup
          categoryName={activeMasterId}
          history={history}
          changePopUpHandler={changePopUpHandler}
          setSubmitClick={click => this.clickChild = click}
          openPopup={openPopup}
          editClusterId={editClusterId}
        />;
    }
  }

  formNameHandler = () => {
    const { categoryName, masterId } = this.props
    let activeMasterId = (masterId == 0 ? categoryName : masterId).toString();

    switch (activeMasterId) {
      case ATTENDANCE:
        document.title = "Connect - Attendance"
        return "AttendanceCluster"
      case WORKDAY_WINDOW:
        document.title = "Connect - Workday Window"
        return "WorkdayWindow"
      case WEEKDAY:
        document.title = "Connect - Weekday"
        return "WeekdayCluster"
      case DAYBREAK:
        document.title = "Connect - Daybreak"
        return "DayBreakCluster"
      case ATTENDANCE_SOURCE:
        document.title = "Connect - Attendance Source"
        return "SourceCluster"
      case LEAVE:
        document.title = "Connect - Leave"
        return "LeaveCluster"
      case HOLIDAY:
        document.title = "Connect - Holiday"
        return "HolidayCluster"
      case OPTIONAL_HOLIDAY:
        document.title = "Connect - Optional Holiday"
        return "Optionalcluster"
      case STATUTORY:
        document.title = "Connect - Statutory"
        return "StatutoryCluster"
      case EXCEPTIONAL_ATTENDANCE:
        document.title = "Connect - Exceptional"
        return "ExceptionalCluster"
      case WORKING_DAY:
        document.title = "Connect - Workingday"
        return "WorkingDayCluster"
      case COLOUR:
        document.title = "Connect - Colour"
        return "ColourCluster"
      case ROLE:
        document.title = "Connect - Role"
        return "RoleCluster"
      case SYMBOL:
        document.title = "Connect - Symbol"
        return "SymbolCluster"
      case LOCK_UNLOCK:
        document.title = "Connect - Attendance Lock"
        return "LockUnlock"
      case LEAVE_YEAR_CONFIG:
        document.title = "Connect - Leave Year Configuration"
        return "LeaveYearConfig"
      case WORKDAY_DAYBREAK_MAPPING:
        document.title = "Connect - Workday Day Break Mapping"
        return "workdayDayBreak"
      case LEAVE_YEAR:
        document.title = "Connect - Leave with Finance"
        return "LeaveYearCluster"
      default:
        document.title = "Connect - Attendance"
        return "AttendanceCluster"
    }
  }

  render() {
    const { changePopUpHandler, openPopup, popUpSource } = this.props
    return (
      <>
        <div id={`${this.formNameHandler()}Form`} className="col-xs-3 col-sm-3 col-lg-3 col-md-3 p-md" style={{ position: 'absolute', right: 0 }}>
          <div
            className="col-xs-12 col-sm-12 col-lg-12 col-md-12"
            style={{
              backgroundColor: "#ffffff",
              display: "flex",
              flexDirection: "column",
              // justifyContent: "space-between",
              borderRadius: ".4rem",
              // height: height,

              marginTop: "3.5rem",
              borderBottom: "1px solid #DBDBDD",
              height: "100%"

            }}>
            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12" >
              {/* <div className='col-sm-12 col-md-12 col-lg-12'> */}
              <div style={{
                backgroundColor: '#ffffff',
                // borderStartEndRadius: "0.4em",
                // borderStartStartRadius: "0.4em",
                height: "3.5rem",
                display: "flex",
                alignItems: "center",
                paddingLeft: "1rem",
                fontWeight: 600,
                fontStyle: 'Circular Std Book', fontSize: '1rem', fontFamily: "Arial",
                borderBottom: "1px solid #DBDBDD",
                marginBottom: '0px',
                boxShadow: '-10px 0px 10px 1px #aaaa',

              }}
              > {openPopup === 'add' ? `Add ${popUpSource}` : `Edit ${popUpSource}`}
              </div>
              <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12"
                style={{ color: 'black', fontStyle: 'Circular Std Book', fontSize: '1rem', fontFamily: "Arial" }}
              >
                {/* Add */}
              </div>
              {/* </div> */}
            </div>
            {/** individual components */}
            <div style={{ height: '100%' }}>
              <div id={`${this.formNameHandler()}`} style={{ boxShadow: '-10px 0px 10px 1px #aaaa', height: '100%' }}>
                {this.popUpHandler()}
              </div>


              <div
                className='col-sm-12 col-md-12 col-lg-12 pb-lg pt-md masterPopupFooter'
                style={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                  borderTop: "1px solid #DBDBDD",
                  backgroundColor: '#FAFAFA',
                  boxShadow: '-10px 0px 10px 1px #aaaa',
                  // flexDirection: 'column',
                  // position: 'fixed',
                  // bottom: 0,
                  // right: 0
                }}>
                {/* <div>
                <Button
                  style={{ width: "90px" }}
                  className="remove-button-shadow atc-cancel-button"
                  variant="contained"
                  onClick={() => changePopUpHandler("close")}
                >
                  Back
                </Button>
              </div> */}
                <div className='popupSaveButton'>
                  <Button
                    style={{ width: "90px" }}
                    className="remove-button-shadow lna-cancel-button"
                    variant="contained"
                    onClick={() => changePopUpHandler("close")}
                  >
                    Cancel
                  </Button>
                  <Button
                    id="okrTourGuideBtnStart"
                    style={{ width: "80px" }}
                    className="remove-button-shadow atc-save-button"
                    variant="contained"
                    onClick={() => this.clickChild()}
                  >
                    Save
                  </Button>

                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          {/* <NotificationContainer style={{ position: 'absolute' }} /> */}
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ meetingcategory, lnareducer }) => {
  const { categoryName } = meetingcategory;
  const { popUpSource } = lnareducer
  return {
    categoryName, popUpSource
  }
}
export default connect(mapStateToProps, {})(ClusterSetup);
