/**
 * Author: Prashant Waphare.
 * Description: To drag/drop or file selection screen
 * Added Date : 11-02-2020
 */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from 'react-redux'
import { addUpdateHelpDeskRedux } from '../../redux/actions/helpdesk/helpdeskaction'
import { getNotify } from "../../app/other/commonfunction";
import { WARNING, SUCCESS } from "../../assets/constants/constants";
import { urlConfig, corsallow } from "../../config/config";
import "../document/dropzone/dropzone.css";
import UploadedImageFilepath, { 
  UploadCommonDocFilepath
} from "./ratingcommonfun";
class UploadDocumentLink extends Component {
  constructor(props) {
    super(props);
    const { allowmultiple } = this.props;
    this.state = {
      hightlight: false,
      allowmultiple,
      filePath: '',
      UploadedImage: "images/baseline-cloud_upload-24px.svg",
      fileNames: [],
      deletePopup: false,
      deleteIdx: null,
    };
    this.fileInputRef = React.createRef();
    this.openFileDialog = this.openFileDialog.bind(this);
    this.onFilesAdded = this.onFilesAdded.bind(this);
    this.onDragOver = this.onDragOver.bind(this);
    this.onDragLeave = this.onDragLeave.bind(this);
    this.onDrop = this.onDrop.bind(this);
    this.getUrlForView = this.getUrlForView.bind(this);
    this.getcssClsForView = this.getcssClsForView.bind(this);
  }

  componentDidMount() {
    const fileInput = document.getElementById('FileInput');
    fileInput?.addEventListener('click', () => {
      // Clear the file input value before clicking it
      let fullPath = fileInput.value;
      let startIndex = fullPath.lastIndexOf("\\") + 1; // Find the last index of backslash
      let fileName = fullPath.substr(startIndex); // Extract the substring after the last backslash
      if (this.props.fileList.includes(fileName)) return;
      else fileInput.value = '';
    });
  }

  onDragOver(event) {
    event.preventDefault();
    const { disabled } = this.props;
    if (disabled) return;
    this.setState({ hightlight: true });
  }

  onDragLeave() {
    this.setState({ hightlight: false });
  }

  onDrop(event) {
    event.preventDefault();
    const { disabled } = this.props;
    if (disabled) return;
    const { files } = event.dataTransfer;
    if (files.length > 0)
      this.uplaodDoc(files[0])
    this.setState({ hightlight: false });
  }

  /**
   * @author Prashant Waphare
   * @description fired this event once the file is added
   */
  onFilesAdded(evt) {
    const { type } = this.props;
    const fileObj = evt.target.files[0];
    let maxFileSize = 1 * 1024 * 1024;
    let files = fileObj
    let allowedExtensions = ['jpeg', 'png', 'jpg','pdf'];
    let fileExtension = files.name.split('.').pop().toLowerCase();

    if (this.props.type === 'regularization') {
      let count = 0;
      if(this.props.fileList.length > 0 && this.props.fileList.length <= 4) {
        this.props.fileList.map(item=> {
          if(item.split('_').slice(1).join('_') === fileObj.name) {
            count = count + 1;
          }
        });
      } else if(this.props.fileList.length == 5) {
        getNotify(WARNING, " Can't upload more than 5 files.")
        return;
      }
      if(count > 0) {
        getNotify(WARNING, " File with same name already exists.")
        return;
      }
    }

    if (this.props.type === 'logo') {
      if (files.size > maxFileSize) {
        getNotify(WARNING, " File is too large. Maximum file size allowed is less than 1MB.")
        return;
      }
      if (!allowedExtensions.includes(fileExtension)) {
        getNotify(WARNING, "Invalid file extension. Allowed extensions are " + allowedExtensions.join(', '));
        return;
      }
      if (evt.target.files[0] !== null)
        this.uplaodDoc(evt.target.files[0])
      return;
    }
    if (evt.target.files[0] !== null)
      this.uplaodDoc(evt.target.files[0])
    const currentDate = Date.parse(new Date()) / 1000;
    const fileNm = currentDate.toString() + "_" + fileObj.name;

    let file = fileObj
    maxFileSize = 10 * 1024 * 1024
    if (file.size > maxFileSize) {
      getNotify(WARNING, " File is too large. Maximum file size allowed is less than 10MB.")
      return;
    }
    if (type === 'ticket' || type === 'idea' || type === 'comment') return
    allowedExtensions = ['jpeg', 'pdf', 'jpg'];
    fileExtension = files.name.split('.').pop().toLowerCase();
    if (!allowedExtensions.includes(fileExtension) && this.props.type !== "rating-self") {
      getNotify(WARNING, "Invalid file extension. Allowed extensions are  " + allowedExtensions.join(', '));
      return;
    }
    if (this.props.documentDetails)
      this.props?.documentDetails(currentDate, fileNm);

  }

  uplaodDoc(upfile) { //NOSONAR
    const { disabled, type } = this.props;
    if (disabled) return;
    if (upfile !== undefined) {
      if ((type === 'ticket' || type === 'idea' || type === 'comment') && this.props.fileList.length >= 10) {
        getNotify(WARNING, "Maximum number of files allowed is 10.");
        return;
      }
      let file = upfile
      let maxFileSize = 10 * 1024 * 1024
      if (file.size > maxFileSize) {
        getNotify(WARNING, " File is too large. Maximum file size allowed is less than 10MB.")
        return;
      }
      //NOSONAR
      // if((type === 'ticket')){
      //   let totalFileSize=0;
      //   console.log("🚀 ~ file: uploaddocuments.jsx:118 ~ UploadDocumentLink ~ this.props.fileList?.map ~ this.props.fileList:", this.props.files)
      //   this.props.files?.map((file)=>{
      //     totalFileSize+=file.size;
      //   })
      //   if(totalFileSize>=20480000){
      //     getNotify(WARNING, "Total document(s) files size should not be more than 20MB.");
      //     return;
      //   }
      // }

      let files = upfile
      let allowedExtensions = ['jpeg', 'pdf', 'jpg'];
      let fileExtension = files.name.split('.').pop().toLowerCase();
      if (type === 'idea') {
        allowedExtensions = ['jpeg', 'pdf', 'jpg', 'doc', 'docx', 'txt', 'pptx', 'ppt', 'jfif', 'xlsx', 'xls', 'png'];
      }
      if (type === "comment" || type === "ticket") {
        this.props.getFileDetails('add', upfile);
        return
      }

      if (!allowedExtensions.includes(fileExtension) && this.props.type !== "rating-self") {
        getNotify(WARNING, "Invalid file extension. Allowed extensions are  " + allowedExtensions.join(', '));
        return;
      }

      const currentDate = Date.parse(new Date()) / 1000;
      const fileObj = upfile;
      const fileNm = currentDate.toString() + '_' + fileObj.name.replace(/[&#,+()$~%'":*?<>{}]/g, '');
      this.setState({ UploadedImage: URL.createObjectURL(upfile), filePath: upfile.name.replace(/[&#,+()$~%'":*?<>{}]/g, '') }, () => {
        let fileList = this.props.fileList;
        fileList.push(fileNm);
        this.props.selectedFiles(fileList);
      });
      const req = new XMLHttpRequest();
      const formData = new FormData();

      formData.append("file", fileObj, fileNm);
      let exUrl;
      switch (type) {
        // case "leave":
        //   exUrl = "document/upl/lna";
        //   formData.append("fileName", fileNm);
        //   formData.append("userId", this.props.authUser);
        //   req.open("POST", `${corsallow}${urlConfig.lnaApiUrl}${exUrl}`);
        //   break;
        case 'leaveapply':
        case "leave":
        case "exceptional":
        case "daybreak":
        case "leave":
        case "workday":
        case "weekday":
        case "holiday":
        case "statutory":
        case "attendance":
        case "regularization":
        case "optionalholiday":
          exUrl = "document/lna";
          req.open("POST", `${corsallow}${urlConfig.lnaApiUrl}${exUrl}`);
          formData.append("type", type);
          break
        case 'idea':
          req.open("POST", `${corsallow}${urlConfig.tmlcApiUrl}helpdesk/uploadhelpdeskdocument`);
          break;
        case "logo":
          const imgType = upfile.type.replace("image/", ".");
          formData.append("usertype", "C");
          formData.append("type", "logo");
          formData.append("fileName", `${currentDate}${imgType}`);
          req.open("POST", `${corsallow}${urlConfig.tmlcApiUrl}document/upl`);
          req.setRequestHeader("Authorization", localStorage.getItem("jwtToken"));
          this.props.documentDetails(`${currentDate}${imgType}`);
          break;
        default:
          formData.append("type", type === "rating-self" ? "rating" : type);
          formData.append("usertype", "D");
          exUrl = "document/upl";

          req.open("POST", `${corsallow}${urlConfig.tmlcApiUrl}${exUrl}`);
      }
      req.setRequestHeader("Authorization", localStorage.getItem("jwtToken"));
      req.send(formData);

      if (this.props.type === "idea")
        this.props.addUpdateHelpDeskRedux({ uploadDocsLoading: true })
      else if (req.statusText === "Internal Server Error") {
        this.props.addUpdateHelpDeskRedux({ uploadDocsLoading: false })
        getNotify(WARNING, "Please select minimum file size(less than 10 MB)");
      } else {
        req.onreadystatechange = () => {
          if (req.readyState === XMLHttpRequest.DONE) {
            if (this.props.type === "idea")   //NOSONAR
              this.props.addUpdateHelpDeskRedux({ uploadDocsLoading: false });            
          }
        }
        if (this.props.type === "idea") return;
        getNotify(SUCCESS, "Document uploaded successfully.");
        if (this.props?.documentDetails)
          this.props?.documentDetails(currentDate, fileNm);
      }
    }
  }

  /**
   * @author Suresh R. Sargam
   * @description Add files to list of array
   */
  fileListToArray(list) {
    const { folderPath } = this.props;
    const array = [];
    const items = list;
    for (let i = 0; i < items.length; i += 1) {
      items.item(i).folderPath = folderPath;
      array.push(items.item(i));
    }
    return array;
  }

  /**
   * @author Suresh R. Sargam
   * @description to open file dialog
   */
  openFileDialog() {
    const { disabled } = this.props;
    if (disabled) return;
    this.fileInputRef.current.click();
  }

  removeFile = (indexToRemove) => {

    let mFileList = this.props.fileList;
    mFileList = [...mFileList.filter((_, index) => index !== indexToRemove)];

    if (this.props.type === 'comment' || this.props.type === "ticket") {
      this.props.getFileDetails('remove', indexToRemove)
      return
    }

    this.props.selectedFiles(mFileList)
    this.setState({ deletePopup: false })
  }

  getUrlForView = (type) => {
    let url;
    switch (type) {
      case "leave":
        url = "document/lna";
        break;
      case "daybreak":
      case "weekday":
      case "optionalholiday":
        url = "document/lna";
        break;
      case "holiday":
        url = "document/lna";
        break;
      case "exceptional":
        url = "document/lna";
        break;
      case "statutory":
        url = "document/lna";
        break;
      case "workday":
        url = "document/lna";
        break;
      case "regularization":
        url = "document/lna";
        break;
      case "rating-self":
        url = "okr/document/rating";
        break;
      case "rating":
      case "interim":
        url = "okr/document";
        break;
      case "logo":
        url = "document"
        break;
      case 'idea':
        url = `${corsallow}${urlConfig.tmlcApiUrl}helpdesk/uploadhelpdeskdocument`;
        break;
      default:
        url = "document/upl";
    }
    return url;
  };

  openDeletePopup = (idx) => {
    this.setState({ deletePopup: true, deleteIdx: idx });
  };

  handleConfirmClose = () => {
    this.setState({ deletePopup: false });
  };

  handleConfirmNo = () => {
    this.setState({ deletePopup: false });
  };

  getcssClsForView = (module) => {
    let cssCls;
    switch (module) { //NOSONAR
      case 'LPOKR':
        cssCls = "upload-document-link-leadership-rating col-4";
        break;
        case 'lna':
        cssCls = "upload-document-link-lna";
        break;
      default:
        cssCls = "upload-document-link col-4";
    }
    return cssCls;
  }

  render() {
    const { hightlight, allowmultiple, deleteIdx, deletePopup } = this.state;
    const { disabled, type, cols, module } = this.props;
    return (
      <>
        <div
          className={`${this.getcssClsForView(module)} ${hightlight ? "Highlight" : ""
            }`}
          onDragOver={this.onDragOver}
          onDragLeave={this.onDragLeave}
          onDrop={this.onDrop}
          onClick={this.openFileDialog}
          onKeyDown={this.openFileDialog}
          style={{
            cursor: disabled ? "default" : "pointer",
            height: type === "interim" || "holiday" ? 40 : 70,
          }}
          role="button"
          tabIndex={0}
        >
          {allowmultiple ? (
            <input
              ref={this.fileInputRef}
              className="FileInput"
              style={{ display: "none" }}
              type="file"
              multiple
              accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"
              onChange={this.onFilesAdded}
              onDragEnd={this.onFilesAdded}
              id="FileInput"
            />
          ) : (
            <input
              ref={this.fileInputRef}
              className="FileInput"
              style={{ display: "none" }}
              type="file"
              accept={this.props.type === 'logo' ? 'image/*' : ".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"}
              onChange={this.onFilesAdded}
              onDragEnd={this.onFilesAdded}
              id="FileInput"
            />
          )}
          <div className="drag-drop-browse"><span style={{ color: '#98989D' }}>Drag & Drop or</span> Browse</div>
        </div>
        <div style={{marginTop:'55px'}}>
          {type !== "leaveapply" &&
            <div id="tags">
              {(type === "rating-self") ? (
                <UploadCommonDocFilepath
                  attachmentFiles={this.props.fileList}
                  filesTimeStamp={this.props.filesTimeStamp}
                  url={`${this.getUrlForView(type)}`}
                  removeFile={this.removeFile}
                  apiUrl={this.props.apiUrl}
                  filesType={type}
                  deleteListPopup={deletePopup}
                  handleConfirmClose={this.handleConfirmClose}
                  handleConfirmNo={this.handleConfirmNo}
                  openDeletePopup={this.openDeletePopup}
                  deleteIdx={deleteIdx}
                  cols={cols}
                />
              ) : (
                <UploadedImageFilepath
                  attachmentFiles={this.props.fileList}
                  url={type === 'idea' ? this.getUrlForView(type) : `${this.getUrlForView(type)}/${type}${this.props.type === 'logo' ? '/' + this.props.authUser : ''}`}
                  removeFile={this.removeFile}
                  apiUrl={this.props.apiUrl}
                  filesType={type}
                  files={this.props.files}
                />
              )}
            </div>
          }
        </div>
      </>
    );
  }
}

UploadDocumentLink.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onFilesAdded: PropTypes.func,
  allowmultiple: PropTypes.bool,
  folderPath: PropTypes.string.isRequired,
};

UploadDocumentLink.defaultProps = {
  allowmultiple: false,
  disabled: false,
  folderPath: "",
};

const mapStateToProps = ({ helpdeskreducer }) => {
  const { uploadDocsLoading } = helpdeskreducer;
  return {
    uploadDocsLoading
  }
}
export default connect(mapStateToProps, { addUpdateHelpDeskRedux })(UploadDocumentLink)
