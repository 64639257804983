/* Author : Vivek Khodade
 * Date : 17-Apr-2020
 * Description: TMLC Employee Profile screen
 */

import React, { Component } from "react";
import { AccordionComponent } from "@syncfusion/ej2-react-navigations";
import queryString from 'query-string';
import { withRouter } from 'react-router-dom';
import NotificationContainer from "../../../../components/notification/notifycontainer";
import EmployeeHeader from "../../../../components/employeeprofileheader/employeeprofileheader";
import BasicInformation from "../../../../components/basicinformation/basicinformation";
import EmployeeProfileTabs from "../../../../components/employeeprofiletabs/employeeprofiletabs";
import { connect } from 'react-redux';
import { closeAgendaPopup } from "../../../other/commonfunction";
import { saveToEnterpriseProfile, updateStatusOnBoardingResucer } from "../../../../redux/actions/entryexit/onboardingaction";
export class EmployeeProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded:true,
      height:500
    };
    this.onCreated = this.onCreated.bind(this);
  }

  onCreated() {
    setTimeout(() => {
      if (this.acrdn !== null) {
        // eslint-disable-next-line
        for (let i = 0; i <= this.acrdn.items.length - 1; i++) {
          if((window.innerHeight ||

            document.documentElement.clientHeight ||

            document.body.clientHeight) > 767){
              this.acrdn.items[i].expanded = true;
            }else{
              this.acrdn.items[i].expanded = false;
            }
          
        }
      }
    }, 500);
  }
  onChange=(args)=>{
    this.setState({ expanded: !this.state.expanded });
  }


  componentDidMount(){
    const { authUser, empid, location } = this.props;
    const queryParams = queryString.parse(location.search); // Parse the query parameters from the URL

    closeAgendaPopup();

    // Check if the 'sync' query parameter is set to 'true'
    if (queryParams.sync === 'true') {
      const sendData = {
        type: "GETPROFILE",
        userId: authUser,
        empId: empid
      };

      this.props.saveToEnterpriseProfile(sendData);
    }
  }
  render() {
    const {expanded} = this.state;
    return (
      <div >
        <EmployeeHeader />
        <div
          style={{ padding: 10, backgroundColor: "#F4F3F5" ,height:window.innerHeight < 768 ? window.innerHeight-106:'',overflowY:'auto'}}
        >
          <AccordionComponent
            ref={(accordion) => {
              this.acrdn = accordion;
            }}
            created={this.onCreated}
            expanded={(args)=>this.onChange(args)}
          >
            <BasicInformation />
          </AccordionComponent>

          {!this.props.openDocumentPopup && !this.props.openDocumentComments && !this.props.open_document_preview && <NotificationContainer />}

          <EmployeeProfileTabs expanded={expanded}/>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ onboardingreducer, auth }) => {
  const { openDocumentPopup, openDocumentComments, open_document_preview, empid, fromOffer } = onboardingreducer
  const { authUser } = auth;

  return {
    openDocumentPopup, openDocumentComments, open_document_preview, authUser, empid, fromOffer
  }
};
export default withRouter(connect(mapStateToProps, { saveToEnterpriseProfile, updateStatusOnBoardingResucer })(EmployeeProfile));



