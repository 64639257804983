import React, { Component } from 'react'
import { ComboBoxComponent } from "@syncfusion/ej2-react-dropdowns";
import { connect } from "react-redux";
import PropTypes, { object } from "prop-types";
import { FormValidator } from "@syncfusion/ej2-inputs";
import OkrProductivityAllocation from './okrproductivityallocation';
import ProgressBar from '../progressbar/progressbar'
import { setCentralOkrKPIRedux, addUpdateObjectveKpi, updateLPVariableLink } from '../../redux/actions/okr/okractions';
import { addUpdateOkrRatingRedux } from '../../redux/actions/okrrating/okrratingaction';
import {
  getNotify, getFinancialYearDate, FrequencyItemTemplate
} from '../../app/other/commonfunction';

import OKRServices from "../../services/okr/okrservices";
import OkrCentral from "../oldokrproductiveallocation/okrcentral";
import OkrLeadership from "../oldokrproductiveallocation/okrleadership";
import OkrCommonFiles from "./okrcommonfiles";
//nosonar import OkrBrand from "../oldokrproductiveallocation/okrbrand";
import BusinessOKR from './businessOKR';
import {
  WARNING, pleaseCompleteProductivityAllocation, pleaseSelectMonthOfReview
} from "../../assets/constants/constants";
import './productiveallocation.css';
import PerformanceScore from '../okrratingcomponents/performancescore';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowUpArrowDown } from "@fortawesome/pro-light-svg-icons";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
var sampleCss = `
.e-accordion .e-acrdn-item.e-select.e-selected.e-expand-state > .e-acrdn-header, .e-accordion .e-acrdn-item.e-acrdn-header {
  background-color: #F4F4F7 !important;
}
.e-acrdn-header{
  background-color: #F4F4F7 !important;
}
.e-control .e-accordion e-lib .e-acrdn-root .e-keyboard{
  background-color: #F4F4F7 !important;
}
.e-accordion .e-acrdn-item.e-select.e-selected.e-expand-state > .e-acrdn-header, .e-accordion .e-acrdn-item.e-select.e-expand-state > .e-acrdn-header {
  background-color:  #F4F4F7 !important;
}
.e-accordion .e-acrdn-item.e-select > .e-acrdn-header {
  background-color:  #F4F4F7 !important;
}
.e-accordion .e-acrdn-item .e-toggle-icon {
  color: #4B4B55 !important;
}
// .e-acrdn-item e-select e-expand-state{
//   background-color: #F4F4F7 !important;
// }
`;
class OKRProductiveAllocationTabs extends Component {
  constructor(props) {
    super(props)

    this.state = {
      expandedBasicInfo: true,
      employeeData: [],
      currentFinancialYear: null,
      showMessage: true,
      productivityallocationData: [],
      height:
        (window.innerHeight ||
          document.documentElement.clientHeight ||
          document.body.clientHeight) - null,
      windowAdjust: null,
      // height:
      //   (window.innerHeight ||
      //     document.documentElement.clientHeight ||
      //     document.body.clientHeight) - 188,
      // windowAdjust: window.innerHeight - 295,
      isLoading: true,
      isLoadPA: true,
      redirect: null,
      loadPA: true,
      loadBusinessOKR: false,
      loadCentralOKR: false,
      loadLPOKR: false,
      loadBVOKR: false,
      loadCFOKR: false,
      enableAction: true,
      monthOfReviewData: [],
      closedMonthOfReviewData: [],
      centralOkrTabClicked: false,
      leaderTabClicked: false,
      bussTabClicked: false,
      documentTabClicked: false,
      performanceScoreData:[],
      showStatus: (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight) > 767 ? false : true,
      documentLength: 0
    };
    this.openTab = this.openTab.bind(this);
    this.setAllVariableLink = this.setAllVariableLink.bind(this);
    this.onChangeTeamMembar = this.onChangeTeamMembar.bind(this);
    this.onChangeFinancialYear = this.onChangeFinancialYear.bind(this);
    this.acrdnbasic = null;
    this.setHeight = (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight) > 767 ? false : true;
  }
  getEmployeeData = () => {
    try {
      const { authUser, OkrMasters } = this.props;
      const userList = OkrMasters.filter((item) => {
        return item.var_list_name === "User"; // && item.Id!==authUser ;
      });
      this.setState({
        employeeData: userList
      })
      this.props.setCentralOkrKPIRedux({ employeeId: authUser, OkrEmployeeMaster: userList });
    } catch (error) {
      // eslint-disable-next-line
      console.error(error);
    }
  }
  getUserWiseProductiveAllocation() { //NOSONAR
    try {
      const { authUser, employeeId } = this.props;
      const { currentFinancialYear } = this.state;
      const userWiseProductivityAllocation = Promise.resolve(
        OKRServices.getUserWiseProductivityAllocation(authUser, employeeId, "ALL", 0, currentFinancialYear)
      );
      userWiseProductivityAllocation.then(async (result) => {
        if (result !== null && typeof result !== 'undefined' && result.length > 0) {
          let showProductiveAllocation = result[0].isSubmitted === "1";
          this.props.setCentralOkrKPIRedux({
            isPrdAllocationDone: result[0].isSubmitted === "1",
          });
          this.setState({ productivityallocationData: result, isLoadPA: false, isLoading: false });
          this.props.addUpdateObjectveKpi({
            businessUserImpact: result[0].userImpactPerc,
            businessUserVariableImpact: result[0].oteVariableImpactPerc,
            ratingMecanismStart: result[0].ratingMechanismPercStart,
            ratingMecanismEnd: result[0].ratingMechanismPercEnd,
            centralUserImpact: result[1].userImpactPerc,
            centralUserVariableImpact: result[1].oteVariableImpactPerc,
            ratingCentralMecanismEnd: result[1].ratingMechanismPercEnd,
            centralUserFrequency: result[1].frequency,
            LPUserVariableImpact: result[2].oteVariableImpactPerc,
            LPUserFrequency: result[2].frequency
          })

          if (!showProductiveAllocation) {
            /**
             * @set the default Tab
             */
            let i;
            const tabcontent = document.getElementsByClassName("tabcontent");
            // eslint-disable-next-line
            for (i = 0; i < tabcontent.length; i++) {
              tabcontent[i].style.display = "none";
            }
            const tablinks = document.getElementsByClassName("tablinks");
            // eslint-disable-next-line
            for (i = 0; i < tablinks.length; i++) {
              tablinks[i].className = tablinks[i].className.replace(" active", "");
            }
            try {
              var element = document.getElementById("ProductiveAllocationTab");
              if (element !== null) {
                element.classList.add("active");
                document.getElementById("ProductiveAllocation").style.display = "block";
              }
            } catch (e) { }
          }


        } else {
          this.data = "error";
          // eslint-disable-next-line
          await this.setState({ showMessage: true, isLoading: false });
          await this.props.setCentralOkrKPIRedux({ isPrdAllocationDone: false });

          /**
           * @set the default Tab
           */
          let i;
          const tabcontent = document.getElementsByClassName("tabcontent");
          // eslint-disable-next-line
          for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].style.display = "none";
          }
          const tablinks = document.getElementsByClassName("tablinks");
          // eslint-disable-next-line
          for (i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace(" active", "");
          }
          try {
            // setTimeout(()=>{
            if (document.getElementById("NoData") === null) {

              // this.setState({showMessage:true})
              // setTimeout(()=>{
              document.getElementById("NoData").style.display = await "block";/**NOSONAR */
              // },1000)
            } else {
              document.getElementById("NoData").style.display = "block";
            }
            // },1000)
          } catch (err) {
          }
        }
      });
    } catch (e) {
      // eslint-disable-next-line
      console.log(e);
    }
  }
  getPeriodOfReview = async () => {
    const { employeeId, authUser, fyid } = this.props;
    const datesObj = {
      fyId: fyid,
      startMonth: null,
      endMonth: null
    }
    const monthOfReview = Promise.resolve(
      OKRServices.getOKRFrequency("PeriodOfReview_A", authUser, employeeId, "1", 0, datesObj)
    );
    await monthOfReview.then((resultData) => {
      this.setState({ monthOfReviewData: resultData.Result });
    })

    const closedMonthOfReview = Promise.resolve(
      OKRServices.getOKRFrequency("PeriodOfReview_C", authUser, employeeId, "1", 0, datesObj)
    );
    await closedMonthOfReview.then((resultData) => {
      this.setState({ closedMonthOfReviewData: resultData.Result });
    })


  }
  componentDidMount = async () => {
    await this.props.setCentralOkrKPIRedux({ fyid: this.props.currentFinancialYearId });
    this.updateDimensions();
    await this.getEmployeeData();/**NOSONAR */
    await this.setState({ currentFinancialYear: this.props.currentFinancialYearId, isLoading: false })
    document.getElementById("NoData").style.display = await "block";/**NOSONAR */
    if (this.props.employeeId !== 0) {
      var customArg = { itemData: { Id: this.props.employeeId } };
      this.onChangeTeamMembar(customArg);
    }
    await this.getPeriodOfReview();
    this.getPerformanceScore();
    this.props.addUpdateOkrRatingRedux({ monthOfRating: 0 })
  };
  //WARNING! To be deprecated in React v17. Use new lifecycle static getDerivedStateFromProps instead.
  componentWillReceiveProps(nextProps) {
    if (nextProps.refreshProdAllocation === 'updated') {
      this.setState({ isLoadPA: true })
      this.getUserWiseProductiveAllocation();
      this.props.addUpdateObjectveKpi({ refreshProdAllocation: '' })
    }
    if (nextProps.lpStatus === 'updated') {
      this.setState({ loadLPOKR: false })
      setTimeout(() => {
        this.setState({ loadLPOKR: true })
      }, 1000)
      this.props.addUpdateObjectveKpi({ lpStatus: '' })
    }
  }
  updateDimensions() {
    const updateheight = window.innerHeight - 206;
    const windowAdjust = window.innerHeight - 240;
    this.setState({
      // width: updatewidth,
      height: updateheight,
      windowAdjust: windowAdjust,
    });
  }
  openTab = (evt, tabName) => {
    const { isPrdAllocationDone } = this.props;
    if (!isPrdAllocationDone && tabName !== "ProductiveAllocation") {
      getNotify(WARNING, pleaseCompleteProductivityAllocation);
      return;
    }

    let i;
    const event = evt;
    const tabcontent = document.getElementsByClassName("tabcontent");
    // eslint-disable-next-line
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }
    const tablinks = document.getElementsByClassName("tablinks");
    // eslint-disable-next-line
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }
    document.getElementById(tabName).style.display = "block";
    event.currentTarget.className += " active";
    let PATab = true; let businessTab = false; let centralTab = false; let LPTab = false; let BVTab = false; let CFTab = false;
    if (tabName === 'ProductiveAllocation') { PATab = true; businessTab = false; centralTab = false; LPTab = false; BVTab = false; CFTab = false /**NOSONAR */ }
    else if (tabName === 'BusinessOKR') { PATab = false; businessTab = true; centralTab = false; LPTab = false; BVTab = false; CFTab = false /**NOSONAR */ }
    else if (tabName === 'CentralOKR') { PATab = false; businessTab = false; centralTab = true; LPTab = false; BVTab = false; CFTab = false /**NOSONAR */ }
    else if (tabName === 'LeadershipPrinciple') { PATab = false; businessTab = false; centralTab = false; LPTab = true; BVTab = false; CFTab = false /**NOSONAR */ }
    else if (tabName === 'BrandValue') { PATab = false; businessTab = false; centralTab = false; LPTab = false; BVTab = true; CFTab = false /**NOSONAR */ }
    else if (tabName === 'CommonFiles') { PATab = false; businessTab = false; centralTab = false; LPTab = false; BVTab = false; CFTab = true /**NOSONAR */ }

    this.setState({ loadPA: PATab, loadBusinessOKR: !businessTab, loadCentralOKR: !centralTab, loadLPOKR: !LPTab, loadBVOKR: BVTab, loadCFOKR: CFTab },()=>this.setState({loadBusinessOKR: businessTab,loadCentralOKR: centralTab, loadLPOKR: LPTab }));
  };
  onChangeTeamMembar(args) {
    const { authUser } = this.props;
    if (args.itemData !== null) {
      var enableActionFlag;
      if (args.itemData.Id === authUser) {
        enableActionFlag = false;
      }
      else {
        enableActionFlag = true;
      }
      try {
        this.props.addUpdateOkrRatingRedux({ empId: args.itemData.Id })
        // eslint-disable-next-line
        this.props.setCentralOkrKPIRedux({ employeeId: args.itemData.Id });
        this.setState({ isLoadPA: true, showMessage: false, loadBusinessOKR: false, loadCentralOKR: false, loadLPOKR: false, loadCFOKR: false }, () => {
          this.setState({ loadBusinessOKR: true, enableAction: enableActionFlag, loadCentralOKR: true, loadLPOKR: true });
        });
        this.getUserWiseProductiveAllocation();
        this.getPeriodOfReview()
        this.getPerformanceScore()
        this.props.addUpdateObjectveKpi({ sortByFlag:false,updateBuDraftFlag: 'updated',reRenderComponent:'updated',closedBusinessOkr:[], closedCentralOkr:[], closedLPOkr:[],
        draftCentralObjectiveKpi:[],activeCentralObjectiveKpi:[], leadershipOKRData:[], activeData:[], draftData:[], leaderShipOkrData:[], draftedBusinessObjKpi:[], submittedBusinessObjective:[],allActiveBusinessObjective:[]  });
      }
      catch (e) {
        console.log('Error is ', e);
      }
    } else {
      const options = {
        rules: {
          teamMemberError: { required: [true, "Please select team member."] },
        },
      };
      setTimeout(() => {
        const addFormObject = new FormValidator(
          "#selectFormInfo",
          options
        );
        addFormObject.validate();
      }, 100);
    }
  }
  onChangeFinancialYear(args) {
    if (args.itemData !== null) {
        // eslint-disable-next-line
        this.props.setCentralOkrKPIRedux({ fyid: args.itemData.Id });
        this.setState({ currentFinancialYear: args.itemData.Id, isLoadPA: true, showMessage: false, loadBusinessOKR: false, loadCentralOKR: false, loadLPOKR: false, loadCFOKR: false }, () => {
          this.setState({ loadBusinessOKR: true, loadCentralOKR: true, loadLPOKR: true });
        });
        this.getUserWiseProductiveAllocation();
        this.getPerformanceScore();
        this.props.addUpdateObjectveKpi({ sortByFlag:false,updateBuDraftFlag: 'updated', reRenderComponent:'updated', selectedFy: args.itemData.Name, closedBusinessOkr:[], closedCentralOkr:[], closedLPOkr:[],
         draftCentralObjectiveKpi:[],activeCentralObjectiveKpi:[], leadershipOKRData:[], activeData:[], draftData:[], leaderShipOkrData:[], draftedBusinessObjKpi:[], submittedBusinessObjective:[],allActiveBusinessObjective:[] });
    } else {
      const options = {
        rules: {
          fyIdrError: { required: [true, "Please select financial year."] },
        },
      };
      setTimeout(() => {/**NOSONAR */
        const addFormObject = new FormValidator(
          "#selectFormInfo",
          options
        );
        addFormObject.validate();
      }, 100);
    }
  }
  onChangeMonthOfReview = (e) => {/**NOSONAR */
    const { allActiveBusinessObjective, selectedFy } = this.props;
    const submittedBusinessObjective = allActiveBusinessObjective;
    let year = selectedFy;
    if (e.itemData !== null) {
      if (e.itemData.Id === 0) {
        this.props.setCentralOkrKPIRedux({ submittedBusinessObjective: submittedBusinessObjective, updateBuActiveFlag: 'updated', monthOfReview: e.itemData.Id });
      } else {
        let submitedActiveObj = [];
        if (submittedBusinessObjective.length > 0) {
          // eslint-disable-next-line
          submittedBusinessObjective.map((item) => {
            if (item.endMonth !== 0) {
              if (getFinancialYearDate(e.itemData.Id, year) >= getFinancialYearDate(item.startMonth, year) && getFinancialYearDate(e.itemData.Id, year) <= getFinancialYearDate(item.endMonth, year)) {
                submitedActiveObj.push(item);
              }
            }
            else {
              if (getFinancialYearDate(e.itemData.Id, year) >= getFinancialYearDate(item.startMonth, year)) {
                submitedActiveObj.push(item)
              }
            }
          })
          this.props.setCentralOkrKPIRedux({ submittedBusinessObjective: submitedActiveObj, updateBuActiveFlag: 'updated', monthOfReview: e.itemData.Id });
        } else {
          this.props.addUpdateObjectveKpi({ monthOfReview: e.itemData, submittedBusinessObjective: [], updateBuActiveFlag: 'updated' })
        }
      }
    } else {
      this.props.addUpdateObjectveKpi({ monthOfReview: e.itemData, submittedBusinessObjective: [], updateBuActiveFlag: 'updated' })
    }
  }


  onChangeClosedMonthOfReview = (e) => {/**NOSONAR */
    if (e.itemData !== null) {
      this.props.addUpdateObjectveKpi({ closedMonthOfReview: e.itemData.Id, closedTabStatus: 'updated' })
      this.props.addUpdateOkrRatingRedux({ monthOfRating: e.itemData.Id })
    } else {
      this.props.addUpdateObjectveKpi({ closedMonthOfReview: e.itemData, closedTabStatus: 'updated' })
    }
  }

  setAllVariableLink = (event) => {
    const { leaderShipOkrData, employeeId } = this.props;
    if (event.itemData !== null) {
      let data = {
        empId: employeeId,
        objectiveId: leaderShipOkrData[0].userObjectiveId,
        objectiveLibId: leaderShipOkrData[0].objectiveId,
        status: event.itemData.Value
      }
      this.props.updateLPVariableLink(data);
    }
  }
  getTeamMemberName = () => {
    const { employeeData } = this.state;
    const { employeeId } = this.props;
    let name = '';
    employeeData.length > 0 &&
      // eslint-disable-next-line
      employeeData.forEach((item) => {
        if (item.Id === parseInt(employeeId, 10)) {
          name = item.var_list_value;
        }
      })
    return name;
  }
  getFinancialYear = () => {
    let financialYear = ""
    const { finacialYearData } = this.props;
    const { currentFinancialYear } = this.state;
    finacialYearData.length &&
      // eslint-disable-next-line
      finacialYearData.map((item) => {
        if (item.Id === currentFinancialYear) {
          financialYear = item.Name
        }
      })
    return financialYear;
  }

  leaderTabClickedFunc = (e) => {
    this.openTab(e, "LeadershipPrinciple")
    this.setState({ leaderTabClicked: true, bussTabClicked: false, centralOkrTabClicked: false, documentTabClicked: false })
  }

  businessOkrClickedFunc = (e) => {
    this.setState({ bussTabClicked: true, leaderTabClicked: false, centralOkrTabClicked: false, documentTabClicked: false })
    this.openTab(e, "BusinessOKR")
  }

  centralOkrClickedFunc = (e) => {
    this.openTab(e, "CentralOKR")
    this.setState({ centralOkrTabClicked: true, bussTabClicked: false, leaderTabClicked: false, documentTabClicked: false })
  }
  documentTabClickedFunc = (e) => {
    this.setState({ centralOkrTabClicked: false, bussTabClicked: false, leaderTabClicked: false, documentTabClicked: true })
    this.openTab(e, "CommonFiles")
  }
  getPerformanceScore = ()=>{
    const {employeeId, fyid}=this.props;    
    const performanceScore = Promise.resolve(
      OKRServices.getOKRPerformanceScore(employeeId,fyid)
    );
    performanceScore.then(async (resultData) => {
        if(resultData!==null){
            this.setState({performanceScoreData:resultData.Result})
        }
    })
  }
  documentLengthHandler = (length) => {
    this.setState({documentLength: length});
  }

  render() { //NOSONAR
    let activeClass = 'active';
    const { employeeId, finacialYearData, monthOfReview, isPrdAllocationDone, okrAllocationTabs, closedMonthOfReview, sortByFlag } = this.props;
    const { height, windowAdjust, employeeData, isLoading, currentFinancialYear, showMessage, productivityallocationData, isLoadPA,
      loadBusinessOKR, loadCentralOKR, loadLPOKR, enableAction, showStatus, monthOfReviewData, closedMonthOfReviewData, performanceScoreData } = this.state;
    let smallResolution = (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight) > 767 ? 0 : -45;
    return (
      <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
        {
          !isLoading ?//NOSONAR
            <>
              <div className='new-productive-allocation-header-section'>
                <style>{sampleCss}</style>
                <div className='row'>
                  <div>
                    {
                      showStatus &&
                      <>
                      <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 ml-xl mb-sm okr-allocation-team-member-name-font' style={{ cursor: 'pointer' }} onClick={() => this.setState({ showStatus: !showStatus })}>
                        <div className='col-lg-4 col-md-4 col-sm-4 col-xs-4'>
                            <div className='col-6 col-lg-6 col-md-6 col-sm-6 col-xs-6  legend-names'>
                                Name : <b>{this.getTeamMemberName()}</b>
                            </div>
                            <div className='col-6 col-lg-6 col-md-6 col-sm-6 col-xs-6  legend-names'>
                                Financial Year : <b>{this.getFinancialYear()}</b> 
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-4 col-sm-4 col-xs-4' >
                            <PerformanceScore performanceScoreData={performanceScoreData}/>
                        </div>
                      </div>
                       </>
                    }
                    <div
                      onClick={() => {
                        this.setHeight = !showStatus;
                        this.setState({ showStatus: !showStatus })
                      }}
                    >
                      {
                        showStatus ? (//NOSONAR
                          <>
                            <span className='okr-allocation-team-member-name-font' style={{ cursor: 'pointer', position: 'absolute', right: 35, top: 25, zIndex: 10 }}>Click here to open &nbsp;&nbsp;</span>
                            <i className="fas fa-chevron-down" style={{ color: '#B7B7BB', cursor: 'pointer', position: 'absolute', right: 30, top: 29, zIndex: 10 }}></i>
                          </>)
                          :
                          (<>
                            <i className="fas fa-chevron-up" style={{ color: '#B7B7BB', cursor: 'pointer', position: 'absolute', right: 30, top: 29, zIndex: 10 }}></i>
                          </>)
                      }
                    </div>
                  </div>
                  {!showStatus &&
                    <form
                      id="selectFormInfo"
                      method="post"
                    >
                      <div className='col-lg-3 col-md-4 col-sm-4 col-xs-4'>
                        {/* <div>Team Member :</div> */}

                        {
                          employeeData.length > 0 ?//NOSONAR
                            <div >
                              <div className="col-xs-6 col-sm-6 col-lg-12 col-md-6  ">
                                <ComboBoxComponent
                                  allowCustom={false}
                                  floatLabelType="Auto"
                                  fields={{ value: "Id", text: "var_list_value", groupBy: 'var_optional1' }}
                                  dataSource={employeeData}
                                  value={parseInt(employeeId, 10)}
                                  change={this.onChangeTeamMembar}
                                  allowFiltering={true}
                                  name={`teamMemberError`}
                                  id={`teamMember`}
                                  data-msg-containerid={`teamMemberError`}
                                  cssClass="e-outline"
                                  placeholder="Team Member *"
                                  showClearButton={false}
                                />
                              </div>
                            </div>
                            :
                            <div >
                              <div className="col-xs-6 col-sm-6 col-lg-12 col-md-6  ">
                                <ComboBoxComponent
                                  allowCustom={false}
                                  floatLabelType="Auto"
                                  fields={{ value: "EmployeeId", text: "EmployeeName" }}
                                  // dataSource={employeeData}
                                  // value={parseInt(employeeId,10)}
                                  change={this.onChangeTeamMembar}
                                  name={`teamMemberError`}
                                  id={`teamMember`}
                                  data-msg-containerid={`teamMemberError`}
                                  cssClass="e-outline"
                                  placeholder="Team Member *"
                                  allowFiltering={true}
                                />
                              </div>
                            </div>
                        }
                      </div>
                      <div className='col-lg-2 col-md-4 col-sm-12 col-xs-12'>
                        {/* <div>Financial Year :</div> */}
                        <div>
                          {finacialYearData.length > 0 ?//NOSONAR
                            <div>
                              <div className="col-xs-2 col-sm-2 col-lg-12 col-md-2  ">
                                <ComboBoxComponent
                                  allowCustom={false}
                                  floatLabelType="Auto"
                                  fields={{ value: "Id", text: "Name" }}
                                  dataSource={finacialYearData}
                                  // id="basicsalary"
                                  value={currentFinancialYear}
                                  change={this.onChangeFinancialYear}
                                  // value={fyid}
                                  name={`fyIdrError`}
                                  id={`fyId`}
                                  data-msg-containerid={`fyIdrError`}
                                  cssClass="e-outline"
                                  placeholder="Financial Year  *"
                                  showClearButton={false}
                                />
                                <div
                                  id="fyIdrError"
                                  className="e-input-group e-float-icon-left error-div"
                                />
                              </div>
                            </div>
                            :
                            <div>
                              <div className="col-xs-2 col-sm-2 col-lg-12 col-md-2  ">
                                <ComboBoxComponent
                                  allowCustom={false}
                                  floatLabelType="Auto"
                                  fields={{ value: "Id", text: "Name" }}
                                  // dataSource={finacialYearData}
                                  // id="basicsalary"
                                  // value={currentFinancialYear}
                                  // change={this.onChangeFinancialYear}
                                  // value={fyid}
                                  name={`fyIdrError`}
                                  id={`fyId`}
                                  data-msg-containerid={`fyIdrError`}
                                  cssClass="e-outline"
                                  placeholder="Financial Year  *"
                                />
                                <div
                                  id="fyIdrError"
                                  className="e-input-group e-float-icon-left error-div"
                                />
                              </div>
                            </div>
                          }
                        </div>
                      </div>
                      <div className='col-lg-4 col-md-4 col-sm-4 col-xs-4 pt-xs'>
                       <PerformanceScore performanceScoreData={performanceScoreData}/>
                      </div>
                    </form>
                  }
                </div>
                {/* nosonar <div className='row'>
                  <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 basic-info-padding'>
                    <style>{sampleCss}</style>
                    {!isLoadPA &&
                      <AccordionComponent
                        id="basicInfoAccordion"
                        ref={(accordion) => {
                          this.acrdnbasic = accordion;
                        }}
                        expanded={this.expandAccordion}
                      >
                        <OKREmployeeInfo />
                      </AccordionComponent>
                    }
                  </div>
                </div> */}
              </div>
              <div className='new-productive-allocation-center-section'>
                <div className='row'>
                  <div className="tab col-12 center-box-padding">
                    <div className="tabs-div-width">
                      <button
                        type="submit"
                        id='ProductiveAllocationTab'
                        className={`tablinks tab-button-width-new-production-allocation ${!showMessage ? /**NOSONAR */activeClass : ''}`}
                        onClick={(e) => this.openTab(e, "ProductiveAllocation")}
                        disabled={!showMessage ?/**NOSONAR */ false : true}
                      >
                        OKR Weightage Summary
                      </button>
                      <button
                        type="submit"
                        className="tablinks tab-button-width-new-production-allocation"
                        onClick={this.businessOkrClickedFunc}
                        disabled={!showMessage ?/**NOSONAR */ false : true}
                        style={{ cursor: !isPrdAllocationDone ?/**NOSONAR */ 'not-allowed' : 'pointer' }}
                      >
                        Business OKR
                      </button>
                      <button
                        type="submit"
                        className="tablinks tab-button-width-new-production-allocation"
                        onClick={this.centralOkrClickedFunc}
                        disabled={!showMessage ?/**NOSONAR */ false : true}
                        style={{ cursor: !isPrdAllocationDone ?/**NOSONAR */ 'not-allowed' : 'pointer' }}
                      >
                        Central OKR
                      </button>
                      <button
                        type="submit"
                        className="tablinks tab-button-width-new-production-allocation"
                        onClick={this.leaderTabClickedFunc}
                        disabled={!showMessage ?/**NOSONAR */ false : true}
                        style={{ cursor: !isPrdAllocationDone ?/**NOSONAR */ 'not-allowed' : 'pointer' }}
                      >
                        Leadership Principles
                      </button>
                      <button
                        type="submit"
                        className="tablinks tab-button-width-new-production-allocation"
                        onClick={this.documentTabClickedFunc}
                        disabled={!showMessage ?/**NOSONAR */ false : true}
                        style={{ cursor: !isPrdAllocationDone ?/**NOSONAR */ 'not-allowed' : 'pointer' }}
                      >
                        Document <span id='ratingCommonDocOkrCount'></span>
                      </button>
                      {/* <button
                        type="submit"
                        className="tablinks tab-button-width-new-production-allocation"
                        disabled={true}
                        style={{ cursor: 'not-allowed' }}
                      >
                        <span style={{ color: 'transparent' }}>.</span>
                      </button>
                      <button
                        type="submit"
                        className="tablinks tab-button-width-new-production-allocation"
                        disabled={true}
                        style={{ cursor: 'default' }}
                      >
                        <span style={{ color: 'transparent' }}>.</span>
                      </button> */}
                    </div>
                  </div>
                  {
                    !showMessage ?//NOSONAR
                      <>
                        <div
                          style={{
                            minHeight: this.setHeight === true ? height + smallResolution : windowAdjust + smallResolution,
                            overflowY: "hidden",
                            overflowX: "hidden",
                            borderRadius: 6,
                          }}
                          id="ProductiveAllocation"
                          className="tabcontent center-box-padding"
                        >
                          {
                            !isLoadPA ? //NOSONAR
                              <OkrProductivityAllocation productivityallocationData={productivityallocationData} parentHeight={height} windowAdjust={windowAdjust} setHeight={this.setHeight} enableAction={enableAction} />
                              :
                              <div style={{ marginTop: '10%' }}>
                                <ProgressBar />
                              </div>
                          }
                        </div>
                        <div style={{ minHeight: this.setHeight === true ? height : windowAdjust, height: this.setHeight === true ? height + smallResolution : windowAdjust + smallResolution, overflowY: "hidden", overflowX: "hidden", borderRadius: 6 }} id="BusinessOKR" className="tabcontent center-box-padding" >
                          <div>
                            <div className="col-xs-12 col-sm-12 col-lg-12" style={{ padding: "8px 4px 0px 4px" }}>
                              <span className="bussiness-okr-word" style={{ padding: "0px 32px 0px 8px" }}>Business OKR</span>
                              {/* <span className="kpi-footer-details" style={{ margin: "0px 0px 0px -32px",position:"absolute" }}><Tooltips titleMessage={businessOkrDetails} /></span> */}
                              <span className="bussiness-okr-productive-data" style={{ paddingLeft: "12px" }}>Business OKR Weightage (%) :</span>
                              <span className="kpi-footer-details" style={{ padding: "0px 32px 0px 4px" }}>{productivityallocationData.length > 0 && `${productivityallocationData[0].userImpactPerc}%`}</span>
                              <span className="bussiness-okr-productive-data">Impact on Variable Pay (%):</span>
                              <span className="kpi-footer-details" style={{ padding: "0px 32px 0px 4px" }}>{productivityallocationData.length > 0 && `${productivityallocationData[0].oteVariableImpactPerc}%`}</span>
                              {(okrAllocationTabs === 'active') &&
                                <span>
                                  <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2 " style={{ float: 'right', width: '220px', }}>
                                    <ComboBoxComponent
                                      style={{ height: 32 }}
                                      allowCustom={false}
                                      floatLabelType="Auto"
                                      fields={{ value: "Id", text: "Name" }}
                                      dataSource={monthOfReviewData}
                                      value={monthOfReview}
                                      change={(e) => this.onChangeMonthOfReview(e)}
                                      name={`fyIdrError`}
                                      id={`fyId`}
                                      data-msg-containerid={`fyIdrError`}
                                      cssClass="e-outline"
                                      itemTemplate={FrequencyItemTemplate}
                                      placeholder="Period of Review *"
                                    />
                                    {
                                      monthOfReview === null &&
                                      <div
                                        id="fyIdrError"
                                        className="e-input-group e-float-icon-left error-div">{pleaseSelectMonthOfReview}</div>
                                    }
                                  </div>
                                  <div className="mt-sm" style={{ float: 'right'}}>
                                  <TooltipComponent
                                    closeOn="Click"
                                    content={!sortByFlag ? "Click to enable the OKR objective(s) sorting.":"Click to disable the OKR objective(s) sorting."}
                                    position="BottomCenter"
                                    isSticky={false}
                                    cssClass='customtooltip'
                                    style={{ fontWeight: 500 }}
                                  >   
                                  {/* <span className='mb-sm'>Sort By:</span> */}
                                      <FontAwesomeIcon
                                                className="mr-sm"
                                                id="pointer"
                                                icon={faArrowUpArrowDown}
                                                onClick={ ()=>this.props.setCentralOkrKPIRedux({sortByFlag:!sortByFlag})}
                                                style={{ cursor: "pointer", fontSize: 20, marginLeft: "4px", color: sortByFlag ? "red":'gray' }}
                                              />
                                     </TooltipComponent>
                                      {/* <SwitchComponent
                                                id="sortByFlag"
                                                // ref={(scope) => {
                                                //   this.asOnSheduled = scope;
                                                // }}
                                                checked={sortByFlag}
                                                change={(event)=>{ 
                                                  this.props.setCentralOkrKPIRedux({sortByFlag:event.checked})
                                                }}
                                              />{" "} */}
                                  </div>
                                </span>
                              }
                              {(okrAllocationTabs === 'closed') &&
                                <span>
                                  <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2 " style={{ float: 'right', width: '220px', }}>
                                    <ComboBoxComponent
                                      style={{ height: 32 }}
                                      allowCustom={false}
                                      floatLabelType="Auto"
                                      fields={{ value: "Id", text: "Name" }}
                                      dataSource={closedMonthOfReviewData}
                                      value={closedMonthOfReview}
                                      change={(e) => this.onChangeClosedMonthOfReview(e)}
                                      name={`fyIdrError`}
                                      id={`fyId`}
                                      data-msg-containerid={`fyIdrError`}
                                      itemTemplate={FrequencyItemTemplate}
                                      cssClass="e-outline"
                                      placeholder="Period of Review *"
                                    />
                                    {
                                      closedMonthOfReview === null &&
                                      <div
                                        id="fyIdrError"
                                        className="e-input-group e-float-icon-left error-div">{pleaseSelectMonthOfReview}</div>
                                    }
                                  </div>
                                </span>
                              }
                            </div>
                            <div>
                              {this.state.bussTabClicked && loadBusinessOKR &&
                                <BusinessOKR setHeightFlag={this.setHeight} windowAdjust={windowAdjust} enableAction={enableAction} />
                              }
                            </div>
                          </div>
                        </div>
                        <div style={{ minHeight: this.setHeight === true ? height + smallResolution : windowAdjust + smallResolution, overflowY: "hidden", overflowX: "hidden", borderRadius: 6 }} id="CentralOKR" className="tabcontent center-box-padding" >
                          {this.state.centralOkrTabClicked && loadCentralOKR && // employeeId > 0 && isPrdAllocationDone && 
                            <OkrCentral setHeightFlag={this.setHeight} windowAdjust={windowAdjust} enableAction={enableAction} />
                          }
                        </div>
                        <div style={{ minHeight: this.setHeight === true ? height + smallResolution : windowAdjust + smallResolution, overflowY: "hidden", overflowX: "hidden", borderRadius: 6 }} id="LeadershipPrinciple" className="tabcontent center-box-padding" >
                          {/* Nosonar {leaderShipOkrData.length > 0 && LPUserVariableImpact > 0 &&
                                <span>
                                  <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2 pt-sm" style={{ float: 'right', width: '194px',}}>
                                    <ComboBoxComponent
                                      style={{height:32 }}
                                      allowCustom={false}
                                      floatLabelType="Auto"
                                      fields={{ value: "Id", text: "Name" }}
                                      dataSource={[{Id:0, Name:"No", Value:'0'},{Id:1, Name:"Yes", Value:'1'}]}
                                      data-msg-containerid={`fyIdrError`}
                                      cssClass="e-outline"
                                      placeholder="Variable Link *"
                                      showClearButton={false}
                                      change={(e) => this.setAllVariableLink(e)}
                                    />
                                  </div>
                                </span>
                        } */}
                          {this.state.leaderTabClicked && loadLPOKR && // employeeId > 0 && isPrdAllocationDone &&
                            <OkrLeadership setHeightFlag={this.setHeight} windowAdjust={windowAdjust} enableAction={enableAction} />
                          }
                        </div>
                        {/* <div style={{ minHeight: this.setHeight === true ? height : windowAdjust, overflowY: "hidden", overflowX: "hidden", borderRadius: 6 }} id="BrandValue" className="tabcontent center-box-padding" >
                          {loadBVOKR &&
                            <OkrBrand setHeightFlag={this.setHeight} windowAdjust={windowAdjust} enableAction={enableAction} />
                          }
                        </div> */}
                        <div style={{ minHeight: this.setHeight === true ? height + smallResolution : windowAdjust + smallResolution, overflowX: "hidden", borderRadius: 6, padding: 0 }} id="CommonFiles" className="tabcontent center-box-padding" >
                          {/* {loadCFOKR && this.state.documentTabClicked && */}
                            <OkrCommonFiles
                              windowAdjust={this.setHeight === true ? height + smallResolution : windowAdjust + smallResolution}
                              documentLengthHandler={this.documentLengthHandler}
                            />
                          {/* } */}
                        </div>
                      </>
                      :
                      <>
                        <div
                          id='NoData'
                          style={{
                            minHeight: this.setHeight === true ? height : windowAdjust + 35,
                            overflowY: "hidden",
                            overflowX: "hidden",
                            borderRadius: 6
                          }}
                          className="tabcontent center-box-padding"
                        >
                          <div style={{ marginTop: '8%' }}>
                            {/* <div className='new-productivity-allocation-no-data-header'>No Items</div>
                            <div className='new-productivity-allocation-no-data-sub-header pt-lg'>You don't have any items here. Select employee</div>
                            <div className='new-productivity-allocation-no-data-sub-header'>and financial year to continue.</div> */}
                            <div className='new-productivity-allocation-no-data-header'>Welcome! This is where your performance journey begins!</div>
                            <div className='new-productivity-allocation-no-data-sub-header pt-lg'>Please select a Team-member and Financial Year to add an OKR</div>
                            {/* <div className='new-productivity-allocation-no-data-sub-header pt-lg'>Please select your team member & FY</div> */}
                            {/* <div className='new-productivity-allocation-no-data-sub-header pt-sm'>Once u select the team member & FY you will be required to allocate Weightage and import Business OKR</div>
                            <div className='new-productivity-allocation-no-data-sub-header pt-sm'>{'Default allocation of Central OKR,  Leadership Principles and Brand Values.'} </div>
                            <div className='new-productivity-allocation-no-data-sub-header pt-sm'>{'Note - Schematic process flow for OKR Input'} </div> */}
                          </div>
                        </div>
                      </>
                  }
                </div>
              </div>
            </>
            :
            <div style={{ marginTop: '40%' }}>
              <ProgressBar />
            </div>
        }
      </div>
    )
  }
}
OKRProductiveAllocationTabs.propTypes = {
  authUser: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  employeeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  monthOfReview: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  closedMonthOfReview: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  finacialYearData: PropTypes.arrayOf(object),
  isPrdAllocationDone: PropTypes.bool,
  activeTabFlag: PropTypes.bool,
  refreshProdAllocation: PropTypes.string,
  LPUserVariableImpact: PropTypes.number,
};
OKRProductiveAllocationTabs.defaultProps = {
  authUser: "",
  employeeId: "",
  finacialYearData: [],
  isPrdAllocationDone: false,
  monthOfReview: 0,
  activeTabFlag: false,
  refreshProdAllocation: "",
  LPUserVariableImpact: 0,
  closedMonthOfReview: ""
};
const mapStateToProps = ({ auth, okrreducer, meetingmaster }) => {
  const { authUser } = auth;
  const { currentFinancialYearId } = meetingmaster;
  const { employeeId, fillObjective, isPrdAllocationDone, fyid, monthOfReview, allActiveBusinessObjective,
    activeTabFlag, selectedFy, OkrMasters, refreshProdAllocation, leaderShipOkrData, lpStatus, LPUserVariableImpact, okrAllocationTabs, closedMonthOfReview, sortByFlag } = okrreducer;
  const { finacialYearData } = meetingmaster;
  return {
    authUser, fillObjective, employeeId, finacialYearData, isPrdAllocationDone, fyid, monthOfReview, allActiveBusinessObjective,
    activeTabFlag, selectedFy, OkrMasters, refreshProdAllocation, leaderShipOkrData, lpStatus, LPUserVariableImpact, okrAllocationTabs, closedMonthOfReview, currentFinancialYearId,sortByFlag
  };
};
export default connect(mapStateToProps, { setCentralOkrKPIRedux, addUpdateObjectveKpi, updateLPVariableLink, addUpdateOkrRatingRedux })(
  OKRProductiveAllocationTabs
);
