import * as React from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import {
  TextBoxComponent,
  // NumericTextBoxComponent,
} from "@syncfusion/ej2-react-inputs";
import "./preoffer.css";
import { faUser, faIdCard } from "@fortawesome/pro-light-svg-icons";
import PropTypes from "prop-types";
import {
  ComboBoxComponent,
  AutoCompleteComponent,
} from "@syncfusion/ej2-react-dropdowns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { connect } from "react-redux";
import { Query } from "@syncfusion/ej2-data";
import TextField from "@material-ui/core/TextField";
import { FormValidator } from "@syncfusion/ej2-inputs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo, faBinoculars } from "@fortawesome/pro-light-svg-icons";
import { saveBasicInformationInRedux } from "../../redux/actions/entryexit/onboardingaction";
import OnbordingToBeOffer from "../../services/entryexit/onbordingServices";
import { urlConfig, corsallow, maxFileSize } from "../../config/config";
import { getNotify, addDays,encryptMethod } from "../../app/other/commonfunction";
import {
  ERROR,
  pleaseEnterFirstName,
  pleaseEnterLastName,
  pleaseEnterMobileName,
  pleaseEnterEmailName,
  pleaseEnterValidEmailId,
  pleaseSelectHighestEducationQualification,
  // pleaseEnterHighestEducationQualificationSpecial,
  // please_enter_work_experiance,
  pleaseEnterAddress,
  pleaseEnterJobTitle,
  pleaseEnterValidRole,
  pleaseSelectBand,
  pleaseSelectEmployeeType,
  pleaseSelectBaseCountry,
  // pleaseEnterBaseLocation,
  pleaseSelectEntity,
  // pleaseSelectServceDivisionSalesUnit,
  pleaseSelectReportingSupervisor,
  pleaseSelectRecruitmentManager,
  pleaseSelectGender,
  pleaseSelectSalutation,
  pleaseSelectServceDivisionSalesUnit,
  pleaseEnterState,
  pleaseEnterCity,
  pleaseSelectCurrency,
  pleaseSelectCTCFrequency,
  // pleaseSelectBirthDate,
  // pleaseSelectJoiningDate,
  FileType
} from "../../assets/constants/constants";

library.add(faUser, faIdCard);

class PreOfferBasicInformation extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      imagePath: "",
      changedData: false,
      salesUnit: [{ Id: 0, Name: "" }],
      reportingManager: [{ Id: 0, Name: "" }],
      recruitmentManager: 0,
      baseCountryChangeFlag: false,
      practiceAreaData: [{ Id: 0, Name: "" }],
      layerData: [{ Id: 0, Name: "" }],
      subLayerData: [{ Id: 0, Name: "" }],
      subFunctions: [{ Id: 0, Name: "" }],
      dataPopulated: true,
      recruiterName: null,
      referralName: null,
      reportingSup: null,
    };
    this.onFiltering = this.onFiltering.bind(this);
    this.getSalesUnitOnChange = this.getSalesUnitOnChange.bind(this);
    this.getReportingManager = this.getReportingManager.bind(this);
    this.dropdownItemTemplate = this.dropdownItemTemplate.bind(this)
    this.referralDropdownItemTemplate = this.referralDropdownItemTemplate.bind(this)
    this.jobTitle = {};
  }

  componentDidMount() {

    setTimeout(() => {
      this.getMasterData()
      this.getMasterDropdownData('businesspnl', 0, true);
    }, 1000);

    setTimeout(() => {
      const { title } = this.props;
      try {
        if (this.jobTitle) {
          this.jobTitle.text = title;
        }
      } catch (err) {
        console.log(err)
      }
    }, 5000);
  }

  getMasterData = () => {
    const { candidateid } = this.props;
    if (candidateid > 0) {
      this.setState({ dataPopulated: false })
      const { authUser } = this.props;
      const getMasterData = Promise.resolve(
        OnbordingToBeOffer.getMasterData("CandDRP", authUser, candidateid)
      );
      getMasterData.then((resultData) => {
        if (resultData !== null) {
          const SDSU = resultData.filter((item) => { return item.Type === "SD"; });
          const layerData = resultData.filter((item) => { return item.Type === "Layer"; });
          const subLayerData = resultData.filter((item) => { return item.Type === "SubLayer"; });
          const reportingManagerData = resultData.filter((item) => { return item.Type === "Rep"; });
          const subFunctions = resultData.filter((item) => { return item.Type === "SubFunction"; });
          this.setState({
            practiceAreaData: SDSU, layerData: layerData, subLayerData: subLayerData, reportingManager: reportingManagerData, subFunctions
          });
          setTimeout(() => {
            this.getCandidateInformation();
          }, 10)
        }
      });
    }
  }
  // eslint-disable-next-line
  UNSAFE_componentWillReceiveProps(newProps) {
    const { baseCountryChangeFlag, dataPopulated } = this.state;
    if (newProps.status === "Refresh" && dataPopulated) {
      if (baseCountryChangeFlag) {
        // eslint-disable-next-line
        this.props.saveBasicInformationInRedux({ status: "" });
        this.setState({ baseCountryChangeFlag: false });
      }
      else {
        // eslint-disable-next-line
        this.props.saveBasicInformationInRedux({ status: "" });
        this.setState({
          salesUnit: [{ Id: 0, Name: "" }],
          reportingManager: [{ Id: 0, Name: "" }],
        });
      }
    }
  }

  /**
   * shouldComponentUpdate
   * @param {*} nextProps
   * @param {*} nextState
   * This funcion is manage the rerender of this component using change data state.
   */
  // shouldComponentUpdate(nextProps, nextState) {
  //     if (this.state.changedData !== nextState.changedData) {
  //         return true
  //     }
  //     else {
  //         return false
  //     }
  // }
  getCandidateInformation() { // NOSONAR
    const { candidateid, authUser } = this.props;
    if (candidateid > 0) {
      try {//NOSONAR
        const documentDetails = OnbordingToBeOffer.getCandidateInformation(candidateid, authUser, 1);
        documentDetails
          .then((result) => {
            const data = {
              salutation: parseInt(result.fk_salutation_id),
              firstName: result.var_cand_first_name,
              middleName: result.var_cand_middle_name,
              lastName: result.var_cand_last_name,
              gender: result.fk_gender_id,
              mobileNumber: result.var_mobile_no,
              alternetNumber: result.var_alt_mobile_no,
              emailId: result.var_email_address,
              panNumber: result.var_pan_number,
              adharNumber: result.var_aadhar_number,
              uanNumber: result.var_uan_number,
              highestEducationQualification: result.fk_highest_education_qualification_id,
              highestEducationQualificationSpecial: result.var_highest_education_qualificatio_special,
              workExperiance: result.dec_work_experience,
              title: result.var_job_title,
              role: result.fk_role_id,
              band: result.fk_grade_id,
              empType: result.fk_employee_type,
              baseCounty: result.fk_base_country_id,
              baseLocation: result.var_base_location,
              entity: result.fk_pnl_id,
              reportingSupervisor: result.fk_reporting_to_id,
              recruitmentManagerId: result.fk_recruitment_manager_id,
              costSharing: parseFloat(result.dec_cost_sharing),
              address: result.txt_contact_address,
              address2: result.var_address_2 === null ? "" : result.var_address_2,
              pincode: result.var_pincode,
              city: result.var_city,
              state: result.var_address_state_nm,
              dateOfBirth: Date.parse(new Date(result.dt_date_of_birth)) / 1000,
              readyToJoinDate: Date.parse(new Date(result.dt_joining_date)) / 1000,
              imageName: result.var_photo_nm,
              servceDivisionSalesUnit: result.fk_department_id,
              roleName: result.Role,
              other:
                result.var_other_identity === null
                  ? ""
                  : result.var_other_identity,
              persCountryCode:
                result.var_cc_mobile_no === null ? "" : result.var_cc_mobile_no,
              altCountryCode:
                result.var_cc_alt_mobile_no === null
                  ? ""
                  : result.var_cc_alt_mobile_no,
              countryType: result.countryType,
              functionId: result.function_id,
              legalName: result.var_legal_name,
              empid: result.empId === null ? 0 : result.empId,
              sourceCategory: result.fk_source_category_id,
              jobPortalId: result.fk_source_details_job_poratl_id,
              referralId: result.fk_source_details_referral_id,
              agencyName: result.var_source_details_agency,
              tctc: result.int_total_ctc,
              oti: result.int_on_target_incentive,
              fixedCTC: result.int_fixed_ctc,
              layerId: result.fk_layer_id,
              subLayerId: result.fk_sub_layer_id,
              addressCountryId: result.fk_address_country_id,
              tenureEndDate: (result.dt_tenure_end_date === null ? null : Date.parse(new Date(result.dt_tenure_end_date)) / 1000),
              currencyId: result.fk_currency_id,
              ctcFrequency: result.enum_ctc_frequency,
              currency: result.CurrencyName,
              subFunction: result.fk_sub_pnl_id,
              docsSubmitted: result.enum_doc_submitted,
              docLinkForExternal: result.docLinkForExternal,
              recruiterName: result.var_recruiter_name,
              referralName: result.var_referral,
              reportingSup: result.var_reporting_sup,
            };
            // eslint-disable-next-line
            if (this.jobTitle) {
              this.jobTitle.text = result.var_job_title;
            }
            this.props.saveBasicInformationInRedux(data);
            setTimeout(() => {
              this.setState({ dataPopulated: true })
            }, 500)
          })
          .catch((error) => {
            // eslint-disable-next-line
            console.log(error);
          });
      } catch (err) {
        // eslint-disable-next-line
        console.log(err);
      }
    }
  }

  getJobRole = (e) => {
    const { authUser } = this.props;
    try {//NOSONAR
      const getMasterData = Promise.resolve(
        OnbordingToBeOffer.getMasterData("JR", authUser, e.text)
      );
      getMasterData.then((resultData) => {

        if (resultData !== null) {
          let query = new Query();

          query =
            e.text !== ""
              ? query.where("Name", "contains", e.text, true)
              : query;
          e.updateData(resultData, query);
        } else {
          this.data = "error";
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  // get City and state list
  getCityState = (e) => { // NOSONAR
    const { baseCounty, countryType } = this.props;
    let countryCode = "";
    try {
      if (baseCounty !== "") {
        if (countryType === "India") {
          countryCode = "IN";
        }
        if (baseCounty === 650) {
          countryCode = "GB";
        }
        if (baseCounty === 651) {
          countryCode = "US";
        }
        if (baseCounty === 648) {
          countryCode = "NL";
        }
        if (e.value.length > 5 && countryCode === "IN") {
          const getCityState = Promise.resolve(OnbordingToBeOffer.getCityState(e.value, countryCode));
          getCityState.then((resultData) => {
            if (
              resultData.data.records !== undefined &&
              resultData.data.records !== null
            ) {
              if (resultData.data.records[0] !== undefined) {
                if (resultData.data.records[0].fields.country_code === "IN") {
                  this.props.saveBasicInformationInRedux({
                    city:
                      resultData.data.records[0] !== undefined
                        ? resultData.data.records[0].fields.admin_name2
                        : "NA",
                    state:
                      resultData.data.records[0] !== undefined
                        ? resultData.data.records[0].fields.admin_name1
                        : "NA",
                  });
                } else {
                  this.props.saveBasicInformationInRedux({
                    city: "",
                    state: "",
                  });
                }
              } else {
                if (resultData.data.records[0] === undefined) {
                  const options1 = {
                    rules: {
                      pincodeErr: {
                        required: [true, "Please enter valid pin/zip code"],
                        regex: [
                          "^[a-zA-Z0-9_+&*-]+(?:\\." +
                          "[a-zA-Z0-9_+&*-]+)*@" +
                          "(?:[a-zA-Z0-9-]+\\.)+[a-z" +
                          "A-Z]{2,7}$",
                          "Please enter valid code",
                        ],
                        maxLength: [6, "Please enter valid pin/zip code"],
                      },
                    },
                  };
                  setTimeout(() => {
                    const addFormObject = new FormValidator(
                      "#addCandidateFormInfo",
                      options1
                    );
                    if (!addFormObject.validate())
                      this.props.saveBasicInformationInRedux({
                        city: "",
                        state: "",
                      });
                  }, 100);
                }
                const options = {
                  rules: {
                    pincodeErr: {
                      required: [true, "Please enter valid pin/zip code"],
                      regex: [
                        "^[1-9][0-9]{5}$",
                        "Please enter valid pin/zip code",
                      ],
                    },
                  },
                };
                setTimeout(() => {
                  const addFormObject = new FormValidator(
                    "#addCandidateFormInfo",
                    options
                  );
                  if (!addFormObject.validate())
                    this.props.saveBasicInformationInRedux({
                      city: "",
                      state: "",
                    });
                }, 100);
              }
            } else {
              this.props.saveBasicInformationInRedux({ city: "", state: "" });
            }
          });
        } else if (e.value.length > 2) {
          const getCityStateOtherCountry = Promise.resolve(
            OnbordingToBeOffer.getCityState(e.value, countryCode)
          );
          getCityStateOtherCountry.then((resultData) => {
            if (
              resultData.data.records !== undefined &&
              resultData.data.records !== null
            ) {
              if (resultData.data.records[0] !== undefined) {
                if (
                  resultData.data.records[0].fields.country_code === "NL" ||
                  resultData.data.records[0].fields.country_code === "GB" ||
                  resultData.data.records[0].fields.country_code === "US"
                ) {
                  this.props.saveBasicInformationInRedux({
                    city:
                      resultData.data.records[0] !== undefined
                        ? resultData.data.records[0].fields.admin_name2
                        : "NA",
                    state:
                      resultData.data.records[0] !== undefined
                        ? resultData.data.records[0].fields.admin_name1
                        : "NA",
                  });
                } else {
                  this.props.saveBasicInformationInRedux({
                    city: "",
                    state: "",
                  });
                }
              } else {
                if (resultData.data.records[0] === undefined) {
                  const options2 = {
                    rules: {
                      pincodeErr: {
                        required: [true, "Please enter valid pin/zip code"],
                        regex: [
                          "^[a-zA-Z0-9_+&*-]+(?:\\." +
                          "[a-zA-Z0-9_+&*-]+)*@" +
                          "(?:[a-zA-Z0-9-]+\\.)+[a-z" +
                          "A-Z]{2,7}$",
                          "Please enter valid pin/zip code",
                        ],
                        maxLength: [6, "Please enter valid pin/zip code"],
                      },
                    },
                  };
                  setTimeout(() => {
                    const addFormObject = new FormValidator(
                      "#addCandidateFormInfo",
                      options2
                    );
                    if (!addFormObject.validate())
                      this.props.saveBasicInformationInRedux({
                        city: "",
                        state: "",
                      });
                  }, 100);
                }
                const options3 = {
                  rules: {
                    pincodeErr: {
                      maxLength: [5, "Please enter valid pin/zip code"],
                      minLength: [3, "Please enter valid pin/zip code"],
                    },
                  },
                };
                setTimeout(() => {
                  const addFormObject = new FormValidator(
                    "#addCandidateFormInfo",
                    options3
                  );
                  if (!addFormObject.validate())
                    this.props.saveBasicInformationInRedux({
                      city: "",
                      state: "",
                    });
                }, 100);
              }
            } else {
              this.props.saveBasicInformationInRedux({ city: "", state: "" });
            }
          });
        } else {
          this.props.saveBasicInformationInRedux({ city: "", state: "" });
          const options = {
            rules: {
              pincodeErr: {
                maxLength: [5, "Please enter valid pin/zip code"],
                minLength: [3, "Please enter valid pin/zip code"],
              },
            },
          };
          setTimeout(() => {
            const addFormObject = new FormValidator(
              "#addCandidateFormInfo",
              options
            );
            addFormObject.validate();
          }, 100);
        }
      } else {
        const options = {
          rules: {
            basecountryErr: {
              required: [true, "Please select country"],
            },
          },
        };
        setTimeout(() => { // NOSONAR
          const addFormObject = new FormValidator(
            "#addCandidateFormInfo",
            options
          );
          addFormObject.validate();
        }, 100);
      }
    } catch (error) {
      console.log(error);
    }
  };
  showDateError = (e) => {
    console.log('showDateError Error ', e);
  }

  getBasicInFormation = (e, type) => { // NOSONAR
    let options = {};
    const { dataPopulated } = this.state
    switch (type) {
      case "FirstName":
        if (e.value !== "") {
          let firstName = e.value.toLowerCase().split(" ");
          for (let i = 0; i < firstName.length; i++) {
            firstName[i] = firstName[i][0].toUpperCase() + firstName[i].slice(1);
          }
          firstName = firstName.join(" ");
          // eslint-disable-next-line
          this.props.saveBasicInformationInRedux({ firstName });
        } else {
          // eslint-disable-next-line
          this.props.saveBasicInformationInRedux({ firstName: e.value });
        }
        options = {
          rules: {
            firstnameErr: {
              required: [true, pleaseEnterFirstName],
              regex: ["^[A-z]+$", "Please enter valid name"],
            },
          },
        };
        break;
      case "MiddleName":
        if (e.value !== "") {
          let middleName = e.value.toLowerCase().split(" ");
          for (let i = 0; i < middleName.length; i++) {
            middleName[i] =
              middleName[i][0].toUpperCase() + middleName[i].slice(1);
          }
          middleName = middleName.join(" ");
          // eslint-disable-next-line
          this.props.saveBasicInformationInRedux({ middleName });
        } else {
          // eslint-disable-next-line
          this.props.saveBasicInformationInRedux({ middleName: e.value });
        }
        options = {
          rules: {
            middlenameErr: {
              regex: ["^[A-z]+$", "Please enter valid middle name"],
            },
          },
        };
        break;
      case "LastName":
        if (e.value !== "") {
          let lastName = e.value.toLowerCase().split(" ");
          for (let i = 0; i < lastName.length; i++) {
            lastName[i] = lastName[i][0].toUpperCase() + lastName[i].slice(1);
          }
          lastName = lastName.join(" ");
          // eslint-disable-next-line
          this.props.saveBasicInformationInRedux({ lastName });
        } else {
          // eslint-disable-next-line
          this.props.saveBasicInformationInRedux({ lastName: e.value });
        }
        options = {
          rules: {
            lastnameErr: {
              required: [true, pleaseEnterLastName],
              regex: ["^[A-z]+$", "Please enter valid last name"],
            },
          },
        };
        break;
      case "Salutation":
        // eslint-disable-next-line
        this.props.saveBasicInformationInRedux({ salutation: e.itemData.Id });
        options = {
          rules: {
            salutationErr: {
              required: [true, pleaseSelectSalutation],
            },
          },
        };
        break;
      case "Gender":
        // eslint-disable-next-line
        this.props.saveBasicInformationInRedux({ gender: e.itemData.Id });
        options = {
          rules: {
            genderErr: { required: [true, pleaseSelectGender] },
          },
        };
        break;
      case "MobileNumber":
        // eslint-disable-next-line
        this.props.saveBasicInformationInRedux({ mobileNumber: e.value });
        options = {
          rules: {
            mobilenumberErr: {
              required: [true, pleaseEnterMobileName],
              // eslint-disable-next-line
              // regex: ["^([0|\+[0-9]{1,5})?([7-9][0-9]{9})$", "Please enter valid mobile number"],
              number: [true, "Please enter valid mobile number"],
              // minLength: [10, "Please enter valid mobile number"],
              // maxLength: [10, "Please enter valid mobile number"],
            },
          },
        };
        break;
      case "AlternateMobile":
        // eslint-disable-next-line
        this.props.saveBasicInformationInRedux({ alternetNumber: e.value });
        options = {
          rules: {
            alternatenumberErr: {
              // eslint-disable-next-line
              // regex: ["^([0|\+[0-9]{1,5})?([7-9][0-9]{9})$", "Please enter valid alternate mobile number"],
              number: [true, "Please enter valid mobile number"],
              // minLength: [10, "Please enter valid mobile number"],
              // maxLength: [10, "Please enter valid mobile number"],
            },
          },
        };

        break;
      case "persCountryCode":
        // eslint-disable-next-line
        this.props.saveBasicInformationInRedux({ persCountryCode: e.value });
        options = {
          rules: {
            persCountryCodeErr: {
              required: [true, "Please enter ISD code"],
            },
          },
        };
        break;
      case "altCountryCode":
        // eslint-disable-next-line
        this.props.saveBasicInformationInRedux({ altCountryCode: e.value });
        break;
      case "EmailID":
        // eslint-disable-next-line
        this.props.saveBasicInformationInRedux({ emailId: e.value });
        options = {
          rules: {
            emailidErr: {
              required: [true, pleaseEnterEmailName],
              regex: [
                "^[a-zA-Z0-9_+&*-]+(?:\\." +
                "[a-zA-Z0-9_+&*-]+)*@" +
                "(?:[a-zA-Z0-9-]+\\.)+[a-z" +
                "A-Z]{2,7}$",
                pleaseEnterValidEmailId,
              ],
            },
          },
        };
        break;
      case "PANNumber":
        // eslint-disable-next-line 
        document.getElementById('PANNumberErr').innerHTML = "";
        this.props.saveBasicInformationInRedux({ panNumber: e.value });
        options = {
          rules: {
            PANNumberErr: {
              // required: [true, "Please enter PAN number"],
              regex: [
                "[A-Z]{5}[0-9]{4}[A-Z]{1}",
                "Please enter valid PAN number",
              ],
              maxLength: [10, "Please enter valid PAN number"],
            },
          },
        };
        break;
      case "AadhaarNumber":
        document.getElementById('AadhaarNumberErr').innerHTML = "";
        this.props.saveBasicInformationInRedux({ adharNumber: e.value });
        options = {
          rules: {
            AadhaarNumberErr: {
              // required: [true, "Please enter Aadhaar number"],
              regex: [
                "^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$",
                "Please enter valid Aadhaar number",
              ],
            },
          },
        };
        break;
      case "UANNumber":
        // eslint-disable-next-line
        document.getElementById('UANNumberErr').innerHTML = "";
        this.props.saveBasicInformationInRedux({ uanNumber: e.value });
        options = {
          rules: {
            UANNumberErr: {
              // required: [true, "Please enter UAN number"],
              regex: ["^[0-9]{12}$", "Please enter valid UAN number"],
            },
          },
        };
        break;
      case "HighestQualification":
        // eslint-disable-next-line
        this.props.saveBasicInformationInRedux({
          highestEducationQualification: e.itemData.Id,
        });
        options = {
          rules: {
            highesteduErr: {
              required: [true, pleaseSelectHighestEducationQualification],
            },
          },
        };
        break;
      case "HighestEducationalSpecialization":
        // eslint-disable-next-line
        this.props.saveBasicInformationInRedux({
          highestEducationQualificationSpecial: e.value,
        });
        break;
      case "TotalWorkExperience":
        // eslint-disable-next-line
        document.getElementById('totalworkErr').innerHTML = "";
        if (e.value > 720 || e.value < 0) {
          document.getElementById("totalworkErr").innerHTML = "Please enter valid experience";
          return;
        } else if (e.value === "") {
          this.props.saveBasicInformationInRedux({ workExperiance: "0" });
          return;
        } else {
          this.props.saveBasicInformationInRedux({ workExperiance: e.value });
        }
        options = {
          rules: {
            totalworkErr: {
              number: [true, "Please enter valid number"],
            },
          },
        };
        break;
      case "function":
        // eslint-disable-next-line
        this.props.saveBasicInformationInRedux({ functionId: e.itemData.Id });
        options = {
          rules: {
            functionErr: { required: [true, "Please select function"] },
          },
        };
        break;
      case "Title":
        // eslint-disable-next-line
        if (e.itemData !== null) {
          this.props.saveBasicInformationInRedux({ title: e.itemData.Name });
          this.jobTitle.text = e.itemData.Name
          options = {
            rules: {
              titleErr: { required: [true, pleaseEnterJobTitle] },
            },
          };
        }
        break;
      case "Band":
        // eslint-disable-next-line
        this.props.saveBasicInformationInRedux({ band: e.itemData.Id });
        options = {
          rules: {
            bandErr: { required: [true, pleaseSelectBand] },
          },
        };
        break;
      case "BaseCountry":
        // eslint-disable-next-line
        this.props.saveBasicInformationInRedux({
          baseCounty: e.itemData.Id,
          city: "",
          state: "",
          pincode: "",
          persCountryCode: e.itemData.Optional1,
          altCountryCode: e.itemData.Optional1,
        });
        options = {
          rules: {
            basecountryErr: { required: [true, pleaseSelectBaseCountry] },
          },
        };
        break;
      case "AddressCountry":
        this.setState({ baseCountryChangeFlag: true });
        this.props.saveBasicInformationInRedux({
          addressCountryId: e.itemData.Id,
          countryType: e.itemData.Name,
          status: "Refresh"
        });
        options = {
          rules: {
            addressCountryErr: { required: [true, "Please select address country"] },
          },
        };
        break;
      case "BaseLocation":
        // eslint-disable-next-line
        this.props.saveBasicInformationInRedux({ baseLocation: e.value });
        break;
      case "Entity":
        // eslint-disable-next-line
        this.props.saveBasicInformationInRedux({ entity: e.itemData.Id });
        options = {
          rules: {
            entityErr: { required: [true, pleaseSelectEntity] },
          },
        };
        break;
      case "ReportingSupervisor":
        // eslint-disable-next-line
        this.props.saveBasicInformationInRedux({
          reportingSupervisor: e.itemData.UserId,
        });
        options = {
          rules: {
            reportingtoErr: {
              required: [true, pleaseSelectReportingSupervisor],
            },
          },
        };
        break;
      case "Recruiter":
        // eslint-disable-next-line
        this.props.saveBasicInformationInRedux({
          recruitmentManagerId: e.itemData.Id,
        });
        options = {
          rules: {
            recruiterErr: {
              required: [true, pleaseSelectRecruitmentManager],
            },
          },
        };
        break;
      case "Costsharing":
        // eslint-disable-next-line
        this.props.saveBasicInformationInRedux({ costSharing: e.value });
        break;
      case "Address1":
        // eslint-disable-next-line
        this.props.saveBasicInformationInRedux({ address: e.value });
        options = {
          rules: {
            address1Err: { required: [true, pleaseEnterAddress] },
          },
        };
        break;
      default:
    }

    switch (type) {
      case "Role":
        // eslint-disable-next-line
        this.props.saveBasicInformationInRedux({
          role: e.itemData.Id,
          roleName: e.itemData.Name,
        });
        options = {
          rules: {
            roleErr: { required: [true, pleaseEnterValidRole] },
          },
        };
        break;
      case "EmpType":
        // eslint-disable-next-line
        if (e.itemData !== null) {
          this.props.saveBasicInformationInRedux({ empType: e.itemData.Id });
        } else {
          this.props.saveBasicInformationInRedux({ empType: "" });
        }
        options = {
          rules: {
            emptypeErr: { required: [true, pleaseSelectEmployeeType] },
          },
        };
        break;
      case "DateofBirth":
        document.getElementById("dateofbirthErr").innerHTML = ""
        // eslint-disable-next-line
        this.props.saveBasicInformationInRedux({ dateOfBirth: Math.floor(Date.UTC(e.getFullYear(), e.getMonth(), e.getDate())) / 1000 });
        break;
      case "ReadytoJoinDate":
        // eslint-disable-next-line
        document.getElementById("readytojoindateErr").innerHTML = "";
        this.props.saveBasicInformationInRedux({ readyToJoinDate: Math.floor(Date.UTC(e.getFullYear(), e.getMonth(), e.getDate())) / 1000 });
        break;
      case "TenureEndDate":
        // eslint-disable-next-line
        if (this.props.empType === 677 || this.props.empType === 747 || this.props.empType === 799 || this.props.empType === 798 || this.props.empType === 748 || this.props.empType === 749) {
          document.getElementById("tenureEndDateErr").innerHTML = "";
          this.props.saveBasicInformationInRedux({ tenureEndDate: Math.floor(Date.UTC(e.getFullYear(), e.getMonth(), e.getDate())) / 1000 });
        } else {
          document.getElementById("tenureEndDateErr").innerHTML = "";
          this.props.saveBasicInformationInRedux({ tenureEndDate: null });
        }
        break;
      case "Address2":
        // eslint-disable-next-line
        this.props.saveBasicInformationInRedux({ address2: e.value });
        break;
      case "state":
        // eslint-disable-next-line
        this.props.saveBasicInformationInRedux({ state: e.value });
        options = {
          rules: {
            stateErr: { required: [true, pleaseEnterState] },
          },
        };
        break;
      case "city":
        // eslint-disable-next-line
        this.props.saveBasicInformationInRedux({ city: e.value });
        options = {
          rules: {
            cityErr: {
              required: [true, pleaseEnterCity],
              // regex: ['^[A-z]+$', 'Please enter valid city']
            },
          },
        };
        break;
      case "pincode":
        // eslint-disable-next-line
        this.props.saveBasicInformationInRedux({ pincode: e.value });
        options = {
          rules: {
            pincodeErr: {
              required: [true, "Please enter pin code"],
            },
          },
        };
        break;
      case "LegalName":
        // eslint-disable-next-line
        if (e.value !== "") {
          this.props.saveBasicInformationInRedux({ legalName: e.value });
        }
        options = {
          rules: {
            LegalNameErr: {
              required: [true, "Please enter legal name"],
              regex: ["^[A-z ]+$", "Please enter legal name"],
            },
          },
        };
        break;
      case "Other":
        // eslint-disable-next-line
        this.props.saveBasicInformationInRedux({ other: e.value });
        break;
      case "sourceCategory":
        // eslint-disable-next-line
        this.props.saveBasicInformationInRedux({
          sourceCategory: e.itemData.Id,
          agencyName: "",
          jobPortalId: null,
          referralId: null
        });
        options = {
          rules: {
            sourceCategoryErr: {
              required: [true, "Please select source category"],
            },
          },
        };
        break;
      case "tctc":
        // eslint-disable-next-line
        this.props.saveBasicInformationInRedux({ tctc: e.value });
        options = {
          rules: {
            tctcErr: {
              required: [true, "Please enter TCTC / OTE "],
              regex: ['^[0-9]+$', 'Please enter value in number format']
            },
          },
        };
        break;
      case "oti":
        // eslint-disable-next-line
        this.props.saveBasicInformationInRedux({ oti: e.value });
        options = {
          rules: {
            otiErr: {
              required: [true, "Please enter on target incentive (OTI)"],
              regex: ['^[0-9]+$', 'Please enter value in number format']
            },
          },
        };
        break;
      case "fixedCTC":
        // eslint-disable-next-line
        this.props.saveBasicInformationInRedux({ fixedCTC: e.value });
        options = {
          rules: {
            fixedCtcErr: {
              required: [true, "Please enter fixed ctc"],
              regex: ['^[0-9]+$', 'Please enter value in number format']
            },
          },
        };
        break;
      case "agencyName":
        // eslint-disable-next-line
        this.props.saveBasicInformationInRedux({ agencyName: e.value });
        options = {
          rules: {
            agencyNameErr: {
              required: [true, "Please enter agency name"],
            },
          },
        };
        break;
      case "jobPortal":
        // eslint-disable-next-line
        this.props.saveBasicInformationInRedux({
          jobPortalId: e.itemData.Id,
        });
        options = {
          rules: {
            jobPortalErr: {
              required: [true, "Please select job portal"],
            },
          },
        };
        break;
      case "referral":
        // eslint-disable-next-line
        this.props.saveBasicInformationInRedux({
          referralId: e.itemData.UserId,
        });
        options = {
          rules: {
            referralErr: {
              required: [true, "Please select referral"],
            },
          },
        };
        break;
      case "layer":
        if (dataPopulated) {
          this.getMasterDropdownData('sublayer', e.itemData.Optional1, true);
          this.props.saveBasicInformationInRedux({
            layerId: e.itemData.Id, subLayerId: null
          });
          options = {
            rules: {
              layerErr: {
                required: [true, "Please select layer"],
              },
            },
          }
        }

        break;
      case "subLayer":
        if (dataPopulated) {
          this.props.saveBasicInformationInRedux({
            subLayerId: e.itemData.Id,
          });
          options = {
            rules: {
              subLayerErr: {
                required: [true, "Please select sub layer"],
              },
            },
          };
        }

        break;
      case "currency":
        this.props.saveBasicInformationInRedux({
          currencyId: e.itemData.Id,
        });
        options = {
          rules: {
            currencyErr: {
              required: [true, pleaseSelectCurrency],
            },
          },
        };
        break;
      case "ctcFrequency":
        this.props.saveBasicInformationInRedux({
          ctcFrequency: e.itemData.Id,
        });
        options = {
          rules: {
            ctcFrequencyErr: {
              required: [true, pleaseSelectCTCFrequency],
            },
          },
        };
        break;
      default:
    }
    setTimeout(() => {
      const addFormObject = new FormValidator("#addCandidateFormInfo", options);
      addFormObject.validate();
    }, 100);
  };

  changeValue = (e) => {
    if (e.itemData !== undefined && e.itemData !== null) {
      // eslint-disable-next-line
      this.props.saveBasicInformationInRedux({
        role: e.itemData.Id,
        roleName: e.itemData.Name,
      });
      if (
        this.props.Id === e.itemData.Id &&
        this.props.roleName === e.itemData.Name
      ) {
        // eslint-disable-next-line
        this.props.saveBasicInformationInRedux({
          role: e.itemData.Id,
          roleName: e.itemData.Name,
        });
      } else if (typeof e.itemData.Id !== "number") {
        // eslint-disable-next-line
        this.props.saveBasicInformationInRedux({
          role: this.props.role,
          roleName: this.props.roleName,
        });
      }
      // else {
      //   // eslint-disable-next-line
      //   this.props.saveBasicInformationInRedux({
      //     role: e.itemData.Id,
      //     roleName: e.itemData.Name,
      //   });
      // }
    } else {
      // eslint-disable-next-line
      this.props.saveBasicInformationInRedux({ role: "", roleName: "" });
    }
  };

  uploadImage = (e) => {
    const currentDate = Date.parse(new Date()) / 1000;
    const imageName = e.target.files[0];
    const imgType = imageName.type.replace("image/", ".");
    const size = e.target.files[0].size / 1024;
    if (imageName !== undefined && size < maxFileSize) {
      if (imageName.type !== "image/png" && imageName.type !== "image/jpeg") {
        getNotify(ERROR, FileType);
        return;
      }
    } else {
      getNotify(ERROR, "Please select png or jpeg image (less than 10MB) ");
      return;
    }
    return new Promise((resolve, reject) => {
      const req = new XMLHttpRequest();
      try {
        const formData = new FormData();
        formData.append("usertype", "C");
        formData.append("type", "P");
        formData.append("file", imageName, `${currentDate}${imgType}`);
        req.open("POST", `${corsallow}${urlConfig.tmlcApiUrl}document/upl`);
        req.setRequestHeader("Authorization", localStorage.getItem("jwtToken"));
        req.send(formData);

        if (e.target.files.length) {
          this.setState({
            imagePath: URL.createObjectURL(e.target.files[0]),
            imageInfo: e.target.files[0],
          });
        } else {

          this.setState({
            imagePath: URL.createObjectURL(e.target.files[0]),
            imageInfo: e.target.files[0],
          });
          return;
        }
        // eslint-disable-next-line
        this.props.saveBasicInformationInRedux({
          imageName: `${currentDate}${imgType}`,
        });
      } catch (error) {
        console.log("Image Upload", error);
      }
    });
  };

  TextFieldComponent = (props) => {
    return <TextField {...props} color="primary" disabled />;
  };

  onFiltering = (e) => {
    // load overall data when search key empty.
    if (e.text === "") {
      e.updateData(this.searchData);
    } else {
      let query = new Query();
      query =
        e.text !== ""
          ? query.where("nameWithDepartment", "contains", e.text, true)
          : query;
      e.updateData(this.props.othersCheckAvailablity, query);
    }
  };
  getSalesUnitOnChange(e) {
    const { dataPopulated } = this.state
    if (dataPopulated) {
      this.props.saveBasicInformationInRedux({
        entity: e.itemData?.Id, subFunction: null, servceDivisionSalesUnit: null, reportingManager: [], layerId: null, subLayerId: null
      });
      this.getMasterDropdownData('subbusinesspnl', e.itemData?.Id, true);
      setTimeout(() => {
        const options = {
          rules: {
            entityErr: {
              required: [true, "Please select business unit / group function"],
            },
          },
        };
        const addFormObject = new FormValidator(
          "#addCandidateFormInfo",
          options
        );
        addFormObject.validate();
      }, 100);
    }
  }

  /**
   * Author :- Md Jahir Uddin
   * Date :- 09-02-2023
   * Description :- Add function to get practice area data based on sub function
   * @param {*} e
   */
  getPracticeAreaOnChange(e) {
    const { dataPopulated } = this.state
    if (dataPopulated) {
      this.props.saveBasicInformationInRedux({
        subFunction: e.itemData?.Id, servceDivisionSalesUnit: null, reportingManager: [], layerId: null, subLayerId: null
      });
      this.getMasterDropdownData('practicearea', e.itemData?.Id, true);
      setTimeout(() => {
        const options = {
          rules: {
            subFunction: {
              required: [true, "Please select sub BU / function"],
            },
          },
        };
        const addFormObject = new FormValidator(
          "#addCandidateFormInfo",
          options
        );
        addFormObject.validate();
      }, 100);
    }
  }

  /**
   * Author :- Prashant Waphare
   * Date :- 18-05-2020
   * Description :- Add function for get Reporting filed data
   * @param {*} e
   */
  getReportingManager = (e) => {
    const { authUser } = this.props;
    const { dataPopulated } = this.state
    try {
      if (dataPopulated) {
        this.props.saveBasicInformationInRedux({ layerId: null });
        this.setState({ layerData: [] });
        this.getMasterDropdownData('layer', e.itemData?.Id, true);
        setTimeout(() => {
          const options = {
            rules: {
              salesunitErr: {
                required: [true, pleaseSelectServceDivisionSalesUnit],
              },
            },
          };
          const addFormObject = new FormValidator(
            "#addCandidateFormInfo",
            options
          );
          addFormObject.validate();
        }, 100);
        const getReportingManagerData = Promise.resolve(
          OnbordingToBeOffer.getMasterData("SDSU", authUser, e.itemData?.Id)
        );
        getReportingManagerData.then((resultData) => {
          if (resultData !== null) {
            // eslint-disable-next-line
            this.props.saveBasicInformationInRedux({ servceDivisionSalesUnit: e.itemData?.Id });
            this.setState({
              reportingManager: resultData,
              changedData: !this.state.changedData,
            });
          } else {
            this.setState({ reportingManager: [{ Id: 0, Name: "" }] });
            this.data = "error";
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  handleFilteringByName = (e) => {
    const { jobTitleData } = this.props;
    const newData = [];
    jobTitleData.forEach((eachD) => {
      newData.push(eachD);
    });
    if (newData != null && newData.length > 0) {
      let query = new Query();
      query =
        e.text !== "" ? query.where("Name", "contains", e.text, true) : query;
      e.updateData(newData, query);
    }
    // }
  };
  /**
     * Author :- Abdulla Shaikh
     * Date :- 18-04-2023
     * Description :- Add tooltip/title to dropdown options
     * @param {*} e
     */
  dropdownItemTemplate(data) {
    if (data?.Name?.length > 25) {
      return (
        <TooltipComponent
          closeOn="Click"
          content={data.Name}
          position="BottomCenter"
          isSticky={false}
          cssClass='customtooltip'
        >
          <span>{data.Name.slice(0, 25) + '...'}</span>
        </TooltipComponent>)
    } else {
      return <span>{data.Name}</span>
    }
  }

  referralDropdownItemTemplate(data) {
    if (data?.nameWithDepartment?.length > 25) {
      return (
        <TooltipComponent
          closeOn="Click"
          content={data.nameWithDepartment}
          position="BottomCenter"
          isSticky={false}
          cssClass='customtooltip'
        >
          <span>{data.nameWithDepartment.slice(0, 25) + '...'}</span>
        </TooltipComponent>)
    } else {
      return <span>{data.nameWithDepartment}</span>
    }
  }
  getMasterDropdownData = (type, id, isEncrypted) => {
    const { dataPopulated } = this.state
    try {
      if (dataPopulated && id !== null && typeof id !== 'undefined') {

        const masterDopdownData = Promise.resolve(
          OnbordingToBeOffer.getOrgchartMasterDropdownData(encryptMethod(type), encryptMethod(id.toString()), isEncrypted)
        );
        masterDopdownData.then((resultData) => {
          if (type === 'businesspnl') {
            this.setState({
              businessUnitData: resultData,
              practiceAreaData: [],
              layerData: [],
              subLayerData: [],
              reportingManager: []
            }, this.practiceAreaTooltip)
          }
          if (type === 'subbusinesspnl') {
            this.setState({
              subFunctions: resultData,
              practiceAreaData: [],
              layerData: [],
              subLayerData: [],
              reportingManager: []
            })
          }
          if (type === 'practicearea') {
            this.props.saveBasicInformationInRedux({ layerId: null, subLayerId: null })

            this.setState({
              practiceAreaData: resultData,
              layerData: [],
              subLayerData: [],
              reportingManager: []
            },)
          }
          if (type === 'layer') {
            this.props.saveBasicInformationInRedux({ subLayerId: null })
            this.setState({
              layerData: resultData,
              subLayerData: []
            })
          }
          if (type === 'sublayer') {
            this.setState({
              subLayerData: resultData
            })
          }
        });
      } else {
        if (type === 'businesspnl') {
          this.setState({
            businessUnitData: [],
            practiceAreaData: [],
            layerData: [],
            subLayerData: [],
            reportingManager: []
          }, this.practiceAreaTooltip)
        }
        if (type === 'subbusinesspnl') {
          this.setState({
            subFunctions: [],
            practiceAreaData: [],
            layerData: [],
            subLayerData: [],
            reportingManager: []
          })
        }
        if (type === 'practicearea') {
          this.props.saveBasicInformationInRedux({ layerId: null, subLayerId: null })
          this.setState({
            practiceAreaData: [],
            layerData: [],
            subLayerData: [],
            reportingManager: []
          },)
        }
        if (type === 'layer') {
          this.props.saveBasicInformationInRedux({ subLayerId: null })
          this.setState({
            layerData: [],
            subLayerData: []
          })
        }
        if (type === 'sublayer') {
          this.setState({
            subLayerData: []
          })
        }
      }
    } catch (err) {
      console.log("OKRRatingServices", err)
    }
  }

  render() { // NOSONAR
    const {
      authUser, imageName, salutation, firstName, middleName, lastName, gender, mobileNumber, alternetNumber, emailId, panNumber, legalName, adharNumber, uanNumber, highestEducationQualification,
      highestEducationQualificationSpecial, workExperiance, empType, baseCounty, baseLocation, entity, reportingSupervisor, address, dateOfBirth, readyToJoinDate, servceDivisionSalesUnit, recruitmentManagerId,
      address2, city, pincode, state, genderstate, highestEducationQualifcation, employeeType, bacecountry, salutationState, other, othersCheckAvailablity, sourceCategory, sourceCategoryData,
      jobPortalData, jobPortalId, referralId, agencyName, tctc, oti, fixedCTC, jobTitleData, layerId, subLayerId, entityMaster, recruitementMaster, addressCountryMaster, addressCountryId, title, tenureEndDate,
      currencyId, ctcFrequency, currencyMaster, subFunction, docsSubmitted, candidateid, recruiterName, referralName, reportingSup
    } = this.props;
    const { imagePath, practiceAreaData, layerData, subLayerData, subFunctions } = this.state;
    let imgUrl = "";
    if (imagePath === "" && imageName === "") {
      imgUrl = "../../app/containers/tmlc/onboarding/avatar-s-16.png";
    } else if (imagePath === "" && imageName !== "") {
      imgUrl = `${urlConfig.tmlcApiUrl}document/A/${authUser}/${imageName}`;
    } else {
      imgUrl = imagePath;
    }

    return (
      <div>
        <div>BASIC INFORMATION: {firstName}&nbsp;{lastName} </div>
        <form
          id="addCandidateFormInfo"
          method="post"
          className="control-section col-md-12"
        >
          <div
            className="row card-layout"
            style={{
              paddingLeft: "30px",
              paddingRight: "29px",
              paddingTop: "10px",
            }}
          >
            <div
              className="col-xs-1 col-sm-1 col-lg-1 col-md-1"
              style={{ height: "50px", paddingLeft: "0px" }}
            >
              <div>
                <div
                  className="col-lg-6"
                  name="imageErr"
                  data-msg-containerid="imageErr"
                >
                  <div className="profile-img-wrap edit-img">
                    {imagePath === "" && imageName === "" ? (
                      <span
                        style={{
                          height: "100%",
                          width: "100%",
                          top: "50px",
                          position: "absolute",
                          left: "20px",
                        }}
                        className="inline-block"
                      >
                        {" "}
                        Upload Image{" "}
                      </span>
                    ) : (
                      <img
                        style={{ height: "100%", width: "100%" }}
                        className="inline-block"
                        src={imgUrl}
                        alt=""
                      />
                    )}
                    <div className="fileupload btn">
                      <input
                        type="file"
                        id="uploadImage"
                        style={{ opacity: 0, cursor: "pointer" }}
                        onChange={this.uploadImage}
                      />
                    </div>
                  </div>
                  {imagePath === "" && imageName === "" ? (
                    <span
                      style={{
                        position: "absolute",
                        top: "96px",
                        left: "54px",
                        color: "black",
                      }}
                      onChange={this.uploadImage}
                    >
                      Upload
                    </span>
                  ) : (
                    <span
                      style={{
                        position: "absolute",
                        top: "98px",
                        left: "64px",
                        color: "white",
                        background: "rgba(0, 0, 0, 0.26)",
                      }}
                      onChange={this.uploadImage}
                    >
                      Edit
                    </span>
                  )}
                </div>
                <div id="imageErr" className="error-div" />
              </div>
            </div>
            <div
              className="col-xs-2 col-sm-2 col-lg-2 col-md-2"
              style={{ padding: "0px", marginLeft: "42px" }}
            >
              <div>
                <ComboBoxComponent
                  id="EmpType"
                  dataSource={employeeType}
                  fields={{ text: "Name", value: "Id" }}
                  value={empType}
                  data-msg-containerid="emptypeErr"
                  name="emptypeErr"
                  allowFiltering
                  allowCustom={false}
                  floatLabelType="Auto"
                  cssClass="e-outline"
                  placeholder="Employment Type*"
                  popupHeight="350px"
                  // popupWidth="180"
                  // change={(e) => this.getEmpTypeValue(e)}
                  select={(e) => this.getBasicInFormation(e, "EmpType")}
                />
                <div
                  id="emptypeErr"
                  className="e-input-group e-float-icon-left error-div"
                />
              </div>
            </div>
            <div className="col-xs-2 col-sm-2 col-lg-3 col-md-2  ">
              <div>
                <ComboBoxComponent
                  id="Entity"
                  dataSource={entityMaster}
                  fields={{ text: "Name", value: "Id" }}
                  value={entity}
                  data-msg-containerid="entityErr"
                  name="entityErr"
                  // allowFiltering={true}
                  allowCustom={false}
                  floatLabelType="Auto"
                  cssClass="e-outline"
                  placeholder="Business Unit / Group Function  *"
                  // change={(e) => this.getSalesUnitOnChange(e)}
                  change={(e) => this.getSalesUnitOnChange(e)}
                // select={(e) => this.getBasicInFormation(e, "Entity")}
                />
                <div
                  id="entityErr"
                  className="e-input-group e-float-icon-left error-div"
                />
              </div>
            </div>
            <div className="col-xs-2 col-sm-2 col-lg-3 col-md-2 ">
              <div className="col-xs-2 col-sm-2 col-lg-6 col-md-2"
                style={{ paddingLeft: 0 }}
              >
                <TooltipComponent
                  closeOn="Click"
                  content={subFunction ? subFunctions.filter(item => item.Id === subFunction)[0]?.Name : "Please select an option"}
                  position="BottomCenter"
                  isSticky={false}
                  cssClass='customtooltip'
                >
                  <ComboBoxComponent
                    id="getSDSU"
                    fields={{ text: "Name", value: "Id" }}
                    data-msg-containerid="subFunction"
                    name="subFunction"
                    // allowFiltering={true}
                    allowCustom={true}
                    value={subFunction}
                    cssClass="e-outline"
                    floatLabelType="Auto"
                    // eslint-disable-next-line
                    dataSource={subFunctions}
                    itemTemplate={this.dropdownItemTemplate}
                    change={(e) => this.getPracticeAreaOnChange(e)}
                    placeholder="Sub BU / Function *"
                  // select={(e) => this.getBasicInFormation(e, "getSDSU")}
                  />
                </TooltipComponent>
                <div
                  id="subFunction"
                  className="e-input-group e-float-icon-left error-div"
                />
              </div>
              <div className="col-xs-2 col-sm-2 col-lg-6 col-md-2"
                style={{ padding: 0 }}
              >
                <TooltipComponent
                  closeOn="Click"
                  content={servceDivisionSalesUnit ? practiceAreaData.filter(item => item.Id === servceDivisionSalesUnit)[0].Name : "Please select an option"}
                  position="BottomCenter"
                  isSticky={false}
                  cssClass='customtooltip'
                >
                  <ComboBoxComponent
                    id="getSDSU"
                    fields={{ text: "Name", value: "Id" }}
                    data-msg-containerid="salesunitErr"
                    name="salesunitErr"
                    // allowFiltering={true}
                    allowCustom={false}
                    value={servceDivisionSalesUnit}
                    cssClass="e-outline"
                    floatLabelType="Auto"
                    // eslint-disable-next-line
                    dataSource={practiceAreaData}
                    itemTemplate={this.dropdownItemTemplate}
                    change={(e) => this.getReportingManager(e)}
                    placeholder="Practice Area *"

                  // select={(e) => this.getBasicInFormation(e, "getSDSU")}
                  />
                </TooltipComponent>
                <div
                  id="salesunitErr"
                  className="e-input-group e-float-icon-left error-div"
                />
              </div>
            </div>
            <div className="col-xs-2 col-sm-2 col-lg-3 col-md-2">
              <div className={reportingSup ? "col-xs-11 col-sm-11 col-lg-11 col-md-11" : ""} style={{ paddingLeft: 0 }}>
                {
                  // this.state.reportingManager !== null && this.state.reportingManager.length > 1 ?
                  othersCheckAvailablity !== null && othersCheckAvailablity.length > 1 ?
                    <ComboBoxComponent
                      id="ReportingSupervisor"
                      // fields={{ text: 'nameWithDepartment', value: 'UserId' }}
                      fields={{ text: "nameWithDepartment", value: "UserId" }}
                      data-msg-containerid="reportingtoErr"
                      name="reportingtoErr"
                      value={reportingSupervisor}
                      // allowFiltering={true}
                      // allowCustom={false}
                      cssClass="e-outline"
                      floatLabelType="Auto"
                      // eslint-disable-next-line
                      dataSource={othersCheckAvailablity}
                      filtering={this.onFiltering}
                      placeholder="Reporting Supervisor *"
                      select={(e) =>
                        this.getBasicInFormation(e, "ReportingSupervisor")
                      }
                    />
                    :
                    <ComboBoxComponent
                      id="ReportingSupervisor"
                      fields={{ text: "Name", value: "Id" }}
                      data-msg-containerid="reportingtoErr"
                      name="reportingtoErr"
                      // allowCustom={false}
                      cssClass="e-outline"
                      floatLabelType="Auto"
                      placeholder="Reporting Supervisor *"
                    />
                }
                <div
                  id="reportingtoErr"
                  className="e-input-group e-float-icon-left error-div"
                />
              </div>
              <div className="col-xs-1 col-sm-1 col-lg-1 col-md-1 preofferTooltipParent" style={{ paddingLeft: 0 }}>
                {reportingSup && <>
                  <FontAwesomeIcon
                    style={{ fontSize: 25, marginTop: ".3rem" }}
                    icon={faBinoculars}
                  // title={reportingSup}
                  />
                  <div className="preofferTooltip">{reportingSup}</div>
                </>}
              </div>
            </div>
          </div>
          <div
            className="row card-layout"
            style={{
              paddingLeft: "30px",
              paddingRight: "30px",
              // paddingTop: "10px",
            }}
          >
            <div className="col-xs-2 col-sm-2 col-lg-3 col-md-2"
              style={{ paddingLeft: "16.15em" }}>
            </div>
            <div
              className="col-xs-2 col-sm-2 col-lg-3 col-md-2"
            // style={{ paddingLeft: "60px" }}
            >
              <div>
                <TooltipComponent
                  closeOn="Click"
                  content="Country location of office where candidate is intended to work"
                  position="BottomCenter"
                  isSticky={false}
                  cssClass='customtooltip'
                >
                  <ComboBoxComponent
                    id="BaseCountry"
                    dataSource={bacecountry}
                    fields={{ text: "Name", value: "Id" }}
                    value={baseCounty}
                    data-msg-containerid="basecountryErr"
                    name="basecountryErr"
                    // allowFiltering={true}
                    floatLabelType="Auto"
                    cssClass="e-outline"
                    placeholder="Select Country of Work *"
                    select={(e) => this.getBasicInFormation(e, "BaseCountry")}
                  />
                </TooltipComponent>
                <div
                  id="basecountryErr"
                  className="e-input-group e-float-icon-left error-div"
                />
              </div>
            </div>
            <div className="col-xs-2 col-sm-2 col-lg-3 col-md-2  ">
              <div>
                <TextBoxComponent
                  id="BaseLocation"
                  value={baseLocation}
                  data-msg-containerid="baselocationErr"
                  name="baselocationErr"
                  floatLabelType="Auto"
                  placeholder="Enter Office Location"
                  cssClass="e-outline"
                  blur={(e) => this.getBasicInFormation(e, "BaseLocation")}
                  htmlAttributes={{ maxlength: 100 }}
                />
                <div
                  id="baselocationErr"
                  className="e-input-group e-float-icon-left error-div"
                />
              </div>
            </div>
            <div className="col-xs-2 col-sm-2 col-lg-3 col-md-2  ">
              <div className="col-xs-2 col-sm-2 col-lg-6 col-md-2"
                style={{ paddingLeft: 0 }}
              >
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    // required={true}
                    style={{ width: "100%" }}
                    // margin="normal"
                    id="ReadytoJoinDate"
                    name="ReadytoJoinDate"
                    format="dd-MMM-yyyy"
                    value={readyToJoinDate === null ? readyToJoinDate : new Date(0).setUTCSeconds(readyToJoinDate)}
                    onChange={(e) =>
                      this.getBasicInFormation(e, "ReadytoJoinDate")
                    }
                    // error={errorText && errorText.length > 0}
                    // helperText={(errorText && errorText.length > 0) ? errorText : null}
                    TextFieldComponent={this.TextFieldComponent}
                    // data-msg-containerid="meetingdateError"
                    color="black"
                    disablePast
                    // minDate={new Date()}
                    autoOk
                    variant="inline"
                    inputVariant="outlined"
                    // label="Date of Joining (Tentative)"
                    placeholder="dd-MMM-yyyy"
                    label="Expected Date of Joining *"
                    KeyboardButtonProps={{ "aria-label": "change date" }}
                    // margin="dense"
                    InputLabelProps={{ shrink: true }}
                  // helperText={pleaseSelectJoiningDate}
                  />
                </MuiPickersUtilsProvider>
                <div
                  id="readytojoindateErr"
                  className="e-input-group e-float-icon-left error-div"
                />
              </div>
              <div className="col-xs-2 col-sm-2 col-lg-6 col-md-2"
                style={{ paddingRight: 0 }}
              >
                {(this.props.empType === 677 || this.props.empType === 747 || this.props.empType === 799 || this.props.empType === 798 || this.props.empType === 748 || this.props.empType === 749) && (
                  <>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        style={{ width: "100%" }}
                        id="TenureEndDate"
                        name="tenureEndDateErr"
                        format="dd-MMM-yyyy"
                        value={tenureEndDate === null ? tenureEndDate : new Date(0).setUTCSeconds(tenureEndDate)}
                        onChange={(e) =>
                          this.getBasicInFormation(e, "TenureEndDate")
                        }
                        TextFieldComponent={this.TextFieldComponent}
                        color="black"
                        disablePast
                        autoOk
                        variant="inline"
                        inputVariant="outlined"
                        placeholder="dd-MMM-yyyy"
                        label="Tenure End Date *"
                        KeyboardButtonProps={{ "aria-label": "change date" }}
                        InputLabelProps={{ shrink: true }}
                      />
                    </MuiPickersUtilsProvider>
                    <div
                      id="tenureEndDateErr"
                      className="e-input-group e-float-icon-left error-div"
                    />
                  </>
                )}
              </div>
            </div>
          </div>
          <div
            className="row card-layout"
            style={{
              paddingLeft: "30px",
              paddingRight: "30px",
              // paddingTop: "10px",
            }}
          >
            <div className="col-xs-2 col-sm-2 col-lg-3 col-md-2" />
            <div className="col-xs-2 col-sm-2 col-lg-3 col-md-2">
              <div>
                <ComboBoxComponent
                  id="layer"
                  dataSource={layerData}
                  fields={{ text: "Name", value: "Id" }}
                  value={layerId}
                  data-msg-containerid="layerErr"
                  name="layerErr"
                  allowFiltering
                  // allowCustom={false}
                  floatLabelType="Auto"
                  cssClass="e-outline"
                  placeholder="Layer *"
                  select={(e) => this.getBasicInFormation(e, "layer")}
                />
                <div
                  id="layerErr"
                  className="e-input-group e-float-icon-left error-div"
                />
              </div>
            </div>
            {/* <div className="col-xs-2 col-sm-2 col-lg-3 col-md-2">
              <div>
                <ComboBoxComponent
                  id="Band"
                  dataSource={bandstate}
                  fields={{ text: "Name", value: "Id" }}
                  value={band}
                  data-msg-containerid="bandErr"
                  name="bandErr"
                  allowFiltering
                  allowCustom={false}
                  floatLabelType="Auto"
                  cssClass="e-outline"
                  placeholder="Select Band *"
                  select={(e) => this.getBasicInFormation(e, "Band")}
                />
                <div
                  id="bandErr"
                  className="e-input-group e-float-icon-left error-div"
                />
              </div>
            </div> */}
            <div className="col-xs-2 col-sm-2 col-lg-3 col-md-2  ">
              <div>
                <ComboBoxComponent
                  id="subLayer"
                  fields={{ text: "Name", value: "Id" }}
                  // filtering={(e) => this.getJobRole(e)}
                  select={(e) => this.getBasicInFormation(e, "subLayer")}
                  // change={(e) => this.changeValue(e)}
                  placeholder="Sub Layer *"
                  popupHeight="220px"
                  cssClass="e-outline"
                  floatLabelType="Auto"
                  data-msg-containerid="subLayerErr"
                  name="subLayerErr"
                  value={subLayerId}
                  dataSource={subLayerData}
                  htmlAttributes={{ maxlength: 100 }}
                />
                <div
                  id="subLayerErr"
                  className="e-input-group e-float-icon-left error-div"
                />
              </div>
            </div>
            <div className="col-xs-2 col-sm-2 col-lg-3 col-md-2  ">
              <div>
                {/* jobTitleData */}
                {/* <TextBoxComponent
                  id="Title"
                  cssClass="e-outline"
                  value={title}
                  data-msg-containerid="titleErr"
                  name="titleErr"
                  floatLabelType="Auto"
                  placeholder="Job Title *"
                  blur={(e) => this.getBasicInFormation(e, "Title")}
                  htmlAttributes={{ maxlength: 100 }}
                /> */}

                {/* {
                  jobTitleData.length > 0 && */}
                <AutoCompleteComponent
                  fields={{ text: "Name", value: "Name" }}
                  id="Title"
                  filtering={this.handleFilteringByName}
                  ref={(autocomplete) => {
                    this.jobTitle = autocomplete;
                  }}
                  // select={(e) =>this.getBasicInFormation(e, "Title")}
                  change={(e) => this.getBasicInFormation(e, "Title")}
                  placeholder="Role / Title *"
                  popupHeight="220px"
                  data-msg-containerid="titleErr"
                  name="titleErr"
                  cssClass="e-outline"
                  floatLabelType="Auto"
                  allowCustom={false}
                  dataSource={jobTitleData}
                  value={title}
                />
                {/* } */}
                <div
                  id="titleErr"
                  className="e-input-group e-float-icon-left error-div"
                />
              </div>
            </div>
          </div>
          <div
            className="row card-layout"
            style={{
              paddingLeft: "30px",
              paddingRight: "30px",
              // paddingTop: "10px",
            }}
          >
            <div className="col-xs-2 col-sm-2 col-lg-3 col-md-12  ">
              <div className="col-xs-2 col-sm-2 col-lg-6 col-md-2  ">
                <div>
                  <ComboBoxComponent
                    id="Gender"
                    floatLabelType="Auto"
                    fields={{ text: "Name", value: "Id" }}
                    data-msg-containerid="genderErr"
                    name="genderErr"
                    allowFiltering
                    allowCustom={false}
                    value={gender}
                    dataSource={genderstate}
                    cssClass="e-outline"
                    placeholder="Gender *"
                    select={(e) => this.getBasicInFormation(e, "Gender")}
                  />
                  <div
                    id="genderErr"
                    className="e-input-group e-float-icon-left error-div"
                  />
                </div>
              </div>
              <div className="col-xs-2 col-sm-2 col-lg-6 col-md-2">
                <div>
                  <ComboBoxComponent
                    id="Salutation"
                    fields={{ text: "Name", value: "Id" }}
                    value={salutation}
                    name="salutationErr"
                    allowFiltering
                    allowCustom={false}
                    data-msg-containerid="salutationErr"
                    dataSource={salutationState}
                    placeholder="Salutation *"
                    floatLabelType="Auto"
                    cssClass="e-outline"
                    select={(e) => this.getBasicInFormation(e, "Salutation")}
                  />
                  <div id="salutationErr" className="error-div" />
                </div>
              </div>
            </div>
            <div className="col-xs-2 col-sm-2 col-lg-3 col-md-2">
              <div>
                <TextBoxComponent
                  data-msg-containerid="firstnameErr"
                  id="FirstName"
                  value={firstName}
                  name="firstnameErr"
                  placeholder="First Name *"
                  floatLabelType="Auto"
                  cssClass="e-outline"
                  blur={(e) => this.getBasicInFormation(e, "FirstName")}
                  htmlAttributes={{ maxlength: 100 }}
                />
                <div id="firstnameErr" className="error-div" />
              </div>
            </div>
            <div className="col-xs-2 col-sm-2 col-lg-3 col-md-2">
              <div>
                <TextBoxComponent
                  id="MiddleName"
                  value={middleName}
                  data-msg-containerid="middlenameErr"
                  name="middlenameErr"
                  placeholder="Middle Name"
                  floatLabelType="Auto"
                  cssClass="e-outline"
                  blur={(e) => this.getBasicInFormation(e, "MiddleName")}
                  htmlAttributes={{ maxlength: 100 }}
                />
                <div
                  id="middlenameErr"
                  className="e-input-group e-float-icon-left error-div"
                />
              </div>
            </div>
            <div className="col-xs-2 col-sm-2 col-lg-3 col-md-2">
              <div>
                <TextBoxComponent
                  id="LastName"
                  value={lastName}
                  data-msg-containerid="lastnameErr"
                  name="lastnameErr"
                  placeholder="Last Name *"
                  floatLabelType="Auto"
                  cssClass="e-outline"
                  blur={(e) => this.getBasicInFormation(e, "LastName")}
                  htmlAttributes={{ maxlength: 100 }}
                />
                <div
                  id="lastnameErr"
                  className="e-input-group e-float-icon-left error-div"
                />
              </div>
            </div>
          </div>
          <div
            className="row card-layout"
            style={{
              paddingLeft: "30px",
              paddingRight: "30px",
              // paddingTop: "10px",
            }}
          >
            <div className="col-xs-2 col-sm-2 col-lg-3 col-md-2 ">
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  // required={true}
                  style={{ width: "100%" }}
                  id="DateofBirth"
                  name="DateofBirth"
                  // data-msg-containerid="dateofbirthErr"
                  format="dd-MMM-yyyy"
                  KeyboardButtonProps={{ "aria-label": "change date" }}
                  // value={dateVal}
                  value={dateOfBirth === null ? dateOfBirth : new Date(0).setUTCSeconds(dateOfBirth)}
                  onChange={(e) => this.getBasicInFormation(e, "DateofBirth")}
                  TextFieldComponent={this.TextFieldComponent}
                  maxDate={addDays(new Date(), -5110)}
                  autoOk
                  variant="inline"
                  inputVariant="outlined"
                  label="Date of Birth *"
                  placeholder="dd-MMM-yyyy"
                  InputLabelProps={{ shrink: true }}
                />
              </MuiPickersUtilsProvider>
              <div
                id="dateofbirthErr"
                className="e-input-group e-float-icon-left error-div"
              />
            </div>
            {/* <div className="col-xs-2 col-sm-2 col-lg-3 col-md-2  "> */}
            <div className="col-xs-2 col-sm-2 col-lg-3 col-md-12  ">
              <div className="col-xs-2 col-sm-2 col-lg-4 col-md-2  ">
                <div>
                  <TextBoxComponent
                    value={this.props.persCountryCode}
                    cssClass="e-outline"
                    placeholder="ISD code"
                    data-msg-containerid="persCountryCodeErr"
                    name="persCountryCodeErr"
                    blur={(e) => this.getBasicInFormation(e, "persCountryCode")}
                    htmlAttributes={{ maxlength: 10 }}
                  />
                  <div
                    id="persCountryCodeErr"
                    className="e-input-group e-float-icon-left error-div"
                  />
                </div>
              </div>
              <div className="col-xs-2 col-sm-2 col-lg-8 col-md-2">
                <div>
                  <TextBoxComponent
                    id="MobileNumber"
                    floatLabelType="Auto"
                    value={mobileNumber}
                    data-msg-containerid="mobilenumberErr"
                    name="mobilenumberErr"
                    cssClass="e-outline"
                    placeholder="Personal Mobile Number *"
                    blur={(e) => this.getBasicInFormation(e, "MobileNumber")}
                    htmlAttributes={{ maxlength: 15 }}
                  />
                  <div
                    id="mobilenumberErr"
                    className="e-input-group e-float-icon-left error-div"
                  />
                </div>
              </div>
            </div>
            {/* </div> */}
            <div className="col-xs-2 col-sm-2 col-lg-3 col-md-12  ">
              <div className="col-xs-2 col-sm-2 col-lg-4 col-md-2  ">
                <div>
                  <TextBoxComponent
                    value={this.props.altCountryCode}
                    cssClass="e-outline"
                    placeholder="ISD code"
                    blur={(e) => this.getBasicInFormation(e, "altCountryCode")}
                    htmlAttributes={{ maxlength: 10 }}
                  />
                </div>
              </div>
              <div className="col-xs-2 col-sm-2 col-lg-8 col-md-2  ">
                <div>
                  <TextBoxComponent
                    id="AlternateMobile"
                    floatLabelType="Auto"
                    data-msg-containerid="alternatenumberErr"
                    name="alternatenumberErr"
                    value={alternetNumber}
                    cssClass="e-outline"
                    placeholder="Alternate Mobile Number"
                    blur={(e) => this.getBasicInFormation(e, "AlternateMobile")}
                    htmlAttributes={{ maxlength: 15 }}
                  />
                  <div
                    id="alternatenumberErr"
                    className="e-input-group e-float-icon-left error-div"
                  />
                </div>
              </div>
            </div>
            <div className="col-xs-2 col-sm-2 col-lg-3 col-md-2  ">
              <div>
                <TextBoxComponent
                  id="EmailID"
                  floatLabelType="Auto"
                  value={emailId}
                  data-msg-containerid="emailidErr"
                  name="emailidErr"
                  cssClass="e-outline"
                  placeholder="Personal Email ID *"
                  blur={(e) => this.getBasicInFormation(e, "EmailID")}
                  htmlAttributes={{ maxlength: 100 }}
                />
                <div
                  id="emailidErr"
                  className="e-input-group e-float-icon-left error-div"
                />
              </div>
            </div>
          </div>
          <div
            className="row card-layout"
            style={{
              paddingLeft: "30px",
              paddingRight: "30px",
              // paddingTop: "10px",
            }}
          >
            <div className="col-xs-2 col-sm-2 col-lg-3 col-md-2  ">
              <div>
                <TextBoxComponent
                  id="Address1"
                  placeholder="Address Line 1 *"
                  floatLabelType="Auto"
                  // multiline={true}
                  value={address}
                  data-msg-containerid="address1Err"
                  name="address1Err"
                  cssClass="e-outline"
                  blur={(e) => this.getBasicInFormation(e, "Address1")}
                  htmlAttributes={{ maxlength: 100 }}
                />
                <div
                  id="address1Err"
                  className="e-input-group e-float-icon-left error-div"
                />
              </div>
            </div>
            <div className="col-xs-2 col-sm-2 col-lg-3 col-md-2  ">
              <div>
                <TextBoxComponent
                  id="Address2"
                  placeholder="Address Line 2"
                  floatLabelType="Auto"
                  // multiline={true}
                  value={address2}
                  data-msg-containerid="address2Err"
                  name="address2Err"
                  cssClass="e-outline"
                  blur={(e) => this.getBasicInFormation(e, "Address2")}
                  htmlAttributes={{ maxlength: 100 }}
                />
                <div
                  id="address2Err"
                  className="e-input-group e-float-icon-left error-div"
                />
              </div>
            </div>
            <div className="col-xs-2 col-sm-2 col-lg-3 col-md-12  ">
              <div className="col-xs-2 col-sm-2 col-lg-6 col-md-2  ">
                <div>
                  <TextBoxComponent
                    id="pincode"
                    floatLabelType="Auto"
                    value={pincode}
                    data-msg-containerid="pincodeErr"
                    name="pincodeErr"
                    cssClass="e-outline"
                    placeholder="Pin/Zip Code *"
                    // onChange={(e) => this.getCityState(e)}
                    blur={(e) => this.getBasicInFormation(e, "pincode")}
                    htmlAttributes={{ maxlength: 10 }}
                  />
                  <div
                    id="pincodeErr"
                    className="e-input-group e-float-icon-left error-div"
                  />
                </div>
              </div>
              <div className="col-xs-2 col-sm-2 col-lg-6 col-md-2">
                <div>
                  <TextBoxComponent
                    id="city"
                    // fields={{fields:{text: "place_name"}}}
                    floatLabelType="Auto"
                    value={city}
                    data-msg-containerid="cityErr"
                    // dataSource = {this.state.cityStateData}
                    name="cityErr"
                    cssClass="e-outline"
                    placeholder="Enter City *"
                    blur={(e) => this.getBasicInFormation(e, "city")}
                    htmlAttributes={{ maxlength: 100 }}
                  />
                  <div
                    id="cityErr"
                    className="e-input-group e-float-icon-left error-div"
                  />
                </div>
              </div>
            </div>
            <div className="col-xs-2 col-sm-2 col-lg-3 col-md-12  ">
              <div className="col-xs-2 col-sm-2 col-lg-6 col-md-2">
                <div>
                  <TextBoxComponent
                    id="state"
                    value={state}
                    data-msg-containerid="stateErr"
                    name="stateErr"
                    allowCustom={false}
                    floatLabelType="Auto"
                    cssClass="e-outline"
                    placeholder="State *"
                    blur={(e) => this.getBasicInFormation(e, "state")}
                  />
                  <div
                    id="stateErr"
                    className="e-input-group e-float-icon-left error-div"
                  />
                </div>
              </div>
              <div>
                <div className="col-xs-2 col-sm-2 col-lg-6 col-md-2">
                  <TooltipComponent
                    closeOn="Click"
                    content={addressCountryId ? addressCountryMaster.filter(item => item.Id === addressCountryId)[0].Name : "Please select an option"}
                    position="BottomCenter"
                    isSticky={false}
                    cssClass='customtooltip'
                  >
                    <ComboBoxComponent
                      id="addressCountry"
                      dataSource={addressCountryMaster}
                      fields={{ text: "Name", value: "Id" }}
                      value={addressCountryId}
                      data-msg-containerid="addressCountryErr"
                      name="addressCountryErr"
                      // allowFiltering={true}
                      floatLabelType="Auto"
                      cssClass="e-outline"
                      placeholder="Address Country *"
                      select={(e) => this.getBasicInFormation(e, "AddressCountry")}
                      itemTemplate={this.dropdownItemTemplate}
                    />
                  </TooltipComponent>
                  <div
                    id="addressCountryErr"
                    className="e-input-group e-float-icon-left error-div"
                  />
                </div>
              </div>
            </div>
          </div>
          <div
            className="row card-layout"
            style={{
              paddingLeft: "30px",
              paddingRight: "30px",
              // paddingTop: "10px",
            }}
          >
            <div className="col-xs-2 col-sm-2 col-lg-3 col-md-2  ">
              <div>
                <ComboBoxComponent
                  id="HighestQualification"
                  dataSource={highestEducationQualifcation}
                  fields={{ text: "Name", value: "Id" }}
                  data-msg-containerid="highesteduErr"
                  name="highesteduErr"
                  value={highestEducationQualification}
                  floatLabelType="Auto"
                  cssClass="e-outline"
                  allowFiltering
                  allowCustom={false}
                  placeholder="Highest Qualification *"
                  select={(e) =>
                    this.getBasicInFormation(e, "HighestQualification")
                  }
                />
                <div
                  id="highesteduErr"
                  className="e-input-group e-float-icon-left error-div"
                />
              </div>
            </div>
            <div className="col-xs-2 col-sm-2 col-lg-3 col-md-2  ">
              <div>
                <TextBoxComponent
                  id="HighestEducationalSpecialization"
                  floatLabelType="Auto"
                  cssClass="e-outline"
                  value={highestEducationQualificationSpecial}
                  data-msg-containerid="highesteduspeErr"
                  name="highesteduspeErr"
                  placeholder="Specialization"
                  blur={(e) =>
                    this.getBasicInFormation(
                      e,
                      "HighestEducationalSpecialization"
                    )
                  }
                  htmlAttributes={{ maxlength: 100 }}
                />
                <div
                  id="highesteduspeErr"
                  className="e-input-group e-float-icon-left error-div"
                />
              </div>
            </div>
            <div className="col-xs-2 col-sm-2 col-lg-3 col-md-12  ">
              <div className="col-xs-2 col-sm-2 col-lg-6 col-md-2  ">
                <div>
                  <TextBoxComponent
                    /* <NumericTextBoxComponent */
                    id="TotalWorkExperience"
                    floatLabelType="Auto"
                    value={workExperiance}
                    data-msg-containerid="totalworkErr"
                    name="totalworkErr"
                    cssClass="e-outline"
                    placeholder="Exp. in Month"
                    // max={720}
                    onChange={(e) =>
                      this.getBasicInFormation(e, "TotalWorkExperience")
                    }
                    htmlAttributes={{ maxlength: 3 }}
                  />
                  <div
                    id="totalworkErr"
                    className="e-input-group e-float-icon-left error-div"
                  />
                </div>
              </div>
              {/* <div className="col-xs-2 col-sm-2 col-lg-6 col-md-2">
                <div>
                  <ComboBoxComponent
                    id="functionId"
                    fields={{ text: "Name", value: "Id" }}
                    floatLabelType="Auto"
                    value={functionId}
                    dataSource={stateFunctionList}
                    data-msg-containerid="functionErr"
                    name="functionErr"
                    cssClass="e-outline"
                    placeholder="Function *"
                    select={(e) => this.getBasicInFormation(e, "function")}
                  />
                  <div
                    id="functionErr"
                    className="e-input-group e-float-icon-left error-div"
                  />
                </div>
              </div> */}
            </div>
            <div className="col-xs-2 col-sm-2 col-lg-3 col-md-2 ">
              <div className={recruiterName ? "col-xs-11 col-sm-11 col-lg-11 col-md-11" : ""} style={{ paddingLeft: 0 }}>
                <ComboBoxComponent
                  id="Recruiter"
                  fields={{ text: "Name", value: "Id" }}
                  data-msg-containerid="recruiterErr"
                  name="recruiterErr"
                  // allowFiltering={true}
                  allowCustom={false}
                  value={recruitmentManagerId}
                  cssClass="e-outline"
                  floatLabelType="Auto"
                  // eslint-disable-next-line
                  dataSource={recruitementMaster}
                  select={(e) => this.getBasicInFormation(e, "Recruiter")}
                  placeholder="Recruiter *"
                // select={(e) => this.getBasicInFormation(e, "getSDSU")}
                />
                <div
                  id="recruiterErr"
                  className="e-input-group e-float-icon-left error-div"
                />
              </div>
              <div className="col-xs-1 col-sm-1 col-lg-1 col-md-1 preofferTooltipParent" style={{ paddingLeft: 0 }}>
                {recruiterName && <>
                  <FontAwesomeIcon
                    style={{ fontSize: 25, marginTop: ".3rem" }}
                    icon={faBinoculars}
                  // title={recruiterName}
                  />
                  <div className="preofferTooltip">{recruiterName}</div>
                </>}
              </div>            </div>
          </div>
          <div
            className="row card-layout"
            style={{
              paddingLeft: "30px",
              paddingRight: "30px",
              // paddingTop: "10px",
            }}
          >
            {/* {baseCounty === 585 && ( */}
            <div className="col-xs-2 col-sm-2 col-lg-3 col-md-2  ">
              <div>
                <TextBoxComponent
                  id="LegalName"
                  value={legalName}
                  data-msg-containerid="LegalNameErr"
                  name="LegalNameErr"
                  cssClass="e-outline"
                  floatLabelType="Auto"
                  placeholder="Legal Name  *"
                  blur={(e) => this.getBasicInFormation(e, "LegalName")}
                // htmlAttributes={{ maxlength: 15 }}
                />
                <div
                  id="LegalNameErr"
                  className="e-input-group e-float-icon-left error-div"
                />
              </div>
            </div>
            {addressCountryId === 101 && (
              <>
                <div className="col-xs-2 col-sm-2 col-lg-3 col-md-2 ">
                  <div>
                    <TextBoxComponent
                      id="PANNumber"
                      value={panNumber}
                      data-msg-containerid="PANNumberErr"
                      name="PANNumberErr"
                      placeholder={((empType === 677 || empType === 747 || empType === 746 || empType === 798) && addressCountryId === 101 && (candidateid > 0 && candidateid !== undefined && candidateid !== null) && (docsSubmitted !== undefined && docsSubmitted !== null && docsSubmitted === '1')) ? "PAN Number *" : "PAN Number"}
                      floatLabelType="Auto"
                      cssClass="e-outline"
                      blur={(e) => this.getBasicInFormation(e, "PANNumber")}
                    // htmlAttributes={{ maxlength: 15 }}
                    />
                    <div
                      id="PANNumberErr"
                      className="e-input-group e-float-icon-left error-div"
                    />
                  </div>
                </div>
                {/* <div className="col-xs-2 col-sm-2 col-lg-3 col-md-2  ">
                  <div>
                    <TextBoxComponent
                      id="AadhaarNumber"
                      value={adharNumber}
                      data-msg-containerid="AadhaarNumberErr"
                      name="AadhaarNumberErr"
                      cssClass="e-outline"
                      floatLabelType="Auto"
                      placeholder="Aadhaar Number *"
                      blur={(e) => this.getBasicInFormation(e, "AadhaarNumber")}
                      htmlAttributes={{ maxlength: 15 }}
                    />
                    <div
                      id="AadhaarNumberErr"
                      className="e-input-group e-float-icon-left error-div"
                    />
                  </div>
                </div> */}
                <div className="col-xs-2 col-sm-2 col-lg-3 col-md-12  ">
                  <div className="col-xs-2 col-sm-2 col-lg-6 col-md-2 ">
                    <div>
                      <TextBoxComponent
                        id="AadhaarNumber"
                        value={adharNumber}
                        data-msg-containerid="AadhaarNumberErr"
                        name="AadhaarNumberErr"
                        cssClass="e-outline"
                        floatLabelType="Auto"
                        placeholder={((empType === 677 || empType === 747 || empType === 746 || empType === 798) && addressCountryId === 101 && (candidateid > 0 && candidateid !== undefined && candidateid !== null) && (docsSubmitted !== undefined && docsSubmitted !== null && docsSubmitted === '1')) ? "Aadhaar Number *" : "Aadhaar Number"}
                        blur={(e) => this.getBasicInFormation(e, "AadhaarNumber")}
                      // htmlAttributes={{ maxlength: 15 }}
                      />
                      <div
                        id="AadhaarNumberErr"
                        className="e-input-group e-float-icon-left error-div"
                      />
                    </div>
                  </div>
                  <div className="col-xs-2 col-sm-2 col-lg-6 col-md-2  ">
                    <div>
                      <TextBoxComponent
                        id="UANNumber"
                        value={uanNumber}
                        data-msg-containerid="UANNumberErr"
                        name="UANNumberErr"
                        cssClass="e-outline"
                        floatLabelType="Auto"
                        placeholder="UAN Number "
                        blur={(e) => this.getBasicInFormation(e, "UANNumber")}
                      // htmlAttributes={{ maxlength: 25 }}
                      />
                      <div
                        id="UANNumberErr"
                        className="e-input-group e-float-icon-left error-div"
                      />
                    </div>
                  </div>
                </div>
              </>
            )}
            <div className="col-xs-2 col-sm-2 col-lg-3 col-md-2">
              <div>
                <TextBoxComponent
                  id="other"
                  placeholder="Other/Identification"
                  floatLabelType="Auto"
                  value={other}
                  data-msg-containerid="otherErr"
                  name="otherErr"
                  cssClass="e-outline"
                  onChange={(e) => this.getBasicInFormation(e, "Other")}
                  htmlAttributes={{ maxlength: 100 }}
                />
                <div
                  id="otherErr"
                  className="e-input-group e-float-icon-left error-div"
                />
              </div>
            </div>
          </div>
          {
            // countryType === "India" &&
            <div
              className="row card-layout"
              style={{
                paddingLeft: "30px",
                paddingRight: "30px",
                // paddingTop: "10px",
              }}
            >
              <div className="col-xs-2 col-sm-2 col-lg-3 col-md-2"
                style={{ padding: 0 }}
              >
                <div className="col-xs-2 col-sm-2 col-lg-6 col-md-2">
                  <div>
                    <ComboBoxComponent
                      id="currency"
                      dataSource={currencyMaster}
                      fields={{ text: "Name", value: "Id" }}
                      data-msg-containerid="currencyErr"
                      name="currencyErr"
                      value={currencyId}
                      floatLabelType="Auto"
                      cssClass="e-outline"
                      allowFiltering
                      allowCustom={false}
                      placeholder="Currency *"
                      select={(e) =>
                        this.getBasicInFormation(e, "currency")
                      }
                    />
                    <div
                      id="currencyErr"
                      className="e-input-group e-float-icon-left error-div"
                    />
                  </div>
                </div>
                <div className="col-xs-2 col-sm-2 col-lg-6 col-md-2">
                  <div>
                    <ComboBoxComponent
                      id="ctcFrequency"
                      dataSource={[{ Id: "1", Name: "Monthly" }, { Id: "2", Name: "Yearly" }]}
                      fields={{ text: "Name", value: "Id" }}
                      data-msg-containerid="ctcFrequencyErr"
                      name="ctcFrequencyErr"
                      value={ctcFrequency}
                      floatLabelType="Auto"
                      cssClass="e-outline"
                      allowFiltering
                      allowCustom={false}
                      placeholder="CTC Frequency *"
                      select={(e) =>
                        this.getBasicInFormation(e, "ctcFrequency")
                      }
                    />
                    <div
                      id="ctcFrequencyErr"
                      className="e-input-group e-float-icon-left error-div"
                    />
                  </div>
                </div>
              </div>
              <div className="col-xs-2 col-sm-2 col-lg-3 col-md-2">
                <div>
                  <TextBoxComponent
                    /* <NumericTextBoxComponent */
                    id="tctc"
                    floatLabelType="Auto"
                    value={tctc}
                    data-msg-containerid="tctcErr"
                    name="tctcErr"
                    cssClass="e-outline"
                    placeholder="TCTC / OTE *"
                    // max={720}
                    onChange={(e) =>
                      this.getBasicInFormation(e, "tctc")
                    }
                  // htmlAttributes={{ maxlength:  }}
                  />
                  <div
                    id="tctcErr"
                    className="e-input-group e-float-icon-left error-div"
                  />
                </div>
              </div>
              <div className="col-xs-2 col-sm-2 col-lg-3 col-md-12">
                <div className="col-xs-2 col-sm-2 col-lg-6 col-md-2">
                  <div>
                    <TextBoxComponent
                      /* <NumericTextBoxComponent */
                      id="oti"
                      floatLabelType="Auto"
                      value={oti}
                      data-msg-containerid="otiErr"
                      name="otiErr"
                      cssClass="e-outline"
                      placeholder="Variable/OTI *"
                      // max={720}
                      onChange={(e) =>
                        this.getBasicInFormation(e, "oti")
                      }
                    // htmlAttributes={{ maxlength: 3 }}
                    />
                    <div
                      id="otiErr"
                      className="e-input-group e-float-icon-left error-div"
                    />
                  </div>
                </div>
                <div className="col-xs-2 col-sm-2 col-lg-6 col-md-2">
                  <div>
                    <TextBoxComponent
                      /* <NumericTextBoxComponent */
                      id="fixedCtc"
                      floatLabelType="Auto"
                      value={fixedCTC}
                      data-msg-containerid="fixedCtcErr"
                      name="fixedCtcErr"
                      cssClass="e-outline"
                      placeholder="Fixed CTC *"
                      // max={720}
                      onChange={(e) =>
                        this.getBasicInFormation(e, "fixedCTC")
                      }
                    // htmlAttributes={{ maxlength: 3 }}
                    />
                    <div
                      id="fixedCtcErr"
                      className="e-input-group e-float-icon-left error-div"
                    />
                  </div>
                </div>
              </div>
              <div className="col-xs-2 col-sm-2 col-lg-3 col-md-2"
                style={{ padding: 0 }}
              >
                <div className="col-xs-2 col-sm-2 col-lg-6 col-md-2">
                  <div>
                    <ComboBoxComponent
                      id="SourceCategory"
                      dataSource={sourceCategoryData}
                      fields={{ text: "Name", value: "Id" }}
                      data-msg-containerid="sourceCategoryErr"
                      name="sourceCategoryErr"
                      value={sourceCategory}
                      floatLabelType="Auto"
                      cssClass="e-outline"
                      allowFiltering
                      allowCustom={false}
                      placeholder="Source Category *"
                      select={(e) =>
                        this.getBasicInFormation(e, "sourceCategory")
                      }
                    />
                    <div
                      id="sourceCategoryErr"
                      className="e-input-group e-float-icon-left error-div"
                    />
                  </div>
                </div>
                {
                  sourceCategory === 729 &&
                  <div className="col-xs-2 col-sm-2 col-lg-6 col-md-2">
                    <div>
                      <ComboBoxComponent
                        id="jobPortal"
                        dataSource={jobPortalData}
                        fields={{ text: "Name", value: "Id" }}
                        data-msg-containerid="jobPortalErr"
                        name="jobPortalErr"
                        value={jobPortalId}
                        floatLabelType="Auto"
                        cssClass="e-outline"
                        allowFiltering
                        allowCustom={false}
                        placeholder="Job Portal *"
                        select={(e) =>
                          this.getBasicInFormation(e, "jobPortal")
                        }
                      />
                      <div
                        id="jobPortalErr"
                        className="e-input-group e-float-icon-left error-div"
                      />
                    </div>
                  </div>
                }
                {
                  sourceCategory === 730 &&
                  <div className="col-xs-2 col-sm-2 col-lg-6 col-md-2">
                    <div className={referralName ? "col-xs-10 col-sm-10 col-lg-10 col-md-10" : ""} style={{ paddingLeft: 0 }}>
                      <TooltipComponent
                          closeOn="Click"
                          content={referralId ? othersCheckAvailablity.filter(item => item.UserId === referralId)[0].nameWithDepartment : "Please select an option"}
                          position="BottomCenter"
                          isSticky={false}
                          cssClass='customtooltip'>
                          <ComboBoxComponent
                            id="Referral"
                            dataSource={othersCheckAvailablity}
                            fields={{ text: "nameWithDepartment", value: "UserId" }}
                            data-msg-containerid="referralErr"
                            name="referralErr"
                            value={referralId}
                            floatLabelType="Auto"
                            cssClass="e-outline"
                            allowFiltering
                            allowCustom={false}
                            placeholder="Referral *"
                            select={(e) =>
                              this.getBasicInFormation(e, "referral")
                            }
                            itemTemplate={this.referralDropdownItemTemplate}
                          />
                        </TooltipComponent>
                        <div
                          id="referralErr"
                          className="e-input-group e-float-icon-left error-div"
                        />
                    </div>
                    <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2 preofferTooltipParentSp" style={{ paddingLeft: 0 }}>
                        {referralName && <>
                          <div className="preofferTooltipSp">{referralName}</div>

                          <FontAwesomeIcon
                            style={{ fontSize: 25, marginTop: ".3rem" }}
                            icon={faBinoculars}
                            // title={referralName}
                            // title={"Hello"}
                          />
                        </>}
                    </div>
                  </div>
                }
                {
                  sourceCategory === 731 &&
                  <div className="col-xs-2 col-sm-2 col-lg-6 col-md-2">
                    <div>
                      <TextBoxComponent
                        id="AgencyName"
                        placeholder="Agency Name *"
                        floatLabelType="Auto"
                        value={agencyName}
                        data-msg-containerid="agencyNameErr"
                        name="agencyNameErr"
                        cssClass="e-outline"
                        onChange={(e) => this.getBasicInFormation(e, "agencyName")}
                        htmlAttributes={{ maxlength: 100 }}
                      />
                      <div
                        id="agencyNameErr"
                        className="e-input-group e-float-icon-left error-div"
                      />
                    </div>
                  </div>
                }
              </div>
            </div>
          }
        </form>
      </div>
      // </form>
    );
  }
}
PreOfferBasicInformation.propTypes = {
  // eslint-disable-next-line
  salutation: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  firstName: PropTypes.string,
  middleName: PropTypes.string,
  lastName: PropTypes.string,
  gender: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  mobileNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  alternetNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  emailId: PropTypes.string,
  panNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  adharNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  uanNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  highestEducationQualification: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  highestEducationQualificationSpecial: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  workExperiance: PropTypes.number,
  title: PropTypes.string,
  role: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  band: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  empType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  baseCounty: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  baseLocation: PropTypes.string,
  entity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  reportingSupervisor: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  costSharing: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  address: PropTypes.string,
  dateOfBirth: PropTypes.number,
  readyToJoinDate: PropTypes.number,
  tenureEndDate: PropTypes.number,
  // openDocumentPopup: PropTypes.string,
  candidateid: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  // open_document_preview: PropTypes.string,
  imageName: PropTypes.string,
  servceDivisionSalesUnit: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  recruitmentManagerId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  // send_links: PropTypes.string,
  // openDocumentComments: PropTypes.string,
  // eslint-disable-next-line
  status: PropTypes.string,
  address2: PropTypes.string,
  city: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  pincode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  state: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  authUser: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  // salutationState: PropTypes.string,
  // genderstate: PropTypes.string,
  // stateMasterList: PropTypes.string,
  // highestEducationQualifcation: PropTypes.string,
  roleName: PropTypes.string,
  jobRole: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  // bandstate: PropTypes.string,
  // employeeType: PropTypes.string,
  // bacecountry: PropTypes.string,
  // entitystate: PropTypes.string,
  other: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  countryType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  functionId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  legalName: PropTypes.string,
  sourceCategory: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  sourceName: PropTypes.string,
  currencyId: PropTypes.number,
  ctcFrequency: PropTypes.number,
};
PreOfferBasicInformation.defaultProps = {
  salutation: "",
  firstName: "",
  middleName: "",
  lastName: "",
  gender: "",
  mobileNumber: "",
  alternetNumber: "",
  emailId: "",
  panNumber: "",
  adharNumber: "",
  uanNumber: "",
  highestEducationQualification: "",
  highestEducationQualificationSpecial: "",
  workExperiance: 0,
  title: "",
  role: "",
  band: "",
  empType: "",
  baseCounty: "",
  baseLocation: "",
  entity: "",
  reportingSupervisor: "",
  costSharing: "",
  address: "",
  dateOfBirth: null,
  readyToJoinDate: null,
  tenureEndDate: null,
  candidateid: 0,
  imageName: "",
  servceDivisionSalesUnit: "",
  recruitmentManagerId: 0,
  status: "",
  address2: "",
  city: "",
  pincode: "",
  state: "",
  authUser: "",
  roleName: "",
  jobRole: "",
  other: "",
  countryType: "",
  functionId: "",
  legalName: "",
  sourceCategory: 0,
  sourceName: '',
  layerId: null,
  subLayerId: null,
  addressCountryId: null,
  currencyId: null,
  ctcFrequency: null,
};
const mapStateToProps = ({ auth, meetingmaster, onboardingreducer, candidateReducer }) => {
  const { authUser } = auth;
  const {
    salutation,
    firstName,
    middleName,
    lastName,
    gender,
    mobileNumber,
    alternetNumber,
    emailId,
    panNumber,
    adharNumber,
    uanNumber,
    highestEducationQualification,
    highestEducationQualificationSpecial,
    workExperiance,
    title,
    role,
    band,
    empType,
    baseCounty,
    baseLocation,
    entity,
    reportingSupervisor,
    recruitmentManagerId,
    costSharing,
    address,
    dateOfBirth,
    readyToJoinDate,
    openDocumentPopup,
    candidateid,
    // eslint-disable-next-line
    open_document_preview,
    imageName,
    servceDivisionSalesUnit,
    status,
    roleName,
    address2,
    city,
    pincode,
    state,
    other,
    persCountryCode,
    altCountryCode,
    countryType,
    functionId,
    legalName,
    sourceCategory,
    jobPortalId,
    referralId,
    agencyName,
    tctc,
    oti,
    fixedCTC,
    layerId,
    subLayerId,
    addressCountryId,
    tenureEndDate,
    currencyId,
    ctcFrequency,
    subFunction,
    docsSubmitted,
    recruiterName,
    referralName,
    reportingSup
  } = onboardingreducer;
  const {
    // eslint-disable-next-line
    othersCheckAvailablity } = meetingmaster;
  const { sourceCategoryData, jobPortalData, jobTitleData, entityMaster, recruitementMaster, addressCountryMaster, currencyMaster } = candidateReducer;
  return {
    authUser,
    othersCheckAvailablity,
    salutation,
    firstName,
    middleName,
    lastName,
    gender,
    mobileNumber,
    alternetNumber,
    emailId,
    panNumber,
    adharNumber,
    uanNumber,
    highestEducationQualification,
    highestEducationQualificationSpecial,
    workExperiance,
    title,
    role,
    band,
    empType,
    baseCounty,
    baseLocation,
    entity,
    reportingSupervisor,
    recruitmentManagerId,
    costSharing,
    address,
    dateOfBirth,
    readyToJoinDate,
    openDocumentPopup,
    candidateid,
    open_document_preview,
    imageName,
    servceDivisionSalesUnit,
    status,
    roleName,
    address2,
    city,
    pincode,
    state,
    other,
    persCountryCode,
    altCountryCode,
    countryType,
    functionId,
    legalName,
    sourceCategory,
    sourceCategoryData, jobPortalData,
    jobPortalId,
    referralId,
    agencyName,
    tctc,
    oti,
    fixedCTC,
    jobTitleData,
    layerId,
    subLayerId,
    addressCountryId,
    entityMaster,
    recruitementMaster,
    addressCountryMaster,
    tenureEndDate,
    currencyId,
    ctcFrequency,
    currencyMaster,
    subFunction,
    docsSubmitted,
    recruiterName,
    referralName,
    reportingSup
  };
};
export default connect(mapStateToProps, { saveBasicInformationInRedux })(
  PreOfferBasicInformation
);
