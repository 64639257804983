/**
 * Author: Vivek Khodade.
 * Created:06-Jun-2020
 * Last Modify:06-Jun-2020
 * Modified By:Vivek Khodade
 */
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import axios from "axios";
import {
  SUBMIT_RESIGNATION_DATA, SUBMIT_WITHDRAWAL_DATA, SUBMIT_ABSCONDING_DATA, SUBMIT_TERMINATION_DATA, SUBMIT_EXIT_CLEARANCE_DATA, SUBMIT_LWD_RESIGNATION_COMPANY_REQUEST_AND_NPS
  , APPROVE_RESIGNATION, APPROVE_WITHDRAWL, INITIATE_CLEARANCE
} from "../../constant/entryexit/separationconstant";
import { addUpdateSeparationDetails } from "../../actions/entryexit/separationaction";
import { urlConfig } from "../../../config/config";
import { getNotify } from "../../../app/other/commonfunction";
import { ERROR, SUCCESS } from "../../../assets/constants/constants";
// Author :- Vivek Khodade
const submitResignatonDataDetailsRequest = async (data) => {
  return axios
    .post(`${urlConfig.tmlcApiUrl}separation/resign`, data, {
      headers: {
        authorization: localStorage.getItem("jwtToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((result) => result)
    .catch((error) => error.response);
};
const submitWithdrawalDataDetailsRequest = async (data) => {
  return axios
    .post(`${urlConfig.tmlcApiUrl}separation/withdrawl`, data, {
      headers: {
        authorization: localStorage.getItem("jwtToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((result) => result)
    .catch((error) => error.response);
};
const submitAbScondingDataDetailsRequest = async (data) => {
  // console.log("submitAbScondingDataDetailsRequest", data)
  const { userId, abscId } = data;
  let urlPath = "";
  if (abscId !== undefined) {
    urlPath = `${urlConfig.tmlcApiUrl}separation/absconding/${abscId}/${userId}`;
    delete data.abscId;
    delete data.userId;
  } else {
    urlPath = `${urlConfig.tmlcApiUrl}separation/absconding`;
  }
  return axios
    .post(urlPath, data, {
      headers: {
        authorization: localStorage.getItem("jwtToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((result) => result)
    .catch((error) => error.response);
};
const submitTerminationDataDetailsRequest = async (data) => {
  // console.log("submitTerminationDataDetailsRequest", data)
  if (data.type === 'commMem') {
    if (data.committeeMemebers.length > 0) {
      var commMembers = data.committeeMemebers;
      var termniId = data.termniId;
      var userId = data.userId;
      var newData = {};
      newData.type = data.type;
      newData.committeeMemebers = commMembers;
      return axios
        .post(`${urlConfig.tmlcApiUrl}separation/termination/${termniId}/${userId}`, newData, {
          headers: {
            authorization: localStorage.getItem("jwtToken"),
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((result) => result)
        .catch((error) => error.response);
    }
  }
  else if (data.type === 'termAct') {
    // console.log('data.terminatioAction ', data.terminatioAction)
    const termniIds = data.termniId;
    const userIds = data.userId;
    const newDatas = {};
    newDatas.type = data.type;
    newDatas.terminatioAction = data.terminatioAction;
    newDatas.terminationEmployeeId = data.terminationEmployeeId;
    return axios
      .post(`${urlConfig.tmlcApiUrl}separation/termination/${termniIds}/${userIds}`, newDatas, {
        headers: {
          authorization: localStorage.getItem("jwtToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((result) => result)
      .catch((error) => error.response);

  } else if (data.type === 'commRpt') {
    const termniIdCommRpt = data.termniId;
    const userIdCommRpt = data.userId;
    const newDataCommRpt = {};
    newDataCommRpt.type = data.type;
    newDataCommRpt.committeeReportNm = data.committeeReportNm;
    return axios
      .post(`${urlConfig.tmlcApiUrl}separation/termination/${termniIdCommRpt}/${userIdCommRpt}`, newDataCommRpt, {
        headers: {
          authorization: localStorage.getItem("jwtToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((result) => result)
      .catch((error) => error.response);
  }
  else {
    return axios
      .post(`${urlConfig.tmlcApiUrl}separation/termination`, data, {
        headers: {
          authorization: localStorage.getItem("jwtToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((result) => result)
      .catch((error) => error.response);
  }
};
const submiteExitClearanceDataDetailsRequest = async (data) => {
  // console.log("submiteExitClearanceDataDetailsRequest", data)
  let empId = data.empId;
  let userId = data.userId;
  delete data.empId;
  delete data.userId;
  return axios
    .post(`${urlConfig.tmlcApiUrl}separation/clearance/${empId}/${userId}`, data, {
      headers: {
        authorization: localStorage.getItem("jwtToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((result) => result)
    .catch((error) => error.response);
};

const approveResignationDetailsRequest = async (data) => {
  const userId = data.userId;
  const resgnId = data.resgnId;
  const empId = data.empId;

  delete data.userId;
  delete data.resgnId;
  delete data.empId;

  return axios
    .post(`${urlConfig.tmlcApiUrl}separation/resign/${resgnId}/${userId}/${empId}`, data, {
      headers: {
        authorization: localStorage.getItem("jwtToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((result) => result)
    .catch((error) => error.response);
};

const submiteResignationLWDAndNPSDataDetailsRequest = async (data) => { // NOSONAR
  const userId = data.userId;
  const resgnId = data.resgnId;
  const empId = data.empId;

  delete data.userId;
  delete data.resgnId;
  delete data.empId;

  return axios
    .post(`${urlConfig.tmlcApiUrl}separation/resign/${resgnId}/${userId}/${empId}`, data, {
      headers: {
        authorization: localStorage.getItem("jwtToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((result) => result)
    .catch((error) => error.response);
};



//Author: Suresh R. Sargam Date:2020-07-16
const approveWithdrawlDetailsRequest = async (data) => {
  const userId = data.userId;
  const withdrawlId = data.withdrawlId;
  const empId = data.empId;

  delete data.userId;
  delete data.withdrawlId;
  delete data.empId;

  return axios
    .post(`${urlConfig.tmlcApiUrl}separation/withdrawl/${withdrawlId}/${userId}/${empId}`, data, {
      headers: {
        authorization: localStorage.getItem("jwtToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((result) => result)
    .catch((error) => error.response);
};


//Author: Suresh R. Sargam Date:2020-07-16
const initiateClearanceDetailsRequest = async (data) => {
  const userId = data.userId;
  const empId = data.empId;
  delete data.userId;
  delete data.empId;
  return axios
    .post(`${urlConfig.tmlcApiUrl}separation/clearance/${empId}/${userId}`, data, {
      headers: {
        authorization: localStorage.getItem("jwtToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((result) => result)
    .catch((error) => error.response);
};

// Author :- Vivek Khodade
function* submitResignatonDataDetails({ payload }) {
  const resignationData = yield call(
    submitResignatonDataDetailsRequest,
    payload
  );
  if (resignationData.status === 200) {
    setTimeout(() => {
      getNotify(SUCCESS, "Resignation submitted successfully.");
    }, 100);
    yield put(addUpdateSeparationDetails({ updateResonForResignationStatus: 'updated' }))
  } else {
    getNotify(ERROR, resignationData.data.message.replace("payload: ", ""));
  }
}
// Author :- Vivek Khodade
function* submitWithdrawalDataDetails({ payload }) {
  const withdrawalData = yield call(
    submitWithdrawalDataDetailsRequest,
    payload
  );
  if (withdrawalData.status === 200) {
    setTimeout(() => {
      getNotify(SUCCESS, "Withdrawal details saved successfully.");
    }, 100);
    yield put(addUpdateSeparationDetails({ updateResonForResignationStatus: 'updated' }))
  } else {
    getNotify(ERROR, withdrawalData.data.message.replace("payload: ", ""));
  }
}
// Author :- Vivek Khodade
function* submitAbScondingDataDetails({ payload }) {
  const withdrawalData = yield call(
    submitAbScondingDataDetailsRequest,
    payload
  );
  if (withdrawalData.status === 200) {
    if (payload.type === "commData") {
      setTimeout(() => {
        getNotify(SUCCESS, "Document uploaded successfully.");
      }, 100);
    } else if (payload.type === "comments") {
      setTimeout(() => {
        getNotify(SUCCESS, "Comments added successfully.");
      }, 100);
    } else if (payload.type === "repByMgr" || payload.type === "repByHr") {
      setTimeout(() => {
        getNotify(SUCCESS, "Employee reported back successfully.");
      }, 1000);
    } else if (payload.type === "acknwByMgr" || payload.type === "acknwByHr") {
      setTimeout(() => {
        getNotify(SUCCESS, "Acknowledged successfully.");
      }, 1000);
    } else if (payload.type !== "back") {
      setTimeout(() => {
        getNotify(SUCCESS, "Absconded successfully.");
      }, 100);
    }
    yield put(addUpdateSeparationDetails({ updateResonForResignationStatus: 'updated', UpdateResignationStatus: 'StatusUpdate', updateAbscondingStatus: 'updated' }));
  } else {
    getNotify(ERROR, withdrawalData.data.message.replace("payload: ", ""));
  }
}
// Author :- Vivek Khodade
function* submitTerminationDataDetails({ payload }) {
  const withdrawalData = yield call(
    submitTerminationDataDetailsRequest,
    payload
  );
  if (withdrawalData.status === 200) {
    if (payload.type === "commRpt") {
      setTimeout(() => {
        getNotify(SUCCESS, "Committee report upload successfully.");
      }, 100);
    } else if (payload.type === "commMem") {
      setTimeout(() => {
        getNotify(SUCCESS, "Committee members updated successfully.");
      }, 100);
    } else if (payload.type === "termAct") {
      setTimeout(() => {
        getNotify(SUCCESS, "Action updated successfully."); // Termination action updated successfully. (chnage as per feedback)
      }, 100);
    } else {
      setTimeout(() => {
        getNotify(SUCCESS, "Employee terminated successfully.");
      }, 100);
    }

    yield put(addUpdateSeparationDetails({ updateResonForResignationStatus: 'updated', updateTerminationStatus: 'updated', UpdateResignationStatus: "StatusUpdate" }))
  } else {
    getNotify(ERROR, withdrawalData.data.message.replace("payload: ", ""));
  }
}
// Author :- Vivek Khodade
function* submiteExitClearanceDataDetails({ payload }) {
  const exitClearanceData = yield call(
    submiteExitClearanceDataDetailsRequest,
    payload
  );
  if (exitClearanceData.status === 200) {
    if (payload.type === "clsinit") {
      setTimeout(() => {
        getNotify(SUCCESS, "Clearance initiated successfully.");
      }, 100);
      yield put(addUpdateSeparationDetails({ UpdateResignationStatus: 'StatusUpdate' }))
      return;
    }
    else if (payload.type === "contiemp") {
      setTimeout(() => {
        getNotify(SUCCESS, "Employment continued successfully.");
      }, 100);
      yield put(addUpdateSeparationDetails({ UpdateResignationStatus: 'StatusUpdate' }))
      return;
    }
    else if (payload.type === "leftEmp") {
      setTimeout(() => {
        getNotify(SUCCESS, "Employee marked as 'Left' successfully.");
      }, 100);
      yield put(addUpdateSeparationDetails({ UpdateResignationStatus: 'LeftStatus' }))
      return;
    }

    setTimeout(() => {
      if (payload.type === 'item') {
        getNotify(SUCCESS, "Clearance completed successfully.");
      }
    }, 100);
    if (payload.type === 'item') {
      yield put(addUpdateSeparationDetails({ updateExitClearanceStatus: 'updated', clearanceType: '', clearanceItem: [], updateExitClearanceNavStatus: 'updated' }))
    }
  } else {
    getNotify(ERROR, exitClearanceData.data.message.replace("payload: ", ""));
  }
}
// Author :- Vivek Khodade, Suresh R. Sargam
function* submiteResignationLWDAndNPSDataDetails({ payload }) {
  const LWDorNPSData = yield call(
    submiteResignationLWDAndNPSDataDetailsRequest,
    payload
  );
  if (LWDorNPSData.status === 200) {
    if (payload.type === "LWDC")
      setTimeout(() => {
        getNotify(SUCCESS, "LWD(Company Requested) updated successfully.");
      }, 100);
    else
      setTimeout(() => {
        getNotify(SUCCESS, "Notice Period Status updated successfully.");
      }, 100);



    yield put(addUpdateSeparationDetails({ UpdateResignationStatus: 'LWDorNPSData' }))
  } else {
    getNotify(ERROR, LWDorNPSData.data.message.replace("payload: ", ""));
  }
}

// Author :- Suresh R. Sargam  
function* approveResignationDetails({ payload }) {
  const approveStatus = yield call(
    approveResignationDetailsRequest,
    payload
  );
  if (approveStatus.status === 200) {
    setTimeout(() => {
      getNotify(SUCCESS, "Resignation approval is confirmed.");
    }, 100);
    yield put(addUpdateSeparationDetails({ UpdateResignationStatus: 'StatusUpdate' }))
  } else {
    getNotify(ERROR, approveStatus.data.message.replace("payload: ", ""));
  }
}

// Author :- Suresh R. Sargam  
function* approveWithdrawlDetails({ payload }) {
  const approveStatus = yield call(
    approveWithdrawlDetailsRequest,
    payload
  );
  if (approveStatus.status === 200) {
    if (payload.approvalStatus === "1") {
      setTimeout(() => {
        getNotify(SUCCESS, "Withdrawal accepted successfully.");
      }, 100);
    } else if (payload.approvalStatus === "0") {
      setTimeout(() => {
        getNotify(SUCCESS, "Withdrawal rejected successfully.");
      }, 100);
    } else {
      setTimeout(() => {
        getNotify(SUCCESS, "Withdrawal accepted successfully.");
      }, 150);
    }
    yield put(addUpdateSeparationDetails({ UpdateWithdrawlStatus: 'StatusUpdate' }))
  } else {
    getNotify(ERROR, approveStatus.data.message.replace("payload: ", ""));
  }
}


// Author :- Suresh R. Sargam  
function* initiateClearanceDetails({ payload }) {
  // console.log(payload, 'initiateClearanceDetails')
  const initiateClrs = yield call(
    initiateClearanceDetailsRequest,
    payload
  );
  if (initiateClrs.status === 200) {
    setTimeout(() => {
      getNotify(SUCCESS, "Clearance initiated successfully.");
    }, 100);
    yield put(addUpdateSeparationDetails({ UpdateResignationStatus: 'ClearanceUpdate' }))
  } else {
    getNotify(ERROR, initiateClrs.data.message.replace("payload: ", ""));
  }
}



/**
 * Function Name: submitResignatonData
 * Sub-Functons: submitResignatonDataDetails, submitResignatonDataDetailsRequest
 * Description: Submit the resignation data.
 * Author: Vivek Khodade.
 */
export function* submitResignatonData() {
  yield takeEvery(
    SUBMIT_RESIGNATION_DATA,
    submitResignatonDataDetails
  );
}
export function* submitWithdrawalData() {
  yield takeEvery(
    SUBMIT_WITHDRAWAL_DATA,
    submitWithdrawalDataDetails
  );
}
export function* submitAbScondingData() {
  yield takeEvery(
    SUBMIT_ABSCONDING_DATA,
    submitAbScondingDataDetails
  );
}
export function* submitTerminationData() {
  yield takeEvery(
    SUBMIT_TERMINATION_DATA,
    submitTerminationDataDetails
  );
}
export function* submiteExitClearanceData() {
  yield takeEvery(
    SUBMIT_EXIT_CLEARANCE_DATA,
    submiteExitClearanceDataDetails
  );
}
export function* submiteResignationLWDAndNPSData() {
  yield takeEvery(
    SUBMIT_LWD_RESIGNATION_COMPANY_REQUEST_AND_NPS,
    submiteResignationLWDAndNPSDataDetails
  );
}
//Author : Suresh R. Sargam 2020-07-15
//Resigantion Approval
export function* approveResignation() {
  yield takeEvery(
    APPROVE_RESIGNATION,
    approveResignationDetails
  );
}
//Author : Suresh R. Sargam 2020-07-15
//Withdrawl Approval
export function* approveWithdrawl() {
  yield takeEvery(
    APPROVE_WITHDRAWL,
    approveWithdrawlDetails
  );
}

//Author : Suresh R. Sargam 2020-07-15
//Initiate Clearance
export function* initiateClearance() {
  yield takeEvery(
    INITIATE_CLEARANCE,
    initiateClearanceDetails
  );
}

export default function* rootSaga() {
  yield all([fork(submitResignatonData), fork(submitWithdrawalData), fork(submitAbScondingData),
  fork(submitTerminationData),
  fork(submiteExitClearanceData),
  fork(submiteResignationLWDAndNPSData),
  fork(approveResignation),
  fork(approveWithdrawl),
  fork(initiateClearance)
  ]);
}
