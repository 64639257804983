/**
 * Author: Vivek Khodade.
 * Last Modify:08-May-2020
 * Modified By:Vivek Khodade
 */
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import {
    SAVE_CANDIDATE_DATA, INITIATE_AND_REVOKE_All_CANDIDATE, SAVE_CANDIDATE_SALARY_DATA, SAVE_BASIC_INFORMATION, UPDATE_DOCUMENT_STATUS,
    GENERATE_APPOINTMENT_LETTER, UPDATE_FUNCTIONAL_ASSETS, ADD_DOCUMENT_COMMENTS, SYNC_USER, MOVE_DOCUMENTS, SYNC_ENTERPRISE_PROFILE
} from "../../constant/entryexit/onboardingconstant";
import axios from "axios";
import { urlConfig } from "../../../config/config";
import { getNotify,encryptMethod } from "../../../app/other/commonfunction";
import {
    ERROR, SUCCESS,
    employeeInitiateDetailsSaved, employeeRoleDetailsSaved, employeeRnDDetailsSaved, employeeAssetsDetailsSaved,
    employeeSalaryDetailsSaved, employeeAppointmentLetterGenerated,
    assetListUpdated, allAssetAccessHasBeenRemoved,
    initiateCandidateInConfirmation,
    revokeCandidateSuccessfully,
    initiateCandidateInOffered,
    employeeCodeGeneratedSuccessfully,
    initiateCandidateInReadyToJoin,
    documentCommentsAdded,
    documentRejectedSuccessfully,
    basicInformationUpdateSuccessfully,
    basicInformationSavedSuccessfully,
    documentSavedAsDraftSuccessfully,
    candidateDocMovementMessage
} from '../../../assets/constants/constants';
import { saveBasicInformationInRedux, addUpdateBasicInfo, updateStatusOnBoardingResucer } from '../../actions/entryexit/onboardingaction';
import { SUBMIT_CANDIDATE_DOCUMENT } from "../../constant/entryexit/candidateconstant";

// Author :- Vivek Khodade 
const savaCandidateSalaryDetailsRequest = async (data) => {
    let empid = data.empid;
    let authUserid = data.authUserid;
    delete data.empid;
    delete data.authUserid;
    return axios.post(urlConfig.tmlcApiUrl + 'employee/salary/' + empid + '/' + authUserid, data, {
        headers: {
            'authorization': localStorage.getItem('jwtToken'),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    }).then(result => result)
        .catch(error => error.response);
}

// Author : VJ - Call to API that generates PDF file of Employee Letter.
const generateAppointmentLetterPDF = async (data) => {
    let empid = data.empId;
    let authUserid = data.userId;
    let variableFlg = data.variable;
    let letterType = 1; // 1 => Appointment Letter , 2 => Confirmation Letter, 3 => Appraisal Letter, 4 => Experience Letter
    return axios.post(urlConfig.tmlcApiUrl + 'employee/letter/' + authUserid + '/' + empid + '/' + letterType + '/' + variableFlg, {}, {
        headers: {
            'authorization': localStorage.getItem('jwtToken'),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    })
        .then(result => result)
        .catch(error => error.response);
}

// Author : VJ - Call to API that fetches generated PDF file of Employee Letter.
// This function is not used as file location not need to be changed.
// const fetchAppointmentLetterPDF = async (data) => {
//     let empid = data.candidateid;
//     let authUserid = data.userid;
//     let filename = data.filename;
//     await axios.get(urlConfig.tmlcApiUrl + 'employee/letter/'+authUserid+'/'+empid+'/'+filename, { responseType: 'blob' })
//     .then((res) => {
//             const pdfBlob = new Blob([res.data], { type: 'application/pdf' });
//             saveAs(pdfBlob, 'myAppointment.pdf');
//         })
//     .catch(error => {
//         
//         //error.response
//     });
// }

// Author :- Vivek Khodade 
const saveCandidateDetailsRequest = async (data) => {

    let empid = data.employeeId;
    let typeid = data.tabId;
    let authUserid = data.authUserid;
    delete data.employeeId;
    delete data.tabId;
    delete data.authUserid;
    return axios.post(urlConfig.tmlcApiUrl + 'employee/onboard/' + empid + '/' + typeid + '/' + authUserid, data, {
        headers: {
            'authorization': localStorage.getItem('jwtToken'),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    }).then(result => result)
        .catch(error => error.response);
}
// Author :- Vivek Khodade 
const generateAppointmentLetterRequest = async (data) => {
    let empId = data.empId;
    let userId = data.userId;
    return axios.post(urlConfig.tmlcApiUrl + 'employee/appointmentletter/' + empId + '/' + userId, data, {
        headers: {
            'authorization': localStorage.getItem('jwtToken'),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    }).then(result => result)
        .catch(error => error.response);
}
// Author :- Vivek Khodade 
function* saveCandidateDetails({ payload }) {
    let taIds = payload.tabId;
    const candidateData = yield call(saveCandidateDetailsRequest, payload);

    if (candidateData.status === 200) {

        setTimeout(() => {
            if (taIds === 1) {
                getNotify(SUCCESS, employeeInitiateDetailsSaved)
            }
            if (taIds === 2) {
                getNotify(SUCCESS, employeeRoleDetailsSaved)
            }
            if (taIds === 5) {
                getNotify(SUCCESS, employeeRnDDetailsSaved)
            }
            if (taIds === 7) {
                getNotify(SUCCESS, employeeAssetsDetailsSaved)
            }
        }, 100)
    } else {
        getNotify(ERROR, candidateData.data.message.replace("payload: ", ""))
    }
}
// Author :- Vivek Khodade 
function* savaCandidateSalaryDetails({ payload }) {
    const candidateData = yield call(savaCandidateSalaryDetailsRequest, payload);

    if (candidateData.status === 200) {
        setTimeout(() => {
            getNotify(SUCCESS, employeeSalaryDetailsSaved)
        }, 100)
    } else {
        getNotify(ERROR, candidateData.data.message.replace("payload: ", ""))
    }
}
// Author :- Vivek Khodade 
function* generateAppointmentLetterDetails({ payload }) {
    const appointmentdata = yield call(generateAppointmentLetterRequest, payload);
    if (appointmentdata.status === 200) {
        // Generate PDF File. In Response, Filename of generated PDF file to be received.
        const appointmentLetterPDF = yield call(generateAppointmentLetterPDF, payload);
        if (appointmentLetterPDF.status === 200) {
            let fileName = appointmentLetterPDF.data;
            payload.fileName = fileName;
            payload.action = 'EDIT';

            // Update FileName against Employee
            const appointmentdataupdate = yield call(generateAppointmentLetterRequest, payload);
            if (appointmentdataupdate.status === 200) {
                setTimeout(() => {
                    getNotify(SUCCESS, employeeAppointmentLetterGenerated)
                }, 500)
                yield put(addUpdateBasicInfo({ apppintmentLetterStatus: 'updated' }))
            }
        }
    } else {
        getNotify(ERROR, appointmentdata.data.message.replace("payload: ", ""))
    }
}
// Author :- Vivek Khodade 
const updateFunctionalAssetsRequest = async (data) => {

    let candidateid = data.candidateid;
    let authUserid = data.authUserid;
    let typeid = data.typeid;
    delete data.candidateid;
    delete data.authUserid;
    delete data.typeid;
    return axios.post(urlConfig.tmlcApiUrl + 'candidate/func/' + typeid + '/' + candidateid + '/' + authUserid, data, {
        headers: {
            'authorization': localStorage.getItem('jwtToken'),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    }).then(result => result)
        .catch(error => error.response);
}
// Author :- Vivek Khodade 
function* updateFunctionalAssetsDetails({ payload }) {
    const typeid = payload.typeid;
    const candidateData = yield call(updateFunctionalAssetsRequest, payload);

    if (candidateData.status === 200) {
        if (typeid === 3) {
            yield put(addUpdateBasicInfo({ functionalAssetStatusInt: 'updated' }))
        }
        if (typeid === 4) {
            yield put(addUpdateBasicInfo({ functionalAssetStatusRevo: 'updated' }))
        }
        setTimeout(() => {
            if (typeid === 3) {
                getNotify(SUCCESS, assetListUpdated);
            } else {
                if (payload.func_info.length > 0) {
                    getNotify(SUCCESS, assetListUpdated);
                } else {
                    getNotify(SUCCESS, allAssetAccessHasBeenRemoved);
                }
            }
        }, 50)
    } else {
        getNotify(ERROR, candidateData.data.message.replace("payload: ", ""));
    }
}

// Add By Vinayak Jadhav
const syncUserDetailsRequest = async (data) => {
    axios.post(urlConfig.carApiUrl + 'user', data, {
        headers: {
            'authorization': localStorage.getItem('appToken'),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    }).then(result => {
        // console.log(result)
    }).catch(error => error.response);
    // delete data.app_id;
    // delete data.authUser;
    // delete data.user_role;
    // axios.post(urlConfig.tmlcApiUrl + 'syncusertoempmaster', data, {
    //     headers: {
    //         'authorization': localStorage.getItem('jwtToken'),
    //         'Accept': 'application/json',
    //         'Content-Type': 'application/json'
    //     }
    // }).then(BDresult => BDresult)
    //     .catch(error => error.response);

}
function* syncUserDetails({ payload }) {
    if (payload.user_pnl === null) delete payload.user_pnl;
    yield call(syncUserDetailsRequest, payload);
}
// Add By Prashant Waphare
const initiateOfferCandidateRequest = async (data) => {
    let authUserid = data.authUser;
    delete data.authUser;
    return axios.post(`${urlConfig.tmlcApiUrl}candidate/status/${encryptMethod(authUserid.toString())}`, data, {
        headers: {
            'authorization': localStorage.getItem('jwtToken'),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    }).then(result => result)
        .catch(error => error.response);
}
// Add By Prashant Waphare
function* initiateOfferCandidateDetails({ payload }) {
    const candidateData = yield call(initiateOfferCandidateRequest, payload);
    if (candidateData.status === 200) {
        if (payload.statusId === 5) {
            getNotify(SUCCESS, initiateCandidateInConfirmation);
            yield put(updateStatusOnBoardingResucer({ onboardStatus: 'updated', empid: candidateData.data.EmployeeId }));
        }
        if (payload.statusId === 4) {
            getNotify(SUCCESS, revokeCandidateSuccessfully);
        }
        if (payload.statusId === 2) {
            getNotify(SUCCESS, initiateCandidateInOffered);
        }
        if (payload.statusId === 3 && payload.revokeType === 'empCode') {
            getNotify(SUCCESS, employeeCodeGeneratedSuccessfully);
        } else if (payload.statusId === 3) {
            getNotify(SUCCESS, initiateCandidateInReadyToJoin);
        }
        var employeeId;
        if (typeof candidateData.data[0] !== 'undefined') employeeId = candidateData.data[0].EmployeeId;
        else if (typeof candidateData.data !== 'undefined') employeeId = candidateData.data.EmployeeId;
        yield put(updateStatusOnBoardingResucer({ status: 'updated', tobeofferStatus: 'updated', offerStatus: 'updated', empid: employeeId }));
    } else {
        getNotify(ERROR, candidateData.data.message.replace("payload: ", ""))
    }
}

// Author :- Suresh R. Sargam | 02-05-2020 
const updateDocStatusSagaDetailsRequest = async (data) => {
    let authUserid = data.authUser;
    delete data.authUser;
    return axios.post(urlConfig.tmlcApiUrl + 'document/' + encryptMethod(authUserid.toString()), data, {
        headers: {
            'authorization': localStorage.getItem('jwtToken'),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    }).then(result => result)
        .catch(error => error.response);
}

// Author :- Suresh R. Sargam | 02-05-2020 
const addDocCommentsSagaDetailsRequest = async (data) => {
    let authUserid = data.authUser;
    let doccatid = data.docCatId;
    let candid = data.candId;
    delete data.authUser;
    delete data.docCatId;
    delete data.candId;
    return axios.post(urlConfig.tmlcApiUrl + 'document/comments/' + authUserid + '/' + doccatid + (candid > 0 ? '/' + candid : ''), data, {
        headers: {
            'authorization': localStorage.getItem('jwtToken'),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    }).then(result => result)
        .catch(error => error.response);
}

// Author :- Suresh R. Sargam | 02-05-2020 
function* updateDocStatusSagaDetails({ payload }) {

    const docstatusData = yield call(updateDocStatusSagaDetailsRequest, payload);

    // console.log('docstatusData',docstatusData)
    if (docstatusData.status === 200) {
        let { status } = payload;
        setTimeout(() => {
            let currentStatus;
            if (status === 'A')
                currentStatus = 'approved';

            else if (status === 'R')
                currentStatus = 'rejected';
            else if (status === 'D')
                currentStatus = 'removed';
            else
                currentStatus = 'status removed';
            getNotify(SUCCESS, "Document " + currentStatus + " successfully.")
        }, 100)
        yield put(saveBasicInformationInRedux({ refreshDocStatus: 'app_rej_status' }));
    } else {
        getNotify(ERROR, docstatusData.data.message.replace("payload: ", ""))
    }
}

// Author :- Suresh R. Sargam | 02-05-2020 
function* addDocCommentsSagaDetails({ payload }) {
    let data = payload;
    const docstatusData = yield call(addDocCommentsSagaDetailsRequest, payload);
    if (docstatusData.status === 200) {
        setTimeout(() => {
            if (data.showMessage === undefined) {
                if (data.commentFor === "1") {
                    getNotify(SUCCESS, documentCommentsAdded)
                }
                if (data.commentFor === "2") {
                    getNotify(SUCCESS, documentRejectedSuccessfully)
                }
            }
        }, 100)
        // Once Add and Update Candidate Information reducer get empty
        yield put(saveBasicInformationInRedux({ refreshDocComments: 'update', refreshNewDoc: 'updated' }));
    } else {
        getNotify(ERROR, docstatusData.data.message.replace("payload: ", ""))
    }
}



// Author :- Suresh R. Sargam | 28-05-2020 
const submitCandidateDocsSagaDetailsRequest = async (data) => {
    let candId = data.candId;
    delete data.candId;
    return axios.post(urlConfig.tmlcApiUrl + 'document/submit/' + candId, data, {
        headers: {
            'authorization': localStorage.getItem('jwtToken'),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    }).then(result => result)
        .catch(error => error.response);
}

// Author :- Suresh R. Sargam | 28-05-2020 
function* submitCandidateDocsSagaDetails({ payload }) {
    const docstatusData = yield call(submitCandidateDocsSagaDetailsRequest, payload);
    if (docstatusData.status === 200) {
        setTimeout(() => {
            if (payload.type === "DRAFT") {
                getNotify(SUCCESS, documentSavedAsDraftSuccessfully)
            }
        }, 100)
        // Once Add and Update Candidate Information reducer get empty
        if (payload.type === "UPDATE") {
            yield put(saveBasicInformationInRedux({ isDocsSubmitted: true }));
        }
    } else {
        getNotify(ERROR, docstatusData.data.message.replace("payload: ", ""))
    }
}

// Author :- Prashant Waphare | 30-04-2020 
// change by :- Prashant Waphare | 05-04-2020 | add update API condition and call saprate
const saveBasicCandidateRequest = async (data) => {
    let candidateId = data.candidate_id;
    delete data.candidate_id;
    // This condition use for update information of candidate 
    if (candidateId > 0) {
        return axios.post(urlConfig.tmlcApiUrl + 'candidate/' + candidateId, data, {
            headers: {
                'authorization': localStorage.getItem('jwtToken'),
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(result => result)
            .catch(error => error.response);
    } else {
        // This API call for add new basic information of candidate 
        delete data.candidate_id;
        return axios.post(urlConfig.tmlcApiUrl + 'candidate', data, {
            headers: {
                'authorization': localStorage.getItem('jwtToken'),
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(result => result)
            .catch(error => error.response);
    }

}
// Author :- Prashant Waphare | 30-04-2020  
function* saveBasicCandidateDetails({ payload }) {
    let candidateId = payload.candidate_id;
    const saveCandidateData = yield call(saveBasicCandidateRequest, payload);
    if (saveCandidateData.status === 200) {
        if (candidateId > 0) {
            setTimeout(() => {
                getNotify(SUCCESS, basicInformationUpdateSuccessfully);
            }, 100)
        } else {
            setTimeout(() => {
                getNotify(SUCCESS, basicInformationSavedSuccessfully);
            }, 100)
        }

        // Once Add and Update Candidate Information reducer get empty
        yield put(saveBasicInformationInRedux({
            candidateid: 0, salutation: '', firstName: '', middleName: '', lastName: '', gender: '', mobileNumber: '', alternetNumber: '', emailId: '',
            panNumber: '', adharNumber: '', uanNumber: '', highestEducationQualification: '', highestEducationQualificationSpecial: '', workExperiance: 0,
            title: '', role: '', band: '', empType: '', baseCounty: '', baseLocation: '', entity: '', reportingSupervisor: '', costSharing: 0, address: '',
            send_links: [], imageName: '', servceDivisionSalesUnit: '', status: 'updated', refresh_send_link_status: 'updated', dateOfBirth: null, readyToJoinDate: null, roleName: '',
            address2: '', city: '', pincode: '', state: '', other: '', persCountryCode: '', altCountryCode: '', recruitmentManagerId: 0, functionId: "", legalName: "", layerId: null,
            subLayerId: null, addressCountryId: null, tenureEndDate: null, currencyId: null, ctcFrequency: null, subFunction: null, docLinkForExternal: "Y"
        }));
    } else {
        setTimeout(() => {
            getNotify(ERROR, saveCandidateData.data.message.replace("payload: ", ""));
        }, 100)
    }
}
// Author :- NOOKESH KARRI | 25-03-2022 To move Candidate Documents to Employee
export const moveCandidatesDocumentsReq = async (data) => {
    // console.log(data)
    return axios.post(urlConfig.tmlcApiUrl + 'candidate/status/movedocuments/' + data, {}, {
        headers: {
            'authorization': localStorage.getItem('jwtToken'),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    }).then(result => result)
        .catch(error => error.response);
}
// Author :- NOOKESH KARRI | 25-03-2022 To move Candidate Documents to Employee
function* moveCandidatesDocuments({ payload }) {

    let candidateId = payload;
    const saveCandidateData = yield call(moveCandidatesDocumentsReq, candidateId);
    if (saveCandidateData.data.success === false) {
        setTimeout(() => {
            getNotify(ERROR, saveCandidateData.data.message);
        }, 100)

    } else {
        setTimeout(() => {
            getNotify(SUCCESS, candidateDocMovementMessage);
        }, 100)
    }
    return false;
}

// Author :- Keval Charla
const saveTMInEnterpriseProfile = async (data) => {
    return axios.post(urlConfig.tmlcApiUrl + 'enterpriseprofile', data.payload, {
        headers: {
            // 'authorization': localStorage.getItem('jwtToken'),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }
    }).then(result => result)
        .catch(error => error.response);
}

// Author :- Prashant Waphare | 30-04-2020  | Add Basic Information Of Candidate (Pre offer).
export function* savaBasicDetailsSaga() {
    yield takeEvery(SAVE_BASIC_INFORMATION, saveBasicCandidateDetails);
}
/**
 * Function Name: savaCandidateSalary
 * Sub-Functons: savaCandidateSalaryDetails, saveCandidateDetailsRequest
 * Description: save candidate data from Employee Profile tabs
 * Author: Vivek Khodade.
 */
export function* savaCandidateSalary() {
    yield takeEvery(SAVE_CANDIDATE_SALARY_DATA, savaCandidateSalaryDetails);
}
/**
 * Function Name: saveCandidate
 * Sub-Functons: saveCandidateDetails, savaCandidateSalaryDetailsRequest
 * Description: save candidate data from Employee Profile tabs
 * Author: Vivek Khodade.
 */
export function* saveCandidate() {
    yield takeEvery(SAVE_CANDIDATE_DATA, saveCandidateDetails);
}

// Add By Prashant Waphare
export function* initiateOfferSaga() {
    yield takeEvery(INITIATE_AND_REVOKE_All_CANDIDATE, initiateOfferCandidateDetails);
}

// Author :- Suresh R. Sargam | 02-05-2020 
export function* updateDocStatusSaga() {
    yield takeEvery(UPDATE_DOCUMENT_STATUS, updateDocStatusSagaDetails);
}

/**
 * Function Name: addDocCommentsSaga
 * Sub-Functons: addDocCommentsSagaDetails, addDocCommentsSagaDetailsRequest
 * Description: To add comments against the document
 * Author: Suresh R. Sargam.
 */
export function* addDocCommentsSaga() {
    yield takeEvery(ADD_DOCUMENT_COMMENTS, addDocCommentsSagaDetails);
}

// Add By Keval Charla
export function* saveToEnterpriseProfile() {
    yield takeEvery(SYNC_ENTERPRISE_PROFILE, saveTMInEnterpriseProfile);
}

/**
 * Function Name: submitCandidateDocsSaga
 * Sub-Functons: submitCandidateDocsSagaDetails, submitCandidateDocsSagaRequest
 * Description: To update the candidate submission status
 * Author: Suresh R. Sargam.
 */
export function* submitCandidateDocsSaga() {
    yield takeEvery(SUBMIT_CANDIDATE_DOCUMENT, submitCandidateDocsSagaDetails);
}

/**
 * Function Name: generateAppointmentLatter
 * Sub-Functons: generateAppointmentLetterDetails, generateAppointmentLetterRequest
 * Description: To generate the Employee Appointment letter.
 * Author: Vivek Khodade.
 */
export function* generateAppointmentLatter() {
    yield takeEvery(GENERATE_APPOINTMENT_LETTER, generateAppointmentLetterDetails);
}
/**
 * Function Name: updateFunctionalAssets
 * Sub-Functons: updateFunctionalAssetsDetails, updateFunctionalAssetsRequest
 * Description: save candidate data from Employee Profile tabs
 * Author: Vivek Khodade.
 */
export function* updateFunctionalAssets() {
    yield takeEvery(UPDATE_FUNCTIONAL_ASSETS, updateFunctionalAssetsDetails);
}

export function* syncUser() {
    yield takeEvery(SYNC_USER, syncUserDetails);
}
export function* moveCandDocuments() {
    yield takeEvery(MOVE_DOCUMENTS, moveCandidatesDocuments);
}
export default function* rootSaga() {
    yield all([fork(saveCandidate), fork(initiateOfferSaga), fork(savaCandidateSalary), fork(savaBasicDetailsSaga),
    fork(updateDocStatusSaga),
    fork(addDocCommentsSaga),
    fork(generateAppointmentLatter),
    fork(updateFunctionalAssets),
    fork(submitCandidateDocsSaga),
    fork(syncUser),
    fork(moveCandDocuments),
    fork(saveToEnterpriseProfile),
    ]);
}
