import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Button } from "@material-ui/core";
import { updateLnAData } from '../../../../redux/actions/lna/lnaaction'


export const LeaveTransferDetails = (props) => {
    const { leaveData, moduletype } = props;

    let [height, setHeight] = useState(window.innerHeight - 260)
    const updateDimensions = () => {
        const updateheight = window.innerHeight - 260
        setHeight(updateheight)
    }
    useEffect(() => {
        window.addEventListener("resize", updateDimensions);
    }, [])


    return (
        <>

            <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                <div style={{
                    backgroundColor: '#F2F2F2',
                    height: "3rem",
                    display: "flex",
                    borderStartEndRadius: "0.4em",
                    borderStartStartRadius: "0.4em",
                    alignItems: "center",
                    paddingLeft: "1rem",
                    fontWeight: 600,
                    width: '100%',
                }}
                >
                    <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12"
                        style={{ color: 'black', fontStyle: 'Circular Std Book', fontSize: '1rem', fontFamily: "Arial" }}
                    >
                        Leave Transfer Details
                    </div>
                </div>
            </div>


            <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12' style={{ overflowY: "auto", height:height }} >

                <div className='col-xs-12 col-sm-12 col-lg-12 col-md-12 pt-sm'>
                    <div className='col-xs-8 col-sm-8 col-lg-8 col-md-8 pt-sm'>
                        <div className="regular-container-head"> Source Leave Type</div>
                        <div className="regular-container-head-res">{leaveData.sourceLeave}</div>
                    </div>

                    <div className='col-xs-4 col-sm-4 col-lg-4 col-md-4 pt-sm'>
                        <div className="regular-container-head">Destination Leave Type </div>
                        <div className="regular-container-head-res">{leaveData.destinationLeave}</div>
                    </div>

                    <div className='col-xs-8 col-sm-8 col-lg-8 col-md-8 pt-md'>
                        <div className="regular-container-head">No. Of Leaves To Be Transferred</div>
                        <div className="regular-container-head-res" style={{ marginLeft: '15px' }}>{leaveData.noOfDays}</div>
                    </div>
                    <div className='col-xs-4 col-sm-4 col-lg-4 col-md-4 pt-md'>
                        <div className="regular-container-head">Status</div>
                        <div className="regular-container-head-res">{leaveData.status}</div>
                    </div>

                    <div className='col-xs-12 col-sm-12 col-lg-12 col-md-12 pt-md'>
                        <div className="regular-container-head" style={{ marginLeft: '15px' }}>Reason</div>
                        <div className="regular-container-head-res" style={{ marginLeft: '15px', whiteSpace: 'normal', maxHeight: 100, overflowY: 'auto', marginRight: '10px' }}>{leaveData.reason} </div>
                    </div>
                </div>
            </div>

            <div className='topBorder' >
                <div
                    className='col-sm-12 col-md-12 col-lg-12 pb-lg pt-md'
                    style={{
                        display: "flex",
                        justifyContent: "right",
                        alignItems: "center",
                        textAlign: 'right',
                        height: '60px',
                        width: '100%',
                        position: 'absolute',
                        bottom: '0',
                        paddingRight: "10px",
                    }}>
                    {moduletype !== "approvals" && <Button
                        className="leave-cancel-apply-button"
                        style={{ width: "114px" }}
                        variant="contained"
                        onClick={() => {
                            props.updateLnAData({
                                leaveApplyLoadName: 'leave-tabs',
                                leaveApplicationId: 0
                            })
                        }}
                    >
                        Close
                    </Button>}

                </div>
            </div>


        </>

    )
}
const mapStateToProps = ({ meetingcategory, lnareducer, auth, meetingmaster }) => {
    const { authUser } = auth;
    const { categoryName } = meetingcategory;
    const { othersCheckAvailablity } = meetingmaster;
    const { leaveTransferFlag, leaveData } = lnareducer;
    const { leaveType, leaveStartDate, leaveEndDate, leaveReason, notificationTo, reliever, leaveTypeData, relieverData, leaveApplyStatus, isStreched, isWeekendCounted, leaveApplyLoadName } = lnareducer;
    return {
        categoryName,
        leaveType, leaveStartDate, leaveEndDate, leaveReason, notificationTo, reliever, authUser, leaveTypeData, othersCheckAvailablity, relieverData, leaveApplyStatus,
        isStreched, isWeekendCounted, leaveTransferFlag, leaveApplyLoadName, leaveData
    }
}

export default connect(
    mapStateToProps,
    { updateLnAData }
)(LeaveTransferDetails);