import React, { useState, useEffect } from "react";
import { connect } from 'react-redux';
import { Button, Dialog, DialogTitle } from "@material-ui/core";
import { faEye, faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import "../../app/layout/okrratinglayout/okrrating.css";
import ViewInterimReviewModal from './viewinterimreviewmodal';
import { DialogContent } from "../customdialog/customdialog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AddEditInterimReviewModal from "./addeditinterimreviewmodal";
import { addUpdateOkrRatingRedux } from '../../redux/actions/okrrating/okrratingaction'

import OKRRatingServices from '../../services/okrrating/okrratingservices'
const ViewkrInterimFeedbackModel = (props) => {
  const [interimFeedbackData, setInterimFeedbackData] = useState([])
  const [viewInterimReview, setInterimReviewData] = useState({ viewInterimReviewPopup: false, viewInterimReviewData: {} })
  const [editInterimPopup, setEditInterimPopup] = useState(false)

  const closeViewInterimReviewPopup = () => {
    setInterimReviewData({ ...viewInterimReview, viewInterimReviewPopup: false, viewInterimReviewData: {} });
  }
  const getInrerimData = async () => {
    const { authUser, empId, fyId, objId, monthOfRating } = props
    const ratingData = await OKRRatingServices.getInterimReviewInfo(authUser, empId, objId, fyId, 'all', 0, monthOfRating)

    if (typeof ratingData !== 'undefined' && ratingData !== null && ratingData.length > 0) {
      const interimFeedback = ratingData.filter((t) => t.isDraft === 'N')
      setInterimFeedbackData(interimFeedback)
    }
  }
  // eslint-disable-next-line
  useEffect(() => {
    // eslint-disable-next-line
    getInrerimData();
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (props.refreshInterimfeedbackflag === 'updated') {
      props.addUpdateOkrRatingRedux({ refreshInterimfeedbackflag: '' })
      setEditInterimPopup(false)
      getInrerimData();
    }
    // eslint-disable-next-line
  }, [props.refreshInterimfeedbackflag])

  const closeAddEditIntrimFeedbackPopup = () => {
    setEditInterimPopup(false)
  }
  return (
    <div>
      <Dialog open={true} fullWidth maxWidth="md" onEscapeKeyDown={() => props.closeKrIntrimFeedbackPopup()}>
        <DialogTitle>
          <span
            className="add-new-self-header interim-feedback-header">
            {"Interim Feedback "} {`(${interimFeedbackData.length})`}
          </span>
          <span style={{ float: 'right', cursor: 'pointer', marginRight: -8 }} onClick={() => props.closeKrIntrimFeedbackPopup()} ><FontAwesomeIcon style={{ color: '#B7B7BB', fontSize: 20 }} icon={faTimes} /></span>
        </DialogTitle>
        <DialogContent style={{ paddingTop: 0 }}>
          <div className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <form>
              <div className="form-row">
                <div
                  className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12"
                  style={{
                    paddingBottom: 32,
                    paddingRight: 16,
                    paddingLeft: 10,
                  }}
                >
                  <div className="view-kr-modalrating-header-text">
                    Objective Name
                  </div>
                  <div className="view-kr-modalrating-text">
                    {props.objectiveName}
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="form-row view-interim-feedback-all" style={{ paddingLeft: 10, marginRight: 15 }}>
            <table className="table table-hover table-bordered scrollbar">
              <thead
                style={{ position: "sticky", top: 0, backgroundColor: '#F4F4F7', zIndex: 11 }}
                className="okr-rating-objective-grid-kr-header-text">
                <tr>
                  <th
                    style={{
                      textAlign: "center",
                    }}
                  >
                    Date
                  </th>
                  <th style={{ width: 200 }}>Key Result(s)</th>
                  <th>Comments</th>
                  <th style={{ textAlign: "center" }}>
                    Uploaded
                    <br />
                    Document
                  </th>
                  <th
                    style={{
                      textAlign: "center",
                    }}
                  >
                    Link
                  </th>
                  <th style={{ width: 200, textAlign: "left" }}>Added By</th>
                </tr>
              </thead>

              <tbody className="okr-interim-review-font">
                {interimFeedbackData.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <div className='interim-date-text' >{item.interimAddedOn}</div>
                      </td>

                      <td><div className='interim-kr-text' onClick={() => setInterimReviewData({ ...viewInterimReview, viewInterimReviewPopup: true, viewInterimReviewData: item })}>{item.kpi} </div></td>
                      <td><div className='interim-comment' onClick={() => setInterimReviewData({ ...viewInterimReview, viewInterimReviewPopup: true, viewInterimReviewData: item })}> {item.interimRemarks}</div></td>
                      <td style={{ textAlign: "center" }}>
                        <span className='interim-feedback-icon-color'>
                          <Button className='interim-feedback-icon-color' style={{ width: 40 }} onClick={() => setInterimReviewData({ ...viewInterimReview, viewInterimReviewPopup: true, viewInterimReviewData: item })}>
                            <FontAwesomeIcon
                              className='interim-feedback-icon-color'
                              style={{ color: "#F94F5E" }}
                              icon={faEye}
                            />
                          </Button>
                        </span>
                      </td>
                      <td style={{ textAlign: "center", width: 30 }}>
                        <span className='interim-feedback-icon-color'>
                          {item.interimReviewLink !== '' ? <a href={`${item.interimReviewLink}`} rel='noreferrer' target='_blank'>
                            <Button>
                              {" "}
                              <FontAwesomeIcon
                                className='interim-feedback-icon-color'
                                style={{ color: "#F94F5E" }}
                                icon={faExternalLinkAlt}
                              />
                            </Button>
                          </a> :
                            ""
                          }
                        </span>
                      </td>
                      <td><span className='rating-added-by-text'>{item.addedBy}</span></td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {
              viewInterimReview.viewInterimReviewPopup &&
              <ViewInterimReviewModal
                closeViewInterimReviewPopup={closeViewInterimReviewPopup}
                viewInterimReviewData={viewInterimReview.viewInterimReviewData}
              />
            }
            {editInterimPopup &&
              <AddEditInterimReviewModal
                closeAddEditIntrimFeedbackPopup={closeAddEditIntrimFeedbackPopup}
                type={"Add"}
                KRData={props.KRData}
                OKRType={props.OKRType}
                ratingTabs={props.ratingTabs}
              />
            }
          </div>
        </DialogContent>

      </Dialog>
    </div>
  );

}
const mapStateToProps = ({ okrratingreducer, auth }) => {
  const { authUser } = auth;
  const {
    empId,
    fyId, refreshInterimfeedbackflag,
    monthOfRating
  } = okrratingreducer;
  return {
    empId,
    fyId,
    authUser,
    refreshInterimfeedbackflag,
    monthOfRating
  };
};
export default connect(mapStateToProps, { addUpdateOkrRatingRedux })(
  ViewkrInterimFeedbackModel
);

