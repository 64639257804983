import React, { useEffect, useState } from "react";
import { ComboBoxComponent } from "@syncfusion/ej2-react-dropdowns";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import TextField from "@material-ui/core/TextField";
import { connect } from "react-redux";
import { addEditStatutoryClusterData, updateLnAData } from "../../redux/actions/lna/lnaaction";
import LnAServices from "../../services/lna/lnaservice";
import { FormValidator } from "@syncfusion/ej2-inputs";
import {
  enterCoutnry,
  enterDate,
  pleaseEnterAttendanceType,
  pleaseenterstatutoryname,
  regexOnlyLettersAndSpaces,
  selectDayBreak,
  pleaseEnterWorkdaysymbol,
} from "../../assets/constants/constants";
import UploadDocuments from "../okrratingcomponents/uploaddocuments";
import { getMySqlDateFormat } from "../../app/other/commonfunction";
import { createImageFilepathForLPView } from "../okrratingcomponents/ratingcommonfun";
import ProgressBar from "../progressbar/progressbar";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";

const SAMPLECSS = `
input.e-input, textarea.e-input, .e-input-group, .e-input-group.e-control-wrapper {
  font-size: 12px !important;
}

# statutoryNameErr{
  font-size:12px !important
}
`
const StatutoryCluster = (props) => {
  //NOSONAR
  let {
    officeCountry,
    statutoryData,
    officeLocation,
    dayBreakData,
    authUser,
    openPopup,
    editClusterId,
  } = props;


  const [changedData, setChangedData] = useState(false);
  const [initialLoad, setInitialLoad] = useState(false)
  const [state, setState] = useState({
    cityId: null,
    // cityName: null,
    countryId: null,
    // countryName: null,
    date: null,
    day: null,
    // dayBreak: null,
    dayBreakId: null,

    isActive: "1",
    statutoryClusterUid: null,
    statutoryDescription: null,
    statutoryName: null,
    statutorySymbolId: null,
    statutoryType: "1",

    statutoryDoc: null,
    statutoryPolicyDoc: [],
    fileName: null,
    oldFileStatus: "",
  });
  const [cityList, setCityList] = useState([]);


  const validOptions = {
    rules: {
      statutoryNameErr: {
        required: [true, pleaseenterstatutoryname],
        regex: [regexOnlyLettersAndSpaces, pleaseenterstatutoryname],
        minLength: [1, pleaseenterstatutoryname],
        maxLength: [50, 'should be maximum of 50 characters only.']
      },
      symbolErr: {
        required: [true, pleaseEnterWorkdaysymbol],
      },
      dateErr: {
        required: [true, enterDate],
      },
      // dayErr: {
      //   required: [true, enterDay],
      // },
      countryErr: {
        required: [true, enterCoutnry],
      },
      selectTypeErr: {
        required: [true, pleaseEnterAttendanceType],
      },
      selectDayBreakErr: {
        required: [true, selectDayBreak],
      },
    },
  };


  const TextFieldComponent = (prop) => {
    return <TextField {...prop} color="primary" disabled />;
  };

  useEffect(() => {
    props.updateLnAData({ popUpSource: 'Statutory Master' })
    getData();
  }, []);
  useEffect(() => {
    setTimeout(() => {
      props.setSubmitClick(saveStatutoryClusterData);
    }, 100);
  }, [state]);

  useEffect(() => {
    getCityList(state.countryId);
  }, [state.countryId]);
  useEffect(() => {
    getData();
  }, [editClusterId, openPopup]);
  useEffect(() => {

    if (props.lnaMasterFlag === "updated")

      props.changePopUpHandler("close");

  }, [props.lnaMasterFlag]);
  const getData = () => {
    if (openPopup === "add") {
      resetData()
    } else {
      (editClusterId !== null && props.currentFinancialYearId != null) &&
        (async () => {
          setInitialLoad(true)
          const StatutoryClusterData =
            await LnAServices.getUniqueStatutoryClusterData(
              authUser,
              props.currentFinancialYearId,
              editClusterId
            );
          const {
            cityId,
            countryId,
            date,
            day,
            dayBreakId,
            isActive,
            statutoryDescription,
            statutoryName,
            statutorySymbolId,
            statutoryType,
            statutoryDoc,
            fileName,
          } = StatutoryClusterData.Result[0];
          cityId && getCityList(countryId)
          let data = {
            cityId,
            countryId,
            date: date,
            day,
            dayBreakId,
            isActive,
            statutoryClusterUid: editClusterId,
            statutoryDescription,
            statutoryName,
            statutorySymbolId,
            // statutoryType,
            statutoryType: statutoryType,
            statutoryDoc,
            fileName,
            oldFileStatus: fileName,
          };
          setState((preState) => ({ ...preState, ...data }));
          setTimeout(() => {
            const addFormObject = new FormValidator("#statutoryclusterform", validOptions);
            addFormObject.validate();
          }, 100)
          setInitialLoad(false)
        })();


    }
  };
  const resetData = () => {
    let data = {
      cityId: null,
      countryId: null,
      date: null,
      day: null,
      dayBreakId: null,
      isActive: "1",
      statutoryClusterUid: null,
      statutoryDescription: null,
      statutoryName: null,
      statutorySymbolId: null,
      statutoryType: "1",
      statutoryDoc: null,
      statutoryPolicyDoc: [],
      fileName: null,
      oldFileStatus: "",
    };
    setState((preState) => ({ ...preState, ...data }));
  }
  const getCityList = (pCountryId) => {
    const cityListArray = [];
    officeLocation.map((item) => {
      if (item.optional1 == pCountryId) {
        cityListArray.push(item);
      }
    });
    setCityList(cityListArray);
  };

  const setDay = (e) => {
    let index = new Date(e.value).getDay();

    const weekDay = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    let day = weekDay[index];
    setState((preState) => ({ ...preState, day }));
  };

  const selectedFiles = (fileNms) => {
    try {
      setState((preState) => ({ ...preState, statutoryPolicyDoc: fileNms }));
      setTimeout(() => {
        setChangedData(!changedData);
      });
    } catch (error) { }
  };
  const removeFile = () => {
    setState(preState => ({ ...preState, fileName: "" }))
  }
  const documentDetails = (currentTime, file) => {
    setState((prevState) => ({
      ...prevState,
      fileName: file,
      oldFileStatus: file,
    }));
  };
  const inputChangeHandler = (e, type) => {

    let options = {};
    switch (type) {
      case "statutory":
        setState({
          ...state,
          statutoryName: e.value.trim(),
        });
        options = validOptions.rules['statutoryNameErr'];
        break;

      case "isActive":
        setState({
          ...state,
          isActive: e.checked === true ? "1" : "0",
        });

        break;

      case "symbol":
        setState({
          ...state,
          statutorySymbolId: e.value,
        });
        options = validOptions.rules['symbolErr'];
        break;
      case "description":
        setState({
          ...state,
          statutoryDescription: e.value && e.value.trim(),
        });
        break;
      case "date":
        setState({
          ...state,
          date: e.value,
        });
        options = validOptions.rules['dateErr'];
        if (document.getElementById("dateErr")) {
          document.getElementById("dateErr").innerHTML = "";
        }

        setDay(e);
        break;

      case "day":
        setState((prevState) => ({ ...prevState, day: e.value }));
        break;

      case "country":

        setState({
          ...state,
          countryId: e.value !== null ? e.value : "",
          cityId: null
        });
        getCityList(e.value);
        options = validOptions.rules['countryErr'];
        break;
      case "location":

        setState({
          ...state,
          cityId: e.value !== null ? e.value : null,
        });

        break;
      case "statutoryType":
        setState({
          ...state,
          statutoryType: e.itemData.Id,
        });
        options = validOptions.rules['countryErr'];
        break;
      case "daybreak":
        setState({
          ...state,
          dayBreakId: e.itemData !== null ? e.itemData.Id : "",
        });

        options = validOptions.rules['selectDayBreakErr'];
        break;
      default:
    }

    setTimeout(() => {

      if (state.statutoryName === null) {
        let sName = document.getElementById('statutoryNameErr')
        sName.style.display = 'block'
      }
      const addFormObject = new FormValidator("#statutoryclusterform", options);
      addFormObject.validate();
    }, 100);
  };

  const saveStatutoryClusterData = () => {
    const {
      cityId,
      // cityName,
      countryId,
      // countryName,
      date,
      day,
      // dayBreak,
      dayBreakId,
      // fyId,

      isActive,
      statutoryClusterUid,
      statutoryDescription,
      statutoryName,
      statutorySymbolId,
      statutoryType,
      statutoryDoc,
      fileName,
    } = state;
    const addFormObject = new FormValidator("#statutoryclusterform", validOptions);
    if (addFormObject.validate()) {
      setInitialLoad(true)
      let hD = getMySqlDateFormat(date); //output 2020-12-12 00:00:00      
      let statutoryClusterSaveData = {
        userId: authUser,
        type: statutoryClusterUid > 0 ? "EDIT" : "ADD",
        cityId,
        countryId,
        date: hD,
        day,
        dayBreakId,
        isActive,
        statutoryClusterUid,
        statutoryDescription,
        statutoryName,
        statutorySymbolId,
        statutoryType: statutoryType,
        statutoryDoc,
        fileName,
      };

      props.addEditStatutoryClusterData(statutoryClusterSaveData);
      setTimeout(() => {
        setInitialLoad(false)
      }, 10)
    }


  };

  const statuoryClusterTextBox = `.statuoryDesc .e-float-input.e-control-wrapper.e-multi-line-input textarea{
         height: 100px !important;
    }`;

  return (
    <div
      id="statutoryclusterform"
      style={{ height: "61.5vh", overflowY: "scroll" }}
    >
      <style>{SAMPLECSS}</style>
      <div className="col-xs-12 col-sm-12 col-lg-12 col-xl-12 mt-lg">
        <div>
          <TextBoxComponent
            id="statutoryName"
            autocomplete={'off'}
            data-msg-containerid="statutoryNameErr"
            name="statutoryNameErr"
            value={state.statutoryName}
            floatLabelType="Auto"
            cssClass="e-outline"
            placeholder="Statutory Name *"
            onChange={(e) => inputChangeHandler(e, "statutory")}
            htmlAttributes={{ maxlength: 50 }}
          />
          <div id="statutoryNameErr" className="error-div" />
        </div>
        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 mt-lg">
          <ComboBoxComponent
            // <TextBoxComponent
            id="symbolName"
            data-msg-containerid="symbolErr"
            name="symbolErr"
            autocomplete={'off'}
            dataSource={statutoryData}
            fields={{ text: "Name", value: "Id" }}
            value={state.statutorySymbolId}
            placeholder="Symbol *"
            floatLabelType="Auto"
            cssClass="e-outline"
            allowCustom={false}
            onChange={(e) => inputChangeHandler(e, "symbol")}
            htmlAttributes={{ maxlength: 50 }}
          />
          <div id="symbolErr" className="error-div" />
        </div>

        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 mt-lg statuoryDesc">
          <style>{statuoryClusterTextBox}</style>
          <TextBoxComponent
            data-msg-containerid="descriptionErr"
            id="description"
            value={state.statutoryDescription}
            multiline={true}
            autocomplete={'off'}
            name="descriptionErr"
            placeholder="Description "
            floatLabelType="Auto"
            cssClass="e-outline"
            onChange={(e) => inputChangeHandler(e, "description")}
            htmlAttributes={{ maxlength: 500 }}
            style={{ height: "80px !important" }}
          />
          <div id="descriptionErr" className="error-div" />
        </div>
      </div>

      <div className="col-xs-12 col-sm-12 col-lg-12 col-sm-12 mt-lg">
        <div
          className="col-xs-6 col-sm-6 col-lg-6 col-md-6"
          style={{ paddingLeft: "0px", paddingRight: "0px" }}
        >
          <DatePickerComponent
            style={{ width: "100%" }}
            id="dated"
            name="dateErr"
            data-msg-containerid="dateErr"
            format="dd-MMM-yyyy"
            value={state.date}
            onChange={(e) => inputChangeHandler(e, "date")}
            TextFieldComponent={TextFieldComponent}
            color="black"
            autoOk
            cssClass="e-outline"
            variant="inline"
            min={new Date(Date.now())}
            floatLabelType="Auto"
            label="dd-MMM-yyyy"
            placeholder="Date *"
            KeyboardButtonProps={{ "aria-label": "change date" }}
            InputLabelProps={{ shrink: true }}
          />
          <div
            id="dateErr"
            className="e-input-group e-float-icon-left error-div"
          />
        </div>
        <div
          className="col-xs-6 col-sm-6 col-lg-6 col-md-6"
          style={{ paddingRight: "0px" }}
        >
          <TextBoxComponent
            autocomplete={'off'}
            id="days"
            value={state.date && state.day}
            readonly={true}
            placeholder="Day "
            floatLabelType="Auto"
            cssClass="e-outline"
            onChange={(e) => inputChangeHandler(e, "day")}
            htmlAttributes={{ maxlength: 100 }}
          />
        </div>
      </div>

      <div className="col-xs-12 col-sm-12 col-lg-12 col-sm-12 ">
        <div
          className="col-xs-6 col-sm-6 col-lg-6 col-md-6"
          style={{ paddingLeft: "0px", paddingRight: "0px" }}
        >
          <ComboBoxComponent
            id="country"
            dataSource={officeCountry}
            fields={{ text: "Name", value: "Id" }}
            value={state.countryId}
            data-msg-containerid="countryErr"
            name="countryErr"
            floatLabelType="Auto"
            cssClass="e-outline"
            placeholder="Country *"
            allowCustom={false}
            onChange={(e) => inputChangeHandler(e, "country")}
          />
          <div
            id="countryErr"
            className="e-input-group e-float-icon-left error-div"
          />
        </div>

        <div
          className="col-xs-6 col-sm-6 col-lg-6 col-md-6"
          style={{ paddingRight: "0px" }}
        >
          {(cityList.length > 0 || props.openPopup == 'add') && <ComboBoxComponent
            id="locationCity"
            dataSource={cityList}
            fields={{ text: "Name", value: "Id" }}
            value={state.cityId}
            data-msg-containerid="locationCityErr"
            name="locationCityErr"
            floatLabelType="Auto"
            cssClass="e-outline"
            placeholder="Location/City "
            allowCustom={false}
            onChange={(e) => inputChangeHandler(e, "location")}
          />}

        </div>
      </div>

      <div className="col-xs-12 col-sm-12 col-lg-12 col-xl-12 mt-sm">
        <div className="col-sm-12 col-md-12 col-lg-12">
          <ComboBoxComponent
            id="selectType"
            dataSource={[
              { Name: "Fixed", Id: "1" },
              { Name: "Optional", Id: "0" },
            ]}
            fields={{ text: "Name", value: "Id" }}
            value={state.statutoryType}
            data-msg-containerid="selectTypeErr"
            name="selectTypeErr"
            floatLabelType="Auto"
            cssClass="e-outline"
            placeholder="Select Type *"
            allowCustom={false}
            select={(e) => inputChangeHandler(e, "statutoryType")}
          />
          <div
            id="selectTypeErr"
            className="e-input-group e-float-icon-left error-div"
          />
        </div>

        <div className="col-sm-12 col-md-12 col-lg-12">
          <ComboBoxComponent
            id="selectDayBreak"
            dataSource={dayBreakData}
            fields={{ text: "Name", value: "Id" }}
            value={state.dayBreakId}
            data-msg-containerid="selectDayBreakErr"
            name="selectDayBreakErr"
            floatLabelType="Auto"
            cssClass="e-outline"
            allowCustom={false}
            placeholder="Select Day Break *"
            select={(e) => inputChangeHandler(e, "daybreak")}
          />
          <div
            id="selectDayBreakErr"
            className="e-input-group e-float-icon-left error-div"
          />
        </div>
      </div>

      <div className="col-xs-12 col-sm-12 col-lg-12 col-xl-12 mb-lg">
        <div
          className="col-xs-12 col-sm-12 col-lg-12 col-md-12 "
          style={{ paddingLeft: 0 }}
        >
          <div
            style={{
              fontSize: ".95rem",
              color: "#939399",
              fontWeight: 500,
              padding: ".5rem 0",
            }}
          >
            Attach Policy
          </div>

          <UploadDocuments
            type={"statutory"}
            module={'lna'}
            allowmultiple={false}
            selectedFiles={selectedFiles}
            fileList={[]}
            apiUrl={"lnaApiUrl"}
            documentDetails={documentDetails}
          />
        </div>
        {state.fileName !== null && state.oldFileStatus !== null ? (
          <div style={{ paddingLeft: 8 }}>
            {createImageFilepathForLPView(
              [].concat(state.fileName),
              "document/lna/statutory",
              "lnaApiUrl", removeFile
            )}
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="loader-new1" id="spinnerContainer" >
        {initialLoad && <ProgressBar />}
      </div>
    </div>
  );
};

const mapStateToProps = ({ auth, lnareducer, meetingmaster }) => {
  const { authUser } = auth;
  const { currentFinancialYearId } = meetingmaster;
  const { symbolData, officeCountry, officeLocation, dayBreakData, lnaMasterFlag } =
    lnareducer;
  const { statutoryData } = symbolData;
  return {
    authUser,
    officeCountry,
    officeLocation,
    currentFinancialYearId,
    dayBreakData,
    statutoryData, lnaMasterFlag
  };
};

export default connect(mapStateToProps, {
  addEditStatutoryClusterData,
  updateLnAData,
})(StatutoryCluster);
