/**
 * Author Name :- Prashant Waphare
 * Date : 28-05-2020
 * Description :- This file use for  HR Resignation employee list
 */
import * as React from "react";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Filter,
  Inject,
  VirtualScroll,
  Sort,
  Page,
} from "@syncfusion/ej2-react-grids";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import "../../../app/containers/tmlc/tmlc.css";
import "../../../../src/components/separation/resignationcomponent/resignationcomponent.css"
import { connect } from "react-redux";
import { Button, Dialog } from "@material-ui/core";
import { Redirect } from "react-router-dom";
import {
  DialogContent,
  DialogActions,
  DialogTitle,
} from "../../customdialog/customdialog";
import {
  getDisplayDateOnly,
  encodeString,encrypt
  // decodeString
} from "../../../app/other/commonfunction";
import EmployeeNameTemplate from "../employeeNameTemplate";
import {
  getGridColumn,
  EMPLOYEE_NAME,
  REPORTING_SUPERVISOR_STATUS,
  PL_STATUS,
  HR_STATUS,
  ACTION,
  JOINING_DATE,
  DATE_OF_RESIGNATION,
  LWD_OFFICIAL,
  LWD_EMPLOYEE_REQUESTED,
  REASON,
  DAYS_DIFFERENCE,
  DAYS_TO_LWD,
  SEP_NOTICE_PERIOD,
  EMP_REMARK
} from "../../../app/other/gridcolumns";
import { DateFilterformat } from '../../../../src/config/config';
import { approveResignation, initiateClearance } from '../../../redux/actions/entryexit/separationaction';
import { AllDateFormat, PnlStatus, HrStatus, ReportingMgrStatus } from "../allcommoncomponents";
import { EMPLOYEE_PROFILE } from "../../../redux/constant/meetingcategoryconstant";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { setMeetingCategory } from '../../../redux/actions/meetingcategoryaction';
import { faPlayCircle, faCheckCircle } from '@fortawesome/pro-light-svg-icons'
library.add(faPlayCircle, faCheckCircle);
class HrResignationGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: null,
      actionState: false,
      selectedNoticeValue: "",
    };
    this.height =
      (window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight) - 252;
    this.width =
      (window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth) - 100;
    this.dReady = false;
    this.dtTime = false;
    this.isDataBound = false;
    this.isDataChanged = true;
    this.dropSlectedIndex = null;
    this.check = {
      type: "CheckBox",
    };
    this.select = {
      persistSelection: true,
      type: "Multiple",
      checkboxOnly: true,
    };
    this.Filter = {
      type: "Menu",
    };
    this.pageOptions = {
      pageSize:
        (window.innerHeight ||
          document.documentElement.clientHeight ||
          document.body.clientHeight) > 700
          ? 12
          : 6,
    };
    this.name = {
      type: "CheckBox",
    };
    this.employeeNameTemplate = this.employeeNameTemplate.bind(this);
    this.navigateToAddBasicInformation = this.navigateToAddBasicInformation.bind(
      this
    );
    this.Noticedata = [{ Id: 1, Name: "Waived Off" }];
    this.handleConfirmedYes = this.handleConfirmedYes.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.resgnId = '';
    this.taskId = '';
    this.empId = '';
    this.resignationStatus = '';
  }

  componentDidMount() {
    this.props.getHrResignationTabData();
    setTimeout(() => {
      const ele = document.getElementsByClassName("e-headercelldiv");
      for(let i in ele) {
        if(!isNaN(parseInt(i, 10))){
          let index = parseInt(i, 10);
          ele[index].classList.add("grid-left-header");
        }
      }
    }, 1000);
  }

  onQueryCellInfoPreJoining = (args) => {
    const { cell } = args;
    cell.setAttribute(
      "style",
      "font-size:14px !important; text-align: left !important; padding-left:18px !important"
    );
  };

  onDataBound() {
    clearTimeout(this.clrIntervalFun);
    clearInterval(this.intervalFun);
    this.dtTime = true;
  }

  onLoad() {
    document
      .getElementById("overviewgrid")
      .ej2_instances[0].on("data-ready", () => {
        this.dReady = true;
        this.stTime = performance.now();
      });
    document
      .getElementById("overviewgrid")
      .addEventListener("DOMSubtreeModified", () => {
        if (this.dReady && this.stTime && this.isDataChanged) {
          this.stTime = null;
          this.dReady = false;
          this.dtTime = false;
          this.isDataChanged = false;
        }
      });
  }

  reportingStatus = (props) => {
    const { reportingMgrStatus } = props;
    return (
      <ReportingMgrStatus reportingMgrStatus={reportingMgrStatus} />
    )
  };

  pnlStatus = (props) => {
    const { pnlHeadStatus } = props;
    return (
      <PnlStatus pnlHeadStatus={pnlHeadStatus} />
    )
  };

  hrStatus = (props) => {
    const { hrStatus } = props;
    return (
      <HrStatus hrStatus={hrStatus} />
    )
  };
  resignationReason = (props) => {
    const { resgnReason } = props;
    if(resgnReason!==null){
      return (
        <div>
          <TooltipComponent
            content={resgnReason.replace(/(?:\r\n|\r|\n|,)/g, '<br>')}
            position="BottomCenter"
            isSticky={false}
            cssClass='customtooltip'
          >
            <span
              style={{
                fontSize: "14px",
                display: "block",
                width: "100px",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              {resgnReason.replace(/[&\\#,+()$~%.'":*?<>{}]/g, ' ')}
            </span>
          </TooltipComponent>
        </div>
      );      
    }
    else{
        return (<></>)
    }
    
  };
  empRemark = (props) => {
    const { remarks } = props;
    return (
      <div>
        <TooltipComponent
          content={remarks.replace(/(?:\r\n|\r|\n|,)/g, '<br>')}
          position="BottomCenter"
          isSticky={true}
          opensOn="Click"
          cssClass='customtooltip'
        >
          <span 
            style={{
              fontSize: "14px",
              display: "block",
              width: "100px",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {remarks.replace(/[&\\#,+()$~%.'":*?<>{}]/g, ' ')}
          </span>
        </TooltipComponent>
      </div>
    );
  };
  joiningDateFormat = (props) => {
    const { joiningDate } = props
    return (
      <AllDateFormat date={joiningDate} />
    );
  };
  resignationDateFormat = (props) => {
    const { dateOfResignation } = props
    return (
      <AllDateFormat date={dateOfResignation} />
    );
  };
  lwdDateFormat = (props) => {
    const { lastWorkingDayOfficial } = props
    return (
      <AllDateFormat date={lastWorkingDayOfficial} />
    );
  };
  lwdEmpRequestedDateFormat = (props) => {
    const { lastworkingEmpRequested } = props
    return (
      <AllDateFormat date={lastworkingEmpRequested} />
    );
  };
  daysDifference = (props) => {
    const { daysDiffJoinSeparation } = props
    return (
      <div style={{ textAlign: "center" }}>
        <span>{daysDiffJoinSeparation}{" Days"}</span>
      </div>
    );
  };
  daysToLWD = (props) => {
    const { daysToLastWorkingDay } = props
    return (
      <div style={{ textAlign: "center" }}>
        <span>{daysToLastWorkingDay}{" Days"}</span>
      </div>
    );
  };

  noticePeriodDate = (props) => {
    const { noticePeriodInDays } = props;
    return (
      <div style={{ textAlign: "center" }}>
        <span>{noticePeriodInDays}{" Days"}</span>
      </div>

    );
  };
  actionToggle = (props, type) => {
    const { resgnUid, taskId, employeeId, resgnStatus } = props;
    this.resgnId = resgnUid;
    this.taskId = taskId;
    this.empId = employeeId;
    this.resignationStatus = (type === "0" ? "clearance" : resgnStatus);
    const { actionState } = this.state;
    this.setState({ actionState: !actionState });
  };


  handleAction = (props) => {
    const { actioned, resgnStatus, clearanceInitiated, reportingMgrStatus, pnlHeadStatus, daysToLastWorkingDay } = props
    if (reportingMgrStatus === "Pending" && pnlHeadStatus === "Pending" && daysToLastWorkingDay <= 2 && clearanceInitiated !== "Y") {
      return (
        <div className="col-4" style={{ paddingLeft: "10px" }}>
          <TooltipComponent content="Initiate Clearance" position="BottomCenter" isSticky={false} className="pointer-cursor"
            cssClass='customtooltip'>
            <FontAwesomeIcon icon={faPlayCircle} style={{ fontSize: "20px" }} onClick={(e) => this.actionToggle(props, "0")} />
          </TooltipComponent>
        </div>
      )
    } else if (clearanceInitiated === "Y") {
      return (<div style={{ paddingLeft: '10px' }}>
        <TooltipComponent
          content="Clearance Initiated"
          position="BottomCenter"
          cssClass='customtooltip'
          isSticky={false}>
          <FontAwesomeIcon icon={faPlayCircle} style={{ fontSize: "20px", color: "green" }} />
        </TooltipComponent>
      </div>);
    } else if (actioned === 'N') {
      return (
        <div style={{ paddingLeft: '10px' }}>
          <TooltipComponent
            content="Accept"
            position="BottomCenter"
            isSticky={false}
            cssClass='customtooltip'
            className="pointer-cursor">
            <FontAwesomeIcon icon={faCheckCircle} style={{ fontSize: "20px" }} onClick={() => this.actionToggle(props, "1")} />
          </TooltipComponent>
        </div>
      );
    }
    else if (resgnStatus === 'Accepted' && clearanceInitiated === "N") {
      return (<div style={{ paddingLeft: '10px' }}>
        <TooltipComponent
          content="Initiate Clearance"
          position="BottomCenter"
          className="pointer-cursor"
          cssClass='customtooltip'
          isSticky={false}>
          <FontAwesomeIcon icon={faPlayCircle} style={{ fontSize: "20px" }} onClick={() => this.actionToggle(props, "0")} />
        </TooltipComponent>
      </div>)
    }
    else if (actioned === 'Y') {
      return (
        <div style={{ paddingLeft: '10px' }}>
          <TooltipComponent
            content="Accepted"
            position="BottomCenter"
            isSticky={false}
            cssClass='customtooltip'          >
            <FontAwesomeIcon icon={faCheckCircle} style={{ fontSize: "20px", color: "green" }} />
          </TooltipComponent>
        </div>
      );
    } else {
      return (<></>)
    }
  };

  navigateToAddBasicInformation = async (args) => {
    try {
      localStorage.setItem('last_meeting_category', localStorage.getItem('meeting_category'));
      localStorage.setItem("meeting_category", encodeString(EMPLOYEE_PROFILE));

      const path = `/onboard?empId=${encrypt(args.employeeId)}`;
      this.setState({
        redirect: path
      }, () => this.props.setMeetingCategory(EMPLOYEE_PROFILE));
    } catch (error) {
      console.log("error", error);
    }
  };
  dateFormat = (props) => {
    return (
      <div style={{ textAlign: "center" }}>
        <span>{getDisplayDateOnly(new Date(props.JoiningDate))}</span>
      </div>
    );
  };

  employeeNameTemplate(props) {
    return (
      <EmployeeNameTemplate
        props={props}
        data="test"
        navigateToAddBasicInformation={this.navigateToAddBasicInformation}
      />
    );
  }

  handleConfirmedYes = () => {
    try {
      const { authUser } = this.props;
      if (this.resignationStatus === "Pending") {
        const approveData = {
          userId: authUser,
          resgnId: this.resgnId,
          taskId: this.taskId,
          empId: this.empId,
          type: "APPR"
        };
        this.props.approveResignation(approveData);
      } else if (this.resignationStatus === "clearance") {
        const clearanceData = {
          userId: authUser,
          empId: this.empId,
          type: "clsinit"
        };
        
        this.props.initiateClearance(clearanceData);
      }
    } catch (error) {
      console.log(error, 'error')
    }
    this.setState({ actionState: false });
  };

  handleClose = () => {
    this.setState({ actionState: false });
  }

  render() {
    const { redirect } = this.state;
    const { hrResignationData } = this.props;
    
    if (redirect !== null) {
      return <Redirect to={redirect} />;
    }
    var widthColumn = 0;
    // eslint-disable-next-line 
    
    // eslint-disable-next-line 
    getGridColumn("hrResignation").forEach((data) => {
      widthColumn = widthColumn + data.width;

    });
    return (
      <div
        className="row resignation-manager-hr-grid-height"
      // style={{ height: "828px" }}
      >
        <div>
          <div className="row-xs-12 row-sm-12 row-lg-12 row-md-12">
            <div
              style={{
                paddingLeft: "15px",
                paddingRight: "15px",
                paddingTop: "5px",
              }}
            >
              <GridComponent
                className="grid-head-custome"
                id="overviewgrid"
                allowPaging
                pageSettings={this.pageOptions}
                dataSource={hrResignationData}
                // dataSource={this.data}
                enableHover={false}
                enableVirtualization={false}
                rowHeight={30}
                height={this.height}
                ref={(g) => {
                  this.gridInstance = g;
                }}
                // actionComplete={this.onComplete.bind(this)}
                load={this.onLoad}
                queryCellInfo={this.onQueryCellInfoPreJoining}
                dataBound={this.onDataBound}
                filterSettings={this.Filter}
                allowFiltering
                allowSorting
                allowSelection 
                selectionSettings={this.select}
                allowTextWrap={true}

              >
                <ColumnsDirective>
                  {getGridColumn("hrResignation").map((item) => {
                    switch (item.colName) {
                      case EMPLOYEE_NAME:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.employeeNameTemplate}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                            filter={this.Filter}
                          />
                        );
                      case REPORTING_SUPERVISOR_STATUS:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.reportingStatus}
                            filter={this.check}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                          />
                        );
                      case PL_STATUS:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.pnlStatus}
                            filter={this.check}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                          />
                        );
                      case HR_STATUS:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.hrStatus}
                            filter={this.check}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                          />
                        );

                      case REASON:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.resignationReason}
                            allowFiltering={false}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                          />
                        );
                      case EMP_REMARK:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.empRemark}
                            allowFiltering={false}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                          />
                        );
                      case JOINING_DATE:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.joiningDateFormat}
                            // allowFiltering={false}
                            filter={this.check}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                            format={DateFilterformat}
                          />
                        );
                      case DATE_OF_RESIGNATION:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.resignationDateFormat}
                            // allowFiltering={false}
                            filter={this.check}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                            format={DateFilterformat}
                          />
                        );
                      case LWD_OFFICIAL:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.lwdDateFormat}
                            // allowFiltering={false}
                            filter={this.check}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                            format={DateFilterformat}
                          />
                        );
                      case LWD_EMPLOYEE_REQUESTED:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.lwdEmpRequestedDateFormat}
                            // allowFiltering={false}
                            filter={this.check}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                            format={DateFilterformat}
                          />
                        );
                      case DAYS_DIFFERENCE:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.daysDifference}
                            // allowFiltering={false}
                            filter={this.check}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                          />
                        );
                      case DAYS_TO_LWD:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.daysToLWD}
                            // allowFiltering={false}
                            filter={this.check}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                          />
                        );
                      case SEP_NOTICE_PERIOD:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.noticePeriodDate}
                            // allowFiltering={false}
                            filter={this.check}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                          />
                        );
                      case ACTION:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.handleAction}
                            allowFiltering={false}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                          />
                        );
                      default:
                        return (
                          <ColumnDirective
                            key={item.field}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            filter={this.check}
                            headerText={item.colName}
                            width={item.width}
                          // filter={this.check}
                          />
                        );
                    }
                  })}
                </ColumnsDirective>
                <Inject services={[Page, Filter, VirtualScroll, Sort]} />
              </GridComponent>
            </div>
          </div>
          <div style={{ width: "350px" }}>
            <Dialog open={this.state.actionState}>
              <DialogTitle>  {this.resignationStatus === 'clearance' ? 'Initiate Clearance' : 'Accept Resignation'} </DialogTitle>
              <DialogContent>
                {this.resignationStatus === 'clearance' ? 'Are you sure you want to Initiate Clearance?' :
                  'Are you sure you want to accept the resignation?'}
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleConfirmedYes}> Yes </Button>
                <Button onClick={this.handleClose}> No </Button>
              </DialogActions>
            </Dialog>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ auth }) => {
  const { authUser } = auth;
  return { authUser };
};
export default connect(mapStateToProps, { approveResignation, initiateClearance, setMeetingCategory })(HrResignationGrid);
