import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../assets/style/index.css";

import { library } from "@fortawesome/fontawesome-svg-core";

import {
  faUser,
  faStar,
  faUserClock,
  faUserShield,
  faTrashAlt,
  faCheck,
  faCheckDouble,
  faCheckCircle,
  faClone,
  faBan,
  faRecycle,
  faUserCrown,
  faEye,
  faMeteor,
  faUserFriends
} from "@fortawesome/pro-light-svg-icons";
import dataService from "../../services/services";

library.add(
  faStar,
  faUserClock,
  faUserShield,
  faTrashAlt,
  faCheck,
  faCheckDouble,
  faCheckCircle,
  faClone,
  faBan,
  faRecycle,
  faEye,
  faMeteor
);

const useStyles = makeStyles(() => ({
  listItemHeight: { height: 32, fontSize: 12 },
  iconNameFont: { fontSize: 10 },
  iconography: { fontWeight: "bold", marginTop: -15 },
}));
export default function Legends(props) {
  const [meetingType, setMeetingType] = React.useState([]);
  const classes = useStyles();

  useEffect(() => {
    // eslint-disable-next-line
    let response = dataService.getMasterData(props.authUser, 'ML');
    response.then((data) => {
      if (props.type === "holidaylist") {
        const holidayListIds = [1067, 1068, 1070, 1071];
        const filterData = data.filter(ele => holidayListIds.includes(ele.Id))
        setMeetingType(filterData);
      } else {
        setMeetingType(data);
      }
    });
    // eslint-disable-next-line
  }, [])

  return (
    <div>
      <List>
        <ListItem className={classes.iconography}>Iconography</ListItem>
        <Divider />
        {meetingType != null
          ? meetingType.map(
            (item) => {
              if ((item.Id === 1067 || item.Id === 1070) && props.type === "holidaylist") {
                return <div key={item.Id}>
                  <ListItem className={classes.listItemHeight}>
                  <ListItemIcon>
                    <div
                      style={{
                        height: 22,
                        width: 40,
                        // backgroundColor: item.PrimaryColor,
                        justifyContent: "center",
                        borderRadius: 3,
                        borderWidth: 3,
                        border: `1px solid ${item.PrimaryColor}`
                      }}
                    >
                      <div
                        style={{
                          height: 20,
                          width: 5,
                          // backgroundColor: item.SecondaryColor,
                          borderRadius: 2,
                        }}
                      />
                    </div>
                  </ListItemIcon>
                  <ListItemText primary={item.Name} />
                </ListItem>
                </div>
              } else {
              return <div key={item.Id}>
              {item.Name !== "Actual Meeting" &&
              item.Name !== "Self" && item.Id !== 1067 && item.Id !== 1070 && (
                <ListItem className={classes.listItemHeight} >
                  <ListItemIcon>
                    <div
                      style={{
                        height: 22,
                        width: 40,
                        backgroundColor: item.PrimaryColor,
                        justifyContent: "center",
                        borderRadius: 3,
                        borderWidth: 3,
                        border: "1px solid lightgray"
                      }}
                    >
                      <div
                        style={{
                          height: 20,
                          width: 5,
                          backgroundColor: item.SecondaryColor,
                          borderRadius: 2,

                        }}
                      />
                    </div>
                  </ListItemIcon>
                  <ListItemText primary={item.Name} />
                </ListItem>
            )}
            </div>}
            })
          : null}
        {(props.type !== "holidaylist") && <>
        <ListItem className={classes.listItemHeight}>
          <ListItemIcon>
            <div
              style={{
                height: 24,
                width: 40,
                backgroundColor: "#ffffff",
                justifyContent: "center",
                borderRadius: 3,
                borderWidth: 3,
                border: "1px solid #FF3342",
              }}
            >
              <div
                style={{
                  height: 20,
                  width: 5,
                  backgroundColor: "#ffffff",
                  borderRadius: 2,
                }}
              />
            </div>
          </ListItemIcon>
          <ListItemText primary="Break" />
        </ListItem>
        <Divider />
        <ListItem className={classes.listItemHeight}>
          <ListItemIcon>
            <FontAwesomeIcon
              icon={faStar}
              className="mr-sm"
              title="Chair Person"
            />
          </ListItemIcon>
          <ListItemText
            primary="Chair Person"
            className={classes.iconNameFont}
          />
        </ListItem>
        <ListItem className={classes.listItemHeight}>
          <ListItemIcon>
            <FontAwesomeIcon
              icon={faUserCrown}
              className="mr-sm"
              title="Important Attendee"
            />
          </ListItemIcon>
          <ListItemText primary="Important Attendee" />
        </ListItem>
        <ListItem className={classes.listItemHeight}>
          <ListItemIcon>
            <FontAwesomeIcon
              icon={faUserClock}
              className="mr-sm"
              title="Partial Attendee"
            />
          </ListItemIcon>
          <ListItemText primary="Partial Attendee" />
        </ListItem>
        <ListItem className={classes.listItemHeight}>
          <ListItemIcon>
            <FontAwesomeIcon
              icon={faUserShield}
              className="mr-sm"
              title="Optional Attendee"
            />
          </ListItemIcon>
          <ListItemText primary="Optional Attendee" />
        </ListItem>
        <ListItem className={classes.listItemHeight}>
          <ListItemIcon>
            <FontAwesomeIcon icon={faUser} className="mr-sm" title="Attendee" />
          </ListItemIcon>
          <ListItemText primary="Attendee" />
        </ListItem>
        <ListItem className={classes.listItemHeight}>
          <ListItemIcon>
            <FontAwesomeIcon
              icon={faTrashAlt}
              className="mr-sm faWindowClose"
              title="Remove Attendee"
            />
          </ListItemIcon>
          <ListItemText primary="Remove Attendee" />
        </ListItem>
        <Divider />
        <ListItem className={classes.listItemHeight}>
          <ListItemIcon>
            <FontAwesomeIcon
              icon={faCheck}
              className="mr-sm"
              title="Meeting requested"
            />
          </ListItemIcon>
          <ListItemText primary="Meeting Requested" />
        </ListItem>
        <ListItem className={classes.listItemHeight}>
          <ListItemIcon>
            <FontAwesomeIcon
              icon={faCheckDouble}
              className="mr-sm"
              title="Meeting accepted"
            />
          </ListItemIcon>
          <ListItemText primary="Meeting Accepted" />
        </ListItem>
        <ListItem className={classes.listItemHeight}>
          <ListItemIcon>
            <FontAwesomeIcon
              icon={faCheckCircle}
              className="mr-sm"
              title="Actual Meeting"
            />
          </ListItemIcon>
          <ListItemText primary="Actual Meeting" />
        </ListItem>
        <ListItem className={classes.listItemHeight}>
          <ListItemIcon>
            <FontAwesomeIcon
              icon={faClone}
              className="mr-sm"
              title="Move to Actual Meeting"
            />
          </ListItemIcon>
          <ListItemText primary="Move to Actual Meeting" />
        </ListItem>
        <ListItem className={classes.listItemHeight}>
          <ListItemIcon>
            <FontAwesomeIcon
              icon={faRecycle}
              className="mr-sm"
              title="Recurrence Meeting"
            />
          </ListItemIcon>
          <ListItemText primary="Recurrence Meeting" />
        </ListItem>
        <ListItem className={classes.listItemHeight}>
          <ListItemIcon>
            <FontAwesomeIcon icon={faBan} className="mr-sm" title="Declined" />
          </ListItemIcon>
          <ListItemText primary="Declined" />
        </ListItem>
        <ListItem className={classes.listItemHeight}>
          <ListItemIcon>
            <FontAwesomeIcon icon={faEye} className="mr-sm" title="Preview the Meeting Details" />
          </ListItemIcon>
          <ListItemText primary="Preview the Meeting Details" />
        </ListItem>
        <ListItem className={classes.listItemHeight}>
          <ListItemIcon>
            <FontAwesomeIcon icon={faMeteor} className="mr-sm" title="View Meetings" />
          </ListItemIcon>
          <ListItemText primary="View Meetings" />
        </ListItem>
        <ListItem className={classes.listItemHeight}>
          <ListItemIcon>
            <FontAwesomeIcon icon={faUserFriends} className="mr-sm" title="Multiple Attendees" />
          </ListItemIcon>
          <ListItemText primary="Multiple Attendees" />
        </ListItem>
        </>}




      </List>
    </div>
  );
}
