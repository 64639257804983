import axios from "axios";
import { urlConfig } from "../config/config";

function errorHandler(reject, error) {
  // eslint-disable-next-line
  console.dir(error);
  reject("operation failed");
}

function returnResponse(response) {
  if (response != null) {
    return response.data;
  }
}

// eslint-disable-next-line
function getTibUseraList(FC, authUserid) {
  return new Promise(function onThen(resolve, reject) {
    axios
      .get(`${urlConfig.tibrApiUrl}tibuserlist/${authUserid}`, {
        headers: {
          'authorization': localStorage.getItem('jwtToken'),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      // eslint-disable-next-line
      .then(function (response) {
        if (response != null) {
          return resolve(response.data);
        }
      })
      // eslint-disable-next-line
      .catch(function (error) {
        errorHandler(reject, error.response.data.message);
        return null;
      });
  });
}
function getGrayShadeLeaveTypes(authUserid) {
  return new Promise(function onThen(resolve, reject) {
    axios
      .get(`${urlConfig.tibrApiUrl}tibgrayleavetypes/${authUserid}`, {
        headers: {
          'authorization': localStorage.getItem('jwtToken'),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      // eslint-disable-next-line
      .then(function (response) {
        return resolve(returnResponse(response));
      })
      // eslint-disable-next-line
      .catch(function (error) {
        errorHandler(reject, error.response.data.message);
        return null;
      });
  });
}
function getUserTIBRData(timeStamp, authUserids, type, selectType) {
  if (type === "single") {
    return new Promise(function onThen(resolve, reject) {
      axios
        .get(
          `${urlConfig.tibrApiUrl
          }userdata/${authUserids}/${timeStamp.toString()}/${selectType}`,
          {
            headers: {
              'authorization': localStorage.getItem('jwtToken'),
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        )
        // eslint-disable-next-line
        .then(function (response) {
          return resolve(returnResponse(response));
        })
        // eslint-disable-next-line
        .catch(function (error) {
          errorHandler(reject, error.response.data.message);
          return null;
        });
    });
  }
  return new Promise(function onThen(resolve, reject) { // NOSONAR
    axios
      .get(
        `${urlConfig.tibrApiUrl
        }userdata/${authUserids}/${timeStamp.toString()}/${selectType}`,
        {
          headers: {
            'authorization': localStorage.getItem('jwtToken'),
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      // eslint-disable-next-line
      .then(function (response) {
        return resolve(returnResponse(response));
      })
      // eslint-disable-next-line
      .catch(function (error) {
        errorHandler(reject, error.response.data.message);
        return null;
      });
  });
}
/**
 * @author Vivek Khodade
 * @param {*} startDate
 * @param {*} endDate
 */
function getExportDataInDateRainge(startDate, endDate) {
  return new Promise(function onThen(resolve, reject) {
    axios
      .get(
        `${urlConfig.tibrApiUrl}getExportDataInDateRainge/${startDate}/${endDate}`,
        {
          headers: {
            authorization: localStorage.getItem("jwtToken"),
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      // eslint-disable-next-line
      .then(function (response) {
        return resolve(returnResponse(response));
      })
      // eslint-disable-next-line
      .catch(function (error) {
        errorHandler(reject, error);
        return null;
      });
  });
}
/**
 * @author Vinayak Jadhav
 * @param {*} prohanceDate
 */
function getProhanceExportedFileList() {
  return new Promise(function onThen(resolve, reject) {
    axios
      .get(`${urlConfig.tibrApiUrl}getProhanceExportedFileList`, {
        headers: {
          authorization: localStorage.getItem("jwtToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      // eslint-disable-next-line
      .then(function (response) {
        return resolve(returnResponse(response));
      })
      // eslint-disable-next-line
      .catch(function (error) {
        errorHandler(reject, error);
        return null;
      });
  });
}

/**
 * @author Vinayak Jadhav
 * @param {*} prohanceDate
 */
function getProhanceExportData(prohanceDate) {
  return new Promise(function onThen(resolve, reject) {
    axios
      .get(`${urlConfig.tibrApiUrl}getProhanceExportData/${prohanceDate}`, {
        headers: {
          authorization: localStorage.getItem("jwtToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      // eslint-disable-next-line
      .then(function (response) {
        return resolve(returnResponse(response));
      })
      // eslint-disable-next-line
      .catch(function (error) {
        errorHandler(reject, error);
        return null;
      });
  });
}
/**
 * @author Prashant Waphare
 * @param {*} startDate
 * @param {*} endDate
 * @param {*} type
 */
function getUserActivityData(startDate, endDate, allEmployeeList) {
  var apiUrl = `${urlConfig.tibrApiUrl}getUserActivityData/${startDate}/${endDate}/${allEmployeeList}`;
  return new Promise(function onThen(resolve, reject) {
    axios
      .get(apiUrl, {
        headers: {
          authorization: localStorage.getItem("jwtToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      // eslint-disable-next-line
      .then(function (response) {
        return resolve(returnResponse(response));
      })
      // eslint-disable-next-line
      .catch(function (error) {
        errorHandler(reject, error);
        return null;
      });
  });
}
/**
 * @author Vinayak Jadhav
 * @param {*} reportType
 */
function getTibAvgReportData(params) {
  var paramStr =
    params[0].authUserId +
    "/" +
    params[0].tibAvgSelectedViewByIds.toString() +
    "/" +
    params[0].tibAvgSelectedBucketIds.toString() +
    "/" +
    params[0].tibAvgSelectedYearOption +
    "/" +
    params[0].tibAvgSelectedFy +
    "/" +
    params[0].tibAvgSelectedFyMonths.toString() +
    "/" +
    params[0].tibAvgSelectedFromMonth +
    "/" +
    params[0].tibAvgSelectedToMonth +
    "/" +
    params[0].tibAvgSelectedSd +
    "/" +
    params[0].tibAvgSelectedUserOption;
  var apiUrl = `${urlConfig.tibrApiUrl}getTibAvgReportData/${paramStr}`;
  return new Promise(function onThen(resolve, reject) {
    axios
      .get(apiUrl, {
        headers: {
          authorization: localStorage.getItem("jwtToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      // eslint-disable-next-line
      .then(function (response) {
        return resolve(returnResponse(response));
      })
      // eslint-disable-next-line
      .catch(function (error) {
        console.log("getTibAvgReportData not connected due to error : ", error);
        errorHandler(reject, error);
        return null;
      });
  });
}
/**
 * @author Prashant Waphare
 * @param {*} type
 * @date 26/05/2021
 * @description this API call for get TIBR Category master List
 */
function getTBIRCategoryMaster(type) {
  return new Promise(function onThen(resolve, reject) {
    axios
      .get(`${urlConfig.tibrApiUrl}getTIBRCategoryMaster/${type}`, {
        headers: {
          authorization: localStorage.getItem("jwtToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      // eslint-disable-next-line
      .then(function (response) {
        return resolve(returnResponse(response));
      })
      // eslint-disable-next-line
      .catch(function (error) {
        console.log(
          "getTBIRCategoryMaster not connected due to error : ",
          error
        );
        errorHandler(reject, error);
        return null;
      });
  });
}
/**
 * @author Vinayak Jadhav
 * @param {*} type
 * @date 31/05/2021
 * @description this API call for get Financial Year wise Month list;
 */
function getFyMonthList(fy) {
  var apiUrl = `${urlConfig.tibrApiUrl}getFyMonthList/${fy}`;
  return new Promise(function onThen(resolve, reject) {
    axios
      .get(apiUrl, {
        headers: {
          authorization: localStorage.getItem("jwtToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      // eslint-disable-next-line
      .then(function (response) {
        return resolve(returnResponse(response));
      })
      // eslint-disable-next-line
      .catch(function (error) {
        console.log("Error at getFyMonthList");
        errorHandler(reject, error);
        return null;
      });
  });
}
/**
 * @author Vivek Khodade
 * @param {*} userId
 * @date 23/12/2021
 * @description this API call get user tib data;
 */
function getUserTibData(userId, stDate, edDate) {
  var apiUrl = `${urlConfig.tibrApiUrl}usertibdata/${userId}/${stDate}/${edDate}`;
  return new Promise(function onThen(resolve, reject) {
    axios
      .get(apiUrl, {
        headers: {
          authorization: localStorage.getItem("jwtToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      // eslint-disable-next-line
      .then(function (response) {
        return resolve(returnResponse(response));
      })
      // eslint-disable-next-line
      .catch(function (error) {
        console.log("Error at getUserTibData");
        errorHandler(reject, error);
        return null;
      });
  });
}

const tibrservices = {
  getUserTIBRData,
  getTibUseraList,
  getExportDataInDateRainge,
  getGrayShadeLeaveTypes,
  getUserActivityData,
  getTibAvgReportData,
  getTBIRCategoryMaster,
  getFyMonthList,
  getProhanceExportData,
  getProhanceExportedFileList,
  getUserTibData,
};

export default tibrservices;
