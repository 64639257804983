
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { GridComponent, ColumnsDirective, ColumnDirective, Inject, Filter } from '@syncfusion/ej2-react-grids';
import LnAServices from "../../../../services/lna/lnaservice";
import {
  getGridColumn,
  HR_TYPE
} from "../../../../app/other/gridcolumns";
import ProgressBar from "../../../progressbar/progressbar";
import downloadCsv from "download-csv";
import leaveandattendance from '../../../../assets/images/leaveandattendance.png';
import { formatDateToYYYYMMDD, generateExcelFile } from '../../../../app/other/commonfunction';
import * as xlsx from 'xlsx';

const styles = `
.grid-left-header-admin{
  border: 0 none;
  display: block;
  font-size: 15px;
  font-weight: 500;
  line-height: 40px !important;
  height: 40px !important;
  margin-left: -1px !important;
  overflow: hidden;
  padding: 0 0.6em !important;
  -webkit-user-select: none;
  user-select: none;
  width: inherit !important;
  word-wrap: break-word !important;
}
.grid-center-header-admin{
  border: 0 none;
  display: block;
  font-size: 15px;
  font-weight: 500;
  line-height: 40px !important;
  height: 40px !important;
  overflow: hidden;
  padding: 0 0.6em !important;
  -webkit-user-select: none;
  user-select: none;
  text-align: 'center'!important;
  width: inherit !important;
  word-wrap: break-word !important;
}
.grid-left-header-popup{
  border: 0 none;
  display: block;
  font-size: 15px;
  font-weight: 500;
  line-height: 30px;
  height: 30px !important;
  margin-left: -1px !important;
  overflow: hidden;
  padding: 0 0.6em !important;
  -webkit-user-select: none;
  user-select: none;
  width: inherit !important;
  word-wrap: break-word !important;
}
.grid-center-header-popup{
  border: 0 none;
  display: block;
  font-size: 15px;
  font-weight: 500;
  height: 22px !important;
  line-height: 30px;
  overflow: hidden;
  padding: 0 0.6em !important;
  -webkit-user-select: none;
  user-select: none;
  text-align: 'center'!important;
  width: inherit !important;
  word-wrap: break-word !important;
}
.e-grid .e-rowcell{
  border-style: solid;
  border-width: 1px 0 0 0;
  display: table-cell;
  font-size: 14px !important;
  line-height: 18px !important;
  overflow: hidden;
  padding: 5px 0px 5px 8px !important;
  vertical-align: middle;
  /* white-space: pre-wrap !important; */
  width: auto;
  /* text-transform: capitalize; */
}
.e-grid .e-filtermenudiv{
    margin-top:28px;
}
.e-dropdown-popup ul {
  padding:0px !important
}
.e-grid .e-gridcontent tr.e-row .e-rowcell {
  padding-left: 1.5rem !important;
}`
  ;

export const LeavesHRReport = (props) => {
  const { content } = props
  const [state, setState] = useState({
    hrReportData: null,
    gridHeight: null,
    reportHeight: null,
  })
  const [initialLoad, setInitialLoad] = useState(true);
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    props.changeViewReport("hrReport");
    updateDimensions();
  }, []);

  useEffect(() => {
    let gridHeight = (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight) - 426;
    if (content.effectiveStartDateContent != null) {
      getReportData("SUMMARY");
    }
    setState((preState) => ({
      ...preState,
      gridHeight,
    }));
  }, [content]);

  const updateDimensions = () => {
    const updateHeight =
      (window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight);

    setState({
      reportHeight: updateHeight - 275,
    });
  }

  const getReportData = async (type) => {
    setIsLoading(true)
    try {
      const hrReportData = await LnAServices.getHrReport(props.authUser, type, content.effectiveStartDateContent, content.effectiveEndDateContent, content.teamMemberContent, content.countryDataContent,
        content.businessUnitContent, content.subBusinessUnitContent, content.attendanceListContent, content.leaveListContent);
      if (type === "detailReport") {
        await exportHrReport(hrReportData.Result);
        setInitialLoad(false)
      } else if (type === "SUMMARY") {
        setState((preState) => ({
          ...preState,
          hrReportData: hrReportData.Result,
        }));
        setInitialLoad(false)
      } else {
        setInitialLoad(true)
      }
      setIsLoading(false)
    }
    catch (err) {
      console.log('catch error', err)
    }
  }
  /**
   *@author: Vivek Khodade  
   *@description : Export the leave and attendance detail report
   * @param {*} exportedData 
   */
  const exportHrReport = async (exportedData) => {
    if (exportedData.length === 0) {
      const ob = {};
      ob.empCode = "No Data Available";
      ob.empName = "";
      ob.pnlName = "";
      ob.subPnlName = "";
      // eslint-disable-next-line
      ob.reportingToName = '';
      ob.leaveDate = "";
      ob.leaveType = "";
      ob.number = "";
      exportedData.push(ob);
    }
   
    let currentDate = new Date();
    const formattedDate = formatDateToYYYYMMDD(currentDate)
    const fileName = (`${formattedDate}_Attendance Summary`);
    const columns = { 
      empCode: 'Team Member Id', 
    empName: 'Team Member Name', 
    pnlName: 'Business Unit / Group Function', 
    subPnlName: 'Sub Business Unit / Function', 
    practiceAreaName: 'Practice Area/Sub Function', 
    reportingToName: 'Reporting Supervisor', 
    leaveDate: 'Date', 
    leaveType: 'Type', 
    number: "Number", 
      timeInBusiness: "Input Time in Business"
    };
    const exportedDataFormat = exportedData.map(ele => {
      let obj={}
      for(let key in columns){
        obj[columns[key]] = ele[key];
      }
      return obj
    })
    generateExcelFile([exportedDataFormat, []], fileName);
  }

  // setHeaderCss()
  return (
    <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12" id='Hr-ReportMainContainer' style={{ backgroundColor: "#fff", height: state.reportHeight }}>
      <div className='hr-Report-grid-two' style={{
        backgroundColor: '#4B4B55',
        borderStartEndRadius: "0.4em",
        borderStartStartRadius: "0.4em",
        display: "flex",
        alignItems: "center",
      }}
      >
        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <div style={{ color: 'whitesmoke', fontSize: '18px' }}>
            Summary
          </div>

          {state.hrReportData !== null && (content.effectiveStartDateContent && content.effectiveEndDateContent) &&
            <div
              className='allTeamReportSwapDisableButton hrreport mr-md'
              onClick={() => getReportData("detailReport")}
            >
              Download
            </div>
          }

        </div>
      </div>

      {!initialLoad && !isLoading &&
        state.hrReportData.length > 0 ? <>
        <div className="col-sm-12 col-md-12 col-lg-12 " id="HR-TeamReport">
          <style>{styles}</style>
          <GridComponent
            id={"hrReport-Grid"}
            gridLines="Both"
            dataSource={state.hrReportData}
            // height={state.gridHeight}
            rowHeight={40}
            allowFiltering
            filterSettings={{
              type: "CheckBox",
            }}
            style={{ overflowY: 'auto' }}

          >
            <ColumnsDirective>
              {getGridColumn("hrreport").map((item) => {
                switch (item.colName) { //NOSONAR
                  case HR_TYPE:
                    return (
                      <ColumnDirective
                        // template={actionTemplate}
                        // filter={false}
                        textAlign={`${item.align}`}
                        field={item.field}
                        headerText={item.colName}
                        width={item.width}
                      // allowFiltering={false}
                      />
                    );
                  default:
                    return (
                      <ColumnDirective
                        field={item.field}
                        width={item.width}
                        headerText={item.colName}
                        textAlign={item.align}
                      />
                    );
                }
              })}
            </ColumnsDirective>
            <Inject services={[Filter]} />
          </GridComponent>
        </div>
      </>
        //  : !isLoading &&
        //   state.hrReportData === null || state.hrReportData === undefined ?
        //     <>
        //       <div className="allTeamReportMonth m-md" style={{ backgroundColor: "#fff" }}>
        //         <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12" style={{ backgroundColor: "#fff", borderRadius: "5px" }}>
        //           <center className='NoData-ToShow' style={{ marginTop: '10%' }}>
        //             <img src={leaveandattendance} alt="Image Not Found" />
        //             <div className='ratingwelcomesubtext pt-xs'>
        //               {"No data to show"}
        //             </div>
        //           </center>
        //         </div>
        //       </div>
        //     </>
        :
        <div className="allTeamReportMonth m-md" style={{ backgroundColor: "#fff" }}>
          <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12" style={{ backgroundColor: "#fff", borderRadius: "5px" }}>
            <center className='NoData-ToShow' style={{ marginTop: '10%' }}>
              <img src={leaveandattendance} alt="Image Not Found" />
              {/* <div className='ratingwelcometext pt-sm'>Welcome to Team Member Yearly Report</div> */}
              <div className='ratingwelcomesubtext pt-xs'>
                {"No data to show. Select From Date and To Date to show data."}
              </div>
            </center>
          </div>
        </div>}
      <div className="loader-new1" id="spinnerContainer" >
        {isLoading && <ProgressBar />}
      </div>
    </div>
  )
}

LeavesHRReport.propTypes = {
  //   second: PropTypes.third
}

const mapStateToProps = ({ auth }) => {
  const { authUser } = auth;

  return { authUser }

}

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(LeavesHRReport)