import React from 'react'
import { Dialog, DialogTitle } from "@material-ui/core";
import { DialogContent } from "../customdialog/customdialog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import ReactHtmlParser from 'react-html-parser'

function ViewkrModel(props) {

  return (
    <div>
      <Dialog open={true} fullWidth={false} onEscapeKeyDown={()=>props.closeLpRatingPopup()} >
        <DialogTitle>
          <span className="add-new-self-header" style={{
            color: "black",
            paddingBottom: 0,
            marginLeft: -4,
            fontFamily: "Circular Std ",
            fontSize: 18
          }} >
            {"Leadership Principle"}
          </span>
          <span style={{ float: 'right', cursor: 'pointer', marginRight: -12 }} ><FontAwesomeIcon onClick={() => props.closeLpRatingPopup()} style={{ color: '#B7B7BB', fontSize: 20 }} icon={faTimes} /></span>
        </DialogTitle>
        <DialogContent style={{ paddingTop: 0, paddingBottom: 32 }}>
          <div className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <form id="InterimReviewForm">
              <div className="form-row">
                <div
                  className="col-6 col-lg-6 col-md-6 col-sm-6 col-xs-6"
                  style={{ paddingBottom: 24, paddingLeft: 10, paddingRight: 10 }}
                >
                  <div className="view-kr-modalrating-header-text"
                  >
                    Leadership Principle
                  </div>
                  <div className="view-kr-modalrating-text"
                  >
                    {props.KRData.krName}
                  </div>
                </div>
                <div
                  className="col-3 col-lg-3 col-md-3 col-sm-3 col-xs-3"
                  style={{ paddingBottom: 24, paddingLeft: 40, paddingRight: 10 }}
                >
                  <div className="view-kr-modalrating-header-text"
                  >
                    Weightage
                  </div>
                  <div className="view-kr-modalrating-text"
                  >
                    {props.KRData.weightage}%
                  </div>
                </div>
              </div>

              <div className="form-row">
                <div
                  className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12"
                  style={{ paddingBottom: 0, paddingLeft: 10, paddingRight: 10 }}>
                  <div className="view-kr-modalrating-header-text">
                    Description
                  </div>
                  <div className="view-kr-modalrating-text">
                    {ReactHtmlParser(props.KRData.libDescription)}
                  </div>
                </div>
                <div
                  id="descripttErr"
                  className="e-input-group e-float-icon-left error-div"
                />
              </div>
            </form>
          </div>
        </DialogContent>



      </Dialog>
    </div>
  );

}
export default ViewkrModel
