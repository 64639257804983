import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { Button, Dialog } from "@material-ui/core";
import { FormValidator } from "@syncfusion/ej2-inputs";
import {
  DialogTitleTMLC,
  DialogContent,
  DialogActions,
} from "../../customdialog/customdialog";
import ErrorBoundary from "../../error/errorboundary";
import {
  updateDocumentInfo,
  addDocumentComments,
} from "../../../redux/actions/entryexit/onboardingaction";
import { NEW_CANDIDATE_UPLOAD } from "../../../redux/constant/entryexit/candidateconstant";
import addCandidateService from "../../../services/entryexit/addCandidateService";
import "./comments.css";


class Comments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      comments: "",
      commentsList: [],
    };
    this.handleYes = this.handleYes.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  componentDidMount() {
    const {
      showComments,
      candidateType,
      authUser,
      selectedFolderId,
      commentFor,
    } = this.props;

    if (showComments) {
      const data = {
        authUser: candidateType === NEW_CANDIDATE_UPLOAD ? 0 : authUser,
        doccatid: selectedFolderId,
        candtype: candidateType,
        commentFor,
      };
      const documentDetails = addCandidateService.getDocumentComments(data);
      documentDetails
        // eslint-disable-next-line
        .then(async (result) => {
          let filterDetails;
          if (candidateType === NEW_CANDIDATE_UPLOAD) {
            filterDetails = result;
          } else {
            filterDetails = await result.filter(function (elem) {
              return elem.commentFor === commentFor;
            });
          }
          this.setState({ commentsList: filterDetails });
        })
        .catch();
    } else {
      setTimeout(() => {
        if (this.textareaObj !== null)
          this.textareaObj.addAttributes({ rows: 10 });
      }, 500);
    }
  }

  // eslint-disable-next-line
  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.refreshDocComments === "update") {
      // eslint-disable-next-line
      this.props.updateDocumentInfo({
        openDocumentComments: false,
        refreshDocComments: "",
      });
      // eslint-disable-next-line
      // if (this.props.commentFor !== '2')
      this.props.updateDocumentInfo({ refreshDocStatus: "doc_upload" });

    }
  }

  handleClose = () => {
    // eslint-disable-next-line
    this.props.updateDocumentInfo({ openDocumentComments: false });
  };

  handleYes = () => {
    const options = {
      rules: {
        commentsErr: { required: [true, "Please enter comments"] },
      },
    };
    const addFormObject = new FormValidator("#addCommentsFormInfo", options);
    if (addFormObject.validate()) {
      const { comments } = this.state;

      const {
        candidateType,
        authUser,
        selectedFolderId,
        candidateid,
        commentFor
      } = this.props;

      const data = {
        authUser: candidateType === NEW_CANDIDATE_UPLOAD ? 0 : authUser,
        docCatId: selectedFolderId,
        candId: candidateType === NEW_CANDIDATE_UPLOAD ? candidateid : 0,
        comments,
        commentFor: commentFor,
      };
      // eslint-disable-next-line
      if (commentFor === '2') {
        // eslint-disable-next-line
        this.props.updateDocumentInfo({
          refreshDocStatus: "app_rej_status",
          doc_status: "R",
          docRejectionParams: [],
        });
        setTimeout(() => {
          this.props.addDocumentComments(data);
        }, 10)
          ;
      } else {
        this.props.addDocumentComments(data);
      }
    }


  };

  setComments = (e) => {
    this.setState({ comments: e.value });
  };

  render() {
    const {
      showComments,
      commentFor,
      categoryName,
      openDocumentComments,
      candidateType
    } = this.props;
    const { commentsList, comments } = this.state;
    return (
      <>
        <Dialog
          aria-labelledby="comments-title"
          open={openDocumentComments}
          onClose={this.handleClose}
          fullWidth
          maxWidth="lg"
          width={1000}
          height={600}

        >
          <DialogTitleTMLC id="comments-title" onClose={this.handleClose}>
            {showComments ? "" : "Add"}{" "}
            {commentFor === "2"
              ? "Comment"
              : `Comments In ${categoryName}`}
          </DialogTitleTMLC>
          <ErrorBoundary>
            <DialogContent dividers>
              {showComments ? commentsList.length > 0 && (
                commentsList.map((item) => (
                  <div key={`comm${item.docId}`} className="comment-main-content">
                    <div className="comment-main-content-header">
                      {item.AddedBy} {candidateType === NEW_CANDIDATE_UPLOAD && item.commentFor === "2" ? " - Comment " : " "}-{" "}
                      {item.AddedOn}

                    </div>
                    <div className="comment-main-content-content" key={`data${item.docId}`}
                      style={{ color: candidateType === NEW_CANDIDATE_UPLOAD && item.commentFor === "2" ? "red" : 'inherit' }} >
                      {item.Comments}
                    </div>
                  </div>
                ))
              ) : (
                <form id="addCommentsFormInfo" method="post">
                  <div>
                    <TextBoxComponent
                      id="Comments"
                      placeholder="Comments *"
                      ref={(scope) => {
                        this.textareaObj = scope;
                      }}
                      floatLabelType="Auto"
                      multiline
                      value={comments}
                      data-msg-containerid="commentsErr"
                      name="commentsErr"
                      cssClass="e-outline"
                      blur={(e) => this.setComments(e)}
                    />
                    <div
                      id="commentsErr"
                      className="e-input-group e-float-icon-left error-div"
                    />
                  </div>
                </form>
              )}
            </DialogContent>
            <DialogActions>
              {!showComments && (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={this.handleYes}
                >
                  {commentFor === "2" ? "Reject" : "Save"}
                </Button>
              )}
              <Button
                onClick={this.handleClose}
                color="default"
                variant="contained"
              >
                Close
              </Button>
            </DialogActions>
          </ErrorBoundary>
        </Dialog>
      </>
    );
  }
}

Comments.propTypes = {
  selectedFolderId: PropTypes.number.isRequired,
  showComments: PropTypes.bool.isRequired,
  authUser: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  candidateType: PropTypes.string.isRequired,
  updateDocumentInfo: PropTypes.func,
  // eslint-disable-next-line
  // addDocumentComments: PropTypes.func,
  openDocumentComments: PropTypes.bool.isRequired,
  candidateid: PropTypes.number,
  // refreshDocStatus: PropTypes.string.isRequired,
  // eslint-disable-next-line
  refreshDocComments: PropTypes.string,
  categoryName: PropTypes.string.isRequired,
  commentFor: PropTypes.string.isRequired,
};
Comments.defaultProps = {
  candidateid: 0,
  refreshDocComments: "",
  updateDocumentInfo: {},
  // addDocumentComments: {},
};

const mapStateToProps = ({ onboardingreducer, auth }) => {
  const { authUser } = auth;
  const {
    openDocumentComments,
    candidateid,
    refreshDocStatus,
    refreshDocComments,
  } = onboardingreducer;
  return {
    authUser,
    openDocumentComments,
    candidateid,
    refreshDocStatus,
    refreshDocComments,
  };
};

export default connect(mapStateToProps, {
  updateDocumentInfo,
  addDocumentComments,
})(Comments);
