import { connect } from "react-redux";
import { Dialog, DialogActions, DialogTitle } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { updateMeetingBookingState } from "../../redux/actions/meetingbookingaction";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { DialogContent } from "../customdialog/customdialog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { SliderComponent } from '@syncfusion/ej2-react-inputs';
import DateFnsUtils from "@date-io/date-fns";
import { faSquare } from "@fortawesome/free-solid-svg-icons";
import dataService from "../../services/services";
function PartialMeetingAttendeeTimer(props) {

    const { timeZoneId, clientTimeZone, authUser, uid, meetingId, othersCheckAvailablity, meetingStartTime, meetingEndTime } = props;
    const [initialStartAndEndForPA, setInitialStartAndEndForPA] = useState({
        pstart: null,
        pend: null
    })
    const [value, setValue] = useState(0);
    const [start, setStart] = useState();
    const [end, setEnd] = useState();
    const [timer, setTimer] = useState();
    const [userStaus, setUserStatus] = useState();
    const [toolTip, setToolTip] = useState()
    const [fullAttendeeStatus, setFullAttendeeStatus] = useState();
    const dateFns = new DateFnsUtils();
    const minValue = new Date(meetingStartTime).getTime();
    const maxValue = new Date(meetingEndTime).getTime();
    let attendeeTag = props.meetingAttendee;
    const meetingTimeInMilliseconds = (maxValue - minValue);
 
    const stepDistance = () => {
        let step;
        if (meetingTimeInMilliseconds <= 3600000) {
            step = 300000;
        } else if (meetingTimeInMilliseconds > 3600000 && meetingTimeInMilliseconds <= 7200000) {
            step = 600000;
        } else if (meetingTimeInMilliseconds > 7200000) {
            step = 900000;
        }
        return step;
    }




    const timeZone = clientTimeZone.find(obj => obj.Id === timeZoneId);
    //getStatus function is to get the status of employee, e.g. busy,available
    const getStatus = async (startDateTime, endDateTime) => {

        const status = await dataService.getUserAvailability(
            authUser,
            startDateTime,
            endDateTime,
            uid,
            timeZoneId,
            'Status',
            meetingId
        );

        let finalStatus;
        let tool;
        if (status.length !== 0) {
            switch (status[0].availabilityStatus) {
                case 'Busy':
                    finalStatus = 'legend_busy';
                    setToolTip('Busy')
                    tool = 'Busy';
                    break;
                case 'Break':
                    finalStatus = 'legend_break';
                    setToolTip('Break')
                    tool = 'Break'
                    break;
                case 'Leave':
                    finalStatus = 'legend_leave';
                    setToolTip('Leave')
                    tool = 'Leave';
                    break;
                default:
            }

        } else {
            finalStatus = 'legend_available';
            setToolTip('Available');
            tool = 'Available';
        }

        setUserStatus(finalStatus);
        return tool
    }

    const getStatusForFullAttendee = async () => {
        let start_time = dateFns.format(new Date(meetingStartTime).getTime(), 'yyyy-MM-dd HH:mm:ss');
        let end_time = dateFns.format(new Date(meetingEndTime).getTime(), 'yyyy-MM-dd HH:mm:ss');
        const status = await getStatus(start_time, end_time)//NOSONAR
        setFullAttendeeStatus(status);

    }


    useEffect(() => {
        getStatusForFullAttendee();
        props.meetingAttendee.forEach(async attendee => {
            let attendeeElArray = attendee.split("_");
            if (attendeeElArray[0] === props.uid.toString() && attendeeElArray.length > 3) {
                //When You edit a partial attendee we need to display partial time on slider time scale,
                //so this is to calculate partial time 
                let attendeeStartTime = new Date(props.meetingStartTime);
                let attendeeEndTime = new Date(props.meetingEndTime);
                attendeeStartTime.setHours(((attendeeElArray[3]).split(" ")[1]).split(':')[0])
                attendeeStartTime.setMinutes(((attendeeElArray[3]).split(" ")[1]).split(':')[1])
                attendeeEndTime.setHours(((attendeeElArray[4]).split(" ")[1]).split(':')[0])
                attendeeEndTime.setMinutes(((attendeeElArray[4]).split(" ")[1]).split(':')[1])
                let sec = [new Date(attendeeStartTime).getTime(), new Date(attendeeEndTime).getTime()]
                setValue(sec)
                let start_time = dateFns.format(new Date(attendeeStartTime).getTime(), 'yyyy-MM-dd HH:mm:ss');
                setStart(start_time);
                let end_time = dateFns.format(new Date(attendeeEndTime).getTime(), 'yyyy-MM-dd HH:mm:ss');
                setEnd(end_time);
                savePartialTime(attendeeTag, props.uid, "", start_time, end_time);

                getStatus(start_time, end_time)
                setInitialStartAndEndForPA({ pstart: start_time, pend: end_time });
            } else if (attendeeElArray[0] === props.uid.toString() && attendeeElArray.length <= 3) {
                //This is when you first time click on partial attendee 
                let start_time = dateFns.format(minValue, 'yyyy-MM-dd HH:mm:ss');
                setStart(start_time);
                let end_time = dateFns.format(maxValue, 'yyyy-MM-dd HH:mm:ss');
                setEnd(end_time);

                getStatus(start_time, end_time)
                let sec = [minValue, maxValue];
                setValue(sec)
                savePartialTime(attendeeTag, props.uid, "", start_time, end_time);
                setInitialStartAndEndForPA({ pstart: start_time, pend: end_time });
            }
        })

    },
        // eslint-disable-next-line
        [])


    const timeTicks = {
        placement: 'After',
        // 3 * 3600000 milliseconds = 3 Hour
        largeStep: stepDistance(),
        smallStep: stepDistance(),
        showSmallTicks: true
    };
    const timeTooltip = {
        placement: 'Before',
        isVisible: true
    };




    const tooltipChangeHandler = (args) => {

        clearTimeout(timer);
        // Splitting the range values from the tooltip using space into an array.
        let totalMiliSeconds = args.text.split(' ');
        // First part is the first handle value
        let firstPart = totalMiliSeconds[0];

        // Second part is the second handle value
        let secondPart = totalMiliSeconds[2];

        /**
         * toLocaleTimeString is predefined javascript date function, which is used to
         * customize the date in different format
         */
        firstPart = new Date(Number(firstPart));
        secondPart = new Date(Number(secondPart));
        const firstPartInHour = dateFns.format(firstPart, 'kk:mm');
        const secondPartInHour = dateFns.format(secondPart, 'kk:mm');

        // Assigning our custom text to the tooltip value.
        args.text = firstPartInHour + ' - ' + secondPartInHour;

        let start_time = dateFns.format(firstPart, 'yyyy-MM-dd HH:mm:ss');
        setStart(start_time);
        let end_time = dateFns.format(secondPart, 'yyyy-MM-dd HH:mm:ss');
        setEnd(end_time);
        //Do we need to close time out please see console for better understanding  <==================


        let tim = setTimeout(() => { getStatus(start_time, end_time) }, 200)
        setTimer(tim)
    }
    const renderingTicksHandler = (args) => {
        let totalMiliSeconds = Number(args.value);
        /**
         * toLocaleTimeString is predefined javascript date function, which is used to
         * customize the date in different format
         */
        // Assigning our custom text to the tick value.
        // args.text = new Date(totalMiliSeconds).toLocaleTimeString('en-us', custom);
        args.text = dateFns.format(new Date(totalMiliSeconds), 'kk:mm');
    }
    /**
     * cancleButton functio check whather the selected time on TimeScale is equal to Meeting Start time and End Time 
     * If it is equal
     *  It mark that user as a full attendee
     * If not equal 
     *  Then It keeps as It is. 
     */
    const meetingStartEnd = () => {
        let meetingStartTime1 = new Date(props.meetingStartTime);
        let meetingEndTime1 = new Date(props.meetingEndTime);
        let meetingStart = dateFns.format(new Date(meetingStartTime1).getTime(), 'yyyy-MM-dd HH:mm:ss');
        let meetingEnd = dateFns.format(new Date(meetingEndTime1).getTime(), 'yyyy-MM-dd HH:mm:ss');
        return { meetingStart, meetingEnd }
    }
    const cancleButton = () => {
        const { meetingStart, meetingEnd } = meetingStartEnd();
        if (start === meetingStart && end === meetingEnd) {
            props.markFullAttendee()
        }
    }
    const cancleButton2 = () => {
        const { meetingStart, meetingEnd } = meetingStartEnd();
        const { pstart, pend } = initialStartAndEndForPA;
        if (pstart === meetingStart && pend === meetingEnd) {
            props.markFullAttendee()
        }
    }
    const renderFullAttendeeButton = () => {
        let renderButton = true;
        const { meetingStart, meetingEnd } = meetingStartEnd();
        if (start === meetingStart && end === meetingEnd) {
            renderButton = false
        }
        return renderButton
    }
    const savePartialTime = (meetingAttendeeList, uId, type, s, e) => {

        let startTime = s ? s : start;
        let endTime = e ? e : end;
        let ArrayOfStartTime = startTime.split(':');
        let ArrayOfEndTime = endTime.split(':');
        let finalStartTime = `${ArrayOfStartTime[0]}:${ArrayOfStartTime[1]}:00`
        let finalEndTime = `${ArrayOfEndTime[0]}:${ArrayOfEndTime[1]}:00`
        let timeFrame;
        let otherAttendee = [];
        meetingAttendeeList.forEach((element, ind) => {

            let arr = element.split("_");
            if (uId !== parseInt(arr[0])) {
                otherAttendee.push(element);
            }
            if (arr[1] === "2" && uId === parseInt(arr[0])) {
                timeFrame = `${arr[0]}_${arr[1]}_${arr[2]}_${finalStartTime}_${finalEndTime}`;
            }
        });
        otherAttendee.push(timeFrame);
        props.updateMeetingBookingState({
            meetingAttendee: otherAttendee,
        });
        cancleButton()
        if (type === "save") {
            props.closePopUp();
        }
    }
    const findUser = () => {
        const user = othersCheckAvailablity.find(users => users.UserId === uid);
        return user.name;
    }



    return (
        < div >
            <Dialog open={true} fullWidth onEscapeKeyDown={() => props.closePopUp()} >
                <DialogTitle style={{ borderBottom: "1px solid #e0e0e0" }}>
                    <span className="add-new-self-header" style={{
                        color: "#5f5f5f",
                        paddingBottom: 0,
                        marginLeft: -4,
                        fontFamily: "Circular Std ",
                        fontSize: 18,
                    }}>
                        Set Meeting Time for Partial Attendee
                    </span>
                    <span style={{ float: 'right', cursor: 'pointer', marginRight: -12 }} ><FontAwesomeIcon onClick={() => props.closePopUp()} style={{ color: '#9e9e9e', fontSize: 20 }} icon={faTimes} /></span>
                </DialogTitle>
                <div style={{ padding: "1em 0em 0em 1.9em" }} >
                    <span > <strong>Attendee:</strong>  {<FontAwesomeIcon
                        icon={faSquare}
                        style={{ cursor: "pointer" }}
                        className={userStaus}
                        title={toolTip}

                    />} {findUser()} &ensp; <strong>Time Zone:</strong> {timeZone.Name}</span>
                </div>



                <DialogContent style={{ padding: "2em 2em 3em 2em" }}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'column',
                    }}>
                        <div className="col-lg-12 control-section">
                            <div className='sliderwrap'>
                                <SliderComponent
                                    id="range01"
                                    type='Range'
                                    value={value}
                                    min={minValue}
                                    max={maxValue}
                                    ticks={timeTicks}
                                    tooltip={timeTooltip}
                                    tooltipChange={tooltipChangeHandler}
                                    renderingTicks={renderingTicksHandler}
                                    timeDisplayFormat="HH:mm"
                                // step={stepDistance}
                                />
                            </div>
                        </div>
                        {/* <div style={{
                            display: 'flex',
                            justifyContent: "space-between"
                        }}>
                            <div>Start Time</div>
                            <div>End Time</div>
                        </div> */}
                    </div>
                </DialogContent>
                <DialogActions>
                    {
                        renderFullAttendeeButton() &&
                        <ButtonComponent
                            style={{
                                background: "#B7B7BB",
                                padding: "6px 16px",
                                fontSize: "0.875rem",
                                minWidth: "64px",
                                width: "140px",
                                color: "#000000",
                                boxSizing: "border-box",
                                fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
                                fontWeight: 400,
                                lineHeight: 1.75,
                                letterSpacing: "0.02857em",
                                borderRadius: "4px",
                                marginRight: 1,
                                marginBottom: 20,
                                textTransform: "none"
                            }}
                            data-ripple="true"
                            onClick={() => {
                                props.getAttendeeStatus({ userid: uid, status: fullAttendeeStatus })
                                props.markFullAttendee()
                                props.closePopUp()
                            }}
                        >
                            Tag Full Duration
                        </ButtonComponent>

                    }

                    <ButtonComponent
                        style={{
                            background: "#f94f5e",
                            padding: "6px 16px",
                            fontSize: "0.875rem",
                            minWidth: "64px",
                            color: "#fff",
                            boxSizing: "border-box",
                            fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
                            fontWeight: 400,
                            lineHeight: 1.75,
                            letterSpacing: "0.02857em",
                            borderRadius: "4px",
                            marginRight: 1,
                            marginBottom: 20,
                            textTransform: "none"
                        }}
                        data-ripple="true"
                        onClick={() => {
                            props.getAttendeeStatus({ userid: uid, status: toolTip })
                            savePartialTime(attendeeTag, props.uid, "save")
                        }}
                    >
                        Save Changes
                    </ButtonComponent>
                    <ButtonComponent
                        style={{
                            background: "#000000",
                            padding: "6px 16px",
                            fontSize: "0.875rem",
                            minWidth: "64px",
                            color: "#fff",
                            boxSizing: "border-box",
                            fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
                            fontWeight: 400,
                            lineHeight: 1.75,
                            letterSpacing: "0.02857em",
                            borderRadius: "4px",
                            marginRight: 24,
                            marginBottom: 20,
                            textTransform: "none"
                        }}
                        data-ripple="true"
                        onClick={() => {
                            // cancleButton()
                            cancleButton2()
                            props.closePopUp()
                        }}
                    >
                        Close
                    </ButtonComponent>
                </DialogActions>
            </Dialog>
        </div >
    );
}

const mapStateToProps = ({ meetingbooking, meetingmaster, auth }) => {
    const { meetingStartTime, meetingEndTime, meetingAttendee, timeZoneId, meetingId } = meetingbooking
    const { authUser } = auth
    const { clientTimeZone, othersCheckAvailablity } = meetingmaster
    return { meetingStartTime, meetingEndTime, meetingAttendee, clientTimeZone, timeZoneId, othersCheckAvailablity, authUser, meetingId }
}

export default connect(mapStateToProps, { updateMeetingBookingState })(PartialMeetingAttendeeTimer)
