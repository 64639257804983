import React, { Component } from 'react'

import AdminServices from "../../../services/adminservices";
import { connect } from "react-redux";
import OrgchartPopUp from "./orgchartpopup";
import "./orgchartadmin.css";
import {
  updateFolderManageFunction,
  saveFolderManageFunction
} from "../../../redux/actions/admin/adminaction";
import { Button } from "@material-ui/core";
import { faCaretDown, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GridComponent, ColumnsDirective, ColumnDirective, Inject, Filter, Group, Sort } from '@syncfusion/ej2-react-grids';
import './orgChartMaster.css';
import ActionTemplate from "./actionTemplate";
import {
  getGridColumn,
  FOLDER_ACTION,
  FOLDERPARENT,
  HIERARCHY
} from "../../../app/other/gridcolumns";

import Progressbar from "../../../components/progressbar/progressbar";
import ErrorMessage from "../../errormessage/errormessage";
import stylingNewSubBussinessUnit from "./orgChartStylingGridComponents";
import { setSpinner } from '@syncfusion/ej2-react-popups';
import { spinner } from "../../../app/other/commonfunction";



class FolderManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      height:
        (window.innerHeight ||
          document.documentElement.clientHeight ||
          document.body.clientHeight) - 188,
      heightPending:
        (window.innerHeight ||
          document.documentElement.clientHeight ||
          document.body.clientHeight) - 188,
      gridHeight: (window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight) - 290,

      result: [],
      folderManagementData: [],
      openpopup: false,
      isLoading: true,
      isExpanded: true
    };
    this.gridInstance = null;
    this.title = "";
    this.type = "";
    this.header = "Folder Management";
    this.filterSettings = { type: 'CheckBox' };
    this.groupOptions = { showGroupedColumn: true, columns: ['parentFolderName'], showDropArea: false };
    this.actionTemplate = this.actionTemplate.bind(this);
    this.check = { type: 'CheckBox' };
    this.width =
      (window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth) - 100;
    this.expandFunc = this.expandFunc.bind(this)
    this.dataBound = this.dataBound.bind(this);
    this.onDataBinding = this.onDataBinding.bind(this)
    this.initialLoad = true;

  }
  sortComparer(reference, comparer) {
    return -1;
  }


  updateDimensions() {
    const updateHeight = window.innerHeight - 230;
    const updateHeightp = window.innerHeight - 185;
    this.setState({ height: updateHeight, heightPending: updateHeightp });
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
    this.getFolderManageFunctionData();
  }


  componentWillReceiveProps(nextProps) {
    if (nextProps.folderManagementFlag === "updated") {
      this.closePopup();
      this.getFolderManageFunctionData();
      setTimeout(() => {
        this.gridInstance.groupModule.expandAll();
      }, 1000)
      this.props.updateFolderManageFunction({ folderManagementFlag: "" });

    }
  }


  getFolderManageFunctionData = () => {
    try {
      const userrole = Promise.resolve(
        AdminServices.getFolderManageData()
      );
      userrole.then((result) => {
        if (result !== null) {
          this.setState({ result: result.result, isLoading: false })

        }
      });
    } catch (error) {
      console.error(error);
    }
  }


  makeActiveInActive = (id, status, parentFolderId) => {
    const { authUser } = this.props;
    let data = {
      authUser: authUser,
      folderId: id,
      isActive: status,
      parentFolderId: parentFolderId,
      type: "Status",
      // hierarchy: hierarchy
    }
    this.props.saveFolderManageFunction(data);
  }



  openPopup = (title, type, newFolderData, hierarchy) => {
    this.setState({ openPopup: true, title: title, type: type, newFolderData: newFolderData, hierarchy: hierarchy });
  }

  closePopup = () => {
    this.setState({ openPopup: false, title: '', type: '', newFolderData: '' });
  }


  actionTemplate = (props) => {

    return (
      <ActionTemplate
        props={props}
        category="FolderManagement"
        header={this.header}
        makeActiveInActive={this.makeActiveInActive}
        openPopup={this.openPopup}
        addButtonTemp={this.addButtonTemp}
      />
    )
  }

  parentFolderTemplate = (props) => {
    return (
      <p style={{ textAlign: "left", fontSize: '14px' }}>{props.parentFolderName}</p>
    )
  }


  setHeaderCss = () => {
    setTimeout(() => {
      const ele = document.getElementsByClassName("e-headercelldiv");
      for (let i in ele) {
        if (!isNaN(parseInt(i, 10))) {
          let index = parseInt(i, 10);

          if (ele[index].innerText === FOLDER_ACTION) {
            ele[index].classList.add("grid-center-g-header");
          } else {
            ele[index].classList.add("grid-left-g-header");
          }
        }
      }
    }, 500);
  }

  groupcFootertMax(props) {
    if (props.field === "ParentFolderName") {
      return (<p style={{ fontWeight: "bold", textAlign: "center", marginLeft: 14 }}>{props.Sum}</p>);
    }
    return null
  }

  dataBound() {
    spinner(false)
    if (this.gridInstance.groupSettings.columns.length > 0) {
      this.gridInstance.groupModule.collapseAll();
    }
  }

  expandFunc = () => {
    const { isExpanded } = this.state

    this.setState(prev => ({ isExpanded: !prev.isExpanded }),
      () => {
        if (isExpanded) {
          this.gridInstance.groupModule.expandAll();
        }
        else {
          this.gridInstance.groupModule.collapseAll();
        }
      }
    )
  }


  /**
  * Created on 09-09-2022 || Noel Fredrick
  * Description - For spinner
  */
  onDataBinding() {
    spinner(true)

  }

  onFirstGridCreated() {
    if (this.initialLoad) {
      this.initialLoad = false;
      setSpinner({
        template: document.getElementById('spinnerContainer').innerHTML,
      });
      document.getElementById('spinnerContainer').remove();

    }

  }


  render() {
    const { openPopup, result, height, gridHeight, isLoading, newFolderData, hierarchy, isExpanded, title, type } = this.state;
    this.setHeaderCss();
    let widthColumnConfirm = 0;
    let finalWidthConfirm;
    // eslint-disable-next-line
    getGridColumn("folderManagement").forEach((data) => {
      widthColumnConfirm = widthColumnConfirm - 10 + data.width;
    });
    switch (window.innerWidth) {
      case 1024:
      case 1366:
      case 1440:
      case 1280:
      case 1152:
        finalWidthConfirm = 1100;
        break;
      default:
        finalWidthConfirm = this.width;
    }
    let width = this.width - 620;
    switch (window.innerWidth) {
      case 1024:
      case 1280:
      case 1366:
      case 1440:
      case 1152:
        width = 1100;
        break;
      default:
        break;
    }
    const gridWidth = (window.innerWidth === 1024 || [1280, 1366, 1440, 1152].includes(window.innerWidth)) ? finalWidthConfirm : width;
    const gridHeightNew = (window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight) > 767
      ? gridHeight - 75//12
      : window.innerHeight === 551 && window.innerWidth === 1152 ? gridHeight - 120
        : window.innerHeight === 599 && window.innerWidth === 1024 ? gridHeight - 130
          : window.innerHeight === 599 ? gridHeight - 110 : window.innerHeight === 731 ? gridHeight - 90 : gridHeight - 70

    let marginLeft = 685;
    switch (window.innerWidth) {
      case 1024:
        marginLeft = 400;
        break;
      case 1280:
      case 1152:
        marginLeft = 500;
        break;
      default:
        break;
    }

    const IconContent = !isExpanded ? (<FontAwesomeIcon icon={faCaretRight} className="mr-sm" />) : (<FontAwesomeIcon icon={faCaretDown} className="mr-sm" />)
    const styleExpand = !isExpanded ? "jss892 expandBtn" : 'expandBtn MuiButton-containedPrimary'

    return (
      <div
        style={{
          color: "black",
          height: height + 15,
          padding: "8px 4px 8px 4px ",
          marginLeft: 14
        }}
        className="sub-businessfunc-height" id='foldermangaeId'

      >
        {result.length > 0 ? <>
          <div style={{ marginBottom: 8 }} className="row">
            <div className="col-xs-5 col-sm-5 col-lg-5 col-md-5 add-header">{this.header} </div>
            <div
              className="col-xs-5 col-sm-5 col-lg-5 col-md-5 pr-xl"
              style={{ textAlign: 'right' }}
            >
              <Button
                style={{
                  marginRight: "50px"
                }}
                className={styleExpand}
                variant="contained"
                onClick={() => this.expandFunc()}

              >
                {IconContent}
                {
                  !isExpanded ? "Collapse All" : "Expand All" //NOSONAR
                }
              </Button>

              <Button

                // style={{ width: "100px", marginBottom: 10, marginLeft: 170, height: 30 }}
                className="remove-button-shadow addbtn"
                color="primary"
                variant="contained"
                onClick={() => this.openPopup(this.header, 'Add', { parentFolderId: 0, folderId: 0, hierarchy: 0 })}
              >
                Add
              </Button>
            </div>
          </div>
          <div className="row">
            <div className='col-xs-10 col-sm-10 col-lg-10 col-md-10'>
              <div style={{ paddingTop: "5px", overflowY: "auto", overflowX: window.innerWidth === 1024 || window.innerWidth === 1152 ? "scroll" : "hidden" }}
                className="control-section gridWidth"
              ><style>{stylingNewSubBussinessUnit}</style>
                <GridComponent
                  dataSource={result}
                  contextMenuItems="AutoFitAll"
                  ref={(grid) => (this.gridInstance = grid)}
                  allowGrouping={true}
                  allowFiltering
                  filterSettings={this.filterSettings}
                  groupSettings={this.groupOptions}
                  allowSorting={true}
                  height={gridHeightNew}
                  rowHeight={40}
                  gridLines='Both'
                  // width={gridWidth + 275}
                  dataBound={this.dataBound.bind(this)}
                  onDataBinding={this.onDataBinding("foldermangaeId")}
                  created={this.onFirstGridCreated.bind(this)}
                >
                  <ColumnsDirective>
                    {getGridColumn("folderManagement").map((item) => {
                      switch (item.colName) {

                        case FOLDERPARENT:
                          return (
                            <ColumnDirective
                              key={item.field}
                              template={this.parentFolderTemplate}
                              textAlign={`${item.align} !important`}
                              field={item.field}
                              headerText={item.colName}
                              width={item.width - 20}
                              filter={false}
                              sortComparer={this.sortComparer}
                            >
                            </ColumnDirective>
                          );


                        case FOLDER_ACTION:
                          return (
                            <ColumnDirective
                              key={item.field}
                              template={this.actionTemplate}
                              textAlign={`${item.align} !important`}
                              field={item.field}
                              headerText={item.colName}
                              width={item.width - 20}
                              filter={false}
                            >
                            </ColumnDirective>
                          );

                        case HIERARCHY:
                          return (
                            <ColumnDirective
                              key={item.field}
                              // template={this.actionTemplate}
                              textAlign={`${item.align} !important`}
                              field={item.field}
                              headerText={item.colName}
                              width={item.width - 10}
                              filter={false}
                              sortComparer={this.sortComparer}
                            >
                            </ColumnDirective>
                          );

                        default:
                          return (
                            <ColumnDirective
                              key={item.field}
                              // template={this.employeeNameTemplate}
                              textAlign={`${item.align} !important`}
                              field={item.field}
                              headerText={item.colName}
                              width={item.width}
                              filter={this.check}
                            >
                            </ColumnDirective>
                          );

                      }
                    })}
                  </ColumnsDirective>

                  <Inject services={[Filter, Group, Sort]} />
                </GridComponent>

                <div className="loader-new" id="spinnerContainer" >
                  <Progressbar />
                </div>

              </div>
            </div>
          </div>
        </> : <div className="control-section col-md-12" style={{ height: 75 }}>
          {isLoading && (<div className="loader-new">
            {/* <Progressbar /> */}
          </div>)
          }
          {(result.length === 0 && isLoading === false) && (
            <ErrorMessage message="No Data Available" />
          )}
        </div>
        }


        <div>

          {
            openPopup &&
            <OrgchartPopUp closePopup={this.closePopup} component={"FolderManagement"} title={title} type={type} newFolderData={newFolderData} hierarchy={hierarchy} />
          }

        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ adminreducer, auth }) => {
  const { folderManagementFlag } = adminreducer;
  const { authUser } = auth;
  return { folderManagementFlag, authUser };
};



export default connect(mapStateToProps, {
  updateFolderManageFunction,
  saveFolderManageFunction
})(FolderManagement);


