import React, { useState, useEffect } from "react";
import { ComboBoxComponent } from "@syncfusion/ej2-react-dropdowns";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import { FormValidator } from "@syncfusion/ej2-inputs";
import okrServices from "../../services/okr/okrservices";
import { getMySqlDateFormat } from "../../app/other/commonfunction";
import { connect } from "react-redux";
import { saveFrequencyMaster, updateOKRData } from "../../redux/actions/okr/okractions";
import { pleaseEnterFrequencyFinalRatingEndDate, pleaseEnterFrequencyFreq, pleaseEnterFrequencyFY, pleaseEnterFrequencyIndicator, pleaseEnterFrequencyPerformanceAddEndDate, pleaseEnterFrequencyPerformanceEditEndDate, pleaseEnterFrequencyPerformanceStDate, pleaseEnterFrequencyPotentialAddEndDate, pleaseEnterFrequencyPotentialEditEndDate, pleaseEnterFrequencyPotentialStDate, pleaseEnterFrequencyRatingStDate, pleaseEnterFrequencySelfRatingEndDate, pleaseEnterFrequencySupRatingEndDate } from "../../assets/constants/constants";
import ProgressBar from "../progressbar/progressbar";

const FrequencyMaster = (props) => {

  const { authUser, openPopup, masterId, loading } = props;
  const masterItemUid = props.masterItemId;

  const [state, setState] = useState({
    title: null,
    masterItemId: null,
    fyId: props.currentFinancialYearId,
    frequency: null,
    frequencyIndicator: null,
    applicableStartDate: null,
    applicableEndDate: null,
    performanceAddStartDate: null,
    performanceAddEndDate: null,
    performanceEditEndDate: null,
    potentialAddStartDate: null,
    potentialEditEndDate: null,
    potentialAddEndDate: null,
    ratingStartDate: null,
    selfRatingEndDate: null,
    supervisorRatingEndDate: null,
    finalRatingEndDate: null,
    isActive: "1",
    frequencyMasterData: [],
  });
  const [indicator, setIndicator] = useState([]);

  useEffect(() => {
    getData();
  }, [openPopup, masterId, masterItemUid]);

  useEffect(() => {

    if (state.frequency) {
      getIndicatorList(state.fyId, state.frequency);
    }

  }, [state.frequency, state.frequencyIndicator]);


  useEffect(() => {
    setTimeout(() => {
      props.setSubmitClick(onSaveData);
    }, 100);
  }, [state]);

  useEffect(() => {
    if (props.okrMasterFlag === "updated") {
      props.changePopUpHandler("close");
    }
  }, [props.okrMasterFlag]);


  const getIndicatorList = async (pFyId, pFrequencyId) => {

    const indicatorArray = []
    const frequencyList = await okrServices.getOKRFrequencyData(authUser, pFyId, pFrequencyId);
    const frequencyListData = frequencyList.data
    for (var i = 0; i < frequencyListData.length; i++) {
      indicatorArray.push({ Name: frequencyListData[i].startDate, Id: `${i + 1}` })
    }
    setIndicator(indicatorArray)

    if (state.frequencyIndicator && frequencyListData) {
      const applicableDates = frequencyListData[state.frequencyIndicator - 1]
      setState((prevState) => ({ ...prevState, applicableStartDate: new Date(applicableDates.actualStartDate) }));
      setState((prevState) => ({ ...prevState, applicableEndDate: new Date(applicableDates.actualEndDate) }));
    }
  };


  const getData = () => {
    ["frequencyFYErr",
      "frquencymasterFrequencyErr",
      "frequencyIndicatorErr",
      "addEditPerformanceStDateErr",
      "addPerformanceEndDateErr",
      "editPerformanceEndDateErr",
      "addEditPotentialStDateErr",
      "addPotentialEndDateErr",
      "editPotentialEndDateErr",
      "ratingStartDateErr",
      "selfRatingEndDateErr",
      "supRatingEndDateErr",
      "finalRatingEndDateErr"
    ].forEach(err => { document.getElementById(err).innerHTML = "" });
    if (openPopup === 'add') {
      props.updateOKRData({ loading: false });
      setState({
        title: null,
        masterItemId: null,
        fyId: props.currentFinancialYearId,
        frequency: null,
        frequencyIndicator: null,
        applicableStartDate: null,
        applicableEndDate: null,
        performanceAddStartDate: null,
        performanceAddEndDate: null,
        performanceEditEndDate: null,
        potentialAddStartDate: null,
        potentialEditEndDate: null,
        potentialAddEndDate: null,
        ratingStartDate: null,
        selfRatingEndDate: null,
        supervisorRatingEndDate: null,
        finalRatingEndDate: null,
        isActive: "1",
        frequencyMasterData: [],
      });
    }
    else if (openPopup === 'edit') {
      props.updateOKRData({ loading: true });
      (
        async () => {

          const frequencyMasterData = await okrServices.getOKRMaserData(
            authUser,
            masterId,
            masterItemUid
          );
          const {

            masterItemId,
            fyId,
            frequency,
            frequencyIndicator,
            applicableStartDate,
            applicableEndDate,
            performanceAddStartDate,
            performanceAddEndDate,
            performanceEditEndDate,
            potentialAddStartDate,
            potentialEditEndDate,
            potentialAddEndDate,
            ratingStartDate,
            selfRatingEndDate,
            supervisorRatingEndDate,
            finalRatingEndDate,
            isActive,
          } = frequencyMasterData.Result[0];


          let data = {
            masterItemId,
            fyId,
            frequency,
            frequencyIndicator: frequencyIndicator.toString(),
            applicableStartDate,
            applicableEndDate,
            performanceAddStartDate,
            performanceAddEndDate,
            performanceEditEndDate,
            potentialAddStartDate,
            potentialEditEndDate,
            potentialAddEndDate,
            ratingStartDate,
            selfRatingEndDate,
            supervisorRatingEndDate,
            finalRatingEndDate,
            isActive,
          };
          setState((preState) => ({ ...preState, ...data }));
          props.updateOKRData({ loading: false });
        }
      )();
    }
  }

  const inputChangeHandler = async (e, type) => {
    let options = {}

    switch (type) {

      case "financialYear":
        options = {
          rules: {
            frequencyFYErr: {
              required: [true, pleaseEnterFrequencyFY],
            },
          }
        }
        setState((prevState) => ({
          ...prevState, fyId: e.value,
          frequency: null,
          frequencyIndicator: null,
          applicableStartDate: null,
          applicableEndDate: null
        }));
        break;

      case "frequency":
        options = {
          rules: {
            frquencymasterFrequencyErr: {
              required: [true, pleaseEnterFrequencyFreq],
            },
          }
        }
        setState((prevState) => ({
          ...prevState, frequency: e.value,
          frequencyIndicator: null,
          applicableStartDate: null,
          applicableEndDate: null

        }));
        break;

      case "frequencyIndicator":
        await getIndicatorList(state.fyId, state.frequency)
        setState((prevState) => ({
          ...prevState, frequencyIndicator: e.value !== null ? e.value.toString() : null,
          applicableStartDate: null,
          applicableEndDate: null,
          performanceAddStartDate: null,
          performanceAddEndDate: null,
          performanceEditEndDate: null,
          potentialAddStartDate: null,
          potentialEditEndDate: null,
          potentialAddEndDate: null,
          ratingStartDate: null,
          selfRatingEndDate: null,
          supervisorRatingEndDate: null,
          finalRatingEndDate: null,
        }));
        options = {
          rules: {
            frequencyIndicatorErr: {
              required: [true, pleaseEnterFrequencyIndicator],
            },
          }
        }
        break;

      case "performanceAddStartDate":
        setState((prevState) => ({ ...prevState, performanceAddStartDate: e.value, performanceAddEndDate: "", performanceEditEndDate: "" }));
        break;

      case "performanceAddEndDate":
        setState((prevState) => ({ ...prevState, performanceAddEndDate: e.value, performanceEditEndDate: "" }));
        break;

      case "performanceEditEndDate":
        setState((prevState) => ({ ...prevState, performanceEditEndDate: e.value }));
        break;

      case "potentialAddStartDate":
        setState((prevState) => ({ ...prevState, potentialAddStartDate: e.value, potentialEditEndDate: "", potentialAddEndDate: "" }));
        break;

      case "potentialAddEndDate":
        setState((prevState) => ({ ...prevState, potentialAddEndDate: e.value, potentialEditEndDate: "" }));
        break;

      case "potentialEditEndDate":
        setState((prevState) => ({ ...prevState, potentialEditEndDate: e.value }));
        break;

      case "ratingStartDate":
        setState((prevState) => ({ ...prevState, ratingStartDate: e.value }));
        break;

      case "selfRatingEndDate":
        setState((prevState) => ({ ...prevState, selfRatingEndDate: e.value, finalRatingEndDate: '' }));
        break;

      case "supervisorRatingEndDate":
        setState((prevState) => ({ ...prevState, supervisorRatingEndDate: e.value, finalRatingEndDate: '' }));
        break;

      case "finalRatingEndDate":
        setState((prevState) => ({ ...prevState, finalRatingEndDate: e.value }));
        break;
      default:
    }
    setTimeout(() => {
      const addFormObject = new FormValidator("#okrFrequencyMasterForm", options);
      addFormObject.validate();
    }, 100);
  }
  const onSaveData = async () => {

    const {
      masterItemId,
      fyId,
      frequency,
      frequencyIndicator,
      applicableStartDate,
      applicableEndDate,
      performanceAddStartDate,
      performanceAddEndDate,
      performanceEditEndDate,
      potentialAddStartDate,
      potentialAddEndDate,
      potentialEditEndDate,
      ratingStartDate,
      selfRatingEndDate,
      supervisorRatingEndDate,
      finalRatingEndDate,
      isActive,
    } = state;

    const options = {

      rules: {
        frequencyFYErr: {
          required: [true, pleaseEnterFrequencyFY],
        },

        frquencymasterFrequencyErr: {
          required: [true, pleaseEnterFrequencyFreq],
        },
        frequencyIndicatorErr: {
          required: [true, pleaseEnterFrequencyIndicator],
        },
        addEditPerformanceStDateErr: {
          required: [true, pleaseEnterFrequencyPerformanceStDate],
        },

        addPerformanceEndDateErr: {
          required: [true, pleaseEnterFrequencyPerformanceAddEndDate],
        },
        editPerformanceEndDateErr: {
          required: [true, pleaseEnterFrequencyPerformanceEditEndDate],
        },
        addEditPotentialStDateErr: {
          required: [true, pleaseEnterFrequencyPotentialStDate],
        },

        addPotentialEndDateErr: {
          required: [true, pleaseEnterFrequencyPotentialAddEndDate],
        },
        editPotentialEndDateErr: {
          required: [true, pleaseEnterFrequencyPotentialEditEndDate],
        },

        ratingStartDateErr: {
          required: [true, pleaseEnterFrequencyRatingStDate],
        },
        selfRatingEndDateErr: {
          required: [true, pleaseEnterFrequencySelfRatingEndDate],
        },

        supRatingEndDateErr: {
          required: [true, pleaseEnterFrequencySupRatingEndDate],
        },
        finalRatingEndDateErr: {
          required: [true, pleaseEnterFrequencyFinalRatingEndDate],
        }

      },
    };

    let validateDates = true;
    setTimeout(() => {
      const addFormObject = new FormValidator("#okrFrequencyMasterForm", options);
      if (addFormObject.validate() && validateDates) {
        let frequencyMasterData = {
          userId: authUser,
          type: masterItemId > 0 ? "EDIT" : "ADD",
          masterItemId,
          fyId,
          frequency,
          frequencyIndicator,
          applicableStartDate: getMySqlDateFormat(applicableStartDate),
          applicableEndDate: getMySqlDateFormat(applicableEndDate),
          performanceAddStartDate: getMySqlDateFormat(performanceAddStartDate),
          performanceAddEndDate: getMySqlDateFormat(performanceAddEndDate),
          performanceEditEndDate: getMySqlDateFormat(performanceEditEndDate),
          potentialAddStartDate: getMySqlDateFormat(potentialAddStartDate),
          potentialAddEndDate: getMySqlDateFormat(potentialAddEndDate),
          potentialEditEndDate: getMySqlDateFormat(potentialEditEndDate),
          ratingStartDate: getMySqlDateFormat(ratingStartDate),
          selfRatingEndDate: getMySqlDateFormat(selfRatingEndDate),
          supervisorRatingEndDate: getMySqlDateFormat(supervisorRatingEndDate),
          finalRatingEndDate: getMySqlDateFormat(finalRatingEndDate),
          isActive,
        };
        props.updateOKRData({ loading: true });
        props.saveFrequencyMaster(frequencyMasterData);
      }
    }, 300);

  }

  const frequencyData = [
    { Name: "Monthly", Id: "1" },
    { Name: "Quarterly", Id: "2" },
    { Name: "Half Yearly", Id: "3" },
    { Name: "Yearly", Id: "4" },]

  return (
    <>
      <div id="okrFrequencyMasterForm">
        <div style={{ padding: "16px" }}>
          <div className="col-xs-12 col-sm-12 col-lg-12 mt-sm mb-sm atc-no-paddding">
            <ComboBoxComponent
              id="frequencyFY"
              data-msg-containerid="frequencyFYErr"
              name="frequencyFYErr"
              dataSource={props.finacialYearData}
              fields={{ text: "Name", value: "Id" }}
              value={state.fyId}
              placeholder="Financial Year *"
              floatLabelType="Auto"
              cssClass="e-outline"
              onChange={(e) => inputChangeHandler(e, "financialYear")}
              htmlAttributes={{ maxlength: 100 }}
            />
            <div id="frequencyFYErr" className="e-input-group e-float-icon-left error-div" />
          </div>
          <div className="col-xs-12 col-sm-12 col-lg-12  mb-sm atc-no-paddding">
            <ComboBoxComponent
              id="frquencymasterFrequency"
              data-msg-containerid="frquencymasterFrequencyErr"
              name="frquencymasterFrequencyErr"
              dataSource={frequencyData}
              fields={{ text: "Name", value: "Id" }}
              value={state.frequency}
              placeholder="Frequency *"
              floatLabelType="Auto"
              cssClass="e-outline"
              onChange={(e) => inputChangeHandler(e, "frequency")}
              htmlAttributes={{ maxlength: 100 }}
            />
            <div id="frquencymasterFrequencyErr" className="e-input-group e-float-icon-left  error-div" />
          </div>

          <div className="col-xs-12 col-sm-12 col-lg-12 mb-sm atc-no-paddding">
            <ComboBoxComponent
              id="frequencyIndicator"
              data-msg-containerid="frequencyIndicatorErr"
              name="frequencyIndicatorErr"
              dataSource={indicator}
              fields={{ text: "Name", value: "Id" }}
              value={state.frequencyIndicator}
              placeholder="Indicator *"
              floatLabelType="Auto"
              cssClass="e-outline"
              onChange={(e) => inputChangeHandler(e, "frequencyIndicator")}
              htmlAttributes={{ maxlength: 100 }}
            />
            <div id="frequencyIndicatorErr" className="e-input-group e-float-icon-left error-div" />
          </div>
          <div
            className="col-xs-12 col-sm-12 col-lg-12 col-md-12 "
            style={{ color: "#939399", font: "normal normal bold 14px/16px Arial" }}
          >
            Applicable Period
          </div>

          <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 mt-sm atc-no-paddding">
            <div
              className="col-xs-6 col-sm-6 col-lg-6 col-md-6"
              style={{ paddingLeft: 0 }}
            >
              <DatePickerComponent
                style={{ width: "100%" }}
                id="applicablePeriodFrom"
                name="applicablePeriodFromErr"
                data-msg-containerid="applicablePeriodFromErr"
                format="dd-MMM-yyyy"
                value={state.applicableStartDate}
                floatLabelType="Auto"
                cssClass="e-outline"
                firstDayOfWeek={1}
                placeholder="From "
                // change={(e) => inputChangeHandler(e, "applicablePeriodFrom")}
                readOnly
                disabled
              />
              {/* <div
                id="aplicablePeriodFromErr"
                className="e-input-group e-float-icon-left error-div"
              /> */}
            </div>
            <div
              className="col-xs-6 col-sm-6 col-lg-6 col-md-6"
              style={{ paddingLeft: 0 }}
            >
              <DatePickerComponent
                style={{ width: "100%" }}
                id="applicablePeriodTo"
                name="applicablePeriodToErr"
                data-msg-containerid="applicablePeriodToErr"
                format="dd-MMM-yyyy"
                value={state.applicableEndDate}
                floatLabelType="Auto"
                cssClass="e-outline"
                firstDayOfWeek={1}
                placeholder="To"
                // change={(e) => inputChangeHandler(e, "applicablePeriodTo")}
                readOnly
                disabled
              />
              {/* <div
                id="aplicablePeriodToErr"
                className="e-input-group e-float-icon-left error-div"
              /> */}
            </div>
          </div>
          <div
            className="col-xs-12 col-sm-12 col-lg-12 col-md-12 mb-sm pt-lg "
            style={{ color: "#939399", font: "normal normal bold 14px/16px Arial" }}
          >
            Performance OKR
          </div>

          <div className="col-xs-12 col-sm-12 col-lg-12 mb-sm atc-no-paddding">
            <DatePickerComponent
              style={{ width: "100%" }}
              id="addEditPerformanceStDate"
              name="addEditPerformanceStDateErr"
              data-msg-containerid="addEditPerformanceStDateErr"
              format="dd-MMM-yyyy"
              value={state.performanceAddStartDate}
              floatLabelType="Auto"
              cssClass="e-outline"
              firstDayOfWeek={1}
              placeholder="Add & Edit Performance Start Date *"
              change={(e) => inputChangeHandler(e, "performanceAddStartDate")}
            />
            <div
              id="addEditPerformanceStDateErr"
              className="e-input-group e-float-icon-left error-div"
            />
          </div>
          <div className="col-xs-12 col-sm-12 col-lg-12 mb-sm atc-no-paddding">
            <DatePickerComponent
              style={{ width: "100%" }}
              id="addPerformanceEndDate"
              name="addPerformanceEndDateErr"
              data-msg-containerid="addPerformanceEndDateErr"
              format="dd-MMM-yyyy"
              value={state.performanceAddEndDate}
              floatLabelType="Auto"
              cssClass="e-outline"
              firstDayOfWeek={1}
              placeholder="Add Performance End Date *"
              change={(e) => inputChangeHandler(e, "performanceAddEndDate")}
            />
            <div
              id="addPerformanceEndDateErr"
              className="e-input-group e-float-icon-left error-div"
            />
          </div>
          <div className="col-xs-12 col-sm-12 col-lg-12 mb-sm  atc-no-paddding">
            <DatePickerComponent
              style={{ width: "100%" }}
              id="editPerformanceEndDate"
              name="editPerformanceEndDateErr"
              data-msg-containerid="editPerformanceEndDateErr"
              format="dd-MMM-yyyy"
              value={state.performanceEditEndDate}
              floatLabelType="Auto"
              cssClass="e-outline"
              firstDayOfWeek={1}
              placeholder="Edit Performance End Date *"
              change={(e) => inputChangeHandler(e, "performanceEditEndDate")}
            />
            <div
              id="editPerformanceEndDateErr"
              className="e-input-group e-float-icon-left error-div"
            />
          </div>
          <div
            className="col-xs-12 col-sm-12 col-lg-12 col-md-12 mb-sm  "
            style={{ color: "#939399", font: "normal normal bold 14px/16px Arial" }}
          >
            Potential OKR
          </div>
          <div className="col-xs-12 col-sm-12 col-lg-12 mb-sm atc-no-paddding ">
            <DatePickerComponent
              style={{ width: "100%" }}
              id="addEditPotentialStDate"
              name="addEditPotentialStDateErr"
              data-msg-containerid="addEditPotentialStDateErr"
              format="dd-MMM-yyyy"
              value={state.potentialAddStartDate}
              floatLabelType="Auto"
              cssClass="e-outline"
              firstDayOfWeek={1}
              placeholder="Add & Edit Potential Start Date *"
              change={(e) => inputChangeHandler(e, "potentialAddStartDate")}
            />
            <div
              id="addEditPotentialStDateErr"
              className="e-input-group e-float-icon-left error-div"
            />
          </div>
          <div className="col-xs-12 col-sm-12 col-lg-12 mb-sm  atc-no-paddding">
            <DatePickerComponent
              style={{ width: "100%" }}
              id="addPotentialEndDate"
              name="addPotentialEndDateErr"
              data-msg-containerid="addPotentialEndDateErr"
              format="dd-MMM-yyyy"
              value={state.potentialAddEndDate}
              floatLabelType="Auto"
              cssClass="e-outline"
              firstDayOfWeek={1}
              placeholder="Add Potential End Date *"
              change={(e) => inputChangeHandler(e, "potentialAddEndDate")}
            />
            <div
              id="addPotentialEndDateErr"
              className="e-input-group e-float-icon-left error-div"
            />
          </div>
          <div className="col-xs-12 col-sm-12 col-lg-12 mb-sm  atc-no-paddding">
            <DatePickerComponent
              style={{ width: "100%" }}
              id="editPotentialEndDate"
              name="editPotentialEndDateErr"
              data-msg-containerid="editPotentialEndDateErr"
              format="dd-MMM-yyyy"
              value={state.potentialEditEndDate}
              floatLabelType="Auto"
              cssClass="e-outline"
              firstDayOfWeek={1}
              placeholder="Edit Potential End Date *"
              change={(e) => inputChangeHandler(e, "potentialEditEndDate")}
            />
            <div
              id="editPotentialEndDateErr"
              className="e-input-group e-float-icon-left error-div"
            />
          </div>
          <div
            className="col-xs-12 col-sm-12 col-lg-12 col-md-12 mb-sm  "
            style={{ color: "#939399", font: "normal normal bold 14px/16px Arial" }}
          >
            Self,Supervisor and Final Rating
          </div>
          <div className="col-xs-12 col-sm-12 col-lg-12 mb-sm  atc-no-paddding">
            <DatePickerComponent
              style={{ width: "100%" }}
              id="ratingStartDate"
              name="ratingStartDateErr"
              data-msg-containerid="ratingStartDateErr"
              format="dd-MMM-yyyy"
              value={state.ratingStartDate}
              floatLabelType="Auto"
              cssClass="e-outline"
              firstDayOfWeek={1}
              placeholder="Rating Start Date *"
              change={(e) => inputChangeHandler(e, "ratingStartDate")}
            />
            <div
              id="ratingStartDateErr"
              className="e-input-group e-float-icon-left error-div"
            />
          </div>
          <div className="col-xs-12 col-sm-12 col-lg-12 mb-sm  atc-no-paddding">
            <DatePickerComponent
              style={{ width: "100%" }}
              id="selfRatingEndDate"
              name="selfRatingEndDateErr"
              data-msg-containerid="selfRatingEndDateErr"
              format="dd-MMM-yyyy"
              value={state.selfRatingEndDate}
              floatLabelType="Auto"
              cssClass="e-outline"
              firstDayOfWeek={1}
              placeholder="Self Rating End Date *"
              change={(e) => inputChangeHandler(e, "selfRatingEndDate")}
            />
            <div
              id="selfRatingEndDateErr"
              className="e-input-group e-float-icon-left error-div"
            />
          </div>
          <div className="col-xs-12 col-sm-12 col-lg-12 mb-sm  atc-no-paddding">
            <DatePickerComponent
              style={{ width: "100%" }}
              id="supRatingEndDate"
              name="supRatingEndDateErr"
              data-msg-containerid="supRatingEndDateErr"
              format="dd-MMM-yyyy"
              value={state.supervisorRatingEndDate}
              floatLabelType="Auto"
              cssClass="e-outline"
              firstDayOfWeek={1}
              placeholder="Supervisor Rating End Date *"
              change={(e) => inputChangeHandler(e, "supervisorRatingEndDate")}
            />
            <div
              id="supRatingEndDateErr"
              className="e-input-group e-float-icon-left error-div"
            />
          </div>
          <div className="col-xs-12 col-sm-12 col-lg-12 mb-sm  atc-no-paddding">
            <DatePickerComponent
              style={{ width: "100%" }}
              id="finalRatingEndDate"
              name="finalRatingEndDateErr"
              data-msg-containerid="finalRatingEndDateErr"
              format="dd-MMM-yyyy"
              value={state.finalRatingEndDate}
              floatLabelType="Auto"
              cssClass="e-outline"
              firstDayOfWeek={1}
              placeholder="Final Rating End Date *"
              change={(e) => inputChangeHandler(e, "finalRatingEndDate")}
            />
            <div
              id="finalRatingEndDateErr"
              className="e-input-group e-float-icon-left error-div"
            />
          </div>
        </div>
        {loading && <ProgressBar />}
      </div>
    </>
  );
};

const mapStateToProps = ({ auth, okrreducer, meetingmaster }) => {
  const { authUser } = auth;
  const { currentFinancialYearId, finacialYearData } = meetingmaster;
  const { okrMasterFlag, loading } = okrreducer;

  return {
    authUser,
    okrreducer,
    currentFinancialYearId,
    okrMasterFlag,
    finacialYearData,
    loading
  };
};
export default connect(mapStateToProps, { saveFrequencyMaster, updateOKRData })(FrequencyMaster);
