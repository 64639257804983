import React from 'react';

export default function AppChangeHeader() {
  return (
    <div style={{ width: '100%', backgroundColor: '#FFFFFF' }}>
      <div style={{ height: '60px', backgroundColor: '#FFFFF', borderBottom: '1px solid #dbdbddd', width: '100%', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <div style={{ display: 'flex', alignItems: 'center', gap: '3px', paddingLeft: '7px', justifyContent: 'flex-start' }}>
          <img alt='company-logo' src='/images/Cheersin_logo_full.svg' style={{ width: '145px', objectFit: 'cover', borderRadius: 0, margin:'0 15px' }} />
          <span style={{ height: '35px', width: '2px', backgroundColor: '#dbdbdd' }}></span>
          <img alt='company-logo' src='/images/FutureBridge_logo_full.svg' style={{ width: '145px', objectFit: 'cover', borderRadius: 0, margin:'0 15px' }} />
          <span style={{ height: '35px', width: '2px', backgroundColor: '#dbdbdd' }}></span>
          <img alt='company-logo' src='/images/WhatNext_logo_full.svg' style={{ width: '145px', objectFit: 'cover', borderRadius: 0, margin:'0 15px' }} />
          <span style={{ height: '35px', width: '2px', backgroundColor: '#dbdbdd' }}></span>
          <img alt='company-logo' src='/images/Cognition_logo_full.svg' style={{ width: '145px', objectFit: 'cover', borderRadius: 0, margin:'0 15px' }} />
        </div>
      </div>
    </div>
  );
}