import React, {useEffect, useState} from 'react';
import { connect } from 'react-redux';
import XYAxis from './xyaxis';
import OKRRatingAndTeamStatus from './okrratingandteamstatus';
import OKRDashboardServices from '../../services/okrdashboardservices/okrdashboardservices';
import {addUpdateOkrDashboardRedux} from '../../redux/actions/okrdashboardaction/okrdashboardaction';
import ProgressBar from '../progressbar/progressbar'
function TeamView(props) {
  const [state, setState] = useState({
    height:null,
    windowAdjust:null,
    isLoading:true
  });
  useEffect(()=>{
    window.addEventListener("resize", updateDimensions()); // NOSONAR
  }, [])
  useEffect(()=>{
    if(props.dashboardTeamRatingStatus==='updated'){
      setState({...state,isLoading:true});
      getDashboardRatingStatusData();
      getXYAxisgridviewData();
      getTeamCompanyMedian();
      getXYAxisDefaultGridData();
      props.addUpdateOkrDashboardRedux({dashboardTeamRatingStatus:''});  
      setTimeout(()=>{
        setState({...state,isLoading:false})
      },500)
    }
  }, [props.dashboardTeamRatingStatus==='updated'])
  const getTeamCompanyMedian= async()=>{
    const {authUser, teamMemberIds, fyId, monthOfRating}=props;
    if(fyId!==null && monthOfRating!==0 && teamMemberIds!==''){
      try{
        let teamCompanyMedian= await OKRDashboardServices.getOKRDashboardData('teamCompanyMedian', authUser, teamMemberIds, fyId, monthOfRating);
        props.addUpdateOkrDashboardRedux({teamMedian:teamCompanyMedian[0].teamMedian, companyMedian:teamCompanyMedian[1].companyMedian})
      }catch(error){
        console.log("🚀 ~ file: teamview.jsx ~ line 37 ~ getTeamCompanyMedian ~ error", error)
      }
    }
  }
  const getDashboardRatingStatusData = async()=>{
    const {teamMemberIds, authUser, fyId, monthOfRating}=props;
    if(fyId!==null && teamMemberIds!=='' && monthOfRating!==0){
      const dashboardteamRatingStatus = await OKRDashboardServices.getDashboardteamRatingStatus(authUser, teamMemberIds, fyId, monthOfRating)
      props.addUpdateOkrDashboardRedux({teamSizeData:dashboardteamRatingStatus[0],directReporteesData:dashboardteamRatingStatus[1]});
    }else{
      console.log('Please select Financial Year and Team Membaer and Month.')
    } 
  }
  const getXYAxisgridviewData = async () =>{
    const {teamMemberIds, authUser, fyId, monthOfRating, teamStatusSelected}=props;
    let xyAxisGridData = [];
    let xyAxixCardData = [];
    let type='okrxyaxisgriddata';
    if(teamStatusSelected==='direct-reportee'){
      type='okrxyaxisDirect';
    }
    if(fyId!==null && teamMemberIds!=='' && monthOfRating!==0){

      let gridData = await OKRDashboardServices.getOKRDashboardData(type,authUser,teamMemberIds,fyId,monthOfRating)
      gridData.filter((item)=>{
        if(item.dataType === 'gridData'){
          xyAxisGridData.push(item)
        }
        if(item.dataType === 'cardData'){
          xyAxixCardData.push(item)
        }
      })
      props.addUpdateOkrDashboardRedux({xyAxixGridRankingData:xyAxisGridData, xyAxixCardRankingData:xyAxixCardData});
    }else{
      console.log('Please select Financial Year and Team Membaer and Month.')
    }
  }
  const getXYAxisDefaultGridData = async () =>{
    const { teamMemberIds, authUser, fyId, monthOfRating, teamStatusSelected }=props;
    let type='defaultTeamMembers';
    if(teamStatusSelected==='direct-reportee'){
        type='defaultTeamMembersDirect'
    }
    try{
      let gridData = await OKRDashboardServices.getOKRDashboardData(type,authUser,teamMemberIds,fyId,monthOfRating)
      props.addUpdateOkrDashboardRedux({xyAxixGridDefaultRankingData:gridData});
    }catch(e){}

  }
  const updateDimensions=()=> {
    const updateheight = window.innerHeight - 302;
    const windowAdjusts = window.innerHeight - 212;
    setState({
      height: updateheight,
      windowAdjust: windowAdjusts,
    });

  }
  const {showMonths}=props;
  const {height, windowAdjust, isLoading} =state;
const getStyle=()=>{
  if(window.innerHeight===625){
    return {height: showMonths ? 322:315, overflow: showMonths ? "auto" : "" }
  }
  if(window.innerHeight < 670){
    return {height: showMonths ? 355:315, overflow: showMonths ? "auto" : "" }
  }else{
    return {height: showMonths ? height:windowAdjust, overflow: showMonths ? "hidden" : "" }
  }
}
  return (
    <div style={{height: showMonths ? height:windowAdjust }}>
      <div className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 team-rating-components" style={getStyle()}>
        <div className="mr-md mb-md ml-md">

          <div className="row card-layout">
            <div className="col-3 col-lg-3 col-md-3 col-sm-3 col-xs-3 okr-rating-team-status">
              <OKRRatingAndTeamStatus showMonths={showMonths} />
            </div>
      {
            !isLoading ?
            <div className="col-9 col-lg-9 col-md-9 col-sm-9 col-xs-9" /** xy-axis-and-grid-view */>
              <XYAxis showMonths={showMonths} />
            </div>
            :
            <div className="col-9 col-lg-9 col-md-9 col-sm-9 col-xs-9" /** xy-axis-and-grid-view */>
              <div style={{marginTop:'25%'}}>
              <ProgressBar/>
              </div>
            </div>
      } 
          </div>
        </div>
      </div>
    </div>
  )
}


const mapStateToProps = ({ auth, okrdashboardreducer, }) => {
  const { authUser } = auth;
  
  const {fyId, teamMemberIds, monthOfRating, dashboardTeamRatingStatus, teamStatusSelected, viewCard }=  okrdashboardreducer;
  return {
    authUser, fyId, teamMemberIds, monthOfRating, dashboardTeamRatingStatus, teamStatusSelected, viewCard
  }
};
export default connect(mapStateToProps, { addUpdateOkrDashboardRedux })(
  TeamView
);
