import React, { useState } from 'react';
import {urlConfig, corsallow, maxFileSize} from '../../../config/config';
import {dencrypt, getNotify} from '../../../app/other/commonfunction'
import {ERROR} from '../../../assets/constants/constants'

export default function CandProfileUpdate(props) {//nosonar
  const [imageName, setImageName] = useState(props.imageName);
  
/**
 * 
 * @param {*} e event 
 * @returns 
 * @author Vivek Khodade
 * @description upload the image file on server and save the image name database.
 */  
const uploadImage = (e) => {
    const { search } = window.location;
    const params = new URLSearchParams(search);
    const candid = dencrypt(params.get("candid"))
    const currentDate = Date.parse(new Date()) / 1000;
    const imageNames = e.target.files[0];
    const imgType = imageNames.type.replace("image/", ".");
    const size = e.target.files[0].size / 1024;
    if (imageNames !== undefined && size < maxFileSize) {
      if (imageNames.type !== "image/png" && imageNames.type !== "image/jpeg") {
        getNotify(ERROR, "Please select png or jpeg image");
        return;
      }
    } else {
      getNotify(ERROR, "Please select png or jpeg image (upto 10 MB) ");
      return;
    }
    return new Promise((resolve, reject) => {
      const req = new XMLHttpRequest();
      try {
        const formData = new FormData();
        formData.append("usertype", "C");
        formData.append("type", "P");
        formData.append("fileName", `${currentDate}${imgType}`);
        formData.append("candidateId", candid);
        formData.append("file", imageNames, `${currentDate}${imgType}`);
        req.open("POST", `${corsallow}${urlConfig.tmlcApiUrl}document/upl/fileUpload`);
        req.setRequestHeader("Authorization", localStorage.getItem("jwtToken"));
        req.send(formData);
        req.onreadystatechange = () => {
          if (req.readyState === XMLHttpRequest.DONE) {  
            setImageName(`${currentDate}${imgType}`)
          }
        }
      } catch (error) {
        console.log("Image Upload", error);
      }
    });
  };

  /**
   * @author Vivek Khodade
   * @description to open file dialog
   */
   const openFileDialog = (Id) => {
     document.getElementById(`${Id}`).click(); // NOSONAR
     
  }
  const fileurl= `${urlConfig.tmlcApiUrl}document/A/730/${imageName}`;
    return (
        <div>
        <div>PROFILE PICTURE</div>
        <div className='col-sm-10 col-xs-12 col-lg-3 col-md-3 mt-md' 
        style={{ 
                height: '115px'
              }}> 
            {
              imageName!=="" && imageName!==null ?
              <div className='col-lg-12 candupl-box-outline'>
                  <div className='col-sm-5 col-xs-5 col-lg-4 col-md-5'>
                        
                          <a href={fileurl} download target="_blank" rel="noopener noreferrer">
                              <img id='profilePic' title='Preview file' className='image-view' src={fileurl} alt='' />
                          </a>
                  </div>
                  <div className='col-sm-4 col-xs-4 col-lg-5 col-md-4'>
                      <div className='image-name-box'>
                          <div className='image-name' >
                              {imageName}
                          </div>
                          <div className='image-size'>
                            {/* &nbsp;--- Kb */}
                          </div>
                      </div>
                  </div>
                  <div className='col-sm-3 col-xs-3 col-lg-3 col-md-3'>
                      <div className='cancel-icon row'>
                          {
                              <div className='upload-icon-reject'
                                  title='File upload'
                                  style={{ cursor: 'pointer' }}
                                  onClick={()=>openFileDialog('inputUploadProfile')}
                              >
                                  <input
                                      id={`inputUploadProfile`}
                                      // ref={this.fileInputRef}
                                      className="FileInput"
                                      style={{ display: "none" }}
                                      type="file"
                                      accept="image/jpeg,image/png,image/jpg,application/pdf"
                                      onChange={uploadImage}
                                  />
                              </div>
                          }

                          {
                              <i className="fas fa-times custom-close-style pl-xlg"
                                  title='Cancel file'
                                  onClick={() => {
                                    setImageName("");
                                  }}
                                  ></i>
                          }
                      </div>
                  </div>
              </div>  
            :
              <div className='candupl-box-outline'>
                <center>
                  <input
                    id="inputUploadProfileNew"
                    className="FileInput"
                    style={{ display: "none" }}
                    type="file"
                    accept="image/jpeg,image/png,image/jpg,application/pdf"
                    onChange={uploadImage}
                  />
                  <div className='upload-icon mt-lg'
                    onClick={() => openFileDialog('inputUploadProfileNew')}
                    style={{ cursor: "pointer" }}
                  />
                </center>
                <div className='candupl-box-outline-headertext'>Profile Picture</div>
              </div>
          } 
            <div id="profilePicError" className='e-input-group e-float-icon-left error-div'/>
            </div>
        </div>
    )
}
