import * as React from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
    TextBoxComponent,
    // NumericTextBoxComponent,
} from "@syncfusion/ej2-react-inputs";
import "../../preoffer/preoffer.css";
import { faUser, faIdCard } from "@fortawesome/pro-light-svg-icons";
import {
    ComboBoxComponent,
    AutoCompleteComponent,
} from "@syncfusion/ej2-react-dropdowns";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from "@material-ui/pickers";
import { Dialog, Button } from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ErrorBoundary from "../../error/errorboundary";
import SeparationService from '../../../services/entryexit/separationServices';
import EmployeeCheckStatus from './employeecheckstatus';
import DateFnsUtils from "@date-io/date-fns";
import { connect } from "react-redux";
import { Query } from "@syncfusion/ej2-data";
import TextField from "@material-ui/core/TextField";
import { FormValidator } from "@syncfusion/ej2-inputs";
import { updateOrgChartDetailsAction, setOrhChartDetaila } from "../../../redux/actions/entryexit/orgchartaction";
import dataService from "../../../services/services";
import { updateHelpDeskUserInfo, createHelpDeskUser } from "../../../redux/actions/helpdesk/helpdeskaction";
import OnbordingToBeOffer from "../../../services/entryexit/onbordingServices";
import { urlConfig, corsallow, maxFileSize } from "../../../config/config";
import { getNotify, getMySqlDateFormat,encryptMethod } from "../../../app/other/commonfunction";
import { syncUser, saveToEnterpriseProfile } from "../../../redux/actions/entryexit/onboardingaction";
import { syncUserLocal } from "../../../app/other/syncuser";
import ProgressBar from "../../progressbar/progressbar";
import { getOnboardingMasterData } from '../../../app/other/commonlayoutfunction';
import { updateCandidateData } from '../../../redux/actions/entryexit/candidateaction'

import {
    ERROR,
    pleaseEnterFirstName,
    pleaseEnterLastName,
    pleaseEnterValidEmailId,
    pleaseEnterJobTitle,
    pleaseSelectEmployeeType,
    pleaseSelectBaseCountry,
    pleaseSelectSalutation,
    FileType,
    employeeStatusData
} from "../../../assets/constants/constants";
import {
    DialogContent,
    DialogActions,
    DialogTitleTMLC,
} from "../../customdialog/customdialog";

library.add(faUser, faIdCard);

class EmployeeEditPopup extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            imageName: "",
            imagePath: "",
            imageInfo: "",
            othersCheckAvailablity: [],
            baseCountryChangeFlag: false,
            persCountryCode: "",
            altCountryCode: "",
            officialCountryCode: "",
            empType: [],
            BusinessUnit: [],
            SubPnlName: [],
            practiceArea: [],
            LayerName: [],
            almRoles: [],
            SubLayerName: [],
            titleRole: "",
            BaseCountryId: null,
            BaseLocation: "",
            JoiningDate: "",
            ReportingSupervisorData: [],
            FirstName: "",
            MiddleName: "",
            LastName: "",
            PersonalMobileNo: "",
            AltMobileNo: "",
            PersonalEmail: "",
            AddressLine1: "",
            AddressLine2: "",
            PinCode: "",
            City: "",
            LegalName: "",
            State: "",
            SalutationState: "",
            Salutation: null,
            BusinessUnitId: null,
            PracticeAreaId: null,
            SubLayerId: null,
            LayerId: null,
            employmentTypeId: null,
            SubPnlId: null,
            FunctionalReportingSupervisorId: null,
            AdministrativeReportingSupervisorId: null,
            ISDCodePersonal: '',
            ISDCodeAlternative: "",
            employeeId: null,
            leftDate: null,
            employeeStatus: '1',
            officialEmail: null,
            addressCountryId: null,
            showLoader: true,
            empCode: "",
            gender: null,
            dateofBirth: "",
            recruitmentManagerId: null,
            highestEducationQualification: null,
            highestEducationQualificationSpecial: "",
            sourceCategory: null,
            workExperience: "",
            adharNumber: "",
            panNumber: "",
            other: "",
            tenureEndDate: null,
            jobPortalId: null,
            referralId: null,
            almId: null,
            agencyName: "",
            organisationDetails: true,
            personalDetails: false,
            otherDetails: false,
            currentTab: "OrganisationDetails",
            currencyId: null,
            ctcFrequency: null,
            windowLogin: "",
            digitalEmail: null,
            teamsEmail: null,
            offeredDate: "",
            offerAcceptanceDate: "",
            beforeLeftPopup: false,
            checkStatusEmployeeData: [],
            noticePeriod: "",
            holidayCountryId: null,
        };
        this.jobTitle = {};
    }

    componentDidMount() {
        const {
            titleRole,
            BaseCountryId,
            BaseLocation,
            JoiningDate,
            FirstName,
            MiddleName,
            LastName,
            PersonalMobileNo,
            AltMobileNo,
            AddressLine1,
            AddressLine2,
            PinCode,
            City,
            LegalName,
            State,
            SalutationId,
            BusinessUnitId,
            PracticeAreaId,
            SubLayerId,
            LayerId,
            employmentTypeId,
            SubPnlId,
            FunctionalReportingSupervisorId,
            AdministrativeReportingSupervisorId,
            ISDCodePersonal,
            ISDCodeAlternative,
            photoNm,
            EmployeeId,
            PersonalEmail,
            LeftDate,
            EmailAddress,
            AddressCountryId,
            holidayCountryId,
            ISDCodeOfficial,
            EmpCode,
            GenderId,
            DateofBirth,
            RecruitmentManagerId,
            HighestEducationQualification,
            Specialization,
            SourceCategoryId,
            WorkExperience,
            AadharCard,
            PanCard,
            OtherIdentification,
            TenureEndDate,
            JobPortalId,
            ReferralId,
            ALMId,
            Agency,
            CurrencyId,
            CTCFrequency,
            WindowLogin,
            digitalEmail,
            teamsEmail,
            OfferedDate,
            OfferAcceptanceDate,
            NoticePeriod
        } = this.props.employeeUpdateData        
        this.setState({
            titleRole,
            BaseCountryId: parseInt(BaseCountryId),
            BaseLocation,
            JoiningDate,
            FirstName,
            MiddleName: MiddleName,
            LastName,
            PersonalMobileNo,
            AltMobileNo,
            PersonalEmail: PersonalEmail,
            AddressLine1,
            AddressLine2,
            PinCode,
            City,
            LegalName,
            State,
            Salutation: SalutationId,
            BusinessUnitId: BusinessUnitId,
            employmentTypeId: employmentTypeId,
            SubPnlId: parseInt(SubPnlId),
            PracticeAreaId: typeof PracticeAreaId === "string" ? parseInt(PracticeAreaId) : PracticeAreaId,
            FunctionalReportingSupervisorId: FunctionalReportingSupervisorId,
            AdministrativeReportingSupervisorId: AdministrativeReportingSupervisorId,
            SubLayerId: typeof SubLayerId === "string" ? parseInt(SubLayerId) : SubLayerId,
            LayerId: LayerId,
            ISDCodePersonal: ISDCodePersonal,
            ISDCodeAlternative: ISDCodeAlternative,
            imageName: photoNm,
            employeeId: EmployeeId,
            leftDate: LeftDate,
            officialEmail: EmailAddress,
            addressCountryId: AddressCountryId,
            holidayCountryId: holidayCountryId,
            officialCountryCode: ISDCodeOfficial,
            empCode: EmpCode,
            gender: GenderId,
            dateofBirth: DateofBirth,
            recruitmentManagerId: RecruitmentManagerId,
            highestEducationQualification: HighestEducationQualification,
            highestEducationQualificationSpecial: Specialization,
            sourceCategory: SourceCategoryId,
            workExperience: WorkExperience,
            adharNumber: AadharCard,
            panNumber: PanCard,
            other: OtherIdentification,
            tenureEndDate: TenureEndDate,
            jobPortalId: JobPortalId,
            referralId: ReferralId,
            almId: ALMId,
            agencyName: Agency,
            currencyId: CurrencyId,
            ctcFrequency: CTCFrequency,
            windowLogin: WindowLogin,
            digitalEmail: digitalEmail,
            teamsEmail: teamsEmail,
            offeredDate: OfferedDate,
            offerAcceptanceDate: OfferAcceptanceDate,
            noticePeriod: NoticePeriod
        })

        this.getMasterDropdownData('businesspnl', this.props.employeeUpdateData.BusinessUnitId, true);
        this.getMasterDropdownData('subbusinesspnl', this.props.employeeUpdateData.BusinessUnitId, true);
        this.getMasterDropdownData('practicearea', this.props.employeeUpdateData.SubPnlId, true);
        this.getMasterDropdownData('layer', this.props.employeeUpdateData.PracticeAreaId, true);
        this.getMasterDropdownData('sublayer', this.props.employeeUpdateData.LayerMappingId, true);
        this.getMasterDropdownData('ALMRole', 0, true);
        this.getSupervisorData(this.props.employeeUpdateData.PracticeAreaId, true)

        this.openTab(null, "OrganisationDetails");

        setTimeout(() => {
            try {
                if (typeof this.jobTitle !== 'undefined') {
                    this.jobTitle.text = titleRole;
                }
            } catch (err) {
                console.log(err)
            }
        }, 1000);
        setTimeout(() => {
            this.setState({
                showLoader: false
            })
        }, 2000);
    }
    getOnboardingDataAndUpdateCandidate = async () => {
        const { authUser } = this.props;
        try {
            const onboardingMasterData = await getOnboardingMasterData(authUser, 'All');
            this.props.updateCandidateData(onboardingMasterData);
        } catch (error) {
            console.error("Error fetching onboarding master data:", error);
        }
    }

    getMasterData = (employeeId) => {
        if (employeeId > 0) {
            const { authUser } = this.props;
            const getMasterData = Promise.resolve(
                OnbordingToBeOffer.getMasterData("EmpDRP", authUser, employeeId)
            );
            getMasterData.then((resultData) => {
                if (resultData !== null) {
                    const subLayerData = resultData.filter((item) => { return item.Type === "SubLayer"; });
                    this.setState({
                        SubLayerName: subLayerData,
                    })
                }
            });
        }
    }
    editBasicInFormation = (e, type) => { // NOSONAR
        let options = {};
        switch (type) {
            case "FirstName":
                if (e.value !== "") {
                    let firstName = e.value.toLowerCase().split(" ");
                    for (let i = 0; i < firstName.length; i++) {
                        firstName[i] = firstName[i][0].toUpperCase() + firstName[i].slice(1);
                    }
                    firstName = firstName.join(" ");
                    this.setState({ FirstName: firstName });
                } else {
                    this.setState({ FirstName: e.value });
                }
                options = {
                    rules: {
                        firstNameErr: {
                            required: [true, pleaseEnterFirstName],
                            regex: ["^[A-z]+$", "Please enter valid name"],
                        },
                    },
                };
                break;
            case "MiddleName":
                if (e.value !== "") {
                    let middleName = e.value.toLowerCase().split(" ");
                    for (let i = 0; i < middleName.length; i++) {
                        middleName[i] =
                            middleName[i][0].toUpperCase() + middleName[i].slice(1);
                    }
                    middleName = middleName.join(" ");
                    this.setState({ MiddleName: middleName });
                } else {
                    this.setState({ MiddleName: e.value });
                }
                options = {
                    rules: {
                        middlenameErr: {
                            regex: ["^[A-z]+$", "Please enter valid middle name"],
                        },
                    },
                };
                break;
            case "LastName":
                if (e.value !== "") {
                    let lastName = e.value.toLowerCase().split(" ");
                    for (let i = 0; i < lastName.length; i++) {
                        lastName[i] = lastName[i][0].toUpperCase() + lastName[i].slice(1);
                    }
                    lastName = lastName.join(" ");
                    this.setState({ LastName: lastName });
                } else {
                    this.setState({ LastName: e.value });
                }
                options = {
                    rules: {
                        lastNameErr: {
                            required: [true, pleaseEnterLastName],
                            regex: ["^[A-z]+$", "Please enter valid last name"],
                        },
                    },
                };
                break;
            case "Salutation":
                if (e.itemData !== null) {
                    this.setState({ Salutation: e.itemData.Id });
                } else {
                    this.setState({ Salutation: e.itemData });
                }
                options = {
                    rules: {
                        salutationErr: {
                            required: [true, pleaseSelectSalutation],
                        },
                    },
                };
                break;
            case "Gender":
                if (e.itemData !== null) {
                    this.setState({ gender: e.itemData.Id });
                } else {
                    this.setState({ gender: e.itemData });
                }
                options = {
                    rules: {
                        genderErr: {
                            required: [true, "Please select gender"],
                        },
                    },
                };
                break;
            case "MobileNumber":
                this.setState({ PersonalMobileNo: e.value });
                options = {
                    rules: {
                        mobilenumberErr: {
                            // regex: ["^([0|\+[0-9]{1,5})?([7-9][0-9]{9})$", "Please enter valid mobile number"],
                            number: [true, "Please enter valid mobile number"],
                            // minLength: [10, "Please enter valid mobile number"],
                            // maxLength: [10, "Please enter valid mobile number"],
                        },
                    },
                };
                break;
            case "AlternateMobile":
                this.setState({ AltMobileNo: e.value });
                options = {
                    rules: {
                        alternateNumberErr: {
                            // regex: ["^([0|\+[0-9]{1,5})?([7-9][0-9]{9})$", "Please enter valid alternate mobile number"],
                            number: [true, "Please enter valid mobile number"],
                        },
                    },
                };
                break;
            case "persCountryCode":
                this.setState({ ISDCodePersonal: e.value });
                break;
            case "altCountryCode":
                this.setState({ ISDCodeAlternative: e.value });
                break;
            case "offCountryCode":
                this.setState({ officialCountryCode: e.value });
                break;
            case "EmailID":
                this.setState({ PersonalEmail: e.value });
                options = {
                    rules: {
                        emailIdErr: {
                            regex: [
                                "^[a-zA-Z0-9_+&*-]+(?:\\." +
                                "[a-zA-Z0-9_+&*-]+)*@" +
                                "(?:[a-zA-Z0-9-]+\\.)+[a-z" +
                                "A-Z]{2,7}$",
                                pleaseEnterValidEmailId,
                            ],
                        },
                    },
                };
                break;
            case "Title":
                if (e.itemData !== null) {
                    this.setState({ titleRole: e.itemData.Name })
                    this.jobTitle.text = e.itemData.Name
                }
                options = {
                    rules: {
                        titleErr: { required: [true, pleaseEnterJobTitle] },
                    },
                };
                break;
            case "BaseCountry":
                this.setState({ baseCountryChangeFlag: true });
                this.setState({

                    BaseCountryId: e.itemData.Id,
                    City: "",
                    State: "",
                    PinCode: "",
                });
                options = {
                    rules: {
                        baseCountryErr: { required: [true, pleaseSelectBaseCountry] },
                    },
                };
                break;
            case "BaseLocation":
                this.setState({ BaseLocation: e.value });
                break;
            case "Entity":
                options = {
                    rules: {
                        businessUnitErr: { required: [true, "Please select Business Unit / Group Function  name"] },
                    },
                };
                if (e.itemData !== null) {
                    this.setState({
                        BusinessUnitId: e.itemData.Id,
                        SubPnlId: null,
                        PracticeAreaId: null,
                        LayerId: null,
                        SubLayerId: null,
                        SubPnlName: [],
                        practiceArea: [],
                        LayerName: [],
                        SubLayerName: []
                    });
                    this.getMasterDropdownData('subbusinesspnl', e.itemData.Id, true);
                } else {
                    this.setState({ BusinessUnitId: e.itemData });
                }
                break;
            case "SubPnL":
                options = {
                    rules: {
                        subPnLErr: { required: [true, "Please select sub BU / function"] },
                    },
                };
                if (e.itemData !== null) {
                    this.setState({
                        SubPnlId: e.itemData.Id,
                        PracticeAreaId: null,
                        LayerId: null,
                        SubLayerId: null,
                        practiceArea: [],
                        LayerName: [],
                        SubLayerName: []
                    });
                    this.getMasterDropdownData('practicearea', e.itemData.Id, true);
                } else {
                    this.setState({ SubPnlId: null });
                }
                break;
            case "administrativeReportingSupervisor":
                if (e.itemData !== null) {

                    this.setState({
                        AdministrativeReportingSupervisorId: e.itemData.UserId,
                    });
                } else {
                    this.setState({
                        AdministrativeReportingSupervisorId: e.itemData,
                    });
                }
                options = {
                    rules: {
                        adminReportingToErr: {
                            required: [true, "Please select administrative reporting supervisor"],
                        },
                    },
                };
                break;
            case "functionalReportingSupervisor":
                if (e.itemData !== null) {
                    this.setState({
                        FunctionalReportingSupervisorId: e.itemData.UserId,
                    });
                } else {
                    this.setState({
                        FunctionalReportingSupervisorId: e.itemData,
                    });
                }
                options = {
                    rules: {
                        reportingToErr: {
                            required: [true, "Please select functional reporting supervisor"],
                        },
                    },
                };
                break;
            case "Address1":
                this.setState({ AddressLine1: e.value });
                break;
            default:
        }
        const { employmentTypeId } = this.state;

        switch (type) { // NOSONAR

            case "empType":

                if (e.itemData !== null) {
                    this.setState({ employmentTypeId: e.itemData.Id });
                } else {
                    this.setState({ empType: "" });

                }
                options = {
                    rules: {
                        empTypeErr: { required: [true, pleaseSelectEmployeeType] },
                    },
                };
                break;
            case "Address2":
                this.setState({ AddressLine2: e.value });
                break;
            case "state":
                this.setState({ State: e.value });
                break;
            case "city":
                this.setState({ City: e.value });
                break;
            case "AadhaarNumber":
                this.setState({ adharNumber: e.value });
                break;
            case "PANNumber":
                this.setState({ panNumber: e.value });
                break;
            case "Other":
                this.setState({ other: e.value });
                break;
            case "pincode":
                this.setState({ PinCode: e.value });
                break;
            case "JoinDate":
                document.getElementById("joinDateErr").innerHTML = "";
                this.setState({ JoiningDate: e });
                break;
            case "OfferedDate":
                document.getElementById("OfferedDateErr").innerHTML = "";
                this.setState({ offeredDate: e });
                break;
            case "OfferAcceptanceDate":
                document.getElementById("OfferAcceptanceDateErr").innerHTML = "";
                this.setState({ offerAcceptanceDate: e });
                break;
            case "TenureEndDate":
                if (employmentTypeId === 677 || employmentTypeId === 747 || employmentTypeId === 799 || employmentTypeId === 798 || employmentTypeId === 748 || employmentTypeId === 749) {
                    document.getElementById("tenureEndDateErr").innerHTML = "";
                    this.setState({ tenureEndDate: e });
                } else {
                    document.getElementById("tenureEndDateErr").innerHTML = "";
                    this.setState({ tenureEndDate: null });
                }
                break;
            case "DateofBirth":
                document.getElementById("dateofbirthErr").innerHTML = "";
                this.setState({ dateofBirth: e });
                break;
            case "LegalName":
                if (e.value !== "") {
                    this.setState({ LegalName: e.value });
                }
                options = {
                    rules: {
                        legalNameErr: {
                            required: [true, "Please enter legal name"],
                            regex: ["^[A-z ]+$", "Please enter legal name"],
                        },
                    },
                };
                break;
            case "practiceArea":
                options = {
                    rules: {
                        practiceErr: {
                            required: [true, "Please select practice area / sales unit / sub function"],
                        },
                    },
                };
                if (e.itemData !== null) {
                    this.setState({
                        PracticeAreaId: e.itemData.Id,
                        LayerId: null,
                        LayerName: [],
                        SubLayerId: null,
                        SubLayerName: [],
                    });
                    this.getSupervisorData(e.itemData.Id)
                    this.getMasterDropdownData('layer', e.itemData.Id, true);
                } else {
                    this.setState({ PracticeAreaId: e.itemData });
                }
                break;
            case "layer":
                options = {
                    rules: {
                        layerErr: {
                            required: [true, "Please select layer"],
                        },
                    },
                };
                if (e.itemData !== null) {
                    this.setState({ LayerId: e.itemData.Id, SubLayerId: null });
                    this.getMasterDropdownData('sublayer', e.itemData.Optional1, true);
                } else {
                    this.setState({ LayerId: e.itemData });
                }
                break;
            case "sublayer":
                if (e.itemData !== null) {
                    this.setState({
                        SubLayerId: e.itemData.Id,
                    });
                } else {
                    this.setState({
                        SubLayerId: e.itemData,
                    })
                }
                options = {
                    rules: {
                        subLayerErr: {
                            required: [true, "Please select sub layer"],
                        },
                    },
                };
                break;
            case "employeeStatus":
                if (e.itemData !== null) {
                    this.setState({ employeeStatus: e.itemData.Value });
                } else {
                    this.setState({ employeeStatus: e.itemData });
                }
                options = {
                    rules: {
                        employeeStatusErr: {
                            required: [true, "Please select employee Status"],
                        },
                    },
                };
                break;
            case "officialEmail":
                this.setState({ officialEmail: e.value });
                options = {
                    rules: {
                        officialEmailErr: {
                            required: [true, "Please enter official email id"],
                        },
                    },
                };
                break;
            case "addressCountry":
                if (e.itemData !== null) {
                    this.setState({ addressCountryId: e.itemData.Id });
                } else {
                    this.setState({ addressCountryId: e.itemData });
                }
                options = {
                    rules: {
                        addressCountryErr: {
                            required: [true, "Please select the address country"],
                        },
                    },
                };
                break;
            case "holidayCountry":
                if (e.itemData !== null) {
                    this.setState({ holidayCountryId: e.itemData.Id });
                } else {
                    this.setState({ holidayCountryId: e.itemData });
                }
                options = {
                    rules: {
                        holidayCountryErr: {
                            required: [true, "Please select the holiday country"],
                        },
                    },
                };
                break;
            case "Recruiter":
                if (e.itemData !== null) {
                    this.setState({ recruitmentManagerId: e.itemData.Id });
                } else {
                    this.setState({ recruitmentManagerId: e.itemData });
                }
                break;
            case "SourceCategory":
                this.setState({ sourceCategory: e.itemData.Id });
                break;
            case "jobPortal":
                if (e.itemData !== null) {
                    this.setState({ jobPortalId: e.itemData.Id });
                } else {
                    this.setState({ jobPortalId: e.itemData });
                }
                break;
            case "referral":
                if (e.itemData !== null) {
                    this.setState({ referralId: e.itemData.UserId });
                } else {
                    this.setState({ referralId: e.itemData });
                }
                break;
            case "almId":
                if (e.itemData !== null) {
                    this.setState({ almId: e.itemData.Id });
                } else {
                    this.setState({ almId: e.itemData });
                };
                break;
            case "agencyName":
                this.setState({ agencyName: e.value });
                break;
            case "HighestQualification":
                if (e.itemData !== null) {
                    this.setState({ highestEducationQualification: e.itemData.Id });
                } else {
                    this.setState({ highestEducationQualification: e.itemData });
                }
                break;
            case "currency":
                if (e.itemData !== null) {
                    this.setState({ currencyId: e.itemData.Id });
                } else {
                    this.setState({ currencyId: e.itemData });
                }
                break;
            case "ctcFrequency":
                if (e.itemData !== null) {
                    this.setState({ ctcFrequency: e.itemData.Id });
                } else {
                    this.setState({ ctcFrequency: e.itemData });
                }
                break;
            case "HighestEducationalSpecialization":
                this.setState({ highestEducationQualificationSpecial: e.value });
                break;
            case "TotalWorkExperience":
                this.setState({ workExperience: e.value });
                break;
            case "leftDate":
                this.setState({ leftDate: e });
                break;
            case "TeamMember":
                if (e.value !== "") {
                    let employeeCode = e.value.toUpperCase();
                    this.setState({ empCode: employeeCode });
                } else {
                    this.setState({ empCode: e.value });
                }
                options = {
                    rules: {
                        teamMemberErr: {
                            required: [true, "Please enter team member id"],
                        },
                    },
                };
                break;
            case "windowLogin":
                this.setState({ windowLogin: e.value });
                options = {
                    rules: {
                        windowLoginErr: {
                            required: [true, "Please enter window login id"],
                        },
                    },
                };
                break;
            case "digitalEmail":
                this.setState({ digitalEmail: e.value });

                break;
            case "teamsEmail":
                this.setState({ teamsEmail: e.value });

                break;
            case "noticePeriod":
                this.setState({ noticePeriod: e.value });
                break;
            default:
        }
        setTimeout(() => {
            const addFormObject = new FormValidator("#editCandidateFormInfo", options);
            addFormObject.validate();
        }, 100);
    };

    TextFieldComponent = (props) => {
        return <TextField {...props} color="primary" disabled />;
    };

    onFiltering = (e) => {
        if (e.text === "") {
            e.updateData(this.searchData);
        } else {
            let query = new Query();
            query =
                e.text !== ""
                    ? query.where("nameWithDepartment", "contains", e.text, true)
                    : query;
            e.updateData(this.state.othersCheckAvailablity, query);
        }
    };

    uploadImage = (e) => {
        const currentDate = Date.parse(new Date()) / 1000;
        const imageName = e.target.files[0];
        const imgType = imageName.type.replace("image/", ".");
        const size = e.target.files[0].size / 1024;
        if (imageName !== undefined && size < maxFileSize) {
            if (imageName.type !== "image/png" && imageName.type !== "image/jpeg") {
                getNotify(ERROR, FileType);
                return;
            }
        } else {
            getNotify(ERROR, "Please select png or jpeg image (less than 10MB) ");
            return;
        }
        return new Promise((resolve, reject) => {
            const req = new XMLHttpRequest();
            try {
                const formData = new FormData();
                formData.append("usertype", "E");
                formData.append("type", "P");
                formData.append("file", imageName, `${currentDate}${imgType}`);
                req.open("POST", `${corsallow}${urlConfig.tmlcApiUrl}document/upl`);
                req.setRequestHeader("Authorization", localStorage.getItem("jwtToken"));
                req.send(formData);

                if (e.target.files.length) {
                    this.setState({
                        imagePath: URL.createObjectURL(e.target.files[0]),
                        imageInfo: e.target.files[0],
                    });
                } else {

                    this.setState({
                        imagePath: URL.createObjectURL(e.target.files[0]),
                        imageInfo: e.target.files[0],
                    });
                    return;
                }
                this.setState({ imageName: `${currentDate}${imgType}` });
            } catch (error) {
                console.log("Image Upload", error);
            }
        });
    };

    getSupervisorData = (PracticeAreaId) => {
        const { authUser } = this.props;
        try {//NOSONAR
            const getSalesUnitData = Promise.resolve(
                OnbordingToBeOffer.getMasterData(
                    'SDSU',
                    authUser,
                    PracticeAreaId
                )
            );
            getSalesUnitData.then((resultData) => { //NOSONAR
                if (resultData !== null) {
                    this.setState({
                        ReportingSupervisorData: resultData,
                    });
                }
            });
        } catch (error) {
            console.log(error);
        }
    }




    getMasterDropdownData = (type, id, isEncrypted) => {
        try {//NOSONAR
            const masterDopdownData = Promise.resolve(
                OnbordingToBeOffer.getOrgchartMasterDropdownData(encryptMethod(type), encryptMethod(id.toString()), isEncrypted)
            );

            masterDopdownData.then((resultData) => {
                if (type === 'businesspnl') {
                    this.setState({ BusinessUnit: resultData })
                }
                if (type === 'subbusinesspnl') {
                    this.setState({ SubPnlName: resultData })
                }
                if (type === 'practicearea') {
                    this.setState({ practiceArea: resultData })
                }
                if (type === 'layer') {
                    this.setState({ LayerName: resultData })
                }
                if (type === 'sublayer') {
                    this.setState({ SubLayerName: resultData })
                }
                if (type === 'ALMRole') {
                    this.setState({ almRoles: resultData })
                }
            });
        } catch (err) {
            console.log("OKRRatingServices", err)
        }
    }

    onSave = () => { //NOSONAR
        const { imageName, officialCountryCode, titleRole, BaseCountryId, BaseLocation, JoiningDate, FirstName, MiddleName, LastName, PersonalMobileNo, AltMobileNo, PersonalEmail, AddressLine1, referralId, agencyName, currencyId, almId, ctcFrequency, windowLogin, offeredDate, offerAcceptanceDate,
            AddressLine2, PinCode, City, LegalName, State, Salutation, BusinessUnitId, PracticeAreaId, SubLayerId, LayerId, employmentTypeId, SubPnlId, FunctionalReportingSupervisorId, AdministrativeReportingSupervisorId, adharNumber, panNumber, other, tenureEndDate, jobPortalId,
            ISDCodePersonal, ISDCodeAlternative, employeeId, leftDate, addressCountryId, officialEmail, digitalEmail, teamsEmail, employeeStatus, empCode, gender, dateofBirth, recruitmentManagerId, highestEducationQualification, highestEducationQualificationSpecial, sourceCategory, workExperience, noticePeriod, holidayCountryId } = this.state;
        const { authUser } = this.props;
        const options = {
            rules: {
                firstNameErr: {
                    required: [true, "Please enter first name"],
                    regex: ["^[A-z]+$", "Please enter valid name"],
                },
                lastNameErr: {
                    required: [true, "Please enter last name"],
                    regex: ["^[A-z]+$", "Please enter valid last name"],
                },
                legalNameErr: {
                    required: [true, "Please enter legal name"],
                    regex: ["^[A-z ]+$", "Please enter valid legal name"],
                },
                titleErr: {
                    required: [true, "Please enter title/role"],
                },
                businessUnitErr: {
                    required: [true, "Please select business unit"],
                },
                subPnLErr: {
                    required: [true, "Please select sub BU / function"]
                },
                practiceErr: {
                    required: [true, "Please select practice area / sales unit / sub function"],
                },
                layerErr: {
                    required: [true, "Please select layer"],
                },
                subLayerErr: {
                    required: [true, "Please select sub layer"],
                },
                reportingToErr: {
                    required: [true, "Please select functional supervisor"],
                },
                salutationErr: {
                    required: [true, pleaseSelectSalutation],
                },
                genderErr: {
                    required: [true, "Please select gender"],
                },
                emailIdErr: {
                    // required: [true, "Please enter email id"],
                    regex: [
                        "^[a-zA-Z0-9_+&*-]+(?:\\." +
                        "[a-zA-Z0-9_+&*-]+)*@" +
                        "(?:[a-zA-Z0-9-]+\\.)+[a-z" +
                        "A-Z]{2,7}$",
                        "Please enter valid email id",
                    ],
                },
                officialEmailErr: {
                    required: [true, "Please enter official email id"],
                    regex: [
                        "^[a-zA-Z0-9_+&*-]+(?:\\." +
                        "[a-zA-Z0-9_+&*-]+)*@" +
                        "(?:[a-zA-Z0-9-]+\\.)+[a-z" +
                        "A-Z]{2,7}$",
                        "Please enter valid email id",
                    ],
                },
                addressCountryErr: {
                    required: [true, "Please select the address country"],
                },
                employeeStatusErr: { // NOSONAR
                    required: [true, "Please select employee Status"],
                },
                adminReportingToErr: {
                    required: [true, "Please select administrative reporting supervisor"],
                },
                baseCountryErr: { required: [true, pleaseSelectBaseCountry] },
                teamMemberErr: {
                    required: [true, "Please enter team member id"],
                },
                windowLoginErr: {
                    required: [true, "Please enter window login id"],
                },
                alternateNumberErr: {
                    // eslint-disable-next-line
                    // regex: ["^([0|\+[0-9]{1,5})?([7-9][0-9]{9})$", "Please enter valid alternate mobile number"],
                    number: [true, "Please enter valid mobile number"],
                    // minLength: [10, "Please enter valid s
                },
                holidayCountryErr: {
                    required: [true, "Please select the holiday country"],
                },
                // empTypeErr: {
                //     required: [true, "Please select employment type"],
                // },
                //   pincodeErr: {
                //     required: [true, "Please enter pin code"],
                // },
                // currencyErr: {
                //     required: [true, pleaseSelectCurrency],
                // },
                // ctcFrequencyErr: {
                //     required: [true, pleaseSelectCTCFrequency],
                // },
                // digitalEmailErr: {
                //     required: [true, "Please enter digital email id"],
                //     regex: [
                //         "^[a-zA-Z0-9_+&*-]+(?:\\." +
                //         "[a-zA-Z0-9_+&*-]+)*@" +
                //         "(?:[a-zA-Z0-9-]+\\.)+[a-z" +
                //         "A-Z]{2,7}$",
                //         "Please enter valid email id",
                //     ],
                // },
                // teamsEmailErr: {
                //     required: [true, "Please enter digital email id"],
                //     regex: [
                //         "^[a-zA-Z0-9_+&*-]+(?:\\." +
                //         "[a-zA-Z0-9_+&*-]+)*@" +
                //         "(?:[a-zA-Z0-9-]+\\.)+[a-z" +
                //         "A-Z]{2,7}$",
                //         "Please enter valid email id",
                //     ],
                // },
            },
        };
        if (PersonalMobileNo !== '' && PersonalMobileNo !== null) {
            options.rules["personalISDErr"] = {
                required: [true, "Please enter a ISD code."],
            };
        }
        if (AltMobileNo !== '' && AltMobileNo !== null) {
            options.rules["altISDErr"] = {
                required: [true, "Please enter a ISD code."],
            };
        }
        if (employeeStatus === '0' && leftDate === null) {
            document.getElementById('leftDateErr').innerHTML = "Please enter a left date.";
            return;
        }
        if (this.state.employmentTypeId === 677 || this.state.employmentTypeId === 747 || this.state.employmentTypeId === 799 || this.state.employmentTypeId === 798) {
            if (tenureEndDate === "" || tenureEndDate === null) {
                document.getElementById("tenureEndDateErr").innerHTML = "Please select tenure end date";
            }
        }
        if ((employmentTypeId === 677 || employmentTypeId === 747 || employmentTypeId === 746 || employmentTypeId === 798) && addressCountryId === 101) {
            options.rules.aadhaarNumberErr = {
                required: [true, "Please enter Aadhaar number"],
                regex: [
                    "^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$",
                    "Please enter valid Aadhaar number",
                ],
            }
            options.rules.PANNumberErr = {
                required: [true, "Please enter PAN number"],
                regex: ["[A-Z]{5}[0-9]{4}[A-Z]{1}", "Please enter valid PAN number"],
                maxLength: [10, "Please enter valid PAN number"],
            }
        }
        let data = {
            imageName: imageName,
            isdCodeOfficial: officialCountryCode,
            titleRole: titleRole,
            country: BaseCountryId,
            location: BaseLocation,
            joiningDate: (JoiningDate === null || JoiningDate === "") ? null : getMySqlDateFormat(JoiningDate),  // getDate(new Date(JoiningDate)), getDateFormat(new Date(JoiningDate), new Date(JoiningDate)),
            firstName: FirstName,
            middleName: MiddleName,
            lastName: LastName,
            personalMobileNo: PersonalMobileNo,
            alternativeMobileNumber: AltMobileNo,
            emailId: PersonalEmail,
            address1: AddressLine1,
            address2: AddressLine2,
            pinCode: PinCode,
            city: City,
            legalName: LegalName,
            state: State,
            salutationId: Salutation,
            businessUnitId: BusinessUnitId,
            practiceAreaId: PracticeAreaId,
            subLayerId: SubLayerId,
            layerId: LayerId,
            employmentType: employmentTypeId,
            subPnlId: SubPnlId,
            reportingSupervisor: FunctionalReportingSupervisorId,
            administrativeSupervisor: AdministrativeReportingSupervisorId,
            isdCodePersonal: ISDCodePersonal,
            isdCodeAlternative: ISDCodeAlternative,
            employeeId: employeeId,
            userId: authUser,
            leftDate: (leftDate === null || leftDate === "") ? null : getMySqlDateFormat(leftDate),  // getDate(new Date(leftDate)),
            addressCountryId: addressCountryId,
            holidayCountryId: holidayCountryId,
            officialEmail: officialEmail,
            officialMobileNumber: null,
            employeeStatus: employeeStatus,
            empCode: empCode,
            genderId: gender,
            dateofBirth: (dateofBirth === null || dateofBirth === "") ? null : getMySqlDateFormat(dateofBirth), // getDate(new Date(dateofBirth)),
            recruitmentManagerId,
            highestEducationQualification,
            highestEducationQualificationSpecial,
            sourceCategory,
            workExperience,
            adharNumber,
            panNumber,
            other,
            tenureEndDate: (tenureEndDate === null || tenureEndDate === "") ? null : getMySqlDateFormat(tenureEndDate), // getDate(new Date(tenureEndDate)),
            jobPortalId,
            referralId,
            agencyName,
            currencyId,
            almId: almId,
            ctcFrequency,
            windowLogin,
            digitalEmail,
            teamsEmail,
            offeredDate: (offeredDate === null || offeredDate === "") ? null : getMySqlDateFormat(offeredDate),
            offerAcceptanceDate: (offerAcceptanceDate === null || offerAcceptanceDate === "") ? null : getMySqlDateFormat(offerAcceptanceDate),
            noticePeriod
        }
        setTimeout(async () => {
            if (data.employeeStatus === "0") {
                const param = {
                    authUser: authUser,
                    type: "CheckStatus",
                    empId: employeeId
                }
                const checkStatusData = Promise.resolve(
                    SeparationService.getActiveLeftEmpList(param)
                );

                checkStatusData.then(async (result) => {
                    if (result) {
                        if (result.result.length > 0) {
                            this.setState({ checkStatusEmployeeData: result.result })
                            this.setState({ beforeLeftPopup: true });
                        } else {
                            const addFormObject = new FormValidator("#editCandidateFormInfo", options);
                            console.log("?? ~ EmployeeEditPopup ~ setTimeout ~ addFormObject:", addFormObject)
                            console.log("?? ~ EmployeeEditPopup ~ setTimeout ~ addFormObject.validate()", addFormObject.validate())
                            if (addFormObject && addFormObject.validate() === false) {
                                getNotify(ERROR, "Please fill all the required fields");
                            }
                            if (addFormObject && addFormObject.validate()) {
                                data.methodType = 'employeeedit'
                                const enterpriseData = {
                                    type: "GETPROFILE",
                                    userId: authUser,
                                    empId: employeeId,
                                }
                                this.props.saveToEnterpriseProfile(enterpriseData);

                                this.props.updateOrgChartDetailsAction(data);
                            }
                        }
                    }
                })
            } else {
                const addFormObject = new FormValidator("#editCandidateFormInfo", options);
                if (addFormObject && addFormObject.validate() === false) {
                    getNotify(ERROR, "Please fill all the required fields");
                }
                if (addFormObject && addFormObject.validate()) {
                    data.methodType = 'employeeedit'
                    const enterpriseData = {
                        type: "GETPROFILE",
                        userId: authUser,
                        empId: employeeId,
                    }
                    this.props.saveToEnterpriseProfile(enterpriseData);

                    this.props.updateOrgChartDetailsAction(data);

                }
            }
        }, 100);

    }
    componentWillReceiveProps(newProps) {
        const { employeeId, } = this.state
        const { authUser } = this.props;
        if (newProps.teamMemberUpdate === "updated") {
            setTimeout(async () => {
                let empData = await syncUserLocal(employeeId, authUser, 0);
                await this.props.syncUser(empData);
                if (empData.hdUserId > 0) {
                    let data = {
                        userId: empData.hdUserId,
                        email: empData.user_email,
                        firstName: empData.user_first_name,
                        lastName: empData.user_last_name,
                        disabled: (empData.user_status === "active" || empData.user_status === "Active") ? false : true
                    }
                    await this.props.updateHelpDeskUserInfo(data);
                } else {
                    await this.createHelpDeskUserRequest(employeeId, authUser)
                }
            }, 100)
            this.props.getEmployeeData(employeeId);
            this.props.closeEditEmployeePopup();
            this.props.setOrhChartDetaila({ teamMemberUpdate: "" });
        }

    }

    createHelpDeskUserRequest = async (empId, authUser) => {
        let empDetail = await dataService.getEnployeeBasicInformation(empId, authUser);
        let empData = empDetail[0];
        let data = {
            username: empData.FirstName.concat(".", empData.LastName),
            password: empData.helpdeskUserPass,
            email: empData.EmailAddress,
            firstName: empData.FirstName,
            lastName: empData.LastName,
            company: empData.CompanyName,
            department: empData.Department,
            sendWelcomeEmail: false,
            userId: this.props.authUser,
            empId: empId
        };
        this.props.createHelpDeskUser(data);
    }

    openTab = (evt, tabName) => {
        let i;
        const tabfields = document.getElementsByClassName("tabfields");
        for (i = 0; i < tabfields.length; i++) {
            tabfields[i].style.display = "none";
        }
        this.setState({ currentTab: tabName });
        const editTablinks = document.getElementsByClassName("editTablinks");
        for (i = 0; i < editTablinks.length; i++) {
            editTablinks[i].className = editTablinks[i].className.replace(" active", "");
        }
        if (document.getElementById(tabName) !== null)
            document.getElementById(tabName).style.display = "block";
        if (evt !== null)
            evt.currentTarget.className += " active";
        else if (document.getElementById('tab' + tabName) !== null)
            document.getElementById('tab' + tabName).classList.add("active");
    };

    closeBeforeLeftPopup = () => {
        this.setState({ beforeLeftPopup: false });
    }

    render() { // NOSONAR
        const {
            imageName,
            imagePath,
            BusinessUnit,
            SubPnlName,
            practiceArea,
            LayerName,
            SubLayerName,
            almRoles,
            titleRole,
            BaseCountryId,
            BaseLocation,
            JoiningDate,
            ReportingSupervisorData,
            FirstName,
            MiddleName,
            LastName,
            PersonalMobileNo,
            AltMobileNo,
            PersonalEmail,
            AddressLine1,
            AddressLine2,
            PinCode,
            City,
            LegalName,
            State,
            Salutation,
            BusinessUnitId,
            PracticeAreaId,
            SubLayerId,
            LayerId,
            employmentTypeId,
            SubPnlId,
            FunctionalReportingSupervisorId,
            AdministrativeReportingSupervisorId, ISDCodePersonal,
            ISDCodeAlternative, leftDate, officialEmail, addressCountryId, employeeStatus, showLoader, holidayCountryId,
            empCode,
            gender,
            dateofBirth,
            recruitmentManagerId,
            highestEducationQualification,
            highestEducationQualificationSpecial,
            sourceCategory,
            workExperience,
            adharNumber,
            panNumber,
            other,
            tenureEndDate,
            jobPortalId,
            referralId,
            agencyName,
            currentTab,
            currencyId,
            ctcFrequency,
            windowLogin,
            digitalEmail,
            teamsEmail,
            offeredDate,
            offerAcceptanceDate,
            beforeLeftPopup,
            checkStatusEmployeeData,
            noticePeriod,
            almId
        } = this.state;
            console.log("🚀 ~ EmployeeEditPopup ~ render ~ BaseCountryId:", BaseCountryId)
        const { employeeTypeMaster, jobTitleData, salutationStateMaster, countryMaster, addressCountryMaster, othersCheckAvailablity, genderMaster, recruitementMaster, highestEducationQualifcationMaster, sourceCategoryData, jobPortalData, currencyMaster, holidayCountryData } = this.props;
        let imgUrl = "";
        if (imagePath === "" && imageName === "") {
            imgUrl = "../../app/containers/tmlc/onboarding/avatar-s-16.png";
        } else if (imagePath === "" && imageName !== "") {
            imgUrl = `${urlConfig.tmlcApiUrl}document/E/0/${imageName}`;
        } else {
            imgUrl = imagePath;
        }
        const ifPracticeAreaId = (typeof PracticeAreaId === "string") ? parseInt(PracticeAreaId) : PracticeAreaId;
        const isLeftDateDisabled = employeeStatus === '1' ? true : false;
        const emptyProfileImage = (
            <span
                style={{
                    height: "100%",
                    width: "100%",
                    top: "50px",
                    position: "absolute",
                    left: "20px",
                }}
                className="inline-block"
            >
                {" "}
                Upload Image{" "}
            </span>
        )
        const profileImageWithPicture = (
            <img
                style={{ height: "100%", width: "100%" }}
                className="inline-block"
                src={imgUrl}
                alt=""
            />
        )
        const uploadProfileImage = (
            <span
                style={{
                    position: "absolute",
                    top: "96px",
                    left: "54px",
                    color: "black",
                }}
                onChange={this.uploadImage}
            >
                Upload
            </span>
        )
        const editProfileImage = (
            <span
                style={{
                    position: "absolute",
                    top: "98px",
                    left: "64px",
                    color: "white",
                    background: "rgba(0, 0, 0, 0.26)",
                }}
                onChange={this.uploadImage}
            >
                Edit
            </span>
        )
        const fileUploadProfileImage = (imagePath === "" && imageName === "" ? emptyProfileImage : profileImageWithPicture);
        const updateEditProfileImage = (imagePath === "" && imageName === "" ? uploadProfileImage : editProfileImage);
        const practiceAreaHasLength = (<div>
            <ComboBoxComponent
                id="practiceArea"
                fields={{ text: "Name", value: "Id" }}
                data-msg-containerid="practiceErr"
                name="practiceErr"
                cssClass="e-outline"
                floatLabelType="Auto"
                value={ifPracticeAreaId}
                dataSource={practiceArea}
                select={(e) => this.editBasicInFormation(e, "practiceArea")}
                placeholder="Practice Area / Sales Unit / Sub Function *"
            />
            <div
                id="practiceErr"
                className="e-input-group e-float-icon-left error-div"
            />
        </div>)
        const practiceAreaHasNoLength = (<div>
            <ComboBoxComponent
                id="practiceArea"
                fields={{ text: "Name", value: "Id" }}
                data-msg-containerid="practiceErr"
                name="practiceErr"
                cssClass="e-outline"
                floatLabelType="Auto"
                placeholder="Practice Area / Sales Unit / Sub Function *"
            />
            <div
                id="practiceErr"
                className="e-input-group e-float-icon-left error-div"
            />
        </div>)
        const funRepManagerHasLength = (<ComboBoxComponent
            id="functionalReportingSupervisor"
            fields={{ text: "nameWithDepartment", value: "UserId" }}
            data-msg-containerid="reportingToErr"
            name="reportingToErr"
            value={FunctionalReportingSupervisorId}
            allowCustom={false}
            cssClass="e-outline"
            floatLabelType="Auto"
            dataSource={othersCheckAvailablity}
            filtering={this.onFiltering}
            placeholder="Functional Supervisor *"
            select={(e) =>
                this.editBasicInFormation(e, "functionalReportingSupervisor")
            }
        />);
        const funRepHasNoLength = (<ComboBoxComponent
            id="functionalReportingSupervisor"
            fields={{ text: "Name", value: "Id" }}
            data-msg-containerid="reportingToErr"
            name="reportingToErr"
            allowCustom={false}
            cssClass="e-outline"
            floatLabelType="Auto"
            placeholder="Functional Supervisor *"
        />)
        const adminRepHasLength = (<ComboBoxComponent
            id="administrativeReportingSupervisor"
            fields={{ text: "nameWithDepartment", value: "UserId" }}
            data-msg-containerid="adminReportingToErr"
            name="adminReportingToErr"
            value={AdministrativeReportingSupervisorId}
            allowCustom={false}
            cssClass="e-outline"
            floatLabelType="Auto"
            dataSource={othersCheckAvailablity}
            filtering={this.onFiltering}
            placeholder="Reporting Supervisor *"
            select={(e) =>
                this.editBasicInFormation(e, "administrativeReportingSupervisor")
            }
        />)
        const adminRepHasNoLength = (<ComboBoxComponent
            id="administrativeReportingSupervisor"
            fields={{ text: "Name", value: "Id" }}
            data-msg-containerid="adminReportingToErr"
            name="adminReportingToErr"
            allowCustom={false}
            cssClass="e-outline"
            floatLabelType="Auto"
            placeholder="Reporting Supervisor *"
        />)
        const ifPAHasLength = (practiceArea.length > 0 ? practiceAreaHasLength : practiceAreaHasNoLength);
        const ifFunRepHasLength = (ReportingSupervisorData !== null && ReportingSupervisorData.length > 1 ? funRepManagerHasLength : funRepHasNoLength);
        const ifAdminRepHasLength = (ReportingSupervisorData !== null && ReportingSupervisorData.length > 1 ? adminRepHasLength : adminRepHasNoLength);
        const isIndian = ((employmentTypeId === 677 || employmentTypeId === 747 || employmentTypeId === 746 || employmentTypeId === 798) && addressCountryId === 101);
        return (
            <div style={{ zIndex: 111 }}>
                {
                    showLoader ?
                        <>
                            <div className="control-section col-md-12" style={{ height: 230 }}>
                                <ProgressBar />
                            </div>
                        </>
                        :
                        <>
                            <DialogContent style={{ height: '410px' }}>
                                <div className="tab">
                                    <div className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <button
                                            id="tabOrganisationDetails"
                                            type="submit"
                                            className="editTablinks col-2 col-lg-2 col-md-2 col-sm-2 col-xs-2 active rating-tab-font"
                                            onClick={(e) => { this.openTab(e, "OrganisationDetails") }}
                                        >
                                            Organisation Details
                                        </button>
                                        <button
                                            id="tabPersonalDetails"
                                            type="submit"
                                            className="editTablinks col-2 col-lg-2 col-md-2 col-sm-2 col-xs-2 rating-tab-font"
                                            onClick={(e) => this.openTab(e, "PersonalDetails")}
                                        >
                                            Personal Details
                                        </button>
                                        <button
                                            id="tabOtherDetails"
                                            type="submit"
                                            className="editTablinks col-2 col-lg-2 col-md-2 col-sm-2 col-xs-2 rating-tab-font"
                                            onClick={(e) => this.openTab(e, "OtherDetails")}
                                        >
                                            Other Details
                                        </button>
                                    </div>
                                </div>
                                <form
                                    id="editCandidateFormInfo"
                                    method="post"
                                    className="control-section col-md-12"
                                >
                                    <div id="OrganisationDetails" className="tabfields" style={{ display: (currentTab === "OrganisationDetails" ? "block" : "none") }}>
                                        <div
                                            className="row card-layout"
                                            style={{
                                                paddingTop: "2.5em",
                                            }}
                                        >
                                            <div className="col-xs-1 col-sm-1 col-lg-4 col-md-2">
                                                <div
                                                    className="col-xs-2 col-sm-3 col-lg-4 col-md-4"
                                                    style={{ height: "50px", paddingLeft: 0, marginTop: "-1em" }}
                                                >
                                                    <div>
                                                        <div
                                                            className="col-lg-6"
                                                            name="imageErr"
                                                            data-msg-containerid="imageErr"
                                                        >
                                                            <div className="profile-img-wrap edit-img">
                                                                {fileUploadProfileImage}
                                                                <div className="fileupload btn">
                                                                    <input
                                                                        type="file"
                                                                        id="uploadImage"
                                                                        style={{ opacity: 0, cursor: "pointer" }}
                                                                        onChange={this.uploadImage}
                                                                    />
                                                                </div>
                                                            </div>
                                                            {updateEditProfileImage}
                                                        </div>
                                                        <div id="imageErr" className="error-div" />
                                                    </div>
                                                </div>
                                                <div
                                                    className="col-xs-2 col-sm-3 col-lg-8 col-md-8"
                                                    style={{ paddingRight: 0, paddingLeft: "2.5em" }}
                                                >{employeeTypeMaster.length > 0 &&
                                                    <div>

                                                        <ComboBoxComponent
                                                            id="empType"
                                                            dataSource={employeeTypeMaster}
                                                            fields={{ text: "Name", value: "Id" }}
                                                            value={employmentTypeId}
                                                            data-msg-containerid="empTypeErr"
                                                            name="empTypeErr"
                                                            floatLabelType="Auto"
                                                            cssClass="e-outline"
                                                            placeholder="Team Member Type *"
                                                            popupHeight="350px"
                                                            select={(e) => this.editBasicInFormation(e, "empType")}
                                                        />
                                                        <div
                                                            id="empTypeErr"
                                                            className="e-input-group e-float-icon-left error-div"
                                                        />
                                                    </div>}
                                                </div>
                                            </div>
                                            <div className="col-xs-1 col-sm-1 col-lg-4 col-md-2">
                                                <div>
                                                    <TextBoxComponent
                                                        data-msg-containerid="teamMemberErr"
                                                        id="TeamMemberId"
                                                        value={empCode}
                                                        name="teamMemberErr"
                                                        placeholder="Team Member ID *"
                                                        floatLabelType="Auto"
                                                        cssClass="e-outline"
                                                        blur={(e) => this.editBasicInFormation(e, "TeamMember")}
                                                        htmlAttributes={{ maxlength: 100 }}
                                                    />
                                                    <div id="teamMemberErr" className="error-div" />
                                                </div>
                                            </div>
                                            <div className="col-xs-1 col-sm-1 col-lg-4 col-md-2">
                                                {BusinessUnit.length > 0 &&
                                                    <div>
                                                        <ComboBoxComponent
                                                            id="Entity"
                                                            dataSource={BusinessUnit}
                                                            fields={{ text: "Name", value: "Id" }}
                                                            value={BusinessUnitId}
                                                            data-msg-containerid="businessUnitErr"
                                                            name="businessUnitErr"
                                                            floatLabelType="Auto"
                                                            cssClass="e-outline"
                                                            placeholder="Business Unit / Group Function  *"
                                                            select={(e) => this.editBasicInFormation(e, "Entity")}
                                                        />
                                                        <div
                                                            id="businessUnitErr"
                                                            className="e-input-group e-float-icon-left error-div"
                                                        />
                                                    </div>}
                                            </div>
                                        </div>
                                        <div
                                            className="row card-layout"
                                            style={{
                                                paddingTop: ".5em",
                                            }}
                                        >
                                            <div className="col-xs-1 col-sm-1 col-lg-4 col-md-2">
                                                <div className="col-xs-1 col-sm-1 col-lg-4 col-md-4" />
                                                <div className="col-xs-2 col-sm-2 col-lg-8 col-md-8"
                                                    style={{
                                                        paddingRight: 0,
                                                        paddingLeft: "2.5em"
                                                    }}
                                                >
                                                    <div>
                                                        <ComboBoxComponent
                                                            id="SubPnL"
                                                            fields={{ text: "Name", value: "Id" }}
                                                            data-msg-containerid="subPnLErr"
                                                            name="subPnLErr"
                                                            value={SubPnlId}
                                                            cssClass="e-outline"
                                                            floatLabelType="Auto"
                                                            dataSource={SubPnlName}
                                                            select={(e) => this.editBasicInFormation(e, "SubPnL")}
                                                            placeholder="Sub BU / Function *"
                                                        />
                                                        <div
                                                            id="subPnLErr"
                                                            className="e-input-group e-float-icon-left error-div"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xs-2 col-sm-2 col-lg-4 col-md-3 mr-2">
                                                {ifPAHasLength}
                                            </div>
                                            <div className="col-xs-1 col-sm-1 col-lg-4 col-md-2">
                                                <div>
                                                    <ComboBoxComponent
                                                        id="layer"
                                                        dataSource={LayerName}
                                                        fields={{ text: "Name", value: "Id" }}
                                                        value={LayerId}
                                                        data-msg-containerid="layerErr"
                                                        name="layerErr"
                                                        floatLabelType="Auto"
                                                        cssClass="e-outline"
                                                        placeholder="Layer *"
                                                        select={(e) => this.editBasicInFormation(e, "layer")}
                                                    />
                                                    <div
                                                        id="layerErr"
                                                        className="e-input-group e-float-icon-left error-div"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="row card-layout"
                                            style={{
                                                paddingTop: ".5em",
                                            }}
                                        >
                                            <div className="col-xs-2 col-sm-2 col-lg-4 col-md-3">
                                                <div>
                                                    <ComboBoxComponent
                                                        id="subLayer"
                                                        fields={{ text: "Name", value: "Id" }}
                                                        select={(e) => this.editBasicInFormation(e, "sublayer")}
                                                        placeholder="Sub Layer *"
                                                        popupHeight="220px"
                                                        cssClass="e-outline"
                                                        floatLabelType="Auto"
                                                        data-msg-containerid="subLayerErr"
                                                        name="subLayerErr"
                                                        value={SubLayerId}
                                                        dataSource={SubLayerName}
                                                    />
                                                    <div
                                                        id="subLayerErr"
                                                        className="e-input-group e-float-icon-left error-div"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-xs-2 col-sm-2 col-lg-4 col-md-3">
                                                <div>
                                                    <AutoCompleteComponent
                                                        fields={{ text: "Name", value: "Name" }}
                                                        id="Title"
                                                        ref={(autocomplete) => {
                                                            this.jobTitle = autocomplete;
                                                        }}
                                                        select={(e) => this.editBasicInFormation(e, "Title")}
                                                        placeholder="Role / Title *"
                                                        popupHeight="220px"
                                                        data-msg-containerid="titleErr"
                                                        name="titleErr"
                                                        cssClass="e-outline"
                                                        floatLabelType="Auto"
                                                        dataSource={jobTitleData}
                                                        value={titleRole}
                                                    />
                                                    <div
                                                        id="titleErr"
                                                        className="e-input-group e-float-icon-left error-div"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-xs-2 col-sm-2 col-lg-4 col-md-3">
                                                <div className="col-xs-1 col-sm-1 col-lg-6 col-md-6"
                                                    style={{
                                                        padding: 0,
                                                        paddingRight: 15
                                                    }}
                                                >
                                                    {countryMaster.length > 0 &&
                                                        <div>
                                                            <ComboBoxComponent
                                                                id="BaseCountry"
                                                                dataSource={countryMaster}
                                                                fields={{ text: "Name", value: "Id" }}
                                                                value={BaseCountryId}
                                                                data-msg-containerid="baseCountryErr"
                                                                name="baseCountryErr"
                                                                floatLabelType="Auto"
                                                                popupHeight="220px"
                                                                cssClass="e-outline"
                                                                placeholder="Country of Work *"
                                                                select={(e) => this.editBasicInFormation(e, "BaseCountry")}
                                                            />

                                                            <div
                                                                id="baseCountryErr"
                                                                className="e-input-group e-float-icon-left error-div"
                                                            />
                                                        </div>
                                                    }
                                                </div>
                                                <div className="col-xs-2 col-sm-2 col-lg-6 col-md-6"
                                                    style={{
                                                        padding: 0,
                                                        paddingLeft: 15
                                                    }}
                                                >
                                                    <div>
                                                        <TextBoxComponent
                                                            id="BaseLocation"
                                                            value={BaseLocation}
                                                            data-msg-containerid="baselocationErr"
                                                            name="baselocationErr"
                                                            floatLabelType="Auto"
                                                            placeholder="Enter Office Location"
                                                            cssClass="e-outline"
                                                            blur={(e) => this.editBasicInFormation(e, "BaseLocation")}
                                                            htmlAttributes={{ maxlength: 100 }}
                                                        />
                                                        <div
                                                            id="baselocationErr"
                                                            className="e-input-group e-float-icon-left error-div"
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div
                                            className="row card-layout"
                                            style={{
                                                paddingTop: ".5em",
                                            }}
                                        >
                                            <div className="col-xs-2 col-sm-2 col-lg-4 col-md-3">
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <KeyboardDatePicker
                                                        style={{ width: "100%" }}
                                                        id="JoinDate"
                                                        name="JoinDate"
                                                        format="dd-MMM-yyyy"
                                                        value={JoiningDate}
                                                        onChange={(e) =>
                                                            this.editBasicInFormation(e, "JoinDate")
                                                        }

                                                        TextFieldComponent={this.TextFieldComponent}
                                                        color="black"
                                                        // disablePast
                                                        autoOk
                                                        variant="inline"
                                                        inputVariant="outlined"
                                                        placeholder="dd-MMM-yyyy"
                                                        label="Date of Joining *"
                                                        KeyboardButtonProps={{ "aria-label": "change date" }}
                                                        InputLabelProps={{ shrink: true }}
                                                    />
                                                </MuiPickersUtilsProvider>
                                                <div
                                                    id="joinDateErr"
                                                    className="e-input-group e-float-icon-left error-div"
                                                />
                                            </div>
                                            <div className="col-xs-2 col-sm-2 col-lg-4 col-md-3">
                                                <div>
                                                    {ifFunRepHasLength}
                                                    <div
                                                        id="reportingToErr"
                                                        className="e-input-group e-float-icon-left error-div"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-xs-2 col-sm-2 col-lg-4 col-md-3"
                                            // style={{marginLeft: "-.9%"}}
                                            >
                                                <div>
                                                    {ifAdminRepHasLength}
                                                    <div
                                                        id="adminReportingToErr"
                                                        className="e-input-group e-float-icon-left error-div"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="PersonalDetails" className="tabfields" style={{ display: (currentTab === "PersonalDetails" ? "block" : "none") }}>
                                        <div
                                            className="row card-layout"
                                            style={{
                                                marginTop: "2em",
                                            }}
                                        >
                                            <div className="col-xs-2 col-sm-2 col-lg-4 col-md-3">
                                                <div className="col-xs-2 col-sm-2 col-lg-6 col-md-6"
                                                    style={{
                                                        padding: 0,
                                                        paddingRight: 15
                                                    }}
                                                >
                                                    <div>
                                                        <ComboBoxComponent
                                                            id="Gender"
                                                            floatLabelType="Auto"
                                                            fields={{ text: "Name", value: "Id" }}
                                                            data-msg-containerid="genderErr"
                                                            name="genderErr"
                                                            allowFiltering
                                                            allowCustom={false}
                                                            value={gender}
                                                            dataSource={genderMaster}
                                                            cssClass="e-outline"
                                                            placeholder="Gender *"
                                                            select={(e) => this.editBasicInFormation(e, "Gender")}
                                                        />
                                                        <div
                                                            id="genderErr"
                                                            className="e-input-group e-float-icon-left error-div"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-xs-2 col-sm-2 col-lg-6 col-md-6"
                                                    style={{
                                                        padding: 0
                                                    }}
                                                >
                                                    <div>
                                                        <ComboBoxComponent
                                                            id="Salutation"
                                                            fields={{ text: "Name", value: "Id" }}
                                                            value={Salutation}
                                                            name="salutationErr"
                                                            data-msg-containerid="salutationErr"
                                                            dataSource={salutationStateMaster}
                                                            placeholder="Salutation *"
                                                            floatLabelType="Auto"
                                                            cssClass="e-outline"
                                                            select={(e) => this.editBasicInFormation(e, "Salutation")}
                                                        />
                                                        <div id="salutationErr" className="error-div" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xs-2 col-sm-2 col-lg-4 col-md-3">
                                                <div>
                                                    <TextBoxComponent
                                                        data-msg-containerid="firstNameErr"
                                                        id="FirstName"
                                                        value={FirstName}
                                                        name="firstNameErr"
                                                        placeholder="First Name *"
                                                        floatLabelType="Auto"
                                                        cssClass="e-outline"
                                                        blur={(e) => this.editBasicInFormation(e, "FirstName")}
                                                        htmlAttributes={{ maxlength: 100 }}
                                                    />
                                                    <div id="firstNameErr" className="error-div" />
                                                </div>
                                            </div>
                                            <div className="col-xs-2 col-sm-2 col-lg-4 col-md-3">
                                                <div>
                                                    <TextBoxComponent
                                                        id="MiddleName"
                                                        value={MiddleName}
                                                        data-msg-containerid="middlenameErr"
                                                        name="middlenameErr"
                                                        placeholder="Middle Name"
                                                        floatLabelType="Auto"
                                                        cssClass="e-outline"
                                                        blur={(e) => this.editBasicInFormation(e, "MiddleName")}
                                                        htmlAttributes={{ maxlength: 100 }}
                                                    />
                                                    <div
                                                        id="middlenameErr"
                                                        className="e-input-group e-float-icon-left error-div"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="row card-layout">
                                            <div className="col-xs-2 col-sm-2 col-lg-4 col-md-3">
                                                <div>
                                                    <TextBoxComponent
                                                        id="LastName"
                                                        value={LastName}
                                                        data-msg-containerid="lastNameErr"
                                                        name="lastNameErr"
                                                        placeholder="Last Name *"
                                                        floatLabelType="Auto"
                                                        cssClass="e-outline"
                                                        blur={(e) => this.editBasicInFormation(e, "LastName")}
                                                        htmlAttributes={{ maxlength: 100 }}
                                                    />
                                                    <div
                                                        id="lastNameErr"
                                                        className="e-input-group e-float-icon-left error-div"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-xs-2 col-sm-2 col-lg-4 col-md-3">
                                                <div>
                                                    <TextBoxComponent
                                                        id="LegalName"
                                                        value={LegalName}
                                                        data-msg-containerid="legalNameErr"
                                                        name="legalNameErr"
                                                        cssClass="e-outline"
                                                        floatLabelType="Auto"
                                                        placeholder="Legal Name  *"
                                                        blur={(e) => this.editBasicInFormation(e, "LegalName")}
                                                    />
                                                    <div
                                                        id="legalNameErr"
                                                        className="e-input-group e-float-icon-left error-div"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-xs-2 col-sm-2 col-lg-4 col-md-3"
                                            >
                                                <div className="col-xs-2 col-sm-2 col-lg-3 col-md-3"
                                                    style={{
                                                        padding: 0,
                                                        paddingRight: 15
                                                    }}
                                                >
                                                    <div>
                                                        <TextBoxComponent
                                                            id="personalISD"
                                                            name="personalISDErr"
                                                            data-msg-containerid="personalISDErr"
                                                            value={ISDCodePersonal}
                                                            cssClass="e-outline"
                                                            placeholder="ISD Code"
                                                            floatLabelType="Auto"
                                                            blur={(e) => this.editBasicInFormation(e, "persCountryCode")}
                                                        />
                                                        <div
                                                            id="personalISDErr"
                                                            className="e-input-group e-float-icon-left error-div"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-xs-2 col-sm-2 col-lg-9 col-md-9"
                                                    style={{
                                                        padding: 0,
                                                    }}
                                                >
                                                    <div>
                                                        <TextBoxComponent
                                                            id="MobileNumber"
                                                            floatLabelType="Auto"
                                                            value={PersonalMobileNo}
                                                            data-msg-containerid="mobilenumberErr"
                                                            name="mobilenumberErr"
                                                            cssClass="e-outline"
                                                            placeholder="Personal Mobile Number"
                                                            blur={(e) => this.editBasicInFormation(e, "MobileNumber")}
                                                            htmlAttributes={{ maxlength: 15 }}
                                                        />
                                                        <div
                                                            id="mobilenumberErr"
                                                            className="e-input-group e-float-icon-left error-div"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="row card-layout">
                                            <div className="col-xs-2 col-sm-2 col-lg-4 col-md-3">
                                                <div className="col-xs-2 col-sm-2 col-lg-3 col-md-3"
                                                    style={{
                                                        padding: 0,
                                                        paddingRight: 15
                                                    }}
                                                >
                                                    <div>
                                                        <TextBoxComponent
                                                            id="altISD"
                                                            name="altISDErr"
                                                            data-msg-containerid="altISDErr"
                                                            value={ISDCodeAlternative}
                                                            cssClass="e-outline"
                                                            placeholder="ISD Code"
                                                            floatLabelType="Auto"
                                                            blur={(e) => this.editBasicInFormation(e, "altCountryCode")}
                                                            htmlAttributes={{ maxlength: 10 }}
                                                        />
                                                        <div
                                                            id="altISDErr"
                                                            className="e-input-group e-float-icon-left error-div"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-xs-2 col-sm-2 col-lg-9 col-md-9"
                                                    style={{
                                                        padding: 0,
                                                    }}
                                                >
                                                    <div>
                                                        <TextBoxComponent
                                                            id="AlternateMobile"
                                                            floatLabelType="Auto"
                                                            data-msg-containerid="alternateNumberErr"
                                                            name="alternateNumberErr"
                                                            value={AltMobileNo}
                                                            cssClass="e-outline"
                                                            placeholder="Alternate Mobile Number"
                                                            blur={(e) => this.editBasicInFormation(e, "AlternateMobile")}
                                                            htmlAttributes={{ maxlength: 15 }}
                                                        />
                                                        <div
                                                            id="alternateNumberErr"
                                                            className="e-input-group e-float-icon-left error-div"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xs-2 col-sm-2 col-lg-4 col-md-3">
                                                <TextBoxComponent
                                                    id="officialEmail"
                                                    value={officialEmail}
                                                    data-msg-containerid="officialEmailErr"
                                                    name="officialEmailErr"
                                                    cssClass="e-outline"
                                                    floatLabelType="Auto"
                                                    placeholder="Official Email *"
                                                    blur={(e) => this.editBasicInFormation(e, "officialEmail")}
                                                />
                                                <div
                                                    id="officialEmailErr"
                                                    className="e-input-group e-float-icon-left error-div"
                                                />
                                            </div>
                                            <div className="col-xs-2 col-sm-2 col-lg-4 col-md-3">
                                                <TextBoxComponent
                                                    id="EmailID"
                                                    floatLabelType="Auto"
                                                    value={PersonalEmail}
                                                    data-msg-containerid="emailIdErr"
                                                    name="emailIdErr"
                                                    cssClass="e-outline"
                                                    placeholder="Personal Email ID"
                                                    blur={(e) => this.editBasicInFormation(e, "EmailID")}
                                                    htmlAttributes={{ maxlength: 100 }}
                                                />
                                                <div
                                                    id="emailIdErr"
                                                    className="e-input-group e-float-icon-left error-div"
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className="row card-layout">
                                            <div className="col-xs-2 col-sm-2 col-lg-4 col-md-3">
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <KeyboardDatePicker
                                                        style={{ width: "100%" }}
                                                        id="DateofBirth"
                                                        name="DateofBirth"
                                                        format="dd-MMM-yyyy"
                                                        value={dateofBirth}
                                                        onChange={(e) =>
                                                            this.editBasicInFormation(e, "DateofBirth")
                                                        }
                                                        TextFieldComponent={this.TextFieldComponent}
                                                        color="black"
                                                        // disablePast
                                                        autoOk
                                                        variant="inline"
                                                        inputVariant="outlined"
                                                        placeholder="dd-MMM-yyyy"
                                                        label="Date of Birth"
                                                        KeyboardButtonProps={{ "aria-label": "change date" }}
                                                        InputLabelProps={{ shrink: true }}
                                                    />
                                                </MuiPickersUtilsProvider>
                                                <div
                                                    id="dateofbirthErr"
                                                    className="e-input-group e-float-icon-left error-div"
                                                />
                                            </div>
                                            <div className="col-xs-2 col-sm-2 col-lg-4 col-md-3">
                                                <div>
                                                    <TextBoxComponent
                                                        id="Address1"
                                                        placeholder="Address Line 1"
                                                        floatLabelType="Auto"
                                                        value={AddressLine1}
                                                        data-msg-containerid="address1Err"
                                                        name="address1Err"
                                                        cssClass="e-outline"
                                                        blur={(e) => this.editBasicInFormation(e, "Address1")}
                                                        htmlAttributes={{ maxlength: 100 }}
                                                    />
                                                    <div
                                                        id="address1Err"
                                                        className="e-input-group e-float-icon-left error-div"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-xs-2 col-sm-2 col-lg-4 col-md-3">
                                                <div>
                                                    <TextBoxComponent
                                                        id="Address2"
                                                        placeholder="Address Line 2"
                                                        floatLabelType="Auto"
                                                        value={AddressLine2}
                                                        data-msg-containerid="address2Err"
                                                        name="address2Err"
                                                        cssClass="e-outline"
                                                        blur={(e) => this.editBasicInFormation(e, "Address2")}
                                                        htmlAttributes={{ maxlength: 100 }}
                                                    />
                                                    <div
                                                        id="address2Err"
                                                        className="e-input-group e-float-icon-left error-div"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="row card-layout">
                                            <div className="col-xs-2 col-sm-2 col-lg-4 col-md-3">
                                                <div className="col-xs-2 col-sm-2 col-lg-6 col-md-6"
                                                    style={{
                                                        padding: 0,
                                                        paddingRight: 15
                                                    }}
                                                >
                                                    <div>
                                                        <TextBoxComponent
                                                            id="pincode"
                                                            floatLabelType="Auto"
                                                            value={PinCode}
                                                            data-msg-containerid="pincodeErr"
                                                            name="pincodeErr"
                                                            cssClass="e-outline"
                                                            placeholder="Pin/Zip Code"
                                                            blur={(e) => this.editBasicInFormation(e, "pincode")}
                                                        />
                                                        <div
                                                            id="pincodeErr"
                                                            className="e-input-group e-float-icon-left error-div"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-xs-2 col-sm-2 col-lg-6 col-md-6"
                                                    style={{
                                                        padding: 0,
                                                    }}
                                                >
                                                    <div>
                                                        <TextBoxComponent
                                                            id="city"
                                                            floatLabelType="Auto"
                                                            value={City}
                                                            data-msg-containerid="cityErr"
                                                            name="cityErr"
                                                            cssClass="e-outline"
                                                            placeholder="Enter City"
                                                            blur={(e) => this.editBasicInFormation(e, "city")}
                                                            htmlAttributes={{ maxlength: 100 }}
                                                        />
                                                        <div
                                                            id="cityErr"
                                                            className="e-input-group e-float-icon-left error-div"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xs-2 col-sm-2 col-lg-4 col-md-3">
                                                <div>
                                                    <TextBoxComponent
                                                        id="state"
                                                        value={State}
                                                        data-msg-containerid="stateErr"
                                                        name="stateErr"
                                                        allowCustom={false}
                                                        floatLabelType="Auto"
                                                        cssClass="e-outline"
                                                        placeholder="Enter State"
                                                        blur={(e) => this.editBasicInFormation(e, "state")}
                                                    />
                                                    <div
                                                        id="stateErr"
                                                        className="e-input-group e-float-icon-left error-div"
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-xs-2 col-sm-2 col-lg-4 col-md-3">
                                                <div className="col-xs-2 col-sm-2 col-lg-6 col-md-6"
                                                    style={{
                                                        padding: 0,
                                                        paddingRight: 15
                                                    }}
                                                >
                                                    <div>
                                                        <ComboBoxComponent
                                                            id="addressCountry"
                                                            fields={{ text: "Name", value: "Id" }}
                                                            value={addressCountryId}
                                                            name="addressCountryErr"
                                                            data-msg-containerid="addressCountryErr"
                                                            dataSource={addressCountryMaster}
                                                            placeholder="Address Country *"
                                                            floatLabelType="Auto"
                                                            cssClass="e-outline"
                                                            select={(e) => this.editBasicInFormation(e, "addressCountry")}
                                                        />
                                                        <div
                                                            id="addressCountryErr" className="error-div"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-xs-2 col-sm-2 col-lg-6 col-md-6"
                                                    style={{
                                                        padding: 0,
                                                    }}
                                                >
                                                    <div>
                                                        <ComboBoxComponent
                                                            id="holidayCountry"
                                                            fields={{ text: "Name", value: "Id" }}
                                                            value={holidayCountryId}
                                                            name="holidayCountryErr"
                                                            data-msg-containerid="holidayCountryErr"
                                                            dataSource={holidayCountryData}
                                                            placeholder="Holiday Country *"
                                                            floatLabelType="Auto"
                                                            cssClass="e-outline"
                                                            select={(e) => this.editBasicInFormation(e, "holidayCountry")}
                                                        />
                                                        <div
                                                            id="holidayCountryErr" className="error-div"
                                                        />
                                                    </div>
                                                </div>
                                            </div>

                                            {/* <div className="col-xs-2 col-sm-2 col-lg-4 col-md-3">
                                                <ComboBoxComponent
                                                    id="addressCountry"
                                                    fields={{ text: "Name", value: "Id" }}
                                                    value={addressCountryId}
                                                    name="addressCountryErr"
                                                    data-msg-containerid="addressCountryErr"
                                                    dataSource={addressCountryMaster}
                                                    placeholder="Address Country ff *"
                                                    floatLabelType="Auto"
                                                    cssClass="e-outline"
                                                    select={(e) => this.editBasicInFormation(e, "addressCountry")}
                                                />
                                                <div id="addressCountryErr" className="error-div" />
                                            </div> */}
                                        </div>
                                    </div>
                                    <div id="OtherDetails" className="tabfields" style={{ display: (currentTab === "OtherDetails" ? "block" : "none") }}>
                                        <div
                                            className="row card-layout"
                                            style={{
                                                paddingTop: "2em",
                                            }}
                                        >
                                            <div className="col-xs-2 col-sm-2 col-lg-4 col-md-3">
                                                <div>
                                                    <ComboBoxComponent
                                                        id="Recruiter"
                                                        fields={{ text: "Name", value: "Id" }}
                                                        data-msg-containerid="recruiterErr"
                                                        name="recruiterErr"
                                                        allowCustom={false}
                                                        value={recruitmentManagerId}
                                                        cssClass="e-outline"
                                                        floatLabelType="Auto"
                                                        dataSource={recruitementMaster}
                                                        select={(e) => this.editBasicInFormation(e, "Recruiter")}
                                                        placeholder="Recruiter"
                                                    />
                                                </div>
                                                <div
                                                    id="recruiterErr"
                                                    className="e-input-group e-float-icon-left error-div"
                                                />
                                            </div>
                                            <div className="col-xs-2 col-sm-2 col-lg-4 col-md-3">
                                                <ComboBoxComponent
                                                    id="HighestQualification"
                                                    dataSource={highestEducationQualifcationMaster}
                                                    fields={{ text: "Name", value: "Id" }}
                                                    data-msg-containerid="highesteduErr"
                                                    name="highesteduErr"
                                                    value={highestEducationQualification}
                                                    floatLabelType="Auto"
                                                    cssClass="e-outline"
                                                    allowFiltering
                                                    allowCustom={false}
                                                    placeholder="Highest Qualification"
                                                    select={(e) =>
                                                        this.editBasicInFormation(e, "HighestQualification")
                                                    }
                                                />
                                                <div
                                                    id="highesteduErr"
                                                    className="e-input-group e-float-icon-left error-div"
                                                />
                                            </div>
                                            <div className="col-xs-2 col-sm-2 col-lg-4 col-md-3">
                                                <TextBoxComponent
                                                    id="HighestEducationalSpecialization"
                                                    floatLabelType="Auto"
                                                    cssClass="e-outline"
                                                    value={highestEducationQualificationSpecial}
                                                    data-msg-containerid="highesteduspeErr"
                                                    name="highesteduspeErr"
                                                    placeholder="Specialization"
                                                    blur={(e) =>
                                                        this.editBasicInFormation(e, "HighestEducationalSpecialization")
                                                    }
                                                    htmlAttributes={{ maxlength: 100 }}
                                                />
                                                <div
                                                    id="highesteduspeErr"
                                                    className="e-input-group e-float-icon-left error-div"
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className="row card-layout">
                                            <div className="col-xs-2 col-sm-2 col-lg-4 col-md-3">
                                                <div className="col-xs-2 col-sm-2 col-lg-6 col-md-2"
                                                    style={{
                                                        padding: 0,
                                                        paddingRight: 15
                                                    }}
                                                >
                                                    <ComboBoxComponent
                                                        id="employeeStatus"
                                                        fields={{ text: "Name", value: "Value" }}
                                                        data-msg-containerid="employeeStatusErr"
                                                        name="employeeStatusErr"
                                                        value={employeeStatus}
                                                        allowCustom={false}
                                                        cssClass="e-outline"
                                                        floatLabelType="Auto"
                                                        dataSource={employeeStatusData}
                                                        filtering={this.onFiltering}
                                                        placeholder="Active / Inactive *"
                                                        select={(e) =>
                                                            this.editBasicInFormation(e, "employeeStatus")
                                                        }
                                                    />
                                                    <div
                                                        id="employeeStatusErr"
                                                        className="e-input-group e-float-icon-left error-div"
                                                    />
                                                </div>
                                                {
                                                    <div className="col-xs-2 col-sm-2 col-lg-6 col-md-2"
                                                        style={{
                                                            padding: 0,
                                                        }}
                                                    >
                                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                            <KeyboardDatePicker
                                                                style={{ width: "100%" }}
                                                                id="leftDate"
                                                                name="leftDateErr"
                                                                format="dd-MMM-yyyy"
                                                                value={leftDate}
                                                                onChange={(e) =>
                                                                    this.editBasicInFormation(e, "leftDate")
                                                                }
                                                                TextFieldComponent={this.TextFieldComponent}
                                                                color="black"
                                                                // disablePast
                                                                autoOk
                                                                variant="inline"
                                                                inputVariant="outlined"
                                                                placeholder="dd-MMM-yyyy"
                                                                label="Left Date "
                                                                KeyboardButtonProps={{ "aria-label": "change date" }}
                                                                InputLabelProps={{ shrink: true }}
                                                                disabled={isLeftDateDisabled}
                                                            />
                                                        </MuiPickersUtilsProvider>
                                                        <div
                                                            id="leftDateErr"
                                                            className="e-input-group e-float-icon-left error-div"
                                                        />
                                                    </div>
                                                }
                                            </div>
                                            {
                                                almRoles.length > 0 &&
                                                <>
                                                    <div className="col-xs-2 col-sm-2 col-lg-4 col-md-3">
                                                        <ComboBoxComponent
                                                            id="almId"
                                                            dataSource={almRoles}
                                                            fields={{ text: "Name", value: "Id" }}
                                                            data-msg-containerid="almErr"
                                                            name="almErr"
                                                            value={almId}
                                                            floatLabelType="Auto"
                                                            cssClass="e-outline"
                                                            allowFiltering
                                                            allowCustom={false}
                                                            placeholder="ALM Role"
                                                            select={(e) =>
                                                                this.editBasicInFormation(e, "almId")
                                                            }
                                                        />
                                                        <div
                                                            id="almErr"
                                                            className="e-input-group e-float-icon-left error-div"
                                                        />
                                                    </div>
                                                </>
                                            }

                                            <div className="col-xs-2 col-sm-2 col-lg-4 col-md-3 ">
                                                <div className="col-xs-2 col-sm-2 col-lg-6 col-md-2"
                                                    style={{
                                                        paddingLeft: 0,
                                                        paddingRight: 15,
                                                    }}
                                                >
                                                    <TextBoxComponent
                                                        id="PANNumber"
                                                        value={panNumber}
                                                        data-msg-containerid="PANNumberErr"
                                                        name="PANNumberErr"
                                                        placeholder={isIndian ? "PAN Number *" : "PAN Number"}
                                                        floatLabelType="Auto"
                                                        cssClass="e-outline"
                                                        blur={(e) => this.editBasicInFormation(e, "PANNumber")}
                                                        htmlAttributes={{ maxlength: 15 }}
                                                    />
                                                    <div
                                                        id="PANNumberErr"
                                                        className="e-input-group e-float-icon-left error-div"
                                                    />
                                                </div>
                                                <div className="col-xs-2 col-sm-2 col-lg-6 col-md-2"
                                                    style={{
                                                        paddingLeft: 0,
                                                        paddingRight: 0,
                                                    }}>
                                                    <TextBoxComponent
                                                        id="aadhaarNumber"
                                                        value={adharNumber}
                                                        data-msg-containerid="aadhaarNumberErr"
                                                        name="aadhaarNumberErr"
                                                        cssClass="e-outline"
                                                        floatLabelType="Auto"
                                                        placeholder={isIndian ? "Aadhaar Number *" : "Aadhaar Number"}
                                                        blur={(e) => this.editBasicInFormation(e, "AadhaarNumber")}
                                                        htmlAttributes={{ maxlength: 15 }}
                                                    />
                                                    <div
                                                        id="aadhaarNumberErr"
                                                        className="e-input-group e-float-icon-left error-div"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="row card-layout">
                                            <div className="col-xs-2 col-sm-2 col-lg-4 col-md-3">
                                                <TextBoxComponent
                                                    id="other"
                                                    placeholder="Other/Identification"
                                                    floatLabelType="Auto"
                                                    value={other}
                                                    data-msg-containerid="otherErr"
                                                    name="otherErr"
                                                    cssClass="e-outline"
                                                    onChange={(e) => this.editBasicInFormation(e, "Other")}
                                                    htmlAttributes={{ maxlength: 100 }}
                                                />
                                                <div
                                                    id="otherErr"
                                                    className="e-input-group e-float-icon-left error-div"
                                                />
                                            </div>
                                            <div className="col-xs-2 col-sm-2 col-lg-4 col-md-3 ">
                                                <div className="col-xs-2 col-sm-2 col-lg-6 col-md-2"
                                                    style={{
                                                        paddingLeft: 0,
                                                        paddingRight: 15,
                                                    }}
                                                >
                                                    <TextBoxComponent
                                                        id="TotalWorkExperience"
                                                        floatLabelType="Auto"
                                                        value={workExperience}
                                                        data-msg-containerid="totalworkErr"
                                                        name="totalworkErr"
                                                        cssClass="e-outline"
                                                        placeholder="Exp. in Months"
                                                        onChange={(e) =>
                                                            this.editBasicInFormation(e, "TotalWorkExperience")
                                                        }
                                                        htmlAttributes={{ maxlength: 3 }}
                                                    />
                                                    <div
                                                        id="totalworkErr"
                                                        className="e-input-group e-float-icon-left error-div"
                                                    />
                                                </div>
                                                {(this.state.employmentTypeId === 677 || this.state.employmentTypeId === 747 || this.state.employmentTypeId === 799 || this.state.employmentTypeId === 798 || this.state.employmentTypeId === 748 || this.state.employmentTypeId === 749) && (
                                                    <div className="col-xs-2 col-sm-2 col-lg-6 col-md-2"
                                                        style={{
                                                            paddingLeft: 0,
                                                            paddingRight: 0,
                                                        }}
                                                    >
                                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                            <KeyboardDatePicker
                                                                style={{ width: "100%" }}
                                                                id="tenureEndDate"
                                                                name="tenureEndDateErr"
                                                                format="dd-MMM-yyyy"
                                                                value={tenureEndDate}
                                                                onChange={(e) =>
                                                                    this.editBasicInFormation(e, "TenureEndDate")
                                                                }
                                                                TextFieldComponent={this.TextFieldComponent}
                                                                color="black"
                                                                autoOk
                                                                variant="inline"
                                                                inputVariant="outlined"
                                                                placeholder="dd-MMM-yyyy"
                                                                label="Tenure End Date "
                                                                KeyboardButtonProps={{ "aria-label": "change date" }}
                                                                InputLabelProps={{ shrink: true }}
                                                            />
                                                        </MuiPickersUtilsProvider>
                                                        <div
                                                            id="tenureEndDateErr"
                                                            className="e-input-group e-float-icon-left error-div"
                                                        />
                                                    </div>)}
                                            </div>
                                            <div className="col-xs-2 col-sm-2 col-lg-4 col-md-3 ">
                                                <div className="col-xs-2 col-sm-2 col-lg-6 col-md-2"
                                                    style={{
                                                        paddingLeft: 0,
                                                        paddingRight: 15,
                                                    }}
                                                >
                                                    <ComboBoxComponent
                                                        id="currency"
                                                        dataSource={currencyMaster}
                                                        fields={{ text: "Name", value: "Id" }}
                                                        data-msg-containerid="currencyErr"
                                                        name="currencyErr"
                                                        value={currencyId}
                                                        floatLabelType="Auto"
                                                        cssClass="e-outline"
                                                        allowFiltering
                                                        allowCustom={false}
                                                        placeholder="Currency"
                                                        select={(e) =>
                                                            this.editBasicInFormation(e, "currency")
                                                        }
                                                    />
                                                    <div
                                                        id="currencyErr"
                                                        className="e-input-group e-float-icon-left error-div"
                                                    />
                                                </div>
                                                <div className="col-xs-2 col-sm-2 col-lg-6 col-md-2"
                                                    style={{
                                                        paddingLeft: 0,
                                                        paddingRight: 0,
                                                    }}>
                                                    <ComboBoxComponent
                                                        id="ctcFrequency"
                                                        dataSource={[{ Id: "1", Name: "Monthly" }, { Id: "2", Name: "Yearly" }]}
                                                        fields={{ text: "Name", value: "Id" }}
                                                        data-msg-containerid="ctcFrequencyErr"
                                                        name="ctcFrequencyErr"
                                                        value={ctcFrequency}
                                                        floatLabelType="Auto"
                                                        cssClass="e-outline"
                                                        allowFiltering
                                                        allowCustom={false}
                                                        placeholder="CTC Frequency"
                                                        select={(e) =>
                                                            this.editBasicInFormation(e, "ctcFrequency")
                                                        }
                                                    />
                                                    <div
                                                        id="ctcFrequencyErr"
                                                        className="e-input-group e-float-icon-left error-div"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row card-layout">
                                            <div className="col-xs-2 col-sm-2 col-lg-4 col-md-3">
                                                <TextBoxComponent
                                                    id="windowLogin"
                                                    value={windowLogin}
                                                    data-msg-containerid="windowLoginErr"
                                                    name="windowLoginErr"
                                                    cssClass="e-outline"
                                                    floatLabelType="Auto"
                                                    placeholder="Window Login Id *"
                                                    blur={(e) => this.editBasicInFormation(e, "windowLogin")}
                                                    htmlAttributes={{ maxlength: 40 }}
                                                />
                                                <div
                                                    id="windowLoginErr"
                                                    className="e-input-group e-float-icon-left error-div"
                                                />
                                            </div>
                                            <div className="col-xs-2 col-sm-2 col-lg-4 col-md-3">
                                                <div className="col-xs-2 col-sm-2 col-lg-6 col-md-2"
                                                    style={{
                                                        paddingLeft: 0,
                                                        paddingRight: 15,
                                                    }}
                                                >
                                                    <ComboBoxComponent
                                                        id="SourceCategory"
                                                        dataSource={sourceCategoryData}
                                                        fields={{ text: "Name", value: "Id" }}
                                                        data-msg-containerid="sourceCategoryErr"
                                                        name="sourceCategoryErr"
                                                        value={sourceCategory}
                                                        floatLabelType="Auto"
                                                        cssClass="e-outline"
                                                        allowFiltering
                                                        allowCustom={false}
                                                        placeholder="Source Category"
                                                        select={(e) =>
                                                            this.editBasicInFormation(e, "SourceCategory")
                                                        }
                                                    />
                                                    <div
                                                        id="sourceCategoryErr"
                                                        className="e-input-group e-float-icon-left error-div"
                                                    />

                                                </div>

                                                {(sourceCategory === 729 || sourceCategory === 730 || sourceCategory === 731) && (
                                                    <div className="col-xs-2 col-sm-2 col-lg-6 col-md-2"
                                                        style={{
                                                            paddingLeft: 0,
                                                            paddingRight: 0,
                                                        }}>
                                                        {sourceCategory === 729 &&
                                                            <>
                                                                <ComboBoxComponent
                                                                    id="jobPortal"
                                                                    dataSource={jobPortalData}
                                                                    fields={{ text: "Name", value: "Id" }}
                                                                    data-msg-containerid="jobPortalErr"
                                                                    name="jobPortalErr"
                                                                    value={jobPortalId}
                                                                    floatLabelType="Auto"
                                                                    cssClass="e-outline"
                                                                    allowFiltering
                                                                    allowCustom={false}
                                                                    placeholder="Job Portal"
                                                                    select={(e) =>
                                                                        this.editBasicInFormation(e, "jobPortal")
                                                                    }
                                                                />
                                                                <div
                                                                    id="jobPortalErr"
                                                                    className="e-input-group e-float-icon-left error-div"
                                                                />
                                                            </>
                                                        }
                                                        {sourceCategory === 730 &&
                                                            <>
                                                                <ComboBoxComponent
                                                                    id="Referral"
                                                                    dataSource={othersCheckAvailablity}
                                                                    fields={{ text: "nameWithDepartment", value: "UserId" }}
                                                                    data-msg-containerid="referralErr"
                                                                    name="referralErr"
                                                                    value={referralId}
                                                                    floatLabelType="Auto"
                                                                    cssClass="e-outline"
                                                                    allowFiltering
                                                                    allowCustom={false}
                                                                    placeholder="Referral"
                                                                    select={(e) =>
                                                                        this.editBasicInFormation(e, "referral")
                                                                    }
                                                                />
                                                                <div
                                                                    id="referralErr"
                                                                    className="e-input-group e-float-icon-left error-div"
                                                                />
                                                            </>
                                                        }
                                                        {sourceCategory === 731 &&
                                                            <>
                                                                <TextBoxComponent
                                                                    id="AgencyName"
                                                                    placeholder="Agency Name"
                                                                    floatLabelType="Auto"
                                                                    value={agencyName}
                                                                    data-msg-containerid="agencyNameErr"
                                                                    name="agencyNameErr"
                                                                    cssClass="e-outline"
                                                                    onChange={(e) => this.editBasicInFormation(e, "agencyName")}
                                                                    htmlAttributes={{ maxlength: 100 }}
                                                                />
                                                                <div
                                                                    id="agencyNameErr"
                                                                    className="e-input-group e-float-icon-left error-div"
                                                                />
                                                            </>
                                                        }
                                                    </div>)}
                                            </div>
                                            <div className="col-xs-2 col-sm-2 col-lg-4 col-md-3">
                                                <div className="col-xs-2 col-sm-2 col-lg-6 col-md-2"
                                                    style={{
                                                        paddingLeft: 0,
                                                        paddingRight: 15,
                                                    }}
                                                >
                                                    <TextBoxComponent
                                                        id="noticePeriod"
                                                        value={noticePeriod}
                                                        data-msg-containerid="noticePeriodErr"
                                                        name="noticePeriodErr"
                                                        cssClass="e-outline"
                                                        floatLabelType="Auto"
                                                        placeholder="Notice Period in Months"
                                                        onChange={(e) => this.editBasicInFormation(e, "noticePeriod")}
                                                    />
                                                    <div
                                                        id="noticePeriodErr"
                                                        className="e-input-group e-float-icon-left error-div"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row card-layout" >
                                            <div className="col-xs-2 col-sm-2 col-lg-4 col-md-3">
                                                <TextBoxComponent
                                                    id="digitalEmail"
                                                    value={digitalEmail}
                                                    data-msg-containerid="digitalEmailErr"
                                                    name="digitalEmailErr"
                                                    cssClass="e-outline"
                                                    floatLabelType="Auto"
                                                    placeholder="Digital Email"
                                                    blur={(e) => this.editBasicInFormation(e, "digitalEmail")}

                                                />
                                                <div
                                                    id="digitalEmailErr"
                                                    className="e-input-group e-float-icon-left error-div"
                                                />
                                            </div>
                                            <div className="col-xs-2 col-sm-2 col-lg-4 col-md-3">
                                                <TextBoxComponent
                                                    id="teamsEmail"
                                                    value={teamsEmail}
                                                    data-msg-containerid="teamsEmailErr"
                                                    name="teamsEmailErr"
                                                    cssClass="e-outline"
                                                    floatLabelType="Auto"
                                                    placeholder="Teams Email"
                                                    blur={(e) => this.editBasicInFormation(e, "teamsEmail")}


                                                />
                                                <div
                                                    id="teamsEmailErr"
                                                    className="e-input-group e-float-icon-left error-div"
                                                />
                                            </div>
                                            <div className="col-xs-2 col-sm-2 col-lg-4 col-md-3">
                                                <div className="col-xs-2 col-sm-2 col-lg-6 col-md-2"
                                                    style={{
                                                        paddingLeft: 0,
                                                        paddingRight: 15,
                                                    }}
                                                >
                                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                        <KeyboardDatePicker
                                                            style={{ width: "100%" }}
                                                            id="OfferedDate"
                                                            name="OfferedDate"
                                                            format="dd-MMM-yyyy"
                                                            value={offeredDate}
                                                            onChange={(e) =>
                                                                this.editBasicInFormation(e, "OfferedDate")
                                                            }
                                                            // disabled
                                                            TextFieldComponent={this.TextFieldComponent}
                                                            color="black"
                                                            // disablePast
                                                            autoOk
                                                            variant="inline"
                                                            inputVariant="outlined"
                                                            placeholder="dd-MMM-yyyy"
                                                            label="Offered Date"
                                                            KeyboardButtonProps={{ "aria-label": "change date" }}
                                                            InputLabelProps={{ shrink: true }}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                    <div
                                                        id="OfferedDateErr"
                                                        className="e-input-group e-float-icon-left error-div"
                                                    />
                                                </div>
                                                <div className="col-xs-2 col-sm-2 col-lg-6 col-md-2"
                                                    style={{
                                                        paddingLeft: 0,
                                                        paddingRight: 0,
                                                    }}
                                                >
                                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                        <KeyboardDatePicker
                                                            style={{ width: "100%" }}
                                                            id="OfferAcceptanceDate"
                                                            name="OfferAcceptanceDate"
                                                            format="dd-MMM-yyyy"
                                                            value={offerAcceptanceDate}
                                                            onChange={(e) =>
                                                                this.editBasicInFormation(e, "OfferAcceptanceDate")
                                                            }

                                                            TextFieldComponent={this.TextFieldComponent}
                                                            color="black"
                                                            // disablePast
                                                            autoOk
                                                            variant="inline"
                                                            inputVariant="outlined"
                                                            placeholder="dd-MMM-yyyy"
                                                            label="Offer Acceptance Date"
                                                            KeyboardButtonProps={{ "aria-label": "change date" }}
                                                            InputLabelProps={{ shrink: true }}
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                    <div
                                                        id="OfferAcceptanceDateErr"
                                                        className="e-input-group e-float-icon-left error-div"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                                {/* <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12' style={{ height: 1, backgroundColor: '#eeee' }} /> */}
                            </DialogContent>
                            <DialogActions style={{ paddingRight: "1.1em", borderTop: "1px solid #c3c3c3" }}>
                                <Button color="primary" variant="contained" onClick={() => this.onSave()} > Save </Button>
                                <Button color="secondary" variant="contained" onClick={() => this.props.closeEditEmployeePopup()}> Close </Button>
                            </DialogActions>
                            <Dialog
                                aria-labelledby="uploading-title"
                                open={beforeLeftPopup}
                                fullWidth
                                // width="1000"
                                maxWidth="sm"
                            >
                                <DialogTitleTMLC>
                                    <div className='col-lg-12' style={{ paddingLeft: 0, display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                        <div className="col-xs-2 col-sm-2 col-lg-8 col-md-12 pt-3">Please Update Employee Supervisory Details</div>
                                        <div style={{ float: "right", marginRight: "-5px" }}>
                                            <IconButton style={{ color: "white", marginRight: "-1.4em" }} aria-label="close" onClick={this.closeBeforeLeftPopup}>
                                                <CloseIcon />
                                            </IconButton>
                                        </div>
                                    </div>
                                </DialogTitleTMLC>
                                <ErrorBoundary>
                                    <EmployeeCheckStatus checkStatusEmployeeData={checkStatusEmployeeData} />
                                </ErrorBoundary>
                                <DialogActions style={{ paddingRight: "1.1em", borderTop: "1px solid #c3c3c3" }}>
                                    <Button color="secondary" variant="contained" onClick={this.closeBeforeLeftPopup}> Close </Button>
                                </DialogActions>
                            </Dialog>
                        </>
                }
            </div>
        );
    }
}

const mapStateToProps = ({ auth, candidateReducer, orgchartreducer, meetingmaster }) => {
    const { authUser } = auth;
    const { orgchartupdateStatus, teamMemberUpdate } = orgchartreducer;
    const { othersCheckAvailablity } = meetingmaster;
    const { employeeTypeMaster, jobTitleData, salutationStateMaster, countryMaster, stateMasterListMaster, addressCountryMaster, genderMaster, recruitementMaster, highestEducationQualifcationMaster, sourceCategoryData, jobPortalData, currencyMaster, holidayCountryData } = candidateReducer;
    return {
        authUser, employeeTypeMaster, jobTitleData, salutationStateMaster, countryMaster, stateMasterListMaster, addressCountryMaster,
        orgchartupdateStatus, othersCheckAvailablity, genderMaster, recruitementMaster, highestEducationQualifcationMaster, sourceCategoryData,
        jobPortalData, currencyMaster, teamMemberUpdate, holidayCountryData
    }
};
export default connect(mapStateToProps, { updateOrgChartDetailsAction, setOrhChartDetaila, syncUser, updateHelpDeskUserInfo, saveToEnterpriseProfile, createHelpDeskUser, updateCandidateData })(
    EmployeeEditPopup
);
