import React, { useEffect, useState, useRef } from 'react';
import { TabComponent } from "@syncfusion/ej2-react-navigations";
import { connect } from 'react-redux';
import {addUpdateOkrDashboardRedux} from '../../redux/actions/okrdashboardaction/okrdashboardaction'
import Tabs from './tab';
import Grid from './grid';
import OKRDashboardServices from '../../services/okrdashboardservices/okrdashboardservices';
import RatingImg from '../../assets/images/ratingreview.jpg';
import ProgressBar from '../progressbar/progressbar';

function PerformancePotential(props) {
  const {showMonths, potentialData} = props;
  const {height, windowAdjust} =state;

  const [loader, setLoader] = useState(false)
  const [state, setState] = useState({
    height:null,
    windowAdjust:null,
    isLoading:true
  });

  const tabRef=useRef(null)
  useEffect(()=>{
    
    window.addEventListener("resize",/*nosonar*/ updateDimensions());
    getPerformancePotrntialData();
    return () => {
      // window.removeEventListener('resize', () => {})
    }
  },[])

  useEffect (()=>{
    if(props.performanceType==="Leadership Principles"){
      tabRef.current.selectedItem = 1
    }
    if(props.dashboardStatus==='updated'){
      getPerformancePotrntialData();
      props.addUpdateOkrDashboardRedux({dashboardStatus:''})
    }
  },[props.dashboardStatus==='updated'])

  const getPerformancePotrntialData = async () => {
    setLoader(true)
    const {fyId,authUser,empId, monthlyOkrStatusMonth}=props;
    const performancePotencialData = await OKRDashboardServices.getPerformacePotentialDashboardData(authUser,empId,fyId,monthlyOkrStatusMonth)
    const performanceData=performancePotencialData.filter((item)=> {
      return item.dataType==='Perfomance'
    });
    const pData = performancePotencialData.filter((item)=> {
      return item.dataType==='Potential'
    });
    props.addUpdateOkrDashboardRedux({performanceBusinessOkrData:performanceData[0].businessOKR, performanceCentralOkrData:performanceData[0].centralOKR, potentialData:pData[0].leadershipPrinciples})
    setTimeout(()=>{
      setState({...state,isLoading:false})
      setLoader(false)
    }, 3000)
  }

  const updateDimensions=()=> {
    const updateheight = window.innerHeight - 243;
    const windowAdjusts = window.innerHeight - 204;
    setState({
      height: updateheight,
      windowAdjust: windowAdjusts,
    });
  }

  const changeTab = (args) => {
    if(args.selectedIndex === 1){
      props.addUpdateOkrDashboardRedux({performanceType:'Leadership Principles'})
    }else{
      props.addUpdateOkrDashboardRedux({performanceType:'Business OKR'})
    }
  }
  const noDataFound = ()=>{
    return(
      <>
        <div
            id='NoData'
            style={{
                height: showMonths ? height - 200 : windowAdjust - 100,
                overflowY: "hidden",
                overflowX: "hidden",
                // borderTop: '1px solid #ccc'
            }}
        >
            <div style={{ marginTop: '2%' }}>
                <div style={{ marginTop: '2%' }}>
                    <div>
                        <center>
                            <img src={RatingImg} alt="Image Not Found" />
                            <div className='ratingwelcometext pt-sm'>Welcome to OKR - Dashboard</div>
                            <div className='ratingwelcomesubtext pt-xs'>
                                    <span style={{ color:"#4B4B4B" }}>
                                      No Objectives to view for this team-member in this Performance Period.
                                    </span>
                                    
                            </div>
                        </center>
                    </div>
                </div>
            </div>
        </div>
      </>
    )
  }

  return (
    <div style={{height: showMonths ? height : windowAdjust }}>
      <div className='col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12'>
      <TabComponent id="defaultTab"
          selected={(arg) => changeTab(arg)}
          ref={tabRef}
          animation={{ previous: { effect: "SlideLeftIn", duration: 0, easing: "ease" }, next: { effect: "SlideRightIn", duration: 0, easing: "ease" } }}
        >
          <div className="e-tab-header" style={{ padding: "10px 10px" }} >
            <div> Performance </div>
            <div> Potential </div>
          </div>
          <div className="e-content" style={{ padding: "10px 10px" }}>
            <div>
              <div className='tab-horizontal-line' />
                <>
                {
                  !loader ? 
                  <Tabs showMonths={showMonths}/>
                  :
                  <div style={{marginTop:400}}>
                      <ProgressBar/>
                  </div>
                }
                </>
            </div>
            <div>
              <div className='tab-horizontal-line' />
              <div className='dashboard-lp-text'>Leadership Principle <span style={{ fontSize: '12px', color: '#CFCFCF', fontWeight: 500 }}> Scale </span><span style={{ fontSize: '12px' }}> 1-5 </span></div>
              <div style={{
                height: showMonths ? height - 120 : windowAdjust - 100,
                overflowY: "auto",
                overflowX: "hidden",
                borderRadius: 6,
              }}>
                {
                potentialData.length > 0 ?
                  potentialData?.map((item) => {
                    return (
                      <Grid objectiveData={item} />
                    )
                  })
                  :
                  noDataFound()
                }
              </div>
            </div>
          </div>
        </TabComponent>
      </div>
    </div>
  )
}
const mapStateToProps = ({ auth, okrdashboardreducer }) => {
  const { authUser } = auth;
  const {potentialData, empId, fyId, dashboardStatus, monthlyOkrStatusMonth ,performanceType}=  okrdashboardreducer;
  return {
    authUser, potentialData, empId, fyId, dashboardStatus, monthlyOkrStatusMonth,performanceType
  }
};
export default connect(mapStateToProps, { addUpdateOkrDashboardRedux })(
  PerformancePotential
);
