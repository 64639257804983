import { showSpinner, hideSpinner } from '@syncfusion/ej2-react-popups';
import React from 'react';
import { NotificationManager } from "react-notifications";
import dataService from '../../services/services'
import { FILE_NAME_ERROR, INVALID_FILE_NAME, MAX_FILE_NAME, MIN_FILE_NAME, OKRQuestionRatingData, timeZoneNames } from '../../assets/constants/constants'
import { format } from 'date-fns';
import * as xlsx from 'xlsx';
import * as CryptoJS from 'crypto-js';
const stringSimilarity = require("string-similarity");

export const getDateFormat = (d, t) => {
  let dateFormatStr;
  // eslint-disable-next-line
  if (typeof d.getFullYear() !== undefined)
    dateFormatStr = `${d.getFullYear().toString()}-${(d.getMonth() + 1).toString().length === 2
      ? (d.getMonth() + 1).toString()
      : `0${(d.getMonth() + 1).toString()}` // NOSONAR
      }-${d.getDate().toString().length === 2
        ? d.getDate().toString()
        : `0${d.getDate().toString()}` // NOSONAR
      } ${t.getHours().toString().length === 2
        ? t.getHours().toString()
        : `0${t.getHours().toString()}` // NOSONAR
      }:${parseInt(t.getMinutes(), 10).toString().length === 2
        ? parseInt(t.getMinutes(), 10).toString()
        : `0${parseInt(t.getMinutes(), 10).toString()}` // NOSONAR
      }:${parseInt(t.getSeconds(), 10).toString().length === 2
        ? parseInt(t.getSeconds(), 10).toString()
        : `0${parseInt(t.getSeconds(), 10).toString()}` // NOSONAR
      }`;
  return dateFormatStr;
};
export const getDisplayDateFormat = (d) => {
  return `${d.getDate().toString().length === 2
    ? d.getDate().toString()
    : `0${d.getDate().toString()}` // NOSONAR
    }-${d.toLocaleString("default", {
      month: "short",
    })}-${d.getYear().toString().substr(-2)} ${d.toLocaleString("en-US", {
      hour: "numeric",
      minute: "numeric",
      hour12: false,
    })}`;
};

export const getDisplayTimeFormat = (t) => {
  return `${t.getHours().toString().length === 2
    ? t.getHours().toString()
    : `0${t.getHours().toString()}` // NOSONAR
    }:${parseInt(t.getMinutes(), 10).toString().length === 2
      ? parseInt(t.getMinutes(), 10).toString()
      : `0${parseInt(t.getMinutes(), 10).toString()}` // NOSONAR
    }:${parseInt(t.getSeconds(), 10).toString().length === 2
      ? parseInt(t.getSeconds(), 10).toString()
      : `0${parseInt(t.getSeconds(), 10).toString()}` // NOSONAR
    }`;

};

export const getDisplayDateOnly = (d) => {
  return `${d.getDate().toString().length === 2
    ? d.getDate().toString()
    : `0${d.getDate().toString()}` // NOSONAR
    }-${d.toLocaleString("default", { month: "short" })}-${d.getFullYear().toString().substr(-2)
    }`;
};
export const getBencharkDisplayDateOnly = (d) => {
  return `${d.getDate().toString().length === 2
    ? d.getDate().toString()
    : `0${d.getDate().toString()}` // NOSONAR
    }-${d.toLocaleString("default", { month: "short" })}-${d.getFullYear().toString()
    }`;
};
export const getExcelDate = (d) => {
  return `${d.getDate().toString().length === 2
    ? d.getDate().toString()
    : `0${d.getDate().toString()}` // NOSONAR
    }-${(d.getMonth() + 1).toString().length === 2
      ? (d.getMonth() + 1).toString()
      : `0${(d.getMonth() + 1).toString()}` // NOSONAR
    }-${d.getFullYear().toString()}`;
};

export const getDate = (d) => {
  let dateFormatStr;
  if (typeof d.getFullYear() !== "undefined")
    dateFormatStr = `${d.getFullYear().toString()}-${(d.getMonth() + 1).toString().length === 2
      ? (d.getMonth() + 1).toString()
      : `0${(d.getMonth() + 1).toString()}` // NOSONAR
      }-${d.getDate().toString().length === 2
        ? d.getDate().toString()
        : `0${d.getDate().toString()}` // NOSONAR
      }`;
  return dateFormatStr;
};

export const endOfWeek = (date) => {
  const lastday = date.getDate() - (date.getDay() - 1) + 6;
  return new Date(date.setDate(lastday));
};

export const startOfWeek = (date) => {
  const diff = date.getDate() - date.getDay() + (date.getDay() === 0 ? -6 : 1);
  return new Date(date.setDate(diff));
};

export const addDays = (date, noDays) => {
  const dateNew = new Date(date);
  dateNew.setDate(dateNew.getDate() + noDays);
  return new Date(dateNew);
};
export const removeDays = (date, noDays) => {
  const dateNew = new Date(date);
  dateNew.setDate(dateNew.getDate() - noDays);
  return new Date(dateNew);
};
export const dateDiffIndays = (date1, date2) => {
  const dt1 = new Date(date1);
  const dt2 = new Date(date2);
  return Math.floor(
    (Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) -
      Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) /
    (1000 * 60 * 60 * 24)
  );
};

export const getCurrentDate = (date) => {

  if (localStorage.getItem("def_locale") != null) {
    try {
      return new Date(
        date.toLocaleString("en-US", {
          timeZone: localStorage.getItem("def_locale"),
        })
      );
    } catch (errr) {

    }
  }
  return null;
};

export const getDateObj = (datestr) => {
  const dt = datestr.toString().split(" ");
  return new Date(
    dt[0].toString().split("-")[0],
    dt[0].toString().split("-")[1] - 1,
    dt[0].toString().split("-")[2],
    dt[1].toString().split(":")[0],
    dt[1].toString().split(":")[1],
    dt[1].toString().split(":")[2]
  );
};

export const getNotify = (pType, pMsg, duration = 5000) => {
  NotificationManager.listNotify = [];
  switch (pType) {
    case "success":
      NotificationManager.success(pMsg, "", duration);
      break;
    case "error":
      NotificationManager.error(pMsg, "", duration);
      break;
    case "warning":
      NotificationManager.warning(pMsg, "", duration);
      break;
    default:
  }
};

export const getDiffDays = (pdt1, pdt2) => {
  const dt1 = new Date(pdt1);
  const dt2 = new Date(pdt2);
  return Math.floor(
    (Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) -
      Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) /
    (1000 * 60 * 60 * 24)
  );
};

export const encrypt = (pData) => {
  return window.btoa(process.env.REACT_APP_SERIAL_KEY * pData);
};

export const dencrypt = (pData) => {

  return Math.round(window.atob(pData) / process.env.REACT_APP_SERIAL_KEY);
};
export const encodeString = (enString) => {
  return btoa(enString);
};
export const decodeString = (DecString) => {
  return atob(DecString);
};

export const closeAgendaPopup = () => {
  try {
    if (document.getElementsByClassName("e-tooltip-wrap").length > 0) {
      const elements = document.getElementsByClassName("customtooltip");
      while (elements.length > 0) {
        elements[0].parentNode.removeChild(elements[0]);
      }
    }
  } catch (error) { }
};

export const getDateGrid = (dtitem) => {
  const dupValue = dtitem;
  const arr = dupValue.split(/[^0-9]/);
  const arg = parseInt(arr[4], 10);
  const arg1 = parseInt(arr[5], 10);
  // eslint-disable-next-line
  dtitem = new Date(
    parseInt(arr[0], 10),
    parseInt(arr[1], 10) - 1,
    parseInt(arr[2], 10),
    parseInt(arr[3], 10),
    arg,
    arg1
  );
  return dtitem;
};
export const getFormatDateForGrid = (dataSource) => {
  dataSource.map((item) => { // NOSONAR
    try {
      if (
        typeof item.joiningDate !== "undefined" &&
        item.joiningDate !== null
      ) {
        // eslint-disable-next-line
        item.joiningDate = getDateGrid(item.joiningDate);
      }
      if (
        typeof item.dateOfResignation !== "undefined" &&
        item.dateOfResignation !== null
      ) {
        // eslint-disable-next-line
        item.dateOfResignation = getDateGrid(item.dateOfResignation);
      }
      if (
        typeof item.lastworkingEmpRequested !== "undefined" &&
        // eslint-disable-next-line
        item.lastworkingEmpRequested !== null
      ) {
        // eslint-disable-next-line
        item.lastworkingEmpRequested = getDateGrid(
          item.lastworkingEmpRequested
        );
      }
      if (
        typeof item.lastWorkingDayCompanyRequested !== "undefined" &&
        // eslint-disable-next-line
        item.lastWorkingDayCompanyRequested !== null
      ) {
        // eslint-disable-next-line
        item.lastWorkingDayCompanyRequested = getDateGrid(
          item.lastWorkingDayCompanyRequested
        );
      }
      if (
        typeof item.lastWorkingDayOfficial !== "undefined" &&
        // eslint-disable-next-line
        item.lastWorkingDayOfficial !== null
      ) {
        // eslint-disable-next-line
        item.lastWorkingDayOfficial = getDateGrid(item.lastWorkingDayOfficial);
      }
      if (
        typeof item.clearanceInitateOn !== "undefined" &&
        // eslint-disable-next-line
        item.clearanceInitateOn !== null
      ) {
        // eslint-disable-next-line
        item.clearanceInitateOn = getDateGrid(item.clearanceInitateOn);
      }
      if (
        typeof item.dt_not_reporting_to_date !== "undefined" &&
        item.dt_not_reporting_to_date !== null
      ) {
        // eslint-disable-next-line
        item.dt_not_reporting_to_date = getDateGrid(
          item.dt_not_reporting_to_date
        );
      }
      if (
        typeof item.lastReportedDate !== "undefined" &&
        item.lastReportedDate !== null
      ) {
        // eslint-disable-next-line
        item.lastReportedDate = getDateGrid(item.lastReportedDate);
      }
      if (
        typeof item.absondingDate !== "undefined" &&
        item.absondingDate !== null
      ) {
        // eslint-disable-next-line
        item.absondingDate = getDateGrid(item.absondingDate);
      }
      if (
        typeof item.lastCommunicatedDate !== "undefined" &&
        item.lastCommunicatedDate !== null
      ) {
        // eslint-disable-next-line
        item.lastCommunicatedDate = getDateGrid(item.lastCommunicatedDate);
      }
      if (
        typeof item.expectedJoiningDate !== "undefined" &&
        item.expectedJoiningDate !== null
      ) {
        // eslint-disable-next-line
        item.expectedJoiningDate = getDateGrid(item.expectedJoiningDate);
      }
      if (
        typeof item.requestedOn !== "undefined" &&
        item.requestedOn !== null
      ) {
        // eslint-disable-next-line
        item.requestedOn = getDateGrid(item.requestedOn);
      }
      if (
        typeof item.lastWorkingDate !== "undefined" &&
        item.lastWorkingDate !== null
      ) {
        // eslint-disable-next-line
        item.lastWorkingDate = getDateGrid(item.lastWorkingDate);
      }
      if (
        typeof item.communicateOn !== "undefined" &&
        item.communicateOn !== null
      ) {
        // eslint-disable-next-line
        item.communicateOn = getDateGrid(item.communicateOn);
      }
      if (typeof item.uplOn !== "undefined" && item.uplOn !== null) {
        // eslint-disable-next-line
        item.uplOn = getDateGrid(item.uplOn);
      }
      if (typeof item.AddedOn !== "undefined" && item.AddedOn !== null) {
        // eslint-disable-next-line
        item.AddedOn = getDateGrid(item.AddedOn);
      }
      if (
        typeof item.dateOfWithdrwal !== "undefined" &&
        item.dateOfWithdrwal !== null
      ) {
        // eslint-disable-next-line
        item.dateOfWithdrwal = getDateGrid(item.dateOfWithdrwal);
      }
      if (typeof item.InitiateOn !== "undefined" && item.InitiateOn !== null) {
        // eslint-disable-next-line
        item.InitiateOn = getDateGrid(item.InitiateOn);
      }
      if (
        typeof item.JoiningDate !== "undefined" &&
        item.JoiningDate !== null
      ) {
        // eslint-disable-next-line
        item.JoiningDate = getDateGrid(item.JoiningDate);
      }
      if (
        typeof item.ConfirmDate !== "undefined" &&
        item.ConfirmDate !== null
      ) {
        // eslint-disable-next-line
        item.ConfirmDate = getDateGrid(item.ConfirmDate);
      }
      if (
        typeof item.DueConfirmDate !== "undefined" &&
        item.DueConfirmDate !== null
      ) {
        // eslint-disable-next-line
        item.DueConfirmDate = getDateGrid(item.DueConfirmDate);
      }
      if (
        typeof item.OfferedDate !== "undefined" &&
        item.OfferedDate !== null
      ) {
        // eslint-disable-next-line
        item.OfferedDate = getDateGrid(item.OfferedDate);
      }
      if (typeof item.RevokeDate !== "undefined" && item.RevokeDate !== null) {
        // eslint-disable-next-line
        item.RevokeDate = getDateGrid(item.RevokeDate);
      }
    } catch (error) {

    }
    return item;
  });
  return dataSource;
};
export const cracterLimit = (e, id, limit) => {
  try {
    if (document.getElementById(`${id}`)) {
      if (e) {
        document.getElementById(`${id}`).innerHTML = `${removeTralingSpaces(e.value).length} / ${limit}`;
      } else {
        document.getElementById(`${id}`).innerHTML = `${0}/${limit}`;
      }
    }
  } catch (error) {

  }

};

export const changeAccordianColor = (pType, pTimeoutSec) => { // NOSONAR
  try {
    if (pType === "Parent") {
      setTimeout(() => {
        const parentObj = document.querySelectorAll(
          ".changeObjBackColor .e-acrdn-header"
        );
        if (typeof parentObj !== "undefined" && parentObj !== null) {
          parentObj.forEach((item) => {
            item.style.setProperty("background-color", "#DFDFDF", "important");
          });
        }
        const parentObj2 = document.querySelectorAll(
          ".changeObjBackColor .e-acrdn-header .e-acrdn-header-content"
        );
        if (typeof parentObj2 !== "undefined" && parentObj2 !== null) {
          parentObj2.forEach((parentObj2Item) => {
            if (parentObj2Item.children.length > 0)
              parentObj2Item.children[0].style.setProperty("color", "black", "important");
            else parentObj2Item.style.setProperty("color", "black", "important");
          });
        }
        const parentObj3 = document.querySelectorAll(
          ".changeObjBackColor .e-acrdn-header .e-toggle-icon"
        );
        if (typeof parentObj3 !== "undefined" && parentObj3 !== null) {
          parentObj3.forEach((parentObj3Item) => {
            if (parentObj3Item.children.length > 0) {
              parentObj3Item.children[0].style.setProperty("color", "black", "important");
            }
            else {
              parentObj3Item.style.setProperty("color", "black", "important");
            }
          });
        }

        const parentObj4 = document.querySelectorAll(".refreshUIRichextBox");
        if (typeof parentObj4 !== "undefined" && parentObj4 !== null) {
          parentObj4.forEach((item) => {
            item.ej2_instances[0].refreshUI();
          });
        }
      }, pTimeoutSec);
    } else {
      setTimeout(() => {
        const parentObj = document.querySelectorAll(
          ".changeKPIBackColor .e-acrdn-header"
        );
        if (typeof parentObj !== "undefined" && parentObj !== null) {
          parentObj.forEach((item) => {
            item.style.setProperty("background-color", "#F8F8FA", "important");
            item.style.setProperty("color", "black", "important");
          });
        }

        const parentObj2 = document.querySelectorAll(
          ".changeKPIBackColor .e-acrdn-header .e-acrdn-header-content"
        );
        if (typeof parentObj2 !== "undefined" && parentObj2 !== null) {
          parentObj2.forEach((item) => {
            item.style.setProperty("color", "black", "important");
          });
        }

        const parentObj4 = document.querySelectorAll(".refreshUIRichextBox");
        if (typeof parentObj4 !== "undefined" && parentObj4 !== null) {
          parentObj4.forEach((item) => {
            item.ej2_instances[0].refreshUI();
          });
        }
      }, pTimeoutSec);
    }
  } catch (error) {
    // eslint-disable-next-line
    console.log(error, "changeAccordianColor");
  }
};
export const setRitchTextBoxHeight = (args, id) => {
  if (args.item.command === "Images" || args.item.command === "Links") {
    document.getElementById(id).ej2_instances[0].height = 450;
  }
};

export const refreshRitchTextBox = (args, id) => {
  setTimeout(() => {
    if (document.getElementById(id) !== null)
      document.getElementById(id).ej2_instances[0].refreshUI();
  }, 500);
};

export const secToTime = (sec) => {
  if (typeof sec !== "undefined") {
    const sec_num = parseInt(sec, 10);
    let hours = Math.floor(sec_num / 3600);
    let minutes = Math.floor((sec_num - hours * 3600) / 60);

    if (hours < 10) {
      hours = `0${hours}`;
    }
    if (minutes < 10) {
      minutes = `0${minutes}`;
    }
    if (hours > 0 || minutes > 0) return `${hours}:${minutes}`;
    return "";
  }
  return "";
};

export const workDayObjCheck = (a, b) => {
  // eslint-disable-next-line
  const idsA = a.map(function (x) {
    return x.calendarDate + x.startTime + x.endTime;
  });
  // eslint-disable-next-line
  const idsB = b.map(function (x) {
    return x.calendarDate + x.startTime + x.endTime;
  });
  return idsA.join(",") === idsB.join(",");
};

export const matchSimilarity = async (string, objResult) => {
  const modifiedObjResult = [];
  let compareResult = 0;
  if (objResult.length > 0) {
    await objResult.forEach(async (ele) => {
      compareResult = 0;
      compareResult = await stringSimilarity.compareTwoStrings(
        string,
        ele.objectiveName
      );
      compareResult *= 100;
      if (compareResult > 50) {
        // eslint-disable-next-line
        ele.matchValue = compareResult;
        modifiedObjResult.push(ele);
      }
    });
    // eslint-disable-next-line
    modifiedObjResult.sort(function (a, b) {
      return b.matchValue - a.matchValue;
    });
  }
  return modifiedObjResult;
};
export const getTimeZoneWiseTime = async (dtFrm, dtTo) => { // NOSONAR
  var istFrmDt = '';
  var ukFrmDt = '';
  var cetFrmDt = '';
  var sgtFrmDt = '';
  var ausFrmDt = '';
  var estFrmDt = '';
  var cstFrmDt = '';
  var mstFrmDt = '';
  var pstFrmDt = '';
  var uaeFrmDt = '';
  if (dtTo === 'IST' || dtTo === 55) {
    istFrmDt = new Date(dtFrm.toLocaleString("en-US", { timeZone: "Asia/Kolkata" }));
    return istFrmDt;
  }
  if (dtTo === 'UK' || dtTo === 56) {
    ukFrmDt = new Date(dtFrm.toLocaleString("en-US", { timeZone: "Europe/London" }));
    return ukFrmDt;
  }
  if (dtTo === 'CET' || dtTo === 57) {
    cetFrmDt = new Date(dtFrm.toLocaleString("en-US", { timeZone: "Europe/Paris" }));
    return cetFrmDt;
  }
  if (dtTo === 'SGT' || dtTo === 58) {
    sgtFrmDt = new Date(dtFrm.toLocaleString("en-US", { timeZone: "Singapore" }));
    return sgtFrmDt;
  }
  if (dtTo === 'AUS' || dtTo === 60) {
    ausFrmDt = new Date(dtFrm.toLocaleString("en-US", { timeZone: "Australia/Sydney" }));
    return ausFrmDt;
  }
  if (dtTo === 'EST' || dtTo === 62) {
    estFrmDt = new Date(dtFrm.toLocaleString("en-US", { timeZone: "America/New_York" }));
    return estFrmDt;
  }
  if (dtTo === 'CST' || dtTo === 63) {
    cstFrmDt = new Date(dtFrm.toLocaleString("en-US", { timeZone: "America/Costa_Rica" }));
    return cstFrmDt;
  }
  if (dtTo === 'MST' || dtTo === 64) {
    mstFrmDt = new Date(dtFrm.toLocaleString("en-US", { timeZone: "America/Denver" }));
    return mstFrmDt;
  }
  if (dtTo === 'PST' || dtTo === 65) {
    pstFrmDt = new Date(dtFrm.toLocaleString("en-US", { timeZone: "America/Los_Angeles" }));
    return pstFrmDt;
  }
  if (dtTo === 'UAE' || dtTo === 67) {
    uaeFrmDt = new Date(dtFrm.toLocaleString("en-US", { timeZone: "Asia/Dubai" }));
    return uaeFrmDt;
  }
};

export const getFinancialYearDate = (month, fYear) => {
  let year = "";
  if (3 < month) {
    year = fYear.slice(0, 4);

  } else {
    year = fYear.slice(7, 13);
  }
  return new Date(`${month}-01-${year}`);
};

export const monthDiff = (d1, d2) => {
  var months;
  months = (d2.getFullYear() - d1.getFullYear()) * 12;
  months -= d1.getMonth();
  months += d2.getMonth();
  return months <= 0 ? 0 : months;
};
export const removeHashFromTags = (array) => {
  let newArray = [];
  // eslint-disable-next-line
  array.map((item, inx) => {
    if (item !== "") {
      // eslint-disable-next-line
      newArray.push(item.replace(/\#/g, ""));
    }
  })
  return newArray;
}
export const benchmarkFormatData = (str) => /**nosonar**/ {
  let finalData = [];
  if (typeof str !== 'undefined' && str !== null) {
    let comaSepData = str.split('$$');
    //eslint-disable-next-line
    comaSepData.map((item) => {
      let underSepData = item.split('_');
      let obj = {};
      if (underSepData[1] !== '1') {
        let from = underSepData[2] === '' ? 0 : underSepData[2];
        let to = underSepData[3] === '' ? 0 : underSepData[3];
        obj = { Id: parseInt(underSepData[0]), Type: underSepData[1], From: (from), To: (to), benchMarkForYes: underSepData[4], sortBy: underSepData[5], currency: underSepData[6] }
      } else {

        let fromDate = underSepData[2] === '' ? null : underSepData[2]
        let toDate = underSepData[3] === '' ? null : underSepData[3]
        obj = { Id: parseInt(underSepData[0]), Type: underSepData[1], From: fromDate, To: toDate, benchMarkForYes: underSepData[4], sortBy: underSepData[5], currency: underSepData[6] }
      }
      finalData.push(obj);
    })
  }
  return finalData;
}
export const validateKRTotalWeightage = (KRData) => {
  if (KRData.length > 0) {
    let returnData = true;
    //eslint-disable-next-line
    KRData.map((item) => {
      if (item.weightage === null || item.scopeTypeId === 0) {
        returnData = false;
      }
    })
    return returnData;
  }
}
export const getAttendeeString = (str) => {
  let finalString = "";
  str = str.split(',');
  str.forEach(
    // eslint-disable-next-line
    (item) =>
    (finalString = `${finalString + item.split("_")[0]}_${1
      }_${item.split("_")[2]}$$`)
  )

  return finalString;
}
export const TIBDateFormat = (date) => {
  var d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2)
    month = '0' + month;
  if (day.length < 2)
    day = '0' + day;

  return [year, month, day].join('-');
}

export const FrequencyItemTemplate = (data) => {
  return (
    <div style={{ marginLeft: 0, marginRight: 0, zIndex: 99999, fontWeight: (data.isBold === "Y" ? 'bold' : 'normal') }}>
      <div style={{ tabSize: 2 }}> {
        Array(data.spaces).fill().map(element => '\u00A0')
      } {data.Name} </div>
    </div>
  );
};

export const getMySqlDateFormat = (inDateTime) => {
  let dateTime = new Date(inDateTime);
  let year = dateTime.getFullYear();
  let month = dateTime.getMonth() + 1;
  let date = dateTime.getDate()

  return `${year}-${month}-${date} 00:00:00` //output 2020-12-12 00:00:00
}

export function calcBusinessDays(startDate, endDate) {
  const firstDate = new Date(startDate);
  const secondDate = new Date(endDate);
  const daysWithOutWeekEnd = [];
  for (var currentDate = new Date(firstDate); /**nosonar**/ currentDate <= secondDate; currentDate.setDate(currentDate.getDate() + 1)) {
    if (currentDate.getDay() !== 0 && currentDate.getDay() !== 6) {
      daysWithOutWeekEnd.push(`${new Date(currentDate).getFullYear()}-${new Date(currentDate).getMonth() + 1 < 10 ? /**nosonar**/ `0${new Date(currentDate).getMonth() + 1}` : `${new Date(currentDate).getMonth() + 1}`}-${new Date(currentDate).getDate()}`);
    }
  }
  return daysWithOutWeekEnd;
}

export const generateZoomObject = async (zoomStartDateTime, zoomEndDateTime, timeZoneId, zoomMeetingTitle, type, recurringObj, hostEmail, zoomRecurring) =>/**nosonar**/ {
  let data = {}
  let result = await dataService.getZoomMeeting(zoomStartDateTime, zoomEndDateTime, timeZoneId, zoomMeetingTitle.replace(/[^\w\s]/gi, '').toString(), type, recurringObj, hostEmail, zoomRecurring);
  if (result !== null) {
    if (result.id) {
      data = { data: result }
    } else if (result.notFoundError) {
      data = { error: result.notFoundError }
    } else if (result.paramError) {
      data = { error: result.paramError }

    }
  }
  return data
}
//input 2020-12-12 06:30:30
//output 2020-12-12 06:30:00
export const removeExtraSeconds = (dateTimeStr) => {
  let dateTimeArray = dateTimeStr.split(':');
  let dtstr /**nosonar**/ = `${dateTimeArray[0]}:${dateTimeArray[1]}:00`;
  return `${dateTimeArray[0]}:${dateTimeArray[1]}:00`;
}
export const leaveDateFormat = (dateTime) => {
  let newDate = /**nosonar**/ `${new Date(dateTime).getFullYear()}-${new Date(dateTime).getMonth() + 1 < 10 ? `0${new Date(dateTime).getMonth() + 1}` : `${new Date(dateTime).getMonth() + 1}`}-${new Date(dateTime).getDate() >= 10 ? new Date(dateTime).getDate() : `0${new Date(dateTime).getDate()}`}`
  return newDate;
}


export const validateLeaveDates = (nos, date, type) => {
  return addDays(new Date(date), nos)
}


export const refreshMultiSelect = (pDivId, pComponentId) => {
  try {
    var children = document.querySelectorAll(`div#${pDivId} div.e-multiselect span.e-ddl-icon`)
    if (children) {
      if (children.length === 2) {
        var tmpTexts = []
        for (const c of children) {
          if (tmpTexts.includes(c.innerText)) continue
          tmpTexts.push(c.innerText)
          c.parentNode.removeChild(c)
        }

        setTimeout(() => {
          if (document.getElementById(pComponentId))
            document.getElementById(pComponentId).ej2_instances[0].refresh()
        }, 100)
      }
    }

  } catch (error) {
    console.log("🚀 ~ file: timeinbusiness.js ~ line 433 ~ TimeInBusiness ~ created ~ error", error)
  }
}

export const getMonthYearFromDate = (d) => {
  return (`${d.toLocaleString("default", { month: "short" })}-${d.getFullYear().toString().substr(-2)}`)
};


/**
   * Created on 08-09-2022 || Noel Fredrick
   * Description - For spinner
   */
export const spinner = (spin, pComponentId) => {
  try {
    if (document.getElementById(`div#${pComponentId}`)) {
      if (spin)
        showSpinner(document.getElementById(`div#${pComponentId}`));
      else
        setTimeout(() => {
          hideSpinner(document.getElementById(`div#${pComponentId}`));
        }, 3000)
    }
  } catch (error) {
    console.log(error)
  }
}

export const getOKRQuestionRatingData = (id) => {
  return (OKRQuestionRatingData.map((item) => {
    if (item.Id === id) {
      return item.Name
    }
  }))
}

export const getQuestionRatingName = (Id) => {
  let name;
  OKRQuestionRatingData?.forEach((item) => {
    if (item.Id === Id) {
      name = item.Name;
    }
  })
  return name;
}
export const extractUserIdsFromTreeDropdown = (data) => {
  let userIds = [];
  try {
    data?.forEach((item) => {
      if (item.split('-').length === 4) {
        let c = item.split('-')
        userIds.push(c[3]);
      }
    })
    return userIds.toString();
  } catch (e) {
  }
};
export const getTimeFormatForLnaMAster = (time) => {
  const { minHrs } = time;
  return (
    !time || !minHrs ? <div></div> :
      <div style={{ display: "flex", justifyContent: "center" }}>
        <p>{minHrs.split(" ")[1].split(":").slice(0, 2).join(":")}</p>
      </div>
  );
}

export const removeTralingSpaces = (str) => {
  try {
    return str
      .replace(/^\s+/g, '')
      .replace(/\s+/g, ' ')
  } catch (error) {
    console.log("🚀 ~ file: commonfunction.js:853 ~ removeTralingSpaces ~ error:", error)
  }
};

/**
   * Created on 27-03-2023
   * Author: Md Jahir Uddin
   * Description: Call this function to encode a string to base64
   * @param {string}: The string to encode
   */
export const getBase64Encoded = (param) => Buffer.from(param).toString('base64')
/**
   * Created on 18-04-2023
   * Author: ML Vivek Khodade
   * Description: call this function helpdesk authentication.
   */
export const getHelpDeskCred = () => {
  return `${decodeString(localStorage.getItem('helpdeskUserName'))}:${decodeString(localStorage.getItem('helpdeskUserPass'))}`
}


//A function to detect if the content is empty
export function isContentEmpty(text) {
  String.prototype.replaceAll = function (search, replacement) {
    let target = this;
    return target.replace(new RegExp(search, 'g'), replacement);
  };
  text = text.replaceAll("&nbsp;", "").
    replaceAll(" ", "").
    replaceAll("<p>", "").
    replaceAll("</p>", "").trim();

  return (text.length == 0) ? true : false;

}
//A function to detect if the content is empty
export function getActualContent(text) {
  String.prototype.replaceAll = function (search, replacement) {
    let target = this;
    return target.replace(new RegExp(search, 'g'), replacement);
  };
  text = text.replaceAll("&nbsp;", "").
    replaceAll(" ", "").
    replaceAll("<p>", "").
    replaceAll("</p>", "").trim();

  return text.length;
}

export const getTimezoneFormattedTime = (time) => {
  let timeTemp = null;
  if (!time) {
    timeTemp = "-";
  }
  if (timeTemp !== "-") {
    let tempFormatWithTimezone = format(
      new Date(time),
      "dd-LLL-yyyy; hh:mm a zzzz"
    );
    let length = tempFormatWithTimezone.length;
    let timezoneText = tempFormatWithTimezone.slice(length - 9);
    tempFormatWithTimezone = tempFormatWithTimezone.replace(
      timezoneText,
      timeZoneNames[timezoneText]
    );
    timeTemp = tempFormatWithTimezone;
  }
  return timeTemp;
};
export const formatDateToYYYYMMDD = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based, so add 1
  const day = String(date.getDate()).padStart(2, '0');

  return `${year}${month}${day}`;
}
export const handleTimeCoversion = (time) => {
  const timeString = time
  const currentDate = new Date();
  const [hours, minutes] = timeString.split(':').map(Number);
  currentDate.setHours(hours, minutes, 0, 0);
  return currentDate
}

export const exctactTime = (time) => {
  const dateTime = new Date(time);
  const hour = dateTime.getHours().toString().padStart(2, '0');
  const minute = dateTime.getMinutes().toString().padStart(2, '0');
  const second = dateTime.getSeconds().toString().padStart(2, '0')
  return `${hour}:${minute}:${second}`
}

/**
 *@author: Somesh Dwivedi  
 *@description : To generate excell file
 */
export const generateExcelFile = (data, filename, sheetName = [], multiFileDownLoad = false) => {
  const wb = xlsx.utils.book_new();
  if (!multiFileDownLoad) {
    data.forEach((csvData, index) => {
      if (index === 0) {
        const ws = xlsx.utils.json_to_sheet(csvData);
        xlsx.utils.book_append_sheet(wb, ws, '');
      }
    });
  }
  else {
    data.forEach((csvData, index) => {
      const ws = xlsx.utils.json_to_sheet(csvData);
      xlsx.utils.book_append_sheet(wb, ws, sheetName && sheetName.length > index ? sheetName[index] : '');
    });
  }
  xlsx.writeFile(wb, `${filename}.xlsx`);
};

export const generateTooltipTitle = (dayBreakupArr, dayBreakupId) => {
  if (dayBreakupArr) {
    let selectedBreakup = dayBreakupArr.find(ele => { return ele.Id === dayBreakupId })
    if (selectedBreakup) return selectedBreakup.name
    else return ''
  }
  else return ''
}

/**
 *@author: Anurag Gurjar  
 *@description : To get the day name from the date  Eg: Monday, 25 Dec 2023
 */
export const getDateWithDayName = (dateString) => {
  const dateObject = new Date(dateString);
  dateObject.setUTCHours(0, 0, 0, 0);
  const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
  const dayOfWeekIndex = dateObject.getUTCDay();
  const dayName = daysOfWeek[dayOfWeekIndex];
  const result = `${dayName}, ${dateObject.getUTCDate()} ${dateObject.toLocaleDateString('en-US', { month: 'short', year: 'numeric', timeZone: 'UTC' })}`;
  return result;
}

/**
 *@author: Anurag Gurjar  
 *@description : To get the date format Eg-: 2023-12-25 >>  25-Dec-2023 
 */
export const formatDateToDDMMMYYYY = (inputDate) => {
  const date = new Date(inputDate);

  // Check if the date is valid
  if (isNaN(date.getTime())) {
    throw new Error('Invalid date format. Please provide a valid date.');
  }

  const formattedDay = ('0' + date.getUTCDate()).slice(-2);
  const formattedMonth = new Intl.DateTimeFormat('en-US', { month: 'short', timeZone: 'UTC' }).format(date);
  const formattedYear = date.getUTCFullYear();

  // Ensure the month is in title case (e.g., Jan, Feb, Mar)
  const capitalizedMonth = formattedMonth.charAt(0).toUpperCase() + formattedMonth.slice(1).toLowerCase();

  return `${formattedDay}-${capitalizedMonth}-${formattedYear}`;
};

export const getBackGroundColor = (
  dayBreakNo,
  dayBreakType,
  attendenceColour,
  leaveColour,
  dayBreakType2,
  dayBreakNo2,
  attendenceColour2,
  leaveColour2,
  type1
) => {

  /**NOSONAR */
  let colorArray = [];
  let day = dayBreakNo;
  if (dayBreakNo == null) {
    day = dayBreakNo2
    // dayBreakType=dayBreakType2
  }
  switch (day) {
    case 1:
      if (leaveColour === null) {
        colorArray[0] = attendenceColour;
        colorArray[1] = attendenceColour;
        colorArray[2] = attendenceColour;
        colorArray[3] = attendenceColour;
        return colorArray;
      } else {
        colorArray[0] = leaveColour;
        colorArray[1] = leaveColour;
        colorArray[2] = leaveColour;
        colorArray[3] = leaveColour;
        return colorArray;
      }
    case 0.5:
      if (dayBreakType === null) {
        if (dayBreakType2 === "2" && leaveColour === null) {
          colorArray[0] = "white";
          colorArray[1] = "white";
          colorArray[2] = attendenceColour;
          colorArray[3] = attendenceColour;
          return colorArray;
        } else if (dayBreakType2 === "2" && leaveColour !== null) {
          colorArray[0] = attendenceColour;
          colorArray[1] = attendenceColour;
          colorArray[2] = leaveColour;
          colorArray[3] = leaveColour;
          return colorArray;
        } else {
          colorArray[0] = attendenceColour;
          colorArray[1] = attendenceColour;
          colorArray[2] = leaveColour;
          colorArray[3] = leaveColour;
          return colorArray;
        }
      }
      else {
        if (dayBreakType === "2" && leaveColour === null) {
          colorArray[0] = "white";
          colorArray[1] = "white";
          colorArray[2] = attendenceColour;
          colorArray[3] = attendenceColour;
          return colorArray;
        }
        else if (dayBreakType === "2" && leaveColour !== null && attendenceColour !== null) {
          colorArray[0] = leaveColour;
          colorArray[1] = leaveColour;
          colorArray[2] = attendenceColour;
          colorArray[3] = attendenceColour;
          return colorArray;
        }
        else if (dayBreakType === "1" && leaveColour === null && leaveColour2 === null && attendenceColour2 === null && attendenceColour !== null) {
          colorArray[0] = attendenceColour;
          colorArray[1] = attendenceColour;
          colorArray[2] = "white";
          colorArray[3] = "white";
          return colorArray;
        }
        else if (dayBreakType === "1" && leaveColour !== null && attendenceColour === null && attendenceColour2 === null && leaveColour2 === null) {
          colorArray[0] = leaveColour;
          colorArray[1] = leaveColour;
          colorArray[2] = "white";
          colorArray[3] = "white";
        }
        else if (dayBreakType === "1" && leaveColour === null && leaveColour2 === null && attendenceColour !== null && attendenceColour2 !== null) {
          colorArray[0] = attendenceColour;
          colorArray[1] = attendenceColour;
          colorArray[2] = attendenceColour2;
          colorArray[3] = attendenceColour2;
        }
        else if (dayBreakType === "1" && leaveColour !== null && leaveColour2 !== null && attendenceColour === null && attendenceColour2 === null) {
          colorArray[0] = leaveColour;
          colorArray[1] = leaveColour;
          colorArray[2] = leaveColour2;
          colorArray[3] = leaveColour2;
        }
        else {
          if (type1 === 'Attendance') {
            colorArray[0] = attendenceColour;
            colorArray[1] = attendenceColour;
            colorArray[2] = leaveColour;
            colorArray[3] = leaveColour;
          } else {
            colorArray[0] = leaveColour;
            colorArray[1] = leaveColour;
            colorArray[2] = attendenceColour;
            colorArray[3] = attendenceColour;
          }
          return colorArray;
        }
      }

    case 0.75:
      if (leaveColour !== null) {
        if (dayBreakType === null) {
          if (dayBreakType2 === "3") {
            colorArray[0] = attendenceColour;
            colorArray[1] = attendenceColour;
            colorArray[2] = leaveColour;
            colorArray[3] = attendenceColour;
          }
          else if (dayBreakType2 === "4") {
            colorArray[0] = attendenceColour;
            colorArray[1] = attendenceColour;
            colorArray[2] = attendenceColour;
            colorArray[3] = leaveColour;
          }
          return colorArray;
        } else {
          if (dayBreakType === "1") {
            if (dayBreakType2 === "3") {
              colorArray[0] = attendenceColour;
              colorArray[1] = attendenceColour;
              colorArray[2] = leaveColour;
              colorArray[3] = attendenceColour;
            } if (dayBreakType2 === "4") {
              colorArray[0] = attendenceColour;
              colorArray[1] = attendenceColour;
              colorArray[2] = attendenceColour;
              colorArray[3] = leaveColour;
            }
            return colorArray;
          }
          if (dayBreakType === "2") {
            if (dayBreakType2 === '1') {
              colorArray[0] = leaveColour;
              colorArray[1] = attendenceColour;
              colorArray[2] = attendenceColour;
              colorArray[3] = attendenceColour;
            } else if (dayBreakType2 === '2') {
              colorArray[0] = attendenceColour;
              colorArray[1] = leaveColour;
              colorArray[2] = attendenceColour;
              colorArray[3] = attendenceColour;
            }
            return colorArray;
          }
        }
      } else {
        if (dayBreakType === "1") {
          if (dayBreakType2 === "3") {
            colorArray[0] = attendenceColour;
            colorArray[1] = attendenceColour;
            colorArray[2] = "white";
            colorArray[3] = attendenceColour;
          } if (dayBreakType2 === "4") {
            colorArray[0] = attendenceColour;
            colorArray[1] = attendenceColour;
            colorArray[2] = attendenceColour;
            colorArray[3] = "white";
          }
          return colorArray;
        }
        if (dayBreakType === "2") {
          if (dayBreakType2 === '1') {
            colorArray[0] = "white";
            colorArray[1] = attendenceColour;
            colorArray[2] = attendenceColour;
            colorArray[3] = attendenceColour;
          } else if (dayBreakType2 === '2') {
            colorArray[0] = attendenceColour;
            colorArray[1] = "white";
            colorArray[2] = attendenceColour;
            colorArray[3] = attendenceColour;
          }
          return colorArray;
        }
      }
    case 0.25: // NOSONAR                 
      if (leaveColour !== null) {
        if (dayBreakType === "1") {
          colorArray[0] = leaveColour;
          colorArray[1] = attendenceColour;
          colorArray[2] = attendenceColour;
          colorArray[3] = attendenceColour;
          return colorArray;
        }
        if (dayBreakType === "2") {
          colorArray[0] = attendenceColour;
          colorArray[1] = leaveColour;
          colorArray[2] = attendenceColour;
          colorArray[3] = attendenceColour;
          return colorArray;
        }
        if (dayBreakType === "3") {
          colorArray[0] = attendenceColour;
          colorArray[1] = attendenceColour;
          colorArray[2] = leaveColour;
          colorArray[3] = attendenceColour;
          return colorArray;
        }
        if (dayBreakType === "4") {
          colorArray[0] = attendenceColour;
          colorArray[1] = attendenceColour;
          colorArray[2] = attendenceColour;
          colorArray[3] = leaveColour;
          return colorArray;
        }
      } else {
        if (dayBreakType === "1") {
          colorArray[0] = "white";
          colorArray[1] = attendenceColour;
          colorArray[2] = attendenceColour;
          colorArray[3] = attendenceColour;
          return colorArray;
        }
        if (dayBreakType === "2") {
          colorArray[0] = attendenceColour;
          colorArray[1] = "white";
          colorArray[2] = attendenceColour;
          colorArray[3] = attendenceColour;
          return colorArray;
        }
        if (dayBreakType === "3") {
          colorArray[0] = attendenceColour;
          colorArray[1] = attendenceColour;
          colorArray[2] = "white";
          colorArray[3] = attendenceColour;
          return colorArray;
        }
        if (dayBreakType === "4") {
          colorArray[0] = attendenceColour;
          colorArray[1] = attendenceColour;
          colorArray[2] = attendenceColour;
          colorArray[3] = "white";
          return colorArray;
        }
      }
      break;

    default:
      colorArray[0] = "white";
      colorArray[1] = "white";
      colorArray[2] = "white";
      colorArray[3] = "white";
      return colorArray;
  }
};


export const decryptMethod = function (cipherText) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const plain = atob(cipherText);
  //atob(cipherText)
  const bytes = CryptoJS.AES.decrypt(plain, process.env.REACT_APP_SECRET_KEY);
  return bytes.toString(CryptoJS.enc.Utf8);
};


export const encryptMethod = (plainText) => {
  const cipherText = CryptoJS.AES.encrypt(plainText, process.env.REACT_APP_SECRET_KEY).toString();
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return btoa(cipherText);
};

export const isNameUnique=(arr, name)=>{
  for (let obj of arr) {
      if (obj.name === name) {
          return false;
      }
  }
  return true;
}
export const validateFileName = (filename) => {
  const dotIndex = filename.lastIndexOf('.');
  const baseName = dotIndex === -1 ? filename : filename.slice(0, dotIndex);

  const validCharacters = /^[a-zA-Z0-9\-_ ]+$/;

  if (baseName.length > MAX_FILE_NAME || baseName.length < MIN_FILE_NAME) {
      return FILE_NAME_ERROR;
  }

  if (!validCharacters.test(baseName)) {
      return INVALID_FILE_NAME;
  }

  return null;
};