import axios from "axios";
import { urlConfig } from "../config/config";

function errorHandler(reject, error) {
    // eslint-disable-next-line
    console.dir(error);
    reject("operation failed");
}

function getEfficiencyReportData(authUser, pMonthYear, pSDPAID, pEmpId) {
    return new Promise(function onThen(resolve, reject) {
        axios
            .get(
                `${urlConfig.processUrl}ec/v1/ecreport/${authUser}/${pMonthYear}/${pSDPAID}/${pEmpId}`,
                {
                    headers: {
                        authorization: localStorage.getItem("jwtToken"),
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                }
            )
            // eslint-disable-next-line
            .then(function (response) {
                if (response != null) {
                    return resolve(response.data.result);
                }
            })
            // eslint-disable-next-line
            .catch(function (error) {
                errorHandler(reject, error);
                return null;
            });
    });
}

function getReportMasterData(authUser, rType) {
    return new Promise(function onThen(resolve, reject) {
        axios
            .get(
                `${urlConfig.apiurl}reportmasters/${authUser}/${rType}`,
                {
                    headers: {
                        authorization: localStorage.getItem("jwtToken"),
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                }
            )
            // eslint-disable-next-line
            .then(function (getReportMasterDataResponse) {
                if (getReportMasterDataResponse != null) {
                    return resolve(getReportMasterDataResponse.data.result);
                }
            })
            // eslint-disable-next-line
            .catch(function (error) {
                errorHandler(reject, error);
                return null;
            });
    });
}
function getUtilizationReportData(authUser, pMonthYear, pSDPAID, pviewType, pEmpId, utilisationSelectedDataField) {
    return new Promise(function onThen(resolve, reject) {
        axios
            .get(
                `${urlConfig.processUrl}ec/v1/utilizationreport/${authUser}/${pMonthYear}/${pSDPAID}/${pviewType}/${pEmpId}/${utilisationSelectedDataField}`,
                {
                    headers: {
                        authorization: localStorage.getItem("jwtToken"),
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                }
            )
            // eslint-disable-next-line
            .then(function (response) {
                if (response != null) {
                    return resolve(response.data.result);
                }
            })
            // eslint-disable-next-line
            .catch(function (error) {
                errorHandler(reject, error);
                return null;
            });
    });
}

const reportService = {
    getEfficiencyReportData,
    getReportMasterData,
    getUtilizationReportData
};

export default reportService;
