
import React, { Component } from "react";
import { connect } from "react-redux";
import { Button } from "@material-ui/core";
import ReactHtmlParser from 'react-html-parser'

import "./helpdeskservice.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

class TopTicketPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // height: null,
      // topHeight: null,
      // contentHeight: null
      savedata: "",
      height:
        (window.innerHeight ||
          document.documentElement.clientHeight ||
          document.body.clientHeight) - 274,
    };
    this.clickChild = null;//nosonar
  }


  handleCancel = () => {
    this.props.changePopUpHandler();
  }
  render() { //nosonar

    const isSixteenHundred = window.innerHeight < 750;
    const isOneTwentyFiveScale = window.innerHeight < 715;
    const isThirteenHundred = window.innerHeight < 618;
    const isOneTwentyFiveInSixteenHundred = window.innerHeight < 571;
    const isOneTwentyFiveInFourteenHundred = window.innerHeight < 571 && window.innerWidth < 1153;
    const isOneTwentyFiveInThirteenHundred = window.innerHeight < 465;

    return (
      <>
        <div
          id={`Ticket Form`}
          className={"popup-matrix-flex"}
          style={{
            width: isOneTwentyFiveInThirteenHundred ? "100vw" : isOneTwentyFiveInFourteenHundred ? "90vw" : //nosonar
              isOneTwentyFiveInSixteenHundred ? "80vw" : isThirteenHundred ? "70vw" : //nosonar
                isOneTwentyFiveScale || isSixteenHundred ? "60vw" : "50vw" //nosonar
          }}
        >

          <div >
            <div className="helpdesk-service-header">
            
                <FontAwesomeIcon
                  icon={faArrowLeft}
                  className="mt-sm back-icon"
                  style={{ cursor: "pointer",fontWeight:"600",fontSize:"20px" }}
                  onClick={this.handleCancel} 
                />
            </div>
          </div>


          <div className={"other-modal-scroll"}>
            <div className="col-xs-12 col-sm-12 col-lg-12 mt-sm mb-sm tooltip-header" >
              Try the following steps:
            </div>
            {
                ReactHtmlParser(this.props.resolution)
              // tooltipData.map((data, index) => (
              //   <div className="col-xs-12 col-sm-12 col-lg-12 mt-sm mb-sm" style={{ display: "flex", flexDirection: "row" }} >
              //     <div className='tooltip-header'>Step {index + 1}:&nbsp; </div> <div className='tooltip-value'>{data.value}</div>
              //   </div>
              // ))
            }


          </div>


        </div>

        {/* <div>
          <NotificationContainer style={{ position: "absolute" }} />
        </div> */}

      </>
    );
  }
}

const mapStateToProps = ({ auth, meetingcategory, meetingmaster }) => {
  const { authUser } = auth;
  const { categoryName } = meetingcategory;
  const { financialYearData } = meetingmaster;
  return {
    categoryName,
    financialYearData,
    authUser
  };
};
export default connect(mapStateToProps)(TopTicketPopup);
