import React from 'react'

function LeaveRules() {
    return (<div className='tooltip-content-lna arrow-right' style={{ display: "flex", flexDirection: "column" }}>
    <div style={{ margin: "16px" }}>
      <table className="table table-hover table-bordered" >
        <thead>
          <tr className="table-odd ">
            <td><b>Planned Leave Application Rule  (PL)</b></td>
          </tr>
          <tr className=" table-even">
            <td>
              <div className="col-xs-12 col-sm-12 col-lg-12 tooltip-desc" >
                * If the leave period is between <b>0.5</b> to <b>2</b> days then you will require to apply 2 days before.
              </div>
              <div className="col-xs-12 col-sm-12 col-lg-12  tooltip-desc " >
                * If the leave period is greater than  <b>2.5</b> then you will require to apply <b>7</b> days before.
              </div>
            </td>
          </tr>

          <tr className="table-odd">
            <td><b>Exigency Leave Application Rule (EL)</b></td>
          </tr>
          <tr className=" table-even">
            <td>
              <div className="col-xs-12 col-sm-12 col-lg-12  tooltip-desc" >
                * Team member needs to apply EL within <b>7</b>-calendar days from the date of leave OR the last day of the calendar month, whichever is earlier.
              </div>
            </td>
          </tr>

          <tr className="table-odd">
            <td><b>Quarter-Day Leave Application Rule (QL)</b></td>
          </tr>
          <tr className=" table-even">
            <td>
              <div className="col-xs-12 col-sm-12 col-lg-12  tooltip-desc" >
                *	Quarter-day Leave (QL) is a <b>2</b>-hours leave on any given working day available for our team-members
              </div>
            </td>
          </tr>
        </thead>
      </table>
    </div>


  </div>);
}

export default LeaveRules;