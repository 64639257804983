// Author @ Amit Kumar
// Component for subbusinessunitfunction PopUp

import React, { Component } from "react";
import { ComboBoxComponent, AutoCompleteComponent } from "@syncfusion/ej2-react-dropdowns";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { FormValidator } from "@syncfusion/ej2-inputs";
import { SwitchComponent } from '@syncfusion/ej2-react-buttons';
import { Query, Predicate } from '@syncfusion/ej2-data';
import { pleaseEnterSubBusinessUnitFunctionName, pleaseSetHierarchy, pleaseSetParentLayer, pleaseselectsubpnlhead } from '../../../../assets/constants/constants'

class Subbusinessunitfunctionpopup extends Component {

  constructor(props) {
    super(props)
    this.state = {
      result: [],
      businessUnit: [],
      hierarchyData: [],
      businessUnitFunctionId: null,
      subBusinessUnitFunctionId: 0,
      subBusinessUnitFunctionName: '',
      hierarchy: null,
      maxHierarchy: '',
      isActingHead: '0',
      subPnlFuncHead: '',
      parent: '0',
      subBusinessPnl: [],
    }
    this.subPnlFuncHeadTitle = {};
  }

  onchangeSubBusinessUnit(e, type) {
    let options = {};
    switch (type) {
      case "SubBusinessUnit":
        this.setState({
          subBusinessUnitFunctionName: e.value
        });
        options = {
          rules: {
            subBusinessUnitErr: {
              required: [true, pleaseEnterSubBusinessUnitFunctionName],
            },
          },
        };
        break;
      case "Hierarchy":
        this.setState({
          hierarchy: e.value
        })
        options = {
          rules: {
            hierarchyErr: {
              required: [true, pleaseSetHierarchy],
            },

          },
        };
        break;
      case "subPnlHead":
        this.setState({
          subPnlFuncHead: e.itemData !== null ? e.itemData.UserId : ''
        })
        this.subPnlFuncHeadTitle.text = e.itemData.nameWithDepartment;
        options = {
          rules: {
            subPnlHeadErr: {
              required: [true, pleaseselectsubpnlhead],
            },

          },
        };
        break;
      case "isActingHead":
        this.setState({
          isActingHead: e.checked === true ? '1' : '0'
        })
        break;
      case "parent":
        this.setState({
          parent: e.checked === true ? '0' : +this.props.subBusinessUnitFunctionData.parent,
        })
        break;
      case "isParent":
        this.setState({
          parent: e.value,
        })
        break;
      default:
        break;
    }
    setTimeout(() => {
      const addFormObject = new FormValidator("#subBusinessUnitFunctionForm", options);
      addFormObject.validate();
    }, 100);

    const { Id, fkId } = this.props.subBusinessUnitFunctionData;
    const { subBusinessUnitFunctionName, hierarchy, subPnlFuncHead, isActingHead, parent } = this.state;
    let data = {
      subPnlFuncHead: subPnlFuncHead,
      businessUnitFunctionId: Id,
      subBusinessUnitFunctionId: fkId,
      subBusinessUnitFunctionName: subBusinessUnitFunctionName,
      hierarchy: hierarchy,
      isActingHead: isActingHead,
      parent: parent,
    }

    this.props.parentComponentfunction(data);
  }

  componentDidMount() {

    this.setStateData();
  }

  componentDidUpdate(prevProps, prevState) {
    try {
      if (prevState.subPnlFuncHead !== this.state.subPnlFuncHead) {
        if (this.subPnlFuncHeadTitle.dataSource.length > 0) {
          this.subPnlFuncHeadTitle.dataSource.forEach(user => {
            if (user.UserId === parseInt(this.state.subPnlFuncHead)) {
              this.subPnlFuncHeadTitle.text = user.nameWithDepartment
            }
          });
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  async setStateData() {
    const { Id, fkId, subBusinessUnitFunction, hierarchy, subPnlFuncHead, isActingHead, parent } = this.props.subBusinessUnitFunctionData;
    const { subBusinessFunctionMasterData } = this.props
    const { maxHierarchy } = this.props
    let hierarchyPeve = hierarchy === null ? 0 : hierarchy.toString();

    const BusinessUnit = subBusinessFunctionMasterData.result.filter((item) => {
      return item.Type === "businessUnitFunction"
    });
    const subBusinessPnl = subBusinessFunctionMasterData.result.filter(item =>
      item.Type === "subPnlFunction" && item.pnlId === Id.toString() && item.Id !== fkId);

    let hierarchyDS = []
    for (let i = 1; i <= maxHierarchy; i++) {
      hierarchyDS.push({ Id: i, Name: i.toString() })
    }

    this.setState({
      businessUnit: BusinessUnit,
      hierarchyData: hierarchyDS,
      businessUnitFunctionId: Id,
      subBusinessUnitFunctionId: fkId,
      subBusinessUnitFunctionName: subBusinessUnitFunction,
      hierarchy: hierarchyPeve,
      subPnlFuncHead: subPnlFuncHead,
      isActingHead: isActingHead,
      parent: parent,
      subBusinessPnl: subBusinessPnl,
    }, () => {
      let data = {
        businessUnitFunctionId: Id,
        subBusinessUnitFunctionId: fkId,
        subBusinessUnitFunctionName: subBusinessUnitFunction,
        hierarchy: hierarchy,
        subPnlFuncHead: subPnlFuncHead,
        isActingHead: isActingHead,
        parent: parent,
      }

      this.props.parentComponentfunction(data);
    })

  }

  handleFilteringByName = (e) => {
    e.preventDefaultAction = true;
    let predicate = new Predicate('nameWithDepartment', 'contains', e.text, true);
    predicate = predicate.or('UserId', 'contains', e.text, true);

    let query = new Query();
    //frame the query based on search string with filter type.
    query = (e.text != "") ? query.where(predicate) : query;

    //pass the filter data source, filter query to updateData method.
    e.updateData(this.props.othersCheckAvailablity, query);
  };

  render() {
    const { businessUnit, hierarchyData,
      businessUnitFunctionId,
      subBusinessUnitFunctionName, hierarchy, subPnlFuncHead,
      isActingHead, parent, subBusinessPnl } = this.state
    return (
      <div>
        <form id="subBusinessUnitFunctionForm">
          <div className="form-row">
            <div className="col-md-12" style={{ padding: '0px 0px 16px 0px' }}>
              {businessUnit.length > 0 ? <ComboBoxComponent
                id="EmpType"
                dataSource={businessUnit}
                fields={{ text: "Name", value: "Id" }}
                value={businessUnitFunctionId}
                data-msg-containerid="emptypeErr"
                name="emptypeErr"
                allowFiltering
                readonly={true}
                floatLabelType="Auto"
                cssClass="e-outline"
                placeholder="Business Unit / Group Function "

                change={(e) => this.onchangeSubBusinessUnit(e, "BusinessUnit")}
              /> : ''}
            </div>
          </div>

          <div className="row"  >
            <div className="col-md-6">
              <TextBoxComponent
                id="SubBusinessUnit"
                placeholder="Sub Business Unit / Function  *"
                floatLabelType="Auto"
                fields={{ text: "Name", value: "Id" }}
                value={subBusinessUnitFunctionName}
                data-msg-containerid="subBusinessUnitErr"
                name="subBusinessUnitErr"
                cssClass="e-outline"
                onChange={(e) => this.onchangeSubBusinessUnit(e, "SubBusinessUnit")}
                htmlAttributes={{ maxlength: 100 }}
              />
              <div
                id="subBusinessUnitErr"
                className="e-input-group e-float-icon-left error-div"
              />
            </div>
            <div className="col-md-6">
              {hierarchyData.length > 0 ? <ComboBoxComponent
                id="SubBusinessUnit"
                dataSource={hierarchyData}
                fields={{ text: "Name", value: "Name" }}
                value={hierarchy}
                data-msg-containerid="hierarchyErr"
                name="hierarchyErr"
                allowFiltering
                allowCustom={false}
                floatLabelType="Auto"
                cssClass="e-outline"
                placeholder="Hierarchy *"

                change={(e) => this.onchangeSubBusinessUnit(e, "Hierarchy")}
              /> : ''}
              <div
                id="hierarchyErr"
                className="e-input-group e-float-icon-left error-div"
              />
            </div>

          </div>
          <div className="row">
            {/* {subPnlFuncHead &&  */}
            <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">

              <AutoCompleteComponent
                id="SubBusinessUnit"
                ref={(autocomplete) => {
                  this.subPnlFuncHeadTitle = autocomplete;
                }}
                cssClass="e-outline"
                floatLabelType="Auto"
                value={subPnlFuncHead}
                fields={{ text: "nameWithDepartment", value: "UserId" }}
                placeholder="Sub Business Unit / Function  Head *"
                select={(e) => this.onchangeSubBusinessUnit(e, 'subPnlHead')}
                data-msg-containerid="subPnlHeadErr"
                ignoreCase={true}
                name='subPnlHeadErr'
                // noRecordsTemplate={this.noRecordsTemplate}
                filtering={this.handleFilteringByName}
                dataSource={this.props.othersCheckAvailablity}
              />
              <div
                id="subPnlHeadErr"
                className="e-input-group e-float-icon-left error-div"
              />
            </div>

            {/* } */}

            <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6" style={{ display: 'flex' }}>
              <div style={{ marginTop: 10, marginRight: 10, color: 'grey' }}>Is Acting Head:</div>
              <div className="atc-switch-heading-start">
                <div className=" atc-switch-inline " style={{ marginTop: 4 }}>
                  <div className="mr-md">No</div>
                  <div>
                    <SwitchComponent
                      cssClass="atc-switch-height"
                      change={(e) => this.onchangeSubBusinessUnit(e, 'isActingHead')}
                      checked={isActingHead === '1' ? true : false}

                    />
                  </div>
                  <div className="ml-md">Yes</div>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6" style={{ display: 'flex' }}>
              <div style={{ marginTop: 10, marginRight: 25, color: 'grey', marginLeft: 10 }}>Is Parent:</div>
              <div className="atc-switch-heading-start">
                <div className=" atc-switch-inline " style={{ marginTop: 10, }}>
                  <div className="mr-md">No</div>
                  <div>
                    <SwitchComponent
                      cssClass="atc-switch-height"
                      change={(e) => this.onchangeSubBusinessUnit(e, 'parent')}
                      checked={parent === '0' ? true : false}
                    />
                  </div>
                  <div className="ml-md">Yes</div>
                </div>
              </div>
            </div>
            {parent !== '0' && <div className="col-md-6">
              <ComboBoxComponent
                id="parent"
                fields={{ text: "Name", value: "Id" }}
                data-msg-containerid="isParentErr"
                name="isParentErr"
                cssClass="e-outline"
                floatLabelType="Auto"
                placeholder="Parent *"
                dataSource={subBusinessPnl}
                // value={parent === 0 || parent === null ? subBusinessPnl.length > 0 ? +subBusinessPnl[0].Id : null : +parent}
                value={parent == 0 || parent === null ? '' : +parent}
                change={(e) => this.onchangeSubBusinessUnit(e, 'isParent')}
              />
              <div
                id="isParentErr"
                className="e-input-group e-float-icon-left error-div"
              />
            </div>}
          </div>
        </form>
      </div>
    );
  }
}
export default Subbusinessunitfunctionpopup;
