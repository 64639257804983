import {
  USER_SETTING_CONFIG_SUCCESS,
  GET_USER_SETTING_CONFIG,
  ADDEDITWORKDAYSETTINGS,
  // ADD_NOTIFICATION_SETTINGS,
  ADD_UPDATE_NOTIFICATION_SETTINGS,
  SET_MODULE_ATTRIBUTE_SETTING,
} from "../constant/settingconstant";

const INIT_STATE = {
  // authUser: localStorage.getItem('user_id'),
  defaultsetting: {},
  saveSetting: [],
  workDaySetting: [],
  workDayStatus: "",
  notificationSettingsStatus: "",
  moduleAttributeSetting: [],
  baseCountry: 0,
  holidayCountry: 0
};
// eslint-disable-next-line
export default (state = INIT_STATE, action) => { //NOSONAR
  switch (action.type) {
    case USER_SETTING_CONFIG_SUCCESS: {
      return {
        ...state,
        saveSetting: action.payload,
      };
    }
    case GET_USER_SETTING_CONFIG: {
      return {
        ...state,
        defaultsetting: action.payload.defaultsetting,
        baseCountry: action.payload.baseCountry ? action.payload.baseCountry : 0,
        holidayCountry: action.payload.holidayCountry ? action.payload.holidayCountry : 0
      };
    }
    case ADDEDITWORKDAYSETTINGS: {
      return {
        ...state,
        workDaySetting: action.payload,
      };
    }
    // case ADD_NOTIFICATION_SETTINGS:{
    //     return{
    //         ...state,
    //         notificationSettings : action.payload,
    //     }
    // }
    case ADD_UPDATE_NOTIFICATION_SETTINGS: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case SET_MODULE_ATTRIBUTE_SETTING: {
      return {
        ...state,
        moduleAttributeSetting: action.payload,
      };
    }
    default:
      return state;
  }
};
