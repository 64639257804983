import React from "react";
import { connect } from "react-redux";
import { library } from "@fortawesome/fontawesome-svg-core";
import PropTypes from "prop-types";
import { faPlus, faPaperclip } from "@fortawesome/pro-light-svg-icons";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { HtmlEditor, Image, Inject, Link, QuickToolbar, RichTextEditorComponent, Toolbar, Count } from '@syncfusion/ej2-react-richtexteditor';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormValidator } from "@syncfusion/ej2-inputs";
import { pleaseEnterKPIName, pleaseEntertags, pleaseEnterDescription, kpiNameDetails, KPITagsDetails, KPIDiscriptionDetails, pleaseEnterValidKPIName } from '../../assets/constants/constants';
import { addUpdateNewKPI, addUpdateObjectveKpi } from '../../redux/actions/okr/okractions';
import { cracterLimit, removeHashFromTags } from '../../app/other/commonfunction';
import TagsInput from './../objective/tags'
import { Button, Dialog } from "@material-ui/core";
import ErrorBoundary from "../error/errorboundary";
import KpiUploadFile from "../kpi/okrkpiupload";
import {
    DialogContent,
    DialogActions,
} from "../customdialog/customdialog";
import fileIcon from "../../../src/assets/svg/fileIcon_jpeg.png"
import Tooltips from "../custommaterialtooltip/custommaterialtooltip";
import { urlConfig } from "../../config/config";

library.add(faPlus);
class CreateKPI extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            kpiTag: [],
            addAttachmentOpen: false,
            documentPath: ""
        };
        this.descriptionTextBox = null;
        this.commentTxtbox = null;
        this.buttonKPIDisable = false;
    }
    closeAttachmentPopup = () => {
        this.setState({ addAttachmentOpen: false });
    }
    openAttachmentPopup = () => {
        this.setState({ addAttachmentOpen: true });
    }
    updateDimensions() {
        const updateHeight = window.innerHeight - 150;
        this.setState({ height: updateHeight });
    }
    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }
    
    createNewKPI = (type) => { // NoSONAR
        document.getElementById("kpiTagsErr").innerHTML = "";
        const { authUser, kpiName, kpiDescription, kpiAttachment, kpiNewTags, objId, kpiId, objKpiAddUpdateType } = this.props;
        let newKpiNewTags=removeHashFromTags(kpiNewTags);        
        let finalKPIData = {};
        if (type === "AddKPI") {
            finalKPIData = {
                userId: authUser,
                type: "AddKPI",
                kpiName: kpiName,
                kpiTags: newKpiNewTags.toString(),
                kpiDescription: kpiDescription,
                kpiAttachment: kpiAttachment.toString(),
                IsDraft: '1',
                objId: objId,
                kpiIds: 0,
                objKpiAddUpdateType: objKpiAddUpdateType
            }
        } else {
            finalKPIData = {
                userId: authUser,
                type: "UpdateKPI",
                kpiName: kpiName,
                kpiTags: newKpiNewTags.toString(),
                kpiDescription: kpiDescription,
                kpiAttachment: kpiAttachment.toString(),
                IsDraft: '1',
                objId: objId,
                kpiIds: kpiId,
                objKpiAddUpdateType: objKpiAddUpdateType
            }
        }
        const options = {
            rules: {
                kpiIdErr: {
                    required: [true, pleaseEnterKPIName],
                    regex: ["[a-zA-Z0-9][\\sa-zA-Z0-9]", pleaseEnterValidKPIName]
                },
            },
        };
        if (newKpiNewTags.toString() === "" || newKpiNewTags.toString() === null) {
            document.getElementById("kpiTagsErr").innerHTML = "";
            document.getElementById("kpiTagsErr").innerHTML = pleaseEntertags;
        }
        if (kpiDescription === "" || kpiDescription === null) {
            document.getElementById("descripionErr").innerHTML = "";
            document.getElementById("descripionErr").innerHTML = pleaseEnterDescription;
        }
        setTimeout(() => {
            const addFormObject = new FormValidator("#addNewKPIForm", options);
            if (addFormObject.validate()) {
                if (newKpiNewTags.toString() === "" || newKpiNewTags.toString() === null) {
                    document.getElementById("kpiTagsErr").innerHTML = "";
                    document.getElementById("kpiTagsErr").innerHTML = pleaseEntertags;
                    return;
                }
                if (kpiDescription === "" || kpiDescription === null) {
                    document.getElementById("descripionErr").innerHTML = "";
                    document.getElementById("descripionErr").innerHTML = pleaseEnterDescription;
                    return;
                }
                this.buttonKPIDisable = true;
                this.props.addUpdateNewKPI(finalKPIData);
                this.setState({ kpiTag: [] });
            }
        }, 100);
    }
    selectedTags = (tags) => {
        document.getElementById("kpiTagsErr").innerHTML = "";
        this.setState({
            kpiTag: tags
        })
        this.buttonKPIDisable = false;
        this.props.addUpdateObjectveKpi({ kpiNewTags: tags });
    };
    getKPIName = (e) => {
        this.props.addUpdateObjectveKpi({ kpiName: e.value });
    }
    getKPIDescription = (e) => {
        document.getElementById("descripionErr").innerHTML = "";
        this.props.addUpdateObjectveKpi({ kpiDescription: e.value });
    }

    documentDetail = (fileNames) => {
        this.setState({
            fileNames,
        });
    };

    selectedFiles = (file) => {
        const { changeData } = this.state;
        this.props.addUpdateObjectveKpi({ kpiAttachment: file.toString() });
        this.setState({ changeData: !changeData });
    }
    viewUploadedAttachment = (filepath) => {
        const {employeeId} = this.props;
        return (
            <div>
                {
                    filepath.map((attachedFile, attInd) => (
                        <span className="uploaded-kpi-file-limit" key={`attachment${attInd}`}>
                            <img
                                alt=""
                                src={fileIcon}
                                key={`img${attInd}`}
                            />
                            <a
                                target="_blank"
                                href={
                                    attachedFile !== null && attachedFile !== ""
                                        ? `${urlConfig.tmlcApiUrl}document/kpi/${employeeId}/${attachedFile}`
                                        : ""
                                }
                                rel="noopener noreferrer"
                                download
                                style={{textDecoration:'none'}}
                            >
                                <span style={{ padding: "8px 4px 12px", color: "#f94f5e" }} title={attachedFile}>
                                    {attachedFile.substring(11, attachedFile.length).length > 35 ? attachedFile.substring(11, attachedFile.length).substr(0, 32) + '...' : attachedFile.substring(11, attachedFile.length)}
                                </span>
                            </a>
                        </span>
                    ))
                }
            </div>
        )
    }
    render() {
        const { documentPath, addAttachmentOpen } = this.state;
        const { kpiName, kpiDescription, kpiNewTags, kpiAttachment, kpiId } = this.props;
        let fileList = [];
        if (typeof kpiAttachment !== "undefined" && kpiAttachment !== "" && kpiAttachment !== null && kpiAttachment.length > 0) {
            fileList = kpiAttachment.split(",");
        }
        return (
            <>
                <form id="addNewKPIForm" method="Post">
                    <div className="row" style={{ paddingTop: "20px" }}>
                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12" style={{ padding: "0px 42px 0px 16px" }}>
                            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                <TextBoxComponent
                                    id="kpiId"
                                    cssClass="e-outline"
                                    data-msg-containerid="kpiIdErr"
                                    name="kpiIdErr"
                                    floatLabelType="Auto"
                                    placeholder="Key Result *"
                                    value={kpiName}
                                    htmlAttributes={{ maxlength: "100", minLength: 1 }}
                                    input={(e) => cracterLimit(e, `kpiTypeCharactor`, '100')}
                                    change={(e) => this.getKPIName(e)}
                                />
                                <div style={{ margin: '0px -52px 4px 0px' }} id="kpiIdErr" className="e-input-group e-float-icon-left error-div" />
                                <div id='kpiTypeCharactor' style={{ float: 'right', fontSize: 12, margin: '0px 0px 8px 0px' }} className="error-div" />
                            </div>
                            <span style={{ padding: "4px 0px 0px 8px", position: "absolute" }}>
                                <Tooltips titleMessage={kpiNameDetails} />
                            </span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12" style={{ padding: "0px 42px 0px 16px" }}>
                            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                <TagsInput selectedTags={this.selectedTags} tags={kpiNewTags} />
                                <div
                                    id="kpiTagsErr"
                                    className="e-input-group e-float-icon-left error-div"
                                />
                            </div>
                            <span style={{ padding: "4px 0px 0px 8px", position: "absolute" }}>
                                <Tooltips titleMessage={KPITagsDetails} />
                            </span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12" style={{ padding: "0px 42px 0px 16px" }}>
                            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                <RichTextEditorComponent
                                    className="refreshUIRichextBox"
                                    placeholder='Description'
                                    id={`descripion`}
                                    name={`descripion`}
                                    data-msg-containerid={`descripionErr`}
                                    value={kpiDescription}
                                    maxLength={1000}
                                    change={(e) => this.getKPIDescription(e)}
                                    showCharCount={true}
                                >
                                    <Inject services={[Toolbar, Image, Link, Count, HtmlEditor, QuickToolbar]} />
                                </RichTextEditorComponent>
                                <div style={{ float: 'left' }} id={`descripionErr`} className="error-div" />
                                {/* <div style={{ float: 'right' }} id={`descripionCountErr`} className="error-div" /> */}
                            </div>
                            <span style={{ padding: "4px 0px 0px 8px", position: "absolute" }}>
                                <Tooltips titleMessage={KPIDiscriptionDetails} />
                            </span>
                        </div>
                    </div>
                    <div className='row' style={{ marginTop: 15 }}>
                        <div className='col-xs-12 col-sm-12 col-lg-12 col-md-11 label-new pending-for-approval-accord-header' style={{ paddingLeft: "16px" }}>

                            <FontAwesomeIcon icon={faPaperclip} className="mr-sm"
                                onClick={(e) => this.openAttachmentPopup()}
                                style={{ cursor: 'pointer' }} />

                            <span style={{ color: '#B7B7BB', cursor: 'pointer' }} onClick={(e) => this.openAttachmentPopup()}>
                                Add attachment
                                </span>
                            {
                                fileList !== "" && fileList !== null &&
                                <div className="col-xs-12 col-sm-12 col-lg-12" style={{ padding: "8px 0px 12px" }}>
                                    {this.viewUploadedAttachment(fileList)}
                                </div>
                            }
                        </div>

                    </div>
                    <div className="row" style={{ float: "right", padding: "0px 42px 0px 0px" }}>
                        <Button
                            style={{ width: "120px" }}
                            className="cancel-button"
                            variant="contained"
                            onClick={this.props.closeAddKPI()}
                        >
                            Cancel
                          </Button>
                        <Button
                            style={{ width: kpiId > 0? "120px": "130px" }}
                            className="remove-button-shadow"
                            color="primary"
                            variant="contained"
                            disabled={this.buttonKPIDisable}
                            onClick={kpiId > 0 ? (e) => this.createNewKPI("UpdateKPI") : (e) => this.createNewKPI("AddKPI")}
                        >
                            {kpiId > 0 ? "Save" : "Add Key Result"}
                        </Button>
                    </div>
                    <div style={{ width: "350px" }}>
                        <Dialog
                            aria-labelledby="uploading-title"
                            open={addAttachmentOpen}
                            fullWidth
                            width="1000"
                        >
                            <span className="add-new-self-header" style={{ padding: "15px 10px" }}>Upload Key Result Attachment</span>
                            <ErrorBoundary>
                                <DialogContent dividers>
                                    <div>
                                        <div className="row card-layout">
                                            <div className="Upload">
                                                <div className="Content">
                                                    <div style={{ textAlign: "-webkit-center" }}>
                                                        <KpiUploadFile
                                                            documentPath={documentPath}
                                                            documentDetail={this.documentDetail}
                                                            type="kpi"
                                                            // kpiId={this.rowId}
                                                            allowmultiple
                                                            selectedFiles={this.selectedFiles}
                                                            fileList={fileList}
                                                        />
                                                    </div>
                                                    <div />
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            id="uploadErr"
                                            className="e-input-group e-float-icon-left error-div"
                                        />
                                    </div>
                                </DialogContent>
                                <DialogActions>
                                    <Button
                                        style={{ width: "120px" }}
                                        className="cancel-button"
                                        variant="contained"
                                        onClick={this.closeAttachmentPopup}
                                    >
                                        {" "}
                                    Cancel{" "}
                                    </Button>
                                    <Button
                                        style={{ width: "120px", margin: "0px 6px 0px 0px" }}
                                        className="remove-button-shadow"
                                        color="primary"
                                        variant="contained"
                                        onClick={this.closeAttachmentPopup}
                                    >
                                        {" "}
                                    Save{" "}
                                    </Button>
                                </DialogActions>
                            </ErrorBoundary>
                        </Dialog>
                    </div>
                </form>
            </>
        );
    }
}
CreateKPI.propTypes = {
    authUser: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    employeeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    objId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    kpiId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    kpiName: PropTypes.oneOfType([PropTypes.string]),
    kpiNewTags: PropTypes.oneOfType(PropTypes.object, PropTypes.string),
    kpiDescription: PropTypes.oneOfType([PropTypes.string]),
    kpiAttachment: PropTypes.oneOfType(PropTypes.object, PropTypes.string),
    objKpiAddUpdateType: PropTypes.oneOfType([PropTypes.string]),
};
CreateKPI.defaultProps = {
    authUser: "",
    employeeId: "",
    kpiName: "",
    kpiNewTags: [],
    kpiDescription: "",
    kpiAttachment: [],
    objId: 0,
    kpiId: 0,
    objKpiAddUpdateType: ""
};
const mapStateToProps = ({ auth, okrreducer }) => {
    const { authUser } = auth;
    const { kpiName, kpiNewTags, kpiDescription, kpiAttachment, objId, kpiId, objKpiAddUpdateType,employeeId } = okrreducer;
    return {
        authUser,
        kpiName, kpiNewTags, kpiDescription, kpiAttachment, objId, kpiId, objKpiAddUpdateType, employeeId
    };
};

export default connect(mapStateToProps, { addUpdateObjectveKpi, addUpdateNewKPI })(CreateKPI);
