import React, { Component } from 'react';
import { Button, Dialog } from '@material-ui/core';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  DialogContent,
  DialogActions,
  DialogTitle,
} from "../../customdialog/customdialog";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import ConfirmDialog from '../../confirmdialog/confirmdialog';
import {submitResignatonData, submitWithdrawalData} from '../../../redux/actions/entryexit/separationaction';
import { getNotify } from '../../../app/other/commonfunction';

import { ERROR, pleaseSelectReason, pleaseWriteReason, plaeaseSelectWillingToBuyBackNotice } from '../../../assets/constants/constants';
class FooterButtons extends Component {
  constructor(props) {
    super(props);
    this.state = {
      button1Caption:'',
      button2Caption:'',
      title:'',
      message:'',
      open:false,
      withdrawalResignation:false,
      reasonId:null,
      reasonText:''
    };
    this.onSubmitResignation = this.onSubmitResignation.bind(this);
  }
  onSubmitResignation = () => {
    const { willingToBuyBackNotice, primaryReasonForResignationId, 
       noticePeriodInMonth, noOfDays,employeeStatus, // reasonsForResignationIds,
      Remarks } = this.props;
    const {open}=this.state;
      if (willingToBuyBackNotice === 0 && (noticePeriodInMonth * 30 !== noOfDays || employeeStatus === "Resigned")) {
        getNotify(ERROR, plaeaseSelectWillingToBuyBackNotice);
        return
      }
    if (primaryReasonForResignationId === "") {
      getNotify(ERROR, pleaseSelectReason);
      return
    }
    if (Remarks === "") {
      getNotify(ERROR, pleaseWriteReason);
      return
    }
    this.setState({
      open:!open,
      button1Caption:'Yes',
      button2Caption:'No',
      title:'Resignation',
      message:'Are you sure you want to Resign?',
    }); 
  }
  handleSubmitYes = ()=>{
    try{
      const {open}=this.state;
      const { willingToBuyBackNotice, additionalEmailIdsCC, primaryReasonForResignationId, reasonsForResignationIds, Remarks, authUser, requestedLWD, noticePeriodInMonth, noOfDays } = this.props;
      let submitData = {
        "userId": authUser,
        "varAddEmpCc": additionalEmailIdsCC,
        "dtNoticePeriodReq": new Date(requestedLWD)/1000,
        "varPrimeReasonId": primaryReasonForResignationId,
        "varReasonIds": reasonsForResignationIds,
        "willingToBuyback": (noticePeriodInMonth * 30 === noOfDays || willingToBuyBackNotice===0) ? null :willingToBuyBackNotice,
        // "willingToBuyback": willingToBuyBackNotice===0 ? null :willingToBuyBackNotice,
        "txtRemarks": Remarks,
      }

      this.props.submitResignatonData(submitData);
      this.setState({
        open:!open,
        button1Caption:'',
        button2Caption:'',
        title:'',
        message:'',
      });
    }catch(error){

    }
  }
  handleConfirmNo = ()=>{
    const {open}=this.state;
    this.setState({
      open:!open,
      button1Caption:'',
      button2Caption:'',
      title:'',
      message:'',
    });
  }
  handleConfirmClose = ()=>{/**NOSONAR */
    const {open}=this.state;
    this.setState({
      open:!open,
      button1Caption:'',
      button2Caption:'',
      title:'',
      message:'',
    });
  }
  onSubmitWithdrawal = ()=>{
    document.getElementById('selectWDLReasonError').innerHTML = ""
    document.getElementById('commentWDLError').innerHTML = ""
    const { reasonId, reasonText, withdrawalResignation }=this.state;
    const {authUser}=this.props;
    if(reasonId===null){
      document.getElementById('selectWDLReasonError').innerHTML = "* Please select the reason."
      return;
    }
    if(reasonText===''){
      document.getElementById('commentWDLError').innerHTML = "* Please enter the comment."
      return;
    }
    this.props.submitWithdrawalData({
      userId:authUser,
      withdrawlReasonId:reasonId,
      withdrawlReasonText:reasonText
    })
    this.setState({
      withdrawalResignation:!withdrawalResignation
    })
    
  }
  limitCaracter = (e) => {
    let word = "";
    let addressCount = 0;
    word = e.value;
    addressCount = word.length;
    document.getElementById("withdrawalCommentLimitErr").innerHTML = addressCount + "/500";
  }
  render() {
    const { employeeStatus, withdrawlReason, clearanceInitiated } = this.props;
    const { withdrawalResignation }= this.state;
    return (
      <div>
        <div className='col-12' style={{ height: 30, textAlign: 'center', borderTop: '1px solid gray',}}>
          <div className=''>
            {/* <div className='col-1 footer-button'>
              <Button
                style={{ background: "#FFF", color: '#000', border: '1px solid ' }}
                className="e-event-create footer-button" variant="contained" title="Save" size='small'>
                Cancel
                </Button>
            </div> */}
            <div className='col-8' style={{color:'#fff'}}>
              .
              </div>
            <div className='col-4' style={{ textAlign: 'right' }}>
              <div className='footer-button'>
                {
                  employeeStatus === "Resigned" && clearanceInitiated !=='Y' &&
                  <Button
                    style={{ background: "#FFF", color: '#fff', border: '1px solid ', marginRight:5 }}
                    disabled={employeeStatus !== "Resigned" ? true : false}
                    onClick={()=>this.setState({withdrawalResignation:!withdrawalResignation})}
                    className="e-event-details ml-sm " variant="contained" title="WITHDRAW" size='small'
                    color='primary'
                    >
                    WITHDRAW
                </Button>
                }
                  {/* <div className='col-1 footer-button'> */}
               
            {/* </div> */}
            {/* <TooltipComponent position="TopRight" className="pointer-cursor" isSticky={false} content='SUBMIT' cssClass='customtooltip'> */}
                {
                  employeeStatus !== "Resigned" && employeeStatus !== "Resignation - Withdrawal" && employeeStatus !=="Terminated"&& clearanceInitiated !=='Y' &&
                    <Button
                      className="e-event-details text-light ml-sm " variant="contained" color='primary' title='SUBMIT' size='small'
                      onClick={() => this.onSubmitResignation()}
                    >
                      SUBMIT
                  </Button>
                }
                {/* </TooltipComponent> */}
                 {/* <Button
                  style={{ background: "#FFF", color: '#000', border: '1px solid ', marginLeft:5 }}
                  className="e-event-create footer-button" variant="contained" title='Cancel' size='small'>
                  Cancel
                </Button> */}
              </div>
            </div>
            <ConfirmDialog open={this.state.open}
              handleConfirmYes={this.handleSubmitYes}
              handleConfirmNo={this.handleConfirmNo}
              handleConfirmClose={this.handleConfirmClose}
              button2Caption={this.state.button2Caption}
              button1Caption={this.state.button1Caption}
              title={this.state.title}
              message={this.state.message} />
          </div>
          <div 
            className='col-12'
            // style={{ width: "350px" }}
          >
            <Dialog open={this.state.withdrawalResignation}>
              <DialogTitle>Resignation Withdrawal</DialogTitle>
              <DialogContent style={{ marginTop: -10 }}>
                <div className="row">
                  <div
                    // className='col-12'
                    // style={{ paddingTop: "15px" }}
                    className="col-xs-12 col-sm-12 col-lg-12 col-md-2"
                  >
                    {/* <span className="span">Select Reason</span> */}
                    <DropDownListComponent
                      id="noticeperiod"
                      fields={{ value: "Id", text: "Name" }}
                      floatLabelType="Auto"
                      dataSource={withdrawlReason}
                      select={(e) =>
                        this.setState({ reasonId: e.itemData.Id })
                      }
                      cssClass="e-outline"
                      placeholder="Reason for Withdrawal*"
                    />
                    <div id="selectWDLReasonError" className="error-div" style={{ fontSize: 12 }} />
                  </div>
                  <div
                    style={{ paddingTop: "15px" }}
                    // className='col-12'
                    className="col-xs-12 col-sm-12 col-lg-12 col-md-2"
                  >
                    {/* <span className="span">Reason for Revoke</span> */}
                    <TextBoxComponent
                      id="reasonWithdrawal"
                      placeholder="Comment*"
                      cssClass="e-outline"
                      multiline
                      floatLabelType="Auto"
                      input={(e)=>this.limitCaracter(e)}
                      htmlAttributes={{ maxlength: 500 }}
                      change={(e) => this.setState({ reasonText: e.value })}
                    />
                    <div>
                      <div id="commentWDLError" className="col-8 error-div" style={{ fontSize: 12 }} />
                      <div id="withdrawalCommentLimitErr" className="error-div" style={{ fontSize: 12, float:'right' }} />
                    </div>
                  </div>
                </div>
              </DialogContent>
              <DialogActions>
                <ButtonComponent
                  id="cancelRevoke"
                  className="e-control "
                  onClick={()=>this.setState({withdrawalResignation:!withdrawalResignation, reasonId:null, reasonText:''})}
                >
                  Cancel
                </ButtonComponent>
                <ButtonComponent
                  id="sumitRevoke"
                  style={{ background: "#f94f5e", color: "#fff" }}
                  data-ripple="true"
                  onClick={()=>this.onSubmitWithdrawal()}
                >
                  Submit
                </ButtonComponent>
              </DialogActions>
            </Dialog>
          </div>
        </div>
      </div>
    );
  }
}
FooterButtons.propTypes = {
  authUser: PropTypes.string,
};
FooterButtons.defaultProps = {
  authUser: "",
};
const mapStateToProps = ({ separationreducer, auth }) => {
  const { authUser } = auth;
  const { employeeStatus, willingToBuyBackNotice, additionalEmailIdsCC, primaryReasonForResignationId, reasonsForResignationIds, Remarks, noticePeriodRequested, withdrawlReason, requestedLWD, noticePeriodInMonth, noOfDays, clearanceInitiated } = separationreducer;
  return { employeeStatus, willingToBuyBackNotice, additionalEmailIdsCC, primaryReasonForResignationId, reasonsForResignationIds, Remarks, authUser, noticePeriodRequested, withdrawlReason, requestedLWD, noticePeriodInMonth, noOfDays, clearanceInitiated };
};

export default connect(mapStateToProps, {submitResignatonData, submitWithdrawalData})(FooterButtons);
