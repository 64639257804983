/**
 * Author: Vivek Khodade.
 * Last Modify:08-May-2020
 * Modified By:Vivek Khodade
 */
export const SAVE_CANDIDATE_DATA = "save_candidate_data";
export const INITIATE_AND_REVOKE_All_CANDIDATE = "initiate_and_revoke_all_candidate";
export const SAVE_CANDIDATE_SALARY_DATA = "save_candidate_salary_data";
export const SAVE_BASIC_INFORMATION = "save_basic_information";
// Save basic Information
export const SAVE_BASIC_INFORMATION_REDUX = "save_basic_information_redux";
//Document Informatopn
export const UPDATE_DOCUMENT_INFO="update_document_info"
export const UPDATE_DOCUMENT_STATUS="update_document_status"
export const ADD_DOCUMENT_COMMENTS="add_document_comments"

//Basic Information 
export const Add_UPDATE_BASIC_INFO="add_update_basic_info"

// Generate Appointment letter
export const GENERATE_APPOINTMENT_LETTER="generate_appointment_letter"
//Initiate Pre-Joining
export const UPDATE_FUNCTIONAL_ASSETS="update_functional_assets"
//
export const UPDATE_STATUS_ONBOARDING = "update_status_onboarding";

export const SYNC_USER = "sync_user";
export const MOVE_DOCUMENTS = "move_user_documents";

export const SYNC_ENTERPRISE_PROFILE = "SYNC_ENTERPRISE_PROFILE";
