/**
 * Author: Prashant Waphare.
 * Description: This class component use for view absconding comunucation document.
 * Added Date : 24-06-2020.
 */
import React, { Component } from "react";
import "../../../../src/components/separation/resignationcomponent/resignationcomponent.css"

class CommunicationDocPreview extends Component {
  constructor(props) {
    super(props);
    const { allowmultiple } = this.props;
    this.state = {
      hightlight: false,
      allowmultiple,      
    };
  }
  render() {
    const { hightlight} = this.state;
    const { disabled,setCommunicationFilePath } = this.props;
    var filePath = setCommunicationFilePath === false ? false :setCommunicationFilePath;

    return (
      <div
        className={`absconding-upload-document-view ${hightlight ? "Highlight" : ""}`}
        onDragOver={this.onDragOver}
        onDragLeave={this.onDragLeave}
        onDrop={this.onDrop}
        onClick={this.openFileDialog}
        onKeyDown={this.openFileDialog}
        style={{ cursor: disabled ? "default" : "pointer" }}
        role="button"
        tabIndex={0}
      >{ filePath !== false ?
         <img alt="View"
             src= {`${filePath}`}
             className="document-view-component"
            //  style={{ opacity: "1", height: "90%", width: "75%", objectFit:'contain' }}
             />
             :
             <span style={{color:'red'}}>Document not available </span>
        }
      </div>
    );
  }
}
export default CommunicationDocPreview;
