/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { FormValidator } from "@syncfusion/ej2-inputs";
import LnAServices from "../../services/lna/lnaservice";
import {
  saveLeaveYearCluster,
  updateLnAData,
} from "../../redux/actions/lna/lnaaction";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import { getMySqlDateFormat } from "../../app/other/commonfunction";
import ProgressBar from "../progressbar/progressbar";
import { ERROR, entryAlreadyExist, pleaseSelectffectiveStartDate } from "../../assets/constants/constants";
import { getNotify } from "../../app/other/commonfunction";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { format } from 'date-fns';

function LeaveYearConfigCluster(props) {
  //NOSONAR
  const { authUser, openPopup, editClusterId } = props;

  const [state, setState] = useState({
    leaveYearId: null,
    title: null,
    effectiveStartDate: null,
    effectiveEndDate: null,
  });
  const [initialLoad, setInitialLoad] = useState(false);
  const [lockUnlockData, setLockUnlockData] = React.useState([])

  let validOptions = {
    rules: {
      effectiveStartDateErr: {
        date: [true, "Please select lock start date."],
      },
      effectiveEndDateErr: {
        date: [true, "Please select lock end date."],
      },
    },
  };

  useEffect(() => {
    props.updateLnAData({ popUpSource: "Leave Year Configuration Master" });
    getAllLockAndUnlock();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      props.setSubmitClick(onSaveData);
    }, 100);
  }, [state]);

  useEffect(() => {
    getData();
  }, [editClusterId, openPopup]);

  useEffect(() => {
    if (props.lnaMasterFlag === "updated") {
      props.changePopUpHandler("close")
    };
  }, [props.lnaMasterFlag]);

  const getData = () => {
    if (openPopup === "add") {
      resetData();
    } else {
      editClusterId !== null &&
        (async () => {
          setInitialLoad(true);
          const lockUnlockClusterData = await LnAServices.getLeaveYearClusterData(authUser, editClusterId);
          const {
            lnAMasterItemUid,
            title,
            effectiveStartDate,
            effectiveEndDate,
          } = lockUnlockClusterData.Result[0];
          let data = {
            leaveYearUid: lnAMasterItemUid,
            title: title ? title : null,
            effectiveStartDate: effectiveStartDate ? new Date(getMySqlDateFormat(effectiveStartDate)) : null,
            effectiveEndDate: effectiveEndDate ? new Date(getMySqlDateFormat(effectiveEndDate)) : null,
          };
          setInitialLoad(false);
          setState((preState) => ({ ...preState, ...data }));
          setTimeout(() => {
            const addFormObject = new FormValidator(
              "#leaveYearClusterForm",
              validOptions
            );
            addFormObject.validate();
          }, 10);
        })();
    }
    // setState(preState => ({ ...preState, title }))
  };

  const getAllLockAndUnlock = async () => {
    const lockUnlockClusterData = await LnAServices.getLeaveYearClusterData(authUser, '0');

    setLockUnlockData(lockUnlockClusterData.Result);
  }

  const resetData = () => {
    let data = {
        leaveYearUid: null,
        title: null,
        effectiveStartDate: null,
        effectiveEndDate: null,
    };
    setState((preState) => ({ ...preState, ...data }));
  };

  useEffect(() => {
    if (props.lnaMasterFlag === "updated") {
      props.changePopUpHandler("close");
    }
  }, [props.lnaMasterFlag]);

  const onSaveData = async () => {
    const {
        leaveYearUid,
        title,
        effectiveStartDate,
        effectiveEndDate,
    } = state;

    let validateDates = true;

    if (effectiveStartDate === "" || effectiveStartDate === null) {
      document.getElementById("effectiveStartDateErr").innerHTML =
      pleaseSelectffectiveStartDate;
      validateDates = false;
    }

    if (effectiveStartDate === null && effectiveEndDate !== null) {
      document.getElementById("effectiveStartDateErr").innerHTML =
      pleaseSelectffectiveStartDate;
      validateDates = false;
    }
    if (effectiveStartDate !== null && effectiveEndDate !== null) {
      if (new Date(effectiveStartDate) > new Date(effectiveEndDate)) {
        document.getElementById("effectiveStartDateErr").innerHTML =
        pleaseSelectffectiveStartDate;
        validateDates = false;
      }
    }

    const currentDate = new Date(title);
    const currentMonth = currentDate.getMonth() + 1; // Adding 1 because months are zero-indexed
    const currentYear = currentDate.getFullYear();
    let isSameMonthYear = false;
    
    lockUnlockData.forEach((res) => {
      const responseDate = new Date(res.title);
      const responseMonth = responseDate.getMonth() + 1;
      const responseYear = responseDate.getFullYear();
      
      if ((currentYear === responseYear) && (currentMonth === responseMonth) && (res.activeStatus === '1') && !(leaveYearUid > 0)) {
        isSameMonthYear = true;
      }
    })
    
    if (isSameMonthYear) {
      getNotify(ERROR, entryAlreadyExist);
      return
    }
    const leaveYearDate = new Date(effectiveStartDate);
    const leaveYearMonth = leaveYearDate.getMonth() + 1; // Adding 1 because months are zero-indexed
    const leaveYearYear = leaveYearDate.getFullYear();
    
    const leaveYearSaveStartDate = new Date(leaveYearYear, leaveYearMonth - 1, 1);
    
    const addFormObject = new FormValidator("#leaveYearClusterForm", validOptions);
    if (addFormObject.validate() && validateDates) {
      setInitialLoad(true)
      let lockUnlockClusterSaveData = {
        userId: authUser,
        type: leaveYearUid > 0 ? "EDIT" : "ADD",
        leaveYearUid: leaveYearUid,
        title: title ? title : null,
        effectiveStartDate: leaveYearSaveStartDate ? getMySqlDateFormat(leaveYearSaveStartDate) : null,
        effectiveEndDate: effectiveEndDate ? getMySqlDateFormat(effectiveEndDate) : null,
      };
      props.saveLeaveYearCluster(lockUnlockClusterSaveData);
      setTimeout(() => {
        setInitialLoad(false)
      }, 10)
    }
  };

  const inputChangeHandler = async (e, type) => {
    let options = {};
    switch (type) {
      case "effectiveStartDate":
        const selectedDate = new Date(e.value);
        const selectedDateMonth = selectedDate.getMonth();
        const selectedDateYear = selectedDate.getFullYear();

        const firstDateOfMonth = new Date(selectedDateYear, selectedDateMonth, 1);
        const currentDay = firstDateOfMonth.getDate() - 1;
        const currentMonth = firstDateOfMonth.getMonth() + 12;
        const currentYear = firstDateOfMonth.getFullYear();
        
        const endDate = new Date(currentYear, currentMonth, currentDay);

        const formattedStartDate = format(firstDateOfMonth, 'MMM-yy');
        const formattedEndDate = format(endDate, 'MMM-yy');

        const dateRangeString = `${formattedStartDate} To ${formattedEndDate}`;

        setState((prevState) => ({
          ...prevState,
          effectiveStartDate: e.value,
          effectiveEndDate: endDate,
          title: dateRangeString
        }));
        if (document.getElementById("effectiveStartDateErr")) {
          document.getElementById("effectiveStartDateErr").innerHTML = "";
        }
        break;
      default:
    }

    setTimeout(() => {
      const addFormObject = new FormValidator("#leaveYearClusterForm", options);
      addFormObject.validate();
    }, 100);
  };

  return (
    <div
      id="leaveYearClusterForm"
      style={{
        height: "61.5vh",
        overflowY: "scroll",
        paddingLeft: 16,
        paddingRight: 16,
        paddingBottom: 16,
        transition: "width 5s",
      }}
    >
      <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 mt-lg">
        <DatePickerComponent
          id="effectiveStartDate"
          name="effectiveStartDateErr"
          data-msg-containerid="effectiveStartDateErr"
          format="MMM-yyyy"
          start="Year"
          depth="Year"
          value={state.effectiveStartDate}
          floatLabelType="Auto"
          cssClass="e-outline minimize-height"
          placeholder="Leave Year Start Date *"
          firstDayOfWeek={1}
          change={(e) => inputChangeHandler(e, "effectiveStartDate")}
        />
        <div
          id="effectiveStartDateErr"
          className="e-input-group e-float-icon-left error-div"
        />
      </div>

      <div
        className="col-xs-12 col-sm-12 col-lg-12 col-md-12"
        style={{ paddingRight: 0 }}
      >
        <DatePickerComponent
          id="effectiveEndDate"
          fields={{ text: "Name", value: "Id" }}
          value={state.effectiveEndDate}
          data-msg-containerid="effectiveEndDateErr"
          name="effectiveEndDateErr"
          format="MMM-yyyy"
          start="Year"
          depth="Year"
          disabled={true}
          floatLabelType="Auto"
          cssClass="e-outline minimize-height"
          placeholder="Leave Year End Date"
          firstDayOfWeek={1}
          change={(e) => inputChangeHandler(e, "effectiveEndDate")}
        />
        <div
          id="effectiveEndDateErr"
          className="e-input-group e-float-icon-left error-div"
        />
      </div>
      <div
        className="col-xs-12 col-sm-12 col-lg-12 col-md-12"
        style={{ paddingLeft: 0 }}
      >
        <TextBoxComponent
          id="titleDate"
          name="titleDateErr"
          data-msg-containerid="titleDateErr"
          value={state.title}
          disabled={true}
          floatLabelType="Auto"
          cssClass="e-outline minimize-height"
          placeholder="Title"
          change={(e) => inputChangeHandler(e, "titleDate")}
        />
        <div
          id="titleDateErr"
          className="e-input-group e-float-icon-left error-div"
        />
      </div>
      <div className="loader-new1" id="spinnerContainer">
        {initialLoad && <ProgressBar />}
      </div>
    </div>
  );
}

const mapStateToProps = ({ auth, lnareducer, meetingmaster }) => {
  const { authUser } = auth;
  const { currentFinancialYearId } = meetingmaster;
  const { colourMaster, lnaMasterFlag } = lnareducer;

  return {
    authUser,
    lnareducer,
    currentFinancialYearId,
    colourMaster,
    lnaMasterFlag,
  };
};
export default connect(mapStateToProps, {
  saveLeaveYearCluster,
  updateLnAData,
})(LeaveYearConfigCluster);
