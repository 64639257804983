
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ComboBoxComponent, DropDownTreeComponent } from "@syncfusion/ej2-react-dropdowns";
import OKRDashboardServices from '../../services/okrdashboardservices/okrdashboardservices';
import { addUpdateOkrDashboardRedux } from '../../redux/actions/okrdashboardaction/okrdashboardaction';
import DashboardMonth from './dashboardmonths';
import { extractUserIdsFromTreeDropdown } from '../../app/other/commonfunction';
import ProgressBar from '../../components/progressbar/progressbar'
import '../../app/layout/okrratinglayout/okrrating.css'
function TeamRatingTabView(props) {
  const { showMonths, fyId, finacialYearData, dashboardUserList, teamMemberSelected, selectedMonthName } = props;
  const [monthsData, setMonthsData] = useState([])
  const [loader, setLoader] = useState(false)
  useEffect(() => {
    setTimeout(() => {
      updateMonthsData();
      getMonthsData(props.fyId)
    }, 1000)
  }, [])
  const updateMonthsData = () => {
    setMonthsData(props.monthsData)
  }

  const onChangeData = (e, type) => {
    if (type === 'financial') {
      setLoader(true)
      if (e.itemData !== null) {
        getMonthsData(e.itemData.Id)
        props.addUpdateOkrDashboardRedux({ fyId: e.itemData.Id, dashboardStatus: 'updated', dashboardTeamRatingStatus: 'updated', monthOfRating: 0 })
      } else {
        props.addUpdateOkrDashboardRedux({ fyId: e.itemData, dashboardStatus: 'updated', dashboardTeamRatingStatus: 'updated', monthOfRating: 0 })
      }
    }
  }
  const onChange = async (args) => {
    let userIds = await extractUserIdsFromTreeDropdown(args.value);
    props.addUpdateOkrDashboardRedux({ teamMemberIds: userIds, dashboardTeamRatingStatus: 'updated', teamMemberSelected: args.value })
  }
  const getMonthsData = async (fYId) => {
    const { empId, authUser } = props;
    let entireMonthData = await OKRDashboardServices.getOKRDashboardData('okrmonthsdata', authUser, empId, fYId, 0)
    setMonthsData(entireMonthData)
    setLoader(false)
  }
  const getFinancialYear = () => {
    let financialYear = ""
    finacialYearData.length &&
      // eslint-disable-next-line
      finacialYearData.map((item) => {
        if (item.Id === fyId) {
          financialYear = item.Name
        }
      })
    return financialYear;
  }
  const created = (pComponentId) => {
    try {
      setTimeout(() => {
        if (document.getElementById(pComponentId)) {
          document.getElementById(pComponentId).ej2_instances[0].showPopup()
          document.getElementById(pComponentId).ej2_instances[0].hidePopup()
        }
      }, 100)

    } catch (error) {
    }
  }
  const style = `
.e-ddt .e-overflow.e-wrap-count {
  padding: 10px 12px 9px !important;
}`

  return (
    <div className='col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12'>
      {
        !showMonths ?
          <div className='col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12'>
            <div className='col-2 col-lg-2 col-md-2 col-sm-2' style={{ width: 200 }}>
              <span style={{ fontSize: 14, color: '#5a5a5d', fontStyle: 'Circular Std Book' }}>Financial Year</span>&nbsp; <span style={{ fontSize: '14px', color: 'black', fontWeight: 'bold' }}> {getFinancialYear()}</span>
            </div>
            {selectedMonthName ? <div className={`col-lg-2 col-md-2 col-sm-2 col-xs-2`} style={{ margin: -8 }}>
              <div className='active-months'>{selectedMonthName}</div>
            </div> : ''}
          </div>
          :
          <>
            <div className='col-lg-3 col-md-3 col-sm-3 col-xs-4 dashboard-team-rating-top-sec' style={{ borderRight: '2px solid #D1D1D1', marginRight: 10 }}>
              <style>{style}</style>
              <div className='row1'>
                <div className='col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 '>
                  {finacialYearData.length > 0 ?
                    <div className='col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                      <div>
                        <ComboBoxComponent
                          allowCustom={false}
                          fields={{ value: "Id", text: "Name" }}
                          floatLabelType="Auto"
                          dataSource={finacialYearData}
                          name={`fyIdrError`}
                          id={`cmbfyId`}
                          data-msg-containerid={`fyIdrError`}
                          change={(e) => onChangeData(e, 'financial')}
                          cssClass="e-outline"
                          placeholder="Financial Year  *"
                          value={fyId}
                        />
                        <div
                          id="fyIdrError"
                          className="e-input-group e-float-icon-left error-div"
                        />
                      </div>
                    </div>
                    :
                    <div className='col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                      <div>
                        <ComboBoxComponent
                          allowCustom={false}
                          fields={{ value: "Id", text: "Name" }}
                          floatLabelType="Auto"
                          name={`fyIdrError`}
                          id={`cmbfyId`}
                          data-msg-containerid={`fyIdrError`}
                          cssClass="e-outline"
                          placeholder="Financial Year  *"
                          value={fyId}
                        />
                        <div
                          id="fyIdrError"
                          className="e-input-group e-float-icon-left error-div"
                        />
                      </div>
                    </div>
                  }
                </div>
                <div className='row1'>
                  {dashboardUserList.length > 0 ?
                    <div className='col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                      <DropDownTreeComponent
                        id="TeamMemberTree"
                        treeSettings={{ autoCheck: true, loadOnDemand: true }}
                        // select={onChange}
                        change={onChange}
                        fields={{
                          dataSource: dashboardUserList,
                          value: 'id',
                          text: 'name',
                          child: 'subChild',
                        }}
                        changeOnBlur={false}
                        showCheckBox={true}
                        placeholder="Team Member *"
                        popupHeight="200px"
                        mode="Delimiter"
                        floatLabelType="Auto"
                        // allowFiltering={true}
                        // name="teamMemberErr"
                        cssClass="e-outline"

                        value={teamMemberSelected}
                        created={() => created("TeamMemberTree")}
                      />

                      <div
                        id="teamMemberErr"
                        className="e-input-group e-float-icon-left error-div"
                      />
                    </div>
                    :
                    <div className='col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                      <ComboBoxComponent
                        id="TeamMember"
                        floatLabelType="Auto"
                        fields={{ value: "Id", text: "var_list_value", groupBy: 'var_optional1' }}
                        data-msg-containerid="teamMemberErr"
                        name="teamMemberErr"
                        allowFiltering={true}
                        // change={(e) => onChangedInput('teamMember', e)}
                        allowCustom={false}
                        cssClass="e-outline"
                        placeholder="Team Member *"
                        dataSource={props.reviewUsers}
                        value={props.empId}
                      />
                      <div
                        id="teamMemberErr"
                        className="e-input-group e-float-icon-left error-div"
                      />
                    </div>
                  }
                </div>
              </div>
            </div>
            {monthsData.length > 0 && !loader &&
              <div className='col-lg-9 col-md-9 col-sm-9 col-xs-9'>
                <DashboardMonth Months={monthsData} />
              </div>


            }
            {loader && <div className='col-lg-9 col-md-9 col-sm-9 col-xs-9' style={{ marginTop: 57 }}>
              <ProgressBar />
            </div>}

          </>
      }
    </div>
  )
}

const mapStateToProps = ({ auth, okrdashboardreducer, meetingmaster }) => {
  const { authUser } = auth;
  const { finacialYearData, currentFinancialYearId } = meetingmaster;
  const { potentialData, fyId, teamMemberList, empId, dashboardUserList, teamMemberSelected, selectedMonthName } = okrdashboardreducer;
  return {
    authUser, potentialData, finacialYearData, currentFinancialYearId, fyId, teamMemberList, empId, dashboardUserList, teamMemberSelected, selectedMonthName
  }
};
export default connect(mapStateToProps, { addUpdateOkrDashboardRedux })(
  TeamRatingTabView
);
