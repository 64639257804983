import React, { useEffect, useState } from 'react'
import { FormValidator } from "@syncfusion/ej2-inputs";
import { TextBoxComponent, } from "@syncfusion/ej2-react-inputs";
import { EnterLayerMasterName } from '../../../../assets/constants/constants';

export default function LayerMasterPopup(props) {
  const [layerMasterData, setlayerMasterData] = useState({
    layerName: "",
    layerId: ""
  });

  useEffect(() => {
    let data
    if (props.type === 'Add') {
      data = {
        layerName: "",
        layerId: 0
      }
      setlayerMasterData(data);
    }
    if (props.type === 'Edit') {
      const { layerName, Id } = props.layerMasterAreaData;
  
      
        data = {
        layerName: layerName,
        layerId: Id
      }
      setlayerMasterData(data);
    }
    // eslint-disable-next-line
  }, [])

  const layerMasterFunction = (event) => {
    let options = {}
    layerMasterData.layerName = event.value;
    setlayerMasterData(layerMasterData);
    options = {
      rules: {
        layerMasterFunctionErr: {
          required: [true, EnterLayerMasterName],
        }
      },
    };

    setTimeout(() => {
      const addFormObject = new FormValidator("#layermasterForm", options);
      addFormObject.validate();
    }, 100);
  }
  props.parentClassFunction(layerMasterData, "layerMasterData");

  return (
    <div>
      <form id="layermasterForm">
        <div className="form-row" >
          <div className="col-md-9">
            <TextBoxComponent
              id="layerMasterFunction"
              placeholder="Layer*"
              floatLabelType="Auto"
              width="500px"
              value={layerMasterData.layerName}
              data-msg-containerid="layerMasterFunctionErr"
              name="layerMasterFunctionErr"
              cssClass="e-outline"
              onChange={(e) => layerMasterFunction(e)}
              htmlAttributes={{
                maxlength: 100,
                databoundary: "scrollParent"
              }}
            />
            <div
              id="layerMasterFunctionErr"
              className="e-input-group e-float-icon-left error-div"
            />
          </div>

        </div>
      </form>
    </div>
  );
}