import React from 'react';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';

function SendEmailFooter() {
  return (
    <div className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 send-email-footer">
      <div className="email-footer-checkbox">
        <div className="checkbox-title">Select Recipients of email</div>
        <div>
          <CheckBoxComponent cssClass="send-email-checkbox" label="All"/>
          <CheckBoxComponent cssClass="send-email-checkbox" label="Self Incomplete"/>
          <CheckBoxComponent cssClass="send-email-checkbox" label="Supervisor Incomplete"/>
          <CheckBoxComponent cssClass="send-email-checkbox" label="Review Incomplete"/>
        </div>
      </div>
      <div>
        <button className="compliance-report-buttons">Email</button>
        <button className="compliance-report-buttons">Download</button>
      </div>
    </div>
  )
}

export default SendEmailFooter;
