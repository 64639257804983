import React from 'react'
import './bulkattendeebox.css';
    export default function TagsInput (props) {
        const [empCodes, setEmpCode] = React.useState(props.empCodes);
        const removeEmpCode = indexToRemove => {
            setEmpCode([...empCodes.filter((_, index) => index !== indexToRemove)]);
            props.selectedEmpCodes([...props.empCodes.filter((_, index) => index !== indexToRemove)],'remove');
        };
        return (
            <div className="tags-input col-lg-8" style={{border:'none'}}>
                <ul id="tags">
                    { props.empCodes.map((empCode, index) => (
                        <li key={index} className="tag" 
                        // style={{background:props.backgroundColor}}
                        >
                            <span className='tag-title'>{empCode}</span>
                            {
                            //  !props.disabled &&
                            <span className='tag-close-icon'
                                // style={{color:props.backgroundColor}}
                                onClick={() => removeEmpCode(index)}
                            >
                                x
                            </span>
                            }
                        </li>
                    ))}
                </ul>
            </div>
        );
}
