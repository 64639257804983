// Author @ Amit Kumar
// Component for OrgAdmin PopUp

import React, { Component } from "react";
import { ComboBoxComponent } from "@syncfusion/ej2-react-dropdowns";
import { FormValidator } from "@syncfusion/ej2-inputs";
import { pleaseEnterSubLayerName, pleaseSetHierarchy, pleaseSelectNoticePeriod } from '../../../../assets/constants/constants';
import { L10n } from '@syncfusion/ej2-base';
import { NumericTextBoxComponent } from "@syncfusion/ej2-react-inputs";
L10n.load({
  'en-US': {
    grid: {
      EmptyRecord: '',
    },
  },
});
class Sublayermappingpopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      result: [],
      businessUnitData: [],
      subBusinessUnitData: [],
      practiceAreaData: [],
      layerData: [],
      subLayerData: [],
      hierarchyData: [],
      practiceAreaId: null,
      practiceAreaName: "",
      layerName: '',
      subLayerName: '',
      hierarchy: null,
      maxHierarchy: "",
      layerId: "",
      subLayerId: "",
      layerMappingId: "",
      noticePeriod: "",
      noticePeriodDatas: [],
    };
  }
  onChangeSubLayerMapping(e, type) {
    let options = {};
    switch (type) {
      case "Sublayer":
        this.setState({
          subLayerId: e.itemData === null ? 0 : e.itemData.Id,
        });
        options = {
          rules: {
            sublayerErr: {
              required: [true, pleaseEnterSubLayerName],
            },
          },
        };
        break;
      case "Hierarchy":
        this.setState({
          hierarchy: e.value,
        });
        options = {
          rules: {
            hierarchyErr: {
              required: [true, pleaseSetHierarchy],
            },
          },
        };
        break;
      case "noticePeriod":
        this.setState({
          noticePeriod: e.value,

        });
        options = {
          rules: {
            noticePeriodErr: {
              required: [true, pleaseSelectNoticePeriod],
            },
          },
        };
        break;
      default:
        break;
    }
    setTimeout(() => {
      const addFormObject = new FormValidator("#sublayerMappingForm", options);
      addFormObject.validate();
    }, 100);
    const {
      businessFunctionUnitId,
      subBusinessFunctionUnitId,
      practiceAreaId,
      practiceAreaName,
      layerId,
      layerName,
      layerMappingId,
    } = this.props.subLayerMappingData;
    const { hierarchy, subLayerName, subLayerId, noticePeriod } = this.state;

    let data = {
      businessFunctionUnitId: businessFunctionUnitId,
      subBusinessFunctionUnitId: subBusinessFunctionUnitId,
      practiceAreaName: practiceAreaName,
      practiceAreaId: practiceAreaId,
      hierarchy: hierarchy,
      layerId: layerId,
      layerName: layerName,
      subLayerName: subLayerName,
      subLayerId: subLayerId,
      layerMappingId: layerMappingId,
      noticePeriod: noticePeriod
    };
    this.props.parentComponentfunction(data);
  }

  componentDidMount() {

    this.setStateData();
  }
  setStateData() {
    const { businessFunctionUnitId,
      hierarchy,
      layerId,
      layerName,
      subLayerName,
      subLayerId,
      practiceAreaId,
      subBusinessFunctionUnitId,
      layerMappingId,
      noticePeriod
    } = this.props.subLayerMappingData
    const { subLayerMappingMasterData } = this.props
    const { maxHierarchy } = this.props;
    let hierarchyPrev = (hierarchy === undefined || hierarchy === null) ? 0 : hierarchy.toString();

    try {
      const BusinessUnit = subLayerMappingMasterData.result.filter((item) => {
        return item.Type === "businessUnitFunction";
      });
      const SubBusinessUnit = subLayerMappingMasterData.result.filter((item) => {
        return item.Type === "subBusinessUnitFunction";
      });
      const Layer = subLayerMappingMasterData.result.filter((item) => {
        return item.Type === "layer";
      });
      const Sublayer = subLayerMappingMasterData.result.filter((item) => {
        return item.Type === "subLayer";
      });
      const PracticeArea = subLayerMappingMasterData.result.filter((item) => {
        return item.Type === "practiceArea";
      });
      const noticePeriodDS = subLayerMappingMasterData.result.filter((item) => {
        return item.Type === "noticePeriod";
      });
      let hierarchyDS = [];
      for (let i = 1; i <= maxHierarchy; i++) {
        hierarchyDS.push({ Id: i, Name: i.toString() });
      }
      if (hierarchyDS.length === 0) {
        hierarchyDS.push({ Id: 1, Name: "1" });
      }
      this.setState({
        businessUnitData: BusinessUnit,
        subBusinessUnitData: SubBusinessUnit,
        hierarchyData: hierarchyDS,
        layerData: Layer,
        subLayerData: Sublayer,
        practiceAreaData: PracticeArea,
        businessFunctionUnitId: businessFunctionUnitId,
        subBusinessFunctionUnitId: subBusinessFunctionUnitId,
        practiceAreaId: practiceAreaId,
        layerName: layerName,
        subLayerName: subLayerName,
        layerId: layerId,
        subLayerId: subLayerId,
        hierarchy: hierarchyPrev,
        layerMappingId: layerMappingId,
        noticePeriodDatas: noticePeriodDS,
        noticePeriod: noticePeriod
      })
      const {
        practiceAreaName,
      } = this.props.subLayerMappingData;
      let data = {
        businessFunctionUnitId: businessFunctionUnitId,
        subBusinessFunctionUnitId: subBusinessFunctionUnitId,
        practiceAreaName: practiceAreaName,
        practiceAreaId: practiceAreaId,
        hierarchy: hierarchy,
        layerId: layerId,
        layerName: layerName,
        subLayerName: subLayerName,
        subLayerId: subLayerId,
        layerMappingId: layerMappingId,
        noticePeriod: noticePeriod
      };
      this.props.parentComponentfunction(data);
    } catch (error) {
      console.error(error);
    }
  }

  render() {
    const { businessUnitData,
      subBusinessUnitData,
      subBusinessFunctionUnitId,
      businessFunctionUnitId,
      subLayerId,
      hierarchyData,
      practiceAreaData,
      practiceAreaId,
      hierarchy,
      layerData,
      subLayerData,
      layerId,
      noticePeriodDatas,
      noticePeriod
    } = this.state
    return (
      <div>
        <form id="sublayerMappingForm">
          <div className="form-row">
            <div className="form-group col-md-4">
              {businessUnitData.length > 0 && <ComboBoxComponent
                id="businessUnitId"
                dataSource={businessUnitData}
                fields={{ text: "Name", value: "Id" }}
                value={businessFunctionUnitId}
                // data-msg-containerid="emptypeErr"
                // name="emptypeErr"
                allowFiltering
                readonly={true}
                allowCustom={false}
                floatLabelType="Auto"
                cssClass="e-outline"
                placeholder="Business Unit / Group Function "

              />}
            </div>
            <div className="form-group col-md-4">
              {subBusinessUnitData.length > 0 && <ComboBoxComponent
                id="subBusinessUnitId"
                dataSource={subBusinessUnitData}
                fields={{ text: "Name", value: "Id" }}
                value={subBusinessFunctionUnitId}
                // data-msg-containerid="emptypeErr"
                // name="emptypeErr"
                allowFiltering
                readonly={true}
                allowCustom={false}
                floatLabelType="Auto"
                cssClass="e-outline"
                placeholder="Sub Business Unit / Function"

              />}
            </div>
            <div className="form-group col-md-4" >
              {practiceAreaData.length > 0 && <ComboBoxComponent
                id="practiceAreaId"
                dataSource={practiceAreaData}
                fields={{ text: "Name", value: "Id" }}
                value={practiceAreaId}
                // data-msg-containerid="emptypeErr"
                // name="emptypeErr"
                allowFiltering
                readonly={true}
                allowCustom={false}
                floatLabelType="Auto"
                cssClass="e-outline"
                placeholder="Practice Area / Sales Unit / Sub Function"

              />}
            </div>
          </div>
          <div className="form-row" >
            <div className="form-group col-md-4" style={{ paddingTop: "16px", paddingBottom: '0px', position: "relative" }}>
              {layerData.length > 0 && <ComboBoxComponent
                id="EmpType"
                dataSource={layerData}
                fields={{ text: "Name", value: "Id" }}
                value={parseInt(layerId, 10)}
                data-msg-containerid="emptypeErr"
                name="emptypeErr"
                allowFiltering
                readonly={true}
                // allowCustom={false}
                floatLabelType="Auto"
                cssClass="e-outline"
                placeholder="Layer"

              />}
            </div>
            <div className="form-group col-md-4" style={{ paddingTop: "16px", paddingBottom: '0px', position: "relative" }}>
              {subLayerData.length > 0 && <ComboBoxComponent
                id="sublayerId"
                dataSource={subLayerData}
                fields={{ text: "Name", value: "Id" }}
                value={parseInt(subLayerId, 10)}
                data-msg-containerid="sublayerErr"
                name="sublayerErr"
                allowFiltering
                allowCustom={false}
                floatLabelType="Auto"
                cssClass="e-outline"
                placeholder="Sub Layer *"
                change={(e) => this.onChangeSubLayerMapping(e, 'Sublayer')}
              />}
              <div
                id="sublayerErr"
                className="e-input-group e-float-icon-left error-div"
              />
            </div>
            <div className="form-group col-md-4" style={{ paddingTop: "16px", paddingBottom: '0px', position: "relative" }}>
              {hierarchyData.length > 0 && <ComboBoxComponent
                id="hierarchyId"
                dataSource={hierarchyData}
                fields={{ text: "Name", value: "Name" }}
                value={hierarchy}
                data-msg-containerid="hierarchyErr"
                name="hierarchyErr"
                allowFiltering
                // allowCustom={false}
                floatLabelType="Auto"
                cssClass="e-outline"
                placeholder="Hierarchy *"
                change={(e) => this.onChangeSubLayerMapping(e, 'Hierarchy')}
              />}
              <div
                id="hierarchyErr"
                className="e-input-group e-float-icon-left error-div"
              />
            </div>
          </div>
          <div className="form-row" >
            <div className="form-group col-md-4" style={{ paddingTop: "5px", paddingBottom: '0px', position: "relative" }}>
              {noticePeriodDatas.length > 0 && <NumericTextBoxComponent
                id="noticePeriodId"
                value={noticePeriod}
                data-msg-containerid="noticePeriodErr"
                name="noticePeriodErr"
                cssClass="e-outline"
                floatLabelType="Auto"
                min={0}
                placeholder="Notice Period in Months *"
                onChange={(e) => this.onChangeSubLayerMapping(e, "noticePeriod")}
                format='##'
              />}
              <div
                id="noticePeriodErr"
                className="e-input-group e-float-icon-left error-div"
              />
            </div>
          </div>
        </form>
      </div>
    );
  }
}
export default Sublayermappingpopup;
