import React, { useState, useEffect } from 'react';
import LeaveDetails from './leavedetails';
import ApprovalGrid from '../../../approvalgrid/approvalgrid';
import lnaservice from '../../../../services/lna/lnaservice';

function LeaveApplicationTypeOfLeave(props) {
    const authUser = props.authUser, applicationId = props.applicationId;
    const [approvalData, setApprovalData] = useState(null);

    const getApprovalData = async () => {
        if (authUser && applicationId) {
            let type = "";
            if (props.type === "swipeApprovalSubmit")
                type = "REGULARIZATION";
            else if (props.type === "leaveApprovalSubmit")
                type = "LEAVE_APPLIED";
            else if (props.type === "specialAttendanceApprovalSubmit")
                type = "SPECIAL_ATTENDANCE";
            else if (props.type === "leaveTransferApprovalSubmit")
                type = "LEAVE_TRANSFER";
            const response = await lnaservice.getApprovalStatusMatrixData(authUser, type, applicationId);
            if (response && response.Result && Array.isArray(response.Result)) {
                setApprovalData(response.Result);
            }
        }
    }

    useEffect(() => {
        getApprovalData();
    }, [props]);

    const renderLeaveTransfer = () => {
        const { singleLeaveInfo } = props
        const { sourceLeave, noOfLeaves, destinationLeave, reason, status } = singleLeaveInfo
        return (
            <div className='pt-sm'>
                <div
                    className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 "
                    style={{ backgroundColor: '#FFFFFF' }}
                >
                    <div className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
                        <div
                            className="col-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 pt-5"
                        >
                            <div className="regular-container-head">Source Leave Type</div>
                            <div className="regular-container-head-res">
                                {sourceLeave}
                            </div>
                        </div>
                        <div className="col-6 col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <div className="regular-container-head">Destination Leave Type</div>
                            <div className="regular-container-head-res">
                                {destinationLeave}
                            </div>
                        </div>
                    </div>

                    <div
                        className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pt-sm"
                    >
                        <div className="col-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 pt-5">
                            <div className="regular-container-head">No.of Leaves to be transferred</div>
                            <div className="regular-container-head-res"> {noOfLeaves}</div>
                        </div>
                        <div className="col-6 col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <div className="regular-container-head">Status</div>
                            <div className="regular-container-head-res">
                                {status}
                            </div>
                        </div>
                    </div>
                    {approvalData && <ApprovalGrid approvalData={approvalData} authUser={authUser} handleApprove={props.handleApproved} />}
                    <div
                        className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pt-sm pl-md"
                    >
                        <div className="regular-container-head">Reason</div>
                        <div className="regular-container-head-res" style={{ maxHeight: 100, overflowY: 'auto' }}>{reason}</div>
                    </div>
                </div>
            </div>
        )
    }

    const renderRegularizationApproval = () => {
        const { singleLeaveInfo } = props
        let attachList = [];
        const { reason, appliedFor, regularizationType, status, dateAppliedOn, regularizationDayBreak, attachments } = singleLeaveInfo
        if(attachments !== '' && attachments.split(',').length > 0) {
            attachList = attachments.split(',')
        }  
        return (
            <div >
                <div
                    className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 p-sm "
                    style={{ backgroundColor: '#FFFFFF' }}
                >
                    <div className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
                        <div
                            className="col-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 "
                        >
                            <div className="regular-container-head">Regularization Type</div>
                            <div className="regular-container-head-res">
                                {regularizationType}
                            </div>
                        </div>
                        <div className="col-6 col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <div className="regular-container-head">Attendance Type</div>
                            <div className="regular-container-head-res">
                                {regularizationDayBreak}
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pt-sm">
                        <div
                            className="col-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 "
                        >
                            <div className="regular-container-head">Applied On</div>
                            <div className="regular-container-head-res"> {dateAppliedOn}</div>
                        </div>
                        <div className="col-6 col-lg-6 col-md-6 col-sm-6 col-xs-6">
                            <div className="regular-container-head">Applied for Date</div>
                            <div className="regular-container-head-res">
                                {appliedFor}
                            </div>
                        </div>
                    </div>
                    <div
                        className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pt-sm">
                        <div
                            className="col-6 col-lg-6 col-md-6 col-sm-6 col-xs-6"
                        >
                            <div className="regular-container-head">Reason</div>
                            <div className="regular-container-head-res">{reason}</div>
                        </div>
                        <div
                            className="col-6 col-lg-6 col-md-6 col-sm-6 col-xs-6"
                        >
                            <div className="regular-container-head">Status</div>
                            <div className={ `regular-container-head-status ${status === 'Pending' ? 'leave-approval-Pending' : status=== 'Rejected' ? 'leave-approval-Rejected' : 'leave-approval-Approved'}`}>
                                {status}
                            </div>
                        </div>
                    </div>
                    {attachList.length > 0 && 
                        <div className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pt-sm pl-md">
                            <div className="regular-container-head">Attachment</div>
                            <div className="regular-container-head-files">
                                {attachList.map((fileName, index) =>
                                    <a
                                        key={index}
                                        href={`http://localhost:3000/elc/lna/v1/document/lna/regularization/${fileName}`} 
                                        style={{ textDecoration: 'none' }}
                                        rel="noopener noreferrer"
                                        download={fileName}
                                        target={"_blank"}
                                    >{fileName}
                                    </a>
                                )}
                            </div>
                        </div>
                    }
                    {approvalData && <ApprovalGrid approvalData={approvalData} authUser={authUser} handleApprove={props.handleApproved} />}
                </div>
            </div>
        )
    }
    const renderLeaveApproval = () => <LeaveDetails boxHeight={200} moduletype="approvals" from={'approvalView'} leaveData={props.singleLeaveInfo} type={props.type} isSpecial={props.type === "specialAttendanceApprovalSubmit"} handleApproved={props.handleApproved} handleError={props.handleError}/>

    const renderCompOff = () => {
        const { singleLeaveInfo } = props
        const { leaveType, startDate, endDate, noOfLeaves, reason, status } = singleLeaveInfo
        return (
            <div className='pt-sm'>
                <div
                    className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 "
                    style={{ backgroundColor: '#FFFFFF' }}
                >
                    <div className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
                        <div
                            className="col-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 pt-5"
                            style={{ paddingBottom: "20px", paddingLeft: "8px" }}
                        >
                            <div className="regular-container-head">Attendance Type</div>
                            <div className="regular-container-head-res">
                                {leaveType}
                            </div>
                        </div>
                        <div
                            className="col-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 pt-5"
                            style={{ paddingBottom: "20px", paddingLeft: "16px" }}
                        >
                            <div className="regular-container-head">Status</div>
                            <div className="regular-container-head-res">
                                {status}
                            </div>
                        </div>
                    </div>

                    <div
                        className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 "
                        style={{ paddingBottom: "20px" }}
                    >
                        <div
                            className="col-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 pt-5"
                            style={{ paddingBottom: "20px", paddingLeft: "8px" }}
                        >
                            <div className="regular-container-head">From Date</div>
                            <div className="regular-container-head-res">{startDate}</div>
                        </div>
                        <div
                            className="col-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 pt-5"
                            style={{ paddingBottom: "20px" }}
                        >
                            <div className="regular-container-head">To Date</div>
                            <div className="regular-container-head-res">{endDate}</div>
                        </div>
                        <div className="regular-container-head-res" style={{ paddingLeft: "8px" }}>Applied For {noOfLeaves} days</div>
                    </div>

                    <div
                        className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12"
                        style={{ paddingBottom: "20px", paddingLeft: "8px" }}
                    >
                        <div className="regular-container-head">Reason</div>
                        <div className="regular-container-head-res">{reason}</div>
                    </div>
                </div>
            </div>
        )
    }

    return (<>
        {(props.type === "leaveApprovalSubmit" || props.type === "specialAttendanceApprovalSubmit") && renderLeaveApproval()}
        {props.type === "leaveTransferApprovalSubmit" && renderLeaveTransfer()}
        {props.type === "swipeApprovalSubmit" && renderRegularizationApproval()}
        {props.type === "compOffApprovalSubmit" && renderCompOff()}
    </>)
}

export default LeaveApplicationTypeOfLeave;