import React, { Component } from 'react';
import { ComboBoxComponent } from "@syncfusion/ej2-react-dropdowns";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import TextField from '@material-ui/core/TextField';
import { TextBoxComponent, } from '@syncfusion/ej2-react-inputs';
import {dencrypt} from '../../../app/other/commonfunction';
import SeparationServices from '../../../services/entryexit/separationServices';
import {addUpdateSeparationDetails } from '../../../redux/actions/entryexit/separationaction';
import'../resignationcomponent/resignationcomponent.css';

class TerminationReasonForResignation extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    this.suspensionDaysFlag = false;
    this.lastWorkingDay=false
    this.getSelectedTerminationType = this.getSelectedTerminationType.bind(this);
    this.getSelectedSuspensionDays = this.getSelectedSuspensionDays.bind(this);
    this.suspensionDaysList = [{'Id': 7, 'Days': 7},{'Id': 15, 'Days': 15},{'Id': 30, 'Days': 30},{'Id': 45, 'Days': 45},{'Id': 60, 'Days': 60}];
  }
  getResignationInfo = () => {
    try {
      const { search } = window.location;
      const params = new URLSearchParams(search);
      const empId = dencrypt(params.get("empId"))
      const employeeMasterData = Promise.resolve(
        SeparationServices.getEmployeeResignationData(
          empId,
          "info"
        )
      );
      employeeMasterData.then((result) => {
        if (result !== null && result.length > 0) {
          const { employeeStatus, noticePeriodInMonth, reportingTo, ccEmpNm } = result[0];
          this.props.addUpdateSeparationDetails({
            "employeeStatus": employeeStatus, "noticePeriodInMonth": noticePeriodInMonth, "reportingTo": reportingTo,
            "ccEmpNm": ccEmpNm
          })
        }
      });
    } catch (error) {
      // eslint-disable-next-line
    }
  }
  
  componentDidMount = () => {
    // eslint-disable-next-line
    this.props.addUpdateSeparationDetails({terminationRemark:'', employeeStatus:'', terminationGrounds:''})
    this.getTerminationMasterData();
    setTimeout(()=>{
      this.textareaObj.addAttributes({ rows: 4 });
    },1000)
  };
  limitCaracter = (e) => {
    let word = "";
    let addressCount = 0;
    word = e.value;
    addressCount = word.length;
    document.getElementById("reasonForterminationLimitErr").innerHTML = addressCount + "/500";
  }
  getTerminationMasterData = () => {
    try {
      const { authUser } = this.props;
      const employeeMasterData = Promise.resolve(
        SeparationServices.getEmployeeResignationMaster(
          authUser,
          "trmn"
        )
      );
      employeeMasterData.then((result) => {
        if (result !== null && result.length > 0) {
          let terminationStatusData = result.filter(item => { return item.Type === 'Termination Status';});
          let groundsData = result.filter(item => { return item.Type === 'Grounds';});
          // eslint-disable-next-line
          this.props.addUpdateSeparationDetails({"terminationStatusData":terminationStatusData, "groundsData":groundsData})
        }
      });
    } catch (error) {
      this.setState({
        RoleTabData: "error",
      });
    }
  };
  TextFieldComponent = (props) => {
    return <TextField {...props} disabled={true} />
  }
  async getSelectedTerminationType(e) {
    if(e.itemData === null){
        this.suspensionDaysFlag = false;
        this.lastWorkingDay=false
        this.props.addUpdateSeparationDetails({"terminationRequestFor":0, "suspensionForDays":0});
    }
    else{
      if(e.itemData.Id === 680){
        this.suspensionDaysFlag = true;
        this.lastWorkingDay=false
      }
      else {
        this.suspensionDaysFlag = false;
        this.lastWorkingDay=true
      }
      this.props.addUpdateSeparationDetails({"terminationRequestFor":e.itemData.Id});
    }    
  }
  async getSelectedSuspensionDays(e) {
    if(e.itemData === null){
      this.props.addUpdateSeparationDetails({"suspensionForDays":0});
    }
    else{
      this.props.addUpdateSeparationDetails({"suspensionForDays":e.itemData.Id});
    }
    
  }
  componentWillReceiveProps(nextProps) {     
    if(nextProps.updateTerminationStatus==='updated'){
      this.getResignationInfo();
      // eslint-disable-next-line
      this.props.addUpdateSeparationDetails({updateTerminationStatus:''})
    }
  }
  TextFieldComponent = (props) => {
    return <TextField {...props} disabled={true} />
  }
  render() {
    const { terminationRemark, terminationStatusData, groundsData,employeeStatus, terminationLWD} =this.props;
    return (
      <div>
        <div> REASON FOR TERMINATION </div>
        <div>
        {/* <div style={{background:"#F4F3F5"}}> */}
        <div>
          {/* <div className='col-12' style={{background:"#F4F3F5"}}> */}
          <div className='col-12' style={{background:"#FFFFFF", marginTop:20}}>
              <div className='col-xs-2 col-sm-2 col-lg-3 col-md-4' 
                // style={{marginLeft:10}}
              >
                <ComboBoxComponent
                  allowCustom={false}
                  floatLabelType="Auto"
                  // value={carAllowance}
                  // select={this.getSelectedTerminationType}
                  change={this.getSelectedTerminationType}
                  // select={(e) =>
                  //   // eslint-disable-next-line
                  //   // this.suspensionDaysFlag = true;
                  //   this.props.addUpdateSeparationDetails({"terminationRequestFor":e.itemData.Id})
                  // }
                  fields={{ text: "Name", value: "Id" }}
                  id="requestFor"
                  dataSource={terminationStatusData}
                  cssClass="e-outline"
                  disabled={employeeStatus === "Terminated" ? true:false}
                  placeholder="Request For *"
                />                
              </div>
              <div className='col-xs-2 col-sm-2 col-lg-3 col-md-4'>
                <ComboBoxComponent
                  allowCustom={false}
                  floatLabelType="Auto"
                  // value={terminationGrounds}
                  change={(e) =>
                    e.itemData!==null ? 
                    // eslint-disable-next-line
                    this.props.addUpdateSeparationDetails({"terminationGrounds":e.itemData.Id})
                    :
                    // eslint-disable-next-line
                    this.props.addUpdateSeparationDetails({"terminationGrounds":0})
                  }
                  fields={{ text: "Name", value: "Id" }}
                  id="grounds"
                  dataSource={groundsData}
                  cssClass="e-outline"
                  placeholder="Grounds *"
                  disabled={employeeStatus === "Terminated" ? true:false}
                />
              </div>
              {
              this.suspensionDaysFlag && 
                <div className='col-xs-2 col-sm-2 col-lg-3 col-md-4'>
                  <ComboBoxComponent
                    allowCustom={false}
                    floatLabelType="Auto"
                    // value={terminationGrounds}
                    change={this.getSelectedSuspensionDays}
                    // select={(e) =>
                    //   // eslint-disable-next-line
                    //   this.props.addUpdateSeparationDetails({"terminationGrounds":e.itemData.Id})
                    // }
                    fields={{ text: "Days", value: "Id" }}
                    id="grounds"
                    dataSource={this.suspensionDaysList}
                    cssClass="e-outline"
                    disabled={employeeStatus === "Terminated" ? true:false}
                    placeholder="Suspension Days *"
                  />
                </div>
              }
              {
                this.lastWorkingDay && 
                <div className='col-xs-2 col-sm-2 col-lg-3 col-md-4'>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker style={{ width: '100%' }}
                    id="lwdCompanyRequest"
                    name="lwdCompanyRequest"
                    format="dd-MMM-yyyy"
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    value={terminationLWD !=='' ? new Date(terminationLWD) : new Date()}
                    onChange={(date) =>// eslint-disable-next-line
                      this.props.addUpdateSeparationDetails({
                        terminationLWD: date.toString()
                      }) }
                    TextFieldComponent={this.TextFieldComponent}
                    inputVariant="outlined"
                    variant="inline"
                    minDate={new Date()}
                    InputAdornmentProps={{ position: "start" }}
                    autoOk
                    // maxDate={new Date()}
                    // disabled={employeeStatus === "Absconded" ? true:false}
                    label="Last Working Date *"
                  />
                </MuiPickersUtilsProvider>
                </div>
              }
          </div>
          {/* <div className='col-12' style={{background:"#F4F3F5", padding:10}}> */}
          <div className='col-xs-2 col-sm-2 col-lg-12 col-md-4' style={{background:"#FFFFFF", marginTop:25, marginBottom:15}}>
                <div>
                <TextBoxComponent placeholder="Manager's Remark *" cssClass="e-outline" floatLabelType="Auto"
                  name="magenda-txt"
                  multiline={true}
                  style={{backgroundColor:'#fff'}}
                  inputProps={{
                    required: true,
                    minLength: 8,
                  }}
                  value={terminationRemark}
                  ref={(scope) => { this.textareaObj = scope; }}
                  input={(e)=>this.limitCaracter(e)}
                  change={(e)=> // eslint-disable-next-line
                    this.props.addUpdateSeparationDetails({"terminationRemark":e.value})}
                  htmlAttributes={{ maxlength: 500 }}
                  data-msg-containerid="agendaError"
                  readonly={employeeStatus === "Terminated" ? true:false}
                />
                </div>
                <div style={{float:'right'}}>
                  <div style={{textAlign:'right'}} id="reasonForterminationLimitErr" className="error-div" />
                </div>
          </div>
        </div>
        </div>
      </div>
    );
  }
}
TerminationReasonForResignation.propTypes = {
  authUser: PropTypes.string,
  Remarks: PropTypes.string,
  terminationLWD: PropTypes.string,
};
TerminationReasonForResignation.defaultProps = {
  authUser: "",
  Remarks: "",
  terminationLWD: "",
};
const mapStateToProps = ({ auth, separationreducer }) => {
  const { authUser } = auth;
  const { terminationGrounds, terminationRemark, terminationRequestFor, terminationStatusData, groundsData, suspensionForDays, employeeStatus, updateTerminationStatus, terminationLWD } = separationreducer;
  return { authUser, terminationGrounds, terminationRemark, terminationRequestFor, terminationStatusData, groundsData, suspensionForDays, employeeStatus, updateTerminationStatus, terminationLWD };
};

export default connect(mapStateToProps, { addUpdateSeparationDetails })(TerminationReasonForResignation);
