import React from 'react';
import { connect } from 'react-redux';
import { addUpdateOkrRatingRedux } from '../../redux/actions/okrrating/okrratingaction';
import {addUpdateOkrDashboardRedux} from '../../redux/actions/okrdashboardaction/okrdashboardaction'
import { OKR_RATING_AND_REVIEW,DASHBOARD } from '../../redux/constant/meetingcategoryconstant'
import '../../app/layout/okrratinglayout/okrrating.css'
import { getNotify } from "../../app/other/commonfunction";
import {
    WARNING
} from "../../assets/constants/constants";

const ratingmonthreviewperiod = (props) => {
    
    const { monthOfRating } = props
    const getClass = (ratingStatus) => {
        switch (ratingStatus) {
            case 'Pending':
                return 'pending'
            // break;
            case 'Completed':
                return 'complete'
            // break;
            case 'Not Open':
                return 'notopen'
            case 'Not Applicable':
                return 'notapplicable'
            // break;
            case 'Locked':
                return 'notapplicable'
            default:
                break;
        }
    }
    const getClassSelected = (ratingStatus) => {
        switch (ratingStatus) {
            case 'Pending':
                return 'pending-selected'
            // break;
            case 'Completed':
                return 'complete-selected'
            // break;
            case 'Not Open':
                return 'notopen'
            case 'Not Applicable':
                return 'notapplicable'
            // break;
            case 'Locked':
                return 'locked'
            default:
                break;
        }
    }
    /**
      * @author Amit Kumar 
      * @description Load the data according to selected month.
      */
    const changeMonthlyData = (item) => {
        try {
            const { Months } = props;
            const { Id } = item;
            var index;
            Months.forEach((m, i) => {
                if (m.Id === Id) {
                    index = i;
                    return
                }
            })
            if (index !== 0 && index !== 12) {
                var month;
                for (let i = 0; i < Months.length; i++) {
                    if (i < index && Months[i].ratingStatus === 'Pending') {
                        month = Months[i];
                        break
                    }
                }
                if (typeof month !== 'undefined') {
                    getNotify(WARNING, 'Please complete the previous month(s) OKR rating.')
                } else {
                    props.addUpdateOkrRatingRedux({ monthOfRating: item.Id, ratingCategoryStatus: "updatedRatingMonth", ratingStatus: item.ratingStatus })
                    props.addUpdateOkrDashboardRedux({monthlyOkrStatusMonth:item.Id, monthlyOkrMonthStatus:'updated'})
                }
            } else {
                props.addUpdateOkrRatingRedux({ monthOfRating: item.Id, ratingCategoryStatus: "updatedRatingMonth", ratingStatus: item.ratingStatus })
                props.addUpdateOkrDashboardRedux({monthlyOkrStatusMonth:item.Id, monthlyOkrMonthStatus:'updated'})
            }
        } catch (error) {
            console.log("🚀 ~ file: ratingmonthreviewperiod.jsx ~ line 84 ~ changeMonthlyData ~ error", error)
        }
    }
    const isOneTwentyFiveScale = window.innerHeight < 715  ||  window.innerHeight < 747;
    return (
        <>
        {/* DASHBOARD */}
            <div className='col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pl-sm pr-sm'>
                {
                    props.categoryName === DASHBOARD ?
                    <>
                    <div className='row' style={{ width: "120%" }}>
                    {props.Months.slice(0, 6).map((item) => {
                        return (
                            <div key={`ratingMnth${item.Id}`} className={`col-lg-2 col-md-2 col-sm-2 col-xs-2 rating-month-border ${(item.Id === monthOfRating||props.monthlyOkrStatusMonth===item.Id) && props.categoryName === OKR_RATING_AND_REVIEW ? 'active-month' : ''} `} style={{ padding: 4 }}>
                                <div className={`${getClass(item.ratingStatus)} ${(item.Id === monthOfRating||props.monthlyOkrStatusMonth===item.Id) ? 'active-month-border' : ''} ${(item.Id === 0||props.monthlyOkrStatusMonth===0) ? getClassSelected(item.ratingStatus) : ''}`} style={{ color: item.ratingStatus === 'Not Open' ? '#000' : '',  fontSize:isOneTwentyFiveScale?11:'' }}>{item.Name}</div>
                            </div>
                        )
                    })}
                </div>
                <div className='row' style={{ width: "120%" }}>
                    {props.Months.slice(6, 12).map((item, key) => {//NOSONAR
                        return (
                            <div key={`ratingMnth${item.Id}`} className={`col-lg-2 col-md-2 col-sm-2 col-xs-2 rating-month-border ${(item.Id === monthOfRating||props.monthlyOkrStatusMonth===item.Id) && props.categoryName === OKR_RATING_AND_REVIEW ? 'active-month' : ''}`} style={{ padding: 4  }}>
                                <div className={`${getClass(item.ratingStatus)} ${(item.Id === monthOfRating||props.monthlyOkrStatusMonth===item.Id) ? 'active-month-border' : ''} ${(item.Id === 0||props.monthlyOkrStatusMonth===0) ? getClassSelected(item.ratingStatus) : ''}`} style={{ color: item.ratingStatus === 'Not Open' ? '#000' : '',  fontSize:isOneTwentyFiveScale?11:'' }}>{item.Name}</div>
                            </div>
                        )

                    })}
                </div> 
                </>
                :
                <>
                <div className='row' style={{ width: window.innerHeight < 900 ? "122%" : "120%" }}>
                    {props.Months.slice(0, 6).map((item) => {
                        return (
                            <div key={`ratingMnth${item.Id}`} className={`col-lg-2 col-md-2 col-sm-2 col-xs-2 rating-month-border ${(item.Id === monthOfRating||props.monthlyOkrStatusMonth===item.Id) && props.categoryName === OKR_RATING_AND_REVIEW ? 'active-month' : ''} `} onClick={() => changeMonthlyData(item)} style={{ padding: 4, cursor: 'pointer' }}>
                                <div className={`${getClass(item.ratingStatus)} ${(item.Id === monthOfRating||props.monthlyOkrStatusMonth===item.Id) ? 'active-month-border' : ''} ${(item.Id === monthOfRating||props.monthlyOkrStatusMonth===item.Id) ? getClassSelected(item.ratingStatus) : ''}`} style={{ color: item.ratingStatus === 'Not Open' ? '#000' : '',  fontSize:isOneTwentyFiveScale?11:'' }}>{item.Name}</div>
                            </div>
                        )
                    })}
                </div>
                <div className='row' style={{ width: window.innerHeight < 900 ? "122%" : "120%" }}>
                    {props.Months.slice(6, 12).map((item, key) => {//NOSONAR
                        return (
                            <div key={`ratingMnth${item.Id}`} className={`col-lg-2 col-md-2 col-sm-2 col-xs-2 rating-month-border ${(item.Id === monthOfRating||props.monthlyOkrStatusMonth===item.Id) && props.categoryName === OKR_RATING_AND_REVIEW ? 'active-month' : ''}`} onClick={() => changeMonthlyData(item)} style={{ padding: 4, cursor: 'pointer'  }}>
                                <div className={`${getClass(item.ratingStatus)} ${(item.Id === monthOfRating||props.monthlyOkrStatusMonth===item.Id) ? 'active-month-border' : ''} ${(item.Id === monthOfRating||props.monthlyOkrStatusMonth===item.Id) ? getClassSelected(item.ratingStatus) : ''}`} style={{ color: item.ratingStatus === 'Not Open' ? '#000' : '',  fontSize:isOneTwentyFiveScale?11:'' }}>{item.Name}</div>
                            </div>
                        )

                    })}
                </div>
                </>
                }

                {props.Months.length > 0 && props.categoryName === OKR_RATING_AND_REVIEW &&
                    <div className='pt-xs' style={{ display: "flex", flexDirection: "row", marginBottom: 2,  fontSize:isOneTwentyFiveScale?11:'' }}>
                        <div className='legends'>
                            <div style={{ display: "flex", flexDirection: "row", marginTop: -4 }}>
                                <div className='legend-locked'></div>
                                <div className='legend-names'>Locked</div>
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", marginTop: -4 }}>
                                <div className='legend-completed'></div>
                                <div className='legend-names'>Completed</div>
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", marginTop: -4 }}>
                                <div className='legend-Pending'></div>
                                <div className='legend-names'>Pending</div>
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", marginTop: -4 }}>
                                <div className='legend-notopen'></div>
                                <div className='legend-names'>Not Open</div>
                            </div>
                            <div style={{ display: "flex", flexDirection: "row", marginTop: -4 }}>
                                <div className='legend-notapplicable'></div>
                                <div style={{ color: '#c9c2c2' }} className='legend-names'>Not Applicable</div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </>
    )
};

const mapStateToProps = ({ auth, okrratingreducer, okrreducer, meetingmaster, meetingcategory, okrdashboardreducer }) => {
    const { authUser } = auth;
    const { empId, fyId, monthOfRating, supervisorUsers, selfUser, TPUsers, refreshRatingDataFlag, reviewUsers, ratingStatusData } = okrratingreducer;
    const { categoryName } = meetingcategory;
    const { OkrMasters } = okrreducer;
    const { monthlyOkrMonthStatus, monthlyOkrStatusMonth } = okrdashboardreducer;
    const { finacialYearData } = meetingmaster;
    return {
        authUser, empId, fyId, monthOfRating, OkrMasters, finacialYearData, supervisorUsers, selfUser, TPUsers, categoryName, refreshRatingDataFlag, reviewUsers,
        ratingStatusData,monthlyOkrMonthStatus, monthlyOkrStatusMonth
    }
};
export default connect(mapStateToProps, { addUpdateOkrRatingRedux, addUpdateOkrDashboardRedux })(
    ratingmonthreviewperiod
);



