import React, { Component } from 'react';
import { ComboBoxComponent } from "@syncfusion/ej2-react-dropdowns";
import { Button } from "@material-ui/core";
import { connect } from 'react-redux';
import { addUpdateOkrRatingRedux } from '../../redux/actions/okrrating/okrratingaction';
import { OKR_SELF_REVIEW, OKR_SUPERVISOR_REVIEW, OKR_THIRD_PART_REVIEW, OKR_REVIEW, OKR_RATING_AND_REVIEW } from '../../redux/constant/meetingcategoryconstant'
import OKRRatingServices from '../../services/okrrating/okrratingservices'
import RatingMonthReviewPeriod from './ratingmonthreviewperiod';
import SetMeetingModalkr from './setmeeting'
import { library } from "@fortawesome/fontawesome-svg-core";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import OKRServices from "../../services/okr/okrservices";
import '../../app/layout/okrratinglayout/okrrating.css'
import Progressbar from '../progressbar/progressbar';
import OKRRatingReviewStatue from './okrratingreviewStatus';
import PerformanceScore from './performancescore'
import {
  faEdit,
  faEye,
  faPlus,
  faCheckCircle,
  faWindowClose
} from "@fortawesome/pro-light-svg-icons";

library.add(
  faExternalLinkAlt,
  faCheckCircle,
  faWindowClose,
  faEdit,
  faEye,
  faPlus
);
class ReviewPeriod extends Component {
  constructor(props) {
    super(props)
    this.state = {
      teamMembersList: [],
      reviewMnths: [],
      setMeetingOpen: false,
      category: '',
      performanceScoreData: []
    };
    this.getSelectedMonthText = this.getSelectedMonthText.bind(this);
  }
  componentDidMount() {
    try {
      const { categoryName, authUser, TPUsers, reviewUsers,
        /* ratingStatusData*/
      } = this.props
      switch (categoryName) {
        case OKR_RATING_AND_REVIEW:
        case OKR_SELF_REVIEW:
          this.setState({ teamMembersList: reviewUsers });
          this.props.addUpdateOkrRatingRedux({ empId: authUser, ratingTabs: 'self-rating' })
          this.getPerformancePeriodEnding('Self', true);
          break;
        case OKR_REVIEW:
          this.setState({ teamMembersList: reviewUsers });
          this.props.addUpdateOkrRatingRedux({ empId: null });
          this.getPerformancePeriodEnding('Sup', true);

          break;
        case OKR_SUPERVISOR_REVIEW:
          this.setState({ teamMembersList: reviewUsers });
          this.props.addUpdateOkrRatingRedux({ empId: null }); break;
        case OKR_THIRD_PART_REVIEW:
          this.setState({ teamMembersList: TPUsers })
          this.props.addUpdateOkrRatingRedux({ empId: null }); break;
        default:
      }
      setTimeout(() => {
        this.updateRefreshFlag();
        this.getPerformanceScore();
      }, 1000)
    } catch (error) {
      console.log(error, 'error')
    }
  }
  getPerformanceScore = () => {
    setTimeout(() => {
      const { empId, fyId } = this.props;      
      if (empId > 0) {
        const performanceScore = Promise.resolve(
          OKRServices.getOKRPerformanceScore(empId, fyId)
        );
        performanceScore.then(async (resultData) => {
          if (resultData !== null) {
            this.setState({ performanceScoreData: resultData.Result })
          }
        })
      }
    }, 100)
  }

  componentWillUnmount() {
    try {
      this.props.addUpdateOkrRatingRedux({
        empId: null, ratingBusinessOkr: [],
        ratingCentralOkr: [],
        ratingLPOkr: [],
        ratingBrandOkr: [],
        reviewBusinessOkr: [],
        reviewCentralOkr: [],
        reviewLPOkr: [],
        reviewBrandOkr: [],
        ratingStatusData: []
      });
      this.setState({ reviewMnths: [] })
    } catch (error) {

    }
  }

  onChangedInput = (type, event) => {//nosonar
    const { authUser, empId } = this.props;
    switch (type) {
      case 'financialYear':
        if (event.itemData !== null) {
          this.setState({ reviewMnths: [] })
          this.props.addUpdateOkrRatingRedux({ fyId: event.itemData.Id, refreshRatingDataFlag: '', ratingTabs: '' })
          if (empId === authUser) {
            this.getPerformancePeriodEnding('Self', true);
          } else {
            this.getPerformancePeriodEnding('Sup', true);
          }
          this.updateRefreshFlag()
          // setTimeout(()=>{
          this.getPerformanceScore();
          // },1000)
        } else {
          // this.props.addUpdateOkrRatingRedux({ fyId: 0 })
        }
        break;
      case 'teamMember':
        this.setState({ reviewMnths: [] })
        if (event.itemData !== null) {
          this.props.addUpdateOkrRatingRedux({ empId: event.itemData.Id, refreshRatingDataFlag: '' })
          if (event.itemData.Id === authUser) {
            this.getPerformancePeriodEnding('Self', true);
          } else {
            this.getPerformancePeriodEnding('Sup', true);
          }
          this.updateRefreshFlag()
        } else {
          this.props.addUpdateOkrRatingRedux({ empId: event.itemData })
        }
        // setTimeout(()=>{
        this.getPerformanceScore();
        // },1000)
        this.props.addUpdateOkrRatingRedux({ monthOfRating: 0, refreshRatingDataFlag: '', ratingTabs: '', ratingStatus: [] })
        break;
      case 'periodReview':
        if (event.itemData !== null) {
          this.props.addUpdateOkrRatingRedux({ monthOfRating: event.itemData.Id, refreshRatingDataFlag: '' })
          this.updateRefreshFlag()
        } else {
          this.props.addUpdateOkrRatingRedux({ monthOfRating: event.itemData })
        }
        break;
      default:
        break;
    }
    setTimeout(() => {
      this.getDashboardRatingSummaryData()
    }, 10)
  }

  updateRefreshFlag() {
    setTimeout(() => {
      const { empId, fyId, monthOfRating } = this.props;
      if (empId !== null && fyId !== null && monthOfRating !== null) {
        this.props.addUpdateOkrRatingRedux({ refreshRatingDataFlag: 'updated' })
      }
    }, 100)
  }

  getDashboardRatingSummaryData = () => {//NOSONAR
    const { empId, fyId, monthOfRating, authUser, ratingTabs } = this.props;
    try {
      let ratingType = 'sup';
      if (ratingTabs === 'okr-review') {
        ratingType = 'review'
      } else {
        if (empId === authUser) {
          ratingType = 'self';
        }
      }
      if (empId > 0 && fyId > 0 && (monthOfRating > 0 || monthOfRating === '-1') && ratingType !== '') {
        const okrRatingDashboardData = Promise.resolve(
          OKRRatingServices.getDashboardData(authUser, empId, fyId, monthOfRating, '', ratingType, 'summary')
        );
        okrRatingDashboardData.then((resultData) => {

          if (resultData.length > 0) {
            setTimeout(() => {
              let checkReview = false
              if (empId === authUser) {
                if (resultData[1].Ratings[0] !== undefined) {
                  if (resultData[1].Ratings[0].allocationStatus === "Complete") {
                    this.props.addUpdateOkrRatingRedux({ ratingTabs: 'okr-review' });
                    checkReview = true
                  } else {
                    this.props.addUpdateOkrRatingRedux({ ratingTabs: 'self-rating' });
                  }
                }
              } else {
                if (resultData[1].Ratings[1] !== undefined) {
                  if (resultData[1].Ratings[1].allocationStatus === "Complete") {
                    this.props.addUpdateOkrRatingRedux({ ratingTabs: 'okr-review' });
                    checkReview = true
                  } else {
                    this.props.addUpdateOkrRatingRedux({ ratingTabs: 'supervisor-rating' });
                  }
                }
              }
              if (checkReview && ratingType !== 'review') {
                setTimeout(() => {
                  this.getDashboardRatingSummaryData()
                }, 10)
              }
              else {
                this.props.addUpdateOkrRatingRedux({ ratingStatusData: resultData })
                this.props.addUpdateOkrRatingRedux({ ratingCategoryStatus: "updated1" });
              }

            }, 100)
          }
        });
      } else {
        this.props.addUpdateOkrRatingRedux({ ratingStatusData: [], ratingCategoryStatus: '' })
      }
    } catch (err) {
      console.log("getDashboardRatingData : OKRRatingServices", err)
    }
  }
  getPerformancePeriodEnding = (type, isResetMonthOfRating) => {
    const { empId, authUser, fyId, categoryName } = this.props;
    if (fyId == null)
      return
    const datesObj = {
      fyId: fyId,
      startMonth: null,
      endMonth: null
    }
    const performanceEnding = Promise.resolve(
      OKRServices.getOKRFrequency(type, authUser, empId === null ? authUser : empId, 1, null, datesObj)
    );
    performanceEnding.then(async (resultData) => {
      if (categoryName === OKR_RATING_AND_REVIEW) {
        this.setState({
          reviewMnths: resultData.Result
        })
        if (isResetMonthOfRating)
          this.props.addUpdateOkrRatingRedux({ monthOfRating: 0, refreshRatingDataFlag: '', ratingTabs: '' })
      }
    })
  }
  //WARNING! To be deprecated in React v17. Use new lifecycle static getDerivedStateFromProps instead.
  componentWillReceiveProps(nextProps) {
    const { authUser, empId } = this.props
    if (nextProps.refreshRatingDataFlag === 'meetingSet') {
      this.setState({ ratingStatusLoaded: true, setMeetingOpen: false })
      this.getDashboardRatingSummaryData();
      this.props.addUpdateOkrRatingRedux({ refreshRatingDataFlag: '' })
    }
    if (nextProps.ratingCategoryStatus === 'updatedRatingMonth' || nextProps.ratingCategoryStatus === 'submittedRating') {
      setTimeout(() => {
        this.getDashboardRatingSummaryData();
        if (nextProps.ratingCategoryStatus === 'submittedRating') {
          if (empId === authUser) {
            this.getPerformancePeriodEnding('Self', false);
          } else {
            this.getPerformancePeriodEnding('Sup', false);
          }
        }
      })
      this.props.addUpdateOkrRatingRedux({ ratingCategoryStatus: '' })
    }
  }

  openSetMeetingPopUp = () => {
    this.setState({ setMeetingOpen: true })
  }

  closeSetMeetingPopUp = () => {
    this.setState({ setMeetingOpen: false })
  }

  getRatingStatusIcon(ratingStatus) {
    switch (ratingStatus) {
      case "Closed":
        return faCheckCircle;
      case "NA":
        return faWindowClose;
      case "Complete":
        return faEye;
      case "Pending":
        return faEdit;
      default:
    }
  }

  itemTemplate = (data) => {
    return (
      <div style={{ marginLeft: 10, marginRight: 0, zIndex: 99999 }}>
        <FontAwesomeIcon
          style={{ fontWeight: "bold", float: "left" }}
          icon={this.getRatingStatusIcon(data.ratingStatus)}
          className="mt-sm fa-lg"
        />
        <div className="ename"> {data.Name} </div>
      </div>
    );
  };
  /**
   * @author Vivek Khodade
   * @param {*} category 
   * @description Load the tabs data according to category
   */
  changeRatingCategory = (category) => {
    let categoryName = '';
    if (category === 'Self Rating') {
      categoryName = 'self-rating';
    }
    else if (category === 'Supervisor Rating') {
      categoryName = 'supervisor-rating';
    }
    else if (category === 'okr-review') {
      categoryName = 'okr-review';
    }
    this.props.addUpdateOkrRatingRedux({ ratingTabs: categoryName, ratingCategoryStatus: "updated1" });
  }

  getFinancalYearText = () => {
    const { finacialYearData, fyId } = this.props;
    let fyText = "";
    if (finacialYearData.length > 0) {
      finacialYearData?.forEach(element => {
        if (fyId === element.Id) {
          fyText = element.Name
        }
      });
    }
    return fyText;
  }
  getSelectedUserName = () => {
    const { teamMembersList } = this.state;

    const { empId } = this.props;
    let empName = ''
    if (teamMembersList.length > 0) {
      teamMembersList?.forEach(item => {
        if (item.Id === empId) {
          empName = item.var_list_value
        }
      })
    }
    return empName;
  }
  getSelectedMonthText = () => {
    const { reviewMnths } = this.state;
    const { monthOfRating } = this.props;
    let activeMonth = {};
    if (reviewMnths.length > 0) {
      reviewMnths?.forEach(ele => {
        if (ele.Id === monthOfRating) {
          activeMonth = ele
        }
      });
    }
    return activeMonth;
  }
  getClassSelected = (ratingStatus) => {
    switch (ratingStatus) {
      case 'Pending':
        return 'pending-selected'
      // break;
      case 'Completed':
        return 'complete-selected'
      // break;
      case 'Not Open':
        return 'notopen'
      case 'Not Applicable':
        return 'notapplicable'
      // break;
      case 'Locked':
        return 'locked'
      default:
        break;
    }
  }
  render() {//NOSONAR
    const { empId, finacialYearData, monthOfRating, fyId, ratingStatusData, authUser, ratingTabs } = this.props;
    const { teamMembersList, setMeetingOpen, reviewMnths, performanceScoreData } = this.state
    let disabledSetMeetingCnt = 0;
    let showMeetingInfo = false
    if (typeof ratingStatusData !== 'undefined' && ratingStatusData.length > 0) {
      ratingStatusData[1].Ratings.forEach((item) => {
        if (item.allocationStatus.toLowerCase() === 'complete') {
          disabledSetMeetingCnt++;
        }
      })
      if (ratingStatusData[3] !== null && typeof ratingStatusData[3] !== 'undefined') {
        if (ratingStatusData[3].Meeting !== null && typeof ratingStatusData[3].Meeting !== undefined)
          showMeetingInfo = true;
      }
    }

    let teamMemberStatus = "";
    if (authUser === empId) {
      teamMemberStatus = "self";
    } else {
      teamMemberStatus = "supervisor"
    }
    let activeMonth = this.getSelectedMonthText();
    const isOneTwentyFiveScale = window.innerHeight < 715  ||  window.innerHeight < 747;
    return (
      <div className='col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pt-sm'>
        {
          reviewMnths.length === 0 && empId > 0 &&
          <Progressbar />
        }
        <>
          {this.props.showStatus ?
            <div className='col-2 col-lg-2 col-md-2 col-sm-2 col-xs-4 pt-sm okr-rating-top-sec ' style={{ borderRight: '2px solid #D1D1D1', marginRight: 10 }}>
              <div className='row1'>
                <div className='col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 '>
                  <div className='col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                    <div>

                      {finacialYearData.length > 0 ?//NOSONAR
                        <div>

                          <ComboBoxComponent
                            floatLabelType="Auto"
                            fields={{ value: "Id", text: "Name" }}
                            dataSource={finacialYearData}
                            change={(e) => this.onChangedInput("financialYear", e)}
                            name={`fyIdrError`}
                            id={`cmbfyId`}
                            data-msg-containerid={`fyIdrError`}
                            cssClass="e-outline"
                            style={{fontSize:isOneTwentyFiveScale?12:''}}
                            placeholder="Financial Year  *"
                            value={fyId}
                          />
                          <div id="fyIdrError" className="e-input-group e-float-icon-left error-div"
                          />

                        </div>
                        :
                        <div>
                          {/* <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2  "> */}
                          <ComboBoxComponent
                            allowCustom={false}
                            floatLabelType="Auto"
                            name={`fyIdrError`}
                            id={`cmbfyId`}
                            data-msg-containerid={`fyIdrError`}
                            cssClass="e-outline"
                            placeholder="Financial Year  *"
                          />
                          <div
                            id="fyIdrError"
                            className="e-input-group e-float-icon-left error-div"
                          />
                          {/* </div> */}
                        </div>
                      }
                    </div>
                  </div>
                </div>
                <div className='row1'>
                  {
                    teamMembersList.length > 0 &&
                    <div className='col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                      <ComboBoxComponent
                        id="TeamMember"
                        floatLabelType="Auto"
                        fields={{ value: "Id", text: "var_list_value", groupBy: 'var_optional1' }}
                        data-msg-containerid="teamMemberErr"
                        name="teamMemberErr"
                        allowFiltering={true}
                        change={(e) => this.onChangedInput('teamMember', e)}
                        allowCustom={false}
                        cssClass="e-outline"
                        placeholder="Team Member *"
                        style={{fontSize:isOneTwentyFiveScale?12:''}}
                        dataSource={teamMembersList}
                        value={empId}
                      />
                      <div
                        id="teamMemberErr"
                        className="e-input-group e-float-icon-left error-div"
                      />
                    </div>
                  }
                </div>
              </div>
            </div>
            :
            <div className='col-9 col-lg-9 col-md-9 col-sm-9 col-xs-9'>
              <div className='col-2 col-lg-2 col-md-2 col-sm-2 col-xs-2' style={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }}>
                <div>
                  <span className='ratingfytext'>Financial Year</span> &nbsp; <span className='ratingfy legend-names'> <b>{this.getFinancalYearText()}</b></span>
                </div>
                <div>
                  <span className='ratingfytext legend-names'><b>{this.getSelectedUserName()}</b></span>
                </div>
              </div>
              <div className='col-2 col-lg-2 col-md-2 col-sm-2 col-xs-2'>
                <div key={`ratingMnth${activeMonth.Id}`} className={`col-lg-12 col-md-12 col-sm-12 col-xs-12 ${monthOfRating > 0 ? 'active-month' : ''}`} style={{ padding: 4, cursor: 'pointer' }}>
                  <div className={`${this.getClassSelected(activeMonth.ratingStatus)} ${activeMonth.Id === monthOfRating ? /*nosonar*/'active-month-border' : ''}`}>{activeMonth.Name}</div>
                </div>
              </div>
              <div className='col-4 col-lg-4 col-md-4 col-sm-4 col-xs-4 ' style={{ paddingLeft: 0, paddingBottom: 0, borderLeft: '2px solid #D1D1D1', borderRight: '2px solid #D1D1D1' }}>
                <OKRRatingReviewStatue
                  reviewMnths={reviewMnths}
                  monthOfRating={monthOfRating}
                  ratingTabs={ratingTabs}
                  changeRatingCategory={this.changeRatingCategory}
                  ratingStatusData={ratingStatusData}
                  teamMemberStatus={teamMemberStatus}
                  showStatus={this.props.showStatus}
                  type={"close"}
                  performanceScoreData={performanceScoreData}
                />
              </div>
              <div className='col-4 col-lg-4 col-md-4 col-sm-4 col-xs-4 pt-sm' style={{ paddingLeft: 0 }}>
                <PerformanceScore performanceScoreData={performanceScoreData} />
              </div>
            </div>
          }
        </>
        {reviewMnths.length > 0 &&
          <div className={`col-7 col-lg-7 col-md-7 col-sm-7 col-xs-7 performanceperiodmonthscontainer`} style={{ paddingLeft: 5, paddingRight: 5 }}>
            {
              this.props.showStatus &&
              <RatingMonthReviewPeriod Months={reviewMnths} />
            }
          </div>}

        <div className='col-3 col-lg-3 col-md-3 col-sm-3 col-xs-3' style={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0, height: this.props.showStatus ? 131 : '', }}>
          <div style={{
            height: this.props.showStatus ? 131 : '',
            borderLeft: '2px solid #D1D1D1',
            // borderLeft: ratingStatusData.length ? '2px solid #D1D1D1' : '', 
            marginLeft: 10
          }}>
            <OKRRatingReviewStatue
              reviewMnths={reviewMnths}
              monthOfRating={monthOfRating}
              ratingTabs={ratingTabs}
              changeRatingCategory={this.changeRatingCategory}
              ratingStatusData={ratingStatusData}
              teamMemberStatus={teamMemberStatus}
              showStatus={this.props.showStatus}
              type={"open"}
              performanceScoreData={performanceScoreData}
            />
          </div>
        </div>

        {/* typeof ratingStatusData[3] !== 'undefined' &&
            typeof ratingStatusData[3].Meeting !== undefined &&
            ratingStatusData[3].Meeting !== undefined && Object.keys(ratingStatusData[3].Meeting).length > 0 && */}
        {
          monthOfRating > 0 &&
          empId > 0 &&
          showMeetingInfo && <> {
            teamMembersList.length > 0 &&
            typeof ratingStatusData !== 'undefined' &&
            ratingStatusData.length > 0 &&
            ratingStatusData[3].Meeting.reviewSubmitted === "N" &&
            <div style={{ float: "right", boxShadow: 'none', marginRight: 50, display: 'none' }} >
              <span className='set-meeting-button1'>
                <Button
                  style={{
                    height: 42, cursor: 'pointer', zIndex: 1, boxShadow: 'none',
                    color: disabledSetMeetingCnt === 2 ? "#fff" : "#c1baba", backgroundColor: disabledSetMeetingCnt === 2 ? '#F94F5E' : ''
                  }}
                  className="e-event-details ml-sm remove-button-shadow "
                  variant="contained"
                  size="small"
                  color="default"
                  onClick={() => this.openSetMeetingPopUp()}
                  disabled={disabledSetMeetingCnt !== 2}
                >
                  {parseInt(ratingStatusData[3].Meeting.Id) === 0 ? "Set Meeting" : "Re-schedule Meeting"}
                </Button>
              </span>
              <span className="okr-review-objective-grid-recommendation-text" style={{ marginLeft: 10 }}>{ratingStatusData[3].Meeting.details}</span>
              {ratingStatusData[3].Meeting.webLink !== "" &&
                <span className='interim-feedback-icon-color'>
                  <a href={`${ratingStatusData[3].Meeting.webLink}`} rel='noreferrer' target='_blank'>
                    <Button className='interim-feedback-icon-color' style={{ width: 30 }}>
                      <FontAwesomeIcon
                        className='interim-feedback-icon-color'
                        style={{ color: "#F94F5E" }}
                        icon={faExternalLinkAlt}
                        title="Start Meeting">

                      </FontAwesomeIcon>

                    </Button>
                  </a>
                </span>
              }
            </div>
          }
            {ratingStatusData.length > 0 &&
              Object.keys(ratingStatusData[3].Meeting).length > 0 &&
              ratingStatusData[3].Meeting.reviewSubmitted === "Y" &&
              <div style={{ float: "right", boxShadow: 'none', marginRight: 50 }} >
                <span className="okr-review-objective-grid-recommendation-text"
                // style={{ marginLeft: 10 }}
                >{ratingStatusData[3].Meeting.details}</span>
              </div>
            }

            {setMeetingOpen &&
              <SetMeetingModalkr
                MeetingInfo={ratingStatusData[3].Meeting}
                closeSetMeetingPopUp={this.closeSetMeetingPopUp} />
            }</>

        }

      </div>
    )
  }
}
const mapStateToProps = ({ auth, okrratingreducer, okrreducer, meetingmaster, meetingcategory }) => {
  const { authUser } = auth;
  const { empId, fyId, monthOfRating, supervisorUsers, selfUser, TPUsers, refreshRatingDataFlag, reviewUsers,
    ratingStatusData, ratingCategoryStatus, ratingTabs } = okrratingreducer;
  const { categoryName } = meetingcategory;
  const { OkrMasters } = okrreducer;
  const { finacialYearData } = meetingmaster;
  return {
    authUser, empId, fyId, monthOfRating, OkrMasters, finacialYearData, supervisorUsers, selfUser, TPUsers, categoryName, refreshRatingDataFlag, reviewUsers,
    ratingStatusData, ratingCategoryStatus, ratingTabs
  }
};
export default connect(mapStateToProps, { addUpdateOkrRatingRedux })(
  ReviewPeriod
);
