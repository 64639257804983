import React, { useState, useEffect } from 'react'
import { Dialog, DialogTitle, Button } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from 'react-redux'
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import ReactHtmlParser from 'react-html-parser'
import { TextBoxComponent, NumericTextBoxComponent } from "@syncfusion/ej2-react-inputs";
import DateFnsUtils from "@date-io/date-fns";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
} from "@material-ui/pickers"; import { FormValidator } from "@syncfusion/ej2-inputs";
import BenchMarkTable from '../okrproductiveallocation/benchmarktable';
import UploadDocuments from './uploaddocuments'
import Tooltips from "../custommaterialtooltip/custommaterialtooltip";
import {
    DialogContent,
    DialogActions,
} from "../customdialog/customdialog";
import { benchmarkFormatData, cracterLimit, getDate } from '../../app/other/commonfunction';
import {
    MeasuremenrUnit,
    MeasuremenrSortBy,
    PleaseEnterTheActualAchievedValueBasedOnReferenceDocument,
    YouMayUploadSupportingDocumentForTheAchievedValue

} from '../../assets/constants/constants';
import { addEditActualRating } from '../../redux/actions/okrrating/okrratingaction'
import OKRRatingServices from '../../services/okrrating/okrratingservices'
import { createImageFilepath } from './ratingcommonfun'
import { OKR_PRODUCTIVE_ALLOCATION } from '../../redux/constant/meetingcategoryconstant'
const sampleCss = `.e-outline textarea, textarea.e-outline, .e-outline.e-float-input textarea, .e-outline.e-float-input.e-control-wrapper textarea, .e-outline.e-float-input.e-input-group textarea {
    box-sizing: border-box;
    min-height: 100px;
}
// @media (-webkit-min-device-pixel-ratio: 1.25) { 
//     table {
//         border-spacing: 0 !important;
//         /* border-collapse: collapse; */
//         border-color: lightgray !important;
//     }
//  }
`
function ActualAchivementModal(props) {//NOSONAR
    const [state, setState] = useState({ actualValue: null, keyResultAchived: '', systemRating: null, actualRemark: "" })
    const [ratingFiles, setRatingFiles] = useState([])
    const [changedData, setChangedData] = useState(false)
    const [selectedFilesTime, setSelectedFilesTime] = useState([]);
    const inputActualAccess = props.actualKRData.actualInputAccess
    useEffect(() => {
        const { authUser, empId, categoryName, employeeId } = props
        const ratingData = OKRRatingServices.getRatingInfo(authUser, categoryName === OKR_PRODUCTIVE_ALLOCATION ? employeeId : empId, props.actualKRData.ratingId, 'actual' + getCategoryType(props.categoryType))
        ratingData.then((resultData) => {
            if (typeof resultData !== 'undefined' && resultData !== null) {
                setState({
                    ...state, actualValue: resultData[0].achievement,
                    keyResultAchived: resultData[0].isAchieved,
                    systemRating: resultData[0].achievedRating, actualRemark: resultData[0].achievedRemarks
                })
                var timeArr = [];
                for (var i = 0; i <= ratingFiles.length; i++) {
                timeArr.push(new Date());
                }
                setSelectedFilesTime(timeArr);
                setRatingFiles(resultData[0].ratingFiles.split(","))
            }
        });
        //eslint-disable-next-line
    }, [props.actualKRData.ratingId])
    const { actualKRData, OKRAllocationStatus } = props;
    let textareaObj;
    const onInput = (e) => {
        textareaObj.respectiveElement.style.height = "auto";
        textareaObj.respectiveElement.style.height = `${textareaObj.respectiveElement.scrollHeight}px`;
        cracterLimit(e, `remarkCount`, '1000')
    };
    const benchMarkData = benchmarkFormatData(actualKRData.benchmark);
    const getUnitName = (unit) => {
        let data = MeasuremenrUnit.filter((t) => t.Value === unit)
        return data[0].Name
    }
    const getSortByName = (sortBy) => {
        let data = MeasuremenrSortBy.filter((t) => t.Value === sortBy)
        return data[0].Name
    }

    const getCurrencyUnit = (Id) => {
        const { currencyInfo } = props
        if (Id !== null) {
            let data = currencyInfo.filter((t) => t.Id === Id);
            if (typeof data !== 'undefined' && data.length > 0)
                return data[0].var_optional1;
        }
        else {
            return ""
        }
    }
    const getCategoryType = (type) => {
        switch (type) {
            case "Self":
                return "self";
            case "Supervisor":
                return "sup"
            case "Third Party":
                return "tp"
            default: return ""
        }
    }
    const onChangeInput = (type, value, unit, sortyBy) => {
        if (type === 'actualValue' && value !== "") {
            let actualValue;
            if (unit !== 'Date') {
                actualValue = value
            } else {
                try {
                    actualValue = new Date(value.toDateString());
                } catch (e) {

                }
            }
            let data = checkActualAchivement(actualValue, unit, sortyBy);
            setState({ ...state, actualValue: actualValue, keyResultAchived: data.isAchived, systemRating: data.rating })
        } else {
            setState({ ...state, actualValue: null, keyResultAchived: '', systemRating: null })
        }
        if (type === 'remark') {
            setState({ ...state, actualRemark: value })
        }
    }
    const checkActualAchivement = (value, unit, sortyBy) => {
        let rating = 0, isAchived, benchMarkForYes;
        //eslint-disable-next-line
        benchMarkData.length > 0 && benchMarkData.forEach((item, inx) => {
            if (rating === 0) {
                if (unit !== 'Date') {
                    let FromValue = parseFloat(item.From, 10)
                    let ToValue = parseFloat(item.To, 10)
                    if (sortyBy === '2') { // High To Low
                        if (FromValue === 0) {
                            if (ToValue <= parseFloat(value, 10)) {
                                rating = inx + 1;
                            }
                        } else if (ToValue === 0) {
                            if (FromValue >= parseFloat(value, 10)) {
                                rating = inx + 1;
                            }
                        } else if (FromValue >= parseFloat(value, 10) && ToValue <= parseFloat(value, 10)) {
                            rating = inx + 1;
                        }
                    } else if (sortyBy === '1') { // Low to High
                        if (FromValue === 0) {
                            if (ToValue >= parseFloat(value, 10)) {
                                rating = inx + 1;
                            }
                        } else if (ToValue === 0) {
                            if (FromValue <= parseFloat(value, 10)) {
                                rating = inx + 1;
                            }
                        } else if (FromValue <= parseFloat(value, 10) && ToValue >= parseFloat(value, 10)) {
                            rating = inx + 1;
                        }
                    }
                    if (item.benchMarkForYes === '1') {
                        benchMarkForYes = inx + 1;
                    }
                } else {
                    let FromValue = item.From === null ? null : new Date(item.From)
                    let ToValue = item.To === null ? null : new Date(item.To)
                    if (sortyBy === '2') { // High To Low
                        if (FromValue === null) {
                            if (new Date(ToValue) <= new Date(value)) {
                                rating = inx + 1;
                            }
                        } else if (ToValue === null) {
                            if (new Date(FromValue) >= new Date(value)) {
                                rating = inx + 1;
                            }
                        } else if (new Date(FromValue) >= new Date(value) && new Date(ToValue) <= new Date(value)) {
                            rating = inx + 1;
                        }
                    } else if (sortyBy === '1') { // Low to High
                        if (FromValue === null) {
                            if (new Date(ToValue) >= new Date(value)) {
                                rating = inx + 1;
                            }
                        } else if (ToValue === null) {
                            if (new Date(FromValue) <= new Date(value)) {
                                rating = inx + 1;
                            }
                        } else if (new Date(FromValue) <= new Date(value) && new Date(ToValue) >= new Date(value)) {
                            rating = inx + 1;
                        }
                    }
                    if (item.benchMarkForYes === '1') {
                        benchMarkForYes = inx + 1;
                    }
                }
            }
        })

        if (rating >= benchMarkForYes) {
            isAchived = 'Yes'
        } else {
            isAchived = 'No'
        }

        return { rating: rating, isAchived: isAchived };
    }
    const saveActualAchivement = () => {
        const options = {
            rules: {
                actualValue: { required: [true, "Please enter actual value *"] },
                actualRemark: { required: [true, "Please enter remarks *"] },
            },
        };
        const addFormObject = new FormValidator("#actualAchivementForm", options);
        setTimeout(() => {
            if (addFormObject.validate()) {
                const { authUser, empId, OKRType, categoryType } = props;

                let data = {
                    "userId": authUser,
                    "empId": empId,
                    "ratingUId": actualKRData.ratingId,
                    "systemRating": state.systemRating,
                    "achievementType": parseInt(benchMarkData[0].Type),
                    "isRatingAchieved": state.keyResultAchived === 'Yes' ? 1 : 0,
                    "achievedValue": parseInt(benchMarkData[0].Type) === 1 ? getDate(new Date(state.actualValue)) : state.actualValue,
                    "remarks": state.actualRemark,
                    "achievedFiles": ratingFiles.toString() === "" ? null : ratingFiles.toString(),
                    "OKRType": OKRType,
                    "userType": getCategoryType(categoryType)
                }
                props.addEditActualRating(data);
            }
        }, 100)
    }

    const selectedFiles = (fileNms) => {
        try {
            setRatingFiles(fileNms);
            setTimeout(() => {
                setChangedData(!changedData)
            })
        } catch (error) { }
    };

    const getActualRating = () => {
        if (props.actionType === "Add" || props.actionType === "Edit") {
            return (<div className="view-kr-modal-body-text pt-xs">
                {
                    getUnitName(benchMarkData[0].Type) !== 'Date' ?
                        <NumericTextBoxComponent
                            id="actualValue"
                            placeholder='Achieved Value *'
                            floatLabelType="Auto"
                            cssClass="e-outline e-input-focus"
                            name='actualValue'
                            onChange={(e) => onChangeInput('actualValue', e.value, getUnitName(benchMarkData[0].Type), benchMarkData[0].sortBy)}
                            value={state.actualValue}
                            data-msg-containerid="actualValueError"
                            showSpinButton={false}
                        />
                        :
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                                inputVariant="outlined"
                                // margin="normal"
                                id="actualValue"
                                placeholder='Achieved Value *'
                                floatLabelType="Auto"
                                cssClass="e-outline e-input-focus"
                                allowKeyboardControl={false}
                                name='actualValue'
                                format="dd-MMM-yyyy"
                                KeyboardButtonProps={{
                                    "aria-label": "change date",
                                }}
                                onChange={(e) => onChangeInput('actualValue', e, getUnitName(benchMarkData[0].Type), benchMarkData[0].sortBy)}
                                value={state.actualValue}
                                data-msg-containerid="actualValueError"
                            />
                        </MuiPickersUtilsProvider>
                }
                <div style={{ margin: '0px -52px 4px 0px' }} id="actualValueError" className="e-input-group e-float-icon-left error-div" />
            </div>)
        } else {
            const achievedValueCurrency = (benchMarkData[0].Type === "2" ? `${state.actualValue}%` :
                (getCurrencyUnit(parseInt(benchMarkData[0].currency)) + " " + new Intl.NumberFormat('en-IN').format(state.actualValue)))

            return (
                <>
                    <div className="view-kr-modal-header-text">Achieved Value</div>
                    <div className="view-kr-modal-body-text pt-xs">{(benchMarkData[0].Type === "1" || benchMarkData[0].Type === "4") ? state.actualValue : achievedValueCurrency}</div>
                </>
            )
        }
    }
    return (
        <div>
            <Dialog
                open={true}
                fullWidth
                maxWidth="lg"
                onEscapeKeyDown={() => props.closeActualAchivementPopup()}
            >
                <DialogTitle>
                    <span className="add-new-self-header" style={{ marginTop: 15 }}>{props.actionType + ' '}{props.categoryType} Actual Achievement For {actualKRData.frequencyNm} </span>
                    <span style={{ position: 'absolute', right: 20, cursor: 'pointer' }}><FontAwesomeIcon onClick={() => props.closeActualAchivementPopup()} style={{ color: '#B7B7BB', fontSize: 20 }} icon={faTimes} /></span>
                </DialogTitle>
                <DialogContent>
                    <div className='col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                        <div className='col-6 col-lg-6 col-md-6 col-sm-6 col-xs-6'>
                            <div className="view-kr-modal-header-text">Key Result</div>
                            <div className="view-kr-modal-body-text pt-sm">{actualKRData.krName}</div>
                            <div className="view-kr-modal-header-text pt-sm">Description</div>
                            <div className="view-kr-modal-body-text pt-sm">{ReactHtmlParser(actualKRData.krDetails)}</div>
                        </div>
                        <div className='col-6 col-lg-6 col-md-6 col-sm-6 col-xs-6'>
                            <div className='row'>
                                <div className='col-3 col-lg-3 col-md-3 col-sm-3 col-xs-3'>
                                    <div className="view-kr-modal-header-text">Variable Link</div>
                                    <div className="view-kr-modal-body-text pt-sm"> {actualKRData.varibleLink === '1' ? 'Yes' : 'No'}</div>
                                </div>
                                <div className='col-3 col-lg-3 col-md-3 col-sm-3 col-xs-3'>
                                    <div className="view-kr-modal-header-text">Weightage %</div>
                                    <div className="view-kr-modal-body-text pt-sm"> {actualKRData.weightage}</div>
                                </div>
                            </div>
                            <div>
                                <div className="view-kr-modal-header-text pt-sm">Reference 1</div>
                                <div className="view-kr-modal-body-text pt-sm">{actualKRData.lable1}</div>
                            </div>
                            <div>
                                <div className="view-kr-modal-header-text pt-sm">Reference 2</div>
                                <div className="view-kr-modal-body-text pt-sm">{actualKRData.lable1}</div>
                            </div>
                        </div>
                        {/* <div className='col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pt-sm pl-md'>
                            {createImageFilepath(ratingFiles, 'okr/document/rating')}
                        </div> */}
                        <div className='col-12 col-lg-12 col-md-12 col-sm-12' >
                            <div className='col-6 col-lg-6 col-md-6 col-sm-6' style={(window.innerHeight < 909) && (props.actionType === "View") ? { borderRight: '1px solid #DBDBDD' } : (window.innerHeight < 909) ? {} : { borderRight: '1px solid #DBDBDD' }}//NOSONAR
                            >
                                <div className="view-kr-modal-body-text-primery pt-lg">Measurement Matrix for the Key Result</div>
                                <div className="row">
                                    <div className='col-4 col-lg-4 col-md-4 col-sm-4'>
                                        <div className="view-kr-modal-header-text pt-sm">Unit of Measurement</div>
                                        <div className="view-kr-modal-body-text pt-xs">{getUnitName(benchMarkData[0].Type)}</div>
                                    </div>
                                    <div className='col-4 col-lg-4 col-md-4 col-sm-4'>
                                        {/* <div className="view-kr-modal-header-text pt-sm">Reference 1</div>
                                        <div className="view-kr-modal-body-text pt-sm">Place holder text</div> */}
                                    </div>
                                    <div className='col-4 col-lg-4 col-md-4 col-sm-4'>
                                        <div className="view-kr-modal-header-text pt-sm">Sort Achievement</div>
                                        <div className="view-kr-modal-body-text pt-xs">{getSortByName(benchMarkData[0].sortBy)}</div>
                                    </div>
                                </div>
                                <div className='row'>
                                    <div>
                                        <BenchMarkTable benchMarkData={benchMarkData} unit={'view'} sortBy={''} />
                                    </div>
                                </div>
                            </div>
                            <div className='col-6 col-lg-6 col-md-6 col-sm-6' style={(window.innerHeight < 909) && (props.actionType !== "View") ? { borderLeft: '1px solid #DBDBDD' } : {}}>
                                <form id="actualAchivementForm">
                                    <div className="view-kr-modal-body-text-primery pt-lg">Actual Achievement
                                        {
                                            // OKRAllocationStatus === "Pending" && inputActualAccess === 'Y' && 
                                            props.actionType === "View" &&
                                            <span style={{ padding: "0px 0px 1.5em .5em", position: "absolute", marginTop: "-.1em" }}><Tooltips titleMessage={PleaseEnterTheActualAchievedValueBasedOnReferenceDocument} /></span>}
                                        <span style={{ padding: "0px 0px 1.5em .5em", position: "absolute", marginTop: "-.1em" }}><Tooltips titleMessage={PleaseEnterTheActualAchievedValueBasedOnReferenceDocument} /></span></div>
                                    <div className="row">
                                        <div className='col-4 col-lg-4 col-md-4 col-sm-4'>
                                            <div className="view-kr-modal-header-text pt-sm"></div>
                                            {getActualRating()}
                                        </div>
                                        <div className='col-4 col-lg-4 col-md-4 col-sm-4'>
                                            <div className="view-kr-modal-header-text pt-sm">Key Result Achieved</div>
                                            <div className="view-kr-modal-body-text pt-xs">{state.keyResultAchived}</div>
                                        </div>
                                        <div className='col-4 col-lg-4 col-md-4 col-sm-4'>
                                            <div className="view-kr-modal-header-text pt-sm">Rating</div>
                                            <div className="view-kr-modal-body-text pt-xs">{state.systemRating}</div>
                                        </div>
                                    </div>
                                    <div className='col-12 col-lg-12 col-md-12 col-sm-12 pt-md'>
                                        {
                                            (props.actionType === "View") &&
                                            <>
                                                <div className="view-kr-modal-header-text pt-sm">Remark</div>
                                                <div className="view-kr-modal-body-text pt-sm">{ReactHtmlParser(state.actualRemark)}</div>
                                            </>
                                        }
                                        {
                                            (props.actionType === "Add" || props.actionType === "Edit") &&
                                            <>
                                                <style>{sampleCss}</style>
                                                <TextBoxComponent
                                                    id='actualRemark'
                                                    placeholder={"Remark *"}
                                                    style={{ height: 100 }}
                                                    cssClass="e-outline textarea"
                                                    name='actualRemark'
                                                    floatLabelType="Auto"
                                                    ref={(scope) => {
                                                        textareaObj = scope;
                                                    }}
                                                    multiline
                                                    inputProps={{
                                                        required: true,
                                                        minLength: 7,
                                                    }}
                                                    htmlAttributes={{ maxlength: 1000 }}
                                                    data-msg-containerid="actualRemarkError"
                                                    input={onInput}
                                                    blur={(e) => onChangeInput('remark', e.value, getUnitName(benchMarkData[0].Type), benchMarkData[0].sortBy)}
                                                    value={state.actualRemark}
                                                />
                                                <div style={{ margin: '0px -52px 4px 0px' }} id="actualRemarkError" className="e-input-group e-float-icon-left error-div" />
                                                <div id='remarkCount' style={{ float: 'right', fontSize: 12, margin: '0px 0px 8px 0px' }} className="error-div" />
                                            </>
                                        }
                                    </div>

                                    {
                                        // OKRAllocationStatus === "Pending" && inputActualAccess === 'Y' && 
                                        (props.actionType === "Add" || props.actionType === "Edit") &&
                                        <>
                                            <div className=''>
                                                <div style={{ color: 'transparent' }} >.</div>
                                                <div className="view-kr-modal-header-text" >Upload Document(s)  <span style={{ padding: "0px 0px 0px 8px", position: "absolute" }}><Tooltips titleMessage={YouMayUploadSupportingDocumentForTheAchievedValue} /></span></div>
                                            </div>
                                            <div className='col-12 col-lg-12 col-md-12 col-sm-12 pt-xs'>
                                                <UploadDocuments                                                    
                                                    type={"rating-self"}                                                    
                                                    allowmultiple={true}
                                                    selectedFiles={selectedFiles}
                                                    filesTimeStamp={selectedFilesTime}
                                                    fileList={ratingFiles}
                                                    apiUrl={'tmlcApiUrl'}
                                                    cols={6}
                                                    module={"businessOKR"}
                                                />
                                            </div>

                                        </>}
                                    {
                                        (props.actionType === "View") &&
                                        <>
                                            <div style={{ color: 'transparent' }}>.</div>
                                            <div style={{display:'flex',flexDirection:'row',flexWrap:'wrap'}} >
                                            {createImageFilepath(ratingFiles, 'okr/document/rating')}
                                            </div>
                                        </>
                                    }
                                    {(OKRAllocationStatus !== "Pending" || inputActualAccess === 'N') && <>
                                        <div className="form-row">
                                            <div className="col-12 col-lg-12 col-md-12 col-sm-12 pt-xs">
                                                <div className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pt-sm ">
                                                    <span
                                                        style={{
                                                            color: "black",
                                                            cursor: "pointer",
                                                            fontFamily: "Circular Std",
                                                        }}>

                                                    </span> &nbsp;&nbsp; &nbsp;&nbsp;
                                                </div>
                                            </div>
                                        </div>
                                    </>}
                                </form>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <div className='mr-lg'>
                        {
                            // OKRAllocationStatus === "Pending" && inputActualAccess === 'Y' &&
                            (props.actionType === "Add" || props.actionType === "Edit") &&
                            <>
                                <Button
                                    style={{ width: "120px" }}
                                    className="cancel-button"
                                    variant="contained"
                                    onClick={() => props.closeActualAchivementPopup()}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    style={{ width: "120px", backgroundColor: '#F94F5E', color: '#fff' }}
                                    className="remove-button-shadow"
                                    variant="contained"
                                    onClick={() => saveActualAchivement()}
                                >
                                    Save
                                </Button>
                            </>
                        }
                    </div>
                </DialogActions>
            </Dialog>
        </div>
    )
}
const mapStateToProps = ({ okrreducer, okrratingreducer, auth, meetingcategory }) => {
    const { authUser } = auth;
    const { OkrMasters, employeeId } = okrreducer;
    const { empId } = okrratingreducer;
    const { categoryName } = meetingcategory;

    return { OkrMasters, authUser, empId, categoryName, employeeId }
};
export default connect(mapStateToProps, { addEditActualRating })(ActualAchivementModal);
