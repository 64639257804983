import React from "react";
import {
  actualMettingDayDifference,
  addMaOkrKPILimit,
} from "../../config/config";
import * as CryptoJS from 'crypto-js';
/**
 * Notification
 */

export const Announce = "Announce";
export const Follow = "Follow";
export const TagForLeaveAnnounce = "Type @ and tag to add team members to Announce";
export const TagForLeaveFollow = "Type @ and tag to add team members to Follow";

/**
 * Error Messages
 */
export const ERROR = "error";
export const meetingStartTimeMustBeLessThanEndTime =
  "The meeting start time must be less than the end time.";
export const pleaseFillAllTheRequiredFields =
  "All fields marked with * are mandatory.";
export const pleaseEnterMeetingTitle = "Please enter the Meeting Title.";
export const YouCannotChangeTheMeetingDetails =
  "The meeting details cannot be edited.";
export const ForPlanMeetingStartTimeAndEndTimeMustBeGreaterThanCurrentTime =
  "The planned meeting start time and end time must be greater than/after the current time.";
export const ForActualMeetingStartTimeAndEndTimeMustBeLessThanCurrentTime =
  "The actual meeting start and end time must be less than/prior to the current time.";
export const startTimeMustBeGreaterThanCurrentTime =
  "The planned meeting start time and end time must be greater than/after the current time.";
export const startTimeMustBeLesserThanCurrentTime =
  "The actual meeting start and end time must be less than/prior to the current time.";
export const endTimeMustBeLesserThanCurrentTime =
  "The actual meeting start and end time must be less than/prior to the current time.";
export const endTimeMustBeGreaterThanCurrentTime =
  "The planned meeting start time and end time must be greater than/after the current time.";
export const PleaseEnteraValidBTBId = "Please input valid BTB ID.";
export const pleaseEnterValidLink =
  "The meeting link is not valid. Please enter the complete link.";
export const pleaseEnterAppropriateLink =
  "Please enter the complete meeting link.";
export const templateSaveError =
  "Error. The template could not be saved. Please try again.";
export const pleaseEnterTemplateName = "Please enter a unique Template Name.";
export const leaveMeetingException =
  "All the meetings within the leave meeting time range will be declined and individual meetings will get auto-canceled.";

/**
 * Success Messages
 */
export const SUCCESS = "success";
export const meetingSavedSuccessfully = "The meeting has been saved.";
export const partialAttendeeMarkedFullAttendee =
  "Meeting updated successfully and partial attendee(s) marked as an attendee(s).";
export const MeetingCancelledSuccessfully = "The meeting has been cancelled.";

export const meetingDeclinedSuccessfully = "The meeting has been declined.";
export const meetingDidNotAttendedSuccessfully =
  "You have marked as not attended.";

export const SeriesMeetingCancelledSuccessfully =
  "All the meetings in the selected series has been cancelled.";
export const linkCopiedSuccessfully =
  "The link has been copied to the clipboard.";
export const timeZoneConfigSuccessfully = "Your settings have been saved.";
export const update_meeting_successfully =
  "The changes made in the settings have been saved.";
export const templateSavedSuccessfully = "The meeting template has been saved.";

export const businessFunctionAddedSuccessfully =
  "The Business Unit / Group Function  added successfully.";
export const businessFunctionUpdatedSuccessfully =
  "The Business Unit / Group Function  modified successfully.";
export const businessFunctionStatus =
  "The Business Unit / Group Function  status updated successfully.";
export const businessFunctionExists =
  "Entered Business Unit / Group Function  already exists.";
export const businessFunctionAssociated =
  "There are some employees who are assosciated with this Business Unit / Group Function .";

export const businessFunctionStatusActive =
  "Business Unit / Group Function  Activated Successfully.";
export const businessFunctionStatusInActive =
  "Business Unit / Group Function  inactivated Successfully.";

export const layerMasterFunctionAddedSuccessfully =
  "The Layer Master Function added successfully.";
export const subLayerMasterFunctionAddedSuccessfully =
  "The Sub Layer Master Function added successfully.";

export const layerMasterStatus =
  "The Layer Master status updated successfully.";
export const layerMasterUpdatedSuccessfully =
  "The Layer Master modified successfully.";
export const layerMasterAddedSuccessfully =
  "The Layer Master added successfully.";
export const layerMasterExists = "Entered Layer already exists.";
export const layerMasterAssociated =
  "There are some employees who are associated this with Layer.";

export const userRoleAddedSuccessfully = "Role / Title added successfully.";
export const UserRoleStatus = "The User Role status updated successfully.";
export const UserRoleUpdatedSuccessfully =
  "The User Role modified successfully.";
export const UserRoleAddedSuccessfully = "The User Role added successfully.";
export const UserRoleExists = "Entered Role / Title already exists.";
export const UserRoleAssociated =
  "There are some employees who are associated this with This Role / Title.";

export const subLayerMasterStatus =
  "The Sub Layer Master status updated successfully.";
export const subLayerMasterUpdatedSuccessfully =
  "The Sub Layer Master modified successfully.";
export const subLayerMasterAddedSuccessfully =
  "The Sub Layer Master added successfully.";
export const sublayerMasterExists = "Entered Sub Layer already exists.";
export const sublayerMasterAssociated =
  "There are some employees who are associated with this Sub Layer.";

/**
 * Warning Messages
 */
export const WARNING = "warning";
export const youCannotAddFutureMeetingInActualSection =
  "You cannot add a plan meeting in actual slot.";

// "You cannot add a meeting in actual before the meeting end time has passed.";
export const YouCannotAddMeetingInActualSection =
  "You cannot add a meeting in actual before the meeting end time has passed.";
export const YouCannotEditThisMeetingByDragging =
  "You cannot edit this meeting by draging, please click on edit ICON to edit.";
export const YouCannotPasteMeetingInActual =
  "You cannot paste meeting in Actual";
export const PasteMeetingInActualIsNotAllowed =
  "You cannot copy & paste any client meeting.";
export const LeaveTypeMeetingNotAllowed =
  "Copy & Paste of leave type meeting is not allowed";
export const youCannotAddBackDatedMeeting =
  "You cannot schedule a meeting prior to the current time.";
export const YouCannotAddFutureMeetingInActualWindow =
  "You cannot add a meeting in actual before the meeting end time has passed.";

export const FileSize = "You can only select a file with file size upto 10 MB.";
export const FileType = "Please select jpeg, jpg or pdf format files only.";

export const YouCannotAddBackDatedMeetingInPlanSection =
  "You cannot schedule a meeting prior to the current time.";
export const YouCannotAddMeetingWhenInLeave =
  "You cannot schedule a meeting as you are on leave.";
export const MeetingSlotNotAvailable =
  "The selected meeting slot is not available.";
export const MeetingSlotNotAvailableOneOrMore =
  "The selected meeting slot is not available for one or more attendees.";
export const youHaveNoAcceccToAddMeetings =
  "You do not have the permissions to add this meeting.";
export const PleaseSelectTheUserfromDropdown =
  "Please select the user from the dropdown.";
export const YouCannotPasteMeetingPriortoCurrentTime =
  "You cannot paste a meeting prior to the current time.";
export const YouCannotPasteMeetingToActual =
  "You Cannot Paste Meeting To Actual .";

//Error Messages

export const EnterbusinessFunctionUnit =
  "Please enter business unit function name";
export const SelectHierarchy = "Please select hierarchy";
export const EnterCompanyName = "Please enter company name";
export const EnterAbbrevation = "Please enter 2 characters only";
export const EnterDomainName = "Please enter domain name";
export const ValidDomainName = "Please enter valid domain name";
export const EnterLayerMasterName = "Please enter layer name";
export const EnterSubLayerName = "Please enter sub layer name";
export const EnterRole = "Please enter role / title";
export const EnterDigitalDomainName = "Please enter digital email domain name.";
export const EnterTeamsDomainName = "Please enter teams email domain name.";
export const EnterpnlHeadName = "Please enter p&l head.";
export const EnterFolder = "Please enter folder name";
export const EnterSpecialCharacter = 'Please do not use characters /:"*?<>|';
export const ParentRequired = "Please select a parent unit.";

/**
 * Mandatory Field.
 */
export const pleaseSelectAppropriateMeetingServiceProduct =
  "Please select the Service/Platform(s).";
export const pleaseSelectAppropriateMeetingSource =
  "Please select the Meeting Source.";
export const pleaseEnterMeetingBTBID = "Please enter the BTB ID.";
export const pleaseSelectClientName = "Please select the Client Attendee.";
export const pleaseEnterClientName =
  "Please enter the Group, Company or Client Name.";
export const pleaseEnterMeetingAgenda = "Please enter the Meeting Agenda.";
export const pleaseSelectMeetingRoom = "Please select the Meeting Room.";
export const pleaseSelectChairPersonForThisMeeting =
  "Please select the Chairperson for this meeting.";
export const pleaseEnterAppropriateLinkDetail =
  "Please enter valid meeting link.";
export const checkUnavailabilityOfuser =
  "Please check the availability of the attendee(s).";
export const pleaseAddMinimumTwoParticipant =
  "Please add attendee. This meeting type requires a minimum of two attendees.";
export const pleaseSelectCampaignData = "Please select the Campaign.";
export const pleaseSelectPracticeAreaData = "Please select the Practice Area.";
export const pleaseSelectMarketActivityData =
  "Please select the Marketing Activity.";

export const pleaseSelectAppropriateMeetingMedia =
  "Please select the Meeting Media.";
export const pleaseselectappropriateMeetingType =
  "Please select the Meeting Type.";
export const pleaseselectappropriateMeetingSubType =
  "Please select the Activity Type.";
export const pleaseselectTimeZoneforthismeeting =
  "Please select the default Time zone for this meeting.";
export const pleaseselectMeetingEndTimeforthismeeting =
  "Please select the Meeting End Time for this meeting.";
export const pleaseselectMeetingStartTimeforthismeeting =
  "Please select the Meeting Start Time for this meeting.";
export const pleaseselectMeetingDateforthismeeting =
  "Please select the Meeting Date for this meeting.";
export const pleaseselectMeetingAttendeeforthismeeting =
  "Please select Meeting Attendee for this meeting.";
export const pleaseselectappropriateContactsforthismeeting =
  "Please select the Client attendee for this meeting.";
export const therearenoContactsfortheselectedEntity =
  "There are no Client(s) added for this Group/Company.";
export const maxTimeZoneSelection =
  "You can select up to a maximum of three time zones only.";
export const minTimeZoneSelection = "Please select at least one time zone.";

export const pleaseselectappropriatesubcategory =
  "Please select the Activity type.";
export const pleaseSelectAppropriateBtb = "Please select the BTB ID.";
export const pleaseselectappropriatejob = "Please select the Sub BTB Id.";

export const noBTBAvailable =
  "There is no BTB available for this Activity Type.";
export const noJobsAvailable = "There are no Sub BTB Ids this BTB.";
export const pleaseUserViewDropdown =
  "Please select the Team Member to view the calendar.";
export const timeShouldBeGreaterThanCurrentTime =
  "The new suggested meeting time cannot be prior to the current time";
export const movePlanToActualFiveDaysOnly =
  "You can edit the actual calendar slot upto " +
  actualMettingDayDifference +
  " days prior to the current day.";

export const validDate = "Please select a date.";
export const selectStartDateEndDate =
  "Please select the start date and end date.";
export const selectEndDate = "Please select an end date.";
export const selectEndDateGreaterStartDate =
  "Please select an end date which is greater than the start date.";
export const selectStartTime = "Please select a start time.";
export const selectEndTime = "Please select an end time.";
export const selectDefaultTimeZone = "Please select a default time zone.";
export const minimumTenHoursWorkday =
  "The minimum duration for a workday setting is 10 hours.";
export const workDayALreadyAddedForDate =
  "The Workday settings are already added for the selected date.";
export const canNotDeleteDefaultWorkDay =
  "You cannot delete the default work day setting.";
export const maxFiveWorkDaySettingAllowed =
  "Maximum 5 work day settings are allowed.";

export const oneOrMoreAttendeesAreAvailable =
  "One or more attendees are unavailable or on a Break during this meeting slot. Are you sure you want to save the meeting?";
export const areYouSureUWantToSaveMeeting =
  "Are you sure you want to save the meeting?";
export const meetingAcceptanceMsg = "Meeting Acceptance";
export const acceptanceSpecificMeetingOrSeries =
  "Please select whether you want to Accept this specific Meeting or Whole Series?";
export const areYouSureAcceptMeetingRequest =
  "Are you sure you want to Accept the Meeting Request?";
export const areYouSureNotAttendThisMeeting =
  "Are you sure you have not attended this meeting?";

export const declineSpecificMeetingOrSeries =
  "Please select if you would like to Decline this specific Meeting or the Meeting Series?";
export const areYouSureDeclineMeetingRequest =
  "Are you sure you want to Decline the Meeting Request?";
export const meetingDeclineMsg = "Meeting Decline";
export const meetingDidNotAttendMsg = "Did Not Attend";

export const impactPerformancePersentage =
  "Total Performance Weightage (%) must be 100%.";
export const impactPotentialPersentage =
  "Total Potential Weightage (%) must be 100%.";
export const impactPerformancePotentialPersentage =
  "Total Performance weightage  and Potential weightage  (%) must be 100%";

export const ECReportWarning =
  "Please select Practice Area / Sales Unit / Sub Function / User to view report.";
export const ECReportWarningMaxUser =
  "Please select max 5 items from Practice Area / Sales Unit / Sub Function / User";
export const ECReportMsg =
  "Please select Practice Area / Sales Unit / Sub Function / User";
export const monthSelect = "Please select month";
export const viewByTypeSelect = "Please select view by type";
export const dataFields = "Please select data field";

export const variablePerformancePersentage =
  "Total Performance Impact on Variable Pay (%) must be 100% or 0%.";
export const variablePotentialPersentage =
  "Total Potential Impact on Variable Pay (%) must be 100% or 0%.";
export const variablePersentage =
  "Total Performance Impact on Variable Pay and Potential Impact on Variable Pay (%) must be 100% or 0%.";
export const userObjectiveSavedSuccessfully =
  "Team member's Objective saved as draft successfully.";
export const userObjectiveUpdatedSuccessfully =
  "Team member's Objective updated as draft successfully.";
export const userKPISavedSuccessfully =
  "Team member's Key Result updated as draft successfully.";
export const userKPIUpdatedSuccessfully =
  "Team member's Key Result updated as draft successfully.";
export const pendingUserKPIUpdatedSuccessfully =
  "Team member's Key Result updated successfully.";
export const pendingUserKPISavedSuccessfully =
  "Team member's Objective updated successfully.";
export const pleaseEnterDescription = "Please enter description.";
export const userObjectiveKPISavesSuccessfully =
  "Team member's OKR saved successfully.";

export const productivityAllocationPopHeaderMessage =
  "Once saved the weightage allocation cannot be changed.";
export const productivityAllocationPopupSubMessage =
  "Please confirm if you wish to save?";
export const pleaseSelectAtleastOneObjective =
  "Please select at least one Objective.";
export const roleObjDeleteSuccessfully = "OKR deleted successfully.";
export const roleKPIDeleteSuccessfully = "Key Result deleted successfully.";
export const searchObjKPITagsNotAvailable =
  "Searched Objective, Key Result or Tags not available.";
export const objdeleteConfernamation =
  "Are you sure you want to delete the Objective?";
export const objDisableConfernamation =
  "Are you sure you want to delete the Objective?";
export const objDeactivateConfiramation =
  "The Deactivated OKR will be unavailable for future ratings. Any period for which rating has been completed for this OKR can be viewed in “Closed” tab.";

export const objCopyConfiramation =
  "You may save a copy of the OKR in “Drafts” tab for future use. If not, this OKR will be unavailable and you may need to add it afresh through your OKR Library.";
export const KpideleteConfernamation =
  "Are you sure you want to delete the Key Result?";
export const pleaseSelectObjWhichHaveOneKPI =
  "You can not add more than " +
  addMaOkrKPILimit +
  " Key Results in one Objective.";
export const youCanNotAddMoreThanFiveKPI =
  "You can not add more than " +
  addMaOkrKPILimit +
  " Key Result in one Objective.";
export const objectivefillData =
  "An objective is a statement of a broad goal that you wish to achieve. Please write a short description of the objective to be achieved.";
export const frequencyFillData =
  "Frequency at which you would want to review this objective.";
export const effectiveFromFillData =
  "Date from which you would want this objective to be effective.";
export const variablePercentageFillData =
  "'OKR's Variable Impact %' which states The total of all Variable Impact percentages allocated across all Objectives must be equal to 'Impact on Variable Pay (%)' defined for Business OKRs";
export const variablePercentage =
  "Percentage of variable pay to be allocated to this objective";
export const pleaseSelectFrequency = "Please select Frequency.";
export const pleaseSelectEffectiveFrom = "Please select OKR Validity Starts.";
export const pleaseSelectEffectiveTo = "Please select OKR Validity Ends.";
export const pleaseEnterVariable = "Please enter Variable % .";
export const pleaseEnterVariableMaxMin =
  "Variable % less than or equal to 100%.";
export const pleaseEnterVariableMin = "Minimum variable % is 0.";
// export const variablePercentageValidation = "Total of selected okrs and active okrs variable % should not be more than Impact on variable pay (%) " nosonar
export const variablePercentageValidation =
  "The total of all Variable Impact percentages allocated across all Objectives must be equal to 'Impact on Variable Pay (%)' defined for Business OKRs.";
export const kpiFillDetails =
  "Key Results are the parameters/benchmarks to measure the achievement of the Objectives. Please add Key Results to measure achievement of Objective.";
export const descriptionFillDetails =
  "Please add a detailed description of the Key Result.";
export const measureFillDetails =
  "Please specify what is the core criterion based on which the Key Result achievement is measured. Eg. For a Key Result titled 'Revenue Achievement',the Measure could be 'Amount collected from the client'";
export const Lable1 =
  "Please provide the URL / location of reference document / hyperlink / \ncollateral reference, that would be referred to ascertain the Key Result outcome.";
export const Lable2 =
  "Please provide the URL / location of reference document / hyperlink / \ncollateral reference, that would be referred to ascertain the Key Result outcome.";
export const variableLinkFillDetails =
  "Choose Yes/No to indicate if the Key Result achievement would impact variable pay, if applicable.";
export const benchmarkForYesFillDetails =
  "Minimum score to decide whether the Key Result target has been achieved.Any score above the benchmark score would be marked as 'Yes' and any score below the benchmark score would be marked as 'No' at the time of review.";
export const weightageFillDetails =
  "% impact that this Key Result will have on the objective Extent of visibility for this Key Result within the organisation.";
export const ScopeFillDetails =
  "Extent of visibility for this Key Result within the organisation.\n\n" +
  "1. Private - Visible only to team member and supervisor.\n " +
  "2. Organisation - Visible to chosen team members across the organisation.\n" +
  "3. Function - Visible only to team members of chosen function.";
export const selectUserFillDetails = "Select Team member's.";
export const pleaseEnterMeasure = "Please enter measure.";
export const pleaseSelectVariableLink = "Please select Variable Link.";
export const pleaseSelectBenchmarkForYes =
  "Please select the benchmark score for marking the achievement of this Key Result as 'Yes'";
export const pleaseEnterWeightage = "Please enter weightage.";
export const pleaseEnterMaxWeightage = "Please enter maximum 100 %.";
export const pleaseEnterMinWeightage = "Please enter maximum 0 %.";
export const pleaseSelectScope = "Please select scope.";
export const pleaseSelectUser = "Please select Team member's.";
export const businessOkrDetails = "Business OKR details.";
export const disableObjSuccessfully = "OKR deleted successfully.";
export const EnableObjSuccessfully = "OKR enable successfully.";
export const objectiveDetails =
  "An objective is a statement of a broad goal that you wish to achieve. Please write a short description of the objective to be achieved.";
export const objectiveTagsData =
  "To simplify future search of objective using key words.";
export const objectiveVisibility = "Classify who can view this OKR.";
export const objectiveLavel = "Objective level.";
export const kpiNameDetails =
  "Key Results(KR) are the parameters/benchmarks to measure the achievement of the Objectives. Please add KRs to measure achievement of the Objective.";
export const KPITagsDetails =
  "Tags - To simplify future search of objective using key words.";
export const KPIDiscriptionDetails =
  "Please add a detailed description of the Key Result.";
export const centralKPIDescription = "Central OKR description.";
export const centralKPIWeightage = "Central OKR Weightage.";
export const centralKPIVariableLink = "Central OKRs' Variable Linkage.";
export const centralKPIVariableWeightage = "Central OKRs' Variable Weightage.";
export const lpKPIDescription = "View Leadership Principles description.";
export const lpKPIWeightage = "Leadership Principles Weightage";
export const lpKPIVariableLink = "Leadership Principles Variable Link.";
export const bvKPIDescription = "Brand Value description.";
export const bvKPIWeightage = "Brand Value Weightage.";
export const bvKPIVariableLink = "Brand Value Variable Link.";

export const pleaseEnterSubBusinessUnitFunctionName =
  "Please enter sub-business unit function name";
export const pleaseEnterPracticeArea =
  "Please enter practice area / sales unit / sub function";
export const pleaseEnterSubLayerName = "Please select sub-layer name";
export const pleaseEnterLayerName = "Please enter layer name";
export const pleaseSetHierarchy = "Please select hierarchy";
export const pleaseSetParentLayer = "Please select parent layer";
export const pleaseselectsubpnlhead = "Please select Sub P&L Function Head.";
export const selectPnlHead = "Please select  P&L Function Head.";
export const setserviceareahead = "Please select  Service Area Head.";
export const practiceAreaInactivatedSuccessfully =
  "Practice Area / Sales Unit / Sub Function inactivated successfully.";
export const practiceAreaActivatedSuccessfully =
  "Practice Area / Sales Unit / Sub Function activated successfully.";
export const practiceAreaModifiedSuccessfully =
  "Practice Area / Sales Unit / Sub Function modified successfully.";
export const practiceAreaAddedSuccessfully =
  "Practice Area / Sales Unit / Sub Function added successfully.";
export const practiceAreaExists =
  "Entered Practise Area / Sales Unit already exists.";
export const practiceAreaAssociated =
  "There are some employees who are associated with this Practise Area / Sales Unit.";

export const layerInactivatedSuccessfully = " Layer inactivated successfully.";
export const layerActivatedSuccessfully = " Layer activated successfully.";
export const layerModifiesSuccessfully = " Layer modified  successfully.";
export const layerAddedSuccessfully = " Layer added successfully.";

export const sublayerInactivatedSuccessfully =
  " Sub Layer inactivated successfully.";
export const sublayerActivatedSuccessfully =
  " Sub Layer activated successfully.";
export const sublayerModifiesSuccessfully =
  " Sub Layer modified  successfully.";
export const sublayerAddedSuccessfully = " Sub Layer added successfully.";

export const subBusinessUnitFunctionInactivatedSuccessfully =
  " Sub Business Unit / Function inactivated successfully.";
export const subBusinessUnitFunctionActivatedSuccessfully =
  " Sub Business Unit / Function activated successfully.";
export const subBusinessUnitFunctionModifiedSuccessfully =
  " Sub Business Unit / Function modified  successfully.";
export const subBusinessUnitFunctionAddedSuccessfully =
  " Sub Business Unit / Function added successfully.";
export const subBusinessUnitFunctionExists =
  "Entered Sub Business Unit / Function already exists.";
export const subBusinessUnitFunctionAssociated =
  "There are some employees who are associated with this Sub Business Unit / Function.";

export const workingDayInactivatedSuccessfully =
  "Working Day inactivated successfully.";
export const workingDayActivatedSuccessfully =
  "Working Day activated successfully.";
export const workingdayModifiedSuccessfully =
  "Working day modified successfully.";
export const workingdayAddedSuccessfully = "Working day added successfully.";
export const workingdayExists = "Entered Working day already exists.";
export const workingdayAssociated =
  "There are some employees who are associated with this Working day.";

export const statutoryInactivatedSuccessfully =
  "Statutory inactivated successfully.";
export const statutoryActivatedSuccessfully =
  "Statutory activated successfully.";
export const statutoryModifiedSuccessfully = "Statutory modified successfully.";
export const statutoryAddedSuccessfully = "Statutory  added successfully.";
export const statutoryExists = "Entered Statutory  already exists.";
export const statutoryAssociated =
  "There are some employees who are associated with this Statutory.";

export const exceptionalInactivatedSuccessfully =
  "Exceptional Attendence inactivated successfully.";
export const exceptionalActivatedSuccessfully =
  "Exceptional Attendence successfully.";
export const exceptionalModifiedSuccessfully =
  "Exceptional Attendence successfully.";
export const exceptionalAddedSuccessfully =
  "Exceptional Attendence  added successfully.";
export const exceptionalExists =
  "Entered exceptional attendence  already exists.";
export const exceptionalAssociated =
  "There are some employees who are associated with this exceptional attendence.";

export const colourInactivatedSuccessfully =
  "Color master inactivated successfully.";
export const colourActivatedSuccessfully =
  "Color  master activated successfully.";
export const colourModifiedSuccessfully = "Color  master edited successfully.";
export const colourAddedSuccessfully = "Color  master added successfully.";
export const colourExists = "Entered color unit already exists.";
export const colourAssociated =
  "There are some employees who are associated with this color.";

export const roleInactivatedSuccessfully =
  "Role master inactivated successfully.";
export const roleActivatedSuccessfully = "Role master activated successfully.";
export const roleModifiedSuccessfully = "Role master edited successfully.";
export const roleAddedSuccessfully = "Role master added successfully.";
export const roleExists = "Entered role name already exists.";
export const roleAssociated =
  "There are some employees who are associated with this role.";
export const roleInvalidRoleNameMessage = "Please enter role name.";

export const ObjAllocatedSuccessfully = "OKR allocated to user successfully.";
export const pleaseSelectObj = "Please select at least one OKR";
export const pleaseCompleteProductivityAllocation =
  "Please complete the OKR allocation.";
export const pleaseSelectImpoFrom = "Please select import from.";
export const pleaseSelectFyId = "Please select financial year.";
export const pleaseTypeSearchText = "Please type search text.";
export const max5objInActiveTab = "You can have maximum 5 active OKRs.";
export const userProductiveAllocationUpdateMessage =
  "User OKR allocation updated successfully.";
export const variablePercentageNote =
  "Note : The total of all OKR's Variable Impact percentages allocated across all Objectives must be equal to 100%.";

export const selectAtLeastOneOptionViewBy =
  "Please select at least one view by option.";
export const selectAtLeastOneOptionBucket =
  "Please select at least one bucket.";
export const selectAtLeastOneOptionSD =
  "Please select at least one service division.";
export const FromMonthShouldLesserThanToMonth =
  "Start month should be lesser than End month";
export const Max12MonthsAllowed = "Max 12 months range is allowed";

export const folderManagementInactivatedSuccessfully =
  " Folder inactivated successfully.";
export const folderManagementActivatedSuccessfully =
  " Folder activated successfully.";
export const folderManagementModifiedSuccessfully =
  " Folder modified  successfully.";
export const folderManagementAddedSuccessfully = " Folder added successfully.";
export const folderManagementFunctionExists = "Entered Folder already exists.";
export const folderManagementFunctionAssociated =
  "There are some Folder which are associated with this Parent.";
export const pleaseEnterFolderName = "Please enter folder name.";
export const folderCannotInactive =
  "Have existing documents, folder cannot be Inactive";

/**
 * LNA Cluster Messages
 */
export const symbolAddedSuccessfully = " Symbol add/edit successfully.";

/** 
 * # Following const are repeted from Error Messages #

    export const pleaseEnterValidLink= "* Please enter valid link.";
    export const pleaseEnterMeetingTitle= "* Please enter Meeting Title .";

 */
export const borderLeftStyle = "5px solid";
export const borderStyle = "";
export const fontColorStyle = "#000";

export const breakBorderLeftStyle = "";
export const breakBorderStyle = "2px solid";
export const breakBorderColorStyle = "#FF3342";
export const breakFontColorStyle = "#B4B4B4";

export const selfBorderColorStyle = "#B5B3B3";
export const selfBorderStyle = "0px solid";
/**
 * TMLC:Onboarding, Employee Profile
 * Salary tab
 * Author : Vivek Khodade.
 * */
export const basicSalaryPersentageData = [
  { Id: 1, Value: 30, Name: "30%" },
  { Id: 2, Value: 40, Name: "40%" },
  { Id: 3, Value: 50, Name: "50%" },
];
export const LTAData = [
  { Id: 1, Value: 0, Name: "0%" },
  { Id: 2, Value: 3, Name: "3%" },
  { Id: 3, Value: 5, Name: "5%" },
];
export const employeePFData = [
  { Id: 1, Value: 1, Name: "Yes" },
  { Id: 2, Value: 0, Name: "No" },
];
export const medicalReimbursementData = [
  { Id: 1, Value: 1, Name: "Yes" },
  { Id: 2, Value: 0, Name: "No" },
];
export const mealVouchersData = [
  { Id: 2, Value: 0, Name: "No", flag: 0 },
  { Id: 3, Value: 6000, Name: "6000", flag: 1 },
  { Id: 4, Value: 12000, Name: "12000", flag: 1 },
  { Id: 5, Value: 24000, Name: "24000", flag: 1 },
];
export const carAllowanceData = [
  { Id: 1, Value: 0 },
  { Id: 2, Value: 37200 },
  { Id: 3, Value: 32400 },
];
export const generateAppointmentLatterDropdown = [
  { Id: 1, Value: "with_variable", Name: "With Variable" },
  { Id: 0, Value: "without_variable", Name: "Without Variable" },
];
export const noticePeriodData = [
  { Id: 1, Value: 1, Name: "1 Month" },
  { Id: 2, Value: 2, Name: "2 Month" },
  { Id: 3, Value: 3, Name: "3 Month" },
  { Id: 4, Value: 4, Name: "4 Month" },
  { Id: 5, Value: 5, Name: "5 Month" },
  { Id: 6, Value: 6, Name: "6 Month" },
];

export const probationPeriod = [
  { Id: 1, Value: 3, Name: "3 Month" },
  { Id: 2, Value: 6, Name: "6 Month" },
];
// Description:- Add all pre offer module constant error , worning, success message

// Error message
export const pleaseEnterFirstName = "Please enter first name";
export const pleaseEnterLastName = "Please enter last name";
export const pleaseEnterMobileName = "Please enter mobile number";
export const pleaseEnterEmailName = "Please enter email Id";
export const pleaseEnterValidEmailId =
  "Please enter valid email (abc@domain.com)";
export const pleaseSelectHighestEducationQualification =
  "Please select highest qualification";
export const pleaseEnterHighestEducationQualificationSpecial =
  "Please enter Specialization";
export const Please_enter_work_experiance = "Please enter work experience";
export const pleaseEnterAddress = "Please enter address";
export const pleaseEnterCity = "Please enter city";
export const pleaseEnterState = "Please enter state";
export const pleaseEnterJobTitle = "Please enter role / title";
export const pleaseEnterValidRole = "Please enter valid role";
export const pleaseSelectBand = "Please select band";
export const pleaseSelectEmployeeType = "Please select employee type.";
export const pleaseSelectBaseCountry = "Please select country of work.";
export const pleaseEnterBaseLocation = "Please enter office location";
export const pleaseSelectEntity =
  "Please select Business Unit / Group Function ";
export const pleaseSelectServceDivisionSalesUnit =
  "Please select practice area / sales unit / sub function";
export const pleaseSelectReportingSupervisor =
  "Please select reporting supervisor";
export const pleaseSelectRecruitmentManager = "Please select recruiter";
export const pleaseSelectGender = "Please select gender";
export const pleaseSelectSalutation = "Please select salutation";
export const pleaseUploadImage = "Please upload image";
export const pleaseSelectBirthDate = "Please select date of birth";
export const pleaseSelectJoiningDate = "Please select expected date of joining";
export const pleaseEnterLegalName = "Please enter legal name";
export const pleaseSelectTenureEndDate = "Please select tenure end date";
export const pleaseSelectLayer = "Please select layer";
export const pleaseSelectSubLayer = "Please select subLayer";
export const pleaseSelectCurrency = "Please select currency";
export const pleaseSelectCTCFrequency = "Please select CTC Frequency ";
export const pleaseEnterFY = "Please select financial year";
export const updateOKRLockSuccess = "OKR lock month updated successfully.";
export const pleaseEnterOptional =
  "Please enter optional holiday in number format.";
export const pleaseEnterChangeRequest =
  "Please enter change request days in number format.";
export const pleaseEnterWeightagedVariable = "Please enter weightage variable.";
export const pleaseEnterWeightAllocation = "Please enter weight allocation.";
export const pleaseEnterGroupName = "Please enter group name.";
export const pleaseEnterXAxisMeaning = "Please enter axis X meaning.";
export const pleaseEnterYAxisMeaning = "Please enter axis Y meaning.";
export const pleaseEnterWeightAllocationTotal =
  "Weight Allocation and Variable total must be 100.";

// candidate doc submission
export const SUBMIT_TITLE = "Submit Documents";
export const SUBMIT_CONFIRM_MSG =
  "Are you sure you want to submit the documents?";
export const SUBMIT_ALREADY_MESSAGE =
  "You have already submitted the documents.";
export const SUBMIT_MESSAGE = "Thank you for submitting the documents.";
export const SUBMIT_WARNING_MESSAGE = "Please upload all required documents.";
export const FILL_ALL_FILED = "Please fill all mandatory details.";
export const PleaseUploadProfilePicture = "Please upload the profile Picture.";

// Resignation messages
export const pleaseSelectReason = "Please select appropriate reasons.";
export const pleaseEnterReason = "Please enter the leave cancel reason.";
export const pleaseWriteReason = "Please enter appropriate remark.";
export const plaeaseSelectWillingToBuyBackNotice =
  "Please select appropriate willing to buyback Notice.";

//Absconding message
export const enterAbscondingManagerRemark =
  "Please enter appropriate manager's remark.";
export const enterAbscondingDateRemark = "Please select appropriate date.";
//Termination
export const selectTerminationRequestFor =
  "Please select appropriate termination Request for.";
export const selectSuspensionDaysFor =
  "Please select appropriate suspension days.";
export const selectTerminationGrounds =
  "Please select appropriate termination grounds.";
export const enterTerminationManagerRemark =
  "Please enter appropriate manager's remark.";
export const selectLastWorkingDate = "Please select appropriate date..";

//Appointment letter
export const pleaseSelectVariable = "Please select appropriate variable.";
export const pleaseSelectPeoplesTeam =
  "Please select appropriate Signature authority.";
export const pleaseSelectProbationPeriod =
  "Please select appropriate probation period.";
export const pleaseSelectNoticePeriod =
  "Please select appropriate Notice period.";
export const pleaseEnterComment = "Please enter appropriate Comment.";

//Employee Profile
export const employeeInitiateDetailsSaved =
  "Employee Initiate details saved successfully.";
export const employeeRoleDetailsSaved =
  "Employee Role details saved successfully.";
export const employeeRnDDetailsSaved =
  "Employee L&D details saved successfully.";
export const employeeAssetsDetailsSaved =
  "Employee Assets details saved successfully.";

//Confirmation
export const pleaseEnterAppropriateComment =
  "Please enter appropriate comment.";
export const pleaseSelectAppropriateSingnatureAthority =
  "Please select appropriate signature authority.";

//Exit Clearance
export const pleaseSelectCondition =
  "Please select appropriate condition for all asset items.";

export const pleaseSelectBillDate = "Please select bill date.";
export const pleaseSelectBillAmount = "Please enter bill amount.";
export const pleaseUploadBillScreenshort =
  "Please upload screenshot of your bill, or scanned copy of your bill.";

export const documentIntemation = "Document intimation send successfully.";
// OKR Module
export const pleaseEnterObjective = "Objective section cannot be blank.";
export const pleaseEnterValidObjective = "Please enter valid Objective.";
export const pleaseEntertags =
  "Please provide Tags for future search of this objective.";
export const pleaseSelectFunction = "Please select Visibility.";
// export const pleaseSelectFunction = "Please select 'Visibility' from the drop-down options" nosonar
export const pleaseSelectLevel =
  "Choose Functions for visibility of this Objective.";
export const pleaseEnterKPIName = "Please enter Key Result.";
export const pleaseEnterValidKPIName = "Please enter valid Key Result.";
export const sumitForApprovalObjectiveMsg =
  "Are you sure you want to submit OKR for approval?";
export const sumitForApprovalObjectiveTitle = "Submit OKR";

export const notifyForEveryMeetingData = [
  { Id: 0, Name: "Select min", Value: 0 },
  { Id: 1, Name: "5 min", Value: 5 },
  { Id: 2, Name: "10 min", Value: 10 },
  { Id: 3, Name: "15 min", Value: 15 },
  { Id: 4, Name: "20 min", Value: 20 },
  { Id: 5, Name: "25 min", Value: 25 },
  { Id: 6, Name: "30 min", Value: 30 },
  { Id: 7, Name: "35 min", Value: 35 },
  { Id: 8, Name: "40 min", Value: 40 },
  { Id: 9, Name: "45 min", Value: 45 },
  { Id: 10, Name: "50 min", Value: 50 },
  { Id: 11, Name: "55 min", Value: 55 },
  { Id: 12, Name: "60 min", Value: 60 },
];
export const notifyEveryDayWeek = [
  { Id: 1, Name: "Day(s)", Value: "1" },
  // { Id: 2, Name: 'Week', Value: '2' },
];
export const defaultNotificationChannel = [
  { Id: 0, Name: "Select", Value: "0" },
  { Id: 4, Name: "System", Value: "4" },
];
export const notificationChannel = [
  { Id: 0, Name: "Select", Value: "0" },
  { Id: 4, Name: "System", Value: "4" },
  { Id: 2, Name: "Telegram", Value: "2" },
  { Id: 3, Name: "Both", Value: "3" },
];
export const defaultNotificationChannelForSchedule = [
  { Id: 0, Name: "Select", Value: "0" },
  { Id: 1, Name: "Email", Value: "1" },
];
export const notificationChannelForSchedule = [
  { Id: 0, Name: "Select", Value: "0" },
  { Id: 1, Name: "Email", Value: "1" },
  { Id: 2, Name: "Telegram", Value: "2" },
  { Id: 3, Name: "Both", Value: "3" },
];
export const weekDays = [
  { Id: 0, Name: "Day", Value: "0" },
  { Id: 1, Name: "Mon", Value: "1" },
  { Id: 2, Name: "Tue", Value: "2" },
  { Id: 3, Name: "Wed", Value: "3" },
  { Id: 4, Name: "Thu", Value: "4" },
  { Id: 5, Name: "Fri", Value: "5" },
];
export const daysNumber = [
  { Id: 1, Name: "1", Value: "1" },
  { Id: 2, Name: "2", Value: "2" },
  { Id: 3, Name: "3", Value: "3" },
  // { Id: 4, Name: '4', Value: '4' },
  // { Id: 5, Name: '5', Value: '5' },
];
export const clusterDayType = [
  { Id: 1, Name: "Fixed", Value: "1" },
  { Id: 0, Name: "Optional", Value: "0" },
];
export const countryList = [
  { Id: 768, Name: "India", Value: 768 },
  { Id: 648, Name: "Netherlands", Value: 648 },
  { Id: 650, Name: "United Kingdom", Value: 650 },
  { Id: 651, Name: "United States", Value: 651 },
  { Id: 718, Name: "Belgium", Value: 718 },
];

export const cityList = [
  { Id: 804, Name: "India", Value: 804 },
  { Id: 800, Name: "Netherlands", Value: 800 },
  { Id: 2, Name: "United Kingdom", Value: 2 },
  { Id: 801, Name: "United States", Value: 801 },
  { Id: 803, Name: "Belgium", Value: 803 },
];

export const backAndFutureDatedDays = [
  { Id: 0, Value: 0 },
  { Id: 1, Value: 1 },
  { Id: 2, Value: 2 },
  { Id: 3, Value: 3 },
  { Id: 4, Value: 4 },
  { Id: 5, Value: 5 },
  { Id: 6, Value: 6 },
  { Id: 7, Value: 7 },
  { Id: 8, Value: 8 },
  { Id: 9, Value: 9 },
  { Id: 10, Value: 10 },
];

export const dayBreakList = [
  // { Id: 1, Name: 'PointOne', Value: 0.1 },
  { Id: 1, Name: "QTR", Value: 0.25 },
  { Id: 2, Name: "Half", Value: 0.5 },
  { Id: 3, Name: "ThreeFourth", Value: 0.75 },
  { Id: 4, Name: "Full", Value: 1.0 },
];

export const companyList = [
  { Id: 1, Name: "Cheers Group" },
  { Id: 2, Name: "What Next" },
  { Id: 3, Name: "FutureBridge" },
  { Id: 4, Name: "Cognition" },
  { Id: 5, Name: "Support Function" },
  { Id: 6, Name: "Governance Functions" },
];

export const timeData = [
  { Id: 1, Name: "09:00", Value: "09:00" },
  { Id: 2, Name: "09:15", Value: "09:15" },
  { Id: 3, Name: "09:30", Value: "09:30" },
  { Id: 4, Name: "09:45", Value: "09:45" },
  { Id: 5, Name: "10:00", Value: "10:00" },
  { Id: 6, Name: "10:15", Value: "10:15" },
  { Id: 7, Name: "10:30", Value: "10:30" },
  { Id: 8, Name: "10:45", Value: "10:45" },
  { Id: 9, Name: "11:00", Value: "11:00" },
  { Id: 10, Name: "11:15", Value: "11:15" },
  { Id: 11, Name: "11:30", Value: "11:30" },
  { Id: 12, Name: "11:45", Value: "11:45" },
  { Id: 13, Name: "12:00", Value: "12:00" },
  { Id: 14, Name: "12:15", Value: "12:15" },
  { Id: 15, Name: "12:30", Value: "12:30" },
  { Id: 16, Name: "12:45", Value: "12:45" },
  { Id: 17, Name: "13:00", Value: "13:00" },
  { Id: 18, Name: "13:15", Value: "13:15" },
  { Id: 19, Name: "13:30", Value: "13:30" },
  { Id: 20, Name: "13:45", Value: "13:45" },
  { Id: 22, Name: "14:00", Value: "14:00" },
  { Id: 23, Name: "14:15", Value: "14:15" },
  { Id: 24, Name: "14:30", Value: "14:30" },
  { Id: 25, Name: "14:45", Value: "14:45" },
  { Id: 26, Name: "15:00", Value: "15:00" },
  { Id: 27, Name: "15:15", Value: "15:15" },
  { Id: 28, Name: "15:30", Value: "15:30" },
  { Id: 29, Name: "15:45", Value: "15:45" },
  { Id: 30, Name: "16:00", Value: "16:00" },
  { Id: 31, Name: "16:15", Value: "16:15" },
  { Id: 32, Name: "16:30", Value: "16:30" },
  { Id: 33, Name: "16:45", Value: "16:45" },
  { Id: 34, Name: "17:00", Value: "17:00" },
  { Id: 35, Name: "17:15", Value: "17:15" },
  { Id: 36, Name: "17:30", Value: "17:30" },
  { Id: 37, Name: "17:45", Value: "17:45" },
  { Id: 38, Name: "18:00", Value: "18:00" },
  { Id: 39, Name: "18:15", Value: "18:15" },
  { Id: 40, Name: "18:30", Value: "18:30" },
  { Id: 41, Name: "18:45", Value: "18:45" },
  { Id: 42, Name: "19:00", Value: "19:00" },
  { Id: 43, Name: "19:15", Value: "19:15" },
  { Id: 44, Name: "19:30", Value: "19:30" },
  { Id: 45, Name: "19:45", Value: "19:45" },
  { Id: 46, Name: "20:00", Value: "20:00" },
  { Id: 47, Name: "20:15", Value: "20:15" },
  { Id: 48, Name: "20:30", Value: "20:30" },
  { Id: 49, Name: "20:45", Value: "20:45" },
  { Id: 50, Name: "21:00", Value: "21:00" },
  { Id: 51, Name: "21:15", Value: "21:15" },
  { Id: 52, Name: "21:30", Value: "21:30" },
  { Id: 53, Name: "21:45", Value: "21:45" },
  { Id: 54, Name: "22:00", Value: "22:00" },
  { Id: 55, Name: "22:15", Value: "22:15" },
  { Id: 56, Name: "22:30", Value: "22:30" },
  { Id: 57, Name: "22:45", Value: "22:45" },
  { Id: 58, Name: "23:00", Value: "23:00" },
  { Id: 59, Name: "23:15", Value: "23:15" },
  { Id: 60, Name: "23:30", Value: "23:30" },
  { Id: 61, Name: "23:45", Value: "23:45" },
  { Id: 62, Name: "00:00", Value: "00:00" },
  { Id: 63, Name: "00:15", Value: "00:15" },
  { Id: 64, Name: "00:30", Value: "00:30" },
  { Id: 65, Name: "00:45", Value: "00:45" },
  { Id: 66, Name: "01:00", Value: "01:00" },
  { Id: 67, Name: "01:15", Value: "01:15" },
  { Id: 68, Name: "01:30", Value: "01:30" },
  { Id: 69, Name: "01:45", Value: "01:45" },
  { Id: 70, Name: "02:00", Value: "02:00" },
  { Id: 71, Name: "02:15", Value: "02:15" },
  { Id: 72, Name: "02:30", Value: "02:30" },
  { Id: 73, Name: "02:45", Value: "02:45" },
  { Id: 74, Name: "03:00", Value: "03:00" },
  { Id: 75, Name: "03:15", Value: "03:15" },
  { Id: 76, Name: "03:30", Value: "03:30" },
  { Id: 77, Name: "03:45", Value: "03:45" },
  { Id: 78, Name: "04:00", Value: "04:00" },
  { Id: 79, Name: "04:15", Value: "04:15" },
  { Id: 80, Name: "04:30", Value: "04:30" },
  { Id: 81, Name: "04:45", Value: "04:45" },
  { Id: 82, Name: "05:00", Value: "05:00" },
  { Id: 83, Name: "05:15", Value: "05:15" },
  { Id: 84, Name: "05:30", Value: "05:30" },
  { Id: 85, Name: "05:45", Value: "05:45" },
  { Id: 86, Name: "06:00", Value: "06:00" },
  { Id: 87, Name: "06:15", Value: "06:15" },
  { Id: 88, Name: "06:30", Value: "06:30" },
  { Id: 89, Name: "06:45", Value: "06:45" },
  { Id: 90, Name: "07:00", Value: "07:00" },
  { Id: 91, Name: "07:15", Value: "07:15" },
  { Id: 92, Name: "07:30", Value: "07:30" },
  { Id: 93, Name: "07:45", Value: "07:45" },
  { Id: 94, Name: "08:00", Value: "08:00" },
  { Id: 95, Name: "08:15", Value: "08:15" },
  { Id: 96, Name: "08:30", Value: "08:30" },
  { Id: 97, Name: "08:45", Value: "08:45" },
];
export const allTimeInterval = [
  { Id: 1, Name: "00:00", Value: "00:00" },
  { Id: 2, Name: "00:15", Value: "00:15" },
  { Id: 3, Name: "00:30", Value: "00:30" },
  { Id: 4, Name: "00:45", Value: "00:45" },
  { Id: 5, Name: "01:00", Value: "01:00" },
  { Id: 6, Name: "01:15", Value: "01:15" },
  { Id: 7, Name: "01:30", Value: "01:30" },
  { Id: 8, Name: "01:45", Value: "01:45" },
  { Id: 9, Name: "02:00", Value: "02:00" },
  { Id: 10, Name: "02:15", Value: "02:15" },
  { Id: 11, Name: "02:30", Value: "02:30" },
  { Id: 12, Name: "02:45", Value: "02:45" },
  { Id: 13, Name: "03:00", Value: "03:00" },
  { Id: 14, Name: "03:15", Value: "03:15" },
  { Id: 15, Name: "03:30", Value: "03:30" },
  { Id: 16, Name: "03:45", Value: "03:45" },
  { Id: 17, Name: "04:00", Value: "04:00" },
  { Id: 18, Name: "04:15", Value: "04:15" },
  { Id: 19, Name: "04:30", Value: "04:30" },
  { Id: 20, Name: "04:45", Value: "04:45" },
  { Id: 21, Name: "05:00", Value: "05:00" },
  { Id: 22, Name: "05:15", Value: "05:15" },
  { Id: 23, Name: "05:30", Value: "05:30" },
  { Id: 24, Name: "05:45", Value: "05:45" },
  { Id: 25, Name: "06:00", Value: "06:00" },
  { Id: 26, Name: "06:15", Value: "06:15" },
  { Id: 27, Name: "06:30", Value: "06:30" },
  { Id: 28, Name: "06:45", Value: "06:45" },
  { Id: 29, Name: "07:00", Value: "07:00" },
  { Id: 30, Name: "07:15", Value: "07:15" },
  { Id: 31, Name: "07:30", Value: "07:30" },
  { Id: 32, Name: "07:45", Value: "07:45" },
  { Id: 33, Name: "08:00", Value: "08:00" },
  { Id: 34, Name: "08:15", Value: "08:15" },
  { Id: 35, Name: "08:30", Value: "08:30" },
  { Id: 36, Name: "08:45", Value: "08:45" },
  { Id: 37, Name: "09:00", Value: "09:00" },
  { Id: 38, Name: "09:15", Value: "09:15" },
  { Id: 39, Name: "09:30", Value: "09:30" },
  { Id: 40, Name: "09:45", Value: "09:45" },
  { Id: 41, Name: "10:00", Value: "10:00" },
  { Id: 42, Name: "10:15", Value: "10:15" },
  { Id: 43, Name: "10:30", Value: "10:30" },
  { Id: 44, Name: "10:45", Value: "10:45" },
  { Id: 45, Name: "11:00", Value: "11:00" },
  { Id: 46, Name: "11:15", Value: "11:15" },
  { Id: 47, Name: "11:30", Value: "11:30" },
  { Id: 48, Name: "11:45", Value: "11:45" },
  { Id: 49, Name: "12:00", Value: "12:00" },
  { Id: 50, Name: "12:15", Value: "12:15" },
  { Id: 51, Name: "12:30", Value: "12:30" },
  { Id: 52, Name: "12:45", Value: "12:45" },
  { Id: 53, Name: "13:00", Value: "13:00" },
  { Id: 54, Name: "13:15", Value: "13:15" },
  { Id: 55, Name: "13:30", Value: "13:30" },
  { Id: 56, Name: "13:45", Value: "13:45" },
  { Id: 57, Name: "14:00", Value: "14:00" },
  { Id: 58, Name: "14:15", Value: "14:15" },
  { Id: 59, Name: "14:30", Value: "14:30" },
  { Id: 60, Name: "14:45", Value: "14:45" },
  { Id: 61, Name: "15:00", Value: "15:00" },
  { Id: 62, Name: "15:15", Value: "15:15" },
  { Id: 63, Name: "15:30", Value: "15:30" },
  { Id: 64, Name: "15:45", Value: "15:45" },
  { Id: 65, Name: "16:00", Value: "16:00" },
  { Id: 66, Name: "16:15", Value: "16:15" },
  { Id: 67, Name: "16:30", Value: "16:30" },
  { Id: 68, Name: "16:45", Value: "16:45" },
  { Id: 69, Name: "17:00", Value: "17:00" },
  { Id: 70, Name: "17:15", Value: "17:15" },
  { Id: 71, Name: "17:30", Value: "17:30" },
  { Id: 72, Name: "17:45", Value: "17:45" },
  { Id: 73, Name: "18:00", Value: "18:00" },
  { Id: 74, Name: "18:15", Value: "18:15" },
  { Id: 75, Name: "18:30", Value: "18:30" },
  { Id: 76, Name: "18:45", Value: "18:45" },
  { Id: 77, Name: "19:00", Value: "19:00" },
  { Id: 78, Name: "19:15", Value: "19:15" },
  { Id: 79, Name: "19:30", Value: "19:30" },
  { Id: 80, Name: "19:45", Value: "19:45" },
  { Id: 81, Name: "20:00", Value: "20:00" },
  { Id: 82, Name: "20:15", Value: "20:15" },
  { Id: 83, Name: "20:30", Value: "20:30" },
  { Id: 84, Name: "20:45", Value: "20:45" },
  { Id: 85, Name: "21:00", Value: "21:00" },
  { Id: 86, Name: "21:15", Value: "21:15" },
  { Id: 87, Name: "21:30", Value: "21:30" },
  { Id: 88, Name: "21:45", Value: "21:45" },
  { Id: 89, Name: "22:00", Value: "22:00" },
  { Id: 90, Name: "22:15", Value: "22:15" },
  { Id: 91, Name: "22:30", Value: "22:30" },
  { Id: 92, Name: "22:45", Value: "22:45" },
  { Id: 93, Name: "23:00", Value: "23:00" },
  { Id: 94, Name: "23:15", Value: "23:15" },
  { Id: 95, Name: "23:30", Value: "23:30" },
  { Id: 96, Name: "23:45", Value: "23:45" },
];
export const endTimeandNextDay = [
  { Id: 1, Name: "00:00", Value: "00:00" },
  { Id: 2, Name: "00:15", Value: "00:15" },
  { Id: 3, Name: "00:30", Value: "00:30" },
  { Id: 4, Name: "00:45", Value: "00:45" },
  { Id: 5, Name: "01:00", Value: "01:00" },
  { Id: 6, Name: "01:15", Value: "01:15" },
  { Id: 7, Name: "01:30", Value: "01:30" },
  { Id: 8, Name: "01:45", Value: "01:45" },
  { Id: 9, Name: "02:00", Value: "02:00" },
  { Id: 10, Name: "02:15", Value: "02:15" },
  { Id: 11, Name: "02:30", Value: "02:30" },
  { Id: 12, Name: "02:45", Value: "02:45" },
  { Id: 13, Name: "03:00", Value: "03:00" },
  { Id: 14, Name: "03:15", Value: "03:15" },
  { Id: 15, Name: "03:30", Value: "03:30" },
  { Id: 16, Name: "03:45", Value: "03:45" },
  { Id: 17, Name: "04:00", Value: "04:00" },
  { Id: 18, Name: "04:15", Value: "04:15" },
  { Id: 19, Name: "04:30", Value: "04:30" },
  { Id: 20, Name: "04:45", Value: "04:45" },
  { Id: 21, Name: "05:00", Value: "05:00" },
  { Id: 22, Name: "05:15", Value: "05:15" },
  { Id: 23, Name: "05:30", Value: "05:30" },
  { Id: 24, Name: "05:45", Value: "05:45" },
  { Id: 25, Name: "06:00", Value: "06:00" },
  { Id: 26, Name: "06:15", Value: "06:15" },
  { Id: 27, Name: "06:30", Value: "06:30" },
  { Id: 28, Name: "06:45", Value: "06:45" },
  { Id: 29, Name: "07:00", Value: "07:00" },
  { Id: 30, Name: "07:15", Value: "07:15" },
  { Id: 31, Name: "07:30", Value: "07:30" },
  { Id: 32, Name: "07:45", Value: "07:45" },
  { Id: 33, Name: "08:00", Value: "08:00" },
  { Id: 34, Name: "08:15", Value: "08:15" },
  { Id: 35, Name: "08:30", Value: "08:30" },
  { Id: 36, Name: "08:45", Value: "08:45" },
  { Id: 37, Name: "09:00", Value: "09:00" },
  { Id: 38, Name: "09:15", Value: "09:15" },
  { Id: 39, Name: "09:30", Value: "09:30" },
  { Id: 40, Name: "09:45", Value: "09:45" },
  { Id: 41, Name: "10:00", Value: "10:00" },
  { Id: 42, Name: "10:15", Value: "10:15" },
  { Id: 43, Name: "10:30", Value: "10:30" },
  { Id: 44, Name: "10:45", Value: "10:45" },
  { Id: 45, Name: "11:00", Value: "11:00" },
  { Id: 46, Name: "11:15", Value: "11:15" },
  { Id: 47, Name: "11:30", Value: "11:30" },
  { Id: 48, Name: "11:45", Value: "11:45" },
  { Id: 49, Name: "12:00", Value: "12:00" },
  { Id: 50, Name: "12:15", Value: "12:15" },
  { Id: 51, Name: "12:30", Value: "12:30" },
  { Id: 52, Name: "12:45", Value: "12:45" },
  { Id: 53, Name: "13:00", Value: "13:00" },
  { Id: 54, Name: "13:15", Value: "13:15" },
  { Id: 55, Name: "13:30", Value: "13:30" },
  { Id: 56, Name: "13:45", Value: "13:45" },
  { Id: 57, Name: "14:00", Value: "14:00" },
  { Id: 58, Name: "14:15", Value: "14:15" },
  { Id: 59, Name: "14:30", Value: "14:30" },
  { Id: 60, Name: "14:45", Value: "14:45" },
  { Id: 61, Name: "15:00", Value: "15:00" },
  { Id: 62, Name: "15:15", Value: "15:15" },
  { Id: 63, Name: "15:30", Value: "15:30" },
  { Id: 64, Name: "15:45", Value: "15:45" },
  { Id: 65, Name: "16:00", Value: "16:00" },
  { Id: 66, Name: "16:15", Value: "16:15" },
  { Id: 67, Name: "16:30", Value: "16:30" },
  { Id: 68, Name: "16:45", Value: "16:45" },
  { Id: 69, Name: "17:00", Value: "17:00" },
  { Id: 70, Name: "17:15", Value: "17:15" },
  { Id: 71, Name: "17:30", Value: "17:30" },
  { Id: 72, Name: "17:45", Value: "17:45" },
  { Id: 73, Name: "18:00", Value: "18:00" },
  { Id: 74, Name: "18:15", Value: "18:15" },
  { Id: 75, Name: "18:30", Value: "18:30" },
  { Id: 76, Name: "18:45", Value: "18:45" },
  { Id: 77, Name: "19:00", Value: "19:00" },
  { Id: 78, Name: "19:15", Value: "19:15" },
  { Id: 79, Name: "19:30", Value: "19:30" },
  { Id: 80, Name: "19:45", Value: "19:45" },
  { Id: 81, Name: "20:00", Value: "20:00" },
  { Id: 82, Name: "20:15", Value: "20:15" },
  { Id: 83, Name: "20:30", Value: "20:30" },
  { Id: 84, Name: "20:45", Value: "20:45" },
  { Id: 85, Name: "21:00", Value: "21:00" },
  { Id: 86, Name: "21:15", Value: "21:15" },
  { Id: 87, Name: "21:30", Value: "21:30" },
  { Id: 88, Name: "21:45", Value: "21:45" },
  { Id: 89, Name: "22:00", Value: "22:00" },
  { Id: 90, Name: "22:15", Value: "22:15" },
  { Id: 91, Name: "22:30", Value: "22:30" },
  { Id: 92, Name: "22:45", Value: "22:45" },
  { Id: 93, Name: "23:00", Value: "23:00" },
  { Id: 94, Name: "23:15", Value: "23:15" },
  { Id: 95, Name: "23:30", Value: "23:30" },
  { Id: 96, Name: "23:45", Value: "23:45" },
  { Id: 1, Name: "00:00 +", Value: "00:00 +" },
  { Id: 2, Name: "00:15 +", Value: "00:15 +" },
  { Id: 3, Name: "00:30 +", Value: "00:30 +" },
  { Id: 4, Name: "00:45 +", Value: "00:45 +" },
  { Id: 5, Name: "01:00 +", Value: "01:00 +" },
  { Id: 6, Name: "01:15 +", Value: "01:15 +" },
  { Id: 7, Name: "01:30 +", Value: "01:30 +" },
  { Id: 8, Name: "01:45 +", Value: "01:45 +" },
  { Id: 9, Name: "02:00 +", Value: "02:00 +" },
  { Id: 10, Name: "02:15 +", Value: "02:15 +" },
  { Id: 11, Name: "02:30 +", Value: "02:30 +" },
  { Id: 12, Name: "02:45 +", Value: "02:45 +" },
  { Id: 13, Name: "03:00 +", Value: "03:00 +" },
  { Id: 14, Name: "03:15 +", Value: "03:15 +" },
  { Id: 15, Name: "03:30 +", Value: "03:30 +" },
  { Id: 16, Name: "03:45 +", Value: "03:45 +" },
  { Id: 17, Name: "04:00 +", Value: "04:00 +" },
  { Id: 18, Name: "04:15 +", Value: "04:15 +" },
  { Id: 19, Name: "04:30 +", Value: "04:30 +" },
  { Id: 20, Name: "04:45 +", Value: "04:45 +" },
  { Id: 21, Name: "05:00 +", Value: "05:00 +" },
  { Id: 22, Name: "05:15 +", Value: "05:15 +" },
  { Id: 23, Name: "05:30 +", Value: "05:30 +" },
  { Id: 24, Name: "05:45 +", Value: "05:45 +" },
  { Id: 25, Name: "06:00 +", Value: "06:00 +" },
  { Id: 26, Name: "06:15 +", Value: "06:15 +" },
  { Id: 27, Name: "06:30 +", Value: "06:30 +" },
  { Id: 28, Name: "06:45 +", Value: "06:45 +" },
  { Id: 29, Name: "07:00 +", Value: "07:00 +" },
  { Id: 30, Name: "07:15 +", Value: "07:15 +" },
  { Id: 31, Name: "07:30 +", Value: "07:30 +" },
  { Id: 32, Name: "07:45 +", Value: "07:45 +" },
  { Id: 33, Name: "08:00 +", Value: "08:00 +" },
  { Id: 34, Name: "08:15 +", Value: "08:15 +" },
  { Id: 35, Name: "08:30 +", Value: "08:30 +" },
  { Id: 36, Name: "08:45 +", Value: "08:45 +" },
  { Id: 37, Name: "09:00 +", Value: "09:00 +" },
  { Id: 38, Name: "09:15 +", Value: "09:15 +" },
  { Id: 39, Name: "09:30 +", Value: "09:30 +" },
  { Id: 40, Name: "09:45 +", Value: "09:45 +" },
  { Id: 41, Name: "10:00 +", Value: "10:00 +" },
  { Id: 42, Name: "10:15 +", Value: "10:15 +" },
  { Id: 43, Name: "10:30 +", Value: "10:30 +" },
  { Id: 44, Name: "10:45 +", Value: "10:45 +" },
  { Id: 45, Name: "11:00 +", Value: "11:00 +" },
  { Id: 46, Name: "11:15 +", Value: "11:15 +" },
  { Id: 47, Name: "11:30 +", Value: "11:30 +" },
  { Id: 48, Name: "11:45 +", Value: "11:45 +" },
  { Id: 49, Name: "12:00 +", Value: "12:00 +" },
  { Id: 50, Name: "12:15 +", Value: "12:15 +" },
  { Id: 51, Name: "12:30 +", Value: "12:30 +" },
  { Id: 52, Name: "12:45 +", Value: "12:45 +" },
  { Id: 53, Name: "13:00 +", Value: "13:00 +" },
  { Id: 54, Name: "13:15 +", Value: "13:15 +" },
  { Id: 55, Name: "13:30 +", Value: "13:30 +" },
  { Id: 56, Name: "13:45 +", Value: "13:45 +" },
  { Id: 57, Name: "14:00 +", Value: "14:00 +" },
  { Id: 58, Name: "14:15 +", Value: "14:15 +" },
  { Id: 59, Name: "14:30 +", Value: "14:30 +" },
  { Id: 60, Name: "14:45 +", Value: "14:45 +" },
  { Id: 61, Name: "15:00 +", Value: "15:00 +" },
  { Id: 62, Name: "15:15 +", Value: "15:15 +" },
  { Id: 63, Name: "15:30 +", Value: "15:30 +" },
  { Id: 64, Name: "15:45 +", Value: "15:45 +" },
  { Id: 65, Name: "16:00 +", Value: "16:00 +" },
  { Id: 66, Name: "16:15 +", Value: "16:15 +" },
  { Id: 67, Name: "16:30 +", Value: "16:30 +" },
  { Id: 68, Name: "16:45 +", Value: "16:45 +" },
  { Id: 69, Name: "17:00 +", Value: "17:00 +" },
  { Id: 70, Name: "17:15 +", Value: "17:15 +" },
  { Id: 71, Name: "17:30 +", Value: "17:30 +" },
  { Id: 72, Name: "17:45 +", Value: "17:45 +" },
  { Id: 73, Name: "18:00 +", Value: "18:00 +" },
  { Id: 74, Name: "18:15 +", Value: "18:15 +" },
  { Id: 75, Name: "18:30 +", Value: "18:30 +" },
  { Id: 76, Name: "18:45 +", Value: "18:45 +" },
  { Id: 77, Name: "19:00 +", Value: "19:00 +" },
  { Id: 78, Name: "19:15 +", Value: "19:15 +" },
  { Id: 79, Name: "19:30 +", Value: "19:30 +" },
  { Id: 80, Name: "19:45 +", Value: "19:45 +" },
  { Id: 81, Name: "20:00 +", Value: "20:00 +" },
  { Id: 82, Name: "20:15 +", Value: "20:15 +" },
  { Id: 83, Name: "20:30 +", Value: "20:30 +" },
  { Id: 84, Name: "20:45 +", Value: "20:45 +" },
  { Id: 85, Name: "21:00 +", Value: "21:00 +" },
  { Id: 86, Name: "21:15 +", Value: "21:15 +" },
  { Id: 87, Name: "21:30 +", Value: "21:30 +" },
  { Id: 88, Name: "21:45 +", Value: "21:45 +" },
  { Id: 89, Name: "22:00 +", Value: "22:00 +" },
  { Id: 90, Name: "22:15 +", Value: "22:15 +" },
  { Id: 91, Name: "22:30 +", Value: "22:30 +" },
  { Id: 92, Name: "22:45 +", Value: "22:45 +" },
  { Id: 93, Name: "23:00 +", Value: "23:00 +" },
  { Id: 94, Name: "23:15 +", Value: "23:15 +" },
  { Id: 95, Name: "23:30 +", Value: "23:30 +" },
  { Id: 96, Name: "23:45 +", Value: "23:45 +" },
];
export const day = [
  { Id: 0, Name: "Select", Value: "Today/Next" },
  { Id: 1, Name: "Today's", Value: "today" },
  { Id: 2, Name: "Next", Value: "next" },
];

export const timeZoneData = [
  {
    Id: 55,
    Value: 55,
    Name: "IST",
    Optional: "Asia/Kolkata",
    Ord: "1",
    Type: "Time Zone",
  },
  {
    Id: 57,
    Value: 57,
    Name: "CET",
    Optional: "Europe/Paris",
    Ord: "2",
    Type: "Time Zone",
  },
  {
    Id: 56,
    Value: 56,
    Name: "UK",
    Optional: "Europe/London",
    Ord: "3",
    Type: "Time Zone",
  },
  {
    Id: 62,
    Value: 62,
    Name: "EST",
    Optional: "America/New_York",
    Ord: "4",
    Type: "Time Zone",
  },
  {
    Id: 63,
    Value: 63,
    Name: "CST",
    Optional: "America/Costa_Rica",
    Ord: "5",
    Type: "Time Zone",
  },
  {
    Id: 64,
    Value: 64,
    Name: "MST",
    Optional: "America/Denver",
    Ord: "6",
    Type: "Time Zone",
  },
  {
    Id: 65,
    Value: 65,
    Name: "PST",
    Optional: "America/Los_Angeles",
    Ord: "7",
    Type: "Time Zone",
  },
  {
    Id: 67,
    Value: 67,
    Name: "UAE",
    Optional: "Asia/Dubai",
    Ord: "8",
    Type: "Time Zone",
  },
  {
    Id: 58,
    Value: 58,
    Name: "SGT",
    Optional: "Singapore",
    Ord: "9",
    Type: "Time Zone",
  },
  {
    Id: 60,
    Value: 60,
    Name: "AUS",
    Optional: "Australia/Sydney",
    Ord: "10",
    Type: "Time Zone",
  },
];

//nosonar export const clientMeetingType = 'BTB / Ops / Client';
export const clientMeetingType = "Billable (BTB)";
export const CheersInteractive = 1;
export const WhatNext = 2;
export const FutureBridge = 3;
export const Cognition = 4;
export const OPSBTBDropdown = [1007, 1008, 926, 930, 1003, 1004, 1005, 1006];
export const OPSBTBClientMeet = [925];
export const SalesClientMeet = [927];
export const ClientMeetingsId = [1001, 1002];
export const MarkrtingMeetingIds = [27, 28, 33, 34, 897, 1026, 1035, 1072];
export const SelfBreakLeave = [8, 885, 998, 999, 1000, 1018, 945];
export const Holidays = [1066, 1067, 1068, 1069, 1070, 1071];
/**nosonar**/ // export const ExcluedPitchRecurrenceIds = [927, 1007, 1008];
export const ExcluedPitchRecurrenceIds = [927, 925];

// OrgchartLevelData
export const orgChartLevelData = [
  { Id: 1, Name: "Level 1", value: "level1" },
  { Id: 2, Name: "Level 2", value: "level2" },
  { Id: 3, Name: "Level 3", value: "level3" },
  { Id: 4, Name: "Level 4", value: "level4" },
  { Id: 5, Name: "Level 5", value: "level5" },
  { Id: 6, Name: "Others", value: "masterothers" },
];
export const roleStatusData = [
  { Id: 0, Name: "Acting", value: "0" },
  { Id: 1, Name: "Active", value: "1" },
];
export const alfabatesArray = [
  "A",
  "B",
  "C",
  "D",
  "E",
  "F",
  "G",
  "H",
  "I",
  "J",
  "K",
  "L",
  "M",
  "N",
  "O",
  "P",
  "Q",
  "R",
  "S",
  "T",
  "U",
  "V",
  "W",
  "X",
  "Y",
  "Z",
];
export const orgchartViews = [
  { Id: "0", Name: "Minimum View" },
  { Id: "1", Name: "Maximum View" },
];
export const orgChartOrientation = [
  { Id: "TopToBottom", Name: "Top-Bottom", Class: "fa-arrow-down" },
  { Id: "LeftToRight", Name: "Left-Right", Class: "fa-arrow-right" },
  // { "Id": "RightToLeft", "Name": "Right-Left", "Class": "fa-arrow-left" },
  // { "Id": "BottomToTop", "Name": "Bottom-Top", "Class": "fa-arrow-up" }
];
export const orgChartSubTreeAlignment = [
  { Id: "pattern1", Name: "Vertical-Alternate", Class: "fa-facebook-f" },
  { Id: "pattern2", Name: "Vertical-Left", Class: "fa-facebook-f" },
  { Id: "pattern5", Name: "Vertical-Right", Class: "fa-facebook-f" },
  { Id: "pattern6", Name: "Horizontal-Balanced", Class: "fa-facebook-f" },
  { Id: "pattern7", Name: "Horizontal-Center", Class: "fa-facebook-f" },
  { Id: "pattern8", Name: "Horizontal-Left", Class: "fa-facebook-f" },
  { Id: "pattern9", Name: "Horizontal-Right", Class: "fa-facebook-f" },
];
export const orgChartSubTreeAlignmentLimited = [
  { Id: "pattern1", Name: "Vertical-Alternate", Class: "fa-facebook-f" },
  { Id: "pattern6", Name: "Horizontal-Balanced", Class: "fa-facebook-f" },
  { Id: "pattern7", Name: "Horizontal-Center", Class: "fa-facebook-f" },
  { Id: "pattern8", Name: "Horizontal-Left", Class: "fa-facebook-f" },
  { Id: "pattern9", Name: "Horizontal-Right", Class: "fa-facebook-f" },
];
// OKR - Messages

export const PleaseSelectObjectiveTxt = "Please Enter Objective";
export const PleaseSelectObjectiveVisibility = "Please Select Visibility";
export const PleaseSelectObjectiveLevel = "Please Select Level";
export const benchmarkList = [
  { Id: 1, Name: "1", value: "1" },
  { Id: 2, Name: "2", value: "2" },
  { Id: 3, Name: "3", value: "3" },
  { Id: 4, Name: "4", value: "4" },
  { Id: 5, Name: "5", value: "5" },
  { Id: 6, Name: "6", value: "6" },
  { Id: 7, Name: "7", value: "7" },
  { Id: 8, Name: "8", value: "8" },
  { Id: 9, Name: "9", value: "9" },
  { Id: 10, Name: "10", value: "10" },
];
export const totalRequiredKpiWeightage = 100;
// OKR - Erro Messages
export const importAtleastOneKPI = "Please import at least one Key Result";
export const selectAtleastOneKPI = "Please select at least one Key Result";
export const fillMandatoryFields = "Please fill all mandatory fields.";
export const weightageMustBeGreaterThanZero =
  "Weightage must be greater that 0";
export const totalKPIWeightage = "Total weightage must be 100 %";
export const unknownError = "Facing some unknown error";

// Onboarding middlewarw
export const employeeSalaryDetailsSaved = "Employee Salary Details Saved.";
export const employeeAppointmentLetterGenerated =
  "Employee Appointment Letter Generated.";
export const assetListUpdated = "Asset List updated.";
export const allAssetAccessHasBeenRemoved =
  "All asset access has been removed.";
export const initiateCandidateInConfirmation =
  "Initiate candidate in confirmation.";
export const revokeCandidateSuccessfully = "Revoke candidate successfully.";
export const initiateCandidateInOffered = "Initiate candidate in Offered.";
export const employeeCodeGeneratedSuccessfully =
  "Employee code generated successfully.";
export const initiateCandidateInReadyToJoin =
  "Initiate candidate in ready to join.";
export const documentCommentsAdded = "Document comments added.";
export const documentSubmittedSuccessfully = "Document submitted successfully.";
export const documentRejectedSuccessfully = "Document rejected successfully.";
export const basicInformationUpdateSuccessfully =
  "Basic information's update successfully.";
export const basicInformationSavedSuccessfully =
  "Basic information's saved successfully.";
export const documentSavedAsDraftSuccessfully =
  "Documents saved as draft successfully.";

// OKR -
export const approvalPopupHeaderMessage = "Approval Status";
export const rjectPopupHeaderMessage = "Reject Status";
export const rejectPopupSubMessage =
  "Are you sure, you want to reject this OKR?";
export const approvalPopupSubMessage =
  "Are you sure, you want to approve this OKR?";
export const objectiveApproved = "OKR approved successfully.";
export const objectiveRejected = "OKR rejected successfully.";

export const impactRangeInfo =
  "OKR Add / View / Edit - Please enter weightage that each OKR category will have on team members overall performance from the pre-defined ranges.";
export const impactPercentageInfo =
  "Defined weightage percentage range for respective OKR category. ";
export const FrequencyInfo =
  "Frequency at which the OKR would be rated in a Performance year.";
export const variableImpactpercentageInfo =
  "If variable pay is applicable please set percentage impact for respective OKR category. ";
export const ratingMechanismInfo =
  "You may choose the most appropriate rating scale to apply to respective OKR categories for rating team member against the objectives.";
export const userObjectiveFillSavesSuccessfully =
  "OKR details saved successfully. ";
export const userObjectiveFillClosedSuccessfully =
  "OKR details closed successfully. ";
export const userObjectiveFillDeactivedSuccessfully =
  "The OKR has been Deactivated.";
export const userKpiFillSavesSuccessfully =
  "Key Result details saved successfully.";
export const userOkrSavedDraftSuccessfully =
  "Team member's OKR saved as draft successfully.";
export const userOkrApprovedSuccessfully =
  "Team member's OKR approved successfully.";
export const userOkrRejectedSuccessfully =
  "Team member's OKR rejected successfully.";
export const userOkrSavedSuccessfully = "Team member's OKR saved successfully.";
export const OkrApprovedSuccessfully = "OKR approved successfully.";
export const OkrRejectedSuccessfully = "OKR rejected successfully.";
export const OkrRemovedSuccessfully = "OKR removed successfully.";
export const OkrAssignedSuccessfully = "OKR assigned successfully.";
export const OkrAllocatedToUserSuccessfully =
  "OKR allocated to user successfully.";
export const OkrSavedAsDraftSuccessfully = "OKR saved as a draft successfully.";
export const OkrImportedSuccessfully = "OKR(s) imported successfully.";
export const OkrNewRequestAddedSuccessfully = "New request added successfully.";
export const requestAcceptedSuccessfully = "Request accepted successfully.";
export const requestRevokedSuccessfully = "Request revoked successfully.";
export const requestDeniedSuccessfully = "Request denied successfully.";

export const variableLink = [
  { Id: 1, Name: "Yes", Value: "1" },
  { Id: 0, Name: "No", Value: "0" },
];
export const pleaseSelectMonthOfReview = "Please select the month";
export const monthOfReviewData = [
  { Id: 0, Name: "All", Value: "0" },
  { Id: 4, Name: "April", Value: "4" },
  { Id: 5, Name: "May", Value: "5" },
  { Id: 6, Name: "June", Value: "6" },
  { Id: 7, Name: "July", Value: "7" },
  { Id: 8, Name: "August", Value: "8" },
  { Id: 9, Name: "September", Value: "9" },
  { Id: 10, Name: "October", Value: "10" },
  { Id: 11, Name: "November", Value: "11" },
  { Id: 12, Name: "December", Value: "12" },
  { Id: 1, Name: "January", Value: "1" },
  { Id: 2, Name: "February", Value: "2" },
  { Id: 3, Name: "March", Value: "3" },
];

export const monthOfRatingData = [
  { Id: 4, Name: "April", Value: "4" },
  { Id: 5, Name: "May", Value: "5" },
  { Id: 6, Name: "June", Value: "6" },
  { Id: 7, Name: "July", Value: "7" },
  { Id: 8, Name: "August", Value: "8" },
  { Id: 9, Name: "September", Value: "9" },
  { Id: 10, Name: "October", Value: "10" },
  { Id: 11, Name: "November", Value: "11" },
  { Id: 12, Name: "December", Value: "12" },
  { Id: 1, Name: "January", Value: "1" },
  { Id: 2, Name: "February", Value: "2" },
  { Id: 3, Name: "March", Value: "3" },
];

export const MeasuremenrUnit = [
  { Id: 1, Name: "Date", Value: "1" },
  { Id: 2, Name: "Percentage", Value: "2" },
  { Id: 3, Name: "Currency", Value: "3" },
  { Id: 4, Name: "Number", Value: "4" },
];
export const MeasuremenrSortBy = [
  { Id: 1, Name: "Low To High", Value: "1" },
  { Id: 2, Name: "High To Low", Value: "2" },
];

export const TotalBusinessUserTotalariableImpact = 100;
export const TotalCentralUserVariableImpact = 100;
// / not exported error msgs - Yogita

export const KRBenchmark = "Please fill the appropriate benchmark data.";
export const LeftEmpMessage = "The employee left";
export const NoLayerAdded = "No Layer Added";
export const NoSubLayerAdded = "No Sub Layer Added";

export const OKRRatingSelfRecomnd = "Self Rating saved successfully";
export const OKRRatingSupRecomnd = "Supervisor Rating saved successfully";
export const OKRRatingFinalRecomnd = "Third party Rating saved successfully";
export const OKRRatingFinalRating = "Final Rating saved successfully";

export const InterimFeedBackSelf = "Self Interim Feedback  saved successfully";
export const InterimFeedBackSupervisor =
  "Supervisor Interim Feedback  saved successfully";
export const InterimFeedBackThirdParty =
  "Third Party Interim Feedback  saved successfully";

export const OKRRatingSubmitWarning =
  "Complete Actual Input to submit your ratings.";
export const OKRRatingSubmitedMessage = "OKR has been submitted.";
export const OKRRatingSubmitSuccess = "Your rating has been submitted.";

export const OKRRatingSelfSubmitted = "Self rating submitted successfully.";
export const OKRRatingSupSubmitted =
  "Supervisor rating submitted successfully.";
export const OKRReviewSubmitted = "OKR review submitted successfully.";

export const OKRRatingNotOpened =
  "Rating is not opened for this Period of Review.";
export const SelfAndSupervisorRatingPending = (
  <div>
    Self or Supervisor input is pending.
    <br />
    <br />
    OKR Review can be initiated only after Self and Supervisor inputs are
    completed.
  </div>
);

export const RatingLocked = (
  <div>
    The Rating & Review for this team-member is locked for this period.
    <br />
    <br></br> Rating & Review need not be done for the team-member for this
    period.
  </div>
);
export const RatingNotApplicable = (
  <div>
    No Objectives to Rate or Review for this team-member in this Performance
    Period.
  </div>
);
export const RatingNotOpen = (
  <div>Ratings or Review for this Performance period is not open.</div>
);

export const OKRFinalRatingSubmitWarning =
  "Please complete the Final ratings and then submit.";
export const OKRMeetingSetMustBeGreaterThanCurrentTime =
  "The planned meeting start time must be greater than the current time.";
export const OKRMeetingSetMustBeGreaterThanStartTime =
  "The planned meeting end time must be greater than the start time.";

/*
Tool Tip Messages
*/

export const PleaseEnterTheActualAchievedValueBasedOnReferenceDocument =
  "Please enter the actual achieved value based on reference document(s)/ system(s).";
export const YouMayUploadSupportingDocumentForTheAchievedValue =
  "You may upload supporting document(s) for the achieved value";
export const YouMayUploadSupportingDocumentForTheRating =
  "You may upload supporting document(s) for the rating";
export const YouMayViewSupportingDocumentForTheRating =
  "You may view supporting document(s) for the rating";
export const SelectYourRatingRecommendation =
  "Select your rating recommendation";
export const SelectDateAndTimeForPerformanceReviewDiscussion =
  "Select a date and time for performance review discussion";
export const SelectYourFinalRatingForTheKeyResult =
  "Select your final rating for the Key Result";
export const SelectYourRatingForTheLeadershipPrinciple =
  "Select your rating for the Leadership Principle";
export const YouCanUploadSupportingDocumentForYourLeadershipPrincipleRating =
  "You can upload supporting document(s) for your Leadership Principle rating";
export const YouCanUploadSupportingDocumentForYourFinalRating =
  "You can upload supporting document(s) for your final rating";
export const pleaseEnterCorrectURLFormat = "Please enter valid link";

export const PleaseEnterRemark = "Please enter remarks *";
export const youCannotAddThirdPartyInterimHereIn = "Not Applicable";
export const areYouSureYouWantToRemoveInterimFeedback =
  "Are you sure, you want to remove this interim feedback?";
export const accepted = "Actual achievement input, Accepted.";
export const employeeStatusData = [
  { Id: 1, Name: "Active", Value: "1" },
  { Id: 2, Name: "Inactive", Value: "0" },
];

export const PeriofFromnToValidation =
  "OKR Validity Start must be greater than and equal to OKR Validity Ends.";

// LnA
export const company = "Please select company.";
export const pleaseEnterAttendanceName = "Please enter attendance name.";
export const pleaseEnterSymbol = "Please select symbol.";
export const pleaseEnterSymbolName = "Please enter symbol name.";
export const pleaseEnterValidSymbol = "Please enter valid symbol.";
export const pleaseEnterValidSymbolName = "Please enter valid symbol name.";
export const pleaseEnterLnADescription = "Please enter description.";
export const pleaseEnterSymbolDescription = "Please enter description.";
export const pleaseSelectMasterCluster = "Please select master admin.";
export const pleaseSelectLeaveYear = "Please select Leave Year.";
export const pleaseSelectTeamMember = "Please select Team Member.";
export const pleaseEnterAttendanceType = "Please select type.";
export const pleaseEnterNoOfDaysBackdated =
  "Please enter no. of days backdated.";
export const pleaseEnterNoOfDaysFutureDated =
  "Please enter no. of days futuredated.";
export const pleaseEnterStartDate = "Please enter start date.";
export const pleaseEnterEndDate = "Please enter end date.";
export const pleaseEnterColor = "Please select color.";
export const attendenceCountLimit =
  "Attendance name should be maximum of 50 characters only.";
export const descriptionCount =
  "Description should be maximum of 500 characters only.";
export const selectholidaydate = "Please select holiday date.";
export const gender = "Please select gender.";
export const noOfLeaveTransfer = "Please enter min. No. of leave transfer.";
export const minimumLeaveCount = "Please enter minimum one count.";
export const pleaseEnterDayBreak =
  "Please enter day break (in number format only).";
export const pleaseEnterWorkDayWindowName = "Please enter workday window name.";
export const pleaseSelectWorkDayWindowName = "Please select workday window.";
export const pleaseSelectDayBreak = "Please select day break.";
export const pleaseEnterSourceName = "Please enter source name.";
export const pleaseEnterDeviceLocation = "Please enter device location.";
export const pleaseEnterDeviceId = "Please enter device id.";
export const pleaseEnterValidIt1 =
  "Please enter appropriate it1 (upto 50 characters only).";
export const pleaseEnterValidIt2 =
  "Please enter appropriate it2 ( upto 50 characters only).";
export const pleaseEnterValidIt3 =
  "Please enter appropriate it3 (upto 50 characters only).";
export const pleaseEnterValidDeviceId =
  "Please enter appropriate deviceId (upto 50 characters only).";
// exceptional attendence
export const pleaseEnterExceptionalAttendanceName =
  "Please enter exceptional attendance name.";

export const pleaseEnterLeaveName = "Please enter leave name.";
export const pleaseEnterHolidayName = "Please enter holiday name.";
export const pleaseEnterLeaveSymbol = "Please select leave symbol.";
export const pleaseSelectColor = "Please select leave color.";
export const pleaseEnterLeaveDate = "Please select leave date.";
export const pleaseEnterResetDate = "Please select reset date.";
export const pleaseEnterLeaveType = "Please select leave type.";
export const pleaseEnterNoOfLeave = "Please enter no. of leaves.";
export const pleaseEnterFrequencyType = "Please select frequency type.";
export const pleaseEnterLeavePolicyType =
  "Please select stretched or staggered.";
export const pleaseEnterMaxLeavesForward =
  "Please enter max leaves forwarded to next year.";
export const pleaseEnterNoOfDaysEligibleForNextYear =
  "Please enter no. of days forward eligible for next year.";
export const pleaseEnterReimbursed = "Please select reimbursed.";
export const pleaseEnterNoOfDaysForwardAllowed =
  "Please enter no. of days forward allowed.";

export const pleaseEnterWeekdayClusterName =
  "Please enter weekday master name.";
export const pleaseEnterWeekdayStart = "Please select weekday start.";
export const pleaseEnterWeekdayEnd = "Please select weekday end.";
export const pleaseEnterWeekdayCount = "Please enter weekday count.";
export const pleaseEnterWeekendCount = "Please enter weekend count.";
export const pleaseEnterWeekendType = "Please select weekend type.";

export const holidayClusterUpdateMessage =
  "Holiday master updated successfully.";
export const holidayClusterAddMessage = "Holiday master added successfully.";
export const holidayClusterEditMessage = "Holiday master edited successfully.";
export const holidayActiveMessage = "Holiday master activated successfully.";
export const holidayInActiveMessage =
  "Holiday master inactivated successfully.";
export const holidayInActiveMessageError =
  "Holiday master cannot be inactivated, as it is in use.";

export const optionalHolidayClusterAddMessage =
  "Optional Holiday master added successfully.";
export const optionalHolidayClusterEditMessage =
  "Optional Holiday master edited successfully.";
export const optionalHolidayActiveMessage =
  "Optional Holiday master activated successfully.";
export const optionalHolidayInActiveMessage =
  "Optional Holiday master inactivated successfully.";

export const exceptionalClusterUpdateMessage =
  "Exceptional Attendance master updated successfully.";
export const exceptionalClusterAddMessage =
  "Exceptional Attendance master added successfully.";
export const exceptionalClusterEditMessage =
  "Exceptional Attendance master edited successfully.";
export const exceptionalClusterActiveMessage =
  "Exceptional Attendance master activated successfully.";
export const exceptionalClustereInactiveMessage =
  "Exceptional Attendance master inactivated successfully.";
export const exceptionalClustereMessageError =
  "Exceptional Attendance master cannot be inactivated, as it is in use.";

export const attendanceClusterAddMessage =
  "Attendance master added successfully.";
export const attendanceClusterEditMessage =
  "Attendance master edited successfully.";
export const attendanceActiveMessage =
  "Attendance master activated successfully.";
export const attendanceInActiveMessage =
  "Attendance master inactive successfully.";

export const lockUnlockClusterAddMessage =
  "Attendance lock master added successfully.";
export const lockUnlockClusterEditMessage =
  "Attendance lock master edited successfully.";
export const lockUnlockActiveMessage =
  "Attendance lock master activated successfully.";
export const lockUnlockInActiveMessage =
  "Attendance lock master inactive successfully.";

export const leaveYearClusterAddMessage =
  "Leave year master added successfully.";
export const leaveYearClusterEditMessage =
  "Leave year master edited successfully.";
export const leaveYearActiveMessage =
  "Leave year master activated successfully.";
export const leaveYearInActiveMessage =
  "Leave year master inactive successfully.";

export const adminAddEditCalMessage =
  "Teammember calculation updated successfully.";
export const adminCalEditMessage = "Admincal edited successfully.";
export const adminCalActiveMessage = "Admincal activated successfully.";
export const adminCalInActiveMessage = "Admincal inactive successfully.";

export const statutoryClusterUpdateMessage =
  "Statutory master updated successfully.";
export const statutoryClusterAddMessage =
  "Statutory master added successfully.";
export const statutoryClusterEditMessage =
  "Statutory master edited successfully.";
export const statutoryClusterActiveMessage =
  "Statutory master activated successfully.";
export const statutoryClustereInactiveMessage =
  "Statutory master inactivated successfully.";
export const statutoryClustereMessageError =
  "Statutory master cannot be inactivated, as it is in use.";
export const pleaseEnterStatutoryName = "Please enter Statutory Name.";

export const workingdayClusterUpdateMessage =
  "Working day master updated successfully.";
export const workingdayClusterAddMessage =
  "Working day master added successfully.";
export const workingdayClusterEditMessage =
  "Working day master edited successfully.";
export const workingdayClusterActiveMessage =
  "Working day master activated successfully.";
export const workingdayClustereInactiveMessage =
  "Working day master inactivated successfully.";
export const workingdayDayBreakMapActiveMessage =
  "Workday day break mapping activated successfully.";
export const workingdayDayBreakMapInactiveMessage =
  "Workday day break mapping inactivated successfully.";
export const workingdayClustereMessageError =
  "Working day master cannot be inactivated, as it is in use.";
export const workdayDayBreakAddMessage =
  "Workday day break mapping added successfully.";
export const workdayDayBreakEditMessage =
  "Workday day break mapping edited successfully.";

export const workdayClusterAddMessage = "Workday master added successfully.";
export const workdayClusterEditMessage = "Workday master edited successfully.";
export const workdayClusterActiveMessage =
  "Workday master activated successfully.";
export const workdayClustereInactiveMessage =
  "Workday master inactivated successfully.";

export const weekdayClusterAddMessage = "Weekday master added successfully.";
export const weekdayClusterEditMessage = "Weekday master edited successfully.";
export const weekdayClusterActiveMessage =
  "Weekday master activated successfully.";
export const weekdayClustereInactiveMessage =
  "Weekday master inactivated successfully.";

export const dayBreakClusterAddMessage = "Day break master added successfully.";
export const dayBreakClusterEditMessage =
  "Day break master edited successfully.";
export const dayBreakClusterActiveMessage =
  "Day break master activated successfully.";
export const dayBreakClustereInactiveMessage =
  "Day break master inactivated successfully.";

export const leaveModuleAddMessage = "Leave master added successfully.";
export const leaveModuleEditMessage = "Leave master edited successfully.";
export const leaveModuleActiveMessage = "Leave master activated successfully.";
export const leaveModuleInactiveMessage =
  "Leave master inactivated successfully.";

export const memberYearAddMessage = "Member with year added successfully.";
export const memberYearEditMessage = "Member with year edited successfully.";
export const memberYearActiveMessage =
  "Member with year activated successfully.";
export const memberYearInactiveMessage =
  "Member with year inactivated successfully.";

export const sourceClusterUpdateMessage =
  "Attendance source master updated successfully.";
export const sourceClusterAddMessage =
  "Attendance source master added successfully.";
export const sourceClusterEditMessage =
  "Attendance source master edited successfully.";
export const sourceClusterActiveMessage =
  "Attendance source master activated successfully.";
export const sourceClusterInactiveMessage =
  "Attendance source master inactivated successfully.";

export const symbolClusterUpdateMessage = "Symbol master updated successfully.";
export const symbolClusterAddMessage = "Symbol master added successfully.";
export const symbolClusterEditMessage = "Symbol master edited successfully.";
export const symbolClusterActiveMessage =
  "Symbol master activated successfully.";
export const symbolClustereInactiveMessage =
  "Symbol master inactivated successfully.";
export const symbolClustereMessageError =
  "Symbol master cannot be inactive, as it is in use in some master(s).";
export const symbolClustereAssociated =
  "Symbol master is associated with some master(s).";

export const roleClusterActiveMessage = "Role master activated successfully.";
export const roleClustereInactiveMessage =
  "Role master inactivated successfully.";

export const attendanceClusterUpdateMessage =
  "Attendance master updated successfully.";

export const dayBreakClusterUpdateMessage =
  "Day break master updated successfully.";
export const leaveClusterUpdateMessage = "Leave master updated successfully.";
export const weekdayClusterUpdateMessage =
  "Week day master updated successfully.";
export const workDayWindowUpdateMessage =
  "Work day window updated successfully.";
export const candidateDocMovementMessage = "Checklist updated successfully.";
export const leaveTransferaddedMessage = "Leave transfer added successfully.";
export const compOffAddMessage = "Comp. Off applied successfully.";
export const compOffEditedMessage = "Comp. Off edited successfully.";
export const compOffInactivatedMessage = "Comp. Off cancelled successfully.";
export const regularizationUpdateMessage =
  "Regularization edited successfully.";
export const regularizationAddMessage =
  "Regularization application applied successfully.";
export const regularizationCancelMessage =
  "Regularization cancelled successfully.";
export const okrSortingSuccess =
  "OKR objective(s) sort sequence is saved successfully.";
export const leaveApproved = "Leave approved successfully.";
export const leaveRejected = "Leave rejected successfully.";
// practice area user type
export const UserType = [
  { Id: 1, Name: "Sales", Value: "1" },
  { Id: 2, Name: "Operation", Value: "2" },
  { Id: 3, Name: "SalesnOps", Value: "3" },
  { Id: 4, Name: "Other", Value: "4" },
  { Id: 5, Name: "Marketing", Value: "5" },
  { Id: 6, Name: "Solution", Value: "6" },
];

export const fixedHolidayIconColor = "invert(60%) sepia(37%) saturate(5618%) hue-rotate(226deg) brightness(93%) contrast(86%)"
export const appliedHolidayIconColor = "invert(35%) sepia(23%) saturate(2080%) hue-rotate(134deg) brightness(98%) contrast(98%)"
export const duplicateFileExists = "A file already exists or may be some issue while uploading."
export const errorConnectingBD = "Connectivity to BD System is broken. Team is working on it.";
// workday window
// export const pleaseEnterWorkdayWindowName = "Please enter workday window name.";
export const pleaseEnterWorkdaysymbol = "Please select symbol.";
export const pleaseEnterNoOfInstances =
  "Please enter number of instances (In number format only).";
export const pleaseEnterFrequency = "Please select frequency.";
export const pleaseEnterWeeklyworkhours =
  "Please enter weekly work hours (In number format only).";
export const pleaseEnterGraceTime =
  "Please enter grace time in minutes. (Upto 60 minutes only)";
export const pleaseSelectffectiveStartDate =
  "Please select effective start date.";
export const EndTimeGreaterThanStartTime =
  "End time must be greater than start time.";
export const OutTimeGreaterThanInTime =
  "Out time must be greater than in time.";
export const StartTimeLessThanEndTime =
  "Start time must be lesser than end time.";
export const pleasesetWorkHrs = "Please select work hours.";
export const pleaseSetMinHrs = "Please select minimum hours.";
export const pleaseSetMaxHrs = "Please select maximum hours.";
export const effectiveStartDateVaidation =
  "Effective start date must be less than effective end date.";
export const effectiveenddatevalidation =
  "Effective end date must be greater than effective start date.";
export const pleaseEnterStartTime = "Please select start time.";
export const pleaseEnterEndTime = "Please select end time.";
export const holidayResetDate = "Please enter holiday reset date.";
export const exceptionalName = "Please enter exceptional attendance name.";
export const pleaseEnterstrechedOrStaggered =
  "Please select Streched or Staggered.";
export const pleaseEnterGovtStatutory =
  "Please select Government or Statutory.";
export const pleaseSelectLockStartDate = "Please select lock start date.";
export const pleaseSelectLockEndDate = "Please select lock end date.";
export const pleaseSelectAttendanceMonthDate =
  "Please select attendance month date.";
export const entryAlreadyExist = "Entry already exist for this month.";
export const entryAlreadyActive = "Entry already active for this month.";
export const usersAlreadyAdded =
  "Users already added for this configuration year.";

export const minFiftyCharacters = "Should be maximum of 50 characters only.";
export const enterSymbol = "Please enter symbol.";
export const enterDate = "Please enter date.";
export const enterDay = "Please enter day.";
export const enterCoutnry = "Please select country.";
export const selectDayBreak = "Please select day break.";
export const pleaseEnterWorkingDay = "Please enter working day name.";

export const OKRQuestionRatingData = [
  {
    Name: "Not Observed",
    Id: 0,
    value: null,
  },
  {
    Name: "Never",
    Id: 1,
    value: null,
  },
  {
    Name: "Rarely",
    Id: 2,
    value: null,
  },
  {
    Name: "Sometimes",
    Id: 3,
    value: null,
  },
  {
    Name: "Most of the time",
    Id: 4,
    value: null,
  },
  {
    Name: "Always",
    Id: 5,
    value: null,
  },
];
export const landingMessageSelfRatingView = 'Please select a Financial Year to see details'
export const landingMessageTeamRatingView = "Please select a Financial Year, then Team member and Month to see details"
export const PunchType = [
  {
    Name: "In",
    Id: 1,
    value: "1",
  },
  {
    Name: "Out",
    Id: 2,
    value: "2",
  },
  {
    Name: "In-Out",
    Id: 3,
    value: "3",
  },
];
export const BREModuleActions = {
  leaveApplication: 1,
  regularizationApplication: 2,
  leaveRuleViolation: 3,
  leaveTransferApplication: 4,
  compOffApplication: 5,
  specialAttendance: 6,
  leaveCancellation: 7,
  specialAttendanceViolation: 8,
  specialAttendanceCancellation: 11
}
export const regexOnlyLettersAndSpaces = /^[a-zA-Z].*/
export const regexForHoliday = /^[a-zA-Z].*/


//OKR MASTER

export const pleaseEnterXposition = "Please enter x position value.";
export const pleaseEnterYposition = "Please enter y position value.";
export const pleaseEnterBlockName = "Please enter block name.";
export const pleaseEnterblockDescription = "Please enter block description.";
export const pleaseEnterBlockMeaning = "Please enter block meaning.";
export const pleaseSelectBlockHeadingColor = "Please select primary color.";
export const pleaseSelectBlockBodyColor = "Please select secondary color.";
export const pleaseEnterBlockPlanOfAction =
  "Please enter block plan of action.";

export const blockerMasterAddMessage = "Blocker master added successfully.";
export const blockerMasterEditMessage = "Blocker master edited successfully.";
export const blockerMasterActiveMessage =
  "Blocker master activated successfully.";
export const blockerMasterInactiveMessage =
  "Blocker master inactivated successfully.";

export const pleaseenterlabel = "Please enter legend type.";
export const pleasechoosecolor = "Please choose color.";
export const selecteffectivestartdate = "Please select effective start date.";
export const pleaseenterroleName = "Please enter role name.";

export const pleaseenterstatutoryname = "Please  enter statutory name."
export const pleaseEnterValidBlockName = "Please enter approptiate block name (alphabets only).";

export const axisMasterAddMessage = "Axis master added successfully."
export const axisMasterEditMessage = "Axis master edited successfully."

/**
 * Author: Md Jahir uddin
 * Date: 23-01-2023
 * Description: Constants to display the rating system success and error message
 */
export const pleaseEnterRatingSystemName = "Please enter rating scale name.";
export const characterCountLimit =
  "Character count should be less than or equal to 100.";
export const ratingMasterAddMessage = "Rating master added successfully.";
export const ratingMasterEditMessage = "Rating master edited successfully.";
export const ratingMasterActiveMessage =
  "Rating master activated successfully.";
export const ratingMasterInactiveMessage =
  "Rating master inactivated successfully.";

/**
 * Author: Md Jahir uddin
 * Date: 23-01-2023
 * Description: Constants to display the rating master success and error message
 */
export const ratingSystemAddMessage = "Rating scale added successfully.";
export const ratingSystemEditMessage = "Rating scale edited successfully.";
export const ratingSystemActiveMessage = "Rating scale activated successfully.";
export const ratingSystemInactiveMessage =
  "Rating scale inactivated successfully.";
export const ratingMasterRatingSystemRequired = "Rating scale is required.";
export const ratingMasterValueValidation = "Please enter a value.";
export const ratingMasterMeaningValidation = "Please enter a meaning.";
export const ratingMasterLowerValueFromStartingValidation =
  "Please enter a level.";
export const ratingMasterLevelValidation = "Level must be a number.";

/**
 * Author: Md Jahir uddin
 * Date: 23-01-2023
 * Description: Constants to display the axis cut-off success and error message
 */
export const axisCutOffAddMessage = "Axis cut-off added successfully.";
export const axisCutOffEditMessage = "Axis cut-off edited successfully.";
export const axisCutOffActiveMessage = "Axis cut-off activated successfully.";
export const axisCutOffInactiveMessage =
  "Axis cut-off inactivated successfully.";
export const axisCutOffAxisNameNotPresent = "Please select a valid axis name.";
// export const axisCutOffAxisNameValidation = "Please select a valid Axis Name."
export const axisCutOffAxisPositionNotPresent =
  "Please select a valid axis position.";
export const axisCutOffAxisRangeNotPresent = "Please select a valid range.";
export const axisCutOffAxisMinValueNotPresent = "Please enter a lower value.";
export const axisCutOffAxisMaxValueNotPresent = "Please enter a higher value.";
export const axisCutOffAxisFyIdNotPresent = "Please select a financial year.";
export const axisCutOffAxisRangeValidation =
  "Please select a valid Range based on your axis name.";
export const axisCutOffAxisMinValueValidation =
  "Lower value should be less than higher value.";
export const axisCutOffAxisMaxValueValidation =
  "Higher value should be greater than lower value.";

export const performanceMasterAddMessage =
  "Performance master added successfully.";
export const performanceMasterEditMessage =
  "Performance master edited successfully.";
export const performanceMasterActiveMessage =
  "Performance  master activated successfully.";
export const performanceMasterInactiveMessage =
  "Performance master inactivated successfully.";

export const potentialMasterAddMessage = "Potential master added successfully.";
export const potentialMasterEditMessage =
  "Potential master edited successfully.";
export const potentialMasterActiveMessage =
  "Potential  master activated successfully.";
export const ppotentialMasterInactiveMessage =
  "Potential master inactivated successfully.";

export const pleaseEnterPerformanceName = "Please enter performance name.";
export const pleaseEnterPotentialName = "Please enter potential name.";
export const pleaseSelectAllocationType = "Please select allocation type.";
export const PleaseSelectRatingScaler = "Please select rating scale.";
export const pleaseEnterPerformanceFY = "Please select performance FY.";
export const pleaseSelectPerformanceFrequency =
  "Please select performance frequency.";
export const STANDARDNAME = "Standard";
export const STANDARDVALUE = "standard";
export const DEFAULTNAME = "";
export const DEFAULTVALUE = "";
export const LOCKEDNAME = "";
export const LOCKEDVALUE = "";
export const NOT_APPLICABLENAME = "";
export const NOT_APPLICABLEVALUE = "";
export const confirmationIdea = "Are you sure you want to submit the idea?";
export const confirmationRequest =
  "Are you sure you want to submit the request?";

export const typeofRating = [
  { Name: "Default", Value: "defaultCalculation" },
  { Name: "Standard", Value: "standerdCalculation" },
  { Name: "Locked", Value: "lockRating" },
  { Name: "Not Applicable", Value: "NotApplicable" },
];

export const teamMemberMatrixMonthFromColumnIndex = {
  aprCalculation: "4",
  mayCalculation: "5",
  junCalculation: "6",
  julCalculation: "7",
  augCalculation: "8",
  sepCalculation: "9",
  octCalculation: "10",
  novCalculation: "11",
  decCalculation: "12",
  janCalculation: "1",
  febCalculation: "2",
  marCalculation: "3",
};

export const pleaseEnterValidPerformanceName =
  "Please enter valid performance name (can take upto 50 alphabets only).";
export const pleaseEnterValidPotentialName =
  "Please enter valid potential name (can take upto 50 alphabets only).";
export const regexOnlyAlphabetsAndSpaces = /^([a-zA-Z\s]{1,50})$/;

export const pleaseSelectWeightageFrom = "Please enter weightage range from.";
export const pleaseSelectWeightageTo = "Please enter weightage range to.";
export const pleaseSelectWeightageDeafualt = "Please enter default weightage.";
export const pleaseSelectWeightageVariable = "Please enter weightage variable.";

export const frequencyMasterAddMessage = "Frequency master added successfully.";
export const frequencyMasterEditMessage =
  "Frequency master edited successfully.";
export const frequencyMasterActiveMessage =
  "Frequency  master activated successfully.";
export const frequencyMasterInactiveMessage =
  "Frequency master inactivated successfully.";

export const pleaseEnterFrequencyFY = "Please select financial year.";
export const pleaseEnterFrequencyFreq = "Please select frequency.";
export const pleaseEnterFrequencyIndicator = "Please select indicator.";

export const pleaseEnterFrequencyPerformanceStDate = "Please select performance start date."
export const pleaseEnterFrequencyPerformanceAddEndDate = "Please select add performance end date."
export const pleaseEnterFrequencyPerformanceEditEndDate = "Please select edit performance end date."

export const pleaseEnterFrequencyPotentialStDate = "Please select potential start date."
export const pleaseEnterFrequencyPotentialAddEndDate = "Please select add potential end date."
export const pleaseEnterFrequencyPotentialEditEndDate = "Please select edit potential end date."


export const pleaseEnterFrequencyRatingStDate = "Please select rating start date."
export const pleaseEnterFrequencySelfRatingEndDate = "Please select self rating end date."
export const pleaseEnterFrequencySupRatingEndDate = "Please select supervisor rating end date ."
export const pleaseEnterFrequencyFinalRatingEndDate = "Please select final rating end date."

/** 
 * Author: Md Jahir uddin
 * Date: 31-01-2023
 * Description: Constants to display the sub potential calculation success and error message
 */

export const selectSubPotentialName = "Please select sub potential name.";
export const enterQuestionForSelf = "Please enter attribute for self.";
export const enterQuestionDescriptionForSelf =
  "Please enter attribute description for self.";
export const enterQuestionForSupervisor =
  "Please enter attribute for supervisor.";
export const enterQuestionDescriptionForSupervisor =
  "Please enter attribute description for supervisor.";
export const selectRatingSystem = "Please select rating scale.";
export const selectFinancialYear = "Please select financial year.";
export const selectFrequency = "Please select frequency.";
export const selectStartMonth = "Please select validity start.";
export const selectEndMonth = "Please select validity end.";
export const subPotentialCalculationAddMessage =
  "Sub potential calculation added successfully.";
export const subPotentialCalculationEditMessage =
  "Sub potential calculation edited successfully.";
export const subPotentialCalculationActiveMessage =
  "Sub potential calculation activated successfully.";
export const subPotentialCalculationInactiveMessage =
  "Sub potential calculation inactivated successfully.";

export const frequencyDataSource = [
  { Name: "Monthly", Id: "1" },
  { Name: "Quarterly", Id: "2" },
  { Name: "Half-Yearly", Id: "3" },
  { Name: "Yearly", Id: "4" },
];

export const pleaseSelectTeamMemberMonth = "Please select month.";
export const pleaseSelectTeamMemberFromDate = "Please select from date.";
export const pleaseSelectTeamMemberToDate = "Please select to date.";
export const pleaseSelectTeamMemberTypeofRating =
  "Please select a rating scale.";
export const enterIndicator = "Please enter indicator.";
export const pleaseSelectTeamMemberLastDate = "Please select last date.";
export const pleaseSelectTeamMemberLastDateSup = "Please select last date.";
export const pleaseSelectTeamMemberLastDateFinal = "Please select last date.";
export const pleaseSelectPotentialType = "Please select potential type.";
export const pleaseSelectSubPotentialType = "Please select sub potential name.";
export const weightedAllocationValidation =
  "Sum of weighted allocation of the X and Y axis must be 100.";
export const weightedVariableValidation =
  "Sum of weighted variable of the X and Y axis must be either 0 or 100.";
export const daysToSetOkrBeforeStart = 5;

/**
 * Author:Abdulla Shaikh
 * Date: 24-02-2023
 * Description: Constants to display the Helpdesk Ticket success and error message
 */

export const pleaseSelectFunctionTemp = "Please select function.";
export const pleaseSelectCategory = "Please select category.";
export const pleaseSelectSubCategory = "Please select custom sub-category.";
export const pleaseProvideSubCategory = "Please enter sub-category name.";
export const pleaseEnterSubject = "Please enter subject.";
export const subjectLength = "Subject length must be less then 100 character.";
export const pleaseSelectPriority = "Please select priority.";
export const pleaseSelectImpact = "Please select impact.";

export const ticketUpdateFailed = "Ticket update failed.";
export const ticketUpdateSuccess = "Ticket updated successfully.";
export const minDiscription =
  "Minimum description length should be more then 50 character.";

/**
 * Author: ML Vivek Khodade
 * Date: 05-04-2023
 * Description: Constants to display the new ticket priority
 */
export const priorityData = [
  { Id: "-1", Name: "Low" },
  { Id: "0", Name: "Normal" },
  { Id: "1", Name: "High" },
  { Id: "2", Name: "Critical" },
];
/**
 * Author: Md Jahir Uddin
 * Date: 04-04-2023
 * Description: Constants to display the Helpdesk Ticket priority
 */
export const helpdeskPriorityList = {
  "-1": "Low",
  0: "Normal",
  1: "High",
  2: "Critical",
};

/**
 * Author: Md Jahir Uddin
 * Date: 04-04-2023
 * Description: Constants to map helpdesk custom field
 */
export const helpdeskCustomFields = {
  ClientImpact: "1",
  SubCategoryName: "2",
  SubCategoryId: "3",
  SubCategoryMapId: process.env.REACT_APP_HD_CUSTOM_SUB_FIELDID,
};
export const newServiceRequestAdded =
  "New service request submitted successfully.";
export const addEditSubCategorySuccess = "sub category successfully.";
export const addEditSubCategoryFailed = "Error occurred while updating the helpdesk profile.";
export const addIdeaSuccess = "New Idea Submitted Successfully.";
export const addIdeaError = "New Idea Submition Failed.";

/**
 * Author: Md Jahir Uddin
 * Date: 10-04-2023
 * Description: Constants to map helpdesk cticket status
 */
export const helpdeskTicketStatus = {
  "In Progress": "InProgress",
  Resolved: "Resolved",
  New: "New",
  Closed: "Closed",
};

export const ticketResolvedBufferTime = 2;
export const ticketResolvedBufferTimeErrorMsg =
  "Further Update Can't be added since Ticket Reopening Period is Crossed.";
export const ticketResolvedBufferTimeBtnMsg =
  "Further Update Can't be added since Ticket Reopening Period is Crossed.";
export const topTicketConfirmationBtnMsg =
  "Are you sure you want to replace the entered details?";

export const imageTypes = ['jpeg', 'jpg', 'png']
export const CHAIR_PERSON_CHANGE_MESSAGE = 'Kindly regenerate the meeting link as the Chair Person has been changed.'

export const timeZoneNames = {
  "GMT-12:00": "BIT",
  "GMT-11:00": "NUT",
  "GMT-10:00": "HST",
  "GMT-09:30": "MART",
  "GMT-09:00": "AKST",
  "GMT-08:00": "PST",
  "GMT-07:00": "MST",
  "GMT-06:00": "CST",
  "GMT-05:00": "EST",
  "GMT-04:30": "VET",
  "GMT-04:00": "AST",
  "GMT-03:30": "NST",
  "GMT-03:00": "BRT",
  "GMT-02:00": "GST",
  "GMT-01:00": "AZOT",
  "GMT±00:00": "GMT",
  "GMT+01:00": "CET",
  "GMT+02:00": "EET",
  "GMT+03:00": "MSK",
  "GMT+03:30": "IRST",
  "GMT+04:00": "GST",
  "GMT+04:30": "AFT",
  "GMT+05:00": "PKT",
  "GMT+05:30": "IST",
  "GMT+05:45": "NPT",
  "GMT+06:00": "BST",
  "GMT+06:30": "CCT",
  "GMT+07:00": "ICT",
  "GMT+08:00": "CST",
  "GMT+08:45": "SST",
  "GMT+09:00": "JST",
  "GMT+09:30": "ACST",
  "GMT+10:00": "AEST",
  "GMT+10:30": "LHST",
  "GMT+11:00": "SBT",
  "GMT+11:30": "NFT",
  "GMT+12:00": "NZST",
  "GMT+12:45": "CHAST",
  "GMT+13:00": "SST",
  "GMT+14:00": "LINT",
};
export const quaterDayData = [
  { Id: 1, name: "1st Quarter" },
  { Id: 2, name: "2nd Quarter" },
  { Id: 3, name: "3rd Quarter" },
  { Id: 4, name: "4th Quarter" },
];
export const dayData = [
  { Id: 1, name: "Full Day" },
  { Id: 2, name: "Half Day" },
];
export const dayBreakupData = [
  { Id: 1, name: "First Half" },
  { Id: 2, name: "Second Half" },
];

export const errorLnAAlreadyApplied =
  'You have already applied a leave on the selected date. Please check the "Day Break" for more information.';
export const errorLnASelectWorkingDay =
  "The selected date is weekly off, kindly select the working day.";
export const errorLnALocked = "The selected date is locked for leave apply.";
export const errorLnADayBreak = "Please set appropriate day break up.";

//Constants for attendance corretion file upload

export const attendanceFileHeaders = [
  "Team Member Code",
  "Team Member Name",
  "Date",
  "Type1",
  "Portion",
  "Type2",
];

export const swapWeeklyOffFileHeaders = [
  "Team Member Code",
  "Team Member Name",
  "Date",
  "Swapped On Date",
  "Remarks",
];
export const attendanceFilePortionColumnData = ["1", "0.5", "0.25"];
export const templateAttendanceFile = [
  {
    "Team Member Code": "",
    "Team Member Name": "",
    Date: "YYYY-MM-DD",
    Type1: "Y",
    Portion: "1",
    Type2: "",
  },
];
export const templateSwapWeekOffFile = [
  {
    "Team Member Code": "",
    "Team Member Name": "",
    Date: "YYYY-MM-DD",
    "Swapped On Date": "YYYY-MM-DD",
    Remarks: "Description or remarks",
  },
];

export const maxiNoOfRowAcceptableInAttendanceFile = 100;

export const pleaseSelectUserName = "Please select user.";
export const pleaseSelectNotifyTo = "Please select notify to.";
export const pleaseSelectApplication = "Please select sub-category.";
export const emailMappingMasterAddStatus =
  "Email Mapping Pair added successfully.";
export const emailMappingMasterEditStatus =
  "Email Mapping Pair edited successfully.";
export const emailMappingMasterErrorStatus =
  "Email Mapping Pair already exist.";

export const months = [
  {
    Id: 1,
    Name: 'January'
  },
  {
    Id: 2,
    Name: 'February'
  },
  {
    Id: 3,
    Name: 'March'
  },
  {
    Id: 4,
    Name: 'April'
  },
  {
    Id: 5,
    Name: 'May'
  },
  {
    Id: 6,
    Name: 'June'
  },
  {
    Id: 7,
    Name: 'July'
  },
  {
    Id: 8,
    Name: 'August'
  },
  {
    Id: 9,
    Name: 'September'
  },
  {
    Id: 10,
    Name: 'October'
  },
  {
    Id: 11,
    Name: 'November'
  },
  {
    Id: 12,
    Name: 'December'
  },
]

export const allLeavesForFilter = [
  { text: 'Fixed Holiday', id: '2', isChecked: false, symbol: 'HO' },
  { text: 'Availed Festival Holiday', id: '3', isChecked: false, symbol: 'FH' },
  { text: 'Exigency Leave', id: '4', isChecked: false, symbol: 'EL' },
  { text: 'Planned Leaves', id: '5', isChecked: false, symbol: 'PL' },
  { text: 'Hospitalization Leave', id: '6', isChecked: false, symbol: 'HL' },
  { text: 'Quarter Day Leaves', id: '7', isChecked: false, symbol: 'QL' },
]

export const CONTRACT_EXPIRING_PI_CHART_NO_DATA_LABEL = ['Within 60 Days', 'Witin 30 Days', 'Expired'];
export const CONTRACT_EXPIRING_PI_CHART_COLORS = ['#48B8E7', '#F94F5E', '#FFDA83'];
export const CONTRACT_PI_CHART_VALUE_TYPE = 'Contracts';

export const UNSELECT_PRACTICE_AREA="Please unselect Practice Area"
export const UTRReportWarningMaxUser = "Please select max 5 items from Practice Area / Sales Unit / Sub Function / User"

export const toolTipStyle = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontSize: '14px',
  cursor: 'pointer',
  display: 'inline-block',
  maxWidth: '100%',
  fontFamily: 'Circular TT Book'
};
export const MAX_FILE_NAME = 50;
export const MIN_FILE_NAME = 5;
export const MAX_FILES_ALLOWED = 20;
export const FILE_NAME_ERROR=`File name must be between ${MIN_FILE_NAME} and ${MAX_FILE_NAME} characters`;
export const SAME_FILE_NAME_EXIST = 'A file with the same name already exists';
export const FILE_LIMIT_EXCEEDED = `Folder capacity reached. Cannot upload more than ${MAX_FILES_ALLOWED} files`;
export const INVALID_FILE_NAME = 'Invalid file name. Please use a valid file name format (e.g. name_1-2).';
export const EMAIL_COPIED = "The Email Id has been copied to the clipboard.";
export const DOCUMENT_MOVEMENT_COMPLETED = 'Document(s) moved successfully';