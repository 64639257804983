import React, { useEffect, useRef } from 'react';
import { Socket, Event } from "react-socket-io";
import { dencrypt } from '../../app/other/commonfunction';
import { webSocketURI, urlConfig } from '../../config/config'

const options = {
    transports: ["polling", "websocket"],
    rememberUpgrade: true,
};
function SocketIO(props) {
    useEffect(() => {
        if (!("Notification" in window)) {/**NOSOLAR */
        } else {
            Notification.requestPermission();
        }
        return () => {
            // eslint-disable-next-line
            socketRef.current.socket.disconnect()
        }
    }, []);
    const socketRef = useRef(null)

    const sendNotification = (pMessage) => {
        try {
            const act_message = JSON.parse(pMessage)
            var options1 = {
                title: act_message.notificationTitle,
                body: act_message.notificationBodyShort,
                icon: `${urlConfig.tmlcApiUrl}document/icon/${dencrypt(localStorage.getItem("user_id"))}/${act_message.notificationIcon}`,
                dir: "ltr"
            };
            new Notification(act_message.notificationTitle, options1);
        } catch (error) {
            console.log(error)
        }
    }    
    return (
        <Socket uri={webSocketURI} options={options} ref={socketRef}>
            <div>
                <Event event="connection" handler={(message) => {
                    socketRef.current.socket.emit("join", { user: props.empCode })
                }} />
                <Event event="notification" handler={(message) => sendNotification(message)} />
                <Event event="disconnect" handler={(message) => console.log("Socket Disconnected")} />
            </div>
        </Socket>
    );
}

export default SocketIO;
