/**
* Author Name : Vinayak Jadhav.
* Created Date: - 03-08-2021
*/

import * as React from "react";
import { connect } from "react-redux";
import NotificationContainer from "../../../components/notification/notifycontainer";
import { TreeGridComponent, ColumnsDirective, ColumnDirective, Inject, Page, Freeze, Resize } from "@syncfusion/ej2-react-treegrid";
import { getObject } from "@syncfusion/ej2-grids";
import { Dialog } from "@material-ui/core";
import { GridComponent, Filter, VirtualScroll, Sort } from "@syncfusion/ej2-react-grids";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { DialogContent, DialogActions, DialogTitle } from "../../../components/customdialog/customdialog";
import adminServices from "../../../services/adminservices";
import { saveRoleModuleInfoRedux, addEditRoleDetails } from "../../../redux/actions/admin/adminaction";
import { appId } from "../../../config/config";

import ProgressBar from "../../../components/progressbar/progressbar";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";

const SAMPLECSS = `
.grid-left-header-admin{
  border: 0 none;
  display: block;
  font-size: 15px;
  font-weight: 500;
  line-height: 40px !important;
  height: 40px !important;
  margin-left: -1px !important;
  overflow: hidden;
  padding: 0 0.6em !important;
  text-align: left !important;
  -webkit-user-select: none;
  user-select: none;
  width: inherit !important;
  word-wrap: break-word !important;
}
.grid-center-header-admin{
  border: 0 none;
  display: block;
  font-size: 15px;
  font-weight: 500;
  line-height: 40px !important;
  height: 40px !important;
  overflow: hidden;
  padding: 0 0.6em !important;
  -webkit-user-select: none;
  user-select: none;
  text-align: 'center'!important;
  width: inherit !important;
  word-wrap: break-word !important;
}
.grid-left-header-popup{
  border: 0 none;
  display: block;
  font-size: 15px;
  font-weight: 500;
  line-height: 30px;
  height: 30px !important;
  margin-left: -1px !important;
  overflow: hidden;
  padding: 0 0.6em !important;
  text-align: left !important;
  -webkit-user-select: none;
  user-select: none;
  width: inherit !important;
  word-wrap: break-word !important;
}
.grid-center-header-popup{
  border: 0 none;
  display: block;
  font-size: 15px;
  font-weight: 500;
  height: 22px !important;
  line-height: 30px;
  overflow: hidden;
  padding: 0 0.6em !important;
  -webkit-user-select: none;
  user-select: none;
  text-align: 'center'!important;
  width: inherit !important;
  word-wrap: break-word !important;
}
.e-grid .e-rowcell{
  border-style: solid;
  border-width: 1px 0 0 0;
  display: table-cell;
  font-size: 14px !important;
  line-height: 18px !important;
  overflow: hidden;
  padding: 5px 0px 5px 8px !important;
  vertical-align: middle;
  /* white-space: pre-wrap !important; */
  width: auto;
  // text-transform: capitalize;
}
.e-treegrid .e-headercell.customcss{
  display:none
}
`;

class RoleMaster extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      changedData: true,
      roleName: "",
      roleDescription: "",
      roleData: [],
      moduleData: [],
      selectedModuleData: [],
      thisRoleModules: [],
      selectedRows: [],
      moduleMapPopup: false,
      moduleMapPopupTitle: 'Manage',
      displayTree: [],
      height: (window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight) - 290,
      gridHeight: (window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight) - 290,
    }
    this.queryCellInfo = this.queryCellInfo.bind(this);
    this.openModuleMapPopup = this.openModuleMapPopup.bind(this);
    this.onCommandClick = this.onCommandClick.bind(this);
    this.actionCreateRole = this.actionCreateRole.bind(this);
    this.beforeDataBound = this.beforeDataBound.bind(this);
    this.onCheckboxChange = this.onCheckboxChange.bind(this);
    this.onRoleParamChange = this.onRoleParamChange.bind(this);
    this.actionUpdateRole = this.actionUpdateRole.bind(this);
    this.getNestedChildren = this.getNestedChildren.bind(this);
    this.cellSelection = { type: "Multiple", mode: "Row", CheckboxSelectionType: "Default", };
    this.searchModule = "";
    this.selectedRootModule = "";
    this.width =
      (window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth) - 80;
    this.pageOptions = {
      pageSize:
        (window.innerHeight ||
          document.documentElement.clientHeight ||
          document.body.clientHeight) > 700
          ? 10
          : 5,
      pageCount: 5,
    };
    this.commands = [
      {
        type: "Mange Role",
        buttonOption: { iconCss: "e-icons e-edit", className: "e-flat" },
      },
    ];
    this.moduleAccess = [];
    this.parentMod = [];
    this.counter = 0;
    this.customAttributes = { class: "customcss" };
  }

  updateDimensions() {
    const { changedData } = this.state;
    let update_width = window.innerWidth - 100;
    let update_height = window.innerHeight - 140;
    let gridHeight = window.innerHeight - 300;
    this.setState({ width: update_width, height: update_height, gridHeight: gridHeight, changedData: !changedData });
  }
  getNestedChildren = (arr, parent_module_id) => {
    var out = [];
    for (var i in arr) {
      if (arr[i].parent_module_id === parent_module_id) {
        arr[i].isChecked = true;
        var grandChildren = this.getNestedChildren(arr, arr[i]._id);
        if (grandChildren.length) {
          arr[i].sub_mod = grandChildren;
        }
        out.push(arr[i]);
      }
    }
    return out;
  }
  async UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.action_status === "updated") {
      this.closeModuleMapPopup();
      var masterRoles = await adminServices.getMasterRoles();
      var masterModules = await adminServices.getMasterModules();
      var nestedModules = await this.getNestedChildren(masterModules.data, 0); // NOSONAR
      this.setState({ roleData: masterRoles.data, moduleData: nestedModules }, () => this.props.saveRoleModuleInfoRedux({ action_status: "" }));
    }
  }

  componentDidMount = async () => {
    this.setState({ loader: true });
    var masterRoles = await adminServices.getMasterRoles();
    var masterModules = await adminServices.getMasterModules();
    var nestedModules = await this.getNestedChildren(masterModules.data, 0); // NOSONAR
    setTimeout(() => {
      this.setState({ roleData: masterRoles.data, moduleData: nestedModules, loader: false });
      const ele = document.getElementsByClassName("e-headercelldiv");
      for (let i in ele) {
        if (!isNaN(parseInt(i, 10))) {
          let index = parseInt(i, 10);
          if (ele[index].innerText === "Action") {
            ele[index].classList.add("grid-center-header-admin");
          } else {
            ele[index].classList.add("grid-left-header-admin");
          }
        }
      }
    }, 2000);
  };
  /**
   * rowDataBound is for to apply css of tree grid view.
   */
  rowDataBound = (args) => { // NOSONAR
    const { selectedRows, thisRoleModules, moduleMapPopupTitle } = this.state;
    var thisRow_id = getObject('_id', args.data);
    if (moduleMapPopupTitle === 'Manage') {
      var selectedRowsArr = selectedRows;
      var idx = thisRoleModules.module_access.findIndex(ech => ech.module_id === thisRow_id);

      if (idx > -1) {
        if (thisRoleModules.module_access[idx].attributes && thisRoleModules.module_access[idx].attributes.length > 0) { // NOSONAR - For empty Block
        }
        else {
          selectedRowsArr.push(parseInt(args.row.getAttribute('aria-rowindex'), 0));
          this.setState({ selectedRows: selectedRowsArr });
        }
      }
      else {
        thisRoleModules.module_access.forEach(echAcc => {
          if (echAcc.attributes) {
            var idx1 = echAcc.attributes.findIndex(echAttr => echAttr.attribute_id === thisRow_id);
            if (idx1 > -1) {
              if (echAcc.attributes[idx1].child && echAcc.attributes[idx1].child.length > 0) { // NOSONAR - For empty Block
              }
              else {
                selectedRowsArr.push(parseInt(args.row.getAttribute('aria-rowindex'), 0));
                this.setState({ selectedRows: selectedRowsArr });
              }
            }
            else {
              echAcc.attributes.forEach(echChild => {
                if (echChild.child && echChild.child.length > 0) {
                  var idx2 = echChild.child.findIndex(chld => chld.attribute_id === thisRow_id);
                  if (idx2 > -1) {
                    if (echChild.child[idx2].child && echChild.child[idx2].child.length > 0) { // NOSONAR - For empty Block
                    }
                    else {
                      selectedRowsArr.push(parseInt(args.row.getAttribute('aria-rowindex'), 0));
                      this.setState({ selectedRows: selectedRowsArr });
                    }
                  }
                }
                if (echChild.attribute_id === thisRow_id) {
                  selectedRowsArr.push(parseInt(args.row.getAttribute('aria-rowindex'), 0));
                  this.setState({ selectedRows: selectedRowsArr });
                }
              });
            }
          }
        });
      }
    }
  };
  isNumeric = (value) => {
    return /^-{0,1}\d+$/.test(value);
  };
  /**
   *
   * @param {*} args
   * queryCellInfo function for filter data each cell wise.
   */
  queryCellInfo(args) { // NOSONAR
    // const { cell } = args;
    // const { data } = args;
    // args.cell.classList.add('above-80');
  }
  beforeDataBound(args) {
    this.grid.resizeSettings.mode = "Auto";
  }
  load() {
    let cols = this.columns;
    let width = 0;
    width = cols.reduce(function (prev, cur) { return prev + cur.width; }, 0);
    if (width < 1830) width = width + 21; // setting total columns width to Tree Grid
    else width = 1830;

    if (width < this.width) {
      this.width = width;
    }
  }
  closeModuleMapPopup() {
    this.parentMod = [];
    this.resetReducer();
    this.selectedRootModule = "";
    this.setState({ moduleMapPopup: false, thisRoleModules: [], selectedRows: [], selectedModuleData: [], displayTree: [] });
  }
  openModuleMapPopup() {
    this.setState({ moduleMapPopup: true });
  }
  async onCommandClick(args) {
    const { roleData } = this.state;
    var selectedRoleId = args.rowData._id;
    var moduleDetails = await roleData.find(role => role._id === selectedRoleId);
    this.parentMod = [];
    this.parentMod = moduleDetails.module_access;
    var selectedMods = await this.filterSelectedNodes(); // NOSONAR
    this.props.saveRoleModuleInfoRedux({
      role_modules: this.parentMod,
      role_name: moduleDetails.role_name,
      role_description: moduleDetails.role_description,
      role_id: moduleDetails._id,
      role_status: moduleDetails.role_status
    });
    this.setState({
      moduleMapPopup: true,
      thisRoleModules: moduleDetails,
      roleName: moduleDetails.role_name,
      roleDescription: moduleDetails.role_description,
      moduleMapPopupTitle: 'Manage',
      selectedRows: [],
      displayTree: selectedMods,
    });
    setTimeout(() => {
      const ele = document.getElementsByClassName("e-headercelldiv");
      for (let i in ele) {
        if (!isNaN(parseInt(i, 10))) {
          let index = parseInt(i, 10);

          if (ele[index].innerText === "Module Name") {
            ele[index].classList.add("grid-left-header-popup");
          } else {
            ele[index].classList.add("grid-center-header-admin");
          }
        }
      }
    }, 2);
  }
  resetReducer() {
    this.props.saveRoleModuleInfoRedux({
      role_id: "",
      role_name: "",
      role_description: "",
      role_status: "",
      role_modules: ""
    });
  }
  actionUpdateRole() {
    const { authUser, role_id, role_name, role_description, role_status, role_modules } = this.props;
    var data = {};
    data.app_id = appId;
    data.user_id = authUser;
    data.entity = 'role';
    data.entity_id = role_id;
    data.entity_name = role_name;
    data.entity_description = role_description;
    data.entity_status = role_id === '' ? 1 : role_status;
    data.module_access = JSON.stringify(role_modules);
    if (role_name === '') {
      document.getElementById('roleNameError').innerHTML = "Please enter role name";
    }
    else if (role_description === '') {
      document.getElementById('roleDescriptionError').innerHTML = "Please enter role description";
    }
    else if (role_modules.length === 0 && role_id !== '') {
      document.getElementById('treeGridError').innerHTML = "Please map modules to role";
    }
    else {
      document.getElementById('roleNameError').innerHTML = "";
      document.getElementById('roleDescriptionError').innerHTML = "";
      document.getElementById('treeGridError').innerHTML = "";
      this.props.addEditRoleDetails(data);
    }

  }
  actionCreateRole() {
    this.resetReducer();
    this.setState({ moduleMapPopup: true, moduleMapPopupTitle: 'Create' });
  }
  getBaseModuleList(records, parentModule = []) { // NOSONAR
    var mainMod = [];
    if (parentModule.length > 0) {
      mainMod = parentModule;
    }
    // else if(this.mainModThis.length > 0){
    //   mainMod = this.mainModThis;
    // }
    if (records.type === 'navigation') {
      var moduleObj = {};
      moduleObj.module_id = records._id;
      moduleObj.module_name = records.module_name;
      if (typeof records.childRecords !== 'undefined' && records.childRecords.length > 0) {
        moduleObj.attributes = [];
        records.childRecords.forEach(eachChild => {
          const found = mainMod.some(e => e.module_id === records._id);
          if (!found) mainMod.push(moduleObj);
          return this.getBaseModuleList(eachChild, mainMod);
        });
      }
      else {
        mainMod.push(moduleObj);
      }
    }
    else if (records.type === 'attribute') {
      var childObj = {};
      childObj.attribute_id = records._id;
      childObj.text = records.module_name;
      if (typeof records.childRecords !== 'undefined' && records.childRecords.length > 0) {
        childObj.child = [];
        records.childRecords.forEach(eachChild => {
          id = mainMod.findIndex(em => em.module_id === records.parentItem._id);
          if (id > -1) {
            if (mainMod[id].attributes) {
              dd = mainMod[id].attributes.findIndex(ddm => ddm.attribute_id === records._id);
              if (dd === -1) {
                mainMod[id].attributes.push(childObj);
              }
            }
            else {
              mainMod[id].attributes = [childObj];
            }
          }
          else {
            mainMod.forEach(ee => {
              if (ee.attributes) {
                var ida = ee.attributes.findIndex(ea => ea.attribute_id === records.parentItem._id);
                if (ida > -1) {
                  ee.attributes[ida].child.push(childObj);
                }
              }
            });
          }
          return this.getBaseModuleList(eachChild, mainMod);
        });
      }
      else {
        var id = mainMod.findIndex(em => em.module_id === records.parentItem._id);
        if (id > -1) {
          if (mainMod[id].attributes) {
            var dd = mainMod[id].attributes.findIndex(ddm => ddm.attribute_id === records._id);
            if (dd === -1) {
              mainMod[id].attributes.push(childObj);
            }
          }
          else {
            mainMod[id].attributes = [childObj];
          }
        }
        else {
          mainMod.forEach(eea => {
            if (eea.attributes) {
              var ida = eea.attributes.findIndex(ea => ea.attribute_id === records.parentItem._id);
              if (ida > -1) {
                eea.attributes[ida].child.push(childObj);
              }
            }
          });
        }
      }
    }
    return mainMod;
  }
  getParentModuleList(records, prevNavArr = [], prevAttrArr = []) { // NOSONAR
    var thisParentNavArr = [];
    var thisParentAttrArr = [];
    if (prevNavArr.length > 0) thisParentNavArr = prevNavArr;
    if (prevAttrArr.length > 0) thisParentAttrArr = prevAttrArr;
    this.counter = this.counter + 1;

    let thisObj = {};
    let childObj;
    if (records.parentItem.type === 'attribute') {
      var idx = thisParentAttrArr.findIndex(e => e.attribute_id === records.parentItem._id);
      if (idx > -1) {
        childObj = {};
        childObj.attribute_id = records._id;
        childObj.text = records.module_name;
        thisParentAttrArr[idx].child.push(childObj);
      }
      else {
        thisObj.attribute_id = records.parentItem._id;
        thisObj.text = records.parentItem.module_name;
        thisObj.child = [];
        childObj = {};
        childObj.attribute_id = records._id;
        childObj.text = records.module_name;
        thisObj.child.push(childObj);
        thisParentAttrArr.push(thisObj);
      }
      return this.getParentModuleList(records.parentItem.parentItem, thisParentNavArr, thisParentAttrArr);
    }
    else if (records.type === 'navigation') {
      var idex = thisParentNavArr.findIndex(e => e.module_id === records._id);
      if (idex > -1) {
        thisParentNavArr[idex].attributes = thisParentAttrArr;
      }
      else {
        thisObj = {};
        thisObj.module_id = records._id;
        thisObj.module_name = records.module_name;
        thisObj.attributes = [];
        if (thisParentAttrArr.length === 0) {
          childObj = {};
          childObj.attribute_id = records._id;
          childObj.text = records.module_name;
          thisObj.attributes.push(childObj);
        }
        else {
          thisObj.attributes = thisParentAttrArr;
        }
        thisParentNavArr.push(thisObj);
      }
      return thisParentNavArr;
    }
    else if (records.parentItem.type === 'navigation') {
      var idex2 = thisParentNavArr.findIndex(e => e.module_id === records.parentItem._id);
      if (idex2 > -1) {
        thisParentNavArr[idex2].attributes = thisParentAttrArr;
      }
      else {
        thisObj = {};
        thisObj.module_id = records.parentItem._id;
        thisObj.module_name = records.parentItem.module_name;
        thisObj.attributes = [];
        if (thisParentAttrArr.length === 0) {
          childObj = {};
          childObj.attribute_id = records._id;
          childObj.text = records.module_name;
          thisObj.attributes.push(childObj);
        }
        else {
          thisObj.attributes = thisParentAttrArr;
        }
        thisParentNavArr.push(thisObj);
      }
      return thisParentNavArr;
    }
  }
  finalizeSelectedNodes(node) {
    var set = false;
    node.forEach(md => {
      if (md.tempFlag) {
        set = true;
      }
      else {
        if (md.sub_mod && md.sub_mod.length > 0) {
          var result = this.finalizeSelectedNodes(md.sub_mod);
          if (result.ret) {
            md.tempFlag = 1;
          }
        }
        // else{
        //   set = false;
        // }
      }
    });
    return { node: node, ret: set };
  }
  recursiveNodes(node, checkId) {
    node.forEach(eN => {
      if (eN._id === checkId) {
        eN.tempFlag = 1;
      }
      else {
        if (eN.sub_mod && eN.sub_mod.length > 0) {
          return this.recursiveNodes(eN.sub_mod, checkId);
        }
      }
    });
    return node;
  }
  removeRecursiveNodes(node) {
    node.forEach(async (item) => {
      this.parentMod = this.parentMod.filter(a => a.module_id !== item._id);

      if (item.sub_mod && item.sub_mod.length > 0) {
        return this.removeRecursiveNodes(item.sub_mod);
      }
    });
    return; // NOSONAR
  }
  filterRecursiveNodes(node) {
    node = node.filter(a => { return a.tempFlag === 1 });
    node.forEach(async (item) => {
      if (item.sub_mod && item.sub_mod.length > 0) {
        var nd = this.filterRecursiveNodes(item.sub_mod); // NOSONAR
        item.sub_mod = nd;
      }
    });
    return node;
  }
  async filterSelectedNodes() { // NOSONAR
    const { moduleData } = this.state;
    const moduleDataCopy = JSON.parse(JSON.stringify(moduleData));
    if (this.parentMod.length > 0) {
      this.parentMod.forEach(pm => {
        var idx1 = moduleDataCopy.findIndex(md => md._id === pm.module_id);
        if (idx1 > -1) {
          moduleDataCopy[idx1].tempFlag = 1;
        }
        else {
          moduleDataCopy.forEach(md => {
            if (md.sub_mod && md.sub_mod.length > 0) {
              this.recursiveNodes(md.sub_mod, pm.module_id);
            }
          });
        }

        if (pm.attributes && pm.attributes.length > 0) {
          pm.attributes.forEach(at => {
            var idx2 = moduleDataCopy.findIndex(md => md._id === at.attribute_id);
            if (idx2 > -1) {
              moduleDataCopy[idx2].tempFlag = 1;
            }
            else {
              moduleDataCopy.forEach(md => {
                if (md.sub_mod && md.sub_mod.length > 0) {
                  this.recursiveNodes(md.sub_mod, at.attribute_id);
                }
              });
            }

            if (at.child && at.child.length > 0) {
              at.child.forEach(ch => {
                var idx3 = moduleDataCopy.findIndex(md => md._id === ch.attribute_id);
                if (idx3 > -1) {
                  moduleDataCopy[idx3].tempFlag = 1;
                }
                else {
                  moduleDataCopy.forEach(md => {
                    if (md.sub_mod && md.sub_mod.length > 0) {
                      this.recursiveNodes(md.sub_mod, ch.attribute_id);
                    }
                  });
                }
              });
            }

          });
        }
      });

      var newmoduleData = await this.finalizeSelectedNodes(moduleDataCopy); // NOSONAR
      var newmoduleDataArr = newmoduleData.node;
      var newmoduleDataArr1 = newmoduleDataArr.filter(a => { return a.tempFlag === 1 });
      return this.filterRecursiveNodes(newmoduleDataArr1);
    }
  }
  async onCheckboxChange(args) { // NOSONAR
    const { selectedModuleData } = this.state;
    var baseModuleList = [];
    if (args.checked) {
      if (args.rowElement.className === 'e-columnheader' && args.rowData.length > 1) {
        var allMods = [];
        if (args.rowData.length > 0) {
          args.rowData.forEach(ech => {
            if ((ech.type === 'navigation' && !ech.childRecords) || (ech.type === 'navigation' && ech.childRecords && ech.childRecords[0].type === 'attribute')) {
              let echObj = {};
              echObj.module_id = ech._id;
              echObj.module_name = ech.module_name;
              if (ech.childRecords) {
                echObj.attributes = [];
                ech.childRecords.forEach(echChd => {
                  let echChldObj = {};
                  echChldObj.attribute_id = echChd._id;
                  echChldObj.text = echChd.module_name;
                  if (echChd.childRecords) {
                    echChldObj.child = [];
                    echChd.childRecords.forEach(echSubChld => {
                      let echSubChldObj = {};
                      echSubChldObj.attribute_id = echSubChld._id;
                      echSubChldObj.text = echSubChld.module_name;
                      echChldObj.child.push(echSubChldObj);
                    });
                  }
                  echObj.attributes.push(echChldObj);
                });
              }
              allMods.push(echObj);
            }
          });
        }
        this.parentMod = this.parentMod.concat(allMods);
      }
      else if (args.rowElement.className.includes("e-row")) {
        var moduleObj = {};
        moduleObj.module_id = args.rowData._id;
        moduleObj.module_name = args.rowData.module_name;
        if (args.rowData.type === 'attribute') {
          var modList = this.getParentModuleList(args.rowData);
          if (this.parentMod.length > 0) {
            var idx = this.parentMod.findIndex(e => e.module_id === modList[0].module_id);

            if (idx > -1) {
              if (modList[0].attributes[0].attribute_id) {
                var idx1 = this.parentMod[idx].attributes.findIndex(e1 => e1.attribute_id === modList[0].attributes[0].attribute_id);
                if (idx1 > -1) {
                  if (modList[0].attributes[0].child) {
                    var idx2 = this.parentMod[idx].attributes[idx1].child.findIndex(e2 => e2.attribute_id === modList[0].attributes[0].child[0].attribute_id);
                    if (idx2 > -1) {
                      // Do Nothing
                    }
                    else {
                      this.parentMod[idx].attributes[idx1].child.push(modList[0].attributes[0].child[0]);
                    }
                  }
                }
                else {
                  this.parentMod[idx].attributes.push(modList[0].attributes[0]);
                }
              }
            }
            else {
              this.parentMod.push(modList[0]);
            }
          }
          else {
            this.parentMod = modList;
          }
          if (typeof args.rowData.childRecords !== 'undefined' && args.rowData.childRecords.length > 0) {
            baseModuleList = this.getBaseModuleList(args.rowData, [moduleObj]);
          }
          if (baseModuleList.length > 0) {
            var dex = this.parentMod.findIndex(de => de.module_id === args.rowData._id);
            if (dex > -1) {
              this.parentMod[dex].attributes = baseModuleList;
            }
            else {
              this.parentMod.forEach(eachM => {
                if (eachM.attributes) {
                  var id = eachM.attributes.findIndex(ec => ec.attribute_id === args.rowData._id);
                  if (id > -1) {
                    if (baseModuleList[0].module_id)
                      eachM.attributes[id].child = baseModuleList[0].attributes;
                    else
                      eachM.attributes[id].child = baseModuleList;
                  }
                  else {
                    eachM.attributes.forEach(eM => {
                      if (eM.child) {
                        var id2 = eM.child.findIndex(ec2 => ec2.attribute_id === args.rowData._id);
                        if (id2 > -1) {
                          eM.child[id2].child = baseModuleList;
                        }
                        else {
                          console.log('There is some issue. Please contact administrator');
                        }
                      }
                    });
                  }
                }
              });
            }
          }
        }
        else if (args.rowData.type === 'navigation') {
          if (typeof args.rowData.childRecords !== 'undefined' && args.rowData.childRecords.length > 0) {
            baseModuleList = this.getBaseModuleList(args.rowData);
            baseModuleList = baseModuleList.filter(k => ((k.attributes && k.attributes.length > 0) || !k.attributes));
            baseModuleList.forEach(bm => {
              var im = this.parentMod.findIndex(e => e.module_id === bm.module_id);
              if (im === -1) {
                this.parentMod.push(bm);
              }
            });
          }
          else {
            if (this.parentMod.length > 0) {
              var pmid = this.parentMod.findIndex(pm => pm.module_id === args.rowData._id);
              if (pmid === -1) {
                var pmObj = {};
                pmObj.module_id = args.rowData._id;
                pmObj.module_name = args.rowData.module_name;
                this.parentMod.push(pmObj);
              }
            }
            else {
              this.parentMod.push(moduleObj);
            }
          }
        }
      }
    }
    else {
      if (args.rowElement.className === 'e-columnheader' && args.rowData.length === 0) {
        await this.removeRecursiveNodes(selectedModuleData); // NOSONAR
      }
      else if (args.rowElement.className.includes("e-row")) {
        var unSelectId = args.rowData._id;
        var unSelectType = args.rowData.type;
        if (unSelectType === 'navigation') {
          baseModuleList = this.getBaseModuleList(args.rowData);
          baseModuleList = baseModuleList.filter(k => ((k.attributes && k.attributes.length > 0) || !k.attributes));
        }
        this.parentMod.forEach((el, ind, obj) => {
          if (unSelectType === 'attribute') {
            if (el.attributes) {
              el.attributes.forEach((el2, index, object) => {
                if (el2.attribute_id === unSelectId) {
                  object.splice(index, 1);
                }
              });
            }
          }
          else if (unSelectType === 'navigation') {
            if (el.module_id === unSelectId) {
              obj.splice(ind, 1);
            }
          }
        });
        if (baseModuleList.length > 0) {
          baseModuleList.forEach((bm) => {
            this.parentMod.forEach((el3, ind3, obj3) => {
              if (el3.module_id === bm.module_id) {
                obj3.splice(ind3, 1);
              }
            });
          });
        }
      }
    }
    this.parentMod = await this.parentMod.filter(k => ((k.attributes && k.attributes.length > 0) || !k.attributes));
    var selectedMods = [];
    if (this.parentMod.length > 0) {
      selectedMods = await this.filterSelectedNodes(); // NOSONAR
    }
    this.props.saveRoleModuleInfoRedux({ role_modules: this.parentMod });
    this.setState({ displayTree: selectedMods })

  }
  onRoleParamChange(e, type) {
    if (type === 'name') {
      this.props.saveRoleModuleInfoRedux({ role_name: e.value });
      this.setState({ roleName: e.value });
      document.getElementById('roleNameError').innerHTML = "";
    }
    else if (type === 'desc') {
      this.props.saveRoleModuleInfoRedux({ role_description: e.value });
      this.setState({ roleDescription: e.value });
      document.getElementById('roleDescriptionError').innerHTML = "";
    }
  }
  onChangeRootModule(e) {
    const { moduleData } = this.state;
    this.setState({ selectedModuleData: [], selectedRows: [] });
    setTimeout(() => {
      this.selectedRootModule = e.value;
      var selectedModuleDataArr = moduleData.find(a => a._id === e.value);
      this.setState({ selectedModuleData: [selectedModuleDataArr], selectedRows: [] });
    }, 1000);
  }
  render() {
    const { roleData, moduleData, selectedRows, moduleMapPopup, thisRoleModules, moduleMapPopupTitle, gridHeight, displayTree, selectedModuleData } = this.state;
    var roleName = thisRoleModules.role_name;
    var roleDesc = thisRoleModules.role_description;
    let selectedRowsUnique = selectedRows.filter((c, index) => {
      return selectedRows.indexOf(c) === index;
    });
    return (
      <div className="control-pane">
        {/* Role List */}
        {roleData.length > 0 &&
          <div className="control-section">
            <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6" style={{ marginTop: 35, marginLeft: 30, textAlign: 'left' }}>
              <ButtonComponent
                id="createRole"
                style={{ background: "#f94f5e", color: "#fff", borderRadius: "4px" }}
                data-ripple="true"
                onClick={this.actionCreateRole}
              >
                Create Role
              </ButtonComponent>
            </div>
            <div className="col-xs-9 col-sm-9 col-lg-9 col-md-9" style={{ marginLeft: 30, marginTop: 16 }}>
              <style>{SAMPLECSS}</style>
              <GridComponent
                // className="grid-head-custome"
                id="rolegrid"
                allowPaging
                // pageSettings={this.pageOptions}
                dataSource={roleData}
                enableHover={false}
                enableVirtualization={false}
                rowHeight={30}
                height={gridHeight}
                overflow="auto"
                ref={(g) => {
                  this.gridInstance = g;
                }}
                allowSelection
                selectionSettings={this.select}
                allowTextWrap={true}
                commandClick={this.onCommandClick}
                width="800"
              // queryCellInfo={this.customizeCell}
              >
                <ColumnsDirective>
                  {/* <ColumnDirective field='role_id' visible={false} headerText='Role ID' isPrimaryKey={true} width='130'></ColumnDirective> */}
                  <ColumnDirective field='role_name' headerText='Role Name' width='130' textAlign='Left'></ColumnDirective>
                  <ColumnDirective field='role_description' headerText='Role Description' width='130' textAlign='Left'></ColumnDirective>
                  <ColumnDirective field='_id' commands={this.commands} headerText='Action' width='130' textAlign='Center'></ColumnDirective>
                </ColumnsDirective>
                <Inject services={[Page, Filter, VirtualScroll, Sort]} />
              </GridComponent>
            </div>
          </div>
        }
        {/* Role Maodule Mapping PopUp */}
        <div style={{ width: 600 }}>

          <Dialog open={moduleMapPopup} fullWidth maxWidth="xl" >
            <DialogTitle>
              {moduleMapPopupTitle} Role
            </DialogTitle>
            <DialogContent style={{ paddingTop: 0 }}>
              <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
                <div style={{ paddingBottom: "0px" }} className="col-xs-10 col-sm-10 col-lg-10 col-md-10" ><b>Role Details</b></div>
                <div style={{ paddingTop: "10px" }} className="col-xs-6 col-sm-6 col-lg-6 col-md-6" >
                  <TextBoxComponent
                    id="roleName"
                    placeholder="Role Name *"
                    cssClass="e-outline"
                    floatLabelType="Auto"
                    value={roleName}
                    // change={(e) => this.setState({ roleName: e.value }, () => document.getElementById('roleNameError').innerHTML = "")}
                    change={(e) => this.onRoleParamChange(e, 'name')}
                  />
                  <div id="roleNameError" className="e-input-group e-float-icon-left error-div" style={{ fontSize: 12 }} ></div>
                </div>
                <div style={{ paddingTop: "10px" }} className="col-xs-6 col-sm-6 col-lg-6 col-md-6" >
                  <TextBoxComponent
                    id="roleDescription"
                    placeholder="Role Description *"
                    cssClass="e-outline"
                    floatLabelType="Auto"
                    value={roleDesc}
                    // change={(e) => this.setState({ roleDescription: e.value }, () => document.getElementById('roleDescriptionError').innerHTML = "")}
                    change={(e) => this.onRoleParamChange(e, 'desc')}
                  />
                  <div id="roleDescriptionError" className="e-input-group e-float-icon-left error-div" style={{ fontSize: 12 }}  ></div>
                </div>
                <div style={{ paddingBottom: "0px" }} className="col-xs-10 col-sm-10 col-lg-10 col-md-10" ></div>
                <div style={{ paddingTop: "10px" }} className="col-xs-6 col-sm-6 col-lg-6 col-md-6" >
                  <DropDownListComponent
                    id={`rootModules`}
                    name={`rootModules`}
                    dataSource={moduleData}
                    fields={{ text: "module_name", value: "_id" }}
                    placeholder="Select Module"
                    // value={this.selectedRootModule}
                    popupHeight="220px"
                    cssClass="e-outline"
                    floatLabelType="Auto"
                    allowCustom={false}
                    //   data-msg-containerid={`veriableLinkErr${index}`}
                    change={(e) => this.onChangeRootModule(e)}
                  />
                </div>
                <div
                  style={{ marginLeft: 16, marginTop: 25, marginBottom: 25, paddingRight: 24 }}
                  className="col-xs-12 col-sm-12 col-lg-12 col-md-12 force-overflow"
                >
                  <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12" style={{ marginBottom: '10px' }}><b>Manage Module Access</b></div>
                  <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                    <TreeGridComponent
                      dataSource={selectedModuleData}
                      treeColumnIndex={0}
                      childMapping='sub_mod'
                      // idMapping ='unique_id'
                      height='410'
                      autoCheckHierarchy={true}
                      dataBound={function (args) {
                        this.selectCheckboxes(selectedRowsUnique);
                      }}
                      enableHierarchySelection={true}
                      // width={857}
                      selectionSettings={this.cellSelection}
                      rowDataBound={this.rowDataBound}
                      checkboxChange={this.onCheckboxChange}
                      ref={treegrid => this.treegridObj = treegrid}
                    >
                      <ColumnsDirective>
                        {/* <ColumnDirective field='unique_id' headerText='ID' width='20' textAlign='Center'></ColumnDirective> */}
                        <ColumnDirective field='module_name' headerText='Module Name' width='100' textAlign='Left' showCheckbox={true}></ColumnDirective>
                        <ColumnDirective field='type' headerText='Type' width='60' format='yMd' textAlign='Center' />
                      </ColumnsDirective>
                      <Inject services={[Freeze, Resize, Page]} />
                    </TreeGridComponent>
                  </div>
                  <div id="treeGridError" className="error-div" style={{ fontSize: 12 }} />
                </div>
              </div>
              <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
                <div className="col-xs-1 col-sm-1 col-lg-1 col-md-1" style={{ borderLeft: '3px solid gray', height: 657, marginLeft: 42 }}></div>
                <div style={{ paddingBottom: "0px" }} className="col-xs-10 col-sm-10 col-lg-10 col-md-10" ><b>Module Access </b> - {roleName}</div>
                <div style={{ height: 611, overflowY: 'auto', marginTop: 10, fontSize: 6 }} className="col-xs-10 col-sm-10 col-lg-10 col-md-10 force-overflow" >
                  {displayTree && displayTree.length > 0 &&
                    <TreeGridComponent
                      dataSource={displayTree}
                      treeColumnIndex={0}
                      childMapping='sub_mod'
                      idMapping='unique_id'
                      // height='Auto' 
                      autoCheckHierarchy={true}
                      enableHierarchySelection={true}
                      // width={857}
                      // selectionSettings={this.cellSelection}
                      ref={treegrid => this.displayTreegridObj = treegrid}
                      gridLines="None"
                    >
                      <ColumnsDirective>
                        <ColumnDirective field='module_name' headerText='Module Name' width='100' textAlign='Left' customAttributes={this.customAttributes}></ColumnDirective>
                      </ColumnsDirective>
                      {/* <Inject services={[Freeze, Resize, Page]} /> */}
                    </TreeGridComponent>
                  }
                  {!displayTree &&
                    <h6>No module has been mapped to this role yet.</h6>
                  }
                  {displayTree && displayTree.length === 0 &&
                    <h6>No module has been mapped to this role yet.</h6>
                  }
                </div>
              </div >
            </DialogContent>
            <DialogActions>
              <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6" style={{ textAlign: 'right' }}>
                <ButtonComponent
                  id="cancelRoleUpdate"
                  className="e-control"
                  style={{ borderRadius: "4px", marginBottom: 20 }}
                  onClick={(e) => this.closeModuleMapPopup()}
                >
                  Cancel
                </ButtonComponent>
                <ButtonComponent
                  id="updateRole"
                  style={{ background: "#f94f5e", color: "#fff", borderRadius: "4px", marginLeft: 16, marginRight: 5, marginBottom: 20 }}
                  data-ripple="true"
                  onClick={this.actionUpdateRole}
                >
                  Submit
                </ButtonComponent>
              </div>
              <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6"></div>
            </DialogActions>
          </Dialog>
        </div>
        <div style={{ height: 300, zIndex: '9999999999 !important' }}><NotificationContainer /></div>
        {this.state.loader &&
          <div style={{ marginTop: 200 }}> <ProgressBar /></div>
        }
      </div>
    );
  }
}

RoleMaster.propTypes = {
  // dataSource: PropTypes.arrayOf(Object).isRequired,
  // grayLeaves: PropTypes.arrayOf(Object).isRequired,
  // eslint-disable-next-line
  // hideSidebar: PropTypes.object.isRequired,
  // noOfUses: PropTypes.number.isRequired,
};
RoleMaster.defaultProps = {};

const mapStateToProps = ({ auth, adminreducer }) => {
  const { authUser } = auth;
  const { role_id, role_name, role_description, role_status, role_modules, action_status } = adminreducer;
  return { authUser, role_id, role_name, role_description, role_status, role_modules, action_status };
};

export default connect(mapStateToProps, {
  saveRoleModuleInfoRedux,
  addEditRoleDetails,
})(RoleMaster);
