import './default.css';
import * as React from 'react';
import { MainClass } from '../../../../src/mainclass';
import { connect } from 'react-redux';
import { NEWSERVICEREQUEST, NEWIDEA, MYTICKET } from "../../../redux/constant/meetingcategoryconstant";
import { setMeetingMaster } from '../../../redux/actions/meetingmasteraction';
import { getTMLCUsers } from "../../other/commonlayoutfunction";
import queryString from "query-string";
import { dencrypt } from '../../other/commonfunction'
import NewTicketContainer from '../../containers/helpdesk/newticketservicescontainer';
import NewIdeaContainer from '../../containers/helpdesk/newideacontainer';
import MyTicketContainer from '../../containers/helpdesk/myticketcontainer';
import { NotificationContainer } from 'react-notifications';

class HelpDeskLayout extends MainClass {
    constructor(props) {
        super(props)
        this.state = {
            isLoaded: false,
            moduleId: 0
        }
    }
    async componentDidMount() {
        this.setModuleId()
        if (this.props.othersCheckAvailablity < 1) {
            const othersCheckAvailablityData = await getTMLCUsers(this.props.authUser);
            this.props.setMeetingMaster(othersCheckAvailablityData);
        }
    }

    setModuleId() {
        try {
            // Set App Id
            const { moduleId } = this.state
            const params = queryString.parse(this.props.location.search);
            if (typeof params.id !== 'undefined' && params.id !== null) {
                if (moduleId !== dencrypt(params.id)){
                    this.setState({ moduleId: dencrypt(params.id) })
                }
            }
        } catch (error) {

        }
    }

    componentDidUpdate(newProps) {
        try {
            this.setModuleId()
        } catch (error) {
        }
    }

    renderSwitch() {
        const { categoryName } = this.props
        const { moduleId } = this.state
        let activeModule = (moduleId === 0 ? categoryName : moduleId).toString()
        switch (activeModule) {
            case NEWSERVICEREQUEST:
                return <NewTicketContainer />;
            case NEWIDEA:
                return <NewIdeaContainer />;
            case MYTICKET:
                return <MyTicketContainer />;
            default:
                return <NewTicketContainer />;
        }
    }
    render() {
        return (<>
            <NotificationContainer />
            <div style={{ height: '100%', width: '100%', backgroundColor: "#F2F2F2" }}>
                {this.renderSwitch()}
            </div>
        </>
        );
    }
}

const mapStateToProps = ({ auth, meetingcategory, meetingmaster }) => {
    const { authUser } = auth;
    const { categoryName } = meetingcategory;
    const {
        meetingType,
        meetingSubType,
        othersCheckAvailablity
    } = meetingmaster;
    return {
        authUser,
        categoryName,
        meetingType,
        meetingSubType,
        othersCheckAvailablity
    }
};
export default connect(mapStateToProps, { setMeetingMaster })(HelpDeskLayout);
