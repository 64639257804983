import React from "react";
import { connect } from "react-redux";
import { Grid } from "@syncfusion/ej2-react-grids";
import { withStyles } from "@material-ui/core/styles";
import {
  MultiSelectComponent,
  ComboBoxComponent,
  Inject,
  CheckBoxSelection,
} from "@syncfusion/ej2-react-dropdowns";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faSearchPlus,
  faCubes,
  faBoxOpen,
} from "@fortawesome/pro-light-svg-icons";
import { updateMeetingBookingState } from "../../redux/actions/meetingbookingaction";
import bdservices from "../../services/bdservices";
import { MainClass } from "../../mainclass";
import { Cognition, noBTBAvailable, noJobsAvailable, WhatNext } from "../../assets/constants/constants";
import { OTHERS_CALENDAR } from "../../redux/constant/meetingcategoryconstant";
import { dencrypt, decodeString } from "../../../src/app/other/commonfunction";
library.add(faSearchPlus, faCubes, faBoxOpen);

const useStyles = (theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
});

class OpsTaskSearch extends MainClass {
  constructor(props) {
    super(props);
    this.state = {
      contacts: [],
      opsBtbList: [],
      opsSubBtbList: [],
      opsJobList: [],
      errorEnter3charactor: false,
      subCategaryName: "",
    };
    this.grid = Grid;
    this.Filter = { type: "Menu" };
    this.check = { type: "CheckBox" };

    this.listbtbref = React.createRef();
    this.listcategary = React.createRef();
    this.handleChange = this.handleChange.bind(this);
  }




  componentDidMount() { // NOSONAR
    const { meetingId, meetingSubTypeId, btbId, workTypeId, authUser, otherUser, categoryName } = this.props;
    if (
      ((meetingId === 0 && (meetingSubTypeId > 0 || meetingSubTypeId !== "")) || (meetingId > 0 && meetingSubTypeId > 0))
      && meetingSubTypeId !== 925
    ) {
      (async () => {
        const response = Promise.resolve(
          bdservices.getBTBJobTask(categoryName === OTHERS_CALENDAR ? otherUser : authUser, meetingSubTypeId, 0)
        );
        await response.then((data) => {
          if (data !== null) this.setState({ opsBtbList: data });
          if (btbId > 0 && workTypeId === 5) {
            (async () => {
              const responseJob = Promise.resolve(
                bdservices.getBTBJobTask(categoryName === OTHERS_CALENDAR ? otherUser : authUser, meetingSubTypeId, btbId)
              );
              await responseJob.then((dataJob) => {
                let setDataJob = [];
                if (dataJob !== null) setDataJob = dataJob;
                this.setState({ opsSubBtbList: setDataJob });
              });
            })();
          }
        });
      })();
    }
  }

  componentWillUnmount() {
    this.props.updateMeetingBookingState({
      btbId: "",
      subBTBId: "",
    });
  }

  // eslint-disable-next-line
  UNSAFE_componentWillReceiveProps(newProps) {
    const { meetingSubTypeId, authUser, categoryName, otherUser } = this.props;
    if (
      newProps.meetingSubTypeId !== meetingSubTypeId &&
      newProps.meetingSubTypeId !== 925
    ) {
      this.props.updateMeetingBookingState({
        meetingSubTypeId: newProps.meetingSubTypeId,
        btbId: "",
        subBTBId: "",
        workTypeId: 0,
        businessImpact: "",
        meetingCategoryId: "",
      });

      if (newProps.meetingSubTypeId > 0) {
        (async () => {
          const response = Promise.resolve(
            bdservices.getBTBJobTask(categoryName === OTHERS_CALENDAR ? otherUser : authUser, newProps.meetingSubTypeId, 0)
          );
          await response.then((data) => {
            let setData = [];
            if (data !== null && data !== "") {
              setData = data;
            }
            this.setState({ opsBtbList: setData });
          });
        })();
      } else {
        this.setState({ opsBtbList: [] });
      }
    }
  }

  handleChange = (event) => {  // NOSONAR
    const { authUser, meetingSubTypeId } = this.props;
    // eslint-disable-next-line
    switch (event.element.id) {
      case "selectbtblist": {
        let selBTBId = "";
        let selWorkTypeId = 0;
        let selBusinessImpact = "";
        if (event.itemData !== null) {
          selBTBId = event.itemData.Id;
          selWorkTypeId = event.itemData.WorkTypeId;
          selBusinessImpact = event.itemData.Status;
        }
        if (selBTBId > 0 && selWorkTypeId === 5) {
          (async () => {
            const response = bdservices.getBTBJobTask(authUser, meetingSubTypeId, selBTBId);
            await response.then((data) => {
              if (data !== null && data !== "") {
                this.setState({ opsSubBtbList: data });
              }
            });
          })();
        } else if (selBTBId > 0 && selWorkTypeId !== 5) {
          this.setState({ opsSubBtbList: [] });
        }
        this.props.updateMeetingBookingState({
          btbId: parseInt(selBTBId,10),
          subBTBId: "",
          workTypeId: selWorkTypeId,
          businessImpact: selBusinessImpact,
        });
        break;
      }
      case "selectSubBTBId": {
        const selJobId = event.itemData === null ? "" : event.itemData.Id;
        this.props.updateMeetingBookingState({ subBTBId: selJobId });
        break;
      }
      case "sp-select":
        // eslint-disable-next-line
        this.props.updateMeetingBookingState({ serviceProductsId: event.value });
        break;
      default: {
        // eslint-disable-next-line
      }
    }
  };

  noRecordsTemplate = () => {
    return <div>No Records Found</div>;
  };

  handleBtbId = () => {
    this.listbtbref.showPopup();
  };

  handleJobId = () => {
    this.listjobref.showPopup();
  };

  handleCategary = () => {
    this.listcategary.showPopup();
  };

  renderNestedTernary(type, item) {

    if (type === 'btbPlaceHolder') {
      if (item === WhatNext) return "BTB ID";
      else return "BTB ID *";
    }
    else if (type === 'jobPlaceHolder') {
      if (item === WhatNext) return "Sub BTB Id";
      else return "Sub BTB *";
    }
  }

  render() {
    const {
      btbId,
      workTypeId,
      // meetingSubTypeId,
      meetingServiceProduct,
      serviceProductsId,
      subBTBId,
    } = this.props;
    const { opsBtbList, opsSubBtbList } = this.state;
    const pnlId = dencrypt(localStorage.getItem("pnlId"));
    // eslint-disable-next-line
    return (
      <div className="pt-sm" style={{ clear: "both" }}>
        {
          // BTB / Ops / Client
          (
            <div className="col-md-4">
              <div className="custom-margin custom-padding-5 material2">
                <div className="col-md-12">
                  <div className="e-input-group e-float-icon-left">
                    {(opsBtbList.length > 0 || (opsBtbList.length === 0 && dencrypt(localStorage.getItem("pnlId")) !== WhatNext)) &&
                      < FontAwesomeIcon icon={faCubes} className="mt-sm fa-lg" />}
                    <div className="e-input-in-wrap">
                      <div style={{ width: "100%", display: "grid" }}>
                        {opsBtbList.length > 0 ? (
                          <>
                            <ComboBoxComponent
                              id="selectbtblist"
                              ref={(combobox) => {
                                this.listbtbref = combobox;
                              }}
                              name="selectbtblist"
                              dataSource={opsBtbList}
                              fields={{ text: "Name", value: "Id" }}
                              change={this.handleChange}
                              placeholder={this.renderNestedTernary('btbPlaceHolder', pnlId)}
                              value={btbId}
                              popupHeight="220px"
                              cssClass="e-outline"
                              floatLabelType="Auto"
                              data-msg-containerid="opsBtbError"
                              noRecordsTemplate={this.noRecordsTemplate.bind(this)}
                              focus={this.handleBtbId}
                            />
                            <div id="opsBtbError" className="error-div" />
                          </>
                        ) : (
                          //dencrypt(localStorage.getItem("pnlId")) !== WhatNext &&
                          <div id="opsBtbError"
                            className="e-input-group e-float-icon-left error-div">
                            {noBTBAvailable}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        }
        {/* Meeting Service Product */}
        {meetingServiceProduct.length > 0 &&
          btbId < 0 &&
          // (decodeString(localStorage.getItem("user_type")) === "Ops" ||
          //   decodeString(localStorage.getItem("user_type")) === "Solution" ||
          //   decodeString(localStorage.getItem("user_type")) === "Leadership") && 
          (
            <div className="col-md-4">
              <div className="custom-margin custom-padding-5 material2">
                <div className="col-md-12">
                  <div className="e-input-group e-float-icon-left">
                    <FontAwesomeIcon
                      icon={faBoxOpen}
                      className="mt-sm fa-lg1"
                    />
                    <div className="e-input-in-wrap">
                      <div style={{ width: "100%", display: "grid" }}>
                        <MultiSelectComponent
                          style={{ display: "inline-block" }}
                          id="sp-select"
                          name="sp-select"
                          dataSource={meetingServiceProduct}
                          fields={{ text: "Name", value: "Id" }}
                          // placeholder="Service/Platform *"
                          placeholder={(dencrypt(localStorage.getItem('pnlId')) === 2 && (decodeString(localStorage.getItem('user_type')) === "Sales" || decodeString(localStorage.getItem('user_type')) === "Solution")) ? "Service/Platform" : "Service/Platform *"}
                          cssClass="e-outline sp-select"
                          floatLabelType="Auto"
                          showSelectAll={false}
                          popupHeight="350px"
                          change={this.handleChange}
                          value={serviceProductsId}
                          showDropDownIcon
                          filterBarPlaceholder="Service/Platform *"
                          data-msg-containerid="srvprdError"
                          mode="CheckBox"
                          allowFiltering={false}
                        >
                          <Inject services={[CheckBoxSelection]} />
                        </MultiSelectComponent>
                        <div id="srvprdError" className="error-div" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

        {
          // select job
          btbId > 0 &&
          (workTypeId === 5) &&
          dencrypt(localStorage.getItem("pnlId")) !== Cognition && (
            <div className="col-md-4">
              <div className="custom-margin custom-padding-5 material2">
                <div className="col-md-12">
                  {opsSubBtbList.length > 0 ? (
                    <div className="e-input-group e-float-icon-left">
                      <FontAwesomeIcon
                        icon={faCubes}
                        className="mt-sm fa-lg"
                      />
                      <div className="e-input-in-wrap">
                        <div style={{ width: "100%", display: "grid" }}>
                          <ComboBoxComponent
                            id="selectSubBTBId"
                            ref={(combobox) => {
                              this.listjobref = combobox;
                            }}
                            name="selectSubBTBId"
                            dataSource={opsSubBtbList}
                            fields={{ text: "Name", value: "Id" }}
                            change={this.handleChange}
                            placeholder={this.renderNestedTernary('jobPlaceHolder', pnlId)}
                            value={subBTBId}
                            popupHeight="220px"
                            cssClass="e-outline"
                            floatLabelType="Auto"
                            data-msg-containerid="opsJobError"
                            noRecordsTemplate={this.noRecordsTemplate.bind(this)}
                            focus={this.handleJobId}
                          />
                          <div id="opsJobError" className="error-div" />
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="e-input-group e-float-icon-left error-div">
                      {noJobsAvailable}
                    </div>
                  )}
                </div>
              </div>
            </div>
          )
        }
      </div>
    );
  }
}

OpsTaskSearch.propTypes = {
  meetingTypeNm: PropTypes.string,
};

OpsTaskSearch.defaultProps = {
  meetingTypeNm: "",
};

const mapStateToProps = ({ auth, meetingbooking, meetingmaster, meetingcategory }) => {
  const { authUser, otherUser } = auth;
  const { categoryName } = meetingcategory;
  const { subCategary, meetingServiceProduct } = meetingmaster;
  const {
    meetingTitle,
    meetingId,
    btbId,
    businessImpact,
    subBTBId,
    subCatId,
    clientId,
    contactsId,
    clientNm,
    clientmode,
    meetingTypeName,
    workTypeId,
    meetingSubTypeId,
    serviceProductsId,
  } = meetingbooking;
  return {
    authUser,
    meetingTitle,
    meetingId,
    btbId,
    businessImpact,
    subBTBId,
    subCatId,
    clientId,
    contactsId,
    clientNm,
    clientmode,
    meetingTypeName,
    subCategary,
    workTypeId,
    meetingSubTypeId,
    meetingServiceProduct,
    serviceProductsId,
    categoryName,
    otherUser
  };
};

export default connect(mapStateToProps, {
  updateMeetingBookingState,
})(withStyles(useStyles)(OpsTaskSearch));
