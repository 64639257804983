/**
 * Author: Prashant Waphare.
 * Description: Absconding - To drag/drop or file selection screen
 * Added Date : 02-06-2020
 */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { getNotify } from "../../../app/other/commonfunction";
import {
  WARNING
} from "../../../assets/constants/constants";
import "../../../../src/components/separation/resignationcomponent/resignationcomponent.css"
import { urlConfig, 
  // defFileType 
} from "../../../config/config";

class AbscondingUploadFile extends Component {
  constructor(props) {
    super(props);
    const { allowmultiple } = this.props;
    this.state = {
      hightlight: false,
      allowmultiple,
      filePath: '',
      UploadedImage: "images/baseline-cloud_upload-24px.svg",
    };
    this.fileInputRef = React.createRef();

    this.openFileDialog = this.openFileDialog.bind(this);
    this.onFilesAdded = this.onFilesAdded.bind(this);
    this.onDragOver = this.onDragOver.bind(this);
    this.onDragLeave = this.onDragLeave.bind(this);
    this.onDrop = this.onDrop.bind(this);
  }

  onDragOver(event) {
    event.preventDefault();
    const { disabled } = this.props;
    if (disabled) return;
    this.setState({ hightlight: true });
  }

  onDragLeave() {
    this.setState({ hightlight: false });
  }

  onDrop(event) {
    event.preventDefault();
    const { disabled } = this.props;
    if (disabled) return;
    const { files } = event.dataTransfer;
    if (this.props.onFilesAdded) {
      const array = this.fileListToArray(files);
      this.props.onFilesAdded(array);
    }
    this.setState({ hightlight: false });
  }

  /**
   * @author Prashant Waphare
   * @description fired this event once the file is added
   */
  onFilesAdded(evt) {
    const { disabled, type } = this.props;
    if (disabled) return;
    if (evt.target.files[0] !== undefined) {
      const currentDate = Date.parse(new Date()) / 1000;
      const fileObj = evt.target.files[0];
      const fileNm = currentDate.toString() + '_' + fileObj.name;
      this.setState({ UploadedImage: URL.createObjectURL(evt.target.files[0]), filePath: evt.target.files[0].name });
      this.props.documentDetail(currentDate, fileNm);

      const req = new XMLHttpRequest();
      const formData = new FormData();
      formData.append("usertype", "D");
      formData.append("type", type);
      formData.append("file", fileObj, fileNm);
      req.open("POST", `${urlConfig.tmlcApiUrl}document/upl`);
      req.setRequestHeader("Authorization", localStorage.getItem("jwtToken"));
      req.send(formData);
      if (req.statusText === "Internal Server Error") {
        getNotify(WARNING, "Please select minimum file size(less than 500 KB)");
        return;
      }
    }
  }

  /**
   * @author Suresh R. Sargam
   * @description Add files to list of array
   */
  fileListToArray(list) {
    const { folderPath } = this.props;
    const array = [];
    const items = list;
    for (let i = 0; i < items.length; i += 1) {
      items.item(i).folderPath = folderPath;
      array.push(items.item(i));
    }
    return array;
  }

  /**
   * @author Suresh R. Sargam
   * @description to open file dialog
   */
  openFileDialog() {
    const { disabled } = this.props;
    if (disabled) return;
    this.fileInputRef.current.click();
  }

  render() {
    const { hightlight, allowmultiple, filePath, UploadedImage } = this.state;
    const { disabled } = this.props;
    return (
      <div
        className={`absconding-upload-document ${hightlight ? "Highlight" : ""}`}
        onDragOver={this.onDragOver}
        onDragLeave={this.onDragLeave}
        onDrop={this.onDrop}
        onClick={this.openFileDialog}
        onKeyDown={this.openFileDialog}
        style={{ cursor: disabled ? "default" : "pointer" }}
        role="button"
        tabIndex={0}
      >
        {allowmultiple ? (
          <input
            ref={this.fileInputRef}
            className="FileInput"
            style={{ display: "none" }}
            type="file"
            multiple
            accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"
            onChange={this.onFilesAdded}
          />
        )
          : (
            <input
              ref={this.fileInputRef}
              className="FileInput"
              style={{ display: "none" }}
              type="file"
              accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"
              onChange={this.onFilesAdded}
            />
          )
        }
        {
          UploadedImage === "images/baseline-cloud_upload-24px.svg" &&
          <img alt="upload" className="Icon" src={UploadedImage} />
        }
        {filePath !== "" && filePath !== null ?
          <>
            <span>{filePath}</span>
          </>
          :
          <span>Upload Files</span>
        }
      </div>
    );
  }
}

AbscondingUploadFile.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onFilesAdded: PropTypes.func,
  allowmultiple: PropTypes.bool,
  folderPath: PropTypes.string.isRequired,
};
AbscondingUploadFile.defaultProps = {
  allowmultiple: false,
  disabled: false,
  folderPath: ""
};

export default AbscondingUploadFile;
