import React from "react"; 

const Error404 = () => (
  <div
    style={{
      textAlign: "center",
      position: "absolute",
      top: "50%",
      bottom: "50%",
      left: "0",
      right: "0",
    }}
  >
    <div
      style={{
        font: "icon",
        display: "inline-table",
        width: "50%",
        background: "#ecebe9",
        color: "#07090d",
        fontWeight: "bold",
      }}
    >
      <h3 style={{ marginTop: "20px", marginBottom: "20px" }}>
        Kindly login through the <b>Software Links</b>.{" "}
      </h3>
    </div>
  </div>
);

export default Error404;
