import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { faLightbulbOn, faScrewdriverWrench } from '@fortawesome/pro-solid-svg-icons'
import {
    ColumnsDirective,
    ColumnDirective,
    Filter,
    Inject,
    Sort,
    Page,
} from "@syncfusion/ej2-react-grids";
import {
    getGridColumn,
    TICKET_UPDATE,
    HELPDESK_TICKET_NO,
    HELPDESK_PRIORITY,
    HELPDESK_PRIORITY_FIELD,
    HELPDESK_STATUS,
    HELPDESK_STATUS_FIELD,
    HELPDESK_SUB_CATEGORY,
} from "../../../app/other/gridcolumns";
import ProgressBar from "../../progressbar/progressbar";
import { getTicketList } from "../../../services/helpdesk/helpdeskServices";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import { format, differenceInDays } from "date-fns";
import { DateRangePickerComponent } from "@syncfusion/ej2-react-calendars";
import DataGrid from "../../../utilities/datagrid";
import ButtonComponent from "../../../utilities/buttoncomponent";
import {
    helpdeskPriorityList,
    helpdeskTicketStatus,
    ticketResolvedBufferTime,
    ticketResolvedBufferTimeErrorMsg,

} from "../../../assets/constants/constants";
import SearchBox from "../../../utilities/searchboxcomponent";
import {
    NEWSERVICEREQUEST,
    NEWIDEA,
} from "../../../redux/constant/meetingcategoryconstant";
import {
    dencrypt,
    encrypt,
    getTimezoneFormattedTime,
} from "../../../app/other/commonfunction";
import DisabledEffect from "../../../utilities/disabledeffect";
const style = `
.e-daterangepicker.e-popup {
    border: 1px solid #DBDBDD;
    margin-top: 0;
    margin-left: 0;
    border-radius: 0.25rem
};

.e-lib.e-touch .ticket-custom-date-range-picker .e-footer,
.e-lib.e-touch .ticket-custom-date-range-picker .e-footer .e-cancel,
.e-lib.e-touch .ticket-custom-date-range-picker .e-footer .e-apply {
    font-family: 'Circular Std Book' !important;
}

@media screen and (max-width: 1366px) and (max-height: 768px),
screen and (max-width: 1440px) and (max-height: 900px) {
    .e-daterangepicker.e-popup,
    .e-bigger.e-small .e-daterangepicker.e-popup {
        margin: 0 0 0 0 !important;
    }
}`

function HelpdeskGrid(props) { // NOSONAR
    let { changePopUpHandler } = props;
    const [ticketList, setTicketList] = useState([]);
    const [loading, setLoading] = useState(true);
    const [date, setDate] = useState(null);
    const [ticketNumber, setTicketNumber] = useState("");
    const [finalTicket, setFinalTicket] = useState([]);
    /**
     * Grid Properties
     */

    const isOneTwentyFiveInThirteenHundred = window.innerHeight < 570;
    const isThirteenHundredBookmarkOn = window.innerHeight < 620;
    const isThirteenHundred = window.innerHeight < 650;
    const isSixteenEightyTEnFiftyOneTwentyFive = window.innerHeight < 700 && window.innerHeight > 650;
    const isNineteenTwentyTenEightyOneTwentyFive = window.innerHeight < 715 && window.innerHeight > 700;
    const isOneTwentyFiveInSixteenEighty = window.innerHeight < 723;
    const isOneTwentyFiveScale = window.innerHeight < 755;
    const isFourteenHundred = window.innerHeight < 782 && window.innerWidth < 1441;
    const isSixteenHundred = window.innerHeight < 782;
    const isTenEightyHundred = window.innerHeight > 870 && window.innerHeight < 900;
    const isSixteenEighty = window.innerHeight < 932;
    const isTenEighty = window.innerHeight < 961;

    const pageSize = isOneTwentyFiveInThirteenHundred ? 4
        : isThirteenHundredBookmarkOn ? 5 // NOSONAR
            : isThirteenHundred ? 6 // NOSONAR
                : isSixteenEightyTEnFiftyOneTwentyFive ? 6 // NOSONAR
                    : isNineteenTwentyTenEightyOneTwentyFive ? 7 // NOSONAR
                        : isOneTwentyFiveInSixteenEighty ? 7 // NOSONAR
                            : isOneTwentyFiveScale ? 8 // NOSONAR
                                : isFourteenHundred ? 8 // NOSONAR
                                    : isSixteenHundred ? 8 // NOSONAR
                                        : isTenEightyHundred ? 11 // NOSONAR
                                            : isSixteenEighty ? 11 // NOSONAR
                                                : isTenEighty ? 12 // NOSONAR
                                                    : (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight) > 767 ? 14 // NOSONAR
                                                        : 7
    const pageSettings = {
        pageCount: 5,
        pageSize: pageSize
    }

    const gridLines = "Horizontal";
    const allowTextWrap = true;
    const allowSelection = false;
    const filterSettings = { type: 'CheckBox' };
    const [gridHeight] = useState((window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight) - 320);
    const [state, setState] = useState({
        gridWidth: (window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) - 84,
    });

    const handleDateRangeChange = (e) => {
        e.value ? setDate({ dateFrom: format(e.value?.[0], 'yyyy-MM-dd'), dateTo: format(e.value?.[1], 'yyyy-MM-dd') }) : setDate(null)
    }

    const actionTemplate = (e) => {
        let isDisabled = false;
        const resolvedDate = e.ResolvedDate.split(";")[0];
        if (
            e.Status === helpdeskTicketStatus.Closed &&
            differenceInDays(new Date(), new Date(resolvedDate)) >
            ticketResolvedBufferTime
        )
            isDisabled = true;
        return (
            <TooltipComponent
                content={isDisabled ? ticketResolvedBufferTimeErrorMsg : "Add Update"}
                position='BottomCenter'
                cssClass='helpdesk-grid-popup circular-regular'
                style={{ fontSize: "14px" }}
            >
                <span
                    className={
                        "circular-regular helpdesk-action-template " + (isDisabled ? "action-disabled" : "")
                    }
                    onClick={() => {
                        if (isDisabled) return;
                        changePopUpHandler(e.IssueID, "open", true);
                    }}
                >
                    <FontAwesomeIcon
                        icon={faPlusCircle}
                        className={isDisabled ? "circular-regular action-btn-disabled" : "circular-regular"}
                    />
                </span>
            </TooltipComponent>
        );
    };

    const ticketNumberTemplate = (e) => {
        return (
            <TooltipComponent
                content={"View Service Request"}
                position='TopCenter'
                cssClass='helpdesk-grid-popup circular-regular'
                style={{ fontSize: "14px" }}
            >
                <span
                    style={{ cursor: "pointer" }}
                    className='helpdesk-ticket-number circular-regular'
                    onClick={() => changePopUpHandler(e.IssueID, "open")}
                >
                    {e.IssueID}
                </span>
            </TooltipComponent>
        );
    };

    const priorityTemplate = (e) => {
        const priority = e[HELPDESK_PRIORITY_FIELD]
        return (
            <span className='circular-regular' style={{ cursor: 'pointer' }}>
                {priority}
            </span>
        );
    };

    const statusTemplate = (e) => {
        let status = e[HELPDESK_STATUS_FIELD];
        return (
            <span className={'circular-regular helpdesk-ticket-status-' + helpdeskTicketStatus[status]}>
                {status}
            </span>
        );
    };

    const subCategoryTemplate = (data) => {
        let subCatValue = "";
        if (data[process.env.REACT_APP_HD_CUSTOM_SUB_FIELDID]?.includes(' > ')) {
            subCatValue =  data[process.env.REACT_APP_HD_CUSTOM_SUB_FIELDID].split(' > ')[2].trim();
        } else if(data["2"]){
            subCatValue =  data["2"];
        }
        if (subCatValue && subCatValue?.length > 37 && +data['column']['width'] <= 310) {
            return (
                <div
                    className="col-12 helpdesk-common-cell circular-regular"
                    style={{ cursor: 'pointer' }}
                >
                    <div>
                        <TooltipComponent content={subCatValue} position="BottomCenter" cssClass="helpdesk-grid-popup circular-regular" style={{ fontSize: '14px' }}>
                            <span className="mr-sm circular-regular">{subCatValue.slice(0, 37) + '...'}</span>
                        </TooltipComponent>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="col-12 helpdesk-common-cell circular-regular">
                    <div><span className="mr-sm circular-regular">{subCatValue}</span></div>
                </div>
            )
        }
    };

    const commonCellTemplate = (data) => {
        if (data[data.column.field] && data[data.column.field]?.length > 37 && +data['column']['width'] <= 310) {
            return (
                <div
                    className="col-12 helpdesk-common-cell circular-regular"
                    style={{ cursor: 'pointer' }}
                >
                    <div>
                        <TooltipComponent content={data[data.column.field]} position="BottomCenter" cssClass="helpdesk-grid-popup circular-regular" style={{ fontSize: '14px' }}>
                            <span className="mr-sm circular-regular">{data[data.column.field].slice(0, 37) + '...'}</span>
                        </TooltipComponent>
                    </div>
                </div>
            );
        } else {
            return (
                <div
                    className="col-12 helpdesk-common-cell circular-regular"
                >
                    <div>
                        <span className="mr-sm circular-regular">{data[data.column.field]}</span>
                    </div>
                </div>
            )
        }
    };

    useEffect(() => {
        !(props.openPopup === "add" || props.openPopup === "edit") &&
            initialLoad();
        const timer = setInterval(() => {
            initialLoad();
        }, 1000 * 60);
        window.onresize = () => {
            let gridWidth =
                (window.innerWidth ||
                    document.documentElement.clientWidth ||
                    document.body.clientWidth) - 84;
            setState((preState) => ({ ...preState, gridWidth }));
        };
        return () => {
            clearInterval(timer);
        };
    }, [date, props.openPopup]);

    const initialLoad = async () => {
        setLoading(true)
        /**
         * Author: Md Jahir Uddin
         * Date: 04-05-2023
         * Description: Below code fetch data from the server using count and offset recursively until all records have been fetched.
         */
        let lastResRowCount = 0;
        let count = 300;
        let offset = 0;
        let getTicketListRes = [];
        do {
            const res = await getTicketList(date, count, offset, dencrypt(localStorage.getItem('helpdeskUserId')) ?? 0);
            getTicketListRes.push(...res);
            lastResRowCount = res.length;
            offset += count
        } while (lastResRowCount >= count);

        /**
         * Author: Md Jahir Uddin
         * Date: 04-05-2023
         * Description: Below code if formatting the response data into correct format to show perfectly in grid and filter dropdown.
         */
        const tempTicketRes = getTicketListRes.map((ticket) => {
            let IssueDate = getTimezoneFormattedTime(ticket["IssueDate"]);
            let status = ticket["Status"]
                ?.split(" ")
                .map((element) => element[0].toUpperCase() + element.slice(1))
                .join(" ");
            let fromDate = new Date();
            let toDate = new Date(ticket["IssueDate"]);
            if (status === "Closed") {
                toDate = new Date(ticket["IssueDate"]);
                fromDate = new Date(ticket["ResolvedDate"]);
            }
            let difference = differenceInDays(fromDate, toDate);
            let age;
            if (difference < 2) age = "Less than 1 day";
            else if (difference < 3) age = "1-2 days";
            else if (difference < 5) age = "3-4 days";
            else if (difference < 8) age = "5-7 days";
            else if (difference < 15) age = "1-2 weeks";
            else age = "More than 2 weeks";
            let functionName = ticket["Category"].split("-")[0];
            let category = ticket["Category"].split("-")[1];
            let priority = ticket["Priority"];
            let resolvedDate = getTimezoneFormattedTime(ticket["ResolvedDate"]);
            let resolvedBy = `${ticket["TechFirstName"] ?? ""} ${ticket["TechLastName"] ?? ""
                }`;
            return {
                ...ticket,
                IssueDate: IssueDate,
                Age: age,
                Function: functionName,
                Category: category,
                Status: status,
                Priority: helpdeskPriorityList[priority],
                ResolvedDate: resolvedDate,
                AssignedTo: resolvedBy,
            };
        });
        setTicketList(tempTicketRes);
        setFinalTicket(tempTicketRes);
        setLoading(false);
    };

    const presets = [
        { label: 'Today', start: new Date(), end: new Date() },
        { label: 'Yesterday', start: new Date(new Date().setDate(new Date().getDate() - 1)), end: new Date(new Date().setDate(new Date().getDate() - 1)) },
        { label: 'Past 7 days', start: new Date(new Date().setDate(new Date().getDate() - 7)), end: new Date(new Date().setDate(new Date().getDate())) },
        { label: 'Past 15 days', start: new Date(new Date().setDate(new Date().getDate() - 15)), end: new Date(new Date().setDate(new Date().getDate())) },
        { label: 'Past 30 days', start: new Date(new Date().setDate(new Date().getDate() - 30)), end: new Date(new Date().setDate(new Date().getDate())) },
        { label: 'Past 90 days', start: new Date(new Date().setDate(new Date().getDate() - 90)), end: new Date(new Date().setDate(new Date().getDate())) },
        { label: 'Past 180 days', start: new Date(new Date().setDate(new Date().getDate() - 180)), end: new Date(new Date().setDate(new Date().getDate())) },
    ];
    const columnDirectives = () => {
        return (
            <ColumnsDirective>
                {getGridColumn("helpDeskMyTicket").map((item, Index) => {
                    switch (item?.colName) {
                        case TICKET_UPDATE:
                            return (
                                <ColumnDirective
                                    key={item.field}
                                    template={actionTemplate}
                                    textAlign={item.align}
                                    // field={item.field}
                                    headerText={item.colName}
                                    width={item.width}
                                    // allowFiltering={false}
                                    freeze
                                />
                            );
                        case HELPDESK_TICKET_NO:
                            return (
                                <ColumnDirective
                                    key={item.field}
                                    template={ticketNumberTemplate}
                                    textAlign={item.align}
                                    field={item.field}
                                    headerText={item.colName}
                                    width={item.width}
                                    allowFiltering={true}
                                    freeze
                                />
                            );
                        case HELPDESK_PRIORITY:
                            return (
                                <ColumnDirective
                                    key={item.field}
                                    template={priorityTemplate}
                                    textAlign={item.align}
                                    field={item.field}
                                    headerText={item.colName}
                                    width={item.width}
                                    allowFiltering={true}
                                    freeze
                                />
                            );
                        case HELPDESK_STATUS:
                            return (
                                <ColumnDirective
                                    key={item.field}
                                    template={statusTemplate}
                                    textAlign={item.align}
                                    field={HELPDESK_STATUS_FIELD}
                                    headerText={item.colName}
                                    allowFiltering={true}
                                    width={item.width}
                                    freeze
                                />
                            );
                        case HELPDESK_SUB_CATEGORY:
                            return (
                                <ColumnDirective
                                key={item.field}
                                template={subCategoryTemplate}
                                textAlign={item.align}
                                field={item.field}
                                headerText={item.colName}
                                width={item.width}
                                allowFiltering={true}
                                />
                            );
                        default:  
                            return (
                                <ColumnDirective
                                    key={item.field}
                                    textAlign={item.align}
                                    field={item.field}
                                    headerText={item.colName}
                                    width={item.width}
                                    template={commonCellTemplate}
                                    allowFiltering={true}
                                />
                            );
                    }
                })}
            </ColumnsDirective>
        )
    }
    const gridEjectParameters = () => {
        return (
            <Inject services={[Page, Sort, Filter]} />
        )
    }
    const navToNewServiceRequest = (event) => {
        const path = `/helpdesk?id=${encrypt(NEWSERVICEREQUEST)}&appId=MjIxNTE5MC43Mg==`;
        return window.open(path);
    }
    const navToNewIdeas = (event) => {
        const path = `/helpdesk?id=${encrypt(NEWIDEA)}&appId=MjIxNTE5MC43Mg==`;
        return window.open(path);
    }
    let finalWidthConfirm;
    let widthColumnConfirm = 0;
    getGridColumn("helpDeskMyTicket")?.forEach((data) => {
        widthColumnConfirm = widthColumnConfirm + 20 + Number(data.width);
    });

    if (widthColumnConfirm <= state.gridWidth) {
        finalWidthConfirm = widthColumnConfirm;
    }
    else {
        finalWidthConfirm = state.gridWidth
    }

    const setHeaderCss = () => {
        // setTimeout(() => {
        const ele = document.getElementsByClassName("e-headercelldiv");
        for (let i in ele) {
            if (!isNaN(parseInt(i, 10))) {
                let index = parseInt(i, 10);
                if (ele[index].innerText === "Action") {
                    ele[index].classList.add("grid-center-header");
                } else {
                    ele[index].classList.add("grid-left-header");
                }
            }
        }
        // }, 500);
    }

    const dataSourceChanged = (pComponentId) => {
        try {
            setTimeout(() => {
                if (document.getElementById(pComponentId) !== null) {
                    document.getElementById(pComponentId).ej2_instances[0].refresh();
                    setHeaderCss();
                }
            });
        } catch (error) {
            console.log(error);
        }
    };

    const handleTicketSearch = (value) => {
        setLoading(true)
        try {
            setTicketNumber(value)
            if (value.length < 1) {
                setFinalTicket(ticketList)
            } else {
                const tickets = ticketList.filter(ticket => ticket.IssueID === Number(value));
                setFinalTicket(tickets)
            }
            dataSourceChanged('helpDeskGrid')
        } catch (e) {
            console.error(e)
        }
        // setTimeout(() => {
        setLoading(false)
        // }, 100);
    }

    return (<>
        {(props.openPopup === 'add' || props.openPopup === 'edit') &&
            <DisabledEffect
                height={document.getElementById('helpdesk-container')?.clientHeight}
                width={document.getElementById('helpdesk-container')?.clientWidth}
            />}
        <style>{style}</style>
        <div className='circular-regular'>
            <div className='ticket-search circular-regular pl-md pr-md pt-sm pb-sm'>
                <div className="ticket-search-sec circular-regular">
                    <SearchBox
                        onChange={(e) => { handleTicketSearch(e.target.value) }}
                        placeholder={"Search with a SR. No."}
                        value={ticketNumber}
                        cssClass='circular-regular'
                    />
                    <span id='ticket-date-range-picker'>
                        <DateRangePickerComponent
                            id="ticket-date"
                            placeholder="Select a Range"
                            format='dd-MMM-yy'
                            floatLabelType='Never'
                            onChange={handleDateRangeChange}
                            startDate={date?.fromDate}
                            endDate={date?.toDate}
                            presets={presets}
                            cssClass="e-outline circular-regular ticket-custom-date-range-picker"
                            style={{ height: '3rem', fontSize: '14px' }}
                        />
                    </span>
                </div>
                <div className="ticket-add-sec">
                    <ButtonComponent id="new-service-request-btn"
                        cssClass={"circular-regular"}
                        click={(e) => navToNewServiceRequest(e)}
                        content={
                            <>
                                <FontAwesomeIcon
                                    icon={faScrewdriverWrench}
                                    className="tool-icon helpdesk-btn-icon circular-regular"
                                />
                                <span className='circular-regular'>New Service Request</span>
                            </>
                        } variant />
                    {/* this Code need in furure */}
                    {/* <ButtonComponent id="new-service-request-btn" 
                        click={(e) => navToNewIdeas(e)}
                        content={
                        <><FontAwesomeIcon
                        icon={faLightbulbOn}
                            className="bulb-icon helpdesk-btn-icon"
                        /><span>New Idea</span></>
                    } /> */}
                </div>
            </div>
            {/** Grid */}
            {finalTicket.length > 0 && <DataGrid
                id="helpDesk"
                cssClass={"circular-regular"}
                mainTitle={"My Service Requests"}
                columnDirectives={columnDirectives}
                gridEjectParameters={gridEjectParameters}
                allowPaging={true}
                gridLines={gridLines}
                dataSource={finalTicket}
                pageSettings={pageSettings}
                allowTextWrap={allowTextWrap}
                allowSelection={allowSelection}
                filterSettings={filterSettings}
                height={gridHeight}
                isPaginationEnable={true}
                width={finalWidthConfirm}
                windowWidth={state.gridWidth}
                bordered={false}
            />}
        </div>
        {
            loading && (
                <div
                    className='loader-new'
                >
                    <ProgressBar />
                </div>
            )
        }
    </>
    )
}



export default HelpdeskGrid