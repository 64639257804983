import React, { useState, useEffect } from "react";
import { ComboBoxComponent } from "@syncfusion/ej2-react-dropdowns";
import { TextBoxComponent, NumericTextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import { connect } from "react-redux";
import { FormValidator } from "@syncfusion/ej2-inputs";
import LnAServices from "../../services/lna/lnaservice";
import { saveWeekdayCluster, updateLnAData } from "../../redux/actions/lna/lnaaction";
import UploadDocuments from "../okrratingcomponents/uploaddocuments";
import { createImageFilepathForLPView } from "../okrratingcomponents/ratingcommonfun";
import {
  pleaseEnterSymbol,
  pleaseEnterWeekdayStart,
  pleaseEnterWeekdayClusterName,
  pleaseEnterWeekdayEnd,
  pleaseEnterWeekdayCount,
  pleaseEnterWeekendCount,
  pleaseEnterWeekendType,
  regexOnlyLettersAndSpaces,
  selecteffectivestartdate
} from "../../assets/constants/constants";
import ProgressBar from "../progressbar/progressbar";
import { getMySqlDateFormat } from "../../app/other/commonfunction";


const SAMPLECSS = `
input.e-input, textarea.e-input, .e-input-group, .e-input-group.e-control-wrapper {
  font-size: 12px;
}
`

function WeekdayCluster(props) {
  //NOSONAR
  const {
    authUser,
    openPopup,
    editClusterId,
  } = props;
  const [state, setState] = useState({
    title: null,
    weekdayClusterUid: null,
    weekdayClusterName: null,
    weekdayClusterSymbolId: null,
    weekdayClusterDescription: null,
    weekdayStart: null,
    weekdayEnd: null,
    weekdayCount: null,
    weekendCount: null,
    weekendType: "",
    weekdayPolicyDoc: [],
    isActive: "1",
    effectiveStartDate: null,
    effectiveEndDate: null,
    fileName: "",
    oldFileStatus: "",
  });
  const [changedData, setChangedData] = useState(false);
  const [initialLoad, setInitialLoad] = useState(false)

  let validOptions = {
    rules: {
      weekDayNameErr: {
        required: [true, pleaseEnterWeekdayClusterName],
        regex: [regexOnlyLettersAndSpaces, pleaseEnterWeekdayClusterName],
        minLength: [1, pleaseEnterWeekdayClusterName],
        maxLength: [50, "Should be maximum of 50 characters only."]
      },
      symbolErr: {
        required: [true, pleaseEnterSymbol],
      },

      weekDayStartErr: {
        required: [true, pleaseEnterWeekdayStart],
      },
      weekDayEndErr: {
        required: [true, pleaseEnterWeekdayEnd],
      },
      weekDayCountErr: {
        required: [true, pleaseEnterWeekdayCount],
      },
      weekEndCountErr: {
        required: [true, pleaseEnterWeekendCount],
      },
      weekEndTypeErr: {
        required: [true, pleaseEnterWeekendType],
      },
      effectiveStartDateErr: {
        required: [true, selecteffectivestartdate],
      },
    },
  };



  useEffect(() => {
    props.updateLnAData({ popUpSource: 'Weekday Master' })
  }, []);
  useEffect(() => {
    setTimeout(() => {
      props.setSubmitClick(onSaveData);
    }, 100);
  }, [state]);
  useEffect(() => {
    getData()
  }, [openPopup, editClusterId])
  useEffect(() => {

    if (props.lnaMasterFlag === "updated")

      props.changePopUpHandler("close");

  }, [props.lnaMasterFlag]);
  const getData = () => {
    if (openPopup === "add") {
      resetData()
    } else {
      editClusterId !== null &&
        (async () => {
          setInitialLoad(true)
          const weekdayClusterData = await LnAServices.getWeekdayClusterData(
            authUser,
            editClusterId
          );
          const {
            weekdayClusterUid,
            weekdayClusterName,
            weekdayClusterSymbolId,
            weekdayClusterDescription,
            weekdayStart,
            weekdayEnd,
            weekdayCount,
            weekendCount,
            tType,
            // weekdayPolicyDoc,
            isActive,
            effectiveStartDate,
            effectiveEndDate,
            fileName,
          } = weekdayClusterData.Result[0];
          let data = {
            weekdayClusterUid,
            weekdayClusterName,
            weekdayClusterSymbolId,
            weekdayClusterDescription,
            weekdayStart,
            weekdayEnd,
            weekdayCount,
            weekendCount,
            weekendType: tType,
            oldFileStatus: fileName,
            effectiveStartDate,
            effectiveEndDate,
          };
          setState((preState) => ({ ...preState, ...data }));
          setTimeout(() => {
            const addFormObject = new FormValidator("#weekdayClusterForm", validOptions);
            addFormObject.validate();
          }, 100)
          setInitialLoad(false)
        })();
    }
  };
  const resetData = () => {
    let data = {
      weekdayClusterUid: null,
      weekdayClusterName: null,
      weekdayClusterSymbolId: null,
      weekdayClusterDescription: null,
      weekdayStart: null,
      weekdayEnd: null,
      weekdayCount: null,
      weekendCount: null,
      weekendType: "",
      weekdayPolicyDoc: [],
      isActive: "1",
      effectiveStartDate: null,
      effectiveEndDate: null,
      fileName: "",
      oldFileStatus: "",
    };
    setState((preState) => ({ ...preState, ...data }));
  }
  const documentDetails = (currentTime, file) => {
    setState((prevState) => ({ ...prevState, fileName: file }));
  };


  const inputChangeHandler = (e, type) => {
    let options = {};
    switch (type) {
      case "weekDayname":
        setState((prevState) => ({
          ...prevState,
          weekdayClusterName: e.value.trim(),
        }));
        options = validOptions.rules['weekDayNameErr'];
        break;
      case "weekdayClusterSymbolId":
        setState((prevState) => ({
          ...prevState,
          weekdayClusterSymbolId: e.value
        }));
        options = validOptions.rules['symbolErr'];
        break;
      case "weekdayClusterDescription":
        setState((prevState) => ({
          ...prevState,
          weekdayClusterDescription: e.value && e.value.trim(),
        }));
        break;
      case "weekdayStart":
        setState((prevState) => ({
          ...prevState,
          weekdayStart: e.itemData.Id.toString(),
        }));
        options = validOptions.rules['weekDayStartErr'];
        break;
      case "weekdayEnd":
        setState((prevState) => ({
          ...prevState,
          weekdayEnd: e.itemData.Id.toString(),
        }));
        options = validOptions.rules['weekDayEndErr'];
        if (document.getElementById("weekDayEndErr")) {
          document.getElementById("weekDayEndErr").innerHTML = "";
        }
        break;
      case "weekdayCount":
        setState((prevState) => ({ ...prevState, weekdayCount: e.value }));

        if (document.getElementById("weekDayCountErr")) {
          document.getElementById("weekDayCountErr").innerHTML = "";
        }
        options = validOptions.rules['weekDayCountErr'];

        break;
      case "weekendCount":
        setState((prevState) => ({ ...prevState, weekendCount: e.value }));

        if (document.getElementById("weekEndCountErr")) {
          document.getElementById("weekEndCountErr").innerHTML = "";
        }
        options = validOptions.rules['weekEndCountErr'];
        break;
      case "weekendType":
        setState((prevState) => ({
          ...prevState,
          weekendType: e.itemData.Name.toString(),
        }));
        options = validOptions.rules['weekEndTypeErr'];
        break;
      case "isActive":
        setState((prevState) => ({
          ...prevState,
          isActive: e.checked === true ? "1" : "0",
        }));
        break;
      case "effectiveStartDate":
        setState((prevState) => ({
          ...prevState,
          effectiveStartDate: e.value,
        }));
        if (document.getElementById("effectiveStartDateErr")) {
          document.getElementById("effectiveStartDateErr").innerHTML = "";
        }

        break;
      case "effectiveEndDate":
        setState((prevState) => ({ ...prevState, effectiveEndDate: e.value }));
        break;
      default:
    }

    setTimeout(() => {
      const addFormObject = new FormValidator("#weekdayClusterForm", options);
      addFormObject.validate();
    }, 100);
  };

  const onSaveData = () => {
    // NOSONAR
    const {
      weekdayClusterUid,
      weekdayClusterName,
      weekdayClusterSymbolId,
      weekdayClusterDescription,
      weekdayStart,
      weekdayEnd,
      weekdayCount,
      weekendCount,
      weekendType,
      isActive,
      effectiveStartDate,
      effectiveEndDate,
      fileName,
    } = state;


    let validateDates = true;
    if (
      state.effectiveStartDate === null &&
      state.effectiveEndDate !== null
    ) {
      document.getElementById("effectiveStartDateErr").innerHTML =
        "Please select effective start date.";
      validateDates = false;
    } else if (
      state.effectiveStartDate !== null &&
      state.effectiveEndDate !== null
    ) {
      if (
        new Date(state.effectiveStartDate) > new Date(state.effectiveEndDate)
      ) {
        document.getElementById("effectiveStartDateErr").innerHTML =
          "Effective start date must be less than effective end date.";
        validateDates = false;
      }
    }

    const addFormObject = new FormValidator("#weekdayClusterForm", validOptions);
    if (addFormObject.validate() && validateDates) {
      setInitialLoad(true)
      let weekdayClusterSaveData = {
        userId: authUser,
        fyID: props.currentFinancialYearId,
        weekdayClusterUid,
        type: weekdayClusterUid > 0 ? "EDIT" : "ADD",
        weekdayClusterName,
        weekdayClusterSymbolId,
        weekdayClusterDescription,
        weekdayStart,
        weekdayEnd,
        weekdayCount,
        weekendCount,
        weekendType: weekendType === "Paid" ? "1" : "0",
        isActive,
        effectiveStartDate:
          effectiveStartDate !== null
            ? getMySqlDateFormat(effectiveStartDate)
            : null,
        effectiveEndDate:
          effectiveEndDate !== null
            ? getMySqlDateFormat(effectiveEndDate)
            : null,
        fileName,
      };

      props.saveWeekdayCluster(weekdayClusterSaveData);
      setTimeout(() => {
        setInitialLoad(false)
      }, 10)
    }

  };

  const selectedFiles = (fileNms) => {
    try {
      setState((preState) => ({ ...preState, weekdayPolicyDoc: fileNms }));
      setTimeout(() => {
        setChangedData(!changedData);
      });
    } catch (error) { }
  };
  const removeFile = () => {
    setState(preState => ({ ...preState, fileName: "" }))
  }


  const days = [
    { Name: "Sunday", Id: "7" },
    { Name: "Saturday", Id: "6" },
    { Name: "Friday", Id: "5" },
    { Name: "Thursday", Id: "4" },
    { Name: "Wednesday", Id: "3" },
    { Name: "Tuesday", Id: "2" },
    { Name: "Monday", Id: "1" },
  ];

  const descriptionTextBox = `.e-outline textarea, textarea.e-outline, .e-outline.e-float-input textarea, .e-outline.e-float-input.e-control-wrapper textarea, .e-outline.e-float-input.e-input-group textarea {
    box-sizing: border-box;
    min-height: 100px;
  }`
  const getOneDayForward = (dt) => {
    let date = dt
    date.setDate(date.getDate() + 1)
    return date
  }
  return (
    <>
      <div
        id="weekdayClusterForm"
        style={{
          paddingLeft: 16,
          paddingRight: 16,
          height: "61.5vh",
          overflowY: "scroll",
          transition: "width 5s",
        }}
      >
        <style>{SAMPLECSS}</style>
        <div className="col-sm-12 col-md-12 col-lg-12">
          <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 mt-lg">
            <TextBoxComponent
              data-msg-containerid="weekDayNameErr"
              id="weekDayName"
              value={state.weekdayClusterName}
              name="weekDayNameErr"
              placeholder="Weekday Name *"
              autocomplete={'off'}
              floatLabelType="Auto"
              cssClass="e-outline"
              onChange={(e) => inputChangeHandler(e, "weekDayname")}
              htmlAttributes={{ maxlength: 50 }}
            />
            <div id="weekDayNameErr" className="error-div" />
          </div>
          <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 mt-lg">
            <ComboBoxComponent
              id="symbol"
              dataSource={props.symbolData.weekdayData}
              fields={{ text: "Name", value: "Id" }}
              value={state.weekdayClusterSymbolId}
              data-msg-containerid="symbolErr"
              name="symbolErr"
              allowCustom={false}
              floatLabelType="Auto"
              cssClass="e-outline"
              placeholder="Symbol *"
              change={(e) => inputChangeHandler(e, "weekdayClusterSymbolId")}
            />
            <div
              id="symbolErr"
              className="e-input-group e-float-icon-left error-div"
            />
          </div>

          <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12  weekdayDescription">
            <style>{descriptionTextBox}</style>
            <TextBoxComponent
              style={{ minHeight: 100 }}
              id={'description'}
              placeholder={"Description "}
              cssClass="e-outline textarea"
              floatLabelType="Auto"
              autocomplete={'off'}
              name="descriptionErr"
              data-msg-containerid="descriptionErr"
              multiline
              inputProps={{
                required: true,
                minLength: 0,
              }}
              htmlAttributes={{ maxlength: 500 }}
              onChange={(e) => inputChangeHandler(e, "weekdayClusterDescription")}
              value={state.weekdayClusterDescription}
            />
          </div>

          <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 mt-lg">
            <div
              className="col-xs-6 col-sm-6 col-lg-6 col-md-6 "
              style={{ paddingLeft: 0 }}
            >
              <ComboBoxComponent
                id="weekDayStart"
                dataSource={days}
                fields={{ text: "Name", value: "Id" }}
                value={state.weekdayStart}
                data-msg-containerid="weekDayStartErr"
                name="weekDayStartErr"
                floatLabelType="Auto"
                cssClass="e-outline"
                placeholder="Weekday Start *"
                allowCustom={false}
                select={(e) => inputChangeHandler(e, "weekdayStart")}
              />
              <div
                id="weekDayStartErr"
                className="e-input-group e-float-icon-left error-div"
              />
            </div>
            <div
              className="col-xs-6 col-sm-6 col-lg-6 col-md-6 "
              style={{ paddingRight: 0 }}
            >
              <ComboBoxComponent
                id="weekDayEnd"
                dataSource={days}
                fields={{ text: "Name", value: "Id" }}
                value={state.weekdayEnd}
                data-msg-containerid="weekDayEndErr"
                name="weekDayEndErr"
                allowCustom={false}
                floatLabelType="Auto"
                cssClass="e-outline"
                placeholder="Weekday End *"
                select={(e) => inputChangeHandler(e, "weekdayEnd")}
              />
              <div
                id="weekDayEndErr"
                className="e-input-group e-float-icon-left error-div"
              />
            </div>
          </div>
        </div>
        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 ">
          <div
            className="col-xs-6 col-sm-6 col-lg-6 col-md-6"
            style={{ paddingLeft: 0 }}
          >
            <NumericTextBoxComponent
              id="weekDayCount"
              fields={{ text: "Name", value: "Id" }}
              value={state.weekdayCount}
              data-msg-containerid="weekDayCountErr"
              name="weekDayCountErr"
              allowCustom={false}
              min={1}
              max={6}
              floatLabelType="Auto"
              autocomplete={'off'}
              cssClass="e-outline"
              placeholder="Weekday Count *"
              onChange={(e) => inputChangeHandler(e, "weekdayCount")}
            />
            <div
              id="weekDayCountErr"
              className="e-input-group e-float-icon-left error-div"
            />
          </div>
          <div
            className="col-xs-6 col-sm-6 col-lg-6 col-md-6"
            style={{ paddingRight: 0 }}
          >
            <NumericTextBoxComponent
              data-msg-containerid="weekEndCountErr"
              id="weekEndCount"
              value={state.weekendCount}
              name="weekEndCountErr"
              placeholder="Weekend Count *"
              autocomplete={'off'}
              min={1}
              max={2}
              floatLabelType="Auto"
              cssClass="e-outline"
              onChange={(e) => inputChangeHandler(e, "weekendCount")}
              htmlAttributes={{ maxlength: 100 }}
            />
            <div id="weekEndCountErr" className="error-div" />
          </div>

          <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 ">
            <ComboBoxComponent
              data-msg-containerid="weekEndTypeErr"
              id="weekEndType"
              fields={{ text: "Name", value: "Id" }}
              dataSource={[
                { Name: "Paid", Id: "1" },
                { Name: "Unpaid", Id: "0" },
              ]}
              value={state.weekendType}
              name="weekEndTypeErr"
              placeholder="Weekend Type *"
              floatLabelType="Auto"
              cssClass="e-outline"
              allowCustom={false}
              select={(e) => inputChangeHandler(e, "weekendType")}
              htmlAttributes={{ maxlength: 100 }}
            />
            <div id="weekEndTypeErr" className="error-div" />
          </div>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-12 mt-lg">
          <div
            className="col-xs-6 col-sm-6 col-lg-6 col-md-6"
            style={{ paddingLeft: 0 }}
          >
            <DatePickerComponent
              id="effectiveStartDate"
              allowEdit={false}
              fields={{ text: "Name", value: "Id" }}
              value={state.effectiveStartDate}
              data-msg-containerid="effectiveStartDateErr"
              name="effectiveStartDateErr"
              format="dd-MMM-yyyy"
              floatLabelType="Auto"
              cssClass="e-outline"
              placeholder="Effective Start Date *"
              change={(e) => inputChangeHandler(e, "effectiveStartDate")}
            />
            <div
              id="effectiveStartDateErr"
              className="e-input-group e-float-icon-left error-div"
            />
          </div>
          <div
            className="col-xs-6 col-sm-6 col-lg-6 col-md-6"
            style={{ paddingRight: 0 }}
          >
            <DatePickerComponent
              allowEdit={false}
              id="effectiveEndDate"
              fields={{ text: "Name", value: "Id" }}
              value={state.effectiveEndDate}
              data-msg-containerid="effectiveEndDateErr"
              name="effectiveEndDateErr"
              // allowCustom={false}
              min={state.effectiveStartDate ? getOneDayForward(new Date(state.effectiveStartDate)) : null}
              format="dd-MMM-yyyy"
              floatLabelType="Auto"
              cssClass="e-outline"
              placeholder="Effective End Date "
              change={(e) => inputChangeHandler(e, "effectiveEndDate")}
            />
          </div>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-12 mt-lg mb-lg">
          <div
            style={{
              fontSize: ".95rem",
              color: "#939399",
              fontWeight: 500,
              padding: ".5rem 0",
              marginTop: "-2rem",
            }}
          >
            Attach Policy
          </div>

          <UploadDocuments
            type={"weekday"}
            module={'lna'}
            allowmultiple={false}
            selectedFiles={selectedFiles}
            fileList={!state.daybreakPolicyDoc ? [] : state.daybreakPolicyDoc}
            apiUrl={"lnaApiUrl"}
            documentDetails={documentDetails}
          />

          {state.fileName !== null && state.oldFileStatus !== null ? (
            <div style={{ paddingLeft: 8 }}>
              {createImageFilepathForLPView(
                [].concat(state.fileName),
                "document/lna/weekday",
                "lnaApiUrl", removeFile
              )}
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="loader-new1" id="spinnerContainer" >
        {initialLoad && <ProgressBar />}
      </div>
    </>
  );
}

const mapStateToProps = ({ auth, lnareducer, meetingmaster }) => {
  const { authUser } = auth;
  const { currentFinancialYearId } = meetingmaster;
  const { symbolData, lnaMasterFlag } = lnareducer;

  return {
    authUser,
    lnareducer,
    currentFinancialYearId,
    symbolData, lnaMasterFlag
  };
};
export default connect(mapStateToProps, {
  saveWeekdayCluster, updateLnAData
})(WeekdayCluster);
