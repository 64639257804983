/**
 * Author: Suresh R. Sargam
 * Created:16-Feb-2022
 * Last Modify:16-Feb-2022
 * Modified By:
 */

import axios from "axios";
import { urlConfig } from "../../config/config";
import { useEffect } from "react";
import { updateLnAData } from "../../redux/actions/lna/lnaaction";
function errorHandler(reject, error) {
  // eslint-disable-next-line
  reject("operation failed");
}

/**
 * Author:Suresh R. Sargam  *
 * @param {*} authUser
 * @param {*} type
 * @param {*} optional1
 * @param {*} optional2
 * @description get the rating data
 * @code Revised added Promise and error handling method
 */
async function getMasterData(authUser, type, optional1, optional2) {
  const getURL =
    `${urlConfig.lnaApiUrl}master/${authUser}/${type}` +
    (optional1 !== "" ? `?optional1=${optional1}` : "") +
    (optional2 !== "" ? `&optional2=${optional2}` : "");

  try {
    return await invokeGetReq(getURL);
  } catch (error) {
    return Promise.reject(error);
  }
}

/**
 * Author:Suresh Patel  *
 * @param {*} authUser
 * @param {*} type
 * @param {*} optional1
 * @param {*} optional2
 * @description get the rating data
 * @code Revised added Promise and error handling method
 */

async function getHolidayClusterData(authUser, fyID, holidayUid) {
  const getURL = `${urlConfig.lnaApiUrl}holiday/${authUser}/${fyID}/${holidayUid}`;

  try {
    return await invokeGetReq(getURL);
  } catch (error) {
    return Promise.reject(error);
  }
}

// optional holiday
//  @code Revised added Promise and error handling method

async function getOptionalHolidayClusterData(authUser, holidayUid) {
  const getURL = `${urlConfig.lnaApiUrl}optionalholiday/${authUser}/${holidayUid}`;

  try {
    return await invokeGetReq(getURL);
  } catch (error) {
    return Promise.reject(error);
  }
}

/**
 * Author - Keval
 * @param {*} type
 * @param {*} authUser
 * @param {*} countryId
 * @param {*} financialYear
 * @param {*} monthYear
 * @description for Working Saturday
 */
async function getHolidayNWorkingSatDetails(
  type,
  authUser = 0,
  countryId = "0",
  financialYear = 0,
  monthYear = "0"
) {
  updateLnAData({
    holidayLoadingStatus: "holidayLoaderInList",
  })

  const getURL = `${urlConfig.lnaApiUrl}holiday/tm/${type}/${authUser}/${countryId}/${financialYear}/${monthYear}`;
  try {
    return await invokeGetReq(getURL);
  } catch (error) {
    return Promise.reject(error);
  }
}

/**
 * @param {*} type
 * @param {*} authUser
 * @param {*} countryId
 * @param {*} financialYear
 * @param {*} monthYear
 * @description for Attendance Admin Master
 */
async function getAttendanceClusterData(authUser, attendanceUid) {
  const getURL = `${urlConfig.lnaApiUrl}attendance/${authUser}/${attendanceUid}`;

  try {
    return await invokeGetReq(getURL);
  } catch (error) {
    return Promise.reject(error);
  }
}

/**
 * @param {*} authUser
 * @param {*} daybreakUid
 * @description for DayBreak Master
 */
async function getDayBreakClusterData(authUser, daybreakUid) {
  const getURL = `${urlConfig.lnaApiUrl}daybreak/${authUser}/${daybreakUid}`;

  try {
    return await invokeGetReq(getURL);
  } catch (error) {
    return Promise.reject(error);
  }
}

/**
 * @param {*} authUser
 * @param {*} weekdayUid
 * @description for WeekDay Master
 */
async function getWeekdayClusterData(authUser, weekdayUid) {
  const getURL = `${urlConfig.lnaApiUrl}weekday/${authUser}/${weekdayUid}`;

  try {
    return await invokeGetReq(getURL);
  } catch (error) {
    return Promise.reject(error);
  }
}

/**
 * @param {*} authUser
 * @param {*} workdayWindowUid
 * @description for workdayWindow Master
 */

async function getWorkdayWindowData(authUser, workdayWindowUid) {
  const getURL = `${urlConfig.lnaApiUrl}workdaywindow/${authUser}/${workdayWindowUid}`;

  try {
    return await invokeGetReq(getURL);
  } catch (error) {
    return Promise.reject(error);
  }
}

/**
 * @param {*} authUser
 * @param {*} workdayWindowUid
 * @description for workday Daybreak Mapping
 */

async function getWorkdayDayBreakMapping(authUser, workdayBreakMappingUid) {
  const getURL = `${urlConfig.lnaApiUrl}workdaybreakmapping/${authUser}/${workdayBreakMappingUid}`;

  try {
    return await invokeGetReq(getURL);
  } catch (error) {
    return Promise.reject(error);
  }
}

/**
 * @param {*} authUser
 * @param {*} sourceUid
 * @description for sourceCluster Master
 */
async function getSourceClusterData(authUser, sourceUid) {
  const getURL = `${urlConfig.lnaApiUrl}source/${authUser}/${sourceUid}`;

  try {
    return await invokeGetReq(getURL);
  } catch (error) {
    return Promise.reject(error);
  }
}

/**
 * @param {*} authUser
 * @param {*} leaveUid
 * @description for Leave Master Admin Module
 */
async function getLeaveClusterData(authUser, leaveUid) {
  const getURL = `${urlConfig.lnaApiUrl}leave/${authUser}/${leaveUid}`;

  try {
    return await invokeGetReq(getURL);
  } catch (error) {
    return Promise.reject(error);
  }
}

/**
 * @author  Vivek Khodade
 * @param {*} authUser
 * @param {*} fyID
 * @description get leave master data.
 * @returns
 */
async function getLeaveMasterData(authUser, fyID) {
  const getURL = `${urlConfig.lnaApiUrl}leave/${authUser}/${fyID}`;

  try {
    return await invokeGetReq(getURL);
  } catch (error) {
    return Promise.reject(error);
  }
}

/**
 * @author  Vivek Khodade
 * @param {*} authUser
 * @param {*} type
 * @param {*} fyID
 * @param {*} leaveClusterUid
 * @description get leave balance data.
 * @returns
 */
async function getLeaveBalanceData(
  authUser,
  type,
  fyID,
  leaveClusterUid,
  specialAttendance
) {
  try {
    const getURL = `${urlConfig.lnaApiUrl
      }leaveapply/balance/${type}/${authUser}/${fyID}/${leaveClusterUid}${specialAttendance == 3
        ? "?isSpecialAttendance=3"
        : specialAttendance == "2"
          ? "?isSpecialAttendance=2"
          : ""
      }`;
    return await invokeGetReq(getURL);
  } catch (error) {
    return Promise.reject(error);
  }
}

/**
 * @author  Noel Fredrick
 * @param {*} authUser
 * @param {*} leaveApplicationId
 * @param {*} type
 * @description get leave balance data.
 * @returns
 */
async function getLeaveDetailsData(
  authUser,
  leaveApplicationId,
  type,
  isSpecialAttendance
) {
  try {
    const getURL = `${urlConfig.lnaApiUrl
      }leaveapply/details/${authUser}/${leaveApplicationId}/${type}${isSpecialAttendance ? "?isSpecialAttendance=2" : ""
      }`;
    return await invokeGetReq(getURL);
  } catch (error) {
    return Promise.reject(error);
  }
}

/**
 * @author  Noel Fredrick
 * @param {*} authUser
 * @param {*} leaveApplicationId
 * @param {*} type
 * @description get Onleave data.
 * @returns
 */
async function getOnLeaveData(authUser, teamId, type, teamMemberIds) {
  const getURL = `${urlConfig.lnaApiUrl
    }leaveapply/onleave/${authUser}/${teamId}/${type}${teamMemberIds !== "" ? "/" + teamMemberIds : ""
    }`;

  try {
    return await invokeGetReq(getURL);
  } catch (error) {
    return Promise.reject(error);
  }
}

/**
 * @author  Sanket Bari
 * @param {*} authUser
 * @param {*} type2
 * @param {*} type1
 * @description get Onleave data.
 * @returns
 */
async function getOnLeaveDataNew(authUser, type1, type2) {
  const getURL = `${urlConfig.lnaApiUrl}leaveapply/onleavenew/${authUser}/${type1}/${type2}`;

  try {
    return await invokeGetReq(getURL);
  } catch (error) {
    return Promise.reject(error);
  }
}

/**
 * @author  Vivek Khodade
 * @param {*} authUser
 * @param {*} fyID
 * @description get leave dates data.
 * @returns
 */
// no sonar
async function getLeavesDates(
  authUser,
  fyID,
  leaveType,
  leaveDatesData,
  leaveApplicationId,
  isSpecialAtt,
  dayBreakString
) {
  let {
    startDate: leaveStartDate,
    endDate: leaveEndDate,
    startDateCount: leaveStartDayCount,
    endDateCount: leaveEndDayCount,
    isWeekendCounted,
    isStreched,
  } = leaveDatesData;
  try {
    const getURL = `${urlConfig.lnaApiUrl
      }leaveapply/validate/${authUser}/validate/${fyID}/${leaveType}/${isWeekendCounted ? isWeekendCounted : "0"
      }/${isStreched}/${leaveStartDate}/${leaveEndDate}/${leaveStartDayCount}/${leaveEndDayCount}/${leaveApplicationId ? leaveApplicationId : 0
      }/${dayBreakString.startStr}/${dayBreakString.endStr}${isSpecialAtt ? "?isSpecialAttendance=2" : ""
      }`;
    return await invokeGetReq(getURL);
  } catch (error) {
    return Promise.reject(error);
  }
}

// Author : Sanjeev (To get Exceptional cluster data)
function getExceptionalClusterData(authUser) {
  const getURL = `${urlConfig.lnaApiUrl}exceptional/${authUser}`;
  return invokeGetReq(getURL);
}

function getUniqueExceptionalClusterData(authUser, exceptionalclusteruid) {
  const getURL = `${urlConfig.lnaApiUrl}exceptional/${authUser}/${exceptionalclusteruid}`;
  return invokeGetReq(getURL);
}

// Author : AMit Kumar (To get Statutory cluster data)
function getStatutoryClusterData(authUser, fyid) {
  const getURL = `${urlConfig.lnaApiUrl}statutory/${authUser}/${fyid}`;
  return invokeGetReq(getURL);
}

function getUniqueStatutoryClusterData(authUser, fyid, statutoryclusteruid) {
  const getURL = `${urlConfig.lnaApiUrl}statutory/${authUser}/${fyid}/${statutoryclusteruid}`;
  return invokeGetReq(getURL);
}
//    getRegularizationData
function getRegularizationData(authUser, type, regularizationId) {
  const getURL = `${urlConfig.lnaApiUrl}leaveswipe/${authUser}/${type}/${regularizationId}`;
  return invokeGetReq(getURL);
}

async function getApprovalStatusMatrixData(authUser, type, applicationId) {
  try {
    const getURL = `${urlConfig.lnaApiUrl}approval/status/${authUser}/${type}/${applicationId}`;
    return await invokeGetReq(getURL);
  } catch (error) {
    return Promise.reject(error);
  }
}

// getLeaveStatusData
function getLeaveStatusData(authUser, type, regularizationId) {
  const getURL = `${urlConfig.lnaApiUrl}compoffleave/${authUser}/${type}/${regularizationId}`;
  return invokeGetReq(getURL);
}
// getLeavesToBeTransferData

function getLeavesToBeTransferData(type, authUser, fyId) {
  const getURL = `${urlConfig.lnaApiUrl}leavestobetransferred/${authUser}/${type}/${fyId}`;
  return invokeGetReq(getURL);
}
// getWeeklyReportData
function getWeeklyReportData(authUser, type, monthId) {
  const getURL = `${urlConfig.lnaApiUrl}report/${authUser}/${type}/${monthId}`;  
  return invokeGetReq(getURL);
}

// getMonthlyReportData
function getMonthlyReportData(
  authUser,
  users,
  monthId,
  country = 0,
  businessUnit = 0,
  subBusinessUnit = 0
) {
  const getURL = `${urlConfig.lnaApiUrl}monthreport/${monthId}?userId=${authUser}&userIds=${users}&country=${country}&businessUnit=${businessUnit}&subBusinessUnit=${subBusinessUnit}`;
  return invokeGetReq(getURL);
}

// getYearlyReportData
function getYearlyReportData(userId, startDate, endDate, teammemberId) {
  const getURL = `${urlConfig.lnaApiUrl}yearlyreport/${userId}/${startDate}/${endDate}/${teammemberId}`;
  return invokeGetReq(getURL);
}

// Author : AMit Kumar (To get Workingday cluster data)
function getWorkingdayClusterData(authUser, fyid) {
  const getURL = `${urlConfig.lnaApiUrl}workingday/${authUser}/${fyid}`;
  return invokeGetReq(getURL);
}

function getUniqueWorkingdayClusterData(authUser, fyid, workingdayclusteruid) {
  const getURL = `${urlConfig.lnaApiUrl}workingday/${authUser}/${fyid}/${workingdayclusteruid}`;
  return invokeGetReq(getURL);
}

// Author : AMit Kumar (To get role cluster data)
function roleClusterData(authUser) {
  const getURL = `${urlConfig.lnaApiUrl}role/${authUser}`;
  return invokeGetReq(getURL);
}

function getUniqueRoleClusterData(authUser, workingdayclusteruid) {
  const getURL = `${urlConfig.lnaApiUrl}role/${authUser}/${workingdayclusteruid}`;
  return invokeGetReq(getURL);
}
function getLeaveApplyMasterData(type, authUser, fyId) {
  const getURL = `${urlConfig.lnaApiUrl}lnaleaveapplymasters/${type}/${authUser}/${fyId}`;
  return invokeGetReq(getURL);
}

// Author : AMit Kumar (To get role cluster data)
function colourClusterData(authUser) {
  const getURL = `${urlConfig.lnaApiUrl}color/${authUser}`;
  return invokeGetReq(getURL);
}

function getUniqueColourClusterData(authUser, workingdayclusteruid) {
  const getURL = `${urlConfig.lnaApiUrl}color/${authUser}/${workingdayclusteruid}`;
  return invokeGetReq(getURL);
}

async function getLNAMaserData(authUser, lnaMasterUid, lnAMasterItemUid, fyID) {
  try {
    const getURL = `${urlConfig.lnaApiUrl}adminmaster/${authUser}/${lnaMasterUid}/${lnAMasterItemUid}/${fyID}`;
    return await invokeGetReq(getURL);
  } catch (error) { }
}

/**
 * @author  Noel
 * @param {*} authUser
 * @param {*} symbolUid
 * @description get Symbol data
 * @date 17/10/2022
 * @returns
 */

async function getSymbolClusterData(authUser, editClusterId) {
  const getURL = `${urlConfig.lnaApiUrl}symbol/${authUser}/${editClusterId}`;

  try {
    return await invokeGetReq(getURL);
  } catch (error) {
    return Promise.reject(error);
  }
}

/**
 * @author  Chandrashekhar Bhagat
 * @param {*} authUser
 * @param {*} rowUid
 * @description get Leave with Finance Year data
 * @date 16/11/2023
 * @returns
 */

async function getLeaveYearData(authUser, fyID, editClusterId) {
  const getURL = `${urlConfig.lnaApiUrl}leaveyear/${authUser}/${fyID}/${editClusterId}`;

  try {
    return await invokeGetReq(getURL);
  } catch (error) {
    return Promise.reject(error);
  }
}

/**
 * @author  Chandrashekhar Bhagat
 * @param {*} authUser
 * @param {*} rowUid
 * @description check that user is allowed for inactive or not.
 * @date 22/11/2023
 * @returns
 */

async function getAllowanceData(authUser, lnaMasterUid, lnAMasterItemUid) {
  const getURL = `${urlConfig.lnaApiUrl}checkAllowance/${authUser}/${lnaMasterUid}/${lnAMasterItemUid}`;
  try {
    return await invokeGetReq(getURL);
  } catch (error) {
    return Promise.reject(error);
  }
}

/**
 * @author  Suresh
 * @param {*} payloadData
 * @description to sync the leaves data in background like email shooting
 * @date 23-Aug-2023
 * @returns
 */

async function syncLeavedetails(payloadData) {
  const getURL = `${urlConfig.lnaApiUrl}background_sync`;
  try {
    return await invokePostReq(getURL, payloadData);
  } catch (error) {
    return Promise.reject(error);
  }
}

function invokeGetReq(getUrl) {
  return new Promise(function onThen(resolve, reject) {
    axios
      .get(getUrl, {
        headers: {
          authorization: localStorage.getItem("jwtToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      // eslint-disable-next-line
      .then((response) => {
        //NOSONAR
        updateLnAData({
          holidayLoadingStatus: "updated",
        })
        if (response != null) {
          return resolve(response.data);
        }
      })
      .catch((error) => {
        errorHandler(reject, error.response.data.message);
        return null;
      });
  });
}

function invokePostReq(getUrl, payLoad) {
  return new Promise(function onThen(resolve, reject) {
    axios
      .post(getUrl, payLoad, {
        headers: {
          authorization: localStorage.getItem("jwtToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      // eslint-disable-next-line
      .then((response) => {
        //NOSONAR
        if (response != null) {
          return resolve(response.data);
        }
      })
      .catch((error) => {
        errorHandler(reject, error.response.data.message);
        return null;
      });
  });
}

function getLeavesApprovalList(type, authUser, fyId, leaveId) {
  const getURL = `${urlConfig.lnaApiUrl}leaveapproval/${type}/${authUser}/${fyId}/${leaveId}`;
  return invokeGetReq(getURL);
}
/**
 * @author  Amit Kumar
 * @param {*} authUser
 * @description get comp off leave dates data.
 * @returns
 */
async function getCompOffLeavesDates(
  authUser,
  leaveType,
  leaveStartDate,
  leaveEndDate,
  leaveStartDayCount,
  leaveEndDayCount,
  leaveApplicationId
) {
  const getURL = `${urlConfig.lnaApiUrl}compoffleaveapply/validate/${authUser}/validate/${leaveType}/${leaveStartDate}/${leaveEndDate}/${leaveStartDayCount}/${leaveEndDayCount}/${leaveApplicationId}`;

  try {
    return await invokeGetReq(getURL);
  } catch (error) {
    return Promise.reject(error);
  }
}

// Author : Sanjeev (To get Exceptional cluster data)
async function getMonthYearAttendenceData(authUser, monthYear) {
  const getURL = `${urlConfig.lnaApiUrl}calendar/${authUser}/${monthYear}`;

  try {
    return await invokeGetReq(getURL);
  } catch (error) {
    return Promise.reject(error);
  }
}

/**
 * @author  Noel
 * @param {*} authUser
 * @description To GET HR REPORT DATA.
 * @returns
 */
async function getHrReport(
  authUser,
  type,
  startDate,
  endDate,
  teamMemberIds,
  countryId,
  businessUnitIds,
  subBusinessUnitIds,
  attendanceTypeId,
  leaveTypeId
) {
  //NOSONAR
  const getURL = `${urlConfig.lnaApiUrl
    }leaveapply/${authUser}/${type}/${startDate}/${endDate}${teamMemberIds ? "?teamMemberIds=" + teamMemberIds : ""
    }${countryId ? "&countryId=" + countryId : ""}${businessUnitIds ? "&businessUnitIds=" + businessUnitIds : ""
    }${subBusinessUnitIds ? "&subBusinessUnitIds=" + subBusinessUnitIds : ""}${attendanceTypeId ? "&attendanceTypeId=" + attendanceTypeId : ""
    }${leaveTypeId ? "&leaveTypeId=" + leaveTypeId : ""}`;

  try {
    return await invokeGetReq(getURL);
  } catch (error) {
    return Promise.reject(error);
  }
}

/**
 * @author  Abdulla Shaikh
 * @param {*} authUser
 * @description To Remove Notication and subscription data
 * @returns
 */
async function removeNotificationData(notificationId) {
  //NOSONAR
  const getURL = `${urlConfig.lnaApiUrl}removenotifierlist/${notificationId}`;
  try {
    return await invokeGetReq(getURL);
  } catch (error) {
    return Promise.reject(error);
  }
}
/**
 * @author  Abdulla Shaikh
 * @param {*} authUser
 * @description To GET Notication and subscription data
 * @returns
 */
async function getNotificationData(type, authUser) {
  //NOSONAR
  const getURL = `${urlConfig.lnaApiUrl}notifierlist/${type}/${authUser}`;

  try {
    return await invokeGetReq(getURL);
  } catch (error) {
    return Promise.reject(error);
  }
}
async function getLockUnlockClusterData(authUser, lockUnlockUid) {
  const getURL = `${urlConfig.lnaApiUrl}lockunlock/${authUser}/${lockUnlockUid}`;

  try {
    return await invokeGetReq(getURL);
  } catch (error) {
    return Promise.reject(error);
  }
}

async function getLeaveYearClusterData(authUser, leaveYearUid) {
  const getURL = `${urlConfig.lnaApiUrl}leaveyear/${authUser}/${leaveYearUid}`;

  try {
    return await invokeGetReq(getURL);
  } catch (error) {
    return Promise.reject(error);
  }
}

const lnaservice = {
  getMasterData,
  getAttendanceClusterData,
  getDayBreakClusterData,
  getWeekdayClusterData,
  getWorkdayWindowData,
  getSourceClusterData,
  getLeaveClusterData,
  getHolidayClusterData,
  getLeaveMasterData,
  getLeaveBalanceData,
  getStatutoryClusterData,
  getUniqueStatutoryClusterData,
  getExceptionalClusterData,
  getWorkingdayClusterData,
  getUniqueWorkingdayClusterData,
  roleClusterData,
  colourClusterData,
  getUniqueColourClusterData,
  getUniqueExceptionalClusterData,
  getUniqueRoleClusterData,
  getLeavesDates,
  getApprovalStatusMatrixData,
  getHolidayNWorkingSatDetails,
  getLeaveApplyMasterData,
  getRegularizationData,
  getLeaveStatusData,
  getLeavesToBeTransferData,
  getLeaveDetailsData,
  getWeeklyReportData,
  getMonthlyReportData,
  getYearlyReportData,
  getOnLeaveData,
  getOnLeaveDataNew,
  getLeavesApprovalList,
  getSymbolClusterData,
  getOptionalHolidayClusterData,
  getCompOffLeavesDates,
  getMonthYearAttendenceData,
  getLNAMaserData,
  getHrReport,
  getNotificationData,
  removeNotificationData,
  getLockUnlockClusterData,
  syncLeavedetails,
  getLeaveYearClusterData,
  getWorkdayDayBreakMapping,
  getLeaveYearData,
  getAllowanceData,
};

export default lnaservice;
