import React, { Component } from 'react';
import { connect } from "react-redux";

import { getNotify } from "../../app/other/commonfunction";
import { WARNING, SUCCESS, ERROR } from "../../assets/constants/constants";
import { urlConfig, corsallow } from "../../config/config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { MDBIcon } from "mdbreact";
import { UploadCommonDocFilepath } from '../okrratingcomponents/ratingcommonfun';
import OKRCommonFilesGridView from "./okrcommonfilesgridview";
import ProgressBar from "../../components/progressbar/progressbar";
import '../objective/tags.css';
class OkrCommonFiles extends Component {
    constructor() {
        super();
        this.state = {
            hightlight: false,
            allowmultiple: true,
            filePath: '',
            fileNames: [],
            disabled: false,
            type: 'Add',
            selectedFiles: [],
            folderPath: '',
            cdmLoaded: false,
            selectedFilesTime: [],
            deletePopup: false,
            deleteIdx: null,
            isListView: true,
            showProgressBar: false,
            docUploadedBy: []
        };
        this.fileInputRef = React.createRef();
    }
    getAllCommonFiles = (empId, callFrom) => {
        const { employeeId, tabType, fyId, fyid, documentLengthHandler } = this.props;
        const teamMemberId = tabType === "OKRRating" ? empId : employeeId
        if (teamMemberId > 0) {
            const getAllCommonFilesUrl = `${corsallow}${urlConfig.tmlcApiUrl}okr/document/all/${teamMemberId}/${tabType === "OKRRating" ? fyId : fyid}`;
            let m_arrSelectedFiles = [];
            let m_arrSelectedFilesTime = [];
            let m_arrDocUploadedBy = [];
            fetch(getAllCommonFilesUrl)
                .then(
                    response => response.json()
                ).then(files => {
                    if (files.Result) {
                        files.Result.forEach(file => {
                            m_arrSelectedFiles.push(file.var_doc_name);
                            m_arrSelectedFilesTime.push(file.dtUploadedOn);
                            m_arrDocUploadedBy.push(file.fk_user_id);

                        })
                    }
                    this.setState({
                        selectedFiles: m_arrSelectedFiles,
                        selectedFilesTime: m_arrSelectedFilesTime,
                        docUploadedBy: m_arrDocUploadedBy,
                        cdmLoaded: true
                    });
                    if (tabType !== "OKRRating") {
                        documentLengthHandler(m_arrSelectedFiles.length);
                    }
                });
        }

    }
    componentDidMount() {
        this.getAllCommonFiles(this.props.empId, "didMount");
    }
    componentDidUpdate(newProps) {
        if ((newProps.empId !== this.props.empId) || (newProps.employeeId !== this.props.employeeId) || (newProps.fyId !== this.props.fyId) || (newProps.fyid !== this.props.fyid)) {
            setTimeout(() => {
                this.getAllCommonFiles(this.props.empId, "receiveProps");//NOSONAR
            }, 500);
        }
    }
    onDragOver = (event) => {
        event.preventDefault();
        const { disabled } = this.state;
        if (disabled) return;
        this.setState({ hightlight: true });
    }

    onDragLeave = () => {
        this.setState({ hightlight: false });
    }

    getFileExtension = (filename) => {
        let ext = filename.split('.').pop().toLowerCase();
        if (ext === filename) return "";
        return ext;
    }

    onDrop = (event) => {
        event.preventDefault();
        const { disabled, selectedFiles } = this.state;
        if (disabled) return;
        const { files } = event.dataTransfer;
        const allowedFiles = ["txt", "TXT", "pdf", "PDF", "xlsx", "XLSX", "xls", "XLS", "doc", "DOC", "docx", "DOCX", "ppt", "PPT", "pptx", "PPTX", "jpeg", "JPEG", "jpg", "JPG", "png", "PNG", "svg", "SVG", "EPS", "eps", "GIF", "gif", "BMP", "bmp", "TIF", "tif", "TIFF", "tiff"];
        const isFileExist = selectedFiles.some((fileName) => {
            return files[0].name.replace(/[&#,+()$~%'":*?<>{}]/g, '').includes(fileName.substring(11, fileName.length))
        });
        if (isFileExist) {
            getNotify(ERROR, "File already exist.");
        }
        if (files.length > 0 && files[0].type && allowedFiles.includes(this.getFileExtension(files[0].name)) && !isFileExist) {
            this.uplaodDoc(files[0]);
        }
        this.setState({ hightlight: false });
    }

    onFilesAdded = (evt) => {
        const { selectedFiles } = this.state;
        const isFileExist = selectedFiles.some((fileName) => {
            return evt.target.files[0].name.replace(/[&#,+()$~%'":*?<>{}]/g, '').includes(fileName.substring(11, fileName.length))
        });

        if (isFileExist) {
            getNotify(ERROR, "File already exist.");
        }

        if (evt.target.files[0] !== null && !isFileExist) {
            this.uplaodDoc(evt.target.files[0])
        }
    }

    uplaodDoc = (upfile) => {
        const { disabled, type, selectedFiles, selectedFilesTime, docUploadedBy } = this.state;
        const { authUser, employeeId, empId, tabType, fyId, fyid } = this.props;
        if (disabled) return;
        if (upfile !== undefined) {
            const currentDate = Date.parse(new Date()) / 1000;
            const fileObj = upfile;
            const fileNm = currentDate.toString() + '_' + fileObj.name.replace(/[&#,+()$~%'":*?<>{}]/g, '');
            const mSelectedFiles = selectedFiles;
            const mSelectedFilesTime = selectedFilesTime;
            const mDocUploadedBy = docUploadedBy;

            mSelectedFiles.push(fileNm);
            mSelectedFilesTime.push(new Date());
            mDocUploadedBy.push(authUser);

            this.setState({
                UploadedImage: URL.createObjectURL(upfile),
                filePath: upfile.name.replace(/[&#,+()$~%'":*?<>{}]/g, ''),
                selectedFiles: mSelectedFiles,
                selectedFilesTime: mSelectedFilesTime,
                docUploadedBy: mDocUploadedBy,
            }, () => {
                this.forceUpdate();
            });
            const req = new XMLHttpRequest();
            const formData = new FormData();
            formData.append("type", type);
            formData.append("userId", authUser);
            formData.append("empId", tabType === "OKRRating" ? empId : employeeId);
            formData.append("fyId", tabType === "OKRRating" ? fyId : fyid);
            formData.append("file", fileObj, fileNm);
            formData.append("fileName", fileNm);
            req.open("POST", `${corsallow}${urlConfig.tmlcApiUrl}document/upl/okr`);
            req.setRequestHeader("Authorization", localStorage.getItem("jwtToken"));
            req.send(formData);
            if (req.statusText === "Internal Server Error") {
                getNotify(WARNING, "Please select minimum file size(less than 5 MB)");
            } else {
                this.setState({ showProgressBar: true })
                setTimeout(() => {
                    this.setState({ showProgressBar: false })
                    getNotify(SUCCESS, "Document uploaded successfully.");
                }, 4000);
            }
        }
    }

    openFileDialog = () => {
        const { disabled } = this.state;
        if (disabled) return;
        this.fileInputRef.current.click();
    }

    removeFile = (indexToRemove) => {
        const { empId, employeeId, tabType } = this.props;
        const fileNm = this.state.selectedFiles[indexToRemove];

        const req = new XMLHttpRequest();
        const formData = new FormData();
        formData.append("type", "Delete");
        formData.append("empId", tabType === "OKRRating" ? empId : employeeId);
        formData.append("fileName", fileNm);
        req.open("POST", `${corsallow}${urlConfig.tmlcApiUrl}document/upl/okr`);
        req.setRequestHeader("Authorization", localStorage.getItem("jwtToken"));
        req.send(formData);
        if (req.statusText === "Internal Server Error") {
            getNotify(WARNING, "Please select minimum file size(less than 5 MB)");
        } else {
            getNotify(SUCCESS, "Document deleted successfully.");
        }

        const deleteFile = this.state.selectedFiles;
        const deleteTime = this.state.selectedFilesTime;

        this.setState({
            selectedFiles: deleteFile.filter((ele, idx) => (idx !== indexToRemove)),
            selectedFilesTime: deleteTime.filter((ele, idx) => (idx !== indexToRemove))
        })
        this.forceUpdate();
        this.setState({ deletePopup: false })
    }
    openDeletePopup = (idx) => {
        this.setState({ deletePopup: true, deleteIdx: idx })
    }
    handleConfirmClose = () => {
        this.setState({ deletePopup: false })
    }
    handleConfirmNo = () => {
        this.setState({ deletePopup: false })
    }

    openTab = (tabName) => {
        if (tabName === "ListView") {
            this.setState({ isListView: true });
        }

        if (tabName === "GridView") {
            this.setState({ isListView: false });
        }
    }

    render() {
        const { hightlight, allowmultiple, disabled, cdmLoaded, selectedFiles, selectedFilesTime, deletePopup, deleteIdx, isListView, showProgressBar, docUploadedBy } = this.state;
        try {
            document.getElementById('ratingCommonDocOkrCount').innerHTML = `(${selectedFiles.length})`;
        } catch (e) { }
        const { employeeId, empId, tabType, windowAdjust, authUser } = this.props;
        return (
            <>
                <div style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    borderBottom: "1px solid #cccccc",
                    padding: "1.5rem 1.5rem 1rem 1.5rem"
                }}>
                    <div>
                        <div>
                            <span className='tab-header-text' >Document(s)</span>
                        </div>

                        <div
                            className={`upload-document-link ${hightlight ? "Highlight" : ""}`}
                            onDragOver={this.onDragOver}
                            onDragLeave={this.onDragLeave}
                            onDrop={this.onDrop}
                            onClick={this.openFileDialog}
                            onKeyDown={this.openFileDialog}
                            style={{
                                cursor: disabled ? "default" : "pointer",
                                height: 50,
                                width: "22rem",
                                marginTop: "1rem",
                            }}
                            role="button"
                            tabIndex={0}

                        >
                            {allowmultiple ? (
                                <input
                                    ref={this.fileInputRef}
                                    className="FileInput"
                                    style={{ display: "none" }}
                                    type="file"
                                    multiple
                                    accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"
                                    onChange={this.onFilesAdded}
                                />
                            )
                                : (
                                    <input
                                        ref={this.fileInputRef}
                                        className="FileInput"
                                        style={{ display: "none" }}
                                        type="file"
                                        accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"
                                        onChange={this.onFilesAdded}
                                    />
                                )
                            }
                            <div className="drag-drop-browse">
                                Upload or Drag and Drop Common Files Here
                            </div>
                        </div>
                    </div>
                    <div style={{ alignSelf: "flex-end", display: "flex" }}>
                        <div
                            className={`list-view-grid-view ${isListView ? "list-view-active" : ""}`}
                        // style={{borderRight: isListView ? "" : "none"}}
                        >
                            <MDBIcon
                                icon="fas  fa-th-large"
                                style={{
                                    fontSize: 15,
                                    color: isListView ? "#F94F5E" : "#0000001A",
                                    cursor: "pointer"
                                }}
                                onClick={() => this.openTab("ListView")}
                            />
                        </div>
                        <div
                            className={`list-view-grid-view ${isListView ? "" : "list-view-active"}`}
                        // style={{borderRight: isListView ? "" : "none"}}
                        >
                            <FontAwesomeIcon
                                icon={faBars}
                                style={{ fontSize: 18, color: isListView ? "#0000001A" : "#F94F5E" }}
                                className="pointer-cursor"
                                onClick={() => this.openTab("GridView")}
                            />
                        </div>
                    </div>
                </div>
                <div style={{ marginTop: selectedFiles.length === 0 ? "5rem" : "" }}>
                    {selectedFiles.length === 0 ?
                        <div className="center-box-padding">
                            <div className="new-productivity-allocation-no-data-header pt-lg" style={{ color: "#4B4B55", fontWeight: 600 }}>
                                No Document Uploaded.
                            </div>
                            <div className="okr-no-item-text pt-xl" style={{ color: "#939399" }}>
                                To upload a document, please click on `Upload or Drag and Drop Common Files Here`.
                            </div>
                        </div>
                        :
                        <>
                            <div id="tags" style={{ display: isListView ? "block" : "none", height: `${windowAdjust - 138}px`, overflowY: "auto", overflowX: "hidden" }}>
                                {showProgressBar && <ProgressBar />}
                                {cdmLoaded &&

                                    <UploadCommonDocFilepath
                                        attachmentFiles={selectedFiles}
                                        filesTimeStamp={selectedFilesTime}
                                        url={`okr/download/document/${tabType === "OKRRating" ? empId : employeeId}`}
                                        removeFile={this.removeFile}
                                        filesType="commonFiles"
                                        deleteListPopup={deletePopup}
                                        handleConfirmClose={this.handleConfirmClose}
                                        handleConfirmNo={this.handleConfirmNo}
                                        openDeletePopup={this.openDeletePopup}
                                        deleteIdx={deleteIdx}
                                        apiUrl={'tmlcApiUrl'}
                                        authUser={authUser}
                                        docUploadedBy={docUploadedBy}
                                    />
                                }
                            </div>
                            <div style={{ display: !isListView ? "block" : "none" }}>
                                {showProgressBar && <ProgressBar />}
                                {cdmLoaded &&
                                    <OKRCommonFilesGridView
                                        attachmentFiles={selectedFiles}
                                        filesTimeStamp={selectedFilesTime}
                                        url={`okr/download/document/${tabType === "OKRRating" ? empId : employeeId}`}
                                        removeFile={this.removeFile}
                                        tabType={tabType}
                                        filesType="commonFiles"
                                        deletePopup={deletePopup}
                                        handleConfirmClose={this.handleConfirmClose}
                                        handleConfirmNo={this.handleConfirmNo}
                                        openDeletePopup={this.openDeletePopup}
                                        deleteIdx={deleteIdx}
                                        apiUrl={'tmlcApiUrl'}
                                        authUser={authUser}
                                        docUploadedBy={docUploadedBy}
                                    />
                                }
                            </div>
                        </>
                    }
                </div>
            </>
        )
    }
}

const mapStateToProps = ({ auth, okrreducer, okrratingreducer }) => {
    const { authUser } = auth;
    const { employeeId, fyid } = okrreducer;
    const { empId, fyId } = okrratingreducer;
    return { authUser, employeeId, empId, fyId, fyid };
};
export default connect(mapStateToProps, {})(
    OkrCommonFiles
); 
