/**
 * Author: Vivek Khodade.
 * Last Modify:08-May-2020
 * Modified By:Vivek Khodade
 */
import React from "react";
import PropTypes from "prop-types";

class ErrorTabMessage extends React.PureComponent {
  render() {
    const { message } = this.props;
    return (
      <div
        className="col-12"
        style={{
          textAlign: "center",
          position: "absolute",
          marginTop: 100,
        }}
      >
        <div
          style={{
            font: "icon",
            display: "inline-table",
            width: "55%",
            background: "#ecebe9",
            color: "#07090d",
            fontWeight: "bold",
          }}
        >
          <h3 style={{ marginTop: "20px", marginBottom: "20px" }}>{message}</h3>
        </div>
      </div>
    );
  }
}
ErrorTabMessage.propTypes = {
  message: PropTypes.string.isRequired,
};
ErrorTabMessage.defaultProps = {};
export default ErrorTabMessage;
