
import React, { useState, useEffect } from "react";
import { Dialog, DialogTitle, DialogActions, Button } from "@material-ui/core";

import { connect } from 'react-redux';

import OKRRatingServices from '../../services/okrrating/okrratingservices'
import { DialogContent } from "../customdialog/customdialog";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import InterimFeedbackGridData from "./interimfeedbackgrid";
import AddEditInterimReviewModal from './addeditinterimreviewmodal';

function InterimFeedBack(props) {//NOSONAR
  const [showAll, setShowAll] = useState(true)
  const [changeData, setChandeData] = useState(true)
  const [addInterimPopup, setAddInterimPopup] = useState(false)
  const [interimFeedbackData, setInterimFeedbackData] = useState({ allInterimFeedbackData: [], draftInterimFeedbackData: [] })
  const showGridData = (active) => {
    if (active === "all") {
      setShowAll(true);
    } else {
      setShowAll(false);
    }
  };
  const {krData,ratingTabs}=props;
  const header = () => {
    switch (props.interimType) {
      case "self":
        return " Interim Review - Self"
      case "sup":
        return " Interim Review - Supervisor"
      case "tp":
        return " Interim Review - Third Party"
      case "okr-review":
        return "Interim Review"
      default :
    }
  }


  const getInrerimData = async () => {
    const { authUser, empId, fyId, objId, monthOfRating, selectedType } = props
    const ratingData = await OKRRatingServices.getInterimReviewInfo(authUser, empId, objId, fyId, selectedType, krData.ratingId, monthOfRating)
    if (typeof ratingData !== 'undefined' && ratingData !== null && ratingData.length > 0) {
      let allInterimFeedbackData = ratingData.filter((t) => t.isDraft === 'N');
      let draftInterimFeedbackData = ratingData.filter((t) => t.isDraft === 'Y');
      setInterimFeedbackData({ allInterimFeedbackData: allInterimFeedbackData, draftInterimFeedbackData: draftInterimFeedbackData });
      setChandeData(!changeData)
      if(props.isDraftClick!==null){
        if(props.isDraftClick===true){
          setShowAll(false);
        }
        else{
          setShowAll(true);
        }
      }
    }
  }
  const closeAddEditIntrimFeedbackPopup = ()=>{
    setAddInterimPopup(false)
  }
  useEffect(() => {
    getInrerimData();
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (props.refreshInterimfeedbackflag === 'updated') {
      getInrerimData();
      closeAddEditIntrimFeedbackPopup();
    }
    // eslint-disable-next-line
  }, [props.refreshInterimfeedbackflag])
  
  return (
    <div>
      <Dialog open={true} fullWidth maxWidth="md" onEscapeKeyDown={() => props.closeViewIntrimFeedbackPopup()}>
        <DialogTitle>
          <span style={{paddingLeft:4}}
            className="add-new-self-header interim-feedback-header"
          >
            { header() }
          </span>
          <span style={{ float: 'right', cursor: 'pointer', marginRight: -8 }}><FontAwesomeIcon onClick={() => props.closeViewIntrimFeedbackPopup()} style={{ color: '#B7B7BB', fontSize: 20 }} icon={faTimes} /></span>
        </DialogTitle>

        <DialogContent>
          <div className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <form >
              <div className="form-row ">
                <div
                  className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12"
                  style={{ paddingLeft: "16px", paddingRight: "16px" }}
                >
                  <div className="view-kr-modalrating-header-text"> {
                  props.OKRType ==='3' ?
                  "Leadership Principle"
                  :
                  "Key Result"
                  }</div>
                  <div className="view-kr-modalrating-text">
                    {krData.krName}
                  </div>
                </div>
                <div
                  style={{ paddingLeft: "16px" }}
                  id="keyErr"
                  className="e-input-group e-float-icon-left error-div"
                />
              </div>
              <div>
                <div
                  className="col-md-12 okr-interim-review-font"
                  style={showAll ? {
                    paddingLeft: "16px",
                    paddingBottom: 8,
                    fontSize: 14,

                  } : {
                    paddingLeft: "16px",
                    paddingBottom: 8,
                    fontSize: 14,
                    color: "#939399"
                  }}
                >
                  <span
                    className="tablinks col-1 col-lg-1 col-md-1 col-sm-1 col-xs-1 interim-review-draft-all-text"
                    style={{ cursor: "pointer", padding: 0, width: 60 }}
                    onClick={() => {
                      showGridData("all");
                    }}
                  >
                    All {`(${interimFeedbackData.allInterimFeedbackData.length})`}
                  </span>
                  {/* show draft conditions */}
                    {
                    props.interimType === "self" && ratingTabs === 'self-rating' &&
                    <span
                      className="tablinks col-2 col-lg-2 col-md-2 col-sm-2 col-xs-2 interim-review-draft-all-text"
                      style={{ cursor: "pointer", padding: 0, width: 70 }}
                      onClick={(e) => { showGridData("draft"); }}
                    >
                      Draft {`(${interimFeedbackData.draftInterimFeedbackData.length})`}
                    </span>
                    }
                    {
                    props.interimType === "sup" && ratingTabs === 'supervisor-rating' &&
                    <span
                      className="tablinks col-2 col-lg-2 col-md-2 col-sm-2 col-xs-2 interim-review-draft-all-text"
                      style={{ cursor: "pointer", padding: 0, width: 70 }}
                      onClick={(e) => { showGridData("draft"); }}
                    >
                      Draft {`(${interimFeedbackData.draftInterimFeedbackData.length})`}
                    </span>
                    }
                    {
                    props.interimType === "tp" && ratingTabs === 'third-party-rating' &&
                    <span
                      className="tablinks col-2 col-lg-2 col-md-2 col-sm-2 col-xs-2 interim-review-draft-all-text"
                      style={{ cursor: "pointer", padding: 0, width: 70 }}
                      onClick={(e) => { showGridData("draft"); }}
                    >
                      Draft {`(${interimFeedbackData.draftInterimFeedbackData.length})`}
                    </span>
                    }

                </div>
              </div>

              <div className="form-row">
                <div style={{ paddingLeft: 16, paddingRight: 16, paddingTop: 5 , paddingBottom: 16}}>
                  {showAll ? <div
                    className="new4 hline-bottom "
                    style={{ marginLeft: 0, marginTop: 5, width: 55 }}
                  /> : <div
                    className="new4 hline-bottom "
                    style={{ marginLeft: 60, marginTop: 5, width: 65 }}
                  />}
                  <div style={{ border: '1px solid #eee' }} />
                  <div
                    style={{
                      paddingLeft: 16,
                      paddingRight: 16,
                      marginTop: -7,
                      marginBottom: 0
                    }}
                  />
                </div>
              </div>
            </form>
          </div>
        {showAll ?
          <div className="form-row">
            <div className="col-md-12 interim-feedback-grid-all" style={{ paddingTop: 8}}>
              {interimFeedbackData.allInterimFeedbackData.length > 0 && <InterimFeedbackGridData props={props} interimFeedbackData={interimFeedbackData.allInterimFeedbackData} selectedTab={'All'} ratingTabs={props.ratingTabs} OKRType={props.OKRType} />}
            </div>
          </div> : <div className="form-row">
            <div className="col-md-12 interim-feedback-grid" style={{ paddingTop: 8}}>
              {interimFeedbackData.draftInterimFeedbackData.length > 0 && <InterimFeedbackGridData props={props} interimFeedbackData={interimFeedbackData.draftInterimFeedbackData} selectedTab={'Draft'} ratingTabs={props.ratingTabs} OKRType={props.OKRType} />}
            </div>
          </div>
        }
        </DialogContent>
        {
      props.interimType === "self" && ratingTabs === 'self-rating' &&
      <DialogActions>
            <div style={{ padding: "0px 24px 16px 0px", float: "right" }}>
              <Button
                style={{ width: "124px" }}
                className="remove-button-shadow"
                color="primary"
                variant="contained"
                onClick={() => setAddInterimPopup(true)}
              >
                Add New
              </Button>
            </div>
      </DialogActions>
        }
        {
       props.interimType === "sup" && ratingTabs === 'supervisor-rating' &&
      <DialogActions>
            <div style={{ padding: "0px 24px 16px 0px", float: "right" }}>
              <Button
                style={{ width: "124px" }}
                className="remove-button-shadow"
                color="primary"
                variant="contained"
                onClick={() => setAddInterimPopup(true)}
              >
                Add New
              </Button>
            </div>
      </DialogActions>
        }
        {
         props.interimType === "tp" && ratingTabs === 'third-party-rating' &&
      <DialogActions>
            <div style={{ padding: "0px 24px 16px 0px", float: "right" }}>
              <Button
                style={{ width: "124px" }}
                className="remove-button-shadow"
                color="primary"
                variant="contained"
                onClick={() => setAddInterimPopup(true)}
              >
                Add New
              </Button>
            </div>
      </DialogActions>
        }
      </Dialog>

      {addInterimPopup &&
              <AddEditInterimReviewModal
                closeAddEditIntrimFeedbackPopup={closeAddEditIntrimFeedbackPopup}
                type={"Add"}
                KRData={props.krData}
                OKRType={props.OKRType}
                ratingTabs={props.ratingTabs}
              />
      }
    </div>
  );
}
const mapStateToProps = ({ okrratingreducer, auth, meetingcategory }) => {
  const { authUser,  } = auth;
  const { categoryName } = meetingcategory;
  const {
    empId,
    fyId,
    monthOfRating,
    refreshInterimfeedbackflag,
    krData,
    interimType,
    isDraftClick,
    ratingTabs
  } = okrratingreducer;
  return {
    empId,
    fyId,
    monthOfRating,
    authUser,
    refreshInterimfeedbackflag,
    krData,
    categoryName,
    interimType,
    isDraftClick,
    ratingTabs
  };
};
export default connect(mapStateToProps, {})(
  InterimFeedBack
);
