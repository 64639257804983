import React, { useEffect, useState, } from "react";
import { connect } from "react-redux";
import { ComboBoxComponent } from "@syncfusion/ej2-react-dropdowns";
import { TextBoxComponent, NumericTextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { DatePickerComponent,TimePickerComponent } from "@syncfusion/ej2-react-calendars";
import LnAServices from "../../services/lna/lnaservice";
import { FormValidator } from "@syncfusion/ej2-inputs";
import { saveWorkDayWindowCluster, updateLnAData } from "../../redux/actions/lna/lnaaction";
import UploadDocuments from "../okrratingcomponents/uploaddocuments";
import { createImageFilepathForLPView } from "../okrratingcomponents/ratingcommonfun";
import ProgressBar from "../progressbar/progressbar";
import {
  effectiveStartDateVaidation,
  EndTimeGreaterThanStartTime,
  pleaseSelectffectiveStartDate,
  pleaseEnterFrequency,
  pleaseEnterStartTime,
  pleaseEnterWeeklyworkhours,
  pleaseEnterWorkdaysymbol,
  pleaseEnterWorkDayWindowName,
  pleaseSetMinHrs,
  pleasesetWorkHrs,
  regexOnlyLettersAndSpaces,
  StartTimeLessThanEndTime,
  pleaseEnterEndTime,
} from "../../assets/constants/constants";
import { getMySqlDateFormat } from "../../app/other/commonfunction";
const SAMPLECSS = `
input.e-input, textarea.e-input, .e-input-group, .e-input-group.e-control-wrapper {
  font-size: 12px;
}
`
function WorkDayWindow(props) {
  //NOSONAR

  const [changedData, setChangedData] = useState(false);
  const [initialLoad, setInitialLoad] = useState(false)
  const [state, setState] = useState({
    workdayWindowName: null,
    workdayWindowSymbol: "",
    symbolId: "",
    workdayWindowDescription: "",
    startTime: null,
    endTime: null,
    workHrs: null,
    minHrs: null,
    graceTime: "",
    noOfIinstances: null,
    workdayFrequency: "",
    workdayPolicyDoc: [],
    fyId: "",
    isActive: "1",
    workdayWindowUid: "0",
    effectiveStartDate: null,
    effectiveEndDate: null,
    weeklyWrkHrs: null,
    fileName:null,
    oldFileStatus: "",
  });
  let { editClusterId, authUser, openPopup } = props;

  let validationOptions = {
    rules: {
      workdayWindowErr: {
        required: [true, pleaseEnterWorkDayWindowName],
        regex: [regexOnlyLettersAndSpaces, pleaseEnterWorkDayWindowName],
        minLength: [1, pleaseEnterWorkDayWindowName],
        maxLength: [50, "Should be maximum of 50 characters only."]
      },
      workdaySymbolErr: {
        required: [true, pleaseEnterWorkdaysymbol],
      },
      noOfInstancesErr: {
        min: [0, pleaseEnterWorkDayWindowName],
        max: [6, "Should be maximum of 6 instances only."]
      },
      frequencyErr: {
        required: [true, pleaseEnterFrequency],
      },
      weeklyhrsErr: {
        required: [true, pleaseEnterWeeklyworkhours],
      },
      effectiveStartDateErr: {
        required: [true, pleaseSelectffectiveStartDate],
      },
    },
  }


  useEffect(() => {
    props.updateLnAData({ popUpSource: 'Workday Window Master' })
  }, []);
  useEffect(() => {
    setTimeout(() => {
      props.setSubmitClick(saveWorkDayClusterData);
    }, 100);
  }, [state]);

  useEffect(() => {
    getData()
  }, [openPopup, editClusterId])

  useEffect(() => {
    if (props.lnaMasterFlag === "updated") {
      props.changePopUpHandler("close");
    }
  }, [props.lnaMasterFlag]);

  const getData = () => {
    if (openPopup === "add") {
      setState({
        workdayWindowName: null,
        workdayWindowSymbol: "",
        symbolId: "",
        workdayWindowDescription: "",
        startTime: null,
        endTime: null,
        workHrs: null,
        minHrs: null,
        graceTime: "",
        noOfIinstances: null,
        workdayFrequency: "",
        workdayPolicyDoc: [],
        fyId: "",
        isActive: "1",
        workdayWindowUid: "0",
        effectiveStartDate: null,
        effectiveEndDate: null,
        weeklyWrkHrs: null,
        fileName: null,
        oldFileStatus: "",
      })
    }
    else {
      editClusterId !== null &&
        (async () => {
          setInitialLoad(true)
          const workDayWindowClusterData = await LnAServices.getWorkdayWindowData(
            authUser,
            editClusterId
          )
          setState({
            workdayWindowName:
              workDayWindowClusterData.Result[0].workdayWindowName,
            workdayWindowSymbol:
              workDayWindowClusterData.Result[0].workdayWindowSymbol,
            workdayWindowDescription:
              workDayWindowClusterData.Result[0].workdayWindowDescription !== null
                ? workDayWindowClusterData.Result[0].workdayWindowDescription
                : "",
            startTime: workDayWindowClusterData.Result[0].startTime,
            endTime: workDayWindowClusterData.Result[0].endTime,
            workHrs: workDayWindowClusterData.Result[0].workHrs,
            minHrs: workDayWindowClusterData.Result[0].minHrs,
            graceTime: workDayWindowClusterData.Result[0].graceTime,
            noOfIinstances: workDayWindowClusterData.Result[0].noOfIinstances,
            workdayFrequency: workDayWindowClusterData.Result[0].frequency,
            fyId: workDayWindowClusterData.Result[0].fyId,
            isActive: workDayWindowClusterData.Result[0].isActive,
            workdayWindowUid: workDayWindowClusterData.Result[0].workdayWindowUid,
            symbolId: workDayWindowClusterData.Result[0].symbolId,
            effectiveStartDate:
              workDayWindowClusterData.Result[0].effectiveStartDate,
            effectiveEndDate: workDayWindowClusterData.Result[0].effectiveEndDate,
            weeklyWrkHrs: workDayWindowClusterData.Result[0].weeklyWrkHrs,
            fileName: workDayWindowClusterData.Result[0].fileName,
            oldFileStatus: workDayWindowClusterData.Result[0].fileName,
          });
          setTimeout(() => {
            const addFormObject = new FormValidator("#workdaywindow", validationOptions);
            addFormObject.validate();
            document.getElementById('minhrsErr').innerText = ''
            document.getElementById('workHrsErr').innerText = ''
            document.getElementById('startTimeErr').innerText = ''
            document.getElementById('endTimeErr').innerText = ''
            setInitialLoad(false)
          }, 200);
        })()

    }
  }

  const documentDetails = (currentTime, file) => {
    setState((prevState) => ({
      ...prevState,
      fileName: file,
      oldFileStatus: file,
    }));
  };

  const onChangeWorkDayClusterData = (e, unit) => {
    let options = {};
    switch (unit) {
      case "workdaywindowname":
        setState({ ...state, workdayWindowName: e.value.trim() });
        options = validationOptions.rules["workdayWindowErr"];
        break;

      case "symbol":
        setState((prevState) => ({ ...prevState, symbolId: e.itemData && e.itemData.Id }));
        options = validationOptions.rules['workdaySymbolErr']
        break;

      case "startTime":
        if (state.endTime !== null) {
          let hours1 = new Date(state.endTime).getHours()
          let hours2 = new Date(e).getHours()
          let minutes1 = new Date(state.endTime).getMinutes()
          let minutes2 = new Date(e).getMinutes()
          const date1 = new Date(2022, 0, 1, +hours1, +minutes1, 0);
          const date2 = new Date(2022, 0, 1, +hours2, +minutes2, 0);
          if (date1.getTime() <= date2.getTime()) {
            if (document.getElementById("startTimeErr")) {
              document.getElementById("startTimeErr").innerHTML = StartTimeLessThanEndTime;
            }
            setState({ ...state, startTime: null });
          } else {
            setState({ ...state, startTime: e.value });
            if (document.getElementById("startTimeErr")) {
              document.getElementById("startTimeErr").innerHTML = "";
              document.getElementById("endTimeErr").innerHTML = "";
      

            }
          }
        } else {
          setState({ ...state, startTime: e.value });
          if (document.getElementById("startTimeErr")) {
            document.getElementById("startTimeErr").innerHTML = "";
     
          }
        }
        break;
      case "endTime":
        if (state.startTime !== null) {
          let hours1 = new Date(state.startTime).getHours()
          let hours2 = new Date(e).getHours()
          let minutes1 = new Date(state.startTime).getMinutes()
          let minutes2 = new Date(e).getMinutes()
          const date1 = new Date(2022, 0, 1, +hours1, +minutes1, 0);
          const date2 = new Date(2022, 0, 1, +hours2, +minutes2, 0);
          if (date1.getTime() >= date2.getTime()) {

            if (document.getElementById("endTimeErr")) {
              document.getElementById("endTimeErr").innerHTML = EndTimeGreaterThanStartTime;
              setState({ ...state, endTime: null });
            }

          } else {
            setState({ ...state, endTime: e.value });
            if (document.getElementById("endTimeErr")) {
              document.getElementById("endTimeErr").innerHTML = '';
            }
            if (document.getElementById("startTimeErr")) {
              document.getElementById("startTimeErr").innerHTML = '';
            }
          }
        }
        break;
      case "workHrs":
        setState({ ...state, workHrs: e.value });
        if (document.getElementById("workHrsErr")) {
          document.getElementById("workHrsErr").innerHTML = "";
        }
        break;
      case "minhrs":
        setState({ ...state, minHrs: e.value });
        if (document.getElementById("minhrsErr")) {
          document.getElementById("minhrsErr").innerHTML = "";
        }
        break;
      case "workdayDescription":
        setState({ ...state, workdayWindowDescription: e.value && e.value.trim() });
        break;
      case "weeklyWrkHrs":
        setState({ ...state, weeklyWrkHrs: e.value });
        if (e.value !== null) {
          document.getElementById("weeklyhrsErr").innerHTML = "";
        }
        options = validationOptions.rules['weeklyhrsErr'];
        break;
      case "effectiveStartDate":
        setState({ ...state, effectiveStartDate: e.value });
        options = validationOptions.rules['effectiveStartDateErr'];
        if (document.getElementById("effectiveStartDateErr")) {
          document.getElementById("effectiveStartDateErr").innerHTML = "";
        }
        break;
      case "effectiveEndDate":
        setState({ ...state, effectiveEndDate: e.value });
        break;
      case "graceTime":
        setState({ ...state, graceTime: e.value });
        break;
      case "noOfInstance":
        setState({ ...state, noOfIinstances: e.value });
        options = validationOptions.rules['noOfInstancesErr'];
        break;
      case "frequency":
        setState({ ...state, workdayFrequency: e.itemData.Id });
        options = validationOptions.rules['frequencyErr'];
        break;
      default:
        break;
    }


    if (options !== null && options !== undefined) {
      if (Object.keys(options).length > 0) {
        setTimeout(() => {
          const addFormObject = new FormValidator("#workdaywindow", options);
          addFormObject.validate();
        }, 100);
      }
    }
  };

  const saveWorkDayClusterData = () => {
    // NOSONAR
    let {
      workdayWindowName: wwn,
      workdayWindowUid,
      symbolId: sid,
      workdayWindowDescription: wwd,
      startTime: st,
      endTime: et,
      workHrs: whrs,
      minHrs: mhrs,
      weeklyWrkHrs: wwhrs,
      graceTime: gt,
      noOfIinstances: noi,
      workdayFrequency: wdf,
      isActive,
      effectiveStartDate: esd,
      effectiveEndDate: end,
      fileName,
    } = state;

    let validateDates = true;
    if (state.startTime === "" || state.startTime === null) {
      document.getElementById("startTimeErr").innerHTML = pleaseEnterStartTime;
      validateDates = false;
    }
    if (state.endTime === "" || state.endTime === null) {
      document.getElementById("endTimeErr").innerHTML = pleaseEnterEndTime;
      validateDates = false;
    }

    if (
      state.startTime !== null &&
      state.endTime !== null
    ) {
      if (
        new Date(state.startTime) >= new Date(state.endTime)
      ) {
        document.getElementById("startTimeErr").innerHTML =
          StartTimeLessThanEndTime;
        validateDates = false;
      }
    }

    if (state.workHrs === "" || state.workHrs === null) {
      document.getElementById("workHrsErr").innerHTML = pleasesetWorkHrs;
      validateDates = false;
    }
    if (state.minHrs === "" || state.minHrs === null) {
      document.getElementById("minhrsErr").innerHTML = pleaseSetMinHrs;
      validateDates = false;
    }
    if  (state.effectiveStartDate !== null && state.effectiveEndDate !== null){
      if (new Date(state.effectiveStartDate) >= new Date(state.effectiveEndDate)) {
        document.getElementById("effectiveStartDateErr").innerHTML = effectiveStartDateVaidation;
        validateDates = false;
      }
    }
    const addFormObject = new FormValidator("#workdaywindow", validationOptions);
    if (addFormObject.validate() && validateDates) {
      setInitialLoad(true)
      let workClusterSaveData = {
        userId: authUser,
        workdayWindowName: wwn,
        workdayWindowUid,
        type: workdayWindowUid > 0 ? "EDIT" : "ADD",
        symbolId: sid,
        workdayWindowDescription: wwd,
        startTime: `${new Date(st).getHours()}:${new Date(
          st
        ).getMinutes()}`,
        endTime: `${new Date(et).getHours()}:${new Date(
          et
        ).getMinutes()}`,
        workHrs: `${new Date(whrs).getHours()}:${new Date(
          whrs
        ).getMinutes()}`,
        minHrs: `${new Date(mhrs).getHours()}:${new Date(
          mhrs
        ).getMinutes()}`,
        graceTime: gt,
        noOfIinstances: noi === null ? 0 : noi,
        workdayFrequency: wdf,
        weeklyWorkHrs: wwhrs,
        isActive: isActive,
        effectiveStartDate:
          esd !== null
            ? getMySqlDateFormat(esd)
            : null,
        effectiveEndDate:
          end !== null
            ? getMySqlDateFormat(end)
            : null,
        fileName,
      };
      props.saveWorkDayWindowCluster(workClusterSaveData);
      setTimeout(() => {
        setInitialLoad(false)
      }, 10)

    }

  };
  const descriptionTextBox = `.e-outline textarea, textarea.e-outline, .e-outline.e-float-input textarea, .e-outline.e-float-input.e-control-wrapper textarea, .e-outline.e-float-input.e-input-group textarea {
    box-sizing: border-box;
    min-height: 100px;
  }`
  const removeFile = () => {
    setState(preState => ({ ...preState, fileName: "" }))
  }
  const selectedFiles = (fileNms) => {
    try {
      setState((preState) => ({ ...preState, workdayPolicyDoc: fileNms }));
      setTimeout(() => {
        setChangedData(!changedData);
      });
    } catch (error) { }
  };

  let {
    workdayWindowName,
    symbolId,
    workdayWindowDescription,
    startTime,
    endTime,
    workHrs,
    minHrs,
    weeklyWrkHrs,
    graceTime,
    noOfIinstances,
    workdayFrequency,
    effectiveEndDate,
    effectiveStartDate,
  } = state;
  const getOneDayForward = (dt) => {
    let date = dt
    date.setDate(date.getDate() + 1)
    return date
  }
  return (
    <div id="workdaywindow"
      style={{ height: "61.5vh", overflowY: "scroll", transition: "width 5s" }}
    >
      <style>{SAMPLECSS}</style>
      <div style={{ padding: "16px" }}>
        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12  atc-no-paddding">
          <TextBoxComponent
            data-msg-containerid="workdayWindowErr"
            id="workdayWindow"
            value={workdayWindowName}
            name="workdayWindowErr"
            placeholder="Workday Window Name * "
            floatLabelType="Auto"
            autocomplete={'off'}
            cssClass="e-outline minimize-height"
            onChange={(e) => onChangeWorkDayClusterData(e, "workdaywindowname")}
            htmlAttributes={{ maxlength: 50 }}
          />
          <div id="workdayWindowErr" className="e-input-group error-div" />
        </div>
        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12  atc-no-paddding">
          <ComboBoxComponent
            id="symbol"
            dataSource={props.symbolData.workdayWindowData}
            fields={{ text: "Name", value: "Id" }}
            value={symbolId}
            data-msg-containerid="workdaySymbolErr"
            name="workdaySymbolErr"
            allowCustom={false}
            floatLabelType="Auto"
            cssClass="e-outline minimize-height"
            placeholder="Symbol *"
            change={(e) => onChangeWorkDayClusterData(e, "symbol")}
          />
          <div id="workdaySymbolErr" className=" e-input-group rror-div" />
        </div>
        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12   atc-no-paddding weekdayDescription">
          <style>{descriptionTextBox}</style>
          <TextBoxComponent
            style={{ minHeight: 100 }}
            id={'workdayDescription'}
            placeholder={"Description"}
            autocomplete={'off'}
            cssClass="e-outline minimize-height textarea"
            floatLabelType="Auto"
            name="workdayDescriptionErr"
            data-msg-containerid="workdayDescriptionErr"
            multiline
            inputProps={{
              required: true,
            }}
            htmlAttributes={{ minlength: 0, maxlength: 500 }}
            onChange={(e) => onChangeWorkDayClusterData(e, "workdayDescription")}
            value={workdayWindowDescription}
          />
          <div id="workdayDescriptionErr" className="error-div" />
        </div>

        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12  mt-md atc-no-paddding">
          <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6 atc-no-padding-left">
            <TimePickerComponent
              style={{ width: "100%" }}
              id="startTime"
              name="startTimeErr"
              placeholder="Start Time *"
              data-msg-containerid="startTimeErr"
              format="HH:mm"
              floatLabelType="Auto"
              cssClass="e-outline"
              allowEdit={false}
              step={30}
              value={startTime}
              onChange={(e) => onChangeWorkDayClusterData(e, "startTime")}

            />
            <div
              id="startTimeErr"
              className="e-input-group e-float-icon-left error-div"
            />
          </div >
          <div
            className="col-xs-6 col-sm-6 col-lg-6 col-md-6 "
            style={{ paddingRight: 0 }}
          >
            <div>
              <TimePickerComponent
                style={{ width: "100%" }}
                id="endTime"
                name="endTimeErr"
                placeholder="End Time *"
                data-msg-containerid="endTimeErr"
                format="HH:mm"
                floatLabelType="Auto"
                cssClass="e-outline"
                allowEdit={false}
                step={30}
                value={endTime}
                onChange={(e) => onChangeWorkDayClusterData(e, "endTime")}

              />
            </div>

            <div id="endTimeErr" className="e-input-group e-float-icon-left error-div" />
          </div >
        </div >

        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12   atc-no-paddding">
          <TimePickerComponent
            style={{ width: "100%" }}
            id="workHrs"
            name="workHrsErr"
            placeholder="Work Hours *"
            data-msg-containerid="workHrsErr"
            format="HH:mm"
            floatLabelType="Auto"
            cssClass="e-outline"
            allowEdit={false}
            step={30}
            min={(new Date('8/3/2017 01:00'))}
            max={new Date('8/3/2017 08:00 ')}
            value={workHrs}
            onChange={(e) => onChangeWorkDayClusterData(e, "workHrs")}

          />
          <div
            id="workHrsErr"
            className="e-input-group e-float-icon-left error-div"
          />
        </div>
        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12  atc-no-paddding">
          <TimePickerComponent
            style={{ width: "100%" }}
            id="minhrs"
            name="minhrsErr"
            placeholder="Minimum Hours *"
            data-msg-containerid="minhrsErr"
            format="HH:mm"
            floatLabelType="Auto"
            cssClass="e-outline"
            allowEdit={false}
            step={30}
            value={minHrs}
            onChange={(e) => onChangeWorkDayClusterData(e, "minhrs")}

          />
          <div
            id="minhrsErr"
            className="e-input-group e-float-icon-left error-div"
          />
        </div >

        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 atc-no-paddding">
          <NumericTextBoxComponent
            data-msg-containerid="weeklyhrsErr"
            id="weeklyhrsId"
            name="weeklyhrsErr"
            autocomplete={'off'}
            value={weeklyWrkHrs}
            min={0}
            max={48}
            multiline={false}
            placeholder="Weekly Work Hours *"
            floatLabelType="Auto"
            cssClass="e-outline minimize-height e-custom-height"
            change={(e) => onChangeWorkDayClusterData(e, "weeklyWrkHrs")}
          />

          <div
            id="weeklyhrsErr"
            className="e-input-group e-float-icon-left error-div"
          />
        </div>

        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 atc-no-paddding">
          <NumericTextBoxComponent
            id="graceTime"
            value={graceTime}
            data-msg-containerid="graceTimeErr"
            name="graceTimeErr"
            min={0}
            max={60}
            format={'##'}
            placeholder="Grace Time In Minutes "
            floatLabelType="Auto"
            cssClass="e-outline minimize-height"
            change={(e) => onChangeWorkDayClusterData(e, "graceTime")}
            htmlAttributes={{ maxlength: 100 }}
          />
          <div id="graceTimeErr" className="error-div" />
        </div>

        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12  mt-lg atc-no-paddding">
          <NumericTextBoxComponent
            data-msg-containerid="noOfInstancesErr"
            id="noOfInstances"
            min={0}
            max={6}
            value={noOfIinstances}
            format={'##'}
            decimals={0}
            name="noOfInstancesErr"
            placeholder="No. Of Instances"
            floatLabelType="Auto"
            cssClass="e-outline minimize-height"
            change={(e) => onChangeWorkDayClusterData(e, "noOfInstance")}
            htmlAttributes={{ maxlength: 100 }}
          />
          <div id="noOfInstancesErr" className="error-div" />
        </div>
        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12  mt-lg atc-no-paddding">
          <ComboBoxComponent
            id="frequency"
            dataSource={[
              { Name: "Yearly", Id: "4" },
              { Name: "Half Yearly", Id: "3" },
              { Name: "Quarterly", Id: "2" },
              { Name: "Monthly", Id: "1" },
            ]}
            fields={{ text: "Name", value: "Id" }}
            value={workdayFrequency}
            data-msg-containerid="frequencyErr"
            name="frequencyErr"
            floatLabelType="Auto"
            cssClass="e-outline minimize-height"
            placeholder="Frequency *"
            allowCustom={false}
            select={(e) => onChangeWorkDayClusterData(e, "frequency")}
          />
          <div
            id="frequencyErr"
            className="e-input-group e-float-icon-left error-div"
          />
        </div>

        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
          <div
            className="col-xs-6 col-sm-6 col-lg-6 col-md-6 "
            style={{ paddingLeft: 0 }}
          >
            <DatePickerComponent
              id="effectiveStartDate"
              name="effectiveStartDateErr"
              data-msg-containerid="effectiveStartDateErr"
              format="dd-MMM-yyyy"
              value={effectiveStartDate}
              floatLabelType="Auto"
              cssClass="e-outline minimize-height"
              placeholder="Effective Start Date *"
              firstDayOfWeek={1}
              change={(e) =>
                onChangeWorkDayClusterData(e, "effectiveStartDate")
              }
            />
            <div
              id="effectiveStartDateErr"
              className="e-input-group e-float-icon-left error-div"
            />
          </div>
          <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6 " style={{ paddingRight: 0 }}>
            <DatePickerComponent
              id="effectiveEndDate"
              fields={{ text: "Name", value: "Id" }}
              value={effectiveEndDate}
              data-msg-containerid="effectiveEndDateErr"
              name="effectiveEndDateErr"
              min={state.effectiveStartDate ? getOneDayForward(new Date(state.effectiveStartDate)) : null}
              format="dd-MMM-yyyy"
              floatLabelType="Auto"
              cssClass="e-outline minimize-height"
              placeholder="Effective End Date"
              firstDayOfWeek={1}
              change={(e) => onChangeWorkDayClusterData(e, "effectiveEndDate")}
            />
            <div
              id="effectiveEndDateErr"
              className="e-input-group e-float-icon-left error-div"
            />
          </div>

          <div className="col-sm-12 col-md-12 col-lg-12 mt-lg mb-lg">
            <div
              style={{
                fontSize: ".95rem",
                color: "#939399",
                fontWeight: 500,
                padding: ".5rem 0",
                marginTop: "-2rem",
              }}
            >
              Attach Policy
            </div>

            <UploadDocuments
              type={"workday"}
              module={'lna'}
              allowmultiple={false}
              selectedFiles={selectedFiles}
              fileList={[]}
              apiUrl={"lnaApiUrl"}
              documentDetails={documentDetails}
            />

            {state.fileName !== null && state.oldFileStatus !== null ? (
              <div style={{ paddingLeft: 8 }}>
                {createImageFilepathForLPView(
                  [].concat(state.fileName),
                  "document/lna/workday",
                  "lnaApiUrl", removeFile
                )}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div >
      <div className="loader-new1" id="spinnerContainer" >
        {initialLoad && <ProgressBar />}
      </div>
    </div >
  );
}

const mapStateToProps = ({ okrratingreducer, auth, lnareducer }) => {
  const { authUser } = auth;
  const { fyId } = okrratingreducer;
  const { symbolData, lnaMasterFlag } = lnareducer;

  return { authUser, fyId, symbolData, lnaMasterFlag };
};

export default connect(mapStateToProps, { saveWorkDayWindowCluster, updateLnAData })(
  WorkDayWindow
);
