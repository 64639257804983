import { getMySqlDateFormat } from '../../../app/other/commonfunction';
import {
    UPDATE_LNA_REDUX,

} from '../../constant/lna/lnaconstant';

const currentYear = new Date().getFullYear();

const INIT_STATE = {
    officeCountry: [],
    officeLocation: [],
    leaveTypeData: [],
    relieverData: [],
    applyForData: [],
    masterCluster: [],
    teamMemberId: [],
    regularizationType: [],
    attendanceType: [],
    leaveType: null,
    attendenceType: null,
    leaveStartDate: null,
    leaveStartDateEdit: null,
    leaveEndDateEdit: null,
    leaveEndDate: null,
    applyFor: null,
    appliedSlot: null,
    leaveReason: "",
    notificationTo: null,
    notificationToArr: [],
    reliever: null,
    leaveBalanceData: [],
    leaveApplyStatus: "",
    lnaMasterFlag: '',
    holidayClusterFlag: '',
    symbolData: {
        attendanceData: [], workdayWindowData: [], weekdayData: [], dayBreakData: [], attendanceSourceData: [], leaveData: [],
        holidayData: [], operationalHolidayData: [], statutoryData: [], exceptionalAttdData: [], workingdayData: [], colourData: [], roleData: []
    },

    dayBreakData: [],
    colourMaster: [],
    companyName: [],
    leaveApplicationId: 0,
    masterLeave: [],
    fyId: null,
    leaveApprovalInfo: {},
    leaveApplyLoadName: 'leave-tabs',
    selectedTab: "",
    leaveData: {},
    appliedDays: null,
    leaveId: 0,
    dayBreakText: '',
    leaveApprovelStatus: '',
    attachmentName: '',
    leaveBalanceStatus: "",
    viewMode: false,
    compOffData: [],
    leaveTransferFlag: '',
    compOffClusterFlag: '',
    leaveRegularizationFlag: '',
    weekReportMonths: [],
    dayBreakBackgroundColor: '',
    updateFinancialYear: '',
    financialYearData: [],
    popUpSource: '',
    // optionalHolidayFlag: "",
    lastUpdateMasterId: 0,
    holidayLoadingStatus: "",
    leaveApprovalMessage: "",
    from: '',
    leaveTypeList: [],
    viewClicked: false,
    reloadChart: false,
    selectedTeamMember: [],
    selectedTeamMemberId: null,
    startMonth: getMySqlDateFormat(new Date()).split(' ')[0],
    endMonth: getMySqlDateFormat(new Date()).split(' ')[0],
    startMonthMonthlyReport: null,
    MainMonthlyReportData: [],
    starting: false,
    teamMemberYearlyDropDown: [],
    leaveCancelStatus: "",
    backgroundSyncId: 0,
    holidayLeaveId: 0,
    weekOrMonth: 'Weekly',
    currentAndFinancialYear: [],
    fyData: null,
    onLeaveView: 'thisMonth',
    showMore: false,
    allCountry: [],
    allState: [],
    holidayCountryData: [],
    baseCountry: [],
    isLoading: false,
    holidayLoader: false
}

// eslint-disable-next-line
export default (state = INIT_STATE, action) => { //NOSONAR
    switch (action.type) { // NOSONAR
        case UPDATE_LNA_REDUX:

            return {
                ...state,
                ...action.payload
            }
        default:
            return state;
    }
}
