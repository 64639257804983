import './default.css';
import * as React from 'react';
import { MainClass } from '../../../../src/mainclass';
import { connect } from 'react-redux';
import OrgMaster from '../../containers/admin/orgchartmaster'
import OrgMapping from '../../containers/admin/orgchartmapping'
import UserRoleMapping from '../../containers/admin/userrolemapping';
import RoleMaster from '../../containers/admin/rolemaster';
import ModuleMaster from '../../containers/admin/modulemaster';
import { USER_ROLE_MAPPING, ROLE_MASTER, MODULE_MASTER, ORG_MASTER, ORG_MAPPING, ADMIN_MODULE } from "../../../redux/constant/meetingcategoryconstant";
import { setMeetingMaster } from '../../../redux/actions/meetingmasteraction';
import { getTMLCUsers } from "../../other/commonlayoutfunction";
import queryString from "query-string";
import { dencrypt } from '../../other/commonfunction'
import AdminmContainer from '../../containers/admin/admincontainer';

class AdminLayout extends MainClass {
    constructor(props) {
        super(props)
        this.state = {
            isLoaded: false,
            moduleId: 0
        }
    }
    async componentDidMount() {
        this.setModuleId()
        if (this.props.othersCheckAvailablity < 1) {
            const othersCheckAvailablityData = await getTMLCUsers(this.props.authUser);
            this.props.setMeetingMaster(othersCheckAvailablityData);
        }
    }

    setModuleId() {
        try {
            // Set App Id
            const { moduleId } = this.state
            const params = queryString.parse(this.props.location.search);
            if (typeof params.id !== 'undefined' && params.id !== null) {
                if (moduleId !== dencrypt(params.id))
                    this.setState({ moduleId: dencrypt(params.id) })
            }
        } catch (error) {

        }
    }

    componentDidUpdate(newProps) {
        try {
            this.setModuleId()
        } catch (error) {
        }
    }

    renderSwitch() {
        const { categoryName } = this.props
        const { moduleId } = this.state
        let activeModule = (moduleId === 0 ? categoryName : moduleId).toString()
        switch (activeModule) {
            case USER_ROLE_MAPPING:
                return <UserRoleMapping />;
            case ROLE_MASTER:
                return <RoleMaster />;
            case MODULE_MASTER:
                return <ModuleMaster />;
            case ORG_MASTER:
                return (
                    <OrgMaster props={this.props} />
                )
            case ORG_MAPPING:
                return <OrgMapping props={this.props} />;
            case ADMIN_MODULE:
                return <AdminmContainer props={this.props} />
            default:
                return <UserRoleMapping />;
        }
    }
    render() {
        return (
            <div style={{ height: '100%', width: '100%',backgroundColor:"#F2F2F2" }}>

                {this.renderSwitch()}
            </div>
        );
    }
}

const mapStateToProps = ({ auth, meetingcategory, meetingmaster }) => {
    const { authUser } = auth;
    const { categoryName } = meetingcategory;
    const {
        meetingType,
        meetingSubType,
        othersCheckAvailablity
    } = meetingmaster;
    return {
        authUser,
        categoryName,
        meetingType,
        meetingSubType,
        othersCheckAvailablity
    }
};
export default connect(mapStateToProps, { setMeetingMaster })(AdminLayout);
