import React from "react";
import { Button, Dialog } from "@material-ui/core";
import { RecurrenceEditorComponent } from "@syncfusion/ej2-react-schedule";
import { connect } from "react-redux";
import Paper from "@material-ui/core/Paper";
import Draggable from "react-draggable";
import { CheckBoxComponent } from "@syncfusion/ej2-react-buttons";
import { updateMeetingBookingState } from "../../redux/actions/meetingbookingaction";
import { MainClass } from "../../mainclass";
import ProgressBar from "../progressbar/progressbar";
import { defRecurringMaximumCount } from "../../config/config";
import {
  DialogTitle,
  DialogContent,
  DialogActions,
} from "../customdialog/customdialog";
import { getDate } from "../../app/other/commonfunction"
function PaperComponent(props) {
  return (
    <Draggable cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper
        // eslint-disable-next-line
        {...props} />
    </Draggable>
  );
}

class MeetingRecurrence extends MainClass {
  constructor(props) {
    super(props);
    this.handleConfirmNo = this.handleConfirmNo.bind(this);
    this.handleRemoveRecurrence = this.handleRemoveRecurrence.bind(this);
    this.handleConfirmYes = this.handleConfirmYes.bind(this);
    this.state = {
      progress: true,
    };
  }

  rendereComplete() {
    setTimeout(() => {
      if (typeof this.recObject !== "undefined" && this.recObject !== null) {
        this.refresh();
        if (this.props.recurring !== "")
          this.recObject.setRecurrenceRule(this.props.recurring);
        else this.recObject.setRecurrenceRule("FREQ=DAILY;INTERVAL=1;COUNT=1");
        this.setState({ progress: false });
      }
    }, 1000);
  }

  handleRemoveRecurrence = () => {
    this.recObject.setRecurrenceRule("FREQ=DAILY;INTERVAL=1;COUNT=1");
  };

  handleConfirmNo = () => {
    this.props.handleConfirmNo();
  };

  handleConfirmYes = () => {
    if (this.recObject.value.includes("COUNT=")) {
      const start = this.recObject.value.indexOf("COUNT=") + 6;
      let count = this.recObject.value.substring(
        start,
        this.recObject.value.length - 1
      );
      count = parseInt(count, 10);
      if (count > defRecurringMaximumCount) {
        document.getElementById(
          "recurenceMeetingCountError"
        ).innerHTML = `* Please select count less than ${defRecurringMaximumCount}.`;
      } else {
        //For Zoom recurring Dates
        const arrDates = [];
        const dates = this.recObject.getRecurrenceDates(
          new Date(this.props.meetingDate),
          //this.props.recurring,
          this.recObject.value,
          null,
          defRecurringMaximumCount,
          null
        );
        for (let dt of dates) {
          arrDates.push(getDate(new Date(dt)));
        }
        this.props.setCurrentRecurring(this.recObject.value);
        setTimeout(() => {
          this.props.updateMeetingBookingState({
            recurring: this.recObject.value,
            meetingFrequency: 2,
            zoomRecurringMeetingDates: arrDates.toString()
          });
          this.props.handleConfirmYes();
        }, 500)

      }
    } else {
      //For Zoom recurring Dates
      const arrDates = [];
      const dates = this.recObject.getRecurrenceDates(
        new Date(this.props.meetingDate),
        //this.props.recurring,
        this.recObject.value,
        null,
        defRecurringMaximumCount,
        null
      );
      for (let dt of dates) {
        arrDates.push(getDate(new Date(dt)));
      }
      this.props.setCurrentRecurring(this.recObject.value);
      setTimeout(() => {//NOSONAR
        this.props.updateMeetingBookingState({
          recurring: this.recObject.value,
          meetingFrequency: 2,
          zoomRecurringMeetingDates: arrDates.toString()
        });
        this.props.handleConfirmYes();
      })

    }

  };

  onChange = () => {
    this.refresh();
  };

  refresh = () => {
    try {
      if (this.recObject.endType.listData.length === 3) {
        this.recObject.endType.liCollections[0].style.display = "none";
        this.recObject.untilDateObj.format = "dd-MMM-yyyy";
        this.recObject.untilDateObj.allowEdit = false;
        this.refreshElement(this.recObject.element.children[0]);
      }
    } catch (error) {
    }
  };

  refreshElement(element) {
    if (typeof element.children !== "undefined") {
      for (let i = 0; i <= element.children.length - 1; i += 1) {
        if (
          !element.children[i].classList.contains("e-outline") &&
          element.children[i].classList.contains("e-input-group")
        ) {
          element.children[i].classList.add("e-outline");
        }
        if (element.children[i].classList.contains("e-date-icon"))
          // eslint-disable-next-line
          element.children[i].style.marginTop = "20px";

        if (element.children[i].classList.contains("e-repeat-content"))
          // eslint-disable-next-line
          element.children[i].style.marginBottom = "20px";
        this.refreshElement(element.children[i]);
      }
    }
  }

  // Author By:- Prashant Waphare | 14-08-2020 | use for exclude holiday
  getExcludeSundayHoliday = (e) => {
    if (e.checked === true) {
      this.props.updateMeetingBookingState({ getExcludeSundayHoliday: 1 });
    } else {
      this.props.updateMeetingBookingState({ getExcludeSundayHoliday: 0 });
    }
  };

  render() {
    const maxDate = new Date(this.props.meetingDate);
    maxDate.setFullYear(maxDate.getFullYear() + 1);
    const startCalDate = new Date(this.props.meetingDate);
    const style = `
      #RecurrenceEditor:has(.e-primary) .e-primary {
        background-color: #f94f5e !important;
        color: #fff !important;
    }`
    return (
      <>
        <Dialog
          open={this.props.open}
          onClose={this.handleConfirmNo}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          disableEscapeKeyDown
          PaperComponent={PaperComponent}
        >
          <style>{style}</style>
          <DialogTitle id="alert-dialog-title" onClose={this.handleConfirmNo}>
            Recurring Details
          </DialogTitle>
          <DialogContent dividers>
            <div>
              {this.state.progress && <ProgressBar />}
              <div style={{ display: this.state.progress ? "none" : "block" }}>
                <RecurrenceEditorComponent
                  id="RecurrenceEditor"
                  ref={(t) => (this.recObject = t)}
                  frequencies={["daily", "weekly", "monthly"]}
                  dateFormat="dd-MMM-yyyy"
                  minDate={startCalDate}
                  maxDate={maxDate}
                  firstDayOfWeek={1}
                  maximumCount={defRecurringMaximumCount}
                  change={this.onChange.bind(this)}
                />
                {/* Author By:- Prashant Waphare | 14-08-2020 | use for exclude holiday */}
                <CheckBoxComponent
                  label="Exclude Holidays, Sundays & Non-Working Saturdays."
                  value={this.props.getExcludeSundayHoliday}
                  checked={this.props.getExcludeSundayHoliday}
                  change={this.getExcludeSundayHoliday}
                />
              </div>
            </div>
            <div id="recurenceMeetingCountError" className="error-div" />
          </DialogContent>
          <DialogActions>
            <Button
              style={{ background: "#146f68", color: "#fff", fontSize: "12px" }}
              onClick={this.handleRemoveRecurrence}
              color="inherit"
              variant="contained"
            >
              Reset Recurrence
            </Button>

            <Button
              onClick={this.handleConfirmYes}
              color="primary"
              autoFocus
              variant="contained"
            >
              OK
            </Button>
            <Button
              onClick={this.handleConfirmNo}
              color="secondary"
              variant="contained"
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

const mapStateToProps = ({ meetingbooking }) => {
  const { recurring, meetingDate, getExcludeSundayHoliday } = meetingbooking;
  return { recurring, meetingDate, getExcludeSundayHoliday };
};

export default connect(mapStateToProps, {
  updateMeetingBookingState,
})(MeetingRecurrence);
