import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import LeaveApplicationTypeOfLeave from "./leaveapplicationtypeofleave";
import ProgressBar from "../../../progressbar/progressbar";
import {
  updateLnAData,
  changeLeaveApprovalStatus,
} from "../../../../redux/actions/lna/lnaaction";
import ApprovalSubmit from "../leaveapproval/approvalsubmit";
import Leavetabs from "../leavesbalance/leavetabs";
import ConfirmationPopup from "../../../../utilities/confirmationpopup";
import { cracterLimit } from "../../../../app/other/commonfunction";

function LeaveApproved(props) {
  // leave-approval-footer
  const [title, setTitle] = useState("");
  const [rejectReasonErr, setRejectReasonErr] = useState("");
  const [state, setState] = useState({
    leaveId: 0,
    status: "",
    open: false,
    title: "",
    message: "",
    reason: "",
    updateHeight: window.innerHeight - 196,
    containerHeight: window.innerHeight - 225,
  });
  const handleTextArea = (e) => {
    setState({ ...state, reason: e.value });
  };

  const handleError = () => {
    if (!state.reason)
      setRejectReasonErr(
        "Please provide appropriate reason for rejecting leave application."
      );
    else setRejectReasonErr("");
  };

  useEffect(() => {
    window.addEventListener("resize", updateDimensions()); /**NOSONAR */
  }, []);
  const updateDimensions = () => {
    const updateHeight = window.innerHeight - 196;
    const containerHeight = window.innerHeight - 225;
    setState((preState) => ({
      ...preState,
      updateHeight: updateHeight,
      containerHeight: containerHeight,
    }));
  };
  useEffect(() => {
    if (props) {
      const { type } = props;
      if (type === "leaveApprovalSubmit") {
        setTitle("Leave Approval Application");
      }

      if (type === "leaveTransferApprovalSubmit") {
        setTitle("Leave Transfer Application");
      }

      if (type === "swipeApprovalSubmit") {
        setTitle("Regularization Application");
      }
      if (type === "compOffApprovalSubmit") {
        setTitle("Comp. Off Application");
      }
      if (type === "specialAttendanceApprovalSubmit") {
        setTitle("Special Attendance Application");
      }
    }
  }, [props.singleLeaveInfo, props.isLoading]);

  useEffect(() => {
    if (Object.keys(props.leaveApprovalInfo).length > 0) {
      updateLeaveData();
      props.updateGridWithStatus();
    }
  }, [props.leaveApprovalInfo]);

  const handleConfirmYes = () => {
    const { leaveId, status, reason } = state;
    const { authUser, selectedTab } = props;
    props.changeLeaveApprovalStatus({
      leaveId,
      userId: authUser,
      status,
      type: selectedTab,
      reason,
    });
  };

  const updateLeaveData = () => {
    setState({
      ...state,
      leaveId: 0,
      status: "",
      open: false,
      title: "",
      message: "",
      reason: "",
    });
    setRejectReasonErr("");
    setRejectReasonErr("");
    cracterLimit(0, "leaveAppReasonCount", "500");
  };
  const handleConfirmNo = () => {
    updateLeaveData();
  };
  const handleApproved = () => {
    const { leaveId } = props.singleLeaveInfo;
    setState({
      ...state,
      leaveId,
      status: "2",
      open: true,
      title: "Approve",
      reason: "",
      message: "Are you sure you want to approve?",
    });
  };

  const handleReject = (reason) => {
    const { leaveId } = props.singleLeaveInfo;
    if (state.reason.trim().length <= 0) {
      setRejectReasonErr(
        "Please provide appropriate reason for rejecting leave application."
      );
      return;
    }
    setRejectReasonErr("");
    setState({
      ...state,
      leaveId,
      status: "3",
      open: true,
      title: "Reject",
      message: "Are you sure you want to reject?",
      reason: reason,
    });
  };
  const { tmName, selectedUserId } = props.singleLeaveInfo;

  return (
    <>
      {!props.isLoading && (
        <>
          <div className="regular-header p-md">{title + " - " + tmName}</div>

          <div
            className="container pl-md pr-md pt-md pb-sm"
            style={{
              // height: props.height - 195,
              height: state.updateHeight,
              display: "flex",
              width: "100%",
              overflowY: "hidden",
              overflowX: "hidden",
            }}
          >
            <div
              className="col-7 col-lg-7 col-md-7 col-sm-7 col-xs-7 lna-container-border overflow-auto"
              style={{
                paddingLeft: 0,
                paddingRight: 0,
                paddingBottom: "10px",
                overflow: "auto",
              }}
            >
              {/* <LeaveApplicationTypeOfLeave height={state.containerHeight} singleLeaveInfo={props.singleLeaveInfo} authUser={props.authUser} applicationId={props.singleLeaveInfo.leaveId} type={props.type} handleError={handleReject} handleApproved={handleApproved} /> */}
              <LeaveApplicationTypeOfLeave
                height={state.containerHeight}
                singleLeaveInfo={props.singleLeaveInfo}
                authUser={props.authUser}
                applicationId={props.singleLeaveInfo.leaveId}
                type={props.type}
                handleError={
                  props.isReject ? handleError : props.rejectApplication
                }
                handleApproved={handleApproved}
              />
            </div>
            <div
              className="col-5 col-lg-5 col-md-5 col-sm-5 col-xs-5 lna-container-border ml-md"
              style={{ padding: 0 }}
            >
              {!props.isReject && (
                <Leavetabs selectedUserId={selectedUserId} type={props.type} />
              )}
              {props.isReject && (
                <ApprovalSubmit
                  isReject={props.isReject}
                  selectedUserId={selectedUserId}
                  height={props.height}
                  type={props.type}
                  rejectApplication={props.rejectApplication}
                  handleApproved={handleApproved}
                  handleReject={handleReject}
                  leaveStatus={props.singleLeaveInfo.status}
                  title={title}
                  handleTextArea={handleTextArea}
                  rejectReason={state.reason}
                  rejectReasonErr={rejectReasonErr}
                />
              )}
            </div>
          </div>

          <div
            className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pl-md pr-md"
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div>
              <button
                className="leave-cancel-apply-button"
                onClick={() => props.goBack()}
              >
                Back
              </button>
            </div>

            {!props.isReject && props.singleLeaveInfo.status === "Pending" && (
              <div>
                <button
                  className="remove-button-shadow-save mr-md"
                  onClick={() => handleApproved()}
                >
                  Approve
                </button>
                <button
                  className="leave-cancel-apply-button"
                  onClick={props.rejectApplication}
                >
                  Reject
                </button>
              </div>
            )}
          </div>
          <ConfirmationPopup
            showButton={{ type: "1" }}
            open={state.open}
            button1function={() => handleConfirmYes()}
            button2function={() => handleConfirmNo()}
            headerMessage={state.message}
            button1Text={"Yes"}
            button2Text={"No"}
            handleConfirmClose={handleConfirmNo}
          />
        </>
      )}
      {props.isLoading && (
        <div
          style={{
            zIndex: 999999,
            position: "absolute",
            marginTop: "20%",
            marginLeft: "49%",
          }}
        >
          <ProgressBar />
        </div>
      )}
    </>
  );
}

const mapStateToProps = ({ auth, lnareducer }) => {
  const { authUser } = auth;
  const { leaveApprovalInfo } = lnareducer;
  return { authUser, leaveApprovalInfo };
};

// const mapDispatchToProps = ;

export default connect(mapStateToProps, {
  changeLeaveApprovalStatus,
  updateLnAData,
})(LeaveApproved);
