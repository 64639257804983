import React, { Component } from 'react';
import { connect } from "react-redux";
import {addUpdateTibData} from '../../redux/actions/tibacton';
import { CheckBoxSelection, Inject, MultiSelectComponent } from '@syncfusion/ej2-react-dropdowns';

import reportService from "../../services/reportsevices";
class SDPracticeArea extends Component {
    constructor(props) {
        super(props)
        this.state = {
            selectedSdIds:[],
            sdList:[]
        };
        this.searchSD = "";
        this.getSelectedSdBy = this.getSelectedSdBy.bind(this);
        this.removeSdBy = this.removeSdBy.bind(this);
    }
    async componentDidMount() {
        const { authUser } = this.props;
        const resposnse = await reportService.getReportMasterData(authUser, 'SD');
        var selectedSds = [];
        resposnse.forEach(each=>{
            if(!selectedSds.includes(each.sd_id))   selectedSds.push(each.sd_id);
        });
        this.setState({sdList : resposnse, selectedSdIds : selectedSds},()=>this.props.addUpdateTibData({tibAvgSelectedSd:selectedSds}));
    }
    getSelectedSdBy(e) {
        const { selectedSdIds } = this.state;
        let selectedIds = selectedSdIds;
        if(e.itemData!==null){
            if(!selectedIds.includes(e.itemData.sd_id))   selectedIds.push(e.itemData.sd_id);
            this.setState({ selectedSdIds: selectedIds },()=>this.props.addUpdateTibData({tibAvgSelectedSd:selectedIds}));
        }else{
            selectedIds=[];
            this.setState({ selectedSdIds: selectedIds },()=>this.props.addUpdateTibData({tibAvgSelectedSd:selectedIds}));
        }
    }
    removeSdBy(e) {
        const { selectedSdIds } = this.state;
        let selectedIds = selectedSdIds;
        if(selectedIds.length > 0 && e.itemData!==null){
            selectedIds = selectedIds.filter(el => el !== e.itemData.sd_id);
            this.setState({ selectedSdIds: selectedIds },()=>this.props.addUpdateTibData({tibAvgSelectedSd:selectedIds}));
        }else{
            selectedIds = [];
            this.setState({ selectedSdIds: selectedIds },()=>this.props.addUpdateTibData({tibAvgSelectedSd:selectedIds}));
        }
    }
    render() {
        const { sdList, selectedSdIds }=this.state;
        return (
            
            <div className='col-lg-4 col-md-4 col-sm-4 col-xs-4' style={{marginLeft:11, marginTop: 0, /* border:"solid 1px #ddd" */ }}>
                {/* <div className='row control-styles'> */}
                <div id="SDmultigroup" className="control-styles">
                    <MultiSelectComponent 
                        id="mtselementSD" 
                        ref={(multiselect) => {
                            this.searchSD = multiselect;
                          }}
                        popupHeight='500px' 
                        fields={ { groupBy: 'pnl_name', text: 'sd_name', value: 'sd_id' }} 
                        cssClass="e-outline"
                        dataSource={sdList} 
                        value={selectedSdIds}
                        placeholder="Select Practice Area / Sales Unit / Sub Function" 
                        mode="CheckBox" 
                        enableGroupCheckBox={true}
                        filterType="Contains"
                        allowFiltering={true}
                        showSelectAll={true}
                        showDropDownIcon
                        filterBarPlaceholder="Search Practice Area / Sales Unit / Sub Function"
                        select={this.getSelectedSdBy}
                        removed={this.removeSdBy}
                        showClearButton={false}
                        floatLabelType="Auto"
                    >
                    <Inject services={[CheckBoxSelection]} />
                    </MultiSelectComponent>
                    <div id="select-sd-user" className="error-div pl-sm" />
                </div>
            </div>
        )
    }
}

const mapStateToProps = ({ auth, tibReducer }) => {
    const { authUser } = auth;
    return { authUser };
};
export default connect(mapStateToProps, {addUpdateTibData})(SDPracticeArea);