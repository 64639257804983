import React, { useEffect, useState, } from "react";
import { connect } from "react-redux";
import { ComboBoxComponent } from "@syncfusion/ej2-react-dropdowns";
import { NumericTextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { TimePickerComponent } from "@syncfusion/ej2-react-calendars";
import LnAServices from "../../services/lna/lnaservice";
import { FormValidator } from "@syncfusion/ej2-inputs";
import { saveWorkdayDayBreakMapping, updateLnAData } from "../../redux/actions/lna/lnaaction";
import ProgressBar from "../progressbar/progressbar";
import {
    pleaseEnterWeeklyworkhours,
    pleaseSelectWorkDayWindowName,
    pleaseSelectDayBreak,
    pleaseSetMinHrs,
    pleasesetWorkHrs,
    pleaseSetMaxHrs,
} from "../../assets/constants/constants";
import { SwitchComponent } from "@syncfusion/ej2-react-buttons";
const SAMPLECSS = `
input.e-input, textarea.e-input, .e-input-group, .e-input-group.e-control-wrapper {
  font-size: 12px;
}
`
function WorkDayWindowMapping(props) {
    //NOSONAR
    const [initialLoad, setInitialLoad] = useState(false)
    const [state, setState] = useState({
        workdayWindowName: null,
        workdayBreakMappingUid: "0",
        dayBreakId: "",
        workdayWindowUid: "",
        workHrs: null,
        minHrs: null,
        maxHrs: null,
        weeklyWrkHrs: null,
        graceTime: "",
        workdayPolicyDoc: [],
        fyId: "",
        isActive: "1",
        workingSaturdayId: "1",
        fortyHrWorkWeekId: "1",
        // workdayBreakMappingUid: "0",
        fileName: null,
        oldFileStatus: "",
    });
    let { editClusterId, authUser, openPopup } = props;

    let validationOptions = {
        rules: {
            workdayWindowErr: {
                required: [true, pleaseSelectWorkDayWindowName],
            },
            workdaySymbolErr: {
                required: [true, pleaseSelectDayBreak],
            },
            weeklyhrsErr: {
                required: [true, pleaseEnterWeeklyworkhours],
            }
        },
    }


    useEffect(() => {
        props.updateLnAData({ popUpSource: 'Workday Day Break Mapping' })
    }, []);
    useEffect(() => {
        setTimeout(() => {
            props.setSubmitClick(saveWorkDayClusterData);
        }, 100);
    }, [state]);

    useEffect(() => {
        getData()
    }, [openPopup, editClusterId])

    useEffect(() => {
        if (props.lnaMasterFlag === "updated") {
            props.changePopUpHandler("close");
        }
    }, [props.lnaMasterFlag]);

    const getData = () => {
        if (openPopup === "add") {
            setState({
                workdayWindowName: null,
                workdayBreakMappingUid: "0",
                dayBreakId: "",
                workdayWindowUid: "",
                workHrs: null,
                minHrs: null,
                maxHrs: null,
                graceTime: "",
                // workdayPolicyDoc: [],
                fyId: "",
                isActive: "1",
                workingSaturdayId: "1",
                fortyHrWorkWeekId: "1",
                // workdayBreakMappingUid: "0",
                weeklyWrkHrs: null,
                // fileName: null,
                // oldFileStatus: "",
            })
        }
        else {
            editClusterId !== null &&
                (async () => {
                    setInitialLoad(true)
                    const workDayWindowClusterData = await LnAServices.getWorkdayDayBreakMapping(
                        authUser,
                        editClusterId
                    )
                    setState((prevState) => ({
                        ...prevState,
                        workdayWindowName: workDayWindowClusterData.Result[0].workdayWindowName,
                        workdayBreakMappingUid: workDayWindowClusterData.Result[0].workdayBreakMappingUid,
                        workHrs: workDayWindowClusterData.Result[0].workHrs,
                        minHrs: workDayWindowClusterData.Result[0].minHrs,
                        maxHrs: workDayWindowClusterData.Result[0].maxHrs,
                        graceTime: workDayWindowClusterData.Result[0].graceTime,
                        fyId: workDayWindowClusterData.Result[0].fyId,
                        isActive: workDayWindowClusterData.Result[0].isActive,
                        workingSaturdayId: workDayWindowClusterData.Result[0].workingSaturdayId,
                        dayBreakId: workDayWindowClusterData.Result[0].dayBreakId,
                        dayBreak: workDayWindowClusterData.Result[0].dayBreak,
                        workdayWindowUid: workDayWindowClusterData.Result[0].workdayWindowUid,
                        weeklyWrkHrs: workDayWindowClusterData.Result[0].weeklyWrkHrs,
                        fortyHrWorkWeekId: workDayWindowClusterData.Result[0].fortyHrWorkWeekId,
                        // fileName: workDayWindowClusterData.Result[0].fileName,
                        // oldFileStatus: workDayWindowClusterData.Result[0].fileName,
                    }));
                    setTimeout(() => {
                        const addFormObject = new FormValidator("#workdayDayBreakMaster", validationOptions);
                        addFormObject.validate();
                        document.getElementById('minhrsErr').innerText = ''
                        document.getElementById('workHrsErr').innerText = ''
                        setInitialLoad(false)
                    }, 200);
                })()

        }
    }

    const onChangeWorkDayClusterData = (e, unit) => {
        let options = {};
        switch (unit) {
            case "workdaywindowname":
                setState((prevState) => {
                    const newState = { ...prevState, workdayWindowUid: e.value };
                    return newState;
                });
                options = validationOptions.rules["workdayWindowErr"];
                break;
            case "dayBreak":
                setState((prevState) => ({ ...prevState, dayBreakId: e.itemData && e.itemData.Id }));
                options = validationOptions.rules['workdaySymbolErr']
                break;
            case "workHrs":
                setState({ ...state, workHrs: e.value });
                if (document.getElementById("workHrsErr")) {
                    document.getElementById("workHrsErr").innerHTML = "";
                }
                break;
            case "minhrs":
                setState({ ...state, minHrs: e.value });
                if (document.getElementById("minhrsErr")) {
                    document.getElementById("minhrsErr").innerHTML = "";
                }
                break;
            case "maxhrs":
                setState({ ...state, maxHrs: e.value });
                if (document.getElementById("maxhrsErr")) {
                    document.getElementById("maxhrsErr").innerHTML = "";
                }
                break;
            case "weeklyWrkHrs":
                setState({ ...state, weeklyWrkHrs: e.value });
                if (e.value !== null) {
                    document.getElementById("weeklyhrsErr").innerHTML = "";
                }
                options = validationOptions.rules['weeklyhrsErr'];
                break;
            case "graceTime":
                setState({ ...state, graceTime: e.value });
                break;
            case "workingSaturdayId":
                setState((prevState) => ({
                    ...prevState,
                    workingSaturdayId: e.checked === true ? "1" : "0",
                }));
                break;
            case "fortyHrWorkWeekId":
                setState((prevState) => ({
                    ...prevState,
                    fortyHrWorkWeekId: e.checked === true ? "1" : "0",
                }));
                break;
            default:
                break;
        }
        if (options !== null && options !== undefined) {
            if (Object.keys(options).length > 0) {
                setTimeout(() => {
                    const addFormObject = new FormValidator("#workdayDayBreakMaster", options);
                    addFormObject.validate();
                }, 100);
            }
        }
    };

    const saveWorkDayClusterData = () => {
        // NOSONAR
        let {
            // workdayWindowName: wwn,
            // workdayBreakMappingUid,
            workdayBreakMappingUid: wDId,
            dayBreakId: sid,
            workdayWindowUid: wwId,
            workHrs: whrs,
            minHrs: mhrs,
            maxHrs: mwrs,
            weeklyWrkHrs: wwhrs,
            graceTime: gt,
            workdayFrequency: wdf,
            workingSaturdayId,
            //NOSONAR
            fortyHrWorkWeekId, 
            isActive,
        } = state;

        let validateDates = true;
        if (state.workHrs === "" || state.workHrs === null) {
            document.getElementById("workHrsErr").innerHTML = pleasesetWorkHrs;
            validateDates = false;
        }
        if (state.minHrs === "" || state.minHrs === null) {
            document.getElementById("minhrsErr").innerHTML = pleaseSetMinHrs;
            validateDates = false;
        }
        if (fortyHrWorkWeekId === '1') {
            if (state.maxHrs === "" || state.maxHrs === null) {
                document.getElementById("maxhrsErr").innerHTML = pleaseSetMaxHrs;
                validateDates = false;
            }
        }
        const addFormObject = new FormValidator("#workdayDayBreakMaster", validationOptions);
        if (addFormObject.validate() && validateDates) {
            setInitialLoad(true)
            let workdaydayDayBreakData = {
                userId: authUser,
                // workdayWindowName: wwn,
                workdayBreakMappingUid: wDId,
                // workdayBreakMappingUid,
                type: wDId > 0 ? "EDIT" : "ADD",
                dayBreakId: sid,
                workdayWindowUid: wwId,
                workHrs: `${new Date(whrs).getHours()}:${new Date(
                    whrs
                ).getMinutes()}`,
                minHrs: `${new Date(mhrs).getHours()}:${new Date(
                    mhrs
                ).getMinutes()}`,
                maxHrs: `${new Date(mwrs).getHours()}:${new Date(
                    mwrs
                ).getMinutes()}`,
                graceTime: gt,
                workdayFrequency: wdf,
                weeklyWorkHrs: wwhrs,
                workingSaturdayId: workingSaturdayId,
                fortyHrWorkWeekId: fortyHrWorkWeekId,
                isActive: isActive,
                // fileName,
            };
            props.saveWorkdayDayBreakMapping(workdaydayDayBreakData);
            setTimeout(() => {
                setInitialLoad(false)
            }, 10)

        }

    };

    let {
        workdayWindowName,
        dayBreakId,
        workHrs,
        minHrs,
        maxHrs,
        weeklyWrkHrs,
        graceTime,
        fortyHrWorkWeekId
    } = state;
    return (
        <div id="workdayDayBreakMaster"
            style={{ height: "61.5vh", overflowY: "scroll", transition: "width 5s" }}
        >
            <style>{SAMPLECSS}</style>
            <div style={{ padding: "16px" }}>
                <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12  atc-no-paddding">
                    <ComboBoxComponent
                        data-msg-containerid="workdayWindowErr"
                        id="workdayWindow"
                        dataSource={props.symbolData.workdayWindowName}
                        fields={{ text: "Name", value: "Id" }}
                        value={workdayWindowName}
                        name="workdayWindowErr"
                        placeholder="Workday Window * "
                        floatLabelType="Auto"
                        autocomplete={false}
                        cssClass="e-outline minimize-height"
                        onChange={(e) => onChangeWorkDayClusterData(e, "workdaywindowname")}
                        htmlAttributes={{ maxlength: 50 }}
                    />
                    <div id="workdayWindowErr" className="e-input-group error-div" />
                </div>
                <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12  atc-no-paddding">
                    <ComboBoxComponent
                        id="dayBreak"
                        dataSource={props.symbolData.dayBreakName}
                        fields={{ text: "Name", value: "Id" }}
                        value={dayBreakId}
                        data-msg-containerid="workdaySymbolErr"
                        name="workdaySymbolErr"
                        allowCustom={false}
                        floatLabelType="Auto"
                        cssClass="e-outline minimize-height"
                        placeholder="Day Break *"
                        change={(e) => onChangeWorkDayClusterData(e, "dayBreak")}
                    />
                    <div id="workdaySymbolErr" className=" e-input-group rror-div" />
                </div>

                <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12   atc-no-paddding">
                    <TimePickerComponent
                        style={{ width: "100%" }}
                        id="workHrs"
                        name="workHrsErr"
                        placeholder="Work Hours *"
                        data-msg-containerid="workHrsErr"
                        format="HH:mm"
                        floatLabelType="Auto"
                        cssClass="e-outline"
                        allowEdit={false}
                        step={30}
                        min={(new Date('8/3/2017 01:00'))}
                        max={new Date('8/3/2017 08:00 ')}
                        value={workHrs}
                        onChange={(e) => onChangeWorkDayClusterData(e, "workHrs")}

                    />
                    <div
                        id="workHrsErr"
                        className="e-input-group e-float-icon-left error-div"
                    />
                </div>
                <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12  atc-no-paddding">
                    <TimePickerComponent
                        style={{ width: "100%" }}
                        id="minhrs"
                        name="minhrsErr"
                        placeholder="Minimum Hours *"
                        data-msg-containerid="minhrsErr"
                        format="HH:mm"
                        floatLabelType="Auto"
                        cssClass="e-outline"
                        allowEdit={false}
                        step={30}
                        value={minHrs}
                        onChange={(e) => onChangeWorkDayClusterData(e, "minhrs")}

                    />
                    <div
                        id="minhrsErr"
                        className="e-input-group e-float-icon-left error-div"
                    />
                </div >
                {fortyHrWorkWeekId === '1' ?
                    <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12  atc-no-paddding">
                        <TimePickerComponent
                            style={{ width: "100%" }}
                            id="maxhrs"
                            name="maxhrsErr"
                            placeholder="Maximum Hours *"
                            data-msg-containerid="maxhrsErr"
                            format="HH:mm"
                            floatLabelType="Auto"
                            cssClass="e-outline"
                            allowEdit={false}
                            step={30}
                            value={maxHrs}
                            onChange={(e) => onChangeWorkDayClusterData(e, "maxhrs")}

                        />
                        <div
                            id="maxhrsErr"
                            className="e-input-group e-float-icon-left error-div"
                        />
                    </div > : ""
                }

                <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 atc-no-paddding">
                    <NumericTextBoxComponent
                        data-msg-containerid="weeklyhrsErr"
                        id="weeklyhrsId"
                        name="weeklyhrsErr"
                        autocomplete={'off'}
                        value={weeklyWrkHrs}
                        min={0}
                        max={48}
                        multiline={false}
                        placeholder="Weekly Work Hours *"
                        floatLabelType="Auto"
                        cssClass="e-outline minimize-height e-custom-height"
                        change={(e) => onChangeWorkDayClusterData(e, "weeklyWrkHrs")}
                    />

                    <div
                        id="weeklyhrsErr"
                        className="e-input-group e-float-icon-left error-div"
                    />
                </div>

                <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 atc-no-paddding">
                    <NumericTextBoxComponent
                        id="graceTime"
                        value={graceTime}
                        data-msg-containerid="graceTimeErr"
                        name="graceTimeErr"
                        min={0}
                        max={60}
                        format={'##'}
                        placeholder="Grace Time In Minutes "
                        floatLabelType="Auto"
                        cssClass="e-outline minimize-height"
                        change={(e) => onChangeWorkDayClusterData(e, "graceTime")}
                        htmlAttributes={{ maxlength: 100 }}
                    />
                    <div id="graceTimeErr" className="error-div" />
                </div>

                <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 mt-lg">
                    <div className="atc-switch-heading">Working Saturday Allowed</div>
                    <div className="col-xs-1 col-sm-1 col-lg-4 col-md-2 atc-switch-inline mt-md atc-no-paddding">
                        <div className="mr-md">No</div>
                        <div className="atc-switch-margin">
                            <SwitchComponent
                                id="workingSaturday"
                                cssClass="atc-switch-height"
                                change={(e) => onChangeWorkDayClusterData(e, "workingSaturdayId")}
                                checked={state.workingSaturdayId === "1" ? true : false}
                            />
                        </div>
                        <div className="ml-md">Yes</div>
                    </div>
                </div>

                <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 mt-lg">
                    <div className="atc-switch-heading">40 Hour Work Week</div>
                    <div className="col-xs-1 col-sm-1 col-lg-4 col-md-2 atc-switch-inline mt-md atc-no-paddding">
                        <div className="mr-md">No</div>
                        <div className="atc-switch-margin">
                            <SwitchComponent
                                id="fortyHourWorkWeek"
                                cssClass="atc-switch-height"
                                change={(e) => onChangeWorkDayClusterData(e, "fortyHrWorkWeekId")}
                                checked={state.fortyHrWorkWeekId === "1" ? true : false}
                            />
                        </div>
                        <div className="ml-md">Yes</div>
                    </div>
                </div>
            </div >

            <div className="loader-new1" id="spinnerContainer" >
                {initialLoad && <ProgressBar />}
            </div>
        </div >
    );
}

const mapStateToProps = ({ okrratingreducer, auth, lnareducer }) => {
    const { authUser } = auth;
    const { fyId } = okrratingreducer;
    const { symbolData, lnaMasterFlag } = lnareducer;

    return { authUser, fyId, symbolData, lnaMasterFlag };
};

export default connect(mapStateToProps, { saveWorkdayDayBreakMapping, updateLnAData })(
    WorkDayWindowMapping
);
