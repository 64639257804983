import React, { useRef, useEffect, useState, useMemo } from "react";
import { connect } from "react-redux";
import {
  updateLnAData,
  applyLeave,
} from "../../../../redux/actions/lna/lnaaction";
import LnAService from "../../../../services/lna/lnaservice";
import ProgressBar from "../../../progressbar/progressbar";
import { VIEWDAILY } from "../../../../redux/constant/meetingcategoryconstant";
import monthlyViewIcon from "../../../../assets/svg/monthView.svg";
import weeklyViewIcon from "../../../../assets/svg/weekView.svg";
import { Tooltip } from "@material-ui/core";
import {
  formatDateToYYYYMMDD,
  generateExcelFile,
  getBackGroundColor,
} from "../../../../app/other/commonfunction";
import "../../../../app/layout/lna/lna.css";
const sampleCss = `
.weeklyReportTable table th {
    padding-top:8px !important;
    padding-bottom:8px !important;
    padding-left: 8px !important;
    padding-right: 8px !important;
    border-bottom: 1px solid #dbdbdd;
    border-top:1px solid #dbdbdd;
    border-collapse: collapse;
    width:200px;
    color: '#939399';
    font-size:12px !important;
    font-famly:'Circular Std';
}

.weeklyReportTable table td {

    padding-right: 8px !important;
    padding-left: 8px !important;
    padding-top:8px !important;
    padding-bottom:8px !important;
    border-bottom: 1px solid #dbdbdd;
    border-top:1px solid #dbdbdd;
    border-collapse: collapse;
    font-size:14px !important;
    colour:black;
    font-weight: 100 !important;
    font-family: 'Circular Std';
    width:200px;
}
.e-outline.e-input-group, .e-outline.e-input-group.e-control-wrapper, .e-outline.e-float-input, .e-outline.e-float-input.e-control-wrapper {
    font-size: 14px;
}
window
`;
const sampleCssSmallisOneTwentyFiveInSixteenHundred = `
    .weeklyReportTable table th {
        padding-top:4px !important;
        padding-bottom:4px !important;
        padding-left: 8px !important;
        padding-right: 8px !important;
        border-bottom: 1px solid #dbdbdd;
        border-top:1px solid #dbdbdd;
        border-collapse: collapse;
        width:200px;
        color: '#939399';
        font-size:10px !important;
        font-famly:'Circular Std';
       
    }
    
    .weeklyReportTable table td {
    
        padding-right: 8px !important;
        padding-left: 6px !important;
        padding-top:4px !important;
        padding-bottom:4px !important;
        border-bottom: 1px solid #dbdbdd;
        border-top:1px solid #dbdbdd;
        border-collapse: collapse;
        font-size:10px !important;
        colour:black;
        font-weight: 100 !important;
        font-family: 'Circular Std';
        width:200px;
    }
    .e-outline.e-input-group, .e-outline.e-input-group.e-control-wrapper, .e-outline.e-float-input, .e-outline.e-float-input.e-control-wrapper {
        font-size: 12px;
`;
const sampleCssisSixteenEighty = `
.weeklyReportTable table th {
    padding-top:5px !important;
    padding-bottom:5px !important;
    padding-left: 8px !important;
    padding-right: 8px !important;
    border-bottom: 1px solid #dbdbdd;
    border-top:1px solid #dbdbdd;
    border-collapse: collapse;
    width:200px;
    color: '#939399';
    font-size:12px !important;
    font-famly:'Circular Std';
   
}

.weeklyReportTable table td {

    padding-right: 8px !important;
    padding-left: 8px !important;
    padding-top:6px !important;
    padding-bottom:6px !important;
    border-bottom: 1px solid #dbdbdd;
    border-top:1px solid #dbdbdd;
    border-collapse: collapse;
    font-size:12px !important;
    colour:black;
    font-weight: 100 !important;
    font-family: 'Circular Std';
    width:200px;
}
.e-outline.e-input-group, .e-outline.e-input-group.e-control-wrapper, .e-outline.e-float-input, .e-outline.e-float-input.e-control-wrapper {
    font-size: 12px;
}
`;
const sampleCssSmall = `
.weeklyReportTable table th {
    padding-top:6px !important;
    padding-bottom:6px !important;
    padding-left: 8px !important;
    padding-right: 8px !important;
    border-bottom: 1px solid #dbdbdd;
    border-top:1px solid #dbdbdd;
    border-collapse: collapse;
    width:200px;
    color: '#939399';
    font-size:11px !important;
    font-famly:'Circular Std';
   
}

.weeklyReportTable table td {

    padding-right: 8px !important;
    padding-left: 8px !important;
    padding-top:5px !important;
    padding-bottom:4px !important;
    border-bottom: 1px solid #dbdbdd;
    border-top:1px solid #dbdbdd;
    border-collapse: collapse;
    font-size:10px !important;
    colour:black;
    font-weight: 100 !important;
    font-family: 'Circular Std';
    width:200px;
}
.e-outline.e-input-group, .e-outline.e-input-group.e-control-wrapper, .e-outline.e-float-input, .e-outline.e-float-input.e-control-wrapper {
    font-size: 12px;
}
`;
const isThirteenHundredBookmarkOnSampleCss = `
.weeklyReportTable table th {
    padding-top:4px !important;
    padding-bottom:4px !important;
    padding-left: 8px !important;
    padding-right: 8px !important;
    border-bottom: 1px solid #dbdbdd;
    border-top:1px solid #dbdbdd;
    border-collapse: collapse;
    width:200px;
    color: '#939399';
    font-size:11px !important;
    font-famly:'Circular Std';
   
}

.weeklyReportTable table td {

    padding-right: 8px !important;
    padding-left: 8px !important;
    padding-top:4px !important;
    padding-bottom:4px !important;
    border-bottom: 1px solid #dbdbdd;
    border-top:1px solid #dbdbdd;
    border-collapse: collapse;
    font-size:10px !important;
    colour:black;
    font-weight: 100 !important;
    font-family: 'Circular Std';
    width:200px;
}
.e-outline.e-input-group, .e-outline.e-input-group.e-control-wrapper, .e-outline.e-float-input, .e-outline.e-float-input.e-control-wrapper {
    font-size: 12px;
}
`;
const isTwelve80SampleCss = `
.weeklyReportTable table th {
    padding-top:3px !important;
    padding-bottom:3px !important;
    padding-left: 8px !important;
    padding-right: 8px !important;
    border-bottom: 1px solid #dbdbdd;
    border-top:1px solid #dbdbdd;
    border-collapse: collapse;
    width:200px;
    color: '#939399';
    font-size:9px !important;
    font-famly:'Circular Std';
   
}

.weeklyReportTable table td {

    padding-right: 8px !important;
    padding-left: 8px !important;
    padding-top:3px !important;
    padding-bottom:3px !important;
    border-bottom: 1px solid #dbdbdd;
    border-top:1px solid #dbdbdd;
    border-collapse: collapse;
    font-size:9px !important;
    colour:black;
    font-weight: 100 !important;
    font-family: 'Circular Std';
    width:200px;
}
.e-outline.e-input-group, .e-outline.e-input-group.e-control-wrapper, .e-outline.e-float-input, .e-outline.e-float-input.e-control-wrapper {
    font-size: 12px;
}

`;
const is1024SampleCss = `
.weeklyReportTable table th {
    padding-top:3px !important;
    padding-bottom:3px !important;
    padding-left: 8px !important;
    padding-right: 8px !important;
    border-bottom: 1px solid #dbdbdd;
    border-top:1px solid #dbdbdd;
    border-collapse: collapse;
    width:200px;
    color: '#939399';
    font-size:9px !important;
    font-famly:'Circular Std';
   
}

.weeklyReportTable table td {

    padding-right: 8px !important;
    padding-left: 8px !important;
    padding-top:4px !important;
    padding-bottom:4px !important;
    border-bottom: 1px solid #dbdbdd;
    border-top:1px solid #dbdbdd;
    border-collapse: collapse;
    font-size:8px !important;
    colour:black;
    font-weight: 100 !important;
    font-family: 'Circular Std';
    width:200px;
}
.e-outline.e-input-group, .e-outline.e-input-group.e-control-wrapper, .e-outline.e-float-input, .e-outline.e-float-input.e-control-wrapper {
    font-size: 10px;
}

`;
const is571SampleCss = `
.weeklyReportTable table th {
    padding-top:3px !important;
    padding-bottom:3px !important;
    padding-left: 8px !important;
    padding-right: 8px !important;
    border-bottom: 1px solid #dbdbdd;
    border-top:1px solid #dbdbdd;
    border-collapse: collapse;
    width:200px;
    color: '#939399';
    font-size:9px !important;
    font-famly:'Circular Std';
   
}

.weeklyReportTable table td {

    padding-right: 8px !important;
    padding-left: 8px !important;
    padding-top:3px !important;
    padding-bottom:3px !important;
    border-bottom: 1px solid #dbdbdd;
    border-top:1px solid #dbdbdd;
    border-collapse: collapse;
    font-size:8px !important;
    colour:black;
    font-weight: 100 !important;
    font-family: 'Circular Std';
    width:200px;
}
.e-outline.e-input-group, .e-outline.e-input-group.e-control-wrapper, .e-outline.e-float-input, .e-outline.e-float-input.e-control-wrapper {
    font-size: 10px;
}
`;
export const WeeklyReport = (props) => {
  /**NOSONAR */
  const {
    teamId,
    getMasterData,
    categoryName,
    authUser,
    startMonthMonthlyReport,
    showDownloadMyReportButton,
    getFullUserInfo,
    viewClicked,
    isLoading
  } = props;
  const [left1, setLeft1] = useState([]); // show data in left part of page
  const [right1, setRight1] = useState([]); // show data in right part of page
  const [left2, setLeft2] = useState([]); // show data on basis of page number from pagination.
  const [right2, setRight2] = useState([]); // show data on basis of page number from pagination.
  const [left3, setLeft3] = useState([]); // show data on basis of page number from pagination.
  const [right3, setRight3] = useState([]); // show data on basis of page number from pagination.
  const [pageNo, setPageNo] = useState(1); // page number set for pagination
  const [dataLeft, setDataLeft] = useState([]); // first time data load for left side
  const [dataRight, setDataRight] = useState([]); // first time data load for right side
  const [leftWeekNo, setLeftWeekNo] = useState(); // left week no show
  const [rightWeekNo, setRightWeekNo] = useState(null); // right week no show
  const [length, setLength] = useState(); // length od all the data to show count in pagination
  const [masterDataLoaded, setMasterDataLoaded] = useState(false);
  const [DataLoaded, setDataLoaded] = useState(false);
  // const [lnaReportMonth, setLnaReportMonth] = useState([]) // months data
  const temp1 = []; // temporary data store
  const temp2 = []; // temporary data store
  const temp3 = []; // temporary data store
  const temp4 = []; // temporary data store
  const temp5 = []; // temporary data store
  const temp6 = []; // temporary data store // store month id to api call on month change
  // const [teamId, setTeamId] = useState(authUser) // store team id to api call on team member change
  // const [month, setMonth] = useState(null) // store month id to api call on month change
  const prevCategory = useRef();

  const isSixteenHundred = window.innerHeight < 782;
  const isFourteenHundred =
    window.innerHeight < 782 && window.innerWidth < 1441;
  const isThirteenHundred = window.innerHeight < 650;
  const isOneTwentyFiveInSixteenHundred = window.innerHeight < 607;

  const [height, setHeight] = useState(
    window.innerHeight ||
    document.documentElement.clientHeight ||
    document.body.clientHeight
  );
  const setHeightBasedOnInnerWidth = (width) => {
    const heightMap = {
      1920: 320,
      1912: 320,
      1872: 320,
      1680: 336,
      1632: 320,
      1440: 310,
      1432: 310,
      1488: 310,
      1280: 295,
      1272: 292,
      1536: 310,
      1528: 310,
      1344: 305,
      1392: 305,
      1366: 298,
      1358: 298,
      1318: 285,
      1296: 304,
      1232: 290,
      1152: 296,
      1144: 294,
      1104: 290,
      1072: 300,
      1024: 295,
      1016: 295,
      976: 280,
    };

    const defaultHeight =
      window.innerHeight - (14 + 73 + 15 + 68 + 15 + 32 + 39 + 51 - 12);
    return heightMap[width] || defaultHeight;
  };

  /*
     Author : Amit Kumar
     desc:get background color of day break
     dayBreakNo: 1-->full day
     dayBreakNo: 0.5--> half day, 0.25 --> quarter day
     dayBreakType 1 --> full day, 2--> first half, 3 --> second half, 4--> quarter day
     */


  const setPageToDisplay = (page) => {
    setPageNo(page);
  };

  /*
 Author : Amit Kumar
 desc:show data in left and right part after changing the page no.
 */

  const getData = () => {
    if (pageNo === 1) {
      setDataLeft(left1);
      left1.length && setLeftWeekNo(left1[0].weekNo);

      setDataRight(right1);
      right1.length && setRightWeekNo(right1[0].weekNo);
    }
    if (pageNo === 2) {
      setDataLeft(left2);
      left2.length && setLeftWeekNo(left2[0].weekNo);
      setDataRight(right2);
      right2.length && setRightWeekNo(right2[0].weekNo);
    }
    if (pageNo === 3) {
      setDataLeft(left3);
      left3.length && setLeftWeekNo(left3[0].weekNo);
      setDataRight(right3);
      right3.length ? setRightWeekNo(right3[0].weekNo) : setRightWeekNo("");
    }
  };
  const getMon_yyy = (mmm_yy) => {
    let date = new Date(mmm_yy);
    let monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    let month = monthNames[date.getMonth()];
    let year = date.getFullYear();
    return `${month} - ${year}`;
  };
  //
  const getClassName = () => {
    if (isOneTwentyFiveInSixteenHundred) {
      return "flexisOneTwentyFiveInSixteenHundred";
    } else if (isThirteenHundred) {
      return "flex-items-small";
    } else if (isFourteenHundred || isSixteenHundred) {
      return "flex-items-fourteen";
    } else {
      return "flex-items";
    }
  };

  const getSampleStyle = () => {
    if (window.innerHeight === 609) {
      return isThirteenHundredBookmarkOnSampleCss;
    }
    if (window.innerWidth === 1280) {
      return isTwelve80SampleCss;
    }
    if (window.innerWidth === 1024) {
      return is1024SampleCss;
    }
    if (window.innerWidth === 1152) {
      return is571SampleCss;
    } if (window.innerWidth === 1440) {
      return sampleCssSmall;
    } else if (isOneTwentyFiveInSixteenHundred) {
      return sampleCssSmallisOneTwentyFiveInSixteenHundred;
    } else if (isThirteenHundred) {
      return sampleCssSmall;
    } else if (isFourteenHundred || isSixteenHundred) {
      return sampleCssSmallisOneTwentyFiveInSixteenHundred;
    } else if (sampleCssisSixteenEighty) {
      return sampleCssisSixteenEighty;
    } else {
      return sampleCss;
    }
  };

  /**
   *@author: Somesh Dwivedi
   *@description : To get the day break and day break type
   */
  const getDayBreakType = (dayBreakNo, dayBreakType) => {
    /**NOSONAR */
    // eslint-disable-next-line default-case
    switch (dayBreakNo) {
      //For full day there is only one case that is the full day leave
      case 1:
        return {
          dayBreak: "Full Day",
          dayBreakType: "",
        };
      //For half day, leave is for the first half, or second half on the basis of day break type
      case 0.5:
        if (dayBreakType === "1") {
          return {
            dayBreak: "Half Day",
            dayBreakType: "First Half",
          };
        } else if (dayBreakType === "2") {
          return {
            dayBreak: "Half Day",
            dayBreakType: "Second Half",
          };
        }
      // eslint-disable-next-line no-fallthrough
      case 0.25: // NOSONAR
        if (dayBreakType === "1") {
          return {
            dayBreak: "Quarter Day",
            dayBreakType: "First Quarter",
          };
        } else if (dayBreakType === "2") {
          return {
            dayBreak: "Quarter Day",
            dayBreakType: "Second Quarter",
          };
        } else if (dayBreakType === "3") {
          return {
            dayBreak: "Quarter Day",
            dayBreakType: "Third Quarter",
          };
        } else if (dayBreakType === "4") {
          return {
            dayBreak: "Quarter Day",
            dayBreakType: "Fourth Quarter",
          };
        }
        break;
      default:
        return {
          dayBreak: "",
          dayBreakType: "",
        };
    }
  };
  /**
   *@author: Somesh Dwivedi
   *@description : getting date and day in different key from date string
   */
  const getDateAndDayFromDateString = (dateString) => {
    const components = dateString.split(" ");

    // Extract the date and day
    const date = components[0]; // "02-Sep-2023"
    const day = components[1]; // "Saturday"
    return {
      date,
      day,
    };
  };
  /**
   *@author: Somesh Dwivedi
   *@description : getting data to of my report to convert it into excell
   */
  const getMyReportData = async () => {
    //Getting weekly leave report data
    const WeeklyReportData = await LnAService.getWeeklyReportData(
      teamId,
      "WEEKREPORT",
      startMonthMonthlyReport && startMonthMonthlyReport.split(" ")[0]
    );

    const fullUserInfo = getFullUserInfo(+teamId);
    let currentDate = new Date();
    const formattedDate = formatDateToYYYYMMDD(currentDate);
    const fileName = `${formattedDate}_Attendance Report - Weekly`;
    // eslint-disable-next-line
    const columns = {
      weekNo: "Week No.",
      date: "Attendance Date",
      day: "Day",
      workingHrs: "Working Hours",
      presentStatus: "Present Status",
      empCode: "Team Member Id",
      empName: "Team Member Name",
    };
    const dataTobeExported = WeeklyReportData.Result.map((ele) => {
      const { day, date } = getDateAndDayFromDateString(ele.dtAttendance);
      return {
        [columns.empCode]: fullUserInfo?.EmpCode,
        [columns.empName]: fullUserInfo?.name,
        [columns.weekNo]: "Week " + ele.weekNo,
        [columns.date]: date,
        [columns.day]: day,
        [columns.workingHrs]: ele.workingHrs,
        [columns.presentStatus]: ele.presentStatus,
      };
    });
    generateExcelFile([dataTobeExported, []], fileName);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        props.updateLnAData({ isLoading: true });
        const weeklyReportDataPromise = LnAService.getWeeklyReportData(
          teamId,
          "WEEKREPORT",
          startMonthMonthlyReport && startMonthMonthlyReport.split(" ")[0]
        );

        const [weeklyReportData] = await Promise.all([
          weeklyReportDataPromise,
        ]);

        if (weeklyReportData.Result) {
          weeklyReportData.Result.forEach((item) => {
            switch (item.weekRank) {
              case 1:
                temp1.push(item);
                break;
              case 2:
                temp2.push(item);
                break;
              case 3:
                temp3.push(item);
                break;
              case 4:
                temp4.push(item);
                break;
              case 5:
                temp5.push(item);
                break;
              case 6:
                temp6.push(item);
                break;
              default:
                break;
            }
          });
        }

        // Set data after processing
        setTimeout(() => {
          setLeft1(temp1);
          setDataLeft(temp1);
          setLeftWeekNo(temp1[0].weekNo);
          setLength(weeklyReportData.Result.length);
          setRight1(temp2);
          setDataRight(temp2);
          if (temp2.length > 0) setRightWeekNo(temp2[0].weekNo);
          setLeft2(temp3);
          setRight2(temp4);
          setLeft3(temp5);
          setRight3(temp6);
          props.updateLnAData({ isLoading: false });
        }, 1000);
      } catch (error) {
        console.error("Error fetching data:", error);
        props.updateLnAData({ isLoading: false })
      }
    };

    if (viewClicked && startMonthMonthlyReport && teamId) {
      fetchData();
      props.updateLnAData({ viewClicked: false })
    }
  }, [viewClicked]);

  useEffect(() => {
    setHeight(
      window.innerHeight - setHeightBasedOnInnerWidth(window.innerWidth)
    );
  }, []);

  // useEffect(() => {
  //     if (startMonthMonthlyReport && teamId) {
  //         getData();
  //         setPageNo(1);
  //     }
  //     // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [startMonthMonthlyReport, teamId, viewClicked]);

  useEffect(() => {
    getData();
  }, [pageNo]);

  return (
    <>
      {<style>{getSampleStyle()}</style>}

      <div className="col-lg-10 col-md-10 col-sm-10 col-xs-10 mt-md custom-margin-left">
        <div
          className="col-lg-12 col-md-12 col-sm-12 col-xs-12 allTeamReportHeader"
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: "#4B4B55",            
            borderStartEndRadius: "0.4em",
            borderStartStartRadius: "0.4em",
            paddingLeft: "1rem",
            fontWeight: 600,
            width: "100%",


          }}
        >

          <div
            style={{
              color: "white",
              fontStyle: "Circular Std Book",
              fontSize: window.innerHeight > 700 ? "1rem" : "14px",
              fontFamily: "Arial",
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              alignItems: "center",
            }}
          >
            <div>
              {startMonthMonthlyReport
                ? getMon_yyy(startMonthMonthlyReport)
                : ""}
            </div>
            {showDownloadMyReportButton === "Y" && (
              <div
                className="leaveBalanceWeeklyReport"
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={getMyReportData}
              >
                <span className="spanleaveBalanceYearlyReport">
                  Download
                </span>
              </div>
            )}
          </div>
          {categoryName === VIEWDAILY && (
            <div
              className="mr-md"
              style={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                backgroundColor: "#fff",
                borderRadius: "3px",
                padding: ".1rem .2rem",
              }}
            >
              <Tooltip title="View Weekly">
                <div
                  style={{
                    backgroundColor: "#4B4B55",
                    borderRadius: "3px",
                  }}
                >
                  <img
                    src={monthlyViewIcon}
                    alt=""
                    onClick={() => props.changeViewReport("weeklyReport")}
                    style={{
                      width: "1.8rem",
                      height:
                        window.innerWidth > 1377 ? "1.8rem" : "1.6rem",
                      cursor: "pointer",
                      filter:
                        "invert(100%) sepia(99%) saturate(2%) hue-rotate(52deg) brightness(103%) contrast(101%)",
                    }}
                  />
                </div>
              </Tooltip>
              <Tooltip title="View Monthly">
                <div style={{ backgroundColor: null, borderRadius: "3px" }}>
                  <img
                    src={weeklyViewIcon}
                    alt=""
                    onClick={() => {
                      props.changeViewReport("monthlyReportMonth")
                      props.updateLnAData({ viewClicked: true });
                    }
                    }
                    style={{
                      width: "1.8rem",
                      height:
                        window.innerWidth > 1377 ? "1.8rem" : "1.6rem",
                      cursor: "pointer",
                      filter: null,
                    }}
                  />
                </div>
              </Tooltip>
            </div>
          )}
        </div>

        {!isLoading && <div
          className="col-lg-12 col-md-12 col-sm-12 col-xs-12 reportParent"
          style={{ backgroundColor: "white", height: height, borderRight: "1px solid #dbdbdd" }}
        >
          <div className="row1">
            {leftWeekNo && (
              <div
                className="col-lg-6 col-md-6 col-sm-6 col-xs-6 atc-no-paddding reportParent"
                style={{ borderRight: "1px solid #dbdbdd" }}
              >
                <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                  <div
                    style={{
                      backgroundColor: "#F2F2F2",
                      height: window.innerHeight > 700 ? "3rem" : "2rem",
                      display: "flex",
                      alignItems: "center",
                      paddingLeft: "1rem",
                      fontWeight: 600,
                      width: "100%",
                    }}
                  >
                    <div
                      className="col-xs-12 col-sm-12 col-lg-12 col-md-12"
                      style={{
                        color: "black",
                        fontStyle: "Circular Std Book",
                        fontSize:
                          window.innerHeight > 700 ? "1rem" : "14px",
                        fontFamily: "Arial",
                      }}
                    >
                      {leftWeekNo && `Weekly Report (Week ${leftWeekNo})`}
                    </div>
                  </div>
                </div>

                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12" >
                  <div>
                    {/* <div className={`weeklyReportTable ${window.innerHeight <= 563 ? 'heightAdjust-one' : '' } ${window.innerHeight == 611 ? 'heightAdjust-two' : '' }`}> */}
                    <div
                      className="weeklyReportTable"
                      style={{ height: height - 86, overflowY: "auto" }}
                    >
                      <table>
                        {/* <style>{sampleCss}</style> */}
                        <tr>
                          <th style={{ color: "#939399" }}>Date</th>
                          <th
                            style={{
                              color: "#939399",
                              textAlign: "center",
                            }}
                          >
                            Daybreak
                          </th>
                          <th style={{ color: "#939399" }}>Time In Business</th>
                          <th
                            style={{
                              color: "#939399",
                              textAlign: "center",
                              width: "100px",
                            }}
                          >
                            Present Status
                          </th>
                        </tr>
                        {dataLeft.map((each, id) => {
                          let val = getBackGroundColor(
                            each.dayBreakNo,
                            each.dayBreakType,
                            each.attendenceColour,
                            each.leaveColour,
                            each.dayBreakType2,
                            each.dayBreakNo2,
                            each.attendenceColour2,
                            each.leaveColour2,
                            each.type1
                          );
                          if (val === undefined) {
                            val = ['white', 'white', 'white', 'white'];
                          }
                          return (
                            <tr key={`weeklyReport-${id}` /**NOSONAR */}>
                              <td>{each.dtAttendance}</td>

                              <td>
                                <div
                                  className="flex-container-table"
                                  style={{
                                    display: "flex",
                                    flexDirection: "row",
                                    width: "100%",
                                  }}
                                >
                                  <>
                                    <div
                                      className={getClassName()}
                                      style={{ backgroundColor: val[0] ?? "white" }}
                                    ></div>
                                    <div
                                      className={getClassName()}
                                      style={{ backgroundColor: val[1] ?? "white" }}
                                    ></div>
                                    <div
                                      className={getClassName()}
                                      style={{ backgroundColor: val[2] ?? "white" }}
                                    ></div>
                                    <div
                                      className={getClassName()}
                                      style={{ backgroundColor: val[3] ?? "white" }}
                                    ></div>
                                  </>
                                </div>
                              </td>

                              <td>{each.workingHrs}</td>
                              <td style={{ textAlign: "center" }}>
                                {each.presentStatus}
                              </td>
                            </tr>
                          );
                        })}
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            )}

            {rightWeekNo && (
              <div
                className="col-lg-6 col-md-6 col-sm-6 col-xs-6 atc-no-paddding"
              >
                <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                  <div
                    style={{
                      backgroundColor: "#F2F2F2",
                      height: window.innerHeight > 700 ? "3rem" : "2rem",
                      display: "flex",
                      alignItems: "center",
                      paddingLeft: "1rem",
                      fontWeight: 600,
                      width: "100%",
                    }}
                  >
                    <div
                      className="col-xs-12 col-sm-12 col-lg-12 col-md-12"
                      style={{
                        color: "black",
                        fontStyle: "Circular Std Book",
                        fontSize:
                          window.innerHeight > 700 ? "1rem" : "14px",
                        fontFamily: "Arial",
                      }}
                    >
                      {rightWeekNo && `Weekly Report (Week ${rightWeekNo})`}
                    </div>
                  </div>
                </div>

                <div
                  className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                >
                  {/* <div className={`weeklyReportTable ${window.innerHeight <= 563 ? 'heightAdjust-one' : '' } ${window.innerHeight == 611 ? 'heightAdjust-two' : '' }`} > */}
                  <div
                    className="weeklyReportTable"
                    style={{ height: height - 86, overflowY: "auto" }}
                  >
                    <table style={{ borderLeft: "1px solid #dbdbdd" }}>
                      <tr>
                        <th style={{ color: "#939399" }}>Date</th>
                        <th
                          style={{
                            color: "#939399",
                            textAlign: "center",
                          }}
                        >
                          Daybreak
                        </th>
                        <th style={{ color: "#939399" }}>Time In Business</th>
                        <th
                          style={{
                            color: "#939399",
                            textAlign: "center",
                          }}
                        >
                          Present Status
                        </th>
                      </tr>
                      {dataRight.map((each, id) => {
                        let val = getBackGroundColor(
                          each.dayBreakNo,
                          each.dayBreakType,
                          each.attendenceColour,
                          each.leaveColour,
                          each.dayBreakType2,
                          each.dayBreakNo2,
                          each.attendenceColour2,
                          each.leaveColour2,
                          each.type1
                        );
                        return (
                          <tr key={`weeklyReport-${id}` /**NOSONAR */}>
                            <td>{each.dtAttendance}</td>
                            <td>
                              <div
                                className="flex-container-table"
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  width: "100%",
                                }}
                              >
                                <>
                                  <div
                                    className={getClassName()}
                                    style={{ backgroundColor: val[0] }}
                                  ></div>
                                  <div
                                    className={getClassName()}
                                    style={{ backgroundColor: val[1] }}
                                  ></div>
                                  <div
                                    className={getClassName()}
                                    style={{ backgroundColor: val[2] }}
                                  ></div>
                                  <div
                                    className={getClassName()}
                                    style={{ backgroundColor: val[3] }}
                                  ></div>
                                </>
                              </div>
                            </td>

                            <td>{each.workingHrs}</td>
                            <td style={{ textAlign: "center" }}>
                              {each.presentStatus}
                            </td>
                          </tr>
                        );
                      })}
                    </table>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              border: "0.4px solid #ccc",
              position: "absolute",
              bottom:
                window.innerHeight > 700
                  ? window.innerHeight > 720
                    ? -3
                    : 0
                  : -7,
            }}
            // '#F94F5E'
            className="col-sm-12 col-md-12 col-lg-12"
          >
            {pageNo === 1 ? (
              <div
                className={"col-sm-2 col-md-2 col-lg-2 flex-pagination "}
                style={{ marginTop: "5px" }}
              >
                <div
                  style={{
                    marginTop: "7px",
                    color: "gray",
                    cursor: "default",
                  }}
                >
                  {" "}
                  &lt;{" "}
                </div>
              </div>
            ) : (
              <div
                className={"col-sm-2 col-md-2 col-lg-2 flex-pagination"}
                style={{ marginTop: "5px", cursor: "pointer" }}
                onClick={() =>
                  setPageToDisplay(pageNo > 1 ? pageNo - 1 : pageNo)
                }
              >
                <div
                  style={{
                    marginTop: "7px",
                    cursor: "pointer",
                    color: "black",
                  }}
                >
                  {" "}
                  &lt;{" "}
                </div>
              </div>
            )}
            <div
              className="col-sm-2 col-md-2 col-lg-2 flex-pagination"
              style={{
                backgroundColor: pageNo === 1 ? "#F94F5E" : "",
                marginTop: "5px",
                cursor: "pointer",
              }}
              onClick={() => setPageToDisplay(1)}
            >
              <div
                style={{
                  marginTop: "7px",
                  cursor: "pointer",
                  color: pageNo === 1 ? "white" : "black",
                }}
              >
                1
              </div>
            </div>
            <div
              className="col-sm-2 col-md-2 col-lg-2 flex-pagination"
              style={{
                marginTop: "5px",
                backgroundColor: pageNo === 2 ? "#F94F5E" : "",
                cursor: "pointer",
              }}
              onClick={() => setPageToDisplay(2)}
            >
              <div
                style={{
                  marginTop: "7px",
                  cursor: "pointer",
                  color: pageNo === 2 ? "white" : "black",
                }}
              >
                2
              </div>
            </div>
            <div
              className="col-sm-2 col-md-2 col-lg-2 flex-pagination"
              style={{
                marginTop: "5px",
                backgroundColor: pageNo === 3 ? "#F94F5E" : "",
                cursor: "pointer",
              }}
              onClick={() => setPageToDisplay(3)}
            >
              <div
                style={{
                  marginTop: "7px",
                  cursor: "pointer",
                  color: pageNo === 3 ? "white" : "black",
                }}
              >
                3
              </div>
            </div>
            {pageNo === 3 ? (
              <div
                className={"col-sm-2 col-md-2 col-lg-2 flex-pagination"}
                style={{ marginTop: "5px" }}
              >
                <div
                  style={{
                    marginTop: "7px",
                    color: "gray",
                    cursor: "default",
                  }}
                >
                  {" "}
                  &gt;{" "}
                </div>
              </div>
            ) : (
              <div
                className={"col-sm-2 col-md-2 col-lg-2 flex-pagination"}
                style={{ marginTop: "5px", cursor: "pointer" }}
                onClick={() =>
                  setPageToDisplay(pageNo < 3 ? pageNo + 1 : pageNo)
                }
              >
                <div
                  style={{
                    marginTop: "7px",
                    cursor: "pointer",
                    color: "black",
                  }}
                >
                  &gt;
                </div>
              </div>
            )}
            <div
              className="col-sm-4 col-md-4 col-lg-4"
              style={{
                marginLeft: "auto",
                marginBottom: 7,
                display: "flex",
                justifyContent: "flex-end",
                fontSize: '.92rem',
                fontWeight: 700,
                color: '#333333',
              }}
            >
              {pageNo} - 3 of 3 pages ({length} items)
            </div>
          </div>
        </div>}
      </div>

      {isLoading && (
        <div
          style={{
            zIndex: 999999,
            position: "absolute",
            marginTop: "20%",
            marginLeft: "40%",
          }}
        >
          <ProgressBar />
        </div>
      )}
      {/* </div> */}
    </>
  );
};
WeeklyReport.propTypes = {
  //   second: PropTypes.third
};

const mapStateToProps = ({ meetingcategory, lnareducer, auth }) => {
  const { authUser } = auth;
  const { categoryName } = meetingcategory;
  const { applyForData, startMonth, startMonthMonthlyReport, weekOrMonth, viewClicked, isLoading } =
    lnareducer;
  return {
    authUser,
    categoryName,
    applyForData,
    startMonth,
    startMonthMonthlyReport,
    weekOrMonth,
    viewClicked,
    isLoading
  };
};

export default connect(mapStateToProps, { updateLnAData, applyLeave })(
  WeeklyReport
);
