/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable no-mixed-operators */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import DateFnsUtils from '@date-io/date-fns';
import leaveandattendance from '../../../../assets/images/leaveandattendance.png';
import ReactPaginate from "react-paginate";
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLessThan, faGreaterThan } from "@fortawesome/free-solid-svg-icons";
import LnAService from '../../../../services/lna/lnaservice';
import { getOnboardingMasterData, getLnAData } from '../../../../app/other/commonlayoutfunction';
import { updateCandidateData } from '../../../../redux/actions/entryexit/candidateaction';
import { updateLnAData, saveWeekOffSwap } from '../../../../redux/actions/lna/lnaaction';
import ProgressBar from "../../../../components/progressbar/progressbar";
import { formatDateToYYYYMMDD, getMySqlDateFormat, getNotify } from "../../../../app/other/commonfunction";
import { ERROR } from "../../../../assets/constants/constants";
import LeavesBalance from '../leavesbalance/leavesbalance'
import { ComboBoxComponent } from '@syncfusion/ej2-react-dropdowns';
import ExcelJS from 'exceljs';
import { VIEWYEARLY } from '../../../../redux/constant/meetingcategoryconstant';

const sampleCss = `
.comboboxFYIDLB .e-input-group.e-control-wrapper.e-outline.e-ddl{
    border:none !important;
    box-shadow: none !important;
}
.comboboxFYIDLB .e-input-group.e-control-wrapper.e-outline.e-ddl.e-valid-input{
    border:none !important;
    box-shadow: none !important;
}
.comboboxFYIDLB .e-input-group.e-control-wrapper.e-outline.e-ddl.e-input-focus{
    border:none !important;
    box-shadow: none !important;
}
`

function AllTeamMemberReport(props) { // NOSONAR
    const { getMasterData, inputChange, teamMemberReportData, parentState, from, viewClicked, selectedTeamMember, teamMemberYearlyDropDown,
        teamMemberSendData, categoryName, startMonth, endMonth, selectedTeamMemberId, startMonthMonthlyReport, financialYearData, starting, authUser, fyId, isLoading, showdownloadLeaveBalanceButton } = props;

    const { countryOfWork, businessUnit, subBusinessUnit, teamMember, monthId, teamId, } = parentState;


    const [state, setState] = useState({
        dropdownHeight: null,
        tableHeight: null,
        reportHeight: null,
        tableContainerHeight: null,
        width: null
    })
    const [minMaxDate, setMinMaxDate] = useState({});
    const [daysOfMonth, setDaysOfMonth] = useState([]);
    const [dayDates, setDayDates] = useState([]);
    const [all31day, setAll31day] = useState([]);
    const [swapWeeklyOffUserId, setSwapWeeklyOffUserId] = useState("");
    const [swapWeeklyOffDayId, setSwapWeeklyOffDayId] = useState([]);
    const [swapWeeklyOffDayRemoveId, setSwapWeeklyOffDayRemoveId] = useState([]);
    const [swapWeeklyOffSwapWithId, setSwapWeeklyOffSwapWithId] = useState([]);
    const [swapWeeklyOffSwapWithRemoveId, setSwapWeeklyOffSwapWithRemoveId] = useState([]);
    const [maxLimit, setMaxLimit] = useState(null);
    const [enableSwap, setEnableSwap] = useState(false);
    const [pageNumber, setPageNumber] = useState(0);
    const [isData, setIsData] = useState(false);
    const [showLeaveBalance, setShowLeaveBalance] = useState(false)
    const [disableSwapWeeklyOff, setDisableSwapWeeklyOff] = useState(true)
    const [swapButton, setSwapButton] = useState("N")
    const isSixteenEighty = window.innerHeight < 932;
    const isSixteenHundred = window.innerHeight < 782;
    const isFourteenHundred = window.innerHeight < 782 && window.innerWidth < 1441;
    const isOneTwentyFiveScale = window.innerHeight < 747;
    const isOneTwentyFiveInSixteenEighty = window.innerHeight < 723;
    const isThirteenHundred = window.innerHeight < 650;
    const isOneTwentyFiveInSixteenHundred = window.innerHeight < 603;

    const isOneTwentyFiveScaleBookmarkOn = window.innerHeight < 715;


    // useEffect(() => {
    //     getInfo()
    // }, []);



    const getInfo = async () => {
        const lnaData = await getLnAData(props.authUser, "FC");
        setSwapButton(lnaData && lnaData.swapWeeklyOff[0] ? lnaData.swapWeeklyOff[0].Name : "")
        updateDimensions();
        getDropdownData();
        getMasterData("lnaReport");
        getMasterData("dropDownData");
        getTeamMemberPerPage();
    }

    useEffect(() => {
        handleCancel();

        props.updateLnAData({
            viewClicked: false
        })
    }, [monthId]);


    useEffect(() => {
        if (categoryName === VIEWYEARLY) {            
            console.log("")
            props.updateLnAData({
                startMonth: getMySqlDateFormat(new Date()).split(' ')[0],
                endMonth: getMySqlDateFormat(new Date()).split(' ')[0]
            })

            setTimeout(() => {
                getYearlyReportData();
            }, 700);
        }
    }, [categoryName]);


    useEffect(() => {
        if (document.getElementById('teamMemberAllTeamReport') === null) return
        if (from === 'notyearly') {
            setTimeout(() => {
                document.getElementById('teamMemberAllTeamReport').ej2_instances[0].value = [props.authUser.toString()];
            }, 500)
        }
    }, [])


    useEffect(() => {
        if (viewClicked) {
            if (from === 'yearly') {
                getYearlyReportData()
            } else if (from === 'notyearly') {
                setTimeout(() => {
                    getMonthsData();
                    props.updateLnAData({ viewClicked: false })
                }, 500);
            }
        }
    }, [viewClicked])


    useEffect(() => {
        if (props.viewReport === '2' || props.viewReport === '3') {
            props.updateLnAData({ starting: true, })
        }
        getMonthsData();
        props.updateLnAData({ viewClicked: false })
    }, []);



    const getMonthsData = () => {
        if (startMonthMonthlyReport) {
            const dateFns = new DateFnsUtils();
            const selectedMonth = new Date(startMonthMonthlyReport);

            let getSelectedMonth = selectedMonth.getMonth();
            let getSelectedYear = selectedMonth.getFullYear();

            let minDate = new Date(getSelectedYear, getSelectedMonth, 1);
            let minDateFormat = dateFns.format(minDate, "yyyy-MM-dd");

            let maxDate = new Date(getSelectedYear, getSelectedMonth + 1, 0);
            let maxDateFormat = dateFns.format(maxDate, "yyyy-MM-dd");

            getDaysAndDates(minDateFormat, maxDateFormat);
            handleTeamMonthlyReport();
            // if(viewClicked){
            //     getDaysAndDates(minDateFormat, maxDateFormat);
            //     handleTeamMonthlyReport();
            // }

        }
    }


    const getTeamMemberPerPage = () => { // NOSONAR
        if (isOneTwentyFiveInSixteenHundred) {
            return from === 'yearly' ? 4 : 2
        } else if (isThirteenHundred) {
            return from === 'yearly' ? 5 : 3
        }
        else if (isOneTwentyFiveScaleBookmarkOn) {
            return from === 'yearly' ? 5 : 4
        }
        else if (isOneTwentyFiveInSixteenEighty) {
            return from === 'yearly' ? 7 : 5
        } else if (isOneTwentyFiveScale) {
            return 5
        } else if (isSixteenHundred) {
            return from === 'yearly' ? 7 : 6
        } else {
            return from === 'yearly' ? 11 : 9
        }
    }

    const getCharactersPerUser = () => { // NOSONAR
        const colorBoxCount = minMaxDate.colorClusterData.length;
        if (isThirteenHundred) {
            return colorBoxCount > 4 ? 4 : 9
        } else if (isFourteenHundred) {
            return colorBoxCount > 4 ? 4 : 6
        } else if (isOneTwentyFiveScale) {
            return colorBoxCount > 4 ? 5 : 10
        } else if (isSixteenHundred) {
            return colorBoxCount > 4 ? 5 : 12
        } else if (isSixteenEighty) {
            return colorBoxCount > 4 ? 5 : 13
        } else {
            return 15
        }
    }

    const getCellWidth = () => {
        switch (window.innerHeight) {
            case 757:
                return 25
            case 625:
            case 657:
                return 20
            case 577:
                return 20
            case 649:
                return 24
            case 698:
            case 722:
                return 24
            default:
                return 30
        }
    }

    const getHalfCellWidth = () => {
        switch (window.innerHeight) {
            case 757:
                return 11.5
            case 625:
            case 657:
                return 9
            case 577:
                return 9
            case 649:
                return 11
            case 698:
            case 722:
                return 11
            default:
                return 14
        }
    }

    const getSummaryCellWidth = () => {
        switch (window.innerHeight) {
            case 757:
                return 19
            case 625:
            case 657:
                return 15
            case 577:
                return 18
            case 698:
            case 722:
                return 20
            default:
                return 23
        }
    }
    let teamMemberPerPage = getTeamMemberPerPage()
    let pagesVisited = pageNumber * teamMemberPerPage;

    const updateDimensions = () => {
        const updateHeight =
            (window.innerHeight ||
                document.documentElement.clientHeight ||
                document.body.clientHeight);

        const updateWidth = (window.innerWidth ||
            document.documentElement.clientWidth ||
            document.body.clientWidth);

        setState({
            dropdownHeight: updateHeight - 860,
            reportHeight: updateHeight - 265,
            tableHeight: updateHeight == 563 ? updateHeight - 291 : updateHeight == 611 && updateHeight - 289,
            tableContainerHeight: updateHeight - 265,
            width: updateWidth - 176
        });
    }

    const getDropdownData = async () => {
        const onboardingMasterData = await getOnboardingMasterData(props.authUser, 'All');

        props.updateCandidateData(onboardingMasterData);
    }

    const getMonthlyReportMasterData = async (users, monthIds, country = 0, businessUnit = 0, subBusinessUnit = 0) => { // NOSONAR
        if (monthIds) {
            props.updateLnAData({
                isLoading: true
            })
            const monthlyReportData = await LnAService.getMonthlyReportData(props.authUser, users, monthIds, country === 101 ? 768 : country, businessUnit, subBusinessUnit);
            if (monthlyReportData?.Result.length > 0) {
                const { minDate, maxDate, allDates, allDays, colorClusterData } = monthlyReportData.Result[0];

                const minMaxDateMaster = {
                    minDate,
                    maxDate,
                    allDates,
                    allDays,
                    colorClusterData,
                }

                const updatedMonthlyReportData = {
                    "Result": monthlyReportData.Result.map(report => {
                        const colorOrderMap = new Map();
                        report.colorClusterData.forEach(item => colorOrderMap.set(item.symbol, item.ord));

                        const sortedLeaveSummary = report.leaveSummary.sort((a, b) => {
                            const ordA = colorOrderMap.get(a.symbol);
                            const ordB = colorOrderMap.get(b.symbol);
                            return ordA - ordB;
                        });

                        return {
                            ...report,
                            leaveSummary: sortedLeaveSummary
                        };
                    })
                };

                setMinMaxDate(minMaxDateMaster);
                inputChange(updatedMonthlyReportData.Result, "teamMemberReportDataChangeFirst")
                setIsData(false);
            } else {
                setIsData(true);
                props.updateLnAData({
                    isLoading: true
                })
            }
        }
        props.updateLnAData({
            isLoading: false
        })
    }
    const getYearlyReportData = async () => {
        props.updateLnAData({
            isLoading: true
        })
        const monthlyReportData = await LnAService.getYearlyReportData(props.authUser, startMonth, endMonth, selectedTeamMemberId ? selectedTeamMemberId : props.authUser);
        if (monthlyReportData && monthlyReportData.Result && monthlyReportData.Result.length > 0) {
            const { minDate, maxDate, allDates, allDays, colorClusterData } = monthlyReportData.Result[0];

            const minMaxDateMaster = {
                minDate,
                maxDate,
                allDates,
                allDays,
                colorClusterData,
            }
            setMinMaxDate(minMaxDateMaster);
            setAll31day(allDates)
            inputChange(monthlyReportData.Result, "teamMemberReportDataChange");

            props.updateLnAData({
                viewClicked: false,
            });
            setIsData(false)
        }
        else {
            setIsData(true);
            props.updateLnAData({
                isLoading: true
            })
        }
        props.updateLnAData({
            isLoading: false
        })
    }



    const getDaysAndDates = (minDate, maxDate) => {
        const dateFns = new DateFnsUtils();

        let firstDay = new Date(minDate).getDate();

        let lastDay = new Date(maxDate).getDate();


        let month = new Date(minDate).getMonth();

        let year = new Date(minDate).getFullYear();


        let days = [];
        let daysDatesArr = [];

        for (let i = firstDay; i <= lastDay; i++) {
            let day = new Date(year, month, i);

            let dayName = dateFns.format(day, "eee");
            let dayDate = dateFns.format(day, "dd");


            days.push(dayName);
            daysDatesArr.push(dayDate);

        }

        setDaysOfMonth(days);
        setDayDates(daysDatesArr);
    }

    const swapWeeklyOfOnChange = (e, userId) => {
        if (e.checked) {
            setSwapWeeklyOffUserId(userId);
        } else {
            setSwapWeeklyOffUserId("");
            setSwapWeeklyOffDayId([]);
            setEnableSwap(false);
            setSwapWeeklyOffSwapWithId([]);
            setSwapWeeklyOffDayRemoveId([]);
            setSwapWeeklyOffSwapWithRemoveId([]);
        }
    }


    const exportToExcel = (data) => {
        if (!data || !Array.isArray(data)) {
            console.error("Invalid data source");
            return;
        }

        // Create a new workbook and worksheet
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('leave_data');
        // Define the headers for the Excel sheet
        const headers = [
            "Emp Code",
            "Employee Name",
            "Date of Birth",
            "Business Unit / Function",
            "Sub Business Unit / Sub Function",
            "Practice Area",
            "Layer",
            "Sub Layer",
            "Date of Joining",
            "Employment Status",
            // Add more headers here
        ];
        // Extract the date headers from your data source

        const dateHeaders = data[0]?.allDates?.slice(0, 32) || [];

        const formattedDateHeaders = dateHeaders.map((date, dateIdx) => {
            return date ? `${daysOfMonth[dateIdx]}, ${getFormattedDate(date, data[0])}` : "";
        });

        // Add the date headers to the headers array
        headers.push(...formattedDateHeaders);
        //getting all the column for leave summary. In minMaxDate headers of summery details already present.
        const summaryHeaders = minMaxDate?.colorClusterData?.map(ele => ele.symbol);
        //Add summary headers to the heares array
        headers.push(...summaryHeaders);
        // Add the headers to the worksheet
        worksheet.addRow(headers);
        // Add the data from your source to the worksheet
        data.forEach((item) => {
            if (item.teamMemberId === "null") {
                return; // Skip rows with null teamMemberId
            }

            const rowData = [
                item.empCode || "",
                item.teamMemberName || "", // Handle missing data
                formateJoiningDate(item.dateOfBirth) || "",
                item.businessUnit || "",
                item.subPnlName || "",
                item.practiceArea || "",
                item.layerName || "",
                item.subLayerName || "",
                formateJoiningDate(item.joiningDate) || "",
                item.activeOrInactive || "",
                // Add more fields from your data source as needed
            ];

            // Extract the date values from your data and add them to the row
            const attendance = (item.leaveData || []).map((attend) => {
                return attend[0] || ""; // Handle missing data
            });
            const leaveSummary = (item.leaveSummary || []).map(summ => summ.count)
            rowData.push(...attendance);
            rowData.push(...leaveSummary);
            // Add the row to the worksheet
            worksheet.addRow(rowData);
        });

        // Apply center alignment to all cells
        worksheet.eachRow((row) => {
            row.alignment = { vertical: 'middle', horizontal: 'center' };
        });

        // Auto-size columns based on content
        worksheet.columns.forEach((column) => {
            let maxLength = 0;
            column.eachCell({ includeEmpty: true }, (cell) => {
                const text = cell.text || '';
                const cellLength = text.length;
                if (cellLength > maxLength) {
                    maxLength = cellLength;
                }
            });
            column.width = maxLength < 10 ? 10 : maxLength + 2; // Minimum width of 10 characters
        });

        // Create a buffer for the Excel file
        workbook.xlsx.writeBuffer().then((buffer) => {
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = URL.createObjectURL(blob);

            // Create a hidden anchor element to trigger the download
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;

            let currentDate = new Date();
            const formattedDate = formatDateToYYYYMMDD(currentDate)
            const fileName = (`${formattedDate}_Attendance Report - Monthly`);

            a.download = `${fileName}.xlsx`;

            // Trigger the click event to start the download
            document.body.appendChild(a);
            a.click();

            // Clean up
            document.body.removeChild(a);
            URL.revokeObjectURL(url);
        });
    };

    // Helper function to format the date (e.g., "01/08")
    const getFormattedDate = (date, minMaxData) => {
        if (!date) return "";
        const month = new Date(minMaxData.minDate).getMonth() + 1; // Months are 0-indexed
        return `${date.toString().padStart(2, '0')}/${month.toString().padStart(2, '0')}`;
    };
    //input-type=> '2000-06-10T00:00:00.000Z', output-type=>'10-Jun-2000'
    const formateJoiningDate = (date_string) => {
        const parsed_date = new Date(date_string);
        const day = parsed_date.getUTCDate().toString().padStart(2, '0');
        const month = parsed_date.toLocaleString('en-US', { month: 'short' });
        const year = parsed_date.getUTCFullYear();
        const formatted_date = `${day}-${month}-${year}`;
        return formatted_date;
    }

    const handleSwap = (type, month) => { // NOSONAR
        if (type === "swapDay" && !enableSwap) {
            const swapWeeklyOffDayIdArr = swapWeeklyOffDayId;
            const swapWeeklyOffDayIdRemoveArr = swapWeeklyOffDayRemoveId;

            if (swapWeeklyOffDayId && !swapWeeklyOffDayId.includes(month)) {
                setSwapWeeklyOffDayId(prevState => [...prevState, month]);

                if (swapWeeklyOffDayIdRemoveArr && swapWeeklyOffDayIdRemoveArr.length > 0) {
                    const index = swapWeeklyOffDayIdRemoveArr.indexOf(month);
                    if (index > -1) {
                        swapWeeklyOffDayIdRemoveArr.splice(index, 1);
                    }

                    setSwapWeeklyOffDayRemoveId(swapWeeklyOffDayIdRemoveArr);
                }
            } else {
                setSwapWeeklyOffDayRemoveId(prevState => [...prevState, month]);

                if (swapWeeklyOffDayIdArr && swapWeeklyOffDayIdArr.length > 0) {
                    const index = swapWeeklyOffDayIdArr.indexOf(month);
                    if (index > -1) {
                        swapWeeklyOffDayIdArr.splice(index, 1);
                    }

                    setSwapWeeklyOffDayId(swapWeeklyOffDayIdArr);
                }
            }
        }

        if (type === "swapDayWith" && enableSwap) {
            const swapWeeklyOffSwapWithIdArr = swapWeeklyOffSwapWithId;
            const swapWeeklyOffSwapWithIdRemoveArr = swapWeeklyOffSwapWithRemoveId;

            if (swapWeeklyOffSwapWithId && !swapWeeklyOffSwapWithId.includes(month)) {
                if (swapWeeklyOffSwapWithId.length < maxLimit) {
                    setSwapWeeklyOffSwapWithId(prevState => [...prevState, month]);
                }

                if (swapWeeklyOffSwapWithIdRemoveArr && swapWeeklyOffSwapWithIdRemoveArr.length > 0) {
                    const index = swapWeeklyOffSwapWithIdRemoveArr.indexOf(month);
                    if (index > -1) {
                        swapWeeklyOffSwapWithIdRemoveArr.splice(index, 1);
                    }

                    setSwapWeeklyOffSwapWithRemoveId(swapWeeklyOffSwapWithIdRemoveArr);
                }
            } else {
                setSwapWeeklyOffSwapWithRemoveId(prevState => [...prevState, month]);

                if (swapWeeklyOffSwapWithIdArr && swapWeeklyOffSwapWithIdArr.length > 0) {
                    const index = swapWeeklyOffSwapWithIdArr.indexOf(month);
                    if (index > -1) {
                        swapWeeklyOffSwapWithIdArr.splice(index, 1);
                    }

                    setSwapWeeklyOffSwapWithId(swapWeeklyOffSwapWithIdArr);
                }
            }

            if (swapWeeklyOffSwapWithId.length >= maxLimit) {
                getNotify(ERROR, `You can select max ${maxLimit} swap only.`);
            }
        }
    }

    const handleNextButton = () => {
        setEnableSwap(true);
        setMaxLimit(swapWeeklyOffDayId.length);
    }

    const pageCount = Math.ceil(teamMemberReportData.length / teamMemberPerPage);

    const changePage = ({ selected }) => {
        setPageNumber(selected);
    };

    const getLeaveBalance = () => {
        setShowLeaveBalance(true)
    }
    const closePopUp = () => {
        setShowLeaveBalance(false)
    }

    const getBackgroundCss = (leave) => {
        if (leave[0] && (leave[2] !== '' || leave[2] !== null)) {
            return `linear-gradient(to right,${leave[1]} 50%, ${leave[2]} 50%)`
        } else {
            return `linear-gradient(to right,white 50%, white 50%)`
        }
    }

    const monthReportDayCell = (teamReport, leave, i) => { // NOSONAR
        const dateFns = new DateFnsUtils();

        let currentDate = new Date();
        let getCurrentMonth = currentDate.getMonth();
        let getCurrentYear = currentDate.getFullYear();
        currentDate.setHours(0, 0, 0);
        currentDate = currentDate.getDate() - 1;

        const selectedMonth = new Date(startMonthMonthlyReport);
        let getSelectedMonth = selectedMonth.getMonth();
        let getSelectedYear = selectedMonth.getFullYear();

        let sendDate = new Date(getSelectedYear, getSelectedMonth, i + 1);
        let sendDateFormat = dateFns.format(sendDate, "yyyy-MM-dd");

        let selectedDateMonth = new Date(getSelectedYear, getSelectedMonth, 1);
        selectedDateMonth.setHours(0, 0, 0);
        let currentMonthDate = new Date(getCurrentYear, getCurrentMonth, 1);
        currentMonthDate.setHours(0, 0, 0);

        const selectedMonthTime = selectedDateMonth.getTime();
        const currentMonthTime = currentMonthDate.getTime();

        const isCurrentMonth = currentMonthTime === selectedMonthTime;

        return (
            <div
                onClick={((teamReport.teamMemberId === swapWeeklyOffUserId) && swapWeeklyOffUserId && (leave[3] === "Weekly off") && isCurrentMonth && i > currentDate)
                    || (teamReport.teamMemberId === swapWeeklyOffUserId) && swapWeeklyOffUserId && (leave[3] === "Weekly off") && !isCurrentMonth ? () => handleSwap("swapDay", sendDateFormat)
                    : ((teamReport.teamMemberId === swapWeeklyOffUserId) && swapWeeklyOffUserId && !leave[0] && isCurrentMonth && i > currentDate /**NOSONAR */)
                        || ((teamReport.teamMemberId === swapWeeklyOffUserId) && swapWeeklyOffUserId && !leave[0] && !isCurrentMonth)
                        ? () => handleSwap("swapDayWith", sendDateFormat) : null /**NOSONAR */}
                style={{
                    backgroundImage: from === 'yearly' ? getBackgroundCss(leave) : '',
                    backgroundColor: (teamReport.teamMemberId === swapWeeklyOffUserId) && swapWeeklyOffUserId && (swapWeeklyOffSwapWithId && swapWeeklyOffSwapWithId.length > 0 && swapWeeklyOffSwapWithId.includes(sendDateFormat) && swapWeeklyOffDayId && swapWeeklyOffDayId.length > 0) && swapWeeklyOffDayId.includes(sendDateFormat) && enableSwap
                        ? "#fff"
                        : ((teamReport.teamMemberId === swapWeeklyOffUserId) && swapWeeklyOffUserId && swapWeeklyOffDayId && swapWeeklyOffDayId.length > 0) && swapWeeklyOffDayId.includes(sendDateFormat) /**NOSONAR */
                            || ((teamReport.teamMemberId === swapWeeklyOffUserId) && swapWeeklyOffUserId && swapWeeklyOffSwapWithId && swapWeeklyOffSwapWithId.includes(sendDateFormat) && enableSwap)
                            ? "#F94F5E" : ((teamReport.teamMemberId === swapWeeklyOffUserId) && swapWeeklyOffUserId && swapWeeklyOffDayRemoveId && swapWeeklyOffDayRemoveId.includes(sendDateFormat))  /**NOSONAR */
                                || (teamReport.teamMemberId === swapWeeklyOffUserId) && swapWeeklyOffUserId && (leave[3] === "Weekly off")
                                ? leave[1] : (!leave[0] && isCurrentMonth && i > currentDate) || (!leave[0] && !isCurrentMonth) ? "#fff" : leave[1] ? leave[1] : leave[2], /**NOSONAR */
                    width: from === 'yearly' ? getCellWidth() : leave[1] && leave[2] ? getHalfCellWidth() : getCellWidth(), /**NOSONAR */
                    height: "23px",
                    cursor: from !== 'yearly' ? (teamReport.teamMemberId === swapWeeklyOffUserId) && swapWeeklyOffUserId && !leave[0] && isCurrentMonth && i > currentDate && enableSwap /**NOSONAR */
                        || (teamReport.teamMemberId === swapWeeklyOffUserId) && swapWeeklyOffUserId && !leave[0] && !isCurrentMonth && enableSwap
                        ? "pointer" : "" : '',
                    border: (from === 'yearly' && leave[0] === null) ? "1px solid #A6A6A6" : from === 'yearly' ? (leave[2] === null) ? 'none' : (teamReport.teamMemberId === swapWeeklyOffUserId && swapWeeklyOffUserId && swapWeeklyOffSwapWithId && swapWeeklyOffSwapWithId.length > 0 && swapWeeklyOffSwapWithId.includes(i) && enableSwap) /**NOSONAR */
                        ? "" : (teamReport.teamMemberId === swapWeeklyOffUserId) && swapWeeklyOffUserId && (!leave[0] && i > currentDate && enableSwap) /**NOSONAR */
                            || (teamReport.teamMemberId === swapWeeklyOffUserId) && swapWeeklyOffUserId && (swapWeeklyOffSwapWithId && swapWeeklyOffSwapWithId.length > 0 && swapWeeklyOffSwapWithId.includes(i) && swapWeeklyOffDayId && swapWeeklyOffDayId.length > 0) && swapWeeklyOffDayId.includes(i) && enableSwap
                            ? "1px solid #F94F5E" : !leave[0] ? "1px solid #A6A6A6" : "" : (teamReport.teamMemberId === swapWeeklyOffUserId && swapWeeklyOffUserId && swapWeeklyOffSwapWithId && swapWeeklyOffSwapWithId.length > 0 && swapWeeklyOffSwapWithId.includes(i) && enableSwap) /**NOSONAR */
                        ? "" :
                        (teamReport.teamMemberId === swapWeeklyOffUserId && swapWeeklyOffUserId && swapWeeklyOffSwapWithId && swapWeeklyOffSwapWithId.length > 0 && swapWeeklyOffSwapWithId.includes(sendDateFormat) && enableSwap) /**NOSONAR */
                            ? "" : (teamReport.teamMemberId === swapWeeklyOffUserId) && swapWeeklyOffUserId && (!leave[0] && isCurrentMonth && i > currentDate && enableSwap) /**NOSONAR */
                                || (teamReport.teamMemberId === swapWeeklyOffUserId) && swapWeeklyOffUserId && (!leave[0] && !isCurrentMonth && enableSwap) /**NOSONAR */
                                || (teamReport.teamMemberId === swapWeeklyOffUserId) && swapWeeklyOffUserId && (swapWeeklyOffSwapWithId && swapWeeklyOffSwapWithId.length > 0 && swapWeeklyOffSwapWithId.includes(sendDateFormat) && swapWeeklyOffDayId && swapWeeklyOffDayId.length > 0) && swapWeeklyOffDayId.includes(sendDateFormat) && enableSwap
                                ? "1px solid #F94F5E" : !leave[0] ? "1px solid #A6A6A6" : "", /**NOSONAR */
                    borderRight: (from === 'yearly' && leave[0] === null) ? "1px solid #A6A6A6" : from === 'yearly' ? (leave[2] === null) ? 'none' : (teamReport.teamMemberId === swapWeeklyOffUserId && swapWeeklyOffUserId && swapWeeklyOffSwapWithId && swapWeeklyOffSwapWithId.length > 0 && swapWeeklyOffSwapWithId.includes(i) && enableSwap) /**NOSONAR */
                        ? "" : (teamReport.teamMemberId === swapWeeklyOffUserId) && swapWeeklyOffUserId && (!leave[0] && i > currentDate && enableSwap) /**NOSONAR */
                            || (teamReport.teamMemberId === swapWeeklyOffUserId) && swapWeeklyOffUserId && (swapWeeklyOffSwapWithId && swapWeeklyOffSwapWithId.length > 0 && swapWeeklyOffSwapWithId.includes(i) && swapWeeklyOffDayId && swapWeeklyOffDayId.length > 0) && swapWeeklyOffDayId.includes(i) && enableSwap /**NOSONAR */
                            ? "1px solid #F94F5E" : !leave[0] ? "1px solid #A6A6A6" /**NOSONAR */ : "" :
                        (teamReport.teamMemberId === swapWeeklyOffUserId && swapWeeklyOffUserId && swapWeeklyOffSwapWithId && swapWeeklyOffSwapWithId && swapWeeklyOffSwapWithId.length > 0 && swapWeeklyOffSwapWithId.includes(sendDateFormat) && enableSwap) /**NOSONAR */
                            ? "" : (teamReport.teamMemberId === swapWeeklyOffUserId) && swapWeeklyOffUserId && (!leave[0] && isCurrentMonth && i > currentDate && enableSwap) /**NOSONAR */
                                || (teamReport.teamMemberId === swapWeeklyOffUserId) && swapWeeklyOffUserId && (!leave[0] && !isCurrentMonth && enableSwap)
                                || (teamReport.teamMemberId === swapWeeklyOffUserId) && swapWeeklyOffUserId && (swapWeeklyOffSwapWithId && swapWeeklyOffSwapWithId.length > 0 && swapWeeklyOffSwapWithId.includes(sendDateFormat) && swapWeeklyOffDayId && swapWeeklyOffDayId.length > 0) && swapWeeklyOffDayId.includes(sendDateFormat) && enableSwap
                                ? "1px solid #F94F5E" : !leave[0] ? "1px solid #A6A6A6" : (leave[1] && leave[2] && from !== 'yearly') ? `${getHalfCellWidth()}px solid ${leave[2]}` : `1px solid #fff`, /**NOSONAR */
                    borderLeft: (from === 'yearly' && leave[0] === null) ? "1px solid #A6A6A6" : from === 'yearly' ? (leave[2] === null) ? 'none' : (teamReport.teamMemberId === swapWeeklyOffUserId && swapWeeklyOffUserId && swapWeeklyOffSwapWithId && swapWeeklyOffSwapWithId.length > 0 && swapWeeklyOffSwapWithId.includes(i) && enableSwap) /**NOSONAR */
                        ? "" : (teamReport.teamMemberId === swapWeeklyOffUserId) && swapWeeklyOffUserId && (!leave[0] && i > currentDate && enableSwap) /**NOSONAR */
                            || (teamReport.teamMemberId === swapWeeklyOffUserId) && swapWeeklyOffUserId && (swapWeeklyOffSwapWithId && swapWeeklyOffSwapWithId.length > 0 && swapWeeklyOffSwapWithId.includes(i) && swapWeeklyOffDayId && swapWeeklyOffDayId.length > 0) && swapWeeklyOffDayId.includes(i) && enableSwap
                            ? "1px solid #F94F5E" : !leave[0] ? "1px solid #A6A6A6" : "" /**NOSONAR */ :
                        (teamReport.teamMemberId === swapWeeklyOffUserId && swapWeeklyOffUserId && swapWeeklyOffSwapWithId && swapWeeklyOffSwapWithId && swapWeeklyOffSwapWithId.length > 0 && swapWeeklyOffSwapWithId.includes(sendDateFormat) && enableSwap) /**NOSONAR */
                            ? "" : (teamReport.teamMemberId === swapWeeklyOffUserId) && swapWeeklyOffUserId && (!leave[0] && isCurrentMonth && i > currentDate && enableSwap) /**NOSONAR */
                                || (teamReport.teamMemberId === swapWeeklyOffUserId) && swapWeeklyOffUserId && (!leave[0] && !isCurrentMonth && enableSwap)
                                || (teamReport.teamMemberId === swapWeeklyOffUserId) && swapWeeklyOffUserId && (swapWeeklyOffSwapWithId && swapWeeklyOffSwapWithId.length > 0 && swapWeeklyOffSwapWithId.includes(sendDateFormat) && swapWeeklyOffDayId && swapWeeklyOffDayId.length > 0) && swapWeeklyOffDayId.includes(sendDateFormat) && enableSwap
                                ? "1px solid #F94F5E" : !leave[0] ? "1px solid #A6A6A6" : (leave[1] && leave[2] && from !== 'yearly') ? `${getHalfCellWidth()}px solid ${leave[1]}` : `1px solid #fff`, /**NOSONAR */
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "flex-start",
                    fontWeight: 400,
                    color: "#4B4B55",
                    paddingTop: ".25rem",
                    fontSize: ".65rem",
                }}>
                {leave && leave[0] ? leave[0] : ""}
            </div>
        )

    }
    const handleSwapWeeklyOff = () => {
        setDisableSwapWeeklyOff(prevState => !prevState);
    }


    const handleCancel = () => {
        setSwapWeeklyOffUserId("");
        setSwapWeeklyOffDayId([]);
        setEnableSwap(false);
        setSwapWeeklyOffSwapWithId([]);
        setSwapWeeklyOffDayRemoveId([]);
        setSwapWeeklyOffSwapWithRemoveId([]);
        setDisableSwapWeeklyOff(true);
    }

    const handleTeamMonthlyReport = () => { // NOSONAR
        if (from !== 'yearly') {
            let teamMemberids = teamMember.length > 0 ? teamMember.join(",") : teamMemberSendData.length > 0 ? teamMemberSendData.join(",") : teamId && teamId.toString();

            inputChange([], "teamMemberReportDataChange")
            getMonthlyReportMasterData(
                starting ? [props.authUser] : teamMemberids,
                // NOSONAR
                getMySqlDateFormat(startMonthMonthlyReport),
                countryOfWork ? countryOfWork : 0,
                businessUnit ? businessUnit : 0,
                subBusinessUnit ? subBusinessUnit : 0,
            );
        }
    }

    const handleSave = () => {
        const data = {
            authUid: authUser,
            userId: parseInt(swapWeeklyOffUserId),
            type: "UPDATE",
            originalWeekOffDates: swapWeeklyOffDayId.join(","),
            latestWeekOffDates: swapWeeklyOffSwapWithId.join(","),
        }

        props.saveWeekOffSwap(data);
        setTimeout(() => {
            handleTeamMonthlyReport();
            handleCancel();
        }, 500);
    }
    let dataToBeMappedfrom = from === 'yearly' ? all31day : dayDates
    const inputChangeHandler = (e, type) => {
        if (type === 'fyId' && e) {
            props.updateLnAData({ fyId: e.itemData.Id });
        }
    }
    const getTeamMemberName = (selectedTeamMember) => {
        if (selectedTeamMember && selectedTeamMember.length > 0) {
            return selectedTeamMember[0].Name.split('(')[0]
        } else {
            let name = teamMemberYearlyDropDown.filter((item) => item.Id === authUser)
            return name[0].Name.split('(')[0]
        }
    }
    const getNoDataMsg = () => {
        try {
            if (props.viewReport === '1') return "No data to show. Select Month & Team Member to show data."
            if (props.viewReport === '2') return "No data to show. Select Month & Team Member to show data."
            if (props.viewReport === '3') return "No data to show. Select From Month, To Month & Team Member to show data."
        } catch (error) {

        }
    }

    const getNoDataContent = () => {
        return (
            <div className="allTeamReportMonth m-md" style={{ height: state.reportHeight - 20, backgroundColor: "#F3F3F6" }}>
                <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12" style={{ backgroundColor: "#fff", height: state.tableContainerHeight, borderRadius: "5px" }}>
                    <center style={{ marginTop: window.innerHeight < 700 ? '10%' : '15%' }}>

                        {!isLoading && (
                            <>
                                <img src={leaveandattendance} alt="Image Not Found" />
                                <div className=' pt-xs' style={{
                                    textAlign: 'center',
                                    font: 'normal normal normal 18px/21px Circular TT Book',
                                    letterSpacing: 0,
                                    color: '#939399',
                                    opacity: 1
                                }}>
                                    {getNoDataMsg()}
                                </div>
                            </>
                        )}
                    </center>
                </div>

            </div>
        )
    }
    return (
        <>
            {
                !isData &&
                <div className="allTeamReportMonth" style={{ height: state.reportHeight, backgroundColor: "#F3F3F6", display: 'flex', flexDirection: 'column', flex: 1 }}>
                    <style>{sampleCss}</style>
                    {teamMemberReportData && <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12" style={{ backgroundColor: "#fff", height: state.tableContainerHeight, borderRadius: "5px", flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', }}>
                            <div className="allTeamReportHeader">
                                <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4" style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                                    {
                                        from === 'yearly' &&
                                        <div className='teamMemberYearlyReport'>
                                            Team Member Yearly Report {selectedTeamMember.length > 0 && '-'} {selectedTeamMember.length > 0 && selectedTeamMember[0].Name.split('(')[0]}
                                        </div>
                                    }
                                </div>
                                <div style={{
                                    display: "flex",
                                    justifyContent: "flex-start",
                                    alignItems: "center",
                                }}>
                                    {from !== 'yearly' ? <>
                                        {swapButton === "Y" && <div
                                            className={`${disableSwapWeeklyOff ? "allTeamReportSwapDisableButton" : "allTeamReportSwapButton"} mr-md`}
                                            onClick={handleSwapWeeklyOff}
                                        >
                                            Swap Weekly Off
                                        </div>}
                                        {showdownloadLeaveBalanceButton === 'Y' && <div
                                            className="allTeamReportSwapDisableButton"
                                            onClick={() => exportToExcel(teamMemberReportData)}
                                            style={{ marginRight: 16 }}
                                        >
                                            Download
                                        </div>}
                                    </> :
                                        <>
                                            <div className="leaveBalanceYearlyReport" onClick={getLeaveBalance} style={{
                                                display: "flex",
                                                alignItems: "center",
                                            }}>
                                                <span className='spanleaveBalanceYearlyReport'>
                                                    Leave Balance
                                                </span>

                                            </div>


                                        </>}

                                </div>
                            </div>

                            <div style={{ overflow: "auto", height: state.tableContainerHeight - 100 }}>
                                {/* <table className="allTeamReportHeaderTable" style={{ width: state.width }}>
                                <thead>
                                    
                                </thead>
                            </table> */}
                                <table className="allTeamReportTable" style={{ width: "100%" }}>
                                    {teamMemberReportData && teamMemberReportData.length > 0 && <thead>
                                        {from !== 'yearly' && <tr>
                                            <th colSpan="1"></th>
                                            {daysOfMonth.map((day, i) => (
                                                <th
                                                    colSpan="1"
                                                    key={`${day}-${i}`} // NOSONAR
                                                    style={{
                                                        paddingLeft: i === (daysOfMonth.length - 1) ? ".3rem" : "",
                                                        textAlign: i === (daysOfMonth.length - 1) ? "left" : "",
                                                    }}
                                                >{day}</th>
                                            ))}
                                            {minMaxDate && minMaxDate.colorClusterData && minMaxDate.colorClusterData.length > 0 && minMaxDate.colorClusterData.map((colorCluster, i) => {
                                                return (
                                                    <th
                                                        key={`${colorCluster}-${i}`} // NOSONAR
                                                        colSpan="1"
                                                        style={{
                                                            paddingRight: i === (minMaxDate.colorClusterData.length - 1) ? ".3rem" : null,
                                                            paddingLeft: i === (minMaxDate.colorClusterData.length - 1) ? null : ".3rem",
                                                        }}
                                                    >
                                                        {colorCluster.symbol}
                                                    </th>
                                                )
                                            })}
                                        </tr>}
                                        <tr>
                                            {dataToBeMappedfrom && <th>

                                                <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", width: "10rem" }}>
                                                    <div className='ml-md mr-sm'>
                                                        {/* <CheckBoxComponent /> */}
                                                    </div>
                                                    {from !== 'yearly' &&
                                                        <div>
                                                            Team Members
                                                        </div>}

                                                </div>
                                            </th>}
                                            {dataToBeMappedfrom.map((date, i) => ( /**NOSONAR */
                                                <th
                                                    key={`${date}-${i}`} // NOSONAR
                                                    style={{
                                                        borderRight: i === (dataToBeMappedfrom.length - 1) ? "1px solid #f2f2f2" : "",
                                                        width: i === (dataToBeMappedfrom.length - 1) ? "5rem" : "",
                                                        textAlign: i === (dataToBeMappedfrom.length - 1) ? "left" : "",
                                                        paddingLeft: i === (dataToBeMappedfrom.length - 1) ? ".7rem" : "",
                                                    }}
                                                >
                                                    {date}
                                                </th>
                                            ))}
                                            {minMaxDate && minMaxDate.colorClusterData && minMaxDate.colorClusterData.length > 0 && minMaxDate.colorClusterData.map((colorCluster, i) => (<>
                                                {colorCluster &&
                                                    <th
                                                        key={`${colorCluster.leaveType}-${i}`} // NOSONAR
                                                        style={{
                                                            paddingLeft: ".8rem",
                                                            paddingRight: i === (minMaxDate.colorClusterData.length - 1) ? ".5rem" : null
                                                        }}
                                                    >
                                                        <TooltipComponent
                                                            // width={130}
                                                            position="BottomCenter"
                                                            className="pointer-cursor"
                                                            isSticky={false}
                                                            content={colorCluster.leaveType}
                                                            cssClass="customtooltip"
                                                        >
                                                            <div style={{
                                                                backgroundColor: colorCluster.color,
                                                                width: getSummaryCellWidth(),
                                                                height: "23px",
                                                                display: "flex",
                                                                justifyContent: "center",
                                                                alignItems: "flex-start",
                                                                fontWeight: 400,
                                                                color: "#4B4B55",
                                                                paddingTop: ".25rem",
                                                                fontSize: ".65rem",
                                                            }}>{colorCluster.symbol}</div>
                                                        </TooltipComponent>
                                                    </th>}
                                            </>))}
                                            {/* <th className="pr-md" style={{ paddingLeft: ".5rem" }}>
                                                    <TooltipComponent
                                                        // width={130}
                                                        position="BottomCenter"
                                                        className="pointer-cursor"
                                                        isSticky={false}
                                                        content={"Others"}
                                                        // cssClass="customtooltip"
                                                    >
                                                        <div style={{ backgroundColor: "#000", width: getSummaryCellWidth(), height: "23px" }}></div>
                                                    </TooltipComponent>
                                                </th> */}
                                        </tr>
                                    </thead>}
                                    <tbody>
                                        {teamMemberReportData && teamMemberReportData.length > 0 &&
                                            teamMemberReportData.slice(pagesVisited, pagesVisited + teamMemberPerPage).map((teamReport, i) => { // NOSONAR
                                                return (
                                                    <tr
                                                        key={`${teamReport.teamMemberId}-${i}`} // NOSONAR
                                                        className={teamReport.teamMemberId === swapWeeklyOffUserId && swapWeeklyOffUserId ? `teamMemberSelected` : ""}
                                                    >
                                                        {teamReport && <td>
                                                            <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                                                                <div className='ml-md mr-sm'>
                                                                    {(from !== 'yearly' && swapButton === "Y") && <CheckBoxComponent
                                                                        value={`${teamReport.teamMemberId}`}
                                                                        change={e => swapWeeklyOfOnChange(e, teamReport.teamMemberId)}
                                                                        cssClass="e-primary"
                                                                        name="teamMemberReport"
                                                                        checked={disableSwapWeeklyOff ? false : swapWeeklyOffUserId && (teamReport.teamMemberId === swapWeeklyOffUserId) ? true : false /**NOSONAR */}
                                                                        disabled={disableSwapWeeklyOff ? true : !swapWeeklyOffUserId ? false : teamReport.teamMemberId !== swapWeeklyOffUserId ? true : false /**NOSONAR */}
                                                                    />
                                                                    }

                                                                </div>

                                                                <div
                                                                    title={teamReport.teamMemberName}
                                                                    style={{
                                                                        cursor: "default",
                                                                        overflow: "hidden",
                                                                        whiteSpace: "nowrap",
                                                                        textOverflow: "ellipsis",
                                                                        width: "7rem",
                                                                        fontWeight: 400,
                                                                    }}
                                                                >
                                                                    {teamReport.teamMemberName}
                                                                </div>
                                                            </div>

                                                        </td>}

                                                        {teamReport && teamReport.leaveData && teamReport.leaveData.length > 0 && teamReport.leaveData.map((leave, iJ) => {

                                                            return (
                                                                <td
                                                                    key={`leave-${teamReport.teamMemberId}-${iJ}`} // NOSONAR
                                                                    style={{
                                                                        borderRight: iJ === (teamReport.leaveData.length - 1) ? "1px solid #f2f2f2" : "",
                                                                        width: iJ === (teamReport.leaveData.length - 1) ? "5rem" : "",
                                                                        textAlign: iJ === (teamReport.leaveData.length - 1) ? "left" : "",
                                                                        paddingLeft: iJ === (teamReport.leaveData.length - 1) ? ".4rem" : ".3rem",
                                                                        paddingRight: iJ === (teamReport.leaveData.length - 1) ? ".4rem" : null,
                                                                    }}
                                                                >
                                                                    {leave && leave.length > 0 && (<>
                                                                        {leave[3] && !enableSwap ?
                                                                            <TooltipComponent
                                                                                // width={130}
                                                                                position="BottomCenter"
                                                                                className="pointer-cursor"
                                                                                isSticky={false}
                                                                                content={leave[3]}
                                                                                cssClass="customtooltip"
                                                                            >
                                                                                {monthReportDayCell(teamReport, leave, iJ)}
                                                                            </TooltipComponent>
                                                                            : <>
                                                                                {monthReportDayCell(teamReport, leave, iJ)}
                                                                            </>}
                                                                    </>
                                                                    )}
                                                                </td>
                                                            )
                                                        })}


                                                        {teamReport && teamReport.leaveSummary && teamReport.leaveSummary.length > 0 && (
                                                            <>
                                                                {minMaxDate?.colorClusterData.map(clusterItem => {
                                                                    const matchedLeave = teamReport.leaveSummary.find(leave => leave.symbol === clusterItem.symbol);
                                                                    return (
                                                                        <td
                                                                            key={`${clusterItem.symbol}`} // Use a unique key for each td
                                                                            className="teamMemberNumbers"
                                                                            style={{
                                                                                paddingRight: clusterItem === minMaxDate.colorClusterData[minMaxDate.colorClusterData.length - 1] ? ".3rem" : null,
                                                                                paddingLeft: clusterItem === minMaxDate.colorClusterData[0] ? ".5rem" : null,
                                                                                textAlign: "center"
                                                                            }}
                                                                        >
                                                                            <TooltipComponent
                                                                                // width={130}
                                                                                position="BottomCenter"
                                                                                className="pointer-cursor"
                                                                                isSticky={false}
                                                                                content={clusterItem.leaveType}
                                                                                cssClass="customtooltip"
                                                                            >
                                                                                <div style={{ fontWeight: 400 }}>
                                                                                    {matchedLeave ? matchedLeave.leaveTotal : 0} {/* Show leave total if matched, otherwise 0 */}
                                                                                </div>
                                                                            </TooltipComponent>
                                                                        </td>
                                                                    );
                                                                })}
                                                            </>
                                                        )}
                                                    </tr>
                                                )
                                            })}
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        {!swapWeeklyOffUserId && <div className="teamMothReportPagination">
                            <div className='pt-lg'>
                                <ReactPaginate
                                    previousLabel={<FontAwesomeIcon
                                        icon={faLessThan}
                                        style={{ color: "#808080", fontSize: 12 }}
                                    />}
                                    nextLabel={<FontAwesomeIcon
                                        icon={faGreaterThan}
                                        style={{ color: "#808080", fontSize: 12 }}
                                    />}
                                    pageCount={pageCount}
                                    onPageChange={changePage}
                                    containerClassName={"paginationBttns"}
                                    previousLinkClassName={"previousBttn"}
                                    nextLinkClassName={"nextBttn"}
                                    disabledClassName={"paginationDisabled"}
                                    activeClassName={"paginationActive"}
                                />
                            </div>
                            <div className="teamMothReportPagesUi mr-sm pt-sm">
                                {pageNumber + 1} - {pageCount} of {pageCount} pages {teamMemberReportData && teamMemberReportData.length > 1 ? `(${teamMemberReportData.length} items)` : teamMemberReportData && teamMemberReportData.length === 1 ? `(1 item)` : `(0 item)`} { /** NOSONAR */}
                            </div>
                        </div>}

                        {swapWeeklyOffUserId && <div className="teamMothReportButtons mt-md mb-md pt-sm pr-sm">
                            {!enableSwap && <>
                                <div
                                    className={`${swapWeeklyOffDayId && swapWeeklyOffDayId.length > 0 ? "redBackgroundButton" : "disableBackgroundButton"} mr-md`}
                                    onClick={swapWeeklyOffDayId && swapWeeklyOffDayId.length > 0 ? handleNextButton : null}
                                >
                                    Next
                                </div>
                                <div
                                    className="redBorderButton"
                                    onClick={handleCancel}
                                >
                                    Cancel
                                </div>
                            </>}

                            {enableSwap && <>
                                <div
                                    className={`${swapWeeklyOffSwapWithId && swapWeeklyOffSwapWithId.length > 0 ? "redBackgroundButton" : "disableBackgroundButton"} mr-md`}
                                    onClick={handleSave}
                                >
                                    Save
                                </div>
                                <div
                                    className="redBorderButton"
                                    onClick={handleCancel}
                                >
                                    Cancel
                                </div>
                            </>}

                        </div>}
                        {

                            showLeaveBalance &&

                            <>
                                <div>
                                    <div className='allTeamReportHeaderLeaveBalance teamMemberYearlyReport' style={{ boxShadow: '-3px 0px 10px 1px #aaaa', width: 700 }}>
                                        &nbsp; &nbsp; Leave Balance - {getTeamMemberName(selectedTeamMember)}
                                    </div>
                                    <div className='financialYearNameSmall'>
                                        Financial Year
                                    </div>
                                    <div className='comboboxFYIDLB' style={{ backgroundColor: 'white' }}>
                                        <ComboBoxComponent
                                            id="fyidLB"
                                            fields={{ text: "Name", value: "Id" }}
                                            cssClass="e-outline"
                                            data-msg-containerid="fyidLBErr"
                                            name="fyidLBErr"
                                            htmlAttributes={{ maxlength: 100 }}
                                            select={(e) => inputChangeHandler(e, "fyId")}
                                            value={fyId}
                                            dataSource={financialYearData}

                                        />
                                    </div>

                                </div>

                                <div className='leave-application-body' style={{ zIndex: 1000, position: 'fixed', top: 152, right: 14, width: 700, boxShadow: '-3px 0px 10px 1px #aaaa', backgroundColor: 'white' }}>
                                    <LeavesBalance from={'yearly'}
                                        onCancel={closePopUp}
                                        teamId={selectedTeamMember && selectedTeamMember.length > 0 ? selectedTeamMember[0].Id : props.authUser}
                                        YearlyfyId={fyId}
                                        parent={'allTeamMembers'}
                                    />
                                </div>


                            </>
                        }
                    </div>}
                </div>
            }
            {isData && getNoDataContent()}
            {isLoading &&
                <div
                    style={{
                        zIndex: 999999,
                        position: "absolute",
                        marginTop: "20%",
                        marginLeft: "49%",
                    }}
                >
                    <ProgressBar />
                </div>}
        </>
    )
}

const mapStateToProps = ({ auth, lnareducer, candidateReducer, meetingmaster }) => {
    const { authUser } = auth;

    const { officeCountry, officeLocation, viewClicked, selectedTeamMember, selectedTeamMemberId,
        financialYearData, startMonth, endMonth, startMonthMonthlyReport, starting, fyId, teamMemberYearlyDropDown, isLoading } = lnareducer;

    const { othersCheckAvailablity } = meetingmaster;

    const { entityMaster, countryMaster } = candidateReducer;

    return {
        authUser, viewClicked, selectedTeamMember, financialYearData, teamMemberYearlyDropDown,
        countryMaster,
        officeCountry,
        officeLocation,
        entityMaster,
        othersCheckAvailablity, selectedTeamMemberId, startMonth, endMonth, startMonthMonthlyReport, starting, fyId, isLoading
    }
}

export default connect(mapStateToProps, { updateCandidateData, updateLnAData, saveWeekOffSwap })(AllTeamMemberReport)
