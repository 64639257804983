import './default.css';
import * as React from 'react';
import { MainClass } from '../../../../src/mainclass';
import { connect } from 'react-redux';
import Schedular from '../../containers/schedular/schedular';
import PlanVsActualSchedular from '../../containers/schedular/planvsactualschedular';
import { PLAN_VS_ACTUAL_MEETING, OTHERS_CALENDAR, CALENDAR_REPORT, MY_MEETING, HOLIDAY_LIST } from "../../../redux/constant/meetingcategoryconstant";
import ProgressBar from "../../../components/progressbar/progressbar";
import ECReport from '../../../components/ecreport/ecreport';
import { decodeString, encodeString, dencrypt } from "../../other/commonfunction"
import { getDataMeetingMasterData, getTMLCUsers, getOnboardingMasterData } from "../../other/commonlayoutfunction"
import { setMeetingMaster } from "../../../redux/actions/meetingmasteraction";
import { updateCandidateData } from '../../../redux/actions/entryexit/candidateaction';
import queryString from "query-string";
import HolidayListLayout from '../../layout/holidaylist/index';
import { setMeetingCategory } from "../../../redux/actions/meetingcategoryaction";
class Default extends MainClass {
    constructor(props) {
        super(props)
        this.state = {
            isLoaded: false,
            moduleId: 0
        }
    }
    async componentDidMount() {
        try {
            this.setModuleId()
            const { meetingType, authUser, othersCheckAvailablity } = this.props;
            if (meetingType.length === 0) {
                const resultMasters = await getDataMeetingMasterData(authUser);                
                this.props.setMeetingMaster(resultMasters)

                // get the Practice Area and Sales Unit Data
                const SD_SU = await getOnboardingMasterData(authUser, "SD_SU");
                this.props.updateCandidateData({ servceDivisionSalesUnitMaster: SD_SU })
            }
            if (othersCheckAvailablity.length === 0) {
                const othersCheckAvailablityData = await getTMLCUsers(authUser)
                this.props.setMeetingMaster(othersCheckAvailablityData);
            }
            this.setState({ isLoaded: true })
        } catch (error) {

        }
    }

    setModuleId() {
        try {
            // Set App Id
            const { moduleId } = this.state
            const params = queryString.parse(this.props.location.search);
            if (typeof params.id !== 'undefined' && params.id !== null) {
                const id = dencrypt(params.id).toString();                
                if (moduleId !== id) {
                    this.props.setMeetingCategory(id);
                    this.setState({ moduleId: id })
                }
            }
        } catch (error) {
            console.log("🚀 ~ file: index.js:59 ~ Default ~ setModuleId ~ error:", error)
        }
    }

    componentDidUpdate(newProps) {
        try {            
            this.setModuleId()
        } catch (error) {

        }
    }

    renderSwitch() {
        const { categoryName } = this.props
        const { moduleId } = this.state
        let activeModule = (moduleId == 0 ? categoryName : moduleId).toString()
        if (activeModule === PLAN_VS_ACTUAL_MEETING || activeModule === MY_MEETING) {
            let digitalEmailAddress = decodeString(localStorage.getItem('authUserDigitalEmailAddress'))
            localStorage.setItem("digitalEmailAddress", encodeString(digitalEmailAddress));
        }
        switch (activeModule) {
            case PLAN_VS_ACTUAL_MEETING:
            case OTHERS_CALENDAR:
                document.title = "Connect - Enterprise Calendar"
                return <PlanVsActualSchedular categoryName={activeModule} history={this.props.history} />;
            case CALENDAR_REPORT:
                return <ECReport categoryName={activeModule} />;
            case HOLIDAY_LIST:
                document.title = "Connect - Holiday List"
                return <HolidayListLayout history={this.props.history} />;
            default:
                document.title = "Connect - Enterprise Calendar"
                return <Schedular categoryName={activeModule} history={this.props.history} />;
        }
    }

    render() {
        const { meetingType, meetingSubType } = this.props
        const { isLoaded } = this.state
        return (
            <div id="calendar-section">
                {
                    meetingType.length > 0
                        && meetingSubType.length > 0
                        && isLoaded ?
                        <div style={{ height: '100%', width: '100%' }}>
                            {this.renderSwitch()}
                        </div>
                        :
                        <div className="loadercentered">
                            <ProgressBar />
                        </div>
                }
            </div>
        );
    }
}

const mapStateToProps = ({ meetingcategory, meetingmaster, auth }) => {
    const { categoryName } = meetingcategory;
    const { authUser } = auth;
    const {
        meetingType,
        meetingSubType,
        othersCheckAvailablity
    } = meetingmaster;
    return {
        categoryName,
        meetingType,
        meetingSubType,
        authUser,
        othersCheckAvailablity
    }
};
export default connect(mapStateToProps, { setMeetingMaster, updateCandidateData, setMeetingCategory })(Default);
