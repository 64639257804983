import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import FormControlLabel from "@material-ui/core/FormControlLabel";
// import IconButton from '@material-ui/core/IconButton';
// import MoreVertIcon from '@material-ui/icons/MoreVert';

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { withStyles } from '@material-ui/core/styles';
import { enableRipple } from '@syncfusion/ej2-base';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/pro-light-svg-icons";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import ReactHtmlParser from 'react-html-parser'
import { getDisplayDateOnly, getNotify } from "../../app/other/commonfunction";
import { addUpdateObjectveKpi } from "../../redux/actions/okr/okractions";
import { ERROR, searchObjKPITagsNotAvailable } from "../../assets/constants/constants";
import { urlConfig } from "../../config/config";
import fileIcon from "../../../src/assets/svg/fileIcon_jpeg.png";
import OKRServices from "../../services/okr/okrservices";
import { Button, Dialog } from "@material-ui/core";
import { DialogContent } from "../customdialog/customdialog";
import EditKPI from "./createkpi";
import CreateObjective from "./createobject"
import editIcon from "../../../src/assets/svg/ic-Edit.svg";
import "./newobjectkpi.css";
enableRipple(true);
withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));
withStyles((theme) => ({
    root: {
        '&:focus': {
            backgroundColor: theme.palette.primary.main,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white,
            },
        },
    },
}))(MenuItem);
const cssForScroll = `
::-webkit-scrollbar {
    width: 24px;
  }

  ::-webkit-scrollbar-thumb {
    background: white;
    border-right: 13px #8080804f  solid;
    background-clip: padding-box;
  }
`
class ViewObjectiveKPIOKR extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openMenu: false,
            openMenuKPI: false,
            heightLayout: 5,
            addKPI: false,
            createObjective: false,
            // objKpiFilterData: this.props.viewObjectiveData,
            objKpiTagsSearch: "",
            typeFilter: [
                { Id: 1, Name: "Approver" },
                { Id: 2, Name: "Key Result" },
                { Id: 3, Name: "Key Result Tags" },
                { Id: 4, Name: "Objective" },
                { Id: 5, Name: "Objective Tags" },
                { Id: 6, Name: "Visibility" },
            ],
            filterType: 7,
            kpiType: '',
            editObjective: false,
        }
    }
    updateDimensions() {
        const updateHeight = window.innerHeight - 270;
        this.setState({ heightLayout: updateHeight });
    }
    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }
    imageFilepath = (filepath) => {
        const { employeeId } = this.props;
        let attachmentFiles = [];
        if (filepath !== "" && filepath !== null) {
            attachmentFiles = filepath.split(",");
        }
        return (
            <>
                {
                    attachmentFiles.map((attachedFile, indView) => (
                        <span key={`AttView${indView}`}>
                            <img
                                alt=""
                                src={fileIcon}
                            />
                            <span style={{ padding: "0px 8px 0px 8px" }}>
                                <a
                                    target="_blank"
                                    href={
                                        attachedFile !== null && attachedFile !== ""
                                            ? `${urlConfig.tmlcApiUrl}document/kpi/${employeeId}/${attachedFile}`
                                            : ""
                                    }
                                    rel="noopener noreferrer"
                                    download
                                    style={{ textDecoration: 'none' }}
                                >
                                    <span title={attachedFile} style={{ color: "#f94f5e" }}>
                                        {attachedFile.substring(11, attachedFile.length).length > 35 ? attachedFile.substring(11, attachedFile.length).substr(0, 32) + '...' : attachedFile.substring(11, attachedFile.length)}
                                    </span>
                                </a>
                            </span>
                        </span>
                    ))
                }
            </>
        )
    }
    getTagObjValue = (e) => {
        const { tempViewObjectiveKPI } = this.props;
        this.setState({ objKpiTagsSearch: e.target.value });
        if (e.target.value === "") {
            this.props.addUpdateObjectveKpi({ viewObjectiveData: tempViewObjectiveKPI });
        }
    }
    // Author By :- Prashant Waphare | change on 03-05-2021
    searchobjectiveKPIData = () => {
        const { viewObjectiveData, tempViewObjectiveKPI, authUser } = this.props;
        const { objKpiTagsSearch, filterType } = this.state;
        let viewFilterData = viewObjectiveData;
        setTimeout(() => { // NOSONAR
            if (objKpiTagsSearch !== '') {
                const OkrObjectiveData = Promise.resolve(OKRServices.getObjectiveDetails(authUser, 0, "APPROVED_PENDING_ALL", objKpiTagsSearch, filterType));
                OkrObjectiveData.then((result) => {
                    if (result.Result !== undefined && result.Result.length > 0) {
                        try {
                            this.props.addUpdateObjectveKpi({ viewObjectiveData: result.Result });
                        } catch (error) {
                            console.error(error);
                        }
                    } else {
                        getNotify(ERROR, searchObjKPITagsNotAvailable);
                    }
                });
                viewFilterData = viewObjectiveData;
            } else {
                getNotify(ERROR, "Pleae Search Objective, Key Result or Tags");
                return;
            }
            if (viewFilterData.length > 0 && objKpiTagsSearch !== null && objKpiTagsSearch !== '') {
                this.props.addUpdateObjectveKpi({ viewObjectiveData: viewFilterData });
            } else if (viewObjectiveData.length <= 0 && objKpiTagsSearch !== null && objKpiTagsSearch !== '') {
                this.props.addUpdateObjectveKpi({ viewObjectiveData: tempViewObjectiveKPI });
            } else {
                if (objKpiTagsSearch === "" || objKpiTagsSearch === null) {
                    this.props.addUpdateObjectveKpi({ viewObjectiveData: tempViewObjectiveKPI });
                } else {
                    getNotify(ERROR, searchObjKPITagsNotAvailable);
                    this.props.addUpdateObjectveKpi({ viewObjectiveData: tempViewObjectiveKPI });
                }

            }
        }, 1000);
    }
    getFilterType = (e) => {
        this.setState({ filterType: e.itemData.Id });
    }
    handleClickObjective = (e, objdata) => {
        const { objectiveId, objectiveName, objectiveTags, objectiveVisibilityId, levelId } = objdata;
        this.props.addUpdateObjectveKpi({ objId: objectiveId, objectiveName: objectiveName, objectiveTags: objectiveTags !== null ? objectiveTags.split('#') : [], visibility: objectiveVisibilityId, levelL: levelId !== null || levelId !== '' ? levelId.split(',').map(Number) : [], objKpiAddUpdateType: 'ViewLibrary' });
        this.setState({ openMenu: true, anchorEl: e.currentTarget });
    }
    handleClose = () => {
        this.props.addUpdateObjectveKpi({ objId: 0, objectiveName: "", objectiveTags: [], visibility: "", levelL: [],objKpiAddUpdateType:"" });
        this.setState({ openMenu: false });
    }
    openAddNewObjective = () => {
        this.setState({ editObjective: true, openMenu: false });
    }
    closeAddNewObjective = () => {        
        this.setState({ editObjective: false });
        this.props.addUpdateObjectveKpi({ objId: 0, objectiveName: "", objectiveTags: [], visibility: "", levelL: [],objKpiAddUpdateType:"" });
    }
    handleClickKPI = (e, kpiData) => {
        const { objectiveId, kpiId, kpiName, kpiTags, description, kpiAttachment } = kpiData;
        this.setState({ openMenuKPI: true, anchorElKPI: e.currentTarget });
        this.props.addUpdateObjectveKpi({ objId: objectiveId, kpiId: kpiId, kpiName: kpiName, kpiDescription: description, kpiAttachment: kpiAttachment, kpiNewTags: kpiTags !== null ? kpiTags.split('#') : [], objKpiAddUpdateType: "ViewLibrary" });
    }
    openAddKPI = (data, type) => {
        this.setState({ openMenuKPI: false, addKPI: true, kpiType: "Edit" });
    }
    closeAddKPI = () => {
        this.setState({ addKPI: false, kpiType: '' });
        this.props.addUpdateObjectveKpi({ objId: 0, kpiId: 0, kpiName: "", kpiDescription: "", kpiAttachment: [], kpiNewTags: [], objKpiAddUpdateType: "" });
    }
     //WARNING! To be deprecated in React v17. Use new lifecycle static getDerivedStateFromProps instead.
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.cancelViewApproval === "cancel") {
            this.closeAddNewObjective();
            this.closeAddKPI();
            this.props.addUpdateObjectveKpi({ cancelViewApproval: "" });
        }
    }
    render() {
        const { heightLayout, typeFilter, addKPI, editObjective, kpiType, openMenu, anchorEl, anchorElKPI, openMenuKPI } = this.state;
        const { viewObjectiveData, okrMastersVisibility,levelMaster } = this.props;
        return (
            <>
                <div>
                    <div className="row">
                        <div className="col-xs-2 col-sm-2 col-lg-5 col-md-5" style={{ paddingTop: "8px" }}>
                            <span className="draft-name">OKR ({viewObjectiveData.length})</span>
                        </div>
                        <div className="col-xs-10 col-sm-10 col-lg-7 col-md-7" style={{ padding: "0px 0px 8px 0px" }}>
                            <div className="col-xs-4 col-sm-4 col-lg-3 col-md-4">
                                <DropDownListComponent
                                    id="importFrom"
                                    dataSource={typeFilter}
                                    fields={{ text: "Name", value: "Id" }}
                                    data-msg-containerid="importFromErr"
                                    name="importFromErr"
                                    allowCustom={false}
                                    floatLabelType="Auto"
                                    cssClass="e-outline"
                                    placeholder="Select Type *"
                                    select={(e) => this.getFilterType(e)}
                                />
                            </div>
                            <div className="col-xs-8 col-sm-8 col-lg-9 col-md-8">
                                <div className="e-input-group e-float-icon-left">
                                    <div className="tags-input" style={{ marginLeft: 0 }}>
                                        <input
                                            style={{height: "34px", padding:"0px"}}
                                            type="text"
                                            onKeyDown={event => event.key === "Enter" ? this.searchobjectiveKPIData() : null}
                                            placeholder="Search Objective, Key Result or Tags *"
                                            onChange={(event)=> this.getTagObjValue(event)}
                                        />
                                        <span style={{ position: "absolute", right: "0" }}>
                                            <Button
                                                id="myTooltip"
                                                title="Search"
                                                style={{ padding: 0 }}
                                                onClick={(e) => this.searchobjectiveKPIData()}
                                                onKeyDown={(e) => e.key === "Enter" ? this.searchobjectiveKPIData() : null}
                                            >
                                                <FontAwesomeIcon
                                                    icon={faSearch}
                                                    style={{ fontSize: 18, color: "#F94F5E", margin: "12px 18px 10px 18px" }}
                                                />
                                            </Button>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <style>{cssForScroll}</style>
                    <div style={{ height: heightLayout, overflowY: "auto", overflowX: "hidden" }}>
                        {
                            viewObjectiveData.map((objData, ind) => (
                                <>
                                    <Accordion
                                        style={{
                                            marginTop: ind === 0 ? "0px" : "16px ",
                                            marginBottom: viewObjectiveData.length === ind + 1 ? "0px" : "16px",
                                            // margin: "16px 0px 16px 0px", 
                                            boxShadow: "none", position: "inherit"
                                        }}
                                    >
                                        <AccordionSummary style={{
                                            background: "#F2F2F2", paddingLeft: "0px", borderLeft: objData.approvalStatus === "Pending" ? '4px solid #FDD141' : objData.approvalStatus === "Approved" ? '4px solid #36C17C' : '4px solid #F47A7A', // NOSONAR
                                            minHeight: "30px",
                                        }}
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-label="Expand"
                                            aria-controls="additional-actions1-content"
                                            id="additional-actions1-header"
                                        >
                                            <div className="col-xs-12 col-sm-9 col-lg-8 col-md-8 bold-header-obj-kpi" style={{ padding: "14px" }}>
                                                {objData.objectiveName}
                                            </div>
                                            <div className="col-xs-12 col-sm-3 col-lg-4 col-md-4 tags-obj-kpi" style={{ padding: "14px" }}> {objData.objectiveTags} </div>
                                            <div className="col-xs-12 col-sm-1 col-lg-1 col-md-1">
                                                <FormControlLabel style={{ margin: "0px", float: "right" }}
                                                    control={
                                                        <div>
                                                            {/* <IconButton
                                                                onClick={(e) => this.handleClickObjective(e, objData)}
                                                                disabled={true}
                                                            >
                                                                <MoreVertIcon />
                                                            </IconButton> */}
                                                            <Menu
                                                                id={`objectId${objData.objectiveId}`}
                                                                keepMounted
                                                                anchorEl={anchorEl}
                                                                open={Boolean(openMenu)}
                                                                onClose={this.handleClose}
                                                                style={{ margin: "50px 0px 0px -80px" }}
                                                            >
                                                                <MenuItem id={`objData${objData.objectiveId}`} onClick={this.openAddNewObjective}>
                                                                    <span style={{ padding: "8px 8px 8px 0px" }}>
                                                                        <img
                                                                            alt=""
                                                                            src={editIcon}

                                                                        />
                                                                    </span> <span id={`FormControlLabel${ind}`} style={{ padding: "0px 32px 0px 0px" }} >Edit</span></MenuItem>
                                                                {/* <MenuItem key={`deleteObj${ind}`} onClick={(e) => this.deleteObjectivePopup("DeleteObj")}>
                                                                    <span style={{ padding: "8px 8px 8px 0px" }}>
                                                                        <img
                                                                            alt=""
                                                                            src={deleteIcon}

                                                                        /></span> <span> Delete </span></MenuItem> */}
                                                            </Menu>
                                                        </div>
                                                    }
                                                    disabled={true}
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                />
                                            </div>
                                        </AccordionSummary>
                                        <AccordionDetails style={{
                                            background: "#8080801c", padding: "0px 0px 8px 0px", borderLeft: objData.approvalStatus === "Pending" ? '4px solid #FDD141' : objData.approvalStatus === "Approved" ? '4px solid #36C17C' : '4px solid #F47A7A' // NOSONAR
                                        }}>
                                            <div className="col-xs-12 col-sm-12 col-lg-12" style={{ padding: "0px 16px 0px 16px" }}>
                                                <span className="normal-visivbility-level-obj-kpi">Visibility :</span><span className="kpi-footer-details" > {objData.objectiveVisibility}</span>
                                                {
                                                    objData.levelName !== "" && objData.levelName !== null &&
                                                    <>
                                                        <span className="normal-visivbility-level-obj-kpi">Level :</span><span className="kpi-footer-details" style={{cursor:'pointer'}} title={objData.levelName}> {`${objData.levelName.split(',')[0]}`}{objData.levelName.split(',').length > 1 ? ` + ${objData.levelName.split(',').length - 1} more` : ""}</span>
                                                    </>
                                                }
                                            </div>
                                        </AccordionDetails>
                                        {
                                            objData.kpis.map((kpiData, kpiInd) => (
                                                <Accordion defaultExpanded={false} style={{ margin: "0px", borderLeft: "1px solid #00000012", borderRight: "1px solid #00000012", borderBottom: "1px solid #00000012", position: "inherit" }}>
                                                    <AccordionSummary style={{ minHeight: "30px"}}
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-label="Expand"
                                                        aria-controls="additional-actions1-content"
                                                        id="additional-actions1-header"
                                                    >
                                                        <div className="col-xs-12 col-sm-9 col-lg-8 col-md-8 bold-header-obj-kpi" style={{ padding: "14px 14px 14px 3px" }}>
                                                            {kpiData.kpiName}
                                                        </div>
                                                        <div className="col-xs-12 col-sm-3 col-lg-4 col-md-4 tags-obj-kpi" style={{ padding: "14px 14px 14px 10px" }}> {kpiData.kpiTags} </div>
                                                        <div className="col-xs-12 col-sm-1 col-lg-1 col-md-1">
                                                            <FormControlLabel style={{ margin: "0px", float: "right" }}
                                                                // aria-label="Acknowledge"
                                                                control={
                                                                    <div>
                                                                        {/* <IconButton
                                                                            id={`${kpiData.kpiId}`}
                                                                            onClick={(e) => this.handleClickKPI(e, kpiData)}
                                                                            disabled={true}
                                                                        >
                                                                            <MoreVertIcon />
                                                                        </IconButton> */}
                                                                        <Menu
                                                                            id="kpiId"
                                                                            keepMounted
                                                                            anchorEl={anchorElKPI}
                                                                            open={Boolean(openMenuKPI)}
                                                                            onClose={this.handleCloseKPI}
                                                                            style={{ margin: "50px 0px 0px -80px" }}
                                                                        >
                                                                            <MenuItem key={`editKpi${kpiInd}`} onClick={(e) => this.openAddKPI(kpiData, "Edit")}>
                                                                                <span style={{ padding: "8px 8px 8px 0px" }}>
                                                                                    <img
                                                                                        alt=""
                                                                                        src={editIcon}

                                                                                    />
                                                                                </span> <span style={{ padding: "0px 32px 0px 0px" }}>Edit</span></MenuItem>
                                                                            {/* <MenuItem key={`deleteKPI${kpiInd}`} onClick={(e) => this.deleteObjectivePopup("DeleteKPI")}>
                                                                                <span style={{ padding: "8px 8px 8px 0px" }}>
                                                                                    <img
                                                                                        alt=""
                                                                                        src={deleteIcon}

                                                                                    /></span> <span> Delete </span></MenuItem> */}
                                                                        </Menu>
                                                                    </div>
                                                                }
                                                                onClick={(event) => event.stopPropagation()}
                                                                onFocus={(event) => event.stopPropagation()}
                                                            />
                                                        </div>
                                                    </AccordionSummary>
                                                    <AccordionDetails style={{ background: "rgb(72 184 231 / 5%)", padding: "8px 18px 8px 18px" }}>
                                                        <div className="row">
                                                            <div className="col-xs-12 col-sm-12 col-lg-12" style={{ color: "#808080a8", paddingBottom: "8px" }}> Description </div>
                                                            <div className="col-xs-12 col-sm-12 col-lg-12">
                                                                {ReactHtmlParser(kpiData.description)}
                                                            </div>
                                                            {
                                                                kpiData.kpiAttachment !== "" && kpiData.kpiAttachment !== null &&
                                                                <div className="col-xs-12 col-sm-12 col-lg-12" style={{ padding: "8px 14px 0px 14px" }}>
                                                                    {this.imageFilepath(kpiData.kpiAttachment)}
                                                                </div>
                                                            }
                                                        </div>
                                                    </AccordionDetails>
                                                </Accordion>
                                            ))
                                        }
                                        <AccordionSummary style={{ background: "#F2F2F2", paddingLeft: "0px", minHeight: "30px", margin: "-1px", cursor: "auto", height: "48px" }}>
                                            <div className="col-xs-12 col-sm-12 col-lg-12" style={{ padding: "0px 20px 0px 20px" }}>
                                                <span className="normal-visivbility-level-obj-kpi">Created by :</span><span className="kpi-footer-details" >{objData.createdBy}</span><span style={{ paddingRight: "12px", borderLeft: "2px solid #DBDBDD" }} />
                                                <span className="normal-visivbility-level-obj-kpi">Created Date :</span><span className="kpi-footer-details" > {getDisplayDateOnly(new Date(objData.createdOn))}</span><span style={{ paddingRight: "12px", borderLeft: "2px solid #DBDBDD" }} />
                                                <span className="normal-visivbility-level-obj-kpi">Approver :</span><span className="kpi-footer-details"> {objData.approvedBy}</span>
                                            </div>
                                        </AccordionSummary>
                                    </Accordion>
                                </>
                            ))
                        }
                    </div>
                    <div>
                        <Dialog
                            open={addKPI}
                            fullWidth
                            width="500"
                            maxWidth="sm"
                        >
                            <DialogContent>
                                <div>
                                    <div>
                                        <span className="add-new-self-header" style={{ paddingLeft: "5px" }}>{kpiType} Key Result </span>
                                        <EditKPI closeAddKPI={() => this.closeAddKPI} />
                                    </div>
                                </div>
                            </DialogContent>
                        </Dialog>
                    </div>
                    <div>
                        <Dialog
                            open={editObjective}
                            fullWidth
                            width="500"
                            maxWidth="sm"
                        >
                            <DialogContent>
                                <div>
                                    <div>
                                        <span className="add-new-self-header" style={{ paddingLeft: "8px" }}>Edit Objective </span>
                                        <CreateObjective closeCreateObj={() => this.closeAddNewObjective} okrMastersVisibility={okrMastersVisibility} levelMaster={levelMaster} />
                                    </div>
                                </div>
                            </DialogContent>
                        </Dialog>
                    </div>
                </div>
            </>
        );
    }
}
ViewObjectiveKPIOKR.propTypes = {
    authUser: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    employeeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    viewObjectiveData: PropTypes.arrayOf(PropTypes.object),
    tempViewObjectiveKPI: PropTypes.arrayOf(PropTypes.object),
    refreshViewStatus: PropTypes.string,
    cancelViewApproval: PropTypes.string,
};
ViewObjectiveKPIOKR.defaultProps = {
    authUser: "",
    employeeId: 0,
    viewObjectiveData: [],
    tempViewObjectiveKPI: [],
    cancelViewApproval:"",
};
const mapStateToProps = ({ auth, okrreducer }) => {
    const { authUser } = auth;
    const { viewObjectiveData, tempViewObjectiveKPI, employeeId, cancelViewApproval } = okrreducer;
    return {
        authUser,
        viewObjectiveData,
        tempViewObjectiveKPI,
        employeeId,
        cancelViewApproval
    }
}
export default connect(mapStateToProps, {
    addUpdateObjectveKpi,
})(ViewObjectiveKPIOKR);