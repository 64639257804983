import React, { useState, useEffect } from "react";
import { ComboBoxComponent } from "@syncfusion/ej2-react-dropdowns";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { SwitchComponent } from "@syncfusion/ej2-react-buttons";
import { connect } from "react-redux";
import { FormValidator } from "@syncfusion/ej2-inputs";
import LnAServices from "../../services/lna/lnaservice";
import { saveAttendanceCluster, updateLnAData } from "../../redux/actions/lna/lnaaction";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import { getMySqlDateFormat } from "../../app/other/commonfunction";
import UploadDocuments from "../okrratingcomponents/uploaddocuments";
import { createImageFilepathForLPView } from "../okrratingcomponents/ratingcommonfun";
import {
  pleaseEnterSymbol,
  pleaseEnterAttendanceType,
  pleaseEnterAttendanceName,
  pleaseEnterColor,
  pleaseSelectffectiveStartDate,
  effectiveStartDateVaidation,
  regexOnlyLettersAndSpaces,

} from "../../assets/constants/constants";

import ProgressBar from "../progressbar/progressbar";

const SAMPLECSS = `
input.e-input, textarea.e-input, .e-input-group, .e-input-group.e-control-wrapper {
  font-size: 12px;
}
`

function AttendanceCluster(props) {
  //NOSONAR
  const {  authUser, openPopup, editClusterId } =
    props;
  const [changedData, setChangedData] = useState(false);
  const [state, setState] = useState({
    title: null,
    attendanceID: null,
    attendanceName: null,
    attendanceSymbolId: null,
    attendanceDescription: null,
    attendanceType: "",
    // noOfDaysBackdated: null,
    // noOfDaysFutureDated: null,
    specialAttendance: "0",
    isActive: "1",
    attendenceClusterData: [],
    effectiveStartDate: null,
    effectiveEndDate: null,
    attendColorId: null,
    attendPolicyDoc: [],
    fileName:null,
    oldFileStatus: "",
  });
  const [initialLoad, setInitialLoad] = useState(false)


  let validOptions = {
    rules: {
      attendanceNameErr: {
        required: [true, pleaseEnterAttendanceName],
        regex: [regexOnlyLettersAndSpaces, pleaseEnterAttendanceName],
        minLength: [1, pleaseEnterAttendanceName],
        maxLength: [50, "Should be minimum of 50 characters only."]
      },
      attendanceSymbolIdErr: {
        required: [true, pleaseEnterSymbol],
      },

      attendColorIdErr: {
        required: [true, pleaseEnterColor],
      },
      attendanceTypeErr: {
        required: [true, pleaseEnterAttendanceType],
      },
      effectiveStartDateErr: {
        date: [true, "Please select effective start date."]
      },
    }
  };

  useEffect(() => {
    props.updateLnAData({ popUpSource: 'Attendance Master' })
  }, []);
  useEffect(() => {
    setTimeout(() => {
      props.setSubmitClick(onSaveData);
    }, 100);
  }, [state]);
  useEffect(() => {
    getData()
  }, [editClusterId, openPopup])

  useEffect(() => {
    if (props.lnaMasterFlag === "updated")
      props.changePopUpHandler("close");
  }, [props.lnaMasterFlag]);

  const getData = () => {
    if (openPopup === "add") {
      resetData()
    }
    else {
      editClusterId !== null &&
        (async () => {
          setInitialLoad(true)
          const attendanceClusterData =
            await LnAServices.getAttendanceClusterData(authUser, editClusterId);
          const {
            attendanceID,
            attendanceName,
            attendanceSymbolId,
            attendanceDescription,
            tType,
            isActive,
            effectiveEndDate,
            effectiveStartDate,
            fileName,
            attendColorId,
            specialAttendance,
          } = attendanceClusterData.Result[0];
          let data = {
            attendanceID,
            attendanceName,
            attendanceSymbolId,
            attendanceDescription,
            attendanceType:tType,
            specialAttendance,
            isActive,
            effectiveEndDate,
            effectiveStartDate,
            attendColorId,
            fileName,
            oldFileStatus: fileName,
          };
          setState((preState) => ({ ...preState, ...data }));
          setTimeout(() => {
            const addFormObject = new FormValidator("#attendanceClusterForm", validOptions);
            addFormObject.validate()
            setInitialLoad(false)
          }, 100)
        })();
    }
    // setState(preState => ({ ...preState, title }))
  };
  const resetData = () => {
    let data = {
      attendanceID: null,
      attendanceName: null,
      attendanceSymbolId: null,
      attendanceDescription: null,
      attendanceType: "",
      // noOfDaysBackdated: null,
      // noOfDaysFutureDated: null,
      specialAttendance: "0",
      isActive: "1",
      attendenceClusterData: [],
      effectiveStartDate: null,
      effectiveEndDate: null,
      attendColorId: null,
      attendPolicyDoc: [],
      fileName:null,
      oldFileStatus: "",
    };
    setState((preState) => ({ ...preState, ...data }));
  }

  const selectedFiles = (fileNms) => {
    try {
      setState((preState) => ({ ...preState, attendPolicyDoc: fileNms }));
      setTimeout(() => {
        setChangedData(!changedData);
      });
    } catch (error) {
      console.log("🚀 ~ file: attendancecluster.jsx:126 ~ selectedFiles ~ error", error)
    }
  };
  const documentDetails = (currentTime, file) => {
    setState((prevState) => ({
      ...prevState,
      fileName: file,
      oldFileStatus: file,
    }));
  };

  const inputChangeHandler = async (e, type) => {
    let options = {};
    switch (type) {
      case "attendanceName":
        setState((prevState) => ({ ...prevState, attendanceName: e.value.trim() }));
        options = {
          rules: {
            attendanceNameErr: {
              required: [true, pleaseEnterAttendanceName],
              regex: [regexOnlyLettersAndSpaces, pleaseEnterAttendanceName],
              minLength: [1, pleaseEnterAttendanceName],
              maxLength: [50, "Should be minimum of 50 characters only."]
            },
          },
        };
        break;
      case "attendanceSymbolId":
        setState((prevState) => ({
          ...prevState,
          attendanceSymbolId: e.itemData.Id.toString(),
        }));
        options = {
          rules: {
            attendanceSymbolIdErr: {
              required: [true, pleaseEnterSymbol],
            },
          },
        };
        break;
      case "attendanceDescription":
        setState((prevState) => ({
          ...prevState,
          attendanceDescription: e.value.trim(),
        }));

        break;
      case "attendanceType":
        setState((prevState) => ({
          ...prevState,
          attendanceType: e.itemData.Name.toString(),
        }));
        options = {
          rules: {
            attendanceTypeErr: {
              required: [true, pleaseEnterAttendanceType],
            },
          },
        };
        break;

      case "AttendColorId":
        setState((prevState) => ({
          ...prevState,
          attendColorId: e.itemData.Id,
        }));
        options = {
          rules: {
            attendColorIdErr: {
              required: [true, pleaseEnterColor],
            },
          },
        };
        break;

      case "specialAttendance":
        setState((prevState) => ({
          ...prevState,
          specialAttendance: e.checked === true ? "1" : "0",
        }));
        break;

      case "effectiveStartDate":
        setState((prevState) => ({
          ...prevState,
          effectiveStartDate: e.value,
        }));
        if (document.getElementById("effectiveStartDateErr")) {
          document.getElementById("effectiveStartDateErr").innerHTML = "";
        }
        break;
      case "effectiveEndDate":
        if((state.effectiveStartDate !== null && state.effectiveStartDate) < e.value){
          if (document.getElementById("effectiveStartDateErr")) {
            document.getElementById("effectiveStartDateErr").innerHTML = "";
          }
        }
        setState((prevState) => ({ ...prevState, effectiveEndDate: e.value }));
        break;
      default:
    }

    if (document.getElementById("attendanceNameErr")) {
      document.getElementById("attendanceNameErr").innerHTML = ""
    }
    setTimeout(() => {
      const addFormObject = new FormValidator("#attendanceClusterForm", options);
      addFormObject.validate();
    }, 100);
  };

  useEffect(() => {
    if (props.lnaMasterFlag === "updated") {
      props.changePopUpHandler("close");
    }
  }, [props.lnaMasterFlag]);

  const onSaveData = async () => {
    const {
      attendanceID,
      attendanceName,
      attendanceSymbolId,
      attendanceDescription,
      attendanceType,
      isActive,
      effectiveEndDate,
      effectiveStartDate,
      attendColorId,
      fileName,
      specialAttendance,
    } = state;

    let validateDates = true;

    if (state.effectiveStartDate === "" || state.effectiveStartDate === null) {
      document.getElementById("effectiveStartDateErr").innerHTML =
        pleaseSelectffectiveStartDate;
      validateDates = false;
    }

    if (state.effectiveStartDate === null && state.effectiveEndDate !== null) {
      document.getElementById("effectiveStartDateErr").innerHTML =
        pleaseSelectffectiveStartDate;
      validateDates = false;
    }
    if (state.effectiveStartDate !== null && state.effectiveEndDate !== null) {
      if (new Date(state.effectiveStartDate) > new Date(state.effectiveEndDate)) {
        document.getElementById("effectiveStartDateErr").innerHTML =
          effectiveStartDateVaidation;
        validateDates = false;
      }
    }
    const addFormObject = new FormValidator("#attendanceClusterForm", validOptions);
    if (addFormObject.validate() && validateDates) {
      setInitialLoad(true)
      let attendanceClusterSaveData = {
        userId: authUser,
        type: attendanceID > 0 ? "EDIT" : "ADD",
        attendanceID,
        attendanceName,
        attendanceSymbolId,
        attendanceDescription: attendanceDescription,
        attendanceType: attendanceType === "Paid" ? "1" : "0",
        isActive,
        effectiveStartDate:
        effectiveStartDate !== null
          ? getMySqlDateFormat(effectiveStartDate)
          : null,
      effectiveEndDate:
        effectiveEndDate !== null
          ? getMySqlDateFormat(effectiveEndDate)
          : null,
        attendColorId,
        fileName,
        specialAttendance,
      };
      props.saveAttendanceCluster(attendanceClusterSaveData);
      setTimeout(() => {
        setInitialLoad(false)
      }, 10)
    }
  };
  const descriptionTextBox = `.e-outline textarea, textarea.e-outline, .e-outline.e-float-input textarea, .e-outline.e-float-input.e-control-wrapper textarea, .e-outline.e-float-input.e-input-group textarea {
    box-sizing: border-box;
    min-height: 100px;
  }`

  const removeFile = () => {
    setState(preState => ({ ...preState, fileName: "" }))
  }
  const colorTemplateHex = (prop) => {
    const { optional1, Name } = prop;
    return (
      <div
        style={{
          borderRadius: 3,
          width: 100,
          display: "flex",
          alignItems: "center",
          marginLeft: 15,
        }}
      >
        <div
          style={{
            backgroundColor: optional1,
            color: optional1,
            height: 20,
            width: 20,
          }}
        >
          &nbsp;&nbsp;
        </div>
        <div>{Name}</div>
      </div>
    );
  };
  const getOneDayForward = (dt)=>{
    let date = dt
    date.setDate(date.getDate() + 1)
    return date
  }
  return (
    <div
      id="attendanceClusterForm"
      style={{
        height: "61.5vh",
        overflowY: "scroll",
        paddingLeft: 16,
        paddingRight: 16,
        paddingBottom: 16,
        transition: "width 5s",

      }}
    >
      <style>{SAMPLECSS}</style>
      <div className="col-sm-12 col-md-12 col-lg-12 mt-lg">
        <TextBoxComponent
          id="attendanceName"
          value={state.attendanceName}
          data-msg-containerid="attendanceNameErr"
          name="attendanceNameErr"
          floatLabelType="Auto"
          cssClass="e-outline minimize-height"
          placeholder="Attendance Name *"
          onChange={(e) => inputChangeHandler(e, "attendanceName")}
          htmlAttributes={{ maxlength: 50, minlength: 1 }}
          autocomplete={'off'}
        />

        <div id="attendanceNameErr" className="error-div" />
      </div>
      <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 mt-lg">
        <ComboBoxComponent
          data-msg-containerid="attendanceSymbolIdErr"
          id="attendanceSymbolId"
          dataSource={props.attendanceData}
          fields={{ text: "Name", value: "Id" }}
          value={state.attendanceSymbolId}
          name="attendanceSymbolIdErr"
          placeholder="Symbol *"
          floatLabelType="Auto"
          cssClass="e-outline minimize-height"
          allowCustom={false}
          select={(e) => inputChangeHandler(e, "attendanceSymbolId")}
        />
        <div id="attendanceSymbolIdErr" className="error-div" />
      </div>
      <div
        className="col-xs-12 col-sm-12 col-lg-12 col-md-12 pt-lg dayBreakDescriptionClass"
        style={{ padding: 0 }}
      >
        <style>{descriptionTextBox}</style>
        <TextBoxComponent
          style={{ minHeight: 100 }}
          id={'leaveReattendanceDescriptionason'}
          placeholder={"Description "}
          cssClass="e-outline minimize-height textarea"
          floatLabelType="Auto"
          autocomplete={'off'}

          name="attendanceDescriptionErr"
          data-msg-containerid="attendanceDescriptionErr"
          multiline
          inputProps={{
            required: true,
            minLength: 0,
          }}
          htmlAttributes={{ maxlength: 500 }}
          onChange={(e) => inputChangeHandler(e, "attendanceDescription")}
          value={state.attendanceDescription}
        />
        <div id="attendanceDescriptionErr" className="error-div" />
      </div>



      <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 pt-lg">
        <ComboBoxComponent
          id="attendanceType"
          dataSource={[
            { Name: "Paid", Id: "1" },
            { Name: "Unpaid", Id: "0" },
          ]}
          fields={{ text: "Name", value: "Id" }}
          value={state.attendanceType}
          data-msg-containerid="attendanceTypeErr"
          name="attendanceTypeErr"
          floatLabelType="Auto"
          cssClass="e-outline minimize-height"
          placeholder="Type *"
          allowCustom={false}
          select={(e) => inputChangeHandler(e, "attendanceType")}
        />
        <div
          id="attendanceTypeErr"
          className="e-input-group e-float-icon-left error-div"
        />
      </div>
      <div
        className="col-xs-12 col-sm-12 col-lg-12 col-md-12"
        style={{ paddingLeft: 0 }}
      >
        <ComboBoxComponent
          id="attendColorId"
          dataSource={props.colourMaster}
          fields={{ text: "Name", value: "Id" }}
          value={state.attendColorId}
          data-msg-containerid="attendColorIdErr"
          name="attendColorIdErr"
          floatLabelType="Auto"
          cssClass="e-outline minimize-height"
          placeholder="Color *"
          itemTemplate={colorTemplateHex}
          allowCustom={false}
          select={(e) => inputChangeHandler(e, "AttendColorId")}
        />
        <div
          id="attendColorIdErr"
          className="e-input-group e-float-icon-left error-div"
        />
      </div>

      <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
        <div
          className="col-xs-6 col-sm-6 col-lg-6 col-md-6 "
          style={{ paddingLeft: 0 }}
        >
          <DatePickerComponent
            id="effectiveStartDate"
            // fields={{ text: "Name", value: "Id" }}
            name="effectiveStartDateErr"
            data-msg-containerid="effectiveStartDateErr"
            format="dd-MMM-yyyy"
            value={state.effectiveStartDate}
            floatLabelType="Auto"
            cssClass="e-outline minimize-height"
            placeholder="Effective Start Date *"
            firstDayOfWeek={1}
            change={(e) => inputChangeHandler(e, "effectiveStartDate")}
          />
          <div
            id="effectiveStartDateErr"
            className="e-input-group e-float-icon-left error-div"
          />
        </div>
        <div
          className="col-xs-6 col-sm-6 col-lg-6 col-md-6 "
          style={{ paddingRight: 0 }}
        >
          <DatePickerComponent
            id="effectiveEndDate"
            fields={{ text: "Name", value: "Id" }}
            value={state.effectiveEndDate}
            data-msg-containerid="effectiveEndDateErr"
            name="effectiveEndDateErr"
            format="dd-MMM-yyyy"
            floatLabelType="Auto"
            min={state.effectiveStartDate ? getOneDayForward(new Date(state.effectiveStartDate)) : null}
            cssClass="e-outline minimize-height"
            placeholder="Effective End Date "
            firstDayOfWeek={1}
            change={(e) => inputChangeHandler(e, "effectiveEndDate")}
          />
          <div
            id="effectiveEndDateErr"
            className="e-input-group e-float-icon-left error-div"
          />
        </div>
      </div>
      <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 ">
        <div className="atc-switch-heading">Special Attendance Allowed</div>
        <div className="col-xs-1 col-sm-1 col-lg-4 col-md-2 atc-switch-inline mt-md atc-no-paddding">
          <div className="mr-md">No</div>
          <div className="atc-switch-margin">
            <SwitchComponent
              id="specia"
              cssClass="atc-switch-height"
              change={(e) => inputChangeHandler(e, "specialAttendance")}
              checked={state.specialAttendance === "1" ? true : false}
            />
          </div>
          <div className="ml-md">Yes</div>
        </div>
      </div>
      <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 mt-sm ">
        <div
          className="attachNote"
          style={{
            fontSize: ".95rem",
            color: "#939399",
            fontWeight: 500,
            padding: ".5rem 0",
            marginTop: "0rem",
          }}
        >
          Attach Policy
        </div>

        <UploadDocuments
          type={"attendance"}
          module={'lna'}
          allowmultiple={false}
          selectedFiles={selectedFiles}
          fileList={[]}
          apiUrl={"lnaApiUrl"}
          documentDetails={documentDetails}
        />

        {state.fileName !== null && state.oldFileStatus !== null ? (
          <div style={{ paddingLeft: 8 }}>
            {createImageFilepathForLPView(
              [].concat(state.fileName),
              "document/lna/attendance",
              "lnaApiUrl", removeFile
            )}
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="loader-new1" id="spinnerContainer" >
        {initialLoad && <ProgressBar />}
      </div>
    </div>
  );
}

const mapStateToProps = ({ auth, lnareducer, meetingmaster }) => {
  const { authUser } = auth;
  const { currentFinancialYearId } = meetingmaster;
  const { attendanceData } = lnareducer.symbolData;
  const { colourMaster, lnaMasterFlag } = lnareducer;

  return {
    authUser,
    lnareducer,
    currentFinancialYearId,
    attendanceData,
    colourMaster,
    lnaMasterFlag,
  };
};
export default connect(mapStateToProps, {
  saveAttendanceCluster, updateLnAData
})(AttendanceCluster);
