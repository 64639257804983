import React from 'react';
import {
    GridComponent,
    ColumnsDirective,
    ColumnDirective,
    Inject,
    Page,
    InfiniteScroll,
    Filter,
    Sort
} from "@syncfusion/ej2-react-grids";
import {
    getGridColumn,
    ADMIN_MODULE_ACTION,
    LEAVE_BALANCE_FROM_DATE,
    LEAVE_BALANCE_TO_DATE,
    LEAVE_BALANCE_TYPE,
    LEAVE_BALANCE_APPLICATION_STATUS,
    LEAVE_APPROVAL_APPLIED_ON,
    LEAVE_BALANCE_NO_OF_DAYS,
    LEAVE_BALANCE_APPROVED_BY,
    LEAVE_BALANCE_APPROVED_ON
} from "../../../../app/other/gridcolumns";
import { DropDownButtonComponent } from '@syncfusion/ej2-react-splitbuttons';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { AllFullDateFormat } from '../../../separation/allcommoncomponents';
import { DateFilterformat } from '../../../../config/config';

const getMenuData = (types) => {
    switch (types) {
        case 'Applied':
        case 'Approved':
        case 'Rejected':
        case 'Utilized':
            return [
                { text: 'View', iconCss: 'e-icons e-page-one' },
            ];
        default:
            return [
                { text: 'View', iconCss: 'e-icons e-page-one' }
            ];
    }
};


const SAMPLECSS = `
// #leaveHistoryLeaveBalanceGrid  .e-rowcell.below-30
// {
//   color: lightgrey !important;
// }
  #leaveHistoryLeaveBalanceGrid .e-grid .e-row.below-30  .e-rowcell{
    color: #9f9f9f;
  }
  #leaveHistoryLeaveBalanceGrid .e-grid  .e-rowcell td[aria-selected="true"]
  {
    color: white !important;
  }
  #leaveHistoryLeaveBalanceGrid tr[aria-selected="true"] td[aria-selected="true"] {
    background-color: #fff !important;
  }
  #leaveHistoryLeaveBalanceGrid .e-grid .e-hide {
      display: none !important;
  }
  #leaveHistoryLeaveBalanceGrid .e-grid .e-headercontent {
      border-width: 0px !important;
  }
  #leaveHistoryLeaveBalanceGrid .e-pagerdropdown  {
      display: inline-block;
      height: 32px;
      margin-left: 12px;
      margin-right: 0;
      margin-top: 9px !important;
      overflow: hidden;
      vertical-align: middle;
      width: 90px;
      position: absolute;
      right: 15%;
  }

  #leaveHistoryLeaveBalanceGrid .e-pagerconstant  {
    display: inline-block;
    height: 32px;
    margin-left: 12px;
    margin-right: 0px;
    margin-top: 14px !important;
    overflow: hidden;
    vertical-align: middle;
    width: 90px;
    position: absolute;
    right: 10%;
  }

  #leaveHistoryLeaveBalanceGrid .e-input-group e-control-wrapper e-alldrop e-ddl e-lib e-keyboard e-valid-input {
    padding-left:40px ! important;
    width:100% ! important;
  }
  #leaveHistoryLeaveBalanceGrid .e-grid.e-wrap .e-columnheader .e-filtermenudiv {
      line-height: 0px !important;
  }
  #leaveHistoryLeaveBalanceGrid .e-grid .e-filtermenudiv::before {
      position:absolute !important;
      top:18px !important ;
      left :88% !important ;
      padding:0px !important;
      }
  }

  .gridHeaderContent{
    font: normal normal normal 16px/20px Circular TT Book;
    color: #ffffff;
    opacity: 1;
    vertical-align: sub;
    padding: 16px;
    cursor: pointer;
  }

  .leave-rules-button{
    border: 1px solid #fff !important;
    color: #fff !important;
    border-radius: 6px;
    padding: 0.3rem 0.5rem;
    cursor: pointer;
    font-weight: 400;
    font: normal normal normal 16px/20px Circular TT Book;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  .e-outline.e-input-group input.e-input{
    font-size: 1rem !important;
  }
  .e-outline.e-input-group .e-clear-icon, .e-outline.e-input-group.e-control-wrapper .e-clear-icon {
    font-size: 16px;
   padding: 0px !important; 
    }
    .e-outline.e-float-input .e-clear-icon::before, .e-outline.e-float-input.e-control-wrapper .e-clear-icon::before, .e-outline.e-input-group .e-clear-icon::before, .e-outline.e-input-group.e-control-wrapper .e-clear-icon::before {
        font-size: 16px;
        margin-right: 5px !important;
    }
    .e-outline.e-input-group .e-clear-icon, .e-outline.e-input-group.e-control-wrapper .e-clear-icon {
      display: none !important;
      font-size: 16px;
      padding: 0px !important;
  }

  .scrollcontainer {
    width:100%;
    overflow: hidden !important; /* Add scrollbars when content overflows */
}

.scroll-bar {
  float: left;
  width: auto;
  background: #fff;
  overflow-y: hidden;
  overflow-x: hidden !important;
  }

  @media screen and (max-width: 1366px) and (max-height: 768px), screen and (max-width: 1440px) and (max-height: 900px)  and (-webkit-min-device-pixel-ratio: 1.5){

.scrollcontainer{
  max-height: 500px;
  overflow-y: auto !important;
}

  .scrollcontainer::-webkit-scrollbar {
    width: 4px; /* Width of the scrollbar */
  }

  .scrollcontainer::-webkit-scrollbar-thumb {
    background-color: #4B4B55; /* Color of the thumb */
    border-radius: 4px; /* Roundness of the thumb */
  }
}


`

const LeaveHistory = (props) => {
    const {clickedFromOnLeave} = props;
    /**
  *@author: Somesh Dwivedi  
  *@description : To get the height of grid on the basis of width.
  */

    const getGridHeight = () => {
        let ht;
        if (props.showCompleteLeaveCategory) {
            if (window.innerWidth > 1024) {
                ht = 'calc(100vh - 510px)';
            } else {
                ht = 'calc(100vh - 452px)';
            }
        } else {
            if (window.innerWidth > 1024) {
                if (props.showMore) {
                    ht = clickedFromOnLeave ? 'calc(100vh - 595px)':'calc(100vh - 539px)';
                } else {
                    // ht='calc(100vh - 480px)'
                    ht = clickedFromOnLeave ? 'calc(100vh - 480px)':'calc(100vh - 424px)' ;
                }
            } else {
                if (props.showMore) {
                    ht = clickedFromOnLeave ?'calc(100vh - 485px)':'calc(100vh - 429px)';
                } else {
                    ht = clickedFromOnLeave ?'calc(100vh - 366px)':'calc(100vh - 410px)';
                }
            }

        }
        return ht;
    }

    const rowDataBound = (args) => {
        if (args.row) {
            try {
                if ((new Date(args.data.fromDate).toISOString().slice(0, 10) < new Date(new Date().toDateString()).toISOString().slice(0, 10))) {
                    args.row.classList.add('below-30');
                }
            } catch (err) {
                console.log(err);
            }
        }
    }
    const dataSourceChanged = (pComponentId) => {
        try {
            setTimeout(() => {
                if (document.getElementById(pComponentId) !== null) { document.getElementById(pComponentId).ej2_instances[0].refresh(); }
            });
        } catch (error) { console.log(error); }
    };


    const onDataBinding = (pComponentId) => {
        try {
            setTimeout(() => {
                document.getElementById(pComponentId).ej2_instances[0].refresh();
            });
        } catch (error) {
            console.log(error);
        }
    };
    const onSelect = (args, data) => {
        try {
            if (args.item.text === 'View') {
                props.updateLnAData({
                    leaveApplyLoadName: 'leave-view',
                    leaveData: data,
                    leaveApplicationId: data.leaveId,
                    leaveType: '',
                    leaveStartDate: null,
                    leaveEndDate: null,
                    leaveReason: '',
                    appliedDays: '',
                    leaveClusterId: '',
                    approverId: 0,
                    attachmentName: '',
                })
            }
        } catch (error) {
        }
    }
    const actionTemplate = (balance) => {
        return (
            <DropDownButtonComponent
                key={`leave-${balance.leaveId}`}
                items={getMenuData(balance.applicationStatus)}
                select={(e) => onSelect(e, balance)}
                iconCss='e-icons e-more-vertical-2'
                cssClass='e-caret-hide e-custom e-vertical'
                iconPosition='Left'
            ></DropDownButtonComponent>
        );
    }

    const leaveTypeTemplate = (rowData) => {
        const { leaveSymbolfull } = rowData;

        const toolTipStyle = {
            fontFamily: 'Roboto',
            fontSize: '0.8rem'
        }
        // Define a maximum width for the container
        const containerStyle = {
            maxWidth: '100%', // You can adjust this value as needed
            whiteSpace: 'nowrap', // Preserve text on a single line
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            cursor: 'pointer',
            fontFamily: 'Roboto',
            fontSize: '0.8rem'
        };

        return (
            <TooltipComponent content={leaveSymbolfull} position="BottomCenter" cssClass="helpdesk-grid-popup" style={toolTipStyle}>
                <div style={containerStyle}>
                    {leaveSymbolfull}
                </div>
            </TooltipComponent>
        );
    }
    const dateOfFromDate = (propsFromDate) => {
      const { fromDate } = propsFromDate;
        return (
            <AllFullDateFormat date={fromDate} />
        );
    }
    const dateOfToDate = (propsToDate) => {
        const { toDate } = propsToDate;
        return (
            <AllFullDateFormat date={toDate} />
        );
    }

    const dateOfActionedOn = (propsToDate) => {
        const { approvedOn } = propsToDate;        
        return (
            <AllFullDateFormat date={approvedOn} />
        );
    }

    const dateOfAppliedOn = (propsToDate) => {
        const { appliedOn } = propsToDate;        
        return (
            <AllFullDateFormat date={appliedOn} />
        );
    }
    const statusTemplate = (prop) => {
        if (prop.applicationStatus === "Utilized")
            return <div>
                <p>{prop.applicationStatus}</p>
            </div>
        return (
            <div>
                <div className={`leave-approval-${prop.status === "2" ? "Approved" : prop.status === "1" ? "Pending" : "Rejected"}`}>
                    {prop.detailedStatus}
                </div>
            </div>
        );
    }
  const handleActionBegin = (args) => {
    if (args.requestType === 'filtering') {      
      // Additional handling or logging can be done here
    }
  };
    return (
        <>
            <style>{SAMPLECSS}</style>
            <GridComponent
                id="leavebalRpt"
                dataSource={props.leaveBalanceData}
                height={getGridHeight()}
                gridLines={'Both'}
                rowHeight={40}
                rowDataBound={rowDataBound}
                allowFiltering={true}
                allowSorting={true}
                filterSettings={{
                    type: "CheckBox",
                }}
                dataSourceChanged={() => dataSourceChanged("leavebalRpt")}
          onDataBinding={() => onDataBinding("leavebalRpt")}
          actionBegin={handleActionBegin}
            >
                <ColumnsDirective>
                    {getGridColumn("leaveBalanceReport").map((item) => {
                        switch (item.colName) { /**NOSONAR */
                            case ADMIN_MODULE_ACTION:
                                return (
                                    <ColumnDirective
                                        isPrimaryKey={true}
                                        textAlign={item.align}
                                        template={actionTemplate}
                                        field={item.field}
                                        headerText={item.colName}
                                        width={item.width}
                                        allowFiltering={false}
                                    />
                                )

                            case LEAVE_BALANCE_FROM_DATE:
                                return (
                                    <ColumnDirective
                                        textAlign={item.align}
                                        template={dateOfFromDate}
                                        field={item.field}
                                        headerText={item.colName}
                                        width={item.width}
                                        allowFiltering={true}
                                    format={DateFilterformat}
                              filter={{ params: { format: 'dd-MM-yyyy' }, type: 'Menu' }}
                                    // filter={{ type: 'Menu' }}
                                    />
                                );
                            case LEAVE_BALANCE_TYPE:
                                return (
                                    <ColumnDirective
                                        textAlign={item.align}
                                        template={leaveTypeTemplate}
                                        field={item.field}
                                        headerText={item.colName}
                                        width={item.width}
                                        allowFiltering={true}
                                    />
                                );

                            case LEAVE_BALANCE_TO_DATE:
                                return (
                                    <ColumnDirective
                                        textAlign={item.align}
                                        template={dateOfToDate}
                                        field={item.field}
                                        headerText={item.colName}
                                        width={item.width}
                                        allowFiltering={true}
                                    format={DateFilterformat}
                                    filter={{ params: { format: 'dd-MM-yyyy' }, type: 'Menu' }}
                                    />
                                );
                            case LEAVE_BALANCE_APPLICATION_STATUS:
                                return (
                                    <ColumnDirective
                                        template={statusTemplate}
                                        key={item.field}
                                        textAlign={item.align}
                                        field={item.field}
                                        headerText={item.colName}
                                        width="60px"
                                        allowFiltering={true}
                                    />

                                );
                           

                            case LEAVE_BALANCE_NO_OF_DAYS:
                                return (
                                    <ColumnDirective
                                        key={item.field}
                                        textAlign={item.align}
                                        field={item.field}
                                        headerText={item.colName}
                                        width="70px"
                                        allowFiltering={true}
                                    />
                                );

                            case LEAVE_BALANCE_APPROVED_BY:
                                return (
                                    <ColumnDirective
                                        key={item.field}
                                        textAlign="center"
                                        field={item.field}
                                        headerText={item.colName}
                                        width={item.width}
                                        allowFiltering={true}
                                    />
                                );
                            case LEAVE_BALANCE_APPROVED_ON:
                                return (
                                    <ColumnDirective
                                        textAlign={item.align}
                                        template={dateOfActionedOn}
                                        field={item.field}
                                        headerText={item.colName}
                                        width={item.width}
                                        allowFiltering={true}
                                    format={DateFilterformat}
                                    filter={{ params: { format: 'dd-MM-yyyy' }, type: 'Menu' }}
                                    />
                                );
                                case LEAVE_APPROVAL_APPLIED_ON:
                                return (
                                    <ColumnDirective
                                        textAlign={item.align}
                                        template={dateOfAppliedOn}
                                        field={item.field}
                                        headerText={item.colName}
                                        width={item.width}
                                        allowFiltering={true}
                                    format={DateFilterformat}
                                    filter={{ params: { format: 'dd-MM-yyyy' }, type: 'Menu' }}
                                    />
                                );
                            default:
                                return (
                                    <ColumnDirective
                                        key={item.field}
                                        textAlign={item.align}
                                        field={item.field}
                                        headerText={item.colName}
                                        width={item.width}
                                        allowFiltering={true}
                                    />
                                );
                        }
                    })}
                </ColumnsDirective>
                <Inject services={[Page, Filter, Sort, InfiniteScroll]} />
            </GridComponent>
        </>
    )
}
export default React.memo(LeaveHistory);