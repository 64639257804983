import React, { Component } from "react";
import PropTypes from "prop-types";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import { urlConfig } from "../../../config/config";

export default class EmployeeNameTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.navigateToEmployeeProfile = this.navigateToEmployeeProfile.bind(this);
  }

  navigateToEmployeeProfile = (empData) => {
    // eslint-disable-next-line
    this.props.navigateToEmployeeProfile(empData);
  };
  addDefaultSrc(ev) {
    ev.target.parentNode.innerHTML = '<span  class="img-circle fas fa-user-circle img-circle-grid-photo" alt="" ></span>'
  }
  render() {
    const { props } = this.props;
    let imgurl = "";
    if (props.PhotoNm === "") {
      imgurl = "../../../app/containers/tmlc/onboarding/avatar-s-16.png";
    } else {
      imgurl = `${urlConfig.tmlcApiUrl}document/E/${props.authUser}/${props.PhotoNm}`;
    }
    return (
      <div
        className="row control-section col-md-12"
        style={{ flexWrap: "wrap", display: "-webkit-box" }}
      >
        <div
          role="button"
          tabIndex="0"
          className="col-xs-2 col-sm-2 col-lg-2 col-md-12"
          style={{ cursor: "pointer" }}
          onClick={() => this.navigateToEmployeeProfile(props)}
          onKeyDown={() => this.navigateToEmployeeProfile(props)}
        >
          {props.PhotoNm === "" || props.PhotoNm === null ? (
            <center>
              <center><span className="img-circle fas fa-user-circle" alt="" style={{ color: 'gray', fontSize: '35px', width: '75%', height: '100%' }} /></center>
            </center>
          ) : (
              <center>
                <img
                  className="img-circle"
                  src={imgurl}
                  onError={this.addDefaultSrc}
                  alt=""
                  style={{
                    width: "35px",
                    height: "35px",
                    borderRadius: "17.5px",
                  }}
                />
              </center>
            )}
        </div>
        <div
          className="col-xs-2 col-sm-2 col-lg-8 col-md-12"
          style={{ fontSize: 14, color: "#4b4a52", cursor: "pointer" }}
        >
          <span
            role="button"
            tabIndex="-1"
            className="span"
            onKeyDown={() => this.navigateToEmployeeProfile(props)}
            onClick={() => this.navigateToEmployeeProfile(props)}
          >
            {props.EmployeeName}
          </span>
          <TooltipComponent
            closeOn="Click"
            content={props.Role}
            position="BottomCenter"
            isSticky={false}
            cssClass='customtooltip'
          >
            <span className="span text-limit" style={{ fontSize: "13px" }}>
              {props.Role}
            </span>
          </TooltipComponent>
        </div>
      </div>
    );
  }
}
EmployeeNameTemplate.propTypes = {
  props: PropTypes.arrayOf(PropTypes.object).isRequired,
};
