import {
  // HIDE_MESSAGE,
  SHOW_MESSAGE,
  INIT_URL,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SIGNIN_USER,
  SIGNIN_USER_SUCCESS,
  SIGNOUT_USER,
  SIGNOUT_USER_SUCCESS,
  CHANGE_AUTH_USER,
  CHECK_USER_AVAILABILITY,
  UPDATE_AUTH_USER_INFO
} from "../constant/constant";

export const userSignIn = (user) => {
  return {
    type: SIGNIN_USER,
    payload: user,
  };
};
export const userSignOut = () => {
  return {
    type: SIGNOUT_USER,
  };
};
export const userSignInSuccess = (authUser) => {
  return {
    type: SIGNIN_USER_SUCCESS,
    payload: authUser,
  };
};
export const userSignOutSuccess = () => {
  return {
    type: SIGNOUT_USER_SUCCESS,
  };
};
export const displayMessage = (message) => {
  return {
    type: SHOW_MESSAGE,
    payload: message,
  };
};

export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url,
  };
};
export const showLoader = () => {
  return {
    type: ON_SHOW_LOADER,
  };
};

export const hideLoader = () => {
  return {
    type: ON_HIDE_LOADER,
  };
};
export const changeAuthUser = (payload) => {
  return {
    type: CHANGE_AUTH_USER,
    payload,
  };
};
export const checkUserAvailability = (payload) => {
  return {
    type: CHECK_USER_AVAILABILITY,
    payload,
  };
};

export const updateUserInfo = (payload) => {
  return {
      type: UPDATE_AUTH_USER_INFO,
      payload: payload
  };
}