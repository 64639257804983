import React, { Component } from "react";

import AdminServices from "../../../services/adminservices";
import { connect } from "react-redux";
import OrgchartPopUp from "./orgchartpopup";
import "./orgchartadmin.css";
import {
  updateAdminReduxData,
  addEditSubBusinessUnitFunction,
} from "../../../redux/actions/admin/adminaction";
import { Button } from "@material-ui/core";
import { faCaretDown, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { GridComponent, ColumnsDirective, ColumnDirective, Inject, Filter, Aggregate, Group, AggregateColumnsDirective, AggregateColumnDirective, AggregateDirective, AggregatesDirective } from '@syncfusion/ej2-react-grids';
import SubActionTemplate from "./subBusinessAction";
import './orgChartMaster.css';
import {
  getGridColumn,

  HIERARCHY,

  EMPLOYEE_COUNT,
  ACTION
} from "../../../app/other/gridcolumns";

import Progressbar from "../../../components/progressbar/progressbar";
import ErrorMessage from "../../errormessage/errormessage";
import stylingNewSubBussinessUnit from "./orgChartStylingGridComponents";
import { setSpinner } from '@syncfusion/ej2-react-popups';
import { spinner } from "../../../app/other/commonfunction";


class Subbusinessfunction extends Component {
  constructor() {
    super();

    this.state = {
      height:
        (window.innerHeight ||
          document.documentElement.clientHeight ||
          document.body.clientHeight) - 188,
      heightPending:
        (window.innerHeight ||
          document.documentElement.clientHeight ||
          document.body.clientHeight) - 188,
      gridHeight: (window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight) - 290,

      result: [],
      subBusinessFunctionMasterData: [],
      openpopup: false,
      maxHierarchy: 0,
      isLoading: true,
      isExpanded: true
    };
    this.title = "";
    this.type = "";
    this.header = "Sub Business Unit / Function";
    this.filterSettings = { type: 'CheckBox' };
    this.groupOptions = { showGroupedColumn: true, columns: ['businessUnitFunction'], showDropArea: false };
    this.actionTemplate = this.actionTemplate.bind(this);
    this.check = { type: 'CheckBox' };
    this.width =
      (window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth) - 100;
    this.dataBound = this.dataBound.bind(this);
    this.onDataBinding = this.onDataBinding.bind(this)
    this.initialLoad = true;
  }

  updateDimensions() {
    const updateHeight = window.innerHeight - 230;
    const updateHeightp = window.innerHeight - 185;
    this.setState({ height: updateHeight, heightPending: updateHeightp });
  }

  componentDidMount() {
    this.getBusinessFunctionData();
    this.getAdminMasterData();
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.subBusinessFunctionFlag === "updated") {
      this.closePopup();
      this.getBusinessFunctionData();
      this.props.updateAdminReduxData({ subBusinessFunctionFlag: "" });
    }
  }

  getAdminMasterData() {
    try {
      const adminMasterData = Promise.resolve(
        AdminServices.getAdminMasterData("SUBBUSINESSUNITSUBFUNCTION")
      );
      adminMasterData.then((result) => {
        if (result !== null) {
          this.setState({ subBusinessFunctionMasterData: result, isLoading: false });
        }
      });
    } catch (error) {
      // eslint-disable-next-line
      console.log(error);
    }
  }


  getBusinessFunctionData() {
    try {
      const businessFunction = Promise.resolve(
        AdminServices.getAdminOrgChartGridData("subBusinessUnitSubFunction")
      );
      businessFunction.then((result) => {
        if (result !== null) {
          if (result !== null) {
            const maxHierarchy = result.result.reduce(
              (acc, item) => (acc > item.hierarchy ? acc : item.hierarchy),
              0
            );
            this.setState({
              result: result.result,
              maxHierarchy: parseInt(maxHierarchy, 10) + 1,
            });
          }
        }
      });
    } catch (error) {
      // eslint-disable-next-line

      console.error(error);
    }
  }

  openPopup = (type, subBusinessUnitFunctionData) => {
    this.setState({ openPopup: true });
    this.title = this.header;
    this.type = type;
    this.subBusinessUnitFunctionData = subBusinessUnitFunctionData;
  };
  closePopup = () => {
    this.setState({ openPopup: false });
    this.title = null;
    this.type = null;
    this.subBusinessUnitFunctionData = null;
  };

  makeActive = (type, subBusinessFunctionData, activeFlag) => {
    const { authUser } = this.props;
    let data = {
      authUser: authUser,
      type: type,

      subBusinessUnitFunctionId: subBusinessFunctionData.fkId,
      isActive: activeFlag,
    };
    this.props.addEditSubBusinessUnitFunction(data);

    setTimeout(() => {
      this.setState(prevState => ({
        isExpanded: !prevState.isExpanded
      }))
    }, 500);
  };

  onChange(sel) {
    this.gridInstance.filterSettings.type = sel.itemData.value;
    this.gridInstance.clearFiltering();
  }


  actionTemplate = (props) => {


    return (<div style={{ display: "flex", justifyContent: "center", marginRight: 14 }}>
      <SubActionTemplate
        props={props}
        category="SUB_BUSINESS"
        authUser={this.props.authUser}
        makeActiveInActive={this.makeActive}
        openPopup={this.openPopup}
      />
    </div>
    )
  }

  level1ActionRole = (props) => {

    return (
      <div style={{ textAlign: "center" }}>{props.hierarchy}
      </div>
    )
  }

  level2ActionRole = (props) => {

    return (
      <div style={{ textAlign: "center" }}>{props.empCnt}
      </div>
    )
  }



  setHeaderCss = () => {
    setTimeout(() => {
      const ele = document.getElementsByClassName("e-headercelldiv");
      for (let i in ele) {
        if (!isNaN(parseInt(i, 10))) {
          let index = parseInt(i, 10);

          if (ele[index].innerText === ACTION) {
            ele[index].classList.add("grid-center-g-header");
          } else {
            ele[index].classList.add("grid-left-g-header");
          }
        }
      }
    }, 500);
  }
  groupcFootertMax(props) {
    if (props.field === "businessUnitFunction") {
      return (<p style={{ fontWeight: "bold", textAlign: "center", marginLeft: 14 }}>{props.Sum}</p>);
    }
    return null
  }
  dataBound() {
    if (this.gridInstance.groupSettings.columns.length > 0) {
      this.gridInstance.groupModule.collapseAll();
    }
  }

  expandFunc = () => {
    const { isExpanded } = this.state
    this.setState(prev => ({ isExpanded: !prev.isExpanded }), () => {
      if (isExpanded) {
        this.gridInstance.groupModule.expandAll();
      }
      else {
        this.gridInstance.groupModule.collapseAll();
      }
    })

  }


  /**
  * Created on 06-09-2022 || Noel Fredrick
  * Description - For spinner
  */
  onDataBinding() {
    spinner(true)
    // try {
    //   refreshMultiSelect(pComponentId);
    //   setTimeout(() => {
    //     document.getElementById(pComponentId).ej2_instances[0].refresh()
    //   })
    // } catch (error) {
    //   console.log(error)
    // }
  }



  onFirstGridCreated() {
    if (this.initialLoad) {
      this.initialLoad = false;
      setSpinner({
        template: document.getElementById('spinnerContainer').innerHTML,
      });
      document.getElementById('spinnerContainer').remove();
      // this.gridInstance.freezeRefresh(); // re-render the grid component
    }

  }



  render() {
    const { openPopup, result, height, maxHierarchy, gridHeight, isLoading, isExpanded } = this.state;
    this.setHeaderCss();
    var widthColumnConfirm = 0;
    var finalWidthConfirm;
    // eslint-disable-next-line
    getGridColumn("orgChartMasterAdmin").forEach((data) => {
      widthColumnConfirm = widthColumnConfirm - 10 + data.width;
    });
    if (window.innerWidth === 1024) {
      finalWidthConfirm = 1350
    } else if (window.innerWidth === 1920) {
      finalWidthConfirm = Math.min(widthColumnConfirm + 150, this.width);
    } else {
      finalWidthConfirm = this.width + 250;
    }
    let heightAdjustment = 0;
    const windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    switch (true) {
      case windowHeight > 767:
        heightAdjustment = 75;
        break;
      case windowHeight === 599 || windowHeight === 551:
        heightAdjustment = 125;
        break;
      case windowHeight === 731:
        heightAdjustment = 125;
        break;
      case windowHeight === 599 && window.innerWidth === 1024:
        heightAdjustment = 150;
        break;
      default:
        heightAdjustment = 70;
    }
    const gridHeightNew = gridHeight - heightAdjustment;

    const IconContent = !isExpanded ? (<FontAwesomeIcon icon={faCaretRight} className="mr-sm" />) : (<FontAwesomeIcon icon={faCaretDown} className="mr-sm" />)
    const styleExpand = !isExpanded ? "jss892 expandBtn" : 'expandBtn MuiButton-containedPrimary'
    return (
      <div
        style={{
          color: "black",
          height: height + 15,
          // height: height + 72,
          padding: "8px 4px 8px 4px ",
          marginLeft: 14
        }}
        className="sub-businessfunc-height" id="sub-businessfunc-id"
      >
        {result.length > 0 ? <>
          <div style={{ marginBottom: 8 }} className="row">
            <div className="col-xs-5 col-sm-5 col-lg-5 col-md-5 add-header">{this.header} </div>
            <div
              className="col-xs-5 col-sm-5 col-lg-5 col-md-5"
              style={{ paddingBottom: 4, textAlign: 'right' }}
            >
              <Button
                className={styleExpand}
                variant="contained"
                onClick={this.expandFunc}
              // className="addbtn businessbtn"
              >
                {IconContent}
                {
                  !isExpanded ? "Collapse All" : "Expand All" //NOSONAR
                }
              </Button>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-10 col-sm-10 col-lg-10 col-md-10">
              <div style={{ paddingTop: "5px", overflowY: "auto", overflowX: "auto" }}
                className="control-section"
              ><style>{stylingNewSubBussinessUnit}</style>
                <GridComponent
                  dataSource={result}
                  contextMenuItems="AutoFitAll"
                  ref={(grid) => (this.gridInstance = grid)}
                  allowGrouping={true}
                  allowFiltering
                  filterSettings={this.filterSettings}
                  groupSettings={this.groupOptions}
                  allowSorting={false}
                  height={gridHeightNew}
                  rowHeight={40}
                  gridLines='Both'
                  // width={finalWidthConfirm + 190}
                  dataBound={this.dataBound.bind(this)}
                  onDataBinding={this.onDataBinding("sub-businessfunc-id")}
                  created={this.onFirstGridCreated.bind(this)}
                >
                  <ColumnsDirective>
                    {getGridColumn("orgChartMasterAdminSubBusinessUnit").map((item) => {
                      switch (item.colName) {
                        case HIERARCHY:
                          return (
                            <ColumnDirective
                              key={item.field}
                              template={this.level1ActionRole}
                              textAlign={`${item.align} !important`}
                              field={item.field}
                              headerText={item.colName}
                              width={item.width}
                              filter={this.check}
                              type={"number"}
                            >
                            </ColumnDirective>
                          );
                        case EMPLOYEE_COUNT:
                          return (
                            <ColumnDirective
                              key={item.field}
                              template={this.level2ActionRole}
                              textAlign={`${item.align} !important`}
                              field={item.field}
                              headerText={item.colName}
                              width={item.width}
                              filter={this.check}
                              type={"number"}
                            >
                            </ColumnDirective>
                          );
                        case ACTION:
                          return (
                            <ColumnDirective
                              key={item.field}
                              template={this.actionTemplate}
                              textAlign={`${item.align} !important`}
                              field={item.field}
                              headerText={item.colName}
                              width={item.width - 20}
                              filter={false}
                            >
                            </ColumnDirective>
                          );

                        default:
                          return (
                            <ColumnDirective
                              key={item.field}
                              // template={this.employeeNameTemplate}
                              textAlign={`${item.align} !important`}
                              field={item.field}
                              headerText={item.colName}
                              width={item.width}
                              filter={this.check}
                            >
                            </ColumnDirective>
                          );

                      }
                    })}
                  </ColumnsDirective>
                  <AggregatesDirective>
                    <AggregateDirective>
                      <AggregateColumnsDirective>
                        <AggregateColumnDirective field='empCnt' type='Sum' groupCaptionTemplate={this.groupcFootertMax}> </AggregateColumnDirective>
                      </AggregateColumnsDirective>
                    </AggregateDirective>

                  </AggregatesDirective>
                  <Inject services={[Filter, Group, Aggregate]} />
                </GridComponent>

                <div className="loader-new" id="spinnerContainer" >
                  <Progressbar />
                </div>

              </div>
            </div>
          </div>
        </> : <div className="control-section col-md-12" style={{ height: 75 }}>
          {isLoading && (<div className="loader-new">
            {/* <Progressbar /> */}
          </div>)
          }
          {(result.length === 0 && isLoading === false) && (
            <ErrorMessage message="No Data Available" />
          )}
        </div>
        }


        {openPopup && (
          <OrgchartPopUp
            closePopup={this.closePopup}
            component="SubBusinessUnit"
            title={this.title}
            type={this.type}
            subBusinessFunctionMasterData={
              this.state.subBusinessFunctionMasterData
            }
            subBusinessUnitFunctionData={this.subBusinessUnitFunctionData}
            maxHierarchy={maxHierarchy}
          />
        )}
      </div>
    );
  }
}
const mapStateToProps = ({ adminreducer, auth }) => {
  const { subBusinessFunctionFlag } = adminreducer;
  const { authUser } = auth;
  return { subBusinessFunctionFlag, authUser };
};
export default connect(mapStateToProps, {
  updateAdminReduxData,
  addEditSubBusinessUnitFunction,
})(Subbusinessfunction);
