import React, { Component } from "react";
import { ComboBoxComponent, MultiSelectComponent, CheckBoxSelection } from "@syncfusion/ej2-react-dropdowns";
import TextField from "@material-ui/core/TextField";
import {
  TextBoxComponent,
  NumericTextBoxComponent,
} from "@syncfusion/ej2-react-inputs";
import { Inject } from "@syncfusion/ej2-react-schedule";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {
  updateReasonsForResignation,
  addUpdateSeparationDetails,
} from "../../../redux/actions/entryexit/separationaction";
import SeparationServices from "../../../services/entryexit/separationServices";
import "./resignationcomponent.css";
import {
  getDisplayDateOnly,
  addDays,
  dateDiffIndays,
  // getNotify
} from "../../../app/other/commonfunction";
import ProgressBar from "../../progressbar/progressbar";

class ReasonForResignation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      willingToBuyBackNoticeData: [],
      reasonForResignation: [],
      otherReasonsForResignation: [],
      willingToBuyBackNotice: 0,
      primaryReasonId: 0,
      otherReasonIds: [],
      primaryReasonFlag: false,
    };
  }

  componentDidMount = () => {
    this.getResignatuionMasterData();
    const { resinationDate, noOfDays
    } = this.props;
    addDays(new Date(resinationDate), noOfDays)
    // eslint-disable-next-line
    this.props.addUpdateSeparationDetails({ Remarks: "", requestedLWD: addDays(new Date(resinationDate), noOfDays) });
    setTimeout(() => {
      if (this.textareaObj !== null && typeof this.textareaObj != 'undefined') {
        this.textareaObj.addAttributes({ rows: 4 });
      }
    }, 2000);
  };
  // eslint-disable-next-line
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.employeeStatus === "Resigned" ||
      nextProps.employeeStatus === "Resignation - Withdrawal"
    ) {
      this.getWithdrawalMasterData();
    }
    if (nextProps.updateResonForResignationStatus === "updated") {
      this.getResignatuionMasterData();
      // eslint-disable-next-line
      this.props.addUpdateSeparationDetails({ updateResonForResignationStatus: '' })
    }
  }

  getWithdrawalMasterData = () => {
    try {
      const { authUser } = this.props;
      const employeeMasterData = Promise.resolve(
        SeparationServices.getEmployeeResignationMaster(authUser, "wdrl")
      );
      employeeMasterData.then((result) => {
        if (result !== null && result.length > 0) {
          const withdrawlReason = result.filter((item) => {
            return item.Type === "Withdrawl Reason";
          });
          // eslint-disable-next-line
          this.props.addUpdateSeparationDetails({ "withdrawlReason": withdrawlReason })
        }
      });
    } catch (error) {
      // eslint-disable-next-line
    }
  };

  getResignatuionMasterData = () => {/**NOSONAR */
    try {
      const { authUser } = this.props;
      const employeeMasterData = Promise.resolve(
        SeparationServices.getEmployeeResignationMaster(authUser, "resg")
      );

      employeeMasterData.then((result) => {
        if (result !== null && result.length > 0) {
          const willingToBuyBackNoticeData = result.filter((item) => {
            return item.Type === "Willing to Buyback Notice";
          });
          const reasonForResignation = result.filter((item) => {
            return item.Type === "Resignation Reason";
          });
          const primaryReason = result.filter((item) => {
            return item.selected && item.Type === "Resignation Reason" && item.primary_reason === 'Y';
          });

          const otherReason = result.filter((item) => {
            return item.selected && item.Type === "Resignation Reason" && item.primary_reason === 'N';
          });
          var primId = 0;
          var primaryReasonFlag = false;
          if (primaryReason.length > 0) {
            primId = primaryReason[0].Id;
            primaryReasonFlag = true;
          }
          var other = [];
          if (otherReason.length > 0) {
            otherReason.forEach(element => {
              other.push(element.Id);
            });
          }
          this.setState({
            willingToBuyBackNoticeData,
            reasonForResignation,
            otherReasonsForResignation: reasonForResignation,
            primaryReasonId: primId,
            otherReasonIds: other,
            primaryReasonFlag: primaryReasonFlag
          });
        }

        setTimeout(() => {
          if (this.textareaObj !== null && typeof this.textareaObj !== 'undefined') {
            this.textareaObj.addAttributes({ rows: 4 });
          }
        }, 2000);
      });
    } catch (error) {
      // eslint-disable-next-line
    }
  };

  TextFieldComponent = (props) => {
    // eslint-disable-next-line
    return <TextField {...props} disabled />;
  };

  limitCaracter = (e) => {
    let word = "";
    let addressCount = 0;
    word = e.value;
    addressCount = word.length;
    document.getElementById(
      "reasonResignationLimitErr"
    ).innerHTML = `${addressCount}/500`;
  };
  getPrimaryReason = (e) => {
    const { reasonForResignation, otherReasonIds } = this.state;
    var otherReasonIdsArr = otherReasonIds;
    if (e.itemData && e.itemData.Id > 0) {
      let otherReasonsForResignationArr = reasonForResignation.filter(item => {
        return item.Id !== e.itemData.Id;
      }
      );
      const index = otherReasonIdsArr.indexOf(e.itemData.Id);
      if (index > -1) {
        otherReasonIdsArr.splice(index, 1);
      }
      this.setState({ primaryReasonId: e.itemData.Id, otherReasonIds: otherReasonIdsArr, otherReasonsForResignation: otherReasonsForResignationArr, primaryReasonFlag: true });
    }
    else
      this.setState({ primaryReasonId: 0, otherReasonIds: [], otherReasonsForResignation: reasonForResignation, primaryReasonFlag: false });

    this.props.updateReasonsForResignation({ primaryReasonForResignationId: this.state.primaryReasonId > 0 ? this.state.primaryReasonId.toString() : "", reasonsForResignationIds: this.state.otherReasonIds.toString() });
  };
  removeItem = (e) => {
    const { otherReasonIds } = this.state;
    var modifiedOtherReasonIds = [];
    if (e.itemData && e.itemData.Id > 0) {
      modifiedOtherReasonIds = otherReasonIds.filter(item => {
        return item !== e.itemData.Id;
      });
      this.setState({ otherReasonIds: modifiedOtherReasonIds });
      this.props.updateReasonsForResignation({ reasonsForResignationIds: this.state.otherReasonIds.toString() });
    }
    else {
      console.log('Error in Removing Other Reasons for Resignation');
    }
  };
  getOtherReasons = (e) => {
    const { otherReasonIds } = this.state;
    var modifiedOtherReasonIds = otherReasonIds;
    if (e.itemData && e.itemData.Id > 0) {
      modifiedOtherReasonIds.push(e.itemData.Id);
      this.setState({ otherReasonIds: modifiedOtherReasonIds });
      this.props.updateReasonsForResignation({ reasonsForResignationIds: this.state.otherReasonIds.toString() });
    }
    else {
      console.log('Error in Adding Other Reasons for Resignation');
    }
  };
  render() {/**NOSONAR */
    const { reasonForResignation, willingToBuyBackNoticeData, otherReasonsForResignation, primaryReasonFlag, primaryReasonId, otherReasonIds } = this.state;
    const { noticePeriodInMonth, resinationDate, employeeStatus, Remarks, willingToBuyBackNotice, noOfDays } = this.props;
    const lwd =
      resinationDate !== ""
        ? addDays(new Date(resinationDate), noticePeriodInMonth * 30)
        : addDays(new Date(), noticePeriodInMonth * 30);
    var otherReasonIdsVal = null;
    if (otherReasonIds.length > 0)
      otherReasonIdsVal = otherReasonIds;
    return (
      <div>
        <div> REASON FOR RESIGNATION * </div>
        <div>
          {reasonForResignation.length > 0 && otherReasonsForResignation.length > 0 ?
            <div>
              <div className="col card-layout">
                {reasonForResignation.length > 0 &&
                  <>
                    <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2 bold-text" style={{ paddingTop: "10px" }}>Primary Reason
                      <div style={{ paddingTop: "10px" }}>
                        <ComboBoxComponent
                          id="primeReason"
                          dataSource={reasonForResignation}
                          fields={{ text: "Name", value: "Id" }}
                          value={primaryReasonId}
                          data-msg-containerid="primeReasonErr"
                          name="primeReasonErr"
                          allowFiltering
                          allowCustom={false}
                          floatLabelType="Auto"
                          cssClass="e-outline"
                          placeholder={'Select Primary Reason*'}
                          popupHeight="350px"
                          // popupWidth="180"
                          change={(e) => this.getPrimaryReason(e)}
                          readonly={(employeeStatus === "Resigned" || employeeStatus === "Resignation - Withdrawal") ?/**NOSONAR */ true : false}
                        />
                        <div
                          id="primeReasonErr"
                          className="e-input-group e-float-icon-left error-div"
                        />
                      </div>
                    </div>
                  </>
                }
                {primaryReasonFlag && otherReasonsForResignation.length > 0 &&
                  <>
                    <div className="col-xs-5 col-sm-5 col-lg-5 col-md-5 bold-text" style={{ paddingTop: "10px" }}>Other Reasons
                      <div style={{ paddingTop: "10px" }}>
                        <MultiSelectComponent
                          id="otherReason"
                          ref={(multiselect) => {
                            this.searchEmail = multiselect;
                          }}
                          cssClass="e-outline"
                          // eslint-disable-next-line
                          dataSource={otherReasonsForResignation}
                          value={otherReasonIdsVal}
                          // dataSource={reasonForResignation}
                          allowFiltering={false}
                          fields={{ text: "Name", value: "Id" }}
                          select={this.getOtherReasons}
                          maximumSelectionLength={5}
                          removed={this.removeItem}
                          mode="CheckBox"
                          showDropDownIcon
                          //filtering={this.handleFilteringByName}
                          placeholder={"Other Reasons"}
                          showSelectAll={false}
                          readonly={(employeeStatus === "Resigned" || employeeStatus === "Resignation - Withdrawal") ? /**NOSONAR */ true : false}
                        >
                          <Inject services={[CheckBoxSelection]} />
                        </MultiSelectComponent>
                      </div>
                    </div>
                  </>
                }
                {(employeeStatus === "Resigned" ||
                  employeeStatus === "Resignation - Withdrawal") && (
                    <div
                      style={{
                        position: "absolute",
                        background: "rgba(255,255,255,0.6",
                        height: "100%",
                        width: "100%",
                        // zIndex:11
                      }}
                    />
                  )}
              </div>
              <form id='noOfDayForm'>
                <div
                  className="col-12"
                  style={{
                    background: "#FFF",
                    // background: "#F4F3F5",
                    marginTop: "15px",
                    paddingTop: "10px",

                  }}
                >
                  <div className="col-xs-2 col-sm-2 col-lg-3 col-md-12">
                    <div className="col-xs-2 col-sm-2 col-lg-6 col-md-2">
                      <div className="bold-text">Notice Period</div>
                      <div>{noticePeriodInMonth * 30} Days</div>
                    </div>
                    <div className="col-xs-2 col-sm-2 col-lg-6 col-md-2">
                      <div className="bold-text">LWD</div>
                      <div>{getDisplayDateOnly(new Date(lwd))}</div>

                    </div>
                  </div>
                  <div className="col-xs-2 col-sm-2 col-lg-3 col-md-2">
                    <NumericTextBoxComponent
                      style={{ background: "#FFF" }}
                      decimals={false}
                      floatLabelType="Auto"
                      id="noticePeriodRequest"
                      value={noOfDays}
                      // value={requestedNoticePeriod}
                      max={noticePeriodInMonth * 30}
                      readonly={
                        !!(
                          employeeStatus === "Resigned" ||
                          employeeStatus === "Resignation - Withdrawal"
                        )
                      }
                      change={(e) => {
                        this.props.addUpdateSeparationDetails({
                          noOfDays: e.value,
                          requestedLWD: addDays(new Date(), 1 * e.value),
                        });
                      }}
                      cssClass="e-outline"
                      placeholder="Notice Period Request in Days"
                      format='##'
                    />
                    <span
                      style={{
                        position: "absolute",
                        textAlign: "left",
                        marginTop: 10,
                        marginLeft: "1%",
                        fontWeight: "bold",
                      }}
                    >
                      OR
                    </span>
                  </div>



                  <div className="col-xs-2 col-sm-2 col-lg-3 col-md-2">
                    <MuiPickersUtilsProvider
                      utils={DateFnsUtils}
                      style={{ height: 50 }}
                    >
                      <KeyboardDatePicker
                        style={{ width: "100%", background: "#FFF" }}
                        id="lwdCompanyRequest"
                        name="lwdCompanyRequest"
                        value={addDays(new Date(resinationDate), noOfDays)}
                        // value={requestedLWD !== '' ? new Date(requestedLWD) : lwd}
                        format="dd-MMM-yyyy"
                        maxDate={addDays(new Date(resinationDate), noticePeriodInMonth * 30)}
                        minDate={new Date()}
                        KeyboardButtonProps={{
                          "aria-label": "change date",
                        }}
                        onChange={(e) => {
                          // eslint-disable-next-line
                          this.props.addUpdateSeparationDetails({
                            noOfDays: dateDiffIndays(new Date(), e),
                            requestedLWD: getDisplayDateOnly(e),
                          });
                        }}
                        TextFieldComponent={this.TextFieldComponent}
                        inputVariant="outlined"
                        variant="inline"
                        InputAdornmentProps={{ position: "start" }}
                        autoOk
                        label="Requested LWD"
                        disabled={
                          !!(
                            employeeStatus === "Resigned" ||
                            employeeStatus === "Resignation - Withdrawal"
                          )
                        }
                      />
                    </MuiPickersUtilsProvider>
                  </div>
                  <div className="col-xs-2 col-sm-2 col-lg-3 col-md-2">
                    {(noticePeriodInMonth * 30 !== noOfDays || employeeStatus === "Resigned") && willingToBuyBackNoticeData.length > 0 && willingToBuyBackNotice !== null &&
                      <div style={{ background: "#FFF" }}>
                        <ComboBoxComponent
                          // style={{ background: "#FFF"}}
                          allowCustom={false}
                          floatLabelType="Auto"

                          change={(e) =>
                            e.itemData !== null ?
                              this.setState(
                                // eslint-disable-next-line
                                { willingToBuyBackNotice: e.itemData.Id },
                                () => {
                                  // eslint-disable-next-line
                                  this.props.updateReasonsForResignation({ willingToBuyBackNotice: e.itemData.Id });
                                }
                              )
                              :
                              this.setState(
                                // eslint-disable-next-line
                                { willingToBuyBackNotice: 0 },
                                () => {
                                  // eslint-disable-next-line
                                  this.props.updateReasonsForResignation({ willingToBuyBackNotice: 0 });
                                }
                              )
                          }
                          fields={{ text: "Name", value: "Id" }}
                          id="noticePeriodRequest"
                          dataSource={willingToBuyBackNoticeData}
                          cssClass="e-outline"
                          placeholder="Willing to Buyback Notice"
                          // eslint-disable-next-line
                          value={parseInt(willingToBuyBackNotice)}
                          readonly={
                            !!(
                              employeeStatus === "Resigned" ||
                              employeeStatus === "Resignation - Withdrawal"
                            )
                          }
                        // disabled={(employeeStatus === "Resigned" || employeeStatus === "Resignation - Withdrawl") ? true: false}
                        />
                      </div>
                    }
                  </div>

                </div>
                <div
                  className="col-12"
                  style={{
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    background: "#FFF",
                    // background: "#F4F3F5",
                  }}
                >
                  <div className="col-xs-2 col-sm-2 col-lg-12 col-md-2">
                    <div style={{ background: "#FFF", padding: 0, margin: 0 }}>
                      <TextBoxComponent
                        placeholder="Remarks *"
                        cssClass="e-outline"
                        floatLabelType="Auto"
                        name="magenda-txt"
                        ref={(scope) => {
                          this.textareaObj = scope;
                        }}
                        multiline
                        rows={10}
                        style={{ background: "#FFF", padding: 0, margin: 0 }}
                        inputProps={{
                          required: true,
                          minLength: 20,
                        }}
                        input={(e) => this.limitCaracter(e)}
                        value={Remarks}
                        htmlAttributes={{ maxlength: 500 }}
                        blur={(e) => {
                          // eslint-disable-next-line
                          this.props.addUpdateSeparationDetails({ Remarks: e.value })
                        }}
                        readonly={
                          !!(
                            employeeStatus === "Resigned" ||
                            employeeStatus === "Resignation - Withdrawal"
                          )
                        }
                        data-msg-containerid=""
                      />
                    </div>
                    <div style={{ float: "right" }}>
                      <div
                        style={{ textAlign: "right" }}
                        id="reasonResignationLimitErr"
                        className="error-div"
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
            :
            <div style={{ height: 200 }}>
              <ProgressBar />
            </div>
          }
        </div>
      </div>
    );
  }
}

ReasonForResignation.propTypes = {
  authUser: PropTypes.string,
  noticePeriodInMonth: PropTypes.number,
  noOfDays: PropTypes.number,
  willingToBuyBackNotice: PropTypes.number,
  resinationDate: PropTypes.string.isRequired,
  Remarks: PropTypes.string,
  employeeStatus: PropTypes.string,
  updateResonForResignationStatus: PropTypes.string,
};
ReasonForResignation.defaultProps = {
  authUser: "",
  noticePeriodInMonth: 0,
  noOfDays: 0,
  Remarks: "",
  employeeStatus: "",
  updateResonForResignationStatus: "",
  willingToBuyBackNotice: 0,
};
const mapStateToProps = ({ auth, separationreducer }) => {
  const { authUser } = auth;
  const {
    resinationDate,
    noticePeriodInMonth,
    employeeStatus,
    Remarks,
    willingToBuyBackNotice,
    noticePeriodRequested,
    updateResonForResignationStatus,
    requestedLWD,
    noOfDays,
  } = separationreducer;
  return {
    authUser,
    noticePeriodInMonth,
    resinationDate,
    employeeStatus,
    Remarks,
    willingToBuyBackNotice,
    noticePeriodRequested,
    updateResonForResignationStatus,
    requestedLWD,
    noOfDays,
  };
};

export default connect(mapStateToProps, {
  updateReasonsForResignation,
  addUpdateSeparationDetails,
})(ReasonForResignation);
