/**
 * Author Name :- Prashant Waphare
 * Date : 23-11-2020
 * Description :- This file use for show Team Shared Grid.
 */
import * as React from "react";
import { closest, isNullOrUndefined } from "@syncfusion/ej2-base";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Filter,
  Inject,
  VirtualScroll,
  Sort,
  Page,
  Resize,
} from "@syncfusion/ej2-react-grids";
import PropTypes from "prop-types";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import "../../app/containers/tmlc/onboarding/onboarding.css";
import "../../app/containers/tmlc/tmlc.css";

import { connect } from "react-redux";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import { Redirect } from "react-router-dom";

import {  encrypt,decodeString,encodeString } from "../../app/other/commonfunction";
import {
    DialogContent,
    DialogActions,
} from "../customdialog/customdialog";
import { OKR_PRODUCTIVE_ALLOCATION } from '../../redux/constant/meetingcategoryconstant';
import { Button, Dialog } from "@material-ui/core";
import { FormValidator } from "@syncfusion/ej2-inputs";
import NotificationContainer from "../../components/notification/notifycontainer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faEye } from "@fortawesome/pro-light-svg-icons";

import {
  ACTIONOKR,
  getGridColumn,
  OKRSTATUS,
  DESCRIPTIONOKR,
  FROM,
  TO,
  SHAREDPER,
  SHAREDAMT,
  OKR,
  SHAREDWITH,
} from "../../app/other/gridcolumns";
import orgChatMaster from "../../services/entryexit/onbordingServices";
import NewRequestForm from "./newrequestform";
import {
  addEditCostsharingNewRequest,
  setCostSharingDetails,
  addUpdateObjectveKpi,
} from "../../redux/actions/okr/okractions";
import { setMeetingCategory } from "../../redux/actions/meetingcategoryaction";

library.add(faEye);
class TeamSharedGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirestCategary: "",
      shareWithMasterList: [],
      employeeMasterList: [],
      currencyMaster: [],
      openNewRequest: false,
      redirect: null,
      height:
        (window.innerHeight ||
          document.documentElement.clientHeight ||
          document.body.clientHeight) - 290,
      gridHeight:
        (window.innerHeight ||
          document.documentElement.clientHeight ||
          document.body.clientHeight) - 290,
    };
    this.width =
      (window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth) - 100;
    this.dReady = false;
    this.dtTime = false;
    this.isDataBound = false;
    this.isDataChanged = true;
    this.dropSlectedIndex = null;
    this.check = {
      type: "CheckBox",
    };
    this.select = {
      persistSelection: true,
      type: "Multiple",
      checkboxOnly: true,
    };
    this.Filter = {
      type: "Menu",
    };
    this.status = {
      type: "CheckBox",
    };
    this.onComplete = this.onComplete.bind(this);
    this.onLoad = this.onLoad.bind(this);
    this.onQueryCellInfoPreJoining = this.onQueryCellInfoPreJoining.bind(this);
    this.onQueryInfo = this.onQueryInfo.bind(this);
    this.onComplete = this.onComplete.bind(this);
    this.onDataBound = this.onDataBound.bind(this);
    this.pageOptions = {
      pageSize:
        (window.innerHeight ||
          document.documentElement.clientHeight ||
          document.body.clientHeight) > 700
          ? 15
          : 10,
      pageCount: 5,
    };
    this.name = {
      type: "CheckBox",
    };
  }

  /**
   * Calculate & Update state of new dimensions
   */
  updateDimensions() {
    const updateHeight = window.innerHeight - 145;
    const gridHeight = window.innerHeight - 320;
    this.setState({
      //   width: updateWidth,
      height: updateHeight,
      gridHeight,
    });
  }

  componentDidMount() {
    this.updateDimensions();
    this.getAllMasterData();
    this.getEmployeeMasterList();
    this.getShareWithMasterList();
    window.addEventListener("resize", this.updateDimensions.bind(this));
    setTimeout(() => {
      const ele = document.getElementsByClassName("e-headercelldiv");
      for(let i in ele) {
        if(!isNaN(parseInt(i, 10))){
          let index = parseInt(i, 10);
          ele[index].classList.add("grid-left-header");
        }
      }
    }, 1000);
  }

  getShareWithMasterList = () => {
    const { authUser } = this.props;
    const getShareWithMasterList = Promise.resolve(
      orgChatMaster.getMasterData("SHAREWITH", authUser)
    );
    getShareWithMasterList.then((data) => {
      if (data !== null) {
        this.setState({ shareWithMasterList: data });
      }
    });
  };

  getAllMasterData = () => {
    const { authUser } = this.props;
    try {
      const getMasterData = Promise.resolve(
        orgChatMaster.getMasterData("All", authUser)
      );
      getMasterData.then((resultData) => {
        if (resultData !== null) {
          const currency = resultData.filter((item) => {
            return item.Type === "Currency";
          });
          this.setState({
            currencyMaster: currency,
          });
        } else {
          this.data = "error";
        }
      });
    } catch (e) {
      // eslint-disable-next-line
            console.log(e);
    }
  };

  onQueryCellInfoPreJoining = (args) => {
    const { cell } = args;
    cell.setAttribute(
      "style",
      "font-size:14px !important; text-align: left !important; padding-left:19px !important"
    );
  };

  onQueryInfo = (args) => {
    const { cell } = args;
    cell.setAttribute(
      "style",
      "font-size:12px !important; text-align: left !important; padding-left:2px !important"
    );
  };

  onDataBound() {
    clearTimeout(this.clrIntervalFun);
    clearInterval(this.intervalFun);
    this.dtTime = true;
  }

  onComplete = (args) => {
    if (args.requestType === "filterchoicerequest") {
      if (
        args.filterModel.options.field === "Trustworthiness" ||
        args.filterModel.options.field === "Rating" ||
        args.filterModel.options.field === "Status"
      ) {
        const span = args.filterModel.dialogObj.element.querySelectorAll(
          ".e-selectall"
        )[0];
        if (!isNullOrUndefined(span)) {
          closest(span, ".e-ftrchk").classList.add("e-hide");
        }
      }
    }
  };

  onChange() {
    this.ddObj.hidePopup();
    this.gridInstance.showSpinner();
    this.dropSlectedIndex = null;
    clearTimeout(this.clrIntervalFun2);
    this.clrIntervalFun2 = setTimeout(() => {
      this.isDataChanged = true;
      this.stTime = null;
      const contentElement = this.gridInstance.contentModule.getPanel()
        .firstChild;
      contentElement.scrollLeft = 0;
      contentElement.scrollTop = 0;
      this.gridInstance.pageSettings.currentPage = 1;
      this.stTime = performance.now();
      this.gridInstance.hideSpinner();
    }, 100);
  }

  onLoad() {
    document
      .getElementById("overviewgrid")
      .ej2_instances[0].on("data-ready", () => {
        this.dReady = true;
        this.stTime = performance.now();
      });
    document
      .getElementById("overviewgrid")
      .addEventListener("DOMSubtreeModified", () => {
        if (this.dReady && this.stTime && this.isDataChanged) {
          this.stTime = null;
          this.dReady = false;
          this.dtTime = false;
          this.isDataChanged = false;
        }
      });
  }

  getEmployeeMasterList = () => {
    const { authUser } = this.props;
    const getEmployeeMasterList = Promise.resolve(
      orgChatMaster.getMasterData("TEAMMEMBER", authUser)
    );
    getEmployeeMasterList.then((data) => {
      if (data !== null) {
        this.setState({ employeeMasterList: data });
      }
    });
  };

  created = () => {
    setTimeout(() => {
      if (document.getElementById("right-pane")) {
        document
          .getElementById("right-pane")
          .addEventListener("click", this.onClick.bind(this));
        document
          .getElementById("right-pane")
          .addEventListener("scroll", this.onScroll.bind(this));
      }
    }, 1000);
  };

  deacriptionTemp = (props) => {
    return (
      <div>
        <TooltipComponent
          content={props.description.replace(/(?:\r\n|\r|\n)/g, "<br>")}
          position="BottomCenter"
          created={this.created}
          isSticky={false} // if use false tooltip auto cancel
          // isSticky={true} // if use true tooltip we naualt need to cancel it.
          className="tooltip-box"
          cssClass="customtooltip"
        >
          <span
            style={{
              fontSize: "14px",
              display: "block",
              width: "200px",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {props.description}
          </span>
        </TooltipComponent>
      </div>
    );
  };

  statusTemp = (props) => {
    const { enumAcceptanceStatus } = props;
    if (
      enumAcceptanceStatus === "0" ||
      enumAcceptanceStatus === null ||
      enumAcceptanceStatus === ""
    ) {
      return (
        <div>
          <span>Not Shared</span>
        </div>
      );
    }
    if (enumAcceptanceStatus === "1") {
      return (
        <div>
          <div style={{ color: "green" }}>Shared</div>
        </div>
      );
    }
    if (enumAcceptanceStatus === "2") {
      return (
        <div>
          <div>Revoke</div>
        </div>
      );
    }
    if (enumAcceptanceStatus === "3") {
      return (
        <div>
          <div>Reject</div>
        </div>
      );
    }
  };

  fromDateTemp = (props) => {
    const { startDate } = props;
    return (
      <div className="date-format-all-grid">
        <span>{startDate}</span>
      </div>
    );
  };

  durationTemp = (props) => {
    const { startDate, endDate } = props;
    return (
      <div className="date-format-all-grid" style={{ textAlign: "center" }}>
        <span>{`${startDate} to ${endDate}`}</span>
      </div>
    );
  };

  percSalary = (props) => {
    const { percSalaryShared } = props;
    return (
      <div className="date-format-all-grid">
        <span>{`${percSalaryShared} % of salary`}</span>
      </div>
    );
  };

  toDateTemp = (props) => {
    const { endDate } = props;
    return (
      <div className="date-format-all-grid">
        <span>{endDate}</span>
      </div>
    );
  };

  sharePerTemp = (props) => {
    const { percSalaryShared } = props;
    return (
      <div className="date-format-all-grid" style={{ textAlign: "center" }}>
        <span>{`${percSalaryShared} %`}</span>
      </div>
    );
  };

  sharedAmountTemp = (props) => {
    const { amountShared } = props;
    return (
      <div className="date-format-all-grid" style={{ textAlign: "center" }}>
        <span>{`${amountShared}`}</span>
      </div>
    );
  };

  shareWithDeprtment = (props) => {
    const { sharedWith, pnlHead } = props;
    return (
      <div className="date-format-all-grid">
        <span>{`${
          sharedWith !== "" && sharedWith !== null ? sharedWith : pnlHead
        }`}</span>
      </div>
    );
  };

  viewOKR = (props) => {
    const { sharedWithId } = props;
    if (sharedWithId === 0 || sharedWithId === null || sharedWithId === "") {
      return (
        <div className="date-format-all-grid" style={{ color: "red" }}>
          <span> Not done </span>
        </div>
      );
    }
    return (
      <div className="date-format-all-grid">
        <TooltipComponent
          position="BottomCenter"
          className="pointer-cursor"
          isSticky={false}
          content="View OKR"
          cssClass="customtooltip"
        >
          {/* <i class="fas fa-eye" style={{ fontSize: 15,padding:10 }}></i> */}
          <FontAwesomeIcon
            icon={faEye}
            className="pointer-cursor"
            style={{ fontSize: "20px", marginLeft: "10px" }}
            // onClick={(e) => this.navigateToProductiveAllocationScreens(props)}
          />
        </TooltipComponent>
      </div>
    );
  };

  navigateToProductiveAllocationScreens = (args) => {
    try {
      localStorage.setItem(
        "last_meeting_category",
        decodeString(localStorage.getItem("meeting_category"))
      );
      // eslint-disable-next-line
      this.props.addUpdateObjectveKpi({
        okrreloadStatus: "updated",
        employeeId: args.userId,
      });
      localStorage.setItem(
        "meeting_category",
        encodeString(OKR_PRODUCTIVE_ALLOCATION)
      );
      const path = `/okr?empId=${encrypt(args.userId)}`;
      this.setState({
        redirect: path,
        redirestCategary: OKR_PRODUCTIVE_ALLOCATION,
      });
    } catch (error) {
      // eslint-disable-next-line
            console.log("error", error);
    }
  };

  dataBound = () => {
    if (this.treegrid) {
      this.treegrid.autoFitColumns(["taskName"]);
    }
  };

  openNewRequest = () => {
    this.setState({ openNewRequest: true });
  };

  closeNewRequest = () => {
    this.setState({ openNewRequest: false });
    // eslint-disable-next-line
    this.props.setCostSharingDetails({
      employeeId: 0,
      sharedWith: 0,
      startDate: new Date(),
      endDate: new Date(),
      percSalaryShared: 0,
      amountShared: 0,
      currencyId: 0,
      description: "",
    });
  };

  submitNewRequest = () => {
    const {
      authUser,
      employeeId,
      sharedWith,
      startDate,
      endDate,
      percSalaryShared,
      amountShared,
      currencyId,
      description,
      totalCostSharing,
    } = this.props;
    document.getElementById("descriptionErr").innerHTML = "";
    document.getElementById("salaryErr").innerHTML = "";
    document.getElementById("currencyErr").innerHTML = "";
    const options = {
      rules: {
        employeeListErr: {
          required: [true, "Please select employee"],
        },
        sharewithErr: {
          required: [true, "Please select share with"],
        },
        // sharewithErr: {
        //     required: [true, 'Please select start month'],
        // },
        // sharewithErr: {
        //     required: [true, 'Please select end month'],
        // },
        salaryErr: {
          required: [true, "Please enter % of salary"],
        },
        amountErr: {
          number: [true, "Please enter valid amount"],
        },
      },
    };
    if (totalCostSharing + parseFloat(percSalaryShared) > 100) {
      document.getElementById("salaryErr").innerHTML =
        "You can not share more than 100 % of salary";
    }
    if (description === "") {
      document.getElementById("descriptionErr").innerHTML =
        "Please enter description";
    }
    const data = {
      employeeId,
      userId: parseInt(authUser),
      sharedWith,
      percSalaryShared,
      amountShared,
      currencyId,
      startDate: new Date(startDate) / 1000,
      endDate: new Date(endDate) / 1000,
      description,
      enumAcceptanceStatus: "0",
      acceptanceUserBy: "0",
      type: "AddCostSharing",
    };
    setTimeout(() => {
      const addFormObject = new FormValidator("#newRequestForm", options);
      if (addFormObject.validate()) {
        if (totalCostSharing + parseFloat(percSalaryShared) > 100) {
          document.getElementById("salaryErr").innerHTML =
            "You can not share more than 100 % of salary";
          return;
        }
        if (description === "") {
          document.getElementById("descriptionErr").innerHTML =
            "Please enter description";
          return;
        }
        if (amountShared !== "" && amountShared !== 0 && currencyId === 0) {
          document.getElementById("currencyErr").innerHTML =
            "Please select currency";
          return;
        }
        // eslint-disable-next-line
        this.props.addEditCostsharingNewRequest({ data });
        this.closeNewRequest();
      }
    }, 100);
  };

  render() {
    const { sharedResourceList } = this.props;
    const { result } = sharedResourceList;
    const {
      employeeMasterList,
      currencyMaster,
      shareWithMasterList,
      redirestCategary,
      redirect,
      height,
      gridHeight,
      openNewRequest,
    } = this.state;
    if (redirect !== null) {
      try {
        // eslint-disable-next-line
        this.props.setMeetingCategory(redirestCategary);
        return <Redirect to={redirect} />;
      } catch (error) {
        // eslint-disable-next-line
        console.log(error);
      }
    }
    let widthColumn = 0;
    let finalWidth = 0;
    // eslint-disable-next-line
    getGridColumn("teamshared").forEach((data) => {
      widthColumn += data.width;
    });
    if (widthColumn <= this.width) {
      finalWidth = widthColumn + 20;
    } else {
      finalWidth = this.width;
    }
    return (
      <div
        className="row"
        style={{
          height,
          overflowY: "hidden",
          overflowX: "hidden",
        }}
      >
        <NotificationContainer />
        <div>
          <div className="row-xs-12 row-sm-12 row-lg-12 row-md-12">
            <div
              style={{
                padding: "1.20rem 1.20rem",
              }}
            >
              <GridComponent
                className="grid-head-custome"
                id="overviewgrid"
                allowPaging
                dataSource={result}
                pageSettings={this.pageOptions}
                enableHover={false}
                enableVirtualization={false}
                rowHeight={38}
                height={gridHeight}
                ref={(g) => {
                  this.gridInstance = g;
                }}
                actionComplete={this.onComplete}
                load={this.onLoad}
                queryCellInfo={this.onQueryCellInfoPreJoining}
                dataBound={this.onDataBound}
                filterSettings={this.Filter}
                allowFiltering
                allowSorting
                allowSelection
                selectionSettings={this.select}
                allowTextWrap
                width={`${finalWidth}`}
              >
                <ColumnsDirective>
                  {getGridColumn("teamshared").map((item) => {
                    switch (item.colName) {
                      case ACTIONOKR:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.actionTemplate}
                            filter={this.check}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                          />
                        );
                      case OKRSTATUS:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.statusTemp}
                            filter={this.check}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                          />
                        );
                      case DESCRIPTIONOKR:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.deacriptionTemp}
                            filter={this.check}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                          />
                        );
                      case FROM:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.fromDateTemp}
                            filter={this.check}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                          />
                        );
                      case TO:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.toDateTemp}
                            filter={this.check}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                          />
                        );
                      case SHAREDPER:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.sharePerTemp}
                            filter={this.check}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                          />
                        );
                      case SHAREDAMT:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.sharedAmountTemp}
                            filter={this.check}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                          />
                        );
                      case OKR:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.viewOKR}
                            filter={this.check}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                            allowFiltering={false}
                          />
                        );
                      case SHAREDWITH:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.shareWithDeprtment}
                            filter={this.check}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                            allowFiltering={false}
                          />
                        );
                      default:
                        return (
                          <ColumnDirective
                            key={item.field}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                            filter={this.check}
                          />
                        );
                    }
                  })}
                </ColumnsDirective>
                <Inject
                  services={[Page, Filter, VirtualScroll, Sort, Resize]}
                />
              </GridComponent>
            </div>
          </div>
          <div className="control-section col-md-12">
            <div
              style={{
                margin: "-5px 15px 0px 15px",
                textAlign: "end",
                float: "right",
              }}
            >
              <ButtonComponent
                style={{
                  background: "#f94f5e",
                  color: "#fff",
                  border: "none",
                }}
                onClick={this.openNewRequest}
              >
                New Request
              </ButtonComponent>
            </div>
          </div>
        </div>
        <div style={{ width: "350px" }}>
          <Dialog
            open={openNewRequest}
          >
            <DialogContent>
              <NewRequestForm
                currencyMaster={currencyMaster}
                employeeMasterList={employeeMasterList}
                shareWithMasterList={shareWithMasterList}
              />
            </DialogContent>
            <DialogActions style={{ padding: "0px 15px 15px 15px" }}>
              <Button
                color="default"
                variant="contained"
                onClick={this.closeNewRequest}
              >
                {" "}
                Cancel{" "}
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={this.submitNewRequest}
              >
                {" "}
                Submit{" "}
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    );
  }
}
TeamSharedGrid.propTypes = {
  authUser: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  totalCostSharing: PropTypes.number,
  description: PropTypes.string,
  currencyId: PropTypes.number,
  amountShared: PropTypes.number,
  percSalaryShared: PropTypes.number,
  sharedWith: PropTypes.number,
  employeeId: PropTypes.number,
  endDate: PropTypes.instanceOf(Date),
  startDate: PropTypes.instanceOf(Date),
  sharedResourceList: PropTypes.arrayOf(PropTypes.object),
};
TeamSharedGrid.defaultProps = {
  authUser: "",
  totalCostSharing: 0,
  description: "",
  currencyId: 0,
  amountShared: 0,
  sharedWith: 0,
  employeeId: 0,
  endDate: new Date(),
  startDate: new Date(),
  sharedResourceList: {},
  percSalaryShared: 0,
};
const mapStateToProps = ({ auth, okrreducer }) => {
  const { authUser } = auth;
  const {
    employeeId,
    sharedWith,
    startDate,
    endDate,
    percSalaryShared,
    amountShared,
    currencyId,
    description,
    totalCostSharing,
  } = okrreducer;
  return {
    authUser,
    employeeId,
    sharedWith,
    startDate,
    endDate,
    percSalaryShared,
    amountShared,
    currencyId,
    description,
    totalCostSharing,
  };
};
export default connect(mapStateToProps, {
  addEditCostsharingNewRequest,
  setCostSharingDetails,
  addUpdateObjectveKpi,
  setMeetingCategory,
})(TeamSharedGrid);
