/**
 * Author: Vivek Khodade.
 * Created:06-Jun-2020
 * Last Modify:06-Jun-2020
 * Modified By:Vivek Khodade
 */

import axios from "axios";
import { urlConfig } from "../../config/config";

function errorHandler(reject, error) {
  // eslint-disable-next-line
  console.dir(error);
  reject("operation failed");
}

/**
 * Author:Vivek Khodade  *
 * @param {*} userId
 * @description get the productive allocation data
 */
function getProductiveAllocation(userId) {
  return new Promise(function onThen(resolve, reject) {
    axios
      .get(`${urlConfig.tmlcApiUrl}productiveallocation/${userId}`, {
        headers: {
          authorization: localStorage.getItem("jwtToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      // eslint-disable-next-line
      .then((response) => {
        if (response != null) {
          return resolve(response.data);
        }
      })
      .catch((error) => {
        errorHandler(reject, error.response.data.message);
        return null;
      });
  });
}
/**
 * Author:Vivek Khodade  *
 * @param {*} userId
 * @description get the productive allocation data
 */
function getUserWiseProductivityAllocation(
  userId,
  empId,
  type,
  userProdAllocId,
  currentFinancialYear
) {
  return new Promise(function onThen(resolve, reject) {
    axios
      .get(
        `${urlConfig.tmlcApiUrl}userproductiveallocation/${userId}/${empId}/${type}/${currentFinancialYear}/${userProdAllocId}`,
        {
          headers: {
            authorization: localStorage.getItem("jwtToken"),
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      // eslint-disable-next-line
      .then((getUserWiseProductivityAllocationResponse) => {
        if (getUserWiseProductivityAllocationResponse != null) {
          return resolve(getUserWiseProductivityAllocationResponse.data);
        }
      })
      .catch((error) => {
        errorHandler(reject, error.response.data.message);
        return null;
      });
  });

}
/**
 * Author: Prashant Waphare
 * @param {*} userId
 * @param {*} type
 * @description get the Shared Resource list
 */
function getSharedResourceList(userId, type) {
  return new Promise(function onThen(resolve, reject) {
    axios
      .get(`${urlConfig.tmlcApiUrl}okrgetcostsharinglist/${userId}/${type}`, {
        headers: {
          authorization: localStorage.getItem("jwtToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      // eslint-disable-next-line
      .then((getSharedResourceListResponse) => {
        if (getSharedResourceListResponse != null) {
          return resolve(getSharedResourceListResponse.data);
        }
      })
      .catch((error) => {
        errorHandler(reject, error.response.data.message);
        return null;
      });
  });
}
/**
 * Author: Vivek Khodade
 * @param {*} userId
 * @param {*} empId
 * @description get the Shared Resource list
 */
function getObjectiveDetails(userId, empId, type, searchType, filterByType) {
  let url = "";
  // objective/lib/44/2/715/all
  // lib=715
  // 0= all Approved.
  const state = type;
  if (type === "APPROVED") {
    url = `${urlConfig.tmlcApiUrl}objective/lib/${userId}/0/715/all`;
  } else if (type === "APPROVED_PENDING_ALL") {
    url = `${urlConfig.tmlcApiUrl}objective/emp/${userId}/${state}/${searchType}/${filterByType}/${empId}`;
  } else {
    url = `${urlConfig.tmlcApiUrl}objective/emp/${userId}/${state}/${searchType}/${filterByType}/${empId}`;
  }
  return new Promise(function onThen(resolve, reject) {
    axios
      .get(url, {
        headers: {
          authorization: localStorage.getItem("jwtToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      // eslint-disable-next-line
      .then((getObjectiveDetailsResponse) => {
        if (getObjectiveDetailsResponse != null) {
          return resolve(getObjectiveDetailsResponse.data);
        }
      })
      .catch((error) => {
        errorHandler(reject, error.response.data.message);
        return null;
      });
  });
}
/**
 * Author:Vivek Khodade  *
 * @param {*} userId
 * @description get the productive allocation data
 */
function getPendingForApproval(userId) {
  return new Promise(function onThen(resolve, reject) {
    axios
      .get(`${urlConfig.tmlcApiUrl}objective/pending/${userId}`, {
        headers: {
          authorization: localStorage.getItem("jwtToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      // eslint-disable-next-line
      .then((getPendingForApprovalResponse) => {
        if (getPendingForApprovalResponse != null) {
          return resolve(getPendingForApprovalResponse.data);
        }
      })
      .catch((error) => {
        errorHandler(reject, error.response.data.message);
        return null;
      });
  });
}
/**
 * Author: Prashant Waphare
 * @param {*} userId
 * @param {*} type
 * @description get All master List for OKR.
 */
function getOKRMaster(userId) {
  return new Promise(function onThen(resolve, reject) {
    axios
      .get(`${urlConfig.tmlcApiUrl}objective/master/${userId}`, {
        headers: {
          authorization: localStorage.getItem("jwtToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      // eslint-disable-next-line
      .then((getOKRMasterResponse) => {
        if (getOKRMasterResponse != null) {
          return resolve(getOKRMasterResponse.data);
        }
      })
      .catch((error) => {
        errorHandler(reject, error.response.data.message);
        return null;
      });
  });
}
/**
 * Author: Prashant Waphare
 * @param {*} userId
 * @param {*} type
 * @description get OKR KPI List.
 */
function getKPIList(userId, objType, type, selectedIds, all, OKRType, fyId) {
  let url = `${urlConfig.tmlcApiUrl}objective/lib/${userId}/${objType}/${type}/${selectedIds}/${fyId}/${encodeURIComponent(all)}`;
  return new Promise(function onThen(resolve, reject) {
    if (all === "") {
      url = `${urlConfig.tmlcApiUrl}objective/lib/${userId}/${objType}/${type}/${selectedIds}/${fyId}`;
    }
    if (OKRType !== "") {
      url = url + "?OKRType=" + OKRType
    }
    axios
      .get(url, {
        headers: {
          authorization: localStorage.getItem("jwtToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      // eslint-disable-next-line
      .then((getKPIListResponse) => {
        if (getKPIListResponse != null) {
          return resolve(getKPIListResponse.data);
        }
      })
      .catch((error) => {
        errorHandler(reject, error.response.data.message);
        return null;
      });
  });
}
/**
 * Author: Prashant Waphare
 * @param {*} userId
 * @param {*} type
 * @description get User Mapp OBJ KPI data List.
 */
function getUserMapObjectiveKpiList(userId, empId, objectiveType, fyid) {
  return new Promise(function onThen(resolve, reject) {
    axios
      .get(
        `${urlConfig.tmlcApiUrl}objective/user/${userId}/${empId}/${fyid}/${objectiveType}`,
        {
          headers: {
            authorization: localStorage.getItem("jwtToken"),
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      // eslint-disable-next-line
      .then((getUserMapObjectiveKpiListResponse) => {
        if (getUserMapObjectiveKpiListResponse != null) {
          return resolve(getUserMapObjectiveKpiListResponse.data);
        }
      })
      .catch((error) => {
        errorHandler(reject, error.response.data.message);
        return null;
      });
  });
}
/**
 * Author: Vivek Khodade
 * @param {*} userId
 * @description get suggested List.
 */
function getSuggestedObjectiveKpiList(userId) {
  return new Promise(function onThen(resolve, reject) {
    axios
      .get(`${urlConfig.tmlcApiUrl}objective/match/${userId}`, {
        headers: {
          authorization: localStorage.getItem("jwtToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      // eslint-disable-next-line
      .then((getSuggestedObjectiveKpiListResponse) => {
        if (getSuggestedObjectiveKpiListResponse != null) {
          return resolve(getSuggestedObjectiveKpiListResponse.data);
        }
      })
      .catch((error) => {
        errorHandler(reject, error.response.data.message);
        return null;
      });
  });
}

/**
 * Author: Suresh
 * @param {*} userId
 * @description get suggested List.
 */
function getOKRFrequency(type, userId, empId, freq, objectiveId, datesObj) {
  return new Promise(function onThen(resolve, reject) {
    const queryStr = `?fyId=${datesObj.fyId}&freq=${freq}` +
      (objectiveId !== "" ? `&objectiveId=${objectiveId}` : '') +
      (datesObj.startMonth !== null && datesObj.startMonth !== "" ? `&startMonth=${datesObj.startMonth}` : "") +
      (datesObj.endMonth !== null && datesObj.endMonth !== "" ? `&endMonth=${datesObj.endMonth}` : "")
      ;
    axios
      .get(`${urlConfig.tmlcApiUrl}okr/frequency/${type}/${userId}/${empId}${queryStr}`, {
        headers: {
          authorization: localStorage.getItem("jwtToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      // eslint-disable-next-line
      .then((getOKRFrequencyResponse) => {
        if (getOKRFrequencyResponse != null) {
          return resolve(getOKRFrequencyResponse.data);
        }
      })
      .catch((error) => {
        errorHandler(reject, error.response.data.message);
        return null;
      });
  });
}
/**
 * Author: Suresh
 * @param {*} empId
 * @param {*} fyId
 * @description get Performance Score.
 */
function getOKRPerformanceScore(empId, fyId) {
  return new Promise(function onThen(resolve, reject) {
    axios
      .get(`${urlConfig.tmlcApiUrl}okr/performance/${empId}/${fyId}`, {
        headers: {
          authorization: localStorage.getItem("jwtToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      // eslint-disable-next-line
      .then((getOKRFrequencyResponse) => {
        if (getOKRFrequencyResponse != null) {
          return resolve(getOKRFrequencyResponse.data);
        }
      })
      .catch((error) => {
        errorHandler(reject, error.response.data.message);
        return null;
      });
  });
}

function getOKRLockTMRatingMonth(empId, fy, type) {
  return new Promise(function onThen(resolve, reject) {
    axios
      .get(`${urlConfig.tmlcApiUrl}okrlockmonth/${empId}/${fy}/${type}`, {
        headers: {
          authorization: localStorage.getItem("jwtToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      // eslint-disable-next-line
      .then((getOKRLockTMRatingMonthResponse) => {
        if (getOKRLockTMRatingMonthResponse != null) {
          return resolve(getOKRLockTMRatingMonthResponse.data);
        }
      })
      .catch((error) => {
        errorHandler(reject, error.response.data.message);
        return null;
      });
  });
}

/**
 * @author  Sanjeev
 * @param {*} authUser 
 * @param {*} masterName 
 * @description common function to get all okr master data
 * @returns 
 */

function invokeGetReq(getUrl) {
  return new Promise(function onThen(resolve, reject) {
    axios
      .get(getUrl, {
        headers: {
          authorization: localStorage.getItem("jwtToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      // eslint-disable-next-line
      .then((response) => {
        if (response != null) {
          return resolve(response.data);
        }
      })
      .catch((error) => {
        errorHandler(reject, error.response.data.message);
        return null;
      });
  });
}

function invokePostReq(postUrl, data) {
  return new Promise(function onThen(resolve, reject) {
    axios
      .post(postUrl, data, {
        headers: {
          authorization: localStorage.getItem("jwtToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      // eslint-disable-next-line
      .then((response) => {
        if (response != null) {
          return resolve(response.data);
        }
      })
      .catch((error) => {
        errorHandler(reject, error.response.data.message);
        return null;
      });
  });
}

function getOKRCompilanceData(userId, empId, fyId, mId) {
  return new Promise(function onThen(resolve, reject) {
    axios
      .get(`${urlConfig.tmlcApiUrl}okrcompilancedata/${userId}/${empId}/${fyId}/${mId}`, {
        headers: {
          authorization: localStorage.getItem("jwtToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      // eslint-disable-next-line
      .then((getOKRCompilanceData) => {
        if (getOKRCompilanceData != null) {
          return resolve(getOKRCompilanceData.data);
        }
      })
      .catch((error) => {
        errorHandler(reject, error.response.data.message);
        return null;
      });
  });
}

function getOKRDashboardData(type, userId, empId, fyId, monthId) {  
  return new Promise(function onThen(resolve, reject) {
    axios
      .get(`${urlConfig.tmlcApiUrl}okrdashboard/${type}/${userId}/${empId}/${fyId}/${monthId}`, {
        headers: {
          authorization: localStorage.getItem("jwtToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      // eslint-disable-next-line
      .then((getXYAxisgridviewData) => {
        if (getXYAxisgridviewData != null) {
          return resolve(getXYAxisgridviewData.data);
        }
      })
      .catch((error) => {
        errorHandler(reject, error.response.data.message);
        return null;
      });
  });
}

function getOKRMaserData(authUser, okrMasterUid, masterItemId = 0, fyID = 0) {
  try {//NOSONAR
    let searParams = masterItemId !== 0 ? "?masterItemId=" + masterItemId : ""
    const getURL = `${urlConfig.tmlcApiUrl}master/${authUser}/${okrMasterUid}/${fyID}${searParams}`
    return /*NOSONAR*/ invokeGetReq(getURL)
  } catch (error) {

  }

}
/**
 * @author  Md Jahir Uddin
 * @param {*} authUser userId
 * @param {*} okrTypeIds okr typeIds to fetch
 * @description function to get all okr admin masters and their libraries
 * @returns <Promise>
 */
function getOKRAdminMasterData(okrTypeIds, type) {
  try {//NOSONAR
    let searParams = okrTypeIds
    const getURL = `${urlConfig.tmlcApiUrl}okradminmaster/${type}/${searParams}`
    return /*NOSONAR*/ invokeGetReq(getURL)
  } catch (error) {
    console.error(error)
  }
}

function getOKRFrequencyData(authUser, fyId, frequencyType) {
  try {//NOSONAR
    const getURL = `${urlConfig.tmlcApiUrl}frequencymaster/${authUser}/${fyId}/${frequencyType}`
    return /*NOSONAR*/ invokeGetReq(getURL)
  } catch (error) {

  }

}

function getAdminEditData() {
  try {//NOSONAR
    const postUrl = `${urlConfig.tmlcApiUrl}okradmincalculation`
    return /*NOSONAR*/ invokePostReq(postUrl)
  } catch (error) {

  }

}
function getAdmincalcuData(userId, fyId, month) {
  try {//NOSONAR
    const getURL = `${urlConfig.tmlcApiUrl}okradmincalculation/${userId}/${fyId}/${month}`
    return /*NOSONAR*/ invokeGetReq(getURL)
  } catch (error) {
  }
}

const okrServices = {
  getSharedResourceList,
  getProductiveAllocation,
  getUserWiseProductivityAllocation,
  getObjectiveDetails,
  getPendingForApproval,
  getKPIList,
  getOKRMaster,
  getUserMapObjectiveKpiList,
  getSuggestedObjectiveKpiList,
  getOKRFrequency,
  getOKRPerformanceScore,
  getOKRLockTMRatingMonth,
  getOKRCompilanceData,
  getOKRDashboardData,
  getOKRMaserData,
  getOKRFrequencyData,
  getOKRAdminMasterData,
  getAdminEditData,
  getAdmincalcuData
};

export default okrServices;
