import React from "react";
import { connect } from "react-redux";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Filter,
  Grid,
  Inject,
  VirtualScroll,
  Sort,
} from "@syncfusion/ej2-react-grids";
import { withStyles } from "@material-ui/core/styles";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { AutoCompleteComponent } from "@syncfusion/ej2-react-dropdowns";
import { Query } from "@syncfusion/ej2-data";
import PropTypes from "prop-types";
import { NotificationManager } from "react-notifications";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faSearchPlus, faCubes } from "@fortawesome/pro-light-svg-icons";
import { defMaxClientContact } from "../../config/config";
import { updateMeetingBookingState } from "../../redux/actions/meetingbookingaction";
import bdservices from "../../services/bdservices";
import { MainClass } from "../../mainclass";
import { getNotify, decodeString } from "../../app/other/commonfunction";
import {
  ERROR,
  PleaseEnteraValidBTBId,
  SalesClientMeet,
  OPSBTBClientMeet,
  OPSBTBDropdown, ClientMeetingsId
} from "../../assets/constants/constants";
import "./client.css";
import "../../app/containers/tmlc/tmlc.css";
library.add(faSearchPlus, faCubes);

const useStyles = (theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
});
let Sample = `
.e-grid .e-gridheader .e-sortfilter .e-fltr-icon .e-headercelldiv {
  margin: -19px -10px -35px 2px;
}
`
class ClientSearch extends MainClass {
  constructor(props) {
    super(props);
    this.state = {
      contacts: [],
      errorEnter3charactor: false,
    };

    this.getSelectedClient = this.getSelectedClient.bind(this);
    this.funGetContactData = this.funGetContactData.bind(this);
    this.funGetContactIds = this.funGetContactIds.bind(this);
    this.handleBTBIdChange = this.handleBTBIdChange.bind(this);
    this.handleContactSelection = this.handleContactSelection.bind(this);
    this.handleBTBFocusOut = this.handleBTBFocusOut.bind(this);
    this.onQueryCellInfoPreJoining = this.onQueryCellInfoPreJoining.bind(this);
    this.handleCellSelected = this.handleCellSelected.bind(this);

    this.grid = Grid;
    this.Filter = { type: "Menu" };
    this.check = { type: "CheckBox" };
    this.txtBTBIdref = React.createRef();
  }

  componentDidMount() {
    const {
      meetingId,
      contactsId,
      clientId,
      clientNm,
      clientmode,
    } = this.props;
    if (meetingId > 0) {
      if (contactsId.length > 0) {
        if (typeof this.searchClient !== "undefined") {
          delete this.searchClient.text;
          this.searchClient.text = clientNm;
        }
        if (clientmode === "MD") {
          this.funGetContactData(0, clientmode, "MD");
        } else {
          this.funGetContactData(clientId, clientmode, "BD");
        }
      }
    }
  }
  // eslint-disable-next-line
  handleKeyDown = (e) => { // NOSONAR
    const { meetingSubTypeId, meetingTypeId } = this.props;
    let mIClientId = 0;
    if (
      ClientMeetingsId.includes(meetingTypeId) &&
      !SalesClientMeet.includes(meetingSubTypeId)
    ) {
      mIClientId = this.props.clientId;
      if (
        this.txtBTBIdref.current.value === 0 ||
        this.txtBTBIdref.current.value === ""
      ) {
        NotificationManager.error("Please enter BTB ID.");
        return false;
      }
      if (mIClientId === 0) {
        (async () => {
          const response = Promise.resolve(
            bdservices.getValidateBTB(this.txtBTBIdref.current.value, 0, meetingSubTypeId, meetingTypeId)
          );
          // eslint-disable-next-line
          response.then((data) => {
            if (data !== null && data.Result !== "Not Valid") {
              this.props.updateMeetingBookingState({
                btbId: this.txtBTBIdref.current.value,

                clientId: parseInt(data.Result.split("$")[0]),
                clientNm: data.Result.split("$")[1],
                clientmode: "BD",
                contactsId: [],
              });
              // eslint-disable-next-line
              mIClientId = data.Result.split("$")[0];
            } else {
              this.props.updateMeetingBookingState({ btbId: "" });
              NotificationManager.error("Please enter valid BTB ID.");
              return false;
            }
          });
        })();
      }
    }
    if (e.text.length <= 2) {
      this.setState({
        errorEnter3charactor: true,
      });
    }
    if (e.text.length > 2) {
      let pId = 0;
      let pSearchTypes = "";

      if (this.props.contactsId.length > 0) {
        if (this.props.clientId > 0) {
          // BdSystem Client
          pId = this.props.clientId;
          pSearchTypes = "CL";
        } else {
          // eslint-disable-next-line
          pId = this.props.contactsId[0]; // Marketing Cntact
          pSearchTypes = "EMS";
        }
      } else if (
        ClientMeetingsId.includes(meetingTypeId)
      ) {
        if (this.props.clientId > 0) {
          // BdSystem Client
          pId = this.props.clientId;
          pSearchTypes = "CL";
        }
      }

      (async () => {
        const response = Promise.resolve(
          bdservices.getBDClients(e.text, pId, pSearchTypes, meetingSubTypeId, meetingTypeId)
        );
        response.then(async (data) => {
          if (data != null && data.length > 0) {
            let query = new Query();
            setTimeout(() => {
              // eslint-disable-next-line
              if (isNaN(e.text)) {
                query =
                  e.text !== ""
                    ? query.where("Name", "startswith", e.text, true)
                    : query;
              } else {
                query =
                  e.text !== ""
                    ? // eslint-disable-next-line
                    query.where("Id", "startswith", parseInt(e.text), true)
                    : query;
              }
            }, 500);
            e.updateData(data, query);
          } else this.setState({ clients: [] });
        });
      })();
      this.setState({
        errorEnter3charactor: false,
      });
    } else if (typeof e.text === "undefined" || e.text.length === 1) {
      if (
        decodeString(localStorage.getItem("user_type")) === "Ops" &&
        (this.props.btbId === 0 || this.props.btbId === "")
      ) {
        this.props.updateMeetingBookingState({
          clientId: 0,
          clientmode: "",
          meetingTitle: "",
          contactsId: [],
        });
      } else if (this.props.contactsId.length === 0) {
        this.props.updateMeetingBookingState({
          clientId: 0,
          clientmode: "",
          meetingTitle: "",
        });
      }
    }
  };

  componentWillReceiveProps(newProps) {
    if (newProps.meetingTypeNm !== this.props.meetingTypeNm) {
      this.handleBTBIdChange();
    }
  }

  getSelectedClient = async (e) => {
    const { meetingAgenda } = this.props;
    if (e != null && e.itemData.Id != null) {
      if (document.getElementById("clientError") !== null) {
        document.getElementById("clientError").innerHTML = "";
      }
      if (e.itemData.SearchType === "CNCT" || e.itemData.SearchType === "EMS") {
        const contactids = await this.props.contactsId;
        contactids.push(e.itemData.Id);
        this.props.updateMeetingBookingState({ contactsId: contactids });
      }
      this.props.updateMeetingBookingState({
        clientId: e.itemData.ClientId,
        clientmode: e.itemData.Mode,
        meetingTitle: `CM - ${e.itemData.Name}`,
        clientNm: e.itemData.Name,
        meetingAgenda: meetingAgenda === '' ? `CM - ${e.itemData.Name}` : meetingAgenda,
      });
      this.funGetContactData(
        e.itemData.Id,
        e.itemData.Mode,
        e.itemData.SearchType
      );
    } else if (this.props.contactsId.length === 0) {
      this.props.updateMeetingBookingState({
        clientId: 0,
        clientmode: "",
        meetingTitle: "",
        clientNm: "",
      });
    }
  };

  funGetContactData = (pClientId, pMode, type) => {
    const { meetingSubTypeId, meetingTypeId } = this.props;
    (async () => { // NOSONAR
      let contactids = "";
      contactids =
        this.props.contactsId.length > 0
          ? this.props.contactsId.toString()
          : "";
      const response = Promise.resolve(
        bdservices.getContacts(pClientId, pMode, type, contactids, meetingSubTypeId, meetingTypeId, this.props.btbId)
      );
      response.then((data) => {
        if (data != null && data.length > 0) {
          this.setState({ contacts: data });
          setTimeout(() => {
            const ele = document.getElementsByClassName("e-headercelldiv");
            for (let i in ele) {
              if (!isNaN(parseInt(i, 10))) {
                let index = parseInt(i, 10);
                switch (ele[index].innerText) {
                  case "Contact Id":
                    ele[index].classList.add("grid-center-header");
                    break;
                  case "Company Name":
                  case "Contact Name":
                  case "Job Title":
                    ele[index].classList.add("grid-left-header");
                    ele[index].classList.add("filter-css");
                    break;
                  default:

                }
              }
            }
          }, 1000);
        } else {
          this.setState({ contacts: [] });
        }
      });
    })();
  };
  /**
 * @function: onQueryCellInfoPreJoining
 * @description:Overwrite the css of grid comonent
 */
  onQueryCellInfoPreJoining = (args) => {
    const { cell } = args;
    cell.setAttribute(
      "style",
      "font-size:14px !important; color:#4B4A52 !important; text-align: left !important; padding-left:18px !important"
    );
  };
  funGetContactIds = () => {
    if (document.getElementById("contactError") !== null)
      document.getElementById("contactError").innerHTML = "";

    this.handleCellSelected()

    if (this.grid) {
      if (this.state.contacts.length > 0) {
        const selectedrecords = this.grid.getSelectedRecords();
        const ContactIds = [];
        selectedrecords.forEach((element) => {
          ContactIds.push(element.ContactId);
        });
        this.props.updateMeetingBookingState({ contactsId: ContactIds });
        if (ContactIds.length > defMaxClientContact) {
          if (document.getElementById("contactError") !== null)
            document.getElementById(
              "contactError"
            ).innerHTML = ` * Only maximum ${defMaxClientContact.toString()} contact(s) are allowed for selection.`;
        }
        return ContactIds.toString();
      }
    }
    if (this.props.contactsId.length > 0) {
      return this.props.contactsId.toString();
    }
    this.props.updateMeetingBookingState({ contactsId: [] });
    return "";
  };

  handleBTBIdChange = (event) => {
    const { meetingSubTypeId } = this.props;
    if (event !== undefined) {
      if (event.value <= 0) {
        getNotify(ERROR, PleaseEnteraValidBTBId);
        return;
      }
    }
    if (OPSBTBDropdown.includes(meetingSubTypeId)) {
      this.props.updateMeetingBookingState({
        contactsId: [],
        clientId: 0,
        clientNm: "",
        clientmode: "BD",
      });
      this.setState({ contacts: [] });
    }
  };

  handleContactSelection = () => {
    this.funGetContactIds();
  };

  handleCellSelected = () => {
    if (document.getElementById('contactgrid') !== null) {
      var ele = document.getElementById('contactgrid')
      if (!ele.classList.contains('e-grid'))
        ele.classList.add('e-grid')
    }
  }

  componentWillUnmount() {
    this.props.updateMeetingBookingState({
      btbId: "",
      contactsId: [],
      clientId: 0,
      clientNm: "",
      clientmode: "",
    });
  }
  // eslint-disable-next-line
  handleBTBFocusOut = () => { // NOSONAR
    try {
      const { meetingSubTypeId, meetingTypeId, meetingAgenda } = this.props
      let mIClientId;
      let mStrClientNm = "";
      if (SalesClientMeet.includes(this.props.meetingSubTypeId) || OPSBTBClientMeet.includes(this.props.meetingSubTypeId)) {
        if (OPSBTBClientMeet.includes(this.props.meetingSubTypeId)) {
          mIClientId = this.props.clientId;
          if (
            this.txtBTBIdref.current.value === 0 ||
            this.txtBTBIdref.current.value === ""
          ) {
            NotificationManager.error("Please enter BTB ID.");
            return false;
          }
          (async () => {
            const response = Promise.resolve(
              bdservices.getValidateBTB(
                this.txtBTBIdref.current.value,
                mIClientId,
                meetingSubTypeId,
                meetingTypeId
              )
            );
            // eslint-disable-next-line
            await response.then((data) => {
              if (data !== null && data.Result !== "Not Valid") {
                this.props.updateMeetingBookingState({
                  // eslint-disable-next-line
                  btbId: parseInt(this.txtBTBIdref.current.value).toString(),
                });

                // eslint-disable-next-line
                mStrClientNm = data.Result.split("$")[1];
                if (mIClientId !== data.Result.split("$")[0]) {
                  this.props.updateMeetingBookingState({
                    clientId: data.Result.split("$")[0],
                    clientNm: mStrClientNm,
                    meetingTitle: `CM - ${mStrClientNm}`,
                    meetingAgenda: meetingAgenda === '' ? `CM - ${mStrClientNm}` : meetingAgenda,
                    clientmode: "BD",
                    contactsId: [],
                  });
                  this.setState({ contacts: [] });
                }
                // eslint-disable-next-line
                mIClientId = data.Result.split("$")[0];
              } else {
                this.setState({ contacts: [] });
                this.props.updateMeetingBookingState({
                  btbId: "",
                  contactsId: [],
                  clientNm: "",
                  clientmode: "BD",
                });
                getNotify(ERROR, PleaseEnteraValidBTBId);
                return false;
              }
              if (mStrClientNm !== "") {
                setTimeout(() => {
                  if (this.searchClient !== undefined) {
                    this.searchClient.text = "";
                    this.searchClient.text = mStrClientNm;
                  }
                }, 500);
                this.funGetContactData(mIClientId, "BD");
              }
            });
          })();
          // }
        } else if (//decodeString(localStorage.getItem("user_type")) === "Sales" && 
          SalesClientMeet.includes(this.props.meetingSubTypeId)) {
          mIClientId = this.props.clientId;
          if (this.txtBTBIdref.current.value > 0 && mIClientId > 0) {
            (async () => {
              const response = Promise.resolve(
                bdservices.getValidateBTB(
                  this.txtBTBIdref.current.value,
                  mIClientId,
                  meetingSubTypeId,
                  meetingTypeId
                )
              );
              // eslint-disable-next-line
              await response.then((data) => {
                if (data !== null && data.Result !== "Not Valid") {
                  this.props.updateMeetingBookingState({
                    // eslint-disable-next-line
                    btbId: parseInt(this.txtBTBIdref.current.value).toString(),
                  });
                } else {
                  this.txtBTBIdref.current.value = "";
                  this.props.updateMeetingBookingState({ btbId: "" });
                  getNotify(ERROR, PleaseEnteraValidBTBId);
                  return false;
                }
              });
            })();
          }
        }
      }
    } catch (error) { }
  };

  noRecordsTemplate = () => {
    return this.state.errorEnter3charactor ? (
      <div>Please enter minimum 3 characters</div>
    ) : (
      <div>No record found</div>
    );
  };

  queryCellInfo = (args) => {
    const { cell } = args;
    if (args.column.index === 0) {
      cell.setAttribute(
        "style",
        "font-size:14px !important; color:#4B4A52 !important; text-align: left !important; padding: 0px 13px 0px 20px!important;t"
      );
    } else {
      cell.setAttribute(
        "style",
        "font-size:14px !important; color:#4B4A52 !important; text-align: left !important; "
      );
    }
    if (args.column.index === 4) {
      cell.setAttribute(
        "style",
        "font-size:14px !important; color:#4B4A52 !important; text-align: center !important; "
      );
    }
    this.handleCellSelected()
  };

  itemTemplate = (data) => {
    return (
      <div>
        <div className="ename"> {data.Name} </div>
        <div className="job"> {data.Optional} </div>
      </div>
    );
  };

  render() {
    const { classes, btbId, meetingSubTypeId, clientId } = this.props;
    const { contacts } = this.state
    return (
      <>
        <div className="pt-sm" style={{ clear: "both" }}>
          {OPSBTBClientMeet.includes(meetingSubTypeId) &&
            ( // Billable -  Client Meeting
              <div className="col-md-4">
                <div className="custom-margin custom-padding-5 material2">
                  <div className="col-md-12">
                    <div className="e-input-group e-float-icon-left">
                      <FontAwesomeIcon icon={faCubes} className="mt-sm fa-lg" />
                      <div className="e-input-in-wrap">
                        <div style={{ width: "100%", display: "grid" }}>
                          <TextBoxComponent
                            ref={this.txtBTBIdref}
                            type="number"
                            name="btbid"
                            id="btbid"
                            value={btbId}
                            required
                            placeholder="BTB ID *"
                            cssClass="e-outline"
                            floatLabelType="Auto"
                            onBlur={this.handleBTBFocusOut}
                            data-msg-containerid="btbError"
                          />
                          <div id="btbError" className="error-div" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          {
            (SalesClientMeet.includes(meetingSubTypeId)||OPSBTBClientMeet.includes(meetingSubTypeId)) && (
              // Piching
              <div className="col-md-6">
                <div className="custom-margin custom-padding-5 material2">
                  <div className="col-md-12">
                    <div className="e-input-group e-float-icon-left">
                      <FontAwesomeIcon
                        icon={faSearchPlus}
                        className="mt-sm fa-lg"
                      />
                      <div className="e-input-in-wrap">
                        <div id="template" style={{ width: "100%", display: "grid" }}>
                          <AutoCompleteComponent
                            id="search-client"
                            name="search-client"
                            ref={(autocomplete) => {
                              this.searchClient = autocomplete;
                            }}
                            cssClass="e-outline"
                            floatLabelType="Auto"
                            fields={{ value: "Name", Id: "Id" }}
                            popupHeight="250px"
                            placeholder="Search Group/Company/Contact/BD System(Contact Id/ Company Id) *"
                            filtering={this.handleKeyDown}
                            select={this.getSelectedClient}
                            data-msg-containerid="clientError"
                            noRecordsTemplate={this.noRecordsTemplate}
                            itemTemplate={this.itemTemplate}
                          />
                          <div id="clientError" className="error-div" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}

          {(SalesClientMeet.includes(meetingSubTypeId) && clientId > 0) && (
            <div className="col-md-3">
              <div className="custom-margin custom-padding-5 material2">
                <div className="col-md-12">
                  <div className="e-input-group e-float-icon-left">
                    <FontAwesomeIcon icon={faCubes} className="mt-sm fa-lg" />
                    <div className="e-input-in-wrap">
                      <div style={{ width: "100%", display: "grid" }}>
                        <TextBoxComponent
                          ref={this.txtBTBIdref}
                          type="number"
                          name="btbid"
                          id="btbid"
                          onChange={this.handleBTBIdChange}
                          value={this.props.btbId}
                          required
                          placeholder="BTB Id"
                          cssClass="e-outline"
                          floatLabelType="Auto"
                          onBlur={this.handleBTBFocusOut}
                          data-msg-containerid="btbError"
                          min={0}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        {(contacts.length > 0 &&
          ((SalesClientMeet.includes(meetingSubTypeId)) || (OPSBTBClientMeet.includes(meetingSubTypeId) && btbId !== "undefined" && btbId > 0))) &&
          // Billable -  Client Meeting
          (
            <div className="row">
              <div className="col-md-12">
                <style>{Sample}</style>
                <div
                  className="custom-margin custom-padding-5 material2"
                  style={{ marginLeft: 18 }}
                >
                  <div className="col-md-12">
                    <div style={{ width: "99.2%", display: "grid" }}>
                      <GridComponent
                        id="contactgrid"
                        dataSource={contacts}
                        rowSelected={this.handleContactSelection}
                        rowDeselected={this.handleContactSelection}
                        className={classes.formControl}
                        queryCellInfo={this.queryCellInfo}
                        cellSelected={this.handleCellSelected}

                        row
                        rowHeight={38}
                        height="150"
                        // eslint-disable-next-line
                        ref={(g) => (this.grid = g)}
                        filterSettings={this.Filter}
                        allowFiltering
                        allowSorting
                        allowSelection
                        data-msg-containerid="contactError"
                      >
                        <ColumnsDirective>
                          <ColumnDirective
                            type="checkbox"
                            field="checked"
                            allowSorting={false}
                            allowFiltering={false}
                            width="5"
                          />
                          <ColumnDirective
                            field="CompanyName"
                            headerText="Company Name"
                            width="30"
                          />
                          <ColumnDirective
                            field="ContactName"
                            headerText="Contact Name"
                            width="30"
                          />
                          <ColumnDirective
                            field="JobTitle"
                            headerText="Job Title"
                            width="44"
                          />
                          <ColumnDirective
                            field="ContactId"
                            headerText="Contact Id"
                            width="12"
                          />
                          <ColumnDirective
                            field=""
                            headerText=""
                            width="1"
                          />
                        </ColumnsDirective>
                        <Inject services={[Filter, VirtualScroll, Sort]} />
                      </GridComponent>
                      <div style={{ marginLeft: "8px" }} id="contactError" className="error-div" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

      </>

    );
  }
}

ClientSearch.propTypes = {
  classes: PropTypes.object.isRequired,
};

ClientSearch.defaultProps = {
  meetingTypeNm: "",
};

const mapStateToProps = ({ meetingbooking }) => {
  const {
    meetingId,
    btbId,
    clientId,
    contactsId,
    clientNm,
    clientmode,
    meetingSubTypeId,
    meetingTypeId,
    meetingAgenda
  } = meetingbooking;
  return { meetingId, btbId, clientId, contactsId, clientmode, clientNm, meetingSubTypeId, meetingTypeId, meetingAgenda };
};

export default connect(mapStateToProps, {
  updateMeetingBookingState,
})(withStyles(useStyles)(ClientSearch));
