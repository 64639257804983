import axios from "axios";
import { getBase64Encoded, getHelpDeskCred } from "../../app/other/commonfunction";
import { urlConfig } from "../../config/config";

function errorHandler(reject, error) {
    // eslint-disable-next-line
    console.dir(error);
    reject("operation failed");
}

/**
 * Author: Md Jahir Uddin
 * @description get ticket list
 * Modified By Vivek Khodade
 */
export function getTicketList(date, count = 300, offset = 0, userId = 0) {
    return new Promise(function onThen(resolve, reject) {
        let url = `${urlConfig.helpDeskUrl}api/Tickets?includeCustomFields=true${date ? '&dateFrom=' + date.dateFrom + '&dateTo=' + date.dateTo : ''}&count=${count}&offset=${offset}`
        if (userId > 0) {
            url += `&fromUserId=${userId}`;
        }
        axios
            .get(url, {
                headers: {
                    Authorization: "Basic " + getBase64Encoded(getHelpDeskCred()),
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
            // eslint-disable-next-line
            .then((response) => {
                if (response != null) {
                    return resolve(response.data);
                }
            })
            .catch((error) => {
                errorHandler(reject, error.response.data.message);
                return null;
            });
    });
}

/**
 * Author: Md Jahir Uddin
 * @description get ticket stats
 */
export function getTicketStats() {
    return new Promise(function onThen(resolve, reject) {
        axios
            .get(`${urlConfig.helpDeskUrl}api/Stats`, {
                headers: {
                    Authorization: "Basic " + getBase64Encoded(getHelpDeskCred()),
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
            // eslint-disable-next-line
            .then((response) => {  //NOSONAR
                if (response != null) {
                    return resolve(response.data);
                }
            })
            .catch((error) => {  //NOSONAR
                console.log("🚀 ~ file: helpdeskServices.js:59 ~ onThen ~ error:", error)
                errorHandler(reject, error);
                return null;
            });
    });
}

/**
 * Author: Md Jahir Uddin
 * @description get ticket details
 */
export function getTicketDetails({ ticketId }) {
    return new Promise(function onThen(resolve, reject) {
        axios
            .get(`${urlConfig.helpDeskUrl}api/ticket?id=${ticketId}`, {
                headers: {
                    Authorization: "Basic " + getBase64Encoded(getHelpDeskCred()),
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
            // eslint-disable-next-line
            .then((response) => { //NOSONAR
                if (response != null) {
                    return resolve(response?.data);
                }
            })
            .catch((error) => { //NOSONAR
                console.log("🚀 ~ file: helpdeskServices.js:59 ~ onThen ~ error:", error)
                errorHandler(reject, error);
                return null;
            });
    });
}

/**
 * Author: Md Jahir Uddin
 * @description get ticket history
 */
export function getTicketComments({ ticketId }) {
    return new Promise(function onThen(resolve, reject) {
        axios
            .get(`${urlConfig.helpDeskUrl}api/comments?id=${ticketId}`, {
                headers: {
                    Authorization: "Basic " + getBase64Encoded(getHelpDeskCred()),
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
            // eslint-disable-next-line
            .then((response) => { //NOSONAR
                if (response != null) {
                    return resolve(response?.data);
                }
            })
            .catch((error) => { //NOSONAR
                console.log("🚀 ~ file: helpdeskServices.js:59 ~ onThen ~ error:", error)
                errorHandler(reject, error);
                return null;
            });
    });
}

/**
 * Author: Md Jahir Uddin
 * @description get ticket custom fields
 */
export function getTicketCustomFields({ ticketId }) {
    return new Promise(function onThen(resolve, reject) {
        axios
            .get(`${urlConfig.helpDeskUrl}api/TicketCustomFields?id=${ticketId}`, {
                headers: {
                    Authorization: "Basic " + getBase64Encoded(getHelpDeskCred()),
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
            // eslint-disable-next-line
            .then((response) => { //NOSONAR
                if (response != null) {
                    return resolve(response?.data);
                }
            })
            .catch((error) => { //NOSONAR
                console.log("🚀 ~ file: helpdeskServices.js:59 ~ onThen ~ error:", error)
                errorHandler(reject, error);
                return null;
            });
    });
}
/**
 * Author: ML Vivek Khodade
 * @description get Categories and Sections Details.
 */
export function getCategoriesAndSections() {
    return new Promise(function onThen(resolve, reject) {
        axios
            .get(`${urlConfig.helpDeskUrl}api/categories`, {
                headers: {
                    Authorization: "Basic " + getBase64Encoded(getHelpDeskCred()),
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
            // eslint-disable-next-line
            .then((response) => { //NOSONAR
                if (response != null) {
                    return resolve(response?.data);
                }
            })
            .catch((error) => { //NOSONAR
                console.log("🚀 ~ file: helpdeskServices.js:59 ~ onThen ~ error:", error)
                errorHandler(reject, error);
                return null;
            });
    });
}
/**
* Author: ML Vivek Khodade
* @description get the Top tickets.
*/
export function getTopTickets() {
    return new Promise(function onThen(resolve, reject) {
        axios
            .get(
                `${urlConfig.tmlcApiUrl}helpdesk/topticket`,
                {
                    headers: {
                        authorization: localStorage.getItem("jwtToken"),
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                }
            )
            // eslint-disable-next-line
            .then((topTicket) => { //NOSONAR
                if (topTicket != null) {
                    return resolve(topTicket.data);
                }
            })
            .catch((error) => { //NOSONAR
                errorHandler(reject, error.response.data.message);
                return null;
            });
    });

}

/**
 * Author: Chandrashekhar Bhagat
 * @description get Sub Category List based on Category.
 */
export function getTicketSubCategories(categoryId) {
    return new Promise(function onThen(resolve, reject) {
        axios
            .get(`${urlConfig.helpDeskUrl}api/CustomFieldsForCategory?categoryId=${categoryId}`, {
                headers: {
                    Authorization: "Basic " + getBase64Encoded(getHelpDeskCred()),
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
            // eslint-disable-next-line
            .then((response) => { //NOSONAR
                if (response != null) {
                    return resolve(response?.data);
                }
            })
            .catch((error) => { //NOSONAR
                console.log("🚀 ~ file: helpdeskServices.js:228 ~ onThen ~ error:", error)
                errorHandler(reject, error);
                return null;
            });
    });
}

export function getIdeas( userId ) {
    return new Promise(function onThen(resolve, reject) {
        axios
            .get(
                `${urlConfig.tmlcApiUrl}helpdesk/idea/${userId}`,
                {
                    headers: {
                        authorization: localStorage.getItem("jwtToken"),
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                }
            )
            // eslint-disable-next-line
            .then((ideas) => { //NOSONAR
                if (ideas != null) {
                    return resolve(ideas.data);
                }
            })
            .catch((error) => { //NOSONAR
                errorHandler(reject, error.response.data.message);
                return null;
            });
    });

}
/**
* Author: ML Vivek Khodade
* @description get the sub-categories.
*/
export function getSubCategories(categoryId = 0, subCategoryId = 0) {
    return new Promise(function onThen(resolve, reject) {
        axios
            .get(
                `${urlConfig.tmlcApiUrl}helpdesk/subcategories/${categoryId}/${subCategoryId}`,
                {
                    headers: {
                        authorization: localStorage.getItem("jwtToken"),
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                }
            )
            // eslint-disable-next-line
            .then((topTicket) => { //NOSONAR
                if (topTicket != null) {
                    return resolve(topTicket.data);
                }
            })
            .catch((error) => { //NOSONAR
                errorHandler(reject, error.response.data.message);
                return null;
            });
    });

}
/**
* Author: ML Vivek Khodade
* @description Verify user is valid or not.
*/
export function getUserDetails(email) {
    return new Promise(function onThen(resolve, reject) {
        axios
            .get(`${urlConfig.helpDeskUrl}api/UserByEmail?email=${email}`, {
                headers: {
                    Authorization: "Basic " + getBase64Encoded(getHelpDeskCred()),
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
            // eslint-disable-next-line
            .then((response) => { //NOSONAR
                if (response != null) {
                    return resolve(response?.data);
                }
            })
            .catch((error) => { //NOSONAR
                console.log("🚀 ~ file: helpdeskServices.js:59 ~ onThen ~ error:", error)
                errorHandler(reject, error);
                return null;
            });
    });

}

