/**
 * Author: Vivek Khodade.
 * Created:08-May-2020
 * Last Modify:08-May-2020
 * Modified By:Vivek Khodade
 */
import React, { Component } from "react";
import NotificationContainer from "../../../../components/notification/notifycontainer";
import { Button } from '@material-ui/core';
import ConfirmationTabs from "../../../../components/confirmationtabs/confirmationtabs";

export default class Confirmation extends Component {
  constructor(props) {
    super(props);    
    this.state = {};
  }

  render() {
    return (
      <div>
        <div>
          {/* <EmployeeList /> */}
          <ConfirmationTabs />
          <NotificationContainer />
          <Button style={{ cursor: 'none', marginTop:'-21px',height:'1px' }} >.</Button>
        </div>
      </div>
    );
  }
}
