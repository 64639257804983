/**
 * @author ML Vivek Khodade
 * 
 */
export const ADD_NEW_SERVICE_REQUEST = 'add_new_service_request';
export const ADD_UPDATE_HELP_DESK_REDUX = 'add_update_help_desk_redux';
export const ADD_EDIT_SUB_CATEGORY = 'add_edit_subcategory';
export const HANDLE_HELPDESK_ADMIN_POPUP = 'handle_helpdesk_admin_popup';
export const HELPDESK_CREATE_USER = 'helpdesk_create_user';
export const HELPDESK_REDUX_UPDATE = 'helpdesk_redux_update';
export const ADD_TICKET_UPDATE  = 'add_ticket_update';
export const UPDATEHELPDESK_USER_INFO  = 'update_helpdesk_user_info';
export const ADD_NEW_IDEA = 'add_new_idea';
