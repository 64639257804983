import React, { useEffect, useState } from 'react'
import { FormValidator } from "@syncfusion/ej2-inputs";
import { TextBoxComponent, } from "@syncfusion/ej2-react-inputs";
import { EnterFolder, pleaseSetHierarchy } from '../../../../assets/constants/constants';

export default function Foldermanagepopup(props) {
  const [newFolderData, setnewFolderData] = useState({
    folderName: "",
    folderId: "",
    parentFolderId: "",
    parentFolderName: "",
    hierarchy:"",
  });
  useEffect(() => {
    const { newFolderData } = props
    let data
    if (props.type === 'Add') {
      data = {
        folderName: "",
        folderId: 0,
        parentFolderId: props.newFolderData.parentFolderId,
        hierarchy: props.newFolderData.hierarchy
      }
      setnewFolderData(data);

    }
    if (props.type === 'Edit') {

      const { folderName, folderId, parentFolderId, parentFolderName, hierarchy } = newFolderData;
        
      data = {
        folderName: folderName,
        folderId: folderId,
        parentFolderId: parentFolderId,
        parentFolderName: parentFolderName,
        hierarchy: hierarchy
      }
      setnewFolderData(data);
    }
    // eslint-disable-next-line
  }, [])

  const newFolderFunction = (event) => {
    let options = {}
    newFolderData.folderName = event.value;
    setnewFolderData(newFolderData);
    options = {
      rules: {
        folderName: {
          required: [true, EnterFolder]
        }
      },
    };

    setTimeout(() => {
      const addFormObject = new FormValidator("#folderForm", options);
      addFormObject.validate();
    }, 100);
  }


  const newHierarchyFunction = (event) => {
    try{
    let options = {}
    newFolderData.hierarchy = event.value;
    setnewFolderData(newFolderData);
    options = {
      rules: {
        hierarchy: {
          required: [true, pleaseSetHierarchy]
        }
      },
    };

    setTimeout(() => {
      const addFormObject = new FormValidator("#folderForm", options);
      if (addFormObject.validate()) {
        addFormObject.validate();
      }
    }, 100);
    } catch(error){
      console.log(error)
    }}




  props.parentClassFunction(newFolderData);

  return (
    <div>
      <form id="folderForm">
        <div className="form-row" style={{ maxWidth: '530px' }}>
          {props.type !== "Add" && props.newFolderData.folderId !== props.newFolderData.parentFolderId && (

            <div className="col-md-9">
              <TextBoxComponent
                id="folderName"
                placeholder="Parent Folder Name*"
                floatLabelType="Auto"
                width="500px"
                value={newFolderData.parentFolderName}
                data-msg-containerid="folderNameErr"
                name="folderName"
                cssClass="e-outline"
                readonly={true}
                onChange={(e) => newFolderFunction(e)}
                htmlAttributes={{
                  maxlength: 100,
                  databoundary: "scrollParent"
                }}
              />
              <div
                id="folderNameErr"
                className="e-input-group e-float-icon-left error-div"
              />
            </div>

          )}

          <div className="col-md-9">
            <TextBoxComponent
              id="folderName"
              placeholder="Folder Name*"
              floatLabelType="Auto"
              width="500px"
              value={newFolderData.folderName}
              data-msg-containerid="folderNameErr"
              name="folderName"
              cssClass="e-outline"
              onChange={(e) => newFolderFunction(e)}
              htmlAttributes={{
                maxlength: 100,
                databoundary: "scrollParent"
              }}
            />
            <div
              id="folderNameErr"
              className="e-input-group e-float-icon-left error-div"
            />
          </div>

          <div className="col-md-9">
            <TextBoxComponent
              id="hierarchy"
              placeholder="Hierarchy*"
              floatLabelType="Auto"
              width="500px"
              value={newFolderData.hierarchy}
              data-msg-containerid="hierarchyerr"
              name="hierarchy"
              cssClass="e-outline"
              onChange={(e) => newHierarchyFunction(e)}
              htmlAttributes={{
                maxlength: 100,
                databoundary: "scrollParent"
              }}
            />
            <div
              id="hierarchyerr"
              className="e-input-group e-float-icon-left error-div"
            />
          </div>

        </div>
      </form>
    </div>
  );
}
