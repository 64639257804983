/**
 * Author: Mukesh.
 * Created:11-Jan-2023
 * Last Modify:11-Jan-2023
 * Modified By:Mukesh
 */

import React, { useEffect, useState } from "react";
import './style.css';
import * as go from "gojs";
import { ReactDiagram } from "gojs-react";

const Piloting = (props) => { 
  const [showPloting, setshowPloting] = useState(true)
  const showHide = () => {
    setshowPloting(false)
    props.onSelectPlot(false)
  }
  useEffect(()=>{
    const $ = go.GraphObject.make;
      let myOverview =
      $(go.Overview, "myOverviewDiv",  // the HTML DIV element for the Overview
        { observed: props.plotingObj, contentAlignment: go.Spot.Center });
  },[])
  if (showPloting) {
    return (
      <div  >       
        <div className="plotin-layout" id="myOverviewDiv"></div>
      </div>
    );

  } else {
    return (<div></div>)
  }

}
export default Piloting