import React from 'react';
import { connect } from 'react-redux';
import { ComboBoxComponent } from "@syncfusion/ej2-react-dropdowns";
import { addUpdateOkrDashboardRedux } from '../../redux/actions/okrdashboardaction/okrdashboardaction';
import {decodeString} from '../../app/other/commonfunction';
function SelfRatingTabView(props) {
  const { showMonths, currentFinancialYearId, fyId } = props;
  const onChangedInput = (type, event) => {//nosonar
    switch (type) {
      case 'financialYear':
        if (event.itemData !== null) {
          props.addUpdateOkrDashboardRedux({fyId:event.itemData.Id, dashboardStatus:'updated', monthlyOkrMonthStatus:'updated',graphStatus:'updated'})
          
        } else {
          props.addUpdateOkrDashboardRedux({fyId:null})
        }
        break;
    }
  }


  return (
    <div className='col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12'>
      {
        !showMonths ?
          <div className='col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12'>
            <div className='col-lg-6 col-md-6 col-sm-6 col-xs-6' style={{paddingLeft:0}}>
              <div className='col-4 col-lg-4 col-md-4 col-sm-4 col-xs-4 ' style={{ fontSize: 14 }}>
                {
                  props.finacialYearData.length > 0 &&
                <div>
                  <ComboBoxComponent
                    floatLabelType="Auto"
                    fields={{ value: "Id", text: "Name" }}
                    dataSource={props.finacialYearData}
                    change={(e) => onChangedInput("financialYear", e)}
                    name={`fyIdrError`}
                    id={`cmbfyId`}
                    data-msg-containerid={`fyIdrError`}
                    cssClass="e-outline"
                    placeholder="Financial Year  *"
                    value={props.fyId}
                    />
                  <div
                    id="fyIdrError"
                    className="e-input-group e-float-icon-left error-div"
                    />
                </div>
                  }
              </div>
              <div className='col-6 col-lg-6 col-md-6 col-sm-6 col-xs-6  legend-names' style={{ fontSize: 16, paddingTop: ".4rem"  }}>
                {/* style={{ paddingTop: ".5rem" }} */}
               |  &nbsp; &nbsp; Name :  &nbsp;{ decodeString(localStorage.getItem("user_nm"))} - { currentFinancialYearId === fyId ? "Current" : "Past"} Rating
              </div>
            </div>
          </div>
          :
          <>
            <div className='col-7 col-lg-7 col-md-7 col-sm-4 col-xs-4 dashboard-team-rating-top-sec' style={{ marginRight: 10, }}>
              <div className='col-5 col-lg-5 col-md-5 col-sm-5 col-xs-5'>
                <div>
                 
                </div>
              </div>
              <div className='col-3 col-lg-3 col-md-3 col-sm-3 col-xs-3'>
                  
              </div>
            </div>
          </>
      }
    </div>
  )
}


const mapStateToProps = ({ auth, meetingmaster, okrdashboardreducer }) => {
  const { authUser } = auth;
  const { fyId } = okrdashboardreducer;
  const { finacialYearData, currentFinancialYearId } = meetingmaster;
  return {
    authUser, finacialYearData, fyId, currentFinancialYearId 
  }
};
export default connect(mapStateToProps, { addUpdateOkrDashboardRedux })(
  SelfRatingTabView
);
