// Author @ Amit Kumar
// Component for OrgAdmin PopUp

import React, { Component } from "react";
import { ComboBoxComponent } from "@syncfusion/ej2-react-dropdowns";
import { FormValidator } from "@syncfusion/ej2-inputs";
import {
  pleaseEnterLayerName,
  pleaseSetHierarchy,
} from "../../../../assets/constants/constants";

import { SwitchComponent } from '@syncfusion/ej2-react-buttons';
import AdminServices from "../../../../services/adminservices";
class Layermappingpopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      result: [],
      businessUnitData: [],
      subBusinessUnitData: [],
      practiceAreaData: [],
      layerData: [],
      hierarchyData: [],
      layerMappingParentData: [],
      practiceAreaId: null,
      practiceAreaName: "",
      layerName: "",
      hierarchy: null,
      parentLayerId: null,
      maxHierarchy: "",
      layerId: "",
      isParenting: false
    };
  }

  onChangeLayerMapping(e, type) {
    let options = {};
    switch (type) {
      case "Layer":
        this.setState({
          layerId: e.itemData === null ? 0 : e.itemData.Id,
        });
        options = {
          rules: {
            layerErr: {
              required: [true, pleaseEnterLayerName],
            },
          },
        };
        this.getAdmiLayerData(e.itemData.Id)
        break;
      case "parentLayerId":
        this.setState({
          parentLayerId: e.value,
        });
        
        break;
      case "Hierarchy":
        this.setState({
          hierarchy: e.value,
        });
        options = {
          rules: {
            hierarchyErr: {
              required: [true, pleaseSetHierarchy],
            },
          },
        };
        break;
      case "isParenting":
        this.setState({
          isParenting: e.checked
        });
        e.checked && this.setState({
          parentLayerId: null
        });
        break;
      default:
        break;
    }
    setTimeout(() => {
      const addFormObject = new FormValidator("#layerMappingForm", options);
      addFormObject.validate();
    }, 100);
    const {
      businessFunctionUnitId,
      subBusinessFunctionUnitId,
      practiceAreaId,
      practiceAreaName,
    } = this.props.layerMappingData;
    const { hierarchy, parentLayerId, layerName, layerId } = this.state;
    let data = {
      businessFunctionUnitId: businessFunctionUnitId,
      subBusinessFunctionUnitId: subBusinessFunctionUnitId,
      practiceAreaName: practiceAreaName,
      practiceAreaId: practiceAreaId,
      hierarchy: hierarchy,
      parentLayerId: parentLayerId,
      layerId: layerId,
      layerName: layerName,
    };
    this.props.parentComponentfunction(data);
  }

  componentDidMount() {

    this.setStateData();
  }

  setStateData() {
    const {
      businessFunctionUnitId,
      hierarchy,
      layerId,
      layerName,
      practiceAreaId,
      subBusinessFunctionUnitId,
      parentLayerId,
    } = this.props.layerMappingData;
    const { layerMappingMasterData } = this.props
    const { maxHierarchy } = this.props;
    let hierarchyPrev = hierarchy === null ? 0 : hierarchy.toString();
    try {
      const BusinessUnit = layerMappingMasterData?.filter((item) => {
        return item.Type === "businessUnitFunction";
      });
      const SubBusinessUnit = layerMappingMasterData?.filter((item) => {
        return item.Type === "subBusinessUnitFunction";
      });
      const Layer = layerMappingMasterData?.filter((item) => {
        return item.Type === "layer";
      });

      const PracticeArea = layerMappingMasterData?.filter((item) => {
        return item.Type === "practiceArea";
      });
      let hierarchyDS = [];
      for (let i = 1; i <= maxHierarchy; i++) {
        hierarchyDS.push({ Id: i, Name: i.toString() });
      }
      
      this.setState({
        businessUnitData: BusinessUnit,
        subBusinessUnitData: SubBusinessUnit,
        hierarchyData: hierarchyDS,
        layerData: Layer,
        practiceAreaData: PracticeArea,
        businessFunctionUnitId: businessFunctionUnitId,
        subBusinessFunctionUnitId: subBusinessFunctionUnitId,
        practiceAreaId: practiceAreaId,
        layerName: layerName,
        layerId: layerId,
        hierarchy: hierarchyPrev,
        parentLayerId: Number(parentLayerId),
        isParenting: parentLayerId == null ? true : false
      });
      this.getAdmiLayerData(Number(layerId));
    } catch (error) {
      console.error(error);
    }
    const {
      practiceAreaName,
    } = this.props.layerMappingData;
    let data = {
      businessFunctionUnitId: businessFunctionUnitId,
      subBusinessFunctionUnitId: subBusinessFunctionUnitId,
      practiceAreaName: practiceAreaName,
      practiceAreaId: practiceAreaId,
      hierarchy: hierarchy,
      layerId: layerId,
      layerName: layerName,
      parentLayerId: Number(parentLayerId),
    };
    this.props.parentComponentfunction(data);
  }
  getAdmiLayerData = async (layerId) => {
    try {
      const result = await AdminServices.getAdminOrgChartGridData("parentLayerMapping", this.props.layerMappingData.practiceAreaId);
      if (result !== null) {
        this.setState({
          layerMappingParentData: result.result.filter((data) => data.layerId !== Number(layerId)),
          isLoading: false,
        });
      }
    } catch (error) {
      // eslint-disable-next-line
      console.log(error);
    }
  };
  render() {
    const {
      businessUnitData,
      subBusinessUnitData,
      subBusinessFunctionUnitId,
      businessFunctionUnitId,
      // layerName,
      hierarchyData,
      practiceAreaData,
      practiceAreaId,
      hierarchy,
      parentLayerId,
      isParenting,
      layerData,
      layerId
    } = this.state;

    return (

      <div>
        <form id="layerMappingForm">
          <div class="form-row">
            <div className="form-group col-md-4">
              {businessUnitData.length > 0 && <ComboBoxComponent
                id="EmpType"
                dataSource={businessUnitData}
                fields={{ text: "Name", value: "Id" }}
                value={businessFunctionUnitId}
                data-msg-containerid="emptypeErr"
                name="emptypeErr"
                allowFiltering
                readonly={true}
                floatLabelType="Auto"
                cssClass="e-outline"
                placeholder="Business Unit / Group Function "
              />}
            </div>
            <div
              className="form-group col-md-4"
            >
              {subBusinessUnitData.length > 0 && <ComboBoxComponent
                id="EmpType"
                dataSource={subBusinessUnitData}
                fields={{ text: "Name", value: "Id" }}
                value={subBusinessFunctionUnitId}
                data-msg-containerid="emptypeErr"
                name="emptypeErr"
                allowFiltering
                readonly={true}
                allowCustom={false}
                floatLabelType="Auto"
                cssClass="e-outline"
                placeholder="Sub Business Unit / Function"
              />}
            </div>
            <div className="form-group col-md-4">
              {practiceAreaData.length > 0 && <ComboBoxComponent
                id="EmpType"
                dataSource={practiceAreaData}
                fields={{ text: "Name", value: "Id" }}
                value={parseInt(practiceAreaId, 10)}
                data-msg-containerid="emptypeErr"
                name="emptypeErr"
                allowFiltering
                readonly={true}
                allowCustom={false}
                floatLabelType="Auto"
                cssClass="e-outline"
                placeholder="Practice Area / Sales Unit / Sub Function"
              />}
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col-md-6" style={{ paddingTop: "16px", paddingBottom: '0px' }}>
              {layerData.length > 0 && <ComboBoxComponent
                id="LayerMappingData"
                dataSource={layerData}
                fields={{ text: "Name", value: "Id" }}
                value={parseInt(layerId, 10)}
                data-msg-containerid="layerErr"
                name="layerErr"
                allowFiltering
                allowCustom={false}
                floatLabelType="Auto"
                cssClass="e-outline"
                placeholder="Layer *"
                change={(e) => this.onChangeLayerMapping(e, "Layer")}
              />}
              <div
                id="layerErr"
                className="e-input-group e-float-icon-left error-div"
              />
            </div>

            <div className="form-group col-md-6" style={{ paddingTop: "16px" }}>
              {hierarchyData.length > 0 && <ComboBoxComponent
                id="EmpType"
                dataSource={hierarchyData}
                fields={{ text: "Name", value: "Name" }}
                value={hierarchy}
                data-msg-containerid="hierarchyErr"
                name="hierarchyErr"
                allowFiltering
                allowCustom={false}
                floatLabelType="Auto"
                cssClass="e-outline"
                placeholder="Hierarchy *"
                change={(e) => this.onChangeLayerMapping(e, "Hierarchy")}
              />}
              <div
                id="hierarchyErr"
                className="e-input-group e-float-icon-left error-div"
              />
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-md-6">
              <div style={{ marginRight: 10, color: 'grey' }}>Is Parent:</div>
              <div className="atc-switch-heading-start">
                <div className=" atc-switch-inline " style={{ marginTop: 5 }}>
                  <div className="mr-md">No</div>
                  <div>
                    <SwitchComponent
                      cssClass="atc-switch-height"
                      change={(e) => this.onChangeLayerMapping(e, "isParenting")}
                      checked={isParenting}

                    />
                  </div>
                  <div className="ml-md">Yes</div>
                </div>
              </div>
            </div>
            <div className="form-group col-md-6" >
              {!isParenting && this.state.layerMappingParentData.length > 0 && <ComboBoxComponent
                id="parentLayerId"
                dataSource={this.state.layerMappingParentData}
                fields={{ text: "layerName", value: "parentLayerMappingId" }}
                value={parentLayerId}
                data-msg-containerid="parentLayerIdErr"
                name="parentLayerIdErr"
                allowFiltering
                allowCustom={false}
                floatLabelType="Auto"
                cssClass="e-outline"
                placeholder="Parent Layer"
                change={(e) => this.onChangeLayerMapping(e, "parentLayerId")}
              />}
              <div
                id="parentLayerIdErr"
                className="e-input-group e-float-icon-left error-div"
              />
            </div>
          </div>
        </form>
      </div>
    );
  }
}
export default Layermappingpopup;
