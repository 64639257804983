import React, { useEffect, useState } from 'react'
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import lnaservice from '../../../../services/lna/lnaservice';
import { connect } from 'react-redux';

const isOneTwentyFiveScale = window.innerHeight < 747;
const isThirteenHundred = window.innerHeight < 650;
const isOneTwentyFiveInSixteenHundred = window.innerHeight < 603;
const isOneTwentyFiveScaleBookmarkOn = window.innerHeight < 715;
const Attendencebreakup = (props) => {
    const { teamId, startMonthMonthlyReport } = props;
    const [attendenceCountData, setAttendenceCountData] = useState([]) // attendence/leave type count data
    const itemsPerPage = 7;
    const [currentPage, setCurrentPage] = useState(1);
    const maxPage = Math.ceil(attendenceCountData.length / itemsPerPage);
    const getClassName = () => {
        if (window.innerHeight === 619) {
            return 'attendence768'
        }
        if (window.innerHeight === 654) {
            return 'attendence768'
        }
        if (window.innerHeight < 610) {
            return 'attendenceNameSmall577'
        }
        else if (isOneTwentyFiveInSixteenHundred) {
            return 'attendenceNameSmall'
        }
        else if (isThirteenHundred) {
            return 'attendenceNameSmall'
        }
        else if (isOneTwentyFiveScale) {
            return 'attendanceisOneTwentyFiveScale'
        }
        else if (isOneTwentyFiveScaleBookmarkOn) {
            return
        }

        else if (window.innerHeight < 900) {
            return 'attendence789'
        }
        else {
            return 'attendenceName'
        }
    }

    const getRoundCss = () => {
        if (window.innerHeight === 619) {
            return 'roundShape768'
        }
        if (window.innerHeight === 654) {
            return 'roundShape654'
        }
        if ((window.innerHeight < 731) && (window.innerHeight > 625)) {
            return 'roundShape698'
        }
        else if (window.innerHeight === 625) {
            return 'roundShapeSmall'
        }
        else if (window.innerHeight < 625) {
            return 'roundShapeSmall577'
        }
        else if (window.innerHeight < 900) {
            return 'roundShape789'
        }
        else {
            return 'roundShape'
        }
    }
    useEffect(() => {
        if (startMonthMonthlyReport && teamId) {
            getAttendenceCount() // get attendence/leave count for that respective month
        }
    }, [startMonthMonthlyReport, teamId]);
    useEffect(() => {
        if (attendenceCountData) {
            // Set the CSS variable on a DOM element
            const root = document.documentElement;
            let dynamicWidth = 0

            if (attendenceCountData?.length <= 7) {
                dynamicWidth = 4
            }
            if (attendenceCountData?.length > 8) {
                dynamicWidth = 2
            }
            if (attendenceCountData?.length < 7 && window.innerWidth < 1400) {
                dynamicWidth = 4
            }
            if (attendenceCountData?.length < 6 && window.innerWidth < 1377) {
                dynamicWidth = 3
            }
            root.style.setProperty('--dynWidth', (dynamicWidth + "rem"));
        }
    }, [attendenceCountData])
    const getAttendenceCount = async () => {
        if (startMonthMonthlyReport && teamId) {
            const AttendenceCountData = await lnaservice.getWeeklyReportData(teamId, 'ATTENDENCECOUNT', startMonthMonthlyReport && startMonthMonthlyReport.split(' ')[0]);
            if (AttendenceCountData.Result) {
                setAttendenceCountData(AttendenceCountData.Result)
            }
        }
    }
    const truncateName = (name) => {
        let maxLength = 25
        if (name !== undefined && name.length <= maxLength) return name;
        return name !== undefined && name.substring(0, maxLength - 4) + '...';
    }
    const handleNextClick = () => {
        if (currentPage < maxPage) {
            setCurrentPage(currentPage + 1);
        }
    };

    const handlePrevClick = () => {
        if (currentPage > 1) {
            setCurrentPage(currentPage - 1);
        }
    };
    const renderItems = () => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;

        return attendenceCountData
            .slice(startIndex, endIndex)
            .map((item) => (
                <div key={item.var_attendance_name} >
                    {/* Your item rendering code here */}
                    <div style={{ display: "flex", flexDirection: "row", width: '100%', justifyContent: 'left' }}>
                        <>
                            <div className={getRoundCss()} style={{ display: "flex", flexDirection: "row", justifyContent: 'center', alignItems: 'center', backgroundColor: item.colorCode }}>{item.count}</div>

                            <TooltipComponent position="BottomCenter" className="pointer-cursor" isSticky={false} content={item.var_attendance_name} cssClass='customtooltip'>
                                <div className={getClassName()} style={{ dynWidth: 0 }}>{truncateName(item.var_attendance_name)}</div>
                            </TooltipComponent>
                        </>

                    </div>
                </div>
            ));
    };
    return (
        <>
            <div className="col-lg-1 col-md-1 col-sm-1 col-xs-1 flex-container " style={{ paddingTop: 7 }}>

                {currentPage === 1 ?
                    <div className={`col-sm-2 col-md-2 col-lg-2  flex-pagination `} >
                        <div style={{ marginTop: 7, color: 'gray',cursor:'default'  }}>&lt;</div>
                    </div>
                    :
                    <TooltipComponent position="BottomCenter" className="pointer-cursor" isSticky={false} content={'Show Previous'} cssClass='customtooltip'>
                        <div className={`col-sm-2 col-md-2 col-lg-2 flex-pagination`} onClick={handlePrevClick} >
                            <div style={{ marginTop: 7, cursor: 'pointer', color: 'black' }}>&lt;</div>
                        </div>
                    </TooltipComponent>}

            </div>


            <div className="col-lg-10 col-md-10 col-sm-10 col-xs-10 flex-container">
                {renderItems()}
            </div>

            <div className="col-lg-1 col-md-1 col-sm-1 col-xs-1 flex-container" style={{ paddingTop: 7 }}>

                {currentPage === maxPage ?
                    <div className={`col-sm-2 col-md-2 col-lg-2  flex-pagination`} >
                        <div style={{ marginTop: 7, color: 'gray',cursor:'default' }}>&gt;</div>
                    </div>
                    : <TooltipComponent position="BottomCenter" className="pointer-cursor" isSticky={false} content={'Show Next'} cssClass='customtooltip'>
                        <div className={`col-sm-2 col-md-2 col-lg-2 flex-pagination `} onClick={handleNextClick}>
                            <div style={{ marginTop: 7, cursor: 'pointer', color: 'black' }}>&gt;</div>
                        </div>
                    </TooltipComponent>
                }
            </div>
        </>
    )
}

const mapStateToProps = ({ meetingcategory, lnareducer, auth }) => {
    const { authUser } = auth;
    const { categoryName } = meetingcategory;
    const { applyForData, startMonth, startMonthMonthlyReport, weekOrMonth } = lnareducer;
    return {
        authUser, categoryName, applyForData, startMonth, startMonthMonthlyReport, weekOrMonth
    }
}

export default connect(mapStateToProps, {})(Attendencebreakup)