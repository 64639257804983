import React, { useEffect, useState } from 'react'
import { setMeetingCategory } from "../../../../redux/actions/meetingcategoryaction";
import { connect } from 'react-redux';
import { updateLnAData } from "../../../../redux/actions/lna/lnaaction";
import { VIEWDAILY, VIEWMONTHLY, VIEWYEARLY } from '../../../../redux/constant/meetingcategoryconstant';

const ReportTabs = (props) => {

  const { setMeetingCategory, isLoading, resetFilters } = props;

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [clickFrom, setClickFrom] = useState('WEEKLY REPORT');

  const openTab = (tabId, tabName) => {
    setClickFrom(tabName);
    setMeetingCategory(tabId);
    if (tabName === "WEEKLY REPORT") {
      props.updateLnAData({
        viewClicked: true,
      });
    }
  };

  useEffect(() => {
    resetFilters();
  }, [])




  return (
    <>
      <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12"  >
        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12" style={{ background: '#FFF', paddingBottom: 16 }}>
          <div className="tab row-xs-11 row-sm-11 row-lg-11 row-md-11">
            <>
              <button
                id="active-team-member-tab"
                type="button"
                style={{ cursor: isLoading ? 'default' : 'pointer' }}
                disabled={isLoading}
                className={clickFrom === "WEEKLY REPORT" ? `col-lg-4 col-md-4 col-sm-4 onboardingtab onboarding-tab active` : `col-lg-4 col-md-4 col-sm-4 onboardingtab onboarding-tab`}
                onClick={(e) => openTab(VIEWDAILY, "WEEKLY REPORT")}
              >
                <b>WEEKLY REPORT</b>{" "}
              </button>
              <button
                type="button"
                style={{ cursor: isLoading ? 'default' : 'pointer' }}
                disabled={isLoading}
                className={clickFrom === "MONTHLY REPORT" ? `col-lg-4 col-md-4 col-sm-4 onboardingtab onboarding-tab active` : `col-lg-4 col-md-4 col-sm-4 onboardingtab onboarding-tab`}
                onClick={(e) => openTab(VIEWMONTHLY, "MONTHLY REPORT")}
              >
                <b>MONTHLY REPORT</b>
              </button>
              <button
                type="button"
                style={{ cursor: isLoading ? 'default' : 'pointer' }}
                disabled={isLoading}
                className={clickFrom === "YEARLY REPORT" ? `col-lg-4 col-md-4 col-sm-4 onboardingtab onboarding-tab active` : `col-lg-4 col-md-4 col-sm-4 onboardingtab onboarding-tab`}
                onClick={(e) => openTab(VIEWYEARLY, "YEARLY REPORT")}
              >
                <b>YEARLY REPORT</b>
              </button>
            </>
          </div>
        </div>

      </div>
    </>

  )
}

const mapStateToProps = ({ meetingcategory, lnareducer }) => {

  const { categoryName } = meetingcategory;
  const { viewClicked, isLoading } = lnareducer;


  return {
    categoryName,
    viewClicked,
    isLoading
  }
};

export default connect(mapStateToProps, {
  setMeetingCategory, updateLnAData
})(ReportTabs);
