import React from "react";
// import Button from '@material-ui/core/Button';


const ExportData = (props) => {
  const { monthlyView, swapweeklyoffview, exportData } = props;

  return (
    <div className="exportContainer">
      <div className="exportHeading" style={{ marginTop: 20, marginBottom: 10, marginLeft: 20 }}>
        {monthlyView || swapweeklyoffview
          ? "Export Descripancy Data"
          : "Export Data"}
      </div>
      <div className="exportText"
        style={{
          marginBottom: 20,
          marginLeft: 20,
          display: "flex",
          alignItems: "center",
          cursor:'pointer',
        }}
        onClick={exportData}
        onKeyDown={(event) => {if (event.key === 'Enter' || event.key === ' ') { // Check if Enter or Spacebar is pressed
          exportData()
        }}}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="23"
          viewBox="0 0 24 23"
          fill="none"
          style={{marginRight:5,cursor:'pointer'}}
          
        >
          
          <path
            d="M13.5 17.866C13.5 18.139 13.275 18.3623 13 18.3623H5C4.725 18.3623 4.5 18.139 4.5 17.866V5.95533C4.5 5.68238 4.725 5.45905 5 5.45905H10V7.94045C10 8.48946 10.4469 8.93301 11 8.93301H13.5V11.9107H15V8.76242C15 8.23512 14.7906 7.72953 14.4156 7.35732L11.5844 4.55024C11.2094 4.17803 10.7031 3.97021 10.1719 3.97021H5C3.89688 3.97021 3 4.86042 3 5.95533V17.866C3 18.961 3.89688 19.8512 5 19.8512H13C14.1031 19.8512 15 18.961 15 17.866V15.3846H13.5V17.866ZM18.2812 10.639C17.9875 10.3474 17.5125 10.3474 17.2219 10.639C16.9313 10.9305 16.9281 11.402 17.2219 11.6905L18.4406 12.9001H9.75C9.33437 12.9001 9 13.232 9 13.6446C9 14.0571 9.33437 14.389 9.75 14.389H18.4406L17.2219 15.5987C16.9281 15.8902 16.9281 16.3617 17.2219 16.6502C17.5156 16.9386 17.9906 16.9417 18.2812 16.6502L20.7812 14.1688C21.075 13.8772 21.075 13.4057 20.7812 13.1173L18.2812 10.6359V10.639Z"
            fill="#F94F5E"
          />
        </svg>
       Export Descripancy Data
      </div>
    </div>
  );
};

export default ExportData;
