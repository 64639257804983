import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  DetailRow,
  Inject,
  Page,
  Filter,
} from "@syncfusion/ej2-react-grids";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AutoCompleteComponent } from "@syncfusion/ej2-react-dropdowns";
import { Query } from "@syncfusion/ej2-data";
import { Redirect } from "react-router-dom";
import {
  faEye,
  faBellOn,
  faEnvelopeOpenText,
  faExternalLinkSquareAlt
} from "@fortawesome/pro-light-svg-icons";
import { Dialog } from "@material-ui/core";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { connect } from "react-redux";
import { urlConfig,DateFilterformat } from "../../../config/config";
import {
  DialogContent,
  DialogActions,
  DialogTitle,
} from "../../customdialog/customdialog";
import { getDisplayDateOnly, encrypt, getNotify} from "../../../app/other/commonfunction";
import { generateConfirmationLetter } from "../../../redux/actions/entryexit/confirmationaction";
import { setMeetingCategory } from "../../../redux/actions/meetingcategoryaction";
import { EMPLOYEE_PROFILE } from "../../../redux/constant/meetingcategoryconstant";
import EmployeeNameTemplate from "./employeenametemplate";
import {
  getGridColumn,
  EMPLOYEE_NAME,
  CONFIRMED_ON,
  EMPLOYEE_STATUS,
  EMPLOYEE_ACTION,
  DATE_OF_JOINING,
  CONFIRM_PREVIEW,
  DUE_CONFIRMED_ON,
  DUE_CONFIRM_REASON
} from "../../../app/other/gridcolumns";
import { pleaseEnterAppropriateComment, pleaseSelectAppropriateSingnatureAthority, SUCCESS, ERROR } from '../../../assets/constants/constants';
import ConfirmationServices from "../../../services/entryexit/confirmationServices";

class Confirmed extends Component {
  constructor(props) {
    super(props);
    this.navigateToEmployeeProfile = this.navigateToEmployeeProfile.bind(this);
    this.employeeNameTemplate = this.employeeNameTemplate.bind(this);
    const { data } = this.props;
    this.state = {
      employeeData: data,
      changedData: false,
      showPopUp: false,
      popupHeader: "",
      popupType: "",
      reason: "",
      confirmationDate: new Date(),
      dateOfJoing: null,
      selectedEmpData: null,
      redirect: null,
      hrUserId: '',
      height:
        (window.innerHeight ||
          document.documentElement.clientHeight ||
          document.body.clientHeight) - 188,
      gridHeight: (window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight) - 188
    };
    this.width =
      (window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth) - 100;

    this.Filter = {
      type: "Menu",
    };
    this.check = {
      type: "CheckBox",
    };
    this.handleDateChange = this.handleDateChange.bind(this);
    this.openPopup = this.openPopup.bind(this);
    this.closePopupDialog = this.closePopupDialog.bind(this);
    this.onQueryCellInfoPreJoining = this.onQueryCellInfoPreJoining.bind(this);
  }
  // eslint-disable-next-line
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { changedData } = this.state;
    this.setState({
      employeeData: nextProps.data,
      changedData: !changedData,
    });
  }
  /**
  * Calculate & Update state of new dimensions
  */
  updateDimensions() {
    const { changedData } = this.state;
    let update_width = window.innerWidth - 100;
    let update_height = window.innerHeight - 140;
    let gridHeight = window.innerHeight - 250;
    this.setState({ width: update_width, height: update_height, gridHeight: gridHeight, changedData: !changedData });
  }
  /**
   * shouldComponentUpdate
   * @param {*} nextProps
   * @param {*} nextState
   * This funcion is manage the rerender of this component using change data state.
   */
  shouldComponentUpdate(nextProps, nextState) {
    const { changedData } = this.state;
    if (changedData !== nextState.changedData) {
      return true;
    }
    return false;
  }

  /**
   * @function: onQueryCellInfoPreJoining
   * @description:Overwrite the css of grid comonent
   */
  onQueryCellInfoPreJoining = (args) => {
    const { cell } = args;
    cell.setAttribute(
      "style",
      "font-size:14px !important; color:#4B4A52 !important; text-align: left !important; padding-left:18px !important"
    );
  };

  /**
   * Function Name: dateFormat
   * Description: To format the Date
   */
  dateFormatDOJ = (props) => {
    return (
      <div
      // style={{ textAlign: "center" }}
      >
        <span>{getDisplayDateOnly(new Date(props.JoiningDate))}</span>
      </div>
    );
  };

  /**
   * Function Name: dateFormat
   * Description: To format the Date
   */
  dateFormatDDC = (props) => {
    return (
      <div
      // style={{ textAlign: "center" }}
      >
        {props.ProbationExtendedDate === null ? (
          <span>{getDisplayDateOnly(new Date(props.DueConfirmDate))}</span>
        ) : (
            <span>
              {getDisplayDateOnly(new Date(props.ProbationExtendedDate))}
            </span>
          )}
      </div>
    );
  };

  /**
   * Function Name: dateFormat
   * Description: To format the Date
   */
  dateFormatDCM = (props) => {
    return (
      <div
      // style={{ textAlign: "center" }}
      >
        <span>{getDisplayDateOnly(new Date(props.ConfirmDate))}</span>
      </div>
    );
  };

  /**
   * @author:Vivek Khodade
   * @function:statusTemplate
   * @description:Status column template code.
   */
  statusTemplate = (props) => {
    // Admin template document status
    if (props.Status === "Confirm") {
      return (
        <div
          style={{ borderLeft: "3px solid green", padding: "7px 0px 8px 5px" }}
        >
          <span id="">{props.Status}</span>
          <span id="" />
          <br />
        </div>
      );
    }
    if (props.Status === "Probation Period Extended") {
      return (
        <div
          style={{ borderLeft: "3px solid gray", padding: "7px 0px 8px 5px" }}
        >
          <span id="">{props.Status}</span>
          <span id="" />
          <br />
        </div>
      );
    }
    if (props.Status === "Probation") {
      return (
        <div
          style={{
            borderLeft: "3px solid #f68104",
            padding: "7px 0px 8px 5px",
          }}
        >
          <span id="">{props.Status}</span>
          <span id="" />
          <br />
        </div>
      );
    }
    return false;
  };

  /**
   * @author Vivek Khodade
   * @description open the popup
   */
  openPopup = (type, props) => {
    const { changedData } = this.state;
    if (type === "generate") {
      this.setState({
        popupHeader: "Generate the Confirmation Letter",
        showPopUp: true,
        popupType: type,
        reason: "",
        selectedEmpData: props,
        confirmationDate: new Date(props.DueConfirmDate),
        dateOfJoing: new Date(props.JoiningDate),
        changedData: !changedData,
      });
    }
    if (type === "extend") {
      this.setState({
        popupHeader: "Extend the Confirmation Period",
        showPopUp: true,
        popupType: type,
        reason: "",
        selectedEmpData: props,
        confirmationDate: props.ProbationExtendedDate ? new Date(props.ProbationExtendedDate) : new Date(props.DueConfirmDate),
        dateOfJoing: new Date(props.JoiningDate),
        changedData: !changedData,
      });
    }
  };

  /**
   * @author Vivek Khodade
   * @description close the popup
   */
  closePopupDialog = () => {
    const { showPopUp, changedData } = this.state;
    this.setState({
      popupHeader: "",
      showPopUp: !showPopUp,
      popupType: "",
      reason: "",
      confirmationDate: null,
      dateOfJoing: null,
      selectedEmpData: null,
      hrUserId: '',
      changedData: !changedData,
    });
  };

  /**
   * @author Vivek Khodade
   * @description Generate the confirmation letter selected employee.
   */
  actionGenerateConfirmationLetter = () => {
    try {
      const { authUser } = this.props;
      const { selectedEmpData, reason, confirmationDate, hrUserId } = this.state;

      const data = {
        type: "Confirm",
        dtDate: Date.parse(new Date(confirmationDate)) / 1000,
        comments: reason,
        empId: selectedEmpData.EmployeeId,
        userId: authUser,
        hrUserId: hrUserId
      };
      document.getElementById('signatureAthority').innerHTML = ""
      document.getElementById('confrmcommentError').innerHTML = ""
      if (hrUserId === '') {
        document.getElementById('signatureAthority').innerHTML = pleaseSelectAppropriateSingnatureAthority
        return;
      }
      if (reason === "") {
        document.getElementById('confrmcommentError').innerHTML = pleaseEnterAppropriateComment
        return;
      }
      // eslint-disable-next-line
      this.props.generateConfirmationLetter(data);
      this.closePopupDialog();
    } catch (error) {
      // eslint-disable-next-line
      console.log("error", error);
    }
  };

  /**
   * @author Vivek Khodade
   * @description Extend the confirmation period selected employee.
   */
  actionExtendProbationPeriod = () => {
    const { selectedEmpData, reason, confirmationDate } = this.state;
    const { authUser } = this.props;
    try {
      // document.getElementById('signatureAthority').innerHTML = ""
      document.getElementById('confrmcommentError').innerHTML = ""
      if (reason === "") {
        document.getElementById('confrmcommentError').innerHTML = pleaseEnterAppropriateComment
        return;
      }
      const data = {
        type: "Probation",
        dtDate: Date.parse(new Date(confirmationDate)) / 1000,
        comments: reason,
        empId: selectedEmpData.EmployeeId,
        userId: authUser,
        hrUserId: 0
      };
      this.props.generateConfirmationLetter(data);
      this.closePopupDialog();
    } catch (error) {
      // eslint-disable-next-line
      console.log("error", error);
    }
  };

  /**
   * @author Vinayak Jadhav
   * @argument props
   * @description Notify email send for selected employee manager.
   */
  actionNotifyManager = async (props) => {
    const { authUser } = this.props;
    let mailResult = await ConfirmationServices.notifyManagerForConfirmationDue(authUser, props);
    if (mailResult) getNotify(SUCCESS, "Mail Notification Sent Successfully");
    else getNotify(ERROR, "Error While Sending Mail Notification");
  };

  /**
   * @author Vivek Khodade
   * @argument props
   * @description Perview the confirmation letter
   */
  previewTemplate = (props) => {
    const { filePath } = props;
    return (
      <div className="col-12" style={{ textAlign: "center" }}>
        <TooltipComponent
          closeOn="Click"
          content='Preview'
          position="BottomCenter"
          isSticky={false}
          cssClass='customtooltip'
        >
          {/* eslint-disable-next-line */}
          <a target='_blank' href={filePath !== null ? `${urlConfig.tmlcApiUrl + filePath}` : ''} download><FontAwesomeIcon className='action-icon' icon={faEye} /></a>
        </TooltipComponent>
      </div>
    );
  };

  /**
   * @author Vivek Khodade
   * @argument props
   * @description UI of Acton column section
   */
  actionTemplate = (props) => {
    return (
      <div className="col-12">
        <div className="col-4">
          <TooltipComponent
            closeOn="Click"
            content="Generate Confirmation Letter"
            position="BottomCenter"
            isSticky={false}
            className="pointer-cursor"
            cssClass='customtooltip'>
            <FontAwesomeIcon
              // title="Generate Confirmation Letter"
              className="action-icon"
              icon={faEnvelopeOpenText}
              onClick={() => this.openPopup("generate", props)}
            />
          </TooltipComponent>
        </div>
        <div className="col-4">
          <TooltipComponent
            closeOn="Click"
            content="Extend Probation Period"
            position="BottomCenter"
            isSticky={false}
            className="pointer-cursor"
            cssClass='customtooltip'>
            <FontAwesomeIcon
              // title="Extend Probation Period"
              className="action-icon"
              icon={faExternalLinkSquareAlt}
              onClick={() => this.openPopup("extend", props)}
            />
          </TooltipComponent>
        </div>
        {/* <div className='col-3'>
            <FontAwesomeIcon title='Preview' className='action-icon' icon={faEye}
                          onClick={() => this.actionPreviewLetter()}
            />
          </div> */}
        <div className="col-4">
          <TooltipComponent
            closeOn="Click"
            content="Notify Manager"
            position="BottomCenter"
            isSticky={false}
            className="pointer-cursor"
            cssClass='customtooltip'>
            <FontAwesomeIcon
              // title="Notify Manager"
              className="action-icon"
              icon={faBellOn}
              onClick={() => this.actionNotifyManager(props)}
            />
          </TooltipComponent>
        </div>
      </div>
    );
  };

  componentDidMount = () => {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
    setTimeout(() => {
      const ele = document.getElementsByClassName("e-headercelldiv");
      // eslint-disable-next-line
      for(let i in ele) {
        if(!isNaN(parseInt(i, 10))){
          let index = parseInt(i, 10);
          if(ele[index].innerText !== CONFIRM_PREVIEW && ele[index].innerText !== EMPLOYEE_ACTION ){
            ele[index].classList.add("grid-left-header");
          }else{
            ele[index].classList.add("grid-center-header");
          }          
        }
      }
    }, 1000);
  };

  /**
   * @author Vivek Khodadde
   * @description navigate to employee page
   */
  navigateToEmployeeProfile = async (args) => {
    try {
      const path = `/onboard?empId=${encrypt(args.EmployeeId)}&id=${encrypt(EMPLOYEE_PROFILE)}`;
      return window.open(path, '_blank')
    } catch (error) {
      // eslint-disable-next-line
      console.log("error", error);
    }
  };

  handleDateChange(e) {
    const { changedData } = this.state;
    this.setState({
      confirmationDate: e,
      changedData: !changedData,
    });
  }

  /**
   * Function Name:candidteNameTemplate
   * @param {* candidate names text} props
   */
  employeeNameTemplate(props) {
    let newProps = props;
    return (
      <EmployeeNameTemplate
        props={newProps}
        data="test"
        navigateToEmployeeProfile={this.navigateToEmployeeProfile}
      />
    );
  }
  handleFilteringByName = (e) => {
    const { hrMasterList } = this.props;
    const newData = [];
    hrMasterList.forEach((eachD) => {
      newData.push(eachD);
    });
    if (newData != null && newData.length > 0) {
      let query = new Query();
      query =
        e.text !== "" ? query.where("Name", "contains", e.text, true) : query;
      e.updateData(newData, query);
    }
    // }
  };
  render() { // NOSONAR
    const {
      redirect,
      employeeData,
      showPopUp,
      popupType,
      popupHeader,
      confirmationDate,
      dateOfJoing
    } = this.state;
    const { hrMemberList } = this.props;
    const { typeid } = this.props;
    if (redirect !== null) {
      return <Redirect to={redirect} />;
    }
    var widthColumn = 0;
    var widthColumnConfirm;
    var finalWidth;
    var finalWidthConfirm = 0;
    // eslint-disable-next-line 
    getGridColumn("Due Confirm").forEach((data) => {
      widthColumn = widthColumn + 15 + data.width;
    });
    if (widthColumn <= this.width) {
      finalWidth = widthColumn;
    } else {
      finalWidth = this.width
    }
    // eslint-disable-next-line 
    getGridColumn("Confirm").forEach((data) => {
      widthColumnConfirm = widthColumnConfirm + 15 + data.width;
    });
    if (widthColumnConfirm <= this.width) {
      finalWidthConfirm = widthColumnConfirm;
    } else {
      finalWidthConfirm = this.width
    }
    return (
      <div>
        {employeeData !== null && typeid === 1 ? (
          <div
            className="control-section"
            style={{
              overflowY: "hidden",
              overflowX: 'hidden',
              height: this.state.height
            }}
          >
            <GridComponent
              ref={(gridid) => {
                this.gridObj = gridid;
              }}
              filterSettings={this.Filter}
              allowFiltering
              allowPaging
              allowSelection
              height={this.state.gridHeight
              }
              queryCellInfo={this.onQueryCellInfoPreJoining}
              pageSettings={{
                pageSize:
                  (window.innerHeight ||
                    document.documentElement.clientHeight ||
                    document.body.clientHeight) > 767
                    ? 12
                    : 6,
                pageCount: 5
              }}
              // pageSettings={this.pageSize}
              dataSource={employeeData}
              // width="auto"
              allowTextWrap={true}
              width={`${finalWidth}`}
            >
              <ColumnsDirective>
                {getGridColumn("Due Confirm").map((item) => {
                  switch (item.colName) {
                    case EMPLOYEE_NAME:
                      return (
                        <ColumnDirective
                          key={item.field}
                          template={this.employeeNameTemplate}
                          textAlign={`${item.align} !important`}
                          field={item.field}
                          headerText={item.colName}
                          width={item.width}
                          filter={this.Filter}
                        />
                      );
                    case DATE_OF_JOINING:
                      return (
                        <ColumnDirective
                          key={item.field}
                          template={this.dateFormatDOJ}
                          filter={this.check}
                          textAlign={`${item.align} !important`}
                          field={item.field}
                          headerText={item.colName}
                          width={item.width}
                          format={DateFilterformat}
                        />
                      );
                    case DUE_CONFIRMED_ON:
                      return (
                        <ColumnDirective
                          key={item.field}
                          template={this.dateFormatDDC}
                          filter={this.check}
                          textAlign={`${item.align} !important`}
                          field={item.field}
                          headerText={item.colName}
                          width={item.width}
                          format={DateFilterformat}
                        />
                      );
                    case EMPLOYEE_STATUS:
                      return (
                        <ColumnDirective
                          key={item.field}
                          template={this.statusTemplate}
                          filter={this.check}
                          textAlign={`${item.align} !important`}
                          field={item.field}
                          headerText={item.colName}
                          width={item.width}
                        />
                      );
                    case EMPLOYEE_ACTION:
                      return (
                        <ColumnDirective
                          key={item.field}
                          template={this.actionTemplate}
                          allowFiltering={false}
                          textAlign={`${item.align} !important`}
                          field={item.field}
                          headerText={item.colName}
                          width={item.width}
                        />
                      );
                    case DUE_CONFIRM_REASON:
                      return (
                        <ColumnDirective
                          key={item.field}
                          textAlign={`${item.align} !important`}
                          field={item.field}
                          headerText={item.colName}
                          width={item.width}
                          allowFiltering={false}
                        />
                      );
                    default:
                      return (
                        <ColumnDirective
                          key={item.field}
                          textAlign={`${item.align} !important`}
                          field={item.field}
                          headerText={item.colName}
                          width={item.width}
                          filter={this.check}
                        />
                      );
                  }
                })}
              </ColumnsDirective>
              <Inject services={[Page, Filter, DetailRow]} />
            </GridComponent>
          </div>
        ) : (
            <div
              className="control-section"
              style={{
                overflowY: "hidden",
                overflowX: 'hidden',
                // height: (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight)/1.27
                height: this.state.height
                // (window.innerHeight ||
                //   document.documentElement.clientHeight ||
                //   document.body.clientHeight) > 767
                //   ? (window.innerHeight ||
                //     document.documentElement.clientHeight ||
                //     document.body.clientHeight) / 1.17
                //   : (window.innerHeight ||
                //     document.documentElement.clientHeight ||
                //     document.body.clientHeight) / 1.27,
              }}
            >
              <GridComponent
                allowPaging
                allowFiltering
                filterSettings={this.Filter}
                // allowSelection={true}
                height={this.state.gridHeight
                  // (window.innerHeight ||
                  //   document.documentElement.clientHeight ||
                  //   document.body.clientHeight) > 767
                  //   ? (window.innerHeight ||
                  //     document.documentElement.clientHeight ||
                  //     document.body.clientHeight) / 1.4
                  //   : (window.innerHeight ||
                  //     document.documentElement.clientHeight ||
                  //     document.body.clientHeight) / 1.6
                }
                // height={(window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight)/1.5}
                // height='78vh'
                // height='400px'
                // selectionSettings={{ type: 'Single'}}
                queryCellInfo={this.onQueryCellInfoPreJoining}
                pageSettings={{
                  pageSize:
                    (window.innerHeight ||
                      document.documentElement.clientHeight ||
                      document.body.clientHeight) > 767
                      ? 12
                      : 6,
                  pageCount: 5
                }}
                // pageSettings={{pageSize:7}}
                // pageSettings={this.pageSize}
                dataSource={employeeData}
                // width="auto"
                allowTextWrap={true}
                width={`${finalWidthConfirm}`}
              >
                <ColumnsDirective>
                  {getGridColumn("Confirm").map((item) => {
                    switch (item.colName) {
                      case EMPLOYEE_NAME:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.employeeNameTemplate}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                            filter={this.Filter}
                          />
                        );
                      case DATE_OF_JOINING:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.dateFormatDOJ}
                            filter={this.check}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                            format={DateFilterformat}
                          />
                        );
                      case CONFIRMED_ON:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.dateFormatDCM}
                            filter={this.check}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                            format={DateFilterformat}
                          />
                        );
                      // case BAND:
                      //   return (
                      //     <ColumnDirective
                      //       key={item.field}
                      //       filter={this.check}
                      //       textAlign={`${item.align} !important`}
                      //       field={item.field}
                      //       headerText={item.colName}
                      //       width={item.width}
                      //       format={DateFilterformat}
                      //     />
                      //   );
                      case CONFIRM_PREVIEW:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.previewTemplate}
                            filter={false}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                            allowFiltering={false}
                          />
                        );
                      default:
                        return (
                          <ColumnDirective
                            key={item.field}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                            filter={this.check}
                          />
                        );
                    }
                  })}
                </ColumnsDirective>
                <Inject services={[Page, DetailRow, Filter]} />
              </GridComponent>
            </div>
          )}
        <div>
          <Dialog open={showPopUp}>
            <DialogTitle> {popupHeader}
            </DialogTitle>
            <DialogContent style={{ marginTop: -10, maxWidth: 500, minWidth: 500 }}

            >
              <div className="row">

                <div className="col-xs-12 col-sm-12 col-lg-6 col-md-6">
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      style={{ width: "100%" }}
                      inputVariant="outlined"
                      margin="normal"
                      id="confirmation date"
                      name="Confirmation Date"
                      format="dd-MMM-yyyy"
                      // value={this.state.popupType==='generate' ? new Date():new Date(this.state.confirmationDate)}
                      value={new Date(confirmationDate)}
                      onChange={this.handleDateChange}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                      minDate={
                        popupType === "generate"
                          ? new Date(dateOfJoing)
                          :
                          new Date(confirmationDate)
                      }
                      // data-msg-containerid="meetingdateError"
                      maxDate={
                        popupType === "generate"
                          ? new Date(confirmationDate) >= new Date() // NOSONAR
                            ? new Date(confirmationDate) :
                            new Date()
                          : new Date().setDate(new Date().getDate() + 365)
                      }
                    />
                  </MuiPickersUtilsProvider>
                  {/* <div id="meetingdateError" className="error-div" /> */}
                  {/* </div> */}
                </div>

                {
                  popupType === "generate" &&
                  <div className="col-xs-12 col-sm-12 col-lg-6 col-md-6">
                    <AutoCompleteComponent
                      fields={{ text: "Name", value: "Id" }}
                      id="Role"
                      filtering={this.handleFilteringByName}
                      select={(e) =>
                        this.setState({
                          hrUserId: e.itemData.Id,
                        }, () => document.getElementById('signatureAthority').innerHTML = "")}
                      placeholder="Signature Authority*"
                      popupHeight="220px"
                      cssClass="e-outline"
                      floatLabelType="Auto"
                      dataSource={hrMemberList}
                    />
                    <div id="signatureAthority" className="error-div" style={{ fontSize: 12 }} />
                  </div>
                }
                <div>
                  <div
                    style={{ paddingTop: "10px" }}
                    className="col-xs-12 col-sm-12 col-lg-12 col-md-6"
                  >
                    <TextBoxComponent
                      id="ReasonToBeRevoke"
                      placeholder={
                        popupType === "generate" ? "Comment" : "Reason"
                      }
                      cssClass="e-outline"
                      multiline
                      floatLabelType="Auto"
                      change={(e) => this.setState({ reason: e.value }, () => document.getElementById('confrmcommentError').innerHTML = "")}
                    />
                    <div id="confrmcommentError" className="error-div" style={{ fontSize: 12 }} />
                  </div>
                </div>
              </div>
            </DialogContent>
            <DialogActions>
              <ButtonComponent
                id="cancelRevoke"
                className="e-control "
                onClick={this.closePopupDialog}
              >
                Cancel
              </ButtonComponent>
              <ButtonComponent
                id="sumitRevoke"
                style={{ background: "#f94f5e", color: "#fff" }}
                data-ripple="true"
                onClick={() => {
                  // eslint-disable-next-line
                  popupType === "generate"
                    ? this.actionGenerateConfirmationLetter()
                    : this.actionExtendProbationPeriod();
                }}
              >
                Submit
              </ButtonComponent>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    );
  }
}
Confirmed.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  authUser: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hrMasterList: PropTypes.arrayOf(PropTypes.object),
  typeid: PropTypes.number.isRequired,
};
Confirmed.defaultProps = {
  authUser: "",
  hrMasterList: [],
};
const mapStateToProps = ({ auth, meetingmaster }) => {
  const { authUser } = auth;
  const { hrMasterList } = meetingmaster;
  return { authUser, hrMasterList };
};

export default connect(mapStateToProps, {
  generateConfirmationLetter,
  setMeetingCategory,
})(Confirmed);
