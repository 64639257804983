/**
 * Author Name : Sanket Bari
 * Created Date: 05 March 2024
 * Modified Date: - .
 * Last Modified Name: - .
 */

import React, {useState} from 'react';
import "../../layout/tibrlayout/tibrlayout.css";

// Functional component

const Navigation = ({ setField,setHeaderData  }) => {
    const [activeTab, setActiveTab] = useState('mRadio'); // Setting default active tab
   
    const handleTab = (e) => {
        const tabId = e.target.id;
        setActiveTab(tabId);
        setHeaderData(tabId);
        setField(e);
        
        
    }

    return (
        <tbody>
            <tr id="navTableRow">
                <td style={{ width: 190 }} className="buttonTableData">
                    <button
                        className={`navhead tab ${activeTab === 'mRadio' ? 'activeTab' : ''}`}
                        id="mRadio"
                        type="submit"
                        onClick={handleTab}
                    >
                        Attendance Correction
                    </button>
                </td>
                <td style={{ width: 120 }} className="buttonTableData">
                    <button
                        className={` navhead tab ${activeTab === 'autoRadio' ? 'activeTab' : ''}`}
                        id="autoRadio"
                        type="submit"
                        onClick={handleTab}
                    >
                        TIB Process Data
                        
                    </button>
                </td>
                <td style={{ width: 160 }} className="buttonTableData">
                    <button
                        className={`navhead tab ${activeTab === 'wRadio' ? 'activeTab' : ''}`}
                        id="wRadio"
                        type="submit"
                        onClick={handleTab}
                    >
                        Swap Weekly Off
                        
                    </button>
                </td>
                <td style={{ width: 185 }} className="buttonTableData">
                    <button
                        className={`navhead tab ${activeTab === 'dwRadio' ? 'activeTab' : ''}`}
                        id="dwRadio"
                        type="submit"
                        onClick={handleTab}
                    >
                       Download Attendance
                        
                    </button>
                </td>
                {/* <td style={{ width: 210 }} className="buttonTableData">
                    <button
                        className={`navhead tab ${activeTab === 'prohanceRadio' ? 'activeTab' : ''}`}
                        id="prohanceRadio"
                        type="submit"
                        onClick={handleTab}
                    >
                       Download Prohance Data
                        
                    </button>
                </td> */}
            </tr>
        </tbody>
    );
};

export default Navigation;











// const Navigation = ({setField}) => {

   

//     const handleTab = (e) =>{
//         setField(e);
//         const tabs = document.querySelectorAll('.tab');
//         tabs.forEach(tab => {
//             tab.addEventListener('click', function(){
//                 tabs.forEach(t => t.classList.remove('activeTab'));
//                  this.classList.add('activeTab');
//             })
//         }

//         )
//     }

    

   

//     return <>
//         <tbody>
//             <tr id="navTableRow">

//                 <td style={{ width: 190 }} className="buttonTableData ">
//                     <button
//                         className="tab activeTab"
//                         id="mRadio"
//                         type="submit"
//                         onClick={handleTab}
//                     > Attendance Correction
//                     </button>
//                     {/* TabComponent */}
//                     {/* <RadioButtonComponent   //Attendance Correction
//                             label="Attendance Correction"
//                             id="mRadio"
//                             name="dataType"
//                             checked
//                             onChange={this.setFields}
//                           /> */}
//                 </td>
//                 <td style={{ width: 120 }} className="buttonTableData">
//                     <button
//                         className="tab"
//                         id="autoRadio"
//                         name="dataType"
//                         type="submit"
//                         onClick={handleTab}
//                     >TIB Process Data
//                     </button>

//                     {/* <RadioButtonComponent  //Auto Script
//                             className="left-col-top"
//                             label="Auto Script"
//                             id="autoRadio"
//                             name="dataType"
//                             onChange={this.setFields}
//                           /> */}
//                 </td>
//                 <td style={{ width: 160 }} className="buttonTableData">
//                     <button
//                         className="tab"
//                         id="wRadio"
//                         name="dataType"
//                         type="submit"
//                         onClick={handleTab}
//                     >Swap Weekly Off
//                     </button>
//                     {/*<RadioButtonComponent  //Weeklyoff Swap
//                             className="left-col-top"
//                             label="Swap Weekly Off"
//                             id="wRadio"
//                             name="dataType"
//                             onChange={this.setFields}
//                         />*/}
//                 </td>
//                 <td style={{ width: 185 }} className="buttonTableData">
//                     <button
//                         className="tab"
//                         id="dwRadio"
//                         name="dataType"
//                         type="submit"
//                         onClick={handleTab}
//                     >Download Attendance
//                     </button>
//                     {/* <RadioButtonComponent    //Download Attendance
//                             className="left-col-top"
//                             label="Download Attendance"
//                             id="dwRadio"
//                             name="dataType"
//                             onChange={this.setFields}
//                           /> */}
//                 </td>

//                 <td style={{ width: 210 }} className="buttonTableData">
//                     <button
//                         className="tab"
//                         id="prohanceRadio"
//                         name="dataType"
//                         type="submit"
//                         onClick={handleTab}
//                     >Download Prohance Data
//                     </button>
//                     {/* <RadioButtonComponent   //Prohance Data
//                             className="left-col-top"
//                             label="Download Prohance Data"
//                             id="prohanceRadio"
//                             name="dataType"
//                             onChange={this.setFields}
//                           /> */}
//                 </td>
               

//             </tr>
//         </tbody>
//     </>
// };

// export default Navigation;


