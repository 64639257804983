import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes, { object } from "prop-types";
import { CheckBoxComponent } from "@syncfusion/ej2-react-buttons";
import "../../app/containers/okr/indexokr.css";
import { setCentralOkrKPIRedux, addUpdateObjectveKpi, submitApprovalAction, fillObjectiveRequest, updateOKROrdersIds } from "../../redux/actions/okr/okractions";
import { library } from "@fortawesome/fontawesome-svg-core";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import fileIcon from "../../../src/assets/svg/fileIcon_jpeg.png";
import editIcon from "../../../src/assets/svg/ic-Edit.svg";
import deleteIcon from "../../../src/assets/svg/ic-Delete.svg";

import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ReactHtmlParser from 'react-html-parser';
import { Dialog } from "@material-ui/core";
import { DialogContent } from "../customdialog/customdialog";
import OKRObjEditDetails from "./okrobjeditdetails";
import OKRKpiEditDetails from "./okrkpieditdetails";
import { urlConfig, addBusinessOkrOkrKPILimit } from "../../config/config";
import { benchmarkFormatData, validateKRTotalWeightage } from '../../app/other/commonfunction';
import {
  KpideleteConfernamation, objdeleteConfernamation, objDisableConfernamation, MeasuremenrUnit, MeasuremenrSortBy, objDeactivateConfiramation,
  objCopyConfiramation
} from "../../assets/constants/constants";
import ConfirmationPopup from "../../utilities/confirmationpopup";
import AddNewEmployeeInGrid from './addnewemployeeingrid'
import BenchMarkTable from './benchmarktable';
import ViewKRDetailsModal from '../okrratingcomponents/viewkrdetailsmodal';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBan, faGripDotsVertical } from "@fortawesome/pro-light-svg-icons";
import {SortableContainer,SortableElement} from 'react-sortable-hoc';									  
import {arrayMoveImmutable} from 'array-move';

const cssForScroll = `
::-webkit-scrollbar {
    width: 24px;
  }

  ::-webkit-scrollbar-thumb {
    background: white;
    border-right: 13px #8080804f  solid;
    background-clip: padding-box;
  }
  .MuiDialog-paperWidthLg {
    padding: 0px 8px 0px 0px;
}
`
library.add(faBan);
class NewImportObjectiveView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showProgress: true,
      collapseAllKpi: false,
      openMenuKPI: false,
      anchorElKPI: false,
      openMenu: false,
      anchorElOBJ: false,
      checkedObjectiveDetails: [],
      fillDataPopup: false,
      fillKPIDetailsFlag: false,
      desableObjectKPI: false,
      desableObjectKPIAdd: false,
      deleteType: '',
      disableAddKR: false,
      selectedUser: [],
      viewKrDetailsPopup: false,
      openType: "",
      KRData: {},
      showObjectiveEditMenu: false,
      showDisableObjectiveMenu: false,
      openConfirmPopUp: false,
      openFancyConfirmPopUp: false,
      moveAccordian:false,
      orderChangeMasterObjKpiList:this.props.masterObjKpiList
    };
    this.objKpiDeletetitle = "";
    this.objKpiDeletetitleMessage = "";
    this.checkedObjectiveAndKpi = this.checkedObjectiveAndKpi.bind(this);
    this.closeFillDetails = this.closeFillDetails.bind(this);
    this.getFrequencyName = this.getFrequencyName.bind(this);
    this.fillKPIDetailsFunc = this.fillKPIDetailsFunc.bind(this);
    this.businessObjActive=null;
    this.moveAccordianId="";
  }

  // eslint-disable-next-line
  UNSAFE_componentWillReceiveProps(newProps) {
    const { showHideComponent,sortByFlag } = this.props;
    if (newProps.updateimportStatus === 'updated') {
      this.props.addUpdateObjectveKpi({ updateimportStatus: '' });
    }
    if(newProps.sortByFlag !==sortByFlag && newProps.sortByFlag===true){
      this.setState({
        orderChangeMasterObjKpiList:this.props.masterObjKpiList
      })
    }
    if (newProps.resetCheckedData === 'updated') {
      if (this.state.checkedObjectiveDetails.length > 0) {
        try {
          this.state.checkedObjectiveDetails.forEach((item) => {
            if (document.getElementById(`objId${showHideComponent}${item.objectiveId}`) !== null)
              document.getElementById(`objId${showHideComponent}${item.objectiveId}`).ej2_instances[0].checked = false;
          })
          this.setState({ checkedObjectiveDetails: [] })
        } catch (r) { console.log('error', r) }
      }
      // checkspandraftBusinessOkr
      this.props.addUpdateObjectveKpi({ resetCheckedData: '' });
    }
  }

  createDraftimageFilepath = (filepath) => {
    const { employeeId } = this.props;
    let attachmentFiles = [];
    if (filepath !== undefined && filepath !== "" && filepath !== null) {
      attachmentFiles = filepath.split(",");
    }
    return (
      <>
        {
          attachmentFiles.map((attachedFile, indDraft) => (
            <span key={`draft${indDraft}`}>
              <img
                alt=""
                src={fileIcon}

              />
              <span style={{ padding: "0px 8px 0px 8px" }}>
                <a
                  target="_blank"
                  href={
                    attachedFile !== null && attachedFile !== ""
                      ? `${urlConfig.tmlcApiUrl}document/kpi/${employeeId}/${attachedFile}`
                      : ""
                  }
                  style={{ textDecoration: 'none' }}
                  rel="noopener noreferrer"
                  download
                >
                  <span style={{ color: '#F94F5E' }} title={attachedFile}>{attachedFile.substring(11, attachedFile.length).length > 35 ? attachedFile.substring(11, attachedFile.length).substr(0, 32) + '...' : attachedFile.substring(11, attachedFile.length)}</span>
                </a>
              </span>
            </span>
          ))
        }
      </>
    )
  }
  checkedObjectiveAndKpi = (selecctedObj, event) => {
    // state disdruct is not working here.
    const { showHideComponent } = this.props;
    if (showHideComponent === 'draftBusinessOkr') {
      let checkedObjData = this.state.checkedObjectiveDetails;
      if (event.target.checked) {
        checkedObjData.push(selecctedObj);
      } else {
        // eslint-disable-next-line
        checkedObjData = checkedObjData.filter((x) => { return x.userObjectiveId !== selecctedObj.userObjectiveId });
      }
      this.setState({
        checkedObjectiveDetails: checkedObjData
      })
      this.props.setCheckedOpjective(checkedObjData, showHideComponent);
    } else {
      let checkedObjData = this.state.checkedObjectiveDetails;
      if (event.target.checked) {
        checkedObjData.push(selecctedObj);
      } else {
        // eslint-disable-next-line
        checkedObjData = checkedObjData.filter((x) => { return x.objectiveId !== selecctedObj.objectiveId });
      }
      this.setState({
        checkedObjectiveDetails: checkedObjData
      })
      this.props.setCheckedOpjective(checkedObjData, showHideComponent)
    }
  }
  fillDetails = async (selectedObjective, type) => {
    if (type !== 'fromMenu') {
      // nosonar let objectiveData=Object.create(selectedObjective);
      let objectiveData = { ...selectedObjective };
      await this.props.addUpdateObjectveKpi({ fillObjective: objectiveData })
    }
    await this.setState({ fillDataPopup: true, openMenu: false })
  }
  /***
   * @Edit active objective details.
   */
  fillActiveObjectiveDetails = async (selectedObjective, type) => {/**NOSONAR */
    if (type !== 'fromMenu') {
      // nosonar let objectiveData=Object.create(selectedObjective);
      let objectiveData = { ...selectedObjective };
      await this.props.addUpdateObjectveKpi({ fillObjective: objectiveData })
    }
    await this.setState({ fillDataPopup: true, openMenu: false })
  }
  openObjectiveDetails = async (selectedObjective, type) => {/**NOSONAR */
    await this.setState({ fillDataPopup: true, openMenu: false, openType: type })
  }
  fillActiveKpiDetails = async (data, type) => {
    await this.setState({ fillKPIDetailsFlag: true, openMenu: false })
  }

  closeFillDetails() {
    this.setState({ fillDataPopup: false, openType: "" })
    this.props.addUpdateObjectveKpi({ fillObjective: {} })
  }
  fillKPIDetailsFunc = (fillKpiData, type, objData) => {
    if (type !== 'fromMenu') {
      // nosonar let kpiDetails=Object.create(fillKpiData);
      let kpiDetails = { ...fillKpiData };
      this.props.addUpdateObjectveKpi({ fillKpi: kpiDetails, fillObjective: objData })
    }
    this.setState({ fillKPIDetailsFlag: true, openMenuKPI: false });
  }
  closeFillKPIDetails() {
    this.setState({ fillKPIDetailsFlag: false })
    this.props.addUpdateObjectveKpi({ fillKpi: {}, fillObjective: {} })
  }
  openKPIMenuPopup = (e, data, type, objectiveData) => {
    const { showHideComponent } = this.props;
    //nosonar let kpiDetails=Object.create(data);
    let kpiDetails = { ...data };
    // nosonar let objectiveDetails=Object.create(objectiveData);
    let objectiveDetails = { ...objectiveData };
    if (showHideComponent === "activeBusinessOKR") {
      this.props.addUpdateObjectveKpi({ fillKpi: kpiDetails, fillObjective: objectiveDetails });
      this.setState({ openMenuKPI: true, anchorElKPI: e.currentTarget });
    } else {
      this.props.addUpdateObjectveKpi({ fillKpi: kpiDetails, fillObjective: objectiveDetails })
      this.setState({ openMenuKPI: true, anchorElKPI: e.currentTarget });
    }
  }
  closeKPIMenuPopup = (e, data) => {
    this.setState({ openMenuKPI: false });
    this.props.addUpdateObjectveKpi({ fillKpi: {} })
  }
  openObjectiveMenuPopup = (e, data, type) => {
    const { userObjectiveId, disableEnableFlag, isRatingDone } = data;
    this.setState({ showObjectiveEditMenu: isRatingDone === "N", showDisableObjectiveMenu: isRatingDone === "N" });

    let objectiveData = { ...data };
    const { showHideComponent } = this.props;
    if (data.kpis.length >= addBusinessOkrOkrKPILimit) {
      this.setState({
        disableAddKR: true
      })
    }
    if (showHideComponent === "activeBusinessOKR") {
      this.props.addUpdateObjectveKpi({ fillObjective: objectiveData, /*fillKpi:addNewKpi,*/ objId: userObjectiveId });
      this.setState({ openMenu: true, anchorElOBJ: e.currentTarget, desableObjectKPI: disableEnableFlag });
    } else {
      let addNewKpi = {}
      if (showHideComponent === "draftBusinessOkr") {
        addNewKpi.userKpiId = 0;
        addNewKpi.kpiId = 0;
        addNewKpi.kpiName = '';
        addNewKpi.description = '';
        addNewKpi.varibleLink = '';
        addNewKpi.weightage = 0;
        addNewKpi.varWeightage = 0;
        addNewKpi.monthNo = '';
        addNewKpi.benchmark = null;
        addNewKpi.kpiAttachment = "";
        addNewKpi.measure = "";
        addNewKpi.lable1 = ""; 
        addNewKpi.lable2 = "";
        addNewKpi.scopeTypeId = "";
        addNewKpi.userList = [];
        //nosonar 
        // this.props.addUpdateObjectveKpi({ fillKpi:addNewKpi});
      }
      this.props.addUpdateObjectveKpi({ fillObjective: objectiveData, fillKpi: addNewKpi, objId: userObjectiveId });
      this.setState({ openMenu: true, anchorElOBJ: e.currentTarget, desableObjectKPI: disableEnableFlag });
    }
  }

  closeObjectMenuePopup = () => {
    this.setState({ openMenu: false, desableObjectKPI: "", disableAddKR: false });
    this.props.addUpdateObjectveKpi({ objId: 0 });
  }

  desableObjective = () => {
    const { authUser, objId } = this.props;
    try {
      const disableEnable = {
        type: "DisableObj",
        userId: authUser,
        objId: objId.toString(),
      }
      this.props.submitApprovalAction(disableEnable);
      this.setState({ desableObjectKPI: '1' }, () => this.handleDeleteNo());
    } catch (e) {
      this.handleDeleteNo();
    }
  }
  getFrequencyName = (type, data, frq) => {/**NOSONAR */
    try {
      const { OkrMasters } = this.props;
      const frequency = OkrMasters.filter((item) => {
        return item.var_list_name === "Frequency";
      });
      const effectiveqtr = OkrMasters.filter((item) => {
        return item.var_list_name === "EffectiveQtrView";
      });
      const effectiveMnt = OkrMasters.filter((item) => {
        return item.var_list_name === "EffectiveMnthView";
      });
      const effectiveHlpYear = OkrMasters.filter((item) => {
        return item.var_list_name === "EffectiveHYView";
      });
      const effectiveYear = OkrMasters.filter((item) => {
        return item.var_list_name === "EffectiveFYView";
      });
      const scopeType = OkrMasters.filter((item) => {
        return item.var_list_value === "Scope Type";
      });

      let returnText = '';
      if (type === 'frequency') {
        // eslint-disable-next-line
        returnText = frequency.filter((item) => {
          if (item.Id === data) {
            return item.var_list_value
          }
        })
        if (data !== null && returnText.length > 0) {
          returnText = returnText[0].var_list_value
        }
      }

      if (type === 'effectiveMonth') {
        if (frq === 1) {
          // eslint-disable-next-line
          returnText = effectiveMnt.filter((item) => {/**NOSONAR */
            if (item.Id === data) {
              return item.var_list_value
            }
          })
          if (data !== null && returnText.length > 0) {/**NOSONAR */
            returnText = returnText[0].var_list_value
          }
        }
        if (frq === 2) {
          // eslint-disable-next-line
          returnText = effectiveqtr.filter((item) => {/**NOSONAR */
            if (item.Id === data) {
              return item.var_list_value
            }
          })
          if (data !== null && returnText.length > 0) {
            returnText = returnText[0].var_list_value
          }
        }
        if (frq === 3) {
          // eslint-disable-next-line
          returnText = effectiveHlpYear.filter((item) => {/**NOSONAR */
            if (item.Id === data) {
              return item.var_list_value
            }
          })
          if (data !== null && returnText.length > 0) {
            returnText = returnText[0].var_list_value
          }
        }
        if (frq === 4) {
          // eslint-disable-next-line
          returnText = effectiveYear.filter((item) => {/**NOSONAR */
            if (item.Id === data) {
              return item.var_list_value
            }
          })
          if (data !== null && returnText.length > 0) {
            returnText = returnText[0].var_list_value
          }
        }
      }
      if (type === 'scope') {
        // eslint-disable-next-line
        returnText = scopeType.filter((item) => {
          if (item.Id === data) {
            return item.Name
          }
        })
        if (data !== null && returnText.length > 0) {
          returnText = returnText[0].var_optional1
        }
      }

      return (
        <span>{returnText}</span>
      );
    } catch (err) {
      console.log('error===>', err);
    }
  }
  deleteUserDraftedObjective = () => {
    const { authUser, fillObjective } = this.props;
    const submitApprovalData = {
      type: "deleteUserDraftOkr",
      userId: authUser,
      objId: fillObjective.userObjectiveId.toString()
    }
    this.props.submitApprovalAction(submitApprovalData);
    this.handleDeleteNo();
    this.props.addUpdateObjectveKpi({ updateBuDraftFlag: 'updated' })
  }
  deleteKpi = () => {
    const { authUser, fillKpi } = this.props;
    const deleteKpiData = {
      type: "deleteUserKpi",
      userId: authUser,
      objId: fillKpi.userKpiId.toString()
    }
    this.props.submitApprovalAction(deleteKpiData);
    this.handleDeleteNo();
    // this.props.addUpdateObjectveKpi({updateBuDraftFlag:'updated'})
  }
  openDeletePopUp = (type) => {
    if (type === 'deleteKpi') {
      this.objKpiDeletetitle = "Delete Key Result"
      this.objKpiDeletetitleMessage = KpideleteConfernamation;
      this.setState({
        openConfirmPopUp: true,
        openFancyConfirmPopUp: true,
        deleteType: type,
        openMenuKPI: false
      })
    }
    if (type === 'deleteObj') {
      this.objKpiDeletetitle = "Delete Objective";
      this.objKpiDeletetitleMessage = objdeleteConfernamation;
      this.setState({
        openConfirmPopUp: true,
        openFancyConfirmPopUp: true,
        deleteType: type,
        openMenu: false,
      })
    }
    if (type === 'disableObj') {
      this.objKpiDeletetitle = "Delete Objective";
      this.objKpiDeletetitleMessage = objDisableConfernamation;
      this.setState({
        openConfirmPopUp: true,
        openFancyConfirmPopUp: true,
        deleteType: type,
        openMenu: false
      })
    }
    if (type === 'deactivateObj') {
      this.objKpiDeletetitle = objDeactivateConfiramation;
      this.objKpiDeletetitleMessage = "Are you sure you want to Deactivate this OKR?";
      this.setState({
        openConfirmPopUp: true,
        openFancyConfirmPopUp: true,
        deleteType: type,
        openMenu: false
      })
    }
  }
  handleDeleteYes = () => {
    const { deleteType } = this.state;    
    if (deleteType === 'deleteKpi') {
      this.deleteKpi();
    }
    if (deleteType === 'deleteObj') {
      this.deleteUserDraftedObjective();
    }
    if (deleteType === 'disableObj') {
      this.desableObjective();
    }
    if (deleteType === 'deactivateObj') {
      this.setState({
        openFancyConfirmPopUp: false,
        deleteType: ""
      })
      setTimeout(() => {
        this.objKpiDeletetitle = objCopyConfiramation;
        this.objKpiDeletetitleMessage = "Do you want to save a copy of this OKR in Drafts tab?";
        this.setState({
          openFancyConfirmPopUp: true,
          deleteType: "copyOKR",
          openMenu: false
        })
      }, 100)
    }
    if (deleteType === 'copyOKR') {
      this.deactiveDraftObj(deleteType)
      this.setState({
        openFancyConfirmPopUp: false,
        deleteType: ""
      })
    }
  }

  handleDeleteNo = () => {
    const { deleteType } = this.state;
    if (deleteType === 'copyOKR') {
      this.deactiveDraftObj("deactiveObj")
    }
    this.setState({
      openConfirmPopUp: false,
      openFancyConfirmPopUp: false,
      deleteType: ""
    })
  };

  checkIsSelected = (arr, val) => {
    return arr.some(function (arrVal) { return val === arrVal.id; });
  }

  showAddmeGrid = async (selectedUser) => {
    const { othersCheckAvailablity } = this.props;
    let userList = [];
    await selectedUser.map(async (item) => {
      let data = await othersCheckAvailablity.filter((t) => t.UserId === item);
      userList.push(data[0]);
    })

    return userList
  }
  getUnitName = (unit) => {
    let data = MeasuremenrUnit.filter((t) => t.Value === unit)
    return data[0].Name
  }
  getSortByName = (sortBy) => {
    let data = MeasuremenrSortBy.filter((t) => t.Value === sortBy)
    return data[0].Name
  }
  closeKrDetailsPopup = () => {
    this.setState({ viewKrDetailsPopup: false, KRData: {} })
  }
  openKrDetailsPopup = (data) => {    
    this.setState({ viewKrDetailsPopup: true, KRData: data })
    try{      
      let ele=document.getElementById("accObj"+data.userObjectiveId);      
      ele.setAttribute('aria-expanded','true')
    }catch(e){

    }
  }
  getFrequencysName = (type) => {
    let frequenyName = '';
    switch (type) {
      case 1:
        frequenyName = "Monthly ";

        break;
      case 2:
        frequenyName = "Quarterly ";
        break;
      case 3:
        frequenyName = "Half Year ";

        break;
      case 4:
        frequenyName = "Year ";
        break;
      default:
    }
    return frequenyName;
  }
  updateLastSubmittedBusinessObj() {
    this.props.addUpdateObjectveKpi({ lastSubmittedBusinessOkrObjId: 0 });
  }

  setObjectiveTitle() {
    const { openType } = this.state
    switch (openType) {
      case "closeOKR":
        return "Close OKR - Please confirm the OKR's Period To.";
      case "copyOKR":
        return "Close & Create New Copy (Draft) - Please confirm the OKR's Period To.";
      default:
        return "Objective - Edit"
    }
  }

  deactiveDraftObj(type) {
    try {
      const { employeeId, fyid, authUser, fillObjective } = this.props;
      let data = {};
      data.objectiveTitle = fillObjective.objectiveName;
      data.effectiveMonth = fillObjective.startMonth;
      data.effectiveTo = fillObjective.endMonth;
      data.effectiveFrequency = fillObjective.effectiveFrequency;
      data.isDraft = fillObjective.isDraft;
      data.type = type;
      data.objectiveLibId = fillObjective.objectiveLibId !== undefined && fillObjective.objectiveLibId !== 0 ? fillObjective.objectiveLibId : fillObjective.objectiveId;
      data.objectiveId = fillObjective.userObjectiveId !== undefined ? fillObjective.userObjectiveId : 0;
      data.empId = employeeId;
      data.fyid = fyid;
      data.userId = authUser;
      data.kpiLibIds = null;
      data.kpiIds = null;
      data.kpiTitle = "";
      data.kpiDescription = "";
      data.kpiAttachment = "";
      data.kpiWeightage = "";
      data.variablePercentage = fillObjective.variable;
      data.kpiVariableLinked = null;
      data.kpiBenchmark = "";
      data.kpiScopeType = "";
      data.kpiScopeUsers = "";
      data.measure = "";
      data.lable1 = "";
      data.lable2 = "";
      this.props.fillObjectiveRequest(data);
      this.props.addUpdateObjectveKpi({ resetCheckedObjData: 'updated', resetCheckedData: 'updated' });
    } catch (error) {
      console.log('error', error);
    }
  }
  addNewKr = (selectedObj, type)=>{
    let addNewKpi = {}
      addNewKpi.userKpiId = 0;
      addNewKpi.kpiId = 0;
      addNewKpi.kpiName = '';
      addNewKpi.description = '';
      addNewKpi.varibleLink = '';
      addNewKpi.weightage = 0;
      addNewKpi.varWeightage = 0;
      addNewKpi.monthNo = '';
      addNewKpi.benchmark = null;
      addNewKpi.kpiAttachment = "";
      addNewKpi.measure = "";
      addNewKpi.lable1 = ""; 
      addNewKpi.lable2 = "";
      addNewKpi.scopeTypeId = "";
      addNewKpi.userList = [];
      //nosonar 
      this.props.addUpdateObjectveKpi({ fillKpi: addNewKpi })
      this.setState({ fillKPIDetailsFlag: true, openMenu: false }); 
  }
  renderAccordions =()=>{
    const { masterObjKpiList, showHideComponent, businessUserVariableImpact, centralUserVariableImpact, enableAction, lastSubmittedBusinessOkrObjId } = this.props;
    const { openMenuKPI, openMenu, anchorElKPI, anchorElOBJ, checkedObjectiveDetails,
       showObjectiveEditMenu, showDisableObjectiveMenu} = this.state;
    const isHigherResolution = (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight) > 767;
    let defaultExpObj = 0;
    // eslint-disable-next-line
    masterObjKpiList.map((createObj) => {
      if (lastSubmittedBusinessOkrObjId === createObj.userObjectiveId) {
        defaultExpObj = lastSubmittedBusinessOkrObjId;
        this.updateLastSubmittedBusinessObj();
      }
    })
    return (
      <>
      {
          masterObjKpiList.map((createObj, indObj) =>//NOSONAR
          (
            <div key={`createDraft${typeof createObj.userObjectiveId !== 'undefined' ? createObj.userObjectiveId : createObj.objectiveId}`}>
              <Accordion
                defaultExpanded={defaultExpObj === createObj.userObjectiveId && (showHideComponent === "draftBusinessOkr" || showHideComponent === "activeBusinessOKR") ? true : false}
                ref={businessObj => (this.businessObj = businessObj)}
                id={`accObj${createObj.objectiveId}`}
                key={`accObj${typeof createObj.userObjectiveId !== 'undefined' ? createObj.userObjectiveId : createObj.objectiveId}`}
                style={{
                  marginTop: indObj === 0 && showHideComponent !== "import" ? "0px" : "16px",
                  marginBottom: masterObjKpiList.length === indObj + 1 ? "0px" : "16px",
                  boxShadow: "none", position: "initial",
                }}
              >
                <AccordionSummary
                  style={{
                    background: "#F2F2F2", paddingLeft: "0px",
                    borderLeft: createObj.disableEnableFlag === "1" ? '4px solid #DBDBDD' : (createObj.isDraft === "0" && createObj.totalWeightage === 100) ? /**NOSONAR */'4px solid #36C17C' : (createObj.isDraft === "0" && createObj.totalWeightage !== 100 && (showHideComponent === "activeBusinessOKR" || showHideComponent === "activeCentralOKR")) ? '4px solid #f47a7a' : createObj.approvalStatus === "Approved" ? '4px solid #36C17C' : (createObj.totalWeightage === 100 && (showHideComponent === "draftBusinessOkr" || showHideComponent === "draftCentralOKR") && createObj.effectiveFrequency !== null && createObj.effectiveMonth !== null && validateKRTotalWeightage(createObj.kpis)) ? "4px solid #48b8e7" : '4px solid #FDD141',
                    minHeight: "30px",
                    height: isHigherResolution ? '' : 40
                  }}
                  expandIcon={<ExpandMoreIcon />}
                  aria-label="Expand"
                  aria-controls="additional-actions1-content"
                  id="additional-actions1-header"
                >
                  {showHideComponent !== "activeBusinessOKR" && showHideComponent !== "activeCentralOKR" &&
                    <span key={showHideComponent === "import" && (typeof createObj.userObjectiveId == 'undefined' || createObj.userObjectiveId === null) ? `checkspan${showHideComponent}${createObj.objectiveId}` : `checkspan${showHideComponent}${createObj.userObjectiveId}`} style={{ margin: "8px 6px 10px -32px" }}>
                      <CheckBoxComponent
                        id={showHideComponent === "import" && (typeof createObj.userObjectiveId == 'undefined' || createObj.userObjectiveId === null) ? `objId${showHideComponent}${createObj.objectiveId}` : `objId${showHideComponent}${createObj.userObjectiveId}`}
                        checked={checkedObjectiveDetails.length > 0 ? this.checkIsSelected(checkedObjectiveDetails, createObj.objectiveId) : false}
                        disabled={createObj.totalWeightage === 100 && createObj.effectiveFrequency !== null && createObj.effectiveMonth !== null &&
                          (showHideComponent === "draftBusinessOkr" || showHideComponent === "draftCentralOKR") && createObj.kpis.length <= addBusinessOkrOkrKPILimit ?
                          false : (showHideComponent !== "draftBusinessOkr" && showHideComponent !== "draftCentralOKR") /**NOSONAR */ ?
                            false : true}
                        onChange={(event) => this.checkedObjectiveAndKpi(createObj, event)}
                      />

                    </span>
                  }
                  <div title={createObj.objectiveName} className={showHideComponent === "import" ? "col-xs-12 col-sm-8 col-lg-8 col-md-8 bold-header-obj-kpi" : "col-xs-12 col-sm-8 col-lg-5 col-md-8 bold-header-obj-kpi"} style={{ padding: showHideComponent !== "activeBusinessOKR" && showHideComponent !== "activeCentralOKR" ? "14px 0px 14px 24px" : "14px 0px 14px 18px" }}>
                    {createObj.objectiveName}
                  </div>
                  {
                    showHideComponent === "import" &&
                    (
                      <div className="col-xs-12 col-sm-3 col-lg-4 col-md-4 tags-obj-kpi" style={{ padding: "14px" }}> {createObj.objectiveTags} </div>
                    )
                  }
                  {
                    (showHideComponent === "draftBusinessOkr") && //NOSONAR
                    (
                      <div className="col-xs-12 col-sm-3 col-lg-7 col-md-4 tags-obj-kpi" style={{ padding: "14px 0px 14px 0px", textAlign: 'right' }}>
                        {createObj.reviewFrequency !== "" &&
                          <span className="normal-visivbility-level-obj-kpi">OKR Rating Frequency:&nbsp;
                            <span className="objective-validaty-text">{createObj.reviewFrequency}</span>
                            {(createObj.periodFromHdr !== "") && <span className="normal-visivbility-level-obj-kpi">&nbsp;OKR Validity{(createObj.periodToHdr !== null && createObj.periodToHdr !== "") ? " Start" : ""}:</span>}
                          </span>
                        }

                        <span className="objective-validaty-text" > {createObj.periodFromHdr} </span>

                        {(createObj.periodToHdr !== null && createObj.periodToHdr !== "") && <><span className="normal-visivbility-level-obj-kpi">Ends:</span><span className="objective-validaty-text" > {createObj.periodToHdr} </span></>}

                        {(businessUserVariableImpact !== 0) && <><span className="normal-visivbility-level-obj-kpi">Variable Impact %:</span><span className="objective-validaty-text" >
                          {createObj.variable === null ?/**NOSONAR */ 0 : createObj.variable}% </span></>
                        }
                      </div>
                    )
                  }
                  {
                    showHideComponent === "activeBusinessOKR" && ( //NOSONAR

                      <div className="col-xs-12 col-sm-3 col-lg-7 col-md-4 tags-obj-kpi" style={{ padding: "14px 0px 14px 0px", textAlign: 'right' }}>
                        <span className="normal-visivbility-level-obj-kpi">OKR Rating Frequency:&nbsp;<span className="objective-validaty-text">{createObj.reviewFrequency}
                          <span className="normal-visivbility-level-obj-kpi"> OKR Validity{(createObj.periodToHdr !== null && createObj.periodToHdr !== "") ? " Start" : ""}:</span>
                        </span>
                        </span>
                        <span className="objective-validaty-text" > {createObj.periodFromHdr} </span>
                        {(createObj.periodToHdr !== null && createObj.periodToHdr !== "") && <><span className="normal-visivbility-level-obj-kpi">Ends:</span><span className="objective-validaty-text" > {createObj.periodToHdr} </span></>}
                        {(businessUserVariableImpact !== 0) && <><span className="normal-visivbility-level-obj-kpi">Variable Impact %:</span><span className="objective-validaty-text" > {createObj.variable === null ?/**NOSONAR */ 0 : createObj.variable}% </span></>}
                      </div>
                    )
                  }
                  {
                    showHideComponent === "draftCentralOKR" && //NOSONAR
                    (
                      <div className="col-xs-12 col-sm-3 col-lg-7 col-md-4 tags-obj-kpi" style={{ padding: "14px 14px 14px 14px", textAlign: 'right' }}>
                        <span className="normal-visivbility-level-obj-kpi">OKR Rating Frequency:&nbsp;<span className="objective-validaty-text">{createObj.reviewFrequency}&nbsp;
                          <span className="normal-visivbility-level-obj-kpi"><span className="normal-visivbility-level-obj-kpi">OKR Validity{(createObj.periodToHdr !== null && createObj.periodToHdr !== "") ? " Start" : ""}:</span></span>
                        </span></span>
                        <span className="objective-validaty-text" > {createObj.periodFromHdr} </span>
                        {(createObj.periodToHdr !== null && createObj.periodToHdr !== "") && <><span className="normal-visivbility-level-obj-kpi">Ends:</span><span className="objective-validaty-text" > {createObj.periodToHdr} </span></>}
                        {(centralUserVariableImpact !== 0) && <><span className="normal-visivbility-level-obj-kpi">Variable Impact %:</span><span className="objective-validaty-text pr-lg" > {createObj.variable === null ?/**NOSONAR */ 0 : createObj.variable}% </span></>}
                      </div>
                    )
                  }
                  {
                    showHideComponent === "activeCentralOKR" && ( //NOSONAR
                      <div className="col-xs-12 col-sm-3 col-lg-7 col-md-4 tags-obj-kpi" style={{ padding: "14px 14px 14px 14px", textAlign: 'right' }}>
                        <span className="normal-visivbility-level-obj-kpi">OKR Rating Frequency:&nbsp;<span className="objective-validaty-text">{createObj.reviewFrequency}
                          <span className="normal-visivbility-level-obj-kpi"> OKR Validity{(createObj.periodToHdr !== null && createObj.periodToHdr !== "") ? " Start" : ""}:</span></span></span><span className="objective-validaty-text" > {createObj.periodFromHdr} </span>
                        {(createObj.periodToHdr !== null && createObj.periodToHdr !== "") && <><span className="normal-visivbility-level-obj-kpi">Ends:</span><span className="objective-validaty-text" > {createObj.periodToHdr} </span></>}
                        {(centralUserVariableImpact !== 0) && <><span className="normal-visivbility-level-obj-kpi">Variable Impact %:</span><span className="objective-validaty-text" > {createObj.variable === null ?/**NOSONAR */ 0 : createObj.variable}% </span></>}
                      </div>
                    )
                  }
                  <div className={showHideComponent === "draftBusinessOkr" && (createObj.totalWeightage !== 100 || createObj.effectiveMonth === null) ? "col-xs-12 col-sm-1 col-lg-1 col-md-1" : ''} style={{ padding: showHideComponent === "draftBusinessOkr" ? "0px 0px 0px 6px" : '0px 8px 0px 8px' }}>
                    {
                      showHideComponent === "draftBusinessOkr" && (createObj.totalWeightage !== 100 || createObj.effectiveMonth === null) && enableAction &&
                      <FormControlLabel
                        control={
                          <span style={{ padding: '32px 16px 0px 0px', position: "absolute", color: "#F94F5E", marginLeft: 10 }} onClick={() => this.fillDetails(createObj, "")}><span className='hoverEffect'> Fill Details </span> </span>
                        }
                        onClick={(event) => { event.stopPropagation() }}
                        onFocus={(event) => event.stopPropagation()}
                      />
                    }
                    <FormControlLabel id={`control${createObj.userObjectiveId}`} style={{ float: "right", margin: (showHideComponent === "draftBusinessOkr") && (createObj.totalWeightage === 100 && createObj.effectiveMonth !== null) && enableAction ? "0px -4px 0px 0px" : "0px -8px 0px 0px" }}
                      control={
                        <div>
                          {
                            showHideComponent !== "import" &&
                            <>
                              <IconButton
                                id={`menuIcon${createObj.userObjectiveId}`}
                                onClick={(e) => this.openObjectiveMenuPopup(e, createObj, showHideComponent)}
                                disabled={!enableAction ||  showHideComponent.includes("activeCentralOKR")}
                              >
                                <MoreVertIcon />
                              </IconButton>
                              <Menu
                                id={`objectId${createObj.userObjectiveId}`}
                                keepMounted
                                anchorEl={anchorElOBJ}
                                open={Boolean(openMenu)}
                                onClose={this.closeObjectMenuePopup}
                                style={{ margin: showHideComponent === "draftCentralOKR" ? "30px 0px 0px -80px" : "60px 0px 0px -80px", top: "25px" }}
                              >
                                {
                                  showHideComponent === "activeBusinessOKR" ? (
                                    <div>
                                      {
                                        <>
                                          <MenuItem key={`objEditMenuItem${createObj.userObjectiveId}`}
                                            disabled={!showObjectiveEditMenu}
                                            onClick={(e) => this.fillActiveObjectiveDetails(createObj, "fromMenu")}>
                                            <span id={`objEditMenuItem${createObj.userObjectiveId}`}>
                                              <span style={{ padding: "8px 8px 8px 0px" }}>
                                                <img
                                                  alt=""
                                                  src={editIcon}
                                                />
                                              </span> <span style={{ padding: "0px 32px 0px 0px" }} >Edit</span>

                                            </span>
                                          </MenuItem>
                                          <MenuItem key={`objDesableMenuItem${createObj.userObjectiveId}`}
                                            disabled={!showDisableObjectiveMenu}
                                            onClick={(e) => { this.openDeletePopUp('disableObj') }}>
                                            <span id={`objDesableMenuItem${createObj.userObjectiveId}`}>
                                              <span style={{ padding: "8px 8px 8px 0px" }}>
                                                <img
                                                  alt=""
                                                  src={deleteIcon}
                                                /></span> <span> Delete </span></span>
                                          </MenuItem>
                                          <MenuItem key={`objDeactiveMenuItem${createObj.userObjectiveId}`}
                                            disabled={showObjectiveEditMenu}
                                            onClick={(e) => this.openDeletePopUp("deactivateObj")}>
                                            <span id={`objDeactiveMenuItem${createObj.userObjectiveId}`}>
                                              <FontAwesomeIcon
                                                className="mr-sm"
                                                id="pointer"
                                                icon={faBan}
                                                style={{ cursor: "pointer", fontSize: 12, marginLeft: "4px", background: "#F4F4F7", color: "red" }}
                                              />
                                              Deactivate</span>
                                          </MenuItem>
                                        </>
                                      }
                                    </div>
                                  ) : (
                                    <div>
                                        {
                                        showHideComponent !== "draftCentralOKR" && showHideComponent !== "activeCentralOKR" &&
                                      <MenuItem key={`editObj${createObj.userObjectiveId}`} onClick={(e) => this.addNewKr(createObj, "fromMenu")}>
                                        <span style={{ padding: "8px 8px 8px 0px" }}>
                                          <img
                                            alt=""
                                            src={editIcon}
                                          />
                                        </span> <span style={{ padding: "0px 32px 0px 0px" }} >Add</span></MenuItem>
                                      }
                                      <MenuItem key={`editObj${createObj.userObjectiveId}`} onClick={(e) => this.fillDetails(createObj, "fromMenu")}>
                                        <span style={{ padding: "8px 8px 8px 0px" }}>
                                          <img
                                            alt=""
                                            src={editIcon}
                                          />
                                        </span> <span style={{ padding: "0px 32px 0px 0px" }} >Edit</span></MenuItem>
                                      {
                                        showHideComponent !== "draftCentralOKR" && showHideComponent !== "activeCentralOKR" &&
                                        <MenuItem key={`deleteObj${createObj.userObjectiveId}`}
                                          onClick={(e) => this.openDeletePopUp('deleteObj')}
                                        >
                                          <span style={{ padding: "8px 8px 8px 0px" }}>
                                            <img
                                              alt=""
                                              src={deleteIcon}

                                            /></span> <span> Delete </span></MenuItem>
                                      }
                                    </div>
                                  )
                                }
                              </Menu>
                            </>
                          }
                        </div>
                      }
                      onClick={(event) => { event.stopPropagation() }}
                      onFocus={(event) => event.stopPropagation()}
                    />
                  </div>
                </AccordionSummary>
                {
                  showHideComponent === "import" &&
                  <AccordionDetails style={{
                    background: "#8080801c", padding: "0px 0px 8px 0px", borderLeft: createObj.disableEnableFlag === "1" ? '4px solid #DBDBDD' : createObj.isDraft === "0" ? /**NOSONAR */'4px solid #36C17C' : (createObj.approvalStatus === "Pending" && createObj.totalWeightage !== 100) ? '4px solid #FDD141' : createObj.approvalStatus === "Approved" ? '4px solid #36C17C' : createObj.approvalStatus === "Approved" ? '4px solid #36C17C' : (createObj.totalWeightage === 100) ? "4px solid #48b8e7" : '4px solid #F47A7A',
                    minHeight: '30px'
                  }}>
                    <div className="col-xs-12 col-sm-12 col-lg-12" style={{ padding: "0px 16px 0px 16px" }}>
                      <span className="normal-visivbility-level-obj-kpi">Visibility :</span><span className="objective-validaty-text" > {createObj.objectiveVisibility} </span>
                      {
                        createObj.levelName !== "" && createObj.levelName !== null &&
                        <>
                          <span className="normal-visivbility-level-obj-kpi">Level :</span><span className="objective-validaty-text" > {createObj.levelName}</span>
                        </>
                      }
                    </div>
                  </AccordionDetails>
                }
                {/* Kpit Start  */}
                {
                  createObj.kpis.map((CreateKpiData)=> {
                    let benchMarkData = [];
                    if (CreateKpiData.benchmark !== null && (showHideComponent !== 'import')) {
                      benchMarkData = benchmarkFormatData(CreateKpiData.benchmark);
                    }
                    return (
                      <Accordion defaultExpanded={false} key={`kpiAcc${CreateKpiData.kpiId}`} className="kpi-border-css"
                        style={{ margin: "0px", boxShadow: "none", position: "inherit", backgroundColor: createObj.disableEnableFlag === "1" ? "#8080801c" : "none", height: isHigherResolution ? '' : 35 }}
                        // disabled={createObj.disableEnableFlag === "1" ? true : false}
                        // expanded={(showHideComponent === "activeBusinessOKR") || (showHideComponent === "draftBusinessOkr") || (showHideComponent === "activeCentralOKR") || (showHideComponent === "draftCentralOKR") ? false : true}
                        expanded={false}

                        onClick={() => this.openKrDetailsPopup(CreateKpiData)}
                      >
                        <AccordionSummary style={{ minHeight: "30px" }}
                          expandIcon={<ExpandMoreIcon />}                          
                          aria-label="Expand"
                          aria-controls="additional-actions1-content"
                          id="additional-actions1-header"

                        >
                          {showHideComponent === "import" ?
                            <div className="col-xs-12 col-sm-8 col-lg-8 col-md-8 header-obj-kpi rating-okr-onhover-text"
                              style={{ padding: isHigherResolution ? "14px 14px 14px 3px" : "0px 0px 16px 4px" }}
                              onClick={() => this.openKrDetailsPopup(CreateKpiData)}
                            >
                              {CreateKpiData.kpiName}
                            </div>
                            :
                            <div className="col-xs-12 col-sm-8 col-lg-8 col-md-8 header-obj-kpi rating-okr-onhover-text"
                              style={{ padding: isHigherResolution ? "14px 14px 14px 3px" : "8px 0px 16px 4px" }}
                              onClick={() => this.openKrDetailsPopup(CreateKpiData)}
                              // onClick={() =>alert("openKrDetailsPopup")}
                            >
                              {CreateKpiData.kpiName}
                            </div>
                          }

                          {
                            showHideComponent === "import" ?
                              (
                                <div className="col-xs-12 col-sm-3 col-lg-4 col-md-4 tags-obj-kpi" style={{ padding: isHigherResolution ? "14px 14px 14px 8px" : "0px 14px 8px 8px" }}> {CreateKpiData.kpiTags} </div>
                              )
                              :
                              (CreateKpiData.varibleLink !== null || CreateKpiData.weightage !== null) && (showHideComponent === "draftBusinessOkr" || showHideComponent === "activeBusinessOKR") ?//NOSONAR
                                (
                                  <div className="col-xs-12 col-sm-3 col-lg-4 col-md-4 tags-obj-kpi" style={{ padding: isHigherResolution ? "14px 8px 14px 8px" : "8px 8px 8px 8px", textAlign: 'right' }}>
                                    {businessUserVariableImpact > 0 && <><span className="normal-visivbility-level-obj-kpi">Variable Link :</span><span className="objective-validaty-text" > {(CreateKpiData.varibleLink === '1' || CreateKpiData.varibleLink === 1) ? //NOSONAR
                                      'Yes' : 'No'} </span></>}
                                    <span className="normal-visivbility-level-obj-kpi">Weightage :</span><span className="objective-validaty-texts" > {CreateKpiData.weightage === null ? 0 : CreateKpiData.weightage//NOSONAR}
                                    }% </span>
                                  </div>
                                )
                                :
                                (CreateKpiData.varibleLink !== null || CreateKpiData.weightage !== null) && (showHideComponent === "draftCentralOKR" || showHideComponent === "activeCentralOKR") ?//NOSONAR
                                  (
                                    <div className="col-xs-12 col-sm-3 col-lg-6 col-md-4 tags-obj-kpi" style={{ padding: isHigherResolution ? "14px 8px 14px 8px" : "8px 8px 8px 8px", textAlign: 'right' }}>
                                      {centralUserVariableImpact > 0 && <><span className="normal-visivbility-level-obj-kpi">Variable Link :</span><span className="objective-validaty-text" > {(CreateKpiData.varibleLink === '1' || CreateKpiData.varibleLink === 1) ? //NOSONAR
                                        'Yes' : 'No'} </span></>}
                                      <span className="normal-visivbility-level-obj-kpi">Weightage :</span><span className="objective-validaty-texts" > {CreateKpiData.weightage === null ? 0 : CreateKpiData.weightage//NOSONAR}
                                      }% </span>
                                    </div>
                                  )
                                  :
                                  <div className="col-xs-12 col-sm-3 col-lg-4 col-md-4 tags-obj-kpi" style={{ padding: "14px 8px 14px 8px", textAlign: 'right' }}></div>
                          }
                          <div className={showHideComponent === "draftBusinessOkr" && createObj.totalWeightage !== 100 ? "col-xs-12 col-sm-1 col-lg-1 col-md-1" : ''} style={{ padding: "0px 8px 0px 0px" }}>
                            {
                              showHideComponent === "draftBusinessOkr" && createObj.totalWeightage !== 100 && enableAction &&
                              <FormControlLabel
                                control={
                                  <span className="form-control-padding" style={{ position: "absolute", color: "#F94F5E", marginLeft: 9 }}
                                    onClick={() => this.fillKPIDetailsFunc(CreateKpiData, "", createObj)}><span className='hoverEffect'> Fill Details </span> </span>
                                }
                                onClick={(event) => { event.stopPropagation() }}
                                onFocus={(event) => event.stopPropagation()}
                              />
                            }
                            {/* <FormControlLabel style={{ margin: showHideComponent === "draftBusinessOkr" ? "0px -13px 0px 0px" : "0px -8px 0px 0px", float: "right" }} */}
                            <FormControlLabel style={{ margin: showHideComponent === "draftBusinessOkr" && createObj.totalWeightage !== 100 && enableAction ? "0px -17px 0px 0px" : "0px -8px 0px 0px", float: "right" }}
                              control={
                                <div>
                                  {
                                    showHideComponent !== "import" &&
                                    <div>
                                      <IconButton
                                        id={`${CreateKpiData.kpiId}`}
                                        onClick={(e) => this.openKPIMenuPopup(e, CreateKpiData, showHideComponent, createObj)}
                                        disabled={createObj.isRatingDone === "Y" || !enableAction}
                                      >
                                        <MoreVertIcon />
                                      </IconButton>
                                      <Menu
                                        id={`kpi${CreateKpiData.kpiId}`}
                                        keepMounted
                                        anchorEl={anchorElKPI}
                                        open={Boolean(openMenuKPI)}
                                        onClose={this.closeKPIMenuPopup}
                                        style={{ margin: "50px 0px 0px -80px" }}
                                      >
                                        <MenuItem key={`kpiEditMenuItem${CreateKpiData.kpiId}`}
                                          onClick={(e) => this.fillKPIDetailsFunc(CreateKpiData, 'fromMenu', createObj)}>
                                          <span style={{ padding: "8px 8px 8px 0px" }}>
                                            <img
                                              alt=""
                                              src={editIcon}

                                            />
                                          </span> <span style={{ padding: "0px 32px 0px 0px" }}>Edit</span></MenuItem>
                                        {showHideComponent !== "activeBusinessOKR" && showHideComponent !== "activeCentralOKR" && //showHideComponent !== "draftCentralOKR" &&
                                          <MenuItem key={`kpiDeleteMenuItem${CreateKpiData.kpiId}`}
                                            onClick={(e) => this.openDeletePopUp('deleteKpi')}
                                          >
                                            <span style={{ padding: "8px 8px 8px 0px" }}>
                                              <img
                                                alt=""
                                                src={deleteIcon}
                                              /></span> <span> Delete </span>
                                          </MenuItem>
                                        }
                                      </Menu>
                                    </div>
                                  }
                                </div>
                              }
                              onClick={(event) => event.stopPropagation()}
                              onFocus={(event) => event.stopPropagation()}
                            />
                          </div>
                        </AccordionSummary>
                        {
                          showHideComponent !== "import" && //showHideComponent !== "draftCentralOKR" && showHideComponent !=="activeCentralOKR" &&
                          <AccordionDetails style={{ backgroundColor: createObj.disableEnableFlag === "1" ? "#80808000" : "#ffff", padding: "0px 0px 8px 0px", minHeight: '30px' }}>
                            <div className="col-xs-12 col-sm-12 col-lg-12" style={{ padding: "0px 18px 0px 18px" }}>
                              {/* <span className="normal-visivbility-level-obj-kpi">Benchmark For Yes : </span><span className="objective-validaty-text" >{CreateKpiData.benchmark} </span> */}
                              {
                                createObj.scopeTypeId !== "" && createObj.scopeTypeId !== null &&
                                <>
                                  <span className="normal-visivbility-level-obj-kpi">Scope : </span><span className="objective-validaty-text" >{this.getFrequencyName('scope', CreateKpiData.scopeTypeId)}</span>
                                </>
                              }
                            </div>
                          </AccordionDetails>
                        }
                        <AccordionDetails style={{ background: "rgb(72 184 231 / 5%)", padding: "8px 18px 8px 18px" }}>
                          <div className="row">
                            {
                              CreateKpiData.kpiAttachment !== "" && CreateKpiData.kpiAttachment !== null &&
                              <div className="col-xs-12 col-sm-12 col-lg-12" style={{ padding: "8px 14px 14px 14px" }}>
                                {this.createDraftimageFilepath(CreateKpiData.kpiAttachment)}
                              </div>
                            }
                            <div className='col-xs-12 col-sm-12 col-lg-12 col-md-12'>
                              <div className={'col-xs-12 col-sm-12 col-lg-6 col-md-6'} style={{ marginLeft: 0, paddingLeft: 0 }}>
                                <div className="col-xs-12 col-sm-12 col-lg-12" style={{ color: "#808080a8", paddingBottom: "4px" }}> Description </div>
                                <div className="col-xs-12 col-sm-12 col-lg-12" style={{}}>
                                  {ReactHtmlParser(CreateKpiData.description)}
                                </div>
                              </div>
                              <div className={'col-xs-12 col-sm-12 col-lg-6 col-md-6'} style={{ marginLeft: -15, paddingLeft: 0 }}>
                                {
                                  showHideComponent !== "import" &&
                                  <>
                                    <div className='col-xs-12 col-sm-12 col-lg-12 col-md-12'>
                                      {
                                        CreateKpiData.lable1 !== null && CreateKpiData.lable1 !== "" &&
                                        <div className="col-xs-12 col-sm-6 col-lg-6 col-md-6" >
                                          <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12" style={{ color: "#808080a8", paddingBottom: "4px", paddingTop: "4px" }}> Reference 1 </div>
                                          <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                            <div style={{ wordWrap: 'break-word' }}>{CreateKpiData.lable1}</div>
                                          </div>
                                        </div>
                                      }

                                      {
                                        CreateKpiData.lable2 !== null && CreateKpiData.lable2 !== "" &&
                                        <div className="col-xs-12 col-sm-6 col-lg-6 col-md-6">
                                          <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12" style={{ color: "#808080a8", paddingBottom: "4px", paddingTop: "4px" }}> Reference 2 </div>
                                          <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                            <div style={{ wordWrap: 'break-word' }}>{CreateKpiData.lable2}</div>
                                          </div>
                                        </div>
                                      }
                                    </div>
                                  </>
                                }
                              </div>
                            </div>
                            {showHideComponent !== "import" &&
                              // <div className='row'>
                              <div className='col-xs-12 col-sm-12 col-lg-12 col-md-12' style={{ backgroundColor: '#fff' }}>
                                <div className={"col-xs-12 col-sm-12 col-lg-6 col-md-6"} style={{ marginLeft: 0, paddingLeft: 0, }} >
                                  {
                                    benchMarkData.length > 0 &&
                                    <div className='pt-sm'>
                                      {/* <span className="pt-sm ml-md" style={{ color: "#808080a8"}}></span> */}
                                      <span className="pt-sm ml-md" style={{ color: "#808080a8" }}> Unit of Measurement : {this.getUnitName(benchMarkData[0].Type)} </span>
                                      <span className="pt-sm ml-md" style={{ color: "#808080a8" }}> Sort Achievement : {this.getSortByName(benchMarkData[0].sortBy)} </span>
                                      <BenchMarkTable benchMarkData={benchMarkData} unit={'view'} sortBy={""} />
                                    </div>
                                  }
                                </div>
                                <div className='col-xs-12 col-sm-12 col-lg-6 col-md-6' style={{ marginLeft: 0, paddingLeft: 0 }}>
                                  {
                                    CreateKpiData.userList.length > 0 ?
                                      <div className='pt-sm pb-sm'>
                                        <span className="pt-sm" style={{ color: "#808080a8" }}>Scope </span>
                                        <AddNewEmployeeInGrid userSelectedData={[]} removeDataFromGrid={() => {/**nosonar*/ }} showHideColumn={'hide'} userList={CreateKpiData.userList} />
                                      </div>
                                      :
                                      <div className='col-xs-12 col-sm-12 col-lg-6 col-md-6' />
                                  }
                                </div>
                                {/* </div> */}
                              </div>
                            }
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    )
                  })
                }
                {
                  showHideComponent !== "import" &&
                  <AccordionSummary style={{ background: "#F2F2F2", paddingLeft: "0px", minHeight: "30px", margin: "-1px", cursor: "auto", height: "32px" }}>
                    <div className="col-xs-12 col-sm-12 col-lg-12" style={{ padding: '0px 94px 0px 12px', textAlign: "right" }}>
                      {((showHideComponent === "draftBusinessOkr" || showHideComponent === "activeBusinessOKR" || showHideComponent !== "draftCentralOKR" || showHideComponent !== "activeCentralOKR") && createObj.kpis.length > addBusinessOkrOkrKPILimit) &&
                        <span style={{ fontSize: 12, color: 'red' }}>OKR can not have more than {addBusinessOkrOkrKPILimit} Key Result(s) {createObj.totalWeightage !== 100 ? "and" : "."} </span>
                      }
                      {((showHideComponent === "draftBusinessOkr" || showHideComponent === "activeBusinessOKR" || showHideComponent !== "draftCentralOKR" || showHideComponent !== "activeCentralOKR") && createObj.totalWeightage !== 100) &&
                        <span style={{ fontSize: 12, color: 'red' }} className='pr-sm'>Total weightage of Key Result(s) should be equal to 100%.</span>
                      }
                      <span className="normal-visivbility-level-obj-kpi">Total : </span><span className="objective-validaty-text" style={{ color: "#36C17C" }} >{createObj.totalWeightage}% </span>
                      {(showHideComponent === "draftBusinessOkr" && createObj.totalWeightage !== 100) && enableAction && <span style={{ padding: '0px 8px 0px 8px', textAlign: "left", color: 'transparent' }}><span>Fill Details</span></span>}
                    </div>
                  </AccordionSummary>
                }
              </Accordion>
            </div>
          )
          )
        }
      </>
    )
  }
  onSortEnd = (e,event) =>{  
    const {orderChangeMasterObjKpiList}=this.state;
    const {employeeId}=this.props;
    var newOrderChangeMasterObjKpiList = arrayMoveImmutable(orderChangeMasterObjKpiList, e.oldIndex, e.newIndex )
    let objIds=[];
    let oldObjIds=[];
    let count =0;
    newOrderChangeMasterObjKpiList?.map((item)=>{ /*NOSONAR*/
      objIds.push(item.userObjectiveId);
    })
    orderChangeMasterObjKpiList?.map((item)=>{ /*NOSONAR*/
      oldObjIds.push(item.userObjectiveId);
    })

    objIds?.forEach((item,inx)=>{
        if(item!==oldObjIds[inx]){
          count+=1;
        }
    })
    if(count>0){
      this.setState({
        orderChangeMasterObjKpiList:newOrderChangeMasterObjKpiList
      },()=>this.props.updateOKROrdersIds({objectiveIds:objIds.toString(), userId:employeeId}))
    }
    document.getElementById('tourGuideTargetElement1').style.backgroundColor='#fff';
    try{
      document.getElementById(this.moveAccordianId).style.cursor='pointer';
      this.moveAccordianId="";
    }catch(error){
      console.log("ðŸš€ ~ file: okrimportobj.jsx ~ line 1128 ~ error", error)
    }
  };
  ToDoItem = ({createObj, indObj}) =>{
  const { showHideComponent, businessUserVariableImpact, centralUserVariableImpact, enableAction} = this.props;
  const { openMenuKPI, openMenu, anchorElKPI, anchorElOBJ, checkedObjectiveDetails,
     showObjectiveEditMenu, showDisableObjectiveMenu} = this.state;
  const isHigherResolution = (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight) > 767;
  let defaultExpObj = 0;  
    return(
      <div id={`createDraft${createObj.userObjectiveId}`} key={`createDraft${typeof createObj.userObjectiveId !== 'undefined' ? createObj.userObjectiveId : createObj.objectiveId} `} className={'sortable-item'}>
              <Accordion
                // defaultExpanded={true}
                defaultExpanded={defaultExpObj === createObj.userObjectiveId && (showHideComponent === "draftBusinessOkr" || showHideComponent === "activeBusinessOKR") ? true : false}
                ref={businessObj => (this.businessObjActive = businessObj)}
                id={`accObj${typeof createObj.userObjectiveId !== 'undefined' ? createObj.userObjectiveId : createObj.objectiveId}`}
                key={`accObj${typeof createObj.userObjectiveId !== 'undefined' ? createObj.userObjectiveId : createObj.objectiveId}`}
                style={{
                  marginTop: indObj === 0 && showHideComponent !== "import" ? "0px" : "16px",
                  // marginBottom: masterObjKpiList.length === indObj + 1 ? "0px" : "16px",
                  marginBottom: indObj === 0 ? "0px" : "16px",
                  boxShadow: "none", position: "initial",
                  cursor:'move'
                  // zIndex:9999
                }}
                expanded={false}
              >
                <AccordionSummary
                  style={{
                    background: "#F2F2F2", paddingLeft: "0px",
                    borderLeft: createObj.disableEnableFlag === "1" ? '4px solid #DBDBDD' : (createObj.isDraft === "0" && createObj.totalWeightage === 100) ? /**NOSONAR */'4px solid #36C17C' : (createObj.isDraft === "0" && createObj.totalWeightage !== 100 && (showHideComponent === "activeBusinessOKR" || showHideComponent === "activeCentralOKR")) ? '4px solid #f47a7a' : createObj.approvalStatus === "Approved" ? '4px solid #36C17C' : (createObj.totalWeightage === 100 && (showHideComponent === "draftBusinessOkr" || showHideComponent === "draftCentralOKR") && createObj.effectiveFrequency !== null && createObj.effectiveMonth !== null && validateKRTotalWeightage(createObj.kpis)) ? "4px solid #48b8e7" : '4px solid #FDD141',
                    minHeight: "30px",
                    height: isHigherResolution ? '' : 40,
                    cursor:'move'
                  }}
                  expandIcon={<ExpandMoreIcon />}
                  aria-label="Expand"
                  aria-controls="additional-actions1-content"
                  id="additional-actions1-header"
                >
                  {showHideComponent !== "activeBusinessOKR" && showHideComponent !== "activeCentralOKR" &&
                    <span key={showHideComponent === "import" && (typeof createObj.userObjectiveId == 'undefined' || createObj.userObjectiveId === null) ? `checkspan${showHideComponent}${createObj.objectiveId}` : `checkspan${showHideComponent}${createObj.userObjectiveId}`} style={{ margin: "8px 6px 10px -32px" }}>
                      <CheckBoxComponent
                        id={showHideComponent === "import" && (typeof createObj.userObjectiveId == 'undefined' || createObj.userObjectiveId === null) ? `objId${showHideComponent}${createObj.objectiveId}` : `objId${showHideComponent}${createObj.userObjectiveId}`}
                        checked={checkedObjectiveDetails.length > 0 ? this.checkIsSelected(checkedObjectiveDetails, createObj.objectiveId) : false}
                        disabled={createObj.totalWeightage === 100 && createObj.effectiveFrequency !== null && createObj.effectiveMonth !== null &&
                          (showHideComponent === "draftBusinessOkr" || showHideComponent === "draftCentralOKR") && createObj.kpis.length <= addBusinessOkrOkrKPILimit ?
                          false : (showHideComponent !== "draftBusinessOkr" && showHideComponent !== "draftCentralOKR") /**NOSONAR */ ?
                            false : true}
                        onChange={(event) => this.checkedObjectiveAndKpi(createObj, event)}
                      />
                    </span>
                  }

                    {showHideComponent === "activeBusinessOKR" &&
                    <FormControlLabel
                      style={{ cursor: "move"}}
                      control={
                        <span style={{ padding: '16px 0px 8px 16px', color: "#F94F5E", marginLeft: 10 }}>
                          <FontAwesomeIcon
                            className="mr-md"
                            icon={faGripDotsVertical}
                            style={{ cursor: "move", fontSize: 14, color: "#000" }}
                          />
                        </span>
                      }
                      onClick={(event) => { event.stopPropagation() }}
                      onFocus={(event) => event.stopPropagation()}
                    />
                    }

                  <div id={createObj.objectiveName} className={showHideComponent === "import" ? "col-xs-12 col-sm-8 col-lg-8 col-md-8 bold-header-obj-kpi" : "col-xs-12 col-sm-8 col-lg-5 col-md-8 bold-header-obj-kpi"} style={{ padding: showHideComponent !== "activeBusinessOKR" && showHideComponent !== "activeCentralOKR" ? "14px 0px 14px 24px" : "14px 0px 14px 0px" }}>
                    {createObj.objectiveName}
                  </div>
                  {
                    showHideComponent === "import" &&
                    (
                      <div className="col-xs-12 col-sm-3 col-lg-4 col-md-4 tags-obj-kpi" style={{ padding: "14px" }}> {createObj.objectiveTags} </div>
                    )
                  }
                  {
                    (showHideComponent === "draftBusinessOkr") && //NOSONAR
                    (
                      <div className="col-xs-12 col-sm-3 col-lg-7 col-md-4 tags-obj-kpi" style={{ padding: "14px 0px 14px 0px", textAlign: 'right' }}>
                        {createObj.reviewFrequency !== "" &&
                          <span className="normal-visivbility-level-obj-kpi">OKR Rating Frequency:&nbsp;
                            <span className="objective-validaty-text">{createObj.reviewFrequency}</span>
                            {(createObj.periodFromHdr !== "") && <span className="normal-visivbility-level-obj-kpi">&nbsp;OKR Validity{(createObj.periodToHdr !== null && createObj.periodToHdr !== "") ? " Start" : ""}:</span>}
                          </span>
                        }

                        <span className="objective-validaty-text" > {createObj.periodFromHdr} </span>

                        {(createObj.periodToHdr !== null && createObj.periodToHdr !== "") && <><span className="normal-visivbility-level-obj-kpi">Ends:</span><span className="objective-validaty-text" > {createObj.periodToHdr} </span></>}

                        {(businessUserVariableImpact !== 0) && <><span className="normal-visivbility-level-obj-kpi">Variable Impact %:</span><span className="objective-validaty-text" >
                          {createObj.variable === null ?/**NOSONAR */ 0 : createObj.variable}% </span></>
                        }
                      </div>
                    )
                  }
                  {
                    showHideComponent === "activeBusinessOKR" && ( //NOSONAR

                      <div className="col-xs-12 col-sm-3 col-lg-7 col-md-4 tags-obj-kpi" style={{ padding: "14px 0px 14px 0px", textAlign: 'right' }}>
                        <span className="normal-visivbility-level-obj-kpi">OKR Rating Frequency:&nbsp;<span className="objective-validaty-text">{createObj.reviewFrequency}
                          <span className="normal-visivbility-level-obj-kpi"> OKR Validity{(createObj.periodToHdr !== null && createObj.periodToHdr !== "") ? " Start" : ""}:</span>
                        </span>
                        </span>
                        <span className="objective-validaty-text" > {createObj.periodFromHdr} </span>
                        {(createObj.periodToHdr !== null && createObj.periodToHdr !== "") && <><span className="normal-visivbility-level-obj-kpi">Ends:</span><span className="objective-validaty-text" > {createObj.periodToHdr} </span></>}
                        {(businessUserVariableImpact !== 0) && <><span className="normal-visivbility-level-obj-kpi">Variable Impact %:</span><span className="objective-validaty-text" > {createObj.variable === null ?/**NOSONAR */ 0 : createObj.variable}% </span></>}
                      </div>
                    )
                  }
                  {
                    showHideComponent === "draftCentralOKR" && //NOSONAR
                    (
                      <div className="col-xs-12 col-sm-3 col-lg-7 col-md-4 tags-obj-kpi" style={{ padding: "14px 14px 14px 14px", textAlign: 'right' }}>
                        <span className="normal-visivbility-level-obj-kpi">OKR Rating Frequency:&nbsp;<span className="objective-validaty-text">{createObj.reviewFrequency}&nbsp;
                          <span className="normal-visivbility-level-obj-kpi"><span className="normal-visivbility-level-obj-kpi">OKR Validity{(createObj.periodToHdr !== null && createObj.periodToHdr !== "") ? " Start" : ""}:</span></span>
                        </span></span>
                        <span className="objective-validaty-text" > {createObj.periodFromHdr} </span>
                        {(createObj.periodToHdr !== null && createObj.periodToHdr !== "") && <><span className="normal-visivbility-level-obj-kpi">Ends:</span><span className="objective-validaty-text" > {createObj.periodToHdr} </span></>}
                        {(centralUserVariableImpact !== 0) && <><span className="normal-visivbility-level-obj-kpi">Variable Impact %:</span><span className="objective-validaty-text pr-lg" > {createObj.variable === null ?/**NOSONAR */ 0 : createObj.variable}% </span></>}
                      </div>
                    )
                  }
                  {
                    showHideComponent === "activeCentralOKR" && ( //NOSONAR
                      <div className="col-xs-12 col-sm-3 col-lg-7 col-md-4 tags-obj-kpi" style={{ padding: "14px 14px 14px 14px", textAlign: 'right' }}>
                        <span className="normal-visivbility-level-obj-kpi">OKR Rating Frequency:&nbsp;<span className="objective-validaty-text">{createObj.reviewFrequency}
                          <span className="normal-visivbility-level-obj-kpi"> OKR Validity{(createObj.periodToHdr !== null && createObj.periodToHdr !== "") ? " Start" : ""}:</span></span></span><span className="objective-validaty-text" > {createObj.periodFromHdr} </span>
                        {(createObj.periodToHdr !== null && createObj.periodToHdr !== "") && <><span className="normal-visivbility-level-obj-kpi">Ends:</span><span className="objective-validaty-text" > {createObj.periodToHdr} </span></>}
                        {(centralUserVariableImpact !== 0) && <><span className="normal-visivbility-level-obj-kpi">Variable Impact %:</span><span className="objective-validaty-text" > {createObj.variable === null ?/**NOSONAR */ 0 : createObj.variable}% </span></>}
                      </div>
                    )
                  }
                  <div className={showHideComponent === "draftBusinessOkr" && (createObj.totalWeightage !== 100 || createObj.effectiveMonth === null) ? "col-xs-12 col-sm-1 col-lg-1 col-md-1" : ''} style={{ padding: showHideComponent === "draftBusinessOkr" ? "0px 0px 0px 6px" : '0px 8px 0px 8px' }}>
                    {
                      showHideComponent === "draftBusinessOkr" && (createObj.totalWeightage !== 100 || createObj.effectiveMonth === null) && enableAction &&
                      <FormControlLabel
                        control={
                          <span style={{ padding: '32px 16px 0px 0px', position: "absolute", color: "#F94F5E", marginLeft: 10, cursor: "move" }} onClick={() => this.fillDetails(createObj, "")}><span className='hoverEffect'> Fill Details </span> </span>
                        }
                        onClick={(event) => { event.stopPropagation() }}
                        onFocus={(event) => event.stopPropagation()}
                      />
                    }
                    <FormControlLabel id={`control${createObj.userObjectiveId}`} style={{ float: "right", cursor: "move", margin: (showHideComponent === "draftBusinessOkr") && (createObj.totalWeightage === 100 && createObj.effectiveMonth !== null) && enableAction ? "0px -4px 0px 0px" : "0px -8px 0px 0px" }}
                      control={
                        <div>
                          {
                            showHideComponent !== "import" &&
                            <>
                              <IconButton
                                id={`menuIcon${createObj.userObjectiveId}`}
                                onClick={(e) => this.openObjectiveMenuPopup(e, createObj, showHideComponent)}
                                disabled={true}
                                style={{ cursor: "move"}}
                                // disabled={!enableAction ||  showHideComponent.includes("activeCentralOKR")}
                              >
                                <MoreVertIcon />
                              </IconButton>
                              <Menu
                                id={`objectId${createObj.userObjectiveId}`}
                                keepMounted
                                anchorEl={anchorElOBJ}
                                open={Boolean(openMenu)}
                                onClose={this.closeObjectMenuePopup}
                                style={{ margin: showHideComponent === "draftCentralOKR" ? "30px 0px 0px -80px" : "60px 0px 0px -80px", top: "25px" }}
                              >
                                {
                                  showHideComponent === "activeBusinessOKR" ? (
                                    <div>
                                      {
                                        <>
                                          <MenuItem key={`objEditMenuItem${createObj.userObjectiveId}`}
                                            disabled={!showObjectiveEditMenu}
                                            onClick={(e) => this.fillActiveObjectiveDetails(createObj, "fromMenu")}>
                                            <span id={`objEditMenuItem${createObj.userObjectiveId}`}>
                                              <span style={{ padding: "8px 8px 8px 0px" }}>
                                                <img
                                                  alt=""
                                                  src={editIcon}
                                                />
                                              </span> <span style={{ padding: "0px 32px 0px 0px" }} >Edit</span>

                                            </span>
                                          </MenuItem>
                                          <MenuItem key={`objDesableMenuItem${createObj.userObjectiveId}`}
                                            disabled={!showDisableObjectiveMenu}
                                            onClick={(e) => { this.openDeletePopUp('disableObj') }}>
                                            <span id={`objDesableMenuItem${createObj.userObjectiveId}`}>
                                              <span style={{ padding: "8px 8px 8px 0px" }}>
                                                <img
                                                  alt=""
                                                  src={deleteIcon}
                                                /></span> <span> Delete </span></span>
                                          </MenuItem>
                                          <MenuItem key={`objDeactiveMenuItem${createObj.userObjectiveId}`}
                                            disabled={showObjectiveEditMenu}
                                            onClick={(e) => this.openDeletePopUp("deactivateObj")}>
                                            <span id={`objDeactiveMenuItem${createObj.userObjectiveId}`}>
                                              <FontAwesomeIcon
                                                className="mr-sm"
                                                id="pointer"
                                                icon={faBan}
                                                style={{ cursor: "pointer", fontSize: 12, marginLeft: "4px", background: "#F4F4F7", color: "red" }}
                                              />
                                              Deactivate</span>
                                          </MenuItem>
                                        </>
                                      }
                                    </div>
                                  ) : (
                                    <div>
                                      <MenuItem key={`editObj${createObj.userObjectiveId}`} onClick={(e) => this.fillDetails(createObj, "fromMenu")}>
                                        <span style={{ padding: "8px 8px 8px 0px" }}>
                                          <img
                                            alt=""
                                            src={editIcon}
                                          />
                                        </span> <span style={{ padding: "0px 32px 0px 0px" }} >Edit</span></MenuItem>
                                      {
                                        showHideComponent !== "draftCentralOKR" && showHideComponent !== "activeCentralOKR" &&
                                        <MenuItem key={`deleteObj${createObj.userObjectiveId}`}
                                          onClick={(e) => this.openDeletePopUp('deleteObj')}
                                        >
                                          <span style={{ padding: "8px 8px 8px 0px" }}>
                                            <img
                                              alt=""
                                              src={deleteIcon}

                                            /></span> <span> Delete </span></MenuItem>
                                      }
                                    </div>
                                  )
                                }
                              </Menu>
                            </>
                          }
                        </div>
                      }
                      onClick={(event) => { event.stopPropagation() }}
                      onFocus={(event) => event.stopPropagation()}
                    />
                  </div>
                </AccordionSummary>
                {
                  showHideComponent === "import" &&
                  <AccordionDetails style={{
                    background: "#8080801c", padding: "0px 0px 8px 0px", borderLeft: createObj.disableEnableFlag === "1" ? '4px solid #DBDBDD' : createObj.isDraft === "0" ? /**NOSONAR */'4px solid #36C17C' : (createObj.approvalStatus === "Pending" && createObj.totalWeightage !== 100) ? '4px solid #FDD141' : createObj.approvalStatus === "Approved" ? '4px solid #36C17C' : createObj.approvalStatus === "Approved" ? '4px solid #36C17C' : (createObj.totalWeightage === 100) ? "4px solid #48b8e7" : '4px solid #F47A7A',
                    minHeight: '30px'
                  }}>
                    <div className="col-xs-12 col-sm-12 col-lg-12" style={{ padding: "0px 16px 0px 16px" }}>
                      <span className="normal-visivbility-level-obj-kpi">Visibility :</span><span className="objective-validaty-text" > {createObj.objectiveVisibility} </span>
                      {
                        createObj.levelName !== "" && createObj.levelName !== null &&
                        <>
                          <span className="normal-visivbility-level-obj-kpi">Level :</span><span className="objective-validaty-text" > {createObj.levelName}</span>
                        </>
                      }
                    </div>
                  </AccordionDetails>
                }
                {/* Kpit Start  */}
                {
                  createObj.kpis.map((CreateKpiData, kpiInd)  => {//NOSONAR
                    let benchMarkData = [];
                    if (CreateKpiData.benchmark !== null && (showHideComponent !== 'import')) {
                      benchMarkData = benchmarkFormatData(CreateKpiData.benchmark);
                    }
                    return (
                      <Accordion defaultExpanded={false} key={`kpiAcc${CreateKpiData.kpiId}`} className="kpi-border-css"
                        style={{ margin: "0px", boxShadow: "none", position: "inherit", backgroundColor: createObj.disableEnableFlag === "1" ? "#8080801c" : "none", height: isHigherResolution ? '' : 35 }}
                        // disabled={createObj.disableEnableFlag === "1" ? true : false}
                        // expanded={(showHideComponent === "activeBusinessOKR") || (showHideComponent === "draftBusinessOkr") || (showHideComponent === "activeCentralOKR") || (showHideComponent === "draftCentralOKR") ? false : true}
                        expanded={false}

                        onClick={() => this.openKrDetailsPopup(CreateKpiData)}
                      >
                        <AccordionSummary style={{ minHeight: "30px" }}
                          expandIcon={<ExpandMoreIcon />}                          
                          aria-label="Expand"
                          aria-controls="additional-actions1-content"
                          id="additional-actions1-header"

                        >
                          {showHideComponent === "import" ?
                            <div className="col-xs-12 col-sm-8 col-lg-8 col-md-8 header-obj-kpi rating-okr-onhover-text"
                              style={{ padding: isHigherResolution ? "14px 14px 14px 3px" : "0px 0px 16px 4px" }}
                              onClick={() => this.openKrDetailsPopup(CreateKpiData)}
                            >
                              {CreateKpiData.kpiName}
                            </div>
                            :
                            <div className="col-xs-12 col-sm-8 col-lg-8 col-md-8 header-obj-kpi rating-okr-onhover-text"
                              style={{ padding: isHigherResolution ? "14px 14px 14px 3px" : "8px 0px 16px 4px" }}
                              onClick={() => this.openKrDetailsPopup(CreateKpiData)}
                            >
                              {CreateKpiData.kpiName}
                            </div>
                          }

                          {
                            showHideComponent === "import" ?
                              (
                                <div className="col-xs-12 col-sm-3 col-lg-4 col-md-4 tags-obj-kpi" style={{ padding: isHigherResolution ? "14px 14px 14px 8px" : "0px 14px 8px 8px" }}> {CreateKpiData.kpiTags} </div>
                              )
                              :
                              (CreateKpiData.varibleLink !== null || CreateKpiData.weightage !== null) && (showHideComponent === "draftBusinessOkr" || showHideComponent === "activeBusinessOKR") ?//NOSONAR
                                (
                                  <div className="col-xs-12 col-sm-3 col-lg-4 col-md-4 tags-obj-kpi" style={{ padding: isHigherResolution ? "14px 8px 14px 8px" : "8px 8px 8px 8px", textAlign: 'right' }}>
                                    {businessUserVariableImpact > 0 && <><span className="normal-visivbility-level-obj-kpi">Variable Link :</span><span className="objective-validaty-text" > {(CreateKpiData.varibleLink === '1' || CreateKpiData.varibleLink === 1) ? //NOSONAR
                                      'Yes' : 'No'} </span></>}
                                    <span className="normal-visivbility-level-obj-kpi">Weightage :</span><span className="objective-validaty-texts" > {CreateKpiData.weightage === null ? 0 : CreateKpiData.weightage//NOSONAR}
                                    }% </span>
                                  </div>
                                )
                                :
                                (CreateKpiData.varibleLink !== null || CreateKpiData.weightage !== null) && (showHideComponent === "draftCentralOKR" || showHideComponent === "activeCentralOKR") ?//NOSONAR
                                  (
                                    <div className="col-xs-12 col-sm-3 col-lg-6 col-md-4 tags-obj-kpi" style={{ padding: isHigherResolution ? "14px 8px 14px 8px" : "8px 8px 8px 8px", textAlign: 'right' }}>
                                      {centralUserVariableImpact > 0 && <><span className="normal-visivbility-level-obj-kpi">Variable Link :</span><span className="objective-validaty-text" > {(CreateKpiData.varibleLink === '1' || CreateKpiData.varibleLink === 1) ? //NOSONAR
                                        'Yes' : 'No'} </span></>}
                                      <span className="normal-visivbility-level-obj-kpi">Weightage :</span><span className="objective-validaty-texts" > {CreateKpiData.weightage === null ? 0 : CreateKpiData.weightage//NOSONAR}
                                      }% </span>
                                    </div>
                                  )
                                  :
                                  <div className="col-xs-12 col-sm-3 col-lg-4 col-md-4 tags-obj-kpi" style={{ padding: "14px 8px 14px 8px", textAlign: 'right' }}></div>
                          }
                          <div className={showHideComponent === "draftBusinessOkr" && createObj.totalWeightage !== 100 ? "col-xs-12 col-sm-1 col-lg-1 col-md-1" : ''} style={{ padding: "0px 8px 0px 0px" }}>
                            {
                              showHideComponent === "draftBusinessOkr" && createObj.totalWeightage !== 100 && enableAction &&
                              <FormControlLabel
                                control={
                                  <span className="form-control-padding" style={{ position: "absolute", color: "#F94F5E", marginLeft: 9 }}
                                    onClick={() => this.fillKPIDetailsFunc(CreateKpiData, "", createObj)}><span className='hoverEffect'> Fill Details </span> </span>
                                }
                                onClick={(event) => { event.stopPropagation() }}
                                onFocus={(event) => event.stopPropagation()}
                              />
                            }
                            {/* <FormControlLabel style={{ margin: showHideComponent === "draftBusinessOkr" ? "0px -13px 0px 0px" : "0px -8px 0px 0px", float: "right" }} */}
                            <FormControlLabel style={{ margin: showHideComponent === "draftBusinessOkr" && createObj.totalWeightage !== 100 && enableAction ? "0px -17px 0px 0px" : "0px -8px 0px 0px", float: "right" }}
                              control={
                                <div>
                                  {
                                    showHideComponent !== "import" &&
                                    <div>
                                      <IconButton
                                        id={`${CreateKpiData.kpiId}`}
                                        onClick={(e) => this.openKPIMenuPopup(e, CreateKpiData, showHideComponent, createObj)}
                                        disabled={createObj.isRatingDone === "Y" || !enableAction}
                                      >
                                        <MoreVertIcon />
                                      </IconButton>
                                      <Menu
                                        id={`kpi${CreateKpiData.kpiId}`}
                                        keepMounted
                                        anchorEl={anchorElKPI}
                                        open={Boolean(openMenuKPI)}
                                        onClose={this.closeKPIMenuPopup}
                                        style={{ margin: "50px 0px 0px -80px" }}
                                      >
                                        <MenuItem key={`kpiEditMenuItem${CreateKpiData.kpiId}`}
                                          onClick={(e) => this.fillKPIDetailsFunc(CreateKpiData, 'fromMenu', createObj)}>
                                          <span style={{ padding: "8px 8px 8px 0px" }}>
                                            <img
                                              alt=""
                                              src={editIcon}

                                            />
                                          </span> <span style={{ padding: "0px 32px 0px 0px" }}>Edit</span></MenuItem>
                                        {showHideComponent !== "activeBusinessOKR" && showHideComponent !== "activeCentralOKR" && //showHideComponent !== "draftCentralOKR" &&
                                          <MenuItem key={`kpiDeleteMenuItem${CreateKpiData.kpiId}`}
                                            onClick={(e) => this.openDeletePopUp('deleteKpi')}
                                          >
                                            <span style={{ padding: "8px 8px 8px 0px" }}>
                                              <img
                                                alt=""
                                                src={deleteIcon}
                                              /></span> <span> Delete </span>
                                          </MenuItem>
                                        }
                                      </Menu>
                                    </div>
                                  }
                                </div>
                              }
                              onClick={(event) => event.stopPropagation()}
                              onFocus={(event) => event.stopPropagation()}
                            />
                          </div>
                        </AccordionSummary>
                        {
                          showHideComponent !== "import" && //showHideComponent !== "draftCentralOKR" && showHideComponent !=="activeCentralOKR" &&
                          <AccordionDetails style={{ backgroundColor: createObj.disableEnableFlag === "1" ? "#80808000" : "#ffff", padding: "0px 0px 8px 0px", minHeight: '30px' }}>
                            <div className="col-xs-12 col-sm-12 col-lg-12" style={{ padding: "0px 18px 0px 18px" }}>
                              {/* <span className="normal-visivbility-level-obj-kpi">Benchmark For Yes : </span><span className="objective-validaty-text" >{CreateKpiData.benchmark} </span> */}
                              {
                                createObj.scopeTypeId !== "" && createObj.scopeTypeId !== null &&
                                <>
                                  <span className="normal-visivbility-level-obj-kpi">Scope : </span><span className="objective-validaty-text" >{this.getFrequencyName('scope', CreateKpiData.scopeTypeId)}</span>
                                </>
                              }
                            </div>
                          </AccordionDetails>
                        }
                        <AccordionDetails style={{ background: "rgb(72 184 231 / 5%)", padding: "8px 18px 8px 18px" }}>
                          <div className="row">
                            {
                              CreateKpiData.kpiAttachment !== "" && CreateKpiData.kpiAttachment !== null &&
                              <div className="col-xs-12 col-sm-12 col-lg-12" style={{ padding: "8px 14px 14px 14px" }}>
                                {this.createDraftimageFilepath(CreateKpiData.kpiAttachment)}
                              </div>
                            }
                            <div className='col-xs-12 col-sm-12 col-lg-12 col-md-12'>
                              <div className={'col-xs-12 col-sm-12 col-lg-6 col-md-6'} style={{ marginLeft: 0, paddingLeft: 0 }}>
                                <div className="col-xs-12 col-sm-12 col-lg-12" style={{ color: "#808080a8", paddingBottom: "4px" }}> Description </div>
                                <div className="col-xs-12 col-sm-12 col-lg-12" style={{}}>
                                  {ReactHtmlParser(CreateKpiData.description)}
                                </div>
                              </div>
                              <div className={'col-xs-12 col-sm-12 col-lg-6 col-md-6'} style={{ marginLeft: -15, paddingLeft: 0 }}>
                                {
                                  showHideComponent !== "import" &&
                                  <>
                                    <div className='col-xs-12 col-sm-12 col-lg-12 col-md-12'>
                                      {
                                        CreateKpiData.lable1 !== null && CreateKpiData.lable1 !== "" &&
                                        <div className="col-xs-12 col-sm-6 col-lg-6 col-md-6" >
                                          <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12" style={{ color: "#808080a8", paddingBottom: "4px", paddingTop: "4px" }}> Reference 1 </div>
                                          <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                            <div style={{ wordWrap: 'break-word' }}>{CreateKpiData.lable1}</div>
                                          </div>
                                        </div>
                                      }

                                      {
                                        CreateKpiData.lable2 !== null && CreateKpiData.lable2 !== "" &&
                                        <div className="col-xs-12 col-sm-6 col-lg-6 col-md-6">
                                          <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12" style={{ color: "#808080a8", paddingBottom: "4px", paddingTop: "4px" }}> Reference 2 </div>
                                          <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                            <div style={{ wordWrap: 'break-word' }}>{CreateKpiData.lable2}</div>
                                          </div>
                                        </div>
                                      }
                                    </div>
                                  </>
                                }
                              </div>
                            </div>
                            {showHideComponent !== "import" &&
                              // <div className='row'>
                              <div className='col-xs-12 col-sm-12 col-lg-12 col-md-12' style={{ backgroundColor: '#fff' }}>
                                <div className={"col-xs-12 col-sm-12 col-lg-6 col-md-6"} style={{ marginLeft: 0, paddingLeft: 0, }} >
                                  {
                                    benchMarkData.length > 0 &&
                                    <div className='pt-sm'>
                                      {/* <span className="pt-sm ml-md" style={{ color: "#808080a8"}}></span> */}
                                      <span className="pt-sm ml-md" style={{ color: "#808080a8" }}> Unit of Measurement : {this.getUnitName(benchMarkData[0].Type)} </span>
                                      <span className="pt-sm ml-md" style={{ color: "#808080a8" }}> Sort Achievement : {this.getSortByName(benchMarkData[0].sortBy)} </span>
                                      <BenchMarkTable benchMarkData={benchMarkData} unit={'view'} sortBy={""} />
                                    </div>
                                  }
                                </div>
                                <div className='col-xs-12 col-sm-12 col-lg-6 col-md-6' style={{ marginLeft: 0, paddingLeft: 0 }}>
                                  {
                                    CreateKpiData.userList.length > 0 ?
                                      <div className='pt-sm pb-sm'>
                                        <span className="pt-sm" style={{ color: "#808080a8" }}>Scope </span>
                                        <AddNewEmployeeInGrid userSelectedData={[]} removeDataFromGrid={() => {/**nosonar*/ }} showHideColumn={'hide'} userList={CreateKpiData.userList} />
                                      </div>
                                      :
                                      <div className='col-xs-12 col-sm-12 col-lg-6 col-md-6' />
                                  }
                                </div>
                                {/* </div> */}
                              </div>
                            }
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    )
                  })
                }
                {
                  showHideComponent !== "import" &&
                  <AccordionSummary style={{ background: "#F2F2F2", paddingLeft: "0px", minHeight: "30px", margin: "-1px", cursor: "auto", height: "32px" }}>
                    <div className="col-xs-12 col-sm-12 col-lg-12" style={{ padding: '0px 94px 0px 12px', textAlign: "right" }}>
                      {((showHideComponent === "draftBusinessOkr" || showHideComponent === "activeBusinessOKR" || showHideComponent !== "draftCentralOKR" || showHideComponent !== "activeCentralOKR") && createObj.kpis.length > addBusinessOkrOkrKPILimit) &&
                        <span style={{ fontSize: 12, color: 'red' }}>OKR can not have more than {addBusinessOkrOkrKPILimit} Key Result(s) {createObj.totalWeightage !== 100 ? "and" : "."} </span>
                      }
                      {((showHideComponent === "draftBusinessOkr" || showHideComponent === "activeBusinessOKR" || showHideComponent !== "draftCentralOKR" || showHideComponent !== "activeCentralOKR") && createObj.totalWeightage !== 100) &&
                        <span style={{ fontSize: 12, color: 'red' }} className='pr-sm'>Total weightage of Key Result(s) should be equal to 100%.</span>
                      }
                      <span className="normal-visivbility-level-obj-kpi">Total : </span><span className="objective-validaty-text" style={{ color: "#36C17C" }} >{createObj.totalWeightage}% </span>
                      {(showHideComponent === "draftBusinessOkr" && createObj.totalWeightage !== 100) && enableAction && <span style={{ padding: '0px 8px 0px 8px', textAlign: "left", color: 'transparent' }}><span>Fill Details</span></span>}
                    </div>
                  </AccordionSummary>
                }
              </Accordion>
            </div>
    )
  }
  toDoList = ({orderChangeMasterObjKpiList})=>{
  const SortableItem = SortableElement(this.ToDoItem) 
    return (
      <div className="sortable-container">
          {orderChangeMasterObjKpiList.map((createObj, indObj)=>{
              return <SortableItem 
                  createObj={createObj}
                  indObj={indObj}
                  index={indObj}
                  key={`createDraft${typeof createObj.userObjectiveId !== 'undefined' ? createObj.userObjectiveId : createObj.objectiveId}`}
              />
          })}
      </div>
    )    
  }
  onSortMove = ()=> {
    try{
      document.getElementById(this.moveAccordianId).style.cursor="move";
    }catch(e){}
  }
  
  onSortStart = (event)=>{
  try{
    this.moveAccordianId= event.helper.attributes[0].value;
    document.getElementById(event.helper.attributes[0].value).style.cursor="move";
    document.getElementById('tourGuideTargetElement1').style.backgroundColor='#f7f7f7';
  }catch(e){}
}
onSortOver = ()=>{
  try{
    document.getElementById(this.moveAccordianId).style.cursor="move";
  }catch(e){}
  }

  render() { //NOSONAR
    const { masterObjKpiList, showHideComponent, lastSubmittedBusinessOkrObjId, sortByFlag } = this.props;
																																		 
    const { fillDataPopup, fillKPIDetailsFlag, viewKrDetailsPopup, KRData, openType, openFancyConfirmPopUp, orderChangeMasterObjKpiList } = this.state;
																																   
							
      // eslint-disable-next-line
      masterObjKpiList.map((createObj)=>{
        if (lastSubmittedBusinessOkrObjId === createObj.userObjectiveId) {
														
          this.updateLastSubmittedBusinessObj();
        }
      })
    const SortableList = SortableContainer(this.toDoList);
    return (
      <div id='tourGuideTargetElement1' >
        <style>{cssForScroll}</style>
          {!sortByFlag &&this.renderAccordions()}          
										   
		  
          {showHideComponent === 'activeBusinessOKR' &&
          sortByFlag  && 
          <center>
            <div className="pb-xs" style={{color: "#EB5465", fontSize:14, paddingTop: "1rem" }}>Note: OKR Objective sorting is currently in Edit mode. Please click on Sort icon once you have completed the OKR Objective(s) sorting to save your OKR sequence.</div>
          </center>
          }
          {showHideComponent === 'activeBusinessOKR' &&
          sortByFlag  && 
          <SortableList 
          helperClass="draggable-item"
          orderChangeMasterObjKpiList={orderChangeMasterObjKpiList} 
          onSortEnd={this.onSortEnd} 
          distance={10} 
          // lockAxis="y" 
          transitionDuration={0}
          rowCount={orderChangeMasterObjKpiList.length}
          onSortMove={this.onSortMove}
          onSortStart={this.onSortStart}
          onSortOver={this.onSortOver}
          // lockToContainerEdges={true}
          hideSortableGhost={true}
          />}
        <div>
          <Dialog
            open={fillDataPopup}
            fullWidth
            maxWidth="lg"
          >
            <DialogContent>
              <div>
                <div>
                  <span className="add-new-self-header" style={{ paddingLeft: "8px", fontWeight: "bold", fontSize: 14 }}>
                    {this.setObjectiveTitle()}
                  </span>
                  <OKRObjEditDetails closeCreateObj={() => this.closeFillDetails()} showHideComponent={showHideComponent} openType={openType} />
                </div>
              </div>
            </DialogContent>
          </Dialog>
        </div>
        <div>
          {
            fillKPIDetailsFlag &&
            <OKRKpiEditDetails closeFillKPIDetails={() => this.closeFillKPIDetails()} open={fillKPIDetailsFlag} showHideComponent={showHideComponent} />
          }
        </div>
        <ConfirmationPopup
          open={openFancyConfirmPopUp}
          headerMessage={this.objKpiDeletetitle}
          subMessage={this.objKpiDeletetitleMessage}
          button1Text={"Yes"}
          button2Text={"No"}
          showButton={{ type: "1" }}
          button1function={this.handleDeleteYes}
          button2function={this.handleDeleteNo}
          handleConfirmClose={this.handleDeleteNo}


        />
        {
          viewKrDetailsPopup &&
          <ViewKRDetailsModal
            closeKrDetailsPopup={this.closeKrDetailsPopup}
            OKRType={""}
            ratingTabs={""}
            ratingType={""}
            KRData={KRData}
          />
        }
      </div>
    );
  }
}

NewImportObjectiveView.propTypes = {
  // eslint-disable-next-line
  masterObjKpiList: PropTypes.arrayOf(object),
  expandAllPopupOBJ: PropTypes.bool,
  // eslint-disable-next-line
  importOKRIds: PropTypes.arrayOf(object),
  draftedBusinessObjKpi: PropTypes.arrayOf(object),
  setCentralOkrKPIRedux: PropTypes.func.isRequired,
  employeeId: PropTypes.number,
  OkrMasters: PropTypes.arrayOf(object),
  // eslint-disable-next-line
  allObjId: PropTypes.arrayOf(object),
  setCheckedOpjective: PropTypes.func,
  updateimportStatus: PropTypes.string,
  // updateBuActiveFlag: PropTypes.string,
  authUser: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disableEnableFlag: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  objId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  businessUserVariableImpact: PropTypes.number,
  lastSubmittedBusinessOkrObjId: PropTypes.number,
  resetCheckedData: PropTypes.string,
  centralUserVariableImpact: PropTypes.number,
};
NewImportObjectiveView.defaultProps = {
  masterObjKpiList: [],
  expandAllPopupOBJ: false,
  importOKRIds: [],
  employeeId: 0,
  allObjId: [],
  updateimportStatus: '',
  // updateBuActiveFlag: '',
  disableEnableFlag: '',
  authUser: 0,
  objId: 0,
  OkrMasters: [],
  businessUserVariableImpact: 0,
  centralUserVariableImpact: 0,
  lastSubmittedBusinessOkrObjId: 0,
  resetCheckedData: '',
};
const mapStateToProps = ({ auth, okrreducer, meetingmaster }) => {
  const { authUser } = auth;
  const { othersCheckAvailablity } = meetingmaster;
  const { allOkrKpi, allObjId, importOKRIds, employeeId, draftedBusinessObjKpi, disableEnableFlag, objId, OkrMasters, updateimportStatus,
    businessUserVariableImpact, fillKpi, fillObjective, lastSubmittedBusinessOkrObjId, resetCheckedData, centralUserVariableImpact, editedObjectiveId, fyid, sortByFlag } = okrreducer;
  return {
    authUser, allOkrKpi, allObjId, importOKRIds, employeeId, draftedBusinessObjKpi, updateimportStatus,
    disableEnableFlag, objId, OkrMasters, businessUserVariableImpact, fillKpi, fillObjective, lastSubmittedBusinessOkrObjId,
    resetCheckedData, centralUserVariableImpact, othersCheckAvailablity, editedObjectiveId, fyid, sortByFlag
  };
};
export default connect(mapStateToProps, { setCentralOkrKPIRedux, addUpdateObjectveKpi, submitApprovalAction, fillObjectiveRequest, updateOKROrdersIds })(
  NewImportObjectiveView
);