import React, { useEffect } from "react";
import { connect } from "react-redux";
import {
  updateLnAData,
  applyLeave,
  addNotification,
} from "../../../../redux/actions/lna/lnaaction";
import ErrorMessage from "../../../errormessage/errormessage";
import { MentionComponent } from "@syncfusion/ej2-react-dropdowns";
import LnAService from "../../../../services/lna/lnaservice";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import ProgressBar from "../../../progressbar/progressbar";
import ConfirmationPopup from "../../../../utilities/confirmationpopup";
import {
  Announce,
  Follow,
  TagForLeaveAnnounce,
  TagForLeaveFollow,
} from "../../../../assets/constants/constants.js";

const sampleCss = `

#commentsMention_popup .e-nodata {
  margin-left: 164px !important;
  margin-top: -12px !important;
}
#commentsMention:focus {
  border-color: #f94f5e; /* Change the border color on focus to blue (adjust as needed) */
  outline: 2px solid #f94f5e; /* Add an outline on focus (adjust as needed) */
  outline-offset: -2px; /* 
}
`;

export const LeaveNotifiersList = (props) => {
  const { user, othersCheckAvailablity, notificationTo, authUser, applyFor } =
    props;
  const [state, setState] = React.useState({
    appliedDays: 0,
    selectedUserIds: [],
    addTempUserId: [],
    applyedLeaveDates: [],
    notificationData: [],
    subscriptionData: [],
    containerHeight: null,
    innercontainerHeight: null,
    gridHeight: null,
    showList: false,
    showDayBreakup: false,
    minStartDate: null,
    maxStartDate: null,
    minEndDate: null,
    maxEndDate: null,
    newSelectedUserIds: null,
    selectedTab: "notificationList",
    isLoading: true,
    showConform: false,
    selectedUserId: 0,
    employeeData: [],
  });
  const isThirteenHundred = window.innerHeight < 658;

  useEffect(() => {
    setState((preState) => ({ ...preState, isLoading: true }));
    window.addEventListener("resize", updateDimensions);
    if (notificationTo !== null && notificationTo !== "") {
      let preSelectedIds = notificationTo.split(",");
      let preSelectedIdsArr = [];
      othersCheckAvailablity &&
        othersCheckAvailablity.forEach((trimnameWithDepartment) => {
          preSelectedIds &&
            preSelectedIds.forEach((id) => {
              if (trimnameWithDepartment.UserId === parseInt(id)) {
                preSelectedIdsArr.push({
                  id: trimnameWithDepartment.UserId,
                  name: trimnameWithDepartment.name,
                  department: trimnameWithDepartment.Department,
                });
              }
            });
        });
      setState((preState) => ({
        ...preState,
        selectedUserId: preSelectedIdsArr,
        employeeData: othersCheckAvailablity.filter(
          (employee) => employee.UserId !== 577
        ),
      }));
      props.updateLnAData({ notificationToArr: preSelectedIdsArr });
    } else {
      setState((preState) => ({
        ...preState,
        employeeData: othersCheckAvailablity.filter(
          (employee) => employee.UserId !== 577
        ),
      }));
    }
  }, []);

  useEffect(() => {
    window.addEventListener("resize", updateDimensions()); // NOSONAR
  }, []);

  useEffect(() => {
    setState((preState) => ({ ...preState, isLoading: true }));
    getMasterData();
    setTimeout(() => {
      try {
        document.getElementById("commentsMention").innerText = "";
      } catch (error) { }
    }, 1000);
  }, [props.notification === "added", user, applyFor]);

  const getMasterData = async () => {
    const onNotificationData = await LnAService.getNotificationData(
      "NOTIFICATION",
      user ? user : applyFor ? applyFor : authUser
    );
    const onNotificationDataList = onNotificationData.Result;
    const onSubscriptionData = await LnAService.getNotificationData(
      "SUBSCRIPTION",
      user ? user : applyFor ? applyFor : authUser
    );
    const onSubscriptionDataList = onSubscriptionData.Result;
    setState((prevState) => ({
      ...prevState,
      isLoading: false,
      notificationData: onNotificationDataList,
      subscriptionData: onSubscriptionDataList,
    }));
  };

  const updateDimensions = () => {
    const containerHeight = window.innerHeight - 248;
    const innercontainerHeight = window.innerHeight - 285;
    const gridHeight = window.innerHeight - 415;
    setState((prevState) => ({
      ...prevState,
      containerHeight: containerHeight,
      innercontainerHeight: innercontainerHeight,
      gridHeight: gridHeight,
    }));
  };
  useEffect(() => {
    let data = {
      appliedDays: 0,
      applyedLeaveDates: [],
      dayBreakupDates: [],
    };
    setState((preState) => ({ ...preState, ...data }));
  }, [props.leaveApplyStatus]);

  const getSelectedAttendee = async (e) => {
    if (state.selectedTab === "notificationList") {
      await props.addNotification({
        userId: user ? user : authUser,
        notifiedToId: e.itemData.UserId,
        subscribed: "0",
      });
    } else {
      await props.addNotification({
        userId: e.itemData.UserId,
        notifiedToId: user ? user : authUser,
        subscribed: "1",
      });
    }
  };
  function getNodeCollection() {
    var range = window.getSelection().getRangeAt(0);
    const endNode =
      range.endContainer.childNodes[
      range.endOffset > 0 ? range.endOffset - 1 : range.endOffset
      ] || range.endContainer;
    if (endNode.nodeName == "SPAN") {
      // NOSONAR
    } else if (
      endNode.previousSibling != null &&
      endNode.previousSibling.nodeName == "#text" &&
      endNode.previousElementSibling != null
    ) {
      let originalstr = endNode.previousElementSibling.innerText; // NOSONAR
    } else if (endNode.previousElementSibling) {
      let originalstr = endNode.innerText; // NOSONAR
    }
  }

  function created() {
    document
      .getElementById("commentsMention")
      .addEventListener("keydown", function (e) {
        if (e.key == "Backspace") {
          var off = window.getSelection().anchorOffset;
          var anchorNode = window.getSelection().anchorNode.childNodes[off];

          if (anchorNode == undefined) {
            getNodeCollection();
          } else if (anchorNode.tagName == "SPAN" || anchorNode.nodeName == "#text") {
            var a = anchorNode.previousElementSibling;
            var originalString1 = a.innerText; // NOSONAR
          } else if ((window.getSelection().anchorOffset == 0)) {
            var b = window.getSelection().anchorNode.previousElementSibling;
            var originalString2 = b.innerText; // NOSONAR
          }
        }
      });
  }

  const removeItem = async () => {
    const result = await LnAService.removeNotificationData(
      state.selectedUserId
    );
    if (result.Result[0].result === "success") {
      getMasterData();
    }
    handleClose();
  };

  const removeItemConfirmation = async (notifierListId) => {
    setState((preState) => ({
      ...preState,
      showConform: true,
      selectedUserId: notifierListId,
    }));
  };

  useEffect(() => {
    document.getElementById("commentsMention").innerText = "";
  }, [state.selectedTab]);

  const handleClose = () => {
    setState((preState) => ({ ...preState, showConform: false }));
  };
  let commentTarget = "#commentsMention";
  const { isLoading } = state;
  return (
    <div
      style={{
        height: state.containerHeight,
        backgroundColor: "#FFFFFF",
      }}
      className="col-lg-12 col-md-12 col-sm-12 col-xs-12 leave-NotifiersList-body"
    >
      <style>{sampleCss}</style>
      <div className="row1">
        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              borderBottom: "1px solid #ccc",
            }}
          >
            <div
              id="tabOnLeave"
              type="submit"
              className={` smallResolutionText leave-balance-individual ${state.selectedTab === "notificationList" ? "active-leave" : ""
                }`}
              onClick={(e) =>
                setState({ ...state, selectedTab: "notificationList" })
              }
              onKeyDown={(event) => {
                if (event.key === 'Enter' || event.key === ' ') { // Check if Enter or Spacebar is pressed
                  setState({ ...state, selectedTab: "notificationList" })
                }
              }}
              style={{ textAlign: "center", cursor: "pointer" }}
            >
              {Announce}
            </div>
            <div
              id="tabOnLeave"
              type="submit"
              className={` smallResolutionText leave-balance-individual ${state.selectedTab === "subscriptionList" ? "active-leave" : ""
                }`}
              onClick={(e) =>
                setState({ ...state, selectedTab: "subscriptionList" })
              }
              onKeyDown={(event) => {
                if (event.key === 'Enter' || event.key === ' ') { // Check if Enter or Spacebar is pressed
                  setState({ ...state, selectedTab: "subscriptionList" })
                }
              }}
              style={{ textAlign: "center", cursor: "pointer" }}
            >
              {Follow}
            </div>
          </div>
        </div>

        <div
          className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
          style={{ height: state.innercontainerHeight, overflowY: "auto" }}
        >
          <div
            className="col-xs-12 col-sm-12 col-lg-12 col-md-12 pt-sm"
            style={{
              marginLeft: "0px",
              paddingTop: "15px",
              paddingBottom: "15px",
              paddingLeft: "12px",
              paddingRight: "12px",
            }}
          >
            <div className="content-wrapper">
              <div id="mention_default">
                <label className="default-size" style={{ color: "black" }}>
                  {state.selectedTab === "notificationList"
                    ? `${TagForLeaveAnnounce}`
                    : `${TagForLeaveFollow}`}{" "}
                </label>
                <div
                  id="commentsMention"
                  // placeholder="Type @ and select your comments"
                  style={{
                    // minHeight: 40,
                    // maxHeight: 40,
                    border: "1px solid #D7D7D7",
                    borderRadius: 4,
                    padding: 8,
                    fontSize: 14,
                    width: "65%",
                  }}
                ></div>
                <MentionComponent
                  // id='mentionTeamMember'
                  dataSource={state.employeeData}
                  allowSpaces={true}
                  target={commentTarget}
                  created={created}
                  fields={{
                    text: "name",
                    value: "UserId",
                    groupBy: "Department",
                  }}
                  cssClass="e-outline"
                  // filtering={handleFilteringByName}
                  change={getSelectedAttendee}
                  // getItems={getItems}
                  mentionChar={"@"}
                ></MentionComponent>
              </div>
            </div>
          </div>

          <div className="row1">
            <div
              className="col-lg-12 col-md-12 col-sm-12"
              style={{
                height: "38px",
                backgroundColor: "#F6F6F6",
                color: "black",
                fontStyle: "Circular Std Book",
                fontFamily: "Arial",
                fontWeight: "bold",
                marginRight: "0px",
                marginLeft: "0px",
              }}
            >
              {state.selectedTab === "subscriptionList" ? (
                <>
                  <div className="col-lg-4 col-md-4 col-sm-4 pt-sm ">Name</div>
                  <div className="col-lg-6 col-md-6 col-sm-6 pt-sm">
                    {" "}
                    Team Name
                  </div>
                  <div className="col-lg-2 col-md-2 col-sm-2 pt-sm">
                    Action
                  </div>{" "}
                </>
              ) : (
                <>
                  <div className="col-lg-4 col-md-4 col-sm-4 pt-sm">Name</div>
                  <div className="col-lg-4 col-md-4 col-sm-4 pt-sm">
                    {" "}
                    Team Name
                  </div>
                  <div
                    style={{
                      height: "50px",
                      marginLeft: isThirteenHundred ? "-8px" : "0px",
                      marginRight: isThirteenHundred ? "5px" : "0px",
                    }}
                    className="col-lg-2 col-md-2 col-sm-2 pt-sm "
                  >
                    {/* {" "}
                    Notifications */}
                  </div>
                  <div className="col-lg-2 col-md-2 col-sm-2 pt-sm">Action</div>
                </>
              )}
            </div>

            <div
              style={{
                height: state.gridHeight,
                overflowY: "auto",
                width: "100%",
              }}
            >
              {!isLoading &&
                state.selectedTab === "notificationList" &&
                state.notificationData &&
                state.notificationData.length > 0 &&
                state.notificationData.map((item, inx) => {
                  return (
                    <div
                      className="col-lg-12 col-md-12 col-sm-12"
                      style={{
                        height: "40px",
                        border: "1px solid #F6F6F6",
                        color: "black",
                      }}
                      key={"selectedUserIdedit" + inx}
                    >
                      <div className="col-lg-4 col-md-4 col-sm-4 pt-sm">
                        {item.tmName.length > 10 ? (
                          <TooltipComponent
                            position="BottomCenter"
                            content={item.tmName}
                            isSticky={false}
                            cssClass="customtooltip"
                          >
                            <div
                              style={{
                                fontSize: "14px",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                              }}
                            >
                              {item.tmName}
                            </div>
                          </TooltipComponent>
                        ) : (
                          item.tmName
                        )}
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-4 pt-sm">
                        {item.department.length > 14 ? (
                          <TooltipComponent
                            position="BottomCenter"
                            content={item.department}
                            isSticky={false}
                            cssClass="customtooltip"
                          >
                            <div
                              style={{
                                fontSize: "14px",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                              }}
                            >
                              {item.department}
                            </div>
                          </TooltipComponent>
                        ) : (
                          item.department
                        )}
                      </div>
                      <div className="col-lg-2 col-md-2 col-sm-2 pt-sm">
                        {/* {item.subscribed === "0" ? "Notify" : "Subscribed"} */}
                      </div>
                      <div className="col-lg-2 col-md-2 col-sm-2 pt-sm ">
                        {item.removeAccess === "Y" && (
                          <div
                            style={{ color: "#F94F5E", cursor: "pointer" }}
                            className="pointer-cursor"
                            onClick={() => {
                              removeItemConfirmation(item.notifierListId);
                            }}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                removeItemConfirmation(item.notifierListId);
                              }
                            }}
                            role="button"
                            tabIndex="0"
                          >Remove</div>
                        )}
                      </div>
                    </div>
                  );
                })}
              {!isLoading &&
                state.selectedTab === "subscriptionList" &&
                state.subscriptionData.length > 0 &&
                state.subscriptionData.map((item, inx) => {
                  return (
                    <div
                      className="col-lg-12 col-md-12 col-sm-12"
                      style={{
                        height: "40px",
                        border: "1px solid #F6F6F6",
                        color: "black",
                      }}
                      key={"selectedUserIdedit" + inx}
                    >
                      <div className="col-lg-4 col-md-4 col-sm-4 pt-sm">
                        {item.tmName.length > 10 ? (
                          <TooltipComponent
                            position="BottomCenter"
                            content={item.tmName}
                            isSticky={false}
                            cssClass="customtooltip"
                          >
                            <div
                              style={{
                                fontSize: "14px",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                              }}
                            >
                              {item.tmName}
                            </div>
                          </TooltipComponent>
                        ) : (
                          item.tmName
                        )}
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 pt-sm">
                        {item.department.length > 20 ? (
                          <TooltipComponent
                            position="BottomCenter"
                            className="pointer-cursor"
                            isSticky={false}
                            content={item.department}
                            cssClass="customtooltip"
                          >
                            <div
                              style={{
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                fontSize: "14px",
                              }}
                            >
                              {item.department}
                            </div>
                          </TooltipComponent>
                        ) : (
                          item.department
                        )}
                      </div>

                      <div className="col-lg-2 col-md-2 col-sm-2 pt-sm ">
                        {item.removeAccess === "Y" && (
                          <div
                            style={{ color: "#F94F5E", cursor: "pointer" }}
                            className="pointer-cursor"
                            onClick={() => {
                              removeItemConfirmation(item.notifierListId);
                            }}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                removeItemConfirmation(item.notifierListId);
                              }
                            }}
                            role="button"
                            tabIndex="0"
                          >Remove</div>
                        )}
                      </div>
                    </div>
                  );
                })}

              {isLoading && (
                <div
                  style={{
                    zIndex: 999999,
                    position: "absolute",
                    marginTop: "20%",
                    marginLeft: "45%",
                  }}
                >
                  <ProgressBar />
                </div>
              )}

              {!isLoading &&
                ((state.selectedTab === "notificationList" &&
                  state.notificationData &&
                  state.notificationData.length === 0) ||
                  (state.selectedTab === "subscriptionList" &&
                    state.subscriptionData &&
                    state.subscriptionData.length === 0)) && (
                  <div
                    className="col-lg-12 col-md-12 col-sm-12"
                    style={{
                      height: "54px",
                      borderBottom: "0px solid #F6F6F6",
                      marginTop: 20,
                    }}
                  >
                    <ErrorMessage message="No Data Available." />
                  </div>
                )}
            </div>
          </div>
        </div>
      </div>
      <ConfirmationPopup
        showButton={{ type: "1" }}
        open={state.showConform}
        button1function={() => removeItem()}
        button2function={() => handleClose()}
        headerMessage={"Are you sure, you  want to remove ?"}
        button1Text={"Yes"}
        button2Text={"No"}
        handleConfirmClose={handleClose}
      />
      {/* <div className="pr-sm pt-sm" style={{ float: "right" }}>
        <button
          className="leave-cancel-apply-button"
          onClick={() => props.updateLnAData({ leaveApplyLoadName: 'leave-tabs' })}
        >
          Close
        </button>
      </div> */}
    </div>
  );
};
LeaveNotifiersList.propTypes = {
  //   second: PropTypes.third
};

const mapStateToProps = ({
  meetingcategory,
  lnareducer,
  auth,
  meetingmaster,
}) => {
  const { authUser } = auth;
  const { categoryName } = meetingcategory;
  const { notificationTo, notification, applyFor } = lnareducer;
  const { othersCheckAvailablity } = meetingmaster;
  return {
    authUser,
    categoryName,
    othersCheckAvailablity,
    notificationTo,
    notification,
    applyFor,
  };
};

export default connect(mapStateToProps, {
  updateLnAData,
  applyLeave,
  addNotification,
})(LeaveNotifiersList);
