/**
 * Author Name :- Prashant Waphare
 * Date :28-05-2020
 * Description :- This file use for Hr Clearance employee list.
 */
import * as React from "react";
import { closest, isNullOrUndefined } from "@syncfusion/ej2-base";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Filter,
  Inject,
  VirtualScroll,
  Sort,
  Page,
} from "@syncfusion/ej2-react-grids";
import "../../../app/containers/tmlc/tmlc.css";
import "../resignationcomponent/resignationcomponent.css";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { DateFilterformat } from '../../../../src/config/config';
import EmployeeNameTemplate from "../employeeNameTemplate";
import { AllDateFormat, FinanceTemp, ClearanceStatus, SelfDepartment, ItDepartment } from "../allcommoncomponents";
import { encrypt,encodeString } from "../../../app/other/commonfunction";
import { setMeetingCategory } from '../../../redux/actions/meetingcategoryaction';
import { EMPLOYEE_PROFILE } from "../../../redux/constant/meetingcategoryconstant";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import {
  getGridColumn,
  EMPLOYEE_NAME,
  CLEARANCE_STATUS,
  SELF_DEPARTMENT,
  IT,
  FINANCE,
  JOINING_DATE,
  // LWD_COMPANY_REQUEST,
  DATE_OF_RESIGNATION,
  LWD_OFFICIAL,
  INFOSEC,
  ADMIN,
  SOFTWARE,
  HR,
  REASON,
  LEGAL_ASSET,
  MIS_ASSET,
  CONTACT_ASSET
} from "../../../app/other/gridcolumns";

class HrClearanceGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: null,
    };
    this.height =
      (window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight) - 252;
    this.width =
      (window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth) - 100;
    this.dReady = false;
    this.dtTime = false;
    this.isDataBound = false;
    this.isDataChanged = true;
    this.dropSlectedIndex = null;
    this.check = {
      type: "CheckBox",
    };
    this.select = {
      persistSelection: true,
      type: "Multiple",
      checkboxOnly: true,
    };
    this.Filter = {
      type: "Menu",
    };
    this.pageOptions = {
      pageSize:
        (window.innerHeight ||
          document.documentElement.clientHeight ||
          document.body.clientHeight) > 700
          ? 12
          : 6,
    };
    this.name = {
      type: "CheckBox",
    };
    this.employeeNameTemplate = this.employeeNameTemplate.bind(this);
    this.navigateToAddBasicInformation = this.navigateToAddBasicInformation.bind(
      this
    );
  }

  componentDidMount() {
    setTimeout(() => {
      const ele = document.getElementsByClassName("e-headercelldiv");
      for(let i in ele) {
        if(!isNaN(parseInt(i, 10))){
          let index = parseInt(i, 10);
          if (
            ele[index].innerText === JOINING_DATE ||
            ele[index].innerText === DATE_OF_RESIGNATION
          ) {
            ele[index].classList.add("grid-center-header");
          } else {
            ele[index].classList.add("grid-left-header");
          }
        }
      }
    }, 1000);
  }

  onQueryCellInfoPreJoining = (args) => {
    const { cell } = args;
    cell.setAttribute(
      "style",
      "font-size:14px !important; text-align: left !important; padding-left:18px !important"
    );
  };

  onDataBound = () => {
    clearTimeout(this.clrIntervalFun);
    clearInterval(this.intervalFun);
    this.dtTime = true;
  };

  onComplete = (args) => {
    if (args.requestType === "filterchoicerequest") {
      if (
        args.filterModel.options.field === "Trustworthiness" ||
        args.filterModel.options.field === "Rating" ||
        args.filterModel.options.field === "Status"
      ) {
        const span = args.filterModel.dialogObj.element.querySelectorAll(
          ".e-selectall"
        )[0];
        if (!isNullOrUndefined(span)) {
          closest(span, ".e-ftrchk").classList.add("e-hide");
        }
      }
    }
  };

  onChange() {
    this.ddObj.hidePopup();
    this.gridInstance.showSpinner();
    this.dropSlectedIndex = null;
    clearTimeout(this.clrIntervalFun2);
    this.clrIntervalFun2 = setTimeout(() => {
      this.isDataChanged = true;
      this.stTime = null;
      const contentElement = this.gridInstance.contentModule.getPanel()
        .firstChild;
      contentElement.scrollLeft = 0;
      contentElement.scrollTop = 0;
      this.gridInstance.pageSettings.currentPage = 1;
      this.stTime = performance.now();
      this.gridInstance.hideSpinner();
    }, 100);
  }

  onLoad = () => {
    document
      .getElementById("overviewgrid")
      .ej2_instances[0].on("data-ready", () => {
        this.dReady = true;
        this.stTime = performance.now();
      });
    document
      .getElementById("overviewgrid")
      .addEventListener("DOMSubtreeModified", () => {
        if (this.dReady && this.stTime && this.isDataChanged) {
          this.stTime = null;
          this.dReady = false;
          this.dtTime = false;
          this.isDataChanged = false;
        }
      });
  };

  clearanceStatus = (props) => {
    const { clearanceStatus } = props;
    return (
      <ClearanceStatus clearanceStatus={clearanceStatus} />
    );
  };

  selfDepartment = (props) => {

    const { OwnDeptAssetsStatus } = props;
    return (
      <SelfDepartment OwnDeptAssetsStatus={OwnDeptAssetsStatus} />
    )
  };

  itDepartment = (props) => {
    const { itAssetsStatus } = props;
    return (
      <ItDepartment itAssetsStatus={itAssetsStatus} />
    )
  };

  financeDepartment = (props) => {
    const { financeAssetsStatus } = props;
    return (
      <FinanceTemp financeAssetsStatus={financeAssetsStatus} />
    )
  };
  infosecTemp = (props) => {
    const { infoSecAssetsStatus } = props;
    return (
      <FinanceTemp financeAssetsStatus={infoSecAssetsStatus} />
    )
  };
  adminTemp = (props) => {
    const { adminAssetsStatus } = props;
    return (
      <FinanceTemp financeAssetsStatus={adminAssetsStatus} />
    )
  };
  softwareTemp = (props) => {
    const { softwareAssetsStatus } = props;
    return (
      <FinanceTemp financeAssetsStatus={softwareAssetsStatus} />
    )
  };

  hrTemp = (props) => {
    const { peopleAssetsStatus } = props;
    return (
      <FinanceTemp financeAssetsStatus={peopleAssetsStatus} />
    )
  };
  legalTemp = (props) => {
    const { legaAssetsStatus } = props;
    return (
      <FinanceTemp financeAssetsStatus={legaAssetsStatus} />
    )
  }
  misTemp = (props) => {
    const { MISAssetsStatus } = props;
    return (
      <FinanceTemp financeAssetsStatus={MISAssetsStatus} />
    )
  }
  contactTemp = (props) => {
    const { ContactResearchAssetsStatus } = props;
    return (
      <FinanceTemp financeAssetsStatus={ContactResearchAssetsStatus} />
    )
  }

  navigateToAddBasicInformation = async (args) => {
    try {
      localStorage.setItem('last_meeting_category', localStorage.getItem('meeting_category'));
      localStorage.setItem("meeting_category", encodeString(EMPLOYEE_PROFILE));

      const path = `/onboard?empId=${encrypt(args.employeeId)}`;
      this.setState({
        redirect: path
      }, () => this.props.setMeetingCategory(EMPLOYEE_PROFILE));
    } catch (error) {
      console.log("error", error);
    }
  };

  joiningDateFormat = (props) => {
    const { joiningDate } = props;
    return (
      <AllDateFormat date={joiningDate} />
    );
  };
  resignationDateFormat = (props) => {
    const { dateOfResignation } = props;
    return (
      <AllDateFormat date={dateOfResignation} />
    );
  };
  lwdOfficialDateFormat = (props) => {
    const { lastWorkingDayOfficial } = props;
    return (
      <AllDateFormat date={lastWorkingDayOfficial} />
    );
  };

  employeeNameTemplate(props) {
    return (
      <EmployeeNameTemplate
        props={props}
        data="test"
        navigateToAddBasicInformation={this.navigateToAddBasicInformation}
      />
    );
  }
  resignationReason = (props) => {
    const { resgnReason } = props;
    return (
      <div>
        <TooltipComponent
          // content={resgnReason}
          // // content={resgnReason.replace(/(?:\r\n|\r|\n)/g, '<br>')}
          // position="BottomCenter"
          // isSticky={false}
          // cssClass='customtooltip'

          content={resgnReason}
          position="BottomCenter"
          isSticky={false}
          cssClass='customtooltip'
          className="pointer-cursor"
        >
          <span
            style={{
              fontSize: "14px",
              display: "block",
              width: "100px",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {resgnReason}
          </span>
        </TooltipComponent>
      </div>
    );
  }
  render() {
    const { redirect } = this.state;
    const { hrClearanceData } = this.props;

    if (redirect !== null) {
      return <Redirect to={redirect} />;
    }
    var widthColumn = 0;
    var finalWidth = 0;
    // eslint-disable-next-line
    getGridColumn("HrClearance").forEach((data) => {
      widthColumn = widthColumn + data.width;
    });
    if (widthColumn <= this.width) {
      finalWidth = widthColumn + 20;
    } else {
      finalWidth = this.width
    }
    return (
      <div
        className="row resignation-manager-hr-grid-height"
      //   style={{ height: '828px' }}
      >
        <div>
          <div className="row-xs-12 row-sm-12 row-lg-12 row-md-12">
            <div
              style={{
                paddingLeft: "15px",
                paddingRight: "15px",
                paddingTop: "5px",
              }}
            >
              <GridComponent
                className="grid-head-custome"
                id="overviewgrid"
                allowPaging
                pageSettings={this.pageOptions}
                dataSource={hrClearanceData}
                // dataSource={this.pageOptions}
                enableHover={false}
                enableVirtualization={false}
                rowHeight={30}
                height={this.height}
                ref={(g) => {
                  this.gridInstance = g;
                }}
                actionComplete={this.onComplete}
                load={this.onLoad}
                queryCellInfo={this.onQueryCellInfoPreJoining}
                dataBound={this.onDataBound}
                filterSettings={this.Filter}
                allowFiltering
                allowSorting
                allowSelection
                selectionSettings={this.select}
                allowTextWrap={true}
                width={`${finalWidth}`}
              >
                <ColumnsDirective>
                  {getGridColumn("HrClearance").map((item) => {
                    switch (item.colName) {
                      case EMPLOYEE_NAME:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.employeeNameTemplate}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                            filter={this.Filter}
                          />
                        );
                      case JOINING_DATE:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.joiningDateFormat}
                            // allowFiltering={false}
                            // filter={{type:'Excel'}}
                            filter={this.check}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                            format={DateFilterformat}
                          />
                        );
                      case DATE_OF_RESIGNATION:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.resignationDateFormat}
                            // allowFiltering={false}
                            filter={this.check}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                            format={DateFilterformat}
                          />
                        );
                      case LWD_OFFICIAL:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.lwdOfficialDateFormat}
                            // allowFiltering={false}
                            filter={this.check}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                            format={DateFilterformat}
                          />
                        );
                      case REASON:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.resignationReason}
                            allowFiltering={false}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                          />
                        );
                      case CLEARANCE_STATUS:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.clearanceStatus}
                            filter={this.check}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                          />
                        );
                      case SELF_DEPARTMENT:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.selfDepartment}
                            filter={this.check}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                          />
                        );
                      case HR:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.hrTemp}
                            filter={this.check}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                          />
                        );
                      case IT:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.itDepartment}
                            filter={this.check}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                          />
                        );
                      case FINANCE:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.financeDepartment}
                            filter={this.check}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                          />
                        );
                      case INFOSEC:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.infosecTemp}
                            filter={this.check}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                          />
                        );
                      case ADMIN:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.adminTemp}
                            filter={this.check}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                          />
                        );
                      case SOFTWARE:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.softwareTemp}
                            filter={this.check}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                          />
                        );
                      case LEGAL_ASSET:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.legalTemp}
                            filter={this.check}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                          />
                        );
                      case MIS_ASSET:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.misTemp}
                            filter={this.check}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                          />
                        );
                      case CONTACT_ASSET:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.contactTemp}
                            filter={this.check}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                          />
                        );
                      default:
                        return (
                          <ColumnDirective
                            key={item.field}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                            filter={this.check}
                          />
                        );
                    }
                  })}
                </ColumnsDirective>
                <Inject services={[Page, Filter, VirtualScroll, Sort]} />
              </GridComponent>
            </div>
          </div>
          {/* <div className="control-section col-md-12">
                <div
                  className="row card-layout"
                  style={{
                    paddingRight: "30px",
                    paddingTop: "30px",
                    textAlign: "end",
                  }}
                >
                  <ButtonComponent
                    style={{ background: "#f94f5e", color: "#fff" }}
                    onClick={this.openDialog}
                  >
                    {" "}
                  INITIATE READY TO JOINING
                </ButtonComponent>
                </div>
              </div> */}
          {/* <div style={{ width: "350px" }}>
              <Dialog open={this.state.openDialog}>
                <DialogTitle> Initiate Ready To Join </DialogTitle>
                <DialogContent>
                  Are you sure to initiate ready to join Candidate ?
                </DialogContent>
                <DialogActions>
                  <Button onClick={this.initiateReadyToJoin}> Yes </Button>
                  <Button onClick={this.closeDialog}> No </Button>
                </DialogActions>
              </Dialog>
            </div> */}
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ auth, meetingcategory }) => {
  const { categoryName } = meetingcategory;
  return { auth, categoryName };

};
export default connect(mapStateToProps, { setMeetingCategory })(HrClearanceGrid);
