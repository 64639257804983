/**
 * Author: Keval Charla.
 * Created:11-July-2022
 * @description to view all list of holidays for user
 */

import React, { useRef, useState, useEffect } from 'react';
import { ScheduleComponent, Year, Inject, ViewsDirective, ViewDirective } from '@syncfusion/ej2-react-schedule';
import DateFnsUtils from '@date-io/date-fns';
import CountryDropdownMenu from './countrydropdownmenu';
import HolidayRulesAndFilter from './holidayrulesandfilter';
import LnAServices from '../../services/lna/lnaservice';
import ProgressBar from "../../components/progressbar/progressbar";
import "./holidaylist.css";

function HolidayListYearView(props) {
  const [selectedYearDate, setSelectedYearDate] = useState(null);
  const [disableFilter, setDisableFilter] = useState(true);
  const [loader, setLoader] = useState(false);
  const [hight, setHight] = useState({ // NOSONAR
    height: null,
    windowAdjust: null
  });

  const holidayYearViewCalendar = useRef();

  /**
   * Author: Keval Charla  *
   * @param {*} type
   * @param {*} authUser
   * @param {*} selectedCountry
   * @param {*} year
   * @param {*} monthYear
   * @description get the holiday list data on country change
   */

  useEffect(() => {
    const getHolidays = () => {
      setTimeout(async () => {
        if (props.selectedCountry && props.selectedYear) {
          setLoader(true);
          const holidayListMaster = await LnAServices.getHolidayNWorkingSatDetails("allList", props.authUser, props.selectedCountry, props.selectedYear, 0);
          if (holidayListMaster) {
            const modifyHolidayListMaster = holidayListMaster.Result.map(holiday => {
              return holiday
            })
            props.getViewData({ Result: modifyHolidayListMaster }, "year");
            setTimeout(() => {
              if (holidayYearViewCalendar.current) {
                holidayYearViewCalendar.current.refreshTemplates();
              }
              setLoader(false);
            }, 2000);
          }
        }
      }, 1000);
    };

    getHolidays();
  }, [props.selectedCountry])

  useEffect(() => {
    const currentDate = new Date();
    const month = currentDate.getMonth();

    if (month === 0 || month === 1 || month === 2) {
      currentDate.setFullYear(currentDate.getFullYear() - 1);
    }

    setSelectedYearDate(currentDate);
  }, []);

  /**
   * Author: Keval Charla  *
   * @description resize dimension
   */

  useEffect(() => {
    window.addEventListener("resize", updateDimensions()); // NOSONAR
  }, [])

  const updateDimensions = () => {
    const updateheight = window.innerHeight - 180;
    const windowAdjusts = window.innerHeight - 130;
    setHight({
      height: updateheight,
      windowAdjust: windowAdjusts,
    });
  }

  /**
   * Author: Keval Charla  *
   * @param {*} args - syncfusion args data
   * @description inject country dropdown and filter dropdown in scheduler toolbar
   */

  const onActionBegin = (args) => {
    if (args.requestType === "toolbarItemRendering") {
      let holidayListCountries = { template: document.querySelector('#holidayListCountries') };
      let holidayListRulesAndFilters = { template: document.querySelector('#holidayListRulesAndFilters'), align: "Right", cssClass: "toolbar-holiday-rules-and-filter" };
      args.items.push(holidayListCountries);
      args.items.push(holidayListRulesAndFilters);
    }
  }

  /**
   * Author: Keval Charla  *
   * @param {*} args - syncfusion args data
   * @description style calendar dates in year view
   */

  const onRenderCell = (args) => { // NOSONAR
    if (args.element.classList.contains('e-other-month') && args.date.getDay() === 0 && args.element.parentElement.children[1].classList.contains('e-other-month')) {
      args.element.parentElement.children[0].classList.add('e-other-month-year-view-week-number');
    }

    if (args.elementType == 'workCells' || args.elementType == 'monthCells') {
      if (props.holidayListData.length > 0) {
        let runOnce = 0;
        while (runOnce < 1) {
          const dateFns = new DateFnsUtils();
          let argsDate = dateFns.format(args.date, 'yyyy-MM-dd');
          let d1 = new Date().getTime(); // NOSONAR

          props.holidayListData.forEach((holiday) => {
            let holidayDate = holiday.holidayDate
            let d2 = new Date(holiday.holidayDate).getTime(); // NOSONAR

            if (holiday.holidayType === "Fixed Holiday" && argsDate === holidayDate) {
              (args.element).classList.add("fixedHolidayYearViewTemplate");
            }

            if (holiday.holidayType === "Working Saturday" && argsDate === holidayDate) {
              (args.element).classList.add("workingSaturdayYearViewTemplate");
            }

            if (holiday.holidayType === "Allocated Festival Holiday" && argsDate === holidayDate) {
              (args.element).classList.add("allocatedFestivalHolidayYearViewTemplate");
            }

            if (holiday.holidayType === "Availed Festival Holiday" && argsDate === holidayDate) {
              (args.element).classList.add("appliedFestivalHolidayYearViewTemplate");
            }

            if ((args.element).classList.contains("fixedHolidayYearViewTemplate") && (args.element).classList.contains("workingSaturdayYearViewTemplate")) {
              (args.element).classList.remove("workingSaturdayYearViewTemplate");
            }

            if ((args.element).classList.contains("appliedFestivalHolidayYearViewTemplate") && (args.element).classList.contains("workingSaturdayYearViewTemplate")) {
              (args.element).classList.remove("appliedFestivalHolidayYearViewTemplate");
            }

            if ((args.element).classList.contains("allocatedFestivalHolidayYearViewTemplate") && (args.element).classList.contains("workingSaturdayYearViewTemplate")) {
              (args.element).classList.remove("workingSaturdayYearViewTemplate");
            }
          })
          runOnce++
        }
      }
    }
  }

  /**
   * Author: Keval Charla  *
   * @param {*} countryId
   * @description handle country change
   */

  const handleCountryChange = (countryId) => {
    props.handleCountryChange(countryId);
    setDisableFilter(true);
    setTimeout(() => {
      if (holidayYearViewCalendar.current)
        holidayYearViewCalendar.current.refreshTemplates();
    }, 2000);
  }

  /**
   * Author: Keval Charla  *
   * @param {*} type
   * @param {*} authUser
   * @param {*} selectedCountry
   * @param {*} year
   * @param {*} monthYear
   * @description get the holiday list data on year change
   */

  const onActionComplete = (args) => {
    if (args.requestType === "dateNavigate") {
      const dateFns = new DateFnsUtils();
      const year = dateFns.format(holidayYearViewCalendar.current.selectedDate, 'yyyy');
      props.handleSelectedYear(year);
      setTimeout(async () => {
        setLoader(true);
        props.refreshHolidayList();
      }, 500);

      setDisableFilter(true);
      setTimeout(() => { // NOSONAR
        setLoader(false);
        if (holidayYearViewCalendar.current)
          holidayYearViewCalendar.current.refreshTemplates();
      }, 2000);
    }
  }

  /**
   * Author: Keval Charla  *
   * @param {*} args - syncfusion args data
   * @description year view event popup background color
   */

  const onEventRendered = (args) => {
    let eventColor = args.data.holidayType !== "Allocated Festival Holiday" ? args.data.secondaryColor : args.data.primaryColor;
    if (!args.element || !eventColor) {
      return;
    } else {
      args.element.style.backgroundColor = eventColor;
    }
  }

  /**
   * @author Keval Charla
   * @argument filterList
   * @description filter holiday list data.
   */

  const handleHolidayFilter = (filterList) => {
    props.handleHolidayFilter(filterList);
    props.handleYearlyFilterData(filterList);
    setDisableFilter(true);
    setLoader(true);
    setTimeout(() => {
      if (holidayYearViewCalendar.current) {
        holidayYearViewCalendar.current.refreshTemplates();
      }
      setLoader(false);
    }, 2000);
  }

  /**
   * @author Keval Charla
   * @argument type - "yes" or "no"
   * @description disable holiday list filter ui color
   */

  const handleDisableFilter = (type) => {
    if (type === "yes") {
      setDisableFilter(false);
    }
    if (type === "no") {
      setDisableFilter(true);
    }
  }
  const eventTemplate = (args) => {
    return (
      <div>
        {/* &nbsp;&nbsp;{args.holidayName.substring(0, args.holidayName.length).substr(0, 32)} */}
        &nbsp;&nbsp;{args.holidayName}
      </div>
    )
  }

  const onPopupOpen = (args) => {
    if (args.type === "QuickInfo") {
      args.cancel = true;
    }
  }

  return (
    <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 p-md" style={{
      height: hight.windowAdjust,
      // height: "100vh"
    }}>
      <>
        <ScheduleComponent
          id="holidayListYearView"
          // width='100%'
          // height={window.innerHeight < 650 ? '81.5vh' : '73%'}
          width='100%'
          height='100%'
          cssClass="year-view"
          selectedDate={selectedYearDate}
          minDate={new Date(props.minDate)}
          maxDate={new Date(props.maxDate)}
          actionBegin={onActionBegin}
          actionComplete={onActionComplete}
          ref={holidayYearViewCalendar}
          firstMonthOfYear={3}
          renderCell={onRenderCell}
          eventSettings={{
            dataSource: props.holidayListData,
            template: eventTemplate,
            fields: {
              Id: { name: "id", value: "id" },
              Subject: { name: "holidayName", value: "holidayName" },
              startTime: { name: "holidayDate", value: "holidayDate" },
              endTime: { name: "holidayDate", value: "holidayDate" },
              PrimaryColor: { name: "primaryColor", value: "primaryColor" },
              EventColor: { name: "secondaryColor", value: "secondaryColor" },
              IsApplied: { name: "isApplied", value: "isApplied" },
            }
          }}
          popupOpen={onPopupOpen}
          eventRendered={onEventRendered}
          showWeekNumber={false}
          weekRule="FirstFourDayWeek"
          firstDayOfWeek={1}
        >
          <ViewsDirective>
            <ViewDirective option='Year' readonly={true} /> {/** eventTemplate={eventTemplate} */}
          </ViewsDirective>
          <Inject services={[Year]} />
        </ScheduleComponent>
        {loader && <ProgressBar />}
      </>
      <CountryDropdownMenu handleCountryChange={handleCountryChange} selectedCountry={props.selectedCountry} />
      <HolidayRulesAndFilter
        handleHolidayFilter={handleHolidayFilter}
        yearViewFilterList={props.yearViewFilterList}
        disableFilter={disableFilter}
        handleDisableFilter={handleDisableFilter}
        handleYearlyFilterData={props.handleYearlyFilterData}
        holidayRulesData={props.holidayRulesData}
        handleFilterCheckBox={props.handleFilterCheckBox}
      />
    </div>
  )
}

export default HolidayListYearView;
