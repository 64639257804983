import React, { Component } from "react";
import PropTypes from "prop-types";
import { ComboBoxComponent } from "@syncfusion/ej2-react-dropdowns";
import { connect } from "react-redux";
import { addUpdateTibData } from "../../redux/actions/tibacton";

class USERFILTER extends Component {
  constructor(props) {
    super(props);
    this.onChangeUserOption = this.onChangeUserOption.bind(this);
    this.userOptions = [
      { Id: 2, Name: "All" },
      { Id: 1, Name: "Active" },
      { Id: 0, Name: "Inactive" },
    ];
  }
  componentDidMount() {
    setTimeout(() => {
      const { tibAvgSelectedUserOption } = this.props;
      var d = {};
      d.value = tibAvgSelectedUserOption;
      this.onChangeUserOption(d);
    })

  }

  async onChangeUserOption(e) {
    if (e.value !== null)
      this.setState(() =>
        this.props.addUpdateTibData({ tibAvgSelectedUserOption: e.value })
      );
  }

  render() {
    const { tibAvgSelectedUserOption } = this.props;
    return (
      <div
        className="col-lg-2 col-sm-2 col-md-2 col-xs-2"
        style={{ paddingLeft: "6px" }}
      >
        <div className="col-lg-10 col-md-10 col-sm-10 col-xs-10">
          <ComboBoxComponent
            allowCustom={false}
            floatLabelType="Auto"
            fields={{ value: "Id", text: "Name" }}
            dataSource={this.userOptions}
            change={this.onChangeUserOption}
            name={`userOptionError`}
            id={`userOption`}
            data-msg-containerid={`userOptionError`}
            cssClass="e-outline"
            placeholder="User Filter *"
            value={tibAvgSelectedUserOption}
            showClearButton={false}
          />
        </div>
      </div>
    );
  }
}
USERFILTER.propTypes = {
  tibAvgSelectedUserOption: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
};
USERFILTER.defaultProps = {
  tibAvgSelectedUserOption: 2,
};
const mapStateToProps = ({ tibReducer, auth }) => {
  const { authUser } = auth;
  // eslint-disable-next-line
  const { tibAvgSelectedUserOption } = tibReducer;
  return {
    authUser,

    tibAvgSelectedUserOption,
  };
};

export default connect(mapStateToProps, { addUpdateTibData })(USERFILTER);
