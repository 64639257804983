
/**
 * Author: Mukesh.
 * Created:11-Jan-2023
 * Last Modify:11-Jan-2023
 * Modified By:Mukesh
 */
import React, { useEffect,  useState } from "react";
import OrgChartTeamView from "./Component/orgChartTeamView"
import OrgChartLayoutLeft from "./Component/orgChartLayoutLeft"
import { connect } from "react-redux";
import { setOrhChartDetaila } from '../../redux/actions/entryexit/orgchartaction'
import ProgressBar from "../../components/progressbar/progressbar";
const ReportingView = (props) => {
  const [nodeDataArray, setData] = useState(props.orgChartData);
  const [viewType] = useState("TeamView")
  const [showLoader, setShowLoader] = useState(true)
  const [layourtVal, setLayourtVal] = useState(0)

  useEffect(() => {

    if (props.orgChartData.length > 0) {
      setShowLoader(false)
      setShowLoader(props.showLoader)
      setLayourtVal(props.layout)
    }
    setData(props.orgChartData)
  }, [props]);
  const getOnLoadPlotinObject = (obj) => {   
    props.onGetPlotObj(obj)
  }
  const showProfile = (data) => {
    if (data.nodeType === "teamMember") {
      props.onSelectHigh(false)
      props.onSelectPlot(false)
      props.onSelectProfile(true, data)
    }
  }
  return (
    <div className="App">
      {showLoader ? (
        <>
          <div style={{ marginTop: "39%" }}>
            <ProgressBar />
          </div>
        </>
      ) : (
        <>
          {layourtVal !== 90 ? (
            <div className="app-orgchart-container">
             <OrgChartLayoutLeft
              nodeDataArray={nodeDataArray}
              viewType={viewType}
              onLoadTreeEvent={getOnLoadPlotinObject}
              onLoadProfileData={showProfile} 
              filterData={props.filterData}             
            />
            </div>
          ) : (<div className="app-orgchart-container">
            <OrgChartTeamView
                nodeDataArray={nodeDataArray}
                viewType={viewType}
                onLoadTreeEvent={getOnLoadPlotinObject}
                onLoadProfileData={showProfile}
                filterData={props.filterData}  
              /> 
          </div>)}
        </>
      )}
    </div>);
}
const mapStateToProps = ({ auth, orgchartreducer }) => {
  const { authUser } = auth;
  const { orgchartupdateStatus } = orgchartreducer;
  return { authUser, orgchartupdateStatus };
};
export default connect(mapStateToProps, { setOrhChartDetaila })(
  ReportingView
);





