
import * as React from 'react';
import { GridComponent, ColumnsDirective, ColumnDirective, Inject, Edit, Toolbar } from '@syncfusion/ej2-react-grids';
import { DropDownListComponent } from '@syncfusion/ej2-react-dropdowns';
import { getDisplayDateOnly, getDate } from "../../app/other/commonfunction";
import { saveSettingConfig, addEditWorkDaySettings } from "../../redux/actions/settingaction";
import { updateMeetingBookingState } from "../../redux/actions/meetingbookingaction";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
    allTimeInterval, endTimeandNextDay, validDate, selectStartDateEndDate, selectEndDate, maxFiveWorkDaySettingAllowed,
    selectEndDateGreaterStartDate, selectStartTime, selectEndTime, selectDefaultTimeZone, canNotDeleteDefaultWorkDay, minimumTenHoursWorkday, workDayALreadyAddedForDate
} from '../../assets/constants/constants';
import { DateRangePickerComponent } from '@syncfusion/ej2-react-calendars';
import TextField from "@material-ui/core/TextField";
import services from "../../services/services";
import ProgressBar from '../progressbar/progressbar'
import ErrorBoundary from '../error/errorboundary';

const gridBackground = `
.e-grid td.e-active {
    background: #e0e0e0;
    color: #000;
}
.e-daterangepicker.e-popup, .e-bigger.e-small .e-daterangepicker.e-popup {
    border: 1px solid #F94F5E;
    margin-top: 0;
    margin-left: 1rem;
}
#workDaySettings .e-gridheader.e-lib.e-droppable .e-headercontent .e-table thead tr.e-columnheader {
    height: 1.5em !important;
}
.e-ddl.e-popup.e-lib.e-control.e-popup-open {
    border: 1px solid #F94F5E;
}
#workDaySettings .e-gridheader.e-lib.e-droppable .e-headercontent .e-table thead tr.e-columnheader .e-headercell.e-centeralign .e-headercelldiv .e-headertext {
    font-size: .8rem;
}
.workdayTime {
    font-size: 12px;
}
// #workday-setting-control-pane {
//     height: 500px;
//     overflow: auto;
// }
@media screen and (max-width: 1366px) and (max-height: 768px), screen and (max-width: 1440px) and (max-height: 900px) {
    // tr.e-columnheader {
    //     height: 1em !important;
    // }
    .e-headertext {
        font-size: 11px !important;
    }
    .e-grid .e-gridheader tr th {
        padding: 7px 0px 0px 1px !important;
    }
    .e-grid .e-headercell, .e-grid .e-detailheadercell {
        height: 30px;
    }
    .workdayTime {
        font-size: 11px;
    }
    .e-input-group input.e-input, .e-float-input.e-input-group input, .e-input-group.e-control-wrapper input.e-input, .e-float-input.e-input-group.e-control-wrapper input, .e-float-input input, .e-float-input.e-control-wrapper input {
        min-height: 0;
    }
    input.e-input, .e-input-group input.e-input, .e-input-group.e-control-wrapper input.e-input, textarea.e-input, .e-input-group textarea.e-input, .e-input-group.e-control-wrapper textarea.e-input {
        padding: 0;
    }
    .e-daterangepicker.e-popup .e-range-header .e-day-span, .e-bigger.e-small .e-daterangepicker.e-popup .e-range-header .e-day-span {
        font-size: 12px;
        margin: -7px 0 0 0;
        height: 32px;
    }
    .e-daterangepicker.e-popup .e-calendar .e-header.e-month, .e-daterangepicker.e-popup .e-calendar .e-header.e-year, .e-daterangepicker.e-popup .e-calendar .e-header.e-decade, .e-bigger.e-small .e-daterangepicker.e-popup .e-calendar .e-header.e-month, .e-bigger.e-small .e-daterangepicker.e-popup .e-calendar .e-header.e-year, .e-bigger.e-small .e-daterangepicker.e-popup .e-calendar .e-header.e-decade {
        padding: 2px 2px 0 2px;
    }
    .e-daterangepicker.e-popup .e-calendar .e-header .e-title, .e-bigger.e-small .e-daterangepicker.e-popup .e-calendar .e-header .e-title {
        line-height: 15px;
    }
    .e-calendar .e-header .e-title, .e-bigger.e-small .e-calendar .e-header .e-title {
        font-size: 12px;
    }
    .e-calendar th, .e-bigger.e-small .e-calendar th {
        font-size: 11px;
    }
    .e-calendar .e-content.e-month td, .e-bigger.e-small .e-calendar .e-content.e-month td {
        padding: 0;
    }
    .e-calendar .e-content span.e-day, .e-bigger.e-small .e-calendar .e-content span.e-day {
        font-size: 11px;
        height: 20px;
        line-height: 20px;
        width: 20px;
    }
    .e-daterangepicker.e-popup .e-calendar .e-content table, .e-bigger.e-small .e-daterangepicker.e-popup .e-calendar .e-content table {
        padding: 0 10px 0px;
    }
    .e-daterangepicker.e-popup .e-footer, .e-bigger.e-small .e-daterangepicker.e-popup .e-footer {
        height: 38px;
    }
    .e-btn, .e-css.e-btn {
        font-size: 11px;
    }
    input.e-input, textarea.e-input, .e-input-group, .e-input-group.e-control-wrapper {
        font-size: 11px;
    }
    .e-daterangepicker.e-popup, .e-bigger.e-small .e-daterangepicker.e-popup {
        margin: -16rem 0 0 0;
    }
    .e-daterangepicker.e-popup .e-range-header, .e-bigger.e-small .e-daterangepicker.e-popup .e-range-header {
        margin: 5px 5px 0 5px;
    }
    .e-daterangepicker.e-popup .e-range-header .e-start-label, .e-daterangepicker.e-popup .e-range-header .e-end-label, .e-bigger.e-small .e-daterangepicker.e-popup .e-range-header .e-start-label, .e-bigger.e-small .e-daterangepicker.e-popup .e-range-header .e-end-label {
        font-size: 12px;
    }
    .e-daterangepicker.e-popup .e-calendar .e-header.e-month, .e-daterangepicker.e-popup .e-calendar .e-header.e-year, .e-daterangepicker.e-popup .e-calendar .e-header.e-decade, .e-bigger.e-small .e-daterangepicker.e-popup .e-calendar .e-header.e-month, .e-bigger.e-small .e-daterangepicker.e-popup .e-calendar .e-header.e-year, .e-bigger.e-small .e-daterangepicker.e-popup .e-calendar .e-header.e-decade {}
}
`
class WorkDaySettings extends React.Component {
    constructor(props) {
        super(...arguments);
        this.toolbarOptions = ['Add', 'Edit', 'Delete', 'Update', 'Cancel'];
        this.editOptions = { allowEditing: true, allowAdding: true, allowDeleting: true, mode: 'Normal', newRowPosition: 'Bottom' };
        this.format = { type: 'time', format: 'HH:mm' };
        this.validationRule = { required: true };
        this.data = {
            endTime: "10:00",
            enumDefault: "0",
            startTime: "20:00",
            timeZone: "",
            timeZoneId: 0,
            workDayId: 0,
            workEndDate: new Date(),
            workStartDate: new Date()
        }
        this.state = {
            workDaySettingList: [],
            isLoading: false,
            actionType: "add",
        }
        this.currentDate = getDate(new Date())
        this.handleChangeTemplate = this.handleChangeTemplate.bind(this)
        this.queryCellInfo = this.queryCellInfo.bind(this)
        this.itemTemplate = this.itemTemplate.bind(this)
    }

    async componentDidMount() {
        this.setState({ isLoading: true })
        this.getWorkDayList()
    }

    getWorkDayList = () => {
        const { authUser } = this.props;
        const type = "Get";
        const workDaySetting = Promise.resolve(
            services.getWorkDaySettingList(authUser, null, null, type));
        workDaySetting.then((result) => {
            if (result !== null) {
                if (result.length === 1) {
                    result[0].currentDate = "Y"
                } else {
                    const data = result.filter((item) => { return item.currentDate === "Y"; });
                    if (data.length === 0) {
                        result.forEach((element, idx) => {
                            if (element.workStartDate === null)
                                element.currentDate = "Y"
                        });
                    }
                }
                this.setState({ workDaySettingList: result })
            } else {
                this.setState({ workDaySettingList: [] })
            }
            setTimeout(() => {
                this.setState({ isLoading: false })
            }, 100)
        });
    };

    deleteWorkDaySetting = (workDayId) => {
        const data = {
            shiftStartDateTime: getDate(new Date()),
            shiftEndDateTime: getDate(new Date()),
            timeZoneForWorkDay: '0',
            shiftStartTime: new Date().toLocaleTimeString("en-GB"),
            shiftEndTime: new Date().toLocaleTimeString("en-GB"),
            nextDayFlagData: 0,
            authUser: workDayId.toString(),
            type: "Delete",
        };
        // eslint-disable-next-line
        this.props.addEditWorkDaySettings(data);
        setTimeout(() => {
            this.setState({ isLoading: false })
        }, 100)

    };

    validateWorkDay(rowIndex) { // NOSONAR
        try {

            this.data.workStartDate = (new Date(this.data.workStartDate)).setHours(0, 0, 0, 0)
            this.data.workEndDate = (new Date(this.data.workEndDate)).setHours(0, 0, 0, 0)

            const { workStartDate, workEndDate, startTime, endTime, timeZone, workDayId, enumDefault } = this.data;
            const { workDaySettingList } = this.state

            document.getElementById("errorSetting").innerHTML = "";
            if (enumDefault === "0") {
                if (typeof workStartDate === "undefined" || workStartDate === null || workStartDate < 0) {
                    document.getElementById("errorSetting").innerHTML = selectStartDateEndDate;
                    return false;
                }
                if (typeof workEndDate === "undefined" || workEndDate === null || workEndDate < 0) {
                    document.getElementById("errorSetting").innerHTML = selectEndDate;
                    return false;
                }
                if (workEndDate < workStartDate) {
                    document.getElementById("errorSetting").innerHTML = selectEndDateGreaterStartDate;
                    return false;
                }
            }

            if (startTime === "" || startTime === null) {
                document.getElementById("errorSetting").innerHTML = selectStartTime;
                return false;
            }
            if (endTime === "" || endTime === null) {
                document.getElementById("errorSetting").innerHTML = selectEndTime;
                return false;
            }
            if (timeZone === "" || timeZone === null) {
                document.getElementById("errorSetting").innerHTML = selectDefaultTimeZone;
                return false;
            }
            let nextDay = false
            let newEndTime = endTime
            if (endTime.includes("+")) {
                nextDay = true
                newEndTime = endTime.split(" +")[0]
            }
            let d1 = startTime !== null ? new Date(`2020-01-01 ${startTime}`) : new Date();
            let d2 = newEndTime !== null ? new Date(`2020-01-01 ${newEndTime}`) : new Date();
            if (nextDay) {
                d2.setDate(d2.getDate() + 1)
            }
            let hourse = Math.abs(d1 - d2) / 36e5;
            if (hourse < 10) {
                document.getElementById("errorSetting").innerHTML = minimumTenHoursWorkday;
                return false;
            }
            if (enumDefault === "1") {
                return true;
            }

            for (let item of workDaySettingList) {
                if (item.workStartDate !== null) {
                    item.workStartDate = (new Date(item.workStartDate)).setHours(0, 0, 0, 0)
                }
                if (item.workEndDate !== null) {
                    item.workEndDate = (new Date(item.workEndDate)).setHours(0, 0, 0, 0)
                }
            }

            if (this.state.workDaySettingList.length >= 5) {
                document.getElementById("errorSetting").innerHTML = maxFiveWorkDaySettingAllowed;
                return false;
            }

            if (workDayId === undefined) {
                document.getElementById("errorSetting").innerHTML = "";
                for (let item of workDaySettingList) {
                    if (item.workDayId !== undefined && item.enumDefault === "0") {
                        if (item.workStartDate !== null && item.workEndDate !== null) {
                            if (((`${workStartDate}`) >= (`${item.workStartDate}`) &&
                                (`${workStartDate}`) <= (`${item.workEndDate}`))
                                ||
                                ((`${workEndDate}`) >= (`${item.workStartDate}`) &&
                                    (`${workEndDate}`) <= (`${item.workEndDate}`)
                                )) {
                                document.getElementById("errorSetting").innerHTML = workDayALreadyAddedForDate;
                                return false;
                            }
                        }
                    }
                }
            } else {
                for (let item of workDaySettingList) {

                    if (item.workDayId !== undefined && workDaySettingList[rowIndex].workDayId !== item.workDayId && item.enumDefault === "0") {
                        if (item.workStartDate !== null && item.workEndDate !== null) {
                            if (((`${workStartDate}`) >= (`${item.workStartDate}`) &&
                                (`${workStartDate}`) <= (`${item.workEndDate}`))
                                ||
                                ((`${workEndDate}`) >= (`${item.workStartDate}`) &&
                                    (`${workEndDate}`) <= (`${item.workEndDate}`)
                                )) {
                                document.getElementById("errorSetting").innerHTML = workDayALreadyAddedForDate;
                                return false;
                            }
                        }
                    }
                }
            }
            return true;
        } catch (error) {
            console.log(error, "error")
        }
    }

    saveWorkDaySetting() { // NOSONAR
        try {
            const { authUser, } = this.props;
            const { workStartDate, workEndDate, startTime, endTime, timeZone, workDayId } = this.data;

            if (workDayId === undefined) {
                const data = {
                    shiftStartDateTime: getDate(workStartDate !== null ? new Date(workStartDate) : new Date()),
                    shiftEndDateTime: getDate(workEndDate !== null ? new Date(workEndDate) : new Date()),
                    timeZoneForWorkDay: timeZone.toString(),
                    shiftStartTime: startTime !== null ? new Date(Math.floor(new Date(`0000 ${startTime}`))).toLocaleTimeString("en-GB") : new Date(),/**NOSONAR */
                    shiftEndTime: endTime !== null ? endTime.includes(" +") === true ? new Date(Math.floor(new Date(`0000 ${endTime.split(" +")[0]}`))).toLocaleTimeString("en-GB") : new Date(Math.floor(new Date(`0000 ${endTime}`))).toLocaleTimeString("en-GB") : new Date(), // NOSONAR
                    nextDayFlagData: endTime.includes(" +") === true ? 1 : 0,
                    authUser: parseInt(authUser),
                    type: "Add",
                }
                this.props.addEditWorkDaySettings(data);

            }
            else {
                const data = {
                    shiftStartDateTime: getDate(workStartDate !== null ? new Date(workStartDate) : new Date()),
                    shiftEndDateTime: getDate(workEndDate !== null ? new Date(workEndDate) : new Date()),
                    timeZoneForWorkDay: timeZone.toString(),
                    shiftStartTime: startTime !== null ? new Date(Math.floor(new Date(`0000 ${startTime}`))).toLocaleTimeString("en-GB") : new Date(),/**NOSONAR */
                    shiftEndTime: endTime !== null ? endTime.includes(" +") === true ? new Date(Math.floor(new Date(`0000 ${endTime.split(" +")[0]}`))).toLocaleTimeString("en-GB") : new Date(Math.floor(new Date(`0000 ${endTime}`))).toLocaleTimeString("en-GB") : new Date(), // NOSONAR
                    nextDayFlagData: endTime.includes(" +") === true ? 1 : 0,
                    authUser: parseInt(workDayId),
                    type: "Edit",
                }
                this.props.addEditWorkDaySettings(data);
            }
            this.setState({ isLoading: true })
            setTimeout(() => {
                this.getWorkDayList();
            }, 1000);
        } catch (error) {
            console.log(error, 'error')
        }
    }

    actionBegin = (args) => {
        if (args.requestType === "beginEdit") {
            this.data = {
                endTime: args.rowData.endTime,
                enumDefault: args.rowData.enumDefault,
                startTime: args.rowData.startTime,
                timeZone: args.rowData.timeZone,
                timeZoneId: args.rowData.timeZoneId,
                workDayId: args.rowData.workDayId,
                workEndDate: args.rowData.workEndDate,
                workStartDate: args.rowData.workStartDate
            }
            this.setState({ actionType: "edit" })
        } else if (args.requestType === "add") {
            this.data = {
                endTime: "", enumDefault: "0", startTime: "", timeZone: "", timeZoneId: 0, workDayId: undefined,
                workEndDate: null, workStartDate: null
            }
            this.setState({ actionType: "add" })
        }
        else if (args.requestType === "delete") {
            if (args.data[0].enumDefault === "1") {
                args.cancel = true;
                document.getElementById("errorSetting").innerHTML = canNotDeleteDefaultWorkDay;
                setTimeout(() => {
                    document.getElementById("errorSetting").innerHTML = "";
                }, 3000);

                return;
            } else {
                this.setState({ isLoading: true })
                this.deleteWorkDaySetting(args.data[0].workDayId);
                this.getWorkDayList();
            }
        }
        else if (args.requestType === "save") {
            const validate = this.validateWorkDay(args.rowIndex)
            if (!validate)
                args.cancel = true;
            else {
                const { workDaySettingList } = this.state
                let tempWorkDaySettingList = workDaySettingList
                tempWorkDaySettingList.splice(0, 0, this.data)
                this.setState({ workDaySettingList: tempWorkDaySettingList })
                this.saveWorkDaySetting()
            }
        }
    }
    actionComplete = (args) => {
        document.getElementById("errorSetting").innerHTML = "";
    }

    onOpen = (args) => {
        args.appendTo = document.getElementById("workday-setting-control-pane");
        const workdayHeight = this.state.workDaySettingList.length * 2;
        if (window.innerHeight > 650) {
            args.popup.position = { X: "left", Y: "bottom" };
            document.getElementById("workday-setting-control-pane").setAttribute("style", `height: ${37 + workdayHeight}rem`);
        }
    }

    onDropdownOpen = () => {
        const workdayHeight = this.state.workDaySettingList.length * 2;
        if (window.innerHeight > 650) {
            document.getElementById("workday-setting-control-pane").setAttribute("style", `height: ${27 + workdayHeight}rem`);
        }
    }

    onClose = () => {
        document.getElementById("workday-setting-control-pane").setAttribute("style", "height: none");
    }

    editTemplateStart = (props) => {
        try {
            if (props.enumDefault === "1") {
                return (<div></div>)
            } else {
                return (
                    <ErrorBoundary>
                        <DateRangePickerComponent
                            id="OrderDate"
                            placeholder="Date Range"
                            format='dd-MMM-yy'
                            floatLabelType='Never'
                            onChange={(e) => this.getStartEndDate(e)}
                            TextFieldComponent={this.TextFieldComponent}
                            disablePast
                            startDate={new Date(props.workStartDate)}
                            endDate={new Date(props.workEndDate)}
                            width={160}
                            // weekNumber={true}
                            // htmlAttributes={{ required: true }}
                            min={new Date()}
                            open={this.onOpen}
                            close={this.onClose}
                        />
                    </ErrorBoundary>
                )
            }
        } catch (error) {

        }
    }

    dateStartEndDateFormat = (props) => {
        if (props.enumDefault === '0') {
            return (
                <div>
                    <span style={{ fontSize: 12 }} >
                        {getDisplayDateOnly(new Date(props.workStartDate))}  To  {getDisplayDateOnly(new Date(props.workEndDate))}</span>
                </div>
            );
        } else {
            return (
                <div>
                    {""}
                </div>
            );
        }
    }
    TextFieldComponent = (props) => {
        return <TextField {...props} color="primary" disabled />;
    };
    getStartEndDate = (e) => {
        document.getElementById("errorSetting").innerHTML = "";
        if (e.value !== null) {
            this.data.workStartDate = e.value[0]
            this.data.workEndDate = e.value[1]
        } else {
            this.data.workStartDate = null
            this.data.workEndDate = null
            document.getElementById("errorSetting").innerHTML = validDate;
        }
    }

    handleChangeTemplate = (event, eventType) => {
        if (event.itemData !== null && typeof event.itemData !== 'undefined') {
            switch (eventType) {
                case "StartTime":
                    this.data.startTime = event.itemData.Value
                    break;
                case "EndTime":
                    this.data.endTime = event.itemData.Value
                    break;
                case "TimeZone":
                    this.data.timeZone = event.itemData.Name
                    this.data.timeZoneId = event.itemData.Id
                    break;
                default:

            }
        }
    }

    editWDS = (props) => {
        return <span>{this.state.actionType === "add" ? this.state.workDaySettingList.length + 1 : props.rowNumber}</span>
    }

    editTemplateStartTime = (props) => {
        return (
            <DropDownListComponent
                dataSource={allTimeInterval}
                fields={{
                    value: 'Value', text: 'Name'
                }}
                value={props.startTime}
                width={100}
                change={(e) => { this.handleChangeTemplate(e, "StartTime") }}
                itemTemplate={this.timeTemplate}
                open={this.onDropdownOpen}
                close={this.onClose}
            />)
    }
    viewStartTime = (props) => {
        return (
            <div>
                <span className="workdayTime">{props.startTime}</span>
            </div>
        );
    }
    timeTemplate = (props) => {
        return (
            <div>
                <span className="workdayTime">{props.Name}</span>
            </div>
        );
    };
    itemTemplate = (props) => {
        if (props.Name.includes("+")) {
            let actualTime = props.Name.split("+")[0];
            return (
                <div>
                    <span className="workdayTime">{actualTime}<sup>+1</sup></span>
                </div>
            );
        } else {
            return (
                <div>
                    <span className="workdayTime">{props.Name}</span>
                </div>
            );
        }
    };
    editTemplateEndTime = (props) => {
        return (
            <DropDownListComponent
                dataSource={endTimeandNextDay}
                fields={{
                    value: 'Value', text: 'Name'
                }}
                value={props.endTime}
                width={100}
                itemTemplate={this.itemTemplate}
                change={(e) => { this.handleChangeTemplate(e, "EndTime") }}
                open={this.onDropdownOpen}
                close={this.onClose}
            />)
    }
    viewEndTime = (props) => {
        if (typeof props.endTime !== 'undefined') {
            if (props.endTime.includes("+")) {
                let actualTime = props.endTime.split("+")[0];
                return (
                    <div>
                        <span className="workdayTime">{actualTime}<sup>+1</sup></span>
                    </div>
                );
            }
        }
        return (
            <div>
                <span className="workdayTime">{props.endTime}</span>
            </div>
        );
    }
    editTemplateTimeZone = (props) => {
        const { timeZoneSetting } = this.props
        return (
            <DropDownListComponent
                dataSource={timeZoneSetting}
                fields={{
                    value: 'Id', text: 'Name'
                }}
                value={props.timeZoneId}
                width={100}
                change={(e) => { this.handleChangeTemplate(e, "TimeZone") }}
                open={this.onDropdownOpen}
                itemTemplate={this.timeTemplate}
                close={this.onClose}
            />)
    }
    viewTimeZone = (props) => {
        return (
            <div>
                <span className="workdayTime">{props.timeZone}</span>
            </div>
        );
    }

    queryCellInfo = e => {
        if (e.data.currentDate === "Y") {
            e.cell.setAttribute("style", "background:#FEDCDF;text-align: center");

            if (e.cell.getAttribute("aria-label").includes("Start Date & End Date")) {
                e.cell.setAttribute("style", "background:#FEDCDF;text-align: left");
            }
        }
    }

    render() {
        const { workDaySettingList, isLoading } = this.state
        return (
            <div id="workday-setting-control-pane">
                <div className="workday-setting--control-section">
                    <style>
                        {
                            gridBackground
                        }
                    </style>
                    <ErrorBoundary>
                        <GridComponent
                            actionBegin={(e) => this.actionBegin(e)}
                            actionComplete={(e) => this.actionComplete(e)}
                            actionFailure={(e) => this.actionFailure(e)}
                            dataSource={workDaySettingList}
                            editSettings={this.editOptions}
                            toolbar={this.toolbarOptions}
                            enableHover={true} allowSelection={true}
                            id="workDaySettings"
                            ref={(schedule) => (this.workDaySettings = schedule)}
                            style={{ fontSize: 14 }}
                            queryCellInfo={(e) => { this.queryCellInfo(e) }}
                        >
                            <ColumnsDirective>
                                <ColumnDirective field={"rowNumber"} headerText='&nbsp;WDS' textAlign='Center' width='50'
                                    editTemplate={this.editWDS}
                                />
                                <ColumnDirective field='workStartDate' type='date' format='dd-MMM-yy' headerText='&nbsp;&nbsp;Start Date & End Date' textAlign='Left' width='120'
                                    editTemplate={this.editTemplateStart} template={this.dateStartEndDateFormat}
                                />
                                <ColumnDirective field='startTime' headerText='Day Start Time' textAlign='Center' width='70'
                                    editTemplate={this.editTemplateStartTime} template={this.viewStartTime}
                                />
                                <ColumnDirective field='endTime' headerText='Day End Time' width='70' textAlign='Center'
                                    editTemplate={this.editTemplateEndTime} template={this.viewEndTime}
                                />
                                <ColumnDirective field='timeZone' headerText='Time Zone' width='70' textAlign='center'
                                    editTemplate={this.editTemplateTimeZone} template={this.viewTimeZone}

                                />
                            </ColumnsDirective>
                            <Inject services={[Toolbar, Edit]} />
                        </GridComponent>
                    </ErrorBoundary>

                </div>
                <div>
                    {isLoading && <ProgressBar />}
                </div>
                <div
                    style={{ fontSize: '11px', padding: '10px 0px 0px 0px' }}
                    id="errorSetting"
                    className="col-12 e-input-group e-float-icon-left error-div"
                />
            </div>
        );
    }
}
WorkDaySettings.propTypes = {
    timeZoneSetting: PropTypes.arrayOf(PropTypes.object),
    authUser: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
WorkDaySettings.defaultProps = {
    timeZoneSetting: [],
    authUser: "",
};
const mapStateToProps = ({ auth, meetingmaster, meetingbooking }) => {
    const { authUser } = auth;
    const { timeZone } = meetingmaster;
    const { getNotifyFlag } = meetingbooking;

    return { authUser, timeZone, getNotifyFlag };
};
export default connect(mapStateToProps, {
    saveSettingConfig,
    addEditWorkDaySettings,
    updateMeetingBookingState,
})(WorkDaySettings);
