



/**
 * @author: Vivek Khodade.
 * @description This middleware use for home office functionality.
 * Created: 31-08-2020
 * Last Modify:11-May-2020
 * Modified By:Vivek Khodade
 */
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import axios from "axios";
import {
  ADD_EDIT_ROLE_DETAILS,
  ADD_EDIT_MODULE_DETAILS,
  ADD_EDIT_PRACTICE_AREA,
  ADD_EDIT_SUB_BUSINESS_UNIT_FUNCTION,
  ADD_EDIT_LAYER_MAPPING_FUNCTION,
  ADD_EDIT_SUB_LAYER_MAPPING_FUNCTION,
  SAVE_BUSINESS_UNIT_FUNCTION,
  SAVE_LAYER_MASTER_FUNCTION,
  SAVE_SUB_LAYER_MASTER_FUNCTION,
  SAVE_USER_ROLE_MASTER_FUNCTION,
  SAVE_FOLDER_MANAGEMENT_FUNCTION,
  ADD_EDIT_EMAIL_MAPPING
} from "../../constant/admin/adminconstant";
import { urlConfig } from "../../../config/config";
import { getNotify } from "../../../app/other/commonfunction";
import {
  ERROR,
  SUCCESS,
  practiceAreaInactivatedSuccessfully,
  practiceAreaActivatedSuccessfully,
  practiceAreaModifiedSuccessfully,
  practiceAreaAddedSuccessfully,
  practiceAreaExists,
  practiceAreaAssociated,

  layerInactivatedSuccessfully,
  layerAddedSuccessfully,
  layerModifiesSuccessfully,
  layerActivatedSuccessfully,

  sublayerInactivatedSuccessfully,
  sublayerAddedSuccessfully,
  sublayerModifiesSuccessfully,
  sublayerActivatedSuccessfully,


  folderManagementInactivatedSuccessfully,
  folderManagementActivatedSuccessfully,
  folderManagementModifiedSuccessfully,
  folderManagementAddedSuccessfully,
  folderManagementFunctionExists,
  folderManagementFunctionAssociated,
  folderCannotInactive,

  subBusinessUnitFunctionInactivatedSuccessfully, subBusinessUnitFunctionActivatedSuccessfully, subBusinessUnitFunctionModifiedSuccessfully,
  subBusinessUnitFunctionAddedSuccessfully, subBusinessUnitFunctionExists, subBusinessUnitFunctionAssociated,
  businessFunctionStatusActive, businessFunctionStatusInActive,
  businessFunctionAddedSuccessfully, businessFunctionUpdatedSuccessfully, businessFunctionExists, businessFunctionAssociated,
  layerMasterStatus, layerMasterUpdatedSuccessfully, layerMasterAddedSuccessfully, layerMasterExists, layerMasterAssociated,
  subLayerMasterStatus, subLayerMasterUpdatedSuccessfully, subLayerMasterAddedSuccessfully, sublayerMasterExists, sublayerMasterAssociated,
  userRoleAddedSuccessfully, UserRoleStatus, UserRoleUpdatedSuccessfully, UserRoleExists, UserRoleAssociated, emailMappingMasterAddStatus,
  emailMappingMasterEditStatus, emailMappingMasterErrorStatus

} from "../../../assets/constants/constants";
import {

  updateAdminReduxData,
  saveRoleModuleInfoRedux, updateBusinessFunctionRedux, updateLayerMasterRedux, updateSubLayerMasterRedux,
  updateFolderManageFunction,
  emailMappingReduxUpdate
} from "../../actions/admin/adminaction";




// Author :- VJ
const saveRoleDetailsRequest = async (data) => {
  if (data.entity_id === '') {
    delete data.entity_id;
    return axios
      .post(
        `${urlConfig.carApiUrl}role`,
        data,
        {
          headers: {
            'authorization': localStorage.getItem('appToken'),
            'Accept': 'application/json',
            "Content-Type": "application/json",
          },
        }
      )
      .then((result) => result)
      .catch((error) => error.response);
  }
  else {
    return axios
      .put(
        `${urlConfig.carApiUrl}role`,
        data,
        {
          headers: {
            // 'authorization': localStorage.getItem('appToken'),
            'Accept': 'application/json',
            "Content-Type": "application/json",
          },
        }
      )
      .then((result) => result)
      .catch((error) => error.response);
  }
};


//Author:- Yogita Ghodake
const saveBusinessUnitFunctionRequest = async (data) => {
  return axios
    .post(
      `${urlConfig.tmlcApiUrl}addeditbusinessunitfunction`,
      data,
      {
        headers: {
          'authorization': localStorage.getItem('jwtToken'),
          'Accept': 'application/json',
          "Content-Type": "application/json"
        },
      })
    .then((result) => result)
    .catch((error) => error.response);
}


// Author  @  AMit Kumar

const savePracticeAreaDetailsRequest = async (data) => {

  return axios
    .post(`${urlConfig.tmlcApiUrl}addeditpracticearea`, data, {
      headers: {
        authorization: localStorage.getItem("jwtToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((result) => result)
    .catch((error) => error.response);
};

// Author  @  AMit Kumar

const saveLayerMappingDetailsRequest = async (data) => {
  return axios
    .post(`${urlConfig.tmlcApiUrl}adminlayermapping`, data, {
      headers: {
        authorization: localStorage.getItem("jwtToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((result) => result)
    .catch((error) => error.response);
};

// Author  @  AMit Kumar

const saveSubLayerMappingDetailsRequest = async (data) => {
  return axios
    .post(`${urlConfig.tmlcApiUrl}adminsublayermapping`, data, {
      headers: {
        authorization: localStorage.getItem("jwtToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((result) => result)
    .catch((error) => error.response);
};

// Author  @  AMit Kumar

const saveSubBusinessUnitFunctionDetailsRequest = async (data) => {

  return axios
    .post(`${urlConfig.tmlcApiUrl}addeditsubbusinessunitfunction`, data, {
      headers: {
        authorization: localStorage.getItem("jwtToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((result) => result)
    .catch((error) => error.response);
};

// Author :- VJ
const saveModuleDetailsRequest = async (data) => {
  if (data.entity_id === "") {
    delete data.entity_id;
    return axios
      .post(`${urlConfig.carApiUrl}module`, data, {
        headers: {
          authorization: localStorage.getItem("appToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((result) => result)
      .catch((error) => error.response);
  } else {
    return axios
      .put(`${urlConfig.carApiUrl}module`, data, {
        headers: {
          authorization: localStorage.getItem("appToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((result) => result)
      .catch((error) => error.response);
  }
}
//
//Author:- Amit kumar
const saveUserRoleMasterFunctionRequest = async (data) => {

  return axios
    .post(
      `${urlConfig.tmlcApiUrl}addeditadminuserroledetails`,
      data,
      {
        headers: {
          'authorization': localStorage.getItem('jwtToken'),
          'Accept': 'application/json',
          "Content-Type": "application/json"
        },
      }
    )
    .then((result) => result)
    .catch((error) => error.response);
};

//Author:- Yogita Ghodake
const saveLayerMasterFunctionRequest = async (data) => {

  return axios
    .post(
      `${urlConfig.tmlcApiUrl}addeditlayermaster`,
      data,
      {
        headers: {
          'authorization': localStorage.getItem('jwtToken'),
          'Accept': 'application/json',
          "Content-Type": "application/json"
        },
      }
    )
    .then((result) => result)
    .catch((error) => error.response);
};

//Author:- Yogita Ghodake
const saveSubLayerMasterFunctionRequest = async (data) => {

  return axios
    .post(
      `${urlConfig.tmlcApiUrl}addeditsublayermaster`,
      data,
      {
        headers: {
          'authorization': localStorage.getItem('jwtToken'),
          'Accept': 'application/json',
          "Content-Type": "application/json"
        },
      }
    )
    .then((result) => result)
    .catch((error) => error.response);
};


//Author:- Noel Fredrick
const saveFolderManageFunctionRequest = async (data) => {
  return axios
    .post(
      `${urlConfig.tmlcApiUrl}addeditfoldermanagement`,
      data,
      {
        headers: {
          'authorization': localStorage.getItem('jwtToken'),
          'Accept': 'application/json',
          "Content-Type": "application/json"
        },
      })
    .then((result) => result)
    .catch((error) => error.response);
};

const addEditEmailsMapping = async (data) => {
  return axios
    .post(
      `${urlConfig.tmlcApiUrl}emailmapping`,
      data,
      {
        headers: {
          'authorization': localStorage.getItem('jwtToken'),
          'Accept': 'application/json',
          "Content-Type": "application/json"
        },
      })
    .then((result) => result)
    .catch((error) => error.response);
}


// Author :- VJ
function* saveRoleDetails({ payload }) {
  const roleDetails = yield call(saveRoleDetailsRequest, payload);
  if (roleDetails.status === 200) {
    if (roleDetails.data.response === "Updated") {

      setTimeout(() => {
        getNotify(SUCCESS, "Role updated successfully.");
      }, 1000);
    } else if (roleDetails.data.response === "Save") {

      setTimeout(() => {
        getNotify(SUCCESS, "Role added successfully");
      }, 1000);
    }
    yield put(
      saveRoleModuleInfoRedux({ action_status: 'updated' })
    );
  } else {
    getNotify(ERROR, roleDetails.data.message.replace("payload: ", ""));
  }
}
// Author :- VJ
function* saveModuleDetails({ payload }) {
  const moduleDetails = yield call(saveModuleDetailsRequest, payload);
  if (moduleDetails.status === 200) {
    if (moduleDetails.data.response === "Updated") {
      setTimeout(() => {

        getNotify(SUCCESS, "Module updated successfully.");
      }, 1000);
    } else if (moduleDetails.data.response === "Save") {
      setTimeout(() => {

        getNotify(SUCCESS, "Module added successfully");
      }, 1000);
    }
    yield put(
      saveRoleModuleInfoRedux({ action_status: 'updated' })
    );
  } else {
    getNotify(ERROR, moduleDetails.data.message.replace("payload: ", ""));
  }
}

// Author @ Amit Kumar

function* saveLayerMappingDetails({ payload }) {
  const moduleDetails = yield call(saveLayerMappingDetailsRequest, payload);
  if (moduleDetails.status === 200) {
    if (payload.isActive === "0") {
      setTimeout(() => {
        getNotify(SUCCESS, layerInactivatedSuccessfully);
      }, 1000);
    } else if (payload.isActive === "1" && payload.type === "Status") {
      setTimeout(() => {
        getNotify(SUCCESS, layerActivatedSuccessfully);
      }, 1000);
    } else if (payload.isActive === "1" && payload.type === "Edit") {
      setTimeout(() => {
        getNotify(SUCCESS, layerModifiesSuccessfully);
      }, 1000);
    } else {
      setTimeout(() => {
        getNotify(SUCCESS, layerAddedSuccessfully);
      }, 1000);
    }
    yield put(updateAdminReduxData({ layerMappingFlag: "updated" }));
  } else {
    getNotify(ERROR, moduleDetails.data.message.replace("payload: ", ""));
  }
}

// Author @ Amit Kumar

function* saveSubLayerMappingDetails({ payload }) {
  const moduleDetails = yield call(saveSubLayerMappingDetailsRequest, payload);
  if (moduleDetails.status === 200) {
    if (payload.isActive === "0") {
      setTimeout(() => {
        getNotify(SUCCESS, sublayerInactivatedSuccessfully);
      }, 1000);
    } else if (payload.isActive === "1" && payload.type === "Status") {
      setTimeout(() => {
        getNotify(SUCCESS, sublayerActivatedSuccessfully);
      }, 1000);
    } else if (payload.isActive === "1" && payload.type === "Edit") {
      setTimeout(() => {
        getNotify(SUCCESS, sublayerModifiesSuccessfully);
      }, 1000);
    } else {
      setTimeout(() => {
        getNotify(SUCCESS, sublayerAddedSuccessfully);
      }, 1000);
    }

    yield put(updateAdminReduxData({ subLayerMappingFlag: "updated" }));
  } else {
    getNotify(ERROR, moduleDetails.data.message.replace("payload: ", ""));
  }
}

// Amit Kumar
function* savePracticeAreaDetails({ payload }) {

  const moduleDetails = yield call(savePracticeAreaDetailsRequest, payload);
  if (moduleDetails.status === 200) {
    if (payload.isActive === "0") {
      setTimeout(() => {
        getNotify(
          SUCCESS,
          practiceAreaInactivatedSuccessfully
        );
      }, 1000);
    } else if (payload.isActive === "1" && payload.type === "Status") {
      setTimeout(() => {
        getNotify(
          SUCCESS,
          practiceAreaActivatedSuccessfully
        );
      }, 1000);
    } else if (payload.isActive === "1" && payload.type === "Edit") {
      setTimeout(() => {
        getNotify(SUCCESS, practiceAreaModifiedSuccessfully);
      }, 1000);
    } else {
      setTimeout(() => {
        getNotify(
          SUCCESS,
          practiceAreaAddedSuccessfully
        );
      }, 1000);
    }
    yield put(updateAdminReduxData({ practiceAreaFlag: "updated" }));
  } else {
    const errMsg = moduleDetails.data.message.replace("payload: ", "")
    if (errMsg === "exists")
      getNotify(ERROR, practiceAreaExists);
    else if (errMsg === "associated")
      getNotify(ERROR, practiceAreaAssociated);
    else
      getNotify(ERROR, moduleDetails.data.message.replace("payload: ", ""));
  }
}

// Author @ Amit Kumar

function* saveSubBusinessFunctionUnitDetails({ payload }) {
  const moduleDetails = yield call(
    saveSubBusinessUnitFunctionDetailsRequest,
    payload
  );
  if (moduleDetails.status === 200) {
    if (payload.isActive === "0") {
      setTimeout(() => {
        getNotify(
          SUCCESS,
          subBusinessUnitFunctionInactivatedSuccessfully
        );
      }, 1000);
    } else if (payload.isActive === "1" && payload.type === "Status") {
      setTimeout(() => {
        getNotify(
          SUCCESS,
          subBusinessUnitFunctionActivatedSuccessfully
        );
      }, 1000);
    } else if (payload.isActive === "1" && payload.type === "Edit") {
      setTimeout(() => {
        getNotify(SUCCESS, subBusinessUnitFunctionModifiedSuccessfully);
      }, 1000);
    } else {
      setTimeout(() => {
        getNotify(
          SUCCESS,
          subBusinessUnitFunctionAddedSuccessfully
        );
      }, 1000);
    }
    yield put(updateAdminReduxData({ subBusinessFunctionFlag: "updated" }));
  } else {
    const errMsg = moduleDetails.data.message.replace("payload: ", "")
    if (errMsg === "exists")
      getNotify(ERROR, subBusinessUnitFunctionExists);
    else if (errMsg === "associated")
      getNotify(ERROR, subBusinessUnitFunctionAssociated);
    else
      getNotify(ERROR, moduleDetails.data.message.replace("payload: ", ""));
  }
}
/**
 * @author: Yogita Ghodake.
 * @description This middleware use for Business Unit Function Deatils.
 * Created: 15-9-2021
 * */
function* saveBusinessUnitFunctionDetails({ payload }) {
  const businessFunction = yield call(saveBusinessUnitFunctionRequest, payload);
  if (businessFunction.status === 200) {
    if (payload.isActive === "0") {
      setTimeout(() => {
        getNotify(
          SUCCESS,
          businessFunctionStatusInActive
        );
      }, 1000);
    } else if (payload.isActive === "1" && payload.type === "Status") {
      setTimeout(() => {
        getNotify(
          SUCCESS,
          businessFunctionStatusActive
        );
      }, 1000);
    } else if (payload.isActive === "1" && payload.type === "Edit") {
      setTimeout(() => {
        getNotify(SUCCESS, businessFunctionUpdatedSuccessfully);
      }, 1000);
    } else {
      setTimeout(() => {
        getNotify(
          SUCCESS,
          businessFunctionAddedSuccessfully
        );
      }, 1000);
    }
    yield put(
      updateBusinessFunctionRedux({ busiess_unit_function_flag: 'updated' })
    );
  } else {
    const errMsg = businessFunction.data.message.replace("payload: ", "")
    if (errMsg === "exists")
      getNotify(ERROR, businessFunctionExists);
    else if (errMsg === "associated")
      getNotify(ERROR, businessFunctionAssociated);
    else
      getNotify(ERROR, businessFunction.data.message.replace("payload: ", ""));
  }
}


// @author: Amit Kumar.
// @description This middleware use for User Role Deatils.
// Created: 06-01-2022

function* saveUserRoleMasterFunctionDetails({ payload }) {
  const roleMaster = yield call(saveUserRoleMasterFunctionRequest, payload);
  if (roleMaster.status === 200) {
    setTimeout(() => {
      if (payload.type === "Status")
        getNotify(SUCCESS, UserRoleStatus);
      else if (payload.type === "Edit")
        getNotify(SUCCESS, UserRoleUpdatedSuccessfully);
      else
        getNotify(SUCCESS, userRoleAddedSuccessfully);
    }, 1000);

    yield put(
      updateLayerMasterRedux({ layer_master_flag: 'updated' })
    );
  } else {
    const errMsg = roleMaster.data.message.replace("payload: ", "")
    if (errMsg === "exists")
      getNotify(ERROR, UserRoleExists);
    else if (errMsg === "associated")
      getNotify(ERROR, UserRoleAssociated);
    else
      getNotify(ERROR, roleMaster.data.message.replace("payload: ", ""));
  }
}



// @author: Yogita Ghodake.
// @description This middleware use for Business Unit Function Deatils.
// Created: 20-9-2021

function* saveLayerMasterFunctionDetails({ payload }) {

  const LayerMasterFunction = yield call(saveLayerMasterFunctionRequest, payload);
  if (LayerMasterFunction.status === 200) {
    setTimeout(() => {
      if (payload.type === "Status")
        getNotify(SUCCESS, layerMasterStatus);
      else if (payload.type === "Edit")
        getNotify(SUCCESS, layerMasterUpdatedSuccessfully);
      else
        getNotify(SUCCESS, layerMasterAddedSuccessfully);
    }, 1000);

    yield put(
      updateLayerMasterRedux({ layer_master_flag: 'updated' })
    );
  } else {
    const errMsg = LayerMasterFunction.data.message.replace("payload: ", "")
    if (errMsg === "exists")
      getNotify(ERROR, layerMasterExists);
    else if (errMsg === "associated")
      getNotify(ERROR, layerMasterAssociated);
    else
      getNotify(ERROR, LayerMasterFunction.data.message.replace("payload: ", ""));
  }
}



// @author: Yogita Ghodake.
// @description This middleware use for Business Unit Function Deatils.
// Created: 20-9-2021

function* saveSubLayerMasterFunctionDetails({ payload }) {
  const SubLayerMasterFunction = yield call(saveSubLayerMasterFunctionRequest, payload);
  if (SubLayerMasterFunction.status === 200) {
    setTimeout(() => {
      if (payload.type === "Status")
        getNotify(SUCCESS, subLayerMasterStatus);
      else if (payload.type === "Edit")
        getNotify(SUCCESS, subLayerMasterUpdatedSuccessfully);
      else
        getNotify(SUCCESS, subLayerMasterAddedSuccessfully);
    }, 1000);
    yield put(
      updateSubLayerMasterRedux({ sub_layer_master_flag: 'updated' })
    );
  } else {
    const errMsg = SubLayerMasterFunction.data.message.replace("payload: ", "")
    if (errMsg === "exists")
      getNotify(ERROR, sublayerMasterExists);
    else if (errMsg === "associated")
      getNotify(ERROR, sublayerMasterAssociated);
    else
      getNotify(ERROR, SubLayerMasterFunction.data.message.replace("payload: ", ""));
  }
}


// @author: Noel Fredrick.
// @description This middleware is used for Folder Management.
// Created: 22-06-2022

function* saveFolderManageFunctionDetails({ payload }) {
  const moduleDetails = yield call(
    saveFolderManageFunctionRequest,
    payload
  );
  if (moduleDetails.status === 200) {
    if (payload.isActive === "0") {
      setTimeout(() => {
        getNotify(
          SUCCESS,
          folderManagementInactivatedSuccessfully
        );
      }, 1000);
    } else if (payload.isActive === "1" && payload.type === "Status") {
      setTimeout(() => {
        getNotify(
          SUCCESS,
          folderManagementActivatedSuccessfully
        );
      }, 1000);
    }

    else if (moduleDetails.statusCode === 400) {
      if (payload.isActive === "0" || (payload.isActive === "1" && payload.type === "Status")) {
        setTimeout(() => {
          getNotify(
            ERROR,
            folderCannotInactive
          );
        }, 1000);
      }

    } else if (payload.isActive === "1" && payload.type === "Edit") {
      setTimeout(() => {
        getNotify(SUCCESS, folderManagementModifiedSuccessfully);
      }, 1000);
    } else {
      setTimeout(() => {
        getNotify(
          SUCCESS,
          folderManagementAddedSuccessfully
        );
      }, 1000);
    }

    yield put(updateFolderManageFunction({ folderManagementFlag: "updated" }));
  } else {
    const errMsg = moduleDetails.data.message.replace("payload: ", "")
    if (errMsg === "exists")
      getNotify(ERROR, folderManagementFunctionExists);
    else if (errMsg === "associated")
      getNotify(ERROR, folderManagementFunctionAssociated);
    else if (errMsg === "Bad Request")
      getNotify(ERROR, folderCannotInactive);
    else
      getNotify(ERROR, moduleDetails.data.message.replace("payload: ", ""));
  }
}



//Author:- Abdulla Shaikh

function* saveEmailMappingDeatils({ payload }) {
  yield put(
    emailMappingReduxUpdate({ emailMappingStatus: 'Added' })
  );
  const emailMappingStatus = yield call(addEditEmailsMapping, payload);
  if (emailMappingStatus.status == 400) {
    getNotify(ERROR, emailMappingMasterErrorStatus);
  } else {
    if (emailMappingStatus.data.Result.result == 'success') {
      if (payload.emailMappingId == 0)
        getNotify(SUCCESS, emailMappingMasterAddStatus);
      else
        getNotify(SUCCESS, emailMappingMasterEditStatus);
    }
  }
  yield put(
    emailMappingReduxUpdate({ emailMappingStatus: 'updated' })
  );
}





/**
 * Function Name: saveRoleData
 * Author: VJ.
 */
export function* saveRoleData() {
  yield takeEvery(ADD_EDIT_ROLE_DETAILS, saveRoleDetails);
}
/**
 * Function Name: saveModuleData
 * Author: VJ.
 */
export function* saveModuleData() {
  yield takeEvery(ADD_EDIT_MODULE_DETAILS, saveModuleDetails);
}

// Author @ Amit Kumar

export function* addEditPracticeArea() {
  yield takeEvery(ADD_EDIT_PRACTICE_AREA, savePracticeAreaDetails);
}

// Author @ Amit Kumar

export function* addEditSubBusinessUnitFunction() {
  yield takeEvery(
    ADD_EDIT_SUB_BUSINESS_UNIT_FUNCTION,
    saveSubBusinessFunctionUnitDetails
  );
}

// Author @ Amit Kumar

export function* addEditLayerMappingFunction() {
  yield takeEvery(ADD_EDIT_LAYER_MAPPING_FUNCTION, saveLayerMappingDetails);
}

// Author @ Amit Kumar

export function* addEditLayerSubMappingFunction() {
  yield takeEvery(
    ADD_EDIT_SUB_LAYER_MAPPING_FUNCTION,
    saveSubLayerMappingDetails
  );
}

// Author : Amit Kumar 

export function* saveUserRoleMasterFunction() {
  yield takeEvery(SAVE_USER_ROLE_MASTER_FUNCTION, saveUserRoleMasterFunctionDetails);
}
//Author- Yogita Ghodake
export function* saveBusinessUnitFunction() {
  yield takeEvery(SAVE_BUSINESS_UNIT_FUNCTION, saveBusinessUnitFunctionDetails);
}

export function* saveLayerMasterFunction() {
  yield takeEvery(SAVE_LAYER_MASTER_FUNCTION, saveLayerMasterFunctionDetails);
}

export function* saveSubLayerMasterFunction() {
  yield takeEvery(SAVE_SUB_LAYER_MASTER_FUNCTION, saveSubLayerMasterFunctionDetails);
}

//Author- Noel : Folder Management
export function* saveFolderManageFunction() {
  yield takeEvery(SAVE_FOLDER_MANAGEMENT_FUNCTION, saveFolderManageFunctionDetails);
}

//Author- Abdulla Shaikh : Email Mapping

export function* addEditEmailMapping() {
  yield takeEvery(ADD_EDIT_EMAIL_MAPPING, saveEmailMappingDeatils);
}
export default function* rootSaga() {
  yield all([
    fork(saveRoleData),
    fork(saveModuleData),
    fork(addEditPracticeArea),
    fork(addEditSubBusinessUnitFunction),
    fork(addEditLayerMappingFunction),
    fork(addEditLayerSubMappingFunction),
    fork(saveBusinessUnitFunction),
    fork(saveLayerMasterFunction),
    fork(saveSubLayerMasterFunction),
    fork(saveUserRoleMasterFunction),
    fork(saveFolderManageFunction),
    fork(addEditEmailMapping)
  ]);
}


