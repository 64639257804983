import React, { useEffect, useState } from 'react'
import { HELPDESK_SUb_CATEGORY } from '../../redux/constant/meetingcategoryconstant'
import { getSubCategories } from '../../services/helpdesk/helpdeskServices'
import { connect } from 'react-redux'
import DisabledEffect from '../../utilities/disabledeffect'
import DataGrid from '../../utilities/datagrid'
import { ColumnDirective, ColumnsDirective, Filter, Inject, Page, Sort } from '@syncfusion/ej2-react-grids'
import { ADMIN_MODULE_ACTION,  getGridColumn } from '../../app/other/gridcolumns'
import { TooltipComponent } from '@syncfusion/ej2-react-popups'
import { DropDownButtonComponent } from "@syncfusion/ej2-react-splitbuttons";
import { addEditSubCategory, helpdeskReduxUpdate } from '../../redux/actions/helpdesk/helpdeskaction'
import ProgressBar from '../progressbar/progressbar'

const HelpdeskAdminGrid = ({ masterId, changePopUpHandler, gridName, openPopup, dataReload, addEditSubCategory: updateSubCategory, helpdeskReduxUpdate: reduxUpdate }) => { // NOSONAR

    const [data, setData] = useState([])
    const [state, setState] = useState({ tableName: "", gridWidth: document.getElementById('helpdesk-admin-container-root')?.clientWidth, })
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        initialLoad();
        window.onresize = () => {
            let gridWidth =
                (window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) - 85;
            setState((preState) => ({ ...preState, gridWidth }));
        }
    }, [])

    useEffect(() => {
        dataReload && initialLoad();
    }, [dataReload])

    const initialLoad = async () => {
        setLoading(true)
        let result = [];
        if (masterId === HELPDESK_SUb_CATEGORY) {
            setState(prevState => ({ ...prevState, tableName: "Sub Category" }))
            const res = await getSubCategories(0, 0)
            result = res.map((subCat) => {
                let status;
                if (subCat.Disabled === null || subCat.Disabled === false) status = 'Active'
                else status = 'Inactive';
                return {
                    ...subCat,
                    Disabled: status
            }
            })
            setData(result);
            reduxUpdate({ dataReload: false })
        }
        setLoading(false)
    }

    const isSixteenHundred = window.innerHeight < 782;
    const isOneTwentyFiveScale = window.innerHeight < 755;
    const isOneTwentyFiveInThirteenHundred = window.innerHeight < 570;
    const isTenEightyHundred = window.innerHeight > 870 && window.innerHeight < 900;
    const isThirteenHundredBookmarkOn = window.innerHeight < 620;
    const isNineteenTwentyTenEightyOneTwentyFive = window.innerHeight < 715 && window.innerHeight > 700;
    const isSixteenEightyTEnFiftyOneTwentyFive = window.innerHeight < 700 && window.innerHeight > 650;
    const pageSettings = {
        pageCount: 5,
        pageSize:
            isOneTwentyFiveInThirteenHundred ? 5
                : isThirteenHundredBookmarkOn ? 6 // NOSONAR
                    : isSixteenEightyTEnFiftyOneTwentyFive ? 8 // NOSONAR
                        : isNineteenTwentyTenEightyOneTwentyFive ? 8 // NOSONAR
                            : isOneTwentyFiveScale ? 9 // NOSONAR
                                : isSixteenHundred ? 11 // NOSONAR
                                    : isTenEightyHundred ? 12 // NOSONAR
                                        : (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight) > 767 ? 13 // NOSONAR
                                            : 7
    }
    const gridHeight = (window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight) - 275
    const gridLines = "Both";
    const allowTextWrap = true;
    const allowSelection = false;
    const filterSettings = { type: 'CheckBox' };

    let finalWidthConfirm;
    let widthColumnConfirm = 0;
    getGridColumn(gridName)?.forEach((rowData) => {
        widthColumnConfirm = widthColumnConfirm + 20 + Number(rowData.width);
    });

    if (widthColumnConfirm <= state.gridWidth) {
        finalWidthConfirm = widthColumnConfirm;
    }
    else {
        finalWidthConfirm = state.gridWidth
    }

    const columnDirectives = () => {
        return (
            <ColumnsDirective>
                {getGridColumn(gridName).map((item, Index) => {
                    switch (item.colName) { //NOSONAR
                        case ADMIN_MODULE_ACTION:
                            return (
                                <ColumnDirective
                                    template={actionTemplate}
                                    textAlign={`${item.align} !important`}
                                    field={item.field}
                                    headerText={item.colName}
                                    width={item.width}
                                    filter={false}
                                    allowFiltering={false}
                                />
                            );
                        default:
                            return (
                                <ColumnDirective
                                    key={item.field}
                                    textAlign={item.align}
                                    field={item.field}
                                    headerText={item.colName}
                                    width={item.width}
                                    template={commonCellTemplate}
                                />
                            );
                    }
                })}
            </ColumnsDirective>
        )
    }

    const gridEjectParameters = () => {
        return (
            <Inject services={[Page, Filter]} />
        )
    }

    const commonCellTemplate = (rowData) => {
        if (rowData[rowData.column.field] && rowData[rowData.column.field]?.length > 37 && +rowData['column']['width'] <= 310) {
            return (
                <div
                    className="col-12 helpdesk-common-cell"
                    style={{ cursor: 'pointer' }}
                >
                    <div>
                        <TooltipComponent content={rowData[rowData.column.field]} position="BottomCenter" cssClass="helpdesk-grid-popup" style={{ fontSize: '14px' }}>
                            <span className="mr-sm">{rowData[rowData.column.field].slice(0, 37) + '...'}</span>
                        </TooltipComponent>
                    </div>
                </div>
            );
        } else {
            return (
                <div
                    className="col-12 helpdesk-common-cell"
                >
                    <div>
                        <span className="mr-sm">{rowData[rowData.column.field]}</span>
                    </div>
                </div>)
        }
    };

    const itemActive = [
        {
            text: "Edit",
            iconCss: "e-icons e-edit",
        },
        {
            text: "Inactive",
            iconCss: "fas fa-toggle-off",
        },
    ];
    const itemInactive = [
        {
            text: "Edit",
            iconCss: "e-icons e-edit",
        },
        {
            text: "Active",
            iconCss: "fas fa-toggle-on",
        },
    ];


    const actionTemplate = (rowData) => {
        try {
            const onSelect = (args) => {
                if (args.item.text === "Edit") {
                    //Open Popup for update
                    changePopUpHandler({ type: 'edit', editItemId: rowData.SubCategoryId })
                }
                else if (args.item.text === "Active") {
                    let activeData = {
                        CategoryId: rowData.CategoryId,
                        SubCategoryId: rowData.SubCategoryId,
                        SubCategoryName: rowData.SubCategoryName,
                        JitbitId: rowData.JitbitId,
                        Disabled: 0,
                    };
                    updateSubCategory(activeData)
                } else if (args.item.text === "Inactive") {
                    let inActiveData = {
                        CategoryId: rowData.CategoryId,
                        SubCategoryId: rowData.SubCategoryId,
                        SubCategoryName: rowData.SubCategoryName,
                        JitbitId: rowData.JitbitId,
                        Disabled: 1,
                    };
                    updateSubCategory(inActiveData)
                }
            };
            return (
                <div
                    className="col-12"
                    style={{
                        textAlign: "center",
                        padding: "0px 20px 0px 0px",
                        fontSize: '14px',
                        color: '#000 !important',
                        fontFamily: 'Arial !important',
                        fontWeight: 500,
                        letterSpacing: '0.03rem',
                    }}
                >
                    <div className="row1">
                        <DropDownButtonComponent
                            items={( rowData.Disabled === 'Active') ? itemActive : itemInactive}
                            select={onSelect}
                            iconCss="e-icons e-more-vertical-2"
                            cssClass="e-caret-hide e-custom e-vertical"
                            iconPosition="Top"
                        ></DropDownButtonComponent>
                    </div>
                </div>
            );
        } catch (error) {
        }
    };



    return (
        <>
            {(openPopup === 'add' || openPopup === 'edit') &&
                <DisabledEffect
                    height={document.getElementById('helpdesk-admin-container-root').clientHeight}
                    width={document.getElementById('helpdesk-admin-container-root').clientWidth}
                    cssClass='helpdesk-admin-disabled-screen'
                />}
            <div
                id='helpdesk-admin-grid'
                className="col-xs-12 col-sm-12 col-lg-12 col-md-12"
            >
                {data.length > 0 && <DataGrid
                    id="helpdesk-admin-data-grid"
                    mainTitle={state.tableName}
                    columnDirectives={columnDirectives}
                    gridEjectParameters={gridEjectParameters}
                    allowPaging={true}
                    gridLines={gridLines}
                    dataSource={data}
                    pageSettings={pageSettings}
                    allowTextWrap={allowTextWrap}
                    allowSelection={allowSelection}
                    filterSettings={filterSettings}
                    height={gridHeight}
                    width={finalWidthConfirm}
                    windowWidth={state.gridWidth}
                    showGridHeader={false}
                    allowSorting={false}
                />}
            </div>
            {
                loading && (
                    <div
                        className='loader-new'
                    >
                        <ProgressBar />
                    </div>
                )
            }
        </>
    )
}

const mapStateToProps = ({ helpdeskreducer }) => {
    const { openPopup, dataReload } = helpdeskreducer;
    return {
        openPopup,
        dataReload
    }
}

export default connect(mapStateToProps, { addEditSubCategory, helpdeskReduxUpdate })(HelpdeskAdminGrid)