/**
 * Author: Suresh R. Sargam.
 * Description: To upload the candidae/employee documents
 * Last Modify:15-May-2020
 */

import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { Button, Dialog } from "@material-ui/core";
import NotificationContainer from "../../../components/notification/notifycontainer";
import Dropzone from "../dropzone/dropzone";
import "./upload.css";
import UplProgress from "../uplprogress/uplprogress";
import { urlConfig, corsallow } from "../../../config/config";
import {
    DialogTitleTMLC,
    DialogContent,
    DialogActions,  
} from "../../customdialog/customdialog";
import ErrorBoundary from "../../error/errorboundary";
import { updateDocumentInfo } from "../../../redux/actions/entryexit/onboardingaction";
import { getNotify } from "../../../app/other/commonfunction";
import { WARNING, SUCCESS, ERROR } from "../../../assets/constants/constants";
import {
    NEW_CANDIDATE_UPLOAD,
    EMPLOYEE_UPLOAD,
} from "../../../redux/constant/entryexit/candidateconstant";

class Upload extends Component {
    constructor(props) {
        super(props);
        const { selectedFolderId, folderPath } = this.props;
        this.state = {
            files: [],
            uploading: false,
            uploadProgress: {},
            successfullUploaded: false,
            selectedFolderId: selectedFolderId,
            folderPath: folderPath,
            allowmultiple: false,
        };
        this.filesuploaded = false;
        this.onFilesAdded = this.onFilesAdded.bind(this);
        this.uploadFiles = this.uploadFiles.bind(this);
        this.sendRequest = this.sendRequest.bind(this);
        this.renderActions = this.renderActions.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.handleDocTypeSelection = this.handleDocTypeSelection.bind(this);
    }

   /**
   * @author Suresh R. Sargam
   * @argument addedfiles
   * @description called this function once the file is selected or drag and dropped
   */
    // Send Link template
    onFilesAdded(addedfiles) {
        // Clear the alredy uploaded files if multiple is false
        const { allowmultiple, files } = this.state;
        if (!allowmultiple && files.length > 0) {
            this.setState({ files: addedfiles });
        } else {
            this.setState((prevState) => ({
                files: prevState.files.concat(addedfiles),
            }));
        }
    }
    /**
    * @author Suresh R. Sargam   
    * @description close the upload screen and refresh document section as required.
    */
    handleClose = () => {
        if (this.filesuploaded) {
            // eslint-disable-next-line
            this.props.updateDocumentInfo({
                openDocumentPopup: false,
                refreshDocStatus: "doc_upload",
            });
        } else {
            // eslint-disable-next-line
            this.props.updateDocumentInfo({ openDocumentPopup: false });
        }
    };

    /**
    * @author Suresh R. Sargam   
    * @description document type selection change event
    */
    handleDocTypeSelection = (e) => {
        try {
            this.setState({
                selectedFolderId: e.itemData.Id,
                allowmultiple: e.itemData.AllowMultiple,
                folderPath: e.itemData.folderPath,
                successfullUploaded: false,
                files: [],
            });
        } catch (error) { }
    };

    /**
   * @author Suresh R. Sargam   
   * @description final submission/uploading of document into server asyncro
   */
    async uploadFiles() {               
        const { files } = this.state;
        if (files.length === 0) {
            getNotify(WARNING, "Please select browse file to upload.");
        } else {
            this.setState({ uploadProgress: {}, uploading: true });
            const promises = [];
            files.forEach((file) => {
                promises.push(this.sendRequest(file));
            });
            try {
                await Promise.all(promises);
                /* nosonar getNotify(SUCCESS, "File uploaded successfully");
                setTimeout(() => {
                    this.props.updateDocumentInfo({
                        openDocumentPopup: false,
                        refreshDocStatus: "doc_upload",
                    });
                    this.setState({ successfullUploaded: true, uploading: false });
                }, 2000)*/
            } catch (e) {
                getNotify(ERROR, "Error while file upload");
            }
        }
    }

    sendRequest(file) {
        return new Promise((resolve, reject) => {
            const req = new XMLHttpRequest();
            req.upload.addEventListener("progress", (event) => {
                if (event.lengthComputable) {
                    // eslint-disable-next-line
                    const copy = { ...this.state.uploadProgress };
                    copy[file.name] = {
                        state: "pending",
                        percentage: (event.loaded / event.total) * 100,
                    };
                    this.setState({ uploadProgress: copy });
                }
            });
            req.upload.addEventListener("load", () => {
                // eslint-disable-next-line
                const copy = { ...this.state.uploadProgress };
                copy[file.name] = { state: "done", percentage: 100 };
                this.setState({ uploadProgress: copy });
                this.filesuploaded = true;
                resolve(req.response);
            });

            req.upload.addEventListener("error", () => {
                // eslint-disable-next-line
                const copy = { ...this.state.uploadProgress };
                copy[file.name] = { state: "error", percentage: 0 };
                this.setState({ uploadProgress: copy });
                reject(req.response);
            });

            try {
                const formData = new FormData();
                const { authUser, candidateType, empid, candidateid } = this.props;
                const { selectedFolderId } = this.state;
                formData.append(
                    "usertype",
                    candidateType === EMPLOYEE_UPLOAD ? "E" : "C"
                );
                formData.append("type", "D");
                formData.append("filepath", file.folderPath);
                formData.append("folderid", selectedFolderId);
                formData.append("isuploadedbycand", "N");
                formData.append(
                    "cand_emp_id",
                    candidateType === EMPLOYEE_UPLOAD ? empid : candidateid
                );
                formData.append("file", file, file.name);
                formData.append("fileSize", file.size);

                if (candidateType === NEW_CANDIDATE_UPLOAD) {
                    formData.append("isuploadedbycand", "N");
                    formData.append("userid", 0);
                } else {
                    formData.append("userid", authUser);
                }
                // If uploaded by candidate pass this parameter
                req.open("POST", `${corsallow}${urlConfig.tmlcApiUrl}document/upl`);
                if (candidateType !== NEW_CANDIDATE_UPLOAD) {
                    req.setRequestHeader("Authorization", localStorage.getItem("jwtToken"));
                }
                req.send(formData);
                req.onreadystatechange = () => {
                    getNotify(SUCCESS, "File uploaded successfully");
                    // setTimeout(() => {
                    this.props.updateDocumentInfo({
                        openDocumentPopup: false,
                        refreshDocStatus: "doc_upload",
                    });
                    this.setState({ successfullUploaded: true, uploading: false });
                // }, 2000)
                }

            } catch (error) {
                console.log(error, 'Upload')

            }
        });
    }

    /**
     * @author Suresh R. Sargam   
     * @description show progress bar once the document is uploaded into server.
     */
    renderProgress(file) {
        // eslint-disable-next-line
        const uploadProgress = this.state.uploadProgress[file.name];

        const { uploading, successfullUploaded } = this.state;
        if (uploading || successfullUploaded) {
            return (
                <div className="ProgressWrapper">
                    <UplProgress
                        progress={uploadProgress ? uploadProgress.percentage : 0}
                    />
                    <img
                        className="CheckIcon"
                        // style={{ opacity: 0.5 ,marginLeft: '32px'}}
                        alt="done"
                        src="images/baseline-check_circle_outline-24px.svg"
                        style={{
                            opacity: 0.5,
                                // uploadProgress && uploadProgress.state === "done" ? 0.5 : 0.5,
                        }}
                    />
                </div>
            );
        }
        return <></>;
    }

    /**
     * @author Suresh R. Sargam   
     * @description displaying buton based on user action like uploading or clearing the content.
     */

    renderActions() {
        const { successfullUploaded, files, uploading } = this.state;
        if (successfullUploaded) {
            return (
                <>
                    {/* <Button
                        color="default"
                        variant="contained"
                        onClick={() =>
                            this.setState({ files: [], successfullUploaded: false })
                        }
                    >
                        Clear
                    </Button> */}
                    {/* <Button
                        onClick={this.handleClose}
                        color="default"
                        variant="contained"
                    >
                        Close
                    </Button> */}
                </>
            );
        }
        return (
            <>
                <Button
                    id="upl"
                    color="primary"
                    variant="contained"
                    style={{ color: files.length <= 0 || uploading ? "#c1baba" : "white" }}
                    disabled={files.length <= 0 || uploading}
                    onClick={this.uploadFiles}
                    title={"Upload"}
                    >
                    Upload
                </Button>
                {/* <Button onClick={this.handleClose} color="default" variant="contained">
                    Close
                </Button> */}
            </>
        );
    }

    render() {
        const { openDocumentPopup, categoryName, folders } = this.props;
        const {
            selectedFolderId,
            folderPath,
            uploading,
            successfullUploaded,
            files,
        } = this.state;
        return (
            <>
                <Dialog
                    aria-labelledby="uploading-title"
                    open={openDocumentPopup}
                    onClose={this.handleClose}
                    fullWidth
                    width="1000"                   
                >
                    <DialogTitleTMLC id="uploading-title" onClose={this.handleClose}>
                        Upload {categoryName}
                    </DialogTitleTMLC>
                    <ErrorBoundary>
                        <DialogContent dividers>
                            <div>
                                <NotificationContainer />
                                <DropDownListComponent
                                    value={selectedFolderId}
                                    variant="outlined"
                                    select={(e) => this.handleDocTypeSelection(e)}
                                    floatLabelType="Auto"
                                    fields={{ text: "DocumenytType", value: "Id" }}
                                    id="folders"
                                    dataSource={folders}
                                    cssClass="e-outline"
                                    placeholder="Document Type"
                                    width="100%"
                                    enabled={false}
                                />
                            </div>
                            <div className="Upload">
                                <div className="Content">
                                    <div style={{ textAlign: '-webkit-center' }}>
                                        <Dropzone
                                            onFilesAdded={this.onFilesAdded}
                                            folderPath={folderPath}
                                            categoryName={categoryName}
                                            disabled={uploading || successfullUploaded}
                                        />
                                    </div>
                                    <div className="Files">
                                        {files.map((file) => {
                                            return (
                                                <div key={file.name} className="FileRow">
                                                    <span className="Filename">{file.name}</span>
                                                    {this.renderProgress(file)}
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions>{this.renderActions()}</DialogActions>
                    </ErrorBoundary>
                </Dialog>
            </>
        );
    }
}

Upload.propTypes = {
    selectedFolderId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    folderPath: PropTypes.string.isRequired,
    candidateType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    authUser: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    empid: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    candidateid: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    openDocumentPopup: PropTypes.bool,
    categoryName: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    folders: PropTypes.arrayOf(PropTypes.object).isRequired,
    updateDocumentInfo: PropTypes.func,
};
Upload.defaultProps = {
    openDocumentPopup: false,
    empid: 0,
    candidateid: 0,
    updateDocumentInfo: {},
};

const mapStateToProps = ({ onboardingreducer, auth }) => {
    const { authUser } = auth;
    const {
        openDocumentPopup,
        candidateid,
        refreshDocStatus,
        empid,
    } = onboardingreducer;
    return {
        authUser,
        openDocumentPopup,
        candidateid,
        refreshDocStatus,
        empid,
    };
};

export default connect(mapStateToProps, {
    updateDocumentInfo,
})(Upload);
