import React, { Component } from 'react';

import orgChatMaster from "../../../services/entryexit/onbordingServices";
import { getNotify } from '../../../app/other/commonfunction';
import {
  AutoCompleteComponent
} from "@syncfusion/ej2-react-dropdowns";
import Progressbar from '../../progressbar/progressbar';
import { SwitchComponent } from "@syncfusion/ej2-react-buttons";
import ErrorMessage from "../../errormessage/errormessage";
import { ERROR } from "../../../assets/constants/constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/pro-light-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
library.add(faTrashAlt)

class OtherCalendarAccessPopup extends Component {
  constructor(props) {/**NOSONAR */
    super(props);
    this.state = {
      OtherCalendarAccessData: [],
      selectedUserId: null,
      isCalendarDataLoading: false,

    }
    this.selectedUser = null
    this.getSelectedAttendee = this.getSelectedAttendee.bind(this);
  }

  componentDidMount() {
    this.setState({ isCalendarDataLoading: true }, this.getData)
  }
  getData = async () => {
    try {
      const { employeeId } = this.props
      const { OtherCalendarAccessData } = this.state

      if (OtherCalendarAccessData.length === 0) {
        const OtherCalendarAccessDataRes = Promise.resolve(orgChatMaster.getCalendarAccessList(employeeId, 0));
        OtherCalendarAccessDataRes.then((data) => {
          if (data !== null) {
            this.setState({ OtherCalendarAccessData: data.result, isCalendarDataLoading: false });
          }
        })
      }

    } catch (error) {
      // eslint-disable-next-line
      console.log("error", error);
    }
  }
  getSelectedAttendee(e) {
    const selectedUser = e.itemData.UserId
    const { employeeId } = this.props
    const { OtherCalendarAccessData } = this.state
    // console.log(e)
    const data2 = {
      accessList: selectedUser,
      type: "ADD",
      edit: "0",
      accessibleBy: employeeId
    }
    const isExist = OtherCalendarAccessData.filter(val => val.userId === selectedUser)
    if (isExist.length === 0) {
      this.props.addEditCalendarAccess(data2);
      try {
        const OtherCalendarAccessDataRes = Promise.resolve(orgChatMaster.getCalendarAccessList(employeeId, selectedUser));
        OtherCalendarAccessDataRes.then((data) => {
          // console.log(data.result[0])
          if (data !== null) {
            this.selectedUser.text = null
            this.setState(prev => ({ OtherCalendarAccessData: [...data.result, ...prev.OtherCalendarAccessData] }));
          }
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log("error", error);
      }
    }
    else {
      this.selectedUser.text = null
      getNotify(ERROR, "Team Member Already Added".replace("payload: ", ""));
    }
  }
  removeAccess = (id) => {
    const { OtherCalendarAccessData } = this.state
    const { employeeId } = this.props
    const data2 = {
      accessList: id,
      type: "DELETE",
      edit: "0",
      accessibleBy: employeeId
    }
    this.setState({ isCalendarDataLoading: true })
    this.props.addEditCalendarAccess(data2);
    const newData = OtherCalendarAccessData.filter(e => e.userId !== id)
    this.setState({ OtherCalendarAccessData: newData, isCalendarDataLoading: false });
  }
  handleSwitch = (id, access) => {
    const { OtherCalendarAccessData } = this.state
    const { employeeId } = this.props
    const data2 = {
      accessList: id,
      type: "EDIT",
      edit: access ? '1' : '0',
      accessibleBy: employeeId
    }
    this.props.addEditCalendarAccess(data2);
    const indexData = OtherCalendarAccessData.findIndex((e) => (e.userId === id))
    const remain = OtherCalendarAccessData
    try {
      const OtherCalendarAccessDataRes = Promise.resolve(orgChatMaster.getCalendarAccessList(employeeId, id));
      OtherCalendarAccessDataRes.then((data) => {
        if (data !== null) {
          // console.log(data)
          remain.splice(indexData, 1, data.result[0])
          this.setState({ OtherCalendarAccessData: remain });
        }
      })
    } catch (error) {
      // eslint-disable-next-line
      console.log("error", error);
    }
  }

  render() {
    const { othersCheckAvailablity } = this.props
    const { OtherCalendarAccessData, isCalendarDataLoading } = this.state
    return (<>{!isCalendarDataLoading ? <>
      <div className='col-lg-6 col-md-6 col-sm-12 col-xs-12 mr-3 autocompletestyle'>

        <AutoCompleteComponent
          id="search-attendee"
          ref={(autocomplete) => {
            this.selectedUser = autocomplete;
            return this.selectedUser;
          }}
          cssClass="e-outline"
          floatLabelType="Auto"
          fields={{ value: "nameWithDepartment", Id: "UserId" }}
          placeholder="Select Team Member *"
          select={this.getSelectedAttendee}
          data-msg-containerid="maError"
          dataSource={othersCheckAvailablity}
        />
        <div
          id="notificationToErr"
          className="e-input-group e-float-icon-left error-div"
        />
      </div>
      <div className='row1 '>
        <div className='col-lg-12 col-md-12 col-sm-12' style={{ height: '42px', backgroundColor: '#F6F6F6', }}>
          <div className='col-lg-4 col-md-4 col-sm-4 pt-sm'>Team Member</div>
          <div className='col-lg-4 col-md-4 col-sm-4 pt-sm'>Practice Area / Sales Unit / Sub Function</div>
          <div className='col-lg-2 col-md-2 col-sm-2 pt-sm'>Edit Access</div>
          <div className='col-lg-2 col-md-2 col-sm-2 pt-sm'>Remove Access</div>
        </div>
        <div className='height424'>
          {OtherCalendarAccessData.length > 0 && OtherCalendarAccessData.map((item, inx) => {
            return (
              <div className='col-lg-12 col-md-12 col-sm-12' style={{ height: '54px', borderBottom: '1px solid #F6F6F6' }} key={"othercalendaraccessedit" + item.userId}>
                <div className='col-lg-4 col-md-4 col-sm-4 pt-md'>{item.userName}</div>
                <div className='col-lg-4 col-md-4 col-sm-4 pt-md'>{item.practiceAreaName}</div>
                <div className='col-lg-2 col-md-2 col-sm-2 pt-md switchmargin' >
                  <SwitchComponent
                    id="editAccess"
                    checked={String(item.editAccess) === '1' ? true : false}
                    change={() => {
                      this.handleSwitch(item.userId, String(item.editAccess) === '1' ? false : true)
                    }
                    }
                  />
                </div>
                <div className='col-lg-2 col-md-2 col-sm-2 pt-sm switchmargin'>
                  <FontAwesomeIcon icon={faTrashAlt}
                    style={{ fontSize: 20, color: "black", backgroundColor: "#F6F6F6" }}
                    className="pointer-cursor"
                    onClick={() => this.removeAccess(item.userId)} />
                </div>

              </div>
            )
          }
          )}
          <div className='col-lg-12 col-md-12 col-sm-12' style={{ height: '54px', borderBottom: '1px solid #F6F6F6', marginTop: 20 }}>
            {OtherCalendarAccessData.length === 0 &&
              <ErrorMessage message="No Data Available" />
            }
          </div>
        </div>
      </div>
    </> :
      <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", height: "500px" }}>
        <Progressbar />
      </div>
    }</>)
  }

}

export default OtherCalendarAccessPopup