/**
 * Author: Abdulla Shaikh.
 * Created:22-Feb-2023
 * @description to view all HelpDesk Service request
 */

import React, { Component } from 'react';
import { connect } from 'react-redux'
import { addNewServiceRequest, addNewIdeas, addUpdateHelpDeskRedux } from '../../redux/actions/helpdesk/helpdeskaction'
import AddServiceRequest from './addservicerequest';

import TopTicketPopup from "./topticketpopupcontainer"
import "./helpdeskservice.css";
import TopTickets from './toptickets';
import { getCategoriesAndSections } from '../../services/helpdesk/helpdeskServices'

import ButtonComponent from "../../utilities/buttoncomponent"

import leftExit from "../../../src/assets/svg/left-exit.svg";
import rightExit from "../../../src/assets/svg/right-exit.svg";
import DisabledEffect from '../../utilities/disabledeffect';
import { dencrypt } from '../../app/other/commonfunction';
import queryString from "query-string";
import { sourceToCategoryMapping } from '../../redux/constant/constant';
class HelpDeskService extends Component {
	constructor(props) {
		super(props);
		this.state = {
			openPopup: false,
			containerHeight: null,
			height: null,
			notifiresPopupHeight: null,
			categoriesAndSections: [],
			selectedTopTicket: {},
			topTicketOpen: false,
			resolution: <></>,
			functionName: "",
			categoryId: null,
			functionId: null,
			sectionData: []
		};
		this.clickChildSumit = null;
		this.clickChildCancel = null;
		this.selectedTicketData = this.selectedTicketData.bind(this);
		this.topTicketOpenClick = this.topTicketOpenClick.bind(this);
	}

	setFunctionName = (functionName) => {
		this.setState({ functionName });
	};

	topTicketOpenClick() {
		this.setState({
			topTicketOpen: !this.state.topTicketOpen,
		});
	}
	changePopUpHandler = (resolution) => {
		this.setState({
			openPopup: !this.state.openPopup,
			resolution: resolution,
		});
	};
	componentDidMount() {
		window.addEventListener("resize", this.updateDimensions());
		this.getData();
	}
	updateDimensions = () => {
		const isThirteenHundred = window.innerHeight < 650;
		const minusHeight = isThirteenHundred ? 125 : 136;

		const updateheight = window.innerHeight - 125;
		const lavContHeight = window.innerHeight - 235;
		const containerHeight = window.innerHeight - minusHeight;
		this.setState({
			containerHeight: containerHeight,
			notifiresPopupHeight: updateheight,
			height: lavContHeight,
		});
	};
	getData = async () => {
		const sourceId= dencrypt(queryString.parse(this.props.location.search).sourceId ?? "");
		const helpdeskSectionToHide = [parseInt(process.env.REACT_APP_HD_SECTION_TO_HIDE)];
		const categoriesAndSections = await Promise.all(
			await getCategoriesAndSections()
		);
		const sectionData = [
			...new Set(categoriesAndSections.map((obj) => obj.SectionID)),
		].map((SectionID) => {
			return categoriesAndSections.find((obj) => obj.SectionID === SectionID);
		}).filter((obj) => !helpdeskSectionToHide.includes(obj.SectionID));
		if (Object.keys(sourceToCategoryMapping).includes(sourceId + "")) {
			this.setState({
				functionId: sourceToCategoryMapping[sourceId]["function"],
				categoryId:  sourceToCategoryMapping[sourceId]["category"]
			});
		}
		this.setState({
			categoriesAndSections: categoriesAndSections,
			sectionData: sectionData,
		});
	};
	getSubmitData = (data) => {
		let requestData = {
			categoryId: data.category,
			body: data.description,
			subject: data.subject,
			priorityId: data.priority,
			uploadFile: data.fileList,
			fileName: data.fileNameList,
			subCategory: data.subCategory,
			customSubCategoryId: data.customSubCategoryId,
			clientImpact: data.clientImpact === true ? 1 : 2,
		};
		this.props.addUpdateHelpDeskRedux({ uploadDocsLoading: true });
		this.props.addNewServiceRequest(requestData);
	};

	submitNewIdea = (data) => {
		this.props.addNewIdeas(data);
	};

    selectedTicketData(data){
        this.setState({selectedTopTicket:{}})
        setTimeout(()=>{
            this.setState({selectedTopTicket:data})
        },100)
    }

	render() {
		const { type } = this.props;
		const { categoriesAndSections, sectionData, selectedTopTicket } =this.state;       

		return (
			<>
				<div className='service-title p-md ml-md'>
					{type == "idea" ? "New Idea" : " New Service Request"}
				</div>
				<div
					className={
						"top-ticket-button" + (this.state.openPopup ? " disabled" : "")
					}
					onClick={this.topTicketOpenClick}
				>
					{/* <FontAwesomeIcon id="box" icon={this.state.topTicketOpen ? faArrowLeftFromLine:faArrowRightFromLine} className="topbutton-icon"  /> */}
					{this.state.topTicketOpen ? (
						<img className='exit-icon' alt='' src={leftExit} />
					) : (
						<img className='exit-icon' alt='' src={rightExit} />
					)}
					{type == "idea" ? "Ideas" : "Top Service Requests"}
				</div>

				<div
					id='new-service-req-container'
					className='col-xs-12 col-sm-12 col-lg-12 col-md-12 leave-application-body'
					style={{
						backgroundColor: "#ffffff",
						height: this.state.containerHeight,
						border: "1px solid #ccc",
						borderRadius: "5px",
						boxShadow: "0.5px 0.5px #ccc",
						margin: "15px",
						marginTop: "0px",
						width: "98.4%",
					}}
				>
					{/* <NotificationContainer /> */}
					{this.state.openPopup && (
						<DisabledEffect
							height={document.getElementById("DisabledEffect")?.clientHeight}
							width={document.getElementById("DisabledEffect")?.clientWidth}
						/>
					)}
					<div
						className='col-xs-12 col-sm-12 col-lg-12 col-md-12 circular-regular'
						style={{
							display: "flex",
							flexDirection: "column",
							justifyContent: "space-between",
						}}
					>
						<div
							className='container circular-regular pl-sm pr-sm'
							style={{
								display: "flex",
								height: this.state.containerHeight - 58,
								width: "100%",
								overflowY: "hidden",
								overflowX: "hidden",
							}}
						>
							{/* First Half */}
							<div
								style={{
									border: !this.state.topTicketOpen && "1px solid #ccc",
									borderRadius: !this.state.topTicketOpen && "5px",
									boxShadow: !this.state.topTicketOpen && "0.5px 0.5px #ccc",
									height: this.state.containerHeight - 68,
									// overflowY: !this.state.topTicketOpen && 'auto'
								}}
								className={`leave-application-body helpdesk-modal-scroll circular-regular mt-sm ${
									this.state.topTicketOpen
										? "col-lg-12 col-md-12 col-sm-12 col-xs-12"
										: "col-lg-7 col-md-7 col-sm-7 col-xs-7"
								}`}
							>
								<div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 '>
									{sectionData.length > 0 && <AddServiceRequest
										setSubmitClick={(click) => (this.clickChildSumit = click)}
										setCancelClick={(click) => (this.clickChildCancel = click)}
										type={type}
										categoriesAndSections={categoriesAndSections}
										sectionData={sectionData}
										getSubmitData={
											type == "idea" ? this.submitNewIdea : this.getSubmitData
										}
										selectedTopTicket={selectedTopTicket}
										setFunctionName={this.setFunctionName}
										categoryId={this.state.categoryId}
										functionId={this.state.functionId}
									/>}
								</div>
							</div>
							{/* Second Half */}
							{!this.state.topTicketOpen && (
								<div
									className='col-lg-5 col-md-5 col-sm-5 col-xs-5 leave-application-body mt-sm ml-md tabcontent-leave'
									style={{
										borderRadius: "5px",
										fontFamily: "Circular Std Book",
										fontWeight: 400,
										color: "#848484",
										padding: 0,
										height: this.state.containerHeight - 68,
									}}
								>
									<TopTickets
										changePopUpHandler={this.changePopUpHandler}
										type={type}
										selectedTicketData={this.selectedTicketData}
										functionName={
											this.state.functionName !== "" ?
											sectionData?.filter(
												(sec) => sec.SectionID === this.state.functionName
											)[0]?.Section
											:
											""
										}
									/>
								</div>
							)}
						</div>
                        <div className='col-lg-12 col-md-2 col-sm-12 col-xs-12 pb-sm pt-sm pl-md' style={{ paddingRight: 0 }}>
                            <div className="pr-sm" style={{ float: "right" }}>
                                {/* <button className="leave-cancel-apply-button" style={{width:"75px",height:"35px"}} >
                                Cancel
                            </button> */}
                                <ButtonComponent
                                    id="helpdeskcancel" 
                                    style={{fontSize:"1rem"}}
									cssClass={'circular-regular'}
                                    content="Reset"
                                    variant="contained"
                                    click={() => this.clickChildCancel()}
                                />
								<ButtonComponent
									id='helpdesksubmit'
									type='primary'
									style={{ fontSize: "1rem" }}
									cssClass={'circular-regular'}
									content='Submit'
									variant='contained'
									disabled={this.props.uploadDocsLoading ? true : false}
									click={() => this.clickChildSumit()}
								/>
							</div>
						</div>

						{this.state.openPopup && (
							<div>
								<TopTicketPopup
									changePopUpHandler={this.changePopUpHandler}
									openPopup={this.state.openPopup}
									resolution={this.state.resolution}
								/>
							</div>
						)}
					</div>
				</div>
			</>
		);
	}
}
const mapStateToProps = ({ helpdeskreducer, router }) => {
    const {uploadDocsLoading}=helpdeskreducer;
	const { location } = router;
    return {
        uploadDocsLoading,
		location
    }
}
export default connect(mapStateToProps, { addNewServiceRequest, addNewIdeas, addUpdateHelpDeskRedux })(HelpDeskService)
