// Author by:- Prashant Waphare | 31-08-2020 | Add basic information home office.

import * as React from "react";
import { library } from "@fortawesome/fontawesome-svg-core";
import { RadioButtonComponent } from "@syncfusion/ej2-react-buttons";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { faUser, faIdCard } from "@fortawesome/pro-light-svg-icons";
import PropTypes from "prop-types";
import { ComboBoxComponent } from "@syncfusion/ej2-react-dropdowns";
import { connect } from "react-redux";
import { FormValidator } from "@syncfusion/ej2-inputs";
import { saveHomeBasicInfoRedux } from "../../redux/actions/homeoffice/homeofficeaction";
import dataService from "../../services/services";

library.add(faUser, faIdCard);

class HomeOfficeAddressDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cityList: [{ Id: 0, Name: "" }],
      stateList: [{ Id: 0, Name: "" }],
      chengeData: false,
      stateLoaded: false,
    };
  }

  componentDidMount() {
    this.getMasterData();
    const { homeOfficeList } = this.props;
        if (homeOfficeList !== undefined && homeOfficeList.length > 0) {
      const { address1, address2, address3, city, state, country, address4, district, pincode, personalEmailId, companyMobileNumber, personalMobileNumber, secondaryMobileNumber, emergContactName,
        emrgencyEmailId, emergContactNumber, addressType } = homeOfficeList[0];
      setTimeout(() => {
        this.props.saveHomeBasicInfoRedux({
          address1: address1,
          address2: address2,
          address3: address3,
          address4: address4,
          city: city,
          district: district,
          state: state,
          selectedcountry: parseInt(country),
          pincode: pincode,
          personalEmailId: personalEmailId,
          name: emergContactName,
          emrgencyEmailId: emrgencyEmailId,
          contactNumber: emergContactNumber,
          companyMobileNumber: companyMobileNumber,
          personalMobileNumber: personalMobileNumber,
          secondaryMobileNumber: secondaryMobileNumber,
          homeAddress: parseInt(addressType),
        });
      }, 2000);
      this.getStateList(country, "didMount");
      this.getCityList(state);
      // }, 2000);
    }
  }
  // eslint-disable-next-line
  // UNSAFE_componentWillReceiveProps(newProps) {
  //   console.log("=========>",newProps);
  //   if (newProps.radioButtonFalse === true) {
  //     // eslint-disable-next-line
  //     this.setState({ chengeData: !this.state.chengeData });
  //     // eslint-disable-next-line
  //     this.props.saveHomeBasicInfoRedux({ radioButtonFalse: false });
  //   }
  // }

  getMasterData = async() => {
    const type = "All";
    const id = 0;
    const masterData = await dataService.getHomeOfficeMasterData(type, id)    
      if (masterData !== null) {
        // eslint-disable-next-line
        this.props.saveHomeBasicInfoRedux({ masterCountry: masterData });
      } else {
        this.data = "error";
      }
  };
  // eslint-disable-next-line
  caracterLimit = (e, type) => {
    if (type === "line1") {
      document.getElementById(
        "addressline1"
      ).innerHTML = `${e.value.length}/50`;
    } else if (type === "line2") {
      document.getElementById(
        "addressline2"
      ).innerHTML = `${e.value.length}/50`;
    } else if (type === "line3") {
      document.getElementById(
        "addressline3"
      ).innerHTML = `${e.value.length}/50`;
    } else {
      document.getElementById(
        "addressline4"
      ).innerHTML = `${e.value.length}/50`;
    }
  };

  addHomeBasicInformation = (e, type) => {
    let options = {};
    switch (type) {
      case "line1":
        // eslint-disable-next-line
        this.props.saveHomeBasicInfoRedux({ address1: e.value });
        break;
      case "line2":
        // eslint-disable-next-line
        this.props.saveHomeBasicInfoRedux({ address2: e.value });
        break;
      case "line3":
        // eslint-disable-next-line
        this.props.saveHomeBasicInfoRedux({ address3: e.value });
        break;
      case "line4":
        // eslint-disable-next-line
        this.props.saveHomeBasicInfoRedux({ address4: e.value });
        break;
      case "district":
        // eslint-disable-next-line
        this.props.saveHomeBasicInfoRedux({ district: e.value });
        break;
      case "state":
        // eslint-disable-next-line
        this.props.saveHomeBasicInfoRedux({ state: e.value });
        break;
      case "pincode":
        const { selectedcountry } = this.props;
        if (selectedcountry === 101) {
          options = {
            rules: {
              pincodeErr: {
                required: [true, "Please enter valid pin/zip code"],
                maxLength: [6, "Please enter valid pin/zip code"],
              },
            },
          };
        } else {
          options = {
            rules: {
              pincodeErr: {
                required: [true, "Please enter valid pin/zip code"],
                maxLength: [10, "Please enter valid pin/zip code"],
              },
            },
          };
        }
        // eslint-disable-next-line
        this.props.saveHomeBasicInfoRedux({ pincode: e.value });
        break;
      case "emailId":
        // eslint-disable-next-line
        this.props.saveHomeBasicInfoRedux({ personalEmailId: e.value });
        break;
      case "name":
        // eslint-disable-next-line
        this.props.saveHomeBasicInfoRedux({ name: e.value });
        break;
      case "emergencyEmail":
        // eslint-disable-next-line
        this.props.saveHomeBasicInfoRedux({ emrgencyEmailId: e.value });
        break;
      case "contactNumber":
        // eslint-disable-next-line
        this.props.saveHomeBasicInfoRedux({ contactNumber: e.value });
        break;
      case "companyMobileNumber":
        options = {
          rules: {
            companyMobileErr: {
              number: [true, "Please enter valid company mobile number"],
              minLength: [10, "Please enter valid company mobile number"],
              maxLength: [15, "Please enter company valid mobile number"],
            },
          },
        };
        // eslint-disable-next-line
        this.props.saveHomeBasicInfoRedux({ companyMobileNumber: e.value });
        break;
      case "personalMobileNumber":
        options = {
          rules: {
            personalMobileErr: {
              required: [true, "Please enter mobile number"],
              number: [true, "Please enter valid mobile number"],
              minLength: [10, "Please enter valid mobile number"],
              maxLength: [15, "Please enter valid mobile number"],
            },
          },
        };
        // eslint-disable-next-line
        this.props.saveHomeBasicInfoRedux({ personalMobileNumber: e.value });
        break;
      case "secondaryMobileNumber":
        options = {
          rules: {
            secondaryMobileErr: {
              number: [true, "Please enter valid secondary mobile number"],
              minLength: [10, "Please enter valid secondary mobile number"],
              maxLength: [15, "Please enter valid secondary mobile number"],
            },
          },
        };
        // eslint-disable-next-line
        this.props.saveHomeBasicInfoRedux({ secondaryMobileNumber: e.value });
        break;
      default:
    }
    setTimeout(() => {
      try {
        const addFormObject = new FormValidator("#addbasicinfo", options);
        addFormObject.validate();
      } catch (error) {
        // eslint-disable-next-line
        console.log("catch", error);
      }
    }, 100);
  };

  addressType = (e) => {
    // eslint-disable-next-line
    this.props.saveHomeBasicInfoRedux({ homeAddress: e.value });
    document.getElementById("addressTypeErr").innerHTML = "";
  };

  getStateList = (e, type = "event") => {
    if (e == null) return null
    // eslint-disable-next-line
    if (e.itemData !== undefined && e.itemData !== null) {
      this.props.saveHomeBasicInfoRedux({ selectedcountry: e.itemData.Id });
    }
    if (e.itemData !== null) {
      const type = "State";
      const id = e.itemData !== undefined ? e.itemData.Id : e;
      const masterData = Promise.resolve(
        dataService.getHomeOfficeMasterData(type, id)
      );
      masterData.then((result) => {
        if (result !== null && result.length > 0) {
          this.setState({ stateList: result }); // cityList: [{ Id: 0, Name: "" }]

          if (type === "event") {
            this.props.saveHomeBasicInfoRedux({ city: "" });
          }
        } else {
          this.setState({
            stateList: [{ Name: "No State/County Found" }],
            cityList: [{ Name: "No City Found" }],
          });
          this.data = "error";
        }
      });
    }
  };

  getCityList = (e) => {
    if (e == null) return null
    // eslint-disable-next-line
    if (e.itemData !== undefined && e.itemData !== null) {
      this.props.saveHomeBasicInfoRedux({ state: e.itemData.Id });
    }
    if (e.itemData !== null) {
      const type = "city";
      const id = e.itemData !== undefined ? e.itemData.Id : e;
      const masterData = Promise.resolve(
        dataService.getHomeOfficeMasterData(type, id)
      );
      masterData.then((result) => {
        if (result !== null && result.length > 0) {
          this.setState({ cityList: result });
          this.props.saveHomeBasicInfoRedux({ city: "" });
        } else {
          this.setState({ cityList: [{ Name: "No City Found" }] });
          this.data = "error";
        }
      });
    }
  };

  setCity = (e) => {
    // eslint-disable-next-line
    this.props.saveHomeBasicInfoRedux({ city: e.itemData.Id });
  };

  render() {
        const {
      masterCountry,
      address1,
      address2,
      address3,
      address4,
      city,
      district,
      state,
      selectedcountry,
      pincode,
      personalEmailId,
      name,
      emrgencyEmailId,
      contactNumber,
      companyMobileNumber,
      personalMobileNumber,
      secondaryMobileNumber,
      homeAddress,
    } = this.props;
    const { stateList, cityList } = this.state;

    return (
      <div>
        <div> ADDRESS OF PROPOSED HOME-OFFICE</div>
        <form
          id="addbasicinfo"
          method="post"
          className="control-section col-md-12"
        // style={{ marginLeft: "10px" }}
        >
          <div className="col-md-12" style={{ paddingTop: "10px" }}>
            <div className="col-xs-12 col-sm-6 col-lg-6 col-md-6">
              <div>
                <TextBoxComponent
                  id="line1"
                  fields={{ text: "Name", value: "Id" }}
                  value={address1}
                  data-msg-containerid="line1Err"
                  name="line1Err"
                  allowCustom={false}
                  floatLabelType="Auto"
                  cssClass="e-outline"
                  htmlAttributes={{ maxlength: 50 }}
                  placeholder="Address Line 1 *"
                  blur={(e) => this.addHomeBasicInformation(e, "line1")}
                  input={(e) => this.caracterLimit(e, "line1")}
                />
                <div className="col-md-12">
                  <div
                    id="line1Err"
                    className="col-11 e-input-group e-float-icon-left error-div"
                  />
                  <div
                    style={{ float: "right", marginTop: "0px" }}
                    id="addressline1"
                    className="error-div"
                  />
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-6 col-lg-6 col-md-6">
              <div>
                <TextBoxComponent
                  id="line2"
                  fields={{ text: "Name", value: "Id" }}
                  data-msg-containerid="line2Err"
                  name="line2Err"
                  allowCustom={false}
                  value={address2}
                  cssClass="e-outline"
                  floatLabelType="Auto"
                  placeholder="Address Line 2 *"
                  htmlAttributes={{ maxlength: 50 }}
                  blur={(e) => this.addHomeBasicInformation(e, "line2")}
                  input={(e) => this.caracterLimit(e, "line2")}
                />
                <div>
                  <div
                    id="line2Err"
                    className="col-11 e-input-group e-float-icon-left error-div"
                  />
                  <div
                    style={{ float: "right", marginTop: "0px" }}
                    id="addressline2"
                    className="error-div"
                  />
                </div>
              </div>
            </div>
          </div>
          {/* <div className="col-md-12"
            style={{ paddingTop: "15px" }}
          >
            <div className="col-xs-12 col-sm-6 col-lg-6 col-md-6">
              <div>
                <TextBoxComponent
                  id="line3"
                  fields={{ text: "Name", value: "Id" }}
                  data-msg-containerid="line3Err"
                  name="line3Err"
                  value={address3}
                  cssClass="e-outline"
                  floatLabelType="Auto"
                  placeholder="Address Line 3"
                  htmlAttributes={{ maxlength: 50 }}
                  blur={(e) => this.addHomeBasicInformation(e, "line3")}
                  input={(e) => this.caracterLimit(e, "line3")}
                />
                <div>
                  <div
                    id="line3Err"
                    className="col-11 e-input-group e-float-icon-left error-div"
                  />
                  <div
                    style={{ float: "right", marginTop: "0px" }}
                    id="addressline3"
                    className="error-div"
                  />
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-6 col-lg-6 col-md-6">
              <div>
                <TextBoxComponent
                  id="line4"
                  fields={{ text: "Name", value: "Id" }}
                  data-msg-containerid="line4Err"
                  name="line4Err"
                  value={address4}
                  cssClass="e-outline"
                  floatLabelType="Auto"
                  placeholder="Address Line 4"
                  htmlAttributes={{ maxlength: 50 }}
                  blur={(e) => this.addHomeBasicInformation(e, "line4")}
                  input={(e) => this.caracterLimit(e, "line4")}
                />
                <div>
                  <div
                    id="line4Err"
                    className="col-11 e-input-group e-float-icon-left error-div"
                  />
                  <div
                    style={{ float: "right", marginTop: "0px" }}
                    id="addressline4"
                    className="error-div"
                  />
                </div>
              </div>
            </div>
          </div> */}
          <div className="col-md-12"
            style={{ paddingTop: "15px" }}
          >
            <div className="col-xs-6 col-sm-4 col-lg-3 col-md-4">
              {
                (masterCountry && masterCountry?.length > 0) &&
                <ComboBoxComponent
                  id="country"
                  fields={{ text: "Name", value: "Id" }}
                  dataSource={masterCountry}
                  value={parseInt(selectedcountry)}
                  data-msg-containerid="countryErr"
                  name="countryErr"
                  allowCustom={false}
                  floatLabelType="Auto"
                  cssClass="e-outline"
                  placeholder="Country *"
                  select={(e) => this.getStateList(e, "event")}
                />
              }
              <div
                id="countryErr"
                className="e-input-group e-float-icon-left error-div"
              />
            </div>
            <div className="col-xs-6 col-sm-4 col-lg-3 col-md-4">
              {
                (stateList !== "" && stateList !== null && stateList?.length > 0) ? //NOSONAR
                  // stateList.length > 0 &&
                  <ComboBoxComponent
                    id="state"
                    fields={{ text: "Name", value: "Id" }}
                    dataSource={stateList}
                    value={state}
                    data-msg-containerid="stateErr"
                    name="stateErr"
                    // allowCustom={false}
                    floatLabelType="Auto"
                    cssClass="e-outline"
                    placeholder="State/County *"
                    select={(e) => this.getCityList(e)}
                  />
                  : (state === "" && stateList.length > 0) &&
                  <ComboBoxComponent
                    id="state"
                    fields={{ text: "Name", value: "Id" }}
                    dataSource={stateList}
                    value={state}
                    data-msg-containerid="stateErr"
                    name="stateErr"
                    // allowCustom={false}
                    floatLabelType="Auto"
                    cssClass="e-outline"
                    placeholder="State/County *"
                    select={(e) => this.getCityList(e)}
                  />
              }
              <div
                id="stateErr"
                className="e-input-group e-float-icon-left error-div"
              />
            </div>
            <div className="col-xs-6 col-sm-4 col-lg-3 col-md-4">
              {
                (state && cityList && cityList.length > 0) && //NOSONAR
                  // cityList.length > 0 &&
                  <ComboBoxComponent
                    id="city"
                    fields={{ text: "Name", value: "Id" }}
                    dataSource={cityList}
                    value={parseInt(city) ? parseInt(city) : city}
                    data-msg-containerid="cityErr"
                    name="cityErr"
                    // allowCustom={false}
                    floatLabelType="Auto"
                    cssClass="e-outline"
                    placeholder="City *"
                    select={(e) => this.setCity(e)}
                  />
                  // : (city === "" && cityList.length > 0) &&
                  // <ComboBoxComponent
                  //   id="city"
                  //   fields={{ text: "Name", value: "Id" }}
                  //   dataSource={cityList}
                  //   value={city}
                  //   data-msg-containerid="cityErr"
                  //   name="cityErr"
                  //   allowCustom={false}
                  //   floatLabelType="Auto"
                  //   cssClass="e-outline"
                  //   placeholder="City *"
                  //   select={(e) => this.setCity(e)}
                  // />
              }
              <div
                id="cityErr"
                className="e-input-group e-float-icon-left error-div"
              />
            </div>
            <div className="col-xs-12 col-sm-12 col-lg-3 col-md-12">
              <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
                <TextBoxComponent
                  id="district"
                  fields={{ text: "Name", value: "Id" }}
                  value={district}
                  data-msg-containerid="districtErr"
                  name="districtErr"
                  allowCustom={false}
                  floatLabelType="Auto"
                  cssClass="e-outline"
                  placeholder="District"
                  blur={(e) => this.addHomeBasicInformation(e, "district")}
                />
                <div
                  id="districtErr"
                  className="e-input-group e-float-icon-left error-div"
                />
              </div>
              <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
                <TextBoxComponent
                  id="pincode"
                  fields={{ text: "Name", value: "Id" }}
                  value={pincode}
                  data-msg-containerid="pincodeErr"
                  name="pincodeErr"
                  allowCustom={false}
                  floatLabelType="Auto"
                  cssClass="e-outline"
                  placeholder="Zip/Pin Code *"
                  blur={(e) => this.addHomeBasicInformation(e, "pincode")}
                />
                <div
                  id="pincodeErr"
                  className="e-input-group e-float-icon-left error-div"
                />
              </div>
            </div>
          </div>
          <div className="col-md-12"
          // style={{ paddingTop: "10px" }}
          >
            <div className="col-xs-6 col-sm-6 col-lg-3 col-md-6">
              <TextBoxComponent
                id="emailId"
                fields={{ text: "Name", value: "Id" }}
                value={personalEmailId}
                data-msg-containerid="emailIdErr"
                name="emailIdErr"
                allowCustom={false}
                floatLabelType="Auto"
                cssClass="e-outline"
                placeholder="Personal Email ID *"
                blur={(e) => this.addHomeBasicInformation(e, "emailId")}
              />
              <div
                id="emailIdErr"
                className="e-input-group e-float-icon-left error-div"
              />
            </div>
            <div className="col-xs-6 col-sm-6 col-lg-3 col-md-6">
              <div>
                <TextBoxComponent
                  id="companyMobile"
                  fields={{ text: "Name", value: "Id" }}
                  data-msg-containerid="companyMobileErr"
                  name="companyMobileErr"
                  allowCustom={false}
                  value={companyMobileNumber}
                  cssClass="e-outline"
                  floatLabelType="Auto"
                  placeholder="Company Mobile Number (If Applicable)"
                  blur={(e) =>
                    this.addHomeBasicInformation(e, "companyMobileNumber")
                  }
                />
                <div
                  id="companyMobileErr"
                  className="e-input-group e-float-icon-left error-div"
                />
              </div>
            </div>
            <div className="col-xs-6 col-sm-6 col-lg-3 col-md-6">
              <div>
                <TextBoxComponent
                  id="personalMobile"
                  fields={{ text: "Name", value: "Id" }}
                  data-msg-containerid="personalMobileErr"
                  name="personalMobileErr"
                  allowCustom={false}
                  value={personalMobileNumber}
                  cssClass="e-outline"
                  floatLabelType="Auto"
                  placeholder="Personal Mobile Number *"
                  blur={(e) =>
                    this.addHomeBasicInformation(e, "personalMobileNumber")
                  }
                />
                <div
                  id="personalMobileErr"
                  className="e-input-group e-float-icon-left error-div"
                />
              </div>
            </div>
            <div className="col-xs-6 col-sm-6 col-lg-3 col-md-6">
              <div>
                <TextBoxComponent
                  id="secondaryMobile"
                  fields={{ text: "Name", value: "Id" }}
                  data-msg-containerid="secondaryMobileErr"
                  name="secondaryMobileErr"
                  allowCustom={false}
                  value={secondaryMobileNumber}
                  cssClass="e-outline"
                  floatLabelType="Auto"
                  placeholder="Secondary Mobile Number"
                  blur={(e) =>
                    this.addHomeBasicInformation(e, "secondaryMobileNumber")
                  }
                />
                <div
                  id="secondaryMobileErr"
                  className="e-input-group e-float-icon-left error-div"
                />
              </div>
            </div>
          </div>
          <div
            className="col-md-12"
            style={{ height: "40px", fontWeight: "bold" }}
          >
            <div className="col-xs-12 col-sm-12 col-lg-12 ">
              EMERGENCY CONTACT DETAILS
              <hr style={{ marginTop: "5px", marginBottom: "5px" }} />
            </div>
          </div>
          <div className="col-md-12" style={{ paddingTop: "0px" }}>
            <div className="col-xs-6 col-sm-4 col-lg-4 col-md-4">
              <div>
                <TextBoxComponent
                  id="Name"
                  fields={{ text: "Name", value: "Id" }}
                  value={name}
                  data-msg-containerid="NameErr"
                  name="NameErr"
                  allowCustom={false}
                  floatLabelType="Auto"
                  cssClass="e-outline"
                  placeholder="Name *"
                  blur={(e) => this.addHomeBasicInformation(e, "name")}
                />
                <div
                  id="NameErr"
                  className="e-input-group e-float-icon-left error-div"
                />
              </div>
            </div>
            <div className="col-xs-6 col-sm-4 col-lg-4 col-md-4">
              <div>
                <TextBoxComponent
                  id="Email"
                  fields={{ text: "Name", value: "Id" }}
                  data-msg-containerid="EmailErr"
                  name="EmailErr"
                  allowCustom={false}
                  value={emrgencyEmailId}
                  cssClass="e-outline"
                  floatLabelType="Auto"
                  placeholder="Email ID *"
                  blur={(e) =>
                    this.addHomeBasicInformation(e, "emergencyEmail")
                  }
                />
                <div
                  id="EmailErr"
                  className="e-input-group e-float-icon-left error-div"
                />
              </div>
            </div>
            <div className="col-xs-6 col-sm-4 col-lg-4 col-md-4">
              <div>
                <TextBoxComponent
                  id="contactNo"
                  fields={{ text: "Name", value: "Id" }}
                  value={contactNumber}
                  data-msg-containerid="contactNoErr"
                  name="contactNoErr"
                  allowCustom={false}
                  floatLabelType="Auto"
                  cssClass="e-outline"
                  placeholder="Contact Number *"
                  blur={(e) =>
                    this.addHomeBasicInformation(e, "contactNumber")
                  }
                />
                <div
                  id="contactNoErr"
                  className="e-input-group e-float-icon-left error-div"
                />
              </div>
            </div>
          </div>
          <div
            className="col-md-12"
            style={{ paddingTop: "0px", marginBottom: "-6px" }}
          >
            <div className="col-xs-12 col-sm-6 col-lg-5 col-md-6">
              <div>
                <RadioButtonComponent
                  style={{ color: "#fff" }}
                  checked={homeAddress === 1 ? true : false}
                  change={this.addressType}
                  label="The above address is my self/family member owned accommodation"
                  name="Address"
                  value={1}
                />
              </div>
            </div>
            <div className="col-xs-12 col-sm-6 col-lg-4 col-md-6">
              <div>
                <RadioButtonComponent
                  style={{ color: "#fff" }}
                  checked={homeAddress === 2 ? true : false}
                  change={this.addressType}
                  label="The above address is a rented accommodation"
                  name="Address"
                  value={2}
                />
              </div>
            </div>
            <div
              style={{ padding: "2px 0px 0px 17px" }}
              id="addressTypeErr"
              className="e-input-group e-float-icon-left error-div"
            />
          </div>
        </form>
      </div>
    );
  }
}
HomeOfficeAddressDetails.propTypes = {
  // eslint-disable-next-line
  masterCountry: PropTypes.array,
  address1: PropTypes.string,
  address2: PropTypes.string,
  address3: PropTypes.string,
  address4: PropTypes.string,
  city: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  district: PropTypes.string,
  state: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  selectedcountry: PropTypes.number,
  pincode: PropTypes.string,
  personalEmailId: PropTypes.string,
  name: PropTypes.string,
  emrgencyEmailId: PropTypes.string,
  contactNumber: PropTypes.string,
  companyMobileNumber: PropTypes.string,
  personalMobileNumber: PropTypes.string,
  secondaryMobileNumber: PropTypes.string,
  // eslint-disable-next-line
  homeAddress: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  radioButtonFalse: PropTypes.bool,
  homeOfficeMode: PropTypes.string,
};
HomeOfficeAddressDetails.defaultProps = {
  masterCountry: [],
  address1: "",
  address2: "",
  address3: "",
  address4: "",
  city: "",
  district: "",
  state: "",
  selectedcountry: null,
  pincode: "",
  personalEmailId: "",
  name: "",
  emrgencyEmailId: "",
  contactNumber: "",
  companyMobileNumber: "",
  personalMobileNumber: "",
  secondaryMobileNumber: "",
  homeAddress: "0",
  radioButtonFalse: false,
  homeOfficeMode: ""
};
const mapStateToProps = ({ auth, homeoffice }) => {
  const { authUser } = auth;
  const {
    address1,
    address2,
    address3,
    address4,
    city,
    district,
    state,
    selectedcountry,
    pincode,
    personalEmailId,
    name,
    emrgencyEmailId,
    contactNumber,
    companyMobileNumber,
    personalMobileNumber,
    secondaryMobileNumber,
    homeAddress,
    // planProvider1,
    // brodbandSpeed1,
    // planProvider2,
    // brodbandSpeed2,
    // filepath1,
    // filepath2,
    masterCountry,
    radioButtonFalse,
    homeOfficeMode
  } = homeoffice;
  return {
    authUser,
    address1,
    address2,
    address3,
    address4,
    city,
    district,
    state,
    selectedcountry,
    pincode,
    personalEmailId,
    name,
    emrgencyEmailId,
    contactNumber,
    companyMobileNumber,
    personalMobileNumber,
    secondaryMobileNumber,
    homeAddress,
    // planProvider1,
    // brodbandSpeed1,
    // planProvider2,
    // brodbandSpeed2,
    // filepath1,
    // filepath2,
    masterCountry,
    radioButtonFalse,
    homeOfficeMode
  };
};
export default connect(mapStateToProps, {
  saveHomeBasicInfoRedux,
})(HomeOfficeAddressDetails);
