// Author by:- Prashant Waphare | 31-08-2020 | home office container load all component here.
import React, { Component } from "react";
import { AccordionComponent } from "@syncfusion/ej2-react-navigations";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { CheckBoxComponent, RadioButtonComponent } from "@syncfusion/ej2-react-buttons";
import { Button } from "@material-ui/core";
import NotificationContainer from "../../../components/notification/notifycontainer";
import HomeOffinceBasicInfo from "../../../components/homeoffice/homeofficebasicinfo";
import { addEditHomeOfficeDetails } from "../../../redux/actions/homeoffice/homeofficeaction";
import { urlConfig } from "../../../config/config";
import {
    getDisplayDateOnly,
} from "../../../app/other/commonfunction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
    faDownload,
} from "@fortawesome/free-solid-svg-icons";

import Holding from "../../../assets/images/Holding.png";
import Deskimage from "../../../assets/images/Deskimage.png";
import Chair from "../../../assets/images/Chair.png";
import Table31 from "../../../assets/images/other_asset_desk.png";

library.add(
    faDownload
);
class ViewHomeOffce extends Component {
    constructor(props) {
        super(props);
        document.title = "Home Office";
        this.state = {
            height:
                (window.innerHeight ||
                    document.documentElement.clientHeight ||
                    document.body.clientHeight) - 188,
        };
        this.onCreated = this.onCreated.bind(this);
    }

    onCreated() {
        setTimeout(() => {
            if (this.acrdnHOAddress !== null) {
                for (let i = 0; i <= this.acrdnHOAddress.items.length - 1; i += 1) {
                    this.acrdnHOAddress.items[i].expanded = true;
                }
            }
            if (this.acrdnHOWS !== null) {
                for (let i = 0; i <= this.acrdnHOWS.items.length - 1; i += 1) {
                    this.acrdnHOWS.items[i].expanded = true;
                }
            }
            if (this.acrdnES !== null) {
                for (let i = 0; i <= this.acrdnES.items.length - 1; i += 1) {
                    this.acrdnES.items[i].expanded = true;
                }
            }
            if (this.internetConnectivity !== null) {
                for (
                    let i = 0;
                    i <= this.internetConnectivity.items.length - 1;
                    i += 1
                ) {
                    this.internetConnectivity.items[i].expanded = true;
                }
            }
            if (this.acrdnOS !== null) {
                for (let i = 0; i <= this.acrdnOS.items.length - 1; i += 1) {
                    this.acrdnOS.items[i].expanded = true;
                }
            }
        }, 500);
    }

    componentDidMount = async () => {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
    };
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.homeOfficeList.length > 0) {
            this.props.addEditHomeOfficeDetails({ homeOfficeStatus: "" });
        }
    }
    updateDimensions() {
        const updateHeight = window.innerHeight - 125;// 65
        this.setState({
            height: updateHeight,
        });
    }
    updateHomeOffice = () => {
        // setTimeout(() => {
        this.props.addEditHomeOfficeDetails({ homeOfficeMode: "editHome" });
        // }, 3000)

    }
    render() { // NOSONAR
        const { homeOfficeList } = this.props;
        if (homeOfficeList !== undefined && homeOfficeList.length > 0) {
            const { address1, address2, countryName, stateName, cityName, district, pincode, personalEmailId, companyMobileNumber, personalMobileNumber, secondaryMobileNumber, emergContactName,
                emrgencyEmailId, emergContactNumber, addressType, preRequisitConfirmation, prereqQuiteDesk, prereqNonDistractive, prereqPlainBackground, uninterElectricitySupply,
                generatorBackup, upsAvailability, upsBrand, upsModel, upsCapacity, upsBackupDuration, inverterAvailability, inverterBrand, inverterModel, inverterCapacity, inverterBackupDuration,
                primaryBroadbandPlan, primaryBroadbandSpeed, primBrodbandSpeedTestScreen, secondaryBrodbandPlan, secondaryBrodbandSpeed, secondaryBrodbandSpeedTestScreen, assetReqWorkDesk,
                assetReqScreenArm, assetReqChair, electricityBillDate, billAmount, billfilePath, declaration, internetType, internetFlag } = homeOfficeList[0];

            const { height } = this.state;
            return (
                <>
                    <div>
                        {
                            <div>
                                <div
                                    style={{
                                        padding: 15,
                                        backgroundColor: "#F4F3F5",
                                        height,
                                        overflowY: "auto",
                                    }}
                                >
                                    <NotificationContainer />
                                    <div>
                                        <AccordionComponent
                                            ref={(accordion) => {
                                                this.acrdnHObasic = accordion;
                                            }}
                                            created={this.onCreated}
                                        >
                                            <HomeOffinceBasicInfo />
                                        </AccordionComponent>
                                    </div>
                                    <div style={{ paddingTop: 10 }}>
                                        <AccordionComponent
                                            ref={(accordion) => {
                                                this.acrdnHOAddress = accordion;
                                            }}
                                            created={this.onCreated}
                                        >
                                            <div>
                                                <div> ADDRESS OF PROPOSED HOME-OFFICE</div>
                                                <form
                                                    id="addbasicinfo"
                                                    method="post"
                                                    className="control-section col-md-12"
                                                // style={{ marginLeft: "10px" }}
                                                >
                                                    <div className="col-md-12" style={{ paddingTop: "10px" }}>
                                                        <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
                                                            <div className="view-input-button">
                                                                <span className="home-office-view-bold">Address Line 1</span>
                                                                <div >
                                                                    {address1}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
                                                            <div className="view-input-button">
                                                                <span className="home-office-view-bold">Address Line 2</span>
                                                                <div >
                                                                    {address2}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <div className="col-md-12"
                                                        style={{ paddingTop: "16px" }}
                                                    >
                                                        <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
                                                            <div className="view-input-button">
                                                                <span className="home-office-view-bold">Address Line 3</span>
                                                                <div>
                                                                    {address3}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
                                                            <div className="view-input-button">
                                                                <span className="home-office-view-bold">Address Line 4</span>
                                                                <div>
                                                                    {address4}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> */}
                                                    <div className="col-md-12"
                                                        style={{ paddingTop: "16px" }}
                                                    >
                                                        <div className="col-xs-6 col-sm-4 col-lg-3 col-md-4">
                                                            <div className="view-input-button">
                                                                <span className="home-office-view-bold">Country</span>
                                                                <div>
                                                                    {countryName}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-xs-6 col-sm-4 col-lg-3 col-md-4">
                                                            <div className="view-input-button">
                                                                <span className="home-office-view-bold">State/County</span>
                                                                <div>
                                                                    {stateName}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-xs-6 col-sm-4 col-lg-3 col-md-4">
                                                            <div className="view-input-button">
                                                                <span className="home-office-view-bold">City</span>
                                                                <div>
                                                                    {cityName}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-xs-12 col-sm-12 col-lg-3 col-md-12">
                                                            <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
                                                                <div className="view-input-button">
                                                                    <span className="home-office-view-bold">District</span>
                                                                    <div>
                                                                        {district}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6">
                                                                <div className="view-input-button">
                                                                    <span className="home-office-view-bold">Zip/Pin Code</span>
                                                                    <div>
                                                                        {pincode}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12"
                                                        style={{ paddingTop: "16px" }}
                                                    >
                                                        <div className="col-xs-6 col-sm-6 col-lg-3 col-md-6">
                                                            <div className="view-input-button">
                                                                <span className="home-office-view-bold">Personal Email ID</span>
                                                                <div>
                                                                    {personalEmailId}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-xs-6 col-sm-6 col-lg-3 col-md-6">
                                                            <div className="view-input-button">
                                                                <span className="home-office-view-bold">Company Mobile Number (If Applicable)</span>
                                                                <div>
                                                                    {companyMobileNumber}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-xs-6 col-sm-6 col-lg-3 col-md-6">
                                                            <div className="view-input-button">
                                                                <span className="home-office-view-bold">Personal Mobile Number</span>
                                                                <div>
                                                                    {personalMobileNumber}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-xs-2 col-sm-2 col-lg-3 col-md-2">
                                                            <div className="view-input-button">
                                                                <span className="home-office-view-bold">Secondary Mobile Number</span>
                                                                <div>
                                                                    {secondaryMobileNumber}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="col-md-12"
                                                        style={{ height: "40px", fontWeight: 500, paddingTop: "16px" }}
                                                    >
                                                        <div className="col-xs-2 col-sm-2 col-lg-12 col-md-2">
                                                            EMERGENCY CONTACT DETAILS
                                                            <hr style={{ marginTop: "5px", marginBottom: "5px" }} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12" style={{ paddingTop: "16px" }}>
                                                        <div className="col-xs-2 col-sm-2 col-lg-3 col-md-2">
                                                            <div className="view-input-button">
                                                                <span className="home-office-view-bold">Name</span>
                                                                <div >
                                                                    {emergContactName}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-xs-2 col-sm-2 col-lg-3 col-md-2">
                                                            <div className="view-input-button">
                                                                <span className="home-office-view-bold">Email ID</span>
                                                                <div>
                                                                    {emrgencyEmailId}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-xs-2 col-sm-2 col-lg-3 col-md-2">
                                                            <div className="view-input-button">
                                                                <span className="home-office-view-bold">Contact Number</span>
                                                                <div>
                                                                    {emergContactNumber}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="col-md-12"
                                                        style={{ paddingTop: "16px", paddingBottom: "16px", marginBottom: "-6px" }}
                                                    >
                                                        <div className="col-xs-2 col-sm-2 col-lg-5 col-md-2">
                                                            <span key="spAdd1">
                                                                <RadioButtonComponent
                                                                    key="add1"
                                                                    checked={addressType === '1' ? true : false}
                                                                    value={1}
                                                                    disabled
                                                                />
                                                            </span>
                                                            <div style={{ marginTop: -8 }}>
                                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; The above address is my self/family member owned accommodation
                                                            </div>
                                                        </div>
                                                        <div className="col-xs-2 col-sm-2 col-lg-4 col-md-2">
                                                            <span key="spAdd2">
                                                                <RadioButtonComponent
                                                                    key="add2"
                                                                    checked={addressType === '2' ? true : false}
                                                                    value={2}
                                                                    disabled
                                                                />
                                                            </span>
                                                            <div style={{ marginTop: -8 }}>
                                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; The above address is a rented accommodation
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </AccordionComponent>
                                    </div>
                                    <div style={{ paddingTop: 10 }}>
                                        <div
                                            className=""
                                            style={{
                                                backgroundColor: "#fff",
                                                padding: "20px 20px 10px 10px",
                                                border: "1px solid #939399",
                                            }}
                                        >
                                            <div style={{ marginLeft: 20 }}>
                                                <div>
                                                    <span style={{ color: "#000", fontWeight: 500 }}>
                                                        PRE-REQUISITES CONFIRMATION *
                                                    </span>
                                                </div>
                                                <div>
                                                    <span>
                                                        <CheckBoxComponent
                                                            checked={preRequisitConfirmation}
                                                            disabled
                                                        />
                                                    </span>
                                                    <span>
                                                        &nbsp; I confirm that I currently possess, and will continue
                                                        to maintain all the prerequisites as per below for my
                                                        home-office.
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div style={{ paddingTop: 10 }}>
                                        <AccordionComponent
                                            ref={(accordion) => {
                                                this.acrdnES = accordion;
                                            }}
                                            created={this.onCreated}
                                        >
                                            <div>
                                                <div className="accordian-header-padding-left">
                                                    {" "}
                                                    ELECTRICITY SUPPLY *
                                                </div>
                                                <div className="control-section col-md-12">
                                                    <div
                                                        className="row "
                                                        style={{
                                                            backgroundColor: "#F4F3F5",
                                                            paddingLeft: -10,
                                                            paddingRight: -10,
                                                            paddingTop: 10,
                                                            paddingBottom: 10,
                                                        }}
                                                    >
                                                        <div
                                                            style={{
                                                                paddingTop: 5,
                                                                paddingBottom: 5,
                                                                color: "#000",
                                                                paddingLeft: 30,
                                                                paddingRight: 10,
                                                            }}
                                                            className="col-xs-7 col-sm-2 col-lg-6 col-md-2"
                                                        >
                                                            I have uninterrupted 24x7 power supply at my Home-Office address
                                                            and powercuts are fewer than one per fornight, and less than four hours at a stretch
                                                        </div>
                                                        <div
                                                            style={{ paddingTop: 5, marginLeft: '.7%' }}
                                                            className="col-xs-4 col-sm-2 col-lg-4 col-md-2"
                                                        >
                                                            <div className="row">
                                                                {/* eslint-disable-next-line */}
                                                                <div
                                                                    className={`col-xs-6 col-sm-2 col-lg-4 col-md-2 ${uninterElectricitySupply === "1"
                                                                        ? "button-yes-viewHome"
                                                                        : "button-unselect-viewHome"
                                                                        }`}
                                                                >
                                                                    YES
                                                                </div>
                                                                <div
                                                                    className={`col-xs-6 col-sm-2 col-lg-4 col-md-2 ${uninterElectricitySupply === "0"
                                                                        ? "button-no-viewHome"
                                                                        : "button-unselect-viewHome"
                                                                        }`}
                                                                >
                                                                    NO
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                        // uninterElectricitySupply === "0" && 
                                                        (
                                                            <>
                                                                <div>
                                                                    <div className="row1">
                                                                        <div
                                                                            style={{ paddingTop: 15, color: "#000" }}
                                                                            className="col-xs-12 col-sm-2 col-lg-12 col-md-2"
                                                                        >
                                                                            <span style={{ color: "#000", fontWeight: 500 }}>
                                                                                POWER BACKUP ARRANGEMENT AT MY HOME-OFFICE ADDRESS
                                                                            </span>
                                                                            <hr style={{ marginTop: 10, marginBottom: 10 }} />
                                                                        </div>
                                                                    </div>
                                                                    <div className="row1">
                                                                        <div
                                                                            style={{ paddingTop: 5, paddingBottom: 32, color: "#6F6E74" }}
                                                                            className="col-xs-8 col-sm-2 col-lg-6 col-md-2"
                                                                        >
                                                                            {/* eslint-disable-next-line */}
                                                                            My home-office has a generator backup and the power supply does not blink during the switchover from one power source to another.
                                                                        </div>
                                                                        <div className="col-xs-4 col-sm-2 col-lg-4 col-md-2">
                                                                            <div className="row" style={{ paddingLeft: "2.25%" }}>
                                                                                <div
                                                                                    className={`col-xs-6 col-sm-2 col-lg-4 col-md-2 ${generatorBackup === "1"
                                                                                        ? "button-yes-viewHome"
                                                                                        : "button-unselect-viewHome"
                                                                                        }`}
                                                                                >
                                                                                    YES
                                                                                </div>
                                                                                <div
                                                                                    className={`col-xs-6 col-sm-2 col-lg-4 col-md-2 ${generatorBackup === "0"
                                                                                        ? "button-no-viewHome"
                                                                                        : "button-unselect-viewHome"
                                                                                        }`}
                                                                                >
                                                                                    NO
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {/* My home-Office has a generator backup does't blink during switchover from main supply */}
                                                                    {/* Section I */}
                                                                    {
                                                                        // generatorBackup === "0" && 
                                                                        (
                                                                            <div style={{ marginLeft: 15 }}>
                                                                                <div
                                                                                    className="col-xs-2 col-sm-2 col-lg-12 col-md-1"
                                                                                    style={{ paddingLeft: 0 }}
                                                                                >
                                                                                    <div className="electricity-supply-backup-header">
                                                                                        UPS
                                                                                    </div>
                                                                                    <div
                                                                                        className="col-xs-2 col-sm-2 col-lg-2"
                                                                                        style={{ width: "7.5%" }}
                                                                                    >
                                                                                        <div className="row">
                                                                                            <div
                                                                                                className={`col-xs-6 col-sm-2 col-lg-4 col-md-2 ${upsAvailability === "1" ? "button-yes-viewHome" : "button-unselect-viewHome"
                                                                                                    }`}
                                                                                            >
                                                                                                YES
                                                                                            </div>
                                                                                            <div
                                                                                                className={`col-xs-6 col-sm-2 col-lg-4 col-md-2 ${upsAvailability === "0" ? "button-no-viewHome" : "button-unselect-viewHome"
                                                                                                    }`}
                                                                                            >
                                                                                                NO
                                                                                            </div>
                                                                                        </div>
                                                                                        <div
                                                                                            id=""
                                                                                            className="e-input-group e-float-icon-left error-div"
                                                                                        />
                                                                                    </div>
                                                                                    {/* {upsAvailability === "1" && ( */}
                                                                                    <div style={{ padding: 0 }}>
                                                                                        <div
                                                                                            className="col-xs-2 col-sm-2 col-lg-3 col-md-2"
                                                                                        // style={{ width: "22%", marginTop: -18 }}
                                                                                        >
                                                                                            <div className="view-input-button">
                                                                                                <span className="home-office-view-bold">Brand</span>
                                                                                                <div>
                                                                                                    {upsBrand}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div
                                                                                            className="col-xs-2 col-sm-2 col-lg-2 col-md-2"
                                                                                        // style={{ width: "22%", marginTop: -18 }}
                                                                                        >
                                                                                            <div className="view-input-button">
                                                                                                <span className="home-office-view-bold">Model</span>
                                                                                                <div>
                                                                                                    {upsModel}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div
                                                                                            className="col-xs-2 col-sm-2 col-lg-3 col-md-2"
                                                                                        // style={{ width: "22%", marginTop: -18 }}
                                                                                        >
                                                                                            <div className="view-input-button">
                                                                                                <span className="home-office-view-bold">VA Capacity</span>
                                                                                                <div>
                                                                                                    {upsCapacity}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div
                                                                                            className="col-xs-2 col-sm-2 col-lg-2 col-md-2"
                                                                                        // style={{ width: "22%", marginTop: -18 }}
                                                                                        >
                                                                                            <div className="view-input-button">
                                                                                                <span className="home-office-view-bold">Backup Duration in Mins</span>
                                                                                                <div>
                                                                                                    {upsBackupDuration}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    {/* )} */}
                                                                                </div>
                                                                                {/* Section II */}
                                                                                <div
                                                                                    className=" col-xs-2 col-sm-2 col-lg-12 col-md-1"
                                                                                    style={{ paddingLeft: 0 }}
                                                                                >
                                                                                    <div className="electricity-supply-backup-header">
                                                                                        Invertor
                                                                                    </div>
                                                                                    <div
                                                                                        className="col-xs-2 col-sm-2 col-lg-2"
                                                                                        style={{ width: "7.5%" }}
                                                                                    >
                                                                                        <div className="row">
                                                                                            <div
                                                                                                className={`col-xs-6 col-sm-2 col-lg-4 col-md-2 ${inverterAvailability === "1" ? "button-yes-viewHome" : "button-unselect-viewHome"
                                                                                                    }`}
                                                                                            >
                                                                                                YES
                                                                                            </div>
                                                                                            <div
                                                                                                className={`col-xs-6 col-sm-2 col-lg-4 col-md-2 ${inverterAvailability === "0" ? "button-no-viewHome" : "button-unselect-viewHome"
                                                                                                    }`}
                                                                                            >
                                                                                                NO
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    {
                                                                                        // inverterAvailability === "1" && 
                                                                                        (
                                                                                            <div style={{ padding: 0 }}>
                                                                                                <div
                                                                                                    className="col-xs-2 col-sm-2 col-lg-3 col-md-2"
                                                                                                // style={{ width: "22%", marginTop: -18 }}
                                                                                                >
                                                                                                    <div className="view-input-button">
                                                                                                        <span className="home-office-view-bold">Brand</span>
                                                                                                        <div>
                                                                                                            {inverterBrand}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="col-xs-2 col-sm-2 col-lg-2 col-md-2"
                                                                                                // style={{ width: "22%", marginTop: -18 }}
                                                                                                >
                                                                                                    <div className="view-input-button">
                                                                                                        <span className="home-office-view-bold">Model</span>
                                                                                                        <div>
                                                                                                            {inverterModel}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="col-xs-2 col-sm-2 col-lg-3 col-md-2"
                                                                                                // style={{ width: "22%", marginTop: -18 }}
                                                                                                >
                                                                                                    <div className="view-input-button">
                                                                                                        <span className="home-office-view-bold">KVA Capacity</span>
                                                                                                        <div>
                                                                                                            {inverterCapacity}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div
                                                                                                    className="col-xs-2 col-sm-2 col-lg-2 col-md-2"
                                                                                                // style={{ width: "22%", marginTop: -18 }}
                                                                                                >
                                                                                                    <div className="view-input-button">
                                                                                                        <span className="home-office-view-bold">Backup Duration in Hrs</span>
                                                                                                        <div>
                                                                                                            {inverterBackupDuration}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        )}
                                                                                </div>
                                                                            </div>
                                                                        )}
                                                                </div>
                                                                {
                                                                    // (upsAvailability === '1' || inverterAvailability === "1") &&
                                                                    <>
                                                                        <div style={{ paddingTop: 15, color: "#000" }} className="col-xs-12 col-sm-2 col-lg-12 col-md-2" >
                                                                            <span style={{ color: "#000", fontWeight: 500 }}>
                                                                                STAGGERED REIMBURSEMENT REQUEST
                                                                            </span>
                                                                            <hr style={{ marginTop: 10, marginBottom: 10 }} />
                                                                        </div>
                                                                        <div className="col-md-12" style={{ height: "40px", paddingLeft: "15px", marginTop: '-5px' }}>
                                                                            <div>
                                                                                <span>
                                                                                    <CheckBoxComponent
                                                                                        checked={electricityBillDate === null ? false : true}
                                                                                        disabled
                                                                                    />
                                                                                </span>
                                                                                <span>
                                                                                    &nbsp; I have newly procured and installed an electricity backup arrangement and would like the organisation to include me in the staggered reimbursement plan.
                                                                                </span>
                                                                            </div>
                                                                        </div>
                                                                        {
                                                                            // inverterAvailability === "1" &&
                                                                            <div className="col-md-12">
                                                                                <div className="col-xs-2 col-sm-2 col-lg-3 col-md-2">
                                                                                    <div className="view-input-button">
                                                                                        <span className="home-office-view-bold">Bill Date</span>
                                                                                        <div>
                                                                                            {electricityBillDate != null && getDisplayDateOnly(new Date(electricityBillDate))}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-xs-2 col-sm-2 col-lg-3 col-md-2">
                                                                                    <div className="view-input-button">
                                                                                        <span className="home-office-view-bold">Bill Amount</span>
                                                                                        <div>
                                                                                            {billAmount}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                {
                                                                                    billfilePath !== "" && billfilePath !== null &&
                                                                                    <div className="col-xs-2 col-sm-2 col-lg-1 col-md-2">
                                                                                        <div className="row" style={{ paddingTop: '8px' }}>
                                                                                            <div className="other-asset-text">
                                                                                                {/* <TooltipComponent
                                                                                                    // content="Download screenshot of your bill, or scanned copy of your bill"
                                                                                                    content="Click to download and view previous document"
                                                                                                    cssClass="customtooltip"
                                                                                                    position="BottomCenter"
                                                                                                    isSticky={false}
                                                                                                    showTipPointer={false}
                                                                                                // style={{textAlign:''}}
                                                                                                > */}
                                                                                                <a style={{ color: "white", textDecoration: 'none' }} href={`${urlConfig.apiurl}getHomeOfficeDocumenttm/${billfilePath}`} download
                                                                                                    title="Click to download and view previous document"
                                                                                                >
                                                                                                    <Button
                                                                                                        style={{
                                                                                                            background: "#4b4b55",
                                                                                                            color: "white",
                                                                                                            height: "40px",
                                                                                                            borderRadius: "5px",
                                                                                                        }}
                                                                                                    >
                                                                                                        <span style={{ fontSize: 14, fontWeight: '100' }}>
                                                                                                            <FontAwesomeIcon
                                                                                                                // className="fas fa-download"
                                                                                                                icon={faDownload}
                                                                                                                style={{ color: "white", fontSize: 14 }}
                                                                                                            />&nbsp;View
                                                                                                        </span>
                                                                                                    </Button>
                                                                                                </a>
                                                                                                {/* </TooltipComponent> */}
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                        }
                                                                    </>
                                                                }
                                                            </>
                                                        )}

                                                </div>
                                            </div>
                                        </AccordionComponent>
                                    </div>
                                    <div style={{ paddingTop: 10 }}>
                                        <AccordionComponent
                                            ref={(accordion) => {
                                                this.internetConnectivity = accordion;
                                            }}
                                            created={this.onCreated}
                                        >
                                            <div>
                                                <div>INTERNET CONNECTIVITY</div>
                                                <form
                                                    id="internetConnectivity"
                                                    method="post"
                                                    className="control-section col-md-12"
                                                >
                                                    <div
                                                        className="col-md-12"
                                                        style={{ marginBottom: "-6px" }}
                                                    >
                                                        <div className="col-xs-2 col-sm-2 col-lg-3 col-md-2">
                                                            <span>
                                                                <RadioButtonComponent
                                                                    key="internet1"
                                                                    checked={internetType === '1' ? true : false}
                                                                    value={1}
                                                                    disabled
                                                                />
                                                            </span>
                                                            <div style={{ marginTop: -8 }}>
                                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; My role is client facing
                                                            </div>
                                                        </div>
                                                        <div className="col-xs-2 col-sm-2 col-lg-3 col-md-2">
                                                            <span>
                                                                <RadioButtonComponent
                                                                    key="internet2"
                                                                    checked={internetType === '2' ? true : false}
                                                                    value={2}
                                                                    disabled
                                                                />
                                                            </span>
                                                            <div style={{ marginTop: -8 }}>
                                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; My role is non-client facing
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="col-md-12"
                                                        style={{ height: "40px", fontWeight: 500, paddingTop: 10 }}
                                                    >
                                                        <div className="col-xs-2 col-sm-2 col-lg-12 col-md-2">
                                                            PRIMARY CONNECTION
                                                            <hr style={{ marginTop: "5px", marginBottom: "5px" }} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="col-xs-2 col-sm-2 col-lg-3 col-md-2">
                                                            <div className="view-input-button">
                                                                <span className="home-office-view-bold">Broadband Provider / Plan</span>
                                                                <div>
                                                                    {primaryBroadbandPlan}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-xs-2 col-sm-2 col-lg-3 col-md-2">
                                                            <div className="view-input-button">
                                                                <span className="home-office-view-bold">Broadband Subscription Speed (Mbps)</span>
                                                                <div>
                                                                    {primaryBroadbandSpeed}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {
                                                            primBrodbandSpeedTestScreen !== "" && primBrodbandSpeedTestScreen !== null &&
                                                            <div className="col-xs-2 col-sm-2 col-lg-1 col-md-2">
                                                                <div className="row" style={{ paddingTop: '8px' }}>
                                                                    <div className="other-asset-text">
                                                                        {/* <TooltipComponent
                                                                            // content="Download screenshot of your internet subscription app, or scanned copy of your internet subscription order"
                                                                            content="Click to download and view previous document"
                                                                            cssClass="customtooltip"
                                                                            position="BottomCenter"
                                                                            isSticky={false}
                                                                            showTipPointer={false}
                                                                        > */}
                                                                        <a style={{ color: "white", textDecoration: 'none' }}
                                                                            href={`${urlConfig.apiurl}getHomeOfficeDocumenttm/${primBrodbandSpeedTestScreen}`} download
                                                                            title="Click to download and view previous document"
                                                                        >
                                                                            <Button
                                                                                style={{
                                                                                    background: "#4b4b55",
                                                                                    color: "white",
                                                                                    height: "40px",
                                                                                    borderRadius: "5px",
                                                                                    // width:100
                                                                                }}
                                                                            >
                                                                                <span style={{ fontSize: 14, fontWeight: '100' }}>
                                                                                    <FontAwesomeIcon
                                                                                        className="fas fa-download"
                                                                                        icon={faDownload}
                                                                                        style={{ color: "white", fontSize: 14 }}
                                                                                    />
                                                                                    &nbsp;View
                                                                                </span>
                                                                            </Button>
                                                                        </a>
                                                                        {/* </TooltipComponent> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                    {
                                                        primaryBroadbandSpeed === '< 500 Mbps' &&
                                                        <div className="col-md-12" style={{ height: "40px", fontWeight: "bold", paddingLeft: "15px", marginTop: '18px' }}>
                                                            <CheckBoxComponent
                                                                checked={internetFlag}
                                                                label="Currently no supplier provides a 500Mbps internet connection at my address. I shall subscribe for the same as soon as it becomes available. Please approve my request with a 200 Mbps connection for now."
                                                                disabled
                                                            />
                                                        </div>
                                                    }
                                                    <div
                                                        className="col-md-12"
                                                        style={{ height: "40px", fontWeight: 500, paddingTop: 10 }}
                                                    >
                                                        <div className="col-xs-2 col-sm-2 col-lg-12 col-md-2">
                                                            SECONDARY CONNECTION (OPTIONAL)
                                                            <hr style={{ marginTop: "5px", marginBottom: "5px" }} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="col-xs-2 col-sm-2 col-lg-3 col-md-2">
                                                            <div className="view-input-button">
                                                                <span className="home-office-view-bold" >Broadband Provider / Plan</span>
                                                                <div>
                                                                    {secondaryBrodbandPlan}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-xs-2 col-sm-2 col-lg-3 col-md-2">
                                                            <div className="view-input-button">
                                                                <span className="home-office-view-bold">Broadband Subscription Speed (Mbps)</span>
                                                                <div>
                                                                    {secondaryBrodbandSpeed}
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {
                                                            secondaryBrodbandSpeedTestScreen !== "" && secondaryBrodbandSpeedTestScreen !== null &&
                                                            <div className="col-xs-2 col-sm-2 col-lg-1 col-md-2">
                                                                <div className="row" style={{ paddingTop: '8px' }}>
                                                                    <div className="other-asset-text">
                                                                        {/* <TooltipComponent
                                                                            // content="Download screenshot of your internet subscription app, or scanned copy of your internet subscription order"
                                                                            content="Click to download and view previous document"
                                                                            cssClass="customtooltip"
                                                                            position="BottomCenter"
                                                                            isSticky={false}
                                                                            showTipPointer={false}
                                                                        > */}
                                                                        <a style={{ color: "white", textDecoration: 'none' }} href={`${urlConfig.apiurl}getHomeOfficeDocumenttm/${secondaryBrodbandSpeedTestScreen}`} download
                                                                            title="Click to download and view previous document"
                                                                        >
                                                                            <Button
                                                                                style={{
                                                                                    background: "#4b4b55",
                                                                                    color: "white",
                                                                                    height: "40px",
                                                                                    borderRadius: "5px",
                                                                                }}
                                                                            >
                                                                                <span style={{ fontSize: 14, fontWeight: '100' }}>
                                                                                    <FontAwesomeIcon
                                                                                        className="fas fa-download"
                                                                                        icon={faDownload}
                                                                                        style={{ color: "white", fontSize: 14 }}
                                                                                    />
                                                                                    &nbsp;View
                                                                                </span>
                                                                            </Button>
                                                                        </a>
                                                                        {/* </TooltipComponent> */}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                    </div>
                                                </form>
                                            </div>
                                        </AccordionComponent>
                                    </div>
                                    <div style={{ paddingTop: 10 }}>
                                        <AccordionComponent
                                            ref={(accordion) => {
                                                this.acrdnHOWS = accordion;
                                            }}
                                            created={this.onCreated}
                                        >
                                            <div>
                                                <div className="accordian-header-padding-left">
                                                    {" "}
                                                    HOME-OFFICE WORKING SPACE *{" "}
                                                </div>
                                                <div className="control-section col-md-12">
                                                    <div
                                                        className="row "
                                                        style={{ backgroundColor: "#FFF", marginLeft: 0 }}
                                                    >
                                                        <div className="col-xs-12 col-sm-2 col-lg-12 col-md-2">
                                                            <div className="row home-office-working-space-text-padding">
                                                                <div
                                                                    style={{ color: "#6F6E74" }}
                                                                    className="col-xs-6 col-sm-2 col-lg-6 col-md-2"
                                                                >
                                                                    1. Fixed private quiet desk space which can accommodate a dual
                                                                    screen set-up
                                                                </div>
                                                                <div
                                                                    style={{ border: "1px blue" }}
                                                                    className="col-xs-4 col-sm-2 col-lg-4 col-md-2"
                                                                >
                                                                    <div className="row" style={{ marginBottom: 5 }}>
                                                                        <div
                                                                            className={`col-xs-6 col-sm-2 col-lg-4 col-md-2 ${prereqQuiteDesk === "1"
                                                                                ? "button-yes-viewHome"
                                                                                : "button-unselect-viewHome"
                                                                                } `}
                                                                        >
                                                                            YES
                                                                        </div>
                                                                        <div
                                                                            className={`col-xs-6 col-sm-2 col-lg-4 col-md-2 ${prereqQuiteDesk === "0"
                                                                                ? "button-no-viewHome"
                                                                                : "button-unselect-viewHome"
                                                                                }`}
                                                                        >
                                                                            NO
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row home-office-working-space-text-padding">
                                                                <div
                                                                    style={{ color: "#6F6E74" }}
                                                                    className="col-xs-6 col-sm-2 col-lg-6 col-md-2"
                                                                >
                                                                    2. Space is isolated from potential distractions (television,
                                                                    personal internet, personal calls,music etc.)
                                                                </div>
                                                                <div
                                                                    style={{ border: "1px blue" }}
                                                                    className="col-xs-4 col-sm-2 col-lg-4 col-md-2"
                                                                >
                                                                    <div className="row" style={{ marginBottom: 5 }}>
                                                                        <div
                                                                            className={`col-xs-6 col-sm-2 col-lg-4 col-md-2 ${prereqNonDistractive === "1"
                                                                                ? "button-yes-viewHome"
                                                                                : "button-unselect-viewHome"
                                                                                }`}
                                                                        >
                                                                            YES
                                                                        </div>
                                                                        <div
                                                                            className={`col-xs-6 col-sm-2 col-lg-4 col-md-2 ${prereqNonDistractive === "0"
                                                                                ? "button-no-viewHome"
                                                                                : "button-unselect-viewHome"
                                                                                }`}
                                                                        >
                                                                            NO
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="row home-office-working-space-text-padding">
                                                                <div
                                                                    style={{ color: "#6F6E74" }}
                                                                    className="col-xs-6 col-sm-2 col-lg-6 col-md-2"
                                                                >
                                                                    3. Plain backdrop for video enabled digital
                                                                    meeting/presentable background for video coverage area
                                                                </div>
                                                                <div className="col-xs-4 col-sm-2 col-lg-4 col-md-2">
                                                                    <div className="row" style={{ marginBottom: 5 }}>
                                                                        <div
                                                                            className={`col-xs-6 col-sm-2 col-lg-4 col-md-2 ${prereqPlainBackground === "1"
                                                                                ? "button-yes-viewHome"
                                                                                : "button-unselect-viewHome"
                                                                                }`}
                                                                        >
                                                                            YES
                                                                        </div>
                                                                        <div
                                                                            className={`col-xs-6 col-sm-2 col-lg-4 col-md-2 ${prereqPlainBackground === "0"
                                                                                ? "button-no-viewHome"
                                                                                : "button-unselect-viewHome"
                                                                                }`}
                                                                        >
                                                                            NO
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </AccordionComponent>
                                    </div>

                                    <div style={{ paddingTop: 10 }}>
                                        <AccordionComponent
                                            ref={(accordion) => {
                                                this.acrdnOS = accordion;
                                            }}
                                            created={this.onCreated}
                                        >
                                            <div>
                                                <div className="accordian-header-padding-left">OTHER ASSETS *</div>
                                                <div className="control-section col-md-12 ">
                                                    <div className="row">
                                                        <div className="col-xs-12 col-sm-2 col-lg-12">
                                                            <div className="col-xs-6 col-sm-2 col-lg-6">
                                                                <div className="row" style={{ padding: 0 }}>
                                                                    <div
                                                                        className="col-xs-2 col-sm-2 col-lg-2"
                                                                        style={{
                                                                            padding: 0,
                                                                            // border: '1px solid #DBDBDB'
                                                                        }}
                                                                    >
                                                                        <img
                                                                            // eslint-disable-next-line
                                                                            src={Holding}
                                                                            style={{ objectFit: "contain" }}
                                                                            alt=""
                                                                            className="col-xs-12 col-sm-2 col-lg-12"
                                                                        />
                                                                    </div>
                                                                    <div className="col-xs-10 col-sm-2 col-lg-10">
                                                                        <div className="other-asset-text">
                                                                            {" "}
                                                                            1. My own desk can accommodate the vertical screen holding
                                                                            arm that would be provided by the organisation
                                                                        </div>
                                                                        <div className="row1" style={{ paddingTop: 10 }}>
                                                                            {/* eslint-disable-next-line */}
                                                                            <div
                                                                                className={`col-xs-6 col-sm-2 col-lg-4 col-md-2 ${assetReqScreenArm === "1"
                                                                                    ? "button-yes-viewHome"
                                                                                    : "button-unselect-viewHome"
                                                                                    }`}
                                                                            >
                                                                                YES
                                                                            </div>
                                                                            {/* eslint-disable-next-line */}
                                                                            <div
                                                                                className={`col-xs-6 col-sm-2 col-lg-4 col-md-2 ${(assetReqScreenArm === "2")
                                                                                    ? "button-no-viewHome"
                                                                                    : "button-unselect-viewHome"
                                                                                    }`}
                                                                            >
                                                                                NO
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-xs-6 col-sm-2 col-lg-6 col-md-2">
                                                                <div className="row" style={{ padding: 0 }}>
                                                                    <div
                                                                        className="col-xs-4 col-sm-2 col-lg-4"
                                                                        style={{
                                                                            padding: 0,
                                                                            // border: '1px solid #DBDBDB'
                                                                        }}
                                                                    >
                                                                        <img
                                                                            // eslint-disable-next-line
                                                                            src={Deskimage}
                                                                            style={{ objectFit: "contain" }}
                                                                            className="col-xs-12 col-sm-2 col-lg-12"
                                                                            alt=""
                                                                        />
                                                                    </div>
                                                                    <div className="col-xs-8 col-sm-2 col-lg-8">
                                                                        <div className="other-asset-text">
                                                                            2. I want an office provided work-desk with the vertical
                                                                            screen-holding arm to be issued to me
                                                                        </div>
                                                                        <div className="row1" style={{ paddingTop: 10 }}>
                                                                            {/* eslint-disable-next-line */}
                                                                            <div
                                                                                className={`col-xs-6 col-sm-2 col-lg-4 col-md-2 ${assetReqWorkDesk === "1"
                                                                                    ? "button-yes-viewHome"
                                                                                    : "button-unselect-viewHome"
                                                                                    }`}
                                                                            >
                                                                                YES
                                                                            </div>
                                                                            {/* eslint-disable-next-line */}
                                                                            <div
                                                                                className={`col-xs-6 col-sm-2 col-lg-4 col-md-2 ${(assetReqWorkDesk === "2")
                                                                                    ? "button-no-viewHome"
                                                                                    : "button-unselect-viewHome"
                                                                                    }`}
                                                                            >
                                                                                NO
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {/* second row */}
                                                        <div className="col-xs-12 col-sm-2 col-lg-12">
                                                            <div className="col-xs-6 col-sm-2 col-lg-6 col-md-2">
                                                                <div className="row" style={{ paddingTop: 15 }}>
                                                                    <div
                                                                        className="col-xs-2 col-sm-2 col-lg-2"
                                                                        style={{
                                                                            padding: 0,
                                                                        }}
                                                                    >
                                                                        <img
                                                                            // eslint-disable-next-line
                                                                            src={Chair}
                                                                            style={{ objectFit: "contain" }}
                                                                            className="col-xs-12 col-sm-2 col-lg-12"
                                                                            alt=""
                                                                        />
                                                                    </div>
                                                                    <div className="col-xs-10 col-sm-2 col-lg-10">
                                                                        <div className="other-asset-text">
                                                                            3. I want an office provided ergonomic chair to be issued
                                                                            to me
                                                                        </div>
                                                                        <div className="row1" style={{ paddingTop: 10 }}>
                                                                            {/* eslint-disable-next-line */}
                                                                            <div
                                                                                className={`col-xs-6 col-sm-2 col-lg-4 col-md-2 ${assetReqChair === "1"
                                                                                    ? "button-yes-viewHome"
                                                                                    : "button-unselect-viewHome"
                                                                                    }`}
                                                                            >
                                                                                YES
                                                                            </div>
                                                                            {/* eslint-disable-next-line */}
                                                                            <div
                                                                                className={`col-xs-6 col-sm-2 col-lg-4 col-md-2 ${assetReqChair === "0"
                                                                                    ? "button-no-viewHome"
                                                                                    : "button-unselect-viewHome"
                                                                                    }`}
                                                                            >
                                                                                NO
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-xs-6 col-sm-2 col-lg-6 col-md-2">
                                                                <div className="row" style={{ paddingTop: 15 }}>
                                                                    <div
                                                                        className="col-xs-4 col-sm-2 col-lg-4"
                                                                        style={{
                                                                            padding: 0,
                                                                        }}
                                                                    >
                                                                        <img
                                                                            // eslint-disable-next-line
                                                                            src={Table31}
                                                                            style={{ objectFit: "contain" }}
                                                                            className="col-xs-12 col-sm-2 col-lg-12"
                                                                            alt=""
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-xs-12 col-sm-2 col-lg-12">
                                                            <div className="col-xs-12 col-sm-2 col-lg-12 col-md-2">
                                                                <div className="row" style={{ paddingTop: 15, paddingLeft: 15 }}>
                                                                    <div className="other-asset-text" style={{ fontWeight: 'bold', color: '#000' }}>
                                                                        Please Note: The Home-Office must have five feet of free-space width-wise (left to right) available behind the office-chair for Company branded backdrop standee.
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        style={{ marginLeft: 15 }}
                                                        id="otherAssets"
                                                        className="e-input-group e-float-icon-left error-div"
                                                    />
                                                </div>
                                            </div>
                                        </AccordionComponent>
                                    </div>
                                    <div style={{ paddingTop: 10 }}>
                                        <div
                                            className=""
                                            style={{
                                                backgroundColor: "#FFF",
                                                // padding: 20,
                                                padding: "20px 20px 10px 10px",
                                                border: "1px solid #939399",
                                            }}
                                        >
                                            <div style={{ float: "left", marginLeft: 20 }}>
                                                <CheckBoxComponent
                                                    checked={declaration}
                                                    disabled
                                                />
                                            </div>
                                            <div style={{ overflow: "hidden", paddingLeft: 10 }}>
                                                I hereby certify that all details mentioned above are authentic
                                                and I understand that any misrepresentation could lead to
                                                relevant action by the organisation. I further declare that any
                                                change in the abovementioned arrangements would be done only
                                                after due approvals from the organisation.
                                            </div>
                                            <div
                                                style={{ marginLeft: 20 }}
                                                id="declearation"
                                                className="e-input-group e-float-icon-left error-div"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xs-12 col-sm-12 col-lg-12" style={{ paddingTop: "10px" }}>
                                    <div className="col-xs-8 col-sm-10 col-lg-11 col-md-11 home-office-note">
                                        You have already submitted your Home Office Confirmation Form earlier, with the details as shown above. If you wish to edit any details and re-submit the form, please click on the <strong> "Edit" </strong> button 👉
                                    </div>
                                    <div className="col-xs-4 col-sm-2 col-lg-1 col-md-1">
                                        <div style={{ float: "right" }}>
                                            <Button
                                                variant="contained"
                                                style={{ color: "white", width: "80px" }}
                                                color={"primary"}
                                                onClick={() => this.updateHomeOffice()}
                                            >
                                                Edit
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </>
            );
        }
    }
}
ViewHomeOffce.propTypes = {
    declearation: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool
    ]),
    preRequisitConfirmation: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.bool
    ]),
};
ViewHomeOffce.defaultProps = {
    declearation: false,
    preRequisitConfirmation: false,
};
const mapStateToProps = ({ homeoffice }) => {
    const { preRequisitConfirmation, declearation, homeOfficeMode } = homeoffice;
    return { preRequisitConfirmation, declearation, homeOfficeMode };
};
export default connect(mapStateToProps, {
    addEditHomeOfficeDetails,
})(ViewHomeOffce);
