import React from "react";
import PropTypes from "prop-types";
import { defEnvironment } from '../../config/config'
import { connect } from "react-redux";
import platform from 'platform';
import saveErrorlog from "../../services/errorlogservice";

class ErrorBoundary extends React.Component {
  constructor() {
    super();
    this.state = {
      error: false,
      errorInfo: null,
    };
  }



  componentDidCatch(error, errorInfo) {
    this.setState({
      error,
      errorInfo,
    });
    const errorMessage = error.message || 'Unknown Error';
    const errorObject = {
      from: 'App',
      errorMessage,
      errorCode: 0,
      userId: this.props.authUser,
      url: '',
      stackTrace: errorInfo?.componentStack.trim().split('\n').join('\n'),
      browserInfo: platform.description,
      environmentInfo: defEnvironment
    }
    saveErrorlog(errorObject);
  }


  render() {
    const { children } = this.props;
    const { error } = this.state;
    if (error) {
      return (
        <>
          <div style={{
            whiteSpace: "pre",
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100vw',
            height: '100vh',
            flexDirection: 'column',
            fontFamily: 'Circular TT Book',
            color: '#959393',
            fontSize: '17px'
          }}
          >
            <img
              src='/images/error-img.svg'
              alt="error"
            />
            <div
              style={{
                fontSize: '20px',
                fontWeight: '700',
                margin: '10px 0'
              }}
            >Something went wrong !</div>
            <p>An error occurred in the application and your page could not be served.</p>
          </div>
        </>
      );
    }
    return children;
  }
}
ErrorBoundary.propTypes = {
  // eslint-disable-next-line
  children: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(Object)
  ]),
};

const mapStateToProps = ({ auth }) => {
  const { authUser } = auth;
  return {
    authUser,
  }
}

export default connect(mapStateToProps)(ErrorBoundary);
