import React from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "@material-ui/core/Button";
import Scrollbars from "react-custom-scrollbars";
import {
  faStar,
  faTimes,
  faCheck,
  faClock,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, TimePicker } from "@material-ui/pickers";
import PropTypes from "prop-types";

import dataService from "../../services/services";
import {
  meetingAcceptance,
  getMeetingDetails,
} from "../../redux/actions/meetingbookingaction";
import ConfirmationPopup from "../../utilities/confirmationpopup";

class NotificationPopUp extends React.Component {
  constructor() {
    super();
    this.state = {
      notificationData: [],
      notificationCount: 0,
      acceptanceStatus: "",
      open: false,
      title: "",
      message: "",
      starttime: new Date(),
      starttimeopen: false,
      meetingId: null,
      button1Caption: "",
      button2Caption: "",
      seeAll: 0,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleStartTimeClose = this.handleStartTimeClose.bind(this);
    this.handleMeeting = this.handleMeeting.bind(this);
  }

  async componentDidMount() {    
    const { authUser } = this.props;
    var elems = document.querySelectorAll(".dropdown-menu-right");

    [].forEach.call(elems, function (el) {
      el.className = el.className.replace("dropdown-menu-right", "");
    });
    try {
      const response = await dataService.getMeetingNotifiction(authUser, "all");
      this.setState(() => {
        const notificationData = response.result.slice(0, 3);
        const notificationCount = response.result.length;
        return {
          notificationData,
          notificationCount,
        };
      });


    } catch (error) { }
  }


  handleConfirmNo = () => {
    this.setState({ open: false });
  };

  handleStartTimeClose = () => {
    this.setState({ starttimeopen: false });
  };

  handleMeeting() {
    const { acceptanceStatus } = this.state;
    switch (acceptanceStatus) {
      case "Accept":
        this.setState({ open: false });
        break;
      case "Decline":
        break;
      case "Suggest":
        break;
      default:
    }
  }

  async loadAllNotifications() {
    const { authUser } = this.props;
    try {
      const response = await dataService.getMeetingNotifiction(authUser, "all");
      this.setState(() => {
        const notificationData = response.result;
        const notificationCount = response.result.length;
        // eslint-disable-next-line
        this.state.seeAll = 1;
        return {
          notificationData,
          notificationCount,
        };
      });
    } catch (error) { }
  }

  async handleInputChange(pType, meetingid, volume) {
    const { seeAll, meetingId } = this.state;
    const { authUser } = this.props;
    if (seeAll === 0) {
      const response = await dataService.getMeetingNotifiction(authUser, "all");
      this.setState(() => {
        const notificationData = response.result.slice(0, 4);
        const notificationCount = response.result.length;
        return {
          notificationData,
          notificationCount,
        };
      });
    }
    switch (pType) {
      case "Accept":
        // eslint-disable-next-line
        await this.props.meetingAcceptance({
          meetingId: meetingid,
          status: 1,
          volume,
          user_id: authUser,
          meetingmode: "P"
        });
        this.setState((state) => {
          const notificationData = state.notificationData.filter(
            (i) => i.meetingId !== meetingid
          );
          return { notificationData };
        });
        break;
      case "Decline":
        // eslint-disable-next-line
        await this.props.meetingAcceptance({
          meetingId: meetingid,
          status: 2,
          volume,
          user_id: authUser,
          meetingmode: "P"
        });

        this.setState((state) => { // NOSONAR
          const notificationData = state.notificationData.filter(
            (i) => i.meetingId !== meetingid
          );
          return { notificationData };
        });
        break;
      case "Suggest":
        this.setState({
          acceptanceStatus: pType,
          starttimeopen: true,
          meetingId,
          meetingmode: "P"
        });
        break;
      default:
    }
  }
  closeNotificationTab = () => {
    // eslint-disable-next-line
    this.props.hideNotification()
  }
  render() {
    const {
      notificationData,
      seeAll,
      notificationCount,
      starttimeopen,
      starttime,
      open,
      message,
      button1Caption,
      button2Caption,
    } = this.state;
    return (
      <>
        <ul className="list-unstyled">
          <div>
            <div className="notificationBox">
              <div className='head'>Notification
                <div style={{ float: "right" }}>
                  <IconButton
                    style={{
                      color: "white",
                      marginRight: "-13px",
                      marginTop: "-5px",
                    }}
                    aria-label="close"
                    onClick={this.closeNotificationTab.bind(this)}
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
              </div>
              {/* <div className="head">Notification</div> */}
              <Scrollbars
                className="messages-list scrollbar"
                style={{ height: "280px", background: "#fff" }}
              >
                <div className="content">
                  <ul>
                    {notificationData.map((eachNotification) => {
                      const displayDt = eachNotification.dt_attendee_start_date;
                      let mr = eachNotification.MeetingRooms;
                      const mrTooltip = `Meeting Room : ${eachNotification.MeetingRooms}`;
                      if (eachNotification.MeetingRooms.indexOf(",") > -1) {
                        mr = eachNotification.MeetingRooms.slice(
                          0,
                          eachNotification.MeetingRooms.indexOf(",")
                        ).trim();
                      }
                      const meetingTitle =
                        eachNotification.MeetingTitle.length > 55
                          ? `${eachNotification.MeetingTitle.substring(
                            0,
                            52
                          )}...`
                          : eachNotification.MeetingTitle;
                      return (
                        <li key={eachNotification.meetingId}>
                          <span
                            className="notify1"
                            title={eachNotification.MeetingTitle}
                          >
                            {meetingTitle}
                          </span>
                          <div className="notifydetail">
                            <span>
                              <FontAwesomeIcon
                                icon={faClock}
                                title="Meeting Date/Time"
                                className="mr-xs text-muted"
                              />
                              {displayDt}
                            </span>
                            <span>
                              <FontAwesomeIcon
                                icon={faMapMarkerAlt}
                                title={mrTooltip}
                                className="mr-xs text-muted"
                              />
                              {mr}
                            </span>
                            <span>
                              <FontAwesomeIcon
                                icon={faStar}
                                title="Chair Person"
                                className="mr-xs text-muted"
                              />
                              {eachNotification.ChairPerson}
                            </span>
                          </div>
                          <div className="btn-notify">
                            <Button
                              style={{
                                background: "#297125",
                                fontSize: "12x",
                                color: "#fff",
                              }}
                              className="mt-none mr-sm pl-sm pr-sm"
                              title=""
                              size="small"
                              variant="contained"
                              color="inherit"
                              onClick={() =>
                                this.handleInputChange(
                                  "Accept",
                                  eachNotification.meetingId,
                                  "single"
                                )
                              }
                            >
                              <FontAwesomeIcon
                                style={{ color: "#fff" }}
                                icon={faCheck}
                                className="mr-xs"
                              />
                              Meeting
                            </Button>
                            {eachNotification.fk_series_id > 0 ? (
                              <Button
                                style={{
                                  background: "#297125",
                                  fontSize: "12x",
                                  color: "#fff",
                                }}
                                className="mt-none mr-sm pl-sm pr-sm"
                                title=""
                                size="small"
                                variant="contained"
                                color="inherit"
                                onClick={() =>
                                  this.handleInputChange(
                                    "Accept",
                                    eachNotification.meetingId,
                                    "series"
                                  )
                                }
                              >
                                <FontAwesomeIcon
                                  style={{ color: "#fff" }}
                                  icon={faCheck}
                                  className="mr-xs"
                                />
                                Series
                              </Button>
                            ) : null}
                            <Button
                              style={{ fontSize: "12px" }}
                              className="mt-none mr-sm pl-sm pr-sm"
                              title=""
                              size="small"
                              variant="contained"
                              color="primary"
                              onClick={() =>
                                this.handleInputChange(
                                  "Decline",
                                  eachNotification.meetingId,
                                  "single"
                                )
                              }
                            >
                              <FontAwesomeIcon
                                icon={faTimes}
                                className="mr-xs"
                              />
                              Meeting
                            </Button>
                            {eachNotification.fk_series_id > 0 && (
                              <Button
                                style={{ fontSize: "12px" }}
                                className="mt-none mr-sm pl-sm pr-sm"
                                title=""
                                size="small"
                                variant="contained"
                                color="primary"
                                onClick={() =>
                                  this.handleInputChange(
                                    "Decline",
                                    eachNotification.meetingId,
                                    "series"
                                  )
                                }
                              >
                                <FontAwesomeIcon
                                  icon={faTimes}
                                  className="mr-xs"
                                />
                                Series
                              </Button>
                            )}
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </Scrollbars>
              {seeAll === 0 ? (
                notificationCount > 3 ? ( // NOSONAR
                  <div className="text-center">
                    <button
                      className="seeMore"
                      onClick={() => this.loadAllNotifications()}
                      onKeyPress={() => this.loadAllNotifications()}

                    >
                      See All
                    </button>
                  </div>
                ) : (
                  <div className="text-center" />
                )
              ) : (
                <div className="text-center seeMore" />
              )}
            </div>
          </div>
        </ul>
        <div style={{ display: "none" }}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <TimePicker
              style={{ width: "100%" }}
              className="mr-lg"
              inputVariant="outlined"
              id="meeting-end-time"
              clearable
              ampm={false}
              open={starttimeopen}
              value={starttime}
            />
          </MuiPickersUtilsProvider>
        </div>
        <ConfirmationPopup
          showButton={{ type: "1" }}
          open={open}
          button1function={() => this.handleMeeting()}
          button2function={() => this.handleConfirmNo()}
          headerMessage={message}
          button1Text={button1Caption}
          button2Text={button2Caption}
          handleConfirmClose={() => this.handleConfirmNo()}
        />  

      </>
    );
  }
}
NotificationPopUp.propTypes = {
  // eslint-disable-next-line
  authUser: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
};

NotificationPopUp.defaultProps = {
  authUser: "",
};
const mapStateToProps = ({ auth, meetingbooking, meetingcategory }) => {
  const { authUser } = auth;
  const { meetingPopOpen, meetingStatus } = meetingbooking;
  const { categoryName } = meetingcategory;
  return { authUser, meetingPopOpen, meetingStatus, categoryName };
};

export default connect(mapStateToProps, {
  meetingAcceptance,
  getMeetingDetails,
})(NotificationPopUp);