 const stylingNewOrgChart=`
  .e-grid .e-rowcell{
   font-size: 14px !important;
    color: #4B4A52 !important; text-align: left !important; padding-left:18px !important;
   font-family: "Roboto", "Segoe UI", "GeezaPro", "DejaVu Serif", "sans-serif", "-apple-system", "BlinkMacSystemFont" !important;
   font-weight: normal !important;
  }
  .e-grid.e-wrap .e-gridheader .e-sortfilterdiv{
   display: none !important;
}
 .e-grid .e-icon-filter {
    margin-top: 15px !important;
}
.e-grid .e-gridcontent tr td {
   font-weight: 500;
   
   
}
.e-headertext{
       font-size: 15px !important;
}
.e-grid .e-gridcontent tr th {
   padding: 5px !important;

}
.e-pager .e-pagercontainer{
   border-radius: 4px;
    border-style: solid;
    border-width: 0;
   position: absolute;
   left: 150px;
}
.e-sortnumber{
   display: none !important;
}
`

export default stylingNewOrgChart