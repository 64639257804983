import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import NotificationContainer from "../../../components/notification/notifycontainer";
import CircularProgress from "@material-ui/core/CircularProgress";
import queryString from "query-string";
import PropTypes from "prop-types";
import { showLoader, userSignIn, userSignOut } from "../../../redux/actions/auth";
import { defEnvironment, defActualDaysBefore } from "../../../config/config";
import "./index.css";
import { getNotify, encrypt } from "../../other/commonfunction";
import { ERROR } from "../../../assets/constants/constants";
import logo from "../../../assets/images/logo.png"
import { updateLnAData } from "../../../redux/actions/lna/lnaaction";
import { getLnAData } from "../../other/commonlayoutfunction";
import LoginProgress from "../../../components/loginprogresspage/loginprogress";
import InvalidUrl from "../../../components/invalidurlpage/invalidurl";
class LogIn extends React.Component {
  constructor() {
    super();
    this.state = { key: "", password: "", loginType: 4 };
    document.title = "Enterprise Calendar - Login";
  }

  componentDidMount() {
    const { history } = this.props;
    this.props.userSignOut();
    // eslint-disable-next-line
    const params = queryString.parse(this.props.location.search);
    if (defEnvironment === "UAT" || defEnvironment === "LIVE" && (typeof params.key === "undefined" && typeof params.appCode === "undefined")) {
      history.push("/error");
    } else {
      if ('key' in params) {
        this.setState({ loginType: 1 })
        localStorage.setItem("defActualDays", encrypt(defActualDaysBefore));
        // eslint-disable-next-line
        this.props.showLoader();
        // eslint-disable-next-line
        this.props.userSignIn({ key: params.key, password: "aer#43$%^#" });
      } else if ('appCode' in params) {
        this.setState({ loginType: 2 })
        this.props.userSignIn({ appCode: params?.appCode, authUser: params?.authUser, token: params?.token, authenticationReason: 'app-change' });
      } else if (defEnvironment === 'QA') {
        history.push("/login");
      } else {
        history.push("/invalidurl");
      }
    }

  }

  componentDidUpdate() {
    const { authUser, history } = this.props;
    const params = queryString.parse(this.props.location.search);
    if (authUser) {
      getLnAData(authUser, 'FY').then(result => {
        let tempData = {}
        if (result && result.financialYearData && result.financialYearData.length > 0) {
          let obj = result.financialYearData.find(ele => { return ele.CurrYear === 1 || ele.CurrYear === true })
          if (obj) tempData = { fyId: obj.Id, financialYearData: result.financialYearData }
          else tempData = { fyId: result.financialYearData[0].Id, financialYearData: result.financialYearData }
          this.props.updateLnAData(tempData);
        }
      }).catch(err => {
        console.log('err====', err);
      })
    }
    if (authUser !== null) {
      if ('redirectUrl' in params) {
        history.push(`${params?.redirectUrl}?id=${params?.id}&appId=${params?.appId}`);
      } else {
        history.push("/");
      }

    }
  }

  render() {
    // eslint-disable-next-line
    const { key, password, loginType } = this.state;
    const { loader } = this.props;
    return (
      <>
        {
          loginType === 1 && (
            <LoginProgress heading='Please wait, we are verifying your ID credentials and loading your personalized content.' />
          )
        }
        {
          loginType === 2 && (
            <LoginProgress heading='Please wait, we are redirecting you to your personalized space.' />
          )
        }
        {
          loginType === 3 && (
            <InvalidUrl />
          )
        }
        {
          loginType === 4 && <div
            className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3 centered"
          >
            <div className="app-login-main-content">
              <div className="app-logo-content d-flex align-items-center justify-content-center">
                <Link className="logo-lg" to="/" title="Enterprise Calendar">
                  <img
                    // eslint-disable-next-line
                    src={logo}
                    alt="Enterprise Calendar"
                    title="Enterprise Calendar"
                  />
                </Link>
              </div>

              <div className="app-login-content">
                <div className="app-login-header mb-4">
                  <h4>Please enter email and password</h4>
                </div>

                <div className="app-login-form">
                  <form>
                    <fieldset>
                      <TextField
                        label="Email"
                        fullWidth
                        onChange={(event) =>
                          // eslint-disable-next-line
                          this.setState({ key: event.target.value })
                        }
                        // eslint-disable-next-line
                        defaultValue={key}
                        margin="normal"
                        className="mt-1 my-sm-3"
                      />
                      <TextField
                        type="password"
                        label="Password"
                        fullWidth
                        onChange={(event) =>
                          this.setState({ password: event.target.value })
                        }
                        defaultValue={password}
                        margin="normal"
                        className="mt-1 my-sm-3"
                      />

                      <div className="mb-3 d-flex align-items-center justify-content-between">
                        <Button
                          onClick={() => {
                            if (this.state.key === "") {
                              getNotify(ERROR, "Invalid Email and Password");
                              return;
                            }
                            // eslint-disable-next-line
                            this.props.showLoader();
                            // eslint-disable-next-line
                            this.props.userSignIn({ ...this.state, authenticationReason: 'email-login' });
                          }}
                          variant="contained"
                          color="primary"
                        >
                          Sign In
                        </Button>
                      </div>
                    </fieldset>
                  </form>
                </div>
              </div>
            </div>
            {loader && (
              <div className="loader-view">
                <CircularProgress />
              </div>
            )}
            <NotificationContainer />
          </div>
        }
      </>
    );
  }
}
LogIn.propTypes = {
  // eslint-disable-next-line
  history: PropTypes.object,
  authUser: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  showMessage: PropTypes.bool,
  loader: PropTypes.bool,
  alertMessage: PropTypes.string,
};

LogIn.defaultProps = {
  history: {},
  authUser: "",
  showMessage: false,
  loader: false,
  alertMessage: "",
};
const mapStateToProps = ({ auth }) => {
  const { loader, alertMessage, showMessage, authUser } = auth;
  return { loader, alertMessage, showMessage, authUser };
};

export default connect(mapStateToProps, {
  userSignIn, userSignOut,
  showLoader, updateLnAData,
})(LogIn);
