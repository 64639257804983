import React, { Component } from 'react'
import RatingObjectiveKRContainer from './ratingobjectivekrcontainer';
import RatingImg from '../../assets/images/ratingreview.jpg';
import OKRServices from "../../services/okr/okrservices";
import RatingKRContainer from './ratingkrcontainer'
import { addUpdateOkrRatingRedux } from '../../redux/actions/okrrating/okrratingaction'
import { connect } from 'react-redux'
import NotificationContainer from "../../components/notification/notifycontainer";
import { SelfAndSupervisorRatingPending, RatingLocked, RatingNotApplicable, RatingNotOpen } from '../../assets/constants/constants'
import '../../app/layout/okrratinglayout/okrrating.css'
import OkrCommonFiles from '../okrproductiveallocation/okrcommonfiles';

class RatingreTabs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            height: null,
            windowAdjust: null,
            showNoDataMessage: true,
            businessOKRCounter: 0,
            centralOKRCounter: 0,
            LPOKRCounter: 0,
            CFOKRCounter: 0,
            refreshChildVar: false,
            selectedTab:'BusinessOKR'
        };
        this.updateDimensions = this.updateDimensions.bind(this)
    }

    componentDidMount = () => {
        window.addEventListener("resize", this.updateDimensions());
        const { empId, fyId, monthOfRating } = this.props;
        const {selectedTab}=this.state;
        setTimeout(() => {
            if (empId !== null && fyId !== null && monthOfRating !== null) {
                this.getUserWiseProductivityAllocation()
                this.openTab(null, selectedTab)
            }
        }, 10)
    };

    getUserWiseProductivityAllocation() {
        try {
            const {selectedTab}=this.state;
            const { empId, fyId, authUser } = this.props;
            if (empId !== null && fyId !== null) {
                const { businessOKRCounter, LPOKRCounter, centralOKRCounter, CFOKRCounter } = this.state
                const userWiseProductivityAllocation = Promise.resolve(
                    OKRServices.getUserWiseProductivityAllocation(authUser, empId, "ALL", 0, fyId)
                );
                userWiseProductivityAllocation.then(async (result) => {
                    if (result !== null && typeof result !== 'undefined' && result.length > 0) {
                        const productivityallocationData = {
                            businessUserImpact: result[0].userImpactPerc,
                            businessUserVariableImpact: result[0].oteVariableImpactPerc,
                            businessVariableLinked: result[0].variableLinked,
                            ratingMecanismStart: result[0].ratingMechanismPercStart,
                            ratingMecanismEnd: result[0].ratingMechanismPercEnd,

                            centralUserImpact: result[1].userImpactPerc,
                            centralUserVariableImpact: result[1].oteVariableImpactPerc,
                            centralVariableLinked: result[0].variableLinked,
                            centralUserFrequency: result[1].frequency,

                            LPUserImpact: result[2].userImpactPerc,
                            LPUserVariableImpact: result[2].oteVariableImpactPerc,
                            LPVariableLinked: result[0].variableLinked,
                            LPUserFrequency: result[2].frequency
                        }
                        this.props.addUpdateOkrRatingRedux({ productivityallocationData: productivityallocationData });
                        setTimeout(() => {
                            this.setState({
                                businessOKRCounter: businessOKRCounter + 1,
                                LPOKRCounter: LPOKRCounter + 1,
                                centralOKRCounter: centralOKRCounter + 1,
                                CFOKRCounter: CFOKRCounter + 1
                            })
                            this.setState({ showNoDataMessage: false })
                            this.openTab(null, selectedTab)
                        }, 100)
                    }
                });
            } else {
                this.props.addUpdateOkrRatingRedux({ productivityallocationData: [] });
            }
        } catch (error) {
            console.log(error, 'getUserWiseProductivityAllocation:error')
        }
    }

    componentDidUpdate = (prevProps, prevState) => {
        const { showStatus } = this.props;
        if (showStatus !== prevProps.showStatus) {
            this.updateDimensions();
        }
    };
    updateDimensions() {
        const updateheight = window.innerHeight - 305;
        const windowAdjust = window.innerHeight - 223;
        this.setState({
            height: updateheight,
            windowAdjust: windowAdjust,
        });
    }
    //WARNING! To be deprecated in React v17. Use new lifecycle static getDerivedStateFromProps instead.
    componentWillReceiveProps(nextProps) {
        const {selectedTab}=this.state;
        if (nextProps.refreshRatingDataFlag === 'updated') {
            if (nextProps.empId !== null && nextProps.fyId !== null && nextProps.monthOfRating !== null) {
                this.setState({
                    showNoDataMessage: false,
                }, () => { 
                    document.getElementById(selectedTab).style.display = "block"; 
                })
            } else {
                this.setState({
                    showNoDataMessage: true,
                }, () => { document.getElementById("NoData").style.display = "block"; })
            }
            setTimeout(() => {
                this.getUserWiseProductivityAllocation()
                this.props.addUpdateOkrRatingRedux({ refreshRatingDataFlag: 'updateSummary' })
            }, 10)
        }

        if (nextProps.ratingCategoryStatus === 'updated1') {
            this.openTab(null, selectedTab)
        }

        if (nextProps.pendingOKRs !== "") {
            switch (nextProps.pendingOKRs) {
                case "Business OKR":
                    this.openTab(null, "BusinessOKR"); break;
                case "Central OKR":
                    this.openTab(null, "CentralOKR"); break;
                case "Leadership Principles":
                    this.openTab(null, "LeadershipPrinciple"); break;
                case "Common Files":
                    this.openTab(null, "CommonFiles"); break;
                default:
            }
            this.props.addUpdateOkrRatingRedux({ pendingOKRs: '' })
        }
    }
    openTab = (evt, tabName) => {

        const { empId, fyId, monthOfRating } = this.props
        let ShowDetails = false;
        if (empId > 0 && fyId > 0 && (monthOfRating > 0)) {
            ShowDetails = true;
        }
        if (tabName === "CommonFiles") {
            ShowDetails = true;
        }
        let i;
        const tabcontent = document.getElementsByClassName("tabcontent");
        // eslint-disable-next-line
        for (i = 0; i < tabcontent.length; i++) {
            tabcontent[i].style.display = "none";
        }
        const tablinks = document.getElementsByClassName("tablinks");
        // eslint-disable-next-line
        for (i = 0; i < tablinks.length; i++) {
            tablinks[i].className = tablinks[i].className.replace(" active", "");
        }
        if (document.getElementById(tabName) !== null && ShowDetails)
            document.getElementById(tabName).style.display = "block";
        if (evt !== null)
            evt.currentTarget.className += " active";
        else if (document.getElementById('tab' + tabName) !== null)
            document.getElementById('tab' + tabName).classList.add("active");

        setTimeout(() => {
            this.setState({ refreshChildVar: !this.state.refreshChildVar, selectedTab: tabName})
        }, 1000)
    };

    ratingNotOpened = (pType) => {//NOSONAR
        const { ratingStatusData, ratingStatus } = this.props;
        let msg = <div style={{ display: "flex", flexDirection: "row", justifyContent: 'center' }}>Please select Performance Period for rating.</div>
        if (ratingStatus === 'Locked')
            msg = RatingLocked
        else if (ratingStatus === 'Not Applicable')
            msg = RatingNotApplicable
        else if (ratingStatus === 'Not Open')
            msg = RatingNotOpen
        else if (typeof ratingStatusData !== 'undefined' && ratingStatusData.length > 0) {
            let selfStatus = "";
            let supStatus = "";
            let OKRStatus = "";
            if (ratingStatusData.length > 0) {
                ratingStatusData[1].Ratings.forEach(item => {
                    if (item.name === 'Self Rating') {
                        selfStatus = item.allocationStatus
                    }
                    if (item.name === 'Supervisor Rating') {
                        supStatus = item.allocationStatus
                    }
                })
                ratingStatusData[0].Allocations.forEach(item => {
                    if (item.allocationName === pType) {
                        OKRStatus = item.allocationStatus
                    }
                })
            }
            if (OKRStatus === 'NA') {
                msg = RatingNotApplicable
            }
            else {
                if (selfStatus === "Pending" || supStatus === "Pending") {
                    msg = SelfAndSupervisorRatingPending
                } else if (selfStatus === "Pending" && supStatus !== "Pending") {
                    msg = "Review window is not opened as Self Rating is Pending."
                } else if (selfStatus !== "Pending" && supStatus === "Pending") {
                    msg = "Review window is not opened as Supervisor Rating is Pending."
                }

            }

        }
        return (
            <div className="center-box-padding" >
                <div className="new-productivity-allocation-no-data-header">
                    <div
                    // style={{ marginTop: '1%' }}
                    >
                        <div>
                            <center>
                                <img src={RatingImg} alt="Image Not Found" />
                                <div className='ratingwelcometext pt-sm'>Welcome to OKR - Rating and Review</div>
                                <div className='ratingwelcomesubtext pt-sm'>
                                    {msg}
                                </div>
                            </center>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    render() {//NOSONAR
        const { height, windowAdjust, showNoDataMessage, businessOKRCounter, LPOKRCounter, centralOKRCounter } = this.state;
        const { showStatus, ratingTabs, productivityallocationData, ratingBusinessOkr, ratingCentralOkr, ratingLPOkr,
            reviewBusinessOkr, reviewCentralOkr, reviewLPOkr, empId, fyId, monthOfRating, ratingStatusData } = this.props;
        let businessOKRCnt = 0, centralOKRCnt = 0, LPOKRCnt = 0
        if (ratingTabs === 'okr-review') {
            businessOKRCnt = reviewBusinessOkr.length
            centralOKRCnt = reviewCentralOkr.length;
            LPOKRCnt = Object.keys(reviewLPOkr).length;
        } else {
            businessOKRCnt = ratingBusinessOkr.length
            centralOKRCnt = ratingCentralOkr.length;
            LPOKRCnt = Object.keys(ratingLPOkr).length;
        }
        let ShowDetails = false;
        if (empId > 0 && fyId > 0 && (monthOfRating > 0)) {
            ShowDetails = true;
        }

        return (
            <div className='container-fluid '>
                <NotificationContainer />
                <div className='row' style={{ marginLeft: -30, marginRight: -30 }}>
                    {!showNoDataMessage &&
                        <>
                            <div className="tab">
                                <div className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12" >
                                    <button
                                        id="tabBusinessOKR"
                                        type="submit"
                                        className="tablinks col-2 col-lg-2 col-md-2 col-sm-2 col-xs-2 active rating-tab-font"
                                        onClick={(e) => { this.openTab(e, "BusinessOKR") }}
                                    >Business OKR <span id='businessOkrCount'></span>
                                    </button>
                                    <button
                                        id="tabCentralOKR"
                                        type="submit"
                                        className="tablinks col-2 col-lg-2 col-md-2 col-sm-2 col-xs-2 rating-tab-font"
                                        onClick={(e) => this.openTab(e, "CentralOKR")}
                                    >
                                        Central OKR <span id='centralOkrCount'></span>
                                    </button>
                                    <button
                                        id="tabLeadershipPrinciple"
                                        type="submit"
                                        className="tablinks col-2 col-lg-2 col-md-2 col-sm-2 col-xs-2 rating-tab-font"
                                        onClick={(e) => this.openTab(e, "LeadershipPrinciple")}
                                    >
                                        Leadership Principles <span id='lpOkrCount'></span>
                                    </button>
                                    <button
                                        id="tabCommonFiles"
                                        type="submit"
                                        className="tablinks col-2 col-lg-2 col-md-2 col-sm-2 col-xs-2 rating-tab-font"
                                        onClick={(e) => this.openTab(e, "CommonFiles")}
                                    >
                                        Document <span id='ratingCommonDocOkrCount'></span>
                                    </button>
                                    {/* <button
                                        type="submit"
                                        className="tablinks col-2 col-lg-2 col-md-2 col-sm-2 col-xs-2 rating-tab-font"
                                        onClick={(e) => this.openTab(e, "BrandValues")}
                                    >
                                        Brand Values
                                    </button>
                                    <button
                                        type="submit"
                                        className="tablinks col-2 col-lg-2 col-md-2 col-sm-2 col-xs-2 rating-tab-font"
                                        onClick={(e) => this.openTab(e, "SharedOKR")}
                                    >
                                        Shared OKR
                                    </button>
                                    <button
                                        type="submit"
                                        className="tablinks col-2 col-lg-2 col-md-2 col-sm-2 col-xs-2 rating-tab-font"
                                        onClick={(e) => this.openTab(e, "GeneralFeedback")}
                                    >
                                        General Feedback
                                    </button> */}
                                </div>
                            </div>

                            <div>
                                <div
                                    style={{
                                        height: showStatus ? height : windowAdjust,
                                        overflowY: "hidden",
                                        overflowX: "hidden",
                                        borderRadius: 6
                                    }}
                                    id="BusinessOKR"
                                    className="tabcontent">
                                    <div className='tab-header-block'>
                                        <span className='tab-header-text'>Business OKR</span>
                                        {businessOKRCnt > 0 && ShowDetails && <>
                                            <span className='weightage-text'> Weightage:</span><span className='okr-central-subheader-value'>
                                                {typeof productivityallocationData.businessUserImpact !== 'undefined' && <> {`${productivityallocationData.businessUserImpact}%`}</>}
                                            </span>
                                            <span className='weightage-text'>Impact on Variable Pay:</span><span className='okr-central-subheader-value'>
                                                {typeof productivityallocationData.businessUserVariableImpact !== 'undefined' && <> {`${productivityallocationData.businessUserVariableImpact}%`}</>}
                                            </span>
                                            <br />
                                        </>}
                                        {ratingTabs === 'supervisor-rating' && (ratingStatusData.length > 0 && ratingStatusData[1].Ratings.length > 0) && ratingStatusData[1].Ratings[0].allocationStatus === "Pending" && businessOKRCnt > 0 &&
                                            <div className='error-div' style={{ position: 'absolute', textAlign: 'center', right: 43, marginTop: -10 }}>Note : Supervisor Inputs can be initiated only after Self-Inputs are completed by the Team-member.</div>
                                        }
                                    </div>
                                    <div>

                                        {businessOKRCnt === 0 && this.ratingNotOpened("Business OKR")}
                                        {
                                            <RatingObjectiveKRContainer key={'businessOKRRating'}
                                                showStatus={showStatus} ratingTabs={ratingTabs} OKRType={'1'}
                                                businessOKRCounter={businessOKRCounter}
                                                refreshChildVar={this.state.refreshChildVar}
                                            />
                                        }

                                    </div>
                                </div>
                                <div
                                    style={{
                                        height: showStatus ? height : windowAdjust,
                                        overflowY: "hidden",
                                        overflowX: "hidden",
                                        borderRadius: 6
                                    }}
                                    id="CentralOKR"
                                    className="tabcontent">
                                    <div className='tab-header-block'>
                                        <span className='tab-header-text'>Central OKR</span>
                                        {centralOKRCnt > 0 && ShowDetails &&
                                            <>
                                                <span className='weightage-text'> Weightage:</span><span className='okr-central-subheader-value'>
                                                    {typeof productivityallocationData.centralUserImpact !== 'undefined' && <> {`${productivityallocationData.centralUserImpact}%`}</>}
                                                </span>
                                                <span className='weightage-text'>Impact on Variable Pay:</span><span className='okr-central-subheader-value'>
                                                    {typeof productivityallocationData.centralUserVariableImpact !== 'undefined' && <> {`${productivityallocationData.centralUserVariableImpact}%`}</>}
                                                </span>
                                                <span className='weightage-text'>Review Frequency:</span><span className='okr-central-subheader-value'>
                                                    {typeof productivityallocationData.centralUserFrequency !== 'undefined' && <> {`${productivityallocationData.centralUserFrequency}`}</>}
                                                </span>
                                            </>}

                                    </div>
                                    <div>
                                        {centralOKRCnt === 0 && this.ratingNotOpened("Central OKR")}

                                        {ShowDetails &&
                                            <RatingObjectiveKRContainer key={'centralOKRRating'} showStatus={showStatus}
                                                ratingTabs={ratingTabs}
                                                OKRType={'2'}
                                                centralOKRCounter={centralOKRCounter}
                                                refreshChildVar={this.state.refreshChildVar}
                                            />
                                        }
                                    </div>
                                </div>
                                <div
                                    style={{
                                        height: showStatus ? height : windowAdjust,
                                        overflowY: "hidden",
                                        overflowX: "hidden",
                                        borderRadius: 6
                                    }}
                                    id="LeadershipPrinciple"
                                    className="tabcontent">
                                    <div className='tab-header-block'>
                                        <span className='tab-header-text'>Leadership Principles</span>
                                        {LPOKRCnt > 0 && ShowDetails &&
                                            <>
                                                <span className='weightage-text'>Weightage:</span><span className='okr-central-subheader-value'>
                                                    {typeof productivityallocationData.LPUserImpact !== 'undefined' && <> {`${productivityallocationData.LPUserImpact}%`}</>}
                                                </span>
                                                <span className='weightage-text'>Impact on Variable Pay:</span><span className='okr-central-subheader-value'>
                                                    {typeof productivityallocationData.LPUserVariableImpact !== 'undefined' && <> {`${productivityallocationData.LPUserVariableImpact}%`}</>}
                                                </span>
                                                <span className='weightage-text'>Review Frequency:</span><span className='okr-central-subheader-value'>
                                                    {typeof productivityallocationData.LPUserFrequency !== 'undefined' && <> {`${productivityallocationData.LPUserFrequency}`}</>}
                                                </span>
                                            </>
                                        }
                                    </div>
                                    <div>
                                        {LPOKRCnt === 0 && this.ratingNotOpened("Leadership Principles")}
                                        {ShowDetails &&
                                            <RatingKRContainer showStatus={showStatus} ratingTabs={ratingTabs}
                                                OKRType={'3'}
                                                LPOKRCounter={LPOKRCounter}
                                                refreshChildVar={this.state.refreshChildVar}
                                            />
                                        }
                                    </div>
                                </div>
                                <div
                                    style={{
                                        height: showStatus ? height : windowAdjust,
                                        overflowY: "hidden",
                                        overflowX: "hidden",
                                        borderRadius: 6,
                                        padding: 0
                                    }}
                                    id="CommonFiles"
                                    className="tabcontent">
                                    <div>
                                        <OkrCommonFiles windowAdjust={showStatus ? height : windowAdjust} tabType="OKRRating" />
                                    </div>
                                </div>
                                <div
                                    style={{
                                        height: showStatus ? height : windowAdjust,
                                        overflowY: "hidden",
                                        overflowX: "hidden",
                                        borderRadius: 6,
                                    }}
                                    id="BrandValues"
                                    className="tabcontent"
                                >
                                    Brand Values
                                </div>
                                <div
                                    style={{
                                        height: showStatus ? height : windowAdjust,
                                        overflowY: "hidden",
                                        overflowX: "hidden",
                                        borderRadius: 6,
                                    }}
                                    id="SharedOKR"
                                    className="tabcontent"
                                >
                                    Shared OKR
                                </div>
                                <div
                                    style={{
                                        height: showStatus ? height : windowAdjust,
                                        overflowY: "hidden",
                                        overflowX: "hidden",
                                        borderRadius: 6,
                                    }}
                                    id="GeneralFeedback"
                                    className="tabcontent">
                                    General Feedback
                                </div>

                            </div>
                        </>
                    }
                    {(showNoDataMessage || ShowDetails === false || monthOfRating === 0) &&
                        <>
                            <div
                                id='NoData'
                                style={{
                                    height: showStatus ? height : windowAdjust,
                                    overflowY: "hidden",
                                    overflowX: "hidden",
                                    borderTop: '1px solid #ccc'
                                }}
                            >
                                <div style={{ marginTop: '2%' }}>
                                    <div style={{ marginTop: '2%' }}>
                                        {/* <div className='new-productivity-allocation-no-data-header'>Welcome! This is where your performance journey begins!</div> */}
                                        <div>
                                            <center>
                                                <img src={RatingImg} alt="Image Not Found" />
                                                <div className='ratingwelcometext pt-sm'>Welcome to OKR - Rating and Review</div>
                                                <div className='ratingwelcomesubtext pt-xs'>
                                                    {"To view OKR of current fiscal year, please select Team Member --> the Month of Performance Period --> the Type of Rating."}
                                                    {/* <span style={{ color: empId === null ? "#DBDBDD" : "#4B4B4B" }}>
                                                        To view OKR
                                                    </span>
                                                    <span style={{ color: empId === null ? "#DBDBDD" : "#4B4B4B" }}> select Team Member
                                                        <span>
                                                            <FontAwesomeIcon icon={faCheck} className="mr-sm ml-xs" style={{ color: empId === null ? "#DBDBDD" : "#2CC277" }} />
                                                        </span>
                                                    </span>
                                                    <span style={{ color: monthOfRating === 0 ? "#DBDBDD" : "#4B4B4B" }}>
                                                        , Month
                                                        <FontAwesomeIcon icon={faCheck} className="mr-sm ml-xs" style={{ color: monthOfRating === 0 ? "#DBDBDD" : "#2CC277" }} />
                                                    </span>
                                                    <span style={{ color: monthOfRating === 0 ? "#DBDBDD" : "#4B4B4B" }}>
                                                        and Type of rating from above
                                                    </span> */}
                                                </div>
                                            </center>
                                        </div>
                                        {/* <div className='new-productivity-allocation-no-data-sub-header pt-lg'>Please select Performance Period for {categoryName === OKR_REVIEW ? " review." : " rating."} </div> */}
                                    </div>
                                </div>
                            </div>
                        </>
                    }
                </div>
            </div>
        )
    }
}
const mapStateToProps = ({ okrratingreducer, auth, meetingcategory }) => {
    const { empId, fyId, monthOfRating, productivityallocationData, refreshRatingDataFlag, ratingBusinessOkr, ratingCentralOkr, ratingLPOkr,
        reviewBusinessOkr, reviewCentralOkr, reviewLPOkr, ratingStatusData, pendingOKRs, ratingStatus, ratingTabs, ratingCategoryStatus } = okrratingreducer;
    const { authUser } = auth;
    const { categoryName } = meetingcategory;
    return {
        empId, fyId, monthOfRating, authUser, productivityallocationData, refreshRatingDataFlag, ratingBusinessOkr, ratingCentralOkr, ratingLPOkr,
        reviewBusinessOkr, reviewCentralOkr, reviewLPOkr, ratingStatusData, categoryName, pendingOKRs, ratingStatus, ratingTabs, ratingCategoryStatus
    }
};
export default connect(mapStateToProps, { addUpdateOkrRatingRedux })(
    RatingreTabs
);


