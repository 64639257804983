 
import * as React from "react"; 
import { MainClass } from "../../../mainclass"; 
import Confirmation from "../../containers/tmlc/confirmation/confirmation";
class ConfirmationLayout extends MainClass {
  render() {
    return (
      <div style={{ height: "100%", width: "100%" }}>
        <Confirmation />
      </div>
    );
  }
}

export default ConfirmationLayout;
