import './default.css';
import * as React from 'react';
import { MainClass } from '../../../mainclass';
import { connect } from 'react-redux';
import Onboarding from '../../containers/tmlc/onboarding/onboarding';
import EmployeeProfile from '../../containers/tmlc/employeeprofile/employeeprofile';
import OnboardingFunctions from '../../containers/tmlc/onboardingfunctions/onboardingfunctions';
import {
    ON_BOARDING, EMPLOYEE_PROFILE, ON_BOARDING_FUNCTION
} from "../../../redux/constant/meetingcategoryconstant";
import { saveBasicInformationInRedux, syncUser } from '../../../redux/actions/entryexit/onboardingaction';
import { Redirect } from "react-router-dom";
import { encrypt, dencrypt, encodeString } from '../../other/commonfunction';
import { syncUserLocal } from "../../../app/other/syncuser";
import queryString from "query-string";
import dataService from "../../../services/services";
import { createHelpDeskUser } from '../../../redux/actions/helpdesk/helpdeskaction'

class OnboardingLayout extends MainClass {
    constructor() {
        super(...arguments);
        this.state = {
            redirect: null,
            isLoading: true,
            moduleId: 0
        }
    }
    async componentDidMount() {
        try {
            let search = window.location.search;
            let params = new URLSearchParams(search);
            const empId = dencrypt(params.get('empId'))
            if (empId > 0) {
                this.setState({
                    emp_id: empId, categoryName: EMPLOYEE_PROFILE
                })
                this.props.saveBasicInformationInRedux({ empid: parseInt(empId) })
            }
            // Set App Id
            this.setModuleId();
        } catch (error) {
        }
    }

    componentWillReceiveProps = async (newProps) => {
        if (newProps.onboardStatus === 'updated' && !isNaN(newProps.empid) && newProps.empid > 0) {
            let emp_id = newProps.empid;
            await this.createHelpDeskUserRequest(emp_id)
            await this.syncUserLocal(emp_id);
            localStorage.setItem('last_meeting_category', localStorage.getItem('meeting_category'))
            localStorage.setItem('meeting_category', encodeString(EMPLOYEE_PROFILE))
            let path = '/onboard?empId=' + encrypt(newProps.empid) + '&id=' + encrypt(EMPLOYEE_PROFILE) + '&sync=true';
            setTimeout(() => {
                this.setState({
                    redirect: path,
                    meetingcategory: EMPLOYEE_PROFILE
                })
            }, 1000);
            this.props.saveBasicInformationInRedux({ onboardStatus: '' })
            setTimeout(() => {
                window.location.reload();
            }, 1500);
        }
    }
    // Sync User for Common access rights
    syncUserLocal = async (emp_id) => {
        const { authUser } = this.props;
        let empData = await syncUserLocal(emp_id, authUser, 0);
        await this.props.syncUser(empData);
    }
    createHelpDeskUserRequest = async (empId) => {
        let empDetail = await dataService.getEnployeeBasicInformation(empId, this.props.authUser);
        let empData = empDetail[0];
        let data = {
            username: empData.FirstName.concat(".", empData.LastName),
            password: empData.helpdeskUserPass,
            email: empData.EmailAddress,
            firstName: empData.FirstName,
            lastName: empData.LastName,
            company: empData.CompanyName,
            department: empData.Department,
            sendWelcomeEmail: false,
            userId: this.props.authUser,
            empId: empId
        };
        this.props.createHelpDeskUser(data);
    }

    setModuleId() {
        try {
            // Set App Id
            const { moduleId } = this.state
            const params = queryString.parse(this.props.location.search);
            if (typeof params.id !== 'undefined' && params.id !== null) {
                if (moduleId !== dencrypt(params.id))
                    this.setState({ moduleId: dencrypt(params.id) })
            }
        } catch (error) {

        }
    }

    componentDidUpdate(newProps) {
        try {
            this.setModuleId()
        } catch (error) {
        }
    }
    render() {
        const { moduleId } = this.state

        if (this.state.redirect !== null) {
            return <Redirect to={this.state.redirect} />
        }

        return (
            <div style={{ height: '100%', width: '100%' }}>
                {
                    moduleId.toString() === ON_BOARDING && <Onboarding />
                }
                {
                    moduleId.toString() === EMPLOYEE_PROFILE && this.props.empid > 0 && <EmployeeProfile />
                }
                {
                    moduleId.toString() === ON_BOARDING_FUNCTION && <OnboardingFunctions />
                }
            </div>
        );
    }

}

const mapStateToProps = ({ auth, meetingcategory, onboardingreducer }) => {
    const { authUser } = auth;
    const { categoryName } = meetingcategory;
    const { empid, onboardStatus } = onboardingreducer;
    return { authUser, categoryName, empid, onboardStatus }
};
export default connect(mapStateToProps, { saveBasicInformationInRedux, syncUser, createHelpDeskUser })(OnboardingLayout);



