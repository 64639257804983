/**
 * Author: Keval Charla.
 * Created:11-July-2022
 * @description to view all list of holidays for user
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import DateFnsUtils from '@date-io/date-fns';
import HolidayCalendar from '../../../components/holidaylist/holidaycalendar';
import HolidayList from '../../../components/holidaylist/holidaylist';
import HolidayListYearView from '../../../components/holidaylist/holidaylistyearview';
import LnAServices from '../../../services/lna/lnaservice';
import dataService from '../../../services/services';
import { applyLeave, saveLeaveCancelResponse, updateLnAData } from '../../../redux/actions/lna/lnaaction';
import { BREModuleActions } from '../../../assets/constants/constants';
import BREServices from "../../../services/breservice";
import { updateMeetingBookingState } from "../../../redux/actions/meetingbookingaction";
import { PLAN_VS_ACTUAL_MEETING } from "../../../redux/constant/meetingcategoryconstant";
import { encrypt, formatDateToDDMMMYYYY } from "../../other/commonfunction";
import NotificationContainer from "../../../components/notification/notifycontainer";
import NewConfirmDialog from "../../../components/confirmdialog/newconfirmdialog";
import "../../../components/holidaylist/holidaylist.css";
import lnaservice from '../../../services/lna/lnaservice';

class HolidaylistContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      holidayListData: [],
      selectedCountry: props.holidayCountry,
      selectedItem: null,
      viewFor: "",
      selectedMonthYear: "0",
      filterHolidayListData: [],
      isFilterApplied: false,
      disableFilter: true,
      selectedYear: null,
      monthViewFilterList: [
        { text: 'Fixed Holiday', id: '2', isChecked: true },
        { text: 'Availed Festival Holiday', id: '3', isChecked: true },
        { text: 'Allocated Festival Holiday', id: '4', isChecked: false },
        { text: 'Working Saturday', id: '5', isChecked: false },
      ],
      yearViewFilterList: [
        { text: 'Fixed Holiday', id: '2', isChecked: true },
        { text: 'Availed Festival Holiday', id: '3', isChecked: true },
        { text: 'Allocated Festival Holiday', id: '4', isChecked: false },
        { text: 'Working Saturday', id: '5', isChecked: false },
      ],
      filterData: [],
      switchView: "year",
      minDate: "2015-04-01",
      maxDate: "2025-03-31",
      loader: false,
      holidayRulesData: "",
      height: null,
      applyHoliday: false,
      currentHoliday: [],
      previousHoliday: [],
      isLoading: false,
      holidayCalendarKey: 0
    };

    this.switchComponent = null;
  }

  /**
 * Author: Keval Charla  *
 * @param {*} type
 * @param {*} authUser
 * @param {*} selectedCountry
 * @param {*} year
 * @param {*} monthYear
 * @description get the holiday list data
 */

  async componentDidMount() {
    const { authUser } = this.props
    const { selectedCountry } = this.state;
    const filterArr = ["Fixed Holiday", "Availed Festival Holiday"];

    const currentYear = new Date();
    const month = currentYear.getMonth();
    let year;

    if (month === 0 || month === 1 || month === 2) {
      year = currentYear.getFullYear() - 1;
    } else {
      year = currentYear.getFullYear();
    }

    this.setState({ selectedYear: year });
    const holidayListMaster = await LnAServices.getHolidayNWorkingSatDetails("allList", authUser, selectedCountry, year, 0);
    if (holidayListMaster && holidayListMaster.Result.length > 0) {
      this.setState({
        holidayListData: holidayListMaster.Result,
        filterHolidayListData: holidayListMaster.Result.filter(holiday => filterArr.includes(holiday.holidayType)),
        isFilterApplied: true
      });
    }

    if (holidayListMaster && holidayListMaster.Result.length === 0) {
      this.setState({
        holidayListData: [],
        filterHolidayListData: [],
        isFilterApplied: false
      });
    }

    this.fetchHolidayTimelineDates();
    this.fetchHolidayRulesData();

    window.addEventListener("resize", this.updateDimensions()); // NOSONAR
  }

  componentDidUpdate(prevProps, prevState) {
    if ((prevState.selectedCountry !== this.state.selectedCountry) || (prevState.selectedYear !== this.state.selectedYear)) {
      this.fetchHolidayRulesData();
    }
    const { backgroundSyncId, leaveApplyStatus, authUser, leaveCancelStatus } = this.props;
    if (backgroundSyncId > 0 && (leaveApplyStatus === "updated" || leaveCancelStatus === "updated")) {
      const leaveData = {
        userId: authUser,
        actionType: leaveCancelStatus === "updated" ? 'cancel-leave' : 'leave-apply',
        optionalId: backgroundSyncId.toString()
      }
      this.props.updateLnAData({ leaveApplyStatus: "", leaveCancelStatus: "", backgroundSyncId: 0 })
      LnAServices.syncLeavedetails(leaveData)
    }
  }

  updateDimensions = () => {
    if (window.innerHeight < 650) {
      const updateheight = window.innerHeight - 25;
      this.setState({
        height: updateheight,
      });
    } else {
      const updateheight = window.innerHeight - 30;
      this.setState({
        height: updateheight,
      });
    }
  }

  /**
   * Author: Keval Charla  *
   * @param {*} type
   * @description get holiday rules data
  */

  async fetchHolidayRulesData() {
    const { authUser } = this.props
    const { selectedCountry, selectedYear } = this.state;

    const rules = await LnAServices.getHolidayNWorkingSatDetails("holidayRules", authUser, selectedCountry, selectedYear);

    if (rules && rules.Result[0]) {
      this.setState({ holidayRulesData: rules.Result[0].holidayRules });
    } else {
      this.setState({ holidayRulesData: '' });
    }
  }

  /**
   * @author Keval Charla
   * @argument data
   * @description update fetch data in local state "holidayListData"
   */

  getViewData = (data, type = "", monthYearDate = "") => {
    const filterArr = [];

    if (type === "month") {
      let monthFilterList = this.state.monthViewFilterList;

      monthFilterList.forEach(filter => {
        if (filter.isChecked) {
          filterArr.push(filter.text);
        }
      })
    }

    if (type === "year") {
      let yearFilterList = this.state.yearViewFilterList;

      yearFilterList.forEach(yearData => {
        if (yearData.isChecked) {
          filterArr.push(yearData.text);
        }
      })
    }

    if (data && data.Result && data.Result.length > 0) {
      this.setState({
        holidayListData: data.Result,
        filterHolidayListData: monthYearDate
          ? data.Result.filter(holiday => holiday.holidayDate >= monthYearDate && filterArr.includes(holiday.holidayType))
          : data.Result.filter(holiday => filterArr.includes(holiday.holidayType)),
        isFilterApplied: true,
      });
    } else if (data === null && monthYearDate && type === "month") {
      this.setState({
        filterHolidayListData: this.state.holidayListData.filter(holiday => holiday.holidayDate >= monthYearDate && filterArr.includes(holiday.holidayType)),
        isFilterApplied: true
      });
    } else {
      this.setState({
        holidayListData: [],
        filterHolidayListData: [],
        isFilterApplied: false
      });
    }

    if (data && data.Result && data.Result.length === 0) {
      this.setState({
        holidayListData: [],
        filterHolidayListData: [],
        isFilterApplied: false
      });
    }
  }


  /**
   * @author Keval Charla
   * @description get min date and max date to Show Holiday From
   */

  fetchHolidayTimelineDates = async () => {
    const { authUser } = this.props

    const holidayDateTimeline = await (dataService.getMasterData(authUser, "ShowHolidayFrom"));

    if (holidayDateTimeline && holidayDateTimeline.length > 0) {
      setTimeout(() => {
        this.setState({
          minDate: holidayDateTimeline[0].minDate,
          maxDate: holidayDateTimeline[0].maxDate
        });
      }, 1000);
    }
  }

  fetchCAlendarDataInContainer = (fetchCalendarData) => {
    fetchCalendarData && fetchCalendarData();
  }

  handleLoader = (type) => {
    if (type === "true") {
      this.setState({ loader: true });
    }
    if (type === "false") {
      this.setState({ loader: false });
    }
  }

  /**
   * @author Keval Charla
   * @description date change in month view
   */

  handleCountryChange = (countryId) => {
    this.setState({ selectedCountry: countryId, viewFor: "monthView", disableFilter: true });
  }

  /**
   * @author Keval Charla
   * @description date change in year view
   */

  handleYearViewCountryChange = (countryId) => {
    this.setState({ selectedCountry: countryId, viewFor: "yearView" });
  }

  /**
   * @author Keval Charla
   * @argument item
   * @description set selected item id in selectedItem.
   */

  handleSelectedItem = (item) => {
    this.setState({ selectedItem: item });
  }

  handleSelectedMonthYear = (monthYear) => {
    this.setState({
      selectedMonthYear: monthYear
    })
  }

  /**
   * @author Keval Charla
   * @argument filterList
   * @argument type - Optional
   * @description filter holiday list data.
   */

  handleHolidayFilter = (filterList, type = "") => { // NOSONAR
    this.setState({ filterData: filterList, loader: true });
    const filterArr = [];

    if (type === "clear") {
      filterList.forEach(ele => {
        ele.isChecked = false;
      });
    } else if (type === "defaultState") {
      filterList.forEach(ele => {
        if (ele.text === "Fixed Holiday" || ele.text === "Availed Festival Holiday") {
          ele.isChecked = true;
        } else {
          ele.isChecked = false;
        }
      });
    } else if (type === "availedAndAllocated") {
      filterList.forEach(ele => {
        if (ele.text === "Allocated Festival Holiday" || ele.text === "Availed Festival Holiday") {
          ele.isChecked = true;
          filterArr.push(ele.text);
        } else {
          ele.isChecked = false;
        }
      });
    } else if (type === "fixedAndAvailed") {
      filterList.forEach(ele => {
        if (ele.text === "Fixed Holiday" || ele.text === "Availed Festival Holiday") {
          ele.isChecked = true;
          filterArr.push(ele.text);
        } else {
          ele.isChecked = false;
        }
      });
    } else {
      filterList.forEach(ele => {
        filterArr.push(ele.text);
      });
    }

    this.setState({
      filterHolidayListData: filterArr.length === 0 ? this.state.holidayListData : this.state.holidayListData.filter(holiday => filterArr.includes(holiday.holidayType)),
      isFilterApplied: true,
    })

    setTimeout(() => {
      this.setState({ loader: false });
    }, 1000);
  }

  handleSelectedYear = (year) => {
    this.setState({
      selectedYear: year,
    })
  }

  /**
   * @author Keval Charla
   * @argument type - "yes" or "no"
   * @description disable holiday list filter ui color
   */

  handleDisableFilter = (type) => {
    if (type === "yes") {
      this.setState({ disableFilter: false });
    }
    if (type === "no") {
      this.setState({ disableFilter: true });
    }
  }

  /**
   * @author Keval Charla
   * @argument type - "year" or "month"
   * @description navigate from year view to month view and vice versa
   */

  handleSwitchView = (type) => {
    if (type === "year") {
      this.setState({ switchView: "year" });
    }

    if (type === "month") {
      this.setState({ switchView: "month" });
    }
  }

  onChangeSwitch = () => { // NOSONAR
    if (this.switchComponent.checked) {
      this.setState({ switchView: "year" });
    } else {
      this.setState({ switchView: "month" });
    }
  }

  /**
   * @author Keval Charla
   * @description navigate from ec calendar
   */

  handleBackToCalendar = () => {
    if (this.props.history) {
      this.props.history.push(`/default?id=${encrypt(PLAN_VS_ACTUAL_MEETING)}&appId=${encrypt(1)}`);
    }
  }

  handleMonthlyFilterData = (filterList) => {
    this.setState(prevState => ({ ...prevState, monthViewFilterList: filterList }));
  }

  handleYearlyFilterData = (filterList) => {
    this.setState({ yearViewFilterList: filterList });
  }


  /**
   * @author Chandrashekhar Bhagat
   * @description For filtering year and month wise data.
   */
  handleFilterCheckBox = (filterItem, type) => {
    let filterListName;
    switch (type) {
      case 'yearly':
        filterListName = 'yearViewFilterList';
        break;
      case 'monthly':
        filterListName = 'monthViewFilterList';
        break;
      default:
        return;
    }
    const filterList = this.state[filterListName];
    filterList.forEach(ele => {
      if (ele.id === filterItem.id) {
        ele.isChecked = filterItem.isChecked;
      }
    });
    this.setState({ [filterListName]: filterList });
  }

  refreshMonthViewCalendar = () => {
    this.clickChild && this.clickChild();
  }

  /**
   * @author Keval Charla
   * @description fetch again holiday list data
   */

  refreshHolidayList = async () => {
    this.setState({ isLoading: true, loader: true });
    const holidayListMaster = await LnAServices.getHolidayNWorkingSatDetails("allList", this.props.authUser, this.state.selectedCountry, 0, this.state.selectedMonthYear);
    if (holidayListMaster?.Result?.length > 0) {
      setTimeout(() => {
        this.getViewData(holidayListMaster, "month");
      }, 2500);
      setTimeout(() => {
        document?.getElementById("holidayListCalendar")?.ej2_instances[0]?.refreshEvents();
        this.refreshMonthViewCalendar();
      }, 2800);
      setTimeout(() => {
        this.setState({ isLoading: false, loader: false });
      }, 3000);
    }
  }

  /**
   * @author Keval Charla
   * @description close popup
   */

  handleConfirmClose = () => {
    this.setState({ applyHoliday: false })
  }

  /**
   * @author Keval Charla
   * @description open popup
   */

  handleApply = (currentHoliday, previousHoliday) => {
    this.setState({
      applyHoliday: true,
      currentHoliday,
      previousHoliday,
    })
  }

  /**
   * @author Keval Charla
   * @description on click of apply this function will fire
   */
  handleHolidayApply = () => {
    try {
      const prevHolidayArr = this.state.previousHoliday.map((prevHoliday) => {
        return prevHoliday.id
      });

      const holidayArr = this.state.currentHoliday.map((holiday) => {
        return holiday.id
      });

      const newApplyHoliday = [];
      let sendRequest = 0;
      let counter = 1;

      this.state.previousHoliday.forEach((holiday, idx) => {
        if (!holidayArr.includes(holiday.id)) {
          sendRequest++;
        }
      });

      this.state.currentHoliday.forEach((holiday, idx) => {
        if (!prevHolidayArr.includes(holiday.id)) {
          sendRequest++;
        }
      });

      if (sendRequest > 0) {
        this.setState({ isLoading: true, loader: true });
      }

      this.state.previousHoliday.forEach((holiday) => {
        if (!holidayArr.includes(holiday.id)) {

          holiday.cancelNotification = (counter === sendRequest);
          counter++;
          setTimeout(() => {
            this.cancelFestivalHolidayLeave(holiday, holiday.cancelNotification);
          }, counter * 1500);
        }

      });

      this.state.currentHoliday.forEach((holiday) => {
        newApplyHoliday.push(holiday);
        if (!prevHolidayArr.includes(holiday.id)) {
          holiday.currentNotification = (counter === sendRequest);
          counter++;
          setTimeout(() => {
            this.applyFestivalHolidayLeave(holiday, holiday.currentNotification);
            document.getElementById("holidayListCalendar").ej2_instances[0].refreshEvents();
          }, counter * 1500)

        }
      });
      this.setState({ previousHoliday: newApplyHoliday, applyHoliday: false });

      setTimeout(() => {
        counter++;
        this.refreshHolidayList();
      }, counter * 2600);

      setTimeout(() => {
        counter++;
        document.getElementById("holidayListCalendar").ej2_instances[0].refreshEvents();
      }, counter * 2800);

      setTimeout(() => {
        this.setState({ isLoading: true, loader: true });
      }, counter * 2600);

    } catch (error) {
      console.log("🚀 ~ file: holidaylistcontainer.jsx:516 ~ HolidaylistContainer ~ error:", error)

    }

  }


  /**
   * @author Keval Charla
   * @description apply festival holiday request
   */

  applyFestivalHolidayLeave = async (holiday, sendNotification = null) => {
    const { holidayDate, notifyToIds, leaveClusterId, relieverId } = holiday;
    const { authUser, currentFinancialYearId, leaveApplicationId, applyLeave } = this.props; //NOSONAR

    const dayBreakDate = formatDateToDDMMMYYYY(holiday.holidayDate)
    const getBREData = await this.getBREDataForLeave(leaveClusterId);

    let payload = {
      userId: authUser,
      startDate: holidayDate,
      endDate: holidayDate,
      totalNoOfDaysLeaveApplied: 1,
      notifyUsersList: notifyToIds ? notifyToIds : "",
      attachmentName: "",
      reason: "Festival holiday apply",
      leaveCancelReason: '',
      relieverId: relieverId ? relieverId : "",
      leaveStatus: '2',
      approverId: 0,
      leaveApplicationId: leaveApplicationId,
      leaveClusterId: leaveClusterId,
      dateDayBreak: `${dayBreakDate}_4_1`,
      fyId: currentFinancialYearId,
      applyFor: authUser,
      supervisorApprovalRequired: getBREData.enum_supervisor_approval,
      hrApprovalRequired: getBREData.enum_HR_approval,
      autoApprovalRequired: getBREData.enum_auto_approval,
      noOfHrsToAutoApprove: getBREData.int_no_hrs_to_approval,
      sendNotification: sendNotification
    }

    applyLeave(payload);
  }

  /**
   * @author Keval Charla
   * @description cancel festival holiday request
   */

  cancelFestivalHolidayLeave = (holiday, sendNotification = null) => {
    const { leaveApplicationId, holidayDate } = holiday;
    const { authUser } = this.props;
    let date = [holidayDate + '_4_1']
    let leaveCancelSaveData = {
      userId: authUser,
      type: 'EDIT',
      leaveApplicationId: leaveApplicationId,
      cancelReason: "Cancel Festival Holiday",
      leaveCancelDate: date.toString(),
      sendNotification: sendNotification,
    }
    this.props.saveLeaveCancelResponse(leaveCancelSaveData)
  }

  /**
   * @author Keval Charla
   * @description get bre data
   */

  getBREDataForLeave = async (pSouceLeaveId) => {
    const { authUser } = this.props;
    const objData = { "int_leave_id": pSouceLeaveId }
    return BREServices.getBREData(authUser, BREModuleActions.leaveApplication, objData);
  }

  render() {
    const { holidayListData, selectedItem, filterHolidayListData, isFilterApplied, disableFilter, filterData, switchView, selectedCountry, monthViewFilterList,
      yearViewFilterList, minDate, maxDate, loader, holidayRulesData, height, applyHoliday, selectedYear, selectedMonthYear, isLoading } = this.state;
    return (
      <>
        <div className="holiday-switch-container" >
          <div className="holiday-text holiday-month-text" style={{ paddingRight: 0 }} onClick={() => this.handleSwitchView("month")}>
            <button style={{ color: switchView === "month" ? "#F95F95" : "" }}>MONTH</button>
          </div>
          <div className="holiday-text holiday-year-text" onClick={() => this.handleSwitchView("year")}>
            <button style={{ color: switchView === "year" ? "#F95F95" : "" }}>YEAR</button>
          </div>
          <div className="holiday-text holiday-calendar-text" onClick={this.handleBackToCalendar}>
            <button>CALENDAR</button>
          </div>
        </div>
        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 pl-md pr-md" style={{ height: height }}> {/** pl-lg pr-lg pt-md */}
          {switchView === "month" ?
            <>
              <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12" style={{ backgroundColor: "#FFFFFF", border: "1px solid #DBDBDD" }}> {/** height: "100vh" */}
                <NotificationContainer />
                <div className="col-xs-8 col-sm-8 col-lg-8 col-md-8 pt-md pb-md" key={this.state.holidayCalendarKey}> {/** style={{ paddingLeft: 0 }} */}
                  <HolidayCalendar
                    holidayListData={isFilterApplied ? filterHolidayListData : holidayListData /** NOSONAR */}
                    setClick={click => this.clickChild = click}
                    selectedItem={selectedItem}
                    handleCountryChange={this.handleCountryChange}
                    handleSelectedMonthYear={this.handleSelectedMonthYear}
                    getViewData={this.getViewData}
                    authUser={this.props.authUser}
                    handleDisableFilter={this.handleDisableFilter}
                    handleHolidayFilter={this.handleHolidayFilter}
                    filterData={filterData}
                    handleMonthlyFilterData={this.handleMonthlyFilterData}
                    selectedCountry={selectedCountry}
                    minDate={minDate}
                    maxDate={maxDate}
                    handleLoader={this.handleLoader}
                    loader={loader}
                    handleSelectedYear={this.handleSelectedYear}
                    handleFilterCheckBox={this.handleFilterCheckBox}
                    holidayCalendarKey={this.state.holidayCalendarKey}
                    refreshHolidayList={this.refreshHolidayList}
                  />
                </div>
                <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4 pt-md pb-md" style={{ paddingLeft: 0 }}>
                  <HolidayList
                    holidayListData={isFilterApplied ? filterHolidayListData : holidayListData /** NOSONAR */}
                    refreshMonthViewCalendar={this.refreshMonthViewCalendar}
                    selectedItem={selectedItem}
                    handleSelectedItem={this.handleSelectedItem}
                    handleHolidayFilter={this.handleHolidayFilter}
                    handleDisableFilter={this.handleDisableFilter}
                    disableFilter={disableFilter}
                    handleMonthlyFilterData={this.handleMonthlyFilterData}
                    monthViewFilterList={monthViewFilterList}
                    handleLoader={this.handleLoader}
                    loader={loader}
                    holidayRulesData={holidayRulesData}
                    handleFilterCheckBox={this.handleFilterCheckBox}
                    refreshHolidayList={this.refreshHolidayList}
                    handleApply={this.handleApply}
                    isLoading={isLoading}
                    selectedMonthYear={selectedMonthYear}
                    selectedYear={selectedYear}
                    selectedCountry={selectedCountry}
                    handleConfirmClose={this.handleConfirmClose}
                    ogHolidayListData={holidayListData}
                  // activateApplyButton={activateApplyButton}
                  />
                </div>
              </div>
            </>
            :
            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12" style={{ backgroundColor: "#FFFFFF", border: "1px solid #DBDBDD" }}>
              <HolidayListYearView
                holidayListData={isFilterApplied ? filterHolidayListData : holidayListData /** NOSONAR */}
                handleCountryChange={this.handleYearViewCountryChange}
                handleSelectedYear={this.handleSelectedYear}
                selectedYear={selectedYear}
                handleHolidayFilter={this.handleHolidayFilter}
                getViewData={this.getViewData}
                authUser={this.props.authUser}
                selectedCountry={selectedCountry}
                yearViewFilterList={yearViewFilterList}
                handleYearlyFilterData={this.handleYearlyFilterData}
                minDate={minDate}
                maxDate={maxDate}
                holidayRulesData={holidayRulesData}
                handleFilterCheckBox={this.handleFilterCheckBox}
                refreshHolidayList={this.refreshHolidayList}
              />
            </div>
          }
        </div>
        <NewConfirmDialog
          open={applyHoliday}
          handleConfirmClose={this.handleConfirmClose}
          title={"Festival Holiday"}
          message={"Are you sure you want to save changes to festival holiday?"}
          handleConfirmYes={() => this.handleHolidayApply()}
          button1Caption={"Apply"}
          handleConfirmNo={this.handleConfirmClose}
          button2Caption={"Cancel"}
        />
      </>
    )
  }

}

const mapStateToProps = ({ auth, meetingbooking, usersettingreducer, meetingmaster, lnareducer }) => {
  const { authUser } = auth;
  const { switchView } = meetingbooking;
  const { holidayCountry } = usersettingreducer;
  const { notificationTo, leaveReason, reliever, leaveApplicationId, leaveApplyStatus, leaveCancelStatus, backgroundSyncId } = lnareducer;
  const { currentFinancialYearId } = meetingmaster;
  return {
    authUser,
    switchView,
    holidayCountry,
    notificationTo,
    leaveReason,
    reliever,
    leaveApplicationId,
    currentFinancialYearId,
    leaveApplyStatus,
    leaveCancelStatus, backgroundSyncId
  };
};
export default connect(mapStateToProps, {
  updateMeetingBookingState,
  applyLeave,
  saveLeaveCancelResponse,
  updateLnAData
})(HolidaylistContainer);
