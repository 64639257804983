/**
* Author Name : Vinayak Jadhav.
* Created Date: - 03-08-2021
*/

import * as React from "react";
import { connect } from "react-redux";
import  NotificationContainer  from "../../../components/notification/notifycontainer";
import { ColumnsDirective, ColumnDirective, Inject, Page } from "@syncfusion/ej2-react-treegrid";
import { getObject } from "@syncfusion/ej2-grids";
import adminServices from "../../../services/adminservices";
import { Dialog } from "@material-ui/core";
import { DialogContent, DialogActions, DialogTitle } from "../../../components/customdialog/customdialog";
import { GridComponent, Filter, VirtualScroll, Sort } from "@syncfusion/ej2-react-grids";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { ButtonComponent, CheckBoxComponent, RadioButtonComponent } from "@syncfusion/ej2-react-buttons";
import { saveRoleModuleInfoRedux, addEditModuleDetails } from "../../../redux/actions/admin/adminaction";
import { appId } from "../../../config/config";
import { getNotify } from "../../../app/other/commonfunction";
import { ERROR } from "../../../assets/constants/constants";
import { ComboBoxComponent } from "@syncfusion/ej2-react-dropdowns";
import ProgressBar from "../../../components/progressbar/progressbar";

const SAMPLECSS = `
.grid-left-header-admin{
  border: 0 none;
  display: block;
  font-size: 15px;
  font-weight: 500;
  line-height: 40px !important;
  height: 40px !important;
  margin-left: -1px !important;
  overflow: hidden;
  padding: 0 0.6em !important;
  text-align: left !important;
  -webkit-user-select: none;
  user-select: none;
  width: inherit !important;
  word-wrap: break-word !important;
}
.grid-center-header-admin{
  border: 0 none;
  display: block;
  font-size: 15px;
  font-weight: 500;
  line-height: 40px !important;
  height: 40px !important;
  overflow: hidden;
  padding: 0 0.6em !important;
  -webkit-user-select: none;
  user-select: none;
  text-align: 'center'!important;
  width: inherit !important;
  word-wrap: break-word !important;
}
.grid-left-header-popup{
  border: 0 none;
  display: block;
  font-size: 15px;
  font-weight: 500;
  line-height: 30px;
  height: 30px !important;
  margin-left: -1px !important;
  overflow: hidden;
  padding: 0 0.6em !important;
  text-align: left !important;
  -webkit-user-select: none;
  user-select: none;
  width: inherit !important;
  word-wrap: break-word !important;
}
.grid-center-header-popup{
  border: 0 none;
  display: block;
  font-size: 15px;
  font-weight: 500;
  height: 22px !important;
  line-height: 30px;
  overflow: hidden;
  padding: 0 0.6em !important;
  -webkit-user-select: none;
  user-select: none;
  text-align: 'center'!important;
  width: inherit !important;
  word-wrap: break-word !important;
}
.e-grid .e-rowcell{
  border-style: solid;
  border-width: 1px 0 0 0;
  display: table-cell;
  font-size: 14px !important;
  line-height: 18px !important;
  overflow: hidden;
  padding: 5px 0px 5px 8px !important;
  vertical-align: middle;
  /* white-space: pre-wrap !important; */
  width: auto;
  // text-transform: capitalize;
}
`;

class ModuleMaster extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      moduleData: [],
      moduleDataForParentCombo: [],
      thisModuleData: [],
      moduleFormPopup: false,
      moduleMapPopupTitle: 'Edit',
      parentModuleEnable: true,
      menuIconFlag: false,
      menuOrderFlag: false,
      height: (window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight) - 290,
      gridHeight: (window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight) - 290,
    }
    this.onCommandClick = this.onCommandClick.bind(this);
    this.actionCreateModule = this.actionCreateModule.bind(this);
    this.actionUpdateModule = this.actionUpdateModule.bind(this);
    this.cellSelection = {
      cellSelectionMode: "Box",
      mode: "Cell",
      CheckboxSelectionType: "Default"
      // type: 'Multiple'
    };
    this.width =
      (window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth) - 80;
    this.customAttributes = { class: "customcss-sat-sun" };
    this.customAttributesAVG = { class: "customcss-avg" };
    this.customAttributesELPL = { class: "customcss-EL-PL" };
    this.customAttributesAbsent = { class: "customcss-absent" };
    this.commands = [
      {
        type: "Edit",
        buttonOption: { iconCss: "e-icons e-edit", className: "e-flat" },
      },
    ];
    this.moduleTypes = [{Id: 1, Name: "Navigation"}, {Id: 2, Name: "Attribute"}]
    this.isRootChangedTo = undefined;
  }
  componentDidMount = async () => {
    this.setState({ loader : true});
    var masterModules = await adminServices.getMasterModules();
    
    setTimeout(() => {
      this.setState({ moduleData : masterModules.data, moduleDataForParentCombo: masterModules.data, loader : false});
      const ele = document.getElementsByClassName("e-headercelldiv");
      for(let i in ele) {
        if(!isNaN(parseInt(i, 10))){
          let index = parseInt(i, 10);
          
          if (ele[index].innerText === "Module Type" || ele[index].innerText === "Menu Order" || ele[index].innerText === "Constant Id" || ele[index].innerText === "Action") {
            ele[index].classList.add("grid-center-header-admin");
          } else {
            ele[index].classList.add("grid-left-header-admin");
          }
        }
      }
    }, 2000);
  };

  async UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.action_status === "updated") {
      this.closeModuleFormPopup();
      var masterModules = await adminServices.getMasterModules();
      this.setState({ moduleData : masterModules.data, moduleDataForParentCombo: masterModules.data}, () => this.props.saveRoleModuleInfoRedux({ action_status : "" }));
    }
  }
  /**
   * rowDataBound is for to apply css of tree grid view.
   */
   rowDataBound = (args) => {
    const { selectedRows, thisRoleModules, moduleMapPopupTitle } = this.state;
    if(moduleMapPopupTitle === 'Edit'){
      var selectedRowsArr = selectedRows;
      if(thisRoleModules.module_access.findIndex(ech => ech.module_id === getObject('_id', args.data)) > -1){
        selectedRowsArr.push(parseInt(args.row.getAttribute('aria-rowindex'),0));
        this.setState({selectedRows: selectedRowsArr});
      }    
    }
  };
  isNumeric = (value) => {
    return /^-{0,1}\d+$/.test(value);
  };
  beforeDataBound(args) {
    this.grid.resizeSettings.mode = "Auto";
  }
  load() {
    let cols = this.columns;
    let width = 0;
    width = cols.reduce(function (prev, cur) { return prev + cur.width; }, 0);
    if (width < 1830) width = width + 21; // setting total columns width to Tree Grid
    else width = 1830;

    if (width < this.width) {
      this.width = width;
    }
  }
  closeModuleFormPopup() {
    this.resetReducer();
    this.setState({moduleFormPopup: false, thisModuleData: []});
  }
  openModuleFormPopup() {
    this.setState({moduleFormPopup: true});
  }
  async onCommandClick(args) {
    var masterModules = await adminServices.getMasterModules();
    var selectedModuleId = args.rowData._id;
    var moduleDetails = await masterModules.data.find(module => module._id === selectedModuleId);
    let moduleDataMod = masterModules.data;
    moduleDataMod = moduleDataMod.filter(md => md._id !== selectedModuleId);
    this.props.saveRoleModuleInfoRedux({
      module_id: moduleDetails._id,
      module_name: moduleDetails.module_name,
      module_description: moduleDetails.module_description,
      module_status: moduleDetails.module_status,
      module_type: moduleDetails.type,
      module_is_root: moduleDetails.parent_module_id === 0 ? 1 : 0,
      module_parent_id: moduleDetails.parent_module_id,
      module_icon: moduleDetails.var_icon_css,
      module_route: moduleDetails.var_route,
      module_menu_order: moduleDetails.menu_order_id,
      constant_id: moduleDetails.constant_id,
    });
    this.setState({
        moduleFormPopup: true,
        moduleMapPopupTitle: 'Edit',
        thisModuleData: moduleDetails,
        parentModuleEnable : moduleDetails.parent_module_id === 0 ? false : true,
        menuIconFlag : moduleDetails.parent_module_id === 0 ? true : false,
        menuOrderFlag : moduleDetails.parent_module_id === 0 ? true : false,
        moduleDataForParentCombo: moduleDataMod,
    });
  }
  resetReducer(){
    this.props.saveRoleModuleInfoRedux({
        module_id: "",
        module_name: "",
        module_description: "",
        module_status: "",
        module_type: "",
        module_is_root: "",
        module_parent_id: "",
        module_icon: "",
        module_route: "",
        module_menu_order: "",
        constant_id:""
    });
  }
  actionUpdateModule(){ // NOSONAR
    const { authUser, module_id, module_name, module_description, module_status, module_type, module_is_root, module_parent_id, 
    module_icon, module_route, module_menu_order, constant_id } = this.props;
    var data = {};
    data.app_id = appId;
    data.user_id = authUser;
    data.entity = 'module';
    data.entity_id = module_id;
    data.entity_name = module_name;
    data.entity_description = module_description;
    data.entity_status = module_id === '' ? 1: module_status;
    data.entity_type = module_type;
    data.entity_is_root = module_is_root === '' ? 0 : module_is_root;
    data.entity_parent_id = module_parent_id === 0 ? '' : module_parent_id;
    data.entity_icon = module_icon;
    data.entity_route = module_route;
    data.entity_order = module_menu_order;
    data.entity_constant_id = parseInt(constant_id, 10);

    

    if(module_name === ''){
      
      getNotify(ERROR, "Please enter module name.");
    }
    else if(module_description === ''){
      
      getNotify(ERROR, "Please enter module description.");
    }
    else if(module_type === ''){
      
      getNotify(ERROR, "Please enter module type.");
    }
    else if(module_is_root === 1 && module_type === 'attribute'){
     
      getNotify(ERROR, "Attribute cannot be a root module.");
    }
    else if(module_is_root === 0 && module_parent_id === ''){
      
      getNotify(ERROR, "Please select parent module.");
    }
    else if(module_is_root === 1 && module_icon === ''){
   
      getNotify(ERROR, "Please select menu icon.");
    }
    else if(module_is_root === 1 && (module_menu_order === '' || module_menu_order === 0)){
      
      getNotify(ERROR, "Please select menu order.");
    }
    else if(constant_id === ""){

      getNotify(ERROR, "Please enter constant id.");
    }
    else if(constant_id !== "" && !this.isNumeric(constant_id)){
      
      getNotify(ERROR, "Constant id should be numeric.");
    }
    else {
      this.props.addEditModuleDetails(data);
    }
  }
  async actionCreateModule(){
    this.resetReducer();
    var masterModules = await adminServices.getMasterModules();
    this.setState({moduleFormPopup: true, moduleDataForParentCombo : masterModules.data, moduleMapPopupTitle: 'Create'});
  }
  onModuleParamChange(e, type){
    const { thisModuleData } = this.state;
    switch (type) {
      case "name":
        this.props.saveRoleModuleInfoRedux({module_name: e.value});
        document.getElementById('moduleNameError').innerHTML = "";
        break;
      case "desc":
        this.props.saveRoleModuleInfoRedux({module_description: e.value});
        document.getElementById('moduleDescriptionError').innerHTML = "";
        break;
      case "route":
        this.props.saveRoleModuleInfoRedux({module_route: e.value});
        document.getElementById('moduleRouteError').innerHTML = "";
        break;
      case "isroot":
        var isRootVal;
        if(e.checked){
          this.isRootChangedTo = false;
          isRootVal = 1;
          var thisModuleDataArr = thisModuleData;
          thisModuleDataArr.parent_module_id = "";
          this.props.saveRoleModuleInfoRedux({module_is_root: isRootVal, module_parent_id : ""});
          this.setState({thisModuleData : thisModuleDataArr, parentModuleEnable: false, menuIconFlag: true, menuOrderFlag: true});
        }
        else {
          this.isRootChangedTo = true;
          isRootVal = 0;
          this.props.saveRoleModuleInfoRedux({module_is_root: isRootVal});
          this.setState({parentModuleEnable: true, menuIconFlag: false, menuOrderFlag: false});
        }
        document.getElementById('isRootModuleErr').innerHTML = "";
        break;
      case "modtype":
        if(e.itemData.Name.toLowerCase() === 'attribute'){
          if(document.getElementById('isRootModule').checked){
            document.getElementById("isRootModule").checked = false;
          }
        }
        this.props.saveRoleModuleInfoRedux({module_type: e.itemData.Name.toLowerCase()});
        document.getElementById('moduleTypeErr').innerHTML = "";
        break;
      case "parentmod":
        this.props.saveRoleModuleInfoRedux({module_parent_id: e.value});
        document.getElementById('parentModuleErr').innerHTML = "";
        break;    
      case "icon":
        this.props.saveRoleModuleInfoRedux({module_icon: e.value});
        document.getElementById('moduleIconError').innerHTML = "";
        break;
      case "order":
        this.props.saveRoleModuleInfoRedux({module_menu_order: e.value});
        document.getElementById('moduleIconError').innerHTML = "";
        break;
      case "constant":
        this.props.saveRoleModuleInfoRedux({constant_id: e.value});
        document.getElementById('constantIdError').innerHTML = "";
        break;
      default:
    }

  }
  setFields = async (e) => {
    if (e.target.id === "activeRadio") {
      this.props.saveRoleModuleInfoRedux({module_status: 1});
    } else if (e.target.id === "inactiveRadio") {
      this.props.saveRoleModuleInfoRedux({module_status: 0});
    }
  }
  render() {
    const { moduleData, moduleDataForParentCombo, moduleFormPopup, moduleMapPopupTitle, gridHeight, thisModuleData, parentModuleEnable, menuIconFlag, menuOrderFlag } = this.state;
    var modTypeVal = '';
    if(thisModuleData){
      if(thisModuleData.type === 'attribute') modTypeVal = 2;
      else if(thisModuleData.type === 'navigation') modTypeVal = 1;
    }
    return (
      <div className="control-pane">
        {/* Module List */}
        {moduleData.length > 0 &&
        <div className="control-section">
        <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6" style={{marginTop: 35, marginLeft: 30, textAlign: 'left'}}>
          <ButtonComponent
            id="createRole"
            style={{ background: "#f94f5e", color: "#fff", borderRadius: "4px" }}
            data-ripple="true"
            onClick={this.actionCreateModule}
          >
            Create Module
          </ButtonComponent>
        </div>
        <div className="col-xs-9 col-sm-9 col-lg-9 col-md-9" style={{marginLeft:30, marginTop: 16}}>
          <style>{SAMPLECSS}</style>
          <GridComponent
                  // className="grid-head-custome"
                  id="modulegrid"
                  // allowPaging
                  // pageSettings={this.pageOptions}
                  dataSource={moduleData}
                  enableHover={false}
                  enableVirtualization={false}
                  rowHeight={30}
                  height={gridHeight}
                  overflow="auto"
                  ref={(g) => {
                    this.gridInstance = g;
                  }}
                  allowSelection
                  selectionSettings={this.select}
                  allowTextWrap={true}
                  commandClick={this.onCommandClick}
                  // width="1400"
                >
                  <ColumnsDirective>
                      {/* <ColumnDirective field='role_id' visible={false} headerText='Module ID' isPrimaryKey={true} width='130'></ColumnDirective> */}
                      <ColumnDirective field='module_name' headerText='Module Name' width='230' textAlign='Left'></ColumnDirective>
                      {/* <ColumnDirective field='module_description' headerText='Module Description' width='130' textAlign='Left'></ColumnDirective> */}
                      <ColumnDirective field='parent_module_name' headerText='Immediate Parent Module' width='160' textAlign='Left'></ColumnDirective>
                      <ColumnDirective field='root_module_name' headerText='Root Module' width='160' textAlign='Left'></ColumnDirective>
                      <ColumnDirective field='type' headerText='Module Type' width='130' textAlign='Left'></ColumnDirective>
                      <ColumnDirective field='var_icon_css' headerText='Menu icon' width='130' textAlign='Left'></ColumnDirective>
                      <ColumnDirective field='constant_id' headerText='Constant Id' width='130' textAlign='Certer'></ColumnDirective>
                      <ColumnDirective field='menu_order_id' headerText='Menu Order' width='130' textAlign='center'></ColumnDirective>
                      <ColumnDirective field='_id' commands={this.commands} headerText='Action' width='130' textAlign='Center'></ColumnDirective>
                  </ColumnsDirective>
                  <Inject services={[Page, Filter, VirtualScroll, Sort]} />
          </GridComponent>
        </div>
        </div>
        }
        
        {/* Maodule Add / Edit Form PopUp */}
        <div style={{width: 300}}>
          {/* <NotificationContainer /> */}
          <Dialog open={moduleFormPopup} fullWidth maxWidth="sm" >
            <DialogTitle>
                {moduleMapPopupTitle} Module
                {/* <div style={{ float: "right" }}>
                    <IconButton style={{ color: "gray", marginRight: "-5px", marginTop: "-18px" }} aria-label="close" onClick={(e) => this.closeModuleFormPopup()}>
                        <CloseIcon />
                    </IconButton>
                </div> */}
            </DialogTitle>
            <DialogContent>
              <div>
                <div>
                  <div className="col-md-12" style={{ paddingTop: "0px" }}>
                    <div style={{ paddingTop: "0px" }} className="col-xs-6 col-sm-6 col-lg-6 col-md-6" >
                      <TextBoxComponent
                        id="moduleName"
                        placeholder="Module Name *"
                        cssClass="e-outline"
                        floatLabelType="Auto"
                        value={thisModuleData.module_name}
                        change={(e) => this.onModuleParamChange(e, 'name')}
                      />
                      <div id="moduleNameError" className="error-div" style={{ fontSize: 12 }} />
                    </div>
                    <div style={{ paddingTop: "0px" }} className="col-xs-6 col-sm-6 col-lg-6 col-md-6" >
                      <TextBoxComponent
                        id="moduleDescription"
                        placeholder="Module Description *"
                        cssClass="e-outline"
                        floatLabelType="Auto"
                        value={thisModuleData.module_description}
                        change={(e) => this.onModuleParamChange(e, 'desc')}
                      />
                      <div id="moduleDescriptionError" className="error-div" style={{ fontSize: 12 }} />
                    </div>
                  </div>
                  <div className="col-md-12" style={{ paddingTop: "16px" }}>
                    <div style={{ paddingTop: "0px" }} className="col-xs-6 col-sm-6 col-lg-6 col-md-6" >
                      <ComboBoxComponent
                          id="moduleType"
                          dataSource={this.moduleTypes}
                          fields={{ text: "Name", value: "Id" }}
                          cssClass="e-outline"
                          data-msg-containerid="moduleTypeErr"
                          name="moduleType"
                          floatLabelType="Auto"
                          placeholder="Module Type *"
                          htmlAttributes={{ maxlength: 100 }}
                          value={modTypeVal}
                          change={(e) => this.onModuleParamChange(e, 'modtype')}
                      />
                      <div id="moduleTypeErr" className="error-div" style={{ fontSize: 12 }} />
                    </div>
                    <div style={{ paddingTop: "0px" }} className="col-xs-6 col-sm-6 col-lg-6 col-md-6" >
                      <CheckBoxComponent
                        id="isRootModule"
                        checked={thisModuleData.is_root === 1 ? true : false}
                        label="Root Module"
                        change={(e) => this.onModuleParamChange(e, 'isroot')}
                      />
                      <div id="isRootModuleErr" className="error-div" style={{ fontSize: 12 }} />
                    </div>
                  </div>
                  <div className="col-md-12" style={{ paddingTop: "16px" }}>
                    <div style={{ paddingTop: "0px" }} className="col-xs-6 col-sm-6 col-lg-6 col-md-6" >
                      <TextBoxComponent
                        id="moduleRoute"
                        placeholder="Module Route *"
                        cssClass="e-outline"
                        floatLabelType="Auto"
                        value={thisModuleData.var_route}
                        change={(e) => this.onModuleParamChange(e, 'route')}
                      />
                      <div id="moduleRouteError" className="error-div" style={{ fontSize: 12 }} />
                    </div>
                    <div style={{ paddingTop: "0px" }} className="col-xs-6 col-sm-6 col-lg-6 col-md-6" >
                      <ComboBoxComponent
                          id="parentModule"
                          dataSource={moduleDataForParentCombo}
                          fields={{ text: "module_name", value: "_id" }}
                          cssClass="e-outline"
                          data-msg-containerid="parentModuleErr"
                          name="parentModule"
                          floatLabelType="Auto"
                          placeholder="Parent Module *"
                          htmlAttributes={{ maxlength: 100 }}
                          // enabled={thisModuleData.is_root === 1 ? false : true}
                          enabled={parentModuleEnable}
                          value={thisModuleData.parent_module_id !== 0 ? thisModuleData.parent_module_id : ''}
                          change={(e) => this.onModuleParamChange(e, 'parentmod')}
                      />
                      <div id="parentModuleErr" className="error-div" style={{ fontSize: 12 }} />
                    </div>
                  </div>
                  <div className="col-md-12" style={{ paddingTop: "16px" }}>
                    <div style={{ paddingTop: "0px" }} className="col-xs-6 col-sm-6 col-lg-6 col-md-6" >
                      <TextBoxComponent
                        id="moduleIcon"
                        placeholder="Module Icon *"
                        cssClass="e-outline"
                        floatLabelType="Auto"
                        value={thisModuleData.var_icon_css}
                        change={(e) => this.onModuleParamChange(e, 'icon')}
                        enabled={menuIconFlag}
                      />
                      <div id="moduleIconError" className="error-div" style={{ fontSize: 12 }} />
                    </div>
                    <div style={{ paddingTop: "0px" }} className="col-xs-6 col-sm-6 col-lg-6 col-md-6" >
                      <TextBoxComponent
                        id="rootModuleOrder"
                        placeholder="Menu Order Id"
                        cssClass="e-outline"
                        floatLabelType="Auto"
                        value={thisModuleData.menu_order_id}
                        change={(e) => this.onModuleParamChange(e, 'order')}
                        enabled={menuOrderFlag}
                      />
                      <div id="rootModuleOrderError" className="error-div" style={{ fontSize: 12 }} />
                    </div>
                  </div>
                  <div className="col-md-12" style={{ paddingTop: "16px" }}>
                  <div style={{ paddingTop: "0px" }} className="col-xs-6 col-sm-6 col-lg-6 col-md-6" >
                      <TextBoxComponent
                        id="constantId"
                        placeholder="Constant Id"
                        cssClass="e-outline"
                        floatLabelType="Auto"
                        value={thisModuleData.constant_id}
                        change={(e) => this.onModuleParamChange(e, 'constant')}
                      />
                      <div id="constantIdError" className="error-div" style={{ fontSize: 12 }} />
                    </div>
                    <div style={{ paddingTop: "0px" }} className="col-xs-6 col-sm-6 col-lg-6 col-md-6" >
                      <span>
                          <RadioButtonComponent
                            label="Active"
                            id="activeRadio"
                            name="moduleStatus"
                            checked
                            onChange={this.setFields}
                            style={{marginRight: 50}}
                          />
                      </span>
                      <span style={{ marginLeft: 15}}>
                          <RadioButtonComponent
                            label="Inactive"
                            id="inactiveRadio"
                            name="moduleStatus"
                            onChange={this.setFields}
                          />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </DialogContent>
            <DialogActions>
                  <ButtonComponent
                    id="cancelRoleUpdate"
                    className="e-control"
                    style={{ borderRadius: "4px", marginBottom: 20 }}
                    onClick={(e) => this.closeModuleFormPopup()}
                  >
                    Cancel
                  </ButtonComponent>
                  <ButtonComponent
                    id="updateRole"
                    style={{ background: "#f94f5e", color: "#fff", borderRadius: "4px", marginRight: 24, marginBottom: 20 }}
                    data-ripple="true"
                    onClick={this.actionUpdateModule}
                  >
                    Submit
                  </ButtonComponent>
            </DialogActions>
          </Dialog>
        </div>
        <div style={{height: 300}}><NotificationContainer /></div>
        {this.state.loader && 
          <div style={{marginTop: 200}}> <ProgressBar /></div>
        }
      </div>
    );
  }
}

ModuleMaster.propTypes = {
  // dataSource: PropTypes.arrayOf(Object).isRequired,
  // grayLeaves: PropTypes.arrayOf(Object).isRequired,
  // eslint-disable-next-line
  // hideSidebar: PropTypes.object.isRequired,
  // noOfUses: PropTypes.number.isRequired,
};
ModuleMaster.defaultProps = {};

const mapStateToProps = ({ auth, adminreducer }) => {
  const { authUser } = auth;
  const { module_id, module_name, module_description, module_status, module_type, module_is_root, module_parent_id, module_icon, module_route, module_menu_order, constant_id, action_status } = adminreducer;
  return { authUser, module_id, module_name, module_description, module_status, module_type, module_is_root, module_parent_id, module_icon, module_route, module_menu_order, constant_id, action_status };
};

export default connect(mapStateToProps, {
  saveRoleModuleInfoRedux, 
  addEditModuleDetails,
})(ModuleMaster);
