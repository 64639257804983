import React, { Component } from 'react';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import './resignationcomponent.css';
import SeparationServices from '../../../services/entryexit/separationServices';
class Status extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resignationStatusData: null,
      withdrawalStatusData: null,
      clearaceStatusData:null
    };
    this.getResignationStatus = this.getResignationStatus.bind(this);
  }
  componentDidMount = () => {
    const { title  } = this.props;
    if (title === "RESIGNATION STATUS") {
      this.getResignationStatus();
    }
    if (title === "RESIGNATION WITHDRAWAL STATUS") {
      this.getWithdrawalDetail();
    }
    if (title === "CLEARANCE STATUS") {
      this.getClearanceStatus();
    }
  };
  getResignationStatus = () => {
    try {
      const { authUser } = this.props;
      const employeeBasicData = Promise.resolve(
        SeparationServices.getEmployeeResignationData(
          authUser,
          "status"
        )
      );
      employeeBasicData.then((result) => {
        if (result !== null) {
          this.setState({
            resignationStatusData: result,
          })
        }
      });
    } catch (error) {

    }
  }
  getClearanceStatus = () => {
    try {
      const { authUser } = this.props;
      const employeeBasicData = Promise.resolve(
        SeparationServices.getClearanceDetail(
          authUser,
          "status"
        )
      );
      employeeBasicData.then((result) => {
        if (result !== null) {
          this.setState({
            clearaceStatusData: result,
          })
        }
      });
    } catch (error) {

    }
  }
  getWithdrawalDetail = () => {
    try {
      const { authUser } = this.props;
      const employeeBasicData = Promise.resolve(
        SeparationServices.getWithdrawalDetail(
          authUser,
          "status"
        )
      );
      employeeBasicData.then((result) => {
        if (result !== null) {
          this.setState({
            withdrawalStatusData: result,
          })
        }
      });
    } catch (error) {

    }
  }

  statusTemplate(statusText) {
    if (statusText.clearanceStatus === "Completed" || statusText.clearanceStatus === "Accepted") {
      return (
        <div style={{ borderLeft: "3px solid green", paddingLeft: "5px" }}>
          <span className='bold-text' id="">Accepted</span>
          <br />
          {
          statusText.procDate !== null ? 
          <span id="">{statusText.procDate}</span>
          :
          <span id="" style={{color:'#fff'}}>.</span>
          }
        </div>
      );
    }
    if (statusText.clearanceStatus === "Partial") {
      return (
        <div style={{ borderLeft: "3px solid #f68104", paddingLeft: "5px" }}>
          <span className='bold-text' id="">Accepted</span>
          <br />
          {
          statusText.procDate !== null ? 
          <span id="">{statusText.procDate}</span>
          :
          <span id="" style={{color:'#fff'}}>.</span>
          }
        </div>
      );
    }
    if (statusText.clearanceStatus === "Pending") {
      return (
        <div style={{ borderLeft: "3px solid gray", paddingLeft: "5px" }}>
          <span className='bold-text' id="">Pending</span>
          <br />
          {
            statusText.procDate !== null ? 
          <span id="">{statusText.procDate}</span>
          :
          <span id="" style={{color:'#fff'}}>.</span>
          }
        </div>
      );
    }
    if (statusText.clearanceStatus === "Not Initiated") {
      return (
        <div style={{ borderLeft: "3px solid #f68104", paddingLeft: "5px" }}>
          <span className='bold-text' id="">Not Initiated</span>
          <br />
          {
            statusText.procDate !== null ? 
          <span id="">{statusText.procDate}</span>
          :
          <span id="" style={{color:'#fff'}}>.</span>
          }
        </div>
      );
    }
    if (statusText.clearanceStatus === "NA") {
      return (
        // <div style={{ borderLeft: "3px solid #f68104", paddingLeft: "5px" }}>
        //   <span id="">Not Initiated</span>
        <div>
          {/* <br /> */}
          <span id="">NA</span>
        </div>
      );
    }
    if (statusText.clearanceStatus === null) {
      return (
        <div style={{paddingLeft: "5px" ,height:"38px" }}>
        </div>
      );
    }
    return false;
  }
  resignationStatusTemplate(item) {
    if (item.resgnStatus === "Accepted" || item.resgnStatus === "Completed") {
      return (
        <div style={{ borderLeft: "3px solid green", paddingLeft: "5px" }}>
          <span className='bold-text' id="">Accepted</span>
          <br />
          <span id="">{item.procDate}</span>
        </div>
      );
    }
    else if (item.resgnStatus === "Pending") {
      return (
        <div style={{ borderLeft: "3px solid gray", paddingLeft: "5px" }}>
          <span className='bold-text' id="">Pending</span>
          <br />
          <span id="">{item.procDate}</span>
        </div>
      );
    }
    else if (item.resgnStatus === "Rejected") {
      return (
        <div style={{ borderLeft: "3px solid red", paddingLeft: "5px" }}>
          <span className='bold-text' id="">{item.resgnStatus}</span>
          <br />
          <span id="">{item.procDate}</span>
        </div>
      );
    }
    else if (item.resgnStatus === "Not Initiated") {
      return (
        <div style={{ borderLeft: "3px solid #f68104", paddingLeft: "5px" }}>
          <span className='bold-text' id="">Not Initiated</span>
          <br />
          <span id="" >{item.procDate}</span>
        </div>
      );
    }
    else {
      return (
        <div>

          <span id="" className='bold-text'>{item.resgnStatus}</span>
        </div>
      );
    }
  }
  withdrawalStatusTemplate(item) {
    if (item.withdrawlStatus === "Accepted") {
      return (
        <div style={{ borderLeft: "3px solid green", paddingLeft: "5px" }}>
          <span className='bold-text' id="">Accepted</span>
          <br />
          <span id="">{item.procDate}</span>
        </div>
      );
    }
    if (item.withdrawlStatus === "Rejected") {
      return (
        <div style={{ borderLeft: "3px solid red", paddingLeft: "5px" }}>
          <span className='bold-text' id="">Rejected</span>
          <br />
          <span id="">{item.procDate}</span>
        </div>
      );
    }
    if (item.withdrawlStatus === "Pending") {
      return (
        <div style={{ borderLeft: "3px solid gray", paddingLeft: "5px" }}>
          <span className='bold-text' id="">Pending</span>
          <br />
          <span id="">{item.procDate}</span>
        </div>
      );
    }
    if (item.withdrawlStatus === "Not Initiated") {
      return (
        <div style={{ borderLeft: "3px solid #f68104", paddingLeft: "5px" }}>
          <span className='bold-text' id="">Not Initiated</span>
          <br />
          <span id="">{item.procDate}</span>
        </div>
      );
    }
    if (item.roleName === "Reason To Withdrawal" || item.roleName==='Withdrawal Date' ) {
      return (
        <div>
          <span id="" className='bold-text'>{item.withdrawlStatus}</span>
        </div>
      );
    }
    if (item.roleName === "Comments") {
      return (
        <div>
          <TooltipComponent
            content={item.withdrawlStatus.replace(/(?:\r\n|\r|\n)/g, '<br>')}
            position="BottomLeft"
            isSticky={item.withdrawlStatus.length > 50 ? true : false}
            cssClass='customtooltip'
            opensOn={item.withdrawlStatus.length > 50 ? "Click" : "Auto"}
          >
            <span className='bold-text' style={{
              cursor: "pointer" 
            }}>{item.withdrawlStatus}</span>
          </TooltipComponent>
        </div>
      );
    }
    return false;
  }
  render() {
    const { title } = this.props;
    const { resignationStatusData, withdrawalStatusData, clearaceStatusData } = this.state;
 
    return (
      <div>
        <div> {title} </div>
        {title === "RESIGNATION STATUS" &&
          <div style={{ padding:'0px 10px 10px 10px' }}>
            <div className="col-12 table-border-bottom top-side-margin"
             
            >
              <div className='table-width'>
                {resignationStatusData !== null && resignationStatusData.length > 0 &&
                  resignationStatusData.map((item, index) => {
                    return <div key={item.roleName + index} className="clearance-status-custom resignation-status-table">{item.roleName}</div>;
                  })
                }
              </div>
            </div>
            <div className="col-12 table-border-bottom top-side-margin">
              <div className='table-width'>
                {resignationStatusData !== null && resignationStatusData.length > 0 &&
                  resignationStatusData.map((item, index) => {
                    return <div className=" clearance-status-custom resignation-status-table">
                      {this.resignationStatusTemplate(item)}
                    </div>
                  })
                }
                {/* <div className=" clearance-status-custom resignation-status-table">
                  {this.resignationStatusTemplate("Completed")}

                </div>
                <div className=" clearance-status-custom resignation-status-table">
                  {this.resignationStatusTemplate("Pending")}
                </div>
                <div className=" clearance-status-custom resignation-status-table">
                  {this.resignationStatusTemplate("Completed")}
                </div>
                <div className=" clearance-status-custom resignation-status-table">
                  {this.resignationStatusTemplate("NA")}
                </div> */}
              </div>
            </div>
          </div>
        }

        {title === "CLEARANCE STATUS" &&
          <div style={{ padding:'0px 10px 10px 10px'   }}>
            {/* <div style={{overflowX:'scroll', padding:'0px 10px 10px 10px'}}> */}
              {/* sssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssssss */}
            {/* </div> */}
            {/* <div className="col-12 table-border-bottom top-side-margin">
              <div className='table-width'>
                {
                clearaceStatusData !==null && clearaceStatusData.length > 0 &&
                clearaceStatusData.map((item, index)=>{
                  return <div key={index} className=" clearance-status-custom resignation-status-table">{item.deptName}</div>
                })   
                }
              </div>

            </div>
            <div className="col-12 table-border-bottom top-side-margin">
              <div className='table-width'>
              
              {
                clearaceStatusData !==null && clearaceStatusData.length > 0 &&
                clearaceStatusData.map((item, index)=>{
                return ( <div className=" clearance-status-custom resignation-status-table">
                    {this.statusTemplate(item)}
                  </div>
                 );
                })
              }

              </div>
            </div> */}

          <div className="col-12 top-side-margin" >
            <div className='table-width' 
            >
              {
                clearaceStatusData !== null && clearaceStatusData.length > 0 &&
                clearaceStatusData.map((item, index) => {
                  return (
                  <div key={index} className=" clearance-status-custom resignation-status-table-clearance">
                    <div className='table-border-bottom' style={{paddingBottom:5}}>{item.deptName}</div>
                    <div className='table-border-bottom' style={{paddingTop:5}}>
                      {this.statusTemplate(item)}
                    </div>
                    {
                      index===5 && <hr style={{borderTop:'#fff'}}/>
                    }
                  </div>
                  );
                })
              }
            </div>

          </div>
         </div>

        }
        {title === "RESIGNATION WITHDRAWAL STATUS" &&
          <div style={{padding:'0px 10px 10px 10px'  }}>
            <div className="col-12 table-border-bottom top-side-margin">
              <div className='table-width'>
                {withdrawalStatusData !== null && withdrawalStatusData.length > 0 &&
                  withdrawalStatusData.map((item, index) => {
                    return <div key={item.roleName + index} className="clearance-status-custom resignation-status-table">{item.roleName}</div>;
                  })
                }
              </div>
            </div>
            <div className="col-12 table-border-bottom top-side-margin">
              <div className='table-width'>
                {withdrawalStatusData !== null && withdrawalStatusData.length > 0 &&
                  withdrawalStatusData.map((item, index) => {
                    return <div className=" clearance-status-custom resignation-status-table">
                      {this.withdrawalStatusTemplate(item)}
                    </div>
                  })
                }

              </div>
            </div>
          </div>
          // <div style={{ paddingBottom: 10 }}>
          //   <div className="col-12 table-border-bottom top-side-margin">
          //     <div className='table-width'>
          //       <div className=" clearance-status-custom resignation-status-table">Date</div>
          //       <div className=" clearance-status-custom resignation-status-table">Reason to withdrawal</div>
          //       <div className=" clearance-status-custom resignation-status-table">Manager</div>
          //       <div className=" clearance-status-custom resignation-status-table">P&L Head</div>
          //       <div className=" clearance-status-custom resignation-status-table">HR</div>
          //     </div>
          //   </div>
          //   <div className="col-12 table-border-bottom top-side-margin">
          //     <div className='table-width'>
          //       <div className=" clearance-status-custom resignation-status-table">
          //         {/* {this.statusTemplate("Completed")} */}
          //         <span id="">{getDisplayDateOnly(new Date())}</span>
          //       </div>
          //       <div className=" clearance-status-custom resignation-status-table">
          //         {/* {this.statusTemplate("Completed")} */}
          //         Role Change
          //     </div>
          //       <div className=" clearance-status-custom resignation-status-table">
          //         {this.statusTemplate("Pending")}
          //       </div>
          //       <div className=" clearance-status-custom resignation-status-table">
          //         {this.statusTemplate("Completed")}
          //       </div>
          //       <div className=" clearance-status-custom resignation-status-table">
          //         {this.statusTemplate("Pending")}
          //       </div>
          //     </div>
          //   </div>
          // </div>
        }
      </div>
    );
  }
}
Status.propTypes = {
  title: PropTypes.string.isRequired,
};
Status.defaultProps = {
  authUser: "",
};
const mapStateToProps = ({ auth, meetingmaster, separationreducer }) => {
  const { authUser } = auth;
  return { authUser };
};

export default connect(mapStateToProps, {})(Status);