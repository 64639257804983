import React from 'react'
import { connect } from "react-redux";
import HelpDeskTicket from '../../../components/helpdeskticket/helpdeskticket';
function MyTicketContainer(props) {
  return (
    <HelpDeskTicket />
  )
}

const mapStateToProps = ({ auth, router }) => {
  const { authUser } = auth;
  const { location } = router;
  return { authUser, location };
};

export default connect(mapStateToProps, {
})(MyTicketContainer);
