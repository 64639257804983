import { all } from "redux-saga/effects";
import authSagas from "./authmiddleware";
import meetingBookingSagas from "./metingbookingmiddleware";
import userSettingSagas from "./settingmiddleware";
import homeOfficeSaga from "./homeoffice/homeofficemiddleware";
import confirmationSaga from './entryexit/confirmationmiddleware';
import onbordingSaga from '../middleware/entryexit/onboardingmiddleware';
import separationmiddleware from '../middleware/entryexit/separationmiddleware';
import orgchartmiddleware from '../middleware/entryexit/orgchartmiddleware';
import okrSagas from '../middleware/okr/okrmiddleware';
import adminSagas from '../middleware/admin/adminmiddleware';
import okrRatingSagas from '../middleware/okrratingmiddleware/okrratingmiddleware';
import lnaSagas from '../middleware/lna/lnamiddleware'
import helpdeskSagas from '../middleware/helpdesk/helpdeskmiddleware'


// eslint-disable-next-line
export default function* rootSaga(getState) {
  yield all([
    authSagas(),
    meetingBookingSagas(),
    userSettingSagas(),
    homeOfficeSaga(),
    onbordingSaga(),
    confirmationSaga(),
    separationmiddleware(),
    orgchartmiddleware(),
    okrSagas(),
    adminSagas(),
    okrRatingSagas(),
    lnaSagas(),
    helpdeskSagas(),
  ]);
}
