import {
  UPDATE_USER_PRODUCTIVE_ALLOCATION,
  SET_COST_SHARING_REDUCER,
  ADD_EDIT_COST_SHARING_NEWREQUEST,
  ACCEPT_REJECT_REQUEST, ADD_UPDATE_OKR_OBJETVE_KPI, SET_CENTRAL_OKR_KPI,
  UPDATE_OKR_REDUX,
  TEAM_MEMBER_SELECTION,
  COMMON_MASTER_SCREEN_LOADING
  // ADD_EDIT_OKR_OBJETVE_KPI
} from "../../constant/okr/okrconstant";
const INIT_STATE = {
  employeeId: 0, // Use Selected Employee Id when navigate to this page - For testing purpose Id is set to 730 (Vivek's Id)
  sharedWith: 0,
  startDate: new Date(),
  endDate: new Date(),
  percSalaryShared: 0,
  amountShared: 0,
  currencyId: 0,
  description: '',
  newRequestStatus: '',
  totalCostSharing: 0,
  objectiveData: [],
  viewObjectiveData: [],
  pendingForApprovalObjectiveData: [],
  objectAddIndex: null,
  objectEditIndex: null,
  addObjectStatus: "",
  editObjectStatus: "",
  allOkrKpi: [],
  allObjId: [],
  allBusinessObj: [],
  importOKRIds: [],
  okrBusinessPopup: false,
  renderObjectiveStatus: '',
  reRenderComponent: '',
  totalWeightage: '',
  basicInfoAccordion: true,
  OkrMasters: [],
  selectedObjId: 0,
  userObjectStatus: '',
  refreshCount: 0,
  isPrdAllocationDone: false,
  centralOkrData: [],
  leaderShipOkrData: [],
  brandValueOkrData: [],
  centralOkrMasterList: [],
  leaderShipOkrMasterList: [],
  brandValueOkrMasterList: [],
  setObjectiveDraftIds: [],
  addObjectDraftStatus: '',
  centralKPITemp: [],
  leadershiKPITemp: [],
  brandKPITemp: [],
  allcommonTempData: [],
  okrreloadStatus: "",
  objectiveName:"", // The following obj related variable declaired by - Prashant
  objectiveTags:[],
  visibility:"",
  levelL:[],
  kpiName:"",
  kpiNewTags:[],
  kpiDescription:"",
  kpiAttachment:[],
  objId:0,
  kpiId:0,
  allOBJKPISubmit:[],
  closAlleDialog:"",
  tempViewObjectiveKPI:[],
  refreshPendingStatus:"",
  objKpiAddUpdateType:"",
  cancelPendingApproval:"",
  fyid:0,
  updateimportStatus:'',
  businessUserImpact:0,
  businessUserVariableImpact:0,
  updateBuDraftFlag: "",
  updateBuActiveFlag: "",
  ratingMecanismStart:0,
  ratingMecanismEnd:0,
  disableEnableFlag:"",
  activeTabFlag:false,
  monthOfReview:0,
  fillObjective:{},
  fillKpi:{},
  draftedBusinessObjKpi:[],
  submittedBusinessObjective:[],
  allActiveBusinessObjective:[],
  selectedFy:"2021 - 2022",
  draftData: [],
  draftWeight: 0,
  activeData: [],
  activeWeight: 0,
  moveToActive:'',
  lastSubmittedBusinessOkrObjId:0,
  resetCheckedData:'',
  refreshProdAllocation:'',
  resetCheckedObjData:'',
  OkrEmployeeMaster:[],
  draftCentralObjectiveKpi:[],
  activeCentralObjectiveKpi:[],
  centralUserImpact:0,
  centralUserVariableImpact:0,
  updateCentralStatus:'',
  centralUserFrequency:'',
  moveCentralToActive:'',
  lpStatus:'',
  LPUserVariableImpact:0,
  LPUserFrequency:0,
  ratingCentralMecanismEnd:0,
  refreshViewStatus:"",
  cancelViewApproval:"",
  isNewObjCreated:null,
  newObjFreqency:null,
  newObjStartMonth:null,
  closedBusinessOkr:[],
  closedCentralOkr:[],
  okrAllocationTabs:null,
  closedMonthOfReview:0,
  closedTabStatus:"",
  closedLPOkr:[],
  isObjDisabled:null,
  updateDefaultSelectionTabFlag:'',
  sortByFlag:false,
  okrMasterFlag:'',
  ratingSystem :[],
  okrAdminMasterData: {},
  teamMemberSelectionList: [],
  commonMasterLoading: false,
  loading: true,
  teamMemberMatrixStatus:''
};

// eslint-disable-next-line
export default (state = INIT_STATE, action) => { //NOSONAR
  switch (action.type) {
    case UPDATE_USER_PRODUCTIVE_ALLOCATION: {
      return {
        ...state,
        categoryName: action.payload,
      };
    }
    case SET_COST_SHARING_REDUCER:
    case ADD_EDIT_COST_SHARING_NEWREQUEST:
    case ACCEPT_REJECT_REQUEST:
    case ADD_UPDATE_OKR_OBJETVE_KPI:
    case UPDATE_OKR_REDUX:
    case TEAM_MEMBER_SELECTION:
    case COMMON_MASTER_SCREEN_LOADING:
    case SET_CENTRAL_OKR_KPI: {
      return {
        ...state,
        ...action.payload
      };
    }
    default:
      return state;
  }
};
