import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import axios from "axios";
import {
    ADD_EDIT_SELF_SUP_FINAL_RATING,
    ADD_EDIT_ACTUAL_RATING,
    ADD_EDIT_INTERIM_REVIEW_FEEDBACK,
    SUBMIT_OKR_RATING,
    OKR_SET_MEETING,
    OKR_ACCEPTED
} from "../../constant/okrratingconstant/okrratingconstant";
import { urlConfig } from "../../../config/config";
import { getNotify } from "../../../app/other/commonfunction";
import {
    ERROR, SUCCESS, OKRRatingSelfRecomnd, OKRRatingSupRecomnd, OKRRatingFinalRecomnd,
    InterimFeedBackThirdParty, InterimFeedBackSelf, InterimFeedBackSupervisor, OKRRatingFinalRating,
    // OKRRatingSelfSubmitted, OKRRatingSupSubmitted, 
    OKRReviewSubmitted
} from "../../../assets/constants/constants";
import { addUpdateOkrRatingRedux } from '../../actions/okrrating/okrratingaction'

// amit
// Author :- Amit Kumar| 13-10-2021 | call to API for adding/updating the interim review feedback
const addEditInterimReviewFeedbackRequest = async (data) => {
    return axios
        .post(
            `${urlConfig.tmlcApiUrl}interimreview`, data[0],
            {
                headers: {
                    authorization: localStorage.getItem("jwtToken"),
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            }
        )
        .then((result) => result)
        .catch((error) => error.response);
}

// Author :- Amit Kumar | 13-10-2021 | call to API for adding/updating the interim review feedback
function* addEditInterimReviewFeedbackDetails({ payload }) { // NOSONAR    
    const ratingType = payload.reviewType
    const OKRType = payload.OKRType
    delete payload.OKRType
    const status = yield call(addEditInterimReviewFeedbackRequest, [payload, ratingType]);
    if (status.status === 200) {
        setTimeout(() => {
            switch (ratingType) {
                case "self":
                    getNotify(SUCCESS, InterimFeedBackSelf); break;
                case "sup":
                    getNotify(SUCCESS, InterimFeedBackSupervisor); break;
                case "tp":
                    getNotify(SUCCESS, InterimFeedBackThirdParty); break;
                default:
            }
        }, 100);
        yield put(addUpdateOkrRatingRedux({ refreshInterimDataFlag: "interimUpdated" + OKRType, refreshInterimfeedbackflag: 'updated' }));

    } else {
        getNotify(ERROR, status.data[0].result.replace("payload: ", ""));
    }
}

// Author :- Amit Kumar | 13-10-2021 | call to API for adding/updating the interim review feedback
export function* addEditInterimreviewfeedback() {
    yield takeEvery(ADD_EDIT_INTERIM_REVIEW_FEEDBACK, addEditInterimReviewFeedbackDetails);
}

// Author :- Vivek Khodade | 14-10-2021 | call to API for adding/updating the self , Supervisor and TP rating actual rating
const addEditActualRatingDetailsRequest = async (data) => {
    return axios
        .post(
            `${urlConfig.tmlcApiUrl}rating/actual`, data,
            {
                headers: {
                    authorization: localStorage.getItem("jwtToken"),
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            }
        )
        .then((result) => result)
        .catch((error) => error.response);
}


// Author :- Vivek Khodade | 14-10-2021 | call to API for adding/updating the self , Supervisor and TP rating actual rating
function* addEditActualRatingDetails({ payload }) { // NOSONAR
    const OKRType = payload.OKRType
    delete payload.OKRType
    const status = yield call(addEditActualRatingDetailsRequest, payload);
    if (status.status === 200) {
        setTimeout(() => {
            getNotify(SUCCESS, "OKR actual rating saved successfully.");
        }, 100);
        yield put(addUpdateOkrRatingRedux({ refreshRatingDataFlag: "refresh" + OKRType }));
    } else {
        getNotify(ERROR, status.data.message.replace("payload: ", ""));
    }
}
/**
  Author :- Vivek Khodade | 14-10-2021 | call to API for adding/updating the self , Supervisor and TP rating actual rating
 */
export function* addEditActualRating() {
    yield takeEvery(ADD_EDIT_ACTUAL_RATING, addEditActualRatingDetails);
}


// Author :- Suresh Sargam | 16-10-2021 | call to API to submit the self, Supervisor and review
const submitOKRRatingDetailsRequest = async (data) => {
    return axios
        .post(
            `${urlConfig.tmlcApiUrl}ratingreview/submit/${data[1]}`, data[0],
            {
                headers: {
                    authorization: localStorage.getItem("jwtToken"),
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            }
        )
        .then((result) => result)
        .catch((error) => error.response);
}
// Author :- Suresh Sargam | 16-10-2021 | call to API to submit the self, Supervisor and review
function* submitOKRRatingDetails({ payload }) { // NOSONAR
    const ratingReviewType = payload.ratingReviewType
    const OKRType = payload.OKRType
    delete payload.ratingReviewType

    const status = yield call(submitOKRRatingDetailsRequest, [payload, ratingReviewType]);
    if (status.status === 200) {
        setTimeout(() => {
            if(ratingReviewType === 'review'){
                getNotify(SUCCESS, OKRReviewSubmitted);
            }
        }, 100);
        yield put(addUpdateOkrRatingRedux({ refreshRatingDataFlag: "ratingSubmitted" + OKRType, ratingCategoryStatus: 'submittedRating' }));
    } else {
        getNotify(ERROR, status.data.message.replace("payload: ", ""));
    }
}
/**
  Author :- Suresh Sargam | 16-10-2021 | call to API to submit the self, Supervisor and review 
 */
export function* submitOKRRating() {
    yield takeEvery(SUBMIT_OKR_RATING, submitOKRRatingDetails);
}


// Author :- Suresh Sargam | 12-10-2021 | call to API for adding/updating the self , Supervisor and TP rating
const addEditSelfSupFinalRatingDetailsRequest = async (data) => {

    return axios
        .post(
            `${urlConfig.tmlcApiUrl}rating/${data[1]}`, data[0],
            {
                headers: {
                    authorization: localStorage.getItem("jwtToken"),
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            }
        )
        .then((result) => result)
        .catch((error) => error.response);
}
// Author :- Suresh Sargam | 12-10-2021 | call to API for adding/updating the self , Supervisor and TP rating
function* addEditSelfSupFinalRatingDetails({ payload }) { // NOSONAR
    const ratingType = payload.type
    const OKRType = payload.OKRType
    delete payload.type
    delete payload.OKRType
    const status = yield call(addEditSelfSupFinalRatingDetailsRequest, [payload, ratingType]);
    if (status.status === 200) {
        setTimeout(() => {
            switch (ratingType) {
                case "self":
                    getNotify(SUCCESS, OKRRatingSelfRecomnd + (OKRType === '3' ? " for Leadership Principles." : ".")); break;
                case "sup":
                    getNotify(SUCCESS, OKRRatingSupRecomnd + (OKRType === '3' ? " for Leadership Principles." : ".")); break;
                case "tp":
                    getNotify(SUCCESS, OKRRatingFinalRecomnd + (OKRType === '3' ? " for Leadership Principles." : ".")); break;
                case "final":
                    getNotify(SUCCESS, OKRRatingFinalRating + (OKRType === '3' ? " for Leadership Principles." : ".")); break;
                default:
            }
        }, 100);
        if (ratingType === 'final') {
            yield put(addUpdateOkrRatingRedux({ refreshRatingDataFlag: "reviewUpdate" + OKRType }));
        } else {
            yield put(addUpdateOkrRatingRedux({ refreshRatingDataFlag: "ratingUpdated" + OKRType }));
        }
    } else {
        getNotify(ERROR, status.data.message.replace("payload: ", ""));
    }
}
/**
  Author :- Suresh Sargam | 12-10-2021 | call to API for adding/updating the self , Supervisor and TP rating
 */
export function* addEditSelfSupFinalRating() {
    yield takeEvery(ADD_EDIT_SELF_SUP_FINAL_RATING, addEditSelfSupFinalRatingDetails);
}

// Author :- Suresh Sargam | 22-10-2021 | call to to set the OKR meeting
const setOKRMeetingDetailsRequest = async (data) => {
    return axios
        .post(
            `${urlConfig.tmlcApiUrl}rating/setmeeting`, data,
            {
                headers: {
                    authorization: localStorage.getItem("jwtToken"),
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            }
        )
        .then((result) => result)
        .catch((error) => error.response);
}
// Author :- Vivek Khodade | 15-11-2021 | call to API to Checked The Actual Input
const okrAcceptedDetailsRequest = async (data) => {
    delete data.OKRType;
    return axios
        .post(
            `${urlConfig.tmlcApiUrl}ratingdashboard/submit`, data,
            {
                headers: {
                    authorization: localStorage.getItem("jwtToken"),
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            }
        )
        .then((result) => result)
        .catch((error) => error.response);
}
// Author :- Suresh Sargam | 22-10-2021 | call to API to set the OKR meeting
function* setOKRMeetingDetails({ payload }) { // NOSONAR
    const status = yield call(setOKRMeetingDetailsRequest, payload);
    if (status.status === 200) {
        yield put(addUpdateOkrRatingRedux({ refreshRatingDataFlag: "meetingSet" }));
    } else {
        getNotify(ERROR, status.data.message.replace("payload: ", ""));
    }
}
// Author :- Vivek Khodade | 15-11-2021 | call to API to Checked The Actual Input
function* okrAcceptedDetails({ payload }) { // NOSONAR
    let OKRType = payload.OKRType;
    const status = yield call(okrAcceptedDetailsRequest, payload);
    if (status.status === 200) {
        yield put(addUpdateOkrRatingRedux({ refreshRatingDataFlag: "refresh" + OKRType }));
    } else {
        getNotify(ERROR, status.data.message.replace("payload: ", ""));
    }
}
/**
  Author :- Suresh Sargam | 22-10-2021 | call to API to set the OKR meeting
 */
export function* setOKRMeeting() {
    yield takeEvery(OKR_SET_MEETING, setOKRMeetingDetails);
}
/**
  Author :- Vivek Khodade | 15-11-2021 | call to API to Checked The Actual Input
 */
export function* okrAccepted() {
    yield takeEvery(OKR_ACCEPTED, okrAcceptedDetails);
}

export default function* rootSaga() {
    yield all([
        fork(addEditSelfSupFinalRating),
        fork(addEditActualRating),
        fork(addEditInterimreviewfeedback),
        fork(submitOKRRating),
        fork(setOKRMeeting),
        fork(okrAccepted)
    ]);
}
