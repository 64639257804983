import axios from "axios";
import { urlConfig, appId, appAuthPassword } from "../config/config";
const https = require('https');

function errorHandler(reject, error) {
  // eslint-disable-next-line
  console.dir(error);
  reject("operation failed");
}

function getMasterData(authUser, pType, pTypeId = 0) {
  return new Promise(function onThen(resolve, reject) {
    const typeCond = pTypeId > 0 ? `/${pTypeId.toString()}` : ""
    axios
      .get(
        `${urlConfig.apiurl}masterdata/${authUser}/${pType}${typeCond}`,
        {
          headers: {
            authorization: localStorage.getItem("jwtToken"),
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      // eslint-disable-next-line
      .then(function (response) {
        if (response != null) {

          return resolve(response.data.result);
        }
      })
      // eslint-disable-next-line
      .catch(function (error) {
        errorHandler(reject, error);
        return null;
      });
  });
}

function getAttendeeList(authUser, pSearch) {
  const searchCond = pSearch !== "" ? `/${pSearch}` : ""
  return new Promise(function onThen(resolve, reject) {
    axios
      .get(
        `${urlConfig.apiurl}userlist/${authUser}${searchCond}`,
        {
          headers: {
            authorization: localStorage.getItem("jwtToken"),
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      // eslint-disable-next-line
      .then(function (response) {
        if (response != null) {
          return resolve(response.data);
        }
      })
      // eslint-disable-next-line
      .catch(function (error) {
        errorHandler(reject, error);
        return null;
      });
  });
}

function getRoomList(authUser) {
  return new Promise(function onThen(resolve, reject) {
    axios
      .get(`${urlConfig.apiurl}roomavailability/${authUser}/`, {
        headers: {
          authorization: localStorage.getItem("jwtToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      // eslint-disable-next-line
      .then(function (getRoomListResponse) {
        if (getRoomListResponse != null) {
          return resolve(getRoomListResponse.data);
        }
      })
      // eslint-disable-next-line
      .catch(function (error) {
        errorHandler(reject, error);
        return null;
      });
  });
}

function getIndMeetingDts(authUser, pId, pType, pIsSeries, pAccess) {

  const seriesCond = pIsSeries ? "/1" : "/0";
  const accessCond = pAccess ? "/1" : "";
  return new Promise(function onThen(resolve, reject) {
    axios
      .get(
        `${urlConfig.apiurl +
        (pType === "P" ? "planmeeting/" : "actualmeeting/") +
        authUser
        }/${pId}${pType === "P" ? (seriesCond) + accessCond : ""
        }`,
        {
          headers: {
            authorization: localStorage.getItem("jwtToken"),
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      // eslint-disable-next-line
      .then(function (getIndMeetingDtsResponse) {
        if (getIndMeetingDtsResponse != null) {
          return resolve(getIndMeetingDtsResponse.data.result);
        }
      })
      // eslint-disable-next-line
      .catch(function (error) {
        errorHandler(reject, error.response.data.message);
        return null;
      });
  });
}

function getUserAvailability(
  authUser,
  startDt,
  endDt,
  attendeeId,
  timeZoneId,
  meetingMode,
  meetingId
) {
  return new Promise(function onThen(resolve, reject) {
    axios
      .get(
        `${urlConfig.apiurl}useravailability/${authUser}/${timeZoneId}/${startDt}/${endDt}/${meetingMode}/${meetingId}/${attendeeId}`,
        {
          headers: {
            authorization: localStorage.getItem("jwtToken"),
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      // eslint-disable-next-line
      .then(function (getUserAvailabilityResponse) {
        if (getUserAvailabilityResponse != null) {
          return resolve(getUserAvailabilityResponse.data.result);
        }
      })
      // eslint-disable-next-line
      .catch(function (error) {
        errorHandler(reject, error);
        return null;
      });
  });
}
function getRoomAvailability(authUser, startDt, endDt, meetingId, roomIds) {
  return new Promise(function onThen(resolve, reject) {
    const roomIdsVar = roomIds ? `/${roomIds}` : "";
    axios
      .get(
        `${urlConfig.apiurl}roomavailability/${authUser}/${startDt}/${endDt}/${meetingId}${roomIdsVar}`,
        {
          headers: {
            authorization: localStorage.getItem("jwtToken"),
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      // eslint-disable-next-line
      .then(function (getRoomAvailabilityResponse) {
        if (getRoomAvailabilityResponse != null) {
          return resolve(getRoomAvailabilityResponse.data.result);
        }
      })
      // eslint-disable-next-line
      .catch(function (error) {
        errorHandler(reject, error);
        return null;
      });
  });
}

function getShiftDetails(authUser, pUserId) {
  return new Promise(function onThen(resolve, reject) {
    axios
      .get(`${urlConfig.apiurl}usershiftdetails/${authUser}/${pUserId}`, {
        headers: {
          authorization: localStorage.getItem("jwtToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      // eslint-disable-next-line
      .then(function (getShiftDetailsResponse) {
        if (getShiftDetailsResponse != null) {
          return resolve(getShiftDetailsResponse.data);
        }
      })
      // eslint-disable-next-line
      .catch(function (error) {
        errorHandler(reject, error);
        return null;
      });
  });
}

function getUserSetting(pUserId, OtherUserId, pdtStartDate) {
  return new Promise(function onThen(resolve, reject) {
    axios
      .get(
        `${urlConfig.apiurl
        }usersettingdetails/${pUserId}/${OtherUserId}/${pdtStartDate.toString()}`,
        {
          headers: {
            authorization: localStorage.getItem("jwtToken"),
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      // eslint-disable-next-line
      .then(function (getUserSettingResponse) {
        if (getUserSettingResponse != null) {
          return resolve(getUserSettingResponse.data);
        }
      })
      // eslint-disable-next-line
      .catch(function (error) {
        errorHandler(reject, error);
        return null;
      });
  });
}
function getUserDetails(otherUser) {
  return new Promise(function onThen(resolve, reject) {
    axios
      .get(`${urlConfig.apiurl}user/${otherUser}`, {
        headers: {
          authorization: localStorage.getItem("jwtToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      // eslint-disable-next-line
      .then(function (getUserDetailsResponse) {
        if (getUserDetailsResponse != null) {
          return resolve(getUserDetailsResponse.data);
        }
      })
      // eslint-disable-next-line
      .catch(function (error) {
        errorHandler(reject, error);
        return null;
      });
  });
}

function getMeetingNotifiction(authUser, limit) {
  if (authUser !== null) {
    return new Promise(function onThen(resolve, reject) {
      axios
        .get(`${urlConfig.apiurl}notification/${authUser}/${limit}`, {
          headers: {
            authorization: localStorage.getItem("jwtToken"),
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        // eslint-disable-next-line
        .then(function (getMeetingNotifictionResponse) {
          if (getMeetingNotifictionResponse != null) {
            return resolve(getMeetingNotifictionResponse.data);
          }
        })
        // eslint-disable-next-line
        .catch(function (error) {
          errorHandler(reject, error);
          return null;
        });
    });
  }
  return null;
}
// Get User Access from Mongo DB
function getUserAccessCAR(userid) {
  return new Promise(function onThen(resolve, reject) {// NOSONAR
    axios
      .get(`${urlConfig.carApiUrl}enterprise/v1/access/getaccess/${userid}/${appId}`, {
        headers: {
          authorization: localStorage.getItem("appToken"),
          // authorization: localStorage.getItem("jwtToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      // eslint-disable-next-line
      .then(function (response) {
        if (response != null) {
          var result
          if (response.status === 200) {
            result = [];
            if (response.data.length > 0) {
              response.data.forEach(e => {
                if (e.app_id === appId) {
                  var r_mod = {};
                  r_mod.text = e.module_name;
                  r_mod.iconCss = e.var_icon_css;
                  r_mod.route = e.var_route;

                  r_mod.id = e.constant_id ? e.constant_id.toString() : '';
                  r_mod.attributes = (e.attributeList) ? e.attributeList : [];
                  if (e.items.length > 0) {
                    var p_mod_arr = [];
                    e.items.forEach(p_ele => {
                      if (p_ele.type !== 'attribute') {
                        var p_mod = {};
                        p_mod.text = p_ele.module_name;
                        p_mod.route = p_ele.var_route;
                        p_mod.id = p_ele.constant_id ? p_ele.constant_id.toString() : '';
                        if (typeof p_ele.items !== 'undefined') {
                          if (p_ele.items.length > 0) {
                            var m_mod_arr = [];
                            p_ele.items.forEach(m_ele => {
                              if (m_ele.type !== 'attribute') {
                                var m_mod = {};
                                m_mod.text = m_ele.module_name;
                                m_mod.route = m_ele.var_route;
                                m_mod.id = (m_ele.constant_id) ? m_ele.constant_id.toString() : 0;
                                m_mod_arr.push(m_mod);
                              }
                            });
                            if (m_mod_arr.length > 0)
                              p_mod.items = m_mod_arr;
                          }
                        }
                        p_mod_arr.push(p_mod);
                      }
                    });
                    if (p_mod_arr.length > 0)
                      r_mod.items = p_mod_arr;
                  }
                  result.push(r_mod);
                }
              });
            }
          }
          else {
            console.log('Error while getting access rights')
            return null;
          }
          return resolve(result);
        }
      })
      // eslint-disable-next-line
      .catch(function (error) {
        console.log('Acceess Rights Authentication Failed');
        errorHandler(reject, error);
        return false;
      });
  });
}
function getUserAccess(userid) {
  return new Promise(function onThen(resolve, reject) {
    axios
      .get(`${urlConfig.apiurl}useraccesslink/${userid}`, {
        headers: {
          authorization: localStorage.getItem("jwtToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      // eslint-disable-next-line
      .then(function (getUserAccessResponse) {
        if (getUserAccessResponse != null) {
          return resolve(getUserAccessResponse.data);
        }
      })
      // eslint-disable-next-line
      .catch(function (error) {
        errorHandler(reject, error);
        return null;
      });
  });
}

async function authenticateApplication() {
  const instanceOne = axios.create({
    httpsAgent: new https.Agent({
      rejectUnauthorized: false
    }),
    auth: {
      username: appId,
      password: appAuthPassword
    }
  });

  try {
    let token;
    const apiResp = await instanceOne.post(urlConfig.carApiUrl + "authentication");
    if (apiResp.data) token = apiResp.data;
    localStorage.setItem('appToken', token);
    return true;
  } catch (err) {
    console.log("Error in connecting Enterprise Access gateway: " + urlConfig.carApiUrl, err);
    return false;
  }
}

function deleteMeetingTemplateList(userid, templateid) {
  return new Promise(function onThen(resolve, reject) {
    axios
      .post(
        `${urlConfig.apiurl}meetingtemplate/delete/${userid}/${templateid}`,
        {},
        {
          headers: {
            authorization: localStorage.getItem("jwtToken"),
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      // eslint-disable-next-line
      .then(function (deleteMeetingTemplateListResponse) {
        if (deleteMeetingTemplateListResponse != null) {
          return resolve(deleteMeetingTemplateListResponse.data);
        }
      })
      // eslint-disable-next-line
      .catch(function (error) {
        errorHandler(reject, error);
        return null;
      });
  });
}
function getMeetingTemplateList(userid) {
  return new Promise(function onThen(resolve, reject) {
    axios
      .get(`${urlConfig.apiurl}meetingtemplate/${userid}`, {
        headers: {
          authorization: localStorage.getItem("jwtToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      // eslint-disable-next-line
      .then(function (getMeetingTemplateListResponse) {
        if (getMeetingTemplateListResponse != null) {
          return resolve(getMeetingTemplateListResponse.data);
        }
      })
      // eslint-disable-next-line
      .catch(function (error) {
        errorHandler(reject, error);
        return null;
      });
  });
}
function getMeetingTemplateDetails(userid, templateId) {
  return new Promise(function onThen(resolve, reject) {
    axios
      .get(
        `${urlConfig.apiurl}meetingtemplatedetails/${userid}/${templateId}`,
        {
          headers: {
            authorization: localStorage.getItem("jwtToken"),
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      // eslint-disable-next-line
      .then(function (getMeetingTemplateDetailsResponse) {
        if (getMeetingTemplateDetailsResponse != null) {
          return resolve(getMeetingTemplateDetailsResponse.data);
        }
      })
      // eslint-disable-next-line
      .catch(function (error) {
        errorHandler(reject, error);
        return null;
      });
  });
}

function getCorrectedTibData(intExportDataId) {
  return new Promise(function onThen(resolve, reject) {
    axios
      .get(`${urlConfig.tibrApiUrl}getCorrectedTibData/${intExportDataId}`, {
        headers: {
          authorization: localStorage.getItem("jwtToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      // eslint-disable-next-line
      .then(function (getCorrectedTibDataResponse) {
        if (getCorrectedTibDataResponse != null) {
          return resolve(getCorrectedTibDataResponse.data);
        }
      })
      // eslint-disable-next-line
      .catch(function (error) {
        errorHandler(reject, error);
        return null;
      });
  });
}
// eslint-disable-next-line
function getUploadedTibDataLog(authUser, activityType) {
  return new Promise(function onThen(resolve, reject) {
    axios
      .get(
        `${urlConfig.tibrApiUrl}getUploadedTibLog/${authUser}/${activityType}`,
        {
          headers: {
            authorization: localStorage.getItem("jwtToken"),
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      // eslint-disable-next-line
      .then(function (getUploadedTibDataLogResponse) {
        if (getUploadedTibDataLogResponse != null) {
          return resolve(getUploadedTibDataLogResponse.data);
        }
      })
      // eslint-disable-next-line
      .catch(function (error) {
        errorHandler(reject, error);
        return null;
      });
  });
}

// eslint-disable-next-line
function getAutoUploadedTibDataLog(authUser) {
  return new Promise(function onThen(resolve, reject) {
    axios
      .get(`${urlConfig.tibrApiUrl}getAutoUploadedTibLog/${authUser}`, {
        headers: {
          authorization: localStorage.getItem("jwtToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      // eslint-disable-next-line
      .then(function (getAutoUploadedTibDataLogResponse) {
        if (getAutoUploadedTibDataLogResponse != null) {
          return resolve(getAutoUploadedTibDataLogResponse.data);
        }
      })
      // eslint-disable-next-line
      .catch(function (error) {
        errorHandler(reject, error);
        return null;
      });
  });
}
function getNewFeaturesData() {
  return new Promise(function onThen(resolve, reject) {
    axios
      .get(`${urlConfig.apiurl}newfeatures`, {
        headers: {
          authorization: localStorage.getItem("jwtToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      // eslint-disable-next-line
      .then(function (getNewFeaturesDataResponse) {
        if (getNewFeaturesDataResponse != null) {
          return resolve(getNewFeaturesDataResponse.data);
        }
      })
      // eslint-disable-next-line
      .catch(function (error) {
        errorHandler(reject, error);
        return null;
      });
  });
}
function getWorkDaySettingList(userId, startDate, endDate, type) {
  return new Promise(function onThen(resolve, reject) {
    axios
      .get(
        `${urlConfig.apiurl}getworkdaysettinglist/${userId}/${startDate}/${endDate}/${type}`,
        {
          headers: {
            authorization: localStorage.getItem("jwtToken"),
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      // eslint-disable-next-line
      .then(function (getWorkDaySettingListResponse) {
        if (getWorkDaySettingListResponse != null) {
          return resolve(getWorkDaySettingListResponse.data);
        }
      })
      // eslint-disable-next-line
      .catch(function (error) {
        errorHandler(reject, error);
        return null;
      });
  });
}
/**
 * @author Vivek khodade
 * @param {*} userId
 * @description Get the Notification setting data.
 */
function getNotificationSettingList(userId) {
  return new Promise(function onThen(resolve, reject) {
    axios
      .get(`${urlConfig.apiurl}getNotifiactionSettingsData/${userId}`, {
        headers: {
          authorization: localStorage.getItem("jwtToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      // eslint-disable-next-line
      .then(function (getNotificationSettingListResponse) {
        if (getNotificationSettingListResponse != null) {
          return resolve(getNotificationSettingListResponse.data);
        }
      })
      // eslint-disable-next-line
      .catch(function (error) {
        errorHandler(reject, error);
        return null;
      });
  });
}
/**
 * @author Vivek Khodade
 * @param {*} candid
 * @param {*} authUserid
 * @description get the basic information of selected employee.
 */
function getEnployeeBasicInformation(empid, authUserid) {
  return new Promise(function onThen(resolve, reject) {
    axios
      .get(`${urlConfig.tmlcApiUrl}employee/${empid}/${authUserid}`, {
        headers: {
          authorization: localStorage.getItem("jwtToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      // eslint-disable-next-line
      .then(function (getEnployeeBasicInformationResponse) {
        if (getEnployeeBasicInformationResponse != null) {
          return resolve(getEnployeeBasicInformationResponse.data);
        }
      })
      // eslint-disable-next-line
      .catch(function (error) {
        errorHandler(reject, error.response.data.message);
        return null;
      });
  });
}
/**
 * @author Prashant Waphare
 * @param {*} candid
 * @param {*} authUserid
 * @description get the basic information of selected employee.
 */
function getHomeOfficeMasterData(id, type) {
  return new Promise(function onThen(resolve, reject) {
    axios
      .get(`${urlConfig.tmlcApiUrl}homemasterdata/${id}/${type}`, {
        headers: {
          authorization: localStorage.getItem("jwtToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      // eslint-disable-next-line
      .then(function (getHomeOfficeMasterDataResponse) {
        if (getHomeOfficeMasterDataResponse != null) {
          return resolve(getHomeOfficeMasterDataResponse.data.result);
        }
      })
      // eslint-disable-next-line
      .catch(function (error) {
        errorHandler(reject, error.response.data.message);
        return null;
      });
  });
}
// Prashant Waphare | 03-09-2020 | get list of home Office
function gethomeofficedetails(userid, empId) {
  return new Promise(function onThen(resolve, reject) {
    axios
      .get(`${urlConfig.tmlcApiUrl}gethomeofficedetails/${userid}/${empId}/0`, {
        headers: {
          authorization: localStorage.getItem("jwtToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      // eslint-disable-next-line
      .then(function (gethomeofficedetailsResponse) {
        if (gethomeofficedetailsResponse != null) {
          return resolve(gethomeofficedetailsResponse.data.result);
        }
      })
      // eslint-disable-next-line
      .catch(function (error) {
        errorHandler(reject, error.response.data.message);
        return null;
      });
  });
}
/**
 * @author Prashant Waphare | 03-09-2020
 * @param {*}  startDateTime
 * @param {*}  endDateTime
 * @param {*}  meetingTitle
 * @description generate authentication token first and then create teams meeting link.
 */
function meetingLinkAuthToken(startDateTime, endDateTime, meetingTitle,chairPersonId) {
  // eslint-disable-next-line
  return new Promise(async function onThen(resolve) {
    const response = await axios.get(
      `${urlConfig.apiurl}generatemeetinglink/${startDateTime}/${endDateTime}/${meetingTitle}/${chairPersonId}`
    );
    return resolve(response.data);
  });
}

/**
 * @author Suresh Patel | 20-12-2021
 * @param {*}  startDateTime
 * @param {*}  endDateTime
 * @param {*}  meetingTitle
 * @description generate authentication token first and then create teams meeting link.
 */
function getZoomMeeting(startDateTime, endDateTime, timeZone, meetingTitle, type, recurringObj, hostEmail, zoomRecurringMeetingDates) { // NOSONAR
  // eslint-disable-next-lin
  return new Promise(async function onThen(resolve) {
    // eslint-disable-next-line
    const response = await axios.post( // NOSONAR
      `${urlConfig.apiurl}generatezoommeetinglink`, {
      startDateTime,
      endDateTime,
      timeZone,
      meetingTitle,
      type,
      recurringObj,
      hostEmail,
      zoomRecurringMeetingDates
    }
    ).then((response) => { // NOSONAR
      return resolve(response.data);
    }).catch((error) => {

      return resolve({ paramError: error.response.data.message });
    })


  });
}

/**
 * @author Suresh Patel | 20-12-2021
 * @param {*}  zoomMeetingId
 * @description delete Zoom meeting.
 */
function deleteZoomMeeting(zoomMeetingId, occurrence_id, meetingId = "") {
  // eslint-disable-next-lin
  return new Promise(async function onThen(resolve) {
    const response = await axios.post(
      `${urlConfig.apiurl}deletezoommeetinglink`,
      {
        zoomMeetingId,
        occurrence_id,
        meetingId
      }
    );
    return resolve(response.data);
  });
}
function validateZoomMeeting(zoomMeetingId) {
  // eslint-disable-next-lin
  return new Promise(async function onThen(resolve) {
    const response = await axios.get(
      `${urlConfig.apiurl}validatezoommeetinglink/${zoomMeetingId}`
    );
    return resolve(response.data);
  });
}



function getOrgChartData(userid, yymm, type) {
  let userId = userid;
  let month = yymm;
  if (!userid || userid === 0) userId = 1000; // 1000 is not a user but a group company...'Cheers Interactive' under which Directors will be shown in ORG Chart
  if (!yymm || yymm < 0) month = 0;
  return new Promise(function onThen(resolve, reject) {
    axios
      .get(`${urlConfig.tmlcApiUrl}orgchart/${userId}/${month}/${type}`, {
        headers: {
          authorization: localStorage.getItem("jwtToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      // eslint-disable-next-line
      .then(function (getOrgChartDataResponse) {
        if (getOrgChartDataResponse != null) {
          return resolve(getOrgChartDataResponse.data);
        }
      })
      // eslint-disable-next-line
      .catch(function (error) {
        errorHandler(reject, error);
        return null;
      });
  });
}
function getOrgChartMasters(level) {
  const userId = 0;
  return new Promise(function onThen(resolve, reject) {
    axios
      .get(`${urlConfig.tmlcApiUrl}orgchartmasterdetails/${userId}/${level}`, {
        headers: {
          authorization: localStorage.getItem("jwtToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      // eslint-disable-next-line
      .then(function (getOrgChartMastersResponse) {
        if (getOrgChartMastersResponse != null) {
          return resolve(getOrgChartMastersResponse.data);
        }
      })
      // eslint-disable-next-line
      .catch(function (error) {
        errorHandler(reject, error);
        return null;
      });
  });
}
function getReportingMembers(userId) {
  return new Promise(function onThen(resolve, reject) {
    axios
      .get(`${urlConfig.tmlcApiUrl}checkreportingmembers/${userId}`, {
        headers: {
          authorization: localStorage.getItem("jwtToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      // eslint-disable-next-line
      .then(function (reportingMembersResponse) {
        if (reportingMembersResponse != null) {
          return resolve(reportingMembersResponse.data);
        }
      })
      // eslint-disable-next-line
      .catch(function (error) {
        errorHandler(reject, error);
        return null;
      });
  });
}

function getHomeOfficeDocument(file) {
  return new Promise(
    async function onThen(resolve, reject) {
      await axios.get(urlConfig.apiurl + `getHomeOfficeDocumenttm/${file}`,
        {
          headers: {
            'authorization': localStorage.getItem('jwtToken'),
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
        }
      ).then(function (response) {
        if (response != null) {
          return resolve(response.config.url)
        }
      }).catch(function (error) {
        errorHandler(reject, error)
        return null;
      });
    }
  )
}

function getOrgChartDataFilter(type,userid,memberId,buId,memberType,status, months, umtype) {
  let userId = userid;
  let month = months;
  if (!userid || userid === 0) userId = 1000; // 1000 is not a user but a group company...'Cheers Interactive' under which Directors will be shown in ORG Chart
  if (!months || months < 0) month = 0;
  return new Promise(function onThen(resolve, reject) {
    axios
      .get(`${urlConfig.tmlcApiUrl}orgchart/${type}/${userId}/${memberId}/${buId}/${memberType}/${status}/${month}/${umtype}`, {
        headers: {
          authorization: localStorage.getItem("jwtToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      // eslint-disable-next-line
      .then(function (getOrgChartDataResponse) {
        if (getOrgChartDataResponse != null) {
          return resolve(getOrgChartDataResponse.data);
        }
      })
      // eslint-disable-next-line
      .catch(function (error) {
        errorHandler(reject, error);
        return null;
      });
  });
}

function getOrgChartStructure(userid,buId) {
  let userId = userid;
  return new Promise(function onThen(resolve, reject) {
    axios
      .get(`${urlConfig.tmlcApiUrl}orgchart/orgstructure/${userId}/${buId}/0`, {
        headers: {
          authorization: localStorage.getItem("jwtToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      // eslint-disable-next-line
      .then(function (getOrgChartDataResponse) {
        if (getOrgChartDataResponse != null) {
          return resolve(getOrgChartDataResponse.data);
        }
      })
      // eslint-disable-next-line
      .catch(function (error) {
        errorHandler(reject, error);
        return null;
      });
  });
}
const services = {
  // Revealing Module Pattern
  getMasterData,
  getAttendeeList,
  getRoomList,
  getUserAvailability,
  getRoomAvailability,
  getIndMeetingDts,
  getShiftDetails,
  getUserSetting,
  getMeetingNotifiction,
  getUserDetails,
  getUserAccess,
  getUserAccessCAR,
  authenticateApplication,
  getMeetingTemplateList,
  getMeetingTemplateDetails,
  deleteMeetingTemplateList,
  getUploadedTibDataLog,
  getAutoUploadedTibDataLog,
  getCorrectedTibData,
  getNewFeaturesData,
  getWorkDaySettingList,
  getNotificationSettingList,
  getEnployeeBasicInformation,
  getHomeOfficeMasterData,
  gethomeofficedetails,
  meetingLinkAuthToken,
  getZoomMeeting,
  deleteZoomMeeting,
  validateZoomMeeting,
  getOrgChartData,
  getOrgChartMasters,
  // getAgendaDocument: getAgendaDocument,
  getHomeOfficeDocument: getHomeOfficeDocument,
  getReportingMembers,
  getOrgChartMasterData,
  getOrgChartDataFilter,
  getOrgChartStructure

};
// Added by Pooja
function getOrgChartMasterData(userid, yymm, type) {
  let userId = userid;
  return new Promise(function onThen(resolve, reject) {
    axios
      .get(`${urlConfig.tmlcApiUrl}orgchartmasterdetails/${userId}/masterInfo`, {
        headers: {
          authorization: localStorage.getItem("jwtToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then(function (getOrgChartDataResponse) {
        if (getOrgChartDataResponse != null) {
          return resolve(getOrgChartDataResponse.data);
        }
      })
      .catch(function (error) {
        errorHandler(reject, error);
        return null;
      });
  });

}
export default services;
