/* Author : Prashant Waphare
 * Date : 02-06-2020
 * Description: This file use for show Exit clearance employee List. (Functional Clearance)
 */
import React, { Component } from "react";
import NotificationContainer from "../../../../../components/notification/notifycontainer";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ExitClearanceFunctionalGrid from '../../../../../components/separation/exitclearancecomponents/exitclearancefunctionalgrid';
import separetionServices from "../../../../../services/entryexit/separationServices";
import Progressbar from '../../../../../components/progressbar/progressbar';
import { getFormatDateForGrid } from "../../../../other/commonfunction";
import { addUpdateSeparationDetails } from '../../../../../redux/actions/entryexit/separationaction';

class ExitClearanceGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clearanceList: null,
      showLoader: true
    }
    this.onCreated = this.onCreated.bind(this);
  }
  componentDidMount() {
    this.getClearanceList();

  }
  getClearanceList = () => {
    const { authUser } = this.props;
    try {
      const getClearanceList = Promise.resolve(
        separetionServices.getClearanceDetail(authUser, "list")
      );
      getClearanceList.then((result) => {
        if (result !== null) {
          this.setState({ clearanceList: getFormatDateForGrid(result), showLoader: false });
        } else {
          this.data = "error";
          this.setState({ showLoader: false });
        }
      });
    } catch (e) {
      // eslint-disable-next-line
      console.log(e);
      this.setState({ showLoader: false, clearanceList: [] });
    }
  };
  onCreated() {
    setTimeout(() => {
      if (this.acrdnbasic !== null) {
        // eslint-disable-next-line
        for (let i = 0; i <= this.acrdnbasic.items.length - 1; i++) {
          this.acrdnbasic.items[i].expanded = true;
        }
      }
    }, 500);
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.UpdateResignationStatus === 'LWDorNPSData' || nextProps.UpdateResignationStatus === 'StatusUpdate' || nextProps.UpdateResignationStatus === 'LeftStatus') {
      this.getClearanceList();
      this.props.addUpdateSeparationDetails({ UpdateResignationStatus: "" })
    }
  }
  render() {
    const { clearanceList, showLoader } = this.state;
    return (
      <div>
        <div
          style={{
            padding: 10,
          }}
        >
          <NotificationContainer />
          <div>
            <ExitClearanceFunctionalGrid clearanceList={clearanceList} />
            {
              showLoader &&
              <Progressbar />
            }
          </div>
        </div>
      </div>
    );
  }
}
ExitClearanceGrid.propTypes = {
  // eslint-disable-next-line
  authUser: PropTypes.string,
};
ExitClearanceGrid.defaultProps = {
  authUser: "",
};

const mapStateToProps = ({ auth, separationreducer }) => {
  const { authUser } = auth;
  const { UpdateResignationStatus } = separationreducer;
  return { authUser, UpdateResignationStatus };
};
export default connect(mapStateToProps, { addUpdateSeparationDetails })(ExitClearanceGrid);