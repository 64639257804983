import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogTitle } from "@material-ui/core";
import { FormValidator } from "@syncfusion/ej2-inputs";
import { connect } from 'react-redux';
import DateFnsUtils from "@date-io/date-fns";
import { getNotify, getDateFormat, decodeString, dencrypt, dateDiffIndays, addDays } from "../../app/other/commonfunction";
import {
  ERROR, SelectDateAndTimeForPerformanceReviewDiscussion, OKRMeetingSetMustBeGreaterThanCurrentTime,
  pleaseselectMeetingDateforthismeeting, OKRMeetingSetMustBeGreaterThanStartTime, pleaseselectMeetingStartTimeforthismeeting, pleaseselectMeetingEndTimeforthismeeting, pleaseselectTimeZoneforthismeeting
} from "../../assets/constants/constants";
import Tooltips from "../custommaterialtooltip/custommaterialtooltip";
import { MuiPickersUtilsProvider, KeyboardDatePicker, TimePicker, } from "@material-ui/pickers";
import { DialogContent, DialogActions } from "../customdialog/customdialog";
import Progressbar from "../../components/progressbar/progressbar"
import "../../app/layout/okrratinglayout/okrrating.css";
import OKRRatingServices from '../../services/okrrating/okrratingservices'
import dataService from '../../services/services';
import { addUpdateOkrRatingRedux, setOKRMeeting } from '../../redux/actions/okrrating/okrratingaction';
import { saveMeeting, updateMeetingBookingState } from "../../redux/actions/meetingbookingaction";
import { ComboBoxComponent } from "@syncfusion/ej2-react-dropdowns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt, faClock } from "@fortawesome/pro-light-svg-icons";

function SetMeetingModalkr(props) { //NOSONAR

  const [meetingStartTime, setMeetingStartTime] = useState(props.MeetingInfo.startTime === '' ? new Date() : props.MeetingInfo.startTime)
  const [meetingEndTime, setMeetingEndTime] = useState(props.MeetingInfo.endTime === '' ? new Date() : props.MeetingInfo.endTime)
  const [meetingDate, setMeetingDate] = useState(props.MeetingInfo.startTime === '' ? new Date() : props.MeetingInfo.startTime)
  const [showProgress, setShowProgress] = useState(false)
  const [timeZoneId, setTimeZoneId] = useState(props.MeetingInfo.timeZoneId)

  useEffect(() => {
    if (props.MeetingInfo.endTime === '') {
      let endTime = new Date(meetingStartTime);
      endTime = endTime.setMinutes(endTime.getMinutes() + 30);

      setMeetingEndTime(endTime)
    }
    if (props.MeetingInfo.timeZoneId === 0)
      setTimeZoneId(typeof dencrypt(localStorage.getItem("def_timezone")) === "undefined" ? "" : parseInt(dencrypt(localStorage.getItem("def_timezone")), 10))
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const { meetingStatus } = props
    if (meetingStatus === 'updated') {
      const { meetingId, authUser, empId, fyId, monthOfRating } = props
      const data = {
        "userId": authUser,
        "empId": empId,
        "fyId": fyId,
        "mnth": monthOfRating,
        "meetingId": meetingId
      }
      props.setOKRMeeting(data)
      props.updateMeetingBookingState({ meetingStatus: "" });
      setShowProgress(false)
    }
    // eslint-disable-next-line
  }, [props.meetingStatus])


  const handleTimeZoneChange = (event) => {
    const value = event.itemData == null ? "" : event.itemData.Id;
    setTimeZoneId(value);
  }

  const onCancel = () => { props.closeSetMeetingPopUp() };

  const onSave = () => {
    const options = {
      rules: {
        "meeting-date": { required: [true, pleaseselectMeetingDateforthismeeting] },
        "meeting-start-time": { required: [true, pleaseselectMeetingStartTimeforthismeeting] },
        "meeting-end-time": { required: [true, pleaseselectMeetingEndTimeforthismeeting] },
        "tz-select": { required: [true, pleaseselectTimeZoneforthismeeting] }
      },
    };

    if (new Date(meetingStartTime).getTime() >= new Date(meetingEndTime).getTime()) {
      options.rules["meeting-end-time"] = {
        required: [true, `*${OKRMeetingSetMustBeGreaterThanStartTime}`],
      };
    }

    setTimeout(() => {
      const addFormObject = new FormValidator("#AddSetMeetingModal", options);
      if (addFormObject.validate()) {
        if ((new Date(meetingStartTime).getTime() >= new Date(meetingEndTime).getTime())) {
          getNotify(ERROR, OKRMeetingSetMustBeGreaterThanStartTime);
          document.getElementById("meetingtimeError").innerHTML = OKRMeetingSetMustBeGreaterThanStartTime;
          return;
        }
        setShowProgress(true)
        const { MeetingInfo, authUser, empId } = props


        const okrRatingDashboardData = Promise.resolve(
          OKRRatingServices.getSetMeetingInfo(authUser, empId, MeetingInfo.Id)
        );
        okrRatingDashboardData.then((resultData) => {

          const mdtMeetingStartDate = getDateFormat(new Date(meetingDate), new Date(meetingStartTime));
          const mdtMeetingEndDate = getDateFormat(new Date(meetingDate), new Date(meetingEndTime));

          let data = {
            bulkAttendeesFlag: null,
            user_id: authUser,
            meeting_id: MeetingInfo.Id,
            meeting_title: resultData[0].meetingTitle,
            meeting_start_date: mdtMeetingStartDate,
            meeting_end_date: mdtMeetingEndDate,
            meeting_type_id: resultData[0].meetingType,
            meeting_source_id: null,
            meeting_media_id: resultData[0].meetingMedia,
            meeting_weblink_type: resultData[0].webLinkType === "" ? null : resultData[0].webLinkType,
            meeting_weblink: resultData[0].webLink === "" ? null : resultData[0].webLink,
            participate_code: resultData[0].participateCode === "" ? null : resultData[0].participateCode,
            time_zone_id: timeZoneId,
            meeting_rooms_id: "87",
            other_attendees: resultData[0].otherAttendees === "" ? null : resultData[0].otherAttendees,
            meeting_agenda: resultData[0].meetingAgenda,
            meeting_actionitems: resultData[0].meetingActionItems,
            meeting_setter_id: authUser,
            chairperson_id: resultData[0].chairPerson,
            client_id: null,
            contacts_id: null,
            service_products_id: null,
            btb_id: null,
            sub_btb_id: null,
            sub_cat_id: null,
            business_impact: null,
            is_marketing_meeting: null,
            meeting_location: resultData[0].meetingLocation === "" ? null : resultData[0].meetingLocation,
            meeting_attendee: resultData[0].meetingAttendee,
            meetingmode: "P",
            is_full_day: null,
            user_type: decodeString(localStorage.getItem("user_type")),
            countries_id: null,
            meeting_category_id: null,
            getExcludeSundayHoliday: null,
            meeting_dates: null,
            meeting_frequency: 1
          };
          if (resultData[0].webLinkType === "") {
            let generateAuthKey = dataService.meetingLinkAuthToken(new Date(mdtMeetingStartDate).toISOString(),
              new Date(mdtMeetingEndDate.toString()).toISOString(),
              resultData[0].meetingTitle.replace(/[^\w\s]/gi, '').toString());

            generateAuthKey.then((result) => {
              if (result !== null) {
                data.meeting_weblink_type = "2";
                data.meeting_weblink = result;
                props.saveMeeting(data);
              }
            })
          } else {
            props.saveMeeting(data);
          }
        
        });
      }
    }, 100);
  };

  const handleMeetingDateChange = (date) => {
    if (date.getDate() !== new Date(meetingDate).getDate()) {
      if (new Date().getTime() <= date.getTime()) {
        const d1 = `${(
          date.getMonth() + 1
        ).toString()}/${date
          .getDate()
          .toString()}/${date.getFullYear().toString()}`;
        const ds2 = `${(
          new Date(meetingStartTime).getMonth() + 1
        ).toString()}/${new Date(meetingStartTime)
          .getDate()
          .toString()}/${new Date(meetingStartTime)
            .getFullYear()
            .toString()}`;
        const de3 = `${(
          new Date(meetingEndTime).getMonth() + 1
        ).toString()}/${new Date(meetingEndTime)
          .getDate()
          .toString()}/${new Date(meetingEndTime)
            .getFullYear()
            .toString()}`;

        const differenceOfStartDate = dateDiffIndays(
          new Date(ds2),
          new Date(d1)
        ); // Math.ceil(dsd / (1000 * 60 * 60 * 24))//date.getDate() - new Date(props.meetingStartTime).getDate();
        const differenceOfEndDate = dateDiffIndays(new Date(de3), new Date(d1)); // Math.ceil(dse / (1000 * 60 * 60 * 24))//date.getDate() - new Date(props.meetingEndTime).getDate();

        const startDate = addDays(props.meetingStartTime, differenceOfStartDate);
        const endDate = addDays(props.meetingEndTime, differenceOfEndDate);

        setMeetingDate(date); setMeetingStartTime(startDate); setMeetingEndTime(endDate)

      } else if (date.getTime() <= new Date().getTime()) {
        const differenceOfStartDate =
          date.getDate() - new Date(meetingStartTime).getDate();
        const differenceOfEndDate =
          date.getDate() - new Date(meetingEndTime).getDate();

        const startDate = addDays(meetingStartTime, differenceOfStartDate);
        const endDate = addDays(meetingEndTime, differenceOfEndDate);

        setMeetingDate(date); setMeetingStartTime(startDate); setMeetingEndTime(endDate)
      }
    } else {
      setMeetingDate(date);
    }
  };

  const handleMeetingStartTimeChange = (startTime) => {
    if (new Date(startTime).getTime() >= new Date(meetingEndTime).getTime()) {
      let endTime = new Date(startTime);
      endTime = endTime.setMinutes(endTime.getMinutes() + 30);
      setMeetingEndTime(new Date(endTime));
    }
    if (new Date().getTime() >= new Date(startTime).getTime()) {
      getNotify(ERROR, OKRMeetingSetMustBeGreaterThanCurrentTime);
      setMeetingStartTime(meetingStartTime)
    } else {
      setMeetingStartTime(startTime)
    }
  };

  const handleMeetingEndTimeChange = (endTime) => {
    document.getElementById("meetingtimeError").innerHTML = "";
    if (new Date().getTime() >= new Date(endTime).getTime()) {
      getNotify(ERROR, OKRMeetingSetMustBeGreaterThanStartTime);
      setMeetingStartTime(meetingEndTime)
    } else {
      setMeetingEndTime(endTime)
    }
  };

  const { timeZone } = props

  return (
    <div style={{ paddingLeft: 8 }}>
      <Dialog open={true} fullWidth maxWidth="xs">
        <DialogTitle>
          <span className="add-new-self-header" style={{ color: "black", marginLeft: -4, fontFamily: "Circular Std ", fontSize: 18, }}>
            {"Set Meeting"}
          </span>
          <span style={{ padding: "0px 0px 0px 8px", position: "absolute" }}>
            <Tooltips titleMessage={SelectDateAndTimeForPerformanceReviewDiscussion} />
          </span>
        </DialogTitle>
        <DialogContent style={{ paddingTop: 8, paddingBottom: 10 }}>
          <form id="AddSetMeetingModal">
            <div className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
              {showProgress && <center> <Progressbar /> </center>}
              <div className="form-row">
                <div
                  className="col-md-8 "
                  style={{ paddingLeft: 10 }}>
                  <div className="e-input-group e-float-icon-left">
                    <FontAwesomeIcon
                      icon={faCalendarAlt}
                      className="mt-sm fa-lg"
                    />
                    <div className="e-input-in-wrap">
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          style={{ width: "100%" }}
                          inputVariant="outlined"
                          margin="normal"
                          id="meeting-date"
                          name="meeting-date"
                          format="dd-MMM-yyyy"
                          value={meetingDate}
                          onChange={handleMeetingDateChange}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                          data-msg-containerid="meetingdateError"
                          minDate={new Date()}
                        />
                      </MuiPickersUtilsProvider>
                      <div id="meetingdateError" style={{ marginLeft: -135 }} className="error-div" />
                    </div>
                  </div>
                </div>
                <div className="col-md-4" style={{ paddingBottom: 24, paddingLeft: 8, paddingRight: 10 }}>
                  <ComboBoxComponent
                    id="tz-select"
                    name="tz-select"
                    dataSource={timeZone}
                    fields={{ text: "Name", value: "Id" }}
                    change={handleTimeZoneChange}
                    placeholder="Time Zone"
                    value={timeZoneId}
                    popupHeight="220px"
                    cssClass="e-outline"
                    floatLabelType="Auto"
                    data-msg-containerid="timezoneError" />
                  <div style={{ marginLeft: "-252px" }} id="timezoneError" className="error-div" />
                </div>
              </div>

            </div>
            <div className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div className="form-row">
                <div style={{ paddingLeft: 10 }} className="col-md-4">
                  <div className="e-input-group e-float-icon-left">
                    <FontAwesomeIcon icon={faClock} className="mt-sm fa-lg" />
                    <div className="e-input-in-wrap" style={{ marginLeft: "16px" }} >
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <TimePicker
                          style={{ width: "100%" }}
                          className="mr-lg1"
                          inputVariant="outlined"
                          id="meeting-start-time"
                          name="meeting-start-time"
                          ampm={false}
                          value={meetingStartTime}
                          onChange={handleMeetingStartTimeChange}
                          data-msg-containerid="meetingtimeError"
                        />
                      </MuiPickersUtilsProvider>
                    </div>
                  </div>

                </div>
                <div style={{ paddingLeft: 10 }} className="col-md-4">
                  <div className="e-input-group e-float-icon-left">
                    <FontAwesomeIcon icon={faClock} className="mt-sm fa-lg" />
                    <div className="e-input-in-wrap" style={{ marginLeft: "13px" }} >
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <TimePicker
                          style={{ width: "100%" }}
                          className="mr-lg1"
                          inputVariant="outlined"
                          id="meeting-end-time"
                          name="meeting-end-time"
                          ampm={false}
                          value={meetingEndTime}
                          onChange={handleMeetingEndTimeChange}
                          data-msg-containerid="meetingtimeError"
                        />
                      </MuiPickersUtilsProvider>

                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div className="form-row">
                <div id="meetingtimeError" style={{ marginLeft: 40 }} className="error-div" />
              </div>
            </div>
          </form>
        </DialogContent>
        <DialogActions>
          <div style={{ padding: "0px 18px 16px 0px", float: "right" }}>
            <Button style={{ width: "120px" }} className="cancel-button" variant="contained" onClick={() => onCancel()}>
              Cancel
            </Button>
            <Button style={{ width: "120px" }} className="remove-button-shadow" color="primary" variant="contained"
              onClick={() => onSave()}
              disabled={showProgress}>
              Save
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapStateToProps = ({ auth, okrratingreducer, meetingmaster, meetingbooking }) => {
  const { authUser } = auth;
  const { timeZone } = meetingmaster;
  const { empId, fyId, monthOfRating } = okrratingreducer;
  const { meetingStatus, meetingId } = meetingbooking
  return { authUser, timeZone, empId, fyId, monthOfRating, meetingStatus, meetingId }
};
export default connect(mapStateToProps, { addUpdateOkrRatingRedux, saveMeeting, updateMeetingBookingState, setOKRMeeting })(
  SetMeetingModalkr
);

