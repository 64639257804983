/**
 * Author: Vivek Khodade.
 * Last Modify:08-May-2020
 * Modified By:Vivek Khodade
 */
import {
    SAVE_CANDIDATE_DATA, INITIATE_AND_REVOKE_All_CANDIDATE, SAVE_CANDIDATE_SALARY_DATA, SAVE_BASIC_INFORMATION, SAVE_BASIC_INFORMATION_REDUX,
    UPDATE_DOCUMENT_INFO,
    UPDATE_DOCUMENT_STATUS,
    Add_UPDATE_BASIC_INFO,
    GENERATE_APPOINTMENT_LETTER,
    UPDATE_FUNCTIONAL_ASSETS,
    UPDATE_STATUS_ONBOARDING,
    ADD_DOCUMENT_COMMENTS,
    SYNC_USER,
    MOVE_DOCUMENTS,
    SYNC_ENTERPRISE_PROFILE
} from '../../constant/entryexit/onboardingconstant';
import { SUBMIT_CANDIDATE_DOCUMENT } from '../../constant/entryexit/candidateconstant';
export const saveCandidateData = (data) => {
    return {
        type: SAVE_CANDIDATE_DATA,
        payload: data
    }
}
export const initiateAllcandidate = (data) => {
    return {
        type: INITIATE_AND_REVOKE_All_CANDIDATE,
        payload: data
    }
}
export const savaCandidateSalary = (data) => {
    return {
        type: SAVE_CANDIDATE_SALARY_DATA,
        payload: data
    }
}
export const saveBasicInformationInRedux = (data) => {
    return {
        type: SAVE_BASIC_INFORMATION_REDUX,
        payload: data
    }
}
export const saveBasicInformation = (data) => {
    return {
        type: SAVE_BASIC_INFORMATION,
        payload: data
    }
}
export const updateDocumentInfo = (data) => {
    return {
        type: UPDATE_DOCUMENT_INFO,
        payload: data
    }
}
export const updateDocumentStatus = (data) => {
    return {
        type: UPDATE_DOCUMENT_STATUS,
        payload: data
    }
}
export const addDocumentComments = (data) => {
    return {
        type: ADD_DOCUMENT_COMMENTS,
        payload: data
    }
}
export const addUpdateBasicInfo = (data) => {
    return {
        type: Add_UPDATE_BASIC_INFO,
        payload: data
    }
}
export const generateAppointmentLetter = (data) => {
    return {
        type: GENERATE_APPOINTMENT_LETTER,
        payload: data
    }
}
export const updateFunctionalAssets = (data) => {
    return {
        type: UPDATE_FUNCTIONAL_ASSETS,
        payload: data
    }
}
export const updateStatusOnBoardingResucer = (data) => {
    return {
        type: UPDATE_STATUS_ONBOARDING,
        payload: data
    }
}
export const submitCandidateDoc = (data) => {
    return {
        type: SUBMIT_CANDIDATE_DOCUMENT,
        payload: data
    }
}
export const syncUser = (data) => {
    return {
        type: SYNC_USER,
        payload: data
    }
}
export const moveDocuments = (data) => {
    return {
        type: MOVE_DOCUMENTS,
        payload: data
    }
}
export const saveToEnterpriseProfile = (data) => {
    return {
        type: SYNC_ENTERPRISE_PROFILE,
        payload: data
    }
}
