import { faCalendarDays, } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from 'react';

function OnLeaveEmployeeList(props) {
    const { tmLeave, firstLetter, secondLetter, symbol } = props;
    return (
        <>
            <div className="on-leave-individual">
                <div className="on-leave-side-by-side">
                    <div className="name-circle-container">
                        <div className="name-circle">{firstLetter}{secondLetter}</div>
                    </div>
                    <div className="on-leave-team-member-name-container smallResolutionText">
                        <div>{tmLeave.teamMemberName}</div>
                        <div className='normal-text'>{tmLeave.startDate} to {tmLeave.endDate} {` (${tmLeave.noOfLeaveDays} day)`}</div>
                    </div>
                </div>
                <div className="on-leave-calendar-icons">
                    <div className="on-leave-team-member-name-container">{symbol}</div>
                    <div className="smallResolutionText">
                        <FontAwesomeIcon
                            style={{ fontSize: 20, cursor: 'pointer' }}
                            icon={faCalendarDays}
                            onClick={() => props.handleCalendarDisplay(tmLeave)}
                        />
                    </div>
                </div>
            </div>

            {tmLeave.onLeave && tmLeave.onLeave.length <= 0 && <>
                <div className="upcoming-leaves">No Upcoming Leaves</div>
            </>}
        </>
    )
}

export default OnLeaveEmployeeList;