import React from 'react'

const AppChangeBody = ({ imgType, heading }) => {
  return (
    <div style={{ gap: '3rem', width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', color: '#4B4B55' }}>
      {imgType === 'loginInProgress' && <img src='/images/loginInProgress.svg' alt='Error Loading Module' />}
      {imgType === 'invalidURL' && <img src='/images/invalidUrl.svg' alt='Error Loading Module' />}
      <h2 style={{ fontSize: '18px', fontWeight: 'bold', fontFamily: "Circular TT Book, Regular", lineHeight: '32px', marginTop:0, marginBottom:0 }}>{heading}</h2>
    </div>
  )
}

export default AppChangeBody;