/* Author : Prashant Waphare
 * Date : 01-06-2020
 * Description: This file use for show Absconding employee List. (Absconding Manager conainar)
 */
import React, { Component } from "react";
import NotificationContainer from "../../../../../components/notification/notifycontainer";
import AbscondingManagerGrid from '../../../../../components/separation/abscondingcomponets/abscondingmanagergrid';
import separetionServices from "../../../../../services/entryexit/separationServices";
import Progressbar from '../../../../../components/progressbar/progressbar';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { addUpdateSeparationDetails } from "../../../../../redux/actions/entryexit/separationaction";
import { getFormatDateForGrid } from "../../../../other/commonfunction";

class AbscondingManager extends Component {
  constructor(props) {
    super(props);
    this.state = {
      abscondingData: []
    }
    this.onCreated = this.onCreated.bind(this);
  }

  componentDidMount() {
    this.getAbscondingListData();
  }
  UNSAFE_componentWillReceiveProps(newProps) {    
    if (newProps.updateAbscondingStatus === "updated") {
      this.getAbscondingListData();
      this.props.addUpdateSeparationDetails({ updateAbscondingStatus: "" });
    }
  }
  getAbscondingListData = () => {
    const { authUser } = this.props;
    try {
      const getAbscondingListDetail = Promise.resolve(
        separetionServices.getAbscondingListDetail(authUser, "mgrlist")
      );
      getAbscondingListDetail.then((result) => {
        if (result !== null) {
          this.setState({ abscondingData: getFormatDateForGrid(result) });          
        } else {
          this.data = "error";
        }
      });
    } catch (e) {
      // eslint-disable-next-line
      console.log(e);
    }
  };
  onCreated() {
    setTimeout(() => {
      if (this.acrdnbasic !== null) {
        // eslint-disable-next-line
        for (let i = 0; i <= this.acrdnbasic.items.length - 1; i++) {
          this.acrdnbasic.items[i].expanded = true;
        }
      }
    }, 500);
  }

  render() {
    const { abscondingData } = this.state;
    return (
      <div>
        <div
          style={{
            padding: 10,
            // backgroundColor: "#F4F3F5" 
          }}
        >
          <NotificationContainer />
          <div>

            {abscondingData !== null ? (
              <AbscondingManagerGrid getAbscondingListData={this.getAbscondingListData} abscondingData={abscondingData} />
            )
              :
              <div className='progress-bar-div'>
                <Progressbar />
              </div>
            }

          </div>
        </div>
      </div>
    );
  }
}
AbscondingManager.propTypes = {
  // eslint-disable-next-line
  status: PropTypes.string,
  authUser: PropTypes.string,
};
AbscondingManager.defaultProps = {
  authUser: "",
  status: "",
};

const mapStateToProps = ({ auth, onboardingreducer, separationreducer }) => {
  const { status } = onboardingreducer;
  const { authUser } = auth;
  const { updateAbscondingStatus } = separationreducer

  return { authUser, status, updateAbscondingStatus };
};
export default connect(mapStateToProps, { addUpdateSeparationDetails })(
  AbscondingManager
);
