
import {
    UPDATE_LNA_REDUX,
    SAVE_HOLIDAY_CLUSTER,
    APPLY_LEAVE,
    ADD_EDIT_WORKINGDAY_CLUSTER_DATA,
    ADD_EDIT_COLOUR_CLUSTER_DATA,
    ADD_EDIT_STATUTORY_CLUSTER_DATA,
    ADD_EDIT_EXCEPTIONAL_CLUSTER_DATA,
    ADD_EDIT_ROLE_CLUSTER_DATA,
    SAVE_ATTENDANCE_CLUSTER,
    ADMIN_EDIT_ADD_CAL,
    SAVE_SOURCE_CLUSTER,
    SAVE_DAY_BREAK_CLUSTER,
    SAVE_LEAVE_CLUSTER,
    SAVE_WEEKDAY_CLUSTER,
    SAVE_WORK_DAY_WINDOW_CLUSTER,
    SAVE_OPTIONAL_HOLIDAY_CLUSTER,
    SAVE_SYMBOL,
    SAVE_LEAVE_YEAR,
    SAVE_REGULARIZATION_DATA,
    SAVE_COMPOFF_DATA,
    SAVE_LEAVE_TRANSFER_DATA,
    CHANGE_LEAVE_APPROVAL_STATUS,
    SAVE_LEAVE_CANCEL_REASON,
    UPDATE_MASTER_ITEM_STATUS,
    SAVE_WEEK_OFF_SWAP_DATES,
    ADD_NOTIFICATION,
    SAVE_LOCK_UNLOCK_CLUSTER,
    SAVE_LEAVE_YEAR_CLUSTER,
    SAVE_PARTIAL_LEAVE_CANCEL_REASON,
    SAVE_WORKDAY_DAY_BREAK_MAP
} from '../../constant/lna/lnaconstant';

export const updateLnAData = (data) => {
    return {
        type: UPDATE_LNA_REDUX,
        payload: data
    }
}
export const saveHolidayCluster = (data) => {
    return {
        type: SAVE_HOLIDAY_CLUSTER,
        payload: data
    }
}

export const saveOptionalHolidayCluster = (data) => {
    return {
        type: SAVE_OPTIONAL_HOLIDAY_CLUSTER,
        payload: data
    }
}

export const applyLeave = (data) => {
    return {
        type: APPLY_LEAVE,
        payload: data
    }
}

export const addEditWorkingdayClusterData = (data) => {
    return {
        type: ADD_EDIT_WORKINGDAY_CLUSTER_DATA,
        payload: data
    }
}

export const addEditColourClusterData = (data) => {
    return {
        type: ADD_EDIT_COLOUR_CLUSTER_DATA,
        payload: data
    }
}

export const addEditRoleClusterData = (data) => {
    return {
        type: ADD_EDIT_ROLE_CLUSTER_DATA,
        payload: data
    }
}

export const addEditStatutoryClusterData = (data) => {
    return {
        type: ADD_EDIT_STATUTORY_CLUSTER_DATA,
        payload: data
    }
}
export const addEditExceptionalClusterData = (data) => {
    return {
        type: ADD_EDIT_EXCEPTIONAL_CLUSTER_DATA,
        payload: data
    }
}

export const saveAttendanceCluster = (data) => {
    return {
        type: SAVE_ATTENDANCE_CLUSTER,
        payload: data
    }
}
export const saveRegularizationData = (data) => {
    return {
        type: SAVE_REGULARIZATION_DATA,
        payload: data
    }
}
export const saveCompOffData = (data) => {
    return {
        type: SAVE_COMPOFF_DATA,
        payload: data
    }
}
export const saveLeaveTransferData = (data) => {
    return {
        type: SAVE_LEAVE_TRANSFER_DATA,
        payload: data
    }
}
export const saveSourceCluster = (data) => {
    return {
        type: SAVE_SOURCE_CLUSTER,
        payload: data
    }
}
export const saveDayBreakCluster = (data) => {
    return {
        type: SAVE_DAY_BREAK_CLUSTER,
        payload: data
    }
}
export const saveLeaveCluster = (data) => {
    return {
        type: SAVE_LEAVE_CLUSTER,
        payload: data
    }
}
export const saveWeekdayCluster = (data) => {
    return {
        type: SAVE_WEEKDAY_CLUSTER,
        payload: data
    }
}
export const saveWorkDayWindowCluster = (data) => {
    return {
        type: SAVE_WORK_DAY_WINDOW_CLUSTER,
        payload: data
    }
}
export const saveWorkdayDayBreakMapping = (data) => {
    return {
        type: SAVE_WORKDAY_DAY_BREAK_MAP,
        payload: data
    }
}

export const saveSymbol = (data) => {
    return {
        type: SAVE_SYMBOL,
        payload: data
    }
}

export const saveLeaveYear = (data) => {
    return {
        type: SAVE_LEAVE_YEAR,
        payload: data
    }
}

export const changeLeaveApprovalStatus = (data) => {
    return {
        type: CHANGE_LEAVE_APPROVAL_STATUS,
        payload: data
    }
}
export const saveLeaveCancelResponse = (data) => {
    return {
        type: SAVE_LEAVE_CANCEL_REASON,
        payload: data
    }
}
export const savePartialLeaveCancelResponse = (data) => {
    return {
        type: SAVE_PARTIAL_LEAVE_CANCEL_REASON,
        payload: data
    }
}
export const updateMasterItemStatus = (data) => {
    return {
        type: UPDATE_MASTER_ITEM_STATUS,
        payload: data
    }
}
export const saveWeekOffSwap = (data) => {
    return {
        type: SAVE_WEEK_OFF_SWAP_DATES,
        payload: data
    }
}
export const addNotification = (data) => {
    return {
        type: ADD_NOTIFICATION,
        payload: data
    }
}

export const saveLockUnlockCluster = (data) => {
    return {
        type: SAVE_LOCK_UNLOCK_CLUSTER,
        payload: data
    }
}
export const saveLeaveYearCluster = (data) => {
    return {
        type: SAVE_LEAVE_YEAR_CLUSTER,
        payload: data
    }
}