import "./default.css";
import * as React from "react";
import { connect } from "react-redux";
import queryString from "query-string";
import { MainClass } from "../../../mainclass";
import { userSignOut } from "../../../redux/actions/auth";
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { FormValidator } from "@syncfusion/ej2-inputs";
import { Button } from '@material-ui/core';
import "../../../assets/font-awesome/css/font-awesome.min.css";
import DocCheckList from "../../../components/employeeprofiletabs/checklist/docchecklist";
import {
  NEW_CANDIDATE_UPLOAD,
  CANDIDATE_UPLOAD_TITLE,
} from "../../../redux/constant/entryexit/candidateconstant";
import OnbordingServices from "../../../services/entryexit/onbordingServices";
import { saveBasicInformationInRedux, submitCandidateDoc } from "../../../redux/actions/entryexit/onboardingaction";
import { getDisplayDateOnly, getNotify, dencrypt } from "../../other/commonfunction";
import { WARNING, ERROR, SUCCESS, SUBMIT_TITLE, SUBMIT_CONFIRM_MSG, SUBMIT_ALREADY_MESSAGE, SUBMIT_MESSAGE, SUBMIT_WARNING_MESSAGE, FILL_ALL_FILED, documentSavedAsDraftSuccessfully, PleaseUploadProfilePicture } from "../../../assets/constants/constants";
import NotificationContainer from "../../../components/notification/notifycontainer";
import ProgressBar from "../../../components/progressbar/progressbar";
import Background  from '../../../assets/images/Group 3.png';
import BackgroundFooter  from '../../../assets/images/footerImage.png';
import ConfirmationPopup from "../../../utilities/confirmationpopup";
class CandUplLayout extends MainClass {
  constructor(props) {
    super(props);
    this.mediaQuery = "(min-width: 600px)";
    this.state = {
      AccountMenuItem: [
        {
          text: "",
          items: [{ text: "Sign out", id: "sign01" }],
        },
      ],
      isExpited: false,
      candidateName: '',
      checkedDisclamair: false,
      confirmOpen: false,
      isLoaded: false,
      height: 182,
      aadhaarNumber:null, 
      panNumber:null,
      isIndia:false, 
      imageName:""
    };
    this.expirationDate = ""
    this.enableDock = true;
    this.dockSize = "52px";
    this.width = "220px";
    this.target = ".main-content";

    document.title = CANDIDATE_UPLOAD_TITLE


    this.submitMessage = SUBMIT_ALREADY_MESSAGE

    this.onSubmit = this.onSubmit.bind(this)
    this.handleConfirmNo = this.handleConfirmNo.bind(this)
    this.handleConfirmYes = this.handleConfirmYes.bind(this)
  }

  async componentDidMount() {//NOSONAR
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));

    const params = queryString.parse(this.props.location.search);
    if (typeof params.candid === "undefined") {
      this.props.history.push("/error");
    } else {
      const candId = dencrypt(params.candid);
      this.props.saveBasicInformationInRedux({ candidateid: parseInt(candId) });
      const candidateBasicInfo = Promise.resolve(
        OnbordingServices.getCandidateInfo(candId)
      );
      candidateBasicInfo.then((result) => {
        if (result !== null) {
          this.expirationDate = getDisplayDateOnly(new Date(result.ExpirationDate))

          const AccountMenuItem = [
            {
              text: result.CandidateName,
              items: [{ text: "Sign out", id: "sign01" }],
            },
          ];
          this.props.saveBasicInformationInRedux({ isDocsSubmitted: result.DocsSubmitted === 'Y' ? true : false });
          this.setState({ AccountMenuItem, candidateName: result.CandidateName, isExpited: ((new Date() >= new Date(result.ExpirationDate)) ? true : false) });
          this.setState({ isLoaded: true, isIndia: result.BaseCountry==='India' ? true : false, panNumber:result.panNumber, aadhaarNumber:result.aadhaarNumber, imageName:result.imageName });
        } else {
          this.props.history.push("/error");
        }
      });
    }
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }


  onSubmit() {
    // check whether candidate uploaded all the required  documensts
    const {isIndia} =this.state;
    const candidateBasicInfo = Promise.resolve(OnbordingServices.getCandidateDocUplStatus(this.props.candidateid));
    candidateBasicInfo.then((result) => {
      if (result !== null) {
        if(isIndia){
         const options = {
          rules: {
              PANNumberErr: {
                required: [true, "Please enter PAN number"],
                regex: [
                  "[A-Z]{5}[0-9]{4}[A-Z]{1}",
                  "Please enter valid PAN number",
                ],
                maxLength: [10, "Please enter valid PAN number"],
              },
              AadhaarNumberErr: {
                required: [true, "Please enter Aadhaar number"],
                regex: [
                  "^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$",
                  "Please enter valid Aadhaar number",
                ],
              },
            },
          };
          const addFormObject = new FormValidator("#addCandiFormInfo", options);
          if(!addFormObject.validate()){
            getNotify(ERROR, FILL_ALL_FILED)
            return;
          }
        }
        let ele=document.getElementById('profilePic');
        if(result[0].Result === 'Yes' && ele!==null) {
          this.submitMessage = SUBMIT_MESSAGE
          this.setState({ confirmOpen: true })
        } else {
          if(ele===null){
            document.getElementById('profilePicError').innerHTML=PleaseUploadProfilePicture;
          }
          getNotify(WARNING, SUBMIT_WARNING_MESSAGE)
        }
      }
    });
  }

  handleConfirmYes = () => {
    const { aadhaarNumber, panNumber } = this.state;
    this.props.submitCandidateDoc({ candId: this.props.candidateid, aadhaarNumber:aadhaarNumber, panNumber:panNumber, type:'UPDATE' })
    this.setState({ confirmOpen: false })
  }
  saveAsDraft = ()=>{
    const { aadhaarNumber, panNumber,isIndia } = this.state;
    if(isIndia){
      const options = {
       rules: {
           PANNumberErr: {
             required: [true, "Please enter PAN number"],
             regex: [
               "[A-Z]{5}[0-9]{4}[A-Z]{1}",
               "Please enter valid PAN number",
             ],
             maxLength: [10, "Please enter valid PAN number"],
           },
           AadhaarNumberErr: {
             required: [true, "Please enter Aadhaar number"],
             regex: [
               "^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$",
               "Please enter valid Aadhaar number",
             ],
           },
         },
       };
       const addFormObject = new FormValidator("#addCandiFormInfo", options);
       if(addFormObject.validate()){
         this.props.submitCandidateDoc({ candId: this.props.candidateid, aadhaarNumber:aadhaarNumber, panNumber:panNumber, type:'DRAFT' })
      }else{
        getNotify(ERROR, FILL_ALL_FILED)
        return;
       }
     }else{
      getNotify(SUCCESS, documentSavedAsDraftSuccessfully)
     }
  }
  handleConfirmNo = () => {
    this.setState({ confirmOpen: false })
  }

  /**
  * Calculate & Update state of new dimensions
  */
  updateDimensions() {
    let update_width = window.innerWidth - 10;
    let update_height = window.innerHeight - 50;
    this.setState({ width: update_width, height: update_height });
  }
  getBasicInFormation = (e, type) => { // NOSONAR
    let options = {};
    switch (type) {
      case "PANNumber":
        this.setState({
          panNumber:e.value
        })
        options = {
          rules: {
            PANNumberErr: {
              required: [true, "Please enter PAN number"],
              regex: [
                "[A-Z]{5}[0-9]{4}[A-Z]{1}",
                "Please enter valid PAN number",
              ],
              maxLength: [10, "Please enter valid PAN number"],
            },
          },
        };
        break;
      case "AadhaarNumber":
        this.setState({
          aadhaarNumber:e.value
        })
        options = {
          rules: {
            AadhaarNumberErr: {
              required: [true, "Please enter Aadhaar number"],
              regex: [
                "^[2-9]{1}[0-9]{3}[0-9]{4}[0-9]{4}$",
                "Please enter valid Aadhaar number",
              ],
            },
          },
        };
        break;
      default:
    }
    setTimeout(() => {
      const addFormObject = new FormValidator("#addCandiFormInfo", options);
      addFormObject.validate();
    }, 100);
  };
  render() { // NOSONAR
    const {aadhaarNumber, panNumber, isIndia, imageName}=this.state;
    return (
      <div className="control-section">
        <div id="wrapper" style={{ borderColor: "2px solid red" }}>
          <div className="col-lg-12 col-sm-12 col-md-12">
            <div className="header-section dock-menu header-img" id="header">
              <ul className="header-list" 
                  style={{background:'none',backgroundImage: "url(" + Background + ")", zIndex:1111, width:'100%'}}
                >
                <li id="hamburger" className="icon-menu1 icon list" />
                <span className="brand" style={{marginLeft:10}}>{CANDIDATE_UPLOAD_TITLE}</span>
              </ul>
            </div>
            <div

              style={{ overflowY: 'auto', padding: "0px 20px 20px 20px", height: this.state.height-40 }}
            >
              {this.state.isLoaded &&
                <>
                  {!this.props.isDocsSubmitted &&
                    <>
                      {!this.state.isExpited && 
                      <div>
                        <div style={{ height: "100%", width: "100%" }}>
                            <div className='text-headers'>Welcome Aboard!</div>
                            <div className='text-normal-subtext'>We are excited at the prospect of having you onboard with us. It is our constant Endeavour to ensure challenging careers full of learning and growth for our team-members. We look forward to you having a long and fulfilling association with us. Irrespective of the brand you get associated with, we are sure that the journey would be an exciting one.</div>
                        </div>
                      </div>}
                      {!this.state.isExpited && <hr className='hr-style'/>}
                      {!this.state.isExpited && 
                      <div>
                        <div style={{ height: "100%", width: "100%" }}>
                            <div className='text-headers'>Documents Upload Section</div>
                            <div className='text-normal-subtext'>In case of multiple documents against any upload section, please scan the documents into a single PDF and upload as one single document.</div>
                            <div></div>
                            <div className='doc-note'>Note:</div>
                            <div>
                                <ul>
                                    <li className='doc-note-subtext'>Government Documents & address proof only in JPG, JPEG and PDF format. For rest document system accept PDF documents only.</li>
                                    <li className='doc-note-subtext'>Maximum File Size-10 MB</li>
                                    <li className='doc-note-subtext'>Documents upload link is valid till <strong>{this.expirationDate} 23:59:59 IST</strong></li>
                                    {
                                    isIndia &&
                                    <li className='doc-note-subtext'>Aadhaar Number and PAN Number are Mandatory </li>
                                    }
                                </ul>
                            </div>
                            {isIndia &&
                          <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                            <form id='addCandiFormInfo' method='post'>
                              <div className='col-lg-2 col-md-4 col-sm-8 col-xs-12'>
                                <div>
                                  <TextBoxComponent
                                    id="AadhaarNumber"
                                    value={aadhaarNumber}
                                    data-msg-containerid="AadhaarNumberErr"
                                    name="AadhaarNumberErr"
                                    cssClass="e-outline"
                                    floatLabelType="Auto"
                                    placeholder="Aadhaar Number *"
                                    blur={(e) => this.getBasicInFormation(e, "AadhaarNumber")}
                                    htmlAttributes={{ maxlength: 15 }}
                                  />
                                  <div
                                    id="AadhaarNumberErr"
                                    className="e-input-group e-float-icon-left error-div"
                                  />
                                </div>
                              </div>
                              <div className='col-lg-2 col-md-4 col-sm-8 col-xs-12'>
                                <div>
                                  <TextBoxComponent
                                    id="PANNumber"
                                    value={panNumber}
                                    data-msg-containerid="PANNumberErr"
                                    name="PANNumberErr"
                                    placeholder="PAN Number *"
                                    floatLabelType="Auto"
                                    cssClass="e-outline"
                                    blur={(e) => this.getBasicInFormation(e, "PANNumber")}
                                    htmlAttributes={{ maxlength: 15 }}
                                  />
                                  <div
                                    id="PANNumberErr"
                                    className="e-input-group e-float-icon-left error-div"
                                  />
                                </div>
                              </div>
                              </form>
                          </div>
                          }
                        </div>
                      </div>}
                      <div>
                        <div style={{ height: "100%", width: "100%" }}>
                          {this.props.candidateid > 0 && !this.state.isExpited &&
                          <div>
                            <DocCheckList candidateType={NEW_CANDIDATE_UPLOAD} imageName={imageName}/>
                            {/* <CheckList candidateType={NEW_CANDIDATE_UPLOAD} /> */}
                           </div> 
                          }
                          {
                            this.state.isExpited && <div className="submit-note">These link is expired on <b>{this.expirationDate}</b>.<br></br> Kindly reach out to your Talent Acquisition SPOC.</div>
                          }
                        </div>
                      </div>
                      <div>
                        {
                          this.props.candidateid > 0 && !this.state.isExpited &&
                          <div style={{ fontSize: 12 }}>
                            <div style={{ float: 'left', marginLeft: 1, paddingTop: 20 }} key="divDisc">
                              <CheckBoxComponent 
                                key="disclaimer"                                
                                checked={this.state.checkedDisclamair}
                                change={() => { this.setState({ checkedDisclamair: !this.state.checkedDisclamair }) }}
                              />
                            </div>
                            <div style={{ overflow: 'hidden', paddingLeft: 5, paddingTop: 25 }}>
                              I <b>{this.state.candidateName}</b>, hereby declare that all the attached documents are true, correct and valid. I understand that my offer has been made based on information furnished by me. Any discrepancy found in the copies of documents, certificates or reference check, the company retains the right to revoke the offer of employment.
                            </div>

                          </div>
                        }
                      </div>
                      {
                            this.props.candidateid > 0 && !this.state.isExpited &&
                        <div //className='col-lg-12 row pt-xl' 
                        className='candupl-submit-button-section'
                        >
                        <div>
                          
                            <Button autoFocus color={!this.state.checkedDisclamair ? "default" : "#4B4B55"} variant="contained" title={"Submit"}
                              style={{ float: 'left', marginBottom: "25px", color: this.state.checkedDisclamair ? "white" : "#c1baba", backgroundColor:this.state.checkedDisclamair ? '#4B4B55':'', width:110 }}
                              disabled={!this.state.checkedDisclamair}
                              onClick={this.onSubmit}>
                              Submit
                            </Button>
                         
                        </div>
                        <div>
                          <Button color={"default" } variant="contained"
                            style={{ float: 'left', marginLeft:10 ,marginBottom: "25px", color: "#4b4b55", backgroundColor:'#fff',  width:125 }}
                            // style={{ float: 'left', marginBottom: "25px", color: "#c1baba", backgroundColor:'#4B4B55', width:125 }}
                            onClick={()=>this.saveAsDraft()}
                            >
                            Save as Draft
                          </Button>
                        </div>
                        {/* <div>
                          <Button color={"default" } variant="contained"
                            style={{ float: 'left', marginLeft:10 ,marginBottom: "25px", color: "#4b4b55", backgroundColor:'#fff',  width:110 }}
                            onClick={()=>window.location.reload()}>
                            Cancel
                          </Button>
                        </div> */}
                      </div>
                      }
                      {
                      this.props.candidateid > 0 && !this.state.isExpited &&
                      <div className='col-lg-12'> 
                        <div className='candupl-last-note'>For any clarifications, please feel free to reach out to your recruiter.</div>
                      </div>}
                    </>
                  }
                </>
              }
              {/* Submit Message */}
              {this.props.isDocsSubmitted && 
              <div style={{ width: "100%" }}>
                  <center>
                    <div className='candupl-card-background ' style={{marginTop:'8%'}}/>
                    <div className='candupl-noun-check' style={{marginTop:'2%'}}/>
                  </center>
                  <div className='candupl-success mt-xl'>Submitted Successfully</div>
                  <div className='candupl-success-subtext mt-lg'>Thank you for submitting your documents. We will get in touch with you soon.</div>
              </div>
              }
            </div> 
            {!this.state.isLoaded && <ProgressBar />}
            <ConfirmationPopup
              showButton={{ type: "1" }}
              open={this.state.confirmOpen}
              button1function={() => this.handleConfirmYes()}
              button2function={() => this.handleConfirmNo()}
              headerMessage={SUBMIT_CONFIRM_MSG}
              button1Text={"Yes"}
              button2Text={"No"}
              handleConfirmClose={this.handleConfirmNo}
            />  


            {/* <div className="e-cell-footer footer col" style={{ height: 15, backgroundColor: '#000', position: 'absolute', bottom: 0, width: '100%', }}>
              <div className='col-xs-12'></div>
              <div className='footer-content col-xs-12' style={{ color: '#cac6c6', fontSize: 11, textAlign: 'center' }}>© {new Date().getFullYear()} Cheers Interactive (I) Pvt. Ltd.  All Rights Reserved.</div>
              <div className='col-xs-12'></div>
            </div> */}
            {!this.props.open_document_preview && !this.props.openDocumentPopup && <NotificationContainer />}
            {/* <Footer /> */}
          </div>
            <div className='footer-styles col-sm-12 col-xs-12 col-lg-12 col-md-12'> 
              <div className='col-lg-12 col-sm-12 col-xs-12 col-md-12'>
                <div className='col-lg-4 col-sm-1 col-xs-1 col-md-3'></div>
                <div className='col-lg-4 col-sm-10 col-xs-10 col-md-6'>
                   <img src={BackgroundFooter} style={{width: '100%', height:' 40px',objectFit: 'contain'}} alt=""/>
                </div>
                <div className='col-lg-4 col-sm-1 col-xs-0 col-md-3'></div>
              </div>
            </div>
        </div>
      </div>
    );
  }  
}

const mapStateToProps = ({ auth, onboardingreducer }) => {
  const { candidateid, isDocsSubmitted, open_document_preview, openDocumentPopup } = onboardingreducer;
  const { authUser } = auth;
  return { authUser, candidateid, isDocsSubmitted, open_document_preview, openDocumentPopup };
};
export default connect(mapStateToProps, {
  userSignOut,
  saveBasicInformationInRedux,
  submitCandidateDoc,
})(CandUplLayout);
