/**
 * Author: Abdulla Shaikh.
 * Created:21-Feb-2023
 * @description to view all HelpDesk Basic Info
 */

import React from 'react';
import ReactHtmlParser from 'react-html-parser'
import LabelText from '../../utilities/labeltext';
import "./helpdesk.css";
import AttachmentComponent from './attachmentcomponent';
import PropTypes from "prop-types";
import { format } from 'date-fns';


function BasicInfoHelpDesk({ functionName, category, subCategory, subject, priority, clientImpact, description, assignedBy, assignedTo, resolvedBy, resolvedOn, status, attachments }) {

  return (
    <div style={{display: 'flex', flexDirection: 'column'}}>
      <div >
        <div className="col-xs-12 col-sm-4 col-lg-4 col-md-4 " >
          <LabelText label={"Function"} text={functionName} />
        </div>
        <div className="col-xs-12 col-sm-4 col-lg-4 col-md-4 pl-0">
          <LabelText label={"Category"} text={category} />
        </div>
        <div className="col-xs-12 col-sm-4 col-lg-4 col-md-4">
          <LabelText label={"Sub Category"} text={subCategory} />
        </div>
      </div>
      <div className="pt-md pr-md pl-md">
        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
          <LabelText label={"Subject"} text={subject} />
        </div>
      </div>
      <div className="pt-md">
        <div className="col-xs-12 col-sm-4 col-lg-4 col-md-4">
          <LabelText label={"Priority"} text={priority} />
        </div>
        <div className="col-xs-12 col-sm-4 col-lg-4 col-md-4">
          <LabelText label={"Client Impact"} text={clientImpact} />
        </div>
      </div>
      <div className="pt-md pr-md pl-md" >
        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 pb-md" style={{ display: "flex", flexDirection: "column", borderBottom: "1px solid #dbdbdd" }}>
          <div className={"helpdesk-text pt-md"}>{"Description"}</div>
          <div className={"helpdesk-value"} id="helpdesk-ticket-description">{(description || description !== '') ? ReactHtmlParser(description) : ''}</div>
        </div>
      </div>
      <div className="pr-md pl-md" style={{display: 'flex', flexDirection: 'row', justifyContent:'space-between'}}>
        {/* <LabelText label={"Assigned By"} text={assignedBy ? assignedBy : " - "} /> */}
        <LabelText label={"Assigned To"} text={assignedTo ? assignedTo : " - "} />
        {/* <LabelText label={"Resolved By"} text={resolvedBy ? resolvedBy : " - "} /> */}
        <LabelText label={"Closed On"} text={resolvedOn} />
        <LabelText label={"Status"} text={status} />
      </div>
      <div className="pt-md pr-md ">
        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
          <div className="helpdesk-text pt-md pr-md pl-md">Uploaded Documents</div>
        </div>
      </div>
      <div className='pl-md pr-md basic-info-attachments'>
        {attachments.map(attachment => 
          <AttachmentComponent key={attachment.FileID} id={attachment.FileID} url={attachment.Url} fileName={attachment.FileName} dateTime={format(new Date(attachment.CommentDate ?? ''), 'dd-LLL-yyyy hh:mm')} showAttachment />
        )}
      </div>
    </div>
  )
}

BasicInfoHelpDesk.propTypes = {
  functionName: PropTypes.string,
  category: PropTypes.string,
  subCategory: PropTypes.string,
  subject: PropTypes.string,
  priority: PropTypes.string,
  clientImpact: PropTypes.string,
  description: PropTypes.string,
  assignedBy: PropTypes.string,
  assignedTo: PropTypes.string,
  resolvedBy: PropTypes.string,
  resolvedOn: PropTypes.string,
  status: PropTypes.string,
  attachments: PropTypes.array
}

BasicInfoHelpDesk.defaultProps = {
  functionName: "",
  category: "",
  subCategory: "",
  subject: "",
  priority: "",
  clientImpact: "",
  description: null,
  assignedBy: "",
  assignedTo: "",
  resolvedBy: "",
  resolvedOn: "",
  status: "",
  attachments: []
}

export default BasicInfoHelpDesk;
