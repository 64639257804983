import axios from "axios";
import {
  urlConfig,
} from "../config/config";
import { dencrypt } from '../app/other/commonfunction'

function errorHandler(reject, error) {
  // eslint-disable-next-line
  console.dir(error);
  reject("operation failed");
}

function getMeetings(
  authUser,
  pStartDate,
  pEndDate,
  pType,
  pMeetingId,
  otherUser
) {
  return new Promise(function onThen(resolve, reject) {
    axios
      .get(
        `${urlConfig.apiurl
        }meetings/${authUser}/${pStartDate}/${pEndDate}/${pType}/${dencrypt(localStorage.getItem('defActualDays'))}${pMeetingId > 0 ? `/${pMeetingId}` : "/0" // NOSONAR
        }${
        // eslint-disable-next-line
        otherUser === "" || typeof otherUser === undefined
          ? ""
          : `/${otherUser}` // NOSONAR
        }`,
        {
          headers: {
            authorization: localStorage.getItem("jwtToken"),
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      )
      // eslint-disable-next-line
      .then(function (response) {
        if (response != null) {
          return resolve(response.data.result);
        }
      })
      // eslint-disable-next-line
      .catch(function (error) {
        errorHandler(reject, error);
        return null;
      });
  });
}

const schedularservice = {
  // Revealing Module Pattern
  getMeetings,
};

export default schedularservice;
