import React, { Component } from 'react'
import RouteImage1 from '../../../assets/images/route1.jpg'
export default class Route66 extends Component {
    render() {
        return (
            <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                    <div className='row mt-xl ml-sm mr-sm'>
                            <div className="col-lg-12 col-md-12" style={{padding:30}}>
                                <img src={RouteImage1} style={{objectFit:'contain', width:'100%', height:'fit-content'}} alt="Route 66"/>
                            </div>                            
                    </div>
            </div>
        )
    }
}
