// Author : Suresh R. Sargam
// Date : 11-Dec-2019
// Description: Footer for Meeting Details Pop and self Meeting Addtion

import React from "react";
import Button from "@material-ui/core/Button";

function Footer(props) {
  const { scheduleObj } = props;
  const handleBreak = () => {
    // eslint-disable-next-line
    props.addBreakMeeting(props.data, false);
  };
  const addMoreDetails = (e) => {
    try {
      const cellDetails = scheduleObj.getCellDetails(
        scheduleObj.getSelectedElements()
      );
      const addObj = {};
      addObj.Id = scheduleObj.getEventMaxID();
      addObj.Subject = "";
      addObj.StartTime = new Date(+cellDetails.startTime);
      addObj.EndTime = new Date(+cellDetails.endTime);
      addObj.groupIndex = cellDetails.groupIndex;
      const quickPopup = scheduleObj.element.querySelector(
        ".e-quick-popup-wrapper"
      );
      const isCellPopup = quickPopup.firstElementChild.classList.contains(
        "e-cell-popup"
      );
      const eventDetails = isCellPopup
        ? addObj
        : scheduleObj.activeEventData.event;
      let currentAction = isCellPopup ? "Add" : "Save";
      cellDetails.StartTime = new Date(+cellDetails.startTime);
      cellDetails.EndTime = new Date(+cellDetails.endTime);
      props.addMeetingQuickInfo(cellDetails, e, eventDetails, currentAction);
    } catch (error) {
      console.log('error addMoreDetails', error);
    }
  }
  return (
    <>
      <div>
        {
          // eslint-disable-next-line
          props.elementType === "cell" ? (
            <div className="e-quick-event-strip">
              <div className="e-cell-footer pr-md">
                <div className="text-center">
                  <Button
                    id="Save"
                    className="e-event-create text-light"
                    variant="contained"
                    title="Save"
                    color="primary"
                    size="small"
                    onClick={addMoreDetails}
                  >
                    Save
                  </Button>
                  <Button
                    id="MoreDetails"
                    className="e-event-details text-light ml-sm"
                    variant="contained"
                    title="More Details"
                    color="secondary"
                    size="small"
                    onClick={addMoreDetails}
                  >
                    More Details
                  </Button>
                  <Button
                    className="e-event-create take-a-break"
                    onClick={() => {
                      handleBreak();
                    }}
                    variant="contained"
                    title="Take a Break"
                    size="small"
                    style={{ color: "black" }}
                  >
                    Take a Break
                  </Button>
                </div>
              </div>
            </div>
          ) : (
            <div className="e-event-footer">
              <div
                className="event-footer-strip"
                style={{ background: props.PrimaryColor, marginLeft: 0 }}
              />
            </div>
          )

        }
      </div>
    </>
  );
}

export default Footer;
