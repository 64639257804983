/**
 * Author Name :- Prashant Waphare
 * Date : 16-04-2020
 * Description :- This file use for show prejoining candidate list
 */
import * as React from "react";
import queryString from "query-string";
import { closest, isNullOrUndefined } from "@syncfusion/ej2-base";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Filter,
  Inject,
  VirtualScroll,
  Sort,
  Page,
  ExcelExport,
  Toolbar
} from "@syncfusion/ej2-react-grids";
import {
  ButtonComponent,
  CheckBoxComponent,
} from "@syncfusion/ej2-react-buttons";
import PropTypes from "prop-types";
import "../../app/containers/tmlc/onboarding/onboarding.css";
import { connect } from "react-redux";
import { Button, Dialog } from "@material-ui/core";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { Redirect } from "react-router-dom";
import OnbordingToBeOffer from "../../services/entryexit/onbordingServices";
import { DateFilterformat } from '../../config/config';
import {
  DialogContent,
  DialogActions,
  DialogTitle,
} from "../customdialog/customdialog";
import { getFormatDateForGrid, getNotify, getDisplayDateOnly, encrypt, decodeString, encodeString, encryptMethod } from "../../app/other/commonfunction";
import { WARNING, SUCCESS } from "../../assets/constants/constants";
import {
  initiateAllcandidate,
  updateStatusOnBoardingResucer,
} from "../../redux/actions/entryexit/onboardingaction";

import Progressbar from "../progressbar/progressbar";
import ErrorMessage from "../errormessage/errormessage";
import EmployeeNameTemplate from "../tobeoffercondicate/employeenametemplate";
import { PRE_OFFERE } from "../../redux/constant/meetingcategoryconstant";
import { setMeetingCategory } from '../../redux/actions/meetingcategoryaction';

import { getGridColumn, CANDIDATE_NAME, IT, ADMIN, FINANCE, SOFTWARE, CANDIDATE_STATUS, PEOPLE_TEAM, DATE_OF_JOINING, DAYS_TO_JOIN, BAND, READY_TO_JOIN_EMPLOYEE_CODE } from "../../app/other/gridcolumns";
import { MuiPickersUtilsProvider, KeyboardDatePicker, } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import TextField from "@material-ui/core/TextField";

function adminTemplate(props) {
  const { AdminCheckListStatus, UpdatedAdminChecklist, AdminChecklist } = props;
  if (AdminCheckListStatus === "Completed") {
    return (
      <div style={{ borderLeft: "3px solid green", paddingLeft: "5px" }}>
        <span id="">{`${UpdatedAdminChecklist}/${AdminChecklist}`}</span>
        <br />
        <span id="">{AdminCheckListStatus}</span>
      </div>
    );
  }
  if (AdminCheckListStatus === "Pending") {
    return (
      <div style={{ borderLeft: "3px solid gray", paddingLeft: "5px" }}>
        <span id="">{`${UpdatedAdminChecklist}/${AdminChecklist}`}</span>
        <br />
        <span id="">{AdminCheckListStatus}</span>
      </div>
    );
  }
  if (AdminCheckListStatus === "Partial") {
    return (
      <div style={{ borderLeft: "3px solid #f68104", paddingLeft: "5px" }}>
        <span id="">{`${UpdatedAdminChecklist}/${AdminChecklist}`}</span>
        <br />
        <span id="">{AdminCheckListStatus}</span>
      </div>
    );
  }
  return false;
}
function itTemplate(props) {
  const { ITCheckListStatus, UpdatedITChecklist, ITChecklist } = props;
  if (ITCheckListStatus === "Completed") {
    return (
      <div style={{ borderLeft: "3px solid green", paddingLeft: "5px" }}>
        <span id="">{`${UpdatedITChecklist}/${ITChecklist}`}</span>
        <br />
        <span id="">{ITCheckListStatus}</span>
      </div>
    );
  }
  if (ITCheckListStatus === "Pending") {
    return (
      <div style={{ borderLeft: "3px solid gray", paddingLeft: "5px" }}>
        <span id="">{`${UpdatedITChecklist}/${ITChecklist}`}</span>
        <br />
        <span id="">{ITCheckListStatus}</span>
      </div>
    );
  }
  if (ITCheckListStatus === "Partial") {
    return (
      <div style={{ borderLeft: "3px solid #f68104", paddingLeft: "5px" }}>
        <span id="">{`${UpdatedITChecklist}/${ITChecklist}`}</span>
        <br />
        <span id="">{ITCheckListStatus}</span>
      </div>
    );
  }
  return false;
}
function peopleTeamTemplate(props) {
  const {
    PeopleCheckListStatus,
    UpdatedPeopleChecklist,
    PeopleChecklist,
  } = props;
  if (PeopleCheckListStatus === "Completed") {
    return (
      <div style={{ borderLeft: "3px solid green", paddingLeft: "5px" }}>
        <span id="">{`${UpdatedPeopleChecklist}/${PeopleChecklist}`}</span>
        <br />
        <span id="">{PeopleCheckListStatus}</span>
      </div>
    );
  }
  if (PeopleCheckListStatus === "Pending") {
    return (
      <div style={{ borderLeft: "3px solid gray", paddingLeft: "5px" }}>
        <span id="">{`${UpdatedPeopleChecklist}/${PeopleChecklist}`}</span>
        <br />
        <span id="">{PeopleCheckListStatus}</span>
      </div>
    );
  }
  if (PeopleCheckListStatus === "Partial") {
    return (
      <div style={{ borderLeft: "3px solid #f68104", paddingLeft: "5px" }}>
        <span id="">{`${UpdatedPeopleChecklist}/${PeopleChecklist}`}</span>
        <br />
        <span id="">{PeopleCheckListStatus}</span>
      </div>
    );
  }
  return false;
}
function financeTemplate(props) {
  const {
    FinanceCheckListStatus,
    UpdatedFinanceChecklist,
    FinanceChecklist,
  } = props;
  if (FinanceCheckListStatus === "Completed") {
    return (
      <div style={{ borderLeft: "3px solid green", paddingLeft: "5px" }}>
        <span id="">{`${UpdatedFinanceChecklist}/${FinanceChecklist}`}</span>
        <br />
        <span id="">{FinanceCheckListStatus}</span>
      </div>
    );
  }
  if (FinanceCheckListStatus === "Pending") {
    return (
      <div style={{ borderLeft: "3px solid gray", paddingLeft: "5px" }}>
        <span id="">{`${UpdatedFinanceChecklist}/${FinanceChecklist}`}</span>
        <br />
        <span id="">{FinanceCheckListStatus}</span>
      </div>
    );
  }
  if (FinanceCheckListStatus === "Partial") {
    return (
      <div style={{ borderLeft: "3px solid #f68104", paddingLeft: "5px" }}>
        <span id="">{`${UpdatedFinanceChecklist}/${FinanceChecklist}`}</span>
        <br />
        <span id="">{FinanceCheckListStatus}</span>
      </div>
    );
  }
  return false;
}
function softwareTemplate(props) {
  const {
    SoftwareCheckListStatus,
    UpdatedSoftwareChecklist,
    SoftwareChecklist,
  } = props;
  if (SoftwareCheckListStatus === "Completed") {
    return (
      <div style={{ borderLeft: "3px solid green", paddingLeft: "5px" }}>
        <span id="">{`${UpdatedSoftwareChecklist}/${SoftwareChecklist}`}</span>
        <br />
        <span id="">{SoftwareCheckListStatus}</span>
      </div>
    );
  }
  if (SoftwareCheckListStatus === "Pending") {
    return (
      <div style={{ borderLeft: "3px solid gray", paddingLeft: "5px" }}>
        <span id="">{`${UpdatedSoftwareChecklist}/${SoftwareChecklist}`}</span>
        <br />
        <span id="">{SoftwareCheckListStatus}</span>
      </div>
    );
  }
  if (SoftwareCheckListStatus === "Partial") {
    return (
      <div style={{ borderLeft: "3px solid #f68104", paddingLeft: "5px" }}>
        <span id="">{`${UpdatedSoftwareChecklist}/${SoftwareChecklist}`}</span>
        <br />
        <span id="">{SoftwareCheckListStatus}</span>
      </div>
    );
  }
  return false;
}
function statusdetails(props) {
  const { CandidateCheckListStatus } = props;
  if (CandidateCheckListStatus === "Select All") {
    return <span>Select All</span>;
  }
  if (CandidateCheckListStatus === "Completed") {
    return (
      <div
        style={{ borderLeft: "3px solid green", padding: "7px 0px 8px 5px" }}
      >
        <span id="Trusttext">Completed</span>
        <br />
        {/* <span >Completed</span> */}
      </div>
    );
  }
  if (CandidateCheckListStatus === "Pending") {
    return (
      <div style={{ borderLeft: "3px solid gray", padding: "7px 0px 8px 5px" }}>
        {/* <span id="Trusttext">{"3/5"}</span><br /> */}
        <span>Pending</span>
      </div>
    );
  }
  if (CandidateCheckListStatus === "Partial") {
    return (
      <div
        style={{ borderLeft: "3px solid #f68104", padding: "7px 0px 8px 5px" }}
      >
        {/* <span id="Trusttext"></span><br />  */}
        {/* className="statustemp e-inactivecolor"  */}
        <span>Partial</span>
      </div>
    );
  }
  return false;
}
class PreJoining extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      offerChangeStatus: [],
      revokeChangeStatus: [],
      openDialog: false,
      openRevokeDialog: false,
      masterDataRevokrReason: null,
      getComment: null,
      revokeReasonID: null,
      revokeDate: null,
      redirect: null,
      docStatus: "",
      height: (window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight) - 290,
      gridHeight: (window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight) - 290,
      selectedData: [],
      joiningDate: null,
      openDialogGenerateEmpCode: false,
    };
    this.height =
      (window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight) - 290;
    this.width =
      (window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth) - 100;
    this.dReady = false;
    this.dtTime = false;
    this.isDataBound = false;
    this.isDataChanged = true;
    this.dropSlectedIndex = null;
    this.check = {
      type: "CheckBox",
    };
    this.select = {
      persistSelection: true,
      type: "Multiple",
      checkboxOnly: true,
    };
    this.Filter = {
      type: "Menu",
    };
    this.status = {
      type: "CheckBox",
      itemTemplate: statusdetails,
    };
    this.pageOptions = {
      pageSize:
        (window.innerHeight ||
          document.documentElement.clientHeight ||
          document.body.clientHeight) > 700
          ? 10
          : 5,
      pageCount: 5,
    };
    this.name = {
      type: "CheckBox",
    };
    this.toolbarOptions = [
      // 'ExcelExport',
      { text: 'Excel Export', tooltipText: 'Excel Export', id: 'ExcelExport', align: 'Right', prefixIcon: 'e-export-excel' }
    ];
    this.employeeNameTemplate = this.employeeNameTemplate.bind(this);
    this.navigateToAddBasicInformation = this.navigateToAddBasicInformation.bind(
      this
    );
    this.onQueryCellInfoPreJoining = this.onQueryCellInfoPreJoining.bind(this);
    this.onLoad = this.onLoad.bind(this);
    this.onComplete = this.onComplete.bind(this);
    this.onDataBound = this.onDataBound.bind(this);
  }
  /**
 * Calculate & Update state of new dimensions
 */
  updateDimensions() {
    const { changedData } = this.state;
    let update_width = window.innerWidth - 100;
    let update_height = window.innerHeight - 140;
    let gridHeight = window.innerHeight - 345;
    this.setState({ width: update_width, height: update_height, gridHeight: gridHeight, changedData: !changedData });
  }
  componentDidMount() {
    const { getReadyToJoinTabData } = this.props;
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
    this.getMasterData();
    getReadyToJoinTabData();
    setTimeout(() => {
      this.gridHeaderDynamic()
    }, 1000)
  }
  gridHeaderDynamic = () => {
    let ele = document.getElementsByClassName("e-headercelldiv");
    for (let i in ele) {
      if (!isNaN(parseInt(i, 10))) {
        let index = parseInt(i, 10);
        ele[index].classList.add("grid-left-header")
      }
    }
  }
  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.status === "updated") {
      // eslint-disable-next-line
      this.props.updateStatusOnBoardingResucer({ status: "" });
      this.props.getReadyToJoinTabData();
    }
  }
  // eslint-disable-next-line
  onQueryCellInfoPreJoining(args) {
    const { cell } = args;
    cell.setAttribute("style", "font-size:14px !important;text-align: left !important; padding-left:18px !important");
  }

  onDataBound() {
    clearTimeout(this.clrIntervalFun);
    clearInterval(this.intervalFun);
    this.dtTime = true;
  }
  // eslint-disable-next-line
  onComplete(args) {
    if (args.requestType === "filterchoicerequest") {
      if (
        args.filterModel.options.field === "Trustworthiness" ||
        args.filterModel.options.field === "Rating" ||
        args.filterModel.options.field === "Status"
      ) {
        const span = args.filterModel.dialogObj.element.querySelectorAll(
          ".e-selectall"
        )[0];
        if (!isNullOrUndefined(span)) {
          closest(span, ".e-ftrchk").classList.add("e-hide");
        }
      }
    }
  }

  onChange() {
    this.ddObj.hidePopup();
    this.gridInstance.showSpinner();
    this.dropSlectedIndex = null;
    clearTimeout(this.clrIntervalFun2);
    this.clrIntervalFun2 = setTimeout(() => {
      this.isDataChanged = true;
      this.stTime = null;
      const contentElement = this.gridInstance.contentModule.getPanel()
        .firstChild;
      contentElement.scrollLeft = 0;
      contentElement.scrollTop = 0;
      this.gridInstance.pageSettings.currentPage = 1;
      this.stTime = performance.now();
      this.gridInstance.hideSpinner();
    }, 100);
  }

  onLoad() {
    document
      .getElementById("overviewgrid")
      .ej2_instances[0].on("data-ready", () => {
        this.dReady = true;
        this.stTime = performance.now();
      });
    document
      .getElementById("overviewgrid")
      .addEventListener("DOMSubtreeModified", () => {
        if (this.dReady && this.stTime && this.isDataChanged) {
          this.stTime = null;
          this.dReady = false;
          this.dtTime = false;
          this.isDataChanged = false;
        }
      });
  }

  getMasterData = () => {
    const { authUser } = this.props;
    try {
      const getMasterData = Promise.resolve(
        OnbordingToBeOffer.getMasterData("RV", authUser)
      );
      getMasterData.then((resultData) => {
        if (resultData !== null) {
          this.setState({ masterDataRevokrReason: resultData });
        } else {
          this.data = "error";
        }
      });
    } catch (e) {
      // eslint-disable-next-line
      console.log(e);
    }
  };

  navigateToAddBasicInformation = async (args) => {
    try {
      localStorage.setItem('last_meeting_category', decodeString(localStorage.getItem('meeting_category')))
      localStorage.setItem("meeting_category", encodeString(PRE_OFFERE));
      const params = queryString.parse(this.props.location.search);
      const path = `/preoffer?candid=${encrypt(args.CandidateId)}&id=${params.id}&appId=${params.appId}`;
      this.setState({
        redirect: path,
        // changedData: !changedData,
      }, () => this.props.setMeetingCategory(PRE_OFFERE));
    } catch (error) {
      console.log("error", error);
    }
  };

  checkedBoxTemplate = (props) => {
    return (
      <div className="check-box-alignment-all-candidate-grid">
        <CheckBoxComponent
          checked={false}
          id={`candidateID${props.CandidateId}`}
          value={props.CandidateId}
          onChange={(e) =>
            this.getValueOfcheckBox(e, props.CandidateCheckListStatus, props.DocApprovalStatus, props)
          }
        />
      </div>
    );
  };

  getValueOfcheckBox = (e, CandidateCheckListStatus, DocStatus, props) => {
    if (CandidateCheckListStatus === "Completed") {
      let arr = [];
      let selectedData = [];
      selectedData = this.state.selectedData;
      arr = this.state.offerChangeStatus;
      // radix issue parseInt
      // eslint-disable-next-line

      if (!arr.includes(parseInt(e.target.properties.value))) {
        // eslint-disable-next-line
        selectedData.push(props)
        arr.push(parseInt(e.target.properties.value));
        this.setState(
          {
            offerChangeStatus: arr,
            docStatus: DocStatus,
            selectedData: selectedData
          }
        );
      } else {
        let filterData = this.state.offerChangeStatus;
        filterData = filterData.filter(
          // eslint-disable-next-line
          (t) => t !== parseInt(e.target.properties.value)
        );
        selectedData = selectedData.filter((t) => t.CandidateId !== props.CandidateId)
        this.setState(
          {
            offerChangeStatus: filterData,
            docStatus: DocStatus,
            selectedData: selectedData
          },
        );
      }
      this.setState({ joiningDate: selectedData[0] !== undefined && selectedData[0].JoiningDate })
    } else {

      // Added By Vinayak - to remove validation for Employee Code Geneartion. Emp code can be generated even of the doc upload process is not complete
      let arr = [];
      let selectedData = [];
      let finalArr = [];
      let joinDate;
      selectedData = this.state.selectedData;
      arr = this.state.offerChangeStatus;
      if (!arr.includes(parseInt(e.target.properties.value))) {
        // eslint-disable-next-line
        selectedData.push(props)
        arr.push(parseInt(e.target.properties.value));
        finalArr = arr;
        joinDate = selectedData[0] !== undefined && selectedData[0].JoiningDate;
      } else {
        let filterData = this.state.offerChangeStatus;
        filterData = filterData.filter(
          // eslint-disable-next-line
          (t) => t !== parseInt(e.target.properties.value)
        );
        selectedData = selectedData.filter((t) => t.CandidateId !== props.CandidateId)
        finalArr = filterData;
        joinDate = selectedData[0] !== undefined && selectedData[0].JoiningDate;
      }
      // -- Code by Vinayak - Ends here -- //
      const revokeChange = this.state.revokeChangeStatus;
      // eslint-disable-next-line
      if (!revokeChange.includes(parseInt(e.target.properties.value))) {
        // eslint-disable-next-line 
        revokeChange.push(parseInt(e.target.properties.value));
        this.setState(
          {
            offerChangeStatus: finalArr,
            joiningDate: joinDate,
            revokeChangeStatus: revokeChange,
            docStatus: DocStatus,
          });
      } else {
        let revokeChangeFilter = this.state.revokeChangeStatus;
        revokeChangeFilter = revokeChangeFilter.filter(
          // eslint-disable-next-line
          (t) => t !== parseInt(e.target.properties.value)
        );
        this.setState(
          {
            offerChangeStatus: finalArr,
            joiningDate: joinDate,
            revokeChangeStatus: revokeChangeFilter,
            docStatus: DocStatus,
          });
      }
    }
  };

  initiateOnBoarding = () => {
    const { authUser, getReadyToJoinTabData } = this.props;
    const { offerChangeStatus } = this.state;
    const revokeToReadyToJoin = {
      authUser,
      candIds: encryptMethod(offerChangeStatus.toString()),
      statusId: 5,
      revokeType: "readyJoin",
      joiningDate: Math.floor(Date.UTC(this.state.joiningDate.getFullYear(), this.state.joiningDate.getMonth(), this.state.joiningDate.getDate())) / 1000
    };
    // eslint-disable-next-line
    this.props.initiateAllcandidate(revokeToReadyToJoin);

    getNotify(SUCCESS, "Initiate candidate in confirmation");
    this.closeDialog();
    getReadyToJoinTabData();
    this.setState({ offerChangeStatus: [] });
  };

  openDialog = () => {
    const { docStatus, offerChangeStatus, revokeChangeStatus } = this.state;
    if (offerChangeStatus.length <= 0 && revokeChangeStatus.length > 0) {
      getNotify(WARNING, "Please complete required assets across all functions, before initiate onboarding!");
      return;
    } else if (offerChangeStatus.length <= 0) {
      getNotify(WARNING, "Please select at least one Candidate !");
      return;
    }
    if (docStatus !== "Complete") {
      getNotify(WARNING, "You can not initiate onboarding, As some required documents are pending !");
      return;
    }
    if (offerChangeStatus.length === 1) {
      this.setState({
        openDialog: true,
      });
    } else {
      getNotify(
        WARNING,
        " You can initiate onboarding only one candidate at a time !"
      );
    }
    if (docStatus !== "Complete" && revokeChangeStatus.length > 0) {
      getNotify(
        WARNING,
        "Please complete required assets across all functions, before initiate onboarding!"
      );
    }


  };

  closeDialog = () => {
    this.setState({
      openDialog: false,
    });
  };
  openRevokeDialog = () => {
    const { revokeChangeStatus, offerChangeStatus } = this.state;
    if (
      revokeChangeStatus.toString().length > 0 ||
      offerChangeStatus.toString().length > 0
    ) {
      this.setState({ openRevokeDialog: true });
    } else {
      getNotify(WARNING, "Please select at least one Candidate !");
    }
  };

  closeRevokeDialog = () => {
    this.setState({ openRevokeDialog: false, revokeReasonID: null, getComment: null, revokeDate: null });
  };

  getRevokeReson = (e) => {
    this.setState({
      revokeReasonID: e.itemData.Id,
    });
    document.getElementById("revokereasonErr").innerHTML = "";
    if (this.state.revokeReasonID === null) {
      document.getElementById("revokereasonErr").innerHTML = "Please select action taken by";
      // return;
    }
  };
  setRevokeDate = async (e, type) => {
    var revokeDate = Math.floor(Date.UTC(e.getFullYear(), e.getMonth(), e.getDate())) / 1000;
    document.getElementById("revokeDateErr").innerHTML = "";
    await this.setState({ revokeDate: revokeDate });
    if (this.state.revokeDate === null) {
      document.getElementById("revokeDateErr").innerHTML = "Please enter revoke date";
      // return;
    }
  };
  getRevokeComment = (e) => {
    this.setState({
      getComment: e.value,
    });
    document.getElementById("revokeCommentErr").innerHTML = "";
    if (this.state.getComment === null) {
      document.getElementById("revokeCommentErr").innerHTML = "Please enter revoke comment";
      // return;
    }
  };

  submitRevoke = () => {
    const { authUser, getReadyToJoinTabData } = this.props;
    const {
      revokeChangeStatus,
      offerChangeStatus,
      getComment,
      revokeReasonID,
      revokeDate
    } = this.state;
    let revokeCandidate;
    if (
      revokeChangeStatus.toString().length > 0 &&
      offerChangeStatus.toString().length > 0
    ) {
      revokeCandidate = `${revokeChangeStatus.toString()},${offerChangeStatus.toString()}`;
    } else if (revokeChangeStatus.toString().length > 0) {
      revokeCandidate = revokeChangeStatus.toString();
    } else {
      revokeCandidate = offerChangeStatus.toString();
    }
    document.getElementById("revokereasonErr").innerHTML = "";
    if (this.state.revokeReasonID === null) {
      document.getElementById("revokereasonErr").innerHTML = "Please select action taken by";
      return;
    }
    document.getElementById("revokeDateErr").innerHTML = "";
    if (this.state.revokeDate === null || this.state.revokeDate === "") {
      document.getElementById("revokeDateErr").innerHTML = "Please enter revoke date";
      return;
    }
    document.getElementById("revokeCommentErr").innerHTML = "";
    if (this.state.getComment === null || this.state.getComment === "") {
      document.getElementById("revokeCommentErr").innerHTML = "Please enter revoke reason";
      return;
    }
    // eslint-disable-next-line
    this.props.initiateAllcandidate({
      authUser,
      candIds: revokeCandidate, // this.state.revokeChangeStatus.toString(),
      statusId: 4,
      revokeComments: getComment,
      revokeReasonId: revokeReasonID,
      revokeDate: revokeDate,
      revokeType: "readyToJoin"
    });
    this.setState({
      openDialog: false,
      openRevokeDialog: false,
      revokeChangeStatus: [],
      offerChangeStatus: [],
      revokeReasonID: null,
      getComment: null,
      revokeDate: null
    });
    getReadyToJoinTabData();
  };

  dateFormat = (props) => {
    return (
      <div style={{ textAlign: "center" }}>
        {
          new Date(props.JoiningDate) <= new Date() ?
            <span style={{ color: "red" }}>{getDisplayDateOnly(new Date(props.JoiningDate))}</span>
            :
            <span>{getDisplayDateOnly(new Date(props.JoiningDate))}</span>
        }
      </div>
    );
  };

  daysToJoin = (props) => {
    return (
      <div style={{ textAlign: "center" }}>
        <span>{props.DaysToJoin}</span>
      </div>
    );
  };

  employeeNameTemplate(props) {
    return (
      <EmployeeNameTemplate
        props={props}
        data="test"
        navigateToAddBasicInformation={this.navigateToAddBasicInformation}
      />
    );
  }
  band = (props) => {
    const { Band } = props;
    return (
      <div>
        <span className="band-alignment" >
          {Band}
        </span>
      </div>
    );
  };
  TextFieldComponent = (props) => {
    return <TextField {...props} disabled />;
  };
  employeeCode = (props) => {
    const { empCode } = props;
    if (empCode === "" || empCode === null || empCode === undefined) {
      return (
        <div>
          <span>
            Not Generated
          </span>
        </div>
      )
    } else {
      return (
        <div>
          <span>
            {empCode}
          </span>
        </div>
      )
    }
  }
  closeEmpcodeDialog = () => {
    this.setState({ openDialogGenerateEmpCode: false })
  }
  generateEmpCode = async () => {
    const { offerChangeStatus } = this.state;
    const { authUser } = this.props;
    // if (offerChangeStatus.length <= 0 && revokeChangeStatus.length > 0) {
    //   getNotify(WARNING, "Please complete required assets across all functions, before generate employee code 1");
    //   return;
    // } else 
    if (offerChangeStatus.length <= 0) {
      getNotify(WARNING, "Please select at least one candidate");
      return;
    }
    const candidateBasicDetails = await OnbordingToBeOffer.getCandidateInformation(offerChangeStatus[0], authUser, 1);
    if (candidateBasicDetails.enum_empcode === '1') {
      getNotify(WARNING, "Employee code is already generated");
      return;
    }

    if (offerChangeStatus.length === 1) {
      this.setState({
        openDialogGenerateEmpCode: true,
      });
    } else {
      getNotify(
        WARNING,
        "You can generate employee code for only one candidate at a time"
      );
      // return;
    }
  };
  generateEmpId = () => {
    const { authUser } = this.props;
    const { offerChangeStatus } = this.state;
    const revokeToReadyToJoin = {
      authUser,
      candIds: offerChangeStatus.toString(),
      statusId: 3,
      revokeType: "empCode",
      joiningDate: Math.floor(Date.UTC(this.state.joiningDate.getFullYear(), this.state.joiningDate.getMonth(), this.state.joiningDate.getDate())) / 1000
    };
    this.props.initiateAllcandidate(revokeToReadyToJoin);
    setTimeout(() => {
      document.getElementById("overviewgrid").ej2_instances[0].refresh();
      this.gridHeaderDynamic();
      this.setState({ offerChangeStatus: [], });
    }, 2000);
    this.closeEmpcodeDialog();
  };
  renderNestedTernary(type, item) {
    if (type === 'datepickervalue') {
      if (item !== null) return item;
      else return '';
    }
    else if (type === 'progressbar') {
      if (item !== "error" && item.length > 0) return <Progressbar />;
      else return <ErrorMessage message="No Data Available" />;
    }
  }

  toolbarClick = (args) => {
    if (args.item.text === 'Excel Export' && this.gridInstance) {
      if (this.gridInstance.getColumnByField("checkbox")) {
        this.gridInstance.getColumnByField("checkbox").visible = false;
      }

      this.gridInstance.excelExport(this.getExcelExportProperties());
    }
  }

  excelExportComplete = () => {
    if (this.gridInstance) {
      if (this.gridInstance.getColumnByField("checkbox")) {
        this.gridInstance.getColumnByField("checkbox").visible = true;
      }
    }
  }

  getExcelExportProperties = () => {
    const dateFns = new DateFnsUtils();
    var currentDate = dateFns.format(new Date(), 'yyyyMMdd');
    return {
      fileName: `${currentDate}_ready-to-join.xlsx`
    }
  }

  render() {
    const {
      redirect,
      openDialog,
      openRevokeDialog,
      masterDataRevokrReason,
      openDialogGenerateEmpCode
    } = this.state;
    const { readyToJoinData } = this.props;
    if (redirect !== null) {
      return <Redirect to={redirect} />;
    }
    var revokeDateVal = null;
    if (this.state.revokeDate)
      revokeDateVal = new Date(0).setUTCSeconds(this.state.revokeDate);
    var widthColumn = 0;
    var finalWidth;
    // eslint-disable-next-line
    getGridColumn("Ready To Join").forEach((data) => {
      widthColumn = widthColumn + data.width;
    });
    if (widthColumn <= this.width) {
      finalWidth = widthColumn;
    } else {
      finalWidth = this.width
    }
    return (
      <div
        className="row "
        style={{ height: this.state.height, overflowY: "hidden", overflowX: 'hidden' }}
      >
        {readyToJoinData !== null &&
          readyToJoinData !== undefined &&
          readyToJoinData !== "" &&
          readyToJoinData !== "error" &&
          readyToJoinData.length > 0 ? (
          <div>
            <div className="row-xs-12 row-sm-12 row-lg-12 row-md-12">
              <div
                style={{
                  paddingLeft: "15px",
                  paddingRight: "15px",
                  paddingTop: "5px",
                }}
              >
                <GridComponent
                  className="grid-head-custome"
                  id="overviewgrid"
                  allowPaging
                  pageSettings={this.pageOptions}
                  dataSource={getFormatDateForGrid(readyToJoinData)}
                  enableHover={false}
                  enableVirtualization={false}
                  rowHeight={30}
                  height={this.state.gridHeight}
                  overflowY="hidden"
                  ref={(g) => {
                    this.gridInstance = g;
                  }}
                  actionComplete={this.onComplete}
                  load={this.onLoad}
                  queryCellInfo={this.onQueryCellInfoPreJoining}
                  dataBound={this.onDataBound}
                  filterSettings={this.Filter}
                  allowFiltering
                  allowSorting
                  allowSelection
                  allowExcelExport={true}
                  toolbar={this.toolbarOptions}
                  toolbarClick={this.toolbarClick}
                  excelExportComplete={this.excelExportComplete}
                  selectionSettings={this.select}
                  allowTextWrap={true}
                  width={`${finalWidth}`}
                // searchSettings={this.searchOptions}
                >
                  <ColumnsDirective>
                    <ColumnDirective
                      template={this.checkedBoxTemplate}
                      width="50"
                      field={"checkbox"}
                      headerText={""}
                      allowFiltering={false}
                    />
                    {
                      getGridColumn("Ready To Join").map(item => {
                        switch (item.colName) {
                          case CANDIDATE_NAME:
                            return <ColumnDirective key={item.field} template={this.employeeNameTemplate} textAlign={item.align + ' !important'} field={item.field} headerText={item.colName} width={item.width} filter={this.Filter} />
                          case READY_TO_JOIN_EMPLOYEE_CODE:
                            return <ColumnDirective key={item.field} template={this.employeeCode} textAlign={item.align + ' !important'} field={item.field} headerText={item.colName} width={item.width} filter={this.check} />
                          case CANDIDATE_STATUS:
                            return <ColumnDirective key={item.field} template={statusdetails} filter={this.check} textAlign={item.align + ' !important'} field={item.field} headerText={item.colName} width={item.width} />
                          case ADMIN:
                            return <ColumnDirective key={item.field} template={adminTemplate} filter={this.check} textAlign={item.align + ' !important'} field={item.field} headerText={item.colName} width={item.width} />
                          case IT:
                            return <ColumnDirective key={item.field} template={itTemplate} filter={this.check} textAlign={item.align + ' !important'} field={item.field} headerText={item.colName} width={item.width} />
                          case PEOPLE_TEAM:
                            return <ColumnDirective key={item.field} template={peopleTeamTemplate} filter={this.check} textAlign={item.align + ' !important'} field={item.field} headerText={item.colName} width={item.width} />
                          case FINANCE:
                            return <ColumnDirective key={item.field} template={financeTemplate} filter={this.check} textAlign={item.align + ' !important'} field={item.field} headerText={item.colName} width={item.width} />
                          case SOFTWARE:
                            return <ColumnDirective key={item.field} template={softwareTemplate} filter={this.check} textAlign={item.align + ' !important'} field={item.field} headerText={item.colName} width={item.width} />
                          case DATE_OF_JOINING:
                            return <ColumnDirective key={item.field} template={this.dateFormat} filter={this.check} textAlign={item.align + ' !important'} field={item.field} headerText={item.colName} width={item.width} format={DateFilterformat} />
                          case DAYS_TO_JOIN:
                            return <ColumnDirective key={item.field} template={this.daysToJoin} filter={this.check} textAlign={item.align + ' !important'} field={item.field} headerText={item.colName} width={item.width} />
                          case BAND:
                            return <ColumnDirective key={item.field} template={this.band} filter={this.check} textAlign={item.align + ' !important'} field={item.field} headerText={item.colName} width={item.width} />
                          default:
                            return <ColumnDirective key={item.field} textAlign={item.align + ' !important'} field={item.field} headerText={item.colName} width={item.width} filter={this.check} />

                        }
                      }
                      )
                    }
                  </ColumnsDirective>
                  <Inject services={[Page, Filter, VirtualScroll, Sort, ExcelExport, Toolbar]} />
                </GridComponent>
              </div>
            </div>
            <div className="control-section col-md-12">
              <div
                className="row card-layout"
                style={{
                  paddingRight: "30px",
                  paddingTop: "15px",
                  textAlign: "end",
                }}
              >
                <ButtonComponent onClick={this.generateEmpCode}>
                  Generate Employee Code
                </ButtonComponent>{" "}
                &nbsp;&nbsp;&nbsp;
                <ButtonComponent onClick={this.openRevokeDialog}>
                  REVOKE
                </ButtonComponent>{" "}
                &nbsp;&nbsp;&nbsp;
                <ButtonComponent
                  style={{ background: "#f94f5e", color: "#fff" }}
                  onClick={this.openDialog}
                >
                  {" "}
                  INITIATE ONBOARDING
                </ButtonComponent>
                <div style={{ width: "350px" }}>
                  <Dialog open={openDialog}>
                    <DialogTitle> Do you want to initiate the joining?</DialogTitle>
                    <DialogContent>
                      {/* Are you sure you want to initiate Onboarding Candidate ? */}
                      {/* <div>Do you want to initiate the joining</div> */}
                      <div className="col-xs-2 col-sm-2 col-lg-12 col-md-12" >
                        {/* <span className="span">Select Revoke Date *</span> */}
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            style={{ width: "100%" }}
                            // margin="normal"
                            id="Joining Data"
                            // name="JoiningData"
                            format="dd-MMM-yyyy"
                            value={this.renderNestedTernary('datepickervalue', this.state.joiningDate)}
                            onChange={(e) => this.setState({ joiningDate: e })}
                            TextFieldComponent={this.TextFieldComponent}
                            // data-msg-containerid="joiningDataErr"
                            color="black"
                            autoOk
                            variant="inline"
                            inputVariant="outlined"
                            placeholder="dd-MMM-yyyy"
                            label="Joining Date "
                            KeyboardButtonProps={{ "aria-label": "change date" }}
                            // margin="dense"
                            InputLabelProps={{ shrink: true }}
                            floatLabelType="Auto"
                            cssClass="e-outline"
                            InputAdornmentProps={{ position: "start" }}
                          />
                        </MuiPickersUtilsProvider>
                        {/* <div
                              id="joiningDataErr"
                              className="error-div"
                              style={{ fontSize: "12px" }}
                            />*/}
                      </div>
                    </DialogContent>
                    <DialogActions>
                      {/* <Link to={`?emp_id=${734}`}> */}
                      <Button onClick={this.initiateOnBoarding}> Yes </Button>
                      {/* </Link> */}
                      <Button onClick={this.closeDialog}> No </Button>
                    </DialogActions>
                  </Dialog>
                </div>
                <div style={{ width: "350px" }}>
                  <Dialog open={openRevokeDialog}>
                    <DialogContent>
                      <div className="row card-layout">
                        <div className="col-xs-2 col-sm-2 col-lg-6 col-md-2">
                          {/* <span className="span">Revoke By *</span> */}
                          <DropDownListComponent
                            id="RevokReason"
                            fields={{ value: "Id", text: "Name" }}
                            dataSource={masterDataRevokrReason}
                            data-msg-containerid="revokereasonErr"
                            name="revokereasonErr"
                            change={(e) => this.getRevokeReson(e)}
                            cssClass="e-outline"
                            placeholder="Revoke By *"
                            floatLabelType="Auto"
                          />
                          <div
                            id="revokereasonErr"
                            className="error-div"
                            style={{ fontSize: "12px" }}
                          />
                        </div>

                        <div className="col-xs-2 col-sm-2 col-lg-6 col-md-2" >
                          {/* <span className="span">Select Revoke Date *</span> */}
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              style={{ width: "100%" }}
                              // margin="normal"
                              id="RevokeDate"
                              name="RevokeDate"
                              format="dd-MMM-yyyy"
                              value={revokeDateVal}
                              onChange={(e) => this.setRevokeDate(e, "RevokeDate")}
                              TextFieldComponent={this.TextFieldComponent}
                              data-msg-containerid="revokeDateErr"
                              color="black"
                              autoOk
                              variant="inline"
                              inputVariant="outlined"
                              placeholder="dd-MMM-yyyy"
                              label="Revoke Date *"
                              KeyboardButtonProps={{ "aria-label": "change date" }}
                              // margin="dense"
                              InputLabelProps={{ shrink: true }}
                              floatLabelType="Auto"
                              cssClass="e-outline"
                              InputAdornmentProps={{ position: "start" }}
                            />
                          </MuiPickersUtilsProvider>
                          <div
                            id="revokeDateErr"
                            className="error-div"
                            style={{ fontSize: "12px" }}
                          />
                        </div>


                        <div
                          style={{ paddingTop: "30px" }}
                          className="col-xs-2 col-sm-2 col-lg-12 col-md-2"
                        >
                          {/* <span className="span">Reason for Revoke *</span> */}
                          <TextBoxComponent
                            id="ReasonRevoke"
                            placeholder="Reason for Revoke *"
                            cssClass="e-outline"
                            data-msg-containerid="revokeCommentErr"
                            name="revokeCommentErr"
                            multiline
                            // value={this.state.revokeComment}
                            change={(e) => this.getRevokeComment(e)}
                            floatLabelType="Auto"
                          />
                          <div
                            id="revokeCommentErr"
                            className="error-div"
                            style={{ fontSize: "12px" }}
                          />
                        </div>
                      </div>
                      {/* <div style={{ border: '1px solid gray', marginBottom: '10px' }}></div> */}
                    </DialogContent>
                    <DialogActions style={{ paddingRight: "15px" }}>
                      <ButtonComponent
                        id="cancelRevoke"
                        className="e-control"
                        onClick={this.closeRevokeDialog}
                      >
                        Cancel
                      </ButtonComponent>
                      <ButtonComponent
                        id="sumitRevoke"
                        style={{ background: "#f94f5e", color: "#fff" }}
                        data-ripple="true"
                        onClick={this.submitRevoke}
                      >
                        Submit
                      </ButtonComponent>
                    </DialogActions>
                  </Dialog>
                </div>
                <div style={{ width: "350px" }}>
                  <Dialog open={openDialogGenerateEmpCode}>
                    <DialogContent>
                      Are you sure you want to generate employee code for candidate?
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={this.generateEmpId}> Yes </Button>
                      <Button onClick={this.closeEmpcodeDialog}> No </Button>
                    </DialogActions>
                  </Dialog>
                </div>
                {/* </div> */}
              </div>
            </div>
          </div>
        ) : (
          <div className="control-section col-md-12" style={{ height: 75 }}>
            {this.renderNestedTernary('progressbar', readyToJoinData)}
          </div>
        )}
      </div>
    );
  }
}
PreJoining.propTypes = {
  // eslint-disable-next-line
  status: PropTypes.string,
  authUser: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  // readyToJoinData: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  getReadyToJoinTabData: PropTypes.func,
};
PreJoining.defaultProps = {
  authUser: "",
  // readyToJoinData: "",
  getReadyToJoinTabData: {},
};
const mapStateToProps = ({ auth, onboardingreducer, router }) => {
  const { location } = router;
  const { authUser } = auth;
  const { empid, onboardStatus } = onboardingreducer;
  return { authUser, onboardStatus, empid, location };
};
export default connect(mapStateToProps, {
  initiateAllcandidate,
  updateStatusOnBoardingResucer,
  setMeetingCategory
})(PreJoining);
