import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  DetailRow,
  Inject,
  Page,
  Filter,
  Freeze,
  Selection,
} from "@syncfusion/ej2-react-grids";
import { DropDownButtonComponent } from "@syncfusion/ej2-react-splitbuttons";
import { updateOKRData, setTeamMemberSelectionData, setCommonMasterLoading, updateOkrMasterItemStatus } from "../../../redux/actions/okr/okractions";
import RatingImg from '../../../assets/images/ratingreview.jpg'
import {
  getGridColumn,
  ADMIN_MODULE_ACTION,
  BLOCK_MASTER_SECONDRY_COLOR_NAME,
  BLOCK_MASTER_PRIMARY_COLOR_NAME,
  PERFORMANCE_MASTER_NAME,
  TEAM_MEMBER_NAME,
  TEAM_MEMBER_DATE_OF_FIRST,
  TEAM_MEMBER_DATE_OF_SECOND,
  TEAM_MEMBER_DATE_OF_THIRD,
  TEAM_MEMBER_DATE_OF_LAST,
  TEAM_MEMBER_DATE_OF_AUG,
  TEAM_MEMBER_DATE_OF_SEP,
  TEAM_MEMBER_DATE_OF_OCT,
  TEAM_MEMBER_DATE_OF_NOV,
  TEAM_MEMBER_DATE_OF_DEC,
  TEAM_MEMBER_DATE_OF_JAN,
  TEAM_MEMBER_DATE_OF_FEB,
  TEAM_MEMBER_DATE_OF_MAR,
  APPLICABLE_PERIOD,
  FREQUENCY_MASTER_APPLY_PERIOD_FROM_FIELD,
  FREQUENCY_MASTER_APPLY_PERIOD_FROM,
  FREQUENCY_MASTER_APPLY_PERIOD_FROM_ALIGN,
  FREQUENCY_MASTER_APPLY_PERIOD_TO,
  FREQUENCY_MASTER_APPLY_PERIOD_TO_FIELD,
  FREQUENCY_MASTER_APPLY_PERIOD_TO_ALIGN,
  PERFORMANCE_OKR,
  FREQUENCY_ADD_EDIT_PERFORM_ST_DATE_FIELD,
  FREQUENCY_ADD__PERFORM_END_DATE_FIELD,
  FREQUENCY_EDIT__PERFORM_END_DATE_FIELD,
  FREQUENCY_ADD_EDIT_PERFORM_ST_DATE,
  FREQUENCY_ADD__PERFORM_END_DATE,
  FREQUENCY_EDIT__PERFORM_END_DATE,
  FREQUENCY_ADD_EDIT_PERFORM_ST_DATE_ALIGN,
  FREQUENCY_ADD__PERFORM_END_DATE_ALIGN,
  FREQUENCY_EDIT__PERFORM_END_DATE_ALIGN,
  POTENTIAL_OKR,
  FREQUENCY_ADD_EDIT_POTENTIAL_ST_DATE,
  FREQUENCY_ADD__POTENTIAL_END_DATE,
  FREQUENCY_EDIT__POTENTIAL_END_DATE,
  FREQUENCY_ADD_EDIT_POTENTIAL_ST_DATE_FIELD,
  FREQUENCY_ADD__POTENTIAL_END_DATE_FIELD,
  FREQUENCY_EDIT__POTENTIAL_END_DATE_FIELD,
  FREQUENCY_ADD_EDIT_POTENTIAL_ST_DATE_ALIGN,
  FREQUENCY_ADD__POTENTIAL_END_DATE_ALIGN,
  FREQUENCY_EDIT__POTENTIAL_END_DATE_ALIGN,
  SELF_SUP_FINAL_RATING,
  PFREQUENCY_SELF_SUP_FINAL_RATING_ST_DATE_FIELD,
  FREQUENCY_SELF_SUP_FINAL_RATING_ST_DATE,
  FREQUENCY_SELF_SUP_FINAL_RATING_ST_DATE_ALIGN,
  FREQUENCY_SELF_RATING_END_DATE_FIELD,
  FREQUENCY_SELF_RATING_END_DATE,
  FREQUENCY_SELF_RATING_END_DATE_ALIGN,
  FREQUENCY_SUP_RATING_END_DATE_FIELD,
  FREQUENCY_SUP_RATING_END_DATE,
  FREQUENCY_SUP_RATING_END_DATE_ALIGN,
  FREQUENCY_FINAL_RATING_END_DATE_FIELD,
  FREQUENCY_FINAL_RATING_END_DATE,
  FREQUENCY_FINAL_RATING_END_DATE_ALIGN,
  TEAM_MEMBER_CHECKBOX,
  PRODUCTIVITY_DESCRIPTION,
  SUB_POTENTIAL_CALCULATION_SELF_ATTRIBUTE_DESCRIPTION,
  SUB_POTENTIAL_CALCULATION_SUP_ATTRIBUTE_DESCRIPTION,
  BLOCK_MASTER_DESCRIPTION,
  SUB_POTENTIAL_CALCULATION_SELF_ATTRIBUTE,
  SUB_POTENTIAL_CALCULATION_SUP_ATTRIBUTE,
  TEAM_MEMBER_DEPARTMENT
} from "../../../app/other/gridcolumns";
import React, { useEffect, useState } from "react";
import OKRServices from "../../../services/okr/okrservices";
import { connect } from "react-redux";

import Progressbar from "../../../components/progressbar/progressbar";
import { L10n } from "@syncfusion/ej2-base";
import { AXIS, FREQUENCY, PERFORMANCE, POTENTIAL, TEAM_MEMBER } from "../../../redux/constant/meetingcategoryconstant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faPen
} from "@fortawesome/pro-light-svg-icons";
import ErrorMessage from "../../../app/containers/tibr/errorMessage";
library.add(faPen);
/**
 * TO show empty when data is Loading
 * Noel -- 30/11/22
 */
L10n.load({
  "en-US": {
    grid: {
      EmptyRecord: "",
    },
  },
});
const SAMPLECSS = `
  .e-headertext{
    white-space: pre-wrap !important;
  }
  .grid-left-header-admin{
    border: 0 none;
    display: block;
    font-size: 15px;
    font-weight: 500;
    line-height: 40px !important;
    height: 40px !important;
    margin-left: -1px !important;
    overflow: hidden;
    padding: 0 0.6em !important;
    text-align: left !important;
    -webkit-user-select: none;
    user-select: none;
    width: inherit !important;
    word-wrap: break-word !important;
  }
  .grid-center-header-admin{
    border: 0 none;
    display: block;
    font-size: 15px;
    font-weight: 500;
    line-height: 40px !important;
    height: 40px !important;
    overflow: hidden;
    padding: 0 0.6em !important;
    -webkit-user-select: none;
    user-select: none;
    text-align: 'center'!important;
    width: inherit !important;
    word-wrap: break-word !important;
  }
  .grid-left-header-popup{
    border: 0 none;
    display: block;
    font-size: 15px;
    font-weight: 500;
    line-height: 30px;
    height: 30px !important;
    margin-left: -1px !important;
    overflow: hidden;
    padding: 0 0.6em !important;
    text-align: left !important;
    -webkit-user-select: none;
    user-select: none;
    width: inherit !important;
    word-wrap: break-word !important;
  }
  .grid-center-header-popup{
    border: 0 none;
    display: block;
    font-size: 15px;
    font-weight: 500;
    height: 22px !important;
    line-height: 30px;
    overflow: hidden;
    padding: 0 0.6em !important;
    -webkit-user-select: none;
    user-select: none;
    text-align: 'center'!important;
    width: inherit !important;
    word-wrap: break-word !important;
  }
  .e-grid .e-rowcell{
    border-style: solid;
    border-width: 1px 0 0 0;
    display: table-cell;
    font-size: 14px !important;
    line-height: 18px !important;
    overflow: hidden;
    padding: 5px 0px 5px 8px !important;
    vertical-align: middle;
    /* white-space: pre-wrap !important; */
    width: auto;
    // // text-transform: capitalize;
  }
  .e-grid .e-filtermenudiv{
      margin-top:28px;
  }
  .e-dropdown-popup ul {
    padding:0px !important
  }
  .e-grid.e-responsive .e-rowcell, .e-grid.e-responsive .e-headercelldiv {
    text-overflow: ellipsis;
    white-space: nowrap;
}
.e-grid .e-gridheader tr th {
  padding:0px !important;
}
.team-table .e-grid .e-gridheader .e-sortfilter .e-headercelldiv, .e-grid .e-gridheader .e-stackedheadercelldiv, .e-grid .e-gridheader .e-headercell .e-headercelldiv.e-headerchkcelldiv {
  padding: 4px 20px 10px 20px !important;
}
.e-grid.e-default tr td:first-child, .e-grid.e-default tr th.e-headercell:first-child:not(.e-firstcell), .e-grid.e-default tr th.e-detailheadercell:first-child, .e-grid.e-default tr th.e-filterbarcell:first-child {
  border-left-width: 0;
  padding-bottom: 3px !important;
}
.e-grid .e-gridheader table th[rowspan], .e-grid.e-device .e-gridheader table th[rowspan] {
  padding-top: 65px !important;
  vertical-align: top;
}

  `;

const OkrMasterSetup = (props) => {
  const { commonMasterLoading, currentFinancialYearId } = props
  const { authUser, refresh, masterId, changePopUpHandler } = props;
  const [state, setState] = useState({
    gridHeight: null,
    popUp: false,
    okrMasterData: null,
    gridWidth: (window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) - 80,
  });


  const [initialLoad, setInitialLoad] = useState(false);
  const itemActive = [
    {
      text: "Edit",
      iconCss: "e-icons e-edit",
    },
    {
      text: "Inactive",
      iconCss: "fas fa-toggle-off",
    },
  ];
  const itemInactive = [
    {
      text: "Edit",
      iconCss: "e-icons e-edit",
    },
    {
      text: "Active",
      iconCss: "fas fa-toggle-on",
    },
  ];

  const isSixteenHundred = window.innerHeight < 782;
  const isOneTwentyFiveScale = window.innerHeight < 747;
  const isThirteenHundred = window.innerHeight < 650;
  const isOneTwentyFiveInSixteenHundred = window.innerHeight < 603;
  const isOneTwentyFiveInFourteenHundred = window.innerHeight < 603 && window.innerWidth < 1153;
  const isOneTwentyFiveInThirteenHundred = window.innerHeight < 497;
  const isTenEightyHundred = window.innerHeight > 870 && window.innerHeight < 900;

  const isSixteenEightyBookmarkOn = window.innerHeight < 900;
  const isOneTwentyFiveScaleBookmarkOn = window.innerHeight < 715;
  const isThirteenHundredBookmarkOn = window.innerHeight < 618;
  useEffect(() => { // NOSONAR
    let gridHeight = (masterId + "" === FREQUENCY) && isOneTwentyFiveScale ? (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight) - 325
      : (masterId + "" === FREQUENCY) ? (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight) - 330
        : isOneTwentyFiveInThirteenHundred ? (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight) - 298
          : isOneTwentyFiveInSixteenHundred ? (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight) - 300
            : isThirteenHundredBookmarkOn ? (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight) - 300
              : isThirteenHundred ? (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight) - 302
                : isOneTwentyFiveScaleBookmarkOn ? (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight) - 300
                  : isOneTwentyFiveScale ? (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight) - 303
                    : isSixteenEightyBookmarkOn ? (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight) - 303
                      : (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight) - 305; // 295

    window.onresize = () => {
      let gridWidth =
        (window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) - 85;
      setState((preState) => ({ ...preState, gridWidth }));
    }

    if (props.okrMasterFlag === "") {
      (async () => {
        setInitialLoad(true);
        if (masterId + "" === TEAM_MEMBER) setInitialLoad(false)

        if (masterId > 0 && masterId + "" !== TEAM_MEMBER) {
          let okrMasterData = await OKRServices.getOKRMaserData(
            authUser,
            masterId,
            0,
            currentFinancialYearId
          );
          okrMasterData = okrMasterData.Result
          if (masterId === PERFORMANCE) {
            okrMasterData = okrMasterData.filter(item => item.allocationId <= 2)

          }
          if (masterId === POTENTIAL) {
            okrMasterData = okrMasterData.filter(item => item.allocationId > 2)

          }
          if (masterId === TEAM_MEMBER) {
            okrMasterData = okrMasterData.filter(item => item.allocationId > 2)
          }

          setState((preState) => ({
            ...preState,
            gridHeight: masterId + "" === FREQUENCY ? gridHeight - 20 : gridHeight + 10,
            okrMasterData: okrMasterData,
          }));
          setInitialLoad(false);

          const okrMasterGridCss = document.querySelectorAll('#OKR-AdminModuleId .e-gridheader.e-lib.e-droppable .e-headercontent .e-table.e-sortfilter thead .e-columnheader .e-headercell .e-headercelldiv')
          if (okrMasterGridCss && okrMasterGridCss.length > 0) {
            okrMasterGridCss[0].style.marginBottom = '10';
          }

        }
      })();
    }
    if (props.okrMasterFlag === "updated") {
      props.updateOKRData({ okrMasterFlag: "" });
      if (masterId + "" === FREQUENCY) {
        (async () => {
          await OKRServices.getOKRAdminMasterData('0', "OPENRATING")
        })()
      }
    }
  }, [refresh, masterId, props.okrMasterFlag, currentFinancialYearId]);

  useEffect(() => {
    let gridHeight =
      (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight) - 320; if (props?.teamMemberData && props.masterId === 25) {
        setState((preState) => ({ ...preState, okrMasterData: [], }));
        setTimeout(() => {
          setState((preState) => ({
            ...preState,
            gridHeight: gridHeight - 20,
            okrMasterData: props?.teamMemberData,
          }));
        }, 0)
      }
  }, [props?.teamMemberData])


  const dataBound = () => {
    setInitialLoad(false);
    props.setCommonMasterLoading({ commonMasterLoading: false })
  };

  const onDataBinding = (pComponentId) => { // NOSONAR
    // spinner(true)  
    setInitialLoad(true);
    try {
      setTimeout(() => {
        document.getElementById(pComponentId).ej2_instances[0].refresh();
      });
    } catch (error) {
      console.log(error);
    }
  };
  const onFirstGridCreated = () => {
    setInitialLoad(true);
  };

  const actionTemplate = (data) => {
    try {
      const onSelect = (args) => {
        if (args.item.text === "Edit") {
          if (masterId === 25) {
            props.changePopUpHandler("edit", data.axisMasterId, masterId);
          } else {
            props.changePopUpHandler("edit", data.masterItemId, data.fyId);
          }
        }
        else if (args.item.text === "Active") {
          let activeData = {
            userId: authUser,
            masterItemId: data.masterItemId,
            masterId: masterId,
            isActive: "1",
          };
          props.updateOkrMasterItemStatus(activeData);
        } else if (args.item.text === "Inactive") {
          let inActiveData = {
            userId: authUser,
            masterItemId: data.masterItemId,
            masterId: masterId,
            isActive: "0",
          };
          // props.saveAttendanceCluster(inActiveData)
          props.updateOkrMasterItemStatus(inActiveData);
        }
      };
      let isDisabled = data.isEditable !== '0' ? false : true;
      if (isDisabled) {
        return (
          <div
            className="col-12"
            style={{
              textAlign: "center",
              padding: "0px 20px 0px 0px",
              fontSize: '14px',
              color: '#000 !important',
              fontFamily: 'Arial !important',
              fontWeight: 500,
              letterSpacing: '0.03rem',
            }}
          >
            <TooltipComponent content={"Rating already opened for this financial year."} position="BottomCenter" cssClass="custom-edit-icon-popup" className="row1">
              <DropDownButtonComponent
                items={data.isActive === "Active" ? itemActive : itemInactive}
                select={onSelect}
                iconCss="e-icons e-more-vertical-2"
                cssClass="e-caret-hide e-custom e-vertical"
                iconPosition="Top"
                disabled={isDisabled}
              ></DropDownButtonComponent>
            </TooltipComponent>
          </div>
        );
      } else {
        return (
          <div
            className="col-12"
            style={{
              textAlign: "center",
              padding: "0px 20px 0px 0px",
              fontSize: '14px',
              color: '#000 !important',
              fontFamily: 'Arial !important',
              fontWeight: 500,
              letterSpacing: '0.03rem',
            }}
          >
            <div className="row1">
              <DropDownButtonComponent
                items={data.isActive === "Active" ? itemActive : itemInactive}
                select={onSelect}
                iconCss="e-icons e-more-vertical-2"
                cssClass="e-caret-hide e-custom e-vertical"
                iconPosition="Top"
              ></DropDownButtonComponent>
            </div>
          </div>
        );
      }

    } catch (error) {
    }
  };

  const actionTeamTemplate = (data) => {
    return (
      <div
        className="col-12"
        style={{
          // textAlign: "right",
          // padding: "0px 20px 0px 0px",
          fontSize: '14px',
          color: '#000 !important',
          fontFamily: 'Arial !important',
          fontWeight: 600,
          letterSpacing: '0.03rem',
          // display: "flex",
          // alignItems: "center",
          cursor: "pointer"
        }}
      >
        <div className='team-member-matrix-edit-icon-tooltip'><span className="mr-sm">{data[data.column.field]}</span>{data[data.column.field] !== '-' &&
          <TooltipComponent content={"Edit Settings"} position="BottomCenter" cssClass="custom-edit-icon-popup">
            <FontAwesomeIcon
              icon={faPen}
              className="team-edit-icon-style"
              onClick={() => changePopUpHandler("edit", data.userId, data)}
              id='edit-icon'
              size="lg"
            />
          </TooltipComponent>}
        </div>
      </div>
    );
  };
  const commonCellTemplate = (data) => {
    if (data[data.column.field] && data[data.column.field]?.length > 37 && data['column']['width'] <= 310) {
      return (
        <div
          className="col-12"
          style={{
            fontSize: '14px',
            color: '#000 !important',
            fontFamily: 'Arial !important',
            fontWeight: 600,
            letterSpacing: '0.03rem',
            cursor: "pointer"
          }}
        >
          <div>
            <TooltipComponent content={data[data.column.field]} position="BottomCenter" cssClass="custom-edit-icon-popup">
              <span className="mr-sm">{data[data.column.field].slice(0, 37) + '...'}</span>
            </TooltipComponent>
          </div>
        </div>
      );
    } else {
      return (
        <div
          className="col-12"
          style={{
            fontSize: '14px',
            color: '#000 !important',
            fontFamily: 'Arial !important',
            fontWeight: 600,
            letterSpacing: '0.03rem',
          }}
        >
          <div>
            <span className="mr-sm">{data[data.column.field]}</span>
          </div>
        </div>)
    }
  };


  const colorTemplateHex = (prop) => {
    const { blockHeadingColor } = prop;
    return (
      <div
        style={{
          backgroundColor: blockHeadingColor,
          color: blockHeadingColor,
          marginRight: 10,
          borderRadius: 3,
        }}
      >
        &nbsp;
      </div>
    );
  };
  const colorTemplateHex1 = (prop) => {
    const { blockBodyColor } = prop;
    return (
      <div
        style={{
          backgroundColor: blockBodyColor,
          color: blockBodyColor,
          marginRight: 10,
          borderRadius: 3,
        }}
      >
        &nbsp;
      </div>
    );
  };

  let finalWidthConfirm;
  let widthColumnConfirm = 0;

  getGridColumn(props.gridName)?.forEach((data) => {
    widthColumnConfirm = widthColumnConfirm + 20 + Number(data.width);
  });
  if (widthColumnConfirm <= state.gridWidth) {
    finalWidthConfirm = widthColumnConfirm;
  }
  else {
    finalWidthConfirm = state.gridWidth
  }
  const rowDataBound = (args) => {
    args.rowHeight = 40
  }
  const settings = { checkboxOnly: true, type: 'Multiple', persistSelection: true }

  // const [memberList, setMemberList] = useState([])

  // const handleRowSelection = (args) => {
  //   const { data } = args
  //   setMemberList(data)
  // }

  // const [isOneTwentyFiveScale, setIsOneTwentyFiveScale] = useState()

  // useEffect(() => {
  //   const isOneTwentyFiveScaleSet = window.innerHeight < 750
  //   setIsOneTwentyFiveScale(isOneTwentyFiveScaleSet);
  // }, []);

  const dataSourceChanged = (pComponentId) => {
    try {
      setTimeout(() => {
        if (document.getElementById(pComponentId) !== null) {
          document.getElementById(pComponentId).ej2_instances[0].refresh();
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  const pageSettings = {
    pageCount: 5,
    pageSize:
      (isOneTwentyFiveInThirteenHundred && (masterId + "" === FREQUENCY || masterId + "" === TEAM_MEMBER)) ? 2
        : isOneTwentyFiveInThirteenHundred ? 3
          : (isThirteenHundredBookmarkOn && (masterId + "" === FREQUENCY || masterId + "" === TEAM_MEMBER)) ? 5
            : isThirteenHundredBookmarkOn ? 6
              : (isOneTwentyFiveScale && (masterId + "" === FREQUENCY || masterId + "" === TEAM_MEMBER)) ? 8
                : isOneTwentyFiveScale ? 9
                  : (isSixteenHundred && (masterId + "" === FREQUENCY || masterId + "" === TEAM_MEMBER)) ? 9
                    : isSixteenHundred ? 10
                      : (isTenEightyHundred && (masterId + "" === FREQUENCY || masterId + "" === TEAM_MEMBER)) ? 12
                        : isTenEightyHundred ? 13
                          : ((window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight) > 767 && (masterId + "" === FREQUENCY || masterId + "" === TEAM_MEMBER)) ? 13
                            : (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight) > 767 ? 14
                              : 7
  }

  return (
    <>
      {(props.openPopup === 'add' || props.openPopup === 'edit') &&
        <div
          style={{
            height: document.getElementById('okrAdminContainer').clientHeight, width: document.getElementById('okrAdminContainer').clientWidth, zIndex: '9', cursor: 'no-drop', position: 'absolute', background: 'rgba(0,0,0,0.1)', top: 0,
            transition: 'background 0.1s ease', borderRadius: '0.4em'
          }}>
        </div>}
      <div className="col-sm-12 col-md-12 col-lg-12" id="OKR-AdminModuleId">
        <div className="team-table"  >
          <style>{SAMPLECSS}</style>
          {state.okrMasterData?.length > 0 && 
            <GridComponent
              id={"OKRMasterGrid"}
              gridLines="Both"
              dataSource={state.okrMasterData}
              height={state.gridHeight - 8}
              // width={finalWidthConfirm} // NO SONAR
              width={isOneTwentyFiveInThirteenHundred ? finalWidthConfirm - 3
                : isOneTwentyFiveInSixteenHundred && !isOneTwentyFiveInFourteenHundred && !isOneTwentyFiveInThirteenHundred
                  ? finalWidthConfirm + 6 : finalWidthConfirm} // NO SONAR
              allowFiltering
              filterSettings={{ type: "CheckBox" }}
              dataBound={dataBound}
              created={onFirstGridCreated}
              dataSourceChanged={dataSourceChanged("OKRMasterGrid")/*NOSONAR*/}
              enableStickyHeader={true}
              rowDataBound={rowDataBound}
              // rowSelected={(e) => { handleRowSelection(e) }}
              selectionSettings={settings}
              // selectedRowIndex={selectedRowIndex}
              // rowSelecting={(e)=>{handleRowSelection(e)}}
              // rowDeselecting={(e)=>{handleRowSelection(e)}}
              allowSelection
              allowPaging={true}
              pageSettings={pageSettings}
            // queryCellInfo={customCell}
            >
              <ColumnsDirective>
                {getGridColumn(props.gridName)?.map((item) => {
                  switch (item.colName) {
                    case ADMIN_MODULE_ACTION:
                      return (
                        <ColumnDirective
                          template={actionTemplate}
                          textAlign={`${item.align} !important`}
                          field={item.field}
                          headerText={item.colName}
                          width={item.width}
                          filter={false}
                          allowFiltering={false}
                        />
                      );
                    case PRODUCTIVITY_DESCRIPTION:
                    case SUB_POTENTIAL_CALCULATION_SELF_ATTRIBUTE:
                    case SUB_POTENTIAL_CALCULATION_SUP_ATTRIBUTE:
                    case BLOCK_MASTER_DESCRIPTION:
                    case SUB_POTENTIAL_CALCULATION_SUP_ATTRIBUTE_DESCRIPTION:
                    case SUB_POTENTIAL_CALCULATION_SELF_ATTRIBUTE_DESCRIPTION:
                    case TEAM_MEMBER_DEPARTMENT:
                      return (
                        <ColumnDirective
                          template={commonCellTemplate}
                          textAlign={`${item.align} !important`}
                          field={item.field}
                          headerText={item.colName}
                          width={item.width}
                        // filter={false}
                        // allowFiltering={false}
                        />
                      );
                    case TEAM_MEMBER_CHECKBOX:
                      return (
                        <ColumnDirective
                          filter={false}
                          textAlign={`${item.align}`}
                          type={item.type}
                          width={item.width}
                        // headerTemplate={headerTemplate}
                        // isFrozen={true}
                        // freeze='Left'
                        />
                      );
                    case TEAM_MEMBER_NAME:
                      return (
                        <ColumnDirective
                          // template={actionTeamTemplate}
                          filter={false}
                          textAlign={`${item.align} !important`}
                          field={item.field}
                          headerText={item.colName}
                          width={item.width}
                        // freeze='Left'
                        />
                      );
                    case TEAM_MEMBER_DATE_OF_FIRST:
                    case TEAM_MEMBER_DATE_OF_SECOND:
                      return (
                        <ColumnDirective
                          template={actionTeamTemplate}
                          filter={false}
                          textAlign={`${item.align} !important`}
                          field={item.field}
                          headerText={`\t${item.colName}`}
                          width={item.width}
                        // freeze='Left'
                        />
                      );
                    case TEAM_MEMBER_DATE_OF_LAST:
                      return (
                        <ColumnDirective
                          template={actionTeamTemplate}
                          filter={false}
                          textAlign={`${item.align} !important`}
                          field={item.field}
                          headerText={'    ' + `${item.colName}`}
                          width={item.width}
                        // freeze='Left'
                        />
                      );
                    case TEAM_MEMBER_DATE_OF_THIRD:
                    case TEAM_MEMBER_DATE_OF_AUG:
                    case TEAM_MEMBER_DATE_OF_SEP:
                    case TEAM_MEMBER_DATE_OF_OCT:
                    case TEAM_MEMBER_DATE_OF_NOV:
                    case TEAM_MEMBER_DATE_OF_DEC:
                    case TEAM_MEMBER_DATE_OF_JAN:
                    case TEAM_MEMBER_DATE_OF_MAR:
                    case TEAM_MEMBER_DATE_OF_FEB:
                      return (
                        <ColumnDirective
                          template={actionTeamTemplate}
                          filter={false}
                          textAlign={`${item.align} !important`}
                          field={item.field}
                          headerText={item.colName}
                          width={item.width}
                        // freeze='Left'
                        />
                      );
                    case BLOCK_MASTER_PRIMARY_COLOR_NAME:
                      return (
                        <ColumnDirective
                          template={colorTemplateHex}
                          filter={false}
                          textAlign={`${item.align} !important`}
                          field={item.field}
                          headerText={item.colName}
                          width={item.width}
                        />
                      );
                    case BLOCK_MASTER_SECONDRY_COLOR_NAME:
                      return (
                        <ColumnDirective
                          template={colorTemplateHex1}
                          filter={false}
                          textAlign={`${item.align} !important`}
                          field={item.field}
                          headerText={item.colName}
                          width={item.width}
                        />
                      );
                    case PERFORMANCE_MASTER_NAME:
                      return (
                        <ColumnDirective
                          filter={false}
                          textAlign={item.align}
                          field={item.field}
                          headerText={masterId == PERFORMANCE ? item.colName : "Potential Name"}
                          width={item.width}
                        />
                      );
                    case APPLICABLE_PERIOD:
                      return (
                        <ColumnDirective
                          // filter={false}
                          // textAlign={item.align}
                          field={""}
                          headerText={item.colName}
                          width={item.width}
                          columns={
                            [
                              {
                                field: FREQUENCY_MASTER_APPLY_PERIOD_FROM_FIELD,
                                headerText: FREQUENCY_MASTER_APPLY_PERIOD_FROM,
                                width: '125',
                                textAlign: FREQUENCY_MASTER_APPLY_PERIOD_FROM_ALIGN
                              },
                              {
                                field: FREQUENCY_MASTER_APPLY_PERIOD_TO_FIELD,
                                headerText: FREQUENCY_MASTER_APPLY_PERIOD_TO,
                                width: '125',
                                textAlign: FREQUENCY_MASTER_APPLY_PERIOD_TO_ALIGN
                              },
                            ]
                          }
                        />
                      );
                    case PERFORMANCE_OKR:
                      return (
                        <ColumnDirective
                          // filter={false}
                          // textAlign={item.align}
                          field={""}
                          headerText={item.colName}
                          width={item.width}
                          columns={[
                            {
                              field: FREQUENCY_ADD_EDIT_PERFORM_ST_DATE_FIELD,
                              headerText: FREQUENCY_ADD_EDIT_PERFORM_ST_DATE,
                              width: '135',
                              textAlign: FREQUENCY_ADD_EDIT_PERFORM_ST_DATE_ALIGN
                            },
                            {
                              field: FREQUENCY_ADD__PERFORM_END_DATE_FIELD,
                              headerText: FREQUENCY_ADD__PERFORM_END_DATE,
                              width: '125',
                              textAlign: FREQUENCY_ADD__PERFORM_END_DATE_ALIGN
                            },
                            {
                              field: FREQUENCY_EDIT__PERFORM_END_DATE_FIELD,
                              headerText: FREQUENCY_EDIT__PERFORM_END_DATE,
                              width: '125',
                              textAlign: FREQUENCY_EDIT__PERFORM_END_DATE_ALIGN
                            },
                          ]}
                        />
                      );
                    case POTENTIAL_OKR:
                      return (
                        <ColumnDirective
                          // filter={false}
                          // textAlign={item.align}
                          field={""}
                          headerText={item.colName}
                          width={item.width}
                          // headerTemplate={headerTemplate}
                          columns={[
                            {
                              field: FREQUENCY_ADD_EDIT_POTENTIAL_ST_DATE_FIELD,
                              headerText: FREQUENCY_ADD_EDIT_POTENTIAL_ST_DATE,
                              width: '135',
                              textAlign: FREQUENCY_ADD_EDIT_POTENTIAL_ST_DATE_ALIGN
                            },
                            {
                              field: FREQUENCY_ADD__POTENTIAL_END_DATE_FIELD,
                              headerText: FREQUENCY_ADD__POTENTIAL_END_DATE,
                              width: '125',
                              textAlign: FREQUENCY_ADD__POTENTIAL_END_DATE_ALIGN
                            },
                            {
                              field: FREQUENCY_EDIT__POTENTIAL_END_DATE_FIELD,
                              headerText: FREQUENCY_EDIT__POTENTIAL_END_DATE,
                              width: '125',
                              textAlign: FREQUENCY_EDIT__POTENTIAL_END_DATE_ALIGN
                            },
                          ]}
                        />
                      );
                    case SELF_SUP_FINAL_RATING:
                      return (
                        <ColumnDirective
                          // filter={false}
                          // textAlign={item.align}
                          field={""}
                          headerText={item.colName}
                          width={item.width}
                          // headerTemplate={headerTemplate}
                          columns={[
                            {
                              field: PFREQUENCY_SELF_SUP_FINAL_RATING_ST_DATE_FIELD,
                              headerText: FREQUENCY_SELF_SUP_FINAL_RATING_ST_DATE,
                              width: '125',
                              textAlign: FREQUENCY_SELF_SUP_FINAL_RATING_ST_DATE_ALIGN
                            },
                            {
                              field: FREQUENCY_SELF_RATING_END_DATE_FIELD,
                              headerText: FREQUENCY_SELF_RATING_END_DATE,
                              width: '105',
                              textAlign: FREQUENCY_SELF_RATING_END_DATE_ALIGN
                            },
                            {
                              field: FREQUENCY_SUP_RATING_END_DATE_FIELD,
                              headerText: FREQUENCY_SUP_RATING_END_DATE,
                              width: (FREQUENCY === masterId + "") && isOneTwentyFiveScale ? '147' : '148',
                              textAlign: FREQUENCY_SUP_RATING_END_DATE_ALIGN
                            },
                            {
                              field: FREQUENCY_FINAL_RATING_END_DATE_FIELD,
                              headerText: FREQUENCY_FINAL_RATING_END_DATE,
                              width: '110',
                              textAlign: FREQUENCY_FINAL_RATING_END_DATE_ALIGN
                            },
                          ]}
                        />
                      );
                    default:
                      return (
                        <ColumnDirective
                          // template={commonCellTemplate}
                          field={item.field}
                          width={item.width}
                          headerText={item.colName}
                          textAlign={item.align}
                          clipMode='Clip'
                        />
                      );
                  }
                })}
              </ColumnsDirective>
              <Inject services={[Page, DetailRow, Filter, Freeze, Selection]} />
            </GridComponent>
          }

          {state.okrMasterData?.length === 0 && masterId + "" === TEAM_MEMBER &&
            <div
              style={{
                "display": 'flex',
                "justifyContent": 'center',
                "alignItems": "center",
                "margin": (masterId + "" === TEAM_MEMBER) && isOneTwentyFiveInThirteenHundred ? "3rem 0 0 0" : "auto",
                "height": state.gridHeight,
                fontSize: 'large',
                flexDirection: 'column'
              }}>
              <img
                src={RatingImg}
                height={isOneTwentyFiveInSixteenHundred && (masterId + "" === TEAM_MEMBER) ? 100
                  : (masterId + "" === TEAM_MEMBER) && isSixteenHundred && !isOneTwentyFiveScale ? 100 : ""}
                alt="Image Not Found"
              />
              <div
                className='ratingwelcometext pt-sm'
                style={{
                  fontSize: isOneTwentyFiveInSixteenHundred && (masterId + "" === TEAM_MEMBER) ? "1.5rem"
                    : (masterId + "" === TEAM_MEMBER) && isSixteenHundred && !isOneTwentyFiveScale ? "1.5rem" : ""
                }}
              >
                Welcome to Team Member Matrix
              </div>
              <div
                className="pt-sm"
                style={{
                  fontSize: isOneTwentyFiveInSixteenHundred && (masterId + "" === TEAM_MEMBER) ? "1rem"
                    : (masterId + "" === TEAM_MEMBER) && isSixteenHundred && !isOneTwentyFiveScale ? "1rem" : ""
                }}
              >
                Please select team member and financial year.
              </div>
            </div>
          }

          <div className="error-message" id="spinnerContainer">

            {state.okrMasterData?.length === 0 && masterId + "" !== TEAM_MEMBER && !(initialLoad || commonMasterLoading) &&
              <ErrorMessage message={"No Data Available"} />
            }
          </div>
          <div className="loader-new" id="spinnerContainer">
            {(initialLoad || commonMasterLoading) && <Progressbar />}
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ auth, meetingmaster, okrreducer, adminreducer }) => {
  const { authUser } = auth;
  const { currentFinancialYearId, finacialYearData } = meetingmaster;
  const { okrMasterFlag, teamMemberSelectionList, commonMasterLoading } = okrreducer;
  const { fyId } = adminreducer;
  return {
    authUser,
    currentFinancialYearId,
    okrMasterFlag,
    finacialYearData,
    fyId,
    teamMemberSelectionList,
    commonMasterLoading
  };
};
export default connect(mapStateToProps, {
  updateOKRData,
  updateOkrMasterItemStatus,
  setTeamMemberSelectionData,
  setCommonMasterLoading
})(OkrMasterSetup);
