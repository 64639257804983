import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { Button } from "@material-ui/core";
import { saveRegularizationData } from '../../../../redux/actions/lna/lnaaction'
import ApprovalGrid from "../../../approvalgrid/approvalgrid";
import lnaservice from "../../../../services/lna/lnaservice";
import BREServices from "../../../../services/breservice";
import { BREModuleActions } from '../../../../assets/constants/constants'
import ConfirmationPopup from "../../../../utilities/confirmationpopup";

export const Regularizationswipe = (props) => {
  const { authUser } = props
  const [approvalData, setApprovalData] = useState(null);
  const { regularizationReason, appliedDated, appliedFor, regularizationType, regularizationDayBreak,
    regularizationApplicationId, attendanceId, dateDayBreak, regularizationTypeId
  } = props.dataToView

  const [state, setState] = useState({
    height: null,
    containerHeight: null,
    open:false
  })

  const getApprovalData = async (type, applicationId) => {
    if (authUser && applicationId) {
      const response = await lnaservice.getApprovalStatusMatrixData(authUser, type, applicationId);
      if (response && response.Result && Array.isArray(response.Result)) {
        setApprovalData(response.Result);
      }
    }
  }

  useEffect(() => {
    window.addEventListener("resize", updateDimensions()); /**nosonar */
  }, [])

  useEffect(() => {
    getApprovalData("Regularization", props.dataToView.regularizationApplicationId);
  }, [props]);

  const updateDimensions = () => {
    const containerHeight = window.innerHeight - 307
    const updateheight = window.innerHeight - 204;
    setState(preState => ({
      ...preState, height: updateheight,
      containerHeight: containerHeight,
    }))
  }

  const dateFormat = (dateString) => {
    const year = new Date(dateString).getFullYear();
    const month = ('0' + (new Date(dateString).getMonth() + 1)).slice(-2); // Months are zero-based
    const day = ('0' + new Date(dateString).getDate()).slice(-2);
    return `${year}-${month}-${day}`
  }

  const getBREDataForRegularization = async (pAttendanceId) => {
    const objData = { "int_attendance_id": pAttendanceId }
    return BREServices.getBREData(authUser, BREModuleActions.regularizationApplication, objData);
  }

  /**
   * Author: Chandrashekhar Bhagat.
   * Created: 11-December-2023
   * @description Cancelling Regularization.
   */
  const onCancelReg = async () => {
    let RegularizationSaveData = {
      userId: authUser,
      type: 'STATUS',
      appliedStartDate: dateFormat(appliedFor),
      appliedEndDate: dateFormat(appliedFor),
      dateDayBreak: dateDayBreak,
      regularizationAppliedUid: regularizationApplicationId,
      regularizationTypeId: regularizationTypeId
    }

    const getBREData = await getBREDataForRegularization(attendanceId);
    RegularizationSaveData = {
      ...RegularizationSaveData,
      supervisorApprovalRequired: getBREData.enum_supervisor_approval,
      hrApprovalRequired: getBREData.enum_HR_approval,
      autoApprovalRequired: getBREData.enum_auto_approval,
      noOfHrsToAutoApprove: getBREData.int_no_hrs_to_approval
    }
    props.saveRegularizationData(RegularizationSaveData)
    setState({...state, open: false});
    props.showViewMode(false, '', 'close')
  }

  return (
    <>
      <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
        <div style={{
          backgroundColor: '#F2F2F2',
          height: "3rem",
          display: "flex",
          borderStartEndRadius: "0.4em",
          borderStartStartRadius: "0.4em",
          alignItems: "center",
          paddingLeft: "15px",
          fontWeight: 600,
          width: '100%',
        }}
        >
          <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12"
            style={{ color: 'black', fontStyle: 'Circular Std Book', fontSize: '1rem', fontFamily: "Arial" }}
          >
            Regularization Details
          </div>
        </div>
      </div>
      <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12' style={{ overflowY: "auto", height: state.containerHeight }} >
        <div className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ padding: '20px 5px' }}>
          <div
            className="col-6 col-lg-6 col-md-6 col-sm-6 col-xs-6"
            style={{ paddingBottom: "20px" }}
          >
            <div className="regular-container-head">Regularizaton Type</div>
            <div className="regular-container-head-res">{regularizationType}</div>
          </div>
          <div
            className="col-6 col-lg-6 col-md-6 col-sm-6 col-xs-6"
            style={{ paddingBottom: "20px" }}
          >
            <div className="regular-container-head">Applied for Date</div>
            <div className="regular-container-head-res">{appliedFor}</div>
          </div>
          <div
            className="col-6 col-lg-6 col-md-6 col-sm-6 col-xs-6"
            style={{ paddingBottom: "20px" }}
          >
            <div className="regular-container-head">Attendance Type</div>
            <div className="regular-container-head-res">{regularizationDayBreak}</div>
          </div>
          <div
            className="col-6 col-lg-6 col-md-6 col-sm-6 col-xs-6"
            style={{ paddingBottom: "20px" }}
          >
            <div className="regular-container-head">Applied On</div>
            <div className="regular-container-head-res">{appliedDated}</div>
          </div>
          <div
            className="col-6 col-lg-6 col-md-6 col-sm-6 col-xs-6"
            style={{ paddingBottom: "20px" }}
          >
            <div className="regular-container-head">Reason</div>
            <div className="regular-container-head-res">{regularizationReason}</div>
          </div>
          {approvalData && <ApprovalGrid approvalData={approvalData} authUser={authUser} handleApprove={null} handleReject={null} />}
        </div>
      </div>
      <div className='topBorder' >
        <div
          className='col-sm-12 col-md-12 col-lg-12'
          style={{
            display: "flex",
            justifyContent: "right",
            alignItems: "center",
            textAlign: 'right',
            height: '70px',
            width: '100%',
            position: 'absolute',
            bottom: '0',
            paddingRight: "10px",
            borderTop: "1px solid rgb(204, 204, 204)"
          }}>
          <div>
            <Button
              className="leave-cancel-button"
              style={{ width: "80px" }}
              variant="contained"
              onClick={() => props.showViewMode(false, '', 'close')}
            >
              Close
            </Button>
            {props.viewType === 'Cancel' ?
              <Button
                style={{ width: "auto", marginLeft: "10px" }}
                variant="contained"
                className="remove-button-shadow"
                color="primary"
                onClick={() => setState({...state, open:true})}
              >
                Cancel Regularization
              </Button> : ''}
          </div>
        </div>
      </div>
      <ConfirmationPopup
        headerMessage='Are you sure want to cancel the Regularization'
        button1Text={"Yes"}
        button2Text={"No"}
        button2function={()=>setState({...state, open: false})}
        button1function={onCancelReg}
        showButton={{ type: "1" }}
        open={state.open}
        handleConfirmClose={() => setState({ ...state, open: false })}
      />
    </>
  );
};

const mapStateToProps = ({ auth }) => {
  const { authUser } = auth
  return {
    authUser
  }
};

export default connect(mapStateToProps, { saveRegularizationData })(Regularizationswipe);