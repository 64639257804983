/*
 * Author : Vivek Khodade
 * Date : 30-Apr-2020
 * Description: TMLC IT Tabs Component
 */

import React, { Component } from "react";
import InitiatePrejoining from "./initiateprejoining/initiateprejoining";
import RevokePrejoining from "./revokeprejoining/revokeprejoining";
import "./functions.css";
import "../../app/containers/tmlc/tmlc.css";

export default class FunctionsTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      revokeprejoining: 0,
      initiateprejoining: 0,
    };
    this.getInitiatePrejoingCount = this.getInitiatePrejoingCount.bind(this);
    this.getRevokePrejoingCount = this.getRevokePrejoingCount.bind(this);
  }

  componentDidMount() {
    localStorage.removeItem("SelectedTabAction");
    document.getElementById("InitiatePrejoining").style.display = "block";
    document.getElementById("RevokePrejoining").style.display = "block";
  }

  getInitiatePrejoingCount = (count) => {
    this.setState({
      initiateprejoining: count,
    });
  };

  getRevokePrejoingCount = (count) => {
    this.setState(
      {
        revokeprejoining: count,
      },
      () => {
        setTimeout(() => {
          document.getElementById("RevokePrejoining").style.display = "none";
        }, 100);
      }
    );
  };

  openTab = (evt, tabName) => {
    let i;
    const event = evt;
    const tabcontent = document.getElementsByClassName("it-tab-content");
    // eslint-disable-next-line
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }
    const tablinks = document.getElementsByClassName("tablinks");
    // eslint-disable-next-line
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }
    document.getElementById(tabName).style.display = "block";
    event.currentTarget.className += " active";
  };

  render() {
    const { initiateprejoining, revokeprejoining } = this.state;
    return (
      <div>
        <div className="col-12 tabs-padding">
          <div className="it-tab">
            <button
              type="submit"
              className="col-6 tab-button tablinks active"
              onClick={(e) => this.openTab(e, "InitiatePrejoining")}
            >
              <b>INITIATE PRE-JOINING</b>
              <span className="confirmation-tab-count-dot">
                {initiateprejoining}
              </span>
            </button>
            <button
              type="submit"
              className="col-6 tab-button tablinks "
              onClick={(e) => this.openTab(e, "RevokePrejoining")}
            >
              <b>REVOKE PRE-JOINING</b>
              <span className="confirmation-tab-count-dot">
                {revokeprejoining}
              </span>{" "}
            </button>
          </div>

          <div id="InitiatePrejoining" className="it-tab-content">
            <InitiatePrejoining
              getInitiatePrejoingCount={this.getInitiatePrejoingCount}
            />
          </div>
          <div id="RevokePrejoining" className="it-tab-content">
            <RevokePrejoining
              getRevokePrejoingCount={this.getRevokePrejoingCount}
            />
          </div>
        </div>
      </div>
    );
  }
}
