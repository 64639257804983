/**
 * Author: Keval Charla.
 * Created:11-July-2022
 * @description to view all list of holidays for user
 */

import React, { useState, useRef, useEffect } from 'react';
import { ListViewComponent } from '@syncfusion/ej2-react-lists';
import { Dropdown, DropdownMenu, DropdownToggle, } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import ReactHtmlParser from 'react-html-parser';
import "./holidaylist.css";
import { Button } from "@material-ui/core";

function HolidayRulesAndFilter(props) {
  const [iconDropdown, setIconDropdown] = useState(false);
  const [showHolidayRules, setShowHolidayRules] = useState(false);
  const holidayFilter = useRef();
  const [count, setCount] = useState(4);

  /**
   * Author: Keval Charla  *
   * @description open or hide filter dropdown
   */
   useEffect(() => {
    if (holidayFilter.current && holidayFilter.current.getSelectedItems().data && holidayFilter.current.getSelectedItems().data.length === 0){
      setCount(4)
    }
  }, []); 


  const getHelp = () => {
    setIconDropdown(prevState => !prevState);
  };

  /**
   * @author Keval Charla
   * @description disable holiday list filter ui color
   */

  const cancelEvent = () => {
    const checkedFilter = props.yearViewFilterList;
    setCount(4)
    setIconDropdown(false);

    checkedFilter.forEach(filter => {
      if (filter.isChecked) {
        props.handleDisableFilter("no");
      } else {
        props.handleDisableFilter("yes");
      }
    });
  }


  const onCheckBoxClick = (arg) => {
    props.handleFilterCheckBox(arg.data, 'yearly')

    if (holidayFilter.current.getSelectedItems().data && holidayFilter.current.getSelectedItems().data.length === 0) {
      setCount(0)
    } else {
      setCount(4)
    }
  }


  /**
   * @author Keval Charla
   * @description apply holiday list filter
   */

  const submitEvent = () => {
    const checkedHoliday = [];
    const checkedFilter = props.yearViewFilterList;

    props.handleHolidayFilter(holidayFilter.current.getSelectedItems().data);
    holidayFilter.current.getSelectedItems().data.forEach(ele => {
      checkedHoliday.push(ele.id)
    });

    checkedFilter.forEach(filter => {
      if (checkedHoliday.includes(filter.id)) {
        filter.isChecked = true;
      } else {
        filter.isChecked = false;
      }
    })
    props.handleYearlyFilterData(checkedFilter);
    setIconDropdown(false);
    if (checkedHoliday.length > 0) {
      props.handleDisableFilter("no");
    } else {
      props.handleDisableFilter("yes");
    }
  }

  const handleHolidayRules = () => {
    setShowHolidayRules(prevState => !prevState);
  }

  return (
    <div id="holidayListRulesAndFilters" className="holiday-filters">
      {props.holidayRulesData !== "" &&
        <div className="holiday-rules-container">
          <div className="holiday-rules" onClick={handleHolidayRules}>Holiday Rules <FontAwesomeIcon title="toggle holiday rules" style={{ marginLeft: ".1rem" }} icon={showHolidayRules ? faAngleUp : faAngleDown} /></div>
          <div className={showHolidayRules ? `holiday-rules-text holiday-rules-show` : `holiday-rules-text`}>{ReactHtmlParser(props.holidayRulesData)}</div>
        </div>
      }
      <div>
        <Dropdown className="quick-menu" isOpen={iconDropdown} toggle={getHelp}>          
          <DropdownToggle className="holiday-filter-icon-and-text" tag="span">
            <div className='holiday-filter-text'>
              Filters
            </div>
            <div className='holiday-filter-icon'>
              <FontAwesomeIcon title="filter" style={{ color: props.disableFilter ? 'red' : 'black', cursor: "pointer" }} icon={faFilter} />
            </div>
          </DropdownToggle>          

          <DropdownMenu className="holiday-filter-menu">
            <div className='drop-menu-3'>
              {/* <div className='filter-title'>Select Holidays / Workdays</div> */}
              <ListViewComponent id='filter-checkbox-items'
                dataSource={props.yearViewFilterList}
                showCheckBox={true}
                ref={holidayFilter}
                showHeader={false}
                select={onCheckBoxClick}>
              </ListViewComponent>
              <div className='holiday-list-filter-buttons'>
                <button className='holiday-cancel-button' onClick={cancelEvent}>Cancel</button>
                {/* <button className='holiday-filter-button' onClick={submitEvent}>Apply</button> */}
                <Button
                  onClick={submitEvent}
                  style={{ color: !count <= 0 ? "white" : "#B7B7BB", backgroundColor: !count <= 0 ? "#f94f5e" : "#DBDBDD", padding: '2px 15px', margin: '0px 10px 0px 0px', minWidth: '74px' }}
                  disabled={count <= 0}
                  variant="contained"
                  title="Apply">
                  Apply
                </Button>
              </div>
            </div>
          </DropdownMenu>

        </Dropdown>
      </div>
    </div>
  )
}

export default HolidayRulesAndFilter;
