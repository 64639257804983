import React,{ useState, useEffect } from "react";
import { Button } from "@material-ui/core";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { connect } from 'react-redux'
import IconButton from "@material-ui/core/IconButton";
import "../../app/layout/okrratinglayout/okrrating.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExternalLinkAlt, faEye } from "@fortawesome/free-solid-svg-icons";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ViewInterimReviewModal from './viewinterimreviewmodal';
import AddEditInterimReviewModal from "./addeditinterimreviewmodal";
import {areYouSureYouWantToRemoveInterimFeedback} from '../../assets/constants/constants'
import { addUpdateOkrRatingRedux, addEditInterimReviewFeedback } from "../../redux/actions/okrrating/okrratingaction";
import ConfirmationPopup from "../../utilities/confirmationpopup";
function InterimFeedbackGridData(props) {
  const [openMenu, setOpenMenu] = useState(false)
  const [isDelete, setIsDelete] = useState(false)
  const [viewInterimReview, setInterimReviewData] = useState({ viewInterimReviewPopup: false, viewInterimReviewData: {} })
  const [positionX, setPositionX] = useState('')
  const [positionY, setPositionY] = useState('')
  const [IFData, setIFData] = useState({})
  const [editInterimPopup, setEditInterimPopup] = useState(false)

  const openObjectiveMenuPopup = (event, item) => {
    // setAnchorElKR(event.currentTarget)
    setPositionX(event.pageX)
    setPositionY(event.pageY)
    setOpenMenu(true);
    setIFData(item)

  };
  const onDeleteInterimFeeback = () => {
    setIsDelete(true)
    setOpenMenu(false)
  };

  const ratingType = () => {
    let type
    switch (props.ratingTabs) {
      case "self-rating":
        type = "self"; break;
      case "supervisor-rating":
        type = "sup"; break;
      case "thirdparty-rating":
        type = "final"; break;
      default:
    }
    return type;
  }
  const editInterimFeedbackMenuPopup = (type) => {
    if (type === 'edit-view') {

      setEditInterimPopup(true)
    }
    setOpenMenu(false)
  };
  const closeAddEditIntrimFeedbackPopup = () => {
    setEditInterimPopup(false);
    setIFData({});
  }
  const closeViewInterimReviewPopup = () => {
    setInterimReviewData({ ...viewInterimReview, viewInterimReviewPopup: false, viewInterimReviewData: {} });
  }
  const { interimFeedbackData } = props;
  const allInterimReview = () => {
    return (
      <>
        <table className="table table-hover table-bordered scrollbar">
          <thead
            style={{ position: "sticky", top: 0, backgroundColor: '#F4F4F7', zIndex: 11 }}
            className="okr-rating-objective-grid-kr-header-text"
          >
            <tr>
              <th
                style={{
                  textAlign: "center",
                }}
              >
                Date
              </th>
              <th>Key Result(s)</th>
              <th>Comments</th>
              <th style={{ textAlign: "center" }}>
                Uploaded
                <br />
                Document
              </th>
              <th
                style={{
                  textAlign: "center",
                }}
              >
                Link
              </th>
            </tr>
          </thead>
          <tbody className="okr-interim-review-font">
            {interimFeedbackData.length && interimFeedbackData.map((item, index) => {
              return (
                <tr key={index}>
                  <td style={{ textAlign: "center" }}>
                    <div className='interim-date-text'>
                      {item.interimAddedOn}
                    </div>
                  </td>
                  <td><div className='interim-kr-text' onClick={() => setInterimReviewData({ ...viewInterimReview, viewInterimReviewPopup: true, viewInterimReviewData: item })}>{item.kpi}</div></td>
                  <td><div className='interim-comment-text-all interim-comment-text-submited' onClick={() => setInterimReviewData({ ...viewInterimReview, viewInterimReviewPopup: true, viewInterimReviewData: item })}> {item.interimRemarks}</div></td>
                  <td style={{ textAlign: "center" }}>
                    <span className='interim-feedback-icon-color'>
                      <Button style={{ width: 40 }} onClick={() => setInterimReviewData({ ...viewInterimReview, viewInterimReviewPopup: true, viewInterimReviewData: item })}>
                        <FontAwesomeIcon
                          className='interim-feedback-icon-color'
                          style={{ color: "#F94F5E" }}
                          icon={faEye}
                        />
                      </Button>
                    </span>
                  </td>
                  <td style={{ textAlign: "center" ,width:85}}>
                    <span className='interim-feedback-icon-color'>
                    {item.interimReviewLink !=='' ? <a href={`${item.interimReviewLink}`} rel='noreferrer' target='_blank'>
                      <Button>
                        {" "}
                        <FontAwesomeIcon
                          className='interim-feedback-icon-color'
                          style={{ color: "#F94F5E" }}
                          icon={faExternalLinkAlt}
                        />
                      </Button>
                    </a>:
                     ''
                    }
                    </span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </>
    )
  }

  
  useEffect(() => {
 
    if (props.refreshInterimfeedbackflag === 'updated') {
      setEditInterimPopup(false)
      props.addUpdateOkrRatingRedux({ refreshInterimfeedbackflag: '' })
    }
    // eslint-disable-next-line
  }, [props.refreshInterimfeedbackflag]);

  const draftInterimReview = () => {
    return (
      <table className="table table-hover table-bordered scrollbar" width='100%'>
        <thead
          style={{ position: "sticky", top: 0, backgroundColor: '#F4F4F7', zIndex: 11 }}
          className="okr-rating-objective-grid-kr-header-text"
        >
          <tr>
            <th
              style={{
                marginRight: "24px",
                textAlign: "center",
              }}
            >
              Date
            </th>
            <th>Key Result(s)</th>
            <th>Comments</th>
            <th style={{ textAlign: "center" }}>
              Uploaded
              <br />
              Document
            </th>
            <th
              style={{
                textAlign: "center",
              }}
            >
              Link
            </th>
            <th
              style={{
                textAlign: "center",
              }}
            >
              &nbsp;
            </th>
          </tr>
        </thead>
        <tbody className="okr-interim-review-font">
          {interimFeedbackData.length && interimFeedbackData.map((item, index) => {
          
            return (
              <tr key={index}>
                <td>
                  <div className='interim-date-text'>{item.interimAddedOn}</div>
                </td>
                <td><div className='interim-kr-text' onClick={() => setInterimReviewData({ ...viewInterimReview, viewInterimReviewPopup: true, viewInterimReviewData: item })}>{item.kpi}</div></td>

                <td> <div className='interim-comment-text' onClick={() => setInterimReviewData({ ...viewInterimReview, viewInterimReviewPopup: true, viewInterimReviewData: item })} > {item.interimRemarks}</div></td>
                <td style={{ textAlign: "center" }}>
                  <span className='interim-feedback-icon-color'>
                    <Button onClick={() => setInterimReviewData({ ...viewInterimReview, viewInterimReviewPopup: true, viewInterimReviewData: item })}>

                      {" "}
                      <FontAwesomeIcon
                        className='interim-feedback-icon-color'
                        style={{ color: "#F94F5E" }}
                        icon={faEye}
                      />
                    </Button>
                  </span>
                </td>
                <td style={{ textAlign: "center" ,width:85}}>
                  <span className='interim-feedback-icon-color'>
                    {item.interimReviewLink !=='' ? <a href={`${item.interimReviewLink}`} rel='noreferrer' target='_blank'>
                      <Button>
                        {" "}
                        <FontAwesomeIcon
                          className='interim-feedback-icon-color'
                          style={{ color: "#F94F5E" }}
                          icon={faExternalLinkAlt}
                        />
                      </Button>
                    </a>:
                      ""
                    }
                  </span>
                </td>
                <td style={{ textAlign: "center" }}>
                  <IconButton
                    style={{ paddingBottom: "8px", width: 38, height: 20 }}
                    onClick={(e) => openObjectiveMenuPopup(e, item)}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Menu
                    // keepMounted
                    key={`menu_${item.interimReviewUid}`}
                    id={`menu_${item.interimReviewUid}`}
                    open={openMenu}
                    // anchorEl={anchorElKR}
                    style={{ margin: `${positionY - 8}px 0px 0px ${positionX - 130}px` }}
                    onClose={()=>setOpenMenu(false)}
                  >

                    <MenuItem
                      key={`menu_Item_Edit_View${item.interimReviewUid}`}
                      id={`menu_Item_Edit_View${item.interimReviewUid}`}
                      onClick={() => editInterimFeedbackMenuPopup('edit-view')}
                    >
                      <span
                        style={{
                          padding: " 0px",
                          color: "black",
                          fontFamily: "Arial",
                          fontSize: 14,
                          fontWeight: "Regular",
                        }}
                      >
                        Edit / View
                      </span>
                    </MenuItem>

                    <MenuItem>
                      <span
                        onClick={() => {
                          onDeleteInterimFeeback();
                        }}
                        style={{
                          padding: " 0px",
                          color: "black",
                          fontFamily: "Arial",
                          fontSize: 14,
                          fontWeight: "Regular",
                        }}
                      >
                        {" "}
                        Remove{" "}
                      </span>
                    </MenuItem>
                  </Menu>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>

    )
  }
  const removeInterimFeedback = () => {
    try {
      const type = ratingType()
      const data = {
        userId: parseInt(props.authUser, 10),
        empId: props.empId,
        ratingUId: IFData.ratingId,
        interimReviewUId: IFData.interimReviewUid,
        reviewRemarks: "dsdsdsd",
        reviewLinks: "",
        reviewType: type,
        reviewFiles: null,
        isDraft: false,
        OKRType: props.OKRType,
        isDelete: true
      }
      props.addEditInterimReviewFeedback(data)
    } catch (error) {
      console.log(error, "error")
    }
  }
  const onClickYes = () => {
    removeInterimFeedback();
    setIsDelete(true);
    setIFData({});
    setIsDelete(false);
  }
  const onClickNo = () => {
    setIFData({});
    setIsDelete(false);
  }

  const { selectedTab } = props;
  return (
    <>
      {
        selectedTab === 'All' && allInterimReview()
      }
      {
        selectedTab === 'Draft' && draftInterimReview()
      }
      {
        viewInterimReview.viewInterimReviewPopup &&
        <ViewInterimReviewModal
          closeViewInterimReviewPopup={closeViewInterimReviewPopup}
          viewInterimReviewData={viewInterimReview.viewInterimReviewData}
        />
      }
      {editInterimPopup &&
        <AddEditInterimReviewModal
          closeAddEditIntrimFeedbackPopup={closeAddEditIntrimFeedbackPopup}
          type={"Edit"}
          KRData={IFData}
          OKRType={props.OKRType}
          ratingTabs={props.ratingTabs}
        />
      }
      <ConfirmationPopup
        showButton={{ type: "1" }}
        open={isDelete}
        button1function={() => onClickYes()}
        button2function={() => onClickNo()}
        headerMessage={areYouSureYouWantToRemoveInterimFeedback}
        button1Text={"Yes"}
        button2Text={"No"}
        handleConfirmClose={onClickNo}
      />  

    </>
  );

}
const mapStateToProps = ({ okrratingreducer, auth }) => {

  const { refreshInterimfeedbackflag, empId } = okrratingreducer
  const { authUser } = auth
  return { refreshInterimfeedbackflag, empId, authUser }
}

export default connect(mapStateToProps, { addUpdateOkrRatingRedux, addEditInterimReviewFeedback })(InterimFeedbackGridData)

