import React, { useEffect, useState } from "react";
import { ComboBoxComponent } from "@syncfusion/ej2-react-dropdowns";
import { NumericTextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { SwitchComponent } from "@syncfusion/ej2-react-buttons";
import { connect } from "react-redux";
import okrServices from "../../services/okr/okrservices";
import ProgressBar from "../progressbar/progressbar";

import { savePotentialCalculationMaster, updateOKRData } from "../../redux/actions/okr/okractions";
import { FormValidator } from "@syncfusion/ej2-inputs";

import {
  pleaseSelectFyId, pleaseEnterWeightage, pleaseSelectTeamMemberTypeofRating, pleaseSelectPotentialType, pleaseSelectSubPotentialType
} from "../../assets/constants/constants";

const PotentialCalculationMaster = (props) => {
  const { authUser, openPopup, masterId, masterItemId, updateOKRData, loading } = props; //NOSONAR
  const [state, setState] = useState({
    potentialData: [],
    subPotentialData: [],
    potentialType: null,
    subPotentialType: null,
    subPotentialWeightage: null,
    mendatory: false,
    financialYear: props.currentFinancialYearId,
    ratingSystem: null
  })
  const [updateSave, setUpdateSave] = useState(false)
  useEffect(() => {
    if(updateSave===true){const {
      subPotentialType,
      subPotentialWeightage,
      mendatory,
      financialYear,
      ratingSystem
    } = state;
    const options = {
      rules: {
        potentialTypeErr: {
          required: [true, pleaseSelectPotentialType],
        },
        subPotentialTypeErr: {
          required: [true, pleaseSelectSubPotentialType],
        },
        weightageErr: {
          required: [true, pleaseEnterWeightage],
        },
        financialYearErr: {
          required: [true, pleaseSelectFyId],
        },
        typeofRatingErr: {
          required: [true, pleaseSelectTeamMemberTypeofRating],
        },
      },
    };
    const addFormObject = new FormValidator(
      "#potentialForm",
      options
    );
    if (addFormObject.validate()) {
      setTimeout(() => {
        try {
          const potentialSaveData = {
            userId: authUser,
            type: masterItemId > 0 ? "EDIT" : "ADD",
            masterId: masterItemId == null ? 0 : masterItemId,
            subPotentialId: subPotentialType,
            weightofSubPotential: subPotentialWeightage,
            mendatory: mendatory ? "1" : "0",
            fyId: financialYear,
            ratingSystemId: ratingSystem
          };
          updateOKRData({ loading: true });
          props.savePotentialCalculationMaster(potentialSaveData);
          setUpdateSave(false)
        } catch (e) {
          console.log(e)
          updateOKRData({ loading: false });
        }
      }, 1000)
    } else {
      setUpdateSave(false)
    }}
  }, [updateSave === true])

  useEffect(() => {
    getData(0, "GETPOTENTIALMASTER");
  }, [openPopup, masterId, masterItemId]);
  //Get Data for grid display
  const getData = (id, type) => {
    ["potentialTypeErr",
      "subPotentialTypeErr",
      "weightageErr",
      "financialYearErr",
      "typeofRatingErr"
    ].forEach(err => { document.getElementById(err).innerHTML = "" });
    setState({
      potentialData: [],
      subPotentialData: [],
      potentialType: null,
      subPotentialType: null,
      subPotentialWeightage: null,
      mendatory: 1,
      financialYear: props.currentFinancialYearId,
      ratingSystem: null
    });
    (
      async () => {
        updateOKRData({ loading: true });
        const potentialMasterData = await okrServices.getOKRAdminMasterData(id, type);
        if (type == "GETPOTENTIALMASTER") {
          setState((preState) => ({
            ...preState, potentialData: potentialMasterData.data.filter((data) => data.type == "potential-value")
          }));
        }
        if (openPopup === 'edit') {
          var okrMasterData = await okrServices.getOKRMaserData(
            authUser,
            masterId,
            masterItemId == null ? 0 : masterItemId
          );
          const {
            potentialTypeId,
            subPotentialTypeId,
            ratingSystemId,
            weightage,
            mandatory,
            financialyear
          } = okrMasterData?.Result[0];

          const subPotentialDataRes = await okrServices.getOKRAdminMasterData(potentialTypeId, 'GETSUBPOTENTIALVALUEDETAILS');
          setState((preState) => ({ ...preState, subPotentialData: subPotentialDataRes.data }));
          setState((preState) => ({
            ...preState, potentialType: potentialTypeId,
            subPotentialType: subPotentialTypeId,
            subPotentialWeightage: weightage,
            mendatory: mandatory == "1" ? true : false,
            financialYear: financialyear,
            ratingSystem: ratingSystemId
          }));
        }
        updateOKRData({ loading: false });
      }
    )();
  }

  useEffect(() => {
    if (props.okrMasterFlag === "updated") {
      props.changePopUpHandler("close");
    }
  }, [props.okrMasterFlag]);

  useEffect(() => {
    setTimeout(() => {
      props.setSubmitClick(onSaveData);
    }, 100);
  }, [state]);
  //get sub-potential type based on potential type
  const inputChangeHandlerPotentialType = (e, type) => {
    getSubPotentialData(e.value, "GETSUBPOTENTIALVALUEDETAILS");
    inputChangeHandler(e, type)
  }

  const getSubPotentialData = async (id, type) => {
    const potentialMasterData = await okrServices.getOKRAdminMasterData(id, type);
    setState((preState) => ({ ...preState, subPotentialData: potentialMasterData.data, subPotentialType: null }));
  }
  // validation check 
  const inputChangeHandler = (e, type) => {
    let options = {}
    switch (type) {
      case "potentialType":
        setState((prevState) => ({ ...prevState, potentialType: e.value }));
        options = {
          rules: {
            potentialTypeErr: {
              required: [true, pleaseSelectPotentialType],
            },
          },
        };
        break;
      case "subPotentialType":
        setState((prevState) => ({ ...prevState, subPotentialType: e.value }));
        options = {
          rules: {
            subPotentialTypeErr: {
              required: [true, pleaseSelectSubPotentialType],
            },
          },
        };
        break;
      case "subPotentialWeightage":
        setState((prevState) => ({ ...prevState, subPotentialWeightage: e.value }));
        if (Number(e.value) < 0) {
          document.getElementById('weightageErr').innerHTML = 'Weightage must be grater than 0.'
        }
        else if (Number(e.value) > 100) {
          document.getElementById('weightageErr').innerHTML = 'Weightage must be less than 100.'
        } else if (e.value == null) {
          document.getElementById('weightageErr').innerHTML = pleaseEnterWeightage
        } else {
          document.getElementById('weightageErr').innerHTML = ""
        }
        break;
      case "mendatory":
        setState((prevState) => ({ ...prevState, mendatory: e.checked }));
        break;
      case "financialYear":
        setState((prevState) => ({ ...prevState, financialYear: e.value }));
        options = {
          rules: {
            financialYearErr: {
              required: [true, pleaseSelectFyId],
            },
          },
        };
        break;
      case "ratingSystem":
        setState((prevState) => ({ ...prevState, ratingSystem: e.value }));
        options = {
          rules: {
            typeofRatingErr: {
              required: [true, pleaseSelectTeamMemberTypeofRating],

            },
          },
        };
        break;
      default:
    }

    setTimeout(() => {
      const addFormObject = new FormValidator("#potentialForm", options);
      addFormObject.validate();
    }, 100);
  }
  // Add/Edit Event
  const onSaveData = async () => {
    const defaultWeightage = document.getElementById('subPotentialWeightage')?.ej2_instances[0]?.value
    if (defaultWeightage !== null) {
      setState(prevState => ({ ...prevState, subPotentialWeightage: defaultWeightage }))
    }
    setUpdateSave(true)
  }

  return (
    <>
      <div id="potentialForm">
        <div style={{ padding: "16px" }}>
          <div className="col-xs-12 col-sm-12 col-lg-12 mt-sm mb-sm atc-no-paddding">
            <ComboBoxComponent
              id="potentialType"
              data-msg-containerid="potentialTypeErr"
              name="potentialTypeErr"
              dataSource={state.potentialData}
              fields={{ text: "Name", value: "Id" }}
              value={state.potentialType}
              placeholder="Potential Type *"
              floatLabelType="Auto"
              cssClass="e-outline"
              onChange={(e) => inputChangeHandlerPotentialType(e, "potentialType")}
              htmlAttributes={{ maxlength: 100 }}
            />
            <div
              id="potentialTypeErr"
              className="error-div"
            />
          </div>

          <div className="col-xs-12 col-sm-12 col-lg-12 mt-sm mb-sm atc-no-paddding">
            <ComboBoxComponent
              id="subPotentialType"
              data-msg-containerid="subPotentialTypeErr"
              name="subPotentialTypeErr"
              dataSource={state.subPotentialData}
              fields={{ text: "subPotential", value: "subPotentialId" }}
              value={state.subPotentialType}
              placeholder=" Sub Potential Name *"
              floatLabelType="Auto"
              cssClass="e-outline"
              change={(e) => inputChangeHandler(e, "subPotentialType")}
              htmlAttributes={{ maxlength: 100 }}
            />
            <div
              id="subPotentialTypeErr"
              className="error-div"
            />
          </div>
          <div className="col-xs-12 col-sm-12 col-lg-12 mt-sm mb-sm atc-no-paddding">
            {/* <div
              className="atc-switch-heading"
              style={{ fontSize: "14px", color: "#939399", fontWeight: 450 }}
            >
              Weightage of Sub Potential *
            </div>   */}
            <NumericTextBoxComponent
              floatLabelType="Auto"
              value={state.subPotentialWeightage}
              onChange={(e) => inputChangeHandler(e, "subPotentialWeightage")}
              id="subPotentialWeightage"
              placeholder="Weightage of Sub Potential *"
              cssClass="e-outline"
              data-msg-containerid="weightageErr"
              name="weightageErr"
              min={0}
              max={100}
              decimals={0}
              validateDecimalOnType={true}
              format={"###"} />
            <div
              id="weightageErr"
              className="error-div"
            />
          </div>
          <div className="col-xs-12 col-sm-12 col-lg-12 mt-sm mb-sm atc-no-paddding">
            <div
              className="atc-switch-heading"
              style={{ fontSize: "14px", color: "#939399", fontWeight: 450 }}
            >
              Mandatory
            </div>
            <div className="atc-switch-heading atc-switch-inline mt-sm">
              <div className="atc-switch-margin">
                <SwitchComponent
                  id="mendatory"
                  name='mendatory'
                  cssClass="atc-switch-height"
                  change={(e) => inputChangeHandler(e, 'mendatory')}
                  checked={state.mendatory}
                />
              </div>
              <div
                className="ml-md"
                style={{ fontSize: ".95rem", fontWeight: 500 }}
              >
                {!state.mendatory ? "No" : "Yes"}
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-lg-12 mt-sm mb-sm atc-no-paddding">
            <ComboBoxComponent
              id="financialYear"
              data-msg-containerid="financialYearErr"
              name="financialYearErr"
              dataSource={props.finacialYearData}
              fields={{ text: "Name", value: "Id" }}
              value={state.financialYear}
              placeholder="Financial Year *"
              floatLabelType="Auto"
              cssClass="e-outline"
              change={(e) => inputChangeHandler(e, "financialYear")}
              htmlAttributes={{ maxlength: 100 }}
            />

            <div
              id="financialYearErr"
              className=" error-div"
            />
          </div>
          <div className="col-xs-12 col-sm-12 col-lg-12 mt-sm mb-sm atc-no-paddding">
            <ComboBoxComponent
              id="ratingSystem"
              data-msg-containerid="typeofRatingErr"
              name="typeofRatingErr"
              dataSource={props.ratingSystem}
              fields={{ text: "Name", value: "Id" }}
              value={state.ratingSystem}
              placeholder="Rating Scale *"
              floatLabelType="Auto"
              cssClass="e-outline"
              change={(e) => inputChangeHandler(e, "ratingSystem")}
              htmlAttributes={{ maxlength: 100 }}
            />
            <div
              id="typeofRatingErr"
              className="error-div"
            />
          </div>
        </div>
      </div>
      {loading && <ProgressBar />}
    </>
  );
};

const mapStateToProps = ({ auth, okrreducer, meetingmaster }) => {
  const { authUser } = auth;
  const { currentFinancialYearId, finacialYearData } = meetingmaster;
  const { okrMasterFlag, ratingSystem, loading } = okrreducer;

  return {
    authUser,
    okrreducer,
    currentFinancialYearId,
    okrMasterFlag,
    finacialYearData,
    ratingSystem,
    loading
  };
};

export default connect(mapStateToProps, { savePotentialCalculationMaster, updateOKRData })(PotentialCalculationMaster);

