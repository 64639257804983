/**
* Author Name : Vivek Khodade.
* Created Date: -
* Last Modified Date: 15/04/2020.
* Last Modified By: Vivek Khodade.
* Code Reviewed Date : 08/09/2020
* Code Reviewed By : Vivek Khodade
*/


import * as React from "react";
import PropTypes from "prop-types";
import {
  TreeGridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Page,
  Freeze,
  Resize,
} from "@syncfusion/ej2-react-treegrid";
import { getObject } from "@syncfusion/ej2-grids";
import "./tibr.css";

const SAMPLECSS = `
.e-table{
  border-bottom-color: lightgray;
    border-bottom-style: solid;
    border-bottom-width: 1px;
}

.e-grid .e-rowcell{
  border-style: solid;
  border-width: 1px 0 0 0;
  display: table-cell;
  font-size: 11px !important;
  line-height: 18px !important;
  overflow: hidden;
  padding: 5px 0px 5px 0px !important;
  vertical-align: middle;
  /* white-space: pre-wrap !important; */
  width: auto;
}
.e-headertext {
  font-size:12px
}
`;

class TIBR extends React.Component {
  constructor(props) {
    super(props);
    const { dataSource, grayLeaves } = this.props;
    this.data = dataSource;
    this.grayLeaves = grayLeaves;
    this.queryCellInfo = this.queryCellInfo.bind(this);
    this.cellSelection = {
      cellSelectionMode: "Box",
      mode: "Cell",
      // type: 'Multiple'
    };
    this.width =
      (window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth) - 80;
    this.customAttributes = { class: "customcss-sat-sun" };
    this.customAttributesAVG = { class: "customcss-avg" };
    this.customAttributesELPL = { class: "customcss-EL-PL" };
    this.customAttributesAbsent = { class: "customcss-absent" };
  }

  componentDidMount = () => {
    setTimeout(() => {
      const ele = document.getElementsByClassName("e-headercelldiv");
      for (let i in ele) {
        if (!isNaN(parseInt(i, 10))) {
          let index = parseInt(i, 10);
          ele[index].classList.add("e-headercelldiv-custom");
        }
      }
      var elem = document.getElementsByClassName("e-content");
      for (let i in elem) {
        if (!isNaN(parseInt(i, 10))) {
          let index = parseInt(i, 10);
          if (elem[index].style.getPropertyValue("display") === 'flex')
            elem[index].style.removeProperty("display");
        }
      }
      this.treegridObj.enableCollapseAll = true;
    }, 1000);
  };

  /**
   * rowDataBound is for to apply css of tree grid view.
   */
  rowDataBound = (args) => {
    let getObjDesc = getObject("Description", args.data);
    switch (getObjDesc) {
      case "Attendance As Per HRMS (days) (ex TRG, TRA, OD)":
        args.row.classList.add("attendance-as-per-HRMS");
        break;
      case "Time Recorded in Office":
        args.row.classList.add("gross-time-recorded");
        break;
      case "Punch in":
        args.row.classList.add("punch-in");
        break;
      case "Punch Out":
        args.row.classList.add("punch-out");
        break;
      case "Break Time Outside Office":
        args.row.classList.add("break-time-outside-office");
        break;
      case "Net Time Recorded (Punch In/Out)":
        args.row.classList.add("net-time-recorded");
        break;
      case "Gross Time On System (B 1 + B 2 + C)":
      case "Gross Time On System":
        args.row.classList.add("gross-time-on-system");
        break;
      case "First Login":
        args.row.classList.add("first-login");
        break;
      case "Last Logout":
        args.row.classList.add("last-logout");
        break;
      case "Time Away From System":
        args.row.classList.add("time-away-from-system");
        break;
      case "Personal Time On System":
        args.row.classList.add("personal-time-on-system");
        break;
      case "Personal Time":
        args.row.classList.add("personal-time");
        break;
      case "Banking Sites":
        args.row.classList.add("banking-sites");
        break;
      case "Dating and Personal":
        args.row.classList.add("dating-and-personal");
        break;
      case "Entertainment":
        args.row.classList.add("entertainment");
        break;
      case "Travelling Sites":
        args.row.classList.add("travelling-sites");
        break;
      case "E_Commerce":
        args.row.classList.add("e_commerce");
        break;
      case "Non Work Related Application":
        args.row.classList.add("non_work_related_application");
        break;
      case "Non Work Video Streaming":
        args.row.classList.add("non_work_video_streaming");
        break;
      case "Unauthorized Content":
        args.row.classList.add("unauthorized_content");
        break;
      case "Games":
        args.row.classList.add("games");
        break;
      case "Jobs and Career":
        args.row.classList.add("jobs-and-career");
        break;
      case "Network Storage":
        args.row.classList.add("network-storage");
        break;
      case "News_and_Media":
        args.row.classList.add("news-and-media");
        break;
      case "Non work related Media player":
        args.row.classList.add("non-work-related-media-player");
        break;
      case "Non-categorized Personal time":
        args.row.classList.add("non-categorized-personal-time");
        break;
      case "Social Networking":
        args.row.classList.add("social-networking");
        break;
      case "Sports":
        args.row.classList.add("sports");
        break;
      default:
      // code block
    }

    switch (getObjDesc) {
      case "(Input) Time In Business":
        args.row.classList.add("time-in-business-background");
        break;
      case "(Input) Time On Productive Applications":
      case " (Input) Time On Productive Applications":
        args.row.classList.add("time-in-productive-applications");
        break;
      case "Business Communication":
      case "Business Communication - Skype":
      case "Business Communication - Teams":
      case "Business Communication - Zoom":
        args.row.classList.add("business-communication");
        break;
      case "Business Mail":
        args.row.classList.add("business-mail");
        break;
      case "Business Tool":
        args.row.classList.add("business-tool");
        break;
      case "Business URL":
        args.row.classList.add("business-URL");
        break;
      case "Cheers Internal Softwares/Applications/Folders":
        args.row.classList.add("cheers-internal-softwares-applications-folders");
        break;
      case "E-mail research Tools":
        args.row.classList.add("E-mail-research-tools");
        break;
      case "Linkedin":
        args.row.classList.add("linkedin");
        break;
      case "Media player":
        args.row.classList.add("media-player");
        break;
      case "Note Taking applications":
        args.row.classList.add("note-taking-applications");
        break;
      case "Office 365 applications":
        args.row.classList.add("Office-365-applications");
        break;
      case "Office Applications":
        args.row.classList.add("office-applications");
        break;
      case "Office Tools":
        args.row.classList.add("office-tools");
        break;
      case "PDF Reader":
        args.row.classList.add("PDF-reader");
        break;
      case "Prohance":
        args.row.classList.add("prohance");
        break;
      case "Remote Access Applications":
        args.row.classList.add("remote-access-applications");
        break;
      case "(Input) Time on Unknown Applications":
        args.row.classList.add("time-on-unknown-applications");
        break;
      case "(Input) Time on Neutral Applications":
        args.row.classList.add("time-on-neutrual-applications");
        break;
      default:
      // code block
    }
  };

  isNumeric = (value) => {
    return /^-{0,1}\d+$/.test(value);
  };

  /**
   *
   * @param {*} args
   * queryCellInfo function for filter data each cell wise.
   */
  queryCellInfo(args) { // NOSONAR
    const { cell } = args;
    const { data } = args;
    // || cell.innerHTML.toString().includes('A')) &&
    // cell.innerHTML.toString().includes('TRA')
    // console.log('cell.innerHTML ',cell.innerHTML);

    /* nosonar if (this.grayLeaves.some((leave) => leave.grayLeave === cell.innerHTML && leave.color === "red" ) && args.column.index !== 0 && args.column.index !== 1) {
       cell.setAttribute( "style", "font-weight:bold;text-align:center; color:#fff !important;background-color:#f94f5e;" );
    }*/

    // Add by:- Prashant Waphare | 20-01-2020 | Add super string	
    if (args.column.index !== 1 && cell.innerHTML.includes("+")) {
      var res = cell.innerHTML.split("+")[0];
      cell.innerHTML = res + " +1".sup();
    }
    // Add by:- Prashant Waphare | 20-01-2020 | remove 00:00 	
    if (cell.innerHTML.includes("00:00")) {
      cell.innerHTML = cell.innerHTML.replace("00:00", "&nbsp;");
      cell.setAttribute("style", "font-weight:500;text-align:center;color:#eee;");
    }
    if (cell.innerHTML.includes(":")) {
      if (cell.innerHTML.includes("00:00")) {
        // eslint-disable-next-line
      } else if (this.isNumeric(parseInt(cell.innerHTML, 10))) {
        if (cell.className.includes("customcss-EL-PL") && data.Description === '(Input) Time In Business') {
          cell.setAttribute("style", "text-align:center; color:#ffffff !important;");
        }
        else {
          cell.setAttribute("style", "font-weight:500;text-align:center;color:#3a3939;");
        }
      }
    }
    if (args.column.index === 0) {
      if (cell.innerHTML === "i") cell.innerHTML = "<i class='fa fa-info-circle' aria-hidden='true' style='font-size: 13px;color:gray'></i>";
    }
  }

  /**
   * cellSelected
   * this funcion gets the onClick event on each cell.
   */
  cellSelected() {
    console.log('Cell Selected');
  }
  beforeDataBound(args) {
    this.grid.resizeSettings.mode = "Auto";
  }
  load() {
    let cols = this.columns;
    let width = 0;
    width = cols.reduce(function (prev, cur) { return prev + cur.width; }, 0);
    if (width < 1830) width = width + 21; // setting total columns width to Tree Grid
    else width = 1830;

    if (width < this.width) {
      this.width = width;
    }
  }
  renderNestedTernary(type, item) { // NOSONAR
    const { viewBySelected } = this.props;
    if (type === 'customAttributes') {
      if (viewBySelected === 7) {
        if (item.includes("AVG")) {
          return this.customAttributesAVG;
        }
      } else if (viewBySelected === 8) {
        if (item.includes("-")) {
          return {};
        } else {
          if (item.includes("AVG")) {
            return this.customAttributesAVG;
          }
        }
      } else {
        return {};
      }
    }
    else if (type === 'headerText') {
      if (item === "Description") return "   Description";
      else return item;
    }
    else if (type === 'width') {
      if (item === "Info") return 20;
      else if (item === "Rank") return 40;
      else return 50;
    }
  }
  renderCustomAttributes(item, itemVal) {
    if ((item.includes("Sat") && itemVal === "WO") || item.includes("Sun") || this.grayLeaves.some((leave) => leave.grayLeave === itemVal && leave.color === "gray")) {
      return this.customAttributes;
    }
    else if (this.grayLeaves.some((leave) => leave.grayLeave === itemVal && leave.color === "blue")) {
      return this.customAttributesELPL;
    }
    else if (this.grayLeaves.some((leave) => leave.grayLeave === itemVal && leave.color === "red")) {
      return this.customAttributesAbsent;
    }
    else {
      return this.renderNestedTernary('customAttributes', item);
    }
  }
  render() {
    this.cellSelected = this.cellSelected.bind(this);
    return (
      <div className="control-pane">

        <div className="control-section">
          <div
            className="col-md-12 force-overflow"
            style={{ marginLeft: 5, marginBottom: 25, paddingRight: 10 }}
          >
            <style>{SAMPLECSS}</style>
            <TreeGridComponent
              className="tibrReport"
              dataSource={this.data}
              rowDataBound={this.rowDataBound}
              queryCellInfo={this.queryCellInfo}
              // beforeDataBound={this.beforeDataBound}
              load={this.load}
              rowHeight={20}
              treeColumnIndex={1}
              childMapping="subtasks"
              allowPaging={false}
              ref={(treegrid) => {
                this.treegridObj = treegrid;
                return this.treegridObj;
              }}
              frozenColumns={2}
              pageSettings={{ pageSize: 43 }}
              allowSelection={false}
              allowResizing="true"
              allowTextWrap="true"
              cellSelected={this.cellSelected}
              selectionSettings={this.cellSelection}
              textWrapSettings={{ wrapMode: "Header" }}
              gridLines="Both"
              enableCollapseAll={true}
              height={280}
              width={this.width}
            >
              <ColumnsDirective>
                {Object.keys(this.data[0]).map(
                  (item, index) =>
                    item !== "taskID" && item !== "EmployeeID" && item !== "Name" && item !== "Title" && item !== "System" && item !== "Info" && item !== "ToolTip" && (
                      <ColumnDirective
                        // eslint-disable-next-line
                        key={index}
                        field={item}
                        customAttributes={this.renderCustomAttributes(item, this.data[0][item])}
                        headerText={
                          // eslint-disable-next-line
                          item === "Rank" || item === "Info" ? "Rank" : this.renderNestedTernary('headerText', item)
                        }
                        textAlign={item === "Description" ? "Left" : "Center"}
                        // minWidth={
                        //   // eslint-disable-next-line
                        //   item !== "Description" && item !== "Rank" && item !== "Info" ? 50 : ""
                        // }
                        width={
                          // eslint-disable-next-line
                          item === "Description" ? 280 : this.renderNestedTernary('width', item)
                          // : // eslint-disable-next-line
                        }
                      // maxWidth={
                      //   // eslint-disable-next-line
                      //   item === "Description"
                      //     ? 280 : item === "Info" ? 20 : 50
                      // }
                      />
                    )
                )}
              </ColumnsDirective>
              <Inject services={[Freeze, Resize, Page]} />
            </TreeGridComponent>
          </div>
        </div>
      </div>
    );
  }
}

TIBR.propTypes = {
  dataSource: PropTypes.arrayOf(Object).isRequired,
  grayLeaves: PropTypes.arrayOf(Object).isRequired,
  // eslint-disable-next-line
  // hideSidebar: PropTypes.object.isRequired,
  noOfUses: PropTypes.number.isRequired,
};
TIBR.defaultProps = {};

export default TIBR;
