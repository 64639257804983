import React, { Component } from 'react';
import { Button } from '@material-ui/core';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import ConfirmDialog from '../../confirmdialog/confirmdialog';
import { submitTerminationData, addUpdateSeparationDetails } from '../../../redux/actions/entryexit/separationaction';
import { getNotify, dencrypt, getDate,encodeString } from '../../../app/other/commonfunction';
import { EMPLOYEE_LIST } from '../../../redux/constant/meetingcategoryconstant';
import { setMeetingCategory } from '../../../redux/actions/meetingcategoryaction';
import { ERROR, selectTerminationGrounds, selectTerminationRequestFor, enterTerminationManagerRemark, selectSuspensionDaysFor, selectLastWorkingDate } from '../../../assets/constants/constants';
class TerminationFooterButtons extends Component {
  constructor(props) {
    super(props);
    this.state = {
      button1Caption: '',
      button2Caption: '',
      title: '',
      message: '',
      open: false,
      redirect: null,
      redirestCategary: "",
    };
    this.onSubmitAbsconding = this.onSubmitAbsconding.bind(this);
  }
  onSubmitAbsconding = () => {
    const { open } = this.state;
    const { terminationGrounds, terminationRemark, terminationRequestFor, suspensionForDays, terminationLWD } = this.props;
    if (terminationRequestFor === 0) {
      getNotify(ERROR, selectTerminationRequestFor);
      return;
    }
    if (terminationGrounds === 0 || terminationGrounds === "") {
      getNotify(ERROR, selectTerminationGrounds);
      return;
    }
    if (terminationRequestFor === 680 && suspensionForDays === 0) {
      getNotify(ERROR, selectSuspensionDaysFor);
      return;
    }
    if (terminationRequestFor === 679 && (terminationLWD === null || terminationLWD === '')) {
      getNotify(ERROR, selectLastWorkingDate);
      return;
    }
    if (terminationRemark === "") {
      getNotify(ERROR, enterTerminationManagerRemark);
      return;
    }
    this.setState({
      open: !open,
      button2Caption: 'No',
      button1Caption: 'Yes',
      title: 'Termination',
      message: `Are you sure, you want to  ${terminationRequestFor === 679 ? 'terminate' : 'suspend'} this Employee?`,
    });
  }
  // componentDidMount() {
  //   this.getResignationInfo()
  // }
  handleSubmitYes = () => {
    try {
      const { open } = this.state;
      const { terminationGrounds, terminationRemark, terminationRequestFor, authUser, suspensionForDays, terminationLWD } = this.props;
      const { search } = window.location;
      const params = new URLSearchParams(search);
      const empId = dencrypt(params.get("empId"))
      // eslint-disable-next-line
      this.props.submitTerminationData({
        "terminationGrounds": terminationGrounds,
        "terminationRemark": terminationRemark,
        "terminationRequestFor": terminationRequestFor,
        "userId": authUser,
        "empId": empId,
        "suspensionForDays": terminationRequestFor === 680 ? suspensionForDays : null,
        "terminationLWD": terminationRequestFor === 679 ? getDate(new Date(terminationLWD)) : null,
      });
      localStorage.setItem("meeting_category", encodeString(EMPLOYEE_LIST));
      const path = "/orgchart";
      this.setState({
        redirect: path,
        open: !open,
        button1Caption: '',
        button2Caption: '',
        title: '',
        message: '',
        redirestCategary: EMPLOYEE_LIST
      },						  
        () => this.props.setMeetingCategory(EMPLOYEE_LIST)
      );
    } catch (error) {
      // eslint-disable-next-line
      console.log(error)
    }
  }
  handleConfirmNo = () => {
    const { open } = this.state;
    this.setState({
      open: !open,
      button1Caption: '',
      button2Caption: '',
      title: '',
      message: '',
      redirestCategary: ''
    });
  }
  handleConfirmClose = () => {/**NOSONAR */
    const { open } = this.state;
    this.setState({
      open: !open,
      button1Caption: '',
      button2Caption: '',
      title: '',
      message: '',
      redirestCategary: ''
    });
  }

  render() {
    const { employeeStatus } = this.props;
    const { redirect, redirestCategary } = this.state;
    if (redirect != null) {
      this.props.setMeetingCategory(redirestCategary);
      return <Redirect to={redirect} />;
    }
    return (
      <div>
        <div className='col-12' style={{ height: 30, textAlign: 'center', borderTop: '1px solid gray', }}>
          <div className=''>
            <div className='col-1 footer-button'>

              <div style={{ height: '100%', width: '100%', color: '#FFF' }}>
                .
                  </div>
            </div>
            <div className='col-8' style={{ color: '#FFF' }}>
              .
            </div>
            <div className='col-3' style={{ textAlign: 'right' }}>
              <div className='footer-button'>
                {
                  (employeeStatus !== "Terminated") &&
                  <Button
                    className="e-event-details text-light ml-sm " variant="contained" title="SUBMIT" color='primary' size='small'
                    onClick={() => this.onSubmitAbsconding()}
                  >
                    SUBMIT
                </Button>
                }
                {/* <Button
                    style={{ background: "#FFF", color: '#000', border: '1px solid ', marginLeft:5 }}
                    className="e-event-create footer-button" variant="contained" title="Cancel" size='small'>
                    Cancel
                    </Button> */}

              </div>
            </div>
            <ConfirmDialog open={this.state.open}
              handleConfirmYes={this.handleSubmitYes}
              handleConfirmNo={this.handleConfirmNo}
              handleConfirmClose={this.handleConfirmClose}
              button2Caption={this.state.button2Caption}
              button1Caption={this.state.button1Caption}
              title={this.state.title}
              message={this.state.message} />
          </div>
        </div>
      </div>
    );
  }
}
TerminationFooterButtons.propTypes = {
  authUser: PropTypes.string,
};
TerminationFooterButtons.defaultProps = {
  authUser: "",
};
const mapStateToProps = ({ separationreducer, onboardingreducer, auth }) => {
  const { authUser } = auth;
  const { empid } = onboardingreducer;
  const { terminationGrounds, terminationRemark, terminationRequestFor, suspensionForDays, updateTerminationStatus, employeeStatus, terminationLWD, } = separationreducer;
  return { authUser, terminationGrounds, terminationRemark, terminationRequestFor, empid, suspensionForDays, updateTerminationStatus, employeeStatus, terminationLWD, };
};

export default connect(mapStateToProps, { submitTerminationData, addUpdateSeparationDetails, setMeetingCategory })(TerminationFooterButtons);
