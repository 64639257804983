/**
 * Author: Prashant Waphare.
 * Created:23-09-2020
 */
import {
    SET_ORGCHART_DETAILS,
    UPDATE_ORGCHART_LIST,
    ADD_EDIT_COST_SHARING,
    ADD_EDIT_OTHER_CALENDAR_ACCESS,
    MOVE_TO_ACTIVE
  } from "../../constant/entryexit/separationconstant";
  import {ADD_NEW_ORGCHART_MASTER_DATA} from '../../constant/entryexit/orgchartconstant'
  
  export const setOrhChartDetaila = (data) => {
    return {
      type: SET_ORGCHART_DETAILS,
      payload: data,
    };
  };
  export const updateOrgChartDetailsAction = (data) => {
    return {
      type: UPDATE_ORGCHART_LIST,
      payload: data,
    };
  };
  export const addEditCostSharingAction = (data) => {
    return {
      type: ADD_EDIT_COST_SHARING,
      payload: data.data,
    };
  };
  export const addNewOrgChatMasterData = (data) => {    
    return {
      type: ADD_NEW_ORGCHART_MASTER_DATA,
      payload: data,
    };
  };
  export const moveLeftEmployeeToActiveAction = (data) => {    
    return {
      type: MOVE_TO_ACTIVE,
      payload: data,
    };
  };
    export const addEditCalendarAccess = (data) => {
    return {
      type: ADD_EDIT_OTHER_CALENDAR_ACCESS,
      payload: data,
    };
  };