import React from "react";
import { connect } from "react-redux";
import { library } from "@fortawesome/fontawesome-svg-core";
import PropTypes from "prop-types";
import { faPlus } from "@fortawesome/pro-light-svg-icons";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { ComboBoxComponent, MultiSelectComponent, CheckBoxSelection } from "@syncfusion/ej2-react-dropdowns";
import { Inject } from "@syncfusion/ej2-react-schedule";
import { Button } from "@material-ui/core";
import { FormValidator } from "@syncfusion/ej2-inputs";
//nosonar import { Query } from "@syncfusion/ej2-data";
import {
    pleaseEnterObjective,
    pleaseEntertags,
    pleaseSelectFunction, pleaseSelectLevel, objectiveDetails, objectiveTagsData, objectiveVisibility, objectiveLavel,pleaseEnterValidObjective
} from '../../assets/constants/constants';
import TagsInput from './../objective/tags'
import { cracterLimit, removeHashFromTags } from '../../app/other/commonfunction';
import { addUpdateNewObj, addUpdateObjectveKpi } from '../../redux/actions/okr/okractions';
import Tooltips from "../custommaterialtooltip/custommaterialtooltip";

library.add(faPlus);
class CreateObjective extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            changeData: true,
            oprnReviewReport: false,
            addNewSelfReviewOpen: false,
            objectiveTag: [],
            height:
                (window.innerHeight ||
                    document.documentElement.clientHeight ||
                    document.body.clientHeight) - 95,
        };
        this.descriptionTextBox = null;
        this.commentTxtbox = null;
        this.buttonObjDisable = false;
    }

    updateDimensions() {
        const updateHeight = window.innerHeight - 150;
        this.setState({ height: updateHeight });
    }
    componentDidMount() {
        const { levelL } = this.props;
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
        if (levelL.length > 1) {
            setTimeout(() => {
                document.getElementById(`Level`).ej2_instances[0].refresh();
            }, 100);
        }
    }
    createNewObjective = (type) => { // NOSONAR
        const { authUser, objectiveName, objectiveTags, visibility, levelL, objId, objKpiAddUpdateType } = this.props;
        let finalData = {};
        let newObjectiveTags=removeHashFromTags(objectiveTags)
        if (type === "AddObj") {
            finalData = {
                userId: authUser,
                type: "AddObj",
                objectiveName: objectiveName,
                objectiveTags: newObjectiveTags.toString(),
                visibilityId: visibility === 718 ? '1' : visibility === 719 ? '2' : '3', // NOSONAR
                levelId: (levelL === "" || levelL === null) ? 0 : levelL.toString(),
                ObjType: '1',
                IsDraft: '1',
                objId: 0,
                objKpiAddUpdateType: objKpiAddUpdateType
            }
        } else {
            finalData = {
                userId: authUser,
                type: "UpdateObj",
                objectiveName: objectiveName,
                objectiveTags: newObjectiveTags.toString(),
                visibilityId: visibility === 718 ? '1' : visibility === 719 ? '2' : '3', // NOSONAR
                levelId: (levelL === "" || levelL === null) ? 0 : levelL.toString(),
                ObjType: '1',
                IsDraft: objKpiAddUpdateType === "PendingForApproval" || objKpiAddUpdateType === "ViewLibrary"  ? '0' : '1',
                objId: objId,
                objKpiAddUpdateType: objKpiAddUpdateType
            }
        }
        const options = {
            rules: {
                objectiveErr: {
                    required: [true, pleaseEnterObjective],
                    regex: ["[a-zA-Z0-9][\\sa-zA-Z0-9]", pleaseEnterValidObjective]
                },
                functionErr: {
                    required: [true, pleaseSelectFunction],
                },
            },
        };
        if (newObjectiveTags.toString() === "" || newObjectiveTags.toString() === null) {
            document.getElementById("TagsErr").innerHTML = "";
            document.getElementById("TagsErr").innerHTML = pleaseEntertags;
        }
        setTimeout(() => {
            const addFormObject = new FormValidator("#createNewObjectiveForm", options);
            if (addFormObject.validate()) {
                if (newObjectiveTags.toString() === "" || newObjectiveTags.toString() === null) {
                    document.getElementById("TagsErr").innerHTML = "";
                    document.getElementById("TagsErr").innerHTML = pleaseEntertags;
                    return;
                }
                if (visibility === 720 && (levelL === "" || levelL === null || levelL.length === 0)) {
                    document.getElementById("levelErr").innerHTML = "";
                    document.getElementById("levelErr").innerHTML = pleaseSelectLevel;
                    return;
                }
                this.buttonObjDisable = true;
                this.props.addUpdateNewObj(finalData);
                this.setState({ objectiveTag: [] });
            }
        }, 100);
    }
    selectedTags = (tags) => {
        document.getElementById("TagsErr").innerHTML = "";
        this.setState({
            objectiveTag: tags
        })
        this.props.addUpdateObjectveKpi({ objectiveTags: tags });
    };
    getObjDetails = (e, type) => {
        const { levelL } = this.props;
        switch (type) {
            case "objName":
                this.props.addUpdateObjectveKpi({ objectiveName: e.value });
                break;
            case "function":
                if (e.itemData !== null) {
                    this.props.addUpdateObjectveKpi({ visibility: e.itemData.Id, levelL: [] });
                }
                break;
            case "level":
                let levelData = levelL;
                document.getElementById("levelErr").innerHTML = "";
                if (e.itemData !== null) {
                    levelData.push(e.itemData.Id);
                    this.props.addUpdateObjectveKpi({ levelL: levelData });
                }
                break;
            default:
        }
    }
    removeEditFunction = (e) => {
        const { levelL } = this.props;
        if (e.itemData !== null) {
            var arr = levelL;
            if (!arr.includes(parseInt(e.itemData.Id, 10))) {
                arr.push(parseInt(e.itemData.Id, 10));
                this.props.addUpdateObjectveKpi({ levelL: arr });
            }
            else {
                let filterData = levelL;
                filterData = filterData.filter((t) => t !== parseInt(e.itemData.Id, 10));
                this.props.addUpdateObjectveKpi({ levelL: filterData });
            }
        }
    };
    
    render() {
        const { okrMastersVisibility, levelMaster, objectiveName, objectiveTags, visibility, levelL, objId } = this.props;
        return (
            <>
                <form id="createNewObjectiveForm" method="post">
                    <div className="row" style={{ paddingTop: "20px" }}>
                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12" style={{ padding: "0px 42px 0px 16px" }}>
                            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                <TextBoxComponent
                                    id="objective"
                                    value={objectiveName}
                                    cssClass="e-outline"
                                    data-msg-containerid="objectiveErr"
                                    name="objectiveErr"
                                    floatLabelType="Auto"
                                    placeholder="Objective*"
                                    htmlAttributes={{ maxlength: "100", minLength: 1 }}
                                    input={(e) => cracterLimit(e, `objectiveTypeCharactor`, '100')}
                                    change={(e) => this.getObjDetails(e, 'objName')}
                                />
                                <div style={{ margin: '0px -52px 4px 0px' }} id="objectiveErr" className="e-input-group e-float-icon-left error-div" />
                                <div id='objectiveTypeCharactor' style={{ float: 'right', fontSize: 12, margin: '0px 0px 8px 0px' }} className="error-div" />
                            </div>
                            <span style={{ padding: "4px 0px 0px 8px", position: "absolute" }}>
                                <Tooltips titleMessage={objectiveDetails} />
                            </span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12" style={{ padding: "0px 42px 0px 16px" }}>
                            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                <TagsInput selectedTags={this.selectedTags} tags={objectiveTags} />
                                <div
                                    id="TagsErr"
                                    className="e-input-group e-float-icon-left error-div"
                                />
                            </div>
                            <span style={{ padding: "4px 0px 0px 8px", position: "absolute" }}>
                                <Tooltips titleMessage={objectiveTagsData} />
                            </span>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12 col-sm-4 col-lg-3 col-md-3" style={{ padding: "0px 32px 0px 16px" }}>
                            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                <ComboBoxComponent
                                    id="function"
                                    fields={{ text: "var_optional1", value: "Id" }}
                                    cssClass="e-outline"
                                    data-msg-containerid="functionErr"
                                    name="functionErr"
                                    floatLabelType="Auto"
                                    placeholder="Visibility *"
                                    htmlAttributes={{ maxlength: 100 }}
                                    dataSource={okrMastersVisibility}
                                    value={visibility}
                                    change={(e) => this.getObjDetails(e, 'function')}
                                />
                                <div
                                    id="functionErr"
                                    className="e-input-group e-float-icon-left error-div"
                                />
                            </div>
                            <span style={{ padding: "4px 0px 0px 8px", position: "absolute" }}>
                                <Tooltips titleMessage={objectiveVisibility} />
                            </span>
                        </div>
                        {
                            (visibility === 720 || visibility === "Function") &&
                            <div className="col-xs-12 col-sm-8 col-lg-6 col-md-6" style={{ padding: "0px 42px 0px 16px" }}>
                                <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                    <MultiSelectComponent
                                        id="Level"
                                        name="levelErr"
                                        data-msg-containerid="levelErr"
                                        dataSource={levelMaster}
                                        value={levelL}
                                        fields={{ text: "var_list_value", value: "Id" }}
                                        select={(e) => this.getObjDetails(e, 'level')}
                                        maximumSelectionLength={10}
                                        removed={(e) => this.removeEditFunction(e)}
                                        mode="CheckBox"
                                        cssClass="e-outline"
                                        showDropDownIcon
                                        placeholder="Level *"
                                        allowFiltering={false}
                                    >
                                        <Inject services={[CheckBoxSelection]} />
                                    </MultiSelectComponent>
                                    <div
                                        id="levelErr"
                                        className="e-input-group e-float-icon-left error-div"
                                    />
                                </div>
                                <span style={{ padding: "4px 0px 0px 8px", position: "absolute" }}>
                                    <Tooltips titleMessage={objectiveLavel} />
                                </span>
                            </div>
                        }
                    </div>
                    <div className="row" style={{ float: "right", padding: "0px 42px 0px 0px" }}>
                        <Button
                            style={{ width: "120px" }}
                            className="mr-sm cancel-button"
                            variant="contained"
                            onClick={this.props.closeCreateObj()}
                        >
                            Cancel
                          </Button>
                        <Button
                            style={{ width: objId > 0 ? "120px":"140px" }}
                            className="remove-button-shadow"
                            color="primary"
                            variant="contained"
                            disabled={this.buttonObjDisable}
                            onClick={objId > 0 ? (e) => this.createNewObjective("UpdateObj") : (e) => this.createNewObjective("AddObj")}
                        >
                            {objId > 0 ? "Save" : "Create Objective"}
                        </Button>
                    </div>
                </form>
            </>
        );
    }
}
CreateObjective.propTypes = {
    authUser: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    objectiveName: PropTypes.oneOfType([PropTypes.string]),
    objectiveTags: PropTypes.oneOfType(PropTypes.object, PropTypes.string,),
    visibility: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    levelL: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    objId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    objKpiAddUpdateType: PropTypes.oneOfType([PropTypes.string]),
};
CreateObjective.defaultProps = {
    authUser: "",
    objectiveName: "",
    objectiveTags: [],
    visibility: "",
    levelL: [],
    objId: 0,
    objKpiAddUpdateType: "",
};
const mapStateToProps = ({ auth, okrreducer }) => {
    const { authUser } = auth;
    const {
        objectiveName,
        objectiveTags,
        visibility,
        levelL,
        objId,
        objKpiAddUpdateType,
    } = okrreducer;
    return {
        authUser,
        objectiveName,
        objectiveTags,
        visibility,
        levelL,
        objId,
        objKpiAddUpdateType,
    };
};

export default connect(mapStateToProps, { addUpdateNewObj, addUpdateObjectveKpi })(CreateObjective);
