/**
 * Author Name:- Prashant Waphare
 * Created Date :- 13-04-2020
 * Description :- This is card view (To be offered componet)
 */
import * as React from "react";
import queryString from "query-string";
import "../../../app/containers/tmlc/onboarding/onboarding.css";
import {
  ButtonComponent,
  CheckBoxComponent,
} from "@syncfusion/ej2-react-buttons";
import PropTypes from "prop-types";
import $ from "jquery";
import { MDBBtn, MDBIcon } from "mdbreact";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { connect } from "react-redux";
import { Button, Dialog } from "@material-ui/core";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import TobeOfferCandidateGridView from "../../tobeoffercondicate/tobeoffergridview";
import OnbordingToBeOffer from "../../../services/entryexit/onbordingServices";
import ErrorMessage from "../../errormessage/errormessage";
import Progressbar from "../../progressbar/progressbar";
import {
  initiateAllcandidate,
  saveBasicInformationInRedux,
  updateStatusOnBoardingResucer,
} from "../../../redux/actions/entryexit/onboardingaction";
import {
  DialogContent,
  DialogActions,
  DialogTitle,
} from "../../customdialog/customdialog";
import {
  getNotify,
  getDisplayDateOnly,
  encrypt,
  encodeString,
  decodeString
} from "../../../app/other/commonfunction";
import { WARNING, encryptMethod } from "../../../assets/constants/constants";
import { urlConfig } from "../../../config/config";

import { PRE_OFFERE } from "../../../redux/constant/meetingcategoryconstant";
import { setMeetingCategory } from '../../../redux/actions/meetingcategoryaction';
import { Redirect } from "react-router-dom";
import { MuiPickersUtilsProvider, KeyboardDatePicker, } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import TextField from "@material-ui/core/TextField";
class CardViewOfferCandidate extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      todosPerPage:
        (window.innerHeight ||
          document.documentElement.clientHeight ||
          document.body.clientHeight) > 700
          ? 12
          : 8,
      upperPageBound: 2,
      lowerPageBound: 0,
      isPrevBtnActive: "disabled",
      isNextBtnActive: "",
      pageBound: 1,
      toggleValue: 0,
      openRevokeDialog: false,
      offerChangeStatus: [],
      openConfDialog: false,
      masterDataRevokrReason: null,
      revokeReasonID: null,
      revokeDate: null,
      revokeComment: null,
      getOfferGridCheckBoxValueState: "",
      refreshOfferCardData: false,
      redirect: null,
      height:
        (window.innerHeight ||
          document.documentElement.clientHeight ||
          document.body.clientHeight) - 140,
      width:
        (window.innerWidth ||
          document.documentElement.clientWidth ||
          document.body.clientWidth) - 300,
      cardLayuotHeight:
        (window.innerHeight ||
          document.documentElement.clientHeight ||
          document.body.clientHeight) - 300,
    };
    const { offerData } = this.props;
    const { todosPerPage } = this.state;
    this.buttonElement = null;
    this.totalPagecount = Math.ceil(offerData.length / todosPerPage);
    this.getOfferchangeStatus = this.getOfferchangeStatus.bind(this);
    this.height =
      (window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight) - 140;
    this.cardLayuotHeight =
      (window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight) - 300;
  }
  updateDimensions() {
    let update_width = window.innerWidth - 100;
    let update_height = window.innerHeight - 140;
    let cardLayuotHeight = window.innerHeight - 210;
    this.setState({ width: update_width, height: update_height, cardLayuotHeight: cardLayuotHeight });
  }
  componentDidMount = () => {
    const { currentPage } = this.state;
    const { getOfferTabData } = this.props;
    $("ul li.active").removeClass("active");
    $(`ul li#${currentPage}`).addClass("");
    this.getMasterData();
    getOfferTabData();
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
  };
  // eslint-disable-next-line
  UNSAFE_componentWillReceiveProps(newProps) {
    const { getOfferTabData } = this.props;
    const { refreshOfferCardData } = this.state;
    if (newProps.offerStatus === "updated") {
      getOfferTabData();
      this.setState({
        offerChangeStatus: [],
        getOfferGridCheckBoxValueState: "",
        refreshOfferCardData: !refreshOfferCardData,
      });
      // eslint-disable-next-line
      this.props.updateStatusOnBoardingResucer({ offerStatus: "" });
    }
  }

  onCancel = () => {
    this.setState({ openRevokeDialog: false, revokeReasonID: null, revokeComment: null, revokeDate: null });
  };

  getRevokeComment = (e) => {
    document.getElementById("revokeCommentErr").innerHTML = "";
    this.setState({
      revokeComment: e.value,
    });    
    if (this.state.revokeComment === null) {
      document.getElementById("revokeCommentErr").innerHTML = "Please enter revoke comment";      
    }
  };
  getRevokeReson = (e) => {
    this.setState({
      revokeReasonID: e.itemData.Id,
    });
    document.getElementById("revokereasonErr").innerHTML = "";
    if (this.state.revokeReasonID === null) {
      document.getElementById("revokereasonErr").innerHTML = "Please select action taken by";      
    }
  };
  setRevokeDate = async (e, type) => {
    var revokeDate = Math.floor(Date.UTC(e.getFullYear(), e.getMonth(), e.getDate())) / 1000;
    document.getElementById("revokeDateErr").innerHTML = "";
    await this.setState({ revokeDate: revokeDate });
    if (this.state.revokeDate === null) {
      document.getElementById("revokeDateErr").innerHTML = "Please enter revoke date";      
    }
  }
  getMasterData = () => {
    const { authUser } = this.props;
    try {
      const getMasterData = Promise.resolve(
        OnbordingToBeOffer.getMasterData("RV", authUser)
      );
      getMasterData.then((resultData) => {
        if (resultData !== null) {
          this.setState({ masterDataRevokrReason: resultData });
        } else {
          this.data = "error";
        }
      });
    } catch (e) {
      // eslint-disable-next-line
      console.log(e);
    }
  };

  setPrevAndNextBtnClass = (listid) => {
    const { offerData } = this.props;
    const { todosPerPage } = this.state;
    const totalPage = Math.ceil(offerData.length / todosPerPage);
    this.setState({ isNextBtnActive: "disabled" });
    this.setState({ isPrevBtnActive: "disabled" });
    if (totalPage === listid && totalPage > 1) {
      this.setState({ isPrevBtnActive: "" });
    } else if (listid === 1 && totalPage > 1) {
      this.setState({ isNextBtnActive: "" });
    } else if (totalPage > 1) {
      this.setState({ isNextBtnActive: "" });
      this.setState({ isPrevBtnActive: "" });
    }
  };

  getOfferchangeStatus(e, candId) {
    const { offerChangeStatus } = this.state;
    let arr = [];
    arr = offerChangeStatus;
    // eslint-disable-next-line
    if (!arr.includes(parseInt(candId))) {
      // eslint-disable-next-line
      arr.push(parseInt(candId));
      this.setState({
        offerChangeStatus: arr,
      });
    } else {
      let filterData = offerChangeStatus;
      filterData = filterData.filter(
        // eslint-disable-next-line
        (t) => t !== parseInt(candId)
      );
      this.setState({
        offerChangeStatus: filterData,
      });
    }
  }

  // This function use for get Preview page in custome pagination
  btnPrevClick = () => {
    const {
      currentPage,
      pageBound,
      upperPageBound,
      lowerPageBound,
    } = this.state;
    if ((currentPage - 1) % pageBound === 0) {
      this.setState({
        upperPageBound: upperPageBound - pageBound,
      });
      this.setState({
        lowerPageBound: lowerPageBound - pageBound,
      });
    }
    const listid = currentPage - 1;
    this.setState({ currentPage: listid });
    this.setPrevAndNextBtnClass(listid);
  };

  // This function use for get next page in custome pagination
  btnNextClick = () => {
    const {
      currentPage,
      upperPageBound,
      pageBound,
      lowerPageBound,
    } = this.state;
    if (currentPage + 1 > upperPageBound) {
      this.setState({
        upperPageBound: upperPageBound + pageBound,
      });
      this.setState({
        lowerPageBound: lowerPageBound + pageBound,
      });
    }
    const listid = currentPage + 1;
    this.setState({ currentPage: listid });
    this.setPrevAndNextBtnClass(listid);
  };

  // This function use for toggle card view to grid component
  cartToGridView = () => {
    const { toggleValue } = this.state;
    if (toggleValue === 0 && this.toggleValue !== "") {
      this.setState({ toggleValue: 1 });
    } else {
      this.setState({ toggleValue: 0 });
    }
  };

  // This function use for decrement one by one page in custome pagination
  btnDecrementClick = () => {
    const { upperPageBound, pageBound, lowerPageBound } = this.state;
    this.setState({
      upperPageBound: upperPageBound - pageBound,
    });
    this.setState({
      lowerPageBound: lowerPageBound - pageBound,
    });
    const listid = upperPageBound - pageBound;
    this.setState({ currentPage: listid });
    this.setPrevAndNextBtnClass(listid);
  };

  // This function use for Increment one by one page in custome pagination
  btnIncrementClick = () => {
    const { upperPageBound, pageBound, lowerPageBound } = this.state;
    this.setState({
      upperPageBound: upperPageBound + pageBound,
    });
    this.setState({
      lowerPageBound: lowerPageBound + pageBound,
    });
    const listid = upperPageBound + 1;
    this.setState({ currentPage: listid });
    this.setPrevAndNextBtnClass(listid);
  };

  handleClick = (event) => {
    const listid = Number(event.target.id);
    this.setState({
      currentPage: listid,
    });
    $("ul li.active").removeClass("active");
    $(`ul li#${listid}`).addClass("");
    this.setPrevAndNextBtnClass(listid);
  };

  openRevokeDialogBox = () => {
    const { offerChangeStatus, getOfferGridCheckBoxValueState } = this.state;    
    if (
      offerChangeStatus.length > 0 || getOfferGridCheckBoxValueState.length > 0) {
      this.setState({ openRevokeDialog: true });
    } else {
      getNotify(WARNING, "Please select at least one candidate !");
    }
  };

  submitRevoke = () => {
    const { authUser } = this.props;
    const {
      revokeComment,
      revokeReasonID,
      revokeDate,
      offerChangeStatus,
      getOfferGridCheckBoxValueState,
    } = this.state;
    document.getElementById("revokereasonErr").innerHTML = "";
    document.getElementById("revokeCommentErr").innerHTML = "";
    document.getElementById("revokeDateErr").innerHTML = "";

    if (this.state.revokeReasonID === null) {
      document.getElementById("revokereasonErr").innerHTML = "Please select action taken by";
      return;
    } else if (this.state.revokeDate === null || this.state.revokeDate === "") {
      document.getElementById("revokeDateErr").innerHTML = "Please enter revoke date";
      return;
    } else if (this.state.revokeComment === null || this.state.revokeComment === "") {
      document.getElementById("revokeCommentErr").innerHTML = "Please enter revoke reason";
      return;
    }
    // else{}
    if (offerChangeStatus.toString().length > 0) {
      // eslint-disable-next-line
      this.props.initiateAllcandidate({
        authUser,
        candIds: offerChangeStatus.toString(),
        statusId: 4,
        revokeComments: revokeComment,
        revokeReasonId: revokeReasonID,
        revokeDate: revokeDate,
        revokeType: "offerTab"
      });
      this.setState({ openRevokeDialog: false, revokeReasonID: null, revokeComment: null, revokeDate: null });
    } else {
      // eslint-disable-next-line
      document.getElementById("revokereasonErr").innerHTML = "";
      if (this.state.revokeReasonID === null) {
        document.getElementById("revokereasonErr").innerHTML = "Please select action taken by";
        return;
      }
      document.getElementById("revokeDateErr").innerHTML = "";
      if (this.state.revokeDate === null || this.state.revokeDate === "") {
        document.getElementById("revokeDateErr").innerHTML = "Please enter revoke date";
        return;
      }
      document.getElementById("revokeCommentErr").innerHTML = "";
      if (this.state.revokeComment === null || this.state.revokeComment === "") {
        document.getElementById("revokeCommentErr").innerHTML = "Please enter revoke comment";
        return;
      }
      this.props.initiateAllcandidate({
        authUser,
        candIds: getOfferGridCheckBoxValueState,
        statusId: 4,
        revokeComments: revokeComment,
        revokeReasonId: revokeReasonID,
        revokeDate: revokeDate,
        revokeType: "offerTab"
      });
    }
    this.setState({ openRevokeDialog: false, revokeReasonID: null, revokeComment: null, revokeDate: null });    
  };

  // This function use for close dialog box
  closeOfferDialog = () => {
    this.setState({ openConfDialog: false, });
  };

  // This function use for open confermation dialog box on initiate offer button
  openOfferDialog = () => {
    const { offerChangeStatus, getOfferGridCheckBoxValueState } = this.state;
    if (
      offerChangeStatus.toString().length > 0 ||
      getOfferGridCheckBoxValueState.length > 0
    ) {
      this.setState({ openConfDialog: true });
    } else {
      getNotify(WARNING, "Please select at least one Offered Candidate!");
    }
  };

  initiateReadyToJoin = () => {
    const { authUser } = this.props;
    const { offerChangeStatus, getOfferGridCheckBoxValueState } = this.state;
    let offerCandidateStatus;
    if (offerChangeStatus.toString().length > 0) {
      offerCandidateStatus = {
        authUser,
        candIds: offerChangeStatus.toString(),
        statusId: 3,
        revokeType: "offerJoin"
      };
    } else {
      offerCandidateStatus = {
        authUser,
        candIds: getOfferGridCheckBoxValueState,
        statusId: 3,
        revokeType: "offerJoin"
      };
    }
    // eslint-disable-next-line
    this.props.initiateAllcandidate(offerCandidateStatus);
    this.setState({ openConfDialog: false });    
  };

  getOfferGridCheckBoxValue = (props) => {
    this.setState({
      getOfferGridCheckBoxValueState: props,
    });
  };

  editCardDetails = async (candId) => {
    try {
      const setCandidateId = {
        candidateid: parseInt(candId),
      };
      // eslint-disable-next-line
      this.props.saveBasicInformationInRedux(setCandidateId);

      localStorage.setItem('last_meeting_category',decodeString(localStorage.getItem('meeting_category')))
      localStorage.setItem("meeting_category", encodeString(PRE_OFFERE));
      const params = queryString.parse(this.props.location.search);
      const path = `/preoffer?candid=${encrypt(candId)}&id=${params.id}&appId=${params.appId}`;
      this.setState({
        redirect: path,
        // changedData: !changedData,
      }, () => this.props.setMeetingCategory(PRE_OFFERE));
    } catch (error) {
      console.log("error", error);
    }

  };

  TextFieldComponent = (props) => {
    return <TextField {...props} disabled />;
  };

  addDefaultSrc(ev) {
    ev.target.parentNode.innerHTML = '<span  class="img-circle fas fa-user-circle img-circle-grid-photo" alt=""  style="font-size: 60px "></span>'
  }
  
  render() { // NOSONAR
    const { authUser, offerData } = this.props;
    const {
      // currentPage,
      // todosPerPage,
      // upperPageBound,
      // lowerPageBound,
      // isPrevBtnActive,
      // isNextBtnActive,
      toggleValue,
      openConfDialog,
      openRevokeDialog,
      masterDataRevokrReason,
      offerChangeStatus,
      redirect
    } = this.state;
    if (redirect !== null) {
      return <Redirect to={redirect} />;
    }
    var revokeDateVal = null;
    if (this.state.revokeDate)
      revokeDateVal = new Date(0).setUTCSeconds(this.state.revokeDate);     
    
    return (
      <div>
        <div className="row container-custome1" style={{ height: this.state.height }}>
          {offerData !== null &&
            offerData !== undefined &&
            offerData !== "" &&
            offerData !== "error" &&
            offerData.length > 0 ? (
              <div>
                <div
                  className="row row-xs-3 row-sm-3 row-lg-3 row-md-3 e-card-resize-container"
                  style={{ paddingRight: "15px", textAlign: "end", paddingBottom: "4px" }}
                >
                  <div className="col-xs-12 ">                    
                    <MDBBtn
                      id="cartToGridView"
                      onClick={this.cartToGridView}
                      color="indigo lighten-2"
                    >
                      {toggleValue ? ( // NOSONAR
                        <TooltipComponent
                          content="Toggle"
                          position="BottomCenter"
                          isSticky={false}
                          width="70px"
                          cssClass="customtooltip"
                        >
                          <MDBIcon icon="th" />
                        </TooltipComponent>
                      ) : (
                          <TooltipComponent
                            content="Toggle"
                            position="BottomCenter"
                            isSticky={false}
                            width="70px"
                            cssClass="customtooltip"
                          >
                            <MDBIcon icon="fas  fa-th-large" />
                          </TooltipComponent>
                        )}
                    </MDBBtn>
                  </div>
                </div>
                <div
                  className="row-xs-6 row-sm-6 row-lg-6 row-md-6 to-be-offer-and-offer-card-page"
                  style={{ height: this.state.cardLayuotHeight }}
                >
                  <div className="control-pane ">
                    {/* <div className='scrollbar '> */}
                    <div className="control-section card-control-section vertical_card_layout">
                      <div className="e-card-resize-container">
                        <div
                          className="row card-layout"
                          style={{ paddingLeft: "10px", paddingRight: "10px" }}
                        >
                          <div
                            className="row card-layout"
                            style={{
                              paddingLeft: "20px",
                              paddingRight: "20px",
                              height: this.state.cardLayuotHeight,
                            }}
                          >
                            {toggleValue === 0 && toggleValue !== "" ? ( // NOSONAR
                              this.props.offerData.map((data) => (
                                <div
                                  key={`carddiv${data.CandidateId}`}
                                  className="col-xs-3 col-sm-3 col-lg-3 col-md-3 e-card-content e-card-card-accent-success"
                                  style={{ paddingTop: "10px", paddingBottom: 15 }}
                                >
                                  <div className="e-card profile card-fix-height-offer">
                                    <div className="col">
                                      <div className="card-check-box">
                                        <CheckBoxComponent
                                          id={`candidate${data.CandidateId}`}
                                          checked={offerChangeStatus.includes(
                                            data.CandidateId
                                          )}
                                          value={data.CandidateId}
                                          change={(e) =>
                                            this.getOfferchangeStatus(e, data.CandidateId)
                                          }
                                        />
                                      </div>
                                      {/* <Link
                                        to={`/preoffer?candid=${encrypt(data.CandidateId)}`}
                                      > */}
                                      <div
                                        className="col-xs-10 col-sm-10 col-lg-12 col-md-10"
                                      // onClick={() =>
                                      //   this.editCardDetails(data.CandidateId)
                                      // }
                                      >
                                        <div
                                          className="e-card-header"
                                          style={{ paddingBottom: "1px" }}
                                        >
                                          {(data.PhotoNm === undefined || data.PhotoNm === "") ? (
                                            <center>
                                              <span
                                                className="img-circle fas fa-user-circle"
                                                alt=""
                                                style={{
                                                  fontSize: "60px",
                                                  color: "#737373e0",
                                                  // position: "relative",
                                                }}
                                              />
                                            </center>
                                          ) : (
                                              <center>
                                                <img
                                                  onError={this.addDefaultSrc}
                                                  className="img-circle image-show-on-card pointer-cursor"
                                                  src={`${urlConfig.tmlcApiUrl}document/A/${authUser}/${data.PhotoNm}`}
                                                  alt=""
                                                  onClick={() =>
                                                    this.editCardDetails(data.CandidateId)
                                                  }
                                                />
                                              </center>
                                            )}
                                        </div>
                                        <div
                                          className="e-card-header"
                                          style={{ paddingTop: "1px" }}
                                        >
                                          <div className="e-card-header-caption center">
                                            <div className="card-name pointer-cursor"
                                              onClick={() =>
                                                this.editCardDetails(data.CandidateId)
                                              }>
                                              <b>{data.CandidateName}</b>
                                            </div>
                                            {/* <TooltipComponent content={data.Role} position="BottomCenter" isSticky={false}> */}
                                            <span className="card-designation pointer-cursor"
                                              onClick={() =>
                                                this.editCardDetails(data.CandidateId)} >
                                              {data.Role}
                                            </span>
                                            {/* </TooltipComponent> */}
                                            <TooltipComponent
                                              content={` Offered Date ${getDisplayDateOnly(
                                                new Date(data.OfferedDate)
                                              )} | Ready to join date ${getDisplayDateOnly(
                                                new Date(data.JoiningDate)
                                              )}`}
                                              position="BottomCenter"
                                              isSticky={false}
                                              cssClass='customtooltip'
                                            >
                                              <span className="card-date">{`${getDisplayDateOnly(
                                                new Date(data.OfferedDate)
                                              )} | ${getDisplayDateOnly(
                                                new Date(data.JoiningDate)
                                              )}`}</span>
                                            </TooltipComponent>
                                          </div>
                                        </div>
                                      </div>
                                      {/* </Link> */}
                                    </div>
                                  </div>
                                </div>
                              ))
                            ) : (
                                <div>
                                  <TobeOfferCandidateGridView
                                    gridData={offerData}
                                    type={"Offered"}
                                    getOfferGridCheckBoxValue={
                                      this.getOfferGridCheckBoxValue
                                    }
                                    // getToBeOfferGridCheckBoxValueRevoke= {this.getToBeOfferGridCheckBoxValueRevoke}
                                    authUser={authUser}
                                  />
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* </div> */}
                  </div>
                </div>
                <div>
                  <div style={{ width: "350px" }}>
                    <Dialog open={openConfDialog}>
                      <DialogTitle> Ready to Join </DialogTitle>
                      <DialogContent>
                        Are you sure you want to initiate joining process for selected Candidate(s)?
                    </DialogContent>
                      <DialogActions>
                        <Button onClick={this.initiateReadyToJoin}> Yes </Button>
                        <Button onClick={this.closeOfferDialog}> No </Button>
                      </DialogActions>
                    </Dialog>
                  </div>
                  <div style={{ width: "350px" }}>
                    <Dialog open={openRevokeDialog}>
                      <DialogContent>
                        <div className="row card-layout">
                          <div className="col-xs-2 col-sm-2 col-lg-6 col-md-2">
                            {/* <span className="span">Revoke By *</span> */}
                            <DropDownListComponent
                              id="RevokReason"
                              fields={{ value: "Id", text: "Name" }}
                              dataSource={masterDataRevokrReason}
                              data-msg-containerid="revokereasonErr"
                              name="revokereasonErr"
                              change={(e) => this.getRevokeReson(e)}
                              cssClass="e-outline"
                              placeholder="Revoke By *"
                              floatLabelType="Auto"
                            />
                            <div
                              id="revokereasonErr"
                              className="error-div"
                              style={{ fontSize: "12px" }}
                            />
                          </div>
                          <div className="col-xs-2 col-sm-2 col-lg-6 col-md-2" >
                            {/* <span className="span">Select Revoke Date *</span> */}
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <KeyboardDatePicker
                                style={{ width: "100%" }}
                                // margin="normal"
                                id="RevokeDate"
                                name="RevokeDate"
                                format="dd-MMM-yyyy"
                                value={revokeDateVal}
                                onChange={(e) => this.setRevokeDate(e, "RevokeDate")}
                                TextFieldComponent={this.TextFieldComponent}
                                data-msg-containerid="revokeDateErr"
                                color="black"
                                autoOk
                                variant="inline"
                                inputVariant="outlined"
                                placeholder="dd-MMM-yyyy"
                                label="Revoke Date *"
                                KeyboardButtonProps={{ "aria-label": "change date" }}
                                // margin="dense"
                                InputLabelProps={{ shrink: true }}
                                floatLabelType="Auto"
                                cssClass="e-outline"
                                InputAdornmentProps={{ position: "start" }}
                              />
                            </MuiPickersUtilsProvider>
                            <div
                              id="revokeDateErr"
                              className="error-div"
                              style={{ fontSize: "12px" }}
                            />
                          </div>
                          <div
                            style={{ paddingTop: "30px" }}
                            className="col-xs-2 col-sm-2 col-lg-12 col-md-2"
                          >
                            {/* <span className="span">Reason for Revoke *</span> */}
                            <TextBoxComponent
                              id="ReasonRevoke"
                              placeholder="Revoke Reason *"
                              cssClass="e-outline"
                              data-msg-containerid="revokeCommentErr"
                              name="revokeCommentErr"
                              multiline
                              // value={this.state.revokeComment}
                              change={(e) => this.getRevokeComment(e)}
                              floatLabelType="Auto"
                            />
                            <div
                              id="revokeCommentErr"
                              className="error-div"
                              style={{ fontSize: "12px" }}
                            />
                          </div>
                        </div>
                        {/* <div style={{ border: '1px solid gray', marginBottom: '10px' }}></div> */}
                      </DialogContent>
                      <DialogActions style={{ paddingRight: "15px" }}>
                        <ButtonComponent
                          id="cancelRevoke"
                          className="e-control "
                          onClick={this.onCancel}
                        >
                          Cancel
                      </ButtonComponent>
                        <ButtonComponent
                          id="sumitRevoke"
                          style={{ background: "#f94f5e", color: "#fff" }}
                          data-ripple="true"
                          onClick={this.submitRevoke}
                        >
                          Submit
                      </ButtonComponent>
                      </DialogActions>
                    </Dialog>
                  </div>
                </div>
                <div
                  className="row"
                  style={{
                    border: "0px solid",
                    position: "absolute",
                    width: "100%",
                    bottom: 15,
                  }}
                >
                  <div
                    className="col-9"
                    style={{
                      textAlign: "right",
                      minHeight: 1,
                      paddingLeft: "13%",
                    }}
                  >                     
                  </div>
                  <div
                    className="col-3 button-down-card"
                  // style={{textAlign:'right', position:'static', paddingRight:12, paddingTop:8}}
                  >
                    <ButtonComponent
                      style={{ borderRadius: "4px" }}
                      onClick={this.openRevokeDialogBox}
                    >
                      REVOKE
                  </ButtonComponent>{" "}
                  &nbsp;&nbsp;
                  <ButtonComponent
                      style={{
                        background: "#f94f5e",
                        color: "#fff",
                        borderRadius: "4px",
                      }}
                      onClick={this.openOfferDialog}
                    >
                      READY TO JOIN
                  </ButtonComponent>
                  </div>
                </div>
              </div>
            ) : (
              <div className="control-section col-md-12" style={{ height: 75 }}>
                {offerData !== "error" && offerData.length > 0 ? ( // NOSONAR
                  <Progressbar />
                ) : (
                    <ErrorMessage message="No Data Available" />
                  )}
                {/* <div style={{textAlign:'center'}}> No Data Available </div> */}
              </div>
            )}
        </div>
      </div>
    );
  }
}
CardViewOfferCandidate.propTypes = {
  // eslint-disable-next-line
  offerStatus: PropTypes.string,
  authUser: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  offerData: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  getOfferTabData: PropTypes.func,
};
CardViewOfferCandidate.defaultProps = {
  authUser: "",
  offerStatus: "",
  offerData: "",
  getOfferTabData: {},
};
const mapStateToProps = ({ auth, onboardingreducer, router }) => {
  const { location } = router;
  const { authUser } = auth;
  const { candidateid, offerStatus } = onboardingreducer;
  return { authUser, candidateid, offerStatus, location  };
};
export default connect(mapStateToProps, {
  initiateAllcandidate,
  saveBasicInformationInRedux,
  updateStatusOnBoardingResucer,
  setMeetingCategory
})(CardViewOfferCandidate);
