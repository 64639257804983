import React from "react";
import { connect } from "react-redux";
import { Button, Dialog } from "@material-ui/core";
import { DialogContent } from "../../../../components/customdialog/customdialog";
import { library } from "@fortawesome/fontawesome-svg-core";
import NotificationContainer from "../../../../components/notification/notifycontainer";
import PropTypes from "prop-types";
import { faPlusCircle } from "@fortawesome/pro-light-svg-icons";
import {
  addEditObjectveKpi,
  addUpdateObjectveKpi,
  approveRejectObjectveKpi,
  submitApprovalAction
} from "../../../../redux/actions/okr/okractions";
import CreateObjective from "../../../../components/okrcreateobjectivekpi/createobject";
import DraftAllObjKPI from "../../../../components/okrcreateobjectivekpi/okrdraftobjkpi";
import OKRPendingForApproval from "../../../../components/okrcreateobjectivekpi/okrpendingforapproval";
import OKRviewobjKPI from "../../../../components/okrcreateobjectivekpi/okrviewobjkpi";
import OKRServices from "../../../../services/okr/okrservices";
import ProgressBar from "../../../../components/progressbar/progressbar";
import "./../../tmlc/onboarding/onboarding.css";
import "../../../../components/okrproductiveallocation/productiveallocation.css";
import "../indexokr.css";
import { ERROR, pleaseSelectAtleastOneObjective, sumitForApprovalObjectiveTitle, sumitForApprovalObjectiveMsg } from "../../../../assets/constants/constants";
import { getNotify } from "../../../../app/other/commonfunction";
import ConfirmationPopup from "../../../../utilities/confirmationpopup";

library.add(faPlusCircle);

class OKRObjectiveKPI extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      changeData: true,
      height:
        (window.innerHeight ||
          document.documentElement.clientHeight ||
          document.body.clientHeight) - 188,
      heightPending:
        (window.innerHeight ||
          document.documentElement.clientHeight ||
          document.body.clientHeight) - 188,
      showLoader: true,
      createObjective: false,
      submitApprovalObj: false
    };
    this.openTab = this.openTab.bind(this);
    this.closeCreateObj = this.closeCreateObj.bind(this);
  }
  // eslint-disable-next-line
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.reRenderComponent === "updated") {
      this.getCreateDraftOkrDetails();
      this.props.addUpdateObjectveKpi({ reRenderComponent: "" });
    }
    if (nextProps.closAlleDialog === "cancel") {
      this.closeCreateObj();
    }
    if (nextProps.refreshViewStatus === "updated") {
      this.getViewOKRObjectiveKPIDetails("noLoading");
      this.props.addUpdateObjectveKpi({ refreshViewStatus: "" });
    }
  }
  // eslint-disable-next-line
  updateDimensions() {
    const updateHeight = window.innerHeight - 230;
    const updateHeightp = window.innerHeight - 185;
    this.setState({ height: updateHeight, heightPending: updateHeightp });
  }

  componentDidMount = async () => {
    const { showLibrary } = this.props
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
    localStorage.removeItem('SelectedTabAction');
    if (showLibrary) {
      localStorage.setItem('SelectedTabAction', "viewOKR");
    }
    this.getCreateDraftOkrDetails();
    this.refreshtabs();
    this.getMasterList();
    //
    let paramString = window.location.search;
    let urlParams = new URLSearchParams(paramString);
    let go = urlParams.get('goto')
    //
  };
  refreshtabs() {
    const getTabActive = localStorage.getItem('SelectedTabAction');
    if (getTabActive !== null && getTabActive === 'pendingRequests') {
      this.getPendingForApprovalObjectiveDetails();
      document.getElementById(`${getTabActive}`).style.display = "block";
    } else if (getTabActive !== null && getTabActive === 'viewOKR') {
      this.getViewOKRObjectiveKPIDetails("");
      document.getElementById(`${getTabActive}`).style.display = "block";
    } else if (getTabActive === null || getTabActive === 'createAndDraftOKR') {
      localStorage.setItem('SelectedTabAction', 'createAndDraftOKR');
      this.getCreateDraftOkrDetails();
      document.getElementById("createAndDraftOKR").style.display = "block";
    }
  }
  openTab = (evt, tabName) => {
    localStorage.setItem('SelectedTabAction', tabName);
    const data = evt;
    if (tabName === "createAndDraftOKR") {
      this.getCreateDraftOkrDetails();
    }
    if (tabName === "viewOKR") {
      this.getViewOKRObjectiveKPIDetails("");
    }
    if (tabName === "pendingRequests") {
      this.getPendingForApprovalObjectiveDetails();
    }
    let i;
    const tabcontentokrtabcomponent = document.getElementsByClassName("tabcontent");
    for (i = 0; i < tabcontentokrtabcomponent.length; i++) {
      tabcontentokrtabcomponent[i].style.display = "none";
    }
    const onboardingtab = document.getElementsByClassName("onboardingtab");
    for (i = 0; i < onboardingtab.length; i++) {
      onboardingtab[i].className = onboardingtab[i].className.replace("active", "");
    }
    document.getElementById(tabName).style.display = "block";
    data.currentTarget.className += "active";
  };
  getMasterList = () => {
    const { authUser } = this.props;
    const OkrObjectiveData = Promise.resolve(OKRServices.getOKRMaster(authUser));
    OkrObjectiveData.then((data) => {
      if (data.Result !== undefined) {
        try {
          const visibility = data.Result.filter((item) => {
            return item.var_list_value === "Visibility";
          });
          const level = data.Result.filter((item) => {
            return item.var_list_name === "Service Division";
          });
          this.setState({
            okrMasters: visibility,
            levelMaster: level,
          });
        } catch (e) {
          this.setState({
            okrMasters: [],
            levelMaster: [],
          });
        }
      }
    });
  }
  // get View OKR Details
  getViewOKRObjectiveKPIDetails = (type) => {
    if (type !== "noLoading") {
      this.setState({ showLoader: true });
    }
    const { authUser } = this.props;
    const OkrObjectiveData = Promise.resolve(
      OKRServices.getObjectiveDetails(authUser, 0, "APPROVED_PENDING_ALL", null, 0)
    );
    OkrObjectiveData.then((result) => {
      // eslint-disable-next-line
      if (result.Result !== undefined) {
        try {
          // eslint-disable-next-line
          this.props.addUpdateObjectveKpi({
            viewObjectiveData: result.Result,
            tempViewObjectiveKPI: result.Result,
          });
          this.setState({ showLoader: false });
        } catch (e) {
          // eslint-disable-next-line
          this.props.addUpdateObjectveKpi({
            viewObjectiveData: [],
            tempViewObjectiveKPI: [],
          });
          this.setState({ showLoader: false });
        }
      }
    });
  };
  getCreateDraftOkrDetails = () => {
    const { authUser } = this.props;
    const OkrObjectiveData = Promise.resolve(
      OKRServices.getObjectiveDetails(authUser, 0, "DRAFT", null, 0)
    );
    OkrObjectiveData.then((result) => {
      if (result.Result !== undefined) {
        try {
          // eslint-disable-next-line
          this.props.addUpdateObjectveKpi({
            objectiveData: result.Result,
          });
          this.setState({ showLoader: false });
        } catch (e) {
          // eslint-disable-next-line
          this.props.addUpdateObjectveKpi({
            objectiveData: [],
          });
          this.setState({ showLoader: false });
        }
      }
    });
  };
  getPendingForApprovalObjectiveDetails = () => {
    this.setState({ showLoader: true });
    const { authUser } = this.props;
    const OkrObjectiveData = Promise.resolve(
      OKRServices.getPendingForApproval(authUser)
    );
    OkrObjectiveData.then((result) => {
      if (result.Result !== undefined) {

        this.props.addUpdateObjectveKpi({
          pendingForApprovalObjectiveData: result.Result,
        });
        this.setState({ showLoader: false });
      }
    });
  };
  createObjectiveFun = () => {
    this.setState({ createObjective: true });
  }
  closeCreateObj = () => {
    this.setState({ createObjective: false });
    this.props.addUpdateObjectveKpi({ objId: 0, objectiveName: "", objectiveTags: [], visibility: "", levelL: "", objKpiAddUpdateType: "" });
  }
  // Author By :- Prashant Waphare | 15-04-2021 | this function use for call API for submit Approval
  submitObjectiveKPI = () => {
    const { authUser, allOBJKPISubmit } = this.props;
    if (allOBJKPISubmit.length > 0) {
      const submitApprovalData = {
        type: "submitObj",
        userId: authUser,
        objId: allOBJKPISubmit.toString()
      }
      this.props.submitApprovalAction(submitApprovalData);
      this.props.addUpdateObjectveKpi({ allOBJKPISubmit: [] });
      this.closeSumitApprovalPopup();
    } else {
      getNotify(ERROR, pleaseSelectAtleastOneObjective);
    }
  }
  openSumitApprovalPopup = () => {
    const { allOBJKPISubmit } = this.props;
    if (allOBJKPISubmit.length > 0) {
      this.setState({ submitApprovalObj: true });
    } else {
      getNotify(ERROR, pleaseSelectAtleastOneObjective);
    }
  }
  closeSumitApprovalPopup = () => {
    this.setState({ submitApprovalObj: false });
  }
  render() {
    const {
      showLoader,
      height,
      createObjective,
      heightPending,
      okrMasters,
      levelMaster,
      submitApprovalObj
    } = this.state;
    const {
      objectiveData,
      viewObjectiveData,
      pendingForApprovalObjectiveData
    } = this.props;
    return (
      <>
        <div className="col-12" style={{ padding: "24px  24px 24px 24px" }}>
          <NotificationContainer />
          <div className="it-tab">
            <button
              id="btncreateAndDraftOKR"
              type="button"
              className={`col-lg-2 onboardingtab onboarding-tab ${(localStorage.getItem('SelectedTabAction') === "createAndDraftOKR" || localStorage.getItem('SelectedTabAction') === null) ? 'active' : ''}`}
              onClick={(e) => this.openTab(e, "createAndDraftOKR")}
            >
              <b>Create & Draft OKR</b>{" "}
            </button>
            <button
              id="btnviewOkr"
              type="button"
              className={`col-lg-2 onboardingtab onboarding-tab ${localStorage.getItem('SelectedTabAction') === "viewOKR" ? 'active' : ''}`}
              onClick={(e) => this.openTab(e, "viewOKR")}
            >
              <b>View OKR Library </b>
            </button>
            <button
              id="btnpendingRequests"
              type="button"
              className={`col-lg-2 onboardingtab onboarding-tab ${localStorage.getItem('SelectedTabAction') === "pendingRequests" ? 'active' : ''}`}
              onClick={(e) => this.openTab(e, "pendingRequests")}
            >
              <b>Pending for Approval </b>
            </button>
            <button
              type="button"
              className={`col-lg-6 onboarding-tab`}
              style={{ color: "#9e9e9e00", cursor: "auto" }}
            >
              <b>{"."}</b>
              <span>{" "}</span>
            </button>
          </div>
          <div id="createAndDraftOKR"
            className="tabcontent">
            <div
              style={{
                // height: showLoader ? height + 15 : "",
              }}
            >
              {
                <div
                  style={{
                    height: height + 15,
                    padding: "10px 4px 10px 4px",
                    // overflowY: "auto",
                    // overflowX: "hidden",
                  }}
                >
                  {
                    objectiveData.length > 0 ? (
                      <div key={`draftObjectiveKPI`}>
                        <DraftAllObjKPI okrMastersVisibility={okrMasters} levelMaster={levelMaster}
                          getCreateDraftOkrDetails={() => this.getCreateDraftOkrDetails} />
                      </div>
                    ) : (
                      <div className="">
                        <div
                          className="col-lg-12 col-md-2"
                          style={{ paddingTop: "12%" }}
                        >
                          <div className="okr-no-item">Welcome to your OKR library</div>
                          {/* <div
                                                            className="okr-no-item-text"
                                                            style={{ padding: 22 }}
                                                        >
                                                            <FontAwesomeIcon
                                                                style={{ textAlign: "center", fontSize: 40 }}
                                                                icon={faPlusCircle}
                                                                className="okr-no-item"
                                                            />
                                                        </div> */}

                          <div className="okr-no-item-text pt-sm">
                            {/* eslint-disable-next-line */}
                            To create an objective select "Create OKR" button
                          </div>
                          <div className="okr-no-item-text">
                            {/* eslint-disable-next-line */}
                            To view status of existing OKRs in your library go to "View OKR Library" tab.
                          </div>
                          <div className="okr-no-item-text">
                            To approve OKRs for your unit’s Library go to "Pending For Approval" tab.
                          </div>
                          <div style={{ textAlign: "center", marginTop: 30 }}>
                            <Button
                              style={{
                                background: "#FFF",
                                color: "#fff",
                                marginRight: 5,
                                width: 260,
                                height: 40,
                              }}
                              className="e-event-details ml-sm remove-button-shadow "
                              variant="contained"
                              size="small"
                              color="primary"
                              onClick={() => this.createObjectiveFun()}
                            >
                              Create OKR
                            </Button>
                          </div>
                        </div>
                      </div>
                    )
                  }
                  <div>
                    <Dialog
                      open={createObjective}
                      fullWidth
                      width="500"
                      maxWidth="sm"
                    >
                      <DialogContent>
                        <div>
                          <div>
                            <span className="add-new-self-header" style={{ paddingLeft: "8px" }}>Create New Objective </span>
                            <CreateObjective closeCreateObj={() => this.closeCreateObj} okrMastersVisibility={okrMasters} levelMaster={levelMaster} />
                          </div>
                        </div>
                      </DialogContent>
                    </Dialog>
                  </div>
                  <ConfirmationPopup
                    showButton={{ type: "1" }}
                    open={submitApprovalObj}
                    button1function={() => this.submitObjectiveKPI()}
                    button2function={() => this.closeSumitApprovalPopup()}
                    headerMessage={sumitForApprovalObjectiveMsg}
                    button1Text={"Yes"}
                    button2Text={"No"}
                    handleConfirmClose={this.closeSumitApprovalPopup}
                  />
                </div>
              }
            </div>
            {/* <hr style={{ margin: "2px -12px 0px -12px"}} /> */}
            <div className="footer-css">
              {
                objectiveData.length > 0 && (
                  <>
                    <div className="col-xs-12 col-sm-8 col-lg-10" style={{ padding: "8px 32px 0px" }}>
                      <span className="footer-status" style={{ background: "#FDD141" }}></span>
                      <span style={{ padding: "0px 24px 0px 8px" }}>Pending for submit</span>
                      {/* <span className="footer-status" style={{ background: "#36C17C" }}></span>
                                            <span style={{ padding: "0px 24px 0px 8px" }}>Approved</span> */}
                      <span className="footer-status" style={{ background: "#F47A7A" }}></span>
                      <span style={{ padding: "0px 24px 0px 8px" }}>Rejected</span>
                    </div>
                    <div className="col-xs-12 col-sm-4 col-lg-2" style={{ padding: "0px 4px 0px 0px" }}>
                      <Button
                        style={{ float: "right" }}
                        className="remove-button-shadow"
                        color="primary"
                        variant="contained"
                        onClick={this.openSumitApprovalPopup}
                      >
                        Submit for approval
                      </Button>
                    </div>
                  </>
                )
              }
            </div>
          </div>
          <div id="viewOKR" className="tabcontent">
            <div
            >
              {
                <div
                  style={{
                    height: height + 15,
                    padding: "10px 4px 10px 4px",
                  }}
                >
                  {
                    showLoader === false && (
                      <div key={`viewOkrObjKPI`}>
                        <OKRviewobjKPI
                          okrMastersVisibility={okrMasters}
                          levelMaster={levelMaster}
                        />
                      </div>
                    )
                  }
                  {showLoader === true && (
                    <div key={`viewOkrObjKPI`} style={{ zIndex: 11, position: 'absolute', marginTop: '20%', marginLeft: '50%' }}>
                      <ProgressBar />
                    </div>
                  )
                  }
                  {showLoader === false && viewObjectiveData.length === 0 && (
                    <div key={`pendingForApprovalOkrObjKPI`}>
                      <div style={{ marginTop: '-25%' }}>
                        <div className='new-productivity-allocation-no-data-header'>Welcome to your OKR Library</div>
                        <div className='new-productivity-allocation-no-data-sub-header pt-lg'>Currently you do not have any OKRs updated in the library. To add OKRs, please click on the "Create and draft OKR tab" </div>
                      </div>
                    </div>
                  )
                  }
                </div>
              }
            </div>
            {/* <hr style={{ margin: "2px -12px 0px -12px"}} /> */}
            <div className="footer-css">
              {
                viewObjectiveData.length > 0 && (
                  <div className="col-xs-12 col-sm-8 col-lg-10" style={{ padding: "8px 4px 0px 4px" }}>
                    <span className="footer-status" style={{ background: "#FDD141" }}></span>
                    <span style={{ padding: "0px 24px 0px 8px" }}>Pending for approval</span>
                    <span className="footer-status" style={{ background: "#36C17C" }}></span>
                    <span style={{ padding: "0px 24px 0px 8px" }}>Approved</span>
                    {/* <span className="footer-status" style={{ background: "#F47A7A" }}></span>
                                        <span style={{ padding: "0px 24px 0px 8px" }}>Rejected</span> */}
                  </div>
                )
              }
            </div>
          </div>
          <div id="pendingRequests" className="tabcontent">
            <div
            >
              {
                <div
                  style={{
                    height: heightPending + 15,
                    padding: "10px 4px 10px 4px",
                  }}
                >
                  {
                    showLoader === false && pendingForApprovalObjectiveData.length > 0 && (
                      <div key={`pendingForApprovalOkrObjKPI`}>
                        <OKRPendingForApproval okrMastersVisibility={okrMasters} levelMaster={levelMaster} />
                      </div>
                    )
                  }
                  {
                    showLoader === true && (
                      <div key={`pendingForApprovalOkrObjKPI`} style={{ zIndex: 11, position: 'absolute', marginTop: '20%', marginLeft: '50%' }}>
                        <ProgressBar />
                      </div>
                    )
                  }{
                    showLoader === false && pendingForApprovalObjectiveData.length === 0 && (
                      <div key={`pendingForApprovalOkrObjKPI`}>
                        <div style={{ marginTop: '13%' }}>
                          <div className='new-productivity-allocation-no-data-header'>There are no OKRs pending for your approval</div>
                          {/* <div className='new-productivity-allocation-no-data-sub-header pt-lg'>You don't have any items here.</div> */}
                        </div>
                      </div>
                    )
                  }
                </div>
              }
            </div>
          </div>
        </div>
      </>
    );
  }
}
OKRObjectiveKPI.propTypes = {
  authUser: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  objectiveData: PropTypes.arrayOf(PropTypes.object),
  viewObjectiveData: PropTypes.arrayOf(PropTypes.object),
  tempViewObjectiveKPI: PropTypes.arrayOf(PropTypes.object),
  pendingForApprovalObjectiveData: PropTypes.arrayOf(PropTypes.object),
  allOBJKPISubmit: PropTypes.arrayOf(PropTypes.object),
  reRenderComponent: PropTypes.string,
  closAlleDialog: PropTypes.string,
  refreshViewStatus: PropTypes.string,
};
OKRObjectiveKPI.defaultProps = {
  authUser: "",
  objectiveData: [],
  viewObjectiveData: [],
  tempViewObjectiveKPI: [],
  pendingForApprovalObjectiveData: [],
  allOBJKPISubmit: [],
  reRenderComponent: "",
  closAlleDialog: "",
  refreshViewStatus: "",
};
const mapStateToProps = ({ auth, okrreducer }) => {
  const { authUser } = auth;
  const {
    objectiveData,
    viewObjectiveData,
    tempViewObjectiveKPI,
    allOBJKPISubmit,
    reRenderComponent,
    closAlleDialog,
    pendingForApprovalObjectiveData,
    refreshViewStatus
  } = okrreducer;
  return {
    authUser,
    objectiveData,
    viewObjectiveData,
    tempViewObjectiveKPI,
    allOBJKPISubmit,
    reRenderComponent,
    closAlleDialog,
    pendingForApprovalObjectiveData,
    refreshViewStatus
  };
};

export default connect(mapStateToProps, {
  addEditObjectveKpi,
  addUpdateObjectveKpi,
  approveRejectObjectveKpi,
  submitApprovalAction
})(OKRObjectiveKPI);
