import {
  HIDE_MESSAGE,
  INIT_URL,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  SHOW_MESSAGE,
  SIGNIN_USER_SUCCESS,
  SIGNOUT_USER_SUCCESS,
  CHANGE_AUTH_USER,
  CHECK_USER_AVAILABILITY,
  UPDATE_AUTH_USER_INFO
} from "../constant/constant";
import { dencrypt } from "../../app/other/commonfunction";

const INIT_STATE = {
  loader: false,
  alertMessage: "",
  showMessage: false,
  initURL: "",
  authUser: dencrypt(localStorage.getItem("user_id")),
  otherUser: 0,
  otherUserAccess: "",
  checkOtherUserAvailability: "0",
  tibAccess: "",
  departmentalText:'',
  openOrgChartSidebar:false,
  orgChartSideBarData:{},
  empCode:""
};
// eslint-disable-next-line
export default (state = INIT_STATE, action) => { //NOSONAR
  switch (action.type) {
    case SIGNIN_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload,
      };
    }
    case CHANGE_AUTH_USER: {
      return {
        ...state,
        empCode: action.payload.empCode,
        otherUser: action.payload.otherUser,
        otherUserAccess: action.payload.otherUserAccess,
        tibAccess: action.payload.tibAccess,
      };
    }
    case CHECK_USER_AVAILABILITY: {
      return {
        ...state,
        checkOtherUserAvailability: action.payload,
      };
    }
    case INIT_URL: {
      return {
        ...state,
        initURL: action.payload,
      };
    }
    case SIGNOUT_USER_SUCCESS: {
      return {
        ...state,
        authUser: null,
        initURL: "",
        loader: false,
      };
    }

    case SHOW_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload,
        showMessage: true,
        loader: false,
      };
    }
    case HIDE_MESSAGE: {
      return {
        ...state,
        showMessage: false,
        alertMessage: "",
        loader: false,
      };
    }

    case ON_SHOW_LOADER: {
      return {
        ...state,
        loader: true,
      };
    }
    case ON_HIDE_LOADER: {
      return {
        ...state,
        loader: false,
      };
    }
    case UPDATE_AUTH_USER_INFO: {
      return {
          ...state,
          ...action.payload
      }
    }
    default:
      return state;
  }
};
