import React, { Component } from 'react';

import RatingPeriod from './reviewperiod';
export default class HeaderContainer extends Component {
    render() {
        const { showStatus } = this.props;
        return (
            <div className='container-fluid header-container'>
                <RatingPeriod showStatus={showStatus} />
            </div>
        )
    }
}
