import React,{ useState, useEffect } from 'react';
import { connect } from 'react-redux';
import RatingMonthReviewPeriod from '../okrratingcomponents/ratingmonthreviewperiod'
import {addUpdateOkrDashboardRedux} from '../../redux/actions/okrdashboardaction/okrdashboardaction'
import services from '../../services/okr/okrservices';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
function MonthlyOKRStatus(props) {
   const [monthsData,setMonthsData] = useState([])
   const [monthlyOkrStatusData,setMonthlyOkrStatusData] = useState([])
  useEffect(() => {
    getMonthsData()
    getOkrStatusData()
    props.addUpdateOkrDashboardRedux({ monthlyOkrStatusMonth :props.monthOfRating, monthlyOkrMonthStatus:'updated'})
  }, [])
  useEffect(() => {
    if(props.monthlyOkrMonthStatus==='updated'){
      getMonthsData()
      getOkrStatusData()
      props.addUpdateOkrDashboardRedux({ monthlyOkrMonthStatus : ''})
    }
  }, [props.monthlyOkrMonthStatus==='updated'])
  
  const getMonthsData=async()=>{
    const {fyId,authUser,empId} = props;
    let datesObj = {
      fyId: fyId,
      startMonth: null,
      endMonth: null
    }
    let monthsData = await services.getOKRFrequency('sup',authUser,empId,1,null,datesObj)
    setMonthsData(monthsData.Result)
  }
  const getOkrStatusData = async () =>{
    const {fyId,monthlyOkrStatusMonth,authUser,empId} = props;
    let okrData = await services.getOKRDashboardData('selfratingmonthlystatus',authUser,empId,fyId,monthlyOkrStatusMonth)
    setMonthlyOkrStatusData(okrData)
  }
 
  const getStyle = ()=>{
    const{showMonths}=props
    if(window.innerHeight === 625 && showMonths){
      return {border:'1px solid #ccc', borderRadius:5,height:window.innerHeight-261}
    }
    if(window.innerHeight === 625 && !showMonths){
      return {border:'1px solid #ccc', borderRadius:5,height:window.innerHeight-260}
    }
    
    if(window.innerHeight < 670){
      return {border:'1px solid #ccc', borderRadius:5,height:window.innerHeight-260}
    }else{
      if(window.innerHeight <960){
        return {border:'1px solid #ccc', borderRadius:5,height:window.innerHeight-386}
      }else{
        return {border:'1px solid #ccc', borderRadius:5,height:window.innerHeight-417}
      }   
    }
  }
  return (
    <div id='monthlyOkrStatusHeight' className='col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-md pt-md pl-md pr-md pb-md' style={getStyle()}>
      <div className='row1 monthly-okr-status-text pt-sm pb-xs'>
          Monthly OKR Status
      </div>
      <div className='row1'>
        <RatingMonthReviewPeriod Months={monthsData} source={'okrDashboard'} />
      </div>
      <div className='row1 '>
        <div className='col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-lg' style={{border:'1px solid #ccc'}}>
          <div className='col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12' style={{borderBottom:'1px solid #ccc'}}>
              <div className='col-4 col-lg-4 col-md-4 col-sm-4 col-xs-4 monthly-okr-status-table-headers-first'>
                    Validity
              </div>
              <div className='col-2 col-lg-2 col-md-2 col-sm-2 col-xs-2 monthly-okr-status-table-headers'>
                    Monthly
              </div>
              <div className='col-2 col-lg-2 col-md-2 col-sm-2 col-xs-2 monthly-okr-status-table-headers'>
                    Quarterly
              </div>
              <div className='col-2 col-lg-2 col-md-2 col-sm-2 col-xs-2 monthly-okr-status-table-headers'>
                    Half Yearly
              </div>
              <div className='col-2 col-lg-2 col-md-2 col-sm-2 col-xs-2 monthly-okr-status-table-headers'>
                  Yearly
              </div>
          </div>
          {
            monthlyOkrStatusData?.map((item)=>{
              return(
                  <div key={item.Id} className='col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12' style={{borderBottom:'1px solid #ccc'}}>
                      <div className='col-4 col-lg-4 col-md-4 col-sm-4 col-xs-4 monthly-okr-status-table-text-first pointer' 
                        onClick={()=>props.addUpdateOkrDashboardRedux({  dashboardTabs :'performance-potential', isEmpName: false ,performanceType:item.validity})}
                      >
                        <TooltipComponent position="BottomCenter" className="pointer-cursor" isSticky={false} content='Click to view ratings.' cssClass='customtooltip' style={{fontSize:14,marginRight:window.innerHeight < 670 ? 10:150}}>
                            {item.validity}
                            </TooltipComponent>
                      </div>
                      <div className='col-2 col-lg-2 col-md-2 col-sm-2 col-xs-2 monthly-okr-status-table-text'>
                            {item.Monthly}
                      </div>
                      <div className='col-2 col-lg-2 col-md-2 col-sm-2 col-xs-2 monthly-okr-status-table-text'>
                            {item.Quarterly}
                      </div>
                      <div className='col-2 col-lg-2 col-md-2 col-sm-2 col-xs-2 monthly-okr-status-table-text'>
                            {item.HalfYearly}
                      </div>
                      <div className='col-2 col-lg-2 col-md-2 col-sm-2 col-xs-2 monthly-okr-status-table-text'>
                            {item.Yearly}
                      </div>
                  </div>
              )
            })
          }
        </div>
      </div>
      <div className='row1'style={{paddingLeft:80}}>
        <div className='col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-md md-bt'>
          <div className='pt-xs' style={{ display: "flex", flexDirection: "row", marginBottom: 2 }}>
            <div className='legends'>
              <div style={{ display: "flex", flexDirection: "row", marginTop: -4 }}>
                <div className='legend-locked'></div>
                <div className='legend-names'>Locked</div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", marginTop: -4 }}>
                <div className='legend-completed'></div>
                <div className='legend-names'>Completed</div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", marginTop: -4 }}>
                <div className='legend-Pending'></div>
                <div className='legend-names'>Incomplete</div>
              </div>
              {/* <div style={{ display: "flex", flexDirection: "row", marginTop: -4 }}>
                <div className='legend-incomplete'></div>
                <div className='legend-names'>Supervisor Incomplete</div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ auth, okrratingreducer, okrreducer, meetingmaster, meetingcategory, okrdashboardreducer }) => {
  const { authUser } = auth;
  const { OkrMasters } = okrreducer;
  const { empId, fyId, monthOfRating, monthlyOkrStatusMonth, monthlyOkrMonthStatus,viewCard, isPeopleLeader } = okrdashboardreducer;
  return {
    authUser, empId, fyId, monthOfRating, OkrMasters, monthlyOkrStatusMonth, monthlyOkrMonthStatus,viewCard, isPeopleLeader
  }
};
export default connect(mapStateToProps, {addUpdateOkrDashboardRedux})(
  MonthlyOKRStatus
);


