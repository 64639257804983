import { helpdeskCustomFields } from "../../assets/constants/constants";
const isSixteenEighty = window.innerHeight < 932;
const isSixteenHundred = window.innerHeight < 782;
const isFourteenHundred = (window.innerHeight < 782 && window.innerWidth < 1441) || (window.innerHeight < 900 && window.innerWidth < 1441);
const isOneTwentyFiveScale = window.innerHeight < 747;
const isOneTwentyFiveInSixteenEighty = window.innerHeight < 723;
const isThirteenHundred = window.innerHeight <= 657;
const isOneTwoEightZero = window.innerHeight < 602;

export const CANDIDATE_NAME = "Candidate Name"; export const CANDIDATE_NAME_WIDTH = 350; export const CANDIDATE_NAME_FIELD = "CandidateName"; export const CANDIDATE_NAME_ALIGN = "Left";
export const READY_TO_JOIN_EMPLOYEE_CODE = "Team Member Id"; export const READY_TO_JOIN_EMPLOYEE_CODE_WIDTH = 160; export const READY_TO_JOIN_EMPLOYEE_CODE_FIELD = "empCode"; export const READY_TO_JOIN_EMPLOYEE_CODE_ALIGN = "Center";
export const CANDIDATE_STATUS = "Status"; export const CANDIDATE_STATUS_WIDTH = 200; export const CANDIDATE_STATUS_FIELD = "CandidateCheckListStatus"; export const CANDIDATE_STATUS_ALIGN = "Center";
export const ADMIN = "Admin"; export const ADMIN_WIDTH = 130; export const ADMIN_FIELD = "AdminCheckListStatus"; export const ADMIN_FIELD_SEP = "adminAssetsStatus"; export const ADMIN_ALIGN = "Center";
export const IT = "IT"; export const IT_WIDTH = 130; export const IT_FIELD = "ITCheckListStatus"; export const IT_FIELD_SEP = "itAssetsStatus"; export const IT_ALIGN = "Center";
export const PEOPLE_TEAM = "People Team"; export const PEOPLE_TEAM_WIDTH = 130; export const PEOPLE_TEAM_FIELD = "PeopleCheckListStatus"; export const PEOPLE_TEAM_FIELD_SEP = "peopleAssetsStatus"; export const PEOPLE_TEAM_ALIGN = "Center";
export const FINANCE = "Finance"; export const FINANCE_WIDTH = 130; export const FINANCE_FIELD = "FinanceCheckListStatus"; export const FINANCE_FIELD_SEP = "financeAssetsStatus"; export const FINANCE_ALIGN = "Center";
export const SOFTWARE = "Software"; export const SOFTWARE_WIDTH = 130; export const SOFTWARE_FIELD = "SoftwareCheckListStatus"; export const SOFTWARE_FIELD_SEP = "softwareAssetsStatus"; export const SOFTWARE_ALIGN = "Center";
export const LEFT_DATE = "Left Date"; export const LEFT_DATE_WIDTH = 100; export const LEFT_DATE_FIELD = "LeftDate"; export const LEFT_DATE_ALIGN = "Left";
export const DATE_OF_JOINING = "Date of Joining"; export const DATE_OF_JOINING_WIDTH = 100; export const DATE_OF_JOINING_FIELD = "JoiningDate"; export const DATE_OF_JOINING_ALIGN = "Left";
export const OFFER_DATE = "Offer Date"; export const OFFER_DATE_WIDTH = 110; export const OFFER_DATE_FIELD = "OfferedDate"; export const OFFER_DATE_ALIGN = "Center";
export const DAYS_TO_JOIN = "Days to Join"; export const DAYS_TO_JOIN_WIDTH = 90; export const DAYS_TO_JOIN_FIELD = "DaysToJoin"; export const DAYS_TO_JOIN_ALIGN = "Left";
export const BAND = "Band"; export const BAND_WIDTH = 100; export const BAND_FIELD = "Band"; export const BAND_ALIGN = "Center";
export const BASE_COUNTRY = "Country of Work"; export const BASE_COUNTRY_WIDTH = 110; export const BASE_COUNTRY_PRE_WIDTH = 105; export const BASE_COUNTRY_FIELD = "BaseCountry"; export const BASE_COUNTRY_ALIGN = "Left";
export const BASE_LOCATION = "Office Location"; export const BASE_LOCATION_WIDTH = 80; const BASE_LOCATION_FIELD = "BaseLocation"; export const BASE_LOCATION_ALIGN = "Left";
export const BASE_FUNCTION = " Business Unit / Group Function "; export const BASE_FUNCTION_WIDTH = 140; export const BASES_FUNCTION_WIDTH = 105; export const BASE_Pre_FUNCTION_WIDTH = 90; export const BASE_FUNCTION_FIELD = "Pnl"; export const BASE_FUNCTION_ALIGN = "Left";
export const BASE_FUNCTION_TO_BE_OFFER = " Business Unit / Group Function "; export const BASE_FUNCTION_TO_BE_OFFER_WIDTH = 140; export const BASE_FUNCTION_TO_BE_OFFER_FIELD = "Pnl"; export const BASE_FUNCTION_TO_BE_OFFER_ALIGN = "Left";
export const PnL_FUNCTION = "Business Unit / Group Function "; export const PnL_FUNCTION_WIDTH = 110; export const PL_FUNCTION_WIDTH = 120; export const PNL_Pre_FUNCTION_WIDTH = 120; export const PnL_FUNCTION_FIELD = "Pnl"; export const PnL_FUNCTION_ALIGN = "Left";
export const PnL_FUNCTION_TO_BE_OFFER = "Business Unit / Group Function "; export const PnL_FUNCTION_TO_BE_OFFER_WIDTH = 240; export const PnL_FUNCTION_TO_BE_OFFER_FIELD = "Pnl"; export const PnL_FUNCTION_TO_BE_OFFER_ALIGN = "Left";
export const SERVICE_DIVISION_SALES_UNIT = "Practice Area / Sales Unit / Sub Function"; export const SERVICE_DIVISION_UNIT = "Practice Area"; export const SERVICE_DIVISION_SALES_UNIT_WIDTH = 240; const SERVICE_DIVISION_SALES_UNIT_FUN_WIDTH = 160; export const SERVICE_DIVISION_SALES_UNIT_FIELD = "Department"; export const SERVICE_DIVISION_SALES_UNIT_ALIGN = "Left";
export const REPORTING_SUPERVISOR = "Reporting Supervisor"; export const REPORTING_SUPERVISOR_WIDTH = 120; export const REPORTING_SUPERVISOR_FIELD = "reportingTo"; export const REPORTING_SUPERVISOR_ALIGN = "Left";
export const RECRUITMENT_TO = "Recruitment To"; export const RECRUITMENT_TO_WIDTH = 140; export const RECRUITMENT_TO_FIELD = "RecruitmentTo"; export const RECRUITMENT_TO_ALIGN = "Left";
export const EMPLOYEE_TYPE = "Team Member Type"; export const EMPLOYEE_TYPE_WIDTH = 180; export const EMPLOYEE_TYPE_FIELD = "EmpType"; export const EMPLOYEE_TYPE_ALIGN = "Left";
export const STAGE = "Stage"; export const STAGE_WIDTH = 130; export const STAGE_FIELD = "Stage"; export const STAGE_ALIGN = "Left";
export const REVOKE_REASON = "Revoke Reason"; export const REVOKE_REASON_WIDTH = 100; export const REVOKE_REASON_FIELD = "RevokeStatus"; export const REVOKE_REASON_ALIGN = "Left";
export const REVOKE_COMMENTS = "Revoke Comments"; export const REVOKE_COMMENTS_WIDTH = 120; export const REVOKE_COMMENTS_FIELD = "RevokeComments"; export const REVOKE_COMMENTS_ALIGN = "Left";
export const REVOKE_DATE = "Revoke Date"; export const REVOKE_DATE_WIDTH = 100; export const REVOKE_DATE_FIELD = "RevokeDate"; export const REVOKE_DATE_ALIGN = "Center";
export const DOC_STATUS = "Document Status"; export const DOC_STATUS_WIDTH = 110; export const DOC_STATUS_FIELD = "DocStatus"; export const DOC_STATUS_ALIGN = "left";
export const DOC_APPROVAL_STATUS = "Document Approval Status"; export const DOC_APPROVAL_STATUS_WIDTH = 130; export const DOC_APPROVAL_STATUS_FIELD = "DocApprovalStatus"; export const DOC_APPROVAL_STATUS_ALIGN = "left";
export const EXTERNAL_SOURCE = "External"; export const EXTERNAL_SOURCE_WIDTH = 120; export const EXTERNAL_SOURCE_FIELD = "externalUid"; export const EXTERNAL_SOURCE_ALIGN = "left"

export const ON_TARGET_INCENTIVE = "On Target Incentive (OTI)"; export const ON_TARGET_INCENTIVE_WIDTH = 120; export const ON_TARGET_INCENTIVE_FIELD = "oti"; export const ON_TARGET_INCENTIVE_ALIGN = "Right";
export const FIXED_CTC = "Fixed CTC"; export const FIXED_CTC_WIDTH = 110; export const FIXED_CTC_FIELD = "fixedCTC"; export const FIXED_CTC_ALIGN = "Right";
export const SOURCE_CATEGORY = "Source Category"; export const SOURCE_CATEGORY_WIDTH = 180; export const SOURCE_CATEGORY_FIELD = "sourceCategory"; export const SOURCE_CATEGORY_ALIGN = "Center";
export const SOURCE_DETAILS = "Source Details"; export const SOURCE_DETAILS_WIDTH = 180; export const SOURCE_DETAILS_FIELD = "sourceCategoryDetail"; export const SOURCE_DETAILS_ALIGN = "Center";

export const CURRENCYNAME = "Currency Name"; export const CURRENCYNAME_WIDTH = 100; export const CURRENCYNAME_FIELD = "CurrencyName"; export const CURRENCYNAME_ALIGN = "Center";

export const EMPLOYEE_NAME = "Team Member"; export const EMPLOYEE_NAME_WIDTH = 320; export const EMPLOYEE_NAME_FIELD = "EmployeeName"; export const EMPLOYEE_NAME_ALIGN = "Left";
export const DUE_CONFIRMED_ON = "Due On"; export const DUE_CONFIRMED_ON_WIDTH = 90; export const DUE_CONFIRMED_ON_FIELD = "DueConfirmDate"; export const DUE_CONFIRMED_ON_ALIGN = "Center";
export const CONFIRMED_ON = "Confirmed On"; export const CONFIRMED_ON_WIDTH = 120; export const CONFIRMED_ON_FIELD = "ConfirmDate"; export const CONFIRMED_ON_ALIGN = "Center";
export const EMPLOYEE_STATUS = "Clearance Status"; export const EMPLOYEE_STATUS_WIDTH = 150; export const EMPLOYEE_STATUS_FIELD = "Status"; export const EMPLOYEE_STATUS_FIELD_SEP = "clearanceStatus"; export const EMPLOYEE_STATUS_ALIGN = "Left";
export const EMPLOYEE_ACTION = "Action"; export const EMPLOYEE_ACTION_WIDTH = 140; export const EMPLOYEE_ACTION_FIELD = " "; export const EMPLOYEE_ACTION_ALIGN = "Center";
export const DUE_CONFIRM_REASON = "Reason"; export const DUE_CONFIRM_REASON_WIDTH = 200; export const DUE_CONFIRM_REASON_FIELD = "ProbationComments"; export const DUE_CONFIRM_REASON_ALIGN = "Left";
export const CONFIRM_PREVIEW = "Preview"; export const CONFIRM_PREVIEW_WIDTH = 80; export const CONFIRM_PREVIEW_FIELD = " "; export const CONFIRM_PREVIEW_ALIGN = "Center";

export const FUNCTION_CANDIDATE_NAME = "Team Member"; export const FUNCTION_CANDIDATE_NAME_WIDTH = 350; export const FUNCTION_CANDIDATE_NAME_FIELD = "CandidateName"; export const FUNCTION_CANDIDATE_NAME_ALIGN = "Left";
export const FUNCTION_INITIAON = "Initiate On"; export const FUNCTION_INITIAON_WIDTH = 100; export const FUNCTION_INITIAON_FIELD = "InitiateOn"; export const FUNCTION_INITIAON_ALIGN = "Center";
export const FUNCTION_STATUS = "Status"; export const FUNCTION_STATUS_WIDTH = 140; export const FUNCTION_STATUS_FIELD = "CheckListStatus"; export const FUNCTION_STATUS_ALIGN = "Left";

// Separation resignation additional grid column 
export const SEP_EMPLOYEE_NAME = "Team Member"; export const SEP_EMPLOYEE_NAME_WIDTH = 300; export const SEP_EMPLOYEE_NAME_FIELD = "employeeName"; export const SEP_EMPLOYEE_NAME_ALIGN = "Left";
export const DEPARTMENT = "Business Unit / Group Function "; export const DEPARTMENT_WIDTH = 150; export const DEPARTMENT_FIELD = "department"; export const DEPARTMENT_ALIGN = "Left";
export const PNL = "Business Unit "; export const PNL_WIDTH = 130; export const PNL_FIELD = "pnl"; export const PNL_ALIGN = "Left";
export const REPORTING_TO = "Reporting To"; export const REPORTING_TO_WIDTH = 120; export const REPORTING_TO_FIELD = "reportingTo"; export const REPORTING_TO_ALIGN = "Left";
export const REPORTING_SUPERVISOR_STATUS = "Reporting Supervisor Status"; export const REPORTING_SUPERVISOR_STATUS_WIDTH = 140; export const REPORTING_SUPERVISOR_STATUS_FIELD = "reportingMgrStatus"; export const REPORTING_SUPERVISOR_STATUS_ALIGN = "Left";
export const PL_STATUS = "Business Unit / Status"; export const PL_STATUS_WIDTH = 150; export const PL_STATUS_FIELD = "pnlHridColumneadStatus"; export const PL_STATUS_ALIGN = "Left";
export const HR_STATUS = "HR Status"; export const HR_STATUS_WIDTH = 110; export const HR_STATUS_FIELD = "hrStatus"; export const HR_STATUS_ALIGN = "Left";
export const NOTICE_PERIOD = "Notice Period In Months"; export const NOTICE_PERIOD_WIDTH = 80; export const NOTICE_PERIOD_FIELD = "noticePeriodInDays"; export const NOTICE_PERIOD_ALIGN = "Center";
export const DAYS_TO_LWD = "Days To LWD"; export const DAYS_TO_LWD_WIDTH = 90; export const DAYS_TO_LWD_FIELD = "daysToLastWorkingDay"; export const DAYS_TO_LWD_ALIGN = "center";
export const REASON = "Reason"; export const REASON_WIDTH = 50; export const REASON_FIELD = "resgnReason"; export const REASON_ALIGN = "Left";
export const DAYS_DIFFERENCE = "Days Difference"; export const DAYS_DIFFERENCE_WIDTH = 100; export const DAYS_DIFFERENCE_FIELD = "daysDiffJoinSeparation"; export const DAYS_DIFFERENCE_ALIGN = "Center";
export const DATE_OF_RESIGNATION = "Date of Resignation"; export const DATE_OF_RESIGNATION_WIDTH = 100; export const DATE_OF_RESIGNATION_FIELD = "dateOfResignation"; export const DATE_OF_RESIGNATION_ALIGN = "Center";
export const JOINING_DATE = "Date of Joining"; export const JOINING_DATE_WIDTH = 100; export const JOINING_DATE_FIELD = "joiningDate"; export const JOINING_DATE_ALIGN = "Center";
export const LWD_OFFICIAL = "LWD (Official)"; export const LWD_OFFICIAL_WIDTH = 130; export const LWD_OFFICIAL_FIELD = "lastWorkingDayOfficial"; export const LWD_OFFICIAL_ALIGN = "Center";
export const LWD_EMPLOYEE_REQUESTED = "LWD (Employee Requested)"; export const LWD_EMPLOYEE_REQUESTED_WIDTH = 140; export const LWD_EMPLOYEE_REQUESTED_FIELD = "lastworkingEmpRequested"; export const LWD_EMPLOYEE_REQUESTED_ALIGN = "Center";
export const LWD_COMPANY_REQUEST = "LWD (Company Request)"; export const LWD_COMPANY_REQUEST_WIDTH = 220; export const LWD_COMPANY_REQUEST_FIELD = "lastWorkingDayCompanyRequested"; export const LWD_COMPANY_REQUEST_ALIGN = "Center";
export const ACTION = "Action"; export const ACTION_WIDTH = 80; export const ACTION_FIELD = "action"; export const ACTION_ALIGN = "center";
export const BUSINESS_ACTION_WIDTH_NEW = 123; export const SUBBUSINESS_ACTION_WIDTH_NEW = 100;
 
// Separation Withdrawal additional grid column 
export const DATE_OF_WITHDRAWAL = "Date Of Withdrawal"; export const DATE_OF_WITHDRAWAL_WIDTH = 100; export const DATE_OF_WITHDRAWAL_FIELD = "dateOfWithdrwal"; export const DATE_OF_WITHDRAWAL_ALIGN = "Center";
export const LWD_REQUESTED = "LWD Requested"; export const LWD_REQUESTED_WIDTH = 100; export const LWD_REQUESTED_FIELD = "lastworkingEmpRequested"; export const LWD_REQUESTED_ALIGN = "Center";
export const WITH_REASON = "Reason"; export const WITH_REASON_WIDTH = 140; export const WITH_REASON_FIELD = "sepReason"; export const WITH_REASON_ALIGN = "Left";
export const WITH_ACTION = "Action "; export const WITH_ACTION_WIDTH = 100; export const WITH_ACTION_FIELD = "action"; export const WITH_ACTION_ALIGN = "Left";

// Separation - Resignation -  Hr Resignation additional grid column 
export const SEP_NOTICE_PERIOD = "Notice Period In Months"; export const SEP_NOTICE_PERIOD_WIDTH = 80; export const SEP_NOTICE_PERIOD_FIELD = "noticePeriodInDays"; export const SEP_NOTICE_PERIOD_ALIGN = "Center";
export const SEP_NOTICE_PERIOD_STATUS = "Notice Period Status"; export const SEP_NOTICE_PERIOD_STATUS_WIDTH = 150; export const SEP_NOTICE_PERIOD_STATUS_FIELD = "noticePeriodStatus"; export const SEP_NOTICE_PERIOD_STATUS_ALIGN = "Center";
export const REQUESTED_BY = "Requested By"; export const REQUESTED_BY_WIDTH = 110; export const REQUESTED_BY_FIELD = "requestedBy"; export const REQUESTED_BY_ALIGN = "Left";

// Separation - Resignation -  Clearance additional grid column 
export const CLEARANCE_STATUS = "Clearance Status"; export const CLEARANCE_STATUS_WIDTH = 110; export const CLEARANCE_STATUS_FIELD = "clearanceStatus"; export const CLEARANCE_STATUS_ALIGN = "Left";
export const SELF_DEPARTMENT = "Own Department"; export const SELF_DEPARTMENT_WIDTH = 130; export const SELF_DEPARTMENT_FIELD = "OwnDeptAssetsStatus"; export const SELF_DEPARTMENT_ALIGN = "Left";
export const CLEARANCE_EMPLOYEE_STATUS = "Employee Status"; export const CLEARANCE_EMPLOYEE_STATUS_WIDTH = 110; export const CLEARANCE_EMPLOYEE_STATUS_FIELD = "empStatus"; export const CLEARANCE_EMPLOYEE_STATUS_ALIGN = "Left";

// Separation - Absconding -  Manager absconding grid column 
export const REPORTED_ON = "Not Reported From"; export const REPORTED_ON_WIDTH = 120; export const REPORTED_ON_FIELD = "lastReportedDate"; export const REPORTED_ON_ALIGN = "Center";
export const DATE_OF_ABSCONDING = "Absconding Date"; export const DATE_OF_ABSCONDING_WIDTH = 110; export const DATE_OF_ABSCONDING_FIELD = "absondingDate"; export const DATE_OF_ABSCONDING_ALIGN = "Center";
export const HR_STAGE = "Communication"; export const HR_STAGE_WIDTH = 150; export const HR_STAGE_FIELD = "communicationStage"; export const HR_STAGE_ALIGN = "Left";
export const EMPLOYEE_STATUS_ABSCONDING = "Absconding Status"; export const EMPLOYEE_STATUS_ABSCONDING_WIDTH = 120; export const EMPLOYEE_STATUS_ABSCONDING_FIELD = "employeeStatus"; export const EMPLOYEE_STATUS_ABSCONDING_ALIGN = "Left";
export const COMMENT = "Comment"; export const COMMENT_WIDTH = 100; export const COMMENT_FIELD = "comment"; export const COMMENT_ALIGN = "Left";

//Separation - Absconding -  Hr additional grid column 
export const LAST_COMMUNICATED_DATE = "Communication Date"; export const LAST_COMMUNICATED_DATE_WIDTH = 140; export const LAST_COMMUNICATED_DATE_FIELD = "lastCommunicatedDate"; export const LAST_COMMUNICATED_DATE_ALIGN = "Center";
export const HR_STAGE_HR = "Communication"; export const HR_STAGE_HR_WIDTH = 130; export const HR_STAGE_HR_FIELD = "communicationStage"; export const HR_STAGE_HR_ALIGN = "Left";
export const ACTION_HR = "Action "; export const ACTION_HR_WIDTH = 120; export const ACTION_HR_FIELD = "action"; export const ACTION_HR_ALIGN = "Left";
export const EXPECTED_JOINING_DATE = "Expected Joining Date "; export const EXPECTED_JOINING_DATE_WIDTH = 146; export const EXPECTED_JOINING_DATE_FIELD = "expectedJoiningDate"; export const EXPECTED_JOINING_DATE_ALIGN = "Center";
export const COMUNICATION_STAGE = "Communication Stage"; export const COMUNICATION_STAGE_WIDTH = 150; export const COMUNICATION_STAGE_FIELD = "communicationStage"; export const COMUNICATION_STAGE_ALIGN = "Center";

// Separation - Absconding - Hr preview grid column
export const UPLOADED_FILE = "Uploaded File"; export const UPLOADED_FILE_WIDTH = 80; export const UPLOADED_FILE_FIELD = "uploadedFile"; export const UPLOADED_FILE_ALIGN = "Left";
export const NO_OF_COMMUNICATION = "Communication Stage"; export const NO_OF_COMMUNICATION_WIDTH = 180; export const NO_OF_COMMUNICATION_FIELD = "communicationStage"; export const NO_OF_COMMUNICATION_ALIGN = "Left";
export const COMMUNICATED_BY = "Communicated By"; export const COMMUNICATED_BY_WIDTH = 140; export const COMMUNICATED_BY_FIELD = "communicatedBy"; export const COMMUNICATED_BY_ALIGN = "Left";
export const COMMUNICATED_DATE = "Last Communicated Date"; export const COMMUNICATED_DATE_WIDTH = 150; export const COMMUNICATED_DATE_FIELD = "communicateOn"; export const COMMUNICATED_DATE_ALIGN = "Center";

// Termination - HR grid additional column
export const REQUEST_ON = "Request Date"; export const REQUEST_ON_WIDTH = 130; export const REQUEST_ON_FIELD = "requestedOn"; export const REQUEST_ON_ALIGN = "Center";
export const GROUNDS = "Grounds"; export const GROUNDS_WIDTH = 110; export const GROUNDS_FIELD = "terminationGround"; export const GROUNDS_ALIGN = "Center";
export const FORM_COMMITTEE = "Form Committee"; export const FORM_COMMITTEE_WIDTH = 100; export const FORM_COMMITTEE_FIELD = "formCommittee"; export const FORM_COMMITTEE_ALIGN = "Center";
export const COMMITTEE_ACTION = "Committee Action"; export const COMMITTEE_ACTION_WIDTH = 180; export const COMMITTEE_ACTION_FIELD = "committeeAction"; export const COMMITTEE_ACTION_ALIGN = "Left";
export const COMMITTEE_REPORT_UPLOAD = "Committee Report"; export const COMMITTEE_REPORT_UPLOAD_WIDTH = 120; export const COMMITTEE_REPORT_UPLOAD_FIELD = "committeeReportUpload"; export const COMMITTEE_REPORT_UPLOAD_ALIGN = "Center";
export const COMMITTEE_BY = "Uploaded By"; export const COMMITTEE_BY_WIDTH = 60; export const COMMITTEE_BY_FIELD = "uplBy"; export const COMMITTEE_BY_ALIGN = "Left";
export const COMMITTEE_DATE = "Uploaded Date"; export const COMMITTEE_DATE_WIDTH = 80; export const COMMITTEE_DATE_FIELD = "uplOn"; export const COMMITTEE_DATE_ALIGN = "Center";
export const SUSPENDED_DAYS = "Suspended Days"; export const SUSPENDED_DAYS_WIDTH = 110; export const SUSPENDED_DAYS_FIELD = "suspendedDays"; export const SUSPENDED_DAYS_ALIGN = "Center";
export const SUSPENDED_STATUS = "Request For"; export const SUSPENDED_STATUS_WIDTH = 120; export const SUSPENDED_STATUS_FIELD = "terminationReqFor"; export const SUSPENDED_STATUS_ALIGN = "Left";
export const LAST_WORKING_DATE = "Last Working Date"; export const LAST_WORKING_DATE_WIDTH = 130; export const LAST_WORKING_DATE_FIELD = "lastWorkingDate"; export const LAST_WORKING_DATE_ALIGN = "Center";

// Exit clearance - exit clearanace (Functional clearance) 
export const DATE_OF_INITIATE = "Date Of Initiate"; export const DATE_OF_INITIATE_WIDTH = 100; export const DATE_OF_INITIATE_FIELD = "clearanceInitateOn"; export const DATE_OF_INITIATE_ALIGN = "Left";
export const INFOSEC = "Infosec"; export const INFOSEC_WIDTH = 130; export const INFOSEC_FIELD = "infosec"; export const INFOSEC_FIELD_SEP = "infoSecAssetsStatus"; export const INFOSEC_ALIGN = "Left";
export const HR = "HR"; export const HR_WIDTH = 130; export const HR_FIELD = "hr"; export const HR_FIELD_SEP = "peopleAssetsStatus"; export const HR_ALIGN = "Left";
export const EXIT_CLEARANCE_ACTION = "Action"; export const EXIT_CLEARANCE_ACTION_WIDTH = 100; export const EXIT_CLEARANCE_ACTION_FIELD = "action"; export const EXIT_CLEARANCE_ACTION_ALIGN = "Center";
export const LEGAL_ASSET = "Legal"; export const LEGAL_ASSET_WIDTH = 130; export const LEGAL_ASSET_FIELD = "legaAssetsStatus"; export const LEGAL_ASSET_ALIGN = "Left";
export const MIS_ASSET = "MIS"; export const MIS_ASSET_WIDTH = 130; export const MIS_ASSET_FIELD = "MISAssetsStatus"; export const MIS_ASSET_ALIGN = "Left";
export const CONTACT_ASSET = "Contact Research"; export const CONTACT_ASSET_WIDTH = 130; export const CONTACT_ASSET_FIELD = "ContactResearchAssetsStatus"; export const CONTACT_ASSET_ALIGN = "Left";

//Employee List 
export const PNL_HEAD = "P&L Head"; export const PNL_HEAD_WIDTH = 100; export const PNL_HEAD_FIELD = "PnlHead"; export const PNL_HEAD_FIELD_ORGCHRT = "pnlFunctionHead"; export const PNL_HEAD_ALIGN = "Left";
export const SUB_PNL_HEAD = "Sub P&L Head"; export const SUB_PNL_HEAD_WIDTH = 100; export const SUB_PNL_HEAD_FIELD = "SubPnlHead"; export const SUB_PNL_HEAD_ALIGN = "Left";
export const EMPLOYEE_LIST_EMPLOYEE_NAME = "Team Member"; export const EMPLOYEE_NAME_LIST_WIDTH = 350; export const EMPLOYEE_NAME_LIST_FIELD = "EmployeeName"; export const EMPLOYEE_NAME_LIST_ALIGN = "Left";
export const EMPLOYEE_LIST_STATUS = "Employee Status"; export const EMPLOYEE_LIST_STATUS_WIDTH = 105; export const EMPLOYEE_LIST_STATUS_FIELD = "EmployeeStatus"; export const EMPLOYEE_LIST_STATUS_ALIGN = "Center";
export const EMPLOYEE_LIST_ACTION = "Action"; export const EMPLOYEE_LIST_ACTION_WIDTH = 100; export const EMPLOYEE_LIST_MASTERS_ACTION_WIDTH = 70; export const EMPLOYEE_LIST_ACTION_FIELD = "action"; export const EMPLOYEE_LIST_ACTION_ALIGN = "Left";
export const LEFT_EMPLOYEE_LIST_ACTION = "Action"; export const LEFT_EMPLOYEE_LIST_ACTION_WIDTH = 100; export const LEFT_EMPLOYEE_LIST_ACTION_FIELD = "action"; export const LEFT_EMPLOYEE_LIST_ACTION_ALIGN = "Left";
export const EMPLOYEE_EMAIL = "Email "; export const EMPLOYEE_EMAIL_WIDTH = 180; export const EMPLOYEE_EMAIL_FIELD = "EmailAddress"; export const EMPLOYEE_EMAIL_ALIGN = "Center";
export const ROLE = "Title/Role"; export const ROLE_WIDTH = 180; export const ROLE_FIELD = "titleRole"; export const ROLE_ALIGN = "Left";
export const BASECOUNTRY = "Country of Work"; export const BASECOUNTRY_WIDTH = 150; export const BASECOUNTRY_FIELD = "BaseCountry"; export const BASECOUNTRY_ALIGN = "Left";
export const EMPLOYEE_CODE = "Team Member Id"; export const EMPLOYEE_CODE_WIDTH = 160; export const EMPLOYEE_CODE_FIELD = "empCode"; export const EMPLOYEE_CODE_ALIGN = "Center";
export const REMARK = "Manager's Remarks"; export const EMP_REMARK = "Employee's Remarks"; export const REMARK_WIDTH = 120; export const REMARK_FIELD = "remarks"; export const REMARK_ALIGN = "Left";
// upcoming Employee List
export const UPCOMING_EMPLOYEE_LIST_ACTION = "Action"; export const UPCOMING_EMPLOYEE_LIST_ACTION_WIDTH = ''; export const UPCOMING_EMPLOYEE_LIST_MASTERS_ACTION_WIDTH = ''; export const UPCOMING_EMPLOYEE_LIST_ACTION_FIELD = ""; export const UPCOMING_EMPLOYEE_LIST_ACTION_ALIGN = "Left";
// Orgchart grid list.
export const EMPLOYEE_ADDRESS1 = "Address Line 1"; export const EMPLOYEE_ADDRESS1_WIDTH = 150; export const EMPLOYEE_ADDRESS1_FIELD = "address1"; export const EMPLOYEE_ADDRESS1_ALIGN = "Left";
export const EMPLOYEE_ADDRESS2 = "Address Line 2"; export const EMPLOYEE_ADDRESS2_WIDTH = 150; export const EMPLOYEE_ADDRESS2_FIELD = "address2"; export const EMPLOYEE_ADDRESS2_ALIGN = "Left";
export const EMPLOYEE_ADDRESS3 = "Address Line 3"; export const EMPLOYEE_ADDRESS3_WIDTH = 150; export const EMPLOYEE_ADDRESS3_FIELD = "address3"; export const EMPLOYEE_ADDRESS3_ALIGN = "Left";
export const EMPLOYEE_ADDRESS4 = "Address Line 4"; export const EMPLOYEE_ADDRESS4_WIDTH = 150; export const EMPLOYEE_ADDRESS4_FIELD = "address4"; export const EMPLOYEE_ADDRESS4_ALIGN = "Left";
export const PIN_ZIP_CODE = "Pin/Zip Code"; export const PIN_ZIP_CODE_WIDTH = 105; export const PIN_ZIP_CODE_FIELD = "pinCode"; export const PIN_ZIP_CODE_ALIGN = "Left";
export const CITY = "City"; export const CITY_WIDTH = 105; export const CITY_FIELD = "city"; export const CITY_ALIGN = "Left";
export const DISTRICT = "District"; export const DISTRICT_WIDTH = 105; export const DISTRICT_FIELD = "district"; export const DISTRICT_ALIGN = "Left";
export const STATE_COUNTY = "State/County"; export const STATE_COUNTY_WIDTH = 125; export const STATE_COUNTY_FIELD = "stateCounty"; export const STATE_COUNTY_ALIGN = "Left";
export const PERSONAL_EMAIL_ID = "Personal Email Id"; export const PERSONAL_EMAIL_ID_WIDTH = 105; export const PERSONAL_EMAIL_ID_FIELD = "personalEmailId"; export const PERSONAL_EMAIL_ID_ALIGN = "Left";
export const LEAVE_DATE = "Leave Date"; export const LEAVE_DATE_WIDTH = 105; export const LEAVE_DATE_FIELD = "leaveDate"; export const LEAVE_DATE_ALIGN = "center";
export const LAYER_FIELD_MASTER = "layerName";

// Common Doc Grid View
export const COMMON_DOC_ICON = ""; export const COMMON_DOC_ICON_WIDTH = 26; export const COMMON_DOC_ICON_FIELD = "attachedFile"; export const COMMON_DOC_ICON_ALIGN = "Center";
export const COMMON_DOC_FILE_NAME = "Name"; export const COMMON_DOC_FILE_NAME_WIDTH = 650; export const COMMON_DOC_FILE_NAME_FIELD = "attachedFile"; export const COMMON_DOC_FILE_NAME_ALIGN = "Left";
export const COMMON_DOC_UPLOADED_DATE = "Uploaded Date"; export const COMMON_DOC_UPLOADED_DATE_WIDTH = 100; export const COMMON_DOC_UPLOADED_DATE_FIELD = "timeStamp"; export const COMMON_DOC_UPLOADED_DATE_ALIGN = "Left";
export const COMMON_DOC_ACTION = "Action"; export const COMMON_DOC_ACTION_WIDTH = 32; export const COMMON_DOC_ACTION_FIELD = "attachedFile"; export const COMMON_DOC_ACTION_ALIGN = "Left";

/** Team Member Ranking */
export const TEAM_MEMBER_RANKING_TEAM_MEMBER_NAME = "Team Member"; export const TEAM_MEMBER_RANKING_TEAM_MEMBER_WIDTH = 330; export const TEAM_MEMBER_RANKING_TEAM_MEMBER_FIELD = "EmployeeName"; export const TEAM_MEMBER_RANKING_TEAM_MEMBER_ALIGN = "Left";
export const TEAM_MEMBER_RANKING_PERFORMANCE_X_AXIS = "Performance X Axis"; export const TEAM_MEMBER_RANKING_PERFORMANCE_X_AXIS_WIDTH = 331; export const TEAM_MEMBER_RANKING_PERFORMANCE_X_AXIS_FIELD = "xAxis"; export const TEAM_MEMBER_RANKING_PERFORMANCE_X_AXIS_ALIGN = "Center";
export const TEAM_MEMBER_RANKING_POTENTIAL_Y_AXIS = "Potential Y Axis"; export const TEAM_MEMBER_RANKING_POTENTIAL_Y_AXIS_WIDTH = 331; export const TEAM_MEMBER_RANKING_POTENTIAL_Y_AXIS_FIELD = "yAxis"; export const TEAM_MEMBER_RANKING_POTENTIAL_Y_AXIS_ALIGN = "Center";
export const TEAM_MEMBER_RANKING_9_BOX_CLASSIFICATION = "9 Box Classification"; export const TEAM_MEMBER_RANKING_9_BOX_CLASSIFICATION_WIDTH = 318; export const TEAM_MEMBER_RANKING_9_BOX_CLASSIFICATION_FIELD = "boxClassification"; export const TEAM_MEMBER_RANKING_9_BOX_CLASSIFICATION_ALIGN = "left";
export const CUMULATIVE_SCORE = "Cumulative Score"; export const CUMULATIVE_SCORE_WIDTH = 180; export const CUMULATIVE_SCORE_FIELD = "cumulativeScore"; export const CUMULATIVE_SCORE_ALIGN = "Center";


/** Compliance Report */
export const COMPLIANCE_REPORT_TEAM_MEMBER_NAME = "Team Member"; export const COMPLIANCE_REPORT_TEAM_MEMBER_WIDTH = 120; export const COMPLIANCE_REPORT_TEAM_MEMBER_FIELD = "employeeName"; export const COMPLIANCE_REPORT_TEAM_MEMBER_ALIGN = "Center";
export const COMPLIANCE_REPORT_TEAM_MEMBER_CODE = "Team Member Id"; export const COMPLIANCE_REPORT_TEAM_MEMBER_CODE_WIDTH = 80; export const COMPLIANCE_REPORT_TEAM_MEMBER_CODE_FIELD = "empCode"; export const COMPLIANCE_REPORT_TEAM_MEMBER_CODE_ALIGN = "Center";
export const COMPLIANCE_REPORT_DEPT = "Dept"; export const COMPLIANCE_REPORT_DEPT_WIDTH = 120; export const COMPLIANCE_REPORT_DEPT_FIELD = "dept"; export const COMPLIANCE_REPORT_DEPT_ALIGN = "Center";
export const COMPLIANCE_REPORT_REPORTING_MANAGER = "Reporting Manager"; export const COMPLIANCE_REPORT_REPORTING_MANAGER_WIDTH = 150; export const COMPLIANCE_REPORT_REPORTING_MANAGER_FIELD = "reportingManager"; export const COMPLIANCE_REPORT_REPORTING_MANAGER_ALIGN = "Center";
export const COMPLIANCE_REPORT_DESIGNATION = "Designation"; export const COMPLIANCE_REPORT_DESIGNATION_WIDTH = 50; export const COMPLIANCE_REPORT_DESIGNATION_FIELD = "designation"; export const COMPLIANCE_REPORT_DESIGNATION_ALIGN = "Center";
export const COMPLIANCE_REPORT_SELF = "Self"; export const COMPLIANCE_REPORT_SELF_WIDTH = 100; export const COMPLIANCE_REPORT_SELF_FIELD = "selfSubmitted"; export const COMPLIANCE_REPORT_SELF_ALIGN = "Center";
export const COMPLIANCE_REPORT_SUPERVISOR = "Supervisor"; export const COMPLIANCE_REPORT_SUPERVISOR_WIDTH = 100; export const COMPLIANCE_REPORT_SUPERVISOR_FIELD = "supSubmitted"; export const COMPLIANCE_REPORT_SUPERVISOR_ALIGN = "Center";
export const COMPLIANCE_REPORT_REVIEW = "Review"; export const COMPLIANCE_REPORT_REVIEW_WIDTH = 100; export const COMPLIANCE_REPORT_REVIEW_FIELD = "reviewSubmitted"; export const COMPLIANCE_REPORT_REVIEW_ALIGN = "Center";

/** Edited by Nookesh  */
export const SUB_LAYER_FIELD_MASTER = "subLayerName"; export const ROLE_MASTER = "Role/Title"; export const ROLE_MASTER_WIDTH = 180; export const ROLE_MASTER_FIELD = "Role"; export const ROLE_MASTER_ALIGN = "Left";
export const BUSINESS_UNIT_FUNCTION_MAPPING_FIELD = "businessFunctionUnitName"; export const SUB_BUSINESS_UNIT_FUNCTION_MAPPING_FIELD = "subBusinessFunctionUnitName";
export const PRACTICE_AREA_UNIT_FUNCTION_MAPPING_FIELD = "practiceAreaName"; export const LAYER_NAME_UNIT_FUNCTION_MAPPING_FIELD = "layerName";
export const ABBREVATION_FIELD_MASTER = "abrivation"; export const PRACTICE_AREA_SALES_UNIT_SUB_FUNCTION_FIELD_MASTER = "practiceArea";
export const MAPPINGBUSINESS_UNIT_FUNCTION_WIDTH_NEW = 160; export const MAPPINGSUBBUSINESS_UNIT_FUNCTION_WIDTH_NEW = 160;
export const MAPPINGPRACTICE_AREA_SALES_UNIT_SUB_FUNCTION_WIDTH_NEW = 250; export const MAPPINGROLE_MASTER_WIDTH_NEW = 160; export const MAPPINGHIERARCHY_WIDTH_NEW = 100;
export const MAPPINGLAYER_WIDTH_NEW = 200; export const MAPPINGSUB_LAYER_WIDTH_NEW = 340; export const MAPPINGACTION_WIDTH_NEW = 140; export const MAPPINGEMPLOYEE_COUNT_WIDTH_NEW = 100;
export const BUSINESS_UNIT_FUNCTION_WIDTH_NEW = 110; export const SUBBUSINESS_UNIT_FUNCTION_WIDTH_NEW = 110; export const BUSINESS_UNIT_FUNCTION_WIDTH_NEW_PRACTICE = 125;
export const PRACTICE_AREA_SALES_UNIT_SUB_FUNCTION_WIDTH_NEW = 150; export const ROLE_MASTER_WIDTH_NEW = 150; export const HIERARCHY_WIDTH_NEW = 85;
export const LAYER_WIDTH_NEW = 122; export const SUB_LAYER_WIDTH_NEW = 125; export const ACTION_WIDTH_NEW = 120; export const DOMAIN_WIDTH_NEW = 80; export const EMPLOYEE_COUNT_WIDTH_NEW = 90;export const EMPLOYEE_COUNT_WIDTH_NEW_SUB = 70;export const EMPLOYEE_COUNT_WIDTH_NEW_PRACTICE = 125
export const COMPANY_NAME_WIDTH_NEW = 120; export const ABBREVATION_WIDTH_NEW = 70; export const PRACTICE_AREA_HIERARCHY_WIDTH_NEW = 80; export const PRACTICE_AREA_TEAM_WIDTH_NEW = 100; 
export const PRACTICE_AREA_ACTION_WIDTH_NEW = 140;

export const FUNCTION = "Function"; export const FUNCTION_WIDTH = 100; export const FUNCTION_FIELD = "Function"; export const FUNCTION_ALIGN = "left";
export const MARKET_FACING_TITLE = "Market Facing Title"; export const MARKET_FACING_TITLE_WIDTH = 110; export const MARKET_FACING_TITLE_FIELD = "MarketFacingTitle"; export const MARKET_FACING_TITLE_ALIGN = "left";
export const WORK_PROFILE = "Work Profile"; export const WORK_PROFILE_WIDTH = 150; export const WORK_PROFILE_FIELD = "workProfile"; export const WORK_PROFILE_ALIGN = "left";
export const BASE_LOCATION1 = "Office Location"; export const BASE_LOCATION_WIDTH1 = 150; export const BASE_LOCATION_FIELD1 = "baseLocation"; export const BASE_LOCATION_ALIGN1 = "left";
export const GROUP_HEAD = "Group Head"; export const GROUP_HEAD_WIDTH = 90; export const GROUP_HEAD_FIELD = "BusssPnl"; export const GROUP_HEAD_ALIGN = "left";
export const BUSINESS_GROUP_FUNCTION = "Business/Group Function"; export const BUSINESS_GROUP_FUNCTION_WIDTH = 170; export const BUSINESS_GROUP_FUNCTION_FIELD = "bussGroupFuncName"; export const BUSINESS_GROUP_FUNCTION_ALIGN = "left";
export const BUSINESS_GROUP_HEAD = "Business/Group Function Head"; export const BUSINESS_GROUP_HEAD_WIDTH = 170; export const BUSINESS_GROUP_HEAD_FIELD = "busssGroupFuncHeadName"; export const BUSINESS_GROUP_HEAD_ALIGN = "left";
export const BUSINESS_UNIT_HEAD = "Business Unit Head/Functional head"; export const BUSINESS_FUNCTION = "Business Function"; export const BUSINESS_UNIT_HEAD_WIDTH = 170; export const BUSINESS_UNIT_HEAD_FIELD = "busssUnitFuncHeadName"; export const BUSINESS_UNIT_HEAD_ALIGN = "left";
export const BUSINESS_UNIT_HEAD_ROLE_STATUS = "Business Unit Head/Functional head Role Status"; export const BUSINESS_UNIT_HEAD_ROLE_STATUS_WIDTH = 250; export const BUSINESS_UNIT_HEAD_ROLE_STATUS_FIELD = "BussHdRoleAS"; export const BUSINESS_UNIT_HEAD_ROLE_STATUS_ALIGN = "left";
export const OPS_PNL_BU_CENTRAL = "Ops P&L/Sales P&L/Central P&L/Support Function/Bu Central"; export const OPS_PNL_BU_CENTRAL_WIDTH = 250; export const OPS_PNL_BU_CENTRAL_FIELD = "opsSalesCentralName"; export const OPS_PNL_BU_CENTRAL_ALIGN = "left";
export const OPS_SALE_CENTRAL_PNL_HEAD = "Ops P&L/Sales P&L/Central P&L Head"; export const OPS_SALE_CENTRAL_PNL_HEAD_WIDTH = 190; export const OPS_SALE_CENTRAL_PNL_HEAD_FIELD = "opsSalesCentralHeadName"; export const OPS_SALE_CENTRAL_PNL_HEAD_ALIGN = "left";
export const OPS_SALE_CENTRAL_PNL_HEAD_ROLE_STATUS = "Ops P&L/Sales P&L/Central P&L Head Role Status"; export const OPS_SALE_CENTRAL_PNL_HEAD_ROLE_STATUS_WIDTH = 250; export const OPS_SALE_CENTRAL_PNL_HEAD_ROLE_STATUS_FIELD = "BussSubHdRoleAS"; export const OPS_SALE_CENTRAL_PNL_HEAD_ROLE_STATUS_ALIGN = "left";
export const OPS_SUB_SALE_PNL_FUNCTION = "Ops Sub P&L/Sales Sub P&L/Sub Function"; export const OPS_SUB_SALE_PNL_FUNCTION_WIDTH = 200; export const OPS_SUB_SALE_PNL_FUNCTION_FIELD = "subPnlFuncName"; export const OPS_SUB_SALE_PNL_FUNCTION_ALIGN = "left";
export const OPS_SUB_SALE_PNL_FUNCTION_HEAD = "Ops Sub P&L/Sales Sub P&L/Sub Function Head"; export const OPS_SUB_SALE_PNL_FUNCTION_HEAD_WIDTH = 200; export const OPS_SUB_SALE_PNL_FUNCTION_HEAD_FIELD = "subPnlFunctHeadName"; export const OPS_SUB_SALE_PNL_FUNCTION_HEAD_ALIGN = "left";
export const OPS_SUB_SALE_PNL_FUNCTION_HEAD_ROLE_STATUS = "Ops Sub P&L/Sales Sub P&L/Sub Function Head Role Status"; export const OPS_SUB_SALE_PNL_FUNCTION_HEAD_ROLE_STATUS_WIDTH = 260; export const OPS_SUB_SALE_PNL_FUNCTION_HEAD_ROLE_STATUS_FIELD = "SubPnlHdRoleAS"; export const OPS_SUB_SALE_PNL_FUNCTION_HEAD_ROLE_STATUS_ALIGN = "left";
export const SERVICE_DIVISION_PRACTICE_SALES_UNIT = "Practice Area / Sales Unit / Sub Function"; export const SERVICE_DIVISION_PRACTICE_SALES_UNIT_WIDTH = 210; export const SERVICE_DIVISION_PRACTICE_SALES_UNIT_FIELD = "sDPASUName"; export const SERVICE_DIVISION_PRACTICE_SALES_UNIT_ALIGN = "left";
export const SERVICE_DIVISION_PRACTICE_SALES_UNIT_HEAD = "Practice Area / Sales Unit / Sub Function - Head"; export const SERVICE_DIVISION_PRACTICE_SALES_UNIT_HEAD_WIDTH = 210; export const SERVICE_DIVISION_PRACTICE_SALES_UNIT_HEAD_FIELD = "sDPASUHeadName"; export const SERVICE_DIVISION_PRACTICE_SALES_UNIT_HEAD_ALIGN = "left";
export const ACTING_ROLE = "Acting Role"; export const ACTING_ROLE_WIDTH = 120; export const ACTING_ROLE_FIELD = "SDHdRoleAS"; export const ACTING_ROLE_ALIGN = "left";
export const GROUP_SALE_MANAGER1 = "Group Manager/Sales Manager/Manager 1"; export const GROUP_SALE_MANAGER1_WIDTH = 200; export const GROUP_SALE_MANAGER1_FIELD = "gMSMName"; export const GROUP_SALE_MANAGER1_ALIGN = "left";
export const GROUP_SALE_MANAGER_NAME = "Group Manager/Sales Manager Name"; export const GROUP_SALE_MANAGER_NAME_WIDTH = 180; export const GROUP_SALE_MANAGER_NAME_FIELD = "gMSMUserName"; export const GROUP_SALE_MANAGER_NAME_ALIGN = "left";
export const PROJECT_MANAGER_SALES_MGR2 = "Project Manager/Sales Person/Manager 2"; export const PROJECT_MANAGER_SALES_MGR2_WIDTH = 200; export const PROJECT_MANAGER_SALES_MGR2_FIELD = "pMSPName"; export const PROJECT_MANAGER_SALES_MGR2_ALIGN = "left";
export const PROJECT_MANAGER_SALES_PERSON_NAME = "Project Manager/Sales Person Name"; export const PROJECT_MANAGER_SALES_PERSON_NAME_WIDTH = 200; export const PROJECT_MANAGER_SALES_PERSON_NAME_FIELD = "pMSPUserName"; export const PROJECT_MANAGER_SALES_PERSON_NAME_ALIGN = "left";
export const COST_SHARING = "Cost Sharing %"; export const COST_SHARING_WIDTH = 100; export const COST_SHARING_FIELD = "costSharing"; export const COST_SHARING_ALIGN = "center";
export const INDIRECT_REPORTING = "Indirect Reporting (Yes/No)"; export const INDIRECT_REPORTING_WIDTH = 155; export const INDIRECT_REPORTING_FIELD = "indirectReporting"; export const INDIRECT_REPORTING_ALIGN = "center";
export const COST_SHARE_PRIMARY_REPORTING = "Cost Share of Primary Reporting"; export const COST_SHARE_PRIMARY_REPORTING_WIDTH = 140; export const COST_SHARE_PRIMARY_REPORTING_FIELD = "costSharingPrimaryReporting"; export const COST_SHARE_PRIMARY_REPORTING_ALIGN = "center";
export const LEVEL8_TEAM_MEMBER_NAME = "Level 8 Team Member Name"; export const LEVEL8_TEAM_MEMBER_NAME_WIDTH = 140; export const LEVEL8_TEAM_MEMBER_NAME_FIELD = "pICUserName"; export const LEVEL8_TEAM_MEMBER_NAME_ALIGN = "left";
export const LEVEL9_TEAM_MEMBER_NAME = "Level 9 Team Member Name"; export const LEVEL9_TEAM_MEMBER_NAME_WIDTH = 150; export const LEVEL9_TEAM_MEMBER_NAME_FIELD = "level9UserName"; export const LEVEL9_TEAM_MEMBER_NAME_ALIGN = "left";
export const LEVEL_1 = "Level 1"; export const LEVEL_1_WIDTH = 140; export const LEVEL_1_FIELD = "level1"; export const LEVEL_1_ALIGN = "left";
export const LEVEL_2 = "Level 2"; export const LEVEL_2_WIDTH = 140; export const LEVEL_2_FIELD = "level2"; export const LEVEL_2_ALIGN = "left";
export const LEVEL_3 = "Level 3"; export const LEVEL_3_WIDTH = 140; export const LEVEL_3_FIELD = "level3"; export const LEVEL_3_ALIGN = "left";
export const LEVEL_4 = "Level 4"; export const LEVEL_4_WIDTH = 140; export const LEVEL_4_FIELD = "level4"; export const LEVEL_4_ALIGN = "left";
export const LEVEL_5 = "Level 5"; export const LEVEL_5_WIDTH = 140; export const LEVEL_5_FIELD = "level5"; export const LEVEL_5_ALIGN = "left";
export const LEVEL_6 = "Level 6"; export const LEVEL_6_WIDTH = 140; export const LEVEL_6_FIELD = "level6"; export const LEVEL_6_ALIGN = "left";
export const LEVEL_7 = "Level 7"; export const LEVEL_7_WIDTH = 140; export const LEVEL_7_FIELD = "level7"; export const LEVEL_7_ALIGN = "left";
export const LEVEL_8 = "Level 8"; export const LEVEL_8_WIDTH = 140; export const LEVEL_8_FIELD = "level8"; export const LEVEL_8_ALIGN = "left";
export const LEVEL_9 = "Level 9"; export const LEVEL_9_WIDTH = 100; export const LEVEL_9_FIELD = "level9"; export const LEVEL_9_ALIGN = "left";
export const ACTING_ROLE6 = "Acting Role"; export const ACTING_ROLE6_WIDTH = 120; export const ACTING_ROLE6_FIELD = "GM_PA_SU_RoleAS"; export const ACTING_ROLE6_ALIGN = "left";
export const ACTING_ROLE7 = "Acting Role"; export const ACTING_ROLE7_WIDTH = 120; export const ACTING_ROLE7_FIELD = "PM_SP_RoleAS"; export const ACTING_ROLE7_ALIGN = "left";
export const ACTING_ROLE8 = "Acting Role"; export const ACTING_ROLE8_WIDTH = 120; export const ACTING_ROLE8_FIELD = "PIC_RoleAS"; export const ACTING_ROLE8_ALIGN = "left";
export const ACTING_ROLE9 = "Acting Role"; export const ACTING_ROLE9_WIDTH = 120; export const ACTING_ROLE9_FIELD = "level9UserRoleAS"; export const ACTING_ROLE9_ALIGN = "left";
export const LEVEL_1_NAME = "Level 1 Name"; export const LEVEL_1_NAME_WIDTH = 130; export const LEVEL_1_NAME_FIELD = "groupName"; export const LEVEL_1_NAME_ALIGN = "left";
export const LEVEL_1_TEAM_MEMBER_NAME = "Level 1 Team Member Name"; export const LEVEL_1_TEAM_MEMBER_NAME_WIDTH = 130; export const LEVEL_1_TEAM_MEMBER_NAME_FIELD = "groupHead"; export const LEVEL_1_TEAM_MEMBER_NAME_ALIGN = "left";
export const LEVEL_1_ROLE_STATUS = "Level 1 Role Status"; export const LEVEL_1_ROLE_STATUS_WIDTH = 130; export const LEVEL_1_ROLE_STATUS_FIELD = "groupHeadRoleAs"; export const LEVEL_1_ROLE_STATUS_ALIGN = "left";
export const UNIQUE_CODE = "Unique Code"; export const UNIQUE_CODE_WIDTH = 90; export const UNIQUE_CODE_FIELD = "uniqueCode"; export const UNIQUE_CODE_ALIGN = "left";
export const DESIGNATION = "Designation"; export const DESIGNATION_WIDTH = 180; export const DESIGNATION_FIELD = "Role"; export const DESIGNATION_ALIGN = "left";
export const BUSINESS_UNIT_FUNCTION = "BU / Group Function "; export const BUSINESS_UNIT_FUNCTION_WIDTH = 150; export const BUSINESS_UNIT_FUNCTION_FIELD = "BusinessUnit"; export const BUSINESS_UNIT_FUNCTION_ALIGN = "left";
export const LEGAL_NAME = "Legal Name"; export const LEGAL_NAME_WIDTH = 220; export const LEGAL_NAME_FIELD = "LegalName"; export const LEGAL_NAME_ALIGN = "Left";
export const FUNCTIONAL_REPORTING_SUPERVISOR = "Functional Supervisor"; export const FUNCTIONAL_REPORTING_SUPERVISOR_WIDTH = 200; export const FUNCTIONAL_REPORTING_SUPERVISOR_FIELD = "FunctionalReportingSupervisorName"; export const FUNCTIONAL_REPORTING_SUPERVISOR_ALIGN = "left";
export const ADMINISTRATIVE_REPORTING_SUPERVISOR = "Reporting Supervisor"; export const ADMINISTRATIVE_REPORTING_SUPERVISOR_WIDTH = 220; export const ADMINISTRATIVE_REPORTING_SUPERVISOR_FIELD = "AdministrativeReportingSupervisorName"; export const ADMINISTRATIVE_REPORTING_SUPERVISOR_ALIGN = "left";
export const OPS_SUB_PNL_SALES_SUB_PNL_SUB_FUNCTION = "Sub P & L"; export const OPS_SUB_PNL_SALES_SUB_PNL_SUB_FUNCTION_WIDTH = 200; export const OPS_SUB_PNL_SALES_SUB_PNL_SUB_FUNCTION_FIELD = "SubPnlName"; export const OPS_SUB_PNL_SALES_SUB_PNL_SUB_FUNCTION_ALIGN = "left";
export const PRACTICE_AREA_SALES_UNIT_SUB_FUNCTION = "Practice Area / Sales Unit / Sub Function"; export const PRACTICE_AREA_SALES_UNIT_SUB_FUNCTION_WIDTH = 200; export const PRACTICE_AREA_SALES_UNIT_SUB_FUNCTION_FIELD = "PracticeArea"; export const PRACTICE_AREA_SALES_UNIT_SUB_FUNCTION_ALIGN = "left";
export const PRACTICE_AREA_PARENTS = "Parent Practice Area / Sales Unit / Sub Function"; export const PRACTICE_AREA_SALES_UNIT_SUB_FUNCTION_WIDTHS = 175; export const PRACTICE_AREA_PARENTS_FIELD_MASTER = "parentLayerName";
export const LAYER = "Layer"; export const LAYER_WIDTH = 280; export const LAYER_FIELD = "LayerName"; export const LAYER_ALIGN = "left";
export const SUB_LAYER = "Sub Layer"; export const SUB_LAYER_WIDTH = 220; export const SUB_LAYER_FIELD = "SubLayerName"; export const SUB_LAYER_ALIGN = "left";
export const REVISED_ROLE_TITLE = "Role / Title"; export const REVISED_ROLE_TITLE_WIDTH = 220; export const REVISED_ROLE_TITLE_FIELD = "titleRole"; export const REVISED_ROLE_TITLE_ALIGN = "left";
export const PERSONAL_MOBILE_NO = "Personal Mobile No"; export const PERSONAL_MOBILE_NO_WIDTH = 190; export const PERSONAL_MOBILE_NO_FIELD = "PersonalMobileNo"; export const PERSONAL_MOBILE_NO_ALIGN = "left";
export const ALTERNATE_MOBILE_NO = "Alternate Mobile No"; export const ALTERNATE_MOBILE_NO_WIDTH = 190; export const ALTERNATE_MOBILE_NO_FIELD = "AltMobileNo"; export const ALTERNATE_MOBILE_NO_ALIGN = "left";
export const SALUTATION = "Salutation"; export const SALUTATION_WIDTH = 110; export const SALUTATION_FIELD = "SalutationName"; export const SALUTATION_ALIGN = "center";
export const FIRST_NAME = "First Name"; export const FIRST_NAME_WIDTH = 160; export const FIRST_NAME_FIELD = "FirstName"; export const FIRST_NAME_ALIGN = "left";
export const MIDDLE_NAME = "Middle Name"; export const MIDDLE_NAME_WIDTH = 160; export const MIDDLE_NAME_FIELD = "MiddleName"; export const MIDDLE_NAME_ALIGN = "left";
export const LAST_NAME = "Last Name"; export const LAST_NAME_WIDTH = 160; export const LAST_NAME_FIELD = "LastName"; export const LAST_NAME_ALIGN = "left";
export const OFFICE_EMAIL = "Official Email Id"; export const OFFICE_EMAIL_WIDTH = 300; export const OFFICE_EMAIL_FIELD = "EmailAddress"; export const OFFICE_EMAIL_ALIGN = "left";
export const SUB_BUSINESS_UNIT = "Sub Business Unit / Function "; export const SUB_BUSINESS_UNIT_WIDTH = 180; export const SUB_BUSINESS_UNIT_FIELD = "SubPnlName"; export const SUB_BUSINESS_UNIT_ALIGN = "left";
export const GENDER = "Gender"; export const GENDER_WIDTH = 120; export const GENDER_FIELD = "Gender"; export const GENDER_ALIGN = "left";
export const DATE_OF_BIRTH = "Date of Birth"; export const DATE_OF_BIRTH_WIDTH = 100; export const DATE_OF_BIRTH_FIELD = "DateofBirth"; export const DATE_OF_BIRTH_ALIGN = "left";
export const NEW_PERSONAL_EMAIL_ID = "Personal Email ID"; export const NEW_PERSONAL_EMAIL_ID_WIDTH = 280; export const NEW_PERSONAL_EMAIL_ID_FIELD = "PersonalEmail"; export const NEW_PERSONAL_EMAIL_ID_ALIGN = "left";
export const ADDRESS_1 = "Address 1"; export const ADDRESS_1_WIDTH = 380; export const ADDRESS_1_FIELD = "AddressLine1"; export const ADDRESS_1_ALIGN = "left";
export const ADDRESS_2 = "Address 2"; export const ADDRESS_2_WIDTH = 340; export const ADDRESS_2_FIELD = "AddressLine2"; export const ADDRESS_2_ALIGN = "left";
export const PIN_CODE = "Pin / Zip Code"; export const PIN_CODE_WIDTH = 100; export const PIN_CODE_FIELD = "PinCode"; export const PIN_CODE_ALIGN = "left";
export const NEW_CITY = "City"; export const NEW_CITY_WIDTH = 150; export const NEW_CITY_FIELD = "City"; export const NEW_CITY_ALIGN = "left";
export const STATE = "State"; export const STATE_WIDTH = 160; export const STATE_FIELD = "State"; export const STATE_ALIGN = "left";
export const COUNTRY = "Country"; export const COUNTRY_WIDTH = 180; export const COUNTRY_FIELD = "BaseCountry"; export const COUNTRY_ALIGN = "left";
export const ADDRESS_COUNTRY = "Address Country"; export const ADDRESS_COUNTRY_WIDTH = 180; export const ADDRESS_COUNTRY_FIELD = "AddressCountry"; export const ADDRESS_COUNTRY_ALIGN = "left";
export const HOME_OFFICE_ADDRESS = "Home-Office Address"; export const HOME_OFFICE_ADDRESS_WIDTH = 430; export const HOME_OFFICE_ADDRESS_FIELD = "HomeOfficeAddress"; export const HOME_OFFICE_ADDRESS_ALIGN = "left";
export const HIGHEST_QUALIFICATION = "Highest Qualification"; export const HIGHEST_QUALIFICATION_WIDTH = 180; export const HIGHEST_QUALIFICATION_FIELD = "HighestQualification"; export const HIGHEST_QUALIFICATION_ALIGN = "left";
export const SPECIALIZATION = "Specialization"; export const SPECIALIZATION_WIDTH = 220; export const SPECIALIZATION_FIELD = "Specialization"; export const SPECIALIZATION_ALIGN = "left";
export const WORK_EXPERIENCE = "Experience in Months' before Joining"; export const WORK_EXPERIENCE_WIDTH = 190; export const WORK_EXPERIENCE_FIELD = "WorkExperience"; export const WORK_EXPERIENCE_ALIGN = "Center";
export const RECRUITER_NAME = "Recruiter Name"; export const RECRUITER_NAME_WIDTH = 180; export const RECRUITER_NAME_FIELD = "RecruiterManager"; export const RECRUITER_NAME_ALIGN = "left";
export const AADHAR_CARD_NUMBER = "Aadhar Card Number"; export const AADHAR_CARD_NUMBER_WIDTH = 180; export const AADHAR_CARD_NUMBER_FIELD = "AadharCard"; export const AADHAR_CARD_NUMBER_ALIGN = "left";
export const PAN_CARD_NUMBER = "Pan Card Number"; export const PAN_CARD_NUMBER_WIDTH = 160; export const PAN_CARD_NUMBER_FIELD = "PanCard"; export const PAN_CARD_NUMBER_ALIGN = "left";
export const OTHER_IDENTIFICATION = "Other Identification"; export const OTHER_IDENTIFICATION_WIDTH = 320; export const OTHER_IDENTIFICATION_FIELD = "OtherIdentification"; export const OTHER_IDENTIFICATION_ALIGN = "left";
export const NEW_SOURCE_CATEGORY = "Source Category"; export const NEW_SOURCE_CATEGORY_WIDTH = 180; export const NEW_SOURCE_CATEGORY_FIELD = "SourceCategory"; export const NEW_SOURCE_CATEGORY_ALIGN = "left";
export const SOURCE = "Source"; export const SOURCE_WIDTH = 280; export const SOURCE_FIELD = "Source"; export const SOURCE_ALIGN = "left";
export const TENURE_END_DATE = "Tenure End Date"; export const TENURE_END_DATE_WIDTH = 120; export const TENURE_END_DATE_FIELD = "TenureEndDate"; export const TENURE_END_DATE_ALIGN = "left";
export const ACTIVE_INACTIVE = "Status"; export const ACTIVE_INACTIVE_WIDTH = 100; export const ACTIVE_INACTIVE_FIELD = "ActiveorInactive"; export const ACTIVE_INACTIVE_ALIGN = "center";
export const CURRENCY = "Currency"; export const CURRENCY_WIDTH = 120; export const CURRENCY_FIELD = "Currency"; export const CURRENCY_ALIGN = "Center";
export const CTC_FREQUENCY = "CTC Frequency"; export const CTC_FREQUENCY_WIDTH = 120; export const CTC_FREQUENCY_FIELD = "CTCFrequencyName"; export const CTC_FREQUENCY_ALIGN = "left";
export const WINDOW_LOGIN_ID = "Window Login Id"; export const WINDOW_LOGIN_ID_WIDTH = 250; export const WINDOW_LOGIN_ID_FIELD = "WindowLogin"; export const WINDOW_LOGIN_ID_ALIGN = "left";
export const DIGITAL_EMAIL = "Digital Email"; export const DIGITAL_EMAIL_WIDTH = 300; export const DIGITAL_EMAIL_FIELD = "digitalEmail"; export const DIGITAL_EMAIL_ALIGN = "left";
export const TEAMS_EMAIL = "Teams Email"; export const TEAMS_EMAIL_WIDTH = 300; export const TEAMS_EMAIL_FIELD = "teamsEmail"; export const TEAMS_EMAIL_ALIGN = "left";
export const OFFERED_DATE = "Offered Date"; export const OFFERED_DATE_WIDTH = 100; export const OFFERED_DATE_FIELD = "OfferedDate"; export const OFFERED_DATE_ALIGN = "left";
export const OFFER_ACCEPTANCE_DATE = "Offer Accceptance Date"; export const OFFER_ACCEPTANCE_DATE_WIDTH = 170; export const OFFER_ACCEPTANCE_DATE_FIELD = "OfferAcceptanceDate"; export const OFFER_ACCEPTANCE_DATE_ALIGN = "left";
export const EMPLOYEE_LIST_NOTICE_PERIOD = "Notice Period In Months"; export const EMPLOYEE_LIST_NOTICE_PERIOD_WIDTH = 140; export const EMPLOYEE_LIST_NOTICE_PERIOD_FIELD = "NoticePeriod"; export const EMPLOYEE_LIST_NOTICE_PERIOD_ALIGN = "left";
export const FORTY_HOUR_WORK_WEEK = "40 Hour Work Week"; export const FORTY_HOUR_WORK_WEEK_WIDTH = 170; export const FORTY_HOUR_WORK_WEEK_FIELD = "isFortyHourWorkweek"; export const FORTY_HOUR_WORK_WEEK_ALIGN = "center";
export const WORK_DAY_WINDOW_NAME = "Workday Window"; export const WORK_DAY_WINDOW_NAME_WIDTH = 160; export const WORK_DAY_WINDOW_NAME_FIELD = "workdayWindowName"; export const WORK_DAY_WINDOW_NAME_ALIGN = "center";
export const WEEK_DAY_CLUSTER_NAME = "Weekday Window"; export const WEEK_DAY_CLUSTER_NAME_WIDTH = 160; export const WEEK_DAY_CLUSTER_NAME_FIELD = "weekdayClusterName"; export const WEEK_DAY_CLUSTER_NAME_ALIGN = "center";

/** orgchar master data grid level wise data show. */
export const LEAGAL_NAME_ORG_MASTER = "Group Head"; export const LEAGAL_NAME_WIDTH_ORG_MASTER = 300; export const LEAGAL_NAME_FIELD_ORG_MASTER = "EmployeeName"; export const LEAGAL_NAME_ALIGN_ORG_MASTER = "Left";
export const COMPANY_NAME = "Company Name"; export const COMPANY_NAME_WIDTH = 180; export const COMPANY_NAME_FIELD = "companyName"; export const COMPANY_NAME_ALIGN = "Left";
export const GROUP_NAME_ORG_MASTER = "Business Unit/Function"; export const GROUP_NAME_WIDTH_ORG_MASTER = 200; export const GROUP_NAME_FIELD_ORG_MASTER = "groupName"; export const GROUP_NAME_ALIGN_ORG_MASTER = "Left";
export const ACTING_HEAD_ORG_MASTER = "Acting Head"; export const ACTING_HEAD_WIDTH_ORG_MASTER = 120; export const ACTING_HEAD_FIELD_ORG_MASTER1 = "isActingHeadAtLevel1"; export const ACTING_HEAD_FIELD_ORG_MASTER3 = "isActingHeadAtLevel3"; export const ACTING_HEAD_FIELD_ORG_MASTER4 = "isActingHeadAtLevel4"; export const ACTING_HEAD_FIELD_ORG_MASTER5 = "isActingHeadAtLevel5"; export const ACTING_HEAD_FIELD_ORG_MASTER2 = "isActingHeadAtLevel2"; export const ACTING_HEAD_ALIGN_ORG_MASTER = "Left";
export const LEVEL_ORG_MASTER = "Level"; export const LEVEL_WIDTH_ORG_MASTER = 150; export const LEVEL_FIELD_ORG_MASTER1 = "level1";
export const LEVEL3_FIELD_ORG_MASTER = "level3"; export const LEVEL4_FIELD_ORG_MASTER = "level4"; export const LEVEL5_FIELD_ORG_MASTER = "level5"; export const LEVEL_ALIGN_ORG_MASTER = "Left";
export const PnL_FUNCTION_NAME = "P&L / Function"; export const PnL_FUNCTION_NAME_WIDTH = 120; export const PnL_FUNCTION_NAME_FIELD = "pnlFuncName"; export const PnL_FUNCTION_NAME_ALIGN = "Left";
export const ABBRIVATION = "Abbrvation"; export const ABBRIVATION_WIDTH = 100; export const ABBRIVATION_FIELD = "abbreviation"; export const ABBRIVATION_ALIGN = "Left";
export const DPMAIN = "Domail"; export const DPMAIN_WIDTH = 180; export const DPMAIN_FIELD = "domain"; export const DPMAIN_ALIGN = "Left";
export const ACTIVE = "Active"; export const ACTIVE_WIDTH = 80; export const ACTIVE_FIELD1 = "isActiveAtLevel1"; export const ACTIVE_FIELD2 = "isActiveAtLevel2"; export const ACTIVE_FIELD3 = "isActiveAtLevel3"; export const ACTIVE_FIELD4 = "isActiveAtLevel4"; export const ACTIVE_FIELD5 = "isActiveAtLevel5"; export const ACTIVE_ALIGN = "Center";
export const SUB_PNL_FUNCTION_NAME = "Sub P&L/Function"; export const SUB_PNL_FUNCTION_NAME_WIDTH = 140; export const SUB_PNL_FUNC_NAME_FIELD = "subPnlFuncName"; export const SUB_PNL_FUNCTION_NAME_FIELD = "subPnlFunctionName"; export const SUB_PNL_FUNCTION_NAME_ALIGN = "Left";
export const BUSINESS_PNL_HEAD = "Business P&L Head"; export const BUSINESS_PNL_HEAD_WIDTH = 180; export const BUSINESS_PNL_HEAD_FIELD = "businessPnlFuncName"; export const BUSINESS_PNL_HEAD_ALIGN = "Left";
export const SUB_PNL_FUNCTION_HEAD = "Sub P&L Function Head"; export const SUB_PNL_FUNCTION_HEAD_WIDTH = 200; export const FKSUB_PNL_FUNCTION_HEAD_FIELD = "fkSubPnlFunctionHeadName"; export const SUB_PNL_FUNCTION_HEAD_FIELD = "subPnlFuncHeadName"; export const SUB_PNL_FUNCTION_HEAD_ALIGN = "Left";
export const SERVICE_DIVISION_HEAD_NAME = "Practice Area / Sales Unit / Sub Function"; export const SERVICE_DIVISION_HEAD_NAME_WIDTH = 180; export const SERVICE_DIVISION_HEAD_NAME_FIELD = "serviceDivisionHeadName"; export const SERVICE_DIVISION_HEAD_NAME_ALIGN = "Left";
export const SERVICE_DIVISION_NAME = "Practice Area / Sales Unit / Sub Function"; export const SERVICE_DIVISION_NAME_WIDTH = 180; export const SERVICE_DIVISION_NAME_FIELD = "serviceDivisionName"; export const SERVICE_DIVISION_NAME_ALIGN = "Left";
export const BUSINESS_SUB_PNL_FUNC_NAME = "Business Sub P&L Function"; export const BUSINESS_SUB_FUNC_NAME = "Business Sub Function"; export const BUSINESS_SUB_PNL_FUNC_NAME_WIDTH = 200; export const BUSINESS_SUB_PNL_FUNC_NAME_FIELD = "businessSubPnlFuncName"; export const BUSINESS_SUB_PNL_FUNC_NAME_ALIGN = "Left"; export const BUSINESS_SUB_PNL_FUNC_NAME_DASHBOARD_ALIGN = "Center";
export const TEMP = ""; export const TEMP_WIDTH = 1; export const TEMP_FIELD = ""; export const TEMP_ALIGN = "left"; export const SUB_BUSINESS_UNIT_FUNC_NAME_WIDTH = 220; export const BUSINESS_UNIT_FUNCTION_HEAD_WIDTH = 180; export const SUB_BUSINESS_UNIT_DASHBOARD_FUNC_NAME_WIDTH = 120;

//orgchar master functions data grid level wise data show.

export const HIERARCHY = "Hierarchy"; export const HIERARCHY_WIDTH = 45; export const HIERARCHY_FIELD = "hierarchy"; export const HIERARCHY_ALIGN = "center";
export const PARENTLAYER = "Parent Layer"; export const PARENTLAYER_WIDTH = 115; export const PARENTLAYER_FIELD = "parentLayerName"; export const PARENTLAYER_ALIGN = "Left";
export const PARENTLAYER_SBU = "Parent BU / Group Function"; export const PARENTLAYER_WIDTH_NEW = 125; export const PARENTLAYER_FIELD_NEW = "parentLayerName"; export const PARENTLAYER_ALIGN_NEW = "Left";
export const SUB_PARENTLAYER_SUBFUNCTION = "Parent Sub BU / Group Function"; export const SUB_PARENTLAYER_WIDTH_NEW = 100; export const SUB_PARENTLAYER_FIELD_NEW = "parentLayerName"; export const SUB_PARENTLAYER_ALIGN_NEW = "Left";
export const EMPLOYEE_COUNT = "Total Team Members"; export const EMPLOYEE_COUNT_WIDTH = 90; export const EMPLOYEE_COUNT_FIELD = "empCnt"; export const EMPLOYEE_COUNT_ALIGN = "center";
export const DOMAIN = "Domain"; export const DOMAIN_WIDTH = 80; export const DOMAIN_FIELD = "domain"; export const DOMAIN_ALIGN = "Left";
export const ABBREVATION = "Abbrevation"; export const ABBREVATION_WIDTH = 40; export const ABBREVATION_FIELD = "abrivation"; export const ABBREVATION_ALIGN = "center !important";
export const BUSINESS_UNIT_FUNCTION_FIELD_NEW = "businessUnitFunction";
export const SUBBUSINESS = "Sub Business Unit / Function"; export const SUBBUSINESS_WIDTH = 60; export const SUBBUSINESS_FIELD = "subBusinessUnitFunction"; export const SUBBUSINESS_ALIGN = "Left";
export const NOTICE_PERIOD_SUBLAYER = "Notice Period In Months"; export const NOTICE_PERIOD_WIDTH_SUBLAYER = 120; export const NOTICE_PERIOD_FIELD_SUBLAYER = "noticePeriod"; export const NOTICE_PERIOD_ALIGN_SUBLAYER = "Center"


/** OKR grid column  */
export const EMPNAME = "Employee"; export const EMPNAME_WIDTH = 200; export const EMPNAME_FIELD = "employeeNm"; export const EMPNAME_ALIGN = "left";
export const OKRSTATUS = "Status"; export const OKRSTATUS_WIDTH = 120; export const OKRSTATUS_FIELD = "status"; export const OKRSTATUS_ALIGN = "left";
export const SHAREDWITH = "Shared with"; export const SHAREDWITH_WIDTH = 200; export const SHAREDWITH_FIELD = "sharedWith"; export const SHAREDWITH_ALIGN = "left";
export const FUNCTIONNAME = "Function"; export const FUNCTIONNAME_WIDTH = 200; export const FUNCTIONNAME_FIELD = "sharedWith"; export const FUNCTIONNAME_ALIGN = "left";
export const TYPE = "Type"; export const TYPE_WIDTH = 120; export const TYPE_FIELD = "type"; export const TYPE_ALIGN = "left";
export const FROM = "From"; export const FROM_WIDTH = 90; export const FROM_FIELD = "startDate"; export const FROM_ALIGN = "left";
export const TO = "To"; export const TO_WIDTH = 90; export const TO_FIELD = "endDate"; export const TO_ALIGN = "left";
export const SHAREDPER = "Shared %"; export const SHAREDPER_WIDTH = 100; export const SHAREDPER_FIELD = "percSalaryShared"; export const SHAREDPER_ALIGN = "left";
export const SHAREDAMT = "Shared Amount"; export const SHAREDAMT_WIDTH = 100; export const SHAREDAMT_FIELD = "amountShared"; export const SHAREDAMT_ALIGN = "left";
export const DESCRIPTIONOKR = "Description"; export const DESCRIPTIONOKR_WIDTH = 250; export const DESCRIPTIONOKR_FIELD = "description"; export const DESCRIPTIONOKR_ALIGN = "left";
export const REQREASON = "Reason"; export const REQREASON_WIDTH = 250; export const REQREASON_FIELD = "description"; export const REQREASON_ALIGN = "left";
export const OKR = "OKR"; export const OKR_WIDTH = 80; export const OKR_FIELD = "okr"; export const OKR_ALIGN = "left";
export const ACTIONOKR = "Action"; export const ACTIONOKR_WIDTH = 100; export const ACTIONOKR_FIELD = ""; export const ACTIONOKR_ALIGN = "left";
export const ALLOCATION_NAME = ""; export const ALLOCATION_NAME_WIDTH = 150; export const ALLOCATION_NAME_FIELD = "allocationName"; export const ALLOCATION_NAME_ALIGN = "Left";
export const IMPACT_RANGE = "Impact Range"; export const IMPACT_RANGE_WIDTH = 100; export const IMPACT_RANGE_FIELD = "impactRangeStartPerc"; export const IMPACT_RANGE_ALIGN = "Center";
export const IMPACT = "Impact %"; export const IMPACT_WIDTH = 100; export const IMPACT_FIELD = "userImpactPerc"; export const IMPACT_ALIGN = "Center";
export const FREQUENCY = "Frequency"; export const FREQUENCY_WIDTH = 100; export const FREQUENCY_FIELD = "frequency"; export const FREQUENCY_ALIGN = "Center";
export const OTE_VAR_IMPACT = "Variable Impact %"; export const OTE_VAR_IMPACT_WIDTH = 100; export const OTE_VAR_IMPACT_FIELD = "oteVariableImpactPerc"; export const OTE_VAR_IMPACT_ALIGN = "Center";
export const RATING_MECHANISM = "Rating Mechanism"; export const RATING_MECHANISM_WIDTH = 100; export const RATING_MECHANISM_FIELD = "ratingMechanisms"; export const RATING_MECHANISM_ALIGN = "Center";
export const PRODUCT_ALLOCATION_EDIT = "Product Allocation Edit"; export const PRODUCT_ALLOCATION_EDIT_WIDTH = 160; export const PRODUCT_ALLOCATION_EDIT_FIELD = " "; export const PRODUCT_ALLOCATION_EDIT_ALIGN = "Center";

/**  OKR Feedback grid column */
export const DATE = "Date"; export const DATE_WIDTH = 100; export const DATE_FIELD = "date"; export const DATE_ALIGN = "Left";
export const GIVEN_BY_NAME = "Given By Name"; export const GIVEN_BY_NAME_WIDTH = 100; export const GIVEN_BY_NAME_FIELD = "givenByName"; export const GIVEN_BY_NAME_ALIGN = "Left";
export const DEPARTMENT_NAME = "Practice Area / Sales Unit / Sub Function"; export const DEPARTMENT_NAME_WIDTH = isThirteenHundred ? 150 : isOneTwentyFiveScale ? 195 : isFourteenHundred ? 185 : isSixteenHundred ? 200 : 205; export const DEPARTMENT_NAME_FIELD = "department"; export const DEPARTMENT_NAME_ALIGN = "Left";
export const CATEGORY_NAME = "Category"; export const CATEGORY_NAME_WIDTH = 80; export const CATEGORY_NAME_FIELD = "category"; export const CATEGORY_NAME_ALIGN = "Left";
export const DISCRIPTION = "Discription"; export const DISCRIPTION_WIDTH = 200; export const DISCRIPTION_FIELD = "discription"; export const DISCRIPTION_ALIGN = "Left";
export const UPLOAD_DOCUMENT = "Document"; export const UPLOAD_DOCUMENT_WIDTH = 100; export const UPLOAD_DOCUMENT_FIELD = "uploadDocument"; export const UPLOAD_DOCUMENT_ALIGN = "Left";
export const GIVEN_BY = "Given By"; export const GIVEN_BY_WIDTH = 110; export const GIVEN_BY_FIELD = "givenby"; export const GIVEN_BY_ALIGN = "Left";
export const LINK = "Link"; export const LINK_WIDTH = 80; export const LINK_FIELD = "link"; export const LINK_ALIGN = "Left";
export const FEEDBACK = "Feedback"; export const FEEDBACK_WIDTH = 100; export const FEEDBACK_FIELD = "feedback"; export const FEEDBACK_ALIGN = "Left";
export const MEETING_REQUEST = "Meeting Request"; export const MEETING_REQUEST_WIDTH = 125; export const MEETING_REQUEST_FIELD = "meetingRequest"; export const MEETING_REQUEST_ALIGN = "Left";
export const DRAFTACTION = ""; export const DRAFTACTION_WIDTH = 100; export const DRAFTACTION_FIELD = ""; export const DRAFTACTION_ALIGN = "Left";
export const DRAFT_DISCRIPTION = "Discription"; export const DRAFT_DISCRIPTION_WIDTH = 300; export const DRAFT_DISCRIPTION_FIELD = "discription"; export const DRAFT_DISCRIPTION_ALIGN = "Left";
/** Holiday cluster grid column
/**
 *Aurhor: Suresh Patel
 *Date:15/02/2022
 */
export const ADMIN_MODULE_ACTION = 'Action'; export const ADMIN_MODULE_ACTION_NAME_FIELD = 'Actions'; export const ADMIN_MODULE_ACTION_ALIGN = 'Center';
export const HOLIDAY_CLUSTER_HOLIDAY_NAME = 'Holiday Name'; export const HOLIDAY_CLUSTER_HOLIDAY_NAME_FIELD = 'holidayName'; export const HOLIDAY_CLUSTER_HOLIDAY_NAME_ALIGN = 'Left';
export const HOLIDAY_CLUSTER_DATE = 'Date'; export const HOLIDAY_CLUSTER_DATE_FIELD = 'date'; export const HOLIDAY_CLUSTER_DATE_ALIGN = 'Left';
export const HOLIDAY_CLUSTER_DAY = 'Day'; export const HOLIDAY_CLUSTER_DAY_FIELD = 'holidayDayName'; export const HOLIDAY_CLUSTER_DAY_ALIGN = 'Left';
export const HOLIDAY_CLUSTER_COUNTRY = 'Country'; export const HOLIDAY_CLUSTER_COUNTRY_FIELD = 'countryNm'; export const HOLIDAY_CLUSTER_COUNTRY_ALIGN = 'Left';
export const HOLIDAY_CLUSTER_LOCATION_CITY = 'Location/City'; export const HOLIDAY_CLUSTER_LOCATION_CITY_FIELD = 'locationNm'; export const HOLIDAY_CLUSTER_LOCATION_CITY_ALIGN = 'Left';
export const HOLIDAY_CLUSTER_TYPE = 'Holiday Type'; export const HOLIDAY_CLUSTER_TYPE_FIELD = 'holidayType'; export const HOLIDAY_CLUSTER_TYPE_ALIGN = 'Left';
export const RESET_DATE = 'Reset Date'; export const RESET_DATE_FIELD = 'resetDate'; export const RESET_DATE_ALIGN = 'Center';
export const HOLIDAY_CLUSTER_FINANCIAL_YEAR = 'Financial Year'; export const HOLIDAY_CLUSTER_FINANCIAL_YEAR_FIELD = 'financialYear'; export const HOLIDAY_CLUSTER_FINANCIAL_YEAR_ALIGN = 'Left';
export const HOLIDAY_CLUSTER_IS_ACTIVE = 'Status'; export const HOLIDAY_CLUSTER_IS_ACTIVE_FIELD = 'isActive'; export const HOLIDAY_CLUSTER_IS_ACTIVE_ALIGN = 'Center';

/** optional holiday cluster */
export const OPTIONAL_HOLIDAY_CLUSTER_COUNTRY = 'Country'; export const OPTIONAL_HOLIDAY_CLUSTER_COUNTRY_FIELD = 'countryNm'; export const OPTIONAL_HOLIDAY_CLUSTER_COUNTRY_ALIGN = 'Left';
// export const OPTIONAL_HOLIDAY_CLUSTER_LOCATION_CITY = 'Location/City'; export const OPTIONAL_HOLIDAY_CLUSTER_LOCATION_CITY_FIELD = 'locationNm'; export const OPTIONAL_HOLIDAY_CLUSTER_LOCATION_CITY_ALIGN = 'Left';
export const OPTIONAL_HOLIDAY_CLUSTER_NO_OPTIONAL = 'No. Of Optional'; export const OPTIONAL_HOLIDAY_CLUSTER_NO_OPTIONAL_FIELD = 'noOfOptionalCount'; export const OPTIONAL_HOLIDAY_CLUSTER_NO_OPTIONAL_ALIGN = 'Center';
export const OPTIONAL_HOLIDAY_CLUSTER_CHANGE_REQ_DATE = 'Change Request Days'; export const OPTIONAL_HOLIDAY_CLUSTER_CHANGE_REQ_DATE_FIELD = 'changeRequestDays'; export const OPTIONAL_HOLIDAY_CLUSTER_CHANGE_REQ_DATE_ALIGN = 'Center';
export const EFFECTIVE_START_DATE = 'Effective Start Date'; export const EFFECTIVE_START_DATE_FIELD = 'effectiveStartDate'; export const EFFECTIVE_START_DATE_ALIGN = 'Center'; export const EFFECTIVE_START_DATE_WIDTH = "110";
export const EFFECTIVE_END_DATE = 'Effective End Date'; export const EFFECTIVE_END_DATE_FIELD = 'effectiveEndDate'; export const EFFECTIVE_END_DATE_ALIGN = 'Center'; export const EFFECTIVE_END_DATE_WIDTH = "110";
export const OPTIONAL_HOLIDAY_CLUSTER_IS_ACTIVE = 'Status'; export const OPTIONAL_HOLIDAY_CLUSTER_IS_ACTIVE_FIELD = 'isActive'; export const OPTIONAL_HOLIDAY_CLUSTER_IS_ACTIVE_ALIGN = 'Center';


/** Attendance Cluster */
export const ATTENDANCE_CLUSTER_ATTENDANCE_NAME = 'Attendance Name'; export const ATTENDANCE_CLUSTER_ATTENDANCE_NAME_WIDTH = "180"; export const ATTENDANCE_CLUSTER_ATTENDANCE_NAME_FIELD = 'attendanceName'; export const ATTENDANCE_CLUSTER_ATTENDANCE_NAME_ALIGN = 'Left';
export const ATTENDANCE_CLUSTER_ATTENDANCE_DESCRIPTION = 'Description'; export const ATTENDANCE_CLUSTER_ATTENDANCE_DESCRIPTION_WIDTH = "200"; export const ATTENDANCE_CLUSTER_ATTENDANCE_DESCRIPTION_FIELD = 'attendanceDescription'; export const ATTENDANCE_CLUSTER_ATTENDANCE_DESCRIPTION_ALIGN = 'Left';
export const ATTENDANCE_CLUSTER_ATTENDANCE_SYMBOL = 'Symbol'; export const ATTENDANCE_CLUSTER_ATTENDANCE_SYMBOL_WIDTH = "100"; export const ATTENDANCE_CLUSTER_ATTENDANCE_SYMBOL_FIELD = 'Symbol'; export const ATTENDANCE_CLUSTER_ATTENDANCE_SYMBOL_ALIGN = 'Left';
export const ATTENDANCE_CLUSTER_ATTENDANCE_TYPE = 'Type'; export const ATTENDANCE_CLUSTER_ATTENDANCE_TYPE_WIDTH = '120'; export const ATTENDANCE_CLUSTER_ATTENDANCE_TYPE_FIELD = 'tType'; export const ATTENDANCE_CLUSTER_ATTENDANCE_TYPE_ALIGN = 'Center'
export const ATTENDANCE_CLUSTER_IS_ACTIVE = 'Status'; export const ATTENDANCE_CLUSTER_IS_ACTIVE_WIDTH = "80"; export const ATTENDANCE_CLUSTER_IS_ACTIVE_FIELD = 'isActive'; export const ATTENDANCE_CLUSTER_IS_ACTIVE_ALIGN = 'Center';
export const ATTENDANCE_CLUSTER_SPECIAL_ATTENDANCE = 'Special Attendance Allowed'; export const ATTENDANCE_CLUSTER_SPECIAL_ATTENDANCE_WIDTH = "200"; export const ATTENDANCE_CLUSTER_SPECIAL_ATTENDANCE_FIELD = 'specialAttendance'; export const ATTENDANCE_CLUSTER_SPECIAL_ATTENDANCE_ALIGN = 'Center';
export const ATTENDANCE_CLUSTER_ATTENDANCE_ATTACH_NOTE = 'Attach Policy'; export const ATTENDANCE_CLUSTER_ATTENDANCE_ATTACH_NOTE_FIELD = 'fileName'; export const ATTENDANCE_CLUSTER_ATTENDANCE_ATTACH_NOTE_ALIGN = 'Left';

/** Weekday Cluster Name */
export const WEEKDAY_CLUSTER_NAME = 'Weekday Name'; export const WEEKDAY_CLUSTER_NAME_WIDTH = '160'; export const WEEKDAY_CLUSTER_NAME_FIELD = 'weekdayClusterName'; export const WEEKDAY_CLUSTER_NAME_ALIGN = 'Left';
export const WEEKDAY_CLUSTER_DESCRIPTION = 'Description'; export const WEEKDAY_CLUSTER_DESCRIPTION_WIDTH = '200'; export const WEEKDAY_CLUSTER_DESCRIPTION_FIELD = 'weekdayClusterDescription'; export const WEEKDAY_CLUSTER_DESCRIPTION_ALIGN = 'Left';
export const WEEKDAY_CLUSTER_SYMBOL = 'Symbol'; export const WEEKDAY_CLUSTER_SYMBOL_WIDTH = '130'; export const WEEKDAY_CLUSTER_SYMBOL_FIELD = 'Symbol'; export const WEEKDAY_CLUSTER_SYMBOL_ALIGN = 'Left';
export const WEEKDAY_CLUSTER_WEEKEND_TYPE = 'Type'; export const WEEKDAY_CLUSTER_WEEKEND_TYPE_WIDTH = '90'; export const WEEKDAY_CLUSTER_WEEKEND_TYPE_FIELD = 'tType'; export const WEEKDAY_CLUSTER_WEEKEND_TYPE_ALIGN = 'Center';
export const WEEKDAY_CLUSTER_FINANCIAL_YEAR = 'Financial Year'; export const WEEKDAY_CLUSTER_FINANCIAL_YEAR_WIDTH = '100'; export const WEEKDAY_CLUSTER_FINANCIAL_YEAR_FIELD = 'financialYearName'; export const WEEKDAY_CLUSTER_FINANCIAL_YEAR_ALIGN = 'Left';
export const WEEKDAY_CLUSTER_IS_ACTIVE = 'Status'; export const WEEKDAY_CLUSTER_IS_ACTIVE_WIDTH = '90'; export const WEEKDAY_CLUSTER_IS_ACTIVE_FIELD = 'isActive'; export const WEEKDAY_CLUSTER_IS_ACTIVE_ALIGN = 'Center';
export const WEEKDAY_CLUSTER_WEEKDAY_START = 'Weekday Start'; export const WEEKDAY_CLUSTER_WEEKDAY_START_WIDTH = '150'; export const WEEKDAY_CLUSTER_WEEKDAY_START_FIELD = 'weekdayStart'; export const WEEKDAY_CLUSTER_WEEKDAY_START_ALIGN = 'Left';
export const WEEKDAY_CLUSTER_WEEKDAY_END = 'Weekday End'; export const WEEKDAY_CLUSTER_WEEKDAY_END_WIDTH = '150'; export const WEEKDAY_CLUSTER_WEEKDAY_END_FIELD = 'weekdayEnd'; export const WEEKDAY_CLUSTER_WEEKDAY_END_ALIGN = 'Left';
export const WEEKDAY_CLUSTER_WEEKDAY_COUNT = 'Weekday Count'; export const WEEKDAY_CLUSTER_WEEKDAY_COUNT_WIDTH = '150'; export const WEEKDAY_CLUSTER_WEEKDAY_COUNT_FIELD = 'weekdayCount'; export const WEEKDAY_CLUSTER_WEEKDAY_COUNT_ALIGN = 'Center';
export const WEEKDAY_CLUSTER_WEEKEND_COUNT = 'Weekend Count'; export const WEEKDAY_CLUSTER_WEEKEND_COUNT_WIDTH = '150'; export const WEEKDAY_CLUSTER_WEEKEND_COUNT_FIELD = 'weekendCount'; export const WEEKDAY_CLUSTER_WEEKEND_COUNT_ALIGN = 'Center';
export const POLICY_DOC = 'Attach Policy '; export const POLICY_DOC_WIDTH = '160'; export const POLICY_DOC_FIELD = 'fileName'; export const POLICY_DOC_ALIGN = 'Left';
export const DOCUMENTS_REQUIRED = 'Document Required'; export const DOCUMENTS_REQUIRED_WIDTH = '120'; export const DOCUMENTS_REQUIRED_FIELD = 'documentsRequired'; export const DOCUMENTS_REQUIRED_ALIGN = 'Center';
export const GOVERNMENT_STATUTORY = 'Government / Statutory'; export const GOVERNMENT_STATUTORY_WIDTH = '120'; export const GOVERNMENT_STATUTORY_FIELD = 'govtStatutory'; export const GOVERNMENT_STATUTORY_ALIGN = 'Center';
export const LEAVE_BIFURCATION = 'Leave Category'; export const LEAVE_BIFURCATION_WIDTH = '150'; export const LEAVE_BIFURCATION_FIELD = 'leaveBifurcation'; export const LEAVE_BIFURCATION_ALIGN = 'Center';


// Day Break Cluster
export const DAY_BREAK_CLUSTER_WORKDAY_WINDOW_NAME = 'Workday Window Name'; export const DAY_BREAK_CLUSTER_WORKDAY_WINDOW_NAME_WIDTH = '60'; export const DAY_BREAK_CLUSTER_WORKDAY_WINDOW_NAME_FIELD = 'dayBreak'; export const DAY_BREAK_CLUSTER_WORKDAY_WINDOW_NAME_ALIGN = 'Left';
export const DAY_BREAK_CLUSTER_NAME = 'Day Break'; export const DAY_BREAK_CLUSTER_NAME_WIDTH = '110'; export const DAY_BREAK_CLUSTER_NAME_FIELD = 'dayBreak'; export const DAY_BREAK_CLUSTER_NAME_ALIGN = 'Left';
export const DAY_BREAK_CLUSTER_SYMBOL = 'Symbol'; export const DAY_BREAK_CLUSTER_SYMBOL_WIDTH = '50'; export const DAY_BREAK_CLUSTER_SYMBOL_FIELD = 'symbol'; export const DAY_BREAK_CLUSTER_SYMBOL_ALIGN = 'Left';
export const DAY_BREAK_CLUSTER_DESCRIPTION = 'Description'; export const DAY_BREAK_CLUSTER_DESCRIPTION_WIDTH = '150'; export const DAY_BREAK_CLUSTER_DESCRIPTION_FIELD = 'description'; export const DAY_BREAK_CLUSTER_DESCRIPTION_ALIGN = 'Left';
export const DAY_BREAK_CLUSTER_LEAVES_ALLOWED = 'Leaves Allowed'; export const DAY_BREAK_CLUSTER_LEAVES_ALLOWED_WIDTH = '87'; export const DAY_BREAK_CLUSTER_LEAVES_ALLOWED_FIELD = 'leavesAllowed'; export const DAY_BREAK_CLUSTER_LEAVES_ALLOWED_ALIGN = 'Center';
export const DAY_BREAK_CLUSTER_ATTENDANCE_CORRECTION_ALLOWED = 'Attendance Correction Allowed'; export const DAY_BREAK_CLUSTER_ATTENDANCE_CORRECTION_ALLOWED_WIDTH = '80'; export const DAY_BREAK_CLUSTER_ATTENDANCE_CORRECTION_ALLOWED_FIELD = 'attendanceCorrectionAllowed'; export const DAY_BREAK_CLUSTER_ATTENDANCE_CORRECTION_ALLOWED_ALIGN = 'Center';
export const DAY_BREAK_CLUSTER_BUSINESS_RULE = 'Business Rule'; export const DAY_BREAK_CLUSTER_BUSINESS_RULE_WIDTH = '150'; export const DAY_BREAK_CLUSTER_BUSINESS_RULE_FIELD = 'attendanceCorrectionAllowed'; export const DAY_BREAK_CLUSTER_BUSINESS_RULE_ALIGN = 'Center';
export const DAY_BREAK_CLUSTER_FINANCIAL_YEAR = 'Financial Year'; export const DAY_BREAK_CLUSTER_FINANCIAL_YEAR_WIDTH = '60'; export const DAY_BREAK_CLUSTER_FINANCIAL_YEAR_FIELD = 'financialYearName'; export const DAY_BREAK_CLUSTER_FINANCIAL_YEAR_ALIGN = 'Left';
export const DAY_BREAK_CLUSTER_IS_ACTIVE = 'Status'; export const DAY_BREAK_CLUSTER_IS_ACTIVE_WIDTH = '80'; export const DAY_BREAK_CLUSTER_IS_ACTIVE_FIELD = 'isActive'; export const DAY_BREAK_CLUSTER_IS_ACTIVE_ALIGN = 'Center';

// Workday Window
// export const WORKDAY_WINDOW_ACTION = ''; export const WORKDAY_WINDOW_ACTION_WIDTH = 20; export const WORKDAY_WINDOW_ACTION_FIELD = ''; export const WORKDAY_WINDOW_ACTION_ALIGN = 'Left';
export const WORKDAY_WINDOW_NAME = 'Workday Window Name'; export const WORKDAY_WINDOW_NAME_WIDTH = '200'; export const WORKDAY_WINDOW_NAME_FIELD = 'workdayWindowName'; export const WORKDAY_WINDOW_NAME_ALIGN = 'Left';
export const WORKDAY_WINDOW_SYMBOL = 'Symbol'; export const WORKDAY_WINDOW_SYMBOL_WIDTH = '110'; export const WORKDAY_WINDOW_SYMBOL_FIELD = 'Symbol'; export const WORKDAY_WINDOW_SYMBOL_ALIGN = 'Left';
export const WORKDAY_WINDOW_DESCRIPTION = 'Description'; export const WORKDAY_WINDOW_DESCRIPTION_WIDTH = '200'; export const WORKDAY_WINDOW_DESCRIPTION_FIELD = 'workdayWindowDescription'; export const WORKDAY_WINDOW_DESCRIPTION_ALIGN = 'Left';
export const WORKDAY_WINDOW_START_TIME = 'Start Time'; export const WORKDAY_WINDOW_START_TIME_WIDTH = '130'; export const WORKDAY_WINDOW_START_TIME_FIELD = 'startTime'; export const WORKDAY_WINDOW_START_TIME_ALIGN = 'Center';
export const WORKDAY_WINDOW_END_TIME = 'End Time'; export const WORKDAY_WINDOW_END_TIME_WIDTH = '130'; export const WORKDAY_WINDOW_END_TIME_FIELD = 'endTime'; export const WORKDAY_WINDOW_END_TIME_ALIGN = 'Center';
export const WORKDAY_WINDOW_WORK_HRS = 'Work Hours'; export const WORKDAY_WINDOW_WORK_HRS_WIDTH = '140'; export const WORKDAY_WINDOW_WORK_HRS_FIELD = 'workHrs'; export const WORKDAY_WINDOW_WORK_HRS_ALIGN = 'Left';
export const WORKDAY_WINDOW_MIN_HRS = 'Minimum Hours'; export const WORKDAY_WINDOW_MIN_HRS_WIDTH = '160'; export const WORKDAY_WINDOW_MIN_HRS_FIELD = 'minHrs'; export const WORKDAY_WINDOW_MIN_HRS_ALIGN = 'Left';
export const WORKDAY_WINDOW_WEEK_HRS = 'Weekly Work Hours'; export const WORKDAY_WINDOW_WEEK_HRS_WIDTH = '200'; export const WORKDAY_WINDOW_WEEK_HRS_FIELD = 'weeklyWrkHrs'; export const WORKDAY_WINDOW_WEEK_HRS_ALIGN = 'Left';
export const WORKDAY_WINDOW_GRACE_TIME = 'Grace Time'; export const WORKDAY_WINDOW_GRACE_TIME_WIDTH = '160'; export const WORKDAY_WINDOW_GRACE_TIME_FIELD = 'graceTime'; export const WORKDAY_WINDOW_GRACE_TIME_ALIGN = 'Left';
export const WORKDAY_WINDOW_NUMBER_OF_INSTANCE = 'Number of Instances'; export const WORKDAY_WINDOW_NUMBER_OF_INSTANCE_WIDTH = '200'; export const WORKDAY_WINDOW_NUMBER_OF_INSTANCE_FIELD = 'noOfIinstances'; export const WORKDAY_WINDOW_NUMBER_OF_INSTANCE_ALIGN = 'Left';
export const WORKDAY_WINDOW_FREQUENCY = 'Frequency'; export const WORKDAY_WINDOW_FREQUENCY_WIDTH = '130'; export const WORKDAY_WINDOW_FREQUENCY_FIELD = 'workdayFrequency'; export const WORKDAY_WINDOW_FREQUENCY_ALIGN = 'Left';
export const WORKDAY_WINDOW_FINANCIAL_YEAR = 'Financial Year'; export const WORKDAY_WINDOW_FINANCIAL_YEAR_WIDTH = '130'; export const WORKDAY_WINDOW_FINANCIAL_YEAR_FIELD = 'graceTime'; export const WORKDAY_WINDOW_FINANCIAL_YEAR_ALIGN = 'Left';
export const WORKDAY_WINDOW_IS_ACTIVE = 'Status'; export const WORKDAY_WINDOW_IS_ACTIVE_WIDTH = '90'; export const WORKDAY_WINDOW_IS_ACTIVE_FIELD = 'isActive'; export const WORKDAY_WINDOW_IS_ACTIVE_ALIGN = 'Center';

// Regularization Status
export const REGULARIZATION_STATUS_ACTION_FIELD = 'Action'; export const REGULARIZATION_STATUS_ACTION_ALIGN = 'Left';
export const REGULARIZATION_STATUS_DATE = 'Date'; export const REGULARIZATION_STATUS_DATE_WIDTH = 70;
export const REGULARIZATION_STATUS_DATE_FIELD = 'appliedDated'; export const REGULARIZATION_STATUS_DATE_ALIGN = 'Left';
export const REGULARIZATION_STATUS_MODE = 'Mode'; export const REGULARIZATION_STATUS_MODE_WIDTH = 70;
export const REGULARIZATION_STATUS_MODE_FIELD = 'punchTypeName'; export const REGULARIZATION_STATUS_MODE_ALIGN = 'Center';

export const REGULARIZATION_STATUS_STATUS = 'Status'; export const REGULARIZATION_STATUS_STATUS_WIDTH = 40;
export const REGULARIZATION_STATUS_STATUS_FIELD = 'swipeStatus'; export const REGULARIZATION_STATUS_STATUS_ALIGN = 'Center';

export const REGULARIZATION_STATUS_DAYBREAK = 'Daybreak'; export const REGULARIZATION_STATUS_DAYBREAK_WIDTH = 140;
export const REGULARIZATION_STATUS_DAYBREAK_FIELD = 'dayBreakInstance'; export const REGULARIZATION_STATUS_DAYBREAK_ALIGN = 'Left';



/** Source Cluster */
export const SOURCE_CLUSTER_NAME = 'Source Name'; export const SOURCE_CLUSTER_NAME_WIDTH = '130'; export const SOURCE_CLUSTER_NAME_FIELD = 'sourceName'; export const SOURCE_CLUSTER_NAME_ALIGN = 'Left';
export const SOURCE_CLUSTER_SYMBOL = 'Symbol'; export const SOURCE_CLUSTER_SYMBOL_WIDTH = '90'; export const SOURCE_CLUSTER_SYMBOL_FIELD = 'Symbol'; export const SOURCE_CLUSTER_SYMBOL_ALIGN = 'Left';
export const SOURCE_CLUSTER_DESCRIPTION = 'Description'; export const SOURCE_CLUSTER_DESCRIPTION_WIDTH = '200'; export const SOURCE_CLUSTER_DESCRIPTION_FIELD = 'sourceDescription'; export const SOURCE_CLUSTER_DESCRIPTION_ALIGN = 'Left';
export const SOURCE_CLUSTER_DEVICE_LOCATION = 'Device Location'; export const SOURCE_CLUSTER_DEVICE_LOCATION_WIDTH = '140'; export const SOURCE_CLUSTER_DEVICE_LOCATION_FIELD = 'deviceLocation'; export const SOURCE_CLUSTER_DEVICE_LOCATION_ALIGN = 'Left';
export const SOURCE_CLUSTER_DEVICE_ID = 'Device Id'; export const SOURCE_CLUSTER_DEVICE_ID_WIDTH = '100'; export const SOURCE_CLUSTER_DEVICE_ID_FIELD = 'deviceId'; export const SOURCE_CLUSTER_DEVICE_ID_ALIGN = 'Left';
export const SOURCE_CLUSTER_FINANCIAL_YEAR = 'Financial Year'; export const SOURCE_CLUSTER_FINANCIAL_YEAR_WIDTH = '60'; export const SOURCE_CLUSTER_FINANCIAL_YEAR_FIELD = 'financialYearName'; export const SOURCE_CLUSTER_FINANCIAL_YEAR_ALIGN = 'Left';
export const SOURCE_CLUSTER_IS_ACTIVE = 'Status'; export const SOURCE_CLUSTER_IS_ACTIVE_WIDTH = '100'; export const SOURCE_CLUSTER_IS_ACTIVE_FIELD = 'isActive'; export const SOURCE_CLUSTER_IS_ACTIVE_ALIGN = 'Center';
export const SOURCE_CLUSTER_EFFECTIVE_START_DATE = 'Effective Start Date'; export const SOURCE_CLUSTER_EFFECTIVE_START_DATE_WIDTH = '160'; export const SOURCE_CLUSTER_EFFECTIVE_START_DATE_FIELD = 'effectiveStartDate'; export const SOURCE_CLUSTER_EFFECTIVE_START_DATE_ALIGN = 'Center';
export const SOURCE_CLUSTER_EFFECTIVE_END_DATE = 'Effective End Date'; export const SOURCE_CLUSTER_EFFECTIVE_END_DATE_WIDTH = '160'; export const SOURCE_CLUSTER_EFFECTIVE_END_DATE_FIELD = 'effectiveEndDate'; export const SOURCE_CLUSTER_EFFECTIVE_END_DATE_ALIGN = 'Center';
export const SOURCE_CLUSTER_IT1 = 'IT1'; export const SOURCE_CLUSTER_IT1_WIDTH = '150'; export const SOURCE_CLUSTER_IT1_FIELD = 'it1'; export const SOURCE_CLUSTER_IT1_ALIGN = 'Left';
export const SOURCE_CLUSTER_IT2 = 'IT2'; export const SOURCE_CLUSTER_IT2_WIDTH = '150'; export const SOURCE_CLUSTER_IT2_FIELD = 'it2'; export const SOURCE_CLUSTER_IT2_ALIGN = 'Left';
export const SOURCE_CLUSTER_IT3 = 'IT3'; export const SOURCE_CLUSTER_IT3_WIDTH = '150'; export const SOURCE_CLUSTER_IT3_FIELD = 'it3'; export const SOURCE_CLUSTER_IT3_ALIGN = 'Left';

/** Leave Cluster */
export const LEAVE_CLUSTER_NAME = 'Leave Name'; export const LEAVE_CLUSTER_NAME_WIDTH = 240; export const LEAVE_CLUSTER_NAME_FIELD = 'leaveName'; export const LEAVE_CLUSTER_NAME_ALIGN = 'Left';
export const LEAVE_CLUSTER_SYMBOL = 'Symbol'; export const LEAVE_CLUSTER_SYMBOL_WIDTH = 130; export const LEAVE_CLUSTER_SYMBOL_FIELD = 'leaveSymbol'; export const LEAVE_CLUSTER_SYMBOL_ALIGN = 'Center';
export const LEAVE_CLUSTER_DESCRIPTION = 'Description'; export const LEAVE_CLUSTER_DESCRIPTION_WIDTH = 280; export const LEAVE_CLUSTER_DESCRIPTION_FIELD = 'leaveDescription'; export const LEAVE_CLUSTER_DESCRIPTION_ALIGN = 'Left';
export const LEAVE_CLUSTER_LEAVE_TYPE = 'Leave Type'; export const LEAVE_CLUSTER_LEAVE_TYPE_WIDTH = isThirteenHundred ? 90 : isOneTwentyFiveScale ? 105 : 110; export const LEAVE_CLUSTER_LEAVE_TYPE_FIELD = 'leaveType'; export const LEAVE_CLUSTER_LEAVE_TYPE_ALIGN = 'Center';
export const LEAVE_CLUSTER_START_DATE = 'From Date'; export const LEAVE_CLUSTER_START_DATE_WIDTH = 120; export const LEAVE_CLUSTER_START_DATE_FIELD = 'startDate'; export const LEAVE_CLUSTER_START_DATE_ALIGN = 'Center';
export const LEAVE_CLUSTER_NO_OF_LEAVES = 'No of Leaves'; export const LEAVE_CLUSTER_NO_OF_LEAVES_WIDTH = 130; export const LEAVE_CLUSTER_NO_OF_LEAVES_FIELD = 'noOfLeave'; export const LEAVE_CLUSTER_NO_OF_LEAVES_ALIGN = 'Center';
export const LEAVE_CLUSTER_ADD_FREQUENCY = 'Frequency'; export const LEAVE_CLUSTER_ADD_FREQUENCY_WIDTH = 120; export const LEAVE_CLUSTER_ADD_FREQUENCY_FIELD = 'frequency'; export const LEAVE_CLUSTER_ADD_FREQUENCY_ALIGN = 'Left';
export const LEAVE_CLUSTER_ADD_GENDER = 'Eligibility as per Gender'; export const LEAVE_CLUSTER_ADD_GENDER_WIDTH = 150; export const LEAVE_CLUSTER_ADD_GENDER_FIELD = 'eligibilityAsPerGender'; export const LEAVE_CLUSTER_ADD_GENDER_ALIGN = 'Center';
export const LEAVE_CLUSTER_PRORATE = 'ProRate'; export const LEAVE_CLUSTER_PRORATE_WIDTH = 100; export const LEAVE_CLUSTER_PRORATE_FIELD = 'proRate'; export const LEAVE_CLUSTER_PRORATE_ALIGN = 'Center';
export const LEAVE_CLUSTER_WEEKEND_COUNTED = 'Weekend Counted'; export const LEAVE_CLUSTER_WEEKEND_COUNTED_WIDTH = 100; export const LEAVE_CLUSTER_WEEKEND_COUNTED_FIELD = 'weekendCounted'; export const LEAVE_CLUSTER_WEEKEND_COUNTED_ALIGN = 'Center';
export const LEAVE_CLUSTER_STRETCHED_STAGGERED = 'Stretched / Staggered'; export const LEAVE_CLUSTER_STRETCHED_STAGGERED_WIDTH = 140; export const LEAVE_CLUSTER_STRETCHED_STAGGERED_FIELD = 'strechedOrStaggered'; export const LEAVE_CLUSTER_STRETCHED_STAGGERED_ALIGN = 'Center';
export const LEAVE_CLUSTER_ACCUMULATE_IN_SAME_YEAR = 'Accumulate In Same Year'; export const LEAVE_CLUSTER_ACCUMULATE_IN_SAME_YEAR_WIDTH = 125; export const LEAVE_CLUSTER_ACCUMULATE_IN_SAME_YEAR_FIELD = 'accumulateInSameYear'; export const LEAVE_CLUSTER_ACCUMULATE_IN_SAME_YEAR_ALIGN = 'Center';
export const LEAVE_CLUSTER_FORWARD_TO_NEXT_YEAR = 'Forward To Next Year'; export const LEAVE_CLUSTER_FORWARD_TO_NEXT_YEAR_WIDTH = 175; export const LEAVE_CLUSTER_FORWARD_TO_NEXT_YEAR_FIELD = 'forwardToNextYear'; export const LEAVE_CLUSTER_FORWARD_TO_NEXT_YEAR_ALIGN = 'Center';
export const LEAVE_CLUSTER_MAX_LEAVES_FORWARD = 'Max leaves Forward To Next Year'; export const LEAVE_CLUSTER_MAX_LEAVES_FORWARD_WIDTH = 180; export const LEAVE_CLUSTER_MAX_LEAVES_FORWARD_FIELD = 'maxLeavesForward'; export const LEAVE_CLUSTER_MAX_LEAVES_FORWARD_ALIGN = 'Center';
export const LEAVE_CLUSTER_NO_OF_DAYS_ELIGIBLE_FOR_NEXT_YEAR = 'No Of Days Forward Eligible Next Year'; export const LEAVE_CLUSTER_NO_OF_DAYS_ELIGIBLE_FOR_NEXT_YEAR_WIDTH = 170; export const LEAVE_CLUSTER_NO_OF_DAYS_ELIGIBLE_FOR_NEXT_YEAR_FIELD = 'noOfDaysEligibleForNextYear'; export const LEAVE_CLUSTER_NO_OF_DAYS_ELIGIBLE_FOR_NEXT_YEAR_ALIGN = 'Center';
export const LEAVE_CLUSTER_REIMBURSED = 'Reimbursed'; export const LEAVE_CLUSTER_REIMBURSED_WIDTH = 110; export const LEAVE_CLUSTER_REIMBURSED_FIELD = 'reimbursed'; export const LEAVE_CLUSTER_REIMBURSED_ALIGN = 'Center';
export const LEAVE_CLUSTER_NO_OF_DAYS_BACKDATED_ALLOWED = 'Num Of Days Backdated Allowed'; export const LEAVE_CLUSTER_NO_OF_DAYS_BACKDATED_ALLOWED_WIDTH = 225; export const LEAVE_CLUSTER_NO_OF_DAYS_BACKDATED_ALLOWED_FIELD = 'noOfDaysBackdated'; export const LEAVE_CLUSTER_NO_OF_DAYS_BACKDATED_ALLOWED_ALIGN = 'Center';
export const LEAVE_CLUSTER_NO_OF_DAYS_FORWARD_ALLOWED = 'Num Of Days Forward Allowed'; export const LEAVE_CLUSTER_NO_OF_DAYS_FORWARD_ALLOWED_WIDTH = 210; export const LEAVE_CLUSTER_NO_OF_DAYS_FORWARD_ALLOWED_FIELD = 'noOfDaysForwardAllowed'; export const LEAVE_CLUSTER_NO_OF_DAYS_FORWARD_ALLOWED_ALIGN = 'Center';
export const LEAVE_CLUSTER_SUPERVISOR_APPROVAL_REQUIRED = 'Supervisor Approval Required'; export const LEAVE_CLUSTER_SUPERVISOR_APPROVAL_REQUIRED_WIDTH = 200; export const LEAVE_CLUSTER_SUPERVISOR_APPROVAL_REQUIRED_FIELD = 'reportingSupervisorApprovalStatus'; export const LEAVE_CLUSTER_SUPERVISOR_APPROVAL_REQUIRED_ALIGN = 'Center';
export const LEAVE_CLUSTER_HR_APPROVAL_REQUIRED = 'HR Approval Required'; export const LEAVE_CLUSTER_HR_APPROVAL_REQUIRED_WIDTH = 160; export const LEAVE_CLUSTER_HR_APPROVAL_REQUIRED_FIELD = 'hrApprovalStatus'; export const LEAVE_CLUSTER_HR_APPROVAL_REQUIRED_ALIGN = 'Center';
export const LEAVE_CLUSTER_FINANCIAL_YEAR = 'Financial Year'; export const LEAVE_CLUSTER_FINANCIAL_YEAR_WIDTH = 120; export const LEAVE_CLUSTER_FINANCIAL_YEAR_FIELD = 'financialYearName'; export const LEAVE_CLUSTER_FINANCIAL_YEAR_ALIGN = 'Left';
export const LEAVE_CLUSTER_IS_ACTIVE = 'Status'; export const LEAVE_CLUSTER_IS_ACTIVE_WIDTH = 100; export const LEAVE_CLUSTER_IS_ACTIVE_FIELD = 'isActive'; export const LEAVE_CLUSTER_IS_ACTIVE_ALIGN = 'Center';
export const LEAVE_CLUSTER_TRANSFER_ALLOWED = 'Transfer Allowed'; export const LEAVE_CLUSTER_TRANSFER_ALLOWED_WIDTH = 100; export const LEAVE_CLUSTER_TRANSFER_ALLOWED_FIELD = 'transferAllowed'; export const LEAVE_CLUSTER_TRANSFER_ALLOWED_ALIGN = 'Center';
export const LEAVE_CLUSTER_MIN_LEAVES_TRANSFER = 'Min No Of Leave Transfer'; export const LEAVE_CLUSTER_MIN_LEAVES_TRANSFER_WIDTH = 150; export const LEAVE_CLUSTER_MIN_LEAVES_TRANSFER_FIELD = 'minLeaveTransfer'; export const LEAVE_CLUSTER_MIN_LEAVES_TRANSFER_ALIGN = 'Center';
export const LEAVE_CLUSTER_COMP_OFF_ALLOWED = 'Comp Off Allowed'; export const LEAVE_CLUSTER_COMP_OFF_ALLOWED_WIDTH = 100; export const LEAVE_CLUSTER_COMP_OFF_ALLOWED_FIELD = 'compoffAllowed'; export const LEAVE_CLUSTER_COMP_OFF_ALLOWED_ALIGN = 'Center';
export const LEAVE_CLUSTER_MIN_COMP_OFF = 'Min No Of Comp Off'; export const LEAVE_CLUSTER_MIN_COMP_OFF_WIDTH = 120; export const LEAVE_CLUSTER_MIN_COMP_OFF_FIELD = 'minCompoff'; export const LEAVE_CLUSTER_MIN_COMP_OFF_ALIGN = 'Center';
export const LEAVE_CLUSTER_COLOR = 'Color'; export const LEAVE_CLUSTER_COLOR_WIDTH = 120; export const LEAVE_CLUSTER_COLOR_FIELD = 'colorName'; export const LEAVE_CLUSTER_COLOR_ALIGN = 'Center';


/** Statutory cluster grid column */
/**
 *Aurhor: Amit Kumar
 *Date:16/02/2022
 */
export const STATUTORY_CLUSTER_STATUTORY_NAME = 'Statutory Name'; export const STATUTORY_CLUSTER_STATUTORY_NAME_FIELD = 'statutoryName'; export const STATUTORY_CLUSTER_STATUTORY_NAME__ALIGN = 'Left';
export const STATUTORY_CLUSTER_STATUTORY_SYMBOL = 'Symbol'; export const STATUTORY_CLUSTER_STATUTORY_SYMBOL_FIELD = 'Symbol'; export const STATUTORY_CLUSTER_STATUTORY_SYMBOL_ALIGN = 'Left';
export const STATUTORY_CLUSTER_STATUTORY_DESCRIPTION = 'Description'; export const STATUTORY_CLUSTER_STATUTORY_DESCRIPTION_FIELD = 'statutoryDescription'; export const STATUTORY_CLUSTER_STATUTORY_DESCRIPTION_ALIGN = 'Left';
export const STATUTORY_CLUSTER_STATUTORY_DATE = 'Date'; export const STATUTORY_CLUSTER_STATUTORY_DATE_FIELD = 'date'; export const STATUTORY_CLUSTER_STATUTORY_DATE_ALIGN = 'Left';
export const STATUTORY_CLUSTER_STATUTORY_DAY = 'Day'; export const STATUTORY_CLUSTER_STATUTORY_DAY_FIELD = 'day'; export const STATUTORY_CLUSTER_STATUTORY_DAY_ALIGN = 'Left';
export const STATUTORY_CLUSTER_STATUTORY_COUNTRY = 'Country'; export const STATUTORY_CLUSTER_STATUTORY_COUNTRY_FIELD = 'countryName'; export const STATUTORY_CLUSTER_STATUTORY_COUNTRY_ALIGN = 'Left';
export const STATUTORY_CLUSTER_STATUTORY_LOCATION = 'Location/City'; export const STATUTORY_CLUSTER_STATUTORY_LOCATION_FIELD = 'cityName'; export const STATUTORY_CLUSTER_STATUTORY_LOCATION_ALIGN = 'Left';
export const STATUTORY_CLUSTER_STATUTORY_TYPE = 'Type'; export const STATUTORY_CLUSTER_STATUTORY_TYPE_FIELD = 'tType'; export const STATUTORY_CLUSTER_STATUTORY_TYPE_ALIGN = 'Center';
export const STATUTORY_CLUSTER_STATUTORY_DAY_BREAK = 'Day Break'; export const STATUTORY_CLUSTER_STATUTORY_DAY_BREAK_FIELD = 'dayBreak'; export const STATUTORY_CLUSTER_STATUTORY_DAY_BREAK_ALIGN = 'Left';
export const STATUTORY_CLUSTER_STATUTORY_ATTACH_NOTE = 'Attach Note'; export const STATUTORY_CLUSTER_STATUTORY_ATTACH_NOTE_FIELD = 'Attach_Note'; export const STATUTORY_CLUSTER_STATUTORY_ATTACH_NOTE_ALIGN = 'Left';
export const STATUTORY_CLUSTER_STATUTORY_ACTIVE = 'Status'; export const STATUTORY_CLUSTER_STATUTORY_ACTIVE_FIELD = 'isActive'; export const STATUTORY_CLUSTER_STATUTORY_ACTIVE_ALIGN = 'Left';
export const REVIEW_STATUE = "Status"; export const REVIEW_STATUE_WIDTH = 110; export const REVIEW_STATUE_FIELD = "reviewStatus"; export const REVIEW_STATUE_ALIGN = "Left";


/**
 * Exceptional cluster grid column
 *Aurhor: Sanjeev
 *Date:9/12/2022
 */
export const EXCEPTIONAL_CLUSTER_EXCEPTIONAL_NAME = 'Attendance Name'; export const EXCEPTIONAL_CLUSTER_EXCEPTIONAL_NAME_FIELD = 'exceptMasterName'; export const EXCEPTIONAL_CLUSTER_EXCEPTIONAL_NAME__ALIGN = 'Left';
export const EXCEPTIONAL_CLUSTER_EXCEPTIONAL_SYMBOL = 'Symbol'; export const EXCEPTIONAL_CLUSTER_EXCEPTIONAL_SYMBOL_FIELD = 'Symbol'; export const EXCEPTIONAL_CLUSTER_EXCEPTIONAL_SYMBOL_ALIGN = 'Left';
export const EXCEPTIONAL_CLUSTER_EXCEPTIONAL_DESCRIPTION = 'Description'; export const EXCEPTIONAL_CLUSTER_EXCEPTIONAL_DESCRIPTION_FIELD = 'exceptDescription'; export const EXCEPTIONAL_CLUSTER_EXCEPTIONAL_DESCRIPTION_ALIGN = 'Left';
export const EXCEPTIONAL_CLUSTER_EXCEPTIONAL_DATE = 'Date'; export const EXCEPTIONAL_CLUSTER_EXCEPTIONAL_DATE_FIELD = 'date'; export const EXCEPTIONAL_CLUSTER_EXCEPTIONAL_DATE_ALIGN = 'Left';
export const EXCEPTIONAL_CLUSTER_EXCEPTIONAL_DAY = 'Day'; export const EXCEPTIONAL_CLUSTER_EXCEPTIONAL_DAY_FIELD = 'day'; export const EXCEPTIONAL_CLUSTER_EXCEPTIONAL_DAY_ALIGN = 'Left';
export const EXCEPTIONAL_CLUSTER_EXCEPTIONAL_COUNTRY = 'Country'; export const EXCEPTIONAL_CLUSTER_EXCEPTIONAL_COUNTRY_FIELD = 'countryName'; export const EXCEPTIONAL_CLUSTER_EXCEPTIONAL_COUNTRY_ALIGN = 'Left';
export const EXCEPTIONAL_CLUSTER_EXCEPTIONAL_LOCATION = 'Location/City'; export const EXCEPTIONAL_CLUSTER_EXCEPTIONAL_LOCATION_FIELD = 'cityName'; export const EXCEPTIONAL_CLUSTER_EXCEPTIONAL_LOCATION_ALIGN = 'Left';
export const EXCEPTIONAL_CLUSTER_EXCEPTIONAL_TYPE = 'Type'; export const EXCEPTIONAL_CLUSTER_EXCEPTIONAL_TYPE_FIELD = 'tType'; export const EXCEPTIONAL_CLUSTER_EXCEPTIONAL_TYPE_ALIGN = 'Center';
export const EXCEPTIONAL_CLUSTER_EXCEPTIONAL_DAY_BREAK = 'Day Break'; export const EXCEPTIONAL_CLUSTER_EXCEPTIONAL_DAY_BREAK_FIELD = 'dayBreak'; export const EXCEPTIONAL_CLUSTER_EXCEPTIONAL_DAY_BREAK_ALIGN = 'Left';
export const EXCEPTIONAL_CLUSTER_EXCEPTIONAL_ATTACH_NOTE = 'Attach Policy'; export const EXCEPTIONAL_CLUSTER_EXCEPTIONAL_ATTACH_NOTE_FIELD = 'Attach_Note'; export const EXCEPTIONAL_CLUSTER_EXCEPTIONAL_ATTACH_NOTE_ALIGN = 'Left';
export const EXCEPTIONAL_CLUSTER_EXCEPTIONAL_ACTIVE = 'Status'; export const EXCEPTIONAL_CLUSTER_EXCEPTIONAL_ACTIVE_FIELD = 'isActive'; export const EXCEPTIONAL_CLUSTER_EXCEPTIONAL_ACTIVE_ALIGN = 'Center';


/**
 * Working Day cluster grid column
 *Aurhor: Amit Kumar
 *Date:17/02/2022 
 */
export const WORKINGDAY_CLUSTER_NAME = 'Working Day'; export const WORKINGDAY_CLUSTER_NAME_FIELD = 'workingdayName'; export const WORKINGDAY_CLUSTER_NAME__ALIGN = 'Left';
export const WORKINGDAY_CLUSTER_DESCRIPTION = 'Description'; export const WORKINGDAY_CLUSTER_DESCRIPTION_FIELD = 'workingdayDescription'; export const WORKINGDAY_CLUSTER_DESCRIPTION_ALIGN = 'Left';
export const WORKINGDAY_CLUSTER_COMPANY = 'Company'; export const WORKINGDAY_CLUSTER_COMPANY_FIELD = 'companyName'; export const WORKINGDAY_CLUSTER_COMPANY_ALIGN = 'Left';
export const WORKINGDAY_CLUSTER_COUNTRY = 'Country'; export const WORKINGDAY_CLUSTER_COUNTRY_FIELD = 'countryName'; export const WORKINGDAY_CLUSTER_COUNTRY_ALIGN = 'Left';
export const WORKINGDAY_CLUSTER_DATE = 'Date'; export const WORKINGDAY_CLUSTER_DATE_FIELD = 'date'; export const WORKINGDAY_CLUSTER_DATE_ALIGN = 'Left';
export const WORKINGDAY_CLUSTER_LOCATION = 'Location/City'; export const WORKINGDAY_CLUSTER_LOCATION_FIELD = 'cityName'; export const WORKINGDAY_CLUSTER_LOCATION_ALIGN = 'Left';
export const WORKINGDAY_CLUSTER_TYPE = 'Type'; export const WORKINGDAY_CLUSTER_TYPE_FIELD = 'tType'; export const WORKINGDAY_CLUSTER_TYPE_ALIGN = 'Center';
export const WORKINGDAY_CLUSTER_DAY_BREAK = 'Day Break'; export const WORKINGDAY_CLUSTER_DAY_BREAK_FIELD = 'dayBreak'; export const WORKINGDAY_CLUSTER_DAY_BREAK_ALIGN = 'Center';
export const WORKINGDAY_CLUSTER_START_TIME = 'Start Time'; export const WORKINGDAY_CLUSTER_START_TIME_FIELD = 'startTime'; export const WORKINGDAY_CLUSTER_START_TIME_ALIGN = 'Center';
export const WORKINGDAY_CLUSTER_END_TIME = 'End Time'; export const WORKINGDAY_CLUSTER_END_TIME_FIELD = 'endTime'; export const WORKINGDAY_CLUSTER_END_TIME_ALIGN = 'Center';
export const WORKINGDAY_CLUSTER_MIN_HRS = 'Min Hrs'; export const WORKINGDAY_CLUSTER_MIN_HRS_FIELD = 'minHrs'; export const WORKINGDAY_CLUSTER_MIN_HRS_ALIGN = 'Center';
export const WORKINGDAY_CLUSTER_ACTIVE = 'Status'; export const WORKINGDAY_CLUSTER_ACTIVE_FIELD = 'isActive'; export const WORKINGDAY_CLUSTER_ACTIVE_ALIGN = 'Center';

/**
 * Workingday Day Break Mapping
 * Aurhor: Noel Fredrick
 * Date:27/11/2023 
 */
export const WORKINGDAY_DAYBREAK_MAPPING_DAYBREAK = 'Day Break'; export const WORKINGDAY_DAYBREAK_MAPPING_DAYBREAK_FIELD = 'dayBreakId'; export const WORKINGDAY_DAYBREAK_MAPPING_DAYBREAK_ALIGN = 'Left';
export const WORKINGDAY_DAYBREAK_MAPPING_WORKDAY = 'Workday Window'; export const WORKINGDAY_DAYBREAK_MAPPING_WORKDAY_FIELD = 'workdayWindowName'; export const WORKINGDAY_DAYBREAK_MAPPING_WORKDAY_ALIGN = 'Left';
export const WORKINGDAY_DAYBREAK_WORK_HRS = 'Work Hours'; export const WORKINGDAY_DAYBREAK_WORK_HRS_FIELD = 'workHrs'; export const WORKINGDAY_DAYBREAK_WORK_HRS_ALIGN = 'Center';
export const WORKINGDAY_DAYBREAK_MIN_HRS = 'Minimum Hours'; export const WORKINGDAY_DAYBREAK_MIN_HRS_FIELD = 'minHrs'; export const WORKINGDAY_DAYBREAK_MIN_HRS_ALIGN = 'Center';
export const WORKINGDAY_DAYBREAK_WEEKLY_HRS = 'Weekly Hours'; export const WORKINGDAY_DAYBREAK_WEEKLY_HRS_FIELD = 'weeklyWrkHrs'; export const WORKINGDAY_DAYBREAK_WEEKLY_HRS_ALIGN = 'Center';
export const WORKINGDAY_DAYBREAK_GRACE_TIME = 'Grace Time'; export const WORKINGDAY_DAYBREAK_GRACE_TIME_FIELD = 'graceTime'; export const WORKINGDAY_DAYBREAK_GRACE_TIME_ALIGN = 'Center';
export const WORKINGDAY_DAYBREAK_WORKING_SAT = 'Working Saturday'; export const WORKINGDAY_DAYBREAK_WORKING_SAT_FIELD = 'workingSaturday'; export const WORKINGDAY_DAYBREAK_WORKING_SATALIGN = 'Center';
export const WORKINGDAY_DAYBREAK_WORKING_FORTYHR = '40 Hr Work Week'; export const WORKINGDAY_DAYBREAK_WORKING_FORTYHR_FIELD = 'fortyHrWorkWeekId'; export const WORKINGDAY_DAYBREAK_WORKING_FORTYHRALIGN = 'Center';
export const WORKINGDAY_DAYBREAK_WORKING_ACTIVE = 'Status'; export const WORKINGDAY_DAYBREAK_WORKINGACTIVE_FIELD = 'isActive'; export const WORKINGDAY_DAYBREAK_WORKINGACTIVE_ALIGN = 'Center';


/**
 * Role cluster grid column
 *Aurhor: Amit Kumar
 *Date:21/02/2022
 */
export const ROLE_CLUSTER_NAME = 'Role Name'; export const ROLE_CLUSTER_NAME_FIELD = 'roleName'; export const ROLE_CLUSTER_NAME_ALIGN = 'Left';
export const ROLE_CLUSTER_DESCRIPTION = 'Description'; export const ROLE_CLUSTER_DESCRIPTION_FIELD = 'roleDescription'; export const ROLE_CLUSTER_DESCRIPTION_ALIGN = 'Left';
export const ROLE_CLUSTER_ACTIVE = 'Status'; export const ROLE_CLUSTER_ACTIVE_FIELD = 'isActive'; export const ROLE_CLUSTER_ALIGN = 'Center';


/**
 * Colour cluster grid column
 *Aurhor: Amit Kumar
 *Date:21/02/2022
 */
export const COLOUR_CLUSTER_NAME = 'Legend Type'; export const COLOUR_CLUSTER_NAME_WIDTH = 220; export const COLOUR_CLUSTER_NAME_FIELD = 'colorName'; export const COLOUR_CLUSTER_NAME_ALIGN = 'Left';
export const COLOUR_CLUSTER_COLOUR = 'Color'; export const COLOR_CLUSTER_COLOR_NAME_WIDTH = 100; export const COLOUR_CLUSTER_COLOUR_FIELD = 'colorHexCode'; export const COLOUR_CLUSTER_COLOUR_ALIGN = 'Left';
export const COLOR_CLUSTER_ACTIVE = 'Status'; export const COLOR_CLUSTER_ACTIVE_FIELD = 'isActive'; export const COLOR_CLUSTER_ACTIVE_ALIGN = 'Center';

/** Leave Approval */
export const LEAVE_APPROVAL_CHECK_BOX = ''; export const LEAVE_APPROVAL_CHECK_BOX_WIDTH = 25; export const LEAVE_APPROVAL_CHECK_BOX_FIELD = ''; export const LEAVE_APPROVAL_CHECK_BOX_ALIGN = 'Center';
export const LEAVE_APPROVAL_EMPLOYEE_NAME = "Team Member Name "; export const LEAVE_APPROVAL_EMPLOYEE_NAME_WIDTH = isThirteenHundred || isFourteenHundred ? 130 : 200; export const LEAVE_APPROVAL_EMPLOYEE_NAME_FIELD = "tmName";
export const LEAVE_APPROVAL_APPLIED_ON = 'Applied Date'; export const LEAVE_APPROVAL_APPLIED_ON_WIDTH = isThirteenHundred ? 145 : isOneTwentyFiveScale ? 140 : isSixteenEighty ? 160 : 165; export const LEAVE_APPROVAL_APPLIED_ON_FIELD = 'appliedOn'; export const LEAVE_APPROVAL_APPLIED_ON_ALIGN = 'Center';
export const LEAVE_APPROVAL_END_DATE = 'To Date'; export const LEAVE_APPROVAL_END_DATE_WIDTH = 125; export const LEAVE_APPROVAL_END_DATE_FIELD = 'endDate'; export const LEAVE_APPROVAL_END_DATE_ALIGN = 'Center';
export const LEAVE_APPROVAL_ROLE = 'Role'; export const LEAVE_APPROVAL_ROLE_WIDTH = 100; export const LEAVE_APPROVAL_ROLE_FIELD = 'role'; export const LEAVE_APPROVAL_ROLE_ALIGN = 'Left';
export const LEAVE_APPROVAL_NO_OF_DAYS = 'No. of Days'; export const LEAVE_APPROVAL_NO_OF_DAYS_WIDTH = 120; export const LEAVE_APPROVAL_NO_OF_DAYS_FIELD = 'noOfDays'; export const LEAVE_APPROVAL_NO_OF_DAYS_ALIGN = 'Center';
export const LEAVE_APPROVAL_ACTION = 'Action'; export const LEAVE_APPROVAL_ACTION_WIDTH = 70; export const LEAVE_APPROVAL_ACTION_FIELD = 'Actions'; export const LEAVE_APPROVAL_ACTION_ALIGN = 'Center';
export const LEAVE_APPROVAL_LEAVE_TYPE = 'Approval Type'; export const LEAVE_APPROVAL_LEAVE_TYPE_WIDTH = isThirteenHundred ? 130 : isOneTwentyFiveScale ? 170 : isSixteenHundred ? 175 : isSixteenEighty ? 173 : 155; export const LEAVE_APPROVAL_LEAVE_TYPE_FIELD = 'ApprovalType'; export const LEAVE_APPROVAL_LEAVE_TYPE_ALIGN = 'Center';
export const LEAVE_APPROVAL_REASON_FIELD = "reason"; export const LEAVE_APPROVAL_STATUS_FIELD = "detailedStatus";

// Leave Transfer Approval
export const LEAVE_APPROVAL_SOURCE_LEAVE = "Source Leave"; export const LEAVE_APPROVAL_SOURCE_LEAVE_WIDTH = isThirteenHundred ? 75 : isSixteenEighty ? 120 : 110; export const LEAVE_APPROVAL_SOURCE_LEAVE_FIELD = "sourceLeave"; export const LEAVE_APPROVAL_SOURCE_LEAVE_ALIGN = "Center";
export const LEAVE_APPROVAL_DESTINATION_LEAVE = "Destination Leave"; export const LEAVE_APPROVAL_DESTINATION_LEAVE_WIDTH = isThirteenHundred ? 55 : isSixteenHundred ? 90 : 100; export const LEAVE_APPROVAL_DESTINATION_LEAVE_FIELD = "destinationLeave"; export const LEAVE_APPROVAL_DESTINATION_LEAVE_ALIGN = "Center";
export const LEAVE_APPROVAL_NO_OF_LEAVES_FIELD = 'noOfLeaves';

/** Swipe Appproval */
export const LEAVE_APPROVAL_NO_OF_HOURS_FIELD = "noOfHours";
export const LEAVE_CLUSTER_APPLIED_FOR_DATE = "Applied for Date"; export const LEAVE_CLUSTER_APPLIED_FOR_DATE_FIELD = "appliedFor";
export const LEAVE_CLUSTER_SWIPE_TYPE = "Attendance Type"; export const LEAVE_CLUSTER_SWIPE_TYPE_FIELD = "swipeType";
export const LEAVE_APPROVAL_MODE = "Mode"; export const LEAVE_APPROVAL_MODE_WIDTH = 80; export const LEAVE_APPROVAL_MODE_FIELD = "swipeMode"; export const LEAVE_APPROVAL_MODE_ALIGN = "center";
export const LEAVE_SWIPE_IN_TIME = "In Time"; export const LEAVE_SWIPE_TIME_WIDTH = isOneTwentyFiveScale ? 115 : isSixteenEighty ? 110 : 100; export const LEAVE_SWIPE_IN_TIME_FIELD = "inTime"; export const LEAVE_SWIPE_OUT_TIME_FIELD = "outTime"; export const LEAVE_SWIPE_TIME_ALIGN = "center";
export const LEAVE_SWIPE_OUT_TIME = "Out Time";

/** orgchar master function data folder Management */
export const ORDERID = "Order ID"; export const ORDERID_WIDTH = 80; export const ORDERID_FIELD = "OrderID"; export const ORDERID_ALIGN = "Center";
export const FOLDERMANAGE = "Folder Name"; export const FOLDERMANAGE_WIDTH = 125; export const FOLDERMANAGE_FIELD = "folderName"; export const FOLDERMANAGE_ALIGN = "Left";
export const FOLDERPARENT = "Parent Folder"; export const FOLDERPARENT_WIDTH = 125; export const FOLDERPARENT_FIELD = "parentFolderName"; export const FOLDERPARENT_ALIGN = "Left";
export const FOLDERSTATUS = "Status"; export const FOLDERSTATUS_WIDTH = 80; export const FOLDERSTATUS_FIELD = "isActive"; export const FOLDERSTATUS_ALIGN = "left";
export const FOLDER_ACTION = "Action"; export const FOLDER_ACTION_WIDTH = window.innerWidth===1536? 70: 60; export const FOLDER_ACTION_FIELD = "action"; export const FOLDER_ACTION_ALIGN = "center";

/**
 * Symbol cluster grid column
 *Aurhor: Noel
 *Date:17/10/2022
 */
export const SYMBOL_CLUSTER_NAME = 'Symbol Name'; export const SYMBOL_CLUSTER_NAME_FIELD = 'SymbolName'; export const SYMBOL_CLUSTER_NAME_ALIGN = 'Left';
export const SYMBOL_CLUSTER_SYMBOL = 'Symbol'; export const SYMBOL_CLUSTER_SYMBOL_FIELD = 'Symbol'; export const SYMBOL_CLUSTER_SYMBOL_ALIGN = 'Left';
export const SYMBOL_CLUSTER_DESCRIPTION = 'Description'; export const SYMBOL_CLUSTER_DESCRIPTION_FIELD = 'Description'; export const SYMBOL_CLUSTER_DESCRIPTION_ALIGN = 'Left';
export const SYMBOL_CLUSTER_MASTER = 'Master(s) Assigned to'; export const SYMBOL_CLUSTER_MASTER_FIELD = 'masterCluster'; export const SYMBOL_CLUSTER_MASTER_ALIGN = 'Left';
export const SYMBOL_CLUSTER_ACTIVE = 'Status'; export const SYMBOL_CLUSTER_ACTIVE_FIELD = 'isActive'; export const SYMBOL_CLUSTER_ACTIVE_ALIGN = 'Center';


/**
 * Leave with Finance cluster grid column
 *Author: Chandrashekhar Bhagat
 *Date:16/11/2023
 */
export const LEAVE_YEAR_BUSINESS = 'Business Unit / Group Function'; export const LEAVE_YEAR_BUSINESS_FIELD = 'pnl'; export const LEAVE_YEAR_BUSINESS_ALIGN = 'Left';
export const LEAVE_YEAR_SUB_BUSINESS = 'Sub Business Unit / Function'; export const LEAVE_YEAR_SUB_BUSINESS_FIELD = 'subPnl'; export const LEAVE_YEAR_SUB_BUSINESS_ALIGN = 'Left';
export const LEAVE_YEAR_PRACTICEAREA = 'Practice Area / Sales Unit / Sub Function'; export const LEAVE_YEAR_PRACTICEAREA_FIELD = 'practiceArea'; export const LEAVE_YEAR_PRACTICEAREA_ALIGN = 'Left';
export const LEAVE_YEAR_MEMBERID = 'Team Member Code'; export const LEAVE_YEAR_MEMBERID_FIELD = 'empCode'; export const LEAVE_YEAR_MEMBERID_ALIGN = 'Center';
export const LEAVE_YEAR_MEMBERNAME = 'Team Member'; export const LEAVE_YEAR_MEMBERNAME_FIELD = 'MemberName'; export const LEAVE_YEAR_MEMBERNAME_ALIGN = 'Left';
export const LEAVE_YEAR = 'Leave Year Configuration'; export const LEAVE_YEAR_FIELD = 'leaveYear'; export const LEAVE_YEAR_ALIGN = 'Center';
export const LEAVE_YEAR_ACTIVE = 'Status'; export const LEAVE_YEAR_ACTIVE_FIELD = 'isActive'; export const LEAVE_YEAR_ACTIVE_ALIGN = 'Center';

/**
 * Correction After Salary Processing grid column
 *Aurhor: Suresh Patel
 *Date:15/02/2022
 */
export const CORR_AFT_SAL_PROCESS_NAME = 'Name'; export const CORR_AFT_SAL_PROCESS_NAME_ALIGN = 'Left';
export const CORR_AFT_SAL_PROCESS_FROM_DATE = 'Form Date'; export const CORR_AFT_SAL_PROCESS_FROM_DATE_ALIGN = 'Left';
export const CORR_AFT_SAL_PROCESS_TO_DATE = 'To Date'; export const CORR_AFT_SAL_PROCESS_TO_DATE_ALIGN = 'Left';
export const CORR_AFT_SAL_PROCESS_LEAVE_TYPE = 'Leave Type'; export const CORR_AFT_SAL_PROCESS_LEAVE_TYPE_ALIGN = 'Left';
export const CORR_AFT_SAL_PROCESS_ATTENDENCE_TYPE = 'Attendence Type'; export const CORR_AFT_SAL_PROCESS_ATTENDENCE_TYPE_ALIGN = 'Left';
export const CORR_AFT_SAL_PROCESS_CURRENT_VALUE = 'Attendence Type'; export const CORR_AFT_SAL_PROCESS_CURRENT_VALUE_ALIGN = 'Left';
export const CORR_AFT_SAL_PROCESS_CHANGED_VALUE_TO = 'Changed Value To'; export const CORR_AFT_SAL_PROCESS_CHANGED_VALUE_TO_ALIGN = 'Left';
export const CORR_AFT_SAL_PROCESS_BALANCE_OF_VALUE = 'Balance of Value'; export const CORR_AFT_SAL_PROCESS_BALANCE_OF_VALUE_ALIGN = 'Left';
export const CORR_AFT_SAL_PROCESS_REASON_FOR_CORRECTION = 'Reason for Correction'; export const CORR_AFT_SAL_PROCESS_REASON_FOR_CORRECTION_ALIGN = 'Left';
export const CORR_AFT_SAL_PROCESS_APPROVAL = 'Reason for Correction'; export const CORR_AFT_SAL_PROCESS_APPROVAL_ALIGN = 'Left';




// okr nine blocker grid column
/**
 *Aurhor: Sanjeev
 *Date:12/1/2023
 */
export const BLOCK_MASTER_PRIMARY_COLOR_NAME = 'Primary Color'; export const BLOCK_MASTER_PRIMARY_COLOR_NAME_FIELD = 'blockHeadingColor'; export const BLOCK_MASTER_PRIMARY_COLOR_NAME_ALIGN = 'Left';
export const BLOCK_MASTER_SECONDRY_COLOR_NAME = 'Secondary Color'; export const BLOCK_MASTER_SECONDRY_COLOR_NAME_FIELD = 'blockHeadingColor'; export const BLOCK_MASTER_SECONDRY_COLOR_NAME_ALIGN = 'Center';
export const BLOCK_MASTER_FINANCIAL_YEAR = 'Financial Year'; export const BLOCK_MASTER_FINANCIAL_YEAR_WIDTH = 120; export const BLOCK_MASTER_FINANCIAL_YEAR_FIELD = 'financialYearName'; export const BLOCK_MASTER_FINANCIAL_YEAR_ALIGN = 'Center';



// OKR Performance and Potential Master
export const PERFORMANCE_MASTER_NAME = 'Performance Name'; export const PERFORMANCE_MASTER_NAME_FIELD = 'productivityName'; export const PERFORMANCE_MASTER_NAME_ALIGN = 'Left';
export const POTENTIAL_MASTER_NAME = 'Potential Name'; export const POTENTIAL_MASTER_NAME_FIELD = 'productivityName'; export const POTENTIAL_MASTER_NAME_ALIGN = 'Left';
export const PRODUCTIVITY_ALLOCATION_TYPE = 'Allocation Type'; export const PRODUCTIVITY_ALLOCATION_TYPE_FIELD = 'allocationType'; export const PRODUCTIVITY_ALLOCATION_TYPE_ALIGN = 'Left';
export const PRODUCTIVITY_DESCRIPTION = 'Description'; export const PRODUCTIVITY_DESCRIPTION_FIELD = 'description'; export const PRODUCTIVITY_DESCRIPTION_ALIGN = 'Left';
export const WEIGHTAGE_RANGE_FROM = 'Weightage From'; export const WEIGHTAGE_RANGE_FROM_FIELD = 'WeightageRangeFrom'; export const WEIGHTAGE_RANGE_FROM_ALIGN = 'Center';
export const WEIGHTAGE_RANGE_TO = 'Weightage To'; export const WEIGHTAGE_RANGE_TO_FIELD = 'WeightageRangeTo'; export const WEIGHTAGE_RANGE_TO_ALIGN = 'Center';
export const PROD_DEFAULT_WEIGHTAGE = 'Default Weightage'; export const PROD_DEFAULT_WEIGHTAGE_FIELD = 'defaultWeightage'; export const PROD_DEFAULT_WEIGHTAGE_ALIGN = 'Center';
export const DEFAULT_WEIGHTAGE_EDIT = 'Default Weightage Editable'; export const DEFAULT_WEIGHTAGE_EDIT_FIELD = 'defaultWeightageEditable'; export const DEFAULT_WEIGHTAGE_EDIT_ALIGN = 'Center';
export const WEIGHTED_VARIABLE = 'Weighted Variable Sub Allocation'; export const WEIGHTED_VARIABLE_FIELD = 'weightedVariable'; export const WEIGHTED_VARIABLE_ALIGN = 'Center';
export const PROD_RATING_SCALE = 'Rating Scale'; export const PROD_RATING_SCALE_FIELD = 'ratingScale'; export const PROD_RATING_SCALE_ALIGN = 'Left';
export const PRODUCTIVITY_FREQUENCY = 'Frequency'; export const PRODUCTIVITY_FREQUENCY_FIELD = 'frequency'; export const PRODUCTIVITY_FREQUENCY_ALIGN = 'Left';
export const PRODUCITVITY_FINANCIAL_YEAR = 'Financial Year'; export const PRODUCITVITY_FINANCIAL_YEAR_WIDTH = 120; export const PRODUCITVITY_FINANCIAL_YEAR_FIELD = 'financialYearName'; export const PRODUCITVITY_FINANCIAL_YEAR_ALIGN = 'Center';

export const PRODUCITVITY_MASTER_ACTIVE = 'Status'; export const PRODUCITVITY_MASTER_ACTIVE_FIELD = 'isActive'; export const PRODUCITVITY_MASTER_ACTIVE_ALIGN = 'Center';

//  OKR admin Rating system
export const RATING_SYSTEM_ACTIVE = 'Status'; export const RATING_SYSTEM_ACTIVE_FIELD = 'isActive'; export const RATING_SYSTEM_ACTIVE_ALIGN = 'Center';
export const RATING_SYSTEM_NAME = 'Rating Scale'; export const RATING_SYSTEM_NAME_FIELD = 'name'; export const RATING_SYSTEM_NAME_ALIGN = 'Left';

export const RATING_MASTER_SYSTEM_NAME = 'Rating Scale'; export const RATING_MASTER_SYSTEM_NAME_FIELD = 'ratingSystemName'; export const RATING_MASTER_SYSTEM_NAME_ALIGN = 'Left';
export const RATING_MASTER_VALUE = 'Value'; export const RATING_MASTER_VALUE_FIELD = 'ratingValue'; export const RATING_MASTER_VALUE_ALIGN = 'Center';
export const RATING_MASTER_MEANING = 'Meaning'; export const RATING_MASTER_MEANING_FIELD = 'meaning'; export const RATING_MASTER_MEANING_ALIGN = 'Left';
export const RATING_MASTER_LEVEL_STARTING = 'Level Starting From Lower Value'; export const RATING_MASTER_LEVEL_STARTING_FIELD = 'levelStartingFromLowerValue'; export const RATING_MASTER_LEVEL_STARTING_ALIGN = 'Center';
export const RATING_MASTER_BENCHMARK = 'Benchmark'; export const RATING_MASTER_BENCHMARK_FIELD = 'benchmark'; export const RATING_MASTER_BENCHMARK_ALIGN = 'Center';

//  OKR admin Team
export const TEAM_MEMBER_NAME = 'Name of Team Member'; export const TEAM_MEMBER_NAME_FIELD = 'employeeName'; export const TEAM_MEMBER_NAME_ALIGN = 'Left';
export const TEAM_MEMBER_TITLE = 'Title'; export const TEAM_MEMBER_TITLE_FIELD = 'jobTitle'; export const TEAM_MEMBER_TITLE_ALIGN = 'Left';
export const TEAM_MEMBER_DEPARTMENT = 'Practice Area/ Sales Unit/ Sub Function'; export const TEAM_MEMBER_DEPARTMENT_FIELD = 'department'; export const TEAM_MEMBER_DEPARTMENT_ALIGN = 'Left';
export const TEAM_MEMBER_DATE_OF_JOINING = 'Date of Joining'; export const TEAM_MEMBER_DATE_OF_JOINING_FIELD = 'dateOfJoining'; export const TEAM_MEMBER_DATE_OF_JOINING_ALIGN = 'Center';
export const TEAM_MEMBER_DATE_OF_FIRST = 'Apr'; export const TEAM_MEMBER_DATE_OF_FIRST_FIELD = 'aprCalculation'; export const TEAM_MEMBER_DATE_OF_FIRST_ALIGN = 'Center';
export const TEAM_MEMBER_DATE_OF_SECOND = 'May'; export const TEAM_MEMBER_DATE_OF_SECOND_FIELD = 'mayCalculation'; export const TEAM_MEMBER_DATE_OF_SECOND_ALIGN = 'Center';
export const TEAM_MEMBER_DATE_OF_THIRD = 'Jun | Q1'; export const TEAM_MEMBER_DATE_OF_THIRD_FIELD = 'junCalculation'; export const TEAM_MEMBER_DATE_OF_THIRD_ALIGN = 'Center';
export const TEAM_MEMBER_DATE_OF_LAST = 'Jul'; export const TEAM_MEMBER_DATE_OF_LAST_FIELD = 'julCalculation'; export const TEAM_MEMBER_DATE_OF_LAST_ALIGN = 'Center';
export const TEAM_MEMBER_DATE_OF_AUG = 'Aug'; export const TEAM_MEMBER_DATE_OF_AUG_FIELD = 'augCalculation'; export const TEAM_MEMBER_DATE_OF_AUG_ALIGN = 'Center';
export const TEAM_MEMBER_DATE_OF_SEP = 'Sep | Q2 | H1'; export const TEAM_MEMBER_DATE_OF_SEP_FIELD = 'sepCalculation'; export const TEAM_MEMBER_DATE_OF_SEP_ALIGN = 'Center';
export const TEAM_MEMBER_DATE_OF_OCT = 'Oct'; export const TEAM_MEMBER_DATE_OF_OCT_FIELD = 'octCalculation'; export const TEAM_MEMBER_DATE_OF_OCT_ALIGN = 'Center';
export const TEAM_MEMBER_DATE_OF_NOV = 'Nov'; export const TEAM_MEMBER_DATE_OF_NOV_FIELD = 'novCalculation'; export const TEAM_MEMBER_DATE_OF_NOV_ALIGN = 'Center';
export const TEAM_MEMBER_DATE_OF_DEC = 'Dec | Q3'; export const TEAM_MEMBER_DATE_OF_DEC_FIELD = 'decCalculation'; export const TEAM_MEMBER_DATE_OF_DEC_ALIGN = 'Center';
export const TEAM_MEMBER_DATE_OF_JAN = 'Jan'; export const TEAM_MEMBER_DATE_OF_JAN_FIELD = 'janCalculation'; export const TEAM_MEMBER_DATE_OF_JAN_ALIGN = 'Center';
export const TEAM_MEMBER_DATE_OF_FEB = 'Feb'; export const TEAM_MEMBER_DATE_OF_FEB_FIELD = 'febCalculation'; export const TEAM_MEMBER_DATE_OF_FEB_ALIGN = 'Center';
export const TEAM_MEMBER_DATE_OF_MAR = 'Mar | Q4 | H2 | FY'; export const TEAM_MEMBER_DATE_OF_MAR_FIELD = 'marCalculation'; export const TEAM_MEMBER_DATE_OF_MAR_ALIGN = 'Center';
export const TEAM_MEMBER_CHECKBOX = 'TEAM_MEMBER_CHECKBOX'; export const TEAM_MEMBER_CHECKBOX_TYPE = 'checkbox'; export const TEAM_MEMBER_CHECKBOX_WIDTH = '50'; export const TEAM_MEMBER_CHECKBOX_ALIGN = 'Center'

// Axis cut off
export const AXIS_CUT_OFF_AXIS_NAME = 'Axis Name'; export const AXIS_CUT_OFF_AXIS_NAME_FIELD = 'axis'; export const AXIS_CUT_OFF_AXIS_NAME_ALIGN = 'Left';
export const AXIS_CUT_OFF_RANGE = 'Range'; export const AXIS_CUT_OFF_RANGE_FIELD = 'range'; export const AXIS_CUT_OFF_RANGE_ALIGN = 'Left';
export const AXIS_CUT_OFF_LOWER_VALUE = 'Lower Value'; export const AXIS_CUT_OFF_LOWER_VALUE_FIELD = 'minValue'; export const AXIS_CUT_OFF_LOWER_VALUE_ALIGN = 'Center';
export const AXIS_CUT_OFF_HIGHER_VALUE = 'Higher Value'; export const AXIS_CUT_OFF_HIGHER_VALUE_FIELD = 'maxValue'; export const AXIS_CUT_OFF_HIGHER_VALUE_FIELD_ALIGN = 'Center';
export const AXIS_CUT_OFF_AXIS_POSITION = 'Axis Position'; export const AXIS_CUT_OFF_AXIS_POSITION_FIELD = 'axisPosition'; export const AXIS_CUT_OFF_AXIS_POSITION_ALIGN = 'Center';
export const AXIS_CUT_OFF_FINANCIAL_YEAR = 'Financial Year'; export const AXIS_CUT_OFF_FINANCIAL_YEAR_FIELD = 'financialYear'; export const AXIS_CUT_OFF_FINANCIAL_YEAR_ALIGN = 'Center';
export const AXIS_CUT_OFF_STATUS = 'Status'; export const AXIS_CUT_OFF_STATUS_FIELD = 'isActive'; export const AXIS_CUT_OFF_STATUS_ALIGN = 'Center';

// OKR Frequency/Period Master
export const FREQUENCY_MASTER_FINANCIAL_YAER = 'Financial Year'; export const FREQUENCY_MASTER_FINANCIAL_YAER_FIELD = 'financialYearName'; export const FREQUENCY_MASTER_FINANCIAL_YAER_ALIGN = 'Left';
export const FREQUENCY_MASTER_FREQUENCY = 'Frequency'; export const FREQUENCY_MASTER_FREQUENCY_FIELD = 'frequency'; export const FREQUENCY_MASTER_FREQUENCY_ALIGN = 'Left';
export const FREQUENCY_MASTER_INDICATOR = ' Indicator'; export const FREQUENCY_MASTER_INDICATOR_FIELD = 'frequencyIndicator'; export const FREQUENCY_MASTER_INDICATOR_ALIGN = 'Left';

export const APPLICABLE_PERIOD = 'Applicable Period'; export const APPLICABLE_PERIOD_ALIGN = 'Center'
export const FREQUENCY_MASTER_APPLY_PERIOD_FROM = 'From'; export const FREQUENCY_MASTER_APPLY_PERIOD_FROM_FIELD = 'applicableStartDate'; export const FREQUENCY_MASTER_APPLY_PERIOD_FROM_ALIGN = 'Center';

export const PERFORMANCE_OKR = "Performance OKR"; export const PERFORMANCE_OKR_ALIGN = 'Center';
export const FREQUENCY_MASTER_APPLY_PERIOD_TO = 'To'; export const FREQUENCY_MASTER_APPLY_PERIOD_TO_FIELD = 'applicableEndDate'; export const FREQUENCY_MASTER_APPLY_PERIOD_TO_ALIGN = 'Center';
export const FREQUENCY_ADD_EDIT_PERFORM_ST_DATE = 'Add & Edit Start'; export const FREQUENCY_ADD_EDIT_PERFORM_ST_DATE_FIELD = 'performanceAddStartDate'; export const FREQUENCY_ADD_EDIT_PERFORM_ST_DATE_ALIGN = 'Center';
export const FREQUENCY_ADD__PERFORM_END_DATE = 'Add End Date'; export const FREQUENCY_ADD__PERFORM_END_DATE_FIELD = 'performanceAddEndDate'; export const FREQUENCY_ADD__PERFORM_END_DATE_ALIGN = 'Center';
export const FREQUENCY_EDIT__PERFORM_END_DATE = 'Edit End Date'; export const FREQUENCY_EDIT__PERFORM_END_DATE_FIELD = 'performanceEditEndDate'; export const FREQUENCY_EDIT__PERFORM_END_DATE_ALIGN = 'Center';

export const POTENTIAL_OKR = "Potential OKR"; export const POTENTIAL_OKR_ALIGN = 'Center';
export const FREQUENCY_ADD_EDIT_POTENTIAL_ST_DATE = 'Add & Edit Start'; export const FREQUENCY_ADD_EDIT_POTENTIAL_ST_DATE_FIELD = 'potentialAddStartDate'; export const FREQUENCY_ADD_EDIT_POTENTIAL_ST_DATE_ALIGN = 'Center';
export const FREQUENCY_ADD__POTENTIAL_END_DATE = 'Add End Date'; export const FREQUENCY_ADD__POTENTIAL_END_DATE_FIELD = 'potentialAddEndDate'; export const FREQUENCY_ADD__POTENTIAL_END_DATE_ALIGN = 'Center';
export const FREQUENCY_EDIT__POTENTIAL_END_DATE = 'Edit End Date'; export const FREQUENCY_EDIT__POTENTIAL_END_DATE_FIELD = 'potentialEditEndDate'; export const FREQUENCY_EDIT__POTENTIAL_END_DATE_ALIGN = 'Center';

export const SELF_SUP_FINAL_RATING = "Self, Supervisor and Final Rating"; export const SELF_SUP_FINAL_RATING_ALIGN = "Center";
export const FREQUENCY_SELF_SUP_FINAL_RATING_ST_DATE = 'Start Date'; export const PFREQUENCY_SELF_SUP_FINAL_RATING_ST_DATE_FIELD = 'ratingStartDate'; export const FREQUENCY_SELF_SUP_FINAL_RATING_ST_DATE_ALIGN = 'Center';
export const FREQUENCY_SELF_RATING_END_DATE = 'Self Rating End Date'; export const FREQUENCY_SELF_RATING_END_DATE_FIELD = 'selfRatingEndDate'; export const FREQUENCY_SELF_RATING_END_DATE_ALIGN = 'Center';
export const FREQUENCY_SUP_RATING_END_DATE = 'Supervisor Rating End Date'; export const FREQUENCY_SUP_RATING_END_DATE_FIELD = 'supervisorRatingEndDate'; export const FREQUENCY_SUP_RATING_END_DATE_ALIGN = 'Center';
export const FREQUENCY_FINAL_RATING_END_DATE = 'Final Rating End Date'; export const FREQUENCY_FINAL_RATING_END_DATE_FIELD = 'finalRatingEndDate'; export const FREQUENCY_FINAL_RATING_END_DATE_ALIGN = 'Center';

export const FREQUENCY_MASTER_ACTIVE = 'Status'; export const FREQUENCY_MASTER_ACTIVE_FIELD = 'isActive'; export const FREQUENCY_MASTER_ACTIVE_ALIGN = 'Center';
//  Sub Potential Calculation
export const SUB_POTENTIAL_CALCULATION_POTENTIAL_TYPE = 'Potential Type'; export const SUB_POTENTIAL_CALCULATION_POTENTIAL_TYPE_FIELD = 'potentialType'; export const SUB_POTENTIAL_CALCULATION_POTENTIAL_TYPE_ALIGN = 'Left';
export const SUB_POTENTIAL_CALCULATION_SUB_POTENTIAL_NAME = 'Sub Potential Name'; export const SUB_POTENTIAL_CALCULATION_SUB_POTENTIAL_NAME_FIELD = 'kpiLibText'; export const SUB_POTENTIAL_CALCULATION_SUB_POTENTIAL_NAME_ALIGN = 'Left';
export const SUB_POTENTIAL_CALCULATION_SELF_ATTRIBUTE = 'Self Attribute'; export const SUB_POTENTIAL_CALCULATION_SELF_ATTRIBUTE_FIELD = 'questionForSelf'; export const SUB_POTENTIAL_CALCULATION_SELF_ATTRIBUTE_ALIGN = 'Left';
export const SUB_POTENTIAL_CALCULATION_SELF_ATTRIBUTE_DESCRIPTION = 'Self Attribute Description'; export const SUB_POTENTIAL_CALCULATION_SELF_ATTRIBUTE_DESCRIPTION_FIELD = 'questionDescriptionForSelf'; export const SUB_POTENTIAL_CALCULATION_SELF_ATTRIBUTE_DESCRIPTION_ALIGN = 'Left';
export const SUB_POTENTIAL_CALCULATION_SUP_ATTRIBUTE = 'Supervisor Attribute'; export const SUB_POTENTIAL_CALCULATION_SUP_ATTRIBUTE_FIELD = 'questionForSupervisor'; export const SUB_POTENTIAL_CALCULATION_SUP_ATTRIBUTE_ALIGN = 'Left';
export const SUB_POTENTIAL_CALCULATION_SUP_ATTRIBUTE_DESCRIPTION = 'Supervisor Attribute Description'; export const SUB_POTENTIAL_CALCULATION_SUP_ATTRIBUTE_DESCRIPTION_FIELD = 'questionDescriptionForSupervisor'; export const SUB_POTENTIAL_CALCULATION_SUP_ATTRIBUTE_DESCRIPTION_ALIGN = 'Left';
export const SUB_POTENTIAL_CALCULATION_FYID = 'Financial Year'; export const SUB_POTENTIAL_CALCULATION_FYID_FIELD = 'financialyear'; export const SUB_POTENTIAL_CALCULATION_FYID_ALIGN = 'Center';
export const SUB_POTENTIAL_CALCULATION_FREQUENCY = 'Frequency'; export const SUB_POTENTIAL_CALCULATION_FREQUENCY_FIELD = 'frequencyName'; export const SUB_POTENTIAL_CALCULATION_FREQUENCY_ALIGN = 'Left';
export const SUB_POTENTIAL_CALCULATION_START_MONTH = 'Start Month'; export const SUB_POTENTIAL_CALCULATION_START_MONTH_FIELD = 'startMonth'; export const SUB_POTENTIAL_CALCULATION_START_MONTH_ALIGN = 'Left';
export const SUB_POTENTIAL_CALCULATION_END_MONTH = 'End Month'; export const SUB_POTENTIAL_CALCULATION_END_MONTH_FIELD = 'endMonth'; export const SUB_POTENTIAL_CALCULATION_END_MONTH_ALIGN = 'Left';
export const SUB_POTENTIAL_CALCULATION_RATING_SYSTEM = 'Rating Scale'; export const SUB_POTENTIAL_CALCULATION_RATING_SYSTEM_FIELD = 'ratingSystemName'; export const SUB_POTENTIAL_CALCULATION_RATING_SYSTEM_ALIGN = 'Left';
export const SUB_POTENTIAL_CALCULATION_STATUS = 'Status'; export const SUB_POTENTIAL_CALCULATION_STATUS_FIELD = 'isActive'; export const SUB_POTENTIAL_CALCULATION_STATUS_ALIGN = 'Center';

//   Potential Calculation Master
export const POTENTIAL_CALCULATION_WEIGHTAGE = 'Weightage of Sub Potential'; export const POTENTIAL_CALCULATION_WEIGHTAGE_FIELD = 'weightage'; export const POTENTIAL_CALCULATION_WEIGHTAGE_ALIGN = 'Center';
export const POTENTIAL_CALCULATION_MANDATORY = 'Mandatory'; export const POTENTIAL_MANDATORY__FIELD = 'mandatory'; export const POTENTIAL_CALCULATION_MANDATORY_ALIGN = 'Center';

// axisMaster
export const AXIS_MASTER_GROUP_NAME = 'Group Name'; export const AXIS_MASTER_GROUP_NAME_WIDTH = '100'; export const AXIS_MASTER_GROUP_NAME_FIELD = 'groupName'; export const AXIS_MASTER_GROUP_NAME_ALIGN = 'Left';
export const AXIS_MASTER_AXIS_NAME = 'Axis Name'; export const AXIS_MASTER_AXIS_NAME_WIDTH = '100'; export const AXIS_MASTER_AXIS_NAME_FIELD = 'axisName'; export const AXIS_MASTER_AXIS_NAME_ALIGN = 'Center';
export const AXIS_MASTER_AXIS_MEANING = 'Axis Meaning'; export const AXIS_MASTER_AXIS_MEANING_WIDTH = '100'; export const AXIS_MASTER_AXIS_MEANING_FIELD = 'axisMeaning'; export const AXIS_MASTER_AXIS_MEANING_ALIGN = 'Left';
export const AXIS_MASTER_AXIS_WEIGHTED_ALLOCATION = 'Weighted Allocation'; export const AXIS_MASTER_AXIS_WEIGHTED_ALLOCATION_WIDTH = '100'; export const AXIS_MASTER_AXIS_WEIGHTED_ALLOCATION_FIELD = 'weightAllocation'; export const AXIS_MASTER_AXIS_WEIGHTED_ALLOCATION_ALIGN = 'Center';
export const AXIS_MASTER_AXIS_WEIGHTED_VARIABLE = 'Weighted Variable Allocation'; export const AXIS_MASTER_AXIS_WEIGHTED_VARIABLE_WIDTH = '100'; export const AXIS_MASTER_AXIS_WEIGHTED_VARIABLE_FIELD = 'variable'; export const AXIS_MASTER_AXIS_WEIGHTED_VARIABLE_ALIGN = 'Center';
export const AXIS_MASTER_AXIS_FY = 'Financial Year'; export const AXIS_MASTER_AXIS_FY_WIDTH = '100'; export const AXIS_MASTER_AXIS_FY_FIELD = 'fyId'; export const AXIS_MASTER_AXIS_FY_ALIGN = 'Center';
export const AXIS_MASTER_ACTION_ALIGN = 'center';

export const BLOCK_MASTER_XPOSITION = 'X Position'; export const BLOCK_MASTER_XPOSITION_FIELD = 'performance'; export const BLOCK_MASTER_XPOSITION_ALIGN = 'Left';
export const BLOCK_MASTER_YPOSITION = 'Y Position'; export const BLOCK_MASTER_YPOSITION_FIELD = 'potential'; export const BLOCK_MASTER_YPOSITION_ALIGN = 'Left';
export const BLOCK_MASTER_NAME = 'Block Name'; export const BLOCK_MASTER_NAME_FIELD = 'blockName'; export const BLOCK_MASTER_NAME_ALIGN = 'Left';
export const BLOCK_MASTER_MEANING = 'Block Meaning'; export const BLOCK_MASTER_MEANING_FIELD = 'blockMeaning'; export const BLOCK_MASTER_MEANING_ALIGN = 'Left';
export const BLOCK_MASTER_DESCRIPTION = 'Description'; export const BLOCK_MASTER_DESCRIPTION_FIELD = 'blockDescription'; export const BLOCK_MASTER_DESCRIPTION_ALIGN = 'Left';
export const BLOCK_MASTER_PLAN_OF_ACTION = 'Block Plan Of Action'; export const BLOCK_MASTER_PLAN_OF_ACTION_FIELD = 'blockPlanOfAction'; export const BLOCK_MASTER_PLAN_OF_ACTION_ALIGN = 'Left';
export const COLOR_NAME = 'Primary Color'; export const COLOR_NAME_FIELD = 'blockHeadingColor'; export const COLOR_NAME_ALIGN = 'Left';
export const BLOCK_MASTER_ACTIVE = 'Status'; export const BLOCK_MASTER_ACTIVE_FIELD = 'isActive'; export const BLOCK_MASTER_ACTIVE_ALIGN = 'Left';


/**
 * 
 *Author: Noel
 *Date 17-03-2023
 *For HR Team Member Report
 */


export const HR_TYPE = 'Type'; export const HR_TYPE_FIELD = 'Type'; export const HR_TYPE_ALIGN = 'Left';
export const HR_SUB_TYPE = 'Sub Type'; export const HR_SUB_TYPE_FIELD = 'subType'; export const HR_SUB_TYPE_ALIGN = 'Left';
export const HR_EMP_COUNT = 'No. of Employees'; export const HR_EMP_COUNT_FIELD = 'userCnt'; export const HR_EMP_COUNT_ALIGN = 'Center';
export const HR_EMP_TOTAL = 'Total Type Taken'; export const HR_EMP_TOTAL_FIELD = 'count'; export const HR_EMP_TOTAL_ALIGN = 'Center';

export const LEAVE_BALANCE_TYPE = "Leave Type"; export const LEAVE_BALANCE_TYPE_WIDTH = 70; export const LEAVE_BALANCE_TYPE_FIELD = 'leaveSymbol'; export const LEAVE_BALANCE_TYPE_FIELD_FOR_REPORT = 'leaveSymbolfull'; export const LEAVE_BALANCE_TYPE_ALIGN = 'Center';
export const LEAVE_BALANCE_FROM_DATE = "From Date"; export const LEAVE_BALANCE_FROM_DATE_WIDTH = 60; export const LEAVE_BALANCE_FROM_DATE_FIELD = 'fromDate'; export const LEAVE_BALANCE_FROM_DATE_ALIGN = 'Center';
export const LEAVE_BALANCE_TO_DATE = "To Date"; export const LEAVE_BALANCE_TO_DATE_WIDTH = 60; export const LEAVE_BALANCE_TO_DATE_FIELD = 'toDate'; export const LEAVE_BALANCE_TO_DATE_ALIGN = 'Center';
export const LEAVE_BALANCE_NO_OF_DAYS = "No. of Leaves"; export const LEAVE_BALANCE_NO_OF_DAYS_WIDTH = 50; export const LEAVE_BALANCE_NO_OF_DAYS_FIELD = 'noOfLeaves'; export const LEAVE_BALANCE_NO_OF_DAYS_ALIGN = 'Center';
export const LEAVE_BALANCE_APPLICATION_STATUS = "Status"; export const LEAVE_BALANCE_APPLICATION_STATUS_WIDTH = 100; export const LEAVE_BALANCE_APPLICATION_STATUS_FIELD = 'applicationStatus'; export const LEAVE_BALANCE_APPLICATION_STATUS_ALIGN = 'Center';
export const LEAVE_BALANCE_APPROVED_BY = "Actioned By"; export const LEAVE_BALANCE_APPROVED_BY_WIDTH = 70; export const LEAVE_BALANCE_APPROVED_BY_FIELD = 'approvedBy'; export const LEAVE_BALANCE_APPROVED_BY_ALIGN = 'LEFT';
export const LEAVE_BALANCE_APPROVED_ON = "Actioned On"; export const LEAVE_BALANCE_APPROVED_ON_WIDTH = 70; export const LEAVE_BALANCE_APPROVED_ON_FIELD = 'approvedOn'; export const LEAVE_BALANCE_APPROVED_ON_ALIGN = 'Center';

// Attendance Lock
export const LOCK_UNLOCK_ATTENDANCE_MONTH = "Attendance Month"; export const LOCK_UNLOCK_ATTENDANCE_MONTH_WIDTH = 70; export const LOCK_UNLOCK_ATTENDANCE_MONTH_FIELD = 'attendanceMonth'; export const LOCK_UNLOCK_ATTENDANCE_MONTH_ALIGN = 'Center';
export const LOCK_UNLOCK_LOCK_START_DATE = "Lock Start Date"; export const LOCK_UNLOCK_LOCK_START_DATE_WIDTH = 70; export const LOCK_UNLOCK_LOCK_START_DATE_FIELD = 'lockStartDate'; export const LOCK_UNLOCK_LOCK_START_DATE_ALIGN = 'Center';
export const LOCK_UNLOCK_LOCK_END_DATE = "Lock End Date"; export const LOCK_UNLOCK_LOCK_END_DATE_WIDTH = 70; export const LOCK_UNLOCK_LOCK_END_DATE_FIELD = 'lockEndDate'; export const LOCK_UNLOCK_LOCK_END_DATE_ALIGN = 'Center';
export const LOCK_UNLOCK_ACTIVE = "Status"; export const LOCK_UNLOCK_ACTIVE_WIDTH = 70; export const LOCK_UNLOCK_ACTIVE_FIELD = 'isActive'; export const LOCK_UNLOCK_ACTIVE_ALIGN = 'Center';
export const LEAVE_YEAR_TITLE = "Title"; export const LEAVE_YEAR_TITLE_WIDTH = 70; export const LEAVE_YEAR_TITLE_FIELD = 'title'; export const LEAVE_YEAR_TITLE_ALIGN = 'Center';
export const LEAVE_YEAR_START_DATE = "Leave Year Start Date"; export const LEAVE_YEAR_END_DATE = "Leave Year End Date";

// HelpDesk -My Tixcket
export const TICKET_UPDATE = "Add Update"; export const TICKET_UPDATE_WIDTH = '100'; export const TICKET_UPDATE_ALIGN = "Center";
export const HELPDESK_TICKET_NO = 'SR. No.'; export const HELPDESK_TICKET_NO_WIDTH = '150'; export const HELPDESK_TICKET_NO_FIELD = 'IssueID'; export const HELPDESK_TICKET_NO_ALIGN = 'Center';
export const HELPDESK_CREATED_DATE_TIME = 'Created Date & Time'; export const HELPDESK_CREATED_DATE_TIME_WIDTH = '240'; export const HELPDESK_CREATED_DATE_TIME_FIELD = 'IssueDate'; export const HELPDESK_CREATED_DATE_TIME_ALIGN = 'Center';
export const HELPDESK_AGING = 'Aging'; export const HELPDESK_AGING_WIDTH = '150'; export const HELPDESK_AGING_FIELD = 'Age'; export const HELPDESK_AGING_ALIGN = 'Left';
export const HELPDESK_FUNCTION = 'Function'; export const HELPDESK_FUNCTION_WIDTH = '250'; export const HELPDESK_FUNCTION_FIELD = 'Function'; export const HELPDESK_FUNCTION_ALIGN = 'Left';
export const HELPDESK_CATEGORY = 'Category'; export const HELPDESK_CATEGORY_WIDTH = '250'; export const HELPDESK_CATEGORY_FIELD = 'Category'; export const HELPDESK_CATEGORY_ALIGN = 'Left';
export const HELPDESK_SUB_CATEGORY = 'Sub Category'; export const HELPDESK_SUB_CATEGORY_WIDTH = '250'; export const HELPDESK_SUB_CATEGORY_FIELD = helpdeskCustomFields['SubCategoryName']; export const HELPDESK_SUB_CATEGORY_ALIGN = 'Left';
export const HELPDESK_STATUS = 'Status'; export const HELPDESK_STATUS_WIDTH = '150'; export const HELPDESK_STATUS_FIELD = 'Status'; export const HELPDESK_STATUS_ALIGN = 'Center';
export const HELPDESK_PRIORITY = 'Priority'; export const HELPDESK_PRIORITY_WIDTH = '120'; export const HELPDESK_PRIORITY_FIELD = 'Priority'; export const HELPDESK_PRIORITY_ALIGN = 'Left';
export const HELPDESK_SUBJECT = 'Subject'; export const HELPDESK_SUBJECT_WIDTH = '300'; export const HELPDESK_SUBJECT_FIELD = 'Subject'; export const HELPDESK_SUBJECT_ALIGN = 'Left';
export const HELPDESK_TYPE = 'Type'; export const HELPDESK_TYPE_WIDTH = '80'; export const HELPDESK_TYPE_FIELD = 'type'; export const HELPDESK_TYPE_ALIGN = 'Left';
export const HELPDESK_ASSIGNED_BY = 'Assigned By'; export const HELPDESK_ASSIGNED_BY_WIDTH = '150'; export const HELPDESK_ASSIGNED_BY_FIELD = 'assignedBy'; export const HELPDESK_ASSIGNED_BY_ALIGN = 'Left';
export const HELPDESK_ASSIGNED_TO = 'Assigned To'; export const HELPDESK_ASSIGNED_TO_WIDTH = '150'; export const HELPDESK_ASSIGNED_TO_FIELD = 'AssignedTo'; export const HELPDESK_ASSIGNED_TO_ALIGN = 'Left';
export const HELPDESK_ASSIGNED_DATE_TIME = 'Assigned Date & Time'; export const HELPDESK_ASSIGNED_DATE_TIME_WIDTH = '200'; export const HELPDESK_ASSIGNED_DATE_TIME_FIELD = 'assignedDate'; export const HELPDESK_ASSIGNED_DATE_TIME_ALIGN = 'Center';
export const HELPDESK_COMMENTS = 'Latest Comments'; export const HELPDESK_COMMENTS_WIDTH = '300'; export const HELPDESK_COMMENTS_FIELD = 'comments'; export const HELPDESK_COMMENTS_ALIGN = 'Left';
export const HELPDESK_RESOLVED_BY = 'Resolved By'; export const HELPDESK_RESOLVED_BY_WIDTH = '150'; export const HELPDESK_RESOLVED_BY_FIELD = 'resolvedBy'; export const HELPDESK_RESOLVED_BY_ALIGN = 'Left';
export const HELPDESK_RESOLVED_DATE_TIME = 'Resolved Date & Time'; export const HELPDESK_RESOLVED_DATE_TIME_WIDTH = '240'; export const HELPDESK_RESOLVED_DATE_TIME_FIELD = 'ResolvedDate'; export const HELPDESK_RESOLVED_DATE_TIME_ALIGN = 'Center';

//Helpdesk Admin Sub Category
export const SUB_CAT_NAME = "Sub Category Name"; export const SUB_CAT_NAME_WIDTH = 300; export const SUB_CAT_NAME_FIELD = 'SubCategoryName'
export const CUSTOM_SUB_CAT_NAME = "Custom Sub Category"; export const CUSTOM_SUB_CAT_NAME_WIDTH = 300; export const CUSTOM_SUB_CAT_NAME_FIELD = 'CustomSubCategory'
export const CAT_NAME = "Category Name"; export const CAT_NAME_WIDTH = 300; export const CAT_NAME_FIELD = 'CategoryName';
export const SEC_NAME = "Function Name"; export const SEC_NAME_WIDTH = 300; export const SEC_NAME_FIELD = 'SectionName';
export const SUB_CAT_DISABLED = "Status"; export const SUB_CAT_DISABLED_WIDTH = 100; export const SUB_CAT_DISABLED_FIELD = 'Disabled'

// Regularization
export const REGULARIZATION_DATE = 'Date'; export const REGULARIZATION_DATE_WIDTH = 100; export const REGULARIZATION_DATE_FIELD = 'date'; export const REGULARIZATION_DATE_ALIGN = 'Left';
export const REGULARIZATION_ATTENDANCE = 'Attendance'; export const REGULARIZATION_ATTENDANCE_WIDTH = 60; export const REGULARIZATION_ATTENDANCE_FIELD = 'attendanceType'; export const REGULARIZATION_ATTENDANCE_ALIGN = 'Left';
export const REGULARIZATION_TIBR = 'TIBR'; export const REGULARIZATION_TIBR_WIDTH = 50; export const REGULARIZATION_TIBR_FIELD = 'timeInBusiness'; export const REGULARIZATION_TIBR_ALIGN = 'Center';
export const REGULARIZATION_ATTENDANCE_CHANGED_TO = 'Attendance Changed To'; export const REGULARIZATION_ATTENDANCE_CHANGED_TO_WIDTH = 160; export const REGULARIZATION_ATTENDANCE_CHANGED_TO_FIELD = 'attendanceChangedTo'; export const REGULARIZATION_ATTENDANCE_CHANGED_TO_ALIGN = 'Center';

//Email Mapping Master
export const USER_NAME = "Team Member"; export const USER_NAME_WIDTH = 100; export const USER_NAME_FIELD = 'userName'; export const USER_NAME_ALIGN = 'Left'
export const NOTIFY_USER_NAME = "Notified Team Member"; export const NOTIFY_USER_NAME_WIDTH = 300; export const NOTIFY_USER_NAME_FIELD = 'notifyUserName'; export const NOTIFY_USER_NAME_ALIGN = 'Left';
export const APPLICATION_NAME = "Module Name"; export const APPLICATION_NAME_WIDTH = 100; export const APPLICATION_NAME_FIELD = 'applicationName'; export const APPLICATION_NAME_ALIGN = 'Left';

//Leave report new
export const LEAVE_TYPE = 'Leave Type';
export const LEAVE_TYPE_WIDTH = 250;
export const LEAVE_TYPE_ALIGN = 'Left'
export const LEAVE_TYPE_FIELD = 'leaveName'

export const FROM_DATE = 'Leave Date';
export const FROM_DATE_WIDTH = 200;
export const FROM_DATE_ALIGN = 'Left';
export const FROM_DATE_FIELD = 'leaveStartDate';

export const TO_DATE = 'To Date';
export const TO_DATE_WIDTH = 200;
export const TO_DATE_ALIGN = 'Left';
export const TO_DATE_FIELD = 'endDate';

export const NO_OF_LEAVES = 'No of Leaves';
export const NO_OF_LEAVES_WIDTH = 250;
export const NO_OF_LEAVES_ALIGN = 'Center';
export const NO_OF_LEAVES_FIELD = 'noOfDaysOnLeave';

export const NAME = 'Name';
export const NAME_WIDTH = 280;
export const NAME_ALIGN = 'Left';
export const NAME_FIELD = 'userName';

export const PRACTICE_AREA = 'Practice Area/ Sales Unit/ Sub Function';
export const PRACTICE_AREA_WIDTH = 350;
export const PRACTICE_AREA_ALIGN = 'Left';
export const PRACTICE_AREA_FIELD = 'practiceArea';

export const ORDER = 'Order';
export const ORDER_WIDTH = 150;
export const ORDER_ALIGN = 'Left';
export const ORDER_FIELD = 'relation';

export const HOLIDAY_NAME = 'Holiday Name';
export const HOLIDAY_NAME_WIDTH = 250;
export const HOLIDAY_NAME_ALIGN = 'Left';
export const HOLIDAY_NAME_FIELD = 'holidayName';

export const getGridColumn = (pType) => { // NOSONAR
    switch (pType) { // NOSONAR
        case 'Ready To Join':
            return (
                [
                    { colName: CANDIDATE_NAME, width: CANDIDATE_NAME_WIDTH, field: CANDIDATE_NAME_FIELD, align: CANDIDATE_NAME_ALIGN },
                    { colName: READY_TO_JOIN_EMPLOYEE_CODE, width: READY_TO_JOIN_EMPLOYEE_CODE_WIDTH, field: READY_TO_JOIN_EMPLOYEE_CODE_FIELD, align: READY_TO_JOIN_EMPLOYEE_CODE_ALIGN },
                    { colName: CANDIDATE_STATUS, width: CANDIDATE_STATUS_WIDTH, field: CANDIDATE_STATUS_FIELD, align: CANDIDATE_STATUS_ALIGN },
                    { colName: ADMIN, width: ADMIN_WIDTH, field: ADMIN_FIELD, align: ADMIN_ALIGN },
                    { colName: IT, width: IT_WIDTH, field: IT_FIELD, align: IT_ALIGN },
                    { colName: PEOPLE_TEAM, width: PEOPLE_TEAM_WIDTH, field: PEOPLE_TEAM_FIELD, align: PEOPLE_TEAM_ALIGN },
                    { colName: FINANCE, width: FINANCE_WIDTH, field: FINANCE_FIELD, align: FINANCE_ALIGN },
                    { colName: SOFTWARE, width: SOFTWARE_WIDTH, field: SOFTWARE_FIELD, align: SOFTWARE_ALIGN },
                    { colName: DATE_OF_JOINING, width: DATE_OF_JOINING_WIDTH, field: DATE_OF_JOINING_FIELD, align: DATE_OF_JOINING_ALIGN },
                    { colName: DAYS_TO_JOIN, width: DAYS_TO_JOIN_WIDTH, field: DAYS_TO_JOIN_FIELD, align: DAYS_TO_JOIN_ALIGN },
                    { colName: BASE_COUNTRY, width: BASE_COUNTRY_WIDTH, field: BASE_COUNTRY_FIELD, align: BASE_COUNTRY_ALIGN },
                    { colName: BASE_LOCATION, width: BASE_LOCATION_WIDTH, field: BASE_LOCATION_FIELD, align: BASE_LOCATION_ALIGN },
                    { colName: BASE_FUNCTION, width: BASE_FUNCTION_WIDTH, field: BASE_FUNCTION_FIELD, align: BASE_FUNCTION_ALIGN },
                    { colName: SERVICE_DIVISION_SALES_UNIT, width: SERVICE_DIVISION_SALES_UNIT_WIDTH, field: SERVICE_DIVISION_SALES_UNIT_FIELD, align: SERVICE_DIVISION_SALES_UNIT_ALIGN },
                    { colName: REPORTING_SUPERVISOR, width: REPORTING_SUPERVISOR_WIDTH, field: REPORTING_SUPERVISOR_FIELD, align: REPORTING_SUPERVISOR_ALIGN },
                    { colName: EMPLOYEE_TYPE, width: EMPLOYEE_TYPE_WIDTH, field: EMPLOYEE_TYPE_FIELD, align: EMPLOYEE_TYPE_ALIGN },
                    { colName: RECRUITMENT_TO, width: RECRUITMENT_TO_WIDTH, field: RECRUITMENT_TO_FIELD, align: RECRUITMENT_TO_ALIGN }
                ]
            );
        case 'To Be Offered':
            return (
                [
                    // { colName: TO_BE_CANDIDATE_NAME, width: TO_BE_CANDIDATE_NAME_WIDTH, field: TO_BE_CANDIDATE_NAME_FIELD, align: TO_BE_CANDIDATE_NAME_ALIGN },
                    // { colName: TO_BE_OFFER_DATE, width: TO_BE_OFFER_DATE_WIDTH, field: TO_BE_OFFER_DATE_FIELD, align: TO_BE_OFFER_DATE_ALIGN },
                    // { colName: TO_BE_DATE_OF_JOINING, width: TO_BE_DATE_OF_JOINING_WIDTH, field: TO_BE_DATE_OF_JOINING_FIELD, align: TO_BE_DATE_OF_JOINING_ALIGN },
                    // { colName: TO_BE_DAYS_TO_JOIN, width: TO_BE_DAYS_TO_JOIN_WIDTH, field: TO_BE_DAYS_TO_JOIN_FIELD, align: TO_BE_DAYS_TO_JOIN_ALIGN },
                    { colName: CANDIDATE_NAME, width: CANDIDATE_NAME_WIDTH, field: CANDIDATE_NAME_FIELD, align: CANDIDATE_NAME_ALIGN },
                    { colName: OFFER_DATE, width: OFFER_DATE_WIDTH, field: OFFER_DATE_FIELD, align: OFFER_DATE_ALIGN },
                    { colName: DOC_STATUS, width: DOC_STATUS_WIDTH, field: DOC_STATUS_FIELD, align: DOC_STATUS_ALIGN },
                    { colName: DOC_APPROVAL_STATUS, width: DOC_APPROVAL_STATUS_WIDTH, field: DOC_APPROVAL_STATUS_FIELD, align: DOC_APPROVAL_STATUS_ALIGN },
                    { colName: DATE_OF_JOINING, width: DATE_OF_JOINING_WIDTH, field: DATE_OF_JOINING_FIELD, align: DATE_OF_JOINING_ALIGN },
                    { colName: DAYS_TO_JOIN, width: DAYS_TO_JOIN_WIDTH, field: DAYS_TO_JOIN_FIELD, align: DAYS_TO_JOIN_ALIGN },
                    // { colName: BAND, width: BAND_WIDTH, field: BAND_FIELD, align: BAND_ALIGN },
                    { colName: BASE_COUNTRY, width: BASE_COUNTRY_WIDTH, field: BASE_COUNTRY_FIELD, align: BASE_COUNTRY_ALIGN },
                    { colName: BASE_LOCATION, width: BASE_LOCATION_WIDTH, field: BASE_LOCATION_FIELD, align: BASE_LOCATION_ALIGN },
                    { colName: BASE_FUNCTION_TO_BE_OFFER, width: BASE_FUNCTION_TO_BE_OFFER_WIDTH, field: BASE_FUNCTION_TO_BE_OFFER_FIELD, align: BASE_FUNCTION_TO_BE_OFFER_ALIGN },
                    { colName: SERVICE_DIVISION_SALES_UNIT, width: SERVICE_DIVISION_SALES_UNIT_WIDTH, field: SERVICE_DIVISION_SALES_UNIT_FIELD, align: SERVICE_DIVISION_SALES_UNIT_ALIGN },
                    { colName: REPORTING_SUPERVISOR, width: REPORTING_SUPERVISOR_WIDTH, field: REPORTING_SUPERVISOR_FIELD, align: REPORTING_SUPERVISOR_ALIGN },
                    { colName: EMPLOYEE_TYPE, width: EMPLOYEE_TYPE_WIDTH, field: EMPLOYEE_TYPE_FIELD, align: EMPLOYEE_TYPE_ALIGN },
                    { colName: RECRUITMENT_TO, width: RECRUITMENT_TO_WIDTH, field: RECRUITMENT_TO_FIELD, align: RECRUITMENT_TO_ALIGN },
                    { colName: CURRENCYNAME, width: CURRENCYNAME_WIDTH, field: CURRENCYNAME_FIELD, align: CURRENCYNAME_ALIGN },
                    { colName: ON_TARGET_INCENTIVE, width: ON_TARGET_INCENTIVE_WIDTH, field: ON_TARGET_INCENTIVE_FIELD, align: ON_TARGET_INCENTIVE_ALIGN },
                    { colName: FIXED_CTC, width: FIXED_CTC_WIDTH, field: FIXED_CTC_FIELD, align: FIXED_CTC_ALIGN },
                    { colName: SOURCE_CATEGORY, width: SOURCE_CATEGORY_WIDTH, field: SOURCE_CATEGORY_FIELD, align: SOURCE_CATEGORY_ALIGN },
                    { colName: SOURCE_DETAILS, width: SOURCE_DETAILS_WIDTH, field: SOURCE_DETAILS_FIELD, align: SOURCE_DETAILS_ALIGN },
                    { colName: EXTERNAL_SOURCE, width: EXTERNAL_SOURCE_WIDTH, field: EXTERNAL_SOURCE_FIELD, align: EXTERNAL_SOURCE_ALIGN },


                    // { colName: EMPLOYEE_TYPE, width: EMPLOYEE_TYPE_WIDTH, field: EMPLOYEE_TYPE_FIELD, align: EMPLOYEE_TYPE_ALIGN },
                    // { colName: EMPLOYEE_TYPE, width: EMPLOYEE_TYPE_WIDTH, field: EMPLOYEE_TYPE_FIELD, align: EMPLOYEE_TYPE_ALIGN }
                ]
            );
        case 'Revoke':
            return (
                [
                    { colName: CANDIDATE_NAME, width: CANDIDATE_NAME_WIDTH, field: CANDIDATE_NAME_FIELD, align: CANDIDATE_NAME_ALIGN },
                    { colName: CANDIDATE_STATUS, width: CANDIDATE_STATUS_WIDTH, field: CANDIDATE_STATUS_FIELD, align: CANDIDATE_STATUS_ALIGN },
                    { colName: STAGE, width: STAGE_WIDTH, field: STAGE_FIELD, align: STAGE_ALIGN },
                    { colName: ADMIN, width: ADMIN_WIDTH, field: ADMIN_FIELD, align: ADMIN_ALIGN },
                    { colName: IT, width: IT_WIDTH, field: IT_FIELD, align: IT_ALIGN },
                    { colName: PEOPLE_TEAM, width: PEOPLE_TEAM_WIDTH, field: PEOPLE_TEAM_FIELD, align: PEOPLE_TEAM_ALIGN },
                    { colName: FINANCE, width: FINANCE_WIDTH, field: FINANCE_FIELD, align: FINANCE_ALIGN },
                    { colName: SOFTWARE, width: SOFTWARE_WIDTH, field: SOFTWARE_FIELD, align: SOFTWARE_ALIGN },
                    { colName: REVOKE_REASON, width: REVOKE_REASON_WIDTH, field: REVOKE_REASON_FIELD, align: REVOKE_REASON_ALIGN },
                    { colName: REVOKE_COMMENTS, width: REVOKE_COMMENTS_WIDTH, field: REVOKE_COMMENTS_FIELD, align: REVOKE_COMMENTS_ALIGN },
                    { colName: DATE_OF_JOINING, width: DATE_OF_JOINING_WIDTH, field: DATE_OF_JOINING_FIELD, align: DATE_OF_JOINING_ALIGN },
                    { colName: REVOKE_DATE, width: REVOKE_DATE_WIDTH, field: REVOKE_DATE_FIELD, align: REVOKE_DATE_ALIGN },
                    // { colName: BAND, width: BAND_WIDTH, field: BAND_FIELD, align: BAND_ALIGN },
                    { colName: BASE_COUNTRY, width: BASE_COUNTRY_WIDTH, field: BASE_COUNTRY_FIELD, align: BASE_COUNTRY_ALIGN },
                    { colName: BASE_LOCATION, width: BASE_LOCATION_WIDTH, field: BASE_LOCATION_FIELD, align: BASE_LOCATION_ALIGN },
                    { colName: BASE_FUNCTION, width: BASE_FUNCTION_WIDTH, field: BASE_FUNCTION_FIELD, align: BASE_FUNCTION_ALIGN },
                    { colName: SERVICE_DIVISION_SALES_UNIT, width: SERVICE_DIVISION_SALES_UNIT_WIDTH, field: SERVICE_DIVISION_SALES_UNIT_FIELD, align: SERVICE_DIVISION_SALES_UNIT_ALIGN },
                    { colName: REPORTING_SUPERVISOR, width: REPORTING_SUPERVISOR_WIDTH, field: REPORTING_SUPERVISOR_FIELD, align: REPORTING_SUPERVISOR_ALIGN },
                    { colName: EMPLOYEE_TYPE, width: EMPLOYEE_TYPE_WIDTH, field: EMPLOYEE_TYPE_FIELD, align: EMPLOYEE_TYPE_ALIGN }
                ]
            );
        case 'Due Confirm':
            return (
                [
                    { colName: EMPLOYEE_NAME, width: EMPLOYEE_NAME_WIDTH, field: EMPLOYEE_NAME_FIELD, align: EMPLOYEE_NAME_ALIGN },
                    { colName: SERVICE_DIVISION_SALES_UNIT, width: SERVICE_DIVISION_SALES_UNIT_WIDTH, field: SERVICE_DIVISION_SALES_UNIT_FIELD, align: SERVICE_DIVISION_SALES_UNIT_ALIGN },
                    { colName: PnL_FUNCTION, width: PL_FUNCTION_WIDTH, field: PnL_FUNCTION_FIELD, align: PnL_FUNCTION_ALIGN },
                    { colName: REPORTING_SUPERVISOR, width: REPORTING_SUPERVISOR_WIDTH, field: REPORTING_SUPERVISOR_FIELD, align: REPORTING_SUPERVISOR_ALIGN },
                    { colName: DATE_OF_JOINING, width: DATE_OF_JOINING_WIDTH, field: DATE_OF_JOINING_FIELD, align: DATE_OF_JOINING_ALIGN },
                    { colName: DUE_CONFIRMED_ON, width: DUE_CONFIRMED_ON_WIDTH, field: DUE_CONFIRMED_ON_FIELD, align: DUE_CONFIRMED_ON_ALIGN },
                    { colName: EMPLOYEE_STATUS, width: EMPLOYEE_STATUS_WIDTH, field: EMPLOYEE_STATUS_FIELD, align: EMPLOYEE_STATUS_ALIGN },
                    { colName: EMPLOYEE_ACTION, width: EMPLOYEE_ACTION_WIDTH, field: EMPLOYEE_ACTION_FIELD, align: EMPLOYEE_ACTION_ALIGN },
                    { colName: DUE_CONFIRM_REASON, width: DUE_CONFIRM_REASON_WIDTH, field: DUE_CONFIRM_REASON_FIELD, align: DUE_CONFIRM_REASON_ALIGN },
                    { colName: EMPLOYEE_TYPE, width: EMPLOYEE_TYPE_WIDTH, field: EMPLOYEE_TYPE_FIELD, align: EMPLOYEE_TYPE_ALIGN },
                    { colName: BASE_COUNTRY, width: BASE_COUNTRY_WIDTH, field: BASE_COUNTRY_FIELD, align: BASE_COUNTRY_ALIGN },
                ]
            );
        case 'Confirm':
            return (
                [
                    { colName: EMPLOYEE_NAME, width: EMPLOYEE_NAME_WIDTH, field: EMPLOYEE_NAME_FIELD, align: EMPLOYEE_NAME_ALIGN },
                    { colName: SERVICE_DIVISION_SALES_UNIT, width: SERVICE_DIVISION_SALES_UNIT_WIDTH, field: SERVICE_DIVISION_SALES_UNIT_FIELD, align: SERVICE_DIVISION_SALES_UNIT_ALIGN },
                    { colName: PnL_FUNCTION, width: PL_FUNCTION_WIDTH, field: PnL_FUNCTION_FIELD, align: PnL_FUNCTION_ALIGN },
                    { colName: REPORTING_SUPERVISOR, width: REPORTING_SUPERVISOR_WIDTH, field: REPORTING_SUPERVISOR_FIELD, align: REPORTING_SUPERVISOR_ALIGN },
                    { colName: DATE_OF_JOINING, width: DATE_OF_JOINING_WIDTH, field: DATE_OF_JOINING_FIELD, align: DATE_OF_JOINING_ALIGN },
                    { colName: CONFIRMED_ON, width: CONFIRMED_ON_WIDTH, field: CONFIRMED_ON_FIELD, align: CONFIRMED_ON_ALIGN },
                    { colName: CONFIRM_PREVIEW, width: CONFIRM_PREVIEW_WIDTH, field: CONFIRM_PREVIEW_FIELD, align: CONFIRM_PREVIEW_ALIGN },
                    { colName: BAND, width: BAND_WIDTH, field: BAND_FIELD, align: BAND_ALIGN },
                    { colName: EMPLOYEE_TYPE, width: EMPLOYEE_TYPE_WIDTH, field: EMPLOYEE_TYPE_FIELD, align: EMPLOYEE_TYPE_ALIGN },
                    { colName: BASE_COUNTRY, width: BASE_COUNTRY_WIDTH, field: BASE_COUNTRY_FIELD, align: BASE_COUNTRY_ALIGN },
                ]
            );
        case 'InitiatePreJoining':
            return (
                [
                    { colName: FUNCTION_CANDIDATE_NAME, width: FUNCTION_CANDIDATE_NAME_WIDTH, field: FUNCTION_CANDIDATE_NAME_FIELD, align: FUNCTION_CANDIDATE_NAME_ALIGN },
                    { colName: SERVICE_DIVISION_SALES_UNIT, width: SERVICE_DIVISION_SALES_UNIT_FUN_WIDTH, field: SERVICE_DIVISION_SALES_UNIT_FIELD, align: SERVICE_DIVISION_SALES_UNIT_ALIGN },
                    { colName: REPORTING_SUPERVISOR, width: REPORTING_SUPERVISOR_WIDTH, field: REPORTING_SUPERVISOR_FIELD, align: REPORTING_SUPERVISOR_ALIGN },
                    { colName: PnL_FUNCTION, width: PNL_Pre_FUNCTION_WIDTH, field: PnL_FUNCTION_FIELD, align: PnL_FUNCTION_ALIGN },
                    { colName: DATE_OF_JOINING, width: DATE_OF_JOINING_WIDTH, field: DATE_OF_JOINING_FIELD, align: DATE_OF_JOINING_ALIGN },
                    { colName: FUNCTION_INITIAON, width: FUNCTION_INITIAON_WIDTH, field: FUNCTION_INITIAON_FIELD, align: FUNCTION_INITIAON_ALIGN },
                    { colName: FUNCTION_STATUS, width: FUNCTION_STATUS_WIDTH, field: FUNCTION_STATUS_FIELD, align: FUNCTION_STATUS_ALIGN },
                    { colName: BAND, width: BAND_WIDTH, field: BAND_FIELD, align: BAND_ALIGN },
                    { colName: EMPLOYEE_TYPE, width: EMPLOYEE_TYPE_WIDTH, field: EMPLOYEE_TYPE_FIELD, align: EMPLOYEE_TYPE_ALIGN },
                    { colName: BASE_COUNTRY, width: BASE_COUNTRY_PRE_WIDTH, field: BASE_COUNTRY_FIELD, align: BASE_COUNTRY_ALIGN },
                ]
            );
        case 'resignation':
            return (
                [
                    { colName: ACTION, width: ACTION_WIDTH, field: ACTION_FIELD, align: ACTION_ALIGN },
                    { colName: SEP_EMPLOYEE_NAME, width: SEP_EMPLOYEE_NAME_WIDTH, field: SEP_EMPLOYEE_NAME_FIELD, align: SEP_EMPLOYEE_NAME_ALIGN },
                    { colName: DEPARTMENT, width: DEPARTMENT_WIDTH, field: DEPARTMENT_FIELD, align: DEPARTMENT_ALIGN },
                    { colName: REPORTING_TO, width: REPORTING_TO_WIDTH, field: REPORTING_TO_FIELD, align: REPORTING_TO_ALIGN },
                    { colName: LWD_OFFICIAL, width: LWD_OFFICIAL_WIDTH, field: LWD_OFFICIAL_FIELD, align: LWD_OFFICIAL_ALIGN },
                    { colName: SEP_NOTICE_PERIOD_STATUS, width: SEP_NOTICE_PERIOD_STATUS_WIDTH, field: SEP_NOTICE_PERIOD_STATUS_FIELD, align: SEP_NOTICE_PERIOD_STATUS_ALIGN },
                    { colName: REPORTING_SUPERVISOR_STATUS, width: REPORTING_SUPERVISOR_STATUS_WIDTH, field: REPORTING_SUPERVISOR_STATUS_FIELD, align: REPORTING_SUPERVISOR_STATUS_ALIGN },
                    { colName: PL_STATUS, width: PL_STATUS_WIDTH, field: PL_STATUS_FIELD, align: PL_STATUS_ALIGN },
                    { colName: HR_STATUS, width: HR_STATUS_WIDTH, field: HR_STATUS_FIELD, align: HR_STATUS_ALIGN },
                    { colName: DAYS_TO_LWD, width: DAYS_TO_LWD_WIDTH, field: DAYS_TO_LWD_FIELD, align: DAYS_TO_LWD_ALIGN },
                    { colName: DAYS_DIFFERENCE, width: DAYS_DIFFERENCE_WIDTH, field: DAYS_DIFFERENCE_FIELD, align: DAYS_DIFFERENCE_ALIGN },
                    { colName: DATE_OF_RESIGNATION, width: DATE_OF_RESIGNATION_WIDTH, field: DATE_OF_RESIGNATION_FIELD, align: DATE_OF_RESIGNATION_ALIGN },
                    { colName: LWD_COMPANY_REQUEST, width: LWD_COMPANY_REQUEST_WIDTH, field: LWD_COMPANY_REQUEST_FIELD, align: LWD_COMPANY_REQUEST_ALIGN },
                    { colName: LWD_EMPLOYEE_REQUESTED, width: LWD_EMPLOYEE_REQUESTED_WIDTH, field: LWD_EMPLOYEE_REQUESTED_FIELD, align: LWD_EMPLOYEE_REQUESTED_ALIGN },
                    { colName: NOTICE_PERIOD, width: NOTICE_PERIOD_WIDTH, field: NOTICE_PERIOD_FIELD, align: NOTICE_PERIOD_ALIGN },
                    { colName: REASON, width: REASON_WIDTH, field: REASON_FIELD, align: REASON_ALIGN },
                    { colName: EMP_REMARK, width: REMARK_WIDTH, field: REMARK_FIELD, align: REMARK_ALIGN },


                ]
            );
        case 'withdrawal':
            return (
                [
                    { colName: WITH_ACTION, width: WITH_ACTION_WIDTH, field: WITH_ACTION_FIELD, align: WITH_ACTION_ALIGN },
                    { colName: SEP_EMPLOYEE_NAME, width: SEP_EMPLOYEE_NAME_WIDTH, field: SEP_EMPLOYEE_NAME_FIELD, align: SEP_EMPLOYEE_NAME_ALIGN },
                    { colName: DEPARTMENT, width: DEPARTMENT_WIDTH, field: DEPARTMENT_FIELD, align: DEPARTMENT_ALIGN },
                    { colName: REPORTING_TO, width: REPORTING_TO_WIDTH, field: REPORTING_TO_FIELD, align: REPORTING_TO_ALIGN },
                    { colName: REPORTING_SUPERVISOR_STATUS, width: REPORTING_SUPERVISOR_STATUS_WIDTH, field: REPORTING_SUPERVISOR_STATUS_FIELD, align: REPORTING_SUPERVISOR_STATUS_ALIGN },
                    { colName: PL_STATUS, width: PL_STATUS_WIDTH, field: PL_STATUS_FIELD, align: PL_STATUS_ALIGN },
                    { colName: HR_STATUS, width: HR_STATUS_WIDTH, field: HR_STATUS_FIELD, align: HR_STATUS_ALIGN },
                    { colName: DAYS_TO_LWD, width: DAYS_TO_LWD_WIDTH, field: DAYS_TO_LWD_FIELD, align: DAYS_TO_LWD_ALIGN },
                    { colName: WITH_REASON, width: WITH_REASON_WIDTH, field: WITH_REASON_FIELD, align: WITH_REASON_ALIGN },
                    { colName: DATE_OF_WITHDRAWAL, width: DATE_OF_WITHDRAWAL_WIDTH, field: DATE_OF_WITHDRAWAL_FIELD, align: DATE_OF_WITHDRAWAL_ALIGN },
                    { colName: JOINING_DATE, width: JOINING_DATE_WIDTH, field: JOINING_DATE_FIELD, align: JOINING_DATE_ALIGN },
                    { colName: DATE_OF_RESIGNATION, width: DATE_OF_RESIGNATION_WIDTH, field: DATE_OF_RESIGNATION_FIELD, align: DATE_OF_RESIGNATION_ALIGN },
                    { colName: LWD_OFFICIAL, width: LWD_OFFICIAL_WIDTH, field: LWD_OFFICIAL_FIELD, align: LWD_OFFICIAL_ALIGN },
                    { colName: LWD_REQUESTED, width: LWD_REQUESTED_WIDTH, field: LWD_EMPLOYEE_REQUESTED_FIELD, align: LWD_REQUESTED_ALIGN },
                    { colName: EMP_REMARK, width: REMARK_WIDTH, field: REMARK_FIELD, align: REMARK_ALIGN },

                ]
            );
        case 'hrResignation':
            return (
                [
                    { colName: ACTION, width: ACTION_WIDTH, field: ACTION_FIELD, align: ACTION_ALIGN },
                    { colName: SEP_EMPLOYEE_NAME, width: SEP_EMPLOYEE_NAME_WIDTH, field: SEP_EMPLOYEE_NAME_FIELD, align: SEP_EMPLOYEE_NAME_ALIGN },
                    { colName: DEPARTMENT, width: DEPARTMENT_WIDTH, field: DEPARTMENT_FIELD, align: DEPARTMENT_ALIGN },
                    { colName: PNL, width: PNL_WIDTH, field: PNL_FIELD, align: PNL_ALIGN },
                    { colName: REPORTING_TO, width: REPORTING_TO_WIDTH, field: REPORTING_TO_FIELD, align: REPORTING_TO_ALIGN },
                    { colName: REPORTING_SUPERVISOR_STATUS, width: REPORTING_SUPERVISOR_STATUS_WIDTH, field: REPORTING_SUPERVISOR_STATUS_FIELD, align: REPORTING_SUPERVISOR_STATUS_ALIGN },
                    { colName: PL_STATUS, width: PL_STATUS_WIDTH, field: PL_STATUS_FIELD, align: PL_STATUS_ALIGN },
                    { colName: HR_STATUS, width: HR_STATUS_WIDTH, field: HR_STATUS_FIELD, align: HR_STATUS_ALIGN },
                    { colName: SEP_NOTICE_PERIOD_STATUS, width: SEP_NOTICE_PERIOD_STATUS_WIDTH, field: SEP_NOTICE_PERIOD_STATUS_FIELD, align: SEP_NOTICE_PERIOD_STATUS_ALIGN },
                    { colName: SEP_NOTICE_PERIOD, width: SEP_NOTICE_PERIOD_WIDTH, field: SEP_NOTICE_PERIOD_FIELD, align: SEP_NOTICE_PERIOD_ALIGN },
                    { colName: DAYS_TO_LWD, width: DAYS_TO_LWD_WIDTH, field: DAYS_TO_LWD_FIELD, align: DAYS_TO_LWD_ALIGN },
                    { colName: REASON, width: REASON_WIDTH, field: REASON_FIELD, align: REASON_ALIGN },
                    { colName: JOINING_DATE, width: JOINING_DATE_WIDTH, field: JOINING_DATE_FIELD, align: JOINING_DATE_ALIGN },
                    { colName: DATE_OF_RESIGNATION, width: DATE_OF_RESIGNATION_WIDTH, field: DATE_OF_RESIGNATION_FIELD, align: DATE_OF_RESIGNATION_ALIGN },
                    { colName: LWD_OFFICIAL, width: LWD_OFFICIAL_WIDTH, field: LWD_OFFICIAL_FIELD, align: LWD_OFFICIAL_ALIGN },
                    { colName: LWD_EMPLOYEE_REQUESTED, width: LWD_EMPLOYEE_REQUESTED_WIDTH, field: LWD_EMPLOYEE_REQUESTED_FIELD, align: LWD_EMPLOYEE_REQUESTED_ALIGN },
                    { colName: DAYS_DIFFERENCE, width: DAYS_DIFFERENCE_WIDTH, field: DAYS_DIFFERENCE_FIELD, align: DAYS_DIFFERENCE_ALIGN },
                ]
            );
        case 'HrWithdrawal':
            return (
                [
                    { colName: WITH_ACTION, width: WITH_ACTION_WIDTH, field: WITH_ACTION_FIELD, align: WITH_ACTION_ALIGN },
                    { colName: SEP_EMPLOYEE_NAME, width: SEP_EMPLOYEE_NAME_WIDTH, field: SEP_EMPLOYEE_NAME_FIELD, align: SEP_EMPLOYEE_NAME_ALIGN },
                    { colName: DEPARTMENT, width: DEPARTMENT_WIDTH, field: DEPARTMENT_FIELD, align: DEPARTMENT_ALIGN },
                    { colName: PNL, width: PNL_WIDTH, field: PNL_FIELD, align: PNL_ALIGN },
                    { colName: REPORTING_TO, width: REPORTING_TO_WIDTH, field: REPORTING_TO_FIELD, align: REPORTING_TO_ALIGN },
                    { colName: REPORTING_SUPERVISOR_STATUS, width: REPORTING_SUPERVISOR_STATUS_WIDTH, field: REPORTING_SUPERVISOR_STATUS_FIELD, align: REPORTING_SUPERVISOR_STATUS_ALIGN },
                    { colName: PL_STATUS, width: PL_STATUS_WIDTH, field: PL_STATUS_FIELD, align: PL_STATUS_ALIGN },
                    { colName: HR_STATUS, width: HR_STATUS_WIDTH, field: HR_STATUS_FIELD, align: HR_STATUS_ALIGN },
                    { colName: NOTICE_PERIOD, width: NOTICE_PERIOD_WIDTH, field: NOTICE_PERIOD_FIELD, align: NOTICE_PERIOD_ALIGN },
                    { colName: DAYS_TO_LWD, width: DAYS_TO_LWD_WIDTH, field: DAYS_TO_LWD_FIELD, align: DAYS_TO_LWD_ALIGN },
                    { colName: WITH_REASON, width: WITH_REASON_WIDTH, field: WITH_REASON_FIELD, align: WITH_REASON_ALIGN },
                    { colName: DATE_OF_WITHDRAWAL, width: DATE_OF_WITHDRAWAL_WIDTH, field: DATE_OF_WITHDRAWAL_FIELD, align: DATE_OF_WITHDRAWAL_ALIGN },
                    { colName: JOINING_DATE, width: JOINING_DATE_WIDTH, field: JOINING_DATE_FIELD, align: JOINING_DATE_ALIGN },
                    { colName: DATE_OF_RESIGNATION, width: DATE_OF_RESIGNATION_WIDTH, field: DATE_OF_RESIGNATION_FIELD, align: DATE_OF_RESIGNATION_ALIGN },
                    { colName: LWD_OFFICIAL, width: LWD_OFFICIAL_WIDTH, field: LWD_OFFICIAL_FIELD, align: LWD_OFFICIAL_ALIGN },
                    { colName: LWD_REQUESTED, width: LWD_REQUESTED_WIDTH, field: LWD_REQUESTED_FIELD, align: LWD_REQUESTED_ALIGN },
                ]
            );
        case 'HrClearance':
            return (
                [
                    { colName: SEP_EMPLOYEE_NAME, width: SEP_EMPLOYEE_NAME_WIDTH, field: SEP_EMPLOYEE_NAME_FIELD, align: SEP_EMPLOYEE_NAME_ALIGN },
                    { colName: CLEARANCE_STATUS, width: CLEARANCE_STATUS_WIDTH, field: CLEARANCE_STATUS_FIELD, align: CLEARANCE_STATUS_ALIGN },
                    { colName: DEPARTMENT, width: DEPARTMENT_WIDTH, field: DEPARTMENT_FIELD, align: DEPARTMENT_ALIGN },
                    { colName: PNL, width: PNL_WIDTH, field: PNL_FIELD, align: PNL_ALIGN },
                    { colName: REPORTING_TO, width: REPORTING_TO_WIDTH, field: REPORTING_TO_FIELD, align: REPORTING_TO_ALIGN },
                    { colName: JOINING_DATE, width: JOINING_DATE_WIDTH, field: JOINING_DATE_FIELD, align: JOINING_DATE_ALIGN },
                    { colName: DATE_OF_RESIGNATION, width: DATE_OF_RESIGNATION_WIDTH, field: DATE_OF_RESIGNATION_FIELD, align: DATE_OF_RESIGNATION_ALIGN },
                    { colName: LWD_OFFICIAL, width: LWD_OFFICIAL_WIDTH, field: LWD_OFFICIAL_FIELD, align: LWD_OFFICIAL_ALIGN },
                    { colName: REASON, width: REASON_WIDTH, field: REASON_FIELD, align: REASON_ALIGN },
                    { colName: CLEARANCE_EMPLOYEE_STATUS, width: CLEARANCE_EMPLOYEE_STATUS_WIDTH, field: CLEARANCE_EMPLOYEE_STATUS_FIELD, align: CLEARANCE_EMPLOYEE_STATUS_ALIGN },
                    { colName: SELF_DEPARTMENT, width: SELF_DEPARTMENT_WIDTH, field: SELF_DEPARTMENT_FIELD, align: SELF_DEPARTMENT_ALIGN },
                    { colName: ADMIN, width: ADMIN_WIDTH, field: ADMIN_FIELD_SEP, align: ADMIN_ALIGN },
                    { colName: IT, width: IT_WIDTH, field: IT_FIELD_SEP, align: IT_ALIGN },
                    { colName: FINANCE, width: FINANCE_WIDTH, field: FINANCE_FIELD_SEP, align: FINANCE_ALIGN },
                    { colName: INFOSEC, width: INFOSEC_WIDTH, field: INFOSEC_FIELD_SEP, align: INFOSEC_ALIGN },
                    { colName: SOFTWARE, width: SOFTWARE_WIDTH, field: SOFTWARE_FIELD_SEP, align: SOFTWARE_ALIGN },
                    { colName: HR, width: HR_WIDTH, field: HR_FIELD_SEP, align: HR_ALIGN },
                    { colName: LEGAL_ASSET, width: LEGAL_ASSET_WIDTH, field: LEGAL_ASSET_FIELD, align: LEGAL_ASSET_ALIGN },
                    { colName: MIS_ASSET, width: MIS_ASSET_WIDTH, field: MIS_ASSET_FIELD, align: MIS_ASSET_ALIGN },
                    { colName: CONTACT_ASSET, width: CONTACT_ASSET_WIDTH, field: CONTACT_ASSET_FIELD, align: CONTACT_ASSET_ALIGN },
                ]
            );
        case 'abscondingManager':
            return (
                [
                    { colName: ACTION, width: ACTION_WIDTH, field: ACTION_FIELD, align: ACTION_ALIGN },
                    // { colName: EMPLOYEE_NAME, width: EMPLOYEE_NAME_WIDTH, field: EMPLOYEE_NAME_FIELD, align: EMPLOYEE_NAME_ALIGN },
                    { colName: SEP_EMPLOYEE_NAME, width: SEP_EMPLOYEE_NAME_WIDTH, field: SEP_EMPLOYEE_NAME_FIELD, align: SEP_EMPLOYEE_NAME_ALIGN },
                    { colName: DEPARTMENT, width: DEPARTMENT_WIDTH, field: DEPARTMENT_FIELD, align: DEPARTMENT_ALIGN },
                    { colName: JOINING_DATE, width: JOINING_DATE_WIDTH, field: JOINING_DATE_FIELD, align: JOINING_DATE_ALIGN },
                    { colName: REPORTED_ON, width: REPORTED_ON_WIDTH, field: REPORTED_ON_FIELD, align: REPORTED_ON_ALIGN },
                    { colName: DATE_OF_ABSCONDING, width: DATE_OF_ABSCONDING_WIDTH, field: DATE_OF_ABSCONDING_FIELD, align: DATE_OF_ABSCONDING_ALIGN },
                    { colName: HR_STAGE, width: HR_STAGE_WIDTH, field: HR_STAGE_FIELD, align: HR_STAGE_ALIGN },
                    { colName: EMPLOYEE_STATUS_ABSCONDING, width: EMPLOYEE_STATUS_ABSCONDING_WIDTH, field: EMPLOYEE_STATUS_ABSCONDING_FIELD, align: EMPLOYEE_STATUS_ABSCONDING_ALIGN },
                    { colName: COMMENT, width: COMMENT_WIDTH, field: COMMENT_FIELD, align: COMMENT_ALIGN },
                    { colName: REMARK, width: REMARK_WIDTH, field: REMARK_FIELD, align: REMARK_ALIGN },
                ]
            );
        case 'abscondingHr':
            return (
                [
                    { colName: ACTION_HR, width: ACTION_HR_WIDTH, field: ACTION_HR_FIELD, align: ACTION_HR_ALIGN },
                    { colName: SEP_EMPLOYEE_NAME, width: SEP_EMPLOYEE_NAME_WIDTH, field: SEP_EMPLOYEE_NAME_FIELD, align: SEP_EMPLOYEE_NAME_ALIGN },
                    // { colName: EMPLOYEE_NAME, width: EMPLOYEE_NAME_WIDTH, field: EMPLOYEE_NAME_FIELD, align: EMPLOYEE_NAME_ALIGN },
                    { colName: DEPARTMENT, width: DEPARTMENT_WIDTH, field: DEPARTMENT_FIELD, align: DEPARTMENT_ALIGN },
                    { colName: JOINING_DATE, width: JOINING_DATE_WIDTH, field: JOINING_DATE_FIELD, align: JOINING_DATE_ALIGN },
                    { colName: REPORTED_ON, width: REPORTED_ON_WIDTH, field: REPORTED_ON_FIELD, align: REPORTED_ON_ALIGN },
                    { colName: DATE_OF_ABSCONDING, width: DATE_OF_ABSCONDING_WIDTH, field: DATE_OF_ABSCONDING_FIELD, align: DATE_OF_ABSCONDING_ALIGN },
                    { colName: EXPECTED_JOINING_DATE, width: EXPECTED_JOINING_DATE_WIDTH, field: EXPECTED_JOINING_DATE_FIELD, align: EXPECTED_JOINING_DATE_ALIGN },
                    { colName: EMPLOYEE_STATUS_ABSCONDING, width: EMPLOYEE_STATUS_ABSCONDING_WIDTH, field: EMPLOYEE_STATUS_ABSCONDING_FIELD, align: EMPLOYEE_STATUS_ABSCONDING_ALIGN },
                    { colName: COMUNICATION_STAGE, width: COMUNICATION_STAGE_WIDTH, field: COMUNICATION_STAGE_FIELD, align: COMUNICATION_STAGE_ALIGN },
                    { colName: LAST_COMMUNICATED_DATE, width: LAST_COMMUNICATED_DATE_WIDTH, field: LAST_COMMUNICATED_DATE_FIELD, align: LAST_COMMUNICATED_DATE_ALIGN },
                    { colName: HR_STAGE_HR, width: HR_STAGE_HR_WIDTH, field: HR_STAGE_HR_FIELD, align: HR_STAGE_HR_ALIGN },
                    { colName: COMMENT, width: COMMENT_WIDTH, field: COMMENT_FIELD, align: COMMENT_ALIGN },
                    { colName: REMARK, width: REMARK_WIDTH, field: REMARK_FIELD, align: REMARK_ALIGN },
                ]
            );
        case 'abscondingHrPreview':
            return (
                [
                    { colName: NO_OF_COMMUNICATION, width: NO_OF_COMMUNICATION_WIDTH, field: NO_OF_COMMUNICATION_FIELD, align: NO_OF_COMMUNICATION_ALIGN },
                    { colName: COMMUNICATED_BY, width: COMMUNICATED_BY_WIDTH, field: COMMUNICATED_BY_FIELD, align: COMMUNICATED_BY_ALIGN },
                    { colName: COMMUNICATED_DATE, width: COMMUNICATED_DATE_WIDTH, field: COMMUNICATED_DATE_FIELD, align: COMMUNICATED_DATE_ALIGN },
                    { colName: UPLOADED_FILE, width: UPLOADED_FILE_WIDTH, field: UPLOADED_FILE_FIELD, align: UPLOADED_FILE_ALIGN },
                ]
            );
        case 'terminationHrPreview':
            return (
                [
                    { colName: COMMITTEE_BY, width: COMMITTEE_BY_WIDTH, field: COMMITTEE_BY_FIELD, align: COMMITTEE_BY_ALIGN },
                    { colName: COMMITTEE_DATE, width: COMMITTEE_DATE_WIDTH, field: COMMITTEE_DATE_FIELD, align: COMMITTEE_DATE_ALIGN },
                    { colName: UPLOADED_FILE, width: UPLOADED_FILE_WIDTH, field: UPLOADED_FILE_FIELD, align: UPLOADED_FILE_ALIGN },
                ]
            );
        case 'terminationHr':
            return (
                [
                    { colName: ACTION, width: ACTION_WIDTH, field: ACTION_FIELD, align: ACTION_ALIGN },
                    { colName: SEP_EMPLOYEE_NAME, width: SEP_EMPLOYEE_NAME_WIDTH, field: SEP_EMPLOYEE_NAME_FIELD, align: SEP_EMPLOYEE_NAME_ALIGN },
                    { colName: DEPARTMENT, width: DEPARTMENT_WIDTH, field: DEPARTMENT_FIELD, align: DEPARTMENT_ALIGN },
                    { colName: PNL, width: PNL_WIDTH, field: PNL_FIELD, align: PNL_ALIGN },
                    { colName: REPORTING_TO, width: REPORTING_TO_WIDTH, field: REPORTING_TO_FIELD, align: REPORTING_TO_ALIGN },
                    { colName: FORM_COMMITTEE, width: FORM_COMMITTEE_WIDTH, field: FORM_COMMITTEE_FIELD, align: FORM_COMMITTEE_ALIGN },
                    { colName: COMMITTEE_ACTION, width: COMMITTEE_ACTION_WIDTH, field: COMMITTEE_ACTION_FIELD, align: COMMITTEE_ACTION_ALIGN },
                    { colName: COMMITTEE_REPORT_UPLOAD, width: COMMITTEE_REPORT_UPLOAD_WIDTH, field: COMMITTEE_REPORT_UPLOAD_FIELD, align: COMMITTEE_REPORT_UPLOAD_ALIGN },
                    { colName: SUSPENDED_STATUS, width: SUSPENDED_STATUS_WIDTH, field: SUSPENDED_STATUS_FIELD, align: SUSPENDED_STATUS_ALIGN },
                    { colName: SUSPENDED_DAYS, width: SUSPENDED_DAYS_WIDTH, field: SUSPENDED_DAYS_FIELD, align: SUSPENDED_DAYS_ALIGN },
                    { colName: JOINING_DATE, width: JOINING_DATE_WIDTH, field: JOINING_DATE_FIELD, align: JOINING_DATE_ALIGN },
                    { colName: REQUEST_ON, width: REQUEST_ON_WIDTH, field: REQUEST_ON_FIELD, align: REQUEST_ON_ALIGN },
                    { colName: LAST_WORKING_DATE, width: LAST_WORKING_DATE_WIDTH, field: LAST_WORKING_DATE_FIELD, align: LAST_WORKING_DATE_ALIGN },
                    { colName: GROUNDS, width: GROUNDS_WIDTH, field: GROUNDS_FIELD, align: GROUNDS_ALIGN },
                    { colName: REMARK, width: REMARK_WIDTH, field: REMARK_FIELD, align: REMARK_ALIGN },
                ]
            );
        case 'exitClearance':
            return (
                [
                    { colName: EXIT_CLEARANCE_ACTION, width: EXIT_CLEARANCE_ACTION_WIDTH, field: EXIT_CLEARANCE_ACTION_FIELD, align: EXIT_CLEARANCE_ACTION_ALIGN },
                    // { colName: EMPLOYEE_NAME, width: EMPLOYEE_NAME_WIDTH, field: EMPLOYEE_NAME_FIELD, align: EMPLOYEE_NAME_ALIGN },
                    { colName: SEP_EMPLOYEE_NAME, width: SEP_EMPLOYEE_NAME_WIDTH, field: SEP_EMPLOYEE_NAME_FIELD, align: SEP_EMPLOYEE_NAME_ALIGN },
                    { colName: EMPLOYEE_STATUS, width: EMPLOYEE_STATUS_WIDTH, field: EMPLOYEE_STATUS_FIELD_SEP, align: EMPLOYEE_STATUS_ALIGN },
                    { colName: DEPARTMENT, width: DEPARTMENT_WIDTH, field: DEPARTMENT_FIELD, align: DEPARTMENT_ALIGN },
                    { colName: PNL, width: PNL_WIDTH, field: PNL_FIELD, align: PNL_ALIGN },
                    { colName: JOINING_DATE, width: JOINING_DATE_WIDTH, field: JOINING_DATE_FIELD, align: JOINING_DATE_ALIGN },
                    { colName: DATE_OF_INITIATE, width: DATE_OF_INITIATE_WIDTH, field: DATE_OF_INITIATE_FIELD, align: DATE_OF_INITIATE_ALIGN },
                    { colName: LWD_COMPANY_REQUEST, width: LWD_COMPANY_REQUEST_WIDTH, field: LWD_COMPANY_REQUEST_FIELD, align: LWD_COMPANY_REQUEST_ALIGN },
                    { colName: CLEARANCE_EMPLOYEE_STATUS, width: CLEARANCE_EMPLOYEE_STATUS_WIDTH, field: CLEARANCE_EMPLOYEE_STATUS_FIELD, align: CLEARANCE_EMPLOYEE_STATUS_ALIGN },
                    // { colName: REASON, width: REASON_WIDTH, field: REASON_FIELD, align: REASON_ALIGN },
                    { colName: SELF_DEPARTMENT, width: SELF_DEPARTMENT_WIDTH, field: SELF_DEPARTMENT_FIELD, align: SELF_DEPARTMENT_ALIGN },
                    { colName: ADMIN, width: ADMIN_WIDTH, field: ADMIN_FIELD_SEP, align: ADMIN_ALIGN },
                    { colName: IT, width: IT_WIDTH, field: IT_FIELD_SEP, align: IT_ALIGN },
                    { colName: SOFTWARE, width: SOFTWARE_WIDTH, field: SOFTWARE_FIELD_SEP, align: SOFTWARE_ALIGN },
                    { colName: INFOSEC, width: INFOSEC_WIDTH, field: INFOSEC_FIELD_SEP, align: INFOSEC_ALIGN },
                    { colName: FINANCE, width: FINANCE_WIDTH, field: FINANCE_FIELD_SEP, align: FINANCE_ALIGN },
                    { colName: HR, width: HR_WIDTH, field: HR_FIELD_SEP, align: HR_ALIGN },
                    { colName: LEGAL_ASSET, width: LEGAL_ASSET_WIDTH, field: LEGAL_ASSET_FIELD, align: LEGAL_ASSET_ALIGN },
                    { colName: MIS_ASSET, width: MIS_ASSET_WIDTH, field: MIS_ASSET_FIELD, align: MIS_ASSET_ALIGN },
                    { colName: CONTACT_ASSET, width: CONTACT_ASSET_WIDTH, field: CONTACT_ASSET_FIELD, align: CONTACT_ASSET_ALIGN },
                ]
            );
        case 'employeeList':
            return (
                [
                    { colName: EMPLOYEE_LIST_ACTION, width: EMPLOYEE_LIST_ACTION_WIDTH, field: EMPLOYEE_LIST_ACTION_FIELD, align: EMPLOYEE_LIST_ACTION_ALIGN },
                    { colName: EMPLOYEE_LIST_EMPLOYEE_NAME, width: EMPLOYEE_NAME_LIST_WIDTH, field: EMPLOYEE_NAME_LIST_FIELD, align: EMPLOYEE_NAME_LIST_ALIGN },
                    { colName: EMPLOYEE_CODE, width: EMPLOYEE_CODE_WIDTH, field: EMPLOYEE_CODE_FIELD, align: EMPLOYEE_CODE_ALIGN },
                    { colName: SALUTATION, width: SALUTATION_WIDTH, field: SALUTATION_FIELD, align: SALUTATION_ALIGN },
                    { colName: FIRST_NAME, width: FIRST_NAME_WIDTH, field: FIRST_NAME_FIELD, align: FIRST_NAME_ALIGN },
                    { colName: MIDDLE_NAME, width: MIDDLE_NAME_WIDTH, field: MIDDLE_NAME_FIELD, align: MIDDLE_NAME_ALIGN },
                    { colName: LAST_NAME, width: LAST_NAME_WIDTH, field: LAST_NAME_FIELD, align: LAST_NAME_ALIGN },
                    { colName: LEGAL_NAME, width: LEGAL_NAME_WIDTH, field: LEGAL_NAME_FIELD, align: LEGAL_NAME_ALIGN },
                    { colName: EMPLOYEE_TYPE, width: EMPLOYEE_TYPE_WIDTH, field: EMPLOYEE_TYPE_FIELD, align: EMPLOYEE_TYPE_ALIGN },
                    { colName: OFFICE_EMAIL, width: OFFICE_EMAIL_WIDTH, field: OFFICE_EMAIL_FIELD, align: OFFICE_EMAIL_ALIGN },
                    { colName: REVISED_ROLE_TITLE, width: REVISED_ROLE_TITLE_WIDTH, field: REVISED_ROLE_TITLE_FIELD, align: REVISED_ROLE_TITLE_ALIGN },
                    { colName: LAYER, width: LAYER_WIDTH, field: LAYER_FIELD, align: LAYER_ALIGN },
                    { colName: SUB_LAYER, width: SUB_LAYER_WIDTH, field: SUB_LAYER_FIELD, align: SUB_LAYER_ALIGN },
                    { colName: BUSINESS_UNIT_FUNCTION, width: BUSINESS_UNIT_FUNCTION_WIDTH, field: BUSINESS_UNIT_FUNCTION_FIELD, align: BUSINESS_UNIT_FUNCTION_ALIGN },
                    { colName: SUB_BUSINESS_UNIT, width: SUB_BUSINESS_UNIT_WIDTH, field: SUB_BUSINESS_UNIT_FIELD, align: SUB_BUSINESS_UNIT_ALIGN },
                    { colName: PRACTICE_AREA_SALES_UNIT_SUB_FUNCTION, width: PRACTICE_AREA_SALES_UNIT_SUB_FUNCTION_WIDTH, field: PRACTICE_AREA_SALES_UNIT_SUB_FUNCTION_FIELD, align: PRACTICE_AREA_SALES_UNIT_SUB_FUNCTION_ALIGN },
                    { colName: FUNCTIONAL_REPORTING_SUPERVISOR, width: FUNCTIONAL_REPORTING_SUPERVISOR_WIDTH, field: FUNCTIONAL_REPORTING_SUPERVISOR_FIELD, align: FUNCTIONAL_REPORTING_SUPERVISOR_ALIGN },
                    { colName: ADMINISTRATIVE_REPORTING_SUPERVISOR, width: ADMINISTRATIVE_REPORTING_SUPERVISOR_WIDTH, field: ADMINISTRATIVE_REPORTING_SUPERVISOR_FIELD, align: ADMINISTRATIVE_REPORTING_SUPERVISOR_ALIGN },
                    { colName: DATE_OF_JOINING, width: DATE_OF_JOINING_WIDTH, field: DATE_OF_JOINING_FIELD, align: DATE_OF_JOINING_ALIGN },
                    { colName: BASECOUNTRY, width: BASECOUNTRY_WIDTH, field: BASECOUNTRY_FIELD, align: BASECOUNTRY_ALIGN },
                    { colName: BASE_LOCATION1, width: BASE_LOCATION_WIDTH1, field: BASE_LOCATION_FIELD1, align: BASE_LOCATION_ALIGN1 },
                    { colName: GENDER, width: GENDER_WIDTH, field: GENDER_FIELD, align: GENDER_ALIGN },
                    { colName: DATE_OF_BIRTH, width: DATE_OF_BIRTH_WIDTH, field: DATE_OF_BIRTH_FIELD, align: DATE_OF_BIRTH_ALIGN },
                    { colName: PERSONAL_MOBILE_NO, width: PERSONAL_MOBILE_NO_WIDTH, field: PERSONAL_MOBILE_NO_FIELD, align: PERSONAL_MOBILE_NO_ALIGN },
                    { colName: ALTERNATE_MOBILE_NO, width: ALTERNATE_MOBILE_NO_WIDTH, field: ALTERNATE_MOBILE_NO_FIELD, align: ALTERNATE_MOBILE_NO_ALIGN },
                    { colName: NEW_PERSONAL_EMAIL_ID, width: NEW_PERSONAL_EMAIL_ID_WIDTH, field: NEW_PERSONAL_EMAIL_ID_FIELD, align: NEW_PERSONAL_EMAIL_ID_ALIGN },
                    { colName: ADDRESS_1, width: ADDRESS_1_WIDTH, field: ADDRESS_1_FIELD, align: ADDRESS_1_ALIGN },
                    { colName: ADDRESS_2, width: ADDRESS_2_WIDTH, field: ADDRESS_2_FIELD, align: ADDRESS_2_ALIGN },
                    { colName: PIN_CODE, width: PIN_CODE_WIDTH, field: PIN_CODE_FIELD, align: PIN_CODE_ALIGN },
                    { colName: NEW_CITY, width: NEW_CITY_WIDTH, field: NEW_CITY_FIELD, align: NEW_CITY_ALIGN },
                    { colName: STATE, width: STATE_WIDTH, field: STATE_FIELD, align: STATE_ALIGN },
                    // { colName: COUNTRY, width: COUNTRY_WIDTH, field: COUNTRY_FIELD, align: COUNTRY_ALIGN },
                    { colName: ADDRESS_COUNTRY, width: ADDRESS_COUNTRY_WIDTH, field: ADDRESS_COUNTRY_FIELD, align: ADDRESS_COUNTRY_ALIGN },
                    { colName: HOME_OFFICE_ADDRESS, width: HOME_OFFICE_ADDRESS_WIDTH, field: HOME_OFFICE_ADDRESS_FIELD, align: HOME_OFFICE_ADDRESS_ALIGN },
                    { colName: HIGHEST_QUALIFICATION, width: HIGHEST_QUALIFICATION_WIDTH, field: HIGHEST_QUALIFICATION_FIELD, align: HIGHEST_QUALIFICATION_ALIGN },
                    { colName: SPECIALIZATION, width: SPECIALIZATION_WIDTH, field: SPECIALIZATION_FIELD, align: SPECIALIZATION_ALIGN },
                    { colName: WORK_EXPERIENCE, width: WORK_EXPERIENCE_WIDTH, field: WORK_EXPERIENCE_FIELD, align: WORK_EXPERIENCE_ALIGN },
                    { colName: RECRUITER_NAME, width: RECRUITER_NAME_WIDTH, field: RECRUITER_NAME_FIELD, align: RECRUITER_NAME_ALIGN },
                    { colName: AADHAR_CARD_NUMBER, width: AADHAR_CARD_NUMBER_WIDTH, field: AADHAR_CARD_NUMBER_FIELD, align: AADHAR_CARD_NUMBER_ALIGN },
                    { colName: PAN_CARD_NUMBER, width: PAN_CARD_NUMBER_WIDTH, field: PAN_CARD_NUMBER_FIELD, align: PAN_CARD_NUMBER_ALIGN },
                    { colName: OTHER_IDENTIFICATION, width: OTHER_IDENTIFICATION_WIDTH, field: OTHER_IDENTIFICATION_FIELD, align: OTHER_IDENTIFICATION_ALIGN },
                    { colName: NEW_SOURCE_CATEGORY, width: NEW_SOURCE_CATEGORY_WIDTH, field: NEW_SOURCE_CATEGORY_FIELD, align: NEW_SOURCE_CATEGORY_ALIGN },
                    { colName: SOURCE, width: SOURCE_WIDTH, field: SOURCE_FIELD, align: SOURCE_ALIGN },
                    { colName: TENURE_END_DATE, width: TENURE_END_DATE_WIDTH, field: TENURE_END_DATE_FIELD, align: TENURE_END_DATE_ALIGN },
                    { colName: ACTIVE_INACTIVE, width: ACTIVE_INACTIVE_WIDTH, field: ACTIVE_INACTIVE_FIELD, align: ACTIVE_INACTIVE_ALIGN },
                    { colName: CURRENCY, width: CURRENCY_WIDTH, field: CURRENCY_FIELD, align: CURRENCY_ALIGN },
                    { colName: CTC_FREQUENCY, width: CTC_FREQUENCY_WIDTH, field: CTC_FREQUENCY_FIELD, align: CTC_FREQUENCY_ALIGN },
                    { colName: WINDOW_LOGIN_ID, width: WINDOW_LOGIN_ID_WIDTH, field: WINDOW_LOGIN_ID_FIELD, align: WINDOW_LOGIN_ID_ALIGN },
                    { colName: DIGITAL_EMAIL, width: DIGITAL_EMAIL_WIDTH, field: DIGITAL_EMAIL_FIELD, align: DIGITAL_EMAIL_ALIGN },
                    { colName: TEAMS_EMAIL, width: TEAMS_EMAIL_WIDTH, field: TEAMS_EMAIL_FIELD, align: TEAMS_EMAIL_ALIGN },
                    { colName: OFFERED_DATE, width: OFFERED_DATE_WIDTH, field: OFFERED_DATE_FIELD, align: OFFERED_DATE_ALIGN },
                    { colName: OFFER_ACCEPTANCE_DATE, width: OFFER_ACCEPTANCE_DATE_WIDTH, field: OFFER_ACCEPTANCE_DATE_FIELD, align: OFFER_ACCEPTANCE_DATE_ALIGN },
                    { colName: EMPLOYEE_LIST_NOTICE_PERIOD, width: EMPLOYEE_LIST_NOTICE_PERIOD_WIDTH, field: EMPLOYEE_LIST_NOTICE_PERIOD_FIELD, align: EMPLOYEE_LIST_NOTICE_PERIOD_ALIGN },
                    { colName: FORTY_HOUR_WORK_WEEK, width: FORTY_HOUR_WORK_WEEK_WIDTH, field: FORTY_HOUR_WORK_WEEK_FIELD, align: FORTY_HOUR_WORK_WEEK_ALIGN },
                    { colName: WORK_DAY_WINDOW_NAME, width: WORK_DAY_WINDOW_NAME_WIDTH, field: WORK_DAY_WINDOW_NAME_FIELD, align: WORK_DAY_WINDOW_NAME_ALIGN },
                    { colName: WEEK_DAY_CLUSTER_NAME, width: WEEK_DAY_CLUSTER_NAME_WIDTH, field: WEEK_DAY_CLUSTER_NAME_FIELD, align: WEEK_DAY_CLUSTER_NAME_ALIGN },
                ]
            );
        case 'nonPeopleFunction':
        case 'upcomingEmployee':
            return (
                [
                    { colName: EMPLOYEE_LIST_EMPLOYEE_NAME, width: EMPLOYEE_NAME_LIST_WIDTH, field: EMPLOYEE_NAME_LIST_FIELD, align: EMPLOYEE_NAME_LIST_ALIGN },
                    { colName: EMPLOYEE_CODE, width: EMPLOYEE_CODE_WIDTH, field: EMPLOYEE_CODE_FIELD, align: EMPLOYEE_CODE_ALIGN },
                    { colName: DATE_OF_JOINING, width: DATE_OF_JOINING_WIDTH, field: DATE_OF_JOINING_FIELD, align: DATE_OF_JOINING_ALIGN },
                    { colName: EMPLOYEE_TYPE, width: EMPLOYEE_TYPE_WIDTH, field: EMPLOYEE_TYPE_FIELD, align: EMPLOYEE_TYPE_ALIGN },
                    { colName: FUNCTIONAL_REPORTING_SUPERVISOR, width: FUNCTIONAL_REPORTING_SUPERVISOR_WIDTH, field: FUNCTIONAL_REPORTING_SUPERVISOR_FIELD, align: FUNCTIONAL_REPORTING_SUPERVISOR_ALIGN },
                    { colName: ADMINISTRATIVE_REPORTING_SUPERVISOR, width: ADMINISTRATIVE_REPORTING_SUPERVISOR_WIDTH, field: ADMINISTRATIVE_REPORTING_SUPERVISOR_FIELD, align: ADMINISTRATIVE_REPORTING_SUPERVISOR_ALIGN },
                    { colName: BUSINESS_UNIT_FUNCTION, width: BUSINESS_UNIT_FUNCTION_WIDTH, field: BUSINESS_UNIT_FUNCTION_FIELD, align: BUSINESS_UNIT_FUNCTION_ALIGN },
                    { colName: SUB_BUSINESS_UNIT, width: SUB_BUSINESS_UNIT_WIDTH, field: SUB_BUSINESS_UNIT_FIELD, align: SUB_BUSINESS_UNIT_ALIGN },
                    { colName: PRACTICE_AREA_SALES_UNIT_SUB_FUNCTION, width: PRACTICE_AREA_SALES_UNIT_SUB_FUNCTION_WIDTH, field: PRACTICE_AREA_SALES_UNIT_SUB_FUNCTION_FIELD, align: PRACTICE_AREA_SALES_UNIT_SUB_FUNCTION_ALIGN },
                    { colName: LAYER, width: LAYER_WIDTH, field: LAYER_FIELD, align: LAYER_ALIGN },
                    { colName: SUB_LAYER, width: SUB_LAYER_WIDTH, field: SUB_LAYER_FIELD, align: SUB_LAYER_ALIGN },
                    { colName: REVISED_ROLE_TITLE, width: REVISED_ROLE_TITLE_WIDTH, field: REVISED_ROLE_TITLE_FIELD, align: REVISED_ROLE_TITLE_ALIGN },
                    { colName: ACTIVE_INACTIVE, width: ACTIVE_INACTIVE_WIDTH, field: ACTIVE_INACTIVE_FIELD, align: ACTIVE_INACTIVE_ALIGN },
                    { colName: FORTY_HOUR_WORK_WEEK, width: FORTY_HOUR_WORK_WEEK_WIDTH, field: FORTY_HOUR_WORK_WEEK_FIELD, align: FORTY_HOUR_WORK_WEEK_ALIGN },
                    { colName: WORK_DAY_WINDOW_NAME, width: WORK_DAY_WINDOW_NAME_WIDTH, field: WORK_DAY_WINDOW_NAME_FIELD, align: WORK_DAY_WINDOW_NAME_ALIGN },
                    { colName: WEEK_DAY_CLUSTER_NAME, width: WEEK_DAY_CLUSTER_NAME_WIDTH, field: WEEK_DAY_CLUSTER_NAME_FIELD, align: WEEK_DAY_CLUSTER_NAME_ALIGN },
                    // { colName: LEGAL_NAME, width: LEGAL_NAME_WIDTH, field: LEGAL_NAME_FIELD, align: LEGAL_NAME_ALIGN },
                    // { colName: OFFICE_EMAIL, width: OFFICE_EMAIL_WIDTH, field: OFFICE_EMAIL_FIELD, align: OFFICE_EMAIL_ALIGN },
                    // { colName: BASECOUNTRY, width: BASECOUNTRY_WIDTH, field: BASECOUNTRY_FIELD, align: BASECOUNTRY_ALIGN },
                    // { colName: BASE_LOCATION1, width: BASE_LOCATION_WIDTH1, field: BASE_LOCATION_FIELD1, align: BASE_LOCATION_ALIGN1 },
                    // { colName: HIGHEST_QUALIFICATION, width: HIGHEST_QUALIFICATION_WIDTH, field: HIGHEST_QUALIFICATION_FIELD, align: HIGHEST_QUALIFICATION_ALIGN },
                    // { colName: SPECIALIZATION, width: SPECIALIZATION_WIDTH, field: SPECIALIZATION_FIELD, align: SPECIALIZATION_ALIGN },
                    // { colName: WORK_EXPERIENCE, width: WORK_EXPERIENCE_WIDTH, field: WORK_EXPERIENCE_FIELD, align: WORK_EXPERIENCE_ALIGN },
                    // { colName: TENURE_END_DATE, width: TENURE_END_DATE_WIDTH, field: TENURE_END_DATE_FIELD, align: TENURE_END_DATE_ALIGN },
                ]
            );
        case 'masterothers':
            return (
                [
                    { colName: EMPLOYEE_LIST_ACTION, width: EMPLOYEE_LIST_MASTERS_ACTION_WIDTH, field: EMPLOYEE_LIST_ACTION_FIELD, align: EMPLOYEE_LIST_ACTION_ALIGN },
                    { colName: EMPLOYEE_LIST_EMPLOYEE_NAME, width: EMPLOYEE_NAME_LIST_WIDTH, field: EMPLOYEE_NAME_LIST_FIELD, align: EMPLOYEE_NAME_LIST_ALIGN },
                    { colName: EMPLOYEE_CODE, width: EMPLOYEE_CODE_WIDTH, field: EMPLOYEE_CODE_FIELD, align: EMPLOYEE_CODE_ALIGN },
                    { colName: UNIQUE_CODE, width: UNIQUE_CODE_WIDTH, field: UNIQUE_CODE_FIELD, align: UNIQUE_CODE_ALIGN },
                    { colName: EMPLOYEE_EMAIL, width: EMPLOYEE_EMAIL_WIDTH, field: EMPLOYEE_EMAIL_FIELD, align: EMPLOYEE_EMAIL_ALIGN },
                    { colName: BAND, width: BAND_WIDTH, field: BAND_FIELD, align: BAND_ALIGN },
                    { colName: DESIGNATION, width: DESIGNATION_WIDTH, field: DESIGNATION_FIELD, align: DESIGNATION_ALIGN },
                    { colName: FUNCTION, width: FUNCTION_WIDTH, field: FUNCTION_FIELD, align: FUNCTION_ALIGN },
                    { colName: ROLE, width: ROLE_WIDTH, field: ROLE_FIELD, align: ROLE_ALIGN },
                    { colName: MARKET_FACING_TITLE, width: MARKET_FACING_TITLE_WIDTH, field: MARKET_FACING_TITLE_FIELD, align: MARKET_FACING_TITLE_ALIGN },
                    { colName: DATE_OF_JOINING, width: DATE_OF_JOINING_WIDTH, field: DATE_OF_JOINING_FIELD, align: DATE_OF_JOINING_ALIGN },
                    { colName: WORK_PROFILE, width: WORK_PROFILE_WIDTH, field: WORK_PROFILE_FIELD, align: WORK_PROFILE_ALIGN },
                    { colName: BASECOUNTRY, width: BASECOUNTRY_WIDTH, field: BASECOUNTRY_FIELD, align: BASECOUNTRY_ALIGN },
                    { colName: BASE_LOCATION1, width: BASE_LOCATION_WIDTH1, field: BASE_LOCATION_FIELD1, align: BASE_LOCATION_ALIGN1 },
                    { colName: LEVEL_1, width: LEVEL_1_WIDTH, field: LEVEL_1_FIELD, align: LEVEL_1_ALIGN },
                    { colName: LEVEL_1_NAME, width: LEVEL_1_NAME_WIDTH, field: LEVEL_1_NAME_FIELD, align: LEVEL_1_NAME_ALIGN },
                    { colName: LEVEL_1_TEAM_MEMBER_NAME, width: LEVEL_1_TEAM_MEMBER_NAME_WIDTH, field: LEVEL_1_TEAM_MEMBER_NAME_FIELD, align: LEVEL_1_TEAM_MEMBER_NAME_ALIGN },
                    // { colName: LEVEL_1_ROLE_STATUS, width: LEVEL_1_ROLE_STATUS_WIDTH, field: LEVEL_1_ROLE_STATUS_FIELD, align: LEVEL_1_ROLE_STATUS_ALIGN },
                    { colName: LEVEL_2, width: LEVEL_2_WIDTH, field: LEVEL_2_FIELD, align: LEVEL_2_ALIGN },
                    { colName: BUSINESS_UNIT_FUNCTION, width: BUSINESS_UNIT_FUNCTION_WIDTH, field: BUSINESS_UNIT_FUNCTION_FIELD, align: BUSINESS_UNIT_FUNCTION_ALIGN },
                    { colName: BUSINESS_UNIT_HEAD, width: BUSINESS_UNIT_HEAD_WIDTH, field: BUSINESS_UNIT_HEAD_FIELD, align: BUSINESS_UNIT_HEAD_ALIGN },
                    // { colName: BUSINESS_UNIT_HEAD_ROLE_STATUS, width: BUSINESS_UNIT_HEAD_ROLE_STATUS_WIDTH, field: BUSINESS_UNIT_HEAD_ROLE_STATUS_FIELD, align: BUSINESS_UNIT_HEAD_ROLE_STATUS_ALIGN },
                    { colName: LEVEL_3, width: LEVEL_3_WIDTH, field: LEVEL_3_FIELD, align: LEVEL_3_ALIGN },
                    { colName: OPS_PNL_BU_CENTRAL, width: OPS_PNL_BU_CENTRAL_WIDTH, field: OPS_PNL_BU_CENTRAL_FIELD, align: OPS_PNL_BU_CENTRAL_ALIGN },
                    { colName: OPS_SALE_CENTRAL_PNL_HEAD, width: OPS_SALE_CENTRAL_PNL_HEAD_WIDTH, field: OPS_SALE_CENTRAL_PNL_HEAD_FIELD, align: OPS_SALE_CENTRAL_PNL_HEAD_ALIGN },
                    // { colName: OPS_SALE_CENTRAL_PNL_HEAD_ROLE_STATUS, width: OPS_SALE_CENTRAL_PNL_HEAD_ROLE_STATUS_WIDTH, field: OPS_SALE_CENTRAL_PNL_HEAD_ROLE_STATUS_FIELD, align: OPS_SALE_CENTRAL_PNL_HEAD_ROLE_STATUS_ALIGN },
                    { colName: LEVEL_4, width: LEVEL_4_WIDTH, field: LEVEL_4_FIELD, align: LEVEL_4_ALIGN },
                    { colName: OPS_SUB_SALE_PNL_FUNCTION, width: OPS_SUB_SALE_PNL_FUNCTION_WIDTH, field: OPS_SUB_SALE_PNL_FUNCTION_FIELD, align: OPS_SUB_SALE_PNL_FUNCTION_ALIGN },
                    { colName: OPS_SUB_SALE_PNL_FUNCTION_HEAD, width: OPS_SUB_SALE_PNL_FUNCTION_HEAD_WIDTH, field: OPS_SUB_SALE_PNL_FUNCTION_HEAD_FIELD, align: OPS_SUB_SALE_PNL_FUNCTION_HEAD_ALIGN },
                    // { colName: OPS_SUB_SALE_PNL_FUNCTION_HEAD_ROLE_STATUS, width: OPS_SUB_SALE_PNL_FUNCTION_HEAD_ROLE_STATUS_WIDTH, field: OPS_SUB_SALE_PNL_FUNCTION_HEAD_ROLE_STATUS_FIELD, align: OPS_SUB_SALE_PNL_FUNCTION_HEAD_ROLE_STATUS_ALIGN },
                    { colName: LEVEL_5, width: LEVEL_5_WIDTH, field: LEVEL_5_FIELD, align: LEVEL_5_ALIGN },
                    { colName: SERVICE_DIVISION_PRACTICE_SALES_UNIT, width: SERVICE_DIVISION_PRACTICE_SALES_UNIT_WIDTH, field: SERVICE_DIVISION_PRACTICE_SALES_UNIT_FIELD, align: SERVICE_DIVISION_PRACTICE_SALES_UNIT_ALIGN },
                    { colName: SERVICE_DIVISION_PRACTICE_SALES_UNIT_HEAD, width: SERVICE_DIVISION_PRACTICE_SALES_UNIT_HEAD_WIDTH, field: SERVICE_DIVISION_PRACTICE_SALES_UNIT_HEAD_FIELD, align: SERVICE_DIVISION_PRACTICE_SALES_UNIT_HEAD_ALIGN },
                    // { colName: ACTING_ROLE, width: ACTING_ROLE_WIDTH, field: ACTING_ROLE_FIELD, align: ACTING_ROLE_ALIGN },
                    { colName: LEVEL_6, width: LEVEL_6_WIDTH, field: LEVEL_6_FIELD, align: LEVEL_6_ALIGN },
                    { colName: GROUP_SALE_MANAGER1, width: GROUP_SALE_MANAGER1_WIDTH, field: GROUP_SALE_MANAGER1_FIELD, align: GROUP_SALE_MANAGER1_ALIGN },
                    { colName: GROUP_SALE_MANAGER_NAME, width: GROUP_SALE_MANAGER_NAME_WIDTH, field: GROUP_SALE_MANAGER_NAME_FIELD, align: GROUP_SALE_MANAGER_NAME_ALIGN },
                    // { colName: ACTING_ROLE6, width: ACTING_ROLE6_WIDTH, field: ACTING_ROLE6_FIELD, align: ACTING_ROLE6_ALIGN },
                    { colName: LEVEL_7, width: LEVEL_7_WIDTH, field: LEVEL_7_FIELD, align: LEVEL_7_ALIGN },
                    { colName: PROJECT_MANAGER_SALES_MGR2, width: PROJECT_MANAGER_SALES_MGR2_WIDTH, field: PROJECT_MANAGER_SALES_MGR2_FIELD, align: PROJECT_MANAGER_SALES_MGR2_ALIGN },
                    { colName: PROJECT_MANAGER_SALES_PERSON_NAME, width: PROJECT_MANAGER_SALES_PERSON_NAME_WIDTH, field: PROJECT_MANAGER_SALES_PERSON_NAME_FIELD, align: PROJECT_MANAGER_SALES_PERSON_NAME_ALIGN },
                    // { colName: ACTING_ROLE7, width: ACTING_ROLE7_WIDTH, field: ACTING_ROLE7_FIELD, align: ACTING_ROLE7_ALIGN },
                    { colName: LEVEL_8, width: LEVEL_8_WIDTH, field: LEVEL_8_FIELD, align: LEVEL_8_ALIGN },
                    { colName: LEVEL8_TEAM_MEMBER_NAME, width: LEVEL8_TEAM_MEMBER_NAME_WIDTH, field: LEVEL8_TEAM_MEMBER_NAME_FIELD, align: LEVEL8_TEAM_MEMBER_NAME_ALIGN },
                    // { colName: ACTING_ROLE8, width: ACTING_ROLE8_WIDTH, field: ACTING_ROLE8_FIELD, align: ACTING_ROLE8_ALIGN },
                    { colName: LEVEL_9, width: LEVEL_9_WIDTH, field: LEVEL_9_FIELD, align: LEVEL_9_ALIGN },
                    { colName: LEVEL9_TEAM_MEMBER_NAME, width: LEVEL9_TEAM_MEMBER_NAME_WIDTH, field: LEVEL9_TEAM_MEMBER_NAME_FIELD, align: LEVEL9_TEAM_MEMBER_NAME_ALIGN },
                    // { colName: ACTING_ROLE9, width: ACTING_ROLE9_WIDTH, field: ACTING_ROLE9_FIELD, align: ACTING_ROLE9_ALIGN },
                    { colName: REPORTING_SUPERVISOR, width: REPORTING_SUPERVISOR_WIDTH, field: REPORTING_SUPERVISOR_FIELD, align: REPORTING_SUPERVISOR_ALIGN },
                    // { colName: COST_SHARING, width: COST_SHARING_WIDTH, field: COST_SHARING_FIELD, align: COST_SHARING_ALIGN },
                    // { colName: INDIRECT_REPORTING, width: INDIRECT_REPORTING_WIDTH, field: INDIRECT_REPORTING_FIELD, align: INDIRECT_REPORTING_ALIGN },
                    // { colName: COST_SHARE_PRIMARY_REPORTING, width: COST_SHARE_PRIMARY_REPORTING_WIDTH, field: COST_SHARE_PRIMARY_REPORTING_FIELD, align: COST_SHARE_PRIMARY_REPORTING_ALIGN },
                    { colName: EMPLOYEE_TYPE, width: EMPLOYEE_TYPE_WIDTH, field: EMPLOYEE_TYPE_FIELD, align: EMPLOYEE_TYPE_ALIGN },
                ]
            );
        case 'leftEmployee':
            return (
                [
                    { colName: EMPLOYEE_LIST_ACTION, width: EMPLOYEE_LIST_ACTION_WIDTH, field: EMPLOYEE_LIST_ACTION_FIELD, align: EMPLOYEE_LIST_ACTION_ALIGN },
                    { colName: EMPLOYEE_LIST_EMPLOYEE_NAME, width: EMPLOYEE_NAME_LIST_WIDTH, field: EMPLOYEE_NAME_LIST_FIELD, align: EMPLOYEE_NAME_LIST_ALIGN },
                    { colName: EMPLOYEE_CODE, width: EMPLOYEE_CODE_WIDTH, field: EMPLOYEE_CODE_FIELD, align: EMPLOYEE_CODE_ALIGN },
                    { colName: LEGAL_NAME, width: LEGAL_NAME_WIDTH, field: LEGAL_NAME_FIELD, align: LEGAL_NAME_ALIGN },
                    { colName: OFFICE_EMAIL, width: OFFICE_EMAIL_WIDTH, field: OFFICE_EMAIL_FIELD, align: OFFICE_EMAIL_ALIGN },
                    { colName: REVISED_ROLE_TITLE, width: REVISED_ROLE_TITLE_WIDTH, field: REVISED_ROLE_TITLE_FIELD, align: REVISED_ROLE_TITLE_ALIGN },
                    { colName: LAYER, width: LAYER_WIDTH, field: LAYER_FIELD, align: LAYER_ALIGN },
                    { colName: SUB_LAYER, width: SUB_LAYER_WIDTH, field: SUB_LAYER_FIELD, align: SUB_LAYER_ALIGN },
                    { colName: BUSINESS_UNIT_FUNCTION, width: BUSINESS_UNIT_FUNCTION_WIDTH, field: BUSINESS_UNIT_FUNCTION_FIELD, align: BUSINESS_UNIT_FUNCTION_ALIGN },
                    { colName: SUB_BUSINESS_UNIT, width: SUB_BUSINESS_UNIT_WIDTH, field: SUB_BUSINESS_UNIT_FIELD, align: SUB_BUSINESS_UNIT_ALIGN },
                    { colName: PRACTICE_AREA_SALES_UNIT_SUB_FUNCTION, width: PRACTICE_AREA_SALES_UNIT_SUB_FUNCTION_WIDTH, field: PRACTICE_AREA_SALES_UNIT_SUB_FUNCTION_FIELD, align: PRACTICE_AREA_SALES_UNIT_SUB_FUNCTION_ALIGN },
                    { colName: FUNCTIONAL_REPORTING_SUPERVISOR, width: FUNCTIONAL_REPORTING_SUPERVISOR_WIDTH, field: FUNCTIONAL_REPORTING_SUPERVISOR_FIELD, align: FUNCTIONAL_REPORTING_SUPERVISOR_ALIGN },
                    { colName: ADMINISTRATIVE_REPORTING_SUPERVISOR, width: ADMINISTRATIVE_REPORTING_SUPERVISOR_WIDTH, field: ADMINISTRATIVE_REPORTING_SUPERVISOR_FIELD, align: ADMINISTRATIVE_REPORTING_SUPERVISOR_ALIGN },
                    { colName: DATE_OF_JOINING, width: DATE_OF_JOINING_WIDTH, field: DATE_OF_JOINING_FIELD, align: DATE_OF_JOINING_ALIGN },
                    { colName: BASECOUNTRY, width: BASECOUNTRY_WIDTH, field: BASECOUNTRY_FIELD, align: BASECOUNTRY_ALIGN },
                    { colName: BASE_LOCATION1, width: BASE_LOCATION_WIDTH1, field: BASE_LOCATION_FIELD1, align: BASE_LOCATION_ALIGN1 },
                    { colName: HIGHEST_QUALIFICATION, width: HIGHEST_QUALIFICATION_WIDTH, field: HIGHEST_QUALIFICATION_FIELD, align: HIGHEST_QUALIFICATION_ALIGN },
                    { colName: SPECIALIZATION, width: SPECIALIZATION_WIDTH, field: SPECIALIZATION_FIELD, align: SPECIALIZATION_ALIGN },
                    { colName: WORK_EXPERIENCE, width: WORK_EXPERIENCE_WIDTH, field: WORK_EXPERIENCE_FIELD, align: WORK_EXPERIENCE_ALIGN },
                    { colName: TENURE_END_DATE, width: TENURE_END_DATE_WIDTH, field: TENURE_END_DATE_FIELD, align: TENURE_END_DATE_ALIGN },
                    { colName: ACTIVE_INACTIVE, width: ACTIVE_INACTIVE_WIDTH, field: ACTIVE_INACTIVE_FIELD, align: ACTIVE_INACTIVE_ALIGN },
                    { colName: LEFT_DATE, width: LEFT_DATE_WIDTH, field: LEFT_DATE_FIELD, align: LEFT_DATE_ALIGN },
                    { colName: FORTY_HOUR_WORK_WEEK, width: FORTY_HOUR_WORK_WEEK_WIDTH, field: FORTY_HOUR_WORK_WEEK_FIELD, align: FORTY_HOUR_WORK_WEEK_ALIGN },
                    { colName: WORK_DAY_WINDOW_NAME, width: WORK_DAY_WINDOW_NAME_WIDTH, field: WORK_DAY_WINDOW_NAME_FIELD, align: WORK_DAY_WINDOW_NAME_ALIGN },
                    { colName: WEEK_DAY_CLUSTER_NAME, width: WEEK_DAY_CLUSTER_NAME_WIDTH, field: WEEK_DAY_CLUSTER_NAME_FIELD, align: WEEK_DAY_CLUSTER_NAME_ALIGN },
                    // { colName: EMPLOYEE_LIST_EMPLOYEE_NAME, width: EMPLOYEE_NAME_LIST_WIDTH, field: EMPLOYEE_NAME_LIST_FIELD, align: EMPLOYEE_NAME_LIST_ALIGN },
                    // { colName: EMPLOYEE_CODE, width: EMPLOYEE_CODE_WIDTH, field: EMPLOYEE_CODE_FIELD, align: EMPLOYEE_CODE_ALIGN },
                    // { colName: REVISED_ROLE_TITLE, width: REVISED_ROLE_TITLE_WIDTH, field: REVISED_ROLE_TITLE_FIELD, align: REVISED_ROLE_TITLE_ALIGN },
                    // { colName: SUB_LAYER, width: SUB_LAYER_WIDTH, field: SUB_LAYER_FIELD, align: SUB_LAYER_ALIGN },
                    // { colName: DATE_OF_JOINING, width: DATE_OF_JOINING_WIDTH, field: DATE_OF_JOINING_FIELD, align: DATE_OF_JOINING_ALIGN },
                    // { colName: BASECOUNTRY, width: BASECOUNTRY_WIDTH, field: BASECOUNTRY_FIELD, align: BASECOUNTRY_ALIGN },
                    // { colName: BASE_LOCATION1, width: BASE_LOCATION_WIDTH1, field: BASE_LOCATION_FIELD1, align: BASE_LOCATION_ALIGN1 },
                    // { colName: BUSINESS_UNIT_FUNCTION, width: BUSINESS_UNIT_FUNCTION_WIDTH, field: BUSINESS_UNIT_FUNCTION_FIELD, align: BUSINESS_UNIT_FUNCTION_ALIGN },
                    // { colName: OPS_SUB_PNL_SALES_SUB_PNL_SUB_FUNCTION, width: OPS_SUB_PNL_SALES_SUB_PNL_SUB_FUNCTION_WIDTH, field: OPS_SUB_PNL_SALES_SUB_PNL_SUB_FUNCTION_FIELD, align: OPS_SUB_PNL_SALES_SUB_PNL_SUB_FUNCTION_ALIGN },
                    // { colName: PRACTICE_AREA_SALES_UNIT_SUB_FUNCTION, width: PRACTICE_AREA_SALES_UNIT_SUB_FUNCTION_WIDTH, field: PRACTICE_AREA_SALES_UNIT_SUB_FUNCTION_FIELD, align: PRACTICE_AREA_SALES_UNIT_SUB_FUNCTION_ALIGN },
                    // { colName: LAYER, width: LAYER_WIDTH, field: LAYER_FIELD, align: LAYER_ALIGN },
                    // { colName: FUNCTIONAL_REPORTING_SUPERVISOR, width: FUNCTIONAL_REPORTING_SUPERVISOR_WIDTH, field: FUNCTIONAL_REPORTING_SUPERVISOR_FIELD, align: FUNCTIONAL_REPORTING_SUPERVISOR_ALIGN },
                    // { colName: ADMINISTRATIVE_REPORTING_SUPERVISOR, width: ADMINISTRATIVE_REPORTING_SUPERVISOR_WIDTH, field: ADMINISTRATIVE_REPORTING_SUPERVISOR_FIELD, align: ADMINISTRATIVE_REPORTING_SUPERVISOR_ALIGN },
                    // { colName: PERSONAL_MOBILE_NO, width: PERSONAL_MOBILE_NO_WIDTH, field:PERSONAL_MOBILE_NO_FIELD,align:PERSONAL_MOBILE_NO_ALIGN },
                    // { colName: ALTERNATE_MOBILE_NO, width: ALTERNATE_MOBILE_NO_WIDTH, field:ALTERNATE_MOBILE_NO_FIELD,align:ALTERNATE_MOBILE_NO_ALIGN },
                    // { colName: LEGAL_NAME, width: LEGAL_NAME_WIDTH, field:LEGAL_NAME_FIELD,align:LEGAL_NAME_ALIGN },
                    // { colName: BAND, width: BAND_WIDTH, field: BAND_FIELD, align: BAND_ALIGN },
                    // { colName: DESIGNATION, width: DESIGNATION_WIDTH, field: DESIGNATION_FIELD, align: DESIGNATION_ALIGN },
                    // { colName: ROLE, width: ROLE_WIDTH, field: ROLE_FIELD, align: ROLE_ALIGN },
                    // { colName: DATE_OF_JOINING, width: DATE_OF_JOINING_WIDTH, field: DATE_OF_JOINING_FIELD, align: DATE_OF_JOINING_ALIGN },
                    // { colName: BASECOUNTRY, width: BASECOUNTRY_WIDTH, field: BASECOUNTRY_FIELD, align: BASECOUNTRY_ALIGN },
                    // { colName: BASE_LOCATION1, width: BASE_LOCATION_WIDTH1, field: BASE_LOCATION_FIELD1, align: BASE_LOCATION_ALIGN1 },
                    // { colName: BUSINESS_GROUP_FUNCTION, width: BUSINESS_GROUP_FUNCTION_WIDTH, field: BUSINESS_GROUP_FUNCTION_FIELD, align: BUSINESS_GROUP_FUNCTION_ALIGN },
                    // { colName: BUSINESS_GROUP_HEAD, width: BUSINESS_GROUP_HEAD_WIDTH, field: BUSINESS_GROUP_HEAD_FIELD, align: BUSINESS_GROUP_HEAD_ALIGN },
                    // { colName: OPS_SUB_SALE_PNL_FUNCTION, width: OPS_SUB_SALE_PNL_FUNCTION_WIDTH, field: OPS_SUB_SALE_PNL_FUNCTION_FIELD, align: OPS_SUB_SALE_PNL_FUNCTION_ALIGN },
                    // { colName: OPS_SUB_SALE_PNL_FUNCTION_HEAD, width: OPS_SUB_SALE_PNL_FUNCTION_HEAD_WIDTH, field: OPS_SUB_SALE_PNL_FUNCTION_HEAD_FIELD, align: OPS_SUB_SALE_PNL_FUNCTION_HEAD_ALIGN },
                    // { colName: SERVICE_DIVISION_PRACTICE_SALES_UNIT, width: SERVICE_DIVISION_PRACTICE_SALES_UNIT_WIDTH, field: SERVICE_DIVISION_PRACTICE_SALES_UNIT_FIELD, align: SERVICE_DIVISION_PRACTICE_SALES_UNIT_ALIGN },
                    // { colName: SERVICE_DIVISION_PRACTICE_SALES_UNIT_HEAD, width: SERVICE_DIVISION_PRACTICE_SALES_UNIT_HEAD_WIDTH, field: SERVICE_DIVISION_PRACTICE_SALES_UNIT_HEAD_FIELD, align: SERVICE_DIVISION_PRACTICE_SALES_UNIT_HEAD_ALIGN },
                    // { colName: GROUP_SALE_MANAGER_NAME, width: GROUP_SALE_MANAGER_NAME_WIDTH, field: GROUP_SALE_MANAGER_NAME_FIELD, align: GROUP_SALE_MANAGER_NAME_ALIGN },
                    // { colName: PROJECT_MANAGER_SALES_PERSON_NAME, width: PROJECT_MANAGER_SALES_PERSON_NAME_WIDTH, field: PROJECT_MANAGER_SALES_PERSON_NAME_FIELD, align: PROJECT_MANAGER_SALES_PERSON_NAME_ALIGN },
                    // { colName: REPORTING_SUPERVISOR, width: REPORTING_SUPERVISOR_WIDTH, field: REPORTING_SUPERVISOR_FIELD, align: REPORTING_SUPERVISOR_ALIGN },
                    // { colName: LEGAL_NAME, width: LEGAL_NAME_WIDTH, field:LEGAL_NAME_FIELD,align:LEGAL_NAME_ALIGN },
                    // { colName: TEMP, width: TEMP_WIDTH, field: TEMP_FIELD, align: TEMP_ALIGN },
                    // { colName: EMPLOYEE_LIST_ACTION, width: EMPLOYEE_LIST_ACTION_WIDTH, field: EMPLOYEE_LIST_ACTION_FIELD, align: EMPLOYEE_LIST_ACTION_ALIGN },
                    /*  
                      { colName: EMPLOYEE_LIST_EMPLOYEE_NAME, width: EMPLOYEE_NAME_LIST_WIDTH, field: EMPLOYEE_NAME_LIST_FIELD, align: EMPLOYEE_NAME_LIST_ALIGN },
                      { colName: EMPLOYEE_CODE, width: EMPLOYEE_CODE_WIDTH, field: EMPLOYEE_CODE_FIELD, align: EMPLOYEE_CODE_ALIGN },
                      // { colName: UNIQUE_CODE, width: UNIQUE_CODE_WIDTH, field: UNIQUE_CODE_FIELD, align: UNIQUE_CODE_ALIGN },
                      // { colName: EMPLOYEE_EMAIL, width: EMPLOYEE_EMAIL_WIDTH, field: EMPLOYEE_EMAIL_FIELD, align: EMPLOYEE_EMAIL_ALIGN },
                      { colName: BAND, width: BAND_WIDTH, field: BAND_FIELD, align: BAND_ALIGN },
                      { colName: DESIGNATION, width: DESIGNATION_WIDTH, field: DESIGNATION_FIELD, align: DESIGNATION_ALIGN },
                      // { colName: FUNCTION, width: FUNCTION_WIDTH, field: FUNCTION_FIELD, align: FUNCTION_ALIGN },
                      { colName: ROLE, width: ROLE_WIDTH, field: ROLE_FIELD, align: ROLE_ALIGN },
                      // { colName: MARKET_FACING_TITLE, width: MARKET_FACING_TITLE_WIDTH, field: MARKET_FACING_TITLE_FIELD, align: MARKET_FACING_TITLE_ALIGN },
                      { colName: LEFT_DATE, width: LEFT_DATE_WIDTH, field: LEFT_DATE_FIELD, align: LEFT_DATE_ALIGN },
                      { colName: DATE_OF_JOINING, width: DATE_OF_JOINING_WIDTH, field: DATE_OF_JOINING_FIELD, align: DATE_OF_JOINING_ALIGN },
                      // { colName: WORK_PROFILE, width: WORK_PROFILE_WIDTH, field: WORK_PROFILE_FIELD, align: WORK_PROFILE_ALIGN },
                      { colName: BASECOUNTRY, width: BASECOUNTRY_WIDTH, field: BASECOUNTRY_FIELD, align: BASECOUNTRY_ALIGN },
                      { colName: BASE_LOCATION1, width: BASE_LOCATION_WIDTH1, field: BASE_LOCATION_FIELD1, align: BASE_LOCATION_ALIGN1 },
                      // { colName: LEVEL_1, width: LEVEL_1_WIDTH, field: LEVEL_1_FIELD, align: LEVEL_1_ALIGN },
                      // { colName: LEVEL_1_NAME, width: LEVEL_1_NAME_WIDTH, field: LEVEL_1_NAME_FIELD, align: LEVEL_1_NAME_ALIGN },
                      // { colName: LEVEL_1_TEAM_MEMBER_NAME, width: LEVEL_1_TEAM_MEMBER_NAME_WIDTH, field: LEVEL_1_TEAM_MEMBER_NAME_FIELD, align: LEVEL_1_TEAM_MEMBER_NAME_ALIGN },
                      // { colName: LEVEL_2, width: LEVEL_2_WIDTH, field: LEVEL_2_FIELD, align: LEVEL_2_ALIGN },
                      { colName: BUSINESS_UNIT_FUNCTION, width: BUSINESS_UNIT_FUNCTION_WIDTH, field: BUSINESS_UNIT_FUNCTION_FIELD, align: BUSINESS_UNIT_FUNCTION_ALIGN },
                      { colName: BUSINESS_UNIT_HEAD, width: BUSINESS_UNIT_HEAD_WIDTH, field: BUSINESS_UNIT_HEAD_FIELD, align: BUSINESS_UNIT_HEAD_ALIGN },
                      // { colName: LEVEL_3, width: LEVEL_3_WIDTH, field: LEVEL_3_FIELD, align: LEVEL_3_ALIGN },
                      { colName: OPS_PNL_BU_CENTRAL, width: OPS_PNL_BU_CENTRAL_WIDTH, field: OPS_PNL_BU_CENTRAL_FIELD, align: OPS_PNL_BU_CENTRAL_ALIGN },
                      { colName: OPS_SALE_CENTRAL_PNL_HEAD, width: OPS_SALE_CENTRAL_PNL_HEAD_WIDTH, field: OPS_SALE_CENTRAL_PNL_HEAD_FIELD, align: OPS_SALE_CENTRAL_PNL_HEAD_ALIGN },
                      // { colName: LEVEL_4, width: LEVEL_4_WIDTH, field: LEVEL_4_FIELD, align: LEVEL_4_ALIGN },
                      { colName: OPS_SUB_SALE_PNL_FUNCTION, width: OPS_SUB_SALE_PNL_FUNCTION_WIDTH, field: OPS_SUB_SALE_PNL_FUNCTION_FIELD, align: OPS_SUB_SALE_PNL_FUNCTION_ALIGN },
                      { colName: OPS_SUB_SALE_PNL_FUNCTION_HEAD, width: OPS_SUB_SALE_PNL_FUNCTION_HEAD_WIDTH, field: OPS_SUB_SALE_PNL_FUNCTION_HEAD_FIELD, align: OPS_SUB_SALE_PNL_FUNCTION_HEAD_ALIGN },
                      // { colName: LEVEL_5, width: LEVEL_5_WIDTH, field: LEVEL_5_FIELD, align: LEVEL_5_ALIGN },
                      { colName: SERVICE_DIVISION_PRACTICE_SALES_UNIT, width: SERVICE_DIVISION_PRACTICE_SALES_UNIT_WIDTH, field: SERVICE_DIVISION_PRACTICE_SALES_UNIT_FIELD, align: SERVICE_DIVISION_PRACTICE_SALES_UNIT_ALIGN },
                      { colName: SERVICE_DIVISION_PRACTICE_SALES_UNIT_HEAD, width: SERVICE_DIVISION_PRACTICE_SALES_UNIT_HEAD_WIDTH, field: SERVICE_DIVISION_PRACTICE_SALES_UNIT_HEAD_FIELD, align: SERVICE_DIVISION_PRACTICE_SALES_UNIT_HEAD_ALIGN },
                      // { colName: LEVEL_6, width: LEVEL_6_WIDTH, field: LEVEL_6_FIELD, align: LEVEL_6_ALIGN },
                      { colName: GROUP_SALE_MANAGER1, width: GROUP_SALE_MANAGER1_WIDTH, field: GROUP_SALE_MANAGER1_FIELD, align: GROUP_SALE_MANAGER1_ALIGN },
                      { colName: GROUP_SALE_MANAGER_NAME, width: GROUP_SALE_MANAGER_NAME_WIDTH, field: GROUP_SALE_MANAGER_NAME_FIELD, align: GROUP_SALE_MANAGER_NAME_ALIGN },
                      // { colName: LEVEL_7, width: LEVEL_7_WIDTH, field: LEVEL_7_FIELD, align: LEVEL_7_ALIGN },
                      { colName: PROJECT_MANAGER_SALES_MGR2, width: PROJECT_MANAGER_SALES_MGR2_WIDTH, field: PROJECT_MANAGER_SALES_MGR2_FIELD, align: PROJECT_MANAGER_SALES_MGR2_ALIGN },
                      { colName: PROJECT_MANAGER_SALES_PERSON_NAME, width: PROJECT_MANAGER_SALES_PERSON_NAME_WIDTH, field: PROJECT_MANAGER_SALES_PERSON_NAME_FIELD, align: PROJECT_MANAGER_SALES_PERSON_NAME_ALIGN },
                      // { colName: LEVEL_8, width: LEVEL_8_WIDTH, field: LEVEL_8_FIELD, align: LEVEL_8_ALIGN },
                      { colName: LEVEL8_TEAM_MEMBER_NAME, width: LEVEL8_TEAM_MEMBER_NAME_WIDTH, field: LEVEL8_TEAM_MEMBER_NAME_FIELD, align: LEVEL8_TEAM_MEMBER_NAME_ALIGN },
                      // { colName: LEVEL_9, width: LEVEL_9_WIDTH, field: LEVEL_9_FIELD, align: LEVEL_9_ALIGN },
                      { colName: LEVEL9_TEAM_MEMBER_NAME, width: LEVEL9_TEAM_MEMBER_NAME_WIDTH, field: LEVEL9_TEAM_MEMBER_NAME_FIELD, align: LEVEL9_TEAM_MEMBER_NAME_ALIGN },
                      { colName: REPORTING_SUPERVISOR, width: REPORTING_SUPERVISOR_WIDTH, field: REPORTING_SUPERVISOR_FIELD, align: REPORTING_SUPERVISOR_ALIGN },
                      { colName: EMPLOYEE_TYPE, width: EMPLOYEE_TYPE_WIDTH, field: EMPLOYEE_TYPE_FIELD, align: EMPLOYEE_TYPE_ALIGN },
                      { colName: TEMP, width: TEMP_WIDTH, field: TEMP_FIELD, align: TEMP_ALIGN },*/

                ]
            );
        // case 'upcomingEmployee':
        // return (
        //     [
        //         { colName: EMPLOYEE_LIST_EMPLOYEE_NAME, width: EMPLOYEE_NAME_LIST_WIDTH, field: EMPLOYEE_NAME_LIST_FIELD, align: EMPLOYEE_NAME_LIST_ALIGN },
        //         { colName: EMPLOYEE_CODE, width: EMPLOYEE_CODE_WIDTH, field: EMPLOYEE_CODE_FIELD, align: EMPLOYEE_CODE_ALIGN },
        //         { colName: LEGAL_NAME, width: LEGAL_NAME_WIDTH, field: LEGAL_NAME_FIELD, align: LEGAL_NAME_ALIGN },
        //         { colName: OFFICE_EMAIL, width: OFFICE_EMAIL_WIDTH, field: OFFICE_EMAIL_FIELD, align: OFFICE_EMAIL_ALIGN },
        //         { colName: REVISED_ROLE_TITLE, width: REVISED_ROLE_TITLE_WIDTH, field: REVISED_ROLE_TITLE_FIELD, align: REVISED_ROLE_TITLE_ALIGN },
        //         { colName: LAYER, width: LAYER_WIDTH, field: LAYER_FIELD, align: LAYER_ALIGN },
        //         { colName: SUB_LAYER, width: SUB_LAYER_WIDTH, field: SUB_LAYER_FIELD, align: SUB_LAYER_ALIGN },
        //         { colName: BUSINESS_UNIT_FUNCTION, width: BUSINESS_UNIT_FUNCTION_WIDTH, field: BUSINESS_UNIT_FUNCTION_FIELD, align: BUSINESS_UNIT_FUNCTION_ALIGN },
        //         { colName: SUB_BUSINESS_UNIT, width: SUB_BUSINESS_UNIT_WIDTH, field: SUB_BUSINESS_UNIT_FIELD, align: SUB_BUSINESS_UNIT_ALIGN },
        //         { colName: PRACTICE_AREA_SALES_UNIT_SUB_FUNCTION, width: PRACTICE_AREA_SALES_UNIT_SUB_FUNCTION_WIDTH, field: PRACTICE_AREA_SALES_UNIT_SUB_FUNCTION_FIELD, align: PRACTICE_AREA_SALES_UNIT_SUB_FUNCTION_ALIGN },
        //         { colName: FUNCTIONAL_REPORTING_SUPERVISOR, width: FUNCTIONAL_REPORTING_SUPERVISOR_WIDTH, field: FUNCTIONAL_REPORTING_SUPERVISOR_FIELD, align: FUNCTIONAL_REPORTING_SUPERVISOR_ALIGN },
        //         { colName: ADMINISTRATIVE_REPORTING_SUPERVISOR, width: ADMINISTRATIVE_REPORTING_SUPERVISOR_WIDTH, field: ADMINISTRATIVE_REPORTING_SUPERVISOR_FIELD, align: ADMINISTRATIVE_REPORTING_SUPERVISOR_ALIGN },
        //         { colName: DATE_OF_JOINING, width: DATE_OF_JOINING_WIDTH, field: DATE_OF_JOINING_FIELD, align: DATE_OF_JOINING_ALIGN },
        //         { colName: BASECOUNTRY, width: BASECOUNTRY_WIDTH, field: BASECOUNTRY_FIELD, align: BASECOUNTRY_ALIGN },
        //         { colName: BASE_LOCATION1, width: BASE_LOCATION_WIDTH1, field: BASE_LOCATION_FIELD1, align: BASE_LOCATION_ALIGN1 },
        //         { colName: HIGHEST_QUALIFICATION, width: HIGHEST_QUALIFICATION_WIDTH, field: HIGHEST_QUALIFICATION_FIELD, align: HIGHEST_QUALIFICATION_ALIGN },
        //         { colName: SPECIALIZATION, width: SPECIALIZATION_WIDTH, field: SPECIALIZATION_FIELD, align: SPECIALIZATION_ALIGN },
        //         { colName: WORK_EXPERIENCE, width: WORK_EXPERIENCE_WIDTH, field: WORK_EXPERIENCE_FIELD, align: WORK_EXPERIENCE_ALIGN },
        //         { colName: TENURE_END_DATE, width: TENURE_END_DATE_WIDTH, field: TENURE_END_DATE_FIELD, align: TENURE_END_DATE_ALIGN },
        //         { colName: ACTIVE_INACTIVE, width: ACTIVE_INACTIVE_WIDTH, field: ACTIVE_INACTIVE_FIELD, align: ACTIVE_INACTIVE_ALIGN },

        //     ]);
        case 'nonpeoplefunctionleftemployee':
            return (
                [
                    { colName: EMPLOYEE_LIST_EMPLOYEE_NAME, width: EMPLOYEE_NAME_LIST_WIDTH, field: EMPLOYEE_NAME_LIST_FIELD, align: EMPLOYEE_NAME_LIST_ALIGN },
                    { colName: EMPLOYEE_CODE, width: EMPLOYEE_CODE_WIDTH, field: EMPLOYEE_CODE_FIELD, align: EMPLOYEE_CODE_ALIGN },
                    { colName: DATE_OF_JOINING, width: DATE_OF_JOINING_WIDTH, field: DATE_OF_JOINING_FIELD, align: DATE_OF_JOINING_ALIGN },
                    { colName: FUNCTIONAL_REPORTING_SUPERVISOR, width: FUNCTIONAL_REPORTING_SUPERVISOR_WIDTH, field: FUNCTIONAL_REPORTING_SUPERVISOR_FIELD, align: FUNCTIONAL_REPORTING_SUPERVISOR_ALIGN },
                    { colName: ADMINISTRATIVE_REPORTING_SUPERVISOR, width: ADMINISTRATIVE_REPORTING_SUPERVISOR_WIDTH, field: ADMINISTRATIVE_REPORTING_SUPERVISOR_FIELD, align: ADMINISTRATIVE_REPORTING_SUPERVISOR_ALIGN },
                    { colName: BUSINESS_UNIT_FUNCTION, width: BUSINESS_UNIT_FUNCTION_WIDTH, field: BUSINESS_UNIT_FUNCTION_FIELD, align: BUSINESS_UNIT_FUNCTION_ALIGN },
                    { colName: SUB_BUSINESS_UNIT, width: SUB_BUSINESS_UNIT_WIDTH, field: SUB_BUSINESS_UNIT_FIELD, align: SUB_BUSINESS_UNIT_ALIGN },
                    { colName: PRACTICE_AREA_SALES_UNIT_SUB_FUNCTION, width: PRACTICE_AREA_SALES_UNIT_SUB_FUNCTION_WIDTH, field: PRACTICE_AREA_SALES_UNIT_SUB_FUNCTION_FIELD, align: PRACTICE_AREA_SALES_UNIT_SUB_FUNCTION_ALIGN },
                    { colName: LAYER, width: LAYER_WIDTH, field: LAYER_FIELD, align: LAYER_ALIGN },
                    { colName: SUB_LAYER, width: SUB_LAYER_WIDTH, field: SUB_LAYER_FIELD, align: SUB_LAYER_ALIGN },
                    { colName: REVISED_ROLE_TITLE, width: REVISED_ROLE_TITLE_WIDTH, field: REVISED_ROLE_TITLE_FIELD, align: REVISED_ROLE_TITLE_ALIGN },
                    { colName: ACTIVE_INACTIVE, width: ACTIVE_INACTIVE_WIDTH, field: ACTIVE_INACTIVE_FIELD, align: ACTIVE_INACTIVE_ALIGN },
                    { colName: LEFT_DATE, width: LEFT_DATE_WIDTH, field: LEFT_DATE_FIELD, align: LEFT_DATE_ALIGN },
                ]
            );
        case 'level1':
            return (
                [
                    { colName: GROUP_NAME_ORG_MASTER, width: GROUP_NAME_WIDTH_ORG_MASTER, field: GROUP_NAME_FIELD_ORG_MASTER, align: GROUP_NAME_ALIGN_ORG_MASTER },
                    { colName: LEAGAL_NAME_ORG_MASTER, width: LEAGAL_NAME_WIDTH_ORG_MASTER, field: LEAGAL_NAME_FIELD_ORG_MASTER, align: LEAGAL_NAME_ALIGN_ORG_MASTER },
                    { colName: COMPANY_NAME, width: COMPANY_NAME_WIDTH, field: COMPANY_NAME_FIELD, align: COMPANY_NAME_ALIGN },
                    // { colName: GROUP_HEAD_ORG_MASTER, width: GROUP_HEAD_WIDTH_ORG_MASTER, field:GROUP_HEAD_FIELD_ORG_MASTER,align:GROUP_HEAD_ALIGN_ORG_MASTER },
                    // { colName: LEVEL_ORG_MASTER, width: LEVEL_WIDTH_ORG_MASTER, field:LEVEL_FIELD_ORG_MASTER1,align:LEVEL_ALIGN_ORG_MASTER },
                    { colName: ACTING_HEAD_ORG_MASTER, width: ACTING_HEAD_WIDTH_ORG_MASTER, field: ACTING_HEAD_FIELD_ORG_MASTER1, align: ACTING_HEAD_ALIGN_ORG_MASTER },
                    { colName: TEMP, width: TEMP_WIDTH, field: TEMP_FIELD, align: TEMP_ALIGN },
                ]
            );
        case 'level2':
            return (
                [
                    { colName: PNL_HEAD, width: PNL_HEAD_WIDTH, field: PNL_HEAD_FIELD_ORGCHRT, align: PNL_HEAD_ALIGN },
                    { colName: ACTING_HEAD_ORG_MASTER, width: ACTING_HEAD_WIDTH_ORG_MASTER, field: ACTING_HEAD_FIELD_ORG_MASTER2, align: ACTING_HEAD_ALIGN_ORG_MASTER },
                    { colName: PnL_FUNCTION_NAME, width: PnL_FUNCTION_NAME_WIDTH, field: PnL_FUNCTION_NAME_FIELD, align: PnL_FUNCTION_NAME_ALIGN },
                    { colName: COMPANY_NAME, width: COMPANY_NAME_WIDTH, field: COMPANY_NAME_FIELD, align: COMPANY_NAME_ALIGN },
                    { colName: ABBRIVATION, width: ABBRIVATION_WIDTH, field: ABBRIVATION_FIELD, align: ABBRIVATION_ALIGN },
                    { colName: DPMAIN, width: DPMAIN_WIDTH, field: DPMAIN_FIELD, align: DPMAIN_ALIGN },
                    // { colName: LEVEL_ORG_MASTER, width: LEVEL_WIDTH_ORG_MASTER, field:LEVEL2_FIELD_ORG_MASTER,align:LEVEL_ALIGN_ORG_MASTER },
                    { colName: ACTIVE, width: ACTIVE_WIDTH, field: ACTIVE_FIELD1, align: ACTIVE_ALIGN },
                    { colName: TEMP, width: TEMP_WIDTH, field: TEMP_FIELD, align: TEMP_ALIGN },
                ]
            );
        case 'level3':
            return (
                [
                    { colName: SUB_PNL_FUNCTION_NAME, width: SUB_PNL_FUNCTION_NAME_WIDTH, field: SUB_PNL_FUNC_NAME_FIELD, align: SUB_PNL_FUNCTION_NAME_ALIGN },
                    { colName: SUB_PNL_FUNCTION_HEAD, width: SUB_PNL_FUNCTION_HEAD_WIDTH, field: SUB_PNL_FUNCTION_HEAD_FIELD, align: SUB_PNL_FUNCTION_HEAD_ALIGN },
                    { colName: ACTING_HEAD_ORG_MASTER, width: ACTING_HEAD_WIDTH_ORG_MASTER, field: ACTING_HEAD_FIELD_ORG_MASTER3, align: ACTING_HEAD_ALIGN_ORG_MASTER },
                    { colName: COMPANY_NAME, width: COMPANY_NAME_WIDTH, field: COMPANY_NAME_FIELD, align: COMPANY_NAME_ALIGN },
                    { colName: PnL_FUNCTION_NAME, width: PnL_FUNCTION_NAME_WIDTH, field: PnL_FUNCTION_NAME_FIELD, align: PnL_FUNCTION_NAME_ALIGN },
                    // { colName: LEVEL_ORG_MASTER, width: LEVEL_WIDTH_ORG_MASTER, field:LEVEL3_FIELD_ORG_MASTER,align:LEVEL_ALIGN_ORG_MASTER },
                    { colName: ACTIVE, width: ACTIVE_WIDTH, field: ACTIVE_FIELD3, align: ACTIVE_ALIGN },
                    { colName: TEMP, width: TEMP_WIDTH, field: TEMP_FIELD, align: TEMP_ALIGN },
                    // { colName: ABBRIVATION, width: ABBRIVATION_WIDTH, field:ABBRIVATION_FIELD,align:ABBRIVATION_ALIGN },
                ]
            );
        case 'level4':
            return (
                [
                    { colName: BUSINESS_PNL_HEAD, width: BUSINESS_PNL_HEAD_WIDTH, field: BUSINESS_PNL_HEAD_FIELD, align: BUSINESS_PNL_HEAD_ALIGN },
                    { colName: SUB_PNL_FUNCTION_NAME, width: SUB_PNL_FUNCTION_NAME_WIDTH, field: SUB_PNL_FUNCTION_NAME_FIELD, align: SUB_PNL_FUNCTION_NAME_ALIGN },
                    { colName: SUB_PNL_FUNCTION_HEAD, width: SUB_PNL_FUNCTION_HEAD_WIDTH, field: FKSUB_PNL_FUNCTION_HEAD_FIELD, align: SUB_PNL_FUNCTION_HEAD_ALIGN },
                    { colName: ACTING_HEAD_ORG_MASTER, width: ACTING_HEAD_WIDTH_ORG_MASTER, field: ACTING_HEAD_FIELD_ORG_MASTER4, align: ACTING_HEAD_ALIGN_ORG_MASTER },
                    // { colName: LEVEL_ORG_MASTER, width: LEVEL_WIDTH_ORG_MASTER, field:LEVEL4_FIELD_ORG_MASTER,align:LEVEL_ALIGN_ORG_MASTER },
                    { colName: ACTIVE, width: ACTIVE_WIDTH, field: ACTIVE_FIELD4, align: ACTIVE_ALIGN },
                    { colName: TEMP, width: TEMP_WIDTH, field: TEMP_FIELD, align: TEMP_ALIGN },
                ]
            );
        case 'level5':
            return (
                [
                    { colName: SUB_PNL_FUNCTION_NAME, width: SUB_PNL_FUNCTION_NAME_WIDTH, field: SUB_PNL_FUNCTION_NAME_FIELD, align: SUB_PNL_FUNCTION_NAME_ALIGN },
                    { colName: BUSINESS_SUB_PNL_FUNC_NAME, width: BUSINESS_SUB_PNL_FUNC_NAME_WIDTH, field: BUSINESS_SUB_PNL_FUNC_NAME_FIELD, align: BUSINESS_SUB_PNL_FUNC_NAME_ALIGN },
                    { colName: SERVICE_DIVISION_NAME, width: SERVICE_DIVISION_NAME_WIDTH, field: SERVICE_DIVISION_NAME_FIELD, align: SERVICE_DIVISION_NAME_ALIGN },
                    { colName: SERVICE_DIVISION_HEAD_NAME, width: SERVICE_DIVISION_HEAD_NAME_WIDTH, field: SERVICE_DIVISION_HEAD_NAME_FIELD, align: SERVICE_DIVISION_HEAD_NAME_ALIGN },
                    { colName: ACTING_HEAD_ORG_MASTER, width: ACTING_HEAD_WIDTH_ORG_MASTER, field: ACTING_HEAD_FIELD_ORG_MASTER5, align: ACTING_HEAD_ALIGN_ORG_MASTER },
                    // { colName: LEVEL_ORG_MASTER, width: LEVEL_WIDTH_ORG_MASTER, field:LEVEL5_FIELD_ORG_MASTER,align:LEVEL_ALIGN_ORG_MASTER },
                    { colName: ACTIVE, width: ACTIVE_WIDTH, field: ACTIVE_FIELD5, align: ACTIVE_ALIGN },
                    { colName: TEMP, width: TEMP_WIDTH, field: TEMP_FIELD, align: TEMP_ALIGN },
                ]
            );
        case 'teamshared':
            return (
                [
                    { colName: EMPNAME, width: EMPNAME_WIDTH, field: EMPNAME_FIELD, align: EMPNAME_ALIGN },
                    { colName: OKRSTATUS, width: OKRSTATUS_WIDTH, field: OKRSTATUS_FIELD, align: OKRSTATUS_ALIGN },
                    { colName: SHAREDWITH, width: SHAREDWITH_WIDTH, field: SHAREDWITH_FIELD, align: SHAREDWITH_ALIGN },
                    { colName: FROM, width: FROM_WIDTH, field: FROM_FIELD, align: FROM_ALIGN },
                    { colName: TO, width: TO_WIDTH, field: TO_FIELD, align: TO_ALIGN },
                    { colName: SHAREDPER, width: SHAREDPER_WIDTH, field: SHAREDPER_FIELD, align: SHAREDPER_ALIGN },
                    { colName: SHAREDAMT, width: SHAREDAMT_WIDTH, field: SHAREDAMT_FIELD, align: SHAREDAMT_ALIGN },
                    { colName: DESCRIPTIONOKR, width: DESCRIPTIONOKR_WIDTH, field: DESCRIPTIONOKR_FIELD, align: DESCRIPTIONOKR_ALIGN },
                    // { colName: OKR, width: OKR_WIDTH, field: OKR_FIELD, align: OKR_ALIGN },
                    { colName: TEMP, width: TEMP_WIDTH, field: TEMP_FIELD, align: TEMP_ALIGN },

                    // { colName: ACTIONOKR, width: ACTIONOKR_WIDTH, field: ACTIONOKR_FIELD, align: ACTIONOKR_ALIGN },
                ]
            );
        case 'sharedWithMe':
            return (
                [
                    { colName: EMPNAME, width: EMPNAME_WIDTH, field: EMPNAME_FIELD, align: EMPNAME_ALIGN },
                    { colName: FUNCTIONNAME, width: FUNCTIONNAME_WIDTH, field: FUNCTIONNAME_FIELD, align: FUNCTIONNAME_ALIGN },
                    { colName: OKRSTATUS, width: OKRSTATUS_WIDTH, field: OKRSTATUS_FIELD, align: OKRSTATUS_ALIGN },
                    { colName: FROM, width: FROM_WIDTH, field: FROM_FIELD, align: FROM_ALIGN },
                    { colName: TO, width: TO_WIDTH, field: TO_FIELD, align: TO_ALIGN },
                    { colName: SHAREDPER, width: SHAREDPER_WIDTH, field: SHAREDPER_FIELD, align: SHAREDPER_ALIGN },
                    { colName: SHAREDAMT, width: SHAREDAMT_WIDTH, field: SHAREDAMT_FIELD, align: SHAREDAMT_ALIGN },
                    { colName: DESCRIPTIONOKR, width: DESCRIPTIONOKR_WIDTH, field: DESCRIPTIONOKR_FIELD, align: DESCRIPTIONOKR_ALIGN },
                    // { colName: OKR, width: OKR_WIDTH, field: OKR_FIELD, align: OKR_ALIGN },
                    // { colName: TEMP, width: TEMP_WIDTH, field: TEMP_FIELD, align: TEMP_ALIGN },

                    { colName: ACTIONOKR, width: ACTIONOKR_WIDTH, field: ACTIONOKR_FIELD, align: ACTIONOKR_ALIGN },
                ]
            );
        case 'requests':
            return (
                [
                    { colName: EMPNAME, width: EMPNAME_WIDTH, field: EMPNAME_FIELD, align: EMPNAME_ALIGN },
                    { colName: FUNCTIONNAME, width: FUNCTIONNAME_WIDTH, field: FUNCTIONNAME_FIELD, align: FUNCTIONNAME_ALIGN },
                    { colName: TYPE, width: TYPE_WIDTH, field: TYPE_FIELD, align: TYPE_ALIGN },
                    { colName: FROM, width: FROM_WIDTH, field: FROM_FIELD, align: FROM_ALIGN },
                    { colName: TO, width: TO_WIDTH, field: TO_FIELD, align: TO_ALIGN },
                    { colName: SHAREDPER, width: SHAREDPER_WIDTH, field: SHAREDPER_FIELD, align: SHAREDPER_ALIGN },
                    { colName: SHAREDAMT, width: SHAREDAMT_WIDTH, field: SHAREDAMT_FIELD, align: SHAREDAMT_ALIGN },
                    { colName: REQREASON, width: REQREASON_WIDTH, field: REQREASON_FIELD, align: REQREASON_ALIGN },
                    // { colName: OKR, width: OKR_WIDTH, field: OKR_FIELD, align: OKR_ALIGN },
                    // { colName: TEMP, width: TEMP_WIDTH, field: TEMP_FIELD, align: TEMP_ALIGN },

                    { colName: ACTIONOKR, width: ACTIONOKR_WIDTH, field: ACTIONOKR_FIELD, align: ACTIONOKR_ALIGN },
                ]
            );
        case 'productiveAllocation':
            return (
                [
                    { colName: ALLOCATION_NAME, width: ALLOCATION_NAME_WIDTH, field: ALLOCATION_NAME_FIELD, align: ALLOCATION_NAME_ALIGN },
                    { colName: IMPACT_RANGE, width: IMPACT_RANGE_WIDTH, field: IMPACT_RANGE_FIELD, align: IMPACT_RANGE_ALIGN },
                    { colName: IMPACT, width: IMPACT_WIDTH, field: IMPACT_FIELD, align: IMPACT_ALIGN },
                    { colName: FREQUENCY, width: FREQUENCY_WIDTH, field: FREQUENCY_FIELD, align: FREQUENCY_ALIGN },
                    { colName: OTE_VAR_IMPACT, width: OTE_VAR_IMPACT_WIDTH, field: OTE_VAR_IMPACT_FIELD, align: OTE_VAR_IMPACT_ALIGN },
                    { colName: RATING_MECHANISM, width: RATING_MECHANISM_WIDTH, field: RATING_MECHANISM_FIELD, align: RATING_MECHANISM_ALIGN },
                    // { colName: PRODUCT_ALLOCATION_EDIT, width: PRODUCT_ALLOCATION_EDIT_WIDTH, field:PRODUCT_ALLOCATION_EDIT_FIELD,align:PRODUCT_ALLOCATION_EDIT_ALIGN },
                ]
            );
        case 'OKRFeedback':
            return (
                [
                    { colName: DATE, width: DATE_WIDTH, field: DATE_FIELD, align: DATE_ALIGN },
                    { colName: GIVEN_BY_NAME, width: GIVEN_BY_NAME_WIDTH, field: GIVEN_BY_NAME_FIELD, align: GIVEN_BY_NAME_ALIGN },
                    { colName: DEPARTMENT_NAME, width: DEPARTMENT_NAME_WIDTH, field: DEPARTMENT_NAME_FIELD, align: DEPARTMENT_NAME_ALIGN },
                    { colName: CATEGORY_NAME, width: CATEGORY_NAME_WIDTH, field: CATEGORY_NAME_FIELD, align: CATEGORY_NAME_ALIGN },
                    { colName: DISCRIPTION, width: DISCRIPTION_WIDTH, field: DISCRIPTION_FIELD, align: DISCRIPTION_ALIGN },
                    { colName: UPLOAD_DOCUMENT, width: UPLOAD_DOCUMENT_WIDTH, field: UPLOAD_DOCUMENT_FIELD, align: UPLOAD_DOCUMENT_ALIGN },
                    { colName: GIVEN_BY, width: GIVEN_BY_WIDTH, field: GIVEN_BY_FIELD, align: GIVEN_BY_ALIGN },
                    { colName: LINK, width: LINK_WIDTH, field: LINK_FIELD, align: LINK_ALIGN },
                    { colName: FEEDBACK, width: FEEDBACK_WIDTH, field: FEEDBACK_FIELD, align: FEEDBACK_ALIGN },
                    { colName: MEETING_REQUEST, width: MEETING_REQUEST_WIDTH, field: MEETING_REQUEST_FIELD, align: MEETING_REQUEST_ALIGN },
                ]
            );
        case 'saveAdDraft':
            return (
                [
                    { colName: DATE, width: DATE_WIDTH, field: DATE_FIELD, align: DATE_ALIGN },
                    { colName: DRAFT_DISCRIPTION, width: DRAFT_DISCRIPTION_WIDTH, field: DRAFT_DISCRIPTION_FIELD, align: DRAFT_DISCRIPTION_ALIGN },
                    { colName: DRAFTACTION, width: DRAFTACTION_WIDTH, field: DRAFTACTION_FIELD, align: DRAFTACTION_ALIGN },
                ]
            );
        case 'orgChartMasterAdmin':
            return (
                [
                    { colName: BUSINESS_UNIT_FUNCTION, width: BUSINESS_UNIT_FUNCTION_WIDTH_NEW, field: BUSINESS_UNIT_FUNCTION_FIELD_NEW, align: BUSINESS_UNIT_FUNCTION_ALIGN },
                    { colName: SUBBUSINESS, width: SUBBUSINESS_UNIT_FUNCTION_WIDTH_NEW, field: SUBBUSINESS_FIELD, align: SUBBUSINESS_ALIGN },
                    { colName: PRACTICE_AREA_SALES_UNIT_SUB_FUNCTION, width: PRACTICE_AREA_SALES_UNIT_SUB_FUNCTION_WIDTH_NEW, field: PRACTICE_AREA_SALES_UNIT_SUB_FUNCTION_FIELD_MASTER, align: PRACTICE_AREA_SALES_UNIT_SUB_FUNCTION_ALIGN },
                    { colName: LAYER, width: LAYER_WIDTH_NEW, field: LAYER_FIELD_MASTER, align: LAYER_ALIGN },
                    { colName: SUB_LAYER, width: SUB_LAYER_WIDTH_NEW, field: SUB_LAYER_FIELD_MASTER, align: SUB_LAYER_ALIGN },
                    { colName: ROLE_MASTER, width: ROLE_MASTER_WIDTH_NEW, field: ROLE_MASTER_FIELD, align: ROLE_MASTER_ALIGN },
                    { colName: HIERARCHY, width: HIERARCHY_WIDTH_NEW, field: HIERARCHY_FIELD, align: HIERARCHY_ALIGN },
                    { colName: COMPANY_NAME, width: COMPANY_NAME_WIDTH_NEW, field: COMPANY_NAME_FIELD, align: COMPANY_NAME_ALIGN },
                    { colName: ABBREVATION, width: ABBREVATION_WIDTH_NEW, field: ABBREVATION_FIELD_MASTER, align: ABBREVATION_ALIGN },
                    { colName: DOMAIN, width: DOMAIN_WIDTH_NEW, field: DOMAIN_FIELD, align: DOMAIN_ALIGN },
                    { colName: EMPLOYEE_COUNT, width: EMPLOYEE_COUNT_WIDTH_NEW, field: EMPLOYEE_COUNT_FIELD, align: EMPLOYEE_COUNT_ALIGN },
                    { colName: ACTION, width: ACTION_WIDTH_NEW, field: ACTION_FIELD, align: ACTION_ALIGN },
                ]
            );
        case 'orgChartMappingAdminAll':
            return (
                [
                    { colName: BUSINESS_UNIT_FUNCTION, width: MAPPINGBUSINESS_UNIT_FUNCTION_WIDTH_NEW, field: BUSINESS_UNIT_FUNCTION_MAPPING_FIELD, align: BUSINESS_UNIT_FUNCTION_ALIGN },
                    { colName: SUBBUSINESS, width: MAPPINGSUBBUSINESS_UNIT_FUNCTION_WIDTH_NEW, field: SUB_BUSINESS_UNIT_FUNCTION_MAPPING_FIELD, align: SUBBUSINESS_ALIGN },
                    { colName: PRACTICE_AREA_SALES_UNIT_SUB_FUNCTION, width: MAPPINGPRACTICE_AREA_SALES_UNIT_SUB_FUNCTION_WIDTH_NEW, field: PRACTICE_AREA_UNIT_FUNCTION_MAPPING_FIELD, align: PRACTICE_AREA_SALES_UNIT_SUB_FUNCTION_ALIGN },
                    { colName: LAYER, width: MAPPINGLAYER_WIDTH_NEW, field: LAYER_NAME_UNIT_FUNCTION_MAPPING_FIELD, align: LAYER_ALIGN },
                    { colName: SUB_LAYER, width: MAPPINGSUB_LAYER_WIDTH_NEW, field: SUB_LAYER_FIELD_MASTER, align: SUB_LAYER_ALIGN },
                    { colName: ROLE_MASTER, width: MAPPINGROLE_MASTER_WIDTH_NEW, field: ROLE_MASTER_FIELD, align: ROLE_MASTER_ALIGN },
                    { colName: HIERARCHY, width: MAPPINGHIERARCHY_WIDTH_NEW, field: HIERARCHY_FIELD, align: HIERARCHY_ALIGN },
                    { colName: EMPLOYEE_COUNT, width: MAPPINGEMPLOYEE_COUNT_WIDTH_NEW, field: EMPLOYEE_COUNT_FIELD, align: EMPLOYEE_COUNT_ALIGN },
                    { colName: ACTION, width: MAPPINGACTION_WIDTH_NEW, field: ACTION_FIELD, align: ACTION_ALIGN },
                ]
            );
        case 'holidayCluster':
            return (
                [
                    { colName: ADMIN_MODULE_ACTION, width: '80', field: ADMIN_MODULE_ACTION_NAME_FIELD, align: ADMIN_MODULE_ACTION_ALIGN },
                    { colName: HOLIDAY_CLUSTER_HOLIDAY_NAME, width: '190', field: HOLIDAY_CLUSTER_HOLIDAY_NAME_FIELD, align: HOLIDAY_CLUSTER_HOLIDAY_NAME_ALIGN },
                    { colName: HOLIDAY_CLUSTER_DATE, width: '80', field: HOLIDAY_CLUSTER_DATE_FIELD, align: HOLIDAY_CLUSTER_DATE_ALIGN },
                    { colName: HOLIDAY_CLUSTER_DAY, width: '80', field: HOLIDAY_CLUSTER_DAY_FIELD, align: HOLIDAY_CLUSTER_DAY_ALIGN },
                    { colName: HOLIDAY_CLUSTER_COUNTRY, width: '125', field: HOLIDAY_CLUSTER_COUNTRY_FIELD, align: HOLIDAY_CLUSTER_COUNTRY_ALIGN },
                    // { colName: HOLIDAY_CLUSTER_LOCATION_CITY, width: '125', field: HOLIDAY_CLUSTER_LOCATION_CITY_FIELD, align: HOLIDAY_CLUSTER_LOCATION_CITY_ALIGN },
                    // { colName: RESET_DATE, width: '100', field: RESET_DATE_FIELD, align: RESET_DATE_ALIGN },
                    // { colName: ATTACH_POLICY, width: '100', field: ATTACH_POLICY_FIELD, align: ATTACH_POLICY_ALIGN },
                    { colName: POLICY_DOC, width: '100', field: POLICY_DOC_FIELD, align: POLICY_DOC_ALIGN },
                    { colName: HOLIDAY_CLUSTER_TYPE, width: '80', field: HOLIDAY_CLUSTER_TYPE_FIELD, align: HOLIDAY_CLUSTER_TYPE_ALIGN },
                    // { colName: HOLIDAY_CLUSTER_FINANCIAL_YEAR, width: '100', field: HOLIDAY_CLUSTER_FINANCIAL_YEAR_FIELD, align: HOLIDAY_CLUSTER_FINANCIAL_YEAR_ALIGN },
                    { colName: HOLIDAY_CLUSTER_IS_ACTIVE, width: '70', field: HOLIDAY_CLUSTER_IS_ACTIVE_FIELD, align: HOLIDAY_CLUSTER_IS_ACTIVE_ALIGN },

                ]
            );


        case 'optionalHolidayCluster':
            return (
                [{ colName: ADMIN_MODULE_ACTION, width: '80', field: ADMIN_MODULE_ACTION_NAME_FIELD, align: ADMIN_MODULE_ACTION_ALIGN },
                { colName: OPTIONAL_HOLIDAY_CLUSTER_COUNTRY, width: '140', field: OPTIONAL_HOLIDAY_CLUSTER_COUNTRY_FIELD, align: OPTIONAL_HOLIDAY_CLUSTER_COUNTRY_ALIGN },
                // { colName: OPTIONAL_HOLIDAY_CLUSTER_LOCATION_CITY, width: '140', field: OPTIONAL_HOLIDAY_CLUSTER_LOCATION_CITY_FIELD, align: OPTIONAL_HOLIDAY_CLUSTER_LOCATION_CITY_ALIGN },
                { colName: OPTIONAL_HOLIDAY_CLUSTER_NO_OPTIONAL, width: '100', field: OPTIONAL_HOLIDAY_CLUSTER_NO_OPTIONAL_FIELD, align: OPTIONAL_HOLIDAY_CLUSTER_NO_OPTIONAL_ALIGN },
                { colName: OPTIONAL_HOLIDAY_CLUSTER_CHANGE_REQ_DATE, width: '130', field: OPTIONAL_HOLIDAY_CLUSTER_CHANGE_REQ_DATE_FIELD, align: OPTIONAL_HOLIDAY_CLUSTER_CHANGE_REQ_DATE_ALIGN },
                // { colName: RESET_DATE, width: '90', field: RESET_DATE_FIELD, align: RESET_DATE_ALIGN },
                // { colName: OPTIONAL_HOLIDAY_CLUSTER_REPORTING_APPROVAL, width: '120', field: OPTIONAL_CLUSTER_REPORTING_APPROVAL_FIELD, align: OPTIONAL_CLUSTER_REPORTING_APPROVAL_ALIGN },
                // { colName: OPTIONAL_HOLIDAY_CLUSTER_HR_REPORTING_APPROVAL, width: '90', field: OPTIONAL_CLUSTER_HR_REPORTING_APPROVAL_FIELD, align: OPTIONAL_CLUSTER_HR_REPORTING_APPROVAL_ALIGN },
                { colName: POLICY_DOC, width: '120', field: POLICY_DOC_FIELD, align: POLICY_DOC_ALIGN },
                { colName: EFFECTIVE_START_DATE, width: EFFECTIVE_START_DATE_WIDTH, field: EFFECTIVE_START_DATE_FIELD, align: EFFECTIVE_START_DATE_ALIGN },
                { colName: EFFECTIVE_END_DATE, width: EFFECTIVE_END_DATE_WIDTH, field: EFFECTIVE_END_DATE_FIELD, align: EFFECTIVE_END_DATE_ALIGN },
                { colName: OPTIONAL_HOLIDAY_CLUSTER_IS_ACTIVE, width: '80', field: OPTIONAL_HOLIDAY_CLUSTER_IS_ACTIVE_FIELD, align: OPTIONAL_HOLIDAY_CLUSTER_IS_ACTIVE_ALIGN },

                ]
            );
        case 'attendanceCluster':
            return (
                [
                    { colName: ADMIN_MODULE_ACTION, width: '80', field: ADMIN_MODULE_ACTION_NAME_FIELD, align: ADMIN_MODULE_ACTION_ALIGN },
                    { colName: ATTENDANCE_CLUSTER_ATTENDANCE_NAME, width: ATTENDANCE_CLUSTER_ATTENDANCE_NAME_WIDTH, field: ATTENDANCE_CLUSTER_ATTENDANCE_NAME_FIELD, align: ATTENDANCE_CLUSTER_ATTENDANCE_NAME_ALIGN },
                    { colName: ATTENDANCE_CLUSTER_ATTENDANCE_SYMBOL, width: ATTENDANCE_CLUSTER_ATTENDANCE_SYMBOL_WIDTH, field: ATTENDANCE_CLUSTER_ATTENDANCE_SYMBOL_FIELD, align: ATTENDANCE_CLUSTER_ATTENDANCE_SYMBOL_ALIGN },
                    { colName: COLOUR_CLUSTER_COLOUR, width: COLOR_CLUSTER_COLOR_NAME_WIDTH, field: COLOUR_CLUSTER_NAME_FIELD, align: COLOUR_CLUSTER_COLOUR_ALIGN },
                    { colName: ATTENDANCE_CLUSTER_ATTENDANCE_DESCRIPTION, width: ATTENDANCE_CLUSTER_ATTENDANCE_DESCRIPTION_WIDTH, field: ATTENDANCE_CLUSTER_ATTENDANCE_DESCRIPTION_FIELD, align: ATTENDANCE_CLUSTER_ATTENDANCE_DESCRIPTION_ALIGN },
                    { colName: ATTENDANCE_CLUSTER_ATTENDANCE_TYPE, width: ATTENDANCE_CLUSTER_ATTENDANCE_TYPE_WIDTH, field: ATTENDANCE_CLUSTER_ATTENDANCE_TYPE_FIELD, align: ATTENDANCE_CLUSTER_ATTENDANCE_TYPE_ALIGN },
                    { colName: ATTENDANCE_CLUSTER_SPECIAL_ATTENDANCE, width: ATTENDANCE_CLUSTER_SPECIAL_ATTENDANCE_WIDTH, field: ATTENDANCE_CLUSTER_SPECIAL_ATTENDANCE_FIELD, align: ATTENDANCE_CLUSTER_SPECIAL_ATTENDANCE_ALIGN },
                    // { colName: ATTENDANCE_CLUSTER_DAYS_BACKDATED, width: ATTENDANCE_CLUSTER_DAYS_BACKDATED_WIDTH, field: ATTENDANCE_CLUSTER_DAYS_BACKDATED_FIELD, align: ATTENDANCE_CLUSTER_DAYS_BACKDATED_ALIGN },
                    // { colName: ATTENDANCE_CLUSTER_DAYS_FUTUREDATED, width: ATTENDANCE_CLUSTER_DAYS_FUTUREDATED_WIDTH, field: ATTENDANCE_CLUSTER_DAYS_FUTUREDATED_FIELD, align: ATTENDANCE_CLUSTER_DAYS_FUTUREDATED_ALIGN },
                    // { colName: ATTENDANCE_CLUSTER_HR_APPROVAL, width: ATTENDANCE_CLUSTER_HR_APPROVAL_WIDTH, field: ATTENDANCE_CLUSTER_HR_APPROVAL_FIELD, align: ATTENDANCE_CLUSTER_HR_APPROVAL_ALIGN },
                    // { colName: ATTENDANCE_CLUSTER_REPORTING_APPROVAL, width: ATTENDANCE_CLUSTER_REPORTING_APPROVAL_WIDTH, field: ATTENDANCE_CLUSTER_REPORTING_APPROVAL_FIELD, align: ATTENDANCE_CLUSTER_REPORTING_APPROVAL_ALIGN },
                    { colName: EFFECTIVE_START_DATE, width: '150', field: EFFECTIVE_START_DATE_FIELD, align: EFFECTIVE_START_DATE_ALIGN },
                    { colName: EFFECTIVE_END_DATE, width: '150', field: EFFECTIVE_END_DATE_FIELD, align: EFFECTIVE_END_DATE_ALIGN },
                    // { colName: POLICY_DOC, width:'50', field: POLICY_DOC_FIELD, align: POLICY_DOC_ALIGN },
                    { colName: ATTENDANCE_CLUSTER_ATTENDANCE_ATTACH_NOTE, width: '150', field: ATTENDANCE_CLUSTER_ATTENDANCE_ATTACH_NOTE_FIELD, align: ATTENDANCE_CLUSTER_ATTENDANCE_ATTACH_NOTE_ALIGN },

                    // {colName: ATTENDANCE_CLUSTER_FINANCIAL_YEAR, width: ATTENDANCE_CLUSTER_FINANCIAL_YEAR_WIDTH, field: ATTENDANCE_CLUSTER_FINANCIAL_YEAR_FIELD, align: ATTENDANCE_CLUSTER_FINANCIAL_YEAR_ALIGN},
                    { colName: ATTENDANCE_CLUSTER_IS_ACTIVE, width: ATTENDANCE_CLUSTER_IS_ACTIVE_WIDTH, field: ATTENDANCE_CLUSTER_IS_ACTIVE_FIELD, align: ATTENDANCE_CLUSTER_IS_ACTIVE_ALIGN },
                ]
            );
        case 'weekdayCluster':
            return (
                [
                    { colName: ADMIN_MODULE_ACTION, width: '80', field: ADMIN_MODULE_ACTION_NAME_FIELD, align: ADMIN_MODULE_ACTION_ALIGN },
                    { colName: WEEKDAY_CLUSTER_NAME, width: WEEKDAY_CLUSTER_NAME_WIDTH, field: WEEKDAY_CLUSTER_NAME_FIELD, align: WEEKDAY_CLUSTER_NAME_ALIGN },
                    { colName: WEEKDAY_CLUSTER_SYMBOL, width: WEEKDAY_CLUSTER_SYMBOL_WIDTH, field: WEEKDAY_CLUSTER_SYMBOL_FIELD, align: WEEKDAY_CLUSTER_SYMBOL_ALIGN },
                    { colName: WEEKDAY_CLUSTER_DESCRIPTION, width: WEEKDAY_CLUSTER_DESCRIPTION_WIDTH, field: WEEKDAY_CLUSTER_DESCRIPTION_FIELD, align: WEEKDAY_CLUSTER_DESCRIPTION_ALIGN },
                    { colName: WEEKDAY_CLUSTER_WEEKDAY_START, width: WEEKDAY_CLUSTER_WEEKDAY_START_WIDTH, field: WEEKDAY_CLUSTER_WEEKDAY_START_FIELD, align: WEEKDAY_CLUSTER_WEEKDAY_START_ALIGN },
                    { colName: WEEKDAY_CLUSTER_WEEKDAY_END, width: WEEKDAY_CLUSTER_WEEKDAY_END_WIDTH, field: WEEKDAY_CLUSTER_WEEKDAY_END_FIELD, align: WEEKDAY_CLUSTER_WEEKDAY_END_ALIGN },
                    { colName: WEEKDAY_CLUSTER_WEEKDAY_COUNT, width: WEEKDAY_CLUSTER_WEEKDAY_COUNT_WIDTH, field: WEEKDAY_CLUSTER_WEEKDAY_COUNT_FIELD, align: WEEKDAY_CLUSTER_WEEKDAY_COUNT_ALIGN },
                    { colName: WEEKDAY_CLUSTER_WEEKEND_COUNT, width: WEEKDAY_CLUSTER_WEEKEND_COUNT_WIDTH, field: WEEKDAY_CLUSTER_WEEKEND_COUNT_FIELD, align: WEEKDAY_CLUSTER_WEEKEND_COUNT_ALIGN },
                    { colName: WEEKDAY_CLUSTER_WEEKEND_TYPE, width: WEEKDAY_CLUSTER_WEEKEND_TYPE_WIDTH, field: WEEKDAY_CLUSTER_WEEKEND_TYPE_FIELD, align: WEEKDAY_CLUSTER_WEEKEND_TYPE_ALIGN },
                    { colName: POLICY_DOC, width: POLICY_DOC_WIDTH, field: POLICY_DOC_FIELD, align: POLICY_DOC_ALIGN },
                    { colName: EFFECTIVE_START_DATE, width: '180', field: EFFECTIVE_START_DATE_FIELD, align: EFFECTIVE_START_DATE_ALIGN },
                    { colName: EFFECTIVE_END_DATE, width: '180', field: EFFECTIVE_END_DATE_FIELD, align: EFFECTIVE_END_DATE_ALIGN },
                    { colName: WEEKDAY_CLUSTER_IS_ACTIVE, width: WEEKDAY_CLUSTER_IS_ACTIVE_WIDTH, field: WEEKDAY_CLUSTER_IS_ACTIVE_FIELD, align: WEEKDAY_CLUSTER_IS_ACTIVE_ALIGN },
                ]
            );
        case 'dayBreakCluster':
            return (
                [
                    { colName: ADMIN_MODULE_ACTION, width: '80', field: ADMIN_MODULE_ACTION_NAME_FIELD, align: ADMIN_MODULE_ACTION_ALIGN },
                    // { colName: DAY_BREAK_CLUSTER_WORKDAY_WINDOW_NAME, width: DAY_BREAK_CLUSTER_WORKDAY_WINDOW_NAME_WIDTH, field: DAY_BREAK_CLUSTER_WORKDAY_WINDOW_NAME_FIELD, align: DAY_BREAK_CLUSTER_WORKDAY_WINDOW_NAME_ALIGN },
                    { colName: DAY_BREAK_CLUSTER_NAME, width: DAY_BREAK_CLUSTER_NAME_WIDTH, field: DAY_BREAK_CLUSTER_NAME_FIELD, align: DAY_BREAK_CLUSTER_NAME_ALIGN },
                    { colName: DAY_BREAK_CLUSTER_SYMBOL, width: DAY_BREAK_CLUSTER_SYMBOL_WIDTH, field: DAY_BREAK_CLUSTER_SYMBOL_FIELD, align: DAY_BREAK_CLUSTER_SYMBOL_ALIGN },
                    { colName: DAY_BREAK_CLUSTER_DESCRIPTION, width: DAY_BREAK_CLUSTER_DESCRIPTION_WIDTH, field: DAY_BREAK_CLUSTER_DESCRIPTION_FIELD, align: DAY_BREAK_CLUSTER_DESCRIPTION_ALIGN },
                    { colName: DAY_BREAK_CLUSTER_LEAVES_ALLOWED, width: DAY_BREAK_CLUSTER_LEAVES_ALLOWED_WIDTH, field: DAY_BREAK_CLUSTER_LEAVES_ALLOWED_FIELD, align: DAY_BREAK_CLUSTER_LEAVES_ALLOWED_ALIGN },
                    { colName: DAY_BREAK_CLUSTER_ATTENDANCE_CORRECTION_ALLOWED, width: DAY_BREAK_CLUSTER_ATTENDANCE_CORRECTION_ALLOWED_WIDTH, field: DAY_BREAK_CLUSTER_ATTENDANCE_CORRECTION_ALLOWED_FIELD, align: DAY_BREAK_CLUSTER_ATTENDANCE_CORRECTION_ALLOWED_ALIGN },
                    // { colName: DAY_BREAK_CLUSTER_BUSINESS_RULE, width: DAY_BREAK_CLUSTER_BUSINESS_RULE_WIDTH, field: DAY_BREAK_CLUSTER_BUSINESS_RULE_FIELD, align: DAY_BREAK_CLUSTER_BUSINESS_RULE_ALIGN },
                    { colName: EFFECTIVE_START_DATE, width: EFFECTIVE_START_DATE_WIDTH, field: EFFECTIVE_START_DATE_FIELD, align: EFFECTIVE_START_DATE_ALIGN },
                    { colName: EFFECTIVE_END_DATE, width: EFFECTIVE_END_DATE_WIDTH, field: EFFECTIVE_END_DATE_FIELD, align: EFFECTIVE_END_DATE_ALIGN },
                    { colName: ATTENDANCE_CLUSTER_IS_ACTIVE, width: '70', field: DAY_BREAK_CLUSTER_IS_ACTIVE_FIELD, align: ATTENDANCE_CLUSTER_IS_ACTIVE_ALIGN },
                    // {colName: DAY_BREAK_CLUSTER_FINANCIAL_YEAR, width: DAY_BREAK_CLUSTER_FINANCIAL_YEAR_WIDTH, field: DAY_BREAK_CLUSTER_FINANCIAL_YEAR_FIELD, align: DAY_BREAK_CLUSTER_FINANCIAL_YEAR_ALIGN},
                    // {colName: DAY_BREAK_CLUSTER_IS_ACTIVE, width: DAY_BREAK_CLUSTER_IS_ACTIVE_WIDTH, field: DAY_BREAK_CLUSTER_IS_ACTIVE_FIELD, align: DAY_BREAK_CLUSTER_IS_ACTIVE_ALIGN},
                ]
            );
        case 'sourceCluster':
            return (
                [
                    { colName: ADMIN_MODULE_ACTION, width: '80', field: ADMIN_MODULE_ACTION_NAME_FIELD, align: ADMIN_MODULE_ACTION_ALIGN },
                    { colName: SOURCE_CLUSTER_NAME, width: SOURCE_CLUSTER_NAME_WIDTH, field: SOURCE_CLUSTER_NAME_FIELD, align: SOURCE_CLUSTER_NAME_ALIGN },
                    { colName: SOURCE_CLUSTER_SYMBOL, width: SOURCE_CLUSTER_SYMBOL_WIDTH, field: SOURCE_CLUSTER_SYMBOL_FIELD, align: SOURCE_CLUSTER_SYMBOL_ALIGN },
                    { colName: SOURCE_CLUSTER_DESCRIPTION, width: SOURCE_CLUSTER_DESCRIPTION_WIDTH, field: SOURCE_CLUSTER_DESCRIPTION_FIELD, align: SOURCE_CLUSTER_DESCRIPTION_ALIGN },
                    { colName: SOURCE_CLUSTER_DEVICE_LOCATION, width: SOURCE_CLUSTER_DEVICE_LOCATION_WIDTH, field: SOURCE_CLUSTER_DEVICE_LOCATION_FIELD, align: SOURCE_CLUSTER_DEVICE_LOCATION_ALIGN },
                    { colName: SOURCE_CLUSTER_DEVICE_ID, width: SOURCE_CLUSTER_DEVICE_ID_WIDTH, field: SOURCE_CLUSTER_DEVICE_ID_FIELD, align: SOURCE_CLUSTER_DEVICE_ID_ALIGN },
                    { colName: SOURCE_CLUSTER_IT1, width: SOURCE_CLUSTER_IT1_WIDTH, field: SOURCE_CLUSTER_IT1_FIELD, align: SOURCE_CLUSTER_IT1_ALIGN },
                    { colName: SOURCE_CLUSTER_IT2, width: SOURCE_CLUSTER_IT2_WIDTH, field: SOURCE_CLUSTER_IT2_FIELD, align: SOURCE_CLUSTER_IT3_ALIGN },
                    { colName: SOURCE_CLUSTER_IT3, width: SOURCE_CLUSTER_IT3_WIDTH, field: SOURCE_CLUSTER_IT3_FIELD, align: SOURCE_CLUSTER_IT3_ALIGN },
                    { colName: SOURCE_CLUSTER_EFFECTIVE_START_DATE, width: SOURCE_CLUSTER_EFFECTIVE_START_DATE_WIDTH, field: SOURCE_CLUSTER_EFFECTIVE_START_DATE_FIELD, align: SOURCE_CLUSTER_EFFECTIVE_START_DATE_ALIGN },
                    { colName: SOURCE_CLUSTER_EFFECTIVE_END_DATE, width: SOURCE_CLUSTER_EFFECTIVE_END_DATE_WIDTH, field: SOURCE_CLUSTER_EFFECTIVE_END_DATE_FIELD, align: SOURCE_CLUSTER_EFFECTIVE_END_DATE_ALIGN },

                    // {colName: SOURCE_CLUSTER_FINANCIAL_YEAR, width: SOURCE_CLUSTER_FINANCIAL_YEAR_WIDTH, field: SOURCE_CLUSTER_FINANCIAL_YEAR_FIELD, align: SOURCE_CLUSTER_FINANCIAL_YEAR_ALIGN},
                    { colName: SOURCE_CLUSTER_IS_ACTIVE, width: SOURCE_CLUSTER_IS_ACTIVE_WIDTH, field: SOURCE_CLUSTER_IS_ACTIVE_FIELD, align: SOURCE_CLUSTER_IS_ACTIVE_ALIGN },
                ]
            );
        case 'workdayWindow':
            return (
                [
                    { colName: ADMIN_MODULE_ACTION, width: '80', field: ADMIN_MODULE_ACTION_NAME_FIELD, align: ADMIN_MODULE_ACTION_ALIGN },
                    { colName: WORKDAY_WINDOW_NAME, width: WORKDAY_WINDOW_NAME_WIDTH, field: WORKDAY_WINDOW_NAME_FIELD, align: WORKDAY_WINDOW_NAME_ALIGN },
                    { colName: WORKDAY_WINDOW_SYMBOL, width: WORKDAY_WINDOW_SYMBOL_WIDTH, field: WORKDAY_WINDOW_SYMBOL_FIELD, align: WORKDAY_WINDOW_SYMBOL_ALIGN },
                    { colName: WORKDAY_WINDOW_DESCRIPTION, width: WORKDAY_WINDOW_DESCRIPTION_WIDTH, field: WORKDAY_WINDOW_DESCRIPTION_FIELD, align: WORKDAY_WINDOW_DESCRIPTION_ALIGN },
                    { colName: WORKDAY_WINDOW_START_TIME, width: WORKDAY_WINDOW_START_TIME_WIDTH, field: WORKDAY_WINDOW_START_TIME_FIELD, align: WORKDAY_WINDOW_START_TIME_ALIGN },
                    { colName: WORKDAY_WINDOW_END_TIME, width: WORKDAY_WINDOW_END_TIME_WIDTH, field: WORKDAY_WINDOW_END_TIME_FIELD, align: WORKDAY_WINDOW_END_TIME_ALIGN },
                    { colName: WORKDAY_WINDOW_WORK_HRS, width: WORKDAY_WINDOW_WORK_HRS_WIDTH, field: WORKDAY_WINDOW_WORK_HRS_FIELD, align: WORKDAY_WINDOW_WORK_HRS_ALIGN },
                    { colName: WORKDAY_WINDOW_MIN_HRS, width: WORKDAY_WINDOW_MIN_HRS_WIDTH, field: WORKDAY_WINDOW_MIN_HRS_FIELD, align: WORKDAY_WINDOW_MIN_HRS_ALIGN },
                    { colName: WORKDAY_WINDOW_WEEK_HRS, width: WORKDAY_WINDOW_WEEK_HRS_WIDTH, field: WORKDAY_WINDOW_WEEK_HRS_FIELD, align: WORKDAY_WINDOW_WEEK_HRS_ALIGN },
                    { colName: WORKDAY_WINDOW_GRACE_TIME, width: WORKDAY_WINDOW_GRACE_TIME_WIDTH, field: WORKDAY_WINDOW_GRACE_TIME_FIELD, align: WORKDAY_WINDOW_GRACE_TIME_ALIGN },
                    { colName: WORKDAY_WINDOW_NUMBER_OF_INSTANCE, width: WORKDAY_WINDOW_NUMBER_OF_INSTANCE_WIDTH, field: WORKDAY_WINDOW_NUMBER_OF_INSTANCE_FIELD, align: WORKDAY_WINDOW_NUMBER_OF_INSTANCE_ALIGN },
                    { colName: WORKDAY_WINDOW_FREQUENCY, width: WORKDAY_WINDOW_FREQUENCY_WIDTH, field: WORKDAY_WINDOW_FREQUENCY_FIELD, align: WORKDAY_WINDOW_FREQUENCY_ALIGN },
                    { colName: POLICY_DOC, width: '150', field: POLICY_DOC_FIELD, align: POLICY_DOC_ALIGN },
                    { colName: EFFECTIVE_START_DATE, width: '170', field: EFFECTIVE_END_DATE_FIELD, align: EFFECTIVE_START_DATE_ALIGN },
                    { colName: EFFECTIVE_END_DATE, width: '170', field: EFFECTIVE_END_DATE_FIELD, align: EFFECTIVE_END_DATE_ALIGN },
                    { colName: WORKDAY_WINDOW_IS_ACTIVE, width: WORKDAY_WINDOW_IS_ACTIVE_WIDTH, field: WORKDAY_WINDOW_IS_ACTIVE_FIELD, align: WORKDAY_WINDOW_IS_ACTIVE_ALIGN },
                ]
            );
        case 'regularizationStatus':
            return ([
                { colName: ADMIN_MODULE_ACTION, width: '50', field: ADMIN_MODULE_ACTION_NAME_FIELD, align: ADMIN_MODULE_ACTION_ALIGN },
                { colName: REGULARIZATION_STATUS_DATE, width: REGULARIZATION_STATUS_DATE_WIDTH, field: REGULARIZATION_STATUS_DATE_FIELD, align: REGULARIZATION_STATUS_DATE_ALIGN },
                { colName: REGULARIZATION_STATUS_DAYBREAK, width: REGULARIZATION_STATUS_DAYBREAK_WIDTH, field: REGULARIZATION_STATUS_DAYBREAK_FIELD, align: REGULARIZATION_STATUS_DAYBREAK_ALIGN },
                { colName: REGULARIZATION_STATUS_STATUS, width: REGULARIZATION_STATUS_STATUS_WIDTH, field: REGULARIZATION_STATUS_STATUS_FIELD, align: REGULARIZATION_STATUS_STATUS_ALIGN },
            ]
            )
        case 'leaveCluster':
            return (
                [
                    { colName: ADMIN_MODULE_ACTION, width: '80', field: ADMIN_MODULE_ACTION_NAME_FIELD, align: ADMIN_MODULE_ACTION_ALIGN },
                    { colName: LEAVE_CLUSTER_NAME, width: LEAVE_CLUSTER_NAME_WIDTH, field: LEAVE_CLUSTER_NAME_FIELD, align: LEAVE_CLUSTER_NAME_ALIGN },
                    { colName: LEAVE_CLUSTER_SYMBOL, width: LEAVE_CLUSTER_SYMBOL_WIDTH, field: LEAVE_CLUSTER_SYMBOL_FIELD, align: LEAVE_CLUSTER_SYMBOL_ALIGN },
                    { colName: LEAVE_CLUSTER_DESCRIPTION, width: '250', LEAVE_CLUSTER_DESCRIPTION_WIDTH, field: LEAVE_CLUSTER_DESCRIPTION_FIELD, align: LEAVE_CLUSTER_DESCRIPTION_ALIGN },

                    { colName: LEAVE_CLUSTER_COLOR, width: '140', LEAVE_CLUSTER_DESCRIPTION_WIDTH, field: LEAVE_CLUSTER_COLOR_FIELD, align: LEAVE_CLUSTER_COLOR_ALIGN },
                    // { colName: COLOUR_CLUSTER_NAME, width: '140', COLOUR_CLUSTER_NAME_WIDTH, field: COLOUR_CLUSTER_NAME_FIELD, align: COLOUR_CLUSTER_NAME_ALIGN },

                    { colName: LEAVE_CLUSTER_LEAVE_TYPE, width: LEAVE_CLUSTER_LEAVE_TYPE_WIDTH, field: LEAVE_CLUSTER_LEAVE_TYPE_FIELD, align: LEAVE_CLUSTER_LEAVE_TYPE_ALIGN },
                    { colName: LEAVE_CLUSTER_NO_OF_LEAVES, width: LEAVE_CLUSTER_NO_OF_LEAVES_WIDTH, field: LEAVE_CLUSTER_NO_OF_LEAVES_FIELD, align: LEAVE_CLUSTER_NO_OF_LEAVES_ALIGN },
                    { colName: LEAVE_CLUSTER_STRETCHED_STAGGERED, width: LEAVE_CLUSTER_STRETCHED_STAGGERED_WIDTH, field: LEAVE_CLUSTER_STRETCHED_STAGGERED_FIELD, align: LEAVE_CLUSTER_STRETCHED_STAGGERED_ALIGN },
                    { colName: LEAVE_CLUSTER_ADD_FREQUENCY, width: LEAVE_CLUSTER_ADD_FREQUENCY_WIDTH, field: LEAVE_CLUSTER_ADD_FREQUENCY_FIELD, align: LEAVE_CLUSTER_ADD_FREQUENCY_ALIGN },
                    { colName: LEAVE_CLUSTER_ADD_GENDER, width: LEAVE_CLUSTER_ADD_GENDER_WIDTH, field: LEAVE_CLUSTER_ADD_GENDER_FIELD, align: LEAVE_CLUSTER_ADD_GENDER_ALIGN },
                    { colName: LEAVE_CLUSTER_PRORATE, width: LEAVE_CLUSTER_PRORATE_WIDTH, field: LEAVE_CLUSTER_PRORATE_FIELD, align: LEAVE_CLUSTER_PRORATE_ALIGN },
                    { colName: LEAVE_CLUSTER_TRANSFER_ALLOWED, width: LEAVE_CLUSTER_TRANSFER_ALLOWED_WIDTH, field: LEAVE_CLUSTER_TRANSFER_ALLOWED_FIELD, align: LEAVE_CLUSTER_TRANSFER_ALLOWED_ALIGN },
                    { colName: LEAVE_CLUSTER_MIN_LEAVES_TRANSFER, width: LEAVE_CLUSTER_MIN_LEAVES_TRANSFER_WIDTH, field: LEAVE_CLUSTER_MIN_LEAVES_TRANSFER_FIELD, align: LEAVE_CLUSTER_MIN_LEAVES_TRANSFER_ALIGN },
                    { colName: LEAVE_CLUSTER_COMP_OFF_ALLOWED, width: LEAVE_CLUSTER_COMP_OFF_ALLOWED_WIDTH, field: LEAVE_CLUSTER_COMP_OFF_ALLOWED_FIELD, align: LEAVE_CLUSTER_COMP_OFF_ALLOWED_ALIGN },
                    { colName: LEAVE_CLUSTER_ACCUMULATE_IN_SAME_YEAR, width: LEAVE_CLUSTER_ACCUMULATE_IN_SAME_YEAR_WIDTH, field: LEAVE_CLUSTER_ACCUMULATE_IN_SAME_YEAR_FIELD, align: LEAVE_CLUSTER_ACCUMULATE_IN_SAME_YEAR_ALIGN },
                    { colName: LEAVE_CLUSTER_FORWARD_TO_NEXT_YEAR, width: LEAVE_CLUSTER_FORWARD_TO_NEXT_YEAR_WIDTH, field: LEAVE_CLUSTER_FORWARD_TO_NEXT_YEAR_FIELD, align: LEAVE_CLUSTER_FORWARD_TO_NEXT_YEAR_ALIGN },
                    { colName: LEAVE_CLUSTER_MAX_LEAVES_FORWARD, width: LEAVE_CLUSTER_MAX_LEAVES_FORWARD_WIDTH, field: LEAVE_CLUSTER_MAX_LEAVES_FORWARD_FIELD, align: LEAVE_CLUSTER_MAX_LEAVES_FORWARD_ALIGN },
                    { colName: LEAVE_CLUSTER_WEEKEND_COUNTED, width: LEAVE_CLUSTER_WEEKEND_COUNTED_WIDTH, field: LEAVE_CLUSTER_WEEKEND_COUNTED_FIELD, align: LEAVE_CLUSTER_WEEKEND_COUNTED_ALIGN },
                    { colName: LEAVE_CLUSTER_NO_OF_DAYS_ELIGIBLE_FOR_NEXT_YEAR, width: LEAVE_CLUSTER_NO_OF_DAYS_ELIGIBLE_FOR_NEXT_YEAR_WIDTH, field: LEAVE_CLUSTER_NO_OF_DAYS_ELIGIBLE_FOR_NEXT_YEAR_FIELD, align: LEAVE_CLUSTER_NO_OF_DAYS_ELIGIBLE_FOR_NEXT_YEAR_ALIGN },
                    { colName: LEAVE_CLUSTER_REIMBURSED, width: '120', LEAVE_CLUSTER_REIMBURSED_WIDTH, field: LEAVE_CLUSTER_REIMBURSED_FIELD, align: LEAVE_CLUSTER_REIMBURSED_ALIGN },
                    { colName: POLICY_DOC, width: '200', field: POLICY_DOC_FIELD, align: POLICY_DOC_ALIGN },
                    { colName: DOCUMENTS_REQUIRED, width: DOCUMENTS_REQUIRED_WIDTH, field: DOCUMENTS_REQUIRED_FIELD, align: DOCUMENTS_REQUIRED_ALIGN },
                    { colName: GOVERNMENT_STATUTORY, width: GOVERNMENT_STATUTORY_WIDTH, field: GOVERNMENT_STATUTORY_FIELD, align: GOVERNMENT_STATUTORY_ALIGN },
                    { colName: LEAVE_BIFURCATION, width: LEAVE_BIFURCATION_WIDTH, field: LEAVE_BIFURCATION_FIELD, align: LEAVE_BIFURCATION_ALIGN },
                    { colName: EFFECTIVE_START_DATE, field: EFFECTIVE_START_DATE_FIELD, width: '180', align: EFFECTIVE_START_DATE_ALIGN },
                    { colName: EFFECTIVE_END_DATE, field: EFFECTIVE_END_DATE_FIELD, width: '180', align: EFFECTIVE_END_DATE_ALIGN },
                    { colName: LEAVE_CLUSTER_IS_ACTIVE, width: '100', LEAVE_CLUSTER_IS_ACTIVE_WIDTH, field: LEAVE_CLUSTER_IS_ACTIVE_FIELD, align: LEAVE_CLUSTER_IS_ACTIVE_ALIGN },

                ]
            );
        case 'statutoryCluster':
            return (
                [
                    { colName: ADMIN_MODULE_ACTION, width: '80', field: ADMIN_MODULE_ACTION_NAME_FIELD, align: ADMIN_MODULE_ACTION_ALIGN },
                    { colName: STATUTORY_CLUSTER_STATUTORY_NAME, width: '150', field: STATUTORY_CLUSTER_STATUTORY_NAME_FIELD, align: STATUTORY_CLUSTER_STATUTORY_NAME__ALIGN },
                    { colName: STATUTORY_CLUSTER_STATUTORY_SYMBOL, width: '100', field: STATUTORY_CLUSTER_STATUTORY_SYMBOL_FIELD, align: STATUTORY_CLUSTER_STATUTORY_SYMBOL_ALIGN },
                    { colName: STATUTORY_CLUSTER_STATUTORY_DESCRIPTION, width: '200', field: STATUTORY_CLUSTER_STATUTORY_DESCRIPTION_FIELD, align: STATUTORY_CLUSTER_STATUTORY_DESCRIPTION_ALIGN },
                    { colName: STATUTORY_CLUSTER_STATUTORY_DATE, width: '120', field: STATUTORY_CLUSTER_STATUTORY_DATE_FIELD, align: STATUTORY_CLUSTER_STATUTORY_DATE_ALIGN },
                    { colName: STATUTORY_CLUSTER_STATUTORY_DAY, width: '120', field: STATUTORY_CLUSTER_STATUTORY_DAY_FIELD, align: STATUTORY_CLUSTER_STATUTORY_DAY_ALIGN },
                    { colName: STATUTORY_CLUSTER_STATUTORY_COUNTRY, width: '130', field: STATUTORY_CLUSTER_STATUTORY_COUNTRY_FIELD, align: STATUTORY_CLUSTER_STATUTORY_COUNTRY_ALIGN },
                    { colName: STATUTORY_CLUSTER_STATUTORY_LOCATION, width: '150', field: STATUTORY_CLUSTER_STATUTORY_LOCATION_FIELD, align: STATUTORY_CLUSTER_STATUTORY_LOCATION_ALIGN },
                    { colName: STATUTORY_CLUSTER_STATUTORY_TYPE, width: '100', field: STATUTORY_CLUSTER_STATUTORY_TYPE_FIELD, align: STATUTORY_CLUSTER_STATUTORY_TYPE_ALIGN },
                    { colName: STATUTORY_CLUSTER_STATUTORY_DAY_BREAK, width: '120', field: STATUTORY_CLUSTER_STATUTORY_DAY_BREAK_FIELD, align: STATUTORY_CLUSTER_STATUTORY_DAY_BREAK_ALIGN },
                    { colName: POLICY_DOC, width: '150', field: POLICY_DOC_FIELD, align: POLICY_DOC_ALIGN },
                    { colName: STATUTORY_CLUSTER_STATUTORY_ACTIVE, width: '80', field: STATUTORY_CLUSTER_STATUTORY_ACTIVE_FIELD, align: STATUTORY_CLUSTER_STATUTORY_ACTIVE_ALIGN },

                ]
            );

        case 'exceptionalCluster':
            return (
                [
                    { colName: ADMIN_MODULE_ACTION, width: '80', field: ADMIN_MODULE_ACTION_NAME_FIELD, align: ADMIN_MODULE_ACTION_ALIGN },
                    { colName: EXCEPTIONAL_CLUSTER_EXCEPTIONAL_NAME, width: '160', field: EXCEPTIONAL_CLUSTER_EXCEPTIONAL_NAME_FIELD, align: EXCEPTIONAL_CLUSTER_EXCEPTIONAL_NAME__ALIGN },
                    { colName: EXCEPTIONAL_CLUSTER_EXCEPTIONAL_SYMBOL, width: '100', field: EXCEPTIONAL_CLUSTER_EXCEPTIONAL_SYMBOL_FIELD, align: EXCEPTIONAL_CLUSTER_EXCEPTIONAL_SYMBOL_ALIGN },
                    { colName: EXCEPTIONAL_CLUSTER_EXCEPTIONAL_DESCRIPTION, width: '280', field: EXCEPTIONAL_CLUSTER_EXCEPTIONAL_DESCRIPTION_FIELD, align: EXCEPTIONAL_CLUSTER_EXCEPTIONAL_DESCRIPTION_ALIGN },
                    { colName: EXCEPTIONAL_CLUSTER_EXCEPTIONAL_DATE, width: '80', field: EXCEPTIONAL_CLUSTER_EXCEPTIONAL_DATE_FIELD, align: EXCEPTIONAL_CLUSTER_EXCEPTIONAL_DATE_ALIGN },
                    { colName: EXCEPTIONAL_CLUSTER_EXCEPTIONAL_DAY, width: '120', field: EXCEPTIONAL_CLUSTER_EXCEPTIONAL_DAY_FIELD, align: EXCEPTIONAL_CLUSTER_EXCEPTIONAL_DAY_ALIGN },
                    { colName: EXCEPTIONAL_CLUSTER_EXCEPTIONAL_COUNTRY, width: '130', field: EXCEPTIONAL_CLUSTER_EXCEPTIONAL_COUNTRY_FIELD, align: EXCEPTIONAL_CLUSTER_EXCEPTIONAL_COUNTRY_ALIGN },
                    { colName: EXCEPTIONAL_CLUSTER_EXCEPTIONAL_LOCATION, width: '150', field: EXCEPTIONAL_CLUSTER_EXCEPTIONAL_LOCATION_FIELD, align: EXCEPTIONAL_CLUSTER_EXCEPTIONAL_LOCATION_ALIGN },
                    { colName: EXCEPTIONAL_CLUSTER_EXCEPTIONAL_TYPE, width: '80', field: EXCEPTIONAL_CLUSTER_EXCEPTIONAL_TYPE_FIELD, align: EXCEPTIONAL_CLUSTER_EXCEPTIONAL_TYPE_ALIGN },
                    { colName: EXCEPTIONAL_CLUSTER_EXCEPTIONAL_DAY_BREAK, width: '120', field: EXCEPTIONAL_CLUSTER_EXCEPTIONAL_DAY_BREAK_FIELD, align: EXCEPTIONAL_CLUSTER_EXCEPTIONAL_DAY_BREAK_ALIGN },
                    // { colName: EXCEPTIONAL_CLUSTER_EXCEPTIONAL_SUP_APPROVAL, width: '150px', field: EXCEPTIONAL_CLUSTER_EXCEPTIONAL_SUP_APPROVAL_FIELD, align: EXCEPTIONAL_CLUSTER_EXCEPTIONAL_SUP_APPROVAL_ALIGN },
                    // { colName: EXCEPTIONAL_CLUSTER_EXCEPTIONAL_HR_APPROVAL, width: '150px', field: EXCEPTIONAL_CLUSTER_EXCEPTIONAL_HR_APPROVAL_FIELD, align: EXCEPTIONAL_CLUSTER_EXCEPTIONAL_HR_APPROVAL_ALIGN },
                    { colName: EXCEPTIONAL_CLUSTER_EXCEPTIONAL_ATTACH_NOTE, width: '150', field: EXCEPTIONAL_CLUSTER_EXCEPTIONAL_ATTACH_NOTE_FIELD, align: EXCEPTIONAL_CLUSTER_EXCEPTIONAL_ATTACH_NOTE_ALIGN },
                    { colName: EXCEPTIONAL_CLUSTER_EXCEPTIONAL_ACTIVE, width: '80', field: EXCEPTIONAL_CLUSTER_EXCEPTIONAL_ACTIVE_FIELD, align: EXCEPTIONAL_CLUSTER_EXCEPTIONAL_ACTIVE_ALIGN },

                ]
            );

        case 'workingDayCluster':
            return (
                [
                    { colName: ADMIN_MODULE_ACTION, width: '80', field: ADMIN_MODULE_ACTION_NAME_FIELD, align: ADMIN_MODULE_ACTION_ALIGN },
                    { colName: WORKINGDAY_CLUSTER_NAME, width: '160', field: WORKINGDAY_CLUSTER_NAME_FIELD, align: WORKINGDAY_CLUSTER_NAME__ALIGN },
                    { colName: WORKINGDAY_CLUSTER_DESCRIPTION, width: '220', field: WORKINGDAY_CLUSTER_DESCRIPTION_FIELD, align: WORKINGDAY_CLUSTER_DESCRIPTION_ALIGN },
                    { colName: WORKINGDAY_CLUSTER_COMPANY, width: '170', field: WORKINGDAY_CLUSTER_COMPANY_FIELD, align: WORKINGDAY_CLUSTER_COMPANY_ALIGN },
                    { colName: WORKINGDAY_CLUSTER_COUNTRY, width: '170', field: WORKINGDAY_CLUSTER_COUNTRY_FIELD, align: WORKINGDAY_CLUSTER_COUNTRY_ALIGN },
                    { colName: WORKINGDAY_CLUSTER_LOCATION, width: '170', field: WORKINGDAY_CLUSTER_LOCATION_FIELD, align: WORKINGDAY_CLUSTER_LOCATION_ALIGN },
                    { colName: WORKINGDAY_CLUSTER_TYPE, width: '120', field: WORKINGDAY_CLUSTER_TYPE_FIELD, align: WORKINGDAY_CLUSTER_TYPE_ALIGN },
                    { colName: WORKINGDAY_CLUSTER_DATE, width: '120', field: WORKINGDAY_CLUSTER_DATE_FIELD, align: WORKINGDAY_CLUSTER_DATE_ALIGN },
                    // { colName: WORKINGDAY_CLUSTER_DAY, width: '', field: WORKINGDAY_CLUSTER_DAY_FIELD, align: WORKINGDAY_CLUSTER_DAY_ALIGN },
                    { colName: WORKINGDAY_CLUSTER_START_TIME, width: '120', field: WORKINGDAY_CLUSTER_START_TIME_FIELD, align: WORKINGDAY_CLUSTER_START_TIME_ALIGN },
                    { colName: WORKINGDAY_CLUSTER_END_TIME, width: '120', field: WORKINGDAY_CLUSTER_END_TIME_FIELD, align: WORKINGDAY_CLUSTER_END_TIME_ALIGN },
                    { colName: WORKINGDAY_CLUSTER_MIN_HRS, width: '120', field: WORKINGDAY_CLUSTER_MIN_HRS_FIELD, align: WORKINGDAY_CLUSTER_MIN_HRS_ALIGN },
                    { colName: WORKINGDAY_CLUSTER_DAY_BREAK, width: '120', field: WORKINGDAY_CLUSTER_DAY_BREAK_FIELD, align: WORKINGDAY_CLUSTER_DAY_BREAK_ALIGN },
                    { colName: WORKINGDAY_CLUSTER_ACTIVE, width: '120', field: WORKINGDAY_CLUSTER_ACTIVE_FIELD, align: WORKINGDAY_CLUSTER_ACTIVE_ALIGN },
                ]
            );

        case 'workdayDayBreak':
            return (
                [
                    { colName: ADMIN_MODULE_ACTION, width: '80', field: ADMIN_MODULE_ACTION_NAME_FIELD, align: ADMIN_MODULE_ACTION_ALIGN },
                    { colName: WORKINGDAY_DAYBREAK_MAPPING_WORKDAY, width: '170', field: WORKINGDAY_DAYBREAK_MAPPING_WORKDAY_FIELD, align: WORKINGDAY_DAYBREAK_MAPPING_WORKDAY_ALIGN, headerTextAlign: WORKINGDAY_DAYBREAK_MAPPING_WORKDAY_ALIGN },
                    { colName: WORKINGDAY_DAYBREAK_MAPPING_DAYBREAK, width: '160', field: WORKINGDAY_DAYBREAK_MAPPING_DAYBREAK_FIELD, align: WORKINGDAY_DAYBREAK_MAPPING_DAYBREAK_ALIGN, headerTextAlign: WORKINGDAY_DAYBREAK_MAPPING_DAYBREAK_ALIGN },
                    { colName: WORKINGDAY_DAYBREAK_WORK_HRS, width: '170', field: WORKINGDAY_DAYBREAK_WORK_HRS_FIELD, align: WORKINGDAY_DAYBREAK_WORK_HRS_ALIGN },
                    { colName: WORKINGDAY_DAYBREAK_MIN_HRS, width: '170', field: WORKINGDAY_DAYBREAK_MIN_HRS_FIELD, align: WORKINGDAY_DAYBREAK_MIN_HRS_ALIGN },
                    // { colName: WORKINGDAY_DAYBREAK_MAX_HRS, width: '170', field: WORKINGDAY_DAYBREAK_MAX_HRS_FIELD, align: WORKINGDAY_DAYBREAK_MAX_HRS_ALIGN },
                    { colName: WORKINGDAY_DAYBREAK_WEEKLY_HRS, width: '120', field: WORKINGDAY_DAYBREAK_WEEKLY_HRS_FIELD, align: WORKINGDAY_DAYBREAK_WEEKLY_HRS_ALIGN },
                    { colName: WORKINGDAY_DAYBREAK_GRACE_TIME, width: '120', field: WORKINGDAY_DAYBREAK_GRACE_TIME_FIELD, align: WORKINGDAY_DAYBREAK_GRACE_TIME_ALIGN },
                    { colName: WORKINGDAY_DAYBREAK_WORKING_SAT, width: '120', field: WORKINGDAY_DAYBREAK_WORKING_SAT_FIELD, align: WORKINGDAY_DAYBREAK_WORKING_SATALIGN },
                    { colName: WORKINGDAY_DAYBREAK_WORKING_FORTYHR, width: '120', field: WORKINGDAY_DAYBREAK_WORKING_FORTYHR_FIELD, align: WORKINGDAY_DAYBREAK_WORKING_FORTYHRALIGN },
                    { colName: WORKINGDAY_DAYBREAK_WORKING_ACTIVE, width: '120', field: WORKINGDAY_DAYBREAK_WORKINGACTIVE_FIELD, align: WORKINGDAY_DAYBREAK_WORKINGACTIVE_ALIGN },
                ]
            );

        case 'roleCluster':
            return (
                [
                    { colName: ADMIN_MODULE_ACTION, width: '80', field: ADMIN_MODULE_ACTION_NAME_FIELD, align: ADMIN_MODULE_ACTION_ALIGN },
                    { colName: ROLE_CLUSTER_NAME, width: '150', field: ROLE_CLUSTER_NAME_FIELD, align: ROLE_CLUSTER_NAME_ALIGN },
                    { colName: ROLE_CLUSTER_DESCRIPTION, width: '250', field: ROLE_CLUSTER_DESCRIPTION_FIELD, align: ROLE_CLUSTER_DESCRIPTION_ALIGN },
                    { colName: ROLE_CLUSTER_ACTIVE, width: '100', field: ROLE_CLUSTER_ACTIVE_FIELD, align: ROLE_CLUSTER_ALIGN }

                ]
            );

        case 'colourCluster':
            return (
                [
                    { colName: ADMIN_MODULE_ACTION, width: '80', field: ADMIN_MODULE_ACTION_NAME_FIELD, align: ADMIN_MODULE_ACTION_ALIGN },
                    { colName: COLOUR_CLUSTER_NAME, width: COLOUR_CLUSTER_NAME_WIDTH, field: COLOUR_CLUSTER_NAME_FIELD, align: COLOUR_CLUSTER_NAME_ALIGN },
                    { colName: COLOUR_CLUSTER_COLOUR, width: COLOR_CLUSTER_COLOR_NAME_WIDTH, field: COLOUR_CLUSTER_COLOUR_FIELD, align: COLOUR_CLUSTER_COLOUR_ALIGN },
                    { colName: COLOR_CLUSTER_ACTIVE, width: '100', field: COLOR_CLUSTER_ACTIVE_FIELD, align: COLOR_CLUSTER_ACTIVE_ALIGN },
                    // { colName: COLOUR_CLUSTER_COLORHEXCODE, width: '', field: COLOUR_CLUSTER_COLORHEXCODE_FIELD, align: COLOUR_CLUSTER_COLORHEXCODE_ALIGN },

                ]
            );
        case 'correctionAfterSalaryProcessing':
            return (
                [
                    { colName: CORR_AFT_SAL_PROCESS_NAME, width: '', field: '', align: CORR_AFT_SAL_PROCESS_NAME_ALIGN },
                    { colName: CORR_AFT_SAL_PROCESS_FROM_DATE, width: '', field: '', align: CORR_AFT_SAL_PROCESS_FROM_DATE_ALIGN },
                    { colName: CORR_AFT_SAL_PROCESS_TO_DATE, width: '', field: '', align: CORR_AFT_SAL_PROCESS_TO_DATE_ALIGN },
                    { colName: CORR_AFT_SAL_PROCESS_LEAVE_TYPE, width: '', field: '', align: CORR_AFT_SAL_PROCESS_LEAVE_TYPE_ALIGN },
                    { colName: CORR_AFT_SAL_PROCESS_ATTENDENCE_TYPE, width: '', field: '', align: CORR_AFT_SAL_PROCESS_ATTENDENCE_TYPE_ALIGN },
                    { colName: CORR_AFT_SAL_PROCESS_CURRENT_VALUE, width: '', field: '', align: CORR_AFT_SAL_PROCESS_CURRENT_VALUE_ALIGN },
                    { colName: CORR_AFT_SAL_PROCESS_CHANGED_VALUE_TO, width: '', field: '', align: CORR_AFT_SAL_PROCESS_CHANGED_VALUE_TO_ALIGN },
                    { colName: CORR_AFT_SAL_PROCESS_BALANCE_OF_VALUE, width: '', field: '', align: CORR_AFT_SAL_PROCESS_BALANCE_OF_VALUE_ALIGN },
                    { colName: CORR_AFT_SAL_PROCESS_REASON_FOR_CORRECTION, width: '', field: '', align: CORR_AFT_SAL_PROCESS_REASON_FOR_CORRECTION_ALIGN },
                    { colName: CORR_AFT_SAL_PROCESS_APPROVAL, width: '', field: '', align: CORR_AFT_SAL_PROCESS_APPROVAL_ALIGN },
                ]
            );
        case 'orgChartMasterAdminPracticeArea':
            return (
                [
                    { colName: BUSINESS_UNIT_FUNCTION, width: BUSINESS_UNIT_FUNCTION_WIDTH_NEW_PRACTICE, field: BUSINESS_UNIT_FUNCTION_FIELD_NEW, align: BUSINESS_UNIT_FUNCTION_ALIGN },
                    { colName: SUBBUSINESS, width: SUBBUSINESS_UNIT_FUNCTION_WIDTH_NEW, field: SUBBUSINESS_FIELD, align: SUBBUSINESS_ALIGN },
                    { colName: PRACTICE_AREA_PARENTS, width: PRACTICE_AREA_SALES_UNIT_SUB_FUNCTION_WIDTHS, field: PRACTICE_AREA_PARENTS_FIELD_MASTER, align: PRACTICE_AREA_SALES_UNIT_SUB_FUNCTION_ALIGN },
                    { colName: PRACTICE_AREA_SALES_UNIT_SUB_FUNCTION, width: PRACTICE_AREA_SALES_UNIT_SUB_FUNCTION_WIDTH_NEW, field: PRACTICE_AREA_SALES_UNIT_SUB_FUNCTION_FIELD_MASTER, align: PRACTICE_AREA_SALES_UNIT_SUB_FUNCTION_ALIGN },
                    // { colName: HIERARCHY, width: HIERARCHY_WIDTH_NEW, field: HIERARCHY_FIELD, align: HIERARCHY_ALIGN },
                    { colName: EMPLOYEE_COUNT, width: EMPLOYEE_COUNT_WIDTH_NEW_PRACTICE, field: EMPLOYEE_COUNT_FIELD, align: EMPLOYEE_COUNT_ALIGN },
                    { colName: ACTION, width: ACTION_WIDTH_NEW, field: ACTION_FIELD, align: ACTION_ALIGN },
                ]
            );
        case 'orgChartMappingAdminAllLayerMapping':
            return (
                [
                    { colName: BUSINESS_UNIT_FUNCTION, width: MAPPINGBUSINESS_UNIT_FUNCTION_WIDTH_NEW, field: BUSINESS_UNIT_FUNCTION_MAPPING_FIELD, align: BUSINESS_UNIT_FUNCTION_ALIGN },
                    { colName: SUBBUSINESS, width: MAPPINGSUBBUSINESS_UNIT_FUNCTION_WIDTH_NEW, field: SUB_BUSINESS_UNIT_FUNCTION_MAPPING_FIELD, align: SUBBUSINESS_ALIGN },
                    { colName: PRACTICE_AREA_SALES_UNIT_SUB_FUNCTION, width: MAPPINGPRACTICE_AREA_SALES_UNIT_SUB_FUNCTION_WIDTH_NEW, field: PRACTICE_AREA_UNIT_FUNCTION_MAPPING_FIELD, align: PRACTICE_AREA_SALES_UNIT_SUB_FUNCTION_ALIGN },
                    { colName: LAYER, width: MAPPINGLAYER_WIDTH_NEW, field: LAYER_NAME_UNIT_FUNCTION_MAPPING_FIELD, align: LAYER_ALIGN },
                    { colName: PARENTLAYER, width: MAPPINGLAYER_WIDTH_NEW, field: PARENTLAYER_FIELD, align: PARENTLAYER_ALIGN },
                    { colName: HIERARCHY, width: MAPPINGHIERARCHY_WIDTH_NEW, field: HIERARCHY_FIELD, align: HIERARCHY_ALIGN },
                    { colName: EMPLOYEE_COUNT, width: MAPPINGEMPLOYEE_COUNT_WIDTH_NEW, field: EMPLOYEE_COUNT_FIELD, align: EMPLOYEE_COUNT_ALIGN },
                    { colName: ACTION, width: MAPPINGACTION_WIDTH_NEW, field: ACTION_FIELD, align: ACTION_ALIGN },
                ]
            );
        case 'orgChartMappingAdminAllSubLayerMapping':
            return (
                [
                    { colName: BUSINESS_UNIT_FUNCTION, width: MAPPINGBUSINESS_UNIT_FUNCTION_WIDTH_NEW, field: BUSINESS_UNIT_FUNCTION_MAPPING_FIELD, align: BUSINESS_UNIT_FUNCTION_ALIGN },
                    { colName: SUBBUSINESS, width: MAPPINGSUBBUSINESS_UNIT_FUNCTION_WIDTH_NEW, field: SUB_BUSINESS_UNIT_FUNCTION_MAPPING_FIELD, align: SUBBUSINESS_ALIGN },
                    { colName: PRACTICE_AREA_SALES_UNIT_SUB_FUNCTION, width: MAPPINGPRACTICE_AREA_SALES_UNIT_SUB_FUNCTION_WIDTH_NEW, field: PRACTICE_AREA_UNIT_FUNCTION_MAPPING_FIELD, align: PRACTICE_AREA_SALES_UNIT_SUB_FUNCTION_ALIGN },
                    { colName: LAYER, width: MAPPINGLAYER_WIDTH_NEW, field: LAYER_NAME_UNIT_FUNCTION_MAPPING_FIELD, align: LAYER_ALIGN },
                    { colName: SUB_LAYER, width: MAPPINGSUB_LAYER_WIDTH_NEW, field: SUB_LAYER_FIELD_MASTER, align: SUB_LAYER_ALIGN },
                    { colName: HIERARCHY, width: MAPPINGHIERARCHY_WIDTH_NEW, field: HIERARCHY_FIELD, align: HIERARCHY_ALIGN },
                    { colName: NOTICE_PERIOD_SUBLAYER, width: NOTICE_PERIOD_WIDTH_SUBLAYER, field: NOTICE_PERIOD_FIELD_SUBLAYER, align: NOTICE_PERIOD_ALIGN_SUBLAYER },
                    { colName: EMPLOYEE_COUNT, width: MAPPINGEMPLOYEE_COUNT_WIDTH_NEW, field: EMPLOYEE_COUNT_FIELD, align: EMPLOYEE_COUNT_ALIGN },
                    { colName: ACTION, width: MAPPINGACTION_WIDTH_NEW, field: ACTION_FIELD, align: ACTION_ALIGN },
                ]
            );
        case 'orgChartMasterAdminBusinessUnit':
            return (
                [
                    { colName: PARENTLAYER_SBU, width: PARENTLAYER_WIDTH_NEW, field: PARENTLAYER_FIELD_NEW, align: PARENTLAYER_ALIGN_NEW },
                    { colName: BUSINESS_UNIT_FUNCTION, width: BUSINESS_UNIT_FUNCTION_WIDTH_NEW, field: BUSINESS_UNIT_FUNCTION_FIELD_NEW, align: BUSINESS_UNIT_FUNCTION_ALIGN },
                    { colName: COMPANY_NAME, width: COMPANY_NAME_WIDTH_NEW, field: COMPANY_NAME_FIELD, align: COMPANY_NAME_ALIGN },
                    { colName: ABBREVATION, width: ABBREVATION_WIDTH_NEW, field: ABBREVATION_FIELD_MASTER, align: ABBREVATION_ALIGN },
                    { colName: DOMAIN, width: DOMAIN_WIDTH_NEW, field: DOMAIN_FIELD, align: DOMAIN_ALIGN },
                    // { colName: HIERARCHY, width: HIERARCHY_WIDTH_NEW, field: HIERARCHY_FIELD, align: HIERARCHY_ALIGN },
                    { colName: EMPLOYEE_COUNT, width: EMPLOYEE_COUNT_WIDTH_NEW, field: EMPLOYEE_COUNT_FIELD, align: EMPLOYEE_COUNT_ALIGN },
                    { colName: ACTION, width: BUSINESS_ACTION_WIDTH_NEW, field: ACTION_FIELD, align: ACTION_ALIGN },
                ]
            );
        case 'orgChartMasterAdminSubBusinessUnit':
            return (
                [
                    { colName: BUSINESS_UNIT_FUNCTION, width: BUSINESS_UNIT_FUNCTION_WIDTH_NEW, field: BUSINESS_UNIT_FUNCTION_FIELD_NEW, align: BUSINESS_UNIT_FUNCTION_ALIGN },
                    { colName: SUB_PARENTLAYER_SUBFUNCTION, width: SUB_PARENTLAYER_WIDTH_NEW, field: SUB_PARENTLAYER_FIELD_NEW, align: SUB_PARENTLAYER_ALIGN_NEW },
                    { colName: SUBBUSINESS, width: SUBBUSINESS_UNIT_FUNCTION_WIDTH_NEW, field: SUBBUSINESS_FIELD, align: SUBBUSINESS_ALIGN },
                    // { colName: HIERARCHY, width: HIERARCHY_WIDTH_NEW, field: HIERARCHY_FIELD, align: HIERARCHY_ALIGN },
                    { colName: EMPLOYEE_COUNT, width: EMPLOYEE_COUNT_WIDTH_NEW_SUB, field: EMPLOYEE_COUNT_FIELD, align: EMPLOYEE_COUNT_ALIGN },
                    { colName: ACTION, width: SUBBUSINESS_ACTION_WIDTH_NEW, field: ACTION_FIELD, align: ACTION_ALIGN },
                ]
            );
        case 'okrCommonDocsGridView':
            return (
                [
                    { colName: COMMON_DOC_ICON, width: COMMON_DOC_ICON_WIDTH, field: COMMON_DOC_ICON_FIELD, align: COMMON_DOC_ICON_ALIGN },
                    { colName: COMMON_DOC_FILE_NAME, width: COMMON_DOC_FILE_NAME_WIDTH, field: COMMON_DOC_FILE_NAME_FIELD, align: COMMON_DOC_FILE_NAME_ALIGN },
                    { colName: COMMON_DOC_UPLOADED_DATE, width: COMMON_DOC_UPLOADED_DATE_WIDTH, field: COMMON_DOC_UPLOADED_DATE_FIELD, align: COMMON_DOC_UPLOADED_DATE_ALIGN },
                    { colName: COMMON_DOC_ACTION, width: COMMON_DOC_ACTION_WIDTH, field: COMMON_DOC_ACTION_FIELD, align: COMMON_DOC_ACTION_ALIGN },
                ]
            );
        case 'teamMembarRanking':
            return (
                [
                    { colName: TEAM_MEMBER_RANKING_TEAM_MEMBER_NAME, width: TEAM_MEMBER_RANKING_TEAM_MEMBER_WIDTH, field: TEAM_MEMBER_RANKING_TEAM_MEMBER_FIELD, align: TEAM_MEMBER_RANKING_TEAM_MEMBER_ALIGN },
                    { colName: TEAM_MEMBER_RANKING_PERFORMANCE_X_AXIS, width: TEAM_MEMBER_RANKING_PERFORMANCE_X_AXIS_WIDTH, field: TEAM_MEMBER_RANKING_PERFORMANCE_X_AXIS_FIELD, align: TEAM_MEMBER_RANKING_PERFORMANCE_X_AXIS_ALIGN },
                    { colName: TEAM_MEMBER_RANKING_POTENTIAL_Y_AXIS, width: TEAM_MEMBER_RANKING_POTENTIAL_Y_AXIS_WIDTH, field: TEAM_MEMBER_RANKING_POTENTIAL_Y_AXIS_FIELD, align: TEAM_MEMBER_RANKING_POTENTIAL_Y_AXIS_ALIGN },
                    { colName: TEAM_MEMBER_RANKING_9_BOX_CLASSIFICATION, width: TEAM_MEMBER_RANKING_9_BOX_CLASSIFICATION_WIDTH, field: TEAM_MEMBER_RANKING_9_BOX_CLASSIFICATION_FIELD, align: TEAM_MEMBER_RANKING_9_BOX_CLASSIFICATION_ALIGN },
                ]
            );
        case 'teamMembarRankingDefault':
            return (
                [
                    { colName: TEAM_MEMBER_RANKING_TEAM_MEMBER_NAME, width: TEAM_MEMBER_RANKING_TEAM_MEMBER_WIDTH, field: TEAM_MEMBER_RANKING_TEAM_MEMBER_FIELD, align: TEAM_MEMBER_RANKING_TEAM_MEMBER_ALIGN },
                    { colName: CUMULATIVE_SCORE, width: CUMULATIVE_SCORE_WIDTH, field: CUMULATIVE_SCORE_FIELD, align: CUMULATIVE_SCORE_ALIGN },
                    { colName: REVIEW_STATUE, width: REVIEW_STATUE_WIDTH, field: REVIEW_STATUE_FIELD, align: REVIEW_STATUE_ALIGN },
                    { colName: BUSINESS_UNIT_FUNCTION, width: BUSINESS_UNIT_FUNCTION_HEAD_WIDTH, field: BUSINESS_UNIT_HEAD_FIELD, align: BUSINESS_UNIT_HEAD_ALIGN },
                    { colName: SUB_BUSINESS_UNIT, width: SUB_BUSINESS_UNIT_FUNC_NAME_WIDTH, field: BUSINESS_SUB_PNL_FUNC_NAME_FIELD, align: BUSINESS_SUB_PNL_FUNC_NAME_DASHBOARD_ALIGN },
                    { colName: SERVICE_DIVISION_SALES_UNIT, width: SERVICE_DIVISION_SALES_UNIT_WIDTH, field: SERVICE_DIVISION_SALES_UNIT_FIELD, align: SERVICE_DIVISION_SALES_UNIT_ALIGN },
                ]
            );
        case 'complianceReport':
            return (
                [
                    { colName: EMPLOYEE_NAME, width: EMPLOYEE_NAME_WIDTH, field: EMPLOYEE_NAME_FIELD, align: EMPLOYEE_NAME_ALIGN },
                    { colName: EMPLOYEE_CODE, width: EMPLOYEE_CODE_WIDTH, field: EMPLOYEE_CODE_FIELD, align: EMPLOYEE_CODE_ALIGN },

                    { colName: PRACTICE_AREA_SALES_UNIT_SUB_FUNCTION, width: PRACTICE_AREA_SALES_UNIT_SUB_FUNCTION_WIDTH, field: PRACTICE_AREA_SALES_UNIT_SUB_FUNCTION_FIELD, align: PRACTICE_AREA_SALES_UNIT_SUB_FUNCTION_ALIGN },

                    { colName: ADMINISTRATIVE_REPORTING_SUPERVISOR, width: ADMINISTRATIVE_REPORTING_SUPERVISOR_WIDTH, field: REPORTING_SUPERVISOR_FIELD, align: REPORTING_SUPERVISOR_ALIGN },

                    { colName: REVISED_ROLE_TITLE, width: REVISED_ROLE_TITLE_WIDTH, field: REVISED_ROLE_TITLE_FIELD, align: REVISED_ROLE_TITLE_ALIGN },

                    { colName: COMPLIANCE_REPORT_SELF, width: COMPLIANCE_REPORT_SELF_WIDTH, field: COMPLIANCE_REPORT_SELF_FIELD, align: COMPLIANCE_REPORT_SELF_ALIGN },
                    { colName: COMPLIANCE_REPORT_SUPERVISOR, width: COMPLIANCE_REPORT_SUPERVISOR_WIDTH, field: COMPLIANCE_REPORT_SUPERVISOR_FIELD, align: COMPLIANCE_REPORT_SUPERVISOR_ALIGN },
                    { colName: COMPLIANCE_REPORT_REVIEW, width: COMPLIANCE_REPORT_REVIEW_WIDTH, field: COMPLIANCE_REPORT_REVIEW_FIELD, align: COMPLIANCE_REPORT_REVIEW_ALIGN },
                ]
            );

        case 'folderManagement':
            return (
                [

                    { colName: FOLDERPARENT, width: FOLDERPARENT_WIDTH, field: FOLDERPARENT_FIELD, align: FOLDERPARENT_ALIGN },
                    { colName: FOLDERMANAGE, width: FOLDERMANAGE_WIDTH, field: FOLDERMANAGE_FIELD, align: FOLDERMANAGE_ALIGN },
                    // { colName: FOLDERSTATUS, width: FOLDERSTATUS_WIDTH, field: FOLDERSTATUS_FIELD, align: FOLDERSTATUS_ALIGN },
                    { colName: HIERARCHY, width: HIERARCHY_WIDTH_NEW, field: HIERARCHY_FIELD, align: HIERARCHY_ALIGN },
                    // { colName: ORDERID, width: ORDERID_WIDTH, field: ORDERID_FIELD, align: ORDERID_ALIGN},
                    { colName: FOLDER_ACTION, width: FOLDER_ACTION_WIDTH, field: FOLDER_ACTION_FIELD, align: FOLDER_ACTION_ALIGN },
                    // { colName: HIERARCHY, width: HIERARCHY_WIDTH_NEW, field: HIERARCHY_FIELD, align: HIERARCHY_ALIGN }
                ]
            );
        case 'leaveApproval':
            return (
                [
                    // { colName: LEAVE_APPROVAL_CHECK_BOX, width: LEAVE_APPROVAL_CHECK_BOX_WIDTH, field: LEAVE_APPROVAL_CHECK_BOX_FIELD, align: LEAVE_APPROVAL_CHECK_BOX_ALIGN },
                    { colName: LEAVE_APPROVAL_EMPLOYEE_NAME, width: LEAVE_APPROVAL_EMPLOYEE_NAME_WIDTH - parseInt(`${isOneTwoEightZero ? 15 : isThirteenHundred ? 0 : isOneTwentyFiveInSixteenEighty ? 10 : isFourteenHundred ? 10 : isOneTwentyFiveScale ? 50 : 0}`), field: LEAVE_APPROVAL_EMPLOYEE_NAME_FIELD, align: EMPLOYEE_NAME_ALIGN },
                    { colName: DEPARTMENT_NAME, width: DEPARTMENT_NAME_WIDTH + parseInt(`${isOneTwoEightZero ? 95 : isThirteenHundred ? 40 : isOneTwentyFiveInSixteenEighty ? 35 : isFourteenHundred ? 0 : isOneTwentyFiveScale ? 35 : isSixteenEighty ? 25 : 40}`), field: DEPARTMENT_NAME_FIELD, align: DEPARTMENT_NAME_ALIGN },
                    { colName: LEAVE_APPROVAL_ROLE, width: LEAVE_APPROVAL_ROLE_WIDTH, field: LEAVE_APPROVAL_ROLE_FIELD, align: LEAVE_APPROVAL_ROLE_ALIGN },
                    { colName: LEAVE_CLUSTER_LEAVE_TYPE, width: LEAVE_CLUSTER_LEAVE_TYPE_WIDTH - parseInt(`${isOneTwoEightZero ? -15 : isThirteenHundred ? 10 : isOneTwentyFiveInSixteenEighty ? 0 : isFourteenHundred ? 10 : 0}`), field: LEAVE_CLUSTER_LEAVE_TYPE_FIELD, align: LEAVE_CLUSTER_LEAVE_TYPE_ALIGN },
                    { colName: LEAVE_APPROVAL_APPLIED_ON, width: LEAVE_APPROVAL_APPLIED_ON_WIDTH - parseInt(`${isThirteenHundred ? 40 : isOneTwentyFiveInSixteenEighty ? 30 : isFourteenHundred ? 40 : isOneTwentyFiveScale ? 20 : 40}`), field: LEAVE_APPROVAL_APPLIED_ON_FIELD, align: LEAVE_APPROVAL_APPLIED_ON_ALIGN },
                    { colName: LEAVE_CLUSTER_START_DATE, width: LEAVE_CLUSTER_START_DATE_WIDTH - 30, field: LEAVE_CLUSTER_START_DATE_FIELD, align: LEAVE_CLUSTER_START_DATE_ALIGN },
                    { colName: LEAVE_APPROVAL_END_DATE, width: LEAVE_APPROVAL_END_DATE_WIDTH - 30, field: LEAVE_APPROVAL_END_DATE_FIELD, align: LEAVE_APPROVAL_END_DATE_ALIGN },
                    { colName: LEAVE_APPROVAL_NO_OF_DAYS, width: LEAVE_APPROVAL_NO_OF_DAYS_WIDTH - parseInt(`${isOneTwoEightZero ? 35 : isThirteenHundred ? 58 : isOneTwentyFiveInSixteenEighty ? 12 : isFourteenHundred ? 38 : isOneTwentyFiveScale ? 20 : 28}`), field: LEAVE_APPROVAL_NO_OF_DAYS_FIELD, align: LEAVE_APPROVAL_NO_OF_DAYS_ALIGN },
                    { colName: REASON, width: REASON_WIDTH + 40, field: LEAVE_APPROVAL_REASON_FIELD, align: REASON_ALIGN },
                    { colName: LEAVE_APPROVAL_LEAVE_TYPE, width: LEAVE_APPROVAL_LEAVE_TYPE_WIDTH - parseInt(`${isThirteenHundred ? 25 : isOneTwentyFiveInSixteenEighty ? 40 : isFourteenHundred ? 25 : 20}`), field: LEAVE_APPROVAL_LEAVE_TYPE_FIELD, align: LEAVE_APPROVAL_LEAVE_TYPE_ALIGN },
                    { colName: CANDIDATE_STATUS, width: CANDIDATE_STATUS_WIDTH - parseInt(`${isThirteenHundred ? 20 : isOneTwentyFiveInSixteenEighty ? 10 : 20}`), field: LEAVE_APPROVAL_STATUS_FIELD, align: CANDIDATE_STATUS_ALIGN },
                    // { colName: LEAVE_APPROVAL_ACTION, width: LEAVE_APPROVAL_ACTION_WIDTH, field: LEAVE_APPROVAL_ACTION_FIELD, align: LEAVE_APPROVAL_ACTION_ALIGN },
                    { colName: ADMIN_MODULE_ACTION, width: LEAVE_APPROVAL_ACTION_WIDTH - parseInt(`${isOneTwoEightZero ? 10 : 0}`), field: ADMIN_MODULE_ACTION_NAME_FIELD, align: ADMIN_MODULE_ACTION_ALIGN },

                ]
            );
        case 'leaveTransferApproval':
            return (
                [
                    // { colName: LEAVE_APPROVAL_CHECK_BOX, width: LEAVE_APPROVAL_CHECK_BOX_WIDTH, field: LEAVE_APPROVAL_CHECK_BOX_FIELD, align: LEAVE_APPROVAL_CHECK_BOX_ALIGN },
                    { colName: LEAVE_APPROVAL_EMPLOYEE_NAME, width: LEAVE_APPROVAL_EMPLOYEE_NAME_WIDTH, field: LEAVE_APPROVAL_EMPLOYEE_NAME_FIELD, align: EMPLOYEE_NAME_ALIGN },
                    { colName: DEPARTMENT_NAME, width: DEPARTMENT_NAME_WIDTH + parseInt(`${isOneTwoEightZero ? 25 : isThirteenHundred ? 80 : isFourteenHundred ? -10 : isOneTwentyFiveInSixteenEighty ? 40 : isFourteenHundred ? 50 : isSixteenEighty ? 105 : 50}`), field: DEPARTMENT_NAME_FIELD, align: DEPARTMENT_NAME_ALIGN },
                    { colName: LEAVE_APPROVAL_SOURCE_LEAVE, width: LEAVE_APPROVAL_SOURCE_LEAVE_WIDTH - parseInt(`${isOneTwoEightZero ? -15 : isThirteenHundred || isFourteenHundred ? -10 : isOneTwentyFiveScale ? 5 : isSixteenHundred ? 25 : 15}`), field: LEAVE_APPROVAL_SOURCE_LEAVE_FIELD, align: LEAVE_APPROVAL_SOURCE_LEAVE_ALIGN },
                    { colName: LEAVE_APPROVAL_DESTINATION_LEAVE, width: LEAVE_APPROVAL_DESTINATION_LEAVE_WIDTH + parseInt(`${isOneTwoEightZero ? 5 : isThirteenHundred || isFourteenHundred ? 60 : isOneTwentyFiveScale ? -5 : 25}`), field: LEAVE_APPROVAL_DESTINATION_LEAVE_FIELD, align: LEAVE_APPROVAL_DESTINATION_LEAVE_ALIGN },
                    { colName: LEAVE_APPROVAL_NO_OF_DAYS, width: LEAVE_APPROVAL_NO_OF_DAYS_WIDTH - parseInt(`${isOneTwoEightZero ? 35 : isThirteenHundred ? 60 : isOneTwentyFiveInSixteenEighty ? 25 : isFourteenHundred ? 40 : isOneTwentyFiveScale ? 25 : 30}`), field: LEAVE_APPROVAL_NO_OF_LEAVES_FIELD, align: LEAVE_APPROVAL_NO_OF_DAYS_ALIGN },
                    { colName: REASON, width: REASON_WIDTH + parseInt(`${isThirteenHundred || isFourteenHundred ? 20 : 30}`), field: LEAVE_APPROVAL_REASON_FIELD, align: REASON_ALIGN },
                    { colName: LEAVE_APPROVAL_LEAVE_TYPE, width: LEAVE_APPROVAL_LEAVE_TYPE_WIDTH - 20, field: LEAVE_APPROVAL_LEAVE_TYPE_FIELD, align: LEAVE_APPROVAL_LEAVE_TYPE_ALIGN },
                    { colName: CANDIDATE_STATUS, width: CANDIDATE_STATUS_WIDTH - parseInt(`${isThirteenHundred || isFourteenHundred ? 40 : 0}`), field: LEAVE_APPROVAL_STATUS_FIELD, align: CANDIDATE_STATUS_ALIGN },
                    // { colName: LEAVE_APPROVAL_ACTION, width: LEAVE_APPROVAL_ACTION_WIDTH, field: LEAVE_APPROVAL_ACTION_FIELD, align: LEAVE_APPROVAL_ACTION_ALIGN },
                    { colName: ADMIN_MODULE_ACTION, width: LEAVE_APPROVAL_ACTION_WIDTH, field: ADMIN_MODULE_ACTION_NAME_FIELD, align: ADMIN_MODULE_ACTION_ALIGN },
                ]
            );
        case 'swipeApproval':
            return (
                [
                    // { colName: LEAVE_APPROVAL_CHECK_BOX, width: LEAVE_APPROVAL_CHECK_BOX_WIDTH, field: LEAVE_APPROVAL_CHECK_BOX_FIELD, align: LEAVE_APPROVAL_CHECK_BOX_ALIGN },
                    { colName: LEAVE_APPROVAL_EMPLOYEE_NAME, width: LEAVE_APPROVAL_EMPLOYEE_NAME_WIDTH - parseInt(`${isOneTwoEightZero ? 10 : isThirteenHundred ? 0 : isFourteenHundred ? 10 : isOneTwentyFiveScale ? 10 : 50}`), field: LEAVE_APPROVAL_EMPLOYEE_NAME_FIELD, align: EMPLOYEE_NAME_ALIGN },
                    { colName: DEPARTMENT_NAME, width: DEPARTMENT_NAME_WIDTH + parseInt(`${isOneTwoEightZero ? 35 : isThirteenHundred ? 40 : isOneTwentyFiveInSixteenEighty ? 40 : isFourteenHundred ? -10 : isSixteenEighty ? 45 : 40}`), field: DEPARTMENT_NAME_FIELD, align: DEPARTMENT_NAME_ALIGN },
                    { colName: LEAVE_CLUSTER_SWIPE_TYPE, width: LEAVE_CLUSTER_LEAVE_TYPE_WIDTH + parseInt(`${isOneTwoEightZero ? 30 : isThirteenHundred ? 45 : isOneTwentyFiveInSixteenEighty ? 40 : isFourteenHundred ? 45 : 50}`), field: LEAVE_CLUSTER_SWIPE_TYPE_FIELD, align: LEAVE_CLUSTER_LEAVE_TYPE_ALIGN },
                    { colName: LEAVE_APPROVAL_MODE, width: LEAVE_APPROVAL_MODE_WIDTH - parseInt(`${isOneTwoEightZero ? 20 : isThirteenHundred ? 20 : isOneTwentyFiveInSixteenEighty ? 20 : isFourteenHundred ? 20 : isOneTwentyFiveScale ? 10 : 20}`), field: LEAVE_APPROVAL_MODE_FIELD, align: LEAVE_APPROVAL_MODE_ALIGN },
                    { colName: LEAVE_APPROVAL_APPLIED_ON, width: LEAVE_APPROVAL_APPLIED_ON_WIDTH - parseInt(`${isThirteenHundred ? 40 : isOneTwentyFiveInSixteenEighty ? 20 : isFourteenHundred ? 40 : isOneTwentyFiveScale ? 10 : 40}`), field: LEAVE_APPROVAL_APPLIED_ON_FIELD, align: LEAVE_APPROVAL_APPLIED_ON_ALIGN },
                    { colName: LEAVE_CLUSTER_APPLIED_FOR_DATE, width: LEAVE_APPROVAL_APPLIED_ON_WIDTH - parseInt(`${isOneTwoEightZero ? 40 : isThirteenHundred ? 0 : isFourteenHundred ? 0 : isOneTwentyFiveScale ? 5 : 0}`), field: LEAVE_CLUSTER_APPLIED_FOR_DATE_FIELD, align: LEAVE_APPROVAL_APPLIED_ON_ALIGN },
                    { colName: LEAVE_SWIPE_IN_TIME, width: LEAVE_SWIPE_TIME_WIDTH - parseInt(`${isThirteenHundred ? 45 : isFourteenHundred ? 30 : 25}`), field: LEAVE_SWIPE_IN_TIME_FIELD, align: LEAVE_SWIPE_TIME_ALIGN },
                    { colName: LEAVE_SWIPE_OUT_TIME, width: LEAVE_SWIPE_TIME_WIDTH - parseInt(`${isThirteenHundred ? 40 : isOneTwentyFiveInSixteenEighty ? 35 : isFourteenHundred ? 25 : isOneTwentyFiveScale ? 22 : 30}`), field: LEAVE_SWIPE_OUT_TIME_FIELD, align: LEAVE_SWIPE_TIME_ALIGN },
                    { colName: REASON, width: REASON_WIDTH + parseInt(`${isThirteenHundred || isFourteenHundred ? 25 : 30}`), field: LEAVE_APPROVAL_REASON_FIELD, align: REASON_ALIGN },
                    { colName: LEAVE_APPROVAL_LEAVE_TYPE, width: LEAVE_APPROVAL_LEAVE_TYPE_WIDTH - parseInt(`${isOneTwoEightZero ? 25 : isThirteenHundred ? 20 : isOneTwentyFiveInSixteenEighty ? 48 : isOneTwentyFiveScale ? 40 : 20}`), field: LEAVE_APPROVAL_LEAVE_TYPE_FIELD, align: LEAVE_APPROVAL_LEAVE_TYPE_ALIGN },
                    { colName: CANDIDATE_STATUS, width: CANDIDATE_STATUS_WIDTH - parseInt(`${isThirteenHundred ? 40 : isFourteenHundred ? 30 : isOneTwentyFiveScale ? 30 : isSixteenHundred ? 30 : 40}`), field: LEAVE_APPROVAL_STATUS_FIELD, align: CANDIDATE_STATUS_ALIGN },
                    // { colName: LEAVE_APPROVAL_ACTION, width: LEAVE_APPROVAL_ACTION_WIDTH, field: LEAVE_APPROVAL_ACTION_FIELD, align: LEAVE_APPROVAL_ACTION_ALIGN },
                    { colName: ADMIN_MODULE_ACTION, width: LEAVE_APPROVAL_ACTION_WIDTH, field: ADMIN_MODULE_ACTION_NAME_FIELD, align: ADMIN_MODULE_ACTION_ALIGN },
                ]
            );
        case 'compOffApproval':
            return (
                [
                    // { colName: LEAVE_APPROVAL_CHECK_BOX, width: LEAVE_APPROVAL_CHECK_BOX_WIDTH, field: LEAVE_APPROVAL_CHECK_BOX_FIELD, align: LEAVE_APPROVAL_CHECK_BOX_ALIGN },
                    { colName: LEAVE_APPROVAL_EMPLOYEE_NAME, width: LEAVE_APPROVAL_EMPLOYEE_NAME_WIDTH + parseInt(`${isThirteenHundred ? 0 : isFourteenHundred ? 0 : 0 /**NOSONAR */}`), field: LEAVE_APPROVAL_EMPLOYEE_NAME_FIELD, align: EMPLOYEE_NAME_ALIGN },
                    { colName: DEPARTMENT_NAME, width: DEPARTMENT_NAME_WIDTH + parseInt(`${isOneTwoEightZero ? 110 : isThirteenHundred ? 29 : isFourteenHundred ? 0 : isOneTwentyFiveScale ? 37 : isSixteenHundred ? 57 : 42}`), field: DEPARTMENT_NAME_FIELD, align: DEPARTMENT_NAME_ALIGN },
                    { colName: LEAVE_CLUSTER_LEAVE_TYPE, width: LEAVE_CLUSTER_LEAVE_TYPE_WIDTH + parseInt(`${isThirteenHundred || isFourteenHundred ? 30 : isOneTwentyFiveScale ? 10 : 0}`), field: LEAVE_CLUSTER_LEAVE_TYPE_FIELD, align: LEAVE_CLUSTER_LEAVE_TYPE_ALIGN },
                    { colName: LEAVE_CLUSTER_START_DATE, width: LEAVE_CLUSTER_START_DATE_WIDTH - parseInt(`${isThirteenHundred || isFourteenHundred ? 0 : isOneTwentyFiveScale ? 30 : 0}`), field: LEAVE_CLUSTER_START_DATE_FIELD, align: LEAVE_CLUSTER_START_DATE_ALIGN },
                    { colName: LEAVE_APPROVAL_END_DATE, width: LEAVE_APPROVAL_END_DATE_WIDTH - parseInt(`${isThirteenHundred || isFourteenHundred ? 0 : isOneTwentyFiveScale ? 30 : 0}`), field: LEAVE_APPROVAL_END_DATE_FIELD, align: LEAVE_APPROVAL_END_DATE_ALIGN },
                    { colName: LEAVE_APPROVAL_NO_OF_DAYS, width: LEAVE_APPROVAL_NO_OF_DAYS_WIDTH - parseInt(`${isOneTwoEightZero ? 38 : isThirteenHundred ? 50 : isOneTwentyFiveInSixteenEighty ? 27 : isFourteenHundred ? 50 : isOneTwentyFiveScale ? 23 : 0}`), field: LEAVE_APPROVAL_NO_OF_LEAVES_FIELD, align: LEAVE_APPROVAL_NO_OF_DAYS_ALIGN },
                    { colName: REASON, width: REASON_WIDTH + parseInt(`${isThirteenHundred || isFourteenHundred ? 10 : 30}`), field: LEAVE_APPROVAL_REASON_FIELD, align: REASON_ALIGN },
                    { colName: LEAVE_APPROVAL_LEAVE_TYPE, width: LEAVE_APPROVAL_LEAVE_TYPE_WIDTH - parseInt(`${isThirteenHundred || isFourteenHundred ? 30 : isOneTwentyFiveScale ? 30 : 20}`), field: LEAVE_APPROVAL_LEAVE_TYPE_FIELD, align: LEAVE_APPROVAL_LEAVE_TYPE_ALIGN },
                    { colName: CANDIDATE_STATUS, width: CANDIDATE_STATUS_WIDTH, field: LEAVE_APPROVAL_STATUS_FIELD, align: CANDIDATE_STATUS_ALIGN },
                    // { colName: LEAVE_APPROVAL_ACTION, width: LEAVE_APPROVAL_ACTION_WIDTH, field: LEAVE_APPROVAL_ACTION_FIELD, align: LEAVE_APPROVAL_ACTION_ALIGN },
                    { colName: ADMIN_MODULE_ACTION, width: LEAVE_APPROVAL_ACTION_WIDTH, field: ADMIN_MODULE_ACTION_NAME_FIELD, align: ADMIN_MODULE_ACTION_ALIGN },
                ]
            );
        case 'specialAttendanceApproval': //NOSONAR
            return (
                [
                    // { colName: LEAVE_APPROVAL_CHECK_BOX, width: LEAVE_APPROVAL_CHECK_BOX_WIDTH, field: LEAVE_APPROVAL_CHECK_BOX_FIELD, align: LEAVE_APPROVAL_CHECK_BOX_ALIGN },
                    { colName: LEAVE_APPROVAL_EMPLOYEE_NAME, width: LEAVE_APPROVAL_EMPLOYEE_NAME_WIDTH, field: LEAVE_APPROVAL_EMPLOYEE_NAME_FIELD, align: EMPLOYEE_NAME_ALIGN },
                    { colName: DEPARTMENT_NAME, width: DEPARTMENT_NAME_WIDTH + parseInt(`${isOneTwoEightZero ? 110 : isThirteenHundred ? 103 : isFourteenHundred ? 0 : isOneTwentyFiveScale ? 45 : isSixteenHundred ? 28 : 100}`), field: DEPARTMENT_NAME_FIELD, align: DEPARTMENT_NAME_ALIGN },
                    { colName: LEAVE_CLUSTER_LEAVE_TYPE, width: LEAVE_CLUSTER_LEAVE_TYPE_WIDTH - parseInt(`${isOneTwoEightZero ? 0 : isThirteenHundred ? 0 : isOneTwentyFiveInSixteenEighty ? 8 : isFourteenHundred ? -5 : isOneTwentyFiveScale ? -5 : 0}`), field: LEAVE_CLUSTER_LEAVE_TYPE_FIELD, align: LEAVE_CLUSTER_LEAVE_TYPE_ALIGN },
                    { colName: LEAVE_CLUSTER_START_DATE, width: LEAVE_CLUSTER_START_DATE_WIDTH, field: LEAVE_CLUSTER_START_DATE_FIELD, align: LEAVE_CLUSTER_START_DATE_ALIGN },
                    { colName: LEAVE_APPROVAL_END_DATE, width: LEAVE_APPROVAL_END_DATE_WIDTH, field: LEAVE_APPROVAL_END_DATE_FIELD, align: LEAVE_APPROVAL_END_DATE_ALIGN },
                    { colName: LEAVE_APPROVAL_NO_OF_DAYS, width: LEAVE_APPROVAL_NO_OF_DAYS_WIDTH - parseInt(`${isOneTwoEightZero ? 35 : isOneTwoEightZero ? 65 : isThirteenHundred ? 40 : isOneTwentyFiveInSixteenEighty ? 20 : isFourteenHundred ? 25 : -10}`), field: LEAVE_APPROVAL_NO_OF_LEAVES_FIELD, align: LEAVE_APPROVAL_NO_OF_DAYS_ALIGN },
                    { colName: REASON, width: REASON_WIDTH + parseInt(`${isThirteenHundred || isFourteenHundred ? 10 : 30}`), field: LEAVE_APPROVAL_REASON_FIELD, align: REASON_ALIGN },
                    { colName: LEAVE_APPROVAL_LEAVE_TYPE, width: LEAVE_APPROVAL_LEAVE_TYPE_WIDTH - parseInt(`${isOneTwoEightZero ? 35 : isThirteenHundred ? 15 : 20}`), field: LEAVE_APPROVAL_LEAVE_TYPE_FIELD, align: LEAVE_APPROVAL_LEAVE_TYPE_ALIGN },
                    { colName: CANDIDATE_STATUS, width: CANDIDATE_STATUS_WIDTH - 20, field: LEAVE_APPROVAL_STATUS_FIELD, align: CANDIDATE_STATUS_ALIGN },
                    // { colName: LEAVE_APPROVAL_ACTION, width: LEAVE_APPROVAL_ACTION_WIDTH, field: LEAVE_APPROVAL_ACTION_FIELD, align: LEAVE_APPROVAL_ACTION_ALIGN },
                    { colName: ADMIN_MODULE_ACTION, width: LEAVE_APPROVAL_ACTION_WIDTH, field: ADMIN_MODULE_ACTION_NAME_FIELD, align: ADMIN_MODULE_ACTION_ALIGN },
                ]
            );
        case 'symbol':
            return (
                [
                    { colName: ADMIN_MODULE_ACTION, width: '80px', field: ADMIN_MODULE_ACTION_NAME_FIELD, align: ADMIN_MODULE_ACTION_ALIGN },
                    { colName: SYMBOL_CLUSTER_NAME, width: '220px', field: SYMBOL_CLUSTER_NAME_FIELD, align: SYMBOL_CLUSTER_NAME_ALIGN },
                    { colName: SYMBOL_CLUSTER_SYMBOL, width: '100px', field: SYMBOL_CLUSTER_SYMBOL_FIELD, align: SYMBOL_CLUSTER_SYMBOL_ALIGN },
                    { colName: SYMBOL_CLUSTER_DESCRIPTION, width: '250px', field: SYMBOL_CLUSTER_DESCRIPTION_FIELD, align: SYMBOL_CLUSTER_DESCRIPTION_ALIGN },
                    { colName: SYMBOL_CLUSTER_MASTER, width: '300px', field: SYMBOL_CLUSTER_MASTER_FIELD, align: SYMBOL_CLUSTER_MASTER_ALIGN },
                    { colName: SYMBOL_CLUSTER_ACTIVE, width: '100px', field: SYMBOL_CLUSTER_ACTIVE_FIELD, align: SYMBOL_CLUSTER_ACTIVE_ALIGN },
                ]
            );

        case 'leaveYear':
            return (
                [
                    { colName: ADMIN_MODULE_ACTION, width: 40, field: ADMIN_MODULE_ACTION_NAME_FIELD, align: ADMIN_MODULE_ACTION_ALIGN },
                    { colName: LEAVE_YEAR_MEMBERID, width: 110, field: LEAVE_YEAR_MEMBERID_FIELD, align: LEAVE_YEAR_MEMBERID_ALIGN },
                    { colName: LEAVE_YEAR_MEMBERNAME, width: 110, field: LEAVE_YEAR_MEMBERNAME_FIELD, align: LEAVE_YEAR_MEMBERNAME_ALIGN },
                    { colName: LEAVE_YEAR_BUSINESS, width: 120, field: LEAVE_YEAR_BUSINESS_FIELD, align: LEAVE_YEAR_BUSINESS_ALIGN },
                    { colName: LEAVE_YEAR_SUB_BUSINESS, width: 120, field: LEAVE_YEAR_SUB_BUSINESS_FIELD, align: LEAVE_YEAR_SUB_BUSINESS_ALIGN },
                    { colName: LEAVE_YEAR_PRACTICEAREA, width: 180, field: LEAVE_YEAR_PRACTICEAREA_FIELD, align: LEAVE_YEAR_PRACTICEAREA_ALIGN },
                    { colName: LEAVE_YEAR, width: 110, field: LEAVE_YEAR_FIELD, align: LEAVE_YEAR_ALIGN },
                    { colName: LEAVE_YEAR_ACTIVE, width: 60, field: LEAVE_YEAR_ACTIVE_FIELD, align: LEAVE_YEAR_ACTIVE_ALIGN },
                ]
            );


        case 'blockermaster':
            return (
                [
                    { colName: ADMIN_MODULE_ACTION, width: 80, field: ADMIN_MODULE_ACTION_NAME_FIELD, align: ADMIN_MODULE_ACTION_ALIGN },
                    { colName: BLOCK_MASTER_XPOSITION, width: 100, field: BLOCK_MASTER_XPOSITION_FIELD, align: BLOCK_MASTER_XPOSITION_ALIGN },
                    { colName: BLOCK_MASTER_YPOSITION, width: 100, field: BLOCK_MASTER_YPOSITION_FIELD, align: BLOCK_MASTER_YPOSITION_ALIGN },
                    { colName: BLOCK_MASTER_NAME, width: 150, field: BLOCK_MASTER_NAME_FIELD, align: BLOCK_MASTER_NAME_ALIGN },
                    { colName: BLOCK_MASTER_MEANING, width: 150, field: BLOCK_MASTER_MEANING_FIELD, align: BLOCK_MASTER_MEANING_ALIGN },
                    { colName: BLOCK_MASTER_DESCRIPTION, width: 150, field: BLOCK_MASTER_DESCRIPTION_FIELD, align: BLOCK_MASTER_DESCRIPTION_ALIGN },
                    { colName: BLOCK_MASTER_PLAN_OF_ACTION, width: 170, field: BLOCK_MASTER_PLAN_OF_ACTION_FIELD, align: BLOCK_MASTER_PLAN_OF_ACTION_ALIGN },
                    { colName: BLOCK_MASTER_PRIMARY_COLOR_NAME, width: 130, field: BLOCK_MASTER_PRIMARY_COLOR_NAME_FIELD, align: BLOCK_MASTER_PRIMARY_COLOR_NAME_ALIGN },
                    { colName: BLOCK_MASTER_SECONDRY_COLOR_NAME, width: 145, field: BLOCK_MASTER_SECONDRY_COLOR_NAME_FIELD, align: BLOCK_MASTER_SECONDRY_COLOR_NAME_ALIGN },
                    { colName: BLOCK_MASTER_FINANCIAL_YEAR, width: 140, field: BLOCK_MASTER_FINANCIAL_YEAR_FIELD, align: BLOCK_MASTER_FINANCIAL_YEAR_ALIGN },

                    { colName: BLOCK_MASTER_ACTIVE, width: 80, field: BLOCK_MASTER_ACTIVE_FIELD, align: BLOCK_MASTER_ACTIVE_ALIGN },
                ]
            );
        // master for Performance and Potential
        case 'productivityMaster':
            return (
                [
                    { colName: ADMIN_MODULE_ACTION, width: 80, field: ADMIN_MODULE_ACTION_NAME_FIELD, align: ADMIN_MODULE_ACTION_ALIGN },
                    { colName: PERFORMANCE_MASTER_NAME, width: 200, field: PERFORMANCE_MASTER_NAME_FIELD, align: PERFORMANCE_MASTER_NAME_ALIGN },
                    { colName: PRODUCTIVITY_ALLOCATION_TYPE, width: 200, field: PRODUCTIVITY_ALLOCATION_TYPE_FIELD, align: PRODUCTIVITY_ALLOCATION_TYPE_ALIGN },
                    { colName: PRODUCTIVITY_DESCRIPTION, width: 310, field: PRODUCTIVITY_DESCRIPTION_FIELD, align: PRODUCTIVITY_DESCRIPTION_ALIGN },
                    { colName: WEIGHTAGE_RANGE_FROM, width: 150, field: WEIGHTAGE_RANGE_FROM_FIELD, align: WEIGHTAGE_RANGE_FROM_ALIGN },
                    { colName: WEIGHTAGE_RANGE_TO, width: 130, field: WEIGHTAGE_RANGE_TO_FIELD, align: WEIGHTAGE_RANGE_TO_ALIGN },
                    { colName: PROD_DEFAULT_WEIGHTAGE, width: 160, field: PROD_DEFAULT_WEIGHTAGE_FIELD, align: PROD_DEFAULT_WEIGHTAGE_ALIGN },
                    { colName: DEFAULT_WEIGHTAGE_EDIT, width: 210, field: DEFAULT_WEIGHTAGE_EDIT_FIELD, align: DEFAULT_WEIGHTAGE_EDIT_ALIGN },
                    { colName: WEIGHTED_VARIABLE, width: 250, field: WEIGHTED_VARIABLE_FIELD, align: WEIGHTED_VARIABLE_ALIGN },
                    { colName: PRODUCITVITY_FINANCIAL_YEAR, width: 130, field: PRODUCITVITY_FINANCIAL_YEAR_FIELD, align: PRODUCITVITY_FINANCIAL_YEAR_ALIGN },
                    { colName: PRODUCTIVITY_FREQUENCY, width: 130, field: PRODUCTIVITY_FREQUENCY_FIELD, align: PRODUCTIVITY_FREQUENCY_ALIGN },
                    { colName: PROD_RATING_SCALE, width: 130, field: PROD_RATING_SCALE_FIELD, align: PROD_RATING_SCALE_ALIGN },
                    { colName: PRODUCITVITY_MASTER_ACTIVE, width: 80, field: PRODUCITVITY_MASTER_ACTIVE_FIELD, align: PRODUCITVITY_MASTER_ACTIVE_ALIGN },
                ]
            );

        case 'ratingSystem':
            return (
                [
                    { colName: ADMIN_MODULE_ACTION, width: 100, field: ADMIN_MODULE_ACTION_NAME_FIELD, align: ADMIN_MODULE_ACTION_ALIGN },
                    { colName: RATING_SYSTEM_NAME, width: 500, field: RATING_SYSTEM_NAME_FIELD, align: RATING_SYSTEM_NAME_ALIGN },
                    { colName: RATING_SYSTEM_ACTIVE, width: 80, field: RATING_SYSTEM_ACTIVE_FIELD, align: RATING_SYSTEM_ACTIVE_ALIGN },
                ]
            );
        case 'ratingMaster':
            return (
                [
                    { colName: ADMIN_MODULE_ACTION, width: 80, field: ADMIN_MODULE_ACTION_NAME_FIELD, align: ADMIN_MODULE_ACTION_ALIGN },
                    { colName: RATING_MASTER_SYSTEM_NAME, width: 150, field: RATING_MASTER_SYSTEM_NAME_FIELD, align: RATING_MASTER_SYSTEM_NAME_ALIGN },
                    { colName: RATING_MASTER_VALUE, width: 150, field: RATING_MASTER_VALUE_FIELD, align: RATING_MASTER_VALUE_ALIGN },
                    { colName: RATING_MASTER_MEANING, width: 150, field: RATING_MASTER_MEANING_FIELD, align: RATING_MASTER_MEANING_ALIGN },
                    { colName: RATING_MASTER_LEVEL_STARTING, width: 150, field: RATING_MASTER_LEVEL_STARTING_FIELD, align: RATING_MASTER_LEVEL_STARTING_ALIGN },
                    // { colName: RATING_MASTER_BENCHMARK, width: 100, field: RATING_MASTER_BENCHMARK_FIELD, align: RATING_MASTER_BENCHMARK_ALIGN },
                    { colName: RATING_SYSTEM_ACTIVE, width: 80, field: RATING_SYSTEM_ACTIVE_FIELD, align: RATING_SYSTEM_ACTIVE_ALIGN },
                ]
            );
        case 'axisCutOff':
            return (
                [
                    { colName: ADMIN_MODULE_ACTION, width: 80, field: ADMIN_MODULE_ACTION_NAME_FIELD, align: ADMIN_MODULE_ACTION_ALIGN },
                    { colName: AXIS_CUT_OFF_AXIS_NAME, width: 100, field: AXIS_CUT_OFF_AXIS_NAME_FIELD, align: AXIS_CUT_OFF_AXIS_NAME_ALIGN },
                    { colName: AXIS_CUT_OFF_RANGE, width: 80, field: AXIS_CUT_OFF_RANGE_FIELD, align: AXIS_CUT_OFF_RANGE_ALIGN },
                    { colName: AXIS_CUT_OFF_LOWER_VALUE, width: 100, field: AXIS_CUT_OFF_LOWER_VALUE_FIELD, align: AXIS_CUT_OFF_LOWER_VALUE_ALIGN },
                    { colName: AXIS_CUT_OFF_HIGHER_VALUE, width: 110, field: AXIS_CUT_OFF_HIGHER_VALUE_FIELD, align: AXIS_CUT_OFF_HIGHER_VALUE_FIELD_ALIGN },
                    { colName: AXIS_CUT_OFF_AXIS_POSITION, width: 110, field: AXIS_CUT_OFF_AXIS_POSITION_FIELD, align: AXIS_CUT_OFF_AXIS_POSITION_ALIGN },
                    { colName: AXIS_CUT_OFF_FINANCIAL_YEAR, width: 110, field: AXIS_CUT_OFF_FINANCIAL_YEAR_FIELD, align: AXIS_CUT_OFF_FINANCIAL_YEAR_ALIGN },
                    { colName: AXIS_CUT_OFF_STATUS, width: 80, field: AXIS_CUT_OFF_STATUS_FIELD, align: AXIS_CUT_OFF_STATUS_ALIGN },
                ]
            );
        case 'subPotentialCalculation':
            return (
                [
                    { colName: ADMIN_MODULE_ACTION, width: 80, field: ADMIN_MODULE_ACTION_NAME_FIELD, align: ADMIN_MODULE_ACTION_ALIGN },
                    { colName: SUB_POTENTIAL_CALCULATION_POTENTIAL_TYPE, width: 200, field: SUB_POTENTIAL_CALCULATION_POTENTIAL_TYPE_FIELD, align: SUB_POTENTIAL_CALCULATION_POTENTIAL_TYPE_ALIGN },
                    { colName: SUB_POTENTIAL_CALCULATION_SUB_POTENTIAL_NAME, width: 200, field: SUB_POTENTIAL_CALCULATION_SUB_POTENTIAL_NAME_FIELD, align: SUB_POTENTIAL_CALCULATION_SUB_POTENTIAL_NAME_ALIGN },
                    { colName: SUB_POTENTIAL_CALCULATION_SELF_ATTRIBUTE, width: 310, field: SUB_POTENTIAL_CALCULATION_SELF_ATTRIBUTE_FIELD, align: SUB_POTENTIAL_CALCULATION_SELF_ATTRIBUTE_ALIGN },
                    { colName: SUB_POTENTIAL_CALCULATION_SELF_ATTRIBUTE_DESCRIPTION, width: 310, field: SUB_POTENTIAL_CALCULATION_SELF_ATTRIBUTE_DESCRIPTION_FIELD, align: SUB_POTENTIAL_CALCULATION_SELF_ATTRIBUTE_DESCRIPTION_ALIGN },
                    { colName: SUB_POTENTIAL_CALCULATION_SUP_ATTRIBUTE, width: 310, field: SUB_POTENTIAL_CALCULATION_SUP_ATTRIBUTE_FIELD, align: SUB_POTENTIAL_CALCULATION_SUP_ATTRIBUTE_ALIGN },
                    { colName: SUB_POTENTIAL_CALCULATION_SUP_ATTRIBUTE_DESCRIPTION, width: 310, field: SUB_POTENTIAL_CALCULATION_SUP_ATTRIBUTE_DESCRIPTION_FIELD, align: SUB_POTENTIAL_CALCULATION_SUP_ATTRIBUTE_DESCRIPTION_ALIGN },
                    { colName: SUB_POTENTIAL_CALCULATION_FYID, width: 150, field: SUB_POTENTIAL_CALCULATION_FYID_FIELD, align: SUB_POTENTIAL_CALCULATION_FYID_ALIGN },
                    { colName: SUB_POTENTIAL_CALCULATION_FREQUENCY, width: 120, field: SUB_POTENTIAL_CALCULATION_FREQUENCY_FIELD, align: SUB_POTENTIAL_CALCULATION_FREQUENCY_ALIGN },
                    { colName: SUB_POTENTIAL_CALCULATION_START_MONTH, width: 130, field: SUB_POTENTIAL_CALCULATION_START_MONTH_FIELD, align: SUB_POTENTIAL_CALCULATION_START_MONTH_ALIGN },
                    { colName: SUB_POTENTIAL_CALCULATION_END_MONTH, width: 130, field: SUB_POTENTIAL_CALCULATION_END_MONTH_FIELD, align: SUB_POTENTIAL_CALCULATION_END_MONTH_ALIGN },
                    { colName: SUB_POTENTIAL_CALCULATION_RATING_SYSTEM, width: 150, field: SUB_POTENTIAL_CALCULATION_RATING_SYSTEM_FIELD, align: SUB_POTENTIAL_CALCULATION_RATING_SYSTEM_ALIGN },
                    { colName: SUB_POTENTIAL_CALCULATION_STATUS, width: 80, field: SUB_POTENTIAL_CALCULATION_STATUS_FIELD, align: SUB_POTENTIAL_CALCULATION_STATUS_ALIGN },
                ]
            );
        case 'potentialCalculation':
            return (
                [
                    { colName: ADMIN_MODULE_ACTION, width: 80, field: ADMIN_MODULE_ACTION_NAME_FIELD, align: ADMIN_MODULE_ACTION_ALIGN },
                    { colName: SUB_POTENTIAL_CALCULATION_POTENTIAL_TYPE, width: 300, field: SUB_POTENTIAL_CALCULATION_POTENTIAL_TYPE_FIELD, align: SUB_POTENTIAL_CALCULATION_POTENTIAL_TYPE_ALIGN },
                    { colName: SUB_POTENTIAL_CALCULATION_SUB_POTENTIAL_NAME, width: 300, field: SUB_POTENTIAL_CALCULATION_SUB_POTENTIAL_NAME_FIELD, align: SUB_POTENTIAL_CALCULATION_SUB_POTENTIAL_NAME_ALIGN },
                    { colName: POTENTIAL_CALCULATION_WEIGHTAGE, width: 215, field: POTENTIAL_CALCULATION_WEIGHTAGE_FIELD, align: POTENTIAL_CALCULATION_WEIGHTAGE_ALIGN },
                    { colName: POTENTIAL_CALCULATION_MANDATORY, width: 120, field: POTENTIAL_MANDATORY__FIELD, align: POTENTIAL_CALCULATION_MANDATORY_ALIGN },
                    { colName: SUB_POTENTIAL_CALCULATION_FYID, width: 130, field: SUB_POTENTIAL_CALCULATION_FYID_FIELD, align: SUB_POTENTIAL_CALCULATION_FYID_ALIGN },
                    { colName: SUB_POTENTIAL_CALCULATION_RATING_SYSTEM, width: 130, field: SUB_POTENTIAL_CALCULATION_RATING_SYSTEM_FIELD, align: SUB_POTENTIAL_CALCULATION_RATING_SYSTEM_ALIGN },
                    { colName: SUB_POTENTIAL_CALCULATION_STATUS, width: 80, field: SUB_POTENTIAL_CALCULATION_STATUS_FIELD, align: SUB_POTENTIAL_CALCULATION_STATUS_ALIGN },
                ]
            );
        case 'frequencyMaster':
            return (
                [
                    { colName: ADMIN_MODULE_ACTION, width: 80, field: ADMIN_MODULE_ACTION_NAME_FIELD, align: ADMIN_MODULE_ACTION_ALIGN },
                    { colName: FREQUENCY_MASTER_FINANCIAL_YAER, width: 130, field: FREQUENCY_MASTER_FINANCIAL_YAER_FIELD, align: FREQUENCY_MASTER_FINANCIAL_YAER_ALIGN },
                    { colName: FREQUENCY_MASTER_FREQUENCY, width: 130, field: FREQUENCY_MASTER_FREQUENCY_FIELD, align: FREQUENCY_MASTER_FREQUENCY_ALIGN },
                    { colName: FREQUENCY_MASTER_INDICATOR, width: 100, field: FREQUENCY_MASTER_INDICATOR_FIELD, align: FREQUENCY_MASTER_INDICATOR_ALIGN },
                    { colName: APPLICABLE_PERIOD, align: APPLICABLE_PERIOD_ALIGN, width: 300 },

                    // { colName: FREQUENCY_MASTER_APPLY_PERIOD_FROM, width: '150', field: FREQUENCY_MASTER_APPLY_PERIOD_FROM_FIELD, align: FREQUENCY_MASTER_APPLY_PERIOD_FROM_ALIGN },
                    // { colName: FREQUENCY_MASTER_APPLY_PERIOD_TO, width: '500', field: FREQUENCY_MASTER_APPLY_PERIOD_TO_FIELD, align: FREQUENCY_MASTER_APPLY_PERIOD_TO_ALIGN },

                    { colName: PERFORMANCE_OKR, width: 500, align: PERFORMANCE_OKR_ALIGN },
                    // { colName: FREQUENCY_ADD_EDIT_PERFORM_ST_DATE, width: '500', field: FREQUENCY_ADD_EDIT_PERFORM_ST_DATE_FIELD, align: FREQUENCY_ADD_EDIT_PERFORM_ST_DATE_ALIGN },
                    // { colName: FREQUENCY_ADD__PERFORM_END_DATE, width: '500', field: FREQUENCY_ADD__PERFORM_END_DATE_FIELD, align: FREQUENCY_ADD__PERFORM_END_DATE_ALIGN },
                    // { colName: FREQUENCY_EDIT__PERFORM_END_DATE, width: '140', field: FREQUENCY_EDIT__PERFORM_END_DATE_FIELD, align: FREQUENCY_EDIT__PERFORM_END_DATE_ALIGN },

                    { colName: POTENTIAL_OKR, width: 400, align: POTENTIAL_OKR_ALIGN },
                    { colName: SELF_SUP_FINAL_RATING, width: 400, align: POTENTIAL_OKR_ALIGN },
                    // { colName: FREQUENCY_ADD_EDIT_POTENTIAL_ST_DATE, width: '500', field: FREQUENCY_ADD_EDIT_POTENTIAL_ST_DATE_FIELD, align: FREQUENCY_ADD_EDIT_POTENTIAL_ST_DATE_ALIGN },
                    // { colName: FREQUENCY_ADD__POTENTIAL_END_DATE, width: '600', field: FREQUENCY_ADD__POTENTIAL_END_DATE_FIELD, align: FREQUENCY_ADD__POTENTIAL_END_DATE_ALIGN },
                    // { colName: FREQUENCY_EDIT__POTENTIAL_END_DATE, width: '200', field: FREQUENCY_EDIT__POTENTIAL_END_DATE_FIELD, align: FREQUENCY_EDIT__POTENTIAL_END_DATE_ALIGN },

                    // { colName: FREQUENCY_SELF_SUP_FINAL_RATING_ST_DATE, width: '140', field: PFREQUENCY_SELF_SUP_FINAL_RATING_ST_DATE_FIELD, align: FREQUENCY_SELF_SUP_FINAL_RATING_ST_DATE_ALIGN },
                    // { colName: FREQUENCY_SELF_RATING_END_DATE, width: '108', field: FREQUENCY_SELF_RATING_END_DATE_FIELD, align: FREQUENCY_SELF_RATING_END_DATE_ALIGN },
                    // { colName: FREQUENCY_SUP_RATING_END_DATE, width: '200', field: FREQUENCY_SUP_RATING_END_DATE_FIELD, align: FREQUENCY_SUP_RATING_END_DATE_ALIGN },
                    // { colName: FREQUENCY_FINAL_RATING_END_DATE, width: '200', field: FREQUENCY_FINAL_RATING_END_DATE_FIELD, align: FREQUENCY_FINAL_RATING_END_DATE_ALIGN },

                    { colName: FREQUENCY_MASTER_ACTIVE, width: 80, field: FREQUENCY_MASTER_ACTIVE_FIELD, align: FREQUENCY_MASTER_ACTIVE_ALIGN },
                ]
            );
        case 'axisMaster':
            return (
                [
                    // X axis
                    { colName: ADMIN_MODULE_ACTION, width: 100, field: ADMIN_MODULE_ACTION_NAME_FIELD, align: AXIS_MASTER_ACTION_ALIGN },
                    // { colName: AXIS_MASTER_GROUP_NAME, width: 300, field: AXIS_MASTER_GROUP_NAME_FIELD, align: AXIS_MASTER_GROUP_NAME_ALIGN },
                    { colName: AXIS_MASTER_AXIS_NAME, width: 130, field: AXIS_MASTER_AXIS_NAME_FIELD, align: AXIS_MASTER_AXIS_NAME_ALIGN },
                    { colName: AXIS_MASTER_AXIS_MEANING, width: 180, field: AXIS_MASTER_AXIS_MEANING_FIELD, align: AXIS_MASTER_AXIS_MEANING_ALIGN },
                    { colName: AXIS_MASTER_AXIS_WEIGHTED_ALLOCATION, width: 180, field: AXIS_MASTER_AXIS_WEIGHTED_ALLOCATION_FIELD, align: AXIS_MASTER_AXIS_WEIGHTED_ALLOCATION_ALIGN },
                    { colName: AXIS_MASTER_AXIS_WEIGHTED_VARIABLE, width: 220, field: AXIS_MASTER_AXIS_WEIGHTED_VARIABLE_FIELD, align: AXIS_MASTER_AXIS_WEIGHTED_VARIABLE_ALIGN },
                    { colName: AXIS_MASTER_AXIS_FY, width: 140, field: AXIS_MASTER_AXIS_FY_FIELD, align: AXIS_MASTER_AXIS_FY_ALIGN },
                    { colName: AXIS_CUT_OFF_STATUS, width: 80, field: AXIS_CUT_OFF_STATUS_FIELD, align: AXIS_CUT_OFF_STATUS_ALIGN }
                ]
            );

        case 'teamMember':
            return (
                [
                    // { colName: TEAM_MEMBER_CHECKBOX, width: TEAM_MEMBER_CHECKBOX_WIDTH, type: TEAM_MEMBER_CHECKBOX_TYPE, align: TEAM_MEMBER_CHECKBOX_ALIGN },
                    { colName: TEAM_MEMBER_NAME, width: 200, field: TEAM_MEMBER_NAME_FIELD, align: RATING_MASTER_SYSTEM_NAME_ALIGN },
                    // { colName: TEAM_MEMBER_TITLE, width: 400, field: TEAM_MEMBER_TITLE_FIELD, align: TEAM_MEMBER_TITLE_ALIGN },
                    { colName: TEAM_MEMBER_DEPARTMENT, width: 250, field: TEAM_MEMBER_DEPARTMENT_FIELD, align: TEAM_MEMBER_DEPARTMENT_ALIGN },
                    { colName: TEAM_MEMBER_DATE_OF_JOINING, width: 140, field: TEAM_MEMBER_DATE_OF_JOINING_FIELD, align: TEAM_MEMBER_DATE_OF_JOINING_ALIGN },
                    { colName: TEAM_MEMBER_DATE_OF_FIRST, width: 110, field: TEAM_MEMBER_DATE_OF_FIRST_FIELD, align: TEAM_MEMBER_DATE_OF_FIRST_ALIGN },
                    { colName: TEAM_MEMBER_DATE_OF_SECOND, width: 110, field: TEAM_MEMBER_DATE_OF_SECOND_FIELD, align: TEAM_MEMBER_DATE_OF_SECOND_ALIGN },
                    { colName: TEAM_MEMBER_DATE_OF_THIRD, width: 110, field: TEAM_MEMBER_DATE_OF_THIRD_FIELD, align: TEAM_MEMBER_DATE_OF_THIRD_ALIGN },
                    { colName: TEAM_MEMBER_DATE_OF_LAST, width: 110, field: TEAM_MEMBER_DATE_OF_LAST_FIELD, align: TEAM_MEMBER_DATE_OF_LAST_ALIGN },
                    { colName: TEAM_MEMBER_DATE_OF_AUG, width: 110, field: TEAM_MEMBER_DATE_OF_AUG_FIELD, align: TEAM_MEMBER_DATE_OF_AUG_ALIGN },
                    { colName: TEAM_MEMBER_DATE_OF_SEP, width: 130, field: TEAM_MEMBER_DATE_OF_SEP_FIELD, align: TEAM_MEMBER_DATE_OF_SEP_ALIGN },
                    { colName: TEAM_MEMBER_DATE_OF_OCT, width: 110, field: TEAM_MEMBER_DATE_OF_OCT_FIELD, align: TEAM_MEMBER_DATE_OF_OCT_ALIGN },
                    { colName: TEAM_MEMBER_DATE_OF_NOV, width: 110, field: TEAM_MEMBER_DATE_OF_NOV_FIELD, align: TEAM_MEMBER_DATE_OF_NOV_ALIGN },
                    { colName: TEAM_MEMBER_DATE_OF_DEC, width: 130, field: TEAM_MEMBER_DATE_OF_DEC_FIELD, align: TEAM_MEMBER_DATE_OF_DEC_ALIGN },
                    { colName: TEAM_MEMBER_DATE_OF_JAN, width: 110, field: TEAM_MEMBER_DATE_OF_JAN_FIELD, align: TEAM_MEMBER_DATE_OF_JAN_ALIGN },
                    { colName: TEAM_MEMBER_DATE_OF_FEB, width: 110, field: TEAM_MEMBER_DATE_OF_FEB_FIELD, align: TEAM_MEMBER_DATE_OF_FEB_ALIGN },
                    { colName: TEAM_MEMBER_DATE_OF_MAR, width: 150, field: TEAM_MEMBER_DATE_OF_MAR_FIELD, align: TEAM_MEMBER_DATE_OF_MAR_ALIGN },

                ]
            );
        // case 'axisCutOff':
        //     return (
        //         [
        //             { colName: AXIS_CUT_OFF_AXIS_NAME, width: 50, field: AXIS_CUT_OFF_AXIS_NAME_FIELD, align: AXIS_CUT_OFF_AXIS_NAME_ALIGN },
        //             { colName: AXIS_CUT_OFF_RANGE, width: 50, field: AXIS_CUT_OFF_RANGE_FIELD, align: AXIS_CUT_OFF_RANGE_ALIGN },
        //             { colName: AXIS_CUT_OFF_LOWER_VALUE, width: 50, field: AXIS_CUT_OFF_LOWER_VALUE_FIELD, align: AXIS_CUT_OFF_LOWER_VALUE_ALIGN },
        //             { colName: AXIS_CUT_OFF_HIGHER_VALUE, width: 50, field: AXIS_CUT_OFF_HIGHER_VALUE_FIELD, align: AXIS_CUT_OFF_HIGHER_VALUE_FIELD_ALIGN },
        //             { colName: AXIS_CUT_OFF_AXIS_POSITION, width: 50, field: AXIS_CUT_OFF_AXIS_POSITION_FIELD, align: AXIS_CUT_OFF_AXIS_POSITION_ALIGN },
        //             { colName: AXIS_CUT_OFF_FINANCIAL_YEAR, width: 50, field: AXIS_CUT_OFF_FINANCIAL_YEAR_FIELD, align: AXIS_CUT_OFF_FINANCIAL_YEAR_ALIGN },
        //             { colName: AXIS_CUT_OFF_STATUS, width: 50, field: AXIS_CUT_OFF_STATUS_FIELD, align: AXIS_CUT_OFF_STATUS_ALIGN },
        //             { colName: ADMIN_MODULE_ACTION, width: 50, field: ADMIN_MODULE_ACTION_NAME_FIELD, align: ADMIN_MODULE_ACTION_ALIGN },
        //         ]
        //     );


        // Enter Price HelpDesk Ticket
        case 'helpDeskMyTicket':
            return (
                [
                    { colName: TICKET_UPDATE, width: TICKET_UPDATE_WIDTH, align: TICKET_UPDATE_ALIGN },
                    { colName: HELPDESK_TICKET_NO, width: HELPDESK_TICKET_NO_WIDTH, field: HELPDESK_TICKET_NO_FIELD, align: HELPDESK_TICKET_NO_ALIGN },
                    { colName: HELPDESK_SUBJECT, width: HELPDESK_SUBJECT_WIDTH, field: HELPDESK_SUBJECT_FIELD, align: HELPDESK_SUBJECT_ALIGN },
                    { colName: HELPDESK_ASSIGNED_TO, width: HELPDESK_ASSIGNED_TO_WIDTH, align: HELPDESK_ASSIGNED_TO_ALIGN, field: HELPDESK_ASSIGNED_TO_FIELD },
                    { colName: HELPDESK_CREATED_DATE_TIME, width: HELPDESK_CREATED_DATE_TIME_WIDTH, field: HELPDESK_CREATED_DATE_TIME_FIELD, align: HELPDESK_CREATED_DATE_TIME_ALIGN },
                    { colName: HELPDESK_AGING, width: HELPDESK_AGING_WIDTH, field: HELPDESK_AGING_FIELD, align: HELPDESK_AGING_ALIGN },
                    { colName: HELPDESK_STATUS, width: HELPDESK_STATUS_WIDTH, field: HELPDESK_STATUS_FIELD, align: HELPDESK_STATUS_ALIGN },
                    { colName: HELPDESK_PRIORITY, width: HELPDESK_PRIORITY_WIDTH, field: HELPDESK_PRIORITY_FIELD, align: HELPDESK_PRIORITY_ALIGN },
                    { colName: HELPDESK_RESOLVED_DATE_TIME, width: HELPDESK_RESOLVED_DATE_TIME_WIDTH, field: HELPDESK_RESOLVED_DATE_TIME_FIELD, align: HELPDESK_RESOLVED_DATE_TIME_ALIGN },
                    { colName: HELPDESK_FUNCTION, width: HELPDESK_FUNCTION_WIDTH, field: HELPDESK_FUNCTION_FIELD, align: HELPDESK_FUNCTION_ALIGN },
                    { colName: HELPDESK_CATEGORY, width: HELPDESK_CATEGORY_WIDTH, field: HELPDESK_CATEGORY_FIELD, align: HELPDESK_CATEGORY_ALIGN },
                    { colName: HELPDESK_SUB_CATEGORY, width: HELPDESK_SUB_CATEGORY_WIDTH, field: HELPDESK_SUB_CATEGORY_FIELD, align: HELPDESK_SUB_CATEGORY_ALIGN },
                    // { colName: HELPDESK_TYPE, width: HELPDESK_TYPE_WIDTH, field: HELPDESK_TYPE_FIELD, align: HELPDESK_TYPE_ALIGN },
                    // { colName: HELPDESK_ASSIGNED_BY, width: HELPDESK_ASSIGNED_BY_WIDTH, field: HELPDESK_ASSIGNED_BY_FIELD, align: HELPDESK_ASSIGNED_BY_ALIGN },
                    // { colName: HELPDESK_ASSIGNED_DATE_TIME, width: HELPDESK_ASSIGNED_DATE_TIME_WIDTH, field: HELPDESK_ASSIGNED_DATE_TIME_FIELD, align: HELPDESK_ASSIGNED_DATE_TIME_ALIGN },
                    // { colName: HELPDESK_COMMENTS, width: HELPDESK_COMMENTS_WIDTH, field: HELPDESK_COMMENTS_FIELD, align: HELPDESK_COMMENTS_ALIGN },
                    // { colName: HELPDESK_RESOLVED_BY, width: HELPDESK_RESOLVED_BY_WIDTH, field: HELPDESK_RESOLVED_BY_FIELD, align: HELPDESK_RESOLVED_BY_ALIGN },
                ]
            );

        case 'helpeskAdminSubCategory':
            return (
                [{ colName: ADMIN_MODULE_ACTION, width: 100, align: 'Center' },
                { colName: SUB_CAT_NAME, width: SUB_CAT_NAME_WIDTH, field: SUB_CAT_NAME_FIELD, align: 'Left' },
                { colName: CUSTOM_SUB_CAT_NAME, width: CUSTOM_SUB_CAT_NAME_WIDTH, field: CUSTOM_SUB_CAT_NAME_FIELD, align: 'Left' },
                { colName: SEC_NAME, width: SEC_NAME_WIDTH, field: SEC_NAME_FIELD, align: 'Left' },
                { colName: CAT_NAME, width: CAT_NAME_WIDTH, field: CAT_NAME_FIELD, align: 'Left' },
                { colName: SUB_CAT_DISABLED, width: SUB_CAT_DISABLED_WIDTH, field: SUB_CAT_DISABLED_FIELD, align: 'Center' },
                ]
            )


        // case 'frequencyMaster':
        // return (
        //     [
        //         { colName: ADMIN_MODULE_ACTION, width: 200, field: ADMIN_MODULE_ACTION_NAME_FIELD, align: ADMIN_MODULE_ACTION_ALIGN },
        //         { colName: FREQUENCY_MASTER_FINANCIAL_YAER, width: 200, field:FREQUENCY_MASTER_FINANCIAL_YAER_FIELD, align: FREQUENCY_MASTER_FINANCIAL_YAER_ALIGN },
        //         { colName: FREQUENCY_MASTER_FREQUENCY, width:200, field: FREQUENCY_MASTER_FREQUENCY_FIELD, align:FREQUENCY_MASTER_FREQUENCY_ALIGN },
        //         { colName: FREQUENCY_MASTER_INDICATOR, width: 200, field: FREQUENCY_MASTER_INDICATOR_FIELD, align: FREQUENCY_MASTER_INDICATOR_ALIGN },
        //         { colName: FREQUENCY_MASTER_APPLY_PERIOD_FROM, width: 120 , field: FREQUENCY_MASTER_APPLY_PERIOD_FROM_FIELD, align: FREQUENCY_MASTER_APPLY_PERIOD_FROM_ALIGN },
        //         { colName: FREQUENCY_MASTER_APPLY_PERIOD_TO, width: 120, field: FREQUENCY_MASTER_APPLY_PERIOD_TO_FIELD, align: FREQUENCY_MASTER_APPLY_PERIOD_TO_ALIGN },
        //         { colName: FREQUENCY_ADD_EDIT_PERFORM_ST_DATE, width:200, field: FREQUENCY_ADD_EDIT_PERFORM_ST_DATE_FIELD, align: FREQUENCY_ADD_EDIT_PERFORM_ST_DATE_ALIGN },
        //         { colName: FREQUENCY_ADD__PERFORM_END_DATE, width:200 , field: FREQUENCY_ADD__PERFORM_END_DATE_FIELD, align: FREQUENCY_ADD__PERFORM_END_DATE_ALIGN },
        //         { colName: FREQUENCY_EDIT__PERFORM_END_DATE, width:200, field: FREQUENCY_EDIT__PERFORM_END_DATE_FIELD, align: FREQUENCY_EDIT__PERFORM_END_DATE_ALIGN },
        //         { colName: FREQUENCY_ADD_EDIT_POTENTIAL_ST_DATE, width: 216, field: FREQUENCY_ADD_EDIT_POTENTIAL_ST_DATE_FIELD, align: FREQUENCY_ADD_EDIT_POTENTIAL_ST_DATE_ALIGN },
        //         { colName: FREQUENCY_ADD__POTENTIAL_END_DATE, width:200 , field: FREQUENCY_ADD__POTENTIAL_END_DATE_FIELD, align: FREQUENCY_ADD__POTENTIAL_END_DATE_ALIGN },
        //         { colName: FREQUENCY_EDIT__POTENTIAL_END_DATE, width:200, field: FREQUENCY_EDIT__POTENTIAL_END_DATE_FIELD, align: FREQUENCY_EDIT__POTENTIAL_END_DATE_ALIGN },
        //         { colName: FREQUENCY_SELF_SUP_FINAL_RATING_ST_DATE, width:200, field: PFREQUENCY_SELF_SUP_FINAL_RATING_ST_DATE_FIELD, align: FREQUENCY_SELF_SUP_FINAL_RATING_ST_DATE_ALIGN },
        //         { colName: FREQUENCY_SELF_RATING_END_DATE, width:200, field: FREQUENCY_SELF_RATING_END_DATE_FIELD, align: FREQUENCY_SELF_RATING_END_DATE_ALIGN },
        //         { colName: FREQUENCY_SUP_RATING_END_DATE, width:200 , field: FREQUENCY_SUP_RATING_END_DATE_FIELD, align: FREQUENCY_SUP_RATING_END_DATE_ALIGN },
        //         { colName: FREQUENCY_FINAL_RATING_END_DATE, width:200, field: FREQUENCY_FINAL_RATING_END_DATE_FIELD, align: FREQUENCY_FINAL_RATING_END_DATE_ALIGN },

        //         { colName: FREQUENCY_MASTER_ACTIVE, width: 200 , field: FREQUENCY_MASTER_ACTIVE_FIELD, align: FREQUENCY_MASTER_ACTIVE_ALIGN },
        //     ]
        // );
        case 'hrreport':
            return (
                [
                    { colName: HR_TYPE, field: HR_TYPE_FIELD, align: HR_TYPE_ALIGN },
                    { colName: HR_SUB_TYPE, field: HR_SUB_TYPE_FIELD, align: HR_SUB_TYPE_ALIGN },
                    { colName: HR_EMP_COUNT, field: HR_EMP_COUNT_FIELD, align: HR_EMP_COUNT_ALIGN },
                    { colName: HR_EMP_TOTAL, field: HR_EMP_TOTAL_FIELD, align: HR_EMP_TOTAL_ALIGN },
                ]
            );


        case 'leaveBalance':
            return (
                [
                    { colName: ADMIN_MODULE_ACTION, width: '20', field: ADMIN_MODULE_ACTION_NAME_FIELD, align: ADMIN_MODULE_ACTION_ALIGN },
                    { colName: LEAVE_BALANCE_TYPE, width: LEAVE_BALANCE_TYPE_WIDTH, field: LEAVE_BALANCE_TYPE_FIELD, align: LEAVE_BALANCE_TYPE_ALIGN },
                    { colName: LEAVE_BALANCE_FROM_DATE, width: LEAVE_BALANCE_FROM_DATE_WIDTH, field: LEAVE_BALANCE_FROM_DATE_FIELD, align: LEAVE_BALANCE_FROM_DATE_ALIGN },
                    { colName: LEAVE_BALANCE_TO_DATE, width: LEAVE_BALANCE_TO_DATE_WIDTH, field: LEAVE_BALANCE_TO_DATE_FIELD, align: LEAVE_BALANCE_TO_DATE_ALIGN },
                    { colName: LEAVE_BALANCE_NO_OF_DAYS, width: LEAVE_BALANCE_NO_OF_DAYS_WIDTH, field: LEAVE_BALANCE_NO_OF_DAYS_FIELD, align: LEAVE_BALANCE_NO_OF_DAYS_ALIGN },
                    { colName: LEAVE_BALANCE_APPLICATION_STATUS, width: LEAVE_BALANCE_APPLICATION_STATUS_WIDTH, field: LEAVE_BALANCE_APPLICATION_STATUS_FIELD, align: LEAVE_BALANCE_APPLICATION_STATUS_ALIGN },
                ]
            );
        case 'leaveBalanceReport':
            return (
                [
                    { colName: ADMIN_MODULE_ACTION, width: '50', field: ADMIN_MODULE_ACTION_NAME_FIELD, align: ADMIN_MODULE_ACTION_ALIGN },
                    { colName: LEAVE_BALANCE_TYPE, width: LEAVE_BALANCE_TYPE_WIDTH, field: LEAVE_BALANCE_TYPE_FIELD_FOR_REPORT, align: "center" },
                    { colName: LEAVE_APPROVAL_APPLIED_ON, width: LEAVE_APPROVAL_APPLIED_ON_WIDTH - parseInt(`${isThirteenHundred ? 40 : isOneTwentyFiveInSixteenEighty ? 30 : isFourteenHundred ? 40 : isOneTwentyFiveScale ? 20 : 40}`), field: LEAVE_APPROVAL_APPLIED_ON_FIELD, align: LEAVE_APPROVAL_APPLIED_ON_ALIGN },
                    { colName: LEAVE_BALANCE_FROM_DATE, width: LEAVE_BALANCE_FROM_DATE_WIDTH, field: LEAVE_BALANCE_FROM_DATE_FIELD, align: LEAVE_BALANCE_FROM_DATE_ALIGN },
                    { colName: LEAVE_BALANCE_TO_DATE, width: LEAVE_BALANCE_TO_DATE_WIDTH, field: LEAVE_BALANCE_TO_DATE_FIELD, align: LEAVE_BALANCE_TO_DATE_ALIGN },
                    { colName: LEAVE_BALANCE_NO_OF_DAYS, width: LEAVE_BALANCE_NO_OF_DAYS_WIDTH, field: LEAVE_BALANCE_NO_OF_DAYS_FIELD, align: LEAVE_BALANCE_NO_OF_DAYS_ALIGN },
                    { colName: LEAVE_BALANCE_APPROVED_BY, width: LEAVE_BALANCE_APPROVED_BY_WIDTH, field: LEAVE_BALANCE_APPROVED_BY_FIELD, align: LEAVE_BALANCE_APPROVED_BY_ALIGN },
                    { colName: LEAVE_BALANCE_APPROVED_ON, width: LEAVE_BALANCE_APPROVED_ON_WIDTH, field: LEAVE_BALANCE_APPROVED_ON_FIELD, align: LEAVE_BALANCE_APPROVED_ON_ALIGN },
                    { colName: LEAVE_BALANCE_APPLICATION_STATUS, width: LEAVE_BALANCE_APPLICATION_STATUS_WIDTH, field: LEAVE_BALANCE_APPLICATION_STATUS_FIELD, align: LEAVE_BALANCE_APPLICATION_STATUS_ALIGN },
                ]
            );
        case 'lockUnlock':
            return (
                [
                    { colName: ADMIN_MODULE_ACTION, width: '50', field: ADMIN_MODULE_ACTION_NAME_FIELD, align: ADMIN_MODULE_ACTION_ALIGN },
                    { colName: LOCK_UNLOCK_ATTENDANCE_MONTH, width: LOCK_UNLOCK_ATTENDANCE_MONTH_WIDTH, field: LOCK_UNLOCK_ATTENDANCE_MONTH_FIELD, align: LOCK_UNLOCK_ATTENDANCE_MONTH_ALIGN },
                    // { colName: LOCK_UNLOCK_LOCK_START_DATE, width: LOCK_UNLOCK_LOCK_START_DATE_WIDTH, field: LOCK_UNLOCK_LOCK_START_DATE_FIELD, align: LOCK_UNLOCK_LOCK_START_DATE_ALIGN },
                    { colName: LOCK_UNLOCK_LOCK_END_DATE, width: LOCK_UNLOCK_LOCK_END_DATE_WIDTH, field: LOCK_UNLOCK_LOCK_END_DATE_FIELD, align: LOCK_UNLOCK_LOCK_END_DATE_ALIGN },
                    { colName: LOCK_UNLOCK_ACTIVE, width: LOCK_UNLOCK_ACTIVE_WIDTH, field: LOCK_UNLOCK_ACTIVE_FIELD, align: LOCK_UNLOCK_ACTIVE_ALIGN },
                ]
            );
        case 'leaveYearConfig':
            return (
                [
                    { colName: ADMIN_MODULE_ACTION, width: '50', field: ADMIN_MODULE_ACTION_NAME_FIELD, align: ADMIN_MODULE_ACTION_ALIGN },
                    { colName: LEAVE_YEAR_TITLE, width: LEAVE_YEAR_TITLE_WIDTH, field: LEAVE_YEAR_TITLE_FIELD, align: LEAVE_YEAR_TITLE_ALIGN },
                    { colName: LEAVE_YEAR_START_DATE, width: EFFECTIVE_START_DATE_WIDTH, field: EFFECTIVE_START_DATE_FIELD, align: EFFECTIVE_START_DATE_ALIGN },
                    { colName: LEAVE_YEAR_END_DATE, width: EFFECTIVE_END_DATE_WIDTH, field: EFFECTIVE_END_DATE_FIELD, align: EFFECTIVE_END_DATE_ALIGN },
                    { colName: LOCK_UNLOCK_ACTIVE, width: LOCK_UNLOCK_ACTIVE_WIDTH, field: LOCK_UNLOCK_ACTIVE_FIELD, align: LOCK_UNLOCK_ACTIVE_ALIGN },
                ]
            );
        case 'regularizationGrid':
            return (
                [
                    { colName: REGULARIZATION_DATE, width: REGULARIZATION_DATE_WIDTH, field: REGULARIZATION_DATE_FIELD, align: REGULARIZATION_DATE_ALIGN },
                    { colName: REGULARIZATION_ATTENDANCE, width: REGULARIZATION_ATTENDANCE_WIDTH, field: REGULARIZATION_ATTENDANCE_FIELD, align: REGULARIZATION_ATTENDANCE_ALIGN },
                    { colName: REGULARIZATION_TIBR, width: REGULARIZATION_TIBR_WIDTH, field: REGULARIZATION_TIBR_FIELD, align: REGULARIZATION_TIBR_ALIGN },
                    { colName: REGULARIZATION_ATTENDANCE_CHANGED_TO, width: REGULARIZATION_ATTENDANCE_CHANGED_TO_WIDTH, field: REGULARIZATION_ATTENDANCE_CHANGED_TO_FIELD, align: REGULARIZATION_ATTENDANCE_CHANGED_TO_ALIGN },
                ]
            );
        case 'emailMappingMaster':
            return (
                [
                    { colName: ADMIN_MODULE_ACTION, width: '30', field: ADMIN_MODULE_ACTION_NAME_FIELD, align: ADMIN_MODULE_ACTION_ALIGN },
                    { colName: USER_NAME, width: USER_NAME_WIDTH, field: USER_NAME_FIELD, align: USER_NAME_ALIGN },
                    { colName: NOTIFY_USER_NAME, width: NOTIFY_USER_NAME_WIDTH, field: NOTIFY_USER_NAME_FIELD, align: NOTIFY_USER_NAME_ALIGN },
                    { colName: APPLICATION_NAME, width: APPLICATION_NAME_WIDTH, field: APPLICATION_NAME_FIELD, align: APPLICATION_NAME_ALIGN },
                ]
            );
        case 'leaveReportNew':
            return (
                [
                    { colName: FROM_DATE, width: FROM_DATE_WIDTH, field: FROM_DATE_FIELD, align: FROM_DATE_ALIGN },
                    // { colName: TO_DATE, width: TO_DATE_WIDTH, field: TO_DATE_FIELD, align: TO_DATE_ALIGN },
                    { colName: NO_OF_LEAVES, width: NO_OF_LEAVES_WIDTH, field: NO_OF_LEAVES_FIELD, align: NO_OF_LEAVES_ALIGN },
                    { colName: LEAVE_TYPE, width: LEAVE_TYPE_WIDTH, field: LEAVE_TYPE_FIELD, align: LEAVE_TYPE_ALIGN },
                    { colName: HOLIDAY_NAME, width: HOLIDAY_NAME_WIDTH, field: HOLIDAY_NAME_FIELD, align: HOLIDAY_NAME_ALIGN },
                ]
            )

        default:
            return []
    }
}


