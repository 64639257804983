/**
 * Author: Vivek Khodade.
 * Last Modify:08-May-2020
 * Modified By:Vivek Khodade
 */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import Button from "@material-ui/core/Button";
import "./functionsmultiselectionbuttoncomponent.css";

class FunctionsMultiSelectionButtonComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectAll: false,
      roleMaster: null,
    };
  }

  componentDidMount = () => {
    const { tabData } = this.props;
    this.setState({
      roleMaster: tabData,
    });
  };

  selectAllByCategories = (index, bool) => {
    const { roleMaster } = this.state;
    if (!bool) {
      const categoryData = roleMaster;
      const temp = categoryData;
      categoryData[index].data.map(
        // eslint-disable-next-line
        (item, i) => (temp[index].data[i].selected = true)
      );
      temp[index].all = true;
      this.setState({
        roleMaster: temp,
      });
      this.checkSelectAll();
    } else {
      const categoryData = roleMaster;
      const temp = categoryData;
      categoryData[index].data.map(
        // eslint-disable-next-line
        (item, i) => (temp[index].data[i].selected = false)
      );
      temp[index].all = false;
      this.setState({
        roleMaster: temp,
      });
      this.checkSelectAll();
    }
  };

  onSave = async () => { // NOSONAR
    const { roleMaster } = this.state;
    const { candidateid, authUserid, typeid } = this.props;
    const finalData = [];
    if (roleMaster.length > 0) {
      // eslint-disable-next-line
     await roleMaster.map((data, i)=>{
        const selectedcategoriesId = [];
        // eslint-disable-next-line
        data.data.map((item,index)=>{
          if (item.selected) {
            selectedcategoriesId.push(item.id);
          }
        });
        if (selectedcategoriesId.length > 0) {
          finalData.push({
            categoryId: data.categoryId,
            SelectedIds: selectedcategoriesId.toString(),
          });
        }
      });
      if (finalData.length > 0) {
        let candidateIds = "";
        // eslint-disable-next-line
        finalData.forEach((data, i) => {
          if (i > 0) {
            candidateIds = `${candidateIds},${data.SelectedIds}`;
          } else {
            candidateIds += data.SelectedIds;
          }
        });
        const candidateData = {
          func_info: candidateIds,
          candidateid,
          authUserid,
          typeid,
        };
        // eslint-disable-next-line
        this.props.saveCandidatedeteils(candidateData)
      } else {
        const candidateData = {
          func_info: "",
          candidateid,
          authUserid,
          typeid,
        };
        // eslint-disable-next-line
        this.props.saveCandidatedeteils(candidateData)
      }
    }
  };

  checkSelectAll = () => {
    let select = 0;
    const { roleMaster } = this.state;
    // eslint-disable-next-line
    roleMaster.map((item,index)=>{
      if (item.all) {
        // eslint-disable-next-line
        select++;
      }
    });
    if (select === roleMaster.length) {
      this.setState({
        selectAll: true,
      });
    } else {
      this.setState({
        selectAll: false,
      });
    }
  };

  onSelectAll = (bool) => {
    if (!bool) {
      const { roleMaster } = this.state;
      const saveData = roleMaster;
      const temp = saveData;
      // eslint-disable-next-line
        saveData.map((data, i)=>{
        // eslint-disable-next-line
        data.data.map((item,index)=>{
          temp[i].data[index].selected = true;
        });
        temp[i].all = true;
      });
      this.setState({ roleMaster: temp, selectAll: true });
    } else {
      const { roleMaster } = this.state;
      const saveData = roleMaster;
      const temp = saveData;
      // eslint-disable-next-line
      saveData.map((data, i)=>{
        // eslint-disable-next-line
        data.data.map((item,index)=>{
          temp[i].data[index].selected = false;
        });
        temp[i].all = false;
      });
      this.setState({ roleMaster: temp, selectAll: false });
    }
  };

  render() {
    const { roleMaster, selectAll } = this.state;
    const { typeid } = this.props;
    var buttonText = 'INITIATE';
    if (typeid === 4) buttonText = 'REVOKE';
    return (
      <div>
        <div className="col-12 col-xs-12 col-sm-12 col-lg-12 col-md-12 row">
          <div style={{ textAlign: "right", marginTop: 7, marginBottom: 7 }}>
            <Button
              color="primary"
              variant="contained"
              style={{ padding: "0px 0px 0px 0px", margin: "3px 5px 0px 0px" }}
              title="Save"
              onClick={() => this.onSave()}
            >
              <TooltipComponent
                content={buttonText}
                position="BottomCenter"
                isSticky={false}
                cssClass='customtooltip'
              >
                <span style={{ fontSize: 14 }}>
              &nbsp;&nbsp;&nbsp;&nbsp;{buttonText} &nbsp;&nbsp;&nbsp;&nbsp;
              </span>
              </TooltipComponent>
            </Button>
            <Button
              className={
                selectAll
                  ? "select-all-button-active"
                  : "select-all-button-deactive"
              }
              style={{
                padding: "0px 0px 0px 0px",
                margin: "3px 5px 0px 0px",
                border: "1px solid",
              }}
              title="Select All"
              onClick={() => this.onSelectAll(selectAll)}
            >
              <TooltipComponent
                content="Select All"
                position="BottomCenter"
                isSticky={false}
                cssClass='customtooltip'
              >
                <span style={{ fontSize: 14 }}>
                  SELECT ALL
                </span>
                </TooltipComponent>
            </Button>
          </div>
        </div>
        <div className="col-12 col-xs-12 col-sm-12 col-lg-12 col-md-12">
          <div className="scroll-initiate-prejoining force-overflow-prejoining multiselectionbuttoncomponent-width-prejoining row">
            {roleMaster !== null && roleMaster.length > 0 && 
              roleMaster.map((data, i) => (
                <div
                  key={
                    // eslint-disable-next-line
                    i
                  }
                  className="col-3 col-xs-3 col-sm-3 col-lg-3 col-md-3"
                >
                  <div className="button-sections-width">
                    <div
                      className="button-header-width row"
                      style={{ marginLeft: "0px" }}
                    >
                      <div
                        style={{
                          float: "left",
                          textAlign: "center",
                          // padding: 5,
                          paddingTop: 9,
                          paddingRight: 5,
                          paddingBottom: 5,
                          paddingLeft:5
                        }}
                      >
                        {data.categoryName}
                      </div>
                      <div style={{ textAlign: "right", marginRight:"3px" }}>
                        <Button
                          key={
                            // eslint-disable-next-line
                            i
                          }
                          className={
                            data.all
                              ? "all-via-categories-select"
                              : "all-via-categories-unselect"
                          }
                          style={{
                            padding: "0px 0px 0px 0px",
                            margin: "3px 2px 3px 0px",
                            border: "1px solid",
                          }}
                          // title="Select All"
                          onClick={() =>
                            this.selectAllByCategories(i, data.all)
                          }
                        >
                          <TooltipComponent
                            content="Select All by category"
                            position="BottomCenter"
                            isSticky={false}
                            cssClass='customtooltip'
                          >
                            <span style={{ fontSize: 14 }}>
                              ALL
                          </span>
                          </TooltipComponent>
                        </Button>
                      </div>
                    </div>
                    <div style={{ height: 200, overflowY: "auto" }}>
                      {data.data.map((item, index) => (
                        // eslint-disable-next-line
                        <div
                          key={
                            // eslint-disable-next-line
                            item.id
                          }
                          className={
                            item.selected
                              ? "button-selected-width row"
                              : "button-unselected-width row"
                          }
                          onClick={() => {
                            const temp = roleMaster[i].data;
                            temp[index].selected = !temp[index].selected;
                            const totalLen = temp.length;
                            const selectedItemslen = temp.filter(
                              (t) => t.selected
                            );
                            if (totalLen === selectedItemslen.length) {
                              roleMaster[i].all = true;
                            } else {
                              roleMaster[i].all = false;
                            }
                            this.setState({
                              roleMaster,
                            });
                            this.checkSelectAll();
                          }}
                        >
                          <div
                            style={{
                              float: "left",
                              textAlign: "center",
                              padding: 5,
                            }}
                          >
                            {item.buttonText}
                          </div>
                          <div style={{ textAlign: "right" }} />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    );
  }
}
FunctionsMultiSelectionButtonComponent.propTypes = {
  tabData: PropTypes.arrayOf(Object).isRequired,
  candidateid: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  typeid: PropTypes.number.isRequired,
  authUserid: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};
FunctionsMultiSelectionButtonComponent.defaultProps = {};
export default FunctionsMultiSelectionButtonComponent;
