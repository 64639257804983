import './default.css';
import * as React from 'react';
import { MainClass } from '../../../mainclass';
import { connect } from 'react-redux';
import PreOffer from '../../containers/tmlc/preoffer/preoffer';
import { saveBasicInformationInRedux } from '../../../redux/actions/entryexit/onboardingaction'
import { updateCandidateData } from '../../../redux/actions/entryexit/candidateaction'
import { dencrypt } from '../../other/commonfunction';
import { getOnboardingMasterData, getTMLCUsers, } from '../../other/commonlayoutfunction.js';
import { setMeetingMaster } from '../../../redux/actions/meetingmasteraction';
class PreOfferLayout extends MainClass {
    constructor() {
        super(...arguments);
        this.state = { preofferLoad: false }
    } 

    async componentDidMount() {
        try {
            const { authUser, bandMaster, othersCheckAvailablity } = this.props;
            let search = window.location.search;
            let params = new URLSearchParams(search);
            if (typeof params.get('candid') !== 'undefined') {
                const candId = dencrypt(params.get('candid'))
                if (candId > 0) {
                    this.props.saveBasicInformationInRedux({ candidateid: candId });
                }
            } else {
                this.props.saveBasicInformationInRedux({
                    candidateid: 0, salutation: '', firstName: '', middleName: '', lastName: '', gender: '', mobileNumber: '', alternetNumber: '', emailId: '',
                    panNumber: '', adharNumber: '', uanNumber: '', highestEducationQualification: '', highestEducationQualificationSpecial: '', workExperiance: 0,
                    title: '', role: '', band: '', empType: '', baseCounty: '', baseLocation: '', entity: '', reportingSupervisor: '', costSharing: 0, address: '',
                    send_links: [], imageName: '', servceDivisionSalesUnit: '', status: 'Refresh', dateOfBirth: null, readyToJoinDate: null, roleName: '', address2: ''
                    , city: '', pincode: '', state: '', other: "", persCountryCode: '', altCountryCode: '', recruitmentManagerId: 0, functionId: "", legalName: "",
                    tenureEndDate: null, currencyId: null, ctcFrequency: null,docLinkForExternal:"Y", subFunction: null
                });
            }
            if (othersCheckAvailablity.length === 0) {
                const othersCheckAvailablityData = await getTMLCUsers(authUser)
                this.props.setMeetingMaster(othersCheckAvailablityData);
            }
            if (bandMaster.length === 0) {
                const onboardingMasterData = await getOnboardingMasterData(authUser, 'All');
                this.props.updateCandidateData(onboardingMasterData);
            }
            setTimeout(() => {
                this.setState({ preofferLoad: true })
            }, 100)
        } catch (error) {
            console.log("error", error);
        }
    }

    componentWillUnmount = () => {
        this.props.saveBasicInformationInRedux({
            candidateid: 0, salutation: '', firstName: '', middleName: '', lastName: '', gender: '', mobileNumber: '', alternetNumber: '', emailId: '',
            panNumber: '', adharNumber: '', uanNumber: '', highestEducationQualification: '', highestEducationQualificationSpecial: '', workExperiance: 0,
            title: '', role: '', band: '', empType: '', baseCounty: '', baseLocation: '', entity: '', reportingSupervisor: '', costSharing: 0, address: '',
            send_links: [], imageName: '', servceDivisionSalesUnit: '', status: 'Refresh', dateOfBirth: null, readyToJoinDate: null, roleName: '', address2: '',
            city: '', pincode: '', state: '', other: "", persCountryCode: '', altCountryCode: '', functionId: "", legalName: "", tenureEndDate: null, currencyId: null,
            ctcFrequency: null,docLinkForExternal:"Y"
        });
    }

    render() {
        const { preofferLoad } = this.state
        return (

            <div style={{ height: '100%', width: '100%' }}>
                {preofferLoad && <PreOffer />}
            </div>
        );
    }
}

const mapStateToProps = ({ meetingcategory, candidateReducer, auth, meetingmaster }) => {
    const { authUser } = auth;
    const { categoryName } = meetingcategory;
    const { bandMaster } = candidateReducer
    const { othersCheckAvailablity } = meetingmaster;
    return { categoryName, bandMaster, authUser, othersCheckAvailablity }
};
export default connect(mapStateToProps, { saveBasicInformationInRedux, updateCandidateData, setMeetingMaster })(PreOfferLayout);

