import React, { Component } from 'react';

class SubActionTemplate extends Component{
    constructor(props) {
    super(props);
    this.state = {
    };
    this.makeActive = this.makeActive.bind(this);
    this.openPopup = this.openPopup.bind(this);
    this.isActive = this.isActive.bind(this);
    this.practiceAreaTemp = this.practiceAreaTemp.bind(this);
  }
  openPopup=(id,item)=>{
     this.props.openPopup( id, item)
  }
   makeActive=(id,data,activeFlag)=>{
    this.props.makeActiveInActive(id,data,activeFlag)
  }
  isActive = (item) => {
   
    if (item.isActive !== "1") {
      return (
        
          <button id='active'

            onClick={() =>
              this.makeActive("Status", item, "1")
            }
          >
            Active
          </button>

        
      )
    } else {
      return (
        <>
          <button id='edit'

            onClick={() =>
              this.openPopup('Edit', item)
            }
          >
            Edit
          </button>
          <button id="inactive"

            onClick={() =>
              this.makeActive("Status", item, "0")
            }
          >
            Inactive
          </button>
        </>
      )
    }
  }

  subBusinessTemp=(item)=>{
      
    return (<div style={{display:"flex",justifyContent:"center"}}>
        {(this.props.category==="SUB_BUSINESS" && item.addd === "+") ? (
                      
            <button id="add"

                onClick={() =>
                this.openPopup('Add', item)
                }
            >
                Add
            </button>
            
        ) : this.isActive(item)}
        </div>)

  }

  practiceAreaTemp=(item)=>{
   
    return (<div style={{display:"flex",justifyContent:"center"}}>
        {item.business === "" &&
            (item.subbusiness === "" ? (
            this.isActive(item)
            ) : (
            
                <button
                id="add"
                onClick={() =>
                    this.openPopup(
                    "Add",
                    item
                    )
                }
                >
                Add
                </button>
            
            ))
        }
        </div>)
  }


    render(){
        const {props}=this.props
        const item=props
      
    return(<>
    {this.props.category==="SUB_BUSINESS" ? this.subBusinessTemp(item) :  this.practiceAreaTemp(item)}
            </>)
    }
        
}

export default SubActionTemplate