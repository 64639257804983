import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { ComboBoxComponent } from "@syncfusion/ej2-react-dropdowns";
import { updateLnAData } from '../../../../redux/actions/lna/lnaaction';
import LeaveDetails from '../leavesapply/leavedetails';
import leaveandattendance from '../../../../assets/images/leaveandattendance.png';
import LnAService from '../../../../services/lna/lnaservice';
import { getLnAData, getMonthlyReportDropdownData } from '../../../../app/other/commonlayoutfunction';
import ProgressBar from "../../../../components/progressbar/progressbar";
import LeaveRules from '../../leaverules';
import { formatDateToYYYYMMDD, generateExcelFile, getBencharkDisplayDateOnly } from '../../../../app/other/commonfunction';
import LeaveTypes from './leaveTypes';
import { Query } from '@syncfusion/ej2-data';
import UnutilizedLeavesDetail from './unutilizedLeavesDetail';
import { ONLEAVE } from '../../../../redux/constant/meetingcategoryconstant';
import { encrypt } from '../../../../app/other/commonfunction';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBarsFilter } from '@fortawesome/pro-light-svg-icons';
import { DOWNLOAD_ALL, LEAVE_RULES, RESET_FILTERS, VIEW_TO_DISPLAY } from '../../../../../src/assets/constants/leaveConstants';
import LeaveHistory from './leavehistory'


const onleavecss = `
        
.onleave{
  display: flex;
  float: right;
  align-items: center;
  padding-right: 40px;
  color: #F94F5E;
  font-family: 'Circular TT Book', Arial, sans-serif;
  font-weight: Bold;
  font-size: 14px;
 
}

.leave-rules-btn {
  border: 1px solid #fff !important;
  color: #fff !important;
  background-color: #4B4B55;
  border-radius: 4px;
  padding: 0.4rem 0.8rem;
  cursor: pointer;
  font-weight: 400;
  font-size: 15px;
  font-family: 'Circular TT Book';
  margin-top: 10px;
  margin-bottom: 10px;
}

.gt{
  padding-left: 6px;
  color: #F94F5E;
  font-size: 24px;
}
.widthStyle{
  width:31%
}
#leaveoption{
  padding:0 12px 0 !important
}
@media screen and (max-width: 1366px) and (max-height: 768px), screen and (max-width: 1440px) and (max-height: 900px){
  .onleave{
    font-size: 10px;
    padding-right: 20px;
    padding-left:2px;
  }

  .gt{
    padding-left: 3px;
    font-size: 16px;
  }
  .widthStyle{
    width:27%
  }
  .e-outline.e-input-group, .e-outline.e-input-group.e-control-wrapper, .e-outline.e-float-input, .e-outline.e-float-input.e-control-wrapper {
    font-size: 11px;
}
}
.e-input-group .e-control-wrapper .e-outline .numberInputFont .fyHeaderDropdown .e-ddl .e-valid-input{
height:33px;
}


@media screen and (max-width: 1920px) and (max-height: 1080px) and (min-resolution: 96dpi){
  .widthStyle{
    width:34%
  }
}
  @media screen and (max-width: 1920px) and (max-height: 1080px) and (min-resolution: 120dpi){
    .widthStyle{
      width:39%
    }
  }
    

    @media screen and (max-width: 1366px) and (max-height:768px) and (min-resolution: 96dpi){
      .widthStyle{
        width:38%
      }
    } 
    @media screen and (max-width: 1440px) and (max-height: 900px) and (min-resolution: 96dpi){
      .widthStyle{
        width:38%
      }
    }
    @media screen and (max-width: 1440px) and (max-height: 900px) and (min-resolution: 120dpi){
      .widthStyle{
        width:36%
      }
    }
    @media screen and (max-width: 1920px) and (max-height: 1080px) and (min-resolution: 144dpi){
      .widthStyle{
        width:35%
      }
    }
`



function LeaveBalanceGrid(props) {
  const { authUser, fyId, getParentMasterData, leaveApplyLoadName, parentState, showdownloadLeaveBalanceButton, showdownloadLeaveBalanceButtonAllTM, getFullUserInfo, fyData, currentAndFinancialYear, showMore } = props;
  const { teamId: newTeamId } = parentState;
  const [height, setHeight] = useState({
    containerHeight: null,
    gridHeight: null
  });
  const [leaveBalanceData, setLeaveBalanceData] = useState([]);
  const [leaveData, setLeaveData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [teamMemberDatas, setTeamMemberData] = useState([]);
  const [carryForwardData, setCarryForwardData] = useState([]);
  const [selectLeaveOption, setSelectLeaveOption] = useState(1);
  const [teamId, setTeamId] = useState(newTeamId);
  // const [showMore, setShowMore] = useState(false);
  const [disbleViewButton, setDisableViewButton] = useState(false);
  const [clickedFrom, setClickedFrom] = useState(false);

  const showCompleteLeaveCategory = false

  const isFourteenHundred = window.innerHeight < 782 && window.innerWidth < 1441;
  const isThirteenHundred = window.innerHeight < 650;
  const updateHeight =
    (window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight);

  const leaveTypeOption = [
    { Name: "Leave", Id: 1 },
    { Name: "Special Attendance", Id: 2 }
  ]

  const history = useHistory();

  const navigateToOnLeave = () => {
    const encryptedViewOnLeave = encrypt(ONLEAVE);
    const encryptedAppId = encrypt(1);

    history.push(`/lna?id=${encryptedViewOnLeave}&appId=${encryptedAppId}`);
  };


  useEffect(() => {
    getParentMasterData("lnaReport")
    updateDimensions();
    getYearData();
    getDropdownData();
    if (!fyId) {
      getYearData(authUser).then(result => {
        props.updateLnAData({ fyId: result })
      }).catch(err => {
      });
    }


    // getMasterData(authUser, fyData);
    props.updateLnAData({
      leaveApplyLoadName: 'leave-tab',
      selectedTeamMemberId: newTeamId,
    })


  }, []);

  useEffect(() => {
    updateDimensions();
  }, [showCompleteLeaveCategory]);

  useEffect(() => {
    handleViewClick();
  }, [fyId]);
  useEffect(() => {
    getQueryString()
  }, []);

  const getQueryString = () => {
    const queryParams = new URLSearchParams(window.location.search);
    setClickedFrom(queryParams.get('onLeave'))
  }
  const updateDimensions = () => {
    let minusHeight;
    if (!showCompleteLeaveCategory) {
      minusHeight = isThirteenHundred || isFourteenHundred ? 395 : 390
    } else {
      minusHeight = isThirteenHundred || isFourteenHundred ? 470 : 505
    }
    setHeight({
      containerHeight: updateHeight,
      gridHeight: updateHeight - minusHeight
    });
  }

  const getDropdownData = async () => {
    setTeamMemberData([]);
    setIsLoading(true)
    const LNAReportDropdownMaster = await getMonthlyReportDropdownData(authUser);
    if (LNAReportDropdownMaster) {
      const { teamMemberData } = LNAReportDropdownMaster;
      setTeamMemberData(teamMemberData);
      setIsLoading(false)
    }
  }

  const filterLeaveData = (result) => {
    return result.map(leaveFilterData => {
      if (leaveFilterData.fromDate) {
        leaveFilterData.fromDate = new Date(leaveFilterData.fromDate)
      }
      if (leaveFilterData.toDate) {
        leaveFilterData.toDate = new Date(leaveFilterData.toDate)
      }
      if (leaveFilterData.approvedOn) {
        leaveFilterData.approvedOn = new Date(leaveFilterData.approvedOn)
      }
      if (leaveFilterData.appliedOn) {
        leaveFilterData.appliedOn = new Date(leaveFilterData.appliedOn)
      }
      return leaveFilterData
    })
  }
  const getMasterData = async (user, fyIds, leaveOption = 1, refreshCarryForward = true) => {
    setIsLoading(true);
    if (fyIds) {
      const onLeaveBalanceData = await LnAService.getLeaveBalanceData(user, 'LEAVEHISTORY', fyIds, 0, leaveOption);
      const leaveBalanceDataList = onLeaveBalanceData.Result
      if (leaveBalanceDataList && leaveBalanceDataList.length > 0) {
        setLeaveBalanceData(filterLeaveData(leaveBalanceDataList))
      } else {
        setLeaveBalanceData([])
      }
      if (refreshCarryForward) {
        setCarryForwardData([])
        getCarryForwardInfo(user, fyIds)
      }
      else {
        setTimeout(() => {
          setIsLoading(false)
        }, 100)
      }
    }
  }

  const getCarryForwardInfo = async (user, fyIds) => {
    if (fyIds) {
      const carryForwardDataRes = await LnAService.getLeaveBalanceData(user ? user : authUser, 'GETCARRYFORWARDLEAVEINFO', fyIds ? fyIds : fyId, 0, 1)
      const carryForwardDataList = carryForwardDataRes.Result
      if (carryForwardDataList && carryForwardDataList.length > 0) {
        setCarryForwardData(carryForwardDataList)
      } else {
        setCarryForwardData([])
      }
      setTimeout(() => {
        setIsLoading(false)
      }, 100)
    }
  }

  const getLeaveMasterData = async (user, fyIds, type = "GETLEAVEMASTER") => {
    setIsLoading(true);
    if (fyId) {
      const onLeaveBalanceData = await LnAService.getLeaveBalanceData(user || user === 0 ? user : authUser, type, fyData ? fyData : fyId, 0, 1)
      const leaveBalanceDataList = onLeaveBalanceData.Result
      if (leaveBalanceDataList && leaveBalanceDataList.length > 0) {
        if (type === 'GETALLLEAVEMASTER') {
          downloadAllTeamMemberLeaveData(leaveBalanceDataList)
        } else {
          setLeaveData(leaveBalanceDataList);
        }
        setTimeout(() => {
          setIsLoading(false)
        }, 100)
      }
    }
  }


  const dataSourceChanged = (pComponentId) => {
    try {
      setTimeout(() => {
        if (document.getElementById(pComponentId) !== null) { document.getElementById(pComponentId).ej2_instances[0].refresh(); }
      });
    } catch (error) { console.log(error); }
  };

  const onChangeApplyLeave = async (e, type) => { // NOSONAR 
    if (type === 'financialYear') {
      if (e.itemData !== null) {
        props.updateLnAData({ fyData: e.itemData.Id, updateFinancialYear: "updated" })
      }
      else {
        props.updateLnAData({ fyData: null, updateFinancialYear: "updated" })
      }
    }
    if (type === 'teamMemberSingleSelect') {
      if (type === 'teamMemberSingleSelect' && e.itemData) {
        const newTmId = e.itemData.Id;
        props.updateLnAData({
          viewClicked: false,
          selectedTeamMemberId: newTmId,
        });
        setTeamId(newTmId);
        await getYearData(newTmId);
      }
    }
  };

  const onChangeLeaveOption = async (e) => {
    setLeaveBalanceData([])
    setSelectLeaveOption(e.itemData.Id)
    getMasterData(teamId ? teamId : authUser, fyData, e.itemData.Id, false)
  }


  const handleViewClick = async () => {
    if (disbleViewButton) {
      return;
    }
    setDisableViewButton(true);
    const { selectedTeamMemberId } = props;
    setLeaveData([]);
    setLeaveBalanceData([])
    setSelectLeaveOption(1);
    try {
      await getMasterData(selectedTeamMemberId ? selectedTeamMemberId : authUser, fyData);
      await getLeaveMasterData(selectedTeamMemberId ? selectedTeamMemberId : authUser, fyData);
    } catch (err) {
      console.log('err in handle view click', err);
    } finally {
      setDisableViewButton(false);
    }
  }


  /**
  *@author: Somesh Dwivedi  
  *@description : getting data to of leave balance to convert it into excell
  */
  const getLeaveBalanceData = () => {
    const columns = {
      leaveSymbol: 'Leave Symbol',
      leaveName: 'Leave Type',
      openingBalance: 'Eligible',
      consumed: 'Utilized/Approved',
      applied: 'Applied',
      availableBalance: 'Available Balance',
      empCode: 'Team Member Id',
      empName: 'Team Member Name'
    };
    const fullUserInfo = getFullUserInfo(teamId ? +teamId : +authUser);
    return leaveData.map((ele) => {
      return {
        [columns.empCode]: fullUserInfo?.EmpCode,
        [columns.empName]: fullUserInfo?.name,
        [columns.leaveSymbol]: ele.leaveSymbol,
        [columns.leaveName]: ele.leaveName,
        [columns.openingBalance]: ele.openingBalance,
        [columns.consumed]: ele.consumed,
        [columns.applied]: ele.applied,
        [columns.availableBalance]: ele.availableBalance,
      }
    })
  }



  /**
  *@author: Somesh Dwivedi  
  *@description : getting data of leave balance history to convert it into excell
  */
  const getLeaveBalanceHistory = () => {
    const columns = {
      leaveSymbol: 'Leave Symbol',
      leaveName: 'Leave Type',
      appliedDate: 'Applied Date',
      fromDate: 'From Date',
      toDate: 'To Date',
      noOfLeaves: 'No. of Leaves',
      status: 'Status',
      approvedBy: 'Actioned By',
      approvedOn: 'Actioned On',
      empCode: 'Team Member Id',
      empName: 'Team Member Name'
    };
    const fullUserInfo = getFullUserInfo(teamId ? +teamId : +authUser);
    return leaveBalanceData.map((ele) => {
      const parts = ele.leaveSymbolfull.split('-');
      const leaveName = parts[1].trim();
      return {
        [columns.empCode]: fullUserInfo?.EmpCode,
        [columns.empName]: fullUserInfo?.name,
        [columns.leaveSymbol]: ele.leaveSymbol,
        [columns.leaveName]: leaveName,
        [columns.appliedDate]: ele.appliedOn,
        [columns.fromDate]: getBencharkDisplayDateOnly(ele.fromDate),
        [columns.toDate]: getBencharkDisplayDateOnly(ele.toDate),
        [columns.noOfLeaves]: ele.noOfLeaves,
        [columns.status]: ele.applicationStatus,
        [columns.approvedBy]: ele.approvedBy,
        [columns.approvedOn]: ele.approvedOn
      }
    });

  }

  /**
  *@author: Abdulla Shaikh  
  *@description : getting data of leave balance history to convert it into excell for all Team members
  */
  const getLeaveBalanceForAllData = (leaveDataForALL) => {
    const columns = {
      empCode: 'Team Member Id',
      empName: 'Team Member Name',
      leaveSymbol: 'Leave Symbol',
      leaveName: 'Leave Type',
      openingBalance: 'Eligible',
      consumed: 'Utilized/Approved',
      applied: 'Applied',
      availableBalance: 'Available Balance',
    };
    return leaveDataForALL.map((ele) => {
      return {
        [columns.empCode]: ele?.empCode,
        [columns.empName]: ele?.empName,
        [columns.leaveName]: ele.leaveName,
        [columns.leaveSymbol]: ele.leaveSymbol,
        [columns.openingBalance]: ele.openingBalance,
        [columns.consumed]: ele.consumed,
        [columns.applied]: ele.applied,
        [columns.availableBalance]: ele.availableBalance,
      }
    })
  }




  /**
  *@author: Somesh Dwivedi  
  *@description : For merging the data of leave balance and leave balance history and calling the generateExcell file function.
  */
  const downloadLeaveData = () => {
    const leaveBalance = getLeaveBalanceData();
    const leaveBalanceHistory = getLeaveBalanceHistory();
    let currentDate = new Date();
    const formattedDate = formatDateToYYYYMMDD(currentDate)
    const fileName = (`${formattedDate}_Leave Balance`);
    const combinedData = [leaveBalance, leaveBalanceHistory];  //Combining the data 
    const sheetName = ['Leave Balance', 'Leave History']
    generateExcelFile(combinedData, fileName, sheetName, true);
  }


  /**
*@author: Abdulla Shaikh  
*@description : For merging the data of leave balance and leave balance history and calling the generateExcell file function for all team member.
*/
  const downloadAllTeamMemberLeaveData = (teamMemberLeaveData) => {
    const leaveBalanceAll = getLeaveBalanceForAllData(teamMemberLeaveData)
    let currentDate = new Date();
    const formattedDate = formatDateToYYYYMMDD(currentDate)
    const fileName = (`${formattedDate}_Leave Balance`);
    const combinedData = [leaveBalanceAll];  //Combining the data 
    const sheetName = ['Leave Balance']
    generateExcelFile(combinedData, fileName, sheetName, true);

  }

  const onFiltering = (args) => {
    let query = new Query();
    query = (args.text !== "") ? query.where("Name", "startswith", args.text, true) : query;
    args.updateData(teamMemberDatas, query);
  }

  const getYearData = async (newId) => {
    let lnaData = await getLnAData(newId ? newId : authUser, 'FY');
    if (lnaData && lnaData.financialYearData.length > 0) {
      const currYear = lnaData.financialYearData.find(ele => { return ele.CurrYear === 1 || ele.CurrYear === true })
      const obj = lnaData.financialYearData;
      try {
        if (obj.length > 0) {
          props.updateLnAData({ fyData: currYear.Id, currentAndFinancialYear: obj })
          return currYear.Id
        } else {
          props.updateLnAData({ fyData: fyId })
          return fyId
        }
      } catch (error) {
        console.log("error", error);
      }
    }
  }

  const clearFilter = () => {
    if (document.getElementById('leavebalRpt')) {
      document.getElementById('leavebalRpt').ej2_instances[0].clearFiltering();
      dataSourceChanged('leavebalRpt')
    }

  }

  //To close the dropdown when user hover on leave rule  
  function closeLeaveOptionsDropdown() {
    const comboBoxInput = document.getElementById('leaveoption');
    // Assuming 'leaveoption' is the ID of the ComboBoxComponent    
    if (comboBoxInput) {
      comboBoxInput.blur(); // Remove focus from the input element, close the dropdown    
    }
  }

  return (
    <div className="container scrollcontainer scroll-bar" style={{ width: "100%", height: height.containerHeight, backgroundColor: "#F3F3F6" }}>
      <style>{onleavecss}</style>
      {teamMemberDatas.length > 0 &&
        <div className="col-12 col-xs-12 col-sm-12 col-lg-12 col-md-12 mt-md  " style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
          <div className="leave-balance-grid-team-report" style={{ width: '30%' }}>
            <ComboBoxComponent
              id="teamMemberAllTeamReport"
              fields={{ text: "Name", value: "Id" }}
              cssClass="e-outline numberInputFont"
              data-msg-containerid="teamMemberAllTeamReportErr"
              name="teamMemberAllTeamReportErr"
              floatLabelType="Auto"
              placeholder="Team Member"
              htmlAttributes={{ maxlength: 100 }}
              // select={e => props.inputChange(e, "teamMemberSingleSelect")}
              change={(e) => onChangeApplyLeave(e, 'teamMemberSingleSelect')}
              value={teamId ? teamId : authUser}
              dataSource={teamMemberDatas}
              filtering={onFiltering}
              allowFiltering={true}
            />
          </div>
          <div className="leave-balance-grid-team-report pl-md pr-md" style={{ paddingLeft: 0, width: '25%' }}>
            <ComboBoxComponent
              id="financialYear"
              fields={{ text: "Name", value: "Id" }}
              cssClass="e-outline"
              data-msg-containerid="financialYearErr"
              name="financialYearErr"
              floatLabelType="Auto"
              placeholder="Select Year *"
              change={(e) => onChangeApplyLeave(e, 'financialYear')}
              value={fyData}
              dataSource={currentAndFinancialYear}
            />
            {/* <div
            id="financialYearErr"
            className="e-input-group e-float-icon-left error-div"
          /> */}
          </div>
          <div className="pr-md" style={{ display: "flex", alignItems: "center" }}>
            <div
              className='leave-balance-grid-team-report-button'
              style={{ cursor: disbleViewButton ? 'auto' : "pointer", color: disbleViewButton ? '#b7b7bb' : '', backgroundColor: disbleViewButton ? '#DBDBDD' : '', border: disbleViewButton ? '1px solid #DBDBDD' : '' }}
              onClick={handleViewClick}>
              View
            </div>
          </div>
          <div className='flex-wrap widthStyle' >
            {carryForwardData.length > 0 && <UnutilizedLeavesDetail key={`carryLeaves${teamId}-${fyData}`} carryForwardData={carryForwardData} />}
          </div>
        </div>
      }

      <div className='pt-md' style={{
        display: 'flex',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}>
        <LeaveTypes leaveData={leaveData} key={`leaveType${teamId}-${fyData}`} />
      </div>

      <div className="col-12 col-xs-12 col-sm-12 col-lg-12 col-md-12 mt-md">
        <div className="allTeamReportHeader">
          <div className="pl-md" style={{ fontSize: "1rem", color: "#fff", fontWeight: 500, fontFamily: 'Circular TT Book' }}>{selectLeaveOption === 2 ? "Special Attendance History" : "Leave History"}</div>
          <div className="mr-sm" style={{ width: "13rem", display: "block", flexDirection: "row", marginLeft: "auto" }}>

            <ComboBoxComponent
              id="leaveoption"
              fields={{ text: "Name", value: "Id" }}
              cssClass="e-outline numberInputFont fyHeaderDropdown"
              data-msg-containerid="leaveoptionErr"
              name="leaveoptionErr"
              placeholder="Leave Option *"
              select={(e) => onChangeLeaveOption(e)}
              value={selectLeaveOption}
              dataSource={leaveTypeOption}
              height={'35px'}
            />
          </div>
          <div className="leave-balance-header-button">
            {showdownloadLeaveBalanceButtonAllTM === 'Y' &&
              <div className="leaveBalanceYearlyReport"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
                onClick={() => getLeaveMasterData(0, fyId, 'GETALLLEAVEMASTER')}>
                <span className='spanleaveBalanceYearlyReport'>
                  {DOWNLOAD_ALL}
                </span>
              </div>
            }
            {
              showdownloadLeaveBalanceButton === 'Y' &&
              <div className="leaveBalanceYearlyReport"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
                onClick={downloadLeaveData}>
                <span className='spanleaveBalanceYearlyReport'>
                  Download
                </span>
              </div>
            }
            <div className="leaveBalanceYearlyReport approval-list-element approval-list-filter toottiptableleaveclass mr-sm" style={{ cursor: "pointer", fontFamily: 'Arial, Regular' }} id='toottiptable' onMouseOver={closeLeaveOptionsDropdown} >
              <div className='leave-grid-header-filter-button' style={{ font: "normal normal normal 15px Circular TT Book" }}>{LEAVE_RULES}</div>
              <span className="tooltiptext"><LeaveRules /></span>
            </div>


            <div className="leaveBalanceYearlyReport approval-list-element approval-list-filter" style={{ cursor: "pointer", fontFamily: 'Arial, Regular' }} id='leaveReportNewFilter' onClick={clearFilter}>
              <div className="approval-list-filter-icon">
                <FontAwesomeIcon
                  icon={faBarsFilter}
                  className='leave-grid-header-icon'
                />
              </div>
              <div className='leave-grid-header-filter-button' style={{ font: "normal normal normal 15px Circular TT Book" }}>{RESET_FILTERS}</div>
            </div>


          </div >

        </div >

        <div className="leaveBalanceReportGrid" id='leaveHistoryLeaveBalanceGrid'>

          {leaveBalanceData.length > 0 ?
            <LeaveHistory leaveBalanceData={leaveBalanceData} showCompleteLeaveCategory={showCompleteLeaveCategory} showMore={showMore}
              updateLnAData={props.updateLnAData} clickedFromOnLeave={clickedFrom}
            />
            :
            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12" style={{ borderRadius: "5px" }}>
              <center style={{ marginTop: window.innerHeight < 700 ? '7%' : '10%' }}>
                <img src={leaveandattendance} alt="Attendance Not Found" />

                <div className=' pt-xs' style={{
                  textAlign: 'center',
                  font: 'normal normal normal 18px/21px Circular TT Book',
                  letterSpacing: 0,
                  color: '#939399',
                  opacity: 1
                }}>
                  {VIEW_TO_DISPLAY}
                </div>
              </center>
            </div>

          }
          {(leaveBalanceData.length > 0 && clickedFrom) &&
            <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 pb-sm pt-sm pl-md' style={{ background: '#FFFFFF 0% 0% no-repeat padding-box', boxShadow: '-1px 0px 3px #00000029', border: '1px solid #DBDBDB' }}>
              <div className="pr-sm" style={{ float: "left" }}>
                <button
                  className="leave-cancel-apply-button"
                  onClick={() => {
                    navigateToOnLeave()
                  }}
                >
                  Back
                </button>
              </div>
            </div>
          }

          {isLoading && <ProgressBar />}
        </div>
      </div >

      <div id={`leave-details-form`} className="col-xs-4 col-sm-4 col-lg-4 col-md-4 m-md" style={{
        position: 'absolute',
        right: 0,
        bottom: "4.3rem",
        backgroundColor: "#fff",
        padding: 0,
        width: "35rem",
        border: (leaveApplyLoadName === 'leave-view') || leaveApplyLoadName === 'leave-cancel' ? "1px solid #DBDBDD" : null,
        boxShadow: (leaveApplyLoadName === 'leave-view') || leaveApplyLoadName === 'leave-cancel' ? "0px 2px 2px #DBDBDD" : null,
      }}>
        {
          (leaveApplyLoadName === 'leave-view') &&
          <>
            {<LeaveDetails type={"view-application"} leaveBalanceType={"leaveBalanceType"} leaveBalanceIsSpecialAttendance={selectLeaveOption === 2 ? true : false} />}
          </>
        }
        {
          leaveApplyLoadName === 'leave-cancel' &&
          <>
            {<LeaveDetails type={"cancel-application"} leaveBalanceType={"leaveBalanceType"} />}
          </>
        }
      </div>
    </div >
  )
}

const mapStateToProps = ({ auth, lnareducer }) => {
  const { authUser } = auth;

  const { fyId, financialYearData, leaveApplyLoadName, fyData, currentAndFinancialYear, showMore, selectedTeamMemberId } = lnareducer;

  return {
    authUser,
    fyId,
    financialYearData,
    leaveApplyLoadName,
    fyData,
    currentAndFinancialYear,
    showMore,
    selectedTeamMemberId
  }
}

export default connect(mapStateToProps, { updateLnAData })(LeaveBalanceGrid)
