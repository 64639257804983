import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { CalendarComponent } from "@syncfusion/ej2-react-calendars";
import DateFnsUtils from "@date-io/date-fns";
import LnAServices from "../../../../services/lna/lnaservice";
import ProgressBar from '../../../progressbar/progressbar'



export const Calender = (props) => {
  let { userId, authUser, applyFor} = props;

  const dateFns = new DateFnsUtils();

  const [dateData, setDateData] = useState([]);
  const [monthYear, setMonthYear] = useState(dateFns.format(new Date(), "yyyy-MM"));

  const [loading, setLoading] = useState(true);
  const [state, setState] = useState(
    {
      height: null,
      calenderHeight: null,
      defaultDayWidth: "34px"
    })

  const isSixteenEighty = window.innerHeight < 930;
  const isSixteenHundred = window.innerHeight < 782;
  const isOneTwentyFiveScale = window.innerHeight < 747;
  const isOneTwentyFiveInSixteenEighty = window.innerHeight < 723;
  const isThirteenHundred = window.innerHeight <= 657;
  const isOneTwentyFiveInSixteenHundred = window.innerHeight < 603;
  const isOneTwentyFiveInFourteenHundred = (window.innerHeight < 603 && window.innerWidth < 1153);
  const isOneTwentyFiveInThirteenHundred = window.innerHeight < 497;
  const is1920x1080 = window.innerHeight === 923 || window.innerHeight === 955 || window.innerHeight === 911;
  const is1280x720 = window.innerHeight === 563 || window.innerHeight === 595 || window.innerHeight === 551 || window.innerHeight===551 || window.innerHeight===593 || window.innerHeight===559 || window.innerHeight===606;
  const is1366x768 = window.innerHeight === 599;
  const is1024x768 = window.innerWidth === 1024 && window.innerHeight === 599;

  const updateDimensions = () => { //NOSONAR
    const minupdateheight = isOneTwentyFiveInThirteenHundred ? 329 : isOneTwentyFiveInSixteenHundred ? 240 :
      isOneTwentyFiveInFourteenHundred ? 240 : isThirteenHundred ? 230 :
        isOneTwentyFiveInSixteenEighty ? 240 : isOneTwentyFiveScale ? 304 : isSixteenHundred ? 240 :
          isSixteenEighty ? 289 : 480
    const updateheight = window.innerHeight - minupdateheight

    const minCalenderHeight = isOneTwentyFiveInThirteenHundred ? 329 : isOneTwentyFiveInSixteenHundred ? 240 :
      isOneTwentyFiveInFourteenHundred ? 240 : isThirteenHundred ? 270 :
        isOneTwentyFiveInSixteenEighty ? 275 : isOneTwentyFiveScale ? 355 : isSixteenHundred ? 354 :
          isSixteenEighty ? 339 : 545
    const updateCalenderHeight = window.innerHeight - minCalenderHeight

    const defaultDayWidth = is1920x1080 ? "43px" : is1280x720 ? "24px" : isOneTwentyFiveInThirteenHundred ? "34px" : is1024x768 ? "15px" : is1366x768 ? "26px" : isOneTwentyFiveInSixteenHundred ? "34px" :
      isOneTwentyFiveInFourteenHundred ? "20px" : isThirteenHundred ? "26px" :
        isOneTwentyFiveInSixteenEighty ? "30px" : isOneTwentyFiveScale ? "30px" : isSixteenHundred ? "31px" :
          isSixteenEighty ? "33px" : "34px"

    setState(preState => ({ ...preState, height: updateheight, calenderHeight: updateCalenderHeight, defaultDayWidth: defaultDayWidth }))
  }
  useEffect(() => { getDateData(); updateDimensions(); }, [monthYear, props.updateFinancialYear === 'updated', userId, applyFor]);

  useEffect(() => { updateDimensions(); }, []);
  const getDateData = async () => {
    if (monthYear !== "") {
      setLoading(true)
      const MonthYearData = await LnAServices.getMonthYearAttendenceData(userId ? userId : (applyFor ? applyFor : authUser), monthYear);
      setTimeout(() => {
        setDateData(MonthYearData.Result);
        setTimeout(() => {

          if (document.getElementById("leaveApplyCalendar") !== null)
            document.getElementById("leaveApplyCalendar").ej2_instances[0].refresh()

          setTimeout(() => {
            setLoading(false)
          }, 1000)
        }, 1000);
      })
    }
  };

  const getEachDay = async (args) => {
    try {
      var argsDate = dateFns.format(args.date, "yyyy-MM-dd");
      for (var i = 0; i < dateData.length; i++) { //NOSONAR
        if (argsDate == dateData[i].dt) {
          const main = document.createElement("div");
          const iEle = document.createElement("div");
          const span = document.createElement("span");

          main.classList.add("default-day-container");
          iEle.classList.add("default-day");
          iEle.classList.add("tooltipCalendar");
          span.classList.add("tooltiptextCalendar");
          span.classList.add("tooltiptextBlock");
          if (dateData[i].caption !== "") {
            span.textContent = dateData[i].caption;
          }
          if (dateData[i].leaveColor !== "")
            iEle.style.backgroundColor = dateData[i].leaveColor;
          else if (dateData[i].attendanceColor !== "")
            iEle.style.backgroundColor = dateData[i].attendanceColor;

          iEle.appendChild(span)
          main.appendChild(iEle)
          args.element.appendChild(main);
        }
      }
    } catch (error) {
      console.log("🚀 ~ file: calender.jsx:171 ~ getEachDay ~ error", error)
    }
  };

  const Onnavigated = async (arg) => {
    try {
      const day = dateFns.format(arg.date, "yyyy-MM");
      setTimeout(() => { setMonthYear(day); }, 1000);
    } catch (error) {
      console.log("🚀 ~ file: calender.jsx:219 ~ Onnavigated ~ error", error)
    }
  };
  const SAMPLECSS = `
  // .e-calendar, .e-bigger.e-small .e-calendar {
  //     max-width: 531px;
  //     /* min-width: 383px; */
  //     padding: 0;
  //     height: 421px;
  // }
  // .e-calendar .e-header .e-title, .e-bigger.e-small .e-calendar .e-header .e-title {
  //     color: rgba(0, 0, 0, 0.75);
  //     /* color: grey; */
  //     font-size: 17px;
  // }
  // .e-calendar .e-content th {
  //     box-sizing: border-box;
  //     font-weight: 500;
  //     font-size: 17px;
  // }
  // .e-calendar .e-footer-container, .e-bigger.e-small .e-calendar .e-footer-container {
  //     visibility:hidden
  // }
  // .e-calendar .e-content.e-month td, .e-bigger.e-small .e-calendar .e-content.e-month td {
  //     height: 55px;
  //     padding: 14px;
  // }
  
  #leaveApplyCalendar {
    max-width: 100%;
    padding: 0;
    height: ${props.type === "leaveApprovalSubmit" || props.type === "swipeApprovalSubmit" ? state.calenderHeight - 45 : state.calenderHeight}px;
    overflow-y:${window.innerWidth > 1282 ? 'hidden' : 'auto'};
  }

  
  #leaveApplyCalendar .e-header.e-month .e-day.e-title {
    color: rgba(0, 0, 0, 0.75);
    /* color: grey; */
    font-size: ${isThirteenHundred ? .85 : 1}rem;
  }
  #leaveApplyCalendar .e-content.e-month th {
    box-sizing: border-box;
    font-weight: 500;
    font-size: ${isThirteenHundred ? .85 : 1}rem;
  }
  #leaveApplyCalendar .e-calendar .e-header.e-month, .e-bigger.e-small .e-calendar .e-header.e-month {
    padding:0px 10px 0px 10px !important;
  }
  #leaveApplyCalendar .e-footer-container {
    visibility:hidden
  }
  
  #leaveApplyCalendar .e-content.e-month td {
    height: .5rem;
    padding: 3px;
  }
  
  #leaveApplyCalendar .e-week-header{
    border-bottom: 2px solid black;
    margin-bottom:10px;
  }
  
  .holidaySat {
      height: 10px;
      width: 10px;
      background-image: conic-gradient(Coral 0deg, Coral 180deg, Coral 90deg, Coral 180deg, Chartreuse 180deg, Chartreuse 270deg, Chartreuse 270deg);
      border-radius: 50%;
      margin-left:31px;
      cursor:pointer;
  
   
  }
  .holidayWeekend {
      height: 10px;
      width: 10px;
      background-color:Chartreuse;
      border-radius: 50%;
      margin-left:31px;
      cursor:pointer;
   
  }
  .absent {
      height: 10px;
      width: 10px;
      background-color:red;
      border-radius: 50%;
      margin-left:31px;
      cursor:pointer;
   
  }
  .present {
      height: 10px;
      width: 10px;
      background-color:Chartreuse;
      border-radius: 50%;
      margin-left:31px;
      cursor:pointer;
   
  }
  
  #leaveApplyCalendar .e-content.e-month span.e-day {
    border: none;
    font-size: ${isThirteenHundred ? .8 : .95}rem;
    font-weight: 500;
    height: 30px;
    line-height: 30px;
    width: 30px;
  }
  
  // .e-calendar .e-content span.e-day, .e-bigger.e-small .e-calendar .e-content span.e-day {
  //     border: none;
  //     font-size: 2vh;
  //     font-weight: 500;
  //     height: 30px;
  //     line-height: 30px;
  //     width: 30px;
  // }
   
  .tooltipCalendar .tooltiptextCalendar {  
    visibility: hidden;    
    background-color: white;  
    color: #fff;  
    text-align: center;  
    border-radius: 6px;  
    padding: 5px 0;  
    position: absolute;  
    border: lightgray 1px solid;
    font-weight: bold;
  }  
  .tooltipCalendar:hover .tooltiptextCalendar {  
    visibility: visible;  
  }
  .tooltiptextCalendar {  
    padding: .3rem !important
  }
  // .tooltipCalendar .tooltiptextCalendar::after {
  //   content: " ";
  //   position: absolute;
  //   top: 100%; /* At the bottom of the tooltip */
  //   left: 50%;
  //   margin-left: -5px;
  //   border-width: 5px;
  //   border-style: solid;
  //   border-color: lightgrey transparent transparent transparent;
  // }
  
  .default-day {
    height: .6rem;
    width: .6rem;
    border-radius: 50%;
    cursor: pointer;
    // display: inline-block !important;
    // text-align: center !important;
  }
  .default-day-container{
    width: 100%;
    display: flex;
    justify-content: center;
  }
  
  #leaveApplyCalendar .e-cell span {
    // display: inline-block !important;
    // width: 100% !important;
    // text-align: center !important;
    // justify-content: center;
    // align-items: center;
    // flex-direction: column;
  }

  // #leaveApplyCalendar .e-cell span.tooltiptextBlock {
  //   display: inline-block !important;
  // }

  #leaveApplyCalendar .e-cell.e-other-month {
    visibility: hidden !important;
  }
 
  `;
  return (
    <div
      className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
      style={{
        padding: props.type === "onleavetab" ? isThirteenHundred && "0rem" :is1280x720? "0.2rem" : "1rem 1rem"
        // height: props.type === "leavefTabs" ? "" :
        //   (props.type === "leavApply" ? "500px" :
        //     (props.type === "leaveApprovalSubmit" ? "453px" :
        //       (props.type === "swipeApprovalSubmit" ? "537px" : state.height))),
      }}
    >
      <style>{SAMPLECSS}</style>
      {loading && <ProgressBar />}
      {<CalendarComponent
        id="leaveApplyCalendar"
        navigated={Onnavigated}
        renderDayCell={getEachDay}
        dayHeaderFormat="Abbreviated"
      />}
    </div>
  );
};

const mapStateToProps = ({ lnareducer, auth }) => {
  const { authUser } = auth;
  const { fyId, updateFinancialYear, leaveApplyLoadName, applyFor} = lnareducer;
  return {
    fyId, updateFinancialYear, leaveApplyLoadName, authUser, applyFor
  }
}

export default connect(mapStateToProps, {})(Calender)