/**
 * Author: Mukesh.
 * Created:11-Jan-2023
 * Last Modify:11-Jan-2023
 * Modified By:Mukesh
 */
import React, { useEffect, useState } from "react";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import './style.css';
import { push } from "connected-react-router";

const HightLight = (props) => {
  const [layer, setLayer] = useState([])
  const [sublayer, setSubLayer] = useState([])
  const [country, setCountry] = useState([])
  const [layerVal, setLayerVal] = useState('')
  const [sublayerVal, setSublayerVal] = useState('')
  const [countryVal, setCountryVal] = useState('')
  const [btnFlag, setbtnFlag] = useState(true)
  const [showHeigLight, setshowHeigLight] = useState(true)
  const onLayerChange = (val) => {
    props.plotingObj.clearHighlighteds();
    setbtnFlag(true)
    setLayerVal(val)
  }
  const onSubLayerChange = (val) => {
    props.plotingObj.clearHighlighteds();
    setbtnFlag(true)
    setSublayerVal(val)
  }
  const onCountryChange = (val) => {
    props.plotingObj.clearHighlighteds();
    setbtnFlag(true)
    setCountryVal(val)
  }
  const objectIterator=()=>{
    props.plotingObj.clearHighlighteds();
    props.plotingObj.nodes.each((e) => {
      e.findObject('recbox').strokeWidth = 1
      e.findObject('recData').strokeWidth = 1
      e.findObject('bottombox').strokeWidth = 1   
      e.isHighlighted = false 
    })
  }
  const showHide = () => {
    setshowHeigLight(false)
    props.onSelectHigh(false)
    objectIterator()
  }
  const clickHighLight = () => {
    try {
      if (layerVal == '' && sublayerVal == '' && countryVal == '') {
        document.getElementById('location').focus()
        document.getElementById('sublayer').focus()
        document.getElementById('layer').focus()
      }
      if (!layerVal) return;
      props.plotingObj.focus();
      props.plotingObj.startTransaction("highlight search");
      let nodeArr =[]    
      if (layerVal) {
        if (layerVal && sublayerVal && countryVal) {
          props.plotingObj.nodes.each((e) => {
            if (e.data.layer == layerVal && e.data.subLayer == sublayerVal && e.data.country == countryVal) {
              nodeArr.push(e)  
            }
          })
        } else if (layerVal && sublayerVal && !countryVal) {
          props.plotingObj.nodes.each((e) => {
            if (e.data.layer == layerVal && e.data.subLayer == sublayerVal) {
              nodeArr.push(e)            
            }
          })
        } else if (layerVal && !sublayerVal && !countryVal) {         
          props.plotingObj.nodes.each((e) => {            
            if (e.data.layer == layerVal) {
              nodeArr.push(e)                      
            }
          })       
        } else if (!layerVal && sublayerVal && countryVal) {
          props.plotingObj.nodes.each((e) => {
            if (e.data.subLayer == sublayerVal && e.data.country == countryVal) {
              nodeArr.push(e)           
            }
          })
        } else if (!layerVal && sublayerVal && !countryVal) {
          props.plotingObj.nodes.each((e) => {
            if (e.data.subLayer == sublayerVal) {
              nodeArr.push(e)                       
            }
          })
        } else if (!layerVal && !sublayerVal && countryVal) {
          props.plotingObj.nodes.each((e) => {
            if (e.data.country == countryVal) {
              nodeArr.push(e)                            
            }
          })
        } else {
          props.plotingObj.nodes.each((e) => {
            if (e.data.layer == layerVal) {
              nodeArr.push(e)       
            }
          })
        }
        if (nodeArr.length > 0) {
             let rLevel= 0   
             nodeArr.forEach(e=>{
              e.findObject('recbox').strokeWidth = 2
              e.findObject('recData').strokeWidth = 2
              e.findObject('bottombox').strokeWidth = 2         
              e.isHighlighted = true
              rLevel = e.findTreeLevel()+1
              props.plotingObj.findTreeRoots().each(root => root.expandTree(rLevel));  
              props.plotingObj.centerRect(e.actualBounds)               
             })
             props.plotingObj.findTreeRoots().each(root => root.collapseTree(rLevel+1));
        }

      } else {
        props.plotingObj.clearHighlighteds();
      }
      props.plotingObj.commitTransaction("highlight search");
      setbtnFlag(false)

    } catch (err) {
      console.log(err)
    }

  }
  const clearHighLight = () => {
     props.plotingObj.clearHighlighteds();
    // props.plotingObj.nodes.each((e) => {
    //   e.findObject('recbox').strokeWidth = 1
    //   e.findObject('recData').strokeWidth = 1
    //   e.findObject('bottombox').strokeWidth = 1   
    //   e.isHighlighted = false 
    // })
   // objectIterator()
    setbtnFlag(true)
  }
  const resetValue = () => {
    props.plotingObj.clearHighlighteds();
    setbtnFlag(true)
    document.getElementById('layer').value = ''
    document.getElementById('sublayer').value = ''
    document.getElementById('location').value = ''
    setLayerVal('')
    setSublayerVal('')
    setCountryVal('')
  }

  function uniqdata(data, key) {
    return [... new Map(data.map(x => [key(x), x])).values()]
  }
  useEffect(() => {
    try {
      let nodeData =  props.plotingObj.model.nodeDataArray//props.plotingObj.model.Kc
      let layerData = []
      let subLayerData = []
      let countryData = []
      for (let i = 0; i < nodeData.length; i++) {
        if (nodeData[i].layer != null) {
          layerData.push({ Id: nodeData[i].Id, Name: nodeData[i].layer })
        }
        if (nodeData[i].subLayer != null) {
          subLayerData.push({ Id: nodeData[i].Id, Name: nodeData[i].subLayer })
        }
        if (nodeData[i].country != null) {
          countryData.push({ Id: nodeData[i].Id, Name: nodeData[i].country.toUpperCase() })
        }
      }
      setLayer(uniqdata(layerData, it => it.Name))
      setSubLayer(uniqdata(subLayerData, it => it.Name))
      setCountry(uniqdata(countryData, it => it.Name))
    } catch (err) {
      console.log(err)
    }
  }, []);
  if (showHeigLight) {
    return (
      <div className="high-container">
        <div className="high-header"><h4>HighLight</h4> <button type="button" className="close" onClick={showHide}></button></div>
        <div className="mb-d">

          <DropDownListComponent
            id={`layer`}
            name={`layer`}
            dataSource={layer}
            fields={{ text: "Name", value: "Name" }}
            placeholder="Layer"
            popupHeight="286px"
            cssClass="e-outline"
            floatLabelType="Auto"
            allowCustom={false}
            allowFiltering={true}
            onChange={(e) => onLayerChange(e.target.value)}
          />

        </div>
        <div className="mb-d">
          <DropDownListComponent
            id={`sublayer`}
            name={`sublayer`}
            dataSource={sublayer}
            fields={{ text: "Name", value: "Name" }}
            placeholder="Sublayer"
            popupHeight="286px"
            cssClass="e-outline"
            floatLabelType="Auto"
            allowCustom={false}
            allowFiltering={true}
            onChange={(e) => onSubLayerChange(e.target.value)}

          />
        </div >
        <div className="mb-d">
          <DropDownListComponent
            id={`location`}
            name={`location`}
            dataSource={country}
            fields={{ text: "Name", value: "Name" }}
            placeholder="Location"
            popupHeight="286px"
            cssClass="e-outline"
            floatLabelType="Auto"
            allowCustom={false}
            allowFiltering={true}
            onChange={(e) => onCountryChange(e.target.value)}
          />
        </div>

        <div style={{ "textAlign": "center" }}>
          <button type="button" className="pbtn reset" onClick={resetValue}>Reset</button>
          <button type="button" className="pbtn cancel" onClick={showHide} >Cancel</button>
          {btnFlag && <button type="button" className="pbtn highlight" onClick={clickHighLight} >HighLight</button>}
          {!btnFlag && <button type="button" className="pbtn highlight" onClick={clearHighLight} >UnHighLight</button>}

        </div>

      </div>
    );
  } else {
    return (<div></div>)
  }

}
export default HightLight
