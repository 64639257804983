import {
  ADD_UPDATE_OKR_RATING_REDUX
} from "../../constant/okrratingconstant/okrratingconstant";

const getRatingMnth = () => {
  let mnth = new Date('12-12-2022').getMonth() + 1;
  return mnth === 1 ? 12 : mnth - 1;
}

const INIT_STATE = {
  fyId: null,
  empId: null,
  monthOfRating: getRatingMnth(),
  ratingBusinessOkr: [],
  ratingCentralOkr: [],
  ratingLPOkr: [],
  ratingBrandOkr: [],
  teamMembersList: [],
  refreshRatingDataFlag: '',
  refreshInterimDataFlag: '',
  productivityallocationData: {},
  selfUser: [],
  supervisorUsers: [],
  TPUsers: [],
  refreshInterimfeedbackflag: '',
  reviewBusinessOkr: [],
  reviewCentralOkr: [],
  reviewLPOkr: [],
  reviewBrandOkr: [],
  ratingStatusData: [],
  krData: {},
  interimType: '',
  isDraftClick: null,
  reviewUsers: [],
  pendingOKRs:"",  
  ratingCategoryStatus:"",
  ratingStatus:'',
  ratingTabs:'',
  selectedMonthName:''
};
// eslint-disable-next-line
export default (state = INIT_STATE, action) => { //NOSONAR
  switch (action.type) {/**nosonar */
    case ADD_UPDATE_OKR_RATING_REDUX: {
      return {
        ...state,
        ...action.payload
      };
    }
    default:
      return state;
  }
};
