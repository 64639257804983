import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import { TextBoxComponent, } from '@syncfusion/ej2-react-inputs';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import {dencrypt} from '../../../app/other/commonfunction';
import SeparationServices from '../../../services/entryexit/separationServices';
import DateFnsUtils from '@date-io/date-fns';
import { addUpdateSeparationDetails} from '../../../redux/actions/entryexit/separationaction';
import'../resignationcomponent/resignationcomponent.css';
import { connect } from "react-redux";
class AbscondingReasonForResignation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      // abscondingDate: new Date(),
      // abscondingManagerRemark:''
    };
  }
  TextFieldComponent = (props) => {
    return <TextField {...props} disabled={true} />
  }
  handaleAbscondingDate(date){
    this.props.addUpdateSeparationDetails({
      absondingDate: date.toString()
    })

  }
  getResignationInfo = () => {
    try {
      const { search } = window.location;
      const params = new URLSearchParams(search);
      const empId = dencrypt(params.get("empId"))
      const employeeMasterData = Promise.resolve(
        SeparationServices.getEmployeeResignationData(
          empId,
          "info"
        )
      );
      employeeMasterData.then((result) => {
        if (result !== null && result.length > 0) {
          const { employeeStatus, noticePeriodInMonth, reportingTo, ccEmpNm } = result[0];
          this.props.addUpdateSeparationDetails({
            "employeeStatus": employeeStatus, "noticePeriodInMonth": noticePeriodInMonth, "reportingTo": reportingTo,
            "ccEmpNm": ccEmpNm
          })
        }
      });
    } catch (error) {
      // eslint-disable-next-line
    }
  }
  componentWillReceiveProps(nextProps) {
    if(nextProps.employeeStatus==="Absconded"){
      // eslint-disable-next-line
        this.props.addUpdateSeparationDetails({updateResonForResignationStatus:''})
    }
    if(nextProps.updateAbscondingStatus==='updated'){
      this.getResignationInfo();
      // eslint-disable-next-line
      this.props.addUpdateSeparationDetails({updateAbscondingStatus:''})
    }
  }
  componentDidMount = () => {
    this.props.addUpdateSeparationDetails({abscondingManagerRemark:'', employeeStatus:'' })
    setTimeout(()=>{
      this.textareaObj.addAttributes({ rows: 4 });
    },1000)
  };
  
  limitCaracter = (e) => {
    let word = "";
    let addressCount = 0;
    word = e.value;
    addressCount = word.length;
    document.getElementById("reasonForAbscondingLimitErr").innerHTML = addressCount + "/500";
  }
  
  render() {
    const { abscondingManagerRemark, absondingDate, employeeStatus } =this.props;
    return (
      <div>
        <div> REASON FOR ABSCONDING </div>
        <div>
        {/* <div style={{background:"#F4F3F5"}}> */}
        <div>
          <div className='col-12' style={{background:"#FFF"}}>
          

              <div className='col-1' style={{marginLeft:10, marginTop:8}}>
                    <div className='bold-text'>Report As</div>
                    <div>Absconding</div>
                </div>
              <div className='col-3-custom'>
              
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker style={{ width: '100%' }}
                    id="lwdCompanyRequest"
                    name="lwdCompanyRequest"
                    format="dd-MMM-yyyy"
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    value={absondingDate !==null ? new Date(absondingDate) : new Date()}
                    onChange={(date) =>// eslint-disable-next-line
                      this.props.addUpdateSeparationDetails({
                        absondingDate: date.toString()
                      }) }
                    TextFieldComponent={this.TextFieldComponent}
                    inputVariant="outlined"
                    variant="inline"
                    InputAdornmentProps={{ position: "start" }}
                    autoOk
                    maxDate={new Date()}
                    disabled={employeeStatus === "Absconded" ? true:false}
                    label="Date Not Reporting *"
                  />
                </MuiPickersUtilsProvider>
              </div>
          </div>
          <div className='col-12' style={{background:"#FFF", padding:10}}>
                <div>
                <TextBoxComponent placeholder="Manager's Remark *" cssClass="e-outline" floatLabelType="Auto"
                  name="magenda-txt"
                  multiline={true}
                  style={{backgroundColor:'#fff'}}
                  change={(e)=>// eslint-disable-next-line
                    this.props.addUpdateSeparationDetails({
                      abscondingManagerRemark: e.value
                    })}
                    input={(e)=>this.limitCaracter(e)}
                  inputProps={{
                    required: true,
                    minLength: 8
                  }}
                  ref={(scope) => { this.textareaObj = scope; }}
                  value={abscondingManagerRemark}
                  htmlAttributes={{ maxlength: 500 }}
                  // read
                  readonly={employeeStatus === "Absconded"? true: false}
                  // disabled={employeeStatus === "Absconded"? true: false}
                />
                </div>
                <div style={{float:'right'}}>
                  <div style={{textAlign:'right'}} id="reasonForAbscondingLimitErr" className="error-div" />
                </div>
          </div>
        </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ separationreducer }) => {
  const { abscondingManagerRemark, absondingDate, employeeStatus, updateResonForResignationStatus, updateAbscondingStatus } = separationreducer;
  return { abscondingManagerRemark, absondingDate, employeeStatus, updateResonForResignationStatus, updateAbscondingStatus };
};

export default connect(mapStateToProps, {addUpdateSeparationDetails})(AbscondingReasonForResignation);
