/*
 * Author : Vivek Khodade
 * Date : 08-05-2020
 * Description: TMLC IT Tabs Component
 */

import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ConfirmationServices from "../../services/entryexit/confirmationServices";
import Progressbar from '../progressbar/progressbar';
import Confirmed from "./confirmed/confirmed";
import { getFormatDateForGrid } from "../../app/other/commonfunction";
import { editUpdateConfirmation } from "../../redux/actions/entryexit/confirmationaction";
import "./confirmationtabs.css";
import "../../app/containers/tmlc/tmlc.css";

class ConfirmationTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dueConfirmationList: null,
      confirmedList: null,
      dueConfirmationCount: 0,
      confirmedCount: 0,
    };
  }

  componentDidMount() {
    localStorage.removeItem('SelectedTabAction');
    this.getEmployeeConfirmationList(1);
    this.getEmployeeConfirmationList(2);
    document.getElementById("DueConfirmation").style.display = "block";
  }
  // eslint-disable-next-line
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.status === "updated") {
      this.getEmployeeConfirmationList(1);
      this.getEmployeeConfirmationList(2);
      // eslint-disable-next-line
      this.props.editUpdateConfirmation({ status: "" });
    }
  }

  getEmployeeConfirmationList(typeid) {
    try {
      const { authUser } = this.props;
      const employeeConfirmationList = Promise.resolve(
        ConfirmationServices.getEmployeeConfirmationList(typeid, authUser)
      );
      employeeConfirmationList.then((result) => {
        if (result !== null) {
          if (typeid === 1) {
            this.setState({
              dueConfirmationList: getFormatDateForGrid(result),
              dueConfirmationCount: result.length,
            });
          } else {
            this.setState({
              confirmedList: getFormatDateForGrid(result),
              confirmedCount: result.length,
            });
          }
        }
      });
    } catch (error) {
      // eslint-disable-next-line
      console.error(error);
    }
  }

  openTab = (evt, tabName) => {
    const event=evt;
    if (tabName === "DueConfirmation") {
      this.getEmployeeConfirmationList(1);
    }
    if (tabName === "Confirmed") {
      this.getEmployeeConfirmationList(2);
    }
    let i;
    let tablinks;
    const tabcontent = document.getElementsByClassName(
      "confirmation-tab-content"
    );
    // eslint-disable-next-line
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }
    // eslint-disable-next-line
    tablinks = document.getElementsByClassName("tablinks");
    // eslint-disable-next-line
    for (i = 0; i < tablinks.length; i++) {
      // eslint-disable-next-line
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }
    document.getElementById(tabName).style.display = "block";
    event.currentTarget.className += " active";
  };

  render() {
    const {
      confirmedCount,
      dueConfirmationCount,
      dueConfirmationList,
      confirmedList,
    } = this.state;
    return (
      <div>
        <div className="col-12 tabs-padding">
          <div className="it-tab">
            <button
              type="submit"
              className="col-6 tab-button tablinks active"
              onClick={(e) => this.openTab(e, "DueConfirmation")}
            >
              <b>DUE CONFIRMATION</b>
              <span className="confirmation-tab-count-dot">
                {dueConfirmationCount > 0 ? dueConfirmationCount : 0}
              </span>
            </button>
            <button
              type="submit"
              className="col-6 tab-button tablinks "
              onClick={(e) => this.openTab(e, "Confirmed")}
            >
              <b>CONFIRMED</b>
              <span className="confirmation-tab-count-dot">
                {confirmedCount > 0 ? confirmedCount : 0}
              </span>
            </button>
          </div>

          <div id="DueConfirmation" className="confirmation-tab-content">
            {dueConfirmationList !== null ? (
              <Confirmed data={dueConfirmationList} typeid={1} />
            )
              :
              <div className='progress-bar-div'>
                <Progressbar/>
              </div>
          }
          </div>
          <div id="Confirmed" className="confirmation-tab-content">
            {confirmedList !== null ? (
              <Confirmed data={confirmedList} typeid={2} />
            )
            :
            <div className='progress-bar-div'>
              <Progressbar/>
            </div>
          }
          </div>
        </div>
      </div>
    );
  }
}

ConfirmationTabs.propTypes = {
  status: PropTypes.string,
  authUser: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  editUpdateConfirmation: PropTypes.func,
};
ConfirmationTabs.defaultProps = {
  authUser: "",
  status: "",
  editUpdateConfirmation: {},
};
const mapStateToProps = ({ auth, confirmationReducer }) => {
  const { authUser } = auth;
  const { status } = confirmationReducer;
  return { authUser, status };
};

export default connect(mapStateToProps, { editUpdateConfirmation })(
  ConfirmationTabs
);
