import React from 'react'

export default function PerformanceScore(props) {
  const {performanceScoreData}=props;  
  const isOneTwentyFiveScale = window.innerHeight < 715  ||  window.innerHeight < 747;
  return (
    <div className={`col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12  ml-xs`}>
        {
          performanceScoreData.length > 0 &&
          <> 
          {
            performanceScoreData[0].performanceScore!==null &&
            <div className='col-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 legend-names' style={{fontSize:isOneTwentyFiveScale? 12:'', paddingRight:0}}>Performance Score : <span className=''><b>{performanceScoreData[0].performanceScore}</b></span></div>
          }             
          {
            performanceScoreData[0].medianScore !==null &&
            <div className='col-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 legend-names ' style={{fontSize:isOneTwentyFiveScale? 12:''}}>Median Score : <span className=''><b>{performanceScoreData[0].medianScore}</b></span></div>
          }
          </>
          }
    </div>
  )
}
