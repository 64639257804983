/* eslint-disable react-hooks/exhaustive-deps */
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  DetailRow,
  Inject,
  Page,
  Filter,
  Freeze,
} from "@syncfusion/ej2-react-grids";
import { connect } from "react-redux";
import LnAServices from "../../../services/lna/lnaservice";
import { updateLnAData, updateMasterItemStatus } from "../../../redux/actions/lna/lnaaction";
import { DropDownButtonComponent } from "@syncfusion/ej2-react-splitbuttons";
import { getDisplayDateOnly, getDisplayTimeFormat, getNotify, getTimeFormatForLnaMAster } from "../../../app/other/commonfunction";
import {
  getGridColumn,
  ADMIN_MODULE_ACTION,
  COLOUR_CLUSTER_COLOUR,
  HOLIDAY_CLUSTER_DATE,
  SYMBOL_CLUSTER_MASTER, STATUTORY_CLUSTER_STATUTORY_DATE,
  STATUTORY_CLUSTER_STATUTORY_SYMBOL,
  STATUTORY_CLUSTER_STATUTORY_TYPE, EFFECTIVE_END_DATE, SOURCE_CLUSTER_EFFECTIVE_START_DATE,
  SOURCE_CLUSTER_EFFECTIVE_END_DATE,
  EFFECTIVE_START_DATE,
  ATTENDANCE_CLUSTER_ATTENDANCE_ATTACH_NOTE,
  ATTENDANCE_CLUSTER_ATTENDANCE_TYPE,
  RESET_DATE,
  STATUTORY_CLUSTER_STATUTORY_DAY_BREAK, POLICY_DOC,
  EXCEPTIONAL_CLUSTER_EXCEPTIONAL_SYMBOL, ATTENDANCE_CLUSTER_ATTENDANCE_SYMBOL,
  EXCEPTIONAL_CLUSTER_EXCEPTIONAL_TYPE,
  EXCEPTIONAL_CLUSTER_EXCEPTIONAL_DAY_BREAK,
  EXCEPTIONAL_CLUSTER_EXCEPTIONAL_ATTACH_NOTE,
  WORKINGDAY_CLUSTER_DATE,
  WORKINGDAY_CLUSTER_MIN_HRS,
  WORKINGDAY_CLUSTER_END_TIME,
  WORKINGDAY_CLUSTER_START_TIME,
  WORKINGDAY_CLUSTER_TYPE,
  WORKINGDAY_CLUSTER_DAY_BREAK,
  WORKDAY_WINDOW_FREQUENCY, LEAVE_CLUSTER_ADD_FREQUENCY,
  WORKDAY_WINDOW_WEEK_HRS,
  LEAVE_CLUSTER_COLOR,
  WORKDAY_WINDOW_SYMBOL,
  WORKDAY_WINDOW_START_TIME,
  WORKDAY_WINDOW_END_TIME,
  WORKDAY_WINDOW_WORK_HRS,
  WORKDAY_WINDOW_MIN_HRS,
  WORKDAY_WINDOW_GRACE_TIME,
  WORKDAY_WINDOW_NUMBER_OF_INSTANCE,
  LEAVE_CLUSTER_SYMBOL,
  WEEKDAY_CLUSTER_SYMBOL, WEEKDAY_CLUSTER_WEEKEND_TYPE,
  DAY_BREAK_CLUSTER_SYMBOL,
  SOURCE_CLUSTER_SYMBOL,
  LOCK_UNLOCK_ATTENDANCE_MONTH,
  LOCK_UNLOCK_LOCK_END_DATE,
  LEAVE_YEAR_START_DATE,
  LEAVE_YEAR_END_DATE,
  WORKINGDAY_DAYBREAK_WORK_HRS,
  WORKINGDAY_DAYBREAK_GRACE_TIME,
} from "../../../app/other/gridcolumns";
import {
  ATTENDANCE, WORKDAY_WINDOW, WEEKDAY, DAYBREAK, ATTENDANCE_SOURCE, LEAVE, HOLIDAY, OPTIONAL_HOLIDAY,
  STATUTORY, EXCEPTIONAL_ATTENDANCE, WORKING_DAY, COLOUR, ROLE, SYMBOL, LOCK_UNLOCK, LEAVE_YEAR_CONFIG, WORKDAY_DAYBREAK_MAPPING, LEAVE_YEAR
} from "../../../redux/constant/meetingcategoryconstant";
import React, { useEffect, useState } from "react";
import Progressbar from "../../progressbar/progressbar";
import { L10n } from '@syncfusion/ej2-base';
import { AllDateFormat, MasterDocumentVew } from "../../separation/allcommoncomponents";
import { ERROR, entryAlreadyActive } from "../../../assets/constants/constants";
import { format } from 'date-fns';
import NewConfirmDialog from "../../confirmdialog/newconfirmdialog";
import ConfirmationPopup from "../../../utilities/confirmationpopup";

L10n.load({
  'en-US': {
    grid: {
      EmptyRecord: '',
    },
  },
});

const SAMPLECSS = `

.e-pagerdropdown  {
  display: inline-block;
  height: 32px;
  margin-left: 12px;
  margin-right: 0;
  margin-top: 9px !important;
  overflow: hidden;
  vertical-align: middle;
  width: 90px;
  position: absolute;
  right: 15%;
}
.e-pagerconstant  {
display: inline-block;
height: 32px;
margin-left: 12px;
margin-right: 0px;
margin-top: 14px !important;
overflow: hidden;
vertical-align: middle;
width: 90px;
position: absolute;
right: 10%;
}
.e-input-group e-control-wrapper e-alldrop e-ddl e-lib e-keyboard e-valid-input {
padding-left:40px ! important;
width:100% ! important;
}

#LnAMasterGrid .e-gridcontent .e-content .e-frozencontent.e-frozen-left-content .e-table {
  border-right-color: #343A40 !important;
}

#LNA-AdminModuleId .e-grid .e-gridcontent tr.e-row .e-rowcell {
  font-size: 0.85rem !important;
}`;
const setHeaderCss = () => {
  setTimeout(() => {
    const ele = document.getElementsByClassName("e-headercelldiv");
    for (let i in ele) {
      if (!isNaN(parseInt(i, 10))) {
        let index = parseInt(i, 10);
        if (ele[index].innerText === ADMIN_MODULE_ACTION) {
          ele[index].classList.add("grid-center-header");
        } else {
          ele[index].classList.add("grid-left-header");
        }
      }
    }
  }, 500);
}
const pagerCss = `.e-pager .e-pagercontainer {
    border-radius: 4px;
    border-style: solid;
    border-width: 0;
    display: inline-block;
    margin: 0px;
    overflow: unset;
    position: relative;
    left: 24%;
}
`
const LNAMasterSetup = (props) => {
  const { authUser, refresh, masterId } = props;
  const [showPopup, setPopup] = useState({ status: false, action: '' })
  const [showMessage, setMessage] = useState({ display: false, action: '' })
  const [actionData, setActionData] = useState({});
  const [state, setState] = useState({
    gridHeight: window.innerHeight - 91,
    popUp: false,
    lnaMasterData: null,
  });
  const [initialLoad, setInitialLoad] = useState(false);
  const itemActive = [
    {
      text: "Edit",
      iconCss: "e-icons e-edit",
    },
    {
      text: "Inactive",
      iconCss: "fas fa-toggle-off"
    },
  ];
  const itemOnlyInactive = [
    {
      text: "Inactive",
      iconCss: "fas fa-toggle-off"
    },
  ];
  const itemInactive = [
    {
      text: "Edit",
      iconCss: "e-icons e-edit",
    },
    {
      text: "Active",
      iconCss: "fas fa-toggle-on"
    },
  ];
  const getGridColumnName = () => {
    switch (masterId + "") {
      case ATTENDANCE:
        return 'attendanceCluster'
      case WORKDAY_WINDOW:
        return "workdayWindow"
      case WEEKDAY:
        return "weekdayCluster"
      case DAYBREAK:
        return "dayBreakCluster"
      case ATTENDANCE_SOURCE:
        return "sourceCluster"
      case LEAVE:
        return "leaveCluster"
      case HOLIDAY:
        return "holidayCluster"
      case OPTIONAL_HOLIDAY:
        return "optionalHolidayCluster"
      case STATUTORY:
        return "statutoryCluster"
      case EXCEPTIONAL_ATTENDANCE:
        return "exceptionalCluster"
      case WORKING_DAY:
        return "workingDayCluster"
      case COLOUR:
        return "colourCluster"
      case ROLE:
        return "roleCluster"
      case SYMBOL:
        return "symbol"
      case LOCK_UNLOCK:
        return "lockUnlock"
      case LEAVE_YEAR_CONFIG:
        return "leaveYearConfig"
      case WORKDAY_DAYBREAK_MAPPING:
        return "workdayDayBreak"
      case LEAVE_YEAR:
        return "leaveYear"


      default:
        return "attendanceCluster"
    }
  }

  /**
  *@author: Chandrashekhar Bhagat  
  *@description : Check Active/Inactive Allowance.
  */
  const checkAllowance = async (mId, args, data) => {
    const allowance = await LnAServices.getAllowanceData(data.userId, mId, data.lnAMasterItemUid);
    if (allowance.Result === 'false') {
      setMessage((preState) => ({ ...preState, display: true, action: "Leaves already allocated can't Inactive." }));
    } else {
      setPopup({ status: true, action: args.item.text });
      let inActiveData = {
        userId: authUser,
        lnAMasterItemUid: data.lnAMasterItemUid,
        lnAMasterId: mId === 0 ? '1' : mId + "",
        isActive: "0",
      };
      setActionData(inActiveData);
    }
  }

  /**
  *@author: Chandrashekhar Bhagat  
  *@description : Check Lock/Unlock Criteria.
  */
  const checkLockUnlock = async (mId, args, data) => {
    let doNotActive = false;
    const lockUnlockClusterData = await LnAServices.getLockUnlockClusterData(authUser, '0');
    const currentDate = new Date(data.attendanceMonth);
    const currentMonth = currentDate.getMonth() + 1; // Adding 1 because months are zero-indexed
    const currentYear = currentDate.getFullYear();

    if (lockUnlockClusterData && lockUnlockClusterData.Result && lockUnlockClusterData.Result.length > 0) {
      lockUnlockClusterData.Result.forEach((res) => {
        const responseDate = new Date(res.attendanceMonth);
        const responseMonth = responseDate.getMonth() + 1;
        const responseYear = responseDate.getFullYear();
        if ((currentYear === responseYear) && (currentMonth === responseMonth) && (res.activeStatus === '1')) {
          doNotActive = true;
        }
      })
    }

    if (doNotActive) {
      getNotify(ERROR, entryAlreadyActive);
    }
    else {
      setPopup({ status: true, action: args.item.text });
      let activeData = {
        userId: authUser,
        lnAMasterItemUid: data.lnAMasterItemUid,
        lnAMasterId: mId === 0 ? '1' : mId + "",
        isActive: "1",
      };
      setActionData(activeData);
    }
  }

  useEffect(() => {
    setInitialLoad(true);
    let gridHeight =
      (window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight) - 290;
    setState((preState) => ({ ...preState, gridHeight }));

    (async () => {
      setInitialLoad(true);
      let lnaMData = {};
      if (masterId === 40 && props.leaveYearId) {
        lnaMData = await LnAServices.getLNAMaserData(authUser, masterId, 0, props.leaveYearId);
      } else if (masterId > 0 && props.currentFinancialYearId) {
        lnaMData = await LnAServices.getLNAMaserData(authUser, masterId, 0, props.currentFinancialYearId);
      }
      setState((preState) => ({
        ...preState,
        gridHeight,
        lnaMasterData: lnaMData.Result,
      }));
      setInitialLoad(false);
    })();
    if (props.lnaMasterFlag === "updated") {
      props.updateLnAData({ lnaMasterFlag: "" });
    }
    setInitialLoad(false);

  }, [refresh, masterId, props.lnaMasterFlag, props.currentFinancialYearId, props.leaveYearId]);

  const dataBound = () => {
    setInitialLoad(false);
  };

  const onDataBinding = (pComponentId) => {
    // spinner(true)
    setInitialLoad(true);
    try {
      setTimeout(() => {
        document.getElementById(pComponentId).ej2_instances[0].refresh();
      });
    } catch (error) {
      console.log(error);
    }
  };
  const actionTemplate = (data) => {
    let visibleMenu = [];
    if (data.isActive === "Active") {
      if (masterId === 42) {
        visibleMenu = itemOnlyInactive
      } else {
        visibleMenu = itemActive
      }
    } else {
      visibleMenu = itemInactive
    }

    const onSelect = async (args) => {
      if (args.item.text === "Edit") {
        props.changePopUpHandler("edit", data.lnAMasterItemUid);//roleCLusterUid
      } else if (args.item.text === "Active") {
        if (masterId === 41) {
          checkLockUnlock(masterId, args, data);
        } else {
          setPopup({ status: true, action: args.item.text });
          let activeData = {
            userId: authUser,
            lnAMasterItemUid: data.lnAMasterItemUid,
            lnAMasterId: masterId === 0 ? '1' : masterId + "",
            isActive: "1",
          };
          setActionData(activeData);
        }
      } else if (args.item.text === "Inactive") {
        if (masterId === 40) {
          checkAllowance(masterId, args, data);
        } else {
          setPopup({ status: true, action: args.item.text });
          let inActiveData = {
            userId: authUser,
            lnAMasterItemUid: data.lnAMasterItemUid,
            lnAMasterId: masterId === 0 ? '1' : masterId + "",
            isActive: "0",
          };
          setActionData(inActiveData);
        }
      }
    };

    return (
      <div
        className="col-12"
        style={{
          textAlign: "center",
          padding: "0px 20px 0px 0px",
        }}
      >
        <div className="row1">
          <DropDownButtonComponent
            items={visibleMenu}
            select={onSelect}
            iconCss="e-icons e-more-vertical-2"
            cssClass="e-caret-hide e-custom e-vertical"
            iconPosition="Top"
          ></DropDownButtonComponent>
        </div>
      </div>
    );
  };

  const actionTemplateHex = (prop) => {
    const { colorHexCode } = prop
    return (
      <div style={{ backgroundColor: colorHexCode, color: colorHexCode, marginRight: 10, borderRadius: 3 }}>
        &nbsp;
      </div>
    )
  }
  const getsymbolMaster = (prop) => {
    const { masterCluster } = prop;
    return (
      <div style={{ display: "flex", justifyContent: "left", wordWrap: 'normal', textAlign: "left" }}>
        <p style={{ margin: 0 }}>{masterCluster}</p>
      </div>
    );
  };
  const dateFormatForUi1 = (prop) => {
    const { date: dt } = prop;

    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <AllDateFormat date={dt} />
      </div>
    );
  };
  const getElementInCenter = (prop) => {
    const { tType } = prop;

    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <p style={{ margin: 0 }}>{tType}</p>
      </div>
    );
  };
  const getdayBreakElementInCenter = (prop) => {
    const dayBreak = prop.dayBreak;
    return (
      <div style={{ display: "flex", justifyContent: "left" }}>
        <p style={{ margin: 0 }}>{dayBreak}</p>
      </div>
    );
  };
  const getminHrsFormat = (prop) => {
    const { minHrs: mHrs } = prop;
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <p style={{ margin: 0 }}>{mHrs.split(" ")[1].split(":").slice(0, 2).join(":")}</p>
      </div>
    );
  };
  const getstartTimeFormat = (prop) => {
    const { startTime: stTime } = prop;
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <p style={{ margin: 0 }}>{stTime && stTime.split(" ")[1].split(":").slice(0, 2).join(":")}</p>
      </div>
    );
  };
  const getWorkHrsFormat = (prop) => {
    const { workHrs: wrs } = prop;
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <p style={{ margin: 0 }}>{wrs && wrs.split(" ")[1].split(":").slice(0, 2).join(":")}</p>
      </div>
    );
  };
  const getGraceTimeFormat = (prop) => {
    const { graceTime } = prop;
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <p style={{ margin: 0 }}>{graceTime}</p>
      </div>
    );
  };
  const getnoOfInstanceFormat = (prop) => {
    const { noOfIinstances } = prop;
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <p style={{ margin: 0 }}>{noOfIinstances}</p>
      </div>
    );
  };
  const getWindowFrequencyFormat = (prop) => {
    const { frequency } = prop;
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <p style={{ margin: 0 }}>{frequency}</p>
      </div>
    );
  };
  const getsymbolFormat = (prop) => {
    const { workdayWindowSymbol } = prop;
    return (
      <div style={{ display: "flex", justifyContent: "left" }}>
        <p style={{ margin: 0 }}>{workdayWindowSymbol}</p>
      </div>
    );
  };
  const getendTimeFormat = (prop) => {
    const { endTime: et } = prop;
    return (
      // !prop || !prop.endTime ? '' :
      <div style={{ display: "flex", justifyContent: "center" }}>
        <p style={{ margin: 0 }}>{et.split(" ")[1].split(":").slice(0, 2).join(":")}</p>
      </div>
    );
  };
  const getSymbolElementInCenterE = (prop) => {
    const { Symbol } = prop;

    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <p style={{ margin: 0 }}>{Symbol}</p>
      </div>
    );
  };
  const getFileNameElementInCenter = (prop) => {
    return (
      <MasterDocumentVew fileName={prop.fileName} masterName='statutory' />
    );
  };
  const startDateTemplate = (prop) => {
    const { effectiveStartDate } = prop;
    return <AllDateFormat date={effectiveStartDate} />;
  };
  const attendanceMonthTemplate = (prop) => {
    const { attendanceMonth } = prop;
    const formattedDate = format(new Date(attendanceMonth), 'MMM-yyyy');
    return <>{formattedDate}</>;
  };
  const lockEndDateTemplate = (prop) => {
    const { lockEndDate } = prop;
    return <AllDateFormat date={lockEndDate} />;
  };
  const dateFormatForUi2 = (prop) => {
    const { resetDate: rstD } = prop;
    return <AllDateFormat date={rstD} />;
  };
  const endDateTemplate = (prop) => {
    const { effectiveEndDate } = prop;
    return <AllDateFormat date={effectiveEndDate} />;
  };
  const weeklyHours = (prop) => {
    const { weeklyWrkHrs } = prop;

    if (weeklyWrkHrs !== null || weeklyWrkHrs !== "") {
      return weeklyWrkHrs + " " + "Hours";
    } else {
      return "";
    }
  };
  const date = {
    type: 'CheckBox',
    params: { format: 'dd-MMM-yyyy' },
    itemTemplate: (e) => {
      if (e.date) {
        return <>{getDisplayDateOnly(new Date(e.date))}</>
      } else {
        return <span></span>
      }
    }
  };
  const dateOfStart = {
    type: 'CheckBox',
    params: { format: 'dd-MMM-yyyy' },
    itemTemplate: (e) => {
      if (e.effectiveStartDate) {
        return <>{getDisplayDateOnly(new Date(e.effectiveStartDate))}</>
      } else {
        return <span></span>
      }
    }
  };
  const dateOfEnd = {
    type: 'CheckBox',
    params: { format: 'dd-MMM-yyyy' },
    itemTemplate: (e) => {
      if (e.effectiveEndDate) {
        return <>{getDisplayDateOnly(new Date(e.effectiveEndDate))}</>
      } else {
        return <span></span>
      }
    }
  };
  const resetDate = {
    type: 'CheckBox',
    params: { format: 'dd-MMM-yyyy' },
    itemTemplate: (e) => {
      if (e.resetDate) {
        return <>{getDisplayDateOnly(new Date(e.resetDate))}</>
      } else {
        return <span></span>
      }
    }
  };
  const endTime = {
    type: 'CheckBox',
    params: { format: 'HH-MM' },
    itemTemplate: (e) => {
      if (e.endTime) {
        return <>{getDisplayTimeFormat(new Date(e.endTime))}</>
      } else {
        return <span></span>
      }
    }
  };
  const minHrs = {
    type: 'CheckBox',
    params: { format: 'HH-MM' },
    itemTemplate: (e) => {
      if (e.minHrs) {
        return <>{getDisplayTimeFormat(new Date(e.minHrs))}</>
      } else {
        return <span></span>
      }
    }
  };
  const startTime = {
    type: 'CheckBox',
    params: { format: 'HH-MM' },
    itemTemplate: (e) => {
      if (e.startTime) {
        return <>{getDisplayTimeFormat(new Date(e.startTime))}</>
      } else {
        return <span></span>
      }
    }
  };
  const workHrs = {
    type: 'CheckBox',
    params: { format: 'HH-MM' },
    itemTemplate: (e) => {
      if (e.workHrs) {
        return <>{getDisplayTimeFormat(new Date(e.workHrs))}</>
      } else {
        return <span></span>
      }
    }
  };

  setHeaderCss()

  return (
    <div className="col-sm-12 col-md-12 col-lg-12" id="LNA-AdminModuleId">
      <div>
        <style>{SAMPLECSS}</style>
        {
          (masterId + "" === COLOUR || masterId + "" === ROLE) ? <style> {pagerCss} </style> : ''
        }

        {(state && state.lnaMasterData && state.lnaMasterData.length > 0) && <GridComponent
          id={"LnAMasterGrid"}
          width={(masterId + "" === COLOUR || masterId + "" === ROLE || masterId + "" === LOCK_UNLOCK) ? 650 : masterId + "" === LEAVE_YEAR_CONFIG ? 850 : ''}
          gridLines="Both"
          dataSource={state.lnaMasterData}
          // frozenColumns={2}
          height={state.gridHeight}
          allowFiltering
          filterSettings={{
            type: "CheckBox",
          }}
          dataBound={dataBound}
          onDataBinding={() => onDataBinding("LnAMasterGrid")}
          //created={onFirstGridCreated}
          allowTextWrap
          allowPaging
          pageSettings={{
            pageCount: 5,
            pageSize: (window.innerHeight ||
              document.documentElement.clientHeight ||
              document.body.clientHeight) > 767
              ? 15
              : 10,
          }}

        >
          <ColumnsDirective>
            {getGridColumn(getGridColumnName()).map((item) => {
              switch (item.colName) {
                case ADMIN_MODULE_ACTION:
                  return (
                    <ColumnDirective
                      template={actionTemplate}
                      filter={false}
                      textAlign={item.align}
                      field={item.field}
                      headerText={item.colName}
                      width={item.width}
                      allowFiltering={false}
                    // freeze='Left'
                    />
                  );
                case COLOUR_CLUSTER_COLOUR:
                case LEAVE_CLUSTER_COLOR:
                  return (
                    <ColumnDirective
                      template={actionTemplateHex}
                      filter={false}
                      textAlign={item.align}
                      field={item.field}
                      headerText={item.colName}
                      width={120}
                    />
                  )
                case SYMBOL_CLUSTER_MASTER:
                  return (
                    <ColumnDirective
                      template={getsymbolMaster}
                      filter={false}
                      textAlign={item.align}
                      field={item.field}
                      headerText={item.colName}
                      width={item.width}
                    />
                  );
                case WORKINGDAY_CLUSTER_DATE:
                case HOLIDAY_CLUSTER_DATE:
                case STATUTORY_CLUSTER_STATUTORY_DATE:
                  return (
                    <ColumnDirective
                      template={dateFormatForUi1}
                      filter={date}
                      textAlign={item.align}
                      field={item.field}
                      headerText={item.colName}
                      width={item.width}
                    />
                  );
                case WORKINGDAY_CLUSTER_TYPE:
                case STATUTORY_CLUSTER_STATUTORY_TYPE:
                case EXCEPTIONAL_CLUSTER_EXCEPTIONAL_TYPE:
                case ATTENDANCE_CLUSTER_ATTENDANCE_TYPE:
                case WEEKDAY_CLUSTER_WEEKEND_TYPE:
                  return (
                    <ColumnDirective
                      template={getElementInCenter}
                      filter={false}
                      textAlign={item.align}
                      field={item.field}
                      headerText={item.colName}
                      width={item.width}
                    />
                  );
                case WORKINGDAY_CLUSTER_DAY_BREAK:
                case STATUTORY_CLUSTER_STATUTORY_DAY_BREAK:
                case EXCEPTIONAL_CLUSTER_EXCEPTIONAL_DAY_BREAK:
                  return (
                    <ColumnDirective
                      template={getdayBreakElementInCenter}
                      filter={false}
                      textAlign={item.align}
                      field={item.field}
                      headerText={item.colName}
                      width={item.width}
                    />
                  );
                case EXCEPTIONAL_CLUSTER_EXCEPTIONAL_SYMBOL:
                case ATTENDANCE_CLUSTER_ATTENDANCE_SYMBOL:
                case STATUTORY_CLUSTER_STATUTORY_SYMBOL:
                case LEAVE_CLUSTER_SYMBOL:
                case WEEKDAY_CLUSTER_SYMBOL:
                case DAY_BREAK_CLUSTER_SYMBOL:
                case SOURCE_CLUSTER_SYMBOL:
                  return (
                    <ColumnDirective
                      template={getSymbolElementInCenterE}
                      filter={false}
                      textAlign={item.align}
                      field={item.field}
                      headerText={item.colName}
                      width={item.width}
                    />
                  );
                case ATTENDANCE_CLUSTER_ATTENDANCE_ATTACH_NOTE:
                case EXCEPTIONAL_CLUSTER_EXCEPTIONAL_ATTACH_NOTE:
                case POLICY_DOC:
                  return (
                    <ColumnDirective
                      template={getFileNameElementInCenter}
                      filter={false}
                      textAlign={item.align}
                      field={item.field}
                      headerText={item.colName}
                      width={item.width}
                    />
                  );
                case RESET_DATE:
                  return (
                    <ColumnDirective
                      filter={resetDate}
                      textAlign={item.align}
                      field={item.field}
                      headerText={item.colName}
                      width={item.width}
                      template={dateFormatForUi2}
                    />
                  );
                case LEAVE_YEAR_START_DATE:
                case SOURCE_CLUSTER_EFFECTIVE_START_DATE:
                case EFFECTIVE_START_DATE:
                  return (
                    <ColumnDirective
                      template={startDateTemplate}
                      filter={dateOfStart}
                      textAlign={item.align}
                      field={item.field}
                      headerText={item.colName}
                      width={item.width}
                    />
                  );
                case LEAVE_YEAR_END_DATE:
                case SOURCE_CLUSTER_EFFECTIVE_END_DATE:
                case EFFECTIVE_END_DATE:
                  return (
                    <ColumnDirective
                      template={endDateTemplate}
                      filter={dateOfEnd}
                      textAlign={item.align}
                      field={item.field}
                      headerText={item.colName}
                      width={item.width}
                    />
                  );
                case WORKINGDAY_CLUSTER_START_TIME:
                case WORKDAY_WINDOW_START_TIME:
                  return (
                    <ColumnDirective
                      template={getstartTimeFormat}
                      filter={startTime}
                      textAlign={item.align}
                      field={item.field}
                      headerText={item.colName}
                      width={item.width}
                    />
                  );
                case WORKINGDAY_CLUSTER_END_TIME:
                case WORKDAY_WINDOW_END_TIME:
                  return (
                    <ColumnDirective
                      template={getendTimeFormat}
                      filter={endTime}
                      textAlign={item.align}
                      field={item.field}
                      headerText={item.colName}
                      width={item.width}
                    // width={60}
                    />
                  );
                case WORKDAY_WINDOW_WEEK_HRS:
                  return (
                    <ColumnDirective
                      template={weeklyHours}
                      filter={false}
                      textAlign={item.align}
                      field={item.field}
                      headerText={item.colName}
                      width={item.width}
                    />
                  );

                case WORKINGDAY_CLUSTER_MIN_HRS:
                  return (
                    <ColumnDirective
                      template={getminHrsFormat}
                      filter={minHrs}
                      textAlign={item.align}
                      field={item.field}
                      headerText={item.colName}
                      width={item.width}
                    // width={60}
                    />
                  );
                case WORKDAY_WINDOW_GRACE_TIME:
                case WORKINGDAY_DAYBREAK_GRACE_TIME:
                  return (
                    <ColumnDirective
                      template={getGraceTimeFormat}
                      filter={false}
                      textAlign={item.align}
                      field={item.field}
                      headerText={item.colName}
                      width={item.width}
                    />
                  );
                case WORKDAY_WINDOW_NUMBER_OF_INSTANCE:
                  return (
                    <ColumnDirective
                      template={getnoOfInstanceFormat}
                      filter={false}
                      textAlign={item.align}
                      field={item.field}
                      headerText={item.colName}
                      width={item.width}
                    />
                  );
                case WORKDAY_WINDOW_FREQUENCY:
                case LEAVE_CLUSTER_ADD_FREQUENCY:
                  return (
                    <ColumnDirective
                      template={getWindowFrequencyFormat}
                      filter={false}
                      textAlign={item.align}
                      field={item.field}
                      headerText={item.colName}
                      width={item.width}
                    />
                  );
                case WORKDAY_WINDOW_MIN_HRS:
                  return (
                    <ColumnDirective
                      template={getTimeFormatForLnaMAster}
                      filter={minHrs}
                      textAlign={item.align}
                      field={item.field}
                      headerText={item.colName}
                      width={item.width}
                    />
                  );
                case WORKDAY_WINDOW_WORK_HRS:
                case WORKINGDAY_DAYBREAK_WORK_HRS:
                  return (
                    <ColumnDirective
                      template={getWorkHrsFormat}
                      filter={workHrs}
                      textAlign={item.align}
                      field={item.field}
                      headerText={item.colName}
                      width={item.width}
                    />
                  );
                case WORKDAY_WINDOW_SYMBOL:
                  return (
                    <ColumnDirective
                      template={getsymbolFormat}
                      filter={false}
                      textAlign={item.align}
                      field={item.field}
                      headerText={item.colName}
                      width={item.width}
                    />
                  );
                case LOCK_UNLOCK_ATTENDANCE_MONTH:
                  return (
                    <ColumnDirective
                      template={attendanceMonthTemplate}
                      // filter={format(new Date(dateOfStart), 'MMM-yyyy')}
                      // filter={dateOfStart}
                      textAlign={item.align}
                      field={item.field}
                      headerText={item.colName}
                      width={item.width}
                    />
                  );
                case LOCK_UNLOCK_LOCK_END_DATE:
                  return (
                    <ColumnDirective
                      template={lockEndDateTemplate}
                      // filter={dateOfStart}
                      textAlign={item.align}
                      field={item.field}
                      headerText={item.colName}
                      width={item.width}
                    />
                  );

                default:
                  return (
                    <ColumnDirective
                      field={item.field}
                      width={item.width}
                      headerText={item.colName}
                      textAlign={item.align}
                    />
                  );
              }
            })}
          </ColumnsDirective>
          <Inject services={[Page, DetailRow, Filter, Freeze]} />
        </GridComponent>}
        {!(state && state.lnaMasterData && state.lnaMasterData.length > 0) && !initialLoad &&
          <div className='ratingwelcomesubtext pt-xs'>
            {"No data to display."}
          </div>
        }
        <div className="loader-new" id="spinnerContainer">
          {initialLoad && <Progressbar />}
        </div>
        <ConfirmationPopup
          open={showPopup.status}
          headerMessage={""}
          subMessage={showPopup.action == "Active" ? 'Are you sure to change the status from Inactive to "Active"?' : 'Are you sure to change the status from Active to "Inactive"?'}
          button1function={() => { setPopup((preState) => ({ ...preState, status: false })); props.updateMasterItemStatus(actionData) }}
          button1Text={"Yes"}
          button2function={() => setPopup((preState) => ({ ...preState, status: false }))}
          button2Text={"No"}
          showButton={{ type: "1" }}
          handleConfirmClose={() => setPopup((preState) => ({ ...preState, status: false }))}
        />
        <ConfirmationPopup
          open={showMessage.display}
          headerMessage={"Restricted"}
          subMessage={showMessage.action}
          button1Text={"Close"}
          button1function={() => { setMessage((preState) => ({ ...preState, display: false })) }}
          button2Text={''}
          showButton={{ type: "1" }}
          handleConfirmClose={() => setPopup((preState) => ({ ...preState, status: false }))}
        />
      </div>
    </div>
  );
};

const mapStateToProps = ({ auth, meetingmaster, lnareducer }) => {
  const { authUser } = auth;
  const { currentFinancialYearId, leaveYearId } = meetingmaster;
  const { lnaMasterFlag } = lnareducer;
  return {
    authUser,
    currentFinancialYearId,
    leaveYearId,
    lnaMasterFlag
  };
};
export default connect(mapStateToProps, {
  updateLnAData,
  updateMasterItemStatus,
})(LNAMasterSetup);
