/**
 * Author: Abdulla Shaikh.
 * Created:22-Feb-2023
 * @description to view all HelpDesk Top ticket
 */
import React, { useEffect, useRef, useState } from 'react';
import { getIdeas, getTopTickets } from '../../services/helpdesk/helpdeskServices';
import "./helpdeskservice.css";
import ReactHtmlParser from 'react-html-parser'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "@material-ui/core";
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import SearchBoxTwoComponent from '../../utilities/searchboxtwocomponent';
import { dencrypt } from '../../app/other/commonfunction';
import { connect } from 'react-redux';
import { faChevronRight } from '@fortawesome/pro-light-svg-icons';
import ConfirmationPopup from '../../utilities/confirmationpopup'
import { topTicketConfirmationBtnMsg } from '../../assets/constants/constants';
import { helpdeskReduxUpdate, addUpdateHelpDeskRedux } from '../../redux/actions/helpdesk/helpdeskaction';
import ProgressBar from '../progressbar/progressbar';
import { useClickOutside } from '../../hooks';

function TopTickets(props) {
  let buttonElement;
  let tooltipInstance;
  const [topTicketList, setTopTicketList] = useState([])
  const [searchText, setSearchText] = useState([])
  const [iconColor, setIconColor] = useState(-1)
  const [containerHeight, setContainerHeight] = useState(null)
  const [ideas, setIdeas] = useState([])
  const [selectedTicket, setSelectedTicket] = useState({})
  const [openPopup, setOpenPopup] = useState(false)
  const [loading, setLoading] = useState(false)
  let topTicketDetails = useRef(null);
  useClickOutside(topTicketDetails, () => {
    if (iconColor !== -1) setIconColor(-1);
  });
  useEffect(() => {
    if (props.topTicketData.length === 0) {
      if (props.type == "service") getTopTicket();
    } else {
      setLoading(true);
      setTimeout(() => {
        setTopTicketList(props.topTicketData);
      }, 100)
      setLoading(false);
    }
    if (props.type == "idea") getIdeasData();
  }, [])

  useEffect(() => {
    if (props.type == "idea" && props.submitNewRequestStatus === 'updated') getIdeasData();
  }, [props.submitNewRequestStatus])

  useEffect(() => {
    window.addEventListener("resize", updateDimensions()); // NOSONAR
  }, []);
  useEffect(() => {
    searchTopTicket(props.functionName ?? "");
  }, [props.functionName]);

  const isThirteenHundred = window.innerHeight < 650;
  const isOneTwentyFiveInSixteenEighty = window.innerHeight < 723;
  const isOneTwentyFiveScale = window.innerHeight < 747;
  const isFourteenHundred = window.innerHeight < 782 && window.innerWidth < 1441;
  const isSixteenHundred = window.innerHeight < 782;
  const isSixteenEighty = window.innerHeight < 932;

  const updateDimensions = () => {
    const minusHeight = isThirteenHundred ? 284 : isFourteenHundred ? 295 : 295 // NOSONAR
    setContainerHeight(window.innerHeight - minusHeight)
  }

  const getIdeasData = async () => {
    try {
      const ideasRes = await getIdeas(dencrypt(localStorage.getItem('user_id')))
      setIdeas(ideasRes.sort((a, b) => parseInt(b.masterItemId) - parseInt(a.masterItemId)));
    } catch (err) {
      console.log("🚀 ~ file: toptickets.jsx:63 ~ getTopTicket ~ err:", err)
    }
  }

  const getTopTicket = async () => {
    try {
      const topTicket = await getTopTickets()
      props.helpdeskReduxUpdate({ topTicketData: topTicket })
      setTopTicketList(topTicket);
    } catch (err) {
      console.log("🚀 ~ file: toptickets.jsx:63 ~ getTopTicket ~ err:", err)
    }
  }
  function handleClick(index) {
    if (index == iconColor) {
      setIconColor(-1)
    } else {
      setIconColor(index)
    }
    try {
      if (buttonElement.getAttribute('data-tooltip-id')) {
        tooltipInstance.close();
      }
      else {
        tooltipInstance.open(buttonElement);
      }
    } catch (error) {

    }
  }
  function viewMore(resolution) {
    props.changePopUpHandler(resolution)
    setIconColor(-1)
  }
  const content = (data) => {
    return (
      <div className='tooltip-content circular-regular'>
        {ReactHtmlParser(data && data.Resolution ? data.Resolution : null)}
        <div className="col-xs-12 col-sm-12 col-lg-12 mt-sm mb-sm circular-regular" style={{ display: "flex", justifyContent: "end", paddingRight: "0px " }} >
          {
            data.Resolution.length > 2500 &&
            <Button style={{ paddingRight: "0px " }} className='btn-more circular-regular' value="Click" onClick={() => viewMore(data.Resolution)}>View More
              <FontAwesomeIcon
                icon={faChevronRight}
                style={{ marginLeft: '0.3125rem', color: '#F94F5E' }}
                size="sm"
                />
            </Button>
          }
        </div>
      </div>
    );
  }
  const searchTopTicket = (value) => {
    try {
      setSearchText(value)
      setTimeout(() => {
        let topTickets = props.topTicketData.filter(ticket => {//NOSONAR
          if (value === "") {
            return ticket;//NOSONAR
          } else if ((ticket.TicketDesc.toLowerCase().includes(value.toLowerCase())) || (ticket.TicketSub.toLowerCase().includes(value.toLowerCase())) || (ticket.FunctioName.toLowerCase().includes(value.toLowerCase()))) {//NOSONAR
            return ticket;//NOSONAR
          }
        });
        setTopTicketList(topTickets);
      }, 200)
    } catch (error) {
      console.log("🚀 ~ file: toptickets.jsx:106 ~ searchTopTicket ~ error:", error)
    }
  }

  const scrollEnable = () => {
    if (isThirteenHundred) {
      return topTicketList?.length > 11
    } else if (isOneTwentyFiveInSixteenEighty) {
      return topTicketList?.length > 13
    } else if (isOneTwentyFiveScale) {
      return topTicketList?.length > 13
    } else if (isFourteenHundred) {
      return topTicketList?.length > 15
    } else if (isSixteenHundred) {
      return topTicketList?.length > 14
    } else if (isSixteenEighty) {
      return topTicketList?.length > 18
    } else {
      return topTicketList?.length > 19
    }
  }

  const paddingEnable = `
    .tableFixHead thead {
      border-right: 16px solid #f2f2f2 !important;
    }
  `;
  const openPopupFunc = (data) => {
    if (props.isRequestFormDataAdded) {
      setOpenPopup(true);
      setSelectedTicket(data)
    } else {
      props.selectedTicketData(data);
      props.addUpdateHelpDeskRedux({ isRequestFormDataAdded: true })
    }
  }
  const onSubmitCancel = () => {
    setOpenPopup(false);
    setSelectedTicket({})
  }
  const onSubmitYes = () => {
    props.selectedTicketData(selectedTicket);
    props.addUpdateHelpDeskRedux({ isRequestFormDataAdded: true })
    setOpenPopup(false);
  }
  return (
    <>
      <table className="table-hover tableFixHead" id='top-ticket-table'>
        <style>{scrollEnable() ? paddingEnable : null}</style>
        <thead>
          <tr>
            <th className="pl-md pt-sm pb-sm" scope="col" colSpan="2" style={{ paddingBottom: "0px", borderBottom: props.type === 'idea' ? '1px solid #e1e1e1' : '' }}>
              {props.type == "idea" ? <div className="table-title" style={{ margin: "5px" }} >{props.type == "idea" ? "My Ideas" : "Top Service Requests"}
              </div> :
                <div style={{ display: "flex" }}>
                  <div className="table-title" style={{ flex: "31%", paddingTop: "7px" }}>{props.type == "idea" ? "My Ideas" : "Top Service Requests"}
                  </div>
                  <div className="input-icons " style={{ flex: "69%" }}>
                    <SearchBoxTwoComponent cssClass="input-field" id={'top-ticket-search-box-id'} type="text" placeholder="Search" value={searchText} onChange={(e) => searchTopTicket(e.target.value)} />
                  </div>
                </div>
              }
            </th>
          </tr>
        </thead>
        <tbody style={{ height: containerHeight }} className='helpdesk-modal-scroll'>
          {props.type == "service" &&
            <tr style={{ borderTop: "1px solid #dbdbdd", borderBottom: "1px solid #dbdbdd", backgroundColor: "#F2F2F2" }}>
              <td className='table-sub-title pl-md pt-sm pb-sm' style={{ width: "43% " }}>Function</td>
              <td className='table-sub-title pl-md pt-sm pb-sm' style={{ width: "100% " }}>Subject</td>
            </tr>
          }
          {props.type == "idea" && ideas.map((data, index) => (
            <tr key={data.subjectName} className={index % 2 == 0 ? "table-even" : "table-odd"}>
              <td className="pl-md pt-sm pb-sm">{data.subjectName}</td>
            </tr>
          ))}
          {props.type == "service" && topTicketList?.map((item, index) => (
            <tr key={item.TopticketId} className={index % 2 == 0 ? "table-even" : "table-odd"}>
              <td className="pl-md pt-xs pb-xs" style={{ width: "30% " }}>
                <span style={{ cursor: 'pointer' }}
                  onClick={() => openPopupFunc(item)}
                >
                  {item.FunctioName}
                </span>
              </td>
              <td className="pl-md pt-xs pb-xs pr-sm" style={{ display: "flex", justifyContent: "flex-start", width: "100%" }}>

                <div id="topTicketToolTip" >
                  {item && <FontAwesomeIcon id={`box${index}`} ref={d => buttonElement = d} style={iconColor == index ? { color: "#F94F5E" } : { color: '#939399' }} icon={faInfoCircle} className="tooltip-info-icon" onClick={() => handleClick(index)} value="Click" />}
                  {iconColor === index &&
                    <div
                      id='top-ticket-details'
                      ref={topTicketDetails}
                      className='ticket-details'
                    >
                      {(() => content(item))()}
                    </div>}

                </div>
                <div style={{ cursor: 'pointer', marginLeft: "10px" }}
                  onClick={() => openPopupFunc(item)}
                >
                  {item.TicketSub}
                </div>
              </td>
            </tr>
          ))
          }
        </tbody>

      </table>
      {openPopup && (
        <ConfirmationPopup
          headerMessage={topTicketConfirmationBtnMsg}
          subMessage={""}
          button1Text={"Yes"}
          button2Text={"No"}
          button2function={() => onSubmitCancel()}
          button1function={() => onSubmitYes()}
          showButton={{ type: "1" }}
          open={openPopup}

          handleConfirmClose={onSubmitCancel}
        />
      )}

      {loading && <div style={{ position: 'absolute' }}>
        <ProgressBar />
      </div>
      }
    </>
  )
}

const mapStateToProps = ({ helpdeskreducer }) => {
  const { submitNewRequestStatus, topTicketData, isRequestFormDataAdded } = helpdeskreducer;
  return {
    submitNewRequestStatus, topTicketData, isRequestFormDataAdded
  }
}
export default connect(mapStateToProps, { helpdeskReduxUpdate, addUpdateHelpDeskRedux })(TopTickets)
