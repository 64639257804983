/**
 * Author: Vivek Khodade.
 * Created:06-Jun-2020
 * Last Modify:06-Jun-2020
 * Modified By:Vivek Khodade
 */

import axios from "axios";
import { urlConfig } from "../../config/config";

function errorHandler(reject, error) {
  // eslint-disable-next-line
  console.dir(error);
  reject("operation failed");
}

function returnResponse(response) {
  if (response != null) {
    return response.data;
  }
}
/**
 * Author:Vivek Khodade  * 
 * @param {*} userId 
 * @param {*} userId 
 * @param {*} type 
 * @description get the resignation master data
 */
function getEmployeeResignationMaster(userId, type) {
  return new Promise(function onThen(resolve, reject) {
    axios
      .get(`${urlConfig.tmlcApiUrl}separation/master/${type}/${userId}`, {
        headers: {
          authorization: localStorage.getItem("jwtToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      // eslint-disable-next-line
      .then((response) => {
        return resolve(returnResponse(response));
      })
      .catch((error) => {
        errorHandler(reject, error.response.data.message);
        return null;
      });
  });
}
/**
 * Author:Vivek Khodade  * 
 * @param {*} userId 
 * @param {*} type 
 * @description get the resignation data.
 */
function getEmployeeClearanceData(type, userId, empId) {
  return new Promise(function onThen(resolve, reject) {
    axios
      .get(`${urlConfig.tmlcApiUrl}separation/clearance/${type}/${userId}/${empId}`, {
        headers: {
          authorization: localStorage.getItem("jwtToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      // eslint-disable-next-line
      .then((response) => {
        return resolve(returnResponse(response));
      })
      .catch((error) => {
        errorHandler(reject, error.response.data.message);
        return null;
      });
  });
}
/**
 * Author:Vivek Khodade  * 
 * @param {*} userId 
 * @param {*} type 
 * @description get the resignation data.
 */
function getEmployeeResignationData(userId, type) {
  return new Promise(function onThen(resolve, reject) {
    axios
      .get(`${urlConfig.tmlcApiUrl}separation/resign/${type}/${userId}`, {
        headers: {
          authorization: localStorage.getItem("jwtToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      // eslint-disable-next-line
      .then((response) => {
        if (response != null) {
          return resolve(response.data);
        }
      })
      .catch((error) => {
        errorHandler(reject, error.response.data.message);
        return null;
      });
  });
}
/**
 * Author : Prashant Waphare | 09-06-2020
 * @param {*} userId 
 * @param {*} type 
 */
function getResignationDetail(userId, type) {
  return new Promise(function onThen(resolve, reject) {
    axios
      .get(`${urlConfig.tmlcApiUrl}separation/resign/${type}/${userId}`, {
        headers: {
          authorization: localStorage.getItem("jwtToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      // eslint-disable-next-line
      .then((response) => {
        return resolve(returnResponse(response));
      })
      .catch((error) => {
        errorHandler(reject, error.response.data.message);
        return null;
      });
  });
}
/**
 * Author : Prashant Waphare | 09-06-2020
 * @param {*} userId 
 * @param {*} type 
 */
function getWithdrawalDetail(userId, type) {
  return new Promise(function onThen(resolve, reject) {
    axios
      .get(`${urlConfig.tmlcApiUrl}separation/withdrawl/${type}/${userId}`, {
        headers: {
          authorization: localStorage.getItem("jwtToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      // eslint-disable-next-line
      .then((response) => {
        return resolve(returnResponse(response));
      })
      .catch((error) => {
        errorHandler(reject, error.response.data.message);
        return null;
      });
  });
}
/**
 * Author : Prashant Waphare | 16-06-2020 | Get clearance List
 * @param {*} userId 
 * @param {*} type 
 */
function getClearanceDetail(userId, type) {
  return new Promise(function onThen(resolve, reject) {
    axios
      .get(`${urlConfig.tmlcApiUrl}separation/clearance/${type}/${userId}`, {
        headers: {
          authorization: localStorage.getItem("jwtToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      // eslint-disable-next-line
      .then((response) => {
        return resolve(returnResponse(response));
      })
      .catch((error) => {
        errorHandler(reject, error.response.data.message);
        return null;
      });
  });
}
/**
 * Author : Prashant Waphare | 17-06-2020 | Get separatioon absconding List
 * @param {*} userId 
 * @param {*} type 
 */
function getAbscondingListDetail(userId, type, optional) {
  return new Promise(function onThen(resolve, reject) {
    let apiPath = "";
    if (optional !== undefined) {
      apiPath = `${urlConfig.tmlcApiUrl}separation/absconding/${type}/${userId}/${optional}`
    } else {
      apiPath = `${urlConfig.tmlcApiUrl}separation/absconding/${type}/${userId}`
    }
    axios
      .get(apiPath, {
        headers: {
          authorization: localStorage.getItem("jwtToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      // eslint-disable-next-line
      .then((response) => {
        return resolve(returnResponse(response));
      })
      .catch((error) => {
        errorHandler(reject, error.response.data.message);
        return null;
      });
  });
}
/**
 * Author : Prashant Waphare | 19-06-2020 | Get Termnation employee List
 * @param {*} userId 
 * @param {*} type 
 */
function getTerminationListData(userId, type) {
  return new Promise(function onThen(resolve, reject) {
    axios
      .get(`${urlConfig.tmlcApiUrl}separation/termination/${type}/${userId}`, {
        headers: {
          authorization: localStorage.getItem("jwtToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      // eslint-disable-next-line
      .then((response) => {
        return resolve(returnResponse(response));
      })
      .catch((error) => {
        errorHandler(reject, error.response.data.message);
        return null;
      });
  });
}
/**
 * Author : Vinayak Jadhav | 21-07-2020 | Get Termnation Committee member List
 * @param {*} terminationId
 * @param {*} type 
 */
function getTerminationCommitteeMembersData(userId, type, terminationId) {
  return new Promise(function onThen(resolve, reject) {
    axios
      .get(`${urlConfig.tmlcApiUrl}separation/termination/${type}/${userId}/${terminationId}`, {
        headers: {
          authorization: localStorage.getItem("jwtToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      // eslint-disable-next-line
      .then((response) => {
        return resolve(returnResponse(response));
      })
      .catch((error) => {
        errorHandler(reject, error.response.data.message);
        return null;
      });
  });
}
function getExitDocumentFile(userId, type, file) {
  return new Promise(function onThen(resolve, reject) {
    axios
      .get(`${urlConfig.tmlcApiUrl}document/${type}/${userId}/${file}`, {
        headers: {
          authorization: localStorage.getItem("jwtToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      // eslint-disable-next-line
      .then((response) => {
        if (response != null) {
          return resolve(response.config.url);
        }
      })
      .catch((error) => {
        errorHandler(reject, error.response.data.message);
        return null;
      });
  });
}
/**
 * 
 * @param {*} userId 
 */
function getEmployeeList(userId) {
  return new Promise(function onThen(resolve, reject) {
    axios
      .get(`${urlConfig.tmlcApiUrl}employee/${userId}`, {
        headers: {
          authorization: localStorage.getItem("jwtToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      // eslint-disable-next-line
      .then((response) => {
        return resolve(returnResponse(response));
      })
      .catch((error) => {
        errorHandler(reject, error.response.data.message);
        return null;
      });
  });
}
/**
 * Author :- Prashant Waphare | 30-09-2020 | Get active employee and left Employee list
 * @param {*} param 
 */
function getActiveLeftEmpList(param) {
  const authUser = param.authUser;
  const type = param.type;
  const empId = typeof param.empId === 'undefined' || param.empId === null ? 0 : param.empId;

  if (empId || empId === 0) {
    return new Promise(function onThen(resolve, reject) {
      axios
        .get(`${urlConfig.tmlcApiUrl}activeleftemplist/${authUser}/${empId}/${type}`, {
          headers: {
            authorization: localStorage.getItem("jwtToken"),
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        // eslint-disable-next-line
        .then((response) => {
          return resolve(returnResponse(response));
        })
        .catch((error) => {
          errorHandler(reject, error.response.data.message);
          return null;
        });
    })
  } else {
    return
  }
}
const separetionServices = {
  getEmployeeResignationMaster: getEmployeeResignationMaster,
  getEmployeeResignationData: getEmployeeResignationData,
  getResignationDetail: getResignationDetail,
  getWithdrawalDetail: getWithdrawalDetail,
  getClearanceDetail: getClearanceDetail,
  getAbscondingListDetail: getAbscondingListDetail,
  getTerminationListData: getTerminationListData,
  getTerminationCommitteeMembersData: getTerminationCommitteeMembersData,
  getEmployeeClearanceData: getEmployeeClearanceData,
  getExitDocumentFile: getExitDocumentFile,
  getEmployeeList: getEmployeeList,
  getActiveLeftEmpList: getActiveLeftEmpList,
};

export default separetionServices;
