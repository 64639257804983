import * as React from "react"
import {
  getBencharkDisplayDateOnly,
  getDisplayDateOnly,
} from "../../app/other/commonfunction";
import { urlConfig } from "../../config/config"

export function AllDateFormat(props) {
  const { date } = props;
  if (date !== null) {
    return (
      <div style={{ textAlign: "center" }}>
        <span>{getDisplayDateOnly(new Date(date))}</span>
      </div>
    );
  } else {
    return (
      <div></div>
    )
  }
}

export function AllFullDateFormat(props) {
  const { date } = props;

  if (date !== null) {    
    return (
      <div style={{ textAlign: "center" }}>
        <span>{getBencharkDisplayDateOnly(new Date(date))}</span>
      </div>
    );
  } else {
    return (
      <div></div>
    )
  }
}
export function AllDateFormatOrgChart(props) {
  const { date } = props;
  if (date !== null) {
    return (
      <span>{getDisplayDateOnly(new Date(date))}</span>
    );
  } else {
    return (
      <span></span>
    )
  }

}
export function ActingActualStatus(props) {
  const { actingActualstatus, groupHeadName } = props;
  if (actingActualstatus === '(Acting)') {
    return (
      <div style={{ color: "#9c9696" }}>
        <span>{groupHeadName}</span>
      </div>
    );
  } else {
    return (
      <div>{groupHeadName}</div>
    )
  }

}//NOSONAR
export function infosecTemp(props) {//NOSONAR
  const {
    infoSecAssetsStatus,
    handOverInfosecAssets,
    totalInfoSecAssets,
  } = props;
  if (infoSecAssetsStatus === "Completed") {
    return (
      <div style={{ borderLeft: "3px solid green", paddingLeft: "5px" }}>
        <span id="">{`${handOverInfosecAssets}/${totalInfoSecAssets}`}</span>
        <br />
        <span id="">{infoSecAssetsStatus}</span>
      </div>
    );
  }
  if (infoSecAssetsStatus === "Pending") {
    return (
      <div style={{ borderLeft: "3px solid gray", paddingLeft: "5px" }}>
        <span id="">{`${handOverInfosecAssets}/${totalInfoSecAssets}`}</span>
        <br />
        <span id="">{infoSecAssetsStatus}</span>
      </div>
    );
  }
  if (infoSecAssetsStatus === "Partial") {
    return (
      <div style={{ borderLeft: "3px solid #f68104", paddingLeft: "5px" }}>
        <span id="">{`${handOverInfosecAssets}/${totalInfoSecAssets}`}</span>
        <br />
        <span id="">{infoSecAssetsStatus}</span>
      </div>
    );
  }
  return false;
}
//NOSONAR
export function itTemp(props) {//NOSONAR
  const {
    itAssetsStatus,
    totalITAssets,
    handOverITAssets,
  } = props;
  if (itAssetsStatus === "Completed") {
    return (
      <div style={{ borderLeft: "3px solid green", paddingLeft: "5px" }}>
        <span id="">{`${handOverITAssets}/${totalITAssets}`}</span>
        <br />
        <span id="">{itAssetsStatus}</span>
      </div>
    );
  }
  if (itAssetsStatus === "Pending") {
    return (
      <div style={{ borderLeft: "3px solid gray", paddingLeft: "5px" }}>
        <span id="">{`${handOverITAssets}/${totalITAssets}`}</span>
        <br />
        <span id="">{itAssetsStatus}</span>
      </div>
    );
  }
  if (itAssetsStatus === "Partial") {
    return (
      <div style={{ borderLeft: "3px solid #f68104", paddingLeft: "5px" }}>
        <span id="">{`${handOverITAssets}/${totalITAssets}`}</span>
        <br />
        <span id="">{itAssetsStatus}</span>
      </div>
    );
  }
  return false;
}
//NOSONAR
export function adminTemp(props) {//NOSONAR
  const {
    handOverAdminAssets,
    totalAdminAssets,
    adminAssetsStatus,
  } = props;
  if (adminAssetsStatus === "Completed") {
    return (
      <div style={{ borderLeft: "3px solid green", paddingLeft: "5px" }}>
        <span id="">{`${handOverAdminAssets}/${totalAdminAssets}`}</span>
        <br />
        <span id="">{adminAssetsStatus}</span>
      </div>
    );
  }
  if (adminAssetsStatus === "Pending") {
    return (
      <div style={{ borderLeft: "3px solid gray", paddingLeft: "5px" }}>
        <span id="">{`${handOverAdminAssets}/${totalAdminAssets}`}</span>
        <br />
        <span id="">{adminAssetsStatus}</span>
      </div>
    );
  }
  if (adminAssetsStatus === "Partial") {
    return (
      <div style={{ borderLeft: "3px solid #f68104", paddingLeft: "5px" }}>
        <span id="">{`${handOverAdminAssets}/${totalAdminAssets}`}</span>
        <br />
        <span id="">{adminAssetsStatus}</span>
      </div>
    );
  }
  return false;
}
//NOSONAR
export function hrTemp(props) {//NOSONAR
  const {
    peopleAssetsStatus,
    handOverPeopleAssets,
    totalPeopleAssets,
  } = props;
  if (peopleAssetsStatus === "Completed") {
    return (
      <div style={{ borderLeft: "3px solid green", paddingLeft: "5px" }}>
        <span id="">{`${handOverPeopleAssets}/${totalPeopleAssets}`}</span>
        <br />
        <span id="">{peopleAssetsStatus}</span>
      </div>
    );
  }
  if (peopleAssetsStatus === "Pending") {
    return (
      <div style={{ borderLeft: "3px solid gray", paddingLeft: "5px" }}>
        <span id="">{`${handOverPeopleAssets}/${totalPeopleAssets}`}</span>
        <br />
        <span id="">{peopleAssetsStatus}</span>
      </div>
    );
  }
  if (peopleAssetsStatus === "Partial") {
    return (
      <div style={{ borderLeft: "3px solid #f68104", paddingLeft: "5px" }}>
        <span id="">{`${handOverPeopleAssets}/${totalPeopleAssets}`}</span>
        <br />
        <span id="">{peopleAssetsStatus}</span>
      </div>
    );
  }
  return false;
}
//NOSONAR
export function FinanceStatusTemp(props) {//NOSONAR
  const {
    financeAssetsStatus,
    handOverFinanceAssets,
    totalFinanceAssets,
  } = props;
  if (financeAssetsStatus === "Completed") {
    return (
      <div style={{ borderLeft: "3px solid green", paddingLeft: "5px" }}>
        <span id="">{`${handOverFinanceAssets}/${totalFinanceAssets}`}</span>
        <br />
        <span id="">{financeAssetsStatus}</span>
      </div>
    );
  }
  if (financeAssetsStatus === "Pending") {
    return (
      <div style={{ borderLeft: "3px solid gray", paddingLeft: "5px" }}>
        <span id="">{`${handOverFinanceAssets}/${totalFinanceAssets}`}</span>
        <br />
        <span id="">{financeAssetsStatus}</span>
      </div>
    );
  }
  if (financeAssetsStatus === "Partial") {
    return (
      <div style={{ borderLeft: "3px solid #f68104", paddingLeft: "5px" }}>
        <span id="">{`${handOverFinanceAssets}/${totalFinanceAssets}`}</span>
        <br />
        <span id="">{financeAssetsStatus}</span>
      </div>
    );
  }
  return false;
}
//NOSONAR
export function DocStatusTemp(props) {//NOSONAR
  const { status } = props;
  if (status === "Select All") {
    return <span>Select All</span>;
  }
  if (status === "Complete") {
    return (
      <div
        style={{ borderLeft: "3px solid green", padding: "7px 0px 8px 5px" }}
      >
        <span id="Trusttext">Completed</span>
        <br />
      </div>
    );
  }
  if (status === "Pending") {
    return (
      <div style={{ borderLeft: "3px solid gray", padding: "7px 0px 8px 5px" }}>
        <span>Pending</span>
      </div>
    );
  }
  if (status === "Partial") {
    return (
      <div
        style={{ borderLeft: "3px solid #f68104", padding: "7px 0px 8px 5px" }}
      >
        <span>Partial</span>
      </div>
    );
  }
  return false;
}
//NOSONAR
export function PnlStatus(props) {//NOSONAR
  const { pnlHeadStatus } = props;
  if (pnlHeadStatus === "Accepted") {
    return (
      <div
        style={{ borderLeft: "3px solid green", padding: "7px 0px 8px 5px" }}
      >
        <span id="Trusttext">Accepted</span>
        <br />
      </div>
    );
  }
  if (pnlHeadStatus === "Pending") {
    return (
      <div
        style={{ borderLeft: "3px solid gray", padding: "7px 0px 8px 5px" }}
      >
        <span>Pending</span>
      </div>
    );
  }
  if (pnlHeadStatus === "Rejected") {
    return (
      <div
        style={{ borderLeft: "3px solid red", padding: "7px 0px 8px 5px" }}
      >
        <span>Rejected</span>
      </div>
    );
  }
  return false;
}
//NOSONAR
export function HrStatus(props) {//NOSONAR
  const { hrStatus } = props;
  if (hrStatus === "Accepted") {
    return (
      <div
        style={{ borderLeft: "3px solid green", padding: "7px 0px 8px 5px" }}
      >
        <span id="Trusttext">Accepted</span>
        <br />
      </div>
    );
  }
  if (hrStatus === "Pending") {
    return (
      <div
        style={{ borderLeft: "3px solid gray", padding: "7px 0px 8px 5px" }}
      >
        <span>Pending</span>
      </div>
    );
  }
  if (hrStatus === "Rejected") {
    return (
      <div
        style={{ borderLeft: "3px solid red", padding: "7px 0px 8px 5px" }}
      >
        <span>Rejected</span>
      </div>
    );
  }
  return false;
}

export function ReportingMgrStatus(props) {//NOSONAR
  const { reportingMgrStatus } = props;
  if (reportingMgrStatus === "Accepted") {
    return (
      <div
        style={{ borderLeft: "3px solid green", padding: "7px 0px 8px 5px" }}
      >
        <span id="Trusttext">Accepted</span>
        <br />
      </div>
    );
  }
  if (reportingMgrStatus === "Pending") {
    return (
      <div
        style={{ borderLeft: "3px solid gray", padding: "7px 0px 8px 5px" }}
      >
        <span>Pending</span>
      </div>
    );
  }
  if (reportingMgrStatus === "Rejected") {
    return (
      <div
        style={{ borderLeft: "3px solid red", padding: "7px 0px 8px 5px" }}
      >
        <span>Rejected</span>
      </div>
    );
  }
  return false;
}

export function FinanceTemp(props) {//NOSONAR
  const { financeAssetsStatus } = props;
  if (financeAssetsStatus === "Completed") {
    return (
      <div
        style={{ borderLeft: "3px solid green", padding: "7px 0px 8px 5px" }}
      >
        <span id="Trusttext">Completed</span>
        <br />
      </div>
    );
  }
  if (financeAssetsStatus === "Pending") {
    return (
      <div
        style={{ borderLeft: "3px solid gray", padding: "7px 0px 8px 5px" }}
      >
        <span>Pending</span>
      </div>
    );
  }
  if (financeAssetsStatus === "Partial") {
    return (
      <div
        style={{ borderLeft: "3px solid #f68104", padding: "7px 0px 8px 5px" }}
      >
        <span>Partial</span>
      </div>
    );
  }
  return false;
}

export function ClearanceStatus(props) {//NOSONAR
  const { clearanceStatus } = props;
  if (clearanceStatus === "Completed") {
    return (
      <div
        style={{ borderLeft: "3px solid green", padding: "7px 0px 8px 5px" }}
      >
        <span id="Trusttext">Completed</span>
        <br />
      </div>
    );
  }
  if (clearanceStatus === "Pending") {
    return (
      <div
        style={{ borderLeft: "3px solid gray", padding: "7px 0px 8px 5px" }}
      >
        <span>Pending</span>
      </div>
    );
  }
  if (clearanceStatus === "Partial") {
    return (
      <div
        style={{ borderLeft: "3px solid #f68104", padding: "7px 0px 8px 5px" }}
      >
        <span>Partial</span>
      </div>
    );
  }
  return false;
}

export function SelfDepartment(props) {//NOSONAR
  const { OwnDeptAssetsStatus } = props;
  if (OwnDeptAssetsStatus === "Completed") {
    return (
      <div
        style={{ borderLeft: "3px solid green", padding: "7px 0px 8px 5px" }}
      >
        <span id="Trusttext">Completed</span>
        <br />
      </div>
    );
  }
  if (OwnDeptAssetsStatus === "Pending") {
    return (
      <div
        style={{ borderLeft: "3px solid gray", padding: "7px 0px 8px 5px" }}
      >
        <span>Pending</span>
      </div>
    );
  }
  if (OwnDeptAssetsStatus === "Partial") {
    return (
      <div
        style={{ borderLeft: "3px solid #f68104", padding: "7px 0px 8px 5px" }}
      >
        <span>Partial</span>
      </div>
    );
  }
  return false;
}

export function ItDepartment(props) {//NOSONAR
  const { itAssetsStatus } = props;
  if (itAssetsStatus === "Completed") {
    return (
      <div
        style={{ borderLeft: "3px solid green", padding: "7px 0px 8px 5px" }}
      >
        <span id="Trusttext">Completed</span>
        <br />
      </div>
    );
  }
  if (itAssetsStatus === "Pending") {
    return (
      <div
        style={{ borderLeft: "3px solid gray", padding: "7px 0px 8px 5px" }}
      >
        <span>Pending</span>
      </div>
    );
  }
  if (itAssetsStatus === "Partial") {
    return (
      <div
        style={{ borderLeft: "3px solid #f68104", padding: "7px 0px 8px 5px" }}
      >
        <span>Partial</span>
      </div>
    );
  }
  return false;
}

export function AllClearanceStatus(props) {//NOSONAR
  const {
    asset,
    totalAsset,
    assetStatus,
  } = props;
  if (assetStatus === "Completed") {
    return (
      <div style={{ borderLeft: "3px solid green", paddingLeft: "5px" }}>
        <span id="">{`${asset}/${totalAsset}`}</span>
        <br />
        <span id="">{assetStatus}</span>
      </div>
    );
  }
  if (assetStatus === "Pending") {
    return (
      <div style={{ borderLeft: "3px solid gray", paddingLeft: "5px" }}>
        <span id="">{`${asset}/${totalAsset}`}</span>
        <br />
        <span id="">{assetStatus}</span>
      </div>
    );
  }
  if (assetStatus === "Partial") {
    return (
      <div style={{ borderLeft: "3px solid #f68104", paddingLeft: "5px" }}>
        <span id="">{`${asset}/${totalAsset}`}</span>
        <br />
        <span id="">{assetStatus}</span>
      </div>
    );
  }
  return false;
}

const downloadImage = (e, fileName, path) => {
  fetch(path)
    .then(response => response.blob())
    .then(blob => {
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement('a');
      a.href = url;
      a.download = fileName;
      a.click();
    });
}




export function MasterDocumentVew(prop) {
  const { fileName, masterName } = prop;
  const path = urlConfig.lnaApiUrl + `document/lna/${masterName}/${fileName}`;
  return (
    <div style={{ display: "flex", justifyContent: "left", cursor: "pointer", }}>
      <p
        style={{ textDecoration: "none", textOverflow: 'ellipsis', whiteSpace: "nowrap", overflow: "hidden", color: '#0088cc' }}
        onClick={(e) => downloadImage(e, fileName, path)}
      >
        {fileName && fileName.substring(11, fileName.length)}
      </p>
    </div>
  );
}