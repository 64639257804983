/**
 * @author: Vivek Khodade.
 * @description This middleware use for home office functionality.
 * Created: 31-08-2020
 * Last Modify:11-May-2020
 * Modified By:Vivek Khodade
 */
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { SAVE_HOME_OFFICE_DETAILS } from "../../constant/homeoffice/homeofficeconstant";
import { urlConfig } from "../../../config/config";
import { getNotify } from "../../../app/other/commonfunction";
import { ERROR, SUCCESS } from "../../../assets/constants/constants";
import { addEditHomeOfficeDetails } from "../../actions/homeoffice/homeofficeaction";
// Author :- Vivek Khodade
const saveHomeOffinceDetailsRequest = async (data) => {
  const { userId } = data.homeOfficeData;
  // eslint-disable-next-line
  delete data.homeOfficeData.userId;
  return axios
    .post(
      `${urlConfig.tmlcApiUrl}addedithomeofficedetailemp/${userId}/${userId}`,
      data.homeOfficeData,
      {
        headers: {
          authorization: localStorage.getItem("jwtToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((result) => result)
    .catch((error) => error.response);  
};

// Author :- Vivek Khodade
function* saveHomeOffinceDetails({ payload }) {
  const homeOfficeDetails = yield call(saveHomeOffinceDetailsRequest, payload);
  if (homeOfficeDetails.status === 200) {    
    if(homeOfficeDetails.data.result[0].response === "Updated"){
      setTimeout(() => {
        getNotify(SUCCESS, "Home office details updated successfully.");
      }, 2000);
    }else if(homeOfficeDetails.data.result[0].response === "save"){
      setTimeout(() => {
        getNotify(SUCCESS, "Home office details saved successfully");
      }, 2000);
    }
    yield put(
      addEditHomeOfficeDetails({
        address1: "",
        address2: "",
        address3: "",
        address4: "",
        city: "",
        district: "",
        state: "",
        selectedcountry: 0,
        pincode: "",
        personalEmailId: "",
        name: "",
        emrgencyEmailId: "",
        contactNumber: "",
        companyMobileNumber: "",
        personalMobileNumber: "",
        secondaryMobileNumber: "",
        homeAddress: "0",
        planProvider1: "",
        brodbandSpeed1: "",
        planProvider2: "",
        brodbandSpeed2: "",
        filepath1: "",
        filepath2: "",
        preRequisitConfirmation: false,
        deskSpaceDualScreenSetUp: null,
        spaceIsolatedDistraction: null,
        plainBackgroundForVideoCall: null,
        uninterruptedElectricitySupply: null,
        homeOfficeBackup: null,
        ups: null,
        invertor: null,
        upsBrand: "",
        upsModel: "",
        upsVACapacity: "",
        upsDuration: 0,
        invertorBrand: "",
        invertorModel: "",
        invertorKVACapacity: "",
        invertorDuration: 0,
        requestForWorkDesk: null,
        requestForHoldingArm: null,
        requestForOfficeChair: null,
        declearation: false,
        radioButtonFalse: true,
        homeOfficeStatus: "updated",
        homeOfficeSuccessMessage:
          "Thanks for submitting the Home-Office Registration form.",
        internetConnectivityState: true,
        internetConnectivity: "0",
      })
    );
  } else {
    getNotify(ERROR, homeOfficeDetails.data.message.replace("payload: ", ""));
  }
}

/**
 * Function Name: generateConfirmationLetter
 * Author: Vivek Khodade.
 */
export function* saveHomeOfficeDetails() {
  yield takeEvery(SAVE_HOME_OFFICE_DETAILS, saveHomeOffinceDetails);
}

export default function* rootSaga() {
  yield all([fork(saveHomeOfficeDetails)]);
}
