import { differenceInDays, differenceInSeconds, format} from "date-fns";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { helpdeskCustomFields, helpdeskPriorityList, helpdeskTicketStatus, ticketResolvedBufferTime } from "../../assets/constants/constants";
import { addTicketUpdate, helpdeskReduxUpdate } from "../../redux/actions/helpdesk/helpdeskaction";
import { getTicketComments, getTicketCustomFields, getTicketDetails } from "../../services/helpdesk/helpdeskServices";
import ButtonComponent from "../../utilities/buttoncomponent";
import ProgressBar from "../progressbar/progressbar";
import BasicInfoHelpDesk from "./basicinfohelpdesk";
import "./helpdesk.css";
import HistoryHelpDesk from "./historyhelpdesk";

const HelpDeskPopup = (props) => {
  const { ticketId, loading, dataUpdated } = props
  const [state, setState] = useState({ comment: '', file: [], fileName: [] })
  const [showAddUpdate, setShowAddUpdate] = useState(false)
  const [ticketHistory, setTicketHistory] = useState([])
  const [ticketDetails, setTicketDetails] = useState({
    CategoryName: "",
    Subject: "",
    Priority: "",
    Body: "",
    IssueDate: "",
    AssignedToUserID: "",
    Attachments: [],
    AssigneeUserInfo: "",
    Status: "",
    FunctionName: "",
    SubCategory: "",
    ClientImpact: "",
    ResolvedDate: null,
    AssignedBy: null,
    ResolvedBy: null,
  })

  const [hight, setHight] = useState({
    containerGridHeight: null,
    windowAdjust: null,
    containerHeight: null,
    containerWidth: null,
  });

  const handleCancel = () => {
    props.changePopUpHandler("close");
  }

  const isOneTwentyFiveScale = window.innerHeight < 715;
  const isThirteenHundred = window.innerHeight < 618;

  useEffect(() => {
    window.addEventListener("resize", updateDimensions()); // NOSONAR
  }, []);

  const updateDimensions = () => {
    const windowAdjusts = window.innerHeight - 160;
    const containerGridHeight = isThirteenHundred ? hight.containerHeight - 35
      : hight.containerHeight - 40;
    const containerHeight =
      isThirteenHundred ? window.innerHeight - 95
        : isOneTwentyFiveScale ? window.innerHeight - 95
          : window.innerHeight - 93;
    const containerWidth = window.innerWidth - 190;
    setHight({
      containerGridHeight: containerGridHeight,
      containerHeight: containerHeight,
      windowAdjust: windowAdjusts,
      containerWidth: containerWidth,
    });
  };

  useEffect(() => {
    if (ticketId) {
      getData()
    }
  }, [])

  useEffect(() => {
    if (dataUpdated === true) {
      setTimeout(() => {
        handleCancel()
        props.helpdeskReduxUpdate({ dataUpdated: false, loading: false });
      }, 500);
    }
  }, [dataUpdated])

	const getData = async () => {
		props.helpdeskReduxUpdate({ loading: true });
		let [getTicketDetailsRes, customFields, ticketComments = []] = await Promise.all(
			[
				await getTicketDetails({ ticketId }),
				await getTicketCustomFields({ ticketId }),
				await getTicketComments({ ticketId }),
			]
		);
		let ClientImpact = customFields.filter(
			(item) => item.FieldID === Number(helpdeskCustomFields.ClientImpact)
		)[0]?.Value;
		let SubCategory = customFields.filter(
			(item) => item.FieldID === Number(helpdeskCustomFields.SubCategoryName)
		)[0]?.Value;
		setTicketDetails({
			...getTicketDetailsRes,
			ClientImpact,
			SubCategory,
			FunctionName: getTicketDetailsRes["CategoryName"].split("/")[0],
			CategoryName: getTicketDetailsRes["CategoryName"].split("/")[1],
		});
		const attachmentsByTicket = getTicketDetailsRes?.Attachments.reduce(
			(acc, data) => {
				let commentId = data.CommentID;
				if (acc.hasOwnProperty(commentId)) {
					return { ...acc, [commentId]: [...acc[commentId], data] };
				} else {
					return { ...acc, [commentId]: [data] };
				}
			},
			{}
		);
		ticketComments = ticketComments.map((ticket) => {
			let commentId = ticket.CommentID;
			if (attachmentsByTicket.hasOwnProperty(commentId)) {
				return { ...ticket, Attachments: attachmentsByTicket[commentId] };
			}
			return ticket;
		});
		ticketComments = ticketComments
			.filter((comment) => comment.UserID)
			.sort((a, b) => {
				if (!props.addUpdate)
					return differenceInSeconds(
						new Date(b.CommentDate),
						new Date(a.CommentDate)
					);
				return differenceInSeconds(
					new Date(a.CommentDate),
					new Date(b.CommentDate)
				);
			});
		ticketComments = ticketComments.filter((ticket) => ticket.Body !== "<!--html-->New ticket submitted via <b>API</b>");
		setTicketHistory(ticketComments);
		props.helpdeskReduxUpdate({ loading: false });
		if (props.addUpdate) {
			setShowAddUpdate(true);
		}
  }

  const postComment = async () => {
    props.helpdeskReduxUpdate({ loading: true });
    const data = {
      ticketId,
      comment: state.comment,
      file: state.file,
      fileName: state.fileName,
    }
    props.addTicketUpdate(data)
  }

  return (
		<>
			<div
				id={`ticket-form`}
				className={"helpdesk-popup-container circular-regular"}
				style={{
					right: 0,
					width: document.getElementById("helpdesk-container")?.clientWidth / 2,
					height: document.getElementById("helpdesk-container")?.clientHeight,
					zIndex: "99",
				}}
			>
				<div>
					<div className='helpdesk-header'>SR. No. {ticketId}, {ticketDetails.Subject}</div>
				</div>
				<div className={"helpdesk-modal-scroll circular-regular"}>
					<BasicInfoHelpDesk
						functionName={ticketDetails.FunctionName}
						category={ticketDetails.CategoryName}
						subCategory={ticketDetails.SubCategory}
						subject={ticketDetails.Subject}
						priority={helpdeskPriorityList[ticketDetails.Priority]}
						clientImpact={ticketDetails.ClientImpact}
						description={ticketDetails.Body}
						assignedBy={ticketDetails.AssignedBy} //#
						assignedTo={ticketDetails.AssigneeUserInfo?.FullName}
						resolvedBy={ticketDetails.ResolvedBy} //#
						resolvedOn={
							ticketDetails.ResolvedDate !== null
								? format(
										new Date(ticketDetails.ResolvedDate),
										"dd-LLL-yyyy, hh:mm a"
								  )
								: "-"
						}
						status={ticketDetails.Status}
						attachments={ticketDetails.Attachments}
					/>
					<HistoryHelpDesk
						showAddUpdate={showAddUpdate}
						comments={ticketHistory}
						state={state}
						setState={setState}
					/>
				</div>
				<div className='col-sm-12 col-md-12 col-lg-12 p-md helpdesk-footer'>
					<div>
						<ButtonComponent
							id='helpdeskcancel'
							style={{ width: "114px" }}
							content='Cancel'
							variant='contained'
							click={handleCancel}
							cssClass={"circular-regular"}
						/>
						{showAddUpdate ? (
							<ButtonComponent
								id='helpdesksubmit'
								type='primary'
								style={{ width: "114px", borderRadius: 0 }}
								content='Submit'
								variant='contained'
								click={postComment}
								cssClass={"circular-regular"}
							/>
						) : (
							<ButtonComponent
								id='helpdesksubmit'
								type='primary'
								style={{ width: "114px", borderRadius: 0 }}
								content='Add Update'
								variant='contained'
								click={() => {
									setShowAddUpdate(true);
								}}
								cssClass={
									ticketDetails.Status === helpdeskTicketStatus.Closed &&
									differenceInDays(
										new Date(),
										new Date(ticketDetails.ResolvedDate)
									) > ticketResolvedBufferTime
										? "circular-regular btn-disabled "
										: "circular-regular"
								}
								disabled={
									ticketDetails.Status === helpdeskTicketStatus.Closed &&
									differenceInDays(
										new Date(),
										new Date(ticketDetails.ResolvedDate)
									) > ticketResolvedBufferTime
								}
							/>
						)}
					</div>
				</div>
				{loading && <ProgressBar />}
			</div>
			</>
	);
}

const mapStateToProps = ({ auth, meetingcategory, meetingmaster, helpdeskreducer }) => {
  const { authUser } = auth;
  const { categoryName } = meetingcategory;
  const { financialYearData } = meetingmaster;
  const { loading, dataUpdated } = helpdeskreducer;
  return {
    categoryName,
    financialYearData,
    authUser,
    loading, 
    dataUpdated
  };
};

export default connect(mapStateToProps, { addTicketUpdate, helpdeskReduxUpdate })(HelpDeskPopup);
