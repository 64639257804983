import React from "react";
import PropTypes from "prop-types";
import { ComboBoxComponent } from "@syncfusion/ej2-react-dropdowns";

/**
 * The ComboBox component allows the user to type  a value or choose an option from the list of predefined options.
 *
 * @example
 * <ComboBox dataSource={data}/>
 */
const ComboBox = (props) => {
    const { dataSource, id, cssClass,  value, floatLabelType } = props

    /**
     * Triggers when an item in a popup is selected or when the model value is changed by user.
     * @event change
     */
    const onChangeFn = (e) => {
        props.onChange(e);
    }
    /**
     * Triggers when an item in the popup is selected by the user either with mouse/tap or with keyboard navigation.
     * @event select
     */

    const onSelectFn = (e) => {
        props.select(e);console.log("onBlur",e)
    }
    /**
     * Triggers when an item in the popup is on blur by the user either with mouse/tap or with keyboard navigation.
     * @event onBlur
     */

    const onBlurFn = (e) => {
        props.onBlur(e);
    }

    return (
        <div className="common-combobox">
            <ComboBoxComponent
                floatLabelType={floatLabelType}
                dataSource={dataSource}
                value={value}
                onChange={onChangeFn}
                select={onSelectFn}
                name={`${id}Err`}
                data-msg-containerid={`${id}Err`}
                cssClass={cssClass}
                allowCustom={false}
                onBlur={onBlurFn}
                {...props}
            />
            <div
                id={`${id}Err`}
                className="e-input-group e-float-icon-left error-div"
            />
        </div>
    );
};

ComboBox.propTypes = {
    dataSource: PropTypes.array.isRequired,
    id: PropTypes.string.isRequired,
    cssClass: PropTypes.string.isRequired,
    floatLabelType: PropTypes.string,
    fields: PropTypes.object,
    onChange: PropTypes.func,
    select: PropTypes.func,
    value: PropTypes.number,
};

ComboBox.defaultProps = {
    floatLabelType: "Auto",
    select: () => { },//NOSONAR
    onChange: () => { },//NOSONAR
    onBlur: () => { },//NOSONAR
};

export default ComboBox;
