/**
 * Author: Vivek Khodade.
 * Created:06-Jun-2020
 * Last Modify:06-Jun-2020
 * Modified By:Vivek Khodade
 */

 import axios from "axios";
 import { urlConfig } from "../../config/config";
 function errorHandler(reject, error) {
   // eslint-disable-next-line  
   reject("operation failed");
 }
 
function invoikeGetReq(getUrl) {
    return new Promise(function onThen(resolve, reject) {
      axios
        .get(getUrl, {
          headers: {
            authorization: localStorage.getItem("jwtToken"),
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        // eslint-disable-next-line
        .then((response) => {
          if (response != null) {
            return resolve(response.data);
          }
        })
        .catch((error) => {
          errorHandler(reject, error.response.data.message);
          return null;
        });
    });
  }
/**
 * 
 * @param {*} authUser 
 * @param {*} empId 
 * @param {*} fyId 
 * @param {*} monthOfRating 
 * @returns Performance and Potential final rating data.
 */
function getPerformacePotentialDashboardData(authUser, empId, fyId, monthOfRating) {
    const getURL = `${urlConfig.tmlcApiUrl}okrdashboard/okrperformancedata/${authUser}/${empId}/${fyId}/${monthOfRating}`;
    return invoikeGetReq(getURL)
  }
/**
 * 
 * @param {*} authUser  
 * @returns Dashboard User list data.
 */
function getDashboardUserList(authUser, empId, fyId, monthOfRating) {
    const getURL = `${urlConfig.tmlcApiUrl}okrdashboard/userlist/${authUser}`;
    return invoikeGetReq(getURL)
  }
  
/**
 * 
 * @param {*} authUser  
 * @param {*} empIds  
 * @param {*} fyId  
 * @param {*} monthId  
 * @returns Dashboard User list data.
 */
function getDashboardteamRatingStatus(authUser, empId, fyId, monthId ) {
    const getURL = `${urlConfig.tmlcApiUrl}okrdashboard/ratingstatus/${authUser}/${empId}/${fyId}/${monthId }`;
    return invoikeGetReq(getURL)
  }
/**
 * 
 * @param {*} type  
 * @param {*} authUser  
 * @param {*} empIds  
 * @param {*} fyId  
 * @param {*} monthId  
 * @returns Dashboard user x and y axis data.
 */
function getOKRDashboardData(type,userId,empId,fyId,monthId) {
    const getURL = `${urlConfig.tmlcApiUrl}okrdashboard/${type}/${userId}/${empId}/${fyId}/${monthId}`;
    return invoikeGetReq(getURL)
  }
  
const OKRDashboardServices = {
    getPerformacePotentialDashboardData,
    getDashboardUserList,
    getDashboardteamRatingStatus,
    getOKRDashboardData
}
  export default OKRDashboardServices;