/* Author : Prashant Waphare
 * Date : 01-05-2020
 * Description: This file use for Absconding HR component.
 */
import * as React from "react";
import {
    GridComponent,
    ColumnsDirective,
    ColumnDirective,
    Filter,
    // Inject,
    VirtualScroll,
    Sort,
    Page,
} from "@syncfusion/ej2-react-grids";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import "../../../../src/app/containers/tmlc/tmlc.css";
import "../../../../src/components/separation/resignationcomponent/resignationcomponent.css"

import { connect } from "react-redux";
import { Button, Dialog } from "@material-ui/core";
import { Redirect } from "react-router-dom";
import {
    DialogContent,
    DialogActions,
    DialogTitleTMLC
} from "../../customdialog/customdialog";
import { Query } from '@syncfusion/ej2-data';
import TextField from "@material-ui/core/TextField";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ErrorBoundary from "../../error/errorboundary";
import NotificationContainer from "../../notification/notifycontainer";
import AbscondingUploadFile from "../abscondingcomponets/abscondinguploadfile";
import CommunicationDocPreview from "../abscondingcomponets/communicationDocPreview";
import EmployeeNameTemplate from "../employeeNameTemplate";
import {
    getGridColumn,
    EMPLOYEE_NAME,
    ACTION,
    JOINING_DATE,
    COMMITTEE_REPORT_UPLOAD,
    REQUEST_ON,
    FORM_COMMITTEE,
    COMMITTEE_ACTION,
    REMARK,
    SUSPENDED_DAYS,
    LAST_WORKING_DATE
} from "../../../app/other/gridcolumns";
import { CheckBoxSelection, MultiSelectComponent, ComboBoxComponent } from '@syncfusion/ej2-react-dropdowns';
import { Inject } from '@syncfusion/ej2-react-schedule';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { AllDateFormat } from "../allcommoncomponents";
import { faArrowAltToTop, faEye, faPlayCircle, faLongArrowAltRight, faArrowAltRight } from '@fortawesome/pro-light-svg-icons';
import { submitTerminationData, submiteExitClearanceData } from '../../../redux/actions/entryexit/separationaction';
import separetionServices from "../../../services/entryexit/separationServices";
import { EMPLOYEE_PROFILE } from "../../../redux/constant/meetingcategoryconstant";
import { encrypt,encodeString } from "../../../app/other/commonfunction";
import ConfirmDialog from '../../confirmdialog/confirmdialog';
import {DateFilterformat} from '../../../../src/config/config';
import CommunicatedPreviewGrid from "../abscondingcomponets/communicatedpreviewgrid";
import { setMeetingCategory } from '../../../redux/actions/meetingcategoryaction';
library.add(faArrowAltToTop, faEye, faPlayCircle, faLongArrowAltRight, faArrowAltRight);

class TerminationHrGrid extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: null,
            actionState: true,
            openCommunication: false,
            openCommitteePreview: false,
            selectCommittee: "",
            selectCommitteeAction: "",
            documentPath: '',
            imgType: '',
            currentDate: '',
            setCommunicationFilePath: false,
            selectedMembers: [],
            button1Caption: '',
            button2Caption: '',
            title: '',
            message: '',
            open: false,
            initiateEmployeeId: '',
            terminationIdReportUpload: '',
            previewDoc: false,
            openCommunicationPreview: false,
            committePreviewList: [],
            openFormCommitteeDialog: false,
            clearanceInitiated: '',
            continueEmployeeId: '',
            dataParam: [],
            postParam: []
        };
        this.selectedTerminationRow = 0;
        this.selectedMembers = [];
        this.height =
            (window.innerHeight ||
                document.documentElement.clientHeight ||
                document.body.clientHeight) - 188;
        this.width =
            (window.innerWidth ||
                document.documentElement.clientWidth ||
                document.body.clientWidth) - 100;
        this.dReady = false;
        this.dtTime = false;
        this.isDataBound = false;
        this.isDataChanged = true;
        this.dropSlectedIndex = null;
        this.committeeMandatoryFlag = true;
        this.committeeActionFlag = true;
        this.check = {
            type: "CheckBox",
        };
        this.select = {
            persistSelection: true,
            type: "Multiple",
            checkboxOnly: true,
        };
        this.Filter = {
            type: "Menu",
        };
        this.pageOptions = {
            pageSize:
                (window.innerHeight ||
                    document.documentElement.clientHeight ||
                    document.body.clientHeight) > 700
                    ? 13
                    : 6,
        };
        this.name = {
            type: "CheckBox",
        };
        this.employeeNameTemplate = this.employeeNameTemplate.bind(this);
        this.navigateToAddBasicInformation = this.navigateToAddBasicInformation.bind(this);
        this.setFormCommittee = this.setFormCommittee.bind(this);
        this.removeUser = this.removeUser.bind(this);
        this.FormCommittee = this.FormCommittee.bind(this);
        this.documentDetail = this.documentDetail.bind(this);
        this.hrStage = [{ Id: 1, Name: "Communication 2" }];
        this.committeeAction = [{ Id: 1, Name: "Termination 2" }];
        this.fields = { text: 'Name', value: 'Id' };
    }

    componentDidMount() {
        setTimeout(() => {
            const ele = document.getElementsByClassName("e-headercelldiv");
            for(let i in ele) {
                if(!isNaN(parseInt(i, 10))){
                  let index = parseInt(i, 10);
                    if (
                        ele[index].innerText === JOINING_DATE ||
                        ele[index].innerText === ACTION ||
                        ele[index].innerText === REQUEST_ON
                        // ele[index].innerText === FORM_COMMITTEE
                    ) {
                        ele[index].classList.add("grid-center-header");
                    } else {
                        ele[index].classList.add("grid-left-header");
                    }
                }
              }
            
        }, 1000);
    }

    onQueryCellInfoPreJoining = (args) => {
        const { cell, column } = args;
        const { field } = column;
        if (field === "committeeReportUpload" || field === "action") {
            cell.setAttribute(
                "style",
                "font-size:14px !important; text-align: center !important;"
            );
        } else {
            cell.setAttribute(
                "style",
                "font-size:14px !important; text-align: left !important; padding-left:18px !important"
            );
        }

    };

    onDataBound() {
        clearTimeout(this.clrIntervalFun);
        clearInterval(this.intervalFun);
        this.dtTime = true;
    }
    hrStageTemplate = (props) => {
        const { hrStage } = props;
        return (
            <>
                <div className="col-xs-2 col-sm-2 col-lg-8 col-md-12"> {hrStage} </div>
                <div>
                    <FontAwesomeIcon icon={faArrowAltToTop}
                        style={{ fontSize: "25px", paddingLeft: "5px" }}
                        onClick={this.openCommunication}
                    />
                    <FontAwesomeIcon icon={faEye}
                        style={{ fontSize: "25px", paddingLeft: "5px" }}
                        onClick={this.openCommitteePreview}
                    />
                </div>
            </>
        );
    };
    closeCommunicationPreviewDialog = () => {
        this.setState({ openCommunicationPreview: false });
    }
    // get list of committee document list 
    getCommittePreviewList = (e) => {
        const { authUser } = this.props;
        try {
            const getTerminationListData = Promise.resolve(
                separetionServices.getTerminationCommitteeMembersData(authUser, "commRpt", e)
            );
            getTerminationListData.then((result) => {
                if (result !== null) {
                    this.setState({ committePreviewList: result });
                } else {
                    this.data = "error";
                }
            });
        } catch (error) {
            // eslint-disable-next-line
            console.log(error);
        }
        this.setState({ openCommunicationPreview: true });
    };

    openCommunication = (termiUid) => {
        this.setState({ openCommunication: true, terminationIdReportUpload: termiUid });
    }
    openPreviewDoc = (termID) => {
        // this.openCommunicationPreview(empDocId)
        this.getCommittePreviewList(termID)
    }
    openCommitteePreview = () => {
        this.setState({ openCommitteePreview: true, setCommunicationFilePath: false });
    }
    openFormCommitteeDialog = async (termiUid,clearanceInitiated) => {
        this.selectedTerminationRow = termiUid;
        const committeeMemberList = await this.getFormCommittee(termiUid);/**NOSONAR */
        committeeMemberList.forEach((d) => {
            this.selectedMembers.push(d.fk_committee_member_id);
        });
        if (this.selectedMembers.length > 0) {
            this.committeeMandatoryFlag = true;
        }
        this.setState({ openFormCommitteeDialog: true, clearanceInitiated: clearanceInitiated, setCommunicationFilePath: false, selectedMembers: this.selectedMembers });
    }
    closeCommunicationDialog = () => {

        this.setState({ openCommunication: false, imgType: "", currentDate: ""});
    }
    closeCommitteePreviewDialog = () => {
        this.setState({ previewDoc: false });
    }
    closeFormCommitteeDialog = () => {
        this.selectedMembers = [];
        this.committeeMandatoryFlag = true;
        this.setState({ selectedMembers: this.selectedMembers, openFormCommitteeDialog: false, setCommunicationFilePath: false });
    }
    actionToggle = () => {
        const { actionState } = this.state;
        this.setState({ actionState: !actionState });
    };
    action = (props) => {
        const { clearanceInitiated, employeeId, terminationAction} = props;
        if (clearanceInitiated === 'N') {
            return (
                <div className="row" style={{ paddingLeft: '25px', marginTop: '-20px' }}>
                    {
                        terminationAction === "Termination" ?
                            <div className="col-4">
                                <TooltipComponent
                                    closeOn="Click"
                                    content="Initiate Clearance"
                                    position="BottomCenter"
                                    isSticky={false}
                                    className="pointer-cursor"
                                    cssClass='customtooltip'>
                                    <FontAwesomeIcon icon={faPlayCircle} style={{ fontSize: "20px" }} onClick={(e) => this.initiateClearance(employeeId)} />
                                </TooltipComponent>
                            </div>
                            : <div className="col-4"></div>
                    }
                    {
                        terminationAction === "CLEAR" ?
                            <div className="col-4">
                                <TooltipComponent
                                    closeOn="Click"
                                    content="Continue Employment"
                                    position="BottomCenter"
                                    isSticky={false}
                                    className="pointer-cursor"
                                    cssClass='customtooltip'>
                                    <FontAwesomeIcon icon={faArrowAltRight} style={{ fontSize: "20px" }} onClick={(e) => this.continueEmployee(employeeId)} />
                                </TooltipComponent>
                            </div>
                            :
                            <div className="col-4"></div>
                    }
                </div>
            );
        } else {
            return (
                <div className="row" style={{ paddingLeft: '25px', marginTop: '-20px' }}>
                    <div className="col-4">
                        <TooltipComponent
                            closeOn="Click"
                            content="Clearance Initiated"
                            position="BottomCenter"
                            isSticky={false}
                            cssClass='customtooltip'>
                            <FontAwesomeIcon icon={faPlayCircle} style={{ fontSize: "20px", color: 'green' }} />
                        </TooltipComponent>
                    </div>

                </div>
            );
        }
    }
    continueEmployee = (continueEmployeeIdempId) => {
        const { open } = this.state;
        this.setState({
            continueEmployeeId: continueEmployeeIdempId,
            open: !open,
            button1Caption: 'Yes',
            button2Caption: 'No',
            title: 'Continue Employment',
            message: 'Are you sure you want to continue his/her employment?',
        });
    }
    handleConfirmNo = () => {
        const { open } = this.state;
        this.setState({
            open: !open,
            button1Caption: '',
            button2Caption: '',
            title: '',
            message: '',
            initiateEmployeeId: '',
            continueEmployeeId: '',
        });
    }
    handleConfirmClose = () => {
        const { open } = this.state;
        this.setState({
            open: !open,
            button1Caption: '',
            button2Caption: '',
            title: '',
            message: '',
            initiateEmployeeId: '',
            continueEmployeeId: ''
        });
    }
    initiateClearance = (empId) => {
        const { open } = this.state;
        this.setState({
            initiateEmployeeId: empId,
            open: !open,
            button1Caption: 'Yes',
            button2Caption: 'No',
            title: 'Initiate Clearance',
            message: 'Are you sure you want to initiate clearance?',
        });
    }
    handleSubmitYes = () => {
        const { authUser } = this.props;
        const { open, initiateEmployeeId, continueEmployeeId, title, dataParam, postParam } = this.state;
        if (title === "Continue Employment") {
            const data = {
                type: "contiemp",
                userId: parseInt(authUser),
                empId: continueEmployeeId,
                clsInfo: 'terminate'
            }
            this.props.submiteExitClearanceData(data);

        } else if(title === 'Action') {
            this.getCommitteeAction(postParam,dataParam);
        } else {
            const data = {
                type: "clsinit",
                userId: parseInt(authUser),
                empId: initiateEmployeeId
            }
            this.props.submiteExitClearanceData(data);
        }
        this.setState({
            open: !open,
            button1Caption: '',
            button2Caption: '',
            title: '',
            message: '',
            initiateEmployeeId: '',
            continueEmployeeId: ''
        });
    }
    committeeReportUpload = (props) => {
        const { termiUid, commiteeRepUplStatus } = props;
        const clr = commiteeRepUplStatus === 'Y' ? 'green' : 'gray'
        return (
            <div className="row" style={{ paddingLeft: '25px', marginTop: '-20px' }}>
                <div className="col-4">
                    <TooltipComponent className="pointer-cursor" content="Uplaod Report" position="BottomCenter" isSticky={false} cssClass='customtooltip'>
                        <FontAwesomeIcon icon={faArrowAltToTop} className="pointer-cursor"
                            style={{ fontSize: "20px", marginLeft: "10px" }}
                            onClick={(e) => this.openCommunication(termiUid)}
                        />
                    </TooltipComponent>
                </div>
                <div className="col-4">
                    <TooltipComponent className="pointer-cursor" content="View Report" position="BottomCenter" isSticky={false} cssClass='customtooltip'>
                        <FontAwesomeIcon icon={faEye} className="pointer-cursor"
                            style={{ fontSize: "20px", marginLeft: "10px", color: clr }}
                            onClick={(e) => this.openPreviewDoc(termiUid)}
                        />

                    </TooltipComponent>
                </div>
            </div>
        );
    }
    mgrRemarks = (props) => {
        const { remarks } = props;
        return (
            <div>
                <TooltipComponent
                    content={remarks.replace(/(?:\r\n|\r|\n)/g, '<br>')}
                    position="BottomCenter"
                    isSticky={true}
                    cssClass='customtooltip'
                    opensOn="Click"
                >
                    <span
                        style={{
                            fontSize: "14px",
                            display: "block",
                            width: "100px",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            cursor: "pointer"
                        }}
                    >
                        {remarks.replace(/[&\\#,+()$~%.'":*?<>{}]/g, '')}
                    </span>
                </TooltipComponent>
            </div>
        );
    }
    FormCommittee = (props) => {
        const { termiUid, commiteeUpdStatus, clearanceInitiated, terminationGround } = props;
        const clr = commiteeUpdStatus === 'Y' ? 'green' : terminationGround !== 'Performance' ?/**NOSONAR */ 'gray' : '#E0E0E0';
        const toolTipContent = terminationGround !== 'Performance' ? 'Manage Committee' : 'Committee Not Required';
        return (
            <div>
                <TooltipComponent
                    closeOn="Click"
                    content={toolTipContent}
                    position="BottomCenter"
                    isSticky={false}
                    className="pointer-cursor"
                    cssClass='customtooltip'>
                    <FontAwesomeIcon icon={faEye} onClick={terminationGround !== 'Performance' ? () => this.openFormCommitteeDialog(termiUid, clearanceInitiated) : undefined } style={{ fontSize: "20px", marginLeft: "25px", color: clr }} />
                </TooltipComponent>
            </div>
        );
    }

    continueCommitteeAction = (e,data) => {
        const { open } = this.state;
        var message = '';
        if(e.itemData){
            if(e.itemData.Id === 701){
                message = 'Please click on "Initiate Clearance" to complete Termination process';
            }
            else if(e.itemData.Id === 703){
                message = 'Please click on "Continue Employment" to complete CLEAR process';
            }
            if(message === ''){
                this.getCommitteeAction(e,data);
            }
            else{
                this.setState({
                    //continueEmployeeId: continueEmployeeIdempId,
                    open: !open,
                    button1Caption: 'OK',
                    button2Caption: '',
                    title: 'Action',
                    dataParam: data,
                    postParam: e,
                    message: message,
                });
            }    
        }
    }

    CommitteeAction = (props) => {
        const { terminationCommitteeAction } = this.props;
        const { clearanceInitiated, terminationGround, commiteeUpdStatus } = props;
        var terminateActionId = parseInt(props.terminateActionId);
        if(commiteeUpdStatus === 'Y') this.committeeActionFlag = true; else this.committeeActionFlag = false;
        if(terminationGround === 'Performance') this.committeeActionFlag = true;
        if(clearanceInitiated === 'Y') this.committeeActionFlag = false;
        return (
            <div className="col-xs-2 col-sm-2 col-lg-12 col-md-12 ">
                <ComboBoxComponent
                    id="committeeAction"
                    fields={{ text: "Name", value: "Id" }}
                    name="committeeAction"
                    allowFiltering={true}
                    value={terminateActionId}
                    allowCustom={false}
                    cssClass="e-outline"
                    dataSource={terminationCommitteeAction}
                    enabled={this.committeeActionFlag ? true : false}
                    change={(e) => this.continueCommitteeAction(e,props)}
                />
            </div>
        );
    }
    suspendedDays = (props) => {
        const { suspendedDays } = props
        return (
            <div style={{ textAlign: "center" }}>
                <span>{suspendedDays}</span>
            </div>
        )
    }
    navigateToAddBasicInformation = async (args) => {
        try {
       
            localStorage.setItem('last_meeting_category', localStorage.getItem('meeting_category'))
            localStorage.setItem("meeting_category", encodeString(EMPLOYEE_PROFILE));
            const path = `/onboard?empId=${encrypt(args.employeeId)}`;
            this.setState({
                redirect: path,
                // changedData: !changedData,
            }, () => this.props.setMeetingCategory(EMPLOYEE_PROFILE));
        } catch (error) {
            console.log("error", error);
        }
    };
    joiningDateFormat = (props) => {
        const { joiningDate } = props;
        return (
            <AllDateFormat date={joiningDate} />
        );
    };
    requestedDateFormat = (props) => {
        const { requestedOn } = props;
        return (
            <AllDateFormat date={requestedOn} />
        );
    };
    lastWorkingDate = (props) => {
        const { lastWorkingDate } = props;
        return (
            <AllDateFormat date={lastWorkingDate} />
        );
    };
    TextFieldComponent = (props) => {
        return (
            // eslint-disable-next-line
            <TextField {...props} disabled />
        );
    };
    onFilesAdded(addedfiles) {
        // Clear the alredy uploaded files if multiple is false
        const { allowmultiple, files } = this.state;
        if (!allowmultiple && files.length > 0) {
            this.setState({ files: addedfiles });
        } else {
            this.setState((prevState) => ({
                files: prevState.files.concat(addedfiles),
            }));
        }
    }
    getCommitteeAction = (e,data) => {
        if (e.itemData !== null) {
            const { authUser } = this.props;
            this.props.submitTerminationData(({
                "type": 'termAct',
                "termniId": data.termiUid,
                "userId": authUser,
                "terminatioAction": e.itemData.Id,
                "terminationEmployeeId": data.employeeId
            }));
            this.selectedMembers = [];
            this.committeeMandatoryFlag = true;
            this.setState({ selectedMembers: this.selectedMembers, openFormCommitteeDialog: false, setCommunicationFilePath: false });
        }
        // else {
        // this.setState({ selectCommitteeAction: e.itemData.Id });
        // this.setState({ selectCommitteeAction: "" });
        // }
    }
    employeeNameTemplate(props) {
        return (
            <EmployeeNameTemplate
                props={props}
                data="test"
                navigateToAddBasicInformation={this.navigateToAddBasicInformation}
            />
        );
    }
    documentDetail = (currentDate, imgType) => {
        this.setState({ currentDate: currentDate, imgType: imgType })
    }
    uploadDocument = () => {
        const { authUser } = this.props;

        document.getElementById("uploadErr").innerHTML = "";
        if (this.state.imgType === undefined || this.state.imgType === "") {
            document.getElementById("uploadErr").innerHTML = "Please upload required document";
            return;
        }
        let fileName = this.state.imgType;
        const data = {
            termniId: this.state.terminationIdReportUpload,
            userId: parseInt(authUser),
            type: "commRpt",
            committeeReportNm: `${fileName}`
        }
        this.props.submitTerminationData(data);
        this.setState({ terminationIdReportUpload: "", openCommunication: false ,imgType:""});
    }
    setFormCommittee(e) {
        this.selectedMembers.push(e.itemData.UserId);
        this.committeeMandatoryFlag = false;
        this.setState({ selectedMembers: this.selectedMembers });
    }
    getFormCommittee = async (termiUid) => {
        const { authUser } = this.props;
        var memberList = [];
        await separetionServices.getTerminationCommitteeMembersData(authUser, 'committee', termiUid)
            .then((result) => {
                if (result !== null) {
                    memberList = result;
                    //return memberList;
                } else {
                    //return memberList;
                }
            }).catch(function (error) {
                console.log(error);
                throw error;
            });
        return memberList;
    }
    async saveCommittee(e) {
        const { authUser } = this.props;
        this.props.submitTerminationData(({
            "type": 'commMem',
            "termniId": this.selectedTerminationRow,
            "userId": authUser,
            "committeeMemebers": this.state.selectedMembers.toString()
        }));
        this.selectedMembers = [];
        this.committeeMandatoryFlag = true;
        this.setState({ selectedMembers: this.selectedMembers, openFormCommitteeDialog: false, setCommunicationFilePath: false });
    }
    async removeUser(e) {
        const index = this.selectedMembers.indexOf(e.itemData.UserId);
        if (index > -1) {
            this.selectedMembers.splice(index, 1);
        }
        this.committeeMandatoryFlag = false;
        if (this.selectedMembers.length === 0) {
            this.committeeMandatoryFlag = true;
        }
        this.setState({ selectedMembers: this.selectedMembers });
    }

    handleFilteringByName = (e) => {
        let newData = [];
        this.props.othersCheckAvailablity.forEach(eachD => {
            newData.push(eachD);
        });
        if (newData != null && newData.length > 0) {
            let query = new Query();
            query = (e.text !== '') ? query.where('nameWithDepartment', 'contains', e.text, true) : query;
            e.updateData(newData, query);
        }
        // }
    }

    render() {
        const { redirect, setCommunicationFilePath, committePreviewList, clearanceInitiated } = this.state;
        const { terminationList, othersCheckAvailablity } = this.props;
        if (redirect !== null) {
            return <Redirect to={redirect} />;
        }
        var widthColumn = 0;
        var finalWidth = 0;
        // eslint-disable-next-line
        getGridColumn("terminationHr").forEach((data) => {
            widthColumn = widthColumn + data.width;
        });
        if (widthColumn <= this.width) {
            finalWidth = widthColumn + 20;
        } else {
            finalWidth = this.width
        }
        return (
            <div
                className="row resignation-manager-hr-grid-height"
                style={{
                    // height: "828px"
                    paddingRight: '15px', paddingLeft: '15px'
                }}
            >
                {/* <div>TERMINATION REPORT STATUS</div> */}
                <div>
                    <div className="row-xs-12 row-sm-12 row-lg-12 row-md-12">
                        <NotificationContainer />
                        <div>
                            <GridComponent
                                className="grid-head-custome"
                                id="overviewgrid"
                                allowPaging
                                pageSettings={this.pageOptions}
                                dataSource={terminationList}
                                rowHeight={30}
                                height={this.height}
                                ref={(g) => {
                                    this.gridInstance = g;
                                }}
                                queryCellInfo={this.onQueryCellInfoPreJoining}
                                dataBound={this.onDataBound}
                                filterSettings={this.Filter}
                                allowFiltering
                                allowSorting
                                allowSelection
                                selectionSettings={this.select}
                                allowTextWrap={true}
                                width={finalWidth}
                                rowSelected={this.rowSelected}
                            >
                                <ColumnsDirective>
                                    {getGridColumn("terminationHr").map((item) => {
                                        switch (item.colName) {
                                            case EMPLOYEE_NAME:
                                                return (
                                                    <ColumnDirective
                                                        key={item.field}
                                                        template={this.employeeNameTemplate}
                                                        textAlign={`${item.align} !important`}
                                                        field={item.field}
                                                        headerText={item.colName}
                                                        width={item.width}
                                                        filter={this.Filter}
                                                    />
                                                );
                                            case JOINING_DATE:
                                                return (
                                                    <ColumnDirective
                                                        key={item.field}
                                                        template={this.joiningDateFormat}
                                                        // allowFiltering={false}
                                                        filter={this.check}
                                                        textAlign={`${item.align} !important`}
                                                        field={item.field}
                                                        headerText={item.colName}
                                                        width={item.width}
                                                        format={DateFilterformat}
                                                    />
                                                );
                                            case REQUEST_ON:
                                                return (
                                                    <ColumnDirective
                                                        key={item.field}
                                                        template={this.requestedDateFormat}
                                                        filter={this.check}
                                                        textAlign={`${item.align} !important`}
                                                        field={item.field}
                                                        headerText={item.colName}
                                                        width={item.width}
                                                        format={DateFilterformat}
                                                    />
                                                );
                                            case LAST_WORKING_DATE:
                                                return (
                                                    <ColumnDirective
                                                        key={item.field}
                                                        template={this.lastWorkingDate}
                                                        filter={this.check}
                                                        textAlign={`${item.align} !important`}
                                                        field={item.field}
                                                        headerText={item.colName}
                                                        width={item.width}
                                                        format={DateFilterformat}
                                                    />
                                                );
                                            case FORM_COMMITTEE:
                                                return (
                                                    <ColumnDirective
                                                        key={item.field}
                                                        template={this.FormCommittee}
                                                        allowFiltering={false}
                                                        textAlign={`${item.align} !important`}
                                                        field={item.field}
                                                        headerText={item.colName}
                                                        width={item.width}
                                                    />
                                                );
                                            case COMMITTEE_ACTION:
                                                return (
                                                    <ColumnDirective
                                                        key={item.field}
                                                        template={this.CommitteeAction}
                                                        allowFiltering={false}
                                                        textAlign={`${item.align} !important`}
                                                        field={item.field}
                                                        headerText={item.colName}
                                                        width={item.width}
                                                    />
                                                );
                                            case COMMITTEE_REPORT_UPLOAD:
                                                return (
                                                    <ColumnDirective
                                                        key={item.field}
                                                        template={this.committeeReportUpload}
                                                        allowFiltering={false}
                                                        textAlign={`${item.align} !important`}
                                                        field={item.field}
                                                        headerText={item.colName}
                                                        width={item.width}
                                                    />
                                                );
                                            case SUSPENDED_DAYS:
                                                return (
                                                    <ColumnDirective
                                                        key={item.field}
                                                        template={this.suspendedDays}
                                                        filter={this.check}
                                                        textAlign={`${item.align} !important`}
                                                        field={item.field}
                                                        headerText={item.colName}
                                                        width={item.width}
                                                    />
                                                );
                                            case REMARK:
                                                return (
                                                    <ColumnDirective
                                                        key={item.field}
                                                        template={this.mgrRemarks}
                                                        allowFiltering={false}
                                                        textAlign={`${item.align} !important`}
                                                        field={item.field}
                                                        headerText={item.colName}
                                                        width={item.width}
                                                    />
                                                );
                                            case ACTION:
                                                return (
                                                    <ColumnDirective
                                                        key={item.field}
                                                        template={this.action}
                                                        allowFiltering={false}
                                                        textAlign={`${item.align} !important`}
                                                        field={item.field}
                                                        headerText={item.colName}
                                                        width={item.width}
                                                    />
                                                );
                                            default:
                                                return (
                                                    <ColumnDirective
                                                        key={item.field}
                                                        textAlign={`${item.align} !important`}
                                                        field={item.field}
                                                        headerText={item.colName}
                                                        width={item.width}
                                                        filter={this.check}
                                                    />
                                                );
                                        }
                                    })}
                                </ColumnsDirective>
                                <Inject services={[Page, Filter, VirtualScroll, Sort]} />
                            </GridComponent>
                        </div>
                    </div>
                    <div style={{ width: "350px" }}>
                        <Dialog
                            aria-labelledby="uploading-title"
                            open={this.state.openCommunication}
                            fullWidth
                            width="1000"
                        >
                            <DialogTitleTMLC id="uploading-title"
                                onClose={this.handleClose}
                            >
                                Report Upload
                                <div style={{ float: 'right' }}>
                                    <IconButton style={{ color: "white", marginRight: '-5px',marginTop:"-7px" }} aria-label="close" onClick={this.closeCommunicationDialog}>
                                        <CloseIcon />
                                    </IconButton>
                                </div>
                                {/* <IconButton style={{ color: "white", position: "absolute", marginLeft: "625px" }} aria-label="close" onClick={this.closeCommunicationDialog}>
                                    <CloseIcon />
                                </IconButton> */}
                            </DialogTitleTMLC>
                            <ErrorBoundary>
                                <DialogContent dividers>
                                    <div>
                                        <div className="row card-layout">
                                            <div className="Upload">
                                                <div className="Content">
                                                    <div style={{ textAlign: '-webkit-center' }}>
                                                        <AbscondingUploadFile
                                                            documentPath={this.state.documentPath}
                                                            documentDetail={this.documentDetail}
                                                            type={"committee"}
                                                        />
                                                    </div>
                                                    <div >
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div id="uploadErr" className="e-input-group e-float-icon-left error-div" />
                                    </div>
                                </DialogContent>
                                <DialogActions>
                                    <Button color="default" variant="contained" onClick={this.closeCommunicationDialog}> Cancel </Button>
                                    <Button color="primary" variant="contained" onClick={this.uploadDocument} style={{ marginRight: '6px' }} > Save </Button>
                                </DialogActions>
                            </ErrorBoundary>
                        </Dialog>
                    </div>
                    <div className='col-12'>
                        <Dialog
                            open={this.state.previewDoc}
                            // onClose={this.closeCommitteePreviewDialog}
                            width="1000px"
                        >
                            <DialogTitleTMLC id="uploading-title"
                            //onClose={this.handleClose}
                            >
                                Committee Preview
                                <div style={{ float: 'right' }}>
                                    <IconButton style={{ color: "white", marginRight: '-5px',marginTop:"-7px" }} aria-label="close" onClick={this.closeCommitteePreviewDialog}>
                                        <CloseIcon />
                                    </IconButton>
                                </div>
                                
                            </DialogTitleTMLC>
                            <ErrorBoundary>
                                <DialogContent dividers>
                                    <div>
                                        {
                                            // setCommunicationFilePath ?
                                            //     <CommunicationDocPreview setCommunicationFilePath={setCommunicationFilePath} />
                                            //     :
                                                <CommunicationDocPreview setCommunicationFilePath={setCommunicationFilePath} />
                                        }
                                    </div>
                                </DialogContent>
                            </ErrorBoundary>
                        </Dialog>
                    </div>
                    <div>
                        <Dialog
                            open={this.state.openFormCommitteeDialog}
                            onClose={this.closeFormCommitteeDialog}
                            width="1000px"
                        >
                            <DialogTitleTMLC id="uploading-title"
                            //onClose={this.handleClose}
                            >
                                Manage Committee
                                <div style={{ float: 'right' }}>
                                    <IconButton style={{ color: "white", marginRight: '-5px',marginTop:"-7px" }} aria-label="close" onClick={this.closeFormCommitteeDialog}>
                                        <CloseIcon />
                                    </IconButton>
                                </div>
                            </DialogTitleTMLC>
                            <ErrorBoundary>
                                <DialogContent dividers>
                                    <div style={{ width: 600, height: 200 }}>
                                        {clearanceInitiated === "N" && 
                                        <div>
                                            <MultiSelectComponent id="localData"
                                                ref={(multiselect) => { this.searchEmail = multiselect; }}
                                                dataSource={othersCheckAvailablity}
                                                fields={{ text: 'nameWithDepartment', value: 'UserId' }}
                                                placeholder="Select Committee Member"
                                                cssClass='e-outline'
                                                floatLabelType="Auto"
                                                showSelectAll={false}
                                                maximumSelectionLength={5}
                                                select={this.setFormCommittee}
                                                removed={this.removeUser}
                                                value={this.state.selectedMembers}
                                                showDropDownIcon={true}
                                                filterBarPlaceholder="Committee Member"
                                                mode="CheckBox"
                                                filtering={this.handleFilteringByName}
                                                allowFiltering={false}
                                            >
                                                <Inject services={[CheckBoxSelection]} />
                                            </MultiSelectComponent>
                                        </div>
                                        }

                                        {this.committeeMandatoryFlag &&
                                            <div style={{ marginTop: 20 }}>
                                                <table>
                                                    <thead>
                                                        <tr><td><h5><b>Committee Members:</b></h5></td></tr>
                                                    </thead>
                                                    <tbody>

                                                        {
                                                            this.selectedMembers.map(value => {
                                                                return <tr key={value}><td>{othersCheckAvailablity.find(o => o.UserId === value).nameWithDepartment}</td></tr>
                                                            })
                                                        }

                                                    </tbody>
                                                </table>
                                            </div>
                                        }

                                    </div>
                                </DialogContent>
                                <DialogActions>
                                    {!this.committeeMandatoryFlag &&
                                        <Button
                                            className="e-event-details text-light ml-sm " variant="contained" title="SUBMIT" color='primary' size='small'
                                            onClick={() => this.saveCommittee(this.selectedTerminationRow)}>SUBMIT
                                        </Button>

                                    }
                                </DialogActions>

                            </ErrorBoundary>
                        </Dialog>
                    </div>
                    <div>
                        <Dialog
                            open={this.state.openCommunicationPreview}
                            width="800px"
                        >
                            <DialogTitleTMLC id="uploading-title"
                                onClose={this.handleClose}
                            >
                                Report List
                                <div style={{ float: 'right' }}>
                                    <IconButton style={{ color: "white", marginRight: '-5px',marginTop:"-7px" }} aria-label="close" onClick={this.closeCommunicationPreviewDialog}>
                                        <CloseIcon />
                                    </IconButton>
                                </div>
                                {/* <IconButton style={{ color: "white", position: "absolute", marginLeft: "640px" }} aria-label="close" onClick={this.closeCommunicationPreviewDialog}>
                                    <CloseIcon />
                                </IconButton> */}
                            </DialogTitleTMLC>
                            <ErrorBoundary>
                                <DialogContent dividers>
                                    <div>
                                        <CommunicatedPreviewGrid committePreviewList={committePreviewList} type={"committee"} />
                                    </div>
                                </DialogContent>
                            </ErrorBoundary>
                        </Dialog>
                    </div>
                    <ConfirmDialog open={this.state.open}
                        handleConfirmYes={this.handleSubmitYes}
                        handleConfirmNo={this.handleConfirmNo}
                        handleConfirmClose={this.handleConfirmClose}
                        button2Caption={this.state.button2Caption}
                        button1Caption={this.state.button1Caption}
                        title={this.state.title}
                        message={this.state.message}
                    />
                </div>

            </div>
        );
    }
}
const mapStateToProps = ({ auth, meetingmaster }) => {
    const { authUser } = auth;
    const { othersCheckAvailablity } = meetingmaster;
    return { authUser, othersCheckAvailablity };
};
export default connect(mapStateToProps, { submitTerminationData, submiteExitClearanceData, setMeetingCategory })(TerminationHrGrid);
