import React from 'react'
import { connect } from 'react-redux'
import {
    DialogContent,
    DialogTitleTMLC,
    DialogActions
    // DialogTitle,
    // DialogTitleLnA
} from "../../../customdialog/customdialog";
import { Dialog, Button } from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { MultiSelectComponent, CheckBoxSelection } from "@syncfusion/ej2-react-dropdowns";
import { Inject } from "@syncfusion/ej2-react-schedule";
import { Query } from "@syncfusion/ej2-data";
import { updateLnAData } from '../../../../redux/actions/lna/lnaaction';


export const NotifiersPopup = (props) => {
    const { othersCheckAvailablity } = props;
    const [state, setState] = React.useState({
        selectedUserId: [],
    });
    const getSelectedAttendee = (e) => {
        const { selectedUserId } = state;
        const trimnameWithDepartment = e.itemData;
        let newSelectedUserIds;
        if (e.itemData.nameWithDepartment.includes("(")) {
            const start = trimnameWithDepartment.nameWithDepartment.indexOf("(");
            trimnameWithDepartment.nameWithDepartment = trimnameWithDepartment.nameWithDepartment.substring(
                0,
                start
            );
            newSelectedUserIds = selectedUserId;
            newSelectedUserIds.push(trimnameWithDepartment.UserId);
            let data = {
                selectedUserId: newSelectedUserIds
            }
            setState(preState => ({ ...preState, ...data }));
            props.updateLnAData({ notificationTo: newSelectedUserIds.toString() })
        } else {
            newSelectedUserIds = selectedUserId;
            newSelectedUserIds.push(e.itemData.UserId);
            let data = {
                selectedUserId: newSelectedUserIds
            }
            setState(preState => ({ ...preState, ...data }));
            props.updateLnAData({ notificationTo: newSelectedUserIds.toString() })
        }
    }
    const removeItem = (e) => {
        const { selectedUserId } = state;
        let newSelectedUserIds = selectedUserId;
        newSelectedUserIds = newSelectedUserIds.filter((el) => el !== e.itemData.UserId);
        let data = {
            selectedUserId: newSelectedUserIds
        }
        setState(preState => ({ ...preState, ...data }));
        props.updateLnAData({ notificationTo: newSelectedUserIds.toString() });
    }
    const handleFilteringByName = (e) => {

        const newData = [];
        othersCheckAvailablity.forEach((eachD) => {
            newData.push(eachD);
        });
        if (newData != null && newData.length > 0) {
            let query = new Query();
            query =
                e.text !== ""
                    ? query.where("nameWithDepartment", "contains", e.text, true)
                    : query;
            e.updateData(newData, query);
        }
    };

    return (
        <div style={{ width: "350px" }}>
            <Dialog
                aria-labelledby="uploading-title"
                open={true}
                fullWidth
                maxWidth="md"
            >
                <DialogTitleTMLC>
                    <div className='col-lg-12' style={{ paddingLeft: 0, display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <div className="col-xs-2 col-sm-2 col-lg-8 col-md-12 pt-3">Leave Notifiers List</div>
                        <div style={{ float: "right", marginRight: "-5px" }}>
                            <IconButton style={{ color: "white", marginRight: "-1.4em" }} aria-label="close" onClick={() => props.closeNotificationPopup()}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                    </div>
                </DialogTitleTMLC>
                <DialogContent dividers>
                    <div>
                        <div className='row1'>
                            <div className='col-lg-9 col-md-2 col-sm-9 col-xs-9'>
                                <MultiSelectComponent
                                    id="localData"
                                    dataSource={othersCheckAvailablity}
                                    fields={{
                                        text: "nameWithDepartment",
                                        value: "UserId",
                                    }}
                                    data-msg-containerid="notificationToErr"
                                    name="notificationToErr"
                                    select={getSelectedAttendee}
                                    removed={removeItem}
                                    mode="CheckBox"
                                    showDropDownIcon
                                    filtering={handleFilteringByName}
                                    showSelectAll={false}
                                    placeholder="Notification To *"
                                    cssClass="e-outline"
                                    allowFiltering={true}
                                >
                                    <Inject services={[CheckBoxSelection]} />
                                </MultiSelectComponent>
                                <div
                                    id="notificationToErr"
                                    className="e-input-group e-float-icon-left error-div"
                                />
                            </div>
                            <div className='col-lg-3 col-md-3 col-sm-3 col-xs-3 pb-sm'>
                                <div style={{ height: 42, border: '1px solid #F94F5E', textAlign: 'center', color: '#F94F5E', cursor: 'pointer', opacity: '1', fontWeight: 500 }}>
                                    <div className='pt-sm'
                                    // onClick={() => openNotificationPopup()}
                                    >
                                        ADD
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='row1'>
                            <div className='col-lg-12 col-md-12 col-sm-12' style={{ height: '42px', backgroundColor: '#F6F6F6', }}>
                                <div className='col-lg-9 col-md-9 col-sm-9 pt-sm'>Name</div>
                                <div className='col-lg-3 col-md-3 col-sm-3 pt-sm'>Action</div>
                            </div>
                            <div className='col-lg-12 col-md-12 col-sm-12' style={{ height: '42px', borderBottom: '1px solid #F6F6F6' }}>
                                <div className='col-lg-9 col-md-9 col-sm-9  pt-sm'>Snehal Wagh</div>
                                <div className='col-lg-3 col-md-3 col-sm-3  pt-sm'>Remove</div>
                            </div>
                            <div className='col-lg-12 col-md-12 col-sm-12' style={{ height: '42px', borderBottom: '1px solid #F6F6F6' }}>
                                <div className='col-lg-9 col-md-9 col-sm-9  pt-sm'>Sivakrishna Buri</div>
                                <div className='col-lg-3 col-md-3 col-sm-3  pt-sm'>Remove</div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <div className='col-lg-12 col-md-2 col-sm-12 col-xs-12 pb-sm'>
                        <div style={{ padding: "0px 0px 0px 0px", float: "right" }}>
                            <Button
                                style={{ width: "124px" }}
                                variant="contained"
                                className="remove-button-shadow"
                                color="primary"
                            // onClick={()=>onSubmitApplyLeave()}
                            >
                                Save
                            </Button>
                            <Button
                                className="leave-cancel-button"
                                style={{ width: "124px" }}
                                variant="contained"
                                onClick={() => props.closeNotificationPopup()}
                            >
                                Cancel
                            </Button>
                        </div>
                    </div>
                </DialogActions>
            </Dialog>
        </div>
    )
}
const mapStateToProps = ({ meetingcategory, lnareducer, auth, meetingmaster }) => {
    const { authUser } = auth;
    const { categoryName } = meetingcategory;
    const { othersCheckAvailablity } = meetingmaster;
    const { leaveType, leaveStartDate, leaveEndDate, leaveReason, notificationTo, reliever, leaveTypeData, relieverData, leaveApplyStatus } = lnareducer;
    return {
        categoryName,
        leaveType, leaveStartDate, leaveEndDate, leaveReason, notificationTo, reliever, authUser, leaveTypeData, othersCheckAvailablity, relieverData, leaveApplyStatus
    }
}

export default connect(mapStateToProps, { updateLnAData })(NotifiersPopup)