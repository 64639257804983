import React, { Component } from 'react';
import { Button } from '@material-ui/core';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import { setMeetingCategory } from '../../../redux/actions/meetingcategoryaction';
import { submitAbscondingData } from '../../../redux/actions/entryexit/separationaction';
import { getNotify, getDate,encodeString } from '../../../app/other/commonfunction';

import { ERROR, enterAbscondingManagerRemark, enterAbscondingDateRemark } from '../../../assets/constants/constants';
import { EMPLOYEE_LIST } from '../../../redux/constant/meetingcategoryconstant';
import ConfirmationPopup from '../../../utilities/confirmationpopup';
class AbscondingFooterButtons extends Component {
  constructor(props) {
    super(props);
    this.state = {
      button1Caption: '',
      button2Caption: '',
      title: '',
      message: '',
      open: false,
      redirect: null
    };
    this.onSubmitAbsconding = this.onSubmitAbsconding.bind(this);
  }
  onSubmitAbsconding = () => {
    const { open } = this.state;
    const { abscondingManagerRemark, absondingDate } = this.props;
    if (absondingDate === null) {
      getNotify(ERROR, enterAbscondingDateRemark);
      return;
    }
    if (abscondingManagerRemark === "") {
      getNotify(ERROR, enterAbscondingManagerRemark);
      return;
    }
    this.setState({
      open: !open,
      button1Caption: 'Yes',
      button2Caption: 'No',
      title: 'Confirm Absconding',
      message: 'Are you sure, you want to mark this employee as Absconded?',
    });

  }
  handleSubmitYes = () => {
    try {
      const { open } = this.state;
      const { abscondingManagerRemark, absondingDate, authUser, empid } = this.props;
      // eslint-disable-next-line
      this.props.submitAbscondingData({
        "abscondingManagerRemark": abscondingManagerRemark,
        "absondingDate": getDate(new Date(absondingDate)),
        "userId": parseInt(authUser),
        "empId": empid,
      });
      localStorage.setItem("meeting_category", encodeString(EMPLOYEE_LIST));
      const path = "/orgchart";
      this.setState({
        redirect: path,
        open: !open,
        button1Caption: '',
        button2Caption: '',
        title: '',
        message: '',
        redirestCategary: EMPLOYEE_LIST
      },
        () => this.props.setMeetingCategory(EMPLOYEE_LIST)
      );
    } catch (error) {

    }
  }
  handleConfirmNo = () => {
    const { open } = this.state;
    this.setState({
      open: !open,
      button1Caption: '',
      button2Caption: '',
      title: '',
      message: '',
      redirestCategary: ''
    });
  }
  handleConfirmClose = () => {/**NOSONAR */
    const { open } = this.state;
    this.setState({
      open: !open,
      button1Caption: '',
      button2Caption: '',
      title: '',
      message: '',
      redirestCategary: ''
    });
  }
  render() {
    const { employeeStatus } = this.props;
    const { redirect ,redirestCategary} = this.state;
    if (redirect) {
      this.props.setMeetingCategory(redirestCategary);
      return <Redirect to={redirect} />;
    }
    return (
      <div>
        <div className='col-12' style={{ height: 30, textAlign: 'center', borderTop: '1px solid gray', }}>
          <div className=''>
            <div className='col-1 footer-button'>

              <div style={{ height: '100%', width: '100%', color: '#fff' }}>
                .
                  </div>
            </div>
            <div className='col-8' style={{ color: '#fff' }}>
              .
            </div>
            <div className='col-3' style={{ textAlign: 'right' }}>
              <div className='footer-button'>
                {
                  employeeStatus !== "Absconded" &&
                  <Button
                    className="e-event-details text-light ml-sm " variant="contained" title="SUBMIT" color='primary' size='small'
                    onClick={() => this.onSubmitAbsconding()}
                  >
                    SUBMIT
                </Button>
                }
                {/* <Button
                    style={{ background: "#FFF", color: '#000', border: '1px solid ', marginLeft:5 }}
                    className="e-event-create footer-button" variant="contained" title="Cancel" size='small'>
                    Cancel
                    </Button> */}

              </div>
            </div>
            <ConfirmationPopup
              showButton={{ type: "1" }}
              open={this.state.open}
              button1function={() => this.handleSubmitYes()}
              button2function={() => this.handleConfirmNo()}
              headerMessage={this.state.message}
              button1Text={this.state.button1Caption}
              button2Text={this.state.button2Caption}
              handleConfirmClose={() => this.handleConfirmNo()}
            />  
          </div>
        </div>
      </div>
    );
  }
}
AbscondingFooterButtons.propTypes = {
  authUser: PropTypes.string,
};
AbscondingFooterButtons.defaultProps = {
  authUser: "",
};
const mapStateToProps = ({ separationreducer, auth }) => {
  const { authUser } = auth;
  const { abscondingManagerRemark, absondingDate, empid, employeeStatus } = separationreducer;
  return { authUser, abscondingManagerRemark, absondingDate, empid, employeeStatus };
};

export default connect(mapStateToProps, { submitAbscondingData, setMeetingCategory })(AbscondingFooterButtons);
