
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import OnLeaveEmployeeList from './onleaveemployeelist';
import LnAService from '../../../../services/lna/lnaservice';
import { updateLnAData } from '../../../../redux/actions/lna/lnaaction';
import ProgressBar from '../../../progressbar/progressbar';
import {
    MultiSelectComponent,
    CheckBoxSelection,
    Inject
} from "@syncfusion/ej2-react-dropdowns";
import { refreshMultiSelect } from '../../../../app/other/commonfunction'
export const OnLeave = (props) => {
    const [state, setState] = useState(
        {
            startDate: '',
            endDate: '',
            noOfLeaveDays: '',
            reason: '',
            relieverName: '',
            userName: '',
            teamMemberIds: "",
            selectTeamMemberIds: "",
            height: null,
            showDropDown: false,
            showLoader: true
        })
    const { handleCalendarDisplay,
        authUser, othersCheckAvailablity, updateFinancialYear, type, user, applyFor, onLeaveView } = props;

    const [teamMemberOnLeave, setTeamMemberOnLeave] = useState([]);

    useEffect(() => {
        setState(preState => ({ ...preState, showLoader: true }))
        getMasterData(state.teamMemberIds);
        updateDimensions();
    }, [onLeaveView, state.teamMemberIds, user, applyFor])

    useEffect(() => {
        if (updateFinancialYear === "updated") {
            setState(preState => ({ ...preState, showLoader: true }))
            getMasterData(state.teamMemberIds);
            updateDimensions();
        }
    }, [updateFinancialYear])

    const updateDimensions = () => {
        const updateheight = window.innerHeight - 398
        setState(preState => ({ ...preState, height: updateheight, }))
    }

    const getMasterData = async (val) => {
        try {
            const onLeaveData = await LnAService.getOnLeaveData(user ? user : (applyFor ? applyFor : authUser), '0', onLeaveView, val)
            const leaveData = onLeaveData.Result
            let newResult = []
            leaveData.map(each => {
                newResult.push(
                    {
                        userId: each.userId,
                        teamMemberName: each.userName,
                        startDate: each.startDate,
                        endDate: each.endDate,
                        noOfLeaveDays: each.noOfLeaveDays,
                        symbol: each.symbol,
                        onLeave: each.appliedDates.split(",").map((e) => ({
                            from: e.split("_")[0],
                            to: e.split("_")[1],
                            days: e.split("_")[2],
                        }))
                    },
                )
            })
            setTeamMemberOnLeave(newResult)
            setState(preState => ({ ...preState, showLoader: false, }))
        } catch (e) {
            setState(preState => ({ ...preState, showLoader: false, }))
        }
    }


    const openTab = (evt, tabName) => {
        props.updateLnAData({ onLeaveView: tabName });
    };

    /**
    * getSelectedTeamMeamber
    * @param {*} e :event
    * this function is used to select team member from  dropdown.
    */

    const getSelectedMemeber = (e) => {
        getMasterData((state.teamMemberIds != '' ? (state.teamMemberIds + ',' + e.itemData.UserId) : (e.itemData.UserId)).toString());
        setState(prevState => ({ ...prevState, teamMemberIds: (state.teamMemberIds != '' ? (state.teamMemberIds + ',' + e.itemData.UserId) : (e.itemData.UserId)).toString() }));
        document.getElementById('select-member').innerHTML = '';
    }
    const removeSelectedMemeber = (e) => {

        if (e.e.target.className === 'e-chips-close e-close-hooker') {
            setState(prevState => ({ ...prevState, teamMemberIds: '' }));
        }
        let selectTeamMemberIds = state.teamMemberIds.split(',');
        selectTeamMemberIds = selectTeamMemberIds.filter(el => parseInt(el) != e.itemData.UserId).join(",")
        setState(prevState => ({ ...prevState, teamMemberIds: selectTeamMemberIds }));
    }

    const created = (pDivId, pComponentId) => {
        try {
            refreshMultiSelect(pDivId, pComponentId);
            setTimeout(() => {
                setState(prevState => ({ ...prevState, showDropDown: true }));
                // document.getElementById(pComponentId).ej2_instances[0].refresh()
            }, 1000)
        } catch (error) {
            console.log("🚀 ~ file: timeinbusiness.js ~ line 433 ~ TimeInBusiness ~ created ~ error", error)
        }
    }

    const { teamMemberIds } = state

    return (
        <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 p-md' id='multigroupTeamMember' style={{ overflowY: 'auto' }}>
            <div className='row'>
                <div id="multigroupchkUser" className="col-xs-12 col-sm-12 col-lg-12 col-md-12 pl-md pr-md" style={{ display: state.showDropDown ? "block" : "none" }}>
                    <MultiSelectComponent
                        id="checkboxUsr"
                        name="selectTeamMemberErr"
                        cssClass="e-outline smallResolutionText"
                        // eslint-disable-next-line
                        dataSource={othersCheckAvailablity}
                        value={teamMemberIds && teamMemberIds.split(",").map(Number)}
                        fields={{
                            text: "nameWithDepartment",
                            value: "UserId",
                            groupBy: "Department"
                        }}
                        placeholder="Select Team Member *"
                        mode="CheckBox"
                        showSelectAll={false}
                        showDropDownIcon
                        select={getSelectedMemeber}
                        showClearButton={true}
                        filterBarPlaceholder="Select Team Member*"
                        popupHeight="350px"
                        // maximumSelectionLength={5}
                        removed={removeSelectedMemeber}
                        created={() => created("multigroupchkUser", "checkboxUsr")}
                        clear={true}
                    >
                        <Inject services={[CheckBoxSelection]} />
                    </MultiSelectComponent>
                    <div
                        id="select-member"
                        className="e-input-group e-float-icon-left error-div"
                    />
                </div>
            </div>
            <div className='row'>
                <div style={{ display: "flex", flexDirection: "row" }}>
                    <div
                        id="tabLeaveBalnce"
                        type="submit"
                        className={`col-2 col-lg-2 col-md-2 col-sm-2 col-xs-2 smallResolutionText onleave-balance-individual ${onLeaveView === "today" ? "active-leave" : ""}`}
                        onClick={(e) => { openTab(e, "today") }}
                        style={{ textAlign: 'center', cursor: "pointer" }}
                    >
                        Today
                    </div>
                    <div
                        id="tabOnLeave"
                        type="submit"
                        className={`col-2 col-lg-2 col-md-2 col-sm-2 col-xs-2 smallResolutionText onleave-balance-individual ${onLeaveView === "thisWeek" ? "active-leave" : ""}`}
                        onClick={(e) => { openTab(e, "thisWeek") }}
                        style={{ textAlign: 'center', cursor: "pointer" }}
                    >
                        This Week
                    </div>
                    <div
                        id="tabOnLeave"
                        type="submit"
                        className={`col-2 col-lg-2 col-md-2 col-sm-2 col-xs-2 smallResolutionText onleave-balance-individual ${onLeaveView === "thisMonth" ? "active-leave" : ""}`}
                        onClick={(e) => { openTab(e, "thisMonth") }}
                        style={{ textAlign: 'center', cursor: "pointer" }}
                    >
                        This Month
                    </div>
                    <div
                        id="tabOnLeave"
                        type="submit"
                        className={`col-4 col-lg-4 col-md-4 col-sm-4 col-xs-4 smallResolutionText onleave-balance-individual ${onLeaveView === "nextMonth" ? "active-leave" : ""}`}
                        onClick={(e) => { openTab(e, "nextMonth") }}
                        style={{ textAlign: 'center', cursor: "pointer" }}
                    >
                        Next Month Onwards
                    </div>
                </div>
                {teamMemberOnLeave.length > 0 && (
                    <div
                        style={{
                            height: "48px",
                            backgroundColor: "#F6F6F6",
                            color: "black",
                            fontStyle: "Circular Std Book",
                            fontFamily: "Arial",
                            fontWeight: "bold",
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                        }}>
                        <div className="col-2 col-lg-2 col-md-2 col-sm-2 col-xs-2 text-center onleave-balance-individual">Name</div>
                        <div className="col-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 onleave-balance-individual"></div>
                        <div className="col-4 col-lg-4 col-md-4 col-sm-4 col-xs-4 text-center onleave-balance-individual">Leave Type</div>
                    </div>
                )}
                <div>

                    <div className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 on-leave-container" style={{
                        overflowY: 'auto', overflowX: "hidden",
                        height: type === "approvalsubmit" || type === "compOffApprovalSubmit" ? state.height - 20 : state.height

                    }}>
                        {teamMemberOnLeave.map((tmLeave) => {
                            const teamMemberSeperatedName = tmLeave.teamMemberName.split(" ");
                            const symbol = tmLeave.symbol;
                            const firstLetter = teamMemberSeperatedName[0].charAt(0);
                            const secondLetter = teamMemberSeperatedName[1].charAt(0);

                            return (
                                <OnLeaveEmployeeList
                                    tmLeave={tmLeave}
                                    firstLetter={firstLetter}
                                    secondLetter={secondLetter}
                                    symbol={symbol}
                                    handleCalendarDisplay={handleCalendarDisplay}
                                // differenceBetween={differenceBetween}
                                />
                            )
                        })

                        }
                        {teamMemberOnLeave != 'undefined' && teamMemberOnLeave.length <= 0 && (
                            <div className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 on-leave-container" >
                                <div className="upcoming-leaves no-data-in-on-leave-margin" ><center>No Upcoming Leaves</center></div>
                            </div>
                        )}
                    </div>
                    {(state.showLoader || !state.showDropDown) && (
                        <div
                            style={{
                                zIndex: 999999,
                                position: "absolute",
                                marginTop: "40%",
                                marginLeft: "49%",
                            }}
                        >
                            <ProgressBar />
                        </div>
                    )}

                </div>


            </div>

        </div>

    )
}

OnLeave.propTypes = {
    //   second: PropTypes.third
}


const mapStateToProps = ({ lnareducer, auth, meetingmaster }) => {
    const { authUser } = auth;
    const { othersCheckAvailablity } = meetingmaster;
    const { leaveType, leaveStartDate, leaveEndDate, leaveReason, notificationTo, reliever,
        leaveTypeData, relieverData, leaveApplyStatus, isStreched, isWeekendCounted, leaveApplicationId, updateFinancialYear, applyFor, onLeaveView } = lnareducer;
    return {
        leaveType, leaveStartDate, leaveEndDate, leaveReason, notificationTo, reliever, authUser, leaveTypeData, relieverData, leaveApplyStatus,
        isStreched, isWeekendCounted, leaveApplicationId, othersCheckAvailablity, updateFinancialYear, applyFor, onLeaveView
    }
}

export default connect(mapStateToProps, { updateLnAData })(OnLeave)