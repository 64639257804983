import * as React from 'react';
import { Component } from "react"; // NOSONAR
import NotificationContainer from "../../components/notification/notifycontainer";
import "react-notifications/lib/notifications.css";
import { connect } from "react-redux";
import { Query } from "@syncfusion/ej2-data";
import { Button } from '@material-ui/core';
import Progressbar from '../../components/progressbar/progressbar';
import EfficiencyReport from './efficiencyreport';
import reportService from "../../services/reportsevices";
import ErrorMessage from '../errormessage/errormessage'
import './ecreport.css';
import { getNotify } from '../../app/other/commonfunction';
import { ECReportWarning, WARNING, ECReportMsg, monthSelect, ECReportWarningMaxUser } from '../../assets/constants/constants';
import {
    MultiSelectComponent,
    CheckBoxSelection,
    Inject,
    DropDownTreeComponent
} from "@syncfusion/ej2-react-dropdowns";
import { ecReportUsers } from '../../redux/actions/meetingbookingaction';
import { setMonths, setTIBMultipleMonthsIds, utilizationReportUpdate } from '../../redux/actions/tibacton';

import { getTibUserList } from '../../app/other/commonlayoutfunction'

const SAMPLECSS = `
.e-ddt .e-overflow.e-show-text {
    padding: 10px 3px 0 12px !important;
}
`
class ECReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedUserMonthsIds: [],

            changedData: false,
            userList: [],
            userIds: [],
            showLoader: true,
        };
        this.selectedSdUserList = [];
        this.showCheckBox = true;
        this.selectedMonth = new Date();
        this.getValues = this.getValues.bind(this);
        this.getReportData = this.getReportData.bind(this);
        this.getSelectedMonths = this.getSelectedMonths.bind(this)
        this.removeMonths = this.removeMonths.bind(this)
        this.UserSelected = false
        this.maxUserSelection = 10
    }
    componentDidMount = async () => {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
        try {
            const { authUser, tibMonts } = this.props;
            if (tibMonts.length === 0) {
                const tibData = await getTibUserList(authUser);
                this.props.setMonths(tibData.months);
                this.props.setTIBMultipleMonthsIds(tibData.mselectedIds);
                this.props.utilizationReportUpdate({ utilisationMonths: tibData.utrMonths });
            }
            const resposnse = reportService.getReportMasterData(authUser, 'Masters')
            resposnse.then((data) => {
                if (typeof data !== 'undefined' && data !== null) {
                    this.fields = { dataSource: data, value: 'id', parentValue: 'pid', text: 'name', hasChildren: 'hasChild' };
                    const { changedData } = this.state
                    let selectedTIBMonthsId = [];
                    if (tibMonts.length > 0) {
                        selectedTIBMonthsId.push(tibMonts[0].Id)
                    }
                    setTimeout(() => {
                        this.setState({ userList: data, changedData: !changedData, showLoader: false, selectedUserMonthsIds: selectedTIBMonthsId }, this.refreshTreeComponent);
                    }, 100)
                }
            })
            // 
        } catch (error) {
        }
    }

    componentWillUnmount() {
        try {
            this.props.ecReportUsers({ ecReportUsersList: [], ecReportId: 0 })
        } catch (error) {
        }
    }

    /**
   * Calculate & Update state of new dimensions
   */
    updateDimensions() {
        let updateHeight = (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight) - 150;
        if (document.querySelector(".usrsel") !== null) {
            updateHeight -= document.querySelector(".usrsel").offsetHeight;
        }
        // eslint-disable-next-line
        this.setState({ WindowHeight: updateHeight, changedData: !this.state.changedData });
    }

    async onDateChange(e) {
        this.selectedMonth = e.toString();
        const { changedData } = this.state
        this.setState({
            // showLoader: false, 
            changedData: !changedData
        });
    }

    /**
   * handleFilteringByName
   * this function is used for to search data in select user dropdown.
   */
    handleFilteringByName = (e) => {
        const newData = [];
        // eslint-disable-next-line
        this.state.userList.forEach((eachD) => {
            newData.push(eachD);
        });
        if (newData != null && newData.length > 0) {
            let query = new Query();
            query =
                e.text !== ""
                    ? query.where("user_name", "contains", e.text, true)
                    : query;
            e.updateData(newData, query);
        }
    };
    /**
   * shouldComponentUpdate
   * @param {*} nextProps
   * @param {*} nextState
   * This funcion is manage the rerender of this component using change data state.
   */
    shouldComponentUpdate(nextProps, nextState) {
        const { changedData } = this.state;
        if (changedData !== nextState.changedData) {
            return true;
        }
        return false;
    }
    getValues(e) {
        if (!this.UserSelected) {
            document.getElementById("select-sd-user").innerHTML = "";
            if (e.itemData.isChecked === 'true') {
                var eachEle = this.state.userList.find(x => x.id === parseInt(e.itemData.id, 10));
                if (typeof eachEle.fk_user_id === 'undefined' && eachEle.sd_id === 0) {
                    getNotify(WARNING, ECReportWarning)
                } else
                    this.selectedSdUserList.push(eachEle);
            }
            else if (e.itemData.isChecked === 'false') {
                this.selectedSdUserList = this.selectedSdUserList.filter(function (obj) {
                    return obj.id !== parseInt(e.itemData.id, 10);
                });

            }
            if (this.selectedSdUserList.length > this.maxUserSelection) {
                document.getElementById("select-sd-user").innerHTML = ECReportWarningMaxUser;
            }
        }
    }
    getReportData() { // NOSONAR
        const { userList, changedData, selectedUserMonthsIds } = this.state
        this.selectedSdUserList = [...new Set(this.selectedSdUserList)]

        if (selectedUserMonthsIds.length === 0 || this.selectedSdUserList.length === 0) {
            if (selectedUserMonthsIds.length === 0) {
                document.getElementById("select-month").innerHTML = monthSelect;
            }
            if (this.selectedSdUserList.length === 0) {
                document.getElementById("select-sd-user").innerHTML = ECReportMsg;
            }
            return;
        }
        if (this.selectedSdUserList.length > this.maxUserSelection) {
            document.getElementById("select-sd-user").innerHTML = ECReportWarningMaxUser;
            return
        }
        this.setState({ showLoader: true, changedData: !changedData });
        // remove duplicates
        var list = [];
        let i = 0;
        if (this.selectedSdUserList.length > 0 && selectedUserMonthsIds.length > 0) {
            this.selectedSdUserList.forEach(eachItem => {
                let sdPAId, empId;
                if (typeof eachItem.fk_user_id === 'undefined') {
                    sdPAId = eachItem.sd_id
                } else {
                    sdPAId = userList.filter(x => (x.id === parseInt(eachItem.pid, 10) && x.hasChild === true))[0].sd_id
                }
                empId = typeof eachItem.fk_user_id === 'undefined' ? 0 : eachItem.fk_user_id
                let key = empId.toString() + sdPAId.toString() + selectedUserMonthsIds.toString().replace(/,/gi, '')
                i = i + 1;
                var listObj = {};
                listObj.empId = empId;
                listObj.sdPAId = sdPAId;
                listObj.monthYear = selectedUserMonthsIds.toString();
                listObj.key = key
                listObj.Id = i
                list.push(listObj);
            });
            // selectedUserMonthsIds.forEach(item => {
            //     var monYr = new Date(new Date(parseInt(item, 10) * 1000));
            //     var monthYear = monYr.getFullYear() + '-' + ('0' + (monYr.getMonth() + 1)).slice(-2) + '-' + ('0' + monYr.getDate()).slice(-2);
            //     var monthYearKey = monYr.getFullYear() + ('0' + (monYr.getMonth() + 1)).slice(-2) + ('0' + monYr.getDate()).slice(-2);

            //     this.selectedSdUserList.forEach(eachItem => {
            //         let sdPAId, empId;
            //         if (typeof eachItem.fk_user_id === 'undefined') {
            //             sdPAId = eachItem.sd_id
            //         } else {
            //             sdPAId = userList.filter(x => (x.id === parseInt(eachItem.pid, 10) && x.hasChild === true))[0].sd_id
            //         }
            //         empId = typeof eachItem.fk_user_id === 'undefined' ? 0 : eachItem.fk_user_id
            //         let key = empId.toString() + sdPAId.toString() + monthYearKey.toString()

            //         var listObj = {};
            //         listObj.empId = empId;
            //         listObj.sdPAId = sdPAId;
            //         listObj.monthYear = monthYear;
            //         listObj.key = key
            //         list.push(listObj);
            //     });
            // })
            this.setState({ changedData: !changedData, showLoader: false });
            this.props.ecReportUsers({ ecReportUsersList: list, ecReportId: 0 })
        } else {
            this.props.ecReportUsers({ ecReportUsersList: list, ecReportId: 0 })
            this.setState({ changedData: !changedData, showLoader: false });
        }
        setTimeout(() => {
            this.props.ecReportUsers({ ecReportId: 1 })
        })
    }

    /**
  * getSelectedMonths
  * @param {*} e :event
  * this function is used for to select the element of remove user dropdown data.
  */
    getSelectedMonths = (e) => {
        document.getElementById("select-month").innerHTML = "";
        // eslint-disable-next-line
        let selectedMonthsIds = this.state.selectedUserMonthsIds;
        selectedMonthsIds.push(e.itemData.Id);
        this.setState({ selectedUserMonthsIds: selectedMonthsIds });
    };

    /**
     * removeMonths
     * @param {*} e :event
     * this function is used for to select the element of month remove dropdown data.
     */
    removeMonths(e) {
        // eslint-disable-next-line
        let selectedUserMonthsIds = this.state.selectedUserMonthsIds;
        // eslint-disable-next-line
        selectedUserMonthsIds = selectedUserMonthsIds.filter(el => el !== e.itemData.Id
        );
        if (selectedUserMonthsIds.length === 0) {
            this.setState({ selectedUserMonthsIds });
            document.getElementById("select-month").innerHTML =
                monthSelect;
            return;
        }
        this.setState({ selectedUserMonthsIds });
    }
    change(e) {
        try {
            this.UserSelected = true
            document.getElementById("select-sd-user").innerHTML = "";
            this.selectedSdUserList = []
            e.value.forEach(item => {
                var eachEle = this.state.userList.find(x => x.id === parseInt(item, 10));
                if (typeof eachEle.fk_user_id === 'undefined' && eachEle.sd_id === 0) {
                    getNotify(WARNING, ECReportWarning)
                } else
                    this.selectedSdUserList.push(eachEle);
            })
            if (this.selectedSdUserList.length > this.maxUserSelection)
                document.getElementById("select-sd-user").innerHTML = ECReportWarningMaxUser;
        } catch (error) {

        }
    }
    created(pDivId, pComponentId) {
        try {
            setTimeout(() => {
                document.getElementById(pComponentId).ej2_instances[0].refresh()
            })
        } catch (error) {
            console.log("🚀 ~ file: timeinbusiness.js ~ line 433 ~ TimeInBusiness ~ created ~ error", error)
        }
    }

    refreshTreeComponent = () => {
        try {
            setTimeout(() => {
                document.getElementById("sdpatree").ej2_instances[0].showPopup()
                document.getElementById("sdpatree").ej2_instances[0].hidePopup()
            }, 100)
        } catch (err) {

        }

    }
    render() {
        const { showLoader, userList } = this.state;
        const { ecReportUsersList } = this.props
        const { authUser } = this.props;
        let counter = 0;
        return (
            <div className='control-pane col-lg-12 control-section'>
                <NotificationContainer />
                <div className="row" style={{ padding: 5, margin: "10px 10px 5px 10px", display: showLoader ? "none" : "block" }} >
                    <div className="col-lg-3" >
                        <div id="multigroup" className="control-styles" >
                            <style>{SAMPLECSS}</style>
                            <DropDownTreeComponent
                                id="sdpatree"
                                style={{ padding: 10 }}
                                fields={{ dataSource: userList, value: 'id', parentValue: 'pid', text: 'name', hasChildren: 'hasChild' }}
                                ref={(scope) => { this.ddtreeObj = scope; }}
                                showCheckBox={this.showCheckBox}
                                select={(e) => { this.getValues(e) }}
                                change={this.change.bind(this)}
                                mode="Delimiter"
                                filterType="Contains"
                                allowFiltering={true}
                                placeholder="Select Practice Area / Sales Unit / Sub Function / User"
                                popupHeight="300px"
                                cssClass="e-outline"
                                ignoreCase={true}
                                showClearButton={true}
                                allowMultiSelection={false}
                                maximumSelectionLength={10}
                            // dataBound={this.refreshTreeComponent}
                            />
                        </div>
                        <div id="select-sd-user" className="error-div pl-sm" />

                    </div>
                    <div className="col-lg-1" style={{ width: 250 }}>
                        <div id="multigroup" className="control-styles">
                            <MultiSelectComponent
                                id="checkboxMnth"
                                ref={(scope) => {
                                    this.mulObj = scope;
                                }}
                                cssClass="e-outline"
                                // eslint-disable-next-line
                                dataSource={this.props.tibMonts}
                                // eslint-disable-next-line
                                value={this.state.selectedUserMonthsIds}
                                fields={{ text: "Name", value: "Id" }}
                                placeholder="Select Month"
                                mode="CheckBox"
                                showSelectAll={false}
                                showDropDownIcon
                                select={this.getSelectedMonths}
                                removed={this.removeMonths}
                                filterBarPlaceholder="Select Month"
                                popupHeight="350px"
                                maximumSelectionLength={2}
                                created={this.created("multigroup", "checkboxMnth")}
                            >
                                <Inject services={[CheckBoxSelection]} />
                            </MultiSelectComponent>
                        </div>
                        <div id="select-month" className="error-div pl-sm" />
                    </div>

                    <div className="col-lg-1">
                        <div id="multigroup" className="control-styles">
                            <Button
                                onClick={this.getReportData}
                                style={{ color: !showLoader ? "white" : "#c1baba" }}
                                disabled={showLoader}
                                color="primary"
                                variant="contained"
                                title="View Report">
                                View
                            </Button>
                        </div>
                    </div>
                </div>
                <div className='scrollbar control-section component-section'
                    style={{
                        height: this.state.WindowHeight,
                        marginLeft: 10,
                        marginRight: 10,
                        overflow: "auto",
                        width: "100%"
                    }}>
                    {showLoader ?
                        <Progressbar />
                        :
                        <div className='content-wrapper'>
                            {
                                ecReportUsersList !== undefined && //NOSONAR
                                    ecReportUsersList.length > 0 ?
                                    ecReportUsersList.map((item, index) =>
                                    (

                                        (item.empId !== 0 || item.sdPAId !== 0) &&
                                        <div key={`divEfficiency${item.key}`} id={`divEfficiency${item.key}`} className="overflow-hidden" >
                                            <div style={{ "display": "none" }}>
                                                {
                                                    counter = (counter + (document.getElementById(`divEfficiency${item.key}`)) ? 0 : 1) // NOSONAR
                                                }
                                            </div>
                                            {
                                                <EfficiencyReport
                                                    reportKey={item.key}
                                                    empId={item.empId}
                                                    sdPAId={item.sdPAId}
                                                    monthYear={item.monthYear}
                                                    authUser={authUser}
                                                    actualIndex={counter}
                                                    Id={item.Id}
                                                />
                                            }
                                        </div>
                                    )
                                    )
                                    :
                                    <div className='row' style={{ width: '100%' }}>
                                        <ErrorMessage message={ECReportWarning} />
                                    </div>
                            }
                        </div>
                    }
                </div>
            </div>);
    }
}
const mapStateToProps = ({ auth, tibReducer, meetingbooking }) => {
    const { authUser } = auth;
    const { tibMonts, selectedTIBMonthsId } = tibReducer;
    const { ecReportUsersList } = meetingbooking;
    return { authUser, tibMonts, selectedTIBMonthsId, ecReportUsersList };
};
export default connect(mapStateToProps, { ecReportUsers, setMonths, setTIBMultipleMonthsIds, utilizationReportUpdate })(ECReport);