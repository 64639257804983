import React, { useState, useEffect } from "react";
import { ComboBoxComponent } from "@syncfusion/ej2-react-dropdowns";
import { TextBoxComponent, NumericTextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import { SwitchComponent } from "@syncfusion/ej2-react-buttons";
import { connect } from "react-redux";
import { FormValidator } from "@syncfusion/ej2-inputs";
import LnAServices from "../../services/lna/lnaservice";
import { saveDayBreakCluster, updateLnAData } from "../../redux/actions/lna/lnaaction";
import { createImageFilepathForLPView } from "../okrratingcomponents/ratingcommonfun";
import UploadDocuments from "../okrratingcomponents/uploaddocuments";
import {
  pleaseEnterDayBreak,
  pleaseEnterSymbol,
  selecteffectivestartdate,
  effectiveStartDateVaidation,
} from "../../assets/constants/constants";
import ProgressBar from "../progressbar/progressbar";
import { getMySqlDateFormat } from "../../app/other/commonfunction";
const SAMPLECSS = `
input.e-input, textarea.e-input, .e-input-group, .e-input-group.e-control-wrapper {
  font-size: 12px;
}
`
function DayBreakCluster(props) {
  //NOSONAR
  const { authUser, openPopup, editClusterId } = props;
  const [state, setState] = useState({
    dayBreakClusterUid: null,
    dayBreak: null,
    symbolId: null,
    description: null,
    leavesAllowed: "0",
    attendanceCorrectionAllowed: "0",
    daybreakPolicyDoc: [],
    effectiveStartDate: null,
    effectiveEndDate: null,
    isActive: "1",
    workdayWindowData: [],
    title: "",
    fileName: "",
    oldFileStatus: "",
  });
  const [initialLoad, setInitialLoad] = useState(false)
let validOptions = {
  rules: {
    dayBreakErr: {
      required: [true, pleaseEnterDayBreak],
    },
    symbolErr: {
      required: [true, pleaseEnterSymbol],
    },
  },
};

  useEffect(() => {
    setTimeout(() => {
      props.setSubmitClick(onSaveData);
    }, 100);
  }, [state]);

  useEffect(() => {
    props.updateLnAData({ popUpSource: 'Day Break Master' })
    getData();
  }, []);
  useEffect(() => {
    getData();
  }, [editClusterId, openPopup]);

  useEffect(() => {

    if (props.lnaMasterFlag === "updated")

      props.changePopUpHandler("close");

  }, [props.lnaMasterFlag]);

  const getData = () => {
    if (openPopup === "add") {
      resetData()
    } else {
      editClusterId !== null &&
        (async () => {
          setInitialLoad(true)
          const dayBreakClusterData = await LnAServices.getDayBreakClusterData(
            authUser,
            editClusterId
          );

          const {
            dayBreakClusterUid,
            dayBreak,
            symbolId,
            description,
            leavesAllowed,
            attendanceCorrectionAllowed,
            effectiveStartDate,
            effectiveEndDate,
            isActive,
            fileName,
          } = dayBreakClusterData.Result[0];
          let data = {
            dayBreakClusterUid,
            dayBreak,
            symbolId,
            description,
            leavesAllowed: leavesAllowed === "Yes" ? "1" : "0",
            attendanceCorrectionAllowed:
              attendanceCorrectionAllowed === "Yes" ? "1" : "0",
            effectiveStartDate,
            effectiveEndDate,
            isActive,
            fileName,
            oldFileStatus: fileName,
          };
          setInitialLoad(false)
          setState((preState) => ({ ...preState, ...data }));
          setTimeout(() => {
            const addFormObject = new FormValidator("#DayBreakClusterForm", validOptions);
            addFormObject.validate();
          }, 100)
        })();
    }
  };
  const resetData = () => {
    let data = {
      dayBreakClusterUid: null,
      dayBreak: null,
      symbolId: null,
      description: null,
      leavesAllowed: "0",
      attendanceCorrectionAllowed: "0",
      daybreakPolicyDoc: [],
      effectiveStartDate: null,
      effectiveEndDate: null,
      isActive: "1",
      workdayWindowData: [],
      title: "",
      fileName: "",
      oldFileStatus: "",
    };
    setState((preState) => ({ ...preState, ...data }));
  }

  const documentDetails = (currentTime, file) => {
    setState((prevState) => ({ ...prevState, fileName: file }));
  };

  const inputChangeHandler = (e, type) => {
    let options = {};
    switch (type) {
      case "dayBreak":
        setState((prevState) => ({ ...prevState, dayBreak: e.value }));
        if(e.value !== null){
          document.getElementById('dayBreakErr').innerText=''
        }
        options = validOptions.rules['dayBreakErr'];
        break;
      case "symbol":
        setState((prevState) => ({
          ...prevState,
          symbolId: e.itemData.Id.toString(),
        }));
        options = validOptions.rules['symbolErr'];
        break;
      case "description":
        setState((prevState) => ({ ...prevState, description: e.value ? e.value.trim() : "" }));
        break;
      case "leavesAllowed":
        setState((prevState) => ({
          ...prevState,
          leavesAllowed: e.checked === true ? "1" : "0",
        }));
        break;
      case "attendanceCorrectionAllowed":
        setState((prevState) => ({
          ...prevState,
          attendanceCorrectionAllowed: e.checked === true ? "1" : "0",
        }));
        break;
      case "effectiveStartDate":
        setState((prevState) => ({
          ...prevState,
          effectiveStartDate: e.value,
        }));
        if (document.getElementById("effectiveStartDateErr")) {
          document.getElementById("effectiveStartDateErr").innerHTML = "";
        }
        break;
      case "effectiveEndDate":
        setState((prevState) => ({ ...prevState, effectiveEndDate: e.value }));
        break;
      case "isActive":
        setState((prevState) => ({
          ...prevState,
          isActive: e.checked === true ? "1" : "0",
        }));
        break;
      default:
    }

    setTimeout(() => {
      const addFormObject = new FormValidator("#DayBreakClusterForm", options);
      addFormObject.validate();
    }, 100);
  };

  const onSaveData = () => {
    let validateDates = true;

   if (state.effectiveStartDate === null
    ) {
      document.getElementById("effectiveStartDateErr").innerHTML = selecteffectivestartdate;
      validateDates = false;
    } else if (state.effectiveStartDate !== null && state.effectiveEndDate !== null
    ) {
      if (new Date(state.effectiveStartDate) > new Date(state.effectiveEndDate)) {
        document.getElementById("effectiveStartDateErr").innerHTML =
        effectiveStartDateVaidation;
        validateDates = false;
      }
    }


    const addFormObject = new FormValidator("#DayBreakClusterForm", validOptions);
    if (addFormObject.validate() && validateDates) {
      setInitialLoad(true)
      const {
        dayBreakClusterUid,
        dayBreak,
        symbolId,
        description,
        leavesAllowed,
        attendanceCorrectionAllowed,
        effectiveStartDate,
        effectiveEndDate,
        isActive,
        fileName,
      } = state;

      let dayBreakClusterSaveData = {
        userId: authUser,
        dayBreakClusterUid,
        type: dayBreakClusterUid > 0 ? "EDIT" : "ADD",
        dayBreak,
        symbolId,
        description,
        leavesAllowed,
        attendanceCorrectionAllowed,
        effectiveStartDate:
          effectiveStartDate !== "" && effectiveStartDate !== null
            ? getMySqlDateFormat(effectiveStartDate)
            : null,
        effectiveEndDate:
          effectiveEndDate !== "" && effectiveEndDate !== null
            ? getMySqlDateFormat(effectiveEndDate)
            : "",
        isActive,
        fileName,
      };
      props.saveDayBreakCluster(dayBreakClusterSaveData);
      setTimeout(() => {
        setInitialLoad(false)
      }, 10)

    }


  };

  const selectedFiles = (fileNms) => {
    try {
      setState((preState) => ({ ...preState, daybreakPolicyDoc: fileNms }));
    } catch (error) { }
  };
  const removeFile = () => {
    setState(preState => ({ ...preState, fileName: "" }))
  }

  const descriptionTextBox = `.e-outline textarea, textarea.e-outline, .e-outline.e-float-input textarea, .e-outline.e-float-input.e-control-wrapper textarea, .e-outline.e-float-input.e-input-group textarea {
    box-sizing: border-box;
    min-height: 100px;
  }`
  const getOneDayForward = (dt)=>{
    let date = dt
    date.setDate(date.getDate() + 1)
    return date
  }

  return (
    <>
      {/* <div> */}
      <div
        id="dayBreakClusterForm"
        style={{
          height: "61.5vh",
          overflowY: "scroll",
          paddingLeft: 16,
          paddingRight: 16,
          transition: "width 5s",
        }}
      >
        <style>{SAMPLECSS}</style>
        <div className="col-sm-12 col-md-12 col-lg-12 mt-lg">
          <NumericTextBoxComponent
            data-msg-containerid="dayBreakErr"
            id="dayBreak"
            value={state.dayBreak}
            autocomplete={'off'}
            name="dayBreakErr"
            placeholder="Day Break *"
            floatLabelType="Auto"
            cssClass="e-outline"
            min={0}
            step={0.01}
            onChange={(e) => inputChangeHandler(e, "dayBreak")}
          // htmlAttributes={{ maxlength: 100 }}
          />
          <div
            id="dayBreakErr"
            className="e-input-group e-float-icon-left error-div"
          />
        </div>
        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 mt-sm">
          <ComboBoxComponent
            data-msg-containerid="symbolErr"
            id="symbol"
            dataSource={props.dayBreakData}
            fields={{ text: "Name", value: "Id" }}
            value={state.symbolId}
            name="symbolErr"
            placeholder="Symbol *"
            floatLabelType="Auto"
            cssClass="e-outline"
            allowCustom={false}
            select={(e) => inputChangeHandler(e, "symbol")}
          />
          <div
            id="symbolErr"
            className="e-input-group e-float-icon-left error-div"
          />
        </div>

        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 mt-sm  dayBreakDescriptionClass">
          <style>{descriptionTextBox}</style>
          <TextBoxComponent
            style={{ minHeight: 100 }}
            id={'dayBreakDescription'}
            autocomplete={'off'}
            placeholder={"Description "}
            cssClass="e-outline textarea"
            floatLabelType="Auto"
            name="descriptionErr"
            data-msg-containerid="descriptionErr"
            multiline
            inputProps={{
              required: true,
              minLength: 0,
            }}
            htmlAttributes={{ maxlength: 500 }}
            onChange={(e) => inputChangeHandler(e, "description")}
            value={state.description}
          />
          {/* <div id="descriptionErr" className="error-div" /> */}
        </div>
        <div
          className="col-xs-12 col-sm-12 col-lg-12 col-md-12 pb-lg"
          style={{ paddingTop: 19 }}
        >
          <div
            className="col-xs-12 col-sm-12 col-lg-12 col-md-12"
            style={{ paddingLeft: 0 }}
          >
            <div className="atc-switch-heading">Leaves Allowed</div>
            <div className="col-xs-1 col-sm-1 col-lg-1 col-md-1 atc-switch-inline atc-no-paddding atc-margin">
              {/* <div className="mr-md">No</div> */}
              <div className="atc-switch-margin">
                <SwitchComponent
                  id="leavesAllowed"
                  cssClass="atc-switch-height"
                  change={(e) => inputChangeHandler(e, "leavesAllowed")}
                  checked={state.leavesAllowed === "1" ? true : false}
                />
              </div>
              <div className="ml-md atc-switch-yes">Yes</div>
            </div>
          </div>
          <div
            className="col-xs-12 col-sm-12 col-lg-12 col-md-12 mt-lg"
            style={{ paddingRight: 0 }}
          >
            <div className="atc-switch-heading">
              Attendance Correction Allowed
            </div>
            <div className="col-xs-1 col-sm-1 col-lg-1 col-md-1 atc-switch-inline atc-no-paddding atc-margin">
              {/* <div className="mr-md">No</div> */}
              <div className="atc-switch-margin">
                <SwitchComponent
                  id="attendanceCorrectionAllowed"
                  cssClass="atc-switch-height"
                  change={(e) =>
                    inputChangeHandler(e, "attendanceCorrectionAllowed")
                  }
                  checked={
                    state.attendanceCorrectionAllowed === "1" ? true : false
                  }
                />
              </div>
              <div className="ml-md atc-switch-yes">Yes</div>
            </div>
          </div>
        </div>

        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
          <div
            className="col-xs-6 col-sm-6 col-lg-6 col-md-6"
            style={{ paddingLeft: 0 }}
          >
            <DatePickerComponent
              id="effectiveStartDate"
              // dataSource={dayBreakData}
              // fields={{ text: "Name", value: "Id" }}

              name="effectiveStartDateErr"
              data-msg-containerid="effectiveStartDateErr"
              // allowCustom={false}
              format="dd-MMM-yyyy"
              value={state.effectiveStartDate}
              floatLabelType="Auto"
              cssClass="e-outline"
              placeholder="Effective Start Date *"
              firstDayOfWeek={1}
              change={(e) => inputChangeHandler(e, "effectiveStartDate")}
            />
            <div
              id="effectiveStartDateErr"
              className="e-input-group e-float-icon-left error-div"
            />
          </div>
          <div
            className="col-xs-6 col-sm-6 col-lg-6 col-md-6"
            style={{ paddingRight: 0 }}
          >
            <DatePickerComponent
              id="effectiveEndDate"
              fields={{ text: "Name", value: "Id" }}
              value={state.effectiveEndDate}
              data-msg-containerid="effectiveEndDateErr"
              name="effectiveEndDateErr"
              format="dd-MMM-yyyy"
              floatLabelType="Auto"
              cssClass="e-outline"
              placeholder="Effective End Date "
              firstDayOfWeek={1}
              min={state.effectiveStartDate ? getOneDayForward(new Date(state.effectiveStartDate)): null}
              change={(e) => inputChangeHandler(e, "effectiveEndDate")}
            />
            <div
              id="effectiveEndDateErr"
              className="e-input-group e-float-icon-left error-div"
            />
          </div>
        </div>
        <div
          className="col-xs-12 col-sm-12 col-lg-12 col-md-12 mt-xl mb-md"
          style={{ paddingLeft: 8 }}
        >
          <div
            style={{
              fontSize: ".95rem",
              color: "#939399",
              fontWeight: 500,
              padding: ".5rem 0",
              marginTop: "-2rem",
            }}
          >
            Attach Policy
          </div>

          <UploadDocuments
            type={"daybreak"}
            module={'lna'}
            allowmultiple={false}
            selectedFiles={selectedFiles}
            // fileList={!state.daybreakPolicyDoc ? [] : state.daybreakPolicyDoc}
            fileList={[]}
            apiUrl={"lnaApiUrl"}
            documentDetails={documentDetails}
          // id={state.dayBreakClusterUid}
          />
        </div>
        {state.fileName !== null && state.oldFileStatus !== null ? (
          <div style={{ paddingLeft: 8 }}>
            {createImageFilepathForLPView(
              [].concat(state.fileName),
              "document/lna/daybreak",
              "lnaApiUrl", removeFile
            )}
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="loader-new1" id="spinnerContainer" >
        {initialLoad && <ProgressBar />}
      </div>
    </>
  );
}

const mapStateToProps = ({ auth, lnareducer, meetingmaster }) => {
  const { authUser } = auth;
  const { currentFinancialYearId } = meetingmaster;
  const {
    symbolData: { dayBreakData }, lnaMasterFlag
  } = lnareducer;

  return {
    authUser,
    dayBreakData,
    currentFinancialYearId, lnaMasterFlag
  };
};
export default connect(mapStateToProps, {
  saveDayBreakCluster, updateLnAData
})(DayBreakCluster);
