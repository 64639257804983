/**
 * Author By :- Vivek Khodade
 * Date : 15-07-2020
 * Description :- This file use for Orgchart laylout
 */
import * as React from 'react';
import { MainClass } from '../../../mainclass';
import { connect } from 'react-redux';
import OrgChart from '../../containers/tmlc/orgchart/orgchart';
import OrgChartView from '../../containers/tmlc/orgchart/orgchartview';
import OrgChartMaster from '../../containers/tmlc/orgchart/orgchartmasters';
import { ORG_CHART_MASTERS,ORG_CHART_NEW } from '../../../redux/constant/meetingcategoryconstant'
import { updateCandidateData } from '../../../redux/actions/entryexit/candidateaction'
import { addUpdateSeparationDetails } from '../../../redux/actions/entryexit/separationaction';
import { addUpdateObjectveKpi } from "../../../redux/actions/okr/okractions";
import OnbordingToBeOffer from "../../../services/entryexit/onbordingServices"
import queryString from "query-string";
import { dencrypt } from '../../other/commonfunction'
import { getOnboardingMasterData } from '../../other/commonlayoutfunction.js';
import  OrgChartContainer  from '../../containers/orgchart/orgchartcontainer'
class OrgchartLayout extends MainClass {
    constructor() {
        super(...arguments);
        this.state = {
            orgChartLoad: false,
            moduleId: 0
        }
    }

    setModuleId() {
        try {
            // Set App Id
            const { moduleId } = this.state
            const params = queryString.parse(this.props.location.search);
            if (typeof params.id !== 'undefined' && params.id !== null) {
                if (moduleId !== dencrypt(params.id))
                    this.setState({ moduleId: dencrypt(params.id) })
            }
        } catch (error) {

        }
    }

    async componentDidMount() {
        const { authUser, bandMaster } = this.props;
        this.setModuleId()
        if (bandMaster.length === 0) {
            const onboardingMasterData = await getOnboardingMasterData(authUser, 'All');
            this.props.updateCandidateData(onboardingMasterData);
        }
        setTimeout(() => {
            this.setState({ orgChartLoad: true })
        }, 100)
    }

    componentDidUpdate(newProps) {
        try {
            this.setModuleId()
        } catch (error) {
        }
    }

    componentWillReceiveProps(nextProps) {

        if (nextProps.reloadStatus === 'updated') {
            this.props.addUpdateSeparationDetails({ reloadStatus: '' })
        }
        if (nextProps.okrreloadStatus === 'updated') {
            this.props.addUpdateObjectveKpi({ okrreloadStatus: '' })
        }
    }

    render() {
        const { categoryName } = this.props;
        const { orgChartLoad } = this.state
        const { moduleId } = this.state
        let activeModule = (moduleId == 0 ? categoryName : moduleId).toString()       
        return (
            <div
                style={{
                    // height: '100%',
                    width: '100%',
                    color: '#FFF'
                }}
            >
                {
                    orgChartLoad &&
                    <>
                        {activeModule === '31' &&
                            <OrgChart />
                        }
                        {activeModule === '32' &&
                            <OrgChartView />
                        }
                        {activeModule === ORG_CHART_MASTERS &&
                            <OrgChartMaster />
                        }
                        {activeModule === ORG_CHART_NEW &&
                            <OrgChartContainer />
                        }
                    </>
                }
            </div>
        );
    }
}

const mapStateToProps = ({ meetingcategory, separationreducer, candidateReducer, auth, okrreducer }) => {
    const { authUser } = auth;
    const { categoryName } = meetingcategory;
    const { bandMaster } = candidateReducer
    const { reloadStatus } = separationreducer;
    const { okrreloadStatus } = okrreducer
    return { authUser, categoryName, reloadStatus, okrreloadStatus, bandMaster }
};
export default connect(mapStateToProps, { addUpdateSeparationDetails, updateCandidateData, addUpdateObjectveKpi})(OrgchartLayout);

