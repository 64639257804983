import React from 'react'
import { connect } from "react-redux";
import HelpdeskService from '../../../components/helpdeservice/helpdeskservice';
function NewTicketServices(props) {
return (
    <div style={{height:"100vh"}}>
         <HelpdeskService type="service"/>
    </div>
  )
}

const mapStateToProps = ({ auth, router }) => {
  const { authUser } = auth;
  const { location } = router;
  return { authUser, location };
};

export default connect(mapStateToProps, {
})(NewTicketServices);
