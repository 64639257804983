import React, { Component } from 'react'
import { PivotViewComponent, FieldList, Inject, ConditionalFormatting, NumberFormatting } from '@syncfusion/ej2-react-pivotview';
import reportService from "../../services/reportsevices";
import { secToTime } from '../../app/other/commonfunction';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faIdCard, faToolbox, faUser, faLaptopHouse } from '@fortawesome/free-solid-svg-icons';
import ProgressBar from '../progressbar/progressbar';
import { ecReportUsers } from '../../redux/actions/meetingbookingaction';
import { connect } from "react-redux";

const SAMPLECSS =
    `
.e-grid .e-rowcell{
    white-space:none !important;
    font-size:12px !important;
    justify-content: center!important;
    align-self: center !important;
    text-align: center !important;
  }
  
.e-grid .e-gridheader tr th {
    justify-content: center!important;
    align-self: center !important;
    text-align: center !important;
    padding: 0px 0px 0px 1px !important;
    font-weight: bold !important;
    color: black;
    padding-right: 0px !important;
  }
  
  .e-grid .e-gridheader .e-sortfilter .e-headercelldiv, .e-grid .e-gridheader .e-stackedheadercelldiv, .e-grid .e-gridheader .e-headercell .e-headercelldiv.e-headerchkcelldiv{
      padding:0px 0px !important      
  }
  .e-grid .e-gridheader .e-headercontent .e-movableheader .e-table>thead>tr>th .e-headercell .e-headercelldiv{    
    text-align:center !important
   }
   .e-headercelldiv {
    text-align: center !important;
    margin-bottom: -15px;
   }

   td.e-rowcell.e-rowsheader {
    text-align: left !important;
    }
    tr.e-columnheader {
        height: 30px !important;
    }
    
`
class EfficiencyReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            height: (window.innerHeight ||
                document.documentElement.clientHeight ||
                document.body.clientHeight) - 220,
            width: (window.innerWidth ||
                document.documentElement.clientWidth ||
                document.body.clientWidth) - 100
        }
        this.jsonReport = {
            valueSortSettings: { headerDelimiter: " - " },
            dataSource: {},
            type: 'JSON',
            expandAll: false,
            enableSorting: true,
            formatSettings: [
                { name: "totalTime", useGrouping: true },
                { name: "%", format: "N0", useGrouping: true },
                { name: "percentage", format: "P0", useGrouping: true }
            ],
            rows: [
                { name: 'mainCategory', caption: 'Main Category', showSubTotals: true },
                { name: 'meetingCategory', caption: 'Meeting Category', showSubTotals: true },
                { name: 'activityType', caption: 'Activity Type', showSubTotals: true },
                { name: 'subActivityType', caption: 'Sub Activity Type', showSubTotals: true },
                { name: 'subActivityType1', caption: 'Sub Activity Type1', showSubTotals: true },
                { name: 'subActivityType2', caption: 'BTB Id', showSubTotals: true }
            ],
            columns: [
                { name: 'monthType', caption: 'Month', showSubTotals: true },
                { name: 'weekType', caption: 'Week', showSubTotals: true },
                { name: 'meetingDate', caption: 'Date', showSubTotals: true }
            ],
            values: [
                { name: 'totalTime', caption: 'Time', type: 'Sum' },
                { name: 'percentage', caption: '%', type: 'PercentageOfParentTotal' },

            ],
            filters: [],
            conditionalFormatSettings: [
                {
                    measure: 'totalTime',
                    value1: "00:00",
                    conditions: 'Equals',
                    style: {
                        color: '#bfbfbf',
                    }
                },
                {
                    measure: 'percentage',
                    value1: "",
                    conditions: 'Equals',
                    style: {
                        color: '#bfbfbf',
                    }
                }
                ,
                {
                    measure: 'percentage',
                    value1: "0%",
                    conditions: 'Equals',
                    style: {
                        color: '#bfbfbf',
                    }
                }
                ,
                {
                    measure: 'percentage',
                    value1: undefined,
                    conditions: 'Equals',
                    style: {
                        color: '#bfbfbf',
                    }
                }
            ],
            showGrandTotals: false,
            showRowGrandTotals: false,
            showColumnGrandTotals: true,
            showSubTotals: true,
            showHeaderWhenEmpty: false,
            enableVirtualization: true,
            showAggregationOnValueField: false
        };
        this.data = {}
        this.aggregateCellInfo = this.aggregateCellInfo.bind()
        this.beforeColumnsRender = this.beforeColumnsRender.bind(this)
        this.queryCellInfo = this.queryCellInfo.bind(this)
        this.LoadingStatus = "None"
    }

    componentDidMount() { // NOSONAR

    }

    aggregateCellInfo = (args) => {
        try {
            if (args.fieldName === "totalTime") {
                if (args.row.ordinal !== 1) {
                    args.skipFormatting = true
                    args.value = secToTime(args.value)
                }
            }
            if (args.fieldName === "percentage") {
                if (args.row.actualText.includes("B.") || args.row.actualText.includes("A.") || args.row.actualText.includes("A1.")) {
                    args.value = ""
                } else if (typeof args.value === undefined || args.value === null) {
                    args.value = "0%"
                } else if (args.row.actualText.includes("B1.")) {
                    args.value = ""
                }
            }
        } catch (error) {
        }
    }

    beforeColumnsRender(args) {
        args.columns[0].width = 260;
        args.columns[args.columns.length-1].width=args.columns[args.columns.length-1].width+13
    }

    queryCellInfo(args) {
        const { cell, data } = args;
        if (data[0].actualText.includes("A.")) {
            cell.setAttribute("style", "text-align:center; color:black !important;background-color:#C6E0B4!important;");
        }
        if (data[0].actualText.includes("B.")) {
            cell.setAttribute("style", "text-align:center; color:black !important;background-color:#FFE699!important;");
        }
        if (data[0].actualText.includes("C.")) {
            cell.setAttribute("style", "text-align:center; color:black !important;background-color:#FFE699 !important;");
        }
        if (data[0].actualText.includes("B1.")) {
            if (!cell.classList.contains("e-summary") && !cell.classList.contains("e-valuescontent")) {
                cell.innerHTML = `<span class="e-cellvalue" style="font-weight:bold">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Utilization of Time in Business</span>`
                cell.setAttribute("style", "font-weight:bold");
            }
        }
        if (data[0].actualText.includes("D.")) {
            cell.setAttribute("style", "text-align:center; color:black !important;background-color:#F7F7F7 !important;");
        }
        if (data[0].actualText.includes("C1.")) {
            cell.setAttribute("style", "text-align:center; color:white !important;background-color:#3f43ad!important;");
        }
    }

    componentWillReceiveProps(nextProps) {
        if (nextProps.ecReportId === this.props.Id) {
            if (this.LoadingStatus === "None") {
                try {
                    this.LoadingStatus = "In Process"
                    const { empId, sdPAId, monthYear, authUser } = this.props;
                    const resposnse = reportService.getEfficiencyReportData(authUser, monthYear, sdPAId, empId);
                    resposnse.then((data) => {
                        this.refineData(data);
                        setTimeout(() => {
                            this.setState({ isLoaded: true });
                        }, 100);
                        this.LoadingStatus = "Done";
                        this.props.ecReportUsers({ ecReportId: nextProps.ecReportId + 1 });
                    })
                } catch (error) {
                }
            }
            else {
                this.props.ecReportUsers({ ecReportId: nextProps.ecReportId + 1 })
            }
        }
    }

    refineData(data) {
        if (typeof data !== 'undefined' && data !== null) {
            this.setDefaultData(data);
            const drillCnt = [...new Set(data.map(item => item.monthType))];
            this.jsonReport.dataSource = data;
            if (drillCnt.length === 1) // NOSONAR
                this.jsonReport.drilledMembers = [{ name: 'monthType', items: [...new Set(data.map(item => item.monthType))] }, { name: "mainCategory", items: ["C. Total Actual Hours Recorded in EC"] }];
            else {
                this.jsonReport.drilledMembers = [{ name: "mainCategory", items: ["C. Total Actual Hours Recorded in EC"] }];
            }
            this.data = { userName: data[0].userName, empCode: data[0].empCode, jobTitle: data[0].jobTitle, sdpaNm: data[0].sdpaNm };
        }
    }

    setDefaultData(data) {
        data.forEach(item => {
            if (item.meetingCategory === "")
                item.meetingCategory = undefined;
            if (item.activityType === "")
                item.activityType = undefined;
            if (item.subActivityType === "")
                item.subActivityType = undefined;
            if (item.subActivityType1 === "")
                item.subActivityType1 = undefined;
            if (item.subActivityType2 === "")
                item.subActivityType2 = undefined;
        });
    }
    render() {
        const { isLoaded } = this.state;
        const { empId, reportKey } = this.props;
        const { userName, empCode, jobTitle, sdpaNm } = this.data
        return (
            <div>
                {isLoaded &&
                    <div className="control-pane" id={`divPivotView${empId}`}>
                        <div className="control-section">
                            {/* Employee Details */}
                            <div className="control-section col-md-12 ursdetails">
                                <div className="row emp-details">
                                    <div style={{
                                        border: "lightgray 1px solid",
                                        margin: "0px 25px 0px 0px",
                                    }} />

                                    {userName === '' &&
                                        <div className="col-md-3 remove-padding" style={{ paddingTop: 5, paddingLeft: 0, paddingBottom: 5, }}>
                                            <div className="col-md-2 remove-padding" style={{ height: "100%" }}>
                                                <div className="row1 user-icon-div">
                                                    <FontAwesomeIcon
                                                        icon={faLaptopHouse}
                                                        className="mr-sm icons-timeinbusiness"
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-10 remove-padding icon-left-align">
                                                <div className="row icon-header-text-font-style-user">
                                                    Practice Area / Sales Unit / Sub Function
                                                </div>
                                                <div className="row icon-text-font-style-user">
                                                    {sdpaNm}
                                                </div>
                                            </div>
                                        </div>

                                    }

                                    {userName !== '' &&
                                        <>
                                            <div className="col-md-3 remove-padding"
                                                style={{
                                                    paddingTop: 5,
                                                    paddingLeft: 0,
                                                    paddingBottom: 5,
                                                }}>
                                                <div
                                                    className="col-md-2 remove-padding"
                                                    style={{ height: "100%" }}
                                                >
                                                    <div className="row1 user-icon-div">
                                                        <FontAwesomeIcon
                                                            icon={faUser}
                                                            className="mr-sm icons-timeinbusiness"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-10 remove-padding icon-left-align">
                                                    <div className="row icon-header-text-font-style-user">
                                                        Name
                                                    </div>
                                                    <div className="row icon-text-font-style-user">
                                                        {userName}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-md-3 remove-padding"
                                                style={{
                                                    paddingTop: 5,
                                                    paddingLeft: 0,
                                                    paddingBottom: 5,
                                                }}>
                                                <div className="col-md-2 remove-padding"
                                                    style={{ height: "100%" }}>
                                                    <div className="row1 user-icon-div">
                                                        <FontAwesomeIcon
                                                            icon={faIdCard}
                                                            className="mr-sm icons-timeinbusiness"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-10 remove-padding">
                                                    <div className="row icon-header-text-font-style-user">
                                                        Employee Code
                                                    </div>
                                                    <div className="row icon-text-font-style-user">
                                                        {empCode}
                                                    </div>
                                                </div>
                                            </div>

                                            <div
                                                className="col-md-3 remove-padding"
                                                style={{
                                                    paddingTop: 5,
                                                    paddingLeft: 0,
                                                    paddingBottom: 5,
                                                }}>
                                                <div className="col-md-2 remove-padding"
                                                    style={{ height: "100%" }}>
                                                    <div className="row1 user-icon-div">
                                                        <FontAwesomeIcon
                                                            icon={faToolbox}
                                                            className="mr-sm icons-timeinbusiness"
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-md-10 remove-padding">
                                                    <div className="row icon-header-text-font-style-user">
                                                        Title
                                                    </div>
                                                    <div className="row icon-text-font-style-user">
                                                        {jobTitle}
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }
                                </div>

                            </div>

                            {/* Employee Details */}
                            <div className="col-md-12"
                                style={{ marginLeft: 0, marginBottom: 25, paddingRight: 10, width: '100%' }}>
                                <style>{SAMPLECSS}</style>
                                <PivotViewComponent id={`PivotView${reportKey}`} className="pivotGrid" ref={(pivotview) => { this.pivotObj = pivotview; }}
                                    dataSourceSettings={this.jsonReport}
                                    showTooltip={false}
                                    height={this.state.height}
                                    gridSettings={{
                                        columnWidth: userName === '' ? 63 : 58,
                                        allowReordering: false,
                                        allowTextWrap: false,
                                        columnRender: this.beforeColumnsRender,
                                        allowSelection: true,
                                        selectionSettings: { mode: 'Both', type: 'Multiple' },
                                        allowAutoResizing: false,
                                        queryCellInfo: this.queryCellInfo.bind(this)
                                    }}
                                    aggregateCellInfo={(e) => { this.aggregateCellInfo(e) }}
                                    allowConditionalFormatting={true}
                                    allowNumberFormatting={true}
                                    allowCalculatedField={true}
                                    rowHeight={60}
                                >
                                    <Inject services={[ConditionalFormatting, FieldList, NumberFormatting]} />
                                </PivotViewComponent>

                            </div>
                        </div>
                    </div>
                }
                {!isLoaded && <ProgressBar />}
            </div>
        )
    }
}

const mapStateToProps = ({ meetingbooking }) => {
    const { ecReportUsersList, ecReportId } = meetingbooking;
    return { ecReportUsersList, ecReportId };
};
export default connect(mapStateToProps, { ecReportUsers })(EfficiencyReport);

