import axios from "axios";
import { urlConfig } from "../config/config";

const saveErrorlog = async (payloadData) => {
    const getURL = `${urlConfig.tmlcApiUrl}errorlog`
    try {
        return await invokePostReq(getURL, payloadData);
    } catch (error) {
        return Promise.reject(error);
    }
}

function invokePostReq(getUrl, payLoad) {
    return new Promise(function onThen(resolve, reject) {
        axios
            .post(getUrl, payLoad, {
                headers: {
                    authorization: localStorage.getItem("jwtToken"),
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
            })
            // eslint-disable-next-line
            .then((response) => {//NOSONAR
                if (response != null) {
                    return resolve(response.data);
                }
            })
            .catch((error) => {
                console.log(error)
                errorHandler(reject, error.response.data.message);
                return null;
            });
    });
}

function errorHandler(reject, error) {
    // eslint-disable-next-line  
    reject("operation failed");
}
export default saveErrorlog;