import React from 'react'

import { NotificationContainer } from "react-notifications";

export default function NotifyContainer() {
  return (
    <>
      <NotificationContainer leaveTimeout={parseInt(process.env.REACT_APP_LEAVE_TIMEOUT)} />
    </>
  )
}







