/**
 * Author By :- Prashant Waphare
 * Date : 28-05-2020
 * Description :- This file use for load saperation resignation Hr approval Tab
 */
import React, { Component } from "react";
import NotificationContainer from "../../../../../components/notification/notifycontainer";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import HrResignationGrid from "../../../../../components/separation/resignationhrapprovaltab/hrresignationgrid";
import HrWithdrawalGrid from "../../../../../components/separation/resignationhrapprovaltab/hrwithdrawalgrid";
import HrClearanceGrid from "../../../../../components/separation/resignationhrapprovaltab/hrclearancegrid";
import ProgressBar from "../../../../../components/progressbar/progressbar";
import separetionServices from "../../../../../services/entryexit/separationServices";
import { updateStatusOnBoardingResucer, } from "../../../../../redux/actions/entryexit/onboardingaction";
import { addUpdateSeparationDetails } from "../../../../../redux/actions/entryexit/separationaction";
import { getFormatDateForGrid } from "../../../../other/commonfunction";

class ResignationHrApproval extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hrResignationData: [],
      hrWithdrawalData: [],
      hrClearanceData: [],
      changedData: false,
      resigTabCount: [],

    };
  }

  componentDidMount() {
    this.getTabCount(true);
  }

  // eslint-disable-next-line
  UNSAFE_componentWillReceiveProps(newProps) {
    const { changedData } = this.state;
    if (newProps.status === "updated") {
      this.setState({ changedData: !changedData });
      // eslint-disable-next-line
      this.props.updateStatusOnBoardingResucer({ status: "" });
    }
    if (newProps.UpdateResignationStatus === 'StatusUpdate' || newProps.UpdateResignationStatus === 'ClearanceUpdate') {
      this.getTabCount(false);
      this.getHrResignationTabData();
      this.getHrWithdrawalTabData();
      this.getHrClearanceTabData();
      this.props.addUpdateSeparationDetails({ UpdateResignationStatus: '' })
    }
    if (newProps.UpdateWithdrawlStatus === 'StatusUpdate' || newProps.updateExitClearanceStatus === 'updated') {
      this.getTabCount(false);
      this.getHrWithdrawalTabData();
      this.props.addUpdateSeparationDetails({ UpdateWithdrawlStatus: '', updateExitClearanceStatus: "" })
    }

  }

  // this finction use for load tab data on click
  openTab = (evt, tabName) => {
    const { hrClearanceData, hrResignationData, hrWithdrawalData } = this.state;
    const data = evt;
    localStorage.setItem('SelectedTabAction', tabName);
    if (tabName === "Clearance" && hrClearanceData.length === 0) {
      this.getHrClearanceTabData();
      this.getTabCount(false);
    } else if (tabName === "Resignation" && hrResignationData.length === 0) {
      this.getHrResignationTabData();
      this.getTabCount(false);
    } else if (tabName === "Withdrawal" && hrWithdrawalData.length === 0) {
      this.getHrWithdrawalTabData();
      this.getTabCount(false);
    }
    let i;
    const tabcontent = document.getElementsByClassName("tabcontent");
    // eslint-disable-next-line
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }
    const onboardingtab = document.getElementsByClassName("onboardingtab");
    // eslint-disable-next-line
    for (i = 0; i < onboardingtab.length; i++) {
      onboardingtab[i].className = onboardingtab[i].className.replace(
        " active",
        ""
      );
    }
    document.getElementById(tabName).style.display = "block";
    data.currentTarget.className += " active ";
  };
  getTabCount = (pIsDefault) => { // NOSONAR
    const { authUser } = this.props;
    try {
      const getResignationDetail = Promise.resolve(
        separetionServices.getResignationDetail(authUser, "hrcount")
      );
      getResignationDetail.then((result) => {
        if (result !== null) {
          this.setState({ resigTabCount: result },
            () => {
              if (pIsDefault) {
                let getTabActive = localStorage.getItem('SelectedTabAction');
                switch (getTabActive) {
                  case 'Withdrawal':
                  case 'Clearance':
                  case 'Resignation':
                    break;
                  default:
                    localStorage.setItem('SelectedTabAction', 'Resignation');
                    getTabActive = 'Resignation';
                }
                if (getTabActive !== null && getTabActive === 'Withdrawal') {
                  this.getHrWithdrawalTabData();
                  document.getElementById(`${getTabActive}`).style.display = "block";
                } else if (getTabActive !== null && getTabActive === 'Clearance') {
                  this.getHrClearanceTabData();
                  document.getElementById(`${getTabActive}`).style.display = "block";
                } else if (getTabActive === null || getTabActive === 'Resignation') {
                  localStorage.setItem('SelectedTabAction', 'Resignation');
                  this.getHrResignationTabData();
                  document.getElementById("Resignation").style.display = "block";
                }
              }
            });
        } else {
          this.data = "error";
        }
      });
    } catch (e) {
      // eslint-disable-next-line
      console.log(e);
    }
  };


  // function uses for get HR Resignation Data
  getHrResignationTabData = () => {
    const { authUser } = this.props;
    try {
      const getAllTabCount = Promise.resolve(
        separetionServices.getResignationDetail(authUser, "hrlist")
      );
      getAllTabCount.then((result) => {
        if (result !== null) {
          this.setState({ hrResignationData: getFormatDateForGrid(result) });
        } else {
          this.data = "error";
        }
      });
    } catch (e) {
      // eslint-disable-next-line
      console.log(e);
    }
  };

  // function uses for get HR Withdrawal Data
  getHrWithdrawalTabData = () => {
    const { authUser } = this.props;
    try {
      const getWithdrawalDetail = Promise.resolve(
        separetionServices.getWithdrawalDetail(authUser, "hrlist")
      );
      getWithdrawalDetail.then((result) => {
        if (result !== null) {
          this.setState({ hrWithdrawalData: getFormatDateForGrid(result) });
        } else {
          this.data = "error";
        }
      });
    } catch (e) {
      // eslint-disable-next-line
      console.log(e);
    }
  };

  // function uses for get HR clearance Data
  getHrClearanceTabData = () => {
    const { authUser } = this.props;
    try {
      const getClearanceDetail = Promise.resolve(
        separetionServices.getClearanceDetail(authUser, "list")
      );
      getClearanceDetail.then((result) => {
        if (result !== null) {
          this.setState({ hrClearanceData: getFormatDateForGrid(result) });
        } else {
          this.data = "error";
        }
      });
    } catch (e) {
      // eslint-disable-next-line
      console.log(e);
    }
  };




  render() {
    const { hrResignationData, hrWithdrawalData, hrClearanceData, resigTabCount } = this.state;

    return (
      <div
        className="control-pane control-section card-control-section row"
        style={{
          paddingBottom: "10px",
          paddingTop: "10px",
          paddingLeft: "25px",
          paddingRight: "25px",
          background: "#eee",
        }}
      >
        <NotificationContainer />
        {
          resigTabCount.length > 0 &&
          <div className="tab row-xs-12 row-sm-12 row-lg-12 row-md-12">
            {hrResignationData !== null && (
              <>
                <button
                  type="button"
                  className={`col-lg-4 onboardingtab onboarding-tab ${localStorage.getItem('SelectedTabAction') === "Resignation" || localStorage.getItem('SelectedTabAction') === null ? 'active' : ''}`}
                  onClick={(e) => this.openTab(e, "Resignation")}
                >
                  <b>RESIGNATION</b>{" "}
                  <span className="active-tab-count-dot">
                    {resigTabCount[0] !== undefined ? resigTabCount[0].Count : 0}
                  </span>
                </button>
                <button
                  type="button"
                  className={`col-lg-4 onboardingtab onboarding-tab ${localStorage.getItem('SelectedTabAction') === "Withdrawal" ? 'active' : ''}`}
                  onClick={(e) => this.openTab(e, "Withdrawal")}
                >
                  <b>WITHDRAWAL</b>
                  <span className="active-tab-count-dot">
                    {resigTabCount[1] !== undefined ? resigTabCount[1].Count : 0}
                  </span>
                </button>
                <button
                  type="button"
                  className={`col-lg-4 onboardingtab onboarding-tab ${localStorage.getItem('SelectedTabAction') === "Clearance" ? 'active' : ''}`}
                  onClick={(e) => this.openTab(e, "Clearance")}
                >
                  <b>CLEARANCE</b>
                  <span className="active-tab-count-dot">
                    {resigTabCount[2] !== undefined ? resigTabCount[2].Count : 0}
                  </span>
                </button>
              </>
            )}
          </div>
        }
        {
          resigTabCount.length > 0 &&
          <>
            <div id="Resignation" className="tabcontent">
              <HrResignationGrid
                getHrResignationTabData={this.getHrResignationTabData} hrResignationData={hrResignationData}
              />
            </div>
            <div id="Withdrawal" className="tabcontent">
              <HrWithdrawalGrid
                getHrWithdrawalTabData={this.getHrWithdrawalTabData} hrWithdrawalData={hrWithdrawalData}
              />
            </div>
            <div id="Clearance" className="tabcontent">
              <HrClearanceGrid
                getHrClearanceTabData={this.getHrClearanceTabData} hrClearanceData={hrClearanceData}
              />
            </div>
          </>
        }
        {
          resigTabCount.length === 0 &&
          <div className="resignation-manager-hr-grid-height"><ProgressBar /></div>

        }

      </div>
    );
  }
}
ResignationHrApproval.propTypes = {
  // eslint-disable-next-line
  status: PropTypes.string,
  authUser: PropTypes.string,
};
ResignationHrApproval.defaultProps = {
  authUser: "",
  status: "",
};

const mapStateToProps = ({ auth, onboardingreducer, separationreducer }) => {
  const { status } = onboardingreducer;
  const { authUser } = auth;

  const { UpdateResignationStatus, UpdateWithdrawlStatus, updateExitClearanceStatus } = separationreducer
  return { authUser, status, UpdateResignationStatus, UpdateWithdrawlStatus, updateExitClearanceStatus };
};
export default connect(mapStateToProps, { updateStatusOnBoardingResucer, addUpdateSeparationDetails })(
  ResignationHrApproval
);
