/**
 * Author Name :- Prashant Waphare
 * Date : 23-11-2020
 * Description :- This file use for show Team Shared Grid.
 */
import * as React from "react";
import { closest, isNullOrUndefined } from "@syncfusion/ej2-base";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Filter,
  Inject,
  VirtualScroll,
  Sort,
  Page,
  Resize,
} from "@syncfusion/ej2-react-grids";
import PropTypes from "prop-types";
import "../../app/containers/tmlc/onboarding/onboarding.css";
import "../../app/containers/tmlc/tmlc.css";

import { connect } from "react-redux";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import { Redirect } from "react-router-dom";
import { library } from "@fortawesome/fontawesome-svg-core";
import NotificationContainer from "../notification/notifycontainer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Dialog } from "@material-ui/core";
import { faTimesCircle, faCheckCircle } from "@fortawesome/pro-light-svg-icons";
import {
  DialogTitle,
  DialogContent,
  DialogActions,
} from "../customdialog/customdialog";
import { appceptRevokeRequest } from "../../redux/actions/okr/okractions";
import {
  ACTIONOKR,
  getGridColumn,
  REQREASON,
  FROM,
  TO,
  SHAREDPER,
  SHAREDAMT,
  TYPE,
  FUNCTIONNAME,
} from "../../app/other/gridcolumns";

library.add(faTimesCircle, faCheckCircle);

class Requested extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openConferMationBox: false,
      redirect: null,
      height:
        (window.innerHeight ||
          document.documentElement.clientHeight ||
          document.body.clientHeight) - 290,
      gridHeight:
        (window.innerHeight ||
          document.documentElement.clientHeight ||
          document.body.clientHeight) - 290,
    };
    this.onComplete = this.onComplete.bind(this);
    this.onLoad = this.onLoad.bind(this);
    this.onQueryCellInfoPreJoining = this.onQueryCellInfoPreJoining.bind(this);
    this.onDataBound = this.onDataBound.bind(this);
    this.width =
      (window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth) - 100;
    this.dReady = false;
    this.dtTime = false;
    this.isDataBound = false;
    this.isDataChanged = true;
    this.dropSlectedIndex = null;
    this.check = {
      type: "CheckBox",
    };
    this.select = {
      persistSelection: true,
      type: "Multiple",
      checkboxOnly: true,
    };
    this.Filter = {
      type: "Menu",
    };
    this.status = {
      type: "CheckBox",
      // itemTemplate: empTemplate
    };
    this.pageOptions = {
      pageSize:
        (window.innerHeight ||
          document.documentElement.clientHeight ||
          document.body.clientHeight) > 700
          ? 15
          : 10,
      pageCount: 5,
    };
    this.name = {
      type: "CheckBox",
    };
  }

  /**
   * Calculate & Update state of new dimensions
   */
  updateDimensions() {
    const updateheight = window.innerHeight - 145;
    const gridHeight = window.innerHeight - 320;
    this.setState({
      //   width: update_width,
      height: updateheight,
      gridHeight,
    });
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
    setTimeout(() => {
      const ele = document.getElementsByClassName("e-headercelldiv");
      for(let i in ele) {
        if(!isNaN(parseInt(i, 10))){
          let index = parseInt(i, 10);
          ele[index].classList.add("grid-left-header");
        }
      }
    }, 1000);
  }

  onQueryCellInfoPreJoining = (args) => {
    const { cell } = args;
    cell.setAttribute(
      "style",
      "font-size:14px !important; text-align: left !important; padding-left:19px !important"
    );
  };

  onDataBound() {
    clearTimeout(this.clrIntervalFun);
    clearInterval(this.intervalFun);
    this.dtTime = true;
  }

  onComplete = (args) => {
    if (args.requestType === "filterchoicerequest") {
      if (
        args.filterModel.options.field === "Trustworthiness" ||
        args.filterModel.options.field === "Rating" ||
        args.filterModel.options.field === "Status"
      ) {
        const span = args.filterModel.dialogObj.element.querySelectorAll(
          ".e-selectall"
        )[0];
        if (!isNullOrUndefined(span)) {
          closest(span, ".e-ftrchk").classList.add("e-hide");
        }
      }
    }
  };

  onChange() {
    this.ddObj.hidePopup();
    this.gridInstance.showSpinner();
    this.dropSlectedIndex = null;
    clearTimeout(this.clrIntervalFun2);
    this.clrIntervalFun2 = setTimeout(() => {
      this.isDataChanged = true;
      this.stTime = null;
      const contentElement = this.gridInstance.contentModule.getPanel()
        .firstChild;
      contentElement.scrollLeft = 0;
      contentElement.scrollTop = 0;
      this.gridInstance.pageSettings.currentPage = 1;
      this.stTime = performance.now();
      this.gridInstance.hideSpinner();
    }, 100);
  }

  onLoad() {
    document
      .getElementById("overviewgrid")
      .ej2_instances[0].on("data-ready", () => {
        this.dReady = true;
        this.stTime = performance.now();
      });
    document
      .getElementById("overviewgrid")
      .addEventListener("DOMSubtreeModified", () => {
        if (this.dReady && this.stTime && this.isDataChanged) {
          this.stTime = null;
          this.dReady = false;
          this.dtTime = false;
          this.isDataChanged = false;
        }
      });
  }

  created = () => {
    setTimeout(() => {
      if (document.getElementById("right-pane")) {
        document
          .getElementById("right-pane")
          .addEventListener("click", this.onClick.bind(this));
        document
          .getElementById("right-pane")
          .addEventListener("scroll", this.onScroll.bind(this));
      }
    }, 1000);
  };

  reasonTemp = (props) => {
    return (
      <div>
        <TooltipComponent
          content={props.description.replace(/(?:\r\n|\r|\n)/g, "<br>")}
          position="BottomCenter"
          created={this.created}
          isSticky={false} // if use false tooltip auto cancel
          // isSticky={true} // if use true tooltip we naualt need to cancel it.
          className="tooltip-box"
          cssClass="customtooltip"
        >
          <span
            style={{
              fontSize: "14px",
              display: "block",
              width: "200px",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {props.description}
          </span>
        </TooltipComponent>
      </div>
    );
  };

  typeTemp = (props) => {
    const { enumAcceptanceStatus } = props;
    if (
      enumAcceptanceStatus === "2" ||
      enumAcceptanceStatus === null ||
      enumAcceptanceStatus === ""
    ) {
      return (
        <div>
          <span style={{ color: "red" }}>Revoke</span>
        </div>
      );
    }
    if (enumAcceptanceStatus === "1") {
      return (
        <div>
          <span style={{ color: "#ffaf1c" }}>Sharing</span>
        </div>
      );
    }
    if (enumAcceptanceStatus === "3") {
      return (
        <div>
          <span style={{ color: "red" }}>Reject</span>
        </div>
      );
    }
    return (
      <div>
        <span>{"Pending "}</span>
      </div>
    );
  };

  fromDateTemp = (props) => {
    const { startDate } = props;
    return (
      <div className="date-format-all-grid">
        <span>{startDate}</span>
      </div>
    );
  };

  shareWithDeprtment = (props) => {
    const { sharedWith, pnlHead } = props;
    return (
      <div className="date-format-all-grid">
        <span>{`${
          sharedWith !== "" && sharedWith !== null ? sharedWith : pnlHead
        }`}</span>
      </div>
    );
  };

  toDateTemp = (props) => {
    const { endDate } = props;
    return (
      <div className="date-format-all-grid">
        <span>{endDate}</span>
      </div>
    );
  };

  sharePerTemp = (props) => {
    const { percSalaryShared } = props;
    return (
      <div className="date-format-all-grid" style={{ textAlign: "center" }}>
        <span>{`${percSalaryShared} %`}</span>
      </div>
    );
  };

  sharedAmountTemp = (props) => {
    const { amountShared } = props;
    return (
      <div className="date-format-all-grid" style={{ textAlign: "center" }}>
        <span>{`${amountShared}`}</span>
      </div>
    );
  };

  dataBound = () => {
    if (this.treegrid) {
      this.treegrid.autoFitColumns(["taskName"]);
    }
  };

  actionTemplate = (props) => {
    const { enumAcceptanceStatus } = props;
    if (enumAcceptanceStatus === "0") {
      return (
        <div
          className="row"
          style={{ paddingLeft: "15px", marginTop: "-10px" }}
        >
          <div className="col-4">
            <TooltipComponent
              closeOn="Click"
              content="Accept"
              position="BottomCenter"
              isSticky={false}
              cssClass="customtooltip"
              className="pointer-cursor"
            >
              <FontAwesomeIcon
                icon={faCheckCircle}
                style={{ fontSize: "20px" }}
                onClick={() => this.openConfermation(props, "1")}
              />
            </TooltipComponent>
          </div>
          <div className="col-4">
            <TooltipComponent
              closeOn="Click"
              content="Reject"
              position="BottomCenter"
              isSticky={false}
              cssClass="customtooltip"
              className="pointer-cursor"
            >
              <FontAwesomeIcon
                icon={faTimesCircle}
                style={{ fontSize: "20px" }}
                onClick={() => this.openConfermation(props, "3")}
              />
            </TooltipComponent>
          </div>
        </div>
      );
    }
    if (enumAcceptanceStatus === "1") {
      return (
        <div
          className="row"
          style={{ paddingLeft: "15px", marginTop: "-10px" }}
        >
          <div className="col-4">
            <TooltipComponent
              closeOn="Click"
              content="Accept"
              position="BottomCenter"
              isSticky={false}
              cssClass="customtooltip"
              className="pointer-cursor"
            >
              <FontAwesomeIcon
                icon={faCheckCircle}
                style={{ fontSize: "20px", color: "green" }}
              />
            </TooltipComponent>
          </div>
          <div className="col-4">
            <TooltipComponent
              closeOn="Click"
              content="Reject"
              position="BottomCenter"
              isSticky={false}
              cssClass="customtooltip"
              className="pointer-cursor"
            >
              <FontAwesomeIcon
                icon={faTimesCircle}
                style={{ fontSize: "20px" }}
                onClick={() => this.openConfermation(props, "3")}
              />
            </TooltipComponent>
          </div>
        </div>
      );
    }
    if (enumAcceptanceStatus === "2") {
      return (
        <div
          className="row"
          style={{ paddingLeft: "15px", marginTop: "-10px" }}
        >
          <div className="col-4">
            <TooltipComponent
              closeOn="Click"
              content="Accept"
              position="BottomCenter"
              isSticky={false}
              cssClass="customtooltip"
              className="pointer-cursor"
            >
              <FontAwesomeIcon
                icon={faCheckCircle}
                style={{ fontSize: "20px" }}
                onClick={() => this.openConfermation(props, "1")}
              />
            </TooltipComponent>
          </div>
          <div className="col-4">
            <TooltipComponent
              closeOn="Click"
              content="Reject"
              position="BottomCenter"
              isSticky={false}
              cssClass="customtooltip"
              className="pointer-cursor"
            >
              <FontAwesomeIcon
                icon={faTimesCircle}
                style={{ fontSize: "20px", color: "red" }}
              />
            </TooltipComponent>
          </div>
        </div>
      );
    }
    if (enumAcceptanceStatus === "3") {
      return (
        <div
          className="row"
          style={{ paddingLeft: "15px", marginTop: "-10px" }}
        >
          <div className="col-4">
            <TooltipComponent
              closeOn="Click"
              content="Accept"
              position="BottomCenter"
              isSticky={false}
              cssClass="customtooltip"
              className="pointer-cursor"
            >
              <FontAwesomeIcon
                icon={faCheckCircle}
                style={{ fontSize: "20px" }}
                onClick={() => this.openConfermation(props, "1")}
              />
            </TooltipComponent>
          </div>
          <div className="col-4">
            <TooltipComponent
              closeOn="Click"
              content="Reject"
              position="BottomCenter"
              isSticky={false}
              cssClass="customtooltip"
              className="pointer-cursor"
            >
              <FontAwesomeIcon
                icon={faTimesCircle}
                style={{ fontSize: "20px", color: "red" }}
              />
            </TooltipComponent>
          </div>
        </div>
      );
    }
  };

  openConfermation = (props, type) => {
    const { costSharingId } = props;
    this.costSharingId = costSharingId;
    this.acceptanceStatus = type;
    this.setState({ openConferMationBox: true });
  };

  closeConfermation = () => {
    this.setState({ openConferMationBox: false });
  };

  acceptRevokeOKRRequest = () => {
    const { authUser } = this.props;
    try {
      const approveData = {
        userId: authUser,
        sharingId: this.costSharingId,
        acceptanceStatus: this.acceptanceStatus,
      };
      // eslint-disable-next-line
      this.props.appceptRevokeRequest(approveData);
    } catch (error) {
      // eslint-disable-next-line
      console.log(error);
    }
    this.setState({ openConferMationBox: false });
  };

  render() {
    const { requestedList } = this.props;
    const { result } = requestedList;
    const { openConferMationBox, redirect, height, gridHeight } = this.state;
    if (redirect !== null) {
      return <Redirect to={redirect} />;
    }
    let widthColumn = 0;
    let finalWidth = 0;
    // eslint-disable-next-line
    getGridColumn("teamshared").forEach((data) => {
      widthColumn += data.width;
    });
    if (widthColumn <= this.width) {
      finalWidth = widthColumn + 120; // 40
    } else {
      finalWidth = this.width;
    }
    return (
      <div
        className="row"
        style={{
          height,
          overflowY: "hidden",
          overflowX: "hidden",
        }}
      >
        <NotificationContainer />
        <div>
          <div className="row-xs-12 row-sm-12 row-lg-12 row-md-12">
            <div
              style={{
                padding: "1.20rem 1.20rem",
              }}
            >
              <GridComponent
                className="grid-head-custome"
                id="overviewgrid"
                allowPaging
                dataSource={result}
                pageSettings={this.pageOptions}
                enableHover={false}
                enableVirtualization={false}
                rowHeight={38}
                height={gridHeight}
                ref={(g) => {
                  this.gridInstance = g;
                }}
                actionComplete={this.onComplete}
                load={this.onLoad}
                queryCellInfo={this.onQueryCellInfoPreJoining}
                dataBound={this.onDataBound}
                filterSettings={this.Filter}
                allowFiltering
                allowSorting
                allowSelection
                selectionSettings={this.select}
                allowTextWrap
                width={`${finalWidth}`}
              >
                <ColumnsDirective>
                  {getGridColumn("requests").map((item) => {
                    switch (item.colName) {
                      case ACTIONOKR:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.actionTemplate}
                            filter={this.check}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                          />
                        );
                      case TYPE:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.typeTemp}
                            filter={this.check}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                          />
                        );
                      case REQREASON:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.reasonTemp}
                            filter={this.check}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                          />
                        );
                      case FROM:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.fromDateTemp}
                            filter={this.check}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                          />
                        );
                      case TO:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.toDateTemp}
                            filter={this.check}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                          />
                        );
                      case SHAREDPER:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.sharePerTemp}
                            filter={this.check}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                          />
                        );
                      case SHAREDAMT:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.sharedAmountTemp}
                            filter={this.check}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                          />
                        );
                      case FUNCTIONNAME:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.shareWithDeprtment}
                            filter={this.check}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                          />
                        );
                      default:
                        return (
                          <ColumnDirective
                            key={item.field}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                            filter={this.check}
                          />
                        );
                    }
                  })}
                </ColumnsDirective>
                <Inject
                  services={[Page, Filter, VirtualScroll, Sort, Resize]}
                />
              </GridComponent>
            </div>
          </div>
          <div style={{ width: "350px" }}>
            <Dialog open={openConferMationBox}>
              <DialogTitle>
                {" "}
                {this.acceptanceStatus === "1"
                  ? "Accept Request"
                  : "Revoke Request"}
              </DialogTitle>
              <DialogContent>
                {this.actioType === "1"
                  ? "Are you sure you want to accept the request?"
                  : "Are you sure you want to revoke the request?"}
              </DialogContent>
              <DialogActions>
                <Button onClick={this.acceptRevokeOKRRequest}> Yes </Button>
                <Button onClick={this.closeConfermation}> No </Button>
              </DialogActions>
            </Dialog>
          </div>
        </div>
      </div>
    );
  }
}
Requested.propTypes = {
  authUser: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  requestedList: PropTypes.arrayOf(PropTypes.object),
  result: PropTypes.arrayOf(PropTypes.object),
};
Requested.defaultProps = {
  authUser: "",
  requestedList: {},
  result: {},
};
const mapStateToProps = ({ auth }) => {
  const { authUser } = auth;
  return { authUser };
};
export default connect(mapStateToProps, { appceptRevokeRequest })(Requested);
