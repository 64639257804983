import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUser,
  faUserFriends,
  faUserCog,
  faUserGraduate,
  faUserTag,
  faUserTie,
  faUserEdit,
  faStreetView,
  faMugHot,
  faMailBulk,
  faSchool,
  faHandHoldingUsd,
  faBookReader,
  faCogs,
  faHome,
  faRunning
} from "@fortawesome/free-solid-svg-icons";
import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import {
  ComboBoxComponent,
  DropDownListComponent,
  MultiSelectComponent,
  CheckBoxSelection,
  Inject,
} from "@syncfusion/ej2-react-dropdowns";

import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faChalkboardTeacher,
  faBoxOpen,
  faList,
} from "@fortawesome/pro-light-svg-icons";
import Clientsearch from "../clientsearch/clientsearch";
import OpsTasksearch from "../opstasksearch/opstasksearch";
import { OTHERS_CALENDAR } from '../../redux/constant/meetingcategoryconstant'
import { updateMeetingBookingState } from "../../redux/actions/meetingbookingaction";
import "../../assets/style/index.css";
import services from "../../services/services";
import { SalesClientMeet, OPSBTBClientMeet, OPSBTBDropdown, ClientMeetingsId, MarkrtingMeetingIds, ExcluedPitchRecurrenceIds, WARNING, leaveMeetingException } from "../../assets/constants/constants";
import { dencrypt, decodeString, getNotify } from "../../app/other/commonfunction";
library.add(faChalkboardTeacher, faBoxOpen, faList);

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
}));

function MeetingType(props) { // NOSONAR
  const classes = useStyles();
  let listsubType;
  let listsource;
  let listcategory;

  const [meetingTypeNm, setMeetingTypeNm] = React.useState("");
  const [meetingSubType, setMeetingSubType] = React.useState([]);
  const [meetingSubTypeIdState, setMeetingSubTypeIdState] = React.useState(null);
  const [practiceAreaData, setPracticeAreaData] = React.useState([]);
  const [marketActivityDataSource, setMarketActivityData] = React.useState([]);
  const [campaign, setCampaignData] = React.useState([]);
  const [marketData, setMarketData] = React.useState([]);
  const [isMarketActivityActivated, setIsMarketActivityActivated] = React.useState(true);
  const [isCampaignActivated, setIsCampaignActivated] = React.useState(true);
  let [practiceArea, setPracticeArea] = React.useState('');
  let [marketActivity, setMarketActivity] = React.useState('');
  let [changesState, setChangesState] = React.useState(true);
  let [changed, setChanged] = React.useState(0);


  const fillSubMeetingType = (selectedId) => {
    // get the meeting sub type from the database
    const subType = props.meetingSubType.filter((item) => {
      return item.Type === "SubType" && item.Optional === selectedId.toString();
    });
    if (subType.length > 1) {
      setMeetingSubType(subType);
    } else {
      setMeetingSubType([]);
    }
    let blncheck = false;
    if (props.meetingSubTypeId > 0) {
      subType.forEach((element) => {
        if (props.meetingSubTypeId === element.Id) {
          blncheck = true;
        }
      });
    } // eslint-disable-next-line
    if (!blncheck) props.updateMeetingBookingState({ meetingSubTypeId: "" });
  };
  const funSetMeetingType = (pId, IsClicked) => { // NOSONAR
    try {
      if (props.meetingId === 0) {
        setMarketActivity('');
      }
      const { meetingSubTypeId, categoryName, meetingTypeId } = props; // NOSONAR
      let newMeetingTypeName;
      let Id;
      if (typeof document.getElementById("meetingtypeError") !== 'undefined' && document.getElementById("meetingtypeError") !== null) {
        document.getElementById("meetingtypeError").innerHTML = "";
      }
      if (typeof document.getElementById("maError") !== 'undefined' && document.getElementById("maError") !== null) {
        document.getElementById("maError").innerHTML = "";
      }
      props.meetingType.forEach((value) => {
        if (pId === value.Id) {
          setMeetingTypeNm(value.Name);
          newMeetingTypeName = value.Name;
          Id = value.Id;

          if (document.getElementById(`btnMT_${Id}`) !== null)
            document
              .getElementById(`btnMT_${Id}`)
              .classList.add("MuiButton-containedPrimary");

          // eslint-disable-next-line
          props.updateMeetingBookingState({
            meetingTypeName: newMeetingTypeName,
          });

          if (
            (props.meetingId === 0 || IsClicked)
            &&
            ClientMeetingsId.includes(meetingTypeId)
          ) {
            // eslint-disable-next-line
            props.updateMeetingBookingState({
              isClientMeeting: OPSBTBClientMeet.includes(meetingSubTypeId) || SalesClientMeet.includes(meetingSubTypeId), //  Client Meeting
              meetingTypeId: Id,
              meetingFrequency: 1,
              meetingDates: [],
              recurring: "",
              meetingTypeName: newMeetingTypeName,
            });
          } else if (
            (props.meetingId === 0 || IsClicked) &&
            (newMeetingTypeName === "Self" ||
              value.Name === "OPS - BTB" ||
              value.Name === "Break")
            // ||
            // (value.Name==="Leave")
          ) {
            if (
              (value.Name === "Break" &&
                document.getElementById("mttitle-txt").value === "")
            ) {
              document.getElementById("mttitle-txt").focus();
              setTimeout(() => {
                if (document.getElementById("mttitle-txt") !== null)
                  // NOSONAR document.getElementById("mttitle-txt").value = "Break";
                  props.updateMeetingBookingState({
                    meetingTitle: "Break",
                    meetingAgenda: "Break",
                  })
              }, 1000);
              // eslint-disable-next-line
              props.updateMeetingBookingState({
                meetingTitle: "",
                chairPersonId: categoryName === OTHERS_CALENDAR ? props.otherUser : props.authUser,
                meetingStatus: "Self",
                meetingSourceId: "",
                isClientMeeting: false,
                serviceProductsId: [],
                meetingTypeId: Id,
                subCatId: "",
                btbId: "",
                subBTBId: "",
                meetingTypeName: newMeetingTypeName,
              });
            }
            if (
              (value.Name === "Self" &&
                document.getElementById("mttitle-txt").value === "")
            ) {
              document.getElementById("mttitle-txt").focus();
              setTimeout(() => {
                if (document.getElementById("mttitle-txt") !== null)
                  document.getElementById("mttitle-txt").value = "";
              }, 1000);
              // eslint-disable-next-line
              props.updateMeetingBookingState({
                meetingTitle: "",
                chairPersonId: categoryName === OTHERS_CALENDAR ? props.otherUser : props.authUser,
                meetingStatus: "Self",
                meetingSourceId: "",
                isClientMeeting: false,
                serviceProductsId: [],
                meetingTypeId: Id,
                subCatId: "",
                btbId: "",
                subBTBId: "",
                meetingTypeName: newMeetingTypeName
              });
            }
            else {
              // eslint-disable-next-line
              props.updateMeetingBookingState({
                chairPersonId: props.authUser,
                meetingStatus: "Self",
                meetingSourceId: "",
                isClientMeeting: false,
                serviceProductsId: [],
                meetingTypeId: Id,
                subCatId: "",
                btbId: "",
                subBTBId: "",
                meetingTypeName: newMeetingTypeName
              });
            }
          } else if (!ClientMeetingsId.includes(meetingTypeId)) {
            /* BTB / Ops / Client */
            // eslint-disable-next-line            
            props.updateMeetingBookingState({
              meetingSourceId: "",
              isClientMeeting: false,
              serviceProductsId: [],
              meetingTypeId: Id,
              subCatId: "",
              btbId: "",
              subBTBId: "",
              meetingTypeName: newMeetingTypeName,
            });
          }
        } else if (document.getElementById(`btnMT_${value.Id}`) !== null) {
          document
            .getElementById(`btnMT_${value.Id}`)
            .classList.remove("MuiButton-containedPrimary");
        }
      });
      fillSubMeetingType(Id);
      if (typeof document.getElementById(`search-attendee`) !== 'undefined' && document.getElementById(`search-attendee`) !== null) {
        document.getElementById(`search-attendee`).ej2_instances[0].refresh();
      }
    } catch (error) {

    }
  };

  const getSalesUnitMasterData = () => {
    // function to filter data on basis of practice area
    let data = campaignData.filter((item) => { return item.PracticeArea !== '' })
    let dataMA = campaignData.filter((item) => { return item.MarketingActivity !== '' })

    let p_data = data.map((item) => { return item.PracticeArea })
    let ma_data = dataMA.map((item) => { return item.MarketingActivity })

    let unique_p_data = p_data.filter((item, ind) => { return p_data.indexOf(item.trim()) === ind })
    let unique_ma_data = ma_data.filter((item, ind) => { return ma_data.indexOf(item) === ind })
    setPracticeAreaData(unique_p_data);

    if (campaignId) {
      let prevData = campaignData.filter((item) => { return item.Id === campaignId })
      setPracticeArea(prevData[0].PracticeArea);

      setMarketActivity(prevData[0].MarketingActivity);

      let campaingData = campaignData.filter((item) => { return item.MarketingActivity === prevData[0].MarketingActivity })
      setCampaignData(campaingData);

      setMarketData(unique_ma_data);

      setMarketActivityData(unique_ma_data);

      setChangesState(!changesState);
    }
  }

  useEffect(() => {
    getSalesUnitMasterData()
    funSetMeetingType(props.meetingTypeId, false);
    getMeetingTemplates()
  }, [])

  useEffect(() => {
    if (props.templateId && meetingSubTypeIdState) {
      setTimeout(() => {
        props.updateMeetingBookingState({
          meetingSubTypeId: meetingSubTypeIdState
        })
      }, 1500);
    }
  }, [props.templateId, meetingSubTypeIdState])

  const getMeetingTemplates = async () => { // NOSONAR
    const getMeetingTemplate = props.templateListData.result;

    setTimeout(() => {
      if (getMeetingTemplate && getMeetingTemplate.length > 0) {
        getMeetingTemplate.forEach(template => {
          if (template.int_meeting_template_uid === props.templateId) {
            const roomId = [+template.fk_room_id];
            props.updateMeetingBookingState({
              meetingTypeId: +template.mainMeetingType,
              meetingSubTypeId: template.fk_meeting_type,
              isFullDay: template.enum_is_full_day === "1",
              meetingTitle: template.var_meeting_title,
              meetingAgenda: template.var_meeting_agenda,
              meetingActionItems: template.var_meeting_actionitems,
              meetingAttendee: template.meeting_attendee.split(','),
              meetingRoomsId: roomId,
              chairPersonId: template.fk_chair_person,
              bulkAttendeesFlag: template.enum_bulk_attendee_flag === "0" ? false : true,
              meetingFrequency: +template.enum_meeting_frequency,
              meetingStatus: template.enum_meeting_status,
              btbId: template.fk_btb_id,
              clientId: template.fk_client_id,
              meetingCategoryId: template.fk_meeting_category,
              meetingMediaId: template.fk_meeting_media,
              meetingSetterId: template.fk_meeting_setter,
              meetingSourceId: template.fk_meeting_source,
              timeZoneId: template.fk_stamp_time_zone,
              subBTBId: template.fk_sub_btb_id,
              subCatId: template.fk_sub_cat_id,
              businessImpact: template.var_business_impact,
              otherAttendees: template.var_other_attendee,
              seriesId: template.fk_series_id
            })
            setMeetingSubTypeIdState(template.fk_meeting_type);

            props.meetingAttendeeList(template.meeting_attendee.split('$$'));

            setTimeout(() => {
              document.getElementById(`btnMT_${template.mainMeetingType}`).click();
            }, 500);

            if (template.enum_is_full_day) {
              document.getElementById('fulldaybooking').click();
              document.getElementById('fulldaybooking').click();
            }

            if (template.var_series_string) {
              props.updateMeetingBookingState({
                recurring: template.var_series_string
              });
              document.getElementById('btnRecurring').classList.add("MuiButton-containedPrimary");
            }

            props.updateMeetingBookingState({
              templateId: template.int_meeting_template_uid
            });
          }
        })
      }
    }, 1500)
  }

  const getIcon = (icon) => {
    let IconContent;
    switch (icon) {
      case "faUser":
        IconContent = <FontAwesomeIcon icon={faUser} className="mr-sm" />;
        break;
      case "faUserFriends":
        IconContent = (
          <FontAwesomeIcon icon={faUserFriends} className="mr-sm" />
        );
        break;
      case "faUserCog":
        IconContent = <FontAwesomeIcon icon={faUserCog} className="mr-sm" />;
        break;
      case "faHome":
        IconContent = <FontAwesomeIcon icon={faHome} className="mr-sm" />;
        break;
      case "faUserGraduate":
        IconContent = (
          <FontAwesomeIcon icon={faUserGraduate} className="mr-sm" />
        );
        break;
      case "faUserTag":
        IconContent = <FontAwesomeIcon icon={faUserTag} className="mr-sm" />;
        break;
      case "faUserTie":
        IconContent = <FontAwesomeIcon icon={faUserTie} className="mr-sm" />;
        break;
      case "faUserEdit":
        IconContent = <FontAwesomeIcon icon={faUserEdit} className="mr-sm" />;
        break;
      case "faStreetView":
        IconContent = <FontAwesomeIcon icon={faStreetView} className="mr-sm" />;
        break;
      case "faMugHot":
        IconContent = <FontAwesomeIcon icon={faMugHot} className="mr-sm" />;
        break;
      case "faMailBulk":
        IconContent = <FontAwesomeIcon icon={faMailBulk} className="mr-sm" />;
        break;
      case "faSchool":
        IconContent = <FontAwesomeIcon icon={faSchool} className="mr-sm" />;
        break;
      case "faHandHoldingUsd":
        IconContent = (
          <FontAwesomeIcon icon={faHandHoldingUsd} className="mr-sm" />
        );
        break;
      case "faRunningUsd":
        IconContent = (
          <FontAwesomeIcon icon={faRunning} className="mr-sm" />
        );
        break;
      case "faBookReader":
        IconContent = <FontAwesomeIcon icon={faBookReader} className="mr-sm" />;
        break;
      case "faCogs":
        IconContent = <FontAwesomeIcon icon={faCogs} className="mr-sm" />;
        break;
      default:
        IconContent = <FontAwesomeIcon icon={faUser} className="mr-sm" />;
        break;
    }
    return IconContent;
  };

  const handleClick = (Id, IsClicked) => {
    funSetMeetingType(Id, IsClicked);
  };

  const handleChange = (event, type) => { // NOSONAR
    let newMeetingSubTypeId;
    const { workingHrStartTime, workingHrEndTime, categoryName } = props;
    switch (event.element.id) {
      case "mst-select":
        newMeetingSubTypeId = event.itemData === null ? "" : event.itemData.Id;
        if (props.meetingId === 0) {
          if (event.itemData !== null) {
            //Full Day
            if (event.itemData.Id === 8) {
              getNotify(WARNING, leaveMeetingException)
              // eslint-disable-next-line
              props.updateMeetingBookingState({
                meetingStartTime: new Date(workingHrStartTime),
                meetingEndTime: new Date(workingHrEndTime),
                meetingTitle: " Leave - Full Day",
                chairPersonId: categoryName === OTHERS_CALENDAR ? props.otherUser : props.authUser,
              });
            }
            // First Half
            if (event.itemData.Id === 999) {
              getNotify(WARNING, leaveMeetingException)
              let startTime = new Date(workingHrStartTime);
              let endTime = new Date(workingHrEndTime);

              let stHr = new Date(startTime).getHours()
              let stMui = new Date(startTime).getMinutes()
              if (new Date(endTime).getDay() === 6) {
                endTime = new Date(endTime).setHours(stHr + 3)
                endTime = new Date(endTime).setMinutes(stMui)
              } else {
                endTime = new Date(endTime).setHours(stHr + 5)
                endTime = new Date(endTime).setMinutes(stMui)
              }

              // eslint-disable-next-line
              props.updateMeetingBookingState({
                meetingStartTime: new Date(startTime),
                meetingTitle: "Leave - First Half",
                meetingEndTime: new Date(endTime),
                chairPersonId: categoryName === OTHERS_CALENDAR ? props.otherUser : props.authUser,
              });
            }
            // Second Half
            if (event.itemData.Id === 1000) {
              getNotify(WARNING, leaveMeetingException)
              let endTime = new Date(workingHrEndTime);

              let endHr = new Date(endTime).getHours();
              let endMui = new Date(endTime).getMinutes();
              let startTimes = null;
              if (new Date(endTime).getDay() === 6) {
                startTimes = new Date(endTime).setHours(endHr - 3);
                startTimes = new Date(startTimes).setMinutes(endMui);
              } else {
                startTimes = new Date(endTime).setHours(endHr - 5);
                startTimes = new Date(startTimes).setMinutes(endMui);
              }
              // eslint-disable-next-line
              props.updateMeetingBookingState({
                meetingStartTime: new Date(startTimes),
                meetingEndTime: new Date(endTime),
                meetingTitle: "Leave - Second Half",
                chairPersonId: categoryName === OTHERS_CALENDAR ? props.otherUser : props.authUser,
              });
            }
            // Quarter Day
            if (event.itemData.Id === 1018) {
              getNotify(WARNING, leaveMeetingException)
              // eslint-disable-next-line
              props.updateMeetingBookingState({
                meetingTitle: "Leave - Quarter Day",
                chairPersonId: categoryName === OTHERS_CALENDAR ? props.otherUser : props.authUser,
              });
            }
          }
          // eslint-disable-next-line
          props.updateMeetingBookingState({
            meetingSubTypeId: newMeetingSubTypeId,
            isClientMeeting: SalesClientMeet.includes(newMeetingSubTypeId), // Client Meeting
          });
          if (
            SalesClientMeet.includes(newMeetingSubTypeId)
          ) {
            // eslint-disable-next-line
            props.updateMeetingBookingState({
              meetingSourceId: "",
              serviceProductsId: [],
              btbId: "",
              subBTBId: "",
              meetingCategoryId: "",
              contactsId: [],
              clientId: 0,
            });
          }
          if (event.itemData !== null) {
            if (ExcluedPitchRecurrenceIds.includes(event.itemData.Id)) {
              props.updateMeetingBookingState({
                recurring: "",
                seriesId: 0,
                meetingFrequency: 1
              });
            }
          }

          break;
        } else {
          if (event.itemData !== null) {
            if (event.itemData.Id === 8) {
              // eslint-disable-next-line
              props.updateMeetingBookingState({
                meetingStartTime: new Date(workingHrStartTime),
                meetingEndTime: new Date(workingHrEndTime),
                meetingTitle: "Leave - Full Day",
                chairPersonId: categoryName === OTHERS_CALENDAR ? props.otherUser : props.authUser,
              });
            }
            // First Half

            if (event.itemData.Id === 999) {
              getNotify(WARNING, leaveMeetingException)
              let startTime = new Date(workingHrStartTime);
              let endTime = new Date(workingHrEndTime);

              let stHr = new Date(startTime).getHours()
              let stMui = new Date(startTime).getMinutes()
              if (new Date(endTime).getDay() === 6) {
                endTime = new Date(endTime).setHours(stHr + 3)
                endTime = new Date(endTime).setMinutes(stMui)
              } else {
                endTime = new Date(endTime).setHours(stHr + 5)
                endTime = new Date(endTime).setMinutes(stMui)
              }
              // eslint-disable-next-line
              props.updateMeetingBookingState({
                meetingStartTime: new Date(startTime),
                meetingEndTime: new Date(endTime),
                meetingTitle: "Leave - First Half",
                chairPersonId: categoryName === OTHERS_CALENDAR ? props.otherUser : props.authUser,
              });
            }
            // Second Half
            if (event.itemData.Id === 1000) {
              getNotify(WARNING, leaveMeetingException)
              let endTime = new Date(workingHrEndTime);

              let endHr = new Date(endTime).getHours();
              let endMui = new Date(endTime).getMinutes();
              let startTimes = null;
              if (new Date(endTime).getDay() === 6) {
                startTimes = new Date(endTime).setHours(endHr - 3);
                startTimes = new Date(startTimes).setMinutes(endMui);
              } else {
                startTimes = new Date(endTime).setHours(endHr - 5);
                startTimes = new Date(startTimes).setMinutes(endMui);
              }
              // eslint-disable-next-line
              props.updateMeetingBookingState({
                meetingStartTime: new Date(startTimes),
                meetingEndTime: new Date(endTime),
                meetingTitle: "Leave - Second Half",
                chairPersonId: categoryName === OTHERS_CALENDAR ? props.otherUser : props.authUser,
              });
            }
            // Quarter Day
            if (event.itemData.Id === 1018) {
              getNotify(WARNING, leaveMeetingException)
              // eslint-disable-next-line
              props.updateMeetingBookingState({
                meetingTitle: "Leave - Quarter Day",
                chairPersonId: categoryName === OTHERS_CALENDAR ? props.otherUser : props.authUser,
              });
            }
          }
          // eslint-disable-next-line
          props.updateMeetingBookingState({
            meetingSubTypeId: newMeetingSubTypeId,
            isClientMeeting: OPSBTBClientMeet.includes(newMeetingSubTypeId) || SalesClientMeet.includes(newMeetingSubTypeId), // Client Meeting
          });
          if (ExcluedPitchRecurrenceIds.includes(event.itemData.Id)) {
            props.updateMeetingBookingState({
              recurring: "",
              seriesId: 0,
              meetingFrequency: 1
            });
          }
          break;
        }
      case "mc-select":
        // eslint-disable-next-line
        props.updateMeetingBookingState({
          meetingCategoryId: event.itemData === null ? "" : event.itemData.Id,
        });
        break;
      case "sp-select":
        // eslint-disable-next-line
        props.updateMeetingBookingState({ serviceProductsId: event.value });
        break;
      case "ms-select":
        // eslint-disable-next-line

        props.updateMeetingBookingState({
          meetingSourceId: event.itemData === null ? "" : event.itemData.Id,
          meetingSourceType: event.itemData === null ? "" : event.itemData.Optional,
          campaignId: null
        });
        setPracticeArea(null);
        break;
      case "pa-select":
        // eslint-disable-next-line
        /** let m_Data = campaignData.filter((item) => { return event.itemData !== null ? event.itemData.text === item.PracticeArea : '' });//NOSONAR
        setMarketData(m_Data);
        let marketdata = m_Data.map((item) => { return item.MarketingActivity })
        let unique_marketActivityData = marketdata.filter((item, ind) => { return marketdata.indexOf(item) === ind })
        setMarketActivityData(unique_marketActivityData)

        setMarketActivity(null);

        props.updateMeetingBookingState({ campaignId: null });
        */
        const isPracticeAreaNull = event.itemData ? event.itemData.text : null;
        setPracticeArea(isPracticeAreaNull);
        if (isPracticeAreaNull) {
          let mData = campaignData.filter((item) => { // NOSONAR
            return event.itemData !== null ? event.itemData.text === item.PracticeArea : ''
          });
          setMarketData(mData);
          let marketdata = mData.map((item) => { return item.MarketingActivity })
          let uniqueMarketActivityData = marketdata.filter((item, ind) => { return marketdata.indexOf(item) === ind })
          setMarketActivityData(uniqueMarketActivityData)
          setIsCampaignActivated(true);
          setIsMarketActivityActivated(true);
        } else {
          setMarketActivity("");
          props.updateMeetingBookingState({ campaignId: null });
          setMarketActivityData([]);
          setCampaignData([]);
          setIsCampaignActivated(false);
          setIsMarketActivityActivated(false);
        }
        break;
      case "mar-select":
        // eslint-disable-next-line
        let cmpnData = marketData.filter((item) => { return event.value !== null ? event.value === item.MarketingActivity : '' });//NOSONAR
        setCampaignData(cmpnData);

        setMarketActivity(event.value);
        props.updateMeetingBookingState({ campaignId: null });
        if (event.value) {
          setIsCampaignActivated(true);
        } else {
          setIsCampaignActivated(false);
        }
        break;
      case "cd-select":
        // eslint-disable-next-line
        props.updateMeetingBookingState({ campaignId: event.value, campaignName: event.itemData === null ? null : event.itemData.Name });
        break;

      default:
    }
  };

  const handleOpensubType = () => {
    listsubType.showPopup();
  };

  const handleOpensource = () => {
    listsource.showPopup();
  };
  const handleOpensourceMeetingCategory = () => {
    listcategory.showPopup();
  };

  const handleChangeTemplate = (event) => {
    if (event.itemData) {
      const roomId = [+event.itemData.fk_room_id];
      props.updateMeetingBookingState({
        meetingTypeId: +event.itemData.mainMeetingType,
        meetingSubTypeId: event.itemData.fk_meeting_type,
        isFullDay: event.itemData.enum_is_full_day === "1",
        meetingTitle: event.itemData.var_meeting_title,
        meetingAgenda: event.itemData.var_meeting_agenda,
        meetingActionItems: event.itemData.var_meeting_actionitems,
        meetingAttendee: event.itemData.meeting_attendee.split(','),
        meetingRoomsId: roomId,
        chairPersonId: event.itemData.fk_chair_person,
        bulkAttendeesFlag: event.itemData.enum_bulk_attendee_flag === "0" ? false : true,
        meetingFrequency: +event.itemData.enum_meeting_frequency,
        meetingStatus: event.itemData.enum_meeting_status,
        btbId: event.itemData.fk_btb_id,
        clientId: event.itemData.fk_client_id,
        meetingCategoryId: event.itemData.fk_meeting_category,
        meetingMediaId: event.itemData.fk_meeting_media,
        meetingSetterId: event.itemData.fk_meeting_setter,
        meetingSourceId: event.itemData.fk_meeting_source,
        timeZoneId: event.itemData.fk_stamp_time_zone,
        subBTBId: event.itemData.fk_sub_btb_id,
        subCatId: event.itemData.fk_sub_cat_id,
        businessImpact: event.itemData.var_business_impact,
        otherAttendees: event.itemData.var_other_attendee,
        seriesId: event.itemData.fk_series_id
      });
      setMeetingSubTypeIdState(event.itemData.fk_meeting_type);

      props.meetingAttendeeList(event.itemData.meeting_attendee.split('$$'));

      setTimeout(() => {
        document.getElementById(`btnMT_${event.itemData.mainMeetingType}`).click();
      }, 500);

      if (event.itemData.enum_is_full_day) {
        document.getElementById('fulldaybooking').click();
        document.getElementById('fulldaybooking').click();
      }

      if (event.itemData.var_series_string) {
        props.updateMeetingBookingState({
          recurring: event.itemData.var_series_string
        });
        document.getElementById('btnRecurring').classList.add("MuiButton-containedPrimary");
      }

      props.updateMeetingBookingState({
        templateId: event.itemData.int_meeting_template_uid
      });
      setChanged(++changed);

    } else if (typeof event.e !== "undefined") {
      props.updateMeetingBookingState({ templateId: 0 });
    }
  }

  const {
    meetingType,
    meetingTypeName,
    meetingmode,
    backDatesClientEditAccess,
    meetingTypeId,
    isClientMeeting,
    meetingSubTypeId,
    meetingServiceProduct,
    serviceProductsId,
    meetingSource,
    meetingSourceId,
    meetingCategory,
    meetingCategoryId,
    campaignData,
    campaignId,
    templateId
  } = props;

  return (

    <div>
      <div>Meeting Type *</div>
      <div className="row">
        <div id="text">
          <div className="col-md-12 col-sm-12">
            <div className="col-md-9 col-sm-9">
              {/* <div className="buttonMargin"> */}
              <div>
                {(meetingType.length > 0 || meetingTypeName === "OPS - BTB") &&
                  meetingType.filter(item => item.Id !== (meetingmode === "A" ? 0 : 998)).map((value) => {
                    if (meetingmode === "A" && value.Name === "Client") {
                      if (!backDatesClientEditAccess) return null;
                    }
                    return meetingTypeId === value.Id ? (
                      <Button
                        variant="contained"
                        onClick={() => handleClick(value.Id, true)}
                        id={`btnMT_${value.Id}`}
                        key={value.Id}
                        className={classes.button}
                        color="primary"
                        title={value.Name}
                      >
                        {getIcon(value.Optional)}
                        {value.Name}
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        onClick={() => handleClick(value.Id, true)}
                        id={`btnMT_${value.Id}`}
                        key={value.Id}
                        className={classes.button}
                        title={value.Name}
                      >
                        {getIcon(value.Optional)}
                        {value.Name}
                      </Button>
                    );
                  })}
                <div id="meetingtypeError" className="error-div pl-sm" />
              </div>
            </div>
            <div className="col-md-3 col-sm-3">
              {/* {props.showTemplate && typeof templateList !== "undefined" && */}
              {props.showTemplate && props.templateListData && props.templateListData.result !== undefined &&
                props.templateListData.result.length > 0 && (
                  <div style={{ marginTop: ".5em" }}>
                    <DropDownListComponent
                      id="template_list"
                      name="template_list"
                      className="e-templatelist e-field"
                      dataSource={props.templateListData.result}
                      // ref={(dropdownlist) => {
                      //   this.listtemplateref = dropdownlist;
                      // }}
                      fields={{
                        value: "int_meeting_template_uid",
                        text: "var_meeting_template_name",
                      }}
                      popupHeight="220px"
                      value={templateId}
                      change={handleChangeTemplate}
                      placeholder="Meeting Templates"
                      floatLabelType="Auto"
                      showClearButton
                      cssClass="e-outline"
                    />
                    <div id="opscatError" className="error-div" />
                  </div>)}
            </div>
          </div>
          <div className="col-md-12">
            <div className="row1">
              {/* Meeting Sub Type */}
              {meetingSubType.length > 0 && (
                <div className="col-md-4 pt-md">
                  <div className="custom-margin custom-padding-5 material2">
                    <div className="col-md-12">
                      <div className="e-input-group e-float-icon-left">
                        <FontAwesomeIcon
                          icon={faChalkboardTeacher}
                          className="mt-sm fa-lg"
                        />
                        <div className="e-input-in-wrap ml-sm">
                          <div style={{ width: "100%", display: "grid" }}>
                            <ComboBoxComponent
                              id="mst-select"
                              ref={(combobox) => {
                                listsubType = combobox;
                              }}
                              name="mst-select"
                              dataSource={meetingSubType}
                              fields={{
                                text: "Name",
                                value: "Id",
                              }}
                              change={handleChange}
                              allowCustom={false}
                              placeholder="Activity Type *"
                              value={meetingSubTypeId}
                              cssClass="e-outline"
                              floatLabelType="Auto"
                              focus={handleOpensubType}
                              data-msg-containerid="subtypeError"
                            />

                            <div id="subtypeError" className="error-div" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {/* Meeting Service Product */}
              {meetingServiceProduct.length > 0 && SalesClientMeet.includes(meetingSubTypeId) &&

                <div className="col-md-4 pt-md">
                  <div className="custom-margin custom-padding-5 material2">
                    <div className="col-md-12">
                      <div className="e-input-group e-float-icon-left">
                        <FontAwesomeIcon
                          icon={faBoxOpen}
                          className="mt-sm fa-lg1"
                        />
                        <div className="e-input-in-wrap">
                          <div style={{ width: "100%", display: "grid" }}>
                            <MultiSelectComponent
                              style={{ display: "inline-block" }}
                              id="sp-select"
                              name="sp-select"
                              dataSource={meetingServiceProduct}
                              fields={{ text: "Name", value: "Id" }}
                              // placeholder="Service/Platform *"
                              placeholder={(dencrypt(localStorage.getItem('pnlId')) === 2 && decodeString(localStorage.getItem('user_type')) === "Sales") ? "Service/Platform" : "Service/Platform *"}
                              cssClass="e-outline sp-select"
                              floatLabelType="Auto"
                              showSelectAll={false}
                              popupHeight="350px"
                              change={handleChange}
                              value={serviceProductsId}
                              showDropDownIcon
                              filterBarPlaceholder="Service/Platform *"
                              data-msg-containerid="srvprdError"
                              mode="CheckBox"
                              allowFiltering={false}
                            >
                              <Inject services={[CheckBoxSelection]} />
                            </MultiSelectComponent>
                            <div id="srvprdError" className="error-div" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              }

              {/* Meeting Category */}
              <div>
                {(isClientMeeting ||
                  (OPSBTBClientMeet.includes(meetingSubTypeId) || SalesClientMeet.includes(meetingSubTypeId))
                ) && ( // Billable - Client Meeting
                    <div className="col-md-4 pt-md">
                      <div className="custom-margin custom-padding-5 material2">
                        <div className="col-md-12">
                          <div className="e-input-group e-float-icon-left">
                            <FontAwesomeIcon
                              icon={faList}
                              className="mt-sm fa-lg"
                            />
                            <div className="e-input-in-wrap">
                              <div style={{ width: "100%", display: "grid" }}>
                                <ComboBoxComponent
                                  id="mc-select"
                                  ref={(combobox) => {
                                    listcategory = combobox;
                                  }}
                                  name="mc-select"
                                  dataSource={meetingCategory}
                                  fields={{ text: "Name", value: "Id" }}
                                  change={handleChange}
                                  placeholder="Meeting Category"
                                  value={meetingCategoryId}
                                  popupHeight="220px"
                                  cssClass="e-outline"
                                  floatLabelType="Auto"
                                  focus={handleOpensourceMeetingCategory}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className='row1'>
              {/* Meeting Source  * */}
              {SalesClientMeet.includes(meetingSubTypeId) && (
                <div className={MarkrtingMeetingIds.includes(meetingSourceId) ? "col-md-4 pt-xl mt-sm" : "col-md-4 pt-md"}>
                  <div className="custom-margin custom-padding-5 material2">
                    <div className="col-md-12">
                      <div className="e-input-group e-float-icon-left">
                        <FontAwesomeIcon
                          icon={faList}
                          className="mt-sm fa-lg"
                        />
                        <div className="e-input-in-wrap">
                          <div style={{ width: "100%", display: "grid" }}>
                            <ComboBoxComponent
                              id="ms-select"
                              ref={(combobox) => {
                                listsource = combobox;
                              }}
                              name="ms-select"
                              dataSource={meetingSource}
                              fields={{ text: "Name", value: "Id", groupBy: 'Optional' }}
                              change={handleChange}
                              placeholder="Meeting Source *"
                              value={meetingSourceId}
                              popupHeight="220px"
                              cssClass="e-outline"
                              floatLabelType="Auto"
                              focus={handleOpensource}
                              data-msg-containerid="sourceError"
                            />
                            <div id="sourceError" className="error-div" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {(MarkrtingMeetingIds.includes(meetingSourceId) && SalesClientMeet.includes(meetingSubTypeId)) &&
              <fieldset id="fieldset">
                <legend id="legend">&nbsp; Tag Campaign - Practice Area / Marketing Activity / Campaign &nbsp;</legend>
                <div className='row1'>
                  {/* Meeting Category * */}
                  <div>
                    {/* {
                props.meetingId === 0 ? MarkrtingMeetingIds.includes(meetingSourceId) && 
                (isClientMeeting ||
                  (OPSBTBClientMeet.includes(meetingSubTypeId) || SalesClientMeet.includes(meetingSubTypeId))
                ) &&  */}
                    {(MarkrtingMeetingIds.includes(meetingSourceId) &&
                      ( // Billable - Client Meeting
                        <div className="col-md-3 ec-pa-ma-ca ec-pa-ma">
                          <div className="e-input-group e-float-icon-left">
                            <FontAwesomeIcon
                              icon={faList}
                              className="mt-sm fa-lg"
                            />
                            <div className="custom-margin custom-padding-5 material2">
                              <div className="col-md-12">
                                <div className="e-input-in-wrap">
                                  <div style={{ width: "100%", display: "grid" }}>
                                    {practiceAreaData.length > 0 ? (
                                      <ComboBoxComponent
                                        id="pa-select"
                                        name="pa-select"
                                        dataSource={practiceAreaData}
                                        // fields={{ text: "PracticeArea", value: "PracticeArea" }}
                                        change={(e) => handleChange(e, 'Add')}
                                        placeholder={'Practice Area *'}
                                        value={practiceArea}
                                        popupHeight="220px"
                                        popupWidth="250px"
                                        cssClass="e-outline"
                                        floatLabelType="Auto"
                                        // focus={handleOpensourceMeetingCategory}
                                        data-msg-containerid="paError"
                                      />
                                    ) : (
                                      <ComboBoxComponent
                                        id="pa-select"
                                        name="pa-select"
                                        // fields={{ text: "PracticeArea", value: "PracticeArea" }}
                                        placeholder={'Practice Area *'}
                                        popupHeight="220px"
                                        popupWidth="250px"
                                        cssClass="e-outline"
                                        floatLabelType="Auto"
                                        // focus={handleOpensourceMeetingCategory}
                                        data-msg-containerid="paError"
                                      />
                                    )}
                                    <div id="paError" className="error-div" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                </div>
                <div className='row1'>
                  {/* Meeting Category * */}
                  <div>
                    {(MarkrtingMeetingIds.includes(meetingSourceId)
                    ) && ( // Billable - Client Meeting
                        <div className="col-md-3 ec-pa-ma-ca ec-pa-ma">
                          <div className="custom-margin custom-padding-5 material2">
                            <div className="col-md-12">
                              <div className="e-input-in-wrap">
                                <div style={{ width: "100%", display: "grid" }}>
                                  {marketActivityDataSource.length > 0 ? (
                                    <ComboBoxComponent
                                      id="mar-select"
                                      name="mar-select"
                                      dataSource={marketActivityDataSource}
                                      // fields={{ text: "Name", value: "Id" }}
                                      change={handleChange}
                                      placeholder={'Marketing Activity *'}
                                      value={marketActivity}
                                      popupHeight="220px"
                                      cssClass="e-outline"
                                      floatLabelType="Auto"
                                      // focus={handleOpensourceMeetingCategory}
                                      data-msg-containerid="marError"
                                      enabled={isMarketActivityActivated}
                                    />
                                  ) : (
                                    <ComboBoxComponent
                                      id="mar-select"
                                      name="mar-select"
                                      // fields={{ text: "Name", value: "Id" }}
                                      placeholder={'Marketing Activity *'}
                                      popupHeight="220px"
                                      cssClass="e-outline"
                                      floatLabelType="Auto"
                                      // focus={handleOpensourceMeetingCategory}
                                      data-msg-containerid="marError"
                                      enabled={isMarketActivityActivated}
                                    />
                                  )}
                                  <div id="marError" className="error-div" />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                  </div>
                </div>
                <div className='row1'>
                  {/* Meeting Category * */}
                  <div>
                    {(MarkrtingMeetingIds.includes(meetingSourceId) &&
                      // Billable - Client Meeting
                      <div className="col-md-4 ec-pa-ma-ca ec-ca">
                        <div className="custom-margin custom-padding-5 material2">
                          <div className="col-md-12">
                            <div className="e-input-group e-float-icon-left">
                              <FontAwesomeIcon
                                icon={faList}
                                className="mt-sm fa-lg"
                              />
                              <div className="e-input-in-wrap">
                                <div style={{ width: "100%", display: "grid" }}>
                                  {marketActivityDataSource.length > 0 ? (
                                    <ComboBoxComponent
                                      id="cd-select"
                                      name="cd-select"
                                      dataSource={campaign}
                                      fields={{ text: "Name", value: "Id" }}
                                      change={handleChange}
                                      placeholder={'Campaign *'}
                                      value={campaignId}
                                      popupHeight="220px"
                                      cssClass="e-outline"
                                      floatLabelType="Auto"
                                      // focus={handleOpensourceMeetingCategory}
                                      data-msg-containerid="cdError"
                                      enabled={isCampaignActivated}
                                    />
                                  ) : (
                                    <ComboBoxComponent
                                      id="cd-select"
                                      name="cd-select"
                                      fields={{ text: "Name", value: "Id" }}
                                      placeholder={'Campaign *'}
                                      popupHeight="220px"
                                      cssClass="e-outline"
                                      floatLabelType="Auto"
                                      // focus={handleOpensourceMeetingCategory}
                                      data-msg-containerid="cdError"
                                      enabled={isCampaignActivated}
                                    />
                                  )}
                                  <div id="cdError" className="error-div" />
                                </div>

                              </div>

                            </div>
                          </div>
                        </div>
                      </div>
                    )
                    }
                  </div>
                </div>
              </fieldset>
            }
            {(isClientMeeting || OPSBTBClientMeet.includes(meetingSubTypeId) || SalesClientMeet.includes(meetingSubTypeId)) && (!OPSBTBDropdown.includes(meetingSubTypeId)) &&
              (
                <Clientsearch meetingTypeNm={meetingTypeNm} />
              )}

            {/* Meeting type OPS  */}
            {(OPSBTBDropdown.includes(meetingSubTypeId) &&
              <OpsTasksearch meetingTypeNm={meetingTypeNm} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = ({
  meetingbooking,
  auth,
  meetingmaster,
  meetingcategory, candidateReducer

}) => {
  const { authUser, otherUser } = auth;
  const { categoryName } = meetingcategory;
  const { servceDivisionSalesUnitMaster } = candidateReducer;
  const {
    meetingId,
    meetingTypeId,
    meetingSubTypeId,
    meetingSourceId,
    meetingSourceType,
    serviceProductsId,

    btbId,
    subBTBId,
    taskId,
    clientId,
    contactsId,
    isClientMeeting,
    validate,
    meetingCategoryId,
    meetingmode,
    backDatesClientEditAccess,
    meetingTypeName,
    workingHrStartTime,
    workingHrEndTime,
    campaignName,
    campaignId,
    templateId,
    templateListData
  } = meetingbooking;
  const {
    meetingType,
    meetingSubType,
    meetingSource,
    meetingServiceProduct,
    meetingCategory,
    campaignData,

  } = meetingmaster;
  return {
    servceDivisionSalesUnitMaster,
    campaignData,
    authUser,
    categoryName,
    meetingId,
    meetingTypeId,
    meetingSubTypeId,
    meetingSourceId,
    meetingSourceType,
    serviceProductsId,
    btbId,
    subBTBId,
    taskId,
    clientId,
    contactsId,
    isClientMeeting,
    validate,
    meetingType,
    meetingSubType,
    meetingSource,
    meetingServiceProduct,
    meetingCategory,
    meetingCategoryId,
    meetingmode,
    backDatesClientEditAccess,
    meetingTypeName,
    otherUser,
    workingHrStartTime,
    workingHrEndTime,
    campaignName,
    campaignId,
    meetingbooking,
    templateId,
    templateListData
  };
};
MeetingType.propTypes = {
  authUser: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  meetingSubTypeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  campaignId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  meetingSourceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  meetingSourceType: PropTypes.string,
  meetingCategoryId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  meetingTypeName: PropTypes.string,
  // eslint-disable-next-line
  serviceProductsId: PropTypes.array,
  // eslint-disable-next-line
  meetingCategory: PropTypes.array,
  // eslint-disable-next-line
  meetingType: PropTypes.array,
  // eslint-disable-next-line
  meetingSubType: PropTypes.array,
  // eslint-disable-next-line
  meetingServiceProduct: PropTypes.array,
  // eslint-disable-next-line
  meetingSource: PropTypes.array,
  campaignData: PropTypes.array,
  marketingActivityData: PropTypes.array,
  practiceAreaData: PropTypes.array,
  meetingId: PropTypes.number,
  meetingTypeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  backDatesClientEditAccess: PropTypes.bool,
  isClientMeeting: PropTypes.bool,
  meetingmode: PropTypes.string,
  workingHrStartTime: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date)
  ]),
  workingHrEndTime: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date)
  ]),
};
MeetingType.defaultProps = {
  authUser: "",
  meetingTypeName: "",
  meetingSubTypeId: "",
  campaignId: "",
  meetingSourceId: "",
  meetingSourceType: '',
  meetingCategoryId: "",
  // eslint-disable-next-line
  serviceProductsId: [],
  meetingServiceProduct: [],
  meetingCategory: [],
  campaignData: [],
  marketingActivityData: [],
  practiceAreaData: [],
  meetingType: [],
  meetingSubType: [],
  meetingSource: [],
  meetingId: 0,
  meetingTypeId: 0,
  backDatesClientEditAccess: false,
  isClientMeeting: false,
  meetingmode: "",
};
export default connect(mapStateToProps, {
  updateMeetingBookingState,
})(MeetingType);
