import React, { useState, useEffect, useRef } from 'react'
import { FormValidator } from "@syncfusion/ej2-inputs";
import { SwitchComponent } from '@syncfusion/ej2-react-buttons';
import { EnterbusinessFunctionUnit, SelectHierarchy, EnterCompanyName, EnterAbbrevation, EnterTeamsDomainName, EnterDigitalDomainName, EnterDomainName, EnterpnlHeadName, ParentRequired } from "../../../../assets/constants/constants";
import { TextBoxComponent, ColorPickerComponent } from "@syncfusion/ej2-react-inputs";
import {
  ComboBoxComponent,
  AutoCompleteComponent,
} from "@syncfusion/ej2-react-dropdowns";
import { Query, Predicate } from '@syncfusion/ej2-data';
import { Picker } from "@material-ui/pickers";
import UploadDocuments from '../../../okrratingcomponents/uploaddocuments'
export default function BusinessUnitFunctionPopup(props) { // NOSONAR
  const [businessUnitFunctionData, setbusinessUnitFunctionData] = useState({
    hierarchy: null,
    businessUnitFunctionName: "",
    companyname: "",
    abbrevation: "",
    domain: "",
    maxHierarchy: "",
    businessUnitFunctionId: 0,
    pnlFuncHead: null,
    color: '#008000',
    DigitalEmail: '',
    TeamsEmail: '',
    IsActingHead: '0',
    isParent: '1',
    parent: 0,
    logo: null,
  });

  const [state, setState] = useState({ logo: [] })
  const pnlFuncHeadTitle = useRef({});

  const [hierarchy, setHierarchy] = useState([]);

  useEffect(() => {
    const { maxHierarchy } = props;
    const { businessUnitFunction, abrivation, companyName, domain, businessUnitFunctionId, pnlFuncHead, color,
      hierarchy, DigitalEmail, IsActingHead, Logo, TeamsEmail, parent } = props.businessUnitFunctionAreaData;//NOSONAR
    if (props.type === 'Edit') {
      setbusinessUnitFunctionData({

        hierarchy: hierarchy,
        businessUnitFunctionName: businessUnitFunction,
        businessUnitFunctionId: businessUnitFunctionId,
        companyname: companyName,
        abbrevation: abrivation,
        domain: domain,
        maxHierarchy: maxHierarchy,
        pnlFuncHead: pnlFuncHead,
        color: color,
        DigitalEmail: DigitalEmail,
        TeamsEmail: TeamsEmail,
        IsActingHead: IsActingHead,
        parent,
        isParent: parent === 0 ? '1' : '0',
        logo: Logo,
      })
      Logo && setState(prevState => ({ ...prevState, logo: [Logo] }))
    }
    let hierarchyDS = [];
    for (let i = 1; i <= maxHierarchy; i++) {
      hierarchyDS.push({ Id: i, Name: i.toString() })
    }
    setHierarchy(hierarchyDS);
    let data = {
      hierarchy: hierarchy,
      businessUnitFunctionName: businessUnitFunction,
      businessUnitFunctionId: businessUnitFunctionId,
      companyname: companyName,
      abbrevation: abrivation,
      domain: domain,
      maxHierarchy: maxHierarchy,
      pnlFuncHead: pnlFuncHead,
      color: color,
      DigitalEmail: DigitalEmail,
      TeamsEmail: TeamsEmail,
      IsActingHead: IsActingHead,
      parent,
      logo: Logo
    }
    props.parentClassFunction(data);
  }, [])
  useEffect(() => {
    try {
      if (pnlFuncHeadTitle && businessUnitFunctionData.pnlFuncHead) {
        if (pnlFuncHeadTitle.current.dataSource.length > 0) {
          pnlFuncHeadTitle.current.dataSource.forEach(user => {
            if (user.UserId === businessUnitFunctionData.pnlFuncHead) {
              pnlFuncHeadTitle.current.text = user.nameWithDepartment
            }
          });
        }
      }
    } catch (err) {
      console.log(err)
    }
  }, [businessUnitFunctionData.pnlFuncHead]);

  const handleFilteringByName = (e) => {
    e.preventDefaultAction = true;
    let predicate = new Predicate('nameWithDepartment', 'contains', e.text, true);
    predicate = predicate.or('UserId', 'contains', e.text, true);

    let query = new Query();
    //frame the query based on search string with filter type.
    query = (e.text != "") ? query.where(predicate) : query;

    //pass the filter data source, filter query to updateData method.
    e.updateData(props.othersCheckAvailablity, query);
  };

  const businessUnitFunctionChange = (event, type) => {
    let options = {}
    switch (type) {
      case "businessUnitFunction":
        businessUnitFunctionData.businessUnitFunctionName = event.value;
        setbusinessUnitFunctionData(businessUnitFunctionData);
        options = {
          rules: {
            businessUnitFunctionErr: {
              required: [true, EnterbusinessFunctionUnit],
            }
          },
        };
        break;
      case "Hierarchy":
        businessUnitFunctionData.hierarchy = event.itemData !== null ? event.itemData.Name : '';
        setbusinessUnitFunctionData(businessUnitFunctionData);
        options = {
          rules: {
            HierarchyErr: {
              required: [true, SelectHierarchy],
            }
          },
        };
        break;
      case "Companyname":
        businessUnitFunctionData.companyname = event.value;
        setbusinessUnitFunctionData(businessUnitFunctionData);
        options = {
          rules: {
            CompanynameErr: {
              required: [true, EnterCompanyName],
            }
          },
        };
        break;
      case "Abbrevation":
        businessUnitFunctionData.abbrevation = event.value.toUpperCase();
        setbusinessUnitFunctionData(businessUnitFunctionData);
        options = {
          rules: {
            AbbrevationErr: {
              required: [true, EnterAbbrevation],
              maxLength: [2, "Please enter 2 characters only."],
              regex: [/^[a-z]{2}$/i, "Please enter 2 characters only."],
            }
          },
        };
        break;
      case "Domain":
        businessUnitFunctionData.domain = event.value;
        setbusinessUnitFunctionData(businessUnitFunctionData);
        options = {
          rules: {
            DomainErr: {
              required: [true, EnterDomainName],
              // eslint-disable-next-line
              // regex: [/^((?:(?:(?:\w[\.\-\+]?)*)\w)+)((?:(?:(?:\w[\.\-\+]?){0,62})\w)+)\.(\w{2,6})$/,
              //   ValidDomainName
              // ],
            }
          },
        };
        break;
      case "DigitalEmail":
        businessUnitFunctionData.DigitalEmail = event.value;
        setbusinessUnitFunctionData(businessUnitFunctionData);
        options = {
          rules: {
            DigitalEmailErr: {
              required: [true, EnterDigitalDomainName],
              // eslint-disable-next-line
              // regex: [/^((?:(?:(?:\w[\.\-\+]?)*)\w)+)((?:(?:(?:\w[\.\-\+]?){0,62})\w)+)\.(\w{2,6})$/,
              //   'Please enter valid digital domain name'
              // ],
            }
          },
        };
        break;
      case "TeamsEmail":
        businessUnitFunctionData.TeamsEmail = event.value;
        setbusinessUnitFunctionData(businessUnitFunctionData);
        options = {
          rules: {
            TeamsEmailErr: {
              required: [true, EnterTeamsDomainName],
              // eslint-disable-next-line
              // regex: [/^((?:(?:(?:\w[\.\-\+]?)*)\w)+)((?:(?:(?:\w[\.\-\+]?){0,62})\w)+)\.(\w{2,6})$/,
              //   'Please enter valid teams domain name'
              // ],
            }
          },
        };
        break;
      case "pnlHead":
        businessUnitFunctionData.pnlFuncHead = event.itemData ? event.itemData.UserId : ''
        setbusinessUnitFunctionData(businessUnitFunctionData);
        pnlFuncHeadTitle.current.text = event.itemData.nameWithDepartment;

        options = {
          rules: {
            pnlHeadErr: {
              required: [true, EnterpnlHeadName],
            }
          },
        };
        break;
      case "hexCode":
        businessUnitFunctionData.color = event.value;
        setbusinessUnitFunctionData(businessUnitFunctionData);
        break;
      case "logo":
        businessUnitFunctionData.logo = event;
        setbusinessUnitFunctionData({ ...businessUnitFunctionData, Logo: event });
        break;
      case "isActingHead":
        businessUnitFunctionData.IsActingHead = event.checked === true ? '1' : '0';
        setbusinessUnitFunctionData(businessUnitFunctionData);
        break;
      case "isParent":
        businessUnitFunctionData.parent = event.checked === true ? 0 : businessUnitFunctionData.parent;
        setbusinessUnitFunctionData({ ...businessUnitFunctionData, isParent: event.checked === true ? '1' : '0', parent: event.checked === true ? '' : businessUnitFunctionData.parent });
        break;
      case "parent":
        if (businessUnitFunctionData.isParent === '0') {
          options = {
            rules: {
              parentErr: {
                required: [true, ParentRequired],
              }
            },
          };
        }
        businessUnitFunctionData.parent = event.value;
        setbusinessUnitFunctionData(businessUnitFunctionData);
        break;

      default:
        break;
    }
    setTimeout(() => {
      const addFormObject = new FormValidator("#businessUnitFunctionForm", options);
      addFormObject.validate();
    }, 100);
    props.parentClassFunction(businessUnitFunctionData);
  }

  const selectedFiles = (file) => {
    if (file) {
      let tempFile = file.slice(-1)
      setState(prevState => ({ ...prevState, logo: tempFile }));
      businessUnitFunctionChange(tempFile[0], 'logo')
    } else {
      setState(prevState => ({ ...prevState, logo: [] }));
    }
  }

  const documentDetails = (logo) => {
    setState(prevState => ({ ...prevState, fileName: logo }));
  }

  return (
    <div>
      <form id="businessUnitFunctionForm">
        <div className="row">
          <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4">
            <TextBoxComponent
              id="businessUnitFunction"
              fields={{ text: "Name", value: "Id" }}
              placeholder="Business Unit / Group Function  *"
              floatLabelType="Auto"
              value={businessUnitFunctionData.businessUnitFunctionName}
              data-msg-containerid="businessUnitFunctionErr"
              name="businessUnitFunctionErr"
              cssClass="e-outline"
              onChange={(e) => businessUnitFunctionChange(e, "businessUnitFunction")}
              htmlAttributes={{ maxlength: 100 }}
            />
            <div id="businessUnitFunctionErr"
              className="e-input-group e-float-icon-left error-div" />
          </div>

          <div >
            <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4" >
              {hierarchy.length > 0 && <ComboBoxComponent
                id="hierarchy"
                dataSource={hierarchy}
                fields={{ text: "Name", value: "Id" }}
                value={businessUnitFunctionData.hierarchy}
                data-msg-containerid="HierarchyErr"
                name="HierarchyErr"
                allowFiltering
                allowCustom={false}
                floatLabelType="Auto"
                cssClass="e-outline"
                placeholder="Hierarchy *"
                change={(e) => businessUnitFunctionChange(e, "Hierarchy")}

              />}
              <div
                id="HierarchyErr"
                className="e-input-group e-float-icon-left error-div"
              />
            </div>
          </div>
          <div >
            <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4" >
              <div style={{ display: 'flex' }}>
                <div style={{ paddingTop: '7px', color: 'grey' }}>Pick colour :</div>
                <div style={{ paddingTop: '4px' }}>
                  <ColorPickerComponent
                    id="color-picker"
                    value={businessUnitFunctionData.color}
                    mode={Picker}
                    change={(e) => businessUnitFunctionChange(e, "hexCode")}
                  ></ColorPickerComponent>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4">
            <TextBoxComponent
              id="Companyname"
              placeholder="Company Name *"
              floatLabelType="Auto"
              value={businessUnitFunctionData.companyname}
              data-msg-containerid="CompanynameErr"
              name="CompanynameErr"
              cssClass="e-outline"
              onChange={(e) => businessUnitFunctionChange(e, "Companyname")}
              htmlAttributes={{ maxlength: 100 }}
            />
            <div
              id="CompanynameErr"
              className="e-input-group e-float-icon-left error-div"
            />
          </div>
          <div  >
            <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4">
              <TextBoxComponent
                id="Abbrevation"
                placeholder="Abbrevation *"
                floatLabelType="Auto"
                value={businessUnitFunctionData.abbrevation}
                data-msg-containerid="AbbrevationErr"
                name="AbbrevationErr"
                cssClass="e-outline"
                onChange={(e) => businessUnitFunctionChange(e, "Abbrevation")}
                htmlAttributes={{ maxlength: 2 }}
              />
              <div
                id="AbbrevationErr"
                className="e-input-group e-float-icon-left error-div"
              />
            </div>
            <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4">
              <TextBoxComponent
                id="Domain"
                placeholder="Domain *"
                floatLabelType="Auto"
                value={businessUnitFunctionData.domain}
                data-msg-containerid="DomainErr"
                name="DomainErr"
                cssClass="e-outline"
                onChange={(e) => businessUnitFunctionChange(e, "Domain")}
              // htmlAttributes={{ type: "domain" }}
              />
              <div
                id="DomainErr"
                className="e-input-group e-float-icon-left error-div"
              />
            </div>
          </div>
        </div>
        <div className="row" >
          <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4">
            <TextBoxComponent
              id="DigitalEmail"
              placeholder="Digital Email Domain *"
              floatLabelType="Auto"
              value={businessUnitFunctionData.DigitalEmail}
              data-msg-containerid="DigitalEmailErr"
              name="DigitalEmailErr"
              cssClass="e-outline"
              onChange={(e) => businessUnitFunctionChange(e, "DigitalEmail")}
            // htmlAttributes={{ type: "domain" }}
            />
            <div
              id="DigitalEmailErr"
              className="e-input-group e-float-icon-left error-div"
            />
          </div>

          <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4">
            <TextBoxComponent
              id="TeamsEmail"
              placeholder="Teams Email Domain *"
              floatLabelType="Auto"
              value={businessUnitFunctionData.TeamsEmail}
              data-msg-containerid="TeamsEmailErr"
              name="TeamsEmailErr"
              cssClass="e-outline"
              onChange={(e) => businessUnitFunctionChange(e, "TeamsEmail")}
            // htmlAttributes={{ type: "domain" }}
            />
            <div
              id="TeamsEmailErr"
              className="e-input-group e-float-icon-left error-div"
            />
          </div>
          <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4" style={{ display: 'flex' }}>
            <div style={{ marginTop: 10, marginRight: 10, color: 'grey' }}>Is Acting Head:</div>
            <div className="atc-switch-heading-start">
              <div className=" atc-switch-inline " style={{ marginTop: 10 }}>
                <div className="mr-md">No</div>
                <div>
                  <SwitchComponent
                    cssClass="atc-switch-height"
                    change={(e) => businessUnitFunctionChange(e, 'isActingHead')}
                    checked={businessUnitFunctionData.IsActingHead === '1' ? true : false}

                  />
                </div>
                <div className="ml-md">Yes</div>
              </div>
            </div>
          </div>

          {/* <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4">
            <TextBoxComponent
              id="logo"
              placeholder="Pnl Function logo*"
              floatLabelType="Auto"
              value={businessUnitFunctionData.logo}
              data-msg-containerid="logoErr"
              name="logoErr"
              cssClass="e-outline"
              // onChange={(e) => businessUnitFunctionChange(e, "Domain")}
            // htmlAttributes={{ type: "domain" }}
            />
            <div
              id="DomainErr"
              className="e-input-group e-float-icon-left error-div"
            />
          </div> */}

        </div>
        <div className="row">
          {/* {businessUnitFunctionData.pnlFuncHead &&  */}

          {/* } */}
          <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4">
            <AutoCompleteComponent
              id="pnlHead"
              ref={pnlFuncHeadTitle}
              cssClass="e-outline"
              floatLabelType="Auto"
              value={businessUnitFunctionData.pnlFuncHead}
              fields={{ text: "nameWithDepartment", value: "UserId" }}
              placeholder="Business Unit/ Function Head *"
              select={(e) => businessUnitFunctionChange(e, "pnlHead")}
              data-msg-containerid="pnlHeadErr"
              name="pnlHeadErr"
              ignoreCase={true}
              // noRecordsTemplate={this.noRecordsTemplate}
              filtering={handleFilteringByName}
              dataSource={props.othersCheckAvailablity}
            />
            <div
              id="pnlHeadErr"
              className="e-input-group e-float-icon-left error-div"
            />
          </div>
          <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4" style={{ display: 'flex' }}>
            <div style={{ marginTop: 10, marginRight: 10, color: 'grey' }}>Is Parent:</div>
            <div className="atc-switch-heading-start">
              <div className=" atc-switch-inline " style={{ marginTop: 10 }}>
                <div className="mr-md">No</div>
                <div>
                  <SwitchComponent
                    cssClass="atc-switch-height"
                    change={(e) => businessUnitFunctionChange(e, 'isParent')}
                    checked={businessUnitFunctionData.isParent === '1' ? true : false}
                  />
                </div>
                <div className="ml-md">Yes</div>
              </div>
            </div>
          </div>
          {businessUnitFunctionData.isParent === '0' && <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4">
            <ComboBoxComponent
              id="parent"
              fields={{ text: "Name", value: "Id" }}
              data-msg-containerid="parentErr"
              name="parentErr"
              cssClass="e-outline"
              floatLabelType="Auto"
              placeholder="Parent *"
              dataSource={props.parents}
              // value={businessUnitFunctionData.parent === 0 || businessUnitFunctionData.parent === null ? props.parents.length > 0 ? props.parents[0].Id : null : businessUnitFunctionData.parent}
              value={businessUnitFunctionData.parent === 0 || businessUnitFunctionData.parent === null ? '' : businessUnitFunctionData.parent}
              change={(e) => businessUnitFunctionChange(e, "parent")}
            />
            <div
              id="parentErr"
              className="e-input-group e-float-icon-left error-div"
            />
          </div>}

        </div>
        <div className="row">
          {<div className="col-xs-4 col-sm-4 col-lg-4 col-md-4">
            <div className="mb-sm ml-sm">Logo</div>
            <UploadDocuments
              type={"logo"}
              allowmultiple={false}
              fileList={state.logo}
              documentDetails={documentDetails}
              selectedFiles={selectedFiles}
              authUser={props.authUser}
            />
            <div
              id="pnlHeadErr"
              className="e-input-group e-float-icon-left error-div"
            />
          </div>}
        </div>
      </form>
    </div>
  );
}