/**
 * @author Vivek Khodade 
 * @description Apply Leave Form
 * Last Modify Date : 25-022022
 * Modify by Vivek Khodade.
 */
import React, { useRef, useEffect } from 'react'
import {
    ComboBoxComponent
} from "@syncfusion/ej2-react-dropdowns";
import { TextBoxComponent, NumericTextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { FormValidator } from "@syncfusion/ej2-inputs";
import { connect } from 'react-redux'
import { cracterLimit, removeTralingSpaces } from '../../../../app/other/commonfunction';
import { updateLnAData, applyLeave, saveLeaveTransferData } from '../../../../redux/actions/lna/lnaaction';
import LnAService from '../../../../services/lna/lnaservice';
import { BREModuleActions } from '../../../../assets/constants/constants'
import Leavetabs from '../leavesbalance/leavetabs';
import NotificationContainer from "../../../../components/notification/notifycontainer";
import BREServices from "../../../../services/breservice";
import Leavetransferdetails from './leavetransferdetails';
import ConfirmationPopup from '../../../../utilities/confirmationpopup';
import { getYearData } from '../../../../app/other/commonlayoutfunction';
import ProgressBar from '../../../progressbar/progressbar';


const sampleCss = `.e-outline textarea, textarea.e-outline, .e-outline.e-float-input textarea, .e-outline.e-float-input.e-control-wrapper textarea, .e-outline.e-float-input.e-input-group textarea {
       box-sizing: border-box;
       min-height: 100px;
     }`

function LeaveTransfer(props) { //NOSONAR
    const {
        authUser,
        leaveApplyLoadName,
        fyId,
        financialYearData
    } = props;


    const [leaveToBeTransfer, setLeaveToBeTransfer] = React.useState([])
    const [minLeavesAllowed, setMinLeavesAllowed] = React.useState({})
    const [isLoading, setIsLoading] = React.useState(false)
    const [leaveTransferData, setLeaveTransferData] = React.useState({
        sourceLeaveSymbol: null,
        destinationLeaveSymbol: null,
        noOfLeavesToBeTransferred: null,
        reason: '',
    })

    const [state, setState] = React.useState({
        height: null,
        leaveId: 0,
        status: "",
        open: false,
        title: "",
        message: "",
        containerHeight: null,

    });
    const option2 = {
        rules: {
            sourceErr: {
                required: [true, 'Please select source leave type.'],
            },
            destinationErr: {
                required: [true, 'Please select destination leave type.'],
            },
            noOfDaysErr: {
                required: [true, 'Please enter no. of leaves.'],
            },
            leaveTransferReasonErr: {
                required: [true, 'Please enter reason.'],
            },
        },
    };
    const clearErrorMessages = () => {
        const errorFields = [
            "sourceErr",
            "destinationErr",
            "noOfDaysErr",
            "leaveTransferReasonErr",
            "leaveTransferReasonCount"
        ];

        errorFields.forEach(field => {
            const elem = document.getElementById(field);
            if (elem) {
                elem.innerHTML = "";
            }
        });
    };

    const textareaObj = useRef(null);
    useEffect(() => {
        window.addEventListener("resize", updateDimensions());// NOSONAR
        getMasterData();
    }, [])

    const getBREDataForLeaveTransfer = async (pSouceLeaveId) => {
        const objData = { "int_leave_id": pSouceLeaveId }
        return BREServices.getBREData(authUser, BREModuleActions.leaveTransferApplication, objData);
    }

    const updateDimensions = () => {
        const updateheight = window.innerHeight - 125
        const lavContHeight = window.innerHeight - 235
        const containerHeight = window.innerHeight - 95
        setState(preState => ({
            ...preState, notifiresPopupHeight: updateheight, height: lavContHeight, containerHeight: containerHeight,
            status: "",
            open: false,
            title: "",
            message: "",
        }))
        return
    }

    useEffect(() => {
        if (props.leaveTransferFlag === "updated") {
            setLeaveTransferData({
                ...leaveTransferData,
                sourceLeaveSymbol: null,
                destinationLeaveSymbol: null,
                noOfLeavesToBeTransferred: null,
                reason: '',
            })
            setState({
                status: "",
                open: false, title: "",
                message: "",
            })
            setIsLoading(false)
            props.updateLnAData({ leaveTransferFlag: '' })
        }
        if (props.leaveTransferFlag === 'errorMsg') {
            setState({
                status: "",
                open: false, title: "",
                message: "",
            })
            setIsLoading(false)
            props.updateLnAData({ leaveTransferFlag: '' })
        }
    }, [props.leaveTransferFlag]);

    useEffect(() => {
        if (!fyId) {
            getYearData(authUser)
                .then(result => {
                    props.updateLnAData({ fyId: result })
                })
                .catch(err => {
                    console.log('err===', err)
                });
        }
    }, [])

    const getMasterData = async () => {
        let tempId = fyId
        if (!tempId) {
            let result = await getYearData(authUser)
            if (result) {
                tempId = result
                props.updateLnAData({ fyId: result })
            }
        }
        if (tempId) {
            const LeavesToBeTransferData = await LnAService.getLeavesToBeTransferData('FC', authUser, tempId);
            if (LeavesToBeTransferData.Result) {
                let leaveType = []
                let minLeaves = {}
                let ids = {}
                LeavesToBeTransferData.Result.map((item) => {
                    if (!ids[item.leaveClusterId]) {
                        leaveType.push({ leaveSymbol: item.leaveType, Id: item.leaveClusterId })
                        ids[item.leaveClusterId] = true
                    }
                    minLeaves[item.leaveClusterId] = item.minNoOfLeavesTransferAllowed
                })
                setLeaveToBeTransfer(leaveType)
                setMinLeavesAllowed(minLeaves)
            }
        }
    }

    /**old code of OnChange event kept for reference */
    // const onChangeApplyLeave = (e, type) => {
    //     try {
    //         let options = {};
    //         let check = true
    //         switch (type) {
    //             case 'source':
    //                 setLeaveTransferData(prevState => ({ ...prevState, sourceLeaveSymbol: e.itemData !== null ? e.itemData.Id : '' }));
    //                 options = {
    //                     rules: {
    //                         sourceErr: {
    //                             required: [true, 'Please select source leave type.'],
    //                         },
    //                     },
    //                 };
    //                 break;
    //             case 'destination':
    //                 setLeaveTransferData(prevState => ({ ...prevState, destinationLeaveSymbol: e.itemData !== null ? e.itemData.Id : '' }));
    //                 options = {
    //                     rules: {
    //                         destinationErr: {
    //                             required: [true, 'Please select destination leave type.'],
    //                         },
    //                     },
    //                 };
    //                 break;
    //             case 'noOfDays':
    //                 if (e.value % minLeavesAllowed[leaveTransferData.sourceLeaveSymbol] === 0) {
    //                     setLeaveTransferData(prevState => ({ ...prevState, noOfLeavesToBeTransferred: e.value }));
    //                     options = {
    //                         rules: {
    //                             noOfDaysErr: {
    //                                 required: [true, 'Please enter no. of leaves.']
    //                             },
    //                         },
    //                     };
    //                 } else {
    //                     if (leaveTransferData.sourceLeaveSymbol !== null) {
    //                         check = false
    //                         setLeaveTransferData(prevState => ({ ...prevState, noOfLeavesToBeTransferred: null }));
    //                         options = {
    //                             rules: {
    //                                 noOfDaysErr: {
    //                                     required: [true, 'Please enter no. of leaves in multiples of ' + minLeavesAllowed[leaveTransferData.sourceLeaveSymbol] + ' only.'],
    //                                 },
    //                             },
    //                         };
    //                     }
    //                 }
    //                 break;
    //             case 'leaveReason':
    //                 setLeaveTransferData(prevState => ({ ...prevState, reason: e.value.trim() }));
    //                 options = {
    //                     rules: {
    //                         leaveTransferReasonErr: {
    //                             required: [true, 'Please enter reason.'],
    //                         },
    //                     },
    //                 };
    //                 break;

    //             default:
    //         }


    //     } catch (error) {
    //         console.log("🚀 ~ file: leavetransfer.jsx:185 ~ onChangeApplyLeave ~ error", error)
    //     }
    // }

    const onChangeApplyLeave = (e, type) => {
        try {
            switch (type) {
                case 'source':
                    setLeaveTransferData(prevState => ({ ...prevState, sourceLeaveSymbol: e.itemData !== null ? e.itemData.Id : '' }));
                    if (document.getElementById("sourceErr")) {
                        document.getElementById("sourceErr").innerHTML = ""
                    }
                    break;
                case 'destination':
                    setLeaveTransferData(prevState => ({ ...prevState, destinationLeaveSymbol: e.itemData !== null ? e.itemData.Id : '' }));
                    if (document.getElementById("destinationErr")) {
                        document.getElementById("destinationErr").innerHTML = ""
                    }
                    break;
                case 'noOfDays':
                    if (e.value % minLeavesAllowed[leaveTransferData.sourceLeaveSymbol] === 0) {
                        setLeaveTransferData(prevState => ({ ...prevState, noOfLeavesToBeTransferred: e.value }));
                    } else {
                        if (leaveTransferData.sourceLeaveSymbol !== null) {
                            setLeaveTransferData(prevState => ({ ...prevState, noOfLeavesToBeTransferred: e.value }));
                            setErrorMessageForNoOfLeaves();
                            return;
                        }
                    }
                    if (document.getElementById("noOfDaysErr")) {
                        document.getElementById("noOfDaysErr").innerHTML = ""
                    }
                    break;
                case 'leaveTransferReason':
                    setLeaveTransferData(prevState => ({ ...prevState, reason: removeTralingSpaces(e.target.value) }));
                    break;
                case 'financialYear':
                    if (e.itemData !== null) {
                        props.updateLnAData({ fyId: e.itemData.Id, updateFinancialYear: "updated" })
                    } else {
                        props.updateLnAData({ fyId: null, updateFinancialYear: "updated" })
                    }
                    break;
                default:
            }
        } catch (error) {
            console.log("🚀 ~ file: leavetransfer.jsx:185 ~ onChangeApplyLeave ~ error", error)
        }
    }

    const setErrorMessageForNoOfLeaves = () => {
        try {
            if (document.getElementById("noOfDaysErr") && leaveTransferData.sourceLeaveSymbol !== null) {
                if (document.getElementById("noOfDaysErr").innerHTML === '') {
                    if (document.getElementById("noOfDays"))
                        document.getElementById("noOfDays").ej2_instances[0].value = null
                    setTimeout(() => {
                        document.getElementById("noOfDaysErr").innerHTML = 'Please enter no. of leaves in multiples of ' + minLeavesAllowed[leaveTransferData.sourceLeaveSymbol] + ' only.'
                    }, 100);
                }
            }
        } catch (error) {
          console.log(error)
        }
    }

    const onInput = (e) => {
        try {
            if (textareaObj !== null && typeof textareaObj !== 'undefined') {
                textareaObj.current.respectiveElement.style.height = "auto";
                textareaObj.current.respectiveElement.style.minHeight = "100px";
                textareaObj.current.respectiveElement.style.height = `${textareaObj.current.respectiveElement.scrollHeight}px`;
                textareaObj.current.respectiveElement.style.maxHeight = "100px";
                cracterLimit(e, 'leaveTransferReasonCount', '1000')
            }
        } catch (error) {
        }
    };

    const handleConfirmNo = () => {
        setState({
            leaveTransferId: 0,
            status: "",
            open: false, title: "",
            message: "",
        })
        // setState({...state,state.})
    }


    const handleConfirmYes = () => {
        setIsLoading(true);
        const {
            reason, sourceLeaveSymbol, destinationLeaveSymbol, noOfLeavesToBeTransferred
        } = leaveTransferData
        let LeavetransferSaveData = {
            leaveTransferId: 0,
            userId: authUser,
            reason,
            sourceLeave: sourceLeaveSymbol,
            destinationLeave: destinationLeaveSymbol,
            noOfLeavesToBeTransferred: parseInt(noOfLeavesToBeTransferred),
            leaveYearId: fyId
        }

        if (document.getElementById("leaveTransferReasonErr")) {
            document.getElementById("leaveTransferReasonErr").innerHTML = ""
        }

        const Options = option2
        setTimeout(async () => {
            const addFormObject = new FormValidator('#leavetransfer', Options);

            if (addFormObject.validate()) {
                const getBREData = await getBREDataForLeaveTransfer(sourceLeaveSymbol)
                LeavetransferSaveData = {
                    ...LeavetransferSaveData,
                    supervisorApprovalRequired: getBREData.enum_supervisor_approval,
                    hrApprovalRequired: getBREData.enum_HR_approval,
                    autoApprovalRequired: getBREData.enum_auto_approval,
                    noOfHrsToAutoApprove: getBREData.int_no_hrs_to_approval
                }
                props.saveLeaveTransferData(LeavetransferSaveData)

            } else {
                // call getNotify function.
                return;
            }
            cracterLimit({ value: "" }, 'leaveTransferReasonCount', '1000')

        }, 100);

    }
    const resetInfo = () => {
        setLeaveTransferData({
            ...leaveTransferData,
            sourceLeaveSymbol: null,
            destinationLeaveSymbol: null,
            noOfLeavesToBeTransferred: null,
            reason: '',
        })
        cracterLimit({ value: "" }, 'leaveTransferReasonCount', '1000')
        clearErrorMessages()
    }

    const onSaveLeaveTransferData = () => {
        const Options = option2
        try {
            const { sourceLeaveSymbol, noOfLeavesToBeTransferred } = leaveTransferData
            const addFormObject = new FormValidator('#leavetransfer', Options);
            if (addFormObject.validate()) {
                let check = true;
                if ((noOfLeavesToBeTransferred === null) || (noOfLeavesToBeTransferred % minLeavesAllowed[sourceLeaveSymbol] !== 0)) {
                    setErrorMessageForNoOfLeaves();
                    // check = false
                }
                if (check)
                    setState({
                        status: "2",
                        open: true, title: "Apply Leave Transfer",
                        message: "Are you sure, you want to apply for the leave transfer?",
                    })
            }

        } catch (error) {
            console.log("🚀 ~ file: leavetransfer.jsx:320 ~ saveCompOffData ~ error", error)
        }
    }

    const minNoOfDays = minLeavesAllowed[leaveTransferData.sourceLeaveSymbol] ? minLeavesAllowed[leaveTransferData.sourceLeaveSymbol] : 1

    return (
        <div
            className="col-xs-12 col-sm-12 col-lg-12 col-md-12 leave-application-body"
            style={{
                backgroundColor: "#ffffff",
                height: state.containerHeight,
                borderRadius: "5px",
            }}
        >
            <NotificationContainer />
            <div
                className="col-xs-12 col-sm-12 col-lg-12 col-md-12"
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                }}>
                <div className='col-sm-12 col-md-12 col-lg-12'>
                    <div style={{
                        backgroundColor: '#343A40',
                        borderStartEndRadius: "0.4em",
                        borderStartStartRadius: "0.4em",
                        height: "2.5rem",
                        display: "flex",
                        alignItems: "center",
                        paddingLeft: "1rem",
                        fontWeight: 600,
                    }}
                    >
                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 leave-application-header"
                        // style={{ color: 'white', fontStyle: 'Circular Std Book', fontSize: '1rem', fontFamily: "Arial" }}
                        >      <div>
                                Leave Transfer Application
                            </div>

                            <div className="mr-sm combobox-css" style={{ width: "11rem", display: "flex", flexDirection: "row", marginLeft: "auto" }}>
                                <ComboBoxComponent
                                    id="financialYear"
                                    fields={{ text: "Name", value: "Id" }}
                                    cssClass="e-outline numberInputFont comboboxFYIDLA"
                                    data-msg-containerid="financialYearErr"
                                    name="financialYearErr"
                                    placeholder="Financial Year *"
                                    change={(e) => onChangeApplyLeave(e, 'financialYear')}
                                    value={fyId}
                                    dataSource={financialYearData}
                                    // showClearButton={false}
                                    allowCustom={false}
                                />
                            </div>
                        </div>
                    </div>
                </div>


                <div className="container pl-sm pr-sm" style={{ display: 'flex', height: state.containerHeight - 90, width: '100%', overflowY: 'hidden', overflowX: 'hidden' }}>

                    {/* <div style={{ height: '671px', border: '1px solid #ccc', borderRadius: "5px", boxShadow: "0.5px 0.5px #ccc", marginLeft: '0rem', marginTop: '15px', }}                          
                        className='col-lg-8 col-md-8 col-sm-8 col-xs-8 leave-application-body'>
                            <ErrorTabMessage message="User will not able to apply previous financial year(s) leave(s)" />
                        </div> */}


                    <div
                        style={{
                            border: '1px solid #ccc',
                            borderRadius: "5px",
                            boxShadow: "0.5px 0.5px #ccc",
                            overflowY: 'auto'
                        }}
                        className='col-lg-7 col-md-7 col-sm-7 col-xs-7 mt-sm leave-application-body'>
                        <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 ' style={{ bottom: '55px' }}>
                            <form id='leavetransfer'>
                                <div className='row1'>
                                    <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12' style={{ marginTop: 70 }} >
                                        <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6 pt-sm" style={{ paddingLeft: 0 }}>
                                            <ComboBoxComponent
                                                id="sourceLeave"
                                                fields={{ text: "leaveSymbol", value: "Id" }}
                                                cssClass="e-outline numberInputFont"
                                                data-msg-containerid="sourceErr"
                                                name="sourceErr"
                                                floatLabelType="Auto"
                                                placeholder="Source Leave Type *"
                                                htmlAttributes={{ maxlength: 100 }}
                                                change={(e) => onChangeApplyLeave(e, 'source')}
                                                allowCustom={false}
                                                value={leaveTransferData.sourceLeaveSymbol}
                                                dataSource={leaveToBeTransfer.filter(each => each.Id !== leaveTransferData.destinationLeaveSymbol)}
                                            />
                                            <div
                                                id="sourceErr"
                                                className="e-input-group e-float-icon-left error-div"
                                            />

                                        </div>
                                        <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6 pt-sm" style={{ paddingRight: 0 }}>
                                            <ComboBoxComponent
                                                id="destinationLeave"
                                                fields={{ text: "leaveSymbol", value: "Id" }}
                                                cssClass="e-outline numberInputFont"
                                                data-msg-containerid="destinationErr"
                                                name="destinationErr"
                                                floatLabelType="Auto"
                                                placeholder="Destination Leave Type *"
                                                htmlAttributes={{ maxlength: 100 }}
                                                change={(e) => onChangeApplyLeave(e, 'destination')}
                                                value={leaveTransferData.destinationLeaveSymbol}
                                                dataSource={leaveToBeTransfer.filter(each => each.Id !== leaveTransferData.sourceLeaveSymbol)}
                                                allowCustom={false}
                                            />
                                            <div
                                                id="destinationErr"
                                                className="e-input-group e-float-icon-left error-div"
                                            />

                                        </div>
                                    </div>
                                </div>

                                <div className='row1'>
                                    <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                                        <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6 pt-sm" style={{ paddingLeft: 0 }}>
                                            <NumericTextBoxComponent
                                                data-msg-containerid="noOfDaysErr"
                                                id="noOfDays"
                                                value={leaveTransferData.noOfLeavesToBeTransferred}
                                                name="noOfDaysErr"
                                                placeholder="No. Of Leaves To Be Transferred *"
                                                floatLabelType='Auto'
                                                htmlAttributes={{ maxlength: 100 }}
                                                change={(e) => onChangeApplyLeave(e, 'noOfDays')}
                                                cssClass="e-outline numberInputFont"
                                                min={minNoOfDays}
                                                format='##'
                                                showSpinButton={true}
                                                step={minLeavesAllowed[leaveTransferData.sourceLeaveSymbol]}
                                            />
                                            <div
                                                id='noOfDaysErr'
                                                className="e-input-group e-float-icon-left error-div"
                                            />
                                        </div>

                                    </div>
                                </div>
                                <div className='row1'>
                                    <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-sm' style={{ padding: 0 }}>

                                        <style>{sampleCss}</style>
                                        <TextBoxComponent
                                            style={{ minHeight: 100 }}
                                            id={'leaveTransferAppReason'}
                                            placeholder={"Reason *"}
                                            cssClass="e-outline textarea"
                                            floatLabelType="Auto"
                                            input={onInput}
                                            ref={textareaObj}
                                            name="leaveTransferReasonErr"
                                            data-msg-containerid="leaveTransferReasonErr"
                                            multiline
                                            inputProps={{
                                                required: true,
                                                minLength: 7
                                            }}
                                            htmlAttributes={{ maxlength: 1000 }}
                                            onChange={(e) => onChangeApplyLeave(e, 'leaveTransferReason')}
                                            value={leaveTransferData.reason}
                                        />

                                        <div id="leaveTransferReasonErr" className="error-div" />
                                        {(leaveTransferData.reason !== null && leaveTransferData.reason !== undefined && leaveTransferData.reason !== '') &&
                                            <div id='leaveTransferReasonCount' style={{ float: 'right', fontSize: 12, margin: '0px 15px 8px 0px' }} className="error-div" />}
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div >

                    <div
                        className='col-lg-5 col-md-5 col-sm-5 col-xs-5 leave-application-body mt-sm ml-md'
                        style={{
                            border: '1px solid #ccc',
                            borderRadius: "5px",
                            // boxShadow: "0.5px 0.5px #ccc",
                            fontFamily: "Arial, Helvetica, sans-serif",
                            color: "#848484",
                            padding: 0,
                            // overflowY: 'auto'
                            overflow: 'hidden'
                        }}
                    >
                        {
                            leaveApplyLoadName === 'leave-tabs' &&
                            <>
                                {<Leavetabs type={'Transfer'} selectedUserId={authUser} />}

                            </>
                        }
                        {
                            leaveApplyLoadName === 'leave-view' &&
                            <>
                                {<Leavetransferdetails type={"view-application"} />}

                            </>
                        }
                    </div>
                </div >
                {
                    leaveApplyLoadName === 'leave-tabs' &&
                    <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 pb-sm pt-sm pl-md' style={{ paddingRight: 0 }}>
                        <div className="pr-sm" style={{ float: "right" }}>
                            <button
                                className="leave-cancel-apply-button"
                                onClick={() => resetInfo()}
                            >
                                Cancel
                            </button>

                            <button
                                className="remove-button-shadow-save"
                                onClick={() => onSaveLeaveTransferData()}
                            >
                                Apply
                            </button>

                        </div>
                    </div>
                }
                {
                    isLoading && (
                        <div
                            style={{
                                zIndex: 999999,
                                position: "absolute",
                                marginTop: "10%",
                                marginLeft: "49%",
                            }}
                        >
                            <ProgressBar />
                        </div >
                    )
                }
                <ConfirmationPopup
                    showButton={{ type: "1" }}
                    open={state.open}
                    button1function={() => handleConfirmYes()}
                    button2function={() => handleConfirmNo()}
                    headerMessage={state.message}
                    button1Text={"Yes"}
            button2Text={"No"}
            handleConfirmClose={() => handleConfirmNo()}
                />
            </div >
        </div >


    )
}

//  LeaveApply.propTypes = {
//      //   second: PropTypes.third
//  }

const mapStateToProps = ({ meetingcategory, lnareducer, auth, meetingmaster }) => {
    const { authUser } = auth;
    const { categoryName } = meetingcategory;
    const { othersCheckAvailablity } = meetingmaster;
    const { leaveTransferFlag, fyId, financialYearData } = lnareducer;
    const { leaveType, leaveStartDate, leaveEndDate, leaveReason, notificationTo, reliever, leaveTypeData, relieverData, leaveApplyStatus, isStreched, isWeekendCounted, leaveApplyLoadName } = lnareducer;
    return {
        categoryName,
        leaveType, leaveStartDate, leaveEndDate, leaveReason, notificationTo, reliever, authUser, leaveTypeData, othersCheckAvailablity, relieverData, leaveApplyStatus,
        isStreched, isWeekendCounted, leaveTransferFlag, leaveApplyLoadName, fyId, financialYearData
    }
}

export default connect(mapStateToProps, { updateLnAData, applyLeave, saveLeaveTransferData })(LeaveTransfer)