/**
 * Author: Vivek Khodade.
 * Last Modify:08-May-2020
 * Modified By:Vivek Khodade
 */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Dialog } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import {
  DropDownListComponent,
  AutoCompleteComponent,
} from "@syncfusion/ej2-react-dropdowns";
import { Query } from "@syncfusion/ej2-data";

import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import { connect } from "react-redux";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { getDisplayDateOnly } from "../../../app/other/commonfunction";
import "../employeeprofiletabs.css";
import OnbordingServices from "../../../services/entryexit/onbordingServices";
import {
  generateAppointmentLatterDropdown,
  noticePeriodData,
  probationPeriod,
  pleaseSelectVariable,
  pleaseSelectPeoplesTeam,
  pleaseSelectProbationPeriod,
  pleaseSelectNoticePeriod,
  pleaseEnterComment,
} from "../../../assets/constants/constants";
import {
  generateAppointmentLetter,
  addUpdateBasicInfo,
} from "../../../redux/actions/entryexit/onboardingaction";
import { urlConfig } from "../../../config/config";
import {
  DialogContent,
  DialogActions,
  DialogTitle,
} from "../../customdialog/customdialog";

class Appointment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openGenerateAppointmentLetterPopup: false,
      generatedAppointmentLetter: [],
      selectedVariable: "",
      comment: "",
      hrUserId: "",
      probationPeriodId: "",
      noticePeriod: "",
      height:
        (window.innerHeight ||
          document.documentElement.clientHeight ||
          document.body.clientHeight) - 188,
      width:
        (window.innerWidth ||
          document.documentElement.clientWidth ||
          document.body.clientWidth) - 71,
    };
  }
   /**
* Calculate & Update state of new dimensions
*/
updateDimensions() {
  let update_width = window.innerWidth - 100;
  let update_height = window.innerHeight - 339;
  this.setState({ width: update_width, height: update_height });
}
  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
    const { empid } = this.props;
    this.getAppointmentLetter(empid);
  }

  // eslint-disable-next-line
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { empid } = this.props;
    const { apppintmentLetterStatus } = nextProps;
    if (apppintmentLetterStatus === "updated") {
      this.getAppointmentLetter(empid);
      // eslint-disable-next-line
      this.props.addUpdateBasicInfo({ apppintmentLetterStatus: "" });
    }
  }

  /**
   * Function Name: getAppointmentLetter
   * Description: Get the appointment letter List
   */
  getAppointmentLetter = (EmployeeId) => {
    const { authUser } = this.props;
    const appointmentLetters = Promise.resolve(
      OnbordingServices.getAppointmentLetters(
        EmployeeId,
        // eslint-disable-next-line
        parseInt(authUser)
      )
    );

    appointmentLetters.then((result) => {
        this.setState({
          generatedAppointmentLetter: result,
          selectedVariable: "",
          comment: "",
        });
      // }
    });
  };

  /**
   * Function Name:onGenerateAppointmentLetter
   * Description: Generate the Appointment Letter.
   */
  onGenerateAppointmentLetter = () => {
    // alert(this.state.selectedVariable+'   '+ this.state.hrUserId)
    const {
      hrUserId,
      selectedVariable,
      comment,
      probationPeriodId,
      noticePeriod,
    } = this.state;
    const { empid, authUser } = this.props;
    const data = {};
    document.getElementById('selectVariableError').innerHTML = ""
    document.getElementById('peoplesTeamError').innerHTML = ""
    document.getElementById('selectProbationPeriodError').innerHTML = ""
    document.getElementById('selectNoticePeriodError').innerHTML = ""
    document.getElementById('commentError').innerHTML = ""
    if (selectedVariable === "") {
      document.getElementById('selectVariableError').innerHTML = pleaseSelectVariable
      return;
    }
    if (hrUserId === "") {
      document.getElementById('peoplesTeamError').innerHTML = pleaseSelectPeoplesTeam
      return;
    }
    if (probationPeriodId === "") {
      document.getElementById('selectProbationPeriodError').innerHTML = pleaseSelectProbationPeriod
      return;
    }
    if (noticePeriod === "") {
      document.getElementById('selectNoticePeriodError').innerHTML = pleaseSelectNoticePeriod
      return;
    }
    if (comment === "") {
      document.getElementById('commentError').innerHTML = pleaseEnterComment
      return;
    }
    data.hrUserId = hrUserId;
    data.variable = selectedVariable.toString();
    data.reason = comment;
    data.empId = empid;
    // eslint-disable-next-line
    data.userId = parseInt(authUser);
    data.probationPeriod = probationPeriodId;
    data.noticePeriod = noticePeriod;
    // eslint-disable-next-line
    this.props.generateAppointmentLetter(data);
    this.closePopupDialog();
  };

  /**
   * Function Name: getSelectedAttendee
   * to get selected attendee.
   */
  getSelectedAttendee(e) {
    this.setState({
      hrUserId: e.itemData.Id,
    });
  }

  /**
   * Function Name: closePopupDialog
   * Close the Popup
   */
  closePopupDialog = () => {
    const { openGenerateAppointmentLetterPopup } = this.state;
    this.setState({
      selectedVariable: "",
      comment: "",
      hrUserId: "",
      probationPeriodId: "",
      noticePeriod: "",
      openGenerateAppointmentLetterPopup: !openGenerateAppointmentLetterPopup,
    });
  };

  /**
   * Function Name: openPopupDialog
   * Description: To open the Popup.
   */
  openPopupDialog = () => {
    const { openGenerateAppointmentLetterPopup } = this.state;
    this.setState({
      openGenerateAppointmentLetterPopup: !openGenerateAppointmentLetterPopup,
    });
  };

  handleFilteringByName = (e) => {
    const { hrMasterList } = this.props;
    const newData = [];
    hrMasterList.forEach((eachD) => {
      newData.push(eachD);
    });
    if (newData != null && newData.length > 0) {
      let query = new Query();
      query =
        e.text !== "" ? query.where("Name", "contains", e.text, true) : query;
      e.updateData(newData, query);
    }
    // }
  };
  

  render() {
    // eslint-disable-next-line
    const { appointmentLetterFlag, hrMemberList } = this.props;
    const {
      generatedAppointmentLetter,
      openGenerateAppointmentLetterPopup,
    } = this.state;
    return (
      <div style={{ display: "block", height:this.state.height }}>
        <div
          className="col-12 appointment-table-header side-margin"
          style={{ textAlign: "center" }}
        >
          <div className="col-12 appointment-table ">
            <Button
              variant="contained"
              style={{
                padding: "2px 2px 2px 2px",
                margin: "3px 5px 0px 0px",
                background: !appointmentLetterFlag ? "#ffffff" : "#4C4C56",
                color: !appointmentLetterFlag ? "#c1baba" : "white",
                border: !appointmentLetterFlag ? ".5px solid #000" : "",
              }}
              onClick={() => this.openPopupDialog()}
              disabled={!appointmentLetterFlag}
            >
              <TooltipComponent
                closeOn="Click"
                content=" Generate Appointment Letter"
                position="BottomCenter"
                isSticky={false}
                cssClass='customtooltip'
              >
                <span style={{ fontSize: 14 }}>
                  Generate Appointment Letter
              </span>
              </TooltipComponent>
            </Button>
          </div>
          {!appointmentLetterFlag && (
            <span style={{ color: "#EB5465" }}>
              *Please fill the salary details first.&nbsp; &nbsp;&nbsp;&nbsp;
            </span>
          )}
          <div className="col-12 table-border-bottom top-side-margin" style={{width:'101%'}}>
            <div className="col-3 appointment-table">Generated By</div>
            <div className="col-3 appointment-table">Created On</div>
            <div className="col-3 appointment-table" style={{textAlign:'left',paddingLeft:0}}>Reason</div>
            <div className="col-3 appointment-table">Download</div>
          </div>
          <div className="col-12 scroll-appointment" style={{height:this.state.height-108}}>
          {/* <div className="col-12" style={{height:this.state.height-120, overflowY:'auto', overflowX:'hidden', paddingBottom:10, width: '101.6%'  }}> */}
            {generatedAppointmentLetter.length > 0 &&
              generatedAppointmentLetter.map((item) => (
                <div
                  className="col-12 table-border-bottom"
                  id={item.int_cand_app_letter}
                  key={item.int_cand_app_letter}
                >
                  <div className="col-3 appointment-table-body">
                    {item.createdBy}
                  </div>
                  <div className="col-3 appointment-table-body">
                    {getDisplayDateOnly(new Date(item.dt_created_on))}
                  </div>
                  <div className="col-3 appointment-table-body" style={{textAlign:'left'}}>
                  <TooltipComponent
                    content={item.text_reason_generate_app_letter.replace(/(?:\r\n|\r|\n)/g, '<br>')}
                    position="BottomLeft"
                    isSticky={item.text_reason_generate_app_letter.length > 50 ? true: false}
                    cssClass='customtooltip'
                    opensOn={item.text_reason_generate_app_letter.length > 50 ? "Click":"Auto"}
                >
                    <div
                        style={{
                            fontSize: "14px",
                            display: "block",
                            width: "300px",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            cursor: "pointer",
                            textAlign:'left'
                        }}
                    >
                        {item.text_reason_generate_app_letter}
                    </div>
                </TooltipComponent>
                  </div>
                  <div className="col-3 appointment-table-body">
                    {/* eslint-disable-next-line */}
                    <a
                      target="_blank"
                      href={
                        item.filePath !== null
                          ? `${urlConfig.tmlcApiUrl + item.filePath}`
                          : ""
                      }
                      rel="noopener noreferrer"
                      download
                    >
                      <FontAwesomeIcon
                        style={{ color: "#EB5465" }}
                        icon={faFilePdf}
                        title="Download PDF file"
                        className="mr-sm pdf-icon"
                      />
                    </a>
                  </div>
                </div>
              ))}
          </div>

          <div style={{ width: "350px" }}>
            <Dialog open={openGenerateAppointmentLetterPopup}>
              <DialogTitle> Generate Appointment Letter </DialogTitle>
              <DialogContent style={{ marginTop: -10 }}>
                <div className="row">
                  <div className="col-xs-2 col-sm-2 col-lg-6 col-md-2">
                    {/* <span className="span">Select Reason</span> */}
                    <div>
                      <DropDownListComponent
                        id=""
                        fields={{ value: "Id", text: "Name" }}
                        floatLabelType="Auto"
                        dataSource={generateAppointmentLatterDropdown}
                        select={(e) =>
                          this.setState({ selectedVariable: e.itemData.Id }, () =>
                            document.getElementById('selectVariableError').innerHTML = "")
                        }
                        cssClass="e-outline"
                        placeholder="Select Variable*"
                      />
                      <div id="selectVariableError" className="error-div" style={{ fontSize: 12 }} />
                    </div>
                  </div>
                  <div className="col-xs-2 col-sm-2 col-lg-6 col-md-2">
                    {/* <span className="span">Select Reason</span> */}
                    <AutoCompleteComponent
                      // className='custom-dropdown'
                      fields={{ text: "Name", value: "Id" }}
                      id="Role"
                      filtering={this.handleFilteringByName}
                      select={(e) =>
                        this.setState({
                          hrUserId: e.itemData.Id,
                        }, () => document.getElementById('peoplesTeamError').innerHTML = "")}
                      placeholder="Signature Authority*"
                      popupHeight="220px"
                      cssClass="e-outline"
                      floatLabelType="Auto"
                      dataSource={hrMemberList}
                    />
                    <div id="peoplesTeamError" className="error-div" style={{ fontSize: 12 }} />
                  </div>
                </div>
                <div className="row">
                  <div
                    style={{ paddingTop: "15px" }}
                    className="col-xs-2 col-sm-2 col-lg-6 col-md-2"
                  >
                    {/* <span className="span">Select Reason</span> */}
                    <DropDownListComponent
                      id="probationperiod"
                      fields={{ value: "Id", text: "Name" }}
                      floatLabelType="Auto"
                      dataSource={probationPeriod}
                      select={(e) =>
                        this.setState({ probationPeriodId: e.itemData.Value }, () =>
                          document.getElementById('selectProbationPeriodError').innerHTML = ""
                        )
                      }
                      cssClass="e-outline"
                      placeholder="Probation Period*"
                    />
                    <div id="selectProbationPeriodError" className="error-div" style={{ fontSize: 12 }} />
                  </div>
                  <div
                    style={{ paddingTop: "15px" }}
                    className="col-xs-2 col-sm-2 col-lg-6 col-md-2"
                  >
                    {/* <span className="span">Select Reason</span> */}
                    <DropDownListComponent
                      id="noticeperiod"
                      fields={{ value: "Id", text: "Name" }}
                      floatLabelType="Auto"
                      dataSource={noticePeriodData}
                      select={(e) =>
                        this.setState({ noticePeriod: e.itemData.Id }, () => document.getElementById('selectNoticePeriodError').innerHTML = "")
                      }
                      cssClass="e-outline"
                      placeholder="Notice Period*"
                    />
                    <div id="selectNoticePeriodError" className="error-div" style={{ fontSize: 12 }} />
                  </div>
                </div>
                <div className="row">
                  <div
                    style={{ paddingTop: "15px" }}
                    className="col-xs-12 col-sm-12 col-lg-12 col-md-2"
                  >
                    {/* <span className="span">Reason for Revoke</span> */}
                    <TextBoxComponent
                      id="ReasonToBeRevoke"
                      placeholder="Comment*"
                      cssClass="e-outline"
                      multiline
                      floatLabelType="Auto"
                      change={(e) => this.setState({ comment: e.value }, () => document.getElementById('commentError').innerHTML = "")}
                    />
                    <div id="commentError" className="error-div" style={{ fontSize: 12 }} />
                  </div>
                </div>
              </DialogContent>
              <DialogActions>
                <ButtonComponent
                  id="cancelRevoke"
                  className="e-control "
                  onClick={this.closePopupDialog}
                >
                  Cancel
                </ButtonComponent>
                <ButtonComponent
                  id="sumitRevoke"
                  style={{ background: "#f94f5e", color: "#fff" }}
                  data-ripple="true"
                  onClick={this.onGenerateAppointmentLetter}
                >
                  Submit
                </ButtonComponent>
              </DialogActions>
            </Dialog>
          </div>
        </div>
      </div>
    );
  }
}
Appointment.propTypes = {
  empid: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  apppintmentLetterStatus: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  authUser: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hrMasterList: PropTypes.arrayOf(PropTypes.object),
  appointmentLetterFlag: PropTypes.bool.isRequired,
};
Appointment.defaultProps = {
  authUser: "",
  empid: 0,
  apppintmentLetterStatus: "",

  hrMasterList: [],
};
const mapStateToProps = ({ auth, onboardingreducer, meetingmaster }) => {
  const { authUser } = auth;
  const {
    basicInformation,
    apppintmentLetterStatus,
    empid,
    appointmentLetterFlag,
  } = onboardingreducer;
  const { hrMasterList } = meetingmaster;
  return {
    authUser,
    basicInformation,
    apppintmentLetterStatus,
    empid,
    appointmentLetterFlag,
    hrMasterList,
  };
};

export default connect(mapStateToProps, {
  generateAppointmentLetter,
  addUpdateBasicInfo,
})(Appointment);
