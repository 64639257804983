import React, { useRef, useState, useEffect } from "react";
import { ComboBoxComponent } from "@syncfusion/ej2-react-dropdowns";
import { FormValidator, TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { connect } from "react-redux";
import okrServices from "../../services/okr/okrservices";
import { saveSubPotentialCalculation, updateOKRData } from "../../redux/actions/okr/okractions";
import { enterQuestionDescriptionForSelf, enterQuestionDescriptionForSupervisor, enterQuestionForSelf, enterQuestionForSupervisor, frequencyDataSource, selectEndMonth, selectFinancialYear, selectFrequency, selectRatingSystem, selectStartMonth, selectSubPotentialName } from "../../assets/constants/constants";
import ProgressBar from "../progressbar/progressbar";
import { cracterLimit } from '../../app/other/commonfunction';

/**
 * Author: Md Jahir Uddin
 * Date: 17-01-2023
 * Description: Add or Edit Sub Potential Calculation
 * @param {{changePopUpHandler: ()=>{}, setSubmitClick:()=>{}, authUser: number, openPopup: string, masterItemId: number, masterId: number, finacialYearData: Array, saveAxisCutOff: ()=>{}, currentFinancialYearId: number}} props 
 */

const SubPotentialCalculationMaster = (props) => {

  const { authUser, openPopup, masterId, masterItemId, setSubmitClick, changePopUpHandler, okrAdminMasterData, ratingSystem, finacialYearData, currentFinancialYearId, saveSubPotentialCalculation, updateOKRData, loading } = props; //NOSONAR

  const [frequency, setFrequency] = useState({})
  const textareaObj1 = useRef(null);
  const textareaObj2 = useRef(null);

  const [state, setState] = useState({
    subPotentialId: "",
    questionForSelf: "",
    questionDescriptionForSelf: "",
    questionForSup: "",
    questionDescriptionForSup: "",
    ratingSystemId: "",
    fyId: currentFinancialYearId,
    frequency: "",
    startMonth: "",
    endMonth: ""
  })

  const onSelfAttrInput = (e) => {
    try {
      if (textareaObj1 !== null && typeof textareaObj1 !== 'undefined') {
        textareaObj1.current.respectiveElement.style.height = "auto";
        textareaObj1.current.respectiveElement.style.minHeight = "100px";
        textareaObj1.current.respectiveElement.style.height = `${textareaObj1.current.respectiveElement.scrollHeight}px`;
        cracterLimit(e, 'selfDescriptionErrInputCount', '1000')
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onSupAttrInput = (e) => {
    try {
      if (textareaObj2 !== null && typeof textareaObj2 !== 'undefined') {
        textareaObj2.current.respectiveElement.style.height = "auto";
        textareaObj2.current.respectiveElement.style.minHeight = "100px";
        textareaObj2.current.respectiveElement.style.height = `${textareaObj2.current.respectiveElement.scrollHeight}px`;
        cracterLimit(e, 'supAttrDescriptionInputCount', '1000')
      }
    } catch (error) {
      console.error(error);
    }
  };


  useEffect(() => {
    getData()
  }, [masterItemId])

  useEffect(() => {
    setTimeout(() => {
      setSubmitClick(onSaveData);
    }, 100);
  }, [state, masterId]);

  useEffect(() => {
    if (props.okrMasterFlag === "updated") {
      changePopUpHandler("close");
    }
  }, [props.okrMasterFlag]);

  const getStartMonths = () => {
    if (Object.keys(frequency) <= 0) {
      return frequency[Number(state.frequency)]?.end
    }
    let index = '';
    if (state.frequency === "" || !state.frequency) {
      return []
    } else {
      if (state.endMonth === "" || !state.endMonth) {
        return frequency[Number(state.frequency)]?.start
      } else {
        index = frequency[Number(state.frequency)]?.end.findIndex(element => element.Id === state.endMonth)
        if (index === -1) return []
        return frequency[Number(state.frequency)]?.start.slice(0, index+1)
      }
    }
  }

  const getEndMonths = () => {
    if (Object.keys(frequency) <= 0) {
      return frequency[Number(state.frequency)]?.end
    }

    let index = '';
    if (state.frequency === "" || !state.frequency) {
      return []
    } else {
      if (state.startMonth === "" || !state.startMonth) {
        return frequency[Number(state.frequency)]?.end
      } else {
        index = frequency[Number(state.frequency)]?.start.findIndex(element => element.Id === state.startMonth)
        if (index === -1) return []
        return frequency[Number(state.frequency)]?.end.slice(index)
      }
    }
  }

  const getData = async () => {
    onSelfAttrInput();
    onSupAttrInput();
    ["subPotentialNameErr",
      "subPotentialSelfAttributesErr",
      "selfDescriptionErr",
      "subPotentialSupAttributesErr",
      "supAttrDescriptionErr",
      "ratingSystemErr",
      "subPotentialCalculationFYErr",
      "subPotentialCalculationFrequencyErr",
      "validityStartErr",
      "validityEndErr"
    ].forEach(err => { document.getElementById(err).innerHTML = "" });
    updateOKRData({loading: true});
    if (openPopup === "add") {
      await getFrequency(currentFinancialYearId);
      setState({
        subPotentialId: "",
        questionForSelf: "",
        questionDescriptionForSelf: "",
        questionForSup: "",
        questionDescriptionForSup: "",
        ratingSystemId: "",
        fyId: currentFinancialYearId,
        frequency: "",
        startMonth: "",
        endMonth: ""
      });
      updateOKRData({loading: false});
    }
    else if (openPopup === "edit") {
      (async () => {
        const subPotentialCalculationData = await okrServices.getOKRMaserData(
          authUser,
          masterId,
          masterItemId
        );
        const {
          kpiLibId: subPotentialId,
          endMonth,
          fyId,
          questionDescriptionForSelf,
          questionDescriptionForSupervisor: questionDescriptionForSup,
          questionForSelf,
          questionForSupervisor: questionForSup,
          questionLibraryId,
          ratingSystemId,
          startMonth,
          frequency //NOSONAR
        } = subPotentialCalculationData.Result[0];
        await getFrequency(fyId)
        setState({
          subPotentialId,
          questionForSelf,
          questionDescriptionForSelf,
          questionForSup,
          questionDescriptionForSup,
          ratingSystemId,
          fyId,
          frequency,
          startMonth,
          endMonth,
          questionLibraryId
        })
      updateOKRData({loading: false});
      })();
    }
  };

  const getFrequency = async (fyId) => {

    const frequencyDataRes = await okrServices.getOKRFrequencyData(authUser, fyId, 0)
    const frequencyData = {}
    frequencyDataRes.data.forEach(item => {
      const { actualEndDate, actualStartDate, endMonthNumber, frequencyType, startDate, startMonthNumber, endDate } = item
      if (frequencyData.hasOwnProperty(frequencyType)) {
        frequencyData[frequencyType]['start'] =
          [...frequencyData[frequencyType]['start'], { Name: startDate, Id: startMonthNumber, actualStartDate }]
        frequencyData[frequencyType]['end'] =
          [...frequencyData[frequencyType]['end'], { Name: endDate, Id: endMonthNumber, actualEndDate }]
      } else {
        frequencyData[frequencyType] = { start: "", end: "" }
        frequencyData[frequencyType]['start'] =
          [{ Name: startDate, Id: startMonthNumber, actualStartDate }]
        frequencyData[frequencyType]['end'] =
          [{ Name: endDate, Id: endMonthNumber, actualEndDate }]
      }
    })
    setFrequency(frequencyData)
  }
  const handleChange = (type, value) => {
    if (type === 'fyId') {
      getFrequency(value)
      setState(prevState => ({ ...prevState, startMonth: "", endMonth: "" }))
    }
    if (type === 'frequency') {
      setState(prevState => ({ ...prevState, startMonth: "", endMonth: "" }))
    }
    setState(prevState => ({ ...prevState, [type]: value }))

    let options = {}

    switch (type) {
      case "subPotentialId":
        options = {
          rules: {
            subPotentialNameErr: {
              required: [true, selectSubPotentialName],
            },
          },
        };
        break;
      case "questionForSelf":
        options = {
          rules: {
            subPotentialSelfAttributesErr: {
              required: [true, enterQuestionForSelf],
              maxLength: [150, "Max 150 character allowed"]
            },
          },
        };
        break;
      case "questionDescriptionForSelf":
        options = {
          rules: {
            selfDescriptionErr: {
              required: [true, enterQuestionDescriptionForSelf],
              maxLength: [1000, "Max 1000 character allowed"]
            },
          },
        };
        break;
      case "questionForSup":
        options = {
          rules: {
            subPotentialSupAttributesErr: {
              required: [true, enterQuestionForSupervisor],
              maxLength: [150, "Max 150 character allowed"]
            },
          },
        };
        break;
      case "questionDescriptionForSup":
        options = {
          rules: {
            supAttrDescriptionErr: {
              required: [true, enterQuestionDescriptionForSupervisor],
              maxLength: [1000, "Max 1000 character allowed"]
            },
          },
        };
        break;
      case "ratingSystemId":
        options = {
          rules: {
            ratingSystemErr: {
              required: [true, selectRatingSystem],
            },
          },
        };
        break;
      case "fyId":
        options = {
          rules: {
            subPotentialCalculationFYErr: {
              required: [true, selectFinancialYear],
            },
          },
        };
        break;
      case "frequency":
        options = {
          rules: {
            subPotentialCalculationFrequencyErr: {
              required: [true, selectFrequency],
            },
          },
        };
        break;
      case "startMonth":
        options = {
          rules: {
            validityStartErr: {
              required: [true, selectStartMonth],
            },
          },
        };
        break;
      case "endMonth":
        options = {
          rules: {
            validityEndErr: {
              required: [true, selectEndMonth],
            },
          },
        };
        break;
      default:
    }

    setTimeout(() => {
      const addFormObject = new FormValidator("#subPotentialCalculationForm", options);
      addFormObject.validate();
    }, 100);
  }

  const onSaveData = async () => {
    let validates = true;
    let options = {
      rules: {
        subPotentialNameErr: {
          required: [true, selectSubPotentialName],
        },
        subPotentialSelfAttributesErr: {
          required: [true, enterQuestionForSelf],
          maxLength: [150, "Max 150 character allowed"]
        },
        selfDescriptionErr: {
          required: [true, enterQuestionDescriptionForSelf],
          maxLength: [1000, "Max 1000 character allowed"]
        },
        subPotentialSupAttributesErr: {
          required: [true, enterQuestionForSupervisor],
          maxLength: [150, "Max 150 character allowed"]
        },
        supAttrDescriptionErr: {
          required: [true, enterQuestionDescriptionForSupervisor],
          maxLength: [1000, "Max 1000 character allowed"]
        },
        ratingSystemErr: {
          required: [true, selectRatingSystem],
        },
        subPotentialCalculationFYErr: {
          required: [true, selectFinancialYear],
        },
        subPotentialCalculationFrequencyErr: {
          required: [true, selectFrequency],
        },
        validityStartErr: {
          required: [true, selectStartMonth],
        },
        validityEndErr: {
          required: [true, selectEndMonth],
        },
      }
    }

    setTimeout(() => {
      const addFormObject = new FormValidator(
        "#subPotentialCalculationForm",
        options
      );
      if (addFormObject.validate() && validates) {
        let data = {
          userId: authUser,
          masterItemId: masterItemId > 0 ? masterItemId : 0,
          questionLibraryId: 0,
          type: masterItemId>0 ? 'EDIT' : 'ADD',
          ...state
        };
        updateOKRData({loading: true});
        saveSubPotentialCalculation(data);
      }
    }, 300);
  };

  return (
    <>

      <div >
        <div style={{ padding: "16px" }} id='subPotentialCalculationForm'>
          <div className="col-xs-12 col-sm-12 col-lg-12 mb-sm atc-no-paddding">
            <TextBoxComponent
              data-msg-containerid="potentialTypeErr"
              id="potentialType"
              value={'Leadership Principle'}
              name="potentialTypeErr"
              placeholder="Potential Type *"
              floatLabelType="Auto"
              cssClass="e-outline"
              readOnly
              // change={(e) => handleChange('subPotentialId', e.value)}
              // htmlAttributes={{ maxlength: 100 }}
            />
            <div
              id="potentialTypeErr"
              className="e-input-group e-float-icon-left error-div"
            />
          </div>
          <div className="col-xs-12 col-sm-12 col-lg-12 mb-sm atc-no-paddding" >
            <ComboBoxComponent
              // <TextBoxComponent
              id="subPotentialName"
              data-msg-containerid="subPotentialNameErr"
              name="subPotentialNameErr"
              dataSource={okrAdminMasterData[1]}
              fields={{ text: "Name", value: "Id" }}
              value={state.subPotentialId}
              placeholder="Sub Potential Name *"
              floatLabelType="Auto"
              cssClass="e-outline"
              change={(e) => handleChange('subPotentialId', e.value)}
              // htmlAttributes={{ maxlength: 100 }}
            />
            <div
              id="subPotentialNameErr"
              className="e-input-group e-float-icon-left error-div"
            />
          </div>
          <div className="col-xs-12 col-sm-12 col-lg-12 mb-sm atc-no-paddding">
            <TextBoxComponent
              data-msg-containerid="subPotentialSelfAttributesErr"
              id="subPotentialSelfAttributes"
              value={state.questionForSelf}
              name="subPotentialSelfAttributesErr"
              placeholder="Self Attributes *"
              floatLabelType="Auto"
              cssClass="e-outline"
              onChange={(e) => handleChange("questionForSelf", e.value)}
              // htmlAttributes={{ maxlength: 100 }}
            />
            <div
              id="subPotentialSelfAttributesErr"
              className="e-input-group e-float-icon-left error-div"
            />
          </div>
          <div className="col-xs-12 col-sm-12 col-lg-12 weekdayDescription mb-sm atc-no-paddding">
            {/* <style>{descriptionTextBox}</style> */}
            <TextBoxComponent
              data-msg-containerid="selfDescriptionErr"
              id="selfDescription"
              style={{ minHeight: 100 }}
              value={state.questionDescriptionForSelf}
              multiline={true}
              name="selfDescriptionErr"
              placeholder="Self Attributes Description *"
              floatLabelType="Auto"
              cssClass="e-outline"
              onChange={(e) => handleChange("questionDescriptionForSelf", e.value)}
              // htmlAttributes={{ maxlength: 1000 }}
              input={onSelfAttrInput}
              ref={textareaObj1}
            />
            <div id="selfDescriptionErrInputCount" style={{ float: 'right', fontSize: 12, margin: '0px 15px 8px 0px' }} className="error-div" />
            <div
              id="selfDescriptionErr"
              className="e-input-group e-float-icon-left error-div"
            />
          </div>
          <div className="col-xs-12 col-sm-12 col-lg-12 mb-sm atc-no-paddding">
            <TextBoxComponent
              data-msg-containerid="subPotentialSupAttributesErr"
              id="subPotentialSupAttributes"
              value={state.questionForSup}
              name="subPotentialSupAttributesErr"
              placeholder="Supervisor Attributes *"
              floatLabelType="Auto"
              cssClass="e-outline"
              onChange={(e) => handleChange("questionForSup", e.value)}
              // htmlAttributes={{ maxlength: 100 }}
            />
            <div
              id="subPotentialSupAttributesErr"
              className="e-input-group e-float-icon-left error-div"
            />
          </div>
          <div className="col-xs-12 col-sm-12 col-lg-12 weekdayDescription mb-sm atc-no-paddding">
            {/* <style>{descriptionTextBox}</style> */}
            <TextBoxComponent
              data-msg-containerid="supAttrDescriptionErr"
              id="supAttrDescription"
              value={state.questionDescriptionForSup}
              multiline={true}
              name="supAttrDescriptionErr"
              placeholder="Supervisor Attributes Description *"
              floatLabelType="Auto"
              cssClass="e-outline"
              onChange={(e) => handleChange("questionDescriptionForSup", e.value)}
              // htmlAttributes={{ maxlength: 1000 }}
              style={{ minHeight: 100 }}
              input={onSupAttrInput}
              ref={textareaObj2}
            />
            <div id="supAttrDescriptionInputCount" style={{ float: 'right', fontSize: 12, margin: '0px 15px 8px 0px' }} className="error-div" />
            <div
              id="supAttrDescriptionErr"
              className="e-input-group e-float-icon-left error-div"
            />
          </div>
          <div className="col-xs-12 col-sm-12 col-lg-12  mb-sm atc-no-paddding">
            <ComboBoxComponent
              // <TextBoxComponent
              id="subPotentialCalculationFY"
              data-msg-containerid="subPotentialCalculationFYErr"
              name="subPotentialCalculationFYErr"
              dataSource={finacialYearData}
              fields={{ text: "Name", value: "Id" }}
              value={state.fyId}
              placeholder="Financial Year *"
              floatLabelType="Auto"
              cssClass="e-outline"
              onChange={(e) => handleChange("fyId", e.value)}
              // htmlAttributes={{ maxlength: 100 }}
            />
            <div
              id="subPotentialCalculationFYErr"
              className="e-input-group e-float-icon-left error-div"
            />
          </div>
          <div className="col-xs-12 col-sm-12 col-lg-12 mb-sm atc-no-paddding">
            <ComboBoxComponent
              // <TextBoxComponent
              id="subPotentialCalculationFrequency"
              data-msg-containerid="subPotentialCalculationFrequencyErr"
              name="subPotentialCalculationFrequencyErr"
              dataSource={frequencyDataSource}
              fields={{ text: "Name", value: "Id" }}
              value={state.frequency}
              placeholder="Frequency *"
              floatLabelType="Auto"
              cssClass="e-outline"
              onChange={(e) => handleChange("frequency", e.value)}
              // htmlAttributes={{ maxlength: 100 }}
            />
            <div
              id="subPotentialCalculationFrequencyErr"
              className="e-input-group e-float-icon-left error-div"
            />
          </div>
          <div
            className="col-xs-12 col-sm-12 col-lg-12 col-md-12 pb-md  "
            style={{ display: 'flex', 'justifyContent': 'space-between' }}
          >
            <div
              className="col-xs-6 col-sm-6 col-lg-6 col-md-6"
              style={{ width: '48%', padding: 0 }}
            >
              <ComboBoxComponent
                // <TextBoxComponent
                id="validityStart"
                data-msg-containerid="validityStartErr"
                name="validityStartErr"
                dataSource={getStartMonths()}
                fields={{ text: "Name", value: "Id" }}
                value={state.startMonth}
                placeholder="Validity Start *"
                floatLabelType="Auto"
                cssClass="e-outline"
                onChange={(e) => { handleChange("startMonth", e.value) }}
                // htmlAttributes={{ maxlength: 100 }}
              />
              <div
                id="validityStartErr"
                className="e-input-group e-float-icon-left error-div"
              />
            </div>
            <div
              className="col-xs-6 col-sm-6 col-lg-6 col-md-6"
              style={{ width: '48%', padding: 0 }}
            >
              <ComboBoxComponent
                // <TextBoxComponent
                id="validityEnd"
                data-msg-containerid="validityEndErr"
                name="validityEndErr"
                dataSource={getEndMonths()}
                fields={{ text: "Name", value: "Id" }}
                value={state.endMonth}
                placeholder="Validity End *"
                floatLabelType="Auto"
                cssClass="e-outline"
                onChange={(e) => handleChange("endMonth", e.value)}
                // htmlAttributes={{ maxlength: 100 }}
              />
              <div
                id="validityEndErr"
                className="e-input-group e-float-icon-left error-div"
              />
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-lg-12 mb-sm atc-no-paddding">
            <ComboBoxComponent
              // <TextBoxComponent
              id="ratingSystem"
              data-msg-containerid="ratingSystemErr"
              name="ratingSystemErr"
              dataSource={ratingSystem}
              fields={{ text: "Name", value: "Id" }}
              value={state.ratingSystemId}
              placeholder="Rating Scale *"
              floatLabelType="Auto"
              cssClass="e-outline"
              change={(e) => handleChange("ratingSystemId", e.value)}
              // htmlAttributes={{ maxlength: 100 }}
            />
            <div
              id="ratingSystemErr"
              className="e-input-group e-float-icon-left error-div"
            />
          </div>
        </div>
      </div>
      {loading && <ProgressBar />}
    </>
  );
};

const mapStateToProps = ({ auth, okrreducer, meetingmaster }) => {
  const { authUser } = auth;
  const { okrMasterFlag, okrAdminMasterData, ratingSystem, loading } = okrreducer;
  const { finacialYearData, currentFinancialYearId } = meetingmaster

  return {
    authUser,
    okrreducer,
    okrMasterFlag,
    okrAdminMasterData,
    ratingSystem,
    finacialYearData,
    currentFinancialYearId,
    loading
  };
};

export default connect(mapStateToProps, {
  saveSubPotentialCalculation,
  updateOKRData
})(SubPotentialCalculationMaster);

