import React from 'react';
import "./approvalgrid.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMessage, faCircleCheck, faCircleXmark } from '@fortawesome/pro-solid-svg-icons';
import { TooltipComponent } from "@syncfusion/ej2-react-popups";

const ApprovalGrid = (props) => {

  const { handleApprove, handleReject, authUser, approvalData } = props;


  const getColor = (type, status, isAutoApproved) => {
    if (type === 'bgColor') {
      if (status === "Applied" || status === "Approved") return "#D2EFE8"
      if (status === "Rejected" || status === "Cancelled") return "#FEDCDF"
      return "#FDD14133"
    }
    if (type === 'borderColor') {
      if (status === "Applied" || status === "Approved" || isAutoApproved) return "1px solid #0CB963"
      if (status === "Rejected" || status === "Cancelled") return "2px solid #F94F5E"
      if (status === "Not reached") return "1px solid #DBDBDD"
      return "2px solid #FDD141"
    }
  }

  return (

    <>
      {approvalData?.length > 0 && <div className='approvalGrid'>
        {approvalData.map((data, index) => {
          return <div key={index} className='gridBoxContainer'>
            <div className='gridBox' style={{ border: `${getColor('borderColor', data.status, props.isAutoApproved)}` }}>
              <div className='gridBoxTop'>
                <p className='purpose'>{data.purpose}</p>
                <TooltipComponent
                  content={data.userName}
                  position="BottomCenter"
                  className="pointer-cursor"
                  isSticky={false}
                  cssClass="customtooltip"
                >
                  <p className='userName'>{data.userName}</p>
                </TooltipComponent>

                {data.status !== "Not reached" && <p className='date lastActioned' style={{ display: 'flex' }}>
                  <span style={{ width: 'fit-content' }}>{data.lastActioned ? 'Last Actioned: ' : 'Pending Since: '}</span>
                  <div className='lastActioned'>
                    <TooltipComponent
                      content={data.lastActioned ? data.lastActioned : data.leaveAppliedDate}
                      position="BottomCenter"
                      className="pointer-cursor"
                      isSticky={false}
                      cssClass="customtooltip"
                    >
                      <p className='lastActioned' style={{ color: 'black' }}>{data.lastActioned ? data.lastActioned : data.leaveAppliedDate} {data.lastActioned ? data.lastActioned : data.leaveAppliedDate} {data.lastActioned ? data.lastActioned : data.leaveAppliedDate}</p>
                    </TooltipComponent>
                  </div>
                </p>}
              </div>
              <hr className='gridBoxPartition' />
              <div className='gridBoxBottom'>
                <div className='commentIconContainer'>
                  {data.comments.length > 0 && <TooltipComponent
                    width={'auto'}
                    position="BottomCenter"
                    className="pointer-cursor"
                    isSticky={false}
                    content={data.comments[0]}
                    cssClass="customtooltip"
                  >
                    <FontAwesomeIcon className='fa-lg commentIcon' icon={faMessage} />
                    <span className='redBadge'>{data.comments.length}</span>
                  </TooltipComponent>}
                </div>
                {props.isAutoApproved && <div className='statusNameContainer' style={{ backgroundColor: "#D2EFE8" }}>
                  <p className='statusName' style={{ color: '#0CB963' }}>Auto Approved</p>
                </div>}
                {data.status && !props.isAutoApproved && ["Applied", "Rejected", "Approved", "Cancelled"].includes(data.status) && <div className='statusNameContainer' style={{ backgroundColor: `${getColor('bgColor', data.status)}` }}>
                  {data.status === "Applied" && <p className='statusName' style={{ color: '#0CB963' }}>Applied</p>}
                  {data.status === "Approved" && <p className='statusName' style={{ color: '#0CB963' }}>Approved</p>}
                  {data.status === "Rejected" && <p className='statusName' style={{ color: '#F94F5E' }}>Rejected</p>}
                  {data.status === "Cancelled" && <p className='statusName' style={{ color: '#F94F5E' }}>Cancelled</p>}
                </div>}
                {data.status && data.status === "Pending" && data.userId === authUser && !props.isHideButtons && <div className='actionButtonsContainer'>
                  <button className='actionButtons' onClick={handleApprove}>
                    <FontAwesomeIcon className='fa-lg' icon={faCircleCheck} style={{ color: "#0CB963" }} />
                  </button>
                  <button className='actionButtons' onClick={handleReject}>
                    <FontAwesomeIcon className='fa-lg' icon={faCircleXmark} style={{ color: "#FF3434" }} />
                  </button>
                </div>}
              </div>
            </div>
            {index < (approvalData?.length - 1) && <span className='arrow'>&#x2B62;</span>}
          </div>
        })}
      </div>}
    </>
  )
}

export default ApprovalGrid
