import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { DialogContent, DialogActions, } from "../customdialog/customdialog";
import { Button } from "@material-ui/core";
import ErrorBoundary from "../error/errorboundary";
import KpiUploadFile from "../kpi/okrkpiupload";
import {
    addUpdateObjectveKpi,
    // setOKRUserKPI, // setUserOKRObjectiveKPI, // setCentralOkrKPIRedux,
  } from "../../redux/actions/okr/okractions";

class AddAttachmentPopup extends React.Component {
    constructor(props) {
        super(props);
        this.state = { weightageVal: 0, documentPath: "", fileNames: props.fileList }
    }
    uploadKpiDoc = () => {
        const { objectType, centralOkrData, leaderShipOkrData, brandValueOkrData, currentKpiIndex } = this.props; 
        const { fileNames } = this.state;
        document.getElementById("uploadErr").innerHTML = "";
        if (typeof fileNames === "undefined" || fileNames === null || fileNames.length < 1) {
            document.getElementById("uploadErr").innerHTML = "Please upload required document";
        } else {
            const centralOkr = centralOkrData;
            const leaderShipOkr = leaderShipOkrData;
            const brandValueokr = brandValueOkrData;
            if (objectType === "2") {
                centralOkr[currentKpiIndex].kpiAttachment = fileNames.toString();
                // eslint-disable-next-line
                this.props.addUpdateObjectveKpi({ centralOkrData: centralOkr });
            }
            if (objectType === "3") {
                leaderShipOkr[currentKpiIndex].kpiAttachment = fileNames.toString();
                // eslint-disable-next-line
                this.props.addUpdateObjectveKpi({ leaderShipOkrData: leaderShipOkr });
            }
            if (objectType === "4") {
                brandValueokr[currentKpiIndex].kpiAttachment = fileNames.toString();
                // eslint-disable-next-line
                this.props.addUpdateObjectveKpi({ brandValueOkrData: brandValueokr });
            }
            this.props.closeAttachmentDialog();
        }
    };
    documentDetail = (fileNames) => {
        this.setState({ fileNames, });
    };
    selectedFiles = (fileNms) => {
        const { changeData } = this.state;
        this.setState({ fileNames: fileNms, changeData: !changeData });
    }
    render() {
        const { closeAttachmentDialog } = this.props;
        const { documentPath,fileNames } = this.state;
        return (
            <>
                <ErrorBoundary>
                    <DialogContent dividers>
                    <div>
                        <div className="row card-layout">
                            <div className="Upload">
                                <div className="Content">
                                    <div style={{ textAlign: "-webkit-center" }}>
                                        <KpiUploadFile 
                                        documentPath={documentPath} 
                                        documentDetail={this.documentDetail} 
                                        type="kpi" 
                                        allowmultiple 
                                        selectedFiles={this.selectedFiles} 
                                        fileList={fileNames} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div id="uploadErr" className="e-input-group e-float-icon-left error-div" />
                    </div>
                    </DialogContent>
                    <DialogActions>
                    <Button style={{ width: "120px" }} className="cancel-button" variant="contained" onClick={closeAttachmentDialog} >{" "}Cancel{" "}</Button>
                    <Button style={{ width: "120px", margin:"0px 6px 0px 0px"}} className="remove-button-shadow" color="primary" variant="contained" onClick={this.uploadKpiDoc} >{" "}Save{" "}</Button>
                    </DialogActions>
                </ErrorBoundary>
            </>
        );
    }
}

AddAttachmentPopup.propTypes = {
    authUser: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
AddAttachmentPopup.defaultProps = {
    authUser: "",
};
const mapStateToProps = ({ auth, okrreducer }) => {
    const { authUser } = auth;
    const { allOkrKpi, employeeId, reRenderComponent, centralOkrData, leaderShipOkrData, brandValueOkrData, } = okrreducer;
    return { authUser, allOkrKpi, employeeId, reRenderComponent, centralOkrData, leaderShipOkrData, brandValueOkrData, }
}
export default connect(mapStateToProps, { addUpdateObjectveKpi })(AddAttachmentPopup);