import React from 'react';

export default function ClosedTabObjHeader(props) {
    const {objectiveData}=props;
  return <div className=''>
  <div className='col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12' style={{height:40, borderLeft:'4px solid #36C17C', backgroundColor:'#F2F2F2'}}>
        <div className='col-8 col-lg-8 col-md-8 col-sm-12 col-xs-12 pt-sm'>
          <span className='bold-header-obj-kpi'>{objectiveData.objectiveName}</span>
        </div>
        <div className='col-4 col-lg-4 col-md-4 col-sm-12 col-xs-12 pt-sm' style={{textAlign:'right'}}>
          <span className='bold-header-obj-kpi' >{objectiveData.frequencyNm}</span>
        </div>
  </div>
</div>;
}
