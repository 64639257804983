import React, { Component } from "react";
import { connect } from "react-redux";

import AdminServices from "../../../services/adminservices";
import OrgchartPopUp from "./orgchartpopup";
import "./orgchartadmin.css";
import {
  updateAdminReduxData,
  addEditPracticeArea,
} from "../../../redux/actions/admin/adminaction";
import SubActionTemplate from "./subBusinessAction";
import { Button } from "@material-ui/core";
import { faCaretDown, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  GridComponent, ColumnsDirective, ColumnDirective, Inject, Filter, Aggregate,
  Group, AggregateColumnsDirective, AggregateColumnDirective, AggregateDirective, AggregatesDirective
} from '@syncfusion/ej2-react-grids';
import {
  getGridColumn,

  HIERARCHY,

  EMPLOYEE_COUNT,
  ACTION,
} from "../../../app/other/gridcolumns";
import Progressbar from "../../../components/progressbar/progressbar";
import ErrorMessage from "../../errormessage/errormessage";
import stylingNewPracticeArea from "./orgChartStylingGridComponents";
import { setSpinner } from '@syncfusion/ej2-react-popups';
import { spinner } from "../../../app/other/commonfunction";

class Practicearea extends Component {
  constructor() {
    super();
    this.state = {
      height:
        (window.innerHeight ||
          document.documentElement.clientHeight ||
          document.body.clientHeight) - 188,
      heightPending:
        (window.innerHeight ||
          document.documentElement.clientHeight ||
          document.body.clientHeight) - 188,
      gridHeight: (window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight) - 290,
      result: [],
      openPopup: false,
      maxHierarchy: 0,
      practiceAreaMasterData: [],
      isLoading: true,
      expandStage: 0
    };
    this.title = "";
    this.type = "";
    this.header = "Practice Area / Sales Unit / Sub Function";

    this.filterSettings = { type: 'CheckBox' };
    this.groupOptions = { showGroupedColumn: true, columns: ['businessUnitFunction', "subBusinessUnitFunction"], showDropArea: false };
    this.actionTemplate = this.actionTemplate.bind(this);

    this.check = { type: 'CheckBox' };
    this.width =
      (window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth) - 100;
    this.dataBound = this.dataBound.bind(this);
    this.onDataBinding = this.onDataBinding.bind(this)
    this.initialLoad = true;
  }
  updateDimensions() {
    const updateHeight = window.innerHeight - 230;
    const updateHeightp = window.innerHeight - 185;
    this.setState({ height: updateHeight, heightPending: updateHeightp });
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
    this.getBusinessFunctionData();
    this.getAdminMasterData();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.practiceAreaFlag === "updated") {
      this.getBusinessFunctionData();
      this.closePopup();
      this.props.updateAdminReduxData({ practiceAreaFlag: "" });
    }
  }

  getAdminMasterData() {
    try {
      const adminMasterData = Promise.resolve(
        AdminServices.getAdminMasterData("PRACTICEAREA")
      );
      adminMasterData.then((result) => {
        if (result !== null) {
          this.setState({ practiceAreaMasterData: result, isLoading: false });
        }
      });
    } catch (error) {
      // eslint-disable-next-line
      console.log(error);
    }
  }

  getBusinessFunctionData = () => {
    try {
      const businessFunction = Promise.resolve(
        AdminServices.getAdminOrgChartGridData("practiceArea")
      );
      businessFunction.then((result) => {
        if (result !== null) {
          const maxHierarchy = result.result.reduce(
            (acc, item) => (acc > item.hierarchy ? acc : item.hierarchy),
            0
          );

          this.setState({
            result: result.result,
            maxHierarchy: parseInt(maxHierarchy, 10) + 1,
            expandStage: 0
          });
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  openPopup = (type, practiceAreaData) => {
    this.setState({ openPopup: true });
    this.title = this.header;
    this.type = type;
    this.practiceAreaData = practiceAreaData;
  };

  makeActive = (type, practiceAreaData, activeFlag) => {
    const { authUser } = this.props;
    let data = {
      authUser: authUser,
      type: type,
      practiceAreaId: practiceAreaData.practiceAreaId,
      isActive: activeFlag,
    };
    this.props.addEditPracticeArea(data);
  };

  closePopup = () => {
    this.setState({ openPopup: false });
  };
  onChange(sel) {
    this.gridInstance.filterSettings.type = sel.itemData.value;
    this.gridInstance.clearFiltering();
  }

  actionTemplate = (props) => {


    return (
      <SubActionTemplate
        props={props}
        category="PRACTICE_AREA"
        authUser={this.props.authUser}
        makeActiveInActive={this.makeActive}
        openPopup={this.openPopup}
      />
    )
  }

  level1ActionRole = (props) => {


    return (
      <div style={{ textAlign: "center" }}>{props.hierarchy}
      </div>
    )
  }

  level2ActionRole = (props) => {


    return (
      <div style={{ textAlign: "center" }}>{props.empCnt}
      </div>
    )
  }

  setHeaderCss = () => {
    setTimeout(() => {
      const ele = document.getElementsByClassName("e-headercelldiv");
      for (let i in ele) {
        if (!isNaN(parseInt(i, 10))) {
          let index = parseInt(i, 10);

          if (ele[index].innerText === ACTION) {
            ele[index].classList.add("grid-center-g-header");
          } else {
            ele[index].classList.add("grid-left-g-header");
          }
        }
      }
    }, 500);
  }

  groupcFootertMax(props) {


    return (<p style={{ fontWeight: "bold", textAlign: "center", marginLeft: 14 }}>{props.Sum}</p>);
    // }



  }
  dataBound() {
    spinner(false)
    if (this.gridInstance.groupSettings.columns.length > 0) {

      const data = document.querySelectorAll("[ej-mappingname=subBusinessUnitFunction]")
      let i = 1;
      while (i < data.length) {
        this.gridInstance.groupModule.expandCollapseRows(data[i])
        i++
      }
    }
    this.gridInstance.groupModule.collapseAll();
  }


  expandFunction = () => {
    const { expandStage } = this.state;
    if (expandStage === 0) {
      const data = document.querySelectorAll("[ej-mappingname=businessUnitFunction]")
      let i = 1;
      while (i < data.length) {
        this.gridInstance.groupModule.expandCollapseRows(data[i])
        i++
      }
      this.setState(preState => ({ expandStage: preState.expandStage + 1 }))
    } else if (expandStage === 1) {
      const data = document.querySelectorAll("[ej-mappingname=subBusinessUnitFunction]")
      let i = 1;
      while (i < data.length) {
        this.gridInstance.groupModule.expandCollapseRows(data[i])
        i++
      }
      this.setState(preState => ({ expandStage: preState.expandStage + 1 }))
    }
  }


  collapseFunction = () => {
    let { expandStage } = this.state;
    expandStage = expandStage - 1;
    if (expandStage === 0) {
      const data = document.querySelectorAll("[ej-mappingname=businessUnitFunction]")
      let i = 1;
      while (i < data.length) {
        this.gridInstance.groupModule.expandCollapseRows(data[i])
        i++
      }
      this.setState(preState => ({ expandStage: preState.expandStage - 1 }))
    } else if (expandStage === 1) {
      const data = document.querySelectorAll("[ej-mappingname=subBusinessUnitFunction]")
      let i = 1;
      while (i < data.length) {
        this.gridInstance.groupModule.expandCollapseRows(data[i])
        i++
      }
      this.setState(preState => ({ expandStage: preState.expandStage - 1 }))
    }
  }


  /**
  * Created on 09-09-2022 || Noel Fredrick
  * Description - For spinner
  */
  onDataBinding() {
    spinner(true)
  }


  onFirstGridCreated() {
    if (this.initialLoad) {
      this.initialLoad = false;
      setSpinner({
        template: document.getElementById('spinnerContainer').innerHTML,
      });
      document.getElementById('spinnerContainer').remove();
      // this.gridInstance.freezeRefresh(); // re-render the grid component
    }

  }


  render() {
    const { height, result, maxHierarchy, gridHeight, isLoading } = this.state;
    this.setHeaderCss();
    var widthColumnConfirm = 0;
    var finalWidthConfirm;
    // eslint-disable-next-lineggr
    getGridColumn("orgChartMasterAdmin").forEach((data) => {
      widthColumnConfirm = widthColumnConfirm + 10 + data.width;
    });
    if (window.innerWidth === 1024) {
      finalWidthConfirm = 1350
    } else if (window.innerWidth === 1920) {
      finalWidthConfirm = Math.min(widthColumnConfirm + 450, this.width);
    } else {
      finalWidthConfirm = this.width + 350;
    }
    let heightAdjustment = 0;
    const windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    const windowWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    switch (true) {
      case windowHeight > 767:
        heightAdjustment = 75;
        break;
      case windowHeight === 731 || (windowHeight === 551 && windowWidth === 1152):
        heightAdjustment = 125;
        break;
      case windowHeight === 599 && window.innerWidth === 1024:
        heightAdjustment = 120;
        break;
      case windowHeight === 599 || windowHeight === 551:
        heightAdjustment = 85;
        break;
      default:
        heightAdjustment = 100;
    }
    const gridHeightNew = gridHeight - heightAdjustment;


    return (
      <div
        style={{
          height: height,
          // height: height + 58,
          color: "black",
          marginBottom: "16px",
          padding: "8px 4px 8px 4px",
          marginLeft: 14,
        }}
        className="practicefunc-height" id="practicefuncid"
      >
        {result.length > 0 ? <>
          <div style={{ marginBottom: 8 }} className="row">
            <div className="col-xs-5 col-sm-5 col-lg-7 col-md-7 add-header" style={{paddingLeft:0}}>{this.header} </div>
            <div
              className="col-xs-5 col-sm-5 col-lg-5 col-md-5"
              style={{ display: 'flex', justifyContent: 'flex-end' }}
            >
              <Button
                style={{
                  marginRight: "12px"
                }}
                className="remove-button-shadow addbtn"
                variant="contained"
                onClick={this.collapseFunction}

              >
                <FontAwesomeIcon icon={faCaretRight} className="mr-sm" />
                Collapse
              </Button>
              <Button

                className="remove-button-shadow addbtn"
                color="primary"
                variant="contained"
                onClick={this.expandFunction}
              >
                <FontAwesomeIcon icon={faCaretDown} className="mr-sm" />
                Expand
              </Button>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-10 col-sm-10 col-lg-12 col-md-12">
              <div style={{ paddingTop: "5px", overflow: "auto"}}
                className="control-section gridWidth"
              ><style>{stylingNewPracticeArea}</style>
                <GridComponent
                  dataSource={result}
                  allowGrouping={true}
                  gridLines='Both'
                  rowHeight={40}
                  allowFiltering
                  filterSettings={this.filterSettings}
                  groupSettings={this.groupOptions}
                  allowSorting={false}
                  height={gridHeightNew}
                  // width={finalWidthConfirm}
                  dataBound={this.dataBound.bind(this)}
                  ref={(grid) => (this.gridInstance = grid)}
                  onDataBinding={this.onDataBinding("practicefuncid")}
                  created={this.onFirstGridCreated.bind(this)}
                  // style={{borderLeft:'1px solid #000', borderRight:'1px solid #000'}}
                >
                  <ColumnsDirective>
                    {getGridColumn("orgChartMasterAdminPracticeArea").map((item) => {
                      switch (item.colName) {
                        case HIERARCHY:
                          return (
                            <ColumnDirective
                              key={item.field}
                              template={this.level1ActionRole}
                              textAlign={item.align}
                              field={item.field}
                              headerText={item.colName}
                              width={item.width}
                              filter={this.check}
                              type={"number"}
                            >
                            </ColumnDirective>
                          );
                        case EMPLOYEE_COUNT:
                          return (
                            <ColumnDirective
                              key={item.field}
                              template={this.level2ActionRole}
                              textAlign={item.align}
                              field={item.field}
                              headerText={item.colName}
                              width={item.width}
                              type={"number"}
                              filter={this.check}
                            >
                            </ColumnDirective>
                          );
                        case ACTION:
                          return (
                            <ColumnDirective
                              key={item.field}
                              template={this.actionTemplate}
                              textAlign={item.align}
                              field={item.field}
                              headerText={item.colName}
                              width={item.width-10}
                              filter={false}
                            >
                            </ColumnDirective>
                          );

                        default:
                          return (
                            <ColumnDirective
                              key={item.field}
                              // template={this.employeeNameTemplate}
                              textAlign={item.align}
                              field={item.field}
                              headerText={item.colName}
                              width={item.width}
                              filter={this.check}
                            >
                            </ColumnDirective>
                          );

                      }
                    })}

                  </ColumnsDirective>
                  <AggregatesDirective>
                    <AggregateDirective>
                      <AggregateColumnsDirective>
                        <AggregateColumnDirective field='empCnt' type='Sum' groupCaptionTemplate={this.groupcFootertMax}> </AggregateColumnDirective>
                      </AggregateColumnsDirective>
                    </AggregateDirective>

                  </AggregatesDirective>
                  <Inject services={[Filter, Group, Aggregate]} />
                </GridComponent>

                <div className="loader-new" id="spinnerContainer" >
                  <Progressbar />
                </div>

              </div>
            </div>
          </div>
        </> :
          <div className="control-section col-md-12" style={{ height: 75 }}>
            {isLoading && (<div className="loader-new">
              {/* <Progressbar /> */}
            </div>)
            }
            {(result.length === 0 && isLoading === false) && (
              <ErrorMessage message="No Data Available" />
            )}
          </div>
        }

        {this.state.openPopup && (
          <OrgchartPopUp
            closePopup={this.closePopup}
            component="PracticeArea"
            title={this.title}
            type={this.type}
            practiceAreaData={this.practiceAreaData}
            maxHierarchy={maxHierarchy}
            practiceAreaMasterData={this.state.practiceAreaMasterData}
            result={this.state.result}
          />
        )}
      </div>
    );
  }
}
const mapStateToProps = ({ adminreducer, auth }) => {
  const { practiceAreaFlag } = adminreducer;
  const { authUser } = auth;

  return { practiceAreaFlag, authUser };
};

export default connect(mapStateToProps, {
  updateAdminReduxData,
  addEditPracticeArea,
})(Practicearea);
