/**
 * Author Name :  Sanket Bari
 * Created Date: 05 March 2024
 * Modified Date: - .
 * Last Modified Name: - .
 */
// Last Modified: -

import React from 'react';
import error from '../../../assets/svg/error.svg';

const StatusButton = ({activityCompletionStatus, minHeight, activityStatus, minWidth, bgColor, fontSize, fontColor, borderStyle, borderRadius, borderWidth, borderColor, boxShadow, buttonContent }) => {
    const buttonstyle = {
        minHeight: minHeight,
        minWidth: minWidth,
        backgroundColor: bgColor,
        fontSize: fontSize,
        color: fontColor,
        borderStyle: borderStyle,
        borderWidth: borderWidth,
        borderColor: borderColor,
        borderRadius: borderRadius,
        boxShadow: boxShadow,

    }

    const textStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    }
    return (
        <>
            <button style={buttonstyle}>
                <div style={textStyle}>
                    {activityCompletionStatus === '3' ? (
                        <>
                            <span title={activityStatus}><img style={{ paddingBottom: '3px' }} src={error} alt="error icon" /></span></>
                    ) : <></>}

                    <span style={{ paddingLeft: '5px' }}>{buttonContent}</span>
                </div>
            </button>
        </>
    );

}

export default StatusButton;