/**
 * Author: Mukesh.
 * Created:11-Jan-2023
 * Last Modify:11-Jan-2023
 * Modified By:Mukesh
 */


import * as React from 'react';
import { faPlus, faMinus, faSearchPlus, faExpand, faEye, faHighlighter } from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SwapVert from "@material-ui/icons/SwapVert";
import SwapHoriz from "@material-ui/icons/SwapHoriz";
import Tooltip from "@material-ui/core/Tooltip";
import { useState } from 'react';
import { ReactComponent as ExpandIcon } from './../../assets/svg/expand-all.svg';
import { ReactComponent as CollapseIcon } from './../../assets/svg/collapse-all.svg';
import { ReactComponent as PlotingIcon } from './../../assets/svg/circle-location-arrow.svg';

export default function ActionButton(props) {
  const [nodeLeaf, setNodeLeaf] = React.useState(false);
  const [nodePlotFlag, setNodePlotFlag] = React.useState(false);
  const [hover, setHover] = useState(false);
  const [hoverPloting, setHoverPloting] = useState(false);

  React.useEffect(() => {
    if (props.plotingObj) {
      setTimeout(() => {
        centerOnRoot()
      }, 200)
    }
  }, [props.plotingObj])

  var zoomInc = 1
  const showHeigLight = () => {
    props.onSelectHigh(true)
    props.onSelectPlot(false)
    props.onSelectProfile(false, props.pData)
  }
  const showPloting = () => {
    try {
      setNodePlotFlag(true)
      props.onSelectHigh(false)
      props.onSelectPlot(true)
      props.onSelectProfile(false, props.pData)
    } catch (err) {
    }
  }
  const onHover = () => {
    setHover(!hover)
  }
  const onHoverPloting = () => {
    setHoverPloting(!hoverPloting)

  }
  const showPlotingHide = () => {
    try {
      setNodePlotFlag(false)
      props.onSelectPlot(false)
    } catch (err) {
    }
  }

  const zoomToFit = () => {
    showPlotingHide()
    try {
      props.plotingObj.commandHandler.zoomToFit()
    } catch (err) {
    }
  }

  const zoomIn = () => {
    showPlotingHide()
    try {
      if (zoomInc < 1) {
        zoomInc = 1
      }
      if (props.plotingObj.scale < 3.87) {
        props.plotingObj.commandHandler.increaseZoom(zoomInc + 0.166)
        zoomInc = (zoomInc + 0.166)
      }
    } catch (err) {
    }
  }

  const zoomOut = () => {
    showPlotingHide()
    try {
      if (zoomInc > 1) {
        zoomInc = 1
      }
      if (props.plotingObj.scale > 0.65) {
        props.plotingObj.commandHandler.decreaseZoom(zoomInc - 0.166)
        zoomInc = (zoomInc - 0.166)
      }
    } catch (err) {
    }
  }
  const centerOnRoot = () => {
    showPlotingHide()
    try {
      props.plotingObj.scale = 1;
      let key = 1
      props.plotingObj.nodes.each(e => {
        if (e.data.parent === "0") {
          key = e.data.key
        }
        if (key === 1) {
          if (e.data.parent === "BU-1") {
            key = e.data.key
          }
        }
        if (key === 1) {
          if (e.data.parent === "BU-1-BU-HEAD-1") {
            key = e.data.key
          }
        }
      })
      props.plotingObj.commandHandler.scrollToPart(props.plotingObj.findNodeForKey(key));
    } catch (err) {
    }
  }

  const collapseOnRoot = () => {
    showPlotingHide()
    try {
      props.plotingObj.nodes.each(e => {
        if (e.data.isExpand === "1") {
          if (nodeLeaf) {
            e.findObject("INFOSTR").visible = false
            setNodeLeaf(false)
          } else {
            e.findObject("INFOSTR").visible = true
            setNodeLeaf(true)
          }
        }
        if (e.data.nodeType === "teamMember") {
          if (!nodeLeaf) {
            e.findObject("INFO").visible = false
            setNodeLeaf(true)
          } else {
            e.findObject("INFO").visible = true
            setNodeLeaf(false)
          }
        }
      })
    } catch (err) {
    }
  }

  const topToButtom = () => {
    showPlotingHide()
    if(props?.showReportView || props?.isStructureView){
      props.setlayout(0)
    }else{
      props.setlayout(90)
    }
  }

  const leftToRight = () => {
    showPlotingHide()
    if(props?.showReportView || props?.isStructureView){
      props.setlayout(90)
    }else{
      props.setlayout(0)
    }
  }
  let className;
  if (props.hideShow) {
    className = "all-triger-show";
  } else {
    className = "all-triger";
    if (props?.orgView) {
      className += " all-triger-show-org";
    } else {
      className += " all-triger-org";
    }
  }
  let icon;
  if (!nodeLeaf) {
    icon = <ExpandIcon />
  } else {
    icon = <CollapseIcon />;
  }

  return (
    <>

      <div className='show-hide-btn'>
        {
          props.hideShow ? <button onClick={props.handleShowChanges} className='hide-btn main-show-button'><FontAwesomeIcon title="Show" icon={faEye} style={{ cursor: "pointer" }} />Show</button> :
            ""
        }
      </div>

      <div className={className}>


        <Tooltip title={<span style={{ font: "normal normal bold 12px/17px Arial" }}>Zoom In</span>} className='tooltip-title' placement='right' onClick={zoomIn}>
          <div className='triger-box'>
            <div className='triger-icon'>
              <FontAwesomeIcon icon={faPlus} />
            </div>
          </div>
        </Tooltip>

        <Tooltip title={<span style={{ font: "normal normal bold 12px/17px Arial" }}>Zoom Out</span>} className='tooltip-title' placement='right' onClick={zoomOut}>
          <div className='triger-box'>
            <div className='triger-icon'>
              <FontAwesomeIcon icon={faMinus} />
            </div>
          </div>
        </Tooltip>

        <Tooltip title={ (props?.isStructureView ? nodeLeaf : !nodeLeaf)  ? <span style={{ font: "normal normal bold 12px/17px Arial" }}>Collapse Node Details</span> : <span style={{ font: "normal normal bold 12px/17px Arial" }}>Expand Node Details</span>} className='tooltip-title' placement='right' onClick={collapseOnRoot} >
          <div className='triger-box' style={{ paddingLeft: "2px", paddingTop: "4px" }} onMouseEnter={onHover} onMouseLeave={onHover}>
            {icon}
          </div>
        </Tooltip>
        <Tooltip title={<span style={{ font: "normal normal bold 12px/17px Arial" }}>CenterOn Root</span>} className='tooltip-title' placement='right' onClick={centerOnRoot}>
          <div className='triger-box'>
            <div className='triger-icon'>
              <FontAwesomeIcon icon={faExpand} />
            </div>

          </div>

        </Tooltip>
        <Tooltip title={<span style={{ font: "normal normal bold 12px/17px Arial" }}>Zoom To Fit</span>} className='tooltip-title' placement='right' onClick={zoomToFit}>
          <div className='triger-box'>
            <div className='triger-icon'>
              <FontAwesomeIcon icon={faSearchPlus} />
            </div>
          </div>

        </Tooltip>
        <Tooltip title={<span style={{ font: "normal normal bold 12px/17px Arial" }}>Highlight</span>} className='tooltip-title' placement='right' onClick={showHeigLight}>
          <div className='triger-box'>
            <div className='triger-icon'>
              <FontAwesomeIcon icon={faHighlighter} />
            </div>
          </div>
        </Tooltip>
        <Tooltip title={!nodePlotFlag ? <span style={{ font: "normal normal bold 12px/17px Arial" }}>Show Piloting</span> : <span style={{ font: "normal normal bold 12px/17px Arial" }}>Hide Ploting</span>} className='tooltip-title' placement='right' onClick={!nodePlotFlag ? showPloting : showPlotingHide}>
          <div className='triger-box' style={{ paddingLeft: "2px", paddingTop: "5px" }} onMouseEnter={onHoverPloting} onMouseLeave={onHoverPloting}>
            <PlotingIcon />
          </div>
        </Tooltip>
        <Tooltip title={<span style={{ font: "normal normal bold 12px/17px Arial" }}>Top To Buttom</span>} className='tooltip-title' placement='right' onClick={leftToRight}>
          <div className='triger-box'>
            <div className='triger-icon triger-mui-icon'>
              <SwapVert />
            </div>
          </div>
        </Tooltip>
        <Tooltip title={<span style={{ font: "normal normal bold 12px/17px Arial" }}>Left To Right</span>} className='tooltip-title' placement='right' onClick={topToButtom} >
          <div className='triger-box'>
            <div className='triger-icon triger-mui-icon'>
              <SwapHoriz />
            </div>
          </div>
        </Tooltip>
      </div>
    </>
  )

}

