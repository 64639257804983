/*
 * Author : Vivek Khodade
 * Date : 13-Feb-2020
 * Description: Footer for Meeting Details Pop and self Meeting Addtion
 * Last Modified: 15-04-2020
 * Modified By: Vivek Khodade
 */

import React from "react";
import "../../app/layout/default/default.css";

function Footer() {
  return (
    <div
      className="e-cell-footer footer col"
      style={{
        height: 16  ,
        backgroundColor: "#000",
        position: "absolute",
        bottom: 0,
        width: "100%",
        zIndex: 1000,
      }}
    >
      <div className="col-xs-4" />
      <div
        className="footer-content col-xs-4"
        style={{ color: "#cac6c6", fontSize: 11, textAlign: "center" }}
      >
        Copyright © {new Date().getFullYear()} Cheers Interactive (I) Pvt. Ltd.
        All Rights Reserved.
      </div>
      <div className="col-xs-4" />
    </div>
  );
}

export default Footer;
