/**
 * Author: Vivek Khodade.
 * Created:08-May-2020
 * Last Modify:08-May-2020
 * Modified By:Vivek Khodade
 */
import React, { Component } from "react";
import NotificationContainer from "../../../../components/notification/notifycontainer";
import EmployeeList from '../../../../components/separation/emoloyeelist/employeelist';
import SeparationService from '../../../../services/entryexit/separationServices';
import { connect } from "react-redux";
import { setOrhChartDetaila, moveLeftEmployeeToActiveAction } from '../../../../redux/actions/entryexit/orgchartaction';
import { setMeetingCategory } from '../../../../redux/actions/meetingcategoryaction';
import ProgressBar from "../../../../components/progressbar/progressbar";
import { setMeetingMaster } from '../../../../redux/actions/meetingmasteraction';
import { getTMLCUsers } from "../../../other/commonlayoutfunction";
import { setSpinner } from '@syncfusion/ej2-react-popups';
import { spinner } from "../../../other/commonfunction";

class OrgChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      upcomingEmployeeList: [],
      employeeData: [],
      refresh: 1,
      leftEmployeeList: [],
      clickFrom: "employeeList",  // "1": Active, "2": Left, "3": Upcoming
      leftEmpRequest: false,
      activeEmpRequest: false,
      activeEmpLoaded: false,
      upComingEmpRequest: false,
      leftEmpLoaded: false,
      upComingEmpLoaded: false,
      gridHeight: (window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight) - 300
    };

    this.dataBound = this.dataBound.bind(this);
    this.onDataBinding = this.onDataBinding.bind(this)
    this.initialLoad = true;

  }
  updateDimensions = () => {
    let activeGridHeight = window.innerHeight - 130;
    this.setState({ gridHeight: activeGridHeight });
  }
  async componentDidMount() {
    localStorage.removeItem('SelectedTabAction');
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions);
    this.getEmployeeData(0);

    if (this.props.othersCheckAvailablity < 1) {
      const othersCheckAvailablityData = await getTMLCUsers(this.props.authUser);

      this.props.setMeetingMaster(othersCheckAvailablityData);
    }
  }
  filterEpmloyeeData(result){
  return  result.map(emp => {
      if (emp.DateofBirth) {
        emp.DateofBirth = new Date(emp.DateofBirth)
      }
      if (emp.JoiningDate) {
        emp.JoiningDate = new Date(emp.JoiningDate)
      }
      if (emp.TenureEndDate) {
        emp.TenureEndDate = new Date(emp.TenureEndDate)
      }
      if (emp.LeftDate) {
        emp.LeftDate = new Date(emp.LeftDate)
      }
      if (emp.LeftDate) {
        emp.LeftDate = new Date(emp.LeftDate)
      }
      if (emp.weekdayClusterName) {
        emp.weekdayClusterName = emp.weekdayClusterName.replace("_", " ")
      }
      if (emp.workdayWindowName) {
        emp.workdayWindowName = emp.workdayWindowName.replace("_", " ")
      }
      return emp
    })

  }
  // Author Get Active List Employee data 
  getEmployeeData = (empId) => { //NOSONAR
    try {
      const { clickFrom } = this.state;
      const { authUser } = this.props;
      const param = {
        authUser: authUser,
        type: empId > 0 ? "SingleEmp" : "ActiveEmp",
        empId: empId > 0 ? empId : 0
      }
      const employeeListData = Promise.resolve(
        SeparationService.getActiveLeftEmpList(param)
      );
      employeeListData.then((result) => {
        if (result !== null) {
          if (empId > 0) {
            if (result.result.length === 0) {
              this.setState(prevState => ({
                employeeData: prevState.employeeData.filter(prevEmployee => {
                  this.setState({ leftEmpRequest: false });
                  return prevEmployee.EmployeeId !== empId
                })
              }));
            }

            if (result.result.length > 0) {
              result.result.forEach(emp => {
                this.setState(prevState => ({
                  employeeData: prevState.employeeData.map(employee => {
                    return employee.EmployeeId === empId ? result.result[0] : employee
                  }),
                  activeEmpRequest: true,
                  activeEmpLoaded: true,
                  leftEmpLoaded: false,
                  // refresh: ++prevState.refresh,
                }));
              })
            }
            setTimeout(() => {
              this.setState(prevState => ({
                refresh: ++prevState.refresh,
              }))
            }, 500);
          } else {
            if (empId || empId === 0 || clickFrom === "employeeList") {
              if (result.result !== false) {
                const employeeData = this.filterEpmloyeeData(result.result)
                this.setState({
                  employeeData: employeeData,
                  activeEmpRequest: true,
                  activeEmpLoaded: true
                })
              }
            }
          }
        }
      });
    } catch (error) {
      // eslint-disable-next-line
      console.error(error);
    }
  }
  getLeftEmpList = (empId) => {
    try {
      const { authUser } = this.props;
      const param = {
        authUser: authUser,
        type: empId > 0 ? "MoveLeftEmp" : "LeftEmp",
        empId: empId > 0 ? empId : 0
      }
      const employeeListData = Promise.resolve(
        SeparationService.getActiveLeftEmpList(param)
      );
      employeeListData.then((result) => {
        if (result !== null) {
          if (empId > 0) {
            this.setState(prevState => ({
              leftEmployeeList: prevState.leftEmployeeList.filter(prevEmployee => prevEmployee.EmployeeId !== empId),
              // activeEmpRequest: false,
              // activeEmpLoaded: false,
              leftEmpLoaded: true
            }), () => {
              this.getEmployeeData(0);
            });
          } else {
            if (empId || empId === 0) {
              const leftEmployeeData = this.filterEpmloyeeData(result.result)

              this.setState({
                leftEmployeeList: leftEmployeeData,
                // activeEmpLoaded: false,
                leftEmpLoaded: true
              })
            }
          }
        }
      });
    } catch (error) {
      // eslint-disable-next-line
      console.error(error);
    }
  }
  // Author:Amit Kumar
  // To get employee list, who will be joining in future.
  getUpcomingEmpData = (empId) => {
    try {
      const { authUser } = this.props;
      const param = {
        authUser: authUser,
        type: "UpcomingEmp",
        empId: empId > 0 ? empId : 0
      }
      const employeeListData = Promise.resolve(
        SeparationService.getActiveLeftEmpList(param)
      );
      employeeListData.then((result) => {

        console.log(result, "result")
        if (result && result.result) {
          const upcomingEmployeeData = result.result.map(emp => {
            if (emp.DateofBirth) {
              emp.DateofBirth = new Date(emp.DateofBirth)
            }
            if (emp.JoiningDate) {
              emp.JoiningDate = new Date(emp.JoiningDate)
            }
            if (emp.TenureEndDate) {
              emp.TenureEndDate = new Date(emp.TenureEndDate)
            }
            if (emp.LeftDate) {
              emp.LeftDate = new Date(emp.LeftDate)
            }
            if (emp.OfferedDate) {
              emp.OfferedDate = new Date(emp.OfferedDate)
            }
            if (emp.OfferAcceptanceDate) {
              emp.OfferAcceptanceDate = new Date(emp.OfferAcceptanceDate)
            }
            if (emp.weekdayClusterName) {
              emp.weekdayClusterName = emp.weekdayClusterName.replace("_", " ")
            }
            if (emp.workdayWindowName) {
              emp.workdayWindowName = emp.workdayWindowName.replace("_", " ")
            }

            return emp
          })

          this.setState({
            upcomingEmployeeList: upcomingEmployeeData,
            upComingEmpLoaded: true
          })
        }
      });
    } catch (error) {
      // eslint-disable-next-line
      console.error(error);
    }
  }
  onCreated() {
    setTimeout(() => {
      if (this.acrdnbasic !== null) {
        // eslint-disable-next-line
        this.acrdnbasic.items.expanded = true;
      }
    }, 1000);
  }
  refreshHandler = () => {
    this.setState(prevState => ({
      refresh: ++prevState.refresh
    }))
  }
  openTab = (evt, tabName) => { // NOSONAR
    const { leftEmpRequest, activeEmpRequest, upComingEmpRequest } = this.state;
    if (tabName === "Active Employee") {
      this.setState({ clickFrom: "employeeList" })
      if (!activeEmpRequest) {
        this.setState({ activeEmpRequest: true, activeEmpLoaded: false });
        this.getEmployeeData(0);
      }
      localStorage.setItem('SelectedTabAction', 'Active Employee');
    }
    if (tabName === "Left Employee") {
      this.setState({ clickFrom: "leftEmployee" });
      if (!leftEmpRequest) {
        this.setState({ leftEmpRequest: true, leftEmpLoaded: false });
        this.getLeftEmpList(0);
      }
      localStorage.setItem('SelectedTabAction', 'Left Employee');

    }
    if (tabName === "Upcoming Employee") {
      this.setState({ clickFrom: "upcomingEmployee" })
      if (!upComingEmpRequest) {
        this.setState({ upComingEmpRequest: true, upComingEmpLoaded: false });
        this.getUpcomingEmpData(0);
      }
      localStorage.setItem('SelectedTabAction', 'Upcoming Employee');

    }
  };


  /**
  * Created on 06-09-2022 || Noel Fredrick
  * Description - For spinner
  */
  onDataBinding() {
    spinner(true)
  }

  dataBound() {
    spinner(false)
  }

  onFirstGridCreated() {
    if (this.initialLoad) {
      this.initialLoad = false;
      setSpinner({
        template: document.getElementById('spinnerContainer').innerHTML,
      });
      document.getElementById('spinnerContainer').remove();
    }

  }

  render() {
    const { gridHeight, employeeData, leftEmployeeList, upcomingEmployeeList, refresh, clickFrom, activeEmpLoaded, leftEmpLoaded, upComingEmpLoaded } = this.state;
    return (
      <div id="orgchartFuncSection"
        className="row"
        style={{
          padding: "10px 25px 10px 25px",
          background: "#eee"
        }} >
        <>
          <NotificationContainer />
          <div className="tab row-xs-12 row-sm-12 row-lg-12 row-md-12">
            <>
              <button
                id="active-team-member-tab"
                type="button"
                className={clickFrom === "employeeList" ? `col-lg-4 onboardingtab onboarding-tab active` : `col-lg-4 onboardingtab onboarding-tab`}
                onClick={(e) => this.openTab(e, "Active Employee")}
              >
                <b>ACTIVE TEAM MEMBER</b>{" "}
              </button>
              <button
                type="button"
                className={clickFrom === "leftEmployee" ? `col-lg-4 onboardingtab onboarding-tab active` : `col-lg-4 onboardingtab onboarding-tab`}
                onClick={(e) => this.openTab(e, "Left Employee")}
              >
                <b>LEFT TEAM MEMBER </b>
              </button>
              <button
                type="button"
                className={clickFrom === "upcomingEmployee" ? `col-lg-4 onboardingtab onboarding-tab active` : `col-lg-4 onboardingtab onboarding-tab`}
                onClick={(e) => this.openTab(e, "Upcoming Employee")}
              >
                <b>UPCOMING TEAM MEMBER </b>
              </button>
            </>
          </div>
          <div id="Active Employee" className="tabcontent" style={{ height: gridHeight, display: clickFrom === "employeeList" ? "block" : "none" }}>
            {
              activeEmpLoaded ?
                <EmployeeList
                  empListType={clickFrom}
                  employeeData={employeeData}
                  getEmployeeData={this.getEmployeeData}
                  refresh={refresh}
                  onDataBinding={this.onDataBinding("orgchartFuncSection")}
                  created={this.onFirstGridCreated.bind(this)}
                />
                : 
                <div id="spinnerContainer">
                <ProgressBar />
                </div>
            }
          </div>
          <div id="Left Employee" className="tabcontent" style={{ height: gridHeight, display: clickFrom === "leftEmployee" ? "block" : "none" }}>
            {
              leftEmpLoaded ?
                <EmployeeList
                  empListType={clickFrom}
                  employeeData={leftEmployeeList}
                  moveLeftEmployeeToActiveAction={this.props.moveLeftEmployeeToActiveAction}
                  authUser={this.props.authUser}
                  getLeftEmpList={this.getLeftEmpList}
                  setMeetingCategory={this.props.setMeetingCategory}
                  onDataBinding={this.onDataBinding("orgchartFuncSection")}
                  created={this.onFirstGridCreated.bind(this)}
                />
                : 
                <div id="spinnerContainer">
                <ProgressBar />
                </div>
            }
          </div>
          <div id="Upcoming Employee" className="tabcontent" style={{ height: gridHeight, display: clickFrom === "upcomingEmployee" ? "block" : "none" }}>
            {
              upComingEmpLoaded ?
                <EmployeeList
                  empListType={clickFrom}
                  // upcomingEmployeeList={upcomingEmployeeList}
                  employeeData={upcomingEmployeeList}
                  setMeetingCategory={this.props.setMeetingCategory}
                  onDataBinding={this.onDataBinding("orgchartFuncSection")}
                  created={this.onFirstGridCreated.bind(this)}
                /> : 
                <div id="spinnerContainer">
                <ProgressBar />
                </div>
            }
          </div>
        </>
      </div>
    );
  }
}
const mapStateToProps = ({ auth, orgchartreducer, usersettingreducer, meetingmaster }) => {
  const { authUser } = auth;
  const { orgchartupdateStatus, updatedEmployee } = orgchartreducer;
  const { moduleAttributeSetting } = usersettingreducer;
  const { othersCheckAvailablity } = meetingmaster;
  return { authUser, orgchartupdateStatus, moduleAttributeSetting, updatedEmployee, othersCheckAvailablity };
};

export default connect(mapStateToProps, { setOrhChartDetaila, moveLeftEmployeeToActiveAction, setMeetingCategory, setMeetingMaster })(
  OrgChart
);
