export const swapWeeklyColumns = {
    TeamMemberCode: 'Team Member Code',
    TeamMemberName: 'Team Member Name',
    Date: "Date",
    SwappedDate: "Swapped On Date",
    Remarks: 'Remarks',
    Descripency: "Descripency",
    DesDate: 'Date'
  }; 

  export const monthlyColumns = {
    TeamMemberCode: 'Team Member Code',
    TeamMemberName: 'Team Member Name',
    LeaveType1: "Leave Type 1",
    LeaveType2: "Leave Type 2",
    DecimalPortion: 'Decimal Portion',
    Descripency: "Descripency",
    DesDate: 'Date'
  };
  export const exportColumns = {
    var_emp_code: 'Emp Code',
    var_full_name: "Name",
    var_attendance_type: "Attendance Type",
    var_attendance_type_old: "Old Attendance Type",
    dt_attendance_date: "Attendance Date",
    tm_total_time_in_business: "Total Time in Business",
  };