import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { addUpdateTibData } from '../../redux/actions/tibacton';
import { CheckBoxSelection, Inject, MultiSelectComponent } from '@syncfusion/ej2-react-dropdowns';
import { refreshMultiSelect } from '../../app/other/commonfunction'

class BucketCategoryViewBy extends Component {
    constructor(props) {
        super(props)
        this.state = {
            changeData: false,
            selectedBucketCatIds: [],
            selectedViewIds: []
        };
        this.getSelectedBucketCategory = this.getSelectedBucketCategory.bind(this);
        this.removeBucketCategory = this.removeBucketCategory.bind(this);
        this.getSelectedViewBy = this.getSelectedViewBy.bind(this);
        this.removeViewBy = this.removeViewBy.bind(this);
        this.created = this.created.bind(this)
    }
    componentDidMount() {
        const { tibAvgSelectedBucketIds, tibAvgSelectedViewByIds } = this.props;
        if (tibAvgSelectedBucketIds.length > 0 && tibAvgSelectedViewByIds.length > 0)
            this.setState({ selectedBucketCatIds: tibAvgSelectedBucketIds, selectedViewIds: tibAvgSelectedViewByIds });
    }
    UNSAFE_componentWillReceiveProps(newProps) {
        const { tibAvgSelectedBucketIds, tibAvgSelectedViewByIds } = newProps;
        this.setState({ selectedBucketCatIds: tibAvgSelectedBucketIds, selectedViewIds: tibAvgSelectedViewByIds })
    }
    getSelectedBucketCategory(e) {
        let selectedIds = this.state.selectedBucketCatIds;
        if (e.itemData !== null) {
            selectedIds.push(e.itemData.Id);
            this.setState({ selectedBucketCatIds: selectedIds }, () => this.props.addUpdateTibData({ tibAvgSelectedBucketIds: selectedIds }));
        } else {
            selectedIds = [];
            this.setState({ selectedBucketCatIds: selectedIds }, () => this.props.addUpdateTibData({ tibAvgSelectedBucketIds: selectedIds }));
        }
    }
    removeBucketCategory(e) {
        let selectedIds = this.state.selectedBucketCatIds;
        if (selectedIds.length > 0 && e.itemData !== null) {
            selectedIds = selectedIds.filter(el => el !== e.itemData.Id);

            this.setState({ selectedBucketCatIds: selectedIds }, () => this.props.addUpdateTibData({ tibAvgSelectedBucketIds: selectedIds }));
        } else {
            selectedIds = [];
            this.setState({ selectedBucketCatIds: selectedIds }, () => this.props.addUpdateTibData({ tibAvgSelectedBucketIds: selectedIds }));
        }
    }
    getSelectedViewBy(e) {
        const { selectedViewIds } = this.state;
        let selectedIds = selectedViewIds;
        if (e.itemData !== null) {
            selectedIds.push(e.itemData.Id);
            this.setState({ selectedViewIds: selectedIds }, () => this.props.addUpdateTibData({ tibAvgSelectedViewByIds: selectedIds }));
        } else {
            selectedIds = [];
            this.setState({ selectedViewIds: selectedIds }, () => this.props.addUpdateTibData({ tibAvgSelectedViewByIds: selectedIds }));
        }
    }
    removeViewBy(e) {
        let selectedIds = this.state.selectedViewIds;
        if (selectedIds.length > 0 && e.itemData !== null) {
            selectedIds = selectedIds.filter(el => el !== e.itemData.Id);
            this.setState({ selectedViewIds: selectedIds }, () => this.props.addUpdateTibData({ tibAvgSelectedViewByIds: selectedIds }));
        } else {
            selectedIds = [];
            this.setState({ selectedViewIds: selectedIds }, () => this.props.addUpdateTibData({ tibAvgSelectedViewByIds: selectedIds }));
        }
    }
    created(pDivId, pComponentId) {        
        try {
            setTimeout(() => {
                refreshMultiSelect(pDivId, pComponentId);
            })
        } catch (error) {
            console.log("🚀 ~ file: timeinbusiness.js ~ line 433 ~ TimeInBusiness ~ created ~ error", error)
        }
    }

    render() {
        const { tibAvgBucketData, tibAvgViewByData, tibAvgSelectedBucketIds, tibAvgSelectedViewByIds } = this.props;
        return (
            <div className='col-lg-4 col-md-4 col-sm-4 col-xs-4' style={{ paddingLeft: "0px" }}>
                <div className='row pb-sm'>
                    <div id="multigroupViewBy" className='col-lg-6 col-md-6 col-sm-6 col-xs-6'>
                        <MultiSelectComponent
                            id="mtselement"
                            popupHeight='200px'
                            fields={{ text: 'Value', value: 'Id' }}
                            cssClass="e-outline"
                            dataSource={tibAvgViewByData}
                            placeholder="View By"
                            mode="CheckBox"
                            allowFiltering={false}
                            showSelectAll={false}
                            floatLabelType="Auto"
                            showDropDownIcon
                            value={tibAvgSelectedViewByIds}
                            select={this.getSelectedViewBy}
                            removed={this.removeViewBy}
                            showClearButton={false}
                            created={this.created("multigroupViewBy", "mtselement")}
                        >
                            <Inject services={[CheckBoxSelection]} />
                        </MultiSelectComponent>
                        <div id="select-view-by" className="error-div pl-sm" />
                    </div>
                    <div id="multigroupBucket" className='col-lg-6 col-md-6 col-sm-6 col-xs-6' style={{ marginLeft: -2 }}>
                        <MultiSelectComponent
                            id="mtselementBucket"
                            popupHeight='240px'
                            fields={{ text: 'Value', value: 'Id' }}
                            cssClass="e-outline"
                            dataSource={tibAvgBucketData}
                            value={tibAvgSelectedBucketIds}
                            placeholder="Buckets"
                            mode="CheckBox"
                            enableGroupCheckBox="false"
                            allowFiltering={false}
                            showSelectAll={false}
                            showDropDownIcon
                            floatLabelType="Auto"
                            select={this.getSelectedBucketCategory}
                            removed={this.removeBucketCategory}
                            showClearButton={false}
                            created={this.created("multigroupBucket", "mtselementBucket")}
                        >
                            <Inject services={[CheckBoxSelection]} />
                        </MultiSelectComponent>
                        <div id="select-bucket" className="error-div pl-sm" />
                    </div>
                </div>
            </div>
        )
    }
}
BucketCategoryViewBy.propTypes = {
    tibAvgBucketData: PropTypes.arrayOf(Object),
    tibAvgViewByData: PropTypes.arrayOf(Object),
};
BucketCategoryViewBy.defaultProps = {
    tibAvgBucketData: [],
    tibAvgViewByData: [],
};
const mapStateToProps = ({ tibReducer }) => {
    // eslint-disable-next-line
    const {
        tibAvgBucketData,
        tibAvgViewByData,
        tibAvgSelectedBucketIds,
        tibAvgSelectedViewByIds
    } = tibReducer;
    return {
        tibAvgBucketData,
        tibAvgViewByData,
        tibAvgSelectedBucketIds,
        tibAvgSelectedViewByIds
    };
};

export default connect(mapStateToProps, { addUpdateTibData })(BucketCategoryViewBy);