/* Author : Vivek Khodade
 * Date : 17-Apr-2020
 * Description: TMLC Employee Profile Header component
 */

import React from "react";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { faArrowLeft } from "@fortawesome/pro-light-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { setMeetingCategory } from '../../redux/actions/meetingcategoryaction';
import {
  CONFIRMATION, EMPLOYEE_LIST, ABSCONDINGHR, ABSCONDINGMANAGER, RESIGNATIONMANAGERAPPROVAL, HRAPPROVAL,
  RESIGNATIONPNLAPPROVAL, TERMINATIONHR, ON_BOARDING, ORG_CHART, EXITCLEARANCEGRID
} from "../../redux/constant/meetingcategoryconstant";
import "./employeeprofileheader.css";
import {encodeString, decodeString} from "../../app/other/commonfunction";

library.add(faArrowLeft);
class Employeeprofileheader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: null,
    };
  }

  /**
   * @author Vivek Khodadde
   * @description navigate to Confirmation page
   */
  navigateToConfirmation = async () => {
    try {
      if (CONFIRMATION === decodeString(localStorage.getItem('emailAddress'))) {
        localStorage.setItem("meeting_category", encodeString(CONFIRMATION));
        const path = `/confirmation?appId=${encodeString(CONFIRMATION)}`;
        this.setState({
          redirect: path,
        }, () => this.props.setMeetingCategory(CONFIRMATION));
      }
      if (CONFIRMATION === decodeString(localStorage.getItem('last_meeting_category'))) {
        localStorage.setItem("meeting_category", encodeString(CONFIRMATION));
        const path = `/confirmation?appId=${encodeString(CONFIRMATION)}`;
        this.setState({
          redirect: path,
        }, () => this.props.setMeetingCategory(encodeString(CONFIRMATION)));
      }
      if (ON_BOARDING === decodeString(localStorage.getItem('last_meeting_category'))) {
        localStorage.setItem("meeting_category", encodeString(CONFIRMATION));
        const path = `/confirmation?appId=${encodeString(CONFIRMATION)}`;
        this.setState({
          redirect: path,
        }, () => this.props.setMeetingCategory(CONFIRMATION));
      }
      if (EMPLOYEE_LIST === decodeString(localStorage.getItem('last_meeting_category'))) {
        localStorage.setItem("meeting_category", encodeString(EMPLOYEE_LIST));
        const path = `/orgchart?appId=${encodeString(EMPLOYEE_LIST)}`;
        this.setState({
          redirect: path,
        }, () => this.props.setMeetingCategory(EMPLOYEE_LIST));
      }
      if (ABSCONDINGHR === decodeString(localStorage.getItem('last_meeting_category'))) {
        localStorage.setItem("meeting_category", encodeString(ABSCONDINGHR));
        const path = "/separation";
        this.setState({
          redirect: path,
        }, () => this.props.setMeetingCategory(ABSCONDINGHR));
      }
      if (ABSCONDINGMANAGER === decodeString(localStorage.getItem('last_meeting_category'))) {
        localStorage.setItem("meeting_category", encodeString(ABSCONDINGMANAGER));
        const path = "/separation";
        this.setState({
          redirect: path,
        }, () => this.props.setMeetingCategory(ABSCONDINGMANAGER));
      }
      if (RESIGNATIONMANAGERAPPROVAL === decodeString(localStorage.getItem('last_meeting_category'))) {
        localStorage.setItem("meeting_category", encodeString(RESIGNATIONMANAGERAPPROVAL));
        const path = "/separation";
        this.setState({
          redirect: path,
        }, () => this.props.setMeetingCategory(RESIGNATIONMANAGERAPPROVAL));
      }
      if (HRAPPROVAL === decodeString(localStorage.getItem('last_meeting_category'))) {
        localStorage.setItem("meeting_category", encodeString(HRAPPROVAL));
        const path = "/separation";
        this.setState({
          redirect: path,
        }, () => this.props.setMeetingCategory(HRAPPROVAL));
      }
      if (RESIGNATIONPNLAPPROVAL === decodeString(localStorage.getItem('last_meeting_category'))) {
        localStorage.setItem("meeting_category", encodeString(RESIGNATIONPNLAPPROVAL));
        const path = "/separation";
        this.setState({
          redirect: path,
        }, () => this.props.setMeetingCategory(RESIGNATIONPNLAPPROVAL));
      }
      if (TERMINATIONHR === decodeString(localStorage.getItem('last_meeting_category'))) {
        localStorage.setItem("meeting_category", encodeString(TERMINATIONHR));
        const path = "/separation";
        this.setState({
          redirect: path,
        }, () => this.props.setMeetingCategory(TERMINATIONHR));
      }
      if (ORG_CHART === decodeString(localStorage.getItem('last_meeting_category'))) {
        localStorage.setItem("meeting_category", encodeString(ORG_CHART));
        const path = `/orgchart?appId=${encodeString(ORG_CHART)}`;
        this.setState({
          redirect: path,
        }, () => this.props.setMeetingCategory(ORG_CHART));
      }
      if (EXITCLEARANCEGRID === decodeString(localStorage.getItem('last_meeting_category'))) {
        localStorage.setItem("meeting_category", encodeString(EXITCLEARANCEGRID));
        const path = "/separation";
        this.setState({
          redirect: path,
        }, () => this.props.setMeetingCategory(EXITCLEARANCEGRID));
      }
    } catch (error) {
      // eslint-disable-next-line
      console.log("error", error);
    }
  };

  render() {
    const { redirect } = this.state;
    if (redirect) {
      return <Redirect to={redirect} />;
    }
    let header_name = "Team Member Profile";
    if (EXITCLEARANCEGRID === decodeString(localStorage.getItem('last_meeting_category'))) {
      header_name = "Exit Clearance";
    } 
    // else if (EXITCLEARANCEGRID === decodeString(localStorage.getItem('last_meeting_category'))) {

    // }

    return (
      <div className="root">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Paper className="paper">
              <div>
                {/* <div style={{ float: "left" }}>
                  {
                    EMPLOYEE_LIST !== decodeString(localStorage.getItem('last_meeting_category')) &&
                  <TooltipComponent
                    closeOn="Click"
                    content='Back'
                    position="BottomCenter"
                    isSticky={false}
                    cssClass='customtooltip'
                  >
                        <FontAwesomeIcon
                          icon={faArrowLeft}
                          className="mt-sm back-icon"
                          style={{ cursor: "pointer" }}
                          onClick={() => this.navigateToConfirmation()}
                        />
                  </TooltipComponent>
                  }
                </div> */}
                <div className="employeeprofileheader-header-div">
                  {header_name}
                </div>
              </div>
            </Paper>
          </Grid>
        </Grid>
      </div>
    );
  }
}
const mapStateToProps = ({ auth }) => {
  const { authUser } = auth;
  return { authUser };
};

export default connect(mapStateToProps, { setMeetingCategory })(
  Employeeprofileheader
);
