import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { addUpdateOkrDashboardRedux } from '../../redux/actions/okrdashboardaction/okrdashboardaction';
import Grid from './grid';
import RatingImg from '../../assets/images/ratingreview.jpg';

function Tab(props) {
  const [state, setState] = useState({
    height: null,
    windowAdjust: null
  });

  useEffect(() => {
    window.addEventListener("resize", updateDimensions()); //NOSONAR
    document.getElementById('BusinessOKR').style.display = "block";
  }, [])


  useEffect(() => {
    if (props.performanceType === "Central OKR") {
      openTab(null, "CentralOKR")
    }
  }, [])

  const updateDimensions = () => {
    const updateheight = window.innerHeight - 416;
    const windowAdjusts = window.innerHeight - 323;
    setState({
      height: updateheight,
      windowAdjust: windowAdjusts,
    });
  }
  const { height, windowAdjust } = state;
  const { showMonths, performanceBusinessOkrData, performanceCentralOkrData } = props;

  const openTab = (evt, tabName) => {
    let i;
    const tabcontent = document.getElementsByClassName("tabcontent");
    // eslint-disable-next-line
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }
    const tablinks = document.getElementsByClassName("tablinks");
    // eslint-disable-next-line
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }
    if (document.getElementById(tabName) !== null) {
      document.getElementById(tabName).style.display = "block";
    }
    if (evt !== null) {
      evt.currentTarget.className += " active";
    }
    else if (document.getElementById('tab' + tabName) !== null) {
      document.getElementById('tab' + tabName).classList.add("active");
    }
  };
  const noDataFound = () => {
    return (
      <>
        <div
          id='NoData'
          style={{
            height: showMonths ? height - 100 : windowAdjust - 100,
            overflowY: "hidden",
            overflowX: "hidden",
            // borderTop: '1px solid #ccc'
          }}
        >
          <div style={{ marginTop: '2%' }}>
            <div style={{ marginTop: '2%' }}>
              <div>
                <center>
                  <img src={RatingImg} alt="Image Not Found" />
                  <div className='ratingwelcometext pt-sm'>Welcome to OKR - Dashboard</div>
                  <div className='ratingwelcomesubtext pt-xs'>
                    <span style={{ color: "#4B4B4B" }}>
                      No Objectives to view for this team-member in this Performance Period.
                    </span>

                  </div>
                </center>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }

  return (
    <div className='container-fluid '>
      <div className='row' style={{ marginLeft: -15, marginRight: -15 }}>
        <>
          <div className="tab">
            <div className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12" >
              <button
                id="tabBusinessOKR"
                type="submit"
                className="tablinks col-2 col-lg-2 col-md-2 col-sm-2 col-xs-2 active rating-tab-font"
                onClick={(e) => { openTab(e, "BusinessOKR") }}
              >Business OKR <span id=''> (Scale 1-5) </span>
              </button>
              <button
                id="tabCentralOKR"
                type="submit"
                className="tablinks col-2 col-lg-2 col-md-2 col-sm-2 col-xs-2 rating-tab-font"
                onClick={(e) => openTab(e, "CentralOKR")}
              >
                Central OKR <span id='centralOkrCount'> (Scale 1-5) </span>
              </button>
            </div>
          </div>

          <div>
            <div
              style={{
                height: showMonths ? height + 78 : windowAdjust,
                overflowY: "auto",
                overflowX: "hidden",
                borderRadius: 6
              }}
              id="BusinessOKR"
              className="tabcontent">
              <div>
                {
                  performanceBusinessOkrData.length > 0 ?
                    performanceBusinessOkrData?.map((item) => {
                      return (
                        <Grid objectiveData={item} />
                      )
                    })
                    :
                    noDataFound()
                }
              </div>
            </div>
            <div
              style={{
                height: showMonths ? height + 78 : windowAdjust,
                overflowY: window.innerHeight < 670 ? 'auto' : "auto",
                overflowX: "hidden",
                borderRadius: 6
              }}
              id="CentralOKR"
              className="tabcontent">
              <div>
                {
                  performanceCentralOkrData.length > 0 ?
                    performanceCentralOkrData?.map((item) => { /* NOSONAR */
                      return (
                        <Grid objectiveData={item} />
                      )
                    })
                    :
                    noDataFound()
                }
              </div>
            </div>
          </div>
        </>

      </div>
    </div>
  )
}
const mapStateToProps = ({ auth, okrdashboardreducer }) => {
  const { authUser } = auth;
  const { performanceBusinessOkrData, performanceCentralOkrData, performanceType } = okrdashboardreducer;
  return {
    performanceBusinessOkrData, performanceCentralOkrData, authUser, performanceType
  }
};
export default connect(mapStateToProps, { addUpdateOkrDashboardRedux })(
  Tab
);

