import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import OnLeave from "../../../lnacomponents/leaves/leavesbalance/onleave";
import LeavesBalance from "../leavesbalance/leavesbalance";
import LeaveDetails from "../leavesbalance/leavesdetails";
import LeavesTransfer from "../leavesbalance/leavetransfer";
import Calendar from "../leavesbalance/calender";
import RegularizationStatus from "../regulization/regularizationStatusGrid";
import { Button } from "@material-ui/core";

import LeaveNotifiersList from "./leavenotifierslist";
import { updateLnAData } from "../../../../redux/actions/lna/lnaaction";
import { CANCELLEAVE } from "../../../../redux/constant/meetingcategoryconstant";
export const LeaveTabs = (props) => {
  const {
    type,
    viewMode,
    leaveApplyLoadName,
    selectedTab: selectedTabPrev,
    categoryName,
  } = props;
  const [showCalendarView, setShowCalendarView] = useState(false);
  const [openCalendarFor, setOpenCalendarFor] = useState({});
  const [openCalendarForOnLeave, setOpenCalendarForOnLeave] = useState(false);
  const [selectedTab, setSelectedTab] = useState("");
  const [allHeight, setAllHeight] = React.useState({
    //nosonar
    containerHeight: null,
    tabHeight: null,
    calendarviewHeight: null,
  });
  useEffect(() => {
    window.addEventListener("resize", updateDimensions()); //NoSONAR
    if (selectedTabPrev === "") {
      let newTab;

      if (type === "Regularization") {
        newTab = "regularization";
      } else if (viewMode === "Notification") {
        newTab = "leaveNotification";
      } else if (categoryName.toString() === CANCELLEAVE) {
        newTab = "leaveDetails";
      } else {
        newTab = "leaveBalance";
      }

      setSelectedTab(newTab);
      updateLnAData({ selectedTab: newTab });
    } else {
      setSelectedTab(selectedTabPrev);
    }
  }, []);

  useEffect(() => {
    if (selectedTabPrev === "") {
      let newTab;
      if (categoryName.toString() === CANCELLEAVE) {
        newTab = "leaveDetails";
      } else {
        newTab = "leaveBalance";
      }

      setSelectedTab(newTab);
      updateLnAData({ selectedTab: newTab });
    } else {
      setSelectedTab(selectedTabPrev);
    }
  }, [categoryName]);

  useEffect(() => {
    if (selectedTab !== "") {
      updateLnAData({ selectedTab });
    }
  }, [selectedTab]);

  const isThirteenHundred = window.innerHeight < 658;
  const updateDimensions = () => {
    const minHeight = isThirteenHundred ? 235 : 238;
    const containerHeight = window.innerHeight - minHeight;
    const calendarviewHeight = window.innerHeight - minHeight;
    const tabHeight = window.innerHeight - 220;
    setAllHeight((prevState) => ({
      ...prevState,
      containerHeight: containerHeight,
      tabHeight: tabHeight,
      calendarviewHeight: calendarviewHeight,
    }));
  };
  useEffect(() => {
    try {
      if (type === "approvalsubmit") {
        setSelectedTab("approvalleaveBalance");
      } else if (
        type === "leaveApprovalSubmit" ||
        type === "swipeApprovalSubmit" ||
        type === "specialAttendanceApprovalSubmit"
      ) {
        setSelectedTab("calendar");
      }
    } catch (err) {
      console.log("? ~ file: leavetabs.jsx:30 ~ useEffect ~ err", err);
    }
  }, [type, viewMode]);

  const openTab = (evt, tabName, onleave = false) => {
    setSelectedTab(tabName);
    setOpenCalendarForOnLeave(onleave);
  };

  const handleCalendarDisplay = (tmLeave) => {
    setShowCalendarView(true);
    setOpenCalendarFor(tmLeave);
  };

  const handleCloseButton = () => {
    setShowCalendarView(false);
  };

  const renderTabsType = (tabType) => {
    switch (tabType) {
      case "Regularization":
        return (
          <>
            <div
              id="tabCalender"
              type="submit"
              className={` smallResolutionText leave-balance-individual ${
                selectedTab === "calendar" ? "active-leave" : ""
              }`}
              onClick={(e) => {
                openTab(e, "calendar");
              }}
              style={{ textAlign: "center", cursor: "pointer" }}
            >
              Calendar
            </div>
            <div
              id="tabRegularization"
              type="submit"
              className={` smallResolutionText leave-balance-individual ${
                selectedTab === "regularization" ? "active-leave" : ""
              }`}
              onClick={(e) => {
                openTab(e, "regularization");
              }}
              style={{ textAlign: "center", cursor: "pointer" }}
            >
              Regularization Status
            </div>
          </>
        );
      case "LeaveApply":
      case "COMPOFF":
        return (
          <>
            <div
              id="tabCalender"
              type="submit"
              className={` smallResolutionText leave-balance-individual ${
                selectedTab === "calendar" ? "active-leave" : ""
              }`}
              onClick={(e) => {
                openTab(e, "calendar");
              }}
              style={{ textAlign: "center", cursor: "pointer" }}
            >
              Calendar
            </div>

            <div
              id="tabLeaveBalnce"
              type="submit"
              className={`smallResolutionText `}
              onClick={(e) => {
                openTab(e, "leaveBalance");
              }}
              style={{ textAlign: "center", cursor: "pointer" }}
            >
              <div
                className={`leave-balance-individual ${
                  selectedTab === "leaveBalance" ? "active-leave" : ""
                }`}
              >
                Leave Balance
              </div>
            </div>
            <div
              id="tabOnLeave"
              type="submit"
              className={` smallResolutionText leave-balance-individual ${
                selectedTab === "onLeave" ? "active-leave" : ""
              }`}
              onClick={(e) => {
                openTab(e, "onLeave", false);
              }}
              style={{ textAlign: "center", cursor: "pointer" }}
            >
              On Leave
            </div>
            <div
              id="tabOnLeave"
              type="submit"
              className={` smallResolutionText leave-balance-individual ${
                selectedTab === "leaveDetails" ? "active-leave" : ""
              }`}
              onClick={(e) => {
                openTab(e, "leaveDetails");
              }}
              style={{ textAlign: "center", cursor: "pointer" }}
            >
              {props.type == "Transfer"
                ? "Leave Transfer Status"
                : "Leave Details"}
            </div>
            <div
              id="tabOnLeave"
              type="submit"
              className={` smallResolutionText leave-balance-individual ${
                selectedTab === "leaveNotification" ? "active-leave" : ""
              }`}
              onClick={(e) => {
                openTab(e, "leaveNotification");
              }}
              style={{
                textAlign: "center",
                cursor: "pointer",
                marginRight: "10px",
              }}
            >
              Notifications
            </div>
          </>
        );

      case "Transfer":
        return (
          <>
            <div
              id="tabCalender"
              type="submit"
              className={` smallResolutionText leave-balance-individual ${
                selectedTab === "calendar" ? "active-leave" : ""
              }`}
              onClick={(e) => {
                openTab(e, "calendar");
              }}
              style={{ textAlign: "center", cursor: "pointer" }}
            >
              Calendar
            </div>

            <div
              id="tabLeaveBalnce"
              type="submit"
              className={`smallResolutionText `}
              onClick={(e) => {
                openTab(e, "leaveBalance");
              }}
              style={{ textAlign: "center", cursor: "pointer" }}
            >
              <div
                className={`leave-balance-individual ${
                  selectedTab === "leaveBalance" ? "active-leave" : ""
                }`}
              >
                Leave Balance
              </div>
            </div>
            <div
              id="tabOnLeave"
              type="submit"
              className={` smallResolutionText leave-balance-individual ${
                selectedTab === "onLeave" ? "active-leave" : ""
              }`}
              onClick={(e) => {
                openTab(e, "onLeave", false);
              }}
              style={{ textAlign: "center", cursor: "pointer" }}
            >
              On Leave
            </div>
            <div
              id="tabOnLeave"
              type="submit"
              className={` smallResolutionText leave-balance-individual ${
                selectedTab === "leaveDetails" ? "active-leave" : ""
              }`}
              onClick={(e) => {
                openTab(e, "leaveDetails");
              }}
              style={{ textAlign: "center", cursor: "pointer" }}
            >
              {props.type == "Transfer"
                ? "Leave Transfer Status"
                : "Leave Details"}
            </div>
          </>
        );
      case "approvalsubmit":
        return (
          <>
            <div
              id="tabLeaveBalnce"
              type="submit"
              className={`smallResolutionText `}
              onClick={(e) => {
                openTab(e, "approvalleaveBalance");
              }}
              style={{ textAlign: "center", cursor: "pointer" }}
            >
              <div
                className={`leave-balance-individual ${
                  selectedTab === "approvalleaveBalance" ? "active-leave" : ""
                }`}
              >
                Leave Balance
              </div>
            </div>
            <div
              id="tabOnLeave"
              type="submit"
              className={` smallResolutionText leave-balance-individual ${
                selectedTab === "onLeave" ? "active-leave" : ""
              }`}
              onClick={(e) => {
                openTab(e, "onLeave", true);
              }}
              style={{ textAlign: "center", cursor: "pointer" }}
            >
              On Leave
            </div>
            {/* <div
              id="tabLeaveBalnce"
              type="submit"
              className={`col-4 col-lg-4 col-md-4 col-sm-4 col-xs-4 smallResolutionText leave-balance-individual ${selectedTab === "approvalleaveBalance" ? "active-leave" : ""}`}
           
              style={{ textAlign: 'center', cursor: "pointer" }}
            >
              Leave Balance
            </div>

            <div
              id="tabCalender"
              type="submit"
              className={`col-4 col-lg-4 col-md-4 col-sm-4 col-xs-4 smallResolutionText leave-balance-individual ${selectedTab === "onLeave" ? "active-leave" : ""}`}
              
              style={{ textAlign: 'center', cursor: "pointer" }}
            >
              On Leave
            </div> */}
          </>
        );

      default:
        return (
          <div
            id="tabCalender"
            type="submit"
            className={`smallResolutionText leave-balance-individual active-leave`}
            onClick={(e) => {
              openTab(e, "calendar");
            }}
            style={{ textAlign: "center", cursor: "pointer" }}
          >
            Calendar
          </div>
        );
    }
  };
  useEffect(() => {
    leaveApplyLoadName == "leave-details" && setSelectedTab("leaveDetails");
  }, [props.updateLnAData]);

  useEffect(() => {
    if (leaveApplyLoadName == "leave-notification-list") {
      setSelectedTab("leaveNotification");
    }
    if (leaveApplyLoadName == "leave-balance-list") {
      setSelectedTab("leaveBalance");
    }
    if (leaveApplyLoadName == "leave-details") {
      setSelectedTab("leaveDetails");
    }
  }, [leaveApplyLoadName]);

  return (
    <>
      {/* <div className='leave-balance-header'> */}
      {!showCalendarView && (
        <div>
          {" "}
          {/** leave-balance-tab */}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              borderBottom: "1px solid #ccc",
            }}
          >
            {renderTabsType(props.type)}
          </div>
        </div>
      )}
      <div
        style={{
          overflowX: "hidden",
          // height: allHeight.containerHeight,
          overflowY:
            selectedTab === "leaveBalance" &&
            props.type === "Transfer" &&
            selectedTab === "onLeave"
              ? "auto"
              : "hidden",
        }}
        id="leaveBalance"
        // className="tabcontent-leave"
      >
        <div
          style={{
            overflowX: "hidden",
            overflowY:
              selectedTab === "leaveBalance" &&
              props.type === "Transfer" &&
              selectedTab === "onLeave"
                ? "auto"
                : "hidden",
          }}
        >
          {!showCalendarView && selectedTab === "calendar" && (
            <Calendar userId={props.selectedUserId} type={props.type} />
          )}
          {!showCalendarView && selectedTab === "leaveBalance" && (
            <LeavesBalance
              getChildData={props.getChildData}
              showViewMode={props.showViewMode}
              type={props.type}
              user={props.selectedUserId}
            />
          )}
          {!showCalendarView && selectedTab === "approvalleaveBalance" && (
            <LeavesBalance
              type={"approvalsubmit"}
              user={props.selectedUserId}
            />
          )}
          {!showCalendarView && selectedTab === "onLeave" && (
            <OnLeave
              type={props.type}
              handleCalendarDisplay={handleCalendarDisplay}
              user={props.selectedUserId}
            />
          )}
          {!showCalendarView &&
            selectedTab === "leaveDetails" &&
            props.type === "Transfer" && (
              <LeavesTransfer
                getChildData={props.getChildData}
                showViewMode={props.showViewMode}
                type={props.type}
              />
            )}
          {!showCalendarView &&
            selectedTab === "leaveDetails" &&
            (props.type === "LeaveApply" || props.type === "COMPOFF") && (
              <LeaveDetails
                getChildData={props.getChildData}
                showViewMode={props.showViewMode}
                type={props.type}
                user={props.selectedUserId}
              />
            )}
          {!showCalendarView &&
            selectedTab === "leaveNotification" &&
            (props.type === "LeaveApply" || props.type === "COMPOFF") && (
              <LeaveNotifiersList
                type={"LeaveApply"}
                user={props.selectedUserId}
              />
            )}

          {!showCalendarView && selectedTab === "regularization" && (
            <RegularizationStatus
              getChildData={props.getChildData}
              showViewMode={props.showViewMode}
            />
          )}
        </div>
      </div>
      {showCalendarView && (
        <>
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div
              style={{
                backgroundColor: "#F2F2F2",
                height: "3rem",
                display: "flex",
                borderStartEndRadius: "0.4em",
                borderStartStartRadius: "0.4em",
                alignItems: "center",
                paddingLeft: "1rem",
                fontWeight: 600,
                width: "100%",
              }}
            >
              <div
                className="col-xs-12 col-sm-12 col-lg-12 col-md-12"
                style={{
                  color: "black",
                  fontStyle: "Circular Std Book",
                  fontSize: "1rem",
                  fontFamily: "Arial",
                }}
              >
                {openCalendarFor.teamMemberName}
              </div>
            </div>
          </div>

          <div
            className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
            style={{ overflowY: "auto", height: allHeight.calendarviewHeight }}
          >
            <Calendar
              userId={openCalendarFor.userId}
              type={openCalendarForOnLeave ? "onleavetab" : "leaveTabs"}
            />
          </div>
          <div className="topBorder" style={{}}>
            <div
              className="col-sm-12 col-md-12 col-lg-12 pb-lg pt-md"
              style={{
                display: "flex",
                justifyContent: "right",
                alignItems: "center",
                textAlign: "right",
                height: "60px",
                width: "100%",
                position: "absolute",
                bottom: "0",
                paddingRight: "10px",
              }}
            >
              <div>
                <Button
                  className="leave-cancel-apply-button"
                  style={{ width: "114px", marginRight: "10px" }}
                  variant="contained"
                  onClick={handleCloseButton}
                >
                  Close
                </Button>
              </div>
            </div>
          </div>
        </>
      )}

      {/* </div> */}
    </>
  );
};

const mapStateToProps = ({ lnareducer, meetingcategory }) => {
  const { leaveApplyLoadName, selectedTab } = lnareducer;
  const { categoryName } = meetingcategory;
  return { leaveApplyLoadName, selectedTab, categoryName };
};

export default connect(mapStateToProps, { updateLnAData })(LeaveTabs);
