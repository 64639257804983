import React, { Component } from 'react'
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { AccordionComponent } from "@syncfusion/ej2-react-navigations";
import NewDocuments from "../../document/newdocuments";
import addCandidateService from "../../../services/entryexit/addCandidateService";
import {
    EMPLOYEE_UPLOAD,
    NEW_CANDIDATE,
} from "../../../redux/constant/entryexit/candidateconstant";
import { updateDocumentInfo } from "../../../redux/actions/entryexit/onboardingaction";
import CandProfileUpdate from './candprofileupdate'
let RemoveAccordBorder = `
.e-accordion {
    border:none;
}
`
class DocChecklist extends Component {
    constructor(props) {
        super(props)
        this.state = {
            docInfo: [],
            refreshId: 0,
            changeStatus: false
        };
        this.tabRefreshed = [];
        this.count = 1;
        this.funcgetDocumentInformation = this.funcgetDocumentInformation.bind(this);
    }

    // TO get the default document structure and also files if uploaded
    // eslint-disable-next-line
    funcgetDocumentInformation(pCategoryId) {
        const { authUser, candidateType, empid, candidateid } = this.props;
        const { changeStatus, docInfo } = this.state;
        try {
            const data = {
                authUser,
                cand_emp_id: candidateType === EMPLOYEE_UPLOAD ? empid : candidateid,
                doccatid: pCategoryId,
                candtype: candidateType,
            };
            const documentDetails = addCandidateService.getDocuments(data);
            documentDetails
                .then((result) => {
                    if (pCategoryId > 0) {
                        // eslint-disable-next-line
                        docInfo.forEach((item) => {
                            if (item.CategoryId === pCategoryId) {
                                // eslint-disable-next-line
                                item.Data = result[0].Data;
                            }
                        });
                        this.setState({
                            docInfo: docInfo,
                            changeStatus: !changeStatus,
                        });
                    } else {
                        this.setState({ docInfo: result })
                    }
                })
                .catch((error) => {
                    // eslint-disable-next-line
                    console.log(error);
                });
        } catch (err) { }
    }
    async componentDidMount() {
        await this.funcgetDocumentInformation(0); // NOSONAR
    }
    expanded = (pCatgoryId) => {
        const { changeStatus } = this.state;
        if (this.tabRefreshed.indexOf(pCatgoryId) === -1) {
            this.tabRefreshed.push(pCatgoryId);
            this.setState({
                refreshId: pCatgoryId,
                changeStatus: !changeStatus,
            });
        }
    };
    onCreated = () => {
        const { docInfo } = this.state;
        setTimeout(() => {
            if (this.load !== null) {
                for (let i = 0; i <= this.load.items.length - 1; i += 1) {
                    this.load.items[i].expanded = true;
                }
                // eslint-disable-next-line
                this.setState({ loader: false });
            }
            for (let i = 0; i < docInfo.length; i += 1) {
                if (document.getElementById(`accr_${i}`) !== null) {
                    document.getElementById(
                        `accr_${i}`
                    ).ej2_instances[0].items[0].expanded = true;
                }
            }
        }, 1000);
    };
    onCreatedProfile = () => {
        /** setTimeout(() => {
            if (this.loadProfile !== null) {
                for (let i = 0; i <= this.load.items.length - 1; i += 1) {
                    this.loadProfile.items[i].expanded = true;
                }
            }
        }, 1000); */
        setTimeout(() => {
            if (this.loadProfile !== null) {
                this.loadProfile.items[0].expanded = true;
            }
        }, 1000);
    };
    render() {
        const { docInfo, changeStatus } = this.state;
        const { categoryName, candidateType, candidateid, imageName } = this.props;
        let preofferRestictedFlag = false;
        if (categoryName === "9" && candidateType === NEW_CANDIDATE)
            preofferRestictedFlag = true;

        return (
            <div className='col-sm-12 col-xs-12 col-lg-12 col-md-12'>
                <AccordionComponent
                    id={`accr_profile${++this.count}`}
                    // eslint-disable-next-line
                    ref={(accordion) => (this.loadProfile = accordion)}
                    created={this.onCreatedProfile.bind(this)}
                >
                    <CandProfileUpdate imageName={imageName} />
                </AccordionComponent>
                {
                    // eslint-disable-next-line
                    docInfo.length > 0 &&
                    docInfo.map((item, index) => {
                        if (item.CategoryId === 3 && preofferRestictedFlag) {
                            // eslint-disable-next-line
                            // Nothing to do here
                            return <></>
                        } else {
                            return (
                                <div className='col-sm-12 col-xs-12 col-lg-12 col-md-12' style={{ marginBottom: "0px" }} key={item.CategoryId}>
                                    <style>{RemoveAccordBorder}</style>
                                    {/* {
                                    index === 0 &&
                                    
                                    } */}
                                    <AccordionComponent
                                        id={`accr_${index}`}
                                        // eslint-disable-next-line
                                        ref={(accordion) => (this.load = accordion)}
                                        expanded={() => {
                                            this.expanded(item.CategoryId);
                                        }}
                                        // eslint-disable-next-line
                                        created={this.onCreated.bind(this)}
                                    >
                                        <NewDocuments
                                            key={`NewDocuments${index}`}
                                            header={item.CategoryName}
                                            id={item.CategoryId}
                                            content={item.Data}
                                            candidateType={candidateType}
                                            // eslint-disable-next-line
                                            pathName={this.props.location.pathname}
                                            candidateid={candidateid}
                                            funcgetDocumentInformation={this.funcgetDocumentInformation}
                                            // statusUpdate={this.funcstatusUpdate}
                                            // RefreshId={refreshId}
                                            changeStatus={changeStatus}
                                        />
                                    </AccordionComponent>
                                </div>
                            );
                        }
                    })
                }
            </div>
        )
    }
}

DocChecklist.propTypes = {
    authUser: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    candidateType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    categoryName: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    empid: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    candidateid: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    // updateDocumentInfo: PropTypes.func,
    send_links: PropTypes.arrayOf(PropTypes.object),
    // location: PropTypes.objectOf(PropTypes.object),
    docRejectionParams: PropTypes.arrayOf(PropTypes.object),
    openDocumentPopup: PropTypes.bool,
    open_document_preview: PropTypes.bool,
    openDocumentComments: PropTypes.bool,
    countryType: PropTypes.string,
    imageName: PropTypes.string
};
DocChecklist.defaultProps = {
    authUser: "",
    candidateType: "",
    categoryName: "",
    empid: 0,
    candidateid: 0,
    // updateDocumentInfo: {},
    send_links: [],
    // location: {},
    docRejectionParams: [],
    openDocumentPopup: false,
    open_document_preview: false,
    openDocumentComments: false,
    countryType: "",
    imageName: "",
};

const mapStateToProps = ({
    auth,
    onboardingreducer,
    router,
    meetingcategory,
}) => {
    const { authUser, initURL } = auth;
    const { location } = router;
    const { categoryName } = meetingcategory;
    const {
        openDocumentPopup,
        candidateid,
        // eslint-disable-next-line
        open_document_preview,
        // eslint-disable-next-line
        send_links,
        refreshDocStatus,
        // eslint-disable-next-line
        doc_status,
        empid,
        status,
        openDocumentComments,
        // eslint-disable-next-line
        refresh_send_link_status,
        docRejectionParams,
        baseCounty,
        countryType
    } = onboardingreducer;
    return {
        authUser,
        openDocumentPopup,
        candidateid,
        open_document_preview,
        send_links,
        refreshDocStatus,
        doc_status,
        empid,
        status,
        openDocumentComments,
        refresh_send_link_status,
        location,
        initURL,
        categoryName,
        docRejectionParams,
        baseCounty,
        countryType
    };
};
export default connect(mapStateToProps, {
    updateDocumentInfo,
    // updateDocumentStatus,
})(DocChecklist);
