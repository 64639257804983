/**
 * Author: Vivek Khodade.
 * Last Modify:08-May-2020
 * Modified By:Vivek Khodade
 */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { NumericTextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { ComboBoxComponent } from "@syncfusion/ej2-react-dropdowns";
import { connect } from "react-redux";
import Button from "@material-ui/core/Button";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import OnbordingServices from "../../../services/entryexit/onbordingServices";
import "../employeeprofiletabs.css";
import {
  savaCandidateSalary,
  addUpdateBasicInfo,
} from "../../../redux/actions/entryexit/onboardingaction";
import {
  ERROR,
  basicSalaryPersentageData,
  employeePFData,
  medicalReimbursementData,
  mealVouchersData,
  carAllowanceData,
  LTAData
} from "../../../assets/constants/constants";
import { getNotify, dencrypt } from "../../../app/other/commonfunction";

class Salary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mediclaimInsuranceData: [],
      designationData: [],
      totalCTC: 0,
      variableCTC: 0,
      otherAllowance: 0,
      conveyanceAllowance: 0,
      medicalInsurancePremium: 0,
      carAllowance: 0,
      designationLevel: 0,
      basicSalaryPersentage: "",
      employeePF: 0,
      medicalReimbursement: "",
      mealVouchers: "",
      mediclaimInsurance: 0,
      empId: "",
      onTargetEarning: 0,
      lta:0,
      grossPay:0,
      height:
        (window.innerHeight ||
          document.documentElement.clientHeight ||
          document.body.clientHeight) - 188,
      width:
        (window.innerWidth ||
          document.documentElement.clientWidth ||
          document.body.clientWidth) - 71,
    };
  }

  componentDidMount = () => {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
    const { search } = window.location;
    const params = new URLSearchParams(search);
    const empId = dencrypt(params.get("empId"));
    this.setState({
      empId,
    });
    this.getDesignationData(empId);
  };
  /**
* Calculate & Update state of new dimensions
*/
  updateDimensions() {
    let update_width = window.innerWidth - 100;
    let update_height = window.innerHeight - 338;
    this.setState({ width: update_width, height: update_height });
  }
  getEmployeeSalaryDetails = async (empId) => {
    try {
      const { authUser } = this.props;
      const employeeSalaryDetails = Promise.resolve(
        OnbordingServices.getEmployeeSalaryDetails(empId, authUser)
      );
      employeeSalaryDetails.then(async (result) => {
        if (result !== null && result.length > 0) {
          // setTimeout(()=>{
          this.setState({
            totalCTC: result[0].TotalCTC,
            variableCTC: result[0].VariableCTC,
            otherAllowance: result[0].OtherAllowance,
            conveyanceAllowance: result[0].ConveyanceAllowance,
            medicalInsurancePremium: result[0].MedicalInsurancePremium,
            carAllowance: result[0].CarAllowance,
            // eslint-disable-next-line
            designationLevel: parseInt(result[0].DesignationLevelId),
            // eslint-disable-next-line
            basicSalaryPersentage: result[0].BasicSalaryPercentage,
            // eslint-disable-next-line
            employeePF: parseInt(result[0].EmployeePF),
            // eslint-disable-next-line
            medicalReimbursement: parseInt(result[0].MedReimburse),
            // eslint-disable-next-line
            mealVouchers: parseInt(result[0].MealVoucher),
            // eslint-disable-next-line
            mediclaimInsurance: parseInt(result[0].MediclaimInsuranceId),
            // eslint-disable-next-line
            grossPay: parseInt(result[0].grossPay),
            // eslint-disable-next-line
            lta: parseInt(result[0].lta),
            onTargetEarning: parseInt(result[0].ote),
            // designationLevel: parseInt(
            //   result[0].DesignationLevelId.substring(
            //     result[0].DesignationLevelId.length - 1
            //   ),
            //   10
            // ),
            // basicSalaryPersentage: result[0].BasicSalaryPercentage,
            // employeePF: parseInt(
            //   result[0].EmployeePF.substring(result[0].EmployeePF.length - 1),
            //   10
            // ),
            // medicalReimbursement: parseInt(
            //   result[0].MedReimburse.substring(
            //     result[0].MedReimburse.length - 1
            //   ),
            //   10
            // ),
            // mealVouchers: parseInt(
            //   result[0].MealVoucher.substring(result[0].MealVoucher.length - 1),
            //   10
            // ),
            // mediclaimInsurance: parseInt(
            //   result[0].MediclaimInsuranceId.substring(
            //     result[0].MediclaimInsuranceId.length - 1
            //   ),
            //   10
            // ),
          });
          // eslint-disable-next-line
          this.props.addUpdateBasicInfo({ appointmentLetterFlag: true });
          // },1000)
        } else {
          // eslint-disable-next-line
          this.props.addUpdateBasicInfo({ appointmentLetterFlag: false });
        }
      });
    } catch (error) {
      // eslint-disable-next-line
    }
  };

  getDesignationData = (empId) => {
    try {
      const type = "SAL";
      const jobRole = null;
      const designationDropdownData = Promise.resolve(
        OnbordingServices.getMasterData(type, empId, jobRole)
      );
      designationDropdownData.then((result) => {
        if (result !== null) {
          const designationData = result.filter((item) => {
            return item.Type === "Designation Level";
          });
          const mediclaimInsuranceData = result.filter((item) => {
            return item.Type === "Mediclaim Insurance";
          });
          this.setState(
            {
              designationData,
              mediclaimInsuranceData,
            },
            () => this.getEmployeeSalaryDetails(empId)
          );
        }
      });
    } catch (error) {
      // eslint-disable-next-line
    }
  };

  onResetSalay = () => {
    this.setState({
      totalCTC: 0,
      variableCTC: 0,
      otherAllowance: 0,
      conveyanceAllowance: 0,
      medicalInsurancePremium: 0,
      designationLevel: "",
      basicSalaryPersentage: "",
      employeePF: "",
      medicalReimbursement: 0,
      mealVouchers: "",
      mediclaimInsurance: 598,
      carAllowance: 0,
      onTargetEarning: 0,
      lta:0,
      grossPay:0
    });
  };

  onSaveSalary = () => { // NOSONAR
    try {
      const {
        totalCTC,
        variableCTC,
        otherAllowance,
        conveyanceAllowance,
        medicalInsurancePremium,
        designationLevel,
        basicSalaryPersentage,
        employeePF,
        medicalReimbursement,
        carAllowance,
        mediclaimInsurance,
        mealVouchers,
        empId,
        onTargetEarning,
        grossPay,
        lta
      } = this.state;
      const { authUser } = this.props;
      if (designationLevel === "" || designationLevel===null || designationLevel===0) { // NOSONAR
        getNotify(ERROR, "Please select designation level");
        return;
      }
      if(grossPay < 0 || grossPay===null){
        getNotify(ERROR, "Please enter gross pay");
        return;
      }
      if (totalCTC < 1) {
        getNotify(ERROR, "Please enter total CTC");
        return;
      }
      if (variableCTC > totalCTC) {
        getNotify(ERROR, "Variable CTC should be less than or equal total CTC");
        return;
      }
      // else if (variableCTC === 0) {
      // getNotify(ERROR, 'Please Enter Variable CTC');
      // return;
      // }

      // else if (conveyanceAllowance === 0) {
      //   getNotify(ERROR, 'Please Enter Conveyance Allowance');
      //   return;
      // }      
      if (basicSalaryPersentage === "" || basicSalaryPersentage === null) {
        getNotify(ERROR, "Please select basic salary percentage");
        return;
      }
      if (employeePF === "") { // NOSONAR
        getNotify(ERROR, "Please select employee PF"); 
        return;
      }
      if (medicalReimbursement === "" || medicalReimbursement===null) {
        getNotify(ERROR, "Please enter medical reimbursement");
        return;
      }
      // else if (carAllowance === 0) {
      //   getNotify(ERROR, 'Please Enter Car Allowance');
      //   return;
      // }
      if (mealVouchers === "" || mealVouchers===null) {
        getNotify(ERROR, "Please select meal vouchers");
        return;
      }
      if (otherAllowance < 0) {
        getNotify(ERROR, "Please enter other allowance");
        return;
      }
      
      // eslint-disable-next-line
      this.props.savaCandidateSalary({
        designation_level_id: designationLevel,
        dec_total_ctc: totalCTC,
        dec_var_ctc: variableCTC === null ? 0 : variableCTC,
        medical_reimbursement: medicalReimbursement,
        meal_vaucher: mealVouchers,
        dec_other_allowance: otherAllowance,
        dec_car_allowance: carAllowance,
        dec_medical_insurance_premium:
          medicalInsurancePremium === null ? 0 : medicalInsurancePremium,
        dec_conveyance_allowance:
          conveyanceAllowance === null ? 0 : conveyanceAllowance,
        dec_basic_salary_perc: basicSalaryPersentage,
        employee_pf: employeePF,
        onTargetEarning: onTargetEarning,
        mediclaim_insurance: mediclaimInsurance,
        empid: empId,
        authUserid: authUser,
        grossPay:grossPay,
        lta:lta===null? 0 : lta
      });
      // eslint-disable-next-line
      this.props.addUpdateBasicInfo({ appointmentLetterFlag: true });
    } catch (error) {
      // eslint-disable-next-line
    }
  };

  render() { // NOSONAR
    const { basicInformation } = this.props;
    const {
      totalCTC,
      variableCTC,
      otherAllowance,
      conveyanceAllowance,
      medicalInsurancePremium,
      designationLevel,
      basicSalaryPersentage,
      employeePF,
      medicalReimbursement,
      carAllowance,
      mediclaimInsurance,
      mealVouchers,
      designationData,
      mediclaimInsuranceData,
      onTargetEarning,
      lta,
      grossPay
    } = this.state;
    var showOtherAllowance = false;
    if(basicInformation.Band === 'B1' || basicInformation.Band === 'B2' || basicInformation.Band === 'B3' || basicInformation.Band === 'Contract'){
      showOtherAllowance = true;
    }
    return (
      <div style={{ display: "block", height: this.state.height }}>
        <div className="control-section col-md-12">
          <div className="col-12 col-xs-12 col-sm-12 col-lg-12 col-md-12 row" >
            <div style={{ textAlign: "right", marginTop: 7, marginBottom: 7 }}>
              <div
                style={{
                  position: "absolute",
                  textAlign: "center",
                  marginLeft: "31.5%",
                  marginTop: "-0.5%",
                }}
              >
                <h5>
                  <span style={{ color: "#EB5465" }}>
                    Note: Please fill all the fields. The values in the field to be entered in annual format.
                  </span>
                </h5>
              </div>
              <Button
                color="primary"
                variant="contained"
                style={{
                  padding: "0px 0px 0px 0px",
                  margin: "3px 5px 0px 0px",
                }}
                onClick={() => this.onSaveSalary()}
              >
                <TooltipComponent
                          closeOn="Click"
                          content="Save"
                          position="BottomCenter"
                          isSticky={false}
                          cssClass='customtooltip'
                        >
                          <span style={{ fontSize: 14 }}>
                &nbsp;&nbsp;&nbsp;Save &nbsp;&nbsp;&nbsp;
                </span>
                </TooltipComponent>
              </Button>
              <Button
                className="select-all-button-deactive"
                style={{
                  padding: "0px 0px 0px 0px",
                  margin: "3px 5px 0px 0px",
                  border: "1px solid",
                }}
                onClick={() => this.onResetSalay()}
              >
                <TooltipComponent
                          closeOn="Click"
                          content="RESET"
                          position="BottomCenter"
                          isSticky={false}
                          cssClass='customtooltip'
                        >
                          <span style={{ fontSize: 14 }}>
                &nbsp;&nbsp;RESET&nbsp;&nbsp;
                </span>
                </TooltipComponent>
              </Button>
            </div>
          </div>
          {/* <div className="col-md-12 scroll-salary force-overflow"> */}
          <div className="col-md-12" style={{ height: this.state.height - 80, overflowY: 'auto', overflowX: 'hidden' }}>
            <div
              className="row card-layout"
              style={{
                paddingLeft: "20px",
                paddingRight: "30px",
                paddingTop: "10px",
              }}
            >
              <div className="col-xs-2 col-sm-2 col-lg-3 col-md-2  ">
                <span className="span" />
                <ComboBoxComponent
                  allowCustom={false}
                  fields={{ text: "Name", value: "Id" }}
                  id="designationlevel"
                  dataSource={designationData}
                  change={(e) =>{
                    if(e.itemData!==null){
                      this.setState({ designationLevel: e.itemData.Id })
                    }else{
                      this.setState({ designationLevel: e.itemData })
                    }
                  }
                  }
                  value={designationLevel}
                  // value={parseInt(designationLevel) === 0 ? '': parseInt(designationLevel)}
                  placeholder="Designation Level *"
                  cssClass="e-outline"
                  floatLabelType="Auto"
                />
                {/* <DropDownListComponent value={designationLevel} select={(e) => this.setState({ designationLevel: e.itemData.Id })} fields={{ text: 'Name', value: 'Id' }} id="designationlevel" dataSource={designationData} cssClass="e-outline" placeholder="Designation Level" /> */}
              </div>
              <div className="col-xs-2 col-sm-2 col-lg-3 col-md-2  ">
                <NumericTextBoxComponent
                  floatLabelType="Auto"
                  value={grossPay}
                  change={(e) => this.setState({ grossPay: e.value })}
                  min={0}
                  number
                  id="onGrossPay"
                  placeholder="Gross Pay *"
                  cssClass="e-outline"
                />
              </div>
              <div className="col-xs-2 col-sm-2 col-lg-3 col-md-2  ">
                <NumericTextBoxComponent
                  floatLabelType="Auto"
                  value={onTargetEarning}
                  change={(e) => this.setState({ onTargetEarning: e.value })}
                  min={0}
                  number
                  id="onTargetEarning"
                  placeholder="On Target Earning"
                  cssClass="e-outline"
                />
              </div>
              <div className="col-xs-2 col-sm-2 col-lg-3 col-md-2  ">
                <NumericTextBoxComponent
                  floatLabelType="Auto"
                  value={totalCTC}
                  change={(e) => this.setState({ totalCTC: e.value })}
                  min={0}
                  number
                  id="totalctc"
                  placeholder="Total CTC *"
                  cssClass="e-outline"
                />
              </div>
             
            </div>
            <div
              className="row card-layout"
              style={{
                paddingLeft: "20px",
                paddingRight: "30px",
                paddingTop: "20px",
              }}
            >
               <div className="col-xs-2 col-sm-2 col-lg-3 col-md-2  ">
                <NumericTextBoxComponent
                  floatLabelType="Auto"
                  value={variableCTC}
                  change={(e) => this.setState({ variableCTC: e.value })}
                  min={0}
                  number
                  id="variablectc"
                  placeholder="Variable CTC"
                  cssClass="e-outline"
                />
              </div>
              <div className="col-xs-2 col-sm-2 col-lg-3 col-md-2  ">
                <ComboBoxComponent
                  allowCustom={false}
                  floatLabelType="Auto"
                  value={basicSalaryPersentage}
                  change={(e) =>{
                    if(e.itemData!==null){
                      this.setState({ basicSalaryPersentage: e.itemData.Value })
                    }else{
                      this.setState({ basicSalaryPersentage: e.itemData })
                    }
                  }
                  }
                  fields={{ value: "Value", text: "Name" }}
                  id="basicsalary"
                  dataSource={basicSalaryPersentageData}
                  cssClass="e-outline"
                  placeholder="Basic Salary % *"
                />
              </div>
              
              <div className="col-xs-2 col-sm-2 col-lg-3 col-md-2  ">
                <ComboBoxComponent
                  allowCustom={false}
                  floatLabelType="Auto"
                  value={employeePF}
                  change={(e) =>{
                    if(e.itemData!==null){
                      this.setState({ employeePF: e.itemData.Value })
                    }else{
                      this.setState({ employeePF: e.itemData})
                    }
                  }
                  }
                  fields={{ text: "Name", value: "Value" }}
                  id="employeepf"
                  dataSource={employeePFData}
                  cssClass="e-outline"
                  placeholder="Employee PF *"
                />
              </div>             
              <div className="col-xs-2 col-sm-2 col-lg-3 col-md-2  ">
                <ComboBoxComponent
                  allowCustom={false}
                  floatLabelType="Auto"
                  value={medicalReimbursement}
                  change={(e) =>{
                    if(e.itemData!==null){
                      this.setState({ medicalReimbursement: e.itemData.Value })
                    }else{
                      this.setState({ medicalReimbursement: e.itemData })
                    }
                  }
                  }
                  fields={{ text: "Name", value: "Value" }}
                  id="medicalreimbursement"
                  dataSource={medicalReimbursementData}
                  cssClass="e-outline"
                  placeholder="Medical Reimbursement *"
                />
              </div>
            </div>
            <div
              className="row card-layout"
              style={{
                paddingLeft: "20px",
                paddingRight: "30px",
                paddingTop: "20px",
              }}
            >
              <div className="col-xs-2 col-sm-2 col-lg-3 col-md-2  ">
                <ComboBoxComponent
                  allowCustom={false}
                  id="mediclaiminsurance"
                  dataSource={mediclaimInsuranceData}
                  placeholder="Mediclaim Insurance"
                  change={(e) =>{
                    if(e.itemData!==null){
                      this.setState({ mediclaimInsurance: e.itemData.Id })
                    }else{
                      this.setState({ mediclaimInsurance: e.itemData })
                    }
                  }
                  }
                  fields={{ text: "Name", value: "Id" }}
                  value={mediclaimInsurance}
                  // value={parseInt(mediclaimInsurance) === 0 ? '' :parseInt(mediclaimInsurance)}
                  cssClass="e-outline"
                  floatLabelType="Auto"
                />
                {/* // <ComboBoxComponent
                  allowCustom={false} value={parseInt(mediclaimInsurance)} select={(e) => this.setState({ mediclaimInsurance: e.itemData.Value })} fields={{ text: 'Name', value: 'Id' }} id="mediclaiminsurance" dataSource={mediclaimInsuranceData} cssClass="e-outline" placeholder="Mediclaim Insurance" /> */}
              </div>
              <div className="col-xs-2 col-sm-2 col-lg-3 col-md-2  ">
                <NumericTextBoxComponent
                  floatLabelType="Auto"
                  value={medicalInsurancePremium}
                  change={(e) =>
                    this.setState({ medicalInsurancePremium: e.value })
                  }
                  min={0}
                  number
                  id="medicalinsurancepremium"
                  placeholder="Medical insurance Premium"
                  cssClass="e-outline"
                />
              </div>
              <div className="col-xs-2 col-sm-2 col-lg-3 col-md-2  ">
                <ComboBoxComponent
                  allowCustom={false}
                  floatLabelType="Auto"
                  value={mealVouchers}
                  change={(e) =>{
                    if(e.itemData!==null){
                      this.setState({ mealVouchers: e.itemData.Value })
                    }else{
                      this.setState({ mealVouchers: e.itemData })
                    }
                  }
                  }
                  fields={{ text: "Name", value: "Value" }}
                  id="mealvouchers"
                  dataSource={mealVouchersData}
                  cssClass="e-outline"
                  placeholder="Meal Vouchers*"
                />
              </div>  
              <div className="col-xs-2 col-sm-2 col-lg-3 col-md-2  ">
                <ComboBoxComponent
                  allowCustom={false}
                  floatLabelType="Auto"
                  value={carAllowance}
                  change={(e) =>{
                    if(e.itemData!==null){
                      this.setState({ carAllowance: e.itemData.Value })
                    }else{
                      this.setState({ carAllowance: e.itemData })
                    }
                  }
                  }
                  fields={{ text: "Value", value: "Value" }}
                  id="carallowance"
                  dataSource={carAllowanceData}
                  cssClass="e-outline"
                  placeholder="Car Allowance"
                />
              </div>           
            </div>
            <div
              className="row card-layout"
              style={{
                paddingLeft: "20px",
                paddingRight: "30px",
                paddingTop: "20px",
              }}
            >
              <div className="col-xs-2 col-sm-2 col-lg-3 col-md-2  ">
                <NumericTextBoxComponent
                  floatLabelType="Auto"
                  value={conveyanceAllowance}
                  change={(e) =>
                    this.setState({ conveyanceAllowance: e.value })
                  }
                  min={0}
                  number
                  id="conveyanceallowance"
                  placeholder="Conveyance Allowance"
                  cssClass="e-outline"
                />
              </div>
              {showOtherAllowance &&
                <div className="col-xs-2 col-sm-2 col-lg-3 col-md-2  ">
                <NumericTextBoxComponent
                  floatLabelType="Auto"
                  value={otherAllowance}
                  change={(e) => this.setState({ otherAllowance: e.value })}
                  min={0}
                  number
                  id="otherallowance"
                  placeholder="Other Allowance*"
                  cssClass="e-outline"
                />
              </div>
              }
              <div className="col-xs-2 col-sm-2 col-lg-3 col-md-2  ">
                <ComboBoxComponent
                  allowCustom={false}
                  value={lta}
                  floatLabelType="Auto"
                  change={(e) =>{
                    if(e.itemData!==null){
                      this.setState({ lta: e.itemData.Value })
                    }else{
                      this.setState({ lta: e.itemData })
                    }
                  }
                  }
                  fields={{ value: "Value", text: "Name" }}
                  id="lta"
                  dataSource={LTAData}
                  cssClass="e-outline"
                  placeholder="LTA % "
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Salary.propTypes = {
  authUser: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
Salary.defaultProps = {
  authUser: "",
};
const mapStateToProps = ({ auth, onboardingreducer }) => {
  const { authUser } = auth;
  const { basicInformation } = onboardingreducer;
  return { authUser, basicInformation };
};
export default connect(mapStateToProps, {
  savaCandidateSalary,
  addUpdateBasicInfo,
})(Salary);
