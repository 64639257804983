import React, { Component } from 'react';
import { ComboBoxComponent, AutoCompleteComponent } from "@syncfusion/ej2-react-dropdowns";
import { Button, Dialog } from "@material-ui/core";
import { FormValidator } from "@syncfusion/ej2-inputs";
import { connect } from "react-redux";
import { Query } from "@syncfusion/ej2-data";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  DetailRow,
  Inject,
  Page,
  Filter
} from "@syncfusion/ej2-react-grids";
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import {addNewOrgChatMasterData} from '../../../redux/actions/entryexit/orgchartaction'
import ErrorBoundary from "../../error/errorboundary";
import {
  DialogContent,
  DialogActions,
  DialogTitleTMLC
} from "../../customdialog/customdialog";
import { roleStatusData, orgChartLevelData } from '../../../assets/constants/constants'
import PrograssBar from '../../progressbar/progressbar'
import EmployeeNameTemplate from '../../separation/employeeNameTemplate'
import { LEAGAL_NAME_ORG_MASTER, getGridColumn, ACTIVE, ACTING_HEAD_ORG_MASTER } from '../../../app/other/gridcolumns'
import Services from '../../../services/services';
import OrgchartMastersOthers from './orgchartmastersothers'
import SeparationService from '../../../services/entryexit/separationServices'

import '../../../app/containers/tmlc/tmlc.css';
import '../resignationcomponent/resignationcomponent.css';
class OrgchartMasters extends Component {
  constructor(props) {
    super(props)
    this.state = {
      // orgChartLevel:{ text: 'Level 1', value: 'level1' },
      orgChartLevel:'level1',
      level: 'level1',
      orgChartLevelResponse: null,
      showLoader: false,
      gridHeight: (window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight) - 390,
      addNewPopup: false,
      varPnlFuncName: '',
      varLevel2Name: '',
      varCompanyName: '',
      varAbbrivation: '',
      varDomain: '',
      roleStatus: '',
      varLevel3Name:'',
      varSubPnlFuncName:'',
      varSubPnlFuncHead:'',
      level1Master:[],
      level2Master:[],
      varLevel4Name:'',
      level3Master:[],
      varBussPnlFunc:0,
      varBussSubPnlFunc:0,
      varBussSubPnlFuncHead:'',
      varLevel5Name:'',
      level4Master:[],
      varSubFnlFunc:0,
      varServiceDivisionName:'',
      varServiceDivisionHead:'',
      varFuncHead:'',
      employeeData:[]
    };
    this.Filter = {
      type: "Menu",
    };
    this.width = (window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth) - 100;
    this.check = { type: 'CheckBox' };
    this.getOrgChartMasterData = this.getOrgChartMasterData.bind(this);
    this.onQueryCellInfoPreJoining = this.onQueryCellInfoPreJoining.bind(this);
    this.employeeNameTemplate = this.employeeNameTemplate.bind(this);
  }
  updateDimensions = () => {
    let activeGridHeight = window.innerHeight - 258;
    this.setState({ gridHeight: activeGridHeight });
  }
  onQueryCellInfoPreJoining = (args) => {
    const { cell } = args;
    cell.setAttribute(
      "style",
      "font-size:14px !important; color:#4B4A52 !important; text-align: left !important; padding-left:18px !important"
    );
  };
  getOrgChartMasterData = (level, type) => {/**NOSONAR */
    // getOrgChartMasters
    try {
      this.setState({
        showLoader: true,
      })
      const orgChartMasterData = Promise.resolve(
        Services.getOrgChartMasters(
          level
        )
      );
      orgChartMasterData.then((result) => {
        if (result !== false) {
          // eslint-disable-next-line
          if(type==='levelData'){
            this.setState({
              orgChartLevelResponse: result.result,
              showLoader: false,            
            }, () => this.setHeaderCSS())
          }
          if(type==='level1Master'){
            this.setState({
              level1Master: result.result,
              showLoader: false
            }, () => this.setHeaderCSS())
          }
          if(type==='level2Master'){
            this.setState({
              level2Master: result.result,
              showLoader: false
            }, () => this.setHeaderCSS())
          }
          if(type==='level3Master'){
            this.setState({
              level3Master: result.result,
              showLoader: false
            }, () => this.setHeaderCSS())
          }
          if(type==='level4Master'){
            this.setState({
              level4Master: result.result,
              showLoader: false
            }, () => this.setHeaderCSS())
          }

        }
      });
    } catch (error) {
      // eslint-disable-next-line
    }
  }
  navigateToAddBasicInformation(data) {/**NOSONAR */

  }
  employeeNameTemplate(props) {
    return (
      <EmployeeNameTemplate
        props={props}
        data="test"
        navigateToAddBasicInformation={this.navigateToAddBasicInformation}
      />
    );
  }
  actingTemplate(props) {/**NOSONAR */
    if (props.isActingHeadAtLevel1 !== undefined) {
      return (
        <div style={{ textAlign: 'center' }}>{props.isActingHeadAtLevel1 === 1 ? 'Yes' : 'No'}</div>
      );
    }
    if (props.isActingHeadAtLevel2 !== undefined) {
      return (
        <div style={{ textAlign: 'center' }}>{props.isActingHeadAtLevel2 === 1 ? 'Yes' : 'No'}</div>
      );
    }
    if (props.isActingHeadAtLevel3 !== undefined) {
      return (
        <div style={{ textAlign: 'center' }}>{props.isActingHeadAtLevel3 === 1 ? 'Yes' : 'No'}</div>
      );
    }
    if (props.isActingHeadAtLevel4 !== undefined) {
      return (
        <div style={{ textAlign: 'center' }}>{props.isActingHeadAtLevel4 === 1 ? 'Yes' : 'No'}</div>
      );
    }
    if (props.isActingHeadAtLevel5 !== undefined) {
      return (
        <div style={{ textAlign: 'center' }}>{props.isActingHeadAtLevel5 === 1 ? 'Yes' : 'No'}</div>
      );
    }
    else {
      return (<div></div>)
    }
  }
  activeTemplate(props) {
    if (props.isActiveAtLevel2 !== undefined) {
      return (
        <div style={{ textAlign: 'center' }}>{props.isActiveAtLevel2 === 1 ? 'Yes' : 'No'}</div>
      );
    }
    if (props.isActiveAtLevel3 !== undefined) {
      return (
        <div style={{ textAlign: 'center' }}>{props.isActiveAtLevel3 === 1 ? 'Yes' : 'No'}</div>
      );
    }
    if (props.isActiveAtLevel4 !== undefined) {
      return (
        <div style={{ textAlign: 'center' }}>{props.isActiveAtLevel4 === 1 ? 'Yes' : 'No'}</div>
      );
    }
    if (props.isActiveAtLevel5 !== undefined) {
      return (
        <div style={{ textAlign: 'center' }}>{props.isActiveAtLevel5 === 1 ? 'Yes' : 'No'}</div>
      );
    }
    else {
      return (<div></div>)
    }
  }
  setHeaderCSS = () => {
    setTimeout(() => {
      const ele = document.getElementsByClassName("e-headercelldiv");
      for(let i in ele) {
        if(!isNaN(parseInt(i, 10))){
          let index = parseInt(i, 10);
          if (ele[index].innerText === ACTIVE || ele[index].innerText === ACTING_HEAD_ORG_MASTER) {
            ele[index].classList.add("grid-center-header");
          } else {
            ele[index].classList.add("grid-left-header");
          }
        }
      }
    }, 500);
  }
    // Author Get Active List Employee data 
    getEmployeeData = () => {
      try {
          this.setState({showLoader:true})
          const { authUser } = this.props;
          const param = {
          authUser:authUser,
          type:"ActiveEmp",
          empId: 0
          }
          const employeeListData = Promise.resolve(
          SeparationService.getActiveLeftEmpList(param)
          );
          employeeListData.then((result) => {
          if (result !== null) {
              this.setState({
              employeeData: result.result,
              showLoader:false
              })
          }
          });
      } catch (error) {
          // eslint-disable-next-line
          console.error(error);
      }
  }
  componentDidMount = () => {
    this.updateDimensions();
    this.getOrgChartMasterData('level1','levelData');
    window.addEventListener("resize", this.updateDimensions);
    this.setHeaderCSS()
  };
  filterEmployee = (e) => {
    const { othersCheckAvailablity } = this.props;
    try {
      if (othersCheckAvailablity !== null) {
        let query = new Query();
        query =
          e.text !== ""
            ? query.where("name", "contains", e.text, true)
            : query;
        e.updateData(othersCheckAvailablity, query);
      } else {
        this.data = "error";
      }
    } catch (error) {
      console.log(error);
    }
  };
  addNewOrgChartMasterDetails = () => {
    const { level, varLevel2Name, varPnlFuncName, varGroupHead, varFuncHead, varCompanyName, varAbbrivation, varDomain, roleStatus, 
            varLevel3Name, varSubPnlFuncName, varPnlFuncHead, varSubPnlFuncHead, varLevel4Name, varBussSubPnlFunc, varBussSubPnlFuncHead, 
            // varLevel5Name, varServiceDivisionName, varSubFnlFunc, varServiceDivisionHead
          }=this.state;
    const {authUser}=this.props;
    let data={};
    if(level==='level2'){
      let options = {
        rules: {
          varLevel2NameErr: {
            required: [true, "Please enter leval name"],
            regex: ["^[A-z]+$", "Please enter valid level name"],
          },
          varPnlFuncNameErr: {
            required: [true, "Please enter business unit/function name"],
            regex: ["^[A-z]+$", "Please enter valid business unit/function name"],
          },
          varFuncHeadErr: {
            required: [true, "Please select business unit head"],
          },
          varGroupHeadErr: {
            required: [true, "Please select group head"],
          },
          varCompanyNameErr: {
            required: [true, "Please enter company name"],
            regex: ["^[A-z]+$", "Please enter valid company name"],
          },
          varAbbrivationErr: {
            required: [true, "Please enter abbrivation "],
            regex: ["^[A-z]+$", "Please enter valid abbrivation "],
          },
          varDomainErr: {
            required: [true, "Please enter domin name eg:@cheersin.com"],
            // regex: ["^[A-z]+$", "Please enter group name"],
          },
          varRoleStatusErr: {
            required: [true, "Please select role status"],
            // regex: ["^[A-z]+$", "Please enter group name"],
          },
        },
      }
      const updateOrgChartMasterForm = new FormValidator("#addOrgChartLevel2Details", options);
      if (updateOrgChartMasterForm.validate()) {
        data={
          'type':level,
          'authUser':authUser,
          'levalName':varLevel2Name,
          'varPnlFuncName':varPnlFuncName,
          'varGroupHead':varGroupHead,
          'varFuncHead':varFuncHead,
          'varCompanyName':varCompanyName,
          'varAbbrivation':varAbbrivation,
          'varDomain':varDomain,
          'roleStatus':roleStatus
        }
        this.props.addNewOrgChatMasterData(data);
        this.closeAddNewPopup();
      }
    }
    if(level==='level3'){
      let options = {
        rules: {
          varLevel3NameErr: {
            required: [true, "Please enter level name"],
            regex: ["^[A-z]+$", "Please enter group name"],
          },
          varSubPnlFuncNameErr: {
            required: [true, "Please enter sub pnl function name"],
            regex: ["^[A-z]+$", "Please enter valid sub pnl function name"],
          },
          varPnlFuncHeadErr: {
            required: [true, "Please select pnl function"],
          },
          varSubPnlFuncHeadErr: {
            required: [true, "Please pnl function head"],
          },
          varRoleStatusErr: {
            required: [true, "Please select role status"],
          },
        },
      }
      const updateOrgChartMasterForm = new FormValidator("#addOrgChartLevel3Details", options);
      if (updateOrgChartMasterForm.validate()) {
      data={
        'type':level,
        'authUser':authUser,
        'levalName':varLevel3Name,
        'varSubPnlFuncName':varSubPnlFuncName,
        'varPnlFuncHead':varPnlFuncHead,
        'varSubPnlFuncHead':varSubPnlFuncHead,
        'roleStatus':roleStatus,
      }
        this.props.addNewOrgChatMasterData(data);
        this.closeAddNewPopup();
      }
    }
    if(level==='level4'){
      let options = {
        rules: {
          varLevel4NameErr: {
            required: [true, "Please enter level name"],
            regex: ["^[A-z]+$", "Please enter valid name"],
          },
          varSubPnlFuncNameErr: {
            required: [true, "Please enter sub pnl name"],
            regex: ["^[A-z]+$", "Please enter valid sub pnl name"],
          },
          varBussSubPnlFuncErr: {
            required: [true, "Please select business business pnl function"],
          },
          varBussSubPnlFuncHeadErr: {
            required: [true, "Please select business sub pnl function head"],
          },
          varRoleStatusErr: {
            required: [true, "Please select role status"],
          },
        },
      }
      const updateOrgChartMasterForm = new FormValidator("#addOrgChartLevel4Details", options);
      if (updateOrgChartMasterForm.validate()) {
        data = {
          'type':level,
          'authUser':authUser,
          'levalName': varLevel4Name,
          'varSubPnlFuncName': varSubPnlFuncName,
          'varBussSubPnlFunc': varBussSubPnlFunc,
          'varBussSubPnlFuncHead': varBussSubPnlFuncHead,
          'roleStatus': roleStatus,
        }
        this.props.addNewOrgChatMasterData(data);
        this.closeAddNewPopup();
      }
    }
    if(level==='level5'){
      let options = {
        rules: {
          varLevel5NameErr: {
            required: [true, "Please enter level name"],
            regex: ["^[A-z]+$", "Please enter valid level name"],
          },
          varServiceDivisionNameErr: {
            required: [true, "Please enter practice area / sales unit / sub function name"],
            regex: ["^[A-z]+$", "Please enter valid practice area / sales unit / sub function name"],
          },
          varSubFnlFuncErr: {
            required: [true, "Please select sub pnl function"],
          },
          varServiceDivisionHeadErr: {
            required: [true, "Please select pnl function head"],
          },
          varRoleStatusErr: {
            required: [true, "Please select role status"],
          },
        },
      }
      const updateOrgChartMasterForm = new FormValidator("#addOrgChartLevel4Details", options);
      if (updateOrgChartMasterForm.validate()) {
        this.closeAddNewPopup();
      }
    }
    
  }
  closeAddNewPopup = () => {
    this.setState({
      addNewPopup: false,
      varPnlFuncName: '',
      varLevel2Name: '',
      varCompanyName: '',
      varAbbrivation: '',
      varDomain: '',
      roleStatus: '',
      varLevel3Name:'',
      varSubPnlFuncName:'',
      varSubPnlFuncHead:'',
      level1Master:[],
      level2Master:[],
      varLevel4Name:'',
      level3Master:[],
      varBussPnlFunc:0,
      varBussSubPnlFuncHead:0,
      varBussSubPnlFunc:0,
      varLevel5Name:'',
      level4Master:[],
      varSubFnlFunc:0,
      varServiceDivisionName:'',
      varServiceDivisionHead:'',
      varFuncHead:'',
      varGroupHead:''
    })
  }
  render() {/**NOSONAR */
    const { orgChartLevel, orgChartLevelResponse, gridHeight, showLoader, level, addNewPopup, varPnlFuncName, 
      varLevel2Name, varCompanyName, varAbbrivation, varDomain, varGroupHead, varPnlFuncHead, roleStatus, varLevel3Name,
      varSubPnlFuncName,varSubPnlFuncHead, level2Master,varLevel4Name, level3Master,  varBussSubPnlFunc, varBussSubPnlFuncHead, varLevel5Name, level4Master,
      varSubFnlFunc, varServiceDivisionName, varServiceDivisionHead, level1Master, varFuncHead, employeeData
    } = this.state;
    const { othersCheckAvailablity }=this.props;
    var widthColumnConfirm = 0;
    var finalWidthConfirm = 0;
    if ((level === "level1" || level === 'level2' || level === 'level3' || level === 'level4' || level === 'level5') && orgChartLevelResponse !== null) {
      // eslint-disable-next-line
      getGridColumn(level).forEach((data) => {
        widthColumnConfirm = widthColumnConfirm + 20 + data.width;
      });
      if (widthColumnConfirm <= this.width) {
        finalWidthConfirm = widthColumnConfirm;
      } else {
        finalWidthConfirm = this.width
      }
    }
    return (
      <div className='control-pane'>
        <div className="col-lg-12 control-section">
          <div className="row">
            <div className='col-lg-2 col-sx-2 col-sm-2' style={{ marginLeft: 0, paddingLeft: 0 }}>
              <ComboBoxComponent
                allowCustom={false}
                fields={{ text: "Name", value: "Id" }}
                id="designationlevel"
                dataSource={orgChartLevelData}
                value={orgChartLevel}
                change={(e) => {
                  if (e.itemData === null) {
                    this.setState({ level: '', orgChartLevelResponse: null });
                  } else {
                    if(e.itemData.value==='masterothers'){
                      this.getEmployeeData();
                      this.setState({ level: e.itemData.value, orgChartLevelResponse: null });
                    }else{
                      this.getOrgChartMasterData(e.itemData.value,'levelData');
                      this.setState({ level: e.itemData.value, orgChartLevelResponse: null });
                    }
                  }
                }}
                placeholder="Select Level *"
                cssClass="e-outline"
                floatLabelType="Auto"
              />
            </div>
            <div className='col-lg-2 col-sx-2 col-sm-2' style={{ paddingLeft: 0, marginLeft: 0 }}>
              {
              ( level === 'level2' || level === 'level3' || level === 'level4' || level === 'level5') &&
              <Button
                className="text-light ml-sm " variant="contained" color='primary' title='SUBMIT' size='medium'
                onClick={() => this.setState({
                  addNewPopup: true
                },()=>{
                    if(level==='level2'){
                        this.getOrgChartMasterData('level1','level1Master')
                    }
                    if(level==='level3'){
                        this.getOrgChartMasterData('level2','level2Master')
                    }
                    if(level==='level4'){
                        this.getOrgChartMasterData('level3','level3Master')
                    }
                    if(level==='level5'){
                        this.getOrgChartMasterData('level4','level4Master')
                    }
                    if(level==='masterothers'){
                        this.getEmployeeData()
                    }
                })}
              >
                Add New
              </Button>
              }
            </div>
          </div>
          {
            (level === 'level1' || level === 'level2' || level === 'level3' || level === 'level4' || level === 'level5') && showLoader === false &&
            <div className="row">
              <div style={{ paddingTop: "10px" }}
                className="control-section"
              >
                <GridComponent
                  className="grid-head-custome"
                  id="overviewgrid"
                  allowPaging
                  allowFiltering
                  filterSettings={this.Filter}
                  rowHeight={30}
                  height={gridHeight}
                  queryCellInfo={this.onQueryCellInfoPreJoining}
                  pageSettings={{
                    pageSize:
                      (window.innerHeight ||
                        document.documentElement.clientHeight ||
                        document.body.clientHeight) > 767
                        ? 12
                        : 6,
                    pageCount: 5
                  }}
                  dataSource={orgChartLevelResponse}
                  allowTextWrap={true}
                  width={`${finalWidthConfirm}`}
                >
                  <ColumnsDirective>
                    {getGridColumn(level).map((item) => {
                      switch (item.colName) {
                        case LEAGAL_NAME_ORG_MASTER:
                          return (
                            <ColumnDirective
                              key={item.field}
                              template={this.employeeNameTemplate}
                              textAlign={`${item.align} !important`}
                              field={item.field}
                              headerText={item.colName}
                              width={item.width}
                              filter={this.Filter}
                            />
                          );
                        case ACTIVE:
                          return (
                            <ColumnDirective
                              key={item.field}
                              template={this.activeTemplate}
                              textAlign={`${item.align} !important`}
                              field={item.field}
                              headerText={item.colName}
                              width={item.width}
                              filter={this.Filter}
                            />
                          );
                        case ACTING_HEAD_ORG_MASTER:
                          return (
                            <ColumnDirective
                              key={item.field}
                              template={this.actingTemplate}
                              textAlign={`${item.align} !important`}
                              field={item.field}
                              headerText={item.colName}
                              width={item.width}
                              filter={this.Filter}
                            />
                          );
                        default:
                          return (
                            <ColumnDirective
                              key={item.field}
                              textAlign={`${item.align} !important`}
                              field={item.field}
                              headerText={item.colName}
                              width={item.width}
                              filter={this.check}
                            />
                          );
                      }
                    })}
                  </ColumnsDirective>
                  <Inject services={[Page, DetailRow, Filter]} />
                </GridComponent>
              </div>
            </div>
          }
          {
            (level === 'masterothers' && employeeData.length > 0) && showLoader === false &&
                <OrgchartMastersOthers employeeData={employeeData}/>
          }
          {
            showLoader &&
            <div className='progress-bar-div'>
              <PrograssBar />
            </div>
          }
        </div>
        <div style={{ width: "350px" }}>
          <Dialog
            aria-labelledby="uploading-title"
            open={addNewPopup}
            fullWidth
            width="1000"
          >
            <DialogTitleTMLC id="uploading-title">
              Add New At {level}
                <div style={{ float: "right" }}>
                <IconButton style={{ color: "white", marginRight: "-5px", marginTop: "-7px" }} aria-label="close" onClick={this.closeAddNewPopup}>
                  <CloseIcon />
                </IconButton>
              </div>
            </DialogTitleTMLC>
            <ErrorBoundary>
              <DialogContent dividers>
                {
                  level === 'level2' &&
                  <div className='control-pane col-lg-12 control-section'>
                    <form id="addOrgChartLevel2Details" method="post" className="control-section col-md-12">
                        <div className='col-lg-12 col-sm-2 col-xs-12 col-md-2'>
                        <div className='row'>
                        <div className="col-xs-2 col-sm-2 col-lg-4 col-md-2  ">
                            <div>
                              <TextBoxComponent
                                id="varLevel2Name"
                                placeholder="Level Name*"
                                cssClass="e-outline"
                                floatLabelType="Auto"
                                data-msg-containerid="varLevel2NameErr"
                                name="varLevel2NameErr"
                                value={varLevel2Name}
                                change={(e) => {
                                  let options = {
                                    rules: {
                                      varLevel2NameErr: {
                                        required: [true, "Please enter leval name"],
                                        regex: ["^[A-z]+$", "Please enter valid level name"],
                                      },
                                    },
                                  }
                                  const updateOrgChartMasterForm = new FormValidator("#addOrgChartLevel2Details", options);
                                  if (updateOrgChartMasterForm.validate()) {
                                    this.setState({
                                      varLevel2Name: e.value
                                    })
                                  }
                                }}
                              />
                              <div
                                id="varLevel2NameErr"
                                className="e-input-group e-float-icon-left error-div"
                              />
                            </div>
                          </div>
                          <div className="col-xs-2 col-sm-2 col-lg-4 col-md-2  ">
                            <div>
                              <TextBoxComponent
                                id="varPnlFuncName"
                                placeholder="Business Unit/ Function*"
                                cssClass="e-outline"
                                floatLabelType="Auto"
                                data-msg-containerid="varPnlFuncNameErr"
                                name="varPnlFuncNameErr"
                                value={varPnlFuncName}
                                change={(e) => {
                                  let options = {
                                    rules: {
                                      varPnlFuncNameErr: {
                                        required: [true, "Please enter business unit/function name"],
                                        regex: ["^[A-z]+$", "Please enter valid business unit/function name"],
                                      },
                                    },
                                  }
                                  const updateOrgChartMasterForm = new FormValidator("#addOrgChartLevel2Details", options);
                                  if (updateOrgChartMasterForm.validate()) {
                                    this.setState({
                                      varPnlFuncName: e.value
                                    })
                                  }
                                }}
                              />
                              <div
                                id="varPnlFuncNameErr"
                                className="e-input-group e-float-icon-left error-div"
                              />
                            </div>
                          </div>
                          <div className="col-xs-2 col-sm-2 col-lg-4 col-md-2  ">
                            <div>
                              <AutoCompleteComponent
                                id="varFuncHead"
                                fields={{ text: "name", value: "UserId" }}
                                filtering={(e) => this.filterEmployee(e)}
                                dataSource={othersCheckAvailablity}
                                placeholder="Bussiness Unit Head*"
                                popupHeight="220px"
                                cssClass="e-outline"
                                floatLabelType="Auto"
                                data-msg-containerid="varFuncHeadErr"
                                name="varFuncHeadErr"
                                value={varFuncHead}
                                change={(e) => {
                                  let options = {
                                    rules: {
                                      varFuncHeadErr: {
                                        required: [true, "Please select business unit head"],
                                      },
                                    },
                                  }
                                  const updateOrgChartMasterForm = new FormValidator("#addOrgChartLevel2Details", options);
                                  if (updateOrgChartMasterForm.validate()) {
                                  this.setState({varFuncHead:e.value})
                                  }
                                }}
                              />
                              <div
                                id="varFuncHeadErr"
                                className="e-input-group e-float-icon-left error-div"
                              />
                            </div>
                          </div>
                          </div>
                          <div className='row'>
                          <div className="col-xs-2 col-sm-2 col-lg-4 col-md-2  ">
                            <div>
                              <ComboBoxComponent
                                id="varGroupHead"
                                fields={{ text: "EmployeeName", value: "groupHead" }}
                                // filtering={(e) => this.filterEmployee(e)}
                                dataSource={level1Master}
                                placeholder="Group Head*"
                                popupHeight="220px"
                                cssClass="e-outline"
                                floatLabelType="Auto"
                                data-msg-containerid="varGroupHeadErr"
                                name="varGroupHeadErr"
                                value={varGroupHead}
                                change={(e) => {
                                  let options = {
                                    rules: {
                                      varGroupHeadErr: {
                                        required: [true, "Please select group head"],
                                      },
                                    },
                                  }
                                  const updateOrgChartMasterForm = new FormValidator("#addOrgChartLevel2Details", options);
                                  if (updateOrgChartMasterForm.validate()) {
                                    this.setState({varGroupHead:e.value})}
                                  }
                                }
                              />
                              <div
                                id="varGroupHeadErr"
                                className="e-input-group e-float-icon-left error-div"
                              />
                            </div>
                          </div>
                          <div className="col-xs-2 col-sm-2 col-lg-4 col-md-2  ">
                            <div>
                              <TextBoxComponent
                                id="varCompanyName"
                                placeholder="Company Name*"
                                cssClass="e-outline"
                                floatLabelType="Auto"
                                data-msg-containerid="varCompanyNameErr"
                                name="varCompanyNameErr"
                                value={varCompanyName}
                                change={(e) => {
                                  let options = {
                                    rules: {
                                      varCompanyNameErr: {
                                        required: [true, "Please enter company name"],
                                        regex: ["^[A-z]+$", "Please enter valid company name"],
                                      },
                                    },
                                  }
                                  const updateOrgChartMasterForm = new FormValidator("#addOrgChartLevel2Details", options);
                                  if (updateOrgChartMasterForm.validate()) {
                                    this.setState({
                                      varCompanyName: e.value
                                    })
                                  }
                                }}
                              />
                              <div
                                id="varCompanyNameErr"
                                className="e-input-group e-float-icon-left error-div"
                              />
                            </div>
                          </div>
                          <div className="col-xs-2 col-sm-2 col-lg-4 col-md-2  ">
                            <div>
                              <TextBoxComponent
                                id="varAbbrivation"
                                placeholder="Abbrivation *"
                                cssClass="e-outline"
                                floatLabelType="Auto"
                                data-msg-containerid="varAbbrivationErr"
                                name="varAbbrivationErr"
                                value={varAbbrivation}
                                change={(e) => {
                                  let options = {
                                    rules: {
                                      varAbbrivationErr: {
                                        required: [true, "Please enter abbrivation "],
                                        regex: ["^[A-z]+$", "Please enter valid abbrivation "],
                                      },
                                    },
                                  }
                                  const updateOrgChartMasterForm = new FormValidator("#addOrgChartLevel2Details", options);
                                  if (updateOrgChartMasterForm.validate()) {
                                    this.setState({
                                      varAbbrivation: e.value
                                    })
                                  }
                                }}
                              />
                              <div
                                id="varAbbrivationErr"
                                className="e-input-group e-float-icon-left error-div"
                              />
                            </div>
                          </div>
                          </div>

                          <div className='row'>  
                          <div className="col-xs-2 col-sm-2 col-lg-4 col-md-2  ">
                            <div>
                              <TextBoxComponent
                                id="varDomain"
                                placeholder="Domain*"
                                cssClass="e-outline"
                                floatLabelType="Auto"
                                data-msg-containerid="varDomainErr"
                                name="varDomainErr"
                                value={varDomain}
                                change={(e) => {
                                  let options = {
                                    rules: {
                                      varDomainErr: {
                                        required: [true, "Please enter domin name eg:@cheersin.com"],
                                        // regex: ["^[A-z]+$", "Please enter group name"],
                                      },
                                    },
                                  }
                                  const updateOrgChartMasterForm = new FormValidator("#addOrgChartLevel2Details", options);
                                  if (updateOrgChartMasterForm.validate()) {
                                    this.setState({
                                      varDomain: e.value
                                    })
                                  }
                                }}
                              />
                              <div
                                id="varDomainErr"
                                className="e-input-group e-float-icon-left error-div"
                              />
                            </div>
                          </div>
                          <div className="col-xs-2 col-sm-2 col-lg-4 col-md-2  ">
                            <div>
                            <ComboBoxComponent
                                            id="varRoleStatus"
                                            dataSource={roleStatusData}
                                            fields={{ text: "Name", value: "Id" }}
                                            value={roleStatus}
                                            data-msg-containerid="varRoleStatusErr"
                                            name="varRoleStatusErr"
                                            allowFiltering
                                            allowCustom={false}
                                            floatLabelType="Auto"
                                            cssClass="e-outline"
                                            placeholder="Role Staus*"
                                            popupHeight="350px"
                                            change={(e) =>{
                                              let options = {
                                                rules: {
                                                  varRoleStatusErr: {
                                                    required: [true, "Please select role status"],
                                                    // regex: ["^[A-z]+$", "Please enter group name"],
                                                  },
                                                },
                                              }
                                              if(e.itemData===null){
                                                const updateOrgChartMasterForm = new FormValidator("#addOrgChartLevel2Details", options);
                                                if (updateOrgChartMasterForm.validate()) {
                                                this.setState({roleStatus:""})
                                                }
                                              }else{
                                                const updateOrgChartMasterForm = new FormValidator("#addOrgChartLevel2Details", options);
                                                if (updateOrgChartMasterForm.validate()) {
                                                this.setState({roleStatus:e.itemData.Id})
                                                }
                                              }
                                            }}
                              />
                              <div
                                id="varRoleStatusErr"
                                className="e-input-group e-float-icon-left error-div"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                }
                {
                  level === "level3" &&

                  <div className='control-pane col-lg-12 control-section'>
                    <form id="addOrgChartLevel3Details" method="post" className="control-section col-md-12">
                      <div className='row'>
                      <div className="col-xs-2 col-sm-2 col-lg-4 col-md-2  ">
                            <div>
                              <TextBoxComponent
                                id="varLevel3Name"
                                placeholder="Level Name*"
                                cssClass="e-outline"
                                floatLabelType="Auto"
                                data-msg-containerid="varLevel3NameErr"
                                name="varLevel3NameErr"
                                value={varLevel3Name}
                                change={(e) => {
                                  let options = {
                                    rules: {
                                      varLevel3NameErr: {
                                        required: [true, "Please enter level name"],
                                        regex: ["^[A-z]+$", "Please enter group name"],
                                      },
                                    },
                                  }
                                  const updateOrgChartMasterForm = new FormValidator("#addOrgChartLevel3Details", options);
                                  if (updateOrgChartMasterForm.validate()) {
                                    this.setState({
                                      varLevel3Name: e.value
                                    })
                                  }
                                }}
                              />
                              <div
                                id="varLevel3NameErr"
                                className="e-input-group e-float-icon-left error-div"
                              />
                            </div>
                          </div>
                      <div className="col-xs-2 col-sm-2 col-lg-4 col-md-2  ">
                            <div>
                              <TextBoxComponent
                                id="varSubPnlFuncName"
                                placeholder="Sub Pnl Function Name*"
                                cssClass="e-outline"
                                floatLabelType="Auto"
                                data-msg-containerid="varSubPnlFuncNameErr"
                                name="varSubPnlFuncNameErr"
                                value={varSubPnlFuncName}
                                change={(e) => {
                                  let options = {
                                    rules: {
                                      varSubPnlFuncNameErr: {
                                        required: [true, "Please enter sub pnl function name"],
                                        regex: ["^[A-z]+$", "Please enter valid sub pnl function name"],
                                      },
                                    },
                                  }
                                  const updateOrgChartMasterForm = new FormValidator("#addOrgChartLevel3Details", options);
                                  if (updateOrgChartMasterForm.validate()) {
                                    this.setState({
                                      varSubPnlFuncName: e.value
                                    })
                                  }
                                }}
                              />
                              <div
                                id="varSubPnlFuncNameErr"
                                className="e-input-group e-float-icon-left error-div"
                              />
                            </div>
                          </div>
                          <div className="col-xs-2 col-sm-2 col-lg-4 col-md-2  ">
                            <div>
                            <ComboBoxComponent
                                            id="varPnlFuncHead"
                                            dataSource={level2Master}
                                            fields={{ text: "pnlFuncName", value: "bussPnalFuncId" }}
                                            value={varPnlFuncHead}
                                            data-msg-containerid="varPnlFuncHeadErr"
                                            name="varPnlFuncHeadErr"
                                            allowFiltering
                                            allowCustom={false}
                                            floatLabelType="Auto"
                                            cssClass="e-outline"
                                            placeholder="Pnl Function*"
                                            popupHeight="350px"
                                            change={(e) =>{
                                              let options = {
                                              rules: {
                                                varPnlFuncHeadErr: {
                                                  required: [true, "Please select pnl function"],
                                                },
                                              },
                                            }
                                              if(e.itemData===null){
                                                  const updateOrgChartMasterForm = new FormValidator("#addOrgChartLevel3Details", options);
                                                  if (updateOrgChartMasterForm.validate()) {
                                                      this.setState({varPnlFuncHead:""})
                                                  }
                                              }else{
                                                  const updateOrgChartMasterForm = new FormValidator("#addOrgChartLevel3Details", options);
                                                  if (updateOrgChartMasterForm.validate()) {
                                                      this.setState({varPnlFuncHead:e.itemData.bussPnalFuncId})
                                                  }
                                              }
                                            }}
                              />
                              <div
                                id="varPnlFuncHeadErr"
                                className="e-input-group e-float-icon-left error-div"
                              />
                            </div>
                          </div>
                          </div>
                          <div className='row'>  
                          <div className="col-xs-2 col-sm-2 col-lg-4 col-md-2  ">
                            <AutoCompleteComponent
                              id="varSubPnlFuncHead"
                              fields={{ text: "name", value: "UserId" }}
                              dataSource={othersCheckAvailablity}
                              filtering={(e) => this.filterEmployee(e)}
                              placeholder="PnL Function Head *"
                              popupHeight="220px"
                              cssClass="e-outline"
                              floatLabelType="Auto"
                              data-msg-containerid="varSubPnlFuncHeadErr"
                              name="varSubPnlFuncHeadErr"
                              value={varSubPnlFuncHead}
                              change={(e) => {
                                let options = {
                                  rules: {
                                    varSubPnlFuncHeadErr: {
                                      required: [true, "Please pnl function head"],
                                    },
                                  },
                                }
                                const updateOrgChartMasterForm = new FormValidator("#addOrgChartLevel3Details", options);
                                if (updateOrgChartMasterForm.validate()) {
                                  this.setState({varSubPnlFuncHead:e.value})
                                }
                              }}
                            />
                            <div
                              id="varSubPnlFuncHeadErr"
                              className="e-input-group e-float-icon-left error-div"
                            />
                        </div>
                          <div className="col-xs-2 col-sm-2 col-lg-4 col-md-2  ">
                            <div>
                            <ComboBoxComponent
                                            id="varRoleStatus"
                                            dataSource={roleStatusData}
                                            fields={{ text: "Name", value: "Id" }}
                                            value={roleStatus}
                                            data-msg-containerid="varRoleStatusErr"
                                            name="varRoleStatusErr"
                                            allowFiltering
                                            allowCustom={false}
                                            floatLabelType="Auto"
                                            cssClass="e-outline"
                                            placeholder="Role Staus*"
                                            popupHeight="350px"
                                            change={(e) =>{
                                              let options = {
                                                rules: {
                                                  varRoleStatusErr: {
                                                    required: [true, "Please select role status"],
                                                  },
                                                },
                                              }
                                              if(e.itemData===null){
                                                const updateOrgChartMasterForm = new FormValidator("#addOrgChartLevel3Details", options);
                                                if (updateOrgChartMasterForm.validate()) {
                                                  this.setState({roleStatus:''})
                                                }
                                              }else{
                                                  const updateOrgChartMasterForm = new FormValidator("#addOrgChartLevel3Details", options);
                                                  if (updateOrgChartMasterForm.validate()) {
                                                    this.setState({roleStatus:e.itemData.Id})
                                                  }
                                              }
                                            }}
                              />
                              <div
                                id="varRoleStatusErr"
                                className="e-input-group e-float-icon-left error-div"
                              />
                            </div>
                          </div>
                      </div>
                    </form>
                  </div>
                }{
                  level === 'level4' && 
                  <div className='control-pane col-lg-12 control-section'>
                  <form id="addOrgChartLevel4Details" method="post" className="control-section col-md-12">
                    <div className='row'>
                    <div className="col-xs-2 col-sm-2 col-lg-4 col-md-2  ">
                            <div>
                              <TextBoxComponent
                                id="varLevel4Name"
                                placeholder="Level Name*"
                                cssClass="e-outline"
                                floatLabelType="Auto"
                                data-msg-containerid="varLevel4NameErr"
                                name="varLevel4NameErr"
                                value={varLevel4Name}
                                change={(e) => {
                                  let options = {
                                    rules: {
                                      varLevel4NameErr: {
                                        required: [true, "Please enter level name"],
                                        regex: ["^[A-z]+$", "Please enter valid name"],
                                      },
                                    },
                                  }
                                  const updateOrgChartMasterForm = new FormValidator("#addOrgChartLevel4Details", options);
                                  if (updateOrgChartMasterForm.validate()) {
                                    this.setState({
                                      varLevel4Name: e.value
                                    })
                                  }
                                }}
                              />
                              <div
                                id="varLevel4NameErr"
                                className="e-input-group e-float-icon-left error-div"
                              />
                            </div>
                          </div>
                          <div className="col-xs-2 col-sm-2 col-lg-4 col-md-2  ">
                            <div>
                              <TextBoxComponent
                                id="varSubPnlFuncName"
                                placeholder="Sub Pnl Function Name*"
                                cssClass="e-outline"
                                floatLabelType="Auto"
                                data-msg-containerid="varSubPnlFuncNameErr"
                                name="varSubPnlFuncNameErr"
                                value={varSubPnlFuncName}
                                change={(e) => {
                                  let options = {
                                    rules: {
                                      varSubPnlFuncNameErr: {
                                        required: [true, "Please enter sub pnl name"],
                                        regex: ["^[A-z]+$", "Please enter valid sub pnl name"],
                                      },
                                    },
                                  }
                                  const updateOrgChartMasterForm = new FormValidator("#addOrgChartLevel4Details", options);
                                  if(updateOrgChartMasterForm.validate()){
                                    this.setState({
                                      varSubPnlFuncName: e.value
                                    })
                                }
                                }}
                              />
                              <div
                                id="varSubPnlFuncNameErr"
                                className="e-input-group e-float-icon-left error-div"
                              />
                            </div>
                          </div>
                          <div className="col-xs-2 col-sm-2 col-lg-4 col-md-2  ">
                            <div>
                            <ComboBoxComponent
                                            id="varBussSubPnlFunc"
                                            dataSource={level3Master}
                                            fields={{ text: "subPnlFuncName", value: "subPnlFunctionId" }}
                                            value={varBussSubPnlFunc}
                                            data-msg-containerid="varBussSubPnlFuncErr"
                                            name="varBussSubPnlFuncErr"
                                            allowFiltering
                                            allowCustom={false}
                                            floatLabelType="Auto"
                                            cssClass="e-outline"
                                            placeholder="Bussiness Pnl Function*"
                                            popupHeight="350px"
                                            change={(e) =>{
                                              let options = {
                                                rules: {
                                                  varBussSubPnlFuncErr: {
                                                    required: [true, "Please select business business pnl function"],
                                                  },
                                                },
                                              }
                                              if(e.itemData===null){
                                                const updateOrgChartMasterForm = new FormValidator("#addOrgChartLevel4Details", options);
                                                if(updateOrgChartMasterForm.validate()){
                                                this.setState({varBussSubPnlFunc:""})
                                                }
                                              }else{
                                                const updateOrgChartMasterForm = new FormValidator("#addOrgChartLevel4Details", options);
                                                if(updateOrgChartMasterForm.validate()){
                                                this.setState({varBussSubPnlFunc:e.itemData.subPnlFunctionId})
                                                }
                                              }
                                            }}
                              />
                              <div
                                id="varBussSubPnlFuncErr"
                                className="e-input-group e-float-icon-left error-div"
                              />
                            </div>
                          </div>
                          </div>
                          <div className='row'>
                          <div className="col-xs-2 col-sm-2 col-lg-4 col-md-2  ">
                            <div>
                            <AutoCompleteComponent
                                            id="varBussSubPnlFuncHead"
                                            dataSource={othersCheckAvailablity}
                                            filtering={(e) => this.filterEmployee(e)}
                                            fields={{ text: "name", value: "UserId" }}
                                            value={varBussSubPnlFuncHead}
                                            data-msg-containerid="varBussSubPnlFuncHeadErr"
                                            name="varBussSubPnlFuncHeadErr"
                                            allowFiltering
                                            allowCustom={false}
                                            floatLabelType="Auto"
                                            cssClass="e-outline"
                                            placeholder="Bussiness Sub Pnl Function Head*"
                                            popupHeight="350px"
                                            change={(e) =>{
                                              let options = {
                                                rules: {
                                                  varBussSubPnlFuncHeadErr: {
                                                    required: [true, "Please select business sub pnl function head"],
                                                  },
                                                },
                                              }
                                              if(e.itemData===null){
                                                const updateOrgChartMasterForm = new FormValidator("#addOrgChartLevel4Details", options);
                                                if(updateOrgChartMasterForm.validate()){
                                                this.setState({varBussSubPnlFuncHead:""})
                                                }
                                              }else{
                                                const updateOrgChartMasterForm = new FormValidator("#addOrgChartLevel4Details", options);
                                                if(updateOrgChartMasterForm.validate()){
                                                this.setState({varBussSubPnlFuncHead:e.itemData.UserId})
                                                }
                                              }
                                            }}
                              />
                              <div
                                id="varBussSubPnlFuncHeadErr"
                                className="e-input-group e-float-icon-left error-div"
                              />
                            </div>
                          </div>
                          <div className="col-xs-2 col-sm-2 col-lg-4 col-md-2  ">
                            <div>
                            <ComboBoxComponent
                                            id="varRoleStatus"
                                            dataSource={roleStatusData}
                                            fields={{ text: "Name", value: "Id" }}
                                            value={roleStatus}
                                            data-msg-containerid="varRoleStatusErr"
                                            name="varRoleStatusErr"
                                            allowFiltering
                                            allowCustom={false}
                                            floatLabelType="Auto"
                                            cssClass="e-outline"
                                            placeholder="Role Staus*"
                                            popupHeight="350px"
                                            change={(e) =>{
                                              let options = {
                                                rules: {
                                                  varRoleStatusErr: {
                                                    required: [true, "Please select role status"],
                                                  },
                                                },
                                              }
                                              if(e.itemData===null){
                                                const updateOrgChartMasterForm = new FormValidator("#addOrgChartLevel4Details", options);
                                                if(updateOrgChartMasterForm.validate()){
                                                  this.setState({roleStatus:""})
                                                }
                                              }else{
                                                const updateOrgChartMasterForm = new FormValidator("#addOrgChartLevel4Details", options);
                                                if(updateOrgChartMasterForm.validate()){
                                                  this.setState({roleStatus:e.itemData.Id})
                                                }
                                              }
                                            }}
                              />
                              <div
                                id="varRoleStatusErr"
                                className="e-input-group e-float-icon-left error-div"
                              />
                            </div>
                          </div>
                    </div>
                  </form>
                  </div>
                }
                {
                  level === 'level5' && 
                  <div className='control-pane col-lg-12 control-section'>
                    <form id="addOrgChartLevel5Details" method="post" className="control-section col-md-12">
                      <div className='row'>
                          <div className="col-xs-2 col-sm-2 col-lg-4 col-md-2  ">
                            <div>
                              <TextBoxComponent
                                id="varLevel5Name"
                                placeholder="Level Name*"
                                cssClass="e-outline"
                                floatLabelType="Auto"
                                data-msg-containerid="varLevel5NameErr"
                                name="varLevel5NameErr"
                                value={varLevel5Name}
                                change={(e) => {
                                  let options = {
                                    rules: {
                                      varLevel5NameErr: {
                                        required: [true, "Please enter level name"],
                                        regex: ["^[A-z]+$", "Please enter valid level name"],
                                      },
                                    },
                                  }
                                  const updateOrgChartMasterForm = new FormValidator("#addOrgChartLevel5Details", options);
                                  if(updateOrgChartMasterForm.validate()){
                                    this.setState({
                                      varLevel5Name: e.value
                                    })
                                  }
                                }}
                              />
                              <div
                                id="varLevel5NameErr"
                                className="e-input-group e-float-icon-left error-div"
                              />
                            </div>
                          </div>
                          <div className="col-xs-2 col-sm-2 col-lg-4 col-md-2  ">
                            <div>
                              <TextBoxComponent
                                id="varServiceDivisionName"
                                placeholder="Practice Area / Sales Unit / Sub Function*"
                                cssClass="e-outline"
                                floatLabelType="Auto"
                                data-msg-containerid="varServiceDivisionNameErr"
                                name="varServiceDivisionNameErr"
                                value={varServiceDivisionName}
                                change={(e) => {
                                  let options = {
                                    rules: {
                                      varServiceDivisionNameErr: {
                                        required: [true, "Please enter practice area / sales unit / sub function name"],
                                        regex: ["^[A-z]+$", "Please enter valid practice area / sales unit / sub function name"],
                                      },
                                    },
                                  }
                                  const updateOrgChartMasterForm = new FormValidator("#addOrgChartLevel5Details", options);
                                  if(updateOrgChartMasterForm.validate()){
                                  this.setState({
                                    varServiceDivisionName: e.value
                                  })
                                  }
                                }}
                              />
                              <div
                                id="varServiceDivisionNameErr"
                                className="e-input-group e-float-icon-left error-div"
                              />
                            </div>
                          </div>
                          <div className="col-xs-2 col-sm-2 col-lg-4 col-md-2  ">
                            <div>
                            <ComboBoxComponent
                                            id="varSubFnlFunc"
                                            dataSource={level4Master}
                                            fields={{ text: "subPnlFunctionName", value: "intSubPnlFuncUid" }}   
                                            value={varSubFnlFunc}
                                            data-msg-containerid="varSubFnlFuncErr"
                                            name="varSubFnlFuncErr"
                                            allowFiltering
                                            allowCustom={false}
                                            floatLabelType="Auto"
                                            cssClass="e-outline"
                                            placeholder="Sub Pnl Function *"
                                            popupHeight="350px"
                                            change={(e) =>{
                                              let options = {
                                                rules: {
                                                  varSubFnlFuncErr: {
                                                    required: [true, "Please select sub pnl function"],
                                                  },
                                                },
                                              }
                                              if(e.itemData===null){
                                                const updateOrgChartMasterForm = new FormValidator("#addOrgChartLevel5Details", options);
                                                if(updateOrgChartMasterForm.validate()){
                                                  this.setState({roleStatus:''})
                                                }
                                              }else{
                                                  const updateOrgChartMasterForm = new FormValidator("#addOrgChartLevel5Details", options);
                                                  if(updateOrgChartMasterForm.validate()){
                                                    this.setState({roleStatus:e.itemData.intSubPnlFuncUid})
                                                  }
                                              }
                                            }}
                              />
                              <div
                                id="varSubFnlFuncErr"
                                className="e-input-group e-float-icon-left error-div"
                              />
                            </div>
                          </div>
                        </div>
                        <div className='row'>
                          <div className="col-xs-2 col-sm-2 col-lg-4 col-md-2  ">
                            <div>
                                
                              
                              <AutoCompleteComponent
                                id="varServiceDivisionHead"
                                fields={{ text: "name", value: "UserId" }}
                                dataSource={othersCheckAvailablity}
                                filtering={(e) => this.filterEmployee(e)}
                                placeholder="PnL Function Head *"
                                popupHeight="220px"
                                cssClass="e-outline"
                                floatLabelType="Auto"
                                data-msg-containerid="varServiceDivisionHeadErr"
                                name="varServiceDivisionHeadErr"
                                value={varServiceDivisionHead}
                                change={(e) => {
                                  let options = {
                                    rules: {
                                      varServiceDivisionHeadErr: {
                                        required: [true, "Please select pnl function head"],
                                      },
                                    },
                                  }
                                  if(e.itemData===null){
                                    const updateOrgChartMasterForm = new FormValidator("#addOrgChartLevel5Details", options);
                                    if(updateOrgChartMasterForm.validate()){
                                    this.setState({varServiceDivisionHead:''})
                                    }
                                  }else{
                                    const updateOrgChartMasterForm = new FormValidator("#addOrgChartLevel5Details", options);
                                    if(updateOrgChartMasterForm.validate()){
                                      this.setState({varServiceDivisionHead:e.itemData.UserId})
                                    }
                                  }
                                }}
                              />
                              <div
                                id="varServiceDivisionHeadErr"
                                className="e-input-group e-float-icon-left error-div"
                              />
                            </div>
                          </div>
                          <div className="col-xs-2 col-sm-2 col-lg-4 col-md-2  ">
                            <div>
                            <ComboBoxComponent
                                            id="varRoleStatus"
                                            dataSource={roleStatusData}
                                            fields={{ text: "Name", value: "Id" }}
                                            value={roleStatus}
                                            data-msg-containerid="varRoleStatusErr"
                                            name="varRoleStatusErr"
                                            allowFiltering
                                            allowCustom={false}
                                            floatLabelType="Auto"
                                            cssClass="e-outline"
                                            placeholder="Role Staus*"
                                            popupHeight="350px"
                                            change={(e) =>{
                                              let options = {
                                                rules: {
                                                  varRoleStatusErr: {
                                                    required: [true, "Please select role status"],
                                                  },
                                                },
                                              }
                                              if(e.itemData===null){
                                                const updateOrgChartMasterForm = new FormValidator("#addOrgChartLevel5Details", options);
                                                if(updateOrgChartMasterForm.validate()){
                                                  this.setState({roleStatus:""})
                                                }
                                              }else{
                                                const updateOrgChartMasterForm = new FormValidator("#addOrgChartLevel5Details", options);
                                                if(updateOrgChartMasterForm.validate()){
                                                  this.setState({roleStatus:e.itemData.Id})
                                                }
                                              }
                                            }}
                              />
                              <div
                                id="varRoleStatusErr"
                                className="e-input-group e-float-icon-left error-div"
                              />
                            </div>
                          </div>
                      </div>
                    </form>
                  </div>
                }
              </DialogContent>
              <DialogActions style={{ paddingRight: "30px" }}>
                <Button color="default" variant="contained" onClick={() => this.closeAddNewPopup()}> Cancel </Button>
                <Button color="primary" variant="contained" onClick={() => this.addNewOrgChartMasterDetails()} > Save </Button>
              </DialogActions>
            </ErrorBoundary>
          </Dialog>
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ meetingmaster, auth }) => {
  const { othersCheckAvailablity } = meetingmaster;
  const { authUser } = auth;
  return {
    othersCheckAvailablity,
    authUser
  };
};
export default connect(mapStateToProps, {addNewOrgChatMasterData})(OrgchartMasters);