import React, { useEffect, useRef, useState } from 'react'
import { EMAIL_MAPPING_MASTER } from '../../../../redux/constant/meetingcategoryconstant';

import ButtonComponent from '../../../../utilities/buttoncomponent';
import { connect } from 'react-redux';
import Emailmapping from '../../../../components/emailmappingadmincomponents/emailmapping';
import './emailMapping.css';

const EmailMappingPopupContainer = ({ changePopUpHandler, state }) => {
    const { masterId, editItemId } = state;
    const handleSaveRef = useRef(null);
    
    let handelSave = () => {
        handleSaveRef.current.onSaveData();
    };

    const formNameHandler = () => {
        if (masterId == EMAIL_MAPPING_MASTER) {
            document.title = "Email Mapping";
            return "Email Mapping";
        }
    };

    const renderSwitch = () => {
        if (masterId == EMAIL_MAPPING_MASTER) {
            return <Emailmapping state={state} handleSaveRef={handleSaveRef} />;
        }
    }

    return (
        <div
            id={`${formNameHandler()}Form`}
            className={"tmlc-admin-popup-root-for-email-mapping"}
            style={{
                width: 450
            }}
        >
            <div className='helpdesk-admin-popup-header'>
                {`${(editItemId > 0 || editItemId !== null) ? "Edit" : "Add"} ` + `${document.title}`}
            </div>
            {renderSwitch()}
            <div
                className="col-sm-12 col-md-12 col-lg-12 pb-md pt-md helpdesk-admin-popup-footer"
            >
                <ButtonComponent
                    id="helpdesk-admin-popup-cancel-btn"
                    type="cancel"
                    content="Cancel"
                    variant="contained"
                    click={() => { changePopUpHandler({ type: 'close' }) }}
                    style={{ width: "90px" }}
                />
                <ButtonComponent
                    id="helpdesk-admin-popup-cancel-btn"
                    type="primary"
                    content="Save"
                    variant="contained"
                    click={() => { handelSave !== null && handelSave() }}
                    style={{ width: "90px" }}
                />
            </div>
        </div>
    )
}

const mapStateToProps = () => {
    return {}
}

export default connect(mapStateToProps, {})(EmailMappingPopupContainer)