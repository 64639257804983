/* Author : Prashant Waphare
 * Date : 01-05-2020
 * Description: This file use for Absconding HR container.
 */
import React, { Component } from "react";
import NotificationContainer from "../../../../../components/notification/notifycontainer";
import AbscondingHrGrid from '../../../../../components/separation/abscondingcomponets/abscondinghrgrid';
import PropTypes from "prop-types";
import { connect } from "react-redux";
import separetionServices from "../../../../../services/entryexit/separationServices";
import Progressbar from '../../../../../components/progressbar/progressbar';
import { addUpdateSeparationDetails } from "../../../../../redux/actions/entryexit/separationaction";
import { getFormatDateForGrid } from "../../../../other/commonfunction";

class AbscondingHr extends Component {
  constructor(props) {
    super(props);
    this.state = {
      abscondingHrData: [],
      communicationHrMaster: [],

    }
    this.onCreated = this.onCreated.bind(this);
  }
  componentDidMount() {
    this.getAbscondingHrData();
    this.getCommunicationMaster();
  }
  UNSAFE_componentWillReceiveProps(newProps) {
    // console.log(newProps.UpdateResignationStatus, 'newProps.UpdateResignationStatus')
    if (newProps.updateAbscondingStatus === "StatusUpdate" || newProps.UpdateResignationStatus === "StatusUpdate") {
      this.getAbscondingHrData();
      this.props.addUpdateSeparationDetails({ updateAbscondingStatus: "", UpdateResignationStatus: '' });
    }
  }
  getAbscondingHrData = () => {
    const { authUser } = this.props;
    try {
      const getAbscondingListDetail = Promise.resolve(
        separetionServices.getAbscondingListDetail(authUser, "hrlist")
      );
      getAbscondingListDetail.then((result) => {
        if (result !== null) {
          this.setState({ abscondingHrData: getFormatDateForGrid(result)});          
        } else {
          this.data = "error";
        }
      });
    } catch (e) {
      // eslint-disable-next-line
      console.log(e);
    }
  };
  getCommunicationMaster = () => {
    const { authUser } = this.props;
    try {
      const getEmployeeResignationMaster = Promise.resolve(
        separetionServices.getEmployeeResignationMaster(authUser, "abscomm")
      );
      getEmployeeResignationMaster.then((result) => {
        if (result !== null) {
          this.setState({ communicationHrMaster: result });
        } else {
          this.data = "error";
        }
      });
    } catch (e) {
      // eslint-disable-next-line
      console.log(e);
    }
  };
  onCreated() {
    setTimeout(() => {
      if (this.acrdnbasic !== null) {
        // eslint-disable-next-line
        for (let i = 0; i <= this.acrdnbasic.items.length - 1; i++) {
          this.acrdnbasic.items[i].expanded = true;
        }
      }
    }, 500);
  }

  render() {
    const { abscondingHrData, communicationHrMaster } = this.state;
    return (
      <div>
        <div
          style={{
            padding: 10
          }}
        >
          <NotificationContainer />
          <div>
            {abscondingHrData !== null && communicationHrMaster.length > 0 ? (
              <AbscondingHrGrid getAbscondingHrData={this.getAbscondingHrData} abscondingHrData={abscondingHrData} getCommunicationMaster={this.getCommunicationMaster} communicationHrMaster={communicationHrMaster} />
            )
              :
              <div className='progress-bar-div'>
                <Progressbar />
              </div>
            }

          </div>
        </div>
      </div>
    );
  }
}
AbscondingHr.propTypes = {
  // eslint-disable-next-line
  status: PropTypes.string,
  authUser: PropTypes.string,
};
AbscondingHr.defaultProps = {
  authUser: "",
  status: "",
};

const mapStateToProps = ({ auth, onboardingreducer, separationreducer }) => {
  const { status } = onboardingreducer;
  const { authUser } = auth;
  const { updateAbscondingStatus, UpdateResignationStatus } = separationreducer

  return { authUser, status, updateAbscondingStatus, UpdateResignationStatus };
};
export default connect(mapStateToProps, { addUpdateSeparationDetails })(
  AbscondingHr
);
