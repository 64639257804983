import { MEETING_CATEGORY } from "../constant/meetingcategoryconstant";
import { defMeetingCategory } from "../../config/config";
// , PLAN_VS_ACTUAL_MEETING

const INIT_STATE = {
  categoryName: defMeetingCategory,
};
// eslint-disable-next-line
export default (state = INIT_STATE, action) => { // NOSONAR
  if(action.type === MEETING_CATEGORY){
    return {
      ...state,
      categoryName: action.payload,
    };
  }else{
    return state;
  }  
};
