/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { FormValidator } from "@syncfusion/ej2-inputs";
import LnAServices from "../../services/lna/lnaservice";
import {
  saveLockUnlockCluster,
  updateLnAData,
} from "../../redux/actions/lna/lnaaction";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import { getMySqlDateFormat, getNotify } from "../../app/other/commonfunction";
import ProgressBar from "../progressbar/progressbar";
import { ERROR, entryAlreadyExist, pleaseSelectAttendanceMonthDate, pleaseSelectLockEndDate } from "../../assets/constants/constants";

function LockUnlockPopup(props) {
  //NOSONAR
  const { authUser, openPopup, editClusterId } = props;

  const [state, setState] = useState({
    lockUnlockId: null,
    attendanceMonth: null,
    lockEndDate: null,
  });
  const [initialLoad, setInitialLoad] = useState(false);
  const [lockUnlockData, setLockUnlockData] = React.useState([])
  const [popUpHeight, setPopUpHeight] = useState('66.5vh');

  let validOptions = {
    rules: {
      attendanceMonthDateErr: {
        date: [true, pleaseSelectAttendanceMonthDate],
      },
      lockEndDateErr: {
        date: [true, pleaseSelectLockEndDate],
      },
    },
  };

  useEffect(() => {
    props.updateLnAData({ popUpSource: "Attendance Lock Master" });
    getAllLockAndUnlock();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      props.setSubmitClick(onSaveData);
    }, 100);
  }, [state]);

  useEffect(() => {
    getData();
  }, [editClusterId, openPopup]);

  useEffect(() => {
    if (props.lnaMasterFlag === "updated") {
      props.changePopUpHandler("close")
    };
  }, [props.lnaMasterFlag]);

  const getData = () => {
    if (openPopup === "add") {
      resetData();
    } else {
      editClusterId !== null &&
        (async () => {
          setInitialLoad(true);
          const lockUnlockClusterData = await LnAServices.getLockUnlockClusterData(authUser, editClusterId);
          const {
            lnAMasterItemUid,
            attendanceMonth,
            lockEndDate,
          } = lockUnlockClusterData.Result[0];
          let data = {
            lockUnlockId: lnAMasterItemUid,
            attendanceMonth: attendanceMonth ? new Date(getMySqlDateFormat(attendanceMonth).split(' ')[0]) : null,
            lockEndDate: lockEndDate ? new Date(getMySqlDateFormat(lockEndDate).split(' ')[0]) : null,
          };
          setInitialLoad(false);
          setState((preState) => ({ ...preState, ...data }));
          setTimeout(() => {
            const addFormObject = new FormValidator(
              "#lockUnlockClusterForm",
              validOptions
            );
            addFormObject.validate();
          }, 10);
        })();
    }
    // setState(preState => ({ ...preState, title }))
  };

  const getAllLockAndUnlock = async () => {
    const lockUnlockClusterData = await LnAServices.getLockUnlockClusterData(authUser, '0');

    setLockUnlockData(lockUnlockClusterData.Result);
  }

  const resetData = () => {
    let data = {
        lockUnlockId: null,
        attendanceMonth: null,
        lockEndDate: null,
    };
    setState((preState) => ({ ...preState, ...data }));
  };

  useEffect(() => {
    if (props.lnaMasterFlag === "updated") {
      props.changePopUpHandler("close");
    }
  }, [props.lnaMasterFlag]);

  const onSaveData = async () => {
    const {
        lockUnlockId,
        attendanceMonth,
        lockEndDate,
    } = state;

    let validateDates = true;

    const currentDate = new Date(attendanceMonth);
    const currentMonth = currentDate.getMonth() + 1; // Adding 1 because months are zero-indexed
    const currentYear = currentDate.getFullYear();
    let isSameMonthYear = false;

    lockUnlockData.forEach((res) => {
      const responseDate = new Date(res.attendanceMonth);
      const responseMonth = responseDate.getMonth() + 1;
      const responseYear = responseDate.getFullYear();

      if ((currentYear === responseYear) && (currentMonth === responseMonth) && (res.activeStatus === '1') && !(lockUnlockId > 0 /* NOSONAR */)) {
        isSameMonthYear = true;
      }
    })

    if (!attendanceMonth) {
      document.getElementById("attendanceMonthDateErr").innerHTML = pleaseSelectAttendanceMonthDate;
      return
    }

    if (!lockEndDate) {
      document.getElementById("lockEndDateErr").innerHTML = pleaseSelectLockEndDate;
      return
    }

    if (isSameMonthYear) {
      getNotify(ERROR, entryAlreadyExist);
      return
    }

    const attendanceSaveMonth = new Date(currentYear, currentMonth - 1, 1);

    const addFormObject = new FormValidator("#lockUnlockClusterForm", validOptions);
    if (addFormObject && addFormObject.validate()) {
      setInitialLoad(true)
      let lockUnlockClusterSaveData = {
        userId: authUser,
        type: lockUnlockId > 0 ? "EDIT" : "ADD",
        lockUnlockUid: lockUnlockId,
        attendanceMonth: attendanceSaveMonth ? getMySqlDateFormat(attendanceSaveMonth) : null,
        lockEndDate: lockEndDate ? getMySqlDateFormat(lockEndDate) : null,
      };
      props.saveLockUnlockCluster(lockUnlockClusterSaveData);
      setTimeout(() => {
        setInitialLoad(false)
      }, 10)
    }
  };

  const getPopUpHeight = (innerWidth) => {
    if ((innerWidth < 1537 && innerWidth > 1438)) {
      setPopUpHeight('58vh');
    }
    if (innerWidth < 1282 || (innerWidth === 1366 && window.innerHeight === 633)) {
      setPopUpHeight('50vh');
    }
    if ((innerWidth === 1280 && window.innerHeight === 633)) {
      setPopUpHeight('53.5vh');
    }
    if ((innerWidth === 1280 && window.innerHeight === 665) || (innerWidth === 1536 && window.innerHeight === 730)) {
      setPopUpHeight('56vh');
    }
  };

  const inputChangeHandler = async (e, type) => {
    let options = {};
    switch (type) {
      case "attendanceMonthDate":
        const currentDate = e.value;
        const currentMonth = currentDate?.getMonth() + 1; // Adding 1 because months are zero-indexed
        const currentYear = currentDate?.getFullYear();

        const attendanceSaveMonth = new Date(currentYear, currentMonth - 1, 1);
        
        setState((prevState) => ({
          ...prevState,
          attendanceMonth: attendanceSaveMonth,
          lockEndDate: ''
        }));
        if (document.getElementById("attendanceMonthDateErr")) {
          document.getElementById("attendanceMonthDateErr").innerHTML = "";
        }
        break;
      case "lockEndDate":
        setState((prevState) => ({ ...prevState, lockEndDate: e.value }));

        if (document.getElementById("lockEndDateErr")) {
          document.getElementById("lockEndDateErr").innerHTML = "";
        }
        break;
      default:
    }

    setTimeout(() => {
      const addFormObject = new FormValidator("#lockUnlockClusterForm", options);
      addFormObject.validate();
    }, 100);
  };

  useEffect(() => {
    getPopUpHeight(window.innerWidth);
  }, [window.innerWidth]);
  return (
    <div
      id="lockUnlockClusterForm"
      style={{
        overflowY: "auto",
        paddingLeft: 16,
        paddingRight: 16,
        paddingBottom: 16,
        transition: "width 5s",
        height: popUpHeight
      }}
    >
      <div
        className="col-xs-12 col-sm-12 col-lg-12 col-md-12 mt-lg"
        style={{ paddingLeft: 0 }}
      >
        <DatePickerComponent
          id="attendanceMonthDate"
          name="attendanceMonthDateErr"
          data-msg-containerid="attendanceMonthDateErr"
          format="MMM-yyyy"
          start="Year"
          depth="Year"
          value={state.attendanceMonth}
          floatLabelType="Auto"
          cssClass="e-outline minimize-height"
          placeholder="Attendance Month *"
          firstDayOfWeek={1}
          allowEdit={false}
          change={(e) => inputChangeHandler(e, "attendanceMonthDate")}
        />
        <div
          id="attendanceMonthDateErr"
          className="e-input-group e-float-icon-left error-div"
        />
      </div>

      <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
        <DatePickerComponent
          id="lockEndDate"
          fields={{ text: "Name", value: "Id" }}
          value={state.lockEndDate}
          data-msg-containerid="lockEndDateErr"
          name="lockEndDateErr"
          format="dd-MMM-yyyy"
          floatLabelType="Auto"
          min={state.attendanceMonth}
          cssClass="e-outline minimize-height"
          placeholder="Lock End Date *"
          firstDayOfWeek={1}
          allowEdit={false}
          change={(e) => inputChangeHandler(e, "lockEndDate")}
        />
        <div
          id="lockEndDateErr"
          className="e-input-group e-float-icon-left error-div"
        />
      </div>
      <div className="loader-new1" id="spinnerContainer">
        {initialLoad && <ProgressBar />}
      </div>
    </div>
  );
}

const mapStateToProps = ({ auth, lnareducer, meetingmaster }) => {
  const { authUser } = auth;
  const { currentFinancialYearId } = meetingmaster;
  const { colourMaster, lnaMasterFlag } = lnareducer;

  return {
    authUser,
    lnareducer,
    currentFinancialYearId,
    colourMaster,
    lnaMasterFlag,
  };
};
export default connect(mapStateToProps, {
  saveLockUnlockCluster,
  updateLnAData,
})(LockUnlockPopup);
