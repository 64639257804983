/*
 * Author Somesh Dwivedi
 * Date  06-03-2024
 * @description This component is render when no data available in leave grid.
 * */

import './leavegridstyle.css'
const EmptyLeaveGridData = () => {
  return (
    <div className="empty-grid-parent">
      <div>
        <img
          src='/images/NoLeaves.png'
          alt='No Upcoming Leaves'
        />
        <p>No Upcoming Leaves</p>
     </div>
    </div>
  )
}

export default EmptyLeaveGridData