import React, { useState, useEffect } from "react";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { connect } from "react-redux";
import LnAServices from "../../services/lna/lnaservice";
import { saveSymbol, updateLnAData } from "../../redux/actions/lna/lnaaction";
import { FormValidator } from "@syncfusion/ej2-inputs";
import {
  MultiSelectComponent,
  CheckBoxSelection,
  Inject
} from "@syncfusion/ej2-react-dropdowns";
import {
  pleaseEnterSymbolName,
  pleaseEnterSymbol,
  pleaseSelectMasterCluster,
  regexOnlyLettersAndSpaces,
} from "../../assets/constants/constants";
import ProgressBar from "../progressbar/progressbar";

const SymbolCluster = (props) => {
  const {
    authUser,
    openPopup,
    editClusterId,
    masterCluster,
  } = props;

  const [state, setState] = useState({
    title: null,
    SymbolName: null,
    Symbol: null,
    symbolUid: 0,
    isActive: "1",
    Description: null,
    masterCluster: null,
    masterClusterIds: []
  });

  const [initialLoad, setInitialLoad] = useState(false)

  let validOptions = {
    rules: {
      symbolNameErr: {
        required: [true, pleaseEnterSymbolName],
        regex: [regexOnlyLettersAndSpaces, pleaseEnterSymbolName],
        minLength: [1, pleaseEnterSymbolName],
        maxLength: [50, "Should be maximum of 50 characters only."]
      },
      symbolErr: {
        required: [true, pleaseEnterSymbol],
        regex: [regexOnlyLettersAndSpaces, pleaseEnterSymbol],
      },
      masterErr: {
        required: [true, pleaseSelectMasterCluster],
      },
    },
  };

  useEffect(() => {
    props.updateLnAData({ popUpSource: 'Symbol Master' })
    if (editClusterId === 0)
      getData();
  }, []);

  useEffect(() => {
    getData();
  }, [editClusterId, openPopup]);

  useEffect(() => {
    setTimeout(() => {
      props.setSubmitClick(onSaveData);
    }, 100);
  }, [state]);

  useEffect(() => {
    if (props.lnaMasterFlag === "updated")
      props.changePopUpHandler("close");
  }, [props.lnaMasterFlag]);

  const getData = () => {

    if (openPopup === "add") {
      let data = {
        SymbolName: null,
        Symbol: null,
        symbolUid: 0,
        isActive: "1",
        Description: null,
        masterCluster: null,
        masterClusterIds: []
      };
      setState((preState) => ({ ...preState, ...data }));
      document.getElementById('masterErr').innerText=''
    } else {
      editClusterId !== null &&
        (async () => {
          setInitialLoad(true)
          const symbolClusterData = await LnAServices.getSymbolClusterData(
            authUser,
            editClusterId
          );
          const {
            SymbolName,
            Symbol,
            isActive,
            Description,
            masterCluster:mcData,
            masterClusterIds
          } = symbolClusterData.Result[0];
          let data = {
            SymbolName,
            Symbol,
            symbolUid: editClusterId,
            isActive: isActive === "Active" ? "1" : "0",
            Description,
            masterCluster:mcData,
            masterClusterIds: masterClusterIds?.split(",").map(Number),
          };
          setInitialLoad(false)
          setState((preState) => ({ ...preState, ...data }));
          setTimeout(() => {
            const addFormObject = new FormValidator("#symbolClusterForm", validOptions);
            addFormObject.validate();
          }, 100)
        })();
    }
  };
  const inputChangeHandler = (e, type) => {
    let options = {};
    switch (type) {
      case "symbolName":
        setState((prevState) => ({ ...prevState, SymbolName: e.value.trim() }));
        options = validOptions.rules['symbolNameErr'];

        break;
      case "symbol":
        setState((prevState) => ({ ...prevState, Symbol: e.value.trim() }));
        options = validOptions.rules['symbolErr'];
        break;
      case "symbolDescription":
        setState((prevState) => ({ ...prevState, Description: e.value && e.value.trim() }));
        break;
      case "symbolMaster":
        setState({...state, masterClusterIds: state.masterClusterIds.concat(e.itemData.Id)})
        document.getElementById("masterErr").innerHTML = "";
        options = validOptions.rules['masterErr'];
        break;
      case "isActive":
        setState({
          ...state,
          isActive: e.checked === true ? "1" : "0",
        });
        break;
      default:
    }
    setTimeout(() => {
      const addFormObject = new FormValidator("#symbolClusterForm", options);
      addFormObject.validate();
    }, 10);
  };

  const onSaveData = () => {
    try {
      const {
        SymbolName,
        Symbol,
        symbolUid,
        Description,
        isActive,
        masterClusterIds
      } = state;

      const addFormObject = new FormValidator("#symbolClusterForm", validOptions);
      if (addFormObject.validate()) {
        setInitialLoad(true)
        let symbolClusterSaveData = {
          userId: authUser,
          type: symbolUid > 0 ? "EDIT" : "ADD",
          SymbolName,
          Symbol,
          symbolUid,
          Description,
          isActive: isActive === "1" ? "1" : "0",
          masterClusterIds: masterClusterIds.join(",")
        };
        props.saveSymbol(symbolClusterSaveData);
        setTimeout(() => {
          setInitialLoad(false)
        });
      }
    } catch (error) {
      console.log("🚀 ~ file: symbolcluster.jsx:289 ~ onSaveData ~ error", error)
    }
  };

  const removeUser = (e) => {
    let masterClusterIds = state.masterClusterIds;
    masterClusterIds = masterClusterIds.filter((el) => parseInt(el) !== e.itemData.Id);
    setState((preState) => ({ ...preState, masterClusterIds }));
  };

  const descriptionBox = `.e-outline textarea, textarea.e-outline, .e-outline.e-float-input textarea, .e-outline.e-float-input.e-control-wrapper textarea, .e-outline.e-float-input.e-input-group textarea {
    box-sizing: border-box;
    min-height: 100px;
  }`

  return (
    <>
      <div
        id="symbolClusterForm"
        style={{ height: "61.5vh" }}
      >
        <div className="col-xs-12 col-sm-12 col-lg-12 col-xl-12 mt-lg">
          <TextBoxComponent
            data-msg-containerid="symbolNameErr"
            id="symbol"
            value={state.SymbolName}
            name="symbolNameErr"
            autocomplete={'off'}
            placeholder="Symbol Name *"
            floatLabelType="Auto"
            cssClass="e-outline"
            htmlAttributes={{ maxlength: '50', minlength: '1' }}
            onChange={(e) => inputChangeHandler(e, "symbolName")}
          />
          <div id="symbolNameErr" className="error-div" />
        </div>

        <div className="col-xs-12 col-sm-12 col-lg-12 col-xl-12 mt-lg">
          <TextBoxComponent
            data-msg-containerid="symbolErr"
            id="symbolId"
            value={state.Symbol}
            name="symbolErr"
            autocomplete={'off'}
            placeholder="Symbol *"
            floatLabelType="Auto"
            cssClass="e-outline"
            htmlAttributes={{ maxlength: '50', minlength: '1' }}
            onChange={(e) => inputChangeHandler(e, "symbol")}
          />

          <div id="symbolErr" style={{ fontSize: 13 }} className="error-div" />
        </div>

        <div className="'col-xs-12 col-sm-12 col-lg-12 col-xl-12 mt-lg symbolDescr">
          <style>{descriptionBox}</style>
          <TextBoxComponent
            style={{ minHeight: 100 }}
            id={'description'}
            placeholder={"Description"}
            cssClass="e-outline textarea"
            floatLabelType="Auto"
            autocomplete={'off'}
            name="descriptionErr"
            data-msg-containerid="descriptionErr"
            multiline
            inputProps={{
              required: true,
            }}
            htmlAttributes={{ maxlength: '500' }}
            onChange={(e) => inputChangeHandler(e, "symbolDescription")}
            value={state.Description}
          />
          <div id="descriptionErr" style={{ fontSize: 13 }} className="error-div" />
        </div>

        <div
          className="col-sm-12 col-md-12 col-lg-12"
          style={{ marginTop: "0px" }}
        >
          <div className="col-xs-12 col-sm-12 col-lg-12 col-xl-12 mt-lg">
            <MultiSelectComponent
              id="Master"
              name="masterErr"
              dataSource={masterCluster}
              fields={{ text: "Name", value: "Id" }}
              placeholder="Select Master(s) to Assign *"
              cssClass="e-outline"
              floatLabelType="Auto"
              showSelectAll={false}
              data-msg-containerid="masterErr"
              removed={removeUser}
              value={state.masterClusterIds}
              showDropDownIcon={true}
              mode="CheckBox"
              allowFiltering={false}
              select={(e) => inputChangeHandler(e, "symbolMaster")}
            >
              <Inject services={[CheckBoxSelection]} />
            </MultiSelectComponent>
          </div>
          <div id="masterErr" style={{ fontSize: 13 }} className="error-div pl-lg" />
        </div>
      </div>
      <div className="loader-new1" id="spinnerContainer" >
        {initialLoad && <ProgressBar />}
      </div>
    </>
  );
};

const mapStateToProps = ({ auth, lnareducer }) => {
  const { authUser } = auth;
  const {

    dayBreakData,
    relieverData,
    masterCluster,
    lnaMasterFlag
  } = lnareducer;

  return {
    authUser,
    dayBreakData,
    relieverData,
    masterCluster,
    lnaMasterFlag
  };
};
export default connect(mapStateToProps, { saveSymbol, updateLnAData })(
  SymbolCluster
);
