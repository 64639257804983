import "date-fns";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import dataService from "./../../services/services"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../assets/style/index.css";
import { makeStyles } from "@material-ui/core/styles";
import { ComboBoxComponent } from "@syncfusion/ej2-react-dropdowns";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
  TimePicker,
} from "@material-ui/pickers";
import "../../assets/font-awesome/css/font-awesome.min.css";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import { library } from "@fortawesome/fontawesome-svg-core";
import CalenderToolbar from "./calendartoolbar";
import {
  faEdit,
  faFileAlt,
  faRecycle,
  faDesktop,
  faCalendarAlt,
  faClock,
  faGlobe,
  faMapMarkerAlt,
  faGlobeAmericas,
  faVideo,
  faSearchPlus,
  faPhone,
  faCopy,
  faSquareList,
} from "@fortawesome/pro-light-svg-icons";
import {
  defTimeZone,
  defClientTimeZone,
  // urlConfig,
} from "../../config/config";
import { updateMeetingBookingState } from "../../redux/actions/meetingbookingaction";

import MeetingRecurrence from "../meetingrecurrence/meetingrecurrence";
import {
  getNotify,
  addDays,
  dateDiffIndays,
  dencrypt
} from "../../app/other/commonfunction";
import {
  ERROR,
  startTimeMustBeGreaterThanCurrentTime,
  endTimeMustBeGreaterThanCurrentTime,
  startTimeMustBeLesserThanCurrentTime,
  endTimeMustBeLesserThanCurrentTime, SelfBreakLeave, ExcluedPitchRecurrenceIds
  // SUCCESS,
} from "../../assets/constants/constants";

library.add(
  faEdit,
  faFileAlt,
  faRecycle,
  faDesktop,
  faCalendarAlt,
  faClock,
  faGlobe,
  faMapMarkerAlt,
  faGlobeAmericas,
  faVideo,
  faSearchPlus,
  faPhone,
  faCopy
);
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    display: "flex",
    border: `1px solid ${theme.palette.divider}`,
    flexWrap: "wrap",
  },
  button: {
    margin: theme.spacing(1),
  },
  formControl: {
    border: "0px solid green",
  },
}));

function MeetingDetails(props) { // NOSONAR
  const { meetingId } = props
  const classes = useStyles();
  const [defaultTimeZone, setDefaultTimeZone] = React.useState([]);
  const [defaultClientTimeZone, setDefaultClientTimeZone] = React.useState([]);
  const [previousDate, setPreviousDate] = React.useState({});
  const [openRecurring, setOpenRecurring] = React.useState(false);
  const [prevRecurring, setPrevRecurring] = React.useState('');
  const [currentRecurring, setCurrentRecurring] = React.useState('');

  let listtz;
  let listmedia;
  let textareaObj;
  let textareaObjActionItem;
  useEffect(() => { // NOSONAR
    const defArray = [];
    const Allunique = [];
    let unique = [];
    const DefTZ = [];
    const def = props.defaultsetting[0].Name.split(",");
    const tznm = def.map((element) => {
      return { Name: element.split("_")[0] };
    });

    tznm.map((item) => {
      props.timeZone.filter((data) => {
        // eslint-disable-next-line
        if (parseInt(item.Name) === data.Id) {
          if (!defArray.includes(data)) {
            defArray.push(data);
          }
        }

        return defArray;
      });
      return defArray;
    });
    // eslint-disable-next-line
    for (let i = 0; i < props.timeZone.length - 1; i++) {
      if (defTimeZone.timezone.indexOf(props.timeZone[i].Name) > -1) {
        DefTZ.push(props.timeZone[i]);
      }
    }
    const unique1 = DefTZ.filter((o) => defArray.indexOf(o) === -1);
    const unique2 = defArray.filter((o) => DefTZ.indexOf(o) === -1);
    unique = defArray.concat(unique1.concat(unique2));
    if (defArray.length < 3) {
      // eslint-disable-next-line
      for (let n = 0; n < 3; n++) {
        Allunique.push(unique[n]);
      }
      setDefaultTimeZone(Allunique);
    } else {
      setDefaultTimeZone(defArray);
    }

    const DefCLTZ = [];
    // eslint-disable-next-line
    for (let j = 0; j < props.clientTimeZone.length - 1; j++) {
      if (
        defClientTimeZone.clientTimeZone.indexOf(props.clientTimeZone[j].Name) >
        -1
      ) {
        DefCLTZ.push(props.clientTimeZone[j]);
      }
    }
    setDefaultClientTimeZone(DefCLTZ);
    // eslint-disable-next-line
  }, [])

  // Added By:- Prashant Waphare | 03-08-2020 | Auto increase textbox size as per containt.
  const onInput = () => {
    textareaObj.respectiveElement.style.height = "auto";
    textareaObj.respectiveElement.style.height = `${textareaObj.respectiveElement.scrollHeight}px`;
  };
  const onInputActionItem = () => {
    textareaObjActionItem.respectiveElement.style.height = "auto";
    textareaObjActionItem.respectiveElement.style.height = `${textareaObjActionItem.respectiveElement.scrollHeight}px`;
  };

  const handleChangeMM = (event) => {
    const value = event.itemData == null ? "" : event.itemData.Id;
    switch (event.element.id) {
      case "mm-select":
        if (value === 51 || value === 52) {
          // eslint-disable-next-line
          props.updateMeetingBookingState({
            meetingMediaId: value,
            isDigitalMeeting: true,
            meetingRoomsId: [87],
          });
          break;
        } else {
          // eslint-disable-next-line
          props.updateMeetingBookingState({
            meetingMediaId: value,
            isDigitalMeeting: false,
            meetingRoomsId: [],
          });
          break;
        }
      case "tz-select":
        // eslint-disable-next-line
        props.updateMeetingBookingState({ timeZoneId: value });
        // eslint-disable-next-line
        handleChkTZ(value);
        break;
      case "cltz-select":
        // eslint-disable-next-line
        props.updateMeetingBookingState({ timeZoneId: value });
        // eslint-disable-next-line
        handleChkCLTZ(value);
        break;
      default:
    }
  };

  const handleMeetingDateChange = (date) => {
    if (date.getDate() !== new Date(props.meetingDate).getDate()) {
      if (new Date().getTime() <= date.getTime()) {
        const d1 = `${(
          date.getMonth() + 1
        ).toString()}/${date
          .getDate()
          .toString()}/${date.getFullYear().toString()}`;
        const ds2 = `${(
          new Date(props.meetingStartTime).getMonth() + 1
        ).toString()}/${new Date(props.meetingStartTime)
          .getDate()
          .toString()}/${new Date(props.meetingStartTime)
            .getFullYear()
            .toString()}`;
        const de3 = `${(
          new Date(props.meetingEndTime).getMonth() + 1
        ).toString()}/${new Date(props.meetingEndTime)
          .getDate()
          .toString()}/${new Date(props.meetingEndTime)
            .getFullYear()
            .toString()}`;

        const differenceOfStartDate = dateDiffIndays(
          new Date(ds2),
          new Date(d1)
        ); // Math.ceil(dsd / (1000 * 60 * 60 * 24))//date.getDate() - new Date(props.meetingStartTime).getDate();
        const differenceOfEndDate = dateDiffIndays(new Date(de3), new Date(d1)); // Math.ceil(dse / (1000 * 60 * 60 * 24))//date.getDate() - new Date(props.meetingEndTime).getDate();

        const startDate = addDays(
          props.meetingStartTime,
          differenceOfStartDate
        );
        const endDate = addDays(props.meetingEndTime, differenceOfEndDate);
        // eslint-disable-next-line
        props.updateMeetingBookingState({
          meetingDate: date,
          meetingStartTime: startDate,
          meetingEndTime: endDate,
        });
        // eslint-disable-next-line
        props.onChange({ changeField: "meeting_date" });
      } else if (props.isFullDay) {
        const differenceOfStartDate =
          date.getDate() - new Date(props.meetingStartTime).getDate();
        const differenceOfEndDate =
          date.getDate() - new Date(props.meetingEndTime).getDate();

        let zeroStart = props.meetingStartTime;
        let zeroEnd = props.meetingEndTime;

        zeroStart = new Date(zeroStart).setHours(0, 0.0);
        zeroEnd = new Date(zeroEnd).setHours(0, 0, 0);

        const startDate = addDays(zeroStart, differenceOfStartDate);
        const endDate = addDays(zeroEnd, differenceOfEndDate);
        // eslint-disable-next-line
        props.updateMeetingBookingState({
          meetingDate: date,
          meetingStartTime: startDate,
          meetingEndTime: endDate,
        });
        // eslint-disable-next-line
        props.onChange({ changeField: "meeting_date" });
      } else if (date.getTime() <= new Date().getTime()) {
        const differenceOfStartDate =
          date.getDate() - new Date(props.meetingStartTime).getDate();
        const differenceOfEndDate =
          date.getDate() - new Date(props.meetingEndTime).getDate();

        const startDate = addDays(
          props.meetingStartTime,
          differenceOfStartDate
        );
        const endDate = addDays(props.meetingEndTime, differenceOfEndDate);
        // eslint-disable-next-line
        props.updateMeetingBookingState({
          meetingDate: date,
          meetingStartTime: startDate,
          meetingEndTime: endDate,
        });
        // eslint-disable-next-line
        props.onChange({ changeField: "meeting_date" });
      }
    } else {
      // eslint-disable-next-line
      props.updateMeetingBookingState({ meetingDate: date });
      // eslint-disable-next-line
      props.onChange({ changeField: "meeting_date" });
    }
  };
  // Author : Suresh Patel
  // Date : 04-01-2022
  // Description: Zoom Meeting Controller Function
  const saveOldZoomMeetingToRedux = async () => {
    const { meetingWebLink, participateCode, zoomMeetingId, zoomMeetingHostEmailId, oldZoomMeeting } = props;
    if (meetingId === 0) {
      if (oldZoomMeeting) {
        await dataService.deleteZoomMeeting(oldZoomMeeting.zoomMeetingId, 0, meetingId)
      }
    }
    props.updateMeetingBookingState({ oldZoomMeeting: { meetingWebLink, participateCode, zoomMeetingId, zoomMeetingHostEmailId }, meetingWebLink: "", participateCode: "", zoomMeetingId: null })
  }
  const handleMeetingStartTimeChange = (startTime) => {

    if (props.zoomMeetingId) {
      saveOldZoomMeetingToRedux()
    }
    if (
      new Date(startTime).getTime() >= new Date(props.meetingEndTime).getTime()
    ) {
      let endTime = new Date(startTime);
      endTime = endTime.setMinutes(endTime.getMinutes() + 30);
      // eslint-disable-next-line
      props.updateMeetingBookingState({ meetingEndTime: new Date(endTime) });
    }

    if (props.meetingmode === "A") {
      if (new Date().getTime() <= new Date(startTime).getTime()) {
        getNotify(ERROR, startTimeMustBeLesserThanCurrentTime);
        // eslint-disable-next-line
        props.updateMeetingBookingState({
          meetingStartTime: props.meetingStartTime,
        });
        // eslint-disable-next-line
        props.onChange({ changeField: "meeting_start_time" });
      } else {
        // eslint-disable-next-line
        props.updateMeetingBookingState({ meetingStartTime: startTime });
        // eslint-disable-next-line
        props.onChange({ changeField: "meeting_start_time" });
      }
    } else if (new Date().getTime() >= new Date(startTime).getTime()) {
      getNotify(ERROR, startTimeMustBeGreaterThanCurrentTime);
      // eslint-disable-next-line
      props.updateMeetingBookingState({
        meetingStartTime: props.meetingStartTime,
      });
      // eslint-disable-next-line
      props.onChange({ changeField: "meeting_start_time" });
    } else {
      // eslint-disable-next-line
      props.updateMeetingBookingState({ meetingStartTime: startTime });
      // eslint-disable-next-line
      props.onChange({ changeField: "meeting_start_time" });
    }
  };
  const handleMeetingEndTimeChange = (endTime) => {
    if (props.zoomMeetingId) {
      saveOldZoomMeetingToRedux()
    }
    document.getElementById("meetingtimeError").innerHTML = "";
    if (props.meetingmode === "A") {
      if (new Date().getTime() <= new Date(endTime).getTime()) {
        getNotify(ERROR, endTimeMustBeLesserThanCurrentTime);
        // eslint-disable-next-line
        props.updateMeetingBookingState({
          meetingStartTime: props.meetingEndTime,
        });
        // eslint-disable-next-line
        props.onChange({ changeField: "meeting_start_time" });
      } else {
        // eslint-disable-next-line
        props.updateMeetingBookingState({ meetingEndTime: endTime });
        // eslint-disable-next-line
        props.onChange({ changeField: "meeting_end_time" });
      }
    } else if (new Date().getTime() >= new Date(endTime).getTime()) {
      getNotify(ERROR, endTimeMustBeGreaterThanCurrentTime);
      // eslint-disable-next-line
      props.updateMeetingBookingState({
        meetingStartTime: props.meetingEndTime,
      });
      // eslint-disable-next-line
      props.onChange({ changeField: "meeting_start_time" });
    } else {
      // eslint-disable-next-line
      props.updateMeetingBookingState({ meetingEndTime: endTime });
      // eslint-disable-next-line
      props.onChange({ changeField: "meeting_end_time" });
    }
  };
  const handleMeetingFullDayBooking = () => {
    if (!props.isFullDay) {
      const previousStartTime = new Date(props.meetingStartTime);
      const previousEndTime = new Date(props.meetingEndTime);

      setPreviousDate({
        startTime: previousStartTime,
        endTime: previousEndTime,
      });
      const thisStartTime = new Date(props.meetingDate);
      const thisEndTime = new Date(props.meetingDate);
      const workDaySetting = Promise.resolve(dataService.getWorkDaySettingList(props.authUser, null, null, "Get"));
      workDaySetting.then((result) => {
        const startTime = result[0].startTime;
        const endTime = result[0].endTime;
        const startHour = startTime.split(":");
        const endHour = endTime.split(":");
        thisStartTime.setHours(+startHour[0], +startHour[1], 0);
        thisEndTime.setHours(+endHour[0], +endHour[1], 0);

        props.updateMeetingBookingState({
          meetingStartTime: thisStartTime,
          meetingEndTime: thisEndTime,
          isFullDay: true,
        });
      })
    } else if (previousDate.startTime === undefined) {
      const thisStartTime = new Date(props.meetingDate);
      const thisEndTime = new Date(props.meetingDate);
      thisEndTime.setMinutes(thisStartTime.getMinutes() + 15);
      // eslint-disable-next-line
      props.updateMeetingBookingState({
        meetingStartTime: thisStartTime,
        meetingEndTime: thisEndTime,
        isFullDay: false,
      });
    } else {
      // eslint-disable-next-line
      props.updateMeetingBookingState({
        meetingStartTime: previousDate.startTime,
        meetingEndTime: previousDate.endTime,
        isFullDay: false,
      });
    }
  };

  const handleChkTZ = (pId) => {
    if (document.getElementById("timezoneError") !== null)
      document.getElementById("timezoneError").innerHTML = "";
    defaultTimeZone.forEach((element) => {
      if (pId === element.Id) {
        document
          .getElementById(`btnTZ${element.Id}`)
          .classList.add("MuiButton-containedPrimary");
        // eslint-disable-next-line
        props.updateMeetingBookingState({ timeZoneId: pId });
      } else {
        document
          .getElementById(`btnTZ${element.Id}`)
          .classList.remove("MuiButton-containedPrimary");
      }
    });
  };
  const handleChkCLTZ = (pId) => {
    if (document.getElementById("timezoneError") !== null)
      document.getElementById("timezoneError").innerHTML = "";
    defaultClientTimeZone.forEach((element) => {
      if (pId === element.Id) {
        document
          .getElementById(`btnCLTZ${element.Id}`)
          .classList.add("MuiButton-containedPrimary");
        // eslint-disable-next-line
        props.updateMeetingBookingState({ timeZoneId: pId });
      } else {
        document
          .getElementById(`btnCLTZ${element.Id}`)
          .classList.remove("MuiButton-containedPrimary");
      }
    });
  };

  const handleRecurring = () => {

    setPrevRecurring(props.recurring)

    setOpenRecurring(true);
  };

  // Meeting Cancel
  const handleConfirmYes = () => {
    if (prevRecurring !== '') {
      if (prevRecurring !== currentRecurring) {
        if (props.zoomMeetingId) {
          saveOldZoomMeetingToRedux()
        }
      }
    }
    if (document.getElementById("btnRecurring") !== null) {
      if (
        !document
          .getElementById("btnRecurring")
          .classList.contains("MuiButton-containedPrimary")
      ) {
        document
          .getElementById("btnRecurring")
          .classList.add("MuiButton-containedPrimary");
      }
    }
    setOpenRecurring(false);
  };

  const handleConfirmNo = () => {
    setOpenRecurring(false);
  };

  const handleRemoveRecurrence = () => {
    if (
      document
        .getElementById("btnRecurring")
        .classList.contains("MuiButton-containedPrimary")
    ) {
      document
        .getElementById("btnRecurring")
        .classList.remove("MuiButton-containedPrimary");
    }
    setOpenRecurring(false);
  };

  const handleFocusOut = (obj) => {
    const { meetingAgenda } = props;//nosonar
    try {
      switch (obj.event.srcElement.name) {
        case "mttitle-txt":
          // eslint-disable-next-line
          props.updateMeetingBookingState({ meetingTitle: obj.value.trim() });
          if (meetingAgenda === "") {
            props.updateMeetingBookingState({ meetingAgenda: obj.value.trim() });
          }

          break;
        case "magenda-txt":
          // eslint-disable-next-line
          props.updateMeetingBookingState({ meetingAgenda: obj.value.trim() });
          break;
        case "mactionitems-txt":
            // eslint-disable-next-line
            props.updateMeetingBookingState({ meetingActionItems: obj.value.trim() });
            break;
        case "mconfdts-txt":
          // eslint-disable-next-line
          props.updateMeetingBookingState({
            participateCode: obj.value.trim(),
          });
          break;
        case "mlocation-txt":
          // eslint-disable-next-line
          props.updateMeetingBookingState({
            meetingLocation: obj.value.trim(),
          });
          break;
        case "mlink-txt":
          // eslint-disable-next-line
          props.updateMeetingBookingState({ meetingWebLink: obj.value.trim() });
          break;
        default:
      }
    } catch (error) { }
  };

  const handleOpentz = () => {
    listtz.showPopup();
  };

  const handleOpenmedia = () => {
    listmedia.showPopup();
  };
  const {
    meetingTitle,
    meetingDate,
    meetingmode,
    timeZoneId,
    isClientMeeting,
    timeZone,
    clientTimeZone,
    meetingMedia,
    meetingTypeId,
    meetingMediaId,
    meetingStartTime,
    meetingEndTime,
    isFullDay,
    recurring,
    meetingAgenda,
    meetingActionItems,
    meetingSubTypeId,
    meetingTypeName
    
  } = props;

  return (
    <div style={{ marginTop: 15 }}>

      <div>Meeting Details</div>
      <div>
        {/* first row */}
        <div className="col-md-12 pt-sm">
          <div className="row">
            <div className="col-md-5">
              <div className="custom-margin custom-padding-5 material2">
                <div className="col-md-12">
                  <div className="e-input-group e-float-icon-left">
                    <FontAwesomeIcon icon={faEdit} className="mt-sm" />
                    <div className="e-input-in-wrap">
                      <TextBoxComponent
                        placeholder="Meeting Title *"
                        required
                        value={meetingTitle}
                        cssClass="e-outline"
                        floatLabelType="Auto"
                        name="mttitle-txt"
                        id="mttitle-txt"
                        data-msg-containerid="titleError"
                        blur={handleFocusOut}
                        htmlAttributes={{ maxlength: "100", minLength: 1 }}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div id="titleError" className="error-div pl-xlg" />
            </div>
            <div className="col-md-3">
              <div className="custom-margin custom-padding-5 material2">
                <div className="col-md-12">
                  <div className="col-md-12">
                    <div className="e-input-group e-float-icon-left">
                      <FontAwesomeIcon
                        icon={faCalendarAlt}
                        className="mt-sm fa-lg"
                      />
                      <div className="e-input-in-wrap">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            style={{ width: "100%" }}
                            inputVariant="outlined"
                            margin="normal"
                            id="meeting-date"
                            name="meeting-date"
                            format="dd-MMM-yyyy"
                            value={meetingDate}
                            onChange={handleMeetingDateChange}
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                            data-msg-containerid="meetingdateError"
                            ToolbarComponent={CalenderToolbar}
                            minDate={
                              meetingmode === "P"
                                ? new Date()
                                : addDays(new Date(), - dencrypt(localStorage.getItem('defActualDays')))
                            }
                            maxDate={
                              meetingmode === "P"
                                ? new Date().setDate(new Date().getDate() + 365)
                                : new Date()
                            }
                          />
                        </MuiPickersUtilsProvider>
                        <div id="meetingdateError" className="error-div" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div id="titleError" className="error-div pl-xlg" />
            </div>
            <div className="col-md-2 p-none">
              <div className="e-input-group btnTimezone e-float-icon-left pull-left">
                <div
                  id="text"
                  className="e-btn-group time-zone1"
                  style={{ margin: 0 }}
                >
                  {isClientMeeting === false
                    ? defaultTimeZone.map((value) => {
                      return (
                        <Button
                          variant="contained"
                          key={`btnTZ${value.Id}`}
                          onClick={() => handleChkTZ(value.Id)}
                          id={`btnTZ${value.Id}`}
                          className={classes.button}
                          color={
                            timeZoneId === value.Id ? "primary" : "inherit"
                          }
                          title={`${value.Name} Time`}
                        >
                          {value.Name}
                        </Button>
                      );
                    })
                    : defaultClientTimeZone.map((value) => {
                      return (
                        <Button
                          variant="contained"
                          key={`btnCLTZ${value.Id}`}
                          onClick={() => handleChkCLTZ(value.Id)}
                          id={`btnCLTZ${value.Id}`}
                          className={classes.button}
                          color={
                            timeZoneId === value.Id ? "primary" : "inherit"
                          }
                          title={`${value.Name} Time`}
                        >
                          {value.Name}
                        </Button>
                      );
                    })}
                </div>
              </div>
            </div>
            <div className="col-md-2">
              <div className="e-input-in-wrap">
                <div style={{ width: "64%", display: "grid", marginLeft: 30 }}>
                  <ComboBoxComponent
                    id={isClientMeeting === false ? "tz-select" : "cltz-select"}
                    ref={(combobox) => {
                      listtz = combobox;
                    }}
                    name={
                      isClientMeeting === false ? "tz-select" : "cltz-select"
                    }
                    dataSource={
                      isClientMeeting === false ? timeZone : clientTimeZone
                    }
                    fields={{ text: "Name", value: "Id" }}
                    change={handleChangeMM}
                    placeholder="Time Zone"
                    value={timeZoneId}
                    popupHeight="220px"
                    cssClass="e-outline"
                    floatLabelType="Auto"
                    data-msg-containerid="timezoneError"
                    focus={handleOpentz}
                  />
                  {/* } */}
                  <div style={{ marginLeft: "-252px" }} id="timezoneError" className="error-div" />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* second row */}

        <div className="col-md-12 pt-xs">
          <div className="row">
            <div className="col-md-5">
              <div className="custom-margin custom-padding-5 material2">
                <div className="col-md-12">
                  <div className="e-input-group e-float-icon-left">
                    <FontAwesomeIcon
                      icon={faDesktop}
                      className="mt-sm fa-lg1"
                    />
                    <div className="e-input-in-wrap">
                      <div style={{ width: "100%", display: "grid" }}>
                        <ComboBoxComponent
                          ref={(combobox) => {
                            listmedia = combobox;
                          }}
                          id="mm-select"
                          name="mm-select"
                          dataSource={meetingMedia}
                          fields={{
                            text: "Name",
                            value: "Id",
                            iconCss: "Optional",
                          }}
                          change={handleChangeMM}
                          placeholder={
                            meetingTypeId === 7 ||
                              meetingTypeId === 844 ||
                              meetingTypeId === 885
                              ? "Meeting Media"
                              : "Meeting Media *"
                          }
                          value={meetingMediaId}
                          popupHeight="220px"
                          cssClass="e-outline"
                          floatLabelType="Auto"
                          data-msg-containerid="meetingmediaError"
                          focus={handleOpenmedia}
                          allowCustom={false}
                        />
                        <div id="meetingmediaError" className="error-div" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="row custom-margin custom-padding-5 material2">
                <div className="col-md-3">
                  <div className="row1 custom-margin custom-padding-5 material2">
                    <div className="e-input-group e-float-icon-left">
                      <FontAwesomeIcon icon={faClock} className="mt-sm fa-lg" />
                      {/* <span className="mt-sm ml-xs">Start</span> */}
                      <div
                        className="e-input-in-wrap"
                        style={{ marginLeft: "13px" }}
                      >
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <TimePicker
                            style={{ width: "100%" }}
                            className="mr-lg1"
                            inputVariant="outlined"
                            id="meeting-start-time"
                            name="meeting-start-time"
                            ampm={false}
                            value={meetingStartTime}
                            onChange={handleMeetingStartTimeChange}
                            // data-msg-containerid="meetingstarttimeError"
                            data-msg-containerid="meetingtimeError"
                            disabled={isFullDay}
                          />
                        </MuiPickersUtilsProvider>
                        {/* <div id="meetingstarttimeError" className="error-div" /> */}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="e-input-group e-float-icon-left">
                    <FontAwesomeIcon icon={faClock} className="mt-sm fa-lg" />
                    {/* <span className="mt-sm ml-sm">End</span> */}
                    <div className="e-input-in-wrap">
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <TimePicker
                          style={{ width: "100%" }}
                          className="mr-sm1"
                          inputVariant="outlined"
                          id="meeting-end-time"
                          name="meeting-end-time"
                          ampm={false}
                          value={meetingEndTime}
                          onChange={handleMeetingEndTimeChange}
                          // data-msg-containerid="meetingendtimeError"
                          data-msg-containerid="meetingtimeError"
                          disabled={isFullDay}
                        />
                      </MuiPickersUtilsProvider>
                    </div>
                  </div>
                  {/* <div id="meetingendtimeError" style={{ marginLeft: 67 }} className="error-div" /> */}
                  <div
                    id="meetingtimeError"
                    style={{ marginLeft: -135 }}
                    className="error-div"
                  />
                </div>
                <div className="col-md-3">
                  <div className="row">
                    {(!isClientMeeting)&& meetingTypeName !== 'Leave' && (
                      <div
                        className="col-md-1 p-none"
                        style={{ width: "auto" }}
                      >
                        {isFullDay ? (
                          <Button
                            className="btnFullDay"
                            color="primary"
                            title="Book All Day"
                            id="fulldaybooking"
                            size="small"
                            variant="contained"
                            onClick={() => handleMeetingFullDayBooking()}
                          >
                            {/* <FontAwesomeIcon icon={faCheckSquare} style={{margin:5}}/> */}
                            Full Day
                          </Button>
                        ) : (
                          <Button
                            className="btnFullDay"
                            title="Book All Day"
                            id="fulldaybooking"
                            size="small"
                            variant="contained"
                            onClick={() => handleMeetingFullDayBooking()}
                          >
                            {/* <FontAwesomeIcon icon={faCheckSquare}  style={{margin:5}}/> */}
                            Full Day
                          </Button>
                        )}
                      </div>
                    )}
                    {(!ExcluedPitchRecurrenceIds.includes(meetingSubTypeId)) &&
                      meetingTypeId !== 844 &&
                      meetingmode === "P" &&
                      ((meetingId >= 0 && recurring !== "") ||
                        meetingId === 0) && (
                        <div className="col-md-1">
                          {recurring !== "" ? (
                            <Button
                              className="pt-sm pb-sm"
                              color="primary"
                              title="Recurrence Meeting"
                              id="btnRecurring"
                              size="large"
                              variant="contained"
                              onClick={() => handleRecurring()}
                            >
                              <FontAwesomeIcon icon={faRecycle} />
                            </Button>
                          ) : (
                            <Button
                              className="pt-sm pb-sm"
                              title="Recurrence Meeting"
                              id="btnRecurring"
                              size="large"
                              variant="contained"
                              onClick={() => handleRecurring()}
                            >
                              <FontAwesomeIcon icon={faRecycle} />
                            </Button>
                          )}
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Third row */}

        <div className="col-md-12 pt-xs">
          <div className="row1">
            <div id="meeting-details" className="col-md-12" style={{ marginTop: 7 }}>
              <div className="custom-margin-top-15 custom-padding-5 material2">
                <div className="col-md-6" style={{ paddingLeft: 0 }}>
                  <div className="e-input-group e-float-icon-left">
                    <FontAwesomeIcon icon={faFileAlt} className="mt-sm fa-lg" />
                    <div
                      className="e-input-in-wrap"
                      style={{ marginLeft: "18px", display: "flex", flexDirection: "column" }}
                    >
                      <TextBoxComponent
                        placeholder={
                          !SelfBreakLeave.includes(meetingTypeId)
                            ? "Meeting Agenda *"
                            : "Meeting Agenda "
                        }
                        cssClass="e-outline"
                        floatLabelType="Auto"
                        ref={(scope) => {
                          textareaObj = scope;
                        }}
                        name="magenda-txt"
                        multiline
                        value={meetingAgenda}
                        inputProps={{
                          required: true,
                          minLength: 6,
                        }}
                        blur={handleFocusOut}
                        htmlAttributes={{ maxlength: 5000 }}
                        data-msg-containerid="agendaError"
                        input={onInput}
                      />
                      <div id="agendaError" className="error-div pt-xs" />
                    </div>
                  </div>
                </div>
                <div className="col-md-6" style={{ paddingRight: 0 }}>
                  <div className="e-input-group e-float-icon-left">
                    <FontAwesomeIcon icon={faSquareList} className="mt-sm fa-lg" />
                    <div
                      className="e-input-in-wrap"
                      style={{ marginLeft: "18px", display: "flex", flexDirection: "column" }}
                    >
                      <TextBoxComponent
                        placeholder={"Action Items"}
                        cssClass="e-outline"
                        floatLabelType="Auto"
                        ref={(scope) => {
                          textareaObjActionItem = scope;
                        }}
                        name="mactionitems-txt"
                        multiline
                        value={meetingActionItems}
                        inputProps={{
                          required: false,
                          minLength: 6,
                        }}
                        blur={handleFocusOut}
                        htmlAttributes={{ maxlength: 5000 }}
                        data-msg-containerid="actionitemsError"
                        input={onInputActionItem}
                      />
                      <div id="actionitemsError" className="error-div" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {openRecurring && (
          <MeetingRecurrence
            open={openRecurring}
            handleConfirmYes={handleConfirmYes}
            handleConfirmNo={handleConfirmNo}
            handleRemoveRecurrence={handleRemoveRecurrence}
            setCurrentRecurring={setCurrentRecurring}
          />
        )}
      </div>
    </div>
  );
}
MeetingDetails.propTypes = {
  // eslint-disable-next-line
  defaultsetting: PropTypes.arrayOf(Object),
  // eslint-disable-next-line
  timeZone: PropTypes.arrayOf(Object),
  // eslint-disable-next-line
  clientTimeZone: PropTypes.arrayOf(Object),
  meetingId: PropTypes.number,
  meetingTitle: PropTypes.string,
  meetingAgenda: PropTypes.string,
  meetingActionItems: PropTypes.string,
  meetingDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date)
  ]),
  meetingStartTime: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date)
  ]),
  meetingEndTime: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date)
  ]),
  meetingMediaId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  timeZoneId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  isFullDay: PropTypes.bool,
  isClientMeeting: PropTypes.bool,
  recurring: PropTypes.string,
  meetingmode: PropTypes.string,
  meetingTypeId: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  agendaDocument: PropTypes.string,
  authUser: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  // eslint-disable-next-line
  meetingMedia: PropTypes.arrayOf(Object),
  meetingTypeName: PropTypes.string,
};

MeetingDetails.defaultProps = {
  timeZone: [],
  clientTimeZone: [],
  meetingId: 0,
  meetingTitle: "",
  meetingAgenda: "",
  meetingActionItems: "",
  meetingMediaId: 52,
  timeZoneId:
    typeof dencrypt(localStorage.getItem("def_timezone")) === "undefined"
      ? ""
      : parseInt(dencrypt(localStorage.getItem("def_timezone")), 10),
  isFullDay: false,
  isClientMeeting: false,
  recurring: "",
  meetingmode: "P",
  meetingTypeId: 0,
  // agendaDocument: "",
  authUser: "",
  meetingMedia: [],
  meetingTypeName: ''
};

const mapStateToProps = ({
  meetingbooking,
  meetingmaster,
  usersettingreducer,
  auth,
}) => {
  const {
    meetingId,
    meetingTitle,
    meetingAgenda,
    meetingActionItems,
    meetingDate,
    meetingStartTime,
    meetingEndTime,
    meetingMediaId,
    timeZoneId,
    participateCode,
    meetingWeblinkType,
    meetingLocation,
    meetingWebLink,
    isFullDay,
    isClientMeeting,
    recurring,
    meetingmode,
    meetingTypeId,
    // agendaDocument,
    getExcludeSundayHoliday,
    meetingTypeName,
    meetingSubTypeId,
    zoomMeetingId,
    zoomMeetingHostEmailId,
    oldZoomMeeting
  } = meetingbooking;
  const { meetingMedia, timeZone, clientTimeZone } = meetingmaster;
  const { defaultsetting, defaultsettingId, saveSetting } = usersettingreducer;
  const { authUser } = auth;
  return {
    meetingId,
    meetingTitle,
    meetingAgenda,
    meetingActionItems,
    meetingDate,
    meetingStartTime,
    meetingEndTime,
    meetingMediaId,
    timeZoneId,
    participateCode,
    meetingWeblinkType,
    meetingLocation,
    meetingWebLink,
    isFullDay,
    // agendaDocument,
    getExcludeSundayHoliday,
    isClientMeeting,
    saveSetting,
    recurring,
    meetingMedia,
    timeZone,
    meetingmode,
    meetingTypeId,
    clientTimeZone,
    defaultsetting,
    defaultsettingId,
    authUser,
    meetingTypeName,
    meetingSubTypeId,
    zoomMeetingId,
    zoomMeetingHostEmailId,
    oldZoomMeeting
  };
};

export default connect(mapStateToProps, {
  updateMeetingBookingState,
})(MeetingDetails);
