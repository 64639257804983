import * as React from "react";
import { AutoCompleteComponent } from "@syncfusion/ej2-react-dropdowns";
import { NumericTextBoxComponent } from "@syncfusion/ej2-react-inputs";
import orgChatMaster from "../../../services/entryexit/onbordingServices";
import { connect } from "react-redux";
import { Query } from "@syncfusion/ej2-data";
import { setOrhChartDetaila } from "../../../redux/actions/entryexit/orgchartaction";
import { Button } from "@material-ui/core";
import { addCostSharingLimit } from '../../../config/config'
class EmployeeCostSharing extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            employeeType: [],
            costSharingLimit: this.props.getCostSharing.length === 0 ? [{ reportingHead: '', sharingCost: 0, id: 1 }] : this.props.getCostSharing,
            reportingHeadList: []
        }
    }
    componentDidMount() {
        try {
            const { authUser } = this.props;
            const getMasterData = Promise.resolve(
                orgChatMaster.getMasterData("ORH", authUser, null)
            );
            getMasterData.then((resultData) => {
                if (resultData !== null) {
                    this.setState({ reportingHeadList: resultData });
                } else {
                    this.data = "error";
                }
            });
        } catch (error) {

        }
    }
    getAllMasterData = () => {
        const { authUser } = this.props;
        try {
            const getMasterData = Promise.resolve(
                orgChatMaster.getMasterData("All", authUser)
            );
            getMasterData.then((resultData) => {
                if (resultData !== null) {
                    const bandarr = resultData.filter((item) => {
                        return item.Type === "Grade";
                    });

                    this.setState({
                        bandList: bandarr,
                    });
                } else {
                    this.data = "error";
                }
            });
        } catch (e) {
            // eslint-disable-next-line
            console.log(e);
        }
    }
    addUpdateCostSharing = (e, index) => {
        document.getElementById('allErrMsg').innerHTML = "";
        let temp = this.state.costSharingLimit;
        temp[index].sharingCost = parseFloat(e.value);
        let total = 0;
        // eslint-disable-next-line
        temp.map((data) => {
            total += parseFloat(data.sharingCost, 10);
        })
        if (total > 100 || total < 100) {
            document.getElementById('allErrMsg').innerHTML = "Cost sharing should not be greater or lesser than 100 %";
            return;/**NOSONAR */
        } 
        // else if (total < 100) {
        //     document.getElementById('allErrMsg').innerHTML = "Cost sharing should not be greater or lesser than 100 %";
        //     return;
        // } 
        else {
            this.props.setOrhChartDetaila({ costSharing: temp });
        }
    }
    addUpdateReportingHead = (e, index) => {
        let temp = this.state.costSharingLimit;
        temp[index].reportingHead = e.value;
        this.props.setOrhChartDetaila({ costSharing: temp });
    }
    getReportingList = (e) => {
        const { authUser } = this.props;
        const { reportingHeadList } = this.state;
        try {
            if (reportingHeadList.length === 0) {
                const getMasterData = Promise.resolve(
                    orgChatMaster.getMasterData("ORH", authUser, null)
                );
                getMasterData.then((resultData) => {
                    if (resultData !== null) {
                        this.setState({ reportingHeadList: resultData });
                        let query = new Query();
                        query =
                            e.text !== ""
                                ? query.where("Name", "contains", e.text, true)
                                : query;
                        e.updateData(resultData, query);
                    } else {
                        this.data = "error";
                    }
                });
            } else {
                let query = new Query();
                query =
                    e.text !== ""
                        ? query.where("Name", "contains", e.text, true)
                        : query;
                e.updateData(this.state.reportingHeadList, query);
            }

        } catch (error) {
            console.log(error);
        }
    };
    addCostSharingTemplate = (item, index) => {
        const { reportingHeadMaster } = this.props;
        const { costSharingLimit } = this.state;
        this.props.setOrhChartDetaila({ costSharing: costSharingLimit });
        return (
            <div className='col-lg-10' style={{ padding: "0px" }}>
                <form id="costSharing" method="post" className="control-section col-md-12">
                    <div className="row card-layout"
                        style={{
                            paddingLeft: "15px",
                            paddingRight: "15px",
                            paddingTop: "10px",
                        }}>
                        <div className="col-xs-2 col-sm-2 col-lg-6 col-md-2">
                            <div>
                                <AutoCompleteComponent
                                    id={`reporting${index}`}
                                    dataSource={reportingHeadMaster}
                                    fields={{ text: "Name", value: "Id" }}
                                    value={item.reportingHead}
                                    data-msg-containerid="reportingErr"
                                    name="reportingErr"
                                    allowFiltering
                                    allowCustom={false}
                                    floatLabelType="Auto"
                                    cssClass="e-outline"
                                    placeholder="Select Reporting Supervisor *"
                                    popupHeight="350px"
                                    filtering={(e) => this.getReportingList(e)}
                                    change={(e) => this.addUpdateReportingHead(e, index)}
                                />
                                <div
                                    id="reportingErr"
                                    className="e-input-group e-float-icon-left error-div"
                                />
                            </div>
                        </div>
                        <div className="col-xs-2 col-sm-2 col-lg-4 col-md-2">
                            <div>
                                <NumericTextBoxComponent
                                    id={`costSharing${index}`}
                                    value={parseFloat(item.sharingCost)}
                                    data-msg-containerid={`costSharingErr`}
                                    name={`costSharingErr`}
                                    floatLabelType="Auto"
                                    cssClass="e-outline"
                                    placeholder={`Cost Sharing % *`}
                                    change={(e) => this.addUpdateCostSharing(e, index)}
                                />
                                <div
                                    id={`costSharingErr`}
                                    className="e-input-group e-float-icon-left error-div"
                                />
                            </div>
                        </div>
                        <div className="col-xs-2 col-sm-2 col-lg-1 col-md-2">
                            <div>
                                <Button id={`remove${index}`} color="default" variant="contained" onClick={() => {
                                    document.getElementById("allErrMsg").innerHTML = ""
                                    let temp = this.state.costSharingLimit;
                                    let removeitem = item;
                                    let t = [];
                                    // eslint-disable-next-line
                                    temp.map((data, i) => {
                                        if (data.id !== removeitem.id) {
                                            t.push(data);
                                        }
                                    })
                                    temp = t;
                                    if (temp.length > 0) {
                                        this.setState({
                                            costSharingLimit: temp
                                        })
                                        this.props.setOrhChartDetaila({ costSharing: temp });
                                    }
                                    else {
                                        document.getElementById("allErrMsg").innerHTML = "Please tag reporting head"
                                    }
                                }} > Remove</Button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        )
    }
    render() {
        return (
            <div>
                {/* <NotificationContainer /> */}
                <div className='col-lg-12' style={{ paddingRight: '7px' }}>
                    <div className="col-xs-2 col-sm-2 col-lg-11 col-md-2" style={{ marginLeft: '-15px' }}> Add / Edit cost sharing in (%) </div>
                    <div className="col-xs-2 col-sm-2 col-lg-1 col-md-2" style={{ paddingLeft: "-1px" }}>
                        <div>
                            <Button color="primary" variant="contained" onClick={() => {
                                document.getElementById("allErrMsg").innerHTML = ""
                                let temp = this.state.costSharingLimit;
                                let data = { reportingHead: '', sharingCost: 0, id: this.state.costSharingLimit.length + 1 };
                                if (temp.length < addCostSharingLimit) {
                                    temp.push(data);
                                    this.setState({
                                        costSharingLimit: temp
                                    })
                                } else {
                                    document.getElementById("allErrMsg").innerHTML = "You can add / share with maximum 5 reportees"
                                }
                            }} > Add </Button>
                        </div>
                    </div>
                </div>
                {
                    this.state.costSharingLimit.length > 0 &&
                    this.state.costSharingLimit.map((item, index) => {
                        return this.addCostSharingTemplate(item, index)
                    })
                }
                <div className="col-xs-2 col-sm-2 col-lg-12 col-md-2">
                    <div
                        id="allErrMsg"
                        className="e-input-group e-float-icon-left error-div"
                    />
                </div>
            </div >
        )
    }
}
const mapStateToProps = ({ auth, orgchartreducer }) => {
    const { authUser } = auth;
    const { employeeId, fistName, lastName, designation, titleRole, employmentType, entityId, serviceDevision, reportingSupervison, band, emailId, country, location, functiondata, costSharingReporting, costSharing } = orgchartreducer;
    return {
        authUser, employeeId, fistName, lastName, designation, titleRole, employmentType, entityId, serviceDevision, reportingSupervison, band, emailId, country, location, functiondata, costSharingReporting, costSharing
    };
};
export default connect(mapStateToProps, { setOrhChartDetaila })(EmployeeCostSharing);