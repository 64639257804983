import React, { Component } from 'react';

export default class KraKpi extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
      }
  render() {
    return (
        <div className='tab-container'>
              <div style={{alignSelf: 'center',}}>
                      {/* <p style={{position:'absolute', textAlign:'center'}}> In Progress</p> */}
                         <div className='col-12 appointment-table-header'>
             <div className="col-12 appointment-table " >
              </div>
              <div className='col-12 appointment-table'>
                   <h4><div style={{color:'#4D4B56'}}><span style={{color:'#EB5465'}}>Work In Progress</span></div></h4>
              </div>    
            </div></div>
        </div>
    );
  }
}