import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { getMeetingDetails } from "../actions/meetingbookingaction";
import { addUpdateNotificationSettings } from "../actions/settingaction";
import { getNotify } from "../../app/other/commonfunction";
import { SUCCESS, ERROR } from "../../assets/constants/constants";

import {
  USER_SETTING_CONFIG_SUCCESS,
  ADDEDITWORKDAYSETTINGS,
  ADD_NOTIFICATION_SETTINGS,
} from "../constant/settingconstant";

import { urlConfig } from "../../config/config";

const saveSattingRequest = async (payload) => {
  // eslint-disable-next-line
  return axios
    .post(
      `${urlConfig.apiurl}usersettingdetails/${payload.userid}/${payload.id}`,
      {
        time_scale: payload.time_scale,
        time_zone: payload.time_zone,
      },
      {
        headers: {
          authorization: localStorage.getItem("jwtToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((result) => result)
    .catch((error) => error.response);
};
// Author By:- Prashant Waphare | 13-08-2020
const addEditWorkDaySettingsRequest = async (payload) => {
  // eslint-disable-next-line
  return axios
    .post(
      `${urlConfig.apiurl}workdaysettings`,
      {
        shiftStartDateTime: payload.shiftStartDateTime,
        shiftEndDateTime: payload.shiftEndDateTime,
        timeZoneForWorkDay: payload.timeZoneForWorkDay,
        shiftStartTime: payload.shiftStartTime,
        shiftEndTime: payload.shiftEndTime,
        nextDayFlagData: payload.nextDayFlagData,
        authUser: payload.authUser,
        type: payload.type,
      },
      {
        headers: {
          authorization: localStorage.getItem("jwtToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((result) => result)
    .catch((error) => error.response);
};
/**
 * @author Vivek Khodade (21-08-2020)
 * @param {*} payload
 * @description Request For Notification settings data save.
 */
const addNotificationSettingsDetailsRequest = async (payload) => {
  // eslint-disable-next-line
  return axios
    .post(`${urlConfig.apiurl}addNotificationSettings`, payload, {
      headers: {
        authorization: localStorage.getItem("jwtToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((result) => result)
    .catch((error) => error.response);
};

/**
 * @author Suresh (21-04-2021)
 * @param {*} payload
 * @description Updating Notification  data save.
 */
const UpdateNotificationContentRequest = async (payload) => {
  // eslint-disable-next-line
 
  return axios
    .post(`${urlConfig.apiurl}addNotificationSettings/update`, payload, {
      headers: {
        authorization: localStorage.getItem("jwtToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((result) => result)
    .catch((error) => error.response);
};

function* saveSattingSuccess({ payload }) {
  try {
    const savesetting = yield call(saveSattingRequest, payload);
    if (savesetting.status === 200)
      yield put(getMeetingDetails({ meetingStatus: "updated", updateTimeZoneChangesStatus: "updated" }));
  } catch (error) {
    // eslint-disable-next-line
    console.error(error);
  }
}
// Author By:- Prashant Waphare | 13-08-2020
function* addEditWorkDaySettings({ payload }) {
  try {
    const workDaySetting = yield call(addEditWorkDaySettingsRequest, payload);
    if (workDaySetting.status === 200) {
      if (payload.type === "Add") {
        yield put(
          getMeetingDetails({ workDayStatus: "Add" })
        );
      } else if (payload.type === "Delete") {
        yield put(
          getMeetingDetails({ workDayStatus: "Delete" })
        );
      } else {
        yield put(
          getMeetingDetails({ workDayStatus: "Update" })
        );
      }
      yield put(getMeetingDetails({ meetingStatus: "WorkDayUpdated" }));
    } else {
      setTimeout(() => {
        getNotify(ERROR, "Internal server error !");
      }, 100);
    }
  } catch (error) {
    console.error(error);
  }
}
// Author By:- Vivek Khodade | 21-08-2020
function* addNotificationSettingsDetails({ payload }) {
  try {
    const notificationSettings = yield call(
      addNotificationSettingsDetailsRequest,
      payload
    );
    if (notificationSettings.status === 200) {
      setTimeout(() => {
        getNotify(SUCCESS, "Notification saved successfully.");
      }, 1000)
    } else {
      getNotify(
        ERROR,
        notificationSettings.data.message.replace("payload: ", "")
      );
    }
    yield put(getMeetingDetails({ getNotifyFlag: true }));
    yield put(
      addUpdateNotificationSettings({ notificationSettingsStatus: "updated" })
    );
    // Author By: - Suresh Sargam | 24-04-2021 to update the notification data once setting is saved
    yield call(UpdateNotificationContentRequest, { userId: payload.userId });
  } catch (error) {
    // eslint-disable-next-line
    console.error(error);
  }
}

export function* userSettingSuccess() {
  yield takeEvery(USER_SETTING_CONFIG_SUCCESS, saveSattingSuccess);
}
// Author By:- Prashant Waphare | 13-08-2020
export function* addEditWorkDaySettingsSuccess() {
  yield takeEvery(ADDEDITWORKDAYSETTINGS, addEditWorkDaySettings);
}
/**
 * @author Vivek Khodade
 * @description Add notification settings details
 */
export function* addNotificationSettings() {
  yield takeEvery(ADD_NOTIFICATION_SETTINGS, addNotificationSettingsDetails);
}

export default function* rootSaga() {
  yield all([
    fork(userSettingSuccess),
    fork(addEditWorkDaySettingsSuccess),
    fork(addNotificationSettings),
  ]);
}
