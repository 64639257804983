// Author:- Prashant Waphare
// date :- 13-02-2020
// discription:- This component use for show logout page.
import React, { Component } from "react";

// eslint-disable-next-line
class Logout extends Component {
  render() {
    return (
      <div
        style={{
          textAlign: "center",
          position: "absolute",
          top: "50%",
          bottom: "50%",
          left: "0",
          right: "0",
        }}
      >
        <div
          style={{
            font: "icon",
            display: "inline-table",
            width: "50%",
            background: "#ecebe9",
            color: "#07090d",
            fontWeight: "bold",
          }}
        >
          <h3 style={{ marginTop: "20px", marginBottom: "20px" }}>
            You have successfully logged out.
          </h3>
        </div>
      </div>
    );
  }
}
export default Logout;
