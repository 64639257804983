// Author by:- Prashant Waphare | 31-08-2020 | Add all action home office.

import {
  SAVE_BASIC_HOME_DATA,
  ADD_EDIT_HOME_OFFICE_DETAILS,
  SAVE_HOME_OFFICE_DETAILS,
} from "../../constant/homeoffice/homeofficeconstant";

export const saveHomeBasicInfoRedux = (data) => {
  return {
    type: SAVE_BASIC_HOME_DATA,
    payload: data,
  };
};
export const addEditHomeOfficeDetails = (data) => {
  return {
    type: ADD_EDIT_HOME_OFFICE_DETAILS,
    payload: data,
  };
};
export const saveHomeOfficeDetails = (data) => {
  return {
    type: SAVE_HOME_OFFICE_DETAILS,
    payload: data,
  };
};
