import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBarsFilter, faCircleXmark } from "@fortawesome/pro-light-svg-icons";
import { faCheckCircle, faEye } from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";
import {
    GridComponent
} from "@syncfusion/ej2-react-grids";
function DataGrid(props) {
const setHeaderCss = () => {
    // setTimeout(() => {
        const ele = document.getElementsByClassName("e-headercelldiv");
        for (let i in ele) {
        if (!isNaN(parseInt(i, 10))) {
            let index = parseInt(i, 10);
            if (ele[index].innerText === "Action") {
            ele[index].classList.add("grid-center-header");
            } else {
            ele[index].classList.add("grid-left-header");
            }
        }
        }
    // }, 500);
}
const styles = `
    #${props.id}Grid{
        overflow-y: auto;
        border-left: 0px;
        border-bottom: 0px;
        ${props.windowWidth < props.width ? 'border-right: 0px;' : ''}
        ${!props.bordered ? 'border: none;': ''}
    }

    #${props.id}Grid.e-grid .e-hide {
        display: none !important;
    }

    #${props.id} .e-headercontent .e-columnheader .e-headercell {
        background-color: #F2F2F2 !important;
    }

    #${props.id}Grid .e-gridheader.e-lib.e-droppable .e-headercontent {
        min-height: 3.2rem !important;
        max-height: 3.2rem !important;
    }

    @media screen and (max-width: 1366px) and (max-height: 768px), screen and (max-width: 1440px) and (max-height: 900px) {
        #${props.id} .e-headercontent .e-columnheader .e-headercell .e-headertext {
            font-size: .8rem !important;
        }

        #${props.id}Grid .e-gridcontent .e-content .e-row .e-rowcell {
            font-size: .8rem !important;
        }

        #${props.id}Grid .e-gridheader.e-lib.e-droppable .e-headercontent {
            min-height: 2.9rem !important;
            max-height: 2.9rem !important;
        }
    }
 `;

 const paginationPadding = `
    #${props.id}Grid .e-gridheader.e-lib.e-droppable {
        padding-right: 0 !important;
    }
 `;

  
    const headerCellInfo = (args) => {
        let checkboxEle = args.node.querySelector('.e-checkbox-wrapper');
        if (checkboxEle) {
            checkboxEle.classList.add('e-hide');
        }
    }
    const dataSourceChanged = (pComponentId) => {
        try {
            setTimeout(() => {
              if (document.getElementById(pComponentId) !== null) {
                document.getElementById(pComponentId).ej2_instances[0].refresh();
                setHeaderCss();
              }
            });
          } catch (error) {
            console.log(error);
          }
    };

    const onDataBinding = (pComponentId) => {
        try {            
            setTimeout(() => {
                document.getElementById(pComponentId).ej2_instances[0].refresh();
            });
        } catch (error) {
            console.log(error);
        }
    };
    const clearFilter = () => {
        document.getElementById(props.id + "Grid").ej2_instances[0].clearFiltering()
    }

    let statusData=[];
    const unique = [...new Set(props.dataSource?.map(item => item.Status))];
    //NOSONAR
    unique?.forEach((item)=>{ 
    let obj={};
    obj={
        Name:item,
        count:props.dataSource?.filter(t => t.Status===item).length
    }
    statusData.push(obj);
    })
    setHeaderCss();
    return (
        <>
            <style>{styles}</style>
            <style>{props.isPaginationEnable ? paginationPadding : null}</style>
            {props.showGridHeader && <div className="ticket-stats pl-md pt-sm pb-sm pr-md">
                <div className="common-list-title">{props.mainTitle}</div>
                <div className="approval-list-right-part">
                    <div className="approval-list-element approval-list-states">
                        <div className="approval-list-states-element">Total - {props.dataSource.length}</div>
                        {
                            statusData?.map((item)=>{
                                return(
                                    <div className="approval-list-states-element">{item.Name} - {item.count}</div>
                                )
                            })
                        }
                    </div>
                    <div className="approval-list-element approval-list-filter" style={{ cursor: "pointer" }}
                    id={props.id + "filter"} onClick={clearFilter}
                    >
                        <div className="approval-list-filter-icon">
                            <FontAwesomeIcon
                                icon={faBarsFilter}
                                style={{ cursor: "pointer", fontSize: ".9rem", color: "#fff" }}
                            />
                        </div>
                        <div>Reset Filters</div>
                    </div>
                </div>
            </div>}
            {/** Grid */}
                <div id={props.id} style={{ "--approvalPadding":  "16px"}}>
                    <GridComponent
                        id={`${props.id}Grid`}
                        allowPaging={props.allowPaging}
                        gridLines={props.gridLines} 
                        // style={{ borderLeft: "0px", borderRight: "0px", borderBottom: "0px",  }}
                        dataSource={props.dataSource}
                        pageSettings={props.pageSettings}
                        allowTextWrap={props.allowTextWrap}
                        rowHeight={props.rowHeight}
                        height={props.height}
                        width={props.width}  
                        allowFiltering={props.allowFiltering}
                        filterSettings={props.filterSettings}
                        allowSorting={props.allowSorting}
                        allowSelection={props.allowSelection}
                        headerCellInfo={headerCellInfo}
                        dataSourceChanged={() => dataSourceChanged(`${props.id}Grid`)}
                        dataBound={props.dataBound}
                        onDataBinding={() => onDataBinding(props.id)}
                        actionBegin={props.actionBegin}
                    >
                        {
                            props.columnDirectives()
                        }
                        {
                            props.gridEjectParameters()
                        }
                    </GridComponent>
                </div>          
        </>
    )
}
DataGrid.propTypes = {
    id: PropTypes.string.isRequired,
    cssClass: PropTypes.string,
    gridLines: PropTypes.string,
    allowPaging: PropTypes.bool,
    ref: PropTypes.object,
    dataSource: PropTypes.array,
    pageSettings:PropTypes.object,
    allowTextWrap:PropTypes.bool,
    rowHeight:PropTypes.number,
    height:PropTypes.number,
    width:PropTypes.number,
    allowFiltering:PropTypes.bool,
    filterSettings:PropTypes.object,
    allowSorting:PropTypes.bool,
    actionComplete: PropTypes.func,
    allowSelection:PropTypes.bool,
    headerCellInfo: PropTypes.func,
    dataSourceChanged: PropTypes.func,
    dataBound: PropTypes.func,
    onDataBinding: PropTypes.func,
    actionBegin: PropTypes.func,
    columnDirectives: PropTypes.func.isRequired,
    gridEjectParameters: PropTypes.func.isRequired,
    showGridHeader: PropTypes.bool,
    isPaginationEnable: PropTypes.bool,
    bordered: PropTypes.bool
};

DataGrid.defaultProps = {
    allowFiltering: true,
    cssClass:"",
    gridLines:"Both",
    ref: null,
    pageSettings:{pageCount: 5,pageSize:10},
    dataSource:[],
    allowTextWrap:true,
    rowHeight:50,
    height:0,
    width:0,
    filterSettings:{type: "Menu"},
    allowSorting:true,
    actionComplete: () => { },//NOSONAR
    allowSelection:false,
    headerCellInfo: () => { },//NOSONAR
    dataSourceChanged: () => { },//NOSONAR
    dataBound: () => { },//NOSONAR
    onDataBinding: () => { },//NOSONAR
    actionBegin: () => { },//NOSONAR
    showGridHeader: true,
    isPaginationEnable: true,
    bordered: true,
};


export default DataGrid