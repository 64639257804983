import React, { Component } from 'react';
import { NumericTextBoxComponent } from '@syncfusion/ej2-react-inputs';
import PropTypes from "prop-types";
import { connect } from "react-redux"
import SeparationServices from '../../../services/entryexit/separationServices';
import { addUpdateSeparationDetails } from '../../../redux/actions/entryexit/separationaction';
import { dencrypt } from "../../../app/other/commonfunction";

class RecoveryAmount extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  getClearanceRecoveryAmt = async () => {
    try {
      const { search } = window.location;
      const params = await new URLSearchParams(search); // NOSONAR
      const empId = await dencrypt(params.get("empId")); // NOSONAR
      const { authUser } = this.props;
      const employeeSeparationClearanceData = Promise.resolve(
        SeparationServices.getEmployeeClearanceData(
          "amt",
          authUser,
          empId,
        )
      );
      employeeSeparationClearanceData.then((result) => {
        if (result !== null && result.length > 0) {
          this.props.addUpdateSeparationDetails({ recoveryAmt: result[0].recoveryAmt })
        }
      });
    } catch (error) {

    }
  }
  componentDidMount = () => {
    this.getClearanceRecoveryAmt();
  };

  render() {
    const { recoveryAmt, departmentalText } = this.props;
    return (
      <div>
        { 
        (departmentalText === 'Info Sec' || departmentalText === 'Networking & Hardware' || departmentalText === 'People Function') &&
          <div className='col-12' style={{ background: '#fff', padding: 10, textAlign: 'center', flex: 1, marginTop: 10 }}>
            <div className='col-2' style={{ textAlign: 'center', marginLeft: '43.5%' }} >
              <NumericTextBoxComponent id='recoveryAmount'
                placeholder="Recovery Amount" floatLabelType="Auto" cssClass="e-outline"
                change={(e) => this.props.addUpdateSeparationDetails({ recoveryAmt: e.value })}
                value={recoveryAmt}
                min={0}
              />
            </div>
          </div>
        }
      </div>
    );
  }
}
RecoveryAmount.propTypes = {
  authUser: PropTypes.string,
};
RecoveryAmount.defaultProps = {
  authUser: "",
};
const mapStateToProps = ({ auth, separationreducer }) => {
  const { authUser, departmentalText } = auth;
  const { recoveryAmt, recoveryAmountStatus } = separationreducer;
  return { authUser, recoveryAmt, recoveryAmountStatus, departmentalText };
};

export default connect(mapStateToProps, { addUpdateSeparationDetails })(RecoveryAmount);