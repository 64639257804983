import React, { Component } from 'react'
import PropTypes, { object } from "prop-types";
import { Button } from "@material-ui/core";
import { connect } from "react-redux";
import { getNotify, dencrypt } from "../../app/other/commonfunction";
import {
  ERROR, impactPerformancePersentage, impactPotentialPersentage, impactPerformancePotentialPersentage, variablePersentage, variablePerformancePersentage, variablePotentialPersentage, productivityAllocationPopHeaderMessage, productivityAllocationPopupSubMessage, impactRangeInfo,
  impactPercentageInfo,
  FrequencyInfo, variableImpactpercentageInfo, ratingMechanismInfo
} from "../../assets/constants/constants";
import OKRServices from "../../services/okr/okrservices";
import {
  updateUserProductiveAllcation,
  setCentralOkrKPIRedux,
} from "../../redux/actions/okr/okractions";
import CustomMaterialTooltip from '../custommaterialtooltip/custommaterialtooltip'
import ConfirmationPopup from '../../utilities/confirmationpopup'
import './productiveallocation.css';
import { result } from 'lodash';
let tableBorder = `
table,th,tr,td{
    border: 1px solid #DBDBDD;
    border-collapse: collapse;
    }
table{
    width:100%;
}
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }

    /* Firefox */
    input[type=number] {
    -moz-appearance: textfield;
    }
`;
class OkrProductivityAllocation extends Component {
  constructor(props) {
    super(props)
    this.state = {
      editFlag: false,
      openPopUp: false,
      productivityallocationData: [],
      ratingMechanism: [],
      performanceData: [],
      potentialData: [],
    }
  }
  getUserWiseProductiveAllocation() {
    try {
      const { authUser } = this.props;
      const { search } = window.location;
      const params = new URLSearchParams(search);
      const empId = dencrypt(params.get("empId"));
      const userWiseProductivityAllocation = Promise.resolve(
        OKRServices.getUserWiseProductivityAllocation(authUser, empId, "ALL", 0)
      );
      userWiseProductivityAllocation.then((resultData) => {
        if (resultData !== null && typeof result !== 'undefined' && resultData.length > 0) {
          // eslint-disable-next-line
          this.props.setCentralOkrKPIRedux({
            isPrdAllocationDone: resultData[0].isSubmitted === "1",
          });
          this.setState({ productivityallocationData: resultData });
        } else {
          this.data = "error";
          // eslint-disable-next-line
          this.props.setCentralOkrKPIRedux({ isPrdAllocationDone: false });
        }
      });
    } catch (e) {
      // eslint-disable-next-line
      console.log(e);
    }
  }

  componentDidMount = () => {
    const { OkrMasters, productivityallocationData } = this.props;
    const ratingMechnisms = OkrMasters.filter((item) => {
      return item.var_list_name === "RatingMechanism";
    }).sort((a, b) => {
      const nameA = a.Name.toUpperCase(); // ignore upper and lowercase
      const nameB = b.Name.toUpperCase(); // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });

    const performanceDataArr = productivityallocationData.filter((item) => {
      return item.type === "Performance";
    });
    const potentialDataArr = productivityallocationData.filter((item) => {
      return item.type === "Potential";
    });
    let performanceData = [];
    performanceDataArr?.forEach((item) => {
      let obj = {
        "type": "Performance",
        "performanceId": item.Id,
        "prodPerformanceAllocId": item.prodAllocId,
        "userPerformanceImpactPerc": item.userImpactPerc,
        "allocationPerformanceName": item.allocationName,
        "impactPerformanceRangeStartPerc": item.impactRangeStartPerc,
        "impactPerformanceRangeEndPerc": item.impactRangeEndPerc,
        "enumPerformanceFrequency": item.enumFrequency,
        "frequencyPerformance": item.frequency,
        "oteVariablePerformanceImpactPerc": item.oteVariableImpactPerc,
        "isPerformanceSubmitted": item.isSubmitted,

        "enumPerformanceVariableLink": item.enumVariableLink,
        "variablePerformanceLinked": item.variableLinked,
        "ratingPerformanceMechanismPercStart": item.ratingMechanismPercStart,
        "ratingPerformanceMechanismPercEnd": item.ratingMechanismPercEnd,
        "ratingPerformanceMechanismId": item.ratingMechanismId,
        "ratingPerformanceMechanisms": item.ratingMechanisms,
        "ratingPerformanceMechanismsId": item.ratingMechanismsId,
        "isEditable": item.isEditable,
      }
      performanceData.push(new Object(obj));
    })
    let potentialData = [];
    potentialDataArr?.forEach((item) => {
      let obj = {
        "potentialId": item.Id,
        "prodpotentialAllocId": item.prodAllocId,
        "userPotentialImpactPerc": item.userImpactPerc,
        "allocationPotentialName": item.allocationName,
        "impactPotentialRangeStartPerc": item.impactRangeStartPerc,
        "impactPotentialRangeEndPerc": item.impactRangeEndPerc,
        "enumPotentialFrequency": item.enumFrequency,
        "frequencyPotential": item.frequency,
        "oteVariablePotentialImpactPerc": item.oteVariableImpactPerc,
        "isPotentialSubmitted": item.isSubmitted,

        "enumPotentialVariableLink": item.enumVariableLink,
        "variablePotentialLinked": item.variableLinked,
        "ratingPotentialMechanismPercStart": item.ratingMechanismPercStart,
        "ratingPotentialMechanismPercEnd": item.ratingMechanismPercEnd,
        "ratingPotentialMechanismId": item.ratingMechanismId,
        "ratingPotentialMechanisms": item.ratingMechanisms,
        "ratingPotentialMechanismsId": item.ratingMechanismsId,
        "isEditable": item.isEditable,
      }
      potentialData.push(new Object(obj));
    })

    this.setState({ ratingMechanism: ratingMechnisms, productivityallocationData: productivityallocationData, performanceData: performanceData, potentialData: potentialData });
  };
  getFlag = (idx, performanceData) => {
    const { potentialData } = this.state;
    if (potentialData.length === 0 && idx === performanceData.length - 1) {
      return 'Yes'
    } else {
      return 'No'
    }
    // NOSONAR
  }

  submitUserProductivityData = async () => {
    const { potentialData, performanceData } = this.state;
    const { authUser, employeeId, currentFinancialYearId } = this.props;
    performanceData.forEach((data, idx, performanceItemData) => {
      // eslint-disable-next-line
      this.props.updateUserProductiveAllcation({
        empId: employeeId,
        userId: authUser,
        OKRProdAllocId: data.prodPerformanceAllocId,
        impactPerc: data.userPerformanceImpactPerc,
        enumFrequency: data.enumPerformanceFrequency,
        enumVariableLinked: data.oteVariablePerformanceImpactPerc > 0 ? "1" : "0",
        intOTEVariableImpactPerc: data.oteVariablePerformanceImpactPerc,
        financialYearId: currentFinancialYearId,
        OkrUsrProdAllocUid: data.performanceId,
        type: "UPDATE",
        ratingMechanismsId: parseInt(data.ratingPerformanceMechanismId, 10),
        sendMessage: this.getFlag(idx, performanceItemData)
      });
    });
    potentialData.forEach((data, idx, potentialItemData) => {
      // eslint-disable-next-line
      this.props.updateUserProductiveAllcation({
        empId: employeeId,
        userId: authUser,
        OKRProdAllocId: data.prodpotentialAllocId,
        impactPerc: data.userPotentialImpactPerc,
        enumFrequency: data.enumPotentialFrequency,
        enumVariableLinked: data.oteVariablePotentialImpactPerc > 0 ? "1" : "0",
        intOTEVariableImpactPerc: data.oteVariablePotentialImpactPerc,
        financialYearId: currentFinancialYearId,
        OkrUsrProdAllocUid: data.potentialId,
        type: "UPDATE",
        ratingMechanismsId: parseInt(data.ratingPotentialMechanismId, 10),
        sendMessage: idx === potentialData.length - 1 ? 'Yes' : 'No'
      });
    });
    setTimeout(() => {
      this.props.setCentralOkrKPIRedux({ isPrdAllocationDone: true });
      this.setState({
        editFlag: false
      })
      this.closePopup();
    }, 1000);
  }
  openPopUp = () => {
    this.setState({
      openPopUp: true
    })
  }
  closePopup = () => {
    this.setState({
      openPopUp: false
    })
  }
  render() {/**NOSONAR */
    const { editFlag, ratingMechanism, openPopUp, performanceData, potentialData } = this.state;
    const { isPrdAllocationDone, setHeight, enableAction } = this.props;
    let totalPerformaceUserImpactPerc = 0;
    let totalVariablePerformanceImpactPerc = 0;
    if (performanceData.length) {
      // eslint-disable-next-line
      performanceData.forEach((data) => {
        totalPerformaceUserImpactPerc = parseInt(totalPerformaceUserImpactPerc, 10) + parseInt(data.userPerformanceImpactPerc, 10);
        totalVariablePerformanceImpactPerc = parseInt(totalVariablePerformanceImpactPerc, 10) + parseInt(data.oteVariablePerformanceImpactPerc, 10);
      })
    }
    let totalPotentialUserImpactPerc = 0;
    let totalPotentialVariableImpactPerc = 0;
    if (potentialData.length) {
      // eslint-disable-next-line
      potentialData.forEach((data) => {
        totalPotentialUserImpactPerc = parseInt(totalPotentialUserImpactPerc, 10) + parseInt(data.userPotentialImpactPerc, 10);
        totalPotentialVariableImpactPerc = parseInt(totalPotentialVariableImpactPerc, 10) + parseInt(data.oteVariablePotentialImpactPerc, 10);
      })
    }
    return (
      <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
        <div className='okr-productivity-containt-padding'>
          <div className='okr-productivity-allocation-header'>Performance </div>
          <div className='okr-productivity-allocation-subtext'>
            {enableAction &&

              <>
                {isPrdAllocationDone ?
                  <span style={{ color: 'transparent' }}>.</span>
                  :
                  <span>Click on Edit to fill the Weightage (%), Impact on Variable Pay (%) and Rating Scale of the respective team member.</span>
                }
                {
                  isPrdAllocationDone ?
                    <div className={'edit-button-style-disable'}
                      style={{ cursor: 'default' }}
                      type='submit'
                    >
                      <span className={'edit-icon-style-disable'} style={{ cursor: 'default' }}><i className="fas fa-pencil-alt"></i></span>
                      <span className={'edit-text-style-disable'} style={{ cursor: 'default' }}>Edit</span>
                    </div>
                    :
                    <div className={!editFlag ? /**NOSONAR */'edit-button-style-enable' : 'edit-button-style-disable-edit'}
                      type='submit'
                      onClick={() => {
                        if (!isPrdAllocationDone) {
                          this.setState({
                            editFlag: !editFlag
                          })
                        }
                      }}
                    >
                      <span className={!editFlag ?/**NOSONAR */'edit-icon-style-enable' : 'edit-icon-style-disable'}><i className="fas fa-pencil-alt"></i></span>
                      <span className={!editFlag ?/**NOSONAR */'edit-text-style-enable' : 'edit-text-style-disable'}>Edit</span>
                    </div>
                }
              </>
            }
          </div>
        </div>
        <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'
          style={{ height: document.documentElement.clientHeight <= 817 ?/**NOSONAR */ setHeight === true ? 410 : 386 : '', overflowY: 'auto' }}
        >
          <div className='okr-productivity-table-padding'>
            <style>{tableBorder}</style>
            {/* OKR Performance */}
            {/* <div className='okr-productivity-allocation-header pt-sm pb-sm'>OKR Performance 
                        </div> */}
            <table>
              <thead>
                <tr>
                  <th></th>
                  <th><div className='new-productivityallocation-table-header'>Weightage Range<span style={{ padding: "0px 0px 0px 8px", position: "absolute", marginTop: -2 }}><CustomMaterialTooltip titleMessage={impactRangeInfo} /></span></div></th>
                  <th><div className='new-productivityallocation-table-header'>Weightage (%) <span style={{ padding: "0px 0px 0px 8px", position: "absolute", marginTop: -2 }}><CustomMaterialTooltip titleMessage={impactPercentageInfo} /></span></div></th>
                  <th><div className='new-productivityallocation-table-header'>Frequency <span style={{ padding: "0px 0px 0px 8px", position: "absolute", marginTop: -2 }}><CustomMaterialTooltip titleMessage={FrequencyInfo} /></span></div></th>
                  <th><div className='new-productivityallocation-table-header'>Impact on Variable Pay (%) <span style={{ padding: "0px 0px 0px 8px", position: "absolute", marginTop: -2 }}><CustomMaterialTooltip titleMessage={variableImpactpercentageInfo} /></span></div></th>
                  <th><div className='new-productivityallocation-table-header'>Rating Scale<span style={{ padding: "0px 0px 0px 8px", position: "absolute", marginTop: -2 }}><CustomMaterialTooltip titleMessage={ratingMechanismInfo} /></span></div></th>
                </tr>
              </thead>
              <tbody>
                {
                  performanceData.length > 0 &&
                  performanceData.map((itemData, i) => {
                    return (<tr key={`key_Performance${itemData.performanceId}`} index={`Performance_k_${itemData.performanceId}`}>
                      <td><div className='new-pro-all-first-column'>{`${itemData.allocationPerformanceName} `}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div></td>
                      <td><div className='new-pro-all-second-column'>{itemData.impactPerformanceRangeStartPerc} - {itemData.impactPerformanceRangeEndPerc}%</div></td>
                      <td>
                        {
                          (editFlag && itemData.isEditable === 'Y') ?
                            <div className='new-pro-all-third-column-edit'>
                              <input
                                id={`input_Performance${itemData.Id}`}
                                className='new-pro-all-third-column-edit-input'
                                type="number"
                                min={itemData.impactPerformanceRangeStartPerc}
                                // max={itemData.impactPerformanceRangeEndPerc}
                                max={100}
                                onChange={(e) => {
                                  let temp = this.state.performanceData;
                                  if (parseInt(e.target.value) > itemData.impactPerformanceRangeEndPerc) {
                                    temp[i].userPerformanceImpactPerc = itemData.impactPerformanceRangeEndPerc;
                                    this.setState({ performanceData: temp })
                                  }
                                  else {
                                    temp[i].userPerformanceImpactPerc = parseInt(e.target.value);
                                    this.setState({ performanceData: temp })
                                  }
                                }}
                                onBlur={(e) => {
                                  let temp = this.state.performanceData;
                                  if (parseInt(e.target.value) < itemData.impactPerformanceRangeStartPerc) {
                                    temp[i].userPerformanceImpactPerc = itemData.impactPerformanceRangeStartPerc;
                                    this.setState({ performanceData: temp })
                                  }
                                }}
                                value={itemData.userPerformanceImpactPerc}
                                showSpinButton={false}
                              />
                            </div>
                            :
                            <div className='new-pro-all-third-column'>{itemData.userPerformanceImpactPerc}</div>
                        }
                      </td>
                      <td><div className='new-pro-all-forth-parent-column'><div className='new-pro-all-forth-column'>{itemData.frequencyPerformance}</div></div></td>
                      <td>
                        {
                          (editFlag) ?
                            <div className='new-pro-all-third-column-edit'>
                              <input
                                className='new-pro-all-third-column-edit-input'
                                type="number"
                                min={0}
                                max='100'
                                onChange={(e) => {
                                  let temp = performanceData;
                                  if (e.target.value < 0 || e.target.value === '-0') {
                                    temp[i].oteVariablePerformanceImpactPerc = 0;
                                    this.setState({ performanceData: temp })
                                  } else {
                                    temp[i].oteVariablePerformanceImpactPerc = e.target.value;
                                    this.setState({ performanceData: temp })
                                  }
                                }}
                                value={itemData.oteVariablePerformanceImpactPerc}
                              />
                            </div>
                            :
                            <div className='new-pro-all-fifth-column'>{itemData.oteVariablePerformanceImpactPerc}</div>
                        }
                      </td>
                      <td>
                        {
                          (editFlag) ?
                            <center>
                              <select
                                className='new-pro-all-third-column-edit-select'
                                style={{ cursor: "pointer" }}
                                onChange={(e) => {
                                  e.persist();
                                  let temp = performanceData;
                                  temp[i].ratingPerformanceMechanismId = e.target.value;
                                  this.setState({ performanceData: temp })
                                }}
                                // value={itemData.ratingPotentialMechanismsId}
                                value={
                                  performanceData[i] && performanceData[i].ratingPerformanceMechanismId
                                    ? performanceData[i].ratingPerformanceMechanismId
                                    : 0
                                }
                              >
                                <option key={0} value={0} disabled={true}>
                                  {'Select'}
                                </option>
                                {ratingMechanism.length > 0 &&
                                  ratingMechanism.map((item) => {
                                    return (
                                      <option key={item.Id} value={item.Id}>
                                        {item.var_list_value}
                                      </option>
                                    );
                                  })}
                              </select>
                            </center>
                            :
                            <div className='new-pro-all-sixth-column'>{itemData.ratingPerformanceMechanisms}</div>
                        }
                      </td>
                    </tr>
                    )
                  })
                }
                {
                  performanceData.length > 0 &&
                  <tr>
                    <td><div className='new-pro-all-first-column'>Total</div></td>
                    <td></td>
                    <td><div className='new-pro-all-total-perce'>{Number.isNaN(totalPerformaceUserImpactPerc) ? '- ' : totalPerformaceUserImpactPerc}%</div></td>
                    <td></td>
                    <td><div className='new-pro-all-total-perce'>{Number.isNaN(totalVariablePerformanceImpactPerc) ? '- ' : totalVariablePerformanceImpactPerc}%</div></td>
                    <td></td>
                  </tr>
                }
              </tbody>
            </table>
            {
              (parseInt(totalPerformaceUserImpactPerc, 10) !== 100) && editFlag ?
                <div className='error-div'>{impactPerformancePersentage}</div>
                :
                <div className='error-div' style={{ color: 'transparent' }}>x</div>
            }
            {
              (totalVariablePerformanceImpactPerc !== 100 && totalVariablePerformanceImpactPerc !== 0) && editFlag ?
                <div className='error-div'>{variablePerformancePersentage}</div>
                :
                <div className='error-div' style={{ color: 'transparent' }}>x</div>
            }
            {/* OKR Potential */}
            <div className='okr-productivity-allocation-header pb-sm'>Potential</div>
            <table>
              <thead>
                <tr>
                  <th></th>
                  <th><div className='new-productivityallocation-table-header'>Weightage Range<span style={{ padding: "0px 0px 0px 8px", position: "absolute", marginTop: -2 }}><CustomMaterialTooltip titleMessage={impactRangeInfo} /></span></div></th>
                  <th><div className='new-productivityallocation-table-header'>Weightage (%) <span style={{ padding: "0px 0px 0px 8px", position: "absolute", marginTop: -2 }}><CustomMaterialTooltip titleMessage={impactPercentageInfo} /></span></div></th>
                  <th><div className='new-productivityallocation-table-header'>Frequency <span style={{ padding: "0px 0px 0px 8px", position: "absolute", marginTop: -2 }}><CustomMaterialTooltip titleMessage={FrequencyInfo} /></span></div></th>
                  <th><div className='new-productivityallocation-table-header'>Impact on Variable Pay (%) <span style={{ padding: "0px 0px 0px 8px", position: "absolute", marginTop: -2 }}><CustomMaterialTooltip titleMessage={variableImpactpercentageInfo} /></span></div></th>
                  <th><div className='new-productivityallocation-table-header'>Rating Scale<span style={{ padding: "0px 0px 0px 8px", position: "absolute", marginTop: -2 }}><CustomMaterialTooltip titleMessage={ratingMechanismInfo} /></span></div></th>
                </tr>
              </thead>
              <tbody>
                {
                  potentialData.length > 0 &&
                  potentialData.map((itemData, i) => {
                    return (<tr key={`key_${itemData.Id}`} index={`k_${itemData.Id}`}>
                      <td><div className='new-pro-all-first-column'>{itemData.allocationPotentialName}</div></td>
                      <td><div className='new-pro-all-second-column'>{itemData.impactPotentialRangeStartPerc} - {itemData.impactPotentialRangeEndPerc}%</div></td>
                      <td>
                        {
                          (editFlag && itemData.isEditable === 'Y') ?
                            <div className='new-pro-all-third-column-edit'>
                              <input
                                className='new-pro-all-third-column-edit-input'
                                type="number"
                                min={itemData.impactPotentialRangeStartPerc}
                                // max={itemData.impactPotentialRangeEndPerc}
                                max={100}
                                onChange={(e) => {
                                  let temp = potentialData;
                                  if (parseInt(e.target.value) > itemData.impactPotentialRangeEndPerc) {
                                    temp[i].userPotentialImpactPerc = itemData.impactPotentialRangeEndPerc;
                                    this.setState({ potentialData: temp })
                                  }
                                  else {
                                    temp[i].userPotentialImpactPerc = parseInt(e.target.value);
                                    this.setState({ potentialData: temp })
                                  }
                                }}
                                onBlur={(e) => {
                                  let temp = potentialData;
                                  if (parseInt(e.target.value) < itemData.impactPotentialRangeStartPerc) {
                                    temp[i].userPotentialImpactPerc = itemData.impactPotentialRangeStartPerc;
                                    this.setState({ potentialData: temp })
                                  }
                                }}
                                value={itemData.userPotentialImpactPerc}
                                showSpinButton={false}
                              />
                            </div>
                            :
                            <div className='new-pro-all-third-column'>{itemData.userPotentialImpactPerc}</div>
                        }
                      </td>
                      <td><div className='new-pro-all-forth-parent-column'><div className='new-pro-all-forth-column'>{itemData.frequencyPotential}</div></div></td>
                      <td>
                        {
                          editFlag ?
                            <div className='new-pro-all-third-column-edit'>
                              <input
                                className='new-pro-all-third-column-edit-input'
                                type="number"
                                min={0}
                                max={100}
                                onChange={(e) => {
                                  let temp = potentialData;
                                  if (e.target.value < 0 || e.target.value === '-0') {
                                    temp[i].oteVariablePotentialImpactPerc = 0;
                                    this.setState({ productivityallocationData: temp })
                                  } else {
                                    temp[i].oteVariablePotentialImpactPerc = e.target.value;
                                    this.setState({ potentialData: temp })
                                  }
                                }}
                                value={itemData.oteVariablePotentialImpactPerc}
                              />
                            </div>
                            :
                            <div className='new-pro-all-fifth-column'>{itemData.oteVariablePotentialImpactPerc}</div>
                        }
                      </td>
                      <td>
                        {
                          editFlag ?
                            <center>
                              <select
                                className='new-pro-all-third-column-edit-select'
                                style={{ cursor: "pointer" }}
                                onChange={(e) => {
                                  e.persist();
                                  let temp = potentialData;
                                  temp[i].ratingPotentialMechanismId = e.target.value;
                                  this.setState({ potentialData: temp })
                                }}
                                // value={itemData.ratingPotentialMechanismsId}
                                value={
                                  potentialData[i] && potentialData[i].ratingPotentialMechanismId
                                    ? potentialData[i].ratingPotentialMechanismId
                                    : 0
                                }

                              >
                                <option key={0} value={0} disabled={true}>
                                  {'Select'}
                                </option>
                                {ratingMechanism.length > 0 &&
                                  ratingMechanism.map((item) => {
                                    return (
                                      <option key={item.Id} value={item.Id}>
                                        {item.var_list_value}
                                      </option>
                                    );

                                  })}
                              </select>
                            </center>
                            :
                            <div className='new-pro-all-sixth-column'>{itemData.ratingPotentialMechanisms}</div>
                        }
                      </td>
                    </tr>
                    )
                  })
                }
                {
                  potentialData.length > 0 &&
                  <tr>
                    <td><div className='new-pro-all-first-column'>Total</div></td>
                    <td></td>
                    <td><div className='new-pro-all-total-perce'>{Number.isNaN(totalPotentialUserImpactPerc) ? '- ' : totalPotentialUserImpactPerc}%</div></td>
                    <td></td>
                    <td><div className='new-pro-all-total-perce'>{Number.isNaN(totalPotentialVariableImpactPerc) ? '- ' : totalPotentialVariableImpactPerc}%</div></td>
                    <td></td>
                  </tr>
                }
              </tbody>
            </table>

            {
              (parseInt(totalPotentialUserImpactPerc, 10) !== 100) && editFlag ?
                <div className='error-div'>{impactPotentialPersentage}</div>
                :
                <div className='error-div' style={{ color: 'transparent' }}>x</div>
            }
            {
              (totalPotentialVariableImpactPerc !== 100 && totalPotentialVariableImpactPerc !== 0) && editFlag ?
                <div className='error-div'>{variablePotentialPersentage}</div>
                :
                <div className='error-div' style={{ color: 'transparent' }}>x</div>
            }
          </div>
        </div>
        {
          potentialData.length > 0 && enableAction &&
          <div className="row" style={{ float: "right", padding: editFlag ? /**NOSONAR */"0px 30px 0px 0px" : "0px 30px 0px 0px", right: 0 }}>
            <Button
              style={{ width: "120px", background: editFlag ? '#F94F5E' : '#DBDBDD', color: editFlag ? '#fff' : '#B7B7BB', cursor: editFlag ? 'pointer' : 'default' }}
              className="remove-button-shadow"
              // color="primary"
              variant="contained"
              onClick={() => {
                if (editFlag && (parseInt(totalPerformaceUserImpactPerc, 10) !== 100 || parseInt(totalPotentialUserImpactPerc, 10) !== 100)) {
                  getNotify(ERROR, impactPerformancePotentialPersentage)
                }
                else if (editFlag && ((totalVariablePerformanceImpactPerc !== 100 && totalVariablePerformanceImpactPerc !== 0) || (totalPotentialVariableImpactPerc !== 100 && totalPotentialVariableImpactPerc !== 0))) {
                  getNotify(ERROR, variablePersentage)
                } else {
                  if (editFlag) {
                    this.openPopUp();

                  }
                }
              }}
            >

              &nbsp;&nbsp; Save &nbsp;&nbsp;

            </Button>
          </div>
        }


        {
          openPopUp &&
          <ConfirmationPopup
            headerMessage={productivityAllocationPopHeaderMessage}
            subMessage={productivityAllocationPopupSubMessage}
            button1Text={"Yes"}
            button2Text={"No"}
            button1function={this.submitUserProductivityData}
            button2function={this.closePopup}
            showButton={{ type: '1' }}
            open={openPopUp}
            handleConfirmClose={this.closePopup}
          />
        }

      </div>
    )
  }
}
OkrProductivityAllocation.propTypes = {
  authUser: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  employeeId: PropTypes.number,
  OkrMasters: PropTypes.arrayOf(object),
  productivityallocationData: PropTypes.arrayOf(object).isRequired,
  isPrdAllocationDone: PropTypes.bool,
};

OkrProductivityAllocation.defaultProps = {
  authUser: "",
  employeeId: 0,
  OkrMasters: [],
  isPrdAllocationDone: false,
};

const mapStateToProps = ({ auth, okrreducer, meetingmaster }) => {
  const { authUser } = auth;
  const { currentFinancialYearId } = meetingmaster;
  const { employeeId, OkrMasters, isPrdAllocationDone } = okrreducer;
  return { authUser, employeeId, OkrMasters, isPrdAllocationDone, currentFinancialYearId };
};

export default connect(mapStateToProps, {
  updateUserProductiveAllcation,
  setCentralOkrKPIRedux,
})(OkrProductivityAllocation);
