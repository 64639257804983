/**
 * Author Name :  Sanket Bari
 * Created Date: 05 March 2024
 * Modified Date: - .
 * Last Modified Name: - .
 */
// Last Modified: -




import React, { useRef } from 'react';

const Uploadbutton = ({uploadfile, id}) => {
    const fileInputRef = useRef(null);


    // Function to trigger click event of input element
    const handleClick = () => {
        fileInputRef.current.click();
    }

    return (
        <div style={{
            display: 'flex',
            width: '126px',
            height: '35px',
            borderRadius: '4px',
            border: '1px solid #DBDBDD',
            background: '#F2F2F2',
            cursor: 'pointer',
        }} onClick={handleClick}>
            <div style={{ flex: '0 0 auto', padding: '5px', boxSizing: 'border-box' }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M20 14.667V18.223C20 18.6945 19.8127 19.1468 19.4792 19.4802C19.1458 19.8137 18.6936 20.001 18.222 20.001H5.778C5.30644 20.001 4.8542 19.8137 4.52076 19.4802C4.18732 19.1468 4 18.6945 4 18.223V14.667" stroke="#939399" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M16.4437 8.444L11.9987 4L7.55469 8.444" stroke="#939399" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                    <path d="M12 4V14.667" stroke="#939399" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
            </div>
            <div style={{ flex: '1', borderLeft: '1px solid #DBDBDD' }}>
                {/* Empty div to create vertical middle line */}
            </div>
            <div style={{
                flex: '0 0 auto', boxSizing: 'border-box', color: '#4B4B55',
                fontFamily: "Circular TT book",
                fontSize: '14px',
                fontStyle: 'normal',
                fontWeight: 450,
                lineHeight: 'normal',
                padding: '8px',
            }}>
                Upload file
            </div>
            {/* Hidden input element */}
            {id === "uploadMonthlyAttendance" && (
                  <input
                  type="file"
                  id="uploadMonthlyAttendance"
                  ref={fileInputRef}
                  style={{ display: 'none' }}
                  onChange={uploadfile}
              />
            )}
            {id === "uploadSwapWeeklyOff" && (
                  <input
                  type="file"
                  id="uploadSwapWeeklyOff"
                  ref={fileInputRef}
                  style={{ display: 'none' }}
                  onChange={uploadfile}
              />
            )}
          
        </div>
    );
}


export default Uploadbutton;