/**
 * Author: Vivek Khodade.
 * Created:11-May-2020
 * Last Modify:11-May-2020
 * Modified By:Vivek Khodade
 */
import {
  GENERATE_CONFIRMATION_LETTER,
  EDIT_UPDATE_CONFIRMATION,
} from "../../constant/entryexit/confirmationconstant";

const INIT_STATE = {
  status: "",
};
// eslint-disable-next-line
export default (state = INIT_STATE, action) => { //NOSONAR
  if (action.type === GENERATE_CONFIRMATION_LETTER || action.type === EDIT_UPDATE_CONFIRMATION) {
    return {
      ...state,
      ...action.payload,
    }
  } else {
    return state;
  }
};
