import React, { useEffect, useState } from "react";
import { Dialog, DialogTitle } from "@material-ui/core";
import { connect } from 'react-redux'
import { DialogContent } from "../customdialog/customdialog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faAngleUp, faAngleDown } from "@fortawesome/pro-light-svg-icons";
import OKRRatingServices from '../../services/okrrating/okrratingservices';
import PastRatingTableItems from "./pastratingtableitems";
import { RatingValue } from '../../assets/constants/constants'
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import ReactHtmlParser from 'react-html-parser'
import ProgressBar from "../progressbar/progressbar";

function PastRatingModal(props) {
  const { KRData } = props;
  const [pastRating, setPastRating] = useState([])
  const [openLeadershipInfo, setOpenLeadershipInfo] = useState(true);
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    setLoading(true)
    const { authUser, empId } = props
    const ratingData = OKRRatingServices.getRatingInfo(authUser, empId, KRData.ratingId, 'past')
    ratingData.then((resultData) => {
      if (typeof resultData !== 'undefined' && resultData !== null) {
        setPastRating([...resultData])
      }
      setLoading(false)
    });
    // eslint-disable-next-line 
  }, [props.KRData.ratingId])

  const handleLeadershipInfo = () => {
    setOpenLeadershipInfo(prevState => !prevState);
  }

  return (
    <div style={{ width: "auto" }}>
      {props.OKRType == 4 ? <>
        <div className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <form id='PastReviewForm'>
            {pastRating.length > 0 &&
              <div className="form-row">
                <div className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ paddingLeft: '10px', paddingRight: '10px', paddingTop: 16 }}>
                  <table className="table table-bordered" id='past-rating-table'>
                    <thead style={{ position: "sticky", top: "0", color: '#939399', fontSize: 14, fontFamily: 'Circular Std', backgroundColor: '#F4F4F7' }}>
                      <tr>
                        <th style={{ textAlign: "center", paddingBottom: '8px' }}>Performance Period</th>
                        <th style={{ textAlign: "center", paddingBottom: '8px' }}> Self Rating</th>
                        <th style={{ textAlign: "center", paddingBottom: '8px', width: 200 }}> Self Remark</th>
                        <th style={{ textAlign: "center", paddingBottom: '8px' }}>Supervisor Rating</th>
                        <th style={{ textAlign: "center", paddingBottom: '8px', width: 200 }}>Supervisor Remark</th>
                        {/* <th style={{ textAlign: "center", paddingBottom: '8px', width: 131 }}> Supervisor vs Rating Sync</th> */}
                        <th style={{ paddingBottom: '8px', textAlign: "center" }}>Self  Vs Supervisor Rating Sync</th>
                        {/* <th style={{ textAlign: "center", paddingBottom: '8px', width: 111 }}>Achieved Value</th> */}
                        <th style={{ textAlign: "center", paddingBottom: '8px' }}>Final Rating</th>
                        <th style={{ textAlign: "center", paddingBottom: '8px', width: 200 }}>Final Remark</th>
                        <th style={{ textAlign: "center", paddingBottom: '8px' }}>Key Result Achieved</th>
                      </tr>
                    </thead>
                    {
                      pastRating.length > 0 && pastRating.map((item, inx) => (<>
                        <PastRatingTableItems key={`index-${inx}`} item={item} inx={inx} />
                      </>))
                    }
                  </table>
                </div>

              </div>
            }
          </form>
        </div>
        {loading &&
          <div
            className='loader-new'
          ><ProgressBar />
          </div>}
      </>
        :
        <Dialog open={true} fullWidth maxWidth="lg">
          <DialogTitle>
            <span className="add-new-self-header" style={{
              color: "black",
              marginLeft: -4,
              fontFamily: "Circular Std ",
              fontSize: 18
            }}>
              Past Rating
            </span>
            <span style={{ position: 'absolute', right: 20, cursor: 'pointer' }}><FontAwesomeIcon onClick={() => props.closePastRatingPopup()} style={{ color: '#B7B7BB', fontSize: 20 }} icon={faTimes} /></span>
          </DialogTitle>

          <DialogContent style={{ paddingTop: 0, marginBottom: 32, marginRight: 16 }}>
            {props.OKRType === '3' ?
              <div className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <form id='PastReviewForm'>
                  <div className="form-row ">
                    <div className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12"
                      style={{ paddingLeft: 10, paddingRight: 10 }}>
                      <div className="view-kr-modalrating-header-text" style={{ height: 20, fontSize: 15 }}>Leadership Principle</div>
                      <div className="view-kr-modalrating-text">
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                          <div style={{ fontSize: 15, fontWeight: 600, color: "#000" }}>
                            {KRData.krName}
                          </div>
                          <div style={{ marginRight: ".4rem" }}>
                            <FontAwesomeIcon
                              style={{ color: '#939399', fontSize: 20, cursor: "pointer" }}
                              icon={openLeadershipInfo ? faAngleUp : faAngleDown}
                              onClick={handleLeadershipInfo}
                            />
                          </div>
                        </div>
                        <div style={{ paddingTop: ".3rem", fontSize: 13, color: "#000", display: openLeadershipInfo ? "block" : "none" }}>
                          {ReactHtmlParser(KRData.krDetails)}
                        </div>
                      </div>
                    </div>
                  </div>

                  {pastRating.length > 0 &&
                    <div className="form-row">
                      <div className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ paddingLeft: '10px', paddingRight: '10px', paddingTop: 16 }}>
                        <table className="table table-bordered past-rating-table">
                          <thead style={{ position: "sticky", top: "0", color: '#939399', fontSize: 14, fontFamily: 'Circular Std', backgroundColor: '#F4F4F7' }}>
                            <tr>
                              <th style={{ textAlign: "center", paddingBottom: '8px' }}>Performance Period</th>
                              <th style={{ textAlign: "center", paddingBottom: '8px' }}> Self Rating</th>
                              <th style={{ textAlign: "center", paddingBottom: '8px', width: 200 }}> Self Remark</th>
                              <th style={{ textAlign: "center", paddingBottom: '8px' }}>Supervisor Rating</th>
                              <th style={{ textAlign: "center", paddingBottom: '8px', width: 200 }}>Supervisor Remark</th>
                              {/* <th style={{ textAlign: "center", paddingBottom: '8px', width: 131 }}> Supervisor vs Rating Sync</th> */}
                              <th style={{ paddingBottom: '8px', textAlign: "center" }}>Self  Vs Supervisor Rating Sync</th>
                              {/* <th style={{ textAlign: "center", paddingBottom: '8px', width: 111 }}>Achieved Value</th> */}
                              <th style={{ textAlign: "center", paddingBottom: '8px' }}>Final Rating</th>
                              <th style={{ textAlign: "center", paddingBottom: '8px', width: 200 }}>Final Remark</th>
                              <th style={{ textAlign: "center", paddingBottom: '8px' }}>Key Result Achieved</th>
                            </tr>
                          </thead>
                          {
                            pastRating.length > 0 && pastRating.map((item, inx) => (<>
                              <PastRatingTableItems key={`index-${inx}`} item={item} inx={inx} />
                            </>))
                          }
                        </table>
                      </div>

                    </div>
                  }
                </form>
              </div>
              :
              <div className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <form id='PastReviewForm'>
                  <div className="form-row ">
                    <div className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12"
                      style={{ paddingLeft: 10, paddingRight: 10 }}>
                      <div className="view-kr-modalrating-header-text">Key Result</div>
                      <div className="view-kr-modalrating-text"
                        style={{ backgroundColor: '#F8F8F880' }}>
                        {
                          KRData.krName
                        }
                      </div>
                    </div>
                  </div>

                  <div className="form-row">
                    <div className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ paddingLeft: '10px', paddingRight: '10px', paddingTop: 16 }}>
                      <table className="table table-hover table-bordered ">
                        <thead style={{ position: "sticky", top: "0", color: '#939399', fontSize: 13, fontFamily: 'Circular Std', backgroundColor: '#F4F4F7' }}>
                          <tr>
                            <th style={{ textAlign: "center", paddingBottom: '8px', width: 100 }}>Performance Period</th>
                            <th style={{ textAlign: "center", paddingBottom: '8px', width: 90 }}> Self Rating</th>
                            <th style={{ textAlign: "center", paddingBottom: '8px', width: 200 }}> Self Remark</th>
                            <th style={{ textAlign: "center", paddingBottom: '8px', width: 90 }}>Supervisor Rating</th>
                            <th style={{ textAlign: "center", paddingBottom: '8px', width: 200 }}>Supervisor Remark</th>
                            {/* <th style={{ textAlign: "center", paddingBottom: '8px', width: 131 }}> Supervisor vs Rating Sync</th> */}
                            <th style={{ paddingBottom: '8px', textAlign: "center", width: 131 }}>Self  Vs Supervisor Rating Sync</th>
                            {/* <th style={{ textAlign: "center", paddingBottom: '8px', width: 111 }}>Achieved Value</th> */}
                            <th style={{ textAlign: "center", paddingBottom: '8px', width: 90 }}>Final Rating</th>
                            <th style={{ textAlign: "center", paddingBottom: '8px', width: 200 }}>Final Remark</th>
                            <th style={{ textAlign: "center", paddingBottom: '8px', width: 100 }}>Key Result Achieved</th>
                          </tr>
                        </thead>
                        <tbody className="okr-past-rating-font">
                          {
                            pastRating.length > 0 && pastRating.map((item, inx) => (
                              <tr style={{ textAlign: 'center', alignItems: 'center' }} key={`tablerow_${inx}`}>
                                <td><div>{item.ratingMonth}</div></td>
                                <td><div>{item.selfRating}</div></td>
                                <td className="">
                                  <TooltipComponent closeOn="Click" position="BottomCenter" isSticky={false}
                                    content={item.selfAchievedRemarks}
                                    cssClass='customtooltip-past'>
                                    <div className="text-overflow-css-past">{item.selfAchievedRemarks}</div>
                                  </TooltipComponent>
                                </td>

                                {/* <td><div><span>{item.selfRating}</span> &nbsp;&nbsp;&nbsp;&nbsp; <span style={{ color: '#939399' }}>|</span><span className={item.selfSystemSync === 'Y' ? 'past-review-yes' : 'past-review-no'}>&nbsp;&nbsp;&nbsp;&nbsp; {item.selfSystemSync}</span></div></td> */}
                                <td><div>{item.supRating}</div></td>
                                <td className="">

                                  <TooltipComponent closeOn="Click" position="BottomCenter" isSticky={false}
                                    content={item.supAchievedRemarks}
                                    cssClass='customtooltip-past'>
                                    <div className="text-overflow-css-past tooltiptext">{item.supAchievedRemarks}</div>
                                  </TooltipComponent>
                                </td>
                                {/* <td><div><span>{item.supRating}</span>&nbsp;&nbsp;&nbsp;&nbsp; <span style={{ color: '#939399' }}>|</span><span className={item.supSystemSync === 'Y' ? 'past-review-yes' : 'past-review-no'}>&nbsp;&nbsp;&nbsp;&nbsp;{item.supSystemSync}</span></div></td> */}
                                <td><div> {item.selfSupSync === 'Y' && <span className='past-review-yes'>{item.selfSupSync}</span>} {item.selfSupSync === 'N' && <span className='past-review-no'>{item.selfSupSync}</span>}</div></td>
                                {/* <td><div>{item.achievedRating}</div></td> */}
                                <td><div>{item.finalRating}</div></td>
                                <td className="">
                                  <TooltipComponent closeOn="Click" position="BottomCenter" isSticky={false}
                                    content={item.finalRemarks}
                                    cssClass='customtooltip-past'>
                                    <div className="text-overflow-css-past tooltiptext">{item.finalRemarks}</div>
                                  </TooltipComponent>
                                </td>
                                <td><div>{item.achieved === "Y" && <span>Yes</span>} {item.achieved === "N" && <span>No</span>}</div></td>
                              </tr>
                            ))
                          }
                        </tbody>
                      </table>
                    </div>

                  </div>
                </form>
              </div>
            }
          </DialogContent>
        </Dialog>
      }

    </div>
  );

}
const mapStateToProps = ({ okrratingreducer, auth }) => {
  const { authUser } = auth
  const { empId } = okrratingreducer
  return { authUser, empId }
};
export default connect(mapStateToProps, {})(
  PastRatingModal
);
