import React, { Component } from "react";
import { connect } from "react-redux";
import { Button } from "@material-ui/core";
import PropTypes, { object } from "prop-types";
import OKRServices from "../../services/okr/okrservices";
import "./oldproductiveallocation.css";
import { setCentralOkrKPIRedux, setUserOKRObjectiveKPI, addUpdateObjectveKpi, setOKRUserKPI, submitApprovalAction } from "../../redux/actions/okr/okractions";
import infoIcon from "../../../src/assets/svg/infoIcon.svg";
import ProgressBar from '../progressbar/progressbar';
import OKRImportObj from '../okrproductiveallocation/okrimportobj';
import { ERROR, pleaseSelectObj, max5objInActiveTab, /**variablePercentageValidation,*/ TotalCentralUserVariableImpact } from '../../assets/constants/constants';//nosonar
import { getNotify } from '../../app/other/commonfunction';
import { TabComponent } from '@syncfusion/ej2-react-navigations';

import OKRRatingServices from '../../services/okrrating/okrratingservices';
import ReviewObjectivKR from '../okrratingcomponents/reviewobjectivekr';
import PastRatingModal from '../okrratingcomponents/pastratingmodal'
import ActualAchivementModal from '../okrratingcomponents/actualachievementmodal';
import AddRatingModalkr from '../okrratingcomponents/addratingmodalkr';
import InterimFeedBack from '../okrratingcomponents/interimfeedback';
import ViewRatingModalKr from '../okrratingcomponents/viewratingmodalkr';
import ViewKRDetailsModal from '../okrratingcomponents/viewkrdetailsmodal';
import ClosedTabObjHeader from '../okrproductiveallocation/closedtabobjheader';

class OkrCentral extends Component {
  constructor(props) {
    super(props);
    this.state = {
      masterKPIList: { Result: [{}] },
      okrCentralPopup: false,
      height: (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight) - 188,
      centralOKRProdAlloc: [],
      popupExpandColapse: false,
      importCount: 0,
      changeData: true,
      isLoading: true,
      centralOKRDraft: [],
      centralOKRActive: [],
      checkedObjectiveToSubmit: [],
      viewKrDetailsPopup: false,
      finalRatingPopup: false,
      pastRatingPopup: false,
      viewActualAchivementPopup: false,
      viewKrRatingPopup: false,
      viewInterimReviewPopup: false,
      viewReviewKrPopup: false,
      KRData: [],
      finalRatingData: [],
      pastRatingKRData: [],
      actualKRData: [],
      categoryType: '',
      actionType: '',
      objId: '',
      selectedType: '',
      KRReviewData: [],
      type: '',
      currencyInfo: [],
      draftSubmitted: false
    };
    this.data = null;
    this.submitButtonClass = 'button-submit-disable';
    this.loaded = true;
    this.tabObj = null;
  }
  componentDidMount = async () => {
    this.updateDimensions();
    await this.getUserMapObjKpiData("RefreshTab");
    await this.getDashboardReviewData(3)
    window.addEventListener("resize", this.updateDimensions.bind(this));
    this.loaded = true
  }

  setTabsDefaults = () => {
    const { draftCentralObjectiveKpi, activeCentralObjectiveKpi } = this.props
    if (this.props.enableAction && this.loaded && !this.state.isLoading) {
      if (this.tabObj !== undefined && activeCentralObjectiveKpi.length > 0) {
        this.tabObj.selectedItem = 1;
      }
      else if (this.tabObj !== undefined && draftCentralObjectiveKpi.length > 0 && activeCentralObjectiveKpi.length === 0) {
        this.tabObj.selectedItem = 0;
        this.props.addUpdateObjectveKpi({ okrAllocationTabs: "draft" })
      }
      else {
        this.tabObj.selectedItem = 1;
      }
    }
  }
  componentWillUnmount() {
    this.loaded = true
    this.props.setCentralOkrKPIRedux({ centralOkrData: [], centralKPITemp: [], draftData: [], activeData: [] });
  }

  getUserMapObjKpiData = (type) => { // NOSONAR
    const { authUser, employeeId, enableAction, fyid } = this.props;
    try {
      const getUserMapData = Promise.resolve(
        OKRServices.getUserMapObjectiveKpiList(authUser, employeeId, "2", fyid)
      );
      getUserMapData.then((resultData) => {
        if (resultData !== null) {
          if (resultData.Result.length > 0) {
            this.setState({ centralOKRData: resultData.Result });

            let centralOkrActiveData = [];
            let centralOkrDraftData = [];
            resultData.Result.forEach((ech) => {
              if (ech.isDraft === '1') {
                centralOkrDraftData.push(ech);
              } else {
                centralOkrActiveData.push(ech);
              }
            });
            if (centralOkrDraftData.length > 0 && (centralOkrActiveData.length === 0 || centralOkrActiveData.length > 0) && type === "RefreshTab") {
              this.currentTab = 'draftTab';
            }
            if ((centralOkrDraftData.length === 0 && centralOkrActiveData.length > 0 && type === "RefreshTab") || !enableAction) {
              this.currentTab = 'activeTab';
            }
            this.props.addUpdateObjectveKpi({
              draftCentralObjectiveKpi: centralOkrDraftData,
              activeCentralObjectiveKpi: centralOkrActiveData
            });
          } else {
            this.props.addUpdateObjectveKpi({
              draftCentralObjectiveKpi: [],
              activeCentralObjectiveKpi: []
            });
          }
          this.setState({ isLoading: false })
        } else {
          this.setState({ isLoading: false })
          this.props.addUpdateObjectveKpi({
            draftCentralObjectiveKpi: [],
            activeCentralObjectiveKpi: []
          });
        }
      });
    } catch (e) {
      this.props.addUpdateObjectveKpi({
        draftCentralObjectiveKpi: [],
        activeCentralObjectiveKpi: []
      });
      this.setState({ isLoading: false })
      console.log(e);
    }
  };

  setCheckedOpjective = (data, type) => {
    if (type === "draftCentralOKR") {
      this.setState({ checkedObjectiveToSubmit: data });
    }
  }

  updateDimensions() {
    const dynHeight = window.innerHeight - 395;
    const updateHeight = window.innerHeight - 425;
    this.setState({
      height: updateHeight,
      dynamicHeight: dynHeight,
    });
  }
  componentWillReceiveProps = (nextProps) => {

    if (nextProps.updateBuActiveFlag === 'updated') {
      this.props.setCentralOkrKPIRedux({ updateBuActiveFlag: "" });
      if (!nextProps.enableAction) {
        this.hideDraft = true;
        this.props.addUpdateObjectveKpi({ activeTabFlag: true });
      }
    }
    if (nextProps.isNewObjCreated === "Y") {
      this.setState({
        popType: "NewObj",
        open: true,
        title: "New Objective is created",
        message: 'A copy of this OKR has been created in "Draft".',
        subMessage: "You may now add/edit details of the OKR and submit to activate the OKR."
      })
    }

    if (nextProps.isObjDisabled === "Y") {
      this.setState({
        popType: "RemoveObj",
        open: true,
        title: 'Objective removed',
        message: 'The OKR has been removed & moved to "Draft".',
        subMessage: "You may now add/edit details of the OKR and submit to reactivate the OKR."
      })
      this.props.setCentralOkrKPIRedux({ isObjDisabled: "N" });
    }
    if (nextProps.moveToActive === 'updated') {
      this.props.setCentralOkrKPIRedux({ moveToActive: "" });
    }
    if (nextProps.moveCentralToActive === 'updated') {
      this.getUserMapObjKpiData("RefreshTab");
      this.props.setCentralOkrKPIRedux({ moveCentralToActive: "", activeTabFlag: true, okrAllocationTabs: "active" });
      document.getElementById("draftTextCount").innerHTML = `(${nextProps.draftCentralObjectiveKpi.length})`;
      document.getElementById("activeTextCount").innerHTML = `(${nextProps.activeCentralObjectiveKpi.length})`;
    }
    if (nextProps.closedTabStatus === 'updated' && nextProps.okrAllocationTabs === 'closed') {
      this.getDashboardReviewData(nextProps.closedMonthOfReview);
      this.props.addUpdateObjectveKpi({ closedTabStatus: "" })
    }
  }

  submitDraftObjectives = () => {/**NOSONAR */
    const { authUser, activeCentralObjectiveKpi, centralUserVariableImpact } = this.props;
    let userObjectiveids = [];
    let activeVariableTotal = 0;
    let draftVariableTotal = 0;
    let totalVariableWeightage = 0;
    this.state.checkedObjectiveToSubmit.length > 0 &&
      // eslint-disable-next-line
      this.state.checkedObjectiveToSubmit.forEach((item) => {
        userObjectiveids.push(item.userObjectiveId);
        if (centralUserVariableImpact > 0) {
          draftVariableTotal += item.variable;
        }
      })
    const submitApprovalData = {
      type: "submitOkrToUser",
      userId: authUser,
      objId: userObjectiveids.toString()
    }
    let cnt = 0;
    activeCentralObjectiveKpi.length > 0 &&
      // eslint-disable-next-line
      activeCentralObjectiveKpi.map((item) => {
        if (item.disableEnableFlag === '1') {
          cnt += 1;
        }
        if (centralUserVariableImpact > 0 && item.disableEnableFlag !== '1') {
          activeVariableTotal += item.variable
        }
      });

    let len = (activeCentralObjectiveKpi.length - cnt) + this.state.checkedObjectiveToSubmit.length;
    totalVariableWeightage = draftVariableTotal + activeVariableTotal;
    if (len >= 1 && len <= 5) {
      if (centralUserVariableImpact > 0) {
        if (totalVariableWeightage <= TotalCentralUserVariableImpact && this.state.checkedObjectiveToSubmit.length > 0) {
          this.props.submitApprovalAction(submitApprovalData);
          // this.setState(prev=>({draftSubmitted:!prev.draftSubmitted}))
          this.setState({ checkedObjectiveToSubmit: [] })
        } else {
          if (this.state.checkedObjectiveToSubmit.length === 0) {
            getNotify(ERROR, pleaseSelectObj)
            return;
          }
          // else if (totalVariableWeightage > TotalCentralUserVariableImpact) {
          //   getNotify(ERROR, variablePercentageValidation);
          //   return;
          // }
        }
      } else {
        if (this.state.checkedObjectiveToSubmit.length > 0) {
          this.props.submitApprovalAction(submitApprovalData);
          this.setState({ checkedObjectiveToSubmit: [] })
        } else {
          getNotify(ERROR, pleaseSelectObj)
          return;
        }
      }
    }
    else if (len > 5) {
      getNotify(ERROR, max5objInActiveTab)
      return;
    }
    else {
      getNotify(ERROR, pleaseSelectObj)
      return;
    }
    this.setState({ isLoading: true })
    this.getDashboardReviewData(9)


  }

  getDashboardReviewData = (closedMonthOfReview) => {
    const { employeeId, fyid, authUser, draftCentralObjectiveKpi, activeCentralObjectiveKpi } = this.props;
    try {//NOSONAR
      const okrRatingDashboardData = Promise.resolve(
        OKRRatingServices.getReviewDashboardData(authUser, employeeId, fyid, closedMonthOfReview, '2', "OKR")
      );
      okrRatingDashboardData.then((resultData) => {
        this.props.addUpdateObjectveKpi({ closedCentralOkr: resultData });
        if (document.getElementById("closeTextCount") !== null) {
          document.getElementById("closeTextCount").innerHTML = `(${resultData.length})`;
          if (this.tabObj !== undefined) {
            if (authUser === employeeId) {
              if (activeCentralObjectiveKpi.length === 0 && resultData.length > 0) {
                this.tabObj.selectedItem = 1;//Close tab
              } else {
                this.tabObj.selectedItem = 0;//Active tab
              }
            } else {
              if (activeCentralObjectiveKpi.length > 0) {
                this.tabObj.selectedItem = 1; //Active tab
              }
              else if (draftCentralObjectiveKpi.length === 0 && activeCentralObjectiveKpi.length === 0 && resultData.length > 0) {
                this.tabObj.selectedItem = 2;//Close tab
              }
              else if (draftCentralObjectiveKpi.length > 0 && activeCentralObjectiveKpi.length === 0) {
                this.tabObj.selectedItem = 0;//Draft tab
              }
              else {
                this.tabObj.selectedItem = 1; //Active tab
              }
            }
          }
        }
      });
    } catch (err) {
      console.log("OKRRatingServices", err)
    }
  }
  opneKrDetailsPopup = (data) => {
    this.setState({ viewKrDetailsPopup: true, KRData: data })
  }
  openFinalRatingPopup = (data) => {
    this.setState({ finalRatingPopup: true, finalRatingData: data })
  }
  openPastRatingPopup = (data) => {
    this.setState({ pastRatingPopup: true, pastRatingKRData: data })
  }
  openActualAchivementPopup = (data, categoryType, actionType) => {
    this.setState({ viewActualAchivementPopup: true, actualKRData: data, categoryType: categoryType, actionType: actionType })
  }
  openAddEditIntrimFeedbackPopup = (data) => {
    this.setState({
      addEditInterimReviewPopup: true,
      KRData: data,

    });
  };
  openKrIntrimFeedbackPopup = (data, userObjectiveId, objectiveName) => {
    this.setState({
      viewKrInterimFeedbackPopup: true,
      KRData: data,
      objId: userObjectiveId,
      objectiveName: objectiveName
    });
  };
  openViewIntrimFeedbackPopup = (data, userObjectiveId, selectedType, draftClick) => {

    this.setState({
      KRData: data,
      viewInterimReviewPopup: true,
      objId: userObjectiveId,
      selectedType: selectedType
    });
    this.props.addUpdateOkrRatingRedux({ krData: data, interimType: selectedType, isDraftClick: draftClick })
  };
  viewKrReviewPopup = (data, type) => {
    this.setState({
      KRReviewData: data,
      viewReviewKrPopup: true,
      type: type
    })
  }
  closeKrDetailsPopup = () => {
    this.setState({ viewKrDetailsPopup: false, KRData: {} })
  }
  closeFinalRatingPopup = () => {
    this.setState({ finalRatingPopup: false, finalRatingData: {} })
  }
  closePastRatingPopup = () => {
    this.setState({ pastRatingPopup: false, pastRatingKRData: {} })
  }
  closeActualAchivementPopup = () => {
    this.setState({ viewActualAchivementPopup: false, actualKRData: {}, categoryType: '', actionType: '', userType: '' })
  }
  closeKrRatingPopup = () => {
    this.setState({ viewKrRatingPopup: false })
  }
  closeViewIntrimFeedbackPopup = () => {
    this.setState({
      viewInterimReviewPopup: false,
      objId: null,
      selectedType: null,
      KRData: {}
    });
    this.props.addUpdateOkrRatingRedux({ krData: {}, interimType: "", isDraftClick: null })
  };
  closeKrReviewPopup = () => {
    this.setState({
      KRReviewData: {},
      viewReviewKrPopup: false,
      type: ''
    })
  }

  changeTab = (args) => {// NOSONAR
    const { enableAction } = this.props;
    this.loaded = false
    if (enableAction) {
      if (args.selectedIndex === 1) {
        this.props.addUpdateObjectveKpi({ activeTabFlag: true, okrAllocationTabs: enableAction ? "active" : "closed" })
      }
      if (args.selectedIndex === 2) {
        this.props.addUpdateObjectveKpi({ okrAllocationTabs: "closed", activeTabFlag: false })
      }

      if (args.selectedIndex === 0) {
        this.props.addUpdateObjectveKpi({ okrAllocationTabs: enableAction ? "draft" : "active", activeTabFlag: enableAction ? false : true })
      }
    }
    else {
      if (args.selectedIndex === 0) {
        this.props.addUpdateObjectveKpi({ activeTabFlag: true, okrAllocationTabs: enableAction ? "active" : "closed" })
      }
      if (args.selectedIndex === 1) {
        this.props.addUpdateObjectveKpi({ okrAllocationTabs: "closed", activeTabFlag: false })
      }
    }
  }

  render() { // NOSONAR
    const { height, isLoading, dynamicHeight, viewKrDetailsPopup, finalRatingPopup, pastRatingPopup, viewActualAchivementPopup, viewKrRatingPopup,
      viewInterimReviewPopup, viewReviewKrPopup,
      KRData, finalRatingData, pastRatingKRData, actualKRData, categoryType, actionType, objId, selectedType, KRReviewData, type, currencyInfo } = this.state;
    const { activeCentralObjectiveKpi, draftCentralObjectiveKpi, setHeightFlag, centralUserImpact, centralUserVariableImpact, centralUserFrequency, enableAction, categoryName, closedCentralOkr, okrAllocationTabs } = this.props;
    let smallResolution = (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight) > 767 ? 0 : + 40;

    let activeTabMargin = 10;
    if (!enableAction) {
      activeTabMargin = 3;
    }

    if (document.getElementById("draftTextCount") !== null && document.getElementById("draftTextCount") !== undefined) {
      document.getElementById("draftTextCount").innerHTML = `(${draftCentralObjectiveKpi.length})`;
    }

    if (document.getElementById("activeTextCount") !== null && document.getElementById("activeTextCount") !== undefined) {
      document.getElementById("activeTextCount").innerHTML = `(${activeCentralObjectiveKpi.length})`;
    }
    return (
      <>
        <div style={{ display: "block", height, padding: '10px 10px 0px 10px' }}>

          {/* <div style={{top:310, left: 96, width:117, height:20}}> */}
          {/* {!isLoading ? <> */}
          <div style={{ paddingBottom: 25 }}>
            <div className="okr-central-LP-BV-position" style={{ verticalAlign: "middle" }}>
              <span className="bussiness-okr-word" style={{ paddingLeft: 2 }}>Central OKR
                {/* <img className="okr-central-subheader-icon" alt="" src={require("../../assets/svg/Group_1863.svg")} style={{ paddingLeft: 5, verticalAlign: "middle" }} /> */}
              </span>
              <span className="okr-central-subheaders" style={{ marginLeft: 32 }}>Central OKR Weightage: </span>
              <span className="okr-central-subheader-value">
                {centralUserImpact === null ? "NA" : `${centralUserImpact}%` /*NOSONAR*/}
              </span>
              <span className="okr-central-subheaders" style={{ marginLeft: 24 }}>Variable Allocation: </span>
              <span className="okr-central-subheader-value">
                {centralUserVariableImpact === null ? "NA" : `${centralUserVariableImpact}%` /*NOSONAR*/}
              </span>
              <span className="okr-central-subheaders" style={{ marginLeft: 24 }}>OKR Rating Frequency: </span>
              <span className="okr-central-subheader-value">{centralUserFrequency}</span>
            </div>
          </div>
          {draftCentralObjectiveKpi.length > 0 && enableAction && okrAllocationTabs === 'draft' &&
            <div className='col-lg-11 col-md-12 col-sm-12 col-xs-12' style={{ textAlign: "center", padding: "5px 0px 0px 100px", position: 'absolute' }}>
              <span className="business-okr-note"> <img alt="" src={infoIcon} /> Click on "Fill Details" to enter specific inputs for each<strong> Objective</strong> and <strong>Key Result </strong></span>
            </div>
          }
          <TabComponent id="centraldefaultTab"
            style={{ left: '0px', right: '100px' }}
            animation={{ previous: { effect: "SlideLeftIn", duration: 0, easing: "ease" }, next: { effect: "SlideRightIn", duration: 0, easing: "ease" } }}
            selected={(arg) => this.changeTab(arg)}
            ref={(tabObj) => (this.tabObj = tabObj)}
            allowDragAndDrop={true}
          >
            <div className="e-tab-header">

              {enableAction && // okrAllocationTabs === 'draft' &&
                <div key="okrCentralDrafts">
                  <span style={{ textTransform: 'none', fontWeight: 600, fontSize: "14px", font: 'normal normal bold 14px/25px Arial' }}>Draft</span>
                  <span id='draftTextCount' style={{ paddingLeft: 5, textTransform: 'none', font: 'normal normal bold 14px/25px Arial' }} />
                </div>
              }
              <div>
                <span style={{ marginLeft: activeTabMargin, textTransform: 'none', fontWeight: 600, fontSize: "14px", font: 'normal normal bold 14px/25px Arial' }}>Active</span>
                <span id='activeTextCount' style={{ paddingLeft: 5, textTransform: 'none', font: 'normal normal bold 14px/25px Arial' }} />
              </div>
              <div>
                <span style={{ marginLeft: activeTabMargin, textTransform: 'none', fontWeight: 600, fontSize: "14px", font: 'normal normal bold 14px/25px Arial' }}>Closed</span>
                <span id='closeTextCount' style={{ paddingLeft: 5, textTransform: 'none', font: 'normal normal bold 14px/25px Arial' }}> </span>
              </div>


            </div>
            <hr style={{ margin: 0, border: "1px solid #F2F2F2" }} />
            <div className="e-content">
              {/* Draft TAB */}

              {(enableAction) && <div>
                <div id="draftTab"
                  style={{
                    height: setHeightFlag === true ? dynamicHeight + smallResolution : height + smallResolution,
                    padding: '0px 0px 0px 28px',
                    overflowY: "auto",
                    overflowX: "hidden",
                    position: "relative",
                    marginTop: '8px',
                  }}
                >
                  {
                    !isLoading ?
                      <>
                        {
                          draftCentralObjectiveKpi.length > 0 ?//NOSONAR
                            <OKRImportObj masterObjKpiList={draftCentralObjectiveKpi} showHideComponent="draftCentralOKR" setCheckedOpjective={this.setCheckedOpjective} />
                            :
                            <div style={{ marginTop: '10%' }}>
                              <div className='new-productivity-allocation-no-data-header'>No Items</div>
                              <div className='new-productivity-allocation-no-data-sub-header pt-lg'>You don't have any items here. Select employee</div>
                              <div className='new-productivity-allocation-no-data-sub-header'>and financial year to continue.</div>
                            </div>
                        }
                      </>
                      :
                      <div style={{ marginTop: '10%' }}><ProgressBar /></div>
                  }
                </div>
                {
                  draftCentralObjectiveKpi.length > 0 &&
                  <div style={{ padding: "10px 0px 0px 0px" }}>
                    <div className="col-xs-12 col-sm-8 col-lg-10" style={{ padding: "8px 28px 0px" }}>
                      <span className="footer-status" style={{ background: "#FDD141" }}></span>
                      <span style={{ padding: "0px 24px 0px 8px" }}>Pending </span>
                      <span className="footer-status" style={{ background: "#48B8E7" }}></span>
                      <span style={{ padding: "0px 24px 0px 8px" }}>Completed</span>
                    </div>
                    {enableAction &&
                      <div className="col-xs-12 col-sm-4 col-lg-2" style={{ padding: "0px 0px 0px 0px" }}>
                        <Button
                          style={{ float: "right", width: "136px", height: 32 }}
                          className="remove-button-shadow"
                          color="primary"
                          variant="contained"
                          onClick={() => this.submitDraftObjectives()}
                        >
                          Submit
                        </Button>
                      </div>
                    }
                  </div>
                }
              </div>}

              {/* Active TAB */}

              <div>
                <div id="activeTab"
                  style={{
                    height: setHeightFlag === true ? dynamicHeight + smallResolution : height + smallResolution,
                    padding: '0px 0px 0px 0px',
                    overflowY: "auto",
                    overflowX: "hidden",
                    position: "relative",
                    marginTop: '8px',
                  }}
                >

                  {
                    !isLoading ?
                      <>
                        {
                          activeCentralObjectiveKpi.length > 0 ?//NOSONAR
                            <OKRImportObj masterObjKpiList={activeCentralObjectiveKpi} showHideComponent="activeCentralOKR" enableAction={enableAction} />
                            :
                            <div style={{ marginTop: '10%' }}>
                              <div className='new-productivity-allocation-no-data-header'>No Items</div>
                              <div className='new-productivity-allocation-no-data-sub-header pt-lg'>You don't have any items here. Select employee</div>
                              <div className='new-productivity-allocation-no-data-sub-header'>and financial year to continue.</div>
                            </div>
                        }
                      </>
                      :
                      <div style={{ marginTop: '10%' }}><ProgressBar /></div>
                  }
                </div>
                <div style={{ padding: "10px 0px 0px 0px" }}>
                  {
                    activeCentralObjectiveKpi.length > 0 &&
                    <div className="col-xs-12 col-sm-8 col-lg-11" style={{ padding: "8px 0px 0px" }}>
                      <span className="footer-status" style={{ background: "#36C17C" }}></span>
                      <span style={{ padding: "0px 24px 0px 8px" }}>Active</span>
                      <span className="footer-status" style={{ background: "#DBDBDD" }}></span>
                      <span style={{ padding: "0px 24px 0px 8px" }}>Disabled </span>
                      <span className="footer-status" style={{ background: "#f47a7a" }}></span>
                      <span style={{ padding: "0px 24px 0px 8px" }}>Discrepancy</span>
                    </div>
                  }
                </div>
              </div>

              {/* Closed TAB */}

              <div>
                <div
                  style={{
                    // height: 300,
                    height: setHeightFlag === true ? dynamicHeight : height,
                    padding: '0px 0px 0px 0px',
                    overflowY: "auto",
                    overflowX: "hidden",
                    position: "relative",
                    marginTop: '0px',
                    marginBottom: '-6px',
                  }}
                >
                  {
                    closedCentralOkr.map((item, inx) => (
                      <div key={`reviewBusinessOKR_${inx}`} id={`reviewBusinessOKR_${inx}`}>
                        <>
                          <ClosedTabObjHeader objectiveData={item} />
                        </>
                        <ReviewObjectivKR objectiveData={item}
                          opneKrDetailsPopup={this.opneKrDetailsPopup}
                          openFinalRatingPopup={this.openFinalRatingPopup}
                          openPastRatingPopup={this.openPastRatingPopup}
                          openActualAchivementPopup={this.openActualAchivementPopup}
                          openAddEditIntrimFeedbackPopup={this.openAddEditIntrimFeedbackPopup}
                          openKrIntrimFeedbackPopup={this.openKrIntrimFeedbackPopup}
                          openViewIntrimFeedbackPopup={this.openViewIntrimFeedbackPopup}
                          ratingTabs={"sup"}
                          OKRType={"1"}
                          currencyInfo={currencyInfo}
                          viewKrReviewPopup={this.viewKrReviewPopup}
                          OKRAllocationStatus={""}
                          categoryName={categoryName}
                          VariableLinked={"No"} />
                      </div>
                    ))
                  }
                  {closedCentralOkr.length === 0 && <div className="okr-no-item" style={{ paddingTop: "100px" }}>You don't have any Closed OKR here.</div>}
                  {
                    viewKrDetailsPopup &&
                    <ViewKRDetailsModal
                      closeKrDetailsPopup={this.closeKrDetailsPopup}
                      OKRType={"1"}
                      ratingTabs={"sup"}
                      ratingType={"review"}
                      KRData={KRData}

                    />
                  }
                  {
                    finalRatingPopup &&
                    <AddRatingModalkr
                      closeKrRatingPopup={this.closeFinalRatingPopup}
                      OKRType={"1"}
                      ratingTabs={"sup"}
                      KRData={finalRatingData}
                      type={"finalRaing"}
                    />
                  }
                  {
                    pastRatingPopup &&
                    <PastRatingModal
                      OKRType={'2'}
                      KRData={pastRatingKRData}
                      closePastRatingPopup={this.closePastRatingPopup}
                    />
                  }
                  {
                    viewActualAchivementPopup &&
                    <ActualAchivementModal
                      closeActualAchivementPopup={this.closeActualAchivementPopup}
                      OKRType={"1"}
                      ratingTabs={"sup"}
                      actualKRData={actualKRData}
                      currencyInfo={currencyInfo}
                      OKRAllocationStatus={""}
                      categoryType={categoryType}
                      actionType={actionType}
                    />
                  }

                  {
                    viewKrRatingPopup &&
                    <AddRatingModalkr
                      closeKrRatingPopup={this.closeKrRatingPopup}
                      OKRType={"1"}
                      ratingTabs={"sup"}
                      KRData={KRData}

                    />}
                  {viewInterimReviewPopup &&
                    <InterimFeedBack
                      closeViewIntrimFeedbackPopup={this.closeViewIntrimFeedbackPopup}
                      ratingTabs={"sup"}
                      OKRType={"1"}
                      KRData={KRData}
                      objId={objId}
                      selectedType={selectedType}
                    />}
                  {
                    viewReviewKrPopup &&
                    <ViewRatingModalKr
                      KRReviewData={KRReviewData}
                      closeKrReviewPopup={this.closeKrReviewPopup}
                      type={type}
                    />
                  }
                </div>
              </div>



            </div>

          </TabComponent>
          {/* </>             : <div style={{ marginTop: '10%' }}><ProgressBar /></div>} */}

        </div>

      </>
    );
  }
}
const mapStateToProps = ({ auth, okrreducer, meetingcategory }) => {
  const { authUser } = auth;
  const { categoryName } = meetingcategory;
  const {
    employeeId,
    centralOkrData,
    centralOkrMasterList,
    userObjectStatus,
    centralKPITemp,
    allcommonTempData,
    reRenderComponent,
    fyid, draftData, draftWeight, okrAllocationTabs, activeData, activeWeight, activeTabFlag, activeCentralObjectiveKpi, draftCentralObjectiveKpi, updateCentralStatus, centralUserVariableImpact, centralUserImpact, centralUserFrequency, moveCentralToActive,
    closedCentralOkr
  } = okrreducer;
  return {
    authUser,
    employeeId,
    centralOkrData,
    centralOkrMasterList,
    userObjectStatus,
    centralKPITemp,
    allcommonTempData,
    reRenderComponent, activeTabFlag, okrAllocationTabs,
    fyid, draftData, draftWeight, activeData, activeWeight,
    activeCentralObjectiveKpi, draftCentralObjectiveKpi,
    updateCentralStatus,
    centralUserVariableImpact, centralUserImpact,
    centralUserFrequency,
    moveCentralToActive,
    categoryName, closedCentralOkr
  };
};
OkrCentral.propTypes = {
  authUser: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  employeeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  centralOkrMasterList: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(object),
  ]),
  centralKPITemp: PropTypes.arrayOf(object),
  centralOkrData: PropTypes.arrayOf(object),
  allcommonTempData: PropTypes.arrayOf(object),
  parentHeight: PropTypes.bool,
  draftData: PropTypes.arrayOf(object),
  activeData: PropTypes.arrayOf(object),
  draftWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  activeWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setHeightFlag: PropTypes.bool.isRequired,
  windowAdjust: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  draftCentralObjectiveKpi: PropTypes.arrayOf(object),
  activeCentralObjectiveKpi: PropTypes.arrayOf(object),
  updateCentralStatus: PropTypes.string,
  centralUserVariableImpact: PropTypes.number,
  centralUserImpact: PropTypes.number,
  centralUserFrequency: PropTypes.string,
  moveCentralToActive: PropTypes.string,
  activeTabFlag: PropTypes.bool,
};
OkrCentral.defaultProps = {
  authUser: "",
  employeeId: 0,
  centralOkrMasterList: {},
  centralOkrData: [],
  centralKPITemp: [],
  allcommonTempData: [],
  parentHeight: false,
  draftData: [],
  activeData: [],
  draftWeight: 0,
  activeWeight: 0,
  activeTabFlag: false,
  draftCentralObjectiveKpi: [],
  activeCentralObjectiveKpi: [],
  updateCentralStatus: "",
  centralUserVariableImpact: 0,
  centralUserImpact: 0,
  centralUserFrequency: "",
  moveCentralToActive: ""
};
export default connect(mapStateToProps, {
  setCentralOkrKPIRedux,
  setUserOKRObjectiveKPI,
  addUpdateObjectveKpi,
  setOKRUserKPI,
  submitApprovalAction
})(OkrCentral);
