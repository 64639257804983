/**
 * Author Name : Sanket Bari
 * Created Date: 05 March 2024
 * Modified Date: - .
 * Last Modified Name: - .
 */



import React, { useState, useEffect } from "react";
import "../../layout/tibrlayout/tibrlayout.css";
import StatusButton from './statusbutton';
import excel from '../../../assets/images/excel.png';



const Recentactivity = ({ filename, activityCompletionStatus, id, headerdata, activityType, displayActivityDate, statusColor, activityStatus, activityLog, autoView, monthlyView, swap, data }) => {
  const [currentData, setCurrentData] = useState(null);
  const [activitydata, setActivityData] = useState(null);
  console.log()
  // Update the component when props change
  useEffect(() => {
    // Update currentData with the new props      
    if (id === "attendanceTab") {
      swap = false;
      autoView = false;
      monthlyView = true;
    }
    else if (id === "tibProcessTab") {
      swap = false;
      autoView = true;
      monthlyView = false;
    }
    else {
      swap = true;
      autoView = false;
      monthlyView = false;
    }
    setCurrentData({
      id,
      headerdata,
      activityType,
      displayActivityDate,
      statusColor,
      activityStatus,
      activityLog,
      autoView,
      monthlyView,
      swap,
      filename,
      activityCompletionStatus,
    });
    const buttonInfo = {
      1: {
        buttonContent: 'In Progress...',
        bgColor: '#fde3e4',
        fontColor: statusColor
      },
      2: {
        buttonContent: 'Completed',
        bgColor: '#cef1df',
        fontColor: '#4cbf86'
      },
      3: {
        buttonContent: 'Error',
        bgColor: '#fde3e4',
        fontColor: statusColor
      },
    }
    if (monthlyView || swap) {
      setActivityData(
        <>

          {filename.length !== 0 && <div className="activity-column"><img style={{ height: '25px' }} src={excel} alt="excel icon" /> <span>{filename}</span></div>}
          {filename.length !== 0 && <div className="activity-column">{displayActivityDate}</div>}
          {/* <div className="activity-column">{displayActivityDate}</div> */}
          <div className="activity-column">{activityType}</div>
          <div className="activity-column">{displayActivityDate}</div>
          <div className="activity-column">
            <StatusButton activityCompletionStatus={activityCompletionStatus} minHeight='30px' activityStatus={activityStatus} minWidth='60px' {...buttonInfo[activityCompletionStatus]} borderStyle='none' borderRadius='5px' />
          </div>
        </>
      );

    } else if (autoView) {
      setActivityData(
        <>
          <div className="activity-column">{activityType}</div>
          <div className="activity-column">{displayActivityDate}</div>
          {(activityCompletionStatus === '1' || activityCompletionStatus === '3') ? (
            <>
              <div className="activity-column"><StatusButton activityCompletionStatus={activityCompletionStatus} minHeight='30px' activityStatus={activityStatus} minWidth='60px' bgColor='#fde3e4' fontColor={statusColor} borderStyle='none' borderRadius='5px' buttonContent={activityCompletionStatus === '1' ? 'In Progress...' : "Error"} /></div>
            </>) : (
            <>
              <div className="activity-column"><StatusButton activityCompletionStatus={activityCompletionStatus} minHeight='30px' activityStatus={activityStatus} minWidth='60px' bgColor='#cef1df' fontColor='#4cbf86' borderStyle='none' borderRadius='5px' buttonContent="Completed" /></div>
            </>
          )}
        </>
      );

    }
  }, [id, activityType, displayActivityDate, statusColor, activityStatus, activityLog, autoView, monthlyView, swap, headerdata, filename, activityCompletionStatus]);

  return (
    <div className="recent-activity-container">
      <div className="recent-activity-header">
        Recent Activity
      </div>
      <div className="recent-activity-content">

        <div className="activity-row">
          {headerdata} {/* Render dynamic header */}

        </div>
        <div className="activity-row">
          {activitydata}
        </div>

        <div className="activity-row">
          <div className="activity-log-header" colspan="5">Activity Log</div>
        </div>
        <div className="activity-row">
          <div className="activity-column" colspan="5">{activityLog}</div>
        </div>
      </div>
    </div>
  );
}

export default Recentactivity;
