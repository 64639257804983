import React from 'react';
function Grid(props) {
  const { objectiveData }= props;
  return (
    <div>
        <table className="table table-hover table-bordered" style={{ maxHeight: "200px" }}>
        <thead>
            <tr>
                <th className='ratng-objective-width' style={{ backgroundColor: "#fff" }} scope="col" colSpan="1" rowSpan={2}><div className="okr-rating-objective-grid-text">{objectiveData.objectiveName}</div></th>
                <th scope="col" colSpan={2} className='no-rating' >
                    <div className='dashboard-grid-quarterly-review-text'>{objectiveData.frequency} Review</div>
                </th>
                <th scope="col" colSpan={objectiveData.months.length} className='dashboard-grid-quarterly-review-text no-rating'>
                    Final Rating
                </th>
            </tr>
            <tr>
                <th className='no-rating'>
                    <div className='dashboard-grid-weightage-text'>Weightage</div>
                </th>
                <th className='dashboard-grid-text no-rating'>
                    <div className='dashboard-grid-weightage-text'>Benchmark</div>
                </th>
                {
                  objectiveData.months?.map(item => {
                  return(
                  <th key={item} className='no-rating'>
                      <div className='dashboard-month-header-text'>
                        <center>
                            {item}
                        </center>
                        </div>
                  </th>
                  )
                  })
                }

            </tr>
          {
            objectiveData.krData?.map(data => {
            return(
            <tr>
                <td className='ratng-key-reasult-width dashboard-kr-text '>
                    {data.KRName}
                </td>
                <td className='dashboard-grid-text dashboard-grid-weightage'>
                    {data.Weightage}
                </td>
                <td className='dashboard-grid-text'>
                    {data.Benchmark}
                </td>
                <td className={`dashboard-grid-text ${data.Apr ===null ? 'no-rating' : ''}`}>
                    {data.Apr}
                </td>
                <td className={`dashboard-grid-text ${data.May ===null ? 'no-rating' : ''}`}>
                    {data.May}
                </td>
                <td className={`dashboard-grid-text ${data.Jun ===null ? 'no-rating' : ''}`}>
                    {data.Jun}
                </td>
                <td className={`dashboard-grid-text ${data.Jul ===null ? 'no-rating' : ''}`}>
                    {data.Jul}
                </td>
                <td className={`dashboard-grid-text ${data.Aug ===null ? 'no-rating' : ''}`}>
                    {data.Aug}
                </td>
                <td className={`dashboard-grid-text ${data.Sep ===null ? 'no-rating' : ''}`}>
                    {data.Sep}
                </td>
                <td className={`dashboard-grid-text ${data.Oct ===null ? 'no-rating' : ''}`}>
                    {data.Oct}
                </td>
                <td className={`dashboard-grid-text ${data.Nov ===null ? 'no-rating' : ''}`}>
                    {data.Nov}
                </td>
                <td className={`dashboard-grid-text ${data.Dec ===null ? 'no-rating' : ''}`}>
                    {data.Dec}
                </td>
                <td className={`dashboard-grid-text ${data.Jan ===null ? 'no-rating' : ''}`}>
                    {data.Jan}
                </td>
                <td className={`dashboard-grid-text ${data.Feb ===null ? 'no-rating' : ''}`}>
                    {data.Feb}
                </td>
                <td className={`dashboard-grid-text ${data.Mar ===null ? 'no-rating' : ''}`}>
                    {data.Mar}
                </td>
            </tr>
            )
          })
        }
        </thead>                        
        </table>
    </div>
  )
}

export default Grid;
