import React, { Component } from 'react';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  DetailRow,
  Inject,
  Page,
  Filter
} from "@syncfusion/ej2-react-grids";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  // faEject,
  // faHouseLeave,
  faEdit,
  // faShareAlt
} from "@fortawesome/pro-light-svg-icons";
import { ComboBoxComponent } from "@syncfusion/ej2-react-dropdowns";
import { CheckBoxComponent } from "@syncfusion/ej2-react-buttons";
import Progressbar from '../../progressbar/progressbar';
import EmployeeNameTemplate from "../employeeNameTemplate";
import {
  getGridColumn,
  EMPLOYEE_LIST_EMPLOYEE_NAME,
  BUSINESS_UNIT_HEAD,
  LEVEL_1_TEAM_MEMBER_NAME,
  EMPLOYEE_LIST_ACTION,
  OPS_SALE_CENTRAL_PNL_HEAD,
  OPS_SUB_SALE_PNL_FUNCTION_HEAD,
  SERVICE_DIVISION_PRACTICE_SALES_UNIT_HEAD,
  PROJECT_MANAGER_SALES_PERSON_NAME,
  LEVEL8_TEAM_MEMBER_NAME,
  LEVEL9_TEAM_MEMBER_NAME,
  DATE_OF_JOINING,
  LEVEL_9
} from "../../../app/other/gridcolumns";
import '../../../app/containers/tmlc/tmlc.css';
import '../resignationcomponent/resignationcomponent.css';
import { setMeetingCategory } from '../../../redux/actions/meetingcategoryaction';
import { updateOrgChartDetailsAction, setOrhChartDetaila, addEditCostSharingAction } from '../../../redux/actions/entryexit/orgchartaction';
import { addUpdateSeparationDetails } from '../../../redux/actions/entryexit/separationaction';

import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import {
  DialogContent,
  DialogActions,
  DialogTitleTMLC
} from "../../customdialog/customdialog";
import { Button, Dialog } from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ErrorBoundary from "../../error/errorboundary";
import OrgChatMaster from '../../../services/entryexit/onbordingServices';

import { ActingActualStatus, AllDateFormat } from "../allcommoncomponents";
import ErrorMessage from "../../errormessage/errormessage";

class OrgchartMastersOthers extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: null,
      redirestCategary: "",
      gridHeight: (window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight) - 290,
      openMenuDialog: false,
      employeeType: [],
      entity: [],
      countryList: [],
      jobRoleState: [],
      bandList: [],
      salesUnit: [{ Id: 0, Name: "" }],
      functionMasterList: [],
      employeeData: [],
      openPopUp: false,
      editMasterUserId: 0,
      serviceDevisionData: [],
      popupLoader: false,
      serviceDevisionId: 0,
      reportingTo: "",
      reportingToRoleAS: "",
      groupSalesManagerData: [],
      groupSalesManagerId: '',
      projectManagerSalesPersonId: '',
      projectManagerSalesPersonData: [],
      projectManagerSalesPersonRolesAs: '',
      projectIndividualInchargeId: '',
      projectIndividualInchargeData: [],
      ReportingToRoleAS: ''
    };
    this.width =
      (window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth) - 100; // 100

    this.Filter = {
      type: "Menu",
    };
    this.check = { type: 'CheckBox' };
    this.employeeNameTemplate = this.employeeNameTemplate.bind(this);
    this.navigateToAddBasicInformation = this.navigateToAddBasicInformation.bind(
      this
    );
  }
  updateDimensions = () => {
    let activeGridHeight = window.innerHeight - 255; //255
    this.setState({ gridHeight: activeGridHeight });
  }
  componentDidMount = () => {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions);
    this.setHeaderCss();
  };
  setHeaderCss = () => {
    setTimeout(() => {
      const ele = document.getElementsByClassName("e-headercelldiv");
      for(let i in ele) {
        if(!isNaN(parseInt(i, 10))){
          let index = parseInt(i, 10);
          if (
            ele[index].innerText === EMPLOYEE_LIST_ACTION) {
            ele[index].classList.add("grid-center-header");
          } else {
            ele[index].classList.add("grid-left-header");
          }
        }
      }
    }, 1900);
  }

  onQueryCellInfoPreJoining = (args) => {
    const { cell } = args;
    cell.setAttribute(
      "style",
      "font-size:14px !important; color:#4B4A52 !important; text-align: left !important; padding-left:18px !important"
    );
  };
  openEmployeeDetailPopup = (props) => {    
    this.setState({
      openPopUp: true,
      popupLoader: true,
    })
    try {
      const getMasterData = Promise.resolve(
        OrgChatMaster.getMasterData("ORGMASTER", props.EmployeeId, props.serviceDevisionId)
      );
      getMasterData.then((resultData) => {
        if (resultData !== null) {
          let serviceDevisionData = resultData.filter(item => { return item.Type === 'SD_SU'; })
          let groupSalesManagerData = resultData.filter(item => { return item.Type === 'GM_SM'; })
          let projectManagerSalesPersonData = resultData.filter(item => { return item.Type === 'PM_SP'; })
          let projectIndividualInchargeData = resultData.filter(item => { return item.Type === 'PIC'; })
          this.setState({
            editMasterUserId: props.EmployeeId,
            serviceDevisionData: serviceDevisionData,
            popupLoader: false,
            serviceDevisionId: props.serviceDevisionId,
            reportingTo: props.reportingToId,
            reportingToRoleAS: props.reportingToRoleAs,
            groupSalesManagerId: props.gMSMUserId,
            groupSalesManagerData: groupSalesManagerData,
            projectManagerSalesPersonId: props.pMSPUserId,
            projectManagerSalesPersonData: projectManagerSalesPersonData,
            projectManagerSalesPersonRolesAs: props.pMSPUserRoleAs,
            projectIndividualInchargeId: props.pICUserId,
            projectIndividualInchargeData: projectIndividualInchargeData,
            projectIndividualInchargeReportingToRoleAS: props.pICUserRoleAs,
          });

        } else {
          console.log("error resultData", resultData);
        }
      });
    } catch (e) {
      // eslint-disable-next-line
      console.log(e);
    }
  }
  closeEmployeeDetailPopup = () => {
    this.setState({
      openPopUp: false,
      editMasterUserId: '',
      serviceDevisionData: [],
      popupLoader: false,
      serviceDevisionId: "",
      reportingTo: "",
      reportingToRoleAS: "",
      groupSalesManagerId: "",
      groupSalesManagerData: [],
      projectManagerSalesPersonId: "",
      projectManagerSalesPersonData: [],
      projectManagerSalesPersonRolesAs: "",
      projectIndividualInchargeId: "",
      projectIndividualInchargeData: [],
      projectIndividualInchargeReportingToRoleAS: "",
    })
  }
  actionTemplate = (props) => {
    return (
      <div className="col-12" style={{
        //  textAlign: "center",
        padding: '0px 10px 0px 10px'
      }}
      >
        <div className="row1">
          <div className="col-3">
            <TooltipComponent position="BottomCenter" isSticky={false} content='Edit' cssClass='pointer-cursor'>
              <FontAwesomeIcon style={{ fontSize: 15 }}
                className='pointer-cursor'
                onClick={() => this.openEmployeeDetailPopup(props)}
                icon={faEdit} />
            </TooltipComponent>
          </div>
        </div>
      </div>
    );
  };


  navigateToAddBasicInformation = async (args) => {/**NOSONAR */

  }
  employeeNameTemplate(props) {
    return (
      <EmployeeNameTemplate
        props={props}
        data="test"
        navigateToAddBasicInformation={this.navigateToAddBasicInformation}
      />
    );
  }

  level1ActionRole = (props) => {
    const { GroupHeadRoleAS, GroupHeadName } = props;
    return (
      <ActingActualStatus actingActualstatus={GroupHeadRoleAS} groupHeadName={GroupHeadName} />
    );
  }
  level2ActionRole = (props) => {
    const { BussHdRoleAS, BUsssPnlHeadName } = props;
    return (
      <ActingActualStatus actingActualstatus={BussHdRoleAS} groupHeadName={BUsssPnlHeadName} />
    );
  }
  level3ActionRole = (props) => {
    const { BussSubHdRoleAS, BUsssSubPnlHeadName } = props;
    return (
      <ActingActualStatus actingActualstatus={BussSubHdRoleAS} groupHeadName={BUsssSubPnlHeadName} />
    );
  }
  level4ActionRole = (props) => {
    const { SubPnlHdRoleAS, SubPnlHead } = props;
    return (
      <ActingActualStatus actingActualstatus={SubPnlHdRoleAS} groupHeadName={SubPnlHead} />
    );
  }
  level5ActionRole = (props) => {
    const { SDHdRoleAS, SDHead } = props;
    return (
      <ActingActualStatus actingActualstatus={SDHdRoleAS} groupHeadName={SDHead} />
    );
  }
  level6ActionRole = (props) => {
    const { actingRole6, GMSU } = props;
    return (
      <ActingActualStatus actingActualstatus={actingRole6} groupHeadName={GMSU} />
    );
  }
  level7ActionRole = (props) => {
    const { PM_SP_RoleAS, PM_SP } = props;
    return (
      <ActingActualStatus actingActualstatus={PM_SP_RoleAS} groupHeadName={PM_SP} />
    );
  }
  level8ActionRole = (props) => {
    const { PIC_RoleAS, PIC_Name } = props;
    return (
      <ActingActualStatus actingActualstatus={PIC_RoleAS} groupHeadName={PIC_Name} />
    );
  }
  level9ActionRole = (props) => {
    const { level9UserRoleAS, level9UserName } = props;
    return (
      <ActingActualStatus actingActualstatus={level9UserRoleAS} groupHeadName={level9UserName} />
    );
  }
  dateOfJoining = (props) => {
    const { JoiningDate } = props;
    return (
      <AllDateFormat date={JoiningDate} />
    );
  }
  level9 = (props) => {
    const { level9 } = props;
    if (level9 === "") {
      return (
        <span>{`Individual Incharge`}</span>
      );
    } else {
      return (
        <span>{level9}</span>
      );
    }
  }
  onChangeServiseDivision = (e) => {

      try {
        this.setState({
          popupLoader: true
        })
        const getMasterData = Promise.resolve(
          OrgChatMaster.getMasterData("ORGMASTER", e.itemData.Id, e.itemData.Id)
        );
        getMasterData.then((resultData) => {
          if (resultData !== null) {
            let serviceDevisionData = resultData.filter(item => { return item.Type === 'SD_SU'; })
            let groupSalesManagerData = resultData.filter(item => { return item.Type === 'GM_SM'; })
            let projectManagerSalesPersonData = resultData.filter(item => { return item.Type === 'PM_SP'; })
            let projectIndividualInchargeData = resultData.filter(item => { return item.Type === 'PIC'; })
            this.setState({
              serviceDevisionId: e.itemData.Id,
              serviceDevisionData: serviceDevisionData,
              popupLoader: false,
              groupSalesManagerData: groupSalesManagerData,
              projectManagerSalesPersonData: projectManagerSalesPersonData,
              projectIndividualInchargeData: projectIndividualInchargeData,
            });

          } else {
            console.log("error resultData");
          }
        });
      } catch (error) {
        // eslint-disable-next-line
        console.log(error);
      }
    // }
  }
  render() {/**NOSONAR */
    const { redirect, redirestCategary, gridHeight, openPopUp, popupLoader, serviceDevisionData, serviceDevisionId, reportingTo, reportingToRoleAS, groupSalesManagerData, groupSalesManagerId,
      projectManagerSalesPersonId, projectManagerSalesPersonData, projectManagerSalesPersonRolesAs, projectIndividualInchargeId, projectIndividualInchargeData, projectIndividualInchargeReportingToRoleAS
    } = this.state;
   
    const { othersCheckAvailablity } = this.props;
    if (redirect !== null) {
      this.props.setMeetingCategory(redirestCategary)
      return <Redirect to={redirect} />;
    }
    const { employeeData } = this.props;
    var widthColumnConfirm = 0;
    var finalWidthConfirm;
    // eslint-disable-next-line
    getGridColumn("masterothers").forEach((data) => {
      widthColumnConfirm = widthColumnConfirm + 20 + data.width;
    });
    if (widthColumnConfirm <= this.width) {
      finalWidthConfirm = widthColumnConfirm;
    } else {
      finalWidthConfirm = this.width + 10
    }
    return (
      <div className='employee-list-manager-hr-grid-height' style={{ paddingLeft: 0, marginLeft: -15 }}>
        <div>
          {
            // The date piker user for get date month wise data in the grid
          }
          {
            employeeData.length > 0 ?
              <div className="row-xs-12 row-sm-12 row-lg-12 row-md-12">
                <div>
                  <div style={{ paddingTop: "5px" }}
                    className=""
                  >
                    <GridComponent
                      className="grid-head-custome"
                      id="overviewgrid"
                      allowPaging
                      allowFiltering
                      filterSettings={this.Filter}
                      rowHeight={30}
                      height={gridHeight}
                      queryCellInfo={this.onQueryCellInfoPreJoining}
                      pageSettings={{
                        pageSize:
                          (window.innerHeight ||
                            document.documentElement.clientHeight ||
                            document.body.clientHeight) > 767
                            ? 11 //12
                            : 7,
                        pageCount: 5
                      }}
                      dataSource={employeeData}
                      allowTextWrap={true}
                      width={`${finalWidthConfirm}`}
                    >
                      <ColumnsDirective>
                        {getGridColumn("masterothers").map((item) => {
                          switch (item.colName) {
                            case EMPLOYEE_LIST_EMPLOYEE_NAME:
                              return (
                                <ColumnDirective
                                  key={item.field}
                                  template={this.employeeNameTemplate}
                                  textAlign={`${item.align} !important`}
                                  field={item.field}
                                  headerText={item.colName}
                                  width={item.width}
                                  filter={this.Filter}
                                />
                              );
                            case EMPLOYEE_LIST_ACTION:
                              return (
                                <ColumnDirective
                                  key={item.field}
                                  template={this.actionTemplate}
                                  filter={false}
                                  textAlign={`${item.align} !important`}
                                  field={item.field}
                                  headerText={item.colName}
                                  width={item.width}
                                  allowFiltering={false}
                                />
                              );
                            case LEVEL_1_TEAM_MEMBER_NAME:
                              return (
                                <ColumnDirective
                                  key={item.field}
                                  template={this.level1ActionRole}
                                  textAlign={`${item.align} !important`}
                                  field={item.field}
                                  headerText={item.colName}
                                  width={item.width}
                                  filter={this.check}
                                />
                              );
                            case BUSINESS_UNIT_HEAD:
                              return (
                                <ColumnDirective
                                  key={item.field}
                                  template={this.level2ActionRole}
                                  textAlign={`${item.align} !important`}
                                  field={item.field}
                                  headerText={item.colName}
                                  width={item.width}
                                  filter={this.check}
                                />
                              );
                            case OPS_SALE_CENTRAL_PNL_HEAD:
                              return (
                                <ColumnDirective
                                  key={item.field}
                                  template={this.level3ActionRole}
                                  textAlign={`${item.align} !important`}
                                  field={item.field}
                                  headerText={item.colName}
                                  width={item.width}
                                  filter={this.check}
                                />
                              );
                            case OPS_SUB_SALE_PNL_FUNCTION_HEAD:
                              return (
                                <ColumnDirective
                                  key={item.field}
                                  template={this.level4ActionRole}
                                  textAlign={`${item.align} !important`}
                                  field={item.field}
                                  headerText={item.colName}
                                  width={item.width}
                                  filter={this.check}
                                />
                              );
                            case SERVICE_DIVISION_PRACTICE_SALES_UNIT_HEAD:
                              return (
                                <ColumnDirective
                                  key={item.field}
                                  template={this.level5ActionRole}
                                  textAlign={`${item.align} !important`}
                                  field={item.field}
                                  headerText={item.colName}
                                  width={item.width}
                                  filter={this.check}
                                />
                              );
                            case PROJECT_MANAGER_SALES_PERSON_NAME:
                              return (
                                <ColumnDirective
                                  key={item.field}
                                  template={this.level7ActionRole}
                                  textAlign={`${item.align} !important`}
                                  field={item.field}
                                  headerText={item.colName}
                                  width={item.width}
                                  filter={this.check}
                                />
                              );
                            case LEVEL_9:
                              return (
                                <ColumnDirective
                                  key={item.field}
                                  template={this.level9}
                                  textAlign={`${item.align} !important`}
                                  field={item.field}
                                  headerText={item.colName}
                                  width={item.width}
                                  filter={this.check}
                                />
                              );
                            case LEVEL8_TEAM_MEMBER_NAME:
                              return (
                                <ColumnDirective
                                  key={item.field}
                                  template={this.level8ActionRole}
                                  textAlign={`${item.align} !important`}
                                  field={item.field}
                                  headerText={item.colName}
                                  width={item.width}
                                  filter={this.check}
                                />
                              );
                            case LEVEL9_TEAM_MEMBER_NAME:
                              return (
                                <ColumnDirective
                                  key={item.field}
                                  template={this.level9ActionRole}
                                  textAlign={`${item.align} !important`}
                                  field={item.field}
                                  headerText={item.colName}
                                  width={item.width}
                                  filter={this.check}
                                />
                              );
                            case DATE_OF_JOINING:
                              return (
                                <ColumnDirective
                                  key={item.field}
                                  template={this.dateOfJoining}
                                  textAlign={`${item.align} !important`}
                                  field={item.field}
                                  headerText={item.colName}
                                  width={item.width}
                                  filter={this.check}
                                />
                              );
                            default:
                              return (
                                <ColumnDirective
                                  key={item.field}
                                  textAlign={`${item.align} !important`}
                                  field={item.field}
                                  headerText={item.colName}
                                  width={item.width}
                                  filter={this.check}
                                />
                              );
                          }
                        })}
                      </ColumnsDirective>
                      <Inject services={[Page, DetailRow, Filter]} />
                    </GridComponent>
                  </div>
                </div>
              </div>
              :
              <div className="control-section col-md-12" style={{ height: 75 }}>
                {employeeData !== "error" && employeeData.length > 0 ?/**NOSONAR */ (
                  <Progressbar />
                ) : (
                    <ErrorMessage message="No Data Available" />
                  )}
              </div>
          }
          <div style={{ width: "350px" }}>
            <Dialog
              open={openPopUp}
              fullWidth
              maxWidth='sm'
            >
              <DialogTitleTMLC>
                <div className='col-lg-12'>
                  <div className="col-xs-2 col-sm-2 col-lg-8 col-md-12">Edit Master Details </div>
                  <div style={{ float: "right", marginRight: "-5px" }}>
                    <div style={{ float: "right" }}>
                      <IconButton style={{ color: "white", marginRight: "-17px", marginTop: "-7px" }} aria-label="close" onClick={this.closeEmployeeDetailPopup}>
                        <CloseIcon />
                      </IconButton>
                    </div>
                  </div>
                </div>
              </DialogTitleTMLC>
              <ErrorBoundary>
                <DialogContent dividers>
                  {!popupLoader ?
                    <div className="col-lg-12 control-section">
                      <div className="row">
                        <div className='col-lg-4 col-sx-2 col-sm-2 md-2' style={{ marginLeft: 0, marginTop: 15, paddingLeft: 0 }}>
                          <ComboBoxComponent
                            allowCustom={false}
                            fields={{ text: "Name", value: "Id" }}
                            id="ServiceDivision"
                            dataSource={serviceDevisionData}
                            value={serviceDevisionId}
                            change={(e) => this.onChangeServiseDivision(e)}
                            placeholder="Service Devision *"
                            cssClass="e-outline"
                            floatLabelType="Auto"
                          />
                        </div>
                        <div className='col-lg-4 col-sx-2 col-sm-2 md-2' style={{ marginLeft: 0, marginTop: 15, paddingLeft: 0 }}>
                          <ComboBoxComponent
                            allowCustom={false}
                            fields={{ text: "name", value: "UserId" }}
                            dataSource={othersCheckAvailablity}
                            value={reportingTo}
                            id="reportingTo"
                            placeholder="Reporting To *"
                            cssClass="e-outline"
                            floatLabelType="Auto"
                          />
                        </div>
                        <div className='col-lg-4 col-sx-2 col-sm-2 md-2' style={{ marginLeft: 0, marginTop: 15, paddingLeft: 0, paddingTop: 10 }}>
                          <CheckBoxComponent
                            label="Is Acting Reporting To"
                            value={reportingToRoleAS === "(Acting)" ?/**NOSONAR */ true : false}
                            checked={reportingToRoleAS === "(Acting)" ? /**NOSONAR */true : false}
                          />
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-lg-4 col-sx-2 col-sm-2 md-2' style={{ marginLeft: 0, marginTop: 15, paddingLeft: 0 }}>
                          <ComboBoxComponent
                            allowCustom={false}
                            fields={{ text: "Name", value: "Id" }}
                            dataSource={groupSalesManagerData}
                            value={groupSalesManagerId}
                            id="groupSalesManager"
                            placeholder="Group/Sales Manager *"
                            cssClass="e-outline"
                            floatLabelType="Auto"
                          />
                        </div>
                        <div className='col-lg-4 col-sx-2 col-sm-2 md-2' style={{ marginLeft: 0, marginTop: 15, paddingLeft: 0, paddingTop: 10 }}>
                          <CheckBoxComponent
                            label="Is Acting General/Sales Manager"
                          // value={this.props.getExcludeSundayHoliday}
                          // checked={this.props.getExcludeSundayHoliday}
                          // change={this.getExcludeSundayHoliday}
                          />
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-lg-4 col-sx-2 col-sm-2 md-2' style={{ marginLeft: 0, marginTop: 15, paddingLeft: 0 }}>
                          <ComboBoxComponent
                            allowCustom={false}
                            fields={{ text: "Name", value: "Id" }}
                            dataSource={projectManagerSalesPersonData}
                            value={projectManagerSalesPersonId}
                            id="projectSalespersonManager"
                            placeholder="Project Manager/Sales Person *"
                            cssClass="e-outline"
                            floatLabelType="Auto"
                          />
                        </div>
                        <div className='col-lg-4 col-sx-2 col-sm-2 md-2' style={{ marginLeft: 0, marginTop: 15, paddingLeft: 0, paddingTop: 10 }}>
                          <CheckBoxComponent
                            label="Is Acting Project Manager/Sales Person"
                            value={projectManagerSalesPersonRolesAs === "(Acting)" ?/**NOSONAR */ true : false}
                            checked={projectManagerSalesPersonRolesAs === "(Acting)" ?/**NOSONAR */ true : false}
                          // change={this.getExcludeSundayHoliday}
                          />
                        </div>
                      </div>
                      <div className='row'>
                        <div className='col-lg-4 col-sx-2 col-sm-2 md-2' style={{ marginLeft: 0, marginTop: 15, paddingLeft: 0 }}>
                          <ComboBoxComponent
                            allowCustom={false}
                            fields={{ text: "Name", value: "Id" }}
                            value={projectIndividualInchargeId}
                            dataSource={projectIndividualInchargeData}
                            id="projectIndividualIncharge"
                            placeholder="Project Individual Incharge *"
                            cssClass="e-outline"
                            floatLabelType="Auto"
                          />
                        </div>
                        <div className='col-lg-4 col-sx-2 col-sm-2 md-2' style={{ marginLeft: 0, marginTop: 15, paddingLeft: 0, paddingTop: 10 }}>
                          <CheckBoxComponent
                            label="Is Acting Individual Incharge"
                            value={projectIndividualInchargeReportingToRoleAS === "(Acting)" ?/**NOSONAR */ true : false}
                            checked={projectIndividualInchargeReportingToRoleAS === "(Acting)" ?/**NOSONAR */ true : false}
                          // change={this.getExcludeSundayHoliday}
                          />
                        </div>
                      </div>
                    </div>
                    :
                    <div style={{ height: 100 }}>
                      <Progressbar />
                    </div>
                  }
                </DialogContent>
                <DialogActions style={{ paddingRight: "25px" }}>
                  <Button color="default" variant="contained"
                    onClick={() => this.closeEmployeeDetailPopup()}
                  > Cancel </Button>
                  <Button color="primary" variant="contained"
                  // onClick={() => this.addEditCostSharing()} 
                  > Save </Button>
                </DialogActions>
              </ErrorBoundary>
            </Dialog>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ auth, orgchartreducer, meetingmaster }) => {
  const { authUser } = auth;
  const { othersCheckAvailablity } = meetingmaster
  const { orgchartupdateStatus, employeeId, fistName, lastName, designation, titleRole, employmentType, entityId, serviceDevision, reportingSupervison, band, emailId, country, location, functiondata, costSharing } = orgchartreducer;
  return {
    authUser, orgchartupdateStatus, employeeId, fistName, lastName, designation, titleRole, employmentType, entityId,
    serviceDevision, reportingSupervison, band, emailId, country, location, functiondata, costSharing, othersCheckAvailablity
  };
};

export default connect(mapStateToProps, { setMeetingCategory, updateOrgChartDetailsAction, setOrhChartDetaila, addEditCostSharingAction, addUpdateSeparationDetails })(
  OrgchartMastersOthers
);
