import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";

function LabelComponent(props) {
    const [brandColors, setBrandColors] = useState({ // NOSONAR
        ["brand01"]: ["#F94F5E", "#FA727E", "#FB959E", "#E8C0C4", "#FEDCDF"],
        ["brand02"]: ["#000000", "#4B4B55", "#939399", "#B7B7BB", "#DBDBDD"],
        ["success"]: ["#0CB963", "#3CC782", "#7ACEB9", "#A6DFD0", "#D2EFE8"],
        ["warning"]: ["#FDD141", "#FDDA67", "#FEE38D", "#FEEDB3", "#FFF6D9"],
        ["error"]: ["#FF3434", "#FF5C5C", "#FF8585", "#FFADAD", "#FFD6D6"],
        ["info"]: ["#2961FB", "#5380FB", "#7EA0FC", "#A9C0FD", "#D4DFFE"],
        ["purple"]: ["#9D7AD2", "#B195DB", "#C4AFE4", "#D8CAED", "#EBE4F6"],
        ["yellow"]: ["#FDCF41", "#FDD867", "#FEE28D", "#FEECB3", "#FEF5D9"],
        ["green"]: ["#22AE8A", "#4EBEA1", "#7ACEB9", "#A7DFD0", "#D3EFE8"],
        ["light-blue"]: ["#48B8E7", "#6DC6EC", "#91D4F1", "#B6E3F5", "#DAF1FA"],
        ["blue"]: ["#3F43AD", "#6569BD", "#8C8ECE", "#B2B4DE", "#D8DAEF"],
        ["background"]: ["#F2F2F2", "#FFFFFF"],
    });
    const [brandFontSize, setBrandFontSize] = useState({ // NOSONAR
        ["xs"]: "0.625rem",
        ["sm"]: "0.75rem",
        ["md"]: "0.875rem",
        ["lg"]: "1rem",
        ["xl"]: "1.25rem",
        ["xxl"]: "1.5rem",
    });
    const [applyColor, setApplyColor] = useState("");
    const [applyFontSize, setApplyFontSize] = useState("");

    useEffect(() => {
        getColors();
        getFontSize();
    }, [])

    const getColors = () => {
        if (props.color) { // brand01-0
            const brandAndColor = props.color.split("-");
            setApplyColor(brandColors[brandAndColor[0]][parseInt(brandAndColor[1])]);
        }
    }

    const getFontSize = () => {
        if (props.fontSize) { // sm
            setApplyFontSize(brandFontSize[props.fontSize]);
        }
    }

    return (
        <div
            className="common-lable"
            style={{
                color: applyColor,
                // fontSize: applyFontSize,
                fontWeight: props.isBold ? "bold" : "600",
                font: `normal normal normal ${applyFontSize} Arial`
            }}
        >
            {props.content}
        </div>
    )
}

LabelComponent.propTypes = {
    content: PropTypes.string,
    color: PropTypes.string,
    fontSize: PropTypes.string,
    isBold: PropTypes.bool
};

LabelComponent.defaultProps = {
    content: "",
    color: "brand01-0",
    fontSize: "lg",
    isBold: true,
};

export default LabelComponent;