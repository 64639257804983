import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faPlus, faPencilAlt } from "@fortawesome/pro-light-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import Tooltips from "../custommaterialtooltip/custommaterialtooltip";
import { lpKPIDescription } from '../../assets/constants/constants'
library.add(faPencilAlt, faPlus, faEye);
function ReviewKR(props) {
    const { reviewData } = props;
    const viewKrReviewPopup = (data, type) => {
        props.viewKrReviewPopup(data, type);
    }

    const setFinalRating = (item) => {
        if (item.finalRecommendationAccess === 'Y') {
            if (item.krFinalRating.Type === 'Add') {
                return (
                    <div title="Add final rating" className="" onClick={() => props.openKrRatingPopup(item, 'Final')}>
                        {
                            <span className="ratingIconColor">
                                <FontAwesomeIcon className="rating-icon-color" icon={faPlus} />
                            </span>
                        }
                    </div>)
            } else if (item.krFinalRating.Type === 'Edit') {
                return (
                    <div title="Edit final rating" className="" onClick={() => props.openKrRatingPopup(item, 'Final')}>
                        {
                            <span className="ratingIconColor">
                                <FontAwesomeIcon className="rating-icon-color" icon={faPencilAlt} />
                            </span>
                        }
                    </div>)
            } else {
                return (<div className="" onClick={() => viewKrReviewPopup(item, 'Final')}>
                    {
                        <span title="View final rating" className="ratingIconColor">
                            <FontAwesomeIcon className="rating-icon-color" icon={faEye} />
                        </span>
                    }
                </div>)

            }
        } else {
            return (<div>-</div>)
        }
    }    
    return (
        <div style={{ margin: 15 }}>
            <table className="table table-hover table-bordered">
                <thead>
                    <tr>
                        <th scope="col" colSpan={11}><div className=""></div></th>
                        <th className='kr-self-header' scope="col" colSpan="3">
                            <div className="">Interim Feedback <span style={{ cursor: 'pointer' }} className="rating-okr-onhover-text" onClick={() => props.openKrIntrimFeedbackPopup(reviewData[0].krData, reviewData[0].userObjectiveId, reviewData[0].objectiveName)} > ({`${reviewData[0].totalInterimFeedback}`}) </span></div>
                        </th>
                    </tr>
                    <tr>
                        <th className="kr-name-header" scope="col" colSpan=""><div>Leadership Principle</div></th>
                        <th className='kr-weightage-header' scope="col"><div>Description</div></th>
                        <th className='kr-weightage-header' scope="col" ><div>Weightage</div></th> 
                        <th className='kr-review-self-sup-sync-rating' scope="col" colSpan={2}><div>Self Rating</div></th>
                        <th className='kr-review-self-sup-sync-rating' scope="col" colSpan={2}><div>Supervisor Rating</div></th>
                        <th className='kr-review-self-sup-sync-rating' scope="col"><div>Self & Sup. Sync</div></th>
                        <th className='kr-self-rating-header' scope="col" colSpan={2}><div>Final Rating</div></th>
                        <th className='kr-review-self-sup-sync-rating' scope="col"><div >History</div></th>
                        <th className='kr-self-header' scope="col"><div >Self</div></th>
                        <th className='kr-supervisor-header' scope="col" colSpan=""><div>Supervisor</div></th>
                        <th className='kr-third-party-header' scope="col" colSpan=""><div style={{ cursor: 'pointer' }} title='Third Party'>TP</div></th>
                    </tr>
                </thead>
                <tbody style={{ overflowY: 'auto', fontSize: 14 }}>
                    {
                        typeof reviewData[0].krData !== 'undefined' &&
                        reviewData[0].krData.length > 0 &&
                        reviewData[0].krData.map((item) => (
                            <tr key={`reviewKR_${item.ratingId}`}>
                                <td className='kr-name'><div
                                    title="View leadership principles description" className='rating-kr-name-limit rating-grid-text rating-okr-onhover-text' 
                                    onClick={() => props.openLpRatingPopup(item, "sup")}>{item.krName}
                                    {/* <span style={{ padding: "0px 0px 2px 8px", position: 'relative' }}>
                                        <Tooltips titleMessage={lpKPIDescription} />
                                    </span> */}
                                </div></td>
                                <td className='kr-weightage '><div className='rating-kr-desc-limit rating-okr-onhover-text' title="View leadership principles description" onClick={() => props.openLpRatingPopup(item, "sup")}><span className='ratingIconColor' ><FontAwesomeIcon className="rating-icon-color" onClick={() => props.openLpRatingPopup(item, "sup")} icon={faEye} /></span></div></td>
                                <td className='kr-weightage rating-grid-text'><div style={{ cursor: 'pointer' }} onClick={() => props.openLpRatingPopup(item, "sup")}>{item.weightage}%</div></td>
                                <td className='kr-self-rating rating-grid-text'>
                                    <div>
                                        &nbsp;{item.krSelfRecommendationRating.Rating}&nbsp;
                                    </div>
                                </td>
                                <td className='kr-self-rating '>
                                    <div title="View self rating" className="" onClick={() => viewKrReviewPopup(item, 'Self')}>

                                        {
                                            <span className="ratingIconColor">
                                                <FontAwesomeIcon className="rating-icon-color" icon={faEye} />
                                            </span>
                                        }
                                    </div>
                                </td>
                                <td className='kr-self'>
                                    <div className='rating-grid-text' style={{ padding: '0px 0px 0px 0px', cursor: 'auto' }}>
                                        {item.krSupervisorRecommendationRating.Rating}
                                    </div>
                                </td>
                                <td className='kr-self-rating'>
                                    <div title="View supervisor rating" className="" onClick={() => viewKrReviewPopup(item, 'Supervisor')}>
                                        {
                                            <span className="ratingIconColor">
                                                <FontAwesomeIcon className="rating-icon-color" icon={faEye} />
                                            </span>
                                        }
                                        &nbsp;
                                    </div>
                                </td>
                                <td className='kr-supervisor'><div className='rating-okr-onhover-text'><span style={{ color: item.selfSupSync === "Y" ? "#18BC00" : "#B7B7BB",cursor:'auto' }}>{item.selfSupSync}</span></div></td>
                                <td className='kr-self'>
                                    <div className='rating-grid-text' style={{ padding: '0px 0px 0px 0px' }}>
                                        {item.krFinalRating.Rating === '' ? "-" : item.krFinalRating.Rating}
                                    </div>
                                </td>
                                <td className='kr-self-rating'>
                                    {setFinalRating(item)}
                                </td>
                                <td className='kr-self-rating'>
                                    <div title="View past rating" className="" >
                                        {
                                            item.pastRating === 'Y' &&
                                            <div className="" onClick={() => props.openPastRatingPopup(item, 'Final')}>
                                                {
                                                    <span className="ratingIconColor">
                                                        <FontAwesomeIcon className="rating-icon-color" icon={faEye} />
                                                    </span>
                                                }
                                            </div>
                                        }
                                        {
                                            item.pastRating === 'N' && <span>-</span>
                                        }
                                    </div>
                                </td>
                                <td className='kr-self' width='7%'>
                                    <div className='rating-okr-onhover-text' >
                                        {
                                            item.interimSelf === '+' || item.interimSelf === 0 ?
                                                <span>
                                                    -
                                                </span>
                                                :
                                                <span title="View self interim feedback" className="rating-grid-text" onClick={() => props.openViewIntrimFeedbackPopup(item, reviewData[0].userObjectiveId, 'self', null)} >
                                                    {item.interimSelf}
                                                </span>
                                        }
                                    </div>
                                </td>
                                <td className='kr-self' width='7%'>
                                    <div className='rating-okr-onhover-text' >
                                        {
                                            item.interimSupervisor === '+' || item.interimSupervisor === 0 ?
                                                <span>
                                                    -
                                                </span>
                                                :
                                                <span title="View supervisor interim feedback" className='rating-grid-text' onClick={() => props.openViewIntrimFeedbackPopup(item, reviewData[0].userObjectiveId, 'sup', null)}>
                                                    {item.interimSupervisor}
                                                </span>
                                        }
                                    </div>
                                </td>
                                <td className='kr-self' width='7%'>

                                    <div className='rating-okr-onhover-text'>
                                        {
                                            item.interimThirdParty === '+' || item.interimThirdParty === 0 ?
                                                <span>
                                                    -
                                                </span>
                                                :
                                                <span title="View third party interim feedback" className='rating-grid-text' onClick={() => props.openViewIntrimFeedbackPopup(item, reviewData[0].userObjectiveId, 'tp', null)}>
                                                    {item.interimThirdParty}
                                                </span>
                                        }
                                    </div>
                                </td>
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
    )
}

ReviewKR.propTypes = {

}

export default ReviewKR

