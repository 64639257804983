import React, { Component } from 'react'
import { connect } from "react-redux";
import PropTypes, { object } from "prop-types";
import { setCentralOkrKPIRedux, addUpdateObjectveKpi, setUserOKRObjectiveKPI, submitApprovalAction } from '../../redux/actions/okr/okractions';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/pro-light-svg-icons";
import { FormValidator } from "@syncfusion/ej2-inputs";
import { Button, Dialog } from "@material-ui/core";
import { DialogContent, DialogActions } from "../customdialog/customdialog";
import { ComboBoxComponent } from "@syncfusion/ej2-react-dropdowns";
import OKRImportObj from './okrimportobj';
import OKRServices from "../../services/okr/okrservices";
import { getNotify, getFinancialYearDate } from '../../app/other/commonfunction';
import { ERROR, pleaseSelectObj, pleaseSelectImpoFrom, pleaseTypeSearchText, pleaseSelectFyId /** variablePercentageValidation, TotalBusinessUserTotalariableImpact*/ } from '../../assets/constants/constants';//nosonar
import ProgressBar from '../progressbar/progressbar';
import ConfirmationPopup from "../../utilities/confirmationpopup";
import { addBusinessOkrOkrKPILimit } from '../../config/config'
//nosonar import { closeFilterDialog } from '@syncfusion/ej2-grids';
class ImportBusinessOKR extends Component {
  constructor(props) {
    super(props)
    this.state = {
      masterObjKpiList: [],
      checkedObjectiveToImport: [],
      checkedObjectiveToSubmit: [],
      importedObjKpi: [],
      isLoading: true,
      visibilityData: [],
      seletctedVisibility: 715,
      searchText: '',
      isLoadingImportPopup: false,
      windowAdjust: window.innerHeight - 290,
      okrBusinessPopup: false,
      tempMaterObjKpiList: [],
      selectedEmp: '',
      messageOpen: false,
      OKRType: 1, // Active,
      fyId: 0
    };
    this.refreshTabs = false;
    this.headerText = [{ text: "Import" }, { text: "Active" }];
    this.headerMessage = "";
    this.subMessage= "";
    this.setCheckedOpjective = this.setCheckedOpjective.bind(this);
    this.saveAsDraftAllKPI = this.saveAsDraftAllKPI.bind(this);
  }

  componentDidMount = async () => {
    const { currentFinancialYearId } = this.props;
    await this.updateDimensions();/**NOSONAR */
    this.setState({ fyId: currentFinancialYearId })
    await this.getAllObjKpiImportBusinessOkr("refresh");/**NOSONAR */
    this.props.setChildComponentMethod(this.createObjectiveFun);
  };
  updateDimensions() {
    const updateheight = window.innerHeight - 405;
    const windowAdjust = window.innerHeight - 290;
    this.setState({
      heightupdateheight: updateheight,
      windowAdjust: windowAdjust,
    });
  }
  // Function for get Objective All business OKR Data Into Import Dialog
  getAllObjKpiImportBusinessOkr = (type) => {
    const { authUser, OkrMasters } = this.props;
    try {
      const mArrObjId = [];
      mArrObjId.push(0);
      const defaaultData = Promise.resolve(
        OKRServices.getKPIList(authUser, 1, 715, mArrObjId.toString(), "", "", 0)
      );
      defaaultData.then(async (resultData) => {
        if (resultData !== null) {
          await this.setState({ masterObjKpiList: resultData.Result, isLoading: true, tempMaterObjKpiList: resultData.Result });
          await this.getUserMapObjKpiData('refreshTabs');/**NOSONAR */
          if (type === 'refresh') {
            this.props.setCentralOkrKPIRedux({
              updateDefaultSelectionTabFlag: 'updated'
            })
          }
        } else {
          await this.setState({ masterObjKpiList: [], isLoading: true });
          await this.getUserMapObjKpiData('refreshTabs');/**NOSONAR */
          if (type === 'refresh') {
            this.setCentralOkrKPIRedux({
              updateDefaultSelectionTabFlag: 'updated'
            })
          }
          this.data = "error";
        }
        const visibility = await OkrMasters.filter((item) => {
          return item.var_list_value === "Import Objectives";
        });

        this.setState({ visibilityData: visibility })
      });
    } catch (e) {
      // eslint-disable-next-line
      console.log(e);
    }
  };

  defaultImportData = () => {
    const { seletctedVisibility, OKRType } = this.state;
    const { draftedBusinessObjKpi, allActiveBusinessObjective } = this.props;
    const { authUser } = this.props;
    try {
      let mArrObjId = [];
      if (draftedBusinessObjKpi.length > 0 || allActiveBusinessObjective.length > 0) {
        draftedBusinessObjKpi.length > 0 &&
          // eslint-disable-next-line
          draftedBusinessObjKpi.map((item) => {
            mArrObjId.push(item.objectiveId);
          });
        allActiveBusinessObjective.length > 0 &&
          // eslint-disable-next-line
          allActiveBusinessObjective.map((item) => {
            mArrObjId.push(item.objectiveId);
          });
      } else {
        mArrObjId.push(0);
      }
      const getMasterData = Promise.resolve(
        OKRServices.getKPIList(
          authUser,
          1,
          seletctedVisibility,
          mArrObjId.toString(),
          "",
          seletctedVisibility === 717 ? OKRType : "",
          0
        )
      );
      getMasterData.then((resultData) => {
        if (resultData !== null) {
          mArrObjId = [];
          this.setState({ masterObjKpiList: resultData.Result, isLoadingImportPopup: false });
        } else {
          this.setState({ masterObjKpiList: [], isLoadingImportPopup: false });
          this.data = "error";
        }
      });

    } catch (e) {
      // eslint-disable-next-line
      console.log(e);
    }
  };
  searchData = () => { // NOSONAR
    const { seletctedVisibility, searchText, selectedEmp, OKRType, fyId } = this.state;
    const { draftedBusinessObjKpi, allActiveBusinessObjective } = this.props;
    const { authUser } = this.props;
    let options;
    if (seletctedVisibility === 717) { // NOSONAR
      options = {
        rules: {
          importFromErr: {
            required: [true, pleaseSelectImpoFrom],
          },
          financialYearErr: {
            required: [true, pleaseSelectFyId],
          },
        },
      };
      document.getElementById("employeeNameErr").innerHTML = "";
      if (selectedEmp === "" || selectedEmp === null) {
        document.getElementById("employeeNameErr").innerHTML = "Please select employee name";
      }
    } else {
      document.getElementById("searchListErr").innerHTML = "";
      options = {
        rules: {
          importFromErr: {
            required: [true, pleaseSelectImpoFrom],
          },
          // searchListErr: {
          //   required: [true, pleaseTypeSearchText],
          // },
        },
      };
      if (searchText === "" || searchText === null) {
        document.getElementById("searchListErr").innerHTML = pleaseTypeSearchText;
        return;
      }
      let hashCondi = searchText.includes('#');
      if (hashCondi) {
        document.getElementById("searchListErr").innerHTML = "Please remove the # from search text";
        return;
      }
    }
    const addSearchObject = new FormValidator("#businessOKRSearchForm", options);
    if (addSearchObject.validate()) {
      if (seletctedVisibility === 717) { // NOSONAR
        if (selectedEmp === "" || selectedEmp === null) {
          document.getElementById("employeeNameErr").innerHTML = "Please select employee name";
          return;
        }
      }
      try {
        this.setState({ isLoadingImportPopup: true })
        let mArrObjId = [];
        if (draftedBusinessObjKpi.length > 0 || allActiveBusinessObjective.length > 0) {
          draftedBusinessObjKpi.length > 0 &&
            // eslint-disable-next-line
            draftedBusinessObjKpi.map((item) => {
              mArrObjId.push(item.objectiveId);
            });
          allActiveBusinessObjective.length > 0 &&
            // eslint-disable-next-line
            allActiveBusinessObjective.map((item) => {
              mArrObjId.push(item.objectiveId);
            });
        } else {
          mArrObjId.push(0);
        }
        let getMasterData;
        if (seletctedVisibility === 717) { // NoSONAR
          getMasterData = Promise.resolve(
            OKRServices.getKPIList(
              authUser,
              1,
              seletctedVisibility,
              0,
              // mArrObjId.toString(),
              selectedEmp.toString(),
              OKRType,
              fyId
            )
          );
        } else {
          getMasterData = Promise.resolve(
            OKRServices.getKPIList(
              authUser,
              1,
              seletctedVisibility,
              mArrObjId.toString(),
              searchText.toString(),
              "",
              0
            )
          );
        }
        getMasterData.then((resultData) => {/**NOSONAR */
          if (resultData !== null) {
            mArrObjId = [];
            this.setState({ masterObjKpiList: resultData.Result, isLoadingImportPopup: false });
          } else {
            this.setState({ masterObjKpiList: [], isLoadingImportPopup: false });
            this.data = "error";
          }
        });

      } catch (e) {
        // eslint-disable-next-line
        console.log(e);
      }
    }
  };
  getUserMapObjKpiData = (type) => {/**NOSONAR */
    const { authUser, employeeId, monthOfReview, selectedFy, fyid } = this.props;
    const { masterObjKpiList } = this.state;
    try {
      this.setState({ isLoading: true })
      const getUserMapData = Promise.resolve(
        OKRServices.getUserMapObjectiveKpiList(authUser, employeeId, "1", fyid)
      );
      getUserMapData.then((resultData) => {
        if (resultData !== null) {
          let temp1 = masterObjKpiList;
          let temp2 = resultData.Result;
          if (type === "refreshTabs") {
            this.refreshTabs = true;
          } else {
            this.refreshTabs = false;
          }
          // eslint-disable-next-line 
          temp2.map((child) => {
            if (temp1.length > 0) {
              temp1 = temp1.filter(function (obj) {
                return obj.objectiveId !== child.objectiveId;
              });
            }
          })

          this.setState({ masterObjKpiList: temp1 });
          let mAllBusinessObj = resultData.Result;
          if (mAllBusinessObj.length > 0) {
            resultData.Result.forEach((item) => {
              mAllBusinessObj.forEach((data) => {
                if (data.objectiveId === item.objectiveId && typeof data.userObjectiveId === 'undefined') {
                  // eslint-disable-next-line
                  data.userObjectiveId = item.userObjectiveId;
                  // eslint-disable-next-line
                  data.kpis = item.kpis;
                }
              });
            });
          } else {
            mAllBusinessObj = resultData.Result;
          }
          let submittedBusinessObjective = [];
          let newDraftedBusinessObjKpi = [];
          if (mAllBusinessObj.length > 0) {
            // eslint-disable-next-line
            mAllBusinessObj.map((item) => {
              if (item.isDraft === '1') {
                newDraftedBusinessObjKpi.push(item);
              } else {
                submittedBusinessObjective.push(item);
              }
            })
          }
          if (monthOfReview === 0) {
            this.props.setCentralOkrKPIRedux({
              draftedBusinessObjKpi: newDraftedBusinessObjKpi, submittedBusinessObjective: submittedBusinessObjective, updateBuActiveFlag: 'updated', allActiveBusinessObjective: submittedBusinessObjective,
              moveToActive: newDraftedBusinessObjKpi.length && this.refreshTabs > 0 /**nosonar */ ? '' : submittedBusinessObjective.length > 0 && this.refreshTabs ? 'updated' : '',
              // activeTabFlag: newDraftedBusinessObjKpi.length && this.refreshTabs > 0 ?/**nosonar */ false :  submittedBusinessObjective.length === 0 && this.refreshTabs ?false : true
            });
          } else {
            let year = selectedFy;
            let submitedActiveObj = [];
            if (submittedBusinessObjective.length > 0) {
              // eslint-disable-next-line
              submittedBusinessObjective.forEach((item) => {

                if (item.endMonth !== 0) {
                  if (getFinancialYearDate(monthOfReview, year) >= getFinancialYearDate(item.startMonth, year) && getFinancialYearDate(monthOfReview, year) <= getFinancialYearDate(item.endMonth, year)) {
                    submitedActiveObj.push(item);
                  }
                }
                else {
                  if (getFinancialYearDate(monthOfReview, year) >= getFinancialYearDate(item.startMonth, year)) {
                    submitedActiveObj.push(item)
                  }
                }
              })
              this.props.setCentralOkrKPIRedux({
                draftedBusinessObjKpi: newDraftedBusinessObjKpi, submittedBusinessObjective: submitedActiveObj, updateBuActiveFlag: 'updated', allActiveBusinessObjective: submittedBusinessObjective,
                moveToActive: newDraftedBusinessObjKpi.length && this.refreshTabs > 0 /**nosonar */ ? '' : submittedBusinessObjective.length === 0 && this.refreshTabs ? '' : 'updated',
                //  activeTabFlag: newDraftedBusinessObjKpi.length > 0  && this.refreshTabs?/**nosonar */ false :  submittedBusinessObjective.length === 0  && this.refreshTabs ?false : true
              });
            }
          }
          // eslint-disable-next-line
          setTimeout(() => {
            this.setState({ checkedObjectiveToImport: [], isLoading: false });
          }, 500);
        } else {
          this.props.setCentralOkrKPIRedux({ draftedBusinessObjKpi: [], submittedBusinessObjective: [], updateBuActiveFlag: 'updated', });
          this.setState({ masterObjKpiList: [], isLoading: false });
          this.data = "error";
        }
      });
    } catch (e) {
      // eslint-disable-next-line
      console.log(e);
    }
  };

  importData = () => {
    return (
      <div> Import 1 </div>
    )
  }
  activeData = () => {
    return (
      <div> Active 2 </div>
    )
  }
  createObjectiveFun = () => {
    try {
      this.setState({ okrBusinessPopup: true, seletctedVisibility: 715, isLoadingImportPopup: true }, () => {
        setTimeout(() => {
          this.defaultImportData();
        }, 1000)
      })
    } catch (e) {
      console.log("errr", e);
    }
  }
  closeObjectivePopup = () => {
    this.setState({
      okrBusinessPopup: false,
      seletctedVisibility: "",
      searchText: ''
    })
  }
  setCheckedOpjective = (data, type) => {
    if (type === "draftBusinessOkr") {
      this.setState({ checkedObjectiveToSubmit: data });
    } else {
      this.setState({ checkedObjectiveToImport: data });
    }
  }

  saveAsDraftAllKPI = () => {/**NOSONAR */
    const { employeeId, authUser, fyid, businessUserVariableImpact } = this.props;
    const { checkedObjectiveToImport } = this.state
    let checkedObjectiveToImportData = checkedObjectiveToImport;
    let kpiLibIds = "";
    let kpiIds = "";
    let kpiTitle = "";
    let kpiDescription = "";
    let kpiAttachment = "";
    let kpiWeightage = "";
    let kpiVariableLinked = "";
    let kpiBenchmark = "";
    let kpiScopeType = "";
    let kpiScopeUsers = "";
    let kpiUserId = "";
    try {
      // prepire data and call to API
      for (let index = 0; index < checkedObjectiveToImportData.length; index += 1) {/**NOSONAR */
        checkedObjectiveToImportData[index].empId = employeeId;
        const data = {};
        data.userId = authUser;
        data.empId = employeeId;
        data.okrType = checkedObjectiveToImportData[index].objType;
        data.objectiveLibId = checkedObjectiveToImportData[index].objectiveId;
        data.objectiveId = 0
        data.objectiveTitle = checkedObjectiveToImportData[index].objectiveName;
        data.objectiveType = checkedObjectiveToImportData[index].objType;
        data.effectiveFromMonth = checkedObjectiveToImportData[index].effectiveMonth === "" ? null : checkedObjectiveToImportData[index].effectiveMonth;
        data.financialYearId = fyid; // Need to set Financial year Id dynamic
        data.effectiveFrequency = checkedObjectiveToImportData[index].effectiveFrequency === "" ? null : checkedObjectiveToImportData[index].effectiveFrequency;
        if (businessUserVariableImpact > 0) {
          data.variablePercentage = checkedObjectiveToImportData[index].variable === null || typeof checkedObjectiveToImportData[index].variable === 'undefined' || checkedObjectiveToImportData[index].variable === '' ? '0' : checkedObjectiveToImportData[index].variable;
        } else {
          data.variablePercentage = "0";
        }

        if (checkedObjectiveToImportData[index].kpis.length > 0) {
          for (let j = 0; j < checkedObjectiveToImportData[index].kpis.length; j += 1) {
            const attachmentKpi = checkedObjectiveToImportData[index].kpis[j].kpiAttachment
              ? checkedObjectiveToImportData[index].kpis[j].kpiAttachment
              : "";
            const kpiUid =
              typeof checkedObjectiveToImportData[index].kpis[j].userKpiId === "undefined"
                ? 0
                : checkedObjectiveToImportData[index].kpis[j].userKpiId;
            let scopeType = "";
            if (checkedObjectiveToImportData[index].kpis[j].scopeTypeId === 725)
              scopeType = "1";
            else if (checkedObjectiveToImportData[index].kpis[j].scopeTypeId === 726)
              scopeType = "2";
            else if (checkedObjectiveToImportData[index].kpis[j].scopeTypeId === 727)
              scopeType = "3";
            else if (checkedObjectiveToImportData[index].kpis[j].scopeTypeId === 728)
              scopeType = "4";
            let userList = "";
            if (scopeType === "3" || scopeType === "4" || scopeType === "2") {
              if (checkedObjectiveToImportData[index].kpis[j].userList.length > 0) {
                userList = checkedObjectiveToImportData[index].kpis[j].userList.toString();
              }
            }
            if (j === 0) {
              kpiLibIds = checkedObjectiveToImportData[index].kpis[j].kpiId === null ? "" : checkedObjectiveToImportData[index].kpis[j].kpiId.toString();
              kpiIds = kpiUid === null ? "" : kpiUid.toString();
              kpiTitle = checkedObjectiveToImportData[index].kpis[j].kpiName;
              kpiDescription = checkedObjectiveToImportData[index].kpis[j].description;
              kpiAttachment = attachmentKpi;
              kpiWeightage = checkedObjectiveToImportData[index].kpis[j].weightage === null ? "" : checkedObjectiveToImportData[index].kpis[j].weightage.toString();
              kpiUserId = checkedObjectiveToImportData[index].kpis[j].userKpiId === null || checkedObjectiveToImportData[index].kpis[j].userKpiId === undefined ? "" : checkedObjectiveToImportData[index].kpis[j].userKpiId.toString();
              if (businessUserVariableImpact > 0) {
                kpiVariableLinked = checkedObjectiveToImportData[index].kpis[
                  j
                ].varibleLink === null ? '0' : checkedObjectiveToImportData[index].kpis[
                  j
                ].varibleLink.toString();
              } else {
                kpiVariableLinked = "0";
              }
              kpiBenchmark = checkedObjectiveToImportData[index].kpis[j].benchmark === undefined || checkedObjectiveToImportData[index].kpis[j].benchmark === null ? "" : checkedObjectiveToImportData[index].kpis[j].benchmark.toString();
              kpiScopeType = scopeType;
              kpiScopeUsers = userList;

            } else {
              kpiLibIds = `${kpiLibIds}$$${checkedObjectiveToImportData[index].kpis[j].kpiId}`;
              kpiIds = `${kpiIds}$$${kpiUid}`;
              kpiTitle = `${kpiTitle}$$${checkedObjectiveToImportData[index].kpis[j].kpiName}`;
              kpiDescription = `${kpiDescription}$$${checkedObjectiveToImportData[index].kpis[j].description}`;
              kpiAttachment = `${kpiAttachment}$$${attachmentKpi}`;
              kpiWeightage = `${kpiWeightage}$$${checkedObjectiveToImportData[index].kpis[j].weightage === null ? '0' : checkedObjectiveToImportData[index].kpis[j].weightage}`;
              kpiUserId = `${kpiUserId}$$${checkedObjectiveToImportData[index].kpis[j].userKpiId === null || checkedObjectiveToImportData[index].kpis[j].userKpiId === undefined ? '0' : checkedObjectiveToImportData[index].kpis[j].userKpiId}`;
              if (businessUserVariableImpact > 0) {
                kpiVariableLinked = `${kpiVariableLinked}$$${checkedObjectiveToImportData[index].kpis[j].varibleLink}`;
              } else {
                kpiVariableLinked += "$$0";
              }
              kpiBenchmark = checkedObjectiveToImportData[index].kpis[j].benchmark === undefined ? `$$""` : `${kpiBenchmark}$$${checkedObjectiveToImportData[index].kpis[j].benchmark}`;
              kpiScopeType = `${kpiScopeType}$$${scopeType}`;
              kpiScopeUsers = `${kpiScopeUsers}$$${userList}`;
            }
          }
        }
        data.kpiLibIds = kpiLibIds;
        data.kpiIds = kpiIds;
        data.kpiTitle = kpiTitle;
        data.kpiDescription = kpiDescription;
        data.kpiAttachment = kpiAttachment;
        data.kpiWeightage = kpiWeightage.toString() === '' ? '0' : kpiWeightage.toString();
        data.kpiVariableLinked = kpiVariableLinked;
        data.kpiBenchmark = kpiBenchmark;
        data.kpiScopeType = kpiScopeType;
        data.kpiScopeUsers = kpiScopeUsers;
        data.kpiUserId = kpiUserId;
        data.isDraft = "Y";
        // eslint-disable-next-line
        this.props.setUserOKRObjectiveKPI(data);
      }
    } catch (error) {
      // eslint-disable-next-line
      console.log(error);
    }
  };

  importObjective = async () => {
    const { checkedObjectiveToImport, masterObjKpiList } = this.state;
    if (checkedObjectiveToImport.length === 0) {
      getNotify(ERROR, pleaseSelectObj)
      return;/**NOSONAR */
    } else {
      let temp1 = masterObjKpiList;
      let temp2 = checkedObjectiveToImport;

      // eslint-disable-next-line
      temp2.forEach((child) => {
        temp1 = temp1.filter(function (obj) {
          return obj.objectiveId !== child.objectiveId;
        });
      })

      this.setState({
        masterObjKpiList: temp1
      })
      await this.saveAsDraftAllKPI();/**NOSONAR */
      this.closeObjectivePopup();
    }
  }
  //WARNING! To be deprecated in React v17. Use new lifecycle static getDerivedStateFromProps instead.
  // eslint-disable-next-line
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.updateBuDraftFlag === 'updated') {
      this.props.addUpdateObjectveKpi({ updateBuDraftFlag: '', fillKpi: {}, fillObjective: {} })
      this.setState({ isLoading: true })
      this.getUserMapObjKpiData('noRefreshTabs');
    }
    if (nextProps.reRenderComponent === "updated") {
      this.getUserMapObjKpiData('noRefreshTabs');
      this.props.addUpdateObjectveKpi({ reRenderComponent: "" })
    }
    if (nextProps.resetCheckedObjData === "updated") {
      this.setState({ checkedObjectiveToSubmit: [] })
      this.props.addUpdateObjectveKpi({ resetCheckedObjData: "" })
    }
  }
  submitDraftObjectives = async () => {/**NOSONAR */
    const { authUser, allActiveBusinessObjective, businessUserVariableImpact, employeeId, fyid } = this.props;

    let userObjectiveids = [];
    // eslint-disable-next-line
    let activeVariableTotal = 0;
    // eslint-disable-next-line
    let draftVariableTotal = 0;
    // eslint-disable-next-line
    let totalVariableWeightage = 0;//nosonar
    this.state.checkedObjectiveToSubmit.length > 0 &&
      // eslint-disable-next-line
      this.state.checkedObjectiveToSubmit.forEach((item) => {
        userObjectiveids.push(item.userObjectiveId);
        if (businessUserVariableImpact > 0) {
          draftVariableTotal += item.variable;
        }
      })
    const datesObj = {
      fyId: fyid,
      startMonth: null,
      endMonth: null
    }
    // Check whether the objectives are 5 against each month;
    const effectiveFrom = Promise.resolve(
      OKRServices.getOKRFrequency("Validate", authUser, employeeId, "1", userObjectiveids.toString(), datesObj)
    );
    const ValidateObj = await effectiveFrom.then((resultData) => {
      return resultData.Result[0];
    })
    try {
      if (ValidateObj.result === 'Success') {
        const submitApprovalData = {
          type: "submitOkrToUser",
          userId: authUser,
          objId: userObjectiveids.toString()
        }
        allActiveBusinessObjective.length > 0 &&
          // eslint-disable-next-line
          allActiveBusinessObjective.map((item) => {
            if (businessUserVariableImpact > 0 && item.disableEnableFlag !== '1') {
              activeVariableTotal += item.variable
            }
          });

        if (businessUserVariableImpact > 0) {
          if (this.state.checkedObjectiveToSubmit.length > 0) {
            this.props.submitApprovalAction(submitApprovalData);
            this.setState({ checkedObjectiveToSubmit: [] })
          } else {
            if (this.state.checkedObjectiveToSubmit.length === 0) {
              getNotify(ERROR, pleaseSelectObj)
              return;
            }
          }
        } else {
          if (this.state.checkedObjectiveToSubmit.length > 0) {
            this.props.submitApprovalAction(submitApprovalData);
            this.setState({ checkedObjectiveToSubmit: [] })
          } else {
            getNotify(ERROR, pleaseSelectObj)
            return;
          }
        }

      } else {
        this.headerMessage = "You can have maximum " + addBusinessOkrOkrKPILimit.toString() + " active Objectives on selected period of time. "
          + (ValidateObj.activeCnt > 0 ? "You already have " + ValidateObj.activeCnt + " active objective(s) in active tab. " : "")
          + "Please check the below objective(s) before pushing them into active tab.";
        this.subMessage = ValidateObj.objName.split(
          '|'
        ).map(item => {
          return <div key={item}>{item} <br/></div>
        })
        
        this.setState({ messageOpen: true })
      }
    } catch (e) {
      if (userObjectiveids.length === 0) {
        getNotify(ERROR, "Please select atleast one Objective.");
      }

    }
  }
  getImportFrom = (event, type) => {

    if (event.itemData !== null) {
      if (type === "profile") {
        this.setState({
          selectedEmp: event.itemData.Id,
        })
        this.searchData();
      }
      else if (event.itemData.var_optional1 === "Profile") {
        this.setState({
          seletctedVisibility: event.itemData.Id,
          masterObjKpiList: []
        })
      }
      else {
        this.setState({
          seletctedVisibility: event.itemData.Id
        })
        this.getAllObjKpiImportBusinessOkr("");
      }
    }
  }
  getClosedOrActiveOKR = (event) => {
    this.setState({ OKRType: event.itemData.Id })
    this.searchData();
  }
  getFyId = (event) => {
    this.setState({ fyId: event.itemData.Id })
    this.searchData();
  }

  setSearchText = (event) => {
    this.setState({
      searchText: event.target.value
    })
    if (event.target.value === '') {
      this.setState({ masterObjKpiList: [] }, () => { this.defaultImportData() });
    }
  }

  handleConfirmClose = () => {
    this.setState({
      messageOpen: false,
    })

  }

  render() { //NOSONAR
    const { okrBusinessPopup, masterObjKpiList, heightupdateheight, isLoading, visibilityData, isLoadingImportPopup, seletctedVisibility, fyId } = this.state;
    const { draftedBusinessObjKpi, setHeightFlag, currWindowAdjust, enableAction, finacialYearData, OkrMasters } = this.props;

    let smallResolution = (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight) > 767 ? 0 : + 65;
    return (
      <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
        {
          draftedBusinessObjKpi !== undefined && draftedBusinessObjKpi.length > 0 && enableAction ?
            <div>

              {/* <DialogContent style={{ padding: "12px 0px 4px 0px" }}>
                <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                  {enableAction &&
                    <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                      <Button
                        style={{
                          background: "#FFF",
                          color: "#fff",
                          width: 120,
                          height: 32,
                          float: "right",
                        }}
                        className="e-event-details ml-sm remove-button-shadow "
                        variant="contained"
                        size="small"
                        color="primary"
                        onClick={() => this.createObjectiveFun()}
                      >
                        Import
                      </Button>
                    </div>
                  }
                </div>
              </DialogContent> */}
              {/* Draft Okr  */}
              <div>
                <div
                  style={{
                    height: setHeightFlag === true ? currWindowAdjust + smallResolution : heightupdateheight,
                    padding: '10px 0px 0px 28px',
                    overflowY: "auto",
                    overflowX: "hidden",
                    position: "relative",
                    marginTop: '8px',
                  }}
                >
                  <OKRImportObj masterObjKpiList={draftedBusinessObjKpi} showHideComponent="draftBusinessOkr" setCheckedOpjective={this.setCheckedOpjective} enableAction={enableAction} />
                </div>
              </div>
              <div style={{ padding: "10px 0px 0px 0px" }}>
                <div className="col-xs-12 col-sm-8 col-lg-10" style={{ padding: "8px 28px 0px" }}>
                  <span className="footer-status" style={{ background: "#FDD141" }}></span>
                  <span style={{ padding: "0px 24px 0px 8px" }}>Pending </span>
                  <span className="footer-status" style={{ background: "#48B8E7" }}></span>
                  <span style={{ padding: "0px 24px 0px 8px" }}>Completed</span>
                </div>
                {enableAction &&
                  <div className="col-xs-12 col-sm-4 col-lg-2" style={{ padding: "0px 0px 0px 0px" }}>
                    <Button
                      style={{ float: "right", width: "120px", height: 32 }}
                      className="remove-button-shadow"
                      color="primary"
                      variant="contained"
                      onClick={() => this.submitDraftObjectives()}
                    >
                      Submit
                    </Button>
                  </div>
                }
              </div>
            </div>
            :

            !isLoading ? //NOSONAR
              <div
                className="col-lg-12 col-md-2"
                style={{ paddingTop: "5%" }}
              >
                <div className="okr-no-item pb-lg">Welcome to the Business OKR section</div>

                <div className="okr-no-item-text">
                  {/* eslint-disable-next-line */}
                  Currently there are no OKRs in this section. Please add OKRs by importing it from the library.
                </div>
                {/* <div className="okr-no-item-text">
                from the library.
              </div>*/}
                {enableAction &&
                  <div style={{ textAlign: "center", marginTop: 30 }}>
                    <Button
                      style={{
                        background: "#FFF",
                        color: "#fff",
                        marginRight: 5,
                        width: 260,
                        height: 40,
                      }}
                      className="e-event-details ml-sm remove-button-shadow "
                      variant="contained"
                      size="small"
                      color="primary"
                      onClick={() => this.createObjectiveFun()}
                    >
                      Your OKR Library
                    </Button>
                  </div>
                }
              </div>
              :
              <div style={{ marginTop: '30%' }}>
                <ProgressBar />
              </div>
        }

        {/* Import Objective Dialog */}
        <div>
          <Dialog
            open={okrBusinessPopup}
            fullWidth
            width="1000"
            maxWidth="lg"
          >
            <DialogContent style={{ paddingBottom: 1, minHeight: "135px" }}>
              <div style={{ padding: "0px 4px 0px 4px", height: 0 }}>
                <div style={{ fontWeight: 700, fontSize: 16, paddingBottom: 18 }}>
                  Import Objectives
                </div>
                <form id="businessOKRSearchForm" method="post">
                  {
                    // <NotificationContainer />
                  }
                  <div
                    className="row card-layout"
                    style={{ margin: "10px -20px 0px -14px" }}
                  >
                    <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2">
                      <div>
                        <ComboBoxComponent
                          id="importFrom"
                          fields={{ text: "Name", value: "Id" }}
                          data-msg-containerid="importFromErr"
                          name="importFromErr"
                          allowFiltering
                          allowCustom={false}
                          floatLabelType="Auto"
                          cssClass="e-outline"
                          placeholder="Import From *"
                          select={(e) => this.getImportFrom(e, "library")}
                          dataSource={visibilityData}
                          showClearButton={false}
                          value={seletctedVisibility}
                        />
                        <div
                          style={{ fontSize: 12 }}
                          id="importFromErr"
                          className="e-input-group e-float-icon-left error-div"
                        />
                      </div>
                    </div>
                    {/* {importFromId !== 717 && ( */}
                    {
                      (seletctedVisibility === 717) ? // NOSONAR
                        (<> <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4">
                          <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                            <ComboBoxComponent
                              id="Level"
                              name="employeeNameErr"
                              data-msg-containerid="employeeNameErr"
                              dataSource={OkrMasters.filter((item) => {
                                return item.var_list_name === "AllUser";
                              })}
                              fields={{ value: "Id", text: "var_list_value", groupBy: 'var_optional1' }}
                              select={(e) => this.getImportFrom(e, "profile")}
                              maximumSelectionLength={10}
                              mode="CheckBox"
                              cssClass="e-outline"
                              showDropDownIcon
                              placeholder="Employee Name *"
                              allowFiltering
                            />
                            <div
                              id="employeeNameErr"
                              className="e-input-group e-float-icon-left error-div"
                            />
                          </div>


                        </div>
                          <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2">
                            <div>
                              <ComboBoxComponent
                                id="OKRType"
                                fields={{ text: "Name", value: "Id" }}
                                data-msg-containerid="OKRTypeErr"
                                name="OKRTypeErr"
                                allowFiltering
                                allowCustom={false}
                                floatLabelType="Auto"
                                cssClass="e-outline"
                                placeholder="OKR *"
                                select={(e) => this.getClosedOrActiveOKR(e, "activeOrClosed")}
                                dataSource={[{ Name: 'Active OKR', Id: 1 }, { Name: 'Closed OKR', Id: 2 }]}
                                showClearButton={false}
                                value={1}
                              />
                              <div
                                style={{ fontSize: 12 }}
                                id="OKRTypeErr"
                                className="e-input-group e-float-icon-left error-div"
                              />
                            </div>
                          </div>
                          <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2">
                            <div>
                              <ComboBoxComponent
                                id="financialYear"
                                fields={{ text: "Name", value: "Id" }}
                                data-msg-containerid="financialYearErr"
                                name="financialYearErr"
                                allowFiltering
                                allowCustom={false}
                                floatLabelType="Auto"
                                cssClass="e-outline"
                                placeholder="Financial Year *"
                                select={(e) => this.getFyId(e, "fyId")}
                                dataSource={finacialYearData}
                                showClearButton={false}
                                value={fyId}
                              />
                              <div
                                style={{ fontSize: 12 }}
                                id="financialYearErr"
                                className="e-input-group e-float-icon-left error-div"
                              />
                            </div>
                          </div>
                        </>) :
                        <div
                          className={"col-xs-6 col-sm-6 col-lg-6 col-md-6"}>
                          <div className="e-input-group e-float-icon-left">
                            <div className="tags-input" style={{ marginLeft: 0 }}>
                              <input
                                style={{ height: "34px", padding: "0px" }}
                                type="text"
                                onKeyDown={event => event.key === "Enter" ? this.searchData() : null}
                                placeholder="Search Objective, Key Result or Tags *"
                                onChange={(event) => this.setSearchText(event)}
                              />
                              <span style={{ position: "absolute", right: "0" }}>
                                <Button
                                  id="myTooltip"
                                  title="Search"
                                  style={{ padding: 0 }}
                                  onClick={() => this.searchData()}
                                  onKeyDown={event => event.key === "Enter" ? this.searchData() : null}
                                >
                                  <FontAwesomeIcon
                                    icon={faSearch}
                                    style={{ fontSize: 18, color: "#F94F5E", margin: "12px 18px 10px 18px" }}
                                  />
                                </Button>
                              </span>
                            </div>
                          </div>
                          <div
                            id="searchListErr"
                            className="e-input-group e-float-icon-left error-div"
                          />
                        </div>
                    }

                  </div>
                </form>
              </div>
            </DialogContent>
            <DialogContent style={{ paddingTop: 0 }}>
              <div
                style={{
                  height: `${this.state.masterObjKpiList.length > 0 ? "650px" : "auto"}`,
                  minHeight: "650px",
                  margin: "-16px 0px 0px 32px",
                }}
              >
                {
                  !isLoadingImportPopup ?
                    <>
                      {/* Import Dialog View Objective */}
                      {
                        masterObjKpiList.length > 0 ?//NOSONAR
                          <OKRImportObj masterObjKpiList={masterObjKpiList} showHideComponent="import" setCheckedOpjective={this.setCheckedOpjective} />
                          :
                          <div style={{ paddingTop: '5%' }}>
                            <div className='new-productivity-allocation-no-data-header'>No matching search results found. Please try another entry to search.</div>
                            {/* <div className='new-productivity-allocation-no-data-sub-header pt-lg'>You don't have any items here. try with other</div>
                            <div className='new-productivity-allocation-no-data-sub-header'>another text to search.</div> */}
                          </div>
                      }
                    </>
                    :
                    <div
                    // style={{marginTop:'10%'}}
                    >
                      <ProgressBar />
                    </div>
                }
              </div>
            </DialogContent>
            <DialogActions style={{ padding: "8px 16px 16px 16px" }}>
              <div
                style={{ padding: "2px" }}
                id="objkpiErr"
                className="e-input-group e-float-icon-left error-div"
              />
              <Button
                style={{ width: "120px" }}
                className="mr-sm cancel-button"
                variant="contained"
                onClick={() => this.closeObjectivePopup()}
              >
                Cancel
              </Button>
              <Button
                style={{ width: "120px" }}
                className="remove-button-shadow"
                color="primary"
                variant="contained"
                onClick={this.importObjective}
              >
                Import
              </Button>
            </DialogActions>
          </Dialog>

          <ConfirmationPopup
            open={this.state.messageOpen}
            headerMessage={this.headerMessage}
            subMessage={this.subMessage}
            button1Text={"OK"}
            button2Text={""}
            button1function={this.handleConfirmClose}
            button2function={this.handleConfirmClose}
            handleConfirmClose={this.handleConfirmClose}
            showButton={{ type: "1" }}
          />
        </div>
      </div>
    )
  }
}
ImportBusinessOKR.propTypes = {
  authUser: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  employeeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  businessUserImpact: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  businessUserVariableImpact: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fyid: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  draftedBusinessObjKpi: PropTypes.oneOfType([PropTypes.arrayOf(object), PropTypes.array]),
  submittedBusinessObjective: PropTypes.oneOfType([PropTypes.arrayOf(object), PropTypes.array]),
  allActiveBusinessObjective: PropTypes.oneOfType([PropTypes.arrayOf(object), PropTypes.array]),
  updateBuDraftFlag: PropTypes.string,
  updateBuActiveFlag: PropTypes.string,
  reRenderComponent: PropTypes.string,
  OkrMasters: PropTypes.oneOfType([PropTypes.arrayOf(object), PropTypes.array]),
  setHeightFlag: PropTypes.bool.isRequired,
  currWindowAdjust: PropTypes.number.isRequired,
  monthOfReview: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  OkrEmployeeMaster: PropTypes.oneOfType([PropTypes.arrayOf(object), PropTypes.array]),
  selectedFy: PropTypes.string,
  resetCheckedObjData: PropTypes.string,
};
ImportBusinessOKR.defaultProps = {
  authUser: "",
  employeeId: 0,
  businessUserImpact: 0,
  businessUserVariableImpact: 0,
  fyid: "",
  draftedBusinessObjKpi: [],
  submittedBusinessObjective: [],
  allActiveBusinessObjective: [],
  updateBuDraftFlag: "",
  updateBuActiveFlag: "",
  reRenderComponent: "",
  OkrMasters: [],
  OkrEmployeeMaster: [],
  monthOfReview: 0,
  selectedFy: '',
  resetCheckedObjData: ''
};
const mapStateToProps = ({ auth, okrreducer, meetingmaster }) => {
  const { authUser } = auth;
  const { finacialYearData, currentFinancialYearId } = meetingmaster;
  const { draftedBusinessObjKpi, submittedBusinessObjective, fyid, employeeId, businessUserImpact, businessUserVariableImpact, updateBuDraftFlag, updateBuActiveFlag, reRenderComponent, OkrMasters, monthOfReview, selectedFy, allActiveBusinessObjective, resetCheckedObjData, OkrEmployeeMaster } = okrreducer;
  return { authUser, draftedBusinessObjKpi, submittedBusinessObjective, fyid, employeeId, businessUserImpact, businessUserVariableImpact, updateBuDraftFlag, updateBuActiveFlag, reRenderComponent, OkrMasters, monthOfReview, selectedFy, allActiveBusinessObjective, resetCheckedObjData, OkrEmployeeMaster, finacialYearData, currentFinancialYearId };
};
export default connect(mapStateToProps, { setCentralOkrKPIRedux, addUpdateObjectveKpi, setUserOKRObjectiveKPI, submitApprovalAction })(
  ImportBusinessOKR
);
