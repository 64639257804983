import React from "react";
import { connect } from "react-redux";
import { library } from "@fortawesome/fontawesome-svg-core";
import PropTypes, { object } from "prop-types";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { ComboBoxComponent } from "@syncfusion/ej2-react-dropdowns";
import { Button } from "@material-ui/core";
import { FormValidator } from "@syncfusion/ej2-inputs";
import {
    pleaseEnterObjective, pleaseSelectFrequency, pleaseSelectEffectiveFrom, pleaseEnterVariable, pleaseEnterVariableMaxMin, pleaseEnterVariableMin,
    objectivefillData, frequencyFillData, effectiveFromFillData, TotalBusinessUserTotalariableImpact, pleaseSelectEffectiveTo,
    ERROR, PeriofFromnToValidation
} from '../../assets/constants/constants';
import { cracterLimit, getFinancialYearDate, getNotify } from '../../app/other/commonfunction';
import { addUpdateNewObj, addUpdateObjectveKpi, fillObjectiveRequest } from '../../redux/actions/okr/okractions';
import Tooltips from "../custommaterialtooltip/custommaterialtooltip";
import OKRServices from "../../services/okr/okrservices";
import ProgressBar from '../progressbar/progressbar';
import { addBusinessOkrOkrKPILimit } from '../../config/config'
import {
    faEdit,
    faEye,
    faPlus,
    faCheckCircle,
    faWindowClose
} from "@fortawesome/pro-light-svg-icons";

library.add(
    faCheckCircle,
    faWindowClose,
    faEdit,
    faEye,
    faPlus
);

class FillDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            changeData: true,
            oprnReviewReport: false,
            addNewSelfReviewOpen: false,
            objectiveTag: [],
            height:
                (window.innerHeight ||
                    document.documentElement.clientHeight ||
                    document.body.clientHeight) - 95,
            frequencyData: [],
            effectiveFromData: [],
            effectiveToData: [],
            variable: null,
            ratingStatus: "",
            isLoading: true,
            periodFromId: "",
            periodToId: ""
        };
        this.descriptionTextBox = null;
        this.commentTxtbox = null;
        this.buttonObjDisable = false;
        this.updateObjectiveDetails = this.updateObjectiveDetails.bind(this);
        this.closePopup = this.closePopup.bind(this);
        this.effectiveTo = null;
        this.frequency = null;
        this.effectiveFrom = null;

     
    }

    updateDimensions() {
        const updateHeight = window.innerHeight - 150;
        this.setState({ height: updateHeight });
    }

    async componentDidMount() {
        this.updateDimensions();
        const { fillObjective, openType } = this.props;
        window.addEventListener("resize", this.updateDimensions.bind(this));
        await this.getFrequency(parseInt(fillObjective.effectiveFrequency === null ? "1" : fillObjective.effectiveFrequency, 10), "PeriodFrom");/**NOSONAR */
        await this.getFrequency(parseInt(fillObjective.effectiveFrequency === null ? "1" : fillObjective.effectiveFrequency, 10), "PeriodTo");/**NOSONAR */
        await this.getFrequency(parseInt(fillObjective.effectiveFrequency === null ? "1" : fillObjective.effectiveFrequency, 10), "FromToFreq");/**NOSONAR */

        setTimeout(() => {
            if ((openType === "copyOKR" || openType === "closeOKR") && this.effectiveTo !== null) {
                this.effectiveTo.focusIn()
            } else {
                if (this.effectiveFrom !== null) {
                    this.effectiveFrom.focusIn()
                }
            }
        }, 1000)
    }

    fillObjectiveDetails = async (type) => {
        const { businessUserVariableImpact, fillObjective, selectedFy, employeeId, authUser, fyid, showHideComponent } = this.props;
        const { startMonth, endMonth } = fillObjective;
        let checkEffectiveDates = true;
        if (getFinancialYearDate(startMonth, selectedFy) > getFinancialYearDate(endMonth, selectedFy)) {
            getNotify(ERROR, PeriofFromnToValidation)
            if (document.getElementById("effectiveFromErr") !== null)
                document.getElementById("effectiveFromErr").innerHTML = PeriofFromnToValidation;
            checkEffectiveDates = false;
        }
        let options = {}
        options = {
            rules: {
                objectiveErr: { required: [true, pleaseEnterObjective], },
                frequencyErr: { required: [true, pleaseSelectFrequency], },
                effectiveFromErr: { required: [true, pleaseSelectEffectiveFrom], },
                effectiveToErr: {
                    required: [true, pleaseSelectEffectiveTo]
                },
            },
        };
        if (businessUserVariableImpact > 0 && TotalBusinessUserTotalariableImpact > 0) {
            options.rules["variableErr"] = {
                required: [true, pleaseEnterVariable],
                max: [TotalBusinessUserTotalariableImpact, pleaseEnterVariableMaxMin],
                min: [0, pleaseEnterVariableMin],
            }
        }

        const addFormObject = new FormValidator("#fillObjectiveForm", options);
        if (addFormObject.validate() && checkEffectiveDates) {
            if (showHideComponent === 'draftBusinessOkr') {
                this.saveObjective();
            } else {
                const datesObj = {
                    fyId: fyid,
                    startMonth: startMonth,
                    endMonth: endMonth
                }
                // Check whether the objectives are 5 against each month;
                const effectiveFrom = Promise.resolve(
                    OKRServices.getOKRFrequency("ValidateObj", authUser, employeeId, "1", fillObjective.userObjectiveId, datesObj)
                );
                const ValidateObj = await effectiveFrom.then((resultData) => {
                    return resultData.Result[0];
                })
                if (ValidateObj.result === 'Success') {
                    this.saveObjective();
                } else {
                    getNotify(ERROR,
                        "You can have maximum " + addBusinessOkrOkrKPILimit.toString() + " active Objectives on selected period of time." 

                    )
                }
            }
        }
    }

    saveObjective() {
        try {
            const { openType, fillObjective } = this.props;
            try {
                const { employeeId, fyid, authUser } = this.props;
                let data = {};
                data.objectiveTitle = fillObjective.objectiveName;
                data.effectiveMonth = fillObjective.startMonth;
                data.effectiveTo = fillObjective.endMonth;
                data.effectiveFrequency = fillObjective.effectiveFrequency;
                data.isDraft = fillObjective.isDraft;
                data.type = (openType === "copyOKR" || openType === "closeOKR") ? openType : "objective";
                data.objectiveLibId = fillObjective.objectiveLibId !== undefined && fillObjective.objectiveLibId !== 0 ? fillObjective.objectiveLibId : fillObjective.objectiveId;
                data.objectiveId = fillObjective.userObjectiveId !== undefined ? fillObjective.userObjectiveId : 0;
                data.empId = employeeId;
                data.fyid = fyid;
                data.userId = authUser;
                data.kpiLibIds = null;
                data.kpiIds = null;
                data.kpiTitle = "";
                data.kpiDescription = "";
                data.kpiAttachment = "";
                data.kpiWeightage = "";
                data.variablePercentage = fillObjective.variable;
                data.kpiVariableLinked = null;
                data.kpiBenchmark = "";
                data.kpiScopeType = "";
                data.kpiScopeUsers = "";
                data.measure = "";
                data.lable1 = "";
                data.lable2 = "";
                this.props.fillObjectiveRequest(data);
                this.props.closeCreateObj()
                this.props.addUpdateObjectveKpi({ lastSubmittedBusinessOkrObjId: data.objectiveId });
                this.props.addUpdateObjectveKpi({ resetCheckedObjData: 'updated', resetCheckedData: 'updated' });

            } catch (error) {
                console.log('error', error);
            }

        } catch (error) {

        }
    }

    checkFrequencyExists(pFreqNo) {
        const { frequencyData } = this.state;
        let checkItemExists = false
        frequencyData.forEach(item => {
            if (pFreqNo === item.Id) {
                checkItemExists = true;
            }
        })
        return checkItemExists;
    }

    updateObjectiveDetails = (event, type) => {
        const { fillObjective } = this.props;
        const { changeData } = this.state;
        let temp = fillObjective;
        switch (type) {
            case "objName":
                temp.objectiveName = event.value
                this.props.addUpdateObjectveKpi({ fillObjective: temp })
                break;
            case "frequecy":
                if (event.itemData !== null) {
                    temp.effectiveFrequency = event.itemData.Id;
                    this.props.addUpdateObjectveKpi({ fillObjective: temp })
                    this.setState({ changeData: !changeData })
                }
                break;
            case "effectiveFrom":
                if (event.itemData !== null) {
                    temp.effectiveMonth = event.itemData.startMonth
                    temp.startMonth = event.itemData.startMonth
                    temp.periodFrom = event.itemData.startId
                    this.getFrequency(event.itemData.frequencyNo, "PeriodTo");
                    temp.effectiveFrequency = this.checkFrequencyExists(event.itemData.frequencyNo) ? event.itemData.frequencyNo : ""
                    this.props.addUpdateObjectveKpi({ fillObjective: temp })
                }
                break;
            case "effectiveTo":
                if (event.itemData !== null) {
                    temp.effectiveTo = event.itemData.endMonth
                    temp.endMonth = event.itemData.endMonth
                    temp.periodTo = event.itemData.endId
                    this.getFrequency(event.itemData.frequencyNo, "FromToFreq");
                    temp.effectiveFrequency = this.checkFrequencyExists(event.itemData.frequencyNo) ? event.itemData.frequencyNo : ""
                    this.props.addUpdateObjectveKpi({ fillObjective: temp })
                }
                break;
            case "variable":
                if (event.value !== '') {
                    temp.variable = event.value
                    this.props.addUpdateObjectveKpi({ fillObjective: temp })
                }
                break;
            default:
        }
    }
    closePopup = () => {
        this.props.addUpdateObjectveKpi({ fillObjective: {} })
    }

    getFrequencyName = async () => {
        const { employeeId, authUser, fillObjective } = this.props;
        const { userObjectiveId, finYear, startMonth, endMonth } = fillObjective;
        const datesObj = {
            fyId: finYear, startMonth: startMonth, endMonth: endMonth
        }

        const effectiveFrom = Promise.resolve(
            OKRServices.getOKRFrequency("Freq", authUser, employeeId, "1", userObjectiveId, datesObj)
        );
        await effectiveFrom.then((resultData) => {
            this.setState({ frequencyData: resultData.Result });
        })
    }


    getFrequency = async (e, type) => { //NOSONAR
        if (e !== undefined && e !== null && e !== '') {
            const { employeeId, authUser, fillObjective, openType } = this.props;
            const { userObjectiveId, finYear, startMonth, endMonth, periodFrom, periodTo, effectiveFrequency } = fillObjective;
            const { changeData } = this.state;
            this.setState({ isLoading: true })
            const datesObj = {
                fyId: finYear, startMonth: startMonth, endMonth: (typeof endMonth !== 'undefined' ? endMonth : 0)
            }
            const effectiveFrom = Promise.resolve(
                OKRServices.getOKRFrequency(type + ((openType === "copyOKR" || openType === "closeOKR") ? "_C" : ""), authUser, employeeId, e, userObjectiveId, datesObj)
            );
            let checkItemExists = false;
            await effectiveFrom.then(async (resultData) => {
                let temp = fillObjective;
                if (type === 'PeriodFrom') {
                    this.setState({ effectiveFromData: resultData.Result });
                    resultData.Result.forEach(item => {
                        if (periodFrom === item.startId) {
                            checkItemExists = true
                            return true
                        }
                    })
                    if (!checkItemExists) {
                        temp.effectiveMonth = ""; temp.startMonth = 4; temp.periodFrom = ""
                    }
                }
                else if (type === 'PeriodTo') {
                    this.setState({ effectiveToData: resultData.Result });
                    resultData.Result.forEach(item => {
                        if (periodTo === item.endId) {
                            checkItemExists = true
                            return true
                        }
                    })
                    if (!checkItemExists) {
                        temp.effectiveTo = ""; temp.endMonth = 3; temp.periodTo = ""
                    }
                }
                else if (type === 'FromToFreq') {
                    this.setState({ frequencyData: resultData.Result });
                    temp.effectiveFrequency = this.checkFrequencyExists(effectiveFrequency) ? effectiveFrequency : ""
                    if (temp.effectiveFrequency === "" && resultData.Result.length === 1 && (openType === "copyOKR" || openType === "closeOKR")) {
                        temp.effectiveFrequency = resultData.Result[0].Id
                    }
                }
                this.props.addUpdateObjectveKpi({ fillObjective: temp })
                this.setState({ changeData: !changeData, isLoading: false })
            })
        }
    }

    getRatingStatusIcon(ratingStatus) {
        switch (ratingStatus) {
            case "Closed":
                return faCheckCircle;
            case "NA":
                return faWindowClose;
            case "Opened":
                return faEye;
            case "Not Opened":
                return faEdit;
            default:
        }
    }

    itemTemplate = (data) => {
        return (
            <div style={{ marginLeft: 0, marginRight: 0, zIndex: 99999, fontWeight: (data.isBold === "Y" ? 'bold' : 'normal') }}>
                <div style={{ tabSize: 2 }}> {
                    Array(data.spaces).fill().map(element => '\u00A0')
                } {data.Name} </div>
            </div>
        );
    };

    getChangeSequence = (seq) => {
        try {
            const { fillObjective, openType } = this.props;
            const { frequencyData, effectiveFromData } = this.state;
            if (seq === 2) {
                return (
                    <div className="col-xs-12 col-sm-4 col-lg-3 col-md-3" style={{ padding: "0px 32px 0px 16px" }}>
                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                            <ComboBoxComponent
                                ref={(obj) => {
                                    this.frequency = obj;
                                }}
                                id="Frequency"                                
                                fields={{ text: "Name", value: "Id" }}
                                cssClass="e-outline"
                                dataSource={frequencyData}
                                data-msg-containerid="frequencyErr"
                                name="frequencyErr"
                                floatLabelType="Auto"
                                placeholder="OKR Rating Frequency *"
                                htmlAttributes={{ maxlength: 100 }}
                                value={fillObjective.effectiveFrequency}
                                change={(e) => this.updateObjectiveDetails(e, 'frequecy')}
                                enabled={!(openType === "copyOKR" || openType === "closeOKR")}
                            />
                            <div
                                id="frequencyErr"
                                className="e-input-group e-float-icon-left error-div"
                            />
                        </div>
                        <span style={{ padding: "4px 0px 0px 8px", position: "absolute" }}>
                            <Tooltips titleMessage={frequencyFillData} />
                        </span>
                    </div>
                )
            } else {
                return (
                    <div className={"col-xs-12 col-sm-4 col-lg-3 col-md-3"} style={{ padding: "0px 32px 0px 16px" }}>
                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                            {
                                effectiveFromData.length > 0 &&
                                <ComboBoxComponent
                                    ref={(obj) => {
                                        this.effectiveFrom = obj;
                                    }}
                                    id="EffectiveFrom"
                                    fields={{ text: "Name", value: "startId" }}
                                    cssClass="e-outline"
                                    data-msg-containerid="effectiveFromErr"
                                    name="effectiveFromErr"
                                    floatLabelType="Auto"
                                    placeholder="OKR Validity Starts *"
                                    htmlAttributes={{ maxlength: 100 }}
                                    dataSource={effectiveFromData}
                                    value={fillObjective.periodFrom}
                                    itemTemplate={this.itemTemplate}
                                    change={(e) => this.updateObjectiveDetails(e, 'effectiveFrom')}
                                    enabled={!(openType === "copyOKR" || openType === "closeOKR")}
                                />
                            }
                            <div
                                id="effectiveFromErr"
                                className="e-input-group e-float-icon-left error-div"
                            />
                        </div>
                        <span style={{ padding: "4px 0px 0px 8px", position: "absolute" }}>
                            <Tooltips titleMessage={effectiveFromFillData} />
                        </span>

                    </div>
                )

            }
        } catch (e) {
        }
    }


    render() {
        const { fillObjective, businessUserVariableImpact, openType } = this.props;
        const { effectiveToData, isLoading } = this.state;
        return (
            <>
                {
                    isLoading ?
                        <div style={{ display: 'flex', height: 200 }}>
                            <ProgressBar />
                        </div>
                        :
                        <>
                            <form id="fillObjectiveForm" method="post">
                                <div className="row" style={{ paddingTop: "20px" }}>
                                    <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12" style={{ padding: "0px 42px 0px 16px" }}>
                                        <div className="row">
                                            {this.getChangeSequence(1)}
                                            {

                                                <div className={"col-xs-12 col-sm-4 col-lg-3 col-md-3"} style={{ padding: "0px 32px 0px 16px" }}>
                                                    <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                                        {
                                                            effectiveToData.length > 0 &&
                                                            <ComboBoxComponent
                                                                ref={(obj) => {
                                                                    this.effectiveTo = obj;
                                                                }}
                                                                id="EffectiveTo"
                                                                fields={{ text: "Name", value: "endId" }}
                                                                cssClass="e-outline"
                                                                data-msg-containerid="effectiveToErr"
                                                                name="effectiveToErr"
                                                                floatLabelType="Auto"
                                                                placeholder="OKR Validity Ends *"
                                                                htmlAttributes={{ maxlength: 100 }}
                                                                dataSource={effectiveToData}
                                                                value={fillObjective.periodTo}
                                                                itemTemplate={this.itemTemplate}
                                                                change={(e) => this.updateObjectiveDetails(e, 'effectiveTo')}
                                                            />
                                                        }
                                                        <div
                                                            id="effectiveToErr"
                                                            className="e-input-group e-float-icon-left error-div"
                                                        />
                                                    </div>
                                                    <span style={{ padding: "4px 0px 0px 8px", position: "absolute" }}>
                                                        <Tooltips titleMessage={effectiveFromFillData} />
                                                    </span>
                                                </div>
                                            }
                                            {this.getChangeSequence(2)}
                                            {
                                                businessUserVariableImpact > 0 &&
                                                <div className="col-xs-12 col-sm-4 col-lg-2 col-md-2" style={{ padding: "0px 32px 0px 16px" }}>
                                                    <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                                        <TextBoxComponent
                                                            id="function"
                                                            cssClass="e-outline"
                                                            data-msg-containerid="variableErr"
                                                            name="variableErr"
                                                            floatLabelType="Auto"
                                                            placeholder="Variable % *"
                                                            value={fillObjective.variable}
                                                            change={(e) => this.updateObjectiveDetails(e, 'variable')}
                                                            disabled={(openType === "copyOKR" || openType === "closeOKR")}
                                                        />
                                                        <div
                                                            style={{ position: "fixed" }}
                                                            id="variableErr"
                                                            className="e-input-group e-float-icon-left error-div"
                                                        />
                                                    </div>
                                                    <span style={{ padding: "4px 0px 0px 8px", position: "absolute" }}>
                                                    </span>
                                                </div>
                                            }

                                        </div>
                                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                            <TextBoxComponent
                                                id="objective"
                                                cssClass="e-outline"
                                                data-msg-containerid="objectiveErr"
                                                name="objectiveErr"
                                                floatLabelType="Auto"
                                                placeholder="Objective *"
                                                value={fillObjective.objectiveName}
                                                onChange={(e) => this.updateObjectiveDetails(e, 'objName')}
                                                htmlAttributes={{ maxlength: "100", minLength: 1 }}
                                                input={(e) => cracterLimit(e, `objectiveTypeCharactor`, '100')}
                                                disabled={(openType === "copyOKR" || openType === "closeOKR")}
                                            />

                                            <div style={{ margin: '0px -52px 4px 0px' }} id="objectiveErr" className="e-input-group e-float-icon-left error-div" />
                                            <div id='objectiveTypeCharactor' style={{ float: 'right', fontSize: 12, margin: '0px 0px 8px 0px' }} className="error-div" />
                                        </div>
                                        <span style={{ padding: "4px 0px 0px 8px", position: "absolute" }}>
                                            <Tooltips titleMessage={objectivefillData} />
                                        </span>
                                    </div>
                                </div>

                                <div className="row" style={{ float: "right", padding: "10px 42px 0px 0px" }}>
                                    <Button
                                        style={{ width: "120px" }}
                                        className="mr-sm cancel-button"
                                        variant="contained"
                                        onClick={() => this.props.closeCreateObj()}
                                    >
                                        Cancel
                                    </Button>

                                    <Button
                                        style={{ width: "120px" }}
                                        className="remove-button-shadow"
                                        color="primary"
                                        variant="contained"
                                        onClick={this.fillObjectiveDetails}>
                                        Save
                                    </Button>

                                </div>
                            </form>
                        </>
                }
            </>
        );
    }
}
FillDetails.propTypes = {
    authUser: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    objectiveName: PropTypes.oneOfType([PropTypes.string]),
    fillObjective: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    OkrMasters: PropTypes.oneOfType([PropTypes.arrayOf(object), PropTypes.array]),
    draftedBusinessObjKpi: PropTypes.oneOfType([PropTypes.arrayOf(object), PropTypes.array]),
    businessUserVariableImpact: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    showHideComponent: PropTypes.oneOfType([PropTypes.string]),
};
FillDetails.defaultProps = {
    authUser: "",
    objectiveName: "",
    fillObjective: {},
    OkrMasters: {},
    draftedBusinessObjKpi: [],
    businessUserVariableImpact: 0,
    showHideComponent: ""
};
const mapStateToProps = ({ auth, okrreducer }) => {
    const { authUser } = auth;
    const {
        objectiveName,
        fillObjective,
        OkrMasters,
        draftedBusinessObjKpi,
        employeeId,
        fyid,
        businessUserVariableImpact,
        selectedFy
    } = okrreducer;
    return {
        authUser,
        objectiveName,
        fillObjective,
        OkrMasters,
        employeeId,
        draftedBusinessObjKpi,
        fyid,
        businessUserVariableImpact,
        selectedFy
    };
};

export default connect(mapStateToProps, { addUpdateNewObj, addUpdateObjectveKpi, fillObjectiveRequest })(FillDetails);
