import React, { Component } from 'react';
import { ComboBoxComponent } from "@syncfusion/ej2-react-dropdowns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons'
import PropTypes from "prop-types";
import { connect } from "react-redux"
import SeparationServices from '../../../services/entryexit/separationServices';
import { dencrypt,decodeString } from "../../../app/other/commonfunction";
import { addUpdateSeparationDetails } from '../../../redux/actions/entryexit/separationaction';
import "./exitclearancecomponents.css";
import { EXIT_CLEARANCE_MANAGER } from '../../../redux/constant/meetingcategoryconstant';
import { getDisplayDateOnly } from './../../../app/other/commonfunction';
import ProgressBar from '../../progressbar/progressbar';
import ErrorTabMessage from '../../errormessage/errormessage';
import ErrorBoundary from '../../error/errorboundary';
class ItDepartmentClearance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      assetList: [],
      assetCondition: [],
      height: 182,
      showLoader: true
    };
  }
  componentDidMount = async () => {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
    await this.getClearanceData();
    this.getClearanceMasterData();
  };
  componentWillReceiveProps(nextProps) {
    if (nextProps.updateExitClearanceStatus === 'updated') {
      this.getClearanceData();
      this.props.addUpdateSeparationDetails({ updateExitClearanceStatus: "" })
    }
  }
  /**
  * Calculate & Update state of new dimensions
  */
  updateDimensions() {
    let update_width = window.innerWidth - 100;
    let update_height = window.innerHeight - 493;
    this.setState({ width: update_width, height: update_height });
  }
  getClearanceMasterData = () => {
    try {
      const { authUser } = this.props;
      const employeeMasterData = Promise.resolve(
        SeparationServices.getEmployeeResignationMaster(
          authUser,
          "assetcnd"
        )
      );
      employeeMasterData.then((result) => {
        if (result !== null && result.length > 0) {
          let data = result.filter(item => { return item.Type === 'Asset Condition'; });
          this.setState({
            assetCondition: data,
          });
        }
      });
    } catch (error) {
      // eslint-disable-next-line
    }
  };
  getClearanceData = async () => {
    try {
      const { authUser } = this.props;
      const { search } = window.location;
      const params = await new URLSearchParams(search);  // NOSONAR
      const empId = await dencrypt(params.get("empId")); // NOSONAR
      let pageId = decodeString(localStorage.getItem('meeting_category'));
      setTimeout(() => {
        const employeeSeparationClearanceData = Promise.resolve( 
          SeparationServices.getEmployeeClearanceData(
            // pageId === EXIT_CLEARANCE_MANAGER ? 'mgr' : 'item',
            'item',
            authUser,
            empId,
          )
        );
        employeeSeparationClearanceData.then((result) => {
          if (result !== null && result.length > 0) {
            this.setState({
              assetList: result,
              showLoader: false
            }, () => this.props.addUpdateSeparationDetails({ clearanceItem: result, clearanceType: pageId === EXIT_CLEARANCE_MANAGER ? 'mgr' : 'item' }))
          } else {
            this.setState({
              assetList: [],
              showLoader: false
            }, () => this.props.addUpdateSeparationDetails({ clearanceItem: [] }))
          }
        });
      }, 3000)
    } catch (error) {
      this.setState({
        showLoader: false
      })
    }
  }

  render() {/**NOSONAR */
    const { assetCondition, assetList, showLoader } = this.state;
    const { departmentalText } = this.props;
    let pageId = decodeString(localStorage.getItem('meeting_category'));
    return (
      <div>
        <ErrorBoundary>
        <div>{pageId !== EXIT_CLEARANCE_MANAGER && departmentalText !== undefined && departmentalText.toUpperCase()} CLEARANCE * </div>
        <div>
          {
            assetList.length > 0 &&
            <div className="row table-border-bottom top-side-margin">
              <div className="col-xs-2 col-sm-2 col-lg-5 col-md-2 exit-clearance-status-table-header" style={{ paddingLeft: '1.4%' }}> List </div>
              <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2 exit-clearance-status-table-header" style={{ textAlign: 'center' }}> Date </div>
              <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2 exit-clearance-status-table-header"> Condition </div>
              <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2 exit-clearance-status-table-header" style={{ textAlign: 'center' }}> Handover Received </div>
            </div>
          }
          <div className="row" style={{ height: this.state.height }}>
            {
              assetList.length > 0 && assetCondition.length > 0 ?
                assetList.map((item, index) => (
                  <div key={item.checkListUid} className="row table-border-bottom top-side-margin">
                    <div className="col-xs-2 col-sm-2 col-lg-5 col-md-2" style={{ paddingLeft: '2.4%', marginTop: 10 }}>{item.checklistType}</div>
                    <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2 " style={{ textAlign: 'center', marginTop: 10 }}>{getDisplayDateOnly(new Date(item.checkDate))}</div>
                    <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2 ">
                      <ComboBoxComponent
                        className='custom-dropdown-clearance'
                        allowCustom={false}
                        value={this.state.assetList[index].assetCondId===undefined || this.state.assetList[index].assetCondId === null ? "" : this.state.assetList[index].assetCondId}
                        change={(e) => {
                          let temp = this.state.assetList;
                          temp[index].assetCond = e.itemData !== null ? e.itemData.Name : e.itemData;
                          temp[index].assetCondId = e.itemData !== null ? e.itemData.Id : e.itemData;
                          this.setState({ assetList: temp }, () => this.props.addUpdateSeparationDetails({ clearanceItem: this.state.assetList }))
                        }}
                        fields={{ text: "Name", value: "Id" }}
                        id="noticePeriodRequest"
                        dataSource={assetCondition}
                        cssClass="e-outline"
                        placeholder="Condition"
                      /></div>
                    <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2" style={{ textAlign: 'center', marginLeft: -10, marginTop: 10 }}>
                      {
                        item.handOverRec !== '0' && item.handOverRec !== null ?
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            onClick={() => {
                              let temp = this.state.assetList;
                              temp[index].handOverRec = "0";
                              this.setState({
                                assetList: temp
                              }, () => this.props.addUpdateSeparationDetails({ clearanceItem: this.state.assetList }))
                            }}
                            style={{ color: "#4B4C50", cursor: "pointer", fontSize: 18 }}
                          />
                          :
                          <FontAwesomeIcon
                            icon={faCheckCircle}
                            onClick={() => {
                              let temp = this.state.assetList;
                              temp[index].handOverRec = "1";
                              this.setState({
                                assetList: temp
                              }, () => this.props.addUpdateSeparationDetails({ clearanceItem: this.state.assetList }))
                            }}
                            style={{ color: "#DEDEDE", cursor: "pointer", fontSize: 18 }}
                          />
                      }
                    </div>
                  </div>
                ))
                :
                !showLoader ?/**NOSONAR */
                  <div style={{ marginTop: '-5%' }}>
                    <ErrorTabMessage message={"No Data Available."} />
                  </div>
                  :
                  <ProgressBar />
            }
          </div>

        </div>
        </ErrorBoundary>
      </div>
    );
  }
}

ItDepartmentClearance.propTypes = {
  authUser: PropTypes.string,
  noticePeriodInMonth: PropTypes.number,
  willingToBuyBackNotice: PropTypes.number,
  Remarks: PropTypes.string,
};
ItDepartmentClearance.defaultProps = {
  authUser: "",
  noticePeriodInMonth: 0,
  Remarks: "",
  willingToBuyBackNotice: 0
};
const mapStateToProps = ({ auth, separationreducer }) => {
  const { authUser, departmentalText } = auth;
  const { updateExitClearanceStatus, empid, clearanceType } = separationreducer;
  return { authUser, updateExitClearanceStatus, empid, clearanceType, departmentalText };
};

export default connect(mapStateToProps, { addUpdateSeparationDetails })(ItDepartmentClearance);
