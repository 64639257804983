import React, { useEffect, useState } from 'react'
import { dencrypt } from '../../other/commonfunction';
import queryString from "query-string";
import HelpdeskAdminPopupContainer from '../../containers/helpdesk/adminpopupcontainer';
import HelpdeskAdminContainer from '../../containers/helpdesk/admincontainer';
import { connect } from 'react-redux';
import { NotificationContainer } from 'react-notifications';
import { handlePopup } from '../../../redux/actions/helpdesk/helpdeskaction';

const HelpdeskAdminSetup = ({ authUser, location, openPopup, ...props }) => {
    const [state, setState] = useState({ masterId: 0 });

    useEffect(() => {
        setMasterId()
    }, [])

    const setMasterId = () => {
        try {
            const { masterId } = state
            const params = queryString.parse(location.search);
            if (typeof params.masterId !== 'undefined' && params.masterId !== null) {
                if (masterId !== dencrypt(params.masterId))
                    setState(prevState => ({ ...prevState, masterId: dencrypt(params.masterId) }))
            }
        } catch (error) {
        }
    }

    const changePopUpHandler = ({ type = "close", editItemId = null }) => {
        setState(prevState => ({
            ...prevState,
            editItemId
        }));
        props.handlePopup(type)
    }

    const [heightWidthState, setHeightState] = useState({ containerHeight: 0, width: (window.innerWidth) - 85 })

    useEffect(() => {
        window.addEventListener("resize", updateDimensions()); // NOSONAR
    }, []);

    useEffect(() => {
        window.onresize = () => {
            let width =
                (window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) - 85;
            setHeightState((preState) => ({ ...preState, width }));
        }
    }, [])

    const updateDimensions = () => {
        const containerHeight = window.innerHeight - 120
        setHeightState(prevState => ({
            ...prevState,
            containerHeight: containerHeight
        }))
    }

    return (
        <div
            id='helpdesk-admin-container-root'
            className="col-xs-12 col-sm-12 col-lg-12 col-md-12"
            style={{
                height: heightWidthState.containerHeight,
                width: heightWidthState.width
            }}
        >
            <NotificationContainer />
            {state.masterId > 0 && <HelpdeskAdminContainer
                changePopUpHandler={changePopUpHandler}
                state={state}
            />}
            {(openPopup === "add" || openPopup === "edit") &&
                <HelpdeskAdminPopupContainer
                    changePopUpHandler={changePopUpHandler}
                    state={state}
                />
            }
        </div>
    )
}

const mapStateToProps = ({ auth, router, helpdeskreducer }) => {
    const { authUser } = auth;
    const { location } = router;
    const { openPopup } = helpdeskreducer;
    return {
        authUser,
        location,
        openPopup
    }
}

export default connect(mapStateToProps, { handlePopup })(HelpdeskAdminSetup)