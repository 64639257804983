import React from 'react';
import PropTypes from "prop-types";
import {
   faMagnifyingGlass
  } from "@fortawesome/pro-light-svg-icons";
  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
function SearchBoxTwoComponent(props) {
    const { placeholder = null, onChange = null, value = null, id = null, cssClass = null, type = null } = props

    document.querySelector('#'+id)?.addEventListener('focus', () => {
        if (document.querySelector('.top-ticket-search-box')) {
            document.querySelector('.top-ticket-search-box').classList.add('focused');
        }
    })

    document.querySelector('#'+id)?.addEventListener('blur', () => {
        if (document.querySelector('.top-ticket-search-box')) {
            document.querySelector('.top-ticket-search-box').classList.remove('focused');
        }
    })

    return (
        <>
            <FontAwesomeIcon className='top-ticke-search-icon' icon={faMagnifyingGlass}/>
            <input
                className={cssClass + " top-ticket-search-box"}
                type={type}
                placeholder={placeholder}
                onChange={onChange}
                value={value}
                id={id}
            />
        </>
    );
}

SearchBoxTwoComponent.propTypes = {
    id: PropTypes.string,
    cssClass: PropTypes.string,
    onChange: PropTypes.func,
    value: PropTypes.string,
    type: PropTypes.string,
    placeholder: PropTypes.string
};

SearchBoxTwoComponent.defaultProps = {
    floatLabelType: "Auto",
    onChange: () => { },//NOSONAR
    cssClass: "",
    value: "",
    type: "",
    id:""
};

export default SearchBoxTwoComponent;