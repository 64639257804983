import React, { Component } from "react";
import PropTypes from "prop-types"; 

// eslint-disable-next-line
class ErrorMessage extends Component {
  render() {
    const { message, position } = this.props;
    return (
      <div
        style={{
          textAlign: "center",
          position: `${position ? position : 'absolute'}`,
          top: "50%",
          bottom: "50%",
          left: "0",
          right: "0",
          marginTop: 200,
        }}
      >
        <div
          style={{
            font: "icon",
            display: "inline-table",
            width: "50%",
            background: "#ecebe9",
            color: "#07090d",
            fontWeight: "bold",
          }}
        >
          <h3 style={{ marginTop: "20px", marginBottom: "20px" }}>{message}</h3>
        </div>
      </div>
    );
  }
}
ErrorMessage.propTypes = {
  message: PropTypes.string.isRequired,
};
ErrorMessage.defaultProps = {};
export default ErrorMessage;
