import React, { PureComponent } from "react";
import { Button, Dialog, DialogContentText } from "@material-ui/core";
import PropTypes from "prop-types";
import {
  DialogTitle,
  DialogContent,
  DialogActions,
} from "../customdialog/customdialog";
import ErrorBoundary from "../error/errorboundary";

class ConfirmDialog extends PureComponent {
  render() {
    const {
      open,
      handleConfirmClose,
      title,
      message,
      handleConfirmYes,
      button1Caption,
      handleConfirmNo,
      button2Caption
    } = this.props;
    return (
      <Dialog
        open={open}
        onClose={handleConfirmClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <ErrorBoundary>
          <DialogTitle id="alert-dialog-title" onClose={handleConfirmClose}>
            {title}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" style={{ padding: "0px 15px", whiteSpace: "pre-line" }}>
              {message}
            </DialogContentText>
          </DialogContent>
          <DialogActions  >

            {
              button2Caption !== "" &&
              <Button onClick={handleConfirmNo} color="primary">
                {button2Caption}
              </Button>
            }
            <Button onClick={handleConfirmYes} color="primary" autoFocus>
              {button1Caption}
            </Button>
          </DialogActions>
        </ErrorBoundary>
      </Dialog>
    );
  }
}
ConfirmDialog.propTypes = {
  open: PropTypes.bool,
  handleConfirmClose: PropTypes.func,
  title: PropTypes.string,
  message: PropTypes.string,
  handleConfirmYes: PropTypes.func,
  button1Caption: PropTypes.string,
  handleConfirmNo: PropTypes.func,
  button2Caption: PropTypes.string,
};

ConfirmDialog.defaultProps = {
  open: false,
  title: "",
  message: "",
  button1Caption: "",
  button2Caption: "",
};
export default ConfirmDialog;
