import './default.css';
import * as React from 'react';
import PropTypes from "prop-types";
import { MainClass } from '../../../mainclass';
import TimeInBusiness from '../../containers/tibr/timeinbusiness';
import { connect } from "react-redux";
import UploadDailyTib from '../../containers/tibr/uploaddailytib';
import TibAverage from '../../containers/tibr/tibaverage';
import UtilizationReport from '../../containers/tibr/UTRContainer';
import {
    TIME_IN_BUSINESS, UPLOAD_DAILY_DATA, TIB_AVERAGE_REPORT, UTILIZATION_OF_TIME_REPORT
} from '../../../redux/constant/meetingcategoryconstant';
import { addUpdateTibData, setTIBMultipleMonthsIds, setTIBMultipleUsersId, setGrayLeaves, setMonths, setTIBUserList ,utilizationReportUpdate} from '../../../redux/actions/tibacton';
import tibrservices from "../../../services/tibservices";
import queryString from "query-string";
import { dencrypt } from '../../other/commonfunction'
import { getTibUserList } from '../../other/commonlayoutfunction'

class TIBRLayout extends MainClass {
    constructor(props) {
        super(props)
        this.state = {
            isLoaded: false,
            moduleId: 0
        }
    }

    async componentDidMount() {
        try {
            this.setModuleId()
            const { tibUserList, tibGrayLeaves, tibFinancialYearData } = this.props
            if (tibUserList.length === 0) {
                const tibData = await getTibUserList(this.props.authUser);
                this.props.setTIBUserList(tibData.userData)
                this.props.setTIBMultipleMonthsIds(tibData.mselectedIds);
                this.props.setMonths(tibData.months);
                this.props.setTIBMultipleUsersId([parseInt(this.props.authUser)]);
                this.props.utilizationReportUpdate({utilisationMonths:tibData.utrMonths});
            }
            if (tibFinancialYearData.length === 0)
                this.getTibMasters();
            if (tibGrayLeaves.length === 0)
                this.getGrayedShadesLeave();
            this.setState({ isLoaded: true })
        } catch (error) {
            console.log("🚀 ~ file: index.js ~ line 44 ~ TIBRLayout ~ componentDidMount ~ error", error)
        }
    }

    getTibMasters = () => {
        try {
            let getCategaryMaster = Promise.resolve(tibrservices.getTBIRCategoryMaster("tibMasterAll"));
            getCategaryMaster.then((result) => {
                if (result !== null && result.length > 0) {
                    const tibFinancialYearData = result.filter((item) => { return item.Type === "fy" && item.Id > 20; });
                    const tibAvgBucketData = result.filter((item) => { return item.Type === "TIB_Avg_Bucket"; });
                    const tibAvgViewByData = result.filter((item) => { return item.Type === "TIB_Avg_View_By"; });

                    let selectedBucketIds = [];
                    tibAvgBucketData.forEach(element => { selectedBucketIds.push(element.Id) });
                    let selectedViewByIds = [];
                    tibAvgViewByData.forEach(element => {
                        if (element.Id === 15) {
                            selectedViewByIds.push(element.Id)
                        }
                    });
                    this.props.addUpdateTibData({
                        tibFinancialYearData: tibFinancialYearData,
                        tibAvgBucketData: tibAvgBucketData,
                        tibAvgViewByData: tibAvgViewByData,
                        tibAvgSelectedBucketIds: selectedBucketIds,
                        tibAvgSelectedViewByIds: selectedViewByIds,
                    })
                }
            });
        } catch (error) {
        }
    }
    // Get LeaveTypes for gray shades
    getGrayedShadesLeave = () => {
        try {
            const getGrayShadeLeaveTypesList = Promise.resolve(
                tibrservices.getGrayShadeLeaveTypes(this.props.authUser)
            );
            getGrayShadeLeaveTypesList.then(async (data) => {
                try {
                    if (data != null) {
                        this.props.setGrayLeaves(data);
                    }
                } catch (error) {
                }
            });
        } catch (error) {
        }
    }

    setModuleId() {
        try {
            // Set App Id
            const { moduleId } = this.state
            const params = queryString.parse(this.props.location.search);
            if (typeof params.id !== 'undefined' && params.id !== null) {
                if (moduleId !== dencrypt(params.id))
                    this.setState({ moduleId: dencrypt(params.id) })
            }
        } catch (error) {

        }
    }

    componentDidUpdate(newProps) {
        try {
            this.setModuleId()
        } catch (error) {
        }
    }

    renderSwitch() {
        const { categoryName, tibMonts, tibUserList, tibFinancialYearData } = this.props
        const { moduleId } = this.state
        let activeModule = (moduleId === 0 ? categoryName : moduleId).toString()
        switch (activeModule.toString()) {
            case TIME_IN_BUSINESS:
                if (tibMonts.length > 0 && tibUserList.length > 0)
                    return <TimeInBusiness categoryName={activeModule} />;
                else
                    return ""
            case UPLOAD_DAILY_DATA:
                return <UploadDailyTib categoryName={activeModule} />;
            case TIB_AVERAGE_REPORT:
                if (tibFinancialYearData.length > 0)
                    return <TibAverage categoryName={activeModule} />
                else
                    return ""
            case UTILIZATION_OF_TIME_REPORT:
                return <UtilizationReport categoryName={activeModule} />
            default:
                return '';
        }
    }

    render() {
        const { isLoaded } = this.state
        return (
            <div style={{ height: '100%', width: '100%' }}>
                {isLoaded && this.renderSwitch()}
            </div>
        );
    }
}
TIBRLayout.propTypes = {
    authUser: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    categoryName: PropTypes.string,
    tibMonts: PropTypes.arrayOf(Object),
    tibUserList: PropTypes.arrayOf(Object),
    tibFinancialYearData: PropTypes.arrayOf(Object)
};
TIBRLayout.defaultProps = {
    authUser: "",
    categoryName: "",
    tibMonts: [],
    tibUserList: [],
    tibFinancialYearData: []

};
const mapStateToProps = ({ meetingcategory, tibReducer, auth }) => {
    const { categoryName } = meetingcategory;
    const { authUser } = auth;
    const {
        tibMonts,
        tibUserList,
        tibFinancialYearData,
        tibGrayLeaves
    } = tibReducer;
    return {
        categoryName,
        tibMonts,
        tibUserList,
        authUser,
        tibFinancialYearData,
        tibGrayLeaves
    }
};
export default connect(mapStateToProps, { addUpdateTibData, setTIBMultipleMonthsIds, setTIBMultipleUsersId, setGrayLeaves, setMonths, setTIBUserList,utilizationReportUpdate })(TIBRLayout);





