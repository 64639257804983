import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import {
    DialogContent,
    DialogTitleTMLC,
    DialogActions
} from "../../../customdialog/customdialog";
import { Dialog } from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { APPLYCOMPOFF, APPLYLEAVE, SPECIAL_ATTENDANCE } from '../.../../../../../redux/constant/meetingcategoryconstant'


export const ViewMoreDayBreakup = (props) => {
    const [state, setState] = React.useState({
        dayBreakupDates: []
    });
    useEffect(() => {
        createDayBreaupFormatData();
    }, [])

    const createDayBreaupFormatData = () => {
        let breakupDatesdata = props.dayBreakupDates;
        let finalDaybreakupData = [];
        if (!(breakupDatesdata === null || typeof breakupDatesdata === undefined)) {
            breakupDatesdata.forEach((item, inx) => {
                if (inx === 0 && item.isSelectionEligible === '1') {
                    item.leaveCluster = props.leaveClusterStart
                } else if (inx > 0 && inx === props.dayBreakupDates.length - 1 && item.isSelectionEligible === '1') {
                    item.leaveCluster = props.leaveClusterEnd
                } else {
                    if (item.dayBreak === 1) {
                        item.leaveCluster = {
                            q1: true,
                            q2: true,
                            q3: true,
                            q4: true
                        }
                    }
                    else if (item.dayBreak === 0.5) {
                        if (item.dayBreakType === '1') {
                            item.leaveCluster = {
                                q1: true,
                                q2: true,
                                q3: false,
                                q4: false
                            }
                        }
                        else {
                            item.leaveCluster = {
                                q1: false,
                                q2: false,
                                q3: true,
                                q4: true
                            }
                        }
                    }
                    else if (item.dayBreak === 0.25) {
                        if (item.dayBreakType === '1') {
                            item.leaveCluster = {
                                q1: true,
                                q2: false,
                                q3: false,
                                q4: false
                            }
                        }
                        else if (item.dayBreakType === '2') {
                            item.leaveCluster = {
                                q1: false,
                                q2: true,
                                q3: false,
                                q4: false
                            }
                        }
                        else if (item.dayBreakType === '3') {
                            item.leaveCluster = {
                                q1: false,
                                q2: false,
                                q3: true,
                                q4: false
                            }
                        }
                        else if (item.dayBreakType === '4') {
                            item.leaveCluster = {
                                q1: false,
                                q2: false,
                                q3: false,
                                q4: true
                            }
                        }
                    }
                }
                finalDaybreakupData.push(item);
            })
        }
        let data = finalDaybreakupData;
        setState({ dayBreakupDates: data });
    }

    const getDayBreakCss = (status) => {
        switch (status) {
            case "":
                return 'ml-sm cluster-select-leave-applocation-block-select';
            case false:
                return 'ml-sm cluster-select-leave-applocation-block-unselect';
            case "WEEK OFF":
            case "Already leave applied.":
            case "HOLIDAY":
                return 'ml-sm cluster-select-leave-applocation-block-default';
            default:
                return 'ml-sm cluster-select-leave-applocation-block-default';
        }
    }
    const getTitleCase = (title) => {
        if (title !== null && title !== '') {
            const words = title.split(' ');
            const newTitle = words.map(word => {
                return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
            });
            return newTitle.join(' ');
        }
    }
    const getBackgroundBreakCss = (type, comment, color) => {
        if (type) {
            if (color) return color
            else if (comment === 'COUNTED') return props.leaveColor
        } else {
            return "#B7B7BB";
        }
    }
    const { dayBreakupDates } = state;

    const isFourteenHundred = window.innerHeight < 782 && window.innerWidth < 1441;
    const isThirteenHundred = window.innerHeight < 658;
    return (
        <div>
            <Dialog
                aria-labelledby="uploading-title"
                open={true}
                fullWidth
                maxWidth="md"
            >
                <DialogTitleTMLC>
                    <div className='col-lg-12' style={{ paddingLeft: 0, display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <div className="col-xs-2 col-sm-2 col-lg-8 col-md-12 pt-3">Day Breakup</div>
                        <div style={{ float: "right", marginRight: "-5px" }}>
                            <IconButton style={{ color: "white", marginRight: "-1.4em" }} aria-label="close" onClick={() => props.closeDayBreakupPopup()}>
                                <CloseIcon />
                            </IconButton>
                        </div>
                    </div>
                </DialogTitleTMLC>
                <DialogContent dividers>
                    <div className='row1'>
                        <div className='col-lg-12 col-md-12 col-sm-12' style={{ height: '42px', backgroundColor: '#F6F6F6', }}>
                            <div className='col-lg-4 col-md-4 col-sm-4 pt-sm'>Date</div>
                            {/* <div className='col-lg-4 col-md-4 col-sm-4 pt-sm'>Description</div> */}
                            <div className='col-lg-4 col-md-4 col-sm-4 pt-sm'>Status</div>
                            <div className='col-lg-4 col-md-4 col-sm-4 pt-sm' style={{ paddingLeft: 24 }} >Day Breakup</div>
                        </div>
                        {
                            dayBreakupDates?.map((item, inx) => {
                                return (
                                    <div className='col-lg-12 col-md-12 col-sm-12' style={{ height: '42px', borderBottom: '1px solid #F6F6F6' }} key={inx}>
                                        <div className='col-lg-4 col-md-4 col-sm-4  pt-sm' style={{ opacity: item.comments === 'COUNTED' ? '1' : '0.4' }}>{item.date} | {item.symbol}</div>
                                        <div className='col-lg-4 col-md-4 col-sm-4  pt-sm' style={{ opacity: item.comments === 'COUNTED' ? '1' : '0.4' }}>{item.comments === 'COUNTED' ? props.leaveName : getTitleCase(item.comments)}</div>
                                        <div className='col-lg-4 col-md-4 col-sm-4  pt-sm' style={{ paddingLeft: 0 }}>
                                            <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", marginTop: "-.3rem" }}>
                                                {/* <div className={leaveClusterStart.q1 ? 'col-lg-2 col-md-2 col-sm-2 col-xs-2 ml-sm cluster-select-leave-applocation-block-select day-break-first-block-margin' : 'col-lg-2 col-md-2 col-sm-2 col-xs-2 ml-sm cluster-select-leave-applocation-block-unselect day-break-first-block-margin'} onClick={() => selectDayLeaveStart(1, leaveClusterStart.q1)} /> */}
                                                <div
                                                    style={{
                                                        backgroundColor: getBackgroundBreakCss(item.leaveCluster && item.leaveCluster.q1, item.comments, item.color),
                                                        height: isThirteenHundred || isFourteenHundred ? "1.4rem" : "1.8rem",
                                                        width: isThirteenHundred || isFourteenHundred ? "1.4rem" : "1.8rem",
                                                        cursor: 'default',
                                                    }}
                                                    className={`${getDayBreakCss(item.comments)} ml-sm`}
                                                // onClick={() => selectDayLeave(1, item.leaveCluster.q1, inx)}
                                                />
                                                <div
                                                    style={{
                                                        backgroundColor: getBackgroundBreakCss(item.leaveCluster && item.leaveCluster.q2, item.comments, item.color),
                                                        height: isThirteenHundred || isFourteenHundred ? "1.4rem" : "1.8rem",
                                                        width: isThirteenHundred || isFourteenHundred ? "1.4rem" : "1.8rem",
                                                        cursor: 'default',
                                                    }}
                                                    className={getDayBreakCss(item.comments)}
                                                // onClick={() => selectDayLeave(1, item.leaveCluster.q2, inx)}
                                                />
                                                <div
                                                    style={{
                                                        backgroundColor: getBackgroundBreakCss(item.leaveCluster && item.leaveCluster.q3, item.comments, item.color),
                                                        height: isThirteenHundred || isFourteenHundred ? "1.4rem" : "1.8rem",
                                                        width: isThirteenHundred || isFourteenHundred ? "1.4rem" : "1.8rem",
                                                        cursor: 'default',
                                                    }}
                                                    className={getDayBreakCss(item.comments)}
                                                // onClick={() => selectDayLeave(1, item.leaveCluster.q3, inx)}
                                                />
                                                <div
                                                    style={{
                                                        backgroundColor: getBackgroundBreakCss(item.leaveCluster && item.leaveCluster.q4, item.comments, item.color),
                                                        height: isThirteenHundred || isFourteenHundred ? "1.4rem" : "1.8rem",
                                                        width: isThirteenHundred || isFourteenHundred ? "1.4rem" : "1.8rem",
                                                        cursor: 'default',
                                                    }}
                                                    className={getDayBreakCss(item.comments)}
                                                // onClick={() => selectDayLeave(1, item.leaveCluster.q4, inx)}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                )

                            })
                        }
                    </div>
                </DialogContent>
                <DialogActions>
                    <div className='col-lg-12 col-md-2 col-sm-12 col-xs-12 pb-sm'>
                        <div style={{ float: "right" }}>
                            <button
                                className="leave-cancel-apply-button"
                                onClick={() => props.closeDayBreakupPopup()}
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </DialogActions>
            </Dialog>
        </div>
    )
}


const mapStateToProps = ({ meetingcategory, lnareducer, auth, meetingmaster }) => {
    const { authUser } = auth;
    const { categoryName } = meetingcategory;
    const { othersCheckAvailablity } = meetingmaster;
    const { leaveType, leaveStartDate, leaveEndDate, leaveReason, notificationTo, reliever, leaveTypeData, relieverData, leaveApplyStatus, dayBreakBackgroundColor } = lnareducer;
    return {
        categoryName,
        leaveType, leaveStartDate, leaveEndDate, leaveReason, notificationTo, reliever, authUser, leaveTypeData, othersCheckAvailablity, relieverData, leaveApplyStatus, dayBreakBackgroundColor
    }
}

export default connect(mapStateToProps, {})(ViewMoreDayBreakup)
