import React, { useState, useRef } from "react";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import Leavetabs from "../leavesbalance/leavetabs";
import { cracterLimit } from "../../../../app/other/commonfunction";

function ApprovalSubmit(props) {
  const { rejectReason } = props;
  const textareaObj = useRef(null);

  const onInput = (e) => {
    try {
      if (textareaObj !== null && typeof textareaObj !== "undefined") {
        textareaObj.current.respectiveElement.style.height = "auto";
        textareaObj.current.respectiveElement.style.minHeight = "100px";
        textareaObj.current.respectiveElement.style.height = `${textareaObj.current.respectiveElement.scrollHeight}px`;
        textareaObj.current.respectiveElement.style.maxHeight = "100px";
        cracterLimit(e, "leaveAppReasonCount", "500");
      }
    } catch (error) {}
  };
  const getTextAreaHeight = () => {
    switch (window.innerHeight) {
      case 969:
        return window.innerHeight - 402;
      case 937:
        return window.innerHeight - 402;
      case 625:
        return window.innerHeight - 405;
      case 657:
        return window.innerHeight - 405;

      default:
        break;
    }
  };
  return (
    <>
      {props.isReject ? (
        <>
          <div className="leave-approval-submit-header p-md">{props.title}</div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "flex-end",
              height: props.height - 250,
            }}
          >
            <div
              className="p-md"
              id={`${props.type}TextArea`}
              style={{ height: getTextAreaHeight(), width: "100%" }}
            >
              <TextBoxComponent
                id={props.type}
                height={300}
                placeholder="Enter a reason to reject *"
                cssClass="e-outline textarea"
                floatLabelType="Auto"
                input={onInput}
                ref={textareaObj}
                name={`${props.type}Err`}
                data-msg-containerid={`${props.type}Err`}
                multiline
                inputProps={{
                  required: true,
                  minLength: 7,
                }}
                htmlAttributes={{ maxlength: 500 }}
                onChange={props.handleTextArea}
                value={rejectReason}
              />
              <div
                id={`${props.type}Err`}
                className="e-input-group e-float-icon-left error-div"
              >
                {props.rejectReasonErr}
              </div>
              <div
                id="leaveAppReasonCount"
                style={{
                  float: "right",
                  fontSize: 13,
                  margin: "-15px 15px 8px 0px",
                }}
                className=" wordCount"
              />
            </div>
          </div>

          <div
            className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 p-md"
            style={{ borderTop: "1px solid #F2F2F2", textAlign: "right" }}
          >
            <button
              onClick={() => props.handleReject(rejectReason)}
              className={`leave-approval-approval-button ${
                rejectReason.trim().length <= 0
                  ? "leave-approval-disable-button"
                  : ""
              }`}
              disabled={rejectReason.trim().length <= 0}
            >
              Submit
            </button>
          </div>
        </>
      ) : (
        <>
          <div
          // style={{ marginTop: 14 }}
          >
            <Leavetabs
              type={
                props.type === "leaveTransferApprovalSubmit" ||
                props.type === "compOffApprovalSubmit"
                  ? "approvalsubmit"
                  : props.type === "leaveApprovalSubmit" ||
                    props.type === "specialAttendanceApprovalSubmit"
                  ? "leaveApprovalSubmit"
                  : props.type
              }
              selectedUserId={props.selectedUserId}
              height={props.height}
              from={"approvalScreen"}
            />
          </div>
        </>
      )}
    </>
  );
}

export default ApprovalSubmit;
