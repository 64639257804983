
import axios from 'axios';
import { urlConfig } from '../../config/config';
import { NEW_CANDIDATE_UPLOAD, EMPLOYEE_UPLOAD } from '../../redux/constant/entryexit/candidateconstant';
import { encryptMethod } from "../../app/other/commonfunction";


function errorHandler(reject, error) {
    console.dir(error);
    reject("operation failed");
}

//Add By :- Suresh R. Sargam to candidate document information.
//Added one condition. 
function getDocuments(data) {
    const { authUser, cand_emp_id, doccatid, candtype } = data

    let apiurl;
    switch (candtype) {
        case NEW_CANDIDATE_UPLOAD: // Get document information for candidate uploading
            apiurl = encryptMethod(cand_emp_id.toString()) + '/' + doccatid; break;
        case EMPLOYEE_UPLOAD: // Get document information for Employee
            apiurl = 'E/' + encryptMethod(cand_emp_id.toString()) + '/' + encryptMethod(authUser.toString()) + "/" + encryptMethod(doccatid.toString()); break;
        default: // Get document information for candidate
            apiurl = 'C/' + encryptMethod(cand_emp_id.toString()) + '/' + encryptMethod(authUser.toString()) + "/" + encryptMethod(doccatid.toString());
    }
    return new Promise(
        async function onThen(resolve, reject) {
            // eslint-disable-next-line
            await axios.get(urlConfig.tmlcApiUrl + 'document/' + apiurl,
                {
                    headers: {
                        'authorization': localStorage.getItem('jwtToken'),
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                }
            ).then(function (response) {
                if (response != null) {
                    return resolve(response.data)
                }
            }).catch(function (error) {
                errorHandler(reject, error.response.data.message)
                return null;
            });
        }
    )
}

//Add By :- Suresh R. Sargam to candidate document comments.
function getDocumentComments(data) {
    const { authUser, doccatid } = data
    const apiurl = '/' + authUser + "/" + doccatid;
    return new Promise(
        async function onThen(resolve, reject) {
            // eslint-disable-next-line
            await axios.get(urlConfig.tmlcApiUrl + 'document/comments' + apiurl,
                {
                    headers: {
                        'authorization': localStorage.getItem('jwtToken'),
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                }
            ).then(function (getDocumentCommentsResponse) {
                if (getDocumentCommentsResponse != null) {
                    return resolve(getDocumentCommentsResponse.data)
                }
            }).catch(function (error) {
                errorHandler(reject, error.response.data.message)
                return null;
            });
        }
    )
}

const addCandidateService =
{
    getDocuments: getDocuments,
    getDocumentComments: getDocumentComments
};

export default addCandidateService
