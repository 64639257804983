// Author:- Prashant waphare
// Date:- 17-02-2020
// Description:- This Component use for show setting of time zone and time scale.
// Modifyed by: Vivek Khodade
// Description: Added new UI css in time-scale and time-zone and default time scale fuctionality.

import * as React from "react";
import {
  ListBoxComponent,
  // ComboBoxComponent,
} from "@syncfusion/ej2-react-dropdowns";
import { connect } from "react-redux";
import { RadioButtonComponent } from "@syncfusion/ej2-react-buttons";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  CommandColumn,
} from "@syncfusion/ej2-react-grids";
import PropTypes from "prop-types";
import { Button } from "@material-ui/core";
import { encrypt, startOfWeek, getNotify, dencrypt } from '../../app/other/commonfunction';
import { TabComponent } from "@syncfusion/ej2-react-navigations";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import NotificationContainer from "../../components/notification/notifycontainer";
import {
  faArrowAltRight,
  faArrowAltLeft,
  faEdit,
  faTrashAlt,
} from "@fortawesome/pro-light-svg-icons";
import NotificationSettings from "./notificationsettings";
import ConfirmDialog from "../confirmdialog/confirmdialog";
import { updateMeetingBookingState } from "../../redux/actions/meetingbookingaction";
import {
  saveSettingConfig,
  addEditWorkDaySettings,
  addNotificationSettings,
} from "../../redux/actions/settingaction";
import {
  timeScaleRadio,
  defTimeZone,
  defaultTimeScaleConfig,
} from "../../config/config";
import services from "../../services/services";
import {
  SUCCESS,
  maxTimeZoneSelection,
  minTimeZoneSelection,
  timeZoneConfigSuccessfully,
} from "../../assets/constants/constants";
import WorkDaySettings from './workday';
library.add(faArrowAltRight, faArrowAltLeft, faEdit, faTrashAlt);
let cssHide=`
.e-listboxtool-wrapper .e-listbox-wrapper {
  -ms-flex: 1;
  flex: 1;
  width: 332px;
}
`;
class DragAndDrop extends React.Component {
  constructor() {
    super();
    this.state = {
      open: false,
      button1Caption: "Yes",
      button2Caption: "No",
      title: "",
      message: "",
      timeScale: null,
      stateSelectedTimeZone: [],
      stateTimeZone: [],
      defaultTimeScale: null,
      change: 0,
      shiftStartDateTime: null,
      shiftEndDateTime: null,
      shiftStartTime: null,
      shiftEndTime: null,
      openSetting: false,
      timeZoneForWorkDay: "",
      workDaySettingList: [],
      editFlag: "",
      stateWorkDayId: "",
      notificationForEveryMeeting: [],
      notificationForScheduledMeeting: [],
      editDefault: "",
      asOnSheduled: false,
      asOnCancelled: false,
      asChangeHappen: false,
      userLoginId: "",
      templateGridData: [],
    };
    this.radioval = null;
    this.defalttime = null;
    this.dataB = [];
    this.fields = { text: "Name", key: "Name" };
    this.time_zone = [];
    this.selected_time_zone = [];
    this.config_time_zone = [];
    this.userDetails = [];
    this.templateList = [];
    this.onCommandClick = this.onCommandClick.bind(this);
    this.timeScaleInterval = this.timeScaleInterval.bind(this);
    this.toolbarSettings = { items: ["moveTo", "moveFrom"] };
    this.commands = [
      {
        type: "Delete",
        buttonOption: { iconCss: "e-icons e-delete", className: "e-flat" },
      },
    ];
    this.editSettings = { allowDeleting: false };
    this.deleteTemplateId = 0;
    this.handleOnChangeHappen = this.handleOnChangeHappen.bind(this);
    this.handleOsOnCancelled = this.handleOsOnCancelled.bind(this);
    this.handleOnSheduled = this.handleOnSheduled.bind(this);
  }
  async componentDidMount() {
    const { authUser } = this.props;
    var elems = document.querySelectorAll(".dropdown-menu-right");

    [].forEach.call(elems, function (el) {
      el.className = el.className.replace("dropdown-menu-right", "");
    });

    let comp;
    let i;
    this.setState({ progress: true, userLoginId: authUser });
    const mdtScheduleDate = new Date();
    const pStartDate = Math.floor(// NOSONAR
      new Date(startOfWeek(mdtScheduleDate).getTime() / 1000.0)
    );

    this.templateList = await services.getMeetingTemplateList(authUser);
    this.setState({ templateGridData: this.templateList });
    this.templateList.result.map((ele, index) => {
      return (ele.srId = index + 1);//NOSONAR
    });
    const settingDetails = services.getUserSetting(
      authUser,
      authUser,
      pStartDate.toString()
    );
    settingDetails.then((result) => {
      this.userDetails = result;
      this.defaultTimeScale = this.userDetails.TimeScale;
      this.defalttime = this.userDetails.PriTz;
      this.setState({
        timeScale: this.userDetails.TimeScale,
        defaultTimeScale: this.userDetails.TimeScale,
      });
      if (result !== null) this.userSetting = result;

      const tz = result.Def_TZnm.split(",");
      const tznm = tz.map((element) => {
        return { Name: element.split("_")[0], value: element.split("_")[1] };
      });
      this.userSetting.tznm = tznm;
      localStorage.setItem("def_timezone", encrypt(result.Def_TimeZone));
      localStorage.setItem("def_locale", result.Locale);

      if (result.Locale !== this.state.locale) {
        this.setState({ locale: result.Locale });
      }
      const { timeZoneSetting } = this.props;
      // eslint-disable-next-line
      for (comp = 0; comp < timeZoneSetting.length; comp++) {
        if (
          this.userSetting.Def_TZnm.indexOf(timeZoneSetting[comp].Name) > -1
        ) {
          this.selected_time_zone.push(timeZoneSetting[comp].Name);
        } else {
          this.time_zone.push(timeZoneSetting[comp].Name);
        }
      }
      // eslint-disable-next-line
      for (i = 0; i < tznm.length; i++) {
        this.config_time_zone.push(tznm[i].Name);
      }
      this.setState({
        stateSelectedTimeZone: this.config_time_zone,
        stateTimeZone: this.time_zone,
      });
    });
    setTimeout(() => {
      const ele = document.getElementsByClassName("e-headercelldiv");
      // eslint-disable-next-line
      for (let x of ele) {
        if (x.innerText === "Template Name") {
          x.classList.add("grid-header-left");
        }
      }
    }, 1000);
  }
  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.getNotifyFlag === true) {
      this.closeSettingTab();
      this.props.updateMeetingBookingState({ getNotifyFlag: false });
    }
  }

  saveChanges() {
    const { authUser } = this.props;
    let payloaddata = {};
    const finalListselectedTimeZone = this.listObj2.listData.length;
    if (finalListselectedTimeZone <= 0) {
      document.getElementById("timezoneval").innerHTML = minTimeZoneSelection;
    } else if (
      finalListselectedTimeZone >= 1 &&
      finalListselectedTimeZone <= 3
    ) {
      document.getElementById("timezoneval").innerHTML = "";
      if (this.state.timeScale !== null) {
        payloaddata = {
          userid: authUser,
          id: authUser,
          // time_scale: this.state.timeScale,
          time_zone: this.listObj2.listData.toString(),
        };
        getNotify(SUCCESS, timeZoneConfigSuccessfully);
        // eslint-disable-next-line
        this.props.saveSettingConfig(payloaddata);
        // eslint-disable-next-line
        this.props.hideSetting();
      }
    } else {
      document.getElementById("timezoneval").innerHTML = maxTimeZoneSelection;
    }
  }

  saveTimeScaleChanges() {
    const { authUser } = this.props;
    const { timeScale } = this.state;
    let payloaddata = {};
    document.getElementById("timezoneval").innerHTML = "";
    if (timeScale !== null) {
      payloaddata = {
        userid: authUser,
        id: authUser,
        time_scale: timeScale,
      };
      getNotify(SUCCESS, timeZoneConfigSuccessfully);
      // eslint-disable-next-line
      this.props.saveSettingConfig(payloaddata);
      // eslint-disable-next-line
      this.props.hideSetting();
    } else {
      payloaddata = {
        userid: authUser,
        id: authUser,
        time_scale: this.userSetting.TimeScale,
      };
      getNotify(SUCCESS, timeZoneConfigSuccessfully);
      // eslint-disable-next-line
      this.props.saveSettingConfig(payloaddata);
      // eslint-disable-next-line
      this.props.hideSetting();
    }
  }

  reSetConfig = () => {
    document.getElementById("timezoneval").innerHTML = "";
    const { timeZoneSetting } = this.props;
    this.reset_time_zone = [];
    let i;
    // eslint-disable-next-line
    for (i = 0; i < timeZoneSetting.length; i++) {
      if (defTimeZone.timezone.indexOf(timeZoneSetting[i].Name) > -1) {
        this.selected_time_zone.push(timeZoneSetting[i].Name);
      } else {
        this.reset_time_zone.push(timeZoneSetting[i].Name);
      }
    }

    this.setState(
      {
        stateSelectedTimeZone: [],
        stateTimeZone: [],
      },
      () => {
        setTimeout(() => {
          this.setState({
            stateSelectedTimeZone: defTimeZone.timezone,
            stateTimeZone: this.reset_time_zone,
          })
        }, 100)

      }
    );
  };

  timescaleReSetConfig = () => {
    document.getElementById("timezoneval").innerHTML = "";
    this.setState({
      timeScale: defaultTimeScaleConfig,
    });
  };

  timeScaleInterval = (e) => {
    this.setState({ timeScale: e.value });
  };

  async onCommandClick(args) {
    this.deleteTemplateId = args.rowData.int_meeting_template_uid;
    this.setState({ open: true });
  }

  handleConfirmYes = () => {
    const { authUser } = this.props;
    this.templateList = services.deleteMeetingTemplateList(
      authUser,
      this.deleteTemplateId
    );
    this.templateList.then((dataResult) => {
      this.setState({ templateGridData: dataResult });
      this.templateList.result = dataResult.result.map((ele, index) => {
        return (ele.srId = index + 1);//NOSONAR
      });
    });
    this.deleteTemplateId = 0;
    
    setTimeout(async () => {
      let templates=[];
      templates = await services.getMeetingTemplateList(authUser);
      this.props.updateMeetingBookingState({ templateListData: templates });
  
      this.setState({ open: false });
    }, 1000);
  }

  handleConfirmNo = () => {
    this.deleteTemplateId = 0;
    this.setState({ open: false });
  };

  closeSettingTab() {
    if (document.getElementById("workDaySettings") !== null && typeof document.getElementById("workDaySettings") !== 'undefined') {
      if (typeof document.getElementById("workDaySettings").ej2_instances !== "undefined") {
        setTimeout(() => {
          document.getElementById("workDaySettings").ej2_instances[0].closeEdit()
        }, 200)
      }
    }
    setTimeout(() => {
      this.props.hideSetting();
      // eslint-disable-next-line
      this.props.updateMeetingBookingState({ getNotifyFlag: false, workDayStatus: "" });
      this.closeAgendaPopup();
    }, 300)

  }
  // Author By:- Prashant Waphare | 20-08-2020 | use for close tooltip on click button or icon.
  closeAgendaPopup = () => {
    try {
      if (document.getElementsByClassName("e-tooltip-wrap").length > 0) {
        const elements = document.getElementsByClassName("customtooltip");
        while (elements.length > 0) {
          elements[0].parentNode.removeChild(elements[0]);
        }
      }
    } catch (error) { }
  };

  rowDataBound(args) {
    if (args.data.workStartDate === null && args.data.workEndDate === null) {
      args.row.classList.add("work-day-setting-grid");
    }
  }

  handleOnChangeHappen() {
    const { asChangeHappen } = this.state;

    this.setState({ asChangeHappen: !asChangeHappen });
    document.getElementById("notificationForEvent").innerHTML = "";
  }

  handleOsOnCancelled() {
    const { asOnCancelled } = this.state;
    this.setState({ asOnCancelled: !asOnCancelled });
    document.getElementById("notificationForEvent").innerHTML = "";
  }

  handleOnSheduled() {
    const { asOnSheduled } = this.state;
    this.setState({ asOnSheduled: !asOnSheduled });
    document.getElementById("notificationForEvent").innerHTML = "";
  }
  deleteElement = ()=>{
    if(document.getElementsByClassName('e-listbox-tool')!==null){
      if(document.getElementsByClassName('e-listbox-tool').length > 1){
        document.getElementsByClassName('e-listbox-tool')[1].style.display='none';
      }
    }
  }
  render() {
    const {
      workDaySettingList,
      open,
      button2Caption,
      button1Caption,
      timeScale,
    } = this.state;
    const { timeZoneSetting } = this.props;
    return (
      <div className="settingsBox">
        <div className='head'>Settings
          {/* <DialogTitleTMLC id="uploading-title" className="head"> */}
          {/* Settings */}
          <div style={{ float: "right" }}>
            <IconButton
              style={{
                color: "white",
                marginRight: "-13px",
                marginTop: "-5px",
              }}
              aria-label="close"
              onClick={this.closeSettingTab.bind(this)}
            >
              <CloseIcon />
            </IconButton>
          </div>
        </div>

        {/* </DialogTitleTMLC> */}
        <NotificationContainer />



        <TabComponent id="defaultTab">
          <div className="e-tab-header" style={{ width: '95% !important', padding: "0px 6px" }} >
            <div> Time Scale </div>
            <div> Time Zone </div>
            <div> Templates </div>
            {
              dencrypt(localStorage.getItem("notificationSettingAccess")) === 1 &&
              <div> Notification Settings </div>
            }            
            <div> Work Day Setting </div>
          </div>
          <div className="e-content">
            {/* TAB - TIME SCALE */}
            <div className="time-scale-settings ">
              <div>
                <div
                  style={{
                    color: "black",
                    fontSize: 14,
                    marginBottom: 3,
                    fontWeight: "500",
                  }}
                >
                  Select Calendar Time Scale (Minutes)
                </div>
                <div className="col-md-12">
                  <div className="row">
                    {timeScaleRadio.map((val, index) => (
                      <div className="col-md-2" key={`div_${val}`}>
                        <RadioButtonComponent
                          key={`key_${val}`}
                          id={`id_${val}`}
                          style={{ color: "#fff" }}
                          checked={timeScale === val}
                          label={val}
                          change={this.timeScaleInterval}
                          name="timescale"
                          value={val}
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div style={{ float: "right", margin: "25px -10px 10px 0px" }}>
                <table className='pt-sm pb-sm'>
                  <tbody>
                    <tr>
                      <td>
                        <Button
                          onClick={this.timescaleReSetConfig.bind(this)}
                          color="secondary"
                          variant="contained"
                          title="Reset Default"
                        >
                          Reset Default
                        </Button>
                      </td>
                      <td>
                        <Button
                          style={{ marginLeft: 10 }}
                          onClick={this.saveTimeScaleChanges.bind(this)}
                          color="primary"
                          variant="contained"
                          title="Save Settings"
                        >
                          Save Settings
                        </Button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            {/* TAB - TIME ZONE */}
            <div className="settings-time-zone  ">
              <div className="control-pane">
                <div className="control-section">
                  <style>{cssHide}</style>
                  <div className="dual-list-wrapper">
                    <div className="dual-list-groupa">
                      <style>{cssHide}</style>
                      <h5 style={{ color: "black" }}>Time Zone</h5>
                      <ListBoxComponent
                        // style={{backgroundColor:'#fff'}}
                        ref={(scope) => {
                          this.listObj1 = scope;
                        }}
                        dataSource={this.state.stateTimeZone}
                        fields={this.fields}
                        height="330px"
                        scope="#combined-listbox"
                        // allowDragAndDrop={true}
                        toolbarSettings={this.toolbarSettings}
                        id='allTimeZone'
                        created={this.deleteElement}
                      />
                    </div>
                    <div className="dual-list-groupb">
                      <h5 style={{ color: "black", marginLeft: 5 }}>
                        Selected Time Zone
                      </h5>
                      <ListBoxComponent
                        id="combined-listbox"
                        // style={{backgroundColor:'#fff'}}
                        ref={(scope) => {
                          this.listObj2 = scope;
                        }}
                        dataSource={this.state.stateSelectedTimeZone}
                        height="330px"
                        fields={this.fields}
                        allowDragAndDrop
                      // select = {this.onChangeValidation.bind(this)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div id="timezoneval" className="setting-error-message">
                {" "}
              </div>
              <div style={{ float: "right", margin: "15px -10px 10px 0px" }}>
                <table className='pt-sm pb-sm'>
                  <tbody>
                    <tr>
                      <td>
                        <Button
                          onClick={this.reSetConfig.bind(this)}
                          color="secondary"
                          variant="contained"
                          title="Reset Default"
                        >
                          Reset Default
                        </Button>
                      </td>
                      <td>
                        <Button
                          style={{ marginLeft: 10 }}
                          onClick={this.saveChanges.bind(this)}
                          color="primary"
                          variant="contained"
                          title="Save Settings"
                        >
                          Save Settings
                        </Button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            {/* TAB - TEMPLATE */}
            <div className="settings-template  " style={{ padding: '10px' }}>
              <div className="control-pane">
                <div className="control-section">
                  <GridComponent
                    ref={(g) => (this.grid = g)}
                    dataSource={this.state.templateGridData.result}
                    editSettings={this.editSettings}
                    commandClick={this.onCommandClick}
                    className="special-header"
                  >
                    <ColumnsDirective>
                      <ColumnDirective
                        field="srId"
                        headerText="Sr. No."
                        width="20%"
                        textAlign="Center"
                      />
                      <ColumnDirective
                        field="var_meeting_template_name"
                        headerText="Template Name"
                        width="60%"
                        className="e-columnheader"
                      />
                      <ColumnDirective
                        field="int_meeting_template_uid"
                        headerText="Action"
                        width="20%"
                        commands={this.commands}
                        textAlign="Center"
                      />
                    </ColumnsDirective>
                    <Inject services={[CommandColumn]} />
                  </GridComponent>
                </div>
              </div>
            </div>
            {/* Notification Settings */}
            {
              dencrypt(localStorage.getItem("notificationSettingAccess")) === 1 &&
              <div className="notification-view ">
                <NotificationSettings />
              </div>
            }
            {/* <div className="notification-view ">
              <NotificationSettings />
            </div> */}

            {/* Author By:- Prashant Waphare |  Add work day shift time */}
            <div className="settings-shift  ">
              <WorkDaySettings
                workDaySettingList={workDaySettingList}
                timeZoneSetting={timeZoneSetting}
                hideSetting={this.closeSettingTab}
              // getWorkDayList={this.getWorkDayList} 

              />
            </div>
          </div>
        </TabComponent>
        <ConfirmDialog
          open={open}
          handleConfirmYes={this.handleConfirmYes}
          handleConfirmNo={this.handleConfirmNo}
          handleConfirmClose={this.handleConfirmNo}
          button2Caption={button2Caption}
          button1Caption={button1Caption}
          title={"Delete Template"}
          message={"Are you sure, you want to delete this template"}
        />
      </div>
    );
  }
}
DragAndDrop.propTypes = {
  // eslint-disable-next-line
  timeZoneSetting: PropTypes.arrayOf(Object),
  authUser: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
};

DragAndDrop.defaultProps = {
  timeZoneSetting: [],
  authUser: "",
};
const mapStateToProps = ({ auth, meetingmaster, meetingbooking }) => {
  const { authUser } = auth;
  const { timeZone } = meetingmaster;
  const { getNotifyFlag, workDayStatus } = meetingbooking;
  return { authUser, timeZone, getNotifyFlag, workDayStatus };
};
export default connect(mapStateToProps, {
  saveSettingConfig,
  addEditWorkDaySettings,
  updateMeetingBookingState,
  addNotificationSettings,
})(DragAndDrop);
