import React, { useState, useEffect } from 'react';
import { applyLeave } from '../../redux/actions/lna/lnaaction';
import { connect } from 'react-redux';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';


const HolidayListSingleHoliday = (props) => {
    const { holiday, holidayDateInHours, todayTimeInHours, selectedListItem, isOneTwentyFiveScale, dateStandard, handleSelectedItem, applyFestivalHoliday, handleApplyFestivalHoliday,
        addToCheckState, removeFromCheckState } = props;
    const [applyHolidayFromInHours, setApplyHolidayFromInHours] = useState(null);

    useEffect(() => {
        var applyHolidayFrom = new Date(holiday.applyHolidayFrom);
        applyHolidayFrom.setHours(0, 0, 0);
        setApplyHolidayFromInHours(applyHolidayFrom.getTime());
    }, []);

    /**
   * @author Keval Charla
   * @description this will fire when checkbox is clicked
   */

    const getValueOfCheckBox = (e, holidayState) => {
        if (holidayState && e) {
            if (e.checked) {
                handleApplyFestivalHoliday("5");
                addToCheckState(holidayState);
            } else {
                handleApplyFestivalHoliday("5");
                removeFromCheckState(holidayState);
            }
        }
    }

    return (
        <div
            className="holidayCard ml-md mr-md mt-sm mb-sm p-sm"
            key={holiday.id}
            onClick={() => handleSelectedItem(holiday.id)}
            style={{
                borderLeft: `8px solid ${holiday.primaryColor}`
            }}
        >
            <div className="holidayTitleAndCheckbox">
                <div className="holidayTitle" style={{ color: holidayDateInHours < todayTimeInHours ? "#C8C8C8" : holiday.holidayType === "Allocated Festival Holiday" ? "#C8C8C8" : "" /** NOSONAR */, fontSize: isOneTwentyFiveScale ? ".9rem" : "1rem" }}>
                    {holiday.holidayName === "Working Saturday" ? "Working Day" : holiday.holidayName}
                </div>
                {(holidayDateInHours >= applyHolidayFromInHours) && (applyFestivalHoliday === "1" || applyFestivalHoliday === "2" || applyFestivalHoliday === "4" || applyFestivalHoliday === "5") && (holiday.holidayType === "Availed Festival Holiday" || holiday.holidayType === "Allocated Festival Holiday") && <div className="holidayCheckbox">
                    <CheckBoxComponent
                        checked={holiday.holidayType === "Availed Festival Holiday" ? true : false}
                        delayUpdate={true}
                        id={`holiday-${holiday.id}`}
                        value={holiday.id}
                        change={(e) => getValueOfCheckBox(e, holiday)}
                    />
                </div>}
            </div>
            <div className="holidayDateAndType">
                <div className="holidayDate">
                    <div className="holidayText" style={{ color: holidayDateInHours < todayTimeInHours ? "#C8C8C8" : selectedListItem, fontSize: isOneTwentyFiveScale ? ".9rem" : "1rem" }}>
                        {dateStandard}
                    </div>
                </div>
                <div className="holidayYearAndType">
                    {holiday.holidayType}
                </div>
            </div>
        </div>
    )
}

const mapStateToProps = ({ auth, meetingmaster, lnareducer }) => {
    const { authUser } = auth;

    const { notificationTo, leaveReason, reliever, leaveApplicationId } = lnareducer;

    const { currentFinancialYearId } = meetingmaster;

    return {
        authUser, notificationTo, leaveReason, reliever, leaveApplicationId, currentFinancialYearId
    }
}

export default connect(mapStateToProps, { applyLeave })(HolidayListSingleHoliday);
