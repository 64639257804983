import React, { useEffect, useState } from "react";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import LnAServices from "../../services/lna/lnaservice";
import { FormValidator } from "@syncfusion/ej2-inputs";
import { connect } from "react-redux";

import ProgressBar from "../progressbar/progressbar";
import { addEditRoleClusterData, updateLnAData } from "../../redux/actions/lna/lnaaction";
import {
  pleaseenterroleName,
  regexOnlyLettersAndSpaces,
} from "../../assets/constants/constants";

const RoleCluster = (props) => {
  //NOSONAR
  const [state, setState] = useState({
    roleName: null,
    isActive: "1",
    masterItemUid: null,
    roleDescription: null
  });

  const validationOptions = {
    rules: {
      roleNameErr: {
        required: [true, pleaseenterroleName],
        regex: [regexOnlyLettersAndSpaces, pleaseenterroleName],
        minLength: [1, pleaseenterroleName],
        maxLength: [50, "Should be maximum of 50 characters only."]
      },
    },
  };
  const [initialLoad, setInitialLoad] = useState(false)

  let { authUser, editClusterId, openPopup } = props;


  useEffect(() => {
    setTimeout(() => {
      props.setSubmitClick(saveRoleClusterData);

    }, 100);
  }, [state]);

  useEffect(() => {
    props.updateLnAData({ popUpSource: 'Role Master' })
    getData()
  }, []);

  useEffect(() => {
    getData()
  }, [editClusterId, openPopup]);


  const getData = () => {

    if (openPopup === "add") {
      setState({
        roleName: null,
        isActive: "1",
        masterItemUid: null,
        roleDescription: null
        // type: 'ADD'
      });
    }
    else {
      editClusterId !== null &&
        (async () => {
          const roleCluster = await LnAServices.getUniqueRoleClusterData(
            authUser,
            editClusterId
          );
          setInitialLoad(true)
          setState({
            roleName: roleCluster.Result[0].roleName,
            isActive: roleCluster.Result[0].isActive,
            masterItemUid: roleCluster.Result[0].lnAMasterItemUid,
            roleDescription: roleCluster.Result[0].roleDescription
            // type: 'EDIT'
          });
          setInitialLoad(false)
          setTimeout(() => {
            const addFormObject = new FormValidator("#roleClusterForm", validationOptions);
            addFormObject.validate();
          }, 100);
        })()
    }
  }

  const inputChangeHandler = (e, unit) => {
    let options = {}
    switch (unit) {
      case "role":
        setState({
          ...state,
          roleName: e.value.trim(),
        });
        options = validationOptions.rules['roleNameErr']

        break;
      case "description":
        setState((prevState) => ({
          ...prevState,
          roleDescription: e.value && e.value.trim(),
        }));
        break;
      case "isActive":
        setState({
          ...state,
          isActive: e.checked === true ? "1" : "0",
        });
        break;
      default:
        break;
    }
    setTimeout(() => {
      const addFormObject = new FormValidator("#roleClusterForm", options);
      addFormObject.validate();
    }, 100);
  };


  useEffect(() => {
    if (props.lnaMasterFlag === "updated") {
      props.changePopUpHandler("close");
    }
  }, [props.lnaMasterFlag]);


  const saveRoleClusterData = () => {
    const {
      masterItemUid,
    } = state;

    const addFormObject = new FormValidator("#roleClusterForm", validationOptions);
    if (addFormObject.validate()) {
      setInitialLoad(true)
      let roleClusterSaveData = {
        userId: authUser,
        type: masterItemUid > 0 ? "EDIT" : "ADD",
        roleName,
        masterItemUid,
        roleDescription,
        isActive: state.isActive ? state.isActive : "1",
      };
      props.addEditRoleClusterData(roleClusterSaveData);
      setTimeout(() => {
        setInitialLoad(false)
      }, 10)
    }
  };

  const { roleName, roleDescription } = state;
  const descriptionTextBox = `.e-outline textarea, textarea.e-outline, .e-outline.e-float-input textarea, .e-outline.e-float-input.e-control-wrapper textarea, .e-outline.e-float-input.e-input-group textarea {
    box-sizing: border-box;
    min-height: 100px;
  }`


  return (
    <div id="roleClusterForm" style={{ height: "61.5vh" }}>
      <div style={{ padding: "20px" }}>
        <div
          className="col-xs-12 col-sm-12 col-lg-12 col-md-12  mt-lg1 atc-no-paddding"
        >
          <TextBoxComponent
            data-msg-containerid="roleNameErr"
            id="roleName"
            value={roleName}
            name="roleNameErr"
            autocomplete={'off'}
            placeholder="Role Name *"
            floatLabelType="Auto"
            cssClass="e-outline"
            onChange={(e) => inputChangeHandler(e, "role")}
            // blur={(e) => this.editBasicInFormation(e, "FirstName")}
            htmlAttributes={{ maxlength: 50 }}
          />
          <div
            id="roleNameErr"
            className="e-input-group e-float-icon-left error-div"
          />
        </div>

        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 mt-lg1 atc-no-paddding weekdayDescription">
          <style>{descriptionTextBox}</style>
          <TextBoxComponent
            style={{ minHeight: 100 }}
            id={'description'}
            placeholder={"Description "}
            cssClass="e-outline textarea"
            autocomplete={'off'}
            floatLabelType="Auto"
            name="descriptionErr"
            data-msg-containerid="descriptionErr"
            multiline
            inputProps={{
              required: true,
              minLength: 0,
            }}
            htmlAttributes={{ maxlength: 500 }}
            onChange={(e) => inputChangeHandler(e, "description")}
            value={roleDescription}
          />
        </div>

        <div className="loader-new1" id="spinnerContainer" >
          {initialLoad && <ProgressBar />}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = ({ auth, lnareducer }) => {
  const { authUser } = auth;
  const { lnaMasterFlag } = lnareducer;

  return { authUser, lnaMasterFlag };

};


export default connect(mapStateToProps, { addEditRoleClusterData, updateLnAData })(
  RoleCluster
);
