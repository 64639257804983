import React from "react";
import NotificationContainer from "../../../../components/notification/notifycontainer";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import OkrProductivityAllocationComponent from "../../../../components/okrproductiveallocation/okrproductivityallocationtabs";

class NewProductiveAllocation extends React.Component {
  constructor(props) {
    super(props);
    document.title = "OKR Add / View / Edit";
    this.state = {
      height:
        (window.innerHeight ||
          document.documentElement.clientHeight ||
          document.body.clientHeight) - 188,
    };
    this.onCreated = this.onCreated.bind(this);
  }

  onCreated() {
    setTimeout(() => {
      if (this.acrdnbasic !== null) {
        // eslint-disable-next-line
        for (let i = 0; i <= this.acrdnbasic.items.length - 1; i++) {
          this.acrdnbasic.items[i].expanded = true;
        }
      }
    }, 500);
  }

  componentDidMount = async () => {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
  };

  /**
   * Calculate & Update state of new dimensions
   */
  updateDimensions() {
    const updateHeight = window.innerHeight - 80;
    this.setState({ height: updateHeight });
  }

  render() {
    const { okrBusinessPopup, currentFinancialYearId } = this.props;
    const { height } = this.state;
    return (
      <div>
        <div style={{ backgroundColor: "#F4F3F5", overflowY: "auto" }}>
          {!okrBusinessPopup && <NotificationContainer />}
          <div />
          <div style={{ marginTop: 10, height }}>
            {/* <OkrProductivityAllocation /> */}
            {
              currentFinancialYearId !== null &&
            <OkrProductivityAllocationComponent />
            }
          </div>
        </div>
      </div>
    );
  }
}
NewProductiveAllocation.propTypes = {
  okrBusinessPopup: PropTypes.bool,
};
NewProductiveAllocation.defaultProps = {
  okrBusinessPopup: false,
};
const mapStateToProps = ({ okrreducer, meetingmaster }) => {
  const { okrBusinessPopup } = okrreducer;
  const { currentFinancialYearId } = meetingmaster;

  return { okrBusinessPopup, currentFinancialYearId };
};
export default connect(mapStateToProps, {})(
  NewProductiveAllocation
);
