/**
 * Author: Vivek Khodade.
 * Created:06-Jun-2020
 * Last Modify:06-Jun-2020
 * Modified By:Vivek Khodade
 */
export const RESONS_FOR_RESIGNATIONS = "resons_for_resignations";
export const ADD_UPDATE_SEPARATION = "add_update_separation";
export const SUBMIT_RESIGNATION_DATA = "sumbit_resignation_data";
export const SUBMIT_WITHDRAWAL_DATA = "sumbit_withdrawal_data";
export const SUBMIT_ABSCONDING_DATA = "sumbit_absconding_data";
export const SUBMIT_TERMINATION_DATA = "sumbit_termination_data";
export const SUBMIT_EXIT_CLEARANCE_DATA = "sumbit_exit_clearance_data";
export const SUBMIT_LWD_RESIGNATION_COMPANY_REQUEST_AND_NPS = "SUBMIT_LWD_RESIGNATION_COMPANY_REQUEST_AND_NPS_and_nps";

export const APPROVE_RESIGNATION = "approve_resignation";
export const APPROVE_WITHDRAWL = "approve_withdrawl";
export const INITIATE_CLEARANCE = "initiate_clearance";
export const UPDATE_ORGCHART_LIST = "update_orgchart_list"; 
export const SET_ORGCHART_DETAILS = "set_orgchart_details"; 
export const ADD_EDIT_COST_SHARING = "add_edit_cost_sharing";
export const MOVE_TO_ACTIVE = "move_to_active";
export const ADD_EDIT_OTHER_CALENDAR_ACCESS = "add_edit_other_calendar_access";

