import React, { Component } from 'react'
import { APPLYCOMPOFF } from '../../../redux/constant/meetingcategoryconstant'
import CompOffApplication from '../../../components/lnacomponents/leaves/compoff/compoff'
import '../../layout/lna/lna.css'
class CompOffContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      height: null
    };
  }
  componentDidMount = () => {
    window.addEventListener("resize", this.updateDimensions());
  };
  updateDimensions() {
    const updateheight = window.innerHeight - 95;
    this.setState({
      height: updateheight,
    });
  }

  render() {
    const { categoryName } = this.props;
    const { height } = this.state;

    return (
      <div className='attendance-container ml-md mr-md mb-md pt-0' style={{ height: height, backgroundColor: "#fff" }}>
        {
          categoryName === APPLYCOMPOFF &&
          <CompOffApplication />
        }
      </div>
    )
  }
}

export default CompOffContainer;
