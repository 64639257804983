import React, { useState, useEffect, useRef } from "react";
import { ComboBoxComponent } from "@syncfusion/ej2-react-dropdowns";
import { TextBoxComponent, NumericTextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { SwitchComponent } from '@syncfusion/ej2-react-buttons';
import { FormValidator } from "@syncfusion/ej2-inputs";
import { cracterLimit, getNotify } from '../../app/other/commonfunction';
import okrServices from "../../services/okr/okrservices";
import { savePerformancePotentialMaster, updateOKRData } from "../../redux/actions/okr/okractions";
import { connect } from "react-redux";
import {
  pleaseEnterPerformanceFY, pleaseEnterPerformanceName, pleaseEnterPotentialName, pleaseEnterValidPerformanceName, pleaseEnterValidPotentialName, pleaseSelectAllocationType, pleaseSelectPerformanceFrequency, PleaseSelectRatingScaler, WARNING
  , pleaseSelectWeightageFrom, pleaseSelectWeightageTo, pleaseSelectWeightageDeafualt, pleaseSelectWeightageVariable, regexOnlyAlphabetsAndSpaces
} from "../../assets/constants/constants";
import { PERFORMANCE, POTENTIAL } from "../../redux/constant/meetingcategoryconstant";
import ProgressBar from "../progressbar/progressbar";

const PerformanceMaster = (props) => {
  const { authUser, openPopup, masterId, updateOKRData, loading } = props; //NOSONAR
  const masterItemUid = props.masterItemId;

  const [state, setState] = useState({
    title: null,
    masterItemId: null,
    productivityName: null,
    allocationType: null,
    description: null,
    WeightageRangeFrom: null,
    WeightageRangeTo: null,
    defaultWeightage: null,
    weightedVariable: null,
    defaultWeightageEditable: true,
    frequency: null,
    rangeScale: null,
    fyId: props.currentFinancialYearId,
    isActive: "1",
    productivityMasterData: [],
  });
  const textareaObj = useRef(null);

  const [updateSave, setUpdateSave] = useState(false)

  useEffect(() => {
    getData();
  }, [openPopup, masterId, masterItemUid]);

  useEffect(() => {
    setTimeout(() => {
      props.setSubmitClick(onSaveData);
    }, 100);
  }, [state]);

  useEffect(() => {
    if (props.okrMasterFlag === "updated") {
      props.changePopUpHandler("close");
    }
  }, [props.okrMasterFlag]);

  useEffect(() => {
    if(updateSave===true){
    ["performanceNameErr",
    "allocationTypeErr",
    "weightageFromErr",
    "weightageToErr",
    "weightageDefaultErr",
    "weightageVariabeErr",
    "performanceRangeScaleErr",
    "performanceFYErr",
    "performanceFrequencyErr"]
    .forEach(err => { document.getElementById(err).innerHTML = "" });
  const {
    masterItemId,
    productivityName,
    allocationType,
    description,
    WeightageRangeFrom,
    WeightageRangeTo,
    defaultWeightage,
    weightedVariable,
    defaultWeightageEditable,
    frequency,
    rangeScale,
    fyId,
    isActive
  } = state;

  const options = {
    rules: {
      performanceNameErr: {
        required: masterId == PERFORMANCE ? [true, pleaseEnterPerformanceName] : [true, pleaseEnterPotentialName],
      },
      allocationTypeErr: {
        required: [true, pleaseSelectAllocationType],
      },
      weightageFromErr: {
        required: [true, pleaseSelectWeightageFrom],
      },
      weightageToErr: {
        required: [true, pleaseSelectWeightageTo],
      },
      weightageVariabeErr: {
        required: [true, pleaseSelectWeightageVariable],
      },

      performanceRangeScaleErr: {
        required: [true, PleaseSelectRatingScaler],
      },
      weightageDefaultErr : {
        required: [ true, pleaseSelectWeightageDeafualt],
      },
      performanceFYErr: {
        required: [true, pleaseEnterPerformanceFY],
      },
      performanceFrequencyErr: {
        required: [true, pleaseSelectPerformanceFrequency],
      },
    },
  };

  let validateDates = true;
  if (state.productivityName !== null) {
    let reg = /^([a-zA-z\s]{1,50})$/
    let validName = reg.test(state.productivityName)
    if (!validName && masterId == PERFORMANCE) {
      // document.getElementById("performanceNameErr").innerHTML =
      // pleaseEnterValidPerformanceName
      getNotify(WARNING, pleaseEnterValidPerformanceName)
      validateDates = false;

    }
    if (!validName && masterId == POTENTIAL) {
      getNotify(WARNING, pleaseEnterValidPotentialName)
        ;
      validateDates = false;
    }
  }

  if (state.WeightageRangeFrom > state.WeightageRangeTo) {
    getNotify(WARNING, "Weightage range from should be less than weightage range to")
    validateDates = false;
  }
  if ((state.defaultWeightage < state.WeightageRangeFrom || state.defaultWeightage > state.WeightageRangeTo) && (state.defaultWeightage !== null && state.defaultWeightage !== '')) {
    document.getElementById("weightageDefaultErr").innerHTML = "Default Weightage must be in the range of from and to"
    validateDates = false;
  }

  setTimeout(() => {
    const addFormObject = new FormValidator("#okrPerPotMasterForm", options);
    if (addFormObject.validate() && validateDates) {
      let productivityMasterData = {
        userId: authUser,
        type: masterItemId > 0 ? "EDIT" : "ADD",
        masterItemId,
        masterId,
        productivityName,
        allocationType,
        description,
        WeightageRangeFrom,
        WeightageRangeTo,
        defaultWeightage,
        weightedVariable,
        defaultWeightageEditable: defaultWeightageEditable ? '1' : '0',
        frequency,
        rangeScale,
        fyId,
        isActive
      };
      updateOKRData({ loading: true });
      props.savePerformancePotentialMaster(productivityMasterData);
      setUpdateSave(false)
    }else{
      setUpdateSave(false)
    }
  }, 300);}
  }, [updateSave === true])

  const getData = () => {

    ["performanceNameErr",
      "allocationTypeErr",
      "weightageFromErr",
      "weightageToErr",
      "weightageDefaultErr",
      "weightageVariabeErr",
      "performanceRangeScaleErr",
      "performanceFYErr",
      "performanceFrequencyErr"]
      .forEach(err => { document.getElementById(err).innerHTML = "" });
    onInput();
    if (openPopup == 'add') {
      updateOKRData({ loading: false });
      setState({
        title: null,
        masterItemId: null,
        productivityName: null,
        allocationType: null,
        description: null,
        WeightageRangeFrom: null,
        WeightageRangeTo: null,
        defaultWeightage: null,
        weightedVariable: null,
        defaultWeightageEditable: true,
        frequency: null,
        rangeScale: null,
        fyId: props.currentFinancialYearId,
        isActive: "1",
        productivityMasterData: [],
      });
    }
    else if (openPopup === 'edit') {
      updateOKRData({ loading: true });
      (
        async () => {
          const productivityMasterData = await okrServices.getOKRMaserData(
            authUser,
            masterId,
            masterItemUid
          );
          const {

            masterItemId,
            productivityName,
            allocationType,
            description,
            WeightageRangeFrom,
            WeightageRangeTo,
            defaultWeightage,
            weightedVariable,
            defaultWeightageEditable,
            frequency,
            rangeScale,
            fyId,
            isActive
          } = productivityMasterData.Result[0];

          let data = {
            masterItemId,
            productivityName,
            allocationType,
            description,
            WeightageRangeFrom,
            WeightageRangeTo,
            defaultWeightage,
            weightedVariable,
            defaultWeightageEditable: defaultWeightageEditable === '1' ? true : false,
            frequency,
            rangeScale,
            fyId,
            isActive
          };
          setState((preState) => ({ ...preState, ...data }));
          updateOKRData({ loading: false });
        }
      )();
    }
  }

  const inputChangeHandler = async (e, type) => {
    let options = {}

    switch (type) {

      case "productivityName":
        setState((prevState) => ({ ...prevState, productivityName: e.value.trim() }));

        if (masterId == PERFORMANCE) {
          options = {
            rules: {
              performanceNameErr: {
                required: [true, pleaseEnterValidPerformanceName],
                regex: [regexOnlyAlphabetsAndSpaces, pleaseEnterValidPerformanceName],
                minLength: [1, pleaseEnterValidPerformanceName],
                maxLength: [50, "Should be maximum of 50 alphabets only."]
              },
            }
          }
        }

        if (masterId == POTENTIAL) {
          options = {
            rules: {
              performanceNameErr: {
                required: [true, pleaseEnterValidPotentialName],
                regex: [regexOnlyAlphabetsAndSpaces, pleaseEnterValidPotentialName],
                minLength: [1, pleaseEnterValidPotentialName],
                maxLength: [50, "Should be maximum of 50 alphabets only."]
              },
            }
          }
        }

        break;
      case "allocationType":
        setState((prevState) => ({ ...prevState, allocationType: e.value, frequency:"" }));
        break;
      case "description":
        setState((prevState) => ({ ...prevState, description: e.value && e.value.trim() }));
        options = {
          rules: {
            descriptionErr: {
              maxLength: [500, "Should be maximum of 500 alphabets only."]
            },
          }
        }
        break;
      case "weightageFrom":
        setState((prevState) => ({ ...prevState, WeightageRangeFrom: e.value }));
        if (state.WeightageRangeFrom > 100) {
          document.getElementById("weightageFromErr").innerHTML =
            "Input value cannot exceed 100.";
        }
        document.getElementById("weightageFromErr").innerHTML = ""
        document.getElementById("weightageDefaultErr").innerHTML = ""

        break;
      case "weightageTo":
        setState((prevState) => ({ ...prevState, WeightageRangeTo: e.value }));
        document.getElementById("weightageToErr").innerHTML = ""
        document.getElementById("weightageDefaultErr").innerHTML = ""

        if (state.WeightageRangeFrom > e.value) {
          document.getElementById("weightageToErr").innerHTML =
            "Weightage range from should be less than weightage range to";
        }

        break;
      case "weightageDefault":
        document.getElementById("weightageDefaultErr").innerHTML = "";
        if ((e.value < state.WeightageRangeFrom || e.value > state.WeightageRangeTo) && (e.value !== '' && e.value !== null)) {
          document.getElementById("weightageDefaultErr").innerHTML = "Default Weightage must be in the range of from and to."
          setState((prevState) => ({ ...prevState, defaultWeightage: e.value }));
        } else if (e.value === '' || e.value === null) {
          document.getElementById("weightageDefaultErr").innerHTML = "Please enter default weightage.";
          setState((prevState) => ({ ...prevState, defaultWeightage: e.value }));
        }
        else {
          document.getElementById("weightageDefaultErr").innerHTML = ""
          setState((prevState) => ({ ...prevState, defaultWeightage: e.value }));
        }

        break;
      case "weightageVariabe":
        setState((prevState) => ({ ...prevState, weightedVariable: e.value }));
        document.getElementById("weightageVariabeErr").innerHTML = ""
        break;
      case "defaultWeightageEdiatable":
        setState((prevState) => ({ ...prevState, defaultWeightageEditable: e.checked }));
        break;
      case "financialYear":
        setState((prevState) => ({ ...prevState, fyId: e.value }));
        break;
      case "frequency":
        setState((prevState) => ({ ...prevState, frequency: e.value }));
        break;
      case "rangeScale":
        setState((prevState) => ({ ...prevState, rangeScale: e.value }));
        break;
      default:
    }
    setTimeout(() => {
      const addFormObject = new FormValidator("#okrPerPotMasterForm", options);
      addFormObject.validate();
    }, 100);
  }

  const onSaveData = async () => {
    const weightageFrom = document.getElementById('weightageFrom')?.ej2_instances[0]?.value
    const weightageTo = document.getElementById('weightageTo')?.ej2_instances[0]?.value
    const weightageDefault = document.getElementById('weightageDefault')?.ej2_instances[0]?.value
    const weightageVariabe = document.getElementById('weightageVariabe')?.ej2_instances[0]?.value
    
    if (weightageFrom !== null && weightageTo !== null && weightageDefault!==null && weightageVariabe!==null) {
      setState(prevState => ({ ...prevState, WeightageRangeFrom: weightageFrom,WeightageRangeTo: weightageTo, defaultWeightage: weightageDefault, weightedVariable: weightageVariabe }))
    }
    setUpdateSave(true)
  }

  const onInput = (e) => {
    try {
      if (textareaObj !== null && typeof textareaObj !== 'undefined') {
        textareaObj.current.respectiveElement.style.height = "auto";
        textareaObj.current.respectiveElement.style.minHeight = "100px";
        textareaObj.current.respectiveElement.style.height = `${textareaObj.current.respectiveElement.scrollHeight}px`;
        cracterLimit(e, 'inputCount', '500')
      }
    } catch (error) {
    }
  };

  const [frequencyData, setFrequencyData] = useState([]);
  console.log("frequencyData",frequencyData, state)
  useEffect(()=>{
    if (masterId + "" === PERFORMANCE && state.allocationType === '1') {
      setFrequencyData ([
        { Name: "To be set", Id: "0" },
        { Name: "Monthly", Id: "1" },
        { Name: "Quarterly", Id: "2" },
        { Name: "Half Yearly", Id: "3" },
        { Name: "Yearly", Id: "4" }
      ])
    } else {
      setFrequencyData ([
        { Name: "Monthly", Id: "1" },
        { Name: "Quarterly", Id: "2" },
        { Name: "Half Yearly", Id: "3" },
        { Name: "Yearly", Id: "4" },
      ])
    }
  }, [state.allocationType])
  

  const allocationTypeData = [
    { Name: "Business OKR", Id: "1" },
    { Name: "Central OKR", Id: "2" },

    { Name: "Leadership Principle", Id: "3" },
    { Name: "Brand Value", Id: "4" },
    { Name: "Brand Character", Id: "5" },

  ]

  return (
    <>

      <div id="okrPerPotMasterForm" >
        <div style={{ padding: "16px" }}>
          <div className="col-xs-12 col-sm-12 col-lg-12 mt-sm mb-sm atc-no-paddding">
            <TextBoxComponent
              data-msg-containerid="performanceNameErr"
              id="performanceName"
              value={state.productivityName}
              name="performanceNameErr"
              placeholder={masterId == PERFORMANCE ? "Performance Name *" : "Potential Name *"}
              floatLabelType="Auto"
              cssClass="e-outline"
              onChange={(e) => inputChangeHandler(e, "productivityName")}
              htmlAttributes={{ maxlength: 100 }}
            />
            <div id="performanceNameErr" className="error-div" />
          </div>

          <div className="col-xs-12 col-sm-12 col-lg-12 mt-sm mb-lg atc-no-paddding">

            <ComboBoxComponent
              id="allocationType"
              data-msg-containerid="allocationTypeErr"
              name="allocationTypeErr"
              dataSource={masterId == PERFORMANCE ? allocationTypeData.slice(0, 2) : allocationTypeData.slice(2, allocationTypeData.length)}
              fields={{ text: "Name", value: "Id" }}
              value={state.allocationType}
              placeholder="Allocation Type *"
              floatLabelType="Auto"
              cssClass="e-outline"
              onChange={(e) => inputChangeHandler(e, "allocationType")}
              allowCustom={false}
              htmlAttributes={{ maxlength: 100 }}
            />
            <div id="allocationTypeErr" className="error-div" />
          </div>



          <div className="col-xs-12 col-sm-12 col-lg-12 weekdayDescription atc-no-paddding">
            <TextBoxComponent
              data-msg-containerid="descriptionErr"
              id="description"
              value={state.description}
              name="descriptionErr"
              placeholder="Description "
              floatLabelType="Auto"
              cssClass="e-outline"
              onChange={(e) => inputChangeHandler(e, "description")}
              htmlAttributes={{ maxlength: 500 }}
              inputProps={{
                required: true,
                minLength: 7,
              }}
              style={{ minHeight: 100 }}
              multiline={true}
              ref={textareaObj}
              input={onInput}
            />
            {/* <div id="descriptionErr" className="error-div" /> */}
            <div id="inputCount" style={{ float: 'right', fontSize: 12, margin: '0px 15px 8px 0px' }} className="error-div" />
          </div>

          <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 pb-md atc-switch-heading " style={{ fontSize: ".95rem", color: "#939399", fontWeight: 600 }}>Weightage Range</div>

          <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 pb-sm mb-sm ">
            <div
              className="col-xs-6 col-sm-6 col-lg-6 col-md-6"
              style={{ padding: 0 }}
            >
              <NumericTextBoxComponent
                data-msg-containerid="weightageFromErr"
                name="weightageFromErr"
                floatLabelType="Auto"
                value={state.WeightageRangeFrom}
                onChange={(e) => inputChangeHandler(e, "weightageFrom")}
                min={0}
                max={100}
                decimals={0}
                format="##"
                validateDecimalOnType={true}                
                id="weightageFrom"
                placeholder='From *'
                cssClass="e-outline"
                showSpinButton={false}
              />
              <div id="weightageFromErr" className="error-div" />
            </div>
            <div
              className="col-xs-6 col-sm-6 col-lg-6 col-md-6"
              style={{ paddingRight: 0 }}
            >
              <NumericTextBoxComponent
                data-msg-containerid="weightageToErr"
                name="weightageToErr"
                floatLabelType="Auto"
                value={state.WeightageRangeTo}
                onChange={(e) => inputChangeHandler(e, "weightageTo")}
                min={0}
                max={100}
                decimals={0}
                format="##"
                validateDecimalOnType={true}
                showSpinButton={false}
                id="weightageTo"
                placeholder="To *"
                cssClass="e-outline"
              />
              <div id="weightageToErr" className="error-div" />
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 pb-md ">
            <div
              className="col-xs-6 col-sm-6 col-lg-6 col-md-6"
              style={{ padding: 0 }}
            >
              <NumericTextBoxComponent
                data-msg-containerid="weightageDefaultErr"
                name="weightageDefaultErr"
                floatLabelType="Auto"
                value={state.defaultWeightage}
                onChange={(e) => inputChangeHandler(e, "weightageDefault")}
                min={0}
                max={100}
                decimals={0}
                format="##"
                validateDecimalOnType={true}
                showSpinButton={false}
                id="weightageDefault"
                placeholder="Default Weightage *"
                cssClass="e-outline"
              />
              <div id="weightageDefaultErr" className="error-div" />
            </div>
            <div
              className="col-xs-6 col-sm-6 col-lg-6 col-md-6"
              style={{ paddingRight: 0 }}
            >
              <NumericTextBoxComponent
                data-msg-containerid="weightageVariabeErr"
                name="weightageVariabeErr"
                floatLabelType="Auto"
                value={state.weightedVariable}
                onChange={(e) => inputChangeHandler(e, "weightageVariabe")}
                min={0}
                max={100}
                decimals={0}
                format="##"
                validateDecimalOnType={true}
                showSpinButton={false}
                id="weightageVariabe"
                placeholder="Weightage Variable *"
                cssClass="e-outline"
              />
              <div id="weightageVariabeErr" className="error-div" />
            </div>
          </div>



          <div className="col-xs-12 col-sm-12 col-lg-12 mt-sm  atc-no-paddding" >
            <div className="atc-switch-heading" style={{ fontSize: ".95rem", color: "#939399", fontWeight: 450 }}>Default Weightage Editable</div>
            <div className="atc-switch-heading atc-switch-inline mt-sm">

              <div className="atc-switch-margin">
                <SwitchComponent
                  cssClass="atc-switch-height"
                  change={(e) => inputChangeHandler(e, 'defaultWeightageEdiatable')}
                  checked={state.defaultWeightageEditable}
                />
              </div>
              <div className="ml-md" style={{ fontSize: ".95rem", fontWeight: 500 }}>{state.defaultWeightageEditable ? 'Yes' : 'No'}</div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-lg-12 mt-sm mb-sm atc-no-paddding">

            <ComboBoxComponent

              id="performanceFY"
              data-msg-containerid="performanceFYErr"
              name="performanceFYErr"
              dataSource={props.finacialYearData}
              fields={{ text: "Name", value: "Id" }}
              value={state.fyId}
              placeholder="Financial Year *"
              floatLabelType="Auto"
              cssClass="e-outline"
              onChange={(e) => inputChangeHandler(e, "financialYear")}
              htmlAttributes={{ maxlength: 100 }}

            />
            <div id="performanceFYErr" className="error-div" />
          </div>
          <div className="col-xs-12 col-sm-12 col-lg-12 mt-sm mb-sm atc-no-paddding">
            <ComboBoxComponent
              id="performanceFrequency"
              data-msg-containerid="performanceFrequencyErr"
              name="performanceFrequencyErr"
              dataSource={frequencyData}
              fields={{ text: "Name", value: "Id" }}
              value={state.frequency}
              placeholder="Frequency *"
              floatLabelType="Auto"
              cssClass="e-outline"
              onChange={(e) => inputChangeHandler(e, "frequency")}
              allowCustom={false}
              htmlAttributes={{ maxlength: 100 }}
            />
            <div id="performanceFrequencyErr" className="error-div" />
          </div>

          <div className="col-xs-12 col-sm-12 col-lg-12 mt-sm mb-sm pb-lg atc-no-paddding">
            <ComboBoxComponent

              id="performanceRangeScale"
              data-msg-containerid="performanceRangeScaleErr"
              name="performanceRangeScaleErr"
              dataSource={props.ratingSystem}
              fields={{ text: "Name", value: "Id" }}
              value={state.rangeScale}
              placeholder="Rating Scale *"
              floatLabelType="Auto"
              cssClass="e-outline"
              onChange={(e) => inputChangeHandler(e, "rangeScale")}
              allowCustom={false}
              htmlAttributes={{ maxlength: 100 }}
            />
            <div id="performanceRangeScaleErr" className="error-div" />
          </div>
        </div>
      </div>
      {/* <div className="loader-new" id="spinnerContainer" > */}
      {loading && <ProgressBar />}
      {/* </div> */}
    </>
  );
};

const mapStateToProps = ({ auth, okrreducer, meetingmaster }) => {
  const { authUser } = auth;
  const { currentFinancialYearId, finacialYearData } = meetingmaster;
  const { okrMasterFlag, ratingSystem, loading } = okrreducer;

  return {
    authUser,
    okrreducer,
    currentFinancialYearId,
    okrMasterFlag,
    finacialYearData,
    ratingSystem,
    loading
  };
};
export default connect(mapStateToProps, { savePerformancePotentialMaster, updateOKRData })(PerformanceMaster);
