/**
 * Author: Vivek Khodade.
 * Created:10-Oct-2021
 * Last Modify:06-Jun-2020
 * Modified By:Vivek Khodade
 */

import axios from "axios";
import { urlConfig } from "../../config/config";
function errorHandler(reject, error) {
  // eslint-disable-next-line  
  reject("operation failed");
}

/**
* Author:Vivek Khodade  *
* @param {*} userId
* @param {*} empId
* @description get the rating dashboard data
*/
function getDashboardData(authUser, empId, fyId, monthOfRating, OKRType, ratingType, type) {
  const getURL = `${urlConfig.tmlcApiUrl}ratingdashboard/${authUser}/${empId}?type=${type}&fyid=${fyId}&mnth=${monthOfRating}&OKRType=${OKRType}&ratingType=${ratingType}`;
  return invoikeGetReq(getURL)
}
/**
* Author:Vivek Khodade  *
* @param {*} userId
* @param {*} empId
* @description get the review dashboard data
*/
function getReviewDashboardData(authUser, empId, fyId, monthOfRating, OKRType, ratingType) {
  const getURL = `${urlConfig.tmlcApiUrl}reviewdashboard/${authUser}/${empId}?fyid=${fyId}&mnth=${monthOfRating}&OKRType=${OKRType}&ratingType=${ratingType}`;
  return invoikeGetReq(getURL)
}

/**
* Author:Suresh Sargam  *
* @param {*} userId
* @param {*} empId
* @param {*} ratingUId
* @param {*} type
* @param {*} userObjId
* @param {*} fyId
* @description get the rating data
*/
function getRatingInfo(authUser, empId, ratingUId, type) {
  try {
    const getURL = `${urlConfig.tmlcApiUrl}rating/${authUser}/${empId}/${ratingUId}/${type}`
    return invoikeGetReq(getURL)
  } catch (error) {

  }
}

function invoikeGetReq(getUrl) {
  return new Promise(function onThen(resolve, reject) {
    axios
      .get(getUrl, {
        headers: {
          authorization: localStorage.getItem("jwtToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      // eslint-disable-next-line
      .then((response) => {
        if (response != null) {
          return resolve(response.data);
        }
      })
      .catch((error) => {
        errorHandler(reject, error.response.data.message);
        return null;
      });
  });
}


/**
* Author:Amit Kumar  *
* @param {*} userId
* @param {*} empId
* @param {*} type
* @param {*} userObjId
* @param {*} fyId
* @description get the rating data
*/

function getInterimReviewInfo(authUser, empId, userObjId, fyId, type, ratingId, monthNo) {
  try {
    const getURL = `${urlConfig.tmlcApiUrl}interimreview/${authUser}/${empId}/${userObjId}?fyId=${fyId}&reviewType=${type}&ratingUId=${ratingId}&mnth=${monthNo}`
    return invoikeGetReq(getURL)
  } catch (error) {

  }
}

/**
* Author:Amit Kumar  *
* @param {*} authUser
* @param {*} empId
* @param {*} meetingId
* @param {*} meetingDataTime
* @param {*} monthNo
* @description get the rating data
*/

function getSetMeetingInfo(authUser, empId, meetingId) {
  try {
    const getURL = `${urlConfig.tmlcApiUrl}rating/setmeeting/${authUser}/${empId}?meetingId=${meetingId}`
    return invoikeGetReq(getURL)
  } catch (error) {

  }
}
/**
* Author:Vivek Khodade  *
* @param {*} type
* @param {*} userId
* @param {*} empId
* @param {*} userKRId
* @param {*} fyId
* @param {*} monthId
* @description get the KR question data.
*/
function getKRQuestionData(type,userId,empId,userKRId,fyId,monthId) {
  try {
    const getURL = `${urlConfig.tmlcApiUrl}okr/getokrkrquestion/${type}/${userId}/${empId}/${userKRId}/${fyId}/${monthId}`
    return invoikeGetReq(getURL)
  } catch (error) {

  }
}

const OKRRatingServices = {
  getDashboardData,
  getRatingInfo,
  getReviewDashboardData,
  getInterimReviewInfo,
  getSetMeetingInfo,
  getKRQuestionData,
}
export default OKRRatingServices;