import { MEETING_MASTER, MEETING_MASTER_UPDATE } from "../constant/meetingmasterconstant";

const INIT_STATE = {
  loader: false,
  meetingType: [],
  meetingSubType: [],
  meetingSource: [],
  meetingServiceProduct: [],
  meetingMedia: [],
  timeZone: [],
  country: [],
  meetingCategory: [],
  executiveAssistant: null,
  clientTimeZone: [],
  othersCheckAvailablity: [],
  newFeaturesData: [],
  newFeatureDataStatus: "",
  subCategary: "",
  finacialYearData: [],
  leaveYear: [],
  leaveYearId: null,
  campaignData: [],
  practiceAreaData: [],
  marketingActivityData: [],
  currentFinancialYearId: null,
  financialYearId: 23,
};
// eslint-disable-next-line
export default (state = INIT_STATE, action) => { //NOSONAR
  if (action.type === MEETING_MASTER) {
    return { ...state, ...action.payload, loader: true };
  } else if (action.type === MEETING_MASTER_UPDATE) {
    return { ...state, ...action.payload };
  }
  else {
    return state;
  }
};
