/**
 * Author: Vivek Khodade.
 * Created:08-May-2020
 * Last Modify:08-May-2020
 * Modified By:Vivek Khodade
 */
import React, { Component } from "react";
import NotificationContainer from "../../../../components/notification/notifycontainer";
import OrgChartConmponent from '../../../../components/separation/orgchartcomponents/orgchartcomponent';
import SeparationService from '../../../../services/entryexit/separationServices';
import { connect } from "react-redux";
import { setOrhChartDetaila } from '../../../../redux/actions/entryexit/orgchartaction';

class OrgChartView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      employeeData: [],
      leftEmployeeList: [],
      gridHeight: (window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight) - 300
    };
  }
  updateDimensions = () => {
    let activeGridHeight = window.innerHeight - 130;
    this.setState({ gridHeight: activeGridHeight });
  }
  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions);
  }
  componentWillReceiveProps(newProps) {
    if (newProps.orgchartupdateStatus === "update") {
      this.getEmployeeData();
      this.props.setOrhChartDetaila({ orgchartupdateStatus: "" });
    }
  }
  // Author Get Active List Employee data 
  getEmployeeData = () => {
    try {
      const { authUser } = this.props;
      const param = {
        authUser: authUser,
        type: "ActiveEmp",
        empId: 0
      }
      const employeeListData = Promise.resolve(
        SeparationService.getActiveLeftEmpList(param)
      );
      employeeListData.then((result) => {
        if (result !== null) {
          this.setState({
            employeeData: result.result
          })
        }
      });
    } catch (error) {
      // eslint-disable-next-line
      console.error(error);
    }
  }
  getLeftEmpList = () => {
    try {
      const { authUser } = this.props;
      const param = {
        authUser: authUser,
        type: "LeftEmp",
        empId: 0
      }
      const employeeListData = Promise.resolve(
        SeparationService.getActiveLeftEmpList(param)
      );
      employeeListData.then((result) => {
        if (result !== null) {
          this.setState({
            leftEmployeeList: result.result
          })
        }
      });
    } catch (error) {
      // eslint-disable-next-line
      console.error(error);
    }
  }
  onCreated() {
    setTimeout(() => {
      if (this.acrdnbasic !== null) {
        // eslint-disable-next-line
        this.acrdnbasic.items.expanded = true;
      }
    }, 1000);
  }
  refreshtabs() {
    const getTabActive = localStorage.getItem('SelectedTabAction');
    if (getTabActive !== null && getTabActive === 'Org Chart') {
      document.getElementById(`${getTabActive}`).style.display = "block";
    } else if (getTabActive !== null && getTabActive === 'Left Employee') {
      document.getElementById(`${getTabActive}`).style.display = "block";
    } else if (getTabActive === null || getTabActive === 'Active Employee') {
      localStorage.setItem('SelectedTabAction', 'Active Employee');
      this.getEmployeeData();
      document.getElementById("Active Employee").style.display = "block";
    }
  }
  openTab = (evt, tabName) => {
    localStorage.setItem('SelectedTabAction', tabName);
    const data = evt;
    if (tabName === "Active Employee") {
      this.getEmployeeData();
    }
    if (tabName === "Left Employee") {
      this.getLeftEmpList();
    }

    let i;
    const tabcontent = document.getElementsByClassName("tabcontent");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }
    const onboardingtab = document.getElementsByClassName("onboardingtab");
    // eslint-disable-next-line
    for (i = 0; i < onboardingtab.length; i++) {
      onboardingtab[i].className = onboardingtab[i].className.replace("active", "");
    }
    document.getElementById(tabName).style.display = "block";
    data.currentTarget.className += "active";
  };
  render() {
    return (
      <div
        className="row"
        style={{
          padding: "5px 10px 5px 25px",
          background: "#eee",
          height: "99%"
        }}
      >
        <NotificationContainer />
        <div className="row-xs-12 row-sm-12 row-lg-12 row-md-12">
          <>
            <OrgChartConmponent />
          </>
        </div>
      </div>

    );
  }
}
const mapStateToProps = ({ auth, orgchartreducer }) => {
  const { authUser } = auth;
  const { orgchartupdateStatus } = orgchartreducer;
  return { authUser, orgchartupdateStatus };
};

export default connect(mapStateToProps, { setOrhChartDetaila })(
  OrgChartView
);
