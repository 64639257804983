import React from 'react'
import HelpdeskAdminGrid from '../../../components/helpdeskadmincomponents';
import ButtonComponent from '../../../utilities/buttoncomponent';
import { HELPDESK_SUb_CATEGORY } from '../../../redux/constant/meetingcategoryconstant';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBarsFilter} from "@fortawesome/pro-light-svg-icons";

const HelpdeskAdminContainer = ({ changePopUpHandler, state }) => {

  const getGridColumnName = () => {
    if (state.masterId === HELPDESK_SUb_CATEGORY) {
      return "helpeskAdminSubCategory"
    }
  }

  const clearFilter = () => {
    document.getElementById('helpdesk-admin-data-gridGrid').ej2_instances[0].clearFiltering()
  }

  return (
    <>
      <div
        id='helpdesk-admin-grid-top'
        style={{
          width: '100%',
          display: 'flex',
          justifyContent:'space-between'
        }}
      >
        <ButtonComponent
          id="helpdesk-admin-add-btn"
          type="add"
          content="Add"
          style={{
            fontFamily: "Circular TT Book, Regular",
            fontWeight: 500,
            fontSize:13
          }}
          click={() => { changePopUpHandler({ type: 'add' }) }}
        />
        <div className="approval-list-element approval-list-filter" style={{ cursor: "pointer" }}
          id={"helpdesk-admin-grid-top-filter"} onClick={clearFilter}
        >
          <div className="approval-list-filter-icon">
            <FontAwesomeIcon
              icon={faBarsFilter}
              style={{ cursor: "pointer", fontSize: ".9rem", color: "#fff" }}
            />
          </div>
          <div style={{ color:'#fff'}}>Reset Filters</div>
        </div>
      </div>
      <HelpdeskAdminGrid
        masterId={state.masterId}
        gridName={getGridColumnName()}
        changePopUpHandler={changePopUpHandler}
      />
    </>
  )
}

export default HelpdeskAdminContainer