/**
 * @author: Vivek Khodade.
 * Created: 31-08-2020
 * Last Modify:06-APR-2023
 * Modified By:Vivek Khodade
 */
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { urlConfig } from "../../../config/config";
import { getNotify, getBase64Encoded, getHelpDeskCred } from "../../../app/other/commonfunction";
import queryString from "query-string";
import {
  ADD_EDIT_SUB_CATEGORY,
  ADD_NEW_SERVICE_REQUEST,
  HELPDESK_CREATE_USER,
  ADD_TICKET_UPDATE,
  UPDATEHELPDESK_USER_INFO,
  ADD_NEW_IDEA
} from "../../constant/helpdesk/helpdeskconstant";
import { addUpdateHelpDeskRedux, handlePopup, helpdeskReduxUpdate } from '../../actions/helpdesk/helpdeskaction'
import {
  ERROR,
  SUCCESS,
  addEditSubCategoryFailed,
  addEditSubCategorySuccess,
  addIdeaError,
  addIdeaSuccess,
  newServiceRequestAdded,
  ticketUpdateFailed,
  ticketUpdateSuccess
} from "../../../assets/constants/constants";
const createHelpDeskUserApiCall = async (payload) => {
  let data = payload;
  let userId = data.userId;
  let empId = data.empId;
  delete data.userId;
  delete data.empId;
  return axios
    .post(
      `${urlConfig.tmlcApiUrl}helpdesk/updatehelpdeskuserid`,
      {
        // hdUserId: result.data,
        empId: empId,
        userId: userId,
        username: data.username,
        password: data.password,
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
        company: data.company,
        department: data.department,
        sendWelcomeEmail: false,
      },
      {
        headers: {
          authorization: localStorage.getItem("jwtToken"),
          'Accept': 'application/json',
          "Content-Type": "application/json"
        },
      })
    .then((tempResult) => {
      return tempResult;
    }
    )
    .catch((error) => error.response);
}
const updateHelpDeskUserInfoApiCall = async (payload) => {
  return axios
    .post(
      `${urlConfig.helpDeskUrl}api/UpdateUser?userId=${payload.userId}`, queryString.stringify(payload),
      {
        headers: {
          Authorization: "Basic " + getBase64Encoded(getHelpDeskCred()),
          'Accept': 'application/json',
          "Content-Type": "application/x-www-form-urlencoded"
        },
      })
    .then((result) => {
      return result
    })
    .catch((error) => error.response);
}

const createTicket = (payload) => {
  return new Promise(resolve => {
    addNewServiceRequestDetailsCall(payload, resolve);
  });
}

const addNewServiceRequestDetailsCall = async (payload, resolve) => {
  try {
    const req = new XMLHttpRequest();
    let data = new FormData();
    const currentDate = Date.parse(new Date()) / 1000;
    data.append("categoryId", payload.categoryId);
    data.append("body", payload.body);
    data.append("subject", payload.subject);
    data.append("priorityId", payload.priorityId);
    if (payload.uploadFile.length > 0) {
      payload.uploadFile.forEach((file, index) => data.append("uploadFile", file, currentDate + payload.fileName[index]))
    }
    req.open("POST", `${urlConfig.helpDeskUrl}api/ticket`);
    req.setRequestHeader("Authorization", "Basic " + getBase64Encoded(getHelpDeskCred()));
    req.send(data);

    req.onreadystatechange = () => {
      if (req.readyState === 4 && req.status === 200) {
        const res = JSON.parse(req.responseText);
        let cData = {
          ticketId: res,
          "cf1": payload.clientImpact,
          "cf2": payload.subCategory,
          "cf3": payload.customSubCategoryId,  // Update Custom Id
          [process.env.REACT_APP_HD_CUSTOM_SUB_FIELD]: payload.customSubCategoryId
        }
        return axios
          .post(
            `${urlConfig.helpDeskUrl}/api/SetCustomFields`,
            queryString.stringify(cData),
            {
              headers: {
                Authorization: "Basic " + getBase64Encoded(getHelpDeskCred()),
                'Accept': 'application/json',
                "Content-Type": "application/x-www-form-urlencoded"
              },
            })
          .then((result) => {
            return resolve(result);;
          }
          )
          .catch((error) => {
            resolve(error.response);
          });

      }
    }

  } catch (e) {
    console.log("🚀 ~ file: helpdeskmiddleware.js:37 ~ addNewServiceRequestDetailsCall ~ e:", e)
  }
}

function* addNewServiceRequestDetails({ payload }) {
  const serviceRequest = yield call(createTicket, payload);
  if (serviceRequest.status === 200) {
    getNotify(
      SUCCESS,
      newServiceRequestAdded)
    yield put(addUpdateHelpDeskRedux({ submitNewRequestStatus: 'updated', uploadDocsLoading: false }))
  }
  else {
    getNotify(ERROR, "Failed");
  }

}
export function* addNewServiceRequest() {
  yield takeEvery(ADD_NEW_SERVICE_REQUEST, addNewServiceRequestDetails);
}

const addEditSubCategoryApiCall = async (payload) => {
  const formattedData = {
    "categoryId": payload.CategoryId,
    "subCategoryName": payload.SubCategoryName,
    "jitbitId": payload.JitbitId,
    "disabled": payload.Disabled
  }
  return axios
    .post(`${urlConfig.tmlcApiUrl}helpdesk/subcategories/${payload.SubCategoryId}`, formattedData, {
      headers: {
        authorization: localStorage.getItem("jwtToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((result) => result)
    .catch((error) => error.response);
}

function* addEditSubCategoryResponseHandler({ payload }) {
  const serviceRequest = yield call(addEditSubCategoryApiCall, payload);
  if (serviceRequest.status === 200) {
    if (serviceRequest.data[0].result === 'Success') {
      let responseNotification = payload.SubCategoryId > 0 ? 'Updated' : 'Added'
      getNotify(
        SUCCESS,
        `${responseNotification} ${addEditSubCategorySuccess}`
      );
      yield all([put(helpdeskReduxUpdate({ dataReload: true, loading: false })), put(handlePopup('close'))])
    } else {
      getNotify(
        ERROR,
        serviceRequest.data[0].result
      );
      yield put(helpdeskReduxUpdate({ loading: false }))
    }
  }
  else {
    let responseNotification = payload.SubCategoryId > 0 ? 'Update' : 'Add'
    getNotify(ERROR, serviceRequest.data.message || `${responseNotification} ${addEditSubCategoryFailed}`);
    yield put(helpdeskReduxUpdate({ loading: false }))
  }
}

const addNewIdeasApiCall = async (payload) => {
  return axios
    .post(`${urlConfig.tmlcApiUrl}helpdesk/addhelpdeskidea`, payload,
      {
        headers: {
          authorization: localStorage.getItem("jwtToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
    .then((result) => result)
    .catch((error) => error.response);
}

function* addNewIdeasResponseHandler({ payload }) {
  const serviceRequest = yield call(addNewIdeasApiCall, payload);

  if (serviceRequest.status === 200) {
    if (serviceRequest.data?.result === 'success') {
      getNotify(
        SUCCESS,
        addIdeaSuccess
      );
      yield put(addUpdateHelpDeskRedux({ submitNewRequestStatus: 'updated' }))
    } else {
      getNotify(
        ERROR,
        addIdeaError
      );
    }
  }
  else {
    getNotify(ERROR, addIdeaError);
  }
}

export function* addNewIdeas() {
  yield takeEvery(ADD_NEW_IDEA, addNewIdeasResponseHandler)
}

function* createHelpDeskUserResponseHandler({ payload }) {
  const serviceRequest = yield call(createHelpDeskUserApiCall, payload);
  if (serviceRequest?.status !== undefined && serviceRequest.status !== null && serviceRequest.status === 200) {
    getNotify(
      SUCCESS,
      "Helpdesk user created successfully."
    );
  }
}

function* updateHelpDeskUserInfoResponseHandler({ payload }) {
  const serviceRequest = yield call(updateHelpDeskUserInfoApiCall, payload);
  if (serviceRequest === undefined || serviceRequest.status !== 200) {
    getNotify(ERROR, addEditSubCategoryFailed);
  }
}
const addCommentApiCall = (payload) => {
  return new Promise((resolve, reject) => {
    const currentDate = Date.parse(new Date()) / 1000;
    const req = new XMLHttpRequest();
    let data = new FormData();
    data.append("id", payload.ticketId);
    data.append("body", payload.comment);
    payload.file.length > 0 &&
      payload.file.forEach((file, index) => data.append("uploadFile", file, currentDate + payload.fileName[index]))
    req.open("POST", `${urlConfig.helpDeskUrl}api/comment`);
    req.setRequestHeader("Authorization", "Basic " + getBase64Encoded(getHelpDeskCred()));
    req.responseType = 'json';
    req.onload = function () {
      if (this.status >= 200 && this.status < 300) {
        resolve({
          status: this.status,
          statusText: req.statusText
        });
      } else {
        reject({
          status: this.status,
          statusText: req.statusText
        });
      }
    };
    req.send(data);
    req.onerror = function () {
      reject({
        status: this.status,
        statusText: req.statusText
      });
    }
  })
}

function* addCommentResponseHandler({ payload }) {
  const serviceRequest = yield call(addCommentApiCall, payload);
  if (serviceRequest === "Internal Server Error") {
    getNotify(ERROR, ticketUpdateFailed);
    yield put(helpdeskReduxUpdate({ loading: false }));
  } else {
    getNotify(SUCCESS, ticketUpdateSuccess);
    yield put(helpdeskReduxUpdate({ dataUpdated: true }));
  }
}
export function* updateHelpDeskUserInfo() {
  yield takeEvery(UPDATEHELPDESK_USER_INFO, updateHelpDeskUserInfoResponseHandler)
}

export function* addEditSubCategory() {
  yield takeEvery(ADD_EDIT_SUB_CATEGORY, addEditSubCategoryResponseHandler)
}

export function* createHelpDeskUser() {
  yield takeEvery(HELPDESK_CREATE_USER, createHelpDeskUserResponseHandler)
}

export function* addComment() {
  yield takeEvery(ADD_TICKET_UPDATE, addCommentResponseHandler)
}

export default function* rootSaga() {
  yield all([
    fork(addNewServiceRequest),
    fork(createHelpDeskUser),
    fork(addEditSubCategory),
    fork(addComment),
    fork(updateHelpDeskUserInfo),
    fork(addNewIdeas)
  ]);
}

