
/**
 * Author: md Jahir Uddin
 * Date: 31-03-2023
 * Description: Use this component when we need to render a label text comb
 */

import React from 'react';
import PropTypes from "prop-types";

const LabelText = ({ label, text, labelClass, textClass, labelStyle, textStyle, id }) => {
    return (
    <span id={id}>
        <div className={"helpdesk-text pt-md pr-md  " + labelClass} style={labelStyle}>{label}</div>
        <div className={"helpdesk-value pr-md " + textClass} style={textStyle}>{text}</div>
    </span>
    )
}

LabelText.propTypes = {
    id: PropTypes.string,
    label: PropTypes.object,
    text: PropTypes.string,
    labelClass: PropTypes.string,
    textClass: PropTypes.string,
    labelStyle: PropTypes.object,
    textStyle: PropTypes.object,

};

LabelText.defaultProps = {
    labelStyle: {},
    textStyle: {},
    labelClass: "",
    textClass: ""
};

export default LabelText