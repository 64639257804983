import React from "react";
import { connect } from "react-redux";
import PropTypes, { object } from "prop-types";
import { addUpdateObjectveKpi, setOKRUserKPI, setUserOKRObjectiveKPI, setCentralOkrKPIRedux } from "../../redux/actions/okr/okractions";
import { changeAccordianColor, getNotify } from "../../app/other/commonfunction";
import { Button } from "@material-ui/core";
import { FormValidator } from "@syncfusion/ej2-inputs";
import { ERROR, totalRequiredKpiWeightage, selectAtleastOneKPI, totalKPIWeightage, fillMandatoryFields } from "../../assets/constants/constants";
import NewOkrObjectiveCommon from "./newokrobjectivecommon";
import ConfirmationPopup from "../../utilities/confirmationpopup";


class ObjectiveKpiCommon extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      changeData: true,
      weightageVal: 0,
      openMenu: false,
      openEditPopup: false,
      documentPath: "",
      fileNames: [],
      currentKpiDetails: [],
      currentKpiIndex: 0,
      selectedKpiList: [],
      kpiListToSubmit: [],
      kpiListDetailsToSubmit: [],
      openConfirm: false,
      totalWeightageVal: 0,
      refreshMe: false,
      isLoading: true,
      heightLayout: window.innerHeight - 500
    };
    this.weblink = [
      { Id: 1, Name: "Yes", value: 1 },
      { Id: 0, Name: "No", value: 0 },
    ];
    this.okrMasters = [];
    this.totalWeightageVal = 0;
    this.submitButtonClass = 'button-submit-enable';
    this.expandAccordian = this.expandAccordian.bind(this);
    this.handleWeightage = this.handleWeightage.bind(this);
    this.renderCallback = this.renderCallback.bind(this);
    this.updateDimensions = this.updateDimensions.bind(this);
  }

  componentDidMount = async () => {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
    const { currentTab, totalWeight, objectType, okrMasters } = this.props;

    if (currentTab === 'activeTab' || objectType === '2' || objectType === '3' || objectType === '4') {
      this.totalWeightageVal = totalWeight;
    }
    this.okrMasters = okrMasters;
    // eslint-disable-next-line
    this.setState({ changeData: !this.state.changeData, totalWeightageVal: this.totalWeightageVal });

  };
  updateDimensions() {
    const updateHeight = window.innerHeight - 420;
    this.setState({ heightLayout: updateHeight });
  }
  // eslint-disable-next-line
  UNSAFE_componentWillReceiveProps(newProps) { // NOSONAR
    window.addEventListener("resize", this.updateDimensions);
    const { objectType, centralOkrData, leaderShipOkrData, brandValueOkrData, totalWeight } = this.props;
    const { totalWeightageVal } = this.state;
    if (objectType === '2' || objectType === '3' || objectType === '4') { // totalWeight === 100 && 
      this.submitButtonClass = 'button-submit-enable';
    }
    try {
      this.closeEditPopup();
      if (objectType === "2") {
        if (newProps.reRenderComponent === "updatedCentral") {
          // eslint-disable-next-line
          this.props.setCentralOkrKPIRedux({
            centralOkrData,
            reRenderComponent: "updatedCentral-main",
          });
        }
        else if (newProps.userObjectStatus === "Savedundefined") {
          this.setState({ changeData: !this.state.changeData });
        }
        if (newProps.totalWeight !== totalWeight || totalWeightageVal !== totalWeight) {
          this.setState({ totalWeightageVal: newProps.totalWeight });
        }
      } else if (objectType === "3") {
        if (newProps.reRenderComponent === "updatedLP") {
          // eslint-disable-next-line
          this.props.setCentralOkrKPIRedux({
            leaderShipOkrData,
            reRenderComponent: "updatedLP-main",
          });
        }
        else if (newProps.totalWeight !== totalWeight || totalWeightageVal !== totalWeight) {

          this.setState({ totalWeightageVal: newProps.totalWeight });
        }
      } else if (objectType === "4") {
        if (newProps.reRenderComponent === "updatedBrand") {
          // eslint-disable-next-line
          this.props.setCentralOkrKPIRedux({
            brandValueOkrData,
            reRenderComponent: "updatedBrand-main",
          });
        }
        else if (newProps.totalWeight !== totalWeight || totalWeightageVal !== totalWeight) {
          this.setState({ totalWeightageVal: newProps.totalWeight });
        }
      }
    } catch (error) {
      // eslint-disable-next-line
      console.log(error);
    }
  }

  extractCentralOkr = (allOkrKpi, objectType) => {
    return allOkrKpi.filter((item) => {
      return item.objType === objectType;
    });
  };

  expandAccordian = (id, type) => {
    try {
      if (
        type === "add" &&
        document.getElementById(id) !== null &&
        typeof document.getElementById(id) !== "undefined"
      ) {
        if (
          typeof document.getElementById(id).ej2_instances !== "undefined" &&
          document.getElementById(id).ej2_instances !== null
        )
          document.getElementById(
            id
          ).ej2_instances[0].items[0].expanded = false;
      }

    } catch (e) {
      // eslint-disable-next-line
      console.log(e);
    }
    changeAccordianColor("Parent", 100);
    changeAccordianColor("child", 300);
  };
  handleClickObjective = (e, objdata, Type) => {
    this.setState({ openMenu: true, anchorElOBJ: e.currentTarget });
  };
  handleClose = () => {
    this.setState({ openMenu: false });
  }
  handleOpenEditPopup = (e, ObjKpiDetails, index) => {
    this.setState({ openEditPopup: true, anchorElKpi: e.currentTarget, currentKpiDetails: ObjKpiDetails, currentKpiIndex: index });
  }
  closeEditPopup = () => {
    this.setState({ openEditPopup: false });
  };
  handleWeightage() {
    const { weightageVal } = this.state;
    const WeightageVal = weightageVal + 1;
    this.setState({ weightageVal: WeightageVal });
  }
  openAttachmentPopup = (currentKpiIndex) => {
    this.setState({
      openAttachment: true, currentKpiIndex: currentKpiIndex
    });
  };
  closeAttachmentDialog = () => {
    this.setState({ openAttachment: false, fileNames: [] });
  };
  getAllInputValue = (e, type, index) => { // NOSONAR
    const {
      objectType,
      centralOkrData,
      leaderShipOkrData,
      brandValueOkrData,
      // index,
      totalWeightage,
    } = this.props;
    const { changedData } = this.state;
    let objectTypeStr = "";
    objectTypeStr = "central";
    const option = { rules: {} };
    const centralOkr = centralOkrData;
    const leaderShipOkr = leaderShipOkrData;
    const brandValueokr = brandValueOkrData;

    switch (type) {
      case "weightage":
        if (e.value !== undefined && e.value !== "") {
          if (objectType === "2") {
            centralOkr[index].weightage = parseInt(e.value, 10);
            // eslint-disable-next-line
            this.props.addUpdateObjectveKpi({ centralOkrData: centralOkr });
          }
          if (objectType === "3") {
            leaderShipOkr[index].weightage = e.value;
            // eslint-disable-next-line
            this.props.addUpdateObjectveKpi({
              leaderShipOkrData: leaderShipOkr,
            });
          }
          if (objectType === "4") {
            brandValueokr[index].weightage = e.value;
            // eslint-disable-next-line
            this.props.addUpdateObjectveKpi({
              brandValueOkrData: brandValueokr,
            });
          }
          // eslint-disable-next-line
          this.handleWeightage(e.value);
          // option.rules[`weightage${index}`] = {
          //   required: [true, "Please enter weightage."],
          //   number: [true, "Please enter valid weightage."],
          //   min: [0, "Please enter valid weightage."],
          //   max: [100, "Please enter less than 100% weightage."],
          // };
          // }, 1000);
        } else {
          // eslint-disable-next-line
          this.props.addUpdateObjectveKpi({
            totalWeightage: totalWeightage + 0,
          });
        }
        this.setState({ changedData: !changedData });
        break;
      case "veriableLink":
        if (e.itemData !== undefined && e.itemData !== null) {
          if (objectType === "2") {
            centralOkr[index].varibleLink = e.itemData.Id;
            // eslint-disable-next-line
            this.props.addUpdateObjectveKpi({ centralOkrData: centralOkr });
          }
          if (objectType === "3") {
            leaderShipOkr[index].varibleLink = e.itemData.Id;
            // eslint-disable-next-line
            this.props.addUpdateObjectveKpi({
              leaderShipOkrData: leaderShipOkr,
            });
          }
          if (objectType === "4") {
            brandValueokr[index].varibleLink = e.itemData.Id;
            // eslint-disable-next-line
            this.props.addUpdateObjectveKpi({
              brandValueOkrData: brandValueokr,
            });
          }
          option.rules[`veriableLink${index}`] = {
            required: [true, "Please select variable link."],
          };
        }
        break;
      case "descripion":
        if (e.value !== undefined && e.value !== null) {
          if (objectType === "2") {
            centralOkr[index].description = e.value;
            // eslint-disable-next-line
            this.props.addUpdateObjectveKpi({ centralOkrData: centralOkr });
          }
          if (objectType === "3") {
            leaderShipOkr[index].description = e.value;
            // eslint-disable-next-line
            this.props.addUpdateObjectveKpi({
              leaderShipOkrData: leaderShipOkr,
            });
          }
          if (objectType === "4") {
            brandValueokr[index].description = e.value;
            // eslint-disable-next-line
            this.props.addUpdateObjectveKpi({
              brandValueOkrData: brandValueokr,
            });
          }
        }
        break;
      default:
    }
    try {
      const formValidate = new FormValidator(
        `#${objectTypeStr}kpiSaveCommon${index}`,
        option
      );
      formValidate.validate();
    } catch (error) {
      // eslint-disable-next-line
      console.log(error);
    }
  };
  uploadKpiDoc = () => {
    const { objectType, centralOkrData, leaderShipOkrData, brandValueOkrData } = this.props;
    const { fileNames, currentKpiIndex } = this.state;
    document.getElementById("uploadErr").innerHTML = "";
    if (typeof fileNames === "undefined" || fileNames === null || fileNames.length < 1) {
      document.getElementById("uploadErr").innerHTML = "Please upload required document";
    } else {
      const centralOkr = centralOkrData;
      const leaderShipOkr = leaderShipOkrData;
      const brandValueokr = brandValueOkrData;
      if (objectType === "2") {
        centralOkr[currentKpiIndex].kpiAttachment = fileNames.toString();
        // eslint-disable-next-line
        this.props.addUpdateObjectveKpi({ centralOkrData: centralOkr });
      }
      if (objectType === "3") {
        leaderShipOkr[currentKpiIndex].kpiAttachment = fileNames.toString();
        // eslint-disable-next-line
        this.props.addUpdateObjectveKpi({ leaderShipOkrData: leaderShipOkr });
      }
      if (objectType === "4") {
        brandValueokr[currentKpiIndex].kpiAttachment = fileNames.toString();
        // eslint-disable-next-line
        this.props.addUpdateObjectveKpi({ brandValueOkrData: brandValueokr });
      }
      this.setState({ openAttachment: false, fileNames: [] });
    }
  };
  documentDetail = (fileNames) => {
    this.setState({
      fileNames,
    });
  };
  selectedFiles = (fileNms) => {
    const { changeData } = this.state;
    // eslint-disable-next-line
    this.setState({ fileNames: fileNms, changeData: !changeData });
  }
  renderCallback = (val, kpilist, totalWeightageVal) => {
    if (val === 'enable') this.submitButtonClass = 'button-submit-enable';
    else this.submitButtonClass = 'button-submit-disable';
    this.setState({ changeData: !this.state.changeData, kpiListToSubmit: kpilist, totalWeightageVal: totalWeightageVal });
  }
  handleSubmitKpi = async (action) => {
    const { employeeId, authUser, objectType } = this.props;
    const { kpiListDetailsToSubmit, refreshMe, totalWeightageVal } = this.state;
    for (let item of kpiListDetailsToSubmit) {
      const data = {};
      data.userId = authUser;
      data.empId = employeeId;
      data.userObjectiveId = item.userObjectiveId;
      data.userKpiId = item.userKpiId;
      data.isDraft = "N";
      data.spType = 'draftUpdate';
      // eslint-disable-next-line
      await this.props.setOKRUserKPI(data);
    }

    let resetWeightage;
    if (objectType === '2') {
      resetWeightage = 0;
      this.submitButtonClass = 'button-submit-disable';
    }
    else {
      resetWeightage = totalWeightageVal;
    }
    this.setState({ refreshMe: !refreshMe, kpiListToSubmit: [], totalWeightageVal: resetWeightage });
  };
  handleConfirmYes = () => {
    this.handleSubmitKpi(this.action);
    this.setState({ openConfirm: false });
  };
  handleConfirmNo = () => {
    this.handleConfirmClose();
  };
  handleConfirmClose = () => {
    this.setState({ openConfirm: false });
    this.title = "";
    this.message = "";
  };
  OpenConfirmDialog = (action) => { // NOSONAR
    const { centralOkrData, leaderShipOkrData, brandValueOkrData, objectType, variablePerc } = this.props;
    const { kpiListToSubmit, refreshMe } = this.state;
    this.action = action;
    var kpiListToSubmitVar = kpiListToSubmit
    var propOkrList;
    var okrTypeStr;
    if (objectType === '2') { kpiListToSubmitVar = centralOkrData; okrTypeStr = 'Central'; }
    else if (objectType === '3') { kpiListToSubmitVar = leaderShipOkrData; okrTypeStr = 'Leadership Principles'; }
    else if (objectType === '4') { kpiListToSubmitVar = brandValueOkrData; okrTypeStr = 'Brand Value'; }
    try {
      if (this.submitButtonClass === 'button-submit-disable') {
        return false;
      }
      if (objectType === '2' && (centralOkrData.length === 0 || kpiListToSubmitVar.length === 0)) {
        this.submitButtonClass = 'button-submit-disable';
        this.setState({ kpiListToSubmit: [], refreshMe: !refreshMe });
        getNotify(ERROR, selectAtleastOneKPI);
        return;
      }
      let newPropOkrList = [];
      for (let item of kpiListToSubmitVar) {
        if (objectType === '2') propOkrList = centralOkrData.filter((eh) => { return eh.userKpiId === item.userKpiId; });
        if (objectType === '3') propOkrList = leaderShipOkrData.filter((eh) => { return eh.userKpiId === item.userKpiId; });
        if (objectType === '4') propOkrList = brandValueOkrData.filter((eh) => { return eh.userKpiId === item.userKpiId; });
        newPropOkrList.push(propOkrList[0]);
      }

      if (newPropOkrList.length === 0) {
        this.submitButtonClass = 'button-submit-disable';
        this.setState({ kpiListToSubmit: [], refreshMe: !refreshMe });
        getNotify(ERROR, selectAtleastOneKPI);
        return;
      }
      var weightageTotal = newPropOkrList.reduce(function (prev, cur) {
        return prev + cur.weightage;
      }, 0);

      if (variablePerc > 0) {
        let variablePercCheck = false
        newPropOkrList.forEach(item => {
          if (item.varibleLink === "" || item.varibleLink === null) {
            variablePercCheck = true
            return false;
          }
        })
        if (variablePercCheck) {
          getNotify(ERROR, fillMandatoryFields);
          return;
        }
      }
      // check business description
      if (objectType === '2') {
        let descriptionCheck = false
        newPropOkrList.forEach(item => {
          if (item.description === "" || item.description === null) {
            descriptionCheck = true
            return false;
          }
        })
        if (descriptionCheck) {
          getNotify(ERROR, fillMandatoryFields);
          return;
        }
      }
      if (totalRequiredKpiWeightage !== weightageTotal) {
        if (objectType === '2') {
          this.setState({
            refreshMe: !refreshMe
          });
        }
        getNotify(ERROR, totalKPIWeightage);
        return;
      }

      this.title = "Submit";
      this.message = "Are you sure, you want to submit " + okrTypeStr + " OKR?";
      this.setState({ openConfirm: true, kpiListDetailsToSubmit: newPropOkrList });
    } catch (error) {
      // eslint-disable-next-line
      console.log(error);
    }
  };
  render() {
    const { importObjectiveKPI, objectType, currentTab, variablePerc, setHeightFlag, currWindowAdjust, enableAction } = this.props; // userProductiveAlloc,
    const { heightLayout, totalWeightageVal, refreshMe } = this.state;
    let paddingStyle;
    if (currentTab === "draftTab" && objectType !== '2' && objectType !== '3' && objectType !== '4') paddingStyle = '0px 0px 0px 28px';
    else paddingStyle = '-16px 0px 0px 0px';
    const draftPart = (setHeightFlag ? currWindowAdjust - 98 : heightLayout)
    const activePart = (setHeightFlag ? currWindowAdjust - 75 : heightLayout + 30)
    return (
      <>
        <div style={{
          height: currentTab === 'draftTab' ? draftPart : activePart,
          margin: paddingStyle, overflowY: "auto", overflowX: "hidden", position: "relative"
        }}>a
          {importObjectiveKPI.length > 0 &&
            <NewOkrObjectiveCommon
              importObjectiveKPI={importObjectiveKPI}
              objectType={objectType}
              renderCallback={this.renderCallback}
              currentTab={currentTab}
              refreshMe={refreshMe}
              variablePerc={variablePerc}
              enableAction={enableAction}
            />
          }
        </div>
        {currentTab === 'draftTab' &&
          <div style={{ float: "right", width: "313px", marginTop: 7 }}>
            <span style={{ color: "#B7B7BB", fontSize: "16px" }}>Total Weightage :</span><span style={{ color: "#36C17C", fontSize: "16px", fontWeight: "700" }} > {`${totalWeightageVal}%`} </span>
          </div>
        }

        <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12' style={{ padding: "13px 0px 0px 0px" }}>
          <div className="col-xs-12 col-sm-7 col-lg-8 col-md-8" style={{ padding: "0px" }}>
            {currentTab === 'draftTab' &&
              <>
                {
                  variablePerc > 0 &&
                  <>
                    <span className="footer-status" style={{ background: "#FDD141" }}></span>
                    <span style={{ padding: "0px 24px 0px 8px" }}>Pending</span>
                  </>
                }
                <span className="footer-status" style={{ background: "#48B8E7" }}></span>
                <span style={{ padding: "0px 24px 0px 8px" }}>Completed</span>
              </>
            }
            {currentTab === 'activeTab' &&
              <>
                <span className="footer-status" style={{ background: "#36C17C" }}></span>
                <span style={{ padding: "0px 24px 0px 8px" }}>Active</span>
                {/* <span className="footer-status" style={{ background: "#DBDBDD" }}></span>
                <span style={{ padding: "0px 24px 0px 8px" }}>Disabled</span> */}
              </>
            }
          </div>
          {currentTab === 'draftTab' ?
            <div className="col-xs-12 col-sm-5 col-lg-4 col-md-4" style={{ padding: "0px 0px 0px 0px" }}>
              <div style={{ float: "right", paddingRight: 2 }} >
                <Button
                  style={{ float: "right", width: "136px", height: 32 }}
                  className="remove-button-shadow"
                  color="primary"
                  variant="contained"
                  // style={{ width: 120, height: 32 }}
                  // className={this.submitButtonClass} 
                  onClick={() => this.OpenConfirmDialog("submit")} >
                  Submit
                  </Button>
              </div>
            </div>
            :
            <div className="col-xs-12 col-sm-5 col-lg-4 col-md-4" style={{ padding: "0px 0px 0px 0px" }}>
              <div style={{ float: "right", width: "236px" }}>
                <span style={{ color: "#B7B7BB", fontSize: "16px" }}>Total Weightage :</span><span style={{ color: "#36C17C", fontSize: "16px", fontWeight: "700" }} > {`${totalWeightageVal}%`} </span>
              </div>
            </div>
          }
        </div>
        <ConfirmationPopup
          showButton={{ type: "1" }}
          open={this.state.openConfirm}
          button1function={() => this.handleConfirmYes()}
          button2function={() => this.handleConfirmNo()}
          headerMessage={this.message}
          button1Text={"Yes"}
          button2Text={"No"}
          handleConfirmClose={this.handleConfirmClose}
        />  

      </>
    );
  }
}

ObjectiveKpiCommon.propTypes = {
  authUser: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  objectType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  variablePerc: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  importObjectiveKPI: PropTypes.arrayOf(object),
  centralOkrData: PropTypes.arrayOf(object),
  leaderShipOkrData: PropTypes.arrayOf(object),
  brandValueOkrData: PropTypes.arrayOf(object),
  userProductiveAlloc: PropTypes.oneOfType([PropTypes.object, PropTypes.arrayOf(object)]),
  setHeightFlag: PropTypes.number.isRequired,
  currWindowAdjust: PropTypes.bool.isRequired
};
ObjectiveKpiCommon.defaultProps = {
  authUser: "",
  importObjectiveKPI: [],
  objectType: "",
  centralOkrData: [],
  leaderShipOkrData: [],
  brandValueOkrData: [],
  variablePerc: 0,
  userProductiveAlloc: [],
};
const mapStateToProps = ({ auth, okrreducer }) => {
  const { authUser } = auth;
  const {
    allOkrKpi, employeeId, reRenderComponent, centralOkrData, leaderShipOkrData, brandValueOkrData, userObjectStatus, okrMasters } = okrreducer;
  return {
    authUser, allOkrKpi, employeeId, reRenderComponent, centralOkrData, leaderShipOkrData, brandValueOkrData, userObjectStatus, okrMasters
  };
};

export default connect(mapStateToProps, {
  addUpdateObjectveKpi,
  setOKRUserKPI,
  setUserOKRObjectiveKPI,
  setCentralOkrKPIRedux,
})(ObjectiveKpiCommon);
