/**
 * File Name:revokeprejoining.js
 * Author: Vivek Khodade.
 * Last Modify:08-May-2020
 * Modified By:Vivek Khodade
 */
import * as React from "react";
import { Internationalization } from "@syncfusion/ej2-base";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import { getFormatDateForGrid, getDisplayDateOnly } from "../../../app/other/commonfunction";
import { DateFilterformat, urlConfig } from '../../../config/config';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  DetailRow,
  Inject,
  Page,
  Filter
} from "@syncfusion/ej2-react-grids";
import OnbordingServices from "../../../services/entryexit/onbordingServices";
import {
  saveCandidateData,
  updateFunctionalAssets,
  addUpdateBasicInfo
} from "../../../redux/actions/entryexit/onboardingaction";
import {
  getGridColumn,
  FUNCTION_CANDIDATE_NAME,
  DATE_OF_JOINING,
  FUNCTION_INITIAON,
  FUNCTION_STATUS,
  BAND
} from "../../../app/other/gridcolumns";

import FunctionsMultiSelectionButtonComponent from "../functionsmultiselectionbuttoncomponent/functionsmultiselectionbuttoncomponent";

const instance = new Internationalization();

class RevokePrejoining extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      employeeData: null,
      height:
      (window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight) - 188,
    gridHeight:(window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight) - 188
    };
    this.width =
      (window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth) - 100;
    this.Filter = {
      type: "Menu",
    };
    this.check = {
      type: "CheckBox",
    };
    this.select = {
      persistSelection: true,
      type: "Multiple",
      checkboxOnly: true,
    }
    this.format = (value) => {
      return instance.formatDate(value, { skeleton: "yMd", type: "date" });
    };
    this.template = this.gridTemplate.bind(this);
    this.saveCandidatedeteils = this.saveCandidatedeteils.bind(this);
    this.onQueryCellInfoPreJoining = this.onQueryCellInfoPreJoining.bind(this);
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.functionalAssetStatusRevo === 'updated') {
      this.refreshFunctionalAssetList();
      // eslint-disable-next-line
      this.props.addUpdateBasicInfo({ functionalAssetStatusRevo: "" });
    }
  }
  /**
  * Calculate & Update state of new dimensions
  */
 updateDimensions() {
  let update_width = window.innerWidth - 100;
  let update_height = window.innerHeight - 140;
  let gridHeight = window.innerHeight - 250;
  this.setState({ width: update_width, height: update_height, gridHeight:gridHeight });
}
  /**
   * Function Name:saveCandidatedeteils
   * Update the employee Asset list
   */
  saveCandidatedeteils = (payload) => {
    // eslint-disable-next-line
    this.props.updateFunctionalAssets(payload);
  };

  /**
   * @author:Vivek Khodade
   * @function:statusTemplate
   * @description:Status column template code.
   */
  statusTemplate = (props) => {
    // Admin template document status
    if (props.CheckListStatus === "Completed") {
      return (
        <div
          style={{ borderLeft: "3px solid green", padding: "7px 0px 8px 5px" }}
        >
          <span id="">{props.CheckListStatus}</span>
          <span id="" />
          <br />
        </div>
      );
    }
    if (props.CheckListStatus === "Partial") {
      return (
        <div style={{ borderLeft: "3px solid #f68104", padding: "7px 0px 8px 5px" }}>
          <span id="">{props.CheckListStatus}</span>
          <span id="" />
          <br />
        </div>
      );
    }
    if (props.CheckListStatus === "Pending") {
      return (
        <div
          style={{
            borderLeft: "3px solid gray",
            padding: "7px 0px 8px 5px",
          }}
        >
          <span id="">{props.CheckListStatus}</span>
          <span id="" />
          <br />
        </div>
      );
    }
    return false;
  };

  /**
   * @function: onQueryCellInfoPreJoining
   * @description:Overwrite the css of grid comonent
   */
  onQueryCellInfoPreJoining = (args) => {
    const { cell } = args;
    cell.setAttribute(
      "style",
      "font-size:14px !important; color:#4B4A52 !important; text-align: left !important; padding-left:18px !important"
    );
  };

  /**
   * Function Name: dateFormat
   * Description: To format the Date
   */
  joiningDateFormat = (props) => {
    return (
      <div style={{ textAlign: "center" }}>
        <span>{getDisplayDateOnly(new Date(props.JoiningDate))}</span>
      </div>
    );
  };

  initiatedDateFormat = (props) => {
    return (
      <div style={{ textAlign: "center" }}>
        <span>{getDisplayDateOnly(new Date(props.InitiateOn))}</span>
      </div>
    );
  };

  componentDidMount = () => {
    this.applyCss();
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
  };

  /**
   * @function: applyCss
   * @description: apply the Custom css to grid
   */
  applyCss = () => {
    this.getFunctionalAssetList();
    setTimeout(() => {
      const ele = document.getElementsByClassName("e-headercelldiv");
      for(let i in ele) {
        if(!isNaN(parseInt(i, 10))){
          let index = parseInt(i, 10);
          if (ele[index].innerText === DATE_OF_JOINING || ele[index].innerText === FUNCTION_INITIAON ) {
            ele[index].classList.add("grid-center-header");
          } else {
            ele[index].classList.add("grid-left-header");
          }
        }
      }
    }, 1000);
  };

  /**
   * Vivek Khodade
   * @param {*Initiate} typeid type id
   * @param {*authUser} authUser user id
   * get the functional asset list.
   */
  getFunctionalAssetList = () => {
    try {
      const { authUser, getRevokePrejoingCount } = this.props;
      const typeid = 4;
      const candidateAssetList = Promise.resolve(
        OnbordingServices.getFunctionalAssetList(typeid, authUser)
      );
      candidateAssetList.then((result) => {
        if (result !== null) {
          this.setState({
            employeeData:  getFormatDateForGrid(result),
          });

          getRevokePrejoingCount(result.length);
          // setTimeout(() => {
          //     if (this.gridObj !== undefined && this.gridObj !== null) {
          //         // this.gridObj.refreshing=true
          //         this.gridObj.refresh()
          //         this.applyCss()
          //         console.log("RevokePrejoining this.gridObj", this.gridObj)
          //     }
          // }, 3000)
          //   // this.RoleTabData = result;
        } else {
          this.setState({
            employeeData: "error",
          });
        }
      });
    } catch (error) {
      this.setState({
        employeeData: "error",
      });
    }
  };
  /**
   * Vivek Khodade
   * @param {*Initiate} typeid type id
   * @param {*authUser} authUser user id
   * refresh the functional asset list.
   */
  refreshFunctionalAssetList = () => {
    try {
      const { authUser } = this.props;
      const typeid = 4;
      const candidateAssetList = Promise.resolve(
        OnbordingServices.getFunctionalAssetList(typeid, authUser)
      );
      candidateAssetList.then((result) => {
        if (result !== null) {
          this.setState({
            employeeData: result,
          });
        } else {
          this.setState({
            employeeData: "error",
          });
        }
      });
    } catch (error) {
      this.setState({
        employeeData: "error",
      });
    }
  };
  addDefaultSrc(ev) {
    ev.target.parentNode.innerHTML = '<span  class="img-circle fas fa-user-circle img-circle-grid-photo" alt="" ></span>'
  }
  /*
   * Function Name:candidteNameTemplate
   * @param {* candidate names text} props
   */
  candidteNameTemplate = (props) => {
    return (
      <div
        className="row control-section col-md-12"
        style={{ flexWrap: "wrap", display: "-webkit-box" }}
      >
        <div className="col-xs-2 col-sm-2 col-lg-2 col-md-12">
          {/* <center><img className="img-circle" src={require("../../../app/containers/tmlc/onboarding/avatar-s-16.png")} alt="" style={{ width: "35px", height: "35px", borderRadius:'17.5px' }} /></center> */}
          {props.PhotoNm === "" ? (
            <center>
              {/* eslint-disable-next-line */}
              <img
                className="img-circle"
                src={
                  // eslint-disable-next-line
                  require("../../../app/containers/tmlc/onboarding/avatar-s-16.png")
                }
                alt=""
                style={{
                  width: "35px",
                  height: "35px",
                  borderRadius: "17.5px",
                }}
              />
            </center>
          ) : (
              <center>
                <img
                  className="img-circle"
                  onError={this.addDefaultSrc}
                  src={
                    // eslint-disable-next-line
                    `${urlConfig.tmlcApiUrl}document/A/` + `0/${props.PhotoNm}`
                  }
                  alt=""
                  style={{
                    width: "35px",
                    height: "35px",
                    borderRadius: "17.5px",
                  }}
                />
              </center>
            )}
        </div>
        <div
          className="col-xs-2 col-sm-2 col-lg-8 col-md-4"
          style={{ fontSize: 14, color: "#4b4a52", }}
        >
          <span className="span">{props.CandidateName}</span>
          <br />
          <TooltipComponent
            closeOn="Click"
            content={props.Role}
            position="BottomCenter"
            isSticky={false}
            cssClass='customtooltip'
          >
            <span className="span text-limit" style={{ fontSize: "13px" }}>
              {props.Role}
            </span>
          </TooltipComponent>
        </div>
      </div>
    );
  };

  /**
   *
   * @param {*} typeid type id
   * @param {*} authUserid user id
   * set the functional asset list.
   */
  gridTemplate(props) {
    const typeid = 4;
    const { Data, CandidateId } = props;
    const { authUser } = this.props;
    return (
      <FunctionsMultiSelectionButtonComponent
        tabData={Data}
        typeid={typeid}
        candidateid={CandidateId}
        authUserid={authUser}
        saveCandidatedeteils={this.saveCandidatedeteils}
      />
    );
  }

  render() {
    const { employeeData } = this.state;
    var widthColumn = 0;
    var finalWidth;
    // eslint-disable-next-line 
    getGridColumn("InitiatePreJoining").forEach((data) => {
      widthColumn = widthColumn + 25 + data.width;
    });
    if (widthColumn <= this.width) {
      finalWidth = widthColumn;
    } else {
      finalWidth = this.width
    }
    return (
      <div className="control-pane">
        <div
          className="control-section"
          style={{
            overflowY: "hidden",
            overflowX:'hidden',
            height: this.state.height
              // (window.innerHeight ||
              //   document.documentElement.clientHeight ||
              //   document.body.clientHeight) > 767
              //   ? (window.innerHeight ||
              //     document.documentElement.clientHeight ||
              //     document.body.clientHeight) / 1.17
              //   : (window.innerHeight ||
              //     document.documentElement.clientHeight ||
              //     document.body.clientHeight) / 1.27,
            // height: (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight) / 1.27
          }}
        >
          <GridComponent
            id="revokegrid"

            height={ this.state.gridHeight
              // (window.innerHeight ||
              //   document.documentElement.clientHeight ||
              //   document.body.clientHeight) > 767
              //   ? (window.innerHeight ||
              //     document.documentElement.clientHeight ||
              //     document.body.clientHeight) / 1.4
              //   : (window.innerHeight ||
              //     document.documentElement.clientHeight ||
              //     document.body.clientHeight) / 1.6
            }
            // height={(window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight) / 1.5}
            queryCellInfo={this.onQueryCellInfoPreJoining}
            allowPaging
            allowFiltering
            allowSorting
            allowSelection
            filterSettings={this.Filter}
            pageSettings={{
              pageSize:
                (window.innerHeight ||
                  document.documentElement.clientHeight ||
                  document.body.clientHeight) > 767
                  ? 11
                  : 7,
              pageCount: 5
            }}
            // pageSettings={{ pageSize: 7, }}
            dataSource={employeeData}
            selectionSettings={this.select}
            detailTemplate={this.template}
            allowTextWrap={true}
            width={`${finalWidth}`}

          >
            <ColumnsDirective>
              {/* <ColumnDirective textAlign="Left !important" field='CandidateName' headerText='Name' width='300' template={this.candidteNameTemplate} />
                        <ColumnDirective textAlign="Left" field='Department' headerText='Service Division / Sales Unit' width='200' />
                        <ColumnDirective textAlign="Left" field='Pnl' headerText='P&L' width='120' />
                        <ColumnDirective textAlign="Center" field='JoiningDate' headerText='Joining On' template={this.joiningDateFormat} width='150' />
                        <ColumnDirective textAlign="Center" field='InitiateOn' headerText='Initiate On' template={this.initiatedDateFormat} width='150'  allowFiltering={false} />
                        <ColumnDirective textAlign="Left" field='ReportingTo' headerText='Reporting To' width='180' />
                        <ColumnDirective textAlign="Left" field='CheckListStatus' headerText='Status' width='70' template={this.statusTemplate} /> */}
              {getGridColumn("InitiatePreJoining").map((item) => {
                switch (item.colName) {
                  case FUNCTION_CANDIDATE_NAME:
                    return (
                      <ColumnDirective
                        key={item.field}
                        textAlign={`${item.align} !important`}
                        template={this.candidteNameTemplate}
                        field={item.field}
                        headerText={item.colName}
                        width={item.width}
                        filter={this.Filter}
                      />
                    );
                  case DATE_OF_JOINING:
                    return (
                      <ColumnDirective
                        key={item.field}
                        filter={this.check}
                        textAlign={`${item.align} !important`}
                        template={this.joiningDateFormat}
                        field={item.field}
                        headerText={item.colName}
                        width={item.width}
                        format={DateFilterformat}
                      />
                    );
                  case FUNCTION_INITIAON:
                    return (
                      <ColumnDirective
                        key={item.field}
                        filter={this.check}
                        textAlign={`${item.align} !important`}
                        template={this.initiatedDateFormat}
                        field={item.field}
                        headerText={item.colName}
                        width={item.width}
                        format={DateFilterformat}
                      />
                    );
                  case FUNCTION_STATUS:
                    return (
                      <ColumnDirective
                        key={item.field}
                        filter={false}
                        textAlign={`${item.align} !important`}
                        template={this.statusTemplate}
                        field={item.field}
                        headerText={item.colName}
                        width={item.width}
                        allowFiltering={false}
                      />
                    );
                    case BAND:
                    return (
                      <ColumnDirective
                        key={item.field}
                        filter={this.check}
                        textAlign={`${item.align} !important`}
                        field={item.field}
                        headerText={item.colName}
                        width={item.width}
                      />
                    );
                  default:
                    return (
                      <ColumnDirective
                        key={item.field}
                        textAlign={`${item.align} !important`}
                        field={item.field}
                        headerText={item.colName}
                        width={item.width}
                        filter={this.check}
                      />
                    );
                }
              })}
            </ColumnsDirective>
            <Inject services={[Page, DetailRow, Filter]} />
          </GridComponent>
        </div>
      </div>
    );
  }
}
RevokePrejoining.propTypes = {
  authUser: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  functionalAssetStatusRevo: PropTypes.string,
  getRevokePrejoingCount: PropTypes.func.isRequired,
};
RevokePrejoining.defaultProps = {
  authUser: "",
  functionalAssetStatusRevo: "",
};
const mapStateToProps = ({ auth, onboardingreducer }) => {
  const { authUser } = auth;
  const { functionalAssetStatusRevo } = onboardingreducer;
  return { authUser, functionalAssetStatusRevo };
}
export default connect(mapStateToProps, {
  saveCandidateData,
  updateFunctionalAssets,
  addUpdateBasicInfo
})(RevokePrejoining);
