import React from 'react'
import fileIcon from "../../../src/assets/images/no_extn.png";
import txtfile from '../../../src/assets/images/txt.png'
import xlsfile from '../../../src/assets/images/xls.png'
import pdffile from '../../../src/assets/images/pdf.png'
import pptfile from '../../../src/assets/images/ppt.png'
import zipfile from '../../../src/assets/images/zip.png'
import wordfile from '../../../src/assets/images/word.png'
import noFormatIcon from "../../../src/assets/png/png/big/no-format-icon.png";
import excelIcon from "../../../src/assets/png/png/big/excel-icon.png";
import docIcon from "../../../src/assets/png/png/big/docx-icon.png";
import imageIcon from "../../../src/assets/png/png/big/image-icon.png";
import pptIcon from "../../../src/assets/png/png/big/ppt-icon.png";
import pdfIcon from "../../../src/assets/png/png/big/pdf-file-icon.png";
import textIcon from "../../../src/assets/png/png/big/text-file-icon.png";
import zipIcon from "../../../src/assets/png/png/big/zipfile-icon.png";
import deleteIcon from "../../../src/assets/png/png/big/delete-icon.png";
import deleteIcon2 from "../../../src/assets/svg/delete-icon.svg";

import { urlConfig } from '../../config/config';
import dateFnsUtils from '@date-io/date-fns';
import NewConfirmDialog from '../confirmdialog/newconfirmdialog';
import '../objective/tags.css';
import ConfirmationPopup from '../../utilities/confirmationpopup';

// ".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"
const getFileTypeIcon = (Files) => {
    let fileFormatIcon;
    let fileType = getFileExtension(Files)
    switch (fileType) {
        case 'txt':
            fileFormatIcon = txtfile
            break;
        case 'csv':
        case 'xlsx':
        case 'xls':
            fileFormatIcon = xlsfile
            break;
        case 'pdf':
            fileFormatIcon = pdffile
            break;
        case 'ppt':
        case 'pptx':
            fileFormatIcon = pptfile
            break;
        case 'zip':
            fileFormatIcon = zipfile
            break;
        case 'doc':
        case 'docx':
            fileFormatIcon = wordfile
            break;
        default:
            fileFormatIcon = fileIcon
            break;
    }
    return fileFormatIcon
}

export const getFileIcon = (Files) => {
    let fileFormatIcon;
    let fileType = getFileExtension(Files)
    switch (fileType) {
        case 'txt':
            fileFormatIcon = textIcon
            break;
        case 'csv':
        case 'xlsx':
        case 'xls':
            fileFormatIcon = excelIcon
            break;
        case 'pdf':
            fileFormatIcon = pdfIcon
            break;
        case 'ppt':
        case 'pptx':
            fileFormatIcon = pptIcon
            break;
        case 'zip':
            fileFormatIcon = zipIcon
            break;
        case 'doc':
        case 'docx':
            fileFormatIcon = docIcon
            break;
        case 'jpeg':
        case 'JPEG':
        case 'jpg':
        case 'JPG':
        case 'png':
        case 'PNG':
        case 'svg':
        case 'SVG':
        case 'EPS':
        case 'eps':
        case 'GIF':
        case 'gif':
        case 'GIF':
        case 'BMP':
        case 'bmp':
        case 'TIF':
        case 'tif':
        case 'TIFF':
        case 'tiff':
            fileFormatIcon = imageIcon
            break;
        default:
            fileFormatIcon = noFormatIcon
            break;
    }
    return fileFormatIcon
}

const getFileExtension = (filename) => {
    let ext = filename ? filename.split('.').pop().toLowerCase() : "";
    if (ext === filename) return "";
    return ext;
}

const adminMasterTypes = ["leave", "daybreak", "workday", "weekday", "optionalholiday", "holiday", "statutory"]

export const createImageFilepath = (attachmentFiles, url) => {
    const displayTime = (tStamp) => {
        const dateFns = new dateFnsUtils();
        return dateFns.format(new Date(tStamp), "dd-MMM-yyyy HH:mm");
    }
    return (
        <>
            {
                attachmentFiles.length > 0 ?
                    attachmentFiles.map((attachedFile, indDraft) => {
                        return (
                            <>
                                {attachedFile !== "" &&
                                    <span key={`draft${indDraft}`}>
                                        <div className="image-container" style={{ display: 'flex', flexDirection: 'row', width: 280, marginTop: 8 }}>
                                            <span style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}><img style={{ marginTop: 6 }} alt="" src={getFileIcon(attachedFile)} /></span>

                                            <div style={{ padding: "0px 8px 0px 8px", flexDirection: 'column', justifyContent: 'center', marginTop: 6 }}>
                                                <a target={getFileExtension(attachedFile) === "txt" || getFileExtension(attachedFile) === "pdf" ||
                                                    getFileExtension(attachedFile) === "jpeg" || getFileExtension(attachedFile) === "JPEG" || getFileExtension(attachedFile) === "jpg" ||
                                                    getFileExtension(attachedFile) === "JPG" || getFileExtension(attachedFile) === "png" || getFileExtension(attachedFile) === "PNG" ||
                                                    getFileExtension(attachedFile) === "svg" || getFileExtension(attachedFile) === "SVG" || getFileExtension(attachedFile) === "EPS" ||
                                                    getFileExtension(attachedFile) === "eps" || getFileExtension(attachedFile) === "GIF" || getFileExtension(attachedFile) === "gif" ||
                                                    getFileExtension(attachedFile) === "BMP" || getFileExtension(attachedFile) === "bmp" || getFileExtension(attachedFile) === "TIF" ||
                                                    getFileExtension(attachedFile) === "tif" || getFileExtension(attachedFile) === "TIFF" || getFileExtension(attachedFile) === "tiff" ? "_blank" : null} href={
                                                        attachedFile !== null && attachedFile !== ""
                                                            ? `${urlConfig.tmlcApiUrl}${url}/${attachedFile}`
                                                            : ""}
                                                    style={{ textDecoration: 'none' }} rel="noopener noreferrer" download>
                                                    <span className='interim-link-view-text' id='interim-link' style={{ color: 'black' }} title={attachedFile}>
                                                        {attachedFile.substring(11, attachedFile.length).length > 25 ?
                                                            attachedFile.substring(11, attachedFile.length).substr(0, 25) + '...'
                                                            :
                                                            attachedFile
                                                        }
                                                    </span>
                                                </a>
                                                <div style={{ color: "#b0b0b0" }}>{displayTime((new Date((attachedFile.split('_')[0]) * 1000)))}</div>
                                            </div>
                                        </div>
                                    </span>
                                }
                            </>
                        )
                    })
                    :
                    <div className='ml-md'></div>
            }
        </>
    )
}


export const createImageFilepathForLPView = (attachmentFiles, url, configPath, removeFile) => {
    const displayTime = (tStamp) => {
        const dateFns = new dateFnsUtils();
        return dateFns.format(new Date(tStamp), "dd-MMM-yyyy HH:mm");
    }
    const path = configPath === 'lnaApiUrl' ? urlConfig.lnaApiUrl : urlConfig.tmlcApiUrl
    return (
        <>
            {
                attachmentFiles.length > 0 ?
                    attachmentFiles.map((attachedFile, indDraft) => {
                        return (
                            <>
                                {attachedFile &&
                                    <div key={`draft${indDraft}`}>
                                        <div className="image-container" style={{ display: 'flex', flexDirection: 'row', width: 200, marginTop: 8 }}>
                                            <span style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}><img style={{ marginTop: 6 }} alt="" src={getFileIcon(attachedFile)} /></span>

                                            <div style={{ padding: "0px 8px 0px 8px", flexDirection: 'column', justifyContent: 'center', marginTop: 6, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                <a target={getFileExtension(attachedFile) === "txt" || getFileExtension(attachedFile) === "pdf" ||
                                                    getFileExtension(attachedFile) === "jpeg" || getFileExtension(attachedFile) === "JPEG" || getFileExtension(attachedFile) === "jpg" ||
                                                    getFileExtension(attachedFile) === "JPG" || getFileExtension(attachedFile) === "png" || getFileExtension(attachedFile) === "PNG" ||
                                                    getFileExtension(attachedFile) === "svg" || getFileExtension(attachedFile) === "SVG" || getFileExtension(attachedFile) === "EPS" ||
                                                    getFileExtension(attachedFile) === "eps" || getFileExtension(attachedFile) === "GIF" || getFileExtension(attachedFile) === "gif" ||
                                                    getFileExtension(attachedFile) === "BMP" || getFileExtension(attachedFile) === "bmp" || getFileExtension(attachedFile) === "TIF" ||
                                                    getFileExtension(attachedFile) === "tif" || getFileExtension(attachedFile) === "TIFF" || getFileExtension(attachedFile) === "tiff" ? "_blank" : null} href={
                                                        attachedFile !== null && attachedFile !== ""
                                                            ? `${path}${url}/${attachedFile}`
                                                            : ""}
                                                    style={{ textDecoration: 'none' }} rel="noopener noreferrer" download>
                                                    <div className='c' style={{ color: 'black', width: 132, textOverflow: 'ellipsis', overflow: 'hidden' }} title={attachedFile ? attachedFile : null}>
                                                        {attachedFile && attachedFile.split('_')[1].substring(11, attachedFile.length).length > 15 ?
                                                            attachedFile.split('_')[1].substring(11, attachedFile.length).substr(0, 15) + '...'
                                                            :
                                                            attachedFile.split('_')[1]
                                                        }
                                                    </div>
                                                </a>
                                                <div style={{ color: "#b0b0b0", }}>{displayTime((new Date((attachedFile.split('_')[0]) * 1000)))}</div>
                                                <span className='tag-close-icon'
                                                    id='tag-close-icon'
                                                    style={{
                                                        height: 20,
                                                        width: 20,
                                                        marginTop: -40,
                                                        marginLeft: 150,
                                                        color: 'black',
                                                        position: 'absolute',
                                                        // background: 'lightgrey',
                                                        // backgroundColor: isHovered ? "#F84F5E" : "#DBDBDD",
                                                        backgroundColor: "#DBDBDD",
                                                    }}
                                                    //  onMouseEnter={() => setIsHovered(true)}
                                                    //  onMouseLeave={() => setIsHovered(false)}
                                                    onClick={() => removeFile()}>x</span>

                                            </div>
                                        </div>
                                    </div>
                                }
                            </>
                        )
                    })
                    :
                    <div className='ml-md'></div>
            }
        </>
    )
}

export function UploadCommonDocFilepath(props) {

    const availableExtensions = ["txt", "pdf", "jpeg", "JPEG", "jpg", "JPG", "png", "PNG", "svg", "SVG", "EPS", "eps", "GIF", "gif", "BMP", "bmp", "TIF", "tif", "TIFF", "tiff"];
    const displayTime = (time) => {
        const dateFns = new dateFnsUtils();
        return dateFns.format(new Date(time), "dd-MMM-yyyy HH:mm");
    }

    const getResolutionText = (attachedFile, type) => {

        if (type === "hdResolution") {

            return (attachedFile.substring(11, attachedFile.length).length > 36 ?
                <>
                    {attachedFile.substring(11, attachedFile.length).substr(0, 40)}
                    <br />
                    {attachedFile.substring(11, attachedFile.length).length > 80 ?
                        attachedFile.substring(11, attachedFile.length).substr(40, 40) + '...' :
                        attachedFile.substring(11, attachedFile.length).substr(40, 39)
                    }
                </>
                :
                attachedFile.substring(11, attachedFile.length));
        }

        if (type === "mediumResolution") {
            return (attachedFile.substring(11, attachedFile.length).length > 28 ?
                <>
                    {attachedFile.substring(11, attachedFile.length).substr(0, 28)}
                    <br />
                    {attachedFile.substring(11, attachedFile.length).length > 56 ?
                        attachedFile.substring(11, attachedFile.length).substr(28, 28) + '...' :
                        attachedFile.substring(11, attachedFile.length).substr(28, 27)
                    }
                </>
                :
                attachedFile.substring(11, attachedFile.length))
        }

        if (type === "smallResolution") {
            return (attachedFile.substring(11, attachedFile.length).length > 20 ?
                <>
                    {attachedFile.substring(11, attachedFile.length).substr(0, 20)}
                    <br />
                    {attachedFile.substring(11, attachedFile.length).length > 40 ?
                        attachedFile.substring(11, attachedFile.length).substr(20, 20) + '...' :
                        attachedFile.substring(11, attachedFile.length).substr(20, 19)
                    }
                </>
                :
                attachedFile.substring(11, attachedFile.length))
        }
    }

    const getPopupResolutionText = (attachedFile, type) => {
        if (type === "hdResolution") {
            if (props.filesType === 'rating-self') {
                return (attachedFile.substring(11, attachedFile.length).length > 13 ?
                    <>
                        {
                            attachedFile.substring(11, attachedFile.length).substr(0, 13) + '...'
                        }
                    </>
                    :
                    attachedFile.substring(11, attachedFile.length));
            } else {
                return (attachedFile.substring(11, attachedFile.length).length > 33 ?
                    <>
                        {attachedFile.substring(11, attachedFile.length).substr(0, 33)}
                        <br />
                        {attachedFile.substring(11, attachedFile.length).length > 66 ?
                            attachedFile.substring(11, attachedFile.length).substr(33, 33) + '...' :
                            attachedFile.substring(11, attachedFile.length).substr(33, 51)
                        }
                    </>
                    :
                    attachedFile.substring(11, attachedFile.length));
            }
        }

        if (type === "mediumResolution") {
            return (attachedFile.substring(11, attachedFile.length).length > 33 ?
                <>
                    {attachedFile.substring(11, attachedFile.length).substr(0, 33)}
                    <br />
                    {attachedFile.substring(11, attachedFile.length).length > 66 ?
                        attachedFile.substring(11, attachedFile.length).substr(33, 33) + '...' :
                        attachedFile.substring(11, attachedFile.length).substr(33, 51)
                    }
                </>
                :
                attachedFile.substring(11, attachedFile.length))
        }

        if (type === "smallResolution") {
            if (props.filesType === 'rating-self') {
                return (attachedFile.substring(11, attachedFile.length).length > 13 ?
                    <>
                        {
                            attachedFile.substring(11, attachedFile.length).substr(0, 13) + '...'
                        }
                    </>
                    :
                    attachedFile.substring(11, attachedFile.length));
            } else {
                return (attachedFile.substring(11, attachedFile.length).length > 33 ?
                    <>
                        {attachedFile.substring(11, attachedFile.length).substr(0, 33)}
                        <br />
                        {attachedFile.substring(11, attachedFile.length).length > 66 ?
                            attachedFile.substring(11, attachedFile.length).substr(33, 33) + '...' :
                            attachedFile.substring(11, attachedFile.length).substr(33, 51)
                        }
                    </>
                    :
                    attachedFile.substring(11, attachedFile.length))
            }
        }
    }



    const cols = typeof props.cols === undefined ? 3 : props.cols
    return (
        <div
            className={`col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 ${props.filesType === "rating-self" ? "" : "pr-sm pl-sm"} documents-container`}
            style={{ padding: props.filesType === "rating-self" ? 0 : "", marginLeft: props.filesType === "rating-self" ? 0 : "", marginRight: props.filesType === "rating-self" ? 0 : "" }}
        >
            {
                props.attachmentFiles.length > 0 &&
                props.attachmentFiles.map((attachedFile, indDraft) => {
                    return (
                        attachedFile !== "" && <>
                            {props.filesType === "rating-self" ? (
                                <div className={`col-${cols} col-lg-${cols} col-md-${cols} col-sm-${cols} col-xs-${cols} self-rating-files`} style={{ paddingLeft: 0 }} key={indDraft}>
                                    <div className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pt-sm pr-sm pb-sm pl-sm individual-document-container">
                                        <div className="image-and-file-container">
                                            <div className="image-container">
                                                <img alt="" src={getFileIcon(attachedFile)} />
                                            </div>
                                            <div className="filename-and-date-container">
                                                <div className="filename-container">
                                                    <a target={availableExtensions.includes(getFileExtension(attachedFile)) ? "_blank" : null} href={`${urlConfig.tmlcApiUrl}${props.url}/${attachedFile}`}
                                                        style={{ textDecoration: 'none' }} rel="noopener noreferrer" download>
                                                        <span className="filename-text" style={{ color: 'black' }}>
                                                            {window.innerHeight < 651 ? getPopupResolutionText(attachedFile, "smallResolution") :
                                                                window.innerHeight < 901 ? getPopupResolutionText(attachedFile, "mediumResolution") :
                                                                    getPopupResolutionText(attachedFile, "hdResolution")
                                                            }
                                                            <span className="tooltiptext">{attachedFile.substring(11, attachedFile.length)}</span>
                                                        </span>
                                                    </a>
                                                </div>
                                                <div className="date-time-container">{displayTime((new Date((attachedFile.split('_')[0]) * 1000)))}</div>
                                            </div>
                                        </div>
                                        <div
                                            className="delete-icon-container"
                                            onClick={() => props.openDeletePopup(indDraft)}
                                        >
                                            <img alt="" src={deleteIcon} />
                                        </div>
                                    </div>
                                </div>) : (
                                <div className={`col-${cols} col-lg-${cols} col-md-${cols} col-sm-${cols} col-xs-${cols} pr-lg pb-sm`} style={{ paddingLeft: ".5rem" }} key={indDraft}>
                                    <div className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pt-sm pr-sm pb-sm pl-sm individual-document-container">
                                        <div className="image-and-file-container">
                                            <div className="image-container">
                                                <img alt="" src={getFileIcon(attachedFile)} />
                                            </div>
                                            <div className="filename-and-date-container">
                                                <div className="filename-container">
                                                    <a target={availableExtensions.includes(getFileExtension(attachedFile)) ? "_blank" : null} href={`${urlConfig.tmlcApiUrl}${props.url}/${attachedFile}`}
                                                        style={{ textDecoration: 'none' }} rel="noopener noreferrer" download>
                                                        <span className="filename-text" style={{ color: 'black' }}>
                                                            {window.innerHeight < 651 ? getResolutionText(attachedFile, "smallResolution") :
                                                                window.innerHeight < 901 ? getResolutionText(attachedFile, "mediumResolution") :
                                                                    getResolutionText(attachedFile, "hdResolution")
                                                            }
                                                            <span className="tooltiptext">{attachedFile.substring(11, attachedFile.length)}</span>
                                                        </span>
                                                    </a>
                                                </div>
                                                <div className="date-time-container">{displayTime((new Date((attachedFile.split('_')[0]) * 1000)))}</div>
                                            </div>
                                        </div>
                                        {props.fileType === 'lpview' ? '' : adminMasterTypes.includes(props.type) ?

                                            <div
                                                className="delete-icon-container"
                                                onClick={() => props.openDeletePopup(indDraft)}
                                            >
                                                <img alt="" src={deleteIcon} />
                                            </div>
                                            :
                                            <>{props.authUser === props.docUploadedBy[indDraft] && <div
                                                className="delete-icon-container"
                                                onClick={() => props.openDeletePopup(indDraft)}
                                            >
                                                <img alt="" src={deleteIcon} />
                                            </div>}</>}
                                    </div>
                                </div>)}
                        </>)
                })
            }
            <NewConfirmDialog
                open={props.deleteListPopup}
                handleConfirmClose={() => props.handleConfirmClose()}
                title={"Delete"}
                message={"Are you sure you want to delete the document?"}
                handleConfirmYes={() => props.removeFile(props.deleteIdx)}
                button1Caption={"Delete"}
                handleConfirmNo={() => props.handleConfirmNo()}
                button2Caption={"Cancel"}
            />
        </div>
    )

}

export default function UploadedImageFilepath(props) {

    let path = props.apiUrl === 'document/upl/lna' || props.apiUrl === 'lnaApiUrl' ? urlConfig.lnaApiUrl : urlConfig.tmlcApiUrl;
    if (props.filesType === 'idea') {
        path = ''
    }

    const displayTime = (time) => {
        const dateFns = new dateFnsUtils();
        return dateFns.format(new Date(time), "dd-MMM-yyyy HH:mm");
    }
    return (
        <>
            {
                props.filesType !== "regularization" && props.attachmentFiles.length > 0 &&
                props.attachmentFiles.map((attachedFile, indDraft) => {
                    return (
                        attachedFile !== "" &&
                        <li key={indDraft} className={(props.filesType === "commonFiles") ? "tag tag-hover" : "tag"} style={{
                            height: (props.filesType === "commonFiles") ? "60px" : "30px",
                            background: (props.filesType === "commonFiles") ? "#f4f4f7" : "#F94F5E1A",
                        }}>
                            <div className={props.filesType === "commonFiles" ? "tag-date" : ""}>
                                <div className="tag-horizontal">
                                    <span className='tag-title' style={{ marginTop: props.filesType === "holiday" ? 18 : 0 }}>
                                        <span >
                                            <img alt="" src={getFileTypeIcon(attachedFile)} />
                                            <span style={{ padding: "0px 8px 0px 8px" }}>
                                                <a target={getFileExtension(attachedFile) === "txt" || getFileExtension(attachedFile) === "pdf" || getFileExtension(attachedFile) === "jpeg" ||
                                                    getFileExtension(attachedFile) === "JPEG" || getFileExtension(attachedFile) === "jpg" || getFileExtension(attachedFile) === "JPG" ||
                                                    getFileExtension(attachedFile) === "png" || getFileExtension(attachedFile) === "PNG" || getFileExtension(attachedFile) === "svg" ||
                                                    getFileExtension(attachedFile) === "SVG" || getFileExtension(attachedFile) === "EPS" || getFileExtension(attachedFile) === "eps" ||
                                                    getFileExtension(attachedFile) === "GIF" || getFileExtension(attachedFile) === "gif" || getFileExtension(attachedFile) === "BMP" ||
                                                    getFileExtension(attachedFile) === "bmp" || getFileExtension(attachedFile) === "TIF" || getFileExtension(attachedFile) === "tif" ||
                                                    getFileExtension(attachedFile) === "TIFF" || getFileExtension(attachedFile) === "tiff" ? "_blank" : null}
                                                    href={
                                                        (props.filesType === "comment" || props.filesType === "ticket") ?
                                                            URL.createObjectURL(props.files[indDraft])
                                                            : `${path}${(props.url === "document/upl/lna/leave") ? props.apiUrl : props.url}/${attachedFile}` //NOSONAR
                                                    }
                                                    style={{ textDecoration: 'none' }} rel="noopener noreferrer" download={attachedFile}>
                                                    <span className='file-text' style={{ color: 'black' }} title={attachedFile}>
                                                        {
                                                            (props.filesType === "ticket" || props.filesType === "comment") ? (
                                                                attachedFile.substring(0, attachedFile.length).length > 25 ? //NOSONAR
                                                                    attachedFile.substring(0, attachedFile.length).substr(0, 25) + '...'
                                                                    :
                                                                    attachedFile.substring(0, attachedFile.length)
                                                            ) :
                                                                (
                                                                    attachedFile.substring(11, attachedFile.length).length > 25 ? //NOSONAR
                                                                        attachedFile.substring(11, attachedFile.length).substr(0, 25) + '...'
                                                                        :
                                                                        attachedFile.substring(11, attachedFile.length)
                                                                )

                                                        }

                                                    </span>
                                                </a>
                                            </span>
                                        </span>
                                    </span>

                                    {props.filesType === "commonFiles" ? (
                                        <>
                                            <span className='tag-close-icon'
                                                style={{ height: 18, width: 18 }}
                                                onClick={() => props.openDeletePopup(indDraft)}>x</span>
                                        </>
                                    ) : (
                                        <span className='tag-close-icon'
                                            style={{
                                                height: 20,
                                                width: 20,
                                                marginTop: props.filesType === "holiday" ? 18 : 0,
                                                color: 'red',

                                            }}

                                            onClick={() => props.removeFile(indDraft)}>x</span>
                                    )}
                                </div>
                                <br />
                                {props.filesType === "commonFiles" && <div className="tag-time">{displayTime(props.filesTimeStamp[indDraft])}</div>}
                            </div>
                        </li>
                    )
                })
            }
            {
                props.filesType === "regularization" && props.attachmentFiles.length > 0 ?
                    props.attachmentFiles.map((attachedFile, indDraft) => {
                        return (
                            <>
                                {attachedFile &&
                                    <div key={`draft${indDraft}`} style={{ border: '1px solid lightgray', borderRadius: '5px', padding: '8px', marginRight: '1rem', marginBottom: '1rem' }}>
                                        <div className="image-container" style={{ display: 'flex', flexDirection: 'row', width: 200, marginRight: 25 }}>
                                            <span style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}><img alt="" src={getFileIcon(attachedFile)} /></span>

                                            <div style={{ padding: "0px 8px 0px 8px", flexDirection: 'column', justifyContent: 'center', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                <a target={getFileExtension(attachedFile) === "txt" || getFileExtension(attachedFile) === "pdf" ||
                                                    getFileExtension(attachedFile) === "jpeg" || getFileExtension(attachedFile) === "JPEG" || getFileExtension(attachedFile) === "jpg" ||
                                                    getFileExtension(attachedFile) === "JPG" || getFileExtension(attachedFile) === "png" || getFileExtension(attachedFile) === "PNG" ||
                                                    getFileExtension(attachedFile) === "svg" || getFileExtension(attachedFile) === "SVG" || getFileExtension(attachedFile) === "EPS" ||
                                                    getFileExtension(attachedFile) === "eps" || getFileExtension(attachedFile) === "GIF" || getFileExtension(attachedFile) === "gif" ||
                                                    getFileExtension(attachedFile) === "BMP" || getFileExtension(attachedFile) === "bmp" || getFileExtension(attachedFile) === "TIF" ||
                                                    getFileExtension(attachedFile) === "tif" || getFileExtension(attachedFile) === "TIFF" || getFileExtension(attachedFile) === "tiff" ? "_blank" : null} href={
                                                        attachedFile !== null && attachedFile !== ""
                                                            ? `${path}${props.url}/${attachedFile}`
                                                            : ""}
                                                    style={{ textDecoration: 'none' }} rel="noopener noreferrer" download>
                                                    <div className='c' style={{ color: 'black', width: 132, textOverflow: 'ellipsis', overflow: 'hidden' }} title={attachedFile ? attachedFile : null}>
                                                        {attachedFile && attachedFile.split('_')[1].substring(11, attachedFile.length).length > 15 ?
                                                            attachedFile.split('_')[1].substring(11, attachedFile.length).substr(0, 15) + '...'
                                                            :
                                                            attachedFile.split('_')[1]
                                                        }
                                                    </div>
                                                </a>
                                                <div style={{ color: "#b0b0b0", }}>{displayTime((new Date((attachedFile.split('_')[0]) * 1000)))}</div>
                                                <span id='tag-close-icon' onClick={() => props.removeFile(indDraft)}><img alt="" src={deleteIcon2} /></span>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </>
                        )
                    }) : <div className='ml-md'></div>
            }
            <ConfirmationPopup
                showButton={{ type: "1" }}
                open={props.deletePopup}
                button1function={() => props.handleConfirmNo()}
                button2function={() => props.removeFile(props.deleteIdx)}
                headerMessage={"Are you sure you want to delete the document?"}
                button1Text={"Cancel"}
                button2Text={"Delete"}
                handleConfirmClose={()=>props.handleConfirmClose()}
            />
        </>
    )
}