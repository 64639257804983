/**
 * Author Name : Vivek Khodade.
 * Created Date: -
 * Modified Date: 15/04/2020.
 * Last Modified Name: Vivek Khodade.
 * Last Modified By: Vivek Khodade.
 * Code Reviewed Date : 08/09/2020
 * Code Reviewed By : Vivek Khodade
 */
import * as React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import NotificationContainer from "../../../components/notification/notifycontainer";
import { Button } from "@material-ui/core";
import { Query } from "@syncfusion/ej2-data";
import ProgressBar from "../../../components/progressbar/progressbar";
import ErrorMessage from "./errorMessage";
import {
  setTIBMultipleUsersId,
  setTIBMultipleMonthsIds,
} from "../../../redux/actions/tibacton";
import tibrservices from "../../../services/tibservices";
import TIBAverageReport from "./tibaveragereport";
import UIFilters from "../../../components/uifilters/uifilters";
import USERFILTER from "../../../components/uifilters/userfilter";
import SDPracticeArea from "../../../components/uifilters/sdpracticearea";
import BucketCategoryViewBy from "../../../components/uifilters/bucketcategoryviewby";
import { getNotify, monthDiff } from "../../other/commonfunction";
import {
  ERROR,
  selectAtLeastOneOptionViewBy,
  selectAtLeastOneOptionBucket,
  selectAtLeastOneOptionSD,
  FromMonthShouldLesserThanToMonth,
  Max12MonthsAllowed,
} from "../../../../src/assets/constants/constants";
import "react-notifications/lib/notifications.css";
import { maxMonthRangeForTibAvgReport } from "../../../config/config";
import { Route } from "react-router-dom";
const notificationClass = `.notification-container {
  box-sizing: border-box;
    position: absolute;
    top: 2rem !important;
    right: 0 !important;
    z-index: 999999;
    width: 320px !important;
    padding: 0px 15px;
    max-height: none;
    overflow-x: hidden;
    overflow-y: auto;
}`;
class TibAverage extends React.Component {
  constructor(props) {
    super(props);
    // maps the appropriate column to fields property
    this.state = {
      tibrData: null,
      reportData: null,
      reportMonths: [],
      showError: false,
      changedData: false,
      progress: false,
      selectedUserId: [],
      selectedReportTypeIds: [1],
    };    
    this.selectedTIBUsersId = [];
    this.removeReportType = this.removeReportType.bind(this);
    this.getAvgReports = this.getAvgReports.bind(this);
    this.reportTypes = [
      { Id: 1, Name: "Exclude Weekend" },
      { Id: 2, Name: "Include Weekend" },
      { Id: 3, Name: "Working Weekend" },
    ];
  }

  // Here we called the close tooltip only
  // eslint-disable-next-line
  UNSAFE_componentWillMount() {
    // Close the tooltip if already opened
    // closeAgendaPopup();
  }

  /**
   * shouldComponentUpdate
   * @param {*} nextProps
   * @param {*} nextState
   * This funcion is manage the rerender of this component using change data state.
   */
  shouldComponentUpdate(nextProps, nextState) {
    const { changedData } = this.state;
    if (changedData !== nextState.changedData) {
      // Close the tooltip if already opened
      // closeAgendaPopup();
      return true;
    }
    return false;
  }

  /**
   * componentDidMount
   * Here we call the api servise name getUserTIBRData
   * Sevice gets the user data and bind into tree grid.
   */
  componentDidMount = async () => {
    try {
      this.updateDimensions();
      window.addEventListener("resize", this.updateDimensions.bind(this));
    } catch (error) {
      console.log("ERROR ===> ", error);
      this.setState({
        showError: true,
        errorMessage: "Ops! Something went wrong. please refresh............. ",
        tibrData: [],
        // eslint-disable-next-line
        changedData: !this.state.changedData,
        progress: false,
      });
    }
  };
  /**
   * removeMonths
   * @param {*} e :event
   * this function is used for to select the element of month remove dropdown data.
   */
  removeReportType(e) {
    // eslint-disable-next-line
    let selectedReportTypeIds = this.state.selectedReportTypeIds;
    // eslint-disable-next-line
    selectedReportTypeIds = selectedReportTypeIds.filter(
      (el) => el !== e.itemData.Id
    );
    if (selectedReportTypeIds.length === 0) {
      this.setState({ selectedReportTypeIds });
      document.getElementById("tib-avg-report-type").innerHTML =
        "* Please select month";
      return;
    }
    this.setState({
      selectedReportTypeIds,
    });
  }

  /**
   * handleFilteringByName
   * this function is used for to search data in select user dropdown.
   */
  handleFilteringByName = (e) => {
    const newData = [];
    // eslint-disable-next-line
    this.props.tibUserList.forEach((eachD) => {
      newData.push(eachD);
    });
    if (newData != null && newData.length > 0) {
      let query = new Query();
      query =
        e.text !== ""
          ? query.where("Display_name", "contains", e.text, true)
          : query;
      e.updateData(newData, query);
    }
  };

  /**
   * getSelectedMonths
   * @param {*} e :event
   * this function is used for to select the element of remove user dropdown data.
   */
  getSelectedReportType = (e) => {
    document.getElementById("tib-avg-report-type").innerHTML = "";
    // eslint-disable-next-line
    let selectedReportIds = this.state.selectedReportTypeIds;
    selectedReportIds.push(e.itemData.Id);
    this.setState(
      {
        selectedReportTypeIds: selectedReportIds,
      }
      // () => {
      //   // eslint-disable-next-line
      //   this.props.setTIBMultipleMonthsIds(this.state.selectedReportTypeIds)
      // }
    );
  };
  // eslint-disable-next-line
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }
  async formatDataForTreeGrid(data) /**nosonar**/ {
    //  NOSONAR
    var parentArr = [];
    // -- Build array for Bucket and PNL
    for (let k in data) {
      if (!isNaN(parseInt(k, 10))) {
        let index = parseInt(k, 10);
        let eachData = data[index];
        let bucketIndex;
        let pnlIndex;
        bucketIndex = parentArr.findIndex(
          (el) => el.bucket_id === eachData.bucket
        );
        if (bucketIndex === -1) {
          let bucketObj = {};
          bucketObj.bucket_id = eachData.bucket;
          bucketObj.entityName = eachData.bucket_name;
          bucketObj.empCount = eachData.bucket_wise_emp_count;
          bucketObj.avgHours = "-";
          bucketObj.service_division = "-";
          bucketObj.reporting_head = "-";
          bucketObj.subtask = [];
          let pnlObj = {};
          pnlObj.pnl_id = eachData.pnl_id;
          pnlObj.entityName = eachData.PNL;
          pnlObj.empCount = eachData.PNL_wise_emp_count;
          pnlObj.avgHours = "-";
          pnlObj.service_division = "-";
          pnlObj.reporting_head = "-";
          pnlObj.subtask = [];
          bucketObj.subtask.push(pnlObj);
          parentArr.push(bucketObj);
        } else {
          pnlIndex = parentArr[bucketIndex].subtask.findIndex(
            (e2) => e2.pnl_id === eachData.pnl_id
          );
          if (pnlIndex === -1) {
            let pnlObj = {};
            pnlObj.pnl_id = eachData.pnl_id;
            pnlObj.entityName = eachData.PNL;
            pnlObj.empCount = eachData.PNL_wise_emp_count;
            pnlObj.avgHours = "-";
            pnlObj.service_division = "-";
            pnlObj.reporting_head = "-";
            pnlObj.subtask = [];
            parentArr[bucketIndex].subtask.push(pnlObj);
          }
        }
      }
    }
    // -- Push User data to array
    for (let k in data) {
      if (!isNaN(parseInt(k, 10))) {
        let index = parseInt(k, 10);
        let eachData = data[index];
        let bucketIdx;
        let pnlIdx;
        let userIdx;
        var addUserFlag = true;
        var tibDynamic;
        var yrDynamic;
        var monDynamic;
        var dayDynamic;

        // eslint-disable-next-line
        parentArr.forEach((eachParent) => {
          eachParent.subtask.forEach((eachSubParent) => {
            userIdx = eachSubParent.subtask.findIndex(
              (e3) => e3.entityName.trim() === eachData.usr.trim()
            );
            if (userIdx > -1) {
              addUserFlag = false;
              let userObj = {};
              tibDynamic = "tib" + eachData.Mon + eachData.Yer;
              yrDynamic = "Year" + eachData.Mon + eachData.Yer;
              monDynamic = "Month" + eachData.Mon + eachData.Yer;
              dayDynamic = "Days" + eachData.Mon + eachData.Yer;
              userObj[tibDynamic] = eachData.user_wise_avg
                ? eachData.user_wise_avg.slice(0, 5)
                : "00:00";
              userObj[yrDynamic] = eachData.Yer;
              userObj[monDynamic] = eachData.Mon;
              userObj[dayDynamic] = eachData.Days;
              let pushObj = {
                ...eachSubParent.subtask[userIdx],
                ...userObj,
              };
              eachSubParent.subtask[userIdx] = pushObj;
            }
          });
        });

        if (addUserFlag) {
          bucketIdx = parentArr.findIndex(
            (d1) => d1.bucket_id === eachData.bucket
          );
          pnlIdx = parentArr[bucketIdx].subtask.findIndex(
            (d2) => d2.pnl_id === eachData.pnl_id
          );
          let userObj = {};
          userObj.entityName = eachData.usr;
          userObj.avgHours = eachData.user_wise_total_avg
            ? eachData.user_wise_total_avg.slice(0, 5)
            : "00:00";
          userObj.service_division = eachData.service_division;
          userObj.reporting_head = eachData.Reporting_Head;
          tibDynamic = "tib" + eachData.Mon + eachData.Yer;
          yrDynamic = "Year" + eachData.Mon + eachData.Yer;
          monDynamic = "Month" + eachData.Mon + eachData.Yer;
          dayDynamic = "Days" + eachData.Mon + eachData.Yer;
          userObj[tibDynamic] = eachData.user_wise_avg
            ? eachData.user_wise_avg.slice(0, 5)
            : "00:00";
          userObj[yrDynamic] = eachData.Yer;
          userObj[monDynamic] = eachData.Mon;
          userObj[dayDynamic] = eachData.Days;
          parentArr[bucketIdx].subtask[pnlIdx].subtask.push(userObj);
        }
      }
    }
    // -- Set Buckwise and PNL wise Employee count
    for (let y in parentArr) {
      if (!isNaN(parseInt(y, 10))) {
        let idex = parseInt(y, 10);
        let eachBucket = parentArr[idex];
        var bucketEmpCount = 0;
        if (eachBucket.subtask.length > 0) {
          // eslint-disable-next-line
          eachBucket.subtask.forEach((eachPnl) => {
            eachPnl.empCount = eachPnl.subtask.length;
            bucketEmpCount = bucketEmpCount + eachPnl.subtask.length;
          });
        }
        eachBucket.empCount = bucketEmpCount;
      }
    }
    return parentArr;
  }
  getReportMonthCols(data) {
    var result = [];
    data.filter(function (a) {
      var key = a.Mon + "|" + a.Yer;
      if (!this[key]) {
        this[key] = true;
        var monNumb =
          new Date(Date.parse(a.Mon + " 1, " + a.Yer)).getMonth() + 1;
        var monNumbTwoDigit = ("0" + monNumb.toString()).slice(-2);
        var dateStr = a.Yer + "-" + monNumbTwoDigit + "-01";
        result.push({ Year: a.Yer, Month: a.Mon, Date: dateStr });
      }
      return result;
    }, Object.create(null));
    result.sort(function (a, b) {
      return new Date(b.Date) - new Date(a.Date);
    });

    return result;
  }
  getAvgReports = () => /**nosonar**/ {
    // NOSONAR
    const { changedData } = this.state;
    const { authUser } = this.props;
    this.setState({ changedData: !changedData });
    const {
      tibAvgSelectedBucketIds,
      tibAvgSelectedViewByIds,
      tibAvgSelectedYearOption,
      tibAvgSelectedFy,
      tibAvgSelectedFyMonths,
      tibAvgSelectedFromMonth,
      tibAvgSelectedToMonth,
      tibAvgSelectedSd,
      tibAvgSelectedUserOption,
    } = this.props;
    var reportParams = [
      {
        authUserId: authUser,
        tibAvgSelectedBucketIds: tibAvgSelectedBucketIds,
        tibAvgSelectedViewByIds: tibAvgSelectedViewByIds,
        tibAvgSelectedYearOption: tibAvgSelectedYearOption,
        tibAvgSelectedFy: tibAvgSelectedFy,
        tibAvgSelectedFyMonths: tibAvgSelectedFyMonths,
        tibAvgSelectedFromMonth: tibAvgSelectedFromMonth,
        tibAvgSelectedToMonth: tibAvgSelectedToMonth,
        tibAvgSelectedSd: tibAvgSelectedSd,
        tibAvgSelectedUserOption: tibAvgSelectedUserOption,
      },
    ];
    if (tibAvgSelectedYearOption === 2) {
      if (new Date(tibAvgSelectedFromMonth) > new Date(tibAvgSelectedToMonth)) {
        getNotify(ERROR, FromMonthShouldLesserThanToMonth);
        return;
      } else {
        var monthDiffCount = monthDiff(
          new Date(tibAvgSelectedFromMonth),
          new Date(tibAvgSelectedToMonth)
        );
        if (monthDiffCount > maxMonthRangeForTibAvgReport) {
          getNotify(ERROR, Max12MonthsAllowed);
          return;
        }
      }
    } else if (tibAvgSelectedBucketIds.length === 0) {
      getNotify(ERROR, selectAtLeastOneOptionBucket);
      return;
    } else if (tibAvgSelectedViewByIds.length === 0) {
      getNotify(ERROR, selectAtLeastOneOptionViewBy);
      return;
    } else if (tibAvgSelectedSd.length === 0) {
      getNotify(ERROR, selectAtLeastOneOptionSD);
      return;
    }
    try {
      this.setState({ progress: true, changedData: !changedData });
      // eslint-disable-next-line
      // -- let reportDetails = Promise.resolve(tibrservices.getTibAvgReportData(this.state.selectedReportTypeIds.toString()));
      let reportDetails = Promise.resolve(
        tibrservices.getTibAvgReportData(reportParams)
      );
      reportDetails.then(async (result) => {
        var reportMonthCols = await this.getReportMonthCols(result); // NOSONAR
        var resultArr = [];
        for (const id of tibAvgSelectedViewByIds) {
          var resObj = {};
          let thisElemResult = result.filter(
            (val) => val.int_weekend_view === id
          );
          resObj.week = id;
          resObj.result = await this.formatDataForTreeGrid(thisElemResult); // NOSONAR
          resultArr.push(resObj);
        }
        if (resultArr !== null && resultArr.length > 0) {
          this.setState(
            {
              reportData: [],
              reportMonths: [],
            },
            () =>
              this.setState({
                reportData: resultArr,
                reportMonths: reportMonthCols,
                showError: false,
                errorMessage: "",
                // eslint-disable-next-line
                changedData: !changedData,
                progress: false,
              })
          );
        } else {
          this.setState({
            showError: true,
            errorMessage: "Currently No Data Available. ",
            reportData: [],
            reportMonths: [],
            // eslint-disable-next-line
            changedData: !changedData,
            progress: false,
          });
        }
        this.updateDimensions();
      });
    } catch (error) {
      console.log("ERROR NOW ===> ", error);
      this.setState({
        showError: true,
        errorMessage: "Ops! Something went wrong. please refresh. ",
        reportData: [],
        reportMonths: [],
        // eslint-disable-next-line
        changedData: !this.state.changedData,
        progress: false,
      });
    }
  };

  /**
   * Calculate & Update state of new dimensions
   */
  updateDimensions() {
    // eslint-disable-next-line
    let updateHeight =
      (window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight) - 135;
    if (document.querySelector(".usrsel") !== null) {
      updateHeight -= document.querySelector(".usrsel").offsetHeight;
    }
    // eslint-disable-next-line
    this.setState({
      WindowHeight: updateHeight,
      changedData: !this.state.changedData,
    });
  }
  UNSAFE_componentWillReceiveProps(newProps) {
    const { tibUserList, tibMonts, selectedTIBUsersId } = this.props;
    const { changedData } = this.state;
    if (
      tibUserList === null ||
      newProps.tibUserList.length !== tibUserList.length ||
      tibMonts === null ||
      newProps.tibMonts.length !== tibMonts.length ||
      (typeof selectedTIBUsersId !== "undefined" &&
        newProps.selectedTIBUsersId.length !== selectedTIBUsersId.length)
    ) {
      this.setState({ changedData: !changedData });
    }
  }

  render() {
    const { reportData, reportMonths } = this.state;
    return (
      // eslint-disable-next-line
      <div>
        <style>{notificationClass}</style>
        <NotificationContainer />
        <div
          className="col-lg-12 usrsel"
          style={{
            padding: 5,
            margin: "28px 10px -5px 9px",
            display: "flex",
          }}
        >
          <Route component={UIFilters} />
          <Route component={BucketCategoryViewBy} />
          <Route component={SDPracticeArea} />
          <Route component={USERFILTER} />

          <div
            className="col-lg-1 col-md-1 col-sm-1 col-xs-1"
            style={{ marginLeft: -30 }}
          >
            <div className="row pb-sm">
              <Button
                onClick={() => this.getAvgReports()}
                color="primary"
                variant="contained"
              >
                View
              </Button>
            </div>
          </div>
        </div>
        {reportData !== null && typeof reportData[0].result !== "undefined" && (
          <div style={{ fontSize: "12px", marginLeft: 24 }}>
            Day Count = Total number of days in the month where Input Time In
            Business is within the specified hours bucket
          </div>
        )}
        <div
          className="scrollbar"
          style={{
            // eslint-disable-next-line
            height: this.state.WindowHeight,
            width:
              (window.innerWidth ||
                document.documentElement.clientWidth ||
                document.body.clientWidth) - 90, // Add By Prashant Waphare | 30-06-2020 | fix width of container
            marginLeft: 18,
            marginRight: 15,
            overflow: "auto",
            // border: "solid 0.2px #ddd"
          }}
        >
          {reportData !== null &&
            typeof reportData[0].result !== "undefined" &&
            reportData.map((data, index) => {
              return (
                <React.Fragment key={index}>
                  <div
                    key={index}
                    className="overflow-hidden"
                    style={{ paddingTop: 5 }}
                  >
                    <TIBAverageReport
                      reportData={data.result}
                      reportMonths={reportMonths}
                      reportType={data.week}
                    />
                  </div>
                </React.Fragment>
              );
            })}
          {reportData !== null && reportData[0].result.length === 0 && (
            <ErrorMessage message={"No Data Available"} />
          )}
          {
            // eslint-disable-next-line
            this.state.progress && (
              <div
                style={{
                  zIndex: 999999,
                  position: "absolute",
                  marginTop: "20%",
                  marginLeft: "49%",
                }}
              >
                <ProgressBar />
              </div>
            )
          }
          {
            // eslint-disable-next-line
            this.state.showError && (
              <ErrorMessage message={this.state.errorMessage} />
            )
          }
        </div>
      </div>
    );
  }
}
TibAverage.propTypes = {
  authUser: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    selectedTIBUsersId: PropTypes.arrayOf(Object),
  selectedTIBMonthsId: PropTypes.arrayOf(Object),
  tibMonts: PropTypes.arrayOf(Object),
  tibUserList: PropTypes.arrayOf(Object),
  tibGrayLeaves: PropTypes.arrayOf(Object),
};
TibAverage.defaultProps = {
  authUser: "",
  // eslint-disable-next-line  
  selectedTIBUsersId: [],
  selectedTIBMonthsId: [],
  tibMonts: [],
  tibUserList: "",
  tibGrayLeaves: "",
};

const mapStateToProps = ({ auth, tibReducer }) => {
  const { authUser } = auth;
  // eslint-disable-next-line  
  const {
    selectedTIBUsersId,
    tibMonts,
    tibUserList,
    selectedTIBMonthsId,
    tibGrayLeaves,
    tibAvgSelectedBucketIds,
    tibAvgSelectedViewByIds,
    tibAvgSelectedYearOption,
    tibAvgSelectedFy,
    tibAvgSelectedFyMonths,
    tibAvgSelectedFromMonth,
    tibAvgSelectedToMonth,
    tibAvgSelectedSd,
    tibAvgSelectedUserOption,
  } = tibReducer;
  return {
    authUser,    
    selectedTIBUsersId,
    tibMonts,
    tibUserList,
    selectedTIBMonthsId,
    tibGrayLeaves,
    tibAvgSelectedBucketIds,
    tibAvgSelectedViewByIds,
    tibAvgSelectedYearOption,
    tibAvgSelectedFy,
    tibAvgSelectedFyMonths,
    tibAvgSelectedFromMonth,
    tibAvgSelectedToMonth,
    tibAvgSelectedSd,
    tibAvgSelectedUserOption,
  };
};

export default connect(mapStateToProps, {
  setTIBMultipleUsersId,
  setTIBMultipleMonthsIds,
})(TibAverage);
