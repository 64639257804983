import React, { Component } from "react";
import { connect } from "react-redux";
import OnleaveContainer from "../../containers/lna/onleavecontainer";
import LeavesContainer from "../../containers/lna/leavescontainer";
import SwipeContainer from "../../containers/lna/swipececontainer";
import CompOffContainer from "../../containers/lna/compoffcontainer";
import LeaveTransferContainer from "../../containers/lna/leavetransfercontainer";
import AllApproval from "../../containers/lna/approvalcontainer";
import TeamMemberReport from "../../containers/lna/teammonthreportcontainer";
import { updateLnAData } from "../../../redux/actions/lna/lnaaction";
import {
  getTMLCUsers,
  getEmpMasterData,
  getLnAData,
} from "../../other/commonlayoutfunction";
import { setMeetingMaster } from "../../../redux/actions/meetingmasteraction";
import {
  APPLYLEAVE,
  ALLAPPROVAL,
  APPLYSWIPE,
  LEAVETRANSFER,
  APPLYCOMPOFF,
  VIEWDAILY,
  VIEWMONTHLY,
  ATTENDANCEHRREPORT,
  VIEWYEARLY,
  VIEWLEAVEBALACE,
  ONLEAVE,
  CANCELLEAVE,
} from "../../../redux/constant/meetingcategoryconstant";
import queryString from "query-string";
import { dencrypt } from "../../other/commonfunction";
import { setMeetingCategory } from "../../../redux/actions/meetingcategoryaction";

class LnALayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      moduleId: 0,
      height: null,
    };
  }
  async componentDidMount() {
    const { authUser, financialYearData } = this.props;
    window.addEventListener("resize", this.updateDimensions());

    try {
      if (financialYearData.length === 0) {
        const lnaFYData = await getLnAData(authUser, "FY");
        if (lnaFYData) {
          this.props.updateLnAData({ ...lnaFYData });
        }
      }
      this.setModuleId();
    } catch (error) {
      console.log(error);
    }

    if (this.props.othersCheckAvailablity < 1) {
      const othersCheckAvailablityData = await getTMLCUsers(
        this.props.authUser
      );
      this.props.setMeetingMaster(othersCheckAvailablityData);
      const empMasterData = await getEmpMasterData(
        this.props.authUser,
        "Basic"
      );
      if (empMasterData !== null) {
        this.props.setMeetingMaster({
          finacialYearData: empMasterData.FYData,
          currentFinancialYearId: empMasterData.currFY,
        });
      }
    }
  }
  //   async componentDidUpdate(prevState){
  // if(prevState.moduleId !==this.state.moduleId){
  //   let date = new Date();
  //   let month = date.getUTCMonth() + 1; //months from 1-12
  //   let year = date.getUTCFullYear();
  //   this.props.updateLnAData({
  //       startMonthMonthlyReport: `${year}-${month}-01`
  //   })
  // }
  //   }
  setModuleId() {
    try {
      // Set App Id
      const { moduleId } = this.state;
      const params = queryString.parse(this.props.location.search);
      if (typeof params.id !== "undefined" && params.id !== null) {
        if (moduleId !== dencrypt(params.id)) {
          this.setState({ moduleId: dencrypt(params.id), isLoaded: true });
          this.props.setMeetingCategory(dencrypt(params.id).toString());
          if (
            dencrypt(params.id).toString() === APPLYLEAVE ||
            dencrypt(params.id).toString() === APPLYCOMPOFF
          ) {
            this.props.updateLnAData({ leaveApplyLoadName: "leave-tabs" });
          }
        }
      }
    } catch (error) {
      console.log(
        "🚀 ~ file: leaveslayout.jsx ~ line 42 ~ Leaveslayout ~ setModuleId ~ error",
        error
      );
    }
  }

  updateDimensions() {
    const updateheight = window.innerHeight;
    this.setState({
      height: updateheight,
    });
  }

  componentDidUpdate(newProps) {
    try {
      this.setModuleId();
    } catch (error) { }
  }

  renderSwitch() {

    const { categoryName } = this.props
    const { moduleId } = this.state
    let activeModule = (moduleId === 0 ? categoryName : moduleId).toString()
    switch (activeModule.toString()) {
      case APPLYSWIPE:
        return <SwipeContainer categoryName={activeModule} />;
      case LEAVETRANSFER:
        return <LeaveTransferContainer categoryName={activeModule} />;
      case ALLAPPROVAL:
        return <AllApproval categoryName={activeModule} />;
      case APPLYCOMPOFF:
        return <CompOffContainer categoryName={activeModule} />;
      case APPLYLEAVE:
        return <LeavesContainer />;
      case CANCELLEAVE:
        return <LeavesContainer />;
      case ONLEAVE:
        return <OnleaveContainer />;
      case VIEWDAILY:
      case VIEWMONTHLY:
      case VIEWYEARLY:
      case ATTENDANCEHRREPORT:
      case VIEWLEAVEBALACE:
        return <TeamMemberReport />;
      default:
        return <LeavesContainer />;
    }
  }

  render() {
    const { categoryName } = this.props;
    const { height } = this.state;
    return (
      <div
        id="lna-layout"
        className="pt-md"
        style={{
          height: height,
          backgroundColor: "#f2f2f2",
          overflow: 'hidden',
          border: '1px solid #DBDBDD',
          boxSizing: 'border-box',
        }}
      >
        {/* <div id="lna-layout" className="pt-md" style={{ height: height, backgroundColor: "#f2f2f2"}}> */}
        {this.state.isLoaded &&
          typeof categoryName !== "undefined" &&
          this.props.currentFinancialYearId !== null &&
          this.props.financialYearData.length > 0 &&
          this.renderSwitch()}
      </div>
    );
  }
}

const mapStateToProps = ({
  auth,
  meetingcategory,
  meetingmaster,
  lnareducer,
}) => {
  const { authUser } = auth;
  const { categoryName } = meetingcategory;
  const { othersCheckAvailablity, currentFinancialYearId } = meetingmaster;
  const { weekReportMonths, financialYearData } = lnareducer;
  return {
    categoryName,
    othersCheckAvailablity,
    authUser,
    currentFinancialYearId,
    weekReportMonths,
    financialYearData,
  };
};
export default connect(mapStateToProps, {
  setMeetingMaster,
  updateLnAData,
  setMeetingCategory,
})(LnALayout);
