import React, { useState, useEffect, useRef } from "react";
import { ComboBoxComponent } from "@syncfusion/ej2-react-dropdowns";
import {
  TextBoxComponent,
  ColorPickerComponent,
  NumericTextBoxComponent
} from "@syncfusion/ej2-react-inputs";
import { Picker } from "@material-ui/pickers";
import { connect } from "react-redux";
import { FormValidator } from "@syncfusion/ej2-inputs";
import okrServices from "../../services/okr/okrservices";
import { saveOKRBlockerMaster, updateOKRData } from "../../redux/actions/okr/okractions";
import {
  pleaseEnterXposition,
  pleaseEnterYposition,
  pleaseEnterBlockName,
  pleaseEnterBlockMeaning,
  pleaseSelectBlockHeadingColor,
  pleaseSelectBlockBodyColor,
  pleaseEnterBlockPlanOfAction,
  pleaseSelectFyId,
  regexOnlyLettersAndSpaces,
  pleaseEnterValidBlockName
} from "../../assets/constants/constants";
import ProgressBar from "../progressbar/progressbar";
import { cracterLimit } from '../../app/other/commonfunction'

const NineBlockMaster = (props) => {
  const { authUser, openPopup, masterId, loading, updateOKRData } = props; //NOSONAR
  const masterItemUid = props.masterItemId;
  const textareaObj = useRef(null);

  const [state, setState] = useState({
    masterItemId: null,
    performance: null,
    potential: null,
    blockName: "",
    attendanceType: "",
    blockDescription: "",
    blockHeadingColor: "",
    blockBodyColor: "",
    blockPlanOfAction: "",
    blockMeaning: "",
    fyId: props.currentFinancialYearId,
    isActive: "1",
    blockerMasterData: [],
  });

  const [updateSave, setUpdateSave] = useState(false)

  useEffect(() => {
    if (updateSave === true) {
      const {
        masterItemId,
        performance,
        potential,
        blockName,
        blockMeaning,
        blockDescription,
        blockHeadingColor,
        blockBodyColor,
        blockPlanOfAction,
        isActive,
        fyId,
      } = state;
  
      const options = {
        rules: {
          xPostionBlockErr: {
            required: [true, pleaseEnterXposition],
          },
          yPositionBlockErr: {
            required: [true, pleaseEnterYposition],
          },
          nineBlockNameErr: {
            required: [true, pleaseEnterBlockName],
            regex: [regexOnlyLettersAndSpaces, pleaseEnterValidBlockName],
          },
          nineBlockMeaningErr: {
            required: [true, pleaseEnterBlockMeaning],
          },
          blockPlanOfActionErr: {
            required: [true, pleaseEnterBlockPlanOfAction],
          },
          nineBlockFYErr: {
            required: [true, pleaseSelectFyId],
          },
        },
      };
  
      let validateDates = true;
  
      if (state.blockHeadingColor == "") {
        document.getElementById("blockHeadingColorErr").innerHTML =
          pleaseSelectBlockHeadingColor;
        validateDates = false;
      }
      if (state.blockBodyColor == "") {
        document.getElementById("blockBodyColorErr").innerHTML =
          pleaseSelectBlockBodyColor;
        validateDates = false;
      }
  
      if (state.blockName.length >= 50) {
        document.getElementById("nineBlockNameErr").innerHTML =
          "Block name should be maximum of 50 characters only.";
        validateDates = false;
      } else if (state.blockDescription && state.blockDescription.length >= 150) {
        document.getElementById("descriptionErr").innerHTML =
          "Description should be maximun of 150 characters only.";
        validateDates = false;
      }
  
      setTimeout(() => {
        const addFormObject = new FormValidator("#okrBlockerMasterForm", options);
        if (addFormObject.validate() && validateDates) {
          let nineBlockerMasterSaveData = {
            userId: authUser,
            type: masterItemId > 0 ? "EDIT" : "ADD",
            fyId,
            masterItemId,
            performance,
            potential,
            blockName,
            blockMeaning,
            blockDescription,
            blockHeadingColor,
            blockBodyColor,
            blockPlanOfAction,
            isActive,
          };
          updateOKRData({ loading: true });
          props.saveOKRBlockerMaster(nineBlockerMasterSaveData);
          setUpdateSave(false)
        }else{
          setUpdateSave(false)
        }
      }, 300);
    }
  }, [updateSave === true])

  useEffect(() => {
    getData();
  }, [openPopup, masterId, masterItemUid]);

  useEffect(() => {
    setTimeout(() => {
      props.setSubmitClick(onSaveData);
    }, 100);
  }, [state]);

  useEffect(() => {
    if (props.okrMasterFlag === "updated") {
      props.changePopUpHandler("close");
    }
  }, [props.okrMasterFlag]);



  const getData = () => {

    ["xPostionBlockErr",
      "yPositionBlockErr",
      "nineBlockNameErr",
      "nineBlockMeaningErr",
      "blockHeadingColorErr",
      "blockBodyColorErr",
      "blockPlanOfActionErr",
      "nineBlockFYErr"
    ].forEach(err => { document.getElementById(err).innerHTML = "" });
    setState({
      masterItemId: null,
      performance: null,
      potential: null,
      blockName: "",
      attendanceType: "",
      blockDescription: "",
      blockHeadingColor: "",
      blockBodyColor: "",
      blockPlanOfAction: "",
      blockMeaning: "",
      fyId: props.currentFinancialYearId,
      isActive: "1",
      blockerMasterData: [],
    });
    updateOKRData({ loading: false });
    if (openPopup === "edit") {
      (async () => {
        updateOKRData({ loading: true });
        const blockerMasterData = await okrServices.getOKRMaserData(
          authUser,
          masterId,
          masterItemUid
        );
        const {
          masterItemId,
          performance,
          potential,
          blockName,
          blockMeaning,
          blockDescription, //NOSONAR
          blockHeadingColor,
          blockBodyColor,
          blockPlanOfAction,
          isActive,
          fyId,
        } = blockerMasterData.Result[0];
        let data = {
          masterItemId,
          performance,
          potential,
          blockName,
          blockMeaning,
          blockDescription,
          blockHeadingColor,
          blockBodyColor,
          blockPlanOfAction,
          isActive,
          fyId,
        };
        setState((preState) => ({ ...preState, ...data }));
        updateOKRData({ loading: false });
      })();
    }
  };

  const inputChangeHandler = async (e, type) => {
    let options = {};
    switch (type) {
      case "xPosition":
        setState((prevState) => ({ ...prevState, performance: e.value }));
        document.getElementById("xPostionBlockErr").innerHTML = ""
        break;
      case "yPosition":
        setState((prevState) => ({
          ...prevState,
          potential: e.value,
        }));
        document.getElementById("yPositionBlockErr").innerHTML = ""
        break;
      case "blockName":
        setState((prevState) => ({
          ...prevState,
          blockName: e.value.replace(/^\s+|\s+$/gm, ""),
        }));
        options = {
          rules: {
            nineBlockNameErr: {
              required: [true, pleaseEnterBlockName],
              regex: [regexOnlyLettersAndSpaces, pleaseEnterValidBlockName],
              minLength: [1, pleaseEnterValidBlockName],
              maxLength: [50, "Should be maximum of 50 characters."]
            },
          },
        };
        break;
      case "blockMeaning":
        setState((prevState) => ({
          ...prevState,
          blockMeaning: e.value.replace(/^\s+|\s+$/gm, ""),
        }));
        options = {
          rules: {
            nineBlockMeaningErr: {
              required: [true, pleaseEnterBlockMeaning],
              minLength: [1, pleaseEnterBlockMeaning],
              maxLength: [150, "Should be maximum of 150 characters."]
            },
          },
        };
        break;
      case "description":
        setState((prevState) => ({
          ...prevState,
          blockDescription: e.value.replace(/^\s+|\s+$/gm, ""),
        }));
        options = {
          rules: {
            descriptionErr: {
              maxLength: [500, "Should be minimum of 500 characters."]
            },
          },
        };
        break;
      case "blockPlanOfAction":
        setState((prevState) => ({
          ...prevState,
          blockPlanOfAction: e.value.replace(/^\s+|\s+$/gm, ""),
        }));
        options = {
          rules: {
            blockPlanOfActionErr: {
              required: [true, "Please enter a plan of action."],
              maxLength: [50, "Should be maximum of 50 characters."]
            },
          },
        };
        break;
      case "fyId":
        setState((prevState) => ({
          ...prevState,
          fyId: e.itemData ? e.itemData.Id : "",
        }));
        break;
      case "blockHeadingColor":
        console.log("evalue", e)
        setState((prevState) => ({ ...prevState, blockHeadingColor: e.value }));
        document.getElementById("blockHeadingColorErr").innerHTML = "";
        break;
      case "blockBodyColor":
        setState((prevState) => ({ ...prevState, blockBodyColor: e.value }));
        document.getElementById("blockBodyColorErr").innerHTML = "";
        break;
      default:
    }

    setTimeout(() => {
      const addFormObject = new FormValidator("#okrBlockerMasterForm", options);
      addFormObject.validate();
    }, 100);
  };


  const onSaveData = async () => {
    const xPostionNineBlock = document.getElementById('xPostionNineBlock')?.ej2_instances[0]?.value
    const YpositionNineBlock = document.getElementById('YpositionNineBlock')?.ej2_instances[0]?.value
    
    if (xPostionNineBlock !== null && YpositionNineBlock !== null) {
      setState(prevState => ({ ...prevState, performance: xPostionNineBlock,potential: YpositionNineBlock}))
    }
    setUpdateSave(true)
  };

  const descriptionTextBox = `.e-outline textarea, textarea.e-outline, .e-outline.e-float-input textarea, .e-outline.e-float-input.e-control-wrapper textarea, .e-outline.e-float-input.e-input-group textarea {
      box-sizing: border-box;
      min-height: 100px;
    }
    `
  const onInput = (e) => {
    try {
      if (textareaObj !== null && typeof textareaObj !== 'undefined') {
        textareaObj.current.respectiveElement.style.height = "auto";
        textareaObj.current.respectiveElement.style.minHeight = "100px";
        textareaObj.current.respectiveElement.style.height = `${textareaObj.current.respectiveElement.scrollHeight}px`;
        textareaObj.current.respectiveElement.style.maxHeight = "100px";
        cracterLimit(e, 'description', '1000')
      }
    } catch (error) {
    }
  };

  return (
    <>
      <div id="okrBlockerMasterForm">
        <div style={{ padding: "16px" }}>
          <div className="col-xs-12 col-sm-12 col-lg-12 mt-sm mb-sm atc-no-paddding">
            <div
              className="col-xs-6 col-sm-6 col-lg-6 col-md-6"
              style={{ paddingLeft: "0px", paddingRight: "0px" }}
            >
              <NumericTextBoxComponent
                data-msg-containerid="xPostionBlockErr"
                name="xPostionBlockErr"
                floatLabelType="Auto"
                value={state.performance}
                onChange={(e) => inputChangeHandler(e, "xPosition")}
                min={1}
                max={3}
                decimals={0}
                format="##"
                number
                id="xPostionNineBlock"
                placeholder="X Position *"
                cssClass="e-outline"
                showSpinButton={false}
              />
              <div id="xPostionBlockErr" className="error-div" />
            </div>
            <div
              className="col-xs-6 col-sm-6 col-lg-6 col-md-6"
              style={{ paddingRight: "0px" }}
            >
              <NumericTextBoxComponent
                data-msg-containerid="yPositionBlockErr"
                name="yPositionBlockErr"
                floatLabelType="Auto"
                value={state.potential}
                onChange={(e) => inputChangeHandler(e, "yPosition")}
                min={1}
                max={3}
                decimals={0}
                format="##"
                number
                id="YpositionNineBlock"
                placeholder="Y Position *"
                cssClass="e-outline"
                showSpinButton={false}
              />
              <div id="yPositionBlockErr" className="error-div" />
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-lg-12 mt-lg mb-sm atc-no-paddding">
            <TextBoxComponent
              data-msg-containerid="nineBlockNameErr"
              id="nineBlockName"
              value={state.blockName}
              name="nineBlockNameErr"
              placeholder="Block Name *"
              floatLabelType="Auto"
              cssClass="e-outline"
              onChange={(e) => inputChangeHandler(e, "blockName")}
            />
            <div id="nineBlockNameErr" className="error-div" />
          </div>
          <div className="col-xs-12 col-sm-12 col-lg-12 mt-lg mb-sm atc-no-paddding">
            <TextBoxComponent
              data-msg-containerid="nineBlockMeaningErr"
              id="nineBlockMeaning"
              value={state.blockMeaning}
              name="nineBlockMeaningErr"
              placeholder="Block Meaning *"
              floatLabelType="Auto"
              cssClass="e-outline"
              onChange={(e) => inputChangeHandler(e, "blockMeaning")}
            />
            <div id="nineBlockMeaningErr" className="error-div" />
          </div>
          <div className="col-xs-12 col-sm-12 col-lg-12 weekdayDescription mt-lg mb-sm atc-no-paddding">
            <style>{descriptionTextBox}</style>
            <TextBoxComponent
              data-msg-containerid="descriptionErr"
              id="description"
              value={state.blockDescription}
              multiline
              name="descriptionErr"
              placeholder="Block Description "
              autocomplete={'off'}
              floatLabelType="Auto"
              cssClass="e-outline textarea"
              onChange={(e) => inputChangeHandler(e, "description")}
              input={onInput}
              ref={textareaObj}
              style={{ minHeight: 100 }}
            />
            <div id="descriptionErr" className="error-div" />
            <div id='description' style={{ float: 'right', fontSize: 12, margin: '0px 15px 8px 0px' }} className="error-div" />
          </div>
          <div className="col-xs-12 col-sm-12 col-lg-12 mt-lg mb-sm atc-no-paddding">
            <TextBoxComponent
              data-msg-containerid="blockPlanOfActionErr"
              id="blockPlanOfAction"
              value={state.blockPlanOfAction}
              name="blockPlanOfActionErr"
              placeholder="Block Plan of Action *"
              floatLabelType="Auto"
              cssClass="e-outline"
              onChange={(e) => inputChangeHandler(e, "blockPlanOfAction")}
            />
            <div id="blockPlanOfActionErr" className="error-div" />
          </div>
          <div className="col-xs-12 col-sm-12 col-lg-12 mt-lg mb-sm atc-no-paddding">
            <ComboBoxComponent
              // <TextBoxComponent
              id="nineBlockFY"
              data-msg-containerid="nineBlockFYErr"
              name="nineBlockFYErr"
              dataSource={props.finacialYearData}
              fields={{ text: "Name", value: "Id" }}
              value={state.fyId}
              placeholder="Financial Year *"
              floatLabelType="Auto"
              cssClass="e-outline"
              change={(e) => inputChangeHandler(e, "fyId")}
              allowCustom={false}
            />{" "}
            <div id="nineBlockFYErr" className="error-div" />
          </div>
          <div className="col-xs-12 col-sm-12 col-lg-12 mt-lg mb-sm atc-no-paddding">
            <div
              className="col-xs-6 col-sm-6 col-lg-6 col-md-6"
              style={{ paddingLeft: "0px", paddingRight: "0px" }}
            >
              <div className="atc-switch-heading">Primary Color *</div>
              <div>
                <ColorPickerComponent
                  id="color-picker"
                  value={state.blockHeadingColor}
                  mode={Picker}
                  change={(e) => inputChangeHandler(e, "blockHeadingColor")}
                ></ColorPickerComponent>
                <div id="blockHeadingColorErr" className="error-div" />
              </div>
            </div>
            <div
              className="col-xs-6 col-sm-6 col-lg-6 col-md-6"
              style={{ paddingRight: "0px" }}
            >
              <div className="atc-switch-heading">Secondary Color *</div>

              <ColorPickerComponent
                id="color-picker"
                value={state.blockBodyColor}
                mode={Picker}
                change={(e) => inputChangeHandler(e, "blockBodyColor")}
              ></ColorPickerComponent>
              <div id="blockBodyColorErr" className="error-div" />
            </div>
          </div>



        </div>
      </div>
      {loading && <ProgressBar />}
    </>
  );
};

const mapStateToProps = ({ auth, okrreducer, meetingmaster }) => {
  const { authUser } = auth;
  const { currentFinancialYearId, finacialYearData } = meetingmaster;
  const { okrMasterFlag, loading } = okrreducer;

  return {
    authUser,
    okrreducer,
    currentFinancialYearId,
    okrMasterFlag,
    finacialYearData,
    loading
  };
};

export default connect(mapStateToProps, {
  saveOKRBlockerMaster,
  updateOKRData
})(NineBlockMaster);
