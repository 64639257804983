import React from "react";
import PropTypes from "prop-types";
import NotificationContainer from "../../../components/notification/notifycontainer";
import { connect } from "react-redux";
import { Query, Predicate, DataManager } from '@syncfusion/ej2-data';
import {
  ScheduleComponent,
  ViewsDirective,
  ViewDirective,
  Day,
  Week,
  WorkWeek,
  Agenda,
  ResourcesDirective,
  ResourceDirective,
  Inject,
  Resize,
} from "@syncfusion/ej2-react-schedule";
import { ContextMenuComponent } from "@syncfusion/ej2-react-navigations";
import { extend, Internationalization, closest, isNullOrUndefined, remove, removeClass } from '@syncfusion/ej2-base';
// NOSONAR import { extend, } from "@syncfusion/ej2-base";
import { RecurrenceEditor } from "@syncfusion/ej2-schedule";
import { Button } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ComboBoxComponent } from "@syncfusion/ej2-react-dropdowns";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faBan,
  faClock,
  faCheckCircle,
  faCheckDouble,
  faClone,
  faCheck,
  faRecycle,
  faEdit,
  faEye,
  faUserFriends,
} from "@fortawesome/pro-light-svg-icons";
import schedularservice from "../../../services/schedularservice";
import services from "../../../services/services";
import tibService from "../../../services/tibservices";
import meetingService from "../../../services/meetingbooking" //NOSONAR
import Header from "../../../components/meetingdetailspopup/header";
import DetailsContent from "../../../components/meetingdetailspopup/detailscontent";
import Footer from "../../../components/meetingdetailspopup/footer";
import AddContent from "../../../components/meetingdetailspopup/addcontent";
import "./planvsactualeventtemplate.css";
import {
  updateMeetingBookingState,
  createMeeting,
  getMeetingDetails,
  meetingAcceptance,
  saveMeeting,
  cancelMeeting,
  saveMeetingTemplate,
} from "../../../redux/actions/meetingbookingaction";
import { setMeetingMaster } from "../../../redux/actions/meetingmasteraction";
import { addUpdateTibData } from "../../../redux/actions/tibacton";
import MeetingBooking from "../meetingbooking/meetingbooking";
import {
  getDateFormat,
  getDateObj,
  addDays,
  getDiffDays,
  getCurrentDate,
  getDate,
  getDisplayDateOnly,
  getNotify,
  encrypt,
  dencrypt,
  encodeString,
  decodeString,
  workDayObjCheck,
  getAttendeeString,
  generateZoomObject,
  getMySqlDateFormat,
  removeExtraSeconds,
  TIBDateFormat
} from "../../other/commonfunction";
import {
  defTimeScrollTo,
  defworkDays,
  defNotificationSetInterval,
  defRecurringMaximumCount,
  urlConfig
} from "../../../config/config";
import { } from "@fortawesome/free-solid-svg-icons";
import ProgressBar from "../../../components/progressbar/progressbar";
import ErrorBoundary from "../../../components/error/errorboundary";
import ViewMeetingPopup from "../../../components/meetingdetailspopup/viewmeetingpopup";
import {
  SUCCESS,
  WARNING,
  ERROR,
  pleaseEnterMeetingTitle,
  YouCannotPasteMeetingInActual,
  YouCannotChangeTheMeetingDetails,
  ForPlanMeetingStartTimeAndEndTimeMustBeGreaterThanCurrentTime,
  YouCannotPasteMeetingPriortoCurrentTime,
  ForActualMeetingStartTimeAndEndTimeMustBeLessThanCurrentTime,
  youCannotAddFutureMeetingInActualSection,
  youCannotAddBackDatedMeeting,
  MeetingCancelledSuccessfully,
  PasteMeetingInActualIsNotAllowed,
  LeaveTypeMeetingNotAllowed,
  SeriesMeetingCancelledSuccessfully,
  YouCannotAddMeetingInActualSection,
  MeetingSlotNotAvailable,
  youHaveNoAcceccToAddMeetings,
  pleaseUserViewDropdown,
  pleaseselectappropriatejob,
  pleaseSelectAppropriateBtb,
  borderLeftStyle,
  breakBorderLeftStyle,
  borderStyle,
  breakBorderStyle,
  breakBorderColorStyle,
  selfBorderColorStyle,
  fontColorStyle,
  breakFontColorStyle,
  selfBorderStyle,
  pleaseselectappropriateMeetingSubType,
  ClientMeetingsId,
  pleaseSelectAppropriateMeetingServiceProduct, Cognition,
  movePlanToActualFiveDaysOnly,
  YouCannotAddBackDatedMeetingInPlanSection, fixedHolidayIconColor, appliedHolidayIconColor,
  pleaseselectappropriateMeetingType, meetingAcceptanceMsg, OPSBTBClientMeet, SalesClientMeet, OPSBTBDropdown, Holidays
} from "../../../assets/constants/constants";
import { changeAuthUser } from "../../../redux/actions/auth";
import {
  PLAN_VS_ACTUAL_MEETING,
  OTHERS_CALENDAR,
  OTHERS_CHECK_AVAILABILITY, HOLIDAY_LIST, APPLYLEAVE
} from "../../../redux/constant/meetingcategoryconstant";
import {
  createSpinner, showSpinner, hideSpinner,
} from '@syncfusion/ej2-react-popups';
import { renderToString } from 'react-dom/server';
import ConfirmationPopup from "../../../utilities/confirmationpopup";

library.add(
  faBan,
  faClock,
  faCheckCircle,
  faCheckDouble,
  faClone,
  faCheck,
  faRecycle,
  faEdit,
  faEye,
  faUserFriends
);

class PlanVsActualSchedular extends React.Component {
  constructor() {
    super();
    this.state = {
      saveAsTemplatePopUp: false,
      open: false,
      title: meetingAcceptanceMsg,
      message: "",
      currentMeetingId: 0,
      currentMeetingMode: "P",
      button1Caption: "Yes",
      button2Caption: "No",
      currentSeriesId: 0,
      locale: "Asia/Kolkata",
      changedData: false,
      dragData: {},
      contentData: [],
      mDtMeetingDate: "",
      seriesDateString: "",
      workHours: [],
    };
    this.myRef = React.createRef();
    this.data = extend([], null, null, true);
    this.templateId = 0;
    this.fieldSettings = {
      subject: { name: "Subject", validation: { required: true } },
    };
    this.Meetingdata = "";
    this.instance = new Internationalization();
    this.startDate = new Date();
    this.endDate = new Date();
    this.workDays = defworkDays;
    this.userSetting = {};
    this.handleClose = this.handleClose.bind(this);
    this.resourceData = [
      { text: "Schedule", id: 1, color: "#ffffff" },
      { text: "Actual", id: 2, color: "#83ae9b" },
    ];
    this.handleExchange = this.handleExchange.bind(this);
    this.meetingIdToTransfer = 0;
    this.firstTimeRefreshed = false;
    this.onActionBegin = this.onActionBegin.bind(this);
    this.onActionComplete = this.onActionComplete.bind(this);
    this.header = this.header.bind(this);
    this.content = this.content.bind(this);
    this.footer = this.footer.bind(this);
    this.onEventRendered = this.onEventRendered.bind(this);
    this.onPopupOpen = this.onPopupOpen.bind(this);
    this.onPopupClose = this.onPopupClose.bind(this);
    this.onDragStart = this.onDragStart.bind(this);
    this.onDataBound = this.onDataBound.bind(this);
    this.onDataBinding = this.onDataBinding.bind(this);
    this.majorSlotTemplate = this.majorSlotTemplate.bind(this);
    this.onCreated = this.onCreated.bind(this);
    this.eventTemplate = this.eventTemplate.bind(this);
    this.onNavigation = this.onNavigation.bind(this);
    this.onEventClick = this.onEventClick.bind(this);
    this.dragStop = this.dragStop.bind(this);
    this.funcAddBreakMeeting = this.funcAddBreakMeeting.bind(this);
    this.addMeetingQuickInfo = this.addMeetingQuickInfo.bind(this);
    this.countdown = null;
    this.autoRefresh = false;
    this.TaskId = 1;
    this.setTitleFromQuickInfo = this.setTitleFromQuickInfo.bind(this);
    this.setTemplateFromQuickInfo = this.setTemplateFromQuickInfo.bind(this);
    this.setOPSMeeting = this.setOPSMeeting.bind(this);
    this.updateTimeZoneChangesStatus = false;
    this.meetingTitle = "";
    this.copyMettingData = null;



    this.menuItems = [
      {
        text: "Paste Meeting",
        iconCss: "e-icons new",
        id: "Paste",
      },
      {
        text: "Paste Series",
        iconCss: "e-icons new",
        id: "PasteSeries",
      },
      {
        text: "New Recurring Event",
        iconCss: "e-icons recurrence",
        id: "AddRecurrence",
      },
      {
        text: "Today",
        iconCss: "e-icons today",
        id: "Today",
      },
      {
        text: "Copy Meeting",
        iconCss: "e-icons edit",
        id: "Copy",
      },
      {
        text: "Save As Template",
        iconCss: "e-icons template",
        id: "SaveTemplate",
      },
      {
        text: "Edit Event",
        id: "EditRecurrenceEvent",
        iconCss: "e-icons edit",
        items: [
          {
            text: "Edit Occurrence",
            id: "EditOccurrence",
          },
          {
            text: "Edit Series",
            id: "EditSeries",
          },
        ],
      },
      {
        text: "Delete Event",
        iconCss: "e-icons delete",
        id: "Delete",
      },
      {
        text: "Delete Event",
        id: "DeleteRecurrenceEvent",
        iconCss: "e-icons delete",
        items: [
          {
            text: "Delete Occurrence",
            id: "DeleteOccurrence",
          },
          {
            text: "Delete Series",
            id: "DeleteSeries",
          },
        ],
      },
    ];

    this.lastTIBDates = { startDate: null, endDate: null };
    this.lastWorkDayData = { startDate: null, endDate: null, userId: null };
    this.userSettingData = { startDate: null, userId: null };
  }

  async componentDidMount() {
    const { authUser, meetingPopOpen } = this.props;
    const { open } = this.state;
    window.addEventListener("resize", this.updateDimensions.bind(this));

    this.spinner(true)

    this.getMeetingTemplateData();

    const shiftDetails = services.getShiftDetails(authUser, authUser);
    shiftDetails.then((result) => {
      this.workHours = result;
      setTimeout(() => { this.funRefreshWorkHours(); }, 1000);
    });
    /**
     * Set Time out for refresh meetings.
     */
    this.countdown = setInterval(async () => {
      if (!meetingPopOpen && !open && document.getElementsByClassName("e-popup-open").length === 0) {
        this.autoRefresh = true;
        this.funTimelyRefresh(0, "");
      }
    }, defNotificationSetInterval);

    setTimeout(() => {
      if (this.scheduleObj !== null && typeof this.scheduleObj !== 'undefined') {
        let dates = [];
        if (this.scheduleObj.activeView.viewClass === "e-agenda-view") {
          dates = [
            addDays(this.scheduleObj.activeView.renderDates[0], 0),
            addDays(this.scheduleObj.activeView.renderDates[0], 6),
          ];
        } else {
          dates = this.scheduleObj.activeView.renderDates;
        }
        this.startDate = getCurrentDate(dates[0]);
        this.endDate = getCurrentDate(dates[dates.length - 1]);

        if (
          typeof this.startDate !== "undefined" &&
          this.startDate !== null &&
          this.endDate !== null) {
          const mNoStartDate = Math.floor( // NOSONAR
            new Date(this.startDate.getTime() / 1000.0) //NOSONAR
          );
          const mNoEndDate = Math.floor( // NOSONAR
            new Date(this.endDate.getTime() / 1000.0) //NOSONAR
          );
          this.funRefreshReport(mNoStartDate, mNoEndDate, 0, "");
        }
      }
    });
    setTimeout(() => {
      this.funRefreshWorkHours();
    }, 500);
  }
  // eslint-disable-next-line
  UNSAFE_componentWillReceiveProps(newProps) { // NOSONAR
    const { showMeetingPopup, categoryName, executiveAssistant, templateListData, authUser } = this.props;
    const { changedData } = this.state;

    if (newProps.updateTimeZoneChangesStatus === "updated") {
      this.updateTimeZoneChangesStatus = true;
      this.lastWorkDayData = { startDate: null, endDate: null, userId: null };
      this.userSettingData = { startDate: null, userId: null };
      this.props.updateMeetingBookingState({ updateTimeZoneChangesStatus: "" });
    }

    if (newProps.categoryName == OTHERS_CALENDAR && categoryName !== newProps.categoryName)
      this.data = extend([], null, null, true);
    if (
      newProps.getNotifyFlag === true ||
      (newProps.categoryName === OTHERS_CALENDAR && (executiveAssistant === null || newProps.executiveAssistant.length !== executiveAssistant.length)) ||
      (newProps.showMeetingPopup !== showMeetingPopup) ||
      (newProps.categoryName == OTHERS_CALENDAR && categoryName !== newProps.categoryName)
    ) {

      this.setState({ changedData: !changedData });
    }

    if (newProps.categoryName !== OTHERS_CALENDAR && categoryName !== newProps.categoryName) {
      this.getMasterDataOthersCalendar();
    }

    try {
      if (
        newProps.meetingStatus === "updated" ||
        newProps.meetingStatus === "refresh" ||
        newProps.categoryName !== categoryName) {
        if (newProps.categoryName === OTHERS_CALENDAR && newProps.categoryName !== categoryName) {
          this.data = extend([], null, null, true);
          this.props.addUpdateTibData({ userTibData: [] });
          this.setState({ changedData: !changedData });
        }
        else {
          this.autoRefresh = false;
          const meetingId = newProps.seriesId > 0 ? 0 : newProps.meetingId;
          const meetingMode = newProps.meetingmode;
          if (newProps.meetingId > 0 && newProps.meetingStatus === "updated" && newProps.meetingmode === "P" && (newProps.outlookActionType === "accept" || newProps.outlookActionType === "decline" || newProps.outlookActionType === "PEDIT" || newProps.outlookActionType === "PCREATE" || newProps.outlookActionType === "suggestTime")) {
            meetingService.syncOutlookMeeting({ planMeetingId: newProps.meetingId, seriesId: newProps.seriesId, userId: authUser, action: newProps.outlookActionType })
          }
          // eslint-disable-next-line
          this.props.getMeetingDetails({
            meetingPopOpen: false,
            meetingId: 0,
            meetingStatus: "",
            seriesId: 0,
            meetingmode: "P",
            outlookActionType: "",
            templateListData: templateListData
          });

          this.funTimelyRefresh(meetingId, meetingMode);
          if (this.meetingIdToTransfer > 0) {
            this.funTimelyRefresh(this.meetingIdToTransfer, "P");
            this.meetingIdToTransfer = 0;
          }
        }
      }
      if (newProps.meetingStatus === "WorkDayUpdated") {
        const mnoStartDate = Math.floor(new Date(this.startDate.getTime()));// NOSONAR
        const mnoEndDate = Math.floor(new Date(this.endDate.getTime()));// NOSONAR
        this.getWorkDaysData(mnoStartDate, mnoEndDate);
        this.props.updateMeetingBookingState({ meetingStatus: "" });
      }
    } catch (error) { }
  }


  getMeetingTemplateData = async () => {
    const { authUser } = this.props;
    let templates = [];
    templates = await services.getMeetingTemplateList(authUser);
    this.props.updateMeetingBookingState({ templateListData: templates });
  }

  /**
   * @author Prashant Waphare
   * @FuncName getWorkDaysData
   * @description get the work shift data
   */
  // eslint-disable-next-line
  getWorkDaysData = async (startDate, endDate) => {
    const { authUser } = this.props;

    if (this.lastWorkDayData.startDate === new Date(startDate).getTime() &&
      this.lastWorkDayData.endDate === new Date(endDate).getTime() &&
      this.lastWorkDayData.userId === authUser) {
      return;
    } else {
      this.lastWorkDayData.startDate = new Date(startDate).getTime()
      this.lastWorkDayData.endDate = new Date(endDate).getTime()
      this.lastWorkDayData.userId = authUser
    }
    const { workHours } = this.state
    const workDaySetting = Promise.resolve(
      services.getWorkDaySettingList(
        authUser,
        getDate(new Date(startDate)),
        getDate(new Date(endDate)),
        "WorkDay"
      )
    );
    workDaySetting.then((result) => {
      if (result !== null) {
        if (!workDayObjCheck(workHours, result)) {
          this.setState({ workHours: result }, () => { this.funRefreshWorkHours() });
        }
      }
    });
  };

  /**
   * @author Prashant Waphare | 15-09-2020
   * @FuncName getWorkDaysDataForOtherCalender
   * @description get the work shift data for other calender
   */
  getWorkDaysDataForOtherCalender = async (startDate, endDate) => {
    const { otherUser } = this.props;
    if (this.lastWorkDayData.startDate === new Date(startDate).getTime() &&
      this.lastWorkDayData.endDate === new Date(endDate).getTime() &&
      this.lastWorkDayData.userId == otherUser) {
      return;
    } else {
      this.lastWorkDayData.startDate = new Date(startDate).getTime()
      this.lastWorkDayData.endDate = new Date(endDate).getTime()
      this.lastWorkDayData.userId = otherUser
    }

    const workDaySetting = Promise.resolve(
      services.getWorkDaySettingList(
        otherUser,
        getDate(new Date(startDate)),
        getDate(new Date(endDate)),
        "WorkDay"
      )
    );
    workDaySetting.then((result) => {
      if (result !== null) {
        this.setState({ workHours: result, });
      }
    });
  };

  fungetMeetingDetails = (pIsSeries) => {  // NOSONAR
    const { categoryName, otherUser, authUser } = this.props;
    try {//NOSONAR
      const meetingDetails = services.getIndMeetingDts(
        categoryName === OTHERS_CALENDAR ? otherUser : authUser,
        this.Meetingdata.MeetingId,
        this.Meetingdata.MeetingMode,
        pIsSeries,
        false
      );
      meetingDetails.then((result) => {
        if (result !== null) {
          let backDatedClientEditAccess = false;
          if (
            dencrypt(localStorage.getItem('defActualDays')) > 0 &&
            result.meetingmode === "A" &&
            this.userSetting.Actual_Edit_Access === "Y"
          ) {
            const days = getDiffDays(
              result.meeting_date,
              getCurrentDate(new Date())
            );
            if (days <= dencrypt(localStorage.getItem('defActualDays'))) {
              backDatedClientEditAccess = true;
            }
          }

          // eslint-disable-next-line
          this.props.updateMeetingBookingState({
            // agendaDocument: null,
            btbId: result.btb_id,
            businessImpact: result.business_impact,
            chairPersonId: result.chairperson_id,
            clientId: result.client_id,
            clientNm: result.client_nm,
            clientmode: result.clientmode,
            getExcludeSundayHoliday: result.getExcludeSundayHoliday,
            isFullDay: result.is_full_day === "1",
            subBTBId: result.sub_btb_id,
            locale: result.locale,
            meetingAgenda: result.meeting_agenda,
            meetingActionItems: result.meeting_actionitems,
            meetingCategoryId: result.meeting_category_id,
            meetingDate: result.meeting_date,
            meetingEndTime: result.meeting_end_time,
            meetingFrequency: result.meeting_frequency,
            meetingId: result.meeting_id,
            meetingLocation: result.meeting_location,
            meetingMediaId: result.meeting_media_id,
            meetingSetterId: result.meeting_setter_id,
            meetingSourceId: result.meeting_source_id,
            meetingStartTime: result.meeting_start_time,
            meetingSubTypeId: result.meeting_sub_type_id,
            meetingTitle: result.meeting_title,
            meetingTypeId: result.meeting_type_id,
            meetingWebLink: result.meeting_weblink,
            meetingWeblinkType: result.meeting_weblink_type,
            meetingmode: result.meetingmode,
            otherAttendees: result.other_attendees,
            participateCode: result.participate_code,
            recurring: result.recurring,
            seriesId: result.series_id,
            subCatId: result.sub_cat_id === "0" ? "" : result.sub_cat_id,
            timeZoneId: result.time_zone_id,
            workTypeId: result.work_type_id,
            bulkAttendeesFlag: result.bulkAttendeesFlag === '1' ? true : false,
            campaignId: result.campaign_id,
            zoomMeetingHostEmailId: result.zoomMeetingHostEmailId ? result.zoomMeetingHostEmailId : null,
            zoomMeetingId: result.zoomMeetingId ? result.zoomMeetingId : null,
            zoomOccurrenceId: result.zoomOccurenceId ? result.zoomOccurenceId : null
          });

          // eslint-disable-next-line
          this.props.updateMeetingBookingState({
            backDatesClientEditAccess:
              result.meetingmode === "A" ? backDatedClientEditAccess : false,
            meetingDate: new Date(result.meeting_date),
            meetingStartTime: new Date(result.meeting_start_time),
            meetingEndTime: new Date(result.meeting_end_time),
            serviceProductsId:
              result.service_products_id === ""
                ? []
                : result.service_products_id
                  .toString()
                  .split(",")
                  .map((item) => parseInt(item, 10)),
            contactsId:
              result.contacts_id === ""
                ? []
                : result.contacts_id
                  .toString()
                  .split(",")
                  .map((item) => (item !== "" ? parseInt(item, 10) : 0)),
            meetingRoomsId:
              result.meeting_rooms_id === ""
                ? []
                : result.meeting_rooms_id
                  .toString()
                  .split(",")
                  .map((item) => (item !== "" ? parseInt(item, 10) : 0)),
            meetingAttendee:
              result.meeting_attendee === ""
                ? []
                : result.meeting_attendee
                  .toString()
                  .split(",")
                  .map((item) => item, 10),
            meetingEditAccess: this.Meetingdata.EditDeleteAccess,
            isClientMeeting: OPSBTBClientMeet.includes(result.meeting_sub_type_id) || SalesClientMeet.includes(result.meeting_sub_type_id),
            isFullDay: result.is_full_day === "1",
            meetingPopOpen: true,
            meetingDates: [],
            isDigitalMeeting: (result.meeting_media_id === 51 || result.meeting_media_id === 52),
            countriesId:
              result.countries_id === ""
                ? []
                : result.countries_id
                  .toString()
                  .split(",")
                  .map((item) => parseInt(item, 10)),
            btbId: result.btb_id === "" ? "" : parseInt(result.btb_id, 10),
            subBTBId: result.sub_btb_id === "" ? "" : parseInt(result.sub_btb_id, 10),
            subCatId: result.sub_cat_id === "" || result.sub_cat_id === "0" ? "" : parseInt(result.sub_cat_id, 10)
          });

          try {
            const { workHours } = this.state;
            let selectedDateData = null;
            if (workHours.length > 0) {
              /**
               * @author Vivek Khodade.
               * @description get month function returns from 0 to 11;
               */
              workHours.forEach((item) => {
                /**
                 * @author Vivek Khodade.
                 * @description get month function returns from 0 to 11;
                 */
                if (new Date(`${new Date(item.calendarDate).getFullYear()}-${new Date(item.calendarDate).getMonth() + 1}-${new Date(item.calendarDate).getDate()}`).getTime() === new Date(`${new Date(result.meeting_start_time).getFullYear()}-${new Date(result.meeting_start_time).getMonth() + 1}-${new Date(result.meeting_start_time).getDate()}`).getTime()) {
                  selectedDateData = item;
                }
              })
            }
            if (selectedDateData !== null) {
              let mDtMeetingStartDate = null;
              let mDtMeetingEndDate = null;
              let stDate = new Date(result.meeting_start_time);
              let endDate = new Date(result.meeting_end_time);

              let stHr = selectedDateData.startTime.slice(0, 2);
              let stMiu = selectedDateData.startTime.slice(3, 5);

              let endHr = selectedDateData.endTime.slice(0, 2);
              let endMiu = selectedDateData.endTime.slice(3, 5);

              stDate = new Date(stDate).setHours(parseInt(stHr, 10));
              stDate = new Date(stDate).setMinutes(parseInt(stMiu, 10));

              endDate = new Date(endDate).setHours(parseInt(endHr, 10));
              endDate = new Date(endDate).setMinutes(parseInt(endMiu, 10));

              mDtMeetingStartDate = getDateFormat(
                new Date(stDate),
                new Date(stDate)
              );
              mDtMeetingEndDate = getDateFormat(
                new Date(endDate),
                new Date(endDate)
              );
              // eslint-disable-next-line
              this.props.updateMeetingBookingState({
                workingHrStartTime: mDtMeetingStartDate,
                workingHrEndTime: mDtMeetingEndDate
              })
            }
          } catch (error) {
          }
          this.setState({ changedData: !this.state.changedData, open: false });
        }
      });
    } catch (error) {
      // eslint-disable-next-line
    }
  };

  handleClose = () => {
    const { changedData } = this.state;
    // eslint-disable-next-line
    this.props.updateMeetingBookingState({ meetingPopOpen: false });
    this.setState({ changedData: !changedData });
  };
  // eslint-disable-next-line
  onEventClick(args) {
    if (args.originalEvent.target.parentElement.classList.contains("movetoactual")) {
      // eslint-disable-next-line
      args.cancel = true;
    }
  }
  handleViewMeetingpopup = () => {
    // eslint-disable-next-line
    this.props.updateMeetingBookingState({ showMeetingPopup: true });
  };
  // eslint-disable-next-line
  setTitleFromQuickInfo(title) {
    // eslint-disable-next-line
    this.props.updateMeetingBookingState({ meetingTitle: title, meetingAgenda: title });
  }

  setTemplateFromQuickInfo(templateId) {
    this.templateId = templateId
    // eslint-disable-next-line
    this.props.updateMeetingBookingState({ templateId });
  }

  setOPSMeeting( // NOSONAR
    meetingTypeId,
    meetingTypeName,
    meetingSubTypeId,
    meetingSubTypeCategory,
    btbId,
    subBTBId,
    workTypeId,
    businessImpact,
    serviceProductsId
  ) {
    let meetingTitle = "";
    if (meetingSubTypeId === 8) {
      meetingTitle = "Leave:- Full Day";
    }
    if (meetingSubTypeId === 999) {
      meetingTitle = "Leave:- First Half";
    }
    if (meetingSubTypeId === 1000) {
      meetingTitle = "Leave:- Second Half";
    }
    if (meetingSubTypeId === 1018) {
      meetingTitle = "Leave:- Quarter Day";
    }
    // eslint-disable-next-line
    this.props.updateMeetingBookingState({
      meetingTypeName,
      meetingTypeId,
      meetingSubTypeCategory,
      btbId,
      subBTBId,
      workTypeId,
      businessImpact,
      meetingSubTypeId,
      serviceProductsId,
      meetingTitle: meetingTitle
    });
  }

  getTimeString(value) {
    return this.instance.formatDate(value, { skeleton: "Hm" });
  }

  handleExchange = (pMeetingId) => {  // NOSONAR
    const { categoryName, otherUser, otherUserAccess, authUser } = this.props;

    if (this.scheduleObj !== null && typeof this.scheduleObj !== 'undefined') {
      this.scheduleObj.showQuickInfo = false;
    }
    if (
      categoryName === OTHERS_CALENDAR &&
      otherUser !== 0 &&
      otherUserAccess === "Y"
    ) {
      const meetingDetails = services.getIndMeetingDts(
        otherUser,
        pMeetingId,
        "P",
        false,
        true
      );
      meetingDetails.then((result) => {
        const m_actualMettingDayDifference = dencrypt(localStorage.getItem('defActualDays'))
        if (result.Result === "Available") {
          if (result.dayDifferenceForActual !== null && result.dayDifferenceForActual > m_actualMettingDayDifference) {
            if (m_actualMettingDayDifference <= 7) {
              getNotify(WARNING, movePlanToActualFiveDaysOnly);
            }
            else {
              let dateTime = addDays(new Date(), -m_actualMettingDayDifference)
              let numOrDate = `01-Apr-${dateTime.getFullYear()}`
              getNotify(
                WARNING,
                `You can edit the actual calendar slot from ${numOrDate} to the current day.`
              );
            }
            return;
          }
          this.meetingIdToTransfer = pMeetingId;
          const mUserId = authUser;
          const mData = {
            plan_meeting_id: this.meetingIdToTransfer,
            meetingmode: "A",
            user_id: mUserId,
            meeting_id: 0,
          };
          // eslint-disable-next-line
          this.props.saveMeeting(mData);
          if (this.scheduleObj !== null && typeof this.scheduleObj !== 'undefined') {
            this.scheduleObj.showQuickInfo = true;
          }
          if (this.scheduleObj !== null && typeof this.scheduleObj !== 'undefined') {
            this.scheduleObj.showQuickInfo = true;
          }
        } else {
          getNotify(WARNING, MeetingSlotNotAvailable);
        }
      });
    } else {
      const meetingDetails = services.getIndMeetingDts(
        authUser,
        pMeetingId,
        "P",
        false,
        true
      );

      meetingDetails.then((item) => {
        if (item.Result === "Available") {
          const m_actualMettingDayDifference = dencrypt(localStorage.getItem('defActualDays'))
          if (item.dayDifferenceForActual !== null && item.dayDifferenceForActual > m_actualMettingDayDifference) {
            if (m_actualMettingDayDifference <= 7) {
              getNotify(WARNING, movePlanToActualFiveDaysOnly);
            }
            else {
              let dateTime = addDays(new Date(), -m_actualMettingDayDifference)
              let numOrDate = `01-Apr-${dateTime.getFullYear()}`
              getNotify(
                WARNING,
                `You can edit the actual calendar slot from ${numOrDate} to the current day.`
              );
            }
            return;
          }
          this.meetingIdToTransfer = pMeetingId;
          const mUserId = authUser;
          const mData = {
            plan_meeting_id: this.meetingIdToTransfer,
            meetingmode: "A",
            user_id: mUserId,
            meeting_id: 0,
          };
          // eslint-disable-next-line
          this.props.saveMeeting(mData);
          if (this.scheduleObj !== null && typeof this.scheduleObj !== 'undefined') {
            this.scheduleObj.showQuickInfo = true;
          }
          if (this.scheduleObj !== null && typeof this.scheduleObj !== 'undefined') {
            this.scheduleObj.showQuickInfo = true;
          }
        } else {
          getNotify(WARNING, MeetingSlotNotAvailable);
        }
      });
    }

    if (this.scheduleObj !== null && typeof this.scheduleObj !== 'undefined') {
      this.scheduleObj.showQuickInfo = true;
    }
  };

  getIcon = (icon, pMeetingId, declineAttendeeFlag) => {
    let IconContent;
    switch (icon) {
      case "2":
        IconContent = <FontAwesomeIcon icon={faBan} title="Declined" />;
        break;
      case "3":
        IconContent = (
          <FontAwesomeIcon icon={faClock} title="Suggested New Time" />
        );
        break;
      case "MoveToActual":
        IconContent = (
          <div className="movetoactual" style={{ marginTop: 2 }}>
            <FontAwesomeIcon
              icon={faClone}
              onClick={() => this.handleExchange(pMeetingId)}
              title="Move to Actual Meeting"
            />
          </div>
        );
        break;
      case "Actual":
      case "ActualRes":
        IconContent = (
          <FontAwesomeIcon icon={faCheckCircle} title="Actual Meeting" />
        );
        break;
      case "Recurrence":
        IconContent = (
          <FontAwesomeIcon icon={faRecycle} title="Recurrence Meeting" />
        );
        break;
      case "MultiAttendee":
        if (declineAttendeeFlag) {
          IconContent = (
            <FontAwesomeIcon style={{ color: '#a52a2a' }} icon={faUserFriends} title="One or more Attendee declined the meeting" />
          );
        } else {
          IconContent = (
            <FontAwesomeIcon icon={faUserFriends} title="Multiple Attendee" />
          );
        }
        break;
      case "1":
      case "CH_MS":
        IconContent = <FontAwesomeIcon icon={faCheckDouble} title="Accepted" />;
        break;
      case "No":
        break;
      default:
        IconContent = <FontAwesomeIcon style={{ marginTop: 3 }} icon={faCheck} title='Meeting Acceptance' />
    }
    return IconContent;
  };
  // eslint-disable-next-line
  eventTemplate(props) {// NOSONAR
    let mdtMeetingDate;
    let dif = props.EndTime - props.StartTime;
    let percent = "100%";
    dif = Math.round(dif / 1000 / 60);
    if (dif <= 20 && props.Subject.length > 20) {
      if (this.scheduleObj.currentView === 'Work') {
        percent = "50%"
      } else {
        if (props.Attendee.includes(',')) {
          percent = "80%"
        } else {
          percent = "90%"
        }
      }
    } else {
      if (this.scheduleObj.currentView === 'Work') {
        percent = "50%"
      }
    }

    if ((props.IsAllDay === true || props.IsAllDay === 1) || (props.IsFullDay === true || props.IsFullDay === 1)) {
      if (this.scheduleObj.currentView === "Agenda")
        mdtMeetingDate = "All day";
      else
        mdtMeetingDate = props.Subject;
    } else {
      mdtMeetingDate = `${this.instance.formatDate(props.StartTime, {
        skeleton: "Hm",
      })} - ${this.instance.formatDate(props.EndTime, { skeleton: "Hm" })}`;
    }
    let borderLeft = borderLeftStyle;
    let border = borderStyle;
    let borderColor = props.SecondaryColor;
    let fontColor = fontColorStyle;
    let timeScale = this.scheduleObj.timeScale.interval;
    let minTimescale = 0;
    let autoTickClassLoad = 0;
    if (timeScale === 5) {
      minTimescale = 3;
      autoTickClassLoad = timeScale + 2;
    }
    if (timeScale === 10) {
      autoTickClassLoad = timeScale + 5;
      minTimescale = 8;
    }
    if (timeScale === 15) {
      autoTickClassLoad = timeScale + 8;
      minTimescale = 8;
    }
    if (timeScale === 30) {
      autoTickClassLoad = timeScale + 16;
      minTimescale = 16;
    }
    if (timeScale === 60) {
      autoTickClassLoad = timeScale + 16;
      timeScale = timeScale + 15;
      minTimescale = 34;
    }
    if (props.MeetingType === "Break") {
      borderLeft = breakBorderLeftStyle;
      border = breakBorderStyle;
      borderColor = breakBorderColorStyle;
      fontColor = breakFontColorStyle;
    } else if (props.MeetingType === "Self") {
      borderLeft = "";
      border = selfBorderStyle;
      borderColor = selfBorderColorStyle;
    }
    let styleNew = {};
    if (borderLeft === "")
      styleNew = {
        background: props.PrimaryColor,
        cursor: "pointer",
        border,
        borderColor,
        position: 'absolute'

      };
    else if (border === "")
      styleNew = {
        background: props.PrimaryColor,
        cursor: "pointer",
        borderLeft,
        borderColor,
        position: 'absolute'
      };
    else
      styleNew = {
        background: props.PrimaryColor,
        cursor: "pointer",
        borderLeft,
        border,
        borderColor,
        position: 'absolute'
      };

    if (this.scheduleObj.currentView === "Agenda") {
      if (props.MeetingType === "Break") {
        return (
          <div className="col-lg-12">
            <div
              className="row"
              style={{
                width: "100%",
                cursor: "pointer",
                marginTop: 0,
                marginBottom: 0,
              }}
            >
              <div
                style={{
                  float: "left",
                  cursor: "pointer",
                  width: 60,
                  height: 35,
                  background: props.PrimaryColor,
                  paddingLeft: 10,
                  border: "2px solid",
                  borderColor: breakBorderColorStyle,
                  position: "absolute",
                  borderRadius: 5,
                }}
              />
              <div
                className="template-wrap"
                aria-grabbed="true"
                title={props.Subject}
                style={{
                  cursor: "pointer",
                  marginLeft: 70,
                }}
              >
                <div className="time-wrap" title={props.Subject}>
                  {mdtMeetingDate}
                </div>
                <div className="subject-wrap" title={props.Subject}>
                  {props.Subject}
                </div>
              </div>
            </div>
          </div>
        );
      }
      if (props.MeetingType === "Self") {
        return (
          <div className="col-lg-12">
            <div
              className="row"
              style={{
                width: "100%",
                cursor: "pointer",
                marginTop: 0,
                marginBottom: 0,
              }}
            >
              <div
                style={{
                  float: "left",
                  cursor: "pointer",
                  width: 60,
                  height: 35,
                  background: props.PrimaryColor,
                  paddingLeft: 10,
                  border: "1px solid",
                  borderColor: selfBorderColorStyle,
                  position: "absolute",
                  borderRadius: 5,
                }}
              />
              <div
                className=""
                aria-grabbed="true"
                title={props.Subject}
                style={{
                  cursor: "pointer",
                  marginLeft: 70,
                }}
              >
                <div className="time-wrap" title={props.Subject}>
                  {mdtMeetingDate}
                </div>
                <div className="subject-wrap" title={props.Subject}>
                  {props.Subject}
                </div>
              </div>
            </div>
          </div>
        );
      }
      return (
        <div className="col-lg-12">
          <div
            className="row"
            style={{
              width: "100%",
              cursor: "pointer",
              marginTop: 0,
              marginBottom: 0,
            }}
          >
            <div
              style={{
                float: "left",
                cursor: "pointer",
                width: 60,
                height: 35,
                border: "1px solid lightgrey",
                borderTopColor: "lightgrey",
                borderRightColor: "lightgrey",
                borderBottomColor: "lightgrey",
                background: props.PrimaryColor,
                paddingLeft: 10,
                position: "absolute",
                borderLeft: props.SecondaryColor !== props.PrimaryColor ? "10px solid transparent" : "1px solid transparent",
                borderLeftColor: props.SecondaryColor !== props.PrimaryColor ? props.SecondaryColor : "lightgrey",
                borderRadius: 5
              }}
            />
            <div
              className=""
              aria-grabbed="true"
              title={props.Subject}
              style={{
                cursor: "pointer",
                marginLeft: 70,
              }}
            >
              <div className="time-wrap" title={props.Subject}>
                {mdtMeetingDate}
              </div>
              <div className="subject-wrap" title={props.Subject}>
                {props.Subject}
              </div>
            </div>
          </div>
        </div>
      );
    }
    if (props.MeetingMode === "A") {
      if (props.Differ === "Y") {
        return (
          <div
            className="template-wrap"
            title={props.Subject}
            style={{
              background: "white",
              cursor: "pointer",
              position: "absolute",
              border: "1px solid #c5bdbd"
            }}
          >
            {props.PrimaryColor === props.SecondaryColor ? (
              ""
            ) : (
              <div
                style={{ background: props.SecondaryColor }}
                title={props.MeetingType}
                className="meetingtypebox1"
              />
            )}
            {dif >= minTimescale ? (
              <div className="time"
                style={{ color: '#b4b4b4' }}
                title={props.Subject}
              >
                {this.getTimeString(props.StartTime)} -{" "}
                {this.getTimeString(props.EndTime)}
              </div>
            ) : null}

            <div className="subject"
              title={props.Subject}
            >
              <span>
                {
                  dif <= timeScale &&

                  <FontAwesomeIcon
                    icon={faCheck}
                    className="actual_icon e-round"
                    style={{
                      backgroundColor: props.SecondaryColor,
                      height: 20,
                      width: 20,
                      position: 'absolute',
                      color: props.ContactIds === null ? '#505461' : 'white'
                    }}
                  />
                }
              </span>
              {
                dif <= timeScale ?
                  null
                  :
                  <span className='subject' style={{
                    paddingLeft: 0,
                    // paddingLeft: dif <= timeScale ? 10 : 0,
                    paddingRight: 10,
                    // position:'absolute',
                    // marginTop:3
                  }}>{
                      props.Subject
                    }</span>
              }
            </div>
            {
              dif > timeScale &&
              //   <FontAwesomeIcon
              //   icon={faCheck}
              //   className="actual_icon e-round"
              //   style={{
              //     backgroundColor: props.PrimaryColor,
              //     height: 20,
              //     width: 20,
              //     float: 'left',
              //     borderRadius:10
              //   }}
              // />
              // :
              <div style={{ width: '100%', textAlign: 'center' }}>
                <center>
                  <FontAwesomeIcon
                    icon={faCheck}
                    className={dif > autoTickClassLoad ? "actual_icon e-round" : ""}
                    style={{
                      backgroundColor: props.SecondaryColor,
                      height: 20,
                      width: 20,
                      // float: 'left',
                      borderRadius: 10,
                      color: props.ContactIds === null ? '#505461' : 'white'
                    }}
                  />
                </center>
              </div>
            }
          </div>
        );
      } else if (props.Differ === "A") {
        return (
          <div
            className="template-wrap"
            title={props.Subject}
            style={{
              background: "white",
              cursor: "pointer",
              borderLeft: "5px solid",
              borderColor: props.SecondaryColor,
              border: "1px solid #c5bdbd",
              borderRadius: "5px",
              position: "absolute",
            }}
          >
            {props.PrimaryColor === props.SecondaryColor ? (
              ""
            ) : (
              <div
                style={{ background: props.SecondaryColor }}
                title={props.MeetingType}
                className="meetingtypebox1"
              />
            )}
            {dif >= minTimescale ? (
              <div className="time" title={props.Subject}>
                {this.getTimeString(props.StartTime)} -{" "}
                {this.getTimeString(props.EndTime)}
              </div>
            ) : null}
            <div className="subject" title={props.Subject}>
              {props.Subject}
            </div>
            {/* <FontAwesomeIcon
            icon={faCheck}
            className="actual_icon e-round"
            style={{
              backgroundColor: props.PrimaryColor,
              height: 20,
              width: 20,
            }}
          /> */}
          </div>
        );
      }
      return (
        <div
          className="template-wrap"
          style={{
            border: "1px solid #c5bdbd",
            borderRadius: "5px",
            backgroundColor: "white",
            position: "absolute",
          }}
        >
          <FontAwesomeIcon
            icon={faCheck}
            className="actual_icon e-round"
            style={{
              backgroundColor: props.SecondaryColor,
              height: 20,
              width: 20,
              color: props.ContactIds === null ? '#505461' : 'white'
            }}
          />
        </div>
      );
    }

    return (
      <div className="template-wrap" style={styleNew}>
        {dif >= minTimescale ? (
          <div
            className="time"
            style={{ background: props.PrimaryColor, width: percent }}
            title={props.Subject}
          >
            {this.getTimeString(props.StartTime)} -{" "}
            {this.getTimeString(props.EndTime)}
          </div>
        ) : null}

        <div
          className="subject"
          style={{ background: props.PrimaryColor, width: percent }}
          title={props.Subject}>
          {this.userSetting.TimeScale >= 30 && dif < 15 && (props.MeetingType === "Break") ? "" : <span style={{ color: fontColor }}>{props.Subject}</span>}

        </div>

        <div className="multiAttendee" >
          {
            props.Attendee.includes(',') &&
            // this.getIcon("MultiAttendee", props.MeetingId, false)
            this.getIcon("MultiAttendee", props.MeetingId, props.Attendee.includes("Decline") || props.Attendee.includes("Leave"))
          }
        </div>
        {props.Recurrence === "Y" ? (
          <div className="e-icons e-recurrence-icon">
            {this.getIcon("Recurrence", props.MeetingId, false)}
          </div>
        ) : (
          ""
        )}
        {props.AttendeeResponse === "" || props.AttendeeResponse === "Hide" ? (
          ""
        ) : (
          <div className="attendeeResponse">
            {this.getIcon(props.AttendeeResponse, props.MeetingId, false)}
          </div>
        )}
      </div>
    );
  }

  onDragStart = (args) => {
    // eslint-disable-next-line
    args.navigation.enable = true;
  };

  dragStop = (args) => {
    if (args.name === "dragStop") {
      // eslint-disable-next-line
      args.cancel = true;
      this.setState({
        currentMeetingId: args.data.MeetingId,
        // eslint-disable-next-line
        changedData: !this.state.changedData,
        dragData: args,
        currentMeetingMode: args.data.MeetingMode,
        button1Caption: "Yes",
        button2Caption: "No",
        title: "Move Meeting",
        message:
          "Are you sure you want to move this meeting to another place ?",
        open: true,
      });
    }
  };

  // Meeting Cancel,Edit,Move to Actual
  handleConfirmYes = () => {  // NOSONAR
    const {
      currentMeetingId,
      currentMeetingMode,
      title,
      dragData,
      changedData
    } = this.state;
    const { authUser, categoryName, otherUser, otherUserAccess } = this.props;
    switch (title) {
      case "Cancel Meeting": {
        // eslint-disable-next-line
        const data = {
          meeting_id: currentMeetingId,
          meetingmode: currentMeetingMode,
          user_id: authUser,
        };
        for (let i = 0; i < this.scheduleObj.eventSettings.dataSource.length; i += 1) {
          if (this.scheduleObj.eventSettings.dataSource[i].MeetingId === currentMeetingId && this.scheduleObj.eventSettings.dataSource[i].MeetingMode === currentMeetingMode) {
            this.scheduleObj.eventSettings.dataSource.splice(i, 1);
          }
        }
        // eslint-disable-next-line
        this.props.cancelMeeting(data);
        getNotify(SUCCESS, MeetingCancelledSuccessfully);
        this.scheduleObj.refreshEvents();
        this.setState({ open: false, changedData: !changedData });
        break;
      }
      case "Edit Meeting": {
        this.fungetMeetingDetails(false);
        break;
      }
      case "Move Meeting": {
        // eslint-disable-next-line
        const dataall =
          dragData.data instanceof Array ? dragData.data[0] : dragData.data;
        // eslint-disable-next-line
        const mDtMeetingStartDate = getDateFormat(
          dataall.StartTime,
          dataall.StartTime
        );
        // eslint-disable-next-line
        const mDtMeetingEndDate = getDateFormat(
          dataall.EndTime,
          dataall.EndTime
        );
        const mIMeetingId = dataall.MeetingId;
        // eslint-disable-next-line
        const meetingdata = {
          user_id: authUser,
          meeting_start_date: mDtMeetingStartDate,
          meeting_end_date: mDtMeetingEndDate,
          meeting_id: mIMeetingId,
          is_drag_meeting: "Y",
          meetingmode: dataall.MeetingMode,
        };
        // eslint-disable-next-line
        this.props.saveMeeting(meetingdata);


        break;
      }
      case "Move Meeting To Actual": {
        let mUserId;
        if (
          categoryName === OTHERS_CALENDAR &&
          otherUser !== 0 &&
          otherUserAccess === "Y"
        ) {
          mUserId = otherUser;
        } else {
          mUserId = authUser;
        }
        const mData = {
          plan_meeting_id: this.meetingIdToTransfer,
          meetingmode: "A",
          user_id: mUserId,
          meeting_id: 0,
        };
        // eslint-disable-next-line
        this.props.saveMeeting(mData);
        if (this.scheduleObj !== null && typeof this.scheduleObj !== 'undefined') {
          this.scheduleObj.showQuickInfo = true;
        }
        break;
      }
      default: {
        // eslint-disable-next-line
      }
    }
  };

  handleConfirmNo = () => {
    const {
      title,
      currentMeetingId,
      currentSeriesId,
      currentMeetingMode,
      button2Caption,
      changedData,
    } = this.state;
    const { authUser } = this.props;
    switch (title) {
      case "Edit Meeting":
        this.fungetMeetingDetails(true);
        break;
      case "Move Meeting":
        // eslint-disable-next-line
        this.setState({ open: false, changedData: !changedData });
        break;
      case "Move Meeting To Actual":
        this.meetingIdToTransfer = 0;
        if (this.scheduleObj !== null) this.scheduleObj.showQuickInfo = true;
        // eslint-disable-next-line
        this.setState({ open: false, changedData: !changedData });
        break;
      case "Cancel Meeting":
        if (button2Caption === "Cancel Series") {
          const data = {
            meeting_id: currentMeetingId,
            series_id: currentSeriesId,
            meetingmode: currentMeetingMode,
            user_id: authUser,
          };
          // eslint-disable-next-line
          this.props.cancelMeeting(data);
          getNotify(SUCCESS, SeriesMeetingCancelledSuccessfully);
          this.setState({ open: false });
        } else {
          // eslint-disable-next-line
          this.setState({ open: false, changedData: !changedData });
        }
        break;
      default:
    }
  };

  handleConfirmClose = () => {
    const { changedData } = this.state;
    // eslint-disable-next-line
    this.setState({ open: false, changedData: !changedData });
    this.meetingIdToTransfer = 0;
    this.scheduleObj.showQuickInfo = true;
  };
  onDataBound() {
    if (!this.firstTimeRefreshed) {
      this.scheduleObj.scrollTo(defTimeScrollTo);
    }
    this.funRefreshWorkHours();
    this.spinner(false)
  }



  getTz(value, minutes) {
    let updatedDate = new Date(value);
    updatedDate = new Date(
      updatedDate.setMinutes(updatedDate.getMinutes() - parseInt(minutes, 10))
    );
    return this.instance.formatDate(updatedDate, { skeleton: "Hm" });
  }

  majorSlotTemplate(props) {
    let tznm;
    if (typeof this.userSetting.tznm !== "undefined") {
      tznm = this.userSetting.tznm.map((element) => {
        if (element.Name === this.userSetting.PriTz) {
          return (
            <div className="majorSlotTemplate" key={element.Name}>
              {element.Name} &nbsp;{this.getTz(props.date, element.value)}
            </div>
          );
        }
        return (
          <div className="majorSlotTemplate" key={element.Name}>
            {element.Name} &nbsp;{this.getTz(props.date, element.value)}
          </div>
        );
      });
    }
    return (
      <div className="e-timewrap" style={{ height: 50 + "px" }}>
        {tznm}
      </div>
    );
  }


























  onCreated() {
    if (this.scheduleObj && this.scheduleObj.element) {

      let spinner = this.scheduleObj.element.querySelector('.e-spinner-pane');
      if (spinner) {
        spinner.remove();
      }
      createSpinner({
        cssClass: "custom-spinner",
        template: renderToString(<ProgressBar />),
        target: document.getElementById('scheduleControlSection')
      });
      this.dynamicChange();
    }
  }


  dynamicChange() {
    if (this.scheduleObj) {
      const timeSlotCells = this.scheduleObj.element.querySelectorAll(
        ".e-time-slots"
      );
      for (let timeSlotCellsItem of timeSlotCells) {
        if (timeSlotCellsItem.childElementCount < 1) {
          timeSlotCellsItem.classList.add("e-hide-slots");
        } else {
          let height =
            this.scheduleObj.element.querySelector(".e-work-cells").offsetHeight *
            this.scheduleObj.timeScale.slotCount;
          height -= 1;
          timeSlotCellsItem.children[0].style.height = `${height}px`;
        }
      }
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { changedData } = this.state;
    return changedData !== nextState.changedData
  }

  componentWillUnmount = () => {
    this.closeAgendaPopup();
    clearInterval(this.countdown);
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  };

  onPopupClose() {
    // to close the agenda pop up from details content
    this.closeAgendaPopup();
  }

  onPopupOpen(args) {  // NOSONAR
    const {
      categoryName,
      otherUser,
      otherUserAccess,
      authUser,
      meetingTitle,
      subCatId,
      meetingAgenda,
      meetingActionItems,
      subBTBId,
      businessImpact,
      btbId,
      workTypeId,
      meetingTypeId,
      meetingSubTypeId,
      serviceProductsId,
      meetingTypeName,
    } = this.props;
    // to close the agenda pop up from details content
    this.closeAgendaPopup();
    // eslint-disable-next-line
    const popup = args.element.ej2_instances[0];
    // eslint-disable-next-line
    popup.open = function () {
      // It will helpful to rendered the dynamic elements in popup
      setTimeout(() => {
        this.refreshPosition(); // It is used too refresh the popup window position
      }, 1000);
    };
    // Store this task id for Take A Break meeting
    // eslint-disable-next-line
    this.TaskId = args.data.TaskId;
    if (
      (args.data.EditDeleteAccess === "N" ||
        args.data.EditDeleteAccess === "D") &&
      args.type === "Editor"
    ) {
      let mDtMeetingDate;
      if ((args.data.IsAllDay === true || args.data.IsAllDay === 1) || (args.data.IsFullDay === true || args.data.IsFullDay === 1)) {
        mDtMeetingDate = `${this.instance.formatDate(args.data.StartTime, {
          skeleton: "yMMMEd",
        })} (All day)`;
      } else {
        mDtMeetingDate = `${this.instance.formatDate(args.data.StartTime, {
          skeleton: "yMMMEd",
        })} (${this.instance.formatDate(args.data.StartTime, {
          skeleton: "Hm",
        })} - ${this.instance.formatDate(args.data.EndTime, {
          skeleton: "Hm",
        })})`;
      }

      let mSeriesString = "";
      if (args.data.SeriesId > 0) {
        mSeriesString = `${getDisplayDateOnly(
          new Date(args.data.SeriesStartDate)
        )} to ${getDisplayDateOnly(
          new Date(args.data.SeriesEndDate)
        )} from ${this.instance.formatDate(args.data.StartTime, {
          skeleton: "Hm",
        })} to ${this.instance.formatDate(args.data.EndTime, {
          skeleton: "Hm",
        })} ${args.data.SeriesString}`;
      }
      this.setState(
        {
          contentData: args.data,
          mDtMeetingDate,
          seriesDateString: mSeriesString,
        },
        () =>
          // eslint-disable-next-line
          this.props.updateMeetingBookingState({ showMeetingPopup: true })
      );
      // eslint-disable-next-line
      args.cancel = true;
      return;
    }

    if (categoryName === OTHERS_CALENDAR && otherUser === 0) {
      getNotify(WARNING, pleaseUserViewDropdown);
      // eslint-disable-next-line
      args.cancel = true;
      return;
    }
    if (
      args.type === "Editor" &&
      categoryName === OTHERS_CALENDAR &&
      args.data.EditDeleteAccess === "N"
    ) {
      // eslint-disable-next-line
      args.cancel = true;
      return;
    }
    if (
      categoryName === OTHERS_CALENDAR &&
      otherUser !== 0 &&
      otherUserAccess === "N"
    ) {
      if ((args.type === "QuickInfo" || args.type === "Editor") && args.data.MeetingId === undefined) {
        getNotify(WARNING, youHaveNoAcceccToAddMeetings);
        // eslint-disable-next-line
        args.cancel = true;
        return;
      }
    }

    if (this.meetingIdToTransfer > 0) {
      // eslint-disable-next-line
      args.cancel = true;
      return;
    }

    if (
      typeof args.data.MeetingId !== "undefined" &&
      args.data.EditDeleteAccess === "N" &&
      args.type === "Editor"
    ) {
      // eslint-disable-next-line
      args.cancel = true;
      return;
    }

    // To reset the value for Subcat and BTB
    if (args.type === "QuickInfo") {
      // eslint-disable-next-line
      this.props.updateMeetingBookingState({
        btbId: "",
        subBTBId: "",
        subCatId: "",
      });
    }

    if (
      (typeof args.data.MeetingId === "undefined" &&
        args.data.TaskId === 2) ||
      (typeof args.data.MeetingId !== "undefined" &&
        args.data.MeetingMode === "A")
    ) {
      if (typeof args.data.MeetingId === "undefined") {
        if (args.type === "QuickInfo" || args.type === "Editor") {
          const data = args.data instanceof Array ? args.data[0] : args.data;
          if (
            !this.scheduleObj.isSlotAvailable(data.StartTime, data.EndTime, 1)
          ) {
            // eslint-disable-next-line
            args.cancel = true;
            getNotify(WARNING, MeetingSlotNotAvailable);
            return;
          }
        }

        if (args.data.StartTime.getTime() >= getCurrentDate(new Date()).getTime()) {
          // eslint-disable-next-line
          args.cancel = true;
          getNotify(WARNING, youCannotAddFutureMeetingInActualSection);
          return;
        }
        let numOrDate = dencrypt(localStorage.getItem('defActualDays'));
        if (numOrDate > 0) {
          const days = getDiffDays(
            args.data.StartTime,
            getCurrentDate(new Date())
          );
          if (days > numOrDate) {
            // eslint-disable-next-line
            args.cancel = true;
            if (numOrDate <= 7) {
              getNotify(
                WARNING,
                `You can only add ${numOrDate} days before meeting in actual slot.`
              );
            } else {
              let dateTime = addDays(new Date(), -numOrDate)
              numOrDate = `01-Apr-${dateTime.getFullYear()}`
              getNotify(
                WARNING,
                `You can not add meeting prior to ${numOrDate} in actual slot.`
              );
            }
            return;
          }
        } else {
          // eslint-disable-next-line
          args.cancel = true;
          getNotify(WARNING, YouCannotAddMeetingInActualSection);
          return;
        }
      }
    } else if (args.type === "QuickInfo" || args.type === "Editor") {
      if (typeof args.data.MeetingId === "undefined") {
        if (args.data.StartTime.getTime() < getCurrentDate(new Date()).getTime() && args.data.groupIndex === 0) {
          // eslint-disable-next-line
          args.cancel = true;
          getNotify(WARNING, youCannotAddBackDatedMeeting);
          return;
        }
      }
      if (typeof args.data.MeetingId === "undefined") {
        if (
          // eslint-disable-next-line
          args.data.StartTime.getTime() < getCurrentDate(new Date()).getTime() && typeof args.data.groupIndex === 'undefined'
        ) {
          // eslint-disable-next-line
          args.cancel = true;
          getNotify(WARNING, YouCannotAddBackDatedMeetingInPlanSection);
          return;
        }
      }
    }

    if (args.type === "Editor") {
      if (typeof args.data.MeetingId === "undefined") {
        let backDatedClientEditAccess = false;
        if (
          dencrypt(localStorage.getItem('defActualDays')) > 0 &&
          args.data.TaskId === 2 &&
          this.userSetting.Actual_Edit_Access === "Y"
        ) {
          const days = getDiffDays(
            args.data.StartTime,
            getCurrentDate(new Date())
          );
          if (days <= dencrypt(localStorage.getItem('defActualDays'))) {
            backDatedClientEditAccess = true;
          }
        }
        if (typeof args.data.Subject === "undefined") {
          this.props.updateMeetingBookingState({
            meetingTypeName: "",
            meetingTypeId: "",
            meetingSubTypeCategory: "",
            btbId: "",
            subBTBId: "",
            workTypeId: 0,
            businessImpact: "",
            meetingSubTypeId: "",
            serviceProductsId: []
          })
        }
        setTimeout(() => {
          // eslint-disable-next-line
          this.props.createMeeting({
            meetingDate: args.data.StartTime,
            meetingStartTime: args.data.StartTime,
            meetingEndTime: args.data.EndTime,
            meetingmode: (args.data.TaskId === 2 || args.data.groupIndex === 1) ? "A" : "P",
            meetingSetterId: authUser,
            timeZoneId: parseInt(dencrypt(localStorage.getItem("def_timezone")), 10),
            meetingPopOpen: true,
            meetingEditAccess: "Y",
            isFullDay: (typeof args.data.IsAllDay === 'undefined' || typeof args.data.IsFullDay === 'undefined' || args.data.IsAllDay === null || args.data.IsFullDay === null || args.data.IsAllDay === false || args.data.IsFullDay === false || args.data.IsAllDay === "" || args.data.IsFullDay === "") ? false : true,
            meetingTitle,
            meetingTypeId: meetingTypeId === "" ? 0 : meetingTypeId,
            meetingTypeName,
            chairPersonId: subCatId > 0 ? authUser : 0,
            backDatesClientEditAccess:
              args.data.TaskId === 1 ? backDatedClientEditAccess : false,
            meetingAgenda,
            meetingActionItems,
            subCatId: subCatId > 0 ? subCatId : "",
            btbId,
            subBTBId,
            workTypeId,
            businessImpact,
            contactsId: [],
            meetingSubTypeId,
            serviceProductsId,
            meetingRoomsId: [87],
            templateId: this.props.templateId,
            templateListData: this.props.templateListData
          });
          try {
            const { workHours } = this.state;
            let selectedDateData = null;
            if (workHours.length > 0) {
              /**
               * @author Vivek Khodade.
               * @description get month function returns from 0 to 11;
               */
              // eslint-disable-next-line
              workHours.forEach((item) => {
                if (new Date(`${new Date(item.calendarDate).getFullYear()}-${new Date(item.calendarDate).getMonth() + 1}-${new Date(item.calendarDate).getDate()}`).getTime() === new Date(`${new Date(args.data.StartTime).getFullYear()}-${new Date(args.data.StartTime).getMonth() + 1}-${new Date(args.data.StartTime).getDate()}`).getTime()) {
                  selectedDateData = item;
                }
              })
            }
            let mDtMeetingStartDate = null;
            let mDtMeetingEndDate = null;
            let stDate = new Date(args.data.StartTime);
            let endDate = new Date(args.data.EndTime);

            let stHr = selectedDateData.startTime.slice(0, 2);
            let stMiu = selectedDateData.startTime.slice(3, 5);

            let endHr = selectedDateData.endTime.slice(0, 2);
            let endMiu = selectedDateData.endTime.slice(3, 5);

            stDate = new Date(stDate).setHours(parseInt(stHr, 10));
            stDate = new Date(stDate).setMinutes(parseInt(stMiu, 10));

            endDate = new Date(endDate).setHours(parseInt(endHr, 10));
            endDate = new Date(endDate).setMinutes(parseInt(endMiu, 10));

            mDtMeetingStartDate = getDateFormat(
              new Date(stDate),
              new Date(stDate)
            );
            mDtMeetingEndDate = getDateFormat(
              new Date(endDate),
              new Date(endDate)
            );
            // eslint-disable-next-line
            this.props.updateMeetingBookingState({
              workingHrStartTime: mDtMeetingStartDate,
              workingHrEndTime: mDtMeetingEndDate
            })
          } catch (error) {
            console.log("leave meeting error", error);
          }

          // eslint-disable-next-line
          this.setState({ changedData: !this.state.changedData });
        }, 100)
        // setTimeout(()=>{
        // },300)
      } else {
        this.Meetingdata = args.data;
        if (
          args.data.Recurrence === "Y" &&
          args.data.MeetingMode === "P" &&
          args.data.EditDeleteAccess === "Y"
        ) {
          this.setState({
            currentMeetingId: args.data.MeetingId,
            currentSeriesId: args.data.SeriesId,
            // eslint-disable-next-line
            changedData: !this.state.changedData,
            button1Caption: "Edit Meeting",
            button2Caption: "Edit Series",
            currentMeetingMode: args.data.MeetingMode,
            title: "Edit Meeting",
            message: "Do you want to edit only this meeting or entire series?",
            open: true,
          });
        } else {
          this.fungetMeetingDetails(false);
        }
      }
      // eslint-disable-next-line
      args.cancel = true;
    }
    if (args.type === "DeleteAlert") {
      args.cancel = true;
      return false
    }
  }
  async addMeetingQuickInfo(args, e, eventDetails, currentAction) {  // NOSONAR
    try {
      const {
        authUser,
        subCatId,
        btbId,
        subBTBId,
        workTypeId,
        categoryName,
        otherUser,
        otherUserAccess,
        businessImpact,
        meetingTypeName,
        meetingSubTypeId,
        serviceProductsId,
        templateId,
        meetingTitle,
        meetingmode,
        meetingId
      } = this.props;



      if (document.getElementById("Subject") != null && typeof document.getElementById("Subject") !== 'undefined') {
        this.meetingTitle = document.getElementById("Subject").value
      } else {
        this.meetingTitle = meetingTitle
      }

      if (typeof e.currentTarget.id !== 'undefined' && e.currentTarget.id !== null && e.currentTarget.id === "MoreDetails") {
        this.scheduleObj.openEditor(eventDetails, currentAction, true);
        return;
      }
      const pnlId = dencrypt(localStorage.getItem("pnlId"));
      if (args.requestType === "dateNavigate") {
        if (typeof this.scheduleObj !== "undefined") {
          setTimeout(() => {
            this.funRefreshWorkHours();
            this.scheduleObj.scrollTo(defTimeScrollTo);
          }, 1000);
        }
      }

      if (
        (e.currentTarget.id === "Save" &&
          // eslint-disable-next-line
          typeof meetingId === "undefined") ||
        e.currentTarget.id === "eventChange"
      ) {
        if (args.groupIndex !== 1) {
          if (
            !this.scheduleObj.isSlotAvailable(args.startTime, args.endTime, 1)
          ) {
            // eslint-disable-next-line
            args.cancel = true;
            getNotify(WARNING, MeetingSlotNotAvailable);

            if (args.requestType === "eventChange") {
              const mNoStartDate = Math.floor(// NOSONAR
                new Date(this.startDate.getTime() / 1000.0) //NOSONAR
              );
              const mNoEndDate = Math.floor(// NOSONAR
                new Date(this.endDate.getTime() / 1000.0) //NOSONAR
              );
              this.funRefreshReport(
                mNoStartDate,
                mNoEndDate,
                0,
                meetingmode
              );
            }
            return;
          }
        }
      }

      if (e.currentTarget.id === "Save") {
        // eslint-disable-next-line
        this.setState({ meetingTitle: "" });
        let isSelfMeetFlag;
        if (this.templateId !== null && this.templateId > 0) {
          if (this.meetingTitle.trim() !== "" && this.meetingTitle !== "Add title") {
            // eslint-disable-next-line
            args.cancel = true;
            const mDtMeetingStartDate = getDateFormat(
              new Date(args.startTime),
              new Date(args.startTime)
            );
            const mDtMeetingEndDate = getDateFormat(
              new Date(args.endTime),
              new Date(args.endTime)
            );
            const response = await services.getMeetingTemplateDetails(
              authUser,
              // eslint-disable-next-line
              this.templateId
            );

            // eslint-disable-next-line
            const template_details = response.result[0];
            const meetingmodeVar = args.groupIndex === 0 ? "P" : "A";
            const recObject = new RecurrenceEditor();
            const webLink = template_details.var_meeting_web_link !== null && typeof template_details.var_meeting_web_link !== "undefined" && template_details.var_meeting_web_link.trim() !== "" ? template_details.var_meeting_web_link.trim() : null;
            const meetingdata = {
              user_id: authUser,
              meeting_id: 0,
              meeting_title: template_details.var_meeting_title.replace(/&amp;/gi, "&"),
              meeting_start_date: mDtMeetingStartDate,
              meeting_end_date: mDtMeetingEndDate,
              // eslint-disable-next-line
              meeting_type_id: template_details.fk_meeting_type,
              meeting_source_id: template_details.fk_meeting_source,
              meeting_media_id: template_details.fk_meeting_media,
              meeting_weblink_type: (templateId && templateId > 0) ? null : template_details.enum_meeting_web_link_type,
              meeting_weblink: (templateId && templateId > 0) ? null : webLink,
              participate_code: (templateId && templateId > 0) ? null : template_details.var_participate_code,
              time_zone_id: template_details.fk_stamp_time_zone,
              meeting_rooms_id: template_details.meeting_rooms_id,
              other_attendees: template_details.var_other_attendee,
              meeting_agenda:
                template_details.var_meeting_agenda === null
                  ? " "
                  : template_details.var_meeting_agenda,
              meeting_actionitems:
              template_details.var_meeting_actionitems === null
                ? " "
                : template_details.var_meeting_actionitems,
              meeting_setter_id: authUser,
              chairperson_id: template_details.fk_chair_person,
              client_id: template_details.fk_client_id,
              contacts_id: template_details.var_contact_ids,
              service_products_id: null,
              btb_id: template_details.fk_btb_id,
              sub_btb_id: template_details.fk_sub_btb_id,
              sub_cat_id: template_details.fk_sub_cat_id,
              business_impact: template_details.var_business_impact,
              is_marketing_meeting: template_details.enum_marketing_meeting,
              meeting_location: template_details.var_meeting_location,
              meeting_attendee: `${template_details.m_varAttendee}$$`,
              meetingmode: meetingmodeVar,
              // meetingmode,
              is_full_day: template_details.enum_is_full_day,
              user_type: decodeString(localStorage.getItem("user_type")),
              countries_id: template_details.var_countries_id,
              meeting_category_id: template_details.fk_meeting_category,
              bulkAttendeesFlag: template_details.enum_bulk_attendee_flag === '1' ? true : false
            };

            if (meetingmodeVar === "P" && template_details.fk_meeting_type === 7) {
              const arrDates = [];
              if (
                template_details.enum_meeting_frequency === "2" &&
                template_details.var_series_string !== ""
              ) {
                const dates = recObject.getRecurrenceDates(
                  new Date(args.StartTime),
                  template_details.var_series_string,
                  null,
                  defRecurringMaximumCount,
                  null
                );
                for (let dt of dates) {
                  arrDates.push(getDate(new Date(dt)));
                }
              }
              meetingdata.meeting_frequency =
                template_details.enum_meeting_frequency;
              meetingdata.recurring =
                template_details.var_series_string === ""
                  ? null
                  : template_details.var_series_string;
              meetingdata.meeting_dates =
                arrDates.length > 0 ? arrDates.toString() : null;
            }
            if (meetingmodeVar === "P" && template_details.fk_meeting_type !== 7) {
              meetingdata.is_self_meeting = "N";
              const arrDates = [];
              if (
                template_details.enum_meeting_frequency === "2" &&
                template_details.var_series_string !== ""
              ) {
                const dates = recObject.getRecurrenceDates(
                  new Date(args.StartTime),
                  template_details.var_series_string,
                  null,
                  defRecurringMaximumCount,
                  null
                );
                for (let dt of dates) {
                  arrDates.push(getDate(new Date(dt)));
                }
              }
              meetingdata.meeting_frequency =
                template_details.enum_meeting_frequency;
              meetingdata.recurring =
                template_details.var_series_string === ""
                  ? null
                  : template_details.var_series_string;
              meetingdata.meeting_dates =
                arrDates.length > 0 ? arrDates.toString() : null;
            }

            if (template_details.enum_meeting_web_link_type === "3") {
              const { var_meeting_agenda } = template_details;
              //zoom code
              let hostEmail = "0";
              let zoomStartDateTime = mDtMeetingStartDate;
              let zoomEndDateTime = mDtMeetingEndDate;
              let zoomMeetingTimeInMin = (new Date(removeExtraSeconds(mDtMeetingEndDate)).getTime() - new Date(removeExtraSeconds(mDtMeetingStartDate)).getTime()) / 60000;
              let date = getMySqlDateFormat(mDtMeetingStartDate);
              let zoomRecurring = date.split(" ")[0];
              let type = 2;
              let recurringObj = JSON.stringify({ "obj": '' });
              let zoomMeetingTitle = var_meeting_agenda;
              let timeZoneId = this.props.defaultsetting[0].Optional;

              if (zoomMeetingTimeInMin <= 30) {
                hostEmail = decodeString(localStorage.getItem('digitalEmailAddress'))
              }


              (async () => {
                let zoomResult = await generateZoomObject(zoomStartDateTime, zoomEndDateTime, timeZoneId, zoomMeetingTitle, type, recurringObj, hostEmail, zoomRecurring)
                if (zoomResult.data) {
                  const { join_url, password, id, host_email } = zoomResult.data;
                  meetingdata.zoomMeetingId = (templateId && templateId > 0) ? null : id.toString();
                  meetingdata.participate_code = (templateId && templateId > 0) ? null : `${id}; Passcode:${password}`;
                  meetingdata.zoomMeetingHostEmailId = (templateId && templateId > 0) ? null : host_email;
                  meetingdata.meeting_weblink = (templateId && templateId > 0) ? null : join_url;
                  meetingdata.zoomOccurrenceId = null;
                  meetingdata.zoomOccurrences = null;
                  meetingdata.time_zone_id = timeZoneId

                  this.props.saveMeeting(meetingdata);
                  this.templateId = 0;
                  this.scheduleObj.closeQuickInfoPopup();

                } else if (zoomResult.error) {
                  getNotify(ERROR, zoomResult.error)
                  return
                }
              })()


            } else {
              this.props.saveMeeting(meetingdata);
              this.templateId = 0;
              this.scheduleObj.closeQuickInfoPopup();
            }


          } else {
            args.cancel = true;
            getNotify(ERROR, pleaseEnterMeetingTitle);
          }
        } else if (
          // Other than template
          this.meetingTitle.trim() !== "" &&
          this.meetingTitle !== "Add title"
          // && meetingId === 0
        ) {
          isSelfMeetFlag = "N";
          const mDtMeetingStartDate = getDateFormat(
            new Date(args.startTime),
            new Date(args.startTime)
          );
          const mDtMeetingEndDate = getDateFormat(
            new Date(args.endTime),
            new Date(args.endTime)
          );

          if (meetingTypeName === "") {
            getNotify(WARNING, pleaseselectappropriateMeetingType);
            // eslint-disable-next-line
            args.cancel = true;
            // eslint-disable-next-line
            return false;
          }


          if (meetingTypeName !== "Break" && meetingTypeName !== "Self") {
            if (meetingSubTypeId === "" || meetingSubTypeId === 0) {
              getNotify(WARNING, pleaseselectappropriateMeetingSubType);
              // eslint-disable-next-line
              args.cancel = true;
              // eslint-disable-next-line
              return false;
            }
          }
          if (OPSBTBDropdown.includes(meetingSubTypeId)) {
            // check the BTB / Ops / Client condition
            if (btbId === "" || btbId === 0) {
              getNotify(WARNING, pleaseSelectAppropriateBtb);
              // eslint-disable-next-line
              args.cancel = true;
              // eslint-disable-next-line
              return false;
            }
            if ((workTypeId === 5) &&
              (subBTBId === 0 || subBTBId === "" || subBTBId === null) &&
              pnlId !== Cognition
            ) {
              getNotify(WARNING, pleaseselectappropriatejob);
              // eslint-disable-next-line
              args.cancel = true;
              // eslint-disable-next-line
              return false;
            }
            if (btbId < 0 && serviceProductsId.length === 0) {
              getNotify(WARNING, pleaseSelectAppropriateMeetingServiceProduct);
              // eslint-disable-next-line
              args.cancel = true;
              // eslint-disable-next-line
              return false;
            }
          } else if (meetingTypeName === "Self") {
            isSelfMeetFlag = "Y";
          } else if (meetingTypeName === "Break") {
            args.cancel = true;
            this.funcAddBreakMeeting(args, true);
            this.scheduleObj.closeQuickInfoPopup();
            return;
          }
          else if (meetingTypeName === "Leave") {
            args.cancel = true;
            this.funcAddLeaveMeeting(args, true);
            this.scheduleObj.closeQuickInfoPopup();
            return;
          }

          let meetingdata;
          // eslint-disable-next-line
          let user_id = authUser;
          const meetingSetterId = authUser;
          if (
            categoryName === OTHERS_CALENDAR &&
            otherUser !== 0 &&
            otherUserAccess === "Y"
          ) {
            // eslint-disable-next-line
            user_id = otherUser;
          }

          if (isSelfMeetFlag === "N") {
            meetingdata = {
              meeting_type_id: meetingSubTypeId, // Meeting Type is derived from Meeting Sub Type
              user_id,
              // eslint-disable-next-line
              meeting_title: this.meetingTitle.replace(/&amp;/gi, "&"),
              meeting_start_date: mDtMeetingStartDate,
              meeting_end_date: mDtMeetingEndDate,
              meetingmode: args.groupIndex === 0 ? "P" : "A",
              is_full_day: args.isAllDay === 1 || args.isAllDay === true || args.isFullDay === 1 || args.isFullDay === true ? "1" : null,
              meeting_setter_id: meetingSetterId,
              time_zone_id: parseInt(dencrypt(localStorage.getItem("def_timezone")), 10),
              meeting_media_id: 52, // Digital Meeting
              // eslint-disable-next-line
              meeting_rooms_id: "87",
              chairperson_id: user_id,
              btb_id: btbId === "" || btbId === 0 ? null : btbId,
              sub_btb_id: subBTBId === "" || subBTBId === 0 ? null : subBTBId,
              sub_cat_id: subCatId === "" ? 0 : subCatId,
              // eslint-disable-next-line
              business_impact: businessImpact === "" ? null : businessImpact,
              // eslint-disable-next-line
              meeting_attendee: `${user_id}_1_0$$`,
              // eslint-disable-next-line
              user_type: "Ops",
              meeting_source_id: null,
              meeting_weblink_type: null,
              meeting_weblink: null,
              participate_code: null,
              other_attendees: null,
              meeting_agenda: "",
              meeting_actionitems: "",
              client_id: null,
              contacts_id: null,
              service_products_id:
                serviceProductsId.length > 0
                  ? serviceProductsId.toString()
                  : null,
              is_marketing_meeting: null,
              meeting_location: null,
              countries_id: null,
              meeting_category_id: null,
            };
            if (args.groupIndex === 0) {
              meetingdata.is_self_meeting = isSelfMeetFlag;
              meetingdata.meeting_frequency = 1;
              meetingdata.meeting_dates = null;
              meetingdata.recurring = null;
            }
          } else {
            meetingdata = {
              user_id,
              // eslint-disable-next-line
              meeting_title: this.meetingTitle.replace(/&amp;/gi, "&"),
              meeting_start_date: mDtMeetingStartDate,
              meeting_end_date: mDtMeetingEndDate,
              is_self_meeting: isSelfMeetFlag,
              meetingmode: args.groupIndex === 0 ? "P" : "A",
              is_full_day: args.isAllDay === 1 || args.isFullDay === 1 ? "1" : null,
              meeting_setter_id: meetingSetterId
            };
          }
          setTimeout(() => {
            // eslint-disable-next-line
            this.props.saveMeeting(meetingdata);
          }, 0);
          // eslint-disable-next-line
          args.cancel = true;
          this.scheduleObj.closeQuickInfoPopup();
        } else if (typeof meetingId === "undefined" || meetingId === 0) {
          getNotify(ERROR, pleaseEnterMeetingTitle);
          // eslint-disable-next-line
          args.cancel = true;
        }
      }
    } catch (error) {
      console.log(error, 'addMeetingQuickInfo-error')
    }
  }
  async onActionBegin(args) {  // NOSONAR
    const {
      authUser,
      subCatId,
      btbId,
      subBTBId,
      workTypeId,
      categoryName,
      otherUser,
      otherUserAccess,
      businessImpact,
      meetingTypeName,
      meetingSubTypeId,
      serviceProductsId,
      meetingTypeId
    } = this.props;
    if (args.requestType === "toolbarItemRendering") {
      args.items[2].click = () => {
        setTimeout((e) => {
          const calendar = document.getElementsByClassName("e-calendar");
          if (calendar) {
            calendar[0].ej2_instances[0].weekNumber = true;
            calendar[0].ej2_instances[0].weekRule = "FirstFourDayWeek"; // "FirstFullWeek" or "FirstFourDayWeek"
          }
        }, 1);
      };
      let items = [{ align: 'Right', type: 'Separator' }, { align: 'Right', text: 'Holiday list', cssClass: 'holidayListCalendarTab', click: this.onHoliDayListClick.bind(this) }, { align: 'Right', type: 'Separator' }, { align: 'Right', text: 'Apply Leave', cssClass: 'leaveDetailsTab', click: this.onLeaveDetailsClick.bind(this) }];
      args.items = args.items.concat(items);
    }
    // Delete Meeting
    if (args.data !== undefined) {
      if (args.requestType === "eventRemove") {
        if (!(args.data[0].EditDeleteAccess === "Y" || args.data[0].EditDeleteAccess === "D")) {
          args.cancel = true;
          return false
        }
        // eslint-disable-next-line
        args.cancel = true;
        if (args.data[0].Recurrence === "Y" && args.data[0].EditDeleteAccess !== "D") {
          this.setState({
            currentMeetingId: args.data[0].MeetingId,
            currentSeriesId: args.data[0].SeriesId,
            button1Caption: "Cancel Meeting",
            button2Caption: "Cancel Series",
            currentMeetingMode: args.data[0].MeetingMode,
            // eslint-disable-next-line
            changedData: !this.state.changedData,
            title: "Cancel Meeting",
            message:
              "Are you sure you want to cancel this meeting or entire series" + ` from ${getDisplayDateOnly(args.data[0].StartTime)} onwards?`,
            open: true,
          });
        } else {
          this.setState({
            currentMeetingId: args.data[0].MeetingId,
            currentSeriesId: 0,
            button1Caption: "Yes",
            button2Caption: "No",
            currentMeetingMode: args.data[0].MeetingMode,
            // eslint-disable-next-line
            changedData: !this.state.changedData,
            title: "Cancel Meeting",
            message: "Are you sure you want to cancel this meeting?",
            open: true,
          });
        }
      }
    }
    const pnlId = dencrypt(localStorage.getItem("pnlId"));

    if (args.requestType === "dateNavigate") {
      if (typeof this.scheduleObj !== "undefined") {
        setTimeout(() => {
          this.funRefreshWorkHours();
          this.scheduleObj.scrollTo(defTimeScrollTo);
        }, 1000);
      }
    }
    /// for actual meeting check whether the slot is available or not
    const data = args.data instanceof Array ? args.data[0] : args.data;
    if (
      (args.requestType === "eventCreate" &&
        // eslint-disable-next-line
        typeof data.MeetingId === "undefined") ||
      args.requestType === "eventChange"
    ) {
      if (data.TaskId !== 1) {
        if (
          !this.scheduleObj.isSlotAvailable(data.StartTime, data.EndTime, 1)
        ) {
          args.cancel = true;
          getNotify(WARNING, MeetingSlotNotAvailable);

          if (args.requestType === "eventChange") {
            const mNoStartDate = Math.floor(// NOSONAR
              new Date(this.startDate.getTime() / 1000.0) //NOSONAR
            );
            const mNoEndDate = Math.floor(// NOSONAR
              new Date(this.endDate.getTime() / 1000.0) //NOSONAR
            );
            this.funRefreshReport(
              mNoStartDate,
              mNoEndDate,
              data.MeetingId,
              data.MeetingMode
            );
          }
          return;
        }
      }
    }
    if (args.requestType === "eventCreate") {
      // eslint-disable-next-line
      this.setState({ meetingTitle: "" });
      let isSelfMeetFlag;
      if (data.template_list !== null && data.template_list > 0) {
        if (data.Subject.trim() !== "" && data.Subject !== "Add title") {
          // eslint-disable-next-line
          args.cancel = true;
          const mDtMeetingStartDate = getDateFormat(
            new Date(data.StartTime),
            new Date(data.StartTime)
          );
          const mDtMeetingEndDate = getDateFormat(
            new Date(data.EndTime),
            new Date(data.EndTime)
          );
          const response = await services.getMeetingTemplateDetails(

            authUser,
            // eslint-disable-next-line
            data.template_list
          );
          // eslint-disable-next-line
          const template_details = response.result[0];
          const meetingmode = data.TaskId === 1 ? "P" : "A";
          const recObject = new RecurrenceEditor();
          const webLink = typeof data.weblink !== "undefined" && data.weblink !== null && data.weblink.trim() !== "" ? data.weblink.trim() : null;
          const meetingdata = {
            user_id: authUser,
            meeting_id: 0,
            meeting_title: data.Subject.replace(/&amp;/gi, "&"),
            meeting_start_date: mDtMeetingStartDate,
            meeting_end_date: mDtMeetingEndDate,
            // eslint-disable-next-line
            meeting_type_id: template_details.fk_meeting_type,
            meeting_source_id: template_details.fk_meeting_source,
            meeting_media_id: template_details.fk_meeting_media,
            meeting_weblink_type: template_details.enum_meeting_web_link_type,
            meeting_weblink: webLink,
            participate_code: template_details.var_participate_code,
            time_zone_id: template_details.fk_stamp_time_zone,
            meeting_rooms_id: template_details.meeting_rooms_id,
            other_attendees: template_details.var_other_attendee,
            meeting_agenda:
              template_details.var_meeting_agenda === null
                ? " "
                : template_details.var_meeting_agenda,
            meeting_actionitems:
              template_details.var_meeting_actionitems === null
                ? " "
                : template_details.var_meeting_actionitems,
            meeting_setter_id: authUser,
            chairperson_id: template_details.fk_chair_person,
            client_id: template_details.fk_client_id,
            contacts_id: template_details.var_contact_ids,
            service_products_id: null,
            btb_id: template_details.fk_btb_id,
            sub_btb_id: template_details.fk_sub_btb_id,
            sub_cat_id: template_details.fk_sub_cat_id,
            business_impact: template_details.var_business_impact,
            is_marketing_meeting: template_details.enum_marketing_meeting,
            meeting_location: template_details.var_meeting_location,
            meeting_attendee: `${template_details.m_varAttendee}$$`,
            meetingmode,
            is_full_day: template_details.enum_is_full_day,
            user_type: decodeString(localStorage.getItem("user_type")),
            countries_id: template_details.var_countries_id,
            meeting_category_id: template_details.fk_meeting_category,
            bulkAttendeesFlag: template_details.enum_bulk_attendee_flag === '1' ? true : false
          };

          if (meetingmode === "P" && template_details.fk_meeting_type === 7) {
            const arrDates = [];
            if (
              template_details.enum_meeting_frequency === "2" &&
              template_details.var_series_string !== ""
            ) {
              const dates = recObject.getRecurrenceDates(
                new Date(data.StartTime),
                template_details.var_series_string,
                null,
                defRecurringMaximumCount,
                null
              );
              for (let dt of dates) {
                arrDates.push(getDate(new Date(dt)));
              }
            }
            meetingdata.meeting_frequency =
              template_details.enum_meeting_frequency;
            meetingdata.recurring =
              template_details.var_series_string === ""
                ? null
                : template_details.var_series_string;
            meetingdata.meeting_dates =
              arrDates.length > 0 ? arrDates.toString() : null;
          }
          if (meetingmode === "P" && template_details.fk_meeting_type !== 7) {
            meetingdata.is_self_meeting = "N";
            const arrDates = [];
            if (
              template_details.enum_meeting_frequency === "2" &&
              template_details.var_series_string !== ""
            ) {
              const dates = recObject.getRecurrenceDates(
                new Date(data.StartTime),
                template_details.var_series_string,
                null,
                defRecurringMaximumCount,
                null
              );
              for (let dt of dates) {
                arrDates.push(getDate(new Date(dt)));
              }
            }
            meetingdata.meeting_frequency =
              template_details.enum_meeting_frequency;
            meetingdata.recurring =
              template_details.var_series_string === ""
                ? null
                : template_details.var_series_string;
            meetingdata.meeting_dates =
              arrDates.length > 0 ? arrDates.toString() : null;
          }
          // eslint-disable-next-line
          if (template_details.enum_meeting_web_link_type === "3") {
            const { var_meeting_agenda } = template_details;
            //zoom code
            let hostEmail = "0";
            let zoomStartDateTime = mDtMeetingStartDate;
            let zoomEndDateTime = mDtMeetingEndDate;
            let zoomMeetingTimeInMin = (new Date(removeExtraSeconds(mDtMeetingEndDate)).getTime() - new Date(removeExtraSeconds(mDtMeetingStartDate)).getTime()) / 60000;
            let date = getMySqlDateFormat(mDtMeetingStartDate);
            let zoomRecurring = date.split(" ")[0];
            let type = 2;
            let recurringObj = JSON.stringify({ "obj": '' });
            let zoomMeetingTitle = var_meeting_agenda;
            let timeZoneId = this.props.defaultsetting[0].Optional;

            if (zoomMeetingTimeInMin <= 30) {
              hostEmail = decodeString(localStorage.getItem('digitalEmailAddress'))
            }


            (async () => {
              let zoomResult = await generateZoomObject(zoomStartDateTime, zoomEndDateTime, timeZoneId, zoomMeetingTitle, type, recurringObj, hostEmail, zoomRecurring)
              if (zoomResult.data) {
                const { join_url, password, id, host_email } = zoomResult.data;
                meetingdata.zoomMeetingId = id.toString();
                meetingdata.participate_code = `${id}; Passcode:${password}`;
                meetingdata.zoomMeetingHostEmailId = host_email;
                meetingdata.meeting_weblink = join_url;
                meetingdata.zoomOccurrenceId = null;
                meetingdata.zoomOccurrences = null;
                meetingdata.time_zone_id = timeZoneId

                this.props.saveMeeting(meetingdata);

              } else if (zoomResult.error) {
                getNotify(ERROR, zoomResult.error)
                return
              }
            })()
          } else {
            this.props.saveMeeting(meetingdata);

          }
        } else {
          // eslint-disable-next-line
          args.cancel = true;
          getNotify(ERROR, pleaseEnterMeetingTitle);
        }
      } else if (
        // Other than template
        data.Subject.trim() !== "" &&
        data.Subject !== "Add title" &&
        typeof data.MeetingId === "undefined"
      ) {
        isSelfMeetFlag = "N";
        const mDtMeetingStartDate = getDateFormat(
          new Date(data.StartTime),
          new Date(data.StartTime)
        );
        const mDtMeetingEndDate = getDateFormat(
          new Date(data.EndTime),
          new Date(data.EndTime)
        );
        if (meetingTypeName === "") {
          getNotify(ERROR, pleaseselectappropriateMeetingType);
          // eslint-disable-next-line
          args.cancel = true;
          // eslint-disable-next-line
          return false;
        }
        if (meetingTypeName !== "Break" && meetingTypeName !== "Self") {
          if (meetingSubTypeId === "" || meetingSubTypeId === 0) {
            getNotify(ERROR, pleaseselectappropriateMeetingSubType);
            // eslint-disable-next-line
            args.cancel = true;
            // eslint-disable-next-line
            return false;
          }
        }
        if (ClientMeetingsId.includes(meetingTypeId)) {
          // check the BTB / Ops / Client condition
          if (btbId === "" || btbId === 0) {
            getNotify(ERROR, pleaseSelectAppropriateBtb);
            // eslint-disable-next-line
            args.cancel = true;
            // eslint-disable-next-line
            return false;
          }
          if (
            (workTypeId === 5) &&
            (subBTBId === 0 || subBTBId === "" || subBTBId === null) &&
            pnlId !== Cognition
          ) {
            getNotify(ERROR, pleaseselectappropriatejob);
            // eslint-disable-next-line
            args.cancel = true;
            // eslint-disable-next-line
            return false;
          }
          if (btbId < 0 && serviceProductsId.length === 0) {
            getNotify(ERROR, pleaseSelectAppropriateMeetingServiceProduct);
            // eslint-disable-next-line
            args.cancel = true;
            // eslint-disable-next-line
            return false;
          }
        } else if (meetingTypeName === "Self") {
          isSelfMeetFlag = "Y";
        } else if (meetingTypeName === "Break") {
          args.cancel = true;
          this.funcAddBreakMeeting(data, true);
          return;
        }
        else if (meetingTypeName === "Leave") {
          args.cancel = true;
          this.funcAddLeaveMeeting(args, true);
          this.scheduleObj.closeQuickInfoPopup();
          return;
        }

        let meetingdata;
        // eslint-disable-next-line
        let user_id = authUser;
        const meetingSetterId = authUser;
        if (
          categoryName === OTHERS_CALENDAR &&
          otherUser !== 0 &&
          otherUserAccess === "Y"
        ) {
          // eslint-disable-next-line
          user_id = otherUser;
        }

        if (isSelfMeetFlag === "N") {
          meetingdata = {
            meeting_type_id: meetingSubTypeId, // Meeting Type is derived from Meeting Sub Type
            user_id,
            // eslint-disable-next-line
            meeting_title: data.Subject.replace(/&amp;/gi, "&"),
            meeting_start_date: mDtMeetingStartDate,
            meeting_end_date: mDtMeetingEndDate,
            meetingmode: data.TaskId === 1 ? "P" : "A",
            is_full_day:
              data.IsAllDay === 1 || data.IsAllDay === true || data.IsFullDay === 1 || data.IsFullDay === true ? "1" : null,
            meeting_setter_id: meetingSetterId,
            time_zone_id: parseInt(dencrypt(localStorage.getItem("def_timezone")), 10),
            meeting_media_id: 52, // Digital Meeting
            // eslint-disable-next-line
            meeting_rooms_id: "87",
            chairperson_id: user_id,
            btb_id: btbId === "" || btbId === 0 ? null : btbId,
            sub_btb_id: subBTBId === "" || subBTBId === 0 ? null : subBTBId,
            sub_cat_id: subCatId === "" ? 0 : subCatId,
            // eslint-disable-next-line
            business_impact: businessImpact === "" ? null : businessImpact,
            // eslint-disable-next-line
            meeting_attendee: `${user_id}_1_0$$`,
            // eslint-disable-next-line
            user_type: "Ops",
            meeting_source_id: null,
            meeting_weblink_type: null,
            meeting_weblink: null,
            participate_code: null,
            other_attendees: null,
            meeting_agenda: "",
            meeting_actionitems: "",
            client_id: null,
            contacts_id: null,
            service_products_id:
              serviceProductsId.length > 0
                ? serviceProductsId.toString()
                : null,
            is_marketing_meeting: null,
            meeting_location: null,
            countries_id: null,
            meeting_category_id: null,
          };
          if (data.TaskId === 1) {
            meetingdata.is_self_meeting = isSelfMeetFlag;
            meetingdata.meeting_frequency = 1;
            meetingdata.meeting_dates = null;
            meetingdata.recurring = null;
          }
        } else {
          meetingdata = {
            user_id,
            // eslint-disable-next-line
            meeting_title: data.Subject.replace(/&amp;/gi, "&"),
            meeting_start_date: mDtMeetingStartDate,
            meeting_end_date: mDtMeetingEndDate,
            is_self_meeting: isSelfMeetFlag,
            meetingmode: data.TaskId === 1 ? "P" : "A",
            is_full_day: data.IsAllDay === 1 || data.IsFullDay === 1 ? "1" : null,
            meeting_setter_id: meetingSetterId,
          };
        }
        setTimeout(() => {
          // eslint-disable-next-line
          this.props.saveMeeting(meetingdata);
        }, 0);
        // eslint-disable-next-line
        args.cancel = true;
      } else if (typeof data.MeetingId === "undefined") {
        getNotify(ERROR, pleaseEnterMeetingTitle);
        // eslint-disable-next-line
        args.cancel = true;
      }
    }
    if (args.requestType === "eventChange") {
      switch (data.MeetingMode) {
        case "P":
          if (data.EditDeleteAccess === "N") {
            getNotify(ERROR, YouCannotChangeTheMeetingDetails);
            // eslint-disable-next-line
            args.cancel = true;
            // eslint-disable-next-line
            return false;
          }
          if (
            data.StartTime.getTime() < getCurrentDate(new Date()).getTime() ||
            data.EndTime.getTime() < getCurrentDate(new Date()).getTime()
          ) {
            getNotify(
              ERROR,
              ForPlanMeetingStartTimeAndEndTimeMustBeGreaterThanCurrentTime
            );
            // eslint-disable-next-line
            args.cancel = true;
            // eslint-disable-next-line
            return false;
          }
          break;
        case "A":
          if (
            data.StartTime.getTime() > getCurrentDate(new Date()).getTime() ||
            data.EndTime.getTime() > getCurrentDate(new Date()).getTime()
          ) {
            getNotify(
              ERROR,
              ForActualMeetingStartTimeAndEndTimeMustBeLessThanCurrentTime
            );
            // eslint-disable-next-line
            args.cancel = true;
            // eslint-disable-next-line
            return false;
          }
          if (dencrypt(localStorage.getItem('defActualDays')) > 0) {
            const days = getDiffDays(
              data.StartTime,
              getCurrentDate(new Date())
            );
            if (days > dencrypt(localStorage.getItem('defActualDays'))) {
              // eslint-disable-next-line
              args.cancel = true;
              getNotify(
                WARNING,
                `You can only drag ${dencrypt(localStorage.getItem('defActualDays')).toString()} days before actual meeting.`
              );
              return;
            }
          } else {
            // eslint-disable-next-line
            args.cancel = true;
            getNotify(WARNING, YouCannotAddMeetingInActualSection);
            return;
          }
          break;
        default:
      }


      let mDtMeetingStartDate = getDateFormat(
        new Date(data.StartTime),
        new Date(data.StartTime)
      );
      let mDtMeetingEndDate = getDateFormat(
        new Date(data.EndTime),
        new Date(data.EndTime)
      );

      if (mDtMeetingStartDate === mDtMeetingEndDate) {
        mDtMeetingEndDate = getDateFormat(
          new Date(data.EndTime.getTime() + (this.userSetting.TimeScale / 2) * 60000),
          new Date(data.EndTime.getTime() + (this.userSetting.TimeScale / 2) * 60000)
        );
      }

      const miMeetingid = data.MeetingId;
      const meetingdata = {
        user_id: categoryName === OTHERS_CALENDAR ? otherUser : authUser,
        meeting_start_date: mDtMeetingStartDate,
        meeting_end_date: mDtMeetingEndDate,
        meeting_id: miMeetingid,
        is_drag_meeting: "Y",
        meetingmode: data.MeetingMode,
      };
      setTimeout(() => {
        // eslint-disable-next-line
        if (data.zoomMeetingId === "") {
          this.props.saveMeeting(meetingdata);
        } else {
          //zoom code
          let hostEmail = "0";
          let zoomStartDateTime = mDtMeetingStartDate;
          let zoomEndDateTime = mDtMeetingEndDate;
          let zoomMeetingTimeInMin = (new Date(removeExtraSeconds(mDtMeetingEndDate)).getTime() - new Date(removeExtraSeconds(mDtMeetingStartDate)).getTime()) / 60000;
          let date = getMySqlDateFormat(mDtMeetingStartDate);
          let zoomRecurring = date.split(" ")[0];
          let type = 2;
          let recurringObj = JSON.stringify({ "obj": '' });
          let zoomMeetingTitle = data.Agenda;
          let timeZoneId = this.props.defaultsetting[0].Optional;

          if (zoomMeetingTimeInMin <= 30) {
            hostEmail = decodeString(localStorage.getItem('digitalEmailAddress'))
          }


          (async () => {
            let zoomResult = await generateZoomObject(zoomStartDateTime, zoomEndDateTime, timeZoneId, zoomMeetingTitle, type, recurringObj, hostEmail, zoomRecurring)
            if (zoomResult.data) {
              const { join_url, password, id, host_email } = zoomResult.data;
              meetingdata.zoomMeetingId = id.toString();
              meetingdata.participate_code = `${id}; Passcode:${password}`;
              meetingdata.zoomMeetingHostEmailId = host_email;
              meetingdata.meeting_weblink = join_url;
              meetingdata.zoomOccurrenceId = null;
              meetingdata.zoomOccurrences = null;
              meetingdata.time_zone_id = timeZoneId

              this.props.saveMeeting(meetingdata);

              let delete_occurrence = data.ZoomOccurenceId !== "" ? data.ZoomOccurenceId : 0;
              await services.deleteZoomMeeting(data.zoomMeetingId, delete_occurrence, data.MeetingId);
            } else if (zoomResult.error) {
              getNotify(ERROR, zoomResult.error)
              return
            }
          })()

        }
      }, 0);
    }
  }

  onHoliDayListClick(args) {
    this.props.history.push(`/default?id=${encrypt(HOLIDAY_LIST)}&appId=${encrypt(1)}`);
  }
  onLeaveDetailsClick(args) {
    this.props.history.push(`/lna?id=${encrypt(APPLYLEAVE)}&appId=${encrypt(1)}`);
  }


  onActionComplete(args) {  // NOSONAR    
    if (args.requestType === "dateNavigate") {
      // eslint-disable-next-line
      const dt_list = this.scheduleObj.getCurrentViewDates();
      // eslint-disable-next-line
      this.props.updateMeetingBookingState({ selectedDate: dt_list[0] });
    }
    if (this.scheduleObj !== null && typeof this.scheduleObj !== 'undefined') {
      if (typeof this.scheduleObj.activeView !== "undefined") {
        let blnCheck = false;
        let dates = [];
        if (this.scheduleObj.activeView.viewClass === "e-agenda-view") {
          dates = [
            addDays(this.scheduleObj.activeView.renderDates[0], 0),
            addDays(this.scheduleObj.activeView.renderDates[0], 6),
          ];
        } else {
          dates = this.scheduleObj.activeView.renderDates;
        }
        dates.forEach((element) => {
          if (
            !(
              this.startDate.getTime() <= getCurrentDate(element).getTime() &&
              this.endDate.getTime() >= getCurrentDate(element).getTime()
            )
          ) {
            blnCheck = true;
          }
        });
        if (blnCheck) {
          this.startDate = getCurrentDate(dates[0]);
          this.endDate = getCurrentDate(dates[dates.length - 1]);
        }

        if (blnCheck) {
          const mNoStartDate = Math.floor(// NOSONAR
            new Date(this.startDate.getTime() / 1000.0) //NOSONAR
          );
          const mNoEndDate = Math.floor(// NOSONAR
            new Date(this.endDate.getTime() / 1000.0) //NOSONAR
          );
          this.funRefreshReport(mNoStartDate, mNoEndDate, 0, "");
        }
      }
    }
  }

  onEventRendered(args) {//NOSONAR

    const { PrimaryColor } = args.data;
    if (!args.element || !PrimaryColor) return;
    if (this.scheduleObj.currentView === "Agenda") {
      // eslint-disable-next-line
      args.element.firstChild.style.borderLeftColor = PrimaryColor;
    } else {
      // eslint-disable-next-line
      args.element.style.backgroundColor = PrimaryColor;
      // Calculate event duration for each date
      //NOSONAR
      let totalEvents = this.scheduleObj.eventsData;

      let dm = new DataManager({ json: totalEvents });
      let resources = this.scheduleObj.resources[0].dataSource;

      let dates = this.scheduleObj.getCurrentViewDates();
      resources.forEach(function (resource) {
        let datasource = dm.executeLocal(
          new Query().where('TaskId', 'equal', resource.id)
        );




        //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
        //This function is to calculate total hours
        const getTotalTimeInMinute = (arrayOfMeeting) => {
          //Stage 1 to create Time Table Object
          let minuteTableObject = {}
          let startTimeArray = [];
          let endTimeArray = [];
          arrayOfMeeting.forEach(element0 => {
            element0.StartTime = new Date(element0.StartTime).getTime() / 60000
            startTimeArray.push(element0.StartTime)
            element0.EndTime = new Date(element0.EndTime).getTime() / 60000;
            endTimeArray.push(element0.EndTime)
          });
          const minMinute = Math.min(...startTimeArray);
          const maxMinute = Math.max(...endTimeArray);
          for (let minuteContainer = minMinute; minuteContainer <= maxMinute; minuteContainer++) {
            minuteTableObject[minuteContainer] = 0
          }
          //Stage 2 to fill Object
          arrayOfMeeting.forEach(element1 => {
            let startTime = element1.StartTime
            let endTime = element1.EndTime
            for (let minuteContainer = startTime; minuteContainer < endTime; minuteContainer++) {
              minuteTableObject[minuteContainer] = 1
            }
          })

          //Stage 3 to add total filled Container
          let finalMinute = 0
          for (const value in minuteTableObject) {
            finalMinute = finalMinute + minuteTableObject[value]
          }
          let functionFroDifferentTime = () => {
            let hour = `${finalMinute / 60}`.split(".")[0];
            hour = hour.length > 1 ? hour : `0${hour}`
            let min = `${finalMinute % 60}`
            min = min.length > 1 ? min : `0${min}`
            return {
              timeInMinute: finalMinute,
              timeInHourAndMinute: `${hour}:${min}`
            }
          }
          return functionFroDifferentTime()
        }



        dates.forEach(date => {
          //To get the id of ui 
          let id =
            resource.id.toString() +
            '_' +
            date.getDate() +
            '_' +
            date.getMonth();


          let startDate, endDate;
          startDate = new Date(date);
          endDate = new Date(date);
          endDate = new Date(endDate.setHours(23, 59, 59));
          let predicate = new Predicate(
            'StartTime',
            'greaterthanorequal',
            startDate
          )
            .and(new Predicate('EndTime', 'greaterthanorequal', startDate))
            .and(new Predicate('StartTime', 'lessthan', endDate))
            .or(
              new Predicate('StartTime', 'lessthanorequal', startDate).and(
                new Predicate('EndTime', 'greaterthan', startDate)
              )
            );

          let datas = new DataManager({ json: datasource }).executeLocal(
            new Query().where(predicate)
          );
          //allDateAndTime to store all the meetings of a day
          const allDateAndTime = []
          datas.forEach(meeting => {
            if (
              meeting.MeetingType !== undefined && meeting.MeetingType !== "Break" && meeting.MeetingType !== "Leave - Full Day"
              && meeting.MeetingType !== "Leave - First Half" && meeting.MeetingType !== "Leave - Second Half" && meeting.MeetingType !== "Leave - Quarter Day"
              && meeting.AttendeeResponse !== "2" && meeting.MeetingTypeId !== 1068 && meeting.MeetingTypeId !== 1069 && meeting.MeetingTypeId !== 1071
            ) {
              allDateAndTime.push({
                StartTime: new Date(meeting.StartTime).toISOString(),
                EndTime: new Date(meeting.EndTime).toISOString(),
                x: (meeting.StartTime.toISOString().split("T")[0]).split("-")[2]
              })
            }
          })

          //To find the total time of all meetings in allDateAndTime
          const totalTime = allDateAndTime.length > 0 ? getTotalTimeInMinute(allDateAndTime) : null;
          document.getElementById(id).innerHTML = totalTime !== null ? totalTime.timeInHourAndMinute : '00:00';
        })

        //^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^

      });
    }




  }

  onNavigation(args) {  // NOSONAR
    if (this.scheduleObj !== null && typeof this.scheduleObj !== 'undefined') {

      if (typeof this.scheduleObj.activeView !== "undefined") {
        let blnCheck = false;
        let dates = [];
        if (args.currentView === "Agenda") {
          const dateLength = this.scheduleObj.getCurrentViewDates();
          dates = [
            addDays(this.scheduleObj.activeView.renderDates[0], 0),
            addDays(
              this.scheduleObj.activeView.renderDates[0],
              dateLength.length
            ),
          ];
        } else {
          dates = this.scheduleObj.activeView.renderDates;
        }
        dates.forEach((dt) => {
          if (
            !(
              this.startDate.getTime() <= getCurrentDate(dt).getTime() &&
              this.endDate.getTime() >= getCurrentDate(dt).getTime()
            )
          ) {
            blnCheck = true;
          }
        });
        if (blnCheck) {
          this.startDate = getCurrentDate(dates[0]);
          this.endDate = getCurrentDate(dates[dates.length - 1]);
        }

        if (blnCheck) {
          const mNoStartDate = Math.floor(// NOSONAR
            new Date(this.startDate.getTime() / 1000.0) //NOSONAR
          );
          const mNoEndDate = Math.floor(// NOSONAR
            new Date(this.endDate.getTime() / 1000.0) //NOSONAR
          );
          this.funRefreshReport(mNoStartDate, mNoEndDate, 0, "");
        }
        if (args.currentView === "Agenda") {
          const currentViewDates = this.scheduleObj.getCurrentViewDates();
          // eslint-disable-next-line
          this.scheduleObj.selectedDate = currentViewDates[0];
        }
      }
    }
  }
  getMasterDataOthersCalendar = () => {
    const { otherUser, authUser } = this.props;

    try {
      // eslint-disable-next-line
      this.props.setMeetingMaster({
        meetingType: [],
        meetingSubType: [],
        meetingServiceProduct: [],
        subCategary: [],
        campaignData: [],


      });
      localStorage.setItem("user_type", encodeString(""));
      const response = services.getMasterData(
        parseInt(otherUser, 10) !== 0 ? otherUser : authUser,
        "OC"
      );

      response.then((data) => {
        if (data != null) {

          const MT = data.filter((item) => {
            return item.Type === "MT";
          });
          const SP = data.filter((item) => {
            return item.Type === "SrvPrd";
          });
          const SBMT = data.filter((item) => {
            return item.Type === "SubType";
          });
          const subCategary = data.filter((item) => {
            return item.Type === "SubCategory";
          });
          const UserType = data.filter((item) => {
            return item.Type === "UserType";
          });
          const meetingCategory = data.filter((item) => {
            return item.Type === "MC";
          });
          const meetingSource = data.filter((item) => {
            return item.Type === "Meeting Source";
          });
          const CD = data.filter((item) => {
            return item.Type === "Campaign";

          });


          let encryptbdUserId = encrypt(UserType[0].bdUserId);
          localStorage.setItem("user_type", encodeString(UserType[0].Name));
          localStorage.setItem("bd_user_id", encryptbdUserId);
          // eslint-disable-next-line
          this.props.setMeetingMaster({
            meetingType: MT,
            meetingSubType: SBMT,
            meetingServiceProduct: SP,
            subCategary,
            meetingCategory,
            meetingSource,
            campaignData: CD,

          });
        }
      });
    } catch (error) {
      // eslint-disable-next-line
    }
  };

  onUserSelect = async (event) => {
    if (event.itemData !== null) {
      // eslint-disable-next-line
      await this.props.changeAuthUser({
        otherUser: event.itemData.Id,
        otherUserAccess: event.itemData.Optional,
      });

      if (event.itemData.Optional === "Y") {
        await this.getMasterDataOthersCalendar();  // NOSONAR
        localStorage.setItem("digitalEmailAddress", encodeString(event.itemData.DigitalEmail));
      }
      // eslint-disable-next-line
      this.props.updateMeetingBookingState({ meetingStatus: "updated" });

    } else {
      if (typeof this.scheduleObj !== "undefined") {
        const { changedData } = this.state
        this.data = extend([], null, null, true);
        this.scheduleObj.refreshEvents()
        this.setState({ changedData: !changedData })
      }
    }
  };

  footer(props) {
    return (
      <ErrorBoundary>
        <Footer
          elementType={props.elementType}
          data={props}
          addBreakMeeting={this.funcAddBreakMeeting}
          PrimaryColor={props.PrimaryColor}
          scheduleObj={this.scheduleObj}
          addMeetingQuickInfo={this.addMeetingQuickInfo}
        />
      </ErrorBoundary>
    );
  }

  // Add the Leave meeting from QuickInfo Popup
  funcAddLeaveMeeting(data, isFromMenu) { // NOSONAR
    const {
      categoryName,
      authUser,
      otherUser,
      otherUserAccess,
      meetingTitle,
      meetingTypeId,
      meetingSubTypeId
    } = this.props;
    try {
      if (document.getElementById("Subject") != null && typeof document.getElementById("Subject") !== 'undefined') {
        this.meetingTitle = document.getElementById("Subject").value
      } else {
        this.meetingTitle = meetingTitle;
      }
      let user_id = authUser;
      const meetingSetterId = authUser;
      if (
        categoryName === OTHERS_CALENDAR &&
        otherUser !== 0 &&
        otherUserAccess === "Y"
      ) {
        // eslint-disable-next-line
        user_id = otherUser;
      }
      const { workHours } = this.state;
      let selectedDateData = null;
      if (workHours.length > 0) {
        /**
         * @author Vivek Khodade.
         * @description get month function returns from 0 to 11;
         */
        // eslint-disable-next-line
        workHours.forEach((item) => {
          /**
           * @author Vivek Khodade.
           * @description get month function returns from 0 to 11;
           */
          if (new Date(`${new Date(item.calendarDate).getFullYear()}-${new Date(item.calendarDate).getMonth() + 1}-${new Date(item.calendarDate).getDate()}`).getTime() === new Date(`${new Date(data.StartTime).getFullYear()}-${new Date(data.StartTime).getMonth() + 1}-${new Date(data.StartTime).getDate()}`).getTime()) {
            selectedDateData = item;
          }
        })
      }
      let mDtMeetingStartDate = null;
      let mDtMeetingEndDate = null;
      // Full day.
      if (meetingTypeId === 998 && meetingSubTypeId === 8) {
        let stDate = data.StartTime;
        let endDate = data.EndTime;

        let stHr = selectedDateData.startTime.slice(0, 2);
        let stMiu = selectedDateData.startTime.slice(3, 5);

        let endHr = selectedDateData.endTime.slice(0, 2);
        let endMiu = selectedDateData.endTime.slice(3, 5);

        stDate = new Date(stDate).setHours(parseInt(stHr, 10));
        stDate = new Date(stDate).setMinutes(parseInt(stMiu, 10));

        endDate = new Date(endDate).setHours(parseInt(endHr, 10));
        endDate = new Date(endDate).setMinutes(parseInt(endMiu, 10));

        mDtMeetingStartDate = getDateFormat(
          new Date(stDate),
          new Date(stDate)
        );
        mDtMeetingEndDate = getDateFormat(
          new Date(endDate),
          new Date(endDate)
        );
      }
      //First Half
      else if (meetingTypeId === 998 && meetingSubTypeId === 999) {
        let stDate = data.StartTime;
        let endDate = data.EndTime;

        let stHr = selectedDateData.startTime.slice(0, 2);
        let stMiu = selectedDateData.startTime.slice(3, 5);
        stDate = new Date(stDate).setHours(parseInt(stHr, 10));
        stDate = new Date(stDate).setMinutes(parseInt(stMiu, 10));

        if (new Date(selectedDateData.calendarDate).getDay() === 6) {
          endDate = new Date(endDate).setHours(parseInt(stHr, 10) + 3);
          endDate = new Date(endDate).setMinutes(parseInt(stMiu, 10));
        } else {
          endDate = new Date(endDate).setHours(parseInt(stHr, 10) + 5);
          endDate = new Date(endDate).setMinutes(parseInt(stMiu, 10));
        }

        mDtMeetingStartDate = getDateFormat(
          new Date(stDate),
          new Date(stDate)
        );
        mDtMeetingEndDate = getDateFormat(
          new Date(endDate),
          new Date(endDate)
        );
      }
      // Second Half
      else if (meetingTypeId === 998 && meetingSubTypeId === 1000) {
        let stDate = data.StartTime;
        let endDate = data.EndTime;
        let endHr = selectedDateData.endTime.slice(0, 2);
        let endMiu = selectedDateData.endTime.slice(3, 5);

        if (new Date(selectedDateData.calendarDate).getDay() === 6) {
          stDate = new Date(stDate).setHours(parseInt(endHr, 10) - 3);
          stDate = new Date(stDate).setMinutes(parseInt(endMiu, 10));
        } else {
          stDate = new Date(stDate).setHours(parseInt(endHr, 10) - 5);
          stDate = new Date(stDate).setMinutes(parseInt(endMiu, 10));
        }

        endDate = new Date(endDate).setHours(parseInt(endHr, 10));
        endDate = new Date(endDate).setMinutes(parseInt(endMiu, 10));

        mDtMeetingStartDate = getDateFormat(
          new Date(stDate),
          new Date(stDate)
        );
        mDtMeetingEndDate = getDateFormat(
          new Date(endDate),
          new Date(endDate)
        );
      } else {
        mDtMeetingStartDate = getDateFormat(
          new Date(data.StartTime),
          new Date(data.StartTime)
        );
        mDtMeetingEndDate = getDateFormat(
          new Date(data.EndTime),
          new Date(data.EndTime)
        );
      }

      // eslint-disable-next-line
      let meeting_title = this.meetingTitle.replace(/&amp;/gi, "&");
      // eslint-disable-next-line
      meeting_title = meeting_title !== "" ? `${meeting_title}` : "Leave";
      const meetingdata = {
        user_id,
        // eslint-disable-next-line
        meeting_title,
        meeting_start_date: mDtMeetingStartDate,
        meeting_end_date: mDtMeetingEndDate,
        meetingmode: this.TaskId === 2 ? "A" : "P",
        is_full_day: data.IsAllDay === 1 || data.IsAllDay === true || data.IsFullDay === 1 || data.IsFullDay === true ? "1" : null,
        meeting_setter_id: meetingSetterId,
        time_zone_id: parseInt(dencrypt(localStorage.getItem("def_timezone")), 10),
        meeting_media_id: null,
        // eslint-disable-next-line
        meeting_type_id: meetingSubTypeId, // Leave Meeting Type
        meeting_rooms_id: "87",
        // eslint-disable-next-line
        chairperson_id: user_id,
        btb_id: null,
        sub_btb_id: null,
        sub_cat_id: null,
        business_impact: null,
        // eslint-disable-next-line
        meeting_attendee: `${user_id}_1_0$$`,
        user_type: decodeString(localStorage.getItem("user_type")),
        meeting_source_id: null,
        meeting_weblink_type: null,
        meeting_weblink: null,
        participate_code: null,
        other_attendees: null,
        meeting_agenda: "",
        meeting_actionitems: "",
        client_id: null,
        contacts_id: null,
        service_products_id: null,
        is_marketing_meeting: null,
        meeting_location: null,
        countries_id: null,
        meeting_category_id: null,
      };
      if (this.TaskId !== 2) {
        meetingdata.is_self_meeting = "N";
        meetingdata.meeting_frequency = 1;
        meetingdata.meeting_dates = null;
        meetingdata.recurring = null;
        meetingdata.meeting_setter_id = meetingSetterId;
      }

      setTimeout(() => {
        // eslint-disable-next-line
        this.props.saveMeeting(meetingdata);
      }, 0);
      if (!isFromMenu) this.scheduleObj.closeQuickInfoPopup();
    } catch (error) {
      console.log("error==>", error);
    }
  }
  // Add the break meeting from QuickInfo Popup
  funcAddBreakMeeting(data, isFromMenu) {
    const {
      categoryName,
      authUser,
      otherUser,
      otherUserAccess,
      meetingTitle,
    } = this.props;
    try {

      if (document.getElementById("Subject") != null && typeof document.getElementById("Subject") !== 'undefined') {
        this.meetingTitle = document.getElementById("Subject").value
      } else {
        this.meetingTitle = meetingTitle;
      }
      // eslint-disable-next-line
      let user_id = authUser;
      const meetingSetterId = authUser;
      if (
        categoryName === OTHERS_CALENDAR &&
        otherUser !== 0 &&
        otherUserAccess === "Y"
      ) {
        // eslint-disable-next-line
        user_id = otherUser;
      }
      const mDtMeetingStartDate = getDateFormat(
        new Date(data.StartTime),
        new Date(data.StartTime)
      );
      const mDtMeetingEndDate = getDateFormat(
        new Date(data.EndTime),
        new Date(data.EndTime)
      );
      // eslint-disable-next-line
      let meeting_title = this.meetingTitle.replace(/&amp;/gi, "&");
      // eslint-disable-next-line
      meeting_title = meeting_title !== "" ? `Break - ${meeting_title}` : "Break";
      if (meeting_title === "Break - Break") {
        meeting_title = "Break";
      }
      const meetingdata = {
        user_id,
        // eslint-disable-next-line
        meeting_title,
        meeting_start_date: mDtMeetingStartDate,
        meeting_end_date: mDtMeetingEndDate,
        meetingmode: this.TaskId === 2 ? "A" : "P",
        is_full_day: data.IsAllDay === 1 || data.IsAllDay === true || data.IsFullDay === 1 || data.IsFullDay === true ? "1" : null,
        meeting_setter_id: meetingSetterId,
        time_zone_id: parseInt(dencrypt(localStorage.getItem("def_timezone")), 10),
        meeting_media_id: null,
        // eslint-disable-next-line
        meeting_type_id: 885, // Break Meeting Type
        meeting_rooms_id: "87",
        // eslint-disable-next-line
        chairperson_id: user_id,
        btb_id: null,
        sub_btb_id: null,
        sub_cat_id: null,
        business_impact: null,
        // eslint-disable-next-line
        meeting_attendee: `${user_id}_1_0$$`,
        user_type: decodeString(localStorage.getItem("user_type")),
        meeting_source_id: null,
        meeting_weblink_type: null,
        meeting_weblink: null,
        participate_code: null,
        other_attendees: null,
        meeting_agenda: "",
        meeting_actionitems: "",
        client_id: null,
        contacts_id: null,
        service_products_id: null,
        is_marketing_meeting: null,
        meeting_location: null,
        countries_id: null,
        meeting_category_id: null,
      };
      if (this.TaskId !== 2) {
        meetingdata.is_self_meeting = "N";
        meetingdata.meeting_frequency = 1;
        meetingdata.meeting_dates = null;
        meetingdata.recurring = null;
        meetingdata.meeting_setter_id = meetingSetterId;
      }

      setTimeout(() => {
        // eslint-disable-next-line
        this.props.saveMeeting(meetingdata);
      }, 0);
      if (!isFromMenu) this.scheduleObj.closeQuickInfoPopup();
    } catch (error) { }
  }

  content(props) {
    // eslint-disable-next-line
    const { categoryName, otherUserAccess, otherUser, authUser, subCategary, getMeetingDetails, saveMeetingTemplate, meetingAcceptance, // NOSONAR
      meetingSubType,
      meetingType,
      meetingServiceProduct, templateListData
    } = this.props;
    const { seriesDateString } = this.state;

    let mDtMeetingDate;
    if (props.IsAllDay === true || props.IsAllDay === 1 || props.IsFullDay === true || props.IsFullDay === 1) {
      mDtMeetingDate = `${this.instance.formatDate(props.StartTime, {
        skeleton: "yMMMEd",
      })} (All day)`;
    } else {
      mDtMeetingDate = `${this.instance.formatDate(props.StartTime, {
        skeleton: "yMMMEd",
      })} (${this.instance.formatDate(props.StartTime, {
        skeleton: "Hm",
      })} - ${this.instance.formatDate(props.EndTime, { skeleton: "Hm" })})`;
    }
    let mSeriesString = "";
    if (props.SeriesId > 0) {
      mSeriesString = `${getDisplayDateOnly(
        new Date(props.SeriesStartDate)
      )} to ${getDisplayDateOnly(
        new Date(props.SeriesEndDate)
      )} from ${this.instance.formatDate(props.StartTime, {
        skeleton: "Hm",
      })} to ${this.instance.formatDate(props.EndTime, { skeleton: "Hm" })} ${props.SeriesString
        }`;
    }
    this.setState({
      contentData: props,
      mDtMeetingDate,
      seriesDateString: mSeriesString,
    });
    const m_user = categoryName === OTHERS_CALENDAR && otherUser !== 0 && otherUserAccess === "Y" ? otherUser : authUser;
    const cellDetails = this.scheduleObj.getCellDetails(this.scheduleObj.getSelectedElements());

    return (
      <div>
        <ErrorBoundary>
          {/* otherUser pass for others calendar and authUser pass for normal user   */}
          {props.elementType === "cell" ? (
            <AddContent
              data={props}
              meetingdate={mDtMeetingDate}
              authUser={authUser}
              setTitleFromQuickInfo={this.setTitleFromQuickInfo}
              setTemplateFromQuickInfo={this.setTemplateFromQuickInfo}
              // addComment={this.addComment}
              setOPSMeeting={this.setOPSMeeting}
              subCategary={subCategary}
              meetingSubType={meetingSubType}
              meetingType={meetingType}
              meetingServiceProduct={meetingServiceProduct}
              categoryName={categoryName}
              otherUser={otherUser}
              templateListData={templateListData}
              groupIndex={cellDetails?.groupIndex}
            />
          ) : (
            <DetailsContent
              ref={this.myRef}
              // setChildComponentMethod={this.setChildComponentMethod} 
              saveAsTemplateOnRightClick={this.saveTemplateonRightClick}

              data={props}
              meetingAcceptance={meetingAcceptance}
              seriesDateString={seriesDateString}
              saveMeetingTemplate={saveMeetingTemplate}
              authUser={m_user}
              getMeetingDetails={getMeetingDetails}
              meetingdate={mDtMeetingDate}
              categoryName={categoryName}
              subCategary={subCategary}
              readWriteAccess={otherUserAccess}
              getMeetingTemplateData={this.getMeetingTemplateData}
              handleViewMeetingpopup={this.handleViewMeetingpopup}
              scheduleObj={this.scheduleObj}
            />
          )}
        </ErrorBoundary>
      </div>
    );
  }

  addComment(meetingAgenda) {
    // eslint-disable-next-line
    this.props.updateMeetingBookingState({ meetingAgenda });
  }

  header(props) {
    return (
      <ErrorBoundary>
        <Header
          Subject={props.Subject}
          elementType={props.elementType}
          EditDeleteAccess={props.EditDeleteAccess}
          SecondaryColor={props.SecondaryColor}
          PrimaryColor={props.PrimaryColor}
          handleViewMeetingpopup={this.handleViewMeetingpopup}
          scheduleObj={this.scheduleObj}
          meetingMode={props.MeetingMode}
        />
      </ErrorBoundary>
    );
  }

  // to close the agenda pop up from details content
  closeAgendaPopup = () => {
    try {
      if (document.getElementsByClassName("e-tooltip-wrap").length > 0) {
        const elements = document.getElementsByClassName("customtooltip");
        while (elements.length > 0) {
          elements[0].parentNode.removeChild(elements[0]);
        }
      }
    } catch (error) { }
  };

  async funRefreshWorkHours() {//NOSONAR    
    const { workHours } = this.state;
    this.spinner(true)
    try {
      if (typeof this.scheduleObj.activeView !== "undefined") {
        let dates;
        // eslint-disable-next-line
        dates = await this.scheduleObj.activeView.renderDates;
        if (workHours.length === 1) {
          this.scheduleObj.resetWorkHours(dates, "00:00", "24:00", 0);
          this.scheduleObj.setWorkHours(
            dates,
            workHours[0].startTime,
            workHours[0].endTime,
            0
          );
          this.scheduleObj.resetWorkHours(dates, "00:00", "24:00", 1);
          this.scheduleObj.setWorkHours(
            dates,
            // eslint-disable-next-line
            workHours[0].startTime,
            // eslint-disable-next-line
            workHours[0].endTime,
            1
          );
        } else if (workHours.length > 1) {
          dates.forEach((element) => {
            const renderDates = getDate(new Date(element));
            this.scheduleObj.resetWorkHours(
              [new Date(element)],
              "00:00",
              "24:00",
              0
            );
            this.scheduleObj.resetWorkHours(
              [new Date(element)],
              "00:00",
              "24:00",
              1
            );
            workHours.forEach((workHour) => {
              if (workHour.calendarDate === renderDates) {
                this.scheduleObj.setWorkHours(
                  [new Date(element)],
                  workHour.startTime,
                  workHour.endTime,
                  0
                );

                this.scheduleObj.setWorkHours(
                  [new Date(element)],
                  workHour.startTime,
                  workHour.endTime,
                  1
                );
              }
            });
          });
        }
        if (
          typeof this.scheduleObj !== "undefined" &&
          !this.firstTimeRefreshed
        ) {
          this.scheduleObj.scrollTo(defTimeScrollTo);
        }
      }
      if (
        typeof this.scheduleObj !== "undefined" &&
        typeof this.userSetting.TimeScale !== "undefined"
      ) {
        this.scheduleObj.timeScale.interval = this.userSetting.TimeScale;
      }
      const schedule = document.getElementsByClassName("e-resource-cells");
      if (schedule) {
        for (let element of schedule) {
          element.innerHTML = element.innerHTML.replace("Hours", "");
        }
      }
    } catch (error) { }
    this.spinner(false)
  }

  funTimelyRefresh(pMeetingId, pMode) {
    try {
      this.scheduleObj.showQuickInfo = false;
      this.scheduleObj.showQuickInfo = true;
      const mNoStartDate = Math.floor(// NOSONAR
        new Date(this.startDate.getTime() / 1000.0) //NOSONAR
      );
      const mNoEndDate = Math.floor(new Date(this.endDate.getTime() / 1000.0));// NOSONAR
      this.funRefreshReport(mNoStartDate, mNoEndDate, pMeetingId, pMode);
    } catch (error) { }
  }

  async funRefreshReport(pStartDate, pEndDate, pMeetingId, pMode) {// NOSONAR
    const { categoryName, authUser, otherUser } = this.props;
    const { locale } = this.state;
    try {
      if (pMeetingId === 0 && !this.autoRefresh) {
        this.spinner(true)
      }
      if (pMeetingId > 0) {
        this.funSubRefreshReport(
          pStartDate,
          pEndDate,
          categoryName,
          pMeetingId,
          pMode
        );
      } else if (otherUser !== "" && otherUser !== null && otherUser !== 0) {
        this.getWorkDaysDataForOtherCalender(
          new Date(pStartDate * 1000),
          new Date(pEndDate * 1000)
        );
        if (this.userSettingData.startDate === new Date(pStartDate).getTime() &&
          this.userSettingData.userId === authUser) {
          this.funSubRefreshReport(
            pStartDate,
            pEndDate,
            categoryName,
            pMeetingId,
            pMode
          );
        } else {
          this.userSettingData.startDate = new Date(pStartDate).getTime()
          this.userSettingData.userId = authUser
          const settingDetails = services.getUserSetting(
            authUser,
            authUser,
            pStartDate
          );
          settingDetails.then((result) => {
            if (result !== null) this.userSetting = result;
            const tz = result.Def_TZnm.split(",");
            const tznm = tz.map((element) => {
              return {
                Name: element.split("_")[0],
                value: element.split("_")[1],
              };
            });
            this.userSetting.tznm = tznm;
            localStorage.setItem("def_timezone", encrypt(result.Def_TimeZone));
            localStorage.setItem("def_locale", result.Locale);
            if (result.Locale !== locale) {
              this.setState({ locale: result.Locale });
            }
            this.funSubRefreshReport(
              pStartDate,
              pEndDate,
              categoryName,
              pMeetingId,
              pMode
            );
          });
        }
      } else {
        this.getWorkDaysData(
          new Date(pStartDate * 1000),
          new Date(pEndDate * 1000)
        );

        if (this.userSettingData.startDate === new Date(pStartDate).getTime() &&
          this.userSettingData.userId === authUser) {
          this.funSubRefreshReport(
            pStartDate,
            pEndDate,
            categoryName,
            pMeetingId,
            pMode
          );
        } else {
          this.userSettingData.startDate = new Date(pStartDate).getTime()
          this.userSettingData.userId = authUser
          const settingDetails = services.getUserSetting(
            authUser,
            authUser,
            pStartDate
          );
          settingDetails.then((result) => {
            if (result !== null) this.userSetting = result;
            const tz = result.Def_TZnm.split(",");
            const tznm = tz.map((tm) => {
              return {
                Name: tm.split("_")[0],
                value: tm.split("_")[1],
              };
            });
            this.userSetting.tznm = tznm;
            localStorage.setItem("def_timezone", encrypt(result.Def_TimeZone));
            localStorage.setItem("def_locale", result.Locale);
            // eslint-disable-next-line
            if (result.Locale !== locale) {
              this.setState({ locale: result.Locale }, () => {
                if (this.updateTimeZoneChangesStatus === true) {
                  document.getElementById("planVsScheduleGrid").ej2_instances[0].timeScale.enable = false;
                  setTimeout(() => {
                    document.getElementById("planVsScheduleGrid").ej2_instances[0].timeScale.enable = true;
                    this.updateTimeZoneChangesStatus = false;
                  }, 10)
                }
              });
            }
            this.funSubRefreshReport(
              pStartDate,
              pEndDate,
              categoryName,
              pMeetingId,
              pMode
            );
          });
        }

      }
    } catch (error) { }
  }

  getUserTibData = async (stDate, endDate) => {
    if (this.lastTIBDates.startDate === stDate && this.lastTIBDates.endDate === endDate) {
      return;
    } else {
      this.lastTIBDates.startDate = stDate
      this.lastTIBDates.endDate = endDate
    }
    const { authUser, otherUser, categoryName } = this.props;
    const userTibData = await tibService.getUserTibData(
      categoryName === OTHERS_CALENDAR ? otherUser : authUser,
      stDate, endDate
    );
    this.props.addUpdateTibData({
      userTibData: userTibData
    });
  }

  async funSubRefreshReport(  // NOSONAR    
    pStartDate,
    pEndDate,
    pMeetingcategory,
    pMeetingId,
    pMode
  ) {

    const { categoryName, checkOtherUserAvailability, otherUser, authUser, } = this.props;
    const { title, open } = this.state;
    let mType;
    switch (pMeetingcategory) {
      case PLAN_VS_ACTUAL_MEETING:
        mType = "PlanVsActual";
        break;
      case OTHERS_CALENDAR:
        mType = "others";
        break;
      default:
        mType = "Query";
    }
    await this.getUserTibData(pStartDate, pEndDate);
    const resposnse = schedularservice.getMeetings(
      authUser,
      pStartDate,
      pEndDate,
      mType,
      pMeetingId,
      categoryName === OTHERS_CHECK_AVAILABILITY
        ? checkOtherUserAvailability
        : otherUser
    );
    resposnse.then((result) => {
      if (pMeetingId > 0) {
        if (this.scheduleObj.eventSettings !== null && typeof this.scheduleObj.eventSettings !== 'undefined') {
          for (let i = 0; i < this.scheduleObj.eventSettings.dataSource.length; i += 1) {
            if (
              this.scheduleObj.eventSettings.dataSource[i].MeetingId ===
              pMeetingId &&
              this.scheduleObj.eventSettings.dataSource[i].MeetingMode === pMode
            ) {
              this.scheduleObj.eventSettings.dataSource.splice(i, 1);
              // 
            }
          }
          if (result.length > 0) {
            // eslint-disable-next-line
            result[0].StartTime = getDateObj(result[0].StartTime);
            // eslint-disable-next-line
            result[0].EndTime = getDateObj(result[0].EndTime);
            this.scheduleObj.addEvent(result[0]);
          }
          this.scheduleObj.refreshEvents();
        }
      } else {
        this.data = extend([], result, null, true);
      }
      if (
        title === "Move Meeting" ||
        title === "Move Meeting To Actual" ||
        open
      ) {
        this.setState({ title: "", open: false, });
      }
      if (pMeetingId === 0) {
        if (this.scheduleObj !== null && typeof this.scheduleObj !== 'undefined') {
          this.scheduleObj.showQuickInfo = false;
          this.scheduleObj.showQuickInfo = true;
        }
        setTimeout(() => {
          this.setState({ open: false });
        }, 50);
        this.autoRefresh = false;
        setTimeout(() => {
          this.firstTimeRefreshed = true;
        }, 2000);
        /** if (document.getElementById("planVsScheduleGrid") !== null && typeof document.getElementById("planVsScheduleGrid") !== 'undefined') {
          if (document.getElementById("planVsScheduleGrid").ej2_instances !== null && typeof document.getElementById("planVsScheduleGrid").ej2_instances !== 'undefined') {
            document.getElementById("planVsScheduleGrid").ej2_instances[0].timeScale.enable = false;
            document.getElementById("planVsScheduleGrid").ej2_instances[0].timeScale.enable = true;
          }
        }*/  /** NOSONAR */
        setTimeout(() => {
          this.setState({
            changedData: !this.state.changedData
          });
        }, 10)
      }
    });

  }

  /*
   * Calculate & Update state of new dimensions
   */
  updateDimensions() {
    this.setState({ changedData: !this.state.changedData });
  }

  itemTemplate = (data) => {
    return data.Optional === "Y" ? (
      <div style={{ marginLeft: 10, marginRight: 10, zIndex: 99999 }}>
        <FontAwesomeIcon
          style={{ fontWeight: "bold", float: "left" }}
          icon={faEdit}
          className="mt-sm fa-lg"
        />
        <div className="ename"> {data.Name} </div>
      </div>
    ) : (
      <div style={{ marginLeft: 10, marginRight: 10, zIndex: 99999 }}>
        {data.Optional === "N" && (
          <FontAwesomeIcon
            style={{ fontWeight: "bold", float: "left" }}
            icon={faEye}
            className="mt-sm fa-lg"
          />
        )}
        <div className="ename"> {data.Name} </div>
      </div>
    );
  };
  getDateHeaderText(value) {
    return this.instance.formatDate(value, { skeleton: 'd' });
  }
  getDateHeaderDayText(value) {
    return this.instance.formatDate(value, { skeleton: 'E' });
  }
  getTibTime = (date) => { // NOSONAR
    const { userTibData } = this.props;
    let neWDate = getDate(date);
    let tibTime = '';
    let color = '';
    let attendanceType = '';
    if (userTibData.length > 0) {
      userTibData.forEach((item) => {

        if (TIBDateFormat(item.date) === TIBDateFormat(neWDate) && item.attendance !== "") {
          if (item.timeInBusiness === null) {
            tibTime = "00:00"
            color = item.color;
            attendanceType = "(" + item.attendanceType + ")";
          } else {
            tibTime = item.timeInBusiness.substring(0, 5) + " ";
            color = item.color;
            attendanceType = '(' + item.attendanceType + ")";
          }
        }
      })
      if (tibTime === '') {
        tibTime = "";
        color = "";
        attendanceType = "";
      }
      return { tibTime: tibTime, color: color, attendanceType: attendanceType }
    } else {
      return { tibTime: "", color: "", attendanceType: "" }
    }
  }

  dateHeaderTemplate(props) { // NOSONAR
    let data = this.getTibTime(props.date);
    let holidayName;

    if (this.props.userTibData && this.props.userTibData.length > 0) {
      this.props.userTibData.forEach(ele => {
        const holidayDate = new Date(ele.date)
        holidayDate.setHours(0, 0, 0);
        const holidayDateInHours = holidayDate.getTime();

        const calendarDate = new Date(props.date);
        calendarDate.setHours(0, 0, 0);
        const calendarDateInHours = calendarDate.getTime();

        if (ele.holidayImage !== '') {
          let holidayImage=urlConfig.lnaApiUrl+'holiday/'+ele.holidayImage;
          let holidayTextDisplay = "";

          if (window.innerHeight < 901) {
            holidayTextDisplay = ele.holidayName.substring(0, ele.holidayName.length).substr(0, 12) + `${ele.holidayName.length > 12 ? "..." : ""}`
          } else {
            holidayTextDisplay = ele.holidayName.substring(0, ele.holidayName.length).substr(0, 20) + `${ele.holidayName.length > 20 ? "..." : ""}`
          }

          if (holidayDateInHours === calendarDateInHours) {
            holidayName = (
              <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-end" }}>
               <img style={{ filter: ele.holidayType === "Fixed" ? fixedHolidayIconColor : appliedHolidayIconColor }} src={holidayImage} width='22' height='22' />
                <div title={ele.holidayName} style={{ color: '#212121', fontWeight: 500, fontSize: window.innerHeight < 901 ? ".8rem" : ".9rem" }}>{ele.holidayName ? holidayTextDisplay : ''}</div>
              </div>
            )
          }
        }
      })
    }

    return (
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <div>
          <div style={{ fontSize: '14px' }} className='e-header-date e-navigate' role="link">{this.getDateHeaderDayText(props.date)} {this.getDateHeaderText(props.date)}</div>
          <div style={{ color: '#808080' }}> {(data.tibTime && data.tibTime !== ' ') ? 'TIB - ' : ''} {` ${data.tibTime && data.tibTime !== ' ' ? data.tibTime : ''}`}<span style={{ color: data.color }}> {data.tibTime && data.tibTime !== ' ' ? data.attendanceType : ''}</span></div>
        </div>
        {holidayName ? holidayName : ''}
      </div>
    );
  }
  onRenderCell(args) {
    if (this.scheduleObj.currentView !== 'Agenda') {
      if (args.elementType === 'resourceHeader') {
        // Append div with unique id to display the total durtion
        let ele = document.createElement('span');
        ele.id = this.scheduleObj.getResourcesByIndex(
          args.groupIndex
        ).resourceData.id;
        ele.innerHTML = this.getDuration(args);
        args.element.appendChild(ele.firstChild);
      }
    }
  }
  getDuration(args) {
    let id = this.scheduleObj.getResourcesByIndex(args.groupIndex).resourceData
      .id;
    id = id + '_' + args.date.getDate() + '_' + args.date.getMonth();
    return '<span id=' + id + '></span>';
  }





















  onDataBinding(args) {

    this.spinner(true)
    if (this.scheduleObj.currentView === 'Agenda') {
      return;
    }
    if (args.result.length === 0) {
      // Reset the duration
      let resources = this.scheduleObj.resources[0].dataSource;
      let dates = this.scheduleObj.getCurrentViewDates();
      resources.forEach(function (resource) {
        dates.forEach(function (date) {
          let id =
            resource.id.toString() +
            '_' +
            date.getDate() +
            '_' +
            date.getMonth();
          document.getElementById(id).innerHTML = '00:00';
        });
      });
    }

  }
  getMeetingDate = (StartTime, SeriesString) => {
    const recObject = new RecurrenceEditor();
    const arrDates = []
    const dates = recObject.getRecurrenceDates(
      new Date(StartTime),
      SeriesString,
      null,
      defRecurringMaximumCount,
      null
    );
    for (let dt of dates) {
      arrDates.push(getDate(new Date(dt)));
    }
    return arrDates.length > 0 ? arrDates.toString() : null;
  }
  getMediaName = (Name) => {
    let IconContent;

    switch (Name) {
      case "Webex":
        IconContent = "1";
        break;
      case "Skype":
        IconContent = "2";
        break;
      case "Zoom":
        IconContent = "3";
        break;
      case "Teams":
        IconContent = "4";
        break;
      default:
        break;
    }
    return IconContent;
  };

  hideCopyForLeave = () => {
try {
  
      this.menuObj.hideItems(
        [
          "Paste",
          "PasteSeries",
          "AddRecurrence",
          "Today",
          "EditRecurrenceEvent",
          "DeleteRecurrenceEvent",
          "Delete",
          "Copy",
          "SaveTemplate"
        ],
        true
      );
      document.getElementsByClassName(
        "e-contextmenu-wrapper schedule-context-menu e-lib e-keyboard"
      )[0].style.visibility = "hidden";
  
} catch (error) {
  
}
  }

  getCopyMeetingData = async (data, selectedMenuItem) => { // NOSONAR
    const { authUser } = this.props;
    let meetingData = {};
    const meetingDetails = await services.getIndMeetingDts(
      authUser,
      data.meetingId,
      data.meetingMode,
      data.seriesId,
      false
    );

    meetingData = {
      btb_id: meetingDetails.btb_id === "" ? null : meetingDetails.btb_id,
      bulkAttendeesFlag: false,
      business_impact:
        meetingDetails.business_impact === ""
          ? null
          : meetingDetails.business_impact,
      chairperson_id: meetingDetails.chairperson_id,
      client_id:
        meetingDetails.client_id === 0 ? null : meetingDetails.client_id,
      contacts_id:
        meetingDetails.contacts_id === "" ? null : meetingDetails.contacts_id,
      countries_id:
        meetingDetails.countries_id === "" ? null : meetingDetails.countries_id,
      getExcludeSundayHoliday:
        meetingDetails.getExcludeSundayHoliday === null
          ? "1"
          : meetingDetails.getExcludeSundayHoliday,
      is_full_day:
        meetingDetails.is_full_day === "" ? null : meetingDetails.is_full_day,
      is_marketing_meeting: null,
      job_id: meetingDetails.job_id === "" ? null : meetingDetails.job_id,
      meeting_agenda: meetingDetails.meeting_agenda,
      meeting_actionitems: meetingDetails.meeting_actionitems,
      meeting_attendee: getAttendeeString(meetingDetails.meeting_attendee),
      meeting_category_id: meetingDetails.meeting_category_id,
      meeting_dates: selectedMenuItem === 'PasteSeries' ? this.getMeetingDate(data.meetingStartTime, meetingDetails.recurring) : data.meetingStartTime,
      // meeting_dates:data.meetingStartTime,
      meeting_end_date: data.meetingEndTime,
      // meeting_frequency: meetingDetails.meeting_frequency,
      meeting_location:
        meetingDetails.meeting_location === ""
          ? null
          : meetingDetails.meeting_location,
      meeting_media_id:
        meetingDetails.meeting_media_id === ""
          ? null
          : meetingDetails.meeting_media_id,
      meeting_rooms_id: meetingDetails.meeting_rooms_id,
      meeting_setter_id: authUser,
      meeting_source_id:
        meetingDetails.meeting_source_id === ""
          ? null
          : meetingDetails.meeting_source_id,
      meeting_start_date: data.meetingStartTime,
      meeting_title: meetingDetails.meeting_title,
      meeting_type_id: meetingDetails.meeting_sub_type_id,
      meeting_weblink:
        meetingDetails.meeting_weblink === ""
          ? null
          : meetingDetails.meeting_weblink,
      meeting_weblink_type:
        meetingDetails.meeting_weblink_type === ""
          ? null
          : this.getMediaName(meetingDetails.meeting_weblink_type),
      other_attendees:
        meetingDetails.other_attendees === ""
          ? null
          : meetingDetails.other_attendees,
      participate_code:
        meetingDetails.participate_code === ""
          ? null
          : meetingDetails.participate_code,
      recurring:
        meetingDetails.recurring === "" ? null : meetingDetails.recurring,
      meeting_frequency: meetingDetails.series_id === 0 ? 1 : 2,
      // series_id:meetingDetails.series_id === "" ? null : meetingDetails.series_id,
      service_products_id:
        meetingDetails.service_products_id === ""
          ? null
          : meetingDetails.service_products_id,
      sub_cat_id:
        meetingDetails.sub_cat_id === "" ? null : meetingDetails.sub_cat_id,
      time_zone_id: meetingDetails.time_zone_id,
      user_id: authUser,
      user_type: "Other",
      campaign_id: meetingDetails.campaign_id,
      campaign_name: meetingDetails.campaign_name,
      //zoom
      //  participate_code: meetingDetails.participate_code === '' ? null : meetingDetails.participate_code,
      // zoomMeetingHostEmailId: meetingDetails.zoomMeetingHostEmailId === '' ? null : meetingDetails.zoomMeetingHostEmailId,
      zoomMeetingId: meetingDetails.zoomMeetingId === '' ? null : meetingDetails.zoomMeetingId,
      // zoomOccurrenceId: meetingDetails.zoomOccurrenceId === '' ? null : meetingDetails.zoomOccurrenceId,
      // zoomOccurrences: meetingDetails.zoomOccurrences === '' ? null : meetingDetails.zoomOccurrences,

    };


    return meetingData;
  };
  async onMenuItemSelect(args) { // NOSONAR
    let selectedMenuItem = args.item.id;
    if (this.selectedTarget.classList.contains("e-appointment")) {
      this.eventObj = this.scheduleObj.getEventDetails(this.selectedTarget);
    }


    switch (selectedMenuItem) {
      case "Paste":
      case "PasteSeries":
        let selectedCells = this.scheduleObj.getSelectedElements();
        let activeCellsData = this.scheduleObj.getCellDetails(
          selectedCells.length > 0 ? selectedCells : this.selectedTarget
        );

        if (selectedMenuItem === "Paste" || selectedMenuItem === "PasteSeries") {
          let groupIndex = activeCellsData.groupIndex
          let currentDate = getDateFormat(new Date(), new Date());
          let selectedDate = getDateFormat(
            new Date(activeCellsData.startTime),
            new Date(activeCellsData.startTime)
          );

          if (this.copyMettingData !== null) {
            let data = {
              meetingMode: this.copyMettingData.MeetingMode,
              seriesId: selectedMenuItem === "Paste" ? false : this.copyMettingData.SeriesId === 0 ? false : true, // NOSONAR
              meetingId: this.copyMettingData.MeetingId,
              meetingStartTime: getDateFormat(
                new Date(activeCellsData.startTime),
                new Date(activeCellsData.startTime)
              ),
              meetingEndTime: getDateFormat(
                new Date(activeCellsData.endTime),
                new Date(activeCellsData.endTime)
              ),
            };
            const copiedData = await this.getCopyMeetingData(data, selectedMenuItem);//NOSONAR

            if (selectedDate > currentDate) {
              if (groupIndex) {
                getNotify(WARNING, YouCannotPasteMeetingInActual)
              } else {
                if ((copiedData.zoomMeetingId !== null) && copiedData.meeting_frequency === 1) {
                  let hostEmail = "0";
                  let zoomStartDateTime = copiedData.meeting_start_date;
                  let zoomEndDateTime = copiedData.meeting_end_date;
                  let zoomMeetingTimeInMin = (new Date(removeExtraSeconds(zoomEndDateTime)).getTime() - new Date(removeExtraSeconds(zoomStartDateTime)).getTime()) / 60000;
                  let zoomRecurring;
                  if (copiedData.meeting_frequency === 1) {
                    let date = getMySqlDateFormat(copiedData.meeting_start_date);
                    zoomRecurring = date.split(" ")[0];
                  } else if (copiedData.meeting_frequency === 2) {
                    zoomRecurring = copiedData.meeting_dates;
                  }

                  let type = 2;
                  let recurringObj = JSON.stringify({ "obj": '' });
                  let zoomMeetingTitle = copiedData.meeting_title;
                  let timeZoneId = this.props.defaultsetting[0].Optional;

                  if (zoomMeetingTimeInMin <= 30) {
                    hostEmail = decodeString(localStorage.getItem('digitalEmailAddress'))
                  }

                  let zoomResult = await generateZoomObject(zoomStartDateTime, zoomEndDateTime, timeZoneId, zoomMeetingTitle, type, recurringObj, hostEmail, zoomRecurring)
                  if (zoomResult.data) {
                    const { join_url, password, id, host_email } = zoomResult.data;
                    copiedData.zoomMeetingId = id.toString();
                    copiedData.participate_code = `${id}; Passcode:${password}`;
                    copiedData.zoomMeetingHostEmailId = host_email;
                    copiedData.meeting_weblink = join_url;
                    copiedData.zoomOccurrenceId = null;
                    copiedData.zoomOccurrences = null;

                    this.props.saveMeeting(copiedData);
                  } else if (zoomResult.error) {

                    getNotify(ERROR, zoomResult.error)
                    return
                  }

                } else if ((copiedData.zoomMeetingId !== null) && copiedData.meeting_frequency === 2) {
                  let hostEmail = "0";
                  let zoomStartDateTime = copiedData.meeting_start_date;
                  let zoomEndDateTime = copiedData.meeting_end_date;
                  let zoomMeetingTimeInMin = (new Date(removeExtraSeconds(zoomEndDateTime)).getTime() - new Date(removeExtraSeconds(zoomStartDateTime)).getTime()) / 60000;
                  let zoomRecurring;
                  if (copiedData.meeting_frequency === 1) {
                    let date = getMySqlDateFormat(copiedData.meeting_start_date);
                    zoomRecurring = date.split(" ")[0];
                  } else if (copiedData.meeting_frequency === 2) {
                    zoomRecurring = copiedData.meeting_dates;
                  }
                  let type = 8;
                  let recurringObj = JSON.stringify({ "obj": `${copiedData.recurring}` });
                  let zoomMeetingTitle = copiedData.meeting_title;
                  let timeZoneId = this.props.defaultsetting[0].Optional;

                  if (zoomMeetingTimeInMin <= 30) {
                    hostEmail = decodeString(localStorage.getItem('digitalEmailAddress'))
                  }
                  let zoomResult = await generateZoomObject(zoomStartDateTime, zoomEndDateTime, timeZoneId, zoomMeetingTitle, type, recurringObj, hostEmail, zoomRecurring)

                  if (zoomResult.data) {


                    let occurrencesArray = []
                    let occurrencesString = ""
                    if (zoomResult.data.occurrences) {
                      zoomResult.data.occurrences.forEach(occurrence => {
                        occurrencesArray.push(`${occurrence.start_time.split("T")[0]}_${occurrence.occurrence_id}`)
                      })
                      occurrencesString = occurrencesArray.join("|")
                    }


                    const { join_url, password, id, host_email } = zoomResult.data;
                    copiedData.zoomMeetingId = id.toString();
                    copiedData.participate_code = `${id}; Passcode:${password}`;
                    copiedData.zoomMeetingHostEmailId = host_email;
                    copiedData.meeting_weblink = join_url;
                    copiedData.zoomOccurrenceId = null;
                    copiedData.zoomOccurrences = occurrencesString;
                    this.props.saveMeeting(copiedData);
                  } else if (zoomResult.error) {

                    getNotify(ERROR, zoomResult.error)
                    return
                  }


                }

                else {
                  this.props.saveMeeting(copiedData);
                }

              }
            } else {
              getNotify(WARNING, YouCannotPasteMeetingPriortoCurrentTime);
            }
          }
        }

        break;
      case "Copy":
        if (selectedMenuItem === "Copy") {
          this.copyMettingData = this.eventObj;



        }
        break;
      case "SaveTemplate":
        this.scheduleObj.openQuickInfoPopup(this.eventObj);
        document.getElementsByClassName("e-event-popup")[0].style.display = 'none'
        this.myRef.current.saveastemplate()
        break;
      default:
    }
  }

  /**openQuickInfo = () => {
     let cellData = {
       StartTime: new Date(2022, 7, 15, 9, 0),
       EndTime: new Date(2022, 7, 15, 10, 0),
     };
     this.scheduleObj.openQuickInfoPopup(cellData);
   } */

  onContextMenuBeforeOpen(args) { //NOSONAR
    let groupIndex = (args.event.target.getAttribute('data-group-index'))
    let my_elem1 = document.getElementById('Copy');
    if (my_elem1.children.length === 1) {
      let imgicon1 = document.createElement('i');
      imgicon1.setAttribute('class', 'fa fa-copy')
      my_elem1.insertBefore(imgicon1, my_elem1.children[0])
    }
    let my_elem2 = document.getElementById('SaveTemplate');
    if (my_elem2.children.length === 1) {
      let imgicon2 = document.createElement('i');
      imgicon2.setAttribute('class', 'fa fa-save')
      my_elem2.insertBefore(imgicon2, my_elem2.children[0])
    }
    let my_elem3 = document.getElementById('Paste');
    if (my_elem3.children.length === 1) {
      let imgicon3 = document.createElement('i');
      imgicon3.setAttribute('class', 'fa fa-paste')
      my_elem3.insertBefore(imgicon3, my_elem3.children[0])
    }
    let my_elem4 = document.getElementById('PasteSeries');
    if (my_elem4.children.length === 1) {
      let imgicon4 = document.createElement('i');
      imgicon4.setAttribute('class', 'fa fa-paste')
      my_elem4.insertBefore(imgicon4, my_elem4.children[0])
    }

    document.getElementsByClassName(
      "e-contextmenu-wrapper schedule-context-menu e-lib e-keyboard"
    )[0].style.visibility = "visible";

    let newEventElement = document.querySelector(".e-new-event");
    if (newEventElement) {
      remove(newEventElement);
      removeClass(
        [document.querySelector(".e-selected-cell")],
        "e-selected-cell"
      );
    }
    let targetElement = args.event.target;

    if (closest(targetElement, ".e-contextmenu")) {
      return;
    }
    this.selectedTarget = closest(
      targetElement,
      ".e-appointment,.e-work-cells," +
      ".e-vertical-view .e-date-header-wrap .e-all-day-cells,.e-vertical-view .e-date-header-wrap .e-header-cells"
    );

    if (isNullOrUndefined(this.selectedTarget)) {
      args.cancel = true;
      return;
    }
    if (this.selectedTarget.classList.contains("e-appointment")) {
      this.eventObj = this.scheduleObj.getEventDetails(this.selectedTarget);
      if (this.eventObj.ClientId !== null && this.eventObj.MeetingMode === "P") {
        getNotify(WARNING, PasteMeetingInActualIsNotAllowed);
      }
      this.getClientMeetingCopyPasteOptions(this.eventObj)


      if (this.eventObj.MeetingType === 'Leave - Full Day' || this.eventObj.MeetingType === "Leave - First Half" || this.eventObj.MeetingType === "Leave - Second Half" || this.eventObj.MeetingType === "Leave - Quarter Day") {

        this.hideCopyForLeave()
        getNotify(WARNING, LeaveTypeMeetingNotAllowed);
      }

      if (Holidays.includes(this.eventObj.MeetingTypeId) || this.props.otherUserAccess === 'N') {
        this.hideCopyForLeave()
      }
      return;
    }
    if (this.copyMettingData !== null && groupIndex !== '1') {
      this.showPasteOptions(this.copyMettingData, this.menuObj)

    } else {
      this.menuObj.hideItems(["Paste"], true);
      this.menuObj.hideItems(["PasteSeries"], true);
      document.getElementsByClassName(
        "e-contextmenu-wrapper schedule-context-menu e-lib e-keyboard"
      )[0].style.visibility = "hidden";
    }
  }
  showPasteOptions(copyMettingData, menuObj) {
   try {
     copyMettingData.SeriesId === 0 ? menuObj.showItems(["Paste"], true) : menuObj.showItems(["PasteSeries"], true); menuObj.showItems(["Paste"], true)
     menuObj.hideItems(["Copy"], true);
     menuObj.hideItems(["SaveTemplate"], true)
   } catch (error) {
    console.log("🚀 ~ file: planvsactualschedular.js:4698 ~ PlanVsActualSchedular ~ showPasteOptions ~ error:", error)
     
   }
  }

  getClientMeetingCopyPasteOptions = (eventObj) => {
    if (eventObj.ClientId === null && eventObj.MeetingMode === "P") {
      this.menuObj.showItems(["Copy"], true);
      this.menuObj.showItems(["SaveTemplate"], true);
      this.menuObj.hideItems(
        [
          "Paste",
          "PasteSeries",
          "AddRecurrence",
          "Today",
          "EditRecurrenceEvent",
          "DeleteRecurrenceEvent",
          "Delete",
        ],
        true
      );
    } else {
      this.menuObj.hideItems(
        [
          "Paste",
          "PasteSeries",
          "AddRecurrence",
          "Today",
          "EditRecurrenceEvent",
          "DeleteRecurrenceEvent",
          "Delete",
          "Copy",
          "SaveTemplate"
        ],
        true
      );
      document.getElementsByClassName(
        "e-contextmenu-wrapper schedule-context-menu e-lib e-keyboard"
      )[0].style.visibility = "hidden";
    }
  }

  /**   saveAsPopUpOpen() {
      this.myRef.current.saveastemplate()
    }*/
  spinner(spin) {
    try {
      if (document.getElementById('scheduleControlSection')) {
        if (spin)
          showSpinner(document.getElementById('scheduleControlSection'));
        else
          setTimeout(() => {
            hideSpinner(document.getElementById('scheduleControlSection'));
          }, 2000)
      }
    } catch (error) {
    }
  }


  render() {
    const schedularHeight =
      (window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight) - 70;
    const {
      contentData,
      mDtMeetingDate,
      seriesDateString,
      locale,
      button2Caption,
      button1Caption,
      message,
      open,
    } = this.state;
    const {
      showMeetingPopup,
      meetingAcceptance, // NOSONAR
      saveMeetingTemplate, // NOSONAR
      authUser,
      getMeetingDetails, // NOSONAR
      categoryName,
      subCategary,
      otherUserAccess,
      executiveAssistant,
      otherUser,
      meetingPopOpen,
      getNotifyFlag,
    } = this.props;
    return (
      <>
        <ErrorBoundary>
          {/* eslint-disable-next-line */}
          <div
            className="schedule-control-section" id="scheduleControlSection"
          >
            <div className="col-lg-12 control-section p-none">
              <div className="control-wrapper ">
                {showMeetingPopup === true && (
                  <ViewMeetingPopup
                    data={contentData}
                    meetingdate={mDtMeetingDate}
                    seriesDateString={seriesDateString}
                    meetingAcceptance={meetingAcceptance}
                    saveMeetingTemplate={saveMeetingTemplate}
                    authUser={authUser}
                    getMeetingDetails={getMeetingDetails}
                    categoryName={categoryName}
                    subCategary={subCategary}
                    readWriteAccess={otherUserAccess}
                  />
                )}
                {categoryName === OTHERS_CALENDAR &&
                  executiveAssistant !== undefined &&
                  executiveAssistant !== null && (
                    <div
                      style={{
                        position: "absolute",
                        zIndex: 99999,
                        marginLeft: "20%",
                        marginTop: 7,
                      }}
                    >
                      <ComboBoxComponent
                        className="custom-dropdown"
                        style={{ marginTop: 0, fontSize: 12 }}
                        ref={(combobox) => {
                          this.listtz = combobox;
                        }}
                        dataSource={executiveAssistant}
                        fields={{ text: "Name", value: "Id" }}
                        select={this.onUserSelect}
                        value={
                          otherUser === 0
                            ? ""
                            : // eslint-disable-next-line
                            parseInt(otherUser, 10)}
                        placeholder="View as"
                        popupHeight="220px"
                        cssClass="e-outline"
                        floatLabelType="Auto"
                        itemTemplate={this.itemTemplate}
                      />
                    </div>
                  )}

                <ScheduleComponent
                  firstDayOfWeek={1}
                  width="100%"
                  height={schedularHeight}
                  // height={784}
                  ref={(schedule) => {
                    this.scheduleObj = schedule;
                    return this.scheduleObj;
                  }}
                  navigating={this.onNavigation}
                  agendaDaysCount={5}
                  eventSettings={{
                    dataSource: this.data,
                    fields: this.fieldSettings,
                  }}
                  group={{ byDate: true, resources: ["meetings"] }}
                  renderCell={this.onRenderCell.bind(this)}
                  dataBinding={this.onDataBinding}
                  eventClick={this.onEventClick}
                  actionBegin={this.onActionBegin}
                  actionComplete={this.onActionComplete}
                  quickInfoTemplates={{
                    header: this.header,
                    content: this.content,
                    footer: this.footer,
                  }}
                  eventRendered={this.onEventRendered}
                  popupOpen={this.onPopupOpen}
                  hideEmptyAgendaDays={false}
                  dragStart={this.onDragStart}
                  // dragStop = {this.dragStop}
                  workDays={this.workDays}
                  currentView="WorkWeek"
                  dataBound={this.onDataBound}
                  timezone={locale}
                  showTimeIndicator
                  delayUpdate="true"
                  timeScale={{
                    enable: true,
                    majorSlotTemplate: this.majorSlotTemplate,
                    // minorSlotTemplate: this.minorSlotTemplate,
                  }}
                  created={this.onCreated}
                  id="planVsScheduleGrid"
                  showWeekNumber={true}
                  dateHeaderTemplate={this.dateHeaderTemplate.bind(this)}
                  // allowKeyboardInteraction={false}
                  selectedDate={this.props.selected_date}
                  weekRule="FirstFourDayWeek"




                >
                  <ResourcesDirective>
                    <ResourceDirective
                      field="TaskId"
                      title="Assignee"
                      name="meetings"
                      allowMultiple
                      dataSource={this.resourceData}
                      textField="text"
                      idField="id"
                      colorField="color"
                    />
                  </ResourcesDirective>
                  <ViewsDirective>
                    <ViewDirective
                      option="Day"
                      eventTemplate={this.eventTemplate}
                    />
                    <ViewDirective
                      option="Week"
                      eventTemplate={this.eventTemplate}
                    />
                    <ViewDirective
                      option="WorkWeek"
                      eventTemplate={this.eventTemplate}
                    />
                    <ViewDirective
                      option="Agenda"
                      eventTemplate={this.eventTemplate}
                      allowVirtualScrolling={false}
                    />
                  </ViewsDirective>
                  <Inject services={[Day, Week, WorkWeek, Agenda, Resize]} />
                </ScheduleComponent>




              </div>
            </div>
            <ContextMenuComponent
              cssClass="schedule-context-menu"
              ref={(menu) => (this.menuObj = menu)}
              target=".e-schedule"
              items={this.menuItems}
              beforeOpen={this.onContextMenuBeforeOpen.bind(this)}
              select={this.onMenuItemSelect.bind(this)}
            />
          </div>

          <Button onClick={this.handleClose} style={{ cursor: "none" }}>
            .
          </Button>

          {meetingPopOpen && <MeetingBooking categoryName={this.props.categoryName} />}
          <ConfirmationPopup
            showButton={{ type: "1" }}
            open={open}
            button1function={() => this.handleConfirmYes()}
            button2function={() => this.handleConfirmNo()}
            headerMessage={message}
            button1Text={button1Caption}
            button2Text={button2Caption}
            handleConfirmClose={this.handleConfirmClose}
          />

          {!meetingPopOpen && getNotifyFlag === false && (
            <NotificationContainer />
          )}
        </ErrorBoundary>
      </>
    );
  }
}
PlanVsActualSchedular.propTypes = {
  authUser: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  meetingPopOpen: PropTypes.bool,
  // categoryName: PropTypes.string,
  showMeetingPopup: PropTypes.bool,
  otherUser: PropTypes.number,
  otherUserAccess: PropTypes.string,
  meetingTitle: PropTypes.string,
  subCatId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  meetingAgenda: PropTypes.string,
  meetingActionItems: PropTypes.string,
  subBTBId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  businessImpact: PropTypes.string,
  btbId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  subCategary: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(Object),
  ]),
  executiveAssistant: PropTypes.arrayOf(Object),
  getNotifyFlag: PropTypes.bool,
  workTypeId: PropTypes.number,
};
PlanVsActualSchedular.defaultProps = {
  authUser: "",
  meetingPopOpen: false,
  // categoryName: "",
  showMeetingPopup: false,
  otherUser: 0,
  subCatId: "",
  meetingAgenda: "",
  meetingActionItems: "",
  subBTBId: "",
  businessImpact: "",
  btbId: "",
  subCategary: "",
  executiveAssistant: null,
  getNotifyFlag: false,
  otherUserAccess: "",
  meetingTitle: "",
  workTypeId: 0,
};
const mapStateToProps = ({
  auth,
  meetingbooking,
  meetingcategory,
  meetingmaster,
  tibReducer,
  usersettingreducer
}) => {
  const { defaultsetting } = usersettingreducer;
  const { authUser, otherUser, otherUserAccess } = auth;
  const { userTibData } = tibReducer;
  const {
    meetingPopOpen,
    meetingId,
    workTypeId,
    meetingmode,
    meetingEditAccess,
    selectedDate,
    showMeetingPopup,
    // agendaDocument,
    getNotifyFlag,
    meetingTypeName,
    serviceProductsId,
    meetingSubTypeCategory,
    updateTimeZoneChangesStatus,
    campaignId,
    meetingSourceType,
    campaignName,
    meetingDate,
    templateId,
    meetingTypeId,
    meetingSubTypeId,
    isFullDay,
    meetingTitle,
    meetingAgenda,
    meetingActionItems,
    meetingAttendee,
    meetingRoomsId,
    chairPersonId,
    bulkAttendeesFlag,
    meetingFrequency,
    meetingStatus,
    btbId,
    clientId,
    meetingCategoryId,
    meetingMediaId,
    meetingSetterId,
    meetingSourceId,
    timeZoneId,
    subBTBId,
    subCatId,
    businessImpact,
    otherAttendees,
    seriesId,
    outlookActionType,
    templateListData
  } = meetingbooking;
  const {
    executiveAssistant,
    subCategary,
    meetingType,
    meetingSubType,
    meetingServiceProduct,
    campaignData
  } = meetingmaster;

  return {
    authUser,
    meetingSourceType,
    campaignId,
    campaignName,
    meetingPopOpen,
    meetingStatus,
    meetingId,
    seriesId,
    subBTBId,
    btbId,
    subCatId,
    workTypeId,
    businessImpact,
    meetingAgenda,
    meetingActionItems,
    meetingmode,
    otherUser,
    otherUserAccess,
    meetingEditAccess,
    meetingTitle,
    executiveAssistant,
    subCategary,
    selectedDate,
    showMeetingPopup,
    // agendaDocument,
    getNotifyFlag,
    meetingType,
    meetingSubType,
    meetingServiceProduct,
    meetingTypeId,
    meetingSubTypeId,
    meetingSubTypeCategory,
    meetingTypeName,
    serviceProductsId,
    updateTimeZoneChangesStatus,
    campaignData,
    userTibData,
    meetingmaster,
    timeZoneId,
    meetingDate,
    defaultsetting,
    templateId,
    isFullDay,
    meetingAttendee,
    meetingRoomsId,
    chairPersonId,
    bulkAttendeesFlag,
    meetingFrequency,
    clientId,
    meetingCategoryId,
    meetingMediaId,
    meetingSetterId,
    meetingSourceId,
    otherAttendees,
    outlookActionType,
    templateListData
  };
};
PlanVsActualSchedular.propTypes = {
  authUser: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  meetingPopOpen: PropTypes.bool,
  campaignId: PropTypes.string,
  campaignName: PropTypes.string,
  meetingSourceType: PropTypes.string,
  showMeetingPopup: PropTypes.bool,
  otherUser: PropTypes.number,
  otherUserAccess: PropTypes.string,
  meetingTitle: PropTypes.string,
  subCatId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  meetingAgenda: PropTypes.string,
  meetingActionItems: PropTypes.string,
  subBTBId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  businessImpact: PropTypes.string,
  btbId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  subCategary: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(Object),
  ]),
  checkOtherUserAvailability: PropTypes.string,
  executiveAssistant: PropTypes.arrayOf(Object),
  getNotifyFlag: PropTypes.bool,
  workTypeId: PropTypes.number,
  meetingTypeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  meetingType: PropTypes.arrayOf(Object),
  meetingSubType: PropTypes.arrayOf(Object),
  meetingSubTypeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  serviceProductsId: PropTypes.arrayOf(PropTypes.number),
  meetingTypeName: PropTypes.string,
  meetingServiceProduct: PropTypes.arrayOf(Object),
  meetingSubTypeCategory: PropTypes.string
};
PlanVsActualSchedular.defaultProps = {
  authUser: "",
  campaignId: '',
  campaignName: '',
  meetingSourceType: '',
  meetingPopOpen: false,
  showMeetingPopup: false,
  otherUser: 0,
  subCatId: "",
  meetingAgenda: "",
  meetingActionItems: "",
  subBTBId: "",
  businessImpact: "",
  btbId: "",
  subCategary: "",
  checkOtherUserAvailability: "",
  executiveAssistant: null,
  getNotifyFlag: false,
  otherUserAccess: "",
  meetingTitle: "",
  workTypeId: 0,
  meetingTypeId: 0,
  meetingType: [],
  meetingSubType: [],
  serviceProductsId: [],
  meetingTypeName: "",
  meetingSubTypeId: "",
  meetingSubTypeCategory: "",
  meetingServiceProduct: [],
};

export default connect(mapStateToProps, {
  updateMeetingBookingState,
  createMeeting,
  getMeetingDetails,
  meetingAcceptance,
  saveMeeting,
  cancelMeeting,
  changeAuthUser,
  setMeetingMaster,
  saveMeetingTemplate,
  addUpdateTibData
})(PlanVsActualSchedular);
