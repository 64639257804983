import React from "react";
import { connect } from "react-redux";
import "../../assets/style/index.css";
import {
  TimelineViews,
  TimelineMonth,
  Agenda,
  ScheduleComponent,
  ViewsDirective,
  ViewDirective,
  ResourcesDirective,
  ResourceDirective,
  Inject,
  Resize,
  DragAndDrop,
} from "@syncfusion/ej2-react-schedule";
import { Internationalization } from "@syncfusion/ej2-base";
import { ButtonComponent } from "@syncfusion/ej2-react-buttons";
import {
  DialogContent,
  DialogActions,
  DialogTitle,
} from "../customdialog/customdialog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquare } from "@fortawesome/free-solid-svg-icons";
import { withStyles } from "@material-ui/core/styles";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import {
  AutoCompleteComponent,
  MultiSelectComponent,
  CheckBoxSelection,
} from "@syncfusion/ej2-react-dropdowns";

import PropTypes from "prop-types";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faUserCrown,
  faStar,
  faUserClock,
  faUserShield,
  faUserPlus,
  faSitemap,
  faUserCircle,
  faTrashAlt,
  faMapMarkerAlt,
  faMeteor,
} from "@fortawesome/pro-light-svg-icons";
import { Button, Dialog } from "@material-ui/core";
import {
  defworkDays,
  defWorkHourStart,
  defWorkHourEnd,
  defMeetingRoomLimit,
  defaultMeetingRoom,
} from "../../config/config";
import { updateMeetingBookingState } from "../../redux/actions/meetingbookingaction";
import dataService from "../../services/services";
import DigitalMedia from "../digitalmedia/digitalmedia";
import { getNotify, dencrypt } from "../../app/other/commonfunction";
import { SelfBreakLeave, WARNING,CHAIR_PERSON_CHANGE_MESSAGE } from "../../assets/constants/constants";
import { OTHERS_CALENDAR, OTHERS_CHECK_AVAILABILITY } from "../../redux/constant/meetingcategoryconstant";
import ProgressBar from "../progressbar/progressbar";
import BulkAttendeeBox from './bulkattendeebox';

import PartialMeetingAttendeeTimer from './partialmeetingattendeetimer';
import ErrorBoundary from "../error/errorboundary";

library.add(
  faUserCrown,
  faStar,
  faUserClock,
  faUserShield,
  faUserPlus,
  faSitemap,
  faUserCircle,
  faTrashAlt,
  faMapMarkerAlt,
  faMeteor
);
const useStyles = (theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
});
class MeetingRoomAttendee extends React.Component {
  // eslint-disable-next-line
  constructor(props) {
    super(props);
    this.workDays = defworkDays;
    this.defWorkHourStart = defWorkHourStart;
    this.defWorkHourEnd = defWorkHourEnd;
    this.AutoCompleteComponentEnableFlag = true;
    this.state = {
      changedData: false,
      openBulkAttendeePopup: false,
      bulkAttendeeLoader: false,
      invalidAttendees: [],
      validAttendees: [],
      disableAddBulk: true,
      bulkAtteendeList: [],
      timeScalePopUp: false,
      // confirmTimeScale: false,
      rUserId: null,
      uId: '',
      userIdPopup: [],
      refresh: 1
    };
    this.roomOptions = [];
    this.roomOptionsSelected = [];
    this.attendeeOptionsSelected = [];
    this.roomIdsSelected = [];
    this.categoryRoomData = [];
    this.categoryData = [];

    this.addendeeTag = [];
    this.chairPersonId = 0;
    this.schedulerSelectedDate = new Date();
    this.counter = 0;
    this.errorEnter3charactor = false;

    this.data = [];
    this.uData = [];
    this.rData = [];
    this.categoryAttendeeData = [];
    this.textareaObj = null;
    this.projectData = [
      { text: "Attendee", id: 1, color: "#80bfff" },
      { text: "Meeting Room", id: 2, color: "#80bfff" },
    ];

    this.finalData = [];
    this.instance = new Internationalization();
    this.getSelectedAttendee = this.getSelectedAttendee.bind(this);
    this.getSelectedRoom = this.getSelectedRoom.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.noRecordsTemplate = this.noRecordsTemplate.bind(this);
    this.resourceHeaderTemplate = this.resourceHeaderTemplate.bind(this);
    this.template = this.template.bind(this);
    this.eventTemplate = this.eventTemplate.bind(this);
    this.majorSlotTemplate = this.majorSlotTemplate.bind(this);
    this.onPopupOpen = this.onPopupOpen.bind(this);
    this.onDataBound = this.onDataBound.bind(this);
    this.getSelectedAttendeeFilter = this.getSelectedAttendeeFilter.bind(this);
    this.handleFilteringByName = this.handleFilteringByName.bind(this);
    this.removeItem = this.removeItem.bind(this);
    this.handleChangeBulkAttendee = this.handleChangeBulkAttendee.bind(this);
    this.handleAddBulkAttendee = this.handleAddBulkAttendee.bind(this);
    this.meetingTypeId = 0;
    this.attendeeStatus = [];
    this.bulkAttendeeLoaderCount = 0;
    this.setPartialTime = this.setPartialTime.bind(this);
    this.closeTimerPopUp = this.closeTimerPopUp.bind(this);
    this.closeButton = this.closeButton.bind(this);
    this.getAttendeeStatus = this.getAttendeeStatus.bind(this);
  }

  componentDidMount() { // NOSONAR
    this.refreshComponent(false)
  }

  refreshComponent(refreshStatus) { // NOSONAR
    try {
      const { checkOtherUserAvailability, othersCheckAvailablity, meetingTypeId, meetingDate, meetingId, categoryName, otherUser,
        otherUserAccess, authUser, meetingStartTime, meetingEndTime, meetingRoomsId,chairPersonId } = this.props;
      const userSelected = checkOtherUserAvailability.split(",");
      const finaluserSelected = userSelected.map((element) => { return { UserId: element.split("_")[0] }; });
      finaluserSelected.map((item) => {
        othersCheckAvailablity.filter((data) => {
          if (parseInt(item.UserId, 10) === data.UserId) {
            this.finalData.push(data);
          }
          return this.finalData;
        });
        return this.finalData;
      });
      if(chairPersonId > 0){
        const selectedDefault = this.props.othersCheckAvailablity.filter((item) => {
          // eslint-disable-next-line
          return item.UserId === parseInt(chairPersonId, 10);
        });
        this.props.updateMeetingBookingState({domainName:selectedDefault[0].EmailAddress.split('@')[1]})
      }
      if (categoryName === OTHERS_CHECK_AVAILABILITY && this.finalData.length > 0) {
        this.finalData.forEach((itemData) => {
          let e = {}
          e.itemData = itemData;
          setTimeout(() => {
            this.getSelectedAttendee(e);
          }, 200)
        })
      }

      if (SelfBreakLeave.includes(meetingTypeId)) {
        this.AutoCompleteComponentEnableFlag = false;
      } else {
        this.AutoCompleteComponentEnableFlag = true;
      }

      let prevCategoryData;
      const MdtMeetingDate = this.getDateOnly(meetingDate).replace(/ /gi, "-");
      const MdtMeetingStartTime = this.getTimeOnly(meetingStartTime, "start");
      const MdtMeetingEndTime = this.getTimeOnly(meetingEndTime, "start");
      const meetingStart = `${MdtMeetingDate} ${MdtMeetingStartTime}`;
      const meetingEnd = `${MdtMeetingDate} ${MdtMeetingEndTime}`;

      if ((meetingId !== 0 && typeof meetingId !== "undefined" && meetingId != null) || (refreshStatus)) {
        prevCategoryData = this.getPreviousAttendees(this.props, refreshStatus);
      }

      // eslint-disable-next-line    
      const roomCategoryData = [];
      const arrMrSelected = [];
      const previousRoomData = {};
      const roomOptionsSelected = [];

      // eslint-disable-next-line
      const roomAvailability = dataService.getRoomAvailability(
        categoryName === OTHERS_CALENDAR && otherUser !== 0 && otherUserAccess === "Y" ? otherUser : authUser,
        meetingStart, meetingEnd, meetingId, meetingRoomsId.toString()
      );
      if (roomAvailability !== null) {
        roomAvailability.then(result => {
          // eslint-disable-next-line
          result.forEach(eachRoomMeet => {
            eachRoomMeet.roomName = eachRoomMeet.var_list_value;
            eachRoomMeet.roomLocation = eachRoomMeet.Category;
            // eslint-disable-next-line
            eachRoomMeet.TaskId = eachRoomMeet.int_list_uid;
            // eslint-disable-next-line
            eachRoomMeet.ProjectId = 2;
            // eslint-disable-next-line
            eachRoomMeet.Subject = " ";
            // eslint-disable-next-line
            eachRoomMeet.StartTime = eachRoomMeet.stDate;
            // eslint-disable-next-line
            eachRoomMeet.EndTime = eachRoomMeet.endDate;
            roomCategoryData.push(eachRoomMeet);
          });
          roomCategoryData.push({
            roomName: "Self Desk", roomLocation: "Self", TaskId: 87, ProjectId: 2, Subject: " ", StartTime: null, EndTime: null,
          });
          let roomName = '';
          let roomLocation = '';
          for (const roomId of meetingRoomsId) {
            const rmDts = roomCategoryData.filter(x => { return x.TaskId === roomId })
            if (rmDts.length > 0) {
              roomName = rmDts[0].roomName; roomLocation = rmDts[0].roomLocation;
            }
            else {
              roomName = ''; roomLocation = '';
            }
            const item = {};
            // eslint-disable-next-lines
            item.groupId = 2;
            // eslint-disable-next-line
            item.name = roomId === 87 ? "Self Desk" : roomName;
            // eslint-disable-next-line
            item.id = roomId;
            // eslint-disable-next-line
            item.location = roomId === 87 ? "Self" : roomLocation;
            // eslint-disable-next-line
            arrMrSelected.push(item);
            if (!roomOptionsSelected.includes(roomId))
              roomOptionsSelected.push(roomId);
          }
          const m_meetingRoomsId = [];
          arrMrSelected.forEach((eachRoom) => {
            m_meetingRoomsId.push(eachRoom.id);
          });
          // eslint-disable-next-line
          previousRoomData.meetingRoomsId = m_meetingRoomsId;
          // eslint-disable-next-line
          previousRoomData.roomCategoryData = roomCategoryData;
          // eslint-disable-next-line
          previousRoomData.arrMrSelected = arrMrSelected;
          // eslint-disable-next-line
          previousRoomData.roomIdsSelected = arrMrSelected;
          // eslint-disable-next-line
          previousRoomData.roomOptionsSelected = roomOptionsSelected;
          // return previousRoomData;
          const responseMr = dataService.getRoomAvailability(
            categoryName === OTHERS_CALENDAR && otherUser !== 0 && otherUserAccess === "Y" ? otherUser : authUser,
            meetingStart, meetingEnd, "0", null
          );
          if (responseMr != null) {
            const mrDataFinal = [];
            responseMr.then((resultMeetingRoom) => {
              if (resultMeetingRoom !== null) {
                resultMeetingRoom.forEach((eachRec) => {
                  const newRec = {};
                  if (eachRec.stDate === null) {
                    // eslint-disable-next-line
                    newRec.Id = eachRec.int_list_uid;
                    // eslint-disable-next-line
                    newRec.Room = eachRec.var_list_value;
                    // eslint-disable-next-line
                    newRec.Category = eachRec.Category;
                    mrDataFinal.push(newRec);
                  }
                });

                this.roomOptions = mrDataFinal;
                this.schedulerSelectedDate = meetingDate;
                if ((meetingId !== 0 && typeof meetingId !== "undefined" && meetingId != null) || (refreshStatus)) {
                  this.addendeeTag = prevCategoryData.attendeetag;
                  this.chairPersonId = prevCategoryData.chairPersonId;
                  this.categoryAttendeeData = prevCategoryData.categoryData;
                  this.uData = prevCategoryData.data;
                  this.rData = previousRoomData.roomCategoryData;
                  this.roomIdsSelected = previousRoomData.roomIdsSelected;
                  if (typeof this.roomIdsSelected !== 'undefined') {
                    this.roomIdsSelected.forEach((thisRoom) => {
                      const rmObj = this.roomOptions.find((rm) => rm.Id === thisRoom.id);
                      if (!rmObj) {
                        const addRoomOpt = {};
                        addRoomOpt.Id = thisRoom.id;
                        addRoomOpt.Room = thisRoom.name;
                        addRoomOpt.Category = thisRoom.location;
                        this.roomOptions.push(addRoomOpt);
                      }
                    });
                  }
                  this.roomOptions.sort((a, b) => (a.Id > b.Id ? 1 : -1));
                  this.roomOptionsSelected = previousRoomData.roomOptionsSelected;
                  this.categoryData = [
                    ...prevCategoryData.categoryData,
                    ...previousRoomData.arrMrSelected,
                  ];
                  this.data = [...prevCategoryData.data, ...this.rData];
                } else {
                  this.handleAddMe();
                }
                // eslint-disable-next-line
                this.setState({ changedData: !this.state.changedData });
              }
            })
          }

        })
      }
    } catch (error) {

    }
  }

  // eslint-disable-next-line
  async UNSAFE_componentWillReceiveProps(newProps) { // NOSONAR
    const {
      meetingDate,
      meetingStartTime,
      meetingEndTime,
      timeZoneId,
      categoryName,
      otherUser,
      otherUserAccess,
      authUser,
      meetingTypeId
    } = this.props;

    if (newProps.meetingPopOpen) {
      if ((newProps.templateId !== this.props.templateId) && this.props.templateId > 0) {
        setTimeout(() => {
          this.refreshComponent(true)
        }, 100)
      } else {
        setTimeout(async () => { // NOSONAR      
          this.meetingTypeId = newProps.meetingTypeId;
          let currentMeetingDate = meetingDate;
          let currentMeetingStartDate = meetingStartTime;
          let currentMeetingEndDate = meetingEndTime;
          const currentTimeZone = timeZoneId;
          let newMeetingDate = newProps.meetingDate;
          let newMeetingStartDate = newProps.meetingStartTime;
          let newMeetingEndDate = newProps.meetingEndTime;
          const newTimeZone = newProps.timeZoneId;
          if (newProps.meetingRoomsId !== null && newProps.meetingRoomsId.length > 0) {
            this.roomOptionsSelected = newProps.meetingRoomsId;
            // eslint-disable-next-line
            this.setState({ changedData: !this.state.changedData });
          } else {
            setTimeout(() => {
              this.props.updateMeetingBookingState({ meetingRoomsId: defaultMeetingRoom })
            }, 1000)
            this.roomOptionsSelected = defaultMeetingRoom;
            // eslint-disable-next-line
            this.setState({ changedData: !this.state.changedData });
          }

          if (typeof currentMeetingDate === "string")
            currentMeetingDate = new Date(currentMeetingDate);
          if (typeof currentMeetingStartDate === "string")
            currentMeetingStartDate = new Date(currentMeetingStartDate);
          if (typeof currentMeetingEndDate === "string")
            currentMeetingEndDate = new Date(currentMeetingEndDate);

          if (typeof newMeetingDate === "string")
            newMeetingDate = new Date(newMeetingDate);
          if (typeof newMeetingStartDate === "string")
            newMeetingStartDate = new Date(newMeetingStartDate);
          if (typeof newMeetingEndDate === "string")
            newMeetingEndDate = new Date(newMeetingEndDate);
          if (
            currentMeetingDate.getTime() !== newMeetingDate.getTime() ||
            currentMeetingStartDate.getTime() !== newMeetingStartDate.getTime() ||
            currentMeetingEndDate.getTime() !== newMeetingEndDate.getTime() ||
            currentTimeZone !== newTimeZone || newProps.templateId !== this.props.templateId
          ) {
            const prevCategoryDataArr = await this.getPreviousAttendees(newProps, newProps.templateId !== this.props.templateId); // NOSONAR

            const prevCategoryData = prevCategoryDataArr.data;
            const categoryDataWithoutRoomData = await this.removePreviousRooms(); // NOSONAR
            const mDtMeetingDate = this.getDateOnly(newMeetingDate).replace(
              / /gi,
              "-"
            );
            const mDtMeetingStartTime = this.getTimeOnly(
              newMeetingStartDate,
              "start"
            );
            const mDtMeetingEndTime = this.getTimeOnly(newMeetingEndDate, "start");
            const meetingStart = `${mDtMeetingDate} ${mDtMeetingStartTime}`;
            const meetingEnd = `${mDtMeetingDate} ${mDtMeetingEndTime}`;

            const responseMr = dataService.getRoomAvailability(
              categoryName === OTHERS_CALENDAR &&
                otherUser !== 0 &&
                otherUserAccess === "Y"
                ? otherUser
                : authUser,
              meetingStart,
              meetingEnd,
              "0",
              null
            );
            responseMr.then((mrdata) => {
              if (mrdata != null) {
                const mrDataFinal = [];
                mrdata.forEach((eachRec) => { // NOSONAR
                  const newRec = {};
                  if (eachRec.stDate === null) {
                    newRec.Id = eachRec.int_list_uid;
                    newRec.Room = eachRec.var_list_value;
                    newRec.Category = eachRec.Category;
                    mrDataFinal.push(newRec);
                  }
                });
                this.categoryData = categoryDataWithoutRoomData;
                this.uData = [...prevCategoryData];
                this.data = this.uData.filter(
                  (elem, index, self) =>
                    self.findIndex((t) => {
                      return (
                        t.Attendee === elem.Attendee &&
                        t.StartTime === elem.StartTime &&
                        t.EndTime === elem.EndTime
                      );
                    }) === index
                );
                this.schedulerSelectedDate = new Date(mDtMeetingDate);
                this.roomOptions = mrDataFinal;
                this.rData = [];
                this.roomIdsSelected = [];

                this.roomOptionsSelected = [];
              }
              const { meetingStatus } = newProps;
              if (meetingStatus === "Self") {
                this.handleAddMe();
                // eslint-disable-next-line
                this.props.updateMeetingBookingState({
                  meetingStatus: "",
                });
              }
              this.updateFlag(newProps.meetingTypeId, newProps.subCatId)
            });
          } else if (newProps.meetingTypeId !== meetingTypeId) {
            this.updateFlag(newProps.meetingTypeId, newProps.subCatId)
          }
          if (this.scheduleObjInt) {
            this.scheduleObjInt.refreshTemplates();
          }
        }, 100)
      }
    }
  }

  updateFlag(meetingTypeId, subCatId) {
    if (SelfBreakLeave.includes(meetingTypeId)) {
      this.AutoCompleteComponentEnableFlag = false;
      this.meetingTypeId = meetingTypeId;
    } else {
      this.AutoCompleteComponentEnableFlag = true;
      this.meetingTypeId = 0;
    }
    this.setState({ changedData: !this.state.changedData });
  }

  shouldComponentUpdate(nextProps, nextState) {
    return this.state.changedData !== nextState.changedData;
  }

  onDataBound() {
    const { meetingStartTime } = this.props;
    this.searchAttendee.value = null;
    const thisDateTime = new Date(meetingStartTime.getTime());
    if (thisDateTime.getHours() < 1) {
      thisDateTime.setHours(thisDateTime.getHours());
    } else {
      thisDateTime.setHours(thisDateTime.getHours() - 1);
    }

    const mDtMeetingStartTime = this.getTimeOnly(thisDateTime, "start");
    this.scheduleObjInt.scrollTo(mDtMeetingStartTime.substr(0, 5));
  }

  onPopupOpen = (args) => {
    // eslint-disable-next-line
    args.cancel = true;
  };

  getIcon(icon, resourceId, iconStatus, meetingType = 0) { // NOSONAR    

    let IconContent;
    let iconStatusClass = "mr-sm";
    const cursor = { cursor: "pointer" };
    if (iconStatus === 1) {
      if (icon === "faSquare") {
        iconStatusClass = "legend_available";
      } else {
        iconStatusClass = "mr-sm selectedIcon";
      }
    } else if (iconStatus === 0 && icon === "faSquare") {
      if (meetingType === 885) {
        iconStatusClass = "legend_break";

      } else {
        if (meetingType === 998) {
          iconStatusClass = "legend_leave";
        } else {
          iconStatusClass = "legend_busy";
        }
      }
    }
    switch (icon) {
      case "faSquare":
        IconContent = (
          <FontAwesomeIcon
            icon={faSquare}
            style={cursor}
            className={iconStatusClass}
            title="Attendee Status"
            id={resourceId}
          />
        );
        break;
      case "faStar":
        IconContent = (
          <FontAwesomeIcon
            icon={faStar}
            style={cursor}
            className={iconStatusClass}
            title="Chair Person"
            id={resourceId}
            onClick={() => {

              this.tagAttendee(resourceId, icon, iconStatus)
            }}
          />
        );
        break;
      case "faUserCrown":
        IconContent = (
          <FontAwesomeIcon
            icon={faUserCrown}
            style={cursor}
            className={iconStatusClass}
            title="Important Attendee"
            id={resourceId}
            onClick={() => this.tagAttendee(resourceId, icon, iconStatus)}
          />
        );
        break;
      case "faUserClock":
        IconContent = (
          <FontAwesomeIcon
            icon={faUserClock}
            style={cursor}
            className={iconStatusClass}
            title="Partial Attendee"
            id={resourceId}
            // onClick={() => this.tagAttendee(resourceId, icon, iconStatus)}
            onClick={() => this.setPartialTime(resourceId, icon, iconStatus)}
          />
        );
        break;
      case "faUserShield":
        IconContent = (
          <FontAwesomeIcon
            icon={faUserShield}
            style={cursor}
            className={iconStatusClass}
            title="Optional Attendee"
            id={resourceId}
            onClick={() => this.tagAttendee(resourceId, icon, iconStatus)}
          />
        );
        break;
      case "faTrashAlt":
        IconContent = (
          <FontAwesomeIcon
            icon={faTrashAlt}
            style={cursor}
            className={iconStatusClass}
            title="Remove Attendee"
            id={resourceId}
            onClick={() => this.removeSelectedAttendee(resourceId)}
          />
        );
        break;
      case "faMeteor":
        IconContent = (
          <FontAwesomeIcon
            icon={faMeteor}
            style={cursor}
            className={iconStatusClass}
            title="View Meetings"
            id={resourceId}
            onClick={() => this.getAllMettingDetails(resourceId)}
          />
        );
        break;
      default:
        IconContent = (
          <FontAwesomeIcon
            icon={faUserCrown}
            style={cursor}
            className={iconStatusClass}
            title="Attendee"
            id={resourceId}
            onClick={() => this.tagAttendee(resourceId, icon, iconStatus)}
          />
        );
        break;
    }
    return IconContent;
  }

  getName = (value) => {
    if (value.resourceData) {
      return (value.resourceData[value.resource.textField] === 'Attendee' ? `${value.resourceData[value.resource.textField]} (${this.props.meetingAttendee.length})` : value.resourceData[value.resource.textField])
    }
    else {
      return value.resourceName
    }

  };

  /**
   * Author : Suresh R. Sargam | 04-07-2020
   * description :- This function get the status info of the attendee like Busy, Break & Available.
   * @param {*} props
   */
  // eslint-disable-next-line
  getStatusImg(props) {
    if (
      props.resourceData.ClassName === "e-child-node" &&
      props.resourceData.groupId === 1
    ) {
      let mStrStatus = "";
      this.attendeeStatus.forEach((item) => {
        if (item.userid === props.resourceData.UserId) {
          mStrStatus = item.status;
        }
      });
      switch (mStrStatus) {
        case "Busy":
          return (
            <div className="pull-left pr-xs">
              {this.getIcon("faSquare", props.resourceData.UserId, 0, 0)}
            </div>
          );
        case "Break":
          return (
            <div className="pull-left pr-xs">
              {this.getIcon("faSquare", props.resourceData.UserId, 0, 885)}
            </div>
          );
        case "Leave":
          return (
            <div className="pull-left pr-xs">
              {this.getIcon("faSquare", props.resourceData.UserId, 0, 998)}
            </div>
          );
        default:
          return (
            <div className="pull-left pr-xs">
              {this.getIcon("faSquare", props.resourceData.UserId, 1, 0)}
            </div>
          );
      }
    }
  }

  setPartialTime = (resourceId, icon, iconStatus) => {
    this.setState(prevState => ({ timeScalePopUp: true, changedData: !prevState.changedData, uId: resourceId }))
    this.tagAttendee(resourceId, icon, iconStatus)
  }
  closeTimerPopUp = () => {
    this.setState(prevState => ({ timeScalePopUp: false, changedData: !prevState.changedData, uId: null }))
  }


  // Get the icon next to the attendee like Chairperson, Important Attendee etc
  // eslint-disable-next-line
  getImg(props) { // NOSONAR
    if (
      props.resourceData.ClassName === "e-child-node" &&
      props.resourceData.groupId === 1
    ) {
      const closeStatus = 0;
      let starStatus = 0;
      let userStatus;
      let clockStatus;

      let shieldStatus = 0;
      if (props.resourceData.UserId === this.chairPersonId) starStatus = 1;
      if (typeof this.addendeeTag !== 'undefined') {
        const thisObj = this.addendeeTag.find(
          (e) => e.userid === props.resourceData.UserId
        );
        if (typeof thisObj !== "undefined") {
          const splitTag = thisObj.tag.split("_");

          switch (splitTag[1]) {
            case "1":
              clockStatus = 0;
              break;
            case "2":

              clockStatus = 1;
              break;
            case "3":
              shieldStatus = 1;
              break;
            default:
              break;
          }
          switch (splitTag[2]) {
            case "0":
              userStatus = 0;
              break;
            case "1":
              userStatus = 1;
              break;
            default:
              break;
          }
        }
      }

      return (
        <div>
          {this.getIcon("faStar", props.resourceData.UserId, starStatus)}
          {this.getIcon("faUserCrown", props.resourceData.UserId, userStatus)}
          {this.getIcon("faUserClock", props.resourceData.UserId, clockStatus)}
          {this.getIcon(
            "faUserShield",
            props.resourceData.UserId,
            shieldStatus
          )}
          {this.getIcon("faTrashAlt", props.resourceData.UserId, closeStatus)}
          {this.getIcon("faMeteor", props.resourceData.UserId, closeStatus)}
        </div>
      );
    }
  }

  getDateFormat = (d, t) => {
    const dateFormatStr = `${d.getFullYear().toString()}-${(d.getMonth() + 1).toString().length === 2 // NOSONAR

      ? (d.getMonth() + 1).toString()
      : `0${(d.getMonth() + 1).toString()}` // NOSONAR
      }-${d.getDate().toString().length === 2

        ? d.getDate().toString()
        : `0${d.getDate().toString()}` // NOSONAR
      } ${t.getHours().toString().length === 2

        ? t.getHours().toString()
        : `0${t.getHours().toString()}` // NOSONAR
      }:${parseInt(t.getMinutes(), 10).toString().length === 2

        ? // eslint-disable-next-line
        parseInt(t.getMinutes(), 10).toString()
        : // eslint-disable-next-line
        `0${parseInt(t.getMinutes(), 10).toString()}` // NOSONAR
      }:${
      // eslint-disable-next-line
      parseInt(t.getSeconds(), 10).toString().length === 2
        ? // eslint-disable-next-line
        parseInt(t.getSeconds(), 10).toString()
        : // eslint-disable-next-line
        `0${parseInt(t.getSeconds(), 10).toString()}` // NOSONAR
      }`;
    return dateFormatStr;
  };

  getDateOnly = (d) => {
    // eslint-disable-next-line
    d = new Date(d);
    const dateFormatStr = `${d.getFullYear()} ${`0${d.getMonth() + 1}`.slice( // NOSONAR
      -2
    )} ${`0${d.getDate()}`.slice(-2)}`; // NOSONAR
    return dateFormatStr;
  };
  // eslint-disable-next-line
  getTimeOnly = (d, event) => {
    let timeFormatStr;
    // eslint-disable-next-line
    if (typeof d === "number") {
      // eslint-disable-next-line
      d = new Date(d);
    }
    // eslint-disable-next-line
    if (typeof d === "string") {
      // eslint-disable-next-line
      d = new Date(d);
    }

    if (event === "start") {
      timeFormatStr = `${`0${d.getHours()}`.slice(-2)}:${`0${d.getMinutes()}`.slice(-2)}:${`0${d.getSeconds()}`.slice(-2)}`; // NOSONAR
    }
    else
      timeFormatStr = `${`0${d.getHours()}`.slice(-2)}:${`0${d.getMinutes()}`.slice(-2)}:${`0${d.getSeconds()}`.slice(-2)}`; // NOSONAR
    return timeFormatStr;
  };
  // eslint-disable-next-line
  getPreviousAttendees(newProps, refresh) { // NOSONAR

    const { meetingPopOpen, othersCheckAvailablity, categoryName, otherUser, otherUserAccess, authUser, timeZoneId, } = this.props;
    if (meetingPopOpen) {
      const userCategoryData = [];
      const categoryData = [];
      const catDat = {};

      const MdtMeetingDate = this.getDateOnly(newProps.meetingDate).replace(/ /gi, "-");
      const MdtMeetingStartTime = this.getTimeOnly(newProps.meetingStartTime, "start");
      const MdtMeetingEndTime = this.getTimeOnly(newProps.meetingEndTime, "start");
      const meetingStart = `${MdtMeetingDate} ${MdtMeetingStartTime}`;
      const meetingEnd = `${MdtMeetingDate} ${MdtMeetingEndTime}`;

      if (
        (newProps.meetingId !== 0 &&
          typeof newProps.meetingId !== "undefined" &&
          newProps.meetingId != null) || refresh === true
      ) {
        const { meetingAttendee } = newProps;
        const { chairPersonId } = newProps;
        const attendeeTagArray = [];
        let attendeeStr = "";
        const userList = [];
        meetingAttendee.forEach((eachAtendee) => {
          const attendeeArr = eachAtendee.split("_");
          attendeeStr =
            attendeeStr === ""
              ? attendeeArr[0]
              : `${attendeeStr}_${attendeeArr[0]}`;
          const attendeeObj = {};
          // eslint-disable-next-line
          attendeeObj.userid = parseInt(attendeeArr[0], 10);
          attendeeObj.tag = eachAtendee;
          attendeeTagArray.push(attendeeObj);
          const usr = othersCheckAvailablity.filter((item) => {
            // eslint-disable-next-line
            return item.UserId === parseInt(attendeeArr[0], 10);
          });
          userList.push(usr[0]);
        });
        attendeeStr = `ids_${attendeeStr}`;
        userList.forEach((eachU) => {
          // eslint-disable-next-line
          eachU.groupId = 1;
          // eslint-disable-next-line
          eachU.id = eachU.UserId;
          let attendeeName = eachU.name;
          if (attendeeName.includes("(")) {
            const start = attendeeName.indexOf("(");
            attendeeName = attendeeName.substring(0, start);
            attendeeName =
              attendeeName.length > 20
                ? `${attendeeName.substring(0, 20)}...`
                : attendeeName;
          } else {
            attendeeName =
              attendeeName.length > 20
                ? `${attendeeName.substring(0, 20)}...`
                : attendeeName;
          }
          // eslint-disable-next-line
          eachU.name = attendeeName;
          categoryData.push(eachU);
        });
        // eslint-disable-next-line
        catDat.attendeetag = attendeeTagArray;
        // eslint-disable-next-line
        catDat.categoryData = categoryData;
        // eslint-disable-next-line
        catDat.chairPersonId = chairPersonId;
      } else {
        // eslint-disable-next-line
        catDat.categoryData = this.categoryData;
      }
      if (catDat.categoryData.length > 0) {
        const mAttendee = [];
        // eslint-disable-next-line
        for (const eachCat of catDat.categoryData) {
          if (eachCat.groupId === 1) {
            mAttendee.push(eachCat.UserId);
          }
        }
        const userAvailability = dataService.getUserAvailability(
          categoryName === OTHERS_CALENDAR &&
            otherUser !== 0 &&
            otherUserAccess === "Y"
            ? otherUser
            : authUser,
          meetingStart,
          meetingEnd,
          mAttendee.toString(),
          newProps.timeZoneId === "undefined"
            ? timeZoneId
            : newProps.timeZoneId,
          "Status",
          newProps.meetingId
        );

        let mAttendeeStatus = "Available";
        if (this.attendeeStatus.length > 0)
          this.attendeeStatus.forEach((item) => {
            // eslint-disable-next-line
            item.status = "Available";
          });

        let currentStatus = this.attendeeStatus;

        if (userAvailability !== null && typeof userAvailability !== 'undefined') {
          userAvailability.then(result => {
            result.forEach((eachuser) => {
              mAttendeeStatus = eachuser.availabilityStatus;
              // fill the attendee status data
              const statusIndex = currentStatus.findIndex(
                (p) => p.userid === eachuser.attendeeId
              );
              if (statusIndex === -1)
                currentStatus = [
                  // eslint-disable-next-line
                  ...currentStatus,
                  { userid: eachuser.attendeeId, status: mAttendeeStatus },
                ];
              else currentStatus[statusIndex].status = mAttendeeStatus;
            });

            mAttendee.forEach((item) => {
              const statusIndex = currentStatus.findIndex((p) => p.userid === item);
              if (statusIndex === -1) {
                currentStatus = [
                  // eslint-disable-next-line
                  ...currentStatus,
                  { userid: item, status: "Available" },
                ];
              }
            });
            this.attendeeStatus = currentStatus;
            this.props.meetingAttendee.forEach(async attendee => {
              const { meetingId } = this.props
              let partial = attendee.split('_');
              if (parseInt(partial[1], 10) === 2) {
                //693_2_0_2022 - 02 - 14 23: 35: 00_2022 - 02 - 14 23: 45: 00
                let startDT = partial[3];
                let endDt = partial[4];
                let user0 = partial[0];
                const status = await dataService.getUserAvailability(
                  authUser,
                  startDT,
                  endDt,
                  user0,
                  timeZoneId,
                  'Status',
                  meetingId
                );

                let tool;
                if (status.length !== 0) {
                  switch (status[0].availabilityStatus) {
                    case 'Busy':

                      tool = 'Busy';
                      break;
                    case 'Break':

                      tool = 'Break'
                      break;
                    case 'Leave':

                      tool = 'Leave';
                      break;
                    default:
                  }
                } else {

                  tool = 'Available';
                }
                let currentStatus1 = this.attendeeStatus;

                currentStatus1.forEach(user => {
                  if (user.userid === parseInt(user0, 10)) {
                    user.status = tool;
                  }
                })
                this.attendeeStatus = currentStatus1;
              }
            })
            this.updateAttendeeOnBreak()
            catDat.data = userCategoryData;
            return catDat;
          })
        }
      }
      catDat.data = userCategoryData;
      return catDat;
    }
  }

  getPreviousRooms(thisProps) {// NOSONAR
    const {
      meetingStartTime,
      meetingEndTime,
      categoryName,
      otherUser,
      authUser,
      otherUserAccess,
      meetingDate,
      meetingRoomsId
    } = this.props;
    const mDtMeetingDate = this.getDateOnly(meetingDate).replace(/ /gi, "-");
    const mDtMeetingStartTime = this.getTimeOnly(meetingStartTime, "start");
    const mDtMeetingEndTime = this.getTimeOnly(meetingEndTime, "start");
    const meetingStart = `${mDtMeetingDate} ${mDtMeetingStartTime}`;
    const meetingEnd = `${mDtMeetingDate} ${mDtMeetingEndTime}`;


    let meetingRooms = meetingRoomsId;

    const roomCategoryData = [];
    const arrMrSelected = [];
    const previousRoomData = {};
    const roomOptionsSelected = [];

    // eslint-disable-next-line
    const roomAvailability = dataService.getRoomAvailability(
      categoryName === OTHERS_CALENDAR &&
        otherUser !== 0 &&
        otherUserAccess === "Y"
        ? otherUser
        : authUser,
      meetingStart,
      meetingEnd,
      thisProps.meetingId,
      meetingRooms.toString()
    );

    if (roomAvailability !== null) {
      roomAvailability.then(result => {
        // eslint-disable-next-line
        result.forEach(eachRoomMeet => { // NOSONAR
          eachRoomMeet.roomName = eachRoomMeet.var_list_value;
          eachRoomMeet.roomLocation = eachRoomMeet.Category;
          // eslint-disable-next-line
          eachRoomMeet.TaskId = eachRoomMeet.int_list_uid;
          // eslint-disable-next-line
          eachRoomMeet.ProjectId = 2;
          // eslint-disable-next-line
          eachRoomMeet.Subject = " ";
          // eslint-disable-next-line
          eachRoomMeet.StartTime = eachRoomMeet.stDate;
          // eslint-disable-next-line
          eachRoomMeet.EndTime = eachRoomMeet.endDate;
          roomCategoryData.push(eachRoomMeet);
        });

        roomCategoryData.push({
          roomName: "Self Desk",
          roomLocation: "Self",
          TaskId: 87,
          ProjectId: 2,
          Subject: " ",
          StartTime: null,
          EndTime: null,
        });

        for (const roomId of meetingRooms) {
          const rmDts = roomCategoryData.filter(x => { return x.TaskId === roomId })
          const item = {};
          // eslint-disable-next-lines
          item.groupId = 2;
          // eslint-disable-next-line
          item.name = roomId === 87 ? "Self Desk" : rmDts[0].roomName;
          // eslint-disable-next-line
          item.id = roomId;
          // eslint-disable-next-line
          item.location = roomId === 87 ? "Self" : rmDts[0].roomLocation;
          // eslint-disable-next-line
          arrMrSelected.push(item);
          if (!roomOptionsSelected.includes(roomId))
            roomOptionsSelected.push(roomId);
        }

        const m_meetingRoomsId = [];
        arrMrSelected.forEach((eachRoom) => {
          m_meetingRoomsId.push(eachRoom.id);
        });
        // eslint-disable-next-line
        previousRoomData.meetingRoomsId = m_meetingRoomsId;
        // eslint-disable-next-line
        previousRoomData.roomCategoryData = roomCategoryData;
        // eslint-disable-next-line
        previousRoomData.arrMrSelected = arrMrSelected;
        // eslint-disable-next-line
        previousRoomData.roomIdsSelected = arrMrSelected;
        // eslint-disable-next-line
        previousRoomData.roomOptionsSelected = roomOptionsSelected;

        return previousRoomData;

      })
    }
  }

  /**
   * Author : Prashant Waphare | 04-07-2020
   * description :- This function user for get single attendee meeting data on click Get All Meeting.
   * @param {*} resource_id
   */
  async getAllMettingDetails(resourceId) {
    const {
      meetingDate,
      meetingStartTime,
      meetingEndTime,
      categoryName,
      otherUser,
      authUser,
      otherUserAccess,
      timeZoneId,
      meetingmode,
      meetingId,
    } = this.props;
    const userCategoryData = [];

    const mDtMeetingDate = this.getDateOnly(meetingDate).replace(/ /gi, "-");
    const mDtMeetingStartTime = this.getTimeOnly(meetingStartTime, "start");
    const mDtMeetingEndTime = this.getTimeOnly(meetingEndTime, "end");
    const meetingStart = `${mDtMeetingDate} ${mDtMeetingStartTime}`;
    const meetingEnd = `${mDtMeetingDate} ${mDtMeetingEndTime}`;
    const userAvailability = await dataService.getUserAvailability(
      categoryName === OTHERS_CALENDAR &&
        otherUser !== 0 &&
        otherUserAccess === "Y"
        ? otherUser
        : authUser,
      meetingStart,
      meetingEnd,
      resourceId,
      timeZoneId,
      meetingmode,
      meetingId
    );

    userAvailability.forEach((eachuser) => {
      // eslint-disable-next-line
      eachuser.TaskId = resourceId;
      // eslint-disable-next-line
      eachuser.ProjectId = 1;
      // eslint-disable-next-line
      eachuser.Subject = " ";
      // eslint-disable-next-line
      eachuser.StartTime = eachuser.dt_attendee_start_date;
      // eslint-disable-next-line
      eachuser.EndTime = eachuser.dt_attendee_end_date;
      // eslint-disable-next-line
      userCategoryData.push(eachuser);
    });
    this.uData = [...this.uData, ...userCategoryData];
    // eslint-disable-next-line
    const data1 = [...this.uData, ...this.rData];
    this.data = data1.filter(
      (elem, index, self) => // NOSONAR
        self.findIndex((t) => { // NOSONAR
          return (
            t.Attendee === elem.Attendee &&
            t.StartTime === elem.StartTime &&
            t.EndTime === elem.EndTime
          );
        }) === index
    );
    // eslint-disable-next-line
    const categoryAttendeeDataNonUnique = [...this.categoryAttendeeData];
    this.categoryAttendeeData = categoryAttendeeDataNonUnique.filter(
      (elem, index, self) =>
        self.findIndex((t) => {
          return t.UserId === elem.UserId;
        }) === index
    );
    // eslint-disable-next-line
    const uniqueArray = [...this.categoryData, ...this.categoryAttendeeData];
    this.categoryData = uniqueArray.filter(
      (item, index) => uniqueArray.indexOf(item) === index
    ); // Remove duplicate items
    // eslint-disable-next-line
    this.setState({ changedData: !this.state.changedData });
  }

  getAttendeeStatus(userStatus) {
    let currentStatus = this.attendeeStatus;
    currentStatus.forEach(user => {
      if (user.userid === userStatus.userid) {
        user.status = userStatus.status;
      }
    })
    this.attendeeStatus = currentStatus;

  }


  async getSelectedAttendee(e) {
    setTimeout(async () => { // NOSONAR
      const {
        meetingDate,
        meetingStartTime,
        meetingEndTime,
        categoryName,
        otherUser,
        authUser,
        otherUserAccess,
        timeZoneId,
        meetingId,
        meetingTypeId,
      } = this.props;

      try {
        if (document.getElementById("maError") !== null)
          document.getElementById("maError").innerHTML = "";

        // check whether user already exists
        let mBlncheck = false;
        // eslint-disable-next-line
        this.addendeeTag.forEach((item) => {
          if (item.userid === e.itemData.UserId) {
            mBlncheck = true;
            if (
              !SelfBreakLeave.includes(this.meetingTypeId) && !SelfBreakLeave.includes(meetingTypeId)
            ) {
              getNotify(WARNING, "Selected user/user's already exists in the list");
              this.bulkAttendeeLoaderCount--;
              this.setState({ bulkAttendeeLoader: false, changedData: !this.state.changedData })
              return false;
            }
          } else if (
            SelfBreakLeave.includes(this.meetingTypeId)
          ) {
            getNotify(
              WARNING,
              "Other users are not allowed in this type of meeting"
            );
            return false;
          }
        });

        // If exists do not execute below code
        if (mBlncheck) {
          setTimeout(() => {
            if (typeof this.searchAttendee !== "undefined") {
              this.searchAttendee.text = null;
            }
          }, 100);
          return;
        }

        let selItem;
        if (e.itemData.name.includes("(")) {
          const trimFirstName = e.itemData;
          const start = trimFirstName.name.indexOf("(");
          trimFirstName.name = trimFirstName.name.substring(0, start);
          trimFirstName.name =
            trimFirstName.name.length > 20
              ? `${trimFirstName.name.substring(0, 20)}...`
              : trimFirstName.name;
          selItem = trimFirstName;
        } else {
          selItem = e.itemData;
          selItem.name =
            selItem.name.length > 20
              ? `${selItem.name.substring(0, 20)}...`
              : selItem.name;
        }
        const mDtMeetingDate = this.getDateOnly(meetingDate).replace(/ /gi, "-");
        const mDtMeetingStartTime = this.getTimeOnly(meetingStartTime, "start");
        const mDtMeetingEndTime = this.getTimeOnly(meetingEndTime, "end");
        const meetingStart = `${mDtMeetingDate} ${mDtMeetingStartTime}`;
        const meetingEnd = `${mDtMeetingDate} ${mDtMeetingEndTime}`;

        const userCategoryData = [];
        if (selItem) {
          selItem.groupId = 1;
          selItem.id = selItem.UserId;
          selItem.color = "#df5286";
          // get list of user availability status
          const userAvailability = await dataService.getUserAvailability(
            categoryName === OTHERS_CALENDAR &&
              otherUser !== 0 &&
              otherUserAccess === "Y"
              ? otherUser
              : authUser,
            meetingStart,
            meetingEnd,
            selItem.UserId,
            timeZoneId,
            "Status",
            meetingId
          );
          let mAttendeeStatus = "Available";
          if (userAvailability[0] !== undefined) {

            mAttendeeStatus = userAvailability[0].availabilityStatus;
          }
          // fill the attendee status data
          let currentStatus = this.attendeeStatus;
          const statusIndex = currentStatus.findIndex(
            (p) => p.userid === selItem.UserId
          );
          if (statusIndex === -1) {
            currentStatus = [
              // eslint-disable-next-line
              ...currentStatus,
              { userid: selItem.UserId, status: mAttendeeStatus },
            ];
          } else {
            // eslint-disable-next-line
            currentStatus[statusIndex].status = mAttendeeStatus;
          }

          this.attendeeStatus = currentStatus;
          //Now we will set the status of partial attendee

          // Add Default Attendee Tag //          
          let currentTags = this.addendeeTag;
          const userimp = "0";
          const usertimeType = "1";
          const finalTag = `${selItem.UserId}_${usertimeType}_${userimp}`;
          const index = currentTags.findIndex((p) => p.userid === selItem.UserId);
          if (index === -1) {
            currentTags = [
              // eslint-disable-next-line
              ...currentTags,
              { userid: selItem.UserId, tag: finalTag },
            ];
          } else {
            currentTags[index].tag = finalTag;
          }
          const meetingAttendee = [];
          currentTags.forEach((eachAttendee) => {
            meetingAttendee.push(eachAttendee.tag);
          });
          // eslint-disable-next-line 
          //
          let finalMeetingAttendee = []
          let varPreMeetingAttendeeId = []
          this.props.meetingAttendee.forEach(preAttendee => {
            finalMeetingAttendee.push(preAttendee);
            varPreMeetingAttendeeId.push(preAttendee.split("_")[0])
          })

          meetingAttendee.forEach(attendee => {
            let idtoFind = attendee.split("_")[0]
            let find = varPreMeetingAttendeeId.includes(idtoFind)
            if (!find) {
              finalMeetingAttendee.push(attendee)
            }
          })
          this.props.updateMeetingBookingState({
            meetingAttendee: finalMeetingAttendee
          });
          this.updateAttendeeOnBreak()
          // eslint-disable-next-line
          this.uData = [...this.uData, ...userCategoryData];
          // eslint-disable-next-line
          const data1 = [...this.uData, ...this.rData];
          // eslint-disable-next-line
          this.data = data1.filter(
            // eslint-disable-next-line
            (elem, index1, self) =>
              self.findIndex((t) => { // NOSONAR
                return (
                  t.Attendee === elem.Attendee &&
                  t.StartTime === elem.StartTime &&
                  t.EndTime === elem.EndTime
                );
              }) === index1
          );
          const categoryAttendeeDataNonUnique = [
            // eslint-disable-next-line
            ...this.categoryAttendeeData,
            selItem,
          ];
          this.categoryAttendeeData = categoryAttendeeDataNonUnique.filter(
            // eslint-disable-next-line
            (elem, index2, self) =>
              self.findIndex((t) => {
                return t.UserId === elem.UserId;
              }) === index2
          );
          // eslint-disable-next-line
          const uniqueArray = [...this.categoryData, ...this.categoryAttendeeData];
          this.categoryData = uniqueArray.filter(
            // eslint-disable-next-line
            (item, index3) => uniqueArray.indexOf(item) === index3
          ); // Remove duplicate items
          this.addendeeTag = currentTags;
          // eslint-disable-next-line
          this.setState({ changedData: !this.state.changedData });
          this.bulkAttendeeLoaderCount--;
          if (this.bulkAttendeeLoaderCount === -1 || this.bulkAttendeeLoaderCount === 0) {
            this.setState({ bulkAttendeeLoader: false, changedData: !this.state.changedData })
          }
        } else {
          this.setState({ bulkAttendeeLoader: false, changedData: !this.state.changedData })
        }
      } catch (err) {
        console.log('err+++++', err);
      }
    }, 100)
    setTimeout(() => {
      this.searchAttendee.refresh()
    }, 1500);
    
  }

  async getSelectedRoom(e) {
    const {
      meetingDate,
      meetingStartTime,
      meetingEndTime,
      categoryName,
      otherUser,
      otherUserAccess,
      authUser,
    } = this.props;

    if (e.name === "removed") {
      // eslint-disable-next-line
      e.value = this.mulObj.value.filter(function (value, index, arr) {
        // eslint-disable-next-line
        return (e.itemData.Id);
      });
    } else if (e.name === "select") {
      // eslint-disable-next-line
      e.value = [...this.mulObj.value, e.itemData.Id];
    }
    if (document.getElementById("mrError") !== null)
      document.getElementById("mrError").innerHTML = "";

    const mDtMeetingDate = this.getDateOnly(meetingDate).replace(/ /gi, "-");
    const mDtMeetingStartTime = this.getTimeOnly(meetingStartTime, "start");
    const mDtMeetingEndTime = this.getTimeOnly(meetingEndTime, "start");
    const meetingStart = `${mDtMeetingDate} ${mDtMeetingStartTime}`;
    const meetingEnd = `${mDtMeetingDate} ${mDtMeetingEndTime}`;

    const arrMrSelected = [];
    const roomCategoryData = [];
    // eslint-disable-next-line
    for (const element of this.roomOptions) {
      if (e.value.indexOf(element.Id) > -1) {
        // eslint-disable-next-line
        const roomAvailability = await dataService.getRoomAvailability(
          categoryName === OTHERS_CALENDAR &&
            otherUser !== 0 &&
            otherUserAccess === "Y"
            ? otherUser
            : authUser,
          meetingStart,
          meetingEnd,
          "0",
          element.Id
        );
        roomAvailability.forEach((eachRoomMeet) => {
          // eslint-disable-next-line
          eachRoomMeet.TaskId = element.Id;
          // eslint-disable-next-line
          eachRoomMeet.ProjectId = 2;
          // eslint-disable-next-line
          eachRoomMeet.Subject = " ";
          // eslint-disable-next-line
          eachRoomMeet.StartTime = eachRoomMeet.stDate;
          // eslint-disable-next-line
          eachRoomMeet.EndTime = eachRoomMeet.endDate;
          roomCategoryData.push(eachRoomMeet);
        });
        const item = {};
        // eslint-disable-next-line
        item.groupId = 2;
        // eslint-disable-next-line
        item.name = element.Room;
        // eslint-disable-next-line
        item.id = element.Id;
        // eslint-disable-next-line
        item.location = element.Category;

        arrMrSelected.push(item);
      }
    }
    const meetingRoomsId = [];
    // eslint-disable-next-line
    arrMrSelected.forEach((eachRoom) => {
      meetingRoomsId.push(eachRoom.id);
    });
    // eslint-disable-next-line
    this.categoryData = [...this.categoryAttendeeData, ...arrMrSelected];
    this.rData = roomCategoryData;
    // eslint-disable-next-line
    const data1 = [...this.uData, ...this.rData];
    this.data = data1.filter(
      (elem, index, self) => // NOSONAR
        self.findIndex((t) => { // NOSONAR
          return (
            t.Attendee === elem.Attendee &&
            t.StartTime === elem.StartTime &&
            t.EndTime === elem.EndTime
          );
        }) === index
    );
    this.roomIdsSelected = arrMrSelected;
    this.roomOptionsSelected = e.value;
    // eslint-disable-next-line
    this.setState({ changedData: !this.state.changedData });

    // eslint-disable-next-line        
    this.props.updateMeetingBookingState({
      meetingRoomsId,
    });
  }

  getSelectedAttendeeFilter(e) {
    const trimnameWithDepartment = e.itemData;
    let mArrattendeeOptionsSelected = [];
    if (e.itemData.nameWithDepartment.includes("(")) {
      const start = trimnameWithDepartment.nameWithDepartment.indexOf("(");
      trimnameWithDepartment.nameWithDepartment = trimnameWithDepartment.nameWithDepartment.substring(
        0,
        start
      );
      mArrattendeeOptionsSelected = this.attendeeOptionsSelected;
      mArrattendeeOptionsSelected.push(trimnameWithDepartment.UserId);
      this.attendeeOptionsSelected = mArrattendeeOptionsSelected;
      // eslint-disable-next-line
      this.setState({ changedData: !this.state.changedData });
    } else {
      mArrattendeeOptionsSelected = this.attendeeOptionsSelected;
      mArrattendeeOptionsSelected.push(e.itemData.UserId);
      this.attendeeOptionsSelected = mArrattendeeOptionsSelected;
      // eslint-disable-next-line
      this.setState({ changedData: !this.state.changedData });
    }
  }

  getTimeString(value) {
    return this.instance.formatDate(value, { skeleton: "Hm" });
  }
  // eslint-disable-next-line
  tagAttendee(userid, action, iconStatus) { // NOSONAR
    try {
      if (document.getElementById("maError") !== null)
        document.getElementById("maError").innerHTML = "";
      let userimp = "0";
      let usertimeType = "1";
      let chairPerson = this.chairPersonId;
      let currentTags = this.addendeeTag;
      const thisObj = this.addendeeTag.find((e) => e.userid === userid);

      if (typeof thisObj !== "undefined") {
        // eslint-disable-next-line
        const splitTag = thisObj.tag.split("_");
        // eslint-disable-next-line
        userimp = splitTag[2];
        // eslint-disable-next-line
        usertimeType = splitTag[1];
      }

      if (this.scheduleObjInt) {
        this.scheduleObjInt.refreshTemplates();
      }

      switch (action) {
        case "faStar": {
          const userList = this.props.othersCheckAvailablity.filter((item) => {
            // eslint-disable-next-line
            return item.UserId === parseInt(userid, 10);
          });
          let domain = userList[0].EmailAddress.split('@')[1]
          if (iconStatus === 0) {
               chairPerson = userid;
               if(this.props.meetingWeblinkType === 'Teams'){
                if(domain!== this.props.domainName){
                  if(this.props.meetingWebLink !==''){
                    getNotify(WARNING,CHAIR_PERSON_CHANGE_MESSAGE)
                   }
                   this.props.updateMeetingBookingState({ meetingWebLink: '', participateCode: "", webLinkLoader: "2" });
                 }
               }
               
          }
          else if (iconStatus === 1) chairPerson = 0;
          userimp = "0";
          usertimeType = "1";
          break;
        }
        case "faUserCrown": {
          if (iconStatus === 0) userimp = "1";
          else if (iconStatus === 1) userimp = "0";
          chairPerson = chairPerson !== userid ? chairPerson : 0;
          usertimeType = usertimeType === "3" ? "1" : usertimeType;
          break;
        }
        case "faUserClock": {
          if (iconStatus === 0 || iconStatus === 1) {
            usertimeType = "2";
          }
          chairPerson = chairPerson !== userid ? chairPerson : 0;
          break;
        }
        case "faUserClockOff": {
          if (iconStatus === 1) {
            usertimeType = "1";
          }
          break;
        }
        case "faUserShield": {
          if (iconStatus === 0) usertimeType = "3";
          else if (iconStatus === 1) usertimeType = "1";
          chairPerson = chairPerson !== userid ? chairPerson : 0;
          userimp = "0";
          break;
        }
        default: {
          break;
        }
      }

      const finalTag = `${userid}_${usertimeType}_${userimp}`;

      const index = currentTags.findIndex((p) => p.userid === userid);
      if (index === -1) {
        // eslint-disable-next-line
        currentTags = [...currentTags, { userid, tag: finalTag }];
      } else {
        // eslint-disable-next-line
        currentTags[index].tag = finalTag;
      }


      // eslint-disable-next-line

      // eslint-disable-next-line
      let finalMeetingAttendee = []
      this.props.meetingAttendee.forEach(preMeetingAttendee => {

        if (parseInt(preMeetingAttendee.split("_")[0], 10) === userid) {

          let arr = preMeetingAttendee.split("_");

          arr[1] = usertimeType;
          arr[2] = userimp;

          let str;
          if (usertimeType === '1') {
            str = `${arr[0]}_${arr[1]}_${arr[2]}`
          } else if (usertimeType === '2') {
            str = arr.join("_");
          } else if (usertimeType === '3') {
            str = `${arr[0]}_${arr[1]}_${arr[2]}`
          }
          finalMeetingAttendee.push(str);
        } else if (parseInt(preMeetingAttendee.split("_")[0], 10) !== userid) {
          finalMeetingAttendee.push(preMeetingAttendee);
        }
      })

      this.props.updateMeetingBookingState({
        meetingAttendee: finalMeetingAttendee,
        chairPersonId: chairPerson,
      });
      this.updateAttendeeOnBreak()
      this.chairPersonId = chairPerson;
      this.addendeeTag = currentTags;
      this.counter += 1;
      // eslint-disable-next-line
      this.setState({ changedData: !this.state.changedData });
    } catch (err) {
      console.log("err", err);
    }
  }

  handleFocusOut = (obj) => {
    try {
      if (obj.event.srcElement.name === 'mlocation-txt') {


        this.props.updateMeetingBookingState({
          meetingLocation: obj.value.trim(),
        });

      }




    } catch (error) {
      // eslint-disable-next-line
    }
  };

  handleChange = (event) => {
    if (event.target.name === 'motherAtt-txt') {

      // eslint-disable-next-line
      this.props.updateMeetingBookingState({
        otherAttendees: event.target.value,
      });




    }
  };
  handleChangeBulkAttendee = (event) => {
    const { changedData } = this.state;
    let list = [];
    list = event.value !== "" ? event.value.split(',') : [];

    this.setState({
      bulkAtteendeList: list,
      disableAddBulk: event.value !== "" ? true : false,
      changedData: !changedData
    })
    // }
  };

  handleFilteringByName = (e) => {
    const { othersCheckAvailablity } = this.props;
    const newData = [];
    // eslint-disable-next-line
    othersCheckAvailablity.forEach((eachD) => {
      newData.push(eachD);
    });
    if (newData !== null && newData.length > 0) {
      return;
    }
  };

  majorSlotTemplate(props) {
    return (
      <div className="timewrap" style={{ height: `${25}px` }}>
        <div>{this.instance.formatDate(props.date, { skeleton: "Hm" })}</div>
      </div>
    );
  }

  eventTemplate(props) {
    return (
      <div className="template-wrap" style={{ margin: "-4px 26px 0px -8px" }}>
        <div className="time" style={{ fontSize: 12 }}>
          {this.getTimeString(props.StartTime)} -{" "}
          {this.getTimeString(props.EndTime)}
        </div>
      </div>
    );
  }

  template(props) {
    return (
      <div className="tooltip-wrap">
        <div className="content-area">
          <div className="name"   >
            {this.getTimeString(props.StartTime)} -{" "}
            {this.getTimeString(props.EndTime)}
          </div>
        </div>
      </div>
    );
  }

  noRecordsTemplate() {
    return this.errorEnter3charactor ? (
      <div>Please enter minimum 3 characters</div>
    ) : (
      <div>No record found</div>
    );
  }

  // Add Me functionality
  async handleAddMe() {
    const {
      categoryName,
      otherUser,
      otherUserAccess,
      meetingSetterId,
      othersCheckAvailablity,
    } = this.props;
    const UserId =
      categoryName === OTHERS_CALENDAR &&
        otherUser !== 0 &&
        otherUserAccess === "Y"
        ? otherUser
        : meetingSetterId;
    const userList = othersCheckAvailablity.filter((item) => {
      // eslint-disable-next-line
      return item.UserId === parseInt(UserId, 10);
    });
    this.getSelectedAttendee({ itemData: userList[0] });
    setTimeout(() => {
      this.tagAttendee(UserId, "faStar", 0);
    }, 1000)
    if (
      categoryName === OTHERS_CALENDAR &&
      otherUser !== 0 &&
      otherUserAccess === "Y" &&
      this.meetingTypeId === 885
    ) {

      // eslint-disable-next-line
      this.props.updateMeetingBookingState({
        chairPersonId: otherUser,
      });
    }
  }

  convertMeetingTimeToTimeStamp(propsArgument) {
    // eslint-disable-next-line
    const mDtMeetingDate = this.getDateOnly(propsArgument.meeting_date);
    const mDtMeetingStartTime = this.getTimeOnly(
      propsArgument.meetingStartTime,
      "start"
    );
    const mDtMeetingEndTime = this.getTimeOnly(
      propsArgument.meetingEndTime,
      "end"
    );
    let meetingStart = `${mDtMeetingDate} ${mDtMeetingStartTime}`;
    let meetingEnd = `${mDtMeetingDate} ${mDtMeetingEndTime}`;
    meetingStart = this.toTimestamp(meetingStart);
    meetingEnd = this.toTimestamp(meetingEnd);
    return { meetingStart, meetingEnd };
  }

  toTimestamp = (strDate) => {
    const datum = Date.parse(strDate);
    return datum / 1000;
  };

  removePreviousRooms() {
    const categoryDataWithoutRoomData = [];
    // eslint-disable-next-line
    this.categoryData.forEach((eachCat) => {
      if (eachCat.groupId === 1) {
        categoryDataWithoutRoomData.push(eachCat);
      }
    });
    return categoryDataWithoutRoomData;
  }

  // To remove selected attendee from the list
  // eslint-disable-next-line
  removeSelectedAttendee(userid) {
    const currentAddendeeTag = this.addendeeTag;
    const addendeeTag = currentAddendeeTag.filter((i) => i.userid !== userid);
    this.addendeeTag = addendeeTag;
    const chairPersonId =
      this.chairPersonId === userid ? 0 : this.chairPersonId;
    const meetingAttendee = [];
    addendeeTag.forEach((eachAttendee) => {
      meetingAttendee.push(eachAttendee.tag);
    });
    // eslint-disable-next-line
    this.props.updateMeetingBookingState({
      meetingAttendee,
      chairPersonId,
    });
    this.updateAttendeeOnBreak()
    this.categoryAttendeeData = this.categoryAttendeeData.filter(
      (item) => item.UserId !== userid
    );
    this.categoryData = this.categoryData.filter(
      (item) => item.UserId !== userid
    );
    this.data = this.data.filter(
      (item) => item.TaskId !== userid && item.ProjectId === 1
    );
    // eslint-disable-next-line
    this.setState({ changedData: !this.state.changedData });
  }

  resourceHeaderTemplate(props) {
    return (
      <div className="template-wrap">
        <div className="resource-detail">
          <div className="resource-name">
            {this.getStatusImg(props)}
            {this.getName(props)}
          </div>
          <div className="resource-designation pull-right pr-sm">
            {this.getImg(props)}
          </div>
        </div>
      </div>
    );
  }

  removeItem(e) {
    const mArrattendeeOptionsSelected = this.attendeeOptionsSelected;
    this.attendeeOptionsSelected = mArrattendeeOptionsSelected.filter(
      (el) => el !== e.itemData.UserId
    );
    // eslint-disable-next-line
    this.setState({ changedData: !this.state.changedData });
  }
  closePopupDialog = () => {
    const { changedData } = this.state;
    this.setState({
      openBulkAttendeePopup: false,
      bulkAtteendeList: [],
      invalidAttendees: [],
      validAttendees: [],
      changedData: !changedData
    }, () =>// eslint-disable-next-line
      this.props.updateMeetingBookingState({
        bulkAttendees: "",
      }))
  }
  handleAddBulkAttendee = () => {
    const { othersCheckAvailablity } = this.props;
    const { changedData, validAttendees } = this.state;
    // 'bulkAttendeeError'
    document.getElementById(
      "bulkAttendeeError"
    ).innerHTML = '';
    if (validAttendees.length > 0) {

      // eslint-disable-next-line
      validAttendees.forEach((item, i) => {
        // eslint-disable-next-line
        othersCheckAvailablity.map((itemData, inx) => {
          if (item === itemData.EmpCode || item === itemData.EmailAddress) {
            this.setState({
              bulkAttendeeLoader: true,
              changedData: !changedData
            })
            let e = {};
            e.itemData = itemData
            setTimeout(() => {
              this.getSelectedAttendee(e);
            }, 200)
            this.bulkAttendeeLoaderCount++;
          }
        })
      })
      this.props.updateMeetingBookingState({ bulkAttendeesFlag: true })
      this.closePopupDialog();
    } else {
      document.getElementById(
        "bulkAttendeeError"
      ).innerHTML = 'Please enter valid comma seperated employee code';
      return; // NOSONAR
    }
  }
  handleValidateBulkAttendee = (e) => { // NOSONAR
    const { othersCheckAvailablity } = this.props;
    const { changedData, bulkAtteendeList } = this.state;
    let attendeeMasterData = othersCheckAvailablity;
    if (bulkAtteendeList !== '') { // NOSONAR
      let invalidAttendees = [];
      let validAttendees = [];
      let bulkData = bulkAtteendeList;
      // eslint-disable-next-line
      bulkData.forEach((item) => {
        let empCode = item;
        let data = attendeeMasterData.filter(x => (x.EmpCode === empCode.replace(/\s/g, '') || x.EmailAddress === empCode.replace(/\s/g, '')))
        if (data.length > 0) {
          validAttendees.push(empCode.replace(/\s/g, ''));
        } else {
          invalidAttendees.push(empCode.replace(/\s/g, ''))
        }
      })
      // this.setState({
      //   bulkAtteendeList:[],
      //   changedData:!changedData
      // })
      // setTimeout(()=>{
      // eslint-disable-next-line
      if (this.state.validAttendees.length > 0 || this.state.invalidAttendees.length > 0) {
        // eslint-disable-next-line
        let validList = this.state.validAttendees;
        // eslint-disable-next-line
        let invalidList = this.state.invalidAttendees;
        if (validAttendees.length > 0) {
          // eslint-disable-next-line
          validAttendees.forEach((d) => {
            validList.push(d);
          })
        }
        if (invalidAttendees.length > 0) {
          // eslint-disable-next-line
          invalidAttendees.forEach((d) => {
            invalidList.push(d);
          })
        }
        this.setState({
          invalidAttendees: invalidList,
          validAttendees: validList,
          bulkAtteendeList: [],
          disableAddBulk: false,
          changedData: !changedData
        })
      } else {
        this.setState({
          invalidAttendees: invalidAttendees,
          validAttendees: validAttendees,
          bulkAtteendeList: [],
          disableAddBulk: false,
          changedData: !changedData
        })
      }

    }
  }
  onInput = (e) => {
    this.handleChangeBulkAttendee(e);
    this.textareaObj.respectiveElement.style.minHeight = '100px'
    this.textareaObj.respectiveElement.style.height = "auto";
    this.textareaObj.respectiveElement.style.height = this.textareaObj.respectiveElement.scrollHeight <= 200 ? `${this.textareaObj.respectiveElement.scrollHeight}px` : `200px`;
  };


  /**
   * closeButton function mark the attendee as a full attendee
   */
  closeButton() {
    this.tagAttendee(this.state.uId, 'faUserClockOff', 1)
  }
  updateAttendeeOnBreak() { //NOSONAR
    try {
      setTimeout(() => {
        const { categoryName, meetingAttendee, authUser, otherUser, otherUserAccess } = this.props;
        let attendeeOnBreak = [];
        if (meetingAttendee !== null && typeof meetingAttendee !== 'undefined') {
          const loggedIn = (categoryName === OTHERS_CALENDAR && otherUser !== 0 && otherUserAccess === "Y" ? otherUser : authUser)
          this.attendeeStatus.forEach((item) => {
            if (["Busy", "Break", "Leave"].includes(item.status)) {
              meetingAttendee.forEach(elem => {
                if (item.userid.toString() === (elem.split("_")[0]).toString() && loggedIn !== item.userid)
                  attendeeOnBreak.push(item.userid)
              })
            }
          });
        }
        this.props.updateMeetingBookingState({ attendeeOnBreak: attendeeOnBreak });
      }, 200)
    } catch (error) { }
  }

  render() {
    const {
      isDigitalMeeting,
      meetingLocation,
      otherAttendees,
      meetingDate,
      othersCheckAvailablity,
    } = this.props;
    const { openBulkAttendeePopup, changedData, disableAddBulk, bulkAtteendeList, invalidAttendees, validAttendees, timeScalePopUp } = this.state;
    return (
      <div>
        <div>Meeting Resources</div>
        <div>
          <ErrorBoundary>
            {isDigitalMeeting && <DigitalMedia />}
          </ErrorBoundary>
          {/* Invite Attendee */}
          <div className="col-md-12 pt-sm">
            <div className="row">
              {/* Select Meeting room */}
              <ErrorBoundary>
                <div className="col-md-5">
                  <div className="row1 custom-margin custom-padding-5 material2">
                    <div className="col-md-12 ">
                      <div className="e-input-group e-float-icon-left">
                        <FontAwesomeIcon
                          icon={faSitemap}
                          className="mt-sm mr-sm fa-lg pull-left"
                        />
                        <MultiSelectComponent
                          style={{ display: "inline-block" }}
                          ref={(scope) => {
                            this.mulObj = scope;
                            return this.mulObj;
                          }}
                          id="mr-select"
                          name="mr-select"
                          dataSource={this.roomOptions}
                          fields={{
                            groupBy: "Category",
                            text: "Room",
                            value: "Id",
                          }}
                          placeholder="Meeting Room *"
                          cssClass="e-outline"
                          floatLabelType="Auto"
                          showSelectAll={false}
                          maximumSelectionLength={defMeetingRoomLimit}
                          select={this.getSelectedRoom}
                          removed={this.getSelectedRoom}
                          value={this.roomOptionsSelected}
                          showDropDownIcon
                          filterBarPlaceholder="Meeting Room"
                          data-msg-containerid="mrError"
                          mode="CheckBox"
                          allowFiltering={false}
                        >
                          <Inject services={[CheckBoxSelection]} />
                        </MultiSelectComponent>
                      </div>
                    </div>
                  </div>
                  <div id="mrError" className="error-div pl-xlg" />
                </div>
              </ErrorBoundary>
              {/* Meeting location */}
              <ErrorBoundary>
                <div className="col-md-5">
                  <div className="custom-margin custom-padding-5 material2">
                    <div className="col-md-12">
                      <div className="e-input-group e-float-icon-left">
                        <FontAwesomeIcon
                          icon={faMapMarkerAlt}
                          className="mt-sm fa-lg"
                        />
                        <div
                          className="e-input-in-wrap"
                          style={{ marginLeft: "18px" }}
                        >
                          <div style={{ width: "100%", display: "grid" }}>
                            <TextBoxComponent
                              name="mlocation-txt"
                              value={meetingLocation}
                              placeholder="Meeting location"
                              cssClass="e-outline"
                              floatLabelType="Auto"
                              data-msg-containerid="locationError"
                              blur={this.handleFocusOut}
                              htmlAttributes={{ maxlength: 255 }}
                            />
                            <div id="locationError" className="error-div" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ErrorBoundary>
            </div>
          </div>
          <div className="col-md-12 pt-sm">
            <div className="row">
              <div className="col-md-5">
                <div className="row1 custom-margin custom-padding-5 material2">
                  <div className="col-md-12">
                    <div className="e-input-group e-float-icon-left">
                      <FontAwesomeIcon
                        icon={faUserPlus}
                        className="mt-sm mr-sm fa-lg pull-left"
                      />
                      <div style={{ display: this.AutoCompleteComponentEnableFlag === true ? 'block' : 'none', width: '100%' }}>
                        <AutoCompleteComponent
                          id="search-attendee"
                          ref={(autocomplete) => {
                            this.searchAttendee = autocomplete;
                            return this.searchAttendee;
                          }}
                          cssClass="e-outline"
                          floatLabelType="Auto"
                          fields={{ value: "nameWithDepartment", Id: "UserId" }}
                          placeholder="Invite Attendee *"
                          select={this.getSelectedAttendee}
                          data-msg-containerid="maError"
                          noRecordsTemplate={this.noRecordsTemplate}
                          filtering={this.handleFilteringByName}
                          dataSource={othersCheckAvailablity}
                          allowCustom={false}
                        />
                      </div>
                      <div style={{ display: this.AutoCompleteComponentEnableFlag === false ? 'block' : 'none', width: '100%' }}>
                        <TextBoxComponent
                          cssClass="e-outline"
                          floatLabelType="Auto"
                          placeholder="Invite Attendee *"
                          enabled={false}
                        />
                      </div>

                      {
                        dencrypt(localStorage.getItem('bulkAttendeeAccess')) === 1 && this.AutoCompleteComponentEnableFlag === true &&
                        <div style={{ position: 'absolute', float: 'right', marginLeft: "67.5%", paddingTop: 2 }}>
                          <Button
                            // color="secondary"
                            variant="contained"
                            onClick={() => this.setState({
                              openBulkAttendeePopup: true,
                              disableAddBulk: true,
                              bulkAtteendeList: [],
                              changedData: !changedData
                            }, () => {
                              setTimeout(() => {
                                this.bulkAttendeeLoaderCount = 0;
                              }, 15)
                            }
                            )}
                            style={{ float: 'right', backgroundColor: "rgb(214, 213, 211)" }}
                          >
                            <span style={{ fontSize: 14, color: "#565656", fontWeight: '100' }}>
                              <FontAwesomeIcon
                                icon={faUserPlus}
                                className="mr-sm fa-lg pull-left" />
                              bulk Attendee
                            </span>
                          </Button>
                        </div>
                      }
                    </div>
                  </div>
                </div>
                <div id="maError" className="error-div pl-xlg" />
              </div>

              {/* Add other attendee with comma  separated */}
              <div className="col-md-5">
                <div className="row1 custom-margin custom-padding-5 material2">
                  <div className="col-md-12 ">
                    <div className="e-input-group e-float-icon-left">
                      <FontAwesomeIcon
                        icon={faUserCircle}
                        className="mt-sm fa-lg"
                      />
                      <div
                        className="e-input-in-wrap"
                        style={{ marginLeft: "14px" }}
                      >
                        <TextBoxComponent
                          onChange={this.handleChange}
                          placeholder="Other external attendees(Multiple email can be added using comma)"
                          cssClass="e-outline"
                          floatLabelType="Auto"
                          name="motherAtt-txt"
                          value={otherAttendees}
                          htmlAttributes={{ maxlength: 255 }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>

          <div
            className="col-md-4 pull-right"
            style={{ position: "relative", right: "35px" }}
          >
            <div className="text-right">
              <span className="mr-lg">
                <FontAwesomeIcon icon={faSquare} className="legend_available" />{" "}
                Available
              </span>
              <span className="mr-lg">
                <FontAwesomeIcon icon={faSquare} className="legend_busy" /> Busy
              </span>
              <span className="mr-lg">
                {" "}
                <FontAwesomeIcon icon={faSquare} className="legend_break" /> On
                Break
              </span>
              <span>
                {" "}
                <FontAwesomeIcon icon={faSquare} className="legend_leave" />
                On Leave
              </span>
            </div>
          </div>

          <div className="schedule-control-section">
            {
              this.bulkAttendeeLoaderCount < 2 ?
                <div className="col-lg-121 control-section" style={{ display: !this.state.bulkAttendeeLoader ? 'block' : 'none' }}>
                  <div id="maError" className="error-div pl-xlg" />
                  <div
                    id="innerComponent"
                    className="control-wrapper"
                    style={{ width: "94.5%", margin: "auto" }}
                  >
                    <ErrorBoundary>
                      <ScheduleComponent
                        resourceHeaderTemplate={this.resourceHeaderTemplate}
                        ref={(t) => {
                          this.scheduleObjInt = t;
                          return this.scheduleObjInt;
                        }}
                        delayUpdate="true"
                        cssClass="schedule-cell-dimension"
                        width="100%"
                        workHours={{
                          highlight: true,
                          start: this.defWorkHourStart,
                          end: this.defWorkHourEnd,
                        }}
                        workDays={this.workDays}
                        selectedDate={meetingDate}
                        currentView="TimelineDay"
                        eventSettings={{
                          dataSource: this.data,
                          enableTooltip: true,
                          tooltipTemplate: this.template,
                          template: this.eventTemplate,
                        }}
                        timeScale={{
                          enable: true,
                          majorSlotTemplate: this.majorSlotTemplate,
                          interval: 60,
                          slotCount: 1,

                        }}
                        showTimeIndicator={false}
                        group={{ resources: ["Attendee", "MeetingRoom"] }}
                        showHeaderBar={false}
                        popupOpen={this.onPopupOpen}
                        dataBound={this.onDataBound}
                        id='meetingroomAttendeeGrid'
                      >
                        <ResourcesDirective>
                          <ResourceDirective
                            field="ProjectId"
                            title="Show Attendee"
                            name="Attendee"
                            allowMultiple={false}
                            dataSource={this.projectData}
                            textField="text"
                            idField="id"
                            colorField="color"
                          />
                          <ResourceDirective
                            field="TaskId"
                            title="Meeting Room"
                            name="MeetingRoom"
                            allowMultiple
                            dataSource={this.categoryData}
                            textField="name"
                            idField="id"
                            groupIDField="groupId"
                            colorField="color"
                          />
                        </ResourcesDirective>
                        <ViewsDirective>
                          <ViewDirective option="TimelineDay" />
                          <ViewDirective option="TimelineWeek" />
                          <ViewDirective option="TimelineWorkWeek" />
                          <ViewDirective option="TimelineMonth" />
                        </ViewsDirective>
                        <Inject
                          services={[
                            TimelineViews,
                            TimelineMonth,
                            Agenda,
                            Resize,
                            DragAndDrop,
                          ]}
                        />
                      </ScheduleComponent>
                    </ErrorBoundary>
                  </div>
                </div>
                :
                this.bulkAttendeeLoaderCount > 2 &&
                <ProgressBar />
            }
          </div>
        </div>
        <div>
          <Dialog open={openBulkAttendeePopup}
            fullWidth
            onEntered={this.handleEntered}
            maxWidth="md"

            disableEscapeKeyDown
          >
            <DialogTitle>  <span className='pl-md'> Add Bulk Attendee </span>
            </DialogTitle>
            <DialogContent style={{ marginTop: -10 }}>
              <ErrorBoundary>
                <div className='col-lg-12'>
                  <TextBoxComponent
                    style={{ minHeight: '100px' }}
                    // onChange={(e) => {
                    //   // this.handleChangeBulkAttendee(e);
                    //   // setTimeout(() => {
                    //   //   this.onInput();
                    //   // }, 1000)
                    // }}
                    ref={(scope) => {
                      this.textareaObj = scope;
                    }}
                    placeholder="Add bulk attendees"
                    cssClass="e-outline"
                    floatLabelType="Auto"
                    name="bulkAtt-txt"
                    multiline
                    value={bulkAtteendeList.toString()}
                    inputProps={{
                      required: true,
                      minLength: 4,
                    }}
                    input={(e) => this.onInput(e)}
                  // htmlAttributes={{ maxlength: 2000 }}
                  />

                  {
                    invalidAttendees.length > 0 &&
                    // !disableAddBulk && invalidAttendees.length > 0 && 
                    <>
                      <div className='mt-sm pb-sm'> <b>Invalid Employee Code/Email</b></div>
                      <div style={{ maxHeight: 200, overflowY: 'auto', border: "1px solid #C2C2C2", borderRadius: '4px' }}>
                        <BulkAttendeeBox selectedEmpCodes={this.selectedInvalidEmpCodes} empCodes={invalidAttendees} backgroundColor={"#F94F5E"} color={'#fff'} disabled={true} />
                      </div>
                    </>
                  }
                  {
                    // !disableAddBulk && 
                    validAttendees.length > 0 &&
                    <>
                      <div className='mt-sm pb-sm'> <b>Valid Employee Code/Email</b></div>

                      <div style={{ maxHeight: 200, overflowY: 'auto', border: "1px solid #C2C2C2", borderRadius: '4px' }}>
                        <BulkAttendeeBox selectedEmpCodes={this.selectedEmpCodes} empCodes={validAttendees} backgroundColor={"green"} color={'#fff'} disabled={true} />
                      </div>
                    </>
                  }


                  <div id="bulkAttendeeError" className="error-div" />
                  <div className="error-div mt-sm" >Note: Multiple employee code can be added using comma eg: CHINCP00000, CHINOP00000, FBINOP00000 / fname.lname@domain.com </div>
                </div>
              </ErrorBoundary>
            </DialogContent>
            <DialogActions>
              <div className='row' style={{ marginRight: 20 }}>
                {/* <div className='pr-sm'> */}
                <ButtonComponent
                  className="e-control mr-sm"
                  onClick={this.closePopupDialog}>
                  Cancel
                </ButtonComponent>

                <ButtonComponent
                  data-ripple="true"
                  onClick={this.handleValidateBulkAttendee}
                  disabled={!disableAddBulk}
                  className='mr-sm'
                  style={{ backgroundColor: !disableAddBulk ? "rgb(214, 213, 211)" : "#f94f5e", color: !disableAddBulk ? "#000" : "#fff" }}
                >
                  &nbsp; Validate &nbsp;
                </ButtonComponent>
                <ButtonComponent
                  style={{ backgroundColor: disableAddBulk ? "rgb(214, 213, 211)" : "#f94f5e", color: disableAddBulk ? "#000" : "#fff" }}
                  data-ripple="true"
                  onClick={this.handleAddBulkAttendee}
                  disabled={disableAddBulk}
                >
                  &nbsp;&nbsp; Add &nbsp;&nbsp;
                </ButtonComponent>
                {/* </div> */}
              </div>
            </DialogActions>
          </Dialog>
        </div>
        {timeScalePopUp && <PartialMeetingAttendeeTimer getAttendeeStatus={this.getAttendeeStatus} markFullAttendee={this.closeButton} closePopUp={this.closeTimerPopUp} uid={this.state.uId} />}

      </div >
    );
  }
  selectedEmpCodes = (empCode, type) => {
    const { validAttendees, changedData } = this.state;
    let list = [];
    list = validAttendees;

    if (type === 'remove') {
      list = empCode;
    } else {
      list.push(empCode[empCode.length - 1]);
    }
    this.setState({
      validAttendees: list,
      disableAddBulk: true,
      changedData: !changedData
    })
  }
  selectedInvalidEmpCodes = (empCode, type) => {
    const { invalidAttendees, changedData } = this.state;
    let list = [];
    list = invalidAttendees;
    if (type === 'remove') {
      list = empCode;
    } else {
      list.push(empCode[empCode.length - 1]);
    }
    this.setState({
      invalidAttendees: list,
      // disableAddBulk:true,
      changedData: !changedData
    })
  }
}



const mapStateToProps = ({
  meetingbooking,
  auth,
  meetingcategory,
  meetingmaster,
}) => {
  const {
    authUser,
    otherUser,
    otherUserAccess,
    checkOtherUserAvailability,
  } = auth;
  const { categoryName } = meetingcategory;
  const {
    meetingTypeId,
    meetingId,
    meetingSetterId,
    meetingAttendee,
    attendeeOnBreak,
    meetingRoomsId,
    chairPersonId,
    meetingDate,
    meetingStartTime,
    meetingEndTime,
    otherAttendees,
    meetingStatus,
    isDigitalMeeting,
    timeZoneId,
    subCatId,
    meetingmode,
    busyUsers,
    meetingPopOpen,
    meetingLocation,
    bulkAttendees,
    templateId,
    meetingWebLink,
    domainName,
    meetingWeblinkType
  } = meetingbooking;
  const { othersCheckAvailablity } = meetingmaster;
  return {
    authUser,
    meetingTypeId,
    meetingId,
    meetingSetterId,
    meetingAttendee,
    attendeeOnBreak,
    meetingRoomsId,
    chairPersonId,
    meetingDate,
    meetingStartTime,
    meetingEndTime,
    otherAttendees,
    timeZoneId,
    meetingStatus,
    isDigitalMeeting,
    meetingmode,
    busyUsers,
    meetingPopOpen,
    otherUser,
    otherUserAccess,
    categoryName,
    othersCheckAvailablity,
    checkOtherUserAvailability,
    subCatId,
    meetingLocation,
    bulkAttendees,
    templateId,
    meetingWebLink,
    domainName,
    meetingWeblinkType
  };
};
MeetingRoomAttendee.propTypes = {
  authUser: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  meetingTypeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  meetingSetterId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  meetingId: PropTypes.number,

  // eslint-disable-next-line
  meetingAttendee: PropTypes.array,
  // eslint-disable-next-line
  attendeeOnBreak: PropTypes.array,
  // eslint-disable-next-line
  meetingRoomsId: PropTypes.array,
  // eslint-disable-next-line
  meetingDate: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  // eslint-disable-next-line
  meetingStartTime: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  // eslint-disable-next-line
  meetingEndTime: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.instanceOf(Date),
  ]),
  timeZoneId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  meetingmode: PropTypes.string,
  // eslint-disable-next-line  


  busyUsers: PropTypes.array,
  meetingPopOpen: PropTypes.bool,
  isDigitalMeeting: PropTypes.bool,
  otherUser: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  otherUserAccess: PropTypes.string,
  categoryName: PropTypes.string,
  // eslint-disable-next-line
  othersCheckAvailablity: PropTypes.array,
  checkOtherUserAvailability: PropTypes.string,
  subCatId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  meetingLocation: PropTypes.string,
  otherAttendees: PropTypes.string,
  bulkAttendees: PropTypes.string,
};
MeetingRoomAttendee.defaultProps = {
  authUser: "",
  meetingId: 0,
  meetingSetterId: 0,
  meetingTypeId: 0,
  meetingAttendee: [],
  attendeeOnBreak: [],
  meetingRoomsId: [],
  timeZoneId: 0,
  meetingmode: "",
  busyUsers: [],
  meetingPopOpen: false,
  isDigitalMeeting: false,
  otherUser: 0,
  otherUserAccess: "",
  categoryName: "",
  othersCheckAvailablity: [],
  checkOtherUserAvailability: "",
  subCatId: "",
  meetingLocation: "",
  otherAttendees: "",
  bulkAttendees: "",
};
export default connect(mapStateToProps, {
  updateMeetingBookingState,
})(withStyles(useStyles)(MeetingRoomAttendee));
