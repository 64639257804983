/* Author : Prashant Waphare
 * Date : 01-06-2020
 * Description: This file use for show Absconding employee List. (Absconding Manager componant)
 */
import * as React from "react";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Filter,
  Inject,
  VirtualScroll,
  Sort,
  Page,
} from "@syncfusion/ej2-react-grids";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import "../../../app/containers/tmlc/tmlc.css";
import "../../../../src/components/separation/resignationcomponent/resignationcomponent.css"
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { connect } from "react-redux";
import { Button, Dialog } from "@material-ui/core";
import { Redirect } from "react-router-dom";
import {
  DialogContent,
  DialogActions,
  DialogTitleTMLC
} from "../../customdialog/customdialog";
import IconButton from '@material-ui/core/IconButton';
import ErrorBoundary from "../../error/errorboundary";
import CloseIcon from '@material-ui/icons/Close';
import {
  // getDisplayDateFormat,
  encrypt,
  getDate,
  // decodeString,
  encodeString
} from "../../../app/other/commonfunction";
import { setMeetingCategory } from '../../../redux/actions/meetingcategoryaction';
import EmployeeNameTemplate from "../employeeNameTemplate";
import {DateFilterformat} from '../../../../src/config/config';
import {
  getGridColumn,
  EMPLOYEE_NAME,
  COMMENT,
  ACTION,
  JOINING_DATE,
  REPORTED_ON,
  DATE_OF_ABSCONDING,
  REMARK
} from "../../../app/other/gridcolumns";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { submitAbscondingData } from "../../../redux/actions/entryexit/separationaction"
import { library } from '@fortawesome/fontawesome-svg-core'
import separetionServices from "../../../../src/services/entryexit/separationServices";
import { AllDateFormat } from "../allcommoncomponents";
import { EMPLOYEE_PROFILE } from "../../../redux/constant/meetingcategoryconstant";
import { faCommentAlt, faHandshake, faCommentAltLines, faReply, faEye, faPlayCircle } from '@fortawesome/pro-light-svg-icons'
library.add(faCommentAlt, faHandshake, faReply, faEye, faPlayCircle);

class AbscondingManagerGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: null,
      actionState: true,
      openCommentBox: false,
      viewCommentList: false,
      commentValue: "",
      abscondingEmpId: "",
      actionType: "",
      commentList: [],
      changedData: false,
      abscondingReportedBackDate: new Date(),
      abscondingExpectedDate: new Date(),
    };
    this.height =
      (window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight) - 188;
    this.width =
      (window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth) - 100;
    this.dReady = false;
    this.dtTime = false;
    this.isDataBound = false;
    this.isDataChanged = true;
    this.dropSlectedIndex = null;
    this.check = {
      type: "CheckBox",
    };
    this.select = {
      persistSelection: true,
      type: "Multiple",
      checkboxOnly: true,
    };
    this.Filter = {
      type: "Menu",
    };
    this.pageOptions = {
      pageSize:
        (window.innerHeight ||
          document.documentElement.clientHeight ||
          document.body.clientHeight) > 700
          ? 10
          : 6,
    };
    this.name = {
      type: "CheckBox",
    };
    this.employeeNameTemplate = this.employeeNameTemplate.bind(this);
    this.navigateToAddBasicInformation = this.navigateToAddBasicInformation.bind(
      this
    );    
  }

  componentDidMount() {
    setTimeout(() => {
      const ele = document.getElementsByClassName("e-headercelldiv");
      for(let i in ele) {
        if(!isNaN(parseInt(i, 10))){
          let index = parseInt(i, 10);
          if (
            ele[index].innerText === JOINING_DATE ||
            ele[index].innerText === REPORTED_ON ||
            ele[index].innerText === DATE_OF_ABSCONDING ||
            ele[index].innerText === ACTION ||
            ele[index].innerText === COMMENT
          ) {
            ele[index].classList.add("grid-center-header");
          } else {
            ele[index].classList.add("grid-left-header");
          }
        }
      }
    }, 1000);
  }
  onQueryCellInfoPreJoining = (args) => {
    const { cell, column } = args;
    const { field } = column;
    if (field === "JoiningDate" || field === "reportedOn" || field === "dateOfAbsconding" || field === "comment" || field === 'action') {
      cell.setAttribute(
        "style",
        "font-size:14px !important; text-align: center !important"
      );
    } else {
      cell.setAttribute(
        "style",
        "font-size:14px !important; text-align: left !important; padding-left:18px !important"
      );
    }
  };

  onDataBound() {
    clearTimeout(this.clrIntervalFun);
    clearInterval(this.intervalFun);
    this.dtTime = true;
  }

  mgrRemarks = (props) => {
    const { remarks } = props;
    return (
      <div>
        <TooltipComponent
          content={remarks.replace(/(?:\r\n|\r|\n)/g, '<br>')}
          position="BottomCenter"
          isSticky={remarks.length > 200 ? true : false}
          cssClass='customtooltip'
          opensOn="Click"
          className="pointer-cursor"
        >
          <span
            style={{
              fontSize: "14px",
              display: "block",
              width: "100px",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {remarks}
          </span>
        </TooltipComponent>
      </div>
    );
  }
  commentTemplate = (props) => {
    const { abscUid, commentsUpdStatus } = props;
    const clr = commentsUpdStatus === 'Y' ? 'green' : 'gray';

    return (
      <div className="row" style={{ paddingLeft: '30px', marginTop: '-20px' }}>
        <div className="col-4">
          <TooltipComponent className="pointer-cursor" content="Add Comments" position="BottomCenter" isSticky={false} cssClass='customtooltip'>
            <FontAwesomeIcon icon={faCommentAltLines} style={{ fontSize: "20px" }} onClick={(e) => {
              this.addCommentOpen(abscUid, "add")
            }} />
          </TooltipComponent>
        </div>
        <div className="col-4">
          <TooltipComponent className="pointer-cursor" content="View Comments" position="BottomCenter" isSticky={false} cssClass='customtooltip'>
            <FontAwesomeIcon icon={faEye} style={{ fontSize: "20px", color: clr }} onClick={(e) => this.viewCommentList(abscUid)} />
          </TooltipComponent>
        </div>
      </div>
    );
  };
  actionToggle = () => {
    const { actionState } = this.state;
    this.setState({ actionState: !actionState });
  };

  action = (props) => {
    const { abscUid, employeeStatus, clearanceInitiated, acknowledgebymgr } = props;
    if (clearanceInitiated === 'Y') {
      return (
        <div>
          <TooltipComponent
            closeOn="Click"
            content="Clearance Initiated"
            position="BottomCenter"
            isSticky={false}
            cssClass='customtooltip'

          >
            <FontAwesomeIcon icon={faPlayCircle} style={{ fontSize: "20px", color: 'green' }} />
          </TooltipComponent>
        </div>
      );
    } else if (acknowledgebymgr === "1") {
      return (
        <div>
          <TooltipComponent
            closeOn="Click"
            content="Acknowledged For Employee Reported Back"
            position="BottomCenter"
            isSticky={false}
            cssClass='customtooltip'>
            <FontAwesomeIcon icon={faHandshake} style={{ fontSize: "20px", color: "green" }} />
          </TooltipComponent>
        </div>
      );
    }
    else if (employeeStatus === 'Reported - HR') {
      return (
        <div>
          <TooltipComponent
            closeOn="Click"
            content="Acknowledge For Employee Reported Back"
            position="BottomCenter"
            isSticky={false}
            cssClass='customtooltip'
            className="pointer-cursor"
          >
            <FontAwesomeIcon icon={faHandshake} style={{ fontSize: "20px" }} onClick={(e) => this.acknowledge(abscUid)} />
          </TooltipComponent>
        </div>
      );
    } else if (employeeStatus === 'Reported - Manager') {
      return (
        <div>
          <TooltipComponent
            closeOn="Click"
            content="Employee Reported Back"
            position="BottomCenter"
            isSticky={false}
            cssClass='customtooltip'>
            <FontAwesomeIcon icon={faReply} style={{ fontSize: "20px", color: 'green' }} />
          </TooltipComponent>
        </div>
      )
    } else if (employeeStatus === 'Absconded') {
      return (
        <div>
          <TooltipComponent
            closeOn="Click"
            content="Employee Reported Back"
            position="BottomCenter"
            isSticky={false}
            cssClass='customtooltip'
            className="pointer-cursor">
            <FontAwesomeIcon icon={faReply} style={{ fontSize: "20px" }} onClick={(e) => this.addCommentOpen(abscUid, "back")} />
          </TooltipComponent>
        </div>
      )
    } 
    // else {
      // Commented during sonarlint
    // }
  }
  acknowledge = (abscUid) => {
    const { authUser } = this.props;
    const data = {
      userId: parseInt(authUser),
      abscId: abscUid,
      type: "acknwByMgr",
      communicateData: '1'
    }
    this.props.submitAbscondingData(data);
  }
  joiningDateFormat = (props) => {
    const { joiningDate } = props;
    return (
      <AllDateFormat date={joiningDate} />
    );
  };
  reportedOnDateFormat = (props) => {
    const { lastReportedDate } = props;
    return (
      <AllDateFormat date={lastReportedDate} />
    );
  };
  abscondingDateFormat = (props) => {
    const { absondingDate } = props;
    return (
      <AllDateFormat date={absondingDate} />
    );
  };

  navigateToAddBasicInformation = async (args) => {
    const { changedData } = this.state;
    try {
      localStorage.setItem('last_meeting_category', localStorage.getItem('meeting_category'))
      localStorage.setItem("meeting_category", encodeString(EMPLOYEE_PROFILE));
      const path = `/onboard?empId=${encrypt(args.employeeId)}`;
      this.setState({
        redirect: path,
        changedData: !changedData,
      }, () => this.props.setMeetingCategory(EMPLOYEE_PROFILE));
    } catch (error) {
      console.log("error", error);
    }
  };

  employeeNameTemplate(props) {
    return (
      <EmployeeNameTemplate
        props={props}
        data="test"
        navigateToAddBasicInformation={this.navigateToAddBasicInformation}
      />
    );
  }
  viewCommentList = (abscId) => {
    const { authUser } = this.props;
    try {
      const getAbscondingListDetail = Promise.resolve(
        separetionServices.getAbscondingListDetail(authUser, "comments", abscId)
      );
      getAbscondingListDetail.then((result) => {
        if (result !== null) {
          this.setState({ commentList: result });
        } else {
          this.data = "error";
        }
      });
    } catch (e) {
      // eslint-disable-next-line
      console.log(e);
    }
    this.setState({ viewCommentList: true, abscondingEmpId: abscId });
  }
  closeCommentList = (abscId) => {
    this.setState({ viewCommentList: false, abscondingEmpId: "" });
  }
  addCommentClose = () => {
    this.setState({ openCommentBox: false, commentValue: "", abscondingEmpId: "", abscondingReportedBackDate: new Date(), abscondingExpectedDate: new Date() });
  }
  addCommentOpen = (abscId, type) => {
    this.setState({ openCommentBox: true, abscondingEmpId: abscId, actionType: type });
    setTimeout(() => {
      this.textareaObj.addAttributes({ rows: 4 });
    }, 100)
  }
  submitAction = () => {
    const { authUser } = this.props;
    const { abscondingEmpId, commentValue } = this.state;
    const data = {
      userId: parseInt(authUser),
      abscId: abscondingEmpId,
      type: "repByMgr",
      communicateData: `${commentValue}`,
    }
    this.props.submitAbscondingData(data);
  }
  submitComment = () => {
    const { authUser } = this.props;
    const { actionType, abscondingReportedBackDate, abscondingExpectedDate } = this.state;
    document.getElementById("addCommentErr").innerHTML = "";
    if (this.state.commentValue === "" || this.state.commentValue === null) {
      document.getElementById("addCommentErr").innerHTML = "Please enter comment";
      return;
    }
    if (actionType === "add") {
      const data = {
        userId: parseInt(authUser),
        abscId: this.state.abscondingEmpId,
        type: "comments",
        communicateData: `${this.state.commentValue}`,
        reportedbackDate: null,
        expectedJoiningDate: null
      }
      this.props.submitAbscondingData(data);
    } else if (actionType === "back") {
      const data = {
        userId: parseInt(authUser),
        abscId: this.state.abscondingEmpId,
        type: "back",
        communicateData: `${this.state.commentValue}`,
        reportedbackDate: getDate(new Date(abscondingReportedBackDate)),
        expectedJoiningDate: getDate(new Date(abscondingExpectedDate))
      }
      this.props.submitAbscondingData(data);
      this.submitAction();
    }

    this.addCommentClose();
  }
  getCommentValue = (e) => {
    document.getElementById("addCommentErr").innerHTML = "";
    if (e.value === "") {
      this.setState({ commentValue: "" });
      document.getElementById("addCommentErr").innerHTML = "Please enter comment";
      return;/**NOSONAR */
    } else {
      document.getElementById("addCommentErr").innerHTML = "";
      this.setState({ commentValue: e.value });
    }
  }
  limitCaracter = (e) => {
    let word = "";
    let addressCount = 0;
    word = e.value;
    addressCount = word.length;
    document.getElementById("commentLimitErr").innerHTML = addressCount + "/500";
  }
  render() {
    const { redirect, abscondingReportedBackDate, abscondingExpectedDate } = this.state;
    const { abscondingData } = this.props;

   
    if (redirect !== null) {
      return <Redirect to={redirect} />;
    }
    var widthColumn = 0;
    var finalWidth = 0;
    // eslint-disable-next-line
    getGridColumn("abscondingManager").forEach((data) => {
      widthColumn = widthColumn + data.width;
    });
    if (widthColumn <= this.width) {
      finalWidth = widthColumn + 20;
    } else {
      finalWidth = this.width
    }
    return (
      <div
        className="row resignation-manager-hr-grid-height"
        style={{
          // height: "828px",
          paddingRight: '15px', paddingLeft: '15px'
        }}
      >
        {/* <div>ABSCONDING REPORT STATUS</div> */}
        <div>
          <div className="row-xs-12 row-sm-12 row-lg-12 row-md-12">
            <div>
              <GridComponent
                className="grid-head-custome"
                id="overviewgrid"
                allowPaging
                pageSettings={this.pageOptions}
                dataSource={abscondingData}
                enableHover={false}
                enableVirtualization={false}
                rowHeight={30}
                height={this.height}
                ref={(g) => {
                  this.gridInstance = g;
                }}
                queryCellInfo={this.onQueryCellInfoPreJoining}
                dataBound={this.onDataBound}
                filterSettings={this.Filter}
                allowFiltering
                allowSorting
                allowSelection
                selectionSettings={this.select}
                width={`${finalWidth}`}
                allowTextWrap={true}
              >
                <ColumnsDirective>
                  {getGridColumn("abscondingManager").map((item) => {
                    switch (item.colName) {
                      case EMPLOYEE_NAME:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.employeeNameTemplate}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                            filter={this.Filter}
                          />
                        );
                      case JOINING_DATE:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.joiningDateFormat}
                            // allowFiltering={false}
                            filter={this.check}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                            format={DateFilterformat}
                          />
                        );
                      case REPORTED_ON:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.reportedOnDateFormat}
                            // allowFiltering={false}
                            filter={this.check}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                            format={DateFilterformat}

                          />
                        );
                      case DATE_OF_ABSCONDING:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.abscondingDateFormat}
                            // allowFiltering={false}
                            filter={this.check}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                            format={DateFilterformat}

                          />
                        );
                      case COMMENT:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.commentTemplate}
                            allowFiltering={false}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                          />
                        );
                      case REMARK:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.mgrRemarks}
                            allowFiltering={false}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                          />
                        );
                      case ACTION:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.action}
                            allowFiltering={false}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                          />
                        );
                      default:
                        return (
                          <ColumnDirective
                            key={item.field}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                            filter={this.check}
                          />
                        );
                    }
                  })}
                </ColumnsDirective>
                <Inject services={[Page, Filter, VirtualScroll, Sort]} />
              </GridComponent>
            </div>
          </div>
          {/* <AddCommentDialog addCommentClose = {this.addCommentClose} getCommentValue = {this.getCommentValue} 
                            addCommentOpen={this.addCommentOpen}
                            limitCaracter = {this.limitCaracter} submitComment={this.submitComment} openCommentBox={this.state.openCommentBox}/> */}
          <div style={{ width: "350px" }}>
            <Dialog
              aria-labelledby="uploading-title"
              open={this.state.openCommentBox}
              fullWidth
              width="1000"
            // PaperComponent={PaperComponent}
            >
              <DialogTitleTMLC id="uploading-title"
                onClose={this.handleClose}
              >
                {
                  this.state.actionType === "add" ?
                    'Add Comments'
                    :
                    'Add Comment For Employee Reported Back'
                }
                <div style={{ float: "right" }}>
                  <IconButton style={{ color: "white", marginRight: "-5px", marginTop: "-7px" }} aria-label="close" onClick={this.addCommentClose}>
                    <CloseIcon />
                  </IconButton>
                </div>
              </DialogTitleTMLC>
              <ErrorBoundary>
                <DialogContent dividers>
                  <div>
                    {
                      this.state.actionType !== "add" &&
                      <div className="row card-layout">
                        <div className="col-xs-2 col-sm-2 col-lg-6 col-md-2" style={{ paddingBottom: '16px' }}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker style={{ width: '100%' }}
                              id="lwdCompanyRequest"
                              name="lwdCompanyRequest"
                              format="dd-MMM-yyyy"
                              KeyboardButtonProps={{
                                'aria-label': 'change date',
                              }}
                              value={abscondingReportedBackDate !== '' ? /**NOSONAR */ new Date(abscondingReportedBackDate) : new Date()}
                              onChange={(date) => this.setState({ abscondingReportedBackDate: date })}
                              TextFieldComponent={this.TextFieldComponent}
                              inputVariant="outlined"
                              variant="inline"
                              InputAdornmentProps={{ position: "start" }}
                              autoOk
                              maxDate={new Date()}
                              // disabled={employeeStatus === "Absconded" ? true:false}
                              label="Reported Back Date *"
                            />
                          </MuiPickersUtilsProvider>
                        </div>
                        <div className="col-xs-2 col-sm-2 col-lg-6 col-md-2" style={{ paddingBottom: '16px' }}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker style={{ width: '100%' }}
                              id="expectedDate"
                              name="expectedDate"
                              format="dd-MMM-yyyy"
                              KeyboardButtonProps={{
                                'aria-label': 'change date',
                              }}
                              value={abscondingExpectedDate !== '' ? /**NOSONAR */ new Date(abscondingExpectedDate) : new Date()}
                              onChange={(date) => this.setState({ abscondingExpectedDate: date })}
                              TextFieldComponent={this.TextFieldComponent}
                              inputVariant="outlined"
                              variant="inline"
                              InputAdornmentProps={{ position: "start" }}
                              autoOk
                              minDate={new Date()}
                              // disabled={employeeStatus === "Absconded" ? true:false}
                              label="Expected Joining Date *"
                            />
                          </MuiPickersUtilsProvider>
                        </div>
                      </div>
                    }
                    <div className="row card-layout">
                      <div className="col-xs-2 col-sm-2 col-lg-12 col-md-2 ">
                        <TextBoxComponent
                          ref={(scope) => { this.textareaObj = scope; }}
                          id="addComment"
                          name="addComment"
                          placeholder="Comment*"
                          cssClass="e-outline"
                          multiline
                          htmlAttributes={{ maxlength: 500 }}
                          floatLabelType="Auto"
                          change={(e) => this.getCommentValue(e)}
                          input={(e) => this.limitCaracter(e)}
                        />
                      </div>
                    </div>
                    <div>
                      <div style={{ float: "left" }} id="addCommentErr" className="col-11 e-input-group e-float-icon-left error-div" />
                      <div style={{ float: "right" }} id="commentLimitErr" className="error-div" />
                    </div>
                  </div>
                </DialogContent>
                <DialogActions style={{ paddingRight: "15px" }}>
                  <Button color="default" variant="contained" onClick={this.addCommentClose}> Cancel </Button>
                  <Button color="primary" variant="contained" onClick={this.submitComment} > Save </Button>
                </DialogActions>
              </ErrorBoundary>
            </Dialog>
          </div>
          <div style={{ width: "350px" }}>
            <Dialog
              aria-labelledby="uploading-title"
              open={this.state.viewCommentList}
              fullWidth
              width="1000"
            // PaperComponent={PaperComponent}
            >
              <DialogTitleTMLC id="uploading-title"
                onClose={this.handleClose}
              >
                View Comments
                <div style={{ float: "right" }}>
                  <IconButton style={{ color: "white", marginRight: "-5px", marginTop: "-7px" }} aria-label="close" onClick={this.closeCommentList}>
                    <CloseIcon />
                  </IconButton>
                </div>
                {/* <IconButton style={{ color: "white", position: "absolute", marginLeft: "713px" }} aria-label="close" onClick={this.closeCommentList}>
                  <CloseIcon />
                </IconButton> */}
              </DialogTitleTMLC>
              <ErrorBoundary>
                <DialogContent dividers>
                  {
                    this.state.commentList.length > 0 ? (
                      this.state.commentList.map((item, index) => (
                        <div id={index} className="comment-main-content">
                          <div className="comment-main-content-header">
                            {item.AddedBy}:{" "}{item.AddedOn} {item.empReportedBack}
                          </div>
                          <div className="comment-main-content-content">

                            {item.Comments}
                          </div>
                        </div>
                      )))
                      :
                      <div> Comment not available</div>
                  }
                </DialogContent>
                <DialogActions style={{ paddingRight: "20px" }}>
                  <Button color="default" variant="contained" onClick={this.closeCommentList}> Close </Button>
                </DialogActions>
              </ErrorBoundary>
            </Dialog>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ auth }) => {
  const { authUser } = auth;
  return { authUser };
};
export default connect(mapStateToProps, { submitAbscondingData, setMeetingCategory })(AbscondingManagerGrid);

