/**
 * Author Name :  Sanket Bari
 * Created Date: 05 March 2024
 * Modified Date: - .
 * Last Modified Name: - .
 */
// Last Modified: -
 
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import "../../app/layout/tibrlayout/tibrlayout.css";
import LnAService from '../../services/lna/lnaservice';
import { encrypt } from '../../app/other/commonfunction';
import { VIEWLEAVEBALACE } from '../../redux/constant/meetingcategoryconstant';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

// import '../../app/layout/okrratinglayout/okrrating.css'
 
// Functional component
const Navigation = ({ activeTab, setActiveTab, secActiveTab, setSecActiveTab, authUser }) => {
 
  const [leaveData, setLeaveData] = useState({});
  const history = useHistory();
 
  const getMasterData = async (val) => {
    try {
 
      let onLeaveData = await LnAService.getOnLeaveDataNew(authUser, activeTab, 'summary');
      setLeaveData(onLeaveData.Result);
    } catch (e) {
      console.log("error", e)
 
    }
  }
  useEffect(() => {
    getMasterData();
  }, [activeTab,authUser]);
 
  //   let onLeaveData=null;
  //   let leaveData = null;
  //   let newResult = [];
 
 
  useEffect(() => {
    // Accessing leaveData after it's updated
  }, [leaveData]);
  

 
 
  const handleTab = (e) => {
    const tabId = e.target.id;
    // setField(e);
    if(tabId === "own"){
      setSecActiveTab("thisWeek");
    }
    setActiveTab(tabId);
  }
 
  const handleSecTab = (e,clickId) => {
 
    const secTabId = e.target.id;
    
    setSecActiveTab(clickId);
     
  }
 
  // const [minwidth, setMinwidth] = useState('12px');
 
  const handleCount = (id) =>{
      let countLength = null;
      if(id === "today"){
        countLength = leaveData[0]?.count.toString().length;
      }
      else if(id === "thisWeek"){
        countLength = leaveData[1]?.count.toString().length;
      }
      else if(id === "nextWeek"){
        countLength = leaveData[2]?.count.toString().length;
      }
      else if(id === "thisMonth"){
        countLength = leaveData[3]?.count.toString().length;
      }
      else if(id === "nextMonth"){
        countLength = leaveData[4]?.count.toString().length; 
      }
      else if(id === "onword"){
        countLength = leaveData[5]?.count.toString().length;
      }
       
        // (countLength <= 2)? setMinwidth('24px'):setMinwidth('50px');
        if (countLength <= 2) {
             return true;
        } else {
             return false;
        }
   
 
  }
 
 
  
  const navigateToLeaveBalance = () => {
    const encryptedViewLeaveBalance = encrypt(VIEWLEAVEBALACE);
    const encryptedAppId = encrypt(1);
    const clickedFromOnLeave = true; // Assuming clickedFromOnLeave is a boolean value

    // Constructing the URL with query parameters
    const queryString = `?id=${encryptedViewLeaveBalance}&appId=${encryptedAppId}&onLeave=${clickedFromOnLeave}`;

    history.push(`/lna${queryString}`);
};
 
 
  return <>
 
    <style>
      {`         
                @media only screen and (min-width: 1280px) and (max-width: 1380px) and (min-height: 570px) and (max-height: 600px) {
                  /* Your styles for screens within the approximate range of 1920x1080 at 150% zoom */
                  .box {
                    width: 150px !important;
                    height: 36px !important;
                  }
                  .secondaryNavNames{
                      height: 20px;
                      color: #939399;
                      font-family: 'Circular TT Book', Arial, sans-serif;
                      font-size:10px;
                  }
                  .counter{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 18px !important;
                    width: 18px !important;
                    font-size:10px !important;
                    background: #F94F5E 0% 0% no-repeat padding-box;
                    border-radius: 10px !important;
                    opacity: 1;
                    color: #FFFFFF;
                    font-family: 'Circular TT Book', Arial, sans-serif;
                  }
                  .secondaryNavNames {
                    height: 14px;
                    color: #939399;
                    font-family: 'Circular TT Book', Arial, sans-serif;
                  }
                  .leaveBalance{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding-right: 40px;
                    color: #F94F5E;
                    font-family: 'Circular TT Book', Arial, sans-serif;
                    font-weight: Bold;
                    font-size: 12px !important;
                   
                  }
                  .secondaryNavNames{
                    height: 14px !important;
                    color: #939399;
                    font-family: 'Circular TT Book', Arial, sans-serif;
                }
                }
                .box{
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    height: 44px;
                    width: 180px;
                    margin-left: 2px;
                    border: .8px solid #DBDBDD;
                    border-radius: 2px;
                    cursor: pointer;
                    flex-shrink: 1;
                    background-color: #F2F2F2;
                }
 
                .secondaryNav{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-top: 70px;
                    padding-left: 18px;
                    flex-shrink: 1;
                }
 
                .secondaryNavBoxes{
                    display: flex;
                    justify-content: space-between;
                    flex-shrink: 1;
                }
 
                .secondaryNavNames{
                    height: 20px;
                    color: #939399;
                    font-family: 'Circular TT Book', Arial, sans-serif;
                }
 
                .counter{
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 24px;
                    width: 36px;
                    background: #F94F5E 0% 0% no-repeat padding-box;
                    border-radius: 12px;
                    opacity: 1;
                    color: #FFFFFF;
                    font-family: 'Circular TT Book', Arial, sans-serif;
                    font-size: 12px;
 
                }
                .secActiveTab{
                  background-color: white;
                  font-weight: bold;
                  color: #000000 !important;
              }
 
                .secActiveTab .secondaryNavNames{
                    background-color: white;
                    font-weight: bold;
                    color: #000000 !important;
                }
 
 
                .navTextColor{
                  color: #000000 !important;
                  font-family: 'Circular TT Book', Arial, sans-serif;
                  font-weight: bold;
                }
 
                .secNavboxContent{
                  display: flex;
                  justify-content: space-around;
                  width: 100%;
                  align-items: center;
                }
 
                .leaveBalance{
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  padding-right: 40px;
                  color: #F94F5E;
                  font-family: 'Circular TT Book';
                  font-weight: 700;
                  font-size: 14px;
                 
                }
 
                .gt{
                  padding-left: 6px;
                  color: #F94F5E;
                  font-size: 24px;
                }
 
                .leavePlan{
                  color: #939399;
                  font-family: 'Circular TT Book', Arial, sans-serif;
                }
 
                #navTableRow {
                  position: absolute;
                  width: 100%;
                  padding-bottom: 5px;
                  border-bottom: 2px solid rgba(0, 0, 0, .1);
              }
 
                @media screen and (max-width: 1280px) and (min-height: 720px) {
                  .box {
                    width: 150px;
                    height: 36px;
                  }
               
                  .secondaryNav {
                    margin-top: 50px;
                  }
 
                  .leaveBalance{
                    padding-right: 10px;
                    font-size: 6px;
                  }
                  
                 
                }
                @media screen and (max-width: 1280px) and (min-height: 633px) {
                  .box {
                    width: 168px;
                    height: 36px;
                  }
               
                  .secondaryNav {
                    margin-top: 50px;
                  }
 
                  .leaveBalance{
                    padding-right: 10px;
                    font-size: 12px;
                  }
                  
                 
                }
      `}
    </style>
 
    <div style={{ position: 'relative' }}>
 
      <tbody>
        <tr style={{ height: '41px', paddingTop: '10px' }} id="navTableRow">
 
          <td style={{ width: 130, textAlign: 'left', paddingLeft: '8px' }} className="buttonTableData ">
            <button
              className={`tab leavePlan ${activeTab === 'own' ? 'activeTab navTextColor' : ''} `}
              id="own"
              type="submit"
              onClick={handleTab}
            > My Leave Plan
            </button>
          </td>
          <td style={{ width: 200 }} className="buttonTableData">
            <button
              className={`tab leavePlan ${activeTab === 'team' ? 'activeTab navTextColor' : ''}`}
              id="team"
              name="dataType"
              type="submit"
              onClick={handleTab}
            >Team Member's Leave Plan
            </button>
          </td>
 
 
        </tr>
      </tbody>
 
      <div className='secondaryNav'>
        <div className='secondaryNavBoxes'>
 
        {activeTab === 'team' && (
              <div
              id="today"
              type="submit"
              className={`box ${secActiveTab === 'today' ? 'secActiveTab' : ''}`}
              onClick={(event) => handleSecTab(event, 'today')}
              onKeyDown={(event) => {if (event.key === 'Enter' || event.key === ' ') { // Check if Enter or Spacebar is pressed
                handleSecTab(event, 'today');
              }}}
 
            >
              <div className='secNavboxContent'>
                <div className='secondaryNavNames'>Today</div>
                <div className='counter' style={{ minWidth: (handleCount('thisWeek') ? '24px' : '50px') }}>{leaveData[0]?.count}</div>
                {/* {leaveData[0]?.count} */}
              </div>
            </div>
 
        )}
 
          <div
            id="thisWeek"
            type="submit"
            className={`box ${secActiveTab === 'thisWeek' ? 'secActiveTab' : ''}`}
            onClick={(event) => handleSecTab(event, 'thisWeek')}
            onKeyDown={(event) => {if (event.key === 'Enter' || event.key === ' ') { // Check if Enter or Spacebar is pressed
              handleSecTab(event, 'thisWeek');
            }}}
 
          >
            <div className='secNavboxContent'>
              <div className='secondaryNavNames'>This Week</div>
              <div className='counter' style={{ minWidth: (handleCount('thisWeek') ? '24px' : '50px') }}>{leaveData[1]?.count}</div>
              {/* {leaveData[0]?.count} */}
            </div>
          </div>
 
 
          <div
            id="nextWeek"
            type="submit"
            className={`box ${secActiveTab === 'nextWeek' ? 'secActiveTab' : ''}`}
            onClick={(event) => handleSecTab( event,'nextWeek')}
            onKeyDown={(event) => {if (event.key === 'Enter' || event.key === ' ') { // Check if Enter or Spacebar is pressed
              handleSecTab(event, 'nextWeek');
            }}}
          >
            <div className='secNavboxContent'>
              <div className='secondaryNavNames'>Next Week</div>
              <div className='counter' style={{ minWidth: (handleCount('nextWeek') ? '24px' : '50px') }}>{leaveData[2]?.count}</div>
            </div>
 
          </div>
          <div
            id="thisMonth"
            type="submit"
            className={`box ${secActiveTab === 'thisMonth' ? 'secActiveTab' : ''}`}
            onClick={(event) => handleSecTab( event,'thisMonth')}
            onKeyDown={(event) => {if (event.key === 'Enter' || event.key === ' ') { // Check if Enter or Spacebar is pressed
              handleSecTab(event, 'thisMonth');
            }}}
          >
            <div className='secNavboxContent'>
              <div className='secondaryNavNames'>This Month</div>
              <div className='counter' style={{ minWidth: (handleCount('thisMonth') ? '24px' : '50px') }}>{leaveData[3]?.count}</div>
            </div>
 
          </div>
          <div
            id="nextMonth"
            type="submit"
            className={`box ${secActiveTab === 'nextMonth' ? 'secActiveTab' : ''}`}
            onClick={(event) => handleSecTab(event, 'nextMonth')}
            onKeyDown={(event) => {if (event.key === 'Enter' || event.key === ' ') { // Check if Enter or Spacebar is pressed
              handleSecTab(event, 'nextMonth');
            }}}
          >
            <div className='secNavboxContent'>
              <div className='secondaryNavNames'>Next Month</div>
              <div className='counter' style={{ minWidth: (handleCount('nextMonth') ? '24px' : '50px') }}>{leaveData[4]?.count}</div>
            </div>
 
          </div>
          <div
            id="onword"
            type="submit"
            className={`box ${secActiveTab === 'onword' ? 'secActiveTab' : ''}`}
            onClick={(event) => handleSecTab( event,'onword')}
            onKeyDown={(event) => {if (event.key === 'Enter' || event.key === ' ') { // Check if Enter or Spacebar is pressed
              handleSecTab(event, 'onword');
            }}}
          >
            <div className='secNavboxContent'>
              <div className='secondaryNavNames'>Onwards</div>
              <div className='counter' style={{ minWidth: (handleCount('onword') ? '24px' : '50px') }}>{leaveData[5]?.count}</div>
            </div>
 
          </div>
        </div>
        <div className='leaveBalance ml-sm' style={{ cursor: 'pointer' }} onClick={navigateToLeaveBalance} onKeyDown={(event) => {if (event.key === 'Enter' || event.key === ' ') { // Check if Enter or Spacebar is pressed
              navigateToLeaveBalance();
            }}}>
          Leave Balance<span className='gt'>&gt;</span>
        </div>
      </div>
    </div>
  </>
};
const mapStateToProps = ({ auth }) => {
  const { authUser } = auth;
  return { authUser};
}; 
export default connect(mapStateToProps, {})(Navigation);