/* Author : Suresh R. Sargam
 * Date : 02-05-2020
 * Description: This file use for Document of the candidate
 * Last Modified by:Amit Kumar
 * Last Modified Date : 04-07-2022
 * Description: Added the File Manager Component.
 */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { AccordionComponent } from "@syncfusion/ej2-react-navigations";
import { connect } from "react-redux";
import { Documents } from "../../document/documents";
import '../checklist/checklist.css'
import { getNotify, encryptMethod, isNameUnique, validateFileName } from "../../../app/other/commonfunction";
import addCandidateService from "../../../services/entryexit/addCandidateService";
import {
  updateDocumentInfo,
  updateDocumentStatus,
  moveDocuments,
  saveBasicInformationInRedux

} from "../../../redux/actions/entryexit/onboardingaction";
import {
  FileManagerComponent,
  Inject,
  NavigationPane,
  DetailsView,
  Toolbar,
} from '@syncfusion/ej2-react-filemanager';
import Upload from "../../document/upload/upload";
import Preview from "../../preview/preview";
import Comments from "../../document/comments/comments";
import { urlConfig } from "../../../config/config";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import {
  NEW_CANDIDATE_UPLOAD,
  EXISTING_CANDIDATE,
  EMPLOYEE_UPLOAD,
  NEW_CANDIDATE,
} from "../../../redux/constant/entryexit/candidateconstant";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSync, faArrowAltToTop,
  faCheckCircle,
  faEye,
  faTimesCircle,
  faPlusCircle,
} from "@fortawesome/pro-light-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { DOCUMENT_MOVEMENT_COMPLETED, ERROR, FILE_LIMIT_EXCEEDED, MAX_FILES_ALLOWED, SAME_FILE_NAME_EXIST, SUCCESS, WARNING, duplicateFileExists } from "../../../assets/constants/constants";
import ConfirmationPopup from "../../../utilities/confirmationpopup";
import { moveCandidatesDocumentsReq } from "../../../redux/middleware/entryexit/onboardingmiddleware";
import ProgressBar from '../../../components/progressbar/progressbar';

library.add(faSync, faArrowAltToTop,
  faCheckCircle,
  faEye,
  faTimesCircle,
  faPlusCircle)

const SAMPLECSS_PREOFFER = `
  #overview_file{
    width:100% !important
  }
  .e-rowcell .lastModifiedv{
    font-size: 13px !important;
  }
`
const SAMPLECSS_PROFILE = `
#overview_file{
  width:99% !important
}
.e-rowcell .lastModifiedv{
  font-size: 13px !important;
}
`
class CheckList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      docInfo: [],
      changeStatus: true,
      refreshId: 0,
      confirmOpen: false,
      approved_status: '',
      currentPath: "\/",
      height: '',
      fileNameWidth: 0,
      dmsID: 0,
      foldId: 0,
      fileDms: 0,
      fileCount: 0,
      files: [],
      loader: false
    };

    // For uploading
    this.selectedFolderId = 0;
    this.folderPath = "";
    this.folders = [];
    this.categoryName = "";
    this.approveStatus = ""
    this.rejectStatus = ""

    // For Preview
    this.files = [];
    this.docName = "";
    this.funcstatusUpdate = this.funcstatusUpdate.bind(this);
    this.expanded = this.expanded.bind(this);
    this.beforeImageLoad = this.beforeImageLoad.bind(this);

    this.onFileSelection = this.onFileSelection.bind(this)
    // this.onCreated = this.onCreated.bind(this)
    this.tabRefreshed = [];
    this.showApprovalRejection = false;
    this.filePath = ''

    // confirmation fields
    this.title = "";
    this.message = "";
    this.handleConfirmNo = this.handleConfirmNo.bind(this);
    this.handleConfirmYes = this.handleConfirmYes.bind(this);
    this.docId = 0;
    this.categoryId = 0;
    this.showComments = false;
    this.commentFor = "1";
    this.approved_status = ""
    this.onSuccess = this.onSuccess.bind(this)
    this.popupClose = this.popupClose.bind(this)
    this.popupOpen = this.popupOpen.bind(this)
    this.totalFiles = 0;
    // columns to show on preoffer and employee profile
    this.preOfferColumn = {
      columns: [
        {
          field: 'name',
          headerText: '&nbsp;&nbsp;File/Folder',
          width: window.innerHeight < 900 ? 200 : 300,
          template: '<span class="fileName">${name}</span>',
        },
        {
          field: 'approveStatus',
          headerText: '&nbsp;&nbsp;Approved',
          width: window.innerHeight < 900 ? 50 : 80,
          template: '<span class="${approveStatus}"><i class="fa fa-check-circle"></i></span>'
        },
        {
          field: 'rejectStatus',
          headerText: '&nbsp;&nbsp;&nbsp;&nbsp;Rejected',
          width: window.innerHeight < 900 ? 50 : 80,
          template: '<span class="${rejectStatus}" id="RejectFile"><i class="fa fa-times-circle"></i><i class="${rejectedComments}" id="eyeRej"></i></span>'
        },
        {
          headerText: '&nbsp;&nbsp;Add Comments',
          width: window.innerHeight < 900 ? 70 : 90,
          template: '<span class="${addComment}"><i class="fa fa-plus-circle"></i><i class="${comments}" id="eyeCmt"></i></span>'
        },
        {
          headerText: '&nbsp;&nbsp;&nbsp;Remove',
          width: window.innerHeight < 900 ? 50 : 80,
          template: '<span class="${removeFile}"><i class="fa fa-trash"></i></span>'
        },
        {
          field: 'size',
          headerText: 'Size',
          width: window.innerHeight < 900 ? 40 : 80,
          template: '<span class="fileName">${size} MB</span>'
        },
        {
          field: '_fm_modified',
          headerText: '&nbsp;&nbsp;Last Modified',
          width: window.innerHeight < 900 ? 80 : 90,
          format: 'd-MMM-y H:mm',
          customAttributes: { class: 'lastModified' },
          // template: '<span class="lastModified">${_fm_modified}</span>'
        }
      ]
    }
    this.employeeProfileColumn = {
      columns: [
        {
          field: 'name',
          headerText: '&nbsp;&nbsp;File/Folder',
          width: window.innerHeight < 900 ? 200 : 300,
          template: '<span class="fileName">${name}</span>',
        },
        {
          headerText: '&nbsp;&nbsp;Add Comments',
          width: window.innerHeight < 900 ? 80 : 110,
          template: '<span class="${addComment}"><i class="fa fa-plus-circle"></i><i class="${comments}" id="eyeCmt"></i></span>'
        },
        {
          headerText: '&nbsp;&nbsp;&nbsp;Remove',
          width: window.innerHeight < 900 ? 80 : 110,
          template: '<span class="${removeFile}"><i class="fa fa-trash"></i></span>'
        },
        {
          field: 'size',
          headerText: 'Size',
          width: window.innerHeight < 900 ? 40 : 80,
          template: '<div><span class="fileSize displayFlex"><span class="align-right">${size}</span> <span>MB</span></span> </div>'
        },
        {
          field: '_fm_modified',
          headerText: '&nbsp;&nbsp;Last Modified',
          width: window.innerHeight < 900 ? 80 : 110,
          format: 'd-MMM-y H:mm',
          customAttributes: { class: 'lastModified' },
        }
      ]
    }
    this.preOfferContextMenuItem = {
      file: ['Open', 'Approve', 'Reject', 'Comment', 'Remove', 'SortBy', 'View', 'Refresh'],
      folder: ['Open', 'SortBy', 'View', 'Refresh'],
      layout: ['SortBy', 'View', 'Refresh'], visible: true,
    }
    this.employeeProfileContextMenuItem = {
      file: ['Open', 'Comment', 'Remove', 'SortBy', 'View', 'Refresh'],
      folder: ['Open', 'SortBy', 'View', 'Refresh'],
      layout: ['SortBy', 'View', 'Refresh'], visible: true,
    }
    this.documentData = 'api/FileManager/'
  }

  async componentDidMount() {
    window.addEventListener("resize", this.getHeight());

    await this.funcgetDocumentInformation(0); // NOSONAR
  }



  passDataToPreoffer = (data) => {
    const { candidateType } = this.props;
    if (candidateType !== EMPLOYEE_UPLOAD) {
      this.props.getDocData(data);
    }
  }

  // TO get the default document structure and also files if uploaded
  // eslint-disable-next-line
  funcgetDocumentInformation(pCategoryId) { // NOSONAR
    const { authUser, candidateType, empid, candidateid, baseCounty, countryType, empType, addressCountryId } = this.props;
    try {
      const data = {
        authUser,
        cand_emp_id: candidateType === EMPLOYEE_UPLOAD ? empid : candidateid,
        doccatid: pCategoryId,
        candtype: candidateType,
      };
      const documentDetails = addCandidateService.getDocuments(data);
      documentDetails
        .then((result) => {
          const { docInfo, changeStatus } = this.state;
          this.passDataToPreoffer(result)
          if (pCategoryId > 0) {
            // eslint-disable-next-line
            docInfo.forEach((item) => {
              if (item.CategoryId === pCategoryId) {
                // eslint-disable-next-line
                item.Data = result[0].Data;
              }
            });
            this.setState({
              docInfo,
              refreshId: pCategoryId,
              changeStatus: !changeStatus,
            });
          } else {
            if ((baseCounty !== '' && countryType !== 'India') || (addressCountryId === 101 && (empType !== 677 || empType !== 747 || empType !== 746 || empType !== 798))) {
              // eslint-disable-next-line
              result.map(item => {
                if (item.CategoryId === 1) {
                  // eslint-disable-next-line
                  item.Data.map((subitem) => {
                    subitem.SendLink = false;
                    subitem.DefSendLink = false;
                  });
                }
              });
            }
            else if (addressCountryId === 101 && (empType === 677 || empType === 747 || empType === 746 || empType === 798)) {
              // eslint-disable-next-line
              result.map(item2 => {
                if (item2.CategoryId === 1) {
                  // eslint-disable-next-line
                  item2.Data.map((subitem) => {
                    if (subitem.Id === 5 || subitem.Id === 6) {
                      subitem.SendLink = true;
                      subitem.DefSendLink = true;
                    }
                  });
                }
              });
            }
            setTimeout(() => {
              this.setState({
                docInfo: result,
                changeStatus: !changeStatus
              });
            }, 500)

          }
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.log(error);
        });
    } catch (err) { }
  }

  // To update the status as Approved, Rejected. Upload and Preview document based on user click
  // For new candidate, to update sendlink status
  funcstatusUpdate(pCategoryId, pId, pType) { // NOSONAR
    const { docInfo, changeStatus } = this.state;
    // eslint-disable-next-line
    const { send_links } = this.props;
    // eslint-disable-next-line
    docInfo.forEach(item => {
      if (item.CategoryId === pCategoryId) {
        // eslint-disable-next-line
        item.Data.map((subitem) => {
          if (subitem.Id === pId) {
            // eslint-disable-next-line
            let mArrSendLink = send_links;
            switch (pType) {
              case "SendLink":
                // eslint-disable-next-line
                if (!mArrSendLink.includes(subitem.Id)) {
                  subitem.SendLink = true;
                  mArrSendLink.push(subitem.Id);
                } else {
                  subitem.SendLink = false;
                  mArrSendLink = mArrSendLink.filter((ele) => {
                    return ele !== subitem.Id;
                  });
                }
                this.categoryId = pCategoryId;
                this.props.updateDocumentInfo({ send_links: mArrSendLink });
                break;
              case "Approved":
                if (!subitem.Approved && !subitem.Rejected) {
                  this.title = "Approve";
                  this.message =
                    "Are you sure you want to approve the document?";
                  this.categoryId = pCategoryId;
                  this.docId =
                    subitem.Files.length > 0 ? subitem.Files[0].DocId : pId;
                  this.setState({ confirmOpen: true });
                }
                break;
              case "Rejected":

                //nosonar if (!subitem.Approved && !subitem.Rejected) {
                if (!subitem.Rejected) {
                  this.title = "Reject";
                  this.message =
                    "Are you sure you want to reject the document?";
                  this.selectedFolderId = subitem.Files[0].DocId;
                  this.categoryId = pCategoryId;
                  this.docId =
                    subitem.Files.length > 0 ? subitem.Files[0].DocId : pId;
                  this.showComments = pType === "ViewRejectedComments";
                  this.commentFor = "2";
                  this.props.updateDocumentInfo({ openDocumentComments: true });
                }
                break;
              case "ViewRejectedComments":
                this.title = "Reject";
                this.message = "Are you sure you want to reject the document?";
                this.selectedFolderId = subitem.Files[0].DocId;
                this.categoryId = pCategoryId;
                this.docId =
                  subitem.Files.length > 0 ? subitem.Files[0].DocId : pId;
                this.showComments = pType === "ViewRejectedComments";
                this.commentFor = "2";
                this.props.updateDocumentInfo({ openDocumentComments: true });
                // }
                break;
              case "OpenDoc":
                this.folders = item.Data;
                this.selectedFolderId = pId;
                this.folderPath = subitem.FolderPath;
                this.categoryName = item.CategoryName;
                this.categoryId = pCategoryId;
                this.props.updateDocumentInfo({ openDocumentPopup: true });

                break;
              case "Comments":
              case "ViewComments":
                this.selectedFolderId = subitem.Files[0].DocId;
                this.categoryName = subitem.DocumenytType;
                this.categoryId = pCategoryId;
                this.showComments = pType === "ViewComments";
                this.commentFor = "1";
                this.props.updateDocumentInfo({ openDocumentComments: true });
                break;
              case "PreviewFile":
                this.files = subitem.Files;
                this.docId =
                  subitem.Files.length > 0 ? subitem.Files[0].DocId : pId;
                this.docName = subitem.DocumenytType;
                this.folderPath = subitem.FolderPath;
                this.categoryId = pCategoryId;
                this.showApprovalRejection = !(subitem.Rejected || subitem.Approved);
                this.props.updateDocumentInfo({ open_document_preview: true });
                break;
              default:
                break;
            }
          }
        });
      }
    });

    if (pType === "SendLink")
      this.setState({
        docInfo,
        refreshId: pCategoryId,
        changeStatus: !changeStatus,
      });
  }

  // Update status as Approved / Rejected
  funcUpdateApprovedRejectStatus(pDocId, pStatus) {
    const { authUser } = this.props;
    const data = {

      authUser,
      docid: pDocId,
      status: pStatus,
    };
    this.props.updateDocumentStatus(data);
  }

  expanded = (pCatgoryId) => {
    const { changeStatus } = this.state;
    if (this.tabRefreshed.indexOf(pCatgoryId) === -1) {
      this.tabRefreshed.push(pCatgoryId);
      this.setState({
        refreshId: pCatgoryId,
        changeStatus: !changeStatus,
      });
    }
  };

  handleConfirmYes = () => {
    this.funcUpdateApprovedRejectStatus(this.docId, this.approved_status)
    this.funcGetDocIdToUpdateStatusAndUpdate();
    this.setState({ confirmOpen: false });

    setTimeout(() => this.popupClose({ popupName: this.title }), 500)

  };

  handleConfirmNo = () => {
    this.setState({ confirmOpen: false });
  };

  // If the status is updated from Preview window
  // eslint-disable-next-line
  UNSAFE_componentWillReceiveProps(newProps) { // NOSONAR
    if (this.props.expanded !== newProps.expanded) {
      window.addEventListener("resize", this.getHeight());
    }


    const { changeStatus, docInfo } = this.state;
    var newDocInfo = docInfo;
    if ((newProps.baseCounty !== '' && newProps.countryType !== "India") || (newProps.addressCountryId === 101 && (newProps.empType !== 677 || newProps.empType !== 747 || newProps.empType !== 746 || newProps.empType !== 798))) {
      // eslint-disable-next-line
      newDocInfo.forEach(item => { // NOSONAR
        if (item.CategoryId === 1) {
          // eslint-disable-next-line
          item.Data.map((subitem2) => {
            if (!newProps.send_links.includes(subitem2.Id)) {
              subitem2.SendLink = false;
              subitem2.DefSendLink = false;
            }
          });
        }
      });
      this.setState({
        docInfo: newDocInfo,
        refreshId: 1,
        changeStatus: !changeStatus,
      });
    }
    else if (newProps.addressCountryId === 101 && (newProps.empType === 677 || newProps.empType === 747 || newProps.empType === 746 || newProps.empType === 798)) {
      // eslint-disable-next-line
      newDocInfo.forEach(item2 => { // NOSONAR
        if (item2.CategoryId === 1) {
          // eslint-disable-next-line
          item2.Data.map((subitem) => { // NOSONAR
            if (subitem.Id === 5 || subitem.Id === 6) {
              subitem.SendLink = true;
              subitem.DefSendLink = true;
            }

          });
        }
      });

      this.setState({
        docInfo: newDocInfo,
        refreshId: 1,
        changeStatus: !changeStatus,
      });
    }


    if (newProps.docRejectionParams.length > 0) {
      this.categoryName = newProps.docRejectionParams[0].categoryName;
      this.selectedFolderId = newProps.docRejectionParams[0].selectedFolderId;
      this.showApprovalRejection =
        newProps.docRejectionParams[0].showApprovalRejection;
      this.categoryId = newProps.docRejectionParams[0].categoryId;
      this.showComments = newProps.docRejectionParams[0].showComments;
      this.commentFor = newProps.docRejectionParams[0].commentFor;
    }
    if (newProps.refreshDocStatus === "app_rej_status") {
      if (newProps.doc_status === "A") {
        this.title = "Approve"
      } else if (newProps.doc_status === "R") {
        this.title = "Reject"
      } else if (newProps.doc_status === "D") {
        this.title = "Remove"
      }

      if (newProps.doc_status !== '') {
        setTimeout(() => this.popupClose({ popupName: this.title }), 500)
        if (newProps.doc_status !== "D")
          this.funcUpdateApprovedRejectStatus(this.docId, newProps.doc_status);
      }
      this.props.updateDocumentInfo({
        refreshDocStatus: "",
        doc_status: "",
        doc_id: 0,
        doc_category_id: 0,
      });
    } else if (newProps.refreshDocStatus === "doc_upload") {
      if (this.fileObj !== null) {
        this.fileObj.refreshFiles()
      }
      this.funcgetDocumentInformation(this.categoryId);
      this.props.updateDocumentInfo({
        refreshDocStatus: "",
        doc_status: "",
        doc_id: 0,
        doc_category_id: 0,
      });
    }
    // To Refresh the document selection once the candidate is saved.
    if (newProps.refresh_send_link_status === "updated") {
      const mArrCat = [];
      const docinfo = []; // sonarlint code clean
      // eslint-disable-next-line
      docinfo.forEach(item => {
        // eslint-disable-next-line
        item.Data.map((subitem) => {
          if (subitem.SendLink !== subitem.DefSendLink) {
            // eslint-disable-next-line
            subitem.SendLink = subitem.DefSendLink;
            mArrCat.push(item.CategoryId);
          }
        });
      });
      mArrCat.forEach((item) => {
        setTimeout(() => {
          this.setState({
            docInfo: docinfo,
            refreshId: item,
            changeStatus: !changeStatus,
          });
        }, 100);
      });
      this.props.updateDocumentInfo({ refresh_send_link_status: "" });
    }
  }
  onCreated = () => {
    const { docInfo } = this.state;
    setTimeout(() => {
      if (this.load !== null) {
        for (let i = 0; i <= this.load.items.length - 1; i += 1) {
          this.load.items[i].expanded = true;
        }
        // eslint-disable-next-line

      }
      for (let i = 0; i < docInfo.length; i += 1) {
        if (document.getElementById(`accr_${i}`) !== null) {
          document.getElementById(
            `accr_${i}`
          ).ej2_instances[0].items[0].expanded = true;
        }
      }
    }, 1000);
  };


  onCreateFileManager = () => {
    setTimeout(() => {
      if (this.onloadfilemanager !== null) {
        this.onloadfilemanager.items.expand = true
      }
    }, 5000)
  }

  funcGetDocIdToUpdateStatusAndUpdate = () => {
    const { docInfo, changeStatus } = this.state;
    this.setState({
      docInfo,
      refreshId: this.categoryId,
      changeStatus: !changeStatus,
      confirmOpen: false,
    });
  };

  moveDocuments = async () => {
    const { basicInformation } = this.props
    this.setState({ loader: true })
    const id = basicInformation.EmployeeId;
    const saveCandidateData = await moveCandidatesDocumentsReq(id)
    if (saveCandidateData.data.success === false) {
      setTimeout(() => {
        getNotify(ERROR, saveCandidateData.data.message);
      }, 100)

    } else {
      setTimeout(() => {
        getNotify(SUCCESS, DOCUMENT_MOVEMENT_COMPLETED);
      }, 100)
    }
    this.setState({ loader: false })

    this.funcgetDocumentInformation(0);
  }
  /*
  Function to change the image url , to show small image icons in large view module.
   */
  beforeImageLoad = (args) => {
    args.imageUrl = urlConfig.tmlcApiUrl + args.fileDetails[0].actualPath
  }
  /*
  *when Default api is getting hit, then before hitting we are sending these parameters ,
  to send parameters to api request in file manager
  */
  beforeSend = (args, candidateType, authUser, empId, candidateId) => {
    args.ajaxSettings.beforeSend = function (args) { 
      args.httpRequest.setRequestHeader("Authorization", localStorage.getItem('jwtToken'));
    } 
    let item = {
      candidateType,
      authUser: encryptMethod(authUser.toString()),
      empId,
      candidateId: candidateId ? encryptMethod(candidateId.toString()) : null,
      type: 'Upload',
      userType: candidateType === EMPLOYEE_UPLOAD ? "E" : "C",
      cand_emp_id: candidateType === EMPLOYEE_UPLOAD ? empId : candidateId,
      uploadedByCand: candidateType === NEW_CANDIDATE_UPLOAD ? '1' : 0,
      from: 'admin',
      type: 'TMLC'
    }
    var data = JSON.parse(args.ajaxSettings.data);
    if (args.action === 'Upload') {
      data[3].filename = data[3].filename.trim().replace(/\s+/g, ' ');  
      const filename = data[3].filename;
      const validationError = validateFileName(filename);

      if (validationError) {
        getNotify(ERROR, validationError);
        args.cancel = true;
        return;
      }

      const isFileCountValid = this.state.fileCount < MAX_FILES_ALLOWED;
      const isFilenameUnique = isNameUnique(this.state.files, filename);

      if (isFileCountValid && isFilenameUnique) {
        data.push({
          usertype: candidateType === EMPLOYEE_UPLOAD ? "E" : "C"
        });
        data.push({
          cand_emp_id: candidateType === EMPLOYEE_UPLOAD ? encryptMethod(empId.toString()) : item.candidateId
        });
        data.push({
          isuploadedbycand: candidateType === NEW_CANDIDATE_UPLOAD ? '' : 0
        });
        data.push({
          userid: item.authUser
        });
        data.push({
          parentDmsId: this.state.dmsID
        });
        data.push({
          folderId: this.state.foldId
        });
        data.push({
          uploadType: 'TMLC'
        });
      } else {
        const errorMessage = isFileCountValid ? SAME_FILE_NAME_EXIST : FILE_LIMIT_EXCEEDED;
        getNotify(ERROR, errorMessage);
        args.cancel = true;
      }
    } else {
      data['payloadData'] = item;
    }
    args.ajaxSettings.data = JSON.stringify(data);
    if (data?.data?.[0]?.dmsId && data?.data?.[0]?.folderId && args.action !== 'Upload') {
      const { dmsId, folderId } = data.data[0];
      this.setState({
        dmsID: dmsId,
        foldId: folderId
      });
    }
  }
  /**
   * to perform the action on context menu options click, eg: open,reject,approve....
   */
  menuClick = (args) => {
    if (args.fileDetails.length > 0) {
      const folderName = args.fileDetails[0].path !== '/' && args.fileDetails[0].path.split('/')[2].split('(')[0]
      const folderpath = args.fileDetails[0].path
      const folderId = args.fileDetails[0].id
      const docStatus = args.fileDetails[0].docStatus
      switch (args.element.innerText) {
        case "Approve":
          this.funOpenwindow("Approve", folderId, false, "1", folderName, docStatus, folderpath);
          break;
        case "Reject":
          this.funOpenwindow("Reject", folderId, false, "2", folderName, docStatus, folderpath);
          break;
        case "Open":
          if (args.fileDetails[0].isFile) {
            const fileNm = args.fileDetails[0].name
            this.files = [{ DocName: fileNm, DocId: folderId }];
            this.docId = folderId;
            this.docName = fileNm;
            this.folderPath = folderpath;
            this.filePath = (this.props.candidateType === 'EmployeeUpl' ? this.props.empid : this.props.candidateid) + '~' + args.fileDetails[0].filePath
            this.showApprovalRejection = (docStatus === '');
            this.setState({ currentPath: folderpath });
            this.setState({ fileDms: args.fileDetails[0].dmsId });
            this.props.updateDocumentInfo({ open_document_preview: true });
            args.cancel = true
          }
          break;
        case 'Comment':
          this.funOpenwindow("Comments", folderId, false, "1", folderName, docStatus, folderpath);
          break;
        case 'Remove':
          this.funOpenwindow("Remove", folderId, false, "1", folderName, docStatus, folderpath);
          break;
        default:
          break;
      }
    }
  };
  /*
  to add desired icons in context menu options
  */
  menuOpen(args) {
    for (const i in args.items) {
      if (args.items[i].properties.text === 'Approve') {
        args.items[i].iconCss = 'e-icons e-fe-circle-check';
      }
      if (args.items[i].properties.text === 'Reject')
        args.items[i].iconCss = 'e-icons e-fe-circle-reject';
      // annotation-edit
      if (args.items[i].properties.text === 'Comment') {
        args.items[i].iconCss = 'e-icons e-fe-circle-add';
      }
      if (args.items[i].properties.text === 'Remove') {
        args.items[i].iconCss = 'e-icons e-fe-remove';
      }
    }
  }

  /*
  to reset the document path after approve,reject upoad, for the purpose of auto refresh
  */
  popupClose(args) {
    try {
      if (this.fileObj !== null) {
        if (args.popupName === "Upload" || args.popupName === "Remove") {

          const { currentPath } = this.state
          if (currentPath !== null) {
            let newPath = ""
            let data = currentPath.split('/');
            data.forEach(item => {
              try {
                if (item !== "") {
                  if (item.includes("(")) {
                    let value = item.substr(item.indexOf(" ("), item.length).match(/\d+/)[0];
                    if (args.popupName === "Remove") {
                      if (value === "1") // if only single document was uploaded
                        newPath = newPath + "/" + item.substring(0, item.indexOf(" ("));
                      else
                        newPath = newPath + "/" + item.replace("(" + value + ")", "(" + (parseInt(value) - 1) + ")");
                    }
                    else
                      newPath = newPath + "/" + item.replace("(" + value + ")", "(" + (parseInt(value) + parseInt(this.totalFiles)) + ')');
                  } else {
                    newPath = newPath + "/" + item + " (" + this.totalFiles + ")"
                  }
                }
              } catch (error) {
              }
            })
            setTimeout(() => {
              this.fileObj.path = "/Documents"
              this.fileObj.path = newPath;
            }, 100)
          }
        }
        else if (args.popupName === "Reject" || args.popupName === "Approve") {
          setTimeout(() => {
            const { currentPath } = this.state
            this.fileObj.path = "/Documents"
            this.fileObj.path = currentPath;
          }, 100)
        }
      }
    } catch (error) {
    }
  }

  /**
   * to count the number of "/" in the given path string
   */
  countString = (str, letter) => {
    let count = 0;

    // looping through the items
    for (let i = 0; i < str.length; i++) {

      // check if the character is at that position
      if (str.charAt(i) == letter) {
        count += 1;
      }
    }
    return count;
  }


  /*
  function to not allow to upload in parent folders
  */
  onToolbarClick = (args) => {
    if (args.item.properties.text === 'Upload') {
      if (this.countString(args.fileDetails[0].path, '/') <= 2) {
        getNotify(WARNING, 'You cannot upload files inside parent folder.');
        args.cancel = true;
      }
    }
    if (typeof args.fileDetails[0] !== 'undefined') {
      this.setState({ currentPath: args.fileDetails[0].path })
      this.totalFiles = 0;
    }

    // this.getToolbarItems(args)
  }
  /*
  to perform action like, approve,reject,remove, on the basis of file select. 
   */
  onFileSelection(args) {
    if (args.action === 'select') {
      if (args.target !== null) {
        const folderName = args.fileDetails.path !== '/' && args.fileDetails.path.split('/')[2].split('(')[0]
        const folderpath = args.fileDetails.path
        const folderId = args.fileDetails.id
        const docStatus = args.fileDetails.docStatus
        if (args.target.classList.contains("fa-plus-circle")) {
          this.funOpenwindow("Comments", folderId, false, "1", folderName, docStatus, folderpath);
        } else if (args.target.classList.contains("fa-check-circle")) {
          this.funOpenwindow("Approve", folderId, false, "1", folderName, docStatus, folderpath);
        } else if (args.target.classList.contains("fa-trash")) {
          this.funOpenwindow("Remove", folderId, false, "1", folderName, docStatus, folderpath);
        } else if (args.target.classList.contains('fa-times-circle')) {
          this.funOpenwindow("Reject", folderId, false, "2", folderName, docStatus, folderpath);
        } else if (args.target.classList.contains("fa-eye")) {
          this.funOpenwindow("Comments", folderId, true, args.target.id === 'eyeCmt' ? "1" : "2", folderName, docStatus, folderpath);
        } else if (args.target.classList.contains("fa-comment")) {
          this.funOpenwindow("Comments", folderId, true, args.target.id === 'eyeCmt' ? "1" : "2", folderName, docStatus, folderpath);
        }
      }
    }

  }
  /**
   * to increase the count of files in sub folder, after each upload
   */
  onSuccess(args) {
    if (args.action == 'Upload') {
      this.totalFiles = this.totalFiles + 1
    } else {
      if (args) {
        this.setState({
          fileCount: args.result.files.length,
          files: args.result.files
        });
      }
    }
  }
  /*to stop image file open on double click */
  openFile(args) {
    const folderpath = args.fileDetails.path
    const docStatus = args.fileDetails.docStatus
    if (args.fileDetails.isFile) {
      const fileNm = args.fileDetails.name
      this.files = [{ DocName: fileNm, DocId: args.fileDetails.id }];
      this.docId = args.fileDetails.id;
      this.docName = fileNm;
      this.folderPath = folderpath;
      this.filePath = (this.props.candidateType === 'EmployeeUpl' ? this.props.empid : this.props.candidateid) + '~' + args.fileDetails.filePath
      this.showApprovalRejection = (docStatus === '');
      this.setState({ currentPath: folderpath });
      this.setState({ fileDms: args.fileDetails.dmsId });
      this.props.updateDocumentInfo({ open_document_preview: true });
      args.cancel = true
    }
  }
  /* */
  /**
   * to set the icons of approve , reject and remove , before the file loads.
   * @param {*contains elements,filedetais and module} args 
   */
  fileLoad(args, candidateType) {
    if (args.fileDetails.isFile === false) {
      if (candidateType !== 'EmployeeUpl') {
        if (args.fileDetails.actionStatus === 'actionRequired' || args.fileDetails.rejectdFolder === 'rejectedFolder') {
          if (args.element.children[1] !== undefined) {
            if (args.element.children[1].className === "e-text-content" || args.element.children[1].className === "e-text-content e-icon-wrapper") {
              const iEle = document.createElement("i")
              iEle.setAttribute("class", 'fa fa-exclamation-circle filepending')
              iEle.setAttribute("id", 'actionrequriedE' + args.fileDetails.name.split("(")[0])
              args.element.children[1].appendChild(iEle)
            }
          }
        }
      }


      if (args.element.children[1] !== undefined) {
        if (args.fileDetails.totalDocs > 0) {
          if (args.element.children[1].className === "e-text-content" || args.element.children[1].className === "e-text-content e-icon-wrapper") {
            args.element.classList.add('e-highlight');
          }
        } else {
          if (args.element.children[1].className === "e-text-content" || args.element.children[1].className === "e-text-content e-icon-wrapper") {
            args.element.classList.add('e-normal')
          }
        }
      }
    }
    else if (args.module === "LargeIconsView") {
      if (args.fileDetails.approveStatus === 'approvedClass') {
        args.element.classList.add('approvedBorder');
      } else if (args.fileDetails.rejectStatus === 'rejectedClass') {
        args.element.classList.add('rejectedBorder');
      }
    }
  }

  getHeight = () => {
    const {
      candidateType,
      expanded
    } = this.props;
    if (candidateType === 'EmployeeUpl') {
      if (window.innerHeight < 900) {
        if (expanded) {
          this.setState({ height: window.innerHeight - 284 })
        } else {
          this.setState({ height: window.innerHeight - 275 })
        }

      } else {
        if (expanded) {
          // this.setState({ height: window.innerHeight - 286 })
          this.setState({ height: window.innerHeight - 433 })
        } else {
          // this.setState({ height: window.innerHeight - 437 })
          this.setState({ height: window.innerHeight - 285 })
        }
      }
    }
    else {
      if (window.innerHeight < 900) {
        this.setState({ height: window.innerHeight - 200 })
      } else {
        if (expanded) {
          this.setState({ height: window.innerHeight - 200 })
        } else {
          this.setState({ height: window.innerHeight - 380 })
        }
      }
    }

  }
  /*function to pass required parameters to open the required popup fot approve, accept,add comments and remove */
  funOpenwindow(pType, pFolderId, pshowComments, pCommentsFor, pFolderName, pDocStatus, pDocPath) {
    try {
      this.categoryId = pFolderId;
      this.selectedFolderId = pFolderId;
      this.docId = pFolderId
      this.showComments = pshowComments;
      this.commentFor = pCommentsFor;
      this.categoryName = pFolderName;

      switch (pType) {
        case "Comments":
          this.props.updateDocumentInfo({ openDocumentComments: true });
          break;
        case "Approve":
        case "Reject":
        case "Remove":
          this.title = pType;
          this.message = "Are you sure you want to " + pType.toLowerCase() + " the document? ";
          if (pType === 'Approve') {
            this.approved_status = 'A'
          } else if (pType === 'Reject') {
            this.approved_status = 'R'
          } else {
            this.approved_status = 'D'
          }
          this.setState({ confirmOpen: pType === "Approve" || pType === "Remove", currentPath: pDocPath });
          if (pType === "Reject") {
            this.props.updateDocumentInfo({ openDocumentComments: true });
          }

          break;
        default:
      }
    } catch (error) {
    }
  }
  /**
   * 
   * @param {popupName} args 
   * while uploading duplicate files, getting some options, which we dont want, so to remoe those, this function is used.
   */
  popupOpen(args) {
    if (args.popupName === "Retry Upload" && document.getElementById("overview_file_extn_dialog") !== null) {
      setTimeout(() => {
        document.getElementById("overview_file_extn_dialog").ej2_instances[0].visible = false
        if (document.getElementsByClassName("e-upload-fails"))
          document.getElementsByClassName("e-upload-fails")[0].innerHTML = duplicateFileExists
      }, 0)
    }
  }

  render() {
    const {
      candidateType,
      categoryName,
      docRejectionParams,
      openDocumentPopup,
      open_document_preview,
      openDocumentComments,
      candidateid,
    } = this.props;
    const { docInfo, changeStatus, refreshId, confirmOpen } = this.state;

    let preofferRestictedFlag = false;
    if (categoryName === "9" && candidateType === NEW_CANDIDATE)
      preofferRestictedFlag = true;

    let margin;
    if (candidateType === NEW_CANDIDATE_UPLOAD) {
      margin = "20px";
    } else if (candidateType === EXISTING_CANDIDATE) {
      margin = "10px";
    } else if (candidateType === EMPLOYEE_UPLOAD) {
      margin = "50px";
    } else {
      margin = "10px";
    }

    return (
      <div
        className={
          candidateType === EMPLOYEE_UPLOAD ? "scroll-checklist-container" : ""
        }
        style={{
          marginTop: margin,
        }}
        id="empFileManager"
      >
        {window.innerHeight < 768 ? <style>{candidateType === EMPLOYEE_UPLOAD ? SAMPLECSS_PROFILE : SAMPLECSS_PREOFFER}</style> : ''}
        {this.state.loader && <ProgressBar />}

        <div style={{ display: candidateType === EMPLOYEE_UPLOAD ? "flex" : 'none', justifyContent: "flex-end", marginBottom: 10 }}>
          <TooltipComponent
            closeOn="Click"
            content={"Refresh Checklist"}
            position="BottomLeft"
            isSticky={false}
            cssClass='customtooltip'
            style={{ fontWeight: 500 }}
          >

            <FontAwesomeIcon
              icon={faSync}
              style={{ fontSize: 20, color: "black", backgroundColor: "#F6F6F6", marginRight: 30 }}
              onClick={this.moveDocuments}
              className="pointer-cursor"
            />
          </TooltipComponent>
        </div>
        <div style={{ marginBottom: "10px" }}>
          {
            // File manager component will upoad when candidateType is either Existing or EmployeeUpl.
            (candidateType === 'Existing' || candidateType === 'EmployeeUpl') ?
              <div>
                <div>
                  <div>
                    <FileManagerComponent
                      id="overview_file"
                      ref={(s) => (this.fileObj = s)}
                      ajaxSettings={{
                        url: urlConfig.tmlcApiUrl + this.documentData + 'FileOperations',
                        uploadUrl: urlConfig.tmlcApiUrl + this.documentData + 'Upload',
                        getImageUrl: urlConfig.tmlcApiUrl + this.documentData + `GetImage`,
                      }}
                      view={"Details"}
                      // height={700}
                      height={this.state.height}
                      searchSettings={{ allowSearchOnTyping: true, filterType: 'contains', ignoreCase: true }}
                      beforeSend={(args) => this.beforeSend(args, candidateType, this.props.authUser, this.props.empid, this.props.candidateid)}
                      beforeImageLoad={(args) => this.beforeImageLoad(args)}
                      contextMenuSettings={candidateType === 'Existing' ? this.preOfferContextMenuItem : this.employeeProfileContextMenuItem}
                      toolbarSettings={{ items: ['Upload', 'Refresh', 'SortBy', 'View'], visible: true }}
                      fileSelection={this.onFileSelection}
                      uploadSettings={{ allowedExtensions: '.pdf', maxFileSize: 10 * 1024 * 1024 }}
                      allowMultiSelection={false}
                      toolbarClick={(args) => { this.onToolbarClick(args) }}
                      success={this.onSuccess}
                      popupClose={this.popupClose}
                      popupOpen={this.popupOpen}
                      fileOpen={(args) => this.openFile(args)}
                      fileLoad={(args) => this.fileLoad(args, candidateType)}
                      menuClick={(args) => this.menuClick(args)}
                      menuOpen={(args) => this.menuOpen(args)}
                      detailsViewSettings={candidateType === 'Existing' ? this.preOfferColumn : this.employeeProfileColumn}

                    >
                      <Inject services={[NavigationPane, DetailsView, Toolbar]} />
                    </FileManagerComponent>
                  </div>
                </div>
                {/* </AccordionComponent> */}
              </div> :

              // eslint-disable-next-line
              docInfo.map((item, index) => {
                if (item.CategoryId === 3 && preofferRestictedFlag) {
                  // eslint-disable-next-line
                  // Nothing to do here
                } else {
                  return (
                    <div style={{ marginBottom: "10px" }} key={item.CategoryId}>
                      <AccordionComponent
                        id={`accr_${index}`}
                        // eslint-disable-next-line
                        ref={(accordion) => (this.load = accordion)}
                        expanded={() => {
                          this.expanded(item.CategoryId);
                        }}
                        // eslint-disable-next-line
                        created={this.onCreated.bind(this)}
                      >
                        <Documents
                          header={item.CategoryName}
                          id={item.CategoryId}
                          content={item.Data}
                          candidateType={candidateType}
                          // eslint-disable-next-line
                          pathName={this.props.location.pathname}
                          statusUpdate={this.funcstatusUpdate}
                          RefreshId={refreshId}
                          changeStatus={changeStatus}
                          candidateid={candidateid}
                        />
                      </AccordionComponent>
                    </div>
                  );
                }
              })
          }
          {openDocumentPopup && (
            <Upload
              folders={this.folders}
              categoryName={this.categoryName}
              selectedFolderId={this.selectedFolderId}
              folderPath={this.folderPath}
              categoryId={this.categoryId}
              candidateType={candidateType}
            />
          )}

          {
            // eslint-disable-next-line
            open_document_preview && (
              <Preview
                files={this.files}
                docName={this.docName}
                folderPath={this.folderPath}
                filePath={this.filePath}
                showApprovalRejection={this.showApprovalRejection}
                categoryId={this.categoryId}
                candidateType={candidateType}
                docRejectionParams={docRejectionParams}
                fileDms={this.state.fileDms}
              />
            )
          }

          {openDocumentComments && (
            <Comments
              categoryName={this.categoryName}
              selectedFolderId={this.selectedFolderId}
              showApprovalRejection={this.showApprovalRejection}
              categoryId={this.categoryId}
              candidateType={candidateType}
              showComments={this.showComments}
              commentFor={this.commentFor}

            />
          )}
          <ConfirmationPopup
            showButton={{ type: "1" }}
            open={confirmOpen}
            button1function={() => this.handleConfirmYes()}
            button2function={() => this.handleConfirmNo()}
            headerMessage={this.message}
            button1Text={"Yes"}
            button2Text={"No"}
            handleConfirmClose={this.handleConfirmNo}
          /> 
        </div>
      </div >
    );
  }
}

CheckList.propTypes = {
  authUser: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  candidateType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  categoryName: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  empid: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  candidateid: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  // updateDocumentInfo: PropTypes.func,
  send_links: PropTypes.arrayOf(PropTypes.object),
  // location: PropTypes.objectOf(PropTypes.object),
  docRejectionParams: PropTypes.arrayOf(PropTypes.object),
  openDocumentPopup: PropTypes.bool,
  open_document_preview: PropTypes.bool,
  openDocumentComments: PropTypes.bool,
  countryType: PropTypes.string
};
CheckList.defaultProps = {
  authUser: "",
  candidateType: "",
  categoryName: "",
  empid: 0,
  candidateid: 0,
  // updateDocumentInfo: {},
  send_links: [],
  // location: {},
  docRejectionParams: [],
  openDocumentPopup: false,
  open_document_preview: false,
  openDocumentComments: false,
  countryType: ""
};

const mapStateToProps = ({
  auth,
  onboardingreducer,
  router,
  meetingcategory,
}) => {
  const { authUser, initURL } = auth;
  const { location } = router;
  const { categoryName } = meetingcategory;
  const {
    openDocumentPopup,
    candidateid,
    // eslint-disable-next-line
    open_document_preview,
    // eslint-disable-next-line
    send_links,
    refreshDocStatus,
    // eslint-disable-next-line
    doc_status,
    empid,
    status,
    openDocumentComments,
    // eslint-disable-next-line
    refresh_send_link_status,
    docRejectionParams,
    baseCounty,
    countryType,
    basicInformation,
    expand,
    empType,
    addressCountryId
  } = onboardingreducer;
  return {
    authUser,
    openDocumentPopup,
    candidateid,
    open_document_preview,
    send_links,
    refreshDocStatus,
    doc_status,
    empid,
    status,
    openDocumentComments,
    refresh_send_link_status,
    location,
    initURL,
    categoryName,
    docRejectionParams,
    baseCounty,
    countryType,
    basicInformation,
    expand,
    empType,
    addressCountryId
  };
};
export default connect(mapStateToProps, {
  updateDocumentInfo,
  updateDocumentStatus,
  moveDocuments,
  saveBasicInformationInRedux
})(CheckList);