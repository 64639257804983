import React, { Component } from 'react'
import { connect } from 'react-redux';
import { ComboBoxComponent } from "@syncfusion/ej2-react-dropdowns";
import { setMeetingMasterUpdate } from '../../../redux/actions/meetingmasteraction';

import {
 HOLIDAY, SYMBOL, LEAVE_YEAR
} from "../../../redux/constant/meetingcategoryconstant";
import LNAMasterSetup from '../../../components/lnacomponents/clustersetup/lnamastersetup';
import NotificationContainer from "../../../components/notification/notifycontainer";
import { updateLnAData } from '../../../redux/actions/lna/lnaaction'

class SetupContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      height: (window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight) - 145
      // heading : 'Attendance Cluster'
    }
    // this.heading = "Attendance Cluster"
  }

  componentDidMount() {
    this.setState({
      height: (window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight) - 145
    })
  }

  onChangedInput = (e) => {
    this.props.setMeetingMasterUpdate({ currentFinancialYearId: e.itemData ? e.itemData.Id : '' })
  }

  onChangedLeaveYear = (e) => {
    this.props.setMeetingMasterUpdate({ leaveYearId: e.itemData ? e.itemData.Id : '' })
  }
  
  renderSwitch = () => {
    const { masterId, history, changePopUpHandler } = this.props
    let activeMasterId = masterId.toString();
    return(
      <LNAMasterSetup 
           categoryName={activeMasterId} masterId= {masterId} history={history} changePopUpHandler={changePopUpHandler}
      />
    )
  }

  componentWillUnmount() {
    try {
      const { masterId } = this.props
      if (SYMBOL === masterId.toString()) {
        this.props.updateLnAData({ lnaMasterFlag: "refresh" })
      }
    } catch (error) {
    }

  }
  render() {
    const { finacialYearData, leaveYear, leaveYearId, currentFinancialYearId, changePopUpHandler, masterId, openPopup } = this.props;
    const { height, } = this.state;
    return (
      <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 p-md" style={{ backgroundColor: "#F2F2F2" }}>
        <NotificationContainer />
        <div
          className="col-xs-12 col-sm-12 col-lg-12 col-md-12"
          style={{
            backgroundColor: "#ffffff",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            borderRadius: ".4rem",
            height: height,
          }}>
          <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
            <div className='col-sm-12 col-md-12 col-lg-12'>
              <div style={{
                backgroundColor: '#343A40',
                borderStartEndRadius: "0.4em",
                borderStartStartRadius: "0.4em",
                height: "3.5rem",
                display: "flex",
                alignItems: "center",
                paddingLeft: "1rem",
                fontWeight: 600,
              }}
              >
                <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <div>
                      <button style={{ borderRadius: '6px' }}
                        className="lna-add-button"
                        onClick={() => changePopUpHandler("open")}
                      >
                        {/* <FontAwesomeIcon
                          style={{ fontSize: "1rem" }}
                          icon={faPlus}
                        /> */}
                        + Add
                      </button>
                    </div>

                    {/* <div>
                      <button className="lna-add-button" style={{ marginLeft: "1rem" }}>
                        <FontAwesomeIcon
                          style={{ fontSize: "1rem" }}
                          icon={faTrashCan}
                        /> Delete
                      </button>
                    </div> */}
                  </div>
                  {masterId == HOLIDAY && (
                    <div className='col-sm-2 col-md-2 col-lg-2 financialYear-box' style={{ marginRight: '25px', backgroundColor: (openPopup !== 'close') ? 'GrayText' : '', border: "1px solid black" }}>
                      <ComboBoxComponent
                        id='fyYear'
                        name='fyErr'
                        data-msg-containerid='fyErr'
                        allowCustom={false}
                        floatLabelType="Auto"
                        fields={{ value: "Id", text: "Name" }}
                        dataSource={finacialYearData}
                        value={currentFinancialYearId}
                        change={(e) => this.onChangedInput(e)}
                        cssClass="e-outline"
                      />
                    </div>
                  )}
                  {masterId == LEAVE_YEAR && (
                    <div className='col-sm-2 col-md-2 col-lg-2 financialYear-box' style={{ marginRight: '25px', backgroundColor: (openPopup !== 'close') ? 'GrayText' : '', border: "1px solid black" }}>
                      <ComboBoxComponent
                        id='leavefyYear'
                        name='leavefyErr'
                        data-msg-containerid='leavefyErr'
                        allowCustom={false}
                        floatLabelType="Auto"
                        fields={{ value: "Id", text: "Name" }}
                        dataSource={leaveYear}
                        value={leaveYearId}
                        change={(e) => this.onChangedLeaveYear(e)}
                        cssClass="e-outline"
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="lna-main-admin-div" style={{ height: '100%', width: '100%', backgroundColor: "#ffffff",marginTop:54 }}>
            {this.renderSwitch()}

          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = ({ meetingcategory, meetingmaster }) => {
  const { finacialYearData, currentFinancialYearId, leaveYear, leaveYearId } = meetingmaster;
  const { categoryName } = meetingcategory;
  return {
    categoryName,
    finacialYearData,
    currentFinancialYearId,
    leaveYear,
    leaveYearId
  }
}
export default connect(mapStateToProps, { setMeetingMasterUpdate, updateLnAData })(SetupContainer);
