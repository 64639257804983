import "date-fns";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../assets/style/index.css";
import { makeStyles } from "@material-ui/core/styles";
import "../../assets/font-awesome/css/font-awesome.min.css";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import Button from "@material-ui/core/Button";
import {
  MultiSelectComponent,
  CheckBoxSelection,
} from "@syncfusion/ej2-react-dropdowns";
import PropTypes from "prop-types";
import { library } from "@fortawesome/fontawesome-svg-core";
import { Inject } from "@syncfusion/ej2-react-schedule";
import {
  faEdit,
  faFileAlt,
  faRecycle,
  faDesktop,
  faCalendarAlt,
  faClock,
  faGlobe,
  faMapMarkerAlt,
  faGlobeAmericas,
  faVideo,
  faSearchPlus,
  faPhone,
  faCopy,
  faMapMarkedAlt,
  faLink,
  faUsers,
} from "@fortawesome/pro-light-svg-icons";
import { updateMeetingBookingState } from "../../redux/actions/meetingbookingaction";
import { getDateFormat, getNotify, getMySqlDateFormat, removeExtraSeconds } from "../../app/other/commonfunction";
import {
  SUCCESS,
  ERROR,
  linkCopiedSuccessfully,
  pleaseEnterValidLink,
  pleaseEnterAppropriateLink,
} from "../../assets/constants/constants";
import Progressbar from "../../components/progressbar/progressbar"
import dataService from '../../services/services';
import { decodeString } from "./../../app/other/commonfunction"
library.add(
  faEdit,
  faFileAlt,
  faRecycle,
  faDesktop,
  faCalendarAlt,
  faClock,
  faGlobe,
  faMapMarkerAlt,
  faGlobeAmericas,
  faVideo,
  faSearchPlus,
  faPhone,
  faCopy,
  faMapMarkedAlt,
  faUsers
);
const useStyles = makeStyles(() => ({
  // root: {
  //   flexGrow: 1,
  // },
  // paper: {
  //   display: "flex",
  //   border: `1px solid ${theme.palette.divider}`,
  //   flexWrap: "wrap",
  // },
  // button: {
  //   margin: theme.spacing(1),
  // },
  // formControl: {
  //   border: "0px solid green",
  // },
}));

function DigitalMedia(props) {
  const classes = useStyles();
  let listsrvPrd;


  const { meetingStartTime,
    meetingDate,
    meetingEndTime,
    meetingTitle,
    meetingWeblinkType,
    timeZoneId,
    recurring,
    zoomMeetingId,
    zoomMeetingHostEmailId,
    oldZoomMeeting,
    participateCode,
    meetingWebLink,
    country,
    countriesId,
    webLinkLoader,
    meetingId,chairPersonId
  } = props;

  const zoomToTeamsBtnClick = async (icon) => {

    if (icon === 'Teams') {
      if (zoomMeetingId) {
        if (oldZoomMeeting) {
          await dataService.deleteZoomMeeting(oldZoomMeeting.zoomMeetingId, 0, meetingId)
        }
        props.updateMeetingBookingState({
          oldZoomMeeting: {
            meetingWebLink,
            participateCode,
            zoomMeetingId,
            zoomMeetingHostEmailId
          },
          meetingWebLink: "",
          participateCode: "",
          zoomMeetingId: null,
          zoomMeetingHostEmailId: null
        })
      }
    }
    else if (icon === 'Zoom') {
      if (oldZoomMeeting) {
        props.updateMeetingBookingState({
          meetingWebLink: oldZoomMeeting.meetingWebLink,
          participateCode: oldZoomMeeting.participateCode,
          zoomMeetingId: oldZoomMeeting.zoomMeetingId,
          zoomMeetingHostEmailId: oldZoomMeeting.zoomMeetingHostEmailId,
          oldZoomMeeting: null
        })
      }
    }

  }


  const generateTeamsLink = () => {//NOSONAR

    try {
      if (meetingWeblinkType === 'Teams') {
          const userList = props.othersCheckAvailablity.filter((item) => {
             // eslint-disable-next-line
             return item.UserId === parseInt(chairPersonId, 10);
           });
           props.updateMeetingBookingState({
             domainName:userList[0].EmailAddress.split('@')[1],
           });
        if (meetingTitle === "" || meetingTitle === null) {
          getNotify(ERROR, "Please enter meeting title");
          return;
        }
        else {
          props.updateMeetingBookingState({ webLinkLoader: "1" });
          let generateAuthKey = dataService.meetingLinkAuthToken(new Date(meetingStartTime.toString()).toISOString(), new Date(meetingEndTime.toString()).toISOString(), meetingTitle.replace(/[^\w\s]/gi, '').toString(),chairPersonId);
          if (generateAuthKey !== undefined) {
            generateAuthKey.then((result) => {
              if (result !== null) {
                props.updateMeetingBookingState({ meetingWebLink: result, participateCode: "", webLinkLoader: "2" });
              }
            })
          }
          else {
            return (
              <div className="control-section col-md-12" style={{ height: 75 }}>
                <center> <Progressbar /> </center>
              </div>
            );
          }
        }
      } else if (meetingWeblinkType === 'Zoom') {
        const { zoomRecurringMeetingDates } = props
        // Author : Suresh Patel
        // Date : 04-01-2022
        // Description: Zoom Meeting Controller Function
        if (!props.zoomMeetingId) {
          const mdtMeetingStartDate = getDateFormat(
            new Date(meetingDate),
            new Date(meetingStartTime)
          );
          const mdtMeetingEndDate = getDateFormat(
            new Date(meetingDate),
            new Date(meetingEndTime)
          );
       
          let recurringDates;
          let hostEmail = "0";
          let zoomMeetingTimeInMin = (new Date(removeExtraSeconds(mdtMeetingEndDate)).getTime() - new Date(removeExtraSeconds(mdtMeetingStartDate)).getTime()) / 60000;
          const type = recurring === "" ? 2 : 8;
          const recurringObj = JSON.stringify({ "obj": recurring })
         
          if (zoomMeetingTimeInMin <= 30) {
            hostEmail = decodeString(localStorage.getItem('digitalEmailAddress'))

          }
          if (zoomRecurringMeetingDates === null || zoomRecurringMeetingDates === undefined) {
            let date = getMySqlDateFormat(meetingDate);
            recurringDates = date.split(' ')[0];
          } else {
            recurringDates = props.zoomRecurringMeetingDates;
          }

          if (meetingTitle === "" || meetingTitle === null) {
            getNotify(ERROR, "Please enter meeting title");
            return;
          }
          else {
          
            props.updateMeetingBookingState({ webLinkLoader: "1" });
            let generateAuthKey = dataService.getZoomMeeting(mdtMeetingStartDate, mdtMeetingEndDate, timeZoneId, meetingTitle.replace(/[^\w\s]/gi, '').toString(), type, recurringObj, hostEmail, recurringDates);

            if (generateAuthKey !== undefined) {
              generateAuthKey.then((result) => {


                if (result !== null) {
                  if (result.id) {
                    const { join_url, password, id, host_email } = result;

                    let occurrencesArray = []
                    let occurrencesString = ""
                    if (result.occurrences) {
                      result.occurrences.forEach(occurrence => {
                        occurrencesArray.push(`${occurrence.start_time.split("T")[0]}_${occurrence.occurrence_id}`)
                      })
                      occurrencesString = occurrencesArray.join("|")
                    }

                    props.updateMeetingBookingState({
                      meetingWebLink: join_url,
                      participateCode: `${id}; Passcode:${password}`,
                      zoomMeetingId: `${id}`,
                      zoomMeetingHostEmailId: host_email,
                      webLinkLoader: "2",
                      zoomOccurrences: occurrencesString
                    });

                  } else if (result.notFoundError) {

                    getNotify(ERROR, result.notFoundError);
                    props.updateMeetingBookingState({ webLinkLoader: "2" });
                    return
                  } else if (result.paramError) {
                    getNotify(ERROR, result.paramError);
                    props.updateMeetingBookingState({ webLinkLoader: "2" });
                    return
                  }
                }
              }).catch((error) => {
                console.log('error==>', error)
              })
            }
            else {
              return (
                <div className="control-section col-md-12" style={{ height: 75 }}>
                  <center> <Progressbar /> </center>
                </div>
              );
            }
          }
        }


      }
    } catch (error) {
      console.log(error);
    }
  }
  const handleDigitalClick = (Id) => {

    try {
      if (document.getElementById("copylinkError") !== null)
        document.getElementById("copylinkError").innerHTML = "";
      const mDigitalMeetings = ["Teams", "Webex", "Zoom"];
      mDigitalMeetings.forEach((element) => {
        if (element === Id) {
          if (
            document
              .getElementById(`btn${element}`)
              .classList.contains("MuiButton-containedPrimary")
          ) {
            document
              .getElementById(`btn${element}`)
              .classList.remove("MuiButton-containedPrimary");
            props.updateMeetingBookingState({ meetingWeblinkType: "" });
          } else {
            setTimeout(() => {
              document
                .getElementById(`btn${element}`)
                .classList.add("MuiButton-containedPrimary");
              props.updateMeetingBookingState({ meetingWeblinkType: Id });
            }, 200)
          }
        } else {
          document
            .getElementById(`btn${element}`)
            .classList.remove("MuiButton-containedPrimary");
        }
      });
    } catch (error) { }
  };

  useEffect(() => {
    const { meetingId, meetingWeblinkType } = props; // NOSONAR
    if (meetingId > 0) {
      if (meetingWeblinkType !== "") {
        handleDigitalClick(meetingWeblinkType);
      }
    } // eslint-disable-next-line
  }, []);

  const handleChange = (event) => {
    if (event.target.name === 'mm-select') {
      props.updateMeetingBookingState({
        meetingMediaId: event.target.value,
      });
    }
  };

  const handleFocusOut = (obj) => {
    try {
      switch (obj.event.srcElement.name) {
        case "mttitle-txt":
          props.updateMeetingBookingState({ meetingTitle: obj.value.trim() });
          break;
        case "magenda-txt":
          props.updateMeetingBookingState({ meetingAgenda: obj.value.trim() });
          break;
        case "mconfdts-txt":
          props.updateMeetingBookingState({
            participateCode: obj.value.trim(),
          });
          break;
        case "mlocation-txt":
          props.updateMeetingBookingState({
            meetingLocation: obj.value.trim(),
          });
          break;
        case "mlink-txt":
          props.updateMeetingBookingState({
            meetingWebLink: obj.value.trim(),
          });
          break;
        default:
      }
    } catch (error) { }
  };
  const countryHandleChange = (event) => {
    if (event.element.id === "country-select") {
      props.updateMeetingBookingState({ countriesId: event.value });
    }
  };
  const handleOpensrvPrd = () => {
    listsrvPrd.showPopup();
  };
  const copyMediaLink = () => {
    const copyText = document.getElementById("copyLink");
    if (copyText.value !== "") {
      const regexp = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
      if (regexp.test(copyText.value)) {
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        document.execCommand("copy");
        getNotify(SUCCESS, linkCopiedSuccessfully);
      } else {
        getNotify(ERROR, pleaseEnterValidLink);
      }
    } else {
      getNotify(ERROR, pleaseEnterAppropriateLink);
    }
  };

  const getMediaIcon = (icon) => {
    let IconContent;

    switch (icon) {
      case "Webex":
        IconContent = (
          <FontAwesomeIcon
            className="mr-sm"
            id="pointer"
            icon={faGlobeAmericas}
            title="Webex"
            style={{ cursor: "pointer", fontSize: 13 }}
          />
        );
        break;
      case "Skype":
        IconContent = (
          <FontAwesomeIcon
            className="mr-sm"
            id="pointer"
            icon={faPhone}
            title="Skype"
            style={{ cursor: "pointer" }}
          />
        );
        break;
      case "Zoom":
        IconContent = (
          <FontAwesomeIcon
            className="mr-sm"
            id="pointer"
            icon={faVideo}
            title="Zoom"
            style={{ cursor: "pointer" }}
          />
        );
        break;
      case "Teams":
        IconContent = (
          <FontAwesomeIcon
            className="mr-sm"
            id="pointer"
            icon={faUsers}
            title="Teams"
            style={{ cursor: "pointer" }}
          />
        );
        break;
      default:
        IconContent = (
          <FontAwesomeIcon
            id="pointer"
            icon={faPhone}
            title="Meeting Link"
            style={{ cursor: "pointer" }}
          />
        );
        break;
    }
    return IconContent;
  };



  const mDigitalMeeting = ["Teams", "Webex", "Zoom"];
  return (
    <div>

      <div className="col-md-12">
        <div className="row">
          {/* Channel Buttons */}
          <div className="col-md-5">
            <div className="row1 custom-padding-1">
              <div className="col-md-12">
                <div className="e-input-group e-float-icon-left">
                  <FontAwesomeIcon icon={faDesktop} className="mt-lg fa-lg1" />
                  <div
                    className="e-input-in-wrap"
                    style={{ marginLeft: "3px" }}
                  >
                    <div id="text" className="e-btn-group ml-xs">
                      {
                        mDigitalMeeting.map((item, index) => {
                          if (meetingWeblinkType === item) {
                            return (
                              <Button variant="contained" key={"id_" + index} id={`btn${item}`}
                                onClick={() => {
                                  props.updateMeetingBookingState({ meetingWeblinkType: meetingWeblinkType === item ? "" : item });
                                  zoomToTeamsBtnClick(item)
                                }}
                                style={{ margin: 8, color: '#FFF', fontWeight: '300' }}
                                className='MuiButton-containedPrimary' title={item}>

                                {getMediaIcon(item)}
                                {item}
                              </Button>
                            )
                          } else {
                            return (
                              <Button variant="contained" key={"id_" + index} id={`btn${item}`}
                                onClick={() => {
                                  props.updateMeetingBookingState({ meetingWeblinkType: item });
                                  zoomToTeamsBtnClick(item)
                                }}
                                className=""
                                style={{ margin: 8, color: '#000', fontWeight: '300' }}
                                title={item}>
                                {getMediaIcon(item)}
                                {item}
                              </Button>
                            )
                          }
                        })
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* Copy Link */}
          {
            <div className="col-md-5 mt-xs">
              <div className="row1 custom-padding-top-10">
                <div className="col-md-12">
                  <div className="e-input-group e-float-icon-left">
                    <FontAwesomeIcon icon={faLink} style={{ fontSize: 15 }} className="mt-sm" />
                    <div className="e-input-in-wrap">
                      <TextBoxComponent
                        name="mlink-txt"
                        id="copyLink"
                        value={meetingWebLink}
                        placeholder="Copy Paste Link"
                        cssClass="e-outline"
                        floatLabelType="Auto"
                        data-msg-containerid="copylinkError"
                        blur={handleFocusOut}
                        htmlAttributes={{ maxlength: 255 }}
                      />
                      <span style={{ position: "absolute", right: "0" }}>
                        <Button
                          style={{
                            padding: "2px 0px 10px 13px",
                            backgroundColor: "rgb(214, 213, 211)",
                            border: 0.5,
                            margin: "2px 2px 0px 0px",
                          }}
                          id="myTooltip"
                          onClick={copyMediaLink}
                          title="Copy link"
                        >
                          <FontAwesomeIcon
                            icon={faCopy}
                            className="mt-sm fa-lg1"
                          />
                        </Button>
                      </span>
                    </div>
                  </div>
                  <div
                    id="copylinkError"
                    className="error-div copylinkerror-label"
                  />
                </div>
              </div>
            </div>
          }
          {
            meetingWeblinkType === 'Teams' &&
            <div className="col-md-2 mt-xs">
              <div className="e-input-group e-float-icon-left">
                <Button variant="contained" style={{ margin: "0px 0px 0px 0px" }} id="btnTeams1" onClick={(e) => generateTeamsLink()} className={classes.button} title="Generate Link">
                  <FontAwesomeIcon icon={faLink} className="mr-sm" />Generate Link</Button>
              </div>
            </div>
          }
          {
            meetingWeblinkType === 'Teams' && webLinkLoader === "1" &&
            <div className="control-section col-md-12">
              <center> <Progressbar /> </center>
            </div>
          }
          {/**TestCode For Zoom Link */}
          {
            meetingWeblinkType === 'Zoom' &&
            <div className="col-md-2 mt-xs">
              <div className="e-input-group e-float-icon-left">
                <Button variant="contained" style={{ margin: "0px 0px 0px 0px" }} id="btnTeams1" onClick={(e) => generateTeamsLink()} className={classes.button} title="Generate Link">
                  <FontAwesomeIcon icon={faLink} className="mr-sm" />Generate Link</Button>
              </div>
            </div>
          }
          {
            meetingWeblinkType === 'Zoom' && webLinkLoader === "1" &&
            <div className="control-section col-md-12">
              <center> <Progressbar /> </center>
            </div>
          }
          {/** */}
        </div>
      </div>
      <div className="col-md-12 pt-xs">
        <div className="row">
          <div className="col-md-5">
            {/* Select country Conference Bridge  */}
            <div className="custom-margin custom-padding-5 material2">
              <div className="col-md-12">
                <div className="e-input-group e-float-icon-left mb-none">
                  <FontAwesomeIcon
                    icon={faMapMarkedAlt}
                    className="mt-sm fa-lg1"
                  />
                  <div className="e-input-in-wrap">
                    <div style={{ width: "100%", display: "grid" }}>
                      <MultiSelectComponent
                        style={{ display: "inline-block" }}
                        ref={(combobox) => {
                          listsrvPrd = combobox;
                        }}
                        id="country-select"
                        name="country-select"
                        dataSource={country}
                        fields={{ text: "Name", value: "Id" }}
                        placeholder="Conference Bridge Telephone number"
                        cssClass="e-outline"
                        floatLabelType="Auto"
                        showSelectAll={false}
                        popupHeight="350px"
                        change={countryHandleChange}
                        value={countriesId}
                        showDropDownIcon
                        filterBarPlaceholder="Conference Bridge Telephone number"
                        data-msg-containerid="srvprdError"
                        mode="CheckBox"
                        focus={handleOpensrvPrd}
                        allowFiltering={false}
                      >
                        <Inject services={[CheckBoxSelection]} />
                      </MultiSelectComponent>
                      <div id="srvprdError" className="error-div" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-5">
            <div className="custom-margin custom-padding-5 material2">
              <div className="col-md-12">
                <div className="e-input-group e-float-icon-left">
                  <FontAwesomeIcon
                    icon={faSearchPlus}
                    className="mt-sm fa-lg"
                    style={{ width: "0.8em" }}
                  />
                  <div className="e-input-in-wrap mt-xs1">
                    <TextBoxComponent
                      name="mconfdts-txt"
                      onChange={handleChange}
                      value={participateCode}
                      placeholder="Conference Bridge code"
                      cssClass="e-outline"
                      floatLabelType="Auto"
                      data-msg-containerid="confdtsError"
                      blur={handleFocusOut}
                      htmlAttributes={{ maxlength: 100 }}
                    />
                    <div id="confdtsError" className="error-div" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
DigitalMedia.propTypes = {
  meetingId: PropTypes.number,
  // eslint-disable-next-line
  updateMeetingBookingState: PropTypes.func,
  meetingWeblinkType: PropTypes.string,
  meetingWebLink: PropTypes.string,
  country: PropTypes.arrayOf(Object),
  countriesId: PropTypes.arrayOf(Object),
  participateCode: PropTypes.string,
};

DigitalMedia.defaultProps = {
  meetingId: 0,
  meetingWeblinkType: "",
  meetingWebLink: "",
  country: [],
  countriesId: [],
  participateCode: "",
};
const mapStateToProps = ({ meetingbooking, meetingmaster }) => {
  const {
    meetingId,
    meetingTitle,
    meetingAgenda,
    meetingDate,
    meetingStartTime,
    meetingEndTime,
    meetingMediaId,
    timeZoneId,
    participateCode,
    meetingWeblinkType,
    meetingLocation,
    meetingWebLink,
    isFullDay,
    isClientMeeting,
    recurring,
    countriesId,
    webLinkLoader,
    zoomMeetingId,
    zoomMeetingHostEmailId, oldZoomMeeting, zoomRecurringMeetingDates,
    chairPersonId
  } = meetingbooking;

  const { meetingMedia, timeZone, country,othersCheckAvailablity } = meetingmaster;
  return {
    meetingId,
    meetingTitle,
    meetingAgenda,
    meetingDate,
    meetingStartTime,
    meetingEndTime,
    meetingMediaId,
    timeZoneId,
    participateCode,
    meetingWeblinkType,
    meetingLocation,
    meetingWebLink,
    isFullDay,
    isClientMeeting,
    recurring,
    meetingMedia,
    timeZone,
    country,
    countriesId,
    webLinkLoader,
    zoomMeetingId,
    zoomMeetingHostEmailId, oldZoomMeeting, zoomRecurringMeetingDates,
    chairPersonId,othersCheckAvailablity
  };
};

export default connect(mapStateToProps, {
  updateMeetingBookingState,
})(DigitalMedia);