import axios from "axios";

export const viewFile = (params) => {
    const { dmsId } = params;
    return new Promise((resolve, reject) => {
        axios
            .get(`${process.env.REACT_APP_DMS_API_URL}?request=getDocument&docId=${dmsId}`, {
                responseType: 'blob',
                
                https: { rejectUnauthorized: false },
            })
            .then((response) => {
                const reader = new window.FileReader();
                reader.readAsDataURL(response.data);
                reader.onloadend = () => {
                    resolve(reader.result);
                };
                return reader.result;
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            });
    });
};
