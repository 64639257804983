import React, { Component } from 'react';
import HeadingContainer from '../../../components/okrratingcomponents/headercontainer';
import RatingTabs from '../../../components/okrratingcomponents/ratingtabs'
import { connect } from 'react-redux';
import { addUpdateOkrRatingRedux } from '../../../redux/actions/okrrating/okrratingaction';


class SelfReview extends Component {
    constructor(props) {
        super(props)
        this.state = {
            height: (window.innerHeight ||
                document.documentElement.clientHeight ||
                document.body.clientHeight),
            showStatus:/**nosonar**/ (window.innerHeight ||
                document.documentElement.clientHeight ||
                document.body.clientHeight) > 767 ? true : true
        }
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.ratingCategoryStatus === 'updated') {
            this.props.addUpdateOkrRatingRedux({ ratingCategoryStatus: "", refreshRatingDataFlag: 'updated' })
        }
    }
    render() {
        const { height, showStatus } = this.state;
        return (
            <div className='' style={{ backgroundColor: '#F3F3F6', height: height }}>
                <div className='container-fluid' >
                    <div>
                        <div style={{ cursor: 'pointer', position: 'absolute', right: 30, top: (showStatus ? 120 : 45), zIndex: 10 }} onClick={() => this.setState({ showStatus: !showStatus })}>
                            {
                                showStatus ?
                                    <>
                                    <span style={{ color: '#B7B7BB' }}>Click to close filters</span> <i className="fas fa-chevron-down" style={{ color: '#B7B7BB' }}></i>
                                    </>
                                    :
                                    <>
                                    <span style={{ color: '#B7B7BB' }}>Click to open filters &nbsp;</span>
                                    <i className="fas fa-chevron-up" style={{ color: '#B7B7BB' }}></i>
                                    </>
                            }
                        </div>
                    </div>
                    <HeadingContainer showStatus={showStatus} />
                </div>

                <div className='container-fluid rating-tabs-container'>
                    <RatingTabs showStatus={showStatus} />
                </div>
            </div>
        )
    }
}
const mapStateToProps = ({ okrratingreducer }) => {
    const { ratingCategoryStatus } = okrratingreducer;
    return { ratingCategoryStatus }
};
export default connect(mapStateToProps, { addUpdateOkrRatingRedux })(
    SelfReview
);
