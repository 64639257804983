/**
 * Author: Vivek Khodade.
 * Created:06-Jun-2020
 * Last Modify:06-Jun-2020
 * Modified By:Vivek Khodade
 */
import {
  RESONS_FOR_RESIGNATIONS,
  ADD_UPDATE_SEPARATION,
} from "../../constant/entryexit/separationconstant";

const INIT_STATE = {
  primaryReasonForResignationId: '',
  reasonsForResignationIds:'',
  willingToBuyBackNotice:0,
  addCcEmpIds:'',
  ccEmpName:'',
  noticePeriodRequested:0,
  resinationDate:'',
  willingToBuyback: null,
  Remarks:'',
  employeeStatus:'',
  noticePeriodInMonth:0,
  reportingTo:'',
  additionalEmailIdsCC:"",
  withdrawlReason:[],
  updateResonForResignationStatus:'',
  absondingDate:new Date(),
  abscondingManagerRemark:"",
  terminationRequestFor:0,
  terminationGrounds:0,
  terminationRemark:'',
  terminationStatusData:'',
  groundsData:'',
  clearanceItem:[],
  updateExitClearanceStatus:'',
  recoveryAmt:0,
  reloadStatus:'',
  UpdateResignationStatus:'',
  suspensionForDays: 0,
  clearanceType:'',
  empid:'',
  UpdateWithdrawlStatus: '',
  requestedLWD:'',
  updateTerminationStatus:'',
  updateAbscondingStatus:'',
  noOfDays:0,
  addCcEmpName:'',
  addCcData:[],
  terminationLWD:new Date().toString(),
  abscondingReportedBackDate:new Date(),
  departmentalText:'',
  updateExitClearanceNavStatus:'',
  clearanceInitiated:'',
  clearanceDepartment:'',
  recoveryAmountStatus:'0'
};
// eslint-disable-next-line
export default (state = INIT_STATE, action) => {  // NOSONAR
  if(action.type === RESONS_FOR_RESIGNATIONS ||  action.type === ADD_UPDATE_SEPARATION){
    return {
      ...state,
      ...action.payload,
    };
  }else{
    return state;
  }
};
