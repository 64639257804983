import React, { useEffect, useState } from "react";
import { ComboBoxComponent } from "@syncfusion/ej2-react-dropdowns";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { makeStyles } from '@material-ui/core';
import LnAServices from "../../services/lna/lnaservice";
import { FormValidator } from "@syncfusion/ej2-inputs";
import { connect } from "react-redux";
import {
  regexOnlyLettersAndSpaces,
  enterCoutnry,
  pleaseEnterWorkingDay,
  enterDate,
  company,
  pleaseEnterAttendanceType,
  pleaseSetMinHrs,
  pleaseEnterStartTime,
  pleaseEnterEndTime,
  selectDayBreak,
  StartTimeLessThanEndTime,
  EndTimeGreaterThanStartTime,
} from "../../assets/constants/constants";
import { addEditWorkingdayClusterData, updateLnAData } from "../../redux/actions/lna/lnaaction";
import { DatePickerComponent, TimePickerComponent } from "@syncfusion/ej2-react-calendars";
import { getMySqlDateFormat } from "../../app/other/commonfunction";
import ProgressBar from "../progressbar/progressbar";

const useStyles = makeStyles({
  root: {
    "& .MuiPopover-root": {
      "& .MuiPaper-root.MuiPopover-paper.MuiPaper-elevation8.MuiPaper-rounded": {
        top: "350px !important"
      },
    }
  }
});

const SAMPLECSS = `
input.e-input, textarea.e-input, .e-input-group, .e-input-group.e-control-wrapper {
  font-size: 12px;
}
`
const WorkingdayCluster = (props) => {
  //NOSONAR
  let {
    authUser,
    editClusterId,
    openPopup,
    officeCountry,
    officeLocation,
    dayBreakData,
    companyName,
  } = props;

  const [state, setState] = useState({
    workingdayClusterUid: 0,
    workingdayName: null,
    workingdayDescription: "",
    date: "",
    startTime: null,
    endTime: null,
    minHrs: null,
    countryId: "",
    cityId: null,
    companyId: "",
    workingDayType: "",
    dayBreakId: 0,
    isActive: "1",
  });
  const [initialLoad, setInitialLoad] = useState(false)
  const [cityList, setCityList] = useState([]);

  const validOptions = {
    rules: {
      countryNameErr: {
        required: [true, enterCoutnry],
      },
      workingdayNameErr: {
        required: [true, pleaseEnterWorkingDay],
        regex: [regexOnlyLettersAndSpaces, pleaseEnterWorkingDay],
        minLength: [1, pleaseEnterWorkingDay],
        maxLength: [50, "Should be maximum of 50 characters only."]
      },
      DateErr: {
        required: [true, enterDate],
      },

      companyListErr: {
        required: [true, company],
      },
      selectTypeErr: {
        required: [true, pleaseEnterAttendanceType],
      },
      selectDayBreakErr: {
        required: [true, selectDayBreak],
      },
    },
  };


  useEffect(() => {
    props.updateLnAData({ popUpSource: 'Working Day Master' })
    getData();
  }, []);
  useEffect(() => {
    getData();
  }, [editClusterId, openPopup]);
  useEffect(() => {

    if (props.lnaMasterFlag === "updated")

      props.changePopUpHandler("close");

  }, [props.lnaMasterFlag]);
  useEffect(() => {
    setTimeout(() => {
      props.setSubmitClick(saveWorkingDayCluster);
    }, 100);
  }, [state]);

  useEffect(() => {
    getCityList(state.countryId);
  }, [state.countryId]);

  const getData = async () => {
    if (openPopup === "add") {
      resetData()
    } else {
      editClusterId !== null &&
        (async () => {
          setInitialLoad(true)
          const workingdayclusterData =
            await LnAServices.getUniqueWorkingdayClusterData(
              authUser,
              props.currentFinancialYearId,
              editClusterId
            );

          const {
            workingdayName,
            workingdayDescription,
            date,
            startTime,
            endTime,
            minHrs,
            countryId,
            cityId,
            companyId,
            tType,
            dayBreakId,
            isActive,
          } = workingdayclusterData.Result[0];
          cityId && getCityList(countryId)

          let data = {
            workingdayClusterUid: editClusterId,
            workingdayName,
            workingdayDescription,
            date,
            startTime,
            endTime,
            minHrs,
            countryId,
            cityId,
            companyId,
            workingDayType: tType,
            dayBreakId,
            isActive,
          };
          setState((preState) => ({ ...preState, ...data }));
          setTimeout(() => {
            const addFormObject = new FormValidator("#workingDayClusterForm", validOptions);
            addFormObject.validate();
          }, 100)
          setInitialLoad(false)
          document.getElementById('DateErr').innerText = ''
          document.getElementById('minhrsErr').innerText = ''
          document.getElementById('endTimeErr').innerText = ''
          document.getElementById('startTimeErr').innerText = ''
        })();

    }
  }

  const resetData = () => {
    let data = {
      workingdayClusterUid: 0,
      workingdayName: null,
      workingdayDescription: "",
      date: null,
      startTime: null,
      endTime: null,
      minHrs: null,
      countryId: "",
      cityId: null,
      // startDate: '',
      // endDate: '',
      companyId: "",
      workingDayType: "",
      dayBreakId: 0,
      isActive: "1",
    };
    setState((preState) => ({ ...preState, ...data }));
  }
  const getCityList = (pCountryId) => {
    // setcityList([])
    const cityListArray = [];
    officeLocation.map((item) => {
      if (item.optional1 == pCountryId) {
        cityListArray.push(item);
      }
    });
    setCityList(cityListArray);
  };

  const setDay = (e) => {
    let index = new Date(e.value).getDay();

    const weekDay = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    let day = weekDay[index];
    setState((preState) => ({ ...preState, day }));
  };


  const onChangeWorkingDayClusterData = (e, type) => {
    let options = {};
    switch (type) {
      case "country":
        setState({
          ...state,
          countryId: e.value !== null ? e.value : "",
          cityId: null
        });

        getCityList(e.value);
        options = validOptions.rules['countryNameErr'];
        break;
      case "workingdayname":
        setState({
          ...state,
          workingdayName: e.value.trim(),
        });
        options = validOptions.rules['workingdayNameErr']
        break;
      case "decsription":
        setState({
          ...state,
          workingdayDescription: e.value && e.value.trim(),
        });

        break;
      case "date":
        setState({
          ...state,
          date: new Date(e.value),
        });
        options = validOptions.rules['DateErr'];
        if (document.getElementById("DateErr")) {
          document.getElementById("DateErr").innerHTML = "";
        }
        setDay(e.value);
        break;
      case "location":

        setState({
          ...state,
          cityId: e.value !== null ? e.value : null,
        });
        break;
      case "companyList":
        setState({
          ...state,
          companyId: e.itemData !== null ? e.itemData.Id : "",
        });
        options = validOptions.rules['companyListErr'];
        break;
      case "type":
        setState({
          ...state,
          workingDayType: e.itemData.Id.toString(),
        });
        options = validOptions.rules['selectTypeErr'];
        break;
      case "daybreak":
        setState({
          ...state,
          dayBreakId: e.itemData !== null ? e.itemData.Id : "",
        });
        break;
      case "startTime":

        if (state.endTime !== null) {
          let hours1 = new Date(state.endTime).getHours()
          let hours2 = new Date(e).getHours()
          let minutes1 = new Date(state.endTime).getMinutes()
          let minutes2 = new Date(e).getMinutes()
          const date1 = new Date(2022, 0, 1, +hours1, +minutes1, 0);
          const date2 = new Date(2022, 0, 1, +hours2, +minutes2, 0);
          if (date1.getTime() <= date2.getTime()) {
            if (document.getElementById("startTimeErr")) {
              document.getElementById("startTimeErr").innerHTML = StartTimeLessThanEndTime;              
              setState({ ...state, startTime: null });
            }
            setState({ ...state, startTime: null });
            return
          } else {
            setState({ ...state, startTime: e.value });
            if (document.getElementById("startTimeErr")) {
              document.getElementById("startTimeErr").innerHTML = "";
            }
          }
        } else {
          setState({ ...state, startTime: e.value });
          if (document.getElementById("startTimeErr")) {
            document.getElementById("startTimeErr").innerHTML = "";            
          }
        }

        break;
      case "isActive":
        setState((prevState) => ({
          ...prevState,
          isActive: e.checked === true ? "1" : "0",
        }));
        break;
      case "endTime":
        if (state.startTime !== null) {

          let hours1 = new Date(state.startTime).getHours()
          let hours2 = new Date(e).getHours()
          let minutes1 = new Date(state.startTime).getMinutes()
          let minutes2 = new Date(e).getMinutes()
          const date1 = new Date(2022, 0, 1, +hours1, +minutes1, 0);
          const date2 = new Date(2022, 0, 1, +hours2, +minutes2, 0);
          if (date1.getTime() >= date2.getTime()) {
            if (document.getElementById("endTimeErr")) {
              document.getElementById("endTimeErr").innerHTML = EndTimeGreaterThanStartTime;
              setState({ ...state, endTime: null });

            }


          } else {
            setState({ ...state, endTime: e.value });
            if (document.getElementById("endTimeErr")) {
              document.getElementById("endTimeErr").innerHTML = '';
            }
            if (document.getElementById("startTimeErr")) {
              document.getElementById("startTimeErr").innerHTML = '';
            }
          }
        }
        break;
      case "minhrs":
        setState({ ...state, minHrs: e.value, });
        if (state.minHrs === null) {
          document.getElementById('minhrsErr').innerText = pleaseSetMinHrs
        } else {
          document.getElementById('minhrsErr').innerText = ""
        }
        break;
      default:
        break;
    }
    if (options !== null && options !== undefined) {
      if (Object.keys(options).length > 0) {
        setTimeout(() => {
          const addFormObject = new FormValidator(
            "#workingDayClusterForm",
            options
          );
          addFormObject.validate();
        }, 100);
      }
    }

  };

  const saveWorkingDayCluster = () => {
    const {
      workingdayClusterUid,
      workingdayName,
      workingdayDescription,
      date,
      startTime,
      endTime,
      minHrs,
      countryId,
      cityId,
      companyId,
      workingDayType,
      dayBreakId,
      isActive,
    } = state;



    let validateDates = true;
    if (state.startTime === "" || state.startTime === null) {
      document.getElementById("startTimeErr").innerHTML =
        pleaseEnterStartTime;
      validateDates = false;
    }
    if (state.endTime === "" || state.endTime === null) {
      document.getElementById("endTimeErr").innerHTML =
        pleaseEnterEndTime;
      validateDates = false;
    }

    if (state.startTime !== null && state.endTime !== null) {
      if (new Date(state.startTime) >= new Date(state.endTime)) {
        document.getElementById("startTimeErr").innerHTML =
          StartTimeLessThanEndTime;
        validateDates = false;
      }
    }

    if (state.minHrs === null) {
      document.getElementById('minhrsErr').innerText = pleaseSetMinHrs
    }

    if (state.minHrs === null) {
      document.getElementById('minhrsErr').innerText = pleaseSetMinHrs
    }

    const addFormObject = new FormValidator(
      "#workingDayClusterForm",
      validOptions
    );
    if (addFormObject.validate() && validateDates) {
      setInitialLoad(true)
      let hD = getMySqlDateFormat(date); //output 2020-12-12 00:00:00
      let workingdayClusterSaveData = {
        userId: authUser,
        type: workingdayClusterUid > 0 ? "EDIT" : "ADD",
        // fyId: props.currentFinancialYearId,
        workingdayClusterUid,
        workingdayName,
        workingdayDescription,
        date: hD,
        startTime: `${new Date(startTime).getHours()}:${new Date(
          startTime
        ).getMinutes()}`,
        endTime: `${new Date(endTime).getHours()}:${new Date(
          endTime
        ).getMinutes()}`,
        minHrs: `${new Date(minHrs).getHours()}:${new Date(
          minHrs
        ).getMinutes()}`,
        countryId,
        cityId,
        companyId,
        workingDayType,
        dayBreakId,
        isActive,
      };
      props.addEditWorkingdayClusterData(workingdayClusterSaveData);
      setTimeout(() => {
        setInitialLoad(false)
      }, 10)
    }

  };

  const descriptionBox = `.e-outline textarea, textarea.e-outline, .e-outline.e-float-input textarea, .e-outline.e-float-input.e-control-wrapper textarea, .e-outline.e-float-input.e-input-group textarea {
    box-sizing: border-box;
    min-height: 100px;
  }`
  return (
    <div>
      <div
        id="workingDayClusterForm"
        style={{ height: "61.5vh", overflowY: "scroll" }}
      >
        <style>{SAMPLECSS}</style>
        <div
          className="col-xs-12 col-sm-12 col-lg-12 col-xl-12 mt-lg"
          style={{ paddingBottom: "0px" }}
        >
          <TextBoxComponent
            id="workingdayName"
            value={state.workingdayName}
            autocomplete={'off'}
            name="workingdayNameErr"
            data-msg-containerid="workingdayNameErr"
            placeholder="Working Day Name *"
            floatLabelType="Auto"
            cssClass="e-outline minimize-height"
            onChange={(e) => onChangeWorkingDayClusterData(e, "workingdayname")}
            htmlAttributes={{ maxlength: 50 }}
          />
          <div id="workingdayNameErr" className="error-div" />
        </div>

        <div className="col-xs-12 col-sm-12 col-lg-12 col-xl-12 mt-lg">
          <ComboBoxComponent
            id="companyList"
            dataSource={companyName}
            fields={{ text: "Name", value: "Id" }}
            value={state.companyId}
            data-msg-containerid="companyListErr"
            name="companyListErr"
            allowCustom={false}
            floatLabelType="Auto"
            cssClass="e-outline minimize-height"
            placeholder="Company *"
            select={(e) => onChangeWorkingDayClusterData(e, "companyList")}
          />
          <div
            id="companyListErr"
            className="e-input-group e-float-icon-left error-div"
          />
        </div>

        <div
          className="col-xs-12 col-sm-12 col-lg-12 col-md-12 workingdayDesc"
          style={{ paddingLeft: "15px", paddingRight: "15px" }}
        >
          <style>{descriptionBox}</style>
          <TextBoxComponent
            style={{ minHeight: 100 }}
            id={'description'}
            placeholder={"Description "}
            cssClass="e-outline textarea minimize-height"
            floatLabelType="Auto"
            // input={onInput}
            autocomplete={'off'}
            // ref={textareaObj}
            name="descriptionErr"
            data-msg-containerid="descriptionErr"
            multiline
            inputProps={{
              required: true,
              minLength: 0,
            }}
            htmlAttributes={{ maxlength: 500 }}
            onChange={(e) => onChangeWorkingDayClusterData(e, "decsription")}
            value={state.workingdayDescription}
          />
          <div id="descriptionErr" className="error-div" />
        </div>

        <div className="col-xs-12 col-sm-12 col-lg-12 col-xl-12">
          <div
            className="col-xs-6 col-sm-6 col-lg-6 col-md-6 mt-lg"
            style={{ paddingLeft: "0px" }}
          >
            <ComboBoxComponent
              id="countryName"
              dataSource={officeCountry}
              fields={{ text: "Name", value: "Id" }}
              value={state.countryId}
              data-msg-containerid="countryNameErr"
              name="countryNameErr"
              allowCustom={false}
              floatLabelType="Auto"
              cssClass="e-outline minimize-height"
              placeholder="Country *"
              onChange={(e) => onChangeWorkingDayClusterData(e, "country")} />
            <div
              id="countryNameErr"
              className="e-input-group e-float-icon-left error-div"
            />
          </div>

          <div
            className="col-xs-6 col-sm-6 col-lg-6 col-md-6 mt-lg"
            style={{ paddingLeft: "0px", paddingRight: "0px" }}
          >
            {(cityList.length > 0 || props.openPopup == 'add') && <ComboBoxComponent
              id="locationCity"
              dataSource={cityList}
              fields={{ text: "Name", value: "Id" }}
              value={state.cityId}
              data-msg-containerid="locationCityErr"
              name="locationCityErr"
              allowCustom={false}
              floatLabelType="Auto"
              cssClass="e-outline minimize-height"
              placeholder="Location/City "
              onChange={(e) => onChangeWorkingDayClusterData(e, "location")}
            />}
          </div>
        </div>

        <div
          className="col-xs-12 col-sm-12 col-lg-12 col-xl-12"
          style={{ paddingBottom: "0px" }}
        >
          <ComboBoxComponent
            id="selectType"
            dataSource={[
              { Name: "Fixed", Id: "1" },
              { Name: "Optional", Id: "0" },
            ]}
            fields={{ text: "Name", value: "Id" }}
            value={state.workingDayType}
            data-msg-containerid="selectTypeErr"
            name="selectTypeErr"
            allowCustom={false}
            floatLabelType="Auto"
            cssClass="e-outline minimize-height"
            placeholder="Type *"
            select={(e) => onChangeWorkingDayClusterData(e, "type")}
          />
          <div
            id="selectTypeErr"
            className="e-input-group e-float-icon-left error-div"
          />
        </div>

        <div className="col-xs-12 col-sm-12 col-lg-12 col-xl-12 mb-md1">
          <DatePickerComponent
            style={{ width: "100%" }}
            id="workingDayDatePicker"
            name="DateErr"
            data-msg-containerid="DateErr"
            format="dd-MMM-yyyy"
            value={state.date}
            floatLabelType="Auto"
            cssClass="e-outline"
            firstDayOfWeek={1}
            placeholder="Date *"
            onChange={(e) => onChangeWorkingDayClusterData(e, "date")}
          />
          <div
            id="DateErr"
            className="e-input-group e-float-icon-left error-div"
          />
        </div>

        <div className="col-xs-12 col-sm-12 col-lg-12 col-xl-12 ">
          <div className="col-xs-6 col-sm-6 col-lg-6 col-xl-6" style={{ paddingLeft: "0px" }}>
            <TimePickerComponent
              style={{ width: "100%" }}
              id="startTime"
              name="startTimeErr"
              placeholder="Start Time *"
              data-msg-containerid="startTimeErr"
              format="HH:mm"
              floatLabelType="Auto"
              cssClass="e-outline"
              allowEdit={false}
              step={30}
              value={state.startTime}
              onChange={(e) => onChangeWorkingDayClusterData(e, "startTime")} />
            <div
              id="startTimeErr"
              className="e-input-group e-float-icon-left error-div"
            />
          </div>

          <div
            className="col-xs-6 col-sm-6 col-lg-6 col-xl-6" style={{ paddingLeft: "0px", paddingRight: "0px" }}>
            <TimePickerComponent
              style={{ width: "100%" }}
              id="endTime"
              name="endTimeErr"
              placeholder="End Time *"
              data-msg-containerid="endTimeErr"
              format="HH:mm"
              floatLabelType="Auto"
              cssClass="e-outline"
              allowEdit={false}
              step={30}
              value={state.endTime}
              onChange={(e) => onChangeWorkingDayClusterData(e, "endTime")}
            />
            <div
              id="endTimeErr"
              className="e-input-group e-float-icon-left error-div"
            />
          </div>
        </div>

        <div className="col-xs-12 col-sm-12 col-lg-12 col-xl-12 mt-xs">
          <TimePickerComponent
            style={{ width: "100%" }}
            id="minhrs"
            name="minhrsErr"
            placeholder="Minimum Hours  *"
            data-msg-containerid="minhrsErr"
            format="HH:mm"
            floatLabelType="Auto"
            cssClass="e-outline"
            allowEdit={false}
            step={30}
            value={state.minHrs}
            onChange={(e) => onChangeWorkingDayClusterData(e, "minhrs")}

          />
          <div
            id="minhrsErr"
            className="e-input-group e-float-icon-left error-div"
          />
        </div>

        <div className="col-xs-12 col-sm-12 col-lg-12 col-xl-12 mt-xs">
          <ComboBoxComponent
            id="selectDayBreak"
            dataSource={dayBreakData}
            fields={{ text: "Name", value: "Id" }}
            value={state.dayBreakId}
            data-msg-containerid="selectDayBreakErr"
            name="selectDayBreakErr"
            allowCustom={false}
            floatLabelType="Auto"
            cssClass="e-outline minimize-height"
            placeholder="Day Break *"
            select={(e) => onChangeWorkingDayClusterData(e, "daybreak")}
          />
          <div
            id="selectDayBreakErr"
            className="e-input-group e-float-icon-left error-div"
          />
        </div>
      </div>
      <div className="loader-new1" id="spinnerContainer" >
        {initialLoad && <ProgressBar />}
      </div>
    </div>
  );
};

const mapStateToProps = ({ meetingmaster, auth, lnareducer }) => {
  const { authUser } = auth;
  const { currentFinancialYearId } = meetingmaster;
  const { officeCountry, officeLocation, dayBreakData, companyName, lnaMasterFlag } =
    lnareducer;
  return {
    authUser,
    officeCountry,
    officeLocation,
    currentFinancialYearId,
    dayBreakData,
    companyName,
    lnaMasterFlag
  };
};

export default connect(mapStateToProps, { addEditWorkingdayClusterData, updateLnAData })(
  WorkingdayCluster
);
