/**
 * @author: Vivek Khodade.
 * @description This middleware use for okr post api.
 * Created: 24-11-2020
 */
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import axios from "axios";
import {
  UPDATE_USER_PRODUCTIVE_ALLOCATION, ADD_EDIT_COST_SHARING_NEWREQUEST, ACCEPT_REJECT_REQUEST, ADD_EDIT_OKR_OBJETVE_KPI, APPROVE_REJECT_OKR_OBJETVE_KPI,
  SET_USER_OKR_OBJECTIVE_KPI, SET_OKR_USER_KPI, CREATE_UPDATE_NEW_OBJ, CREATE_UPDATE_NEW_KPI, SUBMIT_APPROVAL_ACTION, FILL_OBJECTIVE_DETAILS, UPDATE_LP_VARIABLE_LINK,
  UPDATE_OKR_LOCK_STATUS, UPDATE_OKR_ORDER_IDS, SAVE_OKR_BLOCKER_MASTER, SAVE_OKR_RATING_SYSTEM,
  UPDATE_OKR_MASTER_ITEM_STATUS,
  SAVE_OKR_RATING_MASTER,
  SAVE_AXIS_CUT_OFF,
  SAVE_OKR_PERFORMANCE_POTENTIAL_MASTER,
  SAVE_OKR_FREQUENCY_MASTER,
  SAVE_SUB_POTENTIAL_CALCULATION,
  ADMIN_EDIT_ADD_CAL,
  SAVE_OKR_AXIS_MASTER,
  SAVE_OKR_POTENTIAL_CALCULATION_MASTER
} from "../../constant/okr/okrconstant";
import { urlConfig } from "../../../config/config";
import { getNotify } from "../../../app/other/commonfunction";
import {
  ERROR, SUCCESS, objectiveApproved, objectiveRejected,
  userObjectiveSavedSuccessfully, userObjectiveUpdatedSuccessfully, userObjectiveFillSavesSuccessfully, userKpiFillSavesSuccessfully,
  userKPISavedSuccessfully, userKPIUpdatedSuccessfully, userObjectiveKPISavesSuccessfully, pendingUserKPIUpdatedSuccessfully,
  pendingUserKPISavedSuccessfully, roleObjDeleteSuccessfully, roleKPIDeleteSuccessfully, disableObjSuccessfully, EnableObjSuccessfully, ObjAllocatedSuccessfully, userProductiveAllocationUpdateMessage,
  userOkrSavedDraftSuccessfully, userOkrApprovedSuccessfully, userOkrRejectedSuccessfully, userOkrSavedSuccessfully,
  OkrApprovedSuccessfully, OkrRejectedSuccessfully, OkrRemovedSuccessfully, OkrAssignedSuccessfully, OkrAllocatedToUserSuccessfully,
  OkrSavedAsDraftSuccessfully, OkrImportedSuccessfully, OkrNewRequestAddedSuccessfully, requestAcceptedSuccessfully, updateOKRLockSuccess,
  requestRevokedSuccessfully, requestDeniedSuccessfully, userObjectiveFillClosedSuccessfully, userObjectiveFillDeactivedSuccessfully, okrSortingSuccess,
  blockerMasterAddMessage, blockerMasterEditMessage, blockerMasterActiveMessage, blockerMasterInactiveMessage, ratingMasterAddMessage, ratingMasterEditMessage, ratingSystemActiveMessage,
  ratingSystemInactiveMessage, ratingSystemAddMessage, ratingSystemEditMessage, ratingMasterActiveMessage, ratingMasterInactiveMessage, axisCutOffAddMessage, axisCutOffEditMessage,
  performanceMasterActiveMessage, performanceMasterInactiveMessage, performanceMasterAddMessage, performanceMasterEditMessage, frequencyMasterAddMessage,
  subPotentialCalculationEditMessage, subPotentialCalculationAddMessage, frequencyMasterEditMessage, axisCutOffActiveMessage, axisCutOffInactiveMessage,
  frequencyMasterActiveMessage, frequencyMasterInactiveMessage, potentialMasterActiveMessage, ppotentialMasterInactiveMessage, potentialMasterAddMessage,
  potentialMasterEditMessage, subPotentialCalculationActiveMessage, subPotentialCalculationInactiveMessage, axisMasterAddMessage, axisMasterEditMessage, adminAddEditCalMessage
} from "../../../assets/constants/constants";
import { setCostSharingDetails, addUpdateObjectveKpi, updateOKRData, setCommonMasterLoading } from '../../actions/okr/okractions'
import { AXIS_CUTOFF, FREQUENCY, NINE_BLOCKER, PERFORMANCE, RATING_MASTER, POTENTIAL, RATING_SYSTEM, SUB_POTENTIAL_CALCULATION } from "../../constant/meetingcategoryconstant";
// Author :- Vivek Khodade
const updateUserProductiveAllocationDetailsRequest = async (data) => {
  // eslint-disable-next-line
  return axios
    .post(
      `${urlConfig.tmlcApiUrl}userproductiveallocation`,
      data,
      {
        headers: {
          authorization: localStorage.getItem("jwtToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((result) => result)
    .catch((error) => error.response);

};
// Author :- Vivek Khodade
const addEditObjectveKpiDetailsRequest = async (data) => {
  let userId = data.userId;
  let objectiveId = data.objectiveId;
  delete data.userId;
  delete data.objectiveId;
  // eslint-disable-next-line
  let url = '';
  if (objectiveId === 0) {
    url = `${urlConfig.tmlcApiUrl}objective/${userId}`;
  } else {
    url = `${urlConfig.tmlcApiUrl}objective/${userId}/${objectiveId}`;
  }
  return axios
    .post(
      url,
      data,
      {
        headers: {
          authorization: localStorage.getItem("jwtToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((result) => result)
    .catch((error) => error.response);
};
// Author :- Vivek Khodade
const approveRejectObjectveKpiDetailsRequest = async (data) => {
  let userId = data.userId;
  let objectiveId = data.objectiveId;
  delete data.userId;
  delete data.objectiveId;
  // eslint-disable-next-line
  return axios
    .post(
      `${urlConfig.tmlcApiUrl}objective/approve/${userId}/${objectiveId}`,
      data,
      {
        headers: {
          authorization: localStorage.getItem("jwtToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((result) => result)
    .catch((error) => error.response);
};
// Author :- Prashant Waphare | 25-11-2020
const addEditCostSharing = async (data) => {
  return axios
    .post(
      `${urlConfig.tmlcApiUrl}okraddeditcostsharing`,
      data,
      {
        headers: {
          authorization: localStorage.getItem("jwtToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((result) => result)
    .catch((error) => error.response);
};
// Author :- Prashant Waphare | 26-11-2020
const acceptRevokeRequestAction = async (data) => {
  return axios
    .post(
      `${urlConfig.tmlcApiUrl}okrcostsharingupdatestatus`,
      data,
      {
        headers: {
          authorization: localStorage.getItem("jwtToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((result) => result)
    .catch((error) => error.response);
};

const setOKRUserKPIDetailsRequest = async (data) => {
  let userId = data.userId;
  delete data.userId;
  let apiPartStr = 'userkpi';

  if (data.spType && data.spType === 'draftUpdate') {
    delete data.spType;
    apiPartStr = 'userkpidraftstatus';
  }
  return axios
    .post(
      `${urlConfig.tmlcApiUrl}objective/${apiPartStr}/${userId}`,
      data,
      {
        headers: {
          authorization: localStorage.getItem("jwtToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((result) => result)
    .catch((error) => error.response);
}

const setUserOKRObjectiveKPIDetailsRequest = async (data) => {
  let userId = data.userId;
  delete data.userId;
  return axios
    .post(
      `${urlConfig.tmlcApiUrl}objective/user/${userId}`,
      data,
      {
        headers: {
          authorization: localStorage.getItem("jwtToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((result) => result)
    .catch((error) => error.response);
}
// Author :- Prashant Waphare | 13-04-2021 | call to API for add edit objective details
const callAddUpdateObjectiveDetails = async (data) => {
  return axios
    .post(
      `${urlConfig.tmlcApiUrl}addUpdateObjectiveData`,
      data,
      {
        headers: {
          authorization: localStorage.getItem("jwtToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((result) => result)
    .catch((error) => error.response);
}
// Author :- Prashant Waphare | 14-04-2021 | call to API for add edit kpi details
const callAddUpdateKPIDetails = async (data) => {
  return axios
    .post(
      `${urlConfig.tmlcApiUrl}addUpdateKpiData`,
      data,
      {
        headers: {
          authorization: localStorage.getItem("jwtToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((result) => result)
    .catch((error) => error.response);
}
// Author :- Prashant Waphare | 16-04-2021 | call to API for submit Approval objectives.
const callSubmitApprovalDetails = async (data) => {
  return axios
    .post(
      `${urlConfig.tmlcApiUrl}submitApproveObjective`,
      data,
      {
        headers: {
          authorization: localStorage.getItem("jwtToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((result) => result)
    .catch((error) => error.response);

}
// Author :- Vivek Khodade | 26-04-2021 | call to API for fill Business Objective Details.
const fillBusinessObjectiveDetailsRequest = async (data) => {
  const userId = data.userId;
  delete data.userId
  return axios
    .post(
      `${urlConfig.tmlcApiUrl}fillObjectiveDetails/${userId}`,
      data,
      {
        headers: {
          authorization: localStorage.getItem("jwtToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((result) => result)
    .catch((error) => error.response);
}
// Author :- Vivek Khodade | 29-06-2021 | call to API for update LP Variable Link Details
const updateLPVariableLinkDetailsRequest = async (data) => {
  return axios
    .post(
      `${urlConfig.tmlcApiUrl}updateLPVariableLinkDetails`,
      data,
      {
        headers: {
          authorization: localStorage.getItem("jwtToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((result) => result)
    .catch((error) => error.response);
}

const updateOKRLockTMRatingMonthRequest = async (data) => {
  return axios
    .post(
      `${urlConfig.tmlcApiUrl}okrlockmonth`,
      data,
      {
        headers: {
          authorization: localStorage.getItem("jwtToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((result) => result)
    .catch((error) => error.response);
};
const updateOKROrdersIdsDetailsRequest = async (data) => {
  return axios
    .post(
      `${urlConfig.tmlcApiUrl}okrorderchange`,
      data,
      {
        headers: {
          authorization: localStorage.getItem("jwtToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((result) => result)
    .catch((error) => error.response);
};

// Author :- Vivek Khodade
function* updateUserProductiveAllocationDetails({ payload }) {
  let msgFlag = payload.sendMessage
  delete payload.sendMessage;
  const userProductAllocationDetails = yield call(updateUserProductiveAllocationDetailsRequest, payload);
  if (userProductAllocationDetails.status === 200) {
    setTimeout(() => {

      if (msgFlag === 'Yes') {
        getNotify(SUCCESS, userProductiveAllocationUpdateMessage);
      }

    }, 100);
    yield put(addUpdateObjectveKpi({ refreshProdAllocation: "updated", }));
  } else {
    getNotify(ERROR, userProductAllocationDetails.data.message.replace("payload: ", ""));
  }
}
// Author :- Vivek Khodade
function* addEditObjectveKpiDetails({ payload }) { // NOSONAR
  let data = payload;
  const objectiveKpiDetails = yield call(addEditObjectveKpiDetailsRequest, payload);
  if (objectiveKpiDetails.status === 200) {
    setTimeout(() => {
      if (data.isDraft === 'Y') {
        getNotify(SUCCESS, userOkrSavedDraftSuccessfully);
      }
      if (data.approvalStatus === '1' && data.isDraft === 'N') {
        getNotify(SUCCESS, userOkrApprovedSuccessfully);
      }
      if (data.approvalStatus === '2' && data.isDraft === 'N') {
        getNotify(SUCCESS, userOkrRejectedSuccessfully);
      }
      if (data.isDraft === 'N' && data.approvalStatus !== '1' && data.approvalStatus !== '2') {
        getNotify(SUCCESS, userOkrSavedSuccessfully);
      }

    }, 100);
  } else {
    getNotify(ERROR, objectiveKpiDetails.data.message.replace("payload: ", ""));
  }
  if (objectiveKpiDetails.status === 200 && data.approvalStatus === null && (data.isDraft !== "Y" && data.isDraft !== '1')) {
    yield put(addUpdateObjectveKpi({ addObjectStatus: "updated" }));
  }
  if (objectiveKpiDetails.status === 200 && data.isDraft === 'Y' && data.approvalStatus === null) {
    yield put(addUpdateObjectveKpi({ addObjectDraftStatus: "updated", setObjectiveDraftIds: objectiveKpiDetails.data.Result }));
  }
  if (objectiveKpiDetails.status === 200 && (data.approvalStatus === '1' || data.approvalStatus === '2')) {
    yield put(addUpdateObjectveKpi({ editObjectStatus: "updated" }));
  }
}
// Author :- Vivek Khodade
function* approveRejectObjectveKpiDetails({ payload }) {// NOSONAR
  const approvalStatus = payload.approvalStatus
  const approverType = payload.approverType
  const objectiveKpiDetails = yield call(approveRejectObjectveKpiDetailsRequest, payload);
  if (objectiveKpiDetails.status === 200) {
    setTimeout(() => {
      if (approvalStatus === "Approved")
        getNotify(SUCCESS, OkrApprovedSuccessfully);
      else if (approvalStatus === "Rejected")
        getNotify(SUCCESS, OkrRejectedSuccessfully);
      else if (approvalStatus === "Removed" || approvalStatus === "Remove") {
        getNotify(SUCCESS, OkrRemovedSuccessfully);
      } else if (approverType === 'HEAD') {
        if (approvalStatus === '1') {
          getNotify(SUCCESS, objectiveApproved);
        }
        if (approvalStatus === '2') {
          getNotify(SUCCESS, objectiveRejected);
        }
      }
    }, 100);
    if (approverType === 'HEAD') {
      yield put(addUpdateObjectveKpi({ refreshPendingStatus: "updated", cancelPendingApproval: "cancel" }));
    }
  } else {
    getNotify(ERROR, objectiveKpiDetails.data.message.replace("payload: ", ""));
  }
}


function* setOKRUserKPIDetails({ payload }) {
  const { okrType } = payload
  const userOkrMapping = yield call(setOKRUserKPIDetailsRequest, payload);
  if (userOkrMapping.status === 200 && payload.isDraft === 'N') {
    setTimeout(() => {
      getNotify(SUCCESS, okrType === "1" ? OkrAssignedSuccessfully : OkrAllocatedToUserSuccessfully);
    }, 100);
    yield put(addUpdateObjectveKpi({ userObjectStatus: "Saved" + okrType, reRenderComponent: 'updated' }));
  } else if (userOkrMapping.status === 200 && payload.isDraft === 'Y') {
    setTimeout(() => {
      getNotify(SUCCESS, OkrSavedAsDraftSuccessfully);
    }, 100);
    yield put(addUpdateObjectveKpi({ userObjectStatus: "SavedAsDraft" + okrType }));
  } else {
    yield put(addUpdateObjectveKpi({ userObjectStatus: "Error" + okrType }))

    getNotify(ERROR, userOkrMapping.result.message.replace("payload: ", ""));
  }

}

function* setUserOKRObjectiveKPIDetails({ payload }) {
  const { okrType } = payload
  const userOkrMapping = yield call(setUserOKRObjectiveKPIDetailsRequest, payload);
  if (userOkrMapping.status === 200) {
    setTimeout(() => {
      getNotify(SUCCESS, okrType === "1" ? OkrImportedSuccessfully : OkrAllocatedToUserSuccessfully);
    }, 100);
    yield put(addUpdateObjectveKpi({
      userObjectStatus: "Saved" + okrType, reRenderComponent: 'updated', updateBuDraftFlag: 'updated', resetCheckedData: 'updated',
      lastSubmittedBusinessOkrObjId: userOkrMapping.data.Result[0].objectiveId
    }));

  }
  // else if (userOkrMapping.status === 200 && payload.isDraft === 'Y') {
  //   setTimeout(() => {
  //     getNotify(SUCCESS, okrType === "1" ? "OKR saved as a draft successfully." : "KPI saved as a draft successfully.");
  //   }, 100);
  //   yield put(addUpdateObjectveKpi({ userObjectStatus: "SavedAsDraft" + okrType }));
  // } 
  else {
    // yield put(addUpdateObjectveKpi({ userObjectStatus: "Error" + okrType }))
    getNotify(ERROR, userOkrMapping.result.message.replace("payload: ", ""));
  }

}

// Author :- Prashant Waphare | 25-11-2020
function* addEditcostSharingDetails({ payload }) {
  const costSharing = yield call(addEditCostSharing, payload);
  if (costSharing.data.result.code === 200) {
    setTimeout(() => {
      getNotify(SUCCESS, OkrNewRequestAddedSuccessfully);
    }, 100);
    yield put(setCostSharingDetails({ newRequestStatus: "updated", employeeId: 0, sharedWith: 0, startDate: new Date(), endDate: new Date(), percSalaryShared: 0, amountShared: 0, currencyId: 0, description: '', totalCostSharing: 0 }));
  } else {
    getNotify(ERROR, costSharing.data.result.message.replace("payload: ", ""));
  }
}
// Author :- Prashant Waphare | 26-11-2020
function* acceptRevoke({ payload }) {
  const status = yield call(acceptRevokeRequestAction, payload);
  if (status.data.result[0].code === 200) {
    if (payload.acceptanceStatus === '1') {
      setTimeout(() => {
        getNotify(SUCCESS, requestAcceptedSuccessfully);
      }, 100);
    } else if (payload.acceptanceStatus === '2') {
      setTimeout(() => {
        getNotify(SUCCESS, requestRevokedSuccessfully);
      }, 100);
    } else {
      setTimeout(() => {
        getNotify(SUCCESS, requestDeniedSuccessfully);
      }, 100);
    }
    yield put(setCostSharingDetails({ newRequestStatus: "updated" }));
  } else {
    getNotify(ERROR, status.data.result.message.replace("payload: ", ""));
  }
}
// Author :- Prashant Waphare | 13-04-2021 | call to API for add edit objective details
function* addUpdateNewObjectiveDetails({ payload }) {
  const status = yield call(callAddUpdateObjectiveDetails, payload);
  if (status.data[0].result === "Success") {
    if (payload.objId > 0 && payload.objKpiAddUpdateType === "CreateDraft") {
      setTimeout(() => {
        getNotify(SUCCESS, userObjectiveUpdatedSuccessfully);
      }, 100);
    }
    else if (payload.objId > 0 && payload.objKpiAddUpdateType === "PendingForApproval") {
      setTimeout(() => {
        getNotify(SUCCESS, pendingUserKPISavedSuccessfully);
      }, 100);
      yield put(addUpdateObjectveKpi({ reRenderComponent: "updated", cancelPendingApproval: "cancel" }));
      return;
    } else if (payload.objId > 0 && payload.objKpiAddUpdateType === "ViewLibrary") {
      setTimeout(() => {
        getNotify(SUCCESS, pendingUserKPISavedSuccessfully);
      }, 100);
      yield put(addUpdateObjectveKpi({ refreshViewStatus: "updated", cancelViewApproval: "cancel" }));
      return;
    } else {
      setTimeout(() => {
        getNotify(SUCCESS, userObjectiveSavedSuccessfully);
      }, 100);
    }
    yield put(addUpdateObjectveKpi({ reRenderComponent: "updated", closAlleDialog: "cancel" }));
  } else {
    getNotify(ERROR, status.data[0].result.replace("payload: ", ""));
  }
}
// Author :- Prashant Waphare | 14-04-2021 | call to API for add edit KPI details
function* addUpdateNewKPIDetails({ payload }) {
  const status = yield call(callAddUpdateKPIDetails, payload);
  if (status.data[0].result === "Success") {
    if (payload.kpiIds > 0 && payload.objKpiAddUpdateType === "CreateDraft") {
      setTimeout(() => {
        getNotify(SUCCESS, userKPIUpdatedSuccessfully);
      }, 100);
    } else if (payload.kpiIds > 0 && payload.objKpiAddUpdateType === "PendingForApproval") {
      setTimeout(() => {
        getNotify(SUCCESS, pendingUserKPIUpdatedSuccessfully);
      }, 100);
      yield put(addUpdateObjectveKpi({ reRenderComponent: "updated", cancelPendingApproval: "cancel" }));
      return;
    } else if (payload.kpiIds > 0 && payload.objKpiAddUpdateType === "ViewLibrary") {
      setTimeout(() => {
        getNotify(SUCCESS, pendingUserKPIUpdatedSuccessfully);
      }, 100);
      yield put(addUpdateObjectveKpi({ refreshViewStatus: "updated", cancelViewApproval: "cancel" }));
      return;
    }
    else {
      setTimeout(() => {
        getNotify(SUCCESS, userKPISavedSuccessfully);
      }, 100);
    }
    yield put(addUpdateObjectveKpi({ reRenderComponent: "updated", closAlleDialog: "cancel" }));
  } else {
    getNotify(ERROR, status.data[0].result.replace("payload: ", ""));
  }
}
// Author :- Prashant Waphare | 16-04-2021 | call to API for submit Approval objectives
function* submitApproveObj({ payload }) {
  const status = yield call(callSubmitApprovalDetails, payload);
  if (status.data[0].result === "Success") {
    if (payload.type === "DeleteObj") {
      setTimeout(() => {
        getNotify(SUCCESS, roleObjDeleteSuccessfully);
      }, 100);
    }
    else if (payload.type === "deleteUserDraftOkr") {//nosonar
      setTimeout(() => {
        getNotify(SUCCESS, roleObjDeleteSuccessfully);
      }, 100);
    } else if (payload.type === "DeleteKPI") {
      setTimeout(() => {
        getNotify(SUCCESS, roleKPIDeleteSuccessfully);
      }, 100);
    } else if (payload.type === "DisableObj") {
      setTimeout(() => {
        getNotify(SUCCESS, disableObjSuccessfully);
      }, 100);
      yield put(addUpdateObjectveKpi({ isObjDisabled: "Y" }));
    }
    else if (payload.type === "EnableObj") {
      setTimeout(() => {
        getNotify(SUCCESS, EnableObjSuccessfully);
      }, 100);
    }
    else if (payload.type === "submitOkrToUser") {
      setTimeout(() => {
        getNotify(SUCCESS, ObjAllocatedSuccessfully);
      }, 100);
      yield put(addUpdateObjectveKpi({ moveToActive: "updated", resetCheckedData: 'updated', moveCentralToActive: 'updated' }));
    }
    else if (payload.type === "deleteUserKpi") {
      setTimeout(() => {
        getNotify(SUCCESS, roleKPIDeleteSuccessfully);
      }, 200);
      yield put(addUpdateObjectveKpi({ updateBuDraftFlag: "updated", updateBuActiveFlag: 'updated', updateimportStatus: 'updated', updateCentralStatus: 'updated' }));
    }
    else {
      setTimeout(() => {
        getNotify(SUCCESS, userObjectiveKPISavesSuccessfully);
      }, 100);
    }
    yield put(addUpdateObjectveKpi({ reRenderComponent: "updated" }));
  } else {
    getNotify(ERROR, status.data[0].result.replace("payload: ", ""));
  }
}
// Author :- Vivek Khodade | 26-04-2021 | call to API for fill Business Objective Details
function* fillBusinessObjectiveDetails({ payload }) { // NOSONAR
  const status = yield call(fillBusinessObjectiveDetailsRequest, payload);
  if (status.status === 200) {
    if (payload.type === 'kpi' && payload.isDraft === '1') {
      setTimeout(() => {
        getNotify(SUCCESS, userKpiFillSavesSuccessfully);
      }, 100);
    }
    if (payload.type === 'objective' && payload.isDraft === '1') {
      setTimeout(() => {
        getNotify(SUCCESS, userObjectiveFillSavesSuccessfully);
      }, 100);
    }
    if (payload.type === 'objective' && payload.isDraft === '0') {
      setTimeout(() => {
        getNotify(SUCCESS, userObjectiveFillSavesSuccessfully);
      }, 100);
      yield put(addUpdateObjectveKpi({ lastSubmittedBusinessOkrObjId: status.data.Result[0].objectiveId }));
    }
    if (payload.type === 'copyOKR' && payload.isDraft === '0') {

      yield put(addUpdateObjectveKpi({ lastSubmittedBusinessOkrObjId: status.data.Result[0].objectiveId }));
    }
    if (payload.type === 'deactiveObj' && payload.isDraft === '0') {
      setTimeout(() => {
        getNotify(SUCCESS, userObjectiveFillDeactivedSuccessfully);
      }, 100);
    }
    if (payload.type === 'closeOKR' && payload.isDraft === '0') {
      setTimeout(() => {
        getNotify(SUCCESS, userObjectiveFillClosedSuccessfully);
      }, 100);
    }
    if (payload.type === 'kpi' && payload.isDraft === '0') {
      setTimeout(() => {
        getNotify(SUCCESS, userKpiFillSavesSuccessfully);
      }, 100);
    }
    if (payload.isDraft === '1') {
      yield put(addUpdateObjectveKpi({ updateBuDraftFlag: "updated", updateimportStatus: 'updated', updateCentralStatus: 'updated' }));
    } else {
      if (status.data.Result[0].objectiveId !== null) {
        yield put(addUpdateObjectveKpi({ isNewObjCreated: status.data.Result[0].isNewObjCreated, newObjFreqency: status.data.Result[0].newObjFreqency, newObjStartMonth: status.data.Result[0].newObjStartMonth }));
      }
      yield put(addUpdateObjectveKpi({ updateBuDraftFlag: "updated", updateBuActiveFlag: 'updated', updateimportStatus: 'updated', updateCentralStatus: 'updated' }));
    }
  } else {
    getNotify(ERROR, status.data.message.replace("payload: ", ""));
  }
}
// Author :- Vivek Khodade | 29-06-2021 | call to API for update LP Variable Link Details
function* updateLPVariableLinkDetails({ payload }) { // NOSONAR
  const status = yield call(updateLPVariableLinkDetailsRequest, payload);
  if (status.status === 200) {
    setTimeout(() => {
      getNotify(SUCCESS, "Variable Link is updated");
    }, 100);
    yield put(addUpdateObjectveKpi({ lpStatus: "updated" }));

  } else {
    getNotify(ERROR, status.data[0].result.replace("payload: ", ""));
  }
}

function* updateOKRLockTMRatingMonthDetails({ payload }) {
  const updateOKRLockTMRatingMonth = yield call(updateOKRLockTMRatingMonthRequest, payload);
  if (updateOKRLockTMRatingMonth.status === 200) {
    setTimeout(() => {
      getNotify(SUCCESS, updateOKRLockSuccess);
    }, 100);
    yield put(addUpdateObjectveKpi({ refreshProdAllocation: "updated", }));
  } else {
    getNotify(ERROR, updateOKRLockTMRatingMonth.data.message.replace("payload: ", ""));
  }
}
function* updateOKROrdersIdsDetails({ payload }) {
  const updateOKROrdersIds = yield call(updateOKROrdersIdsDetailsRequest, payload);
  if (updateOKROrdersIds.status === 200) {
    setTimeout(() => {
      getNotify(SUCCESS, okrSortingSuccess);
    }, 100);
    yield put(addUpdateObjectveKpi({ updateBuDraftFlag: "updated" }));
  } else {
    getNotify(ERROR, updateOKRLockTMRatingMonth.data.message.replace("payload: ", ""));
  }
}

/**
 * Function Name: update the productive allocations.
 * Author: Vivek Khodade.
 */
export function* updateUserProductiveAllocation() {
  yield takeEvery(UPDATE_USER_PRODUCTIVE_ALLOCATION, updateUserProductiveAllocationDetails);
}
/**
 * Function Name: add edit the objective and kpis .
 * Author: Vivek Khodade.
 */
export function* addEditObjectveKpi() {
  yield takeEvery(ADD_EDIT_OKR_OBJETVE_KPI, addEditObjectveKpiDetails);
}
/**
 * Function Name: Approve or Reject objective and kpis .
 * Author: Vivek Khodade.
 */
export function* approveRejectObjectveKpi() {
  yield takeEvery(APPROVE_REJECT_OKR_OBJETVE_KPI, approveRejectObjectveKpiDetails);
}
/**
 * Function Name: addEditCostsharingNewRequest
 * Author: Prashant Waphare.
 */
export function* addEditCostsharingNewRequest() {
  yield takeEvery(ADD_EDIT_COST_SHARING_NEWREQUEST, addEditcostSharingDetails);
}
/**
 * Function Name: appceptRevokeRequest
 * Author: Prashant Waphare.
 */
export function* appceptRevokeRequest() {
  yield takeEvery(ACCEPT_REJECT_REQUEST, acceptRevoke);
}
/**
 * Function Name: setUserOKRObjectiveKPI
 * Author: Prashant Waphare.
 */
export function* setUserOKRObjectiveKPI() {
  yield takeEvery(SET_USER_OKR_OBJECTIVE_KPI, setUserOKRObjectiveKPIDetails);
}
/**
 * Function Name: setOKRUserKPI
 * Author: Vinayak Jadhav
 */
export function* setOKRUserKPI() {
  yield takeEvery(SET_OKR_USER_KPI, setOKRUserKPIDetails);
}
/**
 * Author: Prashant Waphare. | 13-04-2021 | function for create and update objective details
 */
export function* addUpdateNewObj() {
  yield takeEvery(CREATE_UPDATE_NEW_OBJ, addUpdateNewObjectiveDetails);
}
/**
 * Author: Prashant Waphare. | 14-04-2021 | function for create and update KPI details
 */
export function* addUpdateNewKPI() {
  yield takeEvery(CREATE_UPDATE_NEW_KPI, addUpdateNewKPIDetails);
}
/**
 * Author: Prashant Waphare. | 16-04-2021 | function for submit approve objectives.
 */
export function* submitApprovalAction() {
  yield takeEvery(SUBMIT_APPROVAL_ACTION, submitApproveObj);
}
/**
 * Author: Vivek Khodade. | 26-04-2021 | function for add fill objective details.
 */
export function* fillObjectiveRequest() {
  yield takeEvery(FILL_OBJECTIVE_DETAILS, fillBusinessObjectiveDetails);
}
/**
 * Author: Vivek Khodade. | 26-04-2021 | function for add fill objective details.
 */
export function* updateLPVariableLink() {
  yield takeEvery(UPDATE_LP_VARIABLE_LINK, updateLPVariableLinkDetails);
}

export function* updateOKRLockTMRatingMonth() {
  yield takeEvery(UPDATE_OKR_LOCK_STATUS, updateOKRLockTMRatingMonthDetails);
}
export function* updateOKROrdersIds() {
  yield takeEvery(UPDATE_OKR_ORDER_IDS, updateOKROrdersIdsDetails);
}


/**
 * Author: Sanjeev. | 13-01-2023 | function for add nine blocker master in okr admin module.
 */
const saveBlockerMasterApiReq = async (data) => {
  let id = data.masterItemId ? data.masterItemId : 0;
  delete data.masterItemId
  return axios
    .post(`${urlConfig.tmlcApiUrl}blocker/${id}`, data, {
      headers: {
        authorization: localStorage.getItem("jwtToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((result) => result)
    .catch((error) => error.response);
};

function* saveBlockerMasterDetails({ payload }) {
  const blockerMasterDetails = yield call(saveBlockerMasterApiReq, payload);
  if (blockerMasterDetails.status === 200) {
    if (blockerMasterDetails.data.Result.result === 'success') {
      if (payload.type === "ADD") {
        getNotify(SUCCESS, blockerMasterAddMessage);
      } else if (payload.type === "EDIT") {
        getNotify(SUCCESS, blockerMasterEditMessage);
      }
    } else {
      getNotify(ERROR, blockerMasterDetails.data.Result.result);
      yield put(updateOKRData({ loading: false }));
    }
    yield put(updateOKRData({ okrMasterFlag: "updated", loading: false }));
  }
  else {
    getNotify(ERROR, blockerMasterDetails.data.message);
    yield put(updateOKRData({ loading: false }));
  }
}

export function* saveBlockerMaster() {
  yield takeEvery(SAVE_OKR_BLOCKER_MASTER, saveBlockerMasterDetails);
}

/**
 * Author: Sanjeev. | 14-01-2023 | function for update okr master item status master in okr admin module.
 */


const updateOkrMasterItemStatusApiReq = async (data) => {
  let userId = data.userId ? data.userId : 0;
  delete data.userId
  return axios
    .post(`${urlConfig.tmlcApiUrl}master/status/${userId}`, data, {
      headers: {
        authorization: localStorage.getItem("jwtToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((result) => result)
    .catch((error) => error.response);
};
function* updateOkrMasterItemStatusDetails({ payload }) {
  const masterItemDetails = yield call(updateOkrMasterItemStatusApiReq, payload);
  if (masterItemDetails.status === 200) {
    switch (masterItemDetails.data.Result.masterId.toString()) {
      case NINE_BLOCKER:
        if (payload.isActive === "1") {
          getNotify(SUCCESS, blockerMasterActiveMessage);
        } else if (payload.isActive === "0") {
          getNotify(SUCCESS, blockerMasterInactiveMessage);
        }
        break;
      case RATING_SYSTEM:
        if (payload.isActive === "1") {
          getNotify(SUCCESS, ratingSystemActiveMessage);
        } else if (payload.isActive === "0") {
          getNotify(SUCCESS, ratingSystemInactiveMessage);
        }
        break;
      case RATING_MASTER:
        if (payload.isActive === "1") {
          getNotify(SUCCESS, ratingMasterActiveMessage);
        } else if (payload.isActive === "0") {
          getNotify(SUCCESS, ratingMasterInactiveMessage);
        }
        break;
      case PERFORMANCE:
        if (payload.isActive === "1") {
          getNotify(SUCCESS, performanceMasterActiveMessage);
        } else if (payload.isActive === "0") {
          getNotify(SUCCESS, performanceMasterInactiveMessage);
        }
        break;
      case POTENTIAL:
        if (payload.isActive === "1") {
          getNotify(SUCCESS, potentialMasterActiveMessage);
        } else if (payload.isActive === "0") {
          getNotify(SUCCESS, ppotentialMasterInactiveMessage);
        }
        break;
      case FREQUENCY:
        if (payload.isActive === "1") {
          getNotify(SUCCESS, frequencyMasterActiveMessage);
        } else if (payload.isActive === "0") {
          getNotify(SUCCESS, frequencyMasterInactiveMessage);
        }
        break;
      case AXIS_CUTOFF:
        if (payload.isActive === "1") {
          getNotify(SUCCESS, axisCutOffActiveMessage);
        } else if (payload.isActive === "0") {
          getNotify(SUCCESS, axisCutOffInactiveMessage);
        }
        break;
      case SUB_POTENTIAL_CALCULATION:
        if (payload.isActive === "1") {
          getNotify(SUCCESS, subPotentialCalculationActiveMessage);
        } else if (payload.isActive === "0") {
          getNotify(SUCCESS, subPotentialCalculationInactiveMessage);
        }
        break;
    }
    yield put(updateOKRData({ okrMasterFlag: "updated" }));
  }
  else {
    getNotify(ERROR, masterItemDetails.data.message);
  }
}

export function* updateOkrMasterItemStatus() {
  yield takeEvery(UPDATE_OKR_MASTER_ITEM_STATUS, updateOkrMasterItemStatusDetails);
}
/** 
* Author: Md Jahir Uddin | 13-01-2023 | function to add or edit rating system 
*/
const saveRatingSystemApiReq = async (data) => {
  let id = data.ratingSystemId;
  let userId = data.userId;
  delete data.userId;
  delete data.ratingSystemId
  return axios
    .post(`${urlConfig.tmlcApiUrl}ratingsystem/${userId}/${id}`, data, {
      headers: {
        authorization: localStorage.getItem("jwtToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((result) => result)
    .catch((error) => error.response);
};

function* saveRatingSystemDetails({ payload }) {
  const status = yield call(saveRatingSystemApiReq, payload);
  let { type } = payload;
  if (status.status === 200) {
    if (status.data.data[0].result === "success") {
      if (type === "ADD") {
        getNotify(SUCCESS, ratingSystemAddMessage);
      } else if (type === "EDIT") {
        getNotify(SUCCESS, ratingSystemEditMessage);
      }
      yield put(updateOKRData({ okrMasterFlag: "updated", loading: false }));
    } else {
      getNotify(ERROR, status.data.data[0].result);
      yield put(updateOKRData({ loading: false }));
    }} else {
    getNotify(ERROR, status.data.message);
    yield put(updateOKRData({ loading: false }));
  }
}

export function* saveRatingSystem() {
  yield takeEvery(SAVE_OKR_RATING_SYSTEM, saveRatingSystemDetails);
}


/** 
* Author: Md Jahir Uddin | 18-01-2023 | function to add or edit rating master 
*/

const saveRatingMasterApiReq = async (data) => {
  let id = data.ratingMasterId;
  let userId = data.userId;
  delete data.userId;
  delete data.ratingMasterId;
  setCommonMasterLoading({ commonMasterLoading: true })
  return axios
    .post(`${urlConfig.tmlcApiUrl}ratingmaster/${userId}/${id}`, data, {
      headers: {
        authorization: localStorage.getItem("jwtToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((result) => result)
    .catch((error) => error.response);
};


const AdminAddEditCalculationReq = async (data) => {
  return axios
    .post(`${urlConfig.tmlcApiUrl}okradmincalculation`, data, {
      headers: {
        authorization: localStorage.getItem("jwtToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      }
    })
    .then((result) => result)
    .catch((error) => error.response);
};

function* saveRatingMasterDetails({ payload }) {
  const status = yield call(saveRatingMasterApiReq, payload);
  let { type } = payload;
  if (status.status === 200) {
    if (status.data.data[0].result === "success") {
      if (type === "ADD") {
        getNotify(SUCCESS, ratingMasterAddMessage);
      } else if (type === "EDIT") {
        getNotify(SUCCESS, ratingMasterEditMessage);
      }
      yield put(updateOKRData({ okrMasterFlag: "updated", loading: false }));
    } else {
      getNotify(ERROR, status.data.data[0].result);
      yield put(updateOKRData({ loading: false }));
    }
  } else {
    getNotify(ERROR, status.data.message);
    yield put(updateOKRData({ loading: false }));
  }
}

export function* saveRatingMaster() {
  yield takeEvery(SAVE_OKR_RATING_MASTER, saveRatingMasterDetails);
}


function* AdminAddEditCalculationDetails({ payload }) {
  const AddEditCalculationDetail = yield call(AdminAddEditCalculationReq, payload);


  if (AddEditCalculationDetail.status === 200) {
    if (AddEditCalculationDetail.data.data === "Success") {
      getNotify(SUCCESS, adminAddEditCalMessage);
      yield put(updateOKRData({ okrMasterFlag: "updated", loading: false, teamMemberMatrixStatus: 'updated' }));
    } else {
      getNotify(ERROR, AddEditCalculationDetail.data.data);
      yield put(updateOKRData({ loading: false }));
    }
  }
  else {
    getNotify(ERROR, AddEditCalculationDetail.data.message);
    yield put(updateOKRData({ loading: false }));
  }
  setCommonMasterLoading({ commonMasterLoading: false })
}

export function* AdminAddEditCalculation() {
  yield takeEvery(ADMIN_EDIT_ADD_CAL, AdminAddEditCalculationDetails);
}

/** 
* Author: Md Jahir Uddin | 20-01-2023 | function to add or edit axis cut off 
*/

const saveAxisCutoffApiReq = async (data) => {
  let id = data.masterItemId;
  let userId = data.userId;
  delete data.userId;
  delete data.masterItemId;
  return axios
    .post(`${urlConfig.tmlcApiUrl}axiscutoff/${userId}/${id}`, data, {
      headers: {
        authorization: localStorage.getItem("jwtToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((result) => result)
    .catch((error) => error.response);
};

function* saveAxisCutOffDetails({ payload }) {
  const status = yield call(saveAxisCutoffApiReq, payload);
  let { type } = payload
  if (status.status === 200) {
    if (status.data.data[0].result === "success") {
      if (type === "ADD") {
        getNotify(SUCCESS, axisCutOffAddMessage);
      } else if (type === "EDIT") {
        getNotify(SUCCESS, axisCutOffEditMessage);
      }
      yield put(updateOKRData({ okrMasterFlag: "updated", loading: false }));
    } else {
      getNotify(ERROR, status.data.data[0].result);
      yield put(updateOKRData({ loading: false }));
    }
  } else {
    getNotify(ERROR, status.data.message);
    yield put(updateOKRData({ loading: false }));
  }
}

export function* saveAxisCutOff() {
  yield takeEvery(SAVE_AXIS_CUT_OFF, saveAxisCutOffDetails);
}

/**
 * Author: Sanjeev. | 24-01-2023 | function for add edit performance potential master in okr admin module.
 */
const savePerformancePotentialApiReq = async (data) => {
  let id = data.masterItemId ? data.masterItemId : 0;
  delete data.masterItemId
  return axios
    .post(`${urlConfig.tmlcApiUrl}performance/${id}`, data, {
      headers: {
        authorization: localStorage.getItem("jwtToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((result) => result)
    .catch((error) => error.response);
};

function* savePerformancePotentialMasterDetails({ payload }) {
  const { masterId } = payload

  // delete payload.masterId
  const performancePotentialMasterDetails = yield call(savePerformancePotentialApiReq, payload);

  if (performancePotentialMasterDetails.status === 200) {
    if (payload.type === "ADD") {
      if (masterId === PERFORMANCE) {
        getNotify(SUCCESS, performanceMasterAddMessage);
      }
      else {
        getNotify(SUCCESS, potentialMasterAddMessage);
      }
    } else if (payload.type === "EDIT") {

      if (masterId === PERFORMANCE) {
        getNotify(SUCCESS, performanceMasterEditMessage);
      }
      else {
        getNotify(SUCCESS, potentialMasterEditMessage);
      }
    }
    yield put(updateOKRData({ okrMasterFlag: "updated", loading: false }));
  }
  else {
    getNotify(ERROR, performancePotentialMasterDetails.data.message);
    yield put(updateOKRData({ loading: false }));
  }
}

export function* savePerformancePotentialMaster() {
  yield takeEvery(SAVE_OKR_PERFORMANCE_POTENTIAL_MASTER, savePerformancePotentialMasterDetails);
}


/**
 * Author: Sanjeev. | 24-01-2023 | function for add edit performance potential master in okr admin module.
 */
const saveFrequencyMasterApiReq = async (data) => {
  let id = data.masterItemId ? data.masterItemId : 0;
  delete data.masterItemId
  return axios
    .post(`${urlConfig.tmlcApiUrl}frequency/${id}`, data, {
      headers: {
        authorization: localStorage.getItem("jwtToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((result) => result)
    .catch((error) => error.response);
};

function* saveFrequencyMasterDetails({ payload }) {
  const frequencylMasterDetails = yield call(saveFrequencyMasterApiReq, payload);

  if (frequencylMasterDetails.status === 200) {
    if (payload.type === "ADD") {
      getNotify(SUCCESS, frequencyMasterAddMessage);
    } else if (payload.type === "EDIT") {
      getNotify(SUCCESS, frequencyMasterEditMessage);
    }
    yield put(updateOKRData({ okrMasterFlag: "updated", loading: false }));
  }
  else {
    getNotify(ERROR, frequencylMasterDetails.data.message);
    yield put(updateOKRData({ loading: false }));
  }
}

export function* savefrequencyMaster() {
  yield takeEvery(SAVE_OKR_FREQUENCY_MASTER, saveFrequencyMasterDetails);
}


/** 
* Author: Md Jahir Uddin | 31-01-2023 | function to add or edit sub potential calculation
*/

const saveSubPotentialCalculationApiReq = async (data) => {
  let id = data.masterItemId;
  let userId = data.userId;
  delete data.userId;
  delete data.masterItemId;
  return axios
    .post(`${urlConfig.tmlcApiUrl}subpotentialcalculation/${userId}/${id}`, data, {
      headers: {
        authorization: localStorage.getItem("jwtToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((result) => result)
    .catch((error) => error.response);
};

function* saveSubPotentialCalculationDetails({ payload }) {
  const status = yield call(saveSubPotentialCalculationApiReq, payload);
  let { type } = payload;
  if (status.data.data[0].result === "success") {
    if (type === "ADD") {
      getNotify(SUCCESS, subPotentialCalculationAddMessage);
    } else if (type === "EDIT") {
      getNotify(SUCCESS, subPotentialCalculationEditMessage);
    }
    yield put(updateOKRData({ okrMasterFlag: "updated", loading: false }));
  } else {
    getNotify(ERROR, status.data.data[0].result);
    yield put(updateOKRData({ loading: false }));
  }
}

export function* saveSubPotentialCalculation() {
  yield takeEvery(SAVE_SUB_POTENTIAL_CALCULATION, saveSubPotentialCalculationDetails);
}

const saveAxisMasterApiReq = async (data) => {
  return axios
    .post(`${urlConfig.tmlcApiUrl}okraxismaster`, data, {
      headers: {
        authorization: localStorage.getItem("jwtToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((result) => result)
    .catch((error) => error.response);
};

function* saveAxisMasterDetails({ payload }) {
  const axisMasterDetails = yield call(saveAxisMasterApiReq, payload);

  if (axisMasterDetails.status === 200) {
    if (axisMasterDetails.data.Result.result === 'success') {
      if (payload.type.toUpperCase() === "ADD") {
        getNotify(SUCCESS, axisMasterAddMessage);
      } else if (payload.type.toUpperCase() === "EDIT") {
        getNotify(SUCCESS, axisMasterEditMessage);
      }
      yield put(updateOKRData({ okrMasterFlag: "updated", loading: false }));
    } else {
      getNotify(ERROR, axisMasterDetails.data.Result.result);
      yield put(updateOKRData({ loading: false }));
    }
  } else {
    getNotify(ERROR, axisMasterDetails.data.message.replace("payload: ", ""));
    yield put(updateOKRData({ loading: false }));
  }
}

export function* saveAxisMaster() {
  yield takeEvery(SAVE_OKR_AXIS_MASTER, saveAxisMasterDetails);
}

//
const savePotentialMasterApiReq = async (data) => {
  let id = data.masterId;
  let userId = data.userId;
  delete data.userId
  delete data.masterId
  delete data.type
  return axios
    .post(`${urlConfig.tmlcApiUrl}potentialcalculationmaster/${userId}/${id}`, data, {
      headers: {
        authorization: localStorage.getItem("jwtToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((result) => result)
    .catch((error) => error.response);
};

function* savePotentialMasterDetails({ payload }) {
  const type = payload.type;
  const potentialMasterDetails = yield call(savePotentialMasterApiReq, payload);
  if (potentialMasterDetails.status === 200) {
    if (potentialMasterDetails.data.data[0].result === 'success') {
      if (type.toUpperCase() === "ADD") {
        getNotify(SUCCESS, potentialMasterAddMessage);
      } else if (type.toUpperCase() === "EDIT") {
        getNotify(SUCCESS, potentialMasterEditMessage);
      }
      yield put(updateOKRData({ okrMasterFlag: "updated", loading: false }));
    } else {
      getNotify(ERROR, potentialMasterDetails.data.data[0].result);
      yield put(updateOKRData({ loading: false }));
    }
  } else {
    getNotify(ERROR, potentialMasterDetails.data.message.replace("payload: ", ""));
    yield put(updateOKRData({ loading: false }));
  }
}

export function* savePotentialMaster() {
  yield takeEvery(SAVE_OKR_POTENTIAL_CALCULATION_MASTER, savePotentialMasterDetails);
}

export default function* rootSaga() {
  yield all([fork(updateUserProductiveAllocation),
  fork(addEditCostsharingNewRequest),
  fork(appceptRevokeRequest),
  fork(addEditObjectveKpi),
  fork(approveRejectObjectveKpi),
  fork(setUserOKRObjectiveKPI),
  fork(setOKRUserKPI),
  fork(addUpdateNewObj),
  fork(addUpdateNewKPI),
  fork(submitApprovalAction),
  fork(fillObjectiveRequest),
  fork(updateLPVariableLink),
  fork(updateOKRLockTMRatingMonth),
  fork(updateOKROrdersIds),
  fork(saveBlockerMaster),
  fork(saveRatingSystem),
  fork(updateOkrMasterItemStatus),
  fork(saveRatingMaster),
  fork(saveAxisCutOff),
  fork(savePerformancePotentialMaster),
  fork(savefrequencyMaster),
  fork(saveSubPotentialCalculation),
  fork(saveAxisMaster),
  fork(savePotentialMaster),
  fork(AdminAddEditCalculation),
  ]);
}
