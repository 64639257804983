/* Author : Vivek Khodade
* Date : 30-Apr-2020
* Description: TMLC IT Screen
*/

import React, { Component } from 'react';
import FunctionsTabs from '../../../../components/functionstabs/functions';
import { Button } from '@material-ui/core';
import NotificationContainer  from '../../../../components/notification/notifycontainer';

export default class OnboardingFunctions extends Component {
  render() {
    return (
      <div>
        <div>
        
          <NotificationContainer />
          <FunctionsTabs />
        </div>
        <Button style={{ cursor: 'none', marginTop:'-21px',height:'1px' }} >.</Button>
      </div>
    );
  }
}
