/**
 * @author Vivek Khodade 
 * @description Apply Leave Form
 * Last Modify Date : 25-02-2022
 * Modify by Vivek Khodade.
 */
import React, { useRef, useEffect } from 'react'
import { ComboBoxComponent } from "@syncfusion/ej2-react-dropdowns";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { FormValidator } from "@syncfusion/ej2-inputs";
import { connect } from 'react-redux'
import { cracterLimit, leaveDateFormat, addDays } from '../../../../app/other/commonfunction';
import { updateLnAData, applyLeave, saveCompOffData } from '../../../../redux/actions/lna/lnaaction';
import LnAService from '../../../../services/lna/lnaservice';
import { DatePickerComponent } from '@syncfusion/ej2-react-calendars';
import ViewMoreDayBreakup from '../leavesapply/viewmoredaybreakup';
import Leavetabs from '../leavesbalance/leavetabs';
import CompOffDetails from '../compoff/view/compoffdetails'
import NotificationContainer from '../../../../components/notification/notifycontainer';
import { BREModuleActions } from '../../../../assets/constants/constants'
import BREServices from "../../../../services/breservice";
import LeaveRules from '../../leaverules';
import ConfirmationPopup from '../../../../utilities/confirmationpopup';

const sampleCss = `.e-outline textarea, textarea.e-outline, .e-outline.e-float-input textarea, .e-outline.e-float-input.e-control-wrapper textarea, .e-outline.e-float-input.e-input-group textarea {
      box-sizing: border-box;
      min-height: 100px;
    }
    `

export const CompOff = (props) => { //NOSONAR
    const {
        authUser,
        leaveType,
        leaveStartDate,
        leaveEndDate,
        leaveReason,
        compOffData,
        leaveApplyLoadName,
        dayBreakText,
        leaveApplicationId,
        dayBreakBackgroundColor,
        leaveStartDateEdit,
        leaveEndDateEdit
    } = props;
    const [state, setState] = React.useState({
        appliedDays: 0,
        leaveClusterStart: {
            q1: null,
            q2: null,
            q3: null,
            q4: null,
        },
        leaveClusterEnd: {
            q1: null,
            q2: null,
            q3: null,
            q4: null,
        },
        applyedLeaveDates: [],
        height: null,
        subHeight: null,
        showList: false,
        showDayBreakup: false,
        minStartDate: null,
        maxStartDate: null,
        minEndDate: null,
        maxEndDate: null,
        fileName: [],
        startDate: null,
        endDate: null, leaveStartDateEdit: null, leaveEndDateEdit: null,
    });
    const textareaObj = useRef(null);
    const [showConform, setShowConform] = React.useState(false)
    const [dataToSave, setDataToSave] = React.useState({})
    const option1 = {
        rules: {
            typeOfLeaveErr: {
                required: [true, "Please select leave type."],
            },
            leaveAppReasonErr: {
                required: [true, "Please enter reason."],
            },

        }
    }
    const clearErrorMessages = () => {
        const errorFields = [
            "leaveAppReasonErr",
            "typeOfLeaveErr",
            "appliedDateErr",
            "leaveClusterDayBreakupErr",
            "leaveAppReasonCount",
            "leaveStartDateErr",
            "leaveToDateErr"

        ];

        errorFields.forEach(field => {
            const elem = document.getElementById(field);
            if (elem) {
                elem.innerHTML = "";
            }
        });
    };
    useEffect(() => {
        window.addEventListener("resize", updateDimensions()); // NOSONAR
        props.updateLnAData({ applyFor: authUser })
        getMasterData();
    }, [])
    const updateDimensions = () => {
        let updateheight = window.innerHeight - 190
        let lavContHeight = window.innerHeight - 335
        setState(preState => ({ ...preState, height: updateheight, notifiresPopupHeight: updateheight, subHeight: lavContHeight }))
    }
    useEffect(() => {
        let data = {
            appliedDays: 0,
            leaveClusterStart: {
                q1: null,
                q2: null,
                q3: null,
                q4: null,
            },
            leaveClusterEnd: {
                q1: null,
                q2: null,
                q3: null,
                q4: null,
            },
            applyedLeaveDates: [],
            dayBreakupDates: [],
            startDate: null,
            endDate: null
        };
        setState(preState => ({ ...preState, ...data }));
        props.updateLnAData({ leaveStartDate: null, leaveEndDate: null, leaveType: null, leaveReason: '', reliever: null, leaveApplyStatus: "" })
    }, [props.leaveApplyStatus === "updated"])

    useEffect(() => {
        if (leaveApplyLoadName === 'leave-edit') {

            /*
            *setQuarterData
            *get First Date
            *get Last Date
            *get start date day break count
            *get end date day break count
            */
            const res = getDayBreakFormatToNormal(dayBreakText)
            getCompOffDates(res.startDate, res.endDate, res.startDateDayBreakCount, res.endDateDayBreakCount, props.leaveApplicationId);
            let data = {
                appliedDays: props.appliedDays,
                leaveClusterStart: res.leaveClusterStart,
                leaveClusterEnd: res.leaveClusterEnd,
                startDate: leaveStartDateEdit,
                endDate: leaveEndDateEdit

            }
            setState(preState => ({ ...preState, ...data }));
            cracterLimit({ value: props.leaveReason }, 'leaveAppReasonCount', '1000');

        }
        fieldsValidation()
    }, [leaveApplyLoadName === 'leave-edit', leaveApplicationId])
    useEffect(() => {
        if (leaveApplyLoadName === 'leave-view') {
            cracterLimit({ value: "" }, 'leaveAppReasonCount', '1000');
        }
    }, [leaveApplyLoadName === 'leave-view'])
    const fieldsValidation = () => {
        document.getElementById('leaveAppReasonErr').innerHTML = "";
        document.getElementById('typeOfLeaveErr').innerHTML = "";
        document.getElementById('leaveClusterDayBreakupErr').innerHTML = "";

    }
    useEffect(() => {
        let validateData = validateDayBreakUp();
        if (props.leaveStartDate !== null && props.leaveEndDate !== null && validateData.leaveStartDayCount > 0) {
            getCompOffDates(props.leaveStartDate, props.leaveEndDate, validateData.leaveStartDayCount, validateData.leaveEndDayCount, leaveApplicationId);
        }
        leaveDatesVaidation()
    }, [props.leaveStartDate, props.leaveEndDate])

    useEffect(() => {
        onCancel();
        props.updateLnAData({ compOffClusterFlag: "" })
    }, [props.compOffClusterFlag === "saved"])

    const leaveDatesVaidation = () => {
        document.getElementById('leaveStartDateErr').innerHTML = "";
        document.getElementById('leaveToDateErr').innerHTML = "";

    }
    const showViewMode = (flag, data, type) => {
        props.updateLnAData({ leaveApplyLoadName: flag })
        if (type === 'Close')
            props.updateLnAData({ leaveApplicationId: 0 })
    }
    const getDayBreakFormatToNormal = (dayBreakString) => {
        if (dayBreakString !== "") {
            let dayBreakArr = dayBreakString.split(',');
            let startLeaveType = 0;
            let endLeaveType = 0;
            let startDaybreakType = 0;
            let endDaybreakType = 0;
            let startDateDayBreakCount = 0;
            let endDateDayBreakCount = 0;
            let startDate = null;
            let endDate = null;
            let leaveClusterStart = {//nosonar
                q1: false,
                q2: false,
                q3: false,
                q4: false,
            };
            let leaveClusterEnd = {//nosonar
                q1: false,
                q2: false,
                q3: false,
                q4: false,
            };

            //For Single date.
            if (dayBreakArr.length === 1) {
                let startDateArr = dayBreakArr[0].split('_');
                startDate = startDateArr[0];
                endDate = startDateArr[0];
                startLeaveType = startDateArr[1];
                endLeaveType = startDateArr[1];
                startDaybreakType = startDateArr[2];
                //Full Day
                if (startLeaveType == '4') {
                    startDateDayBreakCount = 1;
                    leaveClusterStart = {
                        q1: true,
                        q2: true,
                        q3: true,
                        q4: true,
                    };
                }
                //Half Day
                if (startLeaveType == '2') {
                    //First Half
                    if (startDaybreakType == '1') {
                        startDateDayBreakCount = .50;
                        leaveClusterStart = {
                            q1: true,
                            q2: true,
                            q3: false,
                            q4: false,
                        };
                    }
                    //Second Half
                    if (startDaybreakType == '2') {
                        startDateDayBreakCount = .50;
                        leaveClusterStart = {
                            q1: false,
                            q2: false,
                            q3: true,
                            q4: true,
                        };
                    }
                }
                //Quarter Day
                if (startLeaveType == '1') {
                    //First Quarter 
                    if (startDaybreakType == '1') {
                        startDateDayBreakCount = .25;
                        leaveClusterStart = {
                            q1: true,
                            q2: false,
                            q3: false,
                            q4: false,
                        };
                    }
                    //Second Quarter 
                    if (startDaybreakType == '2') {
                        startDateDayBreakCount = .25;
                        leaveClusterStart = {
                            q1: false,
                            q2: true,
                            q3: false,
                            q4: false,
                        };
                    }
                    //Third Quarter 
                    if (startDaybreakType == '3') {
                        startDateDayBreakCount = .25;
                        leaveClusterStart = {
                            q1: false,
                            q2: false,
                            q3: true,
                            q4: false,
                        };
                    }
                    //Forth Quarter 
                    if (startDaybreakType == '4') {
                        startDateDayBreakCount = .25;
                        leaveClusterStart = {
                            q1: false,
                            q2: false,
                            q3: false,
                            q4: true,
                        };
                    }
                }
            }
            // For Multiple dates
            if (dayBreakArr.length > 1) {

                let startDateArr = dayBreakArr[0].split('_');
                let endDateArr = dayBreakArr[dayBreakArr.length - 1].split('_');
                startDate = startDateArr[0];
                startLeaveType = startDateArr[1];
                startDaybreakType = startDateArr[2];
                endDate = endDateArr[0];
                endLeaveType = endDateArr[1];
                endDaybreakType = endDateArr[2];
                /**
                 * First date
                 */

                //Full Day
                if (startLeaveType == '4') {
                    startDateDayBreakCount = 1;
                    leaveClusterStart = {
                        q1: true,
                        q2: true,
                        q3: true,
                        q4: true,
                    };
                }
                //Half Day
                if (startLeaveType == '2') {
                    //First Half
                    if (startDaybreakType == '1') {
                        startDateDayBreakCount = .50;
                        leaveClusterStart = {
                            q1: true,
                            q2: true,
                            q3: false,
                            q4: false,
                        };
                    }
                    //Second Half
                    if (startDaybreakType == '2') {
                        startDateDayBreakCount = .50;
                        leaveClusterStart = {
                            q1: false,
                            q2: false,
                            q3: true,
                            q4: true,
                        };
                    }
                }
                //Quarter Day
                if (startLeaveType == '1') {
                    //First Quarter 
                    if (startDaybreakType == '1') {
                        startDateDayBreakCount = .25;
                        leaveClusterStart = {
                            q1: true,
                            q2: false,
                            q3: false,
                            q4: false,
                        };
                    }
                    //Second Quarter 
                    if (startDaybreakType == '2') {
                        startDateDayBreakCount = .25;
                        leaveClusterStart = {
                            q1: false,
                            q2: true,
                            q3: false,
                            q4: false,
                        };
                    }
                    //Third Quarter 
                    if (startDaybreakType == '3') {
                        startDateDayBreakCount = .25;
                        leaveClusterStart = {
                            q1: false,
                            q2: false,
                            q3: true,
                            q4: false,
                        };
                    }
                    //Forth Quarter 
                    if (startDaybreakType == '4') {
                        startDateDayBreakCount = .25;
                        leaveClusterStart = {
                            q1: false,
                            q2: false,
                            q3: false,
                            q4: true,
                        };
                    }
                }

                /**
                 * Last Date
                 */
                //Full Day
                if (endLeaveType == '4') {
                    endDateDayBreakCount = 1;
                    leaveClusterEnd = {
                        q1: true,
                        q2: true,
                        q3: true,
                        q4: true,
                    };
                }
                //Half Day
                if (endLeaveType == '2') {
                    //First Half
                    if (endDaybreakType == '1') {
                        endDateDayBreakCount = .50;
                        leaveClusterEnd = {
                            q1: true,
                            q2: true,
                            q3: false,
                            q4: false,
                        };
                    }
                    //Second Half
                    if (endDaybreakType == '2') {
                        endDateDayBreakCount = .50;
                        leaveClusterEnd = {
                            q1: false,
                            q2: false,
                            q3: true,
                            q4: true,
                        };
                    }
                }
                //Quarter Day
                if (endLeaveType == '1') {
                    //First Quarter 
                    if (endDaybreakType == '1') {
                        endDateDayBreakCount = .25;
                        leaveClusterEnd = {
                            q1: true,
                            q2: false,
                            q3: false,
                            q4: false,
                        };
                    }
                    //Second Quarter 
                    if (endDaybreakType == '2') {
                        endDateDayBreakCount = .25;
                        leaveClusterEnd = {
                            q1: false,
                            q2: true,
                            q3: false,
                            q4: false,
                        };
                    }
                    //Third Quarter 
                    if (endDaybreakType == '3') {
                        endDateDayBreakCount = .25;
                        leaveClusterEnd = {
                            q1: false,
                            q2: false,
                            q3: true,
                            q4: false,
                        };
                    }
                    //Forth Quarter 
                    if (endDaybreakType == '4') {
                        endDateDayBreakCount = .25;
                        leaveClusterEnd = {
                            q1: false,
                            q2: false,
                            q3: false,
                            q4: true,
                        };
                    }
                }
            }
            return {
                startLeaveType: startLeaveType,
                endLeaveType: endLeaveType,
                startDaybreakType: startDaybreakType,
                endDaybreakType: endDaybreakType,
                startDateDayBreakCount: startDateDayBreakCount,
                endDateDayBreakCount: endDateDayBreakCount,
                startDate: startDate,
                endDate: endDate,
                leaveClusterStart: leaveClusterStart,
                leaveClusterEnd: leaveClusterEnd
            }
        }
    }
    const getMasterData = async () => {
        try {
            if (compOffData.length === 0) {
                const leaveTypeMater = await LnAService.getLeaveApplyMasterData("All", authUser, 23);
                const compOffDatas = leaveTypeMater.Result?.filter((t) => { return t.type === "CompOff" });
                props.updateLnAData({ compOffData: compOffDatas })
            }
        } catch (error) {
            console.log("🚀 ~ file: leaveapply.jsx ~ line 113 ~ getMasterData ~ error", error)
        }
    }

    const onInput = (e) => {
        try {
            if (textareaObj !== null && typeof textareaObj !== 'undefined') {
                textareaObj.current.respectiveElement.style.height = "auto";
                textareaObj.current.respectiveElement.style.minHeight = "100px";
                textareaObj.current.respectiveElement.style.height = `${textareaObj.current.respectiveElement.scrollHeight}px`;
                textareaObj.current.respectiveElement.style.maxHeight = "100px";
                cracterLimit(e, 'leaveAppReasonCount', '1000')
            }
        } catch (error) {
        }
    };
    const getBackgroundBreakCss = (type) => {
        switch (type) {
            case true:
                return dayBreakBackgroundColor;
            case false:
                return '#fff';
            case null:
                return '#B7B7BB';
            default:
                return '#B7B7BB';
        }
    }
    const onCancel = () => {
        props.updateLnAData({
            leaveType: '',
            leaveStartDate: null,
            leaveEndDate: null,
            leaveReason: '',
            leaveApplicationId: 0
        })
        let data = {
            appliedDays: 0,
            leaveClusterStart: {
                q1: null,
                q2: null,
                q3: null,
                q4: null,
            },
            leaveClusterEnd: {
                q1: null,
                q2: null,
                q3: null,
                q4: null,
            },
            applyedLeaveDates: [],
            dayBreakupDates: [],
            startDate: null,
            endDate: null
        };
        setState(preState => ({ ...preState, ...data }));
        cracterLimit({ value: "" }, 'leaveAppReasonCount', '1000')
        clearErrorMessages()
    }
    const handleClose = () => {
        setShowConform(false)
    }
    const handleYes = () => {
        props.saveCompOffData(dataToSave);
        setShowConform(false)
        cracterLimit({ value: "" }, 'leaveAppReasonCount', '1000')
        // onCancel()
    }
    const onSubmitApplyLeave = async () => {
        const { leaveClusterStart: lcs, applyedLeaveDates, appliedDays } = state;
        document.getElementById('leaveStartDateErr').innerHTML = "";
        document.getElementById('leaveToDateErr').innerHTML = "";
        document.getElementById('leaveClusterDayBreakupErr').innerHTML = "";

        const addFormObject = new FormValidator("#applyLeave", option1);
        try {
            addFormObject.validate();
        } catch (errr) {
            console.log("🚀 ~ file: leaveapply.jsx:650 ~ onSubmitApplyLeave ~ errr", errr)
        }
        if (leaveStartDate === null) {
            document.getElementById('leaveStartDateErr').innerHTML = "Please select the from date.";

        }
        if (leaveEndDate === null) {
            document.getElementById('leaveToDateErr').innerHTML = "Please select the to date.";

        }
        let count;
        let finalApplyedLeaves = [];
        /**********  Start From   */
        if (leaveStartDate === leaveEndDate) {
            /**
             * Full Day
             */
            if (lcs.q1 && lcs.q2 && lcs.q3 && lcs.q4) {
                count = 0;
                applyedLeaveDates.forEach((dates) => {
                    finalApplyedLeaves.push(`${dates}_4_1`)
                })
            }
            /**
             * First Half 
             */
            else if (lcs.q1 && lcs.q2 && !lcs.q3 && !lcs.q4) {
                count = 0;
                applyedLeaveDates.forEach((dates) => {
                    finalApplyedLeaves.push(`${dates}_2_1`)
                })
            }
            /**
             * Second Half
             */
            else if (lcs.q3 && lcs.q4 && !lcs.q1 && !lcs.q2) {
                count = 0;
                applyedLeaveDates.forEach((dates) => {
                    finalApplyedLeaves.push(`${dates}_2_2`)
                })
            }
            /**
             * First Quarter
             */
            else if (lcs.q1 && !lcs.q2 && !lcs.q3 && !lcs.q4) {
                count = 1;
                applyedLeaveDates.forEach((dates) => {
                    finalApplyedLeaves.push(`${dates}_1_1`)
                })
            }
            /**
             * Second Quarter
             */
            else if (!lcs.q1 && lcs.q2 && !lcs.q3 && !lcs.q4) {
                count = 1;
                applyedLeaveDates.forEach((dates) => {
                    finalApplyedLeaves.push(`${dates}_1_2`)
                })
            }
            /**
             * Third Quarter
             */
            else if (!lcs.q1 && !lcs.q2 && lcs.q3 && !lcs.q4) {
                count = 1;
                applyedLeaveDates.forEach((dates) => {
                    finalApplyedLeaves.push(`${dates}_1_3`)
                })
            }
            /**
             * Forth Quarter
             */
            else if (!lcs.q1 && !lcs.q2 && !lcs.q3 && lcs.q4) {
                count = 1;
                applyedLeaveDates.forEach((dates) => {
                    finalApplyedLeaves.push(`${dates}_1_4`)
                })
            } else {
                count = 1;
            }
        } else {

            if (leaveEndDate !== leaveStartDate) {
                applyedLeaveDates.forEach((dates, inx) => {
                    if (inx === 0) {
                        /**
                        * Full Day
                        */
                        if (lcs.q1 && lcs.q2 && lcs.q3 && lcs.q4) {
                            count = 0;
                            finalApplyedLeaves.push(`${dates}_4_1`)
                        }
                        /**
                         * First Half 
                         */
                        else if (lcs.q1 && lcs.q2 && !lcs.q3 && !lcs.q4) {
                            count = 0;
                            finalApplyedLeaves.push(`${dates}_2_1`)
                        }
                        /**
                         * Second Half
                         */
                        else if (lcs.q3 && lcs.q4 && !lcs.q1 && !lcs.q2) {
                            count = 0;
                            finalApplyedLeaves.push(`${dates}_2_2`)
                        }
                        /**
                         * First Quarter
                         */
                        else if (lcs.q1 && !lcs.q2 && !lcs.q3 && !lcs.q4) {
                            count = 1;
                            finalApplyedLeaves.push(`${dates}_1_1`)
                        }
                        /**
                         * Second Quarter
                         */
                        else if (!lcs.q1 && lcs.q2 && !lcs.q3 && !lcs.q4) {
                            count = 1;
                            finalApplyedLeaves.push(`${dates}_1_2`)
                        }
                        /**
                        * Third Quarter
                        */
                        else if (!lcs.q1 && !lcs.q2 && lcs.q3 && !lcs.q4) {
                            count = 1;
                            finalApplyedLeaves.push(`${dates}_1_3`)
                        }
                        /**
                        * Forth Quarter
                        */
                        else if (!lcs.q1 && !lcs.q2 && !lcs.q3 && lcs.q4) {
                            count = 1;
                            finalApplyedLeaves.push(`${dates}_1_4`)
                        } else {
                            count = 1;
                        }
                    } else if (inx === applyedLeaveDates.length - 1) {
                        /**
                         * Leave Cluster End
                         */
                        /**
                         * Full Day
                         */
                        if (leaveClusterEnd.q1 && leaveClusterEnd.q2 && leaveClusterEnd.q3 && leaveClusterEnd.q4) {
                            count = 0;
                            finalApplyedLeaves.push(`${dates}_4_1`)
                        }
                        /**
                         * First Half 
                         */
                        else if (leaveClusterEnd.q1 && leaveClusterEnd.q2 && !leaveClusterEnd.q3 && !leaveClusterEnd.q4) {
                            count = 0;
                            finalApplyedLeaves.push(`${dates}_2_1`)
                        }
                        /**
                         * Second Half
                         */
                        else if (leaveClusterEnd.q3 && leaveClusterEnd.q4 && !leaveClusterEnd.q1 && !leaveClusterEnd.q2) {
                            count = 0;
                            finalApplyedLeaves.push(`${dates}_2_2`)
                        }
                        /**
                         * First Quarter
                         */
                        else if (leaveClusterEnd.q1 && !leaveClusterEnd.q2 && !leaveClusterEnd.q3 && !leaveClusterEnd.q4) {
                            count = 1;
                            finalApplyedLeaves.push(`${dates}_1_1`)
                        }
                        /**
                         * Second Quarter
                         */
                        else if (!leaveClusterEnd.q1 && leaveClusterEnd.q2 && !leaveClusterEnd.q3 && !leaveClusterEnd.q4) {
                            count = 1;
                            finalApplyedLeaves.push(`${dates}_1_2`)
                        }
                        /**
                         * Third Quarter
                         */
                        else if (!leaveClusterEnd.q1 && !leaveClusterEnd.q2 && leaveClusterEnd.q3 && !leaveClusterEnd.q4) {
                            count = 1;
                            finalApplyedLeaves.push(`${dates}_1_3`)
                        }
                        /**
                         * Forth Quarter
                         */
                        else if (!leaveClusterEnd.q1 && !leaveClusterEnd.q2 && !leaveClusterEnd.q3 && leaveClusterEnd.q4) {
                            count = 1;
                            finalApplyedLeaves.push(`${dates}_1_4`)
                        } else {
                            count = 1;
                        }
                    } else {
                        finalApplyedLeaves.push(`${dates}_4_1`);
                    }
                })
            }
        }
        /***********End To  */
        if (count === 1) {
            document.getElementById('leaveClusterDayBreakupErr').innerHTML = "Please set appropriate day break up.";
            return;
        }

        if (addFormObject.validate() && count === 0) {
            const getBREData = await getBREDataForLeave(leaveType)
            let payload1 = {
                userId: authUser,
                leaveClusterId: leaveType,
                leaveStartDate: leaveStartDate,
                leaveEndDate: leaveEndDate,
                appliedDays: appliedDays,
                reason: leaveReason,
                dateDayBreak: finalApplyedLeaves.toString(),
                leaveApplicationId: props.leaveApplicationId,
                isActive: '1',
                type: leaveApplicationId === 0 ? 'ADD' : 'EDIT',
                status: '1',
                leaveCancelReason: '',
            }
            payload1 = {
                ...payload1,
                supervisorApprovalRequired: getBREData.enum_supervisor_approval,
                hrApprovalRequired: getBREData.enum_HR_approval,
                autoApprovalRequired: getBREData.enum_auto_approval,
                noOfHrsToAutoApprove: getBREData.int_no_hrs_to_approval
            }
            setDataToSave(payload1)
            setShowConform(true)
        }
    }


    const onChangeApplyLeave = async (e, type) => {
        let data = {};
        switch (type) {
            case 'leaveType':
                if (e.itemData !== null) {
                    props.updateLnAData({ leaveType: e.itemData.Id, dayBreakBackgroundColor: e.itemData.color })
                    const getBREData = await getBREDataForLeave(e.itemData.Id)
                    data = {
                        minStartDate: addDays(new Date(), getBREData.int_min_days),
                        maxEndDate: addDays(new Date(), getBREData.int_max_days),
                        leaveClusterStart: {
                            q1: true,
                            q2: true,
                            q3: true,
                            q4: true,
                        },
                        leaveClusterEnd: {
                            q1: true,
                            q2: true,
                            q3: true,
                            q4: true,
                        },
                    }

                    setState(preState => ({ ...preState, ...data }))
                } else {
                    props.updateLnAData({ leaveType: null, isWeekendCounted: "", isStreched: "" })
                }
                break;
            case 'leaveStartDate':
                let date1 = leaveDateFormat(e.value);
                if (e.value !== null) {
                    if ((leaveEndDate === null) || (new Date(date1) > new Date(leaveEndDate))) {
                        data = {
                            startDate: e.target.inputElement.value,
                            endDate: e.target.inputElement.value,
                            leaveClusterStart: { q1: true, q2: true, q3: true, q4: true, },
                            leaveClusterEnd: { q1: true, q2: true, q3: true, q4: true, },
                        }
                        await props.updateLnAData({ leaveStartDate: date1, leaveEndDate: date1, leaveStartDateEdit: data.startDate, leaveEndDateEdit: data.endDate, })
                    } else {

                        data = {
                            startDate: e.target.inputElement.value,
                            leaveClusterStart: { q1: true, q2: true, q3: true, q4: true, }
                        }
                        props.updateLnAData({ leaveStartDate: date1 })
                    }

                    setState(preState => ({ ...preState, ...data }))
                    setTimeout(() => {
                        let validateData = validateDayBreakUp();

                        if (props.leaveEndDate === null || (new Date(date1) > new Date(leaveEndDate))) {
                            getCompOffDates(date1, date1, validateData.leaveStartDayCount, validateData.leaveEndDayCount, leaveApplicationId);
                        } else {

                            getCompOffDates(date1, props.leaveEndDate, validateData.leaveStartDayCount, validateData.leaveEndDayCount, leaveApplicationId);
                        }

                    }, 1000);
                }
                break;
            case 'leaveEndDate':
                let date2 = leaveDateFormat(e.value);
                data = {
                    endDate: e.target.inputElement.value,
                    leaveClusterEnd: { q1: true, q2: true, q3: true, q4: true, }
                }
                props.updateLnAData({ leaveEndDate: date2, leaveEndDateEdit: data.endDate })
                setState(preState => ({ ...preState, ...data }))
                if (leaveStartDate !== null && e !== null) {
                    validateDayBreakUp()
                }



                break;

            case 'leaveReason':
                props.updateLnAData({ leaveReason: e.value })
                break;
        }
    }
    const selectDayLeaveStart = (qtype, qSelected) => {

        let data = {
            leaveClusterStart: state.leaveClusterStart
        }
        if (leaveType === 20) {
            if (qtype === 1) {
                data.leaveClusterStart.q1 = !qSelected;
                data.leaveClusterStart.q2 = false;
                data.leaveClusterStart.q3 = false;
                data.leaveClusterStart.q4 = false;
            }
            if (qtype === 2) {
                data.leaveClusterStart.q1 = false;
                data.leaveClusterStart.q2 = !qSelected;
                data.leaveClusterStart.q3 = false;
                data.leaveClusterStart.q3 = false;
            }
            if (qtype === 3) {
                data.leaveClusterStart.q1 = false;
                data.leaveClusterStart.q2 = false;
                data.leaveClusterStart.q3 = !qSelected;
                data.leaveClusterStart.q4 = false;
            }
            if (qtype === 4) {
                data.leaveClusterStart.q1 = false;
                data.leaveClusterStart.q2 = false;
                data.leaveClusterStart.q3 = false;
                data.leaveClusterStart.q4 = !qSelected;
            }
        } else {
            if (qtype === 1) {
                data.leaveClusterStart.q1 = !qSelected;
            }
            if (qtype === 2) {
                data.leaveClusterStart.q2 = !qSelected;
            }
            if (qtype === 3) {
                data.leaveClusterStart.q3 = !qSelected;
            }
            if (qtype === 4) {
                data.leaveClusterStart.q4 = !qSelected;
            }
        }

        let validateData = validateDayBreakUp()
        if (!validateData.startDayBreakCount) {
            getCompOffDates(leaveStartDate, leaveEndDate, validateData.leaveStartDayCount, validateData.leaveEndDayCount, leaveApplicationId);
            document.getElementById('leaveClusterDayBreakupErr').innerHTML = "";
        } else {
            document.getElementById('leaveClusterDayBreakupErr').innerHTML = "Please set appropriate day break up.";
        }
        setState(preState => ({ ...preState, ...data }));
    }
    const selectDayLeaveEnd = (qtype, qSelected) => {
        let data = {
            leaveClusterEnd: state.leaveClusterEnd
        }
        if (qtype === 1) {
            data.leaveClusterEnd.q1 = !qSelected;
        }
        if (qtype === 2) {
            data.leaveClusterEnd.q2 = !qSelected;
        }
        if (qtype === 3) {
            data.leaveClusterEnd.q3 = !qSelected;
        }
        if (qtype === 4) {
            data.leaveClusterEnd.q4 = !qSelected;
        }

        const validateData = validateDayBreakUp();

        if (!validateData.endDayBreakCount && !validateData.startDayBreakCount && (leaveEndDate !== leaveStartDate)) {
            getCompOffDates(leaveStartDate, leaveEndDate, validateData.leaveStartDayCount, validateData.leaveEndDayCount, leaveApplicationId);
            document.getElementById('leaveClusterDayBreakupErr').innerHTML = "";
        } else {
            document.getElementById('leaveClusterDayBreakupErr').innerHTML = "Please set appropriate day break up.";
        }
        setState(preState => ({ ...preState, ...data }));
    }
    const validateDayBreakUp = () => {
        let startDayBreakCount = 0;
        let endDayBreakCount = 0;
        let leaveStartDayCount = 0;
        let leaveEndDayCount = 0;

        /**
        * If leave start date and end date is not equal the execute this block.
        */

        /**
         * Leave Cluster Start
         */
        /**
         * Full Day
         */
        if (leaveClusterStart.q1 && leaveClusterStart.q2 && leaveClusterStart.q3 && leaveClusterStart.q4) {
            leaveStartDayCount = 1;
        }
        /**
         * First Half 
         */
        else if (leaveClusterStart.q1 && leaveClusterStart.q2 && !leaveClusterStart.q3 && !leaveClusterStart.q4) {
            leaveStartDayCount = .50;
        }
        /**
         * Second Half
         */
        else if (leaveClusterStart.q3 && leaveClusterStart.q4 && !leaveClusterStart.q1 && !leaveClusterStart.q2) {
            leaveStartDayCount = .50;
        }
        /**
         * First Quarter
         */
        else if (leaveClusterStart.q1 && !leaveClusterStart.q2 && !leaveClusterStart.q3 && !leaveClusterStart.q4) {
            startDayBreakCount = 1;
        }
        /**
         * Second Quarter
         */
        else if (!leaveClusterStart.q1 && leaveClusterStart.q2 && !leaveClusterStart.q3 && !leaveClusterStart.q4) {
            startDayBreakCount = 1;
        }
        /**
         * Third Quarter
         */
        else if (!leaveClusterStart.q1 && !leaveClusterStart.q2 && leaveClusterStart.q3 && !leaveClusterStart.q4) {
            startDayBreakCount = 1;
        }
        /**
         * Forth Quarter
         */
        else {
            startDayBreakCount = 1;
        }
        if (leaveEndDate !== leaveStartDate) {
            /**
             * Leave Cluster End
             */
            /**
             * Full Day
             */
            if (leaveClusterEnd.q1 && leaveClusterEnd.q2 && leaveClusterEnd.q3 && leaveClusterEnd.q4) {
                leaveEndDayCount = 1;
            }
            /**
             * First Half 
             */
            else if (leaveClusterEnd.q1 && leaveClusterEnd.q2 && !leaveClusterEnd.q3 && !leaveClusterEnd.q4) {
                leaveEndDayCount = .50;
            }
            /**
             * Second Half
             */
            else if (leaveClusterEnd.q3 && leaveClusterEnd.q4 && !leaveClusterEnd.q1 && !leaveClusterEnd.q2) {
                leaveEndDayCount = .50;
            }
            /**
             * First Quarter
             */
            else if (leaveClusterEnd.q1 && !leaveClusterEnd.q2 && !leaveClusterEnd.q3 && !leaveClusterEnd.q4) {
                endDayBreakCount = 1;
            }
            /**
             * Second Quarter
             */
            else if (!leaveClusterEnd.q1 && leaveClusterEnd.q2 && !leaveClusterEnd.q3 && !leaveClusterEnd.q4) {
                endDayBreakCount = 1;
            }
            /**
             * Third Quarter
             */
            else if (!leaveClusterEnd.q1 && !leaveClusterEnd.q2 && leaveClusterEnd.q3 && !leaveClusterEnd.q4) {
                endDayBreakCount = 1;
            }
            /**
             * Forth Quarter
             */
            else if (!leaveClusterEnd.q1 && !leaveClusterEnd.q2 && !leaveClusterEnd.q3 && leaveClusterEnd.q4) {
                endDayBreakCount = 1;
            } else {
                endDayBreakCount = 1;
            }
        }
        return {
            startDayBreakCount: startDayBreakCount,
            endDayBreakCount: endDayBreakCount,
            leaveStartDayCount: leaveStartDayCount,
            leaveEndDayCount: leaveEndDayCount
        }
    }

    const openDayBreakupPopup = () => {
        let data = {
            showDayBreakup: true
        }
        setState(preState => ({ ...preState, ...data }));
    }
    const closeDayBreakupPopup = () => {
        let data = {
            showDayBreakup: false
        }
        setState(preState => ({ ...preState, ...data }));
    }
    const getCompOffDates = async (leaveStartDate1, leaveEndDate1, leaveStartDayCount, leaveEndDayCount, leaveAppId) => {
        try {
            if (leaveStartDate1 !== null && leaveEndDate1 !== null) {
                const validatedCompOff = await LnAService.getCompOffLeavesDates(authUser, 23, leaveStartDate1, leaveEndDate1, leaveStartDayCount, leaveEndDayCount, leaveAppId)

                let applyedLeaveDates = [];
                let totalLeavesInDays = 0;
                // eslint-disable-next-line no-unused-expressions
                validatedCompOff.Result?.map((item) => {
                    if (item.isSelectionEligible === '1') {
                        applyedLeaveDates.push(item.date)
                        totalLeavesInDays = item.appliedDays
                    }
                })
                let data = {
                    appliedDays: totalLeavesInDays,
                    applyedLeaveDates: applyedLeaveDates,
                    dayBreakupDates: validatedCompOff.Result
                }
                setState(preState => ({ ...preState, ...data }));
            }

        } catch (error) {
            console.log("🚀 ~ file: leaveapply.jsx ~ line 484 ~ getCompOffDates ~ error", error)
        }

    }

    const getDayBreakCss = (type) => {
        switch (type) {
            case true:
                return 'ml-sm cluster-select-leave-applocation-block-select';
            case false:
                return 'ml-sm cluster-select-leave-applocation-block-unselect';
            case null:
                return 'ml-sm cluster-select-leave-applocation-block-default';
            default:
                return 'ml-sm cluster-select-leave-applocation-block-default';
        }
    }
    const getBREDataForLeave = async (pSouceLeaveId) => {
        const objData = { "int_leave_id": pSouceLeaveId }
        return await BREServices.getBREData(authUser,//nosonar
            BREModuleActions.compOffApplication, objData);
    }



    const isFourteenHundred = window.innerHeight < 782 && window.innerWidth < 1441;
    const isOneTwentyFiveScale = window.innerHeight < 747;
    const isThirteenHundred = window.innerHeight < 658;

    const { leaveClusterStart, leaveClusterEnd, height, showDayBreakup, dayBreakupDates, minStartDate, maxEndDate, } = state;
    return (
        <>
            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 leave-application-body">
                <div className='col-sm-12 col-md-12 col-lg-12'>
                    <div style={{
                        backgroundColor: '#343A40',
                        borderStartEndRadius: "0.4em",
                        borderStartStartRadius: "0.4em",
                        height: "2.5rem",
                        display: "flex",
                        alignItems: "center",
                        paddingLeft: "1rem",
                        fontWeight: 600,
                    }}
                    >
                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 leave-application-header">
                            <div>
                                Comp. Off Application
                            </div>
                            <div className="toottiptableleaveclass mr-sm" id="toottiptable">
                                <button
                                    className="leave-rules-button"
                                    style={{ backgroundColor: "#343a40", fontFamily:'Circular TT Book' }}
                                    value="Click"
                                >
                                    Leave Rules
                                </button>
                                <span className="tooltiptext"><LeaveRules /></span>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="container pl-sm pr-sm pt-sm" style={{ display: 'flex', width: '100%', height: height, overflow: 'hidden' }}>
                    <div style={{ border: '1px solid #ccc', borderRadius: "5px", boxShadow: "0.5px 0.5px #ccc", marginLeft: '0rem' }}
                        className='col-lg-7 col-md-7 col-sm-7 col-xs-7 leave-application-body'>
                        <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 ' style={{ bottom: '55px' }}>
                            <form id='applyLeave'>
                                <div className='row1'>
                                    <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12' >
                                        <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6" style={{ paddingLeft: 0, paddingRight: 32 }}>
                                            <ComboBoxComponent
                                                id="applyFor"
                                                fields={{ text: "name", value: "Id" }}
                                                cssClass="e-outline numberInputFont"
                                                data-msg-containerid="typeOfLeaveErr"
                                                name="typeOfLeaveErr"
                                                floatLabelType="Auto"
                                                placeholder="Attendence Type *"
                                                htmlAttributes={{ maxlength: 100 }}
                                                select={(e) => onChangeApplyLeave(e, 'leaveType')}
                                                value={leaveType}
                                                dataSource={compOffData}
                                                allowCustom={false}
                                            />
                                            <div
                                                id="typeOfLeaveErr"
                                                className="e-input-group e-float-icon-left error-div"
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className='row1'>
                                    <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                                        <div className='col-xs-6 col-sm-6 col-lg-6 col-md-6 pt-sm' style={{ marginLeft: '-15px' }}>
                                            {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <KeyboardDatePicker
                                                        style={{ width: "100%" }}
                                                        inputVariant="outlined"
                                                        margin="normal"
                                                        id="meeting-date"
                                                        name="meeting-date"
                                                        variant="inline"
                                                        format="dd-MMM-yyyy"
                                                        // value={new Date()}
                                                        placeholder='From Date *'
                                                        KeyboardButtonProps={{
                                                            "aria-label": "change date",
                                                        }}
                                                        data-msg-containerid="meetingdateError"
                                                        ToolbarComponent={CalenderToolbar}
                                                        onChange={(e) => onChangeApplyLeave(e, 'leaveStartDate')}
                                                        value={leaveStartDate}
                                                        minDate={minStartDate}
                                                        maxDate={categoryName.toString() === APPLYCOMPOFF ? new Date() : maxEndDate}
                                                    />
                                                </MuiPickersUtilsProvider> */}
                                            <DatePickerComponent
                                                // id="datePickerRange"
                                                cssClass="e-outline"
                                                allowFiltering={false}
                                                allowCustom={true}
                                                floatLabelType="Auto"
                                                placeholder="From Date *"
                                                showClearButton={true}
                                                format='dd-MMM-yyyy'
                                                type="date"
                                                popupWidth={296}
                                                min={minStartDate}
                                                max={maxEndDate}
                                                onChange={(e) => onChangeApplyLeave(e, 'leaveStartDate')}
                                                value={state.startDate}
                                            // skeleton="medium"
                                            ></DatePickerComponent>
                                            <div
                                                id="leaveStartDateErr"
                                                className="e-input-group e-float-icon-left error-div"
                                            />
                                        </div>

                                        <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6 ml-md pt-sm">
                                            <div className={isThirteenHundred || isFourteenHundred ? "col-xs-6 col-sm-6 col-lg-6 col-md-6" : ""} style={{ paddingLeft: 0, width: isThirteenHundred || isFourteenHundred ? null : "10.5rem" }}>
                                                <fieldset
                                                    id="fieldsetNotification"
                                                    style={{ height: isThirteenHundred || isFourteenHundred ? "2.6rem" : isOneTwentyFiveScale ? "3rem" : "3.05rem" }}
                                                >
                                                    <legend id="legendNotification" style={{ marginLeft: ".5rem" }}>Working Daybreak</legend>
                                                    <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", marginTop: "-.3rem" }}>
                                                        <div
                                                            className={`${getDayBreakCss(leaveClusterStart.q1)} ml-sm`}
                                                            style={{
                                                                backgroundColor: getBackgroundBreakCss(leaveClusterStart.q1,),
                                                                height: isThirteenHundred || isFourteenHundred ? "1.4rem" : "1.8rem",
                                                                width: isThirteenHundred || isFourteenHundred ? "1.4rem" : "1.8rem"
                                                            }}
                                                            onClick={() => selectDayLeaveStart(1, leaveClusterStart.q1)} />
                                                        <div
                                                            className={getDayBreakCss(leaveClusterStart.q2)}
                                                            style={{
                                                                backgroundColor: getBackgroundBreakCss(leaveClusterStart.q2,),
                                                                height: isThirteenHundred || isFourteenHundred ? "1.4rem" : "1.8rem",
                                                                width: isThirteenHundred || isFourteenHundred ? "1.4rem" : "1.8rem"
                                                            }}
                                                            onClick={() => selectDayLeaveStart(2, leaveClusterStart.q2)} />
                                                        <div
                                                            className={getDayBreakCss(leaveClusterStart.q3)}
                                                            style={{
                                                                backgroundColor: getBackgroundBreakCss(leaveClusterStart.q3,),
                                                                height: isThirteenHundred || isFourteenHundred ? "1.4rem" : "1.8rem",
                                                                width: isThirteenHundred || isFourteenHundred ? "1.4rem" : "1.8rem"
                                                            }}
                                                            onClick={() => selectDayLeaveStart(3, leaveClusterStart.q3)} />
                                                        <div
                                                            className={getDayBreakCss(leaveClusterStart.q4)}
                                                            style={{
                                                                backgroundColor: getBackgroundBreakCss(leaveClusterStart.q4,),
                                                                height: isThirteenHundred || isFourteenHundred ? "1.4rem" : "1.8rem",
                                                                width: isThirteenHundred || isFourteenHundred ? "1.4rem" : "1.8rem"
                                                            }}
                                                            onClick={() => selectDayLeaveStart(4, leaveClusterStart.q4)} />
                                                    </div>
                                                </fieldset>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='row1'>
                                    <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                                        <div className='col-xs-6 col-sm-6 col-lg-6 col-md-6 pt-sm' style={{ marginLeft: '-15px' }}>
                                            {/* <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <KeyboardDatePicker
                                                        style={{ width: "100%" }}
                                                        inputVariant="outlined"
                                                        margin="normal"
                                                        id="meeting-date"
                                                        name="meeting-date"
                                                        format="dd-MMM-yyyy"
                                                        variant="inline"
                                                        placeholder='To Date *'
                                                        KeyboardButtonProps={{
                                                            "aria-label": "change date",
                                                        }}
                                                        data-msg-containerid="meetingdateError"
                                                        ToolbarComponent={CalenderToolbar}
                                                        onChange={(e) => onChangeApplyLeave(e, 'leaveEndDate')}
                                                        value={leaveEndDate}
                                                        disabled={leaveType === 20 ? true : false}
                                                        minDate={minStartDate}
                                                        maxDate={categoryName.toString() === APPLYCOMPOFF ? new Date() : maxEndDate}
                                                    />
                                                </MuiPickersUtilsProvider> */}
                                            <DatePickerComponent
                                                // id="datePickerRange"
                                                cssClass="e-outline"
                                                allowFiltering={false}
                                                allowCustom={true}
                                                floatLabelType="Auto"
                                                placeholder="To Date *"
                                                showClearButton={true}
                                                format='dd-MMM-yyyy'
                                                type="date"
                                                popupWidth={296}
                                                min={minStartDate}
                                                max={maxEndDate}
                                                onChange={(e) => onChangeApplyLeave(e, 'leaveEndDate')}
                                                value={state.endDate}
                                                disabled={leaveType === 20 ? true : false}
                                            // skeleton="medium"
                                            ></DatePickerComponent>
                                            <div
                                                id="leaveToDateErr"
                                                className="e-input-group e-float-icon-left error-div"
                                            />
                                        </div>

                                        <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6 ml-md pt-sm">
                                            {
                                                (leaveStartDate !== leaveEndDate && leaveEndDate !== null) &&
                                                <div className={isThirteenHundred || isFourteenHundred ? "col-xs-6 col-sm-6 col-lg-6 col-md-6" : ""} style={{ paddingLeft: 0, width: isThirteenHundred || isFourteenHundred ? null : "10.5rem" }}>
                                                    <fieldset
                                                        id="fieldsetNotification"
                                                        style={{ height: isThirteenHundred || isFourteenHundred ? "2.6rem" : isOneTwentyFiveScale ? "3rem" : "3.05rem" }}
                                                    >
                                                        <legend id="legendNotification" style={{ marginLeft: ".5rem" }}>Working Daybreak</legend>
                                                        <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", marginTop: "-.3rem" }}>
                                                            <div
                                                                className={`${getDayBreakCss(leaveClusterEnd.q1)} ml-sm`}
                                                                style={{
                                                                    backgroundColor: getBackgroundBreakCss(leaveClusterEnd.q1),
                                                                    height: isThirteenHundred || isFourteenHundred ? "1.4rem" : "1.8rem",
                                                                    width: isThirteenHundred || isFourteenHundred ? "1.4rem" : "1.8rem"
                                                                }}
                                                                onClick={() => selectDayLeaveEnd(1, leaveClusterEnd.q1)}
                                                            />
                                                            <div
                                                                className={getDayBreakCss(leaveClusterEnd.q2)}
                                                                style={{
                                                                    backgroundColor: getBackgroundBreakCss(leaveClusterEnd.q2),
                                                                    height: isThirteenHundred || isFourteenHundred ? "1.4rem" : "1.8rem",
                                                                    width: isThirteenHundred || isFourteenHundred ? "1.4rem" : "1.8rem"
                                                                }}
                                                                onClick={() => selectDayLeaveEnd(2, leaveClusterEnd.q2)}
                                                            />
                                                            <div
                                                                className={getDayBreakCss(leaveClusterEnd.q3)}
                                                                style={{
                                                                    backgroundColor: getBackgroundBreakCss(leaveClusterEnd.q3),
                                                                    height: isThirteenHundred || isFourteenHundred ? "1.4rem" : "1.8rem",
                                                                    width: isThirteenHundred || isFourteenHundred ? "1.4rem" : "1.8rem"
                                                                }}
                                                                onClick={() => selectDayLeaveEnd(3, leaveClusterEnd.q3)}
                                                            />
                                                            <div
                                                                className={getDayBreakCss(leaveClusterEnd.q4)}
                                                                style={{
                                                                    backgroundColor: getBackgroundBreakCss(leaveClusterEnd.q4),
                                                                    height: isThirteenHundred || isFourteenHundred ? "1.4rem" : "1.8rem",
                                                                    width: isThirteenHundred || isFourteenHundred ? "1.4rem" : "1.8rem"
                                                                }}
                                                                onClick={() => selectDayLeaveEnd(4, leaveClusterEnd.q4)}
                                                            />
                                                        </div>
                                                    </fieldset>
                                                </div>
                                            }
                                        </div>
                                    </div>

                                    <div className='working-daybreak' style={{ height: 42, textAlign: 'center', cursor: 'pointer', opacity: '1', fontWeight: 500, marginTop: 70, marginLeft: 125, zIndex: 100 }}>
                                        <div style={{ cursor: 'pointer', color: 'transparent' }} onClick={() => openDayBreakupPopup()}>
                                            {/* View More */}.
                                        </div>
                                    </div>
                                </div>


                                <div className='row1'>
                                    <div
                                        className='col-lg-12 col-md-12 col-sm-12 col-xs-12'
                                        style={{
                                            paddingLeft: 0,
                                            // marginTop: (isThirteenHundred || isFourteenHundred) && !errorMessageMargin.toDateMsg ? "-.6rem" : null
                                        }}
                                    >
                                        <div
                                            className='col-lg-6 col-md-6 col-sm-6 col-xs-6'
                                            style={{
                                                display: "flex",
                                                justifyContent: "flex-start",
                                                alignItems: "center",
                                                fontSize: ".85rem",
                                                padding: 0
                                            }}
                                        >
                                            <div style={{ color: '#000', fontWeight: 500 }}>Applied</div>
                                            <div style={{ paddingLeft: "1rem" }}>
                                                <div style={{ border: '1px solid #F2F2F2', height: isThirteenHundred ? "1.8rem" : "2rem", width: isThirteenHundred ? "3rem" : "3.5rem", textAlign: 'center', borderRadius: "3px" }}>
                                                    <div style={{ fontWeight: 500, paddingTop: ".3rem" }}>
                                                        {state.appliedDays}
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{ fontWeight: 500, paddingLeft: "1rem" }} >
                                                days
                                            </div>
                                        </div>

                                        <div onClick={() => openDayBreakupPopup()}>
                                            <div className='col-lg-6 col-md-6 col-sm-6 col-xs-6' style={{ paddingLeft: 0 }}>
                                                <div className={isThirteenHundred || isFourteenHundred ? "col-xs-6 col-sm-6 col-lg-6 col-md-6 pr-xs" : ""} style={{ paddingLeft: 0, width: isThirteenHundred || isFourteenHundred ? null : "11.5rem" }}>
                                                    <div className="leave-apply-button-view-more" style={{ float: "right" }}>
                                                        View Day Break
                                                    </div>
                                                    <div
                                                        id="leaveClusterDayBreakupErr"
                                                        className="e-input-group e-float-icon-left error-div pl-md"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row1'>
                                    <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 pt-sm' style={{ paddingLeft: 0 }}>
                                        <div style={{ paddingLeft: 0, paddingRight: 0, }}>
                                            <style>{sampleCss}</style>
                                            <TextBoxComponent

                                                id={'leaveAppReason'}
                                                placeholder={"Reason *"}
                                                cssClass="e-outline textarea"
                                                floatLabelType="Auto"
                                                input={onInput}
                                                ref={textareaObj}
                                                name="leaveAppReasonErr"
                                                data-msg-containerid="leaveAppReasonErr"
                                                multiline
                                                inputProps={{
                                                    required: true,
                                                    minLength: 7,
                                                }}
                                                htmlAttributes={{ maxlength: 1000 }}
                                                onChange={(e) => onChangeApplyLeave(e, 'leaveReason')}
                                                value={leaveReason}
                                            />
                                        </div>
                                        <div id="leaveAppReasonErr" className="error-div" />
                                        <div id='leaveAppReasonCount' style={{ float: 'right', fontSize: 12, margin: '0px 15px 8px 0px', display: props.leaveReason && props.leaveReason.length > 0 ? "" : 'none' }} className="error-div" />
                                    </div>
                                </div>
                                <ConfirmationPopup
                                    showButton={{ type: "1" }}
                                    open={showConform}
                                    button1function={() => handleYes()}
                                    button2function={() => handleClose()}
                                    headerMessage='Are you sure, you  want to apply Comp.Off ?'
                                    button1Text={"Yes"}
                    button2Text={"No"}
                    handleConfirmClose={handleClose}
                                />  
                            </form>
                            {
                                showDayBreakup &&
                                <ViewMoreDayBreakup closeDayBreakupPopup={closeDayBreakupPopup} dayBreakupDates={dayBreakupDates} leaveClusterStart={leaveClusterStart} leaveClusterEnd={leaveClusterEnd} />
                            }
                        </div>
                    </div>
                    <div className='col-lg-5 col-md-5 col-sm-5 col-xs-5 ml-md tabcontent-leave' style={{ padding: 0 }}>
                        {
                            (leaveApplyLoadName === 'leave-tabs' || leaveApplyLoadName === 'leave-edit' || leaveApplyLoadName === 'leave-notify-list') &&
                            <Leavetabs type='COMPOFF' showViewMode={showViewMode} selectedUserId={authUser} />
                        }
                        {
                            (leaveApplyLoadName === 'leave-cancel' || leaveApplyLoadName === 'leave-view') &&
                            <>
                                <CompOffDetails showViewMode={showViewMode} dataSave={dataToSave} />
                            </>
                        }
                    </div>
                </div>
                <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 pb-sm pt-sm pl-md'>
                    {(leaveApplyLoadName === 'leave-tabs' || leaveApplyLoadName === 'leave-edit' || leaveApplyLoadName === 'leave-notify-list') &&
                        <div className="pr-sm" style={{ float: "right" }}>
                            <button
                                className="leave-cancel-apply-button"
                                onClick={() => {
                                    onCancel()
                                }}
                            >
                                Cancel
                            </button>
                            <button
                                className="remove-button-shadow-save"
                                onClick={() =>
                                    onSubmitApplyLeave()
                                }>
                                Apply
                            </button>
                            {/* <button onClick={onSubmitApplyLeave} className={`leave-approval-approval-button ${state.appliedDays === 0 ? "leave-approval-disable-button" : ""}`} disabled={state.appliedDays === 0}>Apply</button> */}
                        </div>}
                </div>
            </div >
            <div style={{ height: 300 }}><NotificationContainer /></div>
        </>
    )
}

const mapStateToProps = ({ meetingcategory, lnareducer, auth, meetingmaster }) => {
    const { authUser } = auth;
    const { categoryName } = meetingcategory;
    const { othersCheckAvailablity, currentFinancialYearId } = meetingmaster;
    const { leaveType, attendenceType, leaveStartDate, leaveEndDate, leaveReason, reliever, leaveTypeData, compOffData,
        relieverData, leaveApplyStatus, isStreched, isWeekendCounted, applyForData, applyFor, leaveApplyLoadName, appliedDays,
        dayBreakText, leaveApplicationId, attachmentName, viewMode, dayBreakBackgroundColor, leaveStartDateEdit, leaveEndDateEdit, compOffClusterFlag
    } = lnareducer;
    return {
        categoryName,
        leaveType, attendenceType, leaveStartDate, leaveEndDate, leaveReason, reliever, authUser, leaveTypeData, compOffData,
        othersCheckAvailablity, relieverData, leaveApplyStatus, isStreched, isWeekendCounted,
        applyForData, applyFor, currentFinancialYearId, leaveApplyLoadName, appliedDays, dayBreakText, leaveApplicationId, attachmentName, viewMode, dayBreakBackgroundColor,
        leaveStartDateEdit, leaveEndDateEdit, compOffClusterFlag
    }
}

export default connect(mapStateToProps, { updateLnAData, applyLeave, saveCompOffData })(CompOff)
