import axios from "axios";
import { urlConfig, appId } from "../config/config";

function errorHandler(reject, error) {
  // eslint-disable-next-line
  console.dir(error);
  reject("operation failed");
}

// Get Master Roles from Mongo DB
function getMasterRoles() {
  return new Promise(function onThen(resolve, reject) {// NOSONAR
    axios
      .get(`${urlConfig.carApiUrl}role/${appId}`, {
        headers: {
          'authorization': localStorage.getItem('appToken'),
          'Accept': 'application/json',
          "Content-Type": "application/json",
        },
      })
      .then(function (response) {
        if (response != null) {
          var result
          if (response.status === 200) {
            result = response.data;
          }
          else {
            console.log('Error while getting master roles')
            return null;
          }
          return resolve(result);
        }
      })
      // eslint-disable-next-line
      .catch(function (error) {
        errorHandler(reject, error);
        return false;
      });
  });
}
// Get Master Modules from Mongo DB
function getMasterModules() {
  return new Promise(function onThen(resolve, reject) {// NOSONAR
    axios
      .get(`${urlConfig.carApiUrl}module/${appId}`, {
        headers: {
          'authorization': localStorage.getItem('appToken'),
          'Accept': 'application/json',
          "Content-Type": "application/json",
        },
      })
      // eslint-disable-next-line
      .then(function (response) {
        if (response != null) {
          var result
          if (response.status === 200) {
            result = response.data;
          }
          else {
            console.log('Error while getting master modules')
            return null;
          }
          return resolve(result);
        }
      })
      // eslint-disable-next-line
      .catch(function (error) {
        errorHandler(reject, error);
        return false;
      });
  });
}

function getAdminOrgChartGridData(type, businessUnitId) {

  return new Promise(function onThen(resolve, reject) {

    axios
      .get(`${urlConfig.tmlcApiUrl}adminorgchartdetails/${type}${businessUnitId ? '/' + businessUnitId : ''}`, {
        headers: {
          authorization: localStorage.getItem("jwtToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      // eslint-disable-next-line
      .then((response) => {
        if (response != null) {
          return resolve(response.data);
        }
      })
      .catch((error) => {
        errorHandler(reject, error.response.data.message);
        return null;
      });

  });
}
// Author : AMit Kumar (To get admin user role data)
function getAdminUserRoleData() {

  return new Promise(function onThen(resolve, reject) {
    axios
      .get(`${urlConfig.tmlcApiUrl}adminuserroledetails`, {
        headers: {
          authorization: localStorage.getItem("jwtToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      // eslint-disable-next-line
      .then((response) => { // NOSONAR
        if (response != null) {
          return resolve(response.data);
        }
      })
      .catch((error) => {
        errorHandler(reject, error.response.data.message);
        return null;
      });
  });
}

function getAdminMasterData(type) {
  return new Promise(function onThen(resolve, reject) {
    axios
      .get(`${urlConfig.tmlcApiUrl}adminmasterdata/${type}`, {
        headers: {
          authorization: localStorage.getItem("jwtToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      // eslint-disable-next-line
      .then((response) => { // NOSONAR
        if (response != null) {
          return resolve(response.data);
        }
      })
      .catch((error) => {
        errorHandler(reject, error.response.data.message);
        return null;
      });
  });
}


// Author : Noel Fredrick (To get folder management data)
function getFolderManageData() {

  return new Promise(function onThen(resolve, reject) {
    axios
      .get(`${urlConfig.tmlcApiUrl}foldermanagement`, {
        headers: {
          authorization: localStorage.getItem("jwtToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      // eslint-disable-next-line
      .then((response) => { // NOSONAR
        if (response != null) {
          return resolve(response.data);
        }
      })
      .catch((error) => {
        errorHandler(reject, error.response.data.message);
        return null;
      });
  });
}



// Author : Noel Fredrick (To get folder management data)
function getMasterAdminModuleData(data) {
  return new Promise(function onThen(resolve, reject) {
    axios
      .get(`${urlConfig.tmlcApiUrl}adminmodule/${data.userId}/${data.type}/${data.id}/${data.masterId}`, {
        headers: {
          authorization: localStorage.getItem("jwtToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      // eslint-disable-next-line
      .then((response) => { // NOSONAR
        if (response != null) {
          return resolve(response.data);
        }
      })
      .catch((error) => {
        errorHandler(reject, error.response.data.message);
        return null;
      });
  });
}


function getTeamMemberMatrixData(data) {
  return new Promise(function onThen(resolve, reject) {
    axios
      .get(`${urlConfig.tmlcApiUrl}getteammembermatrixdata/${data.userId}/${data.fyid}`, {
        headers: {
          authorization: localStorage.getItem("jwtToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      // eslint-disable-next-line
      .then((response) => { // NOSONAR
        if (response != null) {
          return resolve(response.data);
        }
      })
      .catch((error) => {
        errorHandler(reject, error.response.data.message);
        return null;
      });
  });
}

function okrAdminCalculationData(data) {

  return new Promise(function onThen(resolve, reject) {
    axios
      .post(`${urlConfig.tmlcApiUrl}okrAdminCalculation`, {
        headers: {
          authorization: localStorage.getItem("jwtToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      // eslint-disable-next-line
      .then((response) => { // NOSONAR
        if (response != null) {
          return resolve(response.data);
        }
      })
      .catch((error) => {
        errorHandler(reject, error.response.data.message);
        return null;
      });
  });
}




function getAdminCalData(data) {
  return new Promise(function onThen(resolve, reject) {
    axios
      .get(`${urlConfig.tmlcApiUrl}okradmincalculation/730/22/3`, {
        headers: {
          authorization: localStorage.getItem("jwtToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      // eslint-disable-next-line
      .then((response) => { // NOSONAR
        if (response != null) {
          return resolve(response.data);
        }
      })
      .catch((error) => {
        errorHandler(reject, error.response.data.message);
        return null;
      });
  });
}

export function getAdminEmailMappingData(type, emailMappingId) {
  return new Promise(function onThen(resolve, reject) {
    axios
      .get(`${urlConfig.tmlcApiUrl}emailmapping/${type}/${emailMappingId}`, {
        headers: {
          authorization: localStorage.getItem("jwtToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      // eslint-disable-next-line
      .then((response) => { // NOSONAR
        if (response != null) {
          return resolve(response.data);
        }
      })
      .catch((error) => {
        errorHandler(reject, error.response.data.message);
        return null;
      });
  });
}




const adminservices = {
  getMasterRoles,
  getMasterModules,
  getAdminOrgChartGridData,
  getAdminMasterData,
  getAdminUserRoleData,
  getFolderManageData,
  getMasterAdminModuleData,
  getTeamMemberMatrixData,
  okrAdminCalculationData,
  getAdminCalData,
  getAdminEmailMappingData

};

export default adminservices;
