import React from 'react'
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";

const useStyles = makeStyles(() => ({
  listItemHeight: { height: 32, fontSize: 12 },
  iconNameFont: { fontSize: 10 },
  iconography: { fontWeight: "bold", marginTop: -15 },
}));

const legendData = [
  { A: "Absent" },
  { AL: "Festival Holiday" },
  { BL: "Without pay leave" },
  { EL: "Exigency Leave" },
  { FH: "Festival Holiday" },
  { HO: "Public Holiday" },
  { L1: "L1 Leave" },
  { L4: "L4 Leave" },
  { ML: "Quarter-day Leave" },
  { OL: "Other Leave" },
  { PL: "Privilege Leave" },
  { PTO: "Without pay leave" },
  { QL: "Quarter-day Leave" },
  { SO: "Birthday/Anniversary" },
  { WO: "Weekly Off" },
  { WPL: "Without pay leave" },
  { Y: "Full-day Present" },
];
export default function TibrLegends(props) {
  const classes = useStyles();
  return (
    <div>
      <List>
        <ListItem className={classes.iconography}>Glossary</ListItem>
        <Divider />
        {legendData.map((each, idx) => (
          <ListItem className={classes.listItemHeight} key={idx}>
            <ListItemIcon>
              <ListItemText primary={Object.keys(each)[0]} />
            </ListItemIcon>
            <ListItemText primary={Object.values(each)[0]} />
          </ListItem>
        ))}
      </List>
    </div>
  );
}
