/**
 * Author: Md Jahir Uddin
 * Created: 17-08-20
 * @description This hook will listen any click outside of the passed ref and call the callback
 */
import useEventListener from "./useEventListener"

export default function useClickOutside(ref, cb) {
    useEventListener("click", e => {
        if (ref.current == null || ref.current.contains(e.target)) return
        cb(e)
    }, document)
}