import React, { Component } from 'react';

class MappingActionTemplate extends Component{
    constructor(props) {
    super(props);
    this.state = {
    };
    this.makeActive = this.makeActive.bind(this);
    this.openPopup = this.openPopup.bind(this);
    this.isActive = this.isActive.bind(this);
  
  }
  openPopup=(e, type, layerMappingData)=>{
       this.props.category==="LAYER_MAPPING" ? this.props.openPopup( e, type, layerMappingData) : this.props.openPopup( e, type) 
  }
   makeActive=(id,data,activeFlag)=>{
    this.props.makeActiveInActive(id,data,activeFlag)
  }
  isActive = (item) => {
    
    if (item.layerName!=="" && item.isActive !== "1") {
      return (
          <button id='active'
            onClick={() =>
              this.makeActive("Status", item, "1")
            }
          >
            Active
          </button>
      )
    }else if(item.layerName==="" && item.subLayerName==="" && item.hierarchy==="") {
      return (null) 
    
    }
    else {
      return (
        <>
          {this.props.category==="LAYER_MAPPING" ? (<>
          <button id='edit'
            onClick={() =>
              this.openPopup(item.layerName, "Edit", item)
            }
          >
            Edit
          </button>
          <button id="inactive" className='inactiveMapping'
            onClick={() =>
              this.makeActive("Status", item, "0")
            }
          >
            Inactive
          </button></>) : 
          (<>
          <button id='edit'
            onClick={() =>
              this.openPopup( "Edit", item)
            }
          >
            Edit
          </button>
          <button id="inactive"
            onClick={() =>
              this.makeActive("Status", item, "0")
            }
          >
            Inactive
          </button></>
          )}
          </>
      )
    }
  }

  layerMappingTemp=(item)=>{
   
    return (<>
        {(item.Type === "+") ? (
            <button id="add"
                onClick={() =>
                this.openPopup(item.practiceAreaName, "Add", item)
                }
            >
                Add
            </button>            
        ) : this.isActive(item)}
        </>)

  }

  subLayerMappingTemp=(item)=>{
    return (<>
        {(item.layerName!=="" && item.Type === "+") ? (         
            <button id="add"
                onClick={() =>
                this.openPopup( "Add", item)
                }
            >
                Add
            </button>
        ) : this.isActive(item)}
        </>)

  }
  render(){
        const {props}=this.props
        const item=props
    
    return(<>
    {this.props.category==="LAYER_MAPPING" ? this.layerMappingTemp(item) :  this.subLayerMappingTemp(item)}
            </>)
    }
}

export default MappingActionTemplate