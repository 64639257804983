import React from "react";

import { createStyles } from "@material-ui/styles";
import { withStyles, Toolbar, Button } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import getISOWeek from "date-fns/getISOWeek";

export const styles = theme =>
    createStyles({
        toolbar: {
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "center",
            height: 100,
              backgroundColor:
                theme.palette.type === "light"
                  ? theme.palette.primary.main
                  : theme.palette.background.default
        },
        label: {
          color:
            theme.palette.type === "light"
              ? theme.palette.primary.contrastText
              : theme.palette.background.default
        }
    });
    
const CalenderToolbar = ({ setOpenView, date }) => {
    const dateFns = new DateFnsUtils();
    const year = dateFns.getYear(date);
    const weeknumber = getISOWeek(date);
    const month = dateFns.format(date, 'MMM');
    const day = dateFns.format(date, 'd');
    const dayName = dateFns.format(date, 'eee');

    return (
        <Toolbar className="MuiToolbar-root MuiToolbar-regular MuiPickersToolbar-toolbar MuiPickersDatePickerRoot-toolbar MuiToolbar-gutters">
            <Button onClick={e => setOpenView("year")}>
                <span className="MuiPickersToolbar-label">
                    <h6 className="MuiTypography-root MuiPickersToolbarText-toolbarTxt MuiTypography-subtitle1">
                        {year}, Week {weeknumber}
                    </h6>
                </span>
                <span className="MuiTouchRipple-root" />
            </Button>
            <Button onClick={e => setOpenView("date")}>
                <span className="MuiPickersToolbar-label">
                    <h4 className="MuiTypography-root MuiPickersToolbarText-toolbarTxt MuiPickersToolbarText-toolbarBtnSelected MuiTypography-h4 MuiTypography-alignCenter">
                        {dayName}, {month} {day}
                    </h4>
                </span>
                <span className="MuiTouchRipple-root" />
            </Button>
        </Toolbar>
    );
};

export default withStyles(styles, { name: "MuiPickersToolbar" })(CalenderToolbar);
