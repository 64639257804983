import React from "react";
import { GridLoader } from 'react-spinners';
export default function ProgressBar() {
  // const [progress, setProgress] = React.useState(0);
  // React.useEffect(() => {
  //   function tick() {
  //     // setProgress((oldProgress) => (oldProgress >= 100 ? 0 : oldProgress + 1));
  //   }

  //   const timer = setInterval(tick, 25);
  //   return () => {
  //     clearInterval(timer);
  //   };
  // }, []);

  return (
    <div className="loader">
            <GridLoader
                margin={2}
                size={15}
                color={"#f94f5e"}
                loading={true}
            />
        </div>
  );
}
