/**
 * Author: Vivek Khodade.
 * Last Modify:08-May-2020
 * Modified By:Vivek Khodade
 */
import axios from 'axios';
import { urlConfig } from '../../config/config';

import { encryptMethod } from "../../app/other/commonfunction";
function errorHandler(reject, error) {
    console.dir(error);
    reject("operation failed");
}

function returnResponse(response) {
    if (response != null) {
        return response.data;
    }
}

/**
 * @author Vinayak Jadhav
 * @param {*} candid 
 * @param {*} authUserid 
 * @param {*} pmode 
 * @description get the basic information of selected candidate
 */
function sendCandidateUploadLink(candid, authUserid) {
    return new Promise(
        async function onThen(resolve, reject) {
            // eslint-disable-next-line
            await axios.get(urlConfig.tmlcApiUrl + 'mailcandidate/' + candid + '/' + authUserid,
                {
                    headers: {
                        'authorization': localStorage.getItem('jwtToken'),
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                }
            ).then(function (response) {
                if (response != null) {
                    return resolve(response.data)
                }
            }).catch(function (error) {
                errorHandler(reject, error.response.data.message)
                return null;
            });
        }
    )
}
/**
 * @author Prashant Waphare
 * @param {*} candid 
 * @param {*} authUserid 
 * @param {*} emailId 
 * @description send document validation information mail to candidate
 */
function sendCandidateDocValidateInfo(firstName, emailId, candid, authUserid) {
    return new Promise(
        async function onThen(resolve, reject) {
            // eslint-disable-next-line
            await axios.get(`${urlConfig.tmlcApiUrl}candidateDocValidationInfo/${encryptMethod(firstName)}/${encryptMethod(emailId)}/${encryptMethod(candid.toString())}/${encryptMethod(authUserid.toString())}`,
                {
                    headers: {
                        'authorization': localStorage.getItem('jwtToken'),
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                }
            ).then(function (response) {
                return resolve(returnResponse(response));
            }).catch(function (error) {
                errorHandler(reject, error.response.data.message)
                return null;
            });
        }
    )
}


/**
 * @author Vivek Khodade
 * @param {*} candid 
 * @param {*} authUserid 
 * @param {*} pmode 
 * @description get the basic information of selected candidate
 */
function getBasicInformation(candid, authUserid, pmode) {
    return new Promise(
        async function onThen(resolve, reject) {
            // eslint-disable-next-line
            await axios.get(urlConfig.tmlcApiUrl + 'candidate/' + candid + '/' + authUserid + '/' + pmode,
                {
                    headers: {
                        'authorization': localStorage.getItem('jwtToken'),
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                }
            ).then(function (response) {
                return resolve(returnResponse(response));
            }).catch(function (error) {
                errorHandler(reject, error.response.data.message)
                return null;
            });
        }
    )
}

/**
 * @author Suresh R. Sargam
 * @param {*} candid 
 * @description get the basic information of selected candidate
 */
function getCandidateInfo(candid) {
    return new Promise(
        async function onThen(resolve, reject) {
            // eslint-disable-next-line
            await axios.get(urlConfig.tmlcApiUrl + 'candidate/' + candid, {}
            ).then(function (response) {
                return resolve(returnResponse(response));
            }).catch(function (error) {
                errorHandler(reject, error.response.data.message)
                return null;
            });
        }
    )
}

/**
 * @author Vivek Khodade
 * @param {*} candid 
 * @param {*} authUserid 
 * @param {*} pmode 
 * @description get the basic information of selected employee.
 */
function getEnployeeBasicInformation(empid, authUserid) {
    return new Promise(
        async function onThen(resolve, reject) {
            // eslint-disable-next-line
            await axios.get(urlConfig.tmlcApiUrl + 'employee/' + empid + '/' + authUserid,
                {
                    headers: {
                        'authorization': localStorage.getItem('jwtToken'),
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                }
            ).then(function (response) {
                return resolve(returnResponse(response));
            }).catch(function (error) {
                errorHandler(reject, error.response.data.message)
                return null;
            });
        }
    )
}
/**
 * @author Vivek khodade
 * @param {*} empid 
 * @param {*} authUserid 
 * @description get the salary details of selected employee.
 */
function getEmployeeSalaryDetails(empid, authUserid) {
    return new Promise(
        async function onThen(resolve, reject) {
            // eslint-disable-next-line
            await axios.get(urlConfig.tmlcApiUrl + 'employee/salary/' + empid + '/' + authUserid,
                {
                    headers: {
                        'authorization': localStorage.getItem('jwtToken'),
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                }
            ).then(function (response) {
                return resolve(returnResponse(response));
            }).catch(function (error) {
                errorHandler(reject, error.response.data.message)
                return null;
            });
        }
    )
}
/**
 * @author Vivek khodade
 * @param {*} candidateid 
 * @param {*} authUserid 
 * @description get the selected employee appointment letter list
 */
function getAppointmentLetters(candidateid, authUserid) {
    return new Promise(
        async function onThen(resolve, reject) {
            // eslint-disable-next-line
            await axios.get(urlConfig.tmlcApiUrl + 'employee/appointmentletter/' + candidateid + '/' + authUserid,
                {
                    headers: {
                        'authorization': localStorage.getItem('jwtToken'),
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                }
            ).then(function (response) {
                return resolve(returnResponse(response));
            }).catch(function (error) {
                errorHandler(reject, error.response.data.message)
                return null;
            });
        }
    )
}
/**
 * @author Vivek khodade
 * @param {*} authUserid 
 * @description get master list of Hr team member list.
 */
function getMasterHrMembarlist(authUserid) {
    return new Promise(
        async function onThen(resolve, reject) {
            // eslint-disable-next-line
            await axios.get(urlConfig.tmlcApiUrl + 'candidate/master/HR/' + authUserid,
                {
                    headers: {
                        'authorization': localStorage.getItem('jwtToken'),
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                }
            ).then(function (response) {
                return resolve(returnResponse(response));
            }).catch(function (error) {
                errorHandler(reject, error.response.data.message)
                return null;
            });
        }
    )
}
/**
 * @author Vivek khodade
 * @param {*} candid 
 * @param {*} typeid 
 * @param {*} authUserid 
 * @description get employee profile tabs data(Initiate, Role, Asset List)
 */
function getInitiateOrRoleOrlDOrAssetListTabData(empid, typeid, authUserid) {
    return new Promise(
        async function onThen(resolve, reject) {
            // eslint-disable-next-line
            await axios.get(urlConfig.tmlcApiUrl + 'employee/onboard/' + empid + '/' + typeid + '/' + authUserid,
                {
                    headers: {
                        'authorization': localStorage.getItem('jwtToken'),
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                }
            ).then(function (response) {
                return resolve(returnResponse(response));
            }).catch(function (error) {
                errorHandler(reject, error.response.data.message)
                return null;
            });
        }
    )
}
/**
 * Author:Vivek Khodade
 * @param {*} typeid type id
 * @param {*} authUserid user id
 * get the functional asset list.
 */
function getFunctionalAssetList(typeid, authUserid) {
    return new Promise(
        async function onThen(resolve, reject) {
            // eslint-disable-next-line
            await axios.get(urlConfig.tmlcApiUrl + 'candidate/func/' + typeid + '/' + authUserid,
                {
                    headers: {
                        'authorization': localStorage.getItem('jwtToken'),
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                }
            ).then(function (response) {
                return resolve(returnResponse(response));
            }).catch(function (error) {
                errorHandler(reject, error.response.data.message)
                return null;
            });
        }
    )
}
/**
 * Author:Vivek Khodade
 * @param {*} typeid type id
 * @param {*} authUserid user id
 * get the Employee Confirmation List.
 */
function getEmployeeConfirmationList(typeid, authUserid) {
    return new Promise(
        async function onThen(resolve, reject) {
            // eslint-disable-next-line
            await axios.get(urlConfig.tmlcApiUrl + 'employee/confirm/' + typeid + '/' + authUserid,
                {
                    headers: {
                        'authorization': localStorage.getItem('jwtToken'),
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                }
            ).then(function (response) {
                return resolve(returnResponse(response));
            }).catch(function (error) {
                errorHandler(reject, error.response.data.message)
                return null;
            });
        }
    )
}
// Add By Prashant Waphare Get list of all candidates
function getAllTabCandidateDetails(candid, authUserid) {
    return new Promise(
        async function onThen(resolve, reject) {
            // eslint-disable-next-line
            await axios.get(`${urlConfig.tmlcApiUrl}candidate/status/ ${encryptMethod(candid.toString())}/${encryptMethod(authUserid.toString())}`,
                {
                    headers: {
                        'authorization': localStorage.getItem('jwtToken'),
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                }
            ).then(function (response) {
                return resolve(returnResponse(response));
            }).catch(function (error) {
                errorHandler(reject, error.response.data.message)
                return null;
            });
        }
    )
}
// Add By :- Prashant Waphare get Master data.
//Modified By Vivek Khodade
//Added one condition. 
function getMasterData(type, authUserid, jobRole) {
    return new Promise(
        async function onThen(resolve, reject) {
            // eslint-disable-next-line
            await axios.get(urlConfig.tmlcApiUrl + 'candidate/master/' + type + '/' + authUserid + ((jobRole !== null && typeof jobRole !== 'undefined') ? '/' + jobRole : ''),
                {
                    headers: {
                        'authorization': localStorage.getItem('jwtToken'),
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                }
            ).then(function (response) {
                return resolve(returnResponse(response));
            }).catch(function (error) {
                errorHandler(reject, error.response.data.message)
                return null;
            });
        }
    )
}

function getSubPnlEmployeeInformation(authUserid, subPnlId) {
    return new Promise(
        async function onThen(resolve, reject) {
            // eslint-disable-next-line
            await axios.get(urlConfig.tmlcApiUrl + 'employee/subpnl/' + authUserid + '/' + subPnlId,
                {
                    headers: {
                        'authorization': localStorage.getItem('jwtToken'),
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                }
            ).then(function (response) {
                return resolve(returnResponse(response));
            }).catch(function (error) {
                errorHandler(reject, error.response.data.message)
                return null;
            });
        }
    )
}

function getCandidateInformation(candid, authUserid, custType) {
    return new Promise(
        async function onThen(resolve, reject) {
            // eslint-disable-next-line
            await axios.get(urlConfig.tmlcApiUrl + 'candidate/' + candid + '/' + authUserid + '/' + custType,
                {
                    headers: {
                        'authorization': localStorage.getItem('jwtToken'),
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                }
            ).then(function (response) {
                return resolve(returnResponse(response));
            }).catch(function (error) {
                errorHandler(reject, error.response.data.message)
                return null;
            });
        }
    )
}

// To check whether the candidate submitted all documents
function getCandidateDocUplStatus(candid) {
    return new Promise(
        async function onThen(resolve, reject) {
            // eslint-disable-next-line
            await axios.get(urlConfig.tmlcApiUrl + 'document/verify/' + encryptMethod(candid.toString()),
                {
                    headers: {
                        'authorization': localStorage.getItem('jwtToken'),
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                }
            ).then(function (response) {
                return resolve(returnResponse(response));
            }).catch(function (error) {
                errorHandler(reject, error.response.data.message)
                return null;
            });
        }
    )
}
// Add By :- Prashant Waphare get Master data. | 04-06-2020
// Description :- this API use for get city,state on pincode. 
function getCityState(pincode, countryCode) {
    return new Promise(
        async function onThen(resolve) {
            // eslint-disable-next-line
            const response = axios.get(`https://data.opendatasoft.com/api/records/1.0/search/?dataset=geonames-postal-code%40public-us&q=${pincode}&refine.country_code=${countryCode}`)
            return resolve(response);
        });
}
// Add By :- Prashant Waphare get cost sharing list. | 29-09-2020
// Description :- this API use for get list of cost sharing. 
function getCostSharingList(data) {
    return new Promise(
        async function onThen(resolve, reject) {
            // eslint-disable-next-line
            await axios.post(urlConfig.tmlcApiUrl + 'addeditorgchartsharing', data,
                {
                    headers: {
                        'authorization': localStorage.getItem('jwtToken'),
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                }
            ).then(function (response) {
                return resolve(returnResponse(response));
            }).catch(function (error) {
                errorHandler(reject, error.response.data.message)
                return null;
            });
        }
    )
}

function getOrgchartMasterDropdownData(type, id, isEncrypted) {
    return new Promise(
        async function onThen(resolve, reject) {
            // eslint-disable-next-line
            await axios.get(urlConfig.tmlcApiUrl + 'orgchartmasterdropdown/' + type + '/' + id + '/' + isEncrypted,
                {
                    headers: {
                        'authorization': localStorage.getItem('jwtToken'),
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                }
            ).then(function (response) {
                return resolve(returnResponse(response));
            }).catch(function (error) {
                errorHandler(reject, error.response.data.message)
                return null;
            });
        }
    )
}

// Add By :- Nookesh | 08-03-2022
// Description :- Get Other calendar list of particular user
function getCalendarAccessList(id, selectedUser) {
    return new Promise(
        async function onThen(resolve, reject) {
            // eslint-disable-next-line
            await axios.get(urlConfig.apiurl + 'othercalendaraccess/' + id + '/' + selectedUser,
                {
                    headers: {
                        'authorization': localStorage.getItem('jwtToken'),
                        'Accept': 'application/json',
                        'Content-Type': 'application/json'
                    }
                }
            ).then(function (response) {
                return resolve(returnResponse(response));
            }).catch(function (error) {
                errorHandler(reject, error.response.data.message)
                return null;
            });
        }
    )
}

const onbordingServices =
{
    sendCandidateUploadLink: sendCandidateUploadLink,
    getBasicInformation: getBasicInformation,
    getEmployeeSalaryDetails: getEmployeeSalaryDetails,
    getInitiateOrRoleOrlDOrAssetListTabData: getInitiateOrRoleOrlDOrAssetListTabData,
    getAllTabCandidateDetails: getAllTabCandidateDetails,
    getMasterData: getMasterData,
    getCandidateInformation: getCandidateInformation,
    getFunctionalAssetList: getFunctionalAssetList,
    getAppointmentLetters: getAppointmentLetters,
    getEnployeeBasicInformation: getEnployeeBasicInformation,
    getEmployeeConfirmationList: getEmployeeConfirmationList,
    getCandidateInfo: getCandidateInfo,
    getMasterHrMembarlist: getMasterHrMembarlist,
    getCandidateDocUplStatus: getCandidateDocUplStatus,
    getCityState: getCityState,
    getSubPnlEmployeeInformation: getSubPnlEmployeeInformation,
    getCostSharingList: getCostSharingList,
    sendCandidateDocValidateInfo: sendCandidateDocValidateInfo,
    getOrgchartMasterDropdownData: getOrgchartMasterDropdownData,
    getCalendarAccessList: getCalendarAccessList
};

export default onbordingServices