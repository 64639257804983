 /**
 * Author Name :- Prashant Waphare
 * Date :28-05-2020
 * Description :- This file use for HR Withdrawal employee list.
 */
import * as React from "react";
import { closest, isNullOrUndefined } from "@syncfusion/ej2-base";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Filter,
  Inject,
  VirtualScroll,
  Sort,
  Page,
} from "@syncfusion/ej2-react-grids";
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import "../../../app/containers/tmlc/tmlc.css";
import "../resignationcomponent/resignationcomponent.css";
import { connect } from "react-redux";
import { Button, Dialog } from "@material-ui/core";
import { Redirect } from "react-router-dom";
import {
  DialogContent,
  DialogActions,
  DialogTitle,
} from "../../customdialog/customdialog";
import { approveWithdrawl, submiteExitClearanceData } from '../../../redux/actions/entryexit/separationaction';
import EmployeeNameTemplate from "../employeeNameTemplate";
import {
  getGridColumn,
  EMPLOYEE_NAME,
  REPORTING_SUPERVISOR_STATUS,
  PL_STATUS,
  ACTION,
  JOINING_DATE,
  DATE_OF_WITHDRAWAL,
  DATE_OF_RESIGNATION,
  LWD_OFFICIAL,
  LWD_REQUESTED,
  WITH_REASON,
  NOTICE_PERIOD,
  DAYS_TO_LWD,
  HR_STATUS
} from "../../../app/other/gridcolumns";
import {DateFilterformat} from '../../../../src/config/config';
import { AllDateFormat, PnlStatus, HrStatus, ReportingMgrStatus } from "../allcommoncomponents";
import { encrypt,getNotify,encodeString } from "../../../app/other/commonfunction";
import { EMPLOYEE_PROFILE } from "../../../redux/constant/meetingcategoryconstant";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPlayCircle, faCheckCircle, faArrowAltRight, faTimesCircle } from '@fortawesome/pro-light-svg-icons'
import ConfirmDialog from '../../confirmdialog/confirmdialog';
import { setMeetingCategory } from '../../../redux/actions/meetingcategoryaction';
import { WARNING } from "../../../assets/constants/constants";
library.add(faPlayCircle, faCheckCircle, faArrowAltRight, faTimesCircle);

class HrWithdrawalGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: null,
      actionState: false,
      button1Caption: '',
      button2Caption: '',
      title: '',
      message: '',
      open: false,
      initiateEmployeeId: ''

    };
    this.height =
      (window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight) - 252;
    this.width =
      (window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth) - 100;
    this.dReady = false;
    this.dtTime = false;
    this.isDataBound = false;
    this.isDataChanged = true;
    this.dropSlectedIndex = null;
    this.check = {
      type: "CheckBox",
    };
    this.select = {
      persistSelection: true,
      type: "Multiple",
      checkboxOnly: true,
    };
    this.Filter = {
      type: "Menu",
    };
    this.pageOptions = {
      pageSize:
        (window.innerHeight ||
          document.documentElement.clientHeight ||
          document.body.clientHeight) > 700
          ? 12
          : 6,
    };
    this.name = {
      type: "CheckBox",
    };
    this.employeeNameTemplate = this.employeeNameTemplate.bind(this);
    this.navigateToAddBasicInformation = this.navigateToAddBasicInformation.bind(
      this
    );
    this.handleConfirmedYes = this.handleConfirmedYes.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.withdrawlId = '';
    this.taskId = '';
    this.empId = '';
    this.withdrawlStatus = "";
    this.actioType = "";
  }

  componentDidMount() {
    this.props.getHrWithdrawalTabData();
    setTimeout(() => {
      const ele = document.getElementsByClassName("e-headercelldiv");
      
      for(let i in ele) {
        if(!isNaN(parseInt(i, 10))){
          let index = parseInt(i, 10);
          if (
            ele[index].innerText === JOINING_DATE ||
            ele[index].innerText === DATE_OF_WITHDRAWAL ||
            ele[index].innerText === DATE_OF_RESIGNATION ||
            ele[index].innerText === LWD_OFFICIAL ||
            ele[index].innerText === LWD_REQUESTED ||
            ele[index].innerText === NOTICE_PERIOD ||
            ele[index].innerText === ACTION
          ) {
            ele[index].classList.add("grid-center-header");
          } else {
            ele[index].classList.add("grid-left-header");
          }
        }
      }
    }, 1000);
  }

  onQueryCellInfoPreJoining = (args) => {
    const { cell } = args;
    cell.setAttribute(
      "style",
      "font-size:14px !important; text-align: left !important; padding-left:18px !important"
    );
  };

  onDataBound = () => {
    clearTimeout(this.clrIntervalFun);
    clearInterval(this.intervalFun);
    this.dtTime = true;
  };

  onComplete = (args) => {
    if (args.requestType === "filterchoicerequest") {
      if (
        args.filterModel.options.field === "Trustworthiness" ||
        args.filterModel.options.field === "Rating" ||
        args.filterModel.options.field === "Status"
      ) {
        const span = args.filterModel.dialogObj.element.querySelectorAll(
          ".e-selectall"
        )[0];
        if (!isNullOrUndefined(span)) {
          closest(span, ".e-ftrchk").classList.add("e-hide");
        }
      }
    }
  };

  onChange() {
    this.ddObj.hidePopup();
    this.gridInstance.showSpinner();
    this.dropSlectedIndex = null;
    clearTimeout(this.clrIntervalFun2);
    this.clrIntervalFun2 = setTimeout(() => {
      this.isDataChanged = true;
      this.stTime = null;
      const contentElement = this.gridInstance.contentModule.getPanel()
        .firstChild;
      contentElement.scrollLeft = 0;
      contentElement.scrollTop = 0;
      this.gridInstance.pageSettings.currentPage = 1;
      this.stTime = performance.now();
      this.gridInstance.hideSpinner();
    }, 100);
  }

  onLoad = () => {
    document
      .getElementById("overviewgrid")
      .ej2_instances[0].on("data-ready", () => {
        this.dReady = true;
        this.stTime = performance.now();
      });
    document
      .getElementById("overviewgrid")
      .addEventListener("DOMSubtreeModified", () => {
        if (this.dReady && this.stTime && this.isDataChanged) {
          this.stTime = null;
          this.dReady = false;
          this.dtTime = false;
          this.isDataChanged = false;
        }
      });
  };

  reportingStatus = (props) => {
    const { reportingMgrStatus } = props;
    return (
      <ReportingMgrStatus reportingMgrStatus={reportingMgrStatus} />
    )
  };

  pnlStatus = (props) => {
    const { pnlHeadStatus } = props;
    return (
      <PnlStatus pnlHeadStatus={pnlHeadStatus} />
    )
  };

  hrStatus = (props) => {
    const { hrStatus } = props;
    return (
      <HrStatus hrStatus={hrStatus} />
    )
  };

  actionToggle = (props, type) => {
    const { withdrawlUid, taskId, employeeId, withdrawlStatus, reportingMgrStatus, pnlHeadStatus } = props;
    if (reportingMgrStatus === "Pending" || pnlHeadStatus === "Pending") {
      getNotify(WARNING, "Reporting Supervisor or BU / Function Head withdrawal acceptance status is pending.");
      return;
    }
    this.withdrawlId = withdrawlUid;
    this.taskId = taskId;
    this.empId = employeeId;
    this.withdrawlStatus = withdrawlStatus;
    this.actioType = type
    const { actionState } = this.state;
    this.setState({ actionState: !actionState });
  };

  handleAction = (props) => {
    const {employeeId, daysToLastWorkingDay, reportingMgrStatus, pnlHeadStatus, hrStatus,clearanceInitiated } = props
      if (reportingMgrStatus === "Pending" && pnlHeadStatus === "Pending" && daysToLastWorkingDay <= 2) {
        return (
          <div className="col-4" style={{ paddingLeft: "10px" }}>
            <TooltipComponent content="Initiate Clearance" position="BottomCenter" isSticky={false} className="pointer-cursor"
              cssClass='customtooltip'>
              <FontAwesomeIcon icon={faPlayCircle} style={{ fontSize: "20px" }} onClick={(e) => this.initiateClearance(employeeId)} />
            </TooltipComponent>
          </div>
        )
      }else if ( hrStatus === "Pending") {
      return (
        <div className="row" style={{ paddingLeft: '10px' }}>
          <div className="col-4">
            <TooltipComponent closeOn="Click" content="Accept" position="BottomCenter" isSticky={false} cssClass='customtooltip' className="pointer-cursor">
              <FontAwesomeIcon icon={faCheckCircle} style={{ fontSize: "20px" }} onClick={() => this.actionToggle(props, "1")} />
            </TooltipComponent>
          </div>
          <div className="col-4">
            <TooltipComponent content="Reject" position="BottomCenter" isSticky={false} className="pointer-cursor" cssClass='customtooltip'>
              <FontAwesomeIcon icon={faTimesCircle} style={{ fontSize: "20px" }} onClick={() => this.actionToggle(props, "0")} />
            </TooltipComponent>
          </div>
        </div>
      )
    } else if ((reportingMgrStatus === "Accepted" && pnlHeadStatus === "Accepted" && hrStatus === "Pending") || (reportingMgrStatus === "Rejected" && pnlHeadStatus === "Rejected" && hrStatus === "Pending")) {
      return (
        <div className="row" style={{ paddingLeft: '10px' }}>
          <div className="col-4">
            <TooltipComponent closeOn="Click" content="Manager and P&L pending " position="BottomCenter" isSticky={false} cssClass='customtooltip' className="pointer-cursor">
              <FontAwesomeIcon icon={faCheckCircle} style={{ fontSize: "20px" }} onClick={() => this.actionToggle(props, "1")} />
            </TooltipComponent>
          </div>
          <div className="col-4">
            <TooltipComponent content="Manager and P&L pending" position="BottomCenter" isSticky={false} className="pointer-cursor" cssClass='customtooltip'>
              <FontAwesomeIcon icon={faTimesCircle} style={{ fontSize: "20px" }} onClick={() => this.actionToggle(props, "0")} />
            </TooltipComponent>
          </div>
        </div>
      )
    } else if (pnlHeadStatus === "Accepted") {
      return (
        <div className="col-4" style={{ paddingLeft: "10px" }}>
          <TooltipComponent content="Continue Employment" position="BottomCenter" isSticky={false} className="pointer-cursor" cssClass='customtooltip'>
            <FontAwesomeIcon icon={faArrowAltRight} style={{ fontSize: "20px" }} onClick={(e) => this.actionToggle(props, "2")} />
          </TooltipComponent>
        </div>
      )
    } else if ((pnlHeadStatus === "Rejected")) {
      return (
        <div className="col-4" style={{ paddingLeft: "10px" }}>
          {
            clearanceInitiated === "N" &&
          <TooltipComponent content="Initiate Clearance" position="BottomCenter" isSticky={false} className="pointer-cursor"
            cssClass='customtooltip'>
            <FontAwesomeIcon icon={faPlayCircle} style={{ fontSize: "20px" }} onClick={(e) => this.initiateClearance(employeeId)} />
          </TooltipComponent>
          }
          {
            clearanceInitiated === "Y" &&
          <TooltipComponent content="Initiate Clearance" position="BottomCenter" isSticky={false}
            cssClass='customtooltip'>
            <FontAwesomeIcon icon={faPlayCircle} style={{ fontSize: "20px",color:"green" }} />
          </TooltipComponent>
          }
        </div>
      )
    } else {
      return (
        <div className="col-4" style={{ paddingLeft: "10px" }}>
          <TooltipComponent closeOn="Click" content="Accepted" position="BottomCenter" isSticky={false} cssClass='customtooltip'>
            <FontAwesomeIcon icon={faCheckCircle} style={{ fontSize: "20px", color: "green" }} />
          </TooltipComponent>
        </div>
      )
    }
  }
  initiateClearance = (empId) => {
    const { open } = this.state;
    this.setState({
      initiateEmployeeId: empId,
      open: !open,
      button1Caption: 'Yes',
      button2Caption: 'No',
      title: 'Initiate Clearance',
      message: 'Are you sure you want to initiate clearance?',
    });
  }
  handleConfirmNo = () => {
    const { open } = this.state;
    this.setState({
      open: !open,
      button1Caption: '',
      button2Caption: '',
      title: '',
      message: '',
      initiateEmployeeId: ''
    });
  }
  handleConfirmClose = () => {/**NOSONAR */
    const { open } = this.state;
    this.setState({
      open: !open,
      button1Caption: '',
      button2Caption: '',
      title: '',
      message: '',
      initiateEmployeeId: ''
    });
  }
  handleSubmitYes = () => {
    const { authUser } = this.props;
    const { open, initiateEmployeeId } = this.state;
    const data = {
      type: "clsinit",
      userId: parseInt(authUser),
      empId: initiateEmployeeId
    }
    this.props.submiteExitClearanceData(data);
    this.setState({
      open: !open,
      button1Caption: '',
      button2Caption: '',
      title: '',
      message: '',
      initiateEmployeeId: ''
    });
  }
  resignationReason = (props) => {
    const { sepReason } = props;
    return (
      <div>
        <TooltipComponent
          content={sepReason.replace(/(?:\r\n|\r|\n)/g, '<br>')}
          position="BottomCenter"
          isSticky={false}
          cssClass='customtooltip'
        >
          <span
            style={{
              fontSize: "14px",
              display: "block",
              // width: "100px",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {sepReason.replace(/[&\\#,+()$~%.'":*?<>{}]/g, '')}
          </span>
        </TooltipComponent>
      </div>
    );
  };
  noticePeriod = (props) => {
    const { noticePeriodInDays } = props;
    return (
      <div style={{ textAlign: "center" }}>
        <span>{noticePeriodInDays}{" Days"}</span>
      </div>
    );
  };
  dysToLwd = (props) => {
    const { daysToLastWorkingDay } = props
    return (
      <div style={{ textAlign: "center" }}>
        <span>{daysToLastWorkingDay}{" Days"}</span>
      </div>
    );
  };
  withdrawalDateFormat = (props) => {
    const { dateOfWithdrwal } = props;
    return (
      <AllDateFormat date={dateOfWithdrwal} />
    );
  };
  joiningDateFormat = (props) => {
    const { joiningDate } = props;
    return (
      <AllDateFormat date={joiningDate} />
    );
  };
  lwdRequestedDateFormat = (props) => {
    const { lastworkingEmpRequested } = props;
    return (
      <AllDateFormat date={lastworkingEmpRequested} />
    );
  };
  resignationDateFormat = (props) => {
    const { dateOfResignation } = props;
    return (
      <AllDateFormat date={dateOfResignation} />
    );
  };
  lwdOfficialDateFormat = (props) => {
    const { lastWorkingDayOfficial } = props;
    return (
      <AllDateFormat date={lastWorkingDayOfficial} />
    );
  };

  navigateToAddBasicInformation = async (args) => {
    try {
      localStorage.setItem('last_meeting_category', localStorage.getItem('meeting_category'))
      localStorage.setItem("meeting_category", encodeString(EMPLOYEE_PROFILE));

      const path = `/onboard?empId=${encrypt(args.employeeId)}`;
      this.setState({
        redirect: path
      }, () => this.props.setMeetingCategory(EMPLOYEE_PROFILE));
    } catch (error) {
      console.log("error", error);
    }
  };
  employeeNameTemplate(props) {
    return (
      <EmployeeNameTemplate
        props={props}
        data="test"
        navigateToAddBasicInformation={this.navigateToAddBasicInformation}
      />
    );
  }

  handleConfirmedYes = () => {
    try {
      const { authUser } = this.props;
      if (this.withdrawlStatus !== 'Accepted') {
        const approveData = {
          userId: authUser,
          withdrawlId: this.withdrawlId,
          taskId: this.taskId,
          empId: this.empId,
          type: "APPR",
          approvalStatus: this.actioType
        };
        this.props.approveWithdrawl(approveData);
      } else {
        const data = {
          type: "contiemp",
          userId: parseInt(authUser),
          empId: this.empId,
          clsInfo: 'withdraw'
        }
        this.props.submiteExitClearanceData(data);
      }
    } catch (error) {
    }


    this.setState({ actionState: false });
  };

  handleClose = () => {
    this.setState({ actionState: false });
  }

  render() {
    const { redirect } = this.state;
    const { hrWithdrawalData } = this.props;
    if (redirect !== null) {
      return <Redirect to={redirect} />;
    }
    var widthColumn = 0;
    getGridColumn("HrWithdrawal").forEach((data) => {
      widthColumn = widthColumn + data.width;

    });
    return (
      <div
        className="row resignation-manager-hr-grid-height"
      // style={{ height: "828px" }}
      >
        <div>
          <div className="row-xs-12 row-sm-12 row-lg-12 row-md-12">
            <div
              style={{
                paddingLeft: "15px",
                paddingRight: "15px",
                paddingTop: "5px",
              }}
            >
              <GridComponent
                className="grid-head-custome"
                id="overviewgrid"
                allowPaging
                pageSettings={this.pageOptions}
                dataSource={hrWithdrawalData}
                enableHover={false}
                enableVirtualization={false}
                rowHeight={30}
                height={this.height}
                ref={(g) => {
                  this.gridInstance = g;
                }}
                actionComplete={this.onComplete}
                load={this.onLoad}
                queryCellInfo={this.onQueryCellInfoPreJoining}
                dataBound={this.onDataBound}
                filterSettings={this.Filter}
                allowFiltering
                allowSorting
                allowSelection
                selectionSettings={this.select}
                allowTextWrap={true}
              >
                <ColumnsDirective>
                  {getGridColumn("HrWithdrawal").map((item) => {
                    switch (item.colName) {
                      case EMPLOYEE_NAME:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.employeeNameTemplate}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                            filter={this.Filter}
                          />
                        );
                      case REPORTING_SUPERVISOR_STATUS:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.reportingStatus}
                            filter={this.check}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                          />
                        );
                      case PL_STATUS:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.pnlStatus}
                            filter={this.check}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                          />
                        );
                      case HR_STATUS:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.hrStatus}
                            filter={this.check}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                          />
                        );

                      case DAYS_TO_LWD:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.dysToLwd}
                            // allowFiltering={false}
                            filter={this.check}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                          />
                        );
                      case NOTICE_PERIOD:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.noticePeriod}
                            // allowFiltering={false}
                            filter={this.check}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                          />
                        );
                      case WITH_REASON:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.resignationReason}
                            allowFiltering={false}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                          />
                        );
                      case DATE_OF_WITHDRAWAL:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.withdrawalDateFormat}
                            // allowFiltering={false}
                            filter={this.check}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                            format={DateFilterformat}
                          />
                        );
                      case JOINING_DATE:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.joiningDateFormat}
                            // allowFiltering={false}
                            filter={this.check}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                            format={DateFilterformat}
                          />
                        );
                      case DATE_OF_RESIGNATION:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.resignationDateFormat}
                            // allowFiltering={false}
                            filter={this.check}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                            format={DateFilterformat}
                          />
                        );
                      case LWD_OFFICIAL:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.lwdOfficialDateFormat}
                            // allowFiltering={false}
                            filter={this.check}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                            format={DateFilterformat}
                          />
                        );
                      case LWD_REQUESTED:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.lwdRequestedDateFormat}
                            // allowFiltering={false}
                            filter={this.check}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                            format={DateFilterformat}
                          />
                        );
                      case ACTION:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.handleAction}
                            allowFiltering={false}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                          />
                        );
                      default:
                        return (
                          <ColumnDirective
                            key={item.field}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                            filter={this.check}
                          />
                        );
                    }
                  })}
                </ColumnsDirective>
                <Inject services={[Page, Filter, VirtualScroll, Sort]} />
              </GridComponent>
            </div>
          </div>
          <div style={{ width: "350px" }}>
            <Dialog open={this.state.actionState}>
              <DialogTitle>
                {this.actioType === '1' ? 'Accept Withdrawal' : this.actioType === "0" ? /**NOSONAR */'Reject Withdrawal': 'Continue Employment'} </DialogTitle>
              <DialogContent>
                {this.actioType === '1' ? 'Are you sure you want to accept the withdrawal?' : this.actioType === '0' ?/**NOSONAR */ 'Are you sure you want to reject the withdrawal?' : 'Are you sure you want to continue his/her employment?'}
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleConfirmedYes}> Yes </Button>
                <Button onClick={this.handleClose}> No </Button>
              </DialogActions>
            </Dialog>
          </div>
          <ConfirmDialog open={this.state.open}
            handleConfirmYes={this.handleSubmitYes}
            handleConfirmNo={this.handleConfirmNo}
            handleConfirmClose={this.handleConfirmClose}
            button2Caption={this.state.button2Caption}
            button1Caption={this.state.button1Caption}
            title={this.state.title}
            message={this.state.message}
          />
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ auth }) => {
  const { authUser } = auth;
  return { authUser };
};
export default connect(mapStateToProps, { approveWithdrawl, submiteExitClearanceData, setMeetingCategory })(HrWithdrawalGrid);
