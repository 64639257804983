import OnboardingService from "../../services/entryexit/onbordingServices";
import dataService from '../../services/services'
import LnAServices from '../../services/lna/lnaservice'
import tibService from '../../services/tibservices'
import { RATING_SYSTEM } from "../../redux/constant/meetingcategoryconstant"
import { number } from "prop-types";
import okrServices from '../../services/okr/okrservices'


export const getHrMasterList = async (authUser) => {
    const hrMasterList = await OnboardingService.getMasterHrMembarlist(authUser); // NOSONAR
    return hrMasterList
}

export const getResponseNotificationCount = async (authUser) => {
    let responseNotificationCount = await dataService.getMeetingNotifiction(authUser, 'all');
    return responseNotificationCount.result.length
}

export const getHomeOfficeData = async (authUser) => {
    try {
        const gethomeofficedetailsList = await dataService.gethomeofficedetails(authUser, authUser);
        return gethomeofficedetailsList.length === 0 ? [] : gethomeofficedetailsList;
    } catch (error) {

    }
}

export const getOnboardingMasterData = async (authUser, type) => {
    const resultData = await OnboardingService.getMasterData(type, authUser);

    if (resultData !== null) {
        if (type === "SD_SU") {
            return resultData.filter((item) => { return item.Type === type; });
        } else if (type === "All") {
            const bandarr = resultData.filter((item) => { return item.Type === "Grade"; });
            const hEQualification = resultData.filter((item) => { return item.Type === "Highest Education Qualifcation"; });
            const entityPNL = resultData.filter((item) => { return item.Type === "PnL"; });
            const empType = resultData.filter((item) => { return item.Type === "WorkRelationship"; });
            const getCountry = resultData.filter((item) => { return item.Type === "Country"; });
            const getGender = resultData.filter((item) => { return item.Type === "Gender"; });
            const getSalutation = resultData.filter((item) => { return item.Type === "Salutation"; });
            const getSDSU = resultData.filter((item) => { return item.Type === "SD_SU"; });
            const getStateMasterList = resultData.filter((item) => { return item.Type === "Address State"; });
            const functionList = resultData.filter((item) => { return item.Type === "Function"; });
            const roleMasterData = resultData.filter((item) => { return item.Type === "Designation Level"; });
            const sourceCategoryData = resultData.filter((item) => { return item.Type === "Source Category"; });
            const jobPortalData = resultData.filter((item) => { return item.Type === "Job Portal"; });
            const jobTitleData = resultData.filter((item) => { return item.Type === "Job Title"; });
            const recruitementData = resultData.filter((item) => { return item.Type === "Recruitement"; });
            const addressCountryData = resultData.filter((item) => { return item.Type === "BasedInCountry"; });
            const currencyData = resultData.filter((item) => { return item.Type === "CurrencyMaster"; });
            const holidayCountryData = resultData.filter((item) => { return item.Type === "holidayCountry"; });
            getSalutation.sort((a, b) => (parseInt(a.Optional1, 10) > parseInt(b.Optional1, 10)) ? 1 : -1);

            return {
                bandMaster: bandarr,
                highestEducationQualifcationMaster: hEQualification,
                entityMaster: entityPNL,
                employeeTypeMaster: empType,
                countryMaster: getCountry,
                genderMaster: getGender,
                salutationStateMaster: getSalutation,
                servceDivisionSalesUnitMaster: getSDSU,
                stateMasterListMaster: getStateMasterList,
                stateFunctionListMaster: functionList,
                roleMaster: roleMasterData,
                sourceCategoryData: sourceCategoryData,
                jobPortalData: jobPortalData,
                jobTitleData: jobTitleData,
                recruitementMaster: recruitementData,
                addressCountryMaster: addressCountryData,
                currencyMaster: currencyData,
                holidayCountryData: holidayCountryData
            }

        }
    } else {
        return []
    }
}


export const newFeaturesResult = async () => {
    const data = await dataService.getNewFeaturesData();
    if (data != null) {
        let acknowFlag = false;
        if (data.result.length) {
            data.result.map((item) => {
                if (item.acknowFlag === true) {
                    acknowFlag = true
                }
            })
            return {
                newFeaturesData: data.result,
                newFeatureDataStatus: "updated",
                acknowFlag
            };
        }
    }

}

export const getDataMeetingMasterData = async (authUser) => {
    try {
        const data = await (dataService.getMasterData(authUser, "ALL"));
        if (data != null) {
            const MT = data.filter((item) => {
                return item.Type === "MT";
            });
            const MM = data.filter((item) => {
                return item.Type === "Meeting Media";
            });
            const MS = data.filter((item) => {
                return item.Type === "Meeting Source";
            });
            const SP = data.filter((item) => {
                return item.Type === "SrvPrd";
            });
            const SBMT = data.filter((item) => {
                return item.Type === "SubType";
            });
            const TZ = data.filter((item) => {
                return item.Type === "Time Zone";
            });
            const CNTRY = data.filter((item) => {
                return item.Type === "Country";
            });
            const MC = data.filter((item) => {
                return item.Type === "MC";
            });
            const EA = data.filter((item) => {
                return item.Type === "EA";
            });
            const CLTZ = data.filter((item) => {
                return item.Type === "CLTZ";
            });
            const subCategary = data.filter((item) => {
                return item.Type === "SubCategory";
            });
            const CD = data.filter((item) => {
                return item.Type === "Campaign";
            });
            return {
                meetingType: MT,
                meetingSubType: SBMT,
                meetingSource: MS,
                meetingServiceProduct: SP,
                meetingMedia: MM,
                timeZone: TZ,
                clientTimeZone: CLTZ,
                country: CNTRY,
                subCategary,
                meetingCategory: MC,
                executiveAssistant: EA.length > 0 ? EA : null,
                campaignData: CD,
            }
        }

    } catch (error) {
    }
}
export const getTimezoneMasterData = async (authUser) => {
    try {
        const data = await (dataService.getMasterData(authUser, "TZ"));
        if (data != null) {
            const TZ = data.filter((item) => {
                return item.Type === "Time Zone";
            });
            const CLTZ = data.filter((item) => {
                return item.Type === "CLTZ";
            });
            return {
                timeZone: TZ,
                cltz: CLTZ
            }
        }

    } catch (error) {
    }
}

export const getEmpMasterData = async (authUser, type) => {
    try {
        const data = await (dataService.getMasterData(authUser, type));
        if (data != null) {
            const empCode = data.filter((item) => { return item.Type === "empCode"; });
            const currFY = data.filter((item) => { return item.Type === "currFY"; });
            const TIBAccess = data.filter((item) => { return item.Type === "TIB"; });
            const FYData = data.filter((item) => { return item.Type === "FYData"; });
            const DefTZ = data.filter((item) => { return item.Type === "DefTZ"; });
            const baseCountry = data.filter((item) => { return item.Type === "baseCountry"; });
            const holidayCountry = data.filter((item) => { return item.Type === "holidayCountry"; });
            return {
                empCode: empCode[0].Name,
                currFY: currFY[0].Id,
                TIBAccess: TIBAccess[0].Id,
                FYData,
                DefTZ,
                baseCountry: baseCountry[0].Id,
                holidayCountry: holidayCountry[0].Id
            }
        }

    } catch (error) {
    }
}

// LnA Masters
export const getLnAData = async (authUser, type) => {
    try {
        const lanMaster = Promise.resolve(LnAServices.getMasterData(authUser, type, "", ""));
        return lanMaster.then(async (data) => {

            if (data.Result !== false && data.Result !== null && typeof data.Result !== 'undefined') {
                if (type === "FC") {
                    const officeCountry = data.Result.filter((item) => { return item.Type === "Country"; });
                    const officeLocation = data.Result.filter((item) => { return item.Type === "Location/City"; });
                    const relieverData = data.Result.filter((item) => { return item.Type === "Reliever"; });
                    const dayBreakData = data.Result.filter((item) => { return item.Type === "DayBreak"; });
                    const colourMaster = data.Result.filter((item) => { return item.Type === "Color"; });
                    const masterCluster = data.Result.filter((item) => { return item.Type === "MasterCluster"; });
                    const companyName = data.Result.filter((item) => { return item.Type === "CompanyName"; });
                    const masterLeave = data.Result.filter((item) => { return item.Type === "MasterLeave" });
                    const lnaReportMonths = data.Result.filter((item) => { return item.Type === "LNA_Report_Months"; });
                    const swapWeeklyOff = data.Result.filter((item) => { return item.Type === "Swap Weekly Off"; });
                    const downloadMyReport = data.Result.filter((item) => { return item.Type === "Download My Report"; });
                    const downloadLeaveBalance = data.Result.filter((item) => { return item.Type === "Download Leave Balance"; });
                    const downloadLeaveBalanceAllTM = data.Result.filter((item) => { return item.Type === "Download Leave Balance - All Team Member"; });
                    const allCountry = data.Result.filter((item) => { return item.Type === "allCountry"; });
                    const allState = data.Result.filter((item) => { return item.Type === "allState"; });
                    const holidayCountryData = data.Result.filter((item) => { return item.Type === "HolidayCountry"; });
                    const baseCountry = data.Result.filter((item) => { return item.Type === "BaseCountry"; });
                    const reportDate = data.Result.filter((item) => { return item.Type === "reportDate"; });
                    let maxDate = null;
                    let minDate = null;
                    if (reportDate && reportDate.length > 0) {
                        const bothDate = reportDate[0]?.Name?.split(',');
                        minDate = bothDate[0];
                        maxDate = bothDate[1];
                    }
                    return {
                        officeCountry, officeLocation, relieverData,
                        dayBreakData,
                        colourMaster,
                        masterCluster,
                        companyName, masterLeave, lnaReportMonths, swapWeeklyOff,
                        downloadMyReport,
                        downloadLeaveBalance,
                        downloadLeaveBalanceAllTM,
                        allCountry,
                        allState,
                        holidayCountryData,
                        baseCountry,
                        minDate,
                        maxDate
                    }
                } else if (type === "FY") {
                    const financialYearData = data.Result
                    const currentAndFinancialYear = data.Result
                    return {
                        financialYearData,
                        currentAndFinancialYear
                    }
                } else if (type === "masterYearConfig") {
                    const updatedYear = data.Result
                    return {
                        updatedYear
                    }
                } else if (type === "regularization") {
                    const regData = data.Result
                    return {
                        regData
                    }
                }

            }
        })
    } catch (error) {
        console.log('error', error)
    }
}

export const getMonthlyReportDropdownData = async (authUser) => {
    try {
        const lanMaster = Promise.resolve(LnAServices.getMasterData(authUser, "MonthlyReport", "", ""));
        return lanMaster.then(async (data) => {
            if (data.Result) {
                const countryData = data.Result.filter((item) => item.type === "country");
                const businessUnitData = data.Result.filter((item) => item.type === "businessUnit");
                const subBusinessUnitData = data.Result.filter((item) => item.type === "subBusinessUnit");
                const teamMemberData = data.Result.filter((item) => item.type === "teamMember");
                const attendanceListData = data.Result.filter((item) => item.type === "attendance");
                const leaveListData = data.Result.filter((item) => item.type === "leave");
                return {
                    countryData,
                    businessUnitData,
                    subBusinessUnitData,
                    teamMemberData,
                    attendanceListData,
                    leaveListData
                }
            }
        });
    } catch (error) {
        console.log('error', error)
    }
}



export const getLnASymbolData = async (authUser, updateLnAData) => {

    try {
        const lanMaster = await LnAServices.getMasterData(authUser, "LnASymbol", "", "");
        if (lanMaster.Result) {
            const attendanceData = lanMaster.Result.filter((item) => { return item.Type === "Attendance"; });
            const workdayWindowData = lanMaster.Result.filter((item) => { return item.Type === "Workday Window"; });
            const workdayWindowName = lanMaster.Result.filter((item) => { return item.Type === "workdayWindow"; });
            const weekdayData = lanMaster.Result.filter((item) => { return item.Type === "Weekday"; });
            const dayBreakData = lanMaster.Result.filter((item) => { return item.Type === "Day Break"; });
            const dayBreakName = lanMaster.Result.filter((item) => { return item.Type === "DayBreakName"; });
            const attendanceSourceData = lanMaster.Result.filter((item) => { return item.Type === "Attendance Source"; });
            const leaveData = lanMaster.Result.filter((item) => { return item.Type === "Leave"; });
            const holidayData = lanMaster.Result.filter((item) => { return item.Type === "Holiday"; });
            const operationalHolidayData = lanMaster.Result.filter((item) => { return item.Type === "Optional Holiday"; });
            const statutoryData = lanMaster.Result.filter((item) => { return item.Type === "Statutory"; });
            const exceptionalAttdData = lanMaster.Result.filter((item) => { return item.Type === "Exceptional Attendance"; });
            const workingdayData = lanMaster.Result.filter((item) => { return item.Type === "Workingday"; });
            const colourData = lanMaster.Result.filter((item) => { return item.Type === "Colour"; });
            const roleData = lanMaster.Result.filter((item) => { return item.Type === "Role"; });
            const compOffAlowedData = lanMaster.Result.filter((item) => { return item.Type === "compOffAllowed"; });

            updateLnAData({
                symbolData: {
                    attendanceData, workdayWindowData, workdayWindowName, weekdayData, dayBreakData, dayBreakName, attendanceSourceData, leaveData,
                    holidayData, operationalHolidayData, statutoryData, exceptionalAttdData, workingdayData, colourData, roleData, compOffAlowedData
                }
            })

            return {
                attendanceData, workdayWindowData, workdayWindowName, weekdayData, dayBreakData, dayBreakName, attendanceSourceData, leaveData,
                holidayData, operationalHolidayData, statutoryData, exceptionalAttdData, workingdayData, colourData, roleData, compOffAlowedData
            }
        }
    } catch (error) {

    }
}

export const getTMLCUsers = async (authUser) => {
    let data = await dataService.getAttendeeList(authUser, "");
    let newData = [];
    if (data != null) {
        await data.forEach(eachD => {
            eachD.name = eachD.FirstName + ' ' + eachD.LastName;
            if (eachD.Department !== "") {
                eachD.nameWithDepartment = eachD.FirstName + ' ' + eachD.LastName + ' (' + eachD.Department + ' )'
            }
            else {
                eachD.nameWithDepartment = eachD.FirstName + ' ' + eachD.LastName;
            }
            if (eachD.UserId !== 0) {
                newData.push(eachD);
            }
        });
    }
    return { othersCheckAvailablity: newData }
}

export const getUserTIBData = async (authUser) => {
    const userTibData = await tibService.getUserTibData(
        authUser
    );
    if (userTibData.length > 0) {
        return {
            userTibData: userTibData
        };
    }
}

export const getTibUserList = async (authUser) => {
    try {
        const data = await tibService.getTibUseraList("FC", authUser)

        try {
            let userData;
            let months = [];
            let mselectedIds = [];
            let utrMonths = [];
            if (data != null) {
                userData = data.filter((item) => { return item.Type === "User"; })
                months = data.filter((item) => { return item.Type === "Mnths"; });
                utrMonths = data.filter((item) => { return item.Type === "UTRMnths"; });

                if (months.length < 2) {
                    months.map((item) => mselectedIds.push(item.Id));
                } else {
                    for (let i = 0; i < months.length; i++) {
                        if (i < 1) {
                            mselectedIds.push(months[i].Id);
                        }
                    }
                }
                return {
                    userData,
                    months,
                    mselectedIds,
                    utrMonths
                }
            }
        } catch (error) {

        }

    } catch (error) {
    }
}


// OKR rating Masters
export const getOKRRatingasterData = async (authUser) => {
    try {
        const okrMasterData = await okrServices.getOKRMaserData(
            authUser,
            RATING_SYSTEM

        );

        if (okrMasterData.Result) {
            const ratingSystem = okrMasterData.Result.filter(item => item.isActive === 'Active').map(item => ({ Name: item.name, Id: item.masterItemId }))
            return {
                ratingSystem
            }
        }
    } catch (error) {

    }
}

export const getYearData = async (authUser) => {
    let lnaData = await getLnAData(authUser, 'FY');
    if (lnaData && lnaData.financialYearData.length > 0) {
        let obj = lnaData.financialYearData.find(ele => { return ele.CurrYear === 1 || ele.CurrYear === true })
        if (obj) return obj.Id
        else return lnaData.financialYearData[0].Id
    }
}
