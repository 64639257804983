import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ReactHtmlParser from 'react-html-parser'
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Button, Dialog } from "@material-ui/core";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { withStyles } from '@material-ui/core/styles';
import { DialogContent } from "../customdialog/customdialog";
import CreateKPI from "./createkpi"
import CreateObjective from "./createobject"
import {
    CheckBoxComponent,
} from "@syncfusion/ej2-react-buttons";
import { enableRipple } from '@syncfusion/ej2-base';
import { addUpdateObjectveKpi,submitApprovalAction } from "../../redux/actions/okr/okractions";
import { ERROR, objdeleteConfernamation ,KpideleteConfernamation,pleaseSelectObjWhichHaveOneKPI,youCanNotAddMoreThanFiveKPI } from "../../assets/constants/constants";
import { getNotify } from "../../app/other/commonfunction";
import fileIcon from "../../../src/assets/svg/fileIcon_jpeg.png";
import editIcon from "../../../src/assets/svg/ic-Edit.svg";
import deleteIcon from "../../../src/assets/svg/ic-Delete.svg";
import "./newobjectkpi.css";
import { urlConfig, addMaOkrKPILimit } from "../../config/config";
import ConfirmationPopup from "../../utilities/confirmationpopup";

enableRipple(true);
withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));
withStyles((theme) => ({
    root: {
        '&:focus': {
            backgroundColor: theme.palette.primary.main,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white,
            },
        },
    },
}))(MenuItem);
const cssForScroll = `
::-webkit-scrollbar {
    width: 24px;
  }

  ::-webkit-scrollbar-thumb {
    background: white;
    border-right: 13px #8080804f  solid;
    background-clip: padding-box;
  }
`
class DraftAllObjKPI extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openMenu: false,
            openMenuKPI: false,
            heightLayout: 5,
            kpiData: ['1', "2"],
            addKPI: false,
            createObjective: false,
            allObj:[]
        }
        this.closeAddNewObjective = this.closeAddNewObjective.bind(this);
        this.objKpiDeletetitle = "";
        this.objKpiDeletetitleMessage = "";
        this.option = [
            'Edit',
            'Delete',
        ]
        this.menuItems = [
            {
                iconCss: 'fas fa-edit',
                items: [
                    { text: 'Edit', iconCss: 'fas fa-edit' },
                    { text: 'Delete', iconCss: 'fas fa-trash-alt' },
                ],
            },
        ];
    }
    UNSAFE_componentWillReceiveProps(nextProps) {
        if(nextProps.closAlleDialog === "cancel"){
            this.closeAddNewObjective();
            this.closeAddKPI();
            this.props.addUpdateObjectveKpi({closAlleDialog:""});
        }
    }
    updateDimensions() {
        const updateHeight = window.innerHeight - 280;
        this.setState({ heightLayout: updateHeight });
    }
    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }
    handleClose = () => {
        this.setState({ openMenu: false });
        this.props.addUpdateObjectveKpi({ objId: 0, objectiveName: "", objectiveTags: [], visibility: "", levelL: [],objKpiAddUpdateType:""});
    }
    handleCloseKPI = () => {
        this.setState({ openMenuKPI: false });
        this.props.addUpdateObjectveKpi({ objId: 0, kpiId: 0, kpiName: "", kpiDescription: "", kpiAttachment: [], kpiNewTags: [],objKpiAddUpdateType:""});
    }
    handleClickObjective = (e, objdata, Type) => {
        const { objectiveId, objectiveName, objectiveTags, objectiveVisibilityId, levelId } = objdata;
        if (Type === "UpdateObj") {
            this.props.addUpdateObjectveKpi({ objId: objectiveId, objectiveName: objectiveName, objectiveTags: objectiveTags !== null?objectiveTags.split(' ') : [], visibility: objectiveVisibilityId, levelL: levelId !== null || levelId !== '' ? levelId.split(',').map(Number) : [], objKpiAddUpdateType:"CreateDraft"});
        }
        this.setState({ openMenu: true, anchorElOBJ: e.currentTarget });
    }
    handleClickKPI = (e, data) => {
        const { objectiveId, kpiId, kpiName, kpiTags, description, kpiAttachment } = data;
        this.setState({ openMenuKPI: true, anchorElKPI: e.currentTarget });
        this.props.addUpdateObjectveKpi({ objId: objectiveId, kpiId: kpiId, kpiName: kpiName, kpiDescription: description, kpiAttachment: kpiAttachment, kpiNewTags: kpiTags !== null?kpiTags.split('#') : [],objKpiAddUpdateType:"CreateDraft" });
    }
    closeAddKPI = () => {
        this.setState({ addKPI: false });
        this.props.addUpdateObjectveKpi({ objId: 0, kpiId: 0, kpiName: "", kpiDescription: "", kpiAttachment: [], kpiNewTags: [],objKpiAddUpdateType:"" });
    }
    openAddKPI = (args, type,objKPICount) => {
        if(objKPICount.kpis !== undefined){
            if(objKPICount.kpis.length >= addMaOkrKPILimit){
                getNotify(ERROR,youCanNotAddMoreThanFiveKPI);
                return;
            }
        }
        if (type === "Add") {
            this.props.addUpdateObjectveKpi({ objId: args });
        }
        this.setState({ addKPI: true, openMenuKPI: false });
    }
    closeAddNewObjective = () => {
        this.setState({ createObjective: false });
        this.props.addUpdateObjectveKpi({ objId: 0, objectiveName: "", objectiveTags: [], visibility: "", levelL: [],objKpiAddUpdateType:"" });
    }
    openAddNewObjective = () => {
        this.setState({ createObjective: true });
    }
    createDraftimageFilepath = (filepath) => {
        const {employeeId} = this.props;
        let attachmentFiles = [];
        if (filepath !== "" && filepath !== null) {
            attachmentFiles = filepath.split(",");
        }
        return (
            <>
                {
                    attachmentFiles.map((attachedFile,indDraft) => (
                        <span key={`draft${indDraft}`}>
                            <img
                                alt=""
                                src={fileIcon}

                            />
                            <span style={{ padding: "0px 8px 0px 8px" }}>
                                <a
                                    target="_blank"
                                    href={
                                        attachedFile !== null && attachedFile !== ""
                                            ? `${urlConfig.tmlcApiUrl}document/kpi/${employeeId}/${attachedFile}`
                                            : ""
                                    }
                                    rel="noopener noreferrer"
                                    download
                                    style={{textDecoration:'none'}}
                                >
                                    <span title={attachedFile} style={{color: "#f94f5e"}}>
                                    {attachedFile.substring(11,attachedFile.length).length > 35 ? attachedFile.substring(11,attachedFile.length).substr(0, 32)+'...' : attachedFile.substring(11,attachedFile.length)}
                                    </span>
                                </a>
                            </span>
                        </span>
                    ))
                }
            </>
        )
    }
    setObjectDetails = (objdata, type) => {
        this.setState({ createObjective: true, openMenu: false });
    }
    deleteObjectivePopup = (type) => {
        this.setState({deleteObjKPI:true,openMenu:false,openMenuKPI:false});
        if(type === "DeleteObj"){
            this.objKpiDeletetitle = "Delete Objective";
            this.objKpiDeletetitleMessage = objdeleteConfernamation;
        }else{
            this.objKpiDeletetitle = "Delete KPI"
            this.objKpiDeletetitleMessage = KpideleteConfernamation;
        }        
    }
    handleStatusYes = () => {
        const {authUser,objId,kpiId} = this.props;
        if(objId > 0 && kpiId === 0){
            const deleteObj = {
                type: "DeleteObj",
                userId: authUser,
                objId: objId.toString(),
            }
            this.props.submitApprovalAction(deleteObj);
        }else if(objId > 0 && kpiId > 0){
            const deleteKPI = {
                type: "DeleteKPI",
                userId: authUser,
                objId: kpiId.toString(),
            }
            this.props.submitApprovalAction(deleteKPI);
        }
        this.handleStatusNo();
    }
    handleStatusNo = () => {
        this.props.addUpdateObjectveKpi({ objId: 0, kpiId: 0, kpiName: "", kpiDescription: "", kpiAttachment: [], kpiNewTags: [],objKpiAddUpdateType:"",objectiveName: "", objectiveTags: [], visibility: "", levelL: []});
        this.setState({deleteObjKPI:false,openMenu:false,openMenuKPI:false});
        this.objKpiDeletetitle = ""
        this.objKpiDeletetitleMessage = "";
    }
    // Author By:- Prashant waphare | 15-04-2021 | function use for get objectiveId
    getObjSubmitApproval = (e) => {
        const { allOBJKPISubmit } = this.props;
        if (e.kpis.length > 0) {
            var arr = allOBJKPISubmit;
            if (!arr.includes(parseInt(e.objectiveId, 10))) {
                arr.push(parseInt(e.objectiveId, 10));
                this.props.addUpdateObjectveKpi({ allOBJKPISubmit: arr });
                this.setState({allObj:arr});                
            }
            else {
                let filterData = allOBJKPISubmit;
                filterData = filterData.filter((t) => t !== parseInt(e.objectiveId, 10));
                this.props.addUpdateObjectveKpi({ allOBJKPISubmit: filterData });
                this.setState({allObj:filterData});
            }
        }else{
            getNotify(ERROR, pleaseSelectObjWhichHaveOneKPI);
            return; // NOSONAR
        }
    }
    render() { // NOSONAR
        const { openMenu, openMenuKPI, addKPI, createObjective, anchorElOBJ, anchorElKPI,deleteObjKPI,allObj,heightLayout } = this.state;
        const { okrMastersVisibility, levelMaster, objectiveData, kpiId, objId} = this.props;
        return (
            <>
                <div>
                    <DialogContent style={{padding:"0px 0px 0px 28px", overflowX:"hidden"}}>
                    <div className="row" style={{ margin: "0px -16px 4px -44px" }}>
                        <div className="col-xs-10 col-sm-10 col-lg-10 col-md-10" style={{ paddingTop: "8px", marginBottom:8 }}>
                            <span className="draft-name">Drafts ({objectiveData.length})</span>
                        </div>
                        <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2">
                            <Button
                                style={{ float: "right", width: "164px" }}
                                className="remove-button-shadow"
                                color="primary"
                                variant="contained"
                                onClick={this.openAddNewObjective}
                            >
                                Add New Objective
                          </Button>
                        </div>
                    </div>
                    </DialogContent>
                    <style>{cssForScroll} </style>
                    <DialogContent style={{padding:"0px 0px 0px 28px",margin:"0px 0px 0px 0px",overflowX:"hidden"}}>
                    <div style={{ height: heightLayout}}>
                        <>
                    {
                        objectiveData.map((createObj, indObj) =>
                        ( 
                            <div key={`createDraft${createObj.objectiveId}`}>
                                <Accordion defaultExpanded={createObj.kpis.length === 0 ? true : false} key={`accObj${createObj.objectiveId}`} 
                                            style={{ 
                                                    marginTop: indObj===0 ?"0px":" 16px", 
                                                    marginBottom: objectiveData.length ===indObj+1 ?"0px":" 16px", 
                                                    // margin: "16px 0px 16px 0px", 
                                                    boxShadow: "none", position:"initial" }}>
                                    <AccordionSummary style={{ background: "#F2F2F2", paddingLeft: "0px", borderLeft: createObj.approvalStatus === "Pending" ? '4px solid #FDD141' : createObj.approvalStatus === "Approved" ? '4px solid #36C17C' : '4px solid #F47A7A', // NOSONAR
                                     minHeight: "30px"}} 
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-label="Expand"
                                        aria-controls="additional-actions1-content"
                                        id="additional-actions1-header"
                                    >
                                        <span key={`checkspan${createObj.objectiveId}`} style={{ margin: "8px 6px 10px -32px"}}>
                                            <CheckBoxComponent
                                                id={`objId${createObj.objectiveId}`}
                                                checked={allObj.includes(createObj.objectiveId)}
                                                disabled = {createObj.kpis.length > 0 ? false : true}
                                                onChange={(e) => this.getObjSubmitApproval(createObj)}
                                            />
                                        </span>
                                        <div className="col-xs-12 col-sm-8 col-lg-7 col-md-7 bold-header-obj-kpi" style={{ padding: "14px 14px 14px 24px" }}>
                                            {createObj.objectiveName}
                                        </div>
                                        <div className="col-xs-12 col-sm-3 col-lg-4 col-md-4 tags-obj-kpi" style={{ padding: "14px" }}> {createObj.objectiveTags} </div>
                                        <div className="col-xs-12 col-sm-1 col-lg-1 col-md-1" style={{ padding: "0px 8px 0px 8px" }}>
                                            <FormControlLabel id={`control${createObj.objectiveId}`} style={{ margin: "0px", float: "right" }}
                                                control={
                                                    <div>
                                                        <IconButton
                                                            id={`menuIcon${createObj.objectiveId}`}
                                                            onClick={(e) => this.handleClickObjective(e, createObj, "UpdateObj")}
                                                        >
                                                            <MoreVertIcon />
                                                        </IconButton>
                                                        <Menu
                                                            id={`objectId${createObj.objectiveId}`}
                                                            keepMounted
                                                            anchorEl={anchorElOBJ}
                                                            open={Boolean(openMenu)}
                                                            onClose={this.handleClose}
                                                            style={{ margin: "50px 0px 0px -80px" }}
                                                        >
                                                            <MenuItem key={`editObj${createObj.objectiveId}`} onClick={(e) => this.setObjectDetails(createObj, "UpdateObj")}>
                                                                <span style={{ padding: "8px 8px 8px 0px" }}>
                                                                    <img
                                                                        alt=""
                                                                        src={editIcon}

                                                                    />
                                                                </span> <span style={{ padding: "0px 32px 0px 0px" }} >Edit</span></MenuItem>
                                                            <MenuItem key={`deleteObj${createObj.objectiveId}`} onClick={(e) => this.deleteObjectivePopup("DeleteObj")}>
                                                                <span style={{ padding: "8px 8px 8px 0px" }}>
                                                                    <img
                                                                        alt=""
                                                                        src={deleteIcon}

                                                                    /></span> <span> Delete </span></MenuItem>
                                                        </Menu>
                                                    </div>
                                                }
                                                onClick={(event) => { event.stopPropagation() }}
                                                onFocus={(event) => event.stopPropagation()}
                                            />
                                        </div>
                                    </AccordionSummary>
                                    <AccordionDetails style={{ background: "#8080801c", padding: "0px 0px 8px 0px", borderLeft: createObj.approvalStatus === "Pending" ? '4px solid #FDD141' : createObj.approvalStatus === "Approved" ? '4px solid #36C17C' : '4px solid #F47A7A', // NOSONAR
                                       minHeight: "30px"}}> 
                                        <div className="col-xs-12 col-sm-12 col-lg-12" style={{ padding: "0px 16px 0px 16px" }}>
                                            <span className="normal-visivbility-level-obj-kpi">Visibility :</span><span className="kpi-footer-details" > {createObj.objectiveVisibility} </span>
                                            {
                                            createObj.levelName !== "" && createObj.levelName !== null &&
                                            <>
                                            <span className="normal-visivbility-level-obj-kpi">Level :</span>
                                            <span className="kpi-footer-details" > { `${createObj.levelName.split(',')[0]}`}{createObj.levelName.split(',').length > 1 ? ` + ${createObj.levelName.split(',').length - 1} more`:""}</span>
                                            </>
                                            }
                                        </div>
                                    </AccordionDetails>
                                    {
                                        createObj.kpis.map((CreateKpiData, kpiInd) => (
                                            <Accordion defaultExpanded={false} key={`kpiAcc${CreateKpiData.kpiId}`} className="kpi-border-css" style={{ margin: "0px", boxShadow: "none",position:"inherit"}}>
                                                <AccordionSummary style={{ minHeight: "30px"}}
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-label="Expand"
                                                    aria-controls="additional-actions1-content"
                                                    id="additional-actions1-header"
                                                >
                                                    <div className="col-xs-12 col-sm-8 col-lg-7 col-md-7 bold-header-obj-kpi" style={{ padding: "14px 14px 14px 3px" }}>
                                                        {CreateKpiData.kpiName}
                                                    </div>
                                                    <div className="col-xs-12 col-sm-3 col-lg-4 col-md-4 tags-obj-kpi" style={{ padding: "14px 14px 14px 0px" }}> {CreateKpiData.kpiTags} </div>
                                                    <div className="col-xs-12 col-sm-1 col-lg-1 col-md-1">
                                                        <FormControlLabel style={{ margin: "0px", float: "right" }}
                                                            // aria-label="Acknowledge"
                                                            control={
                                                                <div>
                                                                    <IconButton
                                                                        id={`${CreateKpiData.kpiId}`}
                                                                        onClick={(e) => this.handleClickKPI(e, CreateKpiData)}
                                                                    >
                                                                        <MoreVertIcon />
                                                                    </IconButton>
                                                                    <Menu
                                                                        id={`kpi${CreateKpiData.kpiId}`}
                                                                        keepMounted
                                                                        anchorEl={anchorElKPI}
                                                                        open={Boolean(openMenuKPI)}
                                                                        onClose={this.handleCloseKPI}
                                                                        style={{ margin: "50px 0px 0px -80px" }}
                                                                    >
                                                                        <MenuItem key={`kpiEditMenuItem${CreateKpiData.kpiId}`} onClick={(e) => this.openAddKPI(CreateKpiData, "Edit",CreateKpiData)}>
                                                                            <span style={{ padding: "8px 8px 8px 0px" }}>
                                                                                <img
                                                                                    alt=""
                                                                                    src={editIcon}

                                                                                />
                                                                            </span> <span style={{ padding: "0px 32px 0px 0px" }}>Edit</span></MenuItem>
                                                                        <MenuItem key={`kpiDeleteMenuItem${CreateKpiData.kpiId}`} onClick={(e) => this.deleteObjectivePopup("DeleteKPI")}>
                                                                            <span style={{ padding: "8px 8px 8px 0px" }}>
                                                                                <img
                                                                                    alt=""
                                                                                    src={deleteIcon}

                                                                                /></span> <span> Delete </span></MenuItem>
                                                                    </Menu>
                                                                </div>
                                                            }
                                                            onClick={(event) => event.stopPropagation()}
                                                            onFocus={(event) => event.stopPropagation()}
                                                        />
                                                    </div>
                                                </AccordionSummary>
                                                <AccordionDetails style={{ background: "rgb(72 184 231 / 5%)", padding: "8px 18px 8px 18px" }}>
                                                    <div className="row">
                                                        <div className="col-xs-12 col-sm-12 col-lg-12" style={{ color: "#808080a8", paddingBottom: "8px" }}> Description </div>
                                                        <div className="col-xs-12 col-sm-12 col-lg-12">
                                                            {ReactHtmlParser(CreateKpiData.description)}
                                                        </div>
                                                        {
                                                            CreateKpiData.kpiAttachment !== "" && CreateKpiData.kpiAttachment !== null &&
                                                            <div className="col-xs-12 col-sm-12 col-lg-12" style={{ padding: "8px 14px 0px 14px" }}>
                                                                {this.createDraftimageFilepath(CreateKpiData.kpiAttachment)}
                                                            </div>
                                                        }
                                                    </div>
                                                </AccordionDetails>
                                            </Accordion>
                                        ))
                                    }
                                    {
                                        createObj.kpis !== undefined && createObj.kpis.length < addMaOkrKPILimit &&
                                        // <Button style={{ color: "red" }} onClick={(e) => this.openAddKPI(createObj.objectiveId, "Add",createObj)}>+ Add KPI</Button>
                                    <div className="add-kpi-alighment" onClick={(e) => this.openAddKPI(createObj.objectiveId, "Add",createObj)}><span className='add-kpi-alighment-hover'>+ Add Key Result</span></div>
                                    }
                                </Accordion>
                            </div>
                        ))
                    }
                        </>
                    </div>
                    </DialogContent>
                    <div>
                        <Dialog
                            open={addKPI}
                            fullWidth
                            width="500"
                            maxWidth="sm"
                        >
                            <DialogContent>
                                <div>
                                    <div>
                                        <span className="add-new-self-header" style={{ paddingLeft: "8px" }}>{kpiId > 0 ? "Edit Key Result" : "Add Key Result"} </span>
                                        <CreateKPI closeAddKPI={() => this.closeAddKPI} />
                                    </div>
                                </div>
                            </DialogContent>
                        </Dialog>
                    </div>
                    <div>
                        <Dialog
                            open={createObjective}
                            fullWidth
                            width="500"
                            maxWidth="sm"
                        >
                            <DialogContent>
                                <div>
                                    <div>
                                        <span className="add-new-self-header" style={{ paddingLeft: "8px" }}>{objId > 0 ? "Edit Objective" : "Create New Objective"} </span>
                                        <CreateObjective closeCreateObj={() => this.closeAddNewObjective} okrMastersVisibility={okrMastersVisibility} levelMaster={levelMaster} />
                                    </div>
                                </div>
                            </DialogContent>
                        </Dialog>
                    </div>
                    <ConfirmationPopup
                        showButton={{ type: "1" }}
                        open={deleteObjKPI}
                        button1function={() => this.handleStatusYes()}
                        button2function={() => this.handleStatusNo()}
                        headerMessage={this.objKpiDeletetitleMessage}
                        button1Text={"Yes"}
                button2Text={"No"}
                handleConfirmClose={() => this.handleStatusNo()}
                    /> 
                </div>
            </>
        );
    }
}
DraftAllObjKPI.propTypes = {
    authUser: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    employeeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    kpiId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    objId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    objectiveData: PropTypes.arrayOf(PropTypes.object),
    allOBJKPISubmit: PropTypes.arrayOf(PropTypes.object),    
    closAlleDialog: PropTypes.string,
    objKpiAddUpdateType: PropTypes.string,
};
DraftAllObjKPI.defaultProps = {
    authUser: "",
    employeeId: "",
    objectiveData: [],
    kpiId: 0,
    objId: 0,
    allOBJKPISubmit: [],
    closAlleDialog:'',
    objKpiAddUpdateType:''
};
const mapStateToProps = ({ auth, okrreducer }) => {
    const { authUser } = auth;
    const { objectiveData, kpiId, objId, allOBJKPISubmit,closAlleDialog,objKpiAddUpdateType, employeeId
    } = okrreducer;
    return {
        authUser,
        objectiveData, kpiId, objId, allOBJKPISubmit,closAlleDialog,objKpiAddUpdateType, employeeId
    }
}
export default connect(mapStateToProps, { addUpdateObjectveKpi,submitApprovalAction })(DraftAllObjKPI);