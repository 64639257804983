import React from "react";
import { withStyles } from "@material-ui/core/styles";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    // eslint-disable-next-line
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});
const ViewMeetingDialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle
      style={{
        backgroundColor: other.primaryColor,
        borderLeft: `7px solid${other.secondaryColor}`,
      }}
      disableTypography
      className={classes.root}
    // // eslint-disable-next-line
    // {...other}
    >
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});
const DialogTitleTMLC = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle
      style={{
        background: "#000",
        color: "#FFF",
        // minHeight: "65px",
        height: "4em",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        paddingTop: "1em",
      }}
      disableTypography
      className={classes.root}
      // eslint-disable-next-line
      {...other}
    >
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});
const DialogTitleCOMMENT = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle
      style={{
        background: "#fff",
        color: "#000",
        minHeight: "65px",
        paddingRight: 23
      }}
      disableTypography
      className={classes.root}
      // eslint-disable-next-line
      {...other}
    >
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          {/* <span style={{marginLeft:-15}}> */}
          <CloseIcon />
          {/* // </span> */}
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});
const DialogTitleKPI = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle
      style={{
        background: "#000",
        color: "#FFF",
        height: "50px",
        padding: '13px 15px 15px 15px',
      }}
      disableTypography
      className={classes.root}
      // eslint-disable-next-line
      {...other}
    >
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});
const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const DialogTitleLnA = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle
      style={{
        background: "#4b4b55",
        color: "white",
        padding: "0 !important",
      }}
      disableTypography
      className={classes.root}
      // eslint-disable-next-line
      {...other}
    >
      <Typography
        variant="h6"
        style={{
          padding: "0 !important",
          
        }}  
      >{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

export {
  DialogTitle,
  DialogContent,
  DialogActions,
  ViewMeetingDialogTitle,
  DialogTitleTMLC,
  DialogTitleKPI,
  DialogTitleCOMMENT,
  DialogTitleLnA
};
