/**
 * Author: Vivek Khodade.
 * Last Modify:08-May-2020
 * Modified By:Vivek Khodade
 */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import MultiSelectionButtonComponent from "../multiselectionbuttoncomponent/multiselectionbuttoncomponent";
import OnbordingServices from "../../../services/entryexit/onbordingServices";
import ProgressBar from "../../progressbar/progressbar";
import ErrorTabMessage from "../../errormessage/errormessage";
import { dencrypt } from "../../../app/other/commonfunction";

class AssetList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      AssetListTabData: null,
      empId: "",
      height:
      (window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight) - 188,
    width:
      (window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth) - 71,
    };
    this.AssetListTabData = null;
    this.errorMessage = "Somthing went wrong! please connect softwere team";
  }
     /**
  * Calculate & Update state of new dimensions
  */
 updateDimensions() {
  let update_width = window.innerWidth - 100;
  let update_height = window.innerHeight - 375;
  this.setState({ width: update_width, height: update_height });
}
  componentDidMount = () => {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
    const { search } = window.location;
    const params = new URLSearchParams(search);
    const empId = dencrypt(params.get("empId"));
    this.setState({
      empId,
    });
    this.getAssetListData(empId);
  };

  /**
   * function name: getAssetListData
   * params
   * empId:candidate id
   * typeid:tab id
   * authUserid: logedin user id
   * Description: get the assets list.
   */
  getAssetListData = (empId) => {
    try {
      const tabId = 7;
      const { authUser } = this.props;
      const candidateBasicInfo = Promise.resolve(
        OnbordingServices.getInitiateOrRoleOrlDOrAssetListTabData(
          empId,
          tabId,
          authUser
        )
      );
      candidateBasicInfo.then((result) => {
        if (result !== null) {
          this.setState({
            AssetListTabData: result,
          });
        } else {
          this.AssetListTabData = "error";
        }
      });
    } catch (error) {
      this.AssetListTabData = "error";
    }
  };

  render() {
    const { AssetListTabData, empId } = this.state;
    return (
      // <div style={{   marginTop: '50px', backgroundColor: '#F4F3F5' }}>
      <div style={{height: this.state.height, overflowY: 'auto'  ,width: '100.6%', overflowX:'hidden'}}>
      {/* <div style={{ display: "block" }} className="tab-container"> */}
        {/* eslint-disable-next-line */}
        {AssetListTabData !== null && AssetListTabData !== "error" ? (
          <MultiSelectionButtonComponent
            tabData={AssetListTabData}
            tabid={7}
            employeeId={empId}
          />
        ) : AssetListTabData !== "error" && AssetListTabData === null ? ( // NOSONAR
          <ProgressBar />
        ) : (
          <ErrorTabMessage message={this.errorMessage} />
        )}
      </div>
    );
  }
}
AssetList.propTypes = {
  authUser: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
AssetList.defaultProps = {
  authUser: "",
};
const mapStateToProps = ({ auth }) => {
  const { authUser } = auth;
  return { authUser };
};

export default connect(mapStateToProps, {})(AssetList);
