/**
 * Author: Mukesh.
 * Created:11-Jan-2023
 * Last Modify:11-Jan-2023
 * Modified By:Mukesh
 */
import React, { Component } from "react";
import { connect } from "react-redux";
import { setOrhChartDetaila } from "../../../redux/actions/entryexit/orgchartaction";
import HeaderTab from "../../../components/orgchart/headertab";
import HeaderFilter from "../../../components/orgchart/headerfilter";
import ReportingView from "../../../components/orgchart/reportingview";
import TeamView from "../../../components/orgchart/teamview";
import StructureView from "../../../components/orgchart/structureview";
import ActionButton from "../../../components/orgchart/actionbutton";
import HightLight from "../../../components/orgchart/hightlight";
import Piloting from "../../../components/orgchart/piloting";
import Sanpshot from "../../../components/orgchart/sanpshot";
import { NotificationContainer } from "react-notifications";

class OrgChartContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showHeigLight: false,
      showPloting: false,
      showProfile: false,
      orgView: false,
      showReportView: false,
      showTeamView: false,
      showStructure: false,
      plotingObj: {},
      profileData: {},
      onSubmit: "",
      hideShow: false,
      filterObj: {},
      orgChartData: [],
      teamOrgChartData: [],
      strutChartData: [],
      showLoader: false,
      layout: 90,
      userAccess: [],
      showOrgChart: false,
    };
    this.onClick = this.onClick.bind(this);
    this.onPloting = this.onPloting.bind(this);
    this.onProfile = this.onProfile.bind(this);
    this.handleViewChange = this.handleViewChange.bind(this);
    this.handleShowChanges = this.handleShowChanges.bind(this);
    this.getPlotObject = this.getPlotObject.bind(this);
  }
  handleShow = (value) => {
    this.setState({ showHeigLight: value });
  };
  handleShowPlot = (value) => {
    this.setState({ showPloting: value });
  };
  handleShowProfile = (value, obj) => {
    this.setState({ showProfile: value, profileData: obj });
  };
  onClick() {
    this.setState({ showHeigLight: true });
  }
  onTeam() {
    this.setState({ showTeamView: true });
  }
  onPloting() {
    this.setState({ showPloting: true });
  }
  onProfile() {
    this.setState({ showProfile: true });
  }
  handleViewChange() {
    this.setState({ orgView: !this.state.orgView });
    this.props.setOrhChartDetaila({ showOrgScreen: !this.state.orgView });
  }
  handleShowChanges(e) {
    this.setState({ hideShow: !this.state.hideShow });
    this.props.setOrhChartDetaila({ showFullScreen: !this.state.hideShow });
  }
  handleShowTree = (type, val) => {
    switch (type) {
      case -1:
        this.setState({ showOrgChart: false });
        break;
      case 1:
        this.setState({ showProfile: false });
        this.setState({
          showReportView: val,
          showTeamView: false,
          showStructure: false,
          showOrgChart: true,
        });
        break;
      case 2:
        this.setState({ showProfile: false });
        this.setState({
          showTeamView: val,
          showReportView: false,
          showStructure: false,
          showOrgChart: true,
        });
        break;
      case 3:
        this.setState({ showProfile: false });
        this.setState({
          showStructure: val,
          showTeamView: false,
          showReportView: false,
          showOrgChart: true,
        });
        break;
      default:
        this.setState({ showProfile: false, showOrgChart: true });
        this.setState({ showReportView: val });
        break;
    }
  };
  handleShowLoader = (val) => {
    this.setState({ showProfile: false });
    this.setState({ showLoader: val });
  };
  getPlotObject = (obj) => {
    this.setState({ plotingObj: obj });
  };
  setFilterObject = (obj) => {
    this.setState({ filterObj: obj });
  };
  setOrgChartData = (data) => {
    this.setState({ orgChartData: data });
  };
  setTeamOrgChartData = (data) => {
    this.setState({ teamOrgChartData: data });
  };
  setStructOrgChartData = (data) => {
    this.setState({ strutChartData: data });
  };
  setlayoutHandler = (val) => {
    this.setState({ layout: val });
  };
  userAccesHandler = (val) => {
    this.setState({ userAccess: val });
  };

  render() {
    return (
      <div
        className="row"
        style={{
          padding: "5px 12px 5px 18px",
          background: "#e5e5e5",
          height: "100vh !important",
          marginRight: 0,
          marginLeft: -5,
        }}
      >
        <HeaderTab
          orgView={this.state.orgView}
          handleViewChange={this.handleViewChange}
          onHandlerView={this.handleShowTree}
          hideShow={this.state.hideShow}
          onSubmit={this.onSubmit}
          handleShowChanges={this.handleShowChanges}
          filterHandler={this.setFilterObject}
          OrgChartHandler={this.setOrgChartData}
          teamOrgChartHandler={this.setTeamOrgChartData}
          structOrgChartHandler={this.setStructOrgChartData}
          setLoaderVal={this.handleShowLoader}
          setUserAccessHandler={this.userAccesHandler}
          setlayout={this.setlayoutHandler}
        />
        {this.state.showOrgChart ? (
          <div>
            <HeaderFilter
              orgView={this.state.orgView}
              handleViewChange={this.handleViewChange}
              filterData={this.state.filterObj}
              hideShow={this.state.hideShow}
            />
            <div
              className={
                this.state.hideShow
                  ? "mainreport-hide"
                  : "mainreport-box" && this.state.orgView
                  ? "mainreport-hide-org"
                  : "mainreport-box-org"
              }
            >
              <div
                style={
                  this.state.showProfile
                    ? { cursor: "none" }
                    : { color: "blue" }
                }
                className={this.state.showProfile ? "updated" : "old-one"}
              >
                {this.state.showReportView ? (
                  <ReportingView
                    orgChartData={this.state.orgChartData}
                    onGetPlotObj={this.getPlotObject}
                    onSelectHigh={this.handleShow}
                    onSelectPlot={this.handleShowPlot}
                    onSelectProfile={this.handleShowProfile}
                    showLoader={this.state.showLoader}
                    layout={this.state.layout}
                    filterData={this.state.filterObj}
                  />
                ) : null}
                {this.state.showTeamView ? (
                  <TeamView
                    teamOrgChartData={this.state.teamOrgChartData}
                    onGetPlotObj={this.getPlotObject}
                    onSelectHigh={this.handleShow}
                    onSelectPlot={this.handleShowPlot}
                    onSelectProfile={this.handleShowProfile}
                    showLoader={this.state.showLoader}
                    layout={this.state.layout}
                    filterData={this.state.filterObj}
                  />
                ) : null}
                {this.state.showStructure ? (
                  <StructureView
                    strucTOrgChartData={this.state.strutChartData}
                    onGetPlotObj={this.getPlotObject}
                    onSelectHigh={this.handleShow}
                    onSelectPlot={this.handleShowPlot}
                    onSelectProfile={this.handleShowProfile}
                    showLoader={this.state.showLoader}
                    layout={this.state.layout}
                    filterData={this.state.filterObj}
                  />
                ) : null}
                <ActionButton
                  showReportView={this.state.showReportView}
                  onSelectHigh={this.handleShow}
                  isStructureView={this.state.showStructure}
                  onSelectPlot={this.handleShowPlot}
                  onSelectProfile={this.handleShowProfile}
                  onSelecTeam={this.handleTeamShow}
                  plotingObj={this.state.plotingObj}
                  hideShow={this.state.hideShow}
                  handleShowChanges={this.handleShowChanges}
                  setlayout={this.setlayoutHandler}
                  orgView={this.state.orgView}
                  handleViewChange={this.handleViewChange}
                />
                {this.state.showHeigLight ? (
                  <HightLight
                    show={this.state.showHeigLight}
                    onSelectHigh={this.handleShow}
                    plotingObj={this.state.plotingObj}
                  />
                ) : null}
              </div>
              {this.state.showPloting ? (
                <Piloting
                  show={this.state.showPloting}
                  onSelectPlot={this.handleShowPlot}
                  plotingObj={this.state.plotingObj}
                />
              ) : null}
              {this.state.showProfile ? (
                <Sanpshot
                  show={this.state.showProfile}
                  onSelectProfile={this.handleShowProfile}
                  profileData={this.state.profileData}
                  plotingObj={this.state.plotingObj}
                  userAccess={this.state.userAccess}
                />
              ) : null}
            </div>
          </div>
        ) : null}
        <NotificationContainer/>
      </div>
    );
  }
}

const mapStateToProps = ({ auth, orgchartreducer }) => {
  const { authUser } = auth;
  const { orgchartupdateStatus } = orgchartreducer;
  return { authUser, orgchartupdateStatus };
};

export default connect(mapStateToProps, { setOrhChartDetaila })(
  OrgChartContainer
);
