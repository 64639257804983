import React from 'react';
import OKRDashboardHeaderTabs from "./okrdashboardheadertabs";
import ComplianceReportGrid from "./compliancereportgrid";
import SendEmailFooter from "./sendemailfooter";

function ComplianceReport() {
  return (
    <div className="okr-dashboard-compliance-container">
      <div className='pt-md pr-md pb-md pl-md'>
        <OKRDashboardHeaderTabs />
      </div>

      <div className="mr-md mb-md ml-md">
        <div className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 compliance-components">
          <div className="mb-md">
            <div className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 compliance-report-title">Compliance Report</div>
            <div className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <ComplianceReportGrid />
            </div>
            <div className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 compliance-footer">
              <SendEmailFooter />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ComplianceReport;
