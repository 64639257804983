import React, { useState} from "react";
import { Dialog, DialogTitle } from "@material-ui/core";
import ReactHtmlParser from 'react-html-parser';
import { DialogContent } from "../customdialog/customdialog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { createImageFilepath } from './ratingcommonfun'

const ViewInterimReviewModal = (props) => {

  const open = useState(true)
  const { viewInterimReviewData } = props;

  return (
    <div>
      <Dialog open={open} fullWidth={false} onEscapeKeyDown={()=>props.closeViewInterimReviewPopup()} >
        <DialogTitle>
          <span className="add-new-self-header" style={{
            color: "black",
            paddingBottom: 0,
            marginLeft: -4,
            fontFamily: "Circular Std ",
            fontSize: 18
          }} >
            {"Interim Review"}
          </span>
          <span style={{ float: 'right', cursor: 'pointer', marginRight: -12 }} onClick={() => props.closeViewInterimReviewPopup()}><FontAwesomeIcon style={{ color: '#B7B7BB', fontSize: 20 }} icon={faTimes} /></span>
        </DialogTitle>

        <DialogContent style={{ paddingTop: 0, paddingBottom: 32 }}>
          <div className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <form id="InterimReviewForm">
              <div className="form-row">
                <div
                  className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12"
                  style={{ paddingBottom: 24, paddingLeft: 10, paddingRight: 10 }}
                >
                  <div className="view-kr-modalrating-header-text"
                  >
                    Key Result
                  </div>
                  <div className="view-kr-modalrating-text"
                  >
                    {viewInterimReviewData.kpi}
                  </div>
                </div>
              </div>

              <div className="form-row">
                <div
                  className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12"
                  style={{ paddingBottom: 0, paddingLeft: 10, paddingRight: 10 }}
                >
                  <div
                    className="view-kr-modalrating-header-text"
                  >
                    Description
                  </div>
                  <div className="view-kr-modalrating-text"
                  >
                    {ReactHtmlParser(viewInterimReviewData.kpiDescription)}
                  </div>
                </div>
                <div
                  id="descripttErr"
                  className="e-input-group e-float-icon-left error-div"
                />
              </div>

              <div className="form-row">
               {viewInterimReviewData.ratingFiles?  <div
                  style={{  paddingLeft: 10, paddingRight: 10 }}
                  className="col-6 col-lg-6 col-md-6 col-sm-6 pt-xs"
                >
                  <div className="view-kr-modalrating-header-text"style={{ paddingBottom: 8 }} >
                  
                    Document
                  </div>
                  <div className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pt-sm ">
                    {createImageFilepath(viewInterimReviewData.ratingFiles.split(','), 'okr/document/interim')}
                  </div>
                </div>:''}
               {viewInterimReviewData.interimReviewLink ?   <div
                  style={{ paddingLeft: 16, paddingTop: 4, paddingBottom: 16 }}
                  className="col-6 col-lg-6 col-md-6 col-sm-6 pt-xs"
                >
                  <div
                    className="view-kr-modalrating-header-text"
                    style={{ paddingBottom: 5 }}
                  >
                    Link
                  </div>

                  <div className='interim-link-view-text' >
                    <FontAwesomeIcon
                      style={{
                        // color: "#F94F5E",
                        // position: "absolute",
                        margin: "10px 0px 0px 3px",
                      }}
                      icon={faLink}
                    />
                    &nbsp;&nbsp;&nbsp;&nbsp;{viewInterimReviewData.interimReviewLink}
                  </div>
                </div>:''}
              </div>
              <div className="form-row">
                <div
                  className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12"
                  style={{ paddingTop: 16, paddingLeft: 10, paddingRight: 10 }}
                >
                  <div
                    className="view-kr-modalrating-header-text"
                  >
                    Remark
                  </div>
                  <div className="view-kr-modalrating-text">
                    {viewInterimReviewData.interimRemarks}
                  </div>
                </div>
              </div>

            </form>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );

}
export default ViewInterimReviewModal
