import React, { Component } from "react";
import { connect } from 'react-redux';
import { Dialog, DialogTitle } from "@material-ui/core";
import { DialogContent } from "../customdialog/customdialog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import dateFnsUtils from '@date-io/date-fns';
import OKRRatingServices from '../../services/okrrating/okrratingservices';
import { createImageFilepathForLPView } from './ratingcommonfun';
import "../../app/layout/okrratinglayout/okrrating.css";
import { faAngleUp, faAngleDown,faTimes } from "@fortawesome/pro-light-svg-icons";
import Tooltips from "../custommaterialtooltip/custommaterialtooltip";
import { YouMayViewSupportingDocumentForTheRating } from '../../assets/constants/constants'
import { getOKRQuestionRatingData } from '../../app/other/commonfunction'
import ProgressBar from "../progressbar/progressbar";
import ReactHtmlParser from 'react-html-parser'

class ViewRatingModalkrModal extends Component {
  constructor() {
    super()
    this.state = {
      remarks: '',
      ratingFiles: '',
      open: true,
      selfRating: '',
      supRating: '',
      selfRemark: '',
      supRemark: '',
      OpenLeadershipInfo: false,
      setSelfRatingQuestions: [],
      isLoading: true
    }
  }


  componentDidMount = () => {
    this.getKRRemarkDetails();
  };



  getKRRemarkDetails = () => {
    const { type, authUser, empId, KRReviewData } = this.props;
    // console.log("🚀 ~ file: viewratingmodalkr.jsx ~ line 28 ~ ViewRatingModalkrModal ~ KRReviewData", KRReviewData)
    let ratingType = '';
    if (type === 'Self') {
      ratingType = 'self';
    }
    if (type === 'Supervisor') {
      ratingType = 'sup';
    }
    if (type === 'Final') {
      ratingType = 'final';
    }
    const ratingData = OKRRatingServices.getRatingInfo(authUser, empId, KRReviewData.ratingId, ratingType)
    ratingData.then((resultData) => {
      if (typeof resultData !== 'undefined' && resultData !== null) {
        this.setState({
          remarks: resultData[0].remarks,
          ratingFiles: resultData[0].ratingFiles,
          selfRating: resultData[0].selfRating,
          supRating: resultData[0].supRating,
          selfRemark: resultData[0].selfRemark,
          supRemark: resultData[0].supRemark,
          setSelfRatingQuestions: resultData[0].leadershipQuestions,
          isLoading: false
        })
      }
    });
  }


  handleLeadershipInfo = (e) => {
    this.setState(prevState => ({ OpenLeadershipInfo: !prevState.OpenLeadershipInfo }))
  }




  displayTime = (time) => {
    const dateFns = new dateFnsUtils();
    return dateFns.format(new Date(time), "dd-MMM-yyyy HH:mm");
  }



  render() {
    const { KRReviewData, type, OKRType } = this.props;
    const { remarks, isLoading, selfRating, selfRemark, supRating, supRemark, OpenLeadershipInfo } = this.state;
    return (
      <div>
        <Dialog open={true} fullWidth maxWidth={'lg'} onEscapeKeyDown={() => this.props.closeKrReviewPopup()}>
          <DialogTitle>
            <span className="add-new-self-header col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pt-sm " style={{
              color: "black",
              marginLeft: -4,
              fontFamily: "Circular Std ",
              fontSize: 18
            }} >
              {type} Rating
            </span>
            <span style={{ float: 'right', cursor: 'pointer', marginRight: -12, position: 'absolute', paddingTop: 12 }}>
              <FontAwesomeIcon style={{ color: '#B7B7BB', fontSize: 20 }} icon={faTimes}
                onClick={() => this.props.closeKrReviewPopup()} />
            </span>
          </DialogTitle>
          <DialogContent style={{ paddingTop: 0, paddingBottom: 24, marginBottom: 32, marginRight: 16, overflowY: 'auto' }}>
            {OKRType !== '3' &&
              <div className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <form id="InterimReviewForm">
                  <div className="form-row">
                    <div
                      className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12"
                      style={{ paddingBottom: 24, paddingLeft: 10, paddingRight: 10 }}
                    >
                      <div className="view-kr-modalrating-header-text"
                      >
                        Key Result
                      </div>
                      <div className="view-kr-modalrating-text">

                        {KRReviewData.krName}
                      </div>
                    </div>
                  </div>
                  {type === 'Final' &&
                    <>
                      <div className="form-row">
                        <div
                          className="col-2 col-lg-2 col-md-2 col-sm-2 col-xs-2"
                          style={{ padding: '0px 16px 16px 10px' }}>
                          <div className="view-kr-modalrating-header-text">
                            Self Rating
                          </div>
                          <div className="view-kr-modalrating-text">{selfRating}</div>
                        </div>
                        <div
                          className="col-10 col-lg-10 col-md-10 col-sm-10 col-xs-10"
                          style={{ padding: '0px 16px 16px 10px' }}>
                          <div className="view-kr-modalrating-header-text">
                            Self Remark
                          </div>
                          <div className="view-kr-modalrating-text-headeing">{selfRemark}</div>
                        </div>
                      </div>
                      <div className="form-row">
                        <div
                          className="col-2 col-lg-2 col-md-2 col-sm-2 col-xs-2"
                          style={{ padding: '0px 16px 16px 10px' }}>
                          <div className="view-kr-modalrating-header-text">
                            Supervisor Rating
                          </div>
                          <div className="view-kr-modalrating-text" >{supRating}</div>
                        </div>
                        <div
                          className="col-10 col-lg-10 col-md-10 col-sm-10 col-xs-10"
                          style={{ padding: '0px 16px 16px 10px' }}>
                          <div className="view-kr-modalrating-header-text">
                            Supervisor Remark
                          </div>
                          <div className="view-kr-modalrating-text-headeing" style={{ Height: 70 }}>
                            {
                              supRemark === "" ? "No Remark" : supRemark //NOSONAR
                            }
                          </div>
                        </div>
                      </div>
                    </>}
                  {type === 'Final' ? <>
                    <div className="form-row">
                      <div className="col-2 col-lg-2 col-md-2 col-sm-2 col-xs-2"
                        style={{ paddingBottom: 10, paddingLeft: 10, paddingRight: 10 }}
                      >
                        <div className="view-kr-modalrating-header-text">
                          Final Rating
                        </div>
                        <div className="view-kr-modalrating-text">
                          {type === 'Self' && KRReviewData.krSelfRecommendationRating.Rating}
                          {type === 'Supervisor' && KRReviewData.krSupervisorRecommendationRating.Rating}
                          {type === 'Final' && KRReviewData.krFinalRating.Rating}
                        </div>
                      </div>
                      <div
                        className="col-10 col-lg-10 col-md-10 col-sm-10 col-xs-10"
                        style={{ padding: '0px 16px 16px 10px' }}>
                        <div className="view-kr-modalrating-header-text">
                          Final Remark
                        </div>
                        <div className="view-kr-modalrating-text-headeing">
                          {
                            remarks === "" ? "No Remark" : remarks //NOSONAR
                          }
                        </div>
                      </div>
                    </div>
                    {/* <div className="form-row">
                  <div className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ paddingTop: 16, paddingLeft: 10, paddingRight: 10 }}>
                    <div className="view-kr-modalrating-header-text"
                      style={{ paddingLeft: 0 }}>
                      Final Remark

                    </div>
                    <div className="view-kr-modalrating-text">
                      {remarks==="" ? "No Remark" : remarks}
                    </div>
                  </div>
                </div> */}
                  </> :
                    <>
                      <div className="form-row">
                        <div className="col-2 col-lg-2 col-md-4 col-sm-4 col-xs-2"
                          style={{ paddingBottom: 10, paddingLeft: 10, paddingRight: 10 }}
                        >
                          <div className="view-kr-modalrating-header-text">
                            Rating
                          </div>
                          <div className="view-kr-modalrating-text">
                            {type === 'Self' && KRReviewData.krSelfRecommendationRating.Rating}
                            {type === 'Supervisor' && KRReviewData.krSupervisorRecommendationRating.Rating}
                            {type === 'Final' && KRReviewData.krFinalRating.Rating}
                          </div>
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ paddingTop: 16, paddingLeft: 10, paddingRight: 10 }}>
                          <div className="view-kr-modalrating-header-text"
                            style={{ paddingLeft: 0 }}>
                            Remark

                          </div>
                          <div className="view-kr-modalrating-text">
                            {
                              remarks === "" ? "No Remark" : remarks //NOSONAR
                            }
                          </div>
                        </div>
                      </div>
                    </>}
                  <div className="form-row">
                    {this.state.ratingFiles.split(',').map((questionArr, id) => (


                      <div style={{
                        alignItems: "center",
                        fontWeight: 400,
                      }}>
                        <div className="col-md-4 col-md-2" style={{ paddingLeft: 16 }} >
                          <span>
                            <div style={{ display: 'flex' }} >{createImageFilepathForLPView([].concat(questionArr), 'okr/document/rating', (new Date((questionArr.split('_')[0]) * 1000)))}</div>
                          </span>
                        </div>
                      </div>
                    ))}
                  </div>
                </form>
              </div>
            }
            {OKRType === '3' && !isLoading &&
              <div className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <form id="InterimReviewForm">
                  <div className="form-row">
                    <div
                      className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12"
                      style={{ paddingBottom: 24, paddingLeft: 10, paddingRight: 10 }}
                    >
                      <div className="view-kr-modalrating-header-text"
                      >
                        Leadership Principle
                      </div>
                      <div className="view-kr-modalrating-text">
                        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                          <div style={{ fontSize: 15, fontWeight: 600, color: "#000" }}>
                            {KRReviewData.krName}
                          </div>
                          <div style={{ marginRight: ".4rem" }}>
                            <FontAwesomeIcon
                              style={{ color: '#939399', fontSize: 20, cursor: "pointer" }}
                              icon={OpenLeadershipInfo ? faAngleUp : faAngleDown}
                              onClick={this.handleLeadershipInfo}
                            />
                          </div>
                        </div>
                        {!OpenLeadershipInfo && <div style={{ paddingTop: ".3rem", fontSize: 13 }}>
                          {ReactHtmlParser(KRReviewData.krDetails)}
                        </div>}
                      </div>

                    </div>

                  </div>


                  {type === 'Final' &&
                    <>
                      <div className="form-row">
                        <div
                          className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12"
                          style={{ padding: '8px 16px 16px 10px' }} >
                          <span className="view-kr-modalrating-header-text" >
                            Self Rating
                          </span>
                          <span className="view-kr-modalrating-text" style={{ marginLeft: "25px", padding: "25px", paddingTop: "10px", paddingBottom: "10px" }}>
                            {selfRating}</span>

                          <span className="view-kr-modalrating-header-text" style={{ marginLeft: "25px" }}>
                            Supervisor Rating
                          </span>
                          <span className="view-kr-modalrating-text" style={{ marginLeft: "25px", padding: "25px", paddingTop: "10px", paddingBottom: "10px" }}>
                            {supRating}</span>

                          <span className="view-kr-modalrating-header-text" style={{ marginLeft: "25px" }} >
                            Final Rating
                          </span>
                          <span className="view-kr-modalrating-text" style={{ marginLeft: "25px", padding: "25px", paddingTop: "10px", paddingBottom: "10px" }}>
                            {KRReviewData.krFinalRating.Rating}</span>
                        </div>
                        <div className="view-kr-modalrating-header-text" style={{ height: 20, fontSize: 15, paddingTop: 17, marginLeft: 10, color: "black" }}>
                          The below observations are for {KRReviewData.frequencyNm}
                        </div>
                        {this.state.setSelfRatingQuestions.length > 0 &&
                          <div className="form-row">
                            <div
                              className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pl-sm pr-sm pb-lg" style={{ display: "flex", flexDirection: "column", marginTop: 20 }}
                            // style={{ padding: "0px 18px 0px 10px" }}style={{ display: "flex",flexDirection:"column"}}
                            >
                              <div style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                backgroundColor: "#F4F4F7",
                                color: "#8A8A8A",
                                fontWeight: 500,
                                borderRadius: "4px 4px 0 0",
                                border: "1px solid #D1D1D1",
                              }}>
                                <div className="self-rating-questions-title" style={{ padding: "0.5rem", justifyContent: "flex-start", width: "100" }} >
                                  Questionnaires
                                </div>

                                <div className="self-rating-questions-title" style={{ padding: "0rem 2.2rem 0.2rem 0", margin: "0", justifyContent: "space-around", width: "100" }}>
                                  <div style={{ marginLeft: -14 }}>Self Rating</div>
                                  <div >Supervisor</div>
                                  <div >Final Rating</div>
                                </div>
                              </div>
                              <div style={{ fontSize: '13px' }}>
                                {this.state.setSelfRatingQuestions.length > 0 && this.state.setSelfRatingQuestions.map((questionArr, id) => (
                                  <div style={{
                                    display: "flex",
                                    flexDirection: "column !important",
                                    justifyContent: "space-between",
                                    alignItems: "center",
                                    backgroundColor: "#FFFFFF",
                                    color: "#000000",
                                    fontWeight: 500,
                                    borderRadius: "0 0 4px 4px",
                                    border: "1px solid #D1D1D1",
                                    borderTop: "none"
                                  }}>
                                    <div style={{ padding: "1rem 0 0.6rem .7rem", flex: "3.8 0 0%", width: "100" }}>
                                      {questionArr.question}
                                    </div>
                                    <div className="questions-radio-button-container pr-sm" style={{ width: "100", justifyContent: "start", padding: "1rem 0 0.6rem 1.7rem" }}>
                                      {getOKRQuestionRatingData(questionArr.selfRating)}
                                    </div>
                                    <div className="questions-radio-button-container pr-sm" style={{ width: "100", justifyContent: "start", padding: "1rem 0 0.6rem 1.7rem" }}>
                                      {getOKRQuestionRatingData(questionArr.supervisorRating)}
                                    </div>
                                    <div className="questions-radio-button-container pr-sm" style={{ width: "100", justifyContent: "start", padding: "1rem 0 0.6rem 1.7rem" }}>
                                      {getOKRQuestionRatingData(questionArr.finalRating)}
                                    </div>
                                  </div>
                                ))}</div>

                            </div>
                          </div>

                        }
                        <div className="form-row">

                          {/* <div
                                className="col-2 col-lg-2 col-md-2 col-sm-2 col-xs-2"
                                style={{ padding: '0px 16px 16px 10px' }}>
                                <div className="view-kr-modalrating-header-text">
                                  Supervisor Rating
                                </div>
                                <div className="view-kr-modalrating-text" >{supRating}</div>
                                </div> */}



                          <div
                            className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12"
                            style={{ padding: '6px 16px 16px 10px' }}>

                            <div className="view-kr-modalrating-header-text">
                              Self Remark
                            </div>
                            <div className="view-kr-modalrating-text-headeing" style={{ padding: '16px 11px 6px 10px' }}>
                              {
                                selfRemark === "" ? "No Remark" : selfRemark //NOSONAR
                              }</div>

                            <div className="view-kr-modalrating-header-text" style={{ padding: '25px 11px 6px 0px' }}>
                              Supervisor Remark
                            </div>
                            <div className="view-kr-modalrating-text-headeing">
                              {
                                supRemark === "" ? "No Remark" : supRemark //NOSONAR
                              }
                            </div>
                          </div>
                        </div>

                      </div>
                    </>}



                  {type === 'Final' ? <>
                    <div className="form-row">
                      <div className="col-2 col-lg-2 col-md-2 col-sm-2 col-xs-2"
                        style={{ paddingBottom: 10, paddingLeft: 10, paddingRight: 10 }}
                      >
                        {/* <div className="view-kr-modalrating-header-text">
                        Final Rating
                      </div> */}
                        <div >
                          {type === 'Self' && KRReviewData.krSelfRecommendationRating.Rating}
                          {type === 'Supervisor' && KRReviewData.krSupervisorRecommendationRating.Rating}
                        </div>
                      </div>
                      <div className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ padding: '0px 16px 16px 10px' }}>
                        <div className="view-kr-modalrating-header-text" style={{ padding: '0px 11px 6px 0px' }}>
                          Final Remark
                        </div>
                        <div className="view-kr-modalrating-text-headeing">
                          {
                            remarks === "" ? "No Remark" : remarks //NOSONAR
                          }
                        </div>
                      </div>
                    </div>
                  </> :
                    <>
                      <div >
                        <div className={type === 'self' ? "col-2 col-lg-2 col-md-4 col-sm-4 col-xs-2" : "col-4 col-lg-4 col-md-4 col-sm-4 col-xs-4"}
                          style={{ paddingBottom: 10, paddingLeft: 10, paddingRight: 10 }}
                        >
                          <span className="view-kr-modalrating-header-text" >
                            {`${type} Rating`}

                            <span className="view-kr-modalrating-text" style={{ marginLeft: 15, padding: 12, paddingLeft: 25, paddingRight: 25 }}>
                              {type === 'Self' && KRReviewData.krSelfRecommendationRating.Rating}
                              {type === 'Supervisor' && KRReviewData.krSupervisorRecommendationRating.Rating}
                              {type === 'Final' && KRReviewData.krFinalRating.Rating}
                            </span>

                          </span>

                        </div>
                        <div className="view-kr-modalrating-header-text col-12" style={{ height: 20, fontSize: 15, paddingTop: 17, marginLeft: 10, color: "black" }}>
                          The below observations are for {KRReviewData.frequencyNm}
                        </div>
                      </div>

                      {this.state.setSelfRatingQuestions.length > 0 &&
                        <div className="form-row">
                          <div
                            className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pl-sm pr-sm pb-lg" style={{ display: "flex", flexDirection: "column", marginTop: 20 }}
                          // style={{ padding: "0px 18px 0px 10px" }}style={{ display: "flex",flexDirection:"column"}}
                          >
                            <div style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              backgroundColor: "#F4F4F7",
                              color: "#8A8A8A",
                              fontWeight: 500,
                              borderRadius: "4px 4px 0 0",
                              border: "1px solid #D1D1D1",
                            }}>
                              <div style={{ paddingLeft: ".7rem", flex: 2.6 }}>
                                Questionnaires
                              </div>

                              <div className="self-rating-questions-title" >
                                <div className="questions-rating" style={{ marginLeft: -5 }} >Answers</div>
                              </div>
                            </div>
                            <div style={{ fontSize: '13px' }} >
                              {this.state.setSelfRatingQuestions.length > 0 && this.state.setSelfRatingQuestions.map((questionArr, id) => (
                                <div style={{
                                  display: "flex",
                                  flexDirection: "column !important",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                  backgroundColor: "#FFFFFF",
                                  color: "#000000",
                                  fontWeight: 500,
                                  borderRadius: "0 0 4px 4px",
                                  border: "1px solid #D1D1D1",
                                  borderTop: "none"
                                }}>
                                  <div style={{ padding: "1rem 0 1rem .7rem", flex: 2.6 }}>
                                    {type === 'Self' ? questionArr.selfQuestion : questionArr.question}
                                  </div>
                                  <div className="questions-radio-button-container pr-sm" style={{ padding: "0.5rem 1rem 0.5rem 1.5rem" }}>
                                    {type === 'Self' ? getOKRQuestionRatingData(questionArr.selfRating) : getOKRQuestionRatingData(questionArr.supervisorRating)}
                                  </div>
                                </div>
                              ))}</div>

                          </div>
                        </div>
                      }
                      <div className="form-row">
                        <div className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ paddingTop: 16, paddingLeft: 10, paddingRight: 10 }}>
                          <div className="view-kr-modalrating-header-text"
                            style={{ paddingLeft: 0 }}>
                            {`${type} Remark`}

                          </div>
                          <div className="view-kr-modalrating-text">
                            {
                              remarks === "" ? "No Remark" : remarks //NOSONAR
                            }
                          </div>
                        </div>
                      </div>
                    </>}
                  {this.state.ratingFiles &&
                    <div className="form-row">
                      <div className="col-12 col-lg-12 col-md-12 col-sm-12">
                        <div className="view-kr-modalrating-header-text pl-sm">
                          Uploaded Document(s)
                          <span style={{ padding: "0px 0px 2px 8px", position: 'relative' }}>
                            <Tooltips titleMessage={YouMayViewSupportingDocumentForTheRating} />
                          </span>
                        </div>


                        {/* {this.state.ratingFiles.split(',')?.map((questionArr, id) => ( */}




                        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', width: '100%', paddingLeft: 16 }} >
                          {createImageFilepathForLPView(this.state.ratingFiles.split(','), 'okr/document/rating','tmlcApiUrl')}
                        </div>



                        {/* ))} */}

                      </div>
                    </div>
                  }
                </form>
              </div>
            }
            {OKRType === '3' && isLoading && <ProgressBar />}
          </DialogContent>
        </Dialog>
      </div >
    );
  }
}

const mapStateToProps = ({ okrratingreducer, auth }) => {
  const { authUser } = auth
  const { empId, fyId, productivityallocationData } = okrratingreducer
  return { authUser, empId, fyId, productivityallocationData }
};
export default connect(mapStateToProps, {})(
  ViewRatingModalkrModal
);


