import React, { Component } from 'react';
import { Button } from '@material-ui/core';
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import { setMeetingCategory } from '../../../redux/actions/meetingcategoryaction';
import { EXITCLEARANCEGRID } from '../../../redux/constant/meetingcategoryconstant';
import { submiteExitClearanceData, addUpdateSeparationDetails } from '../../../redux/actions/entryexit/separationaction';
import { getNotify,dencrypt,encodeString } from '../../../app/other/commonfunction';
import { ERROR, pleaseSelectCondition } from '../../../assets/constants/constants';
import ConfirmationPopup from '../../../utilities/confirmationpopup';
class ExitClearanceFooterButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      button1Caption: '',
      button2Caption: '',
      title: '',
      message: '',
      open: false,
      redirect: null
    };
    this.onSubmitExitClearance = this.onSubmitExitClearance.bind(this);
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.updateExitClearanceNavStatus === 'updated') {
      try {
        this.props.addUpdateSeparationDetails({updateExitClearanceNavStatus:''})
        const { open }=this.state;
        localStorage.setItem("meeting_category", encodeString(EXITCLEARANCEGRID));
        const path = "/separation";
        this.setState({
          redirect: path,
          open: !open,
          button1Caption: '',
          button2Caption: '',
          title: '',
          message: '',
        }, async() =>
        {
          // eslint-disable-next-line
            await this.props.setMeetingCategory(EXITCLEARANCEGRID);
          })
      } catch (error) {
        // eslint-disable-next-line
      }
    }

  }
  onSubmitExitClearance = async () => {
    const { open } = this.state;
    const { clearanceItem } = this.props;
    if (clearanceItem.length === 0) {
      getNotify(ERROR, pleaseSelectCondition);
      return;
    }

    let cnt = 0;
    for(let i in clearanceItem) {
      if(!isNaN(parseInt(i, 10))){
        let index = parseInt(i, 10);
        if (clearanceItem[index].assetCond === null) {
          cnt++;
        }
      }
    }
    if (cnt > 0) {
      getNotify(ERROR, pleaseSelectCondition);
    } else {
      this.setState({
        open: !open,
        button1Caption: 'Yes',
        button2Caption: 'No',
        title: 'Exit Clearance',
        message: 'Are you sure, you want to approve exit clearance for this Employee?',
      });
    }
  }
  handleSubmitYes = async () => {
    try {
      const { authUser, clearanceItem, recoveryAmt } = this.props;
      const { search } = window.location;
      const params = new URLSearchParams(search)
      let clsInfo = '';
      for (let i = 0; i < clearanceItem.length; i++) {
        clsInfo = clsInfo + clearanceItem[i].checkListUid + '_' + clearanceItem[i].assetCondId + '_' + clearanceItem[i].handOverRec + '_' + clearanceItem[i].owner;
        if (i < clearanceItem.length - 1) {
          clsInfo = clsInfo + '|';
        }
      }
    
      // eslint-disable-next-line
      await this.props.submiteExitClearanceData({
        "userId": authUser,
        "empId": dencrypt(params.get("empId")),
        "type": "amt",
        "clsInfo": ((typeof recoveryAmt === 'undefined' || recoveryAmt === null) ? "0" : recoveryAmt.toString())
      });

    // eslint-disable-next-line
      await this.props.submiteExitClearanceData({
        "userId": authUser,
        "empId": dencrypt(params.get("empId")),
        "type": "item",
        "clsInfo": clsInfo
      });
    } catch (error) {
      // eslint-disable-next-line
    }
  }
  handleConfirmNo = () => {
    const { open } = this.state;
    this.setState({
      open: !open,
      button1Caption: '',
      button2Caption: '',
      title: '',
      message: '',
    });
  }
  handleConfirmClose = () => {/**NOSONAR */
    const { open } = this.state;
    this.setState({
      open: !open,
      button1Caption: '',
      button2Caption: '',
      title: '',
      message: '',
    });
  }

  render() {
    const { redirect } = this.state;
    if (redirect) {
      return <Redirect to={redirect} />;
    }
    return (
      <div>
        <div className='col-12' style={{ height: 30, textAlign: 'center', borderTop: '1px solid gray', }}>
          <div className=''>
            <div className='col-1 footer-button'>

              <div style={{ height: '100%', width: '100%', color: '#fff' }}>
                .
                  </div>
            </div>
            <div className='col-8' style={{ color: '#fff' }}>
              .
            </div>
            <div className='col-3' style={{ textAlign: 'right' }}>
              <div className='footer-button'>
                <Button
                  className="e-event-details text-light ml-sm " variant="contained" title="SUBMIT" color='primary' size='small'
                  onClick={() => this.onSubmitExitClearance()}
                >
                  SUBMIT
                </Button>
                {/* <Button
                    style={{ background: "#FFF", color: '#000', border: '1px solid ', marginLeft:5 }}
                    className="e-event-create footer-button" variant="contained" title="Save" size='small'>
                    Cancel
                    </Button> */}

              </div>
            </div>
            <ConfirmationPopup
              showButton={{ type: "1" }}
              open={this.state.open}
              button1function={() => this.handleSubmitYes()}
              button2function={() => this.handleConfirmNo()}
              headerMessage={this.state.message}
              button1Text={this.state.button1Caption}
              button2Text={this.state.button2Caption}
              handleConfirmClose={() => this.handleConfirmNo()}
            />  
          </div>
        </div>
      </div>
    );
  }
}
ExitClearanceFooterButton.propTypes = {
  authUser: PropTypes.string,
};
ExitClearanceFooterButton.defaultProps = {
  authUser: "",
};
const mapStateToProps = ({ separationreducer, auth }) => {
  const { authUser } = auth;
  const { clearanceItem, recoveryAmt, clearanceType, updateExitClearanceStatus, updateExitClearanceNavStatus } = separationreducer;
  return { authUser, clearanceItem, recoveryAmt, clearanceType, updateExitClearanceStatus, updateExitClearanceNavStatus };
};

export default connect(mapStateToProps, { submiteExitClearanceData, setMeetingCategory, addUpdateSeparationDetails })(ExitClearanceFooterButton);
