import React, { Component } from "react";
import { connect } from 'react-redux';
import { urlConfig, serviceUrl } from "../../config/config";
import { DialogTitleTMLC, DialogContent, DialogActions } from "../customdialog/customdialog"
import ErrorBoundary from "../error/errorboundary";
import { Button, Dialog } from '@material-ui/core';
import { updateDocumentInfo, updateDocumentStatus } from "../../redux/actions/entryexit/onboardingaction";
import { NEW_CANDIDATE_UPLOAD, EMPLOYEE_UPLOAD } from "../../redux/constant/entryexit/candidateconstant";
import {
    PdfViewerComponent, Toolbar, Magnification, Navigation, LinkAnnotation, BookmarkView,
    ThumbnailView, Print, TextSelection, TextSearch, Annotation, FormFields, Inject
} from '@syncfusion/ej2-react-pdfviewer';
import ConfirmationPopup from "../../utilities/confirmationpopup";
import { viewFile } from "../../services/documentview";
import ProgressBar from "../progressbar/progressbar";


/**  const options = {
     cMapUrl: 'cmaps/',
     cMapPacked: true,
}; */
class Preview extends Component {
    constructor(props) {
        super(props);
        this.state = {
            confirmOpen: false,
            statusUpdate: this.props.showApprovalRejection,
            numPages: null,
            fileData: null,
            loader: true
        };

        this.handleClose = this.handleClose.bind(this)
        this.funcShowPopUp = this.funcShowPopUp.bind(this)

        //confirmation fields
        this.title = "";
        this.message = "";
        this.status = "";
        this.handleConfirmNo = this.handleConfirmNo.bind(this)
        this.handleConfirmYes = this.handleConfirmYes.bind(this)
    }
    
    renderActions() {
        return (
            <>
                {this.state.statusUpdate && this.props.candidateType !== NEW_CANDIDATE_UPLOAD && <Button onClick={(e) => this.funcShowPopUp("R")} color="default" variant="contained">
                    REJECT
                </Button>
                }
                {this.state.statusUpdate && this.props.candidateType !== NEW_CANDIDATE_UPLOAD && <Button onClick={(e) => this.funcShowPopUp("A")} color="primary" variant="contained">
                    APPROVE
                </Button>
                }
            </>
        );
    }

    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({ numPages });
    }


    // Update status as Approved / Rejected
    funcShowPopUp(pStatus) {
        this.status = pStatus;
        if (pStatus === "A") {
            this.title = "Approve";
            this.message = "Are you sure you want to approve the document?";
            this.setState({ confirmOpen: true })
        } else {
            this.title = "Reject";
            this.message = "Are you sure you want to reject the document?";
            var docIdArr = this.props.folderPath.split('/');
            var docRejectionParams = [];
            var params = {};
            params.pId = docIdArr[2];
            params.categoryName = '';
            params.selectedFolderId = this.props.files[0].DocId;
            params.categoryId = this.props.categoryId;
            params.showApprovalRejection = this.props.showApprovalRejection;
            params.candidateType = this.props.candidateType;
            params.showComments = false;
            params.commentFor = '2';
            params.pType = 'Rejected';
            docRejectionParams.push(params);
            this.props.updateDocumentInfo({ open_document_preview: false, openDocumentComments: true, docRejectionParams: docRejectionParams })

        }
    }

    handleClose = () => {
        this.props.updateDocumentInfo({ open_document_preview: false })
    }

    onDocumentLoadSuccess = ({ numPages }) => {
        this.setState({ numPages });
    }

    handleConfirmYes = () => {
        this.setState({ confirmOpen: false, statusUpdate: false });

        // Refresh the docs status based on Approved or rejected   
        this.props.updateDocumentInfo({
            open_document_preview: false,
            refreshDocStatus: "app_rej_status",
            doc_status: this.status,
        })
    };

    handleConfirmNo = () => {
        this.setState({ confirmOpen: false })
    }
    componentDidMount() {
        const { fileDms } = this.props;
        viewFile({ dmsId: fileDms })
            .then((fileData) => {
                this.setState({ fileData, loader: false });
            })
            .catch((error) => {
                console.log(error);
                this.setState({ loader: false });
            });

    }
    render() {
        
        let fileurl = '';
        fileurl = urlConfig.tmlcApiUrl + 'document/' +
            (this.props.candidateType === EMPLOYEE_UPLOAD ? 'ED' : 'D') + '/'
            + (this.props.candidateType === NEW_CANDIDATE_UPLOAD ? '0' : this.props.authUser) + '/' + this.props.filePath + '~' + this.props.files[0].DocName;
            
        return (
            <>
                <Dialog aria-labelledby="preview-title"
                    open={this.props.open_document_preview}
                    onClose={this.handleClose}
                    fullWidth
                    maxWidth={'lg'}
                // height='700'
                >
                    <DialogTitleTMLC id="preview-title" onClose={this.handleClose}>
                        Preview  {this.props.docName}
                    </DialogTitleTMLC>
                    <ErrorBoundary>
                        <DialogContent dividers>
                            <div className="Upload1" >
                                <div className="Content1">
                                    <div>
                                        {
                                            this.props.files[0].DocName.indexOf('.pdf') > -1 ?
                                                <div className='control-section'>
                                                    <div>
                                                    {this.state.loader && <ProgressBar/>}

                                                    </div>
                                                    <PdfViewerComponent documentPath={this.state.fileData} serviceUrl={serviceUrl}
                                                        enableAnnotation={false}
                                                        enableFormFields={false}
                                                        enablePrint={false}
                                                        enableDownload={true}
                                                        downloadFileName={this.props.files[0].DocName}
                                                        height='700px'
                                                    //  width='1000px'

                                                    >
                                                        <Inject services={[Toolbar, Magnification, Navigation, LinkAnnotation, BookmarkView, ThumbnailView, Print, TextSelection, TextSearch, Annotation, FormFields,]} />
                                                    </PdfViewerComponent>
                                                </div>
                                                :
                                                <center><img src={fileurl} alt="no img available"
                                                    style={{ width: "900px", height: "400px" }}
                                                /></center>

                                        }
                                    </div>
                                    <ConfirmationPopup
                                        showButton={{ type: "1" }}
                                        open={this.state.confirmOpen}
                                        button1function={() => this.handleConfirmYes()}
                                        button2function={() => this.handleConfirmNo()}
                                        headerMessage={this.message}
                                        button1Text={"Yes"}
                        button2Text={"No"}
                        handleConfirmClose={() => this.handleConfirmNo()}
                                    /> 


                                </div>
                            </div>
                        </DialogContent>
                        <DialogActions>
                            {this.renderActions()}
                        </DialogActions>
                    </ErrorBoundary>
                </Dialog>
            </>

        );
    }
}

const mapStateToProps = ({ onboardingreducer, auth }) => {
    const { authUser } = auth
    const { open_document_preview, candidateid } = onboardingreducer
    return { authUser, open_document_preview, candidateid }
};

export default connect(mapStateToProps, {
    updateDocumentInfo, updateDocumentStatus
})(Preview);

