import React, { Component } from "react";
import { connect } from "react-redux";
import {
  APPLYLEAVE,
  APPROVELEAVE,
  LEAVEHRREPORT,
  LEAVENOTIFIERLIST,
  LEAVEDETAILS,
  APPLYCOMPOFF,
  SPECIAL_ATTENDANCE,
  CANCELLEAVE,
} from "../../../redux/constant/meetingcategoryconstant";
import LeaveApply from "../../../components/lnacomponents/leaves/leavesapply/leaveapply";
import LeaveApproval from "../../../components/lnacomponents/leaves/leaveapproval/leaveapproval";
import LeavesHRReport from "../../../components/lnacomponents/leaves/leaveshrreport/leaveshrreport";
import LeaveNotifiersList from "../../../components/lnacomponents/leaves/leavesbalance/leavenotifierslist";
import LeaveDetails from "../../../components/lnacomponents/leaves/leavesapply/leavedetails";
import { updateLnAData } from "../../../redux/actions/lna/lnaaction";

class LeavesContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      height: null,
    };
  }
  componentDidMount = () => {
    window.addEventListener("resize", this.updateDimensions());
    this.props.updateLnAData({
      leaveType: "",
      leaveStartDate: null,
      leaveEndDate: null,
      leaveReason: "",
      appliedDays: "",
      leaveClusterId: "",
      approverId: 0,
      attachmentName: "",
      leaveApplicationId: 0,
      startDate: null,
      endDate: null,
    });
  };
  updateDimensions() {
    const updateheight = window.innerHeight - 65;
    this.setState({
      height: updateheight,
    });
  }
  render() {
    const { categoryName } = this.props;
    const { height } = this.state;

    return (
      <div
        className="attendance-container pl-md pr-md pb-md pt-0"
        style={{ height: height, backgroundColor: "#F3F3F6" }}
      >
        {/* {categoryName === APPLYLEAVE && */}
        {/* <> */}
        {/* <LeavesHeader/> */}
        {/* <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 ' style={{ height: height - 20, backgroundColor: '#F3F3F6' }}> */}
        {/* <div className='row1'> */}
        {/* <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 leaves-appliction-container' style={{ height: height - 95, backgroundColor: '#F3F3F6' }}> */}
        {/* <LeaveApply type={"applyLeave"} /> */}
        {/* </div> */}
        {/* </div> */}
        {/* </div> */}
        {/* </> */}
        {/* }/ */}
        {(categoryName.toString() === APPLYLEAVE ||
          categoryName.toString() === APPLYCOMPOFF ||
          categoryName.toString() === SPECIAL_ATTENDANCE ||
          categoryName.toString() === CANCELLEAVE
          ) && (
          <>
            <LeaveApply height={height} />
            {/* <Compoff type={"Compoff"}/> */}
            {/* <LeaveTransfer/> */}
            {/* <WeeklyReport/> */}
            {/* <div className='col-lg-4 col-md-4 col-sm-4 col-xs-4 leave-NotifiersList-body' style={{height:height - 95, backgroundColor:'#F3F3F6', paddingRight:0,}}> */}
            {/* <LeaveNotifiersList/> */}
            {/* <LeaveDetails /> */}
            {/* </div>  */}
          </>
        )}
        {categoryName === APPROVELEAVE && <LeaveApproval />}
        {categoryName === LEAVEHRREPORT && <LeavesHRReport />}
        {categoryName === LEAVENOTIFIERLIST && <LeaveNotifiersList />}
        {categoryName === LEAVEDETAILS && <LeaveDetails />}
      </div>
    );
  }
}
const mapStateToProps = ({ meetingcategory }) => {
  const { categoryName } = meetingcategory;
  return {
    categoryName,
  };
};

export default connect(mapStateToProps, { updateLnAData })(LeavesContainer);
