import React, { useEffect, useState } from "react";
import { FormValidator, NumericTextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { ComboBoxComponent } from "@syncfusion/ej2-react-dropdowns";
import { connect } from "react-redux";
import okrServices from "../../services/okr/okrservices";
import { saveAxisCutOff, updateOKRData } from "../../redux/actions/okr/okractions";
import { axisCutOffAxisFyIdNotPresent, axisCutOffAxisMaxValueNotPresent, axisCutOffAxisMaxValueValidation, axisCutOffAxisMinValueNotPresent, axisCutOffAxisMinValueValidation, axisCutOffAxisNameNotPresent, axisCutOffAxisPositionNotPresent, axisCutOffAxisRangeNotPresent } from "../../assets/constants/constants";
import ProgressBar from "../progressbar/progressbar";

const AXIS_NAME = [{ Name: 'X', Id: '1' }, { Name: 'Y', Id: '2' }]
const AXIS_POSITION = [{ Name: '1', Id: '1' }, { Name: '2', Id: '2' }, { Name: '3', Id: '3' }]

const RANGE = {
  1: {
    1: [{ Name: '0-X1', Id: '0-X1' }],
    2: [{ Name: 'X1-X2', Id: 'X1-X2' }],
    3: [{ Name: 'X2-X3', Id: 'X2-X3' }]
  },
  2: {
    1: [{ Name: '0-Y1', Id: '0-Y1' }],
    2: [{ Name: 'Y1-Y2', Id: 'Y1-Y2' }],
    3: [{ Name: 'Y2-Y3', Id: 'Y2-Y3' }]
  }
}

/**
 * Author: Md Jahir Uddin
 * Date: 17-01-2023
 * Description: Add or Edit Axis Cut-off
 * @param {{changePopUpHandler: ()=>{}, setSubmitClick:()=>{}, authUser: number, openPopup: string, masterItemId: number, masterId: number, finacialYearData: Array, saveAxisCutOff: ()=>{}, currentFinancialYearId: number}} props 
 */

function AxisCutOffMaster(props) {
  const {
    changePopUpHandler,
    setSubmitClick,
    authUser,
    openPopup,
    masterItemId = 0,
    masterId,
    finacialYearData,
    saveAxisCutOff, //NOSONAR
    currentFinancialYearId,
    updateOKRData, //NOSONAR
    loading } = props

  const [state, setState] = useState({
    minValue: 0.00,
    maxValue: 0.00,
    axisName: '',
    fyId: currentFinancialYearId,
    axisPosition: '',
    range: '',
  })

  const [updateSave, setUpdateSave] = useState(false)

  useEffect(() => {
    if (updateSave === true) {
      let validates = true;
      if (parseFloat(state.minValue) >= parseFloat(state.maxValue)) {
        setTimeout(() => {
          document.getElementById("lowerValueErr").innerHTML =
            axisCutOffAxisMinValueValidation
        }, 300)
        validates = false;
      }

      if (parseFloat(state.maxValue) <= parseFloat(state.minValue)) {
        setTimeout(() => {
          document.getElementById("higherValueErr").innerHTML =
            axisCutOffAxisMaxValueValidation
        }, 300)
        validates = false;
      }

      let options = {
        rules: {
          axisNameErr: {
            required: [true, axisCutOffAxisNameNotPresent],
          },
          axisPositionErr: {
            required: [true, axisCutOffAxisPositionNotPresent],
          },
          axisCutoffRangeErr: {
            required: [true, axisCutOffAxisRangeNotPresent],
          },
          lowerValueErr: {
            required: [true, axisCutOffAxisMinValueNotPresent],
          },
          higherValueErr: {
            required: [true, axisCutOffAxisMaxValueNotPresent],
          },
          axisCutOffFYErr: {
            required: [true, axisCutOffAxisFyIdNotPresent],
          },
        }
      }

      setTimeout(() => {
        const addFormObject = new FormValidator(
          "#axisCutoffForm",
          options
        );

        if (addFormObject.validate() && validates) {
          let data = {
            userId: authUser,
            masterItemId: masterItemId > 0 ? masterItemId : 0,
            ...state
          };
          updateOKRData({ loading: true })
          saveAxisCutOff(data);
          setUpdateSave(false)
        }else{
          setUpdateSave(false)
        }
      }, 300);
    }
  }, [updateSave === true])

  useEffect(() => {
    getData();
  }, [masterItemId]);

  useEffect(() => {
    if (props.okrMasterFlag === "updated") {
      changePopUpHandler("close");
    }
  }, [props.okrMasterFlag]);

  useEffect(() => {
    setTimeout(() => {
      setSubmitClick(onSaveData);
    }, 100);
  }, [state, masterId]);

  const getData = () => {
    ["axisCutoffRangeErr", "higherValueErr", "lowerValueErr", "axisNameErr", "axisPositionErr"]
      .forEach(err => { document.getElementById(err).innerHTML = "" });
    if (openPopup === "add") {
      updateOKRData({ loading: false })
      setState({
        minValue: 0.00,
        maxValue: 0.00,
        axisName: '',
        fyId: currentFinancialYearId,
        axisPosition: '',
        range: '',
      });
    } else if (openPopup === "edit") {
      updateOKRData({ loading: true });
      ["axisCutoffRangeErr", "higherValueErr", "lowerValueErr"]
        .forEach(err => { document.getElementById(err).innerHTML = "" });
      (async () => {
        const ratingSystemData = await okrServices.getOKRMaserData(
          authUser,
          masterId,
          masterItemId
        );
        const {
          axisName,
          axisPosition,
          fyId,
          maxValue,
          minValue,
          range,
        } = ratingSystemData.Result[0];
        setState({
          axisName,
          axisPosition,
          fyId,
          maxValue,
          minValue,
          range
        });
        updateOKRData({ loading: false })
      })();
    }
  };

  const onSaveData = async () => {
    const lowerValue = document.getElementById('lowerValue')?.ej2_instances[0]?.value
    const higherValue = document.getElementById('higherValue')?.ej2_instances[0]?.value
    if (lowerValue !== null && higherValue !== null) {
      setState(prevState => ({ ...prevState, minValue: lowerValue, maxValue: higherValue }))
    }
    setUpdateSave(true)
  };

  const handleChange = (type, value) => {

    if (type === 'axisName' || type === 'axisPosition') {
      setState(prevState => ({ ...prevState, range: "" }))
    }

    setState(prevState => ({ ...prevState, [type]: value }))

    let options = {}

    switch (type) {
      case "axisName":
        options = {
          rules: {
            axisNameErr: {
              required: [true, axisCutOffAxisNameNotPresent],
            },
          },
        };
        break;
      case "axisPosition":
        options = {
          rules: {
            axisPositionErr: {
              required: [true, axisCutOffAxisPositionNotPresent],
            },
          },
        };
        break;
      case "range":
        options = {
          rules: {
            axisCutoffRangeErr: {
              required: [true, axisCutOffAxisRangeNotPresent],
            },
          },
        };
        break;
      case "minValue":
        options = {
          rules: {
            lowerValueErr: {
              required: [true, axisCutOffAxisMinValueNotPresent],
            },
          },
        };
        break;
      case "maxValue":
        options = {
          rules: {
            higherValueErr: {
              required: [true, axisCutOffAxisMaxValueNotPresent],
            },
          },
        };
        break;
      case "fyId":
        options = {
          rules: {
            axisCutOffFYErr: {
              required: [true, axisCutOffAxisFyIdNotPresent],
            },
          },
        };
        break;
      default:
    }

    setTimeout(() => {
      const addFormObject = new FormValidator("#axisCutoffForm", options);
      addFormObject.validate();
    }, 100);
  }

  return (
    <>
      <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12" >
        <div className="pt-md pb-md ml-md mr-md" id="axisCutoffForm">
          <div
            className="col-xs-12 col-sm-12 col-lg-12 col-md-12 "
            style={{ marginBottom: "5px", marginTop: "10px" }}
          >
            <div
              className="col-xs-6 col-sm-6 col-lg-6 col-md-6"
              style={{ padding: 0 }}
            >
              <ComboBoxComponent
                id="axisName"
                fields={{ text: "Name", value: "Id" }}
                data-msg-containerid="axisNameErr"
                name="axisNameErr"
                cssClass="e-outline"
                floatLabelType="Auto"
                placeholder="Axis Name *"
                dataSource={AXIS_NAME}
                value={state.axisName}
                onChange={(e) => handleChange("axisName", e.value)}
              />
              <div
                id="axisNameErr"
                className="e-input-group e-float-icon-left error-div"
              />
            </div>
            <div
              className="col-xs-6 col-sm-6 col-lg-6 col-md-6"
              style={{ paddingRight: 0 }}
            >
              <ComboBoxComponent
                id="axisPosition"
                fields={{ text: "Name", value: "Id" }}
                data-msg-containerid="axisPositionErr"
                name="axisPositionErr"
                cssClass="e-outline"
                floatLabelType="Auto"
                placeholder="Axis Position *"
                dataSource={AXIS_POSITION}
                value={state.axisPosition}
                onChange={(e) => handleChange("axisPosition", e.value)}
              />
              <div
                id="axisPositionErr"
                className="e-input-group e-float-icon-left error-div"
              />
            </div>
          </div>

          <div
            className="col-xs-12 col-sm-12 col-lg-12 col-md-12 "
            style={{ marginBottom: "5px" }}
          >
            <ComboBoxComponent
              id="axisCutoffRange"
              fields={{ text: "Name", value: "Id" }}
              data-msg-containerid="axisCutoffRangeErr"
              name="axisCutoffRangeErr"
              cssClass="e-outline"
              floatLabelType="Auto"
              placeholder="Range *"
              dataSource={RANGE[state.axisName]?.[state.axisPosition] ?? []}
              value={state.range}
              onChange={(e) => handleChange("range", e.value)}
            />
            <div
              id="axisCutoffRangeErr"
              className="e-input-group e-float-icon-left error-div"
            />
          </div>

          <div
            className="col-xs-12 col-sm-12 col-lg-12 col-md-12 pb-md  "
            style={{ marginBottom: "10px" }}
          >
            <div
              className="col-xs-6 col-sm-6 col-lg-6 col-md-6"
              style={{ padding: 0 }}
            >
              <NumericTextBoxComponent
                floatLabelType="Auto"
                value={state.minValue}
                data-msg-containerid="lowerValueErr"
                min={0}
                format={'##.####'}
                decimals={4}
                number
                id="lowerValue"
                placeholder="Lower Value *"
                cssClass="e-outline"
                name='lowerValueErr'
                onChange={(e) => handleChange("minValue", e.value)}
              />
              <div
                id="lowerValueErr"
                className="e-input-group e-float-icon-left error-div"
              />
            </div>
            <div
              className="col-xs-6 col-sm-6 col-lg-6 col-md-6"
              style={{ paddingRight: 0 }}
            >
              <NumericTextBoxComponent
                floatLabelType="Auto"
                value={state.maxValue}
                data-msg-containerid="higherValueErr"
                min={0}
                format={'##.####'}
                decimals={4}
                number
                id="higherValue"
                placeholder="Higher Value *"
                cssClass="e-outline"
                name='higherValueErr'
                onChange={(e) => handleChange("maxValue", e.value)}
              />
              <div
                id="higherValueErr"
                className="e-input-group e-float-icon-left error-div"
              />
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 ">
            <ComboBoxComponent
              id="axisCutOffFY"
              fields={{ text: "Name", value: "Id" }}
              data-msg-containerid="axisCutOffFYErr"
              name="axisCutOffFYErr"
              cssClass="e-outline"
              floatLabelType="Auto"
              placeholder="Financial Year *"
              dataSource={finacialYearData}
              value={state.fyId}
              onChange={(e) => handleChange("fyId", e.value)}
            />
            <div
              id="axisCutOffFYErr"
              className="e-input-group e-float-icon-left error-div"
            />
          </div>
        </div>
        {loading && <ProgressBar />}
      </div>
    </>
  );
}

const mapStateToProps = ({ auth, okrreducer, meetingmaster }) => {
  const { authUser } = auth;
  const { okrMasterFlag, loading } = okrreducer;
  const { finacialYearData, currentFinancialYearId } = meetingmaster

  return {
    authUser,
    okrMasterFlag,
    finacialYearData,
    loading,
    currentFinancialYearId
  };
};

export default connect(mapStateToProps, {
  saveAxisCutOff,
  updateOKRData
})(AxisCutOffMaster);
