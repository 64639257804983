import React, { useEffect, useState } from "react";
import { FormValidator, NumericTextBoxComponent, TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { ComboBoxComponent } from "@syncfusion/ej2-react-dropdowns";
import okrServices from "../../services/okr/okrservices";
import { saveRatingMaster, updateOKRData } from "../../redux/actions/okr/okractions";
import { connect } from "react-redux";
import { ratingMasterLevelValidation, ratingMasterLowerValueFromStartingValidation, ratingMasterMeaningValidation, ratingMasterRatingSystemRequired, ratingMasterValueValidation, regexOnlyLettersAndSpaces } from "../../assets/constants/constants";
import ProgressBar from "../progressbar/progressbar";


/**
 * Author: Md Jahir Uddin
 * Date: 17-01-2023
 * Description: Add or Edit Rating Master
 * @param {{changePopUpHandler: ()=>{}, setSubmitClick:()=>{}, authUser: number, masterItemId: number, masterId: number, saveRatingMaster: ()=>{}}} props 
 */

const RatingMaster = (props) => {

  const {
    changePopUpHandler,
    setSubmitClick,
    authUser,
    masterItemId = 0,
    masterId,
    saveRatingMaster, //NOSONAR
    ratingSystem,
    openPopup,
    updateOKRData, //NOSONAR
    loading } = props

  const [state, setState] = useState({ ratingMasterId: null, ratingSystemId: "", value: "", meaning: "", levelStartingFromLowerValue: "" })

  const [updateSave, setUpdateSave] = useState(false)

  useEffect(() => {
    if (updateSave === true) {
      let options = {
        rules: {
          ratingSystemErr: {
            required: [true, ratingMasterRatingSystemRequired],
          },
          ratingValueErr: {
            required: [true, ratingMasterValueValidation],
            maxLength: 100
            // regex: [regexOnlyLettersAndSpaces, "Please enter appropriate value (Alphabets only)."]
          },
          ratingMeaningErr: {
            required: [true, ratingMasterMeaningValidation],
            maxLength: 100
            // regex: [regexOnlyLettersAndSpaces, "Please enter appropriate meaning (Alphabets only)."]
          },
          ratingLevelStValueErr: {
            required: [true, ratingMasterLowerValueFromStartingValidation],
            number: [true, ratingMasterLevelValidation]
          }
        },
      }
      setTimeout(() => {
        const addFormObject = new FormValidator(
          "#ratingMasterForm",
          options
        );
        if (addFormObject.validate()) {
          let data = {
            ...state,
            userId: authUser,
            ratingMasterId: state.ratingMasterId ? state.ratingMasterId : 0,
            type: state.ratingMasterId ? 'EDIT' : 'ADD'
          };
          updateOKRData({ loading: true });
          saveRatingMaster(data);
          setUpdateSave(false);
        }else{
          setUpdateSave(false);
        }
      }, 300);
    }
  }, [updateSave === true])

  useEffect(() => {
    (async () => {
      try {
        // Get rating master data for edit
        if (openPopup === 'edit') {
          updateOKRData({ loading: true });
          const okrMasterItemData = await okrServices.getOKRMaserData(
            authUser,
            masterId,
            masterItemId
          );
          setState(prevState => {
            let { levelStartingFromLowerValue, meaning, ratingSystemId, ratingValue } = okrMasterItemData.Result[0]
            return {
              ...prevState,
              levelStartingFromLowerValue,
              meaning,
              value: ratingValue,
              ratingSystemId,
              ratingMasterId: masterItemId
            }
          })
          updateOKRData({ loading: false })
        } else {
          setState({ ratingMasterId: null, ratingSystemId: "", value: "", meaning: "", levelStartingFromLowerValue: "" });
          ["ratingSystemErr",
            "ratingValueErr",
            "ratingMeaningErr",
            "ratingLevelStValueErr"
          ].forEach(err => { document.getElementById(err).innerHTML = "" });
          updateOKRData({ loading: false })
        }
      } catch (e) {
        console.log(e);
      }
    })
      ();
  }, [masterId, masterItemId]);


  useEffect(() => {
    if (props.okrMasterFlag === "updated") {
      changePopUpHandler("close");
    }
  }, [props.okrMasterFlag]);

  useEffect(() => {
    setTimeout(() => {
      setSubmitClick(onSaveData);
    }, 100);
  }, [state, masterId]);


  const onSaveData = async () => {
    const ratinglevelStValue = document.getElementById('ratinglevelStValue')?.ej2_instances[0]?.value
    if (ratinglevelStValue !== null) {
      setState(prevState => ({ ...prevState, levelStartingFromLowerValue: ratinglevelStValue,}))
    }
    setUpdateSave(true)
  };

  const handleChange = (type, value) => {
    let options = {}
    switch (type) {
      case "ratingSystemId":
        setState(prevState => ({ ...prevState, ratingSystemId: value }))
        options = {
          rules: {
            ratingSystemErr: {
              required: [true, ratingMasterRatingSystemRequired],
            },
          },
        };
        break;
      case "value":
        setState(prevState => ({ ...prevState, value: value }))
        options = {
          rules: {
            ratingValueErr: {
              required: [true, ratingMasterValueValidation],
              // regex: [regexOnlyLettersAndSpaces, "Please enter appropriate value (Alphanumerics only)."],
              maxLength: 100
            },
          },
        };
        break;
      case "meaning":
        setState(prevState => ({ ...prevState, meaning: value }))
        options = {
          rules: {
            ratingMeaningErr: {
              required: [true, ratingMasterMeaningValidation],
              maxLength: 100
              // regex: [regexOnlyLettersAndSpaces, "Please enter the appropriate meaning for rating master"]
            },
          },
        };
        break;
      case "levelStartingFromLowerValue":
        setState(prevState => ({ ...prevState, levelStartingFromLowerValue: value }))
        options = {
          rules: {
            ratingLevelStValueErr: {
              required: [true, ratingMasterLowerValueFromStartingValidation],
              number: [true, ratingMasterLevelValidation]
            },
          },
        };
        break;
      default:
    }

    setTimeout(() => {
      const addFormObject = new FormValidator("#ratingMasterForm", options);
      addFormObject.validate();
    }, 100);
  }

  return (
    <div>
      <div style={{ padding: "16px" }} id="ratingMasterForm">
        <div className="col-xs-12 col-sm-12 col-lg-12 mt-sm mb-md atc-no-paddding">
          <ComboBoxComponent
            id="ratingSystemFY"
            data-msg-containerid="ratingSystemErr"
            name="ratingSystemErr"
            dataSource={ratingSystem}
            fields={{ text: "Name", value: "Id" }}
            value={state.ratingSystemId}
            placeholder="Rating Scale *"
            floatLabelType="Auto"
            cssClass="e-outline"
            change={(e) => handleChange('ratingSystemId', e.value)}
          />
          <div id="ratingSystemErr" className="error-div" />
        </div>
        <div className="col-xs-12 col-sm-12 col-lg-12 pt-sm mb-md atc-no-paddding">
          <TextBoxComponent
            data-msg-containerid="ratingValueErr"
            id="ratingMasterValue"
            value={state.value}
            name="ratingValueErr"
            placeholder="Value *"
            floatLabelType="Auto"
            cssClass="e-outline"
            onChange={(e) => handleChange('value', e.value)}
          />
          <div id="ratingValueErr" className="error-div" />
        </div>
        <div className="col-xs-12 col-sm-12 col-lg-12 pt-sm mb-md atc-no-paddding">
          <TextBoxComponent
            data-msg-containerid="ratingMeaningErr"
            id="ratingMasterMeaning"
            value={state.meaning}
            name="ratingMeaningErr"
            placeholder="Meaning *"
            floatLabelType="Auto"
            cssClass="e-outline"
            onChange={(e) => handleChange('meaning', e.value)}
          />
          <div id="ratingMeaningErr" className="error-div" />
        </div>
        <div className="col-xs-12 col-sm-12 col-lg-12 pt-sm mb-sm atc-no-paddding">
          <NumericTextBoxComponent
            data-msg-containerid="ratingLevelStValueErr"
            id="ratinglevelStValue"
            value={state.levelStartingFromLowerValue}
            name="ratingLevelStValueErr"
            placeholder="Level Starting From Lower Value *"
            floatLabelType="Auto"
            cssClass="e-outline"
            onChange={(e) => handleChange('levelStartingFromLowerValue', e.value)}
            step={1}
            format='###'
            min={0}
            max={100}
            decimals={0}
            number
          />
          <div id="ratingLevelStValueErr" className="error-div" />
        </div>
      </div>
      {loading && <ProgressBar />}
    </div>
  );
};

const mapStateToProps = ({ auth, okrreducer }) => {
  const { authUser } = auth;
  const { okrMasterFlag, ratingSystem, loading } = okrreducer;
  return {
    authUser,
    okrreducer,
    okrMasterFlag,
    ratingSystem,
    loading
  };
};

export default connect(mapStateToProps, {
  saveRatingMaster,
  updateOKRData
})(RatingMaster);
