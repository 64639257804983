// Author : Suresh R. Sargam
// Date : 11-Dec-2019
// Description: Add Self Meeting Title

import React, { Component } from "react";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import {
  MultiSelectComponent,
  DropDownListComponent,
  Inject,
  CheckBoxSelection,
} from "@syncfusion/ej2-react-dropdowns";
import PropTypes from "prop-types";
import bdservices from "../../services/bdservices";
import services from "../../services/services";
import {
  noJobsAvailable,
  noBTBAvailable, WARNING, leaveMeetingException,
  WhatNext, Cognition, ClientMeetingsId, OPSBTBClientMeet, OPSBTBDropdown, SalesClientMeet
} from "../../assets/constants/constants";
import "../../assets/style/index.css";
import { OTHERS_CALENDAR } from "../../redux/constant/meetingcategoryconstant";
import { dencrypt } from "../../../src/app/other/commonfunction";
import { getNotify } from '../../app/other/commonfunction'

class AddContent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      templateId: 0,
      meetingTypeName: "",
      meetingTypeId: 0,
      meetingSubTypeId: 0,
      meetingSubTypeCategory: "",
      serviceProductsId: [],
      btbId: 0,
      subBTBId: 0,
      businessImpact: "",
      workTypeId: 0,
      subBTBList: [],
      JobList: [],
      changed: 0,
      meetingSubType: [],
      value: ""
    };

    this.handleChangeMeetingType = this.handleChangeMeetingType.bind(this);
    this.handleChangeMeetingSubType = this.handleChangeMeetingSubType.bind(
      this
    );
    this.handleChangeBTB = this.handleChangeBTB.bind(this);
    this.handleChangeJob = this.handleChangeJob.bind(this);
    this.handleChangeServiceProduct = this.handleChangeServiceProduct.bind(
      this
    );
    this.templateList = [];
    this.isDigitalMeeting = false;
    this.templateSelectionFlag = false;
  }

  async componentDidMount() {
    const { templateListData } = this.props;
    this.templateList = templateListData;
    this.setState({ changed: 1 });
    // eslint-disable-next-line
    /** this.props.setOPSMeeting("", "", "", "", "", "", 0, "", []); */
  }

  // on Meeting Type change
  handleChangeMeetingType = (event) => {
    let selectedName = "";
    let selectedId = 0;
    if (event.itemData !== null) {
      selectedName = event.itemData === null ? "" : event.itemData.Name;
      selectedId = event.itemData === null ? 0 : event.itemData.Id;
    }
    this.fillmeetingSubType(selectedName, selectedId);

    if (this.templateSelectionFlag) {
      this.templateSelectionFlag = false;
      document.getElementById("Subject").focus();
      document.getElementById("Subject").value = "";
      this.setTitleFromQuickInfo("");
      this.setTemplateFromQuickInfo(0);
      this.setState({ templateId: 0 });
    }
  };

  // get the meeting activity type
  fillmeetingSubType = (selectedName, selectedId) => {
    const { meetingSubType } = this.props;
    let newSubType = [];
    if (selectedName !== "") {
      newSubType = meetingSubType.filter((item) => {
        return (
          item.Type === "SubType" && item.Optional === selectedId.toString()
        );
      });
    }

    this.setState({
      meetingTypeId: selectedId,
      meetingTypeName: selectedName,
      meetingSubType: newSubType,
      meetingSubTypeId: "",
      meetingSubTypeCategory: "",
      btbId: "",
      workTypeId: 0,
      subBTBList: [],
      JobList: [],
    });

    // eslint-disable-next-line
    this.props.setOPSMeeting(selectedId, selectedName, "", "", "", "", 0, "", []);
    this.popupPositionCalculation();
  };

  handleChangeMeetingSubType = (event) => { // NOSONAR
    const { authUser, otherUser, categoryName } = this.props;
    const { meetingTypeId, meetingTypeName } = this.state;
    let newMeetingSubTypeId = 0, newMeetingSubTypeCategory = "";
    if (event.itemData !== null) {
      newMeetingSubTypeId = event.itemData === null ? 0 : event.itemData.Id;
      newMeetingSubTypeCategory = event.itemData === null ? 0 : event.itemData.Optional1;
      if (ClientMeetingsId.includes(meetingTypeId)) {
        // Get The BTB Ids
        const response = Promise.resolve(
          bdservices.getBTBJobTask(categoryName === OTHERS_CALENDAR ? otherUser : authUser, newMeetingSubTypeId, 0)
        );
        response.then((data) => {
          if (data !== 0 && data !== "") {
            this.setState({ subBTBList: data, JobList: [] });
          }
        });
      }
    }
    setTimeout(() => {

      this.setState({
        meetingSubTypeId: newMeetingSubTypeId,
        meetingSubTypeCategory: newMeetingSubTypeCategory
      });
      if (meetingTypeId === 998) { // Leave
        getNotify(WARNING, leaveMeetingException)
        this.setState({
          meetingSubTypeId: newMeetingSubTypeId,
          meetingSubTypeCategory: newMeetingSubTypeCategory,
          value: "Leave - " + event.itemData.Name
        });
      }
      // eslint-disable-next-line
      this.props.setOPSMeeting(meetingTypeId, meetingTypeName, newMeetingSubTypeId, newMeetingSubTypeCategory, "", "", 0, "", []);
      this.setState({})
      this.popupPositionCalculation();

      // Client Meeting
      if (OPSBTBClientMeet.includes(newMeetingSubTypeId) || SalesClientMeet.includes(newMeetingSubTypeId)) {
        document.getElementsByClassName("e-event-details")[0].click();
      }
    }, 500);
  };

  handleChangeBTB = (event) => {
    const { meetingSubTypeId, meetingSubTypeCategory, meetingTypeId, meetingTypeName } = this.state;
    const { authUser, categoryName, otherUser } = this.props;

    let selBTBId = "";
    let selWorkTypeId = 0;
    let businessimpact = "";

    if (event.itemData !== null) {
      selBTBId = event.itemData === null ? 0 : event.itemData.Id;
      selWorkTypeId = event.itemData === null ? 0 : event.itemData.WorkTypeId;
      businessimpact = event.itemData === null ? 0 : event.itemData.Status;

      // For account btb get the Job list from OPS System
      if (selWorkTypeId === 5) {
        const response = bdservices.getBTBJobTask(
          categoryName === OTHERS_CALENDAR ? otherUser : authUser,
          meetingSubTypeId,
          selBTBId
        );
        response.then((data) => {
          this.setState({ JobList: data });
        });
      } else {
        this.setState({ JobList: [] });
      }
    }

    setTimeout(() => {
      this.setState({
        btbId: parseInt(selBTBId, 10),
        workTypeId: selWorkTypeId,
        businessImpact: businessimpact,
      }, () => { this.popupPositionCalculation(); });

    }, 1000)


    // eslint-disable-next-line
    this.props.setOPSMeeting(meetingTypeId, meetingTypeName, meetingSubTypeId, meetingSubTypeCategory, selBTBId, "", selWorkTypeId, businessimpact, []);

  };

  handleChangeJob = (event) => {
    const {
      meetingTypeId,
      meetingTypeName,
      meetingSubTypeId,
      meetingSubTypeCategory,
      btbId,
      workTypeId,
      businessImpact,
      serviceProductsId,
    } = this.state;
    let selJobId;
    if (event.itemData !== null) {
      selJobId = event.itemData === null ? 0 : event.itemData.Id;
      this.setState({ subBTBId: selJobId });
    }
    // eslint-disable-next-line
    this.props.setOPSMeeting(
      meetingTypeId,
      meetingTypeName,
      meetingSubTypeId,
      meetingSubTypeCategory,
      btbId,
      selJobId,
      workTypeId,
      businessImpact,
      serviceProductsId
    );
    this.popupPositionCalculation();
  };

  handleChangeServiceProduct = (event) => {
    const {
      meetingTypeId,
      meetingTypeName,
      meetingSubTypeId,
      meetingSubTypeCategory,
      subBTBId,
      btbId,
      workTypeId,
      businessImpact,
    } = this.state;
    let newServiceProductsId = [];
    if (event.value !== null) {
      newServiceProductsId = event.value === null ? [] : event.value;
    }
    this.setState({ serviceProductsId: event.value });
    // eslint-disable-next-line
    this.props.setOPSMeeting(
      meetingTypeId,
      meetingTypeName,
      meetingSubTypeId,
      meetingSubTypeCategory,
      btbId,
      subBTBId,
      workTypeId,
      businessImpact,
      newServiceProductsId
    );
    this.popupPositionCalculation();
  };

  noRecordsTemplate = () => {
    return <div>No Records Found</div>;
  };

  setTitleFromQuickInfo = (value) => {
    if (value !== null) {
      // eslint-disable-next-line
      this.props.setTitleFromQuickInfo(value);
    }
  };

  setTemplateFromQuickInfo = (value) => {
    if (value !== null) {
      // eslint-disable-next-line
      this.props.setTemplateFromQuickInfo(value);
    }
  };

  popupPositionCalculation = () => {
    setTimeout(() => {
      const scheduleObj = document.querySelector(".e-schedule")
        .ej2_instances[0];
      const quickPopupWrap = scheduleObj.element.querySelector(
        ".e-quick-popup-wrapper"
      );
      const totalHeight =
        +quickPopupWrap.style.top.replace("px", "") +
        quickPopupWrap.offsetHeight;
      const scheduleHeight = +scheduleObj.element.style.height.replace(
        "px",
        ""
      );
      if (
        totalHeight > scheduleHeight ||
        +quickPopupWrap.style.top.replace("px", "") === 0
      ) {
        quickPopupWrap.style.top = `${scheduleHeight - quickPopupWrap.offsetHeight - 0
          }px`;
      }
    }, 10);
  };

  handleChangeTemplate = (event) => {
    const { changed } = this.state;
    if (event.itemData !== null) {
      this.templateSelectionFlag = true;
      document.getElementById("Subject").focus();
      document.getElementById("Subject").value =
        event.itemData.var_meeting_title;
      this.setTitleFromQuickInfo(event.itemData.var_meeting_title);
      this.setTemplateFromQuickInfo(event.itemData.int_meeting_template_uid);
      const thisVal = changed;
      if (event.itemData.enum_meeting_web_link_type) {
        this.isDigitalMeeting = true;
        this.setState({
          changed: thisVal + 1,
          templateId: event.itemData.int_meeting_template_uid,
        });
      } else {
        this.isDigitalMeeting = false;
        this.setState({
          changed: thisVal + 1,
          templateId: event.itemData.int_meeting_template_uid,
        });
      }
    } else if (typeof event.e !== "undefined") {
      this.templateSelectionFlag = false;
      document.getElementById("Subject").focus();
      document.getElementById("Subject").value = "";
      this.setState({ templateId: 0 });
      this.setTitleFromQuickInfo("");
      this.setTemplateFromQuickInfo(0);
    }

    if (this.templateSelectionFlag) {
      this.setState({ meetingTypeId: "" });
      // eslint-disable-next-line
      this.props.setOPSMeeting(
        "",
        "",
        "",
        "",
        "",
        "",
        0,
        "",
        []
      );
    }
  };



  render() { // NOSONAR
    const {
      value,
      meetingSubTypeId,
      subBTBList,
      workTypeId,
      btbId,
      JobList,
      meetingSubType,
      meetingTypeId,
      serviceProductsId,
      templateId,
    } = this.state;
    const { meetingdate, meetingType, meetingServiceProduct } = this.props;
    const pnlId = dencrypt(localStorage.getItem("pnlId"));
    
    return (
      <>
        <div className="e-cell-content e-template popup-content">
          <form className="e-schedule-form">
            {typeof this.templateList.result !== "undefined" &&
              this.templateList.result.length > 0 && (
                <div style={{ marginBottom: "7px" }}>
                  <DropDownListComponent
                    id="template_list"
                    name="template_list"
                    className="e-templatelist e-field"
                    dataSource={this.templateList.result}
                    ref={(dropdownlist) => {
                      this.listtemplateref = dropdownlist;
                    }}
                    fields={{
                      value: "int_meeting_template_uid",
                      text: "var_meeting_template_name",
                    }}
                    popupHeight="220px"
                    value={templateId}
                    change={this.handleChangeTemplate}
                    placeholder="Meeting Templates"
                    floatLabelType="Auto"
                    showClearButton
                    cssClass="e-outline"
                  />
                  <div id="opscatError" className="error-div" />
                </div>
              )}
            {typeof this.templateList.result !== "undefined" &&
              this.templateList.result.length > 0 &&
              this.isDigitalMeeting && (
                <div style={{ marginBottom: "7px" }}>
                  <TextBoxComponent
                    placeholder="Meeting Digital Link"
                    cssClass="e-outline"
                    name="weblink"
                    id="weblink"
                    floatLabelType="Auto"
                    className="e-weblink e-field"
                    htmlAttributes={{ maxlength: "100" }}
                  />
                </div>
              )}
            {
              // Meeting Type
              this.templateSelectionFlag === false && meetingType.length > 0 && (
                <div style={{ marginTop: "15px" }}>
                  <DropDownListComponent
                    id="meetingType"
                    name="meetingType"
                    className="e-subcategary e-field"
                    dataSource={meetingType.filter((item) => item.Id !== (this.props.groupIndex === 1 ? 0 : 998))}
                    value={meetingTypeId}
                    fields={{ value: "Id", text: "Name", iconCss: "Optional1" }}
                    select={this.handleChangeMeetingType}
                    placeholder="Meeting Type *"
                    floatLabelType="Auto"
                    cssClass="e-outline"
                    showClearButton
                  />
                  <div id="meetingTypeError" className="error-div" />
                </div>
              )
            }
            {
              // Meeting sub Type
              this.templateSelectionFlag === false && meetingSubType.length > 0 && (
                <div style={{ marginTop: "15px" }}>
                  <DropDownListComponent
                    id="meetingSubType"
                    name="meetingSubType"
                    className="e-subcategary e-field"
                    dataSource={meetingSubType}
                    fields={{ text: "Name", value: "Id" }}
                    select={this.handleChangeMeetingSubType}
                    placeholder="Activity Type *"
                    floatLabelType="Auto"
                    cssClass="e-outline"
                  />
                  <div id="meetingSubTypeError" className="error-div" />
                </div>
              )
            }
            {
              ClientMeetingsId.includes(meetingTypeId) &&
              meetingSubTypeId !== 0 &&
              meetingSubTypeId !== "" && // NOSONAR
              OPSBTBDropdown.includes(meetingSubTypeId) &&
              this.templateSelectionFlag === false && (
                <div style={{ marginTop: "15px" }}>
                  {subBTBList.length > 0 ? (
                    <div>
                      <DropDownListComponent
                        id="selectbtblist"
                        name="selectbtblist"
                        className="e-selectbtblist e-field"
                        dataSource={subBTBList}
                        ref={(dropdownlist) => {
                          this.listjobref = dropdownlist;
                        }}
                        fields={{ value: "Id", text: "Name" }}
                        cssClass="e-outline"
                        floatLabelType="Auto"
                        select={this.handleChangeBTB}
                        placeholder={pnlId === WhatNext ? "Select BTB" : "Select BTB *"} // NOSONAR
                      />
                    </div>
                  ) : (
                    dencrypt(localStorage.getItem("pnlId")) !== WhatNext &&
                    <div
                      id="opsBtbError"
                      className="e-input-group e-float-icon-left error-div"
                    >
                      {noBTBAvailable}
                    </div>

                  )}
                </div>
              )
            }
            {
              // Job list according to BTB list
              this.templateSelectionFlag === false &&
              meetingSubTypeId !== 0 &&
              meetingSubTypeId !== "" && // NOSONAR
              workTypeId !== "" && // NOSONAR
              btbId !== 0 &&
              (workTypeId === 5) &&
              dencrypt(localStorage.getItem("pnlId")) !== Cognition && (
                <div style={{ marginTop: "15px" }}>
                  {JobList.length > 0 ? (
                    <div>
                      <DropDownListComponent
                        id="selectSubBTBId"
                        name="selectSubBTBId"
                        className="e-selectSubBTBId e-field"
                        dataSource={JobList}
                        ref={(dropdownlist) => {
                          this.listjob = dropdownlist;
                        }}
                        fields={{ value: "Id", text: "Name" }}
                        cssClass="e-outline"
                        floatLabelType="Auto"
                        style={{ borderBottom: "groove 1px" }}
                        select={this.handleChangeJob}
                        placeholder={pnlId === WhatNext ? "Select Sub BTB Id" : "Select Sub BTB Id *"} // NOSONAR
                      />
                    </div>
                  ) : (
                    <div
                      id="opsJobError"
                      className="e-input-group e-float-icon-left error-div"
                    >
                      {noJobsAvailable}
                    </div>
                  )}
                </div>
              )
            }
            {
              // service Product according to BTB list
              this.templateSelectionFlag === false &&
              meetingSubTypeId !== 0 &&
              meetingSubTypeId !== "" && // NOSONAR
              workTypeId !== "" && // NOSONAR
              btbId < 0 && (
                <div style={{ marginTop: "15px" }}>
                  {meetingServiceProduct.length > 0 ? (
                    <div>
                      <MultiSelectComponent
                        style={{
                          display: "inline-block",
                          borderBottom: "groove 1px",
                        }}
                        id="selectSubBTBId"
                        name="selectSubBTBId"
                        className="e-selectSubBTBId e-field"
                        dataSource={meetingServiceProduct}
                        ref={(dropdownlist) => {
                          this.listjob = dropdownlist;
                        }}
                        fields={{ value: "Id", text: "Name" }}
                        cssClass="e-outline"
                        floatLabelType="Auto"
                        showSelectAll={false}
                        change={this.handleChangeServiceProduct}
                        value={serviceProductsId}
                        showDropDownIcon
                        placeholder="Service / Platform *"
                        mode="CheckBox"
                        allowFiltering={false}
                        data-msg-containerid="serviceProductError"
                      >
                        <Inject services={[CheckBoxSelection]} />
                      </MultiSelectComponent>
                    </div>
                  ) : (
                    <div
                      id="serviceProductError"
                      className="e-input-group e-float-icon-left error-div"
                    >
                      {noJobsAvailable}
                    </div>
                  )}
                </div>
              )
            }
            <div style={{ marginTop: "15px" }}>
              <TextBoxComponent
                placeholder="Meeting Title *"
                cssClass="e-outline"
                name="Subject"
                id="Subject"
                floatLabelType="Auto"
                className="e-subject e-field"
                htmlAttributes={{ maxlength: "100" }}
                change={(e) => this.setTitleFromQuickInfo(e.value)}
                value={meetingTypeId === 885 ? "Break" : value}
              />
            </div>
          </form>
        </div>

        <div className="alignPopup" style={{ marginTop: "5px" }}>
          <div className="e-date-time-icon e-icons pr-sm popup-calender-logo" />
          <div className="e-date-time-wrapper e-text-ellipsis">
            <div className="e-date-time-details e-text-ellipsis">
              {" "}
              {meetingdate}
            </div>
          </div>
        </div>
      </>
    );
  }
}
AddContent.propTypes = {
  authUser: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  meetingdate: PropTypes.string,
  meetingSubType: PropTypes.arrayOf(Object),
  meetingType: PropTypes.arrayOf(Object),
  meetingServiceProduct: PropTypes.arrayOf(Object),
};

AddContent.defaultProps = {
  authUser: "",
  meetingdate: "",
  meetingSubType: [],
  meetingType: [],
  meetingServiceProduct: [],
};
export default AddContent;
