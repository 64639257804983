
/**
 * Author: Md Jahir Uddin
 * Date: 31-03-2023
 * Description: Use this component when we need to render a label text comb
 */

import React from 'react';
import PropTypes from "prop-types";
import AttachmentComponent from '../components/helpdeskticket/attachmentcomponent';
import ReactHtmlParser from 'react-html-parser'
import { getTimezoneFormattedTime } from '../app/other/commonfunction';

const comment = ({ id, comment: commentData, cssClass, style }) => {

    return (
        <div className={'helpdesk-history-sub-layer ' + cssClass} id={id} style={style}>
            <div className="pt-md helpdesk-history-summary">
                <div className=" pb-md">
                    <center><span className="img-circle fas fa-user-circle img-circle-grid-photo" alt="" style={{ fontSize: '24px' }} /></center>
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <div className="pl-md" style={{ display: "flex", flexDirection: "row" }}>
                        <div className='helpdesk-value pr-md'>{`${commentData.FirstNameOrUsername ?? ''} ${commentData.LastName ?? ''}`}</div><div className="file-date" style={{ marginLeft: "4px" }} >{getTimezoneFormattedTime(commentData?.CommentDate)}</div>
                    </div>
                    <div className="file-desc pl-md pt-sm pb-sm">{ReactHtmlParser(commentData.Body)}</div>
                </div>
            </div>
            {commentData.Attachments.length > 0 &&
                <div className='comment-attachments'>
                    {commentData.Attachments.map(attachment => {
                        return (
                            <AttachmentComponent key={attachment.FileID} id={attachment.FileID} url={attachment.Url} fileName={attachment.FileName} dateTime={getTimezoneFormattedTime(attachment?.CommentDate)} showAttachment />
                        )
                    }
                    )}
                </div>
            }
        </div>
    )
}

comment.propTypes = {
    id: PropTypes.string,
    comment: PropTypes.array,
    cssClass: PropTypes.string,
    style: PropTypes.object
};

comment.defaultProps = {
    id: "",
    comment: [],
    style: {},
    cssClass: "",
};

export default comment