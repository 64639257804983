/**
 * Author: Md Jahir Uddin
 * Date: 03-03-2023
 * Description: Layout for tmlc to include master and layers into admin module
 * @param {{location: string}} props 
 */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import SetupContainer from '../../containers/tmlc/setupcontainer';
import { dencrypt } from '../../other/commonfunction';
import queryString from "query-string";

class TmlcSetupLayout extends Component {
  constructor(props) {
    super(props)
    this.state = {
      masterId: 0,
      openPopup: "close",
      editClusterId: null,
      weekDayUniqueData: {}
    }
  }

  async componentDidMount() {
    this.getData()
  }

  getData = async () => {
      this.setMasterId()
  }

  setMasterId = () => {
    try {
      const { masterId } = this.state
      const params = queryString.parse(this.props.location.search);
      if (typeof params.masterId !== 'undefined' && params.masterId !== null) {
        if (masterId !== dencrypt(params.masterId))
          this.setState({ masterId: dencrypt(params.masterId) })
      }
    } catch (error) {
    }
  }

  componentDidUpdate() {
    this.setMasterId();
  }

  render() {
    const {masterId } = this.state
    return (
      <div>
        <SetupContainer
          masterId={masterId}
        />
      </div>
    );
  }
}
const mapStateToProps = ({router }) => {
  const { location } = router;
  return {
    location,
  }
}
export default connect(mapStateToProps, {})(TmlcSetupLayout);
