import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { Button, Dialog } from "@material-ui/core";
import { FormValidator } from "@syncfusion/ej2-inputs";
import {
  DialogTitleCOMMENT,
  DialogContent,
  DialogActions,
} from "../../customdialog/customdialog";
import ErrorBoundary from "../../error/errorboundary";
import {
  updateDocumentInfo,
  addDocumentComments,
} from "../../../redux/actions/entryexit/onboardingaction";
import { NEW_CANDIDATE_UPLOAD } from "../../../redux/constant/entryexit/candidateconstant";
import addCandidateService from "../../../services/entryexit/addCandidateService";
import "./comments.css";

const ScrollBarss = `
.MuiDialog-paper {
  margin: 32px;
  position: relative;
  overflow-y: hidden;
}
`
class Comments extends Component {
  constructor(props) {
    super(props);
    this.state = {
      comments: "",
      commentsList: [],
    };
    this.chatContainer = React.createRef();
    this.handleYes = this.handleYes.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  getPreviousComment = () => {
    const {
      showComments,
      candidateType,
      authUser,
      selectedFolderId,
      commentFor,
    } = this.props;

    if (showComments) {
      const data = {
        authUser: candidateType === NEW_CANDIDATE_UPLOAD ? 0 : authUser,
        doccatid: selectedFolderId,
        candtype: candidateType,
        commentFor,
      };      
      const documentDetails = addCandidateService.getDocumentComments(data);
      documentDetails
        // eslint-disable-next-line
        .then(async (result) => {
          let filterDetails;
          if (candidateType === NEW_CANDIDATE_UPLOAD) {
            filterDetails = result;
          } else {
            filterDetails = await result.filter(function (elem) {
              return elem.commentFor === commentFor;
            });
          }
          this.setState({ commentsList: filterDetails });
          setTimeout(() => {
            this.textareaObj.addAttributes({ rows: 5 });
          }, 500);
          setTimeout(() => {
            this.scrollToMyRef();
          }, 600);
        })
        .catch();
    } else {
      setTimeout(() => {
        this.textareaObj.addAttributes({ rows: 5 });
      }, 500);
      setTimeout(() => {
        this.scrollToMyRef();
      }, 600);
    }
  }
  scrollToMyRef = () => {
    if (this.chatContainer.current !== null) {
      const scroll =
        this.chatContainer.current.scrollHeight -
        this.chatContainer.current.clientHeight;
      this.chatContainer.current.scrollTo(0, scroll);
    }
  };
  componentDidMount() {
    this.getPreviousComment();
    setTimeout(() => {
      this.scrollToMyRef();
    }, 600)
  }
  // eslint-disable-next-line
  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.refreshDocComments === "update") {
      // eslint-disable-next-line
      // this.props.updateDocumentInfo({
      //   openDocumentComments: false,
      //   refreshDocComments: "",
      // });
      // eslint-disable-next-line
      // if (this.props.commentFor !== '2')
      setTimeout(() => {
        this.getPreviousComment();
        this.setState({
          comments: ''
        })
        this.props.updateDocumentInfo({ refreshDocComments: "" });
      }, 600)
    }
  }

  handleClose = () => {
    // eslint-disable-next-line
    this.props.updateDocumentInfo({ openDocumentComments: false });
  };

  handleYes = () => {
    const options = {
      rules: {
        commentsErr: { required: [true, "Please enter comments."] },
      },
    };
    const addFormObject = new FormValidator("#addCommentsFormInfo", options);
    addFormObject.validate();

    const { comments } = this.state;

    const {
      candidateType,
      authUser,
      selectedFolderId,
      candidateid,
      commentFor
    } = this.props;

    const data = {
      authUser: candidateType === NEW_CANDIDATE_UPLOAD ? 0 : authUser,
      docCatId: selectedFolderId,
      candId: candidateType === NEW_CANDIDATE_UPLOAD ? candidateid : 0,
      comments,
      commentFor: commentFor,
      showMessage: true
    };
    // eslint-disable-next-line
    if (commentFor === '2') {
      // eslint-disable-next-line
      this.props.updateDocumentInfo({
        refreshDocStatus: "app_rej_status",
        doc_status: "R",
        docRejectionParams: [],
      });
      setTimeout(() => {
        this.props.addDocumentComments(data);
      }, 10);
    } else {
      if (comments === '') {
        return
      }
      this.props.addDocumentComments(data);
    }
  };

  setComments = (e) => {
    this.setState({ comments: e.value });
  };

  render() { // NOSONAR
    const {
      showComments,
      commentFor,
      categoryName,
      openDocumentComments,
      candidateType
    } = this.props;   
    
    const { commentsList, comments } = this.state;
    // this.commentFor = commentFor;
    // const submitButtontext = commentFor === 2)
    // PaperComponent={PaperComponent}
    return (
      <>
        <style>{ScrollBarss}</style>
        <Dialog
          aria-labelledby="comments-title"
          open={openDocumentComments}
          onClose={this.handleClose}
          fullWidth
          maxWidth="md"
          width={1000}
          height={600}

        >
          <DialogTitleCOMMENT id="comments-title" onClose={this.handleClose}>
            {showComments ? "" : "Add"}{" "}
            {commentFor === "2"
              ? "Comment"
              : `Document : ${categoryName}`}
          </DialogTitleCOMMENT>
          <ErrorBoundary>
            <DialogContent dividers ref={this.chatContainer}>
              {
                // showComments ? 
                commentsList.length > 0 &&
                commentsList.map((item) => (
                  <div key={`comm${item.docId}`} className="comment-main-content-new" style={{ backgroundColor: item.addedByUser > 0 ? '#eee' : '#fff' }}>
                    <div className="comment-main-content-header-new" //style={{textAlign:candidateType === NEW_CANDIDATE_UPLOAD && item.commentFor === "2" ? "left" : 'right'}}
                    >
                      <strong style={{ color: candidateType === NEW_CANDIDATE_UPLOAD && item.commentFor === "2" ? "inherit" : 'inherit', }}>{item.AddedBy} {candidateType === NEW_CANDIDATE_UPLOAD && item.commentFor === "2" ? " - Comment " : " "}-{" "}</strong>

                      <span className='date-style'>{item.AddedOn} {candidateType === NEW_CANDIDATE_UPLOAD && item.commentFor === "2" && <i className="far fa-times-circle custom-reject-style pl-sm"
                        title="File is rejected please re-upload"></i>}</span>

                    </div>
                    <div className="comment-main-content-content" key={`data${item.docId}`}
                      style={{
                        color: candidateType === NEW_CANDIDATE_UPLOAD && item.commentFor === "2" ? "inherit" : 'inherit',
                        //  textAlign:candidateType === NEW_CANDIDATE_UPLOAD && item.commentFor === "2" ? "left" : 'right' 
                      }} >
                      {item.Comments}
                    </div>
                  </div>
                ))
              }
              {
                commentsList.length === 0 && <span style={{ margin: ' 0px 0px 0px 0px', padding: '0px 8px 0px 8px' }}>No Previous Comment</span>
              }
            </DialogContent>
            <DialogActions>
              <div className='col-sm-12 col-xs-12 col-lg-12 col-md-12'>
                <div className='col-sm-12 col-xs-12 col-lg-12 col-md-12'
                  style={{ margin: ' 0px 0px 0px 0px', padding: '0px 15px 0px 15px' }}
                >
                  <form id="addCommentsFormInfo" method="post">
                    <div style={{ paddingTop: 5 }}
                    // ref={(el) => { this.messagesEnd = el; }}
                    >
                      <TextBoxComponent
                        // focus={true}
                        id="Comments"
                        style={{ background: 'f2f2f2' }}
                        placeholder="Comments *"
                        ref={(scope) => {
                          this.textareaObj = scope;
                        }}
                        floatLabelType="Auto"
                        multiline
                        value={comments}
                        data-msg-containerid="commentsErr"
                        name="commentsErr"
                        cssClass="e-outline"
                        blur={(e) => this.setComments(e)}
                      />
                      <div
                        id="commentsErr"
                        className="e-input-group e-float-icon-left error-div"
                      />
                    </div>
                  </form>
                </div>
                <div className='col-sm-12 col-xs-12 col-lg-12 col-md-12'
                  style={{ margin: ' 0px 0px 0px 0px', padding: '0px 15px 0px 15px' }}
                >
                  {showComments && (
                    <Button
                      // color="primary"
                      variant="contained"
                      style={{ color: "white", backgroundColor: '#4B4B55', marginRight: 0, float: 'right' }}
                      onClick={this.handleYes}
                    >
                                Add Comment
                    </Button>
                  )}
                </div>
              </div>

              {/* <Button
                onClick={this.handleClose}
                color="default"
                variant="contained"
              >
                Close
              </Button> */}
            </DialogActions>
          </ErrorBoundary>
        </Dialog>
      </>
    );
  }
}

Comments.propTypes = {
  selectedFolderId: PropTypes.number.isRequired,
  showComments: PropTypes.bool.isRequired,
  authUser: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  candidateType: PropTypes.string.isRequired,
  updateDocumentInfo: PropTypes.func,
  // eslint-disable-next-line
  // addDocumentComments: PropTypes.func,
  openDocumentComments: PropTypes.bool.isRequired,
  candidateid: PropTypes.number,
  // refreshDocStatus: PropTypes.string.isRequired,
  // eslint-disable-next-line
  refreshDocComments: PropTypes.string,
  categoryName: PropTypes.string.isRequired,
  commentFor: PropTypes.string.isRequired,
};
Comments.defaultProps = {
  candidateid: 0,
  refreshDocComments: "",
  updateDocumentInfo: {},
  // addDocumentComments: {},
};

const mapStateToProps = ({ onboardingreducer, auth }) => {
  const { authUser } = auth;
  const {
    openDocumentComments,
    candidateid,
    refreshDocStatus,
    refreshDocComments,
  } = onboardingreducer;
  return {
    authUser,
    openDocumentComments,
    candidateid,
    refreshDocStatus,
    refreshDocComments,
  };
};

export default connect(mapStateToProps, {
  updateDocumentInfo,
  addDocumentComments,
})(Comments);
