import React from "react";
import PropTypes from "prop-types";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";

/**
 * Represents the React TextBox Component
 * ```html
 * <TextBox value={value}></TextBox>
 * ```
 */
const TextBox = (props) => {
    const {
        dataSource, id, cssClass,  value,floatLabelType,
    } = props

    /**
    * Triggers when the content of TextBox has changed and gets focus-out.
    * @event change
    */
    const handleChange = (e) => {
        props.change(e);
    }


    /**
     * Triggers each time when the value of TextBox has changed.
     * @event input
     */
    const handleInput = (e) => {
        props.input(e);
    }

    return (
        <div className="common-textbox">
            <TextBoxComponent
                input={handleInput}
                floatLabelType={floatLabelType}
                dataSource={dataSource}
                value={value}
                onChange={handleChange}
                name={`${id}Err`}
                data-msg-containerid={`${id}Err`}
                cssClass={cssClass}
                {...props}
            />
            <div id={`${id}Err`} className="e-input-group e-float-icon-left error-div" />
            {/* <div
                id={`${id}Count`}
                style={{ float: "right", fontSize: 12, margin: "0px 15px 8px 0px" }}
                className="error-div"
            /> */}
        </div>
    );
};

TextBox.propTypes = {
    dataSource: PropTypes.array,
    id: PropTypes.string.isRequired,
    cssClass: PropTypes.string.isRequired,
    floatLabelType: PropTypes.string,
    onChange: PropTypes.func,
    input: PropTypes.func,
};

TextBox.defaultProps = {
    floatLabelType: "Auto",
    change: () => { },//NOSONAR
    input: () => { },//NOSONAR
};

export default TextBox;
