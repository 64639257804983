import {
  MEETING_SAVE,
  MEETING_SAVED,
  MEETING_BOOK_UPDATE_STATE,
  MEETING_CREATE,
  MEETING_GET_DETAILS,
  MEETING_ACCEPTANCE,
  MEETING_CANCEL,
  MEETING_SAVE_ERROR,
  SAVE_MEETING_TEMPLATE,
  EC_REPORT_USERS,
} from "../constant/meetingbookingconstant";

export const saveMeeting = (data) => {
  return {
    type: MEETING_SAVE,
    payload: data,
  };
};
export const meetingSaved = (data) => {
  return {
    type: MEETING_SAVED,
    payload: data,
  };
};
export const meetingSaveError = () => {
  return {
    type: MEETING_SAVE_ERROR,
  };
};

export const updateMeetingBookingState = (data) => {  
  return {
    type: MEETING_BOOK_UPDATE_STATE,
    payload: data,
  };
};
export const createMeeting = (data) => {
  return {
    type: MEETING_CREATE,
    payload: data,
  };
};

export const cancelMeeting = (data) => {
  return {
    type: MEETING_CANCEL,
    payload: data,
  };
};

export const getMeetingDetails = (data) => {
  return {
    type: MEETING_GET_DETAILS,
    payload: data,
  };
};
export const meetingAcceptance = (data) => {
  return {
    type: MEETING_ACCEPTANCE,
    payload: data,
  };
};
export const saveMeetingTemplate = (data) => {
  return {
    type: SAVE_MEETING_TEMPLATE,
    payload: data,
  };
};
export const ecReportUsers = (data) => {
  return {
    type: EC_REPORT_USERS,
    payload: data,
  };
};
