import React from "react";
import PropTypes from "prop-types";
import { SwitchComponent } from "@syncfusion/ej2-react-buttons";
import LabelComponent from "./lablecomponent";


/**
 * The Switch component allows the user to type a value or choose an option from the list of predefined options.
 *
 * @example
 * <Switch dataSource={data}/>
 */
const Switch = (props) => {
    const { title, id, checked, cssClass, onChange } = props

    const isFourteenHundred = window.innerHeight < 782 && window.innerWidth < 1441;

    /**
     * Triggers when an item in a popup is selected or when the model value is changed by user.
     * @event change
     */
    const onChangeFn = (e) => {
        onChange(e);
    }


    return (
        <>
            <div className="pb-xs">
                <LabelComponent content={title} color={"brand02-2"} fontSize={isFourteenHundred ? "sm" : "md"} isBold={false} />
            </div>
            <div className="common-switch-heading common-switch-inline">
                <div className="common-switch-margin">
                    <div style={{display:"flex"}}>
                    <SwitchComponent
                        cssClass={`common-switch-height ${cssClass}`}
                        change={onChangeFn}
                        name={`${id}Error`}
                        checked={checked}
                        {...props}
                    />
                  
                     <span style={{marginLeft:"10px",fontWeight:"500",fontSize:"0.875rem"}}>{checked ? "Yes" : "No"}</span>
                     </div>
                </div>
            </div>
        </>
    );
};

Switch.propTypes = {
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    cssClass: PropTypes.string.isRequired,
    checked: PropTypes.bool.isRequired,
    onChange: PropTypes.func,
};

Switch.defaultProps = {
    onChange: () => { },//NOSONAR
};

export default Switch;
