import React, { Component } from "react";
import {
  TextBoxComponent,
  NumericTextBoxComponent,
} from "@syncfusion/ej2-react-inputs";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { addEditHomeOfficeDetails } from "../../redux/actions/homeoffice/homeofficeaction";
import { Button } from "@material-ui/core";
import { urlConfig } from "../../config/config";
import { getNotify } from "../../app/other/commonfunction";
import { SUCCESS } from "../../assets/constants/constants";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import TextField from "@material-ui/core/TextField";
import DateFnsUtils from "@date-io/date-fns";
import { CheckBoxComponent } from "@syncfusion/ej2-react-buttons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faDownload,
} from "@fortawesome/free-solid-svg-icons";
library.add(
  faDownload
);
class ElectricitySupply extends Component {
  constructor(props) {
    super(props);
    this.fileInputRef = React.createRef();
    this.uploadElectricityBill = this.uploadElectricityBill.bind(this);
  }
  componentDidMount() {
    const { homeOfficeList } = this.props;
    if (homeOfficeList !== undefined && homeOfficeList.length > 0) {
      const { uninterElectricitySupply, generatorBackup, upsAvailability, inverterAvailability, electricityBillDate, billAmount,
        upsBrand, upsModel, upsCapacity, upsBackupDuration, inverterBrand, inverterModel, inverterCapacity, inverterBackupDuration, billfilePath } = homeOfficeList[0];
      this.props.addEditHomeOfficeDetails({
        uninterruptedElectricitySupply: uninterElectricitySupply === '1' ? true : uninterElectricitySupply === '0'? false:null,
        homeOfficeBackup: generatorBackup === '1' ? true : generatorBackup === '0' ? false: null,
        ups: upsAvailability === '1' ? true : upsAvailability === '0' ? false : null,
        invertor: inverterAvailability === '1' ? true : inverterAvailability === '0' ? false : null,
        billDate: electricityBillDate !== null ? Date.parse(new Date(electricityBillDate)) / 1000 : null, // new Date(electricityBillDate)/1000,
        billAmount: billAmount,
        upsBrand: upsBrand,
        upsModel: upsModel,
        upsVACapacity: upsCapacity,
        upsDuration: upsBackupDuration,
        invertorBrand: inverterBrand,
        invertorModel: inverterModel,
        invertorKVACapacity: inverterCapacity,
        invertorDuration: inverterBackupDuration,
        electricityBillFlag: electricityBillDate === null || electricityBillDate === undefined  ? false : true,
        billFilePath: billfilePath
      })
    }
  }
  addBillAmount = (e) => {
    this.props.addEditHomeOfficeDetails({ billAmount: parseInt(e.value) })
  }
  getBillDate = (e) => {
    this.props.addEditHomeOfficeDetails({ billDate: Math.floor(Date.UTC(e.getFullYear(), e.getMonth(), e.getDate())) / 1000 })
  }
  openFileDialog = () => {
    this.fileInputRef.current.click();
  };
  uploadElectricityBill = (evt, type) => {
    const { authUser } = this.props;
    try {

      if (typeof evt.target.files !== 'undefined') {
        if (evt.target.files.length > 0 && evt.target.files[0] !== undefined) {
          const currentDate = Date.parse(new Date()) / 1000;
          const fileObj = evt.target.files[0];
          const fileNm = `${currentDate.toString()}_${fileObj.name}`;
          if (type === "file1") {
            // eslint-disable-next-line
            this.props.addEditHomeOfficeDetails({ billFilePath: fileNm });
          }
          try {
            const req = new XMLHttpRequest();
            const formData = new FormData();
            formData.append("file", fileObj, fileNm);
            req.open(
              "POST",
              `${urlConfig.apiurl}uploadinternetspeedtestdoctm/${authUser}`
            );
            req.setRequestHeader("Authorization", localStorage.getItem("jwtToken"));
            req.send(formData);
            getNotify(SUCCESS, "Attachment upload successfully");
          } catch (error) {
            console.log('error', error);
          }
        }
      }


    } catch (e) {
      console.log(e);
    }
  };
  TextFieldComponent = (props) => {
    return <TextField {...props} color="primary" disabled />;
  };
  render() { // NOSONAR
    const {
      uninterruptedElectricitySupply,
      homeOfficeBackup,
      ups,
      invertor,
      billDate,
      billAmount,
      billFilePath,
      electricityBillFlag,
      upsBrand,
      upsModel,
      upsVACapacity,
      upsDuration,
      invertorBrand,
      invertorModel,
      invertorKVACapacity,
      invertorDuration,
    } = this.props;

    return (
      <div>
        <div className="accordian-header-padding-left">
          {" "}
          ELECTRICITY SUPPLY *
        </div>
        <div className="control-section col-md-12">
          <div
            className="row "
            style={{
              backgroundColor: "#F4F3F5",
              paddingLeft: -10,
              paddingRight: -10,
              paddingTop: 10,
              paddingBottom: 10,
            }}
          >
            <div
              style={{
                paddingTop: 5,
                paddingBottom: 5,
                color: "#000",
                paddingLeft: 30,
                paddingRight: 10,
              }}
              className="col-xs-7 col-sm-2 col-lg-6 col-md-2"
            >
              I have uninterrupted 24x7 power supply at my Home-Office address
              and powercuts are fewer than one per fornight, and less than four hours at a stretch
            </div>
            <div
              style={{ paddingTop: 5, marginLeft: '.7%' }}
              className="col-xs-4 col-sm-2 col-lg-4 col-md-2"
            >
              <div className="row">
                {/* eslint-disable-next-line */}
                <div
                  className={`col-xs-6 col-sm-2 col-lg-4 col-md-2 ${uninterruptedElectricitySupply === true
                    ? "button-yes"
                    : "button-unselect"
                    }`}
                  onClick={() => {
                    document.getElementById(
                      "uninterruptedElectricitySupply"
                    ).innerHTML = "";
                    // eslint-disable-next-line
                    this.props.addEditHomeOfficeDetails({
                      uninterruptedElectricitySupply: true,
                      homeOfficeBackup: null,
                      ups: null,
                      invertor: null,
                      billDate: null,
                      billAmount: 0,
                      billFilePath: "",
                      electricityBillFlag: false,
                      upsBrand: "",
                      upsModel: "",
                      upsVACapacity: 0,
                      upsDuration: 0,
                      invertorBrand: "",
                      invertorModel: "",
                      invertorKVACapacity: 0,
                      invertorDuration: 0,
                    });
                  }}
                >
                  YES
                </div>
                {/* eslint-disable-next-line */}
                <div
                  className={`col-xs-6 col-sm-2 col-lg-4 col-md-2 ${uninterruptedElectricitySupply === false
                    ? "button-no"
                    : "button-unselect"
                    }`}
                  onClick={() => {
                    document.getElementById(
                      "uninterruptedElectricitySupply"
                    ).innerHTML = "";
                    // eslint-disable-next-line
                    this.props.addEditHomeOfficeDetails({
                      uninterruptedElectricitySupply: false,
                    });
                  }}
                >
                  NO
                </div>
              </div>
            </div>
          </div>
          <div
            style={{ marginLeft: 15 }}
            id="uninterruptedElectricitySupply"
            className="e-input-group e-float-icon-left error-div"
          />
          {
            // uninterruptedElectricitySupply === false && 
            (
              <>
                <div style={{ pointerEvents: uninterruptedElectricitySupply === true ? 'none' : "", backgroundColor: uninterruptedElectricitySupply === true ? "#F4F3F5" : "" }}>
                  <div className="row1">
                    <div
                      style={{ paddingTop: 15, color: "#000" }}
                      className="col-xs-12 col-sm-2 col-lg-12 col-md-2"
                    >
                      <span style={{ color: "#000", fontWeight: 500 }}>
                        POWER BACKUP ARRANGEMENT AT MY HOME-OFFICE ADDRESS
                  </span>
                      <hr style={{ marginTop: 10, marginBottom: 10 }} />
                    </div>
                  </div>
                  <div className="row1">
                    <div
                      style={{ paddingTop: 5, paddingBottom: 12, color: "#6F6E74" }}
                      className="col-xs-8 col-sm-2 col-lg-6 col-md-2"
                    >
                      {/* eslint-disable-next-line */}
                  My home-office has a generator backup and the power supply does not blink during the switchover from one power source to another.
                </div>
                    <div className="col-xs-4 col-sm-2 col-lg-4 col-md-2">
                      <div className="row" style={{ paddingLeft: "2.25%" }}>
                        {/* eslint-disable-next-line */}
                        <div
                          className={`col-xs-6 col-sm-2 col-lg-4 col-md-2 ${homeOfficeBackup === true
                            ? "button-yes"
                            : "button-unselect"
                            }`}
                          onClick={() => {
                            document.getElementById("homeOfficeBackup").innerHTML =
                              "";
                            // eslint-disable-next-line
                            this.props.addEditHomeOfficeDetails({
                              homeOfficeBackup: true,
                            });
                          }}
                        >
                          YES
                    </div>
                        {/* eslint-disable-next-line */}
                        <div
                          className={`col-xs-6 col-sm-2 col-lg-4 col-md-2 ${homeOfficeBackup === false
                            ? "button-no"
                            : "button-unselect"
                            }`}
                          onClick={() => {
                            document.getElementById("homeOfficeBackup").innerHTML =
                              "";
                            // eslint-disable-next-line
                            this.props.addEditHomeOfficeDetails({
                              homeOfficeBackup: false,
                            });
                          }}
                        >
                          NO
                    </div>
                      </div>
                    </div>
                  </div>
                  <div
                    style={{ marginLeft: 15 }}
                    id="homeOfficeBackup"
                    className="e-input-group e-float-icon-left error-div"
                  />

                  {/* My home-Office has a generator backup does't blink during switchover from main supply */}
                  {/* Section I */}
                  {
                    // homeOfficeBackup === false && 
                    (
                      <div style={{ marginLeft: 15 }}>
                        {/* <div id="upsinvertor" className="e-input-group e-float-icon-left error-div"/> */}
                        <div
                          className="col-xs-2 col-sm-2 col-lg-12 col-md-1"
                          style={{ paddingLeft: 0 }}
                        >
                          {/* <div id="ups" className="e-input-group e-float-icon-left error-div"/> */}
                          <div className="electricity-supply-backup-header">
                            UPS
                    </div>
                          <div
                            className="col-xs-2 col-sm-2 col-lg-2"
                            style={{ width: "7.5%" }}
                          >
                            <div className="row">
                              {/* eslint-disable-next-line */}
                              <div
                                className={`col-xs-6 col-sm-2 col-lg-4 col-md-2 ${ups === true ? "button-yes" : "button-unselect"
                                  }`}
                                onClick={() => {
                                  document.getElementById("upsinvertor").innerHTML =
                                    "";
                                  // eslint-disable-next-line
                                  this.props.addEditHomeOfficeDetails({
                                    ups: true,
                                  });
                                }}
                              >
                                YES
                        </div>
                              {/* eslint-disable-next-line */}
                              <div
                                className={`col-xs-6 col-sm-2 col-lg-4 col-md-2 ${ups === false ? "button-no" : "button-unselect"
                                  }`}
                                onClick={() => {
                                  document.getElementById("upsinvertor").innerHTML =
                                    "";
                                  // eslint-disable-next-line
                                  this.props.addEditHomeOfficeDetails({
                                    ups: false,
                                  });
                                }}
                              >
                                NO
                        </div>
                            </div>
                            <div
                              id=""
                              className="e-input-group e-float-icon-left error-div"
                            />
                          </div>
                          {
                            // ups === true && 
                            (
                              <div style={{ padding: 0 }}>
                                <div
                                  className="col-xs-2 col-sm-2 col-lg-3 col-md-2"
                                  style={{ width: "22%" }}
                                >
                                  <TextBoxComponent
                                    allowCustom={false}
                                    floatLabelType="Auto"
                                    cssClass="e-outline"
                                    placeholder={ups === false ? "Brand" : "Brand *"}
                                    value={upsBrand}
                                    // eslint-disable-next-line
                                    onFocus={() => {
                                      try {
                                        if (document.getElementById("upsBrand").innerHTML !== null) {
                                          document.getElementById("upsBrand").innerHTML = ""
                                        }
                                      } catch (e) {

                                      }
                                    }
                                    }
                                    change={(e) => {
                                      try {
                                        if (document.getElementById("upsBrand").innerHTML !== null) {
                                          document.getElementById("upsBrand").innerHTML = ""
                                        }
                                      } catch (error) {

                                      }
                                      this.props.addEditHomeOfficeDetails({
                                        upsBrand: e.value,
                                      });
                                    }}
                                  />
                                  <div
                                    id="upsBrand"
                                    className="e-input-group e-float-icon-left error-div"
                                  />
                                </div>
                                <div
                                  className="col-xs-2 col-sm-2 col-lg-2 col-md-2"
                                  style={{ width: "22%" }}
                                >
                                  <TextBoxComponent
                                    allowCustom={false}
                                    floatLabelType="Auto"
                                    cssClass="e-outline"
                                    placeholder={ups === false ? "Model" : "Model *"}
                                    value={upsModel}
                                    // eslint-disable-next-line
                                    onFocus={() => {
                                      try {
                                        if (document.getElementById("upsModel").innerHTML !== null) {
                                          document.getElementById("upsModel").innerHTML = "";
                                        }
                                      } catch (e) {

                                      }
                                    }
                                    }
                                    change={(e) => {
                                      try {
                                        if (document.getElementById("upsModel").innerHTML !== null) {
                                          document.getElementById("upsModel").innerHTML = "";
                                        }
                                      } catch (error) {

                                      }
                                      // eslint-disable-next-line
                                      this.props.addEditHomeOfficeDetails({
                                        upsModel: e.value,
                                      });
                                    }}
                                  />
                                  <div
                                    id="upsModel"
                                    className="e-input-group e-float-icon-left error-div"
                                  />
                                </div>
                                <div
                                  className="col-xs-2 col-sm-2 col-lg-3 col-md-2"
                                  style={{ width: "22%" }}
                                >
                                  <NumericTextBoxComponent
                                    allowCustom={false}
                                    floatLabelType="Auto"
                                    cssClass="e-outline"
                                    placeholder={ups === false ? "VA Capacity" : "VA Capacity *"}
                                    min={0}
                                    value={upsVACapacity}
                                    // eslint-disable-next-line
                                    onFocus={() => {
                                      try {
                                        document.getElementById(
                                          "upsVACapacity"
                                        ).innerHTML = ""
                                      } catch (e) {

                                      }
                                    }}
                                    change={(e) => {
                                      try {
                                        document.getElementById(
                                          "upsVACapacity"
                                        ).innerHTML = "";
                                      } catch (error) {

                                      }
                                      // eslint-disable-next-line
                                      this.props.addEditHomeOfficeDetails({
                                        upsVACapacity: e.value,
                                      });
                                    }}
                                  />
                                  <div
                                    id="upsVACapacity"
                                    className="e-input-group e-float-icon-left error-div"
                                  />
                                </div>
                                <div
                                  className="col-xs-2 col-sm-2 col-lg-2 col-md-2"
                                  style={{ width: "22%" }}
                                >
                                  <NumericTextBoxComponent
                                    format='###.##'
                                    allowCustom={false}
                                    floatLabelType="Auto"
                                    cssClass="e-outline"
                                    placeholder={ups === false ? "Backup Duration in Mins" : "Backup Duration in Mins *"}
                                    min={0}
                                    step={15}
                                    value={upsDuration}
                                    // eslint-disable-next-line
                                    onFocus={() => {
                                      try {
                                        document.getElementById(
                                          "upsDuration"
                                        ).innerHTML = ""
                                      } catch (e) {

                                      }
                                    }}
                                    change={(e) => {
                                      try {
                                        document.getElementById("upsDuration").innerHTML =
                                          "";
                                      } catch (error) {

                                      }
                                      // eslint-disable-next-line
                                      this.props.addEditHomeOfficeDetails({
                                        upsDuration: e.value,
                                      });
                                    }}
                                  />
                                  <div
                                    id="upsDuration"
                                    className="e-input-group e-float-icon-left error-div"
                                  />
                                </div>
                              </div>
                            )}
                        </div>
                        {/* Section II */}
                        <div
                          className=" col-xs-2 col-sm-2 col-lg-12 col-md-1"
                          style={{ paddingLeft: 0 }}
                        >
                          {/* <div id="invertor" className="e-input-group e-float-icon-left error-div"/>            */}
                          <div className="electricity-supply-backup-header">
                            Invertor
                    </div>
                          <div
                            className="col-xs-2 col-sm-2 col-lg-2"
                            style={{ width: "7.5%" }}
                          >
                            {/* <div className='home-office-working-space-text'>Invertor</div> */}
                            <div className="row">
                              {/* eslint-disable-next-line */}
                              <div
                                className={`col-xs-6 col-sm-2 col-lg-4 col-md-2 ${invertor === true ? "button-yes" : "button-unselect"
                                  }`}
                                onClick={() => {
                                  document.getElementById("upsinvertor").innerHTML =
                                    "";
                                  // eslint-disable-next-line
                                  this.props.addEditHomeOfficeDetails({
                                    invertor: true,
                                  });
                                }}
                              >
                                YES
                        </div>
                              {/* eslint-disable-next-line */}
                              <div
                                className={`col-xs-6 col-sm-2 col-lg-4 col-md-2 ${invertor === false ? "button-no" : "button-unselect"
                                  }`}
                                onClick={() => {
                                  document.getElementById("upsinvertor").innerHTML =
                                    "";
                                  // eslint-disable-next-line
                                  this.props.addEditHomeOfficeDetails({
                                    invertor: false,
                                  });
                                }}
                              >
                                NO
                        </div>
                            </div>
                          </div>
                          {
                            // invertor === true && 
                            (
                              <div style={{ padding: 0 }}>
                                <div
                                  className="col-xs-2 col-sm-2 col-lg-3 col-md-2"
                                  style={{ width: "22%" }}
                                >
                                  <TextBoxComponent
                                    allowCustom={false}
                                    floatLabelType="Auto"
                                    cssClass="e-outline"
                                    placeholder={invertor === false ? "Brand" : "Brand *"}
                                    value={invertorBrand}
                                    // eslint-disable-next-line
                                    onFocus={() => {
                                      try {
                                        document.getElementById(
                                          "invertorBrand"
                                        ).innerHTML = ""
                                      } catch (e) {

                                      }
                                    }
                                    }
                                    change={(e) => {
                                      try {
                                        document.getElementById(
                                          "invertorBrand"
                                        ).innerHTML = "";
                                      } catch (error) {

                                      }
                                      // eslint-disable-next-line
                                      this.props.addEditHomeOfficeDetails({
                                        invertorBrand: e.value,
                                      });
                                    }}
                                  />
                                  <div
                                    id="invertorBrand"
                                    className="e-input-group e-float-icon-left error-div"
                                  />
                                </div>
                                <div
                                  className="col-xs-2 col-sm-2 col-lg-2 col-md-2"
                                  style={{ width: "22%" }}
                                >
                                  <TextBoxComponent
                                    allowCustom={false}
                                    floatLabelType="Auto"
                                    cssClass="e-outline"
                                    placeholder={invertor === false ? "Model" : "Model *"}
                                    value={invertorModel}
                                    // eslint-disable-next-line
                                    onFocus={() => {
                                      try {
                                        document.getElementById(
                                          "invertorModel"
                                        ).innerHTML = ""
                                      } catch (e) {

                                      }
                                    }}
                                    change={(e) => {
                                      try {
                                        document.getElementById(
                                          "invertorModel"
                                        ).innerHTML = "";
                                      } catch (error) {

                                      }
                                      // eslint-disable-next-line
                                      this.props.addEditHomeOfficeDetails({
                                        invertorModel: e.value,
                                      });
                                    }}
                                  />
                                  <div
                                    id="invertorModel"
                                    className="e-input-group e-float-icon-left error-div"
                                  />
                                </div>
                                <div
                                  className="col-xs-2 col-sm-2 col-lg-3 col-md-2"
                                  style={{ width: "22%" }}
                                >
                                  <NumericTextBoxComponent
                                    allowCustom={false}
                                    floatLabelType="Auto"
                                    cssClass="e-outline"
                                    placeholder={invertor === false ? "KVA Capacity" : "KVA Capacity *"}
                                    min={0}
                                    value={invertorKVACapacity}
                                    // eslint-disable-next-line
                                    onFocus={() => {
                                      try {
                                        document.getElementById(
                                          "invertorKVACapacity"
                                        ).innerHTML = ""
                                      } catch (e) {

                                      }
                                    }}
                                    change={(e) => {
                                      try {
                                        document.getElementById(
                                          "invertorKVACapacity"
                                        ).innerHTML = "";
                                      } catch (error) {

                                      }
                                      // eslint-disable-next-line
                                      this.props.addEditHomeOfficeDetails({
                                        invertorKVACapacity: e.value,
                                      });
                                    }}
                                  />
                                  <div
                                    id="invertorKVACapacity"
                                    className="e-input-group e-float-icon-left error-div"
                                  />
                                </div>
                                <div
                                  className="col-xs-2 col-sm-2 col-lg-2 col-md-2"
                                  style={{ width: "22%" }}
                                >
                                  <NumericTextBoxComponent
                                    format='###.##'
                                    allowCustom={false}
                                    floatLabelType="Auto"
                                    cssClass="e-outline"
                                    placeholder={invertor === false ? "Backup Duration in Hrs" : "Backup Duration in Hrs *"}
                                    min={0}
                                    value={invertorDuration}
                                    // eslint-disable-next-line
                                    onFocus={() => {
                                      try {
                                        document.getElementById(
                                          "invertorDuration"
                                        ).innerHTML = ""
                                      } catch (e) {

                                      }
                                    }}
                                    change={(e) => {
                                      try {
                                        document.getElementById(
                                          "invertorDuration"
                                        ).innerHTML = "";
                                      } catch (error) {

                                      }
                                      // eslint-disable-next-line
                                      this.props.addEditHomeOfficeDetails({
                                        invertorDuration: e.value,
                                      });
                                    }}
                                  />
                                  <div
                                    id="invertorDuration"
                                    className="e-input-group e-float-icon-left error-div"
                                  />
                                </div>
                              </div>
                            )}
                        </div>

                        <div
                          style={{ paddingTop: 5 }}
                          id="upsinvertor"
                          className="e-input-group e-float-icon-left error-div"
                        />
                      </div>
                    )}
                </div>
                {
                  // (ups === true || invertor === true) &&
                  <>
                    <div style={{ pointerEvents: uninterruptedElectricitySupply === true ? 'none' : "" }}>
                      <div style={{ paddingTop: 15, color: "#000", backgroundColor: uninterruptedElectricitySupply === true ? "#F4F3F5" : "" }} className="col-xs-12 col-sm-2 col-lg-12 col-md-2" >
                        <span style={{ color: "#000", fontWeight: 500 }}>
                          STAGGERED REIMBURSEMENT REQUEST
                  </span>
                        <hr style={{ marginTop: 10, marginBottom: 10 }} />
                      </div>
                      <div className="col-md-12" style={{ height: "40px", fontWeight: "bold", paddingLeft: "15px", marginTop: '-5px', backgroundColor: uninterruptedElectricitySupply === true ? "#F4F3F5" : "" }}>
                        <CheckBoxComponent
                          change={(e) => {
                            this.props.addEditHomeOfficeDetails({ electricityBillFlag: e.checked, billDate: null, billAmount: 0, billFilePath: "" });
                          }}
                          checked={electricityBillFlag}
                          label="I have newly procured and installed an electricity backup arrangement and would like the organisation to include me in the staggered reimbursement plan."
                        />
                      </div>
                      {
                        // electricityBillFlag === true &&
                        <div className="col-md-12" style={{ backgroundColor: uninterruptedElectricitySupply === true ? "#F4F3F5" : "" }}>
                          <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2">
                            <div>
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                  style={{ width: "100%" }}
                                  id="billDare"
                                  name="billDareErr"
                                  format="dd-MMM-yyyy"
                                  KeyboardButtonProps={{ "aria-label": "change date" }}
                                  value={billDate === null ? billDate : new Date(0).setUTCSeconds(billDate)}
                                  onChange={(e) => this.getBillDate(e)}
                                  TextFieldComponent={this.TextFieldComponent}
                                  autoOk
                                  variant="inline"
                                  inputVariant="outlined"
                                  label={electricityBillFlag === true ? "Bill Date *" : "Bill Date"}
                                  placeholder="dd-MMM-yyyy"
                                  InputLabelProps={{ shrink: true }}
                                  maxDate={new Date()}
                                />
                              </MuiPickersUtilsProvider>
                              <div
                                id="billDareErr"
                                className="e-input-group e-float-icon-left error-div"
                              />
                            </div>
                          </div>
                          <div className="col-xs-2 col-sm-2 col-lg-2 col-md-2">
                            <div>
                              <NumericTextBoxComponent
                                id="billAmount"
                                min={0}
                                data-msg-containerid="billAmountErr"
                                name="billAmountErr"
                                value={billAmount}
                                cssClass="e-outline"
                                floatLabelType="Auto"
                                placeholder={electricityBillFlag === true ? "Bill Amount *" : "Bill Amount"}
                                change={(e) => this.addBillAmount(e)}
                              />
                              <div
                                id="billAmountErr"
                                className="e-input-group e-float-icon-left error-div"
                              />
                            </div>
                          </div>
                          <div className="col-xs-8 col-sm-6 col-lg-4 col-md-8" style={{ padding: '0px' }}>
                            <div className="col-xs-4 col-sm-4 col-lg-3"
                            // style={{ marginLeft:"-28px" }}
                            >
                              <div
                                onClick={this.openFileDialog}
                                onKeyDown={this.openFileDialog}
                                role="button"
                              >
                                <input
                                  ref={this.fileInputRef}
                                  className="FileInput"
                                  style={{ display: "none" }}
                                  type="file"
                                  accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"
                                  onChange={(e) => this.uploadElectricityBill(e, "file1")}
                                />
                                {/* <TooltipComponent
                                  id="uploadBill"
                                  content="Upload screenshot of your bill, or scanned copy of your bill"
                                  cssClass="customtooltip"
                                  position="BottomCenter"
                                  isSticky={false}
                                  showTipPointer={false}
                                > */}
                                <Button
                                  style={{
                                    background: "#4b4b55",
                                    color: "white",
                                    height: "40px",
                                    borderRadius: "5px",
                                  }}
                                  title="Upload screenshot of your bill, or scanned copy of your bill"
                                  onClick={(e) => this.uploadElectricityBill(e, "file1")}
                                >
                                  Upload
                          </Button>
                                {/* </TooltipComponent> */}
                              </div>
                            </div>
                            <div className="col-xs-4 col-sm-4 col-lg-3 button-down-uplod"
                              style={{ paddingRight: "0px" }}
                            >
                              {/* <TooltipComponent
                                  // content="Download screenshot of your bill, or scanned copy of your bill"
                                  content="Click to download and view previous document"
                                  cssClass="customtooltip"
                                  position="BottomCenter"
                                  isSticky={false}
                                  showTipPointer={false}
                                > */}

                              {billFilePath !== "" && billFilePath !== null &&
                                <a style={{ color: "white", textDecoration: 'none' }} href={`${urlConfig.apiurl}getHomeOfficeDocumenttm/${billFilePath}`} download
                                  title="Click to download and view previous document">
                                  <Button
                                    style={{
                                      background: "#4b4b55",
                                      color: "white",
                                      height: "40px",
                                      borderRadius: "5px",
                                    }}
                                  >
                                    <span style={{ fontSize: 14, fontWeight: '100' }}>
                                      <FontAwesomeIcon
                                        className="fas  "
                                        icon={faDownload}
                                        style={{ color: "white", fontSize: 14 }}
                                      />
                                           &nbsp;View
                                        </span>
                                  </Button>
                                  {/* </TooltipComponent> */}
                                </a>
                              }
                            </div>
                          </div>
                        </div>
                      }
                    </div>
                  </>
                }
              </>
            )}

        </div>
      </div>
    );
  }
}
ElectricitySupply.propTypes = {
  uninterruptedElectricitySupply: PropTypes.bool,
  homeOfficeBackup: PropTypes.bool,
  ups: PropTypes.bool,
  invertor: PropTypes.bool,
  // eslint-disable-next-line
  upsBrand: PropTypes.string,
  // eslint-disable-next-line
  upsModel: PropTypes.string,
  // eslint-disable-next-line
  upsVACapacity: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  // eslint-disable-next-line
  upsDuration: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  // eslint-disable-next-line
  invertorBrand: PropTypes.string,
  // eslint-disable-next-line
  invertorModel: PropTypes.string,
  // eslint-disable-next-line
  invertorKVACapacity: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  // eslint-disable-next-line
  invertorDuration: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  billDate: PropTypes.number,
  billAmount: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  billFilePath: PropTypes.string,
  electricityBillFlag: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool
  ]),
};
ElectricitySupply.defaultProps = {
  uninterruptedElectricitySupply: null,
  homeOfficeBackup: null,
  ups: null,
  invertor: null,
  upsBrand: "",
  upsModel: "",
  upsVACapacity: "",
  upsDuration: 0,
  invertorBrand: "",
  invertorModel: "",
  invertorKVACapacity: "",
  invertorDuration: 0,
  billDate: null,
  billAmount: 0,
  billFilePath: "",
  electricityBillFlag: 0
};
const mapStateToProps = ({ homeoffice, auth }) => {
  const {
    uninterruptedElectricitySupply,
    homeOfficeBackup,
    ups,
    invertor,
    upsBrand,
    upsModel,
    upsVACapacity,
    upsDuration,
    invertorBrand,
    invertorModel,
    invertorKVACapacity,
    invertorDuration,
    billDate,
    billAmount,
    billFilePath,
    electricityBillFlag
  } = homeoffice;
  const {authUser }=auth;
  return {
    uninterruptedElectricitySupply,
    homeOfficeBackup,
    ups,
    invertor,
    upsBrand,
    upsModel,
    upsVACapacity,
    upsDuration,
    invertorBrand,
    invertorModel,
    invertorKVACapacity,
    invertorDuration,
    billDate,
    billAmount,
    billFilePath,
    electricityBillFlag,
    authUser
  };
};
export default connect(mapStateToProps, {
  addEditHomeOfficeDetails,
})(ElectricitySupply);
