import React, { useState } from 'react'
import EmailMappingAdminGrid from '../../../../components/emailmappingadmincomponents';
import ButtonComponent from '../../../../utilities/buttoncomponent';
import { EMAIL_MAPPING_MASTER } from '../../../../redux/constant/meetingcategoryconstant';
import { handlePopup } from '../../../../redux/actions/admin/adminaction';
import { connect } from 'react-redux';
import Emailmappingpopupcontainer from './emailmappingpopupcontainer';
import './emailMapping.css';
import { NotificationContainer } from 'react-notifications';

const EmailMappingAdminContainer = ({ masterId, openPopup, ...props }) => {
  const [state, setState] = useState({ itemId: 0, masterId: masterId });
  const getGridColumnName = () => {
    if (masterId == EMAIL_MAPPING_MASTER) {
      return "emailMappingMaster"
    }
  }
  const changePopUpHandler = ({ type = "close", editItemId = null }) => {
    setState(prevState => ({
      ...prevState,
      editItemId
    }));
    props.handlePopup(type)
  }
  return (
    <>
      <NotificationContainer />
      <div
        id='helpdesk-admin-grid-top'
      >
        <ButtonComponent
          id="helpdesk-admin-add-btn"
          type="add"
          content="Add"
          click={() => { changePopUpHandler({ type: 'add' }) }}
        />
      </div>
      <EmailMappingAdminGrid
        masterId={masterId}
        gridName={getGridColumnName()}
        changePopUpHandler={changePopUpHandler}
      />
      {(openPopup === "add" || openPopup === "edit") &&
        <Emailmappingpopupcontainer
          changePopUpHandler={changePopUpHandler}
          state={state}
        />
      }
    </>
  )
}
const mapStateToProps = ({ adminreducer }) => {
  const { openPopup } = adminreducer;
  return {
    openPopup
  }

}
export default connect(mapStateToProps, { handlePopup })(EmailMappingAdminContainer)