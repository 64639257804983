/**
 * Author: Vivek Khodade.
 * Created:11-May-2020
 * Last Modify:11-May-2020
 * Modified By:Vivek Khodade
 */
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { GENERATE_CONFIRMATION_LETTER } from "../../constant/entryexit/confirmationconstant";
import { editUpdateConfirmation } from "../../actions/entryexit/confirmationaction";
import { urlConfig } from "../../../config/config";
import { getNotify } from "../../../app/other/commonfunction";
import { ERROR, SUCCESS } from "../../../assets/constants/constants";
// Author :- Vivek Khodade
const generateConfirmationLetterDetailsRequest = async (data) => {
  const { empId, userId } = data;
  // eslint-disable-next-line
  delete data.empId;
  // eslint-disable-next-line
  delete data.userId;
  return axios
    .post(`${urlConfig.tmlcApiUrl}employee/confirm/${empId}/${userId}`, data, {
      headers: {
        authorization: localStorage.getItem("jwtToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((result) => result)
    .catch((error) => error.response);  
};

// Author :- Vivek Khodade
function* generateConfirmationLetterDetails({ payload }) {
  const confirmationData = yield call(
    generateConfirmationLetterDetailsRequest,
    payload
  );
  if (confirmationData.status === 200) {
    setTimeout(() => {
      if(payload.type==='Probation'){
        getNotify(SUCCESS, "Probation period extended successfully");
      }else{
        getNotify(SUCCESS, "Confirmation letter generated successfully");
      }
    }, 100);
    yield put(editUpdateConfirmation({ status: "updated" }));
  } else {
    getNotify(ERROR, confirmationData.data.message.replace("payload: ", ""));
  }
}

/**
 * Function Name: generateConfirmationLetter
 * Sub-Functons: generateConfirmationLetterDetails, generateConfirmationLetterDetailsRequest
 * Description: Generate the confirmation letter for due confirmation employee.
 * Author: Vivek Khodade.
 */
export function* generateConfirmationLetter() {
  yield takeEvery(
    GENERATE_CONFIRMATION_LETTER,
    generateConfirmationLetterDetails
  );
}

export default function* rootSaga() {
  yield all([fork(generateConfirmationLetter)]);
}
