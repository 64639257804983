import React, { useRef } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './component.css'
import { faMagnifyingGlass } from "@fortawesome/pro-solid-svg-icons";

/**
 * Represents the React TextBox Component
 * ```html
 * <TextBox value={value}></TextBox>
 * ```
 */
/**
 * Author: Md Jahir Uddin
 * This is a React component for a search box with a FontAwesome search icon and customizable
 * placeholder, onChange function, value, id, and CSS class.
 * @returns A functional component named `SearchBox` is being returned. It renders a search box with a
 * search icon and an input field. The component accepts props such as `placeholder`, `onChange`,
 * `value`, `id`, and `cssClass`. The `placeholder` prop is used to set the placeholder text for the
 * input field. The `onChange` prop is used to handle the change event of the
 */
const SearchBox = (props) => {
    const {
        placeholder, onChange, value, id, cssClass
    } = props

    document.querySelector('#search-box-input')?.addEventListener('focus', () => {
        if (document.querySelector('.common-search-box')) {
            document.querySelector('.common-search-box').classList.add('focused');
        }
    })

    document.querySelector('#search-box-input')?.addEventListener('blur', () => {
        if (document.querySelector('.common-search-box')) {
            document.querySelector('.common-search-box').classList.remove('focused');
        }
    })

    return (
        <span className={'mr-md common-search-box '+cssClass} id={id}>
            <FontAwesomeIcon
                icon={faMagnifyingGlass}
                style={{ marginRight: '0.3125rem', color: '#98989e' }}
                size="xl"
            />
            <input type="text" id='search-box-input' placeholder={placeholder} onChange={onChange} value={value}/>
        </span>
    );
};

SearchBox.propTypes = {
    id: PropTypes.string.isRequired,
    cssClass: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    value: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired
};

SearchBox.defaultProps = {
    floatLabelType: "Auto",
    onChange: () => { },//NOSONAR
    cssClass: "",
    value: "",
    id:""
};

export default SearchBox;
