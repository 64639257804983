import React, { useState, useEffect } from 'react'
import { connect } from "react-redux";
import AdminModule from '../../../components/adminmodule/adminmodule'
import MasterModules from '../../../components/adminmodule/mastermodules'
import AdminBedcrum from '../../../components/adminmodule/adminbedcrum'
import queryString from "query-string";
import { dencrypt } from '../../other/commonfunction';
import AdminServices from "../../../services/adminservices";
import LnAAdminLayout from "../../../app/layout/lna/setuplayout";
import TmlcSetupLayout from "../../layout/tmlclayout/tmlcsetuplayout";
import OKRAdminLayout from '../../layout/okrlayout/okrsetuplayout';
import HelpdeskAdminSetup from '../../layout/helpdesk/helpdeskadminsetup';


function AdminContainer(props) {
  const [adminModuleId, setAdminModuleId] = useState(0)
  const [masterId, setMasterId] = useState(0)
  const [bedcrums, setBedcrums] = useState([])

  useEffect(() => {
    updateAdminModuleId()
  }, [])

  useEffect(() => {
    updateAdminModuleId()
  }, [props.location.search])

  const updateAdminModuleId = () => {
    try {
      let updatedAdminId = 0;
      let updatedMasterId = 0;
      const params = queryString.parse(props.location.search);


      if (typeof params.adminId !== 'undefined' && params.adminId !== null) {
        updatedAdminId = dencrypt(params.adminId)
        if (typeof params.masterId !== 'undefined' && params.masterId !== null) {
          updatedMasterId = (dencrypt(params.masterId))

        }
      }
      setAdminModuleId(updatedAdminId)
      setMasterId(updatedMasterId)

      if (updatedAdminId > 0 || updatedMasterId > 0) {
        getBedCrumData(updatedAdminId, updatedMasterId);
      } else {
        setBedcrums([])
      }
    } catch (error) {
      console.log("🚀 ~ file: admincontainer.jsx ~ line 24 ~ updateAdminModuleId ~ error", error)
    }
  }

  const getBedCrumData = (adminId, updatedMasterId) => {
    const { authUser } = props;
    const result = Promise.resolve(
      AdminServices.getMasterAdminModuleData({ userId: authUser, type: "BedCrum", id: adminId, masterId: updatedMasterId })
    );
    result.then((elem) => {//NOSONAR
      if (elem !== null) {
        setBedcrums(elem.data)
      }
    })
      .catch((error) => console.error(error));
  };

  const renderSwitch = () => {
    switch (adminModuleId) {
      case 1: // Leave and Attendance
        return <LnAAdminLayout />;
      case 2: // OKR Admin Module
        return <OKRAdminLayout />
      case 3: // TMLC
        return <TmlcSetupLayout />
      case 6: // Onboarding
        return <HelpdeskAdminSetup />
    }
  }

  return (
    <div style={{ height: "100vh", backgroundColor: '#f5f5f5' }}>
      <AdminBedcrum bedcrums={bedcrums} />
      {masterId > 0 ? renderSwitch() :
        <>
          {adminModuleId > 0 ? <MasterModules adminModuleId={adminModuleId} /> : <AdminModule />}
        </>
      }
    </div>
  )
}


const mapStateToProps = ({ auth, router }) => {
  const { authUser } = auth;
  const { location } = router;
  return { authUser, location };
};

export default connect(mapStateToProps, {
})(AdminContainer);
