import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes, { object } from "prop-types";
import { TabComponent } from '@syncfusion/ej2-react-navigations';

import OKRServices from "../../services/okr/okrservices";
import "./oldproductiveallocation.css";
import ObjectiveKpiCommon from "../centralokr/objectivekpicommon";
import { setCentralOkrKPIRedux, setUserOKRObjectiveKPI, addUpdateObjectveKpi, } from "../../redux/actions/okr/okractions";
import { addUpdateOkrRatingRedux } from "../../redux/actions/okrrating/okrratingaction";
import infoIcon from "../../../src/assets/svg/infoIcon.svg";
import ProgressBar from '../progressbar/progressbar';
import OKRRatingServices from '../../services/okrrating/okrratingservices';
import ReviewKR from '../okrratingcomponents/reviewkr';
import AddRatingModalkr from '../okrratingcomponents/addratingmodalkr'
import ViewRatingModalKr from '../okrratingcomponents/viewratingmodalkr'
import PastRatingModal from '../okrratingcomponents/pastratingmodal'
import AddEditInterimReviewModal from '../okrratingcomponents/addeditinterimreviewmodal'
import ViewkrInterimFeedbackModel from '../okrratingcomponents/viewkrinterimfeedbackmodel'
import InterimFeedBack from '../okrratingcomponents/interimfeedback';
import ViewkrModel from '../okrratingcomponents/viewkrmodal'
import ClosedTabObjHeader from '../okrproductiveallocation/closedtabobjheader';

class OkrLeadership extends Component {
  constructor(props) {
    super(props);
    this.state = {
      masterKPIList: { Result: [{}] },
      addObjectiveArray: [""],
      okrLeadershipPopup: false,
      height: (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight) - 188,
      leadershipOKRData: [],
      expandAllEditObjective: false,
      popupExpandColapse: false,
      importCount: 0,
      changeData: true,
      isLoading: true,
      viewKrRatingPopup: false,
      KRData: [],
      viewLpRatingPopup: false,
      viewReviewKrPopup: false,
      KRReviewData: [],
      type: "",
      pastRatingPopup: false,
      pastRatingKRData: [],
      addEditInterimReviewPopup: false,
      viewKrInterimFeedbackPopup: false,
      objId: null,
      viewInterimReviewPopup: false,
      objectiveName: '',
      selectedType: '',
      // centralOkrOpened:true,
      mounted: true
    };
    this.currentTab = 'draftTab';
    this.data = null;
    this.tabObj = new TabComponent({})
    this.submitButtonClass = 'button-submit-disable';
    this.updateDimensions = this.updateDimensions.bind(this);
    this.mounted = true
  }

  componentDidMount = async() => {

    this.updateDimensions();
    await this.getLeadershipOKRAllocation();
    // await this.getDashboardReviewData();
    window.addEventListener("resize", this.updateDimensions.bind(this));
    // this.loaded=true
  }

  componentDidUpdate() {
    const { draftData, activeData } = this.props

    // console.log(this.props.enableAction , this.mounted , !this.state.isLoading)
    if (this.props.enableAction && this.mounted && !this.state.isLoading) {
      this.props.setCentralOkrKPIRedux({ activeTabFlag: false });
      if (this.tabObj !== undefined && activeData.length > 0) {
        this.tabObj.selectedItem = 1;
      }
      else if (this.tabObj !== undefined && draftData.length > 0 && activeData.length === 0) {
        this.tabObj.selectedItem = 0;
      }
      else {
        this.tabObj.selectedItem = 2;
      }
    }
  }


  componentWillUnmount() {

    this.props.setCentralOkrKPIRedux({ leadershipOKRData: [], leadershiKPITemp: [], draftData: [], activeData: [] });
  }

  getDashboardReviewData = () => {
    const { employeeId, fyid, authUser, activeData, draftData } = this.props;
    try {
      const okrRatingDashboardData = Promise.resolve(
        OKRRatingServices.getReviewDashboardData(authUser, employeeId, fyid, '0', '3', "OKR")
      );
      okrRatingDashboardData.then((resultData) => {
        this.props.setCentralOkrKPIRedux({ closedLPOkr: resultData })
        setTimeout(()=>{

          if(this.tabObj !== undefined && this.tabObj !== null){
              if(authUser === employeeId){
                if(activeData.length === 0 && resultData.length>0){
                  this.tabObj.selectedItem = 1;
                }else{
                  this.tabObj.selectedItem = 0;
                }
              }else{
                if(activeData.length === 0 && draftData.length === 0 && resultData.length>0){
                  this.tabObj.selectedItem = 2;
                }
                else if(activeData.length === 0 && draftData.length > 0){
                  this.tabObj.selectedItem = 0;
                }else{
                  this.tabObj.selectedItem = 1;
                }
              }
          }

        },1000)
      });
    } catch (err) {
      console.log("OKRRatingServices", err)
    }
  }
  getLeadershipOKRAllocation() {
    try {
      this.setState({ isLoading: true })
      const { authUser, fyid, employeeId } = this.props;
      const leadershipOKR = Promise.resolve(
        OKRServices.getUserWiseProductivityAllocation(authUser, employeeId, "3", 0, fyid)
      );
      leadershipOKR.then(async(result) => {
        if (result !== null) {
         await this.getUserMapObjKpiData();
          setTimeout(() => {
            this.setState({ leadershipOKRData: result[0] });
          }, 2000);
        } else {
          this.data = "error";
        }
      });
    } catch (e) {
      // eslint-disable-next-line
      console.log(e);
    }
  }

  getUserMapObjKpiData = () => {
    const { authUser, employeeId, enableAction, fyid } = this.props;
    try {
      this.getDashboardReviewData();
      const getUserMapData = Promise.resolve(
        OKRServices.getUserMapObjectiveKpiList(authUser, employeeId, "3", fyid)
      );
      getUserMapData.then((resultData) => {
        if (resultData !== null) {
          if (resultData.Result.length > 0) {
            let modifiedLeadershipValueOkrData = [];
            let kpisData = resultData.Result[0];
            kpisData.kpis.forEach((ech) => {
              modifiedLeadershipValueOkrData.push(ech);
            });
            let leaderShipOkrDataDraft = [];
            leaderShipOkrDataDraft = modifiedLeadershipValueOkrData.filter((e) => { return e.userKpiId && e.isKpiDraft && e.isKpiDraft === '1'; });
            let totalWeightDraft = leaderShipOkrDataDraft.reduce(function (prev, cur) { return prev + cur.weightage; }, 0);
            let leaderShipOkrDataActive = [];
            leaderShipOkrDataActive = modifiedLeadershipValueOkrData.filter((e) => { return e.userKpiId && e.isKpiDraft && e.isKpiDraft === '0'; });
            let totalWeightActive = leaderShipOkrDataActive.reduce(function (prev, cur) { return prev + cur.weightage; }, 0);
            var currentTabName = 'draftTab';
            if (leaderShipOkrDataActive.length > 0 || !enableAction) currentTabName = 'activeTab';
            // eslint-disable-next-line
            this.props.setCentralOkrKPIRedux({
              leaderShipOkrData: modifiedLeadershipValueOkrData,
              draftData: leaderShipOkrDataDraft,
              draftWeight: totalWeightDraft,
              activeData: leaderShipOkrDataActive,
              activeWeight: totalWeightActive,
              reRenderComponent: currentTabName,
            });
            setTimeout(() => {
              this.setState({ changeData: !this.state.changeData, isLoading: false });
            }, 10)
          }else{
            setTimeout(() => {
              this.setState({ changeData: !this.state.changeData, isLoading: false });
            }, 10)
          }
        }
      });
    } catch (e) {
      // eslint-disable-next-line
      this.setState({ changeData: !this.state.changeData, isLoading: false });
    }
  };

  // eslint-disable-next-line
  UNSAFE_componentWillReceiveProps = (newProps) => {
    window.addEventListener("resize", this.updateDimensions);
    const { leaderShipOkrMasterList, employeeId } = this.props;
    if (newProps.employeeId !== employeeId) {
      this.props.setCentralOkrKPIRedux({ leadershipOKRData: [], leadershiKPITemp: [], draftData: [], activeData: [] });
      this.setState({ changeData: !this.state.changeData });
      setTimeout(() => {
        this.getLeadershipOKRAllocation();
      }, 1);
    } else if (newProps.reRenderComponent === "updatedLP-main") {
      // eslint-disable-next-line
      this.props.addUpdateObjectveKpi({
        leaderShipOkrMasterList,
        addUpdateObjectveKpi: "",
        reRenderComponent: "",
      });
    } else if (newProps.userObjectStatus === "SavedAsDraft3") {
      // eslint-disable-next-line
      this.props.setCentralOkrKPIRedux({ userObjectStatus: "closepopup" });
      this.getLeadershipOKRAllocation();
    } else if (newProps.userObjectStatus === "Saved3") {
      // eslint-disable-next-line
      this.props.setCentralOkrKPIRedux({ userObjectStatus: "" });
      const { leadershipOKRData } = this.state;
      const mLeadershipOKRData = leadershipOKRData;
      mLeadershipOKRData.objectiveSubmitted = "Y";
      this.setState({ leadershipOKRData: mLeadershipOKRData });
    } else if (newProps.reRenderComponent === "updated") {
      this.props.setCentralOkrKPIRedux({ reRenderComponent: "" });
      // eslint-disable-next-line
      this.setState({ changeData: !this.state.changeData });
      this.getLeadershipOKRAllocation();
    }
    // else if (newProps.reRenderComponent === "activeTab" || newProps.reRenderComponent === "draftTab") {
    //   this.currentTab = newProps.reRenderComponent;
    //   this.setState({ isLoading: false });
    // }
  };


  updateDimensions() {
    const dynHeight = window.innerHeight - 293;
    const updateheight = window.innerHeight - 260;
    this.setState({
      height: updateheight,
      dynamicHeight: dynHeight,
    });
  }
  viewKrReviewPopup = (data, type) => {
    this.setState({
      KRReviewData: data,
      viewReviewKrPopup: true,
      type: type
    })
  }
  closeKrReviewPopup = () => {
    this.setState({
      KRReviewData: {},
      viewReviewKrPopup: false,
      type: ''
    })
  }
  openKrRatingPopup = (data, ratingType) => {
    this.setState({ viewKrRatingPopup: true, KRData: data, ratingType })
  }
  closeKrRatingPopup = () => {
    this.setState({ viewKrRatingPopup: false })
  }
  openPastRatingPopup = (data) => {
    this.setState({ pastRatingPopup: true, pastRatingKRData: data })
  }
  closePastRatingPopup = () => {
    this.setState({ pastRatingPopup: false, pastRatingKRData: {} })
  }
  openAddEditIntrimFeedbackPopup = (data,) => {
    this.setState({
      addEditInterimReviewPopup: true,
      KRData: data,

    });
  };
  closeAddEditIntrimFeedbackPopup = () => {
    this.setState({
      addEditInterimReviewPopup: false,
      KRData: {},
    });
  };
  openKrIntrimFeedbackPopup = (data, userObjectiveId, objectiveName) => {
    this.setState({
      viewKrInterimFeedbackPopup: true,
      KRData: data,
      objId: userObjectiveId,
      objectiveName: objectiveName
    });
  };
  closeKrIntrimFeedbackPopup = () => {
    this.setState({
      viewKrInterimFeedbackPopup: false,
      KRData: {},
      objectiveName: ''
    });
  };
  openViewIntrimFeedbackPopup = (data, userObjectiveId, selectedType, isDraftClick) => {
    this.setState({
      KRData: data,
      viewInterimReviewPopup: true,
      objId: userObjectiveId,
      selectedType: selectedType
    });
    this.props.addUpdateOkrRatingRedux({ krData: data, interimType: selectedType, isDraftClick: isDraftClick })
  };
  closeViewIntrimFeedbackPopup = () => {
    this.setState({
      viewInterimReviewPopup: false,
      objId: null,
      selectedType: null,
      KRData: {}
    });
    this.props.addUpdateOkrRatingRedux({ krData: {}, interimType: "", isDraftClick: null })
  };
  openLpRatingPopup = (data, ratingType) => {

    this.setState({ viewLpRatingPopup: true, KRData: data, ratingType })
  }
  closeLpRatingPopup = () => {

    this.setState({ viewLpRatingPopup: false })
  }

  changeTab = (args) => {
    const { enableAction } = this.props;
    // console.log(args)
    this.mounted = false
    // console.log(this.mounted)
    if (args.selectedIndex === 1) {
      this.props.addUpdateObjectveKpi({ activeTabFlag: true, okrAllocationTabs: enableAction ? "active" : "closed" })
    } else {
      this.props.addUpdateObjectveKpi({ activeTabFlag: false })
    }

    if (args.selectedIndex === 2) {
      this.props.addUpdateObjectveKpi({ okrAllocationTabs: "closed" })
    }
    if (args.selectedIndex === 0) {
      this.props.addUpdateObjectveKpi({ okrAllocationTabs: enableAction ? "draft" : "active", activeTabFlag: enableAction ? false : true })

    }

  }

  render() { /*NOSONAR*/
    const { leadershipOKRData, height, dynamicHeight, importCount, isLoading,
      viewKrRatingPopup, KRData, viewLpRatingPopup,
      viewReviewKrPopup, KRReviewData, type, pastRatingPopup, pastRatingKRData, addEditInterimReviewPopup, viewKrInterimFeedbackPopup, objId, viewInterimReviewPopup, objectiveName, selectedType
    } = this.state;
    let smallResolution = (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight) > 767 ? 0 : + 60;
    const { leaderShipOkrData, draftData, draftWeight, activeData, setHeightFlag, enableAction, closedLPOkr, okrAllocationTabs } = this.props;
    const iImportCount = importCount + 1;
    let leaderShipOkrDataDraft = draftData;
    let leaderShipOkrDataActive = activeData;

    let totalWeight = draftWeight;
    activeData?.forEach((item)=>{
      totalWeight=totalWeight+item.weightage
    })

    // console.log(this.tabObj.selectedItem)

    let activeTabMargin = 10;
    if (!enableAction)
      activeTabMargin = 3;    
    return (
      <div style={{ display: "block", height, padding: 10 }}>
        {
          !isLoading && typeof leadershipOKRData.userImpactPerc !== 'undefined' ?
            <>
              <div style={{ paddingBottom: 25 }}>
                <div>
                  <div className="okr-central-LP-BV-position" style={{ verticalAlign: "middle" }}>
                    <span className="bussiness-okr-word" style={{ paddingLeft: 2 }}>Leadership Principles
                      <img className="okr-central-subheader-icon" alt="" src={require("../../assets/svg/Group_1863.svg")} style={{ paddingLeft: 5, verticalAlign: "middle" }} />
                    </span>
                    <span className="okr-central-subheaders" style={{ marginLeft: 32 }}>Leadership Principles OKR Weightage: </span>
                    <span className="okr-central-subheader-value">
                      {leadershipOKRData.userImpactPerc === null ? "NA" : `${leadershipOKRData.userImpactPerc}%` /*NOSONAR*/}
                    </span>
                    <span className="okr-central-subheaders" style={{ marginLeft: 24 }}>Variable Allocation: </span>
                    <span className="okr-central-subheader-value">
                      {leadershipOKRData.oteVariableImpactPerc === null ? "NA" : `${leadershipOKRData.oteVariableImpactPerc}%` /*NOSONAR*/}
                    </span>
                    <span className="okr-central-subheaders" style={{ marginLeft: 24 }}>OKR Rating Frequency: </span>
                    <span className="okr-central-subheader-value">{leadershipOKRData.frequency}</span>
                  </div>
                </div>
              </div>
              {leaderShipOkrDataDraft.length > 0 && okrAllocationTabs === 'draft' && enableAction &&
                <div className='col-lg-11 col-md-12 col-sm-12 col-xs-12' style={{ textAlign: "center", padding: "5px 0px 0px 100px", position: 'absolute' }}>
                  <span className="business-okr-note"> <img alt="" src={infoIcon} /> Click on "Fill Details" to enter specific inputs for each<strong> Objective</strong> and <strong>Key Result </strong></span>
                </div>
              }
              <TabComponent id="okrLeaderdefaultTab"
                style={{ left: '0px', right: '100px' }}
                animation={{ previous: { effect: "SlideLeftIn", duration: 0, easing: "ease" }, next: { effect: "SlideRightIn", duration: 0, easing: "ease" } }}
                selected={(arg) => this.changeTab(arg)}
                ref={(tabObj) => (this.tabObj = tabObj)}
                allowDragAndDrop={true}
              >

                <div className="e-tab-header" >

                  {enableAction &&
                    <div>
                      <span style={{ textTransform: 'none', fontWeight: 600, fontSize: "14px", font: 'normal normal bold 14px/25px Arial' }}>Draft</span>
                      <span id='draftTextCount' style={{ paddingLeft: 5, textTransform: 'none', font: 'normal normal bold 14px/25px Arial' }}> ({leaderShipOkrDataDraft.length}) </span>
                    </div>
                  }
                  <div>

                    <span style={{ marginLeft: activeTabMargin, textTransform: 'none', fontWeight: 600, fontSize: "14px", font: 'normal normal bold 14px/25px Arial' }}>Active</span>
                    <span id='activeTextCount' style={{ paddingLeft: 5, textTransform: 'none', font: 'normal normal bold 14px/25px Arial' }}> ({leaderShipOkrDataActive.length}) </span>

                  </div>
                  <div>
                    <span style={{ marginLeft: activeTabMargin, textTransform: 'none', fontWeight: 600, fontSize: "14px", font: 'normal normal bold 14px/25px Arial' }}>Closed</span>
                    <span id='closeTextCount' style={{ paddingLeft: 5, textTransform: 'none', font: 'normal normal bold 14px/25px Arial' }}> ({closedLPOkr.length})</span>
                  </div>

                </div>
                {/* <hr className="new4" style={{ marginLeft: redLineMarginLeft, marginTop: 5, marginBottom: 7 }} /> */}
                {/* <hr style={{ marginTop: -9 }} /> */}
                <hr style={{ margin: 0, border: "1px solid #F2F2F2" }} />
                <div className="e-content">
                  {(enableAction) && <div id="draftTab">
                    {leaderShipOkrDataDraft.length === 0 ? ( /*NOSONAR*/
                      leaderShipOkrDataActive.length > 0 ? ( /*NOSONAR*/
                        <div className="okr-no-item" style={{ paddingTop: "100px" }}>You have already assigned all KPIs to this Employee</div>
                      ) : (
                        <div className="okr-no-item" style={{ paddingTop: "100px" }}>You don't have any items here. Please Connect with People Team</div>
                      )
                    ) : (
                      <>
                        <div style={{
                          height:
                            // eslint-disable-next-line
                            setHeightFlag ? dynamicHeight + smallResolution : (window.innerHeight - 360) + smallResolution, overflowY: "auto",
                        }}
                        >
                          {leaderShipOkrData.length > 0 && (
                            <ObjectiveKpiCommon
                              importObjectiveKPI={leaderShipOkrDataDraft}
                              importCount={iImportCount}
                              objectType="3"
                              userProductiveAlloc={leadershipOKRData}
                              variablePerc={leadershipOKRData.oteVariableImpactPerc}
                              currentTab={this.currentTab}
                              totalWeight={totalWeight}
                              setHeightFlag={setHeightFlag}
                              currWindowAdjust={dynamicHeight}
                              enableAction={enableAction}
                            />
                          )}
                        </div>
                      </>
                    )}

                  </div>}


                  <div id="activeTab">
                    {leaderShipOkrDataActive.length === 0 ? ( /*NOSONAR*/
                      <div className="okr-no-item" style={{ paddingTop: "100px" }}>You don't have any Active OKR here.</div>
                    ) : (
                      <>
                        <div style={{
                          height:
                            // eslint-disable-next-line
                            setHeightFlag ? dynamicHeight : (window.innerHeight - 360) + smallResolution, overflowY: "auto",
                        }}
                        >
                          {leaderShipOkrData.length > 0 && (
                            <ObjectiveKpiCommon
                              importObjectiveKPI={leaderShipOkrDataActive}
                              importCount={iImportCount}
                              objectType="3"
                              userProductiveAlloc={leadershipOKRData}
                              variablePerc={leadershipOKRData.oteVariableImpactPerc}
                              setHeightFlag={setHeightFlag}
                              currWindowAdjust={dynamicHeight}
                              totalWeight={totalWeight}
                              enableAction={enableAction}
                            />
                          )}
                        </div>
                      </>
                    )}
                  </div>
                  <div>
                    <div style={{
                      height:
                        setHeightFlag ? dynamicHeight : window.innerHeight - 360, overflowY: "auto", marginTop: '10px'
                    }}
                    >
                      {closedLPOkr.length > 0 &&
                        closedLPOkr?.map((obj,i)=>(

                          <>
                          <div style={{ margin: "0px 15px" }}> <ClosedTabObjHeader objectiveData={closedLPOkr[i]} /></div>
                          <ReviewKR reviewData={[closedLPOkr[i]]} ratingTabs={"okr-review"}
                            viewKrReviewPopup={this.viewKrReviewPopup}
                            openKrRatingPopup={this.openKrRatingPopup}
                            openPastRatingPopup={this.openPastRatingPopup}
                            openAddEditIntrimFeedbackPopup={this.openAddEditIntrimFeedbackPopup}
                            openKrIntrimFeedbackPopup={this.openKrIntrimFeedbackPopup}
                            openViewIntrimFeedbackPopup={this.openViewIntrimFeedbackPopup}
                            OKRAllocationStatus={[]}
                            openLpRatingPopup={this.openLpRatingPopup}
                            />

                        </>
                        ))

                      }
                      {
                        closedLPOkr.length === 0 &&
                        <div className="okr-no-item" style={{ paddingTop: "100px" }}>You don't have any Closed OKR here.</div>
                      }
                    </div>
                    {
                      viewKrRatingPopup &&
                      <AddRatingModalkr
                        closeKrRatingPopup={this.closeKrRatingPopup}
                        OKRType={'3'}
                        ratingTabs={"okr-review"}
                        KRData={KRData}
                      />
                    }
                    {
                      viewReviewKrPopup &&
                      <ViewRatingModalKr
                        OKRType={'3'}
                        KRReviewData={KRReviewData}
                        closeKrReviewPopup={this.closeKrReviewPopup}
                        type={type}
                      />
                    }
                    {
                      pastRatingPopup &&
                      <PastRatingModal
                        OKRType={'3'}
                        KRData={pastRatingKRData}
                        closePastRatingPopup={this.closePastRatingPopup}
                      />
                    }
                    {addEditInterimReviewPopup && (
                      <AddEditInterimReviewModal
                        closeAddEditIntrimFeedbackPopup={this.closeAddEditIntrimFeedbackPopup}
                        type={"Add"}
                        KRData={KRData}
                        OKRType={'3'}
                        ratingTabs={"okr-review"}
                      />
                    )}
                    {viewKrInterimFeedbackPopup &&
                      <ViewkrInterimFeedbackModel
                        KRData={KRData}
                        objId={objId}
                        OKRType={'3'}
                        ratingTabs={"okr-review"}
                        closeKrIntrimFeedbackPopup={this.closeKrIntrimFeedbackPopup}
                        objectiveName={objectiveName}
                      />
                    }
                    {viewInterimReviewPopup &&
                      <InterimFeedBack
                        closeViewIntrimFeedbackPopup={this.closeViewIntrimFeedbackPopup}
                        ratingTabs={"okr-review"}
                        OKRType={'3'}
                        KRData={KRData}
                        objId={objId}
                        selectedType={selectedType}
                      />
                    }
                    {
                      viewLpRatingPopup &&
                      <ViewkrModel closeLpRatingPopup={this.closeLpRatingPopup} KRData={KRData} />
                    }
                  </div>
                </div>
              </TabComponent> </>
            :
            <div style={{ marginTop: '30%' }}><ProgressBar />
            </div>
        }
      </div>

    );
  }
}
OkrLeadership.propTypes = {
  authUser: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  employeeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  // eslint-disable-next-line
  allcommonTempData: PropTypes.arrayOf(object),
  // eslint-disable-next-line
  leaderShipOkrData: PropTypes.arrayOf(object),
  leaderShipOkrMasterList: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(object),
  ]),
  // eslint-disable-next-line
  leadershiKPITemp: PropTypes.arrayOf(object),
  parentHeight: PropTypes.bool,
  // eslint-disable-next-line
  draftData: PropTypes.arrayOf(object),
  // eslint-disable-next-line
  activeData: PropTypes.arrayOf(object),
  draftWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  activeWeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setHeightFlag: PropTypes.bool.isRequired,
  windowAdjust: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};
OkrLeadership.defaultProps = {
  authUser: "",
  employeeId: 0,
  allcommonTempData: [],
  leaderShipOkrData: [],
  leaderShipOkrMasterList: {},
  leadershiKPITemp: [],
  parentHeight: false,
  draftData: [],
  activeData: [],
  draftWeight: 0,
  activeWeight: 0,
};
const mapStateToProps = ({ auth, okrreducer }) => {
  const { authUser } = auth;
  const {
    employeeId,
    leaderShipOkrData,
    leaderShipOkrMasterList,
    userObjectStatus,
    leadershiKPITemp,
    allcommonTempData,
    reRenderComponent,
    fyid, draftData, draftWeight, activeData, activeWeight, closedLPOkr, okrAllocationTabs
  } = okrreducer;
  return {
    authUser,
    employeeId,
    leaderShipOkrData,
    leaderShipOkrMasterList,
    userObjectStatus,
    leadershiKPITemp,
    allcommonTempData,
    reRenderComponent,
    fyid, draftData, draftWeight, activeData, activeWeight, closedLPOkr, okrAllocationTabs
  };
};
export default connect(mapStateToProps, {
  setCentralOkrKPIRedux,
  setUserOKRObjectiveKPI,
  addUpdateObjectveKpi,
  addUpdateOkrRatingRedux
})(OkrLeadership);
