import React, { useState, useEffect } from "react";
import { ComboBoxComponent } from "@syncfusion/ej2-react-dropdowns";
import { NumericTextBoxComponent } from "@syncfusion/ej2-react-inputs";
import TextField from "@material-ui/core/TextField";
import { connect } from "react-redux";
import LnAServices from "../../services/lna/lnaservice";
import { saveOptionalHolidayCluster, updateLnAData } from "../../redux/actions/lna/lnaaction";
import UploadDocuments from "../okrratingcomponents/uploaddocuments";
import { createImageFilepathForLPView } from "../okrratingcomponents/ratingcommonfun";
import {
  effectiveStartDateVaidation, selecteffectivestartdate, pleaseSelectBaseCountry,
  pleaseEnterOptional, pleaseEnterChangeRequest
} from "../../assets/constants/constants";
import { FormValidator } from "@syncfusion/ej2-inputs";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import ProgressBar from "../progressbar/progressbar";
import { getMySqlDateFormat } from "../../app/other/commonfunction";

const SAMPLECSS = `
input.e-input, textarea.e-input, .e-input-group, .e-input-group.e-control-wrapper {
  font-size: 12px;
}
`

const OptionalHolidayCluster = (props) => {
  //NOSONAR
  const {
    authUser,
    openPopup,
    officeCountry,
    officeLocation,
    editClusterId,
    allCountry,
    allState
  } = props;
  const [state, setState] = useState({
    officeLocation: null,
    optionalHolidayClusterUid: null,
    countryId: null,
    cityId: null,
    holidayResetDate: null,
    fileName: "",
    isActive: "1",
    editIsActive: null, //when you edit
    holidayPolicyDoc: [],
    changeRequestDays: null,

    noOfOptionalCount: null,
    oldFileStatus: "",
    effectiveStartDate: null,
    effectiveEndDate: null,
  });
  const [changedData, setChangedData] = useState(false);
  const [cityList, setCityList] = useState([]);
  const [initialLoad, setInitialLoad] = useState(false)

  let validOptions = {
    rules: {
      countryErr: {
        required: [true, pleaseSelectBaseCountry],
      },
      noOfOptionalCountErr: {
        required: [true, pleaseEnterOptional],
      },
      noOfChangedDaysErr: {
        required: [true, pleaseEnterChangeRequest],
      },
      // resetDateErr: {
      //   required: [true, holidayResetDate]
      // },
      effectiveStartDateErr: {
        required: [true, selecteffectivestartdate],
      },
    }
  };



  useEffect(
    () => {
      props.updateLnAData({ popUpSource: 'Optional Holiday Master' })
      getData();

    },
    []
  );

  useEffect(() => {
    setTimeout(() => {
      props.setSubmitClick(onSaveData);
    }, 100);
  }, [state]);

  useEffect(() => {
    getCityList(state.countryId);
  }, [state.countryId]);

  useEffect(() => {
    getData();
  }, [editClusterId, openPopup]);

  useEffect(() => {
    if (props.lnaMasterFlag === "updated") {
      props.changePopUpHandler("close");
    }
  }, [props.lnaMasterFlag]);

  const getData = () => {
    if (openPopup === "add") {
      let data = {
        officeLocation: null,
        optionalHolidayClusterUid: null,
        countryId: null,
        cityId: null,
        holidayResetDate: null,
        fileName: "",
        isActive: "1",
        holidayPolicyDoc: [],
        changeRequestDays: null,

        noOfOptionalCount: null,
        oldFileStatus: "",
        effectiveStartDate: null,
        effectiveEndDate: null,
      };
      setState((preState) => ({ ...preState, ...data }));

    }
    else {
      editClusterId !== null &&
        (async () => {
          setInitialLoad(true)
          const optionalHolidayClusterData =
            await LnAServices.getOptionalHolidayClusterData(
              authUser,
              editClusterId
            );

          const {
            resetDate,
            coutryId,
            changeRequestDays,

            noOfOptionalCount,
            isActive,
            cityId,
            effectiveStartDate,
            effectiveEndDate,
            fileName,
          } = optionalHolidayClusterData.Result[0];

          cityId && getCityList(coutryId)

          let data = {
            optionalHolidayClusterUid: editClusterId,
            countryId: coutryId,
            cityId: cityId,
            holidayResetDate: resetDate,
            isActive,
            effectiveStartDate,
            effectiveEndDate,
            fileName: fileName,
            oldFileStatus: fileName,
            officeLocation,
            editIsActive: isActive === "1" ? true : false,
            changeRequestDays: changeRequestDays,

            noOfOptionalCount: noOfOptionalCount,
          };
          setState((preState) => ({ ...preState, ...data }));
          setTimeout(() => {
            const addFormObject = new FormValidator("#optionalholiday", validOptions);
            addFormObject.validate();
          }, 100)
          setInitialLoad(false)
        })();

    }
  };

  const documentDetails = (currentTime, file) => {
    setState((prevState) => ({
      ...prevState,
      fileName: file,
      oldFileStatus: file,
    }));
  };

  const TextFieldComponent = (prop) => {
    return <TextField {...prop} color="primary" disabled />;
  };

  const getCityList = (pCountryId) => {
    // setcityList([])
    const cityListArray = [];
    officeLocation.map((item) => {
      if (parseInt(item.optional1) === pCountryId) {
        cityListArray.push(item);
      }
    });
    setCityList(cityListArray);
  };

  const inputChangeHandler = (e, type) => {
    let options = {};
    switch (type) {
      case "countryId":
        setState({
          ...state,
          countryId: e.value !== null ? e.value : "",
          cityId: null
        });
        getCityList(e.value);
        document.getElementById("countryErr").innerHTML = "";
        options = {
          rules: {
            countryErr: {
              required: [true, pleaseSelectBaseCountry],
            },
          },
        };
        break;
      case "cityId":
        setState({
          ...state,
          cityId: e.value !== null ? e.value : null,
        });
        break;
      // case "holidayResetDate":
      //   setState({
      //     ...state,
      //     holidayResetDate: e.value,
      //   });
      //   if (document.getElementById("resetDateErr")) {
      //     document.getElementById("resetDateErr").innerHTML = "";
      //   }
      //   break;
      case "noOfoptionalCount":

        setState({
          ...state,
          noOfOptionalCount: e.value,
        });
        document.getElementById("noOfOptionalCountErr").innerHTML = "";
        options = {
          rules: {
            noOfOptionalCountErr: {
              required: [true, pleaseEnterOptional],
            },
          },
        };

        break;
      case "changeDaysRequest":
        setState({
          ...state,
          changeRequestDays: e.value,
        });
        document.getElementById("noOfChangedDaysErr").innerHTML = "";
        options = {
          rules: {
            noOfChangedDaysErr: {
              required: [true, pleaseEnterChangeRequest],
            },
          },
        };

        break;
      case "isActive":
        setState({
          ...state,
          isActive: e.checked === true ? "1" : "0",
        });
        break;

      case "effectiveStartDate":
        setState((prevState) => ({ ...prevState, effectiveStartDate: e.value }));
        if (document.getElementById("effectiveStartDateErr")) {
          document.getElementById("effectiveStartDateErr").innerHTML = "";
        }

        break;
      case "effectiveEndDate":
        setState((prevState) => ({ ...prevState, effectiveEndDate: e.value }));
        break;
      default:

    }

    setTimeout(() => {
      const addFormObject = new FormValidator("#optionalholiday", options);
      addFormObject.validate();
    }, 100);
  };

  const onSaveData = () => {
    const {
      countryId,
      cityId,
      fileName,
      isActive,
      optionalHolidayClusterUid,
      noOfOptionalCount,
      changeRequestDays,
      effectiveStartDate,
      effectiveEndDate,
    } = state;



    let validateDates = true;
    if (document.getElementById("countryErr")) {
      document.getElementById("countryErr").innerHTML = ""
    }
    if (document.getElementById("noOfOptionalCountErr")) {
      document.getElementById("noOfOptionalCountErr").innerHTML = ""
    }
    if (document.getElementById("noOfChangedDaysErr")) {
      document.getElementById("noOfChangedDaysErr").innerHTML = ""
    }

    if (
      state.effectiveStartDate === null &&
      state.effectiveEndDate !== null
    ) {
      document.getElementById("effectiveStartDateErr").innerHTML =
        selecteffectivestartdate;
      validateDates = false;
    } else if (
      state.effectiveStartDate !== null &&
      state.effectiveEndDate !== null
    ) {
      if (
        new Date(state.effectiveStartDate) > new Date(state.effectiveEndDate)
      ) {
        document.getElementById("effectiveStartDateErr").innerHTML =
          effectiveStartDateVaidation;
        validateDates = false;
      }
    }


    const addFormObject = new FormValidator("#optionalholiday", validOptions);
    if (addFormObject.validate() && validateDates) {
      setInitialLoad(true)
      props.saveOptionalHolidayCluster({
        userId: authUser,
        type: optionalHolidayClusterUid > 0 ? "EDIT" : "ADD",
        optionalHolidayClusterUid,
        countryId,
        cityId,
        holidayResetDate: state.holidayResetDate,
        fileName,
        isActive,
        noOfOptionalCount,
        changeRequestDays,
        effectiveStartDate:
          effectiveStartDate !== null
            ? getMySqlDateFormat(effectiveStartDate)
            : null,
        effectiveEndDate:
          effectiveEndDate !== null
            ? getMySqlDateFormat(effectiveEndDate)
            : null,
      });
      setTimeout(() => {
        setInitialLoad(false)
      }, 10)
    }

  };

  const selectedFiles = (fileNms) => {
    try {
      setState((preState) => ({ ...preState, holidayPolicyDoc: fileNms }));
      setTimeout(() => {
        setChangedData(!changedData);
      });
    } catch (error) { }
  };
  const removeFile = () => {
    setState(preState => ({ ...preState, fileName: "" }))
  }
  const getOneDayForward = (dt) => {
    let date = dt
    date.setDate(date.getDate() + 1)
    return date
  }
  const { countryId } = state
  return (
    <>
      <div id="optionalholiday" style={{ height: "61.5vh", overflowY: "auto" }}>
        <style>{SAMPLECSS}</style>
        <div style={{ padding: "20px" }}>
          <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 atc-no-paddding">
            <ComboBoxComponent
              id="country"
              dataSource={allCountry}
              fields={{ text: "Name", value: "Id" }}
              value={state.countryId}
              data-msg-containerid="countryErr"
              name="countryErr"
              floatLabelType="Auto"
              cssClass="e-outline"
              allowCustom={false}
              placeholder="Country *"
              onChange={(e) => inputChangeHandler(e, "countryId")}
            />
            <div style={{ fontSize: 12 }}
              id="countryErr"
              className="e-input-group e-float-icon-left error-div"
            />
          </div>
          {/* <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 mb-lg  atc-no-paddding">
            {(state.cityId !== null || cityList.length > 0 || props.openPopup == 'add') && <ComboBoxComponent
              id="locationCity"
              // dataSource={cityList}
              dataSource={allState.filter(state => +state.optional1 === countryId)}
              fields={{ text: "Name", value: "Id" }}
              value={state.cityId}
              data-msg-containerid="locationCityErr"
              name="locationCityErr"
              floatLabelType="Auto"
              cssClass="e-outline"
              placeholder="Location/City"
              allowCustom={false}
              onChange={(e) => inputChangeHandler(e, "cityId")}
            />}
          </div> */}

          <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 atc-no-paddding">
            <NumericTextBoxComponent
              data-msg-containerid="noOfOptionalCountErr"
              id="noOfDaysForwardAllowed"
              value={state.noOfOptionalCount}
              name="noOfOptionalCountErr"
              format="##"
              placeholder="No. of Optional *"
              floatLabelType="Auto"
              min={1}
              max={365}
              cssClass="e-outline"
              // showSpinButton={false}
              onChange={(e) => inputChangeHandler(e, "noOfoptionalCount")}
              htmlAttributes={{ maxlength: 100 }}
            />
            <div id="noOfOptionalCountErr" style={{ fontSize: 12 }} className="error-div" />
          </div>

          <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12  mt-lg  atc-no-paddding">
            <NumericTextBoxComponent
              data-msg-containerid="noOfChangedDaysErr"
              id="noOfDaysForwardAllowed"
              value={state.changeRequestDays}
              format="##"
              name="noOfChangedDaysErr"
              placeholder=" Change Request Days *"
              floatLabelType="Auto"
              cssClass="e-outline"
              min={1}
              max={365}
              onChange={(e) => inputChangeHandler(e, "changeDaysRequest")}
              htmlAttributes={{ maxlength: 100 }}
            />
            <div id="noOfChangedDaysErr" style={{ fontSize: 12 }} className="error-div" />
          </div>

          {/* <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 mt-lg atc-no-paddding">
            <DatePickerComponent
              id="resetDate"
              value={state.holidayResetDate}
              data-msg-containerid="resetDateErr"
              name="resetDateErr"
              // allowCustom={false}
              color="black"
              autoOk
              variant="inline"
              format="dd-MMM-yyyy"
              floatLabelType="Auto"
              cssClass="e-outline"
              inputVariant="outlined"
              // placeholder="Effective Start Date *"
              placeholder="Reset Date *"
              label="Reset Date *"
              // max={}
              onChange={(e) => inputChangeHandler(e, "holidayResetDate")}
              TextFieldComponent={TextFieldComponent}
            />
            <div
              id="resetDateErr"
              className="e-input-group e-float-icon-left error-div"
            />
          </div>*/}
          <div
            className="col-xs-6 col-sm-6 col-lg-6 col-md-6"
            style={{ paddingRight: 0 }}
          >
          </div>

          <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 mt-md">
            <div
              className="col-xs-6 col-sm-6 col-lg-16col-md-1 mt-sm atc-no-paddding"
              style={{ paddingRight: 8 }}
            >
              {/* <MuiPickersUtilsProvider utils={DateFnsUtils}> */}
              <DatePickerComponent
                style={{ width: "100%" }}
                id="effectiveStartDate"
                name="effectiveStartDateErr"
                data-msg-containerid="effectiveStartDateErr"
                format="dd-MMM-yyyy"
                value={state.effectiveStartDate}
                onChange={(e) => inputChangeHandler(e, "effectiveStartDate")}
                TextFieldComponent={TextFieldComponent}
                // minDate={new Date()}
                color="black"
                // disablePast
                autoOk
                cssClass="e-outline"
                variant="inline"
                inputVariant="outlined"
                // min={getMySqlDateFormat(Date.now())}
                floatLabelType="Auto"
                placeholder="Effective Start Date * "
                KeyboardButtonProps={{ "aria-label": "change date" }}
                InputLabelProps={{ shrink: true }}
              />
              {/* </MuiPickersUtilsProvider> */}
              <div
                id="effectiveStartDateErr"
                className="e-input-group e-float-icon-left error-div"
              />
            </div>
            <div
              className="col-xs-6 col-sm-6 col-lg-16col-md-1 mt-sm atc-no-paddding"
              style={{ paddingLeft: 8 }}
            >{
                <DatePickerComponent
                  // style={{ width: "100%" }}
                  // fields={{ text: "Name", value: "Id" }}
                  id="effectiveEndDate"
                  name="effectiveEndDateErr"
                  data-msg-containerid="effectiveEndDateErr"
                  format="dd-MMM-yyyy"
                  value={state.effectiveEndDate}
                  onChange={(e) => inputChangeHandler(e, "effectiveEndDate")}
                  TextFieldComponent={TextFieldComponent}
                  // minDate={new Date()}
                  color="black"
                  // disablePast
                  cssClass="e-outline"
                  autoOk
                  min={state.effectiveStartDate ? getOneDayForward(new Date(state.effectiveStartDate)) : null}
                  variant="inline"
                  inputVariant="outlined"
                  placeholder="Effective End Date "
                  // min={getMySqlDateFormat(Date.now())}
                  floatLabelType="Auto"
                  KeyboardButtonProps={{ "aria-label": "change date" }}
                  InputLabelProps={{ shrink: true }}
                />
              }
              <div
                id="effectiveEndDateErr"
                className="e-input-group e-float-icon-left error-div"
              />
            </div>
          </div>
          <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 mt-sm   atc-no-paddding">
            <div
              className="attachNote"
              style={{
                fontSize: ".95rem",
                color: "#939399",
                fontWeight: 500,
                padding: ".5rem 0",
                marginTop: "0rem",
              }}
            >
              Attach Policy
            </div>
            <div>
              <UploadDocuments
                type={"optionalholiday"}
                module={'lna'}
                allowmultiple={false}
                selectedFiles={selectedFiles}
                // fileList={!state.holidayPolicyDoc ? [] : state.holidayPolicyDoc}
                fileList={[]}
                apiUrl={"lnaApiUrl"}
                documentDetails={documentDetails}
              />
            </div>
            {state.fileName !== null && state.oldFileStatus !== null ? (
              <div style={{ paddingLeft: 8 }}>
                {createImageFilepathForLPView(
                  [].concat(state.fileName),
                  "document/lna/optionalholiday",
                  "lnaApiUrl", removeFile
                )}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <div className="loader-new1" id="spinnerContainer" >
        {initialLoad && <ProgressBar />}
      </div>
    </>
  );
};

const mapStateToProps = ({ auth, lnareducer, meetingmaster }) => {
  const { authUser } = auth;
  const { currentFinancialYearId } = meetingmaster;
  const { officeCountry, officeLocation, popUpSource, lnaMasterFlag, allCountry, allState } = lnareducer;

  return {
    authUser,
    officeCountry,
    officeLocation,
    currentFinancialYearId, popUpSource, lnaMasterFlag,
    allCountry, allState
  };
};
export default connect(mapStateToProps, {
  saveOptionalHolidayCluster, updateLnAData
})(OptionalHolidayCluster);
