/**
 * Author: Vivek Khodade.
 * Created:06-Jun-2020
 * Last Modify:06-Jun-2020
 * Modified By:Vivek Khodade
 */
import {
  RESONS_FOR_RESIGNATIONS,
  ADD_UPDATE_SEPARATION,
  SUBMIT_RESIGNATION_DATA,
  SUBMIT_WITHDRAWAL_DATA,
  SUBMIT_ABSCONDING_DATA,
  SUBMIT_TERMINATION_DATA,
  SUBMIT_EXIT_CLEARANCE_DATA,
  SUBMIT_LWD_RESIGNATION_COMPANY_REQUEST_AND_NPS,
  APPROVE_RESIGNATION,
  APPROVE_WITHDRAWL,
  INITIATE_CLEARANCE
} from "../../constant/entryexit/separationconstant";

export const updateReasonsForResignation = (data) => {
  return {
    type: RESONS_FOR_RESIGNATIONS,
    payload: data,
  };
};
export const addUpdateSeparationDetails = (data) => {
  return {
    type: ADD_UPDATE_SEPARATION,
    payload: data,
  };
};
export const submitResignatonData = (data) => {
  return {
    type: SUBMIT_RESIGNATION_DATA,
    payload: data,
  };
};
export const submitWithdrawalData = (data) => {
  return {
    type: SUBMIT_WITHDRAWAL_DATA,
    payload: data,
  };
};
export const submitAbscondingData = (data) => {
  return {
    type: SUBMIT_ABSCONDING_DATA,
    payload: data,
  };
};
export const submitTerminationData = (data) => {
  return {
    type: SUBMIT_TERMINATION_DATA,
    payload: data,
  };
};
export const submiteExitClearanceData = (data) => {
  return {
    type: SUBMIT_EXIT_CLEARANCE_DATA,
    payload: data,
  };
};
export const submiteLWDResignationCompanyrequestandNPS = (data) => {
  return {
    type: SUBMIT_LWD_RESIGNATION_COMPANY_REQUEST_AND_NPS,
    payload: data,
  };
};
export const approveResignation = (data) => {
  return {
    type: APPROVE_RESIGNATION,
    payload: data,
  };
};
export const approveWithdrawl = (data) => {
  return {
    type: APPROVE_WITHDRAWL,
    payload: data,
  };
};
export const initiateClearance = (data) => {
  return {
    type: INITIATE_CLEARANCE,
    payload: data,
  };
};


