
import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { addUpdateOkrDashboardRedux } from '../../redux/actions/okrdashboardaction/okrdashboardaction';

const breadcrumb = {
    backgroundColor: '#F3F3F6',
    color: '#606069 !important',
    margin: 0,
    paddingBottom: 0,
    //   border: '1px solid rgba(0, 0, 0, 0.125)',
    //   borderRadius: '0.37rem'
}

function Breadcrumb(props) {
    const [activeCrumb, setActiveCrumb] = useState("My Team");

    const buttonRef = useRef();

    useEffect(() => { // NOSONAR
        const { dashboardTabs, crumbs } = props;
        /** if (dashboardTabs === "team-rating") {
            if (crumbs.length === 1 && document.getElementById("crumb-1")) {
                document.getElementById("crumb-1").click();
            }
        } */
        if (dashboardTabs === "self-rating") {
            if (crumbs.length === 2 && document.getElementById("crumb-2")) {
                document.getElementById("crumb-2").click();
            } else {
                if (props.empName && document.getElementById("crumb-3")) {
                    document.getElementById("crumb-3").click();
                }
            }

        }
        if (dashboardTabs === "performance-potential") {
            if (crumbs.length === 3 && document.getElementById("crumb-3")) {
                document.getElementById("crumb-3").click();
            } else {
                if (props.empName && document.getElementById("crumb-4")) {
                    document.getElementById("crumb-4").click();
                }
            }
        }
    }, [props.dashboardTabs, props.crumbs.length])

    const selected = (crumb) => {
        props.selected(crumb);
        if (crumb !== "OKR Dashboard") {
            setActiveCrumb(crumb);
        }
    }

    const crumbFunction = (crumb) => {
        const { dashboardTabs, isPeopleLeader } = props;
        /** if (crumb === "OKR Dashboard" || dashboardTabs === "team-rating") {
            return "team-rating-activeBlack"
        } */
        if (crumb === "My Team" || dashboardTabs === "self-rating") {
            if (crumb === props.empName) {
                return "emp-activeBlack"
            } else {
                return "self-rating-activeBlack"
            }
        }
        if (crumb === 'OKR Dashboard' && isPeopleLeader && dashboardTabs==='self-rating') {
            return "team-rating-activeBlack";
        }
        if (crumb === "Rating" || dashboardTabs === "performance-potential") {
            return "performance-potential-activeBlack"
        }
    }
    return (
        <nav className="row justify-content-center">
            <ol className="breadcrumb" style={breadcrumb}>
                {
                    props.crumbs.map((crumb, ci) => {

                        return (
                            <li
                                key={ci}
                                className="breadcrumb-item align-items-center"
                            >
                                <button
                                    id={`crumb-${ci + 1}`}
                                    className={`greyDisable ${activeCrumb === crumb ? crumbFunction(crumb) : ""}`} onClick={() => selected(crumb)}
                                    ref={buttonRef}
                                >
                                    {crumb}
                                </button>
                            </li>
                        );
                    })
                }
            </ol>
        </nav>
    );
}

const mapStateToProps = () => {
    return {}
  };
  export default connect(mapStateToProps, { addUpdateOkrDashboardRedux })(
    Breadcrumb
  );
