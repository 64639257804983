import './default.css';
import * as React from 'react';
import { connect } from "react-redux";
import { MainClass } from '../../../mainclass';
import HomeOffce from '../../containers/homeoffice/homeoffice';
import ViewHomeOffce from '../../containers/homeoffice/viewhomeoffice';
import ProgressBar from '../../../components/progressbar/progressbar'
import { addEditHomeOfficeDetails } from "../../../redux/actions/homeoffice/homeofficeaction";
import dataService from "../../../services/services";

class HomeOfficeLayout extends MainClass {
  constructor(props) {
    super(props)
    this.state = {
      showSuccessMessage: false,
      homeOfficeSuccessMessage: "",
      homeOfficeList: [],
      showLoader: true
    }
  }

  getHomeOfficeData = async () => {
    try{
      const gethomeofficedetailsList = await dataService.gethomeofficedetails(
        this.props.authUser,
      this.props.authUser
      );
      this.setState({
        homeOfficeList: gethomeofficedetailsList.length > 0 ? gethomeofficedetailsList : [],
        showSuccessMessage: false,
        homeOfficeSuccessMessage: "",
    });

    this.props.addEditHomeOfficeDetails({ homeOfficeMode: gethomeofficedetailsList[0].isSubmited === 1 ? "viewHome" : "editHome", address1:gethomeofficedetailsList[0].address1, address2:gethomeofficedetailsList[0].address2})
    
    if (gethomeofficedetailsList.length === 0) {
      this.props.addEditHomeOfficeDetails({
        deskSpaceDualScreenSetUp: null,
        spaceIsolatedDistraction: null,
        plainBackgroundForVideoCall: null,
        ups: null,
        invertor: null,
        uninterruptedElectricitySupply: null,
        homeOfficeBackup: null,
      });
    }

    this.setState({
      showLoader: false
    });
    }
    catch(e){
      
    }
  };

  componentDidMount() {
    this.getHomeOfficeData();
  }

  //WARNING! To be deprecated in React v17. Use new lifecycle static getDerivedStateFromProps instead.
  componentWillReceiveProps(nextProps) {
    if (nextProps.homeOfficeStatus === "updated") {
      this.getHomeOfficeData();
      setTimeout(() => {
        this.setState({
          showSuccessMessage: true,
          homeOfficeSuccessMessage: nextProps.homeOfficeSuccessMessage,
        });
      }, 1000);
    }
  }
  renderSwitch() {
    const { homeOfficeMode } = this.props;
    const { homeOfficeList } = this.state;
    switch (homeOfficeMode) {
      case "viewHome":
        if (homeOfficeList !== undefined && homeOfficeList.length > 0) {
          return <ViewHomeOffce homeOfficeList={homeOfficeList} />;
        }
        break;
      case "editHome":
        return <HomeOffce homeOfficeList={homeOfficeList} />;
      default:
        return <div style={{ padding: 100 }}> <ProgressBar /> </div>;
    }
  }

  render() {
    const { showLoader } = this.state;
    return (
      <div>
        {showLoader ? <div style={{ padding: 100 }}> <ProgressBar /> </div> : this.renderSwitch()}
      </div>
    )
  }

}

const mapStateToProps = ({
  auth,  
  homeoffice,
}) => {
  const { authUser } = auth;  
  const { homeOfficeStatus, homeOfficeSuccessMessage, homeOfficeMode } = homeoffice;
  return {
    authUser,    
    homeOfficeStatus,
    homeOfficeSuccessMessage,
    homeOfficeMode
  };
};
export default connect(mapStateToProps, {
  addEditHomeOfficeDetails,
})(HomeOfficeLayout);
