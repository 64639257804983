/**
 * Author Name:- Prashant Waphare
 * Created Date :- 13-04-2020
 * Description :- This is card view (To be offered componet)
 */
import * as React from "react";
import queryString from "query-string";
import PropTypes from "prop-types";
import "../../app/containers/tmlc/tmlc.css";
import {
  ButtonComponent,
  CheckBoxComponent,
} from "@syncfusion/ej2-react-buttons";

import $ from "jquery";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import { MDBBtn, MDBIcon } from "mdbreact";
import { Button, Dialog } from "@material-ui/core";
import { connect } from "react-redux";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { Redirect } from "react-router-dom";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import TextField from "@material-ui/core/TextField";
import TobeOfferCandidateGridView from "./tobeoffergridview";
import ErrorMessage from "../errormessage/errormessage";
import Progressbar from "../progressbar/progressbar";
import OnbordingToBeOffer from "../../services/entryexit/onbordingServices";
import {
  DialogContent,
  DialogActions,
  DialogTitle,
} from "../customdialog/customdialog";
import {
  initiateAllcandidate,
  saveBasicInformationInRedux,
  updateStatusOnBoardingResucer,
} from "../../redux/actions/entryexit/onboardingaction";
import { getNotify, encrypt, encodeString, decodeString, encryptMethod } from "../../app/other/commonfunction";
import { WARNING, SUCCESS, ERROR } from "../../assets/constants/constants";
import { urlConfig } from "../../config/config";
import { PRE_OFFERE } from "../../redux/constant/meetingcategoryconstant";
import { setMeetingCategory } from "../../redux/actions/meetingcategoryaction";
import { setSpinner } from '@syncfusion/ej2-react-popups';
import { spinner } from "../../app/other/commonfunction";
import externalUser from "../../assets/png/onboarding/external-user.svg";

class CardViewToBeOfferCandidate extends React.Component {
  constructor(props) {
    super(props);
    const { toBeData } = this.props;
    this.state = {
      color: "green",
      todosPerPage:
        (window.innerHeight ||
          document.documentElement.clientHeight ||
          document.body.clientHeight) > 700
          ? 12
          : 8,
      height:
        (window.innerHeight ||
          document.documentElement.clientHeight ||
          document.body.clientHeight) - 140,
      width:
        (window.innerWidth ||
          document.documentElement.clientWidth ||
          document.body.clientWidth) - 300,

      cardLayuotHeight:
        (window.innerHeight ||
          document.documentElement.clientHeight ||
          document.body.clientHeight) - 300,

      toggleValue: 0,
      changeStatus: [],
      openDialog: false,
      openToBeOfferRevoke: false,
      toBerevokeReasonID: null,
      toBerevokeComment: null,
      toBerevokeDate: null,
      masterDataRevokr: null,
      getToBeOfferGridCheckBoxValueState: "",
      offerChangeStatusRevokestate: "",
      //   changeCardData: false,
      revokeStatus: [],
      redirect: null,
      selectedList: [],
    };
    const { todosPerPage } = this.state;
    this.totalPagecount = Math.ceil(toBeData.length / todosPerPage);
    this.height =
      (window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight) - 140;
    this.cardLayuotHeight =
      (window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight) - 300;

    this.dataBound = this.dataBound.bind(this);
    this.onDataBinding = this.onDataBinding.bind(this)
    this.initialLoad = true;
  }


  updateDimensions() {
    let update_width = window.innerWidth - 100;
    let update_height = window.innerHeight - 140;
    let cardLayuotHeight = window.innerHeight - 210;
    this.setState({ width: update_width, height: update_height, cardLayuotHeight: cardLayuotHeight });
  }
  componentDidMount = () => {
    $("ul li.active").removeClass("active");
    this.getMasterData();
    // eslint-disable-next-line
    this.props.getToBeTabData();
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
  };
  // eslint-disable-next-line
  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.tobeofferStatus === "updated") {
      // eslint-disable-next-line
      this.props.getToBeTabData();
      this.props.updateStatusOnBoardingResucer({ tobeofferStatus: "" });
    }
  }

  // Get master Data
  getMasterData = () => {
    const { authUser } = this.props;
    try {//NOSONAR
      const getMasterData = Promise.resolve(
        OnbordingToBeOffer.getMasterData("RV", authUser)
      );
      getMasterData.then((resultData) => {
        if (resultData !== null) {
          this.setState({ masterDataRevokr: resultData });
        } else {
          this.data = "error";
        }
      });
    } catch (e) {
      // eslint-disable-next-line
      console.log(e);
    }
  };

  changeColor = () => {
    const { color } = this.state;
    const newColor = color === "green" ? "green" : "red";
    this.setState({ color: newColor });
  };

  resendUploadLink = () => {
    const { selectedList, getToBeOfferGridCheckBoxValueState, offerChangeStatusRevokestate } = this.state;
    const { authUser } = this.props;
    if ((selectedList && selectedList.length > 0) || (getToBeOfferGridCheckBoxValueState && getToBeOfferGridCheckBoxValueState.length > 0) || (offerChangeStatusRevokestate && offerChangeStatusRevokestate.length > 0)) {
      var candidateList = "";
      if (selectedList.length > 0) {
        candidateList = selectedList.toString();
      } else if (getToBeOfferGridCheckBoxValueState.length > 0) {
        candidateList = getToBeOfferGridCheckBoxValueState.toString();
      } else {
        candidateList = offerChangeStatusRevokestate.toString();
      }
      try {//NOSONAR
        const res = Promise.resolve(
          OnbordingToBeOffer.sendCandidateUploadLink(candidateList, authUser)
        );
        res.then((resultData) => {
          if (resultData !== null && resultData !== false) {
            getNotify(SUCCESS, "Upload Link Sent again successfully");
            this.setState({
              // selectedList: [], revokeStatus: [], offerChangeStatusRevokestate : [], getToBeOfferGridCheckBoxValueState:[]
            });
          } else {
            getNotify(ERROR, "Please remove candidates, who have completed document upload process");
          }
        });
      } catch (e) {
        // eslint-disable-next-line
        console.log(e);
      }
    } else {
      getNotify(WARNING, "Please select at least one candidate!");
    }
  };

  openRevokeDialog = () => {
    const {
      revokeStatus,
      changeStatus,
      getToBeOfferGridCheckBoxValueState,
      offerChangeStatusRevokestate,
    } = this.state;
    if (
      revokeStatus.toString().length > 0 ||
      changeStatus.toString().length > 0 ||
      getToBeOfferGridCheckBoxValueState.toString().length > 0 ||
      offerChangeStatusRevokestate.toString().length > 0
    ) {
      this.setState({
        openToBeOfferRevoke: true,
      });
    } else {
      getNotify(WARNING, "Please select at least one candidate!");
    }
  };

  closeRevokeDialog = () => {
    this.setState({
      openToBeOfferRevoke: false,
      toBerevokeReasonID: null,
      toBerevokeComment: null,
      toBerevokeDate: null,
    });
  };

  getToBeRevokeReson = (e) => {
    this.setState({
      toBerevokeReasonID: e.itemData.Id,
    });
    const { toBerevokeReasonID } = this.state;
    document.getElementById("revokereasonErr").innerHTML = "";
    if (toBerevokeReasonID === null) {
      document.getElementById("revokereasonErr").innerHTML =
        "Please select action taken by";
    }
  };

  setRevokeDate = async (e) => {
    const revokeDate =
      Math.floor(Date.UTC(e.getFullYear(), e.getMonth(), e.getDate())) / 1000;
    document.getElementById("revokeDateErr").innerHTML = "";
    await this.setState({ toBerevokeDate: revokeDate });
    const { toBerevokeDate } = this.state;
    if (toBerevokeDate === null) {
      document.getElementById("revokeDateErr").innerHTML =
        "Please enter revoke date";
    }
  };

  getTOBeRevokeComment = (e) => {
    document.getElementById("revokeCommentErr").innerHTML = "";
    this.setState({
      toBerevokeComment: e.value,
    });
    const { toBerevokeComment } = this.state;
    if (toBerevokeComment === null) {
      document.getElementById("revokeCommentErr").innerHTML =
        "Please enter revoke comment";
    }
  };

  submitToBeRevoke = () => {/**NOSONAR */
    const {
      revokeStatus,
      changeStatus,
      toBerevokeReasonID,
      toBerevokeComment,
      toBerevokeDate,
      getToBeOfferGridCheckBoxValueState,
      offerChangeStatusRevokestate,
    } = this.state;
    const { authUser } = this.props;
    if (
      revokeStatus.toString().length > 0 ||
      changeStatus.toString().length > 0
    ) {
      let revokeCardSelection;
      if (
        revokeStatus.toString().length > 0 &&
        changeStatus.toString().length > 0
      ) {
        revokeCardSelection = `${revokeStatus.toString()},${changeStatus.toString()}`;
      } else if (revokeStatus.toString().length > 0) {
        revokeCardSelection = revokeStatus.toString();
      } else {
        revokeCardSelection = changeStatus.toString();
      }

      document.getElementById("revokereasonErr").innerHTML = "";
      if (toBerevokeReasonID === null) {
        document.getElementById("revokereasonErr").innerHTML =
          "Please select action taken by";
        return;
      }
      document.getElementById("revokeDateErr").innerHTML = "";
      if (toBerevokeDate === null || toBerevokeDate === "") {
        document.getElementById("revokeDateErr").innerHTML =
          "Please enter revoke date";
        return;
      }
      document.getElementById("revokeCommentErr").innerHTML = "";
      if (toBerevokeComment === null || toBerevokeComment === "") {
        document.getElementById("revokeCommentErr").innerHTML =
          "Please enter revoke reason";
        return;
      }
      // eslint-disable-next-line
      this.props.initiateAllcandidate({
        authUser,
        candIds: revokeCardSelection,
        statusId: 4,
        revokeComments: toBerevokeComment,
        revokeReasonId: toBerevokeReasonID,
        revokeDate: toBerevokeDate,
        revokeType: "tobeOffer"

      });
      this.setState({
        openToBeOfferRevoke: false,
        toBerevokeReasonID: null,
        toBerevokeComment: null,
        toBerevokeDate: null,
        revokeStatus: [],
        changeStatus: [],
      });
    } else {
      let revokeData;
      if (
        getToBeOfferGridCheckBoxValueState.toString().length > 0 &&
        offerChangeStatusRevokestate.toString().length > 0
      ) {
        revokeData = `${getToBeOfferGridCheckBoxValueState.toString()},${offerChangeStatusRevokestate.toString()}`;
      } else if (getToBeOfferGridCheckBoxValueState.toString().length > 0) {
        revokeData = getToBeOfferGridCheckBoxValueState.toString();
      } else {
        revokeData = offerChangeStatusRevokestate.toString();
      }
      document.getElementById("revokereasonErr").innerHTML = "";
      if (toBerevokeReasonID === null) {
        document.getElementById("revokereasonErr").innerHTML =
          "Please select action taken by";
        return;
      }
      document.getElementById("revokeDateErr").innerHTML = "";
      if (toBerevokeDate === null || toBerevokeDate === "") {
        document.getElementById("revokeDateErr").innerHTML =
          "Please enter revoke date";
        return;
      }
      document.getElementById("revokeCommentErr").innerHTML = "";
      if (toBerevokeComment === null || toBerevokeComment === "") {
        document.getElementById("revokeCommentErr").innerHTML =
          "Please enter revoke comment";
        return;
      }
      this.props.initiateAllcandidate({
        authUser,
        candIds: revokeData,
        statusId: 4,
        revokeComments: toBerevokeComment,
        revokeReasonId: toBerevokeReasonID,
        revokeDate: toBerevokeDate,
        revokeType: "tobeOffer"

      });
      this.setState({
        openToBeOfferRevoke: false,
        revokeStatus: [],
        changeStatus: [],
        getToBeOfferGridCheckBoxValueState: [],
        offerChangeStatusRevokestate: [],
        toBerevokeReasonID: null,
        toBerevokeComment: null,
        toBerevokeDate: null,
      });
    }
  };

  // This function use for toggle card view to grid component
  cartToGridView = () => {
    this.setState({
      changeStatus: [], openDialog: false, openToBeOfferRevoke: false, toBerevokeReasonID: null, toBerevokeComment: null, toBerevokeDate: null,
      getToBeOfferGridCheckBoxValueState: "",
      offerChangeStatusRevokestate: "",
      revokeStatus: [],
      redirect: null,
      selectedList: [],
    });
    const { toggleValue } = this.state;
    if (toggleValue === 0 && this.toggleValue !== "") {
      this.setState({ toggleValue: 1 });
    } else {
      this.setState({ toggleValue: 0 });
    }
  };

  // get selected checkbox candidate id
  getchangeStatus = (e, DocStatus, DocApprovalStatus, candId) => {
    const { changeStatus, selectedList, revokeStatus } = this.state;
    const val = candId;
    if (e.checked) {
      if (selectedList.indexOf(val) === -1) selectedList.push(val);
    } else if (selectedList.indexOf(val) > -1) {
      selectedList.splice(selectedList.indexOf(val), 1);
    }
    if (DocApprovalStatus === 'Complete') {
      const arr = changeStatus;
      if (!arr.includes(parseInt(val, 10))) {
        arr.push(parseInt(val, 10));
        this.setState(
          {
            changeStatus: arr,
            selectedList,
          }

        );
      }
      // }
      else {
        let filterData = changeStatus;
        filterData = filterData.filter(
          (t) => t !== parseInt(val, 10)
        );
        this.setState(
          {
            changeStatus: filterData,
            selectedList,
          }

        );
      }
    } else {
      const arrRevokeStatus = revokeStatus;
      if (
        !arrRevokeStatus.includes(parseInt(val, 10))
      ) {
        arrRevokeStatus.push(parseInt(val, 10));
        this.setState(
          {
            revokeStatus: arrRevokeStatus,
            selectedList,
          }

        );
        // getNotify(WARNING, 'You can not initiate the offer but revoke!')
      } else {
        let filterDataRevokeStatus = revokeStatus;
        // eslint-disable-next-line
        filterDataRevokeStatus = filterDataRevokeStatus.filter((t) => {
          return t !== parseInt(val, 10);
        });
        this.setState(
          {
            revokeStatus: filterDataRevokeStatus,
            selectedList,
          }

        );
      }
    }
  };

  // This function use for close dialog box
  closeDialog = () => {
    this.setState({ openDialog: false });
  };

  // This function use for open confermation dialog box on initiate offer button
  openDialog = () => {
    const {
      changeStatus,
      getToBeOfferGridCheckBoxValueState,
      revokeStatus,
      offerChangeStatusRevokestate,
    } = this.state;
    if ((changeStatus.length > 0 || getToBeOfferGridCheckBoxValueState.length > 0) && (revokeStatus.length > 0 || offerChangeStatusRevokestate.length > 0)) {
      getNotify(ERROR, "Please remove candidates whose document upload process is incomplete or pending");
      return;
    }
    if ((changeStatus && changeStatus.length > 0) || (getToBeOfferGridCheckBoxValueState && getToBeOfferGridCheckBoxValueState.length > 0)) {
      this.setState({ openDialog: true });
    } else if (revokeStatus.length > 0 || offerChangeStatusRevokestate.length > 0) {
      getNotify(WARNING, "You can not initiate the offer, As all required documents are pending!");
    } else {
      getNotify(WARNING, "Please select at least one candidate!");
    }
  };

  initiateOffer = () => {
    const { changeStatus, getToBeOfferGridCheckBoxValueState } = this.state;
    const { authUser } = this.props;
    let candidateStatus;
    if (changeStatus.toString().length > 0) {
      candidateStatus = {
        authUser: authUser,
        candIds: encryptMethod(changeStatus.toString()),
        statusId: 2,
        revokeType: "toBeOfferJoin"
      };
    } else {
      candidateStatus = {
        authUser: authUser,
        candIds: encryptMethod(getToBeOfferGridCheckBoxValueState.toString()),
        statusId: '2',
        revokeType: "toBeOfferJoin"
      };
    }
    this.props.initiateAllcandidate(candidateStatus);
    this.setState({ openDialog: false, changeStatus: [], getToBeOfferGridCheckBoxValueState: [], selectedList: [], offerChangeStatusRevokestate: [] });
  };

  // This function use for edit candidate details.(on click to be offered candidate card)
  editCardDetails = async (candId) => {
    try {
      const setCandidateId = {
        candidateid: parseInt(candId, 10),
      };
      this.props.saveBasicInformationInRedux(setCandidateId);

      localStorage.setItem("last_meeting_category", decodeString(localStorage.getItem("meeting_category")));
      localStorage.setItem("meeting_category", encodeString(PRE_OFFERE));
      const params = queryString.parse(this.props.location.search);
      const path = `/preoffer?candid=${encrypt(candId)}&id=${params.id}&appId=${params.appId}`;
      this.setState(
        {
          redirect: path,
        },
        () => this.props.setMeetingCategory(PRE_OFFERE)
      );
    } catch (error) {
      // eslint-disable-next-line
      console.log("error", error);
    }
  };

  getToBeOfferGridCheckBoxValue = (props) => {
    this.setState({
      getToBeOfferGridCheckBoxValueState: props,
    });
  };

  getToBeOfferGridCheckBoxValueRevoke = (props) => {
    this.setState({
      offerChangeStatusRevokestate: props,
    });
  };

  TextFieldComponent = (props) => {
    // eslint-disable-next-line
    return <TextField {...props} disabled />;
  };

  addDefaultSrc(ev) {
    ev.target.parentNode.innerHTML = '<span  class="img-circle fas fa-user-circle img-circle-grid-photo" style="font-size: 60px " alt="" ></span>'
  }


  /**
  * Created on 06-09-2022 || Noel Fredrick
  * Description - For spinner
  */
  onDataBinding(pComponentId) {
    spinner(true)
    try {
      setTimeout(() => {
        document.getElementById(pComponentId).ej2_instances[0].refresh()
      })
    } catch (error) {
      console.log(error)
    }
  }


  dataBound() {
    spinner(false)
  }

  onFirstGridCreated() {
    if (this.initialLoad) {
      this.initialLoad = false;
      setSpinner({
        template: document.getElementById('spinnerContainer').innerHTML,
      });
      document.getElementById('spinnerContainer').remove();
      // this.gridInstance.freezeRefresh(); // re-render the grid component
    }

  }

  render() {/**NOSONAR */
    const {
      redirect,
      toBerevokeDate,
      toggleValue,
      changeStatus,
      openDialog,
      openToBeOfferRevoke,
      masterDataRevokr,
    } = this.state;
    const { toBeData, authUser } = this.props;
    if (redirect !== null) {
      return <Redirect to={redirect} />;
    }
    let revokeDateVal = null;
    if (toBerevokeDate)
      revokeDateVal = new Date(0).setUTCSeconds(toBerevokeDate);

    return (
      <div id="offerSection">
        <div className="row container-custome1" style={{ height: this.state.height }}>
          {toBeData !== null &&
            toBeData !== undefined &&
            toBeData !== "" &&
            toBeData !== "error" &&
            toBeData.length > 0 ? (
            <div>
              <div
                className="row row-xs-3 row-sm-3 row-lg-3 row-md-3 e-card-resize-container"
                style={{
                  paddingRight: "15px",
                  textAlign: "end",
                  paddingBottom: "4px",
                }}
              >
                <div className="col-xs-12 ">
                  {/* <MDBBtn color="cyan lighten-2">
                            <MDBIcon far icon="calendar-alt" />
                        </MDBBtn> &nbsp;&nbsp; */}

                  <MDBBtn
                    id="cartToGridView"
                    onClick={this.cartToGridView}
                    color="indigo lighten-2"
                  >
                    {toggleValue ? /**NOSONAR */(
                      <TooltipComponent
                        content="Toggle"
                        position="BottomCenter"
                        isSticky={false}
                        width="70px"
                        cssClass="customtooltip"
                      >
                        <MDBIcon icon="th" />
                      </TooltipComponent>
                    ) : (
                      <TooltipComponent
                        content="Toggle"
                        position="BottomCenter"
                        isSticky={false}
                        width="70px"
                        cssClass="customtooltip"
                      >
                        <MDBIcon icon="fas  fa-th-large" />
                      </TooltipComponent>
                    )}
                  </MDBBtn>
                </div>
              </div>
              <div
                className="row-xs-6 row-sm-6 row-lg-6 row-md-6  to-be-offer-and-offer-card-page"
                style={{ height: this.state.cardLayuotHeight }}
              >
                <div className="control-pane ">
                  {/* <div className='scrollbar '> */}
                  <div className="control-section card-control-section vertical_card_layout">
                    <div className="e-card-resize-container">
                      <div
                        className="row card-layout"
                        style={{ paddingLeft: "10px", paddingRight: "10px" }}
                      >
                        <div
                          className="row card-layout"
                          style={{
                            paddingLeft: "20px",
                            paddingRight: "20px",
                            height: this.state.cardLayuotHeight,
                          }}
                        >
                          {
                            // eslint-disable-next-line
                            toggleValue === 0 && toggleValue !== "" ?/**NOSONAR */ (
                              toBeData !== null &&/**NOSONAR */
                                toBeData !== "" &&
                                toBeData.length > 0 ? (
                                toBeData.map((data) => (
                                  <div
                                    key={`carddiv${data.CandidateId}`}
                                    className="col-xs-3 col-sm-3 col-lg-3 col-md-3 e-card-content e-card-card-accent-success"
                                    style={{
                                      paddingTop: "10px",
                                      paddingBottom: 15,
                                    }}
                                  >
                                    <div
                                      className="e-card profile card-fix-height-to-be-offer"
                                      style={{
                                        borderLeft:
                                          // eslint-disable-next-line
                                          data.DocStatus === "Partial" ? `5px solid #f68104` : data.DocStatus === "Pending" ? `5px solid gray` : data.DocApprovalStatus === 'Complete' ? `5px solid green` : "5px solid #f68104"/**NOSONAR */
                                      }}>
                                      {/* <div className="col"> */}
                                      <div style={{ display: "flex", justifyContent: "space-between" }}>
                                        {/* <div className='col-xs-2 col-sm-2 col-lg-2 col-md-2'> */}
                                        {/* <div className="card-check-box"> */}
                                        <div style={{ marginLeft: ".8rem" }}>                                              <CheckBoxComponent
                                          id={`candidateID${data.CandidateId}`}
                                          checked={changeStatus.includes(
                                            data.CandidateId
                                          )}
                                          value={data.CandidateId}
                                          change={(e) =>
                                            this.getchangeStatus(
                                              e,
                                              data.DocStatus,
                                              data.DocApprovalStatus,
                                              data.CandidateId
                                            )
                                          }
                                        />
                                        </div>
                                        {/* </div> */}
                                        {/* <Link to={`/preoffer?candid=${encrypt(data.CandidateId)}`}> */}
                                        <div
                                          className="col-xs-10 col-sm-10 col-lg-12 col-md-10"
                                        // onClick={(e) => this.editCardDetails(data.CandidateId)}
                                        >
                                          <div
                                            className="e-card-header"
                                            style={{ paddingBottom: "1px" }}
                                          >
                                            {data.PhotoNm === "" ? (
                                              <center>
                                                <span
                                                  className="img-circle fas fa-user-circle"
                                                  alt=""
                                                  style={{
                                                    fontSize: "60px",
                                                    color: "#737373e0",
                                                    // position: "relative",
                                                  }}
                                                />
                                              </center>
                                            ) : (
                                              // <center><span className="img-circle"  alt="" style={{ width: "50%", height: "100%" }} /></center>
                                              <center>
                                                <img
                                                  onError={this.addDefaultSrc}
                                                  className="img-circle image-show-on-card pointer-cursor"
                                                  src={`${urlConfig.tmlcApiUrl}document/A/${authUser}/${data.PhotoNm}`}
                                                  alt=""
                                                  onClick={() =>
                                                    this.editCardDetails(
                                                      data.CandidateId
                                                    )
                                                  }
                                                />
                                              </center>
                                            )}
                                          </div>
                                          <div
                                            className="e-card-header"
                                            style={{ paddingTop: "1px" }}
                                          >
                                            <div
                                              className="e-card-header-caption center pointer-cursor"
                                              onClick={() =>/**NOSONAR */
                                                this.editCardDetails(
                                                  data.CandidateId
                                                )
                                              }
                                            >
                                              <div className="card-name">
                                                <b>{data.CandidateName}</b>
                                              </div>
                                              <div className="card-designation">
                                                {data.Role}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        {/* </Link> */}
                                        {data.externalUid && <div style={{
                                          display: "flex",
                                          justifyContent: "flex-start",
                                          alignItems: "center",
                                          flexDirection: "column",
                                          padding: ".5rem .8rem 0 0"
                                        }}>
                                          <div>
                                            <img class="external-candidate" src={externalUser} width='28' height='33' />
                                          </div>
                                          <div style={{ marginTop: "-.5rem" }}>External</div>
                                        </div>}
                                      </div>
                                    </div>
                                  </div>
                                ))
                              ) : (
                                <div id="spinnerContainer" >
                                  <Progressbar />
                                </div>

                              )
                            ) : (
                              // <ErrorMessage message={'Somthing went wrong! ...'} />
                              <div>
                                <TobeOfferCandidateGridView
                                  gridData={toBeData}
                                  type={"toBeOffer"}
                                  getToBeOfferGridCheckBoxValue={
                                    this.getToBeOfferGridCheckBoxValue
                                  }
                                  getToBeOfferGridCheckBoxValueRevoke={
                                    this.getToBeOfferGridCheckBoxValueRevoke
                                  }
                                  authUser={authUser}
                                  onDataBinding={this.onDataBinding("offerSection")}
                                  created={this.onFirstGridCreated.bind(this)}
                                />
                              </div>
                            )
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* </div> */}
                </div>
              </div>
              <div style={{ width: "350px" }}>
                <Dialog open={openDialog}>
                  <DialogTitle> Initiate Offer </DialogTitle>
                  <DialogContent>
                    Are you sure you want to initiate offer for selected
                    Candidate(s)?
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={this.initiateOffer}> Yes </Button>
                    <Button onClick={this.closeDialog}> No </Button>
                  </DialogActions>
                </Dialog>
                <div>
                  <Dialog open={openToBeOfferRevoke}>
                    <DialogContent>
                      {/* <form id="revokeCandidate" method="post" style={{maxWidth:600}}> */}
                      <div className="row card-layout">
                        <div className="col-xs-2 col-sm-2 col-lg-6 col-md-2" >
                          {/* <span className="span">Revoke By *</span> */}
                          <DropDownListComponent
                            id="RevokToBeReason"
                            fields={{ value: "Id", text: "Name" }}
                            dataSource={masterDataRevokr}
                            data-msg-containerid="revokereasonErr"
                            name="revokereasonErr"
                            change={(e) => this.getToBeRevokeReson(e)}
                            cssClass="e-outline"
                            placeholder="Revoke By *"
                            floatLabelType="Auto"
                          />
                          <div
                            id="revokereasonErr"
                            className="error-div"
                            style={{ fontSize: "12px" }}
                          />
                        </div>
                        <div className="col-xs-2 col-sm-2 col-lg-6 col-md-2" >
                          {/* <span className="span">Select Revoke Date *</span> */}
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              style={{ width: "100%" }}
                              // margin="normal"
                              id="RevokeDate"
                              name="RevokeDate"
                              format="dd-MMM-yyyy"
                              value={revokeDateVal}
                              onChange={(e) => this.setRevokeDate(e)}
                              TextFieldComponent={this.TextFieldComponent}
                              data-msg-containerid="revokeDateErr"
                              color="black"
                              autoOk
                              variant="inline"
                              inputVariant="outlined"
                              placeholder="dd-MMM-yyyy"
                              label="Revoke Date *"
                              // label="Revoke Date *"
                              KeyboardButtonProps={{
                                "aria-label": "change date",
                              }}
                              // margin="dense"
                              InputLabelProps={{ shrink: true }}
                              floatLabelType="Auto"
                              cssClass="e-outline"
                              InputAdornmentProps={{ position: "start" }}
                            />
                          </MuiPickersUtilsProvider>
                          <div
                            id="revokeDateErr"
                            className="error-div"
                            style={{ fontSize: "12px" }}
                          />
                        </div>
                        <div
                          style={{ paddingTop: "30px" }}
                          className="col-xs-2 col-sm-2 col-lg-12 col-md-2"
                        >
                          {/* <span className="span">Reason for Revoke *</span> */}
                          <TextBoxComponent
                            id="ReasonToBeRevoke"
                            placeholder="Revoke Reason *"
                            cssClass="e-outline"
                            data-msg-containerid="revokeCommentErr"
                            name="revokeCommentErr"
                            multiline
                            change={(e) => this.getTOBeRevokeComment(e)}
                            floatLabelType="Auto"
                          />
                          <div
                            id="revokeCommentErr"
                            className="error-div"
                            style={{ fontSize: "12px" }}
                          />
                        </div>
                      </div>
                      {/* </form> */}
                      {/* <div style={{ border: '1px solid gray', marginBottom: '10px' }}></div> */}
                    </DialogContent>
                    <DialogActions style={{ paddingRight: "15px" }}>
                      <ButtonComponent
                        id="cancelRevoke"
                        className="e-control "
                        onClick={this.closeRevokeDialog}
                      >
                        Cancel
                      </ButtonComponent>
                      <ButtonComponent
                        id="sumitRevoke"
                        style={{ background: "#f94f5e", color: "#fff" }}
                        data-ripple="true"
                        onClick={this.submitToBeRevoke}
                      >
                        Submit
                      </ButtonComponent>
                    </DialogActions>
                  </Dialog>
                </div>
              </div>
              <div
                className="row"
                style={{
                  border: "0px solid",
                  position: "absolute",
                  width: "100%",
                  bottom: 15,
                }}
              >
                <div
                  className="col-9"
                  style={{
                    textAlign: "right",
                    minHeight: 1,
                    paddingLeft: "13%",
                  }}
                >
                </div>
                <div className="col-12 button-down-card">
                  {/* style={{textAlign:'right', position:'static', paddingRight:12, paddingTop:8}} */}
                  <ButtonComponent
                    style={{ borderRadius: "4px" }}
                    onClick={this.resendUploadLink}
                  >
                    RESEND LINK
                  </ButtonComponent>
                  &nbsp;&nbsp;
                  <ButtonComponent
                    style={{ borderRadius: "4px" }}
                    onClick={this.openRevokeDialog}
                  >
                    REVOKE
                  </ButtonComponent>
                  &nbsp;&nbsp;
                  <ButtonComponent
                    style={{
                      background: "#f94f5e",
                      color: "#fff",
                      borderRadius: "4px",
                    }}
                    onClick={this.openDialog}
                  >
                    INITIATE OFFER
                  </ButtonComponent>
                </div>
              </div>
            </div>
          ) : (
            <div className="control-section col-md-12" id="spinnerContainer" style={{ height: 75 }}>
              {toBeData !== "error" && toBeData.length > 0 ?/**NOSONAR */ (
                <Progressbar />
              ) : (
                <ErrorMessage message="No Data Available" />
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

CardViewToBeOfferCandidate.propTypes = {
  toBeData: PropTypes.arrayOf(PropTypes.object),
  todosPerPage: PropTypes.number,
  updateStatusOnBoardingResucer: PropTypes.func,
  authUser: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  // initiateAllcandidate: PropTypes.func,
  saveBasicInformationInRedux: PropTypes.func,
  setMeetingCategory: PropTypes.func,
};
CardViewToBeOfferCandidate.defaultProps = {
  toBeData: [],
  todosPerPage: 0,
  updateStatusOnBoardingResucer: {},
  authUser: "",
  // initiateAllcandidate: {},
  saveBasicInformationInRedux: {},
  setMeetingCategory: {},
};
// CardViewToBeOfferCandidate.rules = {
//     "import/no-extraneous-dependencies": ["error",  {"devDependencies": true}],
//     "react/jsx-props-no-spreading": "off"
// }

const mapStateToProps = ({ auth, onboardingreducer, router }) => {
  const { location } = router;
  const { candidateid, tobeofferStatus } = onboardingreducer;
  const { authUser } = auth;
  return { authUser, candidateid, tobeofferStatus, location };
};
export default connect(mapStateToProps, {
  initiateAllcandidate,
  saveBasicInformationInRedux,
  updateStatusOnBoardingResucer,
  setMeetingCategory,
})(CardViewToBeOfferCandidate);
