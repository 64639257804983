/**
 * @author Vivek Khodade
 * @description Apply Leave Form
 * Last Modify Date : 25-02-2022
 * Modify by Vivek Khodade.
 */
import React, { useRef, useEffect } from "react";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { FormValidator } from "@syncfusion/ej2-inputs";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import { connect } from "react-redux";
import {
  cracterLimit,
  getDisplayDateOnly,
  getNotify,
  leaveDateFormat,
  addDays,
  getMySqlDateFormat,
  removeTralingSpaces,
  handleTimeCoversion,
  exctactTime,
  generateTooltipTitle,
} from "../../../../app/other/commonfunction";
import UploadDocuments from "../../../okrratingcomponents/uploaddocuments";
import {
  updateLnAData,
  applyLeave,
} from "../../../../redux/actions/lna/lnaaction";
import LnAService from "../../../../services/lna/lnaservice";
import NotifiersPopup from "./notifierspopup";
import ViewMoreDayBreakup from "./viewmoredaybreakup";
import Leavetabs from "../leavesbalance/leavetabs";
import LeaveDetails from "./leavedetails";
import NotificationContainer from "../../../../components/notification/notifycontainer";
import {
  BREModuleActions,
  ERROR,
  dayBreakupData,
  dayData,
  quaterDayData,
  errorLnAAlreadyApplied,
  errorLnASelectWorkingDay,
  errorLnADayBreak,
  WARNING,
  fillMandatoryFields,
  errorLnALocked,
} from "../../../../assets/constants/constants";
import BREServices from "../../../../services/breservice";
import { createImageFilepathForLPView } from "../../../../components/okrratingcomponents/ratingcommonfun";
import ConfirmationPopup from "../../../../utilities/confirmationpopup";
import { CANCELLEAVE, SPECIAL_ATTENDANCE } from "../../../../redux/constant/meetingcategoryconstant";
import {
  ComboBoxComponent,
  AutoCompleteComponent,
} from "@syncfusion/ej2-react-dropdowns";
import { Query, Predicate } from "@syncfusion/ej2-data";
import LeaveRules from "../../leaverules";
import { faPlus } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ProgressBar from "../../../progressbar/progressbar";
import { MuiPickersUtilsProvider, TimePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import services from "../../../../services/services";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import { default as dayjs } from "dayjs";
import { getYearData } from "../../../../app/other/commonlayoutfunction";

const sampleCss = `.e-outline textarea, textarea.e-outline, .e-outline.e-float-input textarea, .e-outline.e-float-input.e-control-wrapper textarea, .e-outline.e-float-input.e-input-group textarea {
    box-sizing: border-box;
    min-height: 100px;
    max-height:80px !important;
  }
  # financialYear > span .e-input-group .e-clear-icon.e-clear-icon-hide, .e-input-group.e-control-wrapper .e-clear-icon.e-clear-icon-hide {
      display: none;
  }
  // #timePicker .MuiInputLabel-outlined {
  //     z-index: 1;
  //     transform: translate(17px, 12px) scale(1);
  //     pointer-events: none;
  // }
  .MuiInputLabel-outlined {
      z-index: 1;
      transform: translate(14px, 14px) scale(1);
      pointer-events: none;
  }
 .MuiFormLabel-root{
      color: #939399 !important;
      font-size: 17px !important;
      font-weight: 400;
  }
  
  .timepicker-input-box .MuiInputBase-formControl {
    height: 37px;
  }
  
  .timepicker-label label {
    font-weight: 400 !important;
    font-family: "Roboto" !important;
    font-size: 17px !important;
    color: #939399 !important;
    min-width: 70px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .timepicker-label .Mui-focused fieldset.MuiOutlinedInput-notchedOutline {
    border-color: #f94f5e !important;
  }
 .e-outline.e-input-group input.e-input{
    font-size: 13.5px !important;
  }
  .e-outline.e-input-group .e-clear-icon, .e-outline.e-input-group.e-control-wrapper .e-clear-icon {
    font-size: 16px;
   padding: 0px !important; 
    }
    .e-outline.e-float-input .e-clear-icon::before, .e-outline.e-float-input.e-control-wrapper .e-clear-icon::before, .e-outline.e-input-group .e-clear-icon::before, .e-outline.e-input-group.e-control-wrapper .e-clear-icon::before {
        font-size: 16px;
        margin-right: 5px !important;
    }
  `;
export const LeaveApply = (props) => {
  //NOSONAR
  const {
    authUser,
    leaveType,
    othersCheckAvailablity,
    leaveStartDate,
    leaveEndDate,
    notificationTo,
    leaveReason,
    reliever,
    leaveTypeData,
    applyForData,
    // relieverData,
    isWeekendCounted,
    applyFor,
    leaveApplyLoadName,
    dayBreakText,
    leaveApplicationId,
    attachmentName,
    financialYearData,
    fyId,
    specialAttendanceData,
    categoryName,
    leaveTypeList,
    isStreched,
  } = props;
  const [isLeaveEdit, setIsLeaveEdit] = React.useState(false);
  const [state, setState] = React.useState({
    appliedDays: 0,
    selectedUserId: [],
    leaveClusterStart: {
      q1: null,
      q2: null,
      q3: null,
      q4: null,
    },
    leaveClusterEnd: {
      q1: null,
      q2: null,
      q3: null,
      q4: null,
    },
    applyedLeaveDates: [],
    height: null,
    notifiresPopupHeight: null,
    showList: false,
    showDayBreakup: false,
    minStartDate: null,
    maxStartDate: null,
    minEndDate: null,
    maxEndDate: null,
    fileName: [],
    startDate: null,
    endDate: null,
    selectionEnabled: true,
    violationMsgOpen: false,
    ViolationMsgTitle: "",
    violationMsg: "",
    violationSubMsg: "",

    documentsRequired: null,
    selectedStartDay: 1,
    selectedStartDayBreakup: 1,
    selectedEndDay: 1,
    selectedEndDayBreakup: 1,
    selectedStartDayTime: null,
    selectedEndDayTime: null,
    workDaySettingData: [
      {
        startTime: "10:00",
        endTime: "20:00",
        timeZone: "IST",
      },
    ],
  });
  const textareaObj = useRef(null);
  const relieverRef = useRef(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [showConform, setShowConform] = React.useState(false);
  const [cancelClicked, setCancelClicked] = React.useState(false);
  const [dataToSave, setDataToSave] = React.useState({});
  const [allHeight, setAllHeight] = React.useState({
    containerHeight: null,
  });
  const [errorMessageMargin, setErrorMessageMargin] = React.useState({
    typeOfLeaveMsg: false,
    applyingForMsg: false,
    fromDateMsg: false,
    toDateMsg: false,
    reasonMsg: false,
  });
  const [leaveName, setLeaveName] = React.useState("");
  const [leaveColor, setLeaveColor] = React.useState("");
  const [appliedLeaveDayError, setAppliedLeaveDayError] = React.useState("");
  const [selectedDayWeekOff, setSelectedDayWeekOff] = React.useState(false);
  const [lockUnlockData, setLockUnlockData] = React.useState([]);
  const isShowTooltip =
    window.innerWidth < 1281 ||
    (window.innerWidth < 1441 && window.devicePixelRatio === 1.25) ||
    (window.innerWidth < 1921 && window.devicePixelRatio === 1.5);
  const option2 = {
    rules: {
      typeOfLeaveErr: { required: [true, "Please select leave type."] },
      leaveAppReasonErr: { required: [true, "Please enter reason."] },
      applyForErr: { required: [true, "Please select applying for."] },
      ...(reliever === null || reliever === "" ? {} : { relieverErr: { required: [true, "Please select reporting supervisor."] } })
    },
  };
  let errorEnter3charactor = false;

  useEffect(() => {
    setTimeout(() => {
      try {
        if (relieverRef.current?.props.dataSource.length > 0) {
          // eslint-disable-next-line no-unused-expressions
          relieverRef.current?.props?.dataSource?.forEach((user) => {
            if (user.UserId === parseInt(reliever)) {
              relieverRef.current.text = user.nameWithDepartment;
              relieverRef.current.value = user.nameWithDepartment;
            }
          });
        }
      } catch (error) {
        console.log(error, "err");
      }
    }, 10);
  });
  useEffect(() => {
    props.updateLnAData({ applyFor: authUser });
    onCancel();
    getMasterData(authUser);
    setTimeout(() => {
      try {
        setTimeout(() => {
          let ele = document.getElementById("typeOfLeave");
          if (ele) ele.ej2_instances[0]?.clear();
        }, 50);
      } catch (err) { }
    }, 10);
  }, [categoryName]);

  useEffect(() => {
    props.updateLnAData({ applyFor: authUser });
    onCancel();
    getMasterData(authUser);
    //NOSONAR
    // const { leaveType: lt } = props
    // if (lt !== null) {
    //     updateStartDateEndDate(lt)
    // }
  }, [props.updateFinancialYear === "updated"]);

  useEffect(() => {
    window.addEventListener("resize", updateDimensions()); // NOSONAR
  }, []);

  useEffect(() => {
    getWorkDayList();
  }, [state.startDate]);

  const updateDimensions = () => {
    const updateheight = window.innerHeight - 125;
    const lavContHeight = window.innerHeight - 235;
    const containerHeight = window.innerHeight - 95;
    setState((preState) => ({
      ...preState,
      notifiresPopupHeight: updateheight,
      height: lavContHeight,
    }));
    setAllHeight((prevState) => ({
      ...prevState,
      containerHeight: containerHeight,
    }));
  };

  useEffect(() => {
    if (props.leaveApplyStatus === "updated") {
      let data = {
        appliedDays: 0,
        selectedUserId: [],
        leaveClusterStart: {
          q1: null,
          q2: null,
          q3: null,
          q4: null,
        },
        leaveClusterEnd: {
          q1: null,
          q2: null,
          q3: null,
          q4: null,
        },
        applyedLeaveDates: [],
        dayBreakupDates: [],
        startDate: null,
        endDate: null,
      };
      setIsLeaveEdit(false);
      setIsLoading(false);
      setState((preState) => ({ ...preState, ...data }));
      if (props.backgroundSyncId > 0) {
        const leaveData = {
          userId: authUser,
          actionType: "leave-apply",
          optionalId: props.backgroundSyncId.toString(),
        };
        LnAService.syncLeavedetails(leaveData);
      }
      props.updateLnAData({
        leaveStartDate: null,
        leaveEndDate: null,
        leaveType: null,
        leaveReason: "",
        leaveApplyStatus: "",
        appliedDays: null,
        attachmentName: "",
        dayBreakText: null,
        leaveCancelReason: "",
        leaveApplicationId: 0,
        backgroundSyncId: 0,
      });
    }
  }, [props.leaveApplyStatus]);

  useEffect(() => {
    if (props.leaveApplyStatus === "failed") {
      setIsLoading(false);
      props.updateLnAData({
        leaveApplyStatus: "",
      });
    }
  }, [props.leaveApplyStatus]);

  useEffect(() => {
    if (props.backgroundSyncId > 0 && props.leaveCancelStatus === "updated") {
      const leaveCancelData = {
        userId: authUser,
        actionType: "cancel-leave",
        optionalId: props.backgroundSyncId.toString(),
      };
      LnAService.syncLeavedetails(leaveCancelData);
      props.updateLnAData({
        leaveCancelStatus: "",
        backgroundSyncId: 0,
        leaveApplicationId: 0,
      });
    }
  }, [props.leaveCancelStatus]);

  useEffect(() => {
    // NOSONAR
    if (leaveApplyLoadName === "leave-edit") {
      /*
       *setQuarterData
       *get First Date
       *get Last Date
       *get start date day break count
       *get end date day break count
       */
      setIsLeaveEdit(true);
      let cancelC = cancelClicked;
      if (props.leaveApplicationId !== 0) {
        cancelC = false;
      }
      if (!cancelC) getDayBreakFormat();
      clearErrorMessages();
    }
  }, [leaveApplyLoadName, props.leaveApplicationId, state.startDate]);

  useEffect(() => {
    getMasterData(authUser);
  }, [fyId]);

  const getDayBreakFormat = async () => {
    try {
      setIsLoading(true);
      const res = await getDayBreakFormatToNormal(dayBreakText);
      if (res !== undefined && res.startDate !== null && res.endDate !== null) {
        await getLeavesDates(
          dayjs(res.startDate).format("YYYY-MM-DD"),
          dayjs(res.endDate).format("YYYY-MM-DD"),
          res.startDateDayBreakCount,
          res.endDateDayBreakCount,
          props.leaveApplicationId
        );
        let data = {
          appliedDays: props.appliedDays,
          selectedUserId: props.notificationTo
            ? props.notificationTo.split(",")
            : [],
          leaveClusterStart: res.leaveClusterStart,
          leaveClusterEnd: res.leaveClusterEnd,
          startDate: props.leaveStartDateEdit,
          endDate: props.leaveEndDateEdit,
          selectedStartDay: res.tempSelectedStartDay,
          selectedStartDayBreakup: res.tempSelectedStartDayBreakup,
          selectedEndDay: res.tempSelectedEndDay,
          selectedEndDayBreakup: res.tempSelectedEndDayBreakup,
          selectedStartDayTime: res.tempStartDayLeaveTime
            ? handleTimeCoversion(res.tempStartDayLeaveTime)
            : null,
          selectedEndDayTime: res.tempEndDayLeaveTime
            ? handleTimeCoversion(res.tempEndDayLeaveTime)
            : null,
          leaveStartDate: res.startDate,
          leaveEndDate: res.endDate,
          // isLoading: false
        };
        setState((preState) => ({ ...preState, ...data }));
        cracterLimit({ value: leaveReason }, "leaveApplyReasonCount", "1000");
      }
      let elements = document.getElementsByClassName(
        "e-float-input e-control-wrapper e-input-group e-outline e-date-wrapper e-valid-input e-error"
      );
      if (elements.length > 0) {
        elements[0].classList.remove("e-error");
      }
      let element = document.getElementsByClassName(
        "e-float-input e-control-wrapper e-input-group e-outline e-date-wrapper e-valid-input e-error"
      );
      if (element.length > 0) {
        element[0].classList.remove("e-error");
      }
      setIsLoading(false);
    } catch (error) {
      console.log(
        "🚀 ~ file: leaveapply.jsx:343 ~ getDayBreakFormat ~ error:",
        error
      );
    }
  };
  useEffect(() => {
    if (leaveApplyLoadName === "leave-view") {
      setIsLeaveEdit(false);
      if (
        (isThirteenHundred || isFourteenHundred) &&
        errorMessageMargin.reasonMsg
      ) {
        cracterLimit(undefined, "leaveApplyReasonCount", "1000");
      }
      let data = {
        startDate: null,
        endDate: null,
        appliedDays: 0,
      };
      setState((preState) => ({ ...preState, ...data }));
    }
  }, [leaveApplyLoadName === "leave-view"]);

  useEffect(() => {
    let validateData = validateDayBreakUp();
    setTimeout(() => {
      if (
        props.leaveStartDate !== null &&
        props.leaveEndDate !== null &&
        validateData.leaveStartDayCount > 0
      ) {
        getLeavesDates(
          props.leaveStartDate,
          props.leaveEndDate,
          validateData.leaveStartDayCount,
          validateData.leaveEndDayCount,
          leaveApplicationId
        );
      } else {
        let data = {
          dayBreakupDates: [],
        };
        setState((preState) => ({ ...preState, ...data }));
      }
      let elements = document.getElementsByClassName(
        "e-float-input e-control-wrapper e-input-group e-outline e-date-wrapper e-valid-input e-error"
      );
      if (elements.length > 0) {
        elements[0].classList.remove("e-error");
      }
    }, 10);
  }, [props.leaveStartDate, props.leaveEndDate]);

  useEffect(() => {
    fetchLockUnlockData();
  }, []);

  const fetchLockUnlockData = async () => {
    const lockUnlockClusterData = await LnAService.getLockUnlockClusterData(
      authUser,
      "0"
    );

    if (lockUnlockClusterData?.Result?.length > 0) {
      setLockUnlockData(lockUnlockClusterData.Result);
    }
  };

  const showViewMode = (flag, data, type) => {
    props.updateLnAData({
      leaveApplyLoadName: flag,
    });
  };

  const clearErrorMessages = () => {
    const errorFields = [
      "leaveStartDateErr",
      "leaveEndDateErr",
      "typeOfLeaveErr",
      "leaveAppReasonErr",
      "leaveClusterDayBreakupErr",
      "appliedDaysErr",
    ];

    errorFields.forEach((field) => {
      const elem = document.getElementById(field);
      if (elem) {
        elem.innerHTML = "";
      }
    });
  };

  const getDayBreakFormatToNormal = async (dayBreakString) => {
    // NOSONAR
    if (dayBreakString !== "" && dayBreakString !== null) {
      let dayBreakArr = dayBreakString ? dayBreakString.split(",") : [];
      let startLeaveType = 0;
      let endLeaveType = 0;
      let startDaybreakType = 0;
      let endDaybreakType = 0;
      let startDateDayBreakCount = 0;
      let endDateDayBreakCount = 0;
      let startDate = null;
      let endDate = null;
      let tempSelectedStartDay = null;
      let tempSelectedEndDay = null;
      let tempSelectedStartDayBreakup = null;
      let tempSelectedEndDayBreakup = null;
      let tempStartDayLeaveTime = null;
      let tempEndDayLeaveTime = null;
      let leaveClusterStart = {
        //NOSONAR
        q1: false,
        q2: false,
        q3: false,
        q4: false,
      };
      let leaveClusterEnd = {
        //NOSONAR
        q1: false,
        q2: false,
        q3: false,
        q4: false,
      };
      //For Single date.
      if (dayBreakArr.length === 1) {
        let startDateArr = dayBreakArr[0] ? dayBreakArr[0].split("_") : [];
        startDate = startDateArr[0];
        endDate = startDateArr[0];
        startLeaveType = startDateArr[1];
        endLeaveType = startDateArr[1];
        startDaybreakType = startDateArr[2];
        tempStartDayLeaveTime = startDateArr[3];
        //Full Day
        if (startLeaveType == "4") {
          tempSelectedStartDay = 1;
          startDateDayBreakCount = 1;
          leaveClusterStart = {
            q1: true,
            q2: true,
            q3: true,
            q4: true,
          };
        }
        //Half Day
        if (startLeaveType == "2") {
          //First Half
          tempSelectedStartDay = 2;
          if (startDaybreakType == "1") {
            tempSelectedStartDayBreakup = 1;
            startDateDayBreakCount = 0.5;
            leaveClusterStart = {
              q1: true,
              q2: true,
              q3: false,
              q4: false,
            };
          }
          //Second Half
          if (startDaybreakType == "2") {
            startDateDayBreakCount = 0.5;
            tempSelectedStartDayBreakup = 2;
            leaveClusterStart = {
              q1: false,
              q2: false,
              q3: true,
              q4: true,
            };
          }
        }
        //Quarter Day
        if (startLeaveType == "1") {
          //First Quarter
          if (startDaybreakType == "1") {
            tempSelectedStartDayBreakup = 1;
            startDateDayBreakCount = 0.25;
            leaveClusterStart = {
              q1: true,
              q2: false,
              q3: false,
              q4: false,
            };
          }
          //Second Quarter
          if (startDaybreakType == "2") {
            tempSelectedStartDayBreakup = 2;
            startDateDayBreakCount = 0.25;
            leaveClusterStart = {
              q1: false,
              q2: true,
              q3: false,
              q4: false,
            };
          }
          //Third Quarter
          if (startDaybreakType == "3") {
            tempSelectedStartDayBreakup = 3;
            startDateDayBreakCount = 0.25;
            leaveClusterStart = {
              q1: false,
              q2: false,
              q3: true,
              q4: false,
            };
          }
          //Forth Quarter
          if (startDaybreakType == "4") {
            tempSelectedStartDayBreakup = 4;
            startDateDayBreakCount = 0.25;
            leaveClusterStart = {
              q1: false,
              q2: false,
              q3: false,
              q4: true,
            };
          }
        }
        leaveClusterEnd = leaveClusterStart;
      }

      // For Multiple dates
      if (dayBreakArr.length > 1) {
        let startDateArr = dayBreakArr[0] ? dayBreakArr[0].split("_") : [];
        let endDateArr = dayBreakArr[dayBreakArr.length - 1]
          ? dayBreakArr[dayBreakArr.length - 1].split("_")
          : [];
        startDate = startDateArr[0];
        startLeaveType = startDateArr[1];
        startDaybreakType = startDateArr[2];
        tempStartDayLeaveTime = startDateArr[3];
        endDate = endDateArr[0];
        endLeaveType = endDateArr[1];
        endDaybreakType = endDateArr[2];
        tempEndDayLeaveTime = endDateArr[3];
        /**
         * First date
         */

        //Full Day
        if (startLeaveType == "4") {
          tempSelectedStartDay = 1;
          startDateDayBreakCount = 1;
          leaveClusterStart = {
            q1: true,
            q2: true,
            q3: true,
            q4: true,
          };
        }
        //Half Day
        if (startLeaveType == "2") {
          //First Half
          tempSelectedStartDay = 2;
          if (startDaybreakType == "1") {
            tempSelectedStartDayBreakup = 1;
            startDateDayBreakCount = 0.5;
            leaveClusterStart = {
              q1: true,
              q2: true,
              q3: false,
              q4: false,
            };
          }
          //Second Half
          if (startDaybreakType == "2") {
            startDateDayBreakCount = 0.5;
            tempSelectedStartDayBreakup = 2;
            leaveClusterStart = {
              q1: false,
              q2: false,
              q3: true,
              q4: true,
            };
          }
        }
        //Quarter Day
        if (startLeaveType == "1") {
          //First Quarter
          if (startDaybreakType == "1") {
            tempSelectedStartDayBreakup = 1;
            startDateDayBreakCount = 0.25;
            leaveClusterStart = {
              q1: true,
              q2: false,
              q3: false,
              q4: false,
            };
          }
          //Second Quarter
          if (startDaybreakType == "2") {
            tempSelectedStartDayBreakup = 2;
            startDateDayBreakCount = 0.25;
            leaveClusterStart = {
              q1: false,
              q2: true,
              q3: false,
              q4: false,
            };
          }
          //Third Quarter
          if (startDaybreakType == "3") {
            tempSelectedStartDayBreakup = 3;
            startDateDayBreakCount = 0.25;
            leaveClusterStart = {
              q1: false,
              q2: false,
              q3: true,
              q4: false,
            };
          }
          //Forth Quarter
          if (startDaybreakType == "4") {
            tempSelectedStartDayBreakup = 4;
            startDateDayBreakCount = 0.25;
            leaveClusterStart = {
              q1: false,
              q2: false,
              q3: false,
              q4: true,
            };
          }
        }

        /**
         * Last Date
         */
        //Full Day
        if (endLeaveType == "4") {
          tempSelectedEndDay = 1;
          endDateDayBreakCount = 1;
          leaveClusterEnd = {
            q1: true,
            q2: true,
            q3: true,
            q4: true,
          };
        }
        //Half Day
        if (endLeaveType == "2") {
          tempSelectedEndDay = 2;
          //First Half
          if (endDaybreakType == "1") {
            tempSelectedEndDayBreakup = 1;
            endDateDayBreakCount = 0.5;
            leaveClusterEnd = {
              q1: true,
              q2: true,
              q3: false,
              q4: false,
            };
          }
          //Second Half
          if (endDaybreakType == "2") {
            tempSelectedEndDayBreakup = 2;
            endDateDayBreakCount = 0.5;
            leaveClusterEnd = {
              q1: false,
              q2: false,
              q3: true,
              q4: true,
            };
          }
        }
        //Quarter Day
        if (endLeaveType == "1") {
          //First Quarter
          if (endDaybreakType == "1") {
            tempSelectedEndDayBreakup = 1;
            endDateDayBreakCount = 0.25;
            leaveClusterEnd = {
              q1: true,
              q2: false,
              q3: false,
              q4: false,
            };
          }
          //Second Quarter
          if (endDaybreakType == "2") {
            tempSelectedEndDayBreakup = 2;
            endDateDayBreakCount = 0.25;
            leaveClusterEnd = {
              q1: false,
              q2: true,
              q3: false,
              q4: false,
            };
          }
          //Third Quarter
          if (endDaybreakType == "3") {
            tempSelectedEndDayBreakup = 3;
            endDateDayBreakCount = 0.25;
            leaveClusterEnd = {
              q1: false,
              q2: false,
              q3: true,
              q4: false,
            };
          }
          //Forth Quarter
          if (endDaybreakType == "4") {
            tempSelectedEndDayBreakup = 4;
            endDateDayBreakCount = 0.25;
            leaveClusterEnd = {
              q1: false,
              q2: false,
              q3: false,
              q4: true,
            };
          }
        }
      }
      return {
        startLeaveType: startLeaveType,
        endLeaveType: endLeaveType,
        startDaybreakType: startDaybreakType,
        endDaybreakType: endDaybreakType,
        startDateDayBreakCount: startDateDayBreakCount,
        endDateDayBreakCount: endDateDayBreakCount,
        startDate: startDate,
        endDate: endDate,
        leaveClusterStart: leaveClusterStart,
        leaveClusterEnd: leaveClusterEnd,
        isSpecialAttendance: categoryName === SPECIAL_ATTENDANCE,
        tempSelectedStartDay: tempSelectedStartDay,
        tempSelectedEndDay: tempSelectedEndDay,
        tempSelectedStartDayBreakup: tempSelectedStartDayBreakup,
        tempSelectedEndDayBreakup: tempSelectedEndDayBreakup,
        tempStartDayLeaveTime: tempStartDayLeaveTime,
        tempEndDayLeaveTime: tempEndDayLeaveTime,
      };
    }
  };

  const removeFile = () => {
    props.updateLnAData({ attachmentName: "" });
    setState({ ...state, fileName: [] });
    // setState(preState => ({ ...preState, attachmentName:"" }))
  };

  useEffect(() => {
    let tempYearId = fyId;
    if (!tempYearId) {
      //NOSONAR
      getYearData(authUser)
        .then((result) => {
          props.updateLnAData({ fyId: result });
        })
        .catch((err) => {
          console.log("err===", err);
        });
    }
  }, []);

  const getMasterData = async (userId) => {
    try {
      if (userId && fyId) {
        const leaveTypeMater = await LnAService.getLeaveApplyMasterData(
          "All",
          userId,
          fyId
        );
        const leaveTypes = leaveTypeMater.Result?.filter((t) => {
          return t.type === "LeaveType";
        });
        const relieverDatas = leaveTypeMater.Result?.filter((t) => {
          return t.type === "Reliever";
        });
        const appyForData = leaveTypeMater.Result?.filter((t) => {
          return t.type === "ApplyFor";
        });
        const compOffData = leaveTypeMater.Result?.filter((t) => {
          return t.type === "CompOff";
        });
        const specialAttendanceDatas = leaveTypeMater.Result?.filter((t) => {
          return t.type === "SpecialAttendance";
        });
        if (
          leaveTypes.length > 0 &&
          leaveTypes[0].notifyToIds !== null &&
          leaveTypes[0].notifyToIds !== ""
        ) {
          let preSelectedIds = leaveTypes[0].notifyToIds
            ? leaveTypes[0].notifyToIds.split(",")
            : [];
          let preSelectedIdsArr = [];
          othersCheckAvailablity &&
            othersCheckAvailablity.forEach((trimnameWithDepartment) => {
              preSelectedIds &&
                preSelectedIds.forEach((id) => {
                  if (trimnameWithDepartment.UserId === parseInt(id)) {
                    preSelectedIdsArr.push({
                      id: trimnameWithDepartment.UserId,
                      name: trimnameWithDepartment.name,
                      department: trimnameWithDepartment.Department,
                    });
                  }
                });
            });
          props.updateLnAData({ notificationToArr: preSelectedIdsArr });
        }
        if (authUser === userId) {
          props.updateLnAData({
            leaveTypeData: leaveTypes,
            relieverData: relieverDatas,
            applyForData: appyForData,
            compOffData: compOffData,
            specialAttendanceData: specialAttendanceDatas,
            notificationTo:
              leaveTypes.length > 0 ? leaveTypes[0].notifyToIds : null,
            reliever: leaveTypes.length > 0 ? leaveTypes[0].relieverId : null,
          });
        } else {
          props.updateLnAData({
            leaveTypeData: leaveTypes,
            relieverData: relieverDatas,
            compOffData: compOffData,
            specialAttendanceData: specialAttendanceDatas,
            notificationTo:
              leaveTypes.length > 0 ? leaveTypes[0].notifyToIds : null,
            reliever: leaveTypes.length > 0 ? leaveTypes[0].relieverId : null,
          });
        }

        try {
          let data = {
            selectedUserId: leaveTypes[0].notifyToIds
              ? leaveTypes[0].notifyToIds.split(",")
              : [],
          };
          setState((preState) => ({ ...preState, ...data }));
        } catch (e) { }
      }
      cracterLimit({ value: "" }, "leaveApplyReasonCount", "1000");
      // setTimeout(() => document.getElementById("typeOfLeave").ej2_instances[0].clear(), 50)
    } catch (error) { }
  };

  const getWorkDayList = async () => {
    if (state.startDate != null && state.endDate !== null) {
      const type = "Leave";
      const workDaySetting = await services.getWorkDaySettingList(
        authUser,
        state.startDate,
        state.endDate,
        type
      );
      if (workDaySetting !== null && workDaySetting) {
        if (workDaySetting.length === 1) {
          workDaySetting[0].currentDate = "Y";
        } else {
          const data = workDaySetting.filter((item) => {
            return item.currentDate === "Y";
          });
          if (data.length === 0) {
            workDaySetting.forEach((element, idx) => {
              if (element.workStartDate === null) element.currentDate = "Y";
            });
          }
        }
        let tempData = {
          workDaySettingData: workDaySetting,
        };
        if (leaveApplyLoadName !== "leave-edit" && workDaySetting.length > 0) {
          tempData = {
            ...tempData,
            selectedStartDayTime: handleTimeCoversion(
              workDaySetting[0].startTime
            ),
            selectedEndDayTime: handleTimeCoversion(
              workDaySetting[0].startTime
            ),
            selectedStartDay: 1,
            selectedEndDay: 1,
            selectedStartDayBreakup: 1,
            selectedEndDayBreakup: 1,
          };
        }
        setState({ ...state, ...tempData });
      }
    }
  };

  const onInput = (e) => {
    try {
      if (
        leaveReason !== null &&
        textareaObj !== null &&
        typeof textareaObj !== "undefined"
      ) {
        textareaObj.current.respectiveElement.style.height = "auto";
        cracterLimit(e, "leaveApplyReasonCount", "1000");
        cracterLimit(e, "leaveAppReasonCount", "1000");
      }
    } catch (error) {
      console.log("🚀 ~ file: leaveapply.jsx:716 ~ onInput ~ error:", error);
    }
  };

  const handleFilteringByName = (e) => {
    e.preventDefaultAction = true;
    let predicate = new Predicate(
      "nameWithDepartment",
      "contains",
      e.text,
      true
    );
    predicate = predicate.or("UserId", "contains", e.value, true);

    let query = new Query();
    //frame the query based on search string with filter type.
    query = e.text != "" ? query.where(predicate) : query;

    //pass the filter data source, filter query to updateData method.
    e.updateData(othersCheckAvailablity, query);
  };
  const noRecordsTemplate = () => {
    return errorEnter3charactor ? (
      <div>Please enter minimum 3 characters</div>
    ) : (
      <div>No record found</div>
    );
  };
  const onCancel = () => {
    document.getElementById("documentRequiredErr").innerHTML = "";
    document.getElementById("leaveStartDateErr").innerHTML = "";
    document.getElementById("leaveEndDateErr").innerHTML = "";
    document.getElementById("leaveApplyReasonCount").innerHTML = "";
    props.updateLnAData({
      leaveType: null,
      leaveStartDate: null,
      leaveEndDate: null,
      leaveReason: "",
      appliedDays: "",
      leaveClusterId: "",
      approverId: 0,
      attachmentName: "",
      leaveApplicationId: 0,
      startDate: null,
      endDate: null,
    });
    setIsLeaveEdit(false);
    setState(
      {
        ...state,
        documentsRequired: null,
        startDate: null,
        endDate: null,
        leaveClusterStart: {
          q1: null,
          q2: null,
          q3: null,
          q4: null,
        },
        leaveClusterEnd: {
          q1: null,
          q2: null,
          q3: null,
          q4: null,
        },
        appliedDays: 0,
        selectionEnabled: true,
      },
      () => {
        //NOSONAR
        try {
          setTimeout(
            () =>
              document.getElementById("typeOfLeave").ej2_instances[0].clear(),
            10
          );
        } catch (err) { }
      }
    );
    setErrorMessageMargin((prevState) => ({
      //NOSONAR
      ...prevState,
      typeOfLeaveMsg: false,
      applyingForMsg: false,
      fromDateMsg: false,
      toDateMsg: false,
      reasonMsg: false,
    }));
    if (
      (isThirteenHundred || isFourteenHundred) &&
      errorMessageMargin.reasonMsg
    ) {
      cracterLimit({ value: "" }, "leaveApplyReasonCount", "1000");
    }
    clearErrorMessages();
  };

  const handleClose = () => {
    setErrorMessageMargin((prevState) => ({
      ...prevState,
      typeOfLeaveMsg: false,
      applyingForMsg: false,
      fromDateMsg: false,
      toDateMsg: false,
      reasonMsg: false,
    }));
    setShowConform(false);
  };

  const handleYes = async () => {
    setIsLoading(true);
    await props.applyLeave(dataToSave);
    await getMasterData(authUser);
    props.updateLnAData({ applyFor: authUser });
    handleClose();
    setShowConform(false);
    cracterLimit(0, "leaveApplyReasonCount", "1000");
    cracterLimit(0, "leaveAppReasonCount", "1000");
  };

  const resetTime = (timestamp) => {
    const date = new Date(timestamp);
    date.setHours(0, 0, 0, 0);
    return date.getTime();
  };

  const isLeaveApplyAllowed = (currentDate) => {
    const currentMonth = currentDate.getMonth() + 1;
    const currentYear = currentDate.getFullYear();
    var lastApplyDate = null;

    lockUnlockData.forEach((lockMonth) => {
      const lastDate = new Date(lockMonth.attendanceMonth);
      const lockMonthNumber = lastDate.getMonth() + 1;
      const lockDate = lastDate.getFullYear();

      if (currentMonth === lockMonthNumber && currentYear === lockDate) {
        lastApplyDate = resetTime(lockMonth?.lockEndDate);
      }
    });

    return lastApplyDate < resetTime(currentDate);
  };

  const onSubmitApplyLeave = async () => {
    // NOSONAR
    const { leaveClusterStart, applyedLeaveDates, appliedDays } = state; //NOSONAR
    document.getElementById("leaveStartDateErr").innerHTML = "";
    document.getElementById("leaveEndDateErr").innerHTML = "";
    if (document.getElementById("leaveClusterDayBreakupErr")) {
      document.getElementById("leaveClusterDayBreakupErr").innerHTML = "";
    }
    // cracterLimit({ value: "" }, 'leaveApplyReasonCount', '1000')
    document.getElementById("appliedDaysErr").innerHTML = "";
    const options = option2;
    clearErrorMessages();
    const addFormObject = new FormValidator("#applyLeave", options);

    if (addFormObject.validated.includes("typeOfLeaveErr")) {
      setErrorMessageMargin((prevState) => ({
        ...prevState,
        typeOfLeaveMsg: true,
      }));
    }

    if (addFormObject.validated.includes("applyForErr")) {
      setErrorMessageMargin((prevState) => ({
        ...prevState,
        applyingForMsg: true,
      }));
    }

    if (addFormObject.validated.includes("leaveAppReasonErr")) {
      setErrorMessageMargin((prevState) => ({
        ...prevState,
        reasonMsg: true,
      }));
    }
    try {
      addFormObject.validate();
    } catch (errr) { }
    if (
      (leaveStartDate === null || leaveStartDate === "") &&
      (leaveEndDate === null || leaveEndDate === "")
    ) {
      document.getElementById("leaveStartDateErr").innerHTML =
        "Please select the from date.";
      document.getElementById("leaveEndDateErr").innerHTML =
        "Please select the to date.";

      setErrorMessageMargin((prevState) => ({
        ...prevState,
        fromDateMsg: true,
        toDateMsg: true,
      }));
      return;
    }
    if (leaveStartDate === null || leaveStartDate === "") {
      document.getElementById("leaveStartDateErr").innerHTML =
        "Please select the from date";
      setErrorMessageMargin((prevState) => ({
        ...prevState,
        fromDateMsg: true,
      }));
      return;
    }
    if (leaveEndDate === null || leaveStartDate === "") {
      document.getElementById(
        "leaveEndDateErr"
      ).innerHTML = `Please select the to date.`;
      setErrorMessageMargin((prevState) => ({
        ...prevState,
        toDateMsg: true,
      }));
      return;
    }
    let { eligiblStartDate, eligiblEndDate } = handleCheckAppliedLeaveYear();
    if (
      leaveStartDate < eligiblStartDate ||
      leaveStartDate > eligiblEndDate ||
      leaveEndDate < eligiblStartDate ||
      leaveEndDate > eligiblEndDate
    ) {
      const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      const date = `${monthNames[new Date(eligiblStartDate).getMonth()]
        }-${new Date(eligiblStartDate).getFullYear()} To ${monthNames[new Date(eligiblEndDate).getMonth()]
        }-${new Date(eligiblEndDate).getFullYear()}`;
      document.getElementById(
        "appliedDaysErr"
      ).innerHTML = `You can apply leave only from ${date}. Please select the appropriate year to apply leave.`;
      return;
    }
    if (leaveEndDate < leaveStartDate) {
      document.getElementById("appliedDaysErr").innerHTML =
        '"To Date" should be greater than or equal to "From Date"';
      return;
    }
    let ele = dayBreakupDates.find((item) => {
      return item.comments === "LOCKED";
    });
    if (ele) {
      document.getElementById("appliedDaysErr").innerHTML = errorLnALocked;
      return;
    }
    document.getElementById("appliedDaysErr").innerHTML = "";
    if (leaveReason.trim().length <= 0) {
      document.getElementById("leaveAppReasonErr").innerHTML =
        "Please enter reason.";
      return;
    }
    document.getElementById("leaveAppReasonErr").innerHTML = "";

    let count;
    let finalApplyedLeaves = [];

    /**********  Start From   */
    if (leaveStartDate === leaveEndDate) {
      /**
       * Full Day
       */
      if (
        leaveClusterStart.q1 &&
        leaveClusterStart.q2 &&
        leaveClusterStart.q3 &&
        leaveClusterStart.q4
      ) {
        count = 0;
        applyedLeaveDates.forEach((dates) => {
          finalApplyedLeaves.push(`${dates}_4_1`);
        });
      } else if (
        /**
         * First Half
         */
        leaveClusterStart.q1 &&
        leaveClusterStart.q2 &&
        !leaveClusterStart.q3 &&
        !leaveClusterStart.q4
      ) {
        count = 0;
        applyedLeaveDates.forEach((dates) => {
          let exctactedTime = exctactTime(selectedStartDayTime);
          finalApplyedLeaves.push(`${dates}_2_1_${exctactedTime}`);
        });
      } else if (
        /**
         * Second Half
         */
        leaveClusterStart.q3 &&
        leaveClusterStart.q4 &&
        !leaveClusterStart.q1 &&
        !leaveClusterStart.q2
      ) {
        count = 0;
        applyedLeaveDates.forEach((dates) => {
          let exctactedTime = exctactTime(selectedStartDayTime);
          finalApplyedLeaves.push(`${dates}_2_2_${exctactedTime}`);
        });
      } else if (
        /**
         * First Quarter
         */
        leaveClusterStart.q1 &&
        !leaveClusterStart.q2 &&
        !leaveClusterStart.q3 &&
        !leaveClusterStart.q4
      ) {
        count = leaveType !== 20 ? 1 : 0;
        applyedLeaveDates.forEach((dates) => {
          let exctactedTime = exctactTime(selectedStartDayTime);
          finalApplyedLeaves.push(`${dates}_1_1_${exctactedTime}`);
        });
      } else if (
        /**
         * Second Quarter
         */
        !leaveClusterStart.q1 &&
        leaveClusterStart.q2 &&
        !leaveClusterStart.q3 &&
        !leaveClusterStart.q4
      ) {
        count = leaveType !== 20 ? 1 : 0;
        applyedLeaveDates.forEach((dates) => {
          let exctactedTime = exctactTime(selectedStartDayTime);
          finalApplyedLeaves.push(`${dates}_1_2_${exctactedTime}`);
        });
      } else if (
        /**
         * Third Quarter
         */
        !leaveClusterStart.q1 &&
        !leaveClusterStart.q2 &&
        leaveClusterStart.q3 &&
        !leaveClusterStart.q4
      ) {
        count = leaveType !== 20 ? 1 : 0;
        applyedLeaveDates.forEach((dates) => {
          let exctactedTime = exctactTime(selectedStartDayTime);
          finalApplyedLeaves.push(`${dates}_1_3_${exctactedTime}`);
        });
      } else if (
        /**
         * Forth Quarter
         */
        !leaveClusterStart.q1 &&
        !leaveClusterStart.q2 &&
        !leaveClusterStart.q3 &&
        leaveClusterStart.q4
      ) {
        count = leaveType !== 20 ? 1 : 0;
        applyedLeaveDates.forEach((dates) => {
          let exctactedTime = exctactTime(selectedStartDayTime);
          finalApplyedLeaves.push(`${dates}_1_4_${exctactedTime}`);
        });
      } else {
        count = 1;
      }
    } else {
      if (leaveEndDate !== leaveStartDate) {
        applyedLeaveDates.forEach((dates, inx) => {
          if (inx === 0) {
            /**
             * Full Day
             */
            if (
              leaveClusterStart.q1 &&
              leaveClusterStart.q2 &&
              leaveClusterStart.q3 &&
              leaveClusterStart.q4
            ) {
              count = 0;
              finalApplyedLeaves.push(`${dates}_4_1`);
            } else if (
              /**
               * First Half
               */
              leaveClusterStart.q1 &&
              leaveClusterStart.q2 &&
              !leaveClusterStart.q3 &&
              !leaveClusterStart.q4
            ) {
              count = 0;
              let exctactedTime = exctactTime(selectedStartDayTime);
              finalApplyedLeaves.push(`${dates}_2_1_${exctactedTime}`);
            } else if (
              /**
               * Second Half
               */
              leaveClusterStart.q3 &&
              leaveClusterStart.q4 &&
              !leaveClusterStart.q1 &&
              !leaveClusterStart.q2
            ) {
              count = 0;
              let exctactedTime = exctactTime(selectedStartDayTime);
              finalApplyedLeaves.push(`${dates}_2_2_${exctactedTime}`);
            } else if (
              /**
               * First Quarter
               */
              leaveClusterStart.q1 &&
              !leaveClusterStart.q2 &&
              !leaveClusterStart.q3 &&
              !leaveClusterStart.q4
            ) {
              count = leaveType !== 20 ? 1 : 0;
              let exctactedTime = exctactTime(selectedStartDayTime);
              finalApplyedLeaves.push(`${dates}_1_1_${exctactedTime}`);
            } else if (
              /**
               * Second Quarter
               */
              !leaveClusterStart.q1 &&
              leaveClusterStart.q2 &&
              !leaveClusterStart.q3 &&
              !leaveClusterStart.q4
            ) {
              count = leaveType !== 20 ? 1 : 0;
              let exctactedTime = exctactTime(selectedStartDayTime);
              finalApplyedLeaves.push(`${dates}_1_2_${exctactedTime}`);
            } else if (
              /**
               * Third Quarter
               */
              !leaveClusterStart.q1 &&
              !leaveClusterStart.q2 &&
              leaveClusterStart.q3 &&
              !leaveClusterStart.q4
            ) {
              count = leaveType !== 20 ? 1 : 0;
              let exctactedTime = exctactTime(selectedStartDayTime);
              finalApplyedLeaves.push(`${dates}_1_3_${exctactedTime}`);
            } else if (
              /**
               * Forth Quarter
               */
              !leaveClusterStart.q1 &&
              !leaveClusterStart.q2 &&
              !leaveClusterStart.q3 &&
              leaveClusterStart.q4
            ) {
              count = leaveType !== 20 ? 1 : 0;
              let exctactedTime = exctactTime(selectedStartDayTime);
              finalApplyedLeaves.push(`${dates}_1_4_${exctactedTime}`);
            } else {
              count = 1;
            }
          } else if (inx === applyedLeaveDates.length - 1) {
            /**
             * Leave Cluster End
             */
            /**
             * Full Day
             */
            if (
              leaveClusterEnd.q1 &&
              leaveClusterEnd.q2 &&
              leaveClusterEnd.q3 &&
              leaveClusterEnd.q4
            ) {
              count = 0;
              finalApplyedLeaves.push(`${dates}_4_1`);
            } else if (
              /**
               * First Half
               */
              leaveClusterEnd.q1 &&
              leaveClusterEnd.q2 &&
              !leaveClusterEnd.q3 &&
              !leaveClusterEnd.q4
            ) {
              count = 0;
              let exctactedTime = exctactTime(selectedEndDayTime);
              finalApplyedLeaves.push(`${dates}_2_1_${exctactedTime}`);
            } else if (
              /**
               * Second Half
               */
              leaveClusterEnd.q3 &&
              leaveClusterEnd.q4 &&
              !leaveClusterEnd.q1 &&
              !leaveClusterEnd.q2
            ) {
              count = 0;
              let exctactedTime = exctactTime(selectedEndDayTime);
              finalApplyedLeaves.push(`${dates}_2_2_${exctactedTime}`);
            } else if (
              /**
               * First Quarter
               */
              leaveClusterEnd.q1 &&
              !leaveClusterEnd.q2 &&
              !leaveClusterEnd.q3 &&
              !leaveClusterEnd.q4
            ) {
              count = leaveType !== 20 ? 1 : 0;
              let exctactedTime = exctactTime(selectedEndDayTime);
              finalApplyedLeaves.push(`${dates}_1_1_${exctactedTime}`);
            } else if (
              /**
               * Second Quarter
               */
              !leaveClusterEnd.q1 &&
              leaveClusterEnd.q2 &&
              !leaveClusterEnd.q3 &&
              !leaveClusterEnd.q4
            ) {
              count = leaveType !== 20 ? 1 : 0;
              let exctactedTime = exctactTime(selectedEndDayTime);
              finalApplyedLeaves.push(`${dates}_1_2_${exctactedTime}`);
            } else if (
              /**
               * Third Quarter
               */
              !leaveClusterEnd.q1 &&
              !leaveClusterEnd.q2 &&
              leaveClusterEnd.q3 &&
              !leaveClusterEnd.q4
            ) {
              count = leaveType !== 20 ? 1 : 0;
              let exctactedTime = exctactTime(selectedEndDayTime);
              finalApplyedLeaves.push(`${dates}_1_3_${exctactedTime}`);
            } else if (
              /**
               * Forth Quarter
               */
              !leaveClusterEnd.q1 &&
              !leaveClusterEnd.q2 &&
              !leaveClusterEnd.q3 &&
              leaveClusterEnd.q4
            ) {
              count = leaveType !== 20 ? 1 : 0;
              let exctactedTime = exctactTime(selectedEndDayTime);
              finalApplyedLeaves.push(`${dates}_1_4_${exctactedTime}`);
            } else {
              count = 1;
            }
          } else {
            finalApplyedLeaves.push(`${dates}_4_1`);
          }
        });
      }
    }
    /***********End To  */
    if (count === 1) {
      document.getElementById("leaveClusterDayBreakupErr").innerHTML =
        errorLnADayBreak;
      return;
    }
    if (appliedDays === 0) {
      let stopFuther = false;

      applyedLeaveDates.forEach((applyDate) => {
        if (isLeaveApplyAllowed(new Date(applyDate))) {
          stopFuther = true;
        }
      });

      if (stopFuther) {
        document.getElementById("appliedDaysErr").innerHTML = errorLnALocked;
      } else {
        document.getElementById("appliedDaysErr").innerHTML =
          appliedLeaveDayError || errorLnAAlreadyApplied;
      }
      return;
    }
    const check = checkEligiblityForLeave();
    if (!check) {
      document.getElementById("leaveClusterDayBreakupErr").innerHTML =
        "Insufficient leave balance.";
      return;
    }
    if (selectedDayWeekOff) {
      document.getElementById("appliedDaysErr").innerHTML =
        errorLnASelectWorkingDay;
      return;
    }
    document.getElementById("leaveClusterDayBreakupErr").innerHTML = "";
    document.getElementById("appliedDaysErr").innerHTML = "";

    if (categoryName === SPECIAL_ATTENDANCE) {
      let selectedAttendanceType = specialAttendanceData.find((obj) => {
        return obj.Id === leaveType;
      });
      if (
        ["YES", "Yes"].includes(selectedAttendanceType.documentsRequired) &&
        !attachmentName
      ) {
        getNotify(WARNING, fillMandatoryFields);
        document.getElementById("documentRequiredErr").innerHTML =
          "Please upload the required document";
        return;
      }
    } else {
      let selectedLeaveType = leaveTypeData.find((obj) => {
        return obj.Id === leaveType;
      });
      if (
        ["YES", "Yes"].includes(selectedLeaveType.documentsRequired) &&
        !attachmentName
      ) {
        getNotify(WARNING, fillMandatoryFields);
        document.getElementById("documentRequiredErr").innerHTML =
          "Please upload the required document";
        return;
      }
    }
    document.getElementById("documentRequiredErr").innerHTML = "";
    if (addFormObject.validate() && count === 0) {
      const getBREData = await getBREDataForLeave(leaveType);
      let payload2 = {
        userId: authUser,
        leaveClusterId: leaveType,
        startDate: leaveStartDate,
        endDate: leaveEndDate,
        totalNoOfDaysLeaveApplied: appliedDays,
        notifyUsersList: notificationTo,
        attachmentName: attachmentName,
        reason: leaveReason,
        //props.leaveApplicationId > 0 ? dayBreakText:
        dateDayBreak: finalApplyedLeaves.toString(),
        leaveCancelReason: "",
        relieverId: reliever,
        leaveStatus: "1",
        approverId: 0,
        fyId: fyId,
        leaveApplicationId: props.leaveApplicationId,
        applyFor: applyFor === null ? 0 : applyFor,
        isSpecialAttendance: categoryName === SPECIAL_ATTENDANCE,
      };
      payload2 = {
        ...payload2,
        supervisorApprovalRequired: getBREData.enum_supervisor_approval,
        hrApprovalRequired: getBREData.enum_HR_approval,
        autoApprovalRequired: getBREData.enum_auto_approval,
        noOfHrsToAutoApprove: getBREData.int_no_hrs_to_approval,
      };

      const getBREDataViolation = await getBREDataForLeaveViolation(
        leaveType,
        appliedDays
      );
      if (
        getBREDataViolation.int_min_no_leaves_days_allowed !== "undefined" &&
        categoryName !== SPECIAL_ATTENDANCE
      ) {
        const startDate = addDays(
          new Date(),
          getBREDataViolation.int_min_no_leaves_days_allowed
        ).setHours(0, 0, 0, 0);
        const endDate = addDays(
          new Date(),
          getBREDataViolation.int_max_no_leaves_days_allowed
        ).setHours(0, 0, 0, 0);

        const m_leaveStartDate = new Date(leaveStartDate + " 00:00:00");
        const m_leaveEndDate = new Date(leaveEndDate + " 00:00:00");

        if (startDate > m_leaveStartDate || endDate < m_leaveEndDate) {
          let msg = "";
          if (startDate > m_leaveStartDate) {
            msg =
              "To apply for  " +
              appliedDays.toString() +
              " days leave, please plan your leaves from  " +
              getDisplayDateOnly(new Date(startDate));
            document.getElementById("leaveStartDateErr").innerHTML =
              "Leave rule violated for from date.";
          }
          if (endDate < m_leaveEndDate) {
            if (msg != "") {
              msg = msg + " to " + getDisplayDateOnly(new Date(endDate));
            } else {
              msg =
                "To apply for  " +
                appliedDays.toString() +
                " days leave, please close your leaves on or before " +
                getDisplayDateOnly(new Date(endDate));
            }
            document.getElementById("leaveEndDateErr").innerHTML =
              "Leave rule violated for to date.";
          }
          msg = msg + ".";

          const msgData = {
            violationMsgOpen: true,
            ViolationMsgTitle: "Leaves Rule Violation",
            violationMsg: "Leave Rule Violation",
            violationSubMsg: msg,
          };
          setState((preState) => ({ ...preState, ...msgData }));
        } else {
          setDataToSave(payload2);
          setShowConform(true);
        }
      } else {
        if (categoryName === SPECIAL_ATTENDANCE) {
          setDataToSave(payload2);
          setShowConform(true);
        } else {
          getNotify(ERROR, "Leave rules violation issue.");
        }
      }
      props.updateLnAData({ leaveApplyLoadName: "leave-tabs" });
    } else {
      getNotify(WARNING, fillMandatoryFields);
    }
  };

  const getCurrentFinancialYear = (fId) => {
    let effectiveStartDate = "";
    let effectiveEndDate = "";
    financialYearData &&
      financialYearData.forEach((item) => {
        if (item.Id === fId) {
          effectiveStartDate = item.EffectiveStartDate;
          effectiveEndDate = item.EffectiveEndDate;
        }
      });
    return { effectiveStartDate, effectiveEndDate };
  };
  const getValidDatesDifference = (d1, d2) => {
    let date1 = new Date(d1);
    let date2 = new Date(d2);
    return parseInt((date1 - date2) / (1000 * 60 * 60 * 24), 10);
  };
  const getOneDayForward = (dt) => {
    if (categoryName !== SPECIAL_ATTENDANCE && leaveType !== 20) {
      const { effectiveEndDate } = getCurrentFinancialYear(fyId);
      let date = new Date(dt);
      let validDays = getValidDatesDifference(
        getMySqlDateFormat(effectiveEndDate),
        getMySqlDateFormat(date)
      );
      let arr = leaveTypeList.filter((item) => {
        return item.leaveClusterId == leaveType;
      });
      let fwdDay =
        arr.length > 0 && arr[0].availableBalance - validDays > 0
          ? validDays
          : arr.length > 0 && arr[0].availableBalance;
      date.setDate(date.getDate() + (fwdDay - 1));
      return date;
    }
  };

  const getMinMaxDate = (pMinDate, pMaxDate) => {
    let maxDate;
    let minDate;
    const { effectiveStartDate, effectiveEndDate } =
      getCurrentFinancialYear(fyId);
    const fyStartDate = new Date(effectiveStartDate);
    const fyEndDate = new Date(effectiveEndDate);
    let data = {};
    if (
      (pMinDate <= fyStartDate && pMaxDate >= fyEndDate) ||
      (pMinDate >= fyStartDate && pMinDate <= fyEndDate) ||
      (pMaxDate >= fyStartDate && pMaxDate <= fyEndDate)
    ) {
      data = { selectionEnabled: true };
      if (fyEndDate > new Date(pMaxDate)) {
        maxDate = pMaxDate;
      } else {
        maxDate = fyEndDate;
      }
      if (fyStartDate < new Date(pMinDate)) {
        minDate = pMinDate;
      } else {
        minDate = fyStartDate;
      }
      document.getElementById("leaveStartDateErr").innerHTML = "";
      document.getElementById("leaveEndDateErr").innerHTML = "";
    } else {
      let months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      document.getElementById("leaveStartDateErr").innerHTML =
        "You cannot apply leave for " +
        months[fyStartDate.getMonth()] +
        " " +
        fyStartDate.getFullYear() +
        " - " +
        months[fyEndDate.getMonth()] +
        " " +
        fyEndDate.getFullYear() +
        ".";
      document.getElementById("leaveEndDateErr").innerHTML =
        "You cannot apply leave for " +
        months[fyStartDate.getMonth()] +
        " " +
        fyStartDate.getFullYear() +
        " - " +
        months[fyEndDate.getMonth()] +
        " " +
        fyEndDate.getFullYear() +
        ".";
      minDate = fyStartDate;
      maxDate = fyEndDate;
      data = {
        selectionEnabled: false,
        startDate: null,
        endDate: null,
      };
    }
    setState((preState) => ({ ...preState, ...data }));
    return { maxDate, minDate };
  };

  const getStartDateDayBreak = () => {
    //NOSONAR
    let dayBreakStartStr = "4_1";
    if (
      !state.leaveClusterStart.q1 &&
      !state.leaveClusterStart.q2 &&
      state.leaveClusterStart.q3 &&
      state.leaveClusterStart.q4
    ) {
      dayBreakStartStr = "2_2";
    } else if (
      state.leaveClusterStart.q1 &&
      state.leaveClusterStart.q2 &&
      !state.leaveClusterStart.q3 &&
      !state.leaveClusterStart.q4
    ) {
      dayBreakStartStr = "2_1";
    } else if (
      state.leaveClusterStart.q1 &&
      state.leaveClusterStart.q2 &&
      state.leaveClusterStart.q3 &&
      !state.leaveClusterStart.q4
    ) {
      dayBreakStartStr = "3_1";
    } else if (
      !state.leaveClusterStart.q1 &&
      state.leaveClusterStart.q2 &&
      state.leaveClusterStart.q3 &&
      state.leaveClusterStart.q4
    ) {
      dayBreakStartStr = "3_2";
    } else if (
      state.leaveClusterStart.q1 &&
      !state.leaveClusterStart.q2 &&
      !state.leaveClusterStart.q3 &&
      !state.leaveClusterStart.q4
    ) {
      dayBreakStartStr = "1_1";
    } else if (
      !state.leaveClusterStart.q1 &&
      state.leaveClusterStart.q2 &&
      !state.leaveClusterStart.q3 &&
      !state.leaveClusterStart.q4
    ) {
      dayBreakStartStr = "1_2";
    } else if (
      !state.leaveClusterStart.q1 &&
      !state.leaveClusterStart.q2 &&
      state.leaveClusterStart.q3 &&
      !state.leaveClusterStart.q4
    ) {
      dayBreakStartStr = "1_3";
    } else if (
      !state.leaveClusterStart.q1 &&
      !state.leaveClusterStart.q2 &&
      !state.leaveClusterStart.q3 &&
      state.leaveClusterStart.q4
    ) {
      dayBreakStartStr = "1_4";
    }
    return dayBreakStartStr;
  };

  const getEndDateDayBreak = () => {
    let dayBreakEndStr = "4_1";
    if (
      state.leaveClusterEnd.q1 &&
      state.leaveClusterEnd.q2 &&
      state.leaveClusterEnd.q3 &&
      state.leaveClusterEnd.q4
    ) {
      dayBreakEndStr = "4_1";
    } else if (
      !state.leaveClusterEnd.q1 &&
      !state.leaveClusterEnd.q2 &&
      state.leaveClusterEnd.q3 &&
      state.leaveClusterEnd.q4
    ) {
      dayBreakEndStr = "2_2";
    } else if (
      state.leaveClusterEnd.q1 &&
      state.leaveClusterEnd.q2 &&
      !state.leaveClusterEnd.q3 &&
      !state.leaveClusterEnd.q4
    ) {
      dayBreakEndStr = "2_1";
    } else if (
      state.leaveClusterEnd.q1 &&
      state.leaveClusterEnd.q2 &&
      state.leaveClusterEnd.q3 &&
      !state.leaveClusterEnd.q4
    ) {
      dayBreakEndStr = "3_1";
    } else if (
      !state.leaveClusterEnd.q1 &&
      state.leaveClusterEnd.q2 &&
      state.leaveClusterEnd.q3 &&
      state.leaveClusterEnd.q4
    ) {
      dayBreakEndStr = "3_2";
    } else if (
      state.leaveClusterEnd.q1 &&
      !state.leaveClusterEnd.q2 &&
      !state.leaveClusterEnd.q3 &&
      !state.leaveClusterEnd.q4
    ) {
      dayBreakEndStr = "1_1";
    } else if (
      !state.leaveClusterEnd.q1 &&
      state.leaveClusterEnd.q2 &&
      !state.leaveClusterEnd.q3 &&
      !state.leaveClusterEnd.q4
    ) {
      dayBreakEndStr = "1_2";
    } else if (
      !state.leaveClusterEnd.q1 &&
      !state.leaveClusterEnd.q2 &&
      state.leaveClusterEnd.q3 &&
      !state.leaveClusterEnd.q4
    ) {
      dayBreakEndStr = "1_3";
    } else if (
      !state.leaveClusterEnd.q1 &&
      !state.leaveClusterEnd.q2 &&
      !state.leaveClusterEnd.q3 &&
      state.leaveClusterEnd.q4
    ) {
      dayBreakEndStr = "1_4";
    }
    return dayBreakEndStr;
  };

  const handleCheckAppliedLeaveYear = () => {
    let selectedYearData = financialYearData.find((ele) => {
      return fyId === ele.Id;
    });
    if (selectedYearData) {
      let eligiblStartDate = selectedYearData.EffectiveStartDate;
      let eligiblEndDate = selectedYearData.EffectiveEndDate;
      return { eligiblStartDate, eligiblEndDate };
    }
  };

  const onChangeApplyLeave = async (e, type) => {
    // NOSONAR
    let data = {};
    document.getElementById("leaveClusterDayBreakupErr").innerHTML = "";
    switch (type) {
      case "leaveType":
        if (e.itemData !== null) {
          props.updateLnAData({
            leaveType: e.itemData.Id,
            isWeekendCounted:
              e.itemData.Id === 5 && categoryName !== SPECIAL_ATTENDANCE
                ? "1"
                : e.itemData.isWeekendCounted,
            isStreched: e.itemData.isStreched,
            dayBreakBackgroundColor: e.itemData.color,
            leaveStartDate: null,
            leaveEndDate: null,
            documentsRequired: e.itemData.documentsRequired,
            dayBreakupDates: [],
          });
          await updateStartDateEndDate(e.itemData.Id);
          let datas = {};
          datas = {
            startDate: null,
            endDate: null,
            appliedDays: 0,
            documentsRequired: e.itemData.documentsRequired,
          };
          if (e.itemData.Id === 20) {
            datas = {
              ...datas,
              leaveClusterStart: { q1: true, q2: null, q3: null, q4: null },
              leaveClusterEnd: { q1: true, q2: null, q3: null, q4: null },
            };
          } else {
            datas = {
              ...datas,
              leaveClusterStart: { q1: true, q2: true, q3: true, q4: true },
              leaveClusterEnd: { q1: true, q2: true, q3: true, q4: true },
            };
          }
          setState((preState) => ({ ...preState, ...datas }));
        } else {
          props.updateLnAData({
            leaveType: null,
            isWeekendCounted: "",
            isStreched: "",
            dayBreakupDates: [],
          });
        }
        document.getElementById("documentRequiredErr").innerHTML = "";
        break;
      case "attendenceType":
        if (e.itemData !== null) {
          props.updateLnAData({ attendenceType: e.itemData.Id });
        } else {
          props.updateLnAData({ attendenceType: null });
        }
        break;
      case "financialYear":
        if (e.itemData !== null) {
          props.updateLnAData({
            fyId: e.itemData.Id,
            updateFinancialYear: "updated",
          });
        } else {
          props.updateLnAData({ fyId: null, updateFinancialYear: "updated" });
        }
        break;
      case "leaveStartDate":
        let date1 = leaveDateFormat(e.value);
        let dateMat = leaveDateFormat(getOneDayForward(e.value));
        try {
          if (e.value) {
            document.getElementById("leaveStartDateErr").innerHTML = "";
            document.getElementById("leaveEndDateErr").innerHTML = "";
            setErrorMessageMargin((prevState) => ({
              ...prevState,
              fromDateMsg: false,
              toDateMsg: false,
            }));
          }
        } catch (err) {
          console.log("error", err);
        }

        if (e.value !== null) {
          if (
            isStreched === "1" &&
            categoryName !== SPECIAL_ATTENDANCE &&
            leaveType !== 20
          ) {
            data = {
              startDate: e.target.inputElement.value,
              endDate: getOneDayForward(e.target.inputElement.value),
              leaveClusterStart: { q1: true, q2: true, q3: true, q4: true },
              selectedStartDay: 1,
            };
            setTimeout(() => {
              setState((preState) => ({ ...preState, ...data }));
              props.updateLnAData({
                leaveStartDate: date1,
                leaveEndDate: dateMat,
              });
            }, 10);
          } else if (
            leaveEndDate === null ||
            (new Date(date1) > new Date(leaveEndDate) && leaveType !== 20)
          ) {
            data = {
              startDate: e.target.inputElement.value,
              endDate: e.target.inputElement.value,
              leaveClusterStart: { q1: true, q2: true, q3: true, q4: true },
              leaveClusterEnd: { q1: true, q2: true, q3: true, q4: true },
            };
            props.updateLnAData({ leaveStartDate: date1, leaveEndDate: date1 });
          } else {
            data = {
              startDate: e.target.inputElement.value,
              leaveClusterStart: { q1: true, q2: true, q3: true, q4: true },
            };
            if (selectedStartDay === 2 && selectedStartDayBreakup == 1)
              data.leaveClusterStart = {
                q1: true,
                q2: true,
                q3: false,
                q4: false,
              };
            if (selectedStartDay === 2 && selectedStartDayBreakup == 2)
              data.leaveClusterStart = {
                q1: false,
                q2: false,
                q3: true,
                q4: true,
              };
            const tempData = { leaveStartDate: date1 };
            if (leaveType === 20) {
              tempData.leaveEndDate = date1;
              data.selectedStartDayTime = handleTimeCoversion(
                state.workDaySettingData[0].startTime
              );
            }
            props.updateLnAData({ ...tempData });
          }
          if (leaveType === 20) {
            data = {
              startDate: e.target.inputElement.value,
              endDate: e.target.inputElement.value,
              leaveClusterStart: { q1: true, q2: false, q3: false, q4: false },
              selectedStartDayTime: handleTimeCoversion(
                state.workDaySettingData[0].startTime
              ),
            };
            setState((preState) => ({
              ...preState,
              ...data,
              selectedStartDay: 1,
            }));
            props.updateLnAData({ leaveStartDate: date1, leaveEndDate: date1 });
          } else {
            if (
              (isStreched !== "1" && categoryName !== SPECIAL_ATTENDANCE) ||
              categoryName === SPECIAL_ATTENDANCE
            ) {
              setState((preState) => ({
                ...preState,
                ...data,
                selectedStartDay: 1,
              }));
              setTimeout(() => {
                let validateData = validateDayBreakUp();
                if (
                  props.leaveEndDate === null ||
                  new Date(date1) > new Date(leaveEndDate)
                ) {
                  getLeavesDates(
                    date1,
                    date1,
                    validateData.leaveStartDayCount,
                    validateData.leaveEndDayCount,
                    leaveApplicationId
                  );
                } else {
                  getLeavesDates(
                    date1,
                    props.leaveEndDate,
                    validateData.leaveStartDayCount,
                    validateData.leaveEndDayCount,
                    leaveApplicationId
                  );
                }
              }, 10);
            }
          }
        }
        break;
      case "selectStartDay":
        if (e.itemData !== null) {
          if (e.itemData.Id === 1) selectDayLeaveStart("full", true);
          if (e.itemData.Id === 2) selectDayLeaveStart(1, true);
          setState((preState) => ({
            ...preState,
            selectedStartDay: e.itemData.Id,
            selectedStartDayBreakup: 1,
            selectedStartDayTime: handleTimeCoversion(
              state.workDaySettingData[0].startTime
            ),
          }));
        }
        break;
      case "selectStartDayBreakup":
        if (e.itemData !== null) {
          let updatedTimeFormat = handleTimeCoversion(
            state.workDaySettingData[0].startTime
          );
          updatedTimeFormat = new Date(updatedTimeFormat);
          if (e.itemData.Id === 1 && leaveType !== 20) {
            selectDayLeaveStart(1, true);
          }
          if (e.itemData.Id === 2 && leaveType !== 20) {
            updatedTimeFormat.setHours(updatedTimeFormat.getHours() + 4);
            selectDayLeaveStart(2, true);
          }
          // in case of QL
          if (e.itemData.Id === 1 && leaveType === 20) {
            selectDayLeaveStart(1, true);
          }
          if (e.itemData.Id === 2 && leaveType === 20) {
            updatedTimeFormat.setHours(updatedTimeFormat.getHours() + 2);
            selectDayLeaveStart(2, true);
          }
          if (e.itemData.Id === 3 && leaveType === 20) {
            updatedTimeFormat = handleTimeCoversion(
              state.workDaySettingData[0].endTime
            );
            updatedTimeFormat = new Date(updatedTimeFormat);
            updatedTimeFormat.setHours(updatedTimeFormat.getHours() - 4);
            selectDayLeaveStart(3, true);
          }
          if (e.itemData.Id === 4 && leaveType === 20) {
            updatedTimeFormat = handleTimeCoversion(
              state.workDaySettingData[0].endTime
            );
            updatedTimeFormat = new Date(updatedTimeFormat);
            updatedTimeFormat.setHours(updatedTimeFormat.getHours() - 2);
            selectDayLeaveStart(4, true);
          }
          setState((preState) => ({
            ...preState,
            selectedStartDayBreakup: e.itemData.Id,
            selectedStartDayTime: updatedTimeFormat,
          }));
        }
        break;
      case "selectStartDayTime":
        if (e !== null) {
          setState((preState) => ({ ...preState, selectedStartDayTime: e }));
        }
        break;
      case "selectEndDay":
        if (e.itemData !== null) {
          if (e.itemData.Id === 1) selectDayLeaveEnd("full", true);
          if (e.itemData.Id === 2) selectDayLeaveEnd(1, true);
          setState((preState) => ({
            ...preState,
            selectedEndDay: e.itemData.Id,
            selectedEndDayBreakup: 1,
            selectedEndDayTime: handleTimeCoversion(
              state.workDaySettingData[0].startTime
            ),
          }));
        }
        break;
      case "selectEndDayBreakup":
        if (e.itemData !== null) {
          let updatedTimeFormat;
          if (e.itemData.Id === 2) {
            updatedTimeFormat = handleTimeCoversion(
              state.workDaySettingData[0].startTime
            );
            updatedTimeFormat = new Date(updatedTimeFormat);
            updatedTimeFormat.setHours(updatedTimeFormat.getHours() + 4);
            selectDayLeaveEnd(2, true);
          } else {
            updatedTimeFormat = handleTimeCoversion(
              state.workDaySettingData[0].startTime
            );
            selectDayLeaveEnd(1, true);
          }
          setState((preState) => ({
            ...preState,
            selectedEndDayBreakup: e.itemData.Id,
            selectedEndDayTime: updatedTimeFormat,
          }));
        }
        break;
      case "selectEndDayTime":
        if (e !== null) {
          setState((preState) => ({ ...preState, selectedEndDayTime: e }));
        }
        break;
      case "leaveEndDate":
        let date2 = leaveDateFormat(e.value);
        if (e.value) {
          document.getElementById("leaveEndDateErr").innerHTML = "";
          setErrorMessageMargin((prevState) => ({
            ...prevState,
            toDateMsg: false,
          }));
        }
        data = {
          endDate: e.target.inputElement.value,
          selectedEndDay: 1,
        };
        setState((preState) => ({ ...preState, ...data }));
        if (selectedEndDay === 2 && selectedEndDayBreakup == 1)
          data.leaveClusterStart = { q1: true, q2: true, q3: false, q4: false };
        if (selectedEndDay === 2 && selectedEndDayBreakup == 2)
          data.leaveClusterStart = { q1: false, q2: false, q3: true, q4: true };
        props.updateLnAData({ leaveEndDate: date2 });
        break;
      case "leaveReason":
        const sanitizedValue = removeTralingSpaces(e.target.value);
        props.updateLnAData({
          leaveReason: sanitizedValue,
          leaveApplyLoadName: "leave-notification-list",
        });
        break;
      case "notificationTo":
        props.updateLnAData({ notificationTo: "" });
        break;
      case "reliever":
        if (e.itemData !== null) {
          props.updateLnAData({ reliever: e.itemData.UserId });
        } else {
          props.updateLnAData({ reliever: null });
        }
        break;
      case "apply":
        if (e.itemData !== null) {
          if (e.itemData.name === "Self") {
            props.updateLnAData({ applyFor: authUser, leaveType: null });
            getMasterData(authUser);
          } else {
            props.updateLnAData({ applyFor: e.itemData.Id, leaveType: null });
            getMasterData(e.itemData.Id);
          }
          setTimeout(() => {
            try {
              const val = document.getElementById("leaveBalanceGrid");
              val && val.ej2_instances[0].refresh();
            } catch (err) {
              console.log(err);
            }
          }, 800);
        } else {
          props.updateLnAData({ applyFor: null });
        }
        break;
      default:
    }
  };

  const selectDayLeaveStart = (qtype, qSelected) => {
    // NOSONAR
    let data = {
      leaveClusterStart: state.leaveClusterStart,
    };
    if (leaveType === 20) {
      if (qtype === 1) {
        data.leaveClusterStart.q1 = qSelected;
        data.leaveClusterStart.q2 = false;
        data.leaveClusterStart.q3 = false;
        data.leaveClusterStart.q4 = false;
      }
      if (qtype === 2) {
        data.leaveClusterStart.q1 = false;
        data.leaveClusterStart.q2 = qSelected;
        data.leaveClusterStart.q3 = false;
        data.leaveClusterStart.q3 = false;
      }
      if (qtype === 3) {
        data.leaveClusterStart.q1 = false;
        data.leaveClusterStart.q2 = false;
        data.leaveClusterStart.q3 = qSelected;
        data.leaveClusterStart.q4 = false;
      }
      if (qtype === 4) {
        data.leaveClusterStart.q1 = false;
        data.leaveClusterStart.q2 = false;
        data.leaveClusterStart.q3 = false;
        data.leaveClusterStart.q4 = qSelected;
      }
    } else {
      //full = full day , 1 = firsthalf and 2 = secondhalf
      if (qtype === "full") {
        data.leaveClusterStart.q1 = qSelected;
        data.leaveClusterStart.q2 = qSelected;
        data.leaveClusterStart.q3 = qSelected;
        data.leaveClusterStart.q4 = qSelected;
      }
      if (qtype === 1) {
        data.leaveClusterStart.q1 = qSelected;
        data.leaveClusterStart.q2 = qSelected;
        data.leaveClusterStart.q3 = false;
        data.leaveClusterStart.q4 = false;
      }
      if (qtype === 2) {
        data.leaveClusterStart.q3 = qSelected;
        data.leaveClusterStart.q4 = qSelected;
        data.leaveClusterStart.q1 = false;
        data.leaveClusterStart.q2 = false;
      }
    }
    setState((preState) => ({ ...preState, ...data }));

    let validateData = validateDayBreakUp();
    if (!validateData.startDayBreakCount) {
      getLeavesDates(
        leaveStartDate,
        leaveEndDate,
        validateData.leaveStartDayCount,
        validateData.leaveEndDayCount,
        leaveApplyLoadName === "leave-edit" && props.leaveApplicationId
      );
      if (document.getElementById("leaveClusterDayBreakupErr"))
        document.getElementById("leaveClusterDayBreakupErr").innerHTML = "";
    } else {
      document.getElementById("leaveClusterDayBreakupErr").innerHTML =
        errorLnADayBreak;
    }
  };
  const selectDayLeaveEnd = (qtype, qSelected) => {
    let data = {
      leaveClusterEnd: state.leaveClusterEnd,
    };
    if (leaveType === 20) {
      if (qtype === 1) {
        data.leaveClusterEnd.q1 = !qSelected;
      }
      if (qtype === 2) {
        data.leaveClusterEnd.q2 = !qSelected;
      }
      if (qtype === 3) {
        data.leaveClusterEnd.q3 = !qSelected;
      }
      if (qtype === 4) {
        data.leaveClusterEnd.q4 = !qSelected;
      }
    } else {
      //full = full day , 1 = firsthalf and 2 = secondhalf
      if (qtype === "full") {
        data.leaveClusterEnd.q1 = qSelected;
        data.leaveClusterEnd.q2 = qSelected;
        data.leaveClusterEnd.q3 = qSelected;
        data.leaveClusterEnd.q4 = qSelected;
      }
      if (qtype === 1) {
        data.leaveClusterEnd.q1 = qSelected;
        data.leaveClusterEnd.q2 = qSelected;
        data.leaveClusterEnd.q3 = false;
        data.leaveClusterEnd.q4 = false;
      }
      if (qtype === 2) {
        data.leaveClusterEnd.q3 = qSelected;
        data.leaveClusterEnd.q4 = qSelected;
        data.leaveClusterEnd.q1 = false;
        data.leaveClusterEnd.q2 = false;
      }
    }
    setState((preState) => ({ ...preState, ...data }));

    const validateData = validateDayBreakUp();
    if (
      validateData.leaveStartDayCount !== 0 &&
      validateData.leaveEndDayCount !== 0 &&
      !validateData.endDayBreakCount
    ) {
      getLeavesDates(
        leaveStartDate,
        leaveEndDate,
        validateData.leaveStartDayCount,
        validateData.leaveEndDayCount,
        leaveApplyLoadName === "leave-edit" && props.leaveApplicationId
      );
      document.getElementById("leaveClusterDayBreakupErr").innerHTML = "";
    } else {
      document.getElementById("leaveClusterDayBreakupErr").innerHTML =
        errorLnADayBreak;
    }
  };

  const validateDayBreakUp = () => {
    // NOSONAR
    let startDayBreakCount = 0;
    let endDayBreakCount = 0;
    let leaveStartDayCount = 0;
    let leaveEndDayCount = 0;
    /**
     * If leave start date and end date is not equal the execute this block.
     */

    /**
     * Leave Cluster Start
     */
    /**
     * Full Day
     */
    if (
      leaveClusterStart.q1 &&
      leaveClusterStart.q2 &&
      leaveClusterStart.q3 &&
      leaveClusterStart.q4
    ) {
      leaveStartDayCount = 1;
    } else if (
      /**
       * First Half
       */
      leaveClusterStart.q1 &&
      leaveClusterStart.q2 &&
      !leaveClusterStart.q3 &&
      !leaveClusterStart.q4
    ) {
      leaveStartDayCount = 0.5;
    } else if (
      /**
       * Second Half
       */
      leaveClusterStart.q3 &&
      leaveClusterStart.q4 &&
      !leaveClusterStart.q1 &&
      !leaveClusterStart.q2
    ) {
      leaveStartDayCount = 0.5;
    } else if (
      /**
       * First Quarter
       */
      leaveClusterStart.q1 &&
      !leaveClusterStart.q2 &&
      !leaveClusterStart.q3 &&
      !leaveClusterStart.q4
    ) {
      leaveStartDayCount = 0.25;
      if (leaveType !== 20) {
        startDayBreakCount = 1;
      }
    } else if (
      /**
       * Second Quarter
       */
      !leaveClusterStart.q1 &&
      leaveClusterStart.q2 &&
      !leaveClusterStart.q3 &&
      !leaveClusterStart.q4
    ) {
      // NOSONAR
      leaveStartDayCount = 0.25;
      if (leaveType !== 20) {
        startDayBreakCount = 1;
      }
    } else if (
      /**
       * Third Quarter
       */
      !leaveClusterStart.q1 &&
      !leaveClusterStart.q2 &&
      leaveClusterStart.q3 &&
      !leaveClusterStart.q4
    ) {
      // NOSONAR
      leaveStartDayCount = 0.25;
      if (leaveType !== 20) {
        startDayBreakCount = 1;
      }
    } else if (
      /**
       * Forth Quarter
       */
      !leaveClusterStart.q1 &&
      !leaveClusterStart.q2 &&
      !leaveClusterStart.q3 &&
      leaveClusterStart.q4
    ) {
      // NOSONAR
      leaveStartDayCount = 0.25;
      if (leaveType !== 20) {
        startDayBreakCount = 1;
      }
    } else {
      startDayBreakCount = 1;
    }
    if (leaveEndDate !== leaveStartDate) {
      /**
       * Leave Cluster End
       */
      /**
       * Full Day
       */
      if (
        leaveClusterEnd.q1 &&
        leaveClusterEnd.q2 &&
        leaveClusterEnd.q3 &&
        leaveClusterEnd.q4
      ) {
        leaveEndDayCount = 1;
      } else if (
        /**
         * First Half
         */
        leaveClusterEnd.q1 &&
        leaveClusterEnd.q2 &&
        !leaveClusterEnd.q3 &&
        !leaveClusterEnd.q4
      ) {
        leaveEndDayCount = 0.5;
      } else if (
        /**
         * Second Half
         */
        leaveClusterEnd.q3 &&
        leaveClusterEnd.q4 &&
        !leaveClusterEnd.q1 &&
        !leaveClusterEnd.q2
      ) {
        leaveEndDayCount = 0.5;
      } else if (
        /**
         * First Quarter
         */
        leaveClusterEnd.q1 &&
        !leaveClusterEnd.q2 &&
        !leaveClusterEnd.q3 &&
        !leaveClusterEnd.q4
      ) {
        leaveEndDayCount = 0.25;
        if (leaveType !== 20) {
          endDayBreakCount = 1;
        }
      } else if (
        /**
         * Second Quarter
         */
        !leaveClusterEnd.q1 &&
        leaveClusterEnd.q2 &&
        !leaveClusterEnd.q3 &&
        !leaveClusterEnd.q4
      ) {
        // NOSONAR

        leaveEndDayCount = 0.25;
        if (leaveType !== 20) {
          endDayBreakCount = 1;
        }
      } else if (
        /**
         * Third Quarter
         */
        !leaveClusterEnd.q1 &&
        !leaveClusterEnd.q2 &&
        leaveClusterEnd.q3 &&
        !leaveClusterEnd.q4
      ) {
        // NOSONAR

        leaveEndDayCount = 0.25;
        if (leaveType !== 20) {
          endDayBreakCount = 1;
        }
      } else if (
        /**
         * Forth Quarter
         */
        !leaveClusterEnd.q1 &&
        !leaveClusterEnd.q2 &&
        !leaveClusterEnd.q3 &&
        leaveClusterEnd.q4
      ) {
        // NOSONAR
        leaveEndDayCount = 0.25;
        if (leaveType !== 20) {
          endDayBreakCount = 1;
        }
      } else {
        endDayBreakCount = 1;
      }
    }
    return {
      startDayBreakCount: startDayBreakCount,
      endDayBreakCount: endDayBreakCount,
      leaveStartDayCount: leaveStartDayCount,
      leaveEndDayCount: leaveEndDayCount,
    };
  };

  const closeNotificationPopup = () => {
    let data = { showList: false };
    setState((preState) => ({ ...preState, ...data }));
  };

  const openDayBreakupPopup = () => {
    const check = checkEligiblityForLeave();
    if (!check) {
      document.getElementById("leaveClusterDayBreakupErr").innerHTML =
        "Insufficient leave balance.";
      return;
    }
    const { dayBreakupDates } = state; //NOSONAR
    if (dayBreakupDates?.length > 0) {
      let data = { showDayBreakup: true };
      setState((preState) => ({ ...preState, ...data }));
    }
  };

  const closeDayBreakupPopup = () => {
    let data = {
      showDayBreakup: false,
    };
    setState((preState) => ({ ...preState, ...data }));
  };
  const getLeavesDates = async (
    leaveStartDate1,
    leaveEndDate1,
    leaveStartDayCount,
    leaveEndDayCount,
    leaveAppId
  ) => {
    try {
      if (leaveStartDate1 !== null && leaveEndDate1 !== null) {
        let leaveDatesData = {
          startDate: leaveStartDate1,
          endDate: leaveEndDate1,
          startDateCount: leaveStartDayCount,
          endDateCount: leaveEndDayCount,
          isWeekendCounted: isWeekendCounted,
          isStreched: 1,
        };
        let dayBreakStartStr = getStartDateDayBreak(),
          dayBreakEndStr = getEndDateDayBreak();
        if (state.endDate === state.startDate)
          dayBreakEndStr = dayBreakStartStr;
        const dayBreakString = {
          startStr: dayBreakStartStr,
          endStr: dayBreakEndStr,
        };
        const validatedLeaves = await LnAService.getLeavesDates(
          applyFor,
          fyId,
          leaveType,
          leaveDatesData,
          leaveAppId,
          categoryName === SPECIAL_ATTENDANCE,
          dayBreakString
        );
        let applyedLeaveDates = [];
        let totalLeavesInDays = 0;
        validatedLeaves.Result?.forEach((item) => {
          if (item.comments === "WEEK OFF")
            setAppliedLeaveDayError(errorLnASelectWorkingDay);
          if (item.isSelectionEligible === "0" && item.comments !== "WEEK OFF")
            setAppliedLeaveDayError(errorLnAAlreadyApplied);
          if (item.isSelectionEligible === "0" && item.comments === "LOCKED")
            setAppliedLeaveDayError(errorLnALocked);
          if (item.isSelectionEligible === "1") {
            applyedLeaveDates.push(item.date);
            totalLeavesInDays = item.appliedDays;
            setAppliedLeaveDayError("");
          }
        });
        if (validatedLeaves.Result && validatedLeaves.Result.length > 0) {
          if (validatedLeaves.Result[0].comments === "WEEK OFF") {
            setSelectedDayWeekOff(true);
          } else if (
            validatedLeaves.Result[validatedLeaves.Result.length - 1]
              .comments === "WEEK OFF"
          ) {
            setSelectedDayWeekOff(true);
          } else {
            setSelectedDayWeekOff(false);
          }
        }
        let data = {
          appliedDays: totalLeavesInDays,
          applyedLeaveDates: applyedLeaveDates,
          dayBreakupDates: validatedLeaves.Result,
        };
        setState((preState) => ({ ...preState, ...data }));
      } else {
        let data = {
          dayBreakupDates: [],
        };
        setState((preState) => ({ ...preState, ...data }));
      }
      if (document.getElementById("appliedDaysErr") != "undefined")
        document.getElementById("appliedDaysErr").innerHTML = "";
    } catch (error) { }
  };
  const selectedFiles = (fileNms) => {
    try {
      let data = { fileName: fileNms };
      setState((preState) => ({ ...preState, ...data }));
      document.getElementById("documentRequiredErr").innerHTML = "";
      props.updateLnAData({ attachmentName: fileNms.toString() });
      document.getElementById("documentRequiredErr").innerHTML = "";
    } catch (error) { }
  };

  const documentDetails = (currentTime, file) => {
    setState((prevState) => ({ ...prevState, fileName: file }));
  };

  const getBREDataForLeave = async (pSouceLeaveId) => {
    if (categoryName === SPECIAL_ATTENDANCE) {
      let objData = { int_attendance_id: pSouceLeaveId };
      let mappingId = BREModuleActions.specialAttendance;
      return BREServices.getBREData(authUser, mappingId, objData);
    } else {
      let objData = { int_leave_id: pSouceLeaveId };
      let mappingId = BREModuleActions.leaveApplication;
      return BREServices.getBREData(authUser, mappingId, objData);
    }
  };

  const getBREDataForLeaveViolation = async (pSouceLeaveId, pNoOfDays) => {
    if (categoryName === SPECIAL_ATTENDANCE) {
      let objData = {
        int_attendance_id: pSouceLeaveId,
        int_no_of_days_leave: pNoOfDays,
      };
      return BREServices.getBREData(
        authUser,
        BREModuleActions.specialAttendanceViolation,
        objData
      );
    } else {
      let objData = {
        int_leave_id: pSouceLeaveId,
        int_no_of_days_leave: pNoOfDays,
      };
      return BREServices.getBREData(
        authUser,
        BREModuleActions.leaveRuleViolation,
        objData
      );
    }
  };

  const updateStartDateEndDate = async (pLeaveTypeId) => {
    try {
      let data = {};
      const getBREData = await getBREDataForLeave(pLeaveTypeId);
      let maxdate;
      let mindate;
      mindate = addDays(new Date(), getBREData.int_min_days);
      maxdate = addDays(new Date(), getBREData.int_max_days);

      const dates = getMinMaxDate(mindate, maxdate);
      data = {
        minStartDate: dates.minDate,
        maxEndDate: dates.maxDate,
      };
      setState((preState) => ({ ...preState, ...data }));
    } catch (error) { }
  };

  const {
    leaveClusterStart,
    leaveClusterEnd,
    showList,
    showDayBreakup,
    dayBreakupDates,
    minStartDate,
    maxEndDate,
    selectionEnabled,
    violationMsgOpen,
    violationMsg,
    violationSubMsg,
    selectedStartDay,
    selectedStartDayBreakup,
    selectedEndDay,
    selectedEndDayBreakup,
    selectedStartDayTime,
    selectedEndDayTime,
  } = state;
  const handleViolationMsg = () => {
    const data = {
      violationMsgOpen: false,
      ViolationMsgTitle: "",
      violationMsg: "",
      violationSubMsg: "",
    };
    setState((preState) => ({ ...preState, ...data }));
  };

  const isFourteenHundred =
    window.innerHeight < 782 && window.innerWidth < 1441;
  const isThirteenHundred = window.innerHeight < 658;

  const checkEligiblityForLeave = () => {
    const leaveData =
      categoryName === SPECIAL_ATTENDANCE
        ? specialAttendanceData
        : leaveTypeData;
    const selectedLeave = leaveData.find((item) => item.Id === leaveType);
    setLeaveName(selectedLeave.name.split("-")[1].trim());
    setLeaveColor(selectedLeave.color);
    return (
      categoryName === SPECIAL_ATTENDANCE ||
      +selectedLeave.availableBalance >= state.appliedDays ||
      isLeaveEdit
    );
  };

  return (
    <div
      key={"leaveApplyScreen"}
      className="col-xs-12 col-sm-12 col-lg-12 col-md-12 leave-application-body"
      style={{
        backgroundColor: "#ffffff",
        height: allHeight.containerHeight,
        borderRadius: "5px",
      }}
    >
      <NotificationContainer />
      <div
        className="col-xs-12 col-sm-12 col-lg-12 col-md-12"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div className="col-sm-12 col-md-12 col-lg-12">
          <div
            style={{
              backgroundColor: "#343A40",
              borderStartEndRadius: "0.4em",
              borderStartStartRadius: "0.4em",
              height: "2.5rem",
              display: "flex",
              alignItems: "center",
              paddingLeft: "1rem",
              fontWeight: 600,
            }}
          >
            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 leave-application-header">
              <div>
                {categoryName === SPECIAL_ATTENDANCE ? "Special Attendance" : categoryName === CANCELLEAVE ? "Leave Cancel" : "Leave Application"}
              </div>
              <div
                className="mr-sm combobox-css"
                style={{
                  width: "11rem",
                  display: "flex",
                  flexDirection: "row",
                  marginLeft: "auto",
                }}
              >
                <ComboBoxComponent
                  id="financialYear"
                  fields={{ text: "Name", value: "Id" }}
                  cssClass="e-outline numberInputFont comboboxFYIDLA"
                  data-msg-containerid="financialYearErr"
                  name="financialYearErr"
                  placeholder="Financial Year *"
                  change={(e) => onChangeApplyLeave(e, "financialYear")}
                  value={fyId}
                  dataSource={financialYearData}
                  width={180}
                  // showClearButton={false}
                  allowCustom={false}
                />
              </div>
              <div className="toottiptableleaveclass mr-sm" id="toottiptable">
                <button
                  className="leave-rules-button"
                  style={{ backgroundColor: "#343a40", fontFamily: 'Circular TT Book' }}
                  value="Click"
                >
                  Leave Rules
                </button>
                <span className="tooltiptext arrow-right">
                  <LeaveRules />
                </span>
              </div>
            </div>
          </div>
        </div>

        <div
          className="container pl-sm pr-sm"
          style={{
            display: "flex",
            height: allHeight.containerHeight - 90,
            width: "100%",
            overflowY: "hidden",
            overflowX: "hidden",
          }}
        >
          {/* <div style={{ height: '671px', border: '1px solid #ccc', borderRadius: "5px", boxShadow: "0.5px 0.5px #ccc", marginLeft: '0rem', marginTop: '15px', }}                          
                            className='col-lg-8 col-md-8 col-sm-8 col-xs-8 leave-application-body'>
                                <ErrorTabMessage message="User will not able to apply previous financial year(s) leave(s)" />
                            </div> */}

          <div
            style={{
              border: "1px solid #ccc",
              borderRadius: "5px",
              boxShadow: "0.5px 0.5px #ccc",
              overflowY: "auto",
              overflowX: "hidden",
            }}
            // className={categoryName === VIEWLEAVEBALACE ? 'col-lg-12 col-md-2 col-sm-12 col-xs-12 leave-application-body' : 'col-lg-6 col-md-6 col-sm-6 col-xs-6 leave-application-body'} >
            className="col-lg-7 col-md-7 col-sm-7 col-xs-7 mt-sm leave-application-body"
          >
            {/* <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 leave-application-header'>
                             Leave Application
                           </div> */}
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <form id="applyLeave">
                <div className="row1">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div
                      className="col-xs-5 col-sm-5 col-lg-5 col-md-5 pt-md"
                      style={{ marginLeft: "-1rem" }}
                    >
                      <ComboBoxComponent
                        id="typeOfLeave"
                        fields={{ text: "name", value: "Id" }}
                        cssClass="e-outline numberInputFont"
                        data-msg-containerid="typeOfLeaveErr"
                        name="typeOfLeaveErr"
                        floatLabelType="Auto"
                        placeholder={
                          categoryName !== SPECIAL_ATTENDANCE
                            ? "Type of Leave *"
                            : "Type of Attendance *"
                        }
                        htmlAttributes={{ maxlength: 100 }}
                        select={(e) => onChangeApplyLeave(e, "leaveType")}
                        value={leaveType}
                        dataSource={
                          categoryName === SPECIAL_ATTENDANCE
                            ? specialAttendanceData
                            : leaveTypeData
                        }
                      />
                      <div
                        id="typeOfLeaveErr"
                        className="e-input-group e-float-icon-left error-div"
                      />
                    </div>
                    {/* <span style={{ padding: "15px 0px 0px 0px", position: "absolute" }}>
                                                <Tooltips titleMessage={'Select Tool tip'} />
                                            </span> */}

                    <div
                      className="col-xs-7 col-sm-7 col-lg-7 col-md-7 pt-md"
                      style={{
                        marginLeft: "15px",
                        paddingRight: 0,
                        paddingLeft: 0,
                      }}
                    >
                      <ComboBoxComponent
                        id="applyFor"
                        fields={{ text: "name", value: "Id" }}
                        cssClass="e-outline numberInputFont"
                        data-msg-containerid="applyForErr"
                        name="applyForErr"
                        floatLabelType="Auto"
                        placeholder="Applying For"
                        htmlAttributes={{ maxlength: 100 }}
                        select={(e) => onChangeApplyLeave(e, "apply")}
                        value={applyFor}
                        dataSource={applyForData}
                      />
                      <div
                        id="applyForErr"
                        className="e-input-group e-float-icon-left error-div"
                      />
                    </div>
                  </div>
                </div>

                <div className="row1">
                  <div
                    className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                    style={{
                      marginTop:
                        (isThirteenHundred || isFourteenHundred) &&
                          !errorMessageMargin.typeOfLeaveMsg &&
                          !errorMessageMargin.applyingForMsg
                          ? "-.6rem"
                          : null,
                    }}
                  >
                    <div
                      className="col-xs-5 col-sm-5 col-lg-5 col-md-5 pt-xs"
                      style={{ marginLeft: "-15px" }}
                    >
                      <DatePickerComponent
                        id="leaveStartDate"
                        data-msg-containerid="leaveStartDateErr"
                        name="leaveStartDateErr"
                        cssClass="e-outline"
                        allowFiltering={false}
                        allowCustom={true}
                        floatLabelType="Auto"
                        placeholder="From Date *"
                        format="dd-MMM-yyyy"
                        type="date"
                        popupWidth={296}
                        showClearButton={true}
                        min={minStartDate}
                        max={maxEndDate}
                        onChange={(e) =>
                          onChangeApplyLeave(e, "leaveStartDate")
                        }
                        value={state.startDate}
                        enabled={selectionEnabled}
                        cleared={() => {
                          props.updateLnAData({ leaveStartDate: null });
                        }}
                      ></DatePickerComponent>
                      <div
                        id="leaveStartDateErr"
                        className="e-input-group e-float-icon-left error-div"
                      />
                    </div>
                    {/** ======================================================================================= */}
                    {(isStreched !== "1" || leaveType === 20) &&
                      state.startDate !== null &&
                      leaveType !== null && (
                        <div
                          className="col-xs-7 col-sm-7 col-lg-7 col-md-7 ml-md pt-xs"
                          style={{ paddingLeft: 0, paddingRight: 0 }}
                        >
                          <div
                            className={
                              isThirteenHundred || isFourteenHundred
                                ? "col-xs-12 col-sm-12 col-lg-12 col-md-12"
                                : ""
                            }
                            style={{ paddingLeft: 0 }}
                          >
                            {leaveType === 20 ? (
                              <>
                                <div
                                  className="col-sm-4 col-lg-4 col-md-4"
                                  style={{ paddingLeft: 0, width: "50%" }}
                                >
                                  {isShowTooltip ? (
                                    <TooltipComponent
                                      width={100}
                                      position="BottomCenter"
                                      className="pointer-cursor"
                                      isSticky={false}
                                      content={generateTooltipTitle(
                                        quaterDayData,
                                        selectedStartDayBreakup
                                      )}
                                      cssClass="customtooltip"
                                    >
                                      <ComboBoxComponent
                                        id="typeOfLeave"
                                        fields={{ text: "name", value: "Id" }}
                                        cssClass="e-outline numberInputFont"
                                        data-msg-containerid="selectDay"
                                        name="Select"
                                        floatLabelType="Auto"
                                        placeholder="Select"
                                        htmlAttributes={{ maxlength: 100 }}
                                        value={selectedStartDayBreakup}
                                        dataSource={quaterDayData}
                                        select={(e) => {
                                          onChangeApplyLeave(
                                            e,
                                            "selectStartDayBreakup"
                                          );
                                        }}
                                        showClearButton={false}
                                      />
                                    </TooltipComponent>
                                  ) : (
                                    <ComboBoxComponent
                                      id="typeOfLeave"
                                      fields={{ text: "name", value: "Id" }}
                                      cssClass="e-outline numberInputFont"
                                      data-msg-containerid="selectDay"
                                      name="Select"
                                      floatLabelType="Auto"
                                      placeholder="Select"
                                      htmlAttributes={{ maxlength: 100 }}
                                      value={selectedStartDayBreakup}
                                      dataSource={quaterDayData}
                                      select={(e) => {
                                        onChangeApplyLeave(
                                          e,
                                          "selectStartDayBreakup"
                                        );
                                      }}
                                      showClearButton={false}
                                    />
                                  )}
                                </div>
                                <div
                                  className="col-xs-4 col-sm-4 col-lg-4 col-md-4"
                                  style={{
                                    paddingLeft: 0,
                                    paddingRight: 0,
                                    width: "50%",
                                  }}
                                >
                                  <div className="e-input-group">
                                    <MuiPickersUtilsProvider
                                      utils={DateFnsUtils}
                                    >
                                      <div className="e-input-in-wrap timepicker-input-box timepicker-label">
                                        <TimePicker
                                          style={{ width: "100%" }}
                                          className="mr-lg1 time-picker-custom"
                                          cssClass="e-outline"
                                          inputVariant="outlined"
                                          name="meeting-end-time"
                                          ampm={false}
                                          label={`From Time (${state.workDaySettingData[0].timeZone}) `}
                                          value={selectedStartDayTime}
                                          onChange={(date) =>
                                            onChangeApplyLeave(
                                              date,
                                              "selectStartDayTime"
                                            )
                                          }
                                          data-msg-containerid="meetingtimeError"
                                        />
                                      </div>
                                    </MuiPickersUtilsProvider>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                <div
                                  className="col-sm-4 col-lg-4 col-md-4"
                                  style={
                                    (isShowTooltip ||
                                      window.innerWidth < 1490) &&
                                      selectedStartDay === 1
                                      ? {
                                        paddingLeft: 0,
                                        paddingRight: 0,
                                        width: "100%",
                                      }
                                      : { paddingLeft: 0 }
                                  }
                                >
                                  {isShowTooltip && selectedStartDay !== 1 ? (
                                    <TooltipComponent
                                      width={100}
                                      position="BottomCenter"
                                      className="pointer-cursor"
                                      isSticky={false}
                                      content={generateTooltipTitle(
                                        dayData,
                                        selectedStartDay
                                      )}
                                      cssClass="customtooltip"
                                    >
                                      <ComboBoxComponent
                                        id="typeOfLeave"
                                        fields={{ text: "name", value: "Id" }}
                                        cssClass="e-outline numberInputFont"
                                        data-msg-containerid="selectDay"
                                        name="Select"
                                        floatLabelType="Auto"
                                        placeholder="Select"
                                        select={(e) =>
                                          onChangeApplyLeave(
                                            e,
                                            "selectStartDay"
                                          )
                                        }
                                        htmlAttributes={{ maxlength: 100 }}
                                        value={selectedStartDay}
                                        dataSource={dayData}
                                        showClearButton={false}
                                      />
                                    </TooltipComponent>
                                  ) : (
                                    <ComboBoxComponent
                                      id="typeOfLeave"
                                      fields={{ text: "name", value: "Id" }}
                                      cssClass="e-outline numberInputFont"
                                      data-msg-containerid="selectDay"
                                      name="Select"
                                      floatLabelType="Auto"
                                      placeholder="Select"
                                      select={(e) =>
                                        onChangeApplyLeave(e, "selectStartDay")
                                      }
                                      htmlAttributes={{ maxlength: 100 }}
                                      value={selectedStartDay}
                                      dataSource={dayData}
                                      showClearButton={false}
                                    />
                                  )}
                                </div>
                                {selectedStartDay === 2 && (
                                  <>
                                    <div
                                      className="col-sm-4 col-lg-4 col-md-4"
                                      style={{ paddingLeft: 0 }}
                                    >
                                      {isShowTooltip ? (
                                        <TooltipComponent
                                          width={100}
                                          position="BottomCenter"
                                          className="pointer-cursor"
                                          isSticky={false}
                                          content={generateTooltipTitle(
                                            dayBreakupData,
                                            selectedStartDayBreakup
                                          )}
                                          cssClass="customtooltip"
                                        >
                                          <ComboBoxComponent
                                            id="typeOfLeave"
                                            fields={{
                                              text: "name",
                                              value: "Id",
                                            }}
                                            cssClass="e-outline numberInputFont"
                                            data-msg-containerid="selectDayBreakup"
                                            name="Select"
                                            floatLabelType="Auto"
                                            placeholder="Select"
                                            select={(e) =>
                                              onChangeApplyLeave(
                                                e,
                                                "selectStartDayBreakup"
                                              )
                                            }
                                            htmlAttributes={{ maxlength: 100 }}
                                            value={selectedStartDayBreakup}
                                            dataSource={dayBreakupData}
                                            showClearButton={false}
                                          />
                                        </TooltipComponent>
                                      ) : (
                                        <ComboBoxComponent
                                          id="typeOfLeave"
                                          fields={{ text: "name", value: "Id" }}
                                          cssClass="e-outline numberInputFont"
                                          data-msg-containerid="selectDayBreakup"
                                          name="Select"
                                          floatLabelType="Auto"
                                          placeholder="Select"
                                          select={(e) =>
                                            onChangeApplyLeave(
                                              e,
                                              "selectStartDayBreakup"
                                            )
                                          }
                                          htmlAttributes={{ maxlength: 100 }}
                                          value={selectedStartDayBreakup}
                                          dataSource={dayBreakupData}
                                          showClearButton={false}
                                        />
                                      )}
                                    </div>
                                    <div
                                      className="col-xs-4 col-sm-4 col-lg-4 col-md-4"
                                      style={{
                                        paddingLeft: 0,
                                        paddingRight: 0,
                                      }}
                                    >
                                      <div className="e-input-group">
                                        <div
                                          className="e-input-in-wrap"
                                          style={{ display: "block" }}
                                        >
                                          <MuiPickersUtilsProvider
                                            utils={DateFnsUtils}
                                          >
                                            <div className="timepicker-input-box timepicker-label">
                                              <TimePicker
                                                style={{
                                                  width: "100%",
                                                  height: "37px",
                                                }}
                                                className="mr-lg1 time-picker-custom"
                                                inputVariant="outlined"
                                                name="meeting-end-time"
                                                ampm={false}
                                                label={`From Time (${state.workDaySettingData[0].timeZone}) `}
                                                value={selectedStartDayTime}
                                                onChange={(date) =>
                                                  onChangeApplyLeave(
                                                    date,
                                                    "selectStartDayTime"
                                                  )
                                                }
                                                data-msg-containerid="meetingtimeError"
                                              />
                                            </div>
                                          </MuiPickersUtilsProvider>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      )}
                  </div>
                </div>
                <div className="row1">
                  <div
                    className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                    style={{
                      marginTop:
                        (isThirteenHundred || isFourteenHundred) &&
                          !errorMessageMargin.fromDateMsg
                          ? "-.6rem"
                          : null,
                    }}
                  >
                    <div
                      className="col-xs-5 col-sm-5 col-lg-5 col-md-5 pt-xs"
                      style={{
                        marginLeft: "-15px",
                        height: isThirteenHundred ? "3.6rem" : "4rem",
                      }}
                    >
                      <DatePickerComponent
                        // id="datePickerRange"
                        cssClass="e-outline"
                        id="leaveEndDate"
                        data-msg-containerid="leaveEndDateErr"
                        name="leaveEndDateErr"
                        allowFiltering={false}
                        allowCustom={true}
                        floatLabelType="Auto"
                        placeholder="To Date *"
                        format="dd-MMM-yyyy"
                        showClearButton={true}
                        type="date"
                        // enabled={leaveType=== 5 ? false:true}
                        popupWidth={296}
                        min={minStartDate}
                        max={maxEndDate}
                        onChange={(e) => onChangeApplyLeave(e, "leaveEndDate")}
                        value={state.endDate}
                        readonly={leaveType === 20 ? true : false}
                        enabled={
                          categoryName !== SPECIAL_ATTENDANCE &&
                            isStreched === "1"
                            ? false
                            : selectionEnabled
                        }
                        cleared={() => {
                          props.updateLnAData({ leaveEndDate: null });
                        }}
                      // skeleton="medium"
                      ></DatePickerComponent>
                      <div
                        id="leaveEndDateErr"
                        className="e-input-group e-float-icon-left error-div"
                      />
                    </div>
                    <div
                      className="col-xs-7 col-sm-7 col-lg-7 col-md-7 ml-md pt-xs"
                      style={{ paddingLeft: 0, paddingRight: 0 }}
                    >
                      {leaveStartDate !== leaveEndDate &&
                        leaveEndDate !== null &&
                        leaveType !== 5 &&
                        leaveType !== 20 &&
                        isStreched !== "1" && (
                          <>
                            <div
                              className="col-xs-4 col-sm-4 col-lg-4 col-md-4 "
                              style={
                                (isShowTooltip || window.innerWidth < 1490) &&
                                  selectedEndDay === 1
                                  ? {
                                    paddingLeft: 0,
                                    paddingRight: 0,
                                    width: "100%",
                                  }
                                  : { paddingLeft: 0 }
                              }
                            >
                              {isShowTooltip && selectedEndDay !== 1 ? (
                                <TooltipComponent
                                  width={100}
                                  position="BottomCenter"
                                  className="pointer-cursor"
                                  isSticky={false}
                                  content={generateTooltipTitle(
                                    dayData,
                                    selectedEndDay
                                  )}
                                  cssClass="customtooltip"
                                >
                                  <ComboBoxComponent
                                    id="typeOfLeave"
                                    fields={{ text: "name", value: "Id" }}
                                    cssClass="e-outline numberInputFont  p-0"
                                    data-msg-containerid="selectDay"
                                    name="Select"
                                    floatLabelType="Auto"
                                    placeholder="Select"
                                    select={(e) =>
                                      onChangeApplyLeave(e, "selectEndDay")
                                    }
                                    htmlAttributes={{ maxlength: 100 }}
                                    value={selectedEndDay}
                                    dataSource={dayData}
                                    onCancel={false}
                                  // showClearButton={false}
                                  />
                                </TooltipComponent>
                              ) : (
                                <ComboBoxComponent
                                  id="typeOfLeave"
                                  fields={{ text: "name", value: "Id" }}
                                  cssClass="e-outline numberInputFont  p-0"
                                  data-msg-containerid="selectDay"
                                  name="Select"
                                  floatLabelType="Auto"
                                  placeholder="Select"
                                  select={(e) =>
                                    onChangeApplyLeave(e, "selectEndDay")
                                  }
                                  htmlAttributes={{ maxlength: 100 }}
                                  value={selectedEndDay}
                                  dataSource={dayData}
                                  onCancel={false}
                                // showClearButton={false}
                                />
                              )}
                            </div>
                            {selectedEndDay === 2 && (
                              <>
                                <div
                                  className="col-xs-4 col-sm-4 col-lg-4 col-md-4"
                                  style={{ paddingLeft: 0 }}
                                >
                                  {isShowTooltip ? (
                                    <TooltipComponent
                                      width={100}
                                      position="BottomCenter"
                                      className="pointer-cursor"
                                      isSticky={false}
                                      content={generateTooltipTitle(
                                        dayBreakupData,
                                        selectedEndDayBreakup
                                      )}
                                      cssClass="customtooltip"
                                    >
                                      <ComboBoxComponent
                                        id="typeOfLeave"
                                        fields={{ text: "name", value: "Id" }}
                                        cssClass="e-outline numberInputFont  "
                                        data-msg-containerid="selectDayBreakup"
                                        name="Select"
                                        floatLabelType="Auto"
                                        placeholder="Select"
                                        select={(e) => {
                                          onChangeApplyLeave(
                                            e,
                                            "selectEndDayBreakup"
                                          );
                                        }}
                                        htmlAttributes={{ maxlength: 100 }}
                                        value={selectedEndDayBreakup}
                                        dataSource={dayBreakupData}
                                      // showClearButton={false}
                                      />
                                    </TooltipComponent>
                                  ) : (
                                    <ComboBoxComponent
                                      id="typeOfLeave"
                                      fields={{ text: "name", value: "Id" }}
                                      cssClass="e-outline numberInputFont  "
                                      data-msg-containerid="selectDayBreakup"
                                      name="Select"
                                      floatLabelType="Auto"
                                      placeholder="Select"
                                      select={(e) => {
                                        onChangeApplyLeave(
                                          e,
                                          "selectEndDayBreakup"
                                        );
                                      }}
                                      htmlAttributes={{ maxlength: 100 }}
                                      value={selectedEndDayBreakup}
                                      dataSource={dayBreakupData}
                                    // showClearButton={false}
                                    />
                                  )}
                                </div>

                                <div
                                  className="col-xs-4 col-sm-4 col-lg-4 col-md-4"
                                  style={{ paddingLeft: 0, paddingRight: 0 }}
                                >
                                  <div className="e-input-group">
                                    <div
                                      className="e-input-in-wrap"
                                      style={{ display: "block" }}
                                    >
                                      <MuiPickersUtilsProvider
                                        utils={DateFnsUtils}
                                      >
                                        <div className="timepicker-input-box timepicker-label">
                                          <TimePicker
                                            style={{ width: "100%" }}
                                            className="mr-lg1 time-picker-custom"
                                            inputVariant="outlined"
                                            name="meeting-end-time"
                                            ampm={false}
                                            label={`From Time (${state.workDaySettingData[0].timeZone}) `}
                                            value={selectedEndDayTime}
                                            onChange={(date) =>
                                              onChangeApplyLeave(
                                                date,
                                                "selectEndDayTime"
                                              )
                                            }
                                            data-msg-containerid="meetingtimeError"
                                          />
                                        </div>
                                      </MuiPickersUtilsProvider>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                          </>
                        )}
                    </div>
                  </div>

                  {/* <div className='working-daybreak' style={{ height: 42, textAlign: 'center', cursor: 'pointer', opacity: '1', fontWeight: 500, marginTop: 70, marginLeft: 125, zIndex: 100 }}>
                                            <div style={{ cursor: 'pointer', color: 'transparent' }} onClick={() => openDayBreakupPopup()}>
                                                View More
                                            </div>
                                        </div> */}
                </div>

                <div className="row1">
                  <div
                    className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                    style={{
                      paddingLeft: 0,
                      marginTop:
                        (isThirteenHundred || isFourteenHundred) &&
                          !errorMessageMargin.toDateMsg
                          ? "-.6rem"
                          : null,
                    }}
                  >
                    <div
                      className="col-lg-9 col-md-9 col-sm-9 col-xs-9"
                      style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        fontSize: ".85rem",
                        padding: 0,
                        width: "100%",
                      }}
                    >
                      <div style={{ color: "#000", fontWeight: 500 }}>
                        Applied For
                      </div>
                      <div style={{ paddingLeft: "1rem" }}>
                        <div
                          style={{
                            border: "1px solid #F2F2F2",
                            height: isThirteenHundred ? "1.8rem" : "2rem",
                            width: isThirteenHundred ? "3rem" : "3.5rem",
                            textAlign: "center",
                            borderRadius: "3px",
                          }}
                        >
                          <div style={{ fontWeight: 500, paddingTop: ".3rem" }}>
                            {state.appliedDays}
                          </div>
                        </div>
                      </div>
                      <div style={{ fontWeight: 500, paddingLeft: "1rem" }}>
                        days
                        {dayBreakupDates?.length > 0 && (
                          <>
                            &nbsp; &nbsp;| &nbsp;
                            <span
                              onKeyDown={(event) => {
                                if (
                                  event.key === "Enter" ||
                                  event.key === " "
                                ) {
                                  // Check if Enter or Spacebar is pressed
                                  openDayBreakupPopup();
                                }
                              }}
                              onClick={() => openDayBreakupPopup()}
                              className="leave-apply-button-view-more "
                              style={{ float: "" }}
                            >
                              View Day Break
                            </span>
                          </>
                        )}
                      </div>
                      <div
                        id="leaveClusterDayBreakupErr"
                        className="e-input-group e-float-icon-left error-div "
                        style={{
                          paddingTop: "5px",
                          width: "fit-content",
                          paddingLeft: "13px",
                        }}
                      />
                    </div>
                    {/* {(leaveStartDate === leaveEndDate || leaveType === 5) && */}
                    <div>
                      <div
                        className="col-lg-7 col-md-7 col-sm-7 col-xs-7"
                        style={{ paddingLeft: 0 }}
                      >
                        <div
                          className={
                            isThirteenHundred || isFourteenHundred
                              ? "col-xs-6 col-sm-6 col-lg-6 col-md-6 pr-xs pl-md"
                              : "pl-md"
                          }
                          style={{
                            width:
                              isThirteenHundred || isFourteenHundred
                                ? "100%"
                                : "31.5rem",
                          }}
                        ></div>
                      </div>
                    </div>
                    {/* } */}
                  </div>
                  <div id="appliedDaysErr" className="error-div e-error" />
                </div>
                <div className="row1">
                  <div
                    className={`col-lg-12 col-md-12 col-sm-12 col-xs-12 leaveAppReasonContainer ${isThirteenHundred || isFourteenHundred
                      ? "pt[5px]"
                      : "pt-sm"
                      }`}
                    style={{ padding: 0, paddingTop: "3px" }}
                  >
                    <div style={{ paddingLeft: 0, paddingRight: 0 }}>
                      <style>{sampleCss}</style>
                      <TextBoxComponent
                        id={"leaveAppReason"}
                        placeholder={"Reason *"}
                        cssClass="e-outline textarea"
                        floatLabelType="Auto"
                        input={onInput}
                        ref={textareaObj}
                        name="leaveAppReasonErr"
                        data-msg-containerid="leaveAppReasonErr"
                        multiline
                        inputProps={{
                          required: true,
                          minLength: 7,
                        }}
                        htmlAttributes={{ maxlength: 1000 }}
                        onChange={(e) => onChangeApplyLeave(e, "leaveReason")}
                        value={leaveReason}
                        readonly={leaveApplyLoadName === "leave-cancel"}
                      />
                      {leaveReason !== null &&
                        leaveReason !== undefined &&
                        leaveReason !== "" &&
                        (isThirteenHundred || isFourteenHundred) &&
                        errorMessageMargin.reasonMsg ? (
                        <div
                          id="leaveAppReasonCount"
                          style={{
                            float: "right",
                            fontSize: ".65rem",
                            margin: "0px 15px 8px 0px",
                          }}
                          className="error-div"
                        />
                      ) : (
                        <div
                          id="leaveApplyReasonCount"
                          style={{
                            float: "right",
                            fontSize: ".65rem",
                            margin: "0px 15px 8px 0px",
                          }}
                          className="error-div"
                        />
                      )}
                    </div>
                    <div id="leaveAppReasonErr" className="error-div e-error" />
                    {leaveReason !== null &&
                      leaveReason !== undefined &&
                      leaveReason !== "" && (
                        <div
                          id="leaveApplyReasonCount"
                          style={{
                            float: "right",
                            fontSize: 12,
                            margin: "0px 15px 8px 0px",
                          }}
                          className="error-div"
                        />
                      )}
                  </div>
                </div>
                <div
                  className={`col-lg-12 col-md-12 col-sm-12 col-xs-12 ${(isThirteenHundred || isFourteenHundred) &&
                    !errorMessageMargin.reasonMsg
                    ? "pt-sm"
                    : "pt-md"
                    }`}
                  style={{
                    paddingLeft: 0,
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "flex-start", // "2.7rem"
                    height:
                      isThirteenHundred || isFourteenHundred
                        ? "3.2rem"
                        : "4.2rem",
                  }}
                >
                  {othersCheckAvailablity && (
                    <div style={{ flex: "1.3 1 3%", marginTop: "-.03rem" }}>
                      <div>
                        <AutoCompleteComponent
                          id="reliever"
                          // style={{ height: "1rem" }}
                          ref={relieverRef}
                          cssClass="e-outline"
                          name="relieverErr"
                          floatLabelType="Auto"
                          fields={{
                            text: "nameWithDepartment",
                            value: "UserId",
                          }}
                          placeholder={reliever === null || reliever === "" ? 'Reporting Supervisor' : 'Reporting Supervisor *'}
                          select={(e) => onChangeApplyLeave(e, "reliever")}
                          data-msg-containerid="relieverErr"
                          noRecordsTemplate={noRecordsTemplate}
                          filtering={handleFilteringByName}
                          dataSource={othersCheckAvailablity}
                        />
                        <div
                          id="relieverErr"
                          className="e-input-group e-float-icon-left error-div"
                        />
                      </div>
                    </div>
                  )}
                  {/* </fieldset> */}
                  <div style={{ paddingLeft: "1rem", flex: 1 }}>
                    <div
                      className="add-view-tm"
                      onClick={() => {
                        props.updateLnAData({ leaveApplyLoadName: "" });
                        setTimeout(() => {
                          props.updateLnAData({
                            leaveApplyLoadName: "leave-notification-list",
                          });
                        }, 20);
                      }}
                    // style={{ height: 42, border: '1px solid #F94F5E', textAlign: 'center', color: '#F94F5E', cursor: 'pointer', opacity: '1', fontWeight: 500 }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faPlus}
                          style={{
                            color: "#F94F5E",
                            fontSize: "20px",
                            marginRight: "10px",
                          }}
                        />{" "}
                        <span> View / Add Notifications</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div
                    className={`leave-app-attachment-text`}
                    style={{ paddingLeft: 0, paddingBottom: ".3rem" }}
                  >
                    <span>Attachment</span>
                    {state.documentsRequired &&
                      state.documentsRequired === "YES" && <span>*</span>}
                  </div>
                  <div
                    className="col-lg-12 col-md-12 col-sm-12 col-xs-12 pb-sm"
                    style={{ marginLeft: "0px", paddingLeft: 0 }}
                  >
                    <UploadDocuments
                      type={"leaveapply"}
                      // ratingId={0}
                      allowmultiple={false}
                      selectedFiles={selectedFiles}
                      // fileList={attachmentName !== "" ? [attachmentName] : []}
                      // authUser={props.authUser}
                      fileList={[]}
                      apiUrl={"lnaApiUrl"}
                      documentDetails={documentDetails}
                    />
                    {attachmentName !== "" && (
                      <div className="">
                        {createImageFilepathForLPView(
                          [].concat(attachmentName),
                          "document/lna/leaveapply",
                          "lnaApiUrl",
                          removeFile
                        )}
                      </div>
                    )}
                  </div>
                  <div
                    id="documentRequiredErr"
                    className="e-input-group e-float-icon-left error-div"
                  />
                </div>
                <ConfirmationPopup
                  showButton={{ type: "1" }}
                  open={showConform}
                  button1function={() => handleYes()}
                  button2function={() => handleClose()}
                  headerMessage={`Are you sure, you want to apply ${categoryName === SPECIAL_ATTENDANCE ? "special attendance" : "leave"}?`}
                  button1Text={"Yes"}
                  button2Text={"No"}
                />
              </form>
              {showList && (
                <NotifiersPopup
                  closeNotificationPopup={closeNotificationPopup}
                />
              )}
              {showDayBreakup && dayBreakupDates?.length > 0 && (
                <ViewMoreDayBreakup
                  closeDayBreakupPopup={closeDayBreakupPopup}
                  dayBreakupDates={dayBreakupDates}
                  leaveClusterStart={leaveClusterStart}
                  leaveClusterEnd={leaveClusterEnd}
                  leaveName={leaveName}
                  leaveColor={leaveColor}
                />
              )}
            </div>
            {/* </div > */}
          </div>

          <div
            className="col-lg-5 col-md-5 col-sm-5 col-xs-5 leave-application-body mt-sm ml-md tabcontent-leave"
            style={{
              // border: '1px solid #ccc',
              borderRadius: "5px",
              // boxShadow: "0.5px 0.5px #ccc",
              fontFamily: "Arial, Helvetica, sans-serif",
              color: "#848484",
              padding: 0,
              // overflowY: 'auto'
            }}
          >
            {leaveApplyLoadName === "leave-view" && (
              <>
                {
                  <LeaveDetails
                    type={"view-application"}
                    isHideButtons={true}
                  />
                }
              </>
            )}
            {leaveApplyLoadName === "leave-cancel" && (
              <>{<LeaveDetails type={"cancel-application"} />}</>
            )}
            {(leaveApplyLoadName === "leave-tabs" ||
              leaveApplyLoadName === "leave-edit" ||
              leaveApplyLoadName === "leave-balance-list") && (
                <Leavetabs
                  type={"LeaveApply"}
                  showViewMode={showViewMode}
                  selectedUserId={applyFor ? applyFor : props.authUser}
                />
              )}
            {leaveApplyLoadName === "leave-details" && (
              <Leavetabs
                type={"LeaveApply"}
                showViewMode={showViewMode}
                selectedUserId={applyFor ? applyFor : props.authUser}
              />
            )}
            {leaveApplyLoadName === "leave-notification-list" && (
              <Leavetabs type={"LeaveApply"} viewMode={"Notification"} />
            )}
          </div>
        </div>
        {(leaveApplyLoadName === "leave-details" ||
          leaveApplyLoadName === "leave-tabs" ||
          leaveApplyLoadName === "leave-edit" ||
          leaveApplyLoadName === "leave-notification-list" ||
          leaveApplyLoadName === "leave-balance-list") && (
            <div
              className="pb-sm pt-sm pl-md"
              style={{ paddingRight: 0, width: "-webkit-fill-available" }}
            >
              <div className="pr-sm" style={{ float: "right" }}>
                <button
                  className="leave-cancel-apply-button"
                  onClick={() => {
                    setCancelClicked(true);
                    onCancel();
                  }}
                >
                  Cancel
                </button>
                {selectionEnabled && (
                  <button
                    className="remove-button-shadow-save"
                    onClick={() => onSubmitApplyLeave()}
                  >
                    Apply
                  </button>
                )}
              </div>
            </div>
          )}

        {isLoading && (
          <div
            style={{
              zIndex: 999999,
              position: "absolute",
              marginTop: "21%",
              marginLeft: "49%",
            }}
          >
            <ProgressBar />
          </div>
        )}
        <ConfirmationPopup
          open={violationMsgOpen}
          headerMessage={violationMsg}
          subMessage={violationSubMsg}
          button1Text={"OK"}
          button2Text={""}
          button1function={handleViolationMsg}
          button2function={handleViolationMsg}
          showButton={{ type: "1" }}
        />
      </div>
    </div>
  );
};

const mapStateToProps = ({
  meetingcategory,
  lnareducer,
  auth,
  meetingmaster,
}) => {
  const { authUser } = auth;
  const { categoryName } = meetingcategory;
  const { othersCheckAvailablity, currentFinancialYearId } = meetingmaster;
  const {
    leaveType,
    attendenceType,
    leaveStartDate,
    leaveEndDate,
    leaveReason,
    notificationTo,
    reliever,
    leaveTypeData,
    compOffData,
    relieverData,
    leaveApplyStatus,
    isStreched,
    isWeekendCounted,
    applyForData,
    applyFor,
    leaveApplyLoadName,
    appliedDays,
    dayBreakText,
    leaveApplicationId,
    attachmentName,
    viewMode,
    notificationToArr,
    dayBreakBackgroundColor,
    fyId,
    updateFinancialYear,
    leaveStartDateEdit,
    leaveEndDateEdit,
    financialYearData,
    specialAttendanceData,
    leaveTypeList,
    leaveCancelStatus,
    backgroundSyncId,
  } = lnareducer;
  return {
    categoryName,
    leaveType,
    attendenceType,
    leaveStartDate,
    leaveEndDate,
    leaveReason,
    notificationTo,
    reliever,
    authUser,
    leaveTypeData,
    compOffData,
    othersCheckAvailablity,
    relieverData,
    leaveApplyStatus,
    isStreched,
    isWeekendCounted,
    applyForData,
    applyFor,
    currentFinancialYearId,
    leaveApplyLoadName,
    appliedDays,
    dayBreakText,
    leaveApplicationId,
    attachmentName,
    viewMode,
    notificationToArr,
    dayBreakBackgroundColor,
    financialYearData,
    fyId,
    updateFinancialYear,
    leaveStartDateEdit,
    leaveEndDateEdit,
    specialAttendanceData,
    leaveTypeList,
    leaveCancelStatus,
    backgroundSyncId,
  };
};

export default connect(mapStateToProps, { updateLnAData, applyLeave })(
  LeaveApply
);
