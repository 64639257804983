import React, { Component } from 'react';
import AdminServices from '../../../services/adminservices';
import OrgcharPopUp from './orgchartpopup';
import { connect } from 'react-redux';
import { Button } from "@material-ui/core";
import './orgchartadmin.css';
import { updateUserRoleMasterRedux, saveUserRoleMasterFunction } from '../../../redux/actions/admin/adminaction';
import ActionTemplate from "./actionTemplate";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  DetailRow,
  Inject,
  Page,
  Filter
} from "@syncfusion/ej2-react-grids";

import './orgChartMaster.css';
import {
  getGridColumn,
  ROLE_MASTER,
  EMPLOYEE_COUNT,
  ACTION
} from "../../../app/other/gridcolumns";
import stylingNewSubBussinessUnit from "./orgChartStylingGridComponents"
import Progressbar from '../../progressbar/progressbar';
import ErrorMessage from "../../errormessage/errormessage";
import { setSpinner } from '@syncfusion/ej2-react-popups';
import { spinner } from "../../../app/other/commonfunction";


class UserRoleMaster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      height:
        (window.innerHeight ||
          document.documentElement.clientHeight ||
          document.body.clientHeight) - 188,
      gridHeight: (window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight) - 290,
      heightPending:
        (window.innerHeight ||
          document.documentElement.clientHeight ||
          document.body.clientHeight) - 188,
      result: [],
      openPopup: false,
      title: "",
      type: "",
      isLoading: true
    }
    this.closePopup = this.closePopup.bind(this);
    this.openPopup = this.openPopup.bind(this);
    this.header = "Role / Title"
    this.actionTemplate = this.actionTemplate.bind(this);
    this.Filter = {
      type: "Menu",
    };
    this.check = { type: 'CheckBox' };
    this.width =
      (window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth) - 100;
    this.dataBound = this.dataBound.bind(this);
    this.onDataBinding = this.onDataBinding.bind(this)
    this.initialLoad = true;
  }

  updateDimensions() {
    const updateHeight = window.innerHeight - 230;
    const updateHeightp = window.innerHeight - 185;
    this.setState({ height: updateHeight, heightPending: updateHeightp });
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
    this.getuserrolemaster();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.user_role_flag === 'updated') {
      this.getuserrolemaster();
      this.closePopup();
      this.props.updateUserRoleMasterRedux({ user_role_flag: '' })
    }
  }
  makeActiveInActive = (id, status) => {
    const { authUser } = this.props;
    let data = {
      authUser: authUser,
      roleId: id,
      isActive: status,
      type: "Status"
    }
    this.props.saveUserRoleMasterFunction(data);
  }



  getuserrolemaster = () => {
    try {
      const userrole = Promise.resolve(
        AdminServices.getAdminUserRoleData()
      );
      userrole.then((result) => {
        if (result !== null) {
          this.setState({ result: result.result, isLoading: false })
        }
      });
    } catch (error) {
      console.error(error);
    }
  }

  openPopup(title, type, userRoleData) {

    this.setState({ openPopup: true, title: title, type: type, userRoleData: userRoleData });
  }
  closePopup() {
    this.setState({ openPopup: false, title: '', type: '', userRoleData: '' });
  }

  setHeaderCss = () => {
    setTimeout(() => {
      const ele = document.getElementsByClassName("e-headercelldiv");
      for (let i in ele) {
        if (!isNaN(parseInt(i, 10))) {
          let index = parseInt(i, 10);

          if (ele[index].innerText === ACTION) {
            ele[index].classList.add("grid-center-g-header");
          } else {
            ele[index].classList.add("grid-left-g-header");
          }
        }
      }
    }, 500);
  }

  actionTemplate = (props) => {



    return (
      <ActionTemplate
        props={props}
        category="ROLE_MASTER"
        header={this.header}
        makeActiveInActive={this.makeActiveInActive}
        openPopup={this.openPopup}
      />
    )
  }

  level2ActionRole = (props) => {

    return (
      <div style={{ textAlign: "center" }}>{props.empCnt}
      </div>
    )
  }


  /**
   * Created on 09-09-2022 || Noel Fredrick
   * Description - For spinner
   */
  onDataBinding() {
    spinner(true)
    // try {
    //   refreshMultiSelect(pComponentId);
    //   setTimeout(() => {
    //     document.getElementById(pComponentId).ej2_instances[0].refresh()
    //   })
    // } catch (error) {
    //   console.log(error)
    // }
  }

  dataBound() {
    spinner(false)
  }

  onFirstGridCreated() {
    if (this.initialLoad) {
      this.initialLoad = false;
      setSpinner({
        template: document.getElementById('spinnerContainer').innerHTML,
      });
      document.getElementById('spinnerContainer').remove();
      // this.gridInstance.freezeRefresh(); // re-render the grid component
    }

  }

  roleTemplate = (props) => {
    return (
      <p style={{ textAlign: "left", fontSize: '14px' }}>{props.Role}</p>
    )
  }

  render() {

    const { openPopup, height, result, title, type, userRoleData, gridHeight, isLoading } = this.state;
    var widthColumnConfirm = 0;
    var finalWidthConfirm;
    // eslint-disable-next-line
    getGridColumn("orgChartMasterAdmin").forEach((data) => {
      widthColumnConfirm = widthColumnConfirm - 46 + data.width;
    });
    if (widthColumnConfirm <= this.width) {
      finalWidthConfirm = widthColumnConfirm + 100;
    } else {
      finalWidthConfirm = this.width
    }
    const gridHeightNew = (window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight) > 767
      ? gridHeight - 120 //12
      : gridHeight - 115
    this.setHeaderCss();
    return (
      <>
        <div style={{
          color: "black",
          height: height,
          padding: "8px 4px 8px 4px ",
          marginBottom: "16px ",
          marginLeft: 14
        }}
          className='businessfunc-height' id='userrolemasterId'
        ><style>{stylingNewSubBussinessUnit}</style>
          <div className="row">
            <div className='col-xs-6 col-sm-6 col-lg-6 col-md-6'>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '.8rem', width: '100%' }}>
                <div className="add-header">
                  {this.header}
                </div>
                <div style={{ paddingBottom: 4 }}>
                  <Button
                    className="remove-button-shadow addbtn"
                    color="primary"
                    variant="contained"
                    onClick={() => this.openPopup(this.header, 'Add')}
                  >
                    Add
                  </Button>
                </div>
              </div>

              {result.length > 0 ? <div className="row-xs-12 row-sm-12 row-lg-12 row-md-12 ">
                <div>
                  <div style={{ overflow: "auto" }}
                    className="control-section"
                  >
                    <GridComponent
                      className="grid-head-custome"
                      id="overviewgrid"
                      allowPaging
                      allowFiltering
                      filterSettings={this.Filter}
                      rowHeight={40}
                      height={gridHeightNew}
                      pageSettings={{
                        pageSize:
                          (window.innerHeight ||
                            document.documentElement.clientHeight ||
                            document.body.clientHeight) > 767
                            ? 14 //12
                            : 12,
                        pageCount: 3
                      }}
                      dataSource={result}
                      allowTextWrap={true}
                      // width={'100%'}
                      gridLines='Both'
                      dataBound={this.dataBound.bind(this)}
                      onDataBinding={this.onDataBinding("userrolemasterId")}
                      created={this.onFirstGridCreated.bind(this)}
                    >
                      <ColumnsDirective>
                        {getGridColumn("orgChartMasterAdmin").map((item) => {
                          switch (item.colName) {
                            case ROLE_MASTER:
                              return (
                                <ColumnDirective
                                  key={item.field}
                                  template={this.roleTemplate}
                                  textAlign={`${item.align} !important`}
                                  field={item.field}
                                  headerText={item.colName}
                                  width={90}
                                  filter={this.check}
                                />
                              );
                            case EMPLOYEE_COUNT:
                              return (
                                <ColumnDirective
                                  key={item.field}
                                  template={this.level2ActionRole}
                                  textAlign={`${item.align} !important`}
                                  field={item.field}
                                  headerText={item.colName}
                                  width={item.width}
                                  filter={this.check}
                                  type={"number"}
                                />
                              );
                            case ACTION:
                              return (
                                <ColumnDirective
                                  key={item.field}
                                  template={this.actionTemplate}
                                  textAlign={`${item.align} !important`}
                                  field={item.field}
                                  headerText={item.colName}
                                  width={110}
                                  filter={false}
                                />
                              );

                            default:
                              return null

                          }
                        })}
                      </ColumnsDirective>
                      <Inject services={[Page, DetailRow, Filter]} />
                    </GridComponent>

                    <div className="loader-new" id="spinnerContainer" >
                      <Progressbar />
                    </div>

                  </div>
                </div>
              </div> :
                <div className="control-section col-md-12" style={{ height: 75 }}>
                  {isLoading && (<div className="loader-new">
                    {/* <Progressbar /> */}
                  </div>)
                  }
                  {(result.length === 0 && isLoading === false) && (
                    <ErrorMessage message="No Data Available" />
                  )}
                </div>}
            </div>

            <div>
              {
                openPopup &&
                <OrgcharPopUp closePopup={this.closePopup} component={"UserRoleMaster"} title={title} type={type} userRoleData={userRoleData} />
              }

            </div>
          </div>
        </div>
      </>

    )
  }
}
const mapStateToProps = ({ adminreducer, auth }) => {
  const { user_role_flag } = adminreducer;
  const { authUser } = auth
  return { user_role_flag, authUser };
};

export default connect(mapStateToProps, { updateUserRoleMasterRedux, saveUserRoleMasterFunction })(UserRoleMaster);

