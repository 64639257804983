import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import LeaveApprovalTab from "./leaveapprovaltab";
import LeaveApproved from "../leavesapply/leaveapplicationaaproved";
import { updateLnAData } from "../../../../redux/actions/lna/lnaaction";
import LnAServices from "../../../../services/lna/lnaservice";
import { ComboBoxComponent } from "@syncfusion/ej2-react-dropdowns";
import { getYearData } from "../../../../app/other/commonlayoutfunction";

const LeaveApproval = (props) => {
  const [selectedTab, setSelectedTab] = useState("leaveApproval");
  const [isLoading, setIsLoading] = useState(true);
  const [isReject, setIsReject] = useState(false);
  const [height, setHeight] = useState(0);
  const [leaveApprovalData, setLeaveApprovalData] = useState([]);
  const [leaveTransferApprovalData, setLeaveTransferApprovalData] = useState(
    []
  );
  const [swipeApprovalData, setSwipeApprovalData] = useState([]);
  const [compOffApprovalData, setCompOffApprovalData] = useState([]);
  const [specialAttendanceApprovalData, setSpecialAttendanceApprovalData] =
    useState([]);
  const [approvalPendingData, setApprovalPendingData] = useState({});
  const [approvalSubmit, setApprovalSubmit] = useState("");
  const [singleLeaveInfo, setSingleLeaveInfo] = useState({});
  const updateDimensions = () => {
    const updateheight = window.innerHeight - 95;
    setHeight(updateheight);
  };
  useEffect(() => {
    setIsLoading(true);
    window.addEventListener("resize", updateDimensions()); //NOSONAR
    if (props.fyId) {
      initialDataFetch();
    }
  }, []);

  const initialDataFetch = async () => {
    if (props.financialYearData.length > 0) {
      setApprovalSubmit("");
      await getMasterData("leaveApproval");
      await getMasterData("approvalPending");
    }
  };

  useEffect(() => {
    if (selectedTab !== "") {
      setApprovalSubmit("");
      setSingleLeaveInfo({});
    }
    if (Object.keys(approvalPendingData).length === 0) {
      getMasterData("approvalPending");
    }
  }, [selectedTab]);

  useEffect(() => {
    if (!isLoading && props.fyId !== null) {
      setLeaveApprovalData([]);
      changeMasterData();
      getMasterData(selectedTab);
      setApprovalSubmit("");
      getMasterData("approvalPending");
    } else if (props.fyId === null) {
      setLeaveApprovalData([]);
      getMasterData("approvalPending");
    }
  }, [props.fyId]);

  useEffect(() => {
    let tempYearId = props.fyId;
    if (!tempYearId) {
      //NOSONAR
      getYearData(props.authUser)
        .then((result) => {
          props.updateLnAData({ fyId: result });
        })
        .catch((err) => {
          console.log("err===", err);
        });
    }
  }, []);

  useEffect(() => {
    if (props.leaveApprovelStatus === "updated") {
      setLeaveApprovalData([]);
      setIsLoading(false);
      changeMasterData(false);
      getMasterData(selectedTab);
      setApprovalSubmit("");
      setApprovalSubmit("");
      getMasterData("approvalPending");
      props.updateLnAData({ leaveApprovelStatus: "sync" });
    } else if (props.leaveApprovelStatus === "processing") {
      setIsLoading(true);
    }
  }, [props.leaveApprovelStatus]);

  const changeMasterData = (getInfo) => {
    props.updateLnAData({ leaveApplyLoadName: "", leaveData: {} });
    if (getInfo) {
      getMasterData(selectedTab);
      setIsLoading(true);
    }
  };

  const getMasterData = async (tabName) => {
    setIsLoading(true);
    const leaveApprovalListData = await LnAServices.getLeavesApprovalList(
      tabName,
      props.authUser,
      props.fyId ? props.fyId : 0,
      0
    );
    const leaveApprovalList = leaveApprovalListData.Result;
    if (leaveApprovalList && leaveApprovalList.length > 0) {
      if (tabName === "approvalPending") {
        let pendingCount = approvalPendingData;

        leaveApprovalList &&
          leaveApprovalList.forEach((each) => {
            //nosonar
            pendingCount[each.type] = each.pending;
          });
        setApprovalPendingData({ ...approvalPendingData, ...pendingCount });
      }

      if (tabName === "leaveApproval") {
        setLeaveApprovalData(leaveApprovalList);
        setIsLoading(false);
      } else if (tabName === "leaveTransferApproval") {
        setLeaveTransferApprovalData(leaveApprovalList);
        setIsLoading(false);
      } else if (tabName === "swipeApproval") {
        setSwipeApprovalData(leaveApprovalList);
        setIsLoading(false);
      } else if (tabName === "compOffApproval") {
        setCompOffApprovalData(leaveApprovalList);
        setIsLoading(false);
      } else if (tabName === "specialAttendanceApproval") {
        setSpecialAttendanceApprovalData(leaveApprovalList);
        setIsLoading(false);
      }
    }
    setIsLoading(false);
  };

  const getSingleLeaveInfo = async (tabName, leaveId, type) => {
    if (
      type === "leaveApprovalSubmit" ||
      type === "specialAttendanceApprovalSubmit"
    ) {
      const leaveApprovalListData = await LnAServices.getLeavesApprovalList(
        tabName,
        props.authUser,
        props.fyId,
        leaveId
      );
      if (leaveApprovalListData) {
        setSingleLeaveInfo(leaveApprovalListData.Result[0]);
        setIsLoading(false);
        setApprovalSubmit(type);
      }
      props.updateLnAData({
        leaveApplyLoadName: "leave-view",
        leaveApplicationId: leaveId,
        leaveData: leaveApprovalListData.Result[0],
      });
    } else {
      const leaveApprovalListData = await LnAServices.getLeavesApprovalList(
        tabName,
        props.authUser,
        props.fyId,
        leaveId
      );
      if (leaveApprovalListData) {
        setSingleLeaveInfo(leaveApprovalListData.Result[0]);
        setIsLoading(false);
        setApprovalSubmit(type);
      }
    }
  };
  const openTab = (evt, tabName) => {
    setSelectedTab(tabName);
    setIsLoading(true);
    getMasterData(tabName);
  };
  const goBack = () => {
    setApprovalSubmit("");
    props.updateLnAData({
      leaveApplyLoadName: "",
      leaveApplicationId: 0,
      leaveData: {},
    });
  };

  const handleApprovalSubmit = (type, Id, isRejectVal) => {
    setIsLoading(true);
    setIsReject(isRejectVal);
    setApprovalSubmit(type);
    getSingleLeaveInfo(selectedTab, Id, type);
  };

  const updateGridWithStatus = () => {
    if (props.leaveApprovelStatus !== "updated") {
      changeMasterData(true);
      setSingleLeaveInfo({});
      props.updateLnAData({ leaveApprovalInfo: {} });
    }
  };

  const onFyYearChange = (e) => {
    props.updateLnAData({ fyId: e.value });
  };

  const rejectApplication = () => {
    // setState({ ...state, rejectBtnClicked: !rejectBtnClicked })
    setIsReject((prevState) => !prevState);
    // props.handleReject()
  };

  const cssStyle = ` 
  .e-outline.e-float-input.e-control-wrapper input {

    font-size: 13px;
}`;

  return (
    <div
      className="leave-approval-body"
      style={{
        height: height,
        border: "1px solid #ccc",
        backgroundColor: "#FFFFFF",
      }}
    >
      <style>{cssStyle}</style>
      {approvalSubmit === "" && (
        <div className="leave-approval-tabs">
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <div
              className={`leave-approval-individual-tabs`}
              type="submit"
              onClick={(e) => {
                openTab(e, "leaveApproval");
              }}
            >
              <div
                className={`leave-approval-tabs-content ${
                  selectedTab === "leaveApproval" ? "active-leave-approval" : ""
                }`}
              >
                <div className="leave-approval-tab-name">Leave</div>
                <div
                  className="leave-approval-tab-number"
                  style={{
                    backgroundColor:
                      approvalPendingData["leaveApproval"] === 0
                        ? ""
                        : "#F94F5E",
                  }}
                >
                  {approvalPendingData["leaveApproval"]}
                </div>
              </div>
            </div>

            <div
              className={`leave-approval-individual-tabs`}
              type="submit"
              onClick={(e) => {
                openTab(e, "leaveTransferApproval");
              }}
            >
              <div
                className={`leave-approval-tabs-content ${
                  selectedTab === "leaveTransferApproval"
                    ? "active-leave-approval"
                    : ""
                }`}
              >
                <div className="leave-approval-tab-name">Leave Transfer</div>
                <div
                  className="leave-approval-tab-number"
                  style={{
                    backgroundColor:
                      approvalPendingData["leaveTransferApproval"] === 0
                        ? ""
                        : "#F94F5E",
                  }}
                >
                  {approvalPendingData["leaveTransferApproval"]}
                </div>
              </div>
            </div>

            <div
              className={`leave-approval-individual-tabs`}
              type="submit"
              onClick={(e) => {
                openTab(e, "swipeApproval");
              }}
            >
              <div
                className={`leave-approval-tabs-content ${
                  selectedTab === "swipeApproval" ? "active-leave-approval" : ""
                }`}
              >
                <div className="leave-approval-tab-name">
                  Regularization Approval
                </div>
                <div
                  className="leave-approval-tab-number"
                  style={{
                    backgroundColor:
                      approvalPendingData["swipeApproval"] === 0
                        ? ""
                        : "#F94F5E",
                  }}
                >
                  {approvalPendingData["swipeApproval"]}
                </div>
              </div>
            </div>
            <div
              className={`leave-approval-individual-tabs`}
              type="submit"
              onClick={(e) => {
                openTab(e, "compOffApproval");
              }}
            >
              <div
                className={`leave-approval-tabs-content ${
                  selectedTab === "compOffApproval"
                    ? "active-leave-approval"
                    : ""
                }`}
              >
                <div className="leave-approval-tab-name">Comp. Off</div>
                <div
                  className="leave-approval-tab-number"
                  style={{
                    backgroundColor:
                      approvalPendingData["compOffApproval"] === 0
                        ? ""
                        : "#F94F5E",
                  }}
                >
                  {approvalPendingData["compOffApproval"]}
                </div>
              </div>
            </div>
            <div
              className={`leave-approval-individual-tabs`}
              type="submit"
              onClick={(e) => {
                openTab(e, "specialAttendanceApproval");
              }}
            >
              <div
                className={`leave-approval-tabs-content ${
                  selectedTab === "specialAttendanceApproval"
                    ? "active-leave-approval"
                    : ""
                }`}
              >
                <div className="leave-approval-tab-name">
                  Special Attendance
                </div>
                <div
                  className="leave-approval-tab-number"
                  style={{
                    backgroundColor:
                      approvalPendingData["specialAttendanceApproval"] == 0
                        ? ""
                        : "#F94F5E",
                  }}
                >
                  {approvalPendingData["specialAttendanceApproval"]}
                </div>
              </div>
            </div>
          </div>
          {/* <div className="approval-list-element">Financial Year</div> */}
          <div
            className="combobox-css approval-list-element"
            id="approvalFY-parent-count"
          >
            <ComboBoxComponent
              id="approvalFYCount"
              dataSource={props.financialYearData}
              fields={{ text: "Name", value: "Id" }}
              cssClass="e-outline "
              data-msg-containerid="approvalFYErr"
              name="approvalFY"
              floatLabelType="Auto"
              placeholder="Select Year *"
              value={props.fyId}
              width={180}
              change={(e) => onFyYearChange(e)}
              allowCustom={false}
            />
          </div>
        </div>
      )}

      {selectedTab === "leaveApproval" && approvalSubmit === "" && (
        <LeaveApprovalTab
          approvalData={leaveApprovalData}
          type={"leaveApproval"}
          mainTitle={"Leave Approval"}
          isLoading={isLoading}
          isReject={isReject}
          handleApprovalSubmit={handleApprovalSubmit}
          reloadPage={changeMasterData}
        />
      )}

      {selectedTab === "leaveTransferApproval" && approvalSubmit === "" && (
        <LeaveApprovalTab
          approvalData={leaveTransferApprovalData}
          type={"leaveTransferApproval"}
          handleApprovalSubmit={handleApprovalSubmit}
          isLoading={isLoading}
          isReject={isReject}
          mainTitle={"Leave Transfer Approval"}
          reloadPage={changeMasterData}
        />
      )}

      {selectedTab === "swipeApproval" && approvalSubmit === "" && (
        <LeaveApprovalTab
          approvalData={swipeApprovalData}
          type={"swipeApproval"}
          handleApprovalSubmit={handleApprovalSubmit}
          isLoading={isLoading}
          isReject={isReject}
          mainTitle={"Regularization List"}
          reloadPage={changeMasterData}
        />
      )}
      {selectedTab === "compOffApproval" && approvalSubmit === "" && (
        <LeaveApprovalTab
          approvalData={compOffApprovalData}
          type={"compOffApproval"}
          handleApprovalSubmit={handleApprovalSubmit}
          mainTitle={"Comp. Off Approval"}
          isLoading={isLoading}
          isReject={isReject}
          reloadPage={changeMasterData}
        />
      )}
      {selectedTab === "specialAttendanceApproval" && approvalSubmit === "" && (
        <LeaveApprovalTab
          approvalData={specialAttendanceApprovalData}
          type={"specialAttendanceApproval"}
          handleApprovalSubmit={handleApprovalSubmit}
          mainTitle={"Special Attendance Approval"}
          isLoading={isLoading}
          isReject={isReject}
          reloadPage={changeMasterData}
        />
      )}
      {approvalSubmit !== "" && (
        <div className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
          <LeaveApproved
            height={height}
            type={approvalSubmit}
            selectedTab={selectedTab}
            singleLeaveInfo={singleLeaveInfo}
            isLoading={isLoading}
            rejectApplication={rejectApplication}
            goBack={goBack}
            isReject={isReject}
            updateGridWithStatus={updateGridWithStatus}
          />
        </div>
      )}
    </div>
  );
};

LeaveApproval.propTypes = {};

const mapStateToProps = ({ auth, lnareducer }) => {
  const { authUser } = auth;
  const { fyId, leaveApprovelStatus, financialYearData } = lnareducer;
  return { authUser, fyId, leaveApprovelStatus, financialYearData };
};

const mapDispatchToProps = { updateLnAData };

export default connect(mapStateToProps, mapDispatchToProps)(LeaveApproval);
