import React, { useEffect, useState, useRef } from 'react'
import { Button } from "@material-ui/core";
import { connect } from 'react-redux';
import { CheckBoxComponent } from '@syncfusion/ej2-react-buttons';
import { updateLnAData, saveLeaveCancelResponse, savePartialLeaveCancelResponse } from '../../../../redux/actions/lna/lnaaction';
import LnAService from '../../../../services/lna/lnaservice';
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { BREModuleActions, pleaseEnterReason } from '../../../../assets/constants/constants';
import { FormValidator } from "@syncfusion/ej2-inputs";
import { urlConfig } from '../../../../config/config';
import { SPECIAL_ATTENDANCE } from '../../../../redux/constant/meetingcategoryconstant';
import { cracterLimit } from '../../../../app/other/commonfunction';
import ProgressBar from '../../../progressbar/progressbar';
import breservices from '../../../../services/breservice';
import ApprovalGrid from '../../../approvalgrid/approvalgrid';

import ConfirmationPopup from '../../../../utilities/confirmationpopup';
const LeaveDetails = (props) => {
  const { authUser, leaveApplicationId, leaveData, leaveApplyLoadName, boxHeight, moduletype, categoryName, leaveBalanceType = "", leaveBalanceIsSpecialAttendance = false } = props;
  const [isShown, setIsShown] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false)
  const [apiCalled, setApiCalled] = useState(false)
  const [dataToSave, setDataToSave] = useState({})
  const [approvalData, setApprovalData] = useState(null);
  const [cancelLeaveReason, setCancelLeaveReason] = useState(null);
  const [state, setState] = useState({
    height: window.innerHeight - (boxHeight ? boxHeight + 195 : 195),
    applyedLeaveDates: [],
    // height: null,
    setIsShown: false,
    containerHeight: null,
    tableHeight: null,
  });
  const [leaveDetails, setLeaveDetails] = useState([])
  const [cancelLeaveData, setCancelLeaveData] = useState([])
  const [selectAll, setSelectAll] = useState(false);
  const [checkedItems, setCheckedItems] = useState([]);
  const textareaObj = useRef(null);
  useEffect(() => {
    window.addEventListener("resize", updateDimensions());/**NOSONAR */
    getMasterData();
  }, [])
  const updateDimensions = () => {
    const updateheight = window.innerHeight - (boxHeight ? boxHeight + 195 : 258)
    const minusHeight = (leaveBalanceType === "leaveBalanceType") ? 230// NOSONAR 
      : 301;
    const containerHeight = window.innerHeight - minusHeight;
    const tableHeight = window.innerHeight - 855;
    setState(preState => ({ ...preState, height: updateheight, containerHeight: containerHeight, tableHeight: tableHeight }))
  }
  useEffect(() => {
    window.addEventListener("resize", updateDimensions()); // NOSONAR
  }, [props.leaveApplyStatus])

  useEffect(() => {
    getApprovalData(authUser, "LEAVE_APPLIED", props.leaveApplicationId);
  }, [props]);
  //NOSONAR
  const getApprovalData = async (authUser, type, leaveId) => {
    if (authUser && leaveId) {
      const response = await LnAService.getApprovalStatusMatrixData(authUser, type, leaveId);
      if (response && response.Result && Array.isArray(response.Result)) {
        setApprovalData(response.Result);
      }
    }
  }

  const getMasterData = async () => {
    setApiCalled(true)
    if (props.leaveApplicationId != null) {
      const leaveTypeMater = await LnAService.getLeaveDetailsData(authUser, props.leaveApplicationId, 'GET', props.isSpecial || categoryName === SPECIAL_ATTENDANCE || (leaveBalanceType === "leaveBalanceType" && leaveBalanceIsSpecialAttendance));
      setCancelLeaveData(leaveTypeMater.Result);
      setLeaveDetails(leaveTypeMater.Result[0]);
    }
    setApiCalled(false)
  }
  const handleClick = event => {
    setIsShown(current => !current);
  };
  const handleScroll = event => {
    if (isShown === false) {
      const element = document.getElementById('leavDetail');
      if (element) {
        element.scrollTo({
          top: element.scrollHeight,
          behavior: 'smooth'
        });

      }
    }
  }


  const onInput = (e) => {
    try {
      if (textareaObj !== null && typeof textareaObj !== 'undefined') {
        textareaObj.current.respectiveElement.style.height = "auto";
        textareaObj.current.respectiveElement.style.minHeight = "100px";
        textareaObj.current.respectiveElement.style.height = `${textareaObj.current.respectiveElement.scrollHeight}px`;
        textareaObj.current.respectiveElement.style.maxHeight = "100px";
        cracterLimit(e, 'leaveDetilsReasonCount', '200')
      }
      if (textareaObj === null && typeof textareaObj === 'undefined') {
        cracterLimit({ value: "" }, 'leaveDetilsReasonCount', '200')
      }
    } catch (error) {
    }
  };

  const handleHeaderCheckboxChange = () => {
    const allItems = cancelLeaveData.map(item => item.leaveDateFormat);
    setSelectAll(!selectAll);
    setCheckedItems(selectAll ? [] : allItems);
  };

  const handleChildCheckboxChange = (index) => {
    const updatedCheckedItems = [...checkedItems];
    const item = cancelLeaveData[index].leaveDateFormat;
    if (updatedCheckedItems.includes(item)) {
      updatedCheckedItems.splice(updatedCheckedItems.indexOf(item), 1);
    } else {
      updatedCheckedItems.push(item);
    }
    setCheckedItems(updatedCheckedItems);
    setSelectAll(updatedCheckedItems.length === cancelLeaveData.length);
  };

  const leaveReasonHandler = (sanitizedValue, type) => {
    let options = {};
    if (type === 'leaveCancel') {
      setCancelLeaveReason(sanitizedValue);
      document.getElementById('leaveCancelReasonErr').innerHTML = '';
      document.getElementById('leaveCancelReasonErr').className = 'leave-application-body e-error';
      options = {
        rules: {
          leaveCancelReasonErr: {
            required: [true, pleaseEnterReason],
          },
        },
      }
    }
    setTimeout(() => {
      const addFormObject = new FormValidator("#leaveDetailsCancelForum", options);
      addFormObject.validate();
    }, 100);
  }
  const saveLeaveCancelData = async () => {
    const options = {
      rules: {
        leaveCancelReasonErr: {
          required: [true, pleaseEnterReason],
        },
      },
    };

    const data = await getBREDataForLeave(leaveDetails.leaveClusterId);
    setTimeout(() => {
      const addFormObject = new FormValidator('#leaveDetailsCancelForum', options);
      if (addFormObject.validate()) {
        if (cancelLeaveReason.length <= 0) {
          document.getElementById('leaveCancelReasonErr').innerHTML = pleaseEnterReason;
          document.getElementById('leaveCancelReasonErr').className = 'leave-application-body e-error';
          setCancelLeaveReason('')
          return false;
        }
        let leaveCancelSaveData = {
          userId: authUser,
          type: 'EDIT',
          leaveApplicationId: leaveApplicationId,
          cancelReason: cancelLeaveReason,
          autoApprovalRequired: data.enum_auto_approval,
          supervisorApprovalRequired: data.enum_supervisor_approval,
          hrApprovalRequired: data.enum_HR_approval,
          numberOfHrsToApproval: data.int_no_hrs_to_approval,
          leaveCancelDate: cancelLeaveData.length > 1 ? checkedItems.toString() : cancelLeaveData[0].leaveDateFormat,
          isSpecialAttendance: categoryName === SPECIAL_ATTENDANCE,
          isLeavePartiallyCancel: cancelLeaveData.length === 1 ? 0 : 1  /* If Single Leave is applied then set 0 and for series Leave set 1*/
        }
        setDataToSave(leaveCancelSaveData)
        setShowConfirm(true)
        setApiCalled(false)
      }

    }, 100);
  }
  /**
   * @author Vivek Khodade
   * @description get bre data
   */
  const getBREDataForLeave = async (pSouceLeaveId) => {
    try {
      const objData = { "int_leave_id": pSouceLeaveId }
      //NOSONAR
      if (categoryName === SPECIAL_ATTENDANCE) {
        return await breservices.getBREData(authUser, BREModuleActions.specialAttendanceCancellation, objData);
      } else {
        return await breservices.getBREData(authUser, BREModuleActions.leaveCancellation, objData);
      }
    } catch (error) {
      console.log("🚀 ~ file: leavedetails.jsx:192 ~ getBREDataForLeave ~ error:", error)
    }
  }

  const handleClose = () => {
    setShowConfirm(false)
    setApiCalled(false)
  }
  const handleYes = () => {
    props.savePartialLeaveCancelResponse(dataToSave)
    props.updateLnAData({
      leaveApplicationId: 0
    })
    setShowConfirm(false)
    setApiCalled(true)
  }

  const getTeamMemberName = (userId) => {
    const { othersCheckAvailablity } = props;
    const teamMember = othersCheckAvailablity?.find((item) => item.UserId === userId);
    if (teamMember)
      return teamMember.FirstName + " " + teamMember.LastName;
    else
      return "";
  }

  const LeaveReasonBox = `.LeaveCancelBox .e-float-input.e-control-wrapper.e-outline{
        height: 115px !Important;
      }
    `;

  const LeaveReasonBoxMedia = `.LeaveCancelBox .e-float-input.e-control-wrapper.e-outline{
        height: 115px !Important;
      }
      
      @media screen and (max-width: 1366px) and (max-height: 768px) {
        .LeaveCancelBox .e-float-input.e-control-wrapper.e-outline{
            height: 80px !Important;
        }
      }
    `;
  const path = urlConfig.lnaApiUrl + "document/lna/leaveapply/"
  return (
    <>
      {moduletype !== "approvals" && <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
        <div style={{
          backgroundColor: '#F2F2F2',
          height: "3rem",
          display: "flex",
          borderStartEndRadius: "0.4em",
          borderStartStartRadius: "0.4em",
          alignItems: "center",
          paddingLeft: "1rem",
          fontWeight: 600,
          width: '100%',
        }}
        >
          <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12"
            style={{ color: 'black', fontStyle: 'Circular Std Book', fontSize: '1rem', fontFamily: "Arial" }}
          >
            {categoryName !== SPECIAL_ATTENDANCE ? 'Leave Details' : 'Special Attendance Details'}
          </div>
        </div>
      </div>}

      {!apiCalled && leaveData &&
        <>
          <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12' id='leavDetail'
            style={{
              overflowY: "auto",
              height: `${props.type !== 'leaveApprovalSubmit' ? state.containerHeight + 'px' : ''}`,
              marginBottom: (leaveBalanceType === "leaveBalanceType") ? "55px" : null
            }}>

            <div className='row1'>
              <div className='col-xs-12 col-sm-12 col-lg-12 col-md-12 '>
                <div className='col-xs-7 col-sm-7 col-lg-7 col-md-7 pt-sm'>
                  <div className="regular-container-head">{categoryName !== SPECIAL_ATTENDANCE && !props.isSpecial ? 'Type of Leave' : 'Type of Attendance'}</div>
                  <div className="regular-container-head-res">{leaveDetails.leaveSymbolfull}</div>
                </div>
                <div className='col-xs-5 col-sm-5 col-lg-5 col-md-5 pt-sm'>
                  <div className="regular-container-head">Applied For</div>
                  <div className="regular-container-head-res">{leaveDetails.userId === authUser ? 'Self' : getTeamMemberName(leaveDetails.userId)}</div>
                </div>

                <div className='col-xs-7 col-sm-7 col-lg-7 col-md-7 pt-sm'>
                  <div className="regular-container-head">From Date</div>
                  <div className="regular-container-head-res">{(leaveDetails.startDate)}</div>
                </div>

                <div className='col-xs-5 col-sm-5 col-lg-5 col-md-5 pt-sm'>
                  <div className="regular-container-head">To Date</div>
                  <div className="regular-container-head-res">{(leaveDetails.endDate)}</div>
                </div>

                {/* <div className='col-xs-12 col-sm-12 col-lg-12 col-md-12 pt-sm'>
                                    <div className="regular-container-head-res" style={{ marginLeft: '15px' }}> Applied for {leaveData.noOfDays} days</div>
                                </div> */}
                <div className='col-xs-7 col-sm-7 col-lg-7 col-md-7 pt-sm'>
                  <div className="regular-container-head-res"> Applied for {leaveDetails.noOfLeaveDays} days</div>
                </div>
                <div className='col-xs-5 col-sm-5 col-lg-5 col-md-5 pt-sm'>
                  <div className="regular-container-head">Status</div>
                  <div className="regular-container-head-res">{(leaveDetails.applicationStatus)}</div>
                </div>

                <div className='col-xs-7 col-sm-7 col-lg-7 col-md-7 pt-sm'>
                  <div className="regular-container-head">Application Date</div>
                  <div className="regular-container-head-res">{leaveDetails.appliedOn}</div>
                </div>
                {(leaveDetails.approvedOn !== null && leaveDetails.approvedOn !== "") &&
                  <div className='col-xs-4 col-sm-4 col-lg-4 col-md-4 pt-sm' style={{ color: '#939399' }}>
                    <div className="regular-container-head">Approval Date</div>
                    <div className="regular-container-head-res">{leaveDetails.approvedOn}</div>
                  </div>
                }
                {approvalData && <ApprovalGrid
                  approvalData={approvalData}
                  authUser={authUser}
                  handleApprove={props.handleApproved}
                  handleReject={props.handleError}
                  isAutoApproved={approvalData && approvalData.length <= 1 ? true : false}
                  isHideButtons={props.isHideButtons} />
                }

                <div className='col-xs-12 col-sm-12 col-lg-12 col-md-12 pt-sm '>
                  <div className="regular-container-head" style={{ marginLeft: '15px' }}>Reason</div>
                  <div className="regular-container-head-res" style={{ marginLeft: '15px', maxHeight: 100, overflowY: 'auto', whiteSpace: 'normal' }}>{leaveDetails.reason}</div>
                </div>

                <div className='col-xs-12 col-sm-12 col-lg-12 col-md-12' >
                  <div className='col-xs-4 col-sm-4 col-lg-4 col-md-4 pt-sm' style={{ color: '#939399' }}>
                    <div className="regular-container-head">Send Notification To</div>
                    <div className="regular-container-head-res">{leaveData.notifyUsersList === "" ? 0 : leaveData?.notifyUsersList?.split(',').length} Members</div>
                  </div >

                  <div className='col-xs-4 col-sm-4 col-lg-4 col-md-4 pt-sm'>
                    {(leaveDetails?.notifyUsersList !== "" && leaveDetails?.notifyUsersList !== null && leaveApplyLoadName !== 'leave-cancel') &&
                      <Button style={{ height: '32px', border: '1px solid #F94F5E', textAlign: 'center', color: '#F94F5E', cursor: 'pointer', opacity: '1', fontWeight: 500 }}
                        onClick={async () => { await handleClick(); handleScroll(); }}
                      >
                        {isShown === false && <>See List</> || <>Hide List</>}
                      </Button>
                    }
                  </div>

                  <div className='col-xs-4 col-sm-4 col-lg-4 col-md-4 pt-sm' style={{ color: '#939399' }}>
                    <div className="regular-container-head">Reporting Supervisor</div>
                    <div className="regular-container-head-res">{leaveDetails.relieverName}</div>
                  </div>
                </div>

                {(isShown === true && leaveApplyLoadName !== 'leave-cancel') &&
                  <div className='row1'>
                    <div className='col-lg-11 col-md-11 col-sm-11 mt-lg'
                      style={{
                        height: '3rem',
                        backgroundColor: '#F6F6F6',
                        color: 'black',
                        fontFamily: "Circular TT Book",
                        fontWeight: 'bold',
                        padding: 0,
                      }}>
                      <div className='col-lg-6 col-md-6 col-sm-6' style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', height: '100%' }}>Name</div>
                      <div className='col-lg-6 col-md-6 col-sm-6' style={categoryName !== SPECIAL_ATTENDANCE && !props.isSpecial || categoryName === '113' ? { display: 'flex', justifyContent: 'flex-start', alignItems: 'center', height: '100%' } : { marginLeft: '0px', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', height: '100%' }}>Team</div>
                    </div>


                    <div className='col-lg-11 col-md-11 col-sm-11' style={{ padding: 0 }}>
                      <div style={{ overflowY: 'auto' }}>
                        {leaveData.notifyUsersList && leaveData.notifyUsersList?.split(',').map((item, index) => {
                          return (
                            <div key={"notifyUsersList" + index /**NOSONAR */} className='col-lg-12 col-md-12 col-sm-12' style={{ height: '3rem', border: '1px solid #F6F6F6', color: 'black' }}>
                              <div className='col-lg-6 col-md-6 col-sm-6 circular-tt-regular' style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', height: '100%' }}>{item.split('|')[0]}</div>
                              <div className={'col-lg-6 col-md-6 col-sm-6 circular-tt-regular'} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', height: '100%' }}  >{item.split('|')[1]}</div>
                            </div>
                          )
                        }
                        )}
                      </div>
                    </div>
                  </div>}
                {(leaveDetails.cancelledOn !== null || leaveDetails.cancelledApprovedOn !== null || leaveData.cancelReason !== "") && <div className='col-xs-12 col-sm-12 col-lg-12 col-md-12 pt-sm pl-sm pr-sm'><hr style={{ opacity: 1 }} /> </div>}
                <div className='col-xs-12 col-sm-12 col-lg-12 col-md-12' >

                  {(leaveDetails.cancelledOn !== null && leaveDetails.cancelledOn !== "") &&
                    <div className='col-xs-8 col-sm-8 col-lg-8 col-md-8 pt-sm' style={{ color: '#939399' }}>
                      <div className="regular-container-head">Cancellation Date</div>
                      <div className="regular-container-head-res">{leaveDetails.cancelledOn}</div>
                    </div >
                  }
                  {(leaveDetails.cancelledApprovedOn !== null && leaveDetails.cancelledApprovedOn !== "") &&
                    <div className='col-xs-4 col-sm-4 col-lg-4 col-md-4 pt-sm' style={{ color: '#939399' }}>
                      <div className="regular-container-head">Cancellation Approved On</div>
                      <div className="regular-container-head-res">{leaveDetails.cancelledApprovedOn}</div>
                    </div>
                  }

                </div>
                {(leaveDetails.cancelReason !== null && leaveDetails.cancelReason !== "") &&
                  <div className='col-xs-12 col-sm-12 col-lg-12 col-md-12 pt-sm'>
                    <div className="regular-container-head" style={{ marginLeft: '15px' }}>Cancel Reason</div>
                    <div className="regular-container-head-res" style={{ marginLeft: '15px', maxHeight: 40, overflowY: 'auto', whiteSpace: 'normal' }}>{leaveDetails.cancelReason}</div>
                  </div>
                }
                {(moduletype === "approvals" && isShown !== true && leaveDetails.attachmentName !== null && leaveDetails.attachmentName !== "" && leaveDetails.attachmentName !== undefined) &&
                  <div className='col-xs-12 col-sm-12 col-lg-12 col-md-12 pt-sm'>
                    <div className="regular-container-head" style={{ marginLeft: '15px' }}>Attachment</div>
                    <div className="regular-container-head-res" style={{ marginLeft: '15px' }}>
                      <a
                        href={`${path}${leaveDetails.attachmentName}`}
                        style={{ textDecoration: 'none' }}
                        rel="noopener noreferrer"
                        download
                        target={"_blank"}
                      // target={""}
                      >
                        {leaveDetails.attachmentName}
                      </a>
                    </div>
                  </div>
                }
              </div>
            </div>



            <div className='row1 leavedetails-attachment'>
              {!moduletype === "approvals" && isShown === false && leaveDetails.attachmentName !== "" && <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 pl-md leave-app-attachment-text' style={{ marginTop: '30px' }} >
                Attachment
                <div className="regular-container-head-res">
                  <a
                    href={`${path}${leaveDetails.attachmentName}`}
                    style={{ textDecoration: 'none' }}
                    rel="noopener noreferrer"
                    download
                    target={"_blank"}
                  >
                    {leaveDetails.attachmentName}
                  </a>
                </div>
              </div>}
              {leaveApplyLoadName === 'leave-cancel' && cancelLeaveData && cancelLeaveData.length > 1 &&
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                  <div className='col-lg-11 col-md-11 col-sm-11 mt-lg'
                    style={{
                      height: '3rem',
                      backgroundColor: '#F6F6F6',
                      color: 'black',
                      fontFamily: "Circular TT Book",
                      fontWeight: 'bold',
                      padding: 0,
                      display: 'flex',
                    }}>
                    <div className='col-lg-1 col-md-1 col-sm-1' style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                      <CheckBoxComponent checked={selectAll} change={handleHeaderCheckboxChange} />
                    </div>
                    <div className='col-lg-5 col-md-5 col-sm-5' style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', height: '100%' }}>Date</div>
                    <div className='col-lg-5 col-md-5 col-sm-5' style={categoryName !== SPECIAL_ATTENDANCE && !props.isSpecial || categoryName === '113' ? { display: 'flex', justifyContent: 'flex-start', alignItems: 'center', height: '100%' } : { marginLeft: '0px', display: 'flex', justifyContent: 'flex-start', alignItems: 'center', height: '100%' }}>Leave Type</div>
                  </div>

                  <div className='col-lg-11 col-md-11 col-sm-11' style={{ padding: 0 }}>
                    <div style={{ overflowY: 'auto' }}>
                      {cancelLeaveData && cancelLeaveData.map((item, index) => {
                        return (
                          <div key={"notifyUsersList" + index /**NOSONAR */} className='col-lg-12 col-md-12 col-sm-12' style={{ height: '3rem', border: '1px solid #F6F6F6', color: 'black', display: 'flex' }}>
                            <div className='col-lg-1 col-md-1 col-sm-1' style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                              <CheckBoxComponent checked={checkedItems.includes(item.leaveDateFormat)} change={() => handleChildCheckboxChange(index)} />
                            </div>
                            <div
                              className='col-lg-5 col-md-5 col-sm-5 circular-tt-regular'
                              style={{
                                display: 'flex',
                                justifyContent: 'flex-start',
                                alignItems: 'center',
                                height: '100%',
                                borderRight: '1px solid #F2F2F2',
                              }}>{item.leaveDate}</div>
                            <div className={'col-lg-5 col-md-5 col-sm-5 circular-tt-regular'} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', height: '100%' }}>{item.typeOfLeave}</div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
              }
              {
                leaveApplyLoadName === 'leave-cancel' &&
                <div id='leaveDetailsCancelForum' className="col-lg-11 col-md-11 col-sm-11 col-xs-11 pl-md pt-md pb-md LeaveCancelBox" style={{ height: '75px', paddingRight: 0 }}>
                  <div>
                    <style>{(leaveBalanceType === "leaveBalanceType") ? LeaveReasonBoxMedia : LeaveReasonBox}</style>
                    <TextBoxComponent
                      style={{ minHeight: 100 }}
                      id={'leaveCancelReason'}
                      placeholder={"Leave Cancel Reason *"}
                      cssClass="e-outline textarea"
                      floatLabelType="Auto"
                      ref={textareaObj}
                      input={onInput}
                      name="leaveCancelReasonErr"
                      data-msg-containerid="leaveCancelReasonErr"
                      multiline
                      inputProps={{
                        required: true,
                        minLength: 7,
                      }}
                      value={cancelLeaveReason}
                      htmlAttributes={{ maxlength: 200 }}
                      change={(e) => {
                        const sanitizedValue = e.value
                          .replace(/^\s+/g, '')
                          .replace(/\s+/g, ' ');
                        leaveReasonHandler(sanitizedValue.trim(), 'leaveCancel')
                      }}
                    />
                    <div id='leaveDetilsReasonCount' style={{ float: 'right', fontSize: 12, margin: '7px 15px 8px 0px', width: 'fit-content' }} className="error-div" />
                    <div
                      id="leaveCancelReasonErr"
                      className="e-input-group e-float-icon-left error-div"
                      style={{ marginTop: '4px' }}
                    />
                  </div>

                </div>
              }
            </div>
            <ConfirmationPopup
              showButton={{ type: "1" }}
              open={showConfirm}
              button1function={() => handleYes()}
              button2function={() => handleClose()}
              headerMessage={'Are you sure, you  want to cancel Leave?'}
              button1Text={"Yes"}
              button2Text={"No"}
            />

          </div>
          {moduletype !== "approvals" &&
            <div className='topBorder' style={{}}>
              <div
                className='col-sm-12 col-md-12 col-lg-12 pb-lg pt-md'
                style={{
                  display: "flex",
                  justifyContent: "right",
                  alignItems: "center",
                  textAlign: 'right',
                  height: '60px',
                  width: '100%',
                  position: 'absolute',
                  bottom: '0',
                  paddingRight: "10px",
                }}>
                <div>
                  {moduletype !== "approvals" &&

                    <button
                      className="leave-cancel-apply-button"
                      variant="contained"
                      onClick={() => {
                        props.updateLnAData({
                          leaveApplyLoadName: 'leave-details',
                          leaveApplicationId: 0
                        })
                      }}
                    >
                      Close
                    </button>}
                  {
                    props.type === "cancel-application" &&
                    <button
                      style={{ width: "184px", borderRadius: 0 }}
                      variant="contained"
                      className="remove-button-shadow-save"
                      color="primary"
                      onClick={saveLeaveCancelData}
                      disabled={apiCalled}
                    >
                      Cancel Application
                    </button>

                  }
                </div>
              </div>
            </div>
          }
        </>
      }
      {apiCalled && (
        <div
          style={{
            zIndex: 999999,
            position: "absolute",
            marginTop: "40%",
            marginLeft: "49%",
          }}
        >
          <ProgressBar />
        </div>
      )}
    </>
  )
}

const mapStateToProps = ({ meetingcategory, lnareducer, auth, meetingmaster }) => {
  const { authUser } = auth;
  const { categoryName } = meetingcategory;
  const { othersCheckAvailablity } = meetingmaster;
  const {
    leaveApplicationId, leaveData, leaveApplyLoadName, from } = lnareducer;
  return {
    authUser,
    categoryName,
    othersCheckAvailablity,
    leaveApplicationId, leaveData, leaveApplyLoadName, from
  }
}

export default connect(mapStateToProps, { updateLnAData, saveLeaveCancelResponse, savePartialLeaveCancelResponse })(LeaveDetails)
