/**
 * Author: Prashant Waphare.
 * Created:23-09-2020
 */
 import {
  SET_ORGCHART_DETAILS,
} from "../../constant/entryexit/separationconstant";
import {
  ADD_NEW_ORGCHART_MASTER_DATA,
} from "../../constant/entryexit/orgchartconstant";

const INIT_STATE = {
  employeeId: 0,
  fistName: "",
  lastName: "",
  legalName:"",
  designation: 0,
  titleRole: "",
  employmentType: 0,
  entityId: 0,
  serviceDevision: 0,
  reportingSupervison: 0,
  band: 0,
  emailId: "",
  country: 0,
  location: "",
  functiondata: 0,
  orgchartupdateStatus:"",
  updatedEmployee: null,
  costSharingReporting:[],
  costSharing:[],
  lavel6Acting:false,
  lavel7Acting:false,
  lavel8Acting:false,
  reportingActing:false,
  lavel6HeadId:0,
  lavel7HeadId:0,
  lavel8HeadId:0,
  lavel6Head:[{ Id: 0, Name: "" }],
  lavel7Head:[{ Id: 0, Name: "" }],
  lavel8Head:[{ Id: 0, Name: "" }],
  empCode:'',
  joiningDate:null,
  leftDate:null,
  showOverView:true,
  teamMemberUpdate:"",
  showFullScreen:false
};
// eslint-disable-next-line
export default (state = INIT_STATE, action) => { //NOSONAR
if (action.type === SET_ORGCHART_DETAILS|| action.type === ADD_NEW_ORGCHART_MASTER_DATA){
    return {
      ...state,
      ...action.payload,
    };
  }else{
    return state;
  }  
};
