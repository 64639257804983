/**
 * Author Name :- Prashant Waphare
 * Date : 16-04-2020
 * Description :- This file use for show prejoining candidate list
 */
import * as React from "react";
import queryString from "query-string";
import { closest, isNullOrUndefined } from "@syncfusion/ej2-base";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Filter,
  Inject,
  VirtualScroll,
  Sort,
  Page,
  Resize,
  ExcelExport,
  Toolbar
} from "@syncfusion/ej2-react-grids";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import "../../app/containers/tmlc/onboarding/onboarding.css";
import { CheckBoxComponent } from "@syncfusion/ej2-react-buttons";
import { Redirect } from "react-router-dom";
import { getDisplayDateOnly, encrypt, decodeString, encodeString, getFormatDateForGrid } from "../../app/other/commonfunction";
import EmployeeNameTemplate from "./employeenametemplate";
import { PRE_OFFERE } from "../../redux/constant/meetingcategoryconstant";
import { setMeetingCategory } from '../../redux/actions/meetingcategoryaction';
import { DocStatusTemp } from '../../components/separation/allcommoncomponents';
import DateFnsUtils from '@date-io/date-fns';
import {
  getGridColumn,
  CANDIDATE_NAME,
  DATE_OF_JOINING,
  DAYS_TO_JOIN,
  OFFER_DATE,
  DOC_APPROVAL_STATUS,
  DOC_STATUS,
  ON_TARGET_INCENTIVE,
  FIXED_CTC
} from "../../app/other/gridcolumns";
import { DateFilterformat } from '../../config/config';

function OfferedDate(props) {
  // eslint-disable-next-line
  const { OfferedDate } = props;/**NOSONAR */
  return (
    <div className="date-format-all-grid">
      <span>{getDisplayDateOnly(new Date(OfferedDate))}</span>
    </div>
  );
}
function JoiningDate(props) {
  // eslint-disable-next-line
  const { JoiningDate } = props;/**NOSONAR */
  return (
    <div className="date-format-all-grid">
      <span>{getDisplayDateOnly(new Date(JoiningDate))}</span>
    </div>
  );
}
function DaysToJoin(props) {
  // eslint-disable-next-line
  const { DaysToJoin } = props;/**NOSONAR */
  return (
    <div
    //  style={{ textAlign: "center" }}
    >
      <span>{DaysToJoin}</span>
    </div>
  );
}

function oti(props){  
  const { oti } = props;  // NOSONAR
  return(
    <div style={{textAlign: "Right"}}>
      <span>{ oti===null ? "" : oti.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})}</span>
    </div>
  )
}

function fixedCTC(props){
  const { fixedCTC } = props; // NOSONAR
  return(
    <div style={{textAlign: "Right"}}>
      <span>{ fixedCTC===null ? "": fixedCTC.toLocaleString(undefined, {minimumFractionDigits: 0, maximumFractionDigits: 0})}</span>
    </div>
  )
}



class TobeOfferCandidateGridView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      offerChangeStatus: [],
      offerChangeStatusRevoke: [],
      redirect: null,
    };
    this.height =
      (window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight) - 370;
    this.width =
      (window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth) - 100;
    this.dReady = false;
    this.dtTime = false;
    this.isDataBound = false;
    this.isDataChanged = true;
    this.dropSlectedIndex = null;
    this.check = {
      type: "CheckBox",
    };
    this.select = {
      persistSelection: true,
      type: "Multiple",
      checkboxOnly: true,
    };
    this.Filter = {
      type: "Menu",
    };
    this.ReportTo = {
      type: "CheckBox",
      // itemTemplate: empTemplate
    };
    this.pageOptions = {
      pageSize:
        (window.innerHeight ||
          document.documentElement.clientHeight ||
          document.body.clientHeight) > 700
          ? 10
          : 5,
      pageCount: 5
    };
    this.name = {
      type: "CheckBox",
    };
    this.toolbarOptions = [
      // 'ExcelExport',
      { text: 'Excel Export', tooltipText: 'Excel Export', id: 'ExcelExport', align: 'Right', prefixIcon: 'e-export-excel' }
    ];
    this.employeeNameTemplate = this.employeeNameTemplate.bind(this);
    this.navigateToAddBasicInformation = this.navigateToAddBasicInformation.bind(
      this
    );
    this.onComplete = this.onComplete.bind(this);
    this.onLoad = this.onLoad.bind(this);
    this.onQueryCellInfo = this.onQueryCellInfo.bind(this);
    this.onDataBound = this.onDataBound.bind(this);
  }

  componentDidMount() {
    setTimeout(() => {
      const ele = document.getElementsByClassName("e-headercelldiv");
      for (let i in ele) {
        if (!isNaN(parseInt(i, 10))) {
          let index = parseInt(i, 10);
          ele[index].classList.add("grid-left-header");
        }
      }
    }, 100);
  }

  
  onQueryCellInfo(args) {
    const { cell } = args;
    cell.setAttribute("style", "font-size:14px !important; text-align: left !important; padding-left:18px !important");
  }

  onDataBound() {
    clearTimeout(this.clrIntervalFun);
    clearInterval(this.intervalFun);
    this.dtTime = true;
  }

  onComplete(args) {
    if (args.requestType === "filterchoicerequest") {
      if (
        args.filterModel.options.field === "Trustworthiness" ||
        args.filterModel.options.field === "Rating" ||
        args.filterModel.options.field === "Status"
      ) {
        const span = args.filterModel.dialogObj.element.querySelectorAll(
          ".e-selectall"
        )[0];
        if (!isNullOrUndefined(span)) {
          closest(span, ".e-ftrchk").classList.add("e-hide");
        }
      }
    }
  }

  onLoad() {
    document
      .getElementById("overviewgrid")
      .ej2_instances[0].on("data-ready", () => {
        this.dReady = true;
        this.stTime = performance.now();
      });
    document
      .getElementById("overviewgrid")
      .addEventListener("DOMSubtreeModified", () => {
        if (this.dReady && this.stTime && this.isDataChanged) {
          this.stTime = null;
          this.dReady = false;
          this.dtTime = false;
          this.isDataChanged = false;
        }
      });
  }

  onChange() {
    this.ddObj.hidePopup();
    this.gridInstance.showSpinner();
    this.dropSlectedIndex = null;
    clearTimeout(this.clrIntervalFun2);
    this.clrIntervalFun2 = setTimeout(() => {
      this.isDataChanged = true;
      this.stTime = null;
      const contentElement = this.gridInstance.contentModule.getPanel()
        .firstChild;
      contentElement.scrollLeft = 0;
      contentElement.scrollTop = 0;
      this.gridInstance.pageSettings.currentPage = 1;
      this.stTime = performance.now();
      this.gridInstance.hideSpinner();
    }, 100);
  }

  checkedBoxRevokeTemplate = (props) => {
    const { offerChangeStatusRevoke, offerChangeStatus } = this.state;
    return (
      <div className="check-box-alignment-all-candidate-grid">
        <CheckBoxComponent
          checked={(offerChangeStatusRevoke.includes(props.CandidateId) || offerChangeStatus.includes(props.CandidateId)) ? true : false}
          // checked={false}
          id={`candidateId${props.CandidateId}`}
          value={props.CandidateId}
          // ref={(scope) => { this.checkboxObj = scope; }}
          name={props.DocStatus}
          onChange={(e) => this.getValueOfcheckBox(e)}
        />
      </div>
    );
  };

  navigateToAddBasicInformation = async (args) => {
    try {
      localStorage.setItem('last_meeting_category', decodeString(localStorage.getItem('meeting_category')))
      localStorage.setItem("meeting_category", encodeString(PRE_OFFERE));
      const params = queryString.parse(this.props.location.search);
      const path = `/preoffer?candid=${encrypt(args.CandidateId)}&id=${params.id}&appId=${params.appId}`;
      this.setState({
        redirect: path,
        // changedData: !changedData,
      });
    } catch (error) {
    }
  };

  getValueOfcheckBox = (e) => {
    if (e.target.properties.name === "Complete") {
      let arr = [];
      arr = this.state.offerChangeStatus;
      if (!arr.includes(parseInt(e.target.properties.value))) {
        arr.push(parseInt(e.target.properties.value));
        this.setState({
          offerChangeStatus: arr,
        });
      } else {
        arr = arr.filter((t) => (t) !== parseInt(e.target.properties.value));
        this.setState({
          offerChangeStatus: arr,
        });
      }
    } else {
      let arrRevokeStatus = this.state.offerChangeStatusRevoke;
      if (!arrRevokeStatus.includes(parseInt(e.target.properties.value))) {
        arrRevokeStatus.push(parseInt(e.target.properties.value));
        this.setState({
          offerChangeStatusRevoke: arrRevokeStatus,
        });
      } else {
        let filterDataRevokeStatus = this.state.offerChangeStatusRevoke;
        filterDataRevokeStatus = filterDataRevokeStatus.filter((t) => (t) !== parseInt(e.target.properties.value));

        this.setState({
          offerChangeStatusRevoke: filterDataRevokeStatus,
        });
      }
    }

    // eslint-disable-next-line
    if (this.props.getOfferGridCheckBoxValue !== undefined) {
      // eslint-disable-next-line
      this.props.getOfferGridCheckBoxValue(this.state.offerChangeStatus.toString());
      // this.setState({ offerChangeStatus: [] })
    }
    // eslint-disable-next-line
    if (this.props.getToBeOfferGridCheckBoxValue !== undefined) {
      this.props.getToBeOfferGridCheckBoxValue(this.state.offerChangeStatus, 1);
      // this.setState({ offerChangeStatus: [] })
    }
    // eslint-disable-next-line
    if (this.props.getToBeOfferGridCheckBoxValueRevoke !== undefined) {
      // eslint-disable-next-line
      this.props.getToBeOfferGridCheckBoxValueRevoke(
        this.state.offerChangeStatusRevoke,
        1
      );
      // this.setState({ offerChangeStatusRevoke: [] })
    }


  };

  employeeNameTemplate(props) {
    return (
      <EmployeeNameTemplate
        props={props}
        data="test"
        navigateToAddBasicInformation={this.navigateToAddBasicInformation}
      />
    );
  }
  docStatus = (props) => {
    const { DocStatus } = props;
    return (
      <DocStatusTemp status={DocStatus}
      />
    );
  }
  docApprovalStatus = (props) => {
    const { DocApprovalStatus } = props;
    return (
      <DocStatusTemp status={DocApprovalStatus}
      />
    );
  }

  toolbarClick = (args) => {
    if (args.item.text === 'Excel Export' && this.gridInstance) {
      if (this.gridInstance.getColumnByField("checkbox")) {
        this.gridInstance.getColumnByField("checkbox").visible = false;
      }

      this.gridInstance.excelExport(this.getExcelExportProperties());
    }
  }

  excelExportComplete = () => {
    if (this.gridInstance) {
      if (this.gridInstance.getColumnByField("checkbox")) {
        this.gridInstance.getColumnByField("checkbox").visible = true;
      }
    }
  }

  getExcelExportProperties = () => {
    const dateFns = new DateFnsUtils();
    var currentDate = dateFns.format(new Date(), 'yyyyMMdd');
    if (this.props.type === "toBeOffer") {
      return {
        fileName: `${currentDate}_to-be-offered.xlsx`
      }
    } else {
      return {
        fileName: `${currentDate}_offered.xlsx`
      }
    }
  }

  render() {
    const { gridData } = this.props;
    const { redirect } = this.state;
    if (redirect !== null) {
      return <Redirect to={redirect} />;
    }
    var widthColumn;
    var finalWidth = 0;
    // eslint-disable-next-line 
    getGridColumn("To Be Offered").forEach((data) => {
      widthColumn = widthColumn + 10 + data.width;
    });
    if (widthColumn <= this.width) {
      finalWidth = widthColumn;
    } else {
      finalWidth = this.width
    }
    return (
      <div className="row">
        <div className="row-xs-12 row-sm-12 row-lg-12 row-md-12">
          <div
            style={{
              paddingLeft: "30px",
              paddingRight: "30px",
              paddingTop: "5px",
            }}
          >
            {

              // getGridColumn("To Be Offered").map((data) => {

            
           
              //   return (
              <GridComponent
                className="grid-head-custome"
                id="overviewgrid"
                allowPaging
                pageSettings={this.pageOptions}
                dataSource={getFormatDateForGrid(gridData)}
                enableHover={false}
                enableVirtualization={false}
                rowHeight={30}
                height={this.height}
                ref={(g) => {
                  this.gridInstance = g;
                }}
                actionComplete={this.onComplete}
                load={this.onLoad}
                queryCellInfo={this.onQueryCellInfo}
                dataBound={this.onDataBound}
                filterSettings={this.Filter}
                allowFiltering
                allowSorting
                allowSelection
                selectionSettings={this.select}
                allowExcelExport={true}
                toolbar={this.toolbarOptions}
                toolbarClick={this.toolbarClick}
                excelExportComplete={this.excelExportComplete}
                allowTextWrap={true}
                width={`${finalWidth}`}
              >
                <ColumnsDirective>
                  <ColumnDirective
                    template={this.checkedBoxRevokeTemplate}
                    width="50"
                    field={"checkbox"}
                    headerText={""}
                    allowFiltering={false}
                  />
                  {getGridColumn("To Be Offered").map((item) => {
                    switch (item.colName) {
                      case CANDIDATE_NAME:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.employeeNameTemplate}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                            filter={this.Filter}
                          />
                        );
                      case DOC_STATUS:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.docStatus}
                            filter={this.check}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                          />
                        );
                      case DOC_APPROVAL_STATUS:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.docApprovalStatus}
                            filter={this.check}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                          />
                        );
                      case OFFER_DATE:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={OfferedDate}
                            filter={this.check}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                            format={DateFilterformat}
                          />
                        );
                      case DATE_OF_JOINING:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={JoiningDate}
                            filter={this.check}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                            format={DateFilterformat}
                          />
                        );
                      case DAYS_TO_JOIN:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={DaysToJoin}
                            filter={this.check}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                          />
                        );
                        case ON_TARGET_INCENTIVE:
                          return (
                            <ColumnDirective
                              key={item.field}
                              template={oti}
                              filter={this.check}
                              textAlign={`${item.align} !important`}
                              field={item.field}
                              headerText={item.colName}
                              width={item.width}
                            />
                          );
                          case FIXED_CTC:
                            return (
                              <ColumnDirective
                                key={item.field}
                                template={fixedCTC}
                                filter={this.check}
                                textAlign={`${item.align} !important`}
                                field={item.field}
                                headerText={item.colName}
                                width={item.width}
                              />
                            );
                      default:
                        return (
                          <ColumnDirective
                            key={item.field}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                            filter={this.check}
                          />
                        );
                    }
                  })}
                </ColumnsDirective>
                <Inject services={[Page, Filter, VirtualScroll, Sort, Resize, ExcelExport, Toolbar]} />
              </GridComponent>
              //   );

              // })
            }
          </div>
        </div>
      </div>
    );
  }
}
TobeOfferCandidateGridView.propTypes = {
  // eslint-disable-next-line
  gridData: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
TobeOfferCandidateGridView.defaultProps = {
  gridData: "",
};
const mapStateToProps = ({ candidateReducer, meetingmaster, router }) => {
  const {
    // eslint-disable-next-line
    othersCheckAvailablity } = meetingmaster;
    const { location } = router;
  const { sourceCategoryData, jobPortalData, jobTitleData } = candidateReducer;
  return { sourceCategoryData, jobPortalData, jobTitleData, othersCheckAvailablity, location  };
};
export default connect(mapStateToProps, { setMeetingCategory })(TobeOfferCandidateGridView);
