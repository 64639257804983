import axios from "axios";
import { SalesClientMeet } from "../assets/constants/constants";
import { urlConfig } from "../config/config";
import {dencrypt} from "../app/other/commonfunction";

function errorHandler(reject, error) {
  console.dir(error);
  reject("operation failed");
}

function getClientDts(pSearch, pMode, pId, pUserType) {
  return new Promise(function onThen(resolve, reject) {
    axios
      .get(
        `${urlConfig.apiurl}bdclient/${dencrypt(localStorage.getItem("bd_user_id"))}/${pMode}?search=${pSearch}&id=${pId.toString()}&usertype=${pUserType}`,
        {
          headers: {
            authorization: localStorage.getItem("jwtToken"),
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      // eslint-disable-next-line
      .then(function (response) {
        if (response != null) {
          return resolve(response.data.result);
        }
      })
      // eslint-disable-next-line
      .catch(function (error) {
        errorHandler(reject, error);
        return null;
      });
  });
}

function getContacts(pId, pMode, type, pContactIds, pMeetingSubTypeId, meetingTypeId, btbId) {
  let user_type
  user_type = SalesClientMeet.includes(pMeetingSubTypeId) ? "Sales" : "Ops";


  return new Promise(function onThen(resolve, reject) {
    axios
      .get(
        `${urlConfig.apiurl}bdcontact/${dencrypt(localStorage.getItem("bd_user_id"))}/${pId}/${pMode}?usertype=${user_type}&searchtype=${type}${pContactIds.length > 0 ? `&contactids=${pContactIds}` : ""}${btbId > 0 ? `&btbId=${btbId}` : ""}`,
        {
          headers: {
            authorization: localStorage.getItem("jwtToken"),
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      // eslint-disable-next-line
      .then(function (getContactsResponse) {
        if (getContactsResponse != null) {
          return resolve(getContactsResponse.data.result);
        }
      })
      // eslint-disable-next-line
      .catch(function (error) {
        errorHandler(reject, error);
        return null;
      });
  });
}
function getBDClients(text, pId, pSearchTypes, pMeetingSubTypeId, meetingTypeId) {
  let user_type
  user_type = SalesClientMeet.includes(pMeetingSubTypeId) ? "Sales" : "Ops";

  return new Promise(function onThen(resolve, reject) {
    axios
      .get(
        `${urlConfig.apiurl}bdclient/${dencrypt(localStorage.getItem("bd_user_id"))}?usertype=${user_type}&search=${text}${pSearchTypes !== "" ? `&id=${pId}&searchtype=${pSearchTypes}` : "" // NOSONAR
        }`,
        {
          headers: {
            authorization: localStorage.getItem("jwtToken"),
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      // eslint-disable-next-line
      .then(function (getBDClientsResponse) {
        if (getBDClientsResponse != null) {
          return resolve(getBDClientsResponse.data.result);
        }
      })
      // eslint-disable-next-line
      .catch(function (error) {
        errorHandler(reject, error);
        return null;
      });
  });
}

function getValidateBTB(pBTBId, pClientId, pMeetingSubTypeId, meetingTypeId) {
  let user_type="";
    user_type = SalesClientMeet.includes(pMeetingSubTypeId) ? "Sales" : "Ops";
  return new Promise(function onThen(resolve, reject) {
    axios
      .get(
        `${urlConfig.apiurl}validatebtb/${dencrypt(localStorage.getItem("bd_user_id"))}/${pBTBId.toString()}/${user_type}${pClientId > 0 ? `/${pClientId.toString()}` : ""}`, // NOSONAR
        {
          headers: {
            authorization: localStorage.getItem("jwtToken"),
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      // eslint-disable-next-line
      .then(function (getValidateBTBResponse) {
        if (getValidateBTBResponse != null) {
          return resolve(getValidateBTBResponse.data.result);
        }
      })
      // eslint-disable-next-line
      .catch(function (error) {
        errorHandler(reject, error);
        return null;
      });
  });
}

function getBTBJobTask(authUser, pSubcatId, pBTBId) {
  if (pSubcatId > 0) {
    let apiParams;
    // eslint-disable-next-line
    if (parseInt(pBTBId) === 0 || pBTBId === "")
      apiParams = `${authUser}/${pSubcatId}`;
    else apiParams = `${authUser}/${pSubcatId}/${pBTBId}`;
    return new Promise(function onThen(resolve, reject) {
      axios
        .get(`${urlConfig.apiurl}getbtbjob/${apiParams}`, {
          headers: {
            authorization: localStorage.getItem("jwtToken"),
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        // eslint-disable-next-line
        .then(function (getBTBJobTaskResponse) {
          if (getBTBJobTaskResponse != null) {
            return resolve(getBTBJobTaskResponse.data.result);
          }
        })
        // eslint-disable-next-line
        .catch(function (error) {
          errorHandler(reject, error);
          return null;
        });
    });
  }
  return null;
}

const bdServices = {
  // Revealing Module Pattern
  getClientDts,
  getContacts,
  getValidateBTB,
  getBTBJobTask,
  getBDClients,
};

export default bdServices;
