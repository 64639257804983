import React from 'react';
import TeamSharedGrid from "../../../../components/okrcostsharing/teamsharedgrid.jsx"
import SharedWithMe from "../../../../components/okrcostsharing/sharedwithme.jsx"
import Requested from "../../../../components/okrcostsharing/requested.jsx"
import { connect } from "react-redux";
import okrServices from "../../../../services/okr/okrservices";
import { setCostSharingDetails } from '../../../../redux/actions/okr/okractions';
class CostSharing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sharedResourceList: [],
      sharedWithMe: [],
      requestedList: [],
    };
  }
  componentDidMount = () => {
    localStorage.removeItem('SelectedTabAction');
    this.refreshtabs();
    this.getSharedResourceList();
    this.getRequestList();
    this.getSharedWithMe();
  };
  componentWillReceiveProps(newProps) {
    if (newProps.newRequestStatus === "updated") {
      this.getSharedResourceList();
      this.getRequestList();
      this.getSharedWithMe();
      this.props.setCostSharingDetails({ newRequestStatus: "" });
    }
  }
  getSharedResourceList = () => {
    const { authUser } = this.props;
    try {
      const getSharedResourceList = Promise.resolve(
        okrServices.getSharedResourceList(authUser, 'SharedResource')
      );
      getSharedResourceList.then((result) => {
        if (result !== null) {
          this.setState({ sharedResourceList: result });
        } else {
          this.data = "error";
        }
      });
    } catch (e) {
      // eslint-disable-next-line
      console.log(e);
    }
  }
  getSharedWithMe = () => {
    const { authUser } = this.props;
    try {
      const getSharedWithMe = Promise.resolve(
        okrServices.getSharedResourceList(authUser, 'SharedWithMe')
      );
      getSharedWithMe.then((result) => {
        if (result !== null) {
          this.setState({ sharedWithMe: result });
        } else {
          this.data = "error";
        }
      });
    } catch (e) {
      // eslint-disable-next-line
      console.log(e);
    }
  }
  getRequestList = () => {
    const { authUser } = this.props;
    try {
      const getRequestList = Promise.resolve(
        okrServices.getSharedResourceList(authUser, 'Request')
      );
      getRequestList.then((result) => {
        if (result !== null) {
          this.setState({ requestedList: result });
        } else {
          this.data = "error";
        }
      });
    } catch (e) {
      // eslint-disable-next-line
      console.log(e);
    }
  }
  openTab = (evt, tabName) => {
    localStorage.setItem('SelectedTabAction', tabName);
    const data = evt;
    if (tabName === "Team Shared") {
      this.getSharedResourceList();
    }
    if (tabName === "Shared With Me") {
      this.getSharedWithMe();
    }
    if (tabName === "Request") {
      this.getRequestList();
    }
    let i;
    const tabcontent = document.getElementsByClassName("tabcontent");
    // eslint-disable-next-line
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }
    const onboardingtab = document.getElementsByClassName("onboardingtab");
    // eslint-disable-next-line
    for (i = 0; i < onboardingtab.length; i++) {
      onboardingtab[i].className = onboardingtab[i].className.replace("active", "");
    }
    document.getElementById(tabName).style.display = "block";
    data.currentTarget.className += "active";
  };
  refreshtabs() {
    const getTabActive = localStorage.getItem('SelectedTabAction');
    if (getTabActive !== null && getTabActive === 'Shared With Me') {
      document.getElementById(`${getTabActive}`).style.display = "block";
    } else if (getTabActive !== null && getTabActive === 'Request') {
      document.getElementById(`${getTabActive}`).style.display = "block";
    } else if (getTabActive === null || getTabActive === 'Team Shared') {
      localStorage.setItem('SelectedTabAction', 'Team Shared');
      document.getElementById("Team Shared").style.display = "block";
    }
  }

  render() {
    const { sharedResourceList, sharedWithMe, requestedList } = this.state;
    return <>
      <div className="tabs-main-div">
        <>
          <div className="tab col-12" style={{ padding: '0px', borderRadius: '5px' }}>
            <div className="tabs-div-width">
              <button
                type="submit"
                className={`col-lg-4 onboardingtab onboarding-tab ${(localStorage.getItem('SelectedTabAction') === "Team Shared" || localStorage.getItem('SelectedTabAction') === null) ? 'active' : ''}`}
                onClick={(e) => this.openTab(e, "Team Shared")}
              ><b>TEAM SHARED</b>
                <span className="confirmation-tab-count-dot">
                  {sharedResourceList.length === undefined ? sharedResourceList.result.length : 0}
                </span>
              </button>
              <button
                type="submit"
                className={`col-lg-4 onboardingtab onboarding-tab ${localStorage.getItem('SelectedTabAction') === "Shared With Me" ? 'active' : ''}`}
                onClick={(e) => this.openTab(e, "Shared With Me")}
              ><b>SHARED WITH ME </b>
                <span className="confirmation-tab-count-dot">
                  {sharedWithMe.length === undefined ? sharedWithMe.result.length : 0}
                </span>
              </button>
              <button
                type="submit"
                className={`col-lg-4 onboardingtab onboarding-tab ${localStorage.getItem('SelectedTabAction') === "Request" ? 'active' : ''}`}
                onClick={(e) => this.openTab(e, "Request")}
              ><b>REQUESTS</b>
                <span className="confirmation-tab-count-dot">
                  {requestedList.length === undefined ? requestedList.result.length : 0}
                </span>
              </button>
            </div>
            <div id="Team Shared" className="tabcontent">
              <TeamSharedGrid sharedResourceList={sharedResourceList} />
            </div>
            <div id="Shared With Me" className="tabcontent">
              <SharedWithMe sharedWithMe={sharedWithMe} />
            </div>
            <div id="Request" className="tabcontent">
              <Requested requestedList={requestedList} />
            </div>
          </div>
        </>
      </div>
    </>
  }
}
const mapStateToProps = ({ auth, okrreducer }) => {
  const { authUser } = auth;
  const { employeeId, sharedWith, startDate, endDate, percSalaryShared, amountShared, currencyId, description, newRequestStatus, totalCostSharing } = okrreducer;
  return { authUser, employeeId, sharedWith, startDate, endDate, percSalaryShared, amountShared, currencyId, description, newRequestStatus, totalCostSharing };
};
export default connect(mapStateToProps, { setCostSharingDetails })(
  CostSharing
);