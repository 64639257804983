  /**
 * Author Name : Vivek Khodade.
 * Created Date: -
 * Last Modified Date: 15/04/2020.
 * Last Modified By: Vivek Khodade.
 * Code Reviewed Date : 08/09/2020
 * Code Reviewed By : Vivek Khodade
 */
import * as React from "react";
import { TIB_AVERAGE_REPORT } from "../../../redux/constant/meetingcategoryconstant";
import TreeGrid from "../../../components/treegrid/treegridcomponent";

class TIBAverageReport extends React.Component {
  constructor(props) {
    super(props);
    const { reportData } = this.props;
    this.state = {
      changedData: false
    }
    this.data = reportData;
    this.dataSourceSettings = {
      enableSorting: true,
      columns: [{ name: 'Year' }, { name: 'Month' }, { name: 'Report', caption: 'Weekend Type' }],
      formatSettings: [
        { name: 'TIB', format: 'hh:mm', type: 'date' },
        { name: 'Days', format: '#0.#0' }
      ],
      valueSortSettings: { headerDelimiter: ' - ' },
      values: [
        { name: 'User', caption: 'Employee Count', type: 'sum' },
        { name: 'Days', caption: 'Category Days count', type: 'Avg' },
        { name: 'TIB', caption: 'Monthly Average', type: 'Avg' }
      ],
      dataSource: this.props.reportData,
      sortSettings: [{ name: 'bucket_name', order: 'Descending' }],
      // sortSettings: [{ name: 'bucket', order: 'Descending' }],
      rows: [
        // { name: 'bucket', caption: 'Bucket', dataType: 'number' },
        { name: 'bucket_name', caption: 'Bucket', dataType: 'number' },
        { name: 'PNL' },
        { name: 'SD' },
        { name: 'Reporting_to', caption : 'Reporting Manager' },
        { name: 'User' }
      ],
      expandAll: false,
      filters: [],
      showGrandTotals: false,
      showRowGrandTotals: false,
      showColumnGrandTotals: true,
      showSubTotals: false,
      showHeaderWhenEmpty: false,
      enableVirtualization: true,
      showAggregationOnValueField: false
    };
  }
  componentDidMount = () => {
    // -- Function to call on Component did mount
  }  
  UNSAFE_componentWillReceiveProps(newProps) {
    const { reportData } = newProps;
    const { changedData } = this.state;
    this.dataSourceSettings.dataSource = reportData;
    this.setState({ changedData: !changedData });
  }
  render() {
    const { reportData, reportMonths, reportType } = this.props;
    var gridSettings = {};
    gridSettings.columnWidth = 110;
    gridSettings.allowReordering = false;
    gridSettings.allowTextWrap = false;
    gridSettings.allowSelection = true;
    gridSettings.selectionSettings = { mode: 'Both', type: 'Multiple' };
    gridSettings.allowAutoResizing = false;
    return (
      <div className="control-pane">
        <div className="control-section">
          <div className="col-md-12 force-overflow" style={{ marginLeft: 5, marginBottom: 25, paddingRight: 10 }} >
            {/* -- {this.dataSourceSettings.dataSource && this.dataSourceSettings.dataSource.length > 0 && 
              <PivotVeiw
                PivotViewId={'PivotView'}
                dataSourceSettings={[this.dataSourceSettings]}
                showFieldList={true}
                showGroupingBar={true}
                width={'100%'}
                height={'700'}
                gridSettings={[gridSettings]}
                page={TIB_AVERAGE_REPORT}
                allowConditionalFormatting={true}
                allowNumberFormatting={true}
                column0Width={300}
              />
            } */}
            {this.dataSourceSettings.dataSource && this.dataSourceSettings.dataSource.length > 0 && 
              <TreeGrid page={TIB_AVERAGE_REPORT} reportData={reportData} reportMonths={reportMonths} reportType={reportType}/>
            }
          </div>
        </div>
      </div>
    );
  }
}

TIBAverageReport.propTypes = {};
TIBAverageReport.defaultProps = {};

export default TIBAverageReport;
