/**
 * Author By :- Vivek Khodade
 * Date : 01-06-2020
 * Description :- This file use for load saperation Absconding components
 */

import React, { Component } from "react";
import { AccordionComponent } from "@syncfusion/ej2-react-navigations";
import NotificationContainer from "../../../../../components/notification/notifycontainer";
import BasicInformation from '../../../../../components/basicinformation/basicinformation';
import ItDepartmentClearance from '../../../../../components/separation/exitclearancecomponents/itdepartmentclearance';
import RecoveryAmount from '../../../../../components/separation/exitclearancecomponents/recoveryamount';
import ExitClearanceFooterButton from '../../../../../components/separation/footerbuttons/exitclearancefooterbutton';
import { closeAgendaPopup } from "../../../../other/commonfunction";
import EmployeeHeader from "../../../../../components/employeeprofileheader/employeeprofileheader";
export default class FunctionalClearance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      height:
        (window.innerHeight ||
          document.documentElement.clientHeight ||
          document.body.clientHeight) - 188,
      width:
        (window.innerWidth ||
          document.documentElement.clientWidth ||
          document.body.clientWidth) - 71,
    }
    this.onCreated = this.onCreated.bind(this);
  }

  onCreated() {
    setTimeout(() => {
      if (this.acrdnbasic !== null) {
        // eslint-disable-next-line
        for (let i = 0; i <= this.acrdnbasic.items.length - 1; i++) {
          this.acrdnbasic.items[i].expanded = true;
        }
      }
      if (this.acrdnResignationcomponent !== null) {
        // eslint-disable-next-line
        for (let i = 0; i <= this.acrdnResignationcomponent.items.length - 1; i++) {
          this.acrdnResignationcomponent.items[i].expanded = true;
        }
      }
    }, 500);
  }
  /**
 * Calculate & Update state of new dimensions
 */
  updateDimensions() {
    let update_width = window.innerWidth - 100;    
    let update_height = window.innerHeight - 110;
    this.setState({ width: update_width, height: update_height });
  }
  componentDidMount = async () => {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
    closeAgendaPopup();
  };

  render() {
    return (
      <div>
        <div
          style={{ padding: 10, backgroundColor: "#F4F3F5", height: this.state.height, overflowY: 'auto' }}
        >
          <NotificationContainer />
          <div>
            <div style={{ padding: "0px 0px 10px 0px" }}>
              <EmployeeHeader />
            </div>

            <AccordionComponent
              ref={(accordion) => {
                this.acrdnbasic = accordion;
              }}
              created={this.onCreated}
            >
              <BasicInformation />
            </AccordionComponent>
          </div>
          <div style={{ marginTop: 10 }}>
            <AccordionComponent
              ref={(accordion) => {
                this.acrdnResignationcomponent = accordion;
              }}
              created={this.onCreated}
            >
              <ItDepartmentClearance />
            </AccordionComponent>
          </div>
          <RecoveryAmount />
        </div>
        <ExitClearanceFooterButton />
      </div>
    );
  }
}
