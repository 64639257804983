import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons";
import okrServices from '../../services/okr/okrservices';

import ProgressBar from '../progressbar/progressbar';
function ScoringLogicPopup({ authUser, empId, fyId, monthOfRating, potentialAxis, perfomanceAxis, fillcolor,closeScoringogicPopUp }) {
  const [isLoading, setLoading] = useState(true)
  const [data, setData] = useState([])

  useEffect(() => {
    getData()
  }, [])

  const getData = async () => {
    let chartMasterData = await okrServices.getOKRDashboardData('ScoringLogicMaster', authUser, empId, fyId, monthOfRating);
    let newData = []
    let axis1 = []
    let axis2 = []
    let axis3 = []
    chartMasterData.map((eac) => {
      if (eac.potentialAxis == 1) {
        axis1.push(eac)
      } else if (eac.potentialAxis == 2) {
        axis2.push(eac)
      }else if (eac.potentialAxis == 3) {
        axis3.push(eac)
      }
    })
    newData.push({ 1: axis3 })
    newData.push({ 2: axis2 })
    newData.push({ 3: axis1 })
    setData(newData)
    setLoading(false)
  }
  const style = `
    .highlight-scoring-box{
      background:${fillcolor} !important;
      color:white !important;
      font-weight:bold;
    }
    .highlight-scoring-axis-box{
      color:${fillcolor} !important;
      font-weight:bold;
    }
  `
  return (
    <div className='col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-md'>
      <style>{style}</style>
      {!isLoading && data.length > 0 && <>
        {data.map((each) => (<>
          <div style={{ display: "flex" }}>
            <div className='col-2 col-lg-2 col-md-2 col-sm-2 col-xs-2 ' key={'scoring-logic-main-box' + Object.keys(each)[0]}>
              <div className='col-8 col-lg-8 col-md-8 col-sm-8 col-xs-8 ' style={{ color: 'transparent' }}>
                .
              </div>
              <div className={`col-4 col-lg-4 col-md-4 col-sm-4 col-xs-4 scroling-logic-number scroling-logic-number${4 - Object.keys(each)[0]} pt-sm ${(4 - Object.keys(each)[0]) === potentialAxis ? 'highlight-scoring-axis-box' : " "}`}
                style={{
                  marginTop: 12
                  , display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: 69,
                  borderRadius:5
                }}>
                {4 - Object.keys(each)[0]}
              </div>
            </div>
            {Object.values(each)[0].map((eachVal) => (
              <div className='col-3 col-lg-3 col-md-3 col-sm-3 col-xs-3 pt-sm' key={'scoring-logic-box' + eachVal.scoringLogicId}>
                <div className={`scoring-logic-box-common ${eachVal.performanceAxis == perfomanceAxis && eachVal.potentialAxis === potentialAxis ? 'highlight-scoring-box' : ""}`} style={{ color: eachVal.scoringHeadingColor, border: "2px solid " + eachVal.scoringBodyColor }}>
                  <p style={{paddingTop:5}}>
                    {eachVal.scoringName.split(" ")[0]}
                  </p>
                  <p style={{ marginTop: -13 }}>
                    {eachVal.scoringName.split(" ")[1]}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </>
        ))}
        <div style={{ display: "flex" }}>
          <div className='col-2 col-lg-2 col-md-2 col-sm-2 col-xs-2 '></div>
          <div className='col-3 col-lg-3 col-md-3 col-sm-3 col-xs-3 pt-sm'>
            <div className={`scroling-logic-number scroling-logic-number1 ${perfomanceAxis === 1 ? 'highlight-scoring-axis-box' : " "}`}>
              1
            </div>
          </div>
          <div className={`col-3 col-lg-3 col-md-3 col-sm-3 col-xs-3 pt-sm ${perfomanceAxis === 2 ? 'highlight-scoring-axis-box' : " "}`}>
            <div className='scroling-logic-number scroling-logic-number2'>
              2
            </div>
          </div>
          <div className={`col-3 col-lg-3 col-md-3 col-sm-3 col-xs-3 pt-sm ${perfomanceAxis === 3 ? 'highlight-scoring-axis-box' : " "}`}>
            <div className='scroling-logic-number scroling-logic-number3'>
              3
            </div>
          </div>
        </div>
        <div className='row1'>
          <p className="y-axis-text-graph-logic">Y Axis - Potential <span className="arrow-up-icon"><FontAwesomeIcon icon={faLongArrowAltRight} /></span> </p>
          <p className="x-axis-text-graph-logic" >X Axis - Performance <span className="arrow-right-icon"><FontAwesomeIcon icon={faLongArrowAltRight} /></span></p>
        </div>
        <div className='row1'>
          <span style={{ color: 'transparent' }}>.</span>
          <div style={{ paddingTop: 20, color: 'transparent' }}>.</div>
          <div className='horizontal-line' style={{marginTop:10}}></div>
          <p className='scroling-logic-xy-axis-text pt-sm pl-xlg'>X Axis - Performance Business and Central OKR ( Score - {perfomanceAxis} )</p>
          <p className='scroling-logic-xy-axis-text pl-xlg'>Y Axis - Potential Leadership Principles ( Score - {potentialAxis} )</p>
          <div className='horizontal-line'></div>
        </div>
        {/* <div className='closeButton'>
          <Button
                onClick={closeScoringogicPopUp}
                color="primary"
                variant="contained"
              >
                Close
              </Button></div> */}

      </>}
      {isLoading && <div style={{ height: 50 }}>
        <ProgressBar />
      </div>
      }
    </div>
  )
}


const mapStateToProps = ({okrdashboardreducer }) => {
  const {xyAxixCardRankingData }=  okrdashboardreducer;
  return {
    xyAxixCardRankingData
  }
};
export default connect(mapStateToProps, { })(
  ScoringLogicPopup
);

/*

      {
        props.xyAxixCardRankingData.map((item)=>{
          const temp=item.boxClassification.split(' ')
          return(
            <div className='row1'> 
            <div className='col-3 col-lg-3 col-md-3 col-sm-3 col-xs-3 '>
                <div className='col-8 col-lg-8 col-md-8 col-sm-8 col-xs-8 ' style={{color:'transparent'}}>
                  .
                </div>
                <div className='col-4 col-lg-4 col-md-4 col-sm-4 col-xs-4 scroling-logic-number scroling-logic-number3 mt-sm'>
                  {item.scoreUid
}
                </div>
            </div>
            <div className='col-3 col-lg-3 col-md-3 col-sm-3 col-xs-3 '>
                <div className='potential-star'>{temp[0]} <br/> {temp[1]}</div>
            </div>
            {/* <div className='col-3 col-lg-3 col-md-3 col-sm-3 col-xs-3 '>
                <div className='high-potential'>High <br/> Potential</div>
            </div>
            <div className='col-3 col-lg-3 col-md-3 col-sm-3 col-xs-3 '>
                <div className='star-performer'>Star<br/> Performer</div>
            </div> */
           