import React, { Component } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faPlus, faArrowAltUp, faArrowAltDown, faEye, faCheckCircle, faUserCheck } from "@fortawesome/pro-light-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { getNotify } from '../../app/other/commonfunction';
import { WARNING, youCannotAddThirdPartyInterimHereIn } from '../../assets/constants/constants'
import { OKR_SELF_REVIEW, OKR_SUPERVISOR_REVIEW } from '../../redux/constant/meetingcategoryconstant'
library.add(
    faPencilAlt, faPlus, faArrowAltUp, faArrowAltDown, faCheckCircle, faUserCheck
);
export default class RatingObjectivKR extends Component {
    opneKrDetailsPopup = (data) => {
        this.props.opneKrDetailsPopup(data)
    }
    openActualAchivementPopup = (data, categoryType, actionType) => {
        this.props.openActualAchivementPopup(data, categoryType, actionType)
    }
    openAddEditIntrimFeedbackPopup = (data) => {
        this.props.openAddEditIntrimFeedbackPopup(data)
    }
    openViewIntrimFeedbackPopup = (data, userObjectiveId, selectedType, draftClick) => {
        this.props.openViewIntrimFeedbackPopup(data, userObjectiveId, selectedType, draftClick)
    }
    openKrIntrimFeedbackPopup = (data, userObjectiveId, objectiveName) => {
        this.props.openKrIntrimFeedbackPopup(data, userObjectiveId, objectiveName)
    }

    renderSelfSup = (item) => {//NOSONAR
        try {
            const { ratingTabs } = this.props
            if (ratingTabs === 'self-rating') {
                if (item.selfRecommendationAccess === "Y" && item.actualAchievement !== null) {
                    if (item.krSelfRecommendationRating.Type === "Add" || item.krSelfRecommendationRating.Type === "Edit") {
                        return (
                            item.krSelfRecommendationRating.Type === "Add" ?
                                <span className="ratingIconColor" onClick={() => this.props.openKrRatingPopup(item, "self")} ><b>{item.krSelfRecommendationRating.Type} </b></span>
                                :
                                <>
                                    <span className='rating-okr-onhover-text rating-grid-text' style={{ cursor: 'auto' }}>{item.krSelfRecommendationRating.Rating}</span>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <span style={{ color: "#B7B7BB" }}> | </span> &nbsp;&nbsp;&nbsp;&nbsp;
                                    <span style={{ color: item.krSelfRecommendationRating.IsPass === "Y" ? "#18BC00" : "#B7B7BB" }}>
                                        {item.krSelfRecommendationRating.IsPass}</span> &nbsp;&nbsp;&nbsp;&nbsp;<span style={{ color: "#B7B7BB" }}>  |  </span>&nbsp;&nbsp;&nbsp;&nbsp;
                                    <span className='rating-okr-onhover-text ratingIconColor'>
                                        <FontAwesomeIcon style={{ color: "#F94F5E" }} icon={faPencilAlt} onClick={() => this.props.openKrRatingPopup(item, "self")} /> </span>
                                </>
                        )
                    } else {
                        return (
                            item.krSelfRecommendationRating.Type === "View" && item.krSelfRecommendationRating.Rating > 0 ?
                                <>
                                    <span className='rating-okr-onhover-text rating-grid-text'>{item.krSelfRecommendationRating.Rating}</span>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <span style={{ color: "#B7B7BB" }}> | </span> &nbsp;&nbsp;&nbsp;&nbsp;
                                    <span style={{ color: item.krSelfRecommendationRating.IsPass === "Y" ? "#18BC00" : "#B7B7BB" }}>
                                        {item.krSelfRecommendationRating.IsPass}</span> &nbsp;&nbsp;&nbsp;&nbsp;<span style={{ color: "#B7B7BB" }}>  |  </span>&nbsp;&nbsp;&nbsp;&nbsp;
                                    <span className='rating-okr-onhover-text ratingIconColor'>
                                        <FontAwesomeIcon style={{ color: "#F94F5E" }} icon={faEye} onClick={() => this.props.viewKrReviewPopup(item, "Self")} /> </span>
                                </>
                                :
                                <>
                                    <span style={{ color: "#B7B7BB" }} className='rating-grid-text'>{item.krSystemRating}</span>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <span style={{ color: "#B7B7BB" }}> | </span> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <span style={{ color: "#B7B7BB" }}>
                                        {"Y"}</span> &nbsp;&nbsp;&nbsp;&nbsp;<span style={{ color: "#B7B7BB" }}>  |  </span>
                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; - &nbsp;&nbsp;
                                </>
                        )
                    }
                } else {
                    return (
                        item.krSelfRecommendationRating.Type === "Add" ?
                            <span className="" ><b>-</b></span>
                            :
                            <>
                                <span className='rating-okr-onhover-text rating-grid-text'>{item.krSelfRecommendationRating.Rating}</span>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <span style={{ color: "#B7B7BB" }}> | </span> &nbsp;&nbsp;&nbsp;&nbsp;
                                <span style={{ color: item.krSelfRecommendationRating.IsPass === "Y" ? "#18BC00" : "#B7B7BB" }}>
                                    {item.krSelfRecommendationRating.IsPass}</span> &nbsp;&nbsp;&nbsp;&nbsp;<span style={{ color: "#B7B7BB" }}>  |  </span>
                            </>
                    )
                }
            }

            // supervisor
            else {
                if (item.supRecommendationAccess === "Y" && item.actualAchievement !== null) {
                    if (item.krSupervisorRecommendationRating.Type === "Add") {
                        return (
                            <span className="ratingIconColor" onClick={() => this.props.openKrRatingPopup(item, "sup")} ><b>{item.krSupervisorRecommendationRating.Type} </b></span>
                        )
                    }
                    if (item.krSupervisorRecommendationRating.Type === "Edit") {
                        return (
                            <>
                                <span className='rating-okr-onhover-text rating-grid-text'>{item.krSupervisorRecommendationRating.Rating}</span>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <span style={{ color: "#B7B7BB" }}> | </span>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <span style={{ color: item.krSupervisorRecommendationRating.IsPass === "Y" ? "#18BC00" : "#B7B7BB", fontFamily: 'Circular TT Book' }}>
                                    {item.krSupervisorRecommendationRating.IsPass}</span>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <span style={{ color: "#B7B7BB" }}>
                                    |
                                </span>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <span className='rating-okr-onhover-text ratingIconColor'>
                                    <FontAwesomeIcon style={{ color: "#F94F5E" }} icon={faPencilAlt} onClick={() => this.props.openKrRatingPopup(item, "sup")} />
                                </span>
                            </>
                        )
                    }
                    if (item.krSupervisorRecommendationRating.Type === "View" && item.krSupervisorRecommendationRating.Rating > 0) {
                        return (
                            <>
                                <span className='rating-okr-onhover-text rating-grid-text'
                                    onClick={() => this.props.viewKrReviewPopup(item, "Supervisor")}>{item.krSupervisorRecommendationRating.Rating}</span>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <span style={{ color: "#B7B7BB" }}> | </span> &nbsp;&nbsp;&nbsp;&nbsp;
                                <span style={{ color: item.krSupervisorRecommendationRating.IsPass === "Y" ? "#18BC00" : "#B7B7BB" }}>
                                    {item.krSupervisorRecommendationRating.IsPass}</span> &nbsp;&nbsp;&nbsp;&nbsp;
                                <span style={{ color: "#B7B7BB" }}>
                                    |
                                </span>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <span className='rating-okr-onhover-text ratingIconColor'>
                                    <FontAwesomeIcon style={{ color: "#F94F5E" }} icon={faEye} onClick={() => this.props.viewKrReviewPopup(item, "Supervisor")} />
                                </span>
                            </>
                        )
                    } else {
                        return (
                            <>
                                <span className='rating-grid-text' style={{ color: "#B7B7BB" }}>{item.krSystemRating}</span>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <span style={{ color: "#B7B7BB" }}> | </span> &nbsp;&nbsp;&nbsp;&nbsp;
                                <span style={{ color: "#B7B7BB" }}>
                                    {"Y"}</span> &nbsp;&nbsp;&nbsp;&nbsp;
                                <span style={{ color: "#B7B7BB" }}>
                                    |
                                </span>
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; - &nbsp;&nbsp;

                            </>
                        )
                    }
                }
                else {
                    return (
                        item.krSupervisorRecommendationRating.Type === "Add" ? <span className="" ><b>-</b></span>
                            :
                            item.actualAchievement !== null ?/*NOSONAR*/
                                <>
                                    <span className='rating-okr-onhover-text'><b>{item.krSupervisorRecommendationRating.Rating}</b></span>
                                    &nbsp;&nbsp;&nbsp;&nbsp;
                                    <span style={{ color: "#B7B7BB" }}> | </span> &nbsp;&nbsp;&nbsp;&nbsp;
                                    <span style={{ color: item.krSupervisorRecommendationRating.IsPass === "Y" ? "#18BC00" : "#B7B7BB" }}>
                                        {item.krSelfRecommendationRating.IsPass}</span> &nbsp;&nbsp;&nbsp;&nbsp;<span style={{ color: "#B7B7BB" }}>  |  </span>
                                </>
                                :
                                '-'
                    )
                }

            }
        } catch (error) {
        }
    }
    getCurrencyUnit = (Id) => {
        const { currencyInfo } = this.props
        if (Id !== null) {
            let data = currencyInfo.filter((t) => t.Id === Id);
            if (typeof data !== 'undefined' && data.length > 0)
                return data[0].var_optional1;
        }
        else {
            return ""
        }
    }

    getBenchMarkDataSort(pFromTo, pSortBy) {
        //NOSONAR
        // if (pFromTo === "From") { 
        //     if (pSortBy === "1")
        //         return <><FontAwesomeIcon icon={faArrowAltDown} style={{ color: "#F94F5E" }} /> &nbsp;</>
        //     else
        //         return <><FontAwesomeIcon icon={faArrowAltUp} style={{ color: "#F94F5E" }} />&nbsp;</>
        // } else {
        //     if (pSortBy === "1")
        //         return <><FontAwesomeIcon icon={faArrowAltUp} style={{ color: "#F94F5E" }} />&nbsp;</>
        //     else
        //         return <><FontAwesomeIcon icon={faArrowAltDown} style={{ color: "#F94F5E" }} />&nbsp;</>
        // }
        return <></>
    }
    openAcceptConfirmationPopup = (data) => {
        this.props.openAcceptConfirmationPopup(data);
    }

    getBenchMarkData = (item, ptype) => {//NOSONAR
        switch (item.achievementType) {
            case "1": // date
            case "4": // Numbers
                if (ptype === "From") {
                    return <>{this.getBenchMarkDataSort(ptype, item.sortBy)}{item.krBenchmarkFrom}</>
                } else {
                    return <>{this.getBenchMarkDataSort(ptype, item.sortBy)}{item.krBenchmarkTo}</>
                }
            case "2": // %
                if (ptype === "From") {
                    return <>{this.getBenchMarkDataSort(ptype, item.sortBy)}{item.krBenchmarkFrom + (item.krBenchmarkFrom.includes("=") ? "" : "%")}</>
                } else {
                    return <>{this.getBenchMarkDataSort(ptype, item.sortBy)}{item.krBenchmarkTo + (item.krBenchmarkTo.includes("=") ? "" : "%")}</>
                }
            case "3": {
                if (ptype === "From") {
                    return <>{this.getBenchMarkDataSort(ptype, item.sortBy)}{item.krBenchmarkFrom.includes("=") ? item.krBenchmarkFrom : this.getCurrencyUnit(item.currencyId) + " " + new Intl.NumberFormat('en-IN').format(item.krBenchmarkFrom)}</>
                } else {
                    return <>{this.getBenchMarkDataSort(ptype, item.sortBy)}{item.krBenchmarkTo.includes("=") ? item.krBenchmarkTo : this.getCurrencyUnit(item.currencyId) + " " + new Intl.NumberFormat('en-IN').format(item.krBenchmarkTo)}</>
                }
            }// currency
            default:
        }
    }

    setActualAchievemnt = (item) => {
        switch (item.achievementType) {
            case "1": // date
            case "4": // Numbers
                return <>{item.actualAchievement}</>
            case "2": // %
                return <>{item.actualAchievement + "%"} </>
            case "3": {
                return <>{this.getCurrencyUnit(item.currencyId) + " " + new Intl.NumberFormat('en-IN').format(item.actualAchievement)}</>
            }// currency
            default:
        }
    }
    setFirstActualAchievemnt = (item) => {
        switch (item.achievementType) {
            case "1": // date
            case "4": // Numbers
                return <>{item.krPrimaryActualRating.Achievement}</>
            case "2": // %
                return <>{item.krPrimaryActualRating.Achievement + "%"} </>
            case "3": {
                return <>{this.getCurrencyUnit(item.currencyId) + " " + new Intl.NumberFormat('en-IN').format(item.krPrimaryActualRating.Achievement)}</>
            }// currency
            default:
        }
    }
    setSecondActualAchievemnt = (item) => {
      
        switch (item.achievementType) {
            case "1": // date
            case "4": // Numbers
                return <>{item.krSecondaryActualRating.Achievement}</>
            case "2": // %
                return <>{item.krSecondaryActualRating.Achievement + "%"} </>
            case "3": {
                return <>{this.getCurrencyUnit(item.currencyId) + " " + new Intl.NumberFormat('en-IN').format(item.krSecondaryActualRating.Achievement)}</>
            }// currency
            default:
        }
    }
    firstInputActualHeader = (achievementTypeHeader) => {
        return (
            <th style={{ backgroundColor: "#0CB96312" }} scope="col" colSpan="4"><div className='okr-rating-objective-grid-recommendation-text'>{achievementTypeHeader} Input</div></th>
        )
    }
    firstInputActualSubHeader = () => {
        return (
            <>
                <th className='okr-rating-objective-grid-kr-header-text' scope="col" colSpan={'2'} style={{ textAlign: "center", paddingTop: "15px", backgroundColor: "#0CB96312" }}>{this.props.objectiveData.ratingFlow === 'self-sup' ? "Self" : "Supervisor"}<br /></th>
                <th className='okr-rating-objective-grid-kr-header-text' scope="col" style={{ textAlign: "center", paddingTop: "15px", backgroundColor: "#0CB96312" }}>Key Result<br />Achieved</th>
                <th className='okr-rating-objective-grid-kr-header-text' scope="col" style={{ textAlign: "center", paddingTop: "15px", backgroundColor: "#0CB96312" }}>Rating</th>
            </>
        )
    }
    firstInputActualRows = (item) => {//NOSONAR
        return (
            <>
                <td className="actual-achievement-column-width-ind">
                    {
                        (item.krPrimaryActualRating.Type === "View") &&
                        <div
                            onClick={() => {
                                this.openActualAchivementPopup(item, 'Self', item.krPrimaryActualRating.Type)
                            }}
                            className={item.krSystemRating > 0 ? 'rating-okr-onhover-text rating-grid-text' : ''} /* NOSONAR */>
                            {
                                <span className='rating-okr-onhover-text rating-grid-text word-break'
                                    title={this.props.objectiveData.primaryRatingSubmitted === "Y" ? "View actual achievement" : "Edit actual achievement"}
                                >
                                    {this.setFirstActualAchievemnt(item)}
                                </span>
                            }
                        </div>
                    }
                    {
                        (item.krPrimaryActualRating.Type === "Edit") &&
                        <div
                            onClick={() => {
                                this.openActualAchivementPopup(item, 'Self', item.krPrimaryActualRating.Type)
                            }}
                            title={this.props.objectiveData.primaryRatingSubmitted === "Y" ? "View actual achievement" : "Edit actual achievement"}
                            className={item.krSystemRating > 0 ? 'rating-okr-onhover-text rating-grid-text' : ''} /* NOSONAR */>
                            {
                                <span className='rating-okr-onhover-text rating-grid-text word-break'>
                                    {this.setFirstActualAchievemnt(item)}
                                </span>
                            }
                        </div>
                    }
                    {
                        (item.krPrimaryActualRating.Type === "Add") &&
                        <div
                            onClick={() => {
                                this.openActualAchivementPopup(item, 'Self', item.krPrimaryActualRating.Type)
                            }}
                            title="Add actual achievement - self input"
                            className={item.krSystemRating > 0 ? 'rating-okr-onhover-text rating-grid-text' : ''} /* NOSONAR */>
                            {
                                <span className="ratingIconColor">
                                    <FontAwesomeIcon className="rating-icon-color" icon={faPlus} />
                                </span>
                            }
                        </div>
                    }
                    {
                        (item.krPrimaryActualRating.Type === "") &&
                        <div>
                            <span>
                                -
                            </span>
                        </div>
                    }
                </td>
                <td>
                    <div>
                        {
                            item.krPrimaryActualRating.Type === "View" &&
                            <span className="ratingIconColor"
                                onClick={() => {
                                    this.openActualAchivementPopup(item, 'Self', item.krPrimaryActualRating.Type)
                                }}
                                title={"View actual achievement"}
                            >
                                <FontAwesomeIcon className="rating-icon-color" icon={faEye} />
                            </span>
                        }
                        {
                            item.krPrimaryActualRating.Type === "Edit" &&
                            <span className="ratingIconColor"
                                onClick={() => {
                                    this.openActualAchivementPopup(item, 'Self', item.krPrimaryActualRating.Type)
                                }}
                                title={"Edit actual achievement"}
                            >
                                <FontAwesomeIcon className="rating-icon-color" icon={faPencilAlt} />
                            </span>

                        }
                        {
                            item.krPrimaryActualRating.Type === "Add" && <span className='rating-grid-text'>-</span>
                        }
                    </div>
                </td>
                <td>
                    <div>
                        {
                            item.krAchieved === '' ?
                                "-"
                                :
                                <span className='rating-grid-text'>{item.krPrimaryActualRating.IsPass}</span>
                        }
                    </div>
                </td>
                <td>
                    <div className='rating-okr-onhover-text'
                        onClick={() => { /* NOSONAR */
                            if (item.krPrimaryActualRating.Rating > 0) {
                                this.openActualAchivementPopup(item, 'Self', item.krPrimaryActualRating.Type)
                            }
                        }}>
                        <span title={this.props.objectiveData.primaryRatingSubmitted === "Y" ? "View actual achievement" : "Edit actual achievement"} className='rating-okr-onhover-text rating-grid-text'>{item.krSystemRating}</span>
                    </div>
                </td>
            </>
        )
    }
    secondInputActualHeader = () => {
        return (
            <>
                {/* <th width={300} style={{ backgroundColor: "#0CB96312" }} scope="col" colSpan="1"><div className='okr-rating-objective-grid-recommendation-text'>Self Actual Achievement</div></th> */}
                <th style={{ backgroundColor: "#0CB96312" }} scope="col" colSpan="4"><div className='okr-rating-objective-grid-recommendation-text'>Actual Achievement Input</div></th>
                <th className='rating-objective-rating-width' width={300} style={{ backgroundColor: "#bbf94f12" }} scope="col" colSpan="4"><div className='okr-rating-objective-grid-recommendation-text'>Rating & Sync</div></th>
            </>
        )
    }
    secondInputActualSubHeader = () => {
        return (
            <>
                {/* Actual Achievement */}
                <th style={{ backgroundColor: "#0CB96312" }} scope="col" ><div className='okr-rating-objective-grid-recommendation-text self-supervisor-achievement'>Self</div></th>
                <th style={{ backgroundColor: "#0CB96312" }} scope="col" ><div className='okr-rating-objective-grid-recommendation-text self-supervisor-achievement'>Supervisor</div></th>
                <th style={{ backgroundColor: "#0CB96312" }} scope="col" colSpan='2'><div className='okr-rating-objective-grid-recommendation-text'>Accept OR Edit</div></th>
                {/* <th style={{ backgroundColor: "#0CB96312" }} scope="col" ><div className='okr-rating-objective-grid-recommendation-text'>Action</div></th> */}
                {/* Rating */}
                <th style={{ backgroundColor: "#bbf94f12" }} scope="col" ><div className='okr-rating-objective-grid-recommendation-text'>Self</div></th>
                <th style={{ backgroundColor: "#bbf94f12" }} scope="col" ><div className='okr-rating-objective-grid-recommendation-text'>Supervisor</div></th>
                <th style={{ backgroundColor: "#bbf94f12" }} scope="col" ><div className='okr-rating-objective-grid-recommendation-text'>Sync</div></th>
                <th style={{ backgroundColor: "#bbf94f12" }} scope="col" ><div className='okr-rating-objective-grid-recommendation-text'>History</div></th>
            </>
        )
    }
    secondInputActualRows = (item) => {//NOSONAR
        let krSystemRatingVariable = item.krSystemRating > 0 ? 'rating-okr-onhover-text rating-grid-text' : '';
        let krPrimaryActualRatingNotAccepted = (
            item.krPrimaryActualRating.Achievement !== null ?
                <span className={'rating-okr-onhover-text rating-grid-text word-break'} title={'View Self - Actual Achievement Input & Remark'}>{this.setFirstActualAchievemnt(item)}</span>
                :
                <span>
                    -
                </span>
        )
        let krPrimaryActualRatingItemAccepted = (item.accepted === 'Accept' ? 'rating-okr-onhover-text rating-grid-text word-break acceptColor' : "rating-okr-onhover-text rating-grid-text word-break acceptedColor");
        let krPrimaryActualRatingAccepted = (
            item.krPrimaryActualRating.Achievement !== null ?
                <span className={krPrimaryActualRatingItemAccepted} title={'View Self - Actual Achievement Input & Remark'}>{this.setFirstActualAchievemnt(item)}</span>
                :
                <span >
                    -
                </span>
        )
        let acceptConfirmationPopupTooltip = (item.accepted === 'Accepted' ? "Accepted the team member's self input" : "Accept the team member's self input");
        let acceptConfirmationPopupButtonColor = (item.accepted === 'Accepted' ? "fas fa-user-check rating-icon-color-check-circle" : "rating-icon-color-check-circle-inactive fas fa-user-check");
        let actualAchievementPopupIcon = (
            item.krSecondaryActualRating.Type === 'View' ?
                <FontAwesomeIcon title="View supervisor actual achievement" style={{ cursor: 'pointer' }} className={"rating-icon-color"} icon={faEye} />
                :
                <FontAwesomeIcon title={"Edit Actual Achivement Input"} className="rating-icon-color" icon={faPencilAlt} />
        )
        let krRatingIsPassColor = item.krRating.IsPass === '' ? '#000' : '#f94f5e';

        return (
            <>
                {/* Actual Achievement<br/> (Team member's input) */}
                <td className="benckmark-column-width-ind">
                    {
                        this.props.objectiveData.primaryRatingSubmitted === 'N' && this.props.objectiveData.ratingFlow === 'self-sup' ?
                            <div>
                                {
                                    <span>
                                        -
                                    </span>

                                }
                            </div>
                            :
                            <>
                                {
                                    item.krPrimaryActualRating.Type === 'View' &&
                                    <div
                                        onClick={() => {
                                            this.openActualAchivementPopup(item, 'Self', item.krPrimaryActualRating.Type)
                                        }}
                                        title={'View Self - Actual Achievement Input & Remark'}
                                        className={krSystemRatingVariable}
                                    >
                                        {krPrimaryActualRatingNotAccepted}
                                    </div>
                                }
                                {
                                    item.krPrimaryActualRating.Type === 'Edit' &&
                                    <div
                                        onClick={() => {
                                            this.openActualAchivementPopup(item, 'Self', item.krPrimaryActualRating.Type)
                                        }}
                                        title={'View Self - Actual Achievement Input & Remark'}
                                        className={krSystemRatingVariable}
                                    >
                                        {krPrimaryActualRatingAccepted}
                                    </div>
                                }
                                {
                                    item.krPrimaryActualRating.Type === 'Add' &&
                                    <div
                                        onClick={() => {
                                            this.openActualAchivementPopup(item, 'Self', item.krPrimaryActualRating.Type)
                                        }}
                                        title={"Add Self - Actual Achievement Input & Remark"}
                                        className={krSystemRatingVariable}
                                    >
                                        {
                                            <span className="ratingIconColor">
                                                <FontAwesomeIcon className="rating-icon-color" icon={faPlus} />
                                            </span>
                                        }
                                    </div>
                                }
                                {
                                    item.krPrimaryActualRating.Type === '' &&
                                    <div>
                                        {
                                            <span>
                                                -
                                            </span>
                                        }
                                    </div>
                                }
                            </>
                    }
                </td>
                <td className="benckmark-column-width-ind">
                    {item.krSecondaryActualRating.Type === 'View' &&
                        <div
                            onClick={() => {
                                this.openActualAchivementPopup(item, 'Supervisor', item.krSecondaryActualRating.Type)
                            }}
                            className={item.krSecondaryActualRating.Rating > 0 ? 'rating-okr-onhover-text rating-grid-text' : ''} /* NOSONAR */
                        >
                            {
                                <span className={item.accepted === 'Accept' ? 'rating-okr-onhover-text rating-grid-text word-break acceptColor' : "rating-okr-onhover-text rating-grid-text word-break acceptedColor"} title={"View Supervisor - Actual Achievement Input & Remark"}>{this.setSecondActualAchievemnt(item)}</span>
                            }
                        </div>
                    }
                    {item.krSecondaryActualRating.Type === 'Edit' &&
                        <div
                            onClick={() => {
                                this.openActualAchivementPopup(item, 'Supervisor', item.krSecondaryActualRating.Type)
                            }}
                            className={item.krSecondaryActualRating.Rating > 0 ? 'rating-okr-onhover-text rating-grid-text' : ''} /* NOSONAR */
                        >
                            {
                                <span className={item.accepted === 'Accept' ? 'rating-okr-onhover-text rating-grid-text word-break acceptColor' : "rating-okr-onhover-text rating-grid-text word-break acceptedColor"} title={"Edit/View Supervisor - Actual Achievement Input & Remark"}>{this.setSecondActualAchievemnt(item)}</span>
                            }
                        </div>
                    }
                    {item.krSecondaryActualRating.Type === 'Add' &&
                        <div
                            onClick={() => {
                                if (item.primaryRatingSubmitted === 'Y') {
                                    this.openActualAchivementPopup(item, 'Supervisor', item.krSecondaryActualRating.Type)
                                } else {
                                    getNotify(WARNING, "Please submit the self rating first")
                                }
                            }}
                            className={item.krSecondaryActualRating.Rating > 0 ? 'rating-okr-onhover-text rating-grid-text' : ''} /* NOSONAR */
                            title={"Add Supervisor - Actual Achievement Input & Remark"}
                        >
                            {
                                <span className="ratingIconColor">
                                    <FontAwesomeIcon className="rating-icon-color" icon={faPlus} />
                                </span>
                            }
                        </div>
                    }
                    {item.krSecondaryActualRating.Type === '' &&
                        <div>
                            <span>-</span>
                        </div>
                    }
                </td>
                {/* Actual Achievement<br/> (Supervisor's input) */}
                <td className="benckmark-column-width-ind">
                    <span className='rating-grid-text'>   {
                        item.accepted !== "" ?
                            <span className='' title={acceptConfirmationPopupTooltip} onClick={() => {
                                if (item.accepted !== "Accepted") {
                                    this.openAcceptConfirmationPopup(item)
                                } else {
                                    getNotify(WARNING, "Already Accepted.")
                                }
                            }}>
                                <i style={{ cursor: 'pointer' }} className={acceptConfirmationPopupButtonColor}></i>
                                {/* <FontAwesomeIcon style={{cursor:'pointer'}} className={ item.accepted ==='Accepted' ?"rating-icon-color-check-circle" : ""} icon={faUserCheck} /> */}
                            </span>
                            :
                            "-"
                    }
                    </span>
                </td>
                <td className="benckmark-column-width-ind">
                    {
                        (item.krSecondaryActualRating.Type === 'Add' || item.krSecondaryActualRating.Type === '') ?
                            '-'
                            :
                            <span className='ratingIconColor'
                                onClick={() => {
                                    this.openActualAchivementPopup(item, 'Supervisor', item.krSecondaryActualRating.Type)
                                }
                                }>
                                {actualAchievementPopupIcon}
                            </span>
                    }
                </td>
                {/* Rating */}
                <td className="benckmark-column-width-ind"><span className='rating-grid-text'>{item.krRating.selfRating === "" ? "-" : item.krRating.selfRating}</span></td>
                <td className="benckmark-column-width-ind"><span className='rating-grid-text'>{item.krRating.supervisorRating === "" ? "-" : item.krRating.supervisorRating}</span></td>
                <td className="benckmark-column-width-ind"><span className='rating-grid-text' style={{ color: (item.krRating.IsPass === 'Y') ? '#18bc00' : krRatingIsPassColor }}>{item.krRating.IsPass === "" ? "-" : item.krRating.IsPass}</span></td>
            </>
        )
    }
    render() {//NOSONAR
        const { objectiveName, okrVariableImpact, userObjectiveId, totalInterimFeedback, krData, frequencyNm, achievementTypeHeader,
        } = this.props.objectiveData;
        const { VariableLinked, OKRAllocationStatus, categoryName } = this.props;
        let categoryNameOkrSelfReview = (categoryName === OKR_SELF_REVIEW ? "View and Add self interim feedback" : "View self interim feedback");
        let categoryNameOkrSupervisorReview = (categoryName === OKR_SUPERVISOR_REVIEW ? "View and add supervisor interim feedback" : "View supervisor interim feedback");

        return (
            <div  style={{ margin: 15 }}>
                <table key={`tbl${userObjectiveId}`} className="table table-hover table-bordered" style={{ maxHeight: "200px" }}>
                    <thead>
                        <tr>
                            <th className='ratng-objective-width' style={{ backgroundColor: "#fff" }} scope="col" colSpan="1"><div className="okr-rating-objective-grid-text">{objectiveName}</div></th>
                            <th style={{ backgroundColor: "#005DFF12" }} scope="col" colSpan={VariableLinked === "No" ? 2 : 1}>
                                {VariableLinked === "Yes" &&
                                    <div className='okr-rating-objective-grid-recommendation-text'>Variable <br />Impact {okrVariableImpact === 0 ? " - No" : ` - ${okrVariableImpact}%`}</div>
                                }
                                {
                                    VariableLinked === "No" &&
                                    <div className='okr-rating-objective-grid-recommendation-text rating-objective-frequency-width'>{frequencyNm}</div>
                                }
                            </th>
                            {VariableLinked === "Yes" &&
                                <th style={{ backgroundColor: "#005DFF12" }} scope="col" ><div className='okr-rating-objective-grid-recommendation-text'>{frequencyNm}</div></th>
                            }
                            {
                                OKRAllocationStatus !== 'NA' &&
                                <>
                                    {
                                        this.props.objectiveData.ratingFlow === 'self-sup' && categoryName === OKR_SELF_REVIEW &&
                                        this.firstInputActualHeader(achievementTypeHeader)
                                    }
                                    {
                                        this.props.objectiveData.ratingFlow === 'sup-self' && categoryName === OKR_SELF_REVIEW &&
                                        this.secondInputActualHeader()
                                    }
                                    {
                                        this.props.objectiveData.ratingFlow === 'self-sup' && categoryName === OKR_SUPERVISOR_REVIEW &&
                                        this.secondInputActualHeader()
                                    }
                                    {
                                        this.props.objectiveData.ratingFlow === 'sup-self' && categoryName === OKR_SUPERVISOR_REVIEW &&
                                        this.firstInputActualHeader(achievementTypeHeader)
                                    }

                                    {/* <th style={{ backgroundColor: "#BBF94F12" }} scope="col"><div className='okr-rating-objective-grid-recommendation-text'>{recommendationType}</div></th> */}

                                </>
                            }

                            <th style={{ backgroundColor: "#F94F5E0D" }} scope="col" colSpan="3"><div className='okr-rating-objective-grid-recommendation-text'>Interim Feedback
                                <span style={{ cursor: 'pointer' }} className="rating-okr-onhover-text" onClick={() => this.openKrIntrimFeedbackPopup(krData, userObjectiveId, objectiveName)}
                                    title="View all interim feedbacks" > ({totalInterimFeedback})</span></div></th>
                        </tr>
                    </thead>
                    <tbody key={`tblBody${userObjectiveId}`}>
                        <tr>
                            <th className='okr-rating-objective-grid-kr-header-text ratng-key-reasult-width' scope="col" style={{ textAlign: "left", padding: "0px 0px 0px 10px", backgroundColor: '#F4F4F7' }}> Key Results</th>
                            <th className='okr-rating-objective-grid-kr-header-text benckmark-column-width' scope="col" colSpan="2" style={{ textAlign: "center", paddingTop: "15px", backgroundColor: '#F4F4F7' }}>Benchmark Range</th>
                            {
                                OKRAllocationStatus !== 'NA' &&
                                <>
                                    {
                                        this.props.objectiveData.ratingFlow === 'self-sup' && categoryName === OKR_SELF_REVIEW &&
                                        this.firstInputActualSubHeader()
                                    }
                                    {
                                        this.props.objectiveData.ratingFlow === 'sup-self' && categoryName === OKR_SELF_REVIEW &&
                                        this.secondInputActualSubHeader()
                                    }
                                    {
                                        this.props.objectiveData.ratingFlow === 'self-sup' && categoryName === OKR_SUPERVISOR_REVIEW &&
                                        this.secondInputActualSubHeader()
                                    }
                                    {
                                        this.props.objectiveData.ratingFlow === 'sup-self' && categoryName === OKR_SUPERVISOR_REVIEW &&
                                        this.firstInputActualSubHeader()
                                    }
                                    {/* <th className='okr-rating-objective-grid-kr-header-text' scope="col" style={{ textAlign: "center", paddingTop: "15px", backgroundColor: "#BBF94F12" }}> {ratingTabs === 'self-rating' ? "Self" : "Supervisor"} <br />Rating</th> */}
                                </>
                            }
                            <th className='okr-rating-objective-grid-kr-header-text' scope="col" style={{ textAlign: "center", paddingTop: "15px", backgroundColor: "#F94F5E0D" }}> Self </th>
                            <th className='okr-rating-objective-grid-kr-header-text' scope="col" style={{ textAlign: "center", paddingTop: "15px", backgroundColor: "#F94F5E0D" }}>Supervisor</th>
                            <th className='okr-rating-objective-grid-kr-header-text' scope="col" style={{ textAlign: "center", paddingTop: "15px", backgroundColor: "#F94F5E0D", cursor: 'pointer' }} title={'Third Party'}>TP</th>
                        </tr>
                        {/* <tr>
                            <th className='okr-rating-objective-grid-kr-header-text ratng-key-reasult-width' scope="col" style={{ textAlign: "left", padding: "0px 0px 0px 10px", backgroundColor: '#F4F4F7' }}> </th>
                            <th className='okr-rating-objective-grid-kr-header-text benckmark-column-width' scope="col" colSpan="2" style={{ textAlign: "center", paddingTop: "15px", backgroundColor: '#F4F4F7' }}></th>
                            {
                                OKRAllocationStatus !== 'NA' &&
                                <>
                                    {
                                        this.secondInputActualSupervisorSubHeaderRows()
                                    }
                                </>
                            }
                            <th className='okr-rating-objective-grid-kr-header-text' scope="col" style={{ textAlign: "center", paddingTop: "15px", backgroundColor: "#F4F4F7" }}> </th>
                            <th className='okr-rating-objective-grid-kr-header-text' scope="col" style={{ textAlign: "center", paddingTop: "15px", backgroundColor: "#F4F4F7" }}></th>
                            <th className='okr-rating-objective-grid-kr-header-text' scope="col" style={{ textAlign: "center", paddingTop: "15px", backgroundColor: "#F4F4F7" }}></th>
                        </tr> */}
                        {
                            krData.length > 0 &&
                            krData.map((item) => (
                                <tr style={{ textAlign: "center" }} key={item.ratingId}>
                                    <td style={{ textAlign: "left", paddingLeft: 10 }}>
                                        <div onClick={() => this.opneKrDetailsPopup(item)} className="rating-okr-onhover-text ratng-key-reasult-width rating-grid-text" title="View key results details">{item.krName}</div>
                                    </td>
                                    <td className="benckmark-column-width-ind"><span className='rating-grid-text' >{this.getBenchMarkData(item, "From")}</span></td>
                                    <td className="benckmark-column-width-ind"><span className='rating-grid-text'  >{this.getBenchMarkData(item, "To")}</span></td>
                                    {
                                        OKRAllocationStatus !== 'NA' &&
                                        <>
                                            {
                                                this.props.objectiveData.ratingFlow === 'self-sup' && categoryName === OKR_SELF_REVIEW &&
                                                this.firstInputActualRows(item)
                                            }
                                            {
                                                this.props.objectiveData.ratingFlow === 'sup-self' && categoryName === OKR_SELF_REVIEW &&
                                                this.secondInputActualRows(item)
                                            }
                                            {
                                                this.props.objectiveData.ratingFlow === 'self-sup' && categoryName === OKR_SUPERVISOR_REVIEW &&
                                                this.secondInputActualRows(item)
                                            }
                                            {
                                                this.props.objectiveData.ratingFlow === 'sup-self' && categoryName === OKR_SUPERVISOR_REVIEW &&
                                                this.firstInputActualRows(item)
                                            }
                                            {/* <td>
                                                {this.renderSelfSup(item)}
                                            </td> */}
                                        </>
                                    }
                                    <td>
                                        <div>
                                            {
                                                item.interimSelf === 0 && (item.interimDraftSelf === 0 && this.props.ratingTabs === 'self-rating') ?
                                                    <span onClick={() => {
                                                        this.props.ratingTabs === 'self-rating' ?
                                                            this.openAddEditIntrimFeedbackPopup(item)
                                                            :
                                                            getNotify(WARNING, youCannotAddThirdPartyInterimHereIn)
                                                    }}>
                                                        {
                                                            this.props.ratingTabs === 'supervisor-rating' || this.props.ratingTabs === 'thirdparty-rating' ? /* NOSONAR */
                                                                '-'
                                                                :
                                                                <span className='ratingIconColor'>
                                                                    <FontAwesomeIcon title="Add self interim feedback" className="rating-icon-color" icon={faPlus} />
                                                                </span>
                                                        }
                                                    </span>
                                                    :
                                                    <>
                                                        {
                                                            item.interimSelf !== 0 &&
                                                            <span title={categoryNameOkrSelfReview} className='rating-grid-text rating-okr-onhover-text' onClick={() => this.openViewIntrimFeedbackPopup(item, userObjectiveId, 'self', false)} >
                                                                {item.interimSelf}
                                                            </span>
                                                        }
                                                        {item.interimSelf !== 0 && item.interimDraftSelf !== 0 && this.props.ratingTabs === 'self-rating' &&
                                                            <span className='rating-grid-text-draft'> | </span>
                                                        }
                                                        {
                                                            item.interimDraftSelf !== 0 && this.props.ratingTabs === 'self-rating' &&
                                                            <span title={categoryNameOkrSelfReview} className='rating-grid-text-draft rating-okr-onhover-text' onClick={() => this.openViewIntrimFeedbackPopup(item, userObjectiveId, 'self', true)} >
                                                                {item.interimDraftSelf}
                                                            </span>
                                                        }
                                                    </>
                                            }
                                        </div>
                                    </td>
                                    <td>
                                        <div >
                                            {
                                                item.interimSupervisor === 0 && item.interimDraftSupervisor === 0 ?
                                                    <span style={{ cursor: 'pointer' }}
                                                        onClick={() => {
                                                            this.props.ratingTabs === 'supervisor-rating' ?
                                                                this.openAddEditIntrimFeedbackPopup(item)
                                                                :
                                                                getNotify(WARNING, youCannotAddThirdPartyInterimHereIn)
                                                        }}>
                                                        {
                                                            this.props.ratingTabs === 'self-rating' || this.props.ratingTabs === 'thirdparty-rating' ? /* NOSONAR */
                                                                '-'
                                                                :
                                                                <span title="Add supervisor interim feedback" className='ratingIconColor'>
                                                                    <FontAwesomeIcon className="rating-icon-color" icon={faPlus} />
                                                                </span>
                                                        }

                                                    </span>
                                                    :
                                                    <>
                                                        {
                                                            item.interimSupervisor !== 0 &&
                                                            <span title={categoryNameOkrSupervisorReview} className='rating-grid-text rating-okr-onhover-text' onClick={() => this.openViewIntrimFeedbackPopup(item, userObjectiveId, 'sup', false)}>
                                                                {item.interimSupervisor}
                                                            </span>
                                                        }
                                                        {
                                                            item.interimSupervisor !== 0 && item.interimDraftSupervisor !== 0 && this.props.ratingTabs === 'supervisor-rating' &&
                                                            <span className='rating-grid-text-draft'> | </span>
                                                        }
                                                        {
                                                            item.interimDraftSupervisor !== 0 && this.props.ratingTabs === 'supervisor-rating' &&
                                                            <span title={categoryNameOkrSupervisorReview} className='rating-grid-text-draft rating-okr-onhover-text' onClick={() => this.openViewIntrimFeedbackPopup(item, userObjectiveId, 'sup', true)}>
                                                                {this.props.ratingTabs === 'supervisor-rating' && item.interimDraftSupervisor}
                                                            </span>
                                                        }
                                                    </>
                                            }
                                        </div>
                                    </td>



                                    <td>
                                        <div>
                                            {
                                                item.interimDraftThirdParty === 0 && item.interimThirdParty === 0 ?
                                                    <span className='rating-grid-text rating-okr-onhover-text'

                                                        onClick={() => {
                                                            this.props.ratingTabs === 'thirdparty-rating' ?
                                                                this.openKrIntrimFeedbackPopup(item, userObjectiveId)
                                                                :
                                                                getNotify(WARNING, youCannotAddThirdPartyInterimHereIn)
                                                        }}>
                                                        {
                                                            this.props.ratingTabs === 'self-rating' || this.props.ratingTabs === 'supervisor-rating' ?/* NOSONAR */
                                                                '-'
                                                                :
                                                                <span className='ratingIconColor'>
                                                                    <FontAwesomeIcon title="Add third party interim feedback" className="rating-icon-color" icon={faPlus} />
                                                                </span>
                                                        }
                                                    </span>
                                                    :
                                                    <>
                                                        {
                                                            item.interimThirdParty !== 0 &&
                                                            <span title="View third party interim feedback" className='rating-grid-text rating-okr-onhover-text' onClick={() => this.openViewIntrimFeedbackPopup(item, userObjectiveId, 'tp', false)}>
                                                                {item.interimThirdParty}
                                                            </span>
                                                        }
                                                        {item.interimThirdParty !== 0 && item.interimDraftThirdParty !== 0 && this.props.ratingTabs === 'thirdparty-rating' &&
                                                            <span className='rating-grid-text-draft'> | </span>
                                                        }
                                                        {
                                                            item.interimDraftThirdParty !== 0 && this.props.ratingTabs === 'thirdparty-rating' &&
                                                            <span className='rating-grid-text-draft rating-okr-onhover-text' onClick={() => this.openViewIntrimFeedbackPopup(item, userObjectiveId, 'tp', true)}>
                                                                {this.props.ratingTabs === 'thirdparty-rating' && item.interimDraftThirdParty}
                                                            </span>
                                                        }
                                                    </>
                                            }
                                        </div>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        )
    }
}
