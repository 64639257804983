/* Author : Prashant Waphare
 * Date : 01-05-2020
 * Description: This file use for Absconding HR Preview Grid.
 */
import * as React from "react";
import {
    GridComponent,
    ColumnsDirective,
    ColumnDirective,
    Filter,
    Inject,
    VirtualScroll,
    Sort,
    Page,
} from "@syncfusion/ej2-react-grids";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import "../../../app/containers/tmlc/tmlc.css";
import "../../../../src/components/separation/resignationcomponent/resignationcomponent.css"

import { connect } from "react-redux";
import {
    // getNotify,
    getDisplayDateOnly,
} from "../../../app/other/commonfunction";
import {
    getGridColumn,
    UPLOADED_FILE,
    COMMUNICATED_DATE,
    COMMITTEE_DATE,
} from "../../../app/other/gridcolumns";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core'
import {
    DialogContent,
    DialogTitleTMLC
} from "../../customdialog/customdialog";
import { Dialog } from "@material-ui/core";
import ErrorBoundary from "../../error/errorboundary";
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import CommunicationDocPreview from "./communicationDocPreview";
import { urlConfig } from "../../../config/config";
import separetionServices from "../../../services/entryexit/separationServices";

import { faArrowAltToTop, faEye, faPlayCircle, faLongArrowAltRight, faDownload } from '@fortawesome/pro-light-svg-icons'
library.add(faArrowAltToTop, faEye, faPlayCircle, faLongArrowAltRight);

class CommunicatedPreviewGrid extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: null,
            actionState: true,
            openCommunication: false,
            openCommunicationPreview: false,
            setCommunicationFilePath: false,
        };
        this.dReady = false;
        this.dtTime = false;
        this.isDataBound = false;
        this.isDataChanged = true;
        this.dropSlectedIndex = null;
        this.check = {
            type: "CheckBox",
        };
        this.select = {
            persistSelection: true,
            type: "Multiple",
            checkboxOnly: true,
        };
        this.Filter = {
            type: "Menu",
        };

        this.name = {
            type: "CheckBox",
        };
    }

    componentDidMount() {
        setTimeout(() => {
            const ele = document.getElementsByClassName("e-headercelldiv");
            for(let i in ele) {
                if(!isNaN(parseInt(i, 10))){
                  let index = parseInt(i, 10);
                  if (
                    ele[index].innerText === COMMUNICATED_DATE || ele[index].innerText === UPLOADED_FILE || ele[index].innerText === COMMITTEE_DATE
                ) {
                    ele[index].classList.add("grid-center-header");
                } else {
                    ele[index].classList.add("grid-left-header");
                }
                }
              }
        }, 1000);
    }

    onQueryCellInfoPreJoining = (args) => {
        const { cell, column } = args;
        const { field } = column;
        if (field === 'lastCommunicatedDate' || field === 'uploadedFile') {
            cell.setAttribute(
                "style",
                "font-size:14px !important; text-align: center"
            );
        } else {
            cell.setAttribute(
                "style",
                "font-size:14px !important; text-align: left !important;padding-left: 18px !important;"
            );
        }

    };
    docStatus = (props) => {
        const { fileName } = props;
        return (
            <div>

                <FontAwesomeIcon icon={faEye}
                    style={{ fontSize: "25px", paddingLeft: "5px", paddingTop: '8px' }}
                    onClick={(e) => this.openCommunicationPreview(fileName)}
                />
            </div>
        );
    }
    downloadDoc = (props) => {
        const { fileName } = props;
        const { authUser, type } = this.props;
        return (
            <div>
                <TooltipComponent className="pointer-cursor" content="Download" position="BottomCenter" isSticky={false} cssClass='customtooltip'>
                    <a target="_blank"
                        href={urlConfig.tmlcApiUrl + `document/${type}/` + authUser + '/' + fileName}
                        // rel="download"
                        rel="noopener noreferrer"
                        download
                    >
                        <FontAwesomeIcon icon={faDownload}
                            style={{ fontSize: "25px", paddingLeft: "5px", paddingTop: '8px' }}
                        />
                    </a>
                </TooltipComponent>

            </div>
        );
    }
    openCommunicationPreview = (e) => {
        const { authUser } = this.props;
        try {
            const getExitDocumentFile = Promise.resolve(
                separetionServices.getExitDocumentFile(authUser, "abs", e)
            );
            getExitDocumentFile.then((result) => {
                if (result !== null) {
                    this.setState({ setCommunicationFilePath: result });
                } else {
                    this.data = "error";
                }
            });
        } catch (error) {
            // eslint-disable-next-line
            this.setState({ setCommunicationFilePath: false });
            console.log(error);
        }
        this.setState({ openCommunicationPreview: true });
    }
    openCommunicationPreviewClose = () => {
        this.setState({ openCommunicationPreview: false, setCommunicationFilePath: false });
    }
    onDataBound() {
        clearTimeout(this.clrIntervalFun);
        clearInterval(this.intervalFun);
        this.dtTime = true;
    }

    openCommunication = () => {
        this.setState({ openCommunication: true });
    }

    closeCommunicationDialog = () => {
        this.setState({ openCommunication: false });
    }

    communicateDateFormat = (props) => {
        const { communicateOn } = props;
        return (
            <div style={{ textAlign: "center" }}>
                <span>{getDisplayDateOnly(new Date(communicateOn))}</span>
            </div>
        );
    };
    committeeDateFormat = (props) => {
        const { uplOn } = props;
        return (
            <div style={{ textAlign: "center" }}>
                <span>{getDisplayDateOnly(new Date(uplOn))}</span>
            </div>
        );
    };

    render() {
        const { communicationHrList, committePreviewList } = this.props;
        const { setCommunicationFilePath } = this.state;
        return (
            <div
                className="row absconding-hr-preview-grid-height"
            >
                <div>
                    <div className="row-xs-12 row-sm-12 row-lg-12 row-md-12">
                        <div>
                            {
                                communicationHrList !== undefined &&
                                <GridComponent
                                    className="grid-head-custome"
                                    id="overviewgrid"
                                    dataSource={communicationHrList}
                                    enableHover={false}
                                    // enableVirtualization={false}
                                    rowHeight={30}
                                    height={360}
                                    ref={(g) => {
                                        this.gridInstance = g;
                                    }}
                                    queryCellInfo={this.onQueryCellInfoPreJoining}
                                    dataBound={this.onDataBound}
                                    filterSettings={this.Filter}
                                    allowSorting
                                    allowSelection
                                >
                                    <ColumnsDirective>
                                        {getGridColumn("abscondingHrPreview").map((item) => {
                                            switch (item.colName) {
                                                case UPLOADED_FILE:
                                                    return (
                                                        <ColumnDirective
                                                            key={item.field}
                                                            template={this.downloadDoc}
                                                            textAlign={`${item.align} !important`}
                                                            field={item.field}
                                                            headerText={item.colName}
                                                            width={item.width}
                                                        />
                                                    );
                                                case COMMUNICATED_DATE:
                                                    return (
                                                        <ColumnDirective
                                                            key={item.field}
                                                            template={this.communicateDateFormat}
                                                            textAlign={`${item.align} !important`}
                                                            field={item.field}
                                                            headerText={item.colName}
                                                            width={item.width}
                                                        />
                                                    );
                                                default:
                                                    return (
                                                        <ColumnDirective
                                                            key={item.field}
                                                            textAlign={`${item.align} !important`}
                                                            field={item.field}
                                                            headerText={item.colName}
                                                            width={item.width}
                                                        />
                                                    );
                                            }
                                        })}
                                    </ColumnsDirective>
                                    <Inject services={[Page, Filter, VirtualScroll, Sort]} />
                                </GridComponent>
                            }
                            {
                                committePreviewList !== undefined &&
                                <GridComponent
                                    className="grid-head-custome"
                                    id="overviewgrid"
                                    dataSource={committePreviewList}
                                    enableHover={false}
                                    // enableVirtualization={false}
                                    rowHeight={30}
                                    height={360}
                                    ref={(g) => {
                                        this.gridInstance = g;
                                    }}
                                    queryCellInfo={this.onQueryCellInfoPreJoining}
                                    dataBound={this.onDataBound}
                                    filterSettings={this.Filter}
                                    allowSorting
                                    allowSelection

                                >
                                    <ColumnsDirective>
                                        {getGridColumn("terminationHrPreview").map((item) => {
                                            switch (item.colName) {
                                                case UPLOADED_FILE:
                                                    return (
                                                        <ColumnDirective
                                                            key={item.field}
                                                            template={this.downloadDoc}
                                                            textAlign={`${item.align} !important`}
                                                            field={item.field}
                                                            headerText={item.colName}
                                                            width={item.width}
                                                        />
                                                    );
                                                case COMMITTEE_DATE:
                                                    return (
                                                        <ColumnDirective
                                                            key={item.field}
                                                            template={this.committeeDateFormat}
                                                            textAlign={`${item.align} !important`}
                                                            field={item.field}
                                                            headerText={item.colName}
                                                            width={item.width}
                                                        />
                                                    );
                                                default:
                                                    return (
                                                        <ColumnDirective
                                                            key={item.field}
                                                            textAlign={`${item.align} !important`}
                                                            field={item.field}
                                                            headerText={item.colName}
                                                            width={item.width}
                                                        />
                                                    );
                                            }
                                        })}
                                    </ColumnsDirective>
                                    <Inject services={[Page, Filter, VirtualScroll, Sort]} />
                                </GridComponent>
                            }
                        </div>
                    </div>
                    <div>
                        <Dialog
                            open={this.state.openCommunicationPreview}
                            width="1000px"
                        >
                            <DialogTitleTMLC id="uploading-title">
                                Communicated Preview
                                <div style={{ float: "right" }}>
                                    <IconButton style={{ color: "white", marginRight: "-5px", marginTop: "-7px" }} aria-label="close" onClick={this.openCommunicationPreviewClose}>
                                        <CloseIcon />
                                    </IconButton>
                                </div>
                            </DialogTitleTMLC>
                            <ErrorBoundary>
                                <DialogContent dividers>
                                    {
                                        // setCommunicationFilePath ?
                                        //     <CommunicationDocPreview setCommunicationFilePath={setCommunicationFilePath} />
                                        //     :
                                            <CommunicationDocPreview setCommunicationFilePath={setCommunicationFilePath} />
                                    }
                                </DialogContent>
                            </ErrorBoundary>
                        </Dialog>
                    </div>
                </div>
            </div>
        );
    }
}
const mapStateToProps = ({ auth }) => {
    const { authUser } = auth;
    return { authUser };
};
export default connect(mapStateToProps, {})(CommunicatedPreviewGrid);
