import {
  MEETING_SAVED,
  MEETING_BOOK_UPDATE_STATE,
  MEETING_CREATE,
  MEETING_GET_DETAILS,
  MEETING_SAVE_ERROR,
  EC_REPORT_USERS
} from "../constant/meetingbookingconstant";
import { dencrypt } from "../../../src/app/other/commonfunction";
import { defaultMeetingRoom } from "../../config/config";
const INIT_STATE = {
  meetingId: 0,
  meetingTypeId: 0,
  meetingSubTypeId: "",
  meetingSourceId: "",
  meetingSourceType: "",
  serviceProductsId: [],
  businessImpact: "",
  btbId: "",
  subBTBId: "",
  subCatId: "",
  workTypeId: 0,
  clientId: 0,
  clientNm: "",
  contactsId: [],
  clientmode: "",
  meetingmode: "P",
  meetingFrequency: 1, // 1-->Single,2 -->Recurring
  meetingTitle: "",
  meetingAgenda: "",
  meetingActionItems: "",
  meetingDate: new Date(),
  meetingStartTime: new Date(),
  meetingEndTime: new Date(),
  meetingMediaId: 52,
  timeZoneId:
    typeof dencrypt(localStorage.getItem("def_timezone")) === "undefined"
      ? ""
      : parseInt(dencrypt(localStorage.getItem("def_timezone")), 10),
  meetingWeblinkType: "",
  meetingWebLink: "",
  meetingLocation: "",
  meetingAttendee: [], // ["1_1_0", "2_1_0", "3_1_0", "8_1_0"], // 1st - user_id, 2nd - FullTime/partial/Optional 3rd - Imp,"1_1_0", "2_1_0", "3_1_0", "8_1_0"
  attendeeOnBreak: [],
  chairPersonId: 0,
  recurring: "",
  participateCode: "",
  meetingRoomsId: defaultMeetingRoom,
  otherAttendees: "",
  meetingSetterId: 0,
  meetingPopOpen: false,
  meetingStatus: "",
  meetingEditAccess: "Y",
  isClientMeeting: false,
  meetingDates: [],
  seriesId: 0,
  validate: false,
  isFullDay: false,
  isDigitalMeeting: true,
  countriesId: [],
  meetingCategoryId: "",
  backDatesClientEditAccess: false,
  busyUsers: [],
  meetingTypeName: "",
  selectedDate: new Date(),
  templateId: 0,
  showMeetingPopup: false,
  // agendaDocument: "",
  getExcludeSundayHoliday: 1,
  getNotifyFlag: false,
  workDayStatus: "",
  webLinkLoader: "",
  activityTypeId: "",
  meetingSubTypeCategory: '',
  bulkAttendees: '',
  updateTimeZoneChangesStatus: '',
  bulkAttendeesFlag: false,
  workingHrStartTime: null,
  workingHrEndTime: null,
  ecReportUsersList: [],
  ecReportId: 0,
  campaignName: '',
  campaignId: '',
  zoomMeetingId: null,
  zoomMeetingHostEmailId: null,
  oldZoomMeeting: null,
  zoomOccurrences: null,
  zoomOccurrenceId: null,
  zoomRecurringMeetingDates: null,
  outlookActionType: "",
  saveAsTemplateOnRightClick:'',
  switchView: "month",
  templateListData: [],
  domainName:''
};
// eslint-disable-next-line
export default (state = INIT_STATE, action) => { //NOSONAR
  switch (action.type) {
    case MEETING_SAVED:
    case EC_REPORT_USERS:
    case MEETING_BOOK_UPDATE_STATE: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case MEETING_SAVE_ERROR: {
      return {
        ...state,
        meetingStatus: "error",
      };
    }
    case MEETING_CREATE:
    case MEETING_GET_DETAILS: {
      return {
        ...INIT_STATE,
        ...action.payload,
      };
    }
    default:
      return state;
  }
};
