/**
 * Author: md Jahir Uddin
 * Date: 31-03-2023
 * Description: Use this component when we need to render a label text comb
 */

import React from 'react';
import PropTypes from "prop-types";
import { getFileIcon } from '../okrratingcomponents/ratingcommonfun';
import { decodeString } from '../../app/other/commonfunction';
import { format } from 'date-fns';
import md5 from 'md5';
import { urlConfig } from '../../config/config';

const AttachmentComponent = ({ id, cssClass, style, altImageName, dateTime, fileName, showAttachment = true }) => {

    const getTicketUrlPath = () => {
        const userName = decodeString(localStorage.getItem('helpdeskUserName'));
        const userEmail = decodeString(localStorage.getItem('emailAddress'));
        const haskString = userName + userEmail + process.env.REACT_APP_HD_SHARED_SECRET + format(new Date(), 'ddMM');
        const hash = md5(haskString)
        return `User/AutoLogin?username=${userName}&email=${userEmail}&userHash=${hash}`
    }

    const openAttachment = () => {
        const finalUrl = `${urlConfig.helpDeskUrl}${getTicketUrlPath()}&ReturnUrl=%2FFile%2FGet%2F${id}`
        window.open(finalUrl)
    }
    return (
        <div className="col-xs-12 col-sm-5 col-lg-5 col-md-5 mr-md mt-sm attachment-root">
            <div id={id} className={"helpdesk-history-summary " + cssClass} style={{ ...style, display: showAttachment ? 'flex' : 'none' }} onClick={openAttachment}>
                <div className="image-container">
                    <img alt={altImageName} src={getFileIcon(fileName)} />
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <div className="file-title">{fileName}</div>
                    <div className="file-date">{dateTime}</div>
                </div>
            </div>
        </div>
    )
}

AttachmentComponent.propTypes = {
    id: PropTypes.string,
    cssClass: PropTypes.string,
    style: PropTypes.object,
    image: PropTypes.string,
    altImageName: PropTypes.string,
    dateTime: PropTypes.string,
    fileName: PropTypes.string,
    showAttachment: PropTypes.bool,
    commentId: PropTypes.string
};

AttachmentComponent.defaultProps = {
    id: "", cssClass: "", style: {}, image: "", altImageName: "", dateTime: "", fileName: "", showAttachment: true, commentId: ""
};

export default AttachmentComponent