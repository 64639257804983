import React, { Component } from 'react';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { urlConfig } from '../../config/config';

class Employeenametemplate extends Component {
    constructor(props) {
        super(props);
        this.state = {
        };
        this.navigateToAddBasicInformation = this.navigateToAddBasicInformation.bind(this);
    }
    navigateToAddBasicInformation = (empData) => {
        this.props.navigateToAddBasicInformation(empData)
    }
    addDefaultSrc(ev) {
        ev.target.parentNode.innerHTML = '<span  class="img-circle fas fa-user-circle img-circle-grid-photo" alt="" ></span>'
    }

    render() {
        return (
            <div className='row control-section col-md-12' style={{ flexWrap: 'wrap', display: '-webkit-box' }}>
                <div className="col-xs-2 col-sm-2 col-lg-2 col-md-12">
                    {
                        this.props.props.PhotoNm === '' ?
                            <center><span className="img-circle fas fa-user-circle" alt="" style={{ color: 'gray', fontSize: '40px', width: '75%', height: '100%' }} /></center>
                            :
                            <center><img className="img-circle image-show-in-grid-view"
                                onError={this.addDefaultSrc}
                                src={
                                    urlConfig.tmlcApiUrl + 'document/A/' +// props.authUser + 
                                    '0/' + this.props.props.PhotoNm
                                } alt="" /></center>
                    }
                </div>
                <div className="col-xs-2 col-sm-2 col-lg-8 col-md-12" style={{ paddingLeft: "10px" }} >
                    <span className="span pointer-cursor" onClick={() => this.navigateToAddBasicInformation(this.props.props)}>{this.props.props.CandidateName}</span><br />
                    <TooltipComponent closeOn="Click"
                        position="BottomCenter" isSticky={false}
                        content={this.props.props.Role.replace(/(?:\r\n|\r|\n)/g, '<br>')}
                        cssClass='customtooltip'>
                        <span className="span text-limit" style={{ fontSize: '13px' }} >{this.props.props.Role}</span>
                    </TooltipComponent>

                </div>
            </div>
        );
    }
}
export default Employeenametemplate;