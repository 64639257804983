import React from 'react'
import { connect } from "react-redux";

import HelpdeskService from '../../../components/helpdeservice/helpdeskservice';
function NewIdeaContainer(props) {


return (
    <div style={{height:"100vh"}}>
              <HelpdeskService type="idea"/>
    </div>
  )
}

const mapStateToProps = ({ auth, router }) => {
  const { authUser } = auth;
  const { location } = router;
  return { authUser, location };
};

export default connect(mapStateToProps, {
})(NewIdeaContainer);
