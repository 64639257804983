import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./productiveallocation.css";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from "@material-ui/core/styles";
const CustomMaterialTooltip = withStyles({
    tooltip: {
        color: "#000000",
        backgroundColor: "#F2F2F2",
        font: "normal normal normal 12px/16px Circular TT Book",
        maxWidth: 250,
        letterSpacing: '0px',
        padding: 16
    },
    arrow: {
        color: "#F2F2F2"
    }
})(Tooltip);
class AddNewEmployeeInGrid extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tableHeight: '',
            changeData: true,
            height:
                (window.innerHeight ||
                    document.documentElement.clientHeight ||
                    document.body.clientHeight) - 95,
            empData: []
        };
        this.descriptionTextBox = null;
        this.pageOptions = {
            pageSize: 5,
            pageCount: 5,
        };
        this.remove = this.remove.bind(this);
        this.actionTemplate = this.actionTemplate.bind(this);
    }

    updateDimensions() {
        const updateHeight = window.innerHeight - 150;
        this.setState({ height: updateHeight });
    }
    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
        // setTimeout(() => {
        const backColor = document.getElementsByClassName("e-headercell");
        const ele = document.getElementsByClassName("e-headercelldiv");
        for (let i in backColor) {
            if (!isNaN(parseInt(i, 10))) {
                let index = parseInt(i, 10);
                backColor[index].classList.add("grid-header-with-background-and-text-style");
            }
        }
        for (let i in ele) {
            if (!isNaN(parseInt(i, 10))) {
                let index = parseInt(i, 10);
                ele[index].classList.add("grid-header-Okr");
            }
        }
        const { userList, showHideColumn } = this.props;
        this.pageOptions = {
            pageSize: 3,
            pageCount: 5,
        };
        if (showHideColumn === "hide") {
            this.setEmpData(userList);
        }

    }
    onQueryCellInfoFeedback(args) {
        const { cell } = args;
        cell.setAttribute(
            "style",
            "font-size:14px !important; text-align: left !important; padding-left:8px !important; padding-right:8px !important"
        );
    }
    created = () => {
        setTimeout(() => {
            if (document.getElementById("right-pane")) {
                document.getElementById("right-pane").addEventListener("click", this.onClick.bind(this));
                document.getElementById("right-pane").addEventListener("scroll", this.onScroll.bind(this));
            }
        }, 1000);
    }
    descriptionComment = (props) => {
        return (
            <div>
                <TooltipComponent
                    content={props.discription.replace(/(?:\r\n|\r|\n)/g, '<br>')}
                    position="BottomCenter"
                    created={this.created}
                    isSticky={false} // if use false tooltip auto cancel
                    // isSticky={true} // if use true tooltip we naualt need to cancel it.
                    className="tooltip-box"
                    cssClass='customtooltip'
                >
                    <span className='grid-emp-department'>
                        {props.Department}
                    </span>
                </TooltipComponent>
            </div>
        );
    };
    employeeName = (props) => {
        return (
            <div
                className="row control-section col-md-12"
                style={{ flexWrap: "wrap", display: "-webkit-box", paddingLeft: 10 }}
            >
                <div className="col-xs-2 col-sm-2 col-lg-2 col-md-12">
                    <center>
                        <span
                            className="img-circle fas fa-user-circle image-circle-custom"
                            alt=""
                            style={{
                                color: "gray",
                                fontSize: "40px",
                                width: "75%",
                                height: "100%",
                            }}
                        />
                    </center>
                </div>
                <div
                    className="col-xs-2 col-sm-2 col-lg-8 col-md-12"
                    style={{ paddingLeft: "15px" }}
                >
                    <span className="span grid-emp-name " >
                        {props.name}
                    </span>
                    <br />
                    <CustomMaterialTooltip
                        title={props.JobTitle}
                        placement="bottom-start"
                        arrow
                    >
                        <span className="span grid-designation">
                            {props.JobTitle}
                        </span>
                    </CustomMaterialTooltip>
                </div>
            </div>
        );
    };
    remove = (e, data) => {
        this.props.removeDataFromGrid(data);
    }
    actionTemplate(props) {
        return (
            <div
                className="col-12"
                style={{
                    padding: "0px 20px 0px 0px",
                }}
            >
                <div className="row">
                    <div className="col-lg-12" style={{ textAlign: "center", padding: "0px 0px 0px 16px" }}>
                        <FontAwesomeIcon
                            onClick={(e) => this.remove(e, props)}
                            style={{ fontSize: 20, cursor: "pointer", color: "#9e9e9e" }}
                            icon={faTimes}
                        />
                    </div>
                </div>
            </div>
        );
    }
    setEmpData(userList) {
        const { othersCheckAvailablity } = this.props;
        var empData = [];
        if (userList !== null) {
            //eslint-disable-next-line
            userList.forEach((item) => {
                let data = othersCheckAvailablity.filter((t) => t.UserId === item)
                if (data.length > 0)
                    empData.push(data[0]);
            });
            this.setState({ empData: empData });
        }
    }
    render() {

        const { userSelectedData, showHideColumn } = this.props;
        const { empData } = this.state;
        return (
            <>
                <div id='EmployeeTable' >
                    <div
                        style={{ padding: "10px 14px 0px 0px" }}
                    >

                        <div className='col-lg-12 col-md-12 col-sm-12' style={{ padding: 0 }}>
                            {/* height > 300 ? "table table-hover scrollbar":"table table-hover" */}
                            <table className={userSelectedData.length >= 5 ? "table table-hover scroll" : "table table-hover"}

                            >
                                <thead
                                    style={{
                                        position: "sticky",
                                        top: "0",
                                        zIndex: 1,
                                        marginTop: 0
                                        // borderTop:0,


                                    }}>

                                    <tr style={{ margin: 0, border: 'none' }} >
                                        <th className={'benchTableHeader headerWidth'} >Employee Name</th>
                                        <th className={'benchTableHeader headerWidth2'} >Department</th>
                                        <th className={'benchTableHeader headerWidth3'} ></th>
                                    </tr>

                                </thead>
                                <tbody>
                                    {showHideColumn === 'show' ? userSelectedData.map((item, ind) => {
                                        return (

                                            <tr key={ind}>
                                                <td>
                                                    <div className="col-xs-2 col-sm-2 col-lg-2 col-md-12">
                                                        <center>
                                                            <span
                                                                className="img-circle fas fa-user-circle image-circle-custom"
                                                                alt=""
                                                                style={{
                                                                    color: "gray",
                                                                    fontSize: "40px",
                                                                    width: "75%",
                                                                    height: "100%",
                                                                }}
                                                            />
                                                        </center>
                                                    </div>

                                                    <div
                                                        className="col-xs-2 col-sm-2 col-lg-8 col-md-12"
                                                        style={{ paddingLeft: "15px" }}
                                                    >
                                                        <span className="span grid-emp-name " >
                                                            {item.name}
                                                        </span>
                                                        <br />


                                                        <span className="span grid-designation">
                                                            {item.JobTitle}
                                                        </span>

                                                    </div>
                                                </td>
                                                <td>
                                                    <span className="span grid-emp-name">
                                                        {item.Department}
                                                    </span>
                                                </td>
                                                {/* <td style={{color: "#939399", fontSize: "14px"}} >{item.name}<br/>{item.JobTitle}</td> */}
                                                {/* <td style={{color: "#939399", fontSize: "14px"}}>{item.Department}</td> */}
                                                <td><div
                                                    className="col-12"
                                                    style={{
                                                        padding: "0px 20px 0px 0px",
                                                    }}
                                                >
                                                    <div className="row">
                                                        <div className="col-lg-12" style={{ textAlign: "center", padding: "0px 0px 0px 16px" }}>
                                                            <FontAwesomeIcon
                                                                onClick={(e) => this.remove(e, item)}
                                                                style={{ fontSize: 20, cursor: "pointer", color: "#9e9e9e" }}
                                                                icon={faTimes}
                                                            />
                                                        </div>
                                                    </div>
                                                </div></td>
                                            </tr>
                                        )


                                    })
                                        :
                                        (typeof empData !== 'undefined' &&
                                            empData.map((item, ind) => {
                                                return (
                                                    <tr key={ind}>
                                                        <td>
                                                            <div className="col-xs-2 col-sm-2 col-lg-2 col-md-12">
                                                                <center>
                                                                    <span
                                                                        className="img-circle fas fa-user-circle image-circle-custom"
                                                                        alt=""
                                                                        style={{
                                                                            color: "gray",
                                                                            fontSize: "40px",
                                                                            width: "75%",
                                                                            height: "100%",
                                                                        }}
                                                                    />
                                                                </center>
                                                            </div>

                                                            <div
                                                                className="col-xs-2 col-sm-2 col-lg-8 col-md-12"
                                                                style={{ paddingLeft: "15px" }}
                                                            >
                                                                <span className="span grid-emp-name " >
                                                                    {item.name}
                                                                </span>
                                                                <br />


                                                                <span className="span grid-designation">
                                                                    {item.JobTitle}
                                                                </span>

                                                            </div>
                                                        </td>
                                                        <td>
                                                            <span className="span grid-emp-name">
                                                                {item.Department}
                                                            </span>
                                                        </td>
                                                        {/* <td style={{color: "#939399", fontSize: "14px"}} >{item.name}<br/>{item.JobTitle}</td> */}
                                                        {/* <td style={{color: "#939399", fontSize: "14px"}}>{item.Department}</td> */}
                                                        <td><div
                                                            className="col-12"
                                                            style={{
                                                                padding: "0px 20px 0px 0px",
                                                            }}
                                                        >
                                                            <div className="row">
                                                                <div className="col-lg-12" style={{ textAlign: "center", padding: "0px 0px 0px 16px" }}>
                                                                    <FontAwesomeIcon
                                                                        onClick={(e) => this.remove(e, item)}
                                                                        style={{ fontSize: 20, cursor: "pointer", color: "#9e9e9e" }}
                                                                        icon={faTimes}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div></td>
                                                    </tr>

                                                )
                                            }
                                            ))
                                    }



                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
AddNewEmployeeInGrid.propTypes = {
    authUser: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    showHideColumn: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    removeDataFromGrid: PropTypes.func.isRequired,
    userList: PropTypes.array,
};
AddNewEmployeeInGrid.defaultProps = {
    authUser: "",
    showHideColumn: "",
    userList: [],
};
const mapStateToProps = ({ auth, meetingmaster }) => {
    const { authUser } = auth;
    const { othersCheckAvailablity } = meetingmaster;
    return {
        authUser,
        othersCheckAvailablity
    };
};

export default connect(mapStateToProps, {})(AddNewEmployeeInGrid);
