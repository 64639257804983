/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect, useRef, useLayoutEffect } from 'react'
import { connect } from 'react-redux';
import { DropDownButtonComponent } from '@syncfusion/ej2-react-splitbuttons';
import LnAService from '../../../../services/lna/lnaservice';
import { updateLnAData } from '../../../../redux/actions/lna/lnaaction';
import { leaveDateFormat } from '../../../../app/other/commonfunction';
import ProgressBar from '../../../progressbar/progressbar';
import ErrorTabMessage from '../../../errormessage/errormessage';
import { SPECIAL_ATTENDANCE, APPLYLEAVE} from '../../../../redux/constant/meetingcategoryconstant'
import { pleaseTypeSearchText } from '../../../../assets/constants/constants';
import LnAServices from "../../../../services/lna/lnaservice";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Page,
  Filter,
  Sort
} from "@syncfusion/ej2-react-grids";
import { DateFilterformat } from '../../../../config/config';
import { getYearData } from '../../../../app/other/commonlayoutfunction';
const SAMPLECSS = `

#LnADetailsGridDiv .e-grid .e-row.below-30  .e-rowcell{
  color: #9f9f9f;
}

#LnADetailsGrid .e-grid .e-filtermenudiv{    
  margin-top: 12px !important;
}
#LnADetailsGrid tr[aria-selected="true"] td[aria-selected="true"] {
  background-color: #fff !important;
}

#LnADetailsGridDiv .e-grid .e-gridheader tr th
{
  padding:0px !important;
}
#LnADetailsGridDiv .e-grid .e-gridheader tr th[data-colindex="0"]
{
  padding-bottom: 13px !important;
}
#LnADetailsGridDiv .e-grid .e-gridheader .e-sortfilter .e-headercelldiv{
  padding:0px !important;
}
#LnADetailsGrid .e-headercell .e-headercelldiv {
  display: flex;
  align-items: center; /* Vertically center the content */
  justify-content: space-between; /* Create space between header text and filter */
  padding: 0 10px; /* Add padding to both left and right sides */
}

#LnADetailsGrid .e-filterbar .e-input-group input[type="text"] {
  margin-left: 5px; /* Add some spacing between header text and filter */
}

#LnADetailsGrid .e-headertext {
  width: 100px !important;
}
 #LnADetailsGrid .e-grid .e-headercelldiv {
  line-height: 20px !important;
 }
.e-grid.e-wrap .e-columnheader .e-filtermenudiv {
  line-height: 0px !important;
}

.leave-application-body .e-grid {
  border-width: 0px !important;
}
.e-gridcontent .e-content{
  overflow-y: scroll !important; 
}
.e-grid .e-filtermenudiv::before {
  position:absolute !important;
  top:21px !important ;
  left :82% !important ; 
  padding:0px !important;
}
#LnADetailsGridDiv .e-grid .e-dialog.e-checkboxfilter, .e-grid .e-dialog.e-flmenu{
  top: 25px !important;
 }
 #LnADetailsGrid .e-gridheader{
   padding-right:16px !important;
 }
`
const LeavesDetails = (props) => {
  const { authUser, fyId, user, type, categoryName, applyFor } = props;
  const getMenuData = (types, enableCancelButton, detailedStatus) => {
    let enableEditButton = (detailedStatus === 'Pending' || detailedStatus === '1st Level Approval - Pending') ? '1' : '0';
    let appliedOne = [
      { text: 'View', iconCss: 'e-icons e-page-one' },
      { text: 'Edit', iconCss: 'e-icons e-edit' },
      { text: 'Cancel', iconCss: 'e-icons e-cancel-2' }
    ];
    let appliedTwo = [
      { text: 'View', iconCss: 'e-icons e-page-one' },
      { text: 'Edit', iconCss: 'e-icons e-edit' }
    ];
    let approvedOne = [
      { text: 'View', iconCss: 'e-icons e-page-one' },
      { text: 'Cancel', iconCss: 'e-icons e-cancel-2' }
    ];
    let approvedTwo = [
      { text: 'View', iconCss: 'e-icons e-page-one' }
    ];
    switch (types) {
      case 'Applied':
        if (enableEditButton === '1' && enableCancelButton === '1') {
          return appliedOne;
        } else if (enableEditButton === '1') {
          return appliedTwo;
        } else {
          return enableCancelButton !== '0' ? approvedOne : approvedTwo;
        }
      case 'Approved':
        return approvedOne
      case 'Utilized':
        return enableCancelButton !== '0' ? approvedOne : approvedTwo

      case 'Rejected':
        return approvedTwo
      case 'lockUnlock':
        return approvedTwo
      default:
        return approvedTwo



    }
  };
  const [leaveStatusData, setLeaveStatusData] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const gridRef = useRef(null);
  const [lockUnlockData, setLockUnlockData] = React.useState([])
  const [allHeight, setAllHeight] = React.useState({
    containerHeight: null
  });
  useEffect(() => {
    if ((props.leaveBalanceStatus === 'updated' || props.compOffClusterFlag === 'updated' || props.compOffClusterFlag === 'saved'
      || props.leaveTransferFlag === 'updated'
      || props.updateFinancialYear === "updated"
      || (type === "LeaveApply" && user > 0)
      || categoryName === SPECIAL_ATTENDANCE || categoryName === APPLYLEAVE) && type !== "approvalsubmit") {
      setIsLoading(true)
      getLeaveStatusData();
      props.updateLnAData({ leaveBalanceStatus: "", compOffClusterFlag: '', leaveTransferFlag: '' })
      if (props.updateFinancialYear === "updated") {
        props.updateLnAData({ updateFinancialYear: "" })
      }
    }
  }, [props.leaveBalanceStatus, props.compOffClusterFlag, props.compOffClusterFlag, props.leaveTransferFlag, categoryName, props.updateFinancialYear, user, props.applyFor])

  useLayoutEffect(() => {
    fetchLockUnlockData();
  }, []);


  useEffect(() => {
    if (!fyId) {
      getYearData(authUser)
        .then(result => {
          props.updateLnAData({ fyId: result })
        })
        .catch(err => {
          console.log('err===', err)
        });
    }
  }, [])

  const fetchLockUnlockData = async () => {
    const lockUnlockClusterData = await LnAServices.getLockUnlockClusterData(authUser, '0');

    if (lockUnlockClusterData?.Result?.length > 0) {
      setLockUnlockData(lockUnlockClusterData.Result);
    }
  }

  const getType = (types) => {
    if (types === 'COMPOFF') {
      return 'COMPOFF'
    } else if (types === 'LEAVETRANSFER') {
      return 'LEAVETRANSFER'
    } else if (pleaseTypeSearchText !== "approvalsubmit") {
      return 'LEAVESTATUS'
    }
  }

  const filterLeaveData = (result) => {
    if (!result) return null;
    return result?.map(leaveData => {
      if (leaveData.fromDate) {
        leaveData.fromDate = new Date(leaveData.fromDate)
      }
      if (leaveData.toDate) {
        leaveData.toDate = new Date(leaveData.toDate)
      }
      return leaveData
    })

  }
  const getLeaveStatusData = async () => {
    if (fyId) {
      const onLeaveStatusData = await LnAService.getLeaveBalanceData(user ? user : (applyFor ? applyFor : authUser), getType(props.type), fyId, 0, categoryName === SPECIAL_ATTENDANCE ? 2 : 1)
      const leaveStatusDataList = onLeaveStatusData.Result
      setLeaveStatusData(filterLeaveData(leaveStatusDataList))
      setIsLoading(false)
    }
  }


  const onSelect = (args, data) => {
    try {
      if (args.item.text === 'View') {
        props.updateLnAData({
          leaveApplyLoadName: 'leave-view',
          leaveData: data,
          leaveApplicationId: data.leaveId,
          leaveType: '',
          leaveStartDate: null,
          leaveEndDate: null,
          leaveReason: '',
          appliedDays: '',
          leaveClusterId: '',
          approverId: 0,
          attachmentName: '',
          from: 'ViewGrid',
        })
      } else if (args.item.text === 'Edit') {
        props.updateLnAData({
          leaveApplyLoadName: 'leave-edit',
          leaveType: data.leaveClusterUid,
          leaveStartDate: leaveDateFormat(data.fromDate),
          leaveEndDate: leaveDateFormat(data.toDate),
          leaveReason: data.reason,
          notificationTo: data.notifyIds,
          reliever: data.relieverId,
          appliedDays: data.noOfDays,
          leaveApplicationId: data.leaveId,
          dayBreakText: data.dayBreak,
          applyFor: data.applyFor,
          attachmentName: data.attachmentName,
          leaveStartDateEdit: data.fromDate,
          leaveEndDateEdit: data.toDate,
          dayBreakBackgroundColor: data.leaveColor,
        })

      } else if (args.item.text === 'Cancel') {
        const { leaveId } = data
        props.updateLnAData({
          leaveApplyLoadName: 'leave-cancel',
          leaveData: data,
          leaveApplicationId: leaveId
        })
      }
    } catch (error) {
      console.log("🚀 ~ file: leavesbalance.jsx:147 ~ onSelect ~ error", error)
    }
  }
  const lnaDetailsGridRefresh = () => {
    setTimeout(() => {
      try {
        const val = document.getElementById("LnADetailsGrid");
        val && val.ej2_instances[0].refresh();
      } catch (err) {
        console.log(err);
      }
    }, 500);

  }

  useEffect(() => {
    lnaDetailsGridRefresh();
    window.addEventListener("resize", updateDimensions());// NOSONAR
  }, []);

  const updateDimensions = () => {
    const containerHeight = window.innerHeight - 303
    setAllHeight(prevState => ({
      ...prevState,
      containerHeight: containerHeight
    }))
  }

  const actionTemplate = (balance) => {
    const currentDate = new Date();
    const currentDateTimeStamp = currentDate.getTime();
    const currentMonth = currentDate.getMonth() + 1; // Adding 1 because months are zero-indexed
    const currentYear = currentDate.getFullYear();
    let hideCancel = false;
    if (lockUnlockData && lockUnlockData.length > 0) {
      lockUnlockData.forEach((res) => {
        const responseDate = new Date(res.attendanceMonth);
        const responseMonth = responseDate.getMonth() + 1;
        const responseYear = responseDate.getFullYear();

        if ((currentYear === responseYear) && (currentMonth === responseMonth) && (res.activeStatus === '1')) {
          const lockEnd = new Date(res?.lockEndDate);
          const lockEndPlusOne = new Date(lockEnd);
          lockEndPlusOne.setDate(lockEndPlusOne.getDate() + 1);

          const lockEndPlusOneTimeStamp = lockEndPlusOne.getTime();
          hideCancel = currentDateTimeStamp > lockEndPlusOneTimeStamp;
        }
      })
    }
    if (hideCancel) {
      return (
        <DropDownButtonComponent
          items={getMenuData('lockUnlock', '0')}
          select={(e) => onSelect(e, balance)}
          iconCss='e-icons e-more-vertical-2 '
          cssClass='e-caret-hide e-custom e-vertical'
          iconPosition='Left'
        ></DropDownButtonComponent>
      );
    } else {
      return (
        <DropDownButtonComponent
          items={getMenuData(balance?.applicationStatus, balance?.enableCancelButton, balance?.detailedStatus)}
          select={(e) => onSelect(e, balance)}
          iconCss='e-icons e-more-vertical-2 '
          cssClass='e-caret-hide e-custom e-vertical'
          iconPosition='Left'
        ></DropDownButtonComponent>
      );
    }
  }


  const dataSourceChanged = (pComponentId) => {
    try {
      setTimeout(() => {
        if (document.getElementById(pComponentId) !== null) { document.getElementById(pComponentId).ej2_instances[0].refresh(); }
      });
    } catch (error) { console.log(error); }
  };
  const dataBound = () => {
    setIsLoading(false);
  };

  const onDataBinding = (pComponentId) => {
    // spinner(true)
    setIsLoading(true);
    try {
      setTimeout(() => {
        document.getElementById(pComponentId).ej2_instances[0].refresh();
      });
    } catch (error) {
      console.log(error);
    }
  };

  const onFirstGridCreated = () => {
    lnaDetailsGridRefresh();
  };
  const rowDataBound = (args) => {
    if (args.row) {
      try {
        if ((new Date(args.data.fromDate).toISOString().slice(0, 10) < new Date(new Date().toDateString()).toISOString().slice(0, 10)) || args.data.applicationStatus === "Cancelled" || args.data.applicationStatus === "Utilized") {
          args.row.classList.add('below-30');
        }
      } catch (err) {
        console.log(err);
      }
    }
  }

  const statusTemplate = (prop) => {
    if (prop.applicationStatus === "Utilized")
      return <div>
        <p>{prop.applicationStatus}</p>
      </div>
    return (
      <div>
        <div className={`leave-approval-${prop.finalStatus}`}>
          {prop.detailedStatus}
        </div>
      </div>
    );
  }

  return (
    <div id="LnADetailsGridDiv">
      <style>{SAMPLECSS}</style>
      {(!isLoading) && leaveStatusData.length > 0 &&
        <GridComponent
          id="LnADetailsGrid"
          ref={gridRef}
          dataSource={leaveStatusData}
          gridLines={'Both'}
          rowHeight={40}
          rowDataBound={rowDataBound}
          height={allHeight.containerHeight}
          dataSourceChanged={() => dataSourceChanged("LnADetailsGrid")}
          dataBound={dataBound}
          created={onFirstGridCreated}
          onDataBinding={() => onDataBinding("LnADetailsGrid")}
          allowFiltering={true}
          allowSorting={true}
          allowTextWrap={true}
          filterSettings={{
            type: 'CheckBox'
          }}
        >
          <ColumnsDirective>
            <ColumnDirective
              textAlign="Center"
              template={actionTemplate}
              field=""
              headerText="Action"
              width="60"
              allowFiltering={false}
            />
            {/* 30 */}
            <ColumnDirective field='leaveSymbol' width='70' headerText='Leave      Type' textAlign="Center" />
            {/* 35 */}
            <ColumnDirective field='fromDate' width='95' textAlign="Center" headerText='From Date' format={DateFilterformat} filter={{
              type: 'Menu'
            }} />
            {/* 65 */}
            <ColumnDirective field='toDate' width='80' textAlign="Center" headerText='To Date' format={DateFilterformat} filter={{
              type: 'Menu'
            }} />
            {/* 50 */}
            <ColumnDirective field='noOfLeaves' width='60' textAlign="Center" headerText='No. of Leaves' filter={{
              type: 'CheckBox'
            }} />
            {/* 40 */}
            <ColumnDirective field='approvalType' width='85' textAlign="Center" headerText={`Approval    Type`} filter={{
              type: 'CheckBox' 
            }} />
            {/* 55 */}
            <ColumnDirective template={statusTemplate} field='applicationStatus' width='90' textAlign="Center" headerText='Status' filter={{
              type: 'CheckBox'
            }} />
            {/* 70 */}
          </ColumnsDirective>

          <Inject services={[Page, Filter, Sort]} />
        </GridComponent>

      }

      {isLoading && (
        <div
          style={{
            zIndex: 999999,
            position: "absolute",
            marginTop: "20%",
            marginLeft: "49%",
          }}
        >
          <ProgressBar />
        </div>
      )}
      {(leaveStatusData.length === 0 && !isLoading && props.type !== 'approvalsubmit') && (
        <ErrorTabMessage message="No Data Available" />
      )}

    </div>
  )
}

LeavesDetails.propTypes = {
  //   second: PropTypes.third
}

const mapStateToProps = ({ meetingcategory, auth, meetingmaster, lnareducer }) => {
  const { authUser } = auth;
  const { categoryName } = meetingcategory;
  const { leaveApplyStatus, leaveBalanceStatus, compOffClusterFlag, leaveTransferFlag, updateFinancialYear, fyId, applyFor } = lnareducer;
  const { currentFinancialYearId } = meetingmaster;
  return { authUser, currentFinancialYearId, leaveApplyStatus, leaveBalanceStatus, categoryName, compOffClusterFlag, leaveTransferFlag, updateFinancialYear, fyId, applyFor }
}
export default connect(mapStateToProps, { updateLnAData })(LeavesDetails)
