import React, { Component } from 'react'
import { connect } from "react-redux";
import {
    OKR_COST_SHARING, OKR_PRODUCTIVE_ALLOCATION, OKR_OBJECTIVE_KPI
} from "../../../redux/constant/meetingcategoryconstant";
import OKRServices from '../../../services/okr/okrservices';
import { setCentralOkrKPIRedux } from '../../../redux/actions/okr/okractions';
import CostSharing from '../../containers/okr/costsharing/costsharing.jsx';
import OKRObjectiveKPI from '../../containers/okr/okrobjectivekpi/okrobjectivekpi';
import ProductivityAllocation from '../../containers/okr/productiveallocation/productivityallocation';
import queryString from "query-string";
import { dencrypt } from '../../other/commonfunction'
import './default.min.css';
import { getTMLCUsers } from '../../other/commonlayoutfunction.js';
import { setMeetingMaster } from '../../../redux/actions/meetingmasteraction';
class OKRLayout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            height: 182,
            isloaded: false,
            showLibrary: false,
            moduleId: 0
        }
    }
    async componentDidMount() {
        this.setModuleId()
        this.updateDimensions();
        this.getMasterList();
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }

    componentDidUpdate(newProps) {
        try {
            this.setModuleId()
        } catch (error) {
        }
    }

    setModuleId() {
        try {
            const { moduleId } = this.state
            const params = queryString.parse(this.props.location.search);
            if (typeof params.goto !== 'undefined' && params.goto !== null) {
                if (params.goto === "library") {
                    this.setState({ showLibrary: true })
                }
            }
            // Set App Id                        
            if (typeof params.id !== 'undefined' && params.id !== null) {
                if (moduleId !== dencrypt(params.id))
                    this.setState({ moduleId: dencrypt(params.id) })
            }
        } catch (error) {

        }
    }

    componentWillReceiveProps(nextProps) {

        const params = queryString.parse(this.props.location.search);
        if (typeof params.goto === 'undefined') {
            this.setState({ showLibrary: false })
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions.bind(this));

    }

    /* Calculate & Update state of new dimensions */
    updateDimensions() {
        // let update_width = window.innerWidth - 10; width: update_width,
        let update_height = window.innerHeight - 100;
        this.setState({ height: update_height });
    }

    getMasterList = async () => {
        const { authUser, OkrMasters, othersCheckAvailablity } = this.props;
        try {
            if (OkrMasters.length === 0) {
                const getMasterData = Promise.resolve(OKRServices.getOKRMaster(authUser));
                getMasterData.then((resultData) => {
                    if (resultData !== null && resultData.Result !== false) {
                        this.props.setCentralOkrKPIRedux({ OkrMasters: resultData.Result })
                    } else {
                        this.data = "error";
                    }
                    setTimeout(() => {
                        this.setState({ isloaded: true })
                    }, 200);
                });
            } else {
                this.setState({ isloaded: true })
            }


            if (othersCheckAvailablity.length === 0) {
                const othersCheckAvailablityData = await getTMLCUsers(authUser)
                this.props.setMeetingMaster(othersCheckAvailablityData);
            }
        } catch (e) {
            console.log(e);
        }
    }


    render() {
        const { categoryName } = this.props
        const { isloaded, showLibrary, moduleId } = this.state
        let activeModule = (moduleId === 0 ? categoryName : moduleId).toString()
        return (
            isloaded &&
            <div>
                {

                    showLibrary ? <div><OKRObjectiveKPI showLibrary={showLibrary} /></div> :

                        <div>
                            {
                                activeModule === OKR_COST_SHARING && <CostSharing />
                            }
                            {
                                activeModule === OKR_PRODUCTIVE_ALLOCATION && <ProductivityAllocation />
                            }
                            {
                                activeModule === OKR_OBJECTIVE_KPI && <OKRObjectiveKPI showLibrary={showLibrary} />
                            }
                        </div>
                }

            </div>
        )
    }
}

const mapStateToProps = ({ meetingcategory, auth, okrreducer, meetingmaster }) => {
    const { authUser } = auth;
    const { categoryName } = meetingcategory;
    const { OkrMasters } = okrreducer;
    const { othersCheckAvailablity } = meetingmaster;
    return { categoryName, authUser, OkrMasters, othersCheckAvailablity }
};
export default connect(mapStateToProps, { setCentralOkrKPIRedux, setMeetingMaster })(OKRLayout);
