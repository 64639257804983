import {
  SET_TIB_MULTIPLE_USERS_ID,
  TIB_USER_LIST,
  TIB_MONTHS,
  TIB_GRAY_LEAVES,
  SET_TIB_MULTIPLE_MONTHS_IDS, ADD_UPDATE_TIB_DATA,
  UTILIZATION_OF_TIME_REPORT_UPDATE
} from "../constant/tibconstant";

export const setTIBMultipleUsersId = (data) => {
  return {
    type: SET_TIB_MULTIPLE_USERS_ID,
    payload: data,
  };
};

export const setTIBMultipleMonthsIds = (data) => {
  return {
    type: SET_TIB_MULTIPLE_MONTHS_IDS,
    payload: data,
  };
};
export const setTIBUserList = (data) => {
  return {
    type: TIB_USER_LIST,
    payload: data,
  };
};
export const setMonths = (data) => {
  return {
    type: TIB_MONTHS,
    payload: data,
  };
};
export const setGrayLeaves = (data) => {
  return {
    type: TIB_GRAY_LEAVES,
    payload: data,
  };
};
export const addUpdateTibData = (data) => {
  return {
    type: ADD_UPDATE_TIB_DATA,
    payload: data,
  };
};
export const utilizationReportUpdate = (data) => {
  return {
    type: UTILIZATION_OF_TIME_REPORT_UPDATE,
    payload: data,
  };
}