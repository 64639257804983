import React, { Component } from 'react'
import LPImage from '../../../assets/images/LP.png'

export default class LP extends Component {
    render() {
        return (
            <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
                <div className='row'>
                    <div className="col-lg-12 col-md-12" style={{ overflowY: 'auto' }}>
                        <center>
                            <img src={LPImage} style={{ objectFit: 'contain', padding: 10, overflowY: 'auto' }} alt="Leadership Principles" />
                        </center>
                    </div>
                </div>
            </div>
        )
    }
}
