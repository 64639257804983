/* Author : Prashant Waphare
 * Date : 02-06-2020
 * Description: This file use for show Termination employee List. (Termination HR conainar)
 */
import React, { Component } from "react";
import NotificationContainer from "../../../../../components/notification/notifycontainer";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import TerminationHrGrid from '../../../../../components/separation/terminationcomponents/terminationhrgrid';
import separetionServices from "../../../../../services/entryexit/separationServices";
import Progressbar from '../../../../../components/progressbar/progressbar';
import { addUpdateSeparationDetails } from "../../../../../redux/actions/entryexit/separationaction";
import { getFormatDateForGrid } from "../../../../other/commonfunction";

class TerminationHr extends Component {
  constructor(props) {
    super(props);
    this.state = {
       
      terminationList: [],
      terminationCommitteeAction: [],
    }
    this.onCreated = this.onCreated.bind(this);
  }
  componentDidMount() {    
    this.getTerminationList();
    this.getTerminationCommitteeAction();
  }
  UNSAFE_componentWillReceiveProps(newProps) {
    if (newProps.UpdateResignationStatus === "StatusUpdate") {
      this.getTerminationList();
        this.props.addUpdateSeparationDetails({ UpdateResignationStatus: "" });
    }   
}
   
  getTerminationList = () => {
    const { authUser } = this.props;
    try {
      const getTerminationListData = Promise.resolve(
        separetionServices.getTerminationListData(authUser, "list")
      );
      getTerminationListData.then((result) => {
        if (result !== null) {
          this.setState({ terminationList: getFormatDateForGrid(result) });
        } else {
          this.data = "error";
        }
      });
    } catch (e) {
      // eslint-disable-next-line
      console.log(e);
    }
  };
  getTerminationCommitteeAction = () => {
    const { authUser } = this.props;
    try {
      const getEmployeeResignationMaster = Promise.resolve(
        separetionServices.getEmployeeResignationMaster(authUser, "TRMNACT")
      );
      getEmployeeResignationMaster.then((result) => {
        if (result !== null) {
          const getTermination = result.filter((item) => {
            return item.Type === "Termination Action";
          })
          this.setState({ terminationCommitteeAction: getTermination });
        } else {
          this.data = "error";
        }
      });
    } catch (e) {
      // eslint-disable-next-line
      console.log(e);
    }
  };
  onCreated() {
    setTimeout(() => {
      if (this.acrdnbasic !== null) {
        // eslint-disable-next-line
        for (let i = 0; i <= this.acrdnbasic.items.length - 1; i++) {
          this.acrdnbasic.items[i].expanded = true;
        }
      }
    }, 500);
  }

  render() {
    const { terminationList,  terminationCommitteeAction } = this.state;
    return (
      <div>
        <div
          style={{
            padding: 10,
            //  backgroundColor: "#F4F3F5"
          }}
        // className='resignation-scroll'
        >
          <NotificationContainer />
          <div>
            {/* <AccordionComponent
            ref={(accordion) => {
              this.acrdnbasic = accordion;
            }}
            created={this.onCreated}
          > */}
            {terminationList.length > 0   && terminationCommitteeAction.length>0 ? (
              <TerminationHrGrid getTerminationList={this.getTerminationList} terminationList={terminationList}   terminationCommitteeAction={terminationCommitteeAction} />
            )
              :
              <div className='progress-bar-div'>
                <Progressbar />
              </div>
            }
            {/* </AccordionComponent> */}
          </div>
        </div>
      </div>
    );
  }
}
TerminationHr.propTypes = {
  // eslint-disable-next-line
  authUser: PropTypes.string,
};
TerminationHr.defaultProps = {
  authUser: "",
};

const mapStateToProps = ({ auth,separationreducer }) => {
  const { authUser } = auth;
  const { UpdateResignationStatus } = separationreducer

  return { authUser,UpdateResignationStatus };
};
export default connect(mapStateToProps, {addUpdateSeparationDetails})(
  TerminationHr
);
