export const UPDATE_LNA_REDUX = "update_lna_redux";
export const SAVE_HOLIDAY_CLUSTER = "save_holiday_cluster";
export const APPLY_LEAVE = "apply_leave";
export const ADD_EDIT_WORKINGDAY_CLUSTER_DATA = "add_edit_workingday_clusterdata";
export const ADD_EDIT_COLOUR_CLUSTER_DATA = 'add_edit_colour_cluster_data';
export const ADD_EDIT_STATUTORY_CLUSTER_DATA = 'add_edit_statutory_cluster_data';
export const ADD_EDIT_EXCEPTIONAL_CLUSTER_DATA = 'add_edit_exceptional_cluster_data';
export const ADD_EDIT_ROLE_CLUSTER_DATA = 'add_edit_role_cluster_data';
export const SAVE_ATTENDANCE_CLUSTER = "save_attendance_cluster";
// export const ADMIN_EDIT_ADD_CAL = "admin_edit_add_cal";
export const SAVE_SOURCE_CLUSTER = "save_source_cluster";
export const SAVE_DAY_BREAK_CLUSTER = "save_day_break_cluster";
export const SAVE_LEAVE_CLUSTER = "save_leave_cluster";
export const SAVE_WEEKDAY_CLUSTER = "save_weekday_cluster";
export const SAVE_WORK_DAY_WINDOW_CLUSTER = 'save_working_day_window_cluster'
export const SAVE_OPTIONAL_HOLIDAY_CLUSTER = 'save_optional_holiday_cluster'
export const SAVE_SYMBOL = 'save_symbol'
export const SAVE_LEAVE_YEAR = 'SAVE_LEAVE_YEAR';
export const SAVE_REGULARIZATION_DATA = "save_regularization_data";
export const SAVE_COMPOFF_DATA = "save_compoff_data";
export const SAVE_LEAVE_TRANSFER_DATA = "save_leavetransfer_data";
export const CHANGE_LEAVE_APPROVAL_STATUS = "change_leave_approval_status";
export const SAVE_LEAVE_CANCEL_REASON = "save_leave_cancel_reason";
export const SAVE_PARTIAL_LEAVE_CANCEL_REASON = "save_partial_leave_cancel_reason";
export const UPDATE_MASTER_ITEM_STATUS = "update_master_item_status";
export const SAVE_WEEK_OFF_SWAP_DATES = "save_week_off_swap_dates";
export const ADD_NOTIFICATION = "add_notification";
export const SAVE_LOCK_UNLOCK_CLUSTER = "save_lock_unlock_cluster";
export const SAVE_LEAVE_YEAR_CLUSTER = "save_leave_year_cluster";
export const SAVE_WORKDAY_DAY_BREAK_MAP = "save_workday_day_break_map"
