import React, { Component } from "react";
import { LEAVETRANSFER } from "../../../redux/constant/meetingcategoryconstant";
import LeaveTransferApplication from "../../../components/lnacomponents/leaves/transfer/leavetransfer";
import "../../layout/lna/lna.css";
class LeaverTransferContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      height: null,
    };
  }
  componentDidMount = () => {
    window.addEventListener("resize", this.updateDimensions());
  };
  updateDimensions() {
    const updateheight = window.innerHeight - 95;
    this.setState({
      height: updateheight,
    });
  }

  render() {
    const { categoryName } = this.props;
    const { height } = this.state;

    return (
      <div
        className="attendance-container ml-md mr-md mb-md pt-0"
        style={{ height: height, backgroundColor: "#f2f2f2" }}
      >
        {categoryName === LEAVETRANSFER && <LeaveTransferApplication />}
      </div>
    );
  }
}
export default LeaverTransferContainer;
