import React,{useState,useEffect} from "react";
import { connect } from 'react-redux';
import { updateUserInfo } from '../../redux/actions/auth'
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import '../../assets/style/index.css';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AllDateFormatOrgChart } from "../separation/allcommoncomponents";
import './orgchart-sidebar.css'
import teams from '../../assets/images/teams.png'
import { library, } from '@fortawesome/fontawesome-svg-core'
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import { faStar, faUserClock, faUserShield, faTrashAlt, faCheck, faCheckDouble, faCheckCircle, faClone, faBan, faRecycle, faEnvelope, faPhoneAlt, faUsers, faPhoneRotary, faLayerGroup, faExternalLink, faIdCardAlt,faCalendarDay,faChalkboardTeacher,faLayerMinus} from '@fortawesome/pro-light-svg-icons'
import { urlConfig } from "../../config/config";
library.add(faStar, faUserClock, faUserShield, faTrashAlt, faCheck, faCheckDouble, faCheckCircle, faClone, faBan, faRecycle, faUsers, faEnvelope, faPhoneAlt, faPhoneRotary, faLayerGroup, faExternalLink, faIdCardAlt,faCalendarDay,faChalkboardTeacher,faLayerMinus);

function OrgChartSideMenu(props) {
  const [style,setStyle]=useState('block')

useEffect(()=>{
  getStyle()
})
const getStyle=()=>{
  if(props.orgChartSideBarData.Id === 1000){
    setStyle('none')
  }else{
    setStyle('block')
  }
}


  const addDefaultSrc = (ev) => {
    ev.target.src = urlConfig.tmlcApiUrl + "document/E/0/default.png"
  }
  const getTotalTenure = (d1, d2) => {
    let months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    const month = months <= 0 ? 0 : months

    if (month > 12) {
      return <><span> <AllDateFormatOrgChart date={d1} /></span> <span style={{ fontSize: 12 }}>{'(' + (month / 12 | 0) + " Years" + " " + (month % 12) + " Months" + ')'}</span></>
    } else {
      return <><span> <AllDateFormatOrgChart date={d1} /></span> <span style={{ fontSize: 12 }}>{('(' + (month % 12) + " Months" + ')')}</span></>
    }
  }
  let teamsEmail = "MSTeams:/l/chat/0/0?users=" + props.orgChartSideBarData.teams  

  return (
    <div className='col-lg-12' style={{ width: '350px' }}>
      <div className='row' style={{ width: '100%' }}>
        <div className='header-Profile' style={{ float: 'left' }}>
          PROFILE
        </div>
        <div style={{ float: 'right' }}>
          <IconButton style={{ color: "#b7b7bb", marginRight: "-17px", marginTop: "-7px" }} aria-label="close"
            onClick={() => props.updateUserInfo({ openOrgChartSidebar: false })}
          >
            <CloseIcon className='close-icon' />
          </IconButton>
        </div>
      </div>
      <div className='row'>
        <div className='col-lg-12' style={{ marginLeft: -31 }}>
          <div className='col-lg-3 mt-sm mb-sm'>
            <img className="img-circle image-show-in-layout-view" onError={addDefaultSrc}
              src={urlConfig.tmlcApiUrl + 'document/E/0/' + props.orgChartSideBarData.ImageUrl} alt="" />
              
          </div>
          <div className='col-lg-9 mt-lg mb-sm'>
            <div className='emp-name-sidebar'> {props.orgChartSideBarData.Name === null ? "NA" : props.orgChartSideBarData.Name}</div>
            <div className='emp-designation-sidebar'> {props.orgChartSideBarData.Designation === null ? "NA" : props.orgChartSideBarData.Designation} </div>
            {
              props.orgChartSideBarData.indirect_reportee_flag === "Y" &&
              <div className='emp-shared-sidebar'> Shared ({props.orgChartSideBarData.shareWithCount})</div>
            }
          </div>
        </div>
      </div>
      <div className='divider' style={{ marginTop: 5 }} />
      <div className='row' style={{ width: '100%',display:style }}>
        <div className='header-Profile'>
          Other Details
        </div>
      </div>

      <div className='row' style={{display:style}}>
        <div className='col-lg-12' style={{ width: '100%', }}>
          <div className='round-button-layout' style={{ float: 'left' }}>
            {
              /*
              <div className='mail-button-image' title="Email Address" />
              */
              <TooltipComponent
                closeOn="Click"
                content='Team Member ID'
                position="BottomCenter"
                isSticky={false}
                cssClass='customtooltip'
              >
                <FontAwesomeIcon icon={faIdCardAlt} />
              </TooltipComponent>
            }
          </div>
          <div className='channel-font'>
            <div className='emp-name-sidebar sidebar-other-details'> {props.orgChartSideBarData.var_emp_code === null ? "NA" : props.orgChartSideBarData.var_emp_code}</div>
            <div className='emp-designation-sidebar'> {"Team Member ID "} </div>
          </div>
        </div>
        <div className='col-lg-12' style={{ width: '100%', }}>
          <div className='round-button-layout' style={{ float: 'left' }}>
            {
              /*
              <div className='mail-button-image' title="Email Address" />
              */
              <TooltipComponent
                closeOn="Click"
                content='Date Of Joining'
                position="BottomCenter"
                isSticky={false}
                cssClass='customtooltip'
              >
                <FontAwesomeIcon icon={faCalendarDay} />
              </TooltipComponent>
            }
          </div>
          <div className='channel-font'>
            <div className='emp-name-sidebar sidebar-other-details'> {props.orgChartSideBarData.joiningDate === null ? "NA" : getTotalTenure(new Date(props.orgChartSideBarData.joiningDate), new Date())}</div>
            <div className='emp-designation-sidebar'> {"Date of Joining"} </div>
          </div>
        </div>
        <div className='col-lg-12' style={{ width: '100%', }}>
          <div className='round-button-layout' style={{ float: 'left' }}>


            {
              /*
              <div className='mail-button-image' title="Email Address" />
              */
              <TooltipComponent
                closeOn="Click"
                content='Practice Area / Sales Unit / Sub Function'
                position="BottomCenter"
                isSticky={false}
                cssClass='customtooltip'
              >
                <FontAwesomeIcon icon={faChalkboardTeacher}/>
              </TooltipComponent>
            }
          </div>
          <div className='channel-font'>
            <div className='emp-name-sidebar sidebar-other-details'> {props.orgChartSideBarData.practiceArea === null ? "NA" : props.orgChartSideBarData.practiceArea}</div>
            <div className='emp-designation-sidebar'> {"Practice Area / Sales Unit / Sub Function"} </div>
          </div>
        </div>
        <div className='col-lg-12' style={{ width: '100%', }}>
          <div className='round-button-layout' style={{ float: 'left' }}>
            {

              <TooltipComponent
                closeOn="Click"
                content='Layer'
                position="BottomCenter"
                isSticky={false}
                cssClass='customtooltip'
              >
                <FontAwesomeIcon icon={faLayerGroup} />
              </TooltipComponent>
            }
          </div>
          <div className='channel-font'>
            <div className='emp-name-sidebar sidebar-other-details'> {props.orgChartSideBarData.layer === null ? "NA" : props.orgChartSideBarData.layer}</div>
            <div className='emp-designation-sidebar'> {"Layer"} </div>
          </div>
        </div>
        <div className='col-lg-12' style={{ width: '100%', }}>
          <div className='round-button-layout' style={{ float: 'left' }}>
            {

              <TooltipComponent
                closeOn="Click"
                content='Sub Layer'
                position="BottomCenter"
                isSticky={false}
                cssClass='customtooltip'
              >
                <FontAwesomeIcon icon={faLayerMinus} />
              </TooltipComponent>
            }
          </div>
          <div className='channel-font'>
            <div className='emp-name-sidebar sidebar-other-details'> {props.orgChartSideBarData.subLayer === null ? "NA" : props.orgChartSideBarData.subLayer}</div>
            <div className='emp-designation-sidebar'> {"Sub Layer"} </div>
          </div>
        </div>


        <div className='col-lg-12' style={{ width: '100%', }}>
          <div className='round-button-layout' style={{ float: 'left' }}>
            {
              //<div className='teams-button-image' title="Teams" />
              <TooltipComponent
                closeOn="Click"
                content='Teams'
                position="BottomCenter"
                isSticky={false}
                cssClass='customtooltip'
              >
                {/* <FontAwesomeIcon icon={faUsers} title="Teams" /> */}
                <img style={{ marginLeft: -4, marginTop: -3 }} src={teams} />
              </TooltipComponent>
            }

          </div>
          <div className='channel-font'>
            {(props.orgChartSideBarData.teams === null || props.orgChartSideBarData.teams === "") ? "NA" : <span> {props.orgChartSideBarData.teams}{<a style={{ textDecoration: 'none' }} href={teamsEmail}> <FontAwesomeIcon icon={faExternalLink} title="Open Teams" /></a>}</span>}
          </div>
        </div>
        <div className='col-lg-12' style={{ width: '100%', }}>
          <div className='round-button-layout' style={{ float: 'left' }}>


            {
              /*
              <div className='mail-button-image' title="Email Address" />
              */
              <TooltipComponent
                closeOn="Click"
                content='Email Address'
                position="BottomCenter"
                isSticky={false}
                cssClass='customtooltip'
              >
                <FontAwesomeIcon icon={faEnvelope} />
              </TooltipComponent>
            }
          </div>
          <div className='channel-font'>
            {props.orgChartSideBarData.emailId === null ? "NA" : props.orgChartSideBarData.emailId}
          </div>
        </div>

        {/* <div className='col-lg-12' style={{ width: '100%', }}>
          <div className='round-button-layout' style={{ float: 'left' }}>


            {
              
              <TooltipComponent
                closeOn="Click"
                content='Contact Number'
                position="BottomCenter"
                isSticky={false}
                cssClass='customtooltip'
              >
                <FontAwesomeIcon icon={faPhoneRotary} title="Contact Number" />
              </TooltipComponent>

            }
          </div>
          <div className='channel-font'>
            {props.orgChartSideBarData.mobileNumber === null ? "NA" : props.orgChartSideBarData.mobileNumber}
          </div>
        </div> */}
      </div>
      {/*<div className='divider' style={{ marginTop: 10 }} />
      <div className='row' style={{ width: '100%' }}>
        <div className='header-Profile'>
          OTE Details
            </div>
        <div className='sub-header-Profile'>
          On-target Earning
            </div>
        <div className='on-target-earning'>
          <div className='channel-font'>
            {props.orgChartSideBarData.totalCtc === null ? "NA" : props.orgChartSideBarData.totalCtc}
          </div>
        </div>

      </div>
       <div className='row'>
        <div className='col-lg-12' style={{ marginLeft: -31 }}>
          <div className='col-lg-4'>
            <div className='sub-ote-header'>Fixed CTC</div>
            <div className='channel-font'>
              <div className='sub-ote-earning'>{props.orgChartSideBarData.fixCtc === null ? "NA" : props.orgChartSideBarData.fixCtc}</div>
            </div>
          </div>
          <div className='col-lg-5'>
            <div className='sub-ote-header'>Variable CTC</div>
            <div className='channel-font'>
              <div className='sub-ote-earning'>{props.orgChartSideBarData.variableCtc === null ? "NA" : props.orgChartSideBarData.variableCtc}</div>
            </div>
          </div>
          <div className='col-lg-4'>
            <div className='sub-ote-header'>Variable CTC</div>
            <div className='sub-ote-earning'>{props.orgChartSideBarData.variableCtc === null ? "NA" : props.orgChartSideBarData.variableCtc}</div>
          </div>
        </div>
      </div> */}
    </div>
  );
}
OrgChartSideMenu.defaultProps = {}


const mapStateToProps = ({ auth }) => {
  const { openOrgChartSidebar, orgChartSideBarData } = auth;
  return {
    openOrgChartSidebar, orgChartSideBarData
  }
}

export default connect(mapStateToProps, {
  updateUserInfo
})(OrgChartSideMenu);
