import React, { useState, useEffect } from "react";
import { ComboBoxComponent } from "@syncfusion/ej2-react-dropdowns";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { connect } from "react-redux";
import { FormValidator } from "@syncfusion/ej2-inputs";
import LnAServices from "../../services/lna/lnaservice";
import { saveSourceCluster, updateLnAData } from "../../redux/actions/lna/lnaaction";
import { getNotify,getMySqlDateFormat } from "../../app/other/commonfunction";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import {
  ERROR,
  pleaseEnterSourceName,
  pleaseEnterSymbol,
  pleaseEnterDeviceLocation,
  pleaseEnterDeviceId,
  pleaseEnterValidIt1,
  pleaseEnterValidIt2,
  pleaseEnterValidIt3,
  pleaseEnterValidDeviceId,
  regexOnlyLettersAndSpaces,
  selecteffectivestartdate,
  effectiveStartDateVaidation,
  effectiveenddatevalidation
} from "../../assets/constants/constants";
import ProgressBar from "../progressbar/progressbar";

const SAMPLECSS = `
input.e-input, textarea.e-input, .e-input-group, .e-input-group.e-control-wrapper {
  font-size: 12px;
}
`

function SourceCluster(props) {
  //NOSONAR
  const {
    authUser,
    openPopup,
    editClusterId,
    attendanceSourceData,officeCountry
  } = props;
  const [state, setState] = useState({
    sourceClusterId: null,
    sourceName: null,
    // sourceSymbol: null,
    symbolId: null,
    sourceDescription: null,
    deviceLocation: null,
    deviceId: null,
    isActive: "1",
    effectiveStartDate: null,
    effectiveEndDate: null,
    it1: null,
    it2: null,
    it3: null,
  });
  const [initialLoad, setInitialLoad] = useState(false)

let validOptions = {
  rules: {
    sourceNameErr: {
      required: [true, pleaseEnterSourceName],
      regex:[regexOnlyLettersAndSpaces,pleaseEnterSourceName],
      minLength: [1, pleaseEnterSourceName],
      maxLength: [50, "Should be maximum of 50 characters only."]
      
    },
    sourceSymbolErr: {
      required: [true, pleaseEnterSymbol],
    },
    deviceLocationErr: {
      required: [true, pleaseEnterDeviceLocation],
    },
    deviceIdErr: {
      required: [true, pleaseEnterDeviceId],
    },
    effectiveStartDateErr:{
      required: [true, selecteffectivestartdate],
    }
  },
}; 

  useEffect(() => {
    setTimeout(() => {
      props.setSubmitClick(saveSourceData);
    }, 100);
  }, [state]);

  useEffect(() => {
    props.updateLnAData({ popUpSource: 'Attendance Source Master' })
    getData()
  }, []);

  useEffect(() => {
    getData()
  }, [editClusterId, openPopup]);

  useEffect(() => {
    if (props.lnaMasterFlag === "updated")
      props.changePopUpHandler("close");
  }, [props.lnaMasterFlag]);

  const getData = () => {
    if (openPopup === "add") {
      resetData()
    }
    else if (openPopup === "edit") {
      editClusterId !== null &&
        (async () => {
          setInitialLoad(true)
          const sourceClusterData = await LnAServices.getSourceClusterData(
            authUser,
            editClusterId
          );

          const {
            sourceClusterId,
            sourceName,
            symbolId,
            sourceDescription,
            deviceLocation,
            deviceId,
            isActive,
            effectiveStartDate,
            effectiveEndDate,
            it1,
            it2,
            it3,
          } = sourceClusterData.Result[0];

          let data = {
            sourceClusterId,
            sourceName,
            symbolId,
            sourceDescription,
            deviceLocation,
            deviceId,
            isActive,
            effectiveStartDate,
            effectiveEndDate,
            it1,
            it2,
            it3,
          };
          setInitialLoad(false)
          setState((preState) => ({ ...preState, ...data }));
           setTimeout(() => {
            const addFormObject = new FormValidator("#sourceClusterForm", validOptions);
            addFormObject.validate();
          }, 100)

        })();
    }
  }
  const resetData = () => {
    let data = {
      sourceClusterId: 0,
      sourceName: null,
      // sourceSymbol: null,
      symbolId: null,
      sourceDescription: null,
      deviceLocation: null,
      deviceId: null,
      isActive: "1",
      effectiveStartDate: null,
      effectiveEndDate: null,
      it1: null,
      it2: null,
      it3: null,
    };
    setState((preState) => ({ ...preState, ...data }));
  }
  const inputChangeHandler = (e, type) => {
    let options = {};
    switch (type) {
      case "sourceName":
        setState((prevState) => ({ ...prevState, sourceName:  e.value.trim() }));
        options = validOptions.rules['sourceNameErr'];
        break;
      case "symbolId":
        setState((prevState) => ({ ...prevState, symbolId: e.value }));
        options = validOptions.rules['sourceSymbolErr'];
        break;
      case "sourceDescription":
        setState((prevState) => ({ ...prevState, sourceDescription: e.value && e.value.trim() }));
        break;
      case "deviceLocation":
        setState((prevState) => ({ ...prevState, deviceLocation:  e.itemData && e.itemData.Id}));
        options = validOptions.rules['deviceLocationErr'];
        break;
      case "deviceId":
        setState((prevState) => ({ ...prevState, deviceId: e.value && e.value.trim() }));
        options = validOptions.rules['deviceIdErr'];
        break;
      case "isActive":
        setState((prevState) => ({
          ...prevState,
          isActive: e.checked === true ? "1" : "0",
        }));
        break;
      case "it1":
        setState((prevState) => ({ ...prevState, it1: e.value && e.value.trim() }));

        break;
      case "it2":
        setState((prevState) => ({ ...prevState, it2: e.value && e.value.trim() }));

        break;
      case "it3":
        setState((prevState) => ({ ...prevState, it3: e.value && e.value.trim() }));
        break;

      case "EffectiveStartDate":
        setState((prevState) => ({
          ...prevState,
          effectiveStartDate: e.value,
        }));
       options=validOptions.rules['effectiveStartDateErr']

        break;
      case "EffectiveEndDate":
        setState((prevState) => ({ ...prevState, effectiveEndDate: e.value }));
        break;
      default:
    }

    

    setTimeout(() => {
      const addFormObject = new FormValidator("#sourceClusterForm", options);
      addFormObject.validate();
    }, 100);
  };

  const saveSourceData = () => {
    const {
      sourceClusterId,
      sourceName,
      symbolId,
      sourceDescription,
      deviceLocation,
      deviceId,
      isActive,
      effectiveStartDate,
      effectiveEndDate,
      it1,
      it2,
      it3,
    } = state;

    let validateDates = true;
    if (state.effectiveStartDate === null && state.effectiveEndDate !== null) {
      document.getElementById("effectiveStartDateErr").innerHTML =
      selecteffectivestartdate;
      validateDates = false;
    } else if (
      state.effectiveStartDate !== null &&
      state.effectiveEndDate !== null
    ) {
      if (
        new Date(state.effectiveStartDate) > new Date(state.effectiveEndDate)
      ) {
        document.getElementById("effectiveStartDateErr").innerHTML =
        effectiveStartDateVaidation ;
        document.getElementById("effectiveEndDateErr").innerHTML =
        effectiveenddatevalidation;
        validateDates = false;
      }
    }
    if (state.deviceId !== null) {
      let reg = /^.{2,50}$/
      let validName = reg.test(state.deviceId.trim())
      if (!validName) {
        getNotify(ERROR, pleaseEnterValidDeviceId);
        validateDates = false;
      }
    }

    if (state.it1 !== null) {
      let reg = /^.{0,50}$/
      let validName = reg.test(state.it1)
      if (!validName) {
        getNotify(ERROR, pleaseEnterValidIt1);
        validateDates = false;
      }
    }
    if (state.it2 !== null) {
      let reg = /^.{0,50}$/
      let validName = reg.test(state.it2)
      if (!validName) {
        getNotify(ERROR, pleaseEnterValidIt2);
        validateDates = false;
      }
    }
    if (state.it3 !== null) {
      let reg = /^.{0,50}$/
      let validName = reg.test(state.it3)
      if (!validName) {
        getNotify(ERROR, pleaseEnterValidIt3);
        validateDates = false;
      }
    }





    const addFormObject = new FormValidator("#sourceClusterForm", validOptions);
    if (addFormObject.validate() && validateDates) {
      setInitialLoad(true)
      let sourceClusterSaveData = {
        userId: authUser,
        type: sourceClusterId > 0 ? 'EDIT' : 'ADD',
        sourceUid: sourceClusterId,
        sourceName,
        // sourceSymbol,
        symbolId,
        sourceDescription,
        deviceLocation,
        deviceId,
        isActive,
        effectiveStartDate:
          effectiveStartDate !== null
            ? getMySqlDateFormat(effectiveStartDate)
            : null,
        effectiveEndDate:
          effectiveEndDate !== null
            ? getMySqlDateFormat(effectiveEndDate)
            : null,
        it1,
        it2,
        it3,
      };
      props.saveSourceCluster(sourceClusterSaveData);
      setTimeout(() => {
        setInitialLoad(false)
      }, 10)
    }


  };
  const descriptionTextBox = `.e-outline textarea, textarea.e-outline, .e-outline.e-float-input textarea, .e-outline.e-float-input.e-control-wrapper textarea, .e-outline.e-float-input.e-input-group textarea {
    box-sizing: border-box;
    min-height: 100px;
  }`
  const getOneDayForward = (dt)=>{
    let date = dt
    date.setDate(date.getDate() + 1)
    return date
  }
 
  return (
    <div
      id="sourceClusterForm"
      style={{ height: "61.5vh", overflowY: "scroll" }}
    >
      <style>{SAMPLECSS}</style>
      <div style={{ padding: "20px" }}>
        <div
          className="col-xs-12 col-sm-12 col-lg-12 col-md-12   atc-no-paddding"
          style={{ marginTop: "0px" }}
        >
          <TextBoxComponent
            data-msg-containerid="sourceNameErr"
            id="sourceName"
            value={state.sourceName}
            autocomplete={'off'}
            name="sourceNameErr"
            placeholder="Source Name *"
            floatLabelType="Auto"
            cssClass="e-outline"
            onChange={(e) => inputChangeHandler(e, "sourceName")}
            htmlAttributes={{ maxlength: 50 }}
          />
          <div id="sourceNameErr" className="error-div" />
        </div>

        <div
          className="col-xs-12 col-sm-12 col-lg-12 col-md-12  mt-lg atc-no-paddding"
          style={{ marginBottom: "0px" }}
        >
          <ComboBoxComponent
            id="symbol"
            dataSource={attendanceSourceData}
            fields={{ text: "Name", value: "Id" }}
            value={state.symbolId}
            data-msg-containerid="sourceSymbolErr"
            name="sourceSymbolErr"
            allowCustom={false}
            floatLabelType="Auto"
            cssClass="e-outline"
            placeholder="Symbol *"
            onChange={(e) => inputChangeHandler(e, "symbolId")}
          />
          <div id="sourceSymbolErr" className="error-div" />
        </div>

        <div
          className="col-xs-12 col-sm-12 col-lg-12 col-md-12  mt-lg atc-no-paddding weekdayDescription"
          style={{ marginBottom: "0px" }}
        >
          <style>{descriptionTextBox}</style>
          <TextBoxComponent
            style={{ minHeight: 100 }}
            id={'description'}
            placeholder={"Description "}
            autocomplete={'off'}
            cssClass="e-outline textarea"
            floatLabelType="Auto"
            name="descriptionErr"
            data-msg-containerid="descriptionErr"
            multiline
            inputProps={{
              required: true,
              minLength: 0,
            }}
            htmlAttributes={{ maxlength: 500 }}
            onChange={(e) => inputChangeHandler(e, "sourceDescription")}
            value={state.sourceDescription}
          />
          {/* <div id="sourceDescriptionErr" className="error-div" /> */}
        </div>

        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12  mt-lg atc-no-paddding">
          <ComboBoxComponent
            data-msg-containerid="deviceLocationErr"
            id="deviceLocation"
            value={state.deviceLocation}
            fields={{text:'Name',value:'Id'}}
            name="deviceLocationErr"
            allowCustom={false}
            placeholder="Device Location *"
            floatLabelType="Auto"
            cssClass="e-outline"
            select={(e) => inputChangeHandler(e, "deviceLocation")}
            dataSource={officeCountry}
          />
          <div id="deviceLocationErr" className="error-div" />
        </div>

        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12  mt-lg atc-no-paddding">
          <TextBoxComponent
            data-msg-containerid="deviceIdErr"
            id="deviceId"
            value={state.deviceId}
            name="deviceIdErr"
            placeholder="Device ID *"
            floatLabelType="Auto"
            autocomplete={'off'}
            cssClass="e-outline"
            onChange={(e) => inputChangeHandler(e, "deviceId")}
            htmlAttributes={{ maxlength: 51 }}
          />
          <div id="deviceIdErr" className="error-div" />
        </div>

        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12  mt-lg atc-no-paddding">
          <TextBoxComponent
            data-msg-containerid="itOneErr"
            id="itOne"
            value={state.it1}
            name="itOneErr"
            autocomplete={'off'}
            placeholder="IT 1"
            floatLabelType="Auto"
            onChange={(e) => inputChangeHandler(e, "it1")}
            cssClass="e-outline"
            htmlAttributes={{ maxlength: 150 }}
          />
        </div>

        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12  mt-lg atc-no-paddding">
          <TextBoxComponent
            data-msg-containerid="itTwoErr"
            id="itTwo"
            value={state.it2}
            name="itTwoErr"
            autocomplete={'off'}
            placeholder="IT 2"
            floatLabelType="Auto"
            onChange={(e) => inputChangeHandler(e, "it2")}
            cssClass="e-outline"
            htmlAttributes={{ maxlength: 150 }}
          />
        </div>

        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12  mt-lg atc-no-paddding">
          <TextBoxComponent
            data-msg-containerid="itThreeErr"
            id="itThree"
            value={state.it3}
            autocomplete={'off'}
            name="itThreeErr"
            // multiline={true}
            placeholder="IT 3"
            floatLabelType="Auto"
            onChange={(e) => inputChangeHandler(e, "it3")}
            cssClass="e-outline"
            htmlAttributes={{ maxlength: 150 }}
          />
          {/* <div id="graceTimeErr" className="error-div" /> */}
        </div>

        <div
          className="col-xs-12 col-sm-12 col-lg-12 col-md-12  mt-lg atc-no-paddding"
          style={{ marginBottom: "15px" }}
        >
          <div
            className="col-xs-6 col-sm-6 col-lg-6 col-md-6"
            style={{ padding: 0 }}
          >
            <DatePickerComponent
              style={{ width: "100%" }}
              id="effectiveStartDate"
              name="effectiveStartDateErr"
              data-msg-containerid="effectiveStartDateErr"
              format="dd-MMM-yyyy"
              value={state.effectiveStartDate}
              floatLabelType="Auto"
              cssClass="e-outline"
              firstDayOfWeek={1}
              placeholder="Effective Start Date *"
              change={(e) => inputChangeHandler(e, "EffectiveStartDate")}
            />
            <div
              id="effectiveStartDateErr"
              className="e-input-group e-float-icon-left error-div"
            />
          </div>
          <div
            className="col-xs-6 col-sm-6 col-lg-6 col-md-6"
            style={{ paddingRight: 0 }}
          >
            <DatePickerComponent
              style={{ width: "100%" }}
              id="effectiveEndDate"              
              name="effectiveEndDateErr"
              data-msg-containerid="effectiveStartEndErr"
              format="dd-MMM-yyyy"
              value={state.effectiveEndDate}
              floatLabelType="Auto"
              cssClass="e-outline"
              firstDayOfWeek={1}
              placeholder="Effective End Date "
              min={state.effectiveStartDate ? getOneDayForward(new Date(state.effectiveStartDate)): null}
              change={(e) => inputChangeHandler(e, "EffectiveEndDate")}
            />
            <div
              id="effectiveEndDateErr"
              className="e-input-group e-float-icon-left error-div"
            />
          </div>
        </div>
      </div>
      <div className="loader-new1" id="spinnerContainer" >
        {initialLoad && <ProgressBar />}
      </div>
    </div>
  );
}

const mapStateToProps = ({ auth, lnareducer, meetingmaster }) => {
  const { authUser } = auth;
  const { currentFinancialYearId } = meetingmaster;
  const { symbolData, lnaMasterFlag,officeCountry } = lnareducer;

  const { attendanceSourceData } = symbolData;

  return {
    authUser,
    lnareducer,
    currentFinancialYearId,
    attendanceSourceData,
    lnaMasterFlag,officeCountry
  };
};
export default connect(mapStateToProps, {
  saveSourceCluster, updateLnAData
})(SourceCluster);
