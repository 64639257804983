import React, { Component } from "react";
import { connect } from "react-redux";
import AdminServices from "../../../services/adminservices";
import OrgchartPopUp from "./orgchartpopup";
import "./orgchartadmin.css";
import {
  updateAdminReduxData,
  addEditLayerMappingFunction,
} from "../../../redux/actions/admin/adminaction";
import { Button } from "@material-ui/core";
import { faCaretDown, faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  GridComponent, ColumnsDirective, ColumnDirective, Aggregate, Filter,
  Group, AggregateColumnsDirective, AggregateColumnDirective, AggregateDirective, AggregatesDirective, Inject
} from '@syncfusion/ej2-react-grids';
import { ComboBoxComponent } from "@syncfusion/ej2-react-dropdowns";

import {
  getGridColumn,
  LAYER,
  HIERARCHY,
  PARENTLAYER,
  EMPLOYEE_COUNT,

  ACTION
} from "../../../app/other/gridcolumns";
import MappingActionTemplate from "./mappingAction";
import Progressbar from "../../../components/progressbar/progressbar";
import ErrorMessage from "../../errormessage/errormessage";
import stylingNewOrgChart from "./orgChartStylingGridComponents";
import { setSpinner } from '@syncfusion/ej2-react-popups';
import { spinner } from "../../../app/other/commonfunction";
import { L10n } from '@syncfusion/ej2-base';
L10n.load({
  'en-US': {
    grid: {
      EmptyRecord: '',
    },
  },
});

const styleLayer = ` .e-grid .e-icon-filter {
    margin-top: 0px !important;
}`
class Layermapping extends Component {
  constructor() {
    super();
    this.state = {
      height:
        (window.innerHeight ||
          document.documentElement.clientHeight ||
          document.body.clientHeight) - 188,
      heightPending:
        (window.innerHeight ||
          document.documentElement.clientHeight ||
          document.body.clientHeight) - 188,
      gridHeight: (window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight) - 290,
      result: [],
      openPopup: false,
      maxHierarchy: 0,
      layerMappingMasterData: [],
      layerMappingBusinessUnitFunctionData: [],
      businessUnitId: 1,
      layerMappingParentData: [],
      isLoading: true,
      isExpanded: true,
      expandStage: 0
    };
    this.header = "Layer Mapping"
    this.title = "";
    this.type = "";
    this.filterSettings = { type: 'CheckBox' };
    this.groupOptions = { showGroupedColumn: true, columns: ['businessFunctionUnitName', "subBusinessFunctionUnitName", "practiceAreaName"], showDropArea: false }
    this.actionTemplate = this.actionTemplate.bind(this);
    this.check = { type: 'CheckBox' };
    this.width =
      (window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth) - 100; // 100

    this.dataBound = this.dataBound.bind(this);
    this.onDataBinding = this.onDataBinding.bind(this)
    this.initialLoad = true;
  }

  updateDimensions() {
    const updateHeight = window.innerHeight - 230;
    const updateHeightp = window.innerHeight - 185;
    this.setState({ height: updateHeight, heightPending: updateHeightp });
  }

  componentDidMount() {
    this.getAdminMasterData();
    this.getBusinessFunctionData();
    this.updateDimensions();
    // this.setHeaderCss()
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.layerMappingFlag === "updated") {
      this.getBusinessFunctionData();
      setTimeout(() => {
        this.gridInstance.groupModule.expandAll();
      }, 1000)
      this.closePopup();
      this.props.updateAdminReduxData({ layerMappingFlag: "" });
    }
  }


  /**
   * Created on 06-09-2022 || Noel Fredrick
   * Description - For spinner
   */
  onDataBinding() {
    spinner(true)
  }
  
  async getAdminMasterData() {
    try {
      const result = await AdminServices.getAdminMasterData("LAYERMAPPING");
      if (result !== null) {
        const layerMappingBusinessUnitFunctionData = result.result.filter(item => item.Type === "businessUnitFunction");
        const businessUnitId = layerMappingBusinessUnitFunctionData.length > 0 ? layerMappingBusinessUnitFunctionData[0].Id : null;
        this.setState({
          layerMappingMasterData: result.result,
          layerMappingBusinessUnitFunctionData,
          businessUnitId,
          isLoading: false
        });
      }
    } catch (error) {
      // Handle or log the error appropriately
      console.error(error);
    }
  }

  getBusinessFunctionData = () => {

    try {
      const { businessUnitId } = this.state
      if (businessUnitId) {
        const businessFunction = Promise.resolve(
          AdminServices.getAdminOrgChartGridData("layerMapping", businessUnitId)
        );
        businessFunction.then((result) => {
          if (result !== null) {
            const maxHierarchy = result.result.reduce(
              (acc, item) => {
                const hierarchyValue = item.hierarchy === '' ? 1 : parseInt(item.hierarchy, 10) + 1;
                return (acc > hierarchyValue ? acc : hierarchyValue)
              },
              0
            );
            this.setState({
              result: result.result,
              maxHierarchy: maxHierarchy,
              isLoading: false
            });
            setTimeout(() => {
              if (document.getElementById('spinnerContainer')) {
                document.getElementById('spinnerContainer').remove()
              }
            }, [1000])

          }
        });
      } else {
        this.setState({
          isLoading: false,
        })
      }
    } catch (error) {
      console.error(error);
    }
  };

  makeActive = (type, layerMappingData, activeFlag) => {
    const { authUser } = this.props;
    let data = {
      authUser: authUser,
      type: type,
      // businessUnitId: layerMappingData.businessUnitId,
      // layerId: parseInt(layerMappingData.layerId, 10),
      layerMappingId: parseInt(layerMappingData.layerMappingId, 10),
      isActive: activeFlag,
      // hierarchy: parseInt(layerMappingData.hierarchy, 10)
    };
    this.props.addEditLayerMappingFunction(data);
  };
  getAdmiLayerData = async (businessUnitId) => {
    try {
      const result = await AdminServices.getAdminOrgChartGridData("layerMapping", businessUnitId);
      if (result !== null) {
        this.setState({ layerMappingParentData: result, isLoading: false });
      }
    } catch (error) {
      // eslint-disable-next-line
      console.log(error);
    }
  }
  openPopup = (e, type, layerMappingData) => {
    this.getAdmiLayerData(layerMappingData.businessUnitId);
    this.setState({ openPopup: true });
    this.title = e;
    this.type = type;
    this.layerMappingData = layerMappingData;

  };

  closePopup = () => {
    this.setState({ openPopup: false });
  };

  actionTemplate = (props) => {

    return (<div style={{ display: "flex", justifyContent: "center", marginRight: 15 }}>
      <MappingActionTemplate
        props={props}
        category="LAYER_MAPPING"
        authUser={this.props.authUser}
        makeActiveInActive={this.makeActive}
        openPopup={this.openPopup}
      />
    </div>
    )
  }

  hierarchyFuncSection = (props) => {
    return (
      <div style={{ textAlign: "center" }}>{props.hierarchy}
      </div>
    )
  }

  parentLayerNameFuncSection = (props) => {
    return (
      <div style={{ textAlign: "left" }}>{props.parentLayerName}
      </div>
    )
  }
  layerNameFuncSection = (props) => {
    return (
      <div style={{ textAlign: "left" }}>{props.layerName}
      </div>
    )
  }


  employeeFuncSection = (props) => {
    return (
      <div style={{ textAlign: "center" }}>{props.empCnt}
      </div>
    )
  }

  setHeaderCss = () => {
    setTimeout(() => {
      const ele = document.getElementsByClassName("e-headercelldiv");


      for (let i in ele) {
        if (!isNaN(parseInt(i, 10))) {
          let index = parseInt(i, 10);

          if (ele[index].innerText === ACTION) {
            ele[index].classList.add("grid-center-header");

          } else {
            ele[index].classList.add("grid-left-header");

          }
        }
      }

    }, 500);
  }

  groupcFootertMax(props) {
    return (<p style={{ fontWeight: "bold", textAlign: "center", marginLeft: 14 }}>{props.Sum}</p>);
  }
  dataBound() {
    spinner(false)
    if (this.gridInstance.groupSettings.columns.length > 0) {

      const data = document.querySelectorAll("[ej-mappingname=subBusinessFunctionUnitName]")
      let i = 1;
      while (i < data.length) {
        this.gridInstance.groupModule.expandCollapseRows(data[i])
        i++
      }
    }
  }

  expandFunc = () => {
    const { isExpanded } = this.state
    this.setState(prev => ({ isExpanded: !prev.isExpanded }), () => {
      if (isExpanded) {
        this.gridInstance.groupModule.expandAll();
      }
      else {
        const data = document.querySelectorAll("[ej-mappingname=practiceAreaName]")
        let i = 1;
        while (i < data.length) {
          this.gridInstance.groupModule.expandCollapseRows(data[i])
          i++
        }
      }
    })

  }
  expandFunction = () => {
    const { expandStage } = this.state;
    if (expandStage === 0) {
      const data = document.querySelectorAll("[ej-mappingname=businessFunctionUnitName]")
      let i = 1;
      while (i < data.length) {
        this.gridInstance.groupModule.expandCollapseRows(data[i])
        i++
      }
      this.setState(preState => ({ expandStage: preState.expandStage + 1 }))
    } else if (expandStage === 1) {
      const data = document.querySelectorAll("[ej-mappingname=subBusinessFunctionUnitName]")
      let i = 1;
      while (i < data.length) {
        this.gridInstance.groupModule.expandCollapseRows(data[i])
        i++
      }
      this.setState(preState => ({ expandStage: preState.expandStage + 1 }))
    } else if (expandStage === 2) {
      const data = document.querySelectorAll("[ej-mappingname=practiceAreaName]")
      let i = 1;
      while (i < data.length) {
        this.gridInstance.groupModule.expandCollapseRows(data[i])
        i++
      }
      this.setState(preState => ({ expandStage: preState.expandStage + 1 }))
    }
  }


  collapseFunction = () => {
    let { expandStage } = this.state;
    expandStage = expandStage - 1;
    if (expandStage === 0) {
      const data = document.querySelectorAll("[ej-mappingname=businessFunctionUnitName]")
      let i = 1;
      while (i < data.length) {
        this.gridInstance.groupModule.expandCollapseRows(data[i])
        i++
      }
      this.setState(preState => ({ expandStage: preState.expandStage - 1 }))
    } else if (expandStage === 1) {
      const data = document.querySelectorAll("[ej-mappingname=subBusinessFunctionUnitName]")
      let i = 1;
      while (i < data.length) {
        this.gridInstance.groupModule.expandCollapseRows(data[i])
        i++
      }
      this.setState(preState => ({ expandStage: preState.expandStage - 1 }))
    } else if (expandStage === 2) {
      const data = document.querySelectorAll("[ej-mappingname=practiceAreaName]")
      let i = 1;
      while (i < data.length) {
        this.gridInstance.groupModule.expandCollapseRows(data[i])
        i++
      }
      this.setState(preState => ({ expandStage: preState.expandStage - 1 }))
    }
  }

  /**
       * Created on 14-04-2023 || Abdulla Shaikh
       * Description - For spinner
       */
  onChangeLayerMappingBusinessUnitFunction(e) {

    this.setState({
      result: [],
      businessUnitId: e.itemData != null ? e.itemData.Id : "",
      isLoading: true,
    }, this.getBusinessFunctionData)

  }


  /**
     * Created on 06-09-2022 || Noel Fredrick
     * Description - For spinner
     */
  onFirstGridCreated() {
    if (this.initialLoad) {
      this.initialLoad = false;
      setSpinner({
        template: document.getElementById('spinnerContainer').innerHTML,
      });
      document.getElementById('spinnerContainer').remove();
    }

  }



  render() {
    const { openPopup, height, gridHeight, maxHierarchy, isLoading, result, isExpanded, layerMappingBusinessUnitFunctionData, businessUnitId } = this.state;
    var finalWidthConfirm;
    // eslint-disable-next-line
    var widthColumnConfirm = 0;
    this.setHeaderCss();
    getGridColumn("orgChartMappingAdminAll").forEach((data) => {
      widthColumnConfirm = widthColumnConfirm + 15 + data.width;
    });
    if (widthColumnConfirm <= this.width) {
      finalWidthConfirm = widthColumnConfirm;
    } else {
      finalWidthConfirm = this.width
    }
    const gridHeightNew = (window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight) > 767
      ? gridHeight - 55 //12
      : gridHeight - 50

    const IconContent = !isExpanded ? (<FontAwesomeIcon icon={faCaretRight} className="mr-sm" />) : (<FontAwesomeIcon icon={faCaretDown} className="mr-sm" />)

    return (
      <div
        style={{
          height: height + 20,
          color: "black",
          marginBottom: "16px",
          padding: "8px 4px 8px 4px",
          marginLeft: 14,
          // overflowX:"hidden",
          // overflowY:"auto"
        }}
        id="layerMappFuncSection"
      >
        <div style={{ marginBottom: 8 }} className="subbusiineesTopBtns">
          <span className="add-header">{this.header} </span>
          <div
            className="col-md-6 btncontainer expandbtnMarginLayer2"
            style={{ display: 'flex', justifyContent: 'flex-end' }}
          >
            <div >
              <ComboBoxComponent
                id="EmpType"
                dataSource={layerMappingBusinessUnitFunctionData}
                fields={{ text: "Name", value: "Id" }}
                value={businessUnitId}
                data-msg-containerid="emptypeErr"
                name="emptypeErr"
                allowFiltering
                width={250}
                change={(e) => this.onChangeLayerMappingBusinessUnitFunction(e)}
                floatLabelType="Auto"
                cssClass="e-outline"
                placeholder="Business Unit / Group Function *"
              />
            </div>
            <Button
              style={{
                marginRight: "12px",
                marginLeft: "12px"
              }}
              className="remove-button-shadow addbtn"
              variant="contained"
              onClick={this.collapseFunction}

            >
              <FontAwesomeIcon icon={faCaretRight} className="mr-sm" />
              Collapse
            </Button>
            <Button

              className="remove-button-shadow addbtn"
              color="primary"
              variant="contained"
              onClick={this.expandFunction}
            >
              {IconContent}
              {
                !isExpanded ? "Collapse" : "Expand" //NOSONAR
              }
            </Button>
          </div>
        </div>
        {result.length > 0 ? <>


          <div className="row-xs-12 row-sm-12 row-lg-12 row-md-12 ">
            <div>
              <div style={{ paddingTop: "5px", overflow: "auto" }}
                className="control-section"
              ><style>{stylingNewOrgChart}</style>
                <style>{styleLayer}</style>
                <GridComponent
                  className="grid-head-custome"
                  id="overviewgrid"
                  dataSource={result}
                  allowPaging={false}
                  ref={grid => this.gridInstance = grid}
                  pageSettings={{ pageCount: 5 }}
                  allowFiltering
                  filterSettings={this.filterSettings}
                  allowGrouping={true}
                  groupSettings={this.groupOptions}
                  height={gridHeightNew}
                  rowHeight={40}
                  width={finalWidthConfirm + 20}
                  gridLines='Both'
                  allowTextWrap={true}
                  dataBound={this.dataBound.bind(this)}
                  onDataBinding={this.onDataBinding("layerMappFuncSection")}
                  created={this.onFirstGridCreated.bind(this)}
                >
                  <ColumnsDirective>
                    {getGridColumn("orgChartMappingAdminAllLayerMapping").map((item) => {
                      switch (item.colName) {
                        case LAYER:
                          return (
                            <ColumnDirective
                              key={item.field}
                              template={this.layerNameFuncSection}
                              textAlign={`${item.align} !important`}
                              field={item.field}
                              headerText={item.colName}
                              width={item.width}
                              filter={this.check}

                            >
                            </ColumnDirective>
                          );
                        case PARENTLAYER:
                          return (
                            <ColumnDirective
                              key={item.field}
                              template={this.parentLayerNameFuncSection}
                              textAlign={`${item.align} !important`}
                              field={item.field}
                              headerText={item.colName}
                              width={item.width}
                              filter={this.check}

                            >
                            </ColumnDirective>
                          );
                        case HIERARCHY:
                          return (
                            <ColumnDirective
                              key={item.field}
                              template={this.hierarchyFuncSection}
                              textAlign={`${item.align} !important`}
                              field={item.field}
                              headerText={item.colName}
                              width={item.width}
                              filter={this.check}
                              type={"number"}
                            >
                            </ColumnDirective>
                          );

                        case EMPLOYEE_COUNT:
                          return (
                            <ColumnDirective
                              key={item.field}
                              template={this.employeeFuncSection}
                              textAlign={`${item.align} !important`}
                              field={item.field}
                              headerText={item.colName}
                              width={item.width}
                              filter={this.check}
                              type={"number"}
                            >
                            </ColumnDirective>
                          );
                        case ACTION:
                          return (
                            <ColumnDirective
                              key={item.field}
                              template={this.actionTemplate}
                              textAlign={`${item.align} !important`}
                              field={item.field}
                              headerText={item.colName}
                              width={item.width}
                              filter={false}
                            >
                            </ColumnDirective>
                          );
                        default:
                          return (
                            <ColumnDirective
                              key={item.field}
                              // template={this.hierarchyFuncSection}
                              textAlign={`${item.align} !important`}
                              field={item.field}
                              headerText={item.colName}
                              width={item.width}
                              filter={this.check}
                            >
                            </ColumnDirective>
                          );


                      }
                    })}</ColumnsDirective>
                  <AggregatesDirective>
                    <AggregateDirective>
                      <AggregateColumnsDirective>
                        <AggregateColumnDirective field='empCnt' type='Sum' groupCaptionTemplate={this.groupcFootertMax}> </AggregateColumnDirective>
                      </AggregateColumnsDirective>
                    </AggregateDirective>
                  </AggregatesDirective>
                  <Inject services={[Filter, Group, Aggregate]} />
                </GridComponent>

                <div className="loader-new" id="spinnerContainer" >
                  <Progressbar />
                </div>

              </div>
            </div>
          </div></>
          :
          <div className="control-section col-md-12" style={{ height: 75 }}>
            {this.state.isLoading && (<div className="loader-new">

            </div>)
            }
            {(result.length === 0 && isLoading === false) && (
              <ErrorMessage message={businessUnitId ? "Loading" : "Please select Business Unit / Group Function. "} />
            )}
          </div>
        }
        {openPopup && (
          <OrgchartPopUp
            closePopup={this.closePopup}
            component="LayerMapping"
            title={this.title}
            type={this.type}
            layerMappingData={this.layerMappingData}
            layerMappingParentData={this.state.layerMappingParentData}
            maxHierarchy={maxHierarchy}
            layerMappingMasterData={this.state.layerMappingMasterData}
          />
        )}
      </div>
    );
  }
}
const mapStateToProps = ({ adminreducer, auth }) => {
  const { layerMappingFlag } = adminreducer;
  const { authUser } = auth;

  return { layerMappingFlag, authUser };
};

export default connect(mapStateToProps, {
  updateAdminReduxData,
  addEditLayerMappingFunction,
})(Layermapping);
