/**
 * Author: Vivek Khodade.
 * Last Modify:08-May-2020
 * Modified By:Vivek Khodade
 */
 import {
    SAVE_CANDIDATE_DATA,
    INITIATE_AND_REVOKE_All_CANDIDATE,
    // SAVE_BASIC_INFORMATION,
    SAVE_BASIC_INFORMATION_REDUX,
    UPDATE_DOCUMENT_INFO,
    Add_UPDATE_BASIC_INFO,
    GENERATE_APPOINTMENT_LETTER,UPDATE_STATUS_ONBOARDING
} from '../../constant/entryexit/onboardingconstant';
const INIT_STATE = {
    empid: 0,
    candidateid: 0,
    salutation: '',
    firstName: '',
    middleName: '',
    lastName: '',
    gender: '',
    mobileNumber: '',
    alternetNumber: '',
    emailId: '',
    panNumber: '',
    adharNumber: '',
    uanNumber: '',
    highestEducationQualification: '',
    highestEducationQualificationSpecial: '',
    workExperiance: 0,
    title: '',
    role: '',
    band: '',
    empType: '',
    baseCounty: '',
    baseLocation: '',
    entity: '',
    reportingSupervisor: '',
    recruitmentManagerId: 0,
    costSharing: 0,
    address: '',
    // dateOfBirth: Date.parse(addDays(new Date(), - 6570)) / 1000,
    // readyToJoinDate: Date.parse(new Date()) / 1000,
    dateOfBirth: null,
    readyToJoinDate: null,
    openDocumentPopup: false,
    open_document_preview: false,
    openDocumentComments: false,
    docRejectionParams: [],
    send_links: [],
    refreshDocStatus: "",    
    refresh_send_link_status: "",    
    doc_status: "",        
    imageName:'',
    basicInformation:{},
    servceDivisionSalesUnit:'',
    status:'',
    roleName:'',
    onboardStatus:'',
    tobeofferStatus:'',
    offerStatus:'',
    apppintmentLetterStatus:'',
    salesUnitData:[],
    reportingToData:[],
    appointmentLetterFlag:false,
    address2:'',
    city:'',
    pincode:'',
    state:'',
    isDocsSubmitted:false,
    functionalAssetStatusInt:'',
    functionalAssetStatusRevo:'',
    other:'',
    persCountryCode:'',
    altCountryCode:'',
    revokeStatus:'',
    lta:'',
    grossPay:0,
    countryType:"",
    functionId:"",
    legalName:"",
    refreshNewDoc:"",
    sourceCategory:"",
    jobPortalId:null,
    referralId:null,
    agencyName:"",
    tctc:null,
    oti:null,
    fixedCTC:null,
    layerId:null,
    subLayerId: null,
    addressCountryId: null,
    tenureEndDate: null,
    currencyId: null,
    ctcFrequency: null,
    expand:true,    
	docLinkForExternal:"Y",
    recruiterName:  "",
    referralName: "",
    reportingSup: "",
    subFunction: null,
    fromOffer: false
}
// eslint-disable-next-line
export default (state = INIT_STATE, action) => { // NOSONAR
    switch (action.type) {
        case SAVE_CANDIDATE_DATA:
           case INITIATE_AND_REVOKE_All_CANDIDATE:
               case SAVE_BASIC_INFORMATION_REDUX:
                   case UPDATE_DOCUMENT_INFO:
                       case Add_UPDATE_BASIC_INFO:
                           case GENERATE_APPOINTMENT_LETTER:
                               case UPDATE_STATUS_ONBOARDING: {
                                return {
                                    ...state,
                                    ...action.payload
                                }
        }
        default:
            return state;
    }
}
