import React, { useState, useEffect, useRef } from 'react';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  DetailRow,
  Inject,
  // Page,
  InfiniteScroll,
  Filter,
  Sort,
} from "@syncfusion/ej2-react-grids";
import {
  getGridColumn,
  COMMON_DOC_ICON,
  COMMON_DOC_UPLOADED_DATE,
  COMMON_DOC_ACTION,
  COMMON_DOC_FILE_NAME
} from "../../app/other/gridcolumns";
import noFormatIcon from "../../../src/assets/png/png/big/no-format-icon.png";
import excelIcon from "../../../src/assets/png/png/big/excel-icon.png";
import docIcon from "../../../src/assets/png/png/big/docx-icon.png";
import imageIcon from "../../../src/assets/png/png/big/image-icon.png";
import pptIcon from "../../../src/assets/png/png/big/ppt-icon.png";
import pdfIcon from "../../../src/assets/png/png/big/pdf-file-icon.png";
import textIcon from "../../../src/assets/png/png/big/text-file-icon.png";
import zipIcon from "../../../src/assets/png/png/big/zipfile-icon.png";
import deleteIcon from "../../../src/assets/png/png/big/delete-icon.png";
import NewConfirmDialog from '../confirmdialog/newconfirmdialog';
import dateFnsUtils from '@date-io/date-fns';
import { urlConfig } from '../../config/config';
import '../objective/tags.css';

const COMMONFILESCSS = `
.e-grid {
  border-bottom: none;
}

.e-headercontent .e-table {
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: none;
}

.e-gridcontent .e-content .e-table {
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #F2F2F2;
}

.e-headertext {
  color: #939399;
  font-size: .9rem;
  font-weight: 500;
}

tr.e-columnheader {
  height: 2.5rem !important;
}


`;

function OKRCommonFilesGridView(props) {
  const { attachmentFiles, filesTimeStamp, openDeletePopup, deletePopup, tabType } = props;
  const [okrCommonFilesData, setOkrCommonFilesData] = useState([]);
  const [imageFilesData, setImageFilesData] = useState(["txt", "pdf", "jpeg", "JPEG", "jpg", "JPG", "png", "PNG", "svg", "SVG", "EPS", "eps", "GIF", "gif", "BMP", "bmp", "TIF", "tif", "TIFF", "tiff"]);
  const [gridHeight, setGridHeight] = useState(null);
  const [rowHeight, setRowHeight] = useState(null);

  const gridInstance = useRef();

  useEffect(() => {
    if (window.innerHeight < 650) {
      setGridHeight((window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight) / (2.04))

      setRowHeight(20);
    } else {
      if (tabType === "OKRRating") {
        setGridHeight((window.innerHeight ||
          document.documentElement.clientHeight ||
          document.body.clientHeight) / (2.00))
      } else {
        setGridHeight((window.innerHeight ||
          document.documentElement.clientHeight ||
          document.body.clientHeight) / (1.76))
      }

      setRowHeight(44.9);
    }

    setOkrCommonFilesData([]);

    const commonFilesData = [];
    
    if (attachmentFiles) {
      attachmentFiles.forEach((file, index) => {
        const commonFile = {};
        commonFile.id = index;
        commonFile.attachedFile = file;
        commonFile.timeStamp = filesTimeStamp[index];
        commonFilesData.push(commonFile);
      })
    }

    setTimeout(() => {
      setOkrCommonFilesData(commonFilesData);
    }, 500);

  }, [attachmentFiles.length]);

  const dataBound = () => {
    gridInstance.current.hideScroll();
  };

  const getFileExtension = (filename) => {
    let ext = filename.split('.').pop().toLowerCase();
    if (ext === filename) return "";
    return ext;
  }

  const getFileIcon = (Files) => {
    let fileFormatIcon;
    let fileType = getFileExtension(Files)
    switch (fileType) {
      case 'txt':
        fileFormatIcon = textIcon
        break;
      case 'csv':
      case 'xlsx':
      case 'xls':
        fileFormatIcon = excelIcon
        break;
      case 'pdf':
        fileFormatIcon = pdfIcon
        break;
      case 'ppt':
      case 'pptx':
        fileFormatIcon = pptIcon
        break;
      case 'zip':
        fileFormatIcon = zipIcon
        break;
      case 'doc':
      case 'docx':
        fileFormatIcon = docIcon
        break;
      case 'jpeg':
      case 'JPEG':
      case 'jpg':
      case 'JPG':
      case 'png':
      case 'PNG':
      case 'svg':
      case 'SVG':
      case 'EPS':
      case 'eps':
      case 'GIF':
      case 'gif':
      case 'GIF':
      case 'BMP':
      case 'bmp':
      case 'TIF':
      case 'tif':
      case 'TIFF':
      case 'tiff':
        fileFormatIcon = imageIcon
        break;
      default:
        fileFormatIcon = noFormatIcon
        break;
    }
    return fileFormatIcon
  }

  const displayTime = (time) => {    
    const dateFns = new dateFnsUtils();
    return dateFns.format(new Date(time), "dd-MMM-yyyy HH:mm");
  }

  const commonDocIcon = (props) => {
    return (
      <div className="image-container-grid-view"><img alt="" src={getFileIcon(props.attachedFile)} /></div>
    );
  }
  const commonDocFileName = (fileProps) => {
    const { attachedFile } = fileProps; // tooltiptext-grid-view filename-text-grid-view
    return (
      <div className="filename-container">
        <a target={imageFilesData.includes(getFileExtension(attachedFile)) ? "_blank" : null} href={`${urlConfig.tmlcApiUrl}${props.url}/${attachedFile}`}
          style={{ textDecoration: 'none' }} rel="noopener noreferrer" download>
          <span className="filename-text-grid-view" style={{ color: "#434358", fontSize: ".9rem" }}>
            {attachedFile.substring(11, attachedFile.length).length > 170 ?
              attachedFile.substring(11, attachedFile.length).substr(0, 170) + '...' :
              attachedFile.substring(11, attachedFile.length)
            }
            {/* <span>{attachedFile.substring(11, attachedFile.length)}</span> */}
          </span>
        </a>
      </div>
    );
  }
  const commonDocUploadedDate = (props) => {
    if (props.timeStamp) {
      return (
        <div className="date-time-container" style={{ fontSize: ".8rem" }}>{displayTime(props.timeStamp)}</div>
        // <div className="date-time-container" style={{ fontSize: ".8rem" }}>{props.timeStamp}</div>
      );
    } else {
      return <></>;
    }
  }
  const commonDocAction = (actionProp) => {
    if (props.authUser === props.docUploadedBy[actionProp.id]) {
      return (
        <div
          className="delete-icon-container"
          onClick={() => openDeletePopup(actionProp.id)}
        >
          <img alt="" src={deleteIcon} />
        </div>
      );
    } else {
      return <></>;
    }
  }

  return (
    <div className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ padding: 0 }}>
      <style>{COMMONFILESCSS}</style>
      <GridComponent
        id="team-ranking-grid"
        rowHeight={rowHeight}
        allowTextWrap={true}
        height={gridHeight}
        ref={gridInstance}
        dataBound={dataBound}
        pageSettings={{
          pageSize: 11
        }}

        dataSource={okrCommonFilesData}
        enableHover={false}
      >
        {okrCommonFilesData && <ColumnsDirective>
          {getGridColumn("okrCommonDocsGridView").map((item) => {
            if (item.colName === COMMON_DOC_ICON) {
              return (
                <ColumnDirective
                  key={item.field}
                  template={commonDocIcon}
                  textAlign={item.align}
                  field={item.field}
                  headerText={item.colName}
                  width={item.width}
                // filter={this.check}
                />
              );
            } else if (item.colName === COMMON_DOC_FILE_NAME) {
              return (
                <ColumnDirective
                  key={item.field}
                  template={commonDocFileName}
                  textAlign={item.align}
                  field={item.field}
                  headerText={item.colName}
                  width={item.width}
                // filter={this.check}
                />
              );
            } else if (item.colName === COMMON_DOC_UPLOADED_DATE) {
              return (
                <ColumnDirective
                  key={item.field}
                  template={commonDocUploadedDate}
                  textAlign={item.align}
                  field={item.field}
                  headerText={item.colName}
                  width={item.width}
                // filter={this.check}
                />
              );
            } else if (item.colName === COMMON_DOC_ACTION) {
              return (
                <ColumnDirective
                  key={item.field}
                  template={commonDocAction}
                  textAlign={item.align}
                  field={item.field}
                  headerText={item.colName}
                  width={item.width}
                // filter={this.check}
                />
              );
            } else {
              return (
                <ColumnDirective
                  key={item.field}
                  textAlign={item.align}
                  field={item.field}
                  headerText={item.colName}
                  width={item.width}
                // filter={this.check}
                />
              );
            }
          }
          )}
        </ColumnsDirective>}
        <Inject services={[DetailRow, Filter, Sort, InfiniteScroll]} />
      </GridComponent>

      <NewConfirmDialog
        open={deletePopup}
        handleConfirmClose={() => props.handleConfirmClose()}
        title={"Delete"}
        message={"Are you sure you want to delete the document?"}
        handleConfirmYes={() => props.removeFile(props.deleteIdx)}
        button1Caption={"Delete"}
        handleConfirmNo={() => props.handleConfirmNo()}
        button2Caption={"Cancel"}
      />
    </div>
  )
}

export default OKRCommonFilesGridView;