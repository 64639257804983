
import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from "react-router-dom";
import queryString from "query-string";

const breadcrumb = {
    backgroundColor: '#F3F3F6',
    color: '#606069 !important',
    margin: '0px',
    paddingBottom: '10px',
}
function AdminBreadcrumb(props) {
    const [activeCrumb, setActiveCrumb] = useState("My Team");
    const buttonRef = useRef();
    const history = useHistory();

    const selected = (item) => {
        try {
            const params = queryString.parse(props.location.search);            
            if (typeof params.id !== 'undefined' && params.id !== null) {
                switch (item.Type) {
                    case "Parent":
                        history.push(`${props.location.pathname}?id=${params.id}&appId=${params.appId}`); break;
                    case "Admin":
                        history.push(`${props.location.pathname}?id=${params.id}&appId=${params.appId}&adminId=${params.adminId}`); break;
                    case "Master":
                }
            }
        } catch (error) {
            console.log("🚀 ~ file: adminmodule.jsx ~ line 41 ~ openAdminModule ~ error", error)
        }

    }

    const crumbFunction = (crumb) => {
        return "performance-potential-activeBlack"
    }

    return (
        <nav className="row justify-content-center" style={{marginLeft: '0px'}}>
        {/* , marginBottom: '-20px' */}
            <ol className="breadcrumb" style={{breadcrumb, backgroundColor: 'white'}}>
                {
                    props.bedcrums.map((item, ci) => {
                        return (
                            <li
                                key={ci}
                                className="breadcrumb-item align-items-center"
                            >
                                <button
                                    id={`crumb-${ci + 1}`}
                                    className={`greyDisable ${activeCrumb === props.bedcrums[props.bedcrums.length - 1].crumb ? crumbFunction(item.crumb) : ""}`} onClick={() => selected(item)}
                                    ref={buttonRef}
                                    style={{border:'none'}}
                                >
                                    {item.crumb}
                                </button>
                            </li>
                        );
                    })
                }
            </ol>
        </nav>
    );
}

const mapStateToProps = ({ router }) => {
    const { location } = router;
    return { location }
};
export default connect(mapStateToProps, {})(
    AdminBreadcrumb
);
