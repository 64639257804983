/**
 * Author: prashant Waphare.
 * Created:21-09-2020
 */
import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import axios from "axios";
import {
  UPDATE_ORGCHART_LIST, ADD_EDIT_COST_SHARING, ADD_EDIT_OTHER_CALENDAR_ACCESS, MOVE_TO_ACTIVE
} from "../../constant/entryexit/separationconstant";
import {
  ADD_NEW_ORGCHART_MASTER_DATA
} from "../../constant/entryexit/orgchartconstant";
import { setOrhChartDetaila } from "../../actions/entryexit/orgchartaction";
import { urlConfig } from "../../../config/config";
import { getNotify } from "../../../app/other/commonfunction";
import { ERROR, SUCCESS } from "../../../assets/constants/constants";

// Add by Vivek Khodade
const addNewMasterOrgChartDetailsRequest = async (data) => {
  if (data.type === 'level2') {
    return axios
      .post(`${urlConfig.tmlcApiUrl}addnewmasterorgchartrecordlevel2`, data, {
        headers: {
          authorization: localStorage.getItem("jwtToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((result) => result)
      .catch((error) => error.response);
  }
  if (data.type === 'level3') {
    return axios
      .post(`${urlConfig.tmlcApiUrl}addnewmasterorgchartrecordlevel3`, data, {
        headers: {
          authorization: localStorage.getItem("jwtToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((result) => result)
      .catch((error) => error.response);
  }
  if (data.type === 'level4') {
    return axios
      .post(`${urlConfig.tmlcApiUrl}addnewmasterorgchartrecordlevel4`, data, {
        headers: {
          authorization: localStorage.getItem("jwtToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((result) => result)
      .catch((error) => error.response);

  }
  if (data.type === 'level5') {
    return axios
      .post(`${urlConfig.tmlcApiUrl}addnewmasterorgchartrecordlevel6`, data, {
        headers: {
          authorization: localStorage.getItem("jwtToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      })
      .then((result) => result)
      .catch((error) => error.response);
  }
};
const callUpdateOrgChartApi = async (data) => {
  return axios
    .post(`${urlConfig.tmlcApiUrl}orgchartupdatedetails`, data, {
      headers: {
        authorization: localStorage.getItem("jwtToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((result) => result)
    .catch((error) => error.response);
};
//  Prashant Waphare | 29-09-2020 | add edit cost sharing details
const calladdEditCostSharing = async (data) => {
  return axios
    .post(`${urlConfig.tmlcApiUrl}addeditorgchartsharing`, data, {
      headers: {
        authorization: localStorage.getItem("jwtToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((result) => result)
    .catch((error) => error.response);
};
//added by Vivek khodade
function* addNewMasterOrgChartDetails({ payload }) {
  const data = yield call(addNewMasterOrgChartDetailsRequest, payload);
  if (data.status === 200) {
    setTimeout(() => {
      getNotify(SUCCESS, "New org-chart details added successfully.");
    }, 100);
    // yield put(setOrhChartDetaila({ orgchartupdateStatus: 'update' }))
  } else {
    getNotify(ERROR, data.data.message.replace("payload: ", ""));
  }
}
//added by Nookesh
const addEditOtherCalendarAccessReq = async (data) => {
  return axios
    .post(`${urlConfig.apiurl}othercalendaraccess/${data.accessibleBy}`, data, {
      headers: {
        authorization: localStorage.getItem("jwtToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((result) => result)
    .catch((error) => error.response);
};
function* addEditOtherCalendarAccess({ payload }) {
  const data = yield call(addEditOtherCalendarAccessReq, payload);

  if (data.status === 200) {
    setTimeout(() => {
      const msg = payload.type === "DELETE" ? "User Removed succesfully." : "User Access updated succesfully."
      getNotify(SUCCESS, msg);
    }, 100);
    // yield put(setOrhChartDetaila({ orgchartupdateStatus: 'update' }))
  } else {
    getNotify(ERROR, data.data.message.replace("payload: ", ""));
  }
}

function* updateOrgChartDetailsAction({ payload }) {
  const typeOfAction = payload.methodType
  delete payload.methodType;
  const data = yield call(callUpdateOrgChartApi, payload);
  const employee = JSON.parse(data.config.data);
  if (data.status === 200) {
    if (data.data.result && data.data.result.message === 'Success') {
      setTimeout(() => {
        if (payload.employeeStatus === "0") {
          getNotify(SUCCESS, "Team member moved to left successfully.");
        } else {
          getNotify(SUCCESS, "Team member details updated successfully.");
        }
      }, 100);
      yield put(setOrhChartDetaila({ orgchartupdateStatus: 'update', updatedEmployee: employee.employeeId, teamMemberUpdate: (typeOfAction === "employeeedit" ? "updated" : "") }))
    }
    // else{
    if (data.data.result.message === 'subordinatesExists') {
      getNotify(ERROR, "Left date can't be set as one or more active team members are reporting to this User");
    }
    // }
  } else {
    getNotify(ERROR, data.data.message.replace("payload: ", ""));
  }
}
//  Prashant Waphare | 29-09-2020 | add edit cost sharing details
function* addEditCostSharingAction({ payload }) {
  const data = yield call(calladdEditCostSharing, payload);
  if (data.status === 200) {
    setTimeout(() => {
      getNotify(SUCCESS, "Cost sharing details updated successfully.");
    }, 100);
    yield put(setOrhChartDetaila({ orgchartupdateStatus: 'update' }))
  } else {
    getNotify(ERROR, data.data.message.replace("payload: ", ""));
  }
}
const callMoveLeftEmployeeApi = async (data) => {
  return axios
    .post(`${urlConfig.tmlcApiUrl}movetoactive`, data, {
      headers: {
        authorization: localStorage.getItem("jwtToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((result) => result)
    .catch((error) => error.response);
};
function* moveLeftEmployeeToActiveAction({ payload }) {
  const data = yield call(callMoveLeftEmployeeApi, payload);
  if (data.status === 200) {
    setTimeout(() => {
      getNotify(SUCCESS, "Team member moved to active successfully.");
    }, 100);
  } else {
    getNotify(ERROR, data.data.message.replace("payload: ", ""));
  }
}
/**
 * @author Vivek khodade
 * @description to add new master record in orgchat master.
 */
export function* addNewMasterOrgChartSaga() {
  yield takeEvery(
    ADD_NEW_ORGCHART_MASTER_DATA,
    addNewMasterOrgChartDetails
  );
}
export function* updateOrgChartDetails() {
  yield takeEvery(
    UPDATE_ORGCHART_LIST,
    updateOrgChartDetailsAction
  );
}
//  Prashant Waphare | 29-09-2020 | add edit cost sharing details
export function* addEditCostSharingSaga() {
  yield takeEvery(
    ADD_EDIT_COST_SHARING,
    addEditCostSharingAction
  );
}
export function* moveLeftEmployee() {
  yield takeEvery(
    MOVE_TO_ACTIVE,
    moveLeftEmployeeToActiveAction
  );
}
//  Nookesh|08-03-2022 | add Other Calendar Access
export function* addEditOtherCalendarAccessSaga() {
  yield takeEvery(
    ADD_EDIT_OTHER_CALENDAR_ACCESS,
    addEditOtherCalendarAccess
  );
}

export default function* rootSaga() {
  yield all([fork(updateOrgChartDetails),
  fork(addEditCostSharingSaga),
  fork(addNewMasterOrgChartSaga),
  fork(moveLeftEmployee),
  fork(addEditOtherCalendarAccessSaga)
  ]);
}
