import React, { Component } from 'react'
import NotificationContainer from "../../../components/notification/notifycontainer";
import BusinessFunction from '../../../components/separation/orgchartadmin/businessfunction';
import LayerMaster from '../../../components/separation/orgchartadmin/layermaster';
import PracticeArea from '../../../components/separation/orgchartadmin/practicearea';
import SubBusinessFunction from '../../../components/separation/orgchartadmin/subbusinessfunction';
import SublayerMaster from '../../../components/separation/orgchartadmin/sublayermaster';
import UserRoleMaster from '../../../components/separation/orgchartadmin/userrolemaster';
import FolderManagement from '../../../components/separation/orgchartadmin/foldermanagement';
import '../tmlc/onboarding/onboarding.css'
import '../../../components/okrproductiveallocation/productiveallocation.css';

class OrgChartMaster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      height: 182,
      bussClicked: true,
      subClicked: false,
      practiceAreaClicked: false,
      roleMasterClicked: false,
      layerClicked: false,
      subLayerClicked: false,
      folderManageClicked: false
    };
    this.openTab = this.openTab.bind(this)
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
    try {
      this.setState({ isLoaded: true }, () => { document.getElementById("BusinessFunction").style.display = "block" })

    } catch (error) {
      console.log(error, 'error')
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));

  }

  updateDimensions() {

    let update_height = window.innerHeight - 100;
    this.setState({ height: update_height });
  }

  openTab = (evt, tabName) => {
    let i;
    const event = evt;
    const tabcontent = document.getElementsByClassName("tabcontent");
    // eslint-disable-next-line
    if (tabName === "SubBusinessFunction") {
      this.setState(prev => ({
        subClicked: true, practiceAreaClicked: false, roleMasterClicked: false, layerClicked: false, bussClicked: false,
        subLayerClicked: false, folderManageClicked: false
      }));
    }
    else if (tabName === "PracticeArea") {
      this.setState(prev => ({
        subClicked: false, practiceAreaClicked: true, roleMasterClicked: false, layerClicked: false, bussClicked: false,
        subLayerClicked: false, folderManageClicked: false
      }));
    }

    else if (tabName === "SublayerMaster") {
      this.setState(prev => ({
        subClicked: false, practiceAreaClicked: false, roleMasterClicked: false, layerClicked: false, bussClicked: false,
        subLayerClicked: true, folderManageClicked: false
      }));
    }
    else if (tabName === "LayerMaster") {
      this.setState({
        subClicked: false, practiceAreaClicked: false, roleMasterClicked: false, layerClicked: true, bussClicked: false,
        subLayerClicked: false, folderManageClicked: false
      });
    }
    else if (tabName === "UserRoleMaster") {
      this.setState(({
        roleMasterClicked: true, subClicked: false, practiceAreaClicked: false, layerClicked: false, bussClicked: false,
        subLayerClicked: false, folderManageClicked: false
      }))
    }
    else if (tabName === "BusinessFunction") {
      this.setState(({
        roleMasterClicked: false, subClicked: false, practiceAreaClicked: false, layerClicked: false, bussClicked: true,
        subLayerClicked: false, folderManageClicked: false
      }))
    }

    else if (tabName === "FolderManagement") {
      this.setState(({
        roleMasterClicked: false, subClicked: false, practiceAreaClicked: false, layerClicked: false, bussClicked: false,
        subLayerClicked: false, folderManageClicked: true
      }))
    }

    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }
    const tablinks = document.getElementsByClassName("tablinks");
    // eslint-disable-next-line
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }
    document.getElementById(tabName).style.display = "block";
    event.currentTarget.className += " active";

  };

  render() {
    return (
      <div
        className="col-12"
        style={{
          // paddingBottom: "16px",
          // paddingTop: "16px",
          // paddingLeft: "16px",
          // paddingRight: "16px",
          background: "#eee",
        }}
      >
        <div className="col-lg-12 col-md-12">
          <div className="col-12" >
            <>
              <div className="it-tab">
                <button className={'col-lg-2 onboardingtab onboarding-tab tablinks active onboarding-tab-height'}
                  onClick={(e) => this.openTab(e, "BusinessFunction")}
                >
                  <b>Business Unit / Group Function </b>
                </button>

                <button className={'col-lg-2 onboardingtab onboarding-tab tablinks onboarding-tab-height'}
                  onClick={(e) => this.openTab(e, "SubBusinessFunction")}
                >
                  <b> Sub Business Unit / Function </b>
                </button>

                <button className={'col-lg-2 onboardingtab onboarding-tab tablinks onboarding-tab-height'}
                  onClick={(e) => this.openTab(e, "PracticeArea")}
                >
                  <b> Practice Area / Sales Unit / Sub Function</b>
                </button>

                <button className={'col-lg-2 onboardingtab onboarding-tab tablinks onboarding-tab-height'}
                  onClick={(e) => this.openTab(e, "LayerMaster")}
                >
                  <b>Layer Master</b>
                </button>

                <button className={'col-lg-2 onboardingtab onboarding-tab tablinks onboarding-tab-height'}
                  onClick={(e) => this.openTab(e, "SublayerMaster")}
                >
                  <b>Sub Layer Master</b>
                </button>

                <button className={'col-lg-2 onboardingtab onboarding-tab tablinks onboarding-tab-height'}
                  onClick={(e) => this.openTab(e, "UserRoleMaster")}
                >
                  <b>Role / Title</b>
                </button>

                <button className={'col-lg-2 onboardingtab onboarding-tab tablinks onboarding-tab-height'}
                  onClick={(e) => this.openTab(e, "FolderManagement")}
                >
                  <b>Folder Management</b>
                </button>


              </div>

              <div id="BusinessFunction" className="tabcontent" >
                {this.state.bussClicked && <BusinessFunction />}
              </div>
              <div id="SubBusinessFunction" className="tabcontent">
                {this.state.subClicked && <SubBusinessFunction />}
              </div>
              <div id="PracticeArea" className="tabcontent" style={{padding:'6px 43px 6px 28px'}}>
                {this.state.practiceAreaClicked && <PracticeArea />}
              </div>
              <div id="LayerMaster" className="tabcontent">
                {this.state.layerClicked && <LayerMaster />}
              </div>
              <div id="SublayerMaster" className="tabcontent">
                {this.state.subLayerClicked && <SublayerMaster />}
              </div>

              <div id="UserRoleMaster" className="tabcontent">
                {this.state.roleMasterClicked && <UserRoleMaster />}
              </div>

              <div id="FolderManagement" className="tabcontent">
                {this.state.folderManageClicked && <FolderManagement />}
              </div>
            </>
          </div>
          <NotificationContainer style={{ position: 'absolute' }} />
        </div>
      </div>

    )
  }
}

export default OrgChartMaster

