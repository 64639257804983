import axios from "axios";// NOSONAR
import { urlConfig } from "../config/config";

function errorHandler(reject, error) {
    // eslint-disable-next-line
    console.dir(error);
    reject("operation failed");
}
/** 
 * Author : Suresh Sargam
 * Date : 15-Dec-2022
 * @description Get the BRE Data for a module actio  like leave application ,transfer
 * @param {integer} pUserId - logged- in userId
 * @param {integer} pModuleActionId - module action Id
*/

// Get Master Roles from Mongo DB
function getBREData(pUserId, pModuleActionId, pObject) {
    return new Promise(function onThen(resolve, reject) {// NOSONAR
        axios
            .post(`${urlConfig.tmlcApiUrl}bre/${pUserId}/${pModuleActionId}`, { "objData": pObject }, {
                headers: {
                    'authorization': localStorage.getItem('jwtToken'),
                    'Accept': 'application/json',
                    "Content-Type": "application/json",
                },
            })
            .then(function (response) {
                if (response != null) {
                    var result
                    if (response.status === 200) {
                        result = response.data.result;
                    }
                    else {
                        console.log('Error while getting master roles')
                        return null;
                    }
                    return resolve(result);
                }
            })
            // eslint-disable-next-line
            .catch(function (error) {
                errorHandler(reject, error);
                return false;
            });
    });
}




const breservices = {
    getBREData
};

export default breservices;
