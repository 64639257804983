import React, { useEffect, useState } from 'react'
import { FormValidator } from "@syncfusion/ej2-inputs";
import { TextBoxComponent, } from "@syncfusion/ej2-react-inputs";
import { EnterRole } from '../../../../assets/constants/constants';


export default function UserRoleMasterPopup(props) {
  const [userRoleData, setuserRoleData] = useState({
    userRole: "",
    roleId: ""
  });

  useEffect(() => {
    let data
    if (props.type === 'Add') {
      data = {
        userRole: "",
        roleId: 0
      }
      setuserRoleData(data);
    }
    if (props.type === 'Edit') {
      const { Role, Id } = props.userRoleData;
  
      
        data = {
          userRole: Role,
        roleId: Id
      }
      setuserRoleData(data);
    }
    // eslint-disable-next-line
  }, [])

  const userRoleFunction = (event) => {
    let options = {}
    userRoleData.userRole = event.value;
    setuserRoleData(userRoleData);
    options = {
      rules: {
        userRoleFunctionErr: {
          required: [true, EnterRole],
        }
      },
    };

    setTimeout(() => {
      const addFormObject = new FormValidator("#userRoleForm", options);
      addFormObject.validate();
    }, 100);
  }
  props.parentClassFunction(userRoleData);

  return (
    <div>
      <form id="userRoleForm">
        <div className="form-row" >
          <div className="col-md-9">
            <TextBoxComponent
              id="userRoleFunction"
              placeholder="Role / Title*"
              floatLabelType="Auto"
              width="500px"
              value={userRoleData.userRole}
              data-msg-containerid="userRoleFunctionErr"
              name="userRoleFunctionErr"
              cssClass="e-outline"
              onChange={(e) => userRoleFunction(e)}
              htmlAttributes={{
                maxlength: 100,
                databoundary: "scrollParent"
              }}
            />
            <div
              id="userRoleFunctionErr"
              className="e-input-group e-float-icon-left error-div"
            />
          </div>

        </div>
      </form>
    </div>
  );
}