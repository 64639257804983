import React from 'react'

const DisabledEffect = ({style, height, width, cssClass=""}) => {
    return (
        <div
            className={'grid-disabled-screen ' + cssClass}
            style={{...style, height, width}}>
        </div>
    )
}

export default DisabledEffect