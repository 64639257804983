import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PerformanceCardView from './performancercardview';
import XYAxisGridView from './xyaxisgridview';
import XYAxisDefaultGridView from './xyaxisdefaultgridview';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import { faGripHorizontal, faBars, faLongArrowAltRight, faObjecti } from "@fortawesome/free-solid-svg-icons";
import { faObjectsColumn} from "@fortawesome/pro-light-svg-icons";

function XYAxis(props) {
  const [clickFrom, setClickFrom] = useState("cardsView");

  useEffect(() => {
    if (props.isPeopleLeader && props.viewCard === 0) {
      setClickFrom("defaultGridView");
    }

    if (props.isPeopleLeader && props.viewCard === 1) {
      setClickFrom("cardsView");
    }
  }, [props.viewCard]);

  const openTab = (tabName) => {
    if (tabName === "defaultGridView") {
      setClickFrom("defaultGridView");
    }
    if (tabName === "cardsView") {
      setClickFrom("cardsView");
    }

    if (tabName === "gridView") {
      setClickFrom("gridView");
    }
  }
  const getStyle = (type) => {
    const { showMonths } = props
    if (type === 'XYAXISGridView') {
      if (window.innerHeight === 625) {
        return { display: clickFrom === "gridView" ? "block" : "none", height: showMonths ? 356 : 343, overflowY: 'hidden', overflowX: 'hidden' }
      }
      if (window.innerHeight < 670) {
        return { display: clickFrom === "gridView" ? "block" : "none", overflow: 'hidden', height: showMonths ? 357 : 356 }
      } else {
        return { display: clickFrom === "gridView" ? "block" : "none", overflow: 'hidden' }
      }
    } else if (type === 'CardView') {
      if (window.innerHeight === 625 && showMonths) {
        return 357

      }
      if (window.innerHeight === 625 && !showMonths) {
        return 340

      }
      if (window.innerHeight === 657 && showMonths) {
        return 358
      }
      if (window.innerHeight === 657 && !showMonths) {
        return 356
      }
      if (window.innerHeight > 670) {
        return 539
      }

      // window.innerHeight===625?344:window.innerHeight < 670 ? 364:''}
    }

  }
  const getDefaultStyle = () => {
    const { showMonths } = props
    if (window.innerHeight === 625) {
      return { display: clickFrom === "defaultGridView" ? "block" : "none", height: showMonths ? 356 : 343, overflowY: 'hidden', overflowX: 'hidden' }
    }
    if (window.innerHeight < 670) {
      return { display: clickFrom === "defaultGridView" ? "block" : "none", height: showMonths ? 357 : 356, overflowY: 'hidden', overflowX: 'hidden' }
    } else {
      return { display: clickFrom === "defaultGridView" ? "block" : "none", overflowY: 'hidden', overflowX: 'hidden' }
    }
  }
  const getHeading = (type, tab) => {
    if (tab === 'defaultGridView' && type === 'direct-reportee') {
      return 'Direct Reportees Cumulative Score Details'
    } else if ((type === 'direct-reportee' && tab === 'gridView') || (type === 'direct-reportee' && tab === 'cardsView')) {
      return 'Direct Reportees Rankings'
    } else if (type === 'team-size' && tab === 'gridView') {
      return 'Team Member Rankings'
    } else if (type === 'team-size' && tab === 'defaultGridView') {
      return 'Team Member Cumulative Score Details'
    } else {
      return 'Team Member Rankings'
    }

  }
  const { xyAxixCardRankingData, teamStatusSelected } = props;
  return (
    <div className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-lg mr-md mb-md xy-axis-and-grid-view">
      <div className="row card-layout">
        <div className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 member-ranking-and-icon">
          <p className="team-member-ranking">
            {/* {teamStatusSelected==='direct-reportee' ? 'Direct Reportees':'Team Member'} Rankings */}
            {getHeading(teamStatusSelected, clickFrom)}

          </p>
          <div style={{ display: 'flex', flexDirection: 'row',marginTop:4}}>
            <div style={{ borderRight: '3px solid grey', margin: '-5px 14px 11px 0px' }}>
              <TooltipComponent
                closeOn="Click"
                content={teamStatusSelected === 'direct-reportee' ? 'Direct Reportees Cumulative Score Details' : 'Team Member Cumulative Score Details'}
                position="BottomCenter"
                isSticky={false}
                cssClass='customtooltip'
              >
                {/* eslint-disable-next-line */}
                <FontAwesomeIcon
                  icon={faObjectsColumn}
                  style={{ fontSize: "1.3em", color: clickFrom === "defaultGridView" ? "#242424" : "#BEBABA", marginRight: "1rem" ,marginTop:5}}
                  className="pointer-cursor"
                  onClick={() => openTab("defaultGridView")}
                />
              </TooltipComponent>

            </div>
            <div>
              <TooltipComponent
                closeOn="Click"
                content={teamStatusSelected === 'direct-reportee' ? 'Direct Reportees Rankings' : 'Team Member Rankings'}
                position="BottomCenter"
                isSticky={false}
                cssClass='customtooltip'
              >
                {/* eslint-disable-next-line */}
                <FontAwesomeIcon
                  icon={faGripHorizontal}
                  style={{ fontSize: "1.7em", color: clickFrom === "cardsView" ? "#242424" : "#BEBABA", marginRight: "1rem",marginTop: "-0.1rem"  }}
                  className="pointer-cursor"
                  onClick={() => openTab("cardsView")}
                />
              </TooltipComponent>

            </div>
            <div>
              <TooltipComponent
                closeOn="Click"
                content={teamStatusSelected === 'direct-reportee' ? 'Direct Reportees Rankings' : 'Team Member Rankings'}
                position="BottomCenter"
                isSticky={false}
                cssClass='customtooltip'
              >
                {/* eslint-disable-next-line */}
                <FontAwesomeIcon
                  icon={faBars}
                  style={{ fontSize: "1.4em", color: clickFrom === "gridView" ? "#242424" : "#BEBABA" }}
                  className="pointer-cursor"
                  onClick={() => openTab("gridView")}
                />
              </TooltipComponent>

            </div>

          </div>
        </div>
      </div>

      <div className="row card-layout">
        <div className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12" id="defaultGridView" style={getDefaultStyle()}>
          <XYAxisDefaultGridView showMonths={props.showMonths} />
        </div>
        <div className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12" id="cardsView" style={{ display: clickFrom === "cardsView" ? "block" : "none", overflowY: 'hidden', height: getStyle('CardView') }}>
          <div className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 performance-card-view"/**performance-card-view-margin */>
            {xyAxixCardRankingData?.map((item) => {
              return <PerformanceCardView key={item.scoreUid} title={item.boxClassification} rating={item.empCount} primaryColor={item.primaryColor} secondaryColor={item.secondaryColor} openTab={openTab} />
            })}
          </div>
          <p className="y-axis-text">Y Axis - Potential <span className="arrow-up-icon"><FontAwesomeIcon icon={faLongArrowAltRight} /></span> </p>
          <p className="x-axis-text">X Axis - Performance <span className="arrow-right-icon"><FontAwesomeIcon icon={faLongArrowAltRight} /></span></p>
        </div>
        <div className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12" id="gridView" style={getStyle('XYAXISGridView')}>
          <XYAxisGridView showMonths={props.showMonths} />
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = ({ auth, okrratingreducer, okrreducer, meetingmaster, meetingcategory, okrdashboardreducer }) => {
  const { authUser } = auth;
  const { empId, fyId, monthOfRating, supervisorUsers, selfUser, TPUsers, refreshRatingDataFlag, reviewUsers,
    ratingStatusData, ratingCategoryStatus, ratingTabs } = okrratingreducer;
  const { categoryName } = meetingcategory;
  const { OkrMasters } = okrreducer;
  const { finacialYearData } = meetingmaster;
  const { xyAxixCardRankingData, teamStatusSelected, isPeopleLeader, viewCard } = okrdashboardreducer;
  return {
    authUser, empId, fyId, monthOfRating, OkrMasters, finacialYearData, supervisorUsers, selfUser, TPUsers, categoryName, refreshRatingDataFlag, reviewUsers,
    ratingStatusData, ratingCategoryStatus, ratingTabs, xyAxixCardRankingData, teamStatusSelected, isPeopleLeader, viewCard
  }
};
export default connect(mapStateToProps, {})(
  XYAxis
);

