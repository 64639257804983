/* Author : Prashant Waphare
 * Date : 02-05-2020
 * Description: This file use for exit clearance (Functional clearance).
 */
import * as React from "react";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Filter,
  Inject,
  VirtualScroll,
  Sort,
  Page,
} from "@syncfusion/ej2-react-grids";
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import "../../../../src/app/containers/tmlc/tmlc.css";
import "../../../../src/components/separation/resignationcomponent/resignationcomponent.css"

import { connect } from "react-redux";
import { Redirect } from "react-router-dom";

import TextField from "@material-ui/core/TextField";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  getNotify,
  encrypt,
  // decodeString,
  encodeString
} from "../../../app/other/commonfunction";

import EmployeeNameTemplate from "../employeeNameTemplate";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {
  getGridColumn,
  EMPLOYEE_NAME,
  EMPLOYEE_STATUS,
  JOINING_DATE,
  DATE_OF_INITIATE,
  ADMIN,
  IT,
  INFOSEC,
  FINANCE,
  HR,
  EXIT_CLEARANCE_ACTION,
  LWD_COMPANY_REQUEST,
  SELF_DEPARTMENT,
  // REASON,
  SOFTWARE,
  LEGAL_ASSET,
  MIS_ASSET,
  CONTACT_ASSET,
  LWD_OFFICIAL,
  NOTICE_PERIOD
} from "../../../app/other/gridcolumns";
import { AllDateFormat, AllClearanceStatus } from "../allcommoncomponents";
import { setMeetingCategory } from '../../../redux/actions/meetingcategoryaction';
import { EXIT_CLEARANCE_FUNCTION, EXITCLEARANCEGRID } from '../../../redux/constant/meetingcategoryconstant';
import { addUpdateSeparationDetails, submiteLWDResignationCompanyrequestandNPS, submiteExitClearanceData } from '../../../redux/actions/entryexit/separationaction';
import { library } from '@fortawesome/fontawesome-svg-core';
import { DateFilterformat } from '../../../../src/config/config';
import { faArrowAltToTop, faEye, faPlayCircle, faLongArrowAltRight, faArrowAltRight, faClipboardCheck, faPersonCarry } from '@fortawesome/pro-light-svg-icons'
import {
  DialogTitleTMLC,
  DialogContent,
  DialogActions,
} from "../../customdialog/customdialog";
import { Dialog, Button } from "@material-ui/core";
import separetionServices from "../../../services/entryexit/separationServices";
import { WARNING
   , LeftEmpMessage 
} from "../../../assets/constants/constants";
import ConfirmationPopup from "../../../utilities/confirmationpopup";

library.add(faArrowAltToTop, faEye, faPlayCircle, faLongArrowAltRight, faArrowAltRight, faClipboardCheck, faPersonCarry);

class ExitClearanceFunctionalGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: null,
      actionState: true,
      openCommunication: false,
      openCommunicationPreview: false,
      getLWDCompanyRequestDate: Date.parse(new Date()) / 1000,
      status: "",
      changeData: false,
      open: false,
      initiateEmployeeId: '',
      contEmployeeType: '',
      openLeftEmptPopup: false,
      leftDate: Date.parse(new Date()) / 1000
    };
    this.height =
      (window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight) - 188;
    this.width =
      (window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth) - 71;
    this.dReady = false;
    this.dtTime = false;
    this.isDataBound = false;
    this.isDataChanged = true;
    this.dropSlectedIndex = null;
    this.check = {
      type: "CheckBox",
    };
    this.select = {
      persistSelection: true,
      type: "Multiple",
      checkboxOnly: true,
    };
    this.Filter = {
      type: "Menu",
    };
    this.pageOptions = {
      pageSize:
        (window.innerHeight ||
          document.documentElement.clientHeight ||
          document.body.clientHeight) > 700
          ? 12
          : 7,
    };
    this.name = {
      type: "CheckBox",
    };
    this.curEmployeeName = '';
    this.employeeNameTemplate = this.employeeNameTemplate.bind(this);
    this.navigateToAddBasicInformation = this.navigateToAddBasicInformation.bind(
      this
    );
    this.handleLeftEmpConfirm = this.handleLeftEmpConfirm.bind(this);
    this.handleLeftEmpClose = this.handleLeftEmpClose.bind(this);

  }

  componentDidMount() {
    setTimeout(() => {
      const ele = document.getElementsByClassName("e-headercelldiv");
      // eslint-disable-next-line
      
      for(let i in ele) {
        if(!isNaN(parseInt(i, 10))){
          let index = parseInt(i, 10);
          if (
            ele[index].innerText === JOINING_DATE ||
            ele[index].innerText === DATE_OF_INITIATE
          ) {
            ele[index].classList.add("grid-center-header");
          } else {
            ele[index].classList.add("grid-left-header");
          }
        }
      }
    }, 1000);
  }

  onQueryCellInfoPreJoining = (args) => {
    const { cell } = args;
    cell.setAttribute(
      "style",
      "font-size:14px !important; text-align: left !important; padding-left:18px !important"
    );
  };

  onDataBound() {
    clearTimeout(this.clrIntervalFun);
    clearInterval(this.intervalFun);
    this.dtTime = true;
  }

  statusTemp = (props) => {
    const { clearanceStatus } = props;
    if (clearanceStatus === "Select All") {
      return <span>Select All</span>;
    }
    if (clearanceStatus === "Completed") {
      return (
        <div
          style={{ borderLeft: "3px solid green", padding: "7px 0px 8px 5px" }}
        >
          <span id="Trusttext">Completed</span>
          <br />
        </div>
      );
    }
    if (clearanceStatus === "Pending") {
      return (
        <div style={{ borderLeft: "3px solid gray", padding: "7px 0px 8px 5px" }}>
          <span>Pending</span>
        </div>
      );
    }
    if (clearanceStatus === "Partial") {
      return (
        <div
          style={{ borderLeft: "3px solid #f68104", padding: "7px 0px 8px 5px" }}
        >
          <span>Partial</span>
        </div>
      );
    }
    return false;
  }

  ownDeptTemp = (props) => {
    const {
      OwnDeptAssetsStatus,
      handOverOwnDeptAssets,
      totalOwnDeptAssets,
    } = props;
    return (
      <AllClearanceStatus asset={handOverOwnDeptAssets} totalAsset={totalOwnDeptAssets} assetStatus={OwnDeptAssetsStatus} />
    )
  }

  financeTemp = (props) => {
    const {
      financeAssetsStatus,
      handOverFinanceAssets,
      totalFinanceAssets,
    } = props;
    return (
      <AllClearanceStatus asset={handOverFinanceAssets} totalAsset={totalFinanceAssets} assetStatus={financeAssetsStatus} />
    )
  }
  hrTemp = (props) => {
    const {
      peopleAssetsStatus,
      handOverPeopleAssets,
      totalPeopleAssets,
    } = props;
    return (
      <AllClearanceStatus asset={handOverPeopleAssets} totalAsset={totalPeopleAssets} assetStatus={peopleAssetsStatus} />
    )
  }
  adminTemp = (props) => {
    const {
      handOverAdminAssets,
      totalAdminAssets,
      adminAssetsStatus,
    } = props;
    return (
      <AllClearanceStatus asset={handOverAdminAssets} totalAsset={totalAdminAssets} assetStatus={adminAssetsStatus} />
    )
  }

  itTemp = (props) => {
    const {
      itAssetsStatus,
      totalITAssets,
      handOverITAssets,
    } = props;
    return (
      <AllClearanceStatus asset={handOverITAssets} totalAsset={totalITAssets} assetStatus={itAssetsStatus} />
    )
  }
  infosecTemp = (props) => {
    const {
      infoSecAssetsStatus,
      handOverInfosecAssets,
      totalInfoSecAssets,
    } = props;
    return (
      <AllClearanceStatus asset={handOverInfosecAssets} totalAsset={totalInfoSecAssets} assetStatus={infoSecAssetsStatus} />
    )
  }
  softwareTemp = (props) => {
    const {
      softwareAssetsStatus,
      handOverSoftwareAssets,
      totalSoftwareAssets,
    } = props;
    return (
      <AllClearanceStatus asset={handOverSoftwareAssets} totalAsset={totalSoftwareAssets} assetStatus={softwareAssetsStatus} />
    )
  }
  legalTemp = (props) => {
    const {
      legaAssetsStatus,
      handOverLegalAssets,
      totalLegalAssets,
    } = props;
    return (
      <AllClearanceStatus asset={handOverLegalAssets} totalAsset={totalLegalAssets} assetStatus={legaAssetsStatus} />
    )
  }
  misTemp = (props) => {
    const {
      MISAssetsStatus,
      handOverMISAssets,
      totalMISAssets,
    } = props;
    return (
      <AllClearanceStatus asset={handOverMISAssets} totalAsset={totalMISAssets} assetStatus={MISAssetsStatus} />
    )
  }
  contactTemp = (props) => {
    const {
      ContactResearchAssetsStatus,
      handOverContactResearchAssets,
      totalContactResearchAssets,
    } = props;
    return (
      <AllClearanceStatus asset={handOverContactResearchAssets} totalAsset={totalContactResearchAssets} assetStatus={ContactResearchAssetsStatus} />
    )
  }
  navigateToAddBasicInformation = async (args) => {
    if (args.OwnDeptAssetsStatus === "Completed" || args.managerClearance === 'Y') {
      localStorage.setItem('last_meeting_category', localStorage.getItem('meeting_category'))
      const path = `/separation?empId=${encrypt(args.employeeId)}`;
      this.setState({
        redirect: path,
      }, () => {
        this.props.addUpdateSeparationDetails({ empid: args.employeeId, clearanceType: 'item', clearanceDepartment: args.department, recoveryAmountStatus: args.recoveryAmountStatus })
        localStorage.setItem("meeting_category", encodeString(EXIT_CLEARANCE_FUNCTION));
        this.props.setMeetingCategory(EXIT_CLEARANCE_FUNCTION)
      });
    } else {
      getNotify("warning", 'Own managerial clearance is not yet completed.')
    }
  };

  joiningDateFormat = (props) => {
    const { joiningDate } = props;
    return (
      <AllDateFormat date={joiningDate} />
    );
  };
  initiateDateFormat = (props) => {
    const { clearanceInitateOn } = props;
    return (
      <AllDateFormat date={clearanceInitateOn} />
    );
  };

  handleConfirmNo = () => {
    const { open } = this.state;
    this.setState({
      open: !open,
      button1Caption: '',
      button2Caption: '',
      title: '',
      message: '',
    });
  }
  handleConfirmClose = () => {
    const { open } = this.state;
    this.setState({
      open: !open,
      button1Caption: '',
      button2Caption: '',
      title: '',
      message: '',
      initiateEmployeeId: '',
      contEmployeeType: '',
    });
  }
  handleSubmitYes = () => {
    const { authUser } = this.props;
    const { open, initiateEmployeeId, title, contEmployeeType } = this.state;
    if (title === "Continue Employment") {
      const data = {
        type: "contiemp",
        userId: parseInt(authUser),
        empId: initiateEmployeeId,
        clsInfo: contEmployeeType
      }
      this.props.submiteExitClearanceData(data);
    }
    this.setState({
      open: !open,
      button1Caption: '',
      button2Caption: '',
      title: '',
      message: '',
      initiateEmployeeId: ''
    });
  }

  contEmployee = (contEmployeeType, empId) => {
    const { open } = this.state;
    this.setState({
      initiateEmployeeId: empId,
      contEmployeeType: contEmployeeType,
      open: !open,
      button1Caption: 'Yes',
      button2Caption: 'No',
      title: 'Continue Employment',
      message: 'Are you sure you want to continue his/her employment?',
    });
  }
  ownDepartmentIcons = (props) => {
    const { employeeId, empStatus, managerClearance, hrHead, clearanceStatus, employeeName } = props;
    var contEmployeeType = '';
    if (empStatus !== 'Absconded') contEmployeeType = 'abscond';
    if (empStatus !== 'Terminated') contEmployeeType = 'terminate';
    if (empStatus !== 'Resigned') contEmployeeType = 'resign';
    if (empStatus !== 'Resignation - Withdrawal') contEmployeeType = 'withdraw';

    return (

      <div className="row" style={{ paddingLeft: '10px', marginTop: '-20px' }}>

        <div className="col-4">
          <TooltipComponent position="BottomCenter" className="pointer-cursor" isSticky={false} content='Clearance' cssClass='customtooltip'>
            <FontAwesomeIcon onClick={() => this.navigateToAddBasicInformation(props)} style={{ fontSize: "20px" }} className='action-icon' icon={faClipboardCheck} title='' />
          </TooltipComponent>
        </div>
        {
          (managerClearance === "Y" || hrHead === "Y") &&
          <div className="col-4">
            <TooltipComponent content="Continue Employment" position="BottomCenter" isSticky={false} className="pointer-cursor"
              cssClass='customtooltip'>
              <FontAwesomeIcon icon={faArrowAltRight} style={{ fontSize: "20px" }} onClick={(e) => this.contEmployee(contEmployeeType, employeeId)} />
            </TooltipComponent>
          </div>
        }
        {
          (hrHead === "Y" && clearanceStatus === "Completed") &&
          <div className="col-4">
            <TooltipComponent content="Mark as Left" position="BottomCenter" isSticky={false} className="pointer-cursor"
              cssClass='customtooltip'>
              <FontAwesomeIcon icon={faPersonCarry} style={{ fontSize: "20px" }} onClick={(e) => this.leftEmployee(employeeId, employeeName)} />
            </TooltipComponent>
          </div>
        }

      </div>
    );
  };
  TextFieldComponent = (props) => {
    return (
      // eslint-disable-next-line
      <TextField {...props} disabled />
    );
  }
  onFilesAdded(addedfiles) {
    // Clear the alredy uploaded files if multiple is false
    const { allowmultiple, files } = this.state;
    if (!allowmultiple && files.length > 0) {
      this.setState({ files: addedfiles });
    } else {
      this.setState((prevState) => ({
        files: prevState.files.concat(addedfiles),
      }));
    }
  }
  employeeNameTemplate(props) {
    return (
      <EmployeeNameTemplate
        props={props}
        data="test"
        navigateToAddBasicInformation={this.navigateToAddBasicInformation}
      />
    );
  }
  getLWDCompanyRequestDate = (e, props) => {
    const { authUser } = this.props;
    const { employeeId } = props;
    const { changeData } = this.state;
    this.setState({ getLWDCompanyRequestDate: Math.floor(Date.UTC(e.getFullYear(), e.getMonth(), e.getDate())) / 1000, status: "update", changeData: !changeData }, () =>
      this.props.submiteLWDResignationCompanyrequestandNPS({
        resgnId: props.resgnUid,
        userId: authUser,
        type: 'LWDC',
        empId: employeeId,
        dtLWDCompanyRequested: Math.floor(Date.UTC(e.getFullYear(), e.getMonth(), e.getDate())) / 1000
      })
    );
  }
  lwdCompanyRequest = (props) => {
    const { lastworkingCompanyRequested, lastWorkingDayOfficial, dateOfResignation, empStatus, managerClearance, hrHead } = props;
    const { categoryName } = this.props;

    if ((categoryName === EXITCLEARANCEGRID && empStatus !== 'Absconded' && empStatus !== 'Terminated' && empStatus !== 'Active' && empStatus !== 'Absconded')
      && (managerClearance === "Y" || hrHead === "Y")) {
      return (
        <div className="col-xs-2 col-sm-2 col-lg-12 col-md-12 ">
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              style={{ width: "160px" }}
              id="lwdCompanyRequest"
              name="lwdCompanyRequest"
              format="dd-MMM-yy"

              KeyboardButtonProps={{ "aria-label": "change date" }}
              value={new Date(lastworkingCompanyRequested)}
              // maxDate={new Date(lastWorkingDayOfficial)}
              minDate={new Date(dateOfResignation)}
              onChange={(e) => this.getLWDCompanyRequestDate(e, props)}
              TextFieldComponent={this.TextFieldComponent}
              inputVariant="outlined"
              variant="inline"
              InputAdornmentProps={{ position: "start" }}
              autoOk
            />
          </MuiPickersUtilsProvider>
        </div>
      );
    } else {
      return (
        <AllDateFormat date={lastWorkingDayOfficial} />
      );

    }

  };

  leftEmployee = (empId, empName) => {
    const { authUser } = this.props;
    try {
      const getReportingExist = Promise.resolve(
        separetionServices.getClearanceDetail(authUser, "rptdts"));
      getReportingExist.then((result) => {
        if (result !== "Success") {
          getNotify(WARNING, LeftEmpMessage);
        }
        this.curEmployeeName = empName
        this.setState({ openLeftEmptPopup: true, initiateEmployeeId: empId, leftDate: Date.parse(new Date()) / 1000 });
      });
    } catch (e) {
      console.log(e);
    }
  }

  handleLeftEmpConfirm = () => {
    try {
      const { authUser } = this.props;
      const { initiateEmployeeId, leftDate } = this.state
      const empLeftData = {
        userId: authUser,
        empId: initiateEmployeeId,
        type: "leftEmp",
        clsInfo: leftDate.toString()
      };
      this.props.submiteExitClearanceData(empLeftData);
    } catch (error) {
      console.log(error, 'error')
    }
    this.setState({ openLeftEmptPopup: false });
  }

  handleLeftEmpClose = () => {
    this.setState({ openLeftEmptPopup: false });
  }

  setLeftDate = (e) => {
    var leftDate = Math.floor(Date.UTC(e.getFullYear(), e.getMonth(), e.getDate())) / 1000;
    this.setState({ leftDate: leftDate });
  };

  TextFieldComponent = (props) => {
    return <TextField {...props} disabled />;
  };

  render() {
    const { redirect, openLeftEmptPopup, leftDate } = this.state;
    const { clearanceList, categoryName } = this.props;
    if (this.state.redirect !== null) {
      return <Redirect to={redirect} />;
    }
    var widthColumn = 0;
    var finalWidth = 0;
    // eslint-disable-next-line
    getGridColumn("exitClearance").forEach((data) => {
      widthColumn = widthColumn + data.width;

    });
    if (widthColumn > this.width) {
      finalWidth = this.width
    }
    var leftDateVal = null;
    if (leftDate)
      leftDateVal = new Date(0).setUTCSeconds(leftDate);

    return (
      <div className="resignation-manager-hr-grid-height" >
        <div>
          <div className="row-xs-12 row-sm-12 row-lg-12 row-md-12">
            <div>
              <GridComponent
                className="grid-head-custome"
                id="overviewgrid"
                allowPaging
                pageSettings={this.pageOptions}
                dataSource={clearanceList}
                enableHover={false}
                enableVirtualization={false}
                rowHeight={30}
                height={this.height}
                ref={(g) => {
                  this.gridInstance = g;
                }}
                // actionComplete={this.onComplete.bind(this)}
                // load={this.onLoad}
                queryCellInfo={this.onQueryCellInfoPreJoining}
                dataBound={this.onDataBound}
                filterSettings={this.Filter}
                allowFiltering
                allowSorting
                allowSelection
                selectionSettings={this.select}
                allowTextWrap={true}
                width={`${finalWidth}`}
              >
                <ColumnsDirective>
                  {getGridColumn("exitClearance").map((item) => {
                    switch (item.colName) {
                      case EMPLOYEE_NAME:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.employeeNameTemplate}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                            filter={this.Filter}
                          />
                        );
                      case EMPLOYEE_STATUS:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.statusTemp}
                            filter={this.check}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                          />
                        );
                      case JOINING_DATE:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.joiningDateFormat}
                            // allowFiltering={false}
                            filter={this.check}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                            format={DateFilterformat}
                          />
                        );
                      case DATE_OF_INITIATE:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.initiateDateFormat}
                            // allowFiltering={false}
                            filter={this.check}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                            format={DateFilterformat}
                          />
                        );
                      case LWD_OFFICIAL:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.lwdDate}
                            // allowFiltering={false}
                            filter={this.check}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                            format={DateFilterformat}
                          />
                        );
                      case NOTICE_PERIOD:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.noticePeriodInDay}
                            // allowFiltering={false}
                            filter={this.check}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                          />
                        );
                      case EXIT_CLEARANCE_ACTION:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.ownDepartmentIcons}
                            allowFiltering={false}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                          />
                        );
                      case LWD_COMPANY_REQUEST:
                        {
                          return (
                            categoryName === EXITCLEARANCEGRID ?
                              <ColumnDirective
                                key={item.field}
                                template={this.lwdCompanyRequest}
                                // allowFiltering={true}
                                filter={this.check}
                                textAlign={`${item.align} !important`}
                                field={item.field}
                                headerText={item.colName}
                                width={item.width}
                                format={DateFilterformat}
                              /> :
                              <ColumnDirective
                                key={item.field}
                                template={this.lwdCompanyRequest}
                                // allowFiltering={false}
                                filter={this.check}
                                textAlign={`${item.align} !important`}
                                field={item.field}
                                headerText={item.colName}
                                width={150}
                                format={DateFilterformat}
                              />
                          );
                        }

                      // case REASON:
                      //   return (
                      //     <ColumnDirective
                      //       key={item.field}
                      //       template={this.resignationReason}
                      //       allowFiltering={false}
                      //       textAlign={`${item.align} !important`}
                      //       field={item.field}
                      //       headerText={item.colName}
                      //       width={item.width}
                      //     />
                      //   );
                      case SELF_DEPARTMENT:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.ownDeptTemp}
                            filter={this.check}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                          />
                        );
                      case FINANCE:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.financeTemp}
                            filter={this.check}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                          />
                        );
                      case HR:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.hrTemp}
                            filter={this.check}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                          />
                        );
                      case ADMIN:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.adminTemp}
                            filter={this.check}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                          />
                        );
                      case IT:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.itTemp}
                            filter={this.check}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                          />
                        );
                      case INFOSEC:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.infosecTemp}
                            filter={this.check}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                          />
                        );
                      case SOFTWARE:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.softwareTemp}
                            filter={this.check}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                          />
                        );
                      case LEGAL_ASSET:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.legalTemp}
                            filter={this.check}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                          />
                        );
                      case MIS_ASSET:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.misTemp}
                            filter={this.check}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                          />
                        );
                      case CONTACT_ASSET:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.contactTemp}
                            filter={this.check}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                          />
                        );
                      default:
                        return (
                          <ColumnDirective
                            key={item.field}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                            filter={this.check}
                          />
                        );
                    }
                  })}
                </ColumnsDirective>
                <Inject services={[Page, Filter, VirtualScroll, Sort]} />
              </GridComponent>
            </div>
          </div>
          <ConfirmationPopup
            showButton={{ type: "1" }}
            open={this.state.open}
            button1function={() => this.handleSubmitYes()}
            button2function={() => this.handleConfirmNo()}
            headerMessage={this.state.message}
            button1Text={this.state.button1Caption}
            button2Text={this.state.button2Caption}
            handleConfirmClose={() => this.handleConfirmNo()}
          />  
          <>
            <Dialog
              aria-labelledby="left-date-title"
              open={openLeftEmptPopup}
              onClose={this.handleLeftEmpClose}
              width="500"
            >
              <DialogTitleTMLC id="left-date-title" onClose={this.handleLeftEmpClose}>
                Mark as Left - {this.curEmployeeName}
              </DialogTitleTMLC>
              <DialogContent dividers>
                <div>
                  {/* <span className="span">Select Revoke Date *</span> */}
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      style={{ width: 250 }}
                      id="leftDate"
                      name="leftDate"
                      format="dd-MMM-yyyy"
                      value={leftDateVal}
                      onChange={(e) => this.setLeftDate(e)}
                      TextFieldComponent={this.TextFieldComponent}
                      data-msg-containerid="leftDateErr"
                      color="black"
                      autoOk
                      variant="inline"
                      maxDate={new Date()}
                      inputVariant="outlined"
                      placeholder="dd-MMM-yyyy"
                      label="Left Date *"
                      KeyboardButtonProps={{ "aria-label": "change date" }}
                      InputLabelProps={{ shrink: true }}
                      floatLabelType="Auto"
                      cssClass="e-outline"
                      InputAdornmentProps={{ position: "start" }}
                    />
                  </MuiPickersUtilsProvider>
                  <div
                    id="leftDateErr"
                    className="error-div"
                    style={{ fontSize: "12px" }}
                  />
                  <h5>
                    <span style={{ color: "#EB5465", marginTop: 20 }}>
                      Note: You cannot change the status as <b>Active</b> once you mark him/her as <b>Left</b>.
                  </span>
                  </h5>
                </div>
              </DialogContent>
              <DialogActions>
                <Button
                  id="updLeftDate"
                  color="primary"
                  variant="contained"
                  onClick={this.handleLeftEmpConfirm}
                  title={"Save"}>
                  Save
                </Button>
                <Button onClick={this.handleLeftEmpClose} color="default" variant="contained">
                  Close
                </Button>
              </DialogActions>

            </Dialog>
          </>
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ auth, meetingcategory }) => {
  const { authUser } = auth;
  const { categoryName } = meetingcategory;
  return { authUser, categoryName };
};
export default connect(mapStateToProps, {
  submiteExitClearanceData, addUpdateSeparationDetails, setMeetingCategory,
  submiteLWDResignationCompanyrequestandNPS
})(ExitClearanceFunctionalGrid);
