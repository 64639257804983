import React, { Component } from "react";
import { connect } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile } from "@fortawesome/free-solid-svg-icons";
import Slide from "@material-ui/core/Slide";
import DateFnsUtils from "@date-io/date-fns";
import Draggable from "react-draggable";
import PropTypes from "prop-types";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
import { Dialog, Button, Paper } from "@material-ui/core";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faTimes, faCheck, faClock } from "@fortawesome/pro-light-svg-icons";
import ErrorBoundary from "../error/errorboundary";
import ViewMeeting from "./viewmeeting";
import { getNotify, getDateFormat } from "../../app/other/commonfunction";
import {
  WARNING,
  timeShouldBeGreaterThanCurrentTime,
  pleaseEnterTemplateName,meetingAcceptanceMsg,acceptanceSpecificMeetingOrSeries,areYouSureAcceptMeetingRequest,areYouSureNotAttendThisMeeting,declineSpecificMeetingOrSeries,
  meetingDeclineMsg,areYouSureDeclineMeetingRequest,Holidays
} from "../../assets/constants/constants";
import {
  ViewMeetingDialogTitle,
  DialogContent,
  DialogActions, 
  DialogTitle,
} from "../customdialog/customdialog";
import { updateMeetingBookingState } from "../../redux/actions/meetingbookingaction";
import { OTHERS_CALENDAR,OTHERS_CHECK_AVAILABILITY } from "../../redux/constant/meetingcategoryconstant";
import ConfirmationPopup from "../../utilities/confirmationpopup";

library.add(faTimes, faCheck, faClock);
const Transition = React.forwardRef(function Transition(props, ref) {
  // eslint-disable-next-line
  return <Slide direction="up" ref={ref} {...props} />;
});

function PaperComponent(props) {
  return (
    <Draggable cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper
        // eslint-disable-next-line
        {...props} />
    </Draggable>
  );
}

class Viewmeetingpopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      meetingTemplateOpen: false,
      starttimeopen: false,
      open: false,
      message:'',
      displayStyle:'inline'
    };
  }


  componentDidMount(){
    this.hideActionButtonsForCertainMeetingType()
  }

  hideActionButtonsForCertainMeetingType=()=>{
    if(Holidays.includes(this.props.data.MeetingTypeId)){
      this.setState({displayStyle:'none'})
    }else{
      this.setState({displayStyle:'inline'})
    }
  }

  handleInputChange = (pType) => { // NOSONAR
    const { data } = this.props;
    const { SeriesId, AttendeeResponse } = data;

    switch (pType) {
      case "Accept":
        this.setState({
          message:
            SeriesId > 0
              ? acceptanceSpecificMeetingOrSeries
              : areYouSureAcceptMeetingRequest,
          title: meetingAcceptanceMsg,
          button1Caption: SeriesId > 0 ? "Accept Meeting" : "Yes",
          button2Caption: SeriesId > 0 ? "Accept Series" : "No",
        });
        this.setState({ acceptanceStatus: pType, open: true });
        break;
      case "Decline":
        if (AttendeeResponse === "ActualRes") {
          this.setState({
            message: areYouSureNotAttendThisMeeting,
            title: meetingDeclineMsg,
            button1Caption: "Yes",
            button2Caption: "No",
          });
        } else {
          this.setState({
            message:
              SeriesId > 0
                ? declineSpecificMeetingOrSeries
                : areYouSureDeclineMeetingRequest,
            title: meetingDeclineMsg,
            button1Caption: SeriesId > 0 ? "Decline Meeting" : "Yes",
            button2Caption: SeriesId > 0 ? "Decline Series" : "No",
          });
        }
        this.setState({ acceptanceStatus: pType, open: true });
        break;
      case "Suggest":
        setTimeout(() => {
          this.setState({ acceptanceStatus: pType, starttimeopen: true });
          const ele = document.getElementsByClassName(
            "MuiPickersModal-dialog"
          )[0];
          ele.classList.add("MuiPickersModal-dialog-custom");
        }, 1000);
        break;
      default:
    }
  };

  saveastemplate = () => {
    this.setState({ meetingTemplateOpen: true });
  };

  handleMeetingStartTimeChange = (startTime) => {
    const { meetingdate, data, authUser } = this.props;
    const { MeetingMode, MeetingId } = data;
    const start = meetingdate.indexOf("(");
    let meetingdateTime = meetingdate.substring(start + 1, 23);
    if (meetingdateTime.length < 5) {
      meetingdateTime = meetingdate.substring(start + 1, 24);
    }
    const newSuggestTime = startTime.toString().substring(16, 21);

    let meetingdatecomp = meetingdate.substring(5, 17);
    const newSuggestdatecomp = startTime.toString().substring(3, 15);

    let meetingdatecomp1 = meetingdatecomp.replace(",", "");
    let newFinaldate = "";

    if (meetingdatecomp1.trim() === newSuggestdatecomp.trim()) {
      newFinaldate = meetingdatecomp1;
    } else {
      meetingdatecomp = meetingdate.substring(4, 16);
      meetingdatecomp1 = meetingdatecomp.replace(",", "");
      const position = 5;
      newFinaldate = [
        meetingdatecomp1.slice(0, position),
        "0",
        meetingdatecomp1.slice(position),
      ].join("");
    }

    const comptime = meetingdateTime.localeCompare(newSuggestTime);
    const compdate = newFinaldate
      .trim()
      .localeCompare(newSuggestdatecomp.trim());

    if (
      (comptime !== 0 && comptime !== "") ||
      (compdate !== 0)
    )/**NOSONAR */ {
      this.setState({ starttime: startTime, starttimeopen: false });
      // eslint-disable-next-line
      this.props.meetingAcceptance({
        meetingmode: MeetingMode,
        meetingId: MeetingId,
        status: 3,
        starttime: getDateFormat(new Date(startTime), new Date(startTime)),
        user_id: authUser,
        volume: "single",
      });
    } else {
      getNotify(WARNING, timeShouldBeGreaterThanCurrentTime);
    }
  };

  handleClose = () => {
    this.setState({ meetingTemplateOpen: false });
  };

  handleStartTimeClose = () => {
    this.setState({ starttimeopen: false });
  };

  handleCloseMeetingViewPopup = () => {
    // eslint-disable-next-line
    this.props.updateMeetingBookingState({ showMeetingPopup: false });
  };

  handleSubmit = () => {
    const { data, authUser } = this.props;
    const { MeetingMode, MeetingId } = data;
    const templatename = document.getElementById("template-name-txt").value;
    if (templatename.trim() === "") {
      document.getElementById(
        "templateError"
      ).innerHTML = pleaseEnterTemplateName;
    } else {
      // eslint-disable-next-line
      this.props.saveMeetingTemplate({
        templateName: templatename,
        meetingmode: MeetingMode,
        meetingId: MeetingId,
        user_id: authUser,
      });
      this.setState({ meetingTemplateOpen: false });
    }
  };

  handleConfirmYes = () => {
    const { data, authUser } = this.props;
    const { MeetingMode, MeetingId } = data;
    const { acceptanceStatus } = this.state;

    switch (acceptanceStatus) {
      case "Accept":
        // eslint-disable-next-line
        this.props.meetingAcceptance({
          meetingmode: MeetingMode,
          meetingId: MeetingId,
          status: 1,
          volume: "single",
          user_id: authUser,
        });
        break;
      case "Decline":
        // eslint-disable-next-line
        this.props.meetingAcceptance({
          meetingmode: MeetingMode,
          meetingId: MeetingId,
          status: 2,
          volume: "single",
          user_id: authUser,
        });
        break;
      case "Suggest":
        break;
      default:
    }
    // eslint-disable-next-line
    this.props.getMeetingDetails({ meetingPopOpen: false });
    this.setState({ open: false });
  };

  handleConfirmNo = () => {
    const { data, authUser } = this.props;
    const { MeetingMode, MeetingId, SeriesId } = data;
    const { acceptanceStatus } = this.state;
    if (SeriesId > 0) {
      switch (acceptanceStatus) {
        case "Accept":
          // eslint-disable-next-line
          this.props.meetingAcceptance({
            meetingmode: MeetingMode,
            meetingId: MeetingId,
            status: 1,
            volume: "series",
            user_id: authUser,
          });
          break;
        case "Decline":
          // eslint-disable-next-line
          this.props.meetingAcceptance({
            meetingmode: MeetingMode,
            meetingId: MeetingId,
            status: 2,
            volume: "series",
            user_id: authUser,
          });
          break;
        case "Suggest":
          break;
        default:
      }
    }
    // eslint-disable-next-line
    this.props.getMeetingDetails({ meetingPopOpen: false });
    this.setState({ open: false });
  };

  handleConfirmClose = () => {
    this.setState({ open: false });
  };

  render() { // NOSONAR
    const {displayStyle}=this.state
    const {
      data,
      showMeetingPopup,
      meetingdate,
      seriesDateString,
      categoryName,
      readWriteAccess,
    } = this.props;
    const {
      AttendeeResponse,
      MeetingMode,
      StartTime,
      EditDeleteAccess,
      PrimaryColor,
      SecondaryColor,
      Subject,
      // MeetingType,
      SaveTemplate,
    } = data;

    const {
      starttimeopen,
      starttime,
      meetingTemplateOpen,
      open,
      message,
      button1Caption,
      button2Caption,
    } = this.state;

    if (data !== undefined) {
      let acceptVis = false;
      let declineVis = false;
      let suggestVis = false;
      if (data !== undefined && AttendeeResponse !== "No") {
        if (
          MeetingMode === "P" &&
          new Date(StartTime).getTime() > new Date().getTime()
        ) {
          if (AttendeeResponse === "3" || AttendeeResponse === "Def") {
            acceptVis = true;
            declineVis = true;
            suggestVis = true;
          } else {
            if (AttendeeResponse === "2" || AttendeeResponse === "") {
              acceptVis = true;
            }
            if (AttendeeResponse === "1" || AttendeeResponse === "") {
              declineVis = true;
              suggestVis = true;
            }
            if (AttendeeResponse === "") {
              suggestVis = true;
            }
          }
        } else if (EditDeleteAccess !== "D") {
          if (AttendeeResponse === "1" || AttendeeResponse === "MoveToActual") {
            declineVis = true;
          }
          // && this.props.data.EditDeleteAccess!=="D"
          if (
            MeetingMode === "P" &&
            EditDeleteAccess === "N" &&
            (AttendeeResponse === "1" || AttendeeResponse === "")
          ) {
            declineVis = true;
          }
          if (MeetingMode === "A" && EditDeleteAccess === "N") {
            declineVis = true;
          }
          if (AttendeeResponse === "ActualRes") {
            declineVis = true;
          }
        }
      }
      const duration = {
        enteringScreen: 500,
        leavingScreen: 500,
      };            
      return (
        <div>
          
          <Dialog
            onClose={this.handleCloseMeetingViewPopup}
            aria-labelledby="view-meeting"
            open={showMeetingPopup}
            fullWidth
            maxWidth="lg"
            
            disableEscapeKeyDown
          >
            <ViewMeetingDialogTitle
              primaryColor={PrimaryColor}
              secondaryColor={SecondaryColor}
              id="view-meeting"
              onClose={this.handleCloseMeetingViewPopup}
            >
              {
                categoryName === OTHERS_CHECK_AVAILABILITY ? 
              <span style={{color:"transparent"}}>No Title</span>
                  :
                  Subject
              }  
            </ViewMeetingDialogTitle>
            <DialogContent dividers>
              <div style={{ display: "block" }}>
                <ViewMeeting
                  data={data}
                  meetingdate={meetingdate}
                  seriesDateString={seriesDateString}
                />
              </div>
            </DialogContent>
            <DialogActions>
              <div style={{ width: "100%", marginRight:-8 }}>
                <div
                  className="col-lg-12"
                  style={{
                    textAlign: "right",
                  }}
                >
                  {SaveTemplate === "Y" &&
                    typeof categoryName !== "undefined" &&
                    categoryName !== OTHERS_CALENDAR &&
                    categoryName !== OTHERS_CHECK_AVAILABILITY && (
                      <Button
                        style={{
                          background: "#000000",
                          color: "#fff",
                          fontSize: "12px",
                        }}
                        className="mt-lg pl-sm pr-sm action-buttons"
                        title=""
                        size="small"
                        variant="contained"
                        color="inherit"
                        onClick={this.saveastemplate}
                      >
                        <FontAwesomeIcon
                          style={{ color: "#fff" }}
                          icon={faFile}
                          className="mr-xs"
                        />
                        Save As Template
                      </Button>
                    )}
                  {suggestVis &&
                    (readWriteAccess === "Y" || readWriteAccess === "") &&
                    // (categoryName !== OTHERS_CALENDAR ) &&
                     (
                      <Button
                        style={{
                          background: "#146f68",
                          color: "#fff",
                          fontSize: "12px",
                          display:displayStyle
                        }}
                        className="mt-lg pl-sm pr-sm action-buttons"
                        title=""
                        size="small"
                        variant="contained"
                        color="inherit"
                        onClick={() => this.handleInputChange("Suggest")}
                      >
                        <FontAwesomeIcon
                          style={{ color: "#fff" }}
                          icon={faClock}
                          className="mr-xs"
                        />
                        Suggest New Time
                      </Button>
                    )}
                  {declineVis &&
                    (readWriteAccess === "Y" || readWriteAccess === "") && (
                      <Button
                        style={{ fontSize: "12px" ,display:displayStyle}}
                        className="mt-lg mr-sm pl-sm pr-sm action-buttons"
                        title=""
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={() => this.handleInputChange("Decline")}
                      >
                        <FontAwesomeIcon icon={faTimes} className="mr-xs" />
                        {new Date(StartTime).getTime() < new Date().getTime()
                          ? "Did Not Attend"
                          : " Decline"}
                      </Button>
                    )}
                  {acceptVis &&
                    (readWriteAccess === "Y" || readWriteAccess === "") && (
                      <Button
                        style={{
                          background: "#297125",
                          fontSize: "12px",
                          color: "#fff",
                        }}
                        className="mt-lg mr-sm pl-sm pr-sm action-buttons"
                        title=""
                        size="small"
                        variant="contained"
                        color="inherit"
                        onClick={() => this.handleInputChange("Accept")}
                      >
                        <FontAwesomeIcon
                          style={{ color: "#fff" }}
                          icon={faCheck}
                          className="mr-xs"
                        />
                        Accept
                      </Button>
                    )}
                  <Button
                    style={{
                      background: "#297125",
                      fontSize: "12px",
                      color: "#fff",
                      overflowX: "hidden",
                    }}
                    className="mt-lg mr-sm pl-sm pr-sm action-buttons"
                    size="small"
                    autoFocus
                    onClick={this.handleCloseMeetingViewPopup}
                    color="secondary"
                    variant="contained"
                    title="Close"
                  >
                    Close
                  </Button>
                </div>
                <div style={{ display: "none", width: "376px" }}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DateTimePicker
                      open={starttimeopen}
                      id="MuiPickersModal-dialog-custom"
                      label="DateTimePicker"
                      inputVariant="outlined"
                      value={starttime}
                      onClose={this.handleStartTimeClose}
                      onChange={this.handleMeetingStartTimeChange}
                      minDate={new Date()}
                      clearable
                    />
                  </MuiPickersUtilsProvider>
                </div>
                {/* ADD Template - Pop up */}
                <div style={{ display: "none", width: "376px" }}>
                  <ErrorBoundary>
                    <Dialog
                      onClose={this.handleClose}
                      aria-labelledby="meeting-template-dialog-title"
                      open={meetingTemplateOpen}
                      maxWidth="xs"
                      fullWidth
                      // onEntered={this.handleEntered}
                      TransitionComponent={Transition}
                      
                      disableEscapeKeyDown
                      transitionDuration={{
                        enter: duration.enteringScreen,
                        exit: duration.leavingScreen,
                      }}
                      // transitionDuration={1000}
                      PaperComponent={PaperComponent}
                    >
                      <DialogTitle
                        id="meeting-booking-dialog-title"
                        onClose={this.handleClose}
                      >
                        Template Name
                      </DialogTitle>
                      <DialogContent dividers>
                        {/* <form id="meetingbookingfrm" method="post"> */}
                        <form id="meetingtemplatefrm" method="post">
                          <div className="e-input-group e-float-icon-left">
                            <div className="e-input-in-wrap">
                              <TextBoxComponent
                                placeholder="Template Name"
                                required
                                cssClass="e-outline"
                                floatLabelType="Auto"
                                name="template-name-txt"
                                id="template-name-txt"
                                data-msg-containerid="template-nameError"
                                blur={this.handleFocusOut}
                                htmlAttributes={{
                                  maxlength: "50",
                                  minLength: 1,
                                }}
                              />
                            </div>
                          </div>
                          <div
                            id="templateError"
                            className="error-div"
                            style={{ marginLeft: 15 }}
                          />
                        </form>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          autoFocus
                          onClick={this.handleSubmit}
                          color="primary"
                          variant="contained"
                          title="Add Template"
                        >
                          Add Template
                        </Button>
                        <Button
                          autoFocus
                          onClick={this.handleClose}
                          color="secondary"
                          variant="contained"
                          title="Close"
                        >
                          Close
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </ErrorBoundary>
                </div>
              </div>
            </DialogActions>
          </Dialog>
          <ConfirmationPopup
            showButton={{ type: "1" }}
            open={open}
            button1function={() => this.handleConfirmYes()}
            button2function={() => this.handleConfirmNo()}
            headerMessage={message}
            button1Text={button1Caption}
            button2Text={button2Caption}
            handleConfirmClose={this.handleConfirmClose}
          />  
        </div>
      );
    }
    return <div />;
  }
}
Viewmeetingpopup.propTypes = {
  // eslint-disable-next-line
  data: PropTypes.object,
  meetingdate: PropTypes.string,
  authUser: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  showMeetingPopup: PropTypes.bool,
  seriesDateString: PropTypes.string,
  categoryName: PropTypes.string,
  readWriteAccess: PropTypes.string,
};
Viewmeetingpopup.defaultProps = {
  meetingdate: "",
  authUser: "",
  showMeetingPopup: false,
  seriesDateString: "",
  categoryName: "",
  readWriteAccess: "",
};
const mapStateToProps = ({ auth, meetingbooking, meetingcategory }) => {
  const { authUser } = auth;
  const { showMeetingPopup } = meetingbooking;
  const { categoryName } = meetingcategory;
  return {
    showMeetingPopup,
    authUser,
    categoryName,
  };
};

export default connect(mapStateToProps, { updateMeetingBookingState })(
  Viewmeetingpopup
);
