import React, { useState, useEffect } from "react";
import { RadialChart } from "react-vis";
import lnaservice from "../../services/lna/lnaservice";
import ProgressBar from "../progressbar/progressbar";

const ChartReport = (props) => {
  const { teamId, startMonthMonthlyReport, reloadChart, updateLnAData } = props;
  const [attendenceCountData, setAttendenceCountData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [chartWidth, setChartWidth] = useState(
    (window.innerWidth === 1366 || window.innerWidth === 1280 || window.innerWidth === 1152 || window.innerWidth === 1024) ? 100 : (window.innerWidth > 1700) ? 250 : window.innerWidth * 0.15
  );
  const [chartHeight, setChartHeight] = useState(
    (window.innerWidth === 1366 || window.innerWidth === 1280 || window.innerWidth === 1152 || window.innerWidth === 1024) ? 140 : (window.innerWidth > 1700) ? 300 : window.innerWidth * 0.18
  );
  const [innerRadius, setInnerRadius] = useState(
    (window.innerWidth === 1366 || window.innerWidth === 1280 || window.innerWidth === 1152 || window.innerWidth === 1024) ? 35 : (window.innerWidth > 1700) ? 80 : 50
  );
  const [radius, setRadius] = useState((window.innerWidth === 1366 || window.innerWidth === 1280 || window.innerWidth === 1152 || window.innerWidth === 1024) ? 50 : (window.innerWidth > 1700) ? 125 : 100);
  const [fontSize, setFontSize] = useState("12px");
  const [boxHight, setBoxHight] = useState("20px");
  const [boxWidth, setBoxWidth] = useState("20px");
  const handleResize = () => {
    setChartWidth(window.innerWidth === 1280 ? 100 : window.innerWidth > 1700 ? 250 : 180);
    setChartHeight(window.innerWidth === 1280 ? 140 : window.innerWidth > 1700 ? 300 : 200);
    setInnerRadius(window.innerWidth === 1280 ? 40 : window.innerWidth > 1700 ? 80 : 40);
    setRadius(window.innerWidth === 1280 ? 40 : window.innerWidth > 1700 ? 125 : 80);
    setFontSize(window.innerWidth === 1280 ? "9px" : window.innerWidth > 1700 ? "12px" : "9px");
    setBoxHight(window.innerWidth === 1280 ? "12px" : window.innerWidth > 1700 ? "20px" : "12px");
    setBoxWidth(window.innerWidth === 1280 ? "12px" : window.innerWidth > 1700 ? "20px" : "12px");
  };

  const totalDays = attendenceCountData.filter(
    (item) => item.var_attendance_name === "Total Days"
  );

  const getAttendenceCount = async () => {
    if (startMonthMonthlyReport && teamId) {
      setIsLoading(true)
      const AttendenceCountData = await lnaservice.getWeeklyReportData(
        teamId,
        "ATTENDENCECOUNT",
        startMonthMonthlyReport && startMonthMonthlyReport.split(" ")[0]
      );
      if (AttendenceCountData.Result) {
        setAttendenceCountData(AttendenceCountData.Result);
      } else {
        setAttendenceCountData([]);
      }
      setIsLoading(false)
    }
  };
  const filteredData = attendenceCountData.filter(
    (item) => item.var_attendance_name !== "Total Days"
  );

  const transformedData = filteredData.map((item) => ({
    angle: item.count,
    name: item.symbol,
    attendance: item.symbolName,
    color: item.colorCode,
  }));

  const marginTop = (window.innerWidth === 1536 || window.innerWidth === 1440 || window.innerWidth === 1440 || window.innerWidth === 1366 ||
    window.innerWidth === 1280 || window.innerWidth === 1152 || window.innerWidth === 1024)
    ? "mt-sm" : "mt-xl";
  const getWindowWidthFontSize = () => {
    const windowWidth = window.innerWidth;
    if (windowWidth === 1366 || windowWidth === 1280 || windowWidth === 1152 || windowWidth === 1024) {
      return '8px';
    } else if (window.innerWidth === 1536 || windowWidth === 1440) {
      return '10px';
    } else {
      return fontSize;
    }
  };

  const getWindowWidthBoxAlignment = () => {
    const windowWidth = window.innerWidth;
    if (windowWidth === 1366 || windowWidth === 1280 || windowWidth === 1152 || windowWidth === 1024) {
      return '55px';
    } else if (window.innerWidth === 1536 || windowWidth === 1440) {
      return '65px';
    } else {
      return '85px';
    }
  };

  useEffect(() => {
    if (reloadChart) {
      getAttendenceCount();
      updateLnAData({ reloadChart: false })
    }
  }, [reloadChart]);

  useEffect(() => {
      getAttendenceCount();
      updateLnAData({ reloadChart: false })
  }, []);
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (isLoading) {
    return <div
      style={{
        zIndex: 999999,
        position: "absolute",
        marginTop: "20%",
        marginLeft: "90%",
      }}
    >
      <ProgressBar />
    </div>
  }
  return (
    <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2">
      <div id="weekly-report-pi-chart" className="ml-sm ">
        <RadialChart
          data={transformedData}
          width={chartWidth}
          height={chartHeight}
          colorType="literal"
          // innerRadius={80}
          // radius={125}
          innerRadius={innerRadius}
          radius={radius}
        />
      </div>
      <div
        className="text-center mt-3"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          color: "black",
          fontStyle: "Circular Std",
          fontSize: (window.innerWidth === 1152 && window.innerHeight === 551) ? '8px' : getWindowWidthFontSize(),
          lineHeight: "14px",
          fontFamily: "Circular Std",
        }}
      >
        <div style={{ textAlign: "start" }}>
          <span className="pb-md">
            {attendenceCountData && totalDays.length > 0
              ? `Total Days - ${totalDays[0].count}`
              : ""}
          </span>
          {transformedData?.length > 6 ? (
            <div style={{ display: "flex", flexWrap: "wrap", marginTop: '5px', width: '150%' }}>
              {transformedData?.map((item, index) => (
                <div
                  key={index}
                  className={`mt-${marginTop} mr-md ml-md mb-lg grid-container`}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    width: getWindowWidthBoxAlignment()
                  }}
                  title={item.attendance}
                >
                  <div
                    style={{
                      backgroundColor: item.color,
                      width: (window.innerWidth === 1536 || window.innerWidth === 1366 || window.innerWidth === 1280 || window.innerWidth === 1152 || window.innerWidth === 1024) ? "12px" : boxWidth,
                      height: (window.innerWidth === 1536 || window.innerWidth === 1366 || window.innerWidth === 1280 || window.innerWidth === 1152 || window.innerWidth === 1024) ? "12px" : boxHight,
                      flexShrink: 0,
                    }}
                  ></div>
                  <div className="content">
                    {item.name} - {item.angle}
                  </div>
                </div>
              ))}
            </div>
          ) : (
            transformedData?.map((item, index) => (
              <div
                key={index}
                className={marginTop}
                style={{ display: "flex" }}
                title={item.attendance}
              >
                <span
                  className="mr-md"
                  style={{
                    backgroundColor: item.color,
                    width: (window.innerWidth === 1536 || window.innerWidth === 1366 || window.innerWidth === 1280 || window.innerWidth === 1152 || window.innerWidth === 1024) ? "12px" : boxWidth,
                    height: (window.innerWidth === 1536 || window.innerWidth === 1366 || window.innerWidth === 1280 || window.innerWidth === 1152 || window.innerWidth === 1024) ? "12px" : boxHight,
                  }}
                ></span>
                <span>
                  {item.name} - {item.angle}
                </span>
              </div>
            ))
          )}
        </div>
      </div>
    </div >
  );
};
export default ChartReport;
