// Author by:- Prashant Waphare | 31-08-2020 | reducer for all home office.

import {
  SAVE_ROLE_DATA,
  SAVE_MODULE_DATA,
  UPDATE_BUSINESS_FUNCTION_DATA,
  UPDATE_LAYER_MASTER_DATA,
  UPDATE_SUB_LAYER_MASTER_DATA,
  UPDATE_ADMIN_REDUX_DATA,
  UPDATE_USER_ROLE_MASTER_DATA,
  UPDATE_FOLDER_MANAGEMENT_DATA, UPDATE_FINANCIAL_YEAR, ADD_EDIT_EMAIL_MAPPING_DATA
  // SAVE_USER_ROLE_MASTER_FUNCTION
  //

} from "../../constant/admin/adminconstant";
import { HANDLE_TMLC_ADMIN_POPUP } from "../../constant/admin/adminconstant";

const INIT_STATE = {
  role_id: "",
  role_name: "",
  role_description: "",
  role_status: "",
  role_modules: "",
  module_id: "",
  module_name: "",
  module_description: "",
  module_status: "",
  module_type: "",
  module_is_root: "",
  module_parent_id: "",
  module_icon: "",
  module_route: "",
  module_menu_order: "",
  constant_id: "",
  action_status: "",
  subBusinessFunctionFlag: '',
  practiceAreaFlag: '',
  layerMappingFlag: '',
  subLayerMappingFlag: '',
  busiess_unit_function_flag: "",
  layer_master_flag: "",
  sub_layer_master_flag: "",
  user_role_flag: "",
  folderManagementFlag: "",
  fyId: null,
  emailMappingStatus: ""
};

// eslint-disable-next-line
export default (state = INIT_STATE, action) => { //NOSONAR
  switch (action.type) {
    case SAVE_ROLE_DATA:
    case UPDATE_BUSINESS_FUNCTION_DATA:
    case UPDATE_LAYER_MASTER_DATA:
    case UPDATE_SUB_LAYER_MASTER_DATA:
    case UPDATE_ADMIN_REDUX_DATA:
    case UPDATE_USER_ROLE_MASTER_DATA:
    case UPDATE_FOLDER_MANAGEMENT_DATA:
    case HANDLE_TMLC_ADMIN_POPUP:
    case ADD_EDIT_EMAIL_MAPPING_DATA:
    case UPDATE_FINANCIAL_YEAR:
    case SAVE_MODULE_DATA: {
      return {
        ...state,
        ...action.payload,
      };

    }

    default:
      return state;
  }
};
