import React, { Component } from 'react';
import { connect } from "react-redux";
import { HierarchicalTree, StackPanel, ImageElement, TextElement, SnapConstraints, ConnectorConstraints, DiagramComponent, NodeConstraints, Inject, DataBinding, OverviewComponent, DiagramTools, Node, Connector } from "@syncfusion/ej2-react-diagrams";
import { DataManager } from "@syncfusion/ej2-data";
import { updateUserInfo } from '../../../redux/actions/auth';
import services from "../../../services/services";
import { urlConfig } from "../../../config/config";
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

import Progressbar from '../../../components/progressbar/progressbar';
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { orgchartViews, orgChartOrientation, orgChartSubTreeAlignment, orgChartSubTreeAlignmentLimited } from '../../../assets/constants/constants'

let orien;
let typ;
class OrgChartConmponent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            orgChartSideBarData: null,
            pageHeight: (window.innerHeight ||
                document.documentElement.clientHeight ||
                document.body.clientHeight) - 300,
            pageWidth: (window.innerWidth ||
                document.documentElement.clientWidth ||
                document.body.clientWidth) - 71,
            Data: [],
            changeStateFlag: true,
            showLoader: true,
            viewType: '0',
            selectedTeamMember: null,
            dropDownData: []
        };
        this.diagramInstance = null;
        this.data = [];
        this.month = 0;
        this.selectedDate = new Date();
        this.legendSettings = { visible: false };
        this.zoomsettings = {
            enableMouseWheelZooming: false, enablePinchZooming: false,
            enableSelectionZooming: false
        };
        this.orgChartOrientation = orgChartOrientation;
        this.orgChartSubTreeAlignment = orgChartSubTreeAlignment;
        this.orgchartViews = orgchartViews;
        this.selectedOrientation = "TopToBottom";
        this.selectedSubTreeAlignment = "pattern7";
    }
    updateDimensions = () => {
        let pageHeight = window.innerHeight - 50;
        let pageWidth = window.innerWidth - 50;
        this.setState({ pageHeight: pageHeight, pageWidth: pageWidth, Data: this.data, showLoader: false });
    }


    getOrgChartData = async (type) => {
        const { authUser } = this.props;
        const { selectedTeamMember } = this.state;
        if (type === 'default') {
            this.setState({ showLoader: true })
            var userid = authUser;
            var month = this.month;
            var data = await services.getOrgChartData(userid, month, 'show');
            this.data = data.result;
            this.setState({ dropDownData: this.data.filter((each) => each.showInDropDown !== 0), showLoader: false }, () => {
                this.onSelectTeamMember({
                    itemData: { id: userid }
                })
            })

        } else {
            var userid1 = selectedTeamMember;
            var month1 = this.month;
            var data1 = await services.getOrgChartData(userid1, month1, 'NewData');
            this.data = data1.result;
            this.props.updateUserInfo({ openOrgChartSidebar: false });
            this.setState({ Data: this.data, showLoader: false, selectedTeamMember })
        }
    }

    butnClick = () => {
        this.diagramInstance.nodes[2].isExpanded = !this.diagramInstance.nodes[2].isExpanded
        this.diagramInstance.nodes.callChildDataBind()

    }
    componentDidMount() {
        const { authUser } = this.props;
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions);
        this.setState({ selectedTeamMember: authUser })
        this.getOrgChartData("default");
        this.props.updateUserInfo({ openOrgChartSidebar: false });

    }
    async onDateChange(e) {
        this.setState({ showLoader: true });
        this.selectedDate = e.toString();
        const d = new Date();
        let currentYear = d.getFullYear();
        let currentMonthStr = ((d.getMonth() + 1).toString().length === 2) ? (d.getMonth() + 1).toString() : "0" + (d.getMonth() + 1).toString();
        let year = e.getFullYear();
        let monthStr = ((e.getMonth() + 1).toString().length === 2) ? (e.getMonth() + 1).toString() : "0" + (e.getMonth() + 1).toString();
        this.month = year + '-' + monthStr;
        if (currentMonthStr === monthStr && currentYear === year)
            this.month = '0';
        
        const { selectedTeamMember } = this.state;
        var data = await services.getOrgChartData(selectedTeamMember, this.month, 'NewData');
        this.data = data.result;
        this.setState({ Data: this.data, showLoader: false });
        this.props.updateUserInfo({ openOrgChartSidebar: false });
    }


    //Funtion to add the Template of the Node.
    setNodeTemplate = (obj, diagram) => {/**NOSONAR */

        let content = new StackPanel();
        content.id = obj.id + "_outerstack";
        content.orientation = "Vertical";
        content.style.strokeColor = "#939393";
        content.width = 170;
        content.height = 135;
        content.padding = { left: 0, right: 0, top: 0, bottom: 5 };

        let flag1 = new ImageElement();
        flag1.width = 25;
        flag1.height = 15;
        flag1.margin = { left: 10, top: 0, right: 0, bottom: 0 };
        flag1.padding = { left: 15, top: 2, right: 5, bottom: 2 };
        flag1.style.strokeColor = "none";
        flag1.source = require("../../../assets/svg/" + obj.data.country + ".svg").default; // this.getCountryIcon(obj.data.country) //
        flag1.id = obj.id + "_flag1";

        let resourceSharing = new TextElement();
        resourceSharing.type = 'Basic';
        resourceSharing.shape = 'Rectangle';
        resourceSharing.style.cornerRadius = 10;
        resourceSharing.content = (obj.data.indirect_reportee_flag === 'Y') && 'Shared';
        resourceSharing.style.color = "#03B963";
        resourceSharing.style.bold = true;
        resourceSharing.style.fontSize = 10;
        resourceSharing.style.cursor = 'pointer';
        resourceSharing.style.strokeColor = "none";
        resourceSharing.margin = { left: 90, right: 5, top: 0, bottom: 15 };
        resourceSharing.style.fill = "#FFFFFF";
        resourceSharing.id = obj.id + "_resourceSharing";

        let header = new StackPanel();
        header.id = obj.id + "_headerstack";
        header.orientation = "Horizontal";
        header.style.strokeColor = "none";
        header.style.fill = obj.data.stripColor;
        header.width = 170;
        header.height = 15;
        header.padding = { left: 0, right: 0, top: 5, bottom: 5 };
        header.children = (obj.data.indirect_reportee_flag === 'Y') ? [flag1, resourceSharing] : [flag1];

        let image = new ImageElement();
        image.width = 50;
        image.height = 50;
        image.borderRadius = 50;
        image.margin = { left: 18, top: 5, right: 0, bottom: 0 };
        image.style.strokeColor = "none";
        image.source = (obj.data.Id === 1000) ? require('../../../assets/images/companylogos/' + obj.data.business_pnl_logo).default : urlConfig.tmlcApiUrl + "document/E/486/" + obj.data.ImageUrl;
        image.id = obj.id + "_pic";

        let image2 = new ImageElement();
        image2.width = 25;
        image2.height = 25;
        image2.margin = { left: 30, top: 0, right: 0, bottom: 0 };
        image2.style.strokeColor = "none";

        image2.id = obj.id + "_pic2";

        let image3 = new ImageElement();
        image3.width = 30;
        image3.height = 25;
        image3.staticSize = true;
        image3.margin = { left: 7, top: 0, right: 0, bottom: 0 };
        image3.style.strokeColor = "none";
        image3.source = (obj.data.Id === 1000) ? '' : require('../../../assets/images/companylogos/' + obj.data.business_pnl_logo).default;
        image3.id = obj.id + "_pic3";

        let imageStack = new StackPanel();
        imageStack.id = obj.id + "_imagestack";
        imageStack.orientation = "Horizontal";
        imageStack.style.strokeColor = "none";
        imageStack.children = [image3, image, image2];

        let innerStack = new StackPanel();
        innerStack.style.strokeColor = "none";
        innerStack.margin = { left: 0, right: 0, top: 5, bottom: 0 };
        innerStack.id = obj.id + "_innerstack";

        let text = new TextElement();
        text.content = obj.data.Name;
        text.style.color = "black";
        text.style.bold = true;
        text.style.margin = { left: 0, right: 0, top: 2, bottom: 0 };
        text.style.fontSize = 10;
        text.style.strokeColor = "none";
        text.style.fill = "none";
        text.id = obj.id + "_text1";

        let desigText = new TextElement();
        desigText.margin = { left: 0, right: 0, top: 2, bottom: 0 };
        desigText.content = obj.data.Designation.length > 25 ? obj.data.Designation.slice(0, 25) + '...' : obj.data.Designation;
        desigText.style.color = "#B7B7BB";
        desigText.style.strokeColor = "none";
        desigText.style.fill = "none";
        desigText.style.fontSize = 10;
        desigText.style.textWrapping = 'Wrap';
        desigText.id = obj.id + "_desig";

        innerStack.children = [text, desigText];
        if (obj.data.direction === 'up' && obj.data.my_position === '') {
            content.children = [header, imageStack, innerStack];
        } else if (obj.data.total_reportee > 0 || obj.data.direct_reportee > 0) {
            let directReporting = new TextElement();
            directReporting.content = obj.data.direct_reportee >= 0 ? obj.data.direct_reportee : '0';
            directReporting.style.color = obj.data.direct_reportee > 0 ? "red" : "#ff000066";
            directReporting.style.bold = true;
            directReporting.style.fontSize = 11;
            directReporting.padding = { left: 5, right: 5, top: 5, bottom: 5 };
            directReporting.style.strokeColor = "red";
            directReporting.style.fill = "#F2F2F2";
            directReporting.style.textWrapping = "Wrap";
            directReporting.id = obj.id + "_text2";
            directReporting.width = 30;
            directReporting.height = 16;

            let indirectReporting = new TextElement();
            indirectReporting.margin = { left: 5, right: 5, top: 5, bottom: 5 };
            indirectReporting.content = obj.data.total_reportee >= 0 ? obj.data.total_reportee.toString() : '0';
            indirectReporting.style.bold = true;
            indirectReporting.style.fontSize = 11;
            indirectReporting.style.color = obj.data.total_reportee > 0 ? "black" : '#B0B2AE';
            indirectReporting.style.strokeColor = "red";
            indirectReporting.padding = { left: 5, right: 5, top: 5, bottom: 5 };
            indirectReporting.style.fill = "#F2F2F2";
            indirectReporting.style.textWrapping = "Wrap";
            indirectReporting.id = obj.id + "_desig2";
            indirectReporting.width = 30;
            indirectReporting.height = 16;

            let reportingStack = new StackPanel();
            reportingStack.orientation = "Horizontal";
            reportingStack.style.strokeColor = "none";
            reportingStack.style.textAlign = 'center';
            reportingStack.id = obj.id + "_textStack";

            reportingStack.children = [directReporting, indirectReporting];
            content.children = [header, imageStack, innerStack, reportingStack];
        } else {
            content.children = [header, imageStack, innerStack];
        }
        return content;
    }
    setNodeMinimumViewTemplate = (obj, _diagram) => {

        let content = new StackPanel();
        content.id = obj.id + "_outerstack";
        content.orientation = "Vertical";
        content.style.strokeColor = "#939393";
        content.width = 150;
        content.height = obj.data.total_reportee >= 0 || obj.data.direct_reportee >= 0 ? 60 : 40;
        content.padding = { left: 0, right: 0, top: 0, bottom: 5 };

        let innerStack = new StackPanel();
        innerStack.style.strokeColor = "none";
        innerStack.margin = { left: 0, right: 0, top: 5, bottom: 0 };
        innerStack.id = obj.id + "_innerstack";

        let text = new TextElement();
        text.content = obj.data.Name;
        text.style.color = "black";
        text.style.bold = true;
        text.style.margin = { left: 0, right: 0, top: 2, bottom: 0 };
        text.style.fontSize = 10;
        text.style.strokeColor = "none";
        text.style.fill = "none";
        text.id = obj.id + "_text1";

        let desigText = new TextElement();
        desigText.margin = { left: 0, right: 0, top: 2, bottom: 0 };
        desigText.content = obj.data.Designation.length > 25 ? obj.data.Designation.slice(0, 25) + '...' : obj.data.Designation;
        desigText.style.color = "#B7B7BB";
        desigText.style.strokeColor = "none";
        desigText.style.fill = "none";
        desigText.style.fontSize = 10;
        desigText.id = obj.id + "_desig";

        innerStack.children = [text, desigText];
        if (obj.data.direction === 'up' && obj.data.my_position === '') {
            content.children = [innerStack];
        } else if (obj.data.total_reportee > 0 || obj.data.direct_reportee > 0 || obj.data.indirect_reportee > 0) {
            let directReporting = new TextElement();
            directReporting.content = obj.data.direct_reportee > 0 ? obj.data.direct_reportee : '0';
            directReporting.style.color = "red";
            directReporting.style.bold = true;
            directReporting.style.fontSize = 11;
            directReporting.padding = { left: 5, right: 5, top: 5, bottom: 5 };
            directReporting.style.strokeColor = "red";
            directReporting.style.fill = "#F2F2F2";
            directReporting.style.textWrapping = "Wrap";
            directReporting.id = obj.id + "_text2";
            directReporting.width = 30;
            directReporting.height = 16;

            let indirectReporting = new TextElement();
            indirectReporting.margin = { left: 5, right: 5, top: 5, bottom: 5 };
            indirectReporting.content = obj.data.total_reportee >= 0 ? obj.data.total_reportee.toString() : '0';
            indirectReporting.style.bold = true;
            indirectReporting.style.fontSize = 11;
            indirectReporting.style.color = obj.data.total_reportee > 0 ? "black" : '#B0B2AE';
            indirectReporting.style.strokeColor = "red";
            indirectReporting.padding = { left: 5, right: 5, top: 5, bottom: 5 };
            indirectReporting.style.fill = "#F2F2F2";
            indirectReporting.style.textWrapping = "Wrap";
            indirectReporting.id = obj.id + "_desig2";
            indirectReporting.width = 30;
            indirectReporting.height = 16;

            let reportingStack = new StackPanel();
            reportingStack.orientation = "Horizontal";
            reportingStack.style.strokeColor = "none";
            reportingStack.style.textAlign = 'center';
            reportingStack.id = obj.id + "_textStack";

            reportingStack.children = [directReporting, indirectReporting];
            content.children = [innerStack, reportingStack];
        } else {
            content.children = [innerStack];
        }
        return content;
    }
    clicked = (args) => {

        if (args.actualObject !== undefined) {
            if (args.element instanceof Node) {
                this.props.updateUserInfo({ openOrgChartSidebar: true, orgChartSideBarData: args.actualObject.properties.data });
            }
        }        
    }


    onChangeOrientationView(e) {
        this.setState({ showLoader: true });
        setTimeout(() => {
            this.selectedOrientation = "TopToBottom";
            this.selectedSubTreeAlignment = "pattern7";
            this.setState({ viewType: e.itemData.Id, showLoader: false });
        }, 2000);
        this.props.updateUserInfo({ openOrgChartSidebar: false });
    }
    onChangeOrientation(e) {
        this.setState({ showLoader: true });
        this.selectedOrientation = e.value;
        setTimeout(() => {
            if (this.selectedOrientation === 'LeftToRight' || this.selectedOrientation === 'RightToLeft') {
                this.orgChartSubTreeAlignment = orgChartSubTreeAlignmentLimited;
            }
            else {
                this.orgChartSubTreeAlignment = orgChartSubTreeAlignment;
            }
            this.setState({ showLoader: false });

        }, 2000);
        this.props.updateUserInfo({ openOrgChartSidebar: false });
    }
    onChangeSubTreeAlignment(e) {
        switch (e.value) {
            case "pattern1":
                orien = "Vertical".toString(); typ = "Alternate"; break;
            case "pattern2":
                orien = "Vertical".toString(); typ = "Left"; break;
            case "pattern5":
                orien = "Vertical".toString(); typ = "Right"; break;
            case "pattern6":
                orien = "Horizontal".toString(); typ = "Balanced"; break;
            case "pattern7":
                orien = "Horizontal".toString(); typ = "Center"; break;
            case "pattern8":
                orien = "Horizontal".toString(); typ = "Left"; break;
            case "pattern9":
                orien = "Horizontal".toString(); typ = "Right"; break;
            default:
                orien = "Horizontal".toString(); typ = "Center";
        }
        this.diagramInstance.layout.getLayoutInfo = (node, options) => {
            if (e.value === "pattern2" || e.value === "pattern5") {
                options.offset = -50;
            }
            if (orien) {
                this.getLayoutInfo(node, options, orien, typ);
            }
        };

        this.diagramInstance.dataBind();
        this.diagramInstance.doLayout();
        this.props.updateUserInfo({ openOrgChartSidebar: false });
    }
    //set orientation and type of the Layout.
    getLayoutInfo(_node, options, orientation, type) {
        /* tslint:disable:no-string-literal */
        if (!options.hasSubTree) {
            options.orientation = orientation;
            options.type = type;
        }
    }
    // this.setState({ selectedTeamMember: authUser },()=>this.getOrgChartData("New"))
    onSelectTeamMember = (e) => {
        if (e.itemData !== null) {
            this.setState({ selectedTeamMember: e.itemData.Id, showLoader: true })
            this.getOrgChartData("other");
        }
    }
    render() {
        const { pageHeight, pageWidth, Data, showLoader, viewType, selectedTeamMember, dropDownData } = this.state;
        const stylesNew = `
            /* Tooltip container */
            .tooltip {
            position: relative;
            display: inline-block;
            border-bottom: 1px dotted black; 
            }

            /* Tooltip text */
            .tooltip .tooltiptext {
            visibility: hidden;
            width: 120px;
            background-color: black;
            color: #fff;
            text-align: center;
            padding: 5px 0;
            border-radius: 6px;
            
            position: absolute;
            z-index: 1;
            }

            /* Show the tooltip text when you mouse over the tooltip container */
            .tooltip:hover .tooltiptext {
            visibility: visible;
            }
            `
        return (<div className="control-pane">
            <style>{stylesNew}</style>
            {showLoader && <Progressbar />}
            <div style={{ width: '100%', height: "45px" }}>
                <div style={{ display: 'inline', height: "45px", lineHeight: "37px", fontWeight: "bold", color: "black" }}>
                    <div style={{ marginLeft: '810px', marginTop: '10px', width: '265px' }}>
                        <DropDownListComponent
                            id={`orgChartOrientationView`}
                            name={`orgChartOrientationView`}
                            dataSource={this.orgchartViews}
                            fields={{ text: "Name", value: "Id" }}
                            placeholder="View"
                            value={viewType}
                            popupHeight="220px"
                            cssClass="e-outline"
                            floatLabelType="Auto"
                            allowCustom={false}
                            change={(e) => this.onChangeOrientationView(e)}
                        // change={(e) => this.setState({viewType:e.itemData.Value})}
                        />
                    </div>
                    <div style={{ marginLeft: '1095px', marginTop: '-40px', width: '265px' }}>
                        <DropDownListComponent
                            id={`orgChartOrientation`}
                            name={`orgChartOrientation`}
                            dataSource={this.orgChartOrientation}
                            fields={{ text: "Name", value: "Id" }}
                            placeholder="Orientation"
                            value={this.selectedOrientation}
                            popupHeight="220px"
                            cssClass="e-outline"
                            floatLabelType="Auto"
                            allowCustom={false}
                            //   data-msg-containerid={`veriableLinkErr${index}`}
                            change={(e) => this.onChangeOrientation(e)}
                        />
                    </div>
                    <div style={{ marginLeft: '1375px', marginTop: '-40px', width: '265px' }}>
                        <DropDownListComponent
                            id={`orgChartSubTreeAlign`}
                            name={`orgChartSubTreeAlign`}
                            dataSource={this.orgChartSubTreeAlignment}
                            fields={{ text: "Name", value: "Id" }}
                            placeholder="Sub Tree Alignment"
                            value={this.selectedSubTreeAlignment}
                            popupHeight="220px"
                            cssClass="e-outline"
                            floatLabelType="Auto"
                            allowCustom={false}
                            //   data-msg-containerid={`veriableLinkErr${index}`}
                            change={(e) => this.onChangeSubTreeAlignment(e)}
                        />
                    </div>
                    <div style={{ marginLeft: '1379px', marginTop: '-43px', width: '465px', textAlign: 'right' }}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils} >
                            <KeyboardDatePicker style={{ width: '40%' }}
                                openTo="month"
                                views={["year", "month"]}
                                id="orgChartDate"
                                name="orgChartDate"
                                format="MMM-yyyy"
                                KeyboardButtonProps={{ 'aria-label': 'change date', }}
                                value={this.selectedDate !== null ? new Date(this.selectedDate) : new Date()}
                                onChange={(date) =>
                                    this.onDateChange(date)
                                }
                                //onChange={this.onDateChange}
                                TextFieldComponent={this.TextFieldComponent}
                                inputVariant="outlined"
                                variant="inline"
                                autoOk
                                minDate={new Date("2022-06-01")}
                                maxDate={new Date()}
                            //label="Org Chart Month *"
                            />
                        </MuiPickersUtilsProvider>
                    </div>
                    <div style={{ marginLeft: '525px', marginTop: '-40px', width: '265px' }}>
                        <DropDownListComponent
                            id={`orgChartEmpSelect`}
                            name={`orgChartEmpSelect`}
                            dataSource={dropDownData}
                            fields={{ text: "Name", value: "Id" }}
                            placeholder="Select Team Member"
                            value={selectedTeamMember}
                            popupHeight="220px"
                            cssClass="e-outline"
                            floatLabelType="Auto"
                            allowCustom={false}
                            allowFiltering={true}
                            //   data-msg-containerid={`veriableLinkErr${index}`}
                            change={(e) => this.onSelectTeamMember(e)}
                        />
                    </div>
                </div>
            </div>
            <div className="col-lg-12" style={{ height: pageHeight - 90, width: pageWidth - 25, overflow: "hidden", border: "solid 1px", borderColor: "#C0C0C0", backgroundColor: "#FFF" }}>
                {/* <div className="content-wrapper" style={{height : pageHeight - 82, width: pageWidth - 38}}> */}
                {/* <button onClick={this.butnClick}>stes</button> */}
                {Data.length > 0 && !showLoader &&
                    <DiagramComponent id="diagram"
                        legendSettings={this.legendSettings}
                        zoomSettings={this.zoomsettings}
                        ref={diagram => (this.diagramInstance = diagram)}
                        width={pageWidth - 43}
                        height={pageHeight - 91}
                        dragOver={false}
                        textWrap="wrap"
                        // constraints:DiagramConstraints
                        tool={DiagramTools.ZoomPan | DiagramTools.SingleSelect} // default value is 'Default'
                        // scrollSettings={{ scrollLimit: "Infinity" }, {
                        //     autoScrollBorder: {
                        //         left: 100,
                        //         right: 100,
                        //         top: 100,
                        //         bottom: 100
                        //     }
                        // }} //Sets the constraints of the SnapSettings

                        snapSettings={{ constraints: SnapConstraints.None }} //Configrues organizational chart layout
                        layout={{
                            type: "OrganizationalChart",
                            margin: { top: 20 },
                            orientation: this.selectedOrientation,
                            getLayoutInfo: (_node, tree) => {
                                if (!tree.hasSubTree) {
                                    tree.orientation = "Horizontal";
                                    tree.type = "Center";
                                }
                            }
                        }} //Sets the parent and child relationship of DataSource.
                        dataSourceSettings={{
                            id: "Id",
                            parentId: "ReportingPerson",
                            dataSource: new DataManager(Data)
                        }} //Sets the default values of Node
                        getNodeDefaults={(obj, _diagram) => {
                            obj.height = 50;
                            obj.style = { fill: "transparent", strokeWidth: 2 };
                            obj.expandIcon = {
                                height: 15,
                                width: 15,
                                shape: "Minus",
                                fill: "lightgray",
                                offset: { x: 0.5, y: 1 }
                            };
                            obj.isExpanded = obj.properties.data.IsExpanded === 1;
                            obj.collapseIcon.offset = { x: 0.5, y: 1 };
                            obj.collapseIcon.height = 15;
                            obj.collapseIcon.width = 15;
                            obj.collapseIcon.shape = "Plus";
                            obj.collapseIcon.fill = "lightgray";
                            obj.constraints = NodeConstraints.Default & ~NodeConstraints.Select;
                            return obj;
                        }} //Sets the default values of connector
                        getConnectorDefaults={(connector, _diagram) => {

                            connector.targetDecorator.shape = "None";
                            connector.type = "Orthogonal";
                            connector.constraints = ConnectorConstraints.None & ~ConnectorConstraints.Select
                            return connector;
                        }}
                        //customization of the node.
                        setNodeTemplate={(obj, diagram) => {
                            if (viewType === '1') return this.setNodeTemplate(obj, diagram);
                            else if (viewType === '0') return this.setNodeMinimumViewTemplate(obj, diagram);
                        }}
                        click={(args) => {
                            this.clicked(args)
                        }}
                    >
                        <Inject services={[DataBinding, HierarchicalTree]} />
                    </DiagramComponent>

                }
                {Data.length === 0 && !showLoader &&
                    <span style={{ height: pageHeight - 81, width: pageWidth, textAlign: 'center', paddingTop: 100 }}><h2 style={{ marginTop: 300 }}>No Data Available For Selected Month</h2></span>
                }
                {/* </div> */}
            </div>
            {
                Data.length > 0 && !showLoader &&
                <div className="col-lg-2" id='overview-div-show-hide' style={{
                    width: "35%",
                    padding: "0px",
                    right: "30px",
                    bottom: "20px",
                    border: "#eeeeee",
                    borderStyle: "solid",
                    boxShadow: "0px 2px 2px rgba(0,0,0,0.3)",
                    background: "#f7f7f7",
                    position: "absolute",
                }}>
                    <OverviewComponent id="overview" style={{ top: "30px" }} sourceID="diagram" width={"100%"} height={"150px"} />

                </div>
            }
        </div >);
    }
}
const mapStateToProps = ({ auth, meetingmaster }) => {
    const { authUser } = auth;
    const { executiveAssistant } = meetingmaster;
    return { authUser, executiveAssistant };
};
export default connect(mapStateToProps, { updateUserInfo })(OrgChartConmponent);
