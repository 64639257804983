import React from 'react';
import { ConnectedRouter } from 'connected-react-router'
import { Provider } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import configureStore, { history } from '../src/redux/store/store'; //
import Layout from './app/layout/layout';
import ErrorBoundary from "../src/components/error/errorboundary";
import LogIn from './app/layout/login';
import Logout from './components/logout/logout';
import Error404 from './components/error/error404';
import InvalidUrl from './components/invalidurlpage/invalidurl';


export const store = configureStore();

const App = () =>
  <Provider store={store}>
    <ErrorBoundary>
      <ConnectedRouter history={history}>
        <Switch>
          <Route path="/login" component={LogIn} exact />
          <Route path="/logout" component={Logout} exact />
          <Route path="/error" component={Error404} exact />
          <Route path="/invalidurl" component={InvalidUrl} exact />
          <Route path="/" component={Layout} />

       
        </Switch>
      </ConnectedRouter>
    </ErrorBoundary>
 </Provider>
  
export default App;