import React, { useRef, useEffect, useState } from "react";
import { connect } from "react-redux";
import {
  updateLnAData,
  applyLeave,
} from "../../../../redux/actions/lna/lnaaction";
import LnAService from "../../../../services/lna/lnaservice";
import ProgressBar from "../../../progressbar/progressbar";

import { VIEWDAILY } from "../../../../redux/constant/meetingcategoryconstant";
import monthlyViewIcon from "../../../../assets/svg/monthView.svg";
import weeklyViewIcon from "../../../../assets/svg/weekView.svg";
import { Tooltip } from "@material-ui/core";

import {
  formatDateToYYYYMMDD,
  generateExcelFile,
  getBackGroundColor,
} from "../../../../app/other/commonfunction";
const sampleCss = `
.weeklyReportTable table th {
    padding-top:8px !important;
    padding-bottom:8px !important;
    padding-left: 8px !important;
    padding-right: 8px !important;
    border-bottom: 1px solid #dbdbdd;
    border-top:1px solid #dbdbdd;
    border-collapse: collapse;
    width:20%;
    color: '#939399';
    font-size:12px !important;
    font-famly:'Circular Std';
}

.weeklyReportTable table td {

    padding-right: 8px !important;
    padding-left: 8px !important;
    padding-top:8px !important;
    padding-bottom:8px !important;
    border-bottom: 1px solid #dbdbdd;
    border-top:1px solid #dbdbdd;
    border-collapse: collapse;
    font-size:14px !important;
    colour:black;
    font-weight: 100 !important;
    font-family: 'Circular Std';
    width:20%;
}
.e-outline.e-input-group, .e-outline.e-input-group.e-control-wrapper, .e-outline.e-float-input, .e-outline.e-float-input.e-control-wrapper {
    font-size: 14px;
}
`;
const sampleCssSmallisOneTwentyFiveInSixteenHundred = `
    .weeklyReportTable table th {
        padding-top:4px !important;
        padding-bottom:4px !important;
        padding-left: 8px !important;
        padding-right: 8px !important;
        border-bottom: 1px solid #dbdbdd;
        border-top:1px solid #dbdbdd;
        border-collapse: collapse;
        width:20%;
        color: '#939399';
        font-size:11px !important;
        font-famly:'Circular Std';
       
    }
    
    .weeklyReportTable table td {
    
        padding-right: 8px !important;
        padding-left: 8px !important;
        padding-top:4px !important;
        padding-bottom:4px !important;
        border-bottom: 1px solid #dbdbdd;
        border-top:1px solid #dbdbdd;
        border-collapse: collapse;
        font-size:10px !important;
        colour:black;
        font-weight: 100 !important;
        font-family: 'Circular Std';
        width:20%;
    }
    .e-outline.e-input-group, .e-outline.e-input-group.e-control-wrapper, .e-outline.e-float-input, .e-outline.e-float-input.e-control-wrapper {
        font-size: 12px;
`;
const sampleCssisSixteenEighty = `
.weeklyReportTable table th {
    padding-top:5px !important;
    padding-bottom:5px !important;
    padding-left: 8px !important;
    padding-right: 8px !important;
    border-bottom: 1px solid #dbdbdd;
    border-top:1px solid #dbdbdd;
    border-collapse: collapse;
    width:20%;
    color: '#939399';
    font-size:12px !important;
    font-famly:'Circular Std';
   
}

.weeklyReportTable table td {

    padding-right: 8px !important;
    padding-left: 8px !important;
    padding-top:6px !important;
    padding-bottom:6px !important;
    border-bottom: 1px solid #dbdbdd;
    border-top:1px solid #dbdbdd;
    border-collapse: collapse;
    font-size:12px !important;
    colour:black;
    font-weight: 100 !important;
    font-family: 'Circular Std';
    width:20%;
}
.e-outline.e-input-group, .e-outline.e-input-group.e-control-wrapper, .e-outline.e-float-input, .e-outline.e-float-input.e-control-wrapper {
    font-size: 12px;
}
`;
const sampleCssSmall = `
.weeklyReportTable table th {
    padding-top:6px !important;
    padding-bottom:6px !important;
    padding-left: 8px !important;
    padding-right: 8px !important;
    border-bottom: 1px solid #dbdbdd;
    border-top:1px solid #dbdbdd;
    border-collapse: collapse;
    width:20%;
    color: '#939399';
    font-size:11px !important;
    font-famly:'Circular Std';
   
}

.weeklyReportTable table td {

    padding-right: 8px !important;
    padding-left: 8px !important;
    padding-top:6px !important;
    padding-bottom:5px !important;
    border-bottom: 1px solid #dbdbdd;
    border-top:1px solid #dbdbdd;
    border-collapse: collapse;
    font-size:10px !important;
    colour:black;
    font-weight: 100 !important;
    font-family: 'Circular Std';
    width:20%;
}
.e-outline.e-input-group, .e-outline.e-input-group.e-control-wrapper, .e-outline.e-float-input, .e-outline.e-float-input.e-control-wrapper {
    font-size: 12px;
}
`;
const isThirteenHundredBookmarkOnSampleCss = `
.weeklyReportTable table th {
    padding-top:4px !important;
    padding-bottom:4px !important;
    padding-left: 8px !important;
    padding-right: 8px !important;
    border-bottom: 1px solid #dbdbdd;
    border-top:1px solid #dbdbdd;
    border-collapse: collapse;
    width:20%;
    color: '#939399';
    font-size:11px !important;
    font-famly:'Circular Std';
   
}

.weeklyReportTable table td {

    padding-right: 8px !important;
    padding-left: 8px !important;
    padding-top:4px !important;
    padding-bottom:4px !important;
    border-bottom: 1px solid #dbdbdd;
    border-top:1px solid #dbdbdd;
    border-collapse: collapse;
    font-size:10px !important;
    colour:black;
    font-weight: 100 !important;
    font-family: 'Circular Std';
    width:20%;
}
.e-outline.e-input-group, .e-outline.e-input-group.e-control-wrapper, .e-outline.e-float-input, .e-outline.e-float-input.e-control-wrapper {
    font-size: 12px;
}
`;
const isTwelve80SampleCss = `
.weeklyReportTable table th {
    padding-top:3px !important;
    padding-bottom:3px !important;
    padding-left: 8px !important;
    padding-right: 8px !important;
    border-bottom: 1px solid #dbdbdd;
    border-top:1px solid #dbdbdd;
    border-collapse: collapse;
    width:20%;
    color: '#939399';
    font-size:9px !important;
    font-famly:'Circular Std';
   
}

.weeklyReportTable table td {

    padding-right: 8px !important;
    padding-left: 6px !important;
    padding-top:4px !important;
    padding-bottom:4px !important;
    border-bottom: 1px solid #dbdbdd;
    border-top:1px solid #dbdbdd;
    border-collapse: collapse;
    font-size:9px !important;
    colour:black;
    font-weight: 100 !important;
    font-family: 'Circular Std';
    width:20%;
}
.e-outline.e-input-group, .e-outline.e-input-group.e-control-wrapper, .e-outline.e-float-input, .e-outline.e-float-input.e-control-wrapper {
    font-size: 12px;
}
#weekRank{
    padding: 0px !important;
}
`;
const is1024SampleCss = `
.weeklyReportTable table th {
    padding-top:3px !important;
    padding-bottom:3px !important;
    padding-left: 8px !important;
    padding-right: 8px !important;
    border-bottom: 1px solid #dbdbdd;
    border-top:1px solid #dbdbdd;
    border-collapse: collapse;
    width:200px;
    color: '#939399';
    font-size:9px !important;
    font-famly:'Circular Std';
   
}

.weeklyReportTable table td {

    padding-right: 8px !important;
    padding-left: 8px !important;
    padding-top:4px !important;
    padding-bottom:4px !important;
    border-bottom: 1px solid #dbdbdd;
    border-top:1px solid #dbdbdd;
    border-collapse: collapse;
    font-size:8px !important;
    colour:black;
    font-weight: 100 !important;
    font-family: 'Circular Std';
    width:200px;
}
.e-outline.e-input-group, .e-outline.e-input-group.e-control-wrapper, .e-outline.e-float-input, .e-outline.e-float-input.e-control-wrapper {
    font-size: 10px;
}

`;
export const WeeklyReport = (props) => {
  /**NOSONAR */
  const {
    teamId,
    categoryName,
    authUser,
    startMonthMonthlyReport,
    showDownloadMyReportButton,
    getFullUserInfo,
    isLoading,
    viewClicked
  } = props;
  const [isLoader, setIsLoading] = useState(true);
  const [allData, setAllData] = useState([]);
  const isSixteenHundred = window.innerHeight < 782;
  const isFourteenHundred =
    window.innerHeight < 782 && window.innerWidth < 1441;
  const isThirteenHundred = window.innerHeight < 650;
  const isOneTwentyFiveInSixteenHundred = window.innerHeight < 603;

  const [height, setHeight] = useState(
    window.innerHeight ||
    document.documentElement.clientHeight ||
    document.body.clientHeight
  );

  const setHeightBasedOnInnerWidth = (width) => {
    const heightMap = {
      1920: 320,
      1912: 320,
      1872: 318,
      1680: 336,
      1632: 320,
      1440: 310,
      1488: 310,
      1432: 310,
      1280: 295,
      1536: 310,
      1528: 310,
      1344: window.innerHeight === 723 ? 321 : 308,
      1392: 308,
      1366: 298,
      1358: 298,
      1318: 285,
      1296: 308,
      1272: 295,
      1232: 290,
      1152: 296,
      1144: 294,
      1104: 290,
      1072: 300,
      1024: 295,
      976: 280,
    };

    return (
      heightMap[width] ||
      window.innerHeight - (14 + 73 + 15 + 68 + 15 + 32 + 39 + 51 - 12)
    );
  };


  /*
     Author : Amit Kumar
     desc:get weekly report on basis of month
     */

  const getWeeklyReportData = async () => {
    /**NOSONAR */
    if (startMonthMonthlyReport && teamId) {
      props.updateLnAData({ isLoading: true })
      const WeeklyReportData = await LnAService.getWeeklyReportData(
        teamId,
        "WEEKREPORT",
        startMonthMonthlyReport && startMonthMonthlyReport.split(" ")[0]
      );
      if (WeeklyReportData.Result) {
        setAllData(WeeklyReportData.Result);
      }
      props.updateLnAData({ isLoading: false })
      setTimeout(() => {
        props.updateLnAData({ viewClicked: false })
      }, 1000);
    }
  };

  /*
     Author : Amit Kumar
     desc:get background color of day break
     dayBreakNo: 1-->full day
     dayBreakNo: 0.5--> half day, 0.25 --> quarter day
     dayBreakType 1 --> full day, 2--> first half, 3 --> second half, 4--> quarter day
     */


  const getMon_yyy = (mmm_yy) => {
    let date = new Date(mmm_yy);
    let a = date.toString().split(" ");
    return `${a[1]} - ${a[3].split("0")[1]}`;
  };
  //
  const getClassName = () => {
    if (isOneTwentyFiveInSixteenHundred) {
      return "flexisOneTwentyFiveInSixteenHundred";
    } else if (isThirteenHundred) {
      return "flex-items-small";
    } else if (isFourteenHundred || isSixteenHundred) {
      return "flex-items-fourteen";
    } else {
      return "flex-items";
    }
  };

  const getSampleStyle = () => {
    if (window.innerHeight === 609) {
      return isThirteenHundredBookmarkOnSampleCss;
    }
    if (window.innerWidth === 1280) {
      return isTwelve80SampleCss;
    }
    if (window.innerWidth === 1024) {
      return is1024SampleCss;
    } else if (isOneTwentyFiveInSixteenHundred) {
      return sampleCssSmallisOneTwentyFiveInSixteenHundred;
    } else if (isThirteenHundred) {
      return sampleCssSmall;
    } else if (isFourteenHundred || isSixteenHundred) {
      return sampleCssSmallisOneTwentyFiveInSixteenHundred;
    } else if (sampleCssisSixteenEighty) {
      return sampleCssisSixteenEighty;
    } else {
      return sampleCss;
    }
  };
  /**
   *@author: Somesh Dwivedi
   *@description : getting date and day in different key from date string
   */
  const getDateAndDayFromDateString = (dateString) => {
    const components = dateString.split(" ");

    // Extract the date and day
    const date = components[0]; // "02-Sep-2023"
    const day = components[1]; // "Saturday"
    return {
      date,
      day,
    };
  };
  /**
   *@author: Somesh Dwivedi
   *@description : getting data to of my report to convert it into excell
   */
  const getMyReportData = async () => {
    const WeeklyReportData = await LnAService.getWeeklyReportData(
      teamId,
      "WEEKREPORT",
      startMonthMonthlyReport && startMonthMonthlyReport.split(" ")[0]
    );
    const fullUserInfo = getFullUserInfo(+teamId);
    let currentDate = new Date();
    const formattedDate = formatDateToYYYYMMDD(currentDate);
    const fileName = `${formattedDate}_Attendance Report - Weekly`;
    // eslint-disable-next-line
    const columns = {
      weekNo: "Week No.",
      date: "Attendance Date",
      day: "Day",
      // dayBreak: "Day Break",
      // dayBreakType: "Day Break Type",
      workingHrs: "Working Hours",
      presentStatus: "Present Status",
      empCode: "Team Member Id",
      empName: "Team Member Name",
    };
    const dataTobeExported = WeeklyReportData.Result.map((ele, index) => {
      const { day, date } = getDateAndDayFromDateString(ele.dtAttendance);
      // const { dayBreak, dayBreakType } = getDayBreakType(
      //   ele.dayBreakNo,
      //   ele.dayBreakType
      // );
      return {
        [columns.empCode]: fullUserInfo?.EmpCode,
        [columns.empName]: fullUserInfo?.name,
        [columns.weekNo]: "Week " + ele.weekNo,
        [columns.date]: date,
        [columns.day]: day,
        [columns.workingHrs]: ele.workingHrs,
        [columns.presentStatus]: ele.presentStatus,
        // [columns.dayBreak]: dayBreak,
        // [columns.dayBreakType]: dayBreakType,
      };
    });
    generateExcelFile([dataTobeExported, []], fileName);
  };

  useEffect(() => {
    setHeight(
      window.innerHeight - setHeightBasedOnInnerWidth(window.innerWidth)
    );
  }, []);

  useEffect(() => {
    if (startMonthMonthlyReport && teamId && viewClicked) {
      getWeeklyReportData();
      props.updateLnAData({ viewClicked: false })
    }
  }, [viewClicked]);

  return (
    <>
      {<style>{getSampleStyle()}</style>}
      <div className="col-lg-10 col-md-10 col-sm-10 col-xs-10 mt-md custom-margin-left">
        <div
          className="col-lg-12 col-md-12 col-sm-12 col-xs-12 allTeamReportHeader" 
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            backgroundColor: "#4B4B55",            
            borderStartEndRadius: "0.4em",
            borderStartStartRadius: "0.4em",
            paddingLeft: "1rem",
            fontWeight: 600,
            width: "100%",
          }}
        >
          <div
            style={{
              color: "white",
              fontStyle: "Circular Std Book",
              fontSize: window.innerHeight > 700 ? "1rem" : "14px",
              fontFamily: "Arial",
              display: "flex",
              alignItems: "center !important",
            }}
          >
            {startMonthMonthlyReport
              ? getMon_yyy(startMonthMonthlyReport)
              : ""}
          </div>
          <div style={{ display: "flex" }}>
            {showDownloadMyReportButton === "Y" && (
              <div
                className="leaveBalanceWeeklyReport"
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={getMyReportData}
              >
                <span className="spanleaveBalanceYearlyReport">
                  Download
                </span>
              </div>
            )}
            {categoryName === VIEWDAILY && (
              <div
                className="mr-md"
                style={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  backgroundColor: "#fff",
                  borderRadius: "3px",
                  padding: ".1rem .2rem",
                }}
              >
                <Tooltip title="View Weekly">
                  <div
                    style={{
                      backgroundColor: null,
                      borderRadius: "3px",
                    }}
                  >
                    <img
                      src={monthlyViewIcon}
                      alt=""
                      onClick={() => {
                        props.changeViewReport("weeklyReport");
                        props.updateLnAData({ viewClicked: true });
                      }}
                      style={{
                        width: "1.8rem",
                        height:
                          window.innerWidth > 1377 ? "1.8rem" : "1.6rem",
                        cursor: "pointer",
                        filter: null,
                      }}
                    />
                  </div>
                </Tooltip>
                <Tooltip title="View Monthly">
                  <div
                    style={{
                      backgroundColor: "#4B4B55",
                      borderRadius: "3px",
                    }}
                  >
                    <img
                      src={weeklyViewIcon}
                      alt=""
                      onClick={() => {
                        props.changeViewReport("monthlyReportMonth");
                      }}
                      style={{
                        width: "1.8rem",
                        height:
                          window.innerWidth > 1377 ? "1.8rem" : "1.6rem",
                        cursor: "pointer",
                        filter:
                          "invert(100%) sepia(99%) saturate(2%) hue-rotate(52deg) brightness(103%) contrast(101%)",
                      }}
                    />
                  </div>
                </Tooltip>
              </div>
            )}
          </div>
        </div>

        <div
          className="col-lg-12 col-md-12 col-sm-12 col-xs-12 reportParent"
          style={{
            backgroundColor: "white",
            height: height,
          }}
        >
          <div className="row1">
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 atc-no-paddding reportParent">
              <div
                className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                style={{ border: "1px solid #dbdbdd" }}
              >
                <div
                  className="weeklyReportTable"
                  style={{
                    height: height,
                    overflowY: "auto",
                  }}
                >
                  <table
                    style={{
                      borderRight: "1px solid #dbdbdd",
                      paddingTop: 0,
                    }}
                  >
                    <tr
                      style={{
                        position: "sticky",
                        top: -1,
                        zIndex: 1,
                        background: "white",
                      }}
                    >
                      <th style={{ color: "#939399" }}>Date</th>
                      <th
                        style={{
                          width: "30% !important",
                          color: "#939399",
                          textAlign: "center",
                        }}
                      >
                        Daybreak
                      </th>
                      <th style={{ color: "#939399" }}>Time In Business</th>
                      <th style={{ color: "#939399", textAlign: "center" }}>
                        Present Status
                      </th>
                    </tr>
                    {allData.map((each, id) => {
                      let val = getBackGroundColor(
                        each.dayBreakNo,
                        each.dayBreakType,
                        each.attendenceColour,
                        each.leaveColour,
                        each.dayBreakType2,
                        each.dayBreakNo2,
                        each.attendenceColour2,
                        each.leaveColour2,
                        each.type1
                      );
                      const isUniqueEntry =
                        id === 0 || each.weekNo !== allData[id - 1].weekNo;

                      return (
                        <React.Fragment
                          key={`weeklyReport-${id}` /**NOSONAR */}
                        >
                          {isUniqueEntry && (
                            //   <tr>
                            //     <td style={{textAlign:'center'}}>Week No.: {each.weekNo}</td>
                            //   </tr>
                            <tr id="weekRank">
                              <td
                                colSpan="4"
                                style={{ textAlign: "center" }}
                              >
                                <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                  <div
                                    style={{
                                      backgroundColor: "#F2F2F2",
                                      height:
                                        window.innerHeight > 700
                                          ? "2rem"
                                          : "1rem",
                                      display: "flex",
                                      alignItems: "center",
                                      fontWeight: 600,
                                      width: "100%", // Make the div take up full width
                                    }}
                                  >
                                    <div
                                      className="col-xs-12 col-sm-12 col-lg-12 col-md-12"
                                      style={{
                                        color: "black",
                                        fontStyle: "Circular Std Book",
                                        fontSize:
                                          window.innerHeight > 700
                                            ? ".8rem"
                                            : "10px",
                                        fontFamily: "Arial",
                                      }}
                                    >
                                      {`Week - ${each.weekNo}`}
                                    </div>
                                  </div>
                                </div>
                              </td>
                            </tr>
                          )}
                          <tr>
                            <td>{each.dtAttendance}</td>
                            <td>
                              <div
                                className="flex-container-table"
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  width: "100%",
                                }}
                              >
                                <div
                                  className={getClassName()}
                                  style={{ backgroundColor: val[0] }}
                                ></div>
                                <div
                                  className={getClassName()}
                                  style={{ backgroundColor: val[1] }}
                                ></div>
                                <div
                                  className={getClassName()}
                                  style={{ backgroundColor: val[2] }}
                                ></div>
                                <div
                                  className={getClassName()}
                                  style={{ backgroundColor: val[3] }}
                                ></div>
                              </div>
                            </td>
                            <td>{each.workingHrs}</td>
                            <td style={{ textAlign: "center" }}>
                              {each.presentStatus}
                            </td>
                          </tr>
                        </React.Fragment>
                      );
                    })}
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isLoading && (
        <div
          style={{
            zIndex: 999999,
            position: "absolute",
            marginTop: "20%",
            marginLeft: "49%",
          }}
        >
          <ProgressBar />
        </div>
      )}
    </>
  );
};
WeeklyReport.propTypes = {
  //   second: PropTypes.third
};

const mapStateToProps = ({ meetingcategory, lnareducer, auth }) => {
  const { authUser } = auth;
  const { categoryName } = meetingcategory;
  const { applyForData, startMonth, startMonthMonthlyReport, viewClicked, isLoading } = lnareducer;

  return {
    authUser,
    categoryName,
    applyForData,
    startMonth,
    startMonthMonthlyReport,
    isLoading,
    viewClicked
  };
};

export default connect(mapStateToProps, { updateLnAData, applyLeave })(
  WeeklyReport
);
