// Author by:- Prashant Waphare | 31-08-2020 | Add all action home office.
/**
 * @author: Yogita Ghodake
 * @description This use for BusinessUnitFunction action.
 * Created: 15-09-2021
 */
import {
  SAVE_ROLE_DATA,
  ADD_EDIT_ROLE_DETAILS,
  SAVE_MODULE_DATA,
  ADD_EDIT_MODULE_DETAILS,
  ADD_EDIT_PRACTICE_AREA,
  ADD_EDIT_SUB_BUSINESS_UNIT_FUNCTION,
  UPDATE_ADMIN_REDUX_DATA,
  ADD_EDIT_LAYER_MAPPING_FUNCTION,
  ADD_EDIT_SUB_LAYER_MAPPING_FUNCTION,
  SAVE_BUSINESS_UNIT_FUNCTION,
  SAVE_LAYER_MASTER_FUNCTION,
  SAVE_SUB_LAYER_MASTER_FUNCTION,
  UPDATE_BUSINESS_FUNCTION_DATA,
  UPDATE_LAYER_MASTER_DATA,
  UPDATE_SUB_LAYER_MASTER_DATA,
  SAVE_USER_ROLE_MASTER_FUNCTION,
  UPDATE_USER_ROLE_MASTER_DATA,
  UPDATE_FOLDER_MANAGEMENT_DATA,
  SAVE_FOLDER_MANAGEMENT_FUNCTION,
  UPDATE_FINANCIAL_YEAR,
  ADD_EDIT_EMAIL_MAPPING,
  ADD_EDIT_EMAIL_MAPPING_DATA

} from "../../constant/admin/adminconstant";
import { HANDLE_TMLC_ADMIN_POPUP } from "../../constant/admin/adminconstant";

export const saveRoleModuleInfoRedux = (data) => {
  return {
    type: SAVE_ROLE_DATA,
    payload: data,
  };
};
export const addEditRoleDetails = (data) => {
  return {
    type: ADD_EDIT_ROLE_DETAILS,
    payload: data,
  };
};
export const saveModuleInfoRedux = (data) => {
  return {
    type: SAVE_MODULE_DATA,
    payload: data,
  };
};
export const addEditModuleDetails = (data) => {
  return {
    type: ADD_EDIT_MODULE_DETAILS,
    payload: data,
  };
};

export const addEditPracticeArea = (data) => {
  return {
    type: ADD_EDIT_PRACTICE_AREA,
    payload: data,
  };
};
export const updateAdminReduxData = (data) => {
  return {
    type: UPDATE_ADMIN_REDUX_DATA,
    payload: data,
  };
};
export const saveBusinessUnitFunction = (data) => {
  return {
    type: SAVE_BUSINESS_UNIT_FUNCTION,
    payload: data,
  };
};
export const saveLayerMasterFunction = (data) => {
  return {
    type: SAVE_LAYER_MASTER_FUNCTION,
    payload: data,
  };
};

export const saveUserRoleMasterFunction = (data) => {
  return {
    type: SAVE_USER_ROLE_MASTER_FUNCTION,
    payload: data,
  };
};


export const addEditSubBusinessUnitFunction = (data) => {
  return {
    type: ADD_EDIT_SUB_BUSINESS_UNIT_FUNCTION,
    payload: data,
  };
};
export const saveSubLayerMasterFunction = (data) => {
  return {
    type: SAVE_SUB_LAYER_MASTER_FUNCTION,
    payload: data,
  };
};

export const updateBusinessFunctionRedux = (data) => {
  return {
    type: UPDATE_BUSINESS_FUNCTION_DATA,
    payload: data,
  };
};

export const addEditLayerMappingFunction = (data) => {
  return {
    type: ADD_EDIT_LAYER_MAPPING_FUNCTION,
    payload: data,
  };
};
export const updateLayerMasterRedux = (data) => {
  return {
    type: UPDATE_USER_ROLE_MASTER_DATA,
    payload: data,
  };
};

export const updateUserRoleMasterRedux = (data) => {
  return {
    type: UPDATE_LAYER_MASTER_DATA,
    payload: data,
  };
};

export const addEditLayerSubMappingFunction = (data) => {
  return {
    type: ADD_EDIT_SUB_LAYER_MAPPING_FUNCTION,
    payload: data,
  };
};
export const updateSubLayerMasterRedux = (data) => {
  return {
    type: UPDATE_SUB_LAYER_MASTER_DATA,
    payload: data,
  };
};



export const updateFolderManageFunction = (data) => {
  return {
    type: UPDATE_FOLDER_MANAGEMENT_DATA,
    payload: data,
  };
};

export const saveFolderManageFunction = (data) => {
  return {
    type: SAVE_FOLDER_MANAGEMENT_FUNCTION,
    payload: data,
  };
};

export const updateFinancialYear = (data) => {
  return {
    type: UPDATE_FINANCIAL_YEAR,
    payload: data,
  };
};

export const handlePopup = (data) => {
  return {
    type: HANDLE_TMLC_ADMIN_POPUP,
    payload: { openPopup: data }
  }
}

export const addEditEmailMapping = (data) => {
  return {
    type: ADD_EDIT_EMAIL_MAPPING,
    payload: data,
  };
};


export const emailMappingReduxUpdate = (data) => {
  return {
    type: ADD_EDIT_EMAIL_MAPPING_DATA,
    payload: data
  }
}
