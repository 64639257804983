import React from 'react'
import './tags.css';
    export default function TagsInput (props) {
        const [tags, setTags] = React.useState(props.tags);
        const removeTags = indexToRemove => {
            setTags([...tags.filter((_, index) => index !== indexToRemove)]);
            props.selectedTags([...tags.filter((_, index) => index !== indexToRemove)]);
        };
        const addTags = event => {
            event.target.value = myTrim(event.target.value);
            if (event.target.value !== "" 
            //&& event.target.value !== " "&& event.target.value !== "  "&& event.target.value !== "   "&& event.target.value !== "     "&& event.target.value !== "      "&& event.target.value !== "       "&& event.target.value !== "        "&& event.target.value !== "          "
                ) {
                setTags([...tags, event.target.value]);
                props.selectedTags([...tags, event.target.value]);
                event.target.value = "";
            }
        };
        const myTrim =(x) =>{
                    return x.replace(/^\s+|\s+$/gm,'');
        }
        return (
            <div className="tags-input col-lg-8">
                <ul id="tags">
                    {tags.map((tag, index) => (
                            tag!=="" &&
                            <li key={index} className="tag" >
                            <span>{tag}</span>
                            <span className='tag-close-icon'
                            onClick={() => removeTags(index)}
                            >
                            x
                            </span>
                            </li>
                    ))}
                </ul>
                <input
                    type="text"
                    onKeyDown={event => event.key === "Enter" ? addTags(event) : null}
                    placeholder="Press enter to add tags *"
                    onBlur={(event)=> addTags(event)}
                />
            </div>
        );
}
