import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { getCategoriesAndSections, getTicketSubCategories, getSubCategories } from '../../services/helpdesk/helpdeskServices'
import ComboBox from '../../utilities/comboboxcomponent'
import { pleaseProvideSubCategory, pleaseSelectCategory, pleaseSelectFunctionTemp, pleaseSelectSubCategory } from '../../assets/constants/constants'
import { FormValidator } from '@syncfusion/ej2-react-inputs'
import TextBox from '../../utilities/textboxcomponent'
import ProgressBar from '../progressbar/progressbar'
import { connect } from 'react-redux'
import { addEditSubCategory, helpdeskReduxUpdate } from '../../redux/actions/helpdesk/helpdeskaction'
import { LoadingIndicator } from '@syncfusion/ej2-react-grids'


const SubCategory = ({ state: stateData, addEditSubCategory: saveSubCategory, handleSaveRef, helpdeskReduxUpdate, loading }) => { //NOSONAR

  const { editItemId } = stateData
  const [state, setState] = useState({
    function: "",
    functionName: "",
    category: "",
    subCategory: "",
    customSubCategory: ""
  })
  const [subCategoryData, setSubCategoryData] = useState([])
  const [categoryData, setCategoryData] = useState([])
  const [sectionData, setSectionData] = useState([])
  const [ticketSubCategories, setTicketSubCategories] = useState([]);


  useEffect(() => {
    initialLoad()
    _getTicketSubCategories()
  }, [])
  const onSaveData = () => {
    let options = {
      rules: {
        functionErr: {
          required: [true, pleaseSelectFunctionTemp],
        },
        categoryErr: {
          required: [true, pleaseSelectCategory],
        },
        subCategoryErr: {
          required: [true, pleaseProvideSubCategory],
          minLength: [3, 'Please enter at least 3 characters'],
          maxLength: [50, 'Maximum 50 characters are allowed'],
          regex: [/^[a-zA-Z ]+$/, 'Special characters and numbers are not allowed']
        },
        customSubCategoryErr: {
          required: [true, pleaseSelectSubCategory],
        },
      },
    }
    setTimeout(() => {
      const addFormObject = new FormValidator(
        "#helpdesk-sub-category",
        options
      );
      if (addFormObject.validate()) {
        const payload = {
          CategoryId: state.category,
          SubCategoryId: stateData.editItemId ?? 0,
          SubCategoryName: state.subCategory.trim(),
          JitbitId: state.customSubCategory,
          Disabled: 0,
        }
        helpdeskReduxUpdate({ loading: true })
        saveSubCategory(payload);
      }
    }, 300);

  }

  useImperativeHandle(handleSaveRef, () => ({ onSaveData }))

  const initialLoad = async () => {
    helpdeskReduxUpdate({ loading: true })
    if ((editItemId > 0 && editItemId !== null)) {
      const [categoriesAndSections, result] = await Promise.all([await getCategoriesAndSections(), await getSubCategories(0, editItemId)])
      await getSubCategory(result[0].CategoryId, result[0].CategoryName)

      const sections = [...new Set(categoriesAndSections.map(obj => obj.SectionID))].map(SectionID => { return categoriesAndSections.find(obj => obj.SectionID === SectionID) })
      setSectionData(sections)
      const categories = categoriesAndSections.reduce((acc, item) => {
        const SectionID = item.SectionID
        if (acc.hasOwnProperty(SectionID)) {
          return { ...acc, [SectionID]: [...acc[SectionID], item] }
        } else {
          return { ...acc, [SectionID]: [item] }
        }
      }, {})
      setCategoryData(categories);

      setState(prevState => ({
        ...prevState,
        function: result[0].SectionId,
        category: result[0].CategoryId,
        subCategory: result[0].SubCategoryName,
        customSubCategory: result[0].JitbitId
      }))
      helpdeskReduxUpdate({ loading: false })

    } else {
      const categoriesAndSections = await getCategoriesAndSections()
      const sections = [...new Set(categoriesAndSections.map(obj => obj.SectionID))].map(SectionID => { return categoriesAndSections.find(obj => obj.SectionID === SectionID) })
      setSectionData(sections)
      const categories = categoriesAndSections.reduce((acc, item) => { //NOSONAR
        const SectionID = item.SectionID
        if (acc.hasOwnProperty(SectionID)) {
          return { ...acc, [SectionID]: [...acc[SectionID], item] }
        } else {
          return { ...acc, [SectionID]: [item] }
        }
      }, {})
      setCategoryData(categories)
      helpdeskReduxUpdate({ loading: false })
    }
  }

  const handleChange = (data, type) => {
    let options = {}
    switch (type) {
      case "function":
        options = {
          rules: {
            functionErr: {
              required: [true, pleaseSelectFunctionTemp],
            },
          },
        };
        setState(prevState => ({ ...prevState, functionName: data.target.itemData?.Section, category: "", customSubCategory: "", subCategory: "" }))
        break;
      case "category":
        options = {
          rules: {
            categoryErr: {
              required: [true, pleaseSelectCategory],
            },
          },
        };
        setState(prevState => ({ ...prevState, customSubCategory: "", subCategory: "" }))
        break;
      case "customSubCategory":
        options = {
          rules: {
            customSubCategoryErr: {
              required: [true, pleaseSelectSubCategory],
            },
            subCategoryErr: {
              required: [false, pleaseProvideSubCategory],
            },
          },
        };
        setState(prevState => ({ ...prevState, subCategory: data.target?.itemData?.OptionValue ? data.target?.itemData?.OptionValue : '' }))
        break;
      case "subCategory":
        options = {
          rules: {
            subCategoryErr: {
              required: [true, pleaseProvideSubCategory],
              minLength: [3, 'Please enter at least 3 characters'],
              maxLength: [50, 'Maximum 50 characters are allowed'],
              regex: [/^[a-zA-Z ]+$/, 'Special characters and numbers are not allowed']
            },
          },
        };
        break;
      default:
    }

    setState(prevState => ({ ...prevState, [type]: typeof data.value === 'string' ? data.value.trim() : data.value }))
    setTimeout(() => {
      const addFormObject = new FormValidator("#helpdesk-sub-category", options);
      addFormObject.validate();
    }, 100)
  }

  /**
 * Author: Chandrashekhar Bhagat
 * @description get Sub Category List based on Category.
 */
  const getSubCategory = (value, name) => {
    if (!value) return setSubCategoryData([])
    const matchedObject = ticketSubCategories.find(obj => obj.FieldName === 'Sub Category Mapping');
    if (matchedObject.SelectionComboOptions.length > 0) {
      const filteredArray = matchedObject.SelectionComboOptions.filter(obj => (obj.OptionValue.includes(state.functionName + ' > ' + name + ' > ') && !obj.IsDisabled)); // Filtering data according to category.
      const newArray = filteredArray.map(obj => {
        return {
          ...obj,
          OptionValue: obj.OptionValue.split(' > ')[2].trim()
        };
      });      
      setSubCategoryData(newArray);
    }
  }
  /**
  * Author: Somesh Dwivedi
  * @description To get ticketSubCategories.
  */
  const _getTicketSubCategories = async () => {
    try {
      const ticketSubCategoriesData = await getTicketSubCategories(12);
      setTicketSubCategories(ticketSubCategoriesData);
    } catch (err) {
      console.log(err, 'err in getting')
    }
  };


  return (
    <form id='helpdesk-sub-category'>
      <ComboBox
        id="function"
        fields={{ text: "Section", value: "SectionID" }}
        value={state.function}
        dataSource={sectionData}
        placeholder="Function *"
        floatLabelType="Auto"
        cssClass="e-outline"
        onChange={(e) => handleChange(e, "function")}
      />
      <ComboBox
        id="category"
        data-msg-containerid="categoryErr"
        name="categoryErr"
        dataSource={categoryData[state.function]}
        fields={{ text: "Name", value: "CategoryID" }}
        value={state.category}
        placeholder="Category  *"
        floatLabelType="Auto"
        cssClass="e-outline"
        onChange={(e) => {
          handleChange(e, "category")
          getSubCategory(e.target.value, e.target.itemData?.Name)
        }
        }
        htmlAttributes={{ maxlength: 100 }}
      />
      <ComboBox
        id="customSubCategory"
        data-msg-containerid="customSubCategoryErr"
        name="customSubCategoryErr"
        dataSource={subCategoryData}
        fields={{ text: "OptionValue", value: "OptionID" }}
        value={state.customSubCategory}
        placeholder="Custom Sub Category *"
        floatLabelType="Auto"
        cssClass="e-outline"
        onChange={(e) => handleChange(e, "customSubCategory")}
        htmlAttributes={{ maxlength: 100 }}
      />
      <TextBox
        id="subCategory"
        value={state.subCategory}
        placeholder="Sub Category Name *"
        floatLabelType="Auto"
        cssClass="e-outline"
        input={(e) => handleChange(e, "subCategory")}
      />
      {
        loading && (
          <div
            className='loader-new'
          >
            <ProgressBar />
          </div>
        )
      }
    </form>
  )
}
const mapStateToProps = ({ helpdeskreducer }) => { //nosonar
  const { loading } = helpdeskreducer
  return { loading }
}

export default connect(mapStateToProps, { addEditSubCategory, helpdeskReduxUpdate })(forwardRef(SubCategory))