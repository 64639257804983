/*
 * Author Somesh Dwivedi
 * Date  08-03-2024
 * @description This component is used to render the leave grid.
 * */
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Inject,
  Page,
  Filter,
  Sort
} from "@syncfusion/ej2-react-grids";
import {
  FROM_DATE,
  LEAVE_TYPE,
  NAME,
  NAME_ALIGN,
  NAME_WIDTH,
  NO_OF_LEAVES,
  ORDER,
  ORDER_ALIGN,
  ORDER_FIELD,
  ORDER_WIDTH,
  PRACTICE_AREA,
  PRACTICE_AREA_ALIGN,
  PRACTICE_AREA_FIELD,
  PRACTICE_AREA_WIDTH,
  TO_DATE,
  getGridColumn
} from '../../other/gridcolumns'
import { NAME_FIELD } from './../../other/gridcolumns';
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import { urlConfig } from "../../../config/config";
import './leavegridstyle.css';
import { toolTipStyle } from "../../../assets/constants/constants";

const LeaveGrid = ({ leaveData, activeTab, dataSourceChanged }) => {
  //User defined functions
  const onDataBinding = (pComponentId) => {
    try {
      setTimeout(() => {
        document.getElementById(pComponentId).ej2_instances[0].refresh();
      });
    } catch (error) {
      console.log(error);
    }
  };

  const rowDataBound = (args) => {
    if (args.row) {
      if (new Date(args.data.fromDate) < new Date()) {
        args.row.classList.add('below-30');
      }
    }
  }


  //Template to render the leave
  const leaveTypeTemplate = (rowData) => {
    const leaveContent = rowData.symbol + (rowData.leaveName ? ' - ' + rowData.leaveName : '');
    return (
      <TooltipComponent content={leaveContent} position="TopCenter" style={toolTipStyle}>
        {leaveContent}
      </TooltipComponent>
    );
  }
  //Template to render the date
  const dateTemplate = (rowData, dateType) => {
    const date = rowData[dateType + 'DayName'] + ' ' + rowData[dateType];
    return (
      <TooltipComponent content={date} position="TopCenter" style={toolTipStyle}>
        {date}
      </TooltipComponent>
    );
  }
  //user to render the icon when ever the profile image giving error
  const addDefaultSrc = (ev) => {
    ev.target.outerHTML = '<span  class="img-circle fas fa-user-circle leave-grid-no-profile-img" alt="" ></span>'
  }
  //Rendering profile pic of user
  const getUserPrifilePic = (imgName) => {
    return (imgName ?
      <img alt="Pic" src={
        urlConfig.tmlcApiUrl + 'document/E/' +// props.authUser +
        '0/' + imgName
      }
        onError={addDefaultSrc}
        className="leave-grid-profile-img"
      /> :
      <span
        className="img-circle fas fa-user-circle leave-grid-no-profile-img"
        alt="no pic"
      />
    )
  }
  //rendering name with profile pic of user
  const userNameTemplate = (rowData) => {
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {getUserPrifilePic(rowData.imgName)}
        <TooltipComponent
          content={rowData.userName}
          position="TopCenter"
          style={toolTipStyle}
        >
          {rowData.userName}
        </TooltipComponent>
      </div>
    );
  }

  const commomTemplate = (data) => {
    return (
      <TooltipComponent
        content={data}
        position="TopCenter"
        style={toolTipStyle}
      >
        {data}
      </TooltipComponent>
    )
  }
  return (
    <div>
      <div id='leaveHistoryLeaveBalanceGridNew'>
        <style>{activeTab === 'team' ? teamFilterIconCss : ownFilterIconCss}</style>
        <GridComponent
          id="leaveReportNew"
          dataSource={leaveData}
          height={'calc(100vh - 370px)'}
          rowHeight={48}
          rowDataBound={rowDataBound}
          allowFiltering={true}
          allowSorting={true}
          filterSettings={{
            type: "CheckBox",
          }}
          dataSourceChanged={() => dataSourceChanged("leaveReportNew")}
          onDataBinding={() => onDataBinding("leaveReportNew")}
          pageSettings={{ pageCount: 4, pageSize: 20 }}
          allowPaging
        >
          <ColumnsDirective>
            {
              activeTab === 'team' && <ColumnDirective
                template={userNameTemplate}
                key={NAME_FIELD}
                textAlign={NAME_ALIGN}
                field={NAME_FIELD}
                headerText={NAME}
                width={NAME_WIDTH}
                allowFiltering={true}
              />
            }
            {
              activeTab === 'team' && <ColumnDirective
                template={(rowData) => commomTemplate(rowData[PRACTICE_AREA_FIELD])}
                key={PRACTICE_AREA_FIELD}
                textAlign={PRACTICE_AREA_ALIGN}
                field={PRACTICE_AREA_FIELD}
                headerText={PRACTICE_AREA}
                width={PRACTICE_AREA_WIDTH}
                allowFiltering={true}
              />
            }
            {getGridColumn("leaveReportNew").map((item) => {
              switch (item.colName) {
                case LEAVE_TYPE:
                  return (
                    <ColumnDirective
                      //template={leaveTypeTemplate}
                      key={item.field}
                      textAlign={item.align}
                      field={item.field}
                      headerText={item.colName}
                      width={item.width}
                      allowFiltering={true}
                    />
                  )
                case FROM_DATE:
                  return (
                    <ColumnDirective
                      template={(rowData) => dateTemplate(rowData, 'startDate')}
                      key={item.field}
                      textAlign={item.align}
                      field={item.field}
                      headerText={item.colName}
                      width={item.width}
                      allowFiltering={true}
                      type="date"
                      format={{ type: "datetime", format: "dd-MMM-yy" }}
                      filter={{ params: { format: 'dd-MM-yyyy' }, type: 'Menu' }}
                    />
                  )
                case TO_DATE:
                  return (
                    <ColumnDirective
                      template={(rowData) => dateTemplate(rowData, 'endDate')}
                      key={item.field}
                      textAlign={item.align}
                      field={item.field}
                      headerText={item.colName}
                      width={item.width}
                      allowFiltering={true}
                    />
                  )
                case NO_OF_LEAVES:
                  return (
                    <ColumnDirective
                      key={item.field}
                      textAlign={item.align}
                      field={item.field}
                      headerText={item.colName}
                      width={item.width}
                      allowFiltering={true}
                    />
                  );
                default:
                  return (
                    <ColumnDirective
                      template={(rowData) => commomTemplate(rowData[item.field])}
                      key={item.field}
                      textAlign={item.align}
                      field={item.field}
                      headerText={item.colName}
                      width={item.width}
                      allowFiltering={true}
                    />
                  );
              }

            })}
            {
              activeTab === 'team' && <ColumnDirective
                template={(rowData) => commomTemplate(rowData[ORDER_FIELD])}
                key={ORDER_FIELD}
                textAlign={ORDER_ALIGN}
                field={ORDER_FIELD}
                headerText={ORDER}
                width={ORDER_WIDTH}
                allowFiltering={true}
              />
            }
          </ColumnsDirective>
          <Inject services={[Page, Filter, Sort]} />
        </GridComponent>
      </div>
    </div>
  )
}

export default LeaveGrid;

const teamFilterIconCss = `
  #leaveHistoryLeaveBalanceGridNew .e-grid .e-filtermenudiv::before {
      position:absolute !important;
      top:12px !important ;
      right :15% !important ;
      padding:0px !important;
      font-size: 12px !important;
  }
  #leaveHistoryLeaveBalanceGridNew .e-grid .e-gridheader tr th:nth-child(2) .e-filtermenudiv::before{
    left:40% !important ;
  }
  @media only screen and (max-width: 1280px){
    #leaveHistoryLeaveBalanceGridNew .e-grid .e-filtermenudiv::before {
      position:absolute !important;
      top:14px !important ;
      left :42% !important ;
      padding:0px !important;
      font-size: 12px !important;
  }
  @media screen and (width: 1536px) and (height: 730px){
    #leaveHistoryLeaveBalanceGridNew .e-grid .e-filtermenudiv::before {
      position:absolute !important;
      top:14px !important ;
      left :38% !important ;
      padding:0px !important;
      font-size: 12px !important;
   }
  }
  #leaveHistoryLeaveBalanceGridNew .e-grid .e-gridheader tr th:nth-child(2) .e-filtermenudiv::before{
    right:10% !important ;
  }

  @media only screen and (max-width: 1280px){
      #leaveHistoryLeaveBalanceGridNew .e-grid .e-gridheader tr th:nth-child(2) .e-filtermenudiv::before{
    right:5% !important ;
  }
        #leaveHistoryLeaveBalanceGridNew .e-grid .e-gridheader tr th:nth-child(3) .e-filtermenudiv::before{
    right:5% !important ;
  }
`
const ownFilterIconCss = `
  #leaveHistoryLeaveBalanceGridNew .e-grid .e-filtermenudiv::before {
      position:absolute !important;
      top:12px !important ;
      left :38% !important ;
      padding:0px !important;
      font-size: 12px !important;
  }
  @media only screen and (max-width: 1280px){
    #leaveHistoryLeaveBalanceGridNew .e-grid .e-filtermenudiv::before {
      position:absolute !important;
      top:14px !important ;
      left :42% !important ;
      padding:0px !important;
      font-size: 12px !important;
  }
   #leaveHistoryLeaveBalanceGridNew .e-grid .e-gridheader tr th:last-child .e-filtermenudiv::before{
    right:20% !important ;
   }
  }
  #leaveHistoryLeaveBalanceGridNew .e-grid .e-gridheader tr th:nth-child(2) .e-filtermenudiv::before{
    left:70% !important ;
  }
  #leaveHistoryLeaveBalanceGridNew .e-grid .e-gridheader tr th:last-child .e-filtermenudiv::before{
    right:27% !important ;
  }
  @media only screen and (min-width: 1536px) and (min-height: 730px){
    #leaveHistoryLeaveBalanceGridNew .e-grid .e-filtermenudiv::before {
      position:absolute !important;
      top:14px !important ;
      left :38% !important ;
      padding:0px !important;
      font-size: 12px !important;
   }
    #leaveHistoryLeaveBalanceGridNew .e-grid .e-gridheader tr th:last-child .e-filtermenudiv::before{
      right:25% !important ;
    }
  }

`
