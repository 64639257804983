import React, { useEffect, useState } from 'react';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
export const LeaveBalanceCard = ({ leave }) => {

  const [cardWidth, setCardWidth] = useState('240px');

  const cardCss = `
  .progress-bar-continer {
    margin-top: 10px;
    margin-bottom: 5px;
    display: flex;
  }
  .progress-bar {
    position: relative;
    border-radius: 2px;
    width: 100%;
    height: 12px;
    background-color: #F2F2F2;
  }
  .progress-bar-left{
    height: 100%;
    border-radius: 2px;
  }
  .used-leaves{
    font-size: 14px;
    font-weight: 600;
    padding-right: 5px;
    font-family:'Circular TT Book'
  }
  .leave-name{
    font-size: 16px;
    font-weight: 600;
    font-family:'Circular TT Book'
  }
  .leave-status{
    font-size: 12px;
    font-weight: 400;
    color: #939399;
    font-family:'Circular TT Book'
  }
  .available-leave{
    font-size: 12px;
    font-weight: 500;
    color: #22AE8A;
    font-family:'Circular TT Book'
  }
  .available-leave-container{
    display: flex;
    justify-content: space-between;
    align-items: center; 
  }
  `
  const getCardWidth = (innerWidth) => {
    if ((innerWidth < 1400 && innerWidth > 1310)) {
      setCardWidth('190px')
    }
    if ((innerWidth < 1310 && innerWidth > 1100)) {
      setCardWidth('230px')
    }
    if ((window.innerHeight === 695 && innerWidth === 1309)) {
      setCardWidth('210px')
    }
    if ((innerWidth < 1100 && innerWidth > 1000) || innerWidth === 1242) {
      setCardWidth('195px')
    }
  };

  useEffect(() => {
    getCardWidth(window.innerWidth)
  }, [window.innerWidth])

  const getLeaveName = (leave, item) => { // Assuming `item` is passed as an argument
   
    if (leave.length > 20) {
        return (
            <TooltipComponent closeOn="Click" position="BottomCenter" isSticky={false} content={leave} cssClass='customtooltip-past'>
                <div className="leave-name">{leave.substring(0, 18)}...</div>
            </TooltipComponent>
        );
    } else {
        return leave;
    }
}

  return (
    <>
      <style>{cardCss}</style>
      <div className="leave-balance-card-details" style={{ width: cardWidth }}>
        <div className='leave-name'>{getLeaveName(leave.leaveName)}</div>
        <div>
          <span className='used-leaves'>{leave.openingBalance}</span>
          <span className='leave-status'>Eligible</span>
        </div>
        <div className='progress-bar-continer'>
          <div className='progress-bar'>
            <div className='progress-bar-left' style={{ backgroundColor: leave.color, width: `${leave.availableBalance > 0 ? (leave.availableBalance / leave.openingBalance) * 100 : 0}%` }}></div>
          </div>
        </div>
        <div className='available-leave-container'>
          <div>
            <span className='used-leaves' >{leave.availableBalance}</span>
            <span className='leave-status'>Available</span>
          </div>
          <div>
            <span className='used-leaves'>{leave.consumed}</span>
            <span className='leave-status'>Utilized</span>
          </div>
        </div>
      </div>
    </>
  )
};