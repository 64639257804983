/**
 * Author By :- Prashant Waphare
 * Date : 27-05-2020
 * Description :- This file use for Separation laylout
 */

import * as React from 'react';
import { MainClass } from '../../../mainclass';
import { connect } from 'react-redux';
import {
    RESIGNATIONMANAGERAPPROVAL, RESIGNATION, HRAPPROVAL, ABSCONDING,
    TERMINATION,
    ABSCONDINGMANAGER,
    ABSCONDINGHR,
    TERMINATIONHR, EXITCLEARANCEGRID, RESIGNATIONPNLAPPROVAL, EXIT_CLEARANCE_FUNCTION,
    EXIT_CLEARANCE_MANAGER
} from "../../../redux/constant/meetingcategoryconstant";

import ResignationManagerApproval from '../../containers/tmlc/separation/resignationmanagerapproval/resignationmanagerapproval';
import ResignationHrApproval from '../../containers/tmlc/separation/resignationhrapproval/resignationhrapproval';
import Resignation from './../../containers/tmlc/separation/resignation/resignation';
import Absconding from '../../containers/tmlc/separation/absconding/absconding';
import AbscondingManager from '../../containers/tmlc/separation/absconding/abscondingmanager';
import AbscondingHr from '../../containers/tmlc/separation/absconding/abscondinghr';
import Termination from '../../containers/tmlc/separation/termination/termination';
import FunctionalClearance from '../../containers/tmlc/separation/exitclearance/functionalclearance';
import TerminationHr from '../../containers/tmlc/separation/termination/terminationhr';
import ExitClearanceGrid from '../../containers/tmlc/separation/exitclearance/exitclearancegrid';
import { addUpdateSeparationDetails } from '../../../redux/actions/entryexit/separationaction';
import queryString from "query-string";
import { dencrypt } from '../../other/commonfunction'
class SeparationLayout extends MainClass {
    constructor(props) {
        super(props)
        this.state = {
            isLoaded: false,
            moduleId: 0
        }
    }
    componentDidMount() {
        try {
            this.setModuleId()
            this.setState({ isLoaded: true })
        } catch (error) {
        }
    }

    setModuleId() {
        try {
            // Set App Id
            const { moduleId } = this.state
            const params = queryString.parse(this.props.location.search);
            if (typeof params.id !== 'undefined' && params.id !== null) {
                if (moduleId !== dencrypt(params.id))
                    this.setState({ moduleId: dencrypt(params.id) })
            }
        } catch (error) {

        }
    }

    componentDidUpdate(newProps) {
        try {
            this.setModuleId()
        } catch (error) {
        }
    }

    render() {

        const { categoryName } = this.props
        const { moduleId } = this.state
        let activeModule = (moduleId === 0 ? categoryName : moduleId).toString()

        return (
            <div style={{ height: '100%', width: '100%' }}>
                {
                    (activeModule === RESIGNATIONMANAGERAPPROVAL || activeModule === RESIGNATIONPNLAPPROVAL) && <ResignationManagerApproval categoryName={activeModule} />
                }
                {
                    activeModule === RESIGNATION && <Resignation categoryName={activeModule} />
                }
                {
                    activeModule === HRAPPROVAL && <ResignationHrApproval categoryName={activeModule} />
                }
                {
                    activeModule === ABSCONDING && <Absconding categoryName={activeModule} />
                }
                {
                    activeModule === ABSCONDINGMANAGER && <AbscondingManager categoryName={activeModule} />
                }
                {
                    activeModule === ABSCONDINGHR && <AbscondingHr categoryName={activeModule} />
                }
                {
                    activeModule === TERMINATION && <Termination categoryName={activeModule} />
                }
                {
                    activeModule === EXIT_CLEARANCE_FUNCTION && <FunctionalClearance categoryName={activeModule} />
                }
                {
                    activeModule === EXIT_CLEARANCE_MANAGER && <FunctionalClearance categoryName={activeModule} />
                }
                {
                    activeModule === TERMINATIONHR && <TerminationHr categoryName={activeModule} />
                }
                {
                    activeModule === EXITCLEARANCEGRID && <ExitClearanceGrid categoryName={activeModule} />
                }
            </div>
        );
    }

}

const mapStateToProps = ({ meetingcategory, separationreducer }) => {
    const { categoryName } = meetingcategory;
    const { reloadStatus } = separationreducer;
    return { categoryName, reloadStatus }
};
export default connect(mapStateToProps, { addUpdateSeparationDetails })(SeparationLayout);

