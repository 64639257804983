
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { DropDownButtonComponent } from '@syncfusion/ej2-react-splitbuttons';
import LnAService from '../../../../services/lna/lnaservice';
import ErrorTabMessage from '../../../errormessage/errormessage';
import ProgressBar from '../../../progressbar/progressbar';

import {
    GridComponent,
    ColumnsDirective,
    ColumnDirective
} from "@syncfusion/ej2-react-grids";
import {
    getGridColumn,
    ADMIN_MODULE_ACTION, REGULARIZATION_STATUS_DAYBREAK, REGULARIZATION_STATUS_DATE, REGULARIZATION_STATUS_STATUS
} from '../../../../app/other/gridcolumns'
import { AllFullDateFormat } from "../../../../components/separation/allcommoncomponents";

const isSixteenEighty = window.innerHeight < 932;
const isSixteenHundred = window.innerHeight < 782;
const isFourteenHundred = window.innerHeight < 782 && window.innerWidth < 1441;
const isOneTwentyFiveScale = window.innerHeight < 747;
const isThirteenHundred = window.innerHeight < 658;
const isOneTwentyFiveInSixteenHundred = window.innerHeight < 603;

const isOneNineEightZeroBookmarkOn = window.innerHeight < 930 && window.innerWidth < 1921;
const SAMPLECSS = `
.e-grid-min-height .e-gridheader .e-headercell .e-headercelldiv {
  height: auto;
  margin-left: 0px !important;
}
.e-grid .e-rowcell{
    padding:0px !important;
}
@media screen and (max-width: 1366px) and (max-height: 768px),
screen and (max-width: 1440px) and (max-height: 900px) {
    .leave-application-body .e-grid .e-gridcontent tr.e-row .e-rowcell {
        font-size: 0.7rem !important;
      }
      .e-time-icon{
        font-size:0.84rem !important;
        margin-left:10px !important;
      }
}
// th {
//   top: 0;
//   position: sticky;
// }
// .statusData{
//   // height:290px !important;
//   height:334px !important;
//   overflow-y:auto;
// }
// .e-dropdown-popup ul {
//   padding:0px !important
// }
`
const getDateData = (prop) => {
    const { appliedFor } = prop;
    return (
        <div style={{ display: "flex", justifyContent: "center" }}>
            <AllFullDateFormat date={appliedFor} />
        </div>
    );
};

/**
 * @author Chandrashekhar Bhagat
 * @argument prop
 * @description displaying status column with background color and font color."
 */
const getStatus = (prop) => {
    const { regularizationStatus } = prop;
    return (
        <div style={{ display: "flex", justifyContent: "center" }}>
            {regularizationStatus}
        </div>
    );
};

const getDaybreakData = (prop) => {
    const { regularizationDayBreak } = prop;
    return (
        <div style={{ display: "flex", justifyContent: "center" }}>
            {regularizationDayBreak}
        </div>
    );
}

export const RegularizationStatus = (props) => {
    const [regulizationGridData, setRegulizationGridData] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [allHeight, setAllHeight] = React.useState({
        containerHeight: null
    });
    useEffect(() => {
        window.addEventListener("resize", updateDimensions()); // NOSONAR
    }, []);

    const updateDimensions = () => {
        const containerHeight = window.innerHeight - 303
        setAllHeight(prevState => ({
            ...prevState,
            containerHeight: containerHeight
        }))
    }

    useEffect(() => {
        setIsLoading(true)
        getRegularizationData();
    }, [])

    useEffect(() => {
        if (props.leaveRegularizationFlag === 'updated') {
            setIsLoading(true)
            getRegularizationData();
        }
    }, [props.leaveRegularizationFlag === 'updated'])

    const getRegularizationData = async () => {
        const regularizationData = await LnAService.getRegularizationData(props.authUser, "GET", 0);
        setIsLoading(false)
        setRegulizationGridData(regularizationData.Result)
    }

    const actionTemplate = (data) => {
        let dropDownSource = []
        if (data.regularizationStatus === "Pending") {
            dropDownSource = [
                {
                    text: 'View', iconCss: 'e-icons e-page-one'
                },
                {
                    text: 'Edit',
                    iconCss: 'e-icons e-edit'
                },
                {
                    text: 'Cancel',
                    iconCss: 'e-icons e-cancel-2'
                }
            ]
        } else if (data.regularizationStatus === "Rejected") {
            dropDownSource = [
                {
                    text: 'View',
                    iconCss: 'e-icons e-page-one'
                },
                {
                    text: 'Edit',
                    iconCss: 'e-icons e-edit'
                }
            ]
        } else {
            dropDownSource = [
                {
                    text: 'View',
                    iconCss: 'e-icons e-page-one'
                },

            ]
        }
        const onSelect = (args) => {
            if (args.item.text === 'Edit') {
                props.getChildData(data)
            }
            if (args.item.text === 'View') {
                props.showViewMode(true, data, 'view')
            }
            if (args.item.text === 'Cancel') {
                props.showViewMode(true, data, 'Cancel')
            }
        }
        return (
            <div className="col-12">
                <div className="row1" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <DropDownButtonComponent items={dropDownSource} select={onSelect} iconCss='e-icons e-more-vertical-2' cssClass='e-caret-hide e-custom e-vertical' iconPosition='Top'></DropDownButtonComponent>
                </div>

            </div>
        );
    };


    return (
        <div id='swipeGridId' >
            <style>{SAMPLECSS}</style>

            {(!isLoading && regulizationGridData.length > 0) &&
                < GridComponent
                    // id={'GridSwipe'}
                    dataSource={regulizationGridData}
                    rowHeight={40}
                    height={allHeight.containerHeight}
                    gridLines='Both'
                    width={"100%"}
                >
                    <ColumnsDirective>
                        {
                            getGridColumn('regularizationStatus').map(item => {
                                if (item.colName === ADMIN_MODULE_ACTION) {
                                    return (
                                        <ColumnDirective
                                            key={item.field}
                                            template={actionTemplate}
                                            filter={false}
                                            textAlign={`${item.align} !important`}
                                            field={item.field}
                                            headerText={item.colName}
                                            width={item.width}
                                        />
                                    )
                                }
                                else if (item.colName === REGULARIZATION_STATUS_DAYBREAK) {
                                    return (
                                        <ColumnDirective
                                            key={item.field}
                                            template={getDaybreakData}
                                            filter={false}
                                            textAlign={`${item.align} !important`}
                                            field={item.field}
                                            headerText={item.colName}
                                            width={item.width}  
                                        />
                                    )
                                }
                                else if (item.colName === REGULARIZATION_STATUS_DATE) {
                                    return (
                                        <ColumnDirective
                                            key={item.field}
                                            template={getDateData}
                                            filter={false}
                                            textAlign={`${item.align} !important`}
                                            field={item.field}
                                            headerText={item.colName}
                                            width={item.width}
                                        />
                                    ) 
                                }
                                else if (item.colName === REGULARIZATION_STATUS_STATUS) {
                                    return (
                                        <ColumnDirective
                                            key={item.field}
                                            template={getStatus}
                                            filter={false}
                                            textAlign={`${item.align} !important`}
                                            field={item.field}
                                            headerText={item.colName}
                                            width={item.width}
                                        />
                                    )
                                }
                                else {
                                    return (
                                        <ColumnDirective
                                            field={item.field}
                                            width={item.width}
                                            headerText={item.colName}
                                            textAlign={item.align}
                                        />
                                    )
                                }
                            })
                        }

                    </ColumnsDirective>
                </GridComponent>
            }
            {isLoading && (
                <div
                    style={{
                        marginTop: "20%",
                        marginLeft: "49%",
                    }}
                >
                    <ProgressBar />
                </div>)}
            {(regulizationGridData.length === 0 && !isLoading) && (
                <ErrorTabMessage message="No Data Available" />
            )}

        </div >
    )
}


const mapStateToProps = ({ auth, lnareducer }) => {
    const { authUser } = auth
    const { leaveRegularizationFlag } = lnareducer
    return {
        authUser, leaveRegularizationFlag
    }
};

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(RegularizationStatus)