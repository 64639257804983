/**
 * @author: Vivek Khodade.
 * @description This use for okr rating action.
 * Created: 10-10-2021
 */
import {
  ADD_UPDATE_OKR_RATING_REDUX, ADD_EDIT_SELF_SUP_FINAL_RATING, ADD_EDIT_ACTUAL_RATING,
  ADD_EDIT_INTERIM_REVIEW_FEEDBACK, SUBMIT_OKR_RATING, OKR_SET_MEETING,OKR_ACCEPTED
} from '../../constant/okrratingconstant/okrratingconstant';
export const addUpdateOkrRatingRedux = (data) => {
  return {
    type: ADD_UPDATE_OKR_RATING_REDUX,
    payload: data,
  };
};
export const addEditSelfSupTPRatingRedux = (data) => {
  return {
    type: ADD_EDIT_SELF_SUP_FINAL_RATING,
    payload: data,
  };
};
export const addEditActualRating = (data) => {
  return {
    type: ADD_EDIT_ACTUAL_RATING,
    payload: data,
  };
};

export const addEditInterimReviewFeedback = (data) => {
  return {
    type: ADD_EDIT_INTERIM_REVIEW_FEEDBACK,
    payload: data,
  };
};

export const submitOKRRating = (data) => {
  return {
    type: SUBMIT_OKR_RATING,
    payload: data,
  };
};


export const setOKRMeeting = (data) => {
  return {
    type: OKR_SET_MEETING,
    payload: data,
  };
};

export const okrAccepted = (data) => {
  return {
    type: OKR_ACCEPTED,
    payload: data,
  };
};