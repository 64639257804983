import React, { Component } from 'react';
import { connect } from 'react-redux';
import OKRDashboardContainer from '../../containers/okr/dashboard/okrdashboardcontainer';
import ComplienceReportContainer from '../../containers/okr/dashboard/compliencereportcontainer';
import { DASHBOARD, COMPLIANCE_REPORT } from '../../../redux/constant/meetingcategoryconstant';
import queryString from "query-string";
import { dencrypt } from '../../other/commonfunction'
import './default.css';

class OKRDashboardLayout extends Component {
  constructor(props) {
    super(props)

    this.state = {
      moduleId: 0
    };
  };

  componentDidMount() {
    this.setModuleId();
  }

  setModuleId() {
    try {
      // Set App Id
      const { moduleId } = this.state
      const params = queryString.parse(this.props.location.search);
      if (typeof params.id !== 'undefined' && params.id !== null) {
        if (moduleId !== dencrypt(params.id))
          this.setState({ moduleId: dencrypt(params.id).toString() })
      }
    } catch (error) {

    }
  }

  componentDidUpdate(newProps) {
    try {
      this.setModuleId()
    } catch (error) {

    }
  }
  render() {
    const {moduleId}=this.state;
    return (
      <>
        {moduleId === DASHBOARD && <OKRDashboardContainer />}
        {/* {moduleId === COMPLIANCE_REPORT && <ComplienceReportContainer />} */}
        {moduleId === COMPLIANCE_REPORT && <ComplienceReportContainer />}
      </>
    )
  }
}

const mapStateToProps = ({ meetingcategory, router }) => {
  const { categoryName } = meetingcategory;
  const { location } = router;
  return {
    categoryName, location
  }
};

export default connect(mapStateToProps, {})(OKRDashboardLayout);
