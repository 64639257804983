import React, { Component } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faPlus, faEye, faArrowAltUp, faArrowAltDown } from "@fortawesome/pro-light-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { OKR_PRODUCTIVE_ALLOCATION} from '../../redux/constant/meetingcategoryconstant'
library.add(faPencilAlt, faPlus, faEye, faArrowAltUp, faArrowAltDown);

export default class ReviewObjectivKR extends Component {
    opneKrDetailsPopup = (data) => {
        this.props.opneKrDetailsPopup(data)
    }
    openActualAchivementPopup = (data, categoryType, actionType) => {
        this.props.openActualAchivementPopup(data, categoryType, actionType)
    }
    openFinalRatingPopup = (data) => {
        this.props.openFinalRatingPopup(data)
    }
    openPastRatingPopup = (data) => {
        this.props.openPastRatingPopup(data)
    }

    openKrRatingView = (data, type, rating) => {
        if (rating > 0)
            this.props.viewKrReviewPopup(data, type)
    }

    getCurrencyUnit = (Id) => {
        const { currencyInfo } = this.props
        if (Id !== null) {
            let data = currencyInfo.filter((t) => t.Id === Id);
            if (typeof data !== 'undefined' && data.length > 0)
                return data[0].var_optional1;
        }
        else {
            return ""
        }
    }

    getBenchMarkDataSort(pFromTo, pSortBy) {
        //NOSONAR
        // if (pFromTo === "From") { 
        //     if (pSortBy == "1")
        //         return <><FontAwesomeIcon icon={faArrowAltDown} style={{ color: "#F94F5E" }} /> &nbsp;</>
        //     else
        //         return <><FontAwesomeIcon icon={faArrowAltUp} style={{ color: "#F94F5E" }} />&nbsp;</>
        // } else {
        //     if (pSortBy === "1")
        //         return <><FontAwesomeIcon icon={faArrowAltUp} style={{ color: "#F94F5E" }} />&nbsp;</>
        //     else
        //         return <><FontAwesomeIcon icon={faArrowAltDown} style={{ color: "#F94F5E" }} />&nbsp;</>
        // }
        return <></>
    }

    getBenchMarkData = (item, ptype) => {//NOSONAR
        switch (item.achievementType) {
            case "1": // date
            case "4": // Numbers
                if (ptype === "From") {
                    return <>{this.getBenchMarkDataSort(ptype, item.sortBy)}{item.krBenchmarkFrom}</>
                } else {
                    return <>{this.getBenchMarkDataSort(ptype, item.sortBy)}{item.krBenchmarkTo}</>
                }
            case "2": // %
                if (ptype === "From") {
                    return <>{this.getBenchMarkDataSort(ptype, item.sortBy)}{item.krBenchmarkFrom + (item.krBenchmarkFrom.includes("=") ? "" : "%")}</>
                } else {
                    return <>{this.getBenchMarkDataSort(ptype, item.sortBy)}{item.krBenchmarkTo + (item.krBenchmarkTo.includes("=") ? "" : "%")}</>
                }
            case "3": {
                if (ptype === "From") {
                    return <>{this.getBenchMarkDataSort(ptype, item.sortBy)}{item.krBenchmarkFrom.includes("=") ? item.krBenchmarkFrom : this.getCurrencyUnit(item.currencyId) + " " + new Intl.NumberFormat('en-IN').format(item.krBenchmarkFrom)}</>
                } else {
                    return <>{this.getBenchMarkDataSort(ptype, item.sortBy)}{item.krBenchmarkTo.includes("=") ? item.krBenchmarkTo : this.getCurrencyUnit(item.currencyId) + " " + new Intl.NumberFormat('en-IN').format(item.krBenchmarkTo)}</>
                }
            }// currency
            default:
        }
    }

    setActualAchievemnt = (item, actualAchievement) => {
        switch (item.achievementType) {
            case "1": // date
            case "4": // Numbers
                return <>{actualAchievement}</>
            case "2": // %
                return <>{actualAchievement + "%"} </>
            case "3": {
                return <>{this.getCurrencyUnit(item.currencyId) + " " + new Intl.NumberFormat('en-IN').format(actualAchievement)}</>
            }// currency
            default:
        }
    }
    setFinalRating = (item) => {
        if (item.finalRecommendationAccess === 'Y') {
            if (item.krFinalRating.Type === 'Add') {

                if (item.krPrimaryActualRating.Rating === item.krSecondaryActualRating.Rating && item.krSecondaryActualRating.Rating !== "") {
                    return <span>
                        <span><span className='rating-grid-text'>{item.krSecondaryActualRating.Rating}</span></span>
                        &nbsp;&nbsp;
                        <span title="Edit final rating" className='ratingIconColor' onClick={() => this.openFinalRatingPopup(item)}><FontAwesomeIcon icon={faPencilAlt} style={{ color: "#F94F5E" }} /></span>
                    </span>
                } else {
                    return (<span  title="Add final rating" className='ratingIconColor' onClick={() => this.openFinalRatingPopup(item)}><b><FontAwesomeIcon icon={faPlus} style={{ color: "#F94F5E" }} /></b></span>)
                }



            } else if (item.krFinalRating.Type === 'Edit') {
                return <span>
                    <span><span className='rating-grid-text'>{item.krFinalRating.Rating}</span></span>
                    &nbsp;&nbsp;
                    <span title="Edit final rating" className='ratingIconColor' onClick={() => this.openFinalRatingPopup(item)}><FontAwesomeIcon icon={faPencilAlt} style={{ color: "#F94F5E" }} /></span>
                </span>
            } else {
                return (<div className='rating-okr-onhover-text rating-grid-text'>
                    <span title="View final rating" className='rating-grid-text rating-okr-onhover-text' onClick={() => this.props.viewKrReviewPopup(item, 'Final')}>{item.krFinalRating.Rating}</span>
                </div>)

            }
        } else {
            return <>{""}</>
        }
    }

    setSelfSupSync = (item) => {
        return (
            <div className='review-text-yes-no' title="Self & Supervisor Rating Sync"><span className={item.krRating.IsPass === 'Y' ? 'past-review-yes' : 'past-review-no'}>{item.krRating.IsPass}</span></div>
        )
    }
    oldActualAchievementHeader = (achievementTypeHeader) => {
        return (
            <th style={{ backgroundColor: "#0CB96312" }} scope="col" colSpan="3"><div className='okr-review-objective-grid-recommendation-text'>{achievementTypeHeader}</div></th>
        )
    }
    oldActualAchievementSubHeader = () => {
        return (
            <>
                <th className='okr-rating-objective-grid-kr-header-text' scope="col" style={{ textAlign: "center", backgroundColor: "#0CB96312" }}>Actual <br /> Achievement</th>
                <th className='okr-rating-objective-grid-kr-header-text' scope="col" style={{ textAlign: "center", backgroundColor: "#0CB96312" }}>&nbsp;Key Result&nbsp;<br />Achieved</th>
                <th className='okr-rating-objective-grid-kr-header-text' scope="col" style={{ textAlign: "center", backgroundColor: "#0CB96312" }}>&nbsp;&nbsp;System&nbsp;&nbsp; <br /> &nbsp;&nbsp;Rating&nbsp;&nbsp;</th>
            </>
        )
    }
    oldAchivementRows = (item) => {
        return (
            <>
                <td className="actual-achievement-column-width-ind"> <div
                    onClick={() => this.openActualAchivementPopup(item)}
                    className='rating-okr-onhover-text rating-grid-text word-break'>{this.setActualAchievemnt(item)}</div></td>
                <td> <span className='rating-grid-text'> {item.krAchieved} </span></td>
                <td><div className='rating-okr-onhover-text rating-grid-text' onClick={() => this.openActualAchivementPopup(item)}>{item.krSystemRating}</div></td>
            </>
        )
    }
    newAchievementHeader = () => {
        return (
            <th style={{ backgroundColor: "#0CB96312" }} scope="col" colSpan="2"><div className='okr-review-objective-grid-recommendation-text'>Actual Achievement Input</div></th>
        )
    }
    newAchievementSubHeader = () => {
        return (
            <>
                <th className='okr-rating-objective-grid-kr-header-text' scope="col" style={{ textAlign: "center", backgroundColor: "#0CB96312" }}>Self</th>
                <th className='okr-rating-objective-grid-kr-header-text' scope="col" style={{ textAlign: "center", backgroundColor: "#0CB96312" }}>Supervisor</th>
            </>
        )
    }
    newAchivementRows = (item) => {
        const { ratingFlow } = this.props.objectiveData
        return (
            <>
                <td className="actual-achievement-column-width-ind">
                    <div style={{width:120}} className='rating-okr-onhover-text rating-grid-text word-break' title={'View Self - Actual Achievement Input & Remark'} onClick={() => this.openActualAchivementPopup(item, "Self", "View")} >
                        {this.setActualAchievemnt(item, (ratingFlow === 'self-sup' ? item.krPrimaryActualRating.Achievement : item.krSecondaryActualRating.Achievement))}
                    </div>
                </td>
                <td>
                    <div style={{width:120}} className='rating-okr-onhover-text rating-grid-text'  title={"View Supervisor - Actual Achievement Input & Remark"} onClick={() => this.openActualAchivementPopup(item, "Supervisor", "View")}>
                        {this.setActualAchievemnt(item, (ratingFlow !== 'self-sup' ? item.krPrimaryActualRating.Achievement : item.krSecondaryActualRating.Achievement))}
                    </div>
                </td>
            </>
        )
    }
    showRating = (item, categoryType, rating) => {
        return (
            <div onClick={() => this.openActualAchivementPopup(item, categoryType, "View")}>
                <>
                    <span className='rating-okr-onhover-text rating-grid-text '> &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;{rating} </span>
                    &nbsp;&nbsp;  &nbsp;&nbsp; &nbsp;&nbsp;
                    {/* <span className='review-text-yes-no' style={{ color: "#B7B7BB" }}> | </span> &nbsp;&nbsp;
                        <span className='review-text-yes-no' style={{ color: item.krSelfRecommendationRating.IsPass === "Y" ? "#18BC00" : "#B7B7BB" }} >{item.krSelfRecommendationRating.IsPass}</span> */}
                </>
            </div>
        )
    }

    render() {//NOSONAR
        const { objectiveName, okrVariableImpact, krData, frequencyNm, userObjectiveId, totalInterimFeedback } = this.props.objectiveData;
        const { VariableLinked, categoryName } = this.props
        return (
            <div style={{  margin: categoryName===OKR_PRODUCTIVE_ALLOCATION ? '15px 0px 15px 0px': 15 }}>
                <table className="table table-hover table-bordered" style={{ maxHeight: "200px" }}>
                    <thead>
                        <tr>
                            <th className='review-objective-width' style={{ backgroundColor: "#fff" }} scope="col" colSpan="1"><div className="okr-rating-objective-grid-text">{ categoryName===OKR_PRODUCTIVE_ALLOCATION ? "":objectiveName}</div></th>
                            <th className="objective-benchmark-width" style={{ backgroundColor: "#005DFF12" }} scope="col" colSpan={VariableLinked === "No" ? 2 : 1}>
                                <div className='okr-review-objective-grid-recommendation-text'>
                                    {VariableLinked === "Yes" && categoryName!==OKR_PRODUCTIVE_ALLOCATION &&
                                        <div className='okr-rating-objective-grid-recommendation-text'>Variable <br />Impact {okrVariableImpact === 0 ? " - No" : ` - ${okrVariableImpact}%`}</div>
                                    }
                                    {
                                        (VariableLinked === "No" || categoryName===OKR_PRODUCTIVE_ALLOCATION) &&
                                        <div className='okr-rating-objective-grid-recommendation-text'>{categoryName===OKR_PRODUCTIVE_ALLOCATION ? "" : frequencyNm}</div>
                                    }
                                </div>
                            </th>
                            {VariableLinked === "Yes" &&
                                <th style={{ backgroundColor: "#005DFF12" }} scope="col" ><div className='okr-rating-objective-grid-recommendation-text'>{frequencyNm}<br />Review</div></th>
                            }
                            {this.newAchievementHeader()}
                            <th style={{ backgroundColor: "#4F9EF914" }} scope="col" colSpan="3"><div className='okr-review-objective-grid-recommendation-text'>{"Rating"}</div></th>
                            <th style={{ backgroundColor: "#BBF94F12" }} scope="col" colSpan="2"><div className='okr-review-objective-grid-recommendation-text final-ratng-width'>Final Rating</div></th>
                            <th style={{ backgroundColor: "#F94F5E0D" }} scope="col" colSpan="3"><div className='okr-rating-objective-grid-recommendation-text'>Interim Feedback
                                <span style={{ cursor: 'pointer' }} className="rating-okr-onhover-text" onClick={() => this.props.openKrIntrimFeedbackPopup(krData, userObjectiveId, objectiveName)} > ({totalInterimFeedback})</span></div>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th className='okr-rating-objective-grid-kr-header-text' scope="col" style={{ textAlign: "left", padding: "00px 0px 0px 10px", backgroundColor: '#F4F4F7' }}> Key Results</th>
                            <th className='okr-rating-objective-grid-kr-header-text benckmark-column-width rating-objective-frequency-width' scope="col" colSpan="2" style={{ textAlign: "center", backgroundColor: '#F4F4F7' }}>Benchmark Range</th>
                            {this.newAchievementSubHeader()}
                            <th className='okr-rating-objective-grid-kr-header-text' scope="col" style={{ textAlign: "center", backgroundColor: "#4F9EF914" }}>Self</th>
                            <th className='okr-rating-objective-grid-kr-header-text' scope="col" style={{ textAlign: "center", backgroundColor: "#4F9EF914" }}>Supervisor</th>
                            <th className='okr-rating-objective-grid-kr-header-text' scope="col" style={{ textAlign: "center", backgroundColor: "#4F9EF914" }} title='Past Rating History'>History</th>
                            <th className='okr-rating-objective-grid-kr-header-text' scope="col" style={{ textAlign: "center", backgroundColor: "#BBF94F12" }} title="Self & Supervisor Rating Sync">Sync</th>
                            <th className='okr-rating-objective-grid-kr-header-text' scope="col" style={{ textAlign: "center", backgroundColor: "#BBF94F12" }}>Rating</th>
                            <th className='okr-rating-objective-grid-kr-header-text' scope="col" style={{ textAlign: "center", backgroundColor: "#F94F5E0D" }}>Self</th>
                            <th className='okr-rating-objective-grid-kr-header-text' scope="col" style={{ textAlign: "center", backgroundColor: "#F94F5E0D" }}>Supervisor</th>
                            <th className='okr-rating-objective-grid-kr-header-text' scope="col" style={{ textAlign: "center", backgroundColor: "#F94F5E0D", cursor: 'pointer' }} title='Third Party'>TP</th>
                        </tr>
                        {
                            krData.length > 0 &&
                            krData.map((item) => (
                                <tr style={{ textAlign: "center" }} key={`reviewKR_${item.ratingId}`}>
                                    <td style={{ textAlign: "left", paddingLeft: 8 }}> <div onClick={() => this.opneKrDetailsPopup(item)} title="View key results details" className=" rating-grid-text rating-okr-onhover-text review-key-reasult-width">{item.krName}</div></td>
                                    <td className="benckmark-column-width-ind"> <span className='rating-grid-text'>{this.getBenchMarkData(item, "From")}</span></td>
                                    <td className="benckmark-column-width-ind"><span className='rating-grid-text'> {this.getBenchMarkData(item, "To")} </span></td>
                                    {this.newAchivementRows(item)}
                                    <td>
                                        {

                                            <div onClick={() => this.openActualAchivementPopup(item, "Self", "View")} >
                                                <>
                                                    <span className='rating-okr-onhover-text rating-grid-text '  title={"View Self - Rating Input & Remark"}> {item.krRating.selfRating} </span>
                                                    
                                                    {/* <span className='review-text-yes-no' style={{ color: "#B7B7BB" }}> | </span> &nbsp;&nbsp;
                                                        <span className='review-text-yes-no' style={{ color: item.krSelfRecommendationRating.IsPass === "Y" ? "#18BC00" : "#B7B7BB" }} >{item.krSelfRecommendationRating.IsPass}</span> */}
                                                </>
                                            </div>

                                        }
                                    </td>
                                    <td>
                                        {
                                            <div onClick={() => this.openActualAchivementPopup(item, "Supervisor", "View")}>
                                                <>
                                                    <span className='rating-okr-onhover-text rating-grid-text ' title={"View Supervisor - Rating & Remark"}> {item.krRating.supervisorRating} </span>
                                                    
                                                    {/* <span className='review-text-yes-no' style={{ color: "#B7B7BB" }}> | </span> &nbsp;&nbsp;
                                                        <span className='review-text-yes-no' style={{ color: item.krSelfRecommendationRating.IsPass === "Y" ? "#18BC00" : "#B7B7BB" }} >{item.krSelfRecommendationRating.IsPass}</span> */}
                                                </>
                                            </div>
                                        }
                                    </td>
                                    <td>
                                        {item.pastRating === 'Y' && <div className='review-text-yes-no rating-okr-onhover-text' title='Past Rating History' onClick={() => this.openPastRatingPopup(item)}>&nbsp;&nbsp; <FontAwesomeIcon icon={faEye} style={{ color: "#F94F5E" }} />&nbsp;&nbsp; </div>
                                        }
                                        {item.pastRating === 'N' && <span>-</span>}
                                    </td>
                                    <td>
                                        {
                                            this.setSelfSupSync(item)
                                        }
                                    </td>
                                    <td>
                                        <div>
                                            {
                                                this.setFinalRating(item)
                                            }
                                        </div>
                                    </td>
                                    <td>
                                        <div >
                                            {
                                                item.interimSelf === 0 ? <span>-</span> :
                                                    <>
                                                        {
                                                            <span  onClick={() => this.props.openViewIntrimFeedbackPopup(item, userObjectiveId, 'self', null)} >
                                                                <span title="View self interim feedback" className='rating-grid-text rating-okr-onhover-text'>{item.interimSelf}</span>
                                                            </span>
                                                        }
                                                    </>
                                            }
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            {
                                                item.interimSupervisor === 0

                                                    ?
                                                    <span>
                                                        -
                                                    </span>
                                                    :
                                                    <>
                                                        {
                                                            <span onClick={() => this.props.openViewIntrimFeedbackPopup(item, userObjectiveId, 'sup', null)}>
                                                                <span title="View supervisor interim feedback" className='rating-grid-text rating-okr-onhover-text'>{item.interimSupervisor}</span>
                                                            </span>
                                                        }
                                                    </>
                                            }
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            {
                                                item.interimThirdParty === 0
                                                    ?
                                                    <span>
                                                        -
                                                    </span>
                                                    :
                                                    <>
                                                        {
                                                            <span onClick={() => this.props.openViewIntrimFeedbackPopup(item, userObjectiveId, 'tp', null)}>
                                                                <span title="View third party interim feedback" className='rating-grid-text rating-okr-onhover-text'>{item.interimThirdParty}</span >
                                                            </span>
                                                        }
                                                    </>
                                            }
                                        </div>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            </div>
        )
    }
}
