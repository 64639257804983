/**
 * Author: Md Jahir Uddin
 * Date: 03-03-2023
 * Description: Container for tmlc to include master and layers into admin module
 * @param {{masterId: number, categoryName: string}} props 
 */

import React, { Component } from 'react'
import { connect } from 'react-redux';
import { setMeetingMasterUpdate } from '../../../redux/actions/meetingmasteraction';
import { LAYER_SUB_LAYER_MAPPING, ORG_CHART_MASTER, EMAIL_MAPPING_MASTER } from '../../../redux/constant/meetingcategoryconstant';
import Orgchartmapping from '../admin/orgchartmapping';
import OrgChartMaster from '../admin/orgchartmaster';
import EmailMappingAdminContainer from './emailmapping/emailmappingcontainer';

class SetupContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      height: (window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight) - 145,
    }
  }

  componentDidMount() {
    this.setState({
      height: (window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight) - 145
    })
  }

  renderSwitch = () => {
    const { categoryName, masterId } = this.props
    let activeMasterId = (masterId == 0 ? categoryName : masterId).toString();
    switch (activeMasterId) {
      case ORG_CHART_MASTER:
        return <OrgChartMaster />;
      case LAYER_SUB_LAYER_MAPPING:
        return <Orgchartmapping />
      case EMAIL_MAPPING_MASTER:
        return <EmailMappingAdminContainer masterId={masterId} />
    }
  }

  render() {
    return (
      <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 p-md" style={{ backgroundColor: "#F2F2F2" }}>
        {this.renderSwitch()}
      </div>
    )
  }
}
const mapStateToProps = ({ meetingcategory }) => {
  const { categoryName } = meetingcategory;
  return {
    categoryName,
  }
}
export default connect(mapStateToProps, { setMeetingMasterUpdate })(SetupContainer);