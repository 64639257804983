import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import LnAServices from "../../services/lna/lnaservice";
import { saveLeaveYear, updateLnAData } from "../../redux/actions/lna/lnaaction";
import { FormValidator } from "@syncfusion/ej2-inputs";
import { ComboBoxComponent } from "@syncfusion/ej2-react-dropdowns";
import { pleaseSelectTeamMember, pleaseSelectLeaveYear } from "../../assets/constants/constants";
import ProgressBar from "../progressbar/progressbar";

const LeaveYearCluster = (props) => {
  const {
    authUser,
    openPopup,
    editClusterId,
    othersCheckAvailablity,
    leaveYear
  } = props;

  const [state, setState] = useState({
    MemberId: null,
    yearConfigId: null,
    rowUid: 0,
  });

  const [initialLoad, setInitialLoad] = useState(false)

  let validOptions = {
    rules: {
      memberIdErr: {
        required: [true, pleaseSelectTeamMember],
      },
      yearConfigErr: {
        required: [true, pleaseSelectLeaveYear],
      },
    },
  };

  useEffect(() => {
    props.updateLnAData({ popUpSource: 'Team Member with Leave Year Configuration' })
    getData();
  }, []);

  useEffect(() => {
    getData();
  }, [editClusterId, openPopup]);

  useEffect(() => {
    setTimeout(() => {
      props.setSubmitClick(onSaveData);
    }, 100);
  }, [state]);

  useEffect(() => {
    if (props.lnaMasterFlag === "updated"){
      props.changePopUpHandler("close");
    }
  }, [props.lnaMasterFlag]);

  const getData = async() => {

    if (openPopup === "add") {
      let data = {
        MemberId: null,
        yearConfigId: null,
        rowUid: 0,
      };
      setState((preState) => ({ ...preState, ...data }));
    } else {
      editClusterId !== null &&
        (async () => {
          setInitialLoad(true)
          if (props.leaveYearId != null) {
            const leaveYearData = await LnAServices.getLeaveYearData(
              authUser,
              props.leaveYearId,
              editClusterId
            );
            const {
              userId,
              yearConfigId,
            } = leaveYearData.Result[0];
            let data = {
              MemberId :userId,
              yearConfigId,
              rowUid: editClusterId,
            };
            setInitialLoad(false)
            setState((preState) => ({ ...preState, ...data }));
            setTimeout(() => {
              const addFormObject = new FormValidator("#leaveYearForm", validOptions);
              addFormObject.validate();
            }, 100)
          }
        })();
    }
  };
  const inputChangeHandler = (e, type) => {
    let options = {};
    switch (type) {
      case "memberId":
        setState((prevState) => ({ ...prevState, MemberId: e.value }));
        options = validOptions.rules['memberIdErr'];
        break;

      case "yearConfig":
        setState((prevState) => ({ ...prevState, yearConfigId: e.value }));
        options = validOptions.rules['yearConfigErr'];
        break;
      default:
    }
    setTimeout(() => {
      const addFormObject = new FormValidator("#leaveYearForm", options);
      addFormObject.validate();
    }, 10);
  };

  const onSaveData = () => {
    try {
      const {
        MemberId,
        yearConfigId,
        rowUid,
      } = state;

      const addFormObject = new FormValidator("#leaveYearForm", validOptions);
      if (addFormObject.validate()) {
        setInitialLoad(true)
        let leaveYearSaveData = {
          userId: authUser,
          type: rowUid > 0 ? "EDIT" : "ADD",
          MemberId,
          yearConfigId,
          rowUid,
        };
        props.saveLeaveYear(leaveYearSaveData);
        setTimeout(() => {
          setInitialLoad(false)
        });
      }
    } catch (error) {
      console.log("🚀 ~ file: Leaveyearcluster.jsx:117 ~ onSaveData ~ error", error)
    }
  };

  return (
    <>
      <div
        id="leaveYearForm"
        style={{ height: "61.5vh" }}
      >
        <div
          className="col-sm-12 col-md-12 col-lg-12"
          style={{ marginTop: "0px" }}
        >
          <div className="col-xs-12 col-sm-12 col-lg-12 col-xl-12 mt-lg">
            <ComboBoxComponent
              id="memberName"
              dataSource={othersCheckAvailablity}
              fields={{ text: "nameWithDepartment", value: "UserId" }}
              value={state.MemberId}
              data-msg-containerid="memberIdErr"
              name="memberIdErr"
              allowCustom={false}
              floatLabelType="Auto"
              cssClass="e-outline"
              placeholder="Team Member"
              change={(e) => inputChangeHandler(e, "memberId")}
            />
            <div
              id="memberIdErr"
              className="e-input-group e-float-icon-left error-div"
            />
          </div>
          <div id="memberIdErr" style={{ fontSize: 13 }} className="error-div pl-lg" />
        </div>

        <div
          className="col-sm-12 col-md-12 col-lg-12"
          style={{ marginTop: "0px" }}
        >
          <div className="col-xs-12 col-sm-12 col-lg-12 col-xl-12 mt-lg">
            <ComboBoxComponent
              id="yearConfig"
              dataSource={leaveYear}
              fields={{ text: "Name", value: "Id" }}
              value={state.yearConfigId}
              data-msg-containerid="yearConfigErr"
              name="yearConfigErr"
              allowCustom={false}
              floatLabelType="Auto"
              cssClass="e-outline"
              placeholder="Leave Year Configuration"
              change={(e) => inputChangeHandler(e, "yearConfig")}
            />
            <div
              id="yearConfigErr"
              className="e-input-group e-float-icon-left error-div"
            />
          </div>
          <div id="yearConfigErr" style={{ fontSize: 13 }} className="error-div pl-lg" />
        </div>
      </div>
      <div className="loader-new1" id="spinnerContainer" >
        {initialLoad && <ProgressBar />}
      </div>
    </>
  );
};

const mapStateToProps = ({ auth, meetingmaster, lnareducer }) => {
  const { authUser } = auth;
  const { othersCheckAvailablity, leaveYear, leaveYearId } = meetingmaster;
  const { lnaMasterFlag } = lnareducer;
  return {
    authUser,
    othersCheckAvailablity,
    leaveYear,
    leaveYearId,
    lnaMasterFlag
  };
};
export default connect(mapStateToProps, { saveLeaveYear, updateLnAData })(
  LeaveYearCluster
);
