import React, { Component } from 'react'
import PerformanceScore from './performancescore'
export default class OkrRatingreViewStatus extends Component {
  getClassName = (type) => {
    switch (type.allocationStatus) {
      case 'Complete':
        return ' selfratingbtn completedratings'
      case 'Pending':
        return ' selfratingbtn pendingratings '
      case 'NA':
        return 'selfratingbtn notapplicableratings'

      default:
        break;
    }
  }
  getClassNameSelected = (type) => {
    switch (type.allocationStatus) {
      case 'Complete':
        return ' selfratingbtn completedratings-selected'
      case 'Pending':
        return ' selfratingbtn pendingratings-selected'
      case 'NA':
        return 'selfratingbtn notapplicableratings'

      default:
        break;
    }
  }
  changeRatingCategory = (type) => {
    this.props.changeRatingCategory(type)
  }
  
  render() {
    const isOneTwentyFiveScale = window.innerHeight < 715  ||  window.innerHeight < 747;
    const { reviewMnths, ratingStatusData, monthOfRating, ratingTabs, teamMemberStatus, performanceScoreData } = this.props;
    return (
      <div>
        {reviewMnths.length > 0 && (this.props.showStatus || this.props.type === 'close') && ratingStatusData.length > 0 && monthOfRating > 0 &&
          <>
            <div className={`col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 ${this.props.type === 'close' ? '' : 'ratingcategorycontainer'}`} style={{fontSize:isOneTwentyFiveScale?12:''}}>
              <div className={`col-4 col-lg-4 col-md-4 col-sm-4 col-xs-4 ratingtabcontainer rating-container-not-selected`} style={{ paddingRight: 0, border: ratingTabs === 'self-rating' ? 0 : '' }}>
                <div className={`${ratingTabs === 'self-rating' ? 'self ' : ''}`} style={{ padding: ratingTabs === 'self-rating' ? '4px' : '3px' }}>

                  {/* Self */}
                  <div className={`${this.getClassName(ratingStatusData[1].Ratings[0])}  ${'width'} ${ratingTabs === 'self-rating' ? this.getClassNameSelected(ratingStatusData[1].Ratings[0]) : ''}`}
                    style={{ padding: 8 }}
                    onClick={() => { if (teamMemberStatus === 'self') { this.changeRatingCategory(ratingStatusData[1].Ratings[0].name) } }}>
                    <div className=''>{ratingStatusData[1].Ratings[0].name}</div>
                  </div>
                </div>
                {/* <div className={`status-text-${ratingStatusData[1].Ratings[0].allocationStatus.toLowerCase()}`} style={{textAlign:'center'}}>{ratingStatusData[1].Ratings[0].allocationStatus}</div> */}
              </div>
              {/* Supervisor */}
              <div className={`col-4 col-lg-4 col-md-4 col-sm-4 col-xs-4 ratingtabcontainer rating-container-not-selected`} style={{ paddingLeft: 0, paddingRight: 0, border: ratingTabs === 'supervisor-rating' ? 0 : '' }}>
                <div className={` ${ratingTabs === 'supervisor-rating' ? 'self ' : ''}`} style={{ padding: ratingTabs === 'supervisor-rating' ? '4px' : '3px' }}>
                  <div className={`${this.getClassName(ratingStatusData[1].Ratings[1])}  ${'width'} ${ratingTabs === 'supervisor-rating' ? this.getClassNameSelected(ratingStatusData[1].Ratings[1]) : ''} `}
                    style={{ paddingTop: 8, paddingBottom: 8 }}
                    onClick={() => { if (teamMemberStatus === 'supervisor') { this.changeRatingCategory(ratingStatusData[1].Ratings[1].name) } }}>
                    <div className=''>{ratingStatusData[1].Ratings[1].name}</div>
                  </div>
                </div>
                {/* <div className={`status-text-${ratingStatusData[1].Ratings[1].allocationStatus.toLowerCase()}` } style={{textAlign:'center'}}>{ratingStatusData[1].Ratings[1].allocationStatus}</div> */}

              </div>
              {/* Review */}
              {
                ratingStatusData.length > 3 &&
                <div className='col-4 col-lg-4 col-md-4 col-sm-4 col-xs-4 ratingtabcontainer rating-container-not-selected' style={{ paddingLeft: 0, paddingRight: 0, border: ratingTabs === 'okr-review' ? 0 : ''  }}>
                  <div className={`${ratingTabs === 'okr-review' ? 'self' : ''}`} style={{ padding: ratingTabs === 'okr-review' ? '4px' : '3px' }}>
                    <div className={`${this.getClassName(ratingStatusData[4].Review)}  ${'width'} ${ratingTabs === 'okr-review' ? this.getClassNameSelected(ratingStatusData[4].Review) : ''} `}
                      onClick={() => this.changeRatingCategory('okr-review')} style={{ marginRight: 0, padding: 8 }}>Review
                    </div>
                  </div>
                  {/* <div className={`status-text-${ratingStatusData[4].Review.allocationStatus.toLowerCase()}`}style={{textAlign:'center'}}>{ratingStatusData[4].Review.allocationStatus}</div> */}
                </div>
              }
            </div>
          </>
        }
        {
          this.props.type==='open'&& this.props.showStatus &&
          <PerformanceScore performanceScoreData={performanceScoreData}/>
        }
      </div>
    )
  }
}
