import axios from "axios";
import { urlConfig } from "../config/config";

function errorHandler(reject, error) {
  // eslint-disable-next-line
  console.dir(error);
  reject("operation failed");
}

function addPlanMeeting(pData) {
  return new Promise(function onThen(resolve, reject) {
    axios
      .post(
        `${urlConfig.apiurl}planmeeting`,
        { pData },
        {
          headers: {
            authorization: localStorage.getItem("jwtToken"),
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      // eslint-disable-next-line
      .then(function (response) {
        if (response != null) {
          return resolve(response.data.result);
        }
      })
      // eslint-disable-next-line
      .catch(function (error) {
        errorHandler(reject, error);
        return null;
      });
  });
}

function syncOutlookMeeting(pData) {
  return new Promise(function onThen(resolve, reject) {
    axios
      .post(
        `${urlConfig.apiurl}planmeeting/outlook`,
        pData,
        {
          headers: {
            authorization: localStorage.getItem("jwtToken"),
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      )
      // eslint-disable-next-line
      .then(function (response) {
        if (response != null) {
          return resolve(response.data.result);
        }
      })
      // eslint-disable-next-line
      .catch(function (error) {
        errorHandler(reject, error);
        return null;
      });
  });
}
const meetingbooking = {
  // Revealing Module Pattern
  syncOutlookMeeting,
  addPlanMeeting,
};

export default meetingbooking;
