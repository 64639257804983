import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { enableRipple } from '@syncfusion/ej2-base';
import { addUpdateObjectveKpi, submitApprovalAction, setOKRUserKPI } from "../../../src/redux/actions/okr/okractions";
import fileIcon from "../../../src/assets/svg/fileIcon_jpeg.png";
import { HtmlEditor, Image, Inject, Link, QuickToolbar, RichTextEditorComponent, Toolbar, Count, } from "@syncfusion/ej2-react-richtexteditor";
import { Button, Dialog } from "@material-ui/core";
import Tooltips from "../custommaterialtooltip/custommaterialtooltip";
import { DropDownListComponent } from "@syncfusion/ej2-react-dropdowns";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPaperclip } from "@fortawesome/pro-light-svg-icons";
import { urlConfig } from "../../config/config";
import { FormValidator } from "@syncfusion/ej2-inputs";
import { getNotify } from "../../app/other/commonfunction";
import {
  ERROR, importAtleastOneKPI, fillMandatoryFields, centralKPIDescription, centralKPIWeightage, centralKPIVariableLink, lpKPIWeightage, bvKPIWeightage, lpKPIVariableLink, bvKPIVariableLink
} from "../../assets/constants/constants";
import AddAttachmentPopup from "./addAttachmentPopup";
import "../okrcreateobjectivekpi/newobjectkpi.css";
import ReactHtmlParser from 'react-html-parser'

enableRipple(true);
class UpdateCommonKPI extends React.Component {
  constructor(props) {
    super(props);
    this.state = { weightageVal: 0, documentPath: "", openAttachment: false, refreshMe: false }
    this.weblink = [{ Id: 0, Name: "No" },
    { Id: 1, Name: "Yes" }]
  }
  updateDimensions() {
    const updateHeight = window.innerHeight - 600;
    this.setState({ heightLayout: updateHeight });
  }
  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
  }
  UNSAFE_componentWillReceiveProps = (newProps) => {
    if (newProps.refreshMe !== this.state.refreshMe) {
      this.setState({ refreshMe: newProps.refreshMe });
    }
  }
  getAllInputValue = (e, type, index) => { // NOSONAR
    const {
      objectType,
      centralOkrData,
      leaderShipOkrData,
      brandValueOkrData,
      // index,
      totalWeightage,
    } = this.props;
    const { changedData } = this.state;
    let objectTypeStr = "";
    if (objectType === "2") objectTypeStr = "central";
    else if (objectType === "3") objectTypeStr = "leadership";
    else if (objectType === "4") objectTypeStr = "brand";

    const option = { rules: {} };
    const centralOkr = centralOkrData;
    const leaderShipOkr = leaderShipOkrData;
    const brandValueokr = brandValueOkrData;

    switch (type) {
      case "weightage":
        if (e.value !== undefined && e.value !== "") {
          if (objectType === "2") {
            centralOkr[index].weightage = parseInt(e.value, 10);
            // eslint-disable-next-line
            this.props.addUpdateObjectveKpi({ centralOkrData: centralOkr });
          }
          if (objectType === "3") {
            leaderShipOkr[index].weightage = e.value;
            // eslint-disable-next-line
            this.props.addUpdateObjectveKpi({
              leaderShipOkrData: leaderShipOkr,
            });
          }
          if (objectType === "4") {
            brandValueokr[index].weightage = e.value;
            // eslint-disable-next-line
            this.props.addUpdateObjectveKpi({
              brandValueOkrData: brandValueokr,
            });
          }
        } else {
          // eslint-disable-next-line
          this.props.addUpdateObjectveKpi({
            totalWeightage: totalWeightage + 0,
          });
        }
        this.setState({ changedData: !changedData });
        break;
      case "veriableLink":
        if (e.itemData !== undefined && e.itemData !== null) {
          if (objectType === "2") {
            centralOkr[index].varibleLink = e.itemData.Id;
            // eslint-disable-next-line
            this.props.addUpdateObjectveKpi({ centralOkrData: centralOkr });
          }
          if (objectType === "3") {
            leaderShipOkr[index].varibleLink = e.itemData.Id;
            // eslint-disable-next-line
            this.props.addUpdateObjectveKpi({
              leaderShipOkrData: leaderShipOkr,
            });
          }
          if (objectType === "4") {
            brandValueokr[index].varibleLink = e.itemData.Id;
            // eslint-disable-next-line
            this.props.addUpdateObjectveKpi({
              brandValueOkrData: brandValueokr,
            });
          }
          if (objectType !== "3") {
            option.rules[`veriableLink${index}`] = {
              required: [true, "Please select variable link."],
            };
          }
        }
        break;
      case "descripion":
        if (typeof document.getElementById("descriptionErrId") !== 'undefined' && document.getElementById("descriptionErrId") !== null) {
          document.getElementById("descriptionErrId").innerHTML = "";
        }
        if (e.value !== undefined) {
          if (objectType === "2") {
            centralOkr[index].description = e.value;
            // eslint-disable-next-line
            this.props.addUpdateObjectveKpi({ centralOkrData: centralOkr });
          }
          if (objectType === "3") {
            leaderShipOkr[index].description = e.value;
            // eslint-disable-next-line
            this.props.addUpdateObjectveKpi({
              leaderShipOkrData: leaderShipOkr,
            });
          }
          if (objectType === "4") {
            brandValueokr[index].description = e.value;
            // eslint-disable-next-line
            this.props.addUpdateObjectveKpi({
              brandValueOkrData: brandValueokr,
            });
          }
        }

        break;
      default:
    }
    try {
      const formValidate = new FormValidator(
        `#${objectTypeStr}kpiSaveCommon${index}`,
        option
      );
      formValidate.validate();
    } catch (error) {
      // eslint-disable-next-line
      console.log(error);
    }
  }
  viewUploadedAttachment = (fileList) => {
    const { employeeId } = this.props;
    return (
      <>
        {fileList.length > 0 &&
          // eslint-disable-next-line
          fileList.map((name, fileIndex) => {
            return (
              <span
                style={{ paddingRight: "15px" }}
                // eslint-disable-next-line
                key={`file${fileIndex}`}>
                <a
                  // eslint-disable-next-line
                  key={`url${fileIndex}`}
                  href={`${urlConfig.tmlcApiUrl}document/kpi/${employeeId}/${name}`} target="_blank" rel="noopener noreferrer" className="attachment-file-name" download
                  style={{ textDecoration: 'none' }}
                >
                  <img alt="" src={fileIcon} key={`img${fileIndex}`} />
                  <span style={{ padding: "8px 0px 12px 2px", color: "#f94f5e" }} title={name}>
                    {name.substring(11, name.length).length > 35 ? name.substring(11, name.length).substr(0, 32) + '...' : name.substring(11, name.length)}
                  </span>
                </a>
              </span>
            );
          })}
      </>
    )
  }
  saveAsDraftKPI = async () => { // NOSONAR
    if (typeof document.getElementById("descriptionErrId") !== 'undefined' && document.getElementById("descriptionErrId") !== null) {
      document.getElementById("descriptionErrId").innerHTML = "";
    }
    const { employeeId, authUser, objectType, centralOkrData, leaderShipOkrData, brandValueOkrData, variablePerc, currentKpiIndex, currentFinancialYearId } = this.props;
    var index = currentKpiIndex;
    try {
      if ((objectType === '2' && centralOkrData.length === 0) || (objectType === '3' && leaderShipOkrData.length === 0) || (objectType === '4' && brandValueOkrData.length === 0)) {
        getNotify(ERROR, importAtleastOneKPI);
        return;
      }
      const option = { rules: {} };
      option.rules[`weightage`] = {
        required: [true, "Please enter weightage."],
        number: [true, "Please enter valid weightage."],
        min: [0, "Please enter valid weightage."],
        max: [100, "Please enter less than 100% weightage."],
      };
      if (variablePerc > 0) {
        option.rules[`veriableLink`] = {
          required: [true, "Please select variable link."],
        };
      }
      if (objectType === '2') {
        if (typeof centralOkrData[index].description === 'undefined' || centralOkrData[index].description === null || centralOkrData[index].description === "") {
          document.getElementById("descriptionErrId").innerHTML = "Please enter business description.";
          return;
        }
      }


      var formValidate = new FormValidator(`#frmEditSelectedKpi`, option);
      if (formValidate.validate() === false) {
        getNotify(ERROR, fillMandatoryFields);
        return;
      }
      const data = {};
      data.userId = authUser;
      data.empId = employeeId;
      data.financialYearId = currentFinancialYearId;
      let kpiTitle = "";
      let kpiLibIds = "";
      let kpiIds = "";
      let kpiDescription = "";
      let kpiAttachment = "";
      let kpiWeightage = "";
      let kpiVariableLinked = "";

      if (objectType === '2') {
        data.okrType = centralOkrData[index].objType;
        data.objectiveId = typeof centralOkrData[index].userObjectiveId === "undefined" ? 0 : centralOkrData[index].userObjectiveId;
        data.objectiveLibId = centralOkrData[index].objectiveId;
        data.objectiveTitle = centralOkrData[index].objectiveName;
        data.objectiveType = centralOkrData[index].objType;
        const attachmentKpi = centralOkrData[index].kpiAttachment ? centralOkrData[index].kpiAttachment : "";
        const kpiUid = typeof centralOkrData[index].userKpiId === "undefined" ? 0 : centralOkrData[index].userKpiId;
        kpiTitle = centralOkrData[index].kpiName;
        kpiLibIds = centralOkrData[index].kpiId;
        kpiIds = kpiUid;
        kpiDescription = centralOkrData[index].description;
        kpiAttachment = attachmentKpi;
        kpiWeightage = centralOkrData[index].weightage ? centralOkrData[index].weightage.toString() : "0";
        kpiVariableLinked = centralOkrData[index].varibleLink ? centralOkrData[index].varibleLink.toString() : "0";
      }
      else if (objectType === '3') {
        data.okrType = leaderShipOkrData[index].objType;
        data.objectiveId = typeof leaderShipOkrData[index].userObjectiveId === "undefined" ? 0 : leaderShipOkrData[index].userObjectiveId;
        data.objectiveLibId = leaderShipOkrData[index].objectiveId;
        data.objectiveTitle = leaderShipOkrData[index].objectiveName;
        data.objectiveType = leaderShipOkrData[index].objType;
        const attachmentKpi = leaderShipOkrData[index].kpiAttachment ? leaderShipOkrData[index].kpiAttachment : "";
        const kpiUid = typeof leaderShipOkrData[index].userKpiId === "undefined" ? 0 : leaderShipOkrData[index].userKpiId;
        kpiTitle = leaderShipOkrData[index].kpiName;
        kpiLibIds = leaderShipOkrData[index].kpiId;
        kpiIds = kpiUid;
        kpiDescription = leaderShipOkrData[index].description;
        kpiAttachment = attachmentKpi;
        kpiWeightage = leaderShipOkrData[index].weightage ? leaderShipOkrData[index].weightage.toString() : "0";
        kpiVariableLinked = leaderShipOkrData[index].varibleLink ? leaderShipOkrData[index].varibleLink.toString() : "0";
      }
      else if (objectType === '4') {
        data.okrType = brandValueOkrData[index].objType;
        data.objectiveId = typeof brandValueOkrData[index].userObjectiveId === "undefined" ? 0 : brandValueOkrData[index].userObjectiveId;
        data.objectiveLibId = brandValueOkrData[index].objectiveId;
        data.objectiveTitle = brandValueOkrData[index].objectiveName;
        data.objectiveType = brandValueOkrData[index].objType;
        const attachmentKpi = brandValueOkrData[index].kpiAttachment ? brandValueOkrData[index].kpiAttachment : "";
        const kpiUid = typeof brandValueOkrData[index].userKpiId === "undefined" ? 0 : brandValueOkrData[index].userKpiId;
        kpiTitle = brandValueOkrData[index].kpiName;
        kpiLibIds = brandValueOkrData[index].kpiId;
        kpiIds = kpiUid;
        kpiDescription = brandValueOkrData[index].description;
        kpiAttachment = attachmentKpi;
        kpiWeightage = brandValueOkrData[index].weightage ? brandValueOkrData[index].weightage.toString() : "0";
        kpiVariableLinked = brandValueOkrData[index].varibleLink ? brandValueOkrData[index].varibleLink.toString() : "0";
      }
      data.kpiLibId = kpiLibIds;
      data.kpiId = kpiIds;
      data.kpiTitle = kpiTitle;
      data.kpiDescription = kpiDescription;
      data.kpiAttachment = kpiAttachment;
      data.kpiWeightage = kpiWeightage;
      data.kpiVariableLinked = kpiVariableLinked;
      // // data.kpiVariableWeightage = kpiVariableWeightage;
      data.isDraft = "Y";
      
      // eslint-disable-next-line
      this.props.setOKRUserKPI(data);
    } catch (error) {
      // eslint-disable-next-line
      console.log(error);
    }
  }

  // --- Add Attachment related Functions - START --- //
  openAttachmentPopup = (currentKpiIndex) => {
    this.setState({ openAttachment: true, currentKpiIndex: currentKpiIndex });
  };
  closeAttachmentDialog = () => {
    this.setState({ openAttachment: false, fileNames: [] });
  };
  // --- Add Attachment related Functions - END --- //

  render() { // NOSONAR
    const { variablePerc, currentKpiDetails, currentKpiIndex, objectType } = this.props;
    const { openAttachment } = this.state;
    let fileList = [];
    if (objectType === "2" || objectType === "3" || objectType === "4") {
      if (typeof currentKpiDetails.kpiAttachment !== "undefined" && currentKpiDetails.kpiAttachment !== "" && currentKpiDetails.kpiAttachment !== null) {
        fileList = currentKpiDetails.kpiAttachment.split(",");
      }
    }
    return (
      <>
        <form id={`frmEditSelectedKpi`} method="POST" >
          {/* Business Description Rich textbox - section */}
          {objectType === '2' &&
            <>
              <div className="row">
                <div className="kpi-var-link-weightage" style={{ padding: "8px 16px 2px 16px" }}>Description</div>
                <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12" style={{ padding: "0px 42px 0px 16px" }}>
                  <div style={{ padding: "16px", border: "1px solid #80808061", color: "#000000" }}>
                    {ReactHtmlParser(currentKpiDetails.libDescription)}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="kpi-var-link-weightage" style={{ padding: "8px 16px 2px 16px" }}>Business Description</div>
                <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12" style={{ padding: "0px 42px 0px 16px" }}>
                  <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                    <RichTextEditorComponent
                      className="refreshUIRichextBox"
                      placeholder='Description'
                      value={currentKpiDetails.description}
                      id={`descripion`}
                      name={`descripion`}
                      showCharCount
                      maxLength={500}
                      data-msg-containerid={`descripionErr`}
                      change={(e) => this.getAllInputValue(e, "descripion", currentKpiIndex)}
                    >
                      <Inject services={[Toolbar, Image, Link, Count, HtmlEditor, QuickToolbar]} />
                    </RichTextEditorComponent>
                    <div style={{ float: 'left' }} id={`descriptionErrId`} className="error-div" />
                  </div>
                  <span style={{ padding: "4px 0px 0px 8px", position: "absolute" }}>
                    <Tooltips titleMessage={centralKPIDescription} />
                  </span>
                </div>
              </div>
            </>
          }
          {(objectType === '3' || objectType === '4') &&
            <div className="row">
              <div className="kpi-var-link-weightage" style={{ padding: "8px 16px 2px 16px" }}>Description</div>
              <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12" style={{ padding: "0px 42px 0px 16px" }}>
                <div style={{ padding: "16px", border: "1px solid #80808061", color: "#000000" }}>
                  {ReactHtmlParser(currentKpiDetails.description !== "" ? currentKpiDetails.description : currentKpiDetails.libDescription)}
                </div>
              </div>
            </div>
          }
          {/* Add Attachment link - section */}
          {objectType === '2' &&
            <div className="col-lg-12 mb-sm label-new pending-for-approval-accord-header" style={{ padding: "5px 0px 0px", cursor: "pointer" }} onClick={() => this.openAttachmentPopup(currentKpiIndex)}>
              <FontAwesomeIcon icon={faPaperclip} className="mr-sm" />
              Add attachment
            </div>
          }
          {objectType === '2' && fileList !== "" && fileList !== null &&
            <div className="col-xs-12 col-sm-12 col-lg-12" style={{ padding: "0px 0px 20px 0px" }}>
              <div>
                {this.viewUploadedAttachment(fileList)}
              </div>
            </div>
          }

          {/* Weightage textbox - section */}
          <div className="row" style={{ padding: objectType === '3' || objectType === '4' ? "30px 0px 30px 0px" : "" }}>
            <div className="col-xs-12 col-sm-4 col-lg-2 col-md-3" style={{ padding: "0px 26px 0px 16px" }}>
              <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                <TextBoxComponent
                  id={`weightage`}
                  name={`weightage`}
                  placeholder="Weightage % *"
                  value={currentKpiDetails.weightage}
                  popupHeight="220px"
                  cssClass="e-outline"
                  floatLabelType="Auto"
                  data-msg-containerid={`weightageErr`}
                  change={(e) => this.getAllInputValue(e, "weightage", currentKpiIndex)}
                />
                <div id={`weightageErr`} className="error-div" />
              </div>
              <span style={{ padding: "4px 0px 0px 8px", position: "absolute" }}>
                <Tooltips titleMessage={objectType === '2' ? centralKPIWeightage : objectType === '3' ? lpKPIWeightage : objectType === '4' ? bvKPIWeightage : ''}/*NOSONAR*/ />
              </span>
            </div>
            {
              variablePerc > 0 &&
              <div className="col-xs-12 col-sm-8 col-lg-3 col-md-6" style={{ padding: "0px 42px 0px 16px" }}>
                <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                  <DropDownListComponent
                    id={`veriableLink`}
                    name={`veriableLink`}
                    dataSource={this.weblink}
                    fields={{ text: "Name", value: "Id" }}
                    placeholder={objectType === '3' ? "Variable Link":"Variable Link *"}
                    value={currentKpiDetails.varibleLink}
                    popupHeight="220px"
                    cssClass="e-outline"
                    floatLabelType="Auto"
                    allowCustom={false}
                    readonly={objectType === '3' ? true : false}
                    data-msg-containerid={`veriableLinkErr`}
                    change={(e) => this.getAllInputValue(e, "veriableLink", currentKpiIndex)}
                  />
                  <div id={`veriableLinkErr`} className="error-div" />
                </div>
                <span style={{ padding: "4px 0px 0px 8px", position: "absolute" }}>
                  <Tooltips titleMessage={objectType === '2' ? centralKPIVariableLink : objectType === '3' ? lpKPIVariableLink : objectType === '4' ? bvKPIVariableLink : ''}/*NOSONAR*/ />
                </span>
              </div>
            }
          </div>
          <div className="row" style={{ float: "right", padding: "0px 42px 0px 0px" }}>
            <Button style={{ width: "120px" }} className="mr-sm cancel-button" variant="contained" onClick={this.props.fillKPIDetailsPopupClose()} >Cancel</Button>
            <Button style={{ width: "120px" }} className="remove-button-shadow" color="primary" variant="contained" onClick={this.saveAsDraftKPI} >Save</Button>
          </div>
        </form>

        {/* Add Attachment Dialog */}
        <div style={{ width: "350px" }}>
          <Dialog aria-labelledby="uploading-title" open={openAttachment} fullWidth width="1000" >
            <span className="add-new-self-header" style={{ padding: "15px 10px" }}>Add Attachment</span>
            <AddAttachmentPopup
              currentKpiIndex={currentKpiIndex}
              objectType={objectType}
              closeAttachmentDialog={this.closeAttachmentDialog}
              fileList={fileList}
            />
          </Dialog>
        </div>
      </>
    );
  }
}
UpdateCommonKPI.propTypes = {
  authUser: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  employeeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
UpdateCommonKPI.defaultProps = {
  authUser: "",
  employeeId: 0
};
const mapStateToProps = ({ auth, okrreducer, meetingmaster }) => {
  const { authUser } = auth;
  const { currentFinancialYearId } = auth;
  const { allOkrKpi, employeeId, reRenderComponent, centralOkrData, leaderShipOkrData, brandValueOkrData, } = okrreducer;
  return { authUser, allOkrKpi, employeeId, reRenderComponent, centralOkrData, leaderShipOkrData, brandValueOkrData, currentFinancialYearId}
}
export default connect(mapStateToProps, { addUpdateObjectveKpi, submitApprovalAction, setOKRUserKPI })(UpdateCommonKPI);