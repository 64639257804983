import React, { Component } from "react";
import { ReactSVG } from "react-svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import "./meetingdetailspopup.css";
import {
  faCalendar,
  faStar,
  faAddressCard,
  faTv,
  faClipboardCheck,
  faUser,
  faUserTie,
  faUserCircle,
  faMapMarkedAlt,
  faUserPlus,
  faUserCrown,
  faUserShield,
  faUserClock,
  faTimes,
  faLocationPin,
  faMapMarkerAlt,
  faSearchPlus,
  faDownload,
  faSquareList,
  faHandshake,
} from "@fortawesome/pro-light-svg-icons";
import {
  faChevronDown,
  faChevronUp
} from "@fortawesome/pro-solid-svg-icons";
import PropTypes from "prop-types";

library.add(
  faCalendar,
  faStar,
  faHandshake,
  faAddressCard,
  faTv,
  faClipboardCheck,
  faUserCircle,
  faMapMarkedAlt,
  faUserCrown,
  faUserShield,
  faDownload,
  faUserClock,
  faTimes,
  faLocationPin,
  faMapMarkerAlt,
  faSearchPlus
);

const reqSvgs = require.context("../../assets/svg", true, /\.svg$/);
const svgs = reqSvgs.keys().map((path) => ({ path, file: reqSvgs(path) }));
export default class ViewMeeting extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMoreAgenda: false,
      showMoreActionItems: false,
    };
  }

  getAttendeeIcon = (icon) => {
    let IconContent;
    const iconColor = "mr-sm ";
    switch (icon) {
      case "faUserImp":
        IconContent = (
          <FontAwesomeIcon
            title="Important Attendee"
            icon={faUserCrown}
            style={{ width: "7%", cursor: "pointer" }}
            className={iconColor}
          />
        );
        break;
      case "faUserShield":
        IconContent = (
          <FontAwesomeIcon
            title="Optional Attendee"
            icon={faUserShield}
            style={{ width: "7%", cursor: "pointer" }}
            className={iconColor}
          />
        );
        break;
      case "faStar":
        IconContent = (
          <FontAwesomeIcon
            icon={faStar}
            title="Chair Person"
            style={{ width: "7%", cursor: "pointer" }}
            className={iconColor}
          />
        );
        break;
      case "faUserClock":
        IconContent = (
          <FontAwesomeIcon
            icon={faUserClock}
            title="Partial Attendee"
            style={{ width: "7%", cursor: "pointer" }}
            className={iconColor}
          />
        );
        break;
      case "faTimes":
        IconContent = (
          <FontAwesomeIcon
            icon={faTimes}
            title="Unable to attend"
            style={{ width: "7%", cursor: "pointer" }}
            className={iconColor}
          />
        );
        break;
      default:
        IconContent = (
          <FontAwesomeIcon
            style={{ width: "7%" }}
            title="Attendee"
            className="mr-sm"
            icon={faUser}
          />
        );
        break;
    }
    return IconContent;
  };

  getAttendeeTitle = (icon) => {
    let attendeeTitle;
    switch (icon) {
      case "faUserImp":
        attendeeTitle = "Important Attendee";
        break;
      case "faUser":
        attendeeTitle = "Attendee";
        break;
      case "faUserClock":
        attendeeTitle = "Partial Attendee";
        break;
      case "faUserShield":
        attendeeTitle = "Optional Attendee";
        break;
      case "faStar":
        attendeeTitle = "Chair Person";
        break;
      case "faTimes":
        attendeeTitle = "Unable to attend";
        break;
      default:
        break;
    }
    return attendeeTitle;
  };

  getMediaName = (Name) => {
    let IconContent;

    switch (Name) {
      case "1":
        IconContent = "Webex";
        break;
      case "2":
        IconContent = "Skype";
        break;
      case "3":
        IconContent = "Zoom";
        break;
      case "4":
        IconContent = "Teams";
        break;
      default:
        break;
    }
    return IconContent;
  };

  render() { // NOSONAR
    const {
      BDContacts,
      Agenda,
      ActionItems,
      MeetingType,
      MeetingMedia,
      MeetingRooms,
      ParticipateCode,
      ClientId,
      ChairPerson,
      // eslint-disable-next-line
      otherAttendees,
      Location,
      Countries,
      Attendee,
      PrimaryColor,
      WebLinkType,
      WebLink,
      // agendaDocument,
      // eslint-disable-next-line
    } = this.props.data;
    const { meetingdate, seriesDateString } = this.props;
    const { showMoreAgenda, showMoreActionItems } = this.state;
    const cCountries = [];
    Countries.toString()
      .split("$")
      .forEach((item) => {
        if (item !== "") {
          const name = item.split("^")[0];
          const number = item.split("^")[1];
          const svg = item.split("^")[2];

          cCountries.push({ name, number, svg });
        }
      });

    const mArrAttendee = [];
    Attendee.toString()
      .split(",")
      .forEach((item) => {
        mArrAttendee.push({
          Attendee: item.split("$")[0],
          Icon: item.split("$")[1],
          Status: item.split("$")[2] === "" ? "inherit" : "#A52A2A",
          Ord: item.split("$")[3],
        });
      });

    mArrAttendee.sort((a, b) => Number(a.Ord) - Number(b.Ord));
    let declineAttendee = [];
    let otherAttendee = [];

    mArrAttendee.forEach((item) => {
      if (item.Status === "inherit") {
        otherAttendee.push(item);
      } else {
        declineAttendee.push(item)
      }
    })
    const agendalength =
      ClientId !== null && (typeof BDContacts !== 'undefined' && BDContacts.length > 0) ? 500 : 750;
    return (
      <div id="view-meeting-details" className="control-section">
        <div
          className="row chair-person-row"
          style={{ background: PrimaryColor }}
        >
          <div className="col-lg-6">
            <span className="icon-header-color">Chair Person:</span>
            <FontAwesomeIcon
              icon={faStar}
              className="view-meeting-icon-margin"
            />
            {ChairPerson}{" "}
            {WebLink !== "" && (
              <span className="icon-header-color">
                |&nbsp;&nbsp;Meeting Link:{" "}
              </span>
            )}{" "}
            {WebLink !== "" && (
              <a
                className="e-text-ellipsis weblinkStyle"
                href={WebLink}
                target="blank"
              >
                {" "}
                Open {this.getMediaName(WebLinkType)} Link
              </a>
            )}
          </div>
          <div className="col-lg-2" />
          <div className="col-lg-4 text-align-end">
            <FontAwesomeIcon
              icon={faCalendar}
              className="view-meeting-icon-margin"
            />
            <span className="icon-header-color">Meeting Date/Time: </span>{" "}
            {meetingdate}
          </div>
        </div>
        <div className="row">
          <div className="meeting-type-row">
            <div className="col-lg-4">
              <span className="icon-header-color">
                <FontAwesomeIcon
                  icon={faAddressCard}
                  className="view-meeting-icon-margin"
                />
                Meeting Type
              </span>
              <br />
              <span className="view-meeting-text-margin">{MeetingType} </span>
            </div>
            <div className="col-lg-3">
              <span className="icon-header-color">
                <FontAwesomeIcon
                  icon={faTv}
                  className="view-meeting-icon-margin"
                />
                Meeting Media
              </span>
              <br />
              <span className="view-meeting-text-margin">{MeetingMedia}</span>
            </div>
            <div className="col-lg-2">
              <span className="icon-header-color">
                <FontAwesomeIcon
                  icon={faLocationPin}
                  className="view-meeting-icon-margin"
                />
                Meeting Room
              </span>
              <br />
              <span className="view-meeting-text-margin">{MeetingRooms}</span>
            </div>
            <div className="col-lg-2">
              <span className="icon-header-color">
                <FontAwesomeIcon
                  icon={faMapMarkerAlt}
                  className="view-meeting-icon-margin"
                />
                Meeting Location
              </span>
              {/* <br /> */}
              <TooltipComponent
                id="downloadAgenda"
                content={Location}
                cssClass="customtooltip"
                position="BottomCenter"
                isSticky={false}
              >
                <span className="view-meeting-text-margin-loc meeting-location-text-limit">
                  {Location}
                </span>
              </TooltipComponent>
            </div>
          </div>
        </div>
        <hr />

        {(ActionItems !== "NA" && ActionItems !== "" && ActionItems) && <div className="row agenda-row mt-sm">
          <div>
            <div className="col-12 icon-header-color">
              <div className="col-2">
                <FontAwesomeIcon
                  icon={faSquareList}
                  className="view-meeting-icon-margin"
                  style={{ fontSize: 16 }}
                />
                &nbsp;Action Items &nbsp;&nbsp;&nbsp;&nbsp;
              </div>
            </div>
            {/* <br /> */}
            <div className="view-meeting-text-margin-loc agenda-text-limit">
              {!showMoreActionItems ? (
                <div>
                  {ActionItems.length > 750 ? ( // NOSONAR
                    <span>
                      &nbsp;&nbsp;&nbsp;{ActionItems.substring(0, 750)}{" "}
                      <br />
                      <button
                        type="button"
                        className="view-all-button"
                        onClick={() =>
                          this.setState({ showMoreActionItems: true })
                        }
                      >
                        <span className="click-here-red-text">Click here</span> to view more <FontAwesomeIcon icon={faChevronDown} />
                      </button>
                    </span>
                  ) : (
                    <span>&nbsp;&nbsp;{ActionItems}</span>
                  )}
                </div>
              ) : (
                  <div>
                    {ActionItems}
                    <br />
                    <button
                      type="button"
                      className="view-all-button"
                      onClick={() => this.setState({ showMoreActionItems: false })}
                    >
                      <span className="click-here-red-text">Click here</span> to view less <FontAwesomeIcon icon={faChevronUp} />
                    </button>{" "}
                  </div>
              )}
            </div>
          </div>
        </div>}
        {
          ActionItems !== "NA" && ActionItems !== "" && ActionItems && (<hr />)
        }
        <div className="row agenda-row mt-sm">
          {Agenda !== "NA" && Agenda !== "" && Agenda !== null && (
            <div>
              <div className="col-12 icon-header-color">
                <div className="col-2">
                  <FontAwesomeIcon
                    icon={faClipboardCheck}
                    className="view-meeting-icon-margin"
                    style={{ fontSize: 16 }}
                  />
                  &nbsp;Agenda &nbsp;&nbsp;&nbsp;&nbsp;
                </div>
              </div>
              {/* <br /> */}
              <div className="view-meeting-text-margin-loc agenda-text-limit">
                {!showMoreAgenda ? (
                  <div>
                    {Agenda.length > agendalength ? ( // NOSONAR
                      <span>
                        &nbsp;&nbsp;&nbsp;{Agenda.substring(0, agendalength)}{" "}
                        <br />
                        <button
                          type="button"
                          className="view-all-button"
                          onClick={() =>
                            this.setState({ showMoreAgenda: true })
                          }
                        >
                          <span className="click-here-red-text">Click here</span> to view more <FontAwesomeIcon icon={faChevronDown} />
                        </button>
                      </span>
                    ) : (
                      <span>&nbsp;&nbsp;{Agenda}</span>
                    )}
                  </div>
                ) : (
                  <div>
                    {Agenda}
                    <br />
                    <button
                      type="button"
                      className="view-all-button"
                      onClick={() => this.setState({ showMoreAgenda: false })}
                    >
                      <span className="click-here-red-text">Click here</span> to view less <FontAwesomeIcon icon={faChevronUp} />
                    </button>{" "}
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        {
          Agenda !== "NA" && Agenda !== "" && Agenda !== null && (<hr />)
        }

        <div className="row client-attendee-row">
          <div>
            <div className="">
              {ClientId !== null && (typeof BDContacts !== 'undefined' && BDContacts.length > 0) && (
                <div className="col-lg-6">
                  <div className=" row icon-header-color ">
                    <FontAwesomeIcon
                      icon={faUserTie}
                      className="view-meeting-icon-margin"
                    />
                    Client Details ({BDContacts.length})
                  </div>
                  <div className="row">
                    <table
                      width="95%"
                      className="clientMettingTable view-meeting-text-margin mt-sm"
                    >
                      <thead>
                        <tr>
                          <th className="e-text-ellipsis">Company Name</th>
                          <th className="e-text-ellipsis">Name</th>
                          <th className="e-text-ellipsis">Job Title</th>
                        </tr>
                      </thead>
                      <tbody>
                        {BDContacts.map((element) => {
                          return (
                            <tr key={element.ContactId}>
                              <td
                                className="e-text-ellipsis"
                                title={element.CompanyName}
                                style={{ cursor: "pointer" }}
                              >
                                {element.CompanyName}
                              </td>
                              <td
                                className="e-text-ellipsis"
                                title={element.ContactName}
                                style={{ cursor: "pointer" }}
                              >
                                {element.ContactName}
                              </td>
                              <td
                                className="e-text-ellipsis"
                                title={element.JobTitle}
                                style={{ cursor: "pointer" }}
                              >
                                {element.JobTitle}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              )}
              {/* {m_arrAttendee.length > 1 && */}
              <div
                className={
                  ClientId !== null && (typeof BDContacts !== 'undefined' && BDContacts.length > 0)
                    ? "col-lg-6"
                    : "col-lg-12"
                }
              >
                <div className=" row icon-header-color ">
                  <FontAwesomeIcon
                    icon={faUserPlus}
                    className="view-meeting-icon-margin"
                  />
                  Attendees Details ({otherAttendee.length <= 100 ? otherAttendee.length : '100+'})
                </div>
                <div className="mt-sm attendee-border">
                  <ul className="view-attendeeList">
                    {otherAttendee.map((element) => {
                      return (
                        <li
                          className="e-text-ellipsis"
                          key={element.Attendee}
                          style={{ color: element.Status, cursor: "pointer" }}
                          title={this.getAttendeeTitle(element.Icon)}
                        >
                          {
                            element.Icon === "faStar" || element.Icon === "faUserImp" ?
                              this.getAttendeeIcon(element.Icon) : null
                          }
                          {/* nosnanar {this.getAttendeeIcon(element.Icon)} */}
                          {element.Attendee}
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
              {
                declineAttendee.length > 0 &&
                <div
                  className={
                    ClientId !== null && (typeof BDContacts !== 'undefined' && BDContacts.length > 0)
                      ? "col-lg-6 pt-sm"
                      : "col-lg-12 pt-sm"
                  }
                >
                  <div className=" row icon-header-color ">
                    <FontAwesomeIcon
                      icon={faUserPlus}
                      className="view-meeting-icon-margin"
                    />
                    Declined Attendees Details ({declineAttendee.length > 100 ? '100+' : declineAttendee.length})
                  </div>
                  <div className="mt-sm attendee-border">
                    <ul className="view-attendeeList">
                      {declineAttendee.map((declElement) => {
                        return (
                          <li
                            className="e-text-ellipsis"
                            key={declElement.Attendee}
                            style={{ color: declElement.Status, cursor: "pointer" }}
                            title={this.getAttendeeTitle(declElement.Icon)}
                          >
                            {
                              declElement.Icon === "faStar" || declElement.Icon === "faUserImp" ?
                                this.getAttendeeIcon(declElement.Icon) : null
                            }
                            {declElement.Attendee}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              }
              {/* } */}
            </div>
          </div>
        </div>
        <hr />
        <div className="row" style={{ marginTop: -10 }}>
          <div className="meeting-type-row">
            {
              // eslint-disable-next-line
              otherAttendees && (
                <div className="col-lg-6">
                  <div className="icon-header-color">
                    <FontAwesomeIcon
                      icon={faUserCircle}
                      className="view-meeting-icon-margin"
                    />
                    External Attendee
                  </div>
                  <div className="view-meeting-text-margin">
                    {
                      // eslint-disable-next-line
                      otherAttendees}
                  </div>
                </div>
              )
            }
            {cCountries.length > 0 && (
              <div className={
                // eslint-disable-next-line
                otherAttendees === "" ||
                  ParticipateCode === ""
                  ? "col-lg-6"
                  : "col-lg-4"
              }
              >
                <span className="icon-header-color">
                  <FontAwesomeIcon
                    icon={faMapMarkedAlt}
                    className="view-meeting-icon-margin"
                  />
                  Conference Bridge Telephone Number
                </span>
                <div className="view-meeting-text-margin" style={{ marginTop: -8 }}>
                  {cCountries.length > 0 && (
                    <div className="mt-sm">
                      <ul className="customAttendeeList">
                        {cCountries.map((element, index) =>
                          svgs.map((item) => {
                            if (element.svg === item.path) {
                              return (
                                <li
                                  className="e-text-ellipsis"
                                  key={
                                    // eslint-disable-next-line
                                    index
                                  }
                                  style={{
                                    color: element.Status,
                                    cursor: "pointer",
                                    height: 25,
                                  }}
                                  title={element.name}
                                >
                                  <div style={{ float: "left" }}>
                                    <ReactSVG
                                      src={item.file}
                                      style={{ height: 20, width: 20 }}
                                    />
                                  </div>
                                  <div style={{ fontSize: 10, float: "left" }}>
                                    {" "}
                                    &nbsp; {element.number}
                                  </div>
                                </li>
                              );
                            }
                            return null;
                          })
                        )}
                      </ul>
                    </div>
                  )}
                </div>
              </div>
            )}
            {ParticipateCode !== "" && (
              <div className="col-lg-2">
                <span className="icon-header-color">
                  <FontAwesomeIcon
                    icon={faSearchPlus}
                    className="view-meeting-icon-margin"
                  />
                  Conference Bridge Code
                </span>
                <br />
                <span className="view-meeting-text-margin">
                  {ParticipateCode}
                </span>
              </div>
            )}
          </div>
        </div>
        {(ParticipateCode !== "" || cCountries.length > 0 || otherAttendees) && <hr />}
        {seriesDateString !== "" && (
          <div className="row">
            <div className="meeting-type-row">
              <div className="col-lg-12">
                <span className="icon-header-color">
                  <FontAwesomeIcon
                    icon={faHandshake}
                    className="view-meeting-icon-margin"
                  />
                  Recurring Meeting
                </span>
                <br />
                <span className="view-meeting-text-margin series-text">
                  {seriesDateString}{" "}
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
ViewMeeting.propTypes = {
  // eslint-disable-next-line
  data: PropTypes.object.isRequired,
  meetingdate: PropTypes.string,
  seriesDateString: PropTypes.string,
};

ViewMeeting.defaultProps = {
  // eslint-disable-next-line
  data: [],
  meetingdate: "",
  seriesDateString: "",
};
