/**
 * Author: Vivek Khodade.
 * Created:11-May-2020
 * Last Modify:11-May-2020
 * Modified By:Vivek Khodade
 */
import {
  GENERATE_CONFIRMATION_LETTER,
  EDIT_UPDATE_CONFIRMATION,
} from "../../constant/entryexit/confirmationconstant";

export const generateConfirmationLetter = (data) => {
  return {
    type: GENERATE_CONFIRMATION_LETTER,
    payload: data,
  };
};
export const editUpdateConfirmation = (data) => {
  return {
    type: EDIT_UPDATE_CONFIRMATION,
    payload: data,
  };
};
