import React, { Component } from "react";

// eslint-disable-next-line
class SessionExpired extends Component {
  render() {
    return (
      <div
        style={{
          textAlign: "center",
          background: "#ecebe9",
          position: "absolute",
          top: "50%",
          bottom: "50%",
          left: "0",
          right: "0",
        }}
      >
        <div
          style={{
            font: "icon",
            display: "inline-table",
            width: "50%",
            background: "#ecebe9",
            color: "#07090d",
            fontWeight: "bold",
          }}
        >
          <h3 style={{ marginTop: "20px", marginBottom: "20px" }}>
            Session is expired.{" "}
          </h3>
          <h3 style={{ marginTop: "20px", marginBottom: "20px" }}>
            And you have logged out from the system.{" "}
          </h3>
        </div>
      </div>
    );
  }
}
export default SessionExpired;
