/**
 * Author: Suresh R. Sargam.
 * Last Modify:15-May-2020
 * Description: to show the uploading progress once the doc is uploaded
 */
import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import "./uplprogress.css";

class UplProgress extends PureComponent {
  render() {
    const { progress } = this.props;
    return (
      <div className="ProgressBar">
        <div className="Progress" style={{ width: `${progress}%` }} />
      </div>
    );
  }
}

UplProgress.propTypes = {
  progress: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default UplProgress;
