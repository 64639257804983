/**
 * Author Name : Vivek Khodade.
 * Created Date: -
 * Modified Date: 15/04/2020.
 * Last Modified Name: Vivek Khodade.
 * Last Modified By: Vivek Khodade.
 * Code Reviewed Date : 08/09/2020
 * Code Reviewed By : Vivek Khodade
 */
import * as React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import { Button } from "@material-ui/core";
import { Query } from "@syncfusion/ej2-data";
import {
  faUser,
  faIdCard,
  faToolbox,
  faLaptop,
} from "@fortawesome/free-solid-svg-icons";
import {
  MultiSelectComponent,
  CheckBoxSelection,
  Inject, ComboBoxComponent
} from "@syncfusion/ej2-react-dropdowns";
import { getNotify, closeAgendaPopup, refreshMultiSelect } from "../../other/commonfunction";
import { ERROR } from "../../../assets/constants/constants";
import ProgressBar from "../../../components/progressbar/progressbar";

import ErrorMessage from "./errorMessage";
import {
  setTIBMultipleUsersId,
  setTIBMultipleMonthsIds,
} from "../../../redux/actions/tibacton";
import tibrservices from "../../../services/tibservices";

import TIBR from "./tibr";
import { TIME_IN_BUSINESS } from "../../../redux/constant/meetingcategoryconstant";

class TimeInBusiness extends React.Component {
  constructor(props) {
    super(props);
    // maps the appropriate column to fields property
    this.state = {
      tibrData: null,
      showError: false,
      changedData: false,
      progress: true,
      selectedUserId: [],
      selectedUserMonthsIds: [],
      selectedCategoryMaster: [],
      selectedTypeId: 7,
      showDropDown: false
    };
    this.selectedTIBUsersId = [];
    this.removeUser = this.removeUser.bind(this);
    this.getSelectedAttendee = this.getSelectedAttendee.bind(this);
    this.getSelectedMonths = this.getSelectedMonths.bind(this);
    this.removeMonths = this.removeMonths.bind(this);
    this.getTibReports = this.getTibReports.bind(this);
    this.created = this.created.bind(this)
  }

  // Here we called the close tooltip only
  // eslint-disable-next-line
  UNSAFE_componentWillMount() {
    // Close the tooltip if already opened
    closeAgendaPopup();
  }

  /**
   * shouldComponentUpdate
   * @param {*} nextProps
   * @param {*} nextState
   * This funcion is manage the rerender of this component using change data state.
   */
  shouldComponentUpdate(nextProps, nextState) {
    const { changedData } = this.state;
    if (changedData !== nextState.changedData) {
      // Close the tooltip if already opened
      closeAgendaPopup();
      return true;
    }
    return false;
  }

  /**
   * componentDidMount
   * Here we call the api servise name getUserTIBRData
   * Sevice gets the user data and bind into tree grid.
   */
  componentDidMount = () => {
    const { selectedTypeId } = this.state;
    try {

      this.getMasterCategoryList();
      this.updateDimensions();
      window.addEventListener("resize", this.updateDimensions.bind(this));
      // Close the tooltip if already opened
      closeAgendaPopup();

      setTimeout(() => {

        // eslint-disable-next-line
        var userListString = this.props.authUser;
        let mode = "single";
        // eslint-disable-next-line
        if (this.props.selectedTIBMonthsId.toString() !== "") {
          // eslint-disable-next-line
          if (this.props.selectedTIBUsersId.toString() !== "") {
            // eslint-disable-next-line
            userListString = this.props.selectedTIBUsersId.toString();
            mode = "multiple";
          }
          // eslint-disable-next-line
          let meetingDetails = Promise.resolve(
            tibrservices.getUserTIBRData(
              // eslint-disable-next-line
              this.props.selectedTIBMonthsId.toString(),
              userListString,
              mode,
              selectedTypeId
            )
          );
          meetingDetails.then((result) => {
            if (result !== null && result.length > 0) {
              this.setState({
                tibrData: result,
                // eslint-disable-next-line
                selectedUserMonthsIds: this.props.selectedTIBMonthsId,
                // eslint-disable-next-line
                selectedUserId: this.props.selectedTIBUsersId,
                showError: false,
                errorMessage: "",
                // eslint-disable-next-line
                changedData: !this.state.changedData,
                progress: false,
              });
            } else {
              this.setState({
                showError: true,
                // eslint-disable-next-line
                selectedUserMonthsIds: this.props.selectedTIBMonthsId,
                errorMessage: "Currently No Data Available. ",
                tibrData: [],
                // eslint-disable-next-line
                changedData: !this.state.changedData,
                progress: false,
              });
            }
          });
        }
      }, 1000)

    } catch (error) {
      this.setState({
        showError: true,
        errorMessage: "Ops! Something went wrong. please refresh. ",
        tibrData: [],
        // eslint-disable-next-line
        changedData: !this.state.changedData,
        progress: false,
      });
    }
  };
  getMasterCategoryList = () => {
    let getCategaryMaster = Promise.resolve(
      tibrservices.getTBIRCategoryMaster("TIBRMaster")
    );
    getCategaryMaster.then((result) => {
      if (result !== null && result.length > 0) {
        this.setState({ selectedCategoryMaster: result });
      }
    });
  }
  getSelectedType = (e) => { // NOSONAR
    const { tibMonts } = this.props;
    let myMonth = [];
    if (e.itemData !== null) {
      if (e.itemData.Id === 7) {
        for (let i = 0; i < tibMonts.length; i++) {
          if (i < 1) {
            myMonth.push(tibMonts[i].Id);
          }
        }
      } else if (e.itemData.Id === 8) {
        for (let j = 0; j < tibMonts.length; j++) {
          if (j < 3) {
            myMonth.push(tibMonts[j].Id);
          }
        }
      } else if (e.itemData.Id === 9) {
        for (let k = 0; k < tibMonts.length; k++) {
          if (k < 12) {
            myMonth.push(tibMonts[k].Id);
          }
        }
      }
      this.setState({ selectedTypeId: e.itemData.Id, selectedUserMonthsIds: myMonth, changedData: !this.state.changedData });
      this.props.setTIBMultipleMonthsIds(myMonth)

    } else {
      this.setState({ selectedTypeId: "" });
    }
  }
  async getSelectedAttendee(e) {
    document.getElementById("tib-select-user").innerHTML = "";
    const trimDisplayName = e.itemData;
    let selectedUserIds;
    if (e.itemData.Display_name.includes("(")) {
      // eslint-disable-next-line
      selectedUserIds = this.state.selectedUserId;
      selectedUserIds.push(trimDisplayName.Id);
      this.setState(
        {
          selectedUserId: selectedUserIds,
        },
        () =>
          // eslint-disable-next-line
          this.props.setTIBMultipleUsersId(this.state.selectedUserId)
      );
    } else {
      // eslint-disable-next-line
      selectedUserIds = this.state.selectedUserId
      selectedUserIds.push(e.itemData.Id);
      this.setState(
        {
          selectedUserId: selectedUserIds,
        },
        () =>
          // eslint-disable-next-line
          this.props.setTIBMultipleUsersId(this.state.selectedUserId)
      );
    }
  }

  /**
   * removeUser
   * @param {*} e :event
   * this function is used for to select the element of user remove dropdown data.
   */
  // eslint-disable-next-line
  removeUser(e) {
    // eslint-disable-next-line
    let selectedUserIds = this.state.selectedUserId;
    // eslint-disable-next-line
    selectedUserIds = selectedUserIds.filter(el => el !== e.itemData.Id)
    this.setState(
      {
        selectedUserId: selectedUserIds,
      },
      () =>
        // eslint-disable-next-line
        this.props.setTIBMultipleUsersId(this.state.selectedUserId)
    );
  }

  /**
   * removeMonths
   * @param {*} e :event
   * this function is used for to select the element of month remove dropdown data.
   */
  removeMonths(e) {
    // eslint-disable-next-line
    let selectedUserMonthsIds = this.state.selectedUserMonthsIds;
    // eslint-disable-next-line
    selectedUserMonthsIds = selectedUserMonthsIds.filter(el => el !== e.itemData.Id
    );
    if (selectedUserMonthsIds.length === 0) {
      this.setState({ selectedUserMonthsIds });
      document.getElementById("tib-select-month").innerHTML =
        "* Please select month";
      return;
    }
    this.setState(
      {
        selectedUserMonthsIds,
      },
      () => {
        // eslint-disable-next-line
        this.props.setTIBMultipleMonthsIds(this.state.selectedUserMonthsIds)
      }
    );
  }

  /**
   * handleFilteringByName
   * this function is used for to search data in select user dropdown.
   */
  handleFilteringByName = (e) => {
    const newData = [];
    // eslint-disable-next-line
    this.props.tibUserList.forEach((eachD) => {
      newData.push(eachD);
    });
    if (newData != null && newData.length > 0) {
      let query = new Query();
      query =
        e.text !== ""
          ? query.where("Display_name", "contains", e.text, true)
          : query;
      e.updateData(newData, query);
    }
  };

  /**
   * getSelectedMonths
   * @param {*} e :event
   * this function is used for to select the element of remove user dropdown data.
   */
  getSelectedMonths = (e) => {
    document.getElementById("tib-select-month").innerHTML = "";
    // eslint-disable-next-line
    let selectedMonthsIds = this.state.selectedUserMonthsIds;
    selectedMonthsIds.push(e.itemData.Id);
    this.setState(
      {
        selectedUserMonthsIds: selectedMonthsIds,
      },
      () => {
        // eslint-disable-next-line
        this.props.setTIBMultipleMonthsIds(this.state.selectedUserMonthsIds)
      }
    );
  };
  // eslint-disable-next-line
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions.bind(this));
  }

  getTibReports = () => {
    document.getElementById("selectTypeErr").innerHTML = "";
    const { selectedTypeId } = this.state
    if (this.searchUser.value.length > 0) {
      // eslint-disable-next-line
      if (this.props.selectedTIBMonthsId.length < 1 || this.state.selectedUserMonthsIds.length < 1) {
        document.getElementById("tib-select-month").innerHTML =
          "* Please select month";
        return;
      }
      if (selectedTypeId === "") { //NOSONAR
        document.getElementById("selectTypeErr").innerHTML = "Please select type";
        return;
      }
      try {
        this.setState({
          progress: true,
          // eslint-disable-next-line
          changedData: !this.state.changedData,
        });
        // eslint-disable-next-line
        let meetingDetails = tibrservices.getUserTIBRData(this.props.selectedTIBMonthsId.toString(), this.searchUser.value.toString(), 'multiple', selectedTypeId);
        meetingDetails.then((result) => {
          if (result !== null && result.length > 0) {
            this.setState(
              {
                tibrData: [],
              },
              () =>
                this.setState({
                  tibrData: result,
                  showError: false,
                  errorMessage: "",
                  // eslint-disable-next-line
                  changedData: !this.state.changedData,
                  progress: false,
                })
            );
          } else {
            this.setState({
              showError: true,
              errorMessage: "Currently No Data Available. ",
              tibrData: [],
              // eslint-disable-next-line
              changedData: !this.state.changedData,
              progress: false,
            });
          }
          this.updateDimensions();
        });
      } catch (error) {
        this.setState({
          showError: true,
          errorMessage: "Ops! Something went wrong. please refresh. ",
          tibrData: [],
          // eslint-disable-next-line
          changedData: !this.state.changedData,
          progress: false,
        });
      }
    } else {
      getNotify(ERROR, "Please select user from select user dropdown");
      document.getElementById("tib-select-user").innerHTML =
        "* Please select user";
    }
  };

  /**
   * Calculate & Update state of new dimensions
   */
  updateDimensions() {
    // eslint-disable-next-line
    let updateHeight =
      (window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight) - 80;
    if (document.querySelector(".usrsel") !== null) {
      updateHeight -= document.querySelector(".usrsel").offsetHeight;
    }
    // eslint-disable-next-line
    this.setState({ WindowHeight: updateHeight, changedData: !this.state.changedData });
  }
  UNSAFE_componentWillReceiveProps(newProps) {
    const { tibUserList, tibMonts, selectedTIBUsersId } = this.props;
    const { changedData } = this.state;
    if ((tibUserList === null || newProps.tibUserList.length !== tibUserList.length)
      || (tibMonts === null || newProps.tibMonts.length !== tibMonts.length)
      || (typeof selectedTIBUsersId !== 'undefined' && newProps.selectedTIBUsersId.length !== selectedTIBUsersId.length)
    ) {
      this.setState({ changedData: !changedData });
    }

  }

  created(pDivId, pComponentId) {
    try {
      refreshMultiSelect(pDivId, pComponentId);
      setTimeout(() => {
        this.setState({ showDropDown: true })
        document.getElementById(pComponentId).ej2_instances[0].refresh()
      })
    } catch (error) {
      console.log("🚀 ~ file: timeinbusiness.js ~ line 433 ~ TimeInBusiness ~ created ~ error", error)
    }
  }

  render() {
    const { selectedCategoryMaster, selectedTypeId, selectedUserMonthsIds, showDropDown } = this.state;
    const { selectedTIBUsersId, tibMonts, tibUserList } = this.props;
    return (
      // eslint-disable-next-line
      <div>
        <div className="col-lg-12 usrsel"
          style={{ padding: 5, margin: "10px 10px 5px 10px", display: showDropDown ? "block" : "none" }}
        >
          {
            // eslint-disable-next-line
            this.props.categoryName === TIME_IN_BUSINESS && (
              <div
                className="control-section col-lg-3 tib-dropdown-padding"
                style={{ width: 510 }}
              >
                <div id="multigroupchkUser" className="control-styles">
                  <MultiSelectComponent
                    id="checkboxUsr"
                    ref={(scope) => {
                      this.searchUser = scope;
                    }}
                    popupHeight="350px"
                    cssClass="e-outline"
                    fields={{ text: "Display_name", value: "Id" }}
                    dataSource={tibUserList}
                    placeholder="Select User *"
                    mode="CheckBox"
                    showDropDownIcon
                    value={selectedTIBUsersId}
                    //  value={['Id']}
                    enableGroupCheckBox={true}
                    maximumSelectionLength={10}
                    allowFiltering={true}
                    filtering={this.handleFilteringByName}
                    select={this.getSelectedAttendee}
                    //  removed={this.removeUser}
                    filterBarPlaceholder="Select User"
                    floatLabelType="Auto"
                    data-msg-containerid="tib-select-user"
                    created={this.created("multigroupchkUser", "checkboxUsr")}
                  >
                    <Inject services={[CheckBoxSelection]} />
                  </MultiSelectComponent>
                </div>
                <div id="tib-select-user" className="error-div pl-sm" />
              </div>
            )
          }
          <div
            className="control-section col-lg-2 col-md-2 tib-dropdown-padding tib-month-dropdown-width"
            style={{ width: 160 }}
          >
            <div id="multigroup" className="control-styles">
              {
                selectedCategoryMaster.length > 0 ?
                  <ComboBoxComponent
                    id="selectType"
                    fields={{ text: "Name", value: "Id" }}
                    data-msg-containerid="selectTypeErr"
                    name="selectTypeErr"
                    allowFiltering
                    allowCustom={false}
                    floatLabelType="Auto"
                    cssClass="e-outline"
                    placeholder="View By *"
                    change={(e) => this.getSelectedType(e)}
                    dataSource={selectedCategoryMaster}
                    value={selectedTypeId}
                  />
                  :
                  <ComboBoxComponent
                    id="selectType"
                    data-msg-containerid="selectTypeErr"
                    name="selectTypeErr"
                    allowFiltering
                    allowCustom={false}
                    floatLabelType="Auto"
                    cssClass="e-outline"
                    placeholder="View By *"
                  />
              }
            </div>
            <div id="selectTypeErr" className="error-div pl-sm" />
          </div>
          <div
            className="control-section col-lg-2 col-md-2 tib-dropdown-padding tib-month-dropdown-width"
            style={{ width: 250 }}
          >
            <div id="multigroup" className="control-styles">
              <MultiSelectComponent
                id="checkboxMnth"
                ref={(scope) => {
                  this.mulObj = scope;
                }}
                cssClass="e-outline"
                dataSource={tibMonts}
                value={selectedUserMonthsIds}
                fields={{ text: "Name", value: "Id" }}
                placeholder="Select Month *"
                mode="CheckBox"
                showSelectAll={false}
                showDropDownIcon
                select={this.getSelectedMonths}
                removed={this.removeMonths}
                filterBarPlaceholder="Select Month"
                popupHeight="350px"
                floatLabelType="Auto"
                data-msg-containerid="tib-select-month"
                maximumSelectionLength={selectedTypeId === 7 ? 3 : selectedTypeId === 8 ? 6 : 12} // NOSONAR
                created={this.created("multigroup", "checkboxMnth")}
              >
                <Inject services={[CheckBoxSelection]} />
              </MultiSelectComponent>
            </div>
            <div id="tib-select-month" className="error-div pl-sm" />
          </div>
          <div className="control-section col-lg-1 view-button">
            <div id="multigroup" className="control-styles">
              <Button
                onClick={this.getTibReports}
                // eslint-disable-next-line
                style={{ color: !this.state.progress ? "white" : "#c1baba" }}
                disabled={this.state.progress}
                color="primary"
                variant="contained"
                title="View TIB Report"
              >
                View
              </Button>
            </div>
          </div>

        </div>
        <div
          className="scrollbar"
          style={{
            // eslint-disable-next-line
            height: this.state.WindowHeight,
            width:
              (window.innerWidth ||
                document.documentElement.clientWidth ||
                document.body.clientWidth) - 70, // Add By Prashant Waphare | 30-06-2020 | fix width of container
            marginLeft: 10,
            marginRight: -5,
            overflow: "auto",
          }}
        >
          {
            // eslint-disable-next-line
            this.state.tibrData !== null && this.state.tibrData.length > 0 && !this.state.progress &&
            // eslint-disable-next-line
            this.state.tibrData.map((data, index) => (
              <React.Fragment
                // eslint-disable-next-line
                key={index}>
                <div
                  // eslint-disable-next-line
                  key={index} className="overflow-hidden">
                  <div className="control-section col-md-12 ursdetails">
                    <div className="row emp-details">
                      <div
                        style={{
                          border: "lightgray 1px solid",
                          margin: "0px 25px 0px 22px",
                        }}
                      />

                      <div
                        className="col-md-3 remove-padding"
                        style={{
                          paddingTop: 5,
                          paddingLeft: 0,
                          paddingBottom: 5,
                        }}
                      >
                        <div
                          className="col-md-2 remove-padding"
                          style={{ height: "100%" }}
                        >
                          <div className="row1 user-icon-div">
                            <FontAwesomeIcon
                              icon={faUser}
                              className="mr-sm icons-timeinbusiness"
                              style={{ color: data.user_status_color }}
                            />
                          </div>
                        </div>
                        <div className="col-md-10 remove-padding icon-left-align">
                          <div className="row icon-header-text-font-style-user">
                            Name
                          </div>
                          <div className="row icon-text-font-style-user">
                            {data.Name}
                          </div>
                        </div>
                      </div>

                      <div
                        className="col-md-3 remove-padding"
                        style={{
                          paddingTop: 5,
                          paddingLeft: 0,
                          paddingBottom: 5,
                        }}
                      >
                        <div
                          className="col-md-2 remove-padding"
                          style={{ height: "100%" }}
                        >
                          <div className="row1 user-icon-div">
                            <FontAwesomeIcon
                              icon={faIdCard}
                              className="mr-sm icons-timeinbusiness"
                            />
                          </div>
                        </div>
                        <div className="col-md-10 remove-padding">
                          <div className="row icon-header-text-font-style-user">
                            Employee Code
                          </div>
                          <div className="row icon-text-font-style-user">
                            {data.EmployeeCode}
                          </div>
                        </div>
                      </div>

                      <div
                        className="col-md-3 remove-padding"
                        style={{
                          paddingTop: 5,
                          paddingLeft: 0,
                          paddingBottom: 5,
                        }}
                      >
                        <div
                          className="col-md-2 remove-padding"
                          style={{ height: "100%" }}
                        >
                          <div className="row1 user-icon-div">
                            <FontAwesomeIcon
                              icon={faToolbox}
                              className="mr-sm icons-timeinbusiness"
                            />
                          </div>
                        </div>
                        <div className="col-md-10 remove-padding">
                          <div className="row icon-header-text-font-style-user">
                            Title
                          </div>
                          <div className="row icon-text-font-style-user">
                            {data.Title}
                          </div>
                        </div>
                      </div>
                      <div
                        className="col-md-3 remove-padding"
                        style={{
                          paddingTop: 5,
                          paddingLeft: 0,
                          paddingBottom: 5,
                        }}
                      >
                        <div
                          className="col-md-2 remove-padding"
                          style={{ height: "100%" }}
                        >
                          <div className="row1 user-icon-div">
                            <FontAwesomeIcon
                              icon={faLaptop}
                              className="mr-sm icons-timeinbusiness"
                            />
                          </div>
                        </div>
                        <div className="col-md-10 remove-padding">
                          <div className="row icon-header-text-font-style-user">
                            System
                          </div>
                          <div className="row icon-text-font-style-user">
                            {data.System}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* eslint-disable-next-line */}
                  {
                    <TIBR key={index} dataSource={data.data} // hideSidebar={this.props.hideSidebar}
                      noOfUses={this.state.selectedUserId.length} grayLeaves={this.props.tibGrayLeaves} viewBySelected={selectedTypeId}
                    />
                  }
                </div>
              </React.Fragment>
            ))
          }
          {
            // eslint-disable-next-line
            this.state.progress && (
              <div
                style={{
                  zIndex: 999999,
                  position: "absolute",
                  marginTop: "20%",
                  marginLeft: "49%",
                }}
              >
                <ProgressBar />
              </div>
            )
          }
          {
            // eslint-disable-next-line
            this.state.showError && <ErrorMessage message={this.state.errorMessage} />
          }
        </div>
      </div>
    );
  }
}
TimeInBusiness.propTypes = {
  authUser: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  categoryName: PropTypes.string,
  selectedTIBUsersId: PropTypes.arrayOf(Object),
  selectedTIBMonthsId: PropTypes.arrayOf(Object),
  tibMonts: PropTypes.arrayOf(Object),
  tibUserList: PropTypes.arrayOf(Object),
  tibGrayLeaves: PropTypes.arrayOf(Object),
};
TimeInBusiness.defaultProps = {
  authUser: "",
  // eslint-disable-next-line
  categoryName: "",
  selectedTIBUsersId: [],
  selectedTIBMonthsId: [],
  tibMonts: [],
  tibUserList: "",
  tibGrayLeaves: "",
};

const mapStateToProps = ({ auth, meetingcategory, tibReducer }) => {
  const { authUser } = auth;
  // eslint-disable-next-line  
  const {
    selectedTIBUsersId,
    tibMonts,
    tibUserList,
    selectedTIBMonthsId,
    tibGrayLeaves,
  } = tibReducer;
  return {
    authUser,
    selectedTIBUsersId,
    tibMonts,
    tibUserList,
    selectedTIBMonthsId,
    tibGrayLeaves,
  };
};

export default connect(mapStateToProps, {
  setTIBMultipleUsersId,
  setTIBMultipleMonthsIds,
})(TimeInBusiness);

