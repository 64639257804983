// ...............................
// OrgChart.jsx
// ...............................
import React, { useEffect, useRef, useState } from "react";
import * as go from "gojs";
import { ReactDiagram } from "gojs-react";
import { connect } from "react-redux";
import { setOrhChartDetaila } from '../../../redux/actions/entryexit/orgchartaction' //'../../redux/actions/entryexit/orgchartaction'
import "./OrgChart.css"; // contains .diagram-component CSS
import { GOJSLICENSE } from '../../../config/config'

const OrgChartLayoutLeft = (OrgChartProps) => {

  const { showFullScreen, showOrgScreen } = OrgChartProps
  // const [diagramObj, setDiagramObj] = useState({})
  const [scale, setScale] = useState(window.devicePixelRatio)
  const [digranComponent, setDigramComponent] = useState('diagram-component')
  const [digranComponentHide, setDigramComponentHide] = useState('diagram-component-hide')
  const [digramClassSow, setDigranmClassSow] = useState('diagramco-org-show')
  const [digramClassHide, setDigranmClassSowHide] = useState('diagramco-org-hide')

  let zoomInc = 1
  let diagramRef = useRef()
  const $ = go.GraphObject.make;
  go.Diagram.licenseKey = GOJSLICENSE // set your license key here before creating the diagram: go.Diagram.licenseKey = "...";

  var diagram = $(go.Diagram, {
    // autoScale: go.Diagram.Uniform,
    contentAlignment: go.Spot.TopCenter,
    maxSelectionCount: 1, // users can select only one part at a time
    initialDocumentSpot: go.Spot.TopCenter,
    initialViewportSpot: go.Spot.TopCenter,
    validCycle: go.Diagram.CycleDestinationTree, // make sure users can only create trees
    model: $(go.TreeModel, {
      nodeKeyProperty: "key",
      nodeParentKeyProperty: "parent",
    }),
    layout: $(go.TreeLayout, {
      treeStyle: go.TreeLayout.StyleRootOnly,
      arrangement: go.TreeLayout.ArrangementHorizontal,
      // properties for most of the tree:
      angle: 0,
      layerSpacing: 35,
      // properties for the "last parents":
      alternateAngle: 0,
      alternateLayerSpacing: 35,
      // alternateAlignment: go.TreeLayout.AlignmentBus,
      alternateNodeSpacing: 20,
      comparer: go.LayoutVertex.smartCompare
    }),
    "undoManager.isEnabled": false // enable undo & redo
  });

  diagram.addDiagramListener("Modified", function (e) {
    try {
      let memberName = document.getElementById('teamMember').value
      if (memberName) {
        let results = diagram.findNodesByExample({ name: memberName })
        if (OrgChartProps.filterData.team === 1) {
          diagram.findTreeRoots().each(root => root.collapseTree(3));
        } else {
          if (Object.keys(results.iterator)) {
            if (results.iterator.count > 0) {
              results.each(ele => {

                diagram.findTreeRoots().each(root => root.expandTree(ele.findTreeLevel() + 2));
              })
            } else {
              diagram.findTreeRoots().each(root => root.collapseTree(2));
             }
          }
        }
      } else {
        diagram.findTreeRoots().each(root => root.collapseTree(5));
      }

      diagram.commandHandler.scrollToPart(diagram.findNodeForKey(1));
      diagram.nodes.each((event) => {
        if (event.data.nodeType !== "teamMember") {
          if (event.data.office === '') {
            event.findObject("nodeName").margin = go.Margin.parse("20 0 0 5")
          }
          if (event.findObject("INFO") != null) {
            event.findObject("infoBtn").visible = false
            event.findObject("INFO").visible = false
          }
        }
        if (event.findObject("INFOSTR") != null) {
          event.findObject("INFOSTR").visible = false
        }
        if (event.data.isExpand === "0") {

          if (event.findObject("INFOSTR") != null) {
            event.findObject("infoBtnStr").visible = false
            event.findObject("INFOSTR").visible = false
          }
        } else {
          if (event.data.directReportee === 0) {
            event.findObject("dReportee").text = ''
          }
          if (event.data.totalReportee === 0) {
            event.findObject("tReportee").text = ''
          }
        }
      })
    } catch (err) {
      console.log(err)
    }
  });
  diagram.addDiagramListener("InitialLayoutCompleted", function (e) {
    e.diagram.findTreeRoots().each(function (r) {
      let lvale = 2
      if (r.Gf === 'LayerView') {
        lvale = 1
      }
      r.expandTree(lvale);
    });
  });
  useEffect(() => {//NOSONAR
    setScale(window.devicePixelRatio)
    if (scale === 1.25) {
      setDigramComponent('diagram-component-scale')
      setDigramComponentHide('diagram-component-hide-scale')
      setDigranmClassSow('diagramco-org-show-scale')
      setDigranmClassSowHide('diagramco-org-hide-scale')
    }
    diagram.layout.invalidateLayout();
    return () => initDiagram
  }, [OrgChartProps.viewType, zoomInc]);


  /**
   * Diagram initialization method, which is passed to the ReactDiagram component.
   * This method is responsible for making the diagram and initializing the model and any templates.
   * The model's data should not be set here, as the ReactDiagram component handles that via the other props.
   */
  function initDiagram() {

    try {
      // manage parent info manually when a node or link is deleted from the diagram
      diagram.addDiagramListener("SelectionDeleting", function (e) {
        var part = e.subject.first(); // e.subject is the diagram.selection collection,
        // so we'll get the first since we know we only have one selection
        diagram.startTransaction("clear parent");
        var child, parentText;
        if (part instanceof go.Node) {
          var it = part.findTreeChildrenNodes(); // find all child nodes
          while (it.next()) {
            // now iterate through them and clear out the parent information
            child = it.value;
            parentText = child.findObject("parent"); // since the parent TextBlock is named, we can access it by name
            if (parentText === null) return;
            parentText.text = "";
          }
        } else if (part instanceof go.Link) {
          child = part.toNode;
          parentText = child.findObject("parent"); // since the parent TextBlock is named, we can access it by name
          if (parentText === null) return;
          parentText.text = "";
        }

      });


      const levelColors = [
        "white",

      ];

      // override TreeLayout.commitNodes to also modify the background brush based on the tree depth level
      diagram.layout.commitNodes = function () {
        go.TreeLayout.prototype.commitNodes.call(diagram.layout); // do the standard behavior
        // then go through all of the vertexes and set their corresponding node's Shape.fill
        // to a brush dependent on the TreeVertex.level value
        diagram.layout.network.vertexes.each(function (v) {
          if (v.node) {
            var level = v.level % levelColors.length;
            var color = levelColors[level];
            var shape = v.node.findObject("SHAPE");
            if (shape)
              shape.fill = $(go.Brush, "Linear", {
                0: color,
                1: go.Brush.lightenBy(color, 0.05),
                start: go.Spot.Left,
                end: go.Spot.Right
              });
          }
        });
      };

      // this is used to determine feedback during drags
      /** 
      function mayWorkFor(node1, node2) {
        if (!(node1 instanceof go.Node)) return false; // must be a Node
        if (node1 === node2) return false; // cannot work for yourself
        if (node2.isInTreeOf(node1)) return false; // cannot work for someone who works for you
        return true;
      } */

      // This function provides a common style for most of the TextBlocks.
      // Some of these values may be overridden in a particular TextBlock.
      function textStyle() {
        return {
          font: "9pt  Segoe UI,sans-serif", stroke: "white",
          // overflow: go.TextBlock.OverflowClip,
          // maxLines:2,
          wrap: go.TextBlock.WrapFit, width: 180, isMultiline: true,
          alignment: go.Spot.Center
        };
      }

      let layerTemp = $(go.Node, "Spot", {
        selectionAdorned: false, locationSpot: go.Spot.TopCenter,
        isShadowed: true, shadowBlur: 1,
        shadowOffset: new go.Point(0, 1),
        shadowColor: "rgba(0, 0, 0, .14)",
        isTreeExpanded: false
      },

        $(go.Panel, "Auto",
          $(go.Shape, "Rectangle", {
            name: "recbox",
            fill: "white",
            stroke: "#B7B7BB",
            strokeWidth: 1,
            width: 300,
            background: "white",
            alignment: go.Spot.Left,
            height:50
            // geometryString: "F M0 0 L80 0 B-90 90 80 20 20 20 L100 100 20 100 B90 90 20 80 20 20z",
          }
          ),
          $(go.Panel, "Vertical",
            $(go.Panel, "Vertical",
              { width: 300, alignment: go.Spot.Center },
              $(go.Panel, "Auto", { alignment: go.Spot.Center, },
                $(
                  go.TextBlock,
                  // textStyle(),
                  {
                    name: "NAMETB",
                    font: "normal normal normal 14px/19px Arial",
                    stroke: "black",
                    editable: false,
                    isMultiline: true,
                    alignment: go.Spot.Center,
                    maxSize: new go.Size(300, 50),
                    // wrap: go.TextBlock.WrapFit, 
                    margin: 10
                  },
                  new go.Binding("text", "office").makeTwoWay()
                ),
              ),

              $("PanelExpanderButton", "INFOSTR",
                { margin: new go.Margin(-30, 15, 0, 140), name: 'infoBtnStr', alignment: go.Spot.Right, },
                new go.Binding("stroke", "isSelected", function (h) { return h ? "white" : "#000000"; }).ofObject(),
              ),

            ),
            $(go.Shape, "LineH",
              {
                stroke: "#B7B7BB", strokeWidth: 1, name: "bottombox",
                height: 1, stretch: go.GraphObject.Horizontal
              },
              new go.Binding("visible").ofObject("INFOSTR")  // only visible when info is expanded
            ),

            $(
              go.Panel,
              "Auto", { name: "INFOSTR" },
              $(go.Shape, "Rectangle",
                {
                  name: "recData",
                  stroke: null,
                  fill: "white",
                  // desiredSize: new go.Size(214, 29)                  
                },
                new go.Binding("visible").ofObject("INFOSTR"),
              ),

              $(go.Panel, "Vertical",
                new go.Binding("itemArray", "subContent"),
                {
                  itemTemplate:
                    $(go.Panel, "Auto",
                      { margin: 3, },
                      $(go.TextBlock, new go.Binding("text", ""),
                        {
                          margin: 2, alignment: go.Spot.Left,
                          width: 200, isMultiline: true,
                          maxSize: new go.Size(200, 30)
                        })
                    )  // end of itemTemplate
                }),
            ),
          ),
        ),
        $("TreeExpanderButton",
          {
            name: "BUTTONXX", alignment: go.Spot.RightCenter, opacity: 1,  // initially not visible
            "_treeExpandedFigure": "TriangleRight",
            "_treeCollapsedFigure": "TriangleLeft",
            alignmentFocus: go.Spot.Center,
            "ButtonIcon.strokeWidth": 0,
          },
        ),
      )


      function nodeClickProfile(e, obj) {
        obj.findObject('recData').width = 214
        OrgChartProps.onLoadProfileData(obj.data)
        if (obj.data.teamMemStatus === "Acting") {
          obj.findObject('recbox').fill = '#9D7AD2'
          obj.findObject('recbox').stroke = '#9D7AD2'
          obj.findObject('recData').fill = 'white'
          obj.findObject('bottombox').stroke = '#9D7AD2'
        } else if (new Date(obj.data.joiningDate).getTime() > new Date().getTime()) {
          obj.findObject('recbox').fill = '#48B8E7'
          obj.findObject('recbox').stroke = '#48B8E7'
          obj.findObject('recData').fill = 'white'
          obj.findObject('bottombox').stroke = '#48B8E7'
        }
      }

      var newDesign = $(go.Node, "Spot", {
        click: nodeClickProfile,
      },
        {
          selectionAdorned: false, locationSpot: go.Spot.TopCenter,
          isShadowed: true, shadowBlur: 1,
          shadowOffset: new go.Point(0, 1),
          shadowColor: "rgba(0, 0, 0, .14)",
          isTreeExpanded: false
          // selection adornment to match shape of nodes
        },
        $(go.Panel, "Auto",
          $(go.Shape, "Rectangle", {
            name: "recbox",
            fill: "white",
            stroke: null,
            strokeWidth: 1,
            width: 214,
            background: "white",
            // geometryString: "F M0 0 L80 0 B-90 90 80 20 20 20 L100 100 20 100 B90 90 20 80 20 20z",
          }
            , new go.Binding("stroke", "isHighlighted", function (h) { return h ? "#F84F5E" : "#B7B7BB"; }).ofObject(),

            new go.Binding("stroke", "isSelected", function (h) { return h ? "#F84F5E" : "#B7B7BB"; }).ofObject(),
            new go.Binding("fill", "isSelected", function (h) { return h ? "#F84F5E" : "white"; }).ofObject()
          ),
          $(go.Panel, "Vertical",

            $(go.Panel, "Horizontal", { width: 214, height: 52 },
              {
                toolTip:  // define a tooltip for each node that displays the color as text
                  $("ToolTip",
                    $(go.TextBlock, new go.Binding("text", "name"), { margin: 4 },
                    ),
                  ),  // end of Adornment

              },
              $(go.Panel, "Spot",
                {
                  margin: go.Margin.parse("0 0 0 10"), isClipping: true, row: 1,
                  columnSpan: 5, alignment: go.Spot.Center,
                },
                $(go.Shape, "Circle", { width: 36, height: 36, strokeWidth: 0, margin: go.Margin.parse("8 20 0 65") }),
                // $(go.Picture, require("./assets/3.jpeg").default,
                $(go.Picture, new go.Binding("source", "ImageUrl"),
                  { width: 36, height: 36, margin: go.Margin.parse("10 10 0 0"), imageStretch: go.GraphObject.UniformToFill, }
                )),

              $(go.Panel, "Vertical", { name: 'nodeName' },
                $(
                  go.TextBlock,

                  {
                    font: "normal normal bold 14px Arial",
                    editable: false,
                    isMultiline: true,
                    stroke: null,
                    alignment: go.Spot.Left,
                    margin: go.Margin.parse("5 0 0 5"),
                    overflow: go.TextBlock.OverflowEllipsis,
                    maxLines: 1,
                    width: 150,
                  },
                  new go.Binding("stroke", "isSelected", function (h) { return h ? "white" : "#000000"; }).ofObject(),
                  new go.Binding("text", "name"),

                ),

                $(go.TextBlock, {
                  overflow: go.TextBlock.OverflowClip,
                  maxLines: 2,
                  width: 140
                }, new go.Binding("text", "office"),
                  new go.Binding("stroke", "isSelected", function (h) { return h ? "white" : "#000000"; }).ofObject(), {
                  font: "normal normal normal 12px Arial",
                  editable: false,
                  alignment: go.Spot.Left,
                  margin: go.Margin.parse("1 0 0 5"),
                },
                ),
                $("PanelExpanderButton", "INFO",
                  { margin: new go.Margin(-35, 0, 0, 140), name: 'infoBtn' },
                  new go.Binding("stroke", "isSelected", function (h) { return h ? "white" : "#000000"; }).ofObject(),
                ),
              ),

            ),
            $(go.Shape, "LineH",
              {
                stroke: null, strokeWidth: 1, name: "bottombox",
                height: 1, stretch: go.GraphObject.Horizontal
              },
              new go.Binding("visible").ofObject("INFO")  // only visible when info is expanded
              , new go.Binding("stroke", "isSelected", function (h) { return h ? "#F84F5E" : "#B7B7BB"; }).ofObject(),
              new go.Binding("stroke", "isHighlighted", function (h) { return h ? "#F84F5E" : "#B7B7BB"; }).ofObject(),
            ),


            $(
              go.Panel,
              "Auto", { name: "INFO" },
              $(go.Shape, "Rectangle",
                {
                  name: "recData",
                  stroke: null,
                  height: 29
                  // desiredSize: new go.Size(214, 29)                 
                },
                new go.Binding("visible").ofObject("INFO"),
                new go.Binding("fill", "isSelected", function (h) { return h ? "#FEDCDF" : "white"; }).ofObject()
              ),
              $(go.Panel, "Horizontal",
                // {
                //   name: "INFO",  // identify to the PanelExpanderButton
                // },
                $(go.TextBlock, new go.Binding("text", "totalReportee"), {
                  font: "normal bold normal 14px Arial",
                  editable: false,
                  stroke: "#000000",
                  alignment: go.Spot.Left,
                  margin: new go.Margin(0, 0, 0, 10),
                  name: "dReportee"

                },
                  {
                    toolTip:                       // define a tooltip for each node
                      $(go.Adornment, "Spot",      // that has several labels around it
                        { background: "transparent" },  // avoid hiding tooltip when mouse moves
                        $(go.Placeholder, { padding: 10 }),
                        $(go.TextBlock, "Team Size",
                          {
                            font: "normal normal normal 14px/16px Arial",
                            verticalAlignment: go.Spot.Center, textAlign: "center", alignment: go.Spot.Bottom, alignmentFocus: go.Spot.Center, stroke: "white", background: "black", width: 81,
                            height: 24
                          },
                        ),
                      )  // end Adornment
                  }

                )
                ,
                $(go.TextBlock, new go.Binding("text", "directReportee"), {
                  font: "normal bold normal 14px Arial",
                  editable: false,
                  alignment: go.Spot.Left,
                  margin: new go.Margin(0, 0, 0, 10),
                  stroke: "#F94F5E",
                  name: "tReportee"
                }, {
                  toolTip:                       // define a tooltip for each node
                    $(go.Adornment, "Spot",      // that has several labels around it
                      { background: "transparent" },  // avoid hiding tooltip when mouse moves
                      $(go.Placeholder, { padding: 10 }),
                      $(go.TextBlock, "Direct Reportees",
                        {
                          font: "normal normal normal 14px/16px Arial",
                          verticalAlignment: go.Spot.Center, textAlign: "center", alignment: go.Spot.Bottom, alignmentFocus: go.Spot.Center, stroke: "white", background: "black", width: 121,
                          height: 24
                        },
                      ),

                    )  // end Adornment
                }

                ), $(go.TextBlock, new go.Binding("text", "country"), {
                  font: "normal bold normal 14px Arial",
                  stroke: "#000000",
                  editable: false,
                  alignment: go.Spot.Right,
                  margin: new go.Margin(0, 0, 0, 130),

                },
                ),
              ),),

          ),
        ),

        $("TreeExpanderButton", {
          name: "BUTTONXX", alignment: go.Spot.RightCenter, opacity: 1, // initially not visible
          _treeExpandedFigure: "TriangleRight",
          _treeCollapsedFigure: "TriangleLeft", alignmentFocus: go.Spot.Center, "ButtonIcon.strokeWidth": 0,
        },
        ),
      );
      var templmap = new go.Map(); // In TypeScript you could write: new go.Map<string, go.Node>();
      // for each of the node categories, specify which template to use

      templmap.add("LayerView", layerTemp);
      templmap.add("TeamView", newDesign);


      // for the default category, "", use the same template that Diagrams use by default;
      // this just shows the key value as a simple TextBlock
      templmap.add("", diagram.nodeTemplate);
      diagram.nodeTemplateMap = templmap;

      // the context menu allows users to make a position vacant,
      // remove a role and reassign the subtree, or remove an office
      diagram.toolManager.dragSelectingTool.isEnabled = true;

      diagram.linkTemplate = $(
        go.Link,
        go.Link.Orthogonal,
        { corner: 5, relinkableFrom: true, relinkableTo: true },
        $(go.Shape, { strokeWidth: 1, stroke: "#B7B7BB" })
      );
      // setDiagramObj(diagram)
      OrgChartProps.onLoadTreeEvent(diagram);
      return diagram;
    } catch (err) {
      console.log('Error', err)
    }

  } // end init
  const style = `
  #myOverviewDiv {
    position: absolute;
    width: 400px;
    height: 200px;
    right: 50px;
    bottom: 50px;
    background-color: #f2f2f2;
    z-index: 300; /* make sure its in front */
    border: solid 1px #7986cb;
  }
  `
  return (
    <div >
      <style>{style}</style>

      <ReactDiagram
        ref={diagramRef}
        initDiagram={initDiagram}
        divClassName={showFullScreen ? digranComponentHide : digranComponent && showOrgScreen ? digramClassHide : digramClassSow}
        nodeDataArray={OrgChartProps.nodeDataArray}
        skipsDiagramUpdate={false}
      />
    </div>
  );
};


const mapStateToProps = ({ orgchartreducer }) => {
  const { showFullScreen, showOrgScreen } = orgchartreducer;
  return { showFullScreen, showOrgScreen };
};
export default connect(mapStateToProps, { setOrhChartDetaila })(
  OrgChartLayoutLeft
);
