import React, { useEffect, useState } from "react";
import { ComboBoxComponent } from "@syncfusion/ej2-react-dropdowns";
import { FormValidator } from "@syncfusion/ej2-react-inputs";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import okrServices from "../../services/okr/okrservices";

import "../../app/containers/okr/oksetup.css"
import { connect } from "react-redux";

import Popover from '@material-ui/core/Popover';

import { Typography, Button } from '@material-ui/core';

import {
  typeofRating, pleaseSelectTeamMemberMonth, pleaseSelectTeamMemberFromDate, pleaseSelectTeamMemberToDate, pleaseSelectTeamMemberTypeofRating,
  enterIndicator, pleaseSelectTeamMemberLastDate, pleaseSelectTeamMemberLastDateSup, pleaseSelectTeamMemberLastDateFinal, teamMemberMatrixMonthFromColumnIndex
} from "../../assets/constants/constants";
import { adminAddEditCalculation, updateOKRData } from "../../redux/actions/okr/okractions";
import { getMonth } from 'date-fns'
import ProgressBar from "../progressbar/progressbar";
import { getMySqlDateFormat } from '../../app/other/commonfunction'


const TeamMemberMatrix = (props) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [indicator, setIndicator] = useState([]);
  const [memberList, setMemberList] = useState([]);
  const [memberName, setMemberName] = useState("");
  const [memberLength, setMemberLength] = useState('')
  const { authUser, teammemberData, masterItemId, fyId, openPopup, okrMasterFlag, changePopUpHandler, updateOKRData, loading //NOSONAR
  } = props;
  const financialYear = props.finacialYearData?.filter(e => e.Id === (props.fyId ?? props.currentFinancialYearId))[0]?.Name?.split(' - ');
  const startDate = new Date(financialYear[0], 3, 1);
  const endDate = new Date(financialYear[1], 2, 31);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const [state, setState] = useState({
    month: null,
    fromDate: null,
    toDate: null,
    indicator: null,
    lastDate: null,
    lastDateSup: null,
    lastDateFinal: null,
    typeofRating: "standerdCalculation",
    self: false,
    supervisor: false,
    final: false,
    selffreeze: false,
    supfreeze: false,
    finalfreeze: false,
    selectedDay: null,
    frequency: "1",
    frequencyIndicator: "1",
    fyId,
    applicableStartDate: null,
    applicableEndDate: null,
    userId: masterItemId
  })

  const [btnDisables, setBtnDisables] = useState({
    resetSelf: false,
    supReset: false,
    finalReset: false,
    selfUnfreeze: false,
    supUnfreeze: false,
    finalUnfreeze: false
  })

  useEffect(() => {
    setTimeout(() => {
      props.setSubmitClick(onSaveData);
    }, 100);
  }, [state, props.masterId]);

  useEffect(() => {
    if (okrMasterFlag === "updated") {
      changePopUpHandler("close");
    }
  }, [okrMasterFlag]);
  let month = "";
  useEffect(() => {
    (async () => {
      try {
        if (openPopup === 'add') {
          updateOKRData({ loading: false });
          if (Array.isArray(teammemberData)) {
            const firstMemberName = teammemberData[0]['employeeName'];
            setMemberName(firstMemberName)
            let text = props?.teammemberData.slice(1)?.length > 0 ? `+ ${props?.teammemberData.slice(1)?.length} more` : "";
            setMemberLength(text);
            const mappData = props.teammemberData.slice(1)?.map((data) => {
              return data?.employeeName
            })
            setMemberList(mappData)
          }
        }

        if (openPopup === 'edit') {
          updateOKRData({ loading: true });
          setMemberName(teammemberData['employeeName'])
          setMemberLength('');
          setMemberList([])
          month = teamMemberMatrixMonthFromColumnIndex[teammemberData['column']['field']]
          const okrAdminEditItemData = await okrServices.getAdmincalcuData(
            masterItemId, fyId, month
          );
          if (okrAdminEditItemData.data.length > 0) {

            let { finalResetEnable, finalUnfreezeEnable, selfResetEnable, selfUnfreezeEnable, supResetEnable, supUnfreezeEnable } = okrAdminEditItemData.data[0];

            setBtnDisables(prevState => ({
              ...prevState,
              resetSelf: selfResetEnable === 'Y',
              supReset: supResetEnable === 'Y',
              finalReset: finalResetEnable === 'Y',
              selfUnfreeze: selfUnfreezeEnable === 'Y',
              supUnfreeze: supUnfreezeEnable === 'Y',
              finalUnfreeze: finalUnfreezeEnable === 'Y'
            }))

            setState(prevState => {
              let { RatingType, enumMonth, finalLastDate, finalRatingFreeze, finalRatingReset, selfLastDate, selfRatingFreeze, selfRatingReset, supLastDate, supRatingFreeze, supRatingReset } = okrAdminEditItemData.data[0]
              return {
                ...prevState,
                typeofRating: typeofRating.filter(e => e.Name === RatingType)[0]?.Value,
                month: new Date(Number(enumMonth) > 3 ? financialYear[0] : financialYear[1], Number(enumMonth) - 1),
                lastDateFinal: finalLastDate,
                finalfreeze: finalRatingFreeze === "1" ? true : false,
                final: finalRatingReset === "1" ? true : false,
                lastDate: selfLastDate,
                selffreeze: selfRatingFreeze === "1" ? true : false,
                self: selfRatingReset === "1" ? true : false,
                lastDateSup: supLastDate,
                supfreeze: supRatingFreeze === "1" ? true : false,
                supervisor: supRatingReset === "1" ? true : false,
              }
            })
          } else {
            const selectedMonth = new Date(Number(month) > 3 ? financialYear[0] : financialYear[1], Number(month) - 1)
            setState(prevState => {
              return {
                ...prevState,
                month: selectedMonth
              }
            })
          }
          updateOKRData({ loading: false });
        }
      }
      catch (e) {
        updateOKRData({ loading: false });
      }
    })();
  }, [masterItemId, month]);

  useEffect(() => {
    if (state.month) {
      var selectedDate = new Date(state.month)
      const currIndicator = getMonth(selectedDate) + 1 + ""
      setState((prevState) => ({ ...prevState, applicableStartDate: new Date(selectedDate.getFullYear(), selectedDate.getMonth(), 1) }));
      setState((prevState) => ({ ...prevState, indicator: currIndicator, applicableEndDate: new Date(selectedDate.getFullYear(), selectedDate.getMonth() + 1, 0) }));
    }
  }, [state.month])

  useEffect(() => {
    if (state.self) {
      setState((prevState) => ({ ...prevState, self: state.self }));
    }
  }, [state.self])

  const inputChangeHandler = (e, type) => {
    let options = {}
    switch (type) {
      case "month":
        let frequencyIndicator;
        if (getMonth(e.value) + 10 > 12) {
          frequencyIndicator = getMonth(e.value) - 2
        } else {
          frequencyIndicator = getMonth(e.value) + 10
        }
        setState((prevState) => ({ ...prevState, month: e.value, frequencyIndicator: frequencyIndicator + '' }));
        options = {
          rules: {
            teamMemberMonthErr: {
              required: [true, pleaseSelectTeamMemberMonth],
            },
          },
        };
        break;
      case "fromDate":
        setState((prevState) => ({ ...prevState, fromDate: e.value }));
        options = {
          rules: {
            teamMemberFromDateErr: {
              required: [true, pleaseSelectTeamMemberFromDate],
            },
          },
        };
        break;
      case "toDate":
        setState((prevState) => ({ ...prevState, toDate: e.value }));
        if (new Date(e.value) < new Date(state.fromDate)) {
          document.getElementById('teamMemberToDateErr').innerHTML = 'To  date must be greater than From date.'
        }
        options = {
          rules: {
            teamMemberToDateErr: {
              required: [true, pleaseSelectTeamMemberToDate],
            },
          },
        };
        break;
      case "indicator":
        setState((prevState) => ({ ...prevState, indicator: e.value }));
        options = {
          rules: {
            teamMemberIndicatorErr: {
              required: [true, enterIndicator],
            },
          },
        };
        break;
      case "lastDate":
        setState((prevState) => ({ ...prevState, lastDate: e.value }));
        options = {
          rules: {
            teamMemberLastDateErr: {
              required: [(state.self === true || state.selffreeze) ? true : false, pleaseSelectTeamMemberLastDate],
            },
          },
        };
        break;

      case "lastDateSup":
        setState((prevState) => ({ ...prevState, lastDateSup: e.value }));
        options = {
          rules: {
            teamMemberLastDateSupErr: {
              required: [(state.supervisor === true || state.supfreeze === true) ? true : false, pleaseSelectTeamMemberLastDateSup],
            },
          },
        };
        break;
      case "lastDateFinal":
        setState((prevState) => ({ ...prevState, lastDateFinal: e.value }));
        options = {
          rules: {
            teamMemberLastDateFinalErr: {
              required: [(state.final || state.finalfreeze) ? true : false, pleaseSelectTeamMemberLastDateFinal],
            },
          },
        };
        break;
      case "typeofRating":
        setState((prevState) => ({
          ...prevState, typeofRating: e.value,
          selffreeze: false,
          supfreeze: false,
          finalfreeze: false,
          self: false,
          supervisor: false,
          final: false,
        }));
        options = {
          rules: {
            teamMemberTypeofRatingErr: {
              required: [true, pleaseSelectTeamMemberTypeofRating],

            },
          },
        };
        break;
      case "self":
        setState((prevState) => (
          {
            ...prevState,
            self: !prevState.self,
            supervisor: getIfDisabled('supReset') ? false : !prevState.self,
            final: getIfDisabled('finalReset') ? false : !prevState.self,
            lastDate: prevState.self ? "" : prevState.lastDate,
            lastDateSup: prevState.self ? "" : prevState.lastDateSup,
            lastDateFinal: prevState.self ? "" : prevState.lastDateFinal
          }));
        break;
      case "selffreeze":
        setState((prevState) => (
          {
            ...prevState,
            lastDate: prevState.selffreeze ? "" : prevState.lastDate,
            lastDateSup: prevState.selffreeze ? "" : prevState.lastDateSup,
            lastDateFinal: prevState.selffreeze ? "" : prevState.lastDateFinal,
            selffreeze: !state.selffreeze,
            supfreeze: !state.selffreeze,
            finalfreeze: !state.selffreeze
          }));
        break;
      case "supfreeze":
        setState((prevState) => (
          {
            ...prevState,
            lastDateSup: prevState.supfreeze ? "" : prevState.lastDateSup,
            lastDateFinal: prevState.supfreeze ? "" : prevState.lastDateFinal,
            supfreeze: !state.supfreeze,
            finalfreeze: !state.supfreeze
          }
        ));
        break;
      case "finalfreeze":
        setState((prevState) => ({ ...prevState, lastDateFinal: prevState.finalfreeze ? "" : prevState.lastDateFinal, finalfreeze: !state.finalfreeze }));
        break;
      case "supervisor":
        setState((prevState) => (
          {
            ...prevState,
            lastDateSup: prevState.supervisor ? "" : prevState.lastDateSup,
            lastDateFinal: prevState.supervisor ? "" : prevState.lastDateFinal,
            supervisor: !state.supervisor,
            final: getIfDisabled('finalReset') ? false : !state.supervisor
          }
        ));
        break;
      case "final":
        setState((prevState) => ({ ...prevState, lastDateFinal: prevState.final ? "" : prevState.lastDateFinal, final: !state.final }));
        break;
      default:
    }
    setTimeout(() => {
      const addFormObject = new FormValidator("#teamClusterForm", options);
      addFormObject.validate();
    }, 100);
  }
  const onSaveData = () => {
    const {
      lastDate,
      lastDateSup,
      lastDateFinal,
      selffreeze,
      supfreeze,
      finalfreeze,
      self,
      supervisor,
      final,
    } = state;

    const options = {
      rules: {
        teamMemberMonthErr: {
          required: [true, pleaseSelectTeamMemberMonth],
        },
        // teamMemberFromDateErr: {
        //   required: [true, pleaseSelectTeamMemberFromDate],
        // },
        // teamMemberToDateErr: {
        //   required: [true, pleaseSelectTeamMemberToDate],
        // },
        // teamMemberIndicatorErr: {
        //   required: [true, enterIndicator],
        // },
        // teamMemberTypeofRatingErr: {
        //   required: [true, pleaseSelectTeamMemberTypeofRating],
        // },
        teamMemberLastDateErr: {
          required: [(state.self === true || state.selffreeze) ? true : false, pleaseSelectTeamMemberLastDate],
        },
        teamMemberLastDateSupErr: {
          required: [(state.supervisor === true || state.self === true) ? true : false, pleaseSelectTeamMemberLastDateSup],
        },
        teamMemberLastDateFinalErr: {
          required: [(state.final || state.finalfreeze) ? true : false, pleaseSelectTeamMemberLastDateFinal],
        },

      },
    };

    setTimeout(() => {

      const teamClusterSaveData = {
        type: state.typeofRating,
        userId: authUser,
        empId: masterItemId + '',
        fyId,
        ratingMonth: getMonth(state.month) + 1 + '',
        freezeseSelf: selffreeze ? "1" : "0",
        freezeseSup: supfreeze ? "1" : "0",
        freezeseFinal: finalfreeze ? "1" : "0",
        resetSelf: self ? "1" : '0',
        resetSup: supervisor ? "1" : '0',
        resetFinal: final ? "1" : '0',
        selfLastDate: getMySqlDateFormat(lastDate),
        supLastDate: getMySqlDateFormat(lastDateSup),
        finalLastDate: getMySqlDateFormat(lastDateFinal)
      };

      const addFormObject = new FormValidator("#teamClusterForm", options);
      if (addFormObject.validate()) {
        updateOKRData({ loading: true });
        props.adminAddEditCalculation(teamClusterSaveData)
      }

    }, 300);

  }
  const getIfDisabled = (type) => {
    if (state.typeofRating !== "standerdCalculation") return true;
    return !btnDisables[type];
  }

  const getIndicatorList = async (pFyId, pFrequencyId) => {
    const indicatorArray = []
    const frequencyList = await okrServices.getOKRFrequencyData(authUser, pFyId, pFrequencyId);
    const frequencyListData = frequencyList.data
    for (var i = 0; i < frequencyListData.length; i++) {
      indicatorArray.push({ Name: frequencyListData[i].startDate, Id: `${i + 1}` })
    }
    setIndicator(indicatorArray)
    if (frequencyListData) {
      const applicableDates = frequencyListData[state.frequencyIndicator - 1]
      setState((prevState) => ({ ...prevState, applicableStartDate: applicableDates?.actualStartDate }));
      setState((prevState) => ({ ...prevState, applicableEndDate: applicableDates?.actualEndDate }));
    }
  };
  useEffect(() => {
    if (state.frequency == "1") {
      getIndicatorList(fyId, "-1");
    }
  }, [state.frequency]);
  const getIndicatorText = () => {
    let indicatorText = "";    
      indicator?.forEach((t) => {//NOSONAR
        	if(t.Id===state.frequencyIndicator){
          	indicatorText=t.Name;
        	}
      })
      return indicatorText;
  }
  return (
    <>
      <div id="teamClusterForm">
        <div
          className="team-modal-matrix"
          style={{
            backgroundColor: "#ffffff",
            height: "8.5rem",
            fontWeight: 600,
            fontStyle: "Circular Std Book",
            fontSize: "1rem",
            padding: "16px",
            fontFamily: "Arial",
            borderBottom: "1px solid #DBDBDD",
            marginBottom: "0px",
          }}
        >

          <p style={{ color: "#212529", font: " bold 16px arial" }}>Settings</p>
          <div className="col-xs-12 col-sm-7 col-lg-7 mt-sm mb-sm atc-no-paddding">
            <p style={{ color: "#939399", font: " normal 14px arial" }}>Selected Team Member</p>
            <p style={{ color: "#212529", font: " bold 16px arial", display: "flex", alignItems: "center" }}>
              {memberName}
              <span id='team-popover' className="member-total" aria-describedby={id} variant="contained" color="primary" style={{ cursor: "pointer" }} onClick={handleClick}>
                {memberLength !== '' && memberLength}
              </span>
            </p>
          </div>

          <Popover
            className="team-popover-text"
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorReference='anchorEl'
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'center',
            }}
            classes={{ paper: 'team-popover' }}
          >
            {memberList?.length > 0 && memberList.map((data) => (
              <Typography className="Popover-content">{data}</Typography>
            ))}
          </Popover>

          <div className="col-xs-12 col-sm-5 col-lg-5  atc-no-paddding">
            <p style={{ color: "#939399", font: " normal 14px arial" }}>Month *</p>
            <DatePickerComponent
              style={{ width: "100%" }}
              id="month"
              name="teamMemberMonthErr"
              data-msg-containerid="teamMemberMonthErr"
              format="MMMM y"
              start='Decade'
              min={startDate}
              max={endDate}
              //sets the depth
              depth='Year'
              value={state.month}
              // floatLabelType="Auto"
              cssClass="e-outline matrix-datepiker"
              inputProps={{
                required: true,
              }}
              firstDayOfWeek={1}
              placeholder="Select Month"
              change={(e) => inputChangeHandler(e, "month")}
            />

            <div
              id="teamMemberMonthErr"
              className="e-input-group e-float-icon-left error-div"
            />
          </div>



        </div>

        <div className="matrix-sec-modal" style={{ padding: "16px" }}>

          <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 " >
            <div
              className="col-xs-6 col-sm-6 col-lg-4 col-md-6"
              style={{ padding: 0 }}
            >
              <p style={{ color: "#939399", font: " normal 14px arial" }}>Type of Rating *</p>
              <ComboBoxComponent
                id="teamMemberTypeofRating"
                data-msg-containerid="teamMemberTypeofRatingErr"
                name="teamMemberTypeofRatingErr"
                dataSource={typeofRating}
                fields={{ text: "Name", value: "Value" }}
                value={state.typeofRating}
                placeholder="Select Type of Rating"
                cssClass="e-outline"
                inputProps={{
                  required: true,
                }}
                change={(e) => inputChangeHandler(e, "typeofRating")}
                htmlAttributes={{ maxlength: 100 }}
              />
              <div
                id="teamMemberTypeofRatingErr"
                className="e-input-group e-float-icon-left error-div"
              />
            </div>
            <div
              className="col-xs-6 col-sm-6 col-lg-8 col-md-6"
              style={{ paddingRight: 0 }}
            >

              <p style={{ color: "#939399", font: " normal 14px arial" }}>Indicator *</p>
              <div style={{ border: "1px solid #C2C2C2", padding: "9px", borderRadius: "5px", fontWeight: 600 }}>
                {getIndicatorText()}
              </div>
              {/* <ComboBoxComponent
                id="frequencyIndicator"
                data-msg-containerid="frequencyIndicator"
                name="frequencyIndicator"
                dataSource={indicator}
                fields={{ text: "Name", value: "Id" }}
                value={state.frequencyIndicator}
                cssClass="e-outline"
                onChange={(e) => inputChangeHandler(e, "frequencyIndicator")}
                htmlAttributes={{ maxlength: 100 }}
                readonly
              />
              <div
                id="teamMemberIndicatorErr"
                className="e-input-group e-float-icon-left error-div"
              /> */}
            </div>
          </div>


          <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 pb-md atc-switch-heading " style={{ color: "#939399", font: " bold 14px arial" }}>Rating Applicable Period</div>
          <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 " >
            <div
              className="col-xs-6 col-sm-6 col-lg-6 col-md-6"
              style={{ padding: 0 }}
            >
              <DatePickerComponent
                style={{ width: "100%" }}
                id="teamMemberFromDate"
                name="teamMemberFromDateErr"
                data-msg-containerid="teamMemberFromDateErr"
                format="dd-MMM-yyyy"
                value={state.applicableStartDate}
                floatLabelType="Auto"
                cssClass="e-outline"
                firstDayOfWeek={1}
                placeholder="From "
                disabled={true}
              />
              <div
                id="teamMemberFromDateErr"
                className="e-input-group e-float-icon-left error-div"
              />
            </div>
            <div
              className="col-xs-6 col-sm-6 col-lg-6 col-md-6"
              style={{ paddingRight: 0 }}
            >
              <DatePickerComponent
                style={{ width: "100%" }}
                id="teamMemberToDate"
                name="teamMemberToDateErr"
                data-msg-containerid="teamMemberToDateErr"
                format="dd-MMM-yyyy"
                value={state.applicableEndDate}
                floatLabelType="Auto"
                cssClass="e-outline"
                firstDayOfWeek={1}
                placeholder="To"
                disabled={true}
              />
              <div
                id="teamMemberToDateErr"
                className="e-input-group e-float-icon-left error-div"
              />
            </div>

          </div>

          <div style={{
            display: "flex",
            flexDirection: "column",

          }}>
            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 atc-switch-heading " style={{ color: "#939399", font: " bold 14px arial", display: "flex", flexDirection: "column", padding: "0" }}>
              <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12  atc-switch-heading " style={{ color: "#939399", font: " bold 14px arial", padding: "0" }}>Self Rating</div>
              <div className="reset-last">
                <span className={(getIfDisabled('resetSelf')) ? "btn-disabled-cursor" : ""}>
                  <Button
                    style={{ width: "90px", font: "  14px arial", color: getIfDisabled('selfUnfreeze') ? '#9494B6' : '#000000' }}
                    className={
                      state.self ? "remove-button-shadow reset-matrix-btn active-bg"
                        : "remove-button-shadow reset-matrix-btn deactive-bg"}
                    variant="contained"
                    onClick={(e) => inputChangeHandler(e, "self")}
                    disabled={getIfDisabled('resetSelf')}
                  >
                    <span style={{ color: getIfDisabled('resetSelf') ? '#9494B6' : '' }}>
                      Reset
                    </span>
                  </Button>
                </span>
                <span className={getIfDisabled('selfUnfreeze') ? "btn-disabled-cursor" : ""}>
                  <Button
                    style={{ width: "90px", font: "  14px arial", color: getIfDisabled('selfUnfreeze') ? '#9494B6' : '#000000' }}
                    className={state.selffreeze ? "remove-button-shadow reset-matrix-btn active-bg" : "remove-button-shadow reset-matrix-btn deactive-bg"}
                    variant="contained"
                    onClick={(e) => inputChangeHandler(e, "selffreeze")}
                    disabled={getIfDisabled('selfUnfreeze')}
                  >
                    <span style={{ color: getIfDisabled('selfUnfreeze') ? '#9494B6' : '' }}>
                      Unfreeze
                    </span>
                  </Button>
                </span>
                <div className="col-xs-12 col-sm-12 col-lg-6 col-md-6 " style={{ padding: 0 }}  >
                  <p style={{ color: "#939399", font: " normal 14px arial" }}>Last Date</p>
                  <DatePickerComponent
                    style={{ width: "100%" }}
                    id="teamMemberLastDate"
                    name="teamMemberLastDateErr"
                    data-msg-containerid="teamMemberLastDateErr"
                    format="dd-MMM-yyyy"
                    value={state.lastDate}
                    inputProps={{
                      required: true,
                    }}

                    enabled={state.self === true || state.selffreeze === true}
                    // floatLabelType="Auto"
                    cssClass="e-outline matrix-datepiker"
                    firstDayOfWeek={1}
                    placeholder="Select"
                    change={(e) => inputChangeHandler(e, "lastDate")}

                  />

                  <div
                    id="teamMemberLastDateErr"
                    className="e-input-group e-float-icon-left error-div"
                  />
                </div>
                {/* <div> */}
              </div>
            </div>
            {/* ========================= */}
            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 atc-switch-heading " style={{ color: "#939399", font: " bold 14px arial", display: "flex", flexDirection: "column", padding: "0" }}>
              <div className="col-xs-12 col-sm-8 col-lg-8 col-md-8  atc-switch-heading " style={{ color: "#939399", font: " bold 14px arial", padding: "0" }}>Supervisor Rating</div>
              <div className="reset-last">
                <span className={(state.self || getIfDisabled('supReset')) ? "btn-disabled-cursor" : ""}>
                  <Button
                    style={{ width: "90px", font: "  14px arial", color: getIfDisabled('supReset') ? '#9494B6' : '#000000' }}
                    className={
                      state.supervisor ? "remove-button-shadow reset-matrix-btn active-bg"
                        : "remove-button-shadow reset-matrix-btn deactive-bg"}
                    variant="contained"
                    onClick={(e) => inputChangeHandler(e, "supervisor")}
                    disabled={state.self || getIfDisabled('supReset')}
                  >
                    <span style={{ color: getIfDisabled('supReset') ? '#9494B6' : '' }}>
                      Reset
                    </span>
                  </Button>
                </span>
                <span className={(state.selffreeze || getIfDisabled('supUnfreeze')) ? "btn-disabled-cursor" : ""}>
                  <Button
                    style={{ width: "90px", font: "  14px arial", color: getIfDisabled('supUnfreeze') ? '#9494B6' : '#000000' }}
                    className={
                      state.supfreeze ? "remove-button-shadow reset-matrix-btn active-bg"
                        : "remove-button-shadow reset-matrix-btn deactive-bg"}
                    variant="contained"
                    onClick={(e) => inputChangeHandler(e, "supfreeze")}
                    disabled={state.selffreeze || getIfDisabled('supUnfreeze')}
                  >
                    <span style={{ color: getIfDisabled('supUnfreeze') ? '#9494B6' : '' }}>
                      Unfreeze
                    </span>
                  </Button>
                </span>

                <div className="col-xs-12 col-sm-12 col-lg-6 col-md-6 " style={{ padding: 0 }}>
                  <p style={{ color: "#939399", font: " normal 14px arial" }}>Last Date</p>
                  <DatePickerComponent
                    style={{ width: "100%" }}
                    id="teamMemberLastDateSup"
                    name="teamMemberLastDateSupErr"
                    data-msg-containerid="teamMemberLastDateSupErr"
                    format="dd-MMM-yyyy"
                    value={state.lastDateSup}
                    inputProps={{
                      required: true,
                    }}
                    enabled={state.supfreeze === true || state.supervisor}
                    // floatLabelType="Auto"
                    cssClass="e-outline matrix-datepiker"
                    firstDayOfWeek={1}
                    placeholder="Select"
                    change={(e) =>
                      inputChangeHandler(e, "lastDateSup")}

                  />

                  <div
                    id="teamMemberLastDateSupErr"
                    className="e-input-group e-float-icon-left error-div"
                  />

                </div>

              </div>
            </div>
            {/* ========================= */}
            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12  atc-switch-heading " style={{ color: "#939399", font: " bold 14px arial", display: "flex", flexDirection: "column", padding: "0" }}>

              <div className="col-xs-12 col-sm-8 col-lg-8 col-md-8  atc-switch-heading " style={{ color: "#939399", font: " bold 14px arial", padding: "0" }}>Final Rating</div>
              <div className="reset-last">
                <span className={(state.self || state.supervisor || getIfDisabled('finalReset')) ? "btn-disabled-cursor" : ""}>
                  <Button
                    style={{ width: "90px", color: getIfDisabled('finalReset') ? '#9494B6' : "#000000", font: "  14px arial" }}
                    className={
                      state.final ? "remove-button-shadow reset-matrix-btn active-bg"
                        : "remove-button-shadow reset-matrix-btn deactive-bg"}
                    variant="contained"
                    onClick={(e) => inputChangeHandler(e, "final")}
                    disabled={state.self || state.supervisor || getIfDisabled('finalReset')}
                  >
                    <span style={{ color: getIfDisabled('finalReset') ? '#9494B6' : '' }}>
                      Reset
                    </span>
                  </Button>
                </span>
                <span className={(state.selffreeze || state.supfreeze || getIfDisabled('finalUnfreeze')) ? "btn-disabled-cursor" : ""}>
                  <Button
                    style={{ width: "90px", font: "14px arial", color: getIfDisabled('finalUnfreeze') ? '#9494B6' : '#000000' }}
                    className={
                      state.finalfreeze ? "remove-button-shadow reset-matrix-btn active-bg"
                        : "remove-button-shadow reset-matrix-btn deactive-bg"}
                    variant="contained"
                    onClick={(e) => inputChangeHandler(e, "finalfreeze")}
                    disabled={state.selffreeze || state.supfreeze || getIfDisabled('finalUnfreeze')}
                  >
                    <span style={{ color: getIfDisabled('finalUnfreeze') ? '#9494B6' : '' }}>
                      Unfreeze
                    </span>
                  </Button>
                </span>
                <div className="col-xs-12 col-sm-12 col-lg-6 col-md-6 " style={{ padding: 0 }}>
                  <p style={{ color: "#939399", font: " normal 14px arial" }}>Last Date</p>
                  <DatePickerComponent
                    style={{ width: "100%" }}
                    id="teamMemberLastDateFinal"
                    name="teamMemberLastDateFinalErr"
                    data-msg-containerid="teamMemberLastDateFinalErr"
                    format="dd-MMM-yyyy"
                    value={state.lastDateFinal}
                    inputProps={{
                      required: true,
                    }}
                    enabled={state.finalfreeze === true || state.final}
                    // floatLabelType="Auto"
                    cssClass="e-outline matrix-datepiker"
                    firstDayOfWeek={1}
                    placeholder="Select"
                    change={(e) =>
                      inputChangeHandler(e, "lastDateFinal")}
                  />
                  <div
                    id="teamMemberLastDateFinalErr"
                    className="e-input-group e-float-icon-left error-div"
                  />
                </div>

              </div>

            </div>
          </div>

        </div>
        {loading && <ProgressBar />}
      </div>
    </>
  );
};


const mapStateToProps = ({ auth, meetingmaster, adminreducer, okrreducer }) => {
  const { authUser } = auth;
  const { currentFinancialYearId, finacialYearData, othersCheckAvailablity } = meetingmaster;
  const { fyId } = adminreducer;
  const { okrMasterFlag, loading } = okrreducer;

  return {
    authUser,
    currentFinancialYearId,
    othersCheckAvailablity,
    finacialYearData,
    fyId,
    okrMasterFlag,
    loading
  };
};
export default connect(mapStateToProps, {
  adminAddEditCalculation,
  updateOKRData
})(TeamMemberMatrix);
