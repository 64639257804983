/**
 * Author: Keval Charla.
 * Created:11-July-2022
 * @description to view all list of holidays for user
 */

import React, { useRef, useEffect, useState, Fragment } from 'react';
import { connect } from 'react-redux';
import { ScheduleComponent, Month, Inject, ViewsDirective, ViewDirective } from '@syncfusion/ej2-react-schedule';
import { createElement } from '@syncfusion/ej2-base';
import DateFnsUtils from '@date-io/date-fns';
import LnAServices from '../../services/lna/lnaservice';
import CountryDropdownMenu from './countrydropdownmenu';
import ProgressBar from "../../components/progressbar/progressbar";
import "./holidaylist.css";
import { TIBDateFormat } from '../../app/other/commonfunction';
import { updateLnAData } from '../../redux/actions/lna/lnaaction';
import { urlConfig } from '../../config/config';

const styleSheet = `
  #holidayListCalendar .e-schedule-toolbar-container .e-schedule-toolbar.e-control.e-toolbar.e-lib.e-toolpop.e-keyboard .e-toolbar-items.e-tbar-pos .e-toolbar-left .e-toolbar-item.e-date-range.e-overflow-show .e-tbar-btn.e-tbtn-txt.e-control.e-btn.e-lib span:first-child {
    font-size: 16px;
    font-weight: 500;
    color: #707070;
  }

  #holidayListCalendar .e-schedule-toolbar-container .e-schedule-toolbar.e-control.e-toolbar.e-lib.e-toolpop.e-keyboard .e-toolbar-items.e-tbar-pos .e-toolbar-right .e-toolbar-item.e-today.e-overflow-show .e-tbar-btn.e-tbtn-txt.e-control.e-btn.e-lib .e-tbar-btn-text {
    font-size: 15px;
    font-weight: 500;
    color: #707070;
  }

  #holidayListCalendar .e-schedule-toolbar-container .e-schedule-toolbar.e-control.e-toolbar.e-lib.e-toolpop.e-keyboard .e-toolbar-items.e-tbar-pos .e-toolbar-left .e-toolbar-item.e-template .e-input-group.e-control-wrapper.e-ddl.e-lib.e-keyboard.e-valid-input {
    margin: 0 0 0 1rem !important;
  }
  
  #holidayListCalendar .e-schedule-toolbar-container .e-schedule-toolbar.e-control.e-toolbar.e-lib.e-toolpop.e-keyboard .e-toolbar-items.e-tbar-pos .e-toolbar-left .e-toolbar-item.e-template .e-input-group.e-control-wrapper.e-ddl.e-valid-input {
    margin-top: .4rem !important;
  }

  #holidayListCalendar .e-schedule-toolbar-container .e-schedule-toolbar.e-control.e-toolbar.e-lib.e-toolpop.e-keyboard .e-toolbar-items.e-tbar-pos .e-toolbar-left .e-toolbar-item.e-template .e-input-group.e-control-wrapper.e-ddl.e-valid-input .e-input-group-icon.e-ddl-icon.e-search-icon {
    font-size: 10px !important;
    margin-bottom: 6px !important;
  }

  #holidayListCalendar .e-table-container .e-table-wrap.e-month-view.e-current-panel .e-schedule-table.e-outer-table tbody tr:first-child td .e-date-header-container .e-date-header-wrap .e-schedule-table tbody tr .e-header-cells {
    border-width: 0 0 1px 0;
    padding-top: 1rem;
  }

  #holidayListCalendar .e-table-container .e-table-wrap.e-month-view.e-current-panel .e-schedule-table.e-outer-table tbody tr:first-child td .e-date-header-container .e-date-header-wrap .e-schedule-table tbody tr .e-header-cells span {
    font-size: 15px;
    font-weight: 500;
    color: #707070;
  }

  #holidayListCalendar .e-table-container .e-table-wrap.e-month-view.e-current-panel .e-schedule-table.e-outer-table tbody tr:last-child .e-content-wrap .e-schedule-table.e-content-table tbody tr .e-work-cells {
    border-width: 0 0 1px 0;
  }

  #holidayListCalendar .e-table-container .e-table-wrap.e-month-view.e-current-panel .e-schedule-table.e-outer-table tbody tr:last-child .e-content-wrap .e-schedule-table.e-content-table tbody tr .e-work-cells .e-date-header.e-navigate {
    font-size: 15px;
    font-weight: 500;
    color: #949494;
  }

  #holidayListCalendar .e-table-container .e-table-wrap.e-month-view.e-current-panel .e-schedule-table.e-outer-table tbody tr:last-child .e-content-wrap .e-schedule-table.e-content-table tbody tr .e-current-date .e-date-header.e-navigate {
    color: #fff !important;
  }
`

const HolidayCalendar = (props) => {
  const [navigateDate, setNavigateDate] = useState(null);
  const [isOneTwentyFiveScale, setIsOneTwentyFiveScale] = useState(false);
  const [hight, setHight] = useState({
    height: null,
    windowAdjust: null
  });
  const holidayCalendar = useRef();

  /**
   * Author: Keval Charla  *
   * @param {*} type
   * @param {*} authUser
   * @param {*} selectedCountry
   * @param {*} year
   * @param {*} monthYear
   * @description get the holiday list data on month year change
   */

  useEffect(() => {
    props.handleLoader("true");
    const getHolidays = () => {
      setTimeout(async () => {
        const dateFns = new DateFnsUtils();
        let monthYear = "";
        let year = "";

        if (holidayCalendar.current) {
          monthYear = dateFns.format(holidayCalendar.current.selectedDate, 'yyyy-MM');
          year = dateFns.format(holidayCalendar.current.selectedDate, 'yyyy');
        }

        props.handleSelectedYear(year);
        props.handleSelectedMonthYear(monthYear);

        const monthYearDate = `${monthYear}-01`

        const holidayListMaster = await LnAServices.getHolidayNWorkingSatDetails("allList", props.authUser, props.selectedCountry, 0, `${year}-04`);
        props.getViewData(holidayListMaster, "month", monthYearDate);
        props.handleDisableFilter("no");
        fetchCalendarData();
        props.handleLoader("false");
      }, 500);
    };

    getHolidays();
  }, [props.selectedCountry]);

  /**
   * Author: Keval Charla  *
   * @description resize dimension
   */

  useEffect(() => {
    setIsOneTwentyFiveScale(window.innerHeight < 750);
    window.addEventListener("resize", updateDimensions()); // NOSONAR
    setNavigateDate(new Date());
    props.setClick(refreshCalendar);
  }, [])

  const updateDimensions = () => {
    if (window.innerHeight < 650) {
      const updateheight = window.innerHeight - 25;
      const windowAdjusts = window.innerHeight - 150;
      setHight({
        height: updateheight,
        windowAdjust: windowAdjusts,
      });
    } else {
      const updateheight = window.innerHeight - 25;
      const windowAdjusts = window.innerHeight - 160;
      setHight({
        height: updateheight,
        windowAdjust: windowAdjusts,
      });
    }
  }

  useEffect(() => {
    if (holidayCalendar.current) {
      let currentView = holidayCalendar.current.currentView;
      if (currentView === "Month") {
        props.holidayListData.forEach((holiday) => {
          if (holiday.id === props.selectedItem) {
            setNavigateDate(holiday.holidayDate);
          }
        })
      }
    }
  }, [props.selectedItem]);

  const refreshCalendar = () => {
    props.handleLoader("true");
    setTimeout(() => {
      if (holidayCalendar.current) {
        holidayCalendar.current.refreshTemplates();
      }
    }, 2000);

    setTimeout(() => {
      props.handleLoader("false");
    }, 3000);
  }

  /**
   * Author: Keval Charla  *
   * @param {*} args - syncfusion args data
   * @description inject country dropdown in scheduler toolbar
   */

  const onActionBegin = (args) => {
    if (args.requestType === "toolbarItemRendering") {
      let userIconItem = { template: document.querySelector('#holidayListCountries') };
      args.items.push(userIconItem);
    }
  }

  /**
  * Author: Keval Charla  *
  * @param {*} type
  * @param {*} authUser
  * @param {*} selectedCountry
  * @param {*} year
  * @param {*} monthYear
  * @description get the holiday list data on month year change
  */

  const onActionComplete = (args) => {
    if (args.requestType === "dateNavigate") {
      fetchCalendarData()
    }
  }

  const fetchCalendarData = () => {
    props.handleLoader("true");
    const dateFns = new DateFnsUtils();
    const monthYear = dateFns.format(holidayCalendar.current.selectedDate, 'yyyy-MM');
    const year = dateFns.format(holidayCalendar.current.selectedDate, 'yyyy');

    props.handleSelectedYear(year);
    props.handleSelectedMonthYear(monthYear);

    const monthYearDate = `${monthYear}-01`
    setTimeout(async () => {
      props.getViewData(null, "month", monthYearDate);
      props.handleDisableFilter("no");
      props.handleLoader("true");
    }, 1500);

    setTimeout(() => {
      if (holidayCalendar.current) {
        holidayCalendar.current.refreshTemplates();
        props.handleLoader("false");
      }
    }, 1800);
  }

  /**
   * Author: Keval Charla  *
   * @param {*} countryId
   * @description handle country change
   */

  const handleCountryChange = (countryId) => {
    props.handleCountryChange(countryId);
    props.handleLoader("true");
    setTimeout(() => { // NOSONAR
      props.handleLoader("false");
      if (holidayCalendar.current)
        holidayCalendar.current.refreshTemplates();
    }, 2000);
  }

  /**
   * Author: Keval Charla  *
   * @param {*} args - syncfusion args data
   * @description style calendar dates in month view
   */
  const onRenderCell = (args) => { // NOSONAR
    if (args.elementType == 'workCells' || args.elementType == 'monthCells') {
      if (props.holidayListData.length > 0) {
        props.handleLoader("true");
        let runOnce = 0;
        while (runOnce < 1) {
          const dateFns = new DateFnsUtils();
          let argsDate = dateFns.format(args.date, 'yyyy-MM-dd');
          let navDate = dateFns.format(new Date(navigateDate), 'yyyy-MM-dd');
          let monthWeeks;

          if (holidayCalendar.current) {
            let date = new Date(holidayCalendar.current.selectedDate);
            var firstDay = TIBDateFormat(new Date(date.getFullYear(), date.getMonth(), 1))// NOSONAR
            var lastDay = TIBDateFormat(new Date(date.getFullYear(), date.getMonth() + 1, 0)) // NOSONAR
            let lastDayDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
            monthWeeks = weekCount(lastDayDate);
          }

          props.holidayListData.forEach((holiday) => {
            let holidayDate = holiday.holidayDate
            let holidayDateInHours = holidayDate;


            if (holiday.holidayType === "Fixed Holiday" && argsDate === holidayDate) {
              if (holidayDateInHours >= firstDay && holidayDateInHours <= lastDay) {
                let ele; //NOSONAR
                let userImage = urlConfig.lnaApiUrl + 'holiday/' + holiday.image;

                if (window.innerHeight < 650) {
                  ele = createElement('div', {
                    innerHTML: `<img class="holidayIconImgTemplate fixedHolidayIconImgTemplate" src='${userImage}' width='18' height='20' /> <div class="fixedHolidayTextTemplate" title="${holiday.holidayName}">${holiday.holidayName.substring(0, holiday.holidayName.length).substr(0, 12)}${holiday.holidayName.length > 12 ? "..." : ""}</div>`,
                    className: "fixedHolidayIconTemplate"
                  });
                } else if (isOneTwentyFiveScale) {
                  if (monthWeeks === 6) {
                    ele = createElement('div', {
                      innerHTML: `<img class="holidayIconImgTemplate fixedHolidayIconImgTemplate" src='${userImage}' width='20' height='25' /> <div class="fixedHoliday125ScaleTextTemplate" title="${holiday.holidayName}">${holiday.holidayName.substring(0, holiday.holidayName.length).substr(0, 16)}${holiday.holidayName.length > 16 ? "..." : ""}</div>`,
                      className: "fixedHolidayIconTemplate"
                    });
                  } else {
                    ele = createElement('div', {
                      innerHTML: `<img class="holidayIconImgTemplate fixedHolidayIconImgTemplate" src='${userImage}' width='25' height='30' /> <div class="fixedHoliday125ScaleTextTemplate" title="${holiday.holidayName}">${holiday.holidayName.substring(0, holiday.holidayName.length).substr(0, 16)} <br /> ${holiday.holidayName.substring(0, holiday.holidayName.length).substr(16, 19)}${holiday.holidayName.length > 20 ? "..." : ""}</div>`,
                      className: "fixedHolidayIconTemplate"
                    });
                  }
                } else {
                  ele = createElement('div', {
                    innerHTML: `<img class="holidayIconImgTemplate fixedHolidayIconImgTemplate" src='${userImage}' width='40' height='45' /> <div class="fixedHolidayTextTemplate" title="${holiday.holidayName}">${holiday.holidayName.substring(0, holiday.holidayName.length).substr(0, 20)} <br /> ${holiday.holidayName.substring(0, holiday.holidayName.length).substr(20, 26)}${holiday.holidayName.length > 46 ? "..." : ""}</div>`,
                    className: "fixedHolidayIconTemplate"
                  });
                }
                (args.element).appendChild(ele);
                (args.element).classList.add("fixedHolidayTemplate");
                (args.element).lastChild.style.padding = `${holiday.holidayName.length > 18 ? "0 0 0 .3rem" : "1.2rem 0 0 .3rem"}`
              }
            }

            if (holiday.holidayType === "Working Saturday" && argsDate === holidayDate) {
              if (holidayDateInHours >= firstDay && holidayDateInHours <= lastDay) {
                (args.element).classList.add("workingSaturdayTemplate");
              }
            }

            if (holiday.holidayType === "Allocated Festival Holiday" && argsDate === holidayDate) {
              if (holidayDateInHours >= firstDay && holidayDateInHours <= lastDay) {
                var ele; // NOSONAR
                let userImage = urlConfig.lnaApiUrl + 'holiday/' + holiday.image;

                if (window.innerHeight < 650) {
                  ele = createElement('div', {
                    innerHTML: `<img class="holidayIconImgTemplate festivalHolidayIconImgTemplate" src='${userImage}' width='18' height='20' /> <div class="fixedHolidayTextTemplate" title="${holiday.holidayName}">${holiday.holidayName.substring(0, holiday.holidayName.length).substr(0, 12)}${holiday.holidayName.length > 12 ? "..." : ""}</div>`,
                    className: "festivalHolidayIconTemplate"
                  });
                } else if (isOneTwentyFiveScale) {
                  if (monthWeeks === 6) {
                    ele = createElement('div', {
                      innerHTML: `<img class="holidayIconImgTemplate festivalHolidayIconImgTemplate" src='${userImage}' width='20' height='25' /> <div class="fixedHoliday125ScaleTextTemplate" title="${holiday.holidayName}">${holiday.holidayName.substring(0, holiday.holidayName.length).substr(0, 16)}${holiday.holidayName.length > 16 ? "..." : ""}</div>`,
                      className: "festivalHolidayIconTemplate"
                    });
                  } else {
                    ele = createElement('div', {
                      innerHTML: `<img class="holidayIconImgTemplate festivalHolidayIconImgTemplate" src='${userImage}' width='25' height='30' /> <div class="fixedHoliday125ScaleTextTemplate" title="${holiday.holidayName}">${holiday.holidayName.substring(0, holiday.holidayName.length).substr(0, 16)} <br /> ${holiday.holidayName.substring(0, holiday.holidayName.length).substr(16, 19)}${holiday.holidayName.length > 20 ? "..." : ""}</div>`,
                      className: "festivalHolidayIconTemplate"
                    });
                  }
                } else {
                  ele = createElement('div', {
                    innerHTML: `<img class="holidayIconImgTemplate festivalHolidayIconImgTemplate" src='${userImage}' width='40' height='45' /> <div class="fixedHolidayTextTemplate" title="${holiday.holidayName}">${holiday.holidayName.substring(0, holiday.holidayName.length).substr(0, 20)} <br /> ${holiday.holidayName.substring(0, holiday.holidayName.length).substr(20, 26)}${holiday.holidayName.length > 46 ? "..." : ""}</div>`,
                    className: "festivalHolidayIconTemplate"
                  });
                }
                (args.element).appendChild(ele);
                (args.element).classList.add("festivalHolidayTemplate");
              }
            }

            if (holiday.holidayType === "Availed Festival Holiday" && argsDate === holidayDate) {
              if (holidayDateInHours >= firstDay && holidayDateInHours <= lastDay) {
                var ele; // NOSONAR

                let userImage = urlConfig.lnaApiUrl + 'holiday/' + holiday.image;
                if (window.innerHeight < 650) {
                  ele = createElement('div', {
                    innerHTML: `<img class="holidayIconImgTemplate festivalHolidayIconImgTemplate" src='${userImage}' width='18' height='20' /> <div class="fixedHolidayTextTemplate" title="${holiday.holidayName}">${holiday.holidayName.substring(0, holiday.holidayName.length).substr(0, 12)}${holiday.holidayName.length > 12 ? "..." : ""}</div>`,
                    className: "festivalHolidayIconTemplate"
                  });
                } else if (isOneTwentyFiveScale) {
                  if (monthWeeks === 6) {
                    ele = createElement('div', {
                      innerHTML: `<img class="holidayIconImgTemplate festivalHolidayIconImgTemplate" src='${userImage}' width='20' height='25' /> <div class="fixedHoliday125ScaleTextTemplate" title="${holiday.holidayName}">${holiday.holidayName.substring(0, holiday.holidayName.length).substr(0, 16)}${holiday.holidayName.length > 16 ? "..." : ""}</div>`,
                      className: "festivalHolidayIconTemplate"
                    });
                  } else {
                    ele = createElement('div', {
                      innerHTML: `<img class="holidayIconImgTemplate festivalHolidayIconImgTemplate" src='${userImage}' width='25' height='30' /> <div class="fixedHoliday125ScaleTextTemplate" title="${holiday.holidayName}">${holiday.holidayName.substring(0, holiday.holidayName.length).substr(0, 16)} <br /> ${holiday.holidayName.substring(0, holiday.holidayName.length).substr(16, 19)}${holiday.holidayName.length > 20 ? "..." : ""}</div>`,
                      className: "festivalHolidayIconTemplate"
                    });
                  }
                } else {
                  ele = createElement('div', {
                    innerHTML: `<img class="holidayIconImgTemplate festivalHolidayIconImgTemplate" src='${userImage}' width='40' height='45' /> <div class="fixedHolidayTextTemplate" title="${holiday.holidayName}">${holiday.holidayName.substring(0, holiday.holidayName.length).substr(0, 20)} <br /> ${holiday.holidayName.substring(0, holiday.holidayName.length).substr(20, 26)}${holiday.holidayName.length > 46 ? "..." : ""}</div>`,
                    className: "festivalHolidayIconTemplate"
                  });
                }
                (args.element).appendChild(ele);

                (args.element).classList.add("festivalHolidayTakenTemplate");
              }
            }

            if (argsDate === navDate) {
              (args.element).classList.add("selectedHolidayTemplate");
            }

            if ((args.element).classList.contains("fixedHolidayTemplate") && (args.element).classList.contains("workingSaturdayTemplate")) {
              (args.element).classList.remove("workingSaturdayTemplate");
            }

            if ((args.element).classList.contains("festivalHolidayTakenTemplate") && (args.element).classList.contains("workingSaturdayTemplate")) {
              (args.element).classList.remove("festivalHolidayTakenTemplate");
            }

            if ((args.element).classList.contains("festivalHolidayTemplate") && (args.element).classList.contains("workingSaturdayTemplate")) {
              (args.element).classList.remove("workingSaturdayTemplate");
            }

          })
          runOnce++
          props.handleLoader("false");
        }
      }
    }
  }

  const weekCount = (date) => {
    const startWeekDayIndex = 1; // 1 MonthDay 0 Sundays
    const firstDate = new Date(date.getFullYear(), date.getMonth(), 1);
    const firstDay = firstDate.getDay();

    let weekNumber = Math.ceil((date.getDate() + firstDay) / 7);
    if (startWeekDayIndex === 1) {
      if (date.getDay() === 0 && date.getDate() > 1) {
        weekNumber -= 1;
      }

      if (firstDate.getDate() === 1 && firstDay === 0 && date.getDate() > 1) {
        weekNumber += 1;
      }
    }
    return weekNumber;
  }

  useEffect(() => {
    if (props.leaveApplyStatus === 'updated') {
      fetchCalendarData()
    }
  }, [props.leaveApplyStatus])
  useEffect(() => {
    if (props.leaveCancelStatus === 'updated') {
      fetchCalendarData()
    }
  }, [props.leaveCancelStatus])

  return (
    <>
      <style>{styleSheet}</style>
      <Fragment key={props.holidayCalendarKey}>
        <ScheduleComponent
          id="holidayListCalendar"
          cssClass="year-view"
          width='100%'
          height={hight.windowAdjust}
          ref={holidayCalendar}
          // selectedDate={navigateDate}
          /** eventSettings={{ dataSource: this.data }} */
          minDate={new Date(props.minDate)}
          maxDate={new Date(props.maxDate)}
          showNextPrevMonth={false}
          renderCell={onRenderCell}
          actionBegin={onActionBegin}
          actionComplete={onActionComplete}
          showWeekNumber={true}
          weekRule="FirstFourDayWeek"
          firstDayOfWeek={1}
        >
          <ViewsDirective>
            <ViewDirective option='Month' readonly={true} />
          </ViewsDirective>
          <Inject services={[Month]} />
        </ScheduleComponent>
        {props.loader && <ProgressBar />}
      </Fragment>
      <CountryDropdownMenu handleCountryChange={handleCountryChange} selectedCountry={props.selectedCountry} />
    </>
  )
};

const mapStateToProps = ({ lnareducer }) => {

  const { leaveApplyStatus, leaveCancelStatus } = lnareducer;
  return {
    leaveApplyStatus, leaveCancelStatus
  };
};

export default connect(mapStateToProps, {
  updateLnAData
})(HolidayCalendar);
