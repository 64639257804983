// Author by:- Prashant Waphare | 31-08-2020 | reducer for all home office.

import {
  SAVE_BASIC_HOME_DATA,
  ADD_EDIT_HOME_OFFICE_DETAILS,
  SAVE_HOME_OFFICE_DETAILS,
} from "../../constant/homeoffice/homeofficeconstant";

const INIT_STATE = {
  address1: "",
  address2: "",
  address3: "",
  address4: "",
  city: "",
  district: "",
  state: "",
  selectedcountry: 0,
  pincode: "",
  personalEmailId: "",
  name: "",
  emrgencyEmailId: "",
  contactNumber: "",
  companyMobileNumber: "",
  personalMobileNumber: "",
  secondaryMobileNumber: "",
  homeAddress: "0",
  rentAddress: null,
  planProvider1: "",
  brodbandSpeed1: "",
  planProvider2: "",
  brodbandSpeed2: "",
  filepath1: "",
  filepath2: "",
  preRequisitConfirmation: false,
  deskSpaceDualScreenSetUp: null,
  spaceIsolatedDistraction: null,
  plainBackgroundForVideoCall: null,
  uninterruptedElectricitySupply: null,
  homeOfficeBackup: null,
  ups: null,
  invertor: null,
  upsBrand: "",
  upsModel: "",
  upsVACapacity: "",
  upsDuration: 0,
  invertorBrand: "",
  invertorModel: "",
  invertorKVACapacity: "",
  invertorDuration: 0,
  requestForWorkDesk: null,
  requestForHoldingArm: null,
  requestForOfficeChair: null,
  declearation: false,
  masterCountry: [],
  radioButtonFalse: false,
  homeOfficeStatus: "",
  homeOfficeSuccessMessage: "",
  internetConnectivity: "0",
  internetConnectivityState: false,
  internetFlag:false,
  billDate:null,
  billAmount:0,
  billFilePath:"",
  electricityBillFlag:0,
  homeOfficeMode:"",
};

// eslint-disable-next-line
export default (state = INIT_STATE, action) => { //NOSONAR
  switch (action.type) {
    case SAVE_BASIC_HOME_DATA:
       case ADD_EDIT_HOME_OFFICE_DETAILS:
         case  SAVE_HOME_OFFICE_DETAILS :{
      return {
        ...state,
        ...action.payload,
      };
    }
    // case ADD_EDIT_HOME_OFFICE_DETAILS: {
    //   return {
    //     ...state,
    //     ...action.payload,
    //   };
    // }
    // case SAVE_HOME_OFFICE_DETAILS: {
    //   return {
    //     ...state,
    //     ...action.payload,
    //   };
    // }
    default:
      return state;
  }
};
