import React, { useEffect, useState } from 'react';
import MonthlOkrStatus from './monthlyokrstatus';
import GraphView from './graphview';
function SelfView(props) {
  const [state, setState] = useState({
    height: null,
    windowAdjust: null
  });
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => {
      // window.removeEventListener('resize', () => {})
    }
  }, [])

  const updateDimensions = () => {
    const updateheight = window.innerHeight - 244;
    const windowAdjusts = window.innerHeight === 625 ? window.innerHeight - 204 : window.innerHeight - 205;
    setState({
      height: updateheight,
      windowAdjust: windowAdjusts,
    });
  }
  const { showMonths } = props;
  const { height, windowAdjust } = state;
  return (
    <div style={{ overflowY: 'hidden', overflowX: 'hidden' }}>
      <div className='col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12' style={{ height: showMonths ? height : windowAdjust }}>
        <div className='col-5 col-lg-5 col-md-5 col-sm-5 col-xs-5'>
          <GraphView showMonths={showMonths} />
        </div>
        <div className='col-7 col-lg-7 col-md-7 col-sm-7 col-xs-7'>
          <div className='row1'>
            <MonthlOkrStatus showMonths={showMonths} />
          </div>
        </div>
      </div>
    </div>

  )
}

export default SelfView