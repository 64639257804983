
/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  MultiSelectComponent,
  CheckBoxSelection,
  Inject,
  ComboBoxComponent
} from "@syncfusion/ej2-react-dropdowns";
import { getOnboardingMasterData } from '../../../../app/other/commonlayoutfunction';
import { updateCandidateData } from '../../../../redux/actions/entryexit/candidateaction';
import { updateLnAData, saveWeekOffSwap } from '../../../../redux/actions/lna/lnaaction';
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import { VIEWDAILY, VIEWMONTHLY, ATTENDANCEHRREPORT, VIEWYEARLY } from '../../../../redux/constant/meetingcategoryconstant'
import { Query } from '@syncfusion/ej2-data';

/* eslint-disable */
function ReportFilter(props) { // NOSONAR
  const { authUser, getMasterData, teamMemberYearlyDropDown, inputChange, parentState, changeTeamMemberIdsOnReportScreenChange, from, updateDefaultTeamMember, categoryName, selectedTeamMemberId, startMonth, endMonth, startMonthMonthlyReport } = props;
  const { monthId, teamId, businessUnitData, teamMemberData, attendanceList, leaveList, effectiveStartDate, effectiveEndDate,
    attendanceListData, leaveListData, countryOfWork, businessUnit, subBusinessUnit, teamMember, subPnLData, minDate, maxDate } = parentState;

  const [isStartDateSelected, setIsStartDateSelected] = useState(false);
  const [customStyle, setCSS] = useState('')

  const currentYear = new Date().getFullYear();

  const style = `
        @media screen and (max-width: 1440px) and (max-height: 900px) {
            #leavemonthlyreportID .e-float-text-content {
                font-size: 10px !important;
            }
        }
        .e-multi-select-wrapper .e-close-hooker::before {
            left: 17px !important;
        }
    `

  useEffect(() => {
    if (props.viewReport === "1" && from !== 'yearly') {
      inputChange(props.authUser, "teamMemberAuthUser");
    }
    if (props.viewReport === "2" && from !== 'yearly') {
      inputChange([teamId], "teamMemberAllTeamReportOnlyTeamId");
    }
    changeTeamMemberIdsOnReportScreenChange()

  }, [props.viewReport]);
  useEffect(() => {
    getDropdownData();
    getMasterData("lnaReport");
    getMasterData("dropDownData");
    setPlaceholder();
    updateDefaultTeamMember({ teamId: authUser });
    setTimeout(() => {
      props.updateLnAData({
        selectedTeamMemberId: authUser,
        viewClicked: true,
      })
    }, 2000);

  }, []);

  // useEffect(() => {
  //     updateDefaultTeamMember({ teamId: authUser })
  //     setTimeout(() => {
  //         props.updateLnAData({
  //             selectedTeamMemberId: authUser,
  //             viewClicked: true,
  //         })
  //     }, 2000);
  // }, [])

  useEffect(() => {
    // if (from !== 'yearly') {
    let id
    if (categoryName.toString() === VIEWDAILY) {
      id = '1'
    }
    if (categoryName.toString() === VIEWMONTHLY) {
      id = '2'
    }
    if (categoryName.toString() === VIEWYEARLY) {
      id = '3'
    }
    updateDefaultTeamMember({
      teamId: props.authUser,
      teamMemberReportData: [], viewReport: id
    })

    // }
    props.updateLnAData({ selectedTeamMemberId: null, })
  }, [categoryName]);

  // useEffect(() => {
  //     handleHrTeamReport();
  // }, [monthId]);

  const setPlaceholder = () => {
    let fontSize = '';
    if (window.innerHeight === 563) {
      fontSize = '12px';
    }
    if (window.innerHeight === 611) {
      fontSize = '12px';
    }
    if (window.innerHeight === 563 && window.innerWidth === 1152) {
      fontSize = '10px';
    }
    if (window.innerHeight === 563 && window.innerWidth === 1280) {
      fontSize = '12px';
    }
    if (window.innerHeight === 611 && window.innerWidth === 1024) {
      fontSize = '8.5px';
    }
    setCSS(`
        .e-float-text-content {
            font-size: ${fontSize} !important
        }
        `)
  }
  const getDropdownData = async () => {
    const onboardingMasterData = await getOnboardingMasterData(props.authUser, 'All');
    props.updateCandidateData(onboardingMasterData);
  }
  const inputChangeHandler = (e, type) => {
    inputChange(e, type)
  }

  const handleHrTeamReport = () => {
    if (props.viewReport === "1") {
      props.updateLnAData({
        viewClicked: true,
        selectedTeamMemberId: teamId,
        monthId: monthId,
        reloadChart: true
      });
    } else {

      const data = { //NOSONAR
        teamMember: props.viewReport === "1" ? [parseInt(teamId)] : teamMember === undefined ? '' : teamMember.length > 0 ? teamMember.join(",") : props.viewReport === "3" ? '0' : props.authUser /**NOSONAR */,
        monthId: monthId,
        countryOfWork: countryOfWork,
        businessUnit: businessUnit ? businessUnit.toString() : '0',
        subBusinessUnit: subBusinessUnit ? subBusinessUnit.toString() : '0',
        effectiveStartDate: effectiveStartDate ? effectiveStartDate : null,
        effectiveEndDate: effectiveEndDate ? effectiveEndDate : null,
        attendanceList: attendanceList !== undefined && attendanceList.length > 0 ? attendanceList.join(",") : '0',
        leaveList: leaveList !== undefined && leaveList.length > 0 ? leaveList.join(",") : '0',
      }

      props.saveFilterInput(data)
    }

  }

  const handleYearlyReport = () => {
    props.updateLnAData({
      viewClicked: true,
      selectedTeamMember: teamMemberYearlyDropDown.filter((item) => item.Id === teamId)
    })
  }

  function addDays(date, days) {
    let result = new Date(date);
    result.setDate(result.getDate() + days);

    return result;

  }
  const onFiltering = (args, type) => {
    let query = new Query();
    // frame the query based on search string with filter type.
    query = (args.text !== "") ? query.where("Name", "startswith", args.text, true) : query;
    // pass the filter data source, filter query to updateData method.
    const DataSource = (() => {
      switch (type) {
        case "teamMemberAllTeamReport":
          return teamMemberYearlyDropDown;
        case "BaseCountryAllTeamReport":
          return props.countryMaster;
        case "BusinessUnitAllTeamReport":
          return businessUnitData;
        case "SubBusinessUnitAllTeamReport":
          return subPnLData;
        case "teamMemberSingleSelect":
          return teamMemberData;
        default:
          return null; // or some other default value
      }
    })();

    args.updateData(DataSource, query);
  }
  const isThirteenHundred = window.innerHeight < 658;
  const applyBootstrapClass = props.viewReport === "3" ? "col-xs-3 col-sm-3 col-lg-3 col-md-3" : "pl-md"
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = currentDate.getMonth() + 1; // Month starts from 0
  const endOfMonth = new Date(year, month, 0);
  return (
    from === 'yearly' ?
      <>
        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 mb-md" style={{
          backgroundColor: '#F3F3F6', borderTop: '1px solid #DBDBDD',
          borderBottom: '1px solid #DBDBDD'
        }} >
          <div
            className={`col-xs-12 col-sm-12 col-lg-12 col-md-12 pl-md pr-md`}
            style={{
              backgroundColor: '#fff',
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}

          >
            <div className='col-xs-12 col-sm-12 col-lg-12 col-md-12' style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              flex: 1,
            }}>
              <div className='pt-md pb-md col-xs-3 col-sm-3 col-lg-3 col-md-3' style={{ paddingLeft: 0 }}>
                <DatePickerComponent
                  id="effectiveStartDate"
                  name="effectiveStartDateErr"
                  data-msg-containerid="effectiveStartDateErr"
                  value={startMonth && new Date(startMonth)}
                  start={'Year'}
                  depth={'Year'}
                  format={'MMM-yyyy'}
                  floatLabelType="Auto"
                  cssClass="e-outline minimize-height"
                  placeholder="From Month *"
                  // firstDayOfWeek={1}
                  showClearButton={false}
                  min={new Date(minDate)}
                  max={new Date(maxDate)}
                  allowEdit={false}
                  change={(e) => {
                    inputChangeHandler(e, "startMonth");
                    setIsStartDateSelected(true);
                  }}

                />
              </div>
              <div className='pt-md pb-md col-xs-3 col-sm-3 col-lg-3 col-md-3' style={{ paddingLeftt: 0 }}>
                <DatePickerComponent
                  id="effectiveEndDate"
                  fields={{ text: "Name", value: "Id" }}
                  // value={effectiveEndDate}
                  data-msg-containerid="effectiveEndDateErr"
                  name="effectiveEndDateErr"
                  // format="dd-MMM-yyyy"
                  value={endMonth && new Date(endMonth)}
                  start={'Year'}
                  depth={'Year'}
                  format={'MMM-yyyy'}
                  floatLabelType="Auto"
                  cssClass="e-outline minimize-height"
                  placeholder="To Month *"
                  showClearButton={false}
                  min={startMonth ? new Date(startMonth) : new Date(minDate)}
                  max={new Date(maxDate)}
                  allowEdit={false}
                  change={(e) => inputChangeHandler(e, "endMonth")}
                />
              </div>
              <div
                className='pt-md pb-md col-xs-4 col-sm-4 col-lg-3 col-xl-4  col-md-4'>
                <ComboBoxComponent
                  id="teamMemberAllTeamReport"
                  fields={{ text: "Name", value: "Id" }}
                  cssClass="e-outline numberInputFont"
                  data-msg-containerid="teamMemberAllTeamReportErr"
                  name="teamMemberAllTeamReportErr"
                  floatLabelType="Auto"
                  placeholder="Team Member *"
                  style={{ width: "55% !important" }}
                  htmlAttributes={{ maxlength: 100 }}
                  select={(e) => inputChangeHandler(e, "teamMemberSingleSelect")}
                  value={selectedTeamMemberId ? selectedTeamMemberId : authUser}
                  // dataSource={props.applyForData}
                  dataSource={teamMemberYearlyDropDown}
                  filtering={(e) => onFiltering(e, "teamMemberAllTeamReport")}
                  allowFiltering={true}

                />
              </div>

              <div
                className='col-xs-1 col-sm-1 col-lg-1 col-md-1'
                style={{
                  verticalAlign: "middle",
                  textAlign: "left",
                }}>
                <button
                  style={{
                    cursor: props.isLoading ? 'no-drop' : '',
                    background: props.isLoading ? '#DBDBDD' : '',
                    border: props.isLoading ? '1px solid #DBDBDD' : '',
                    color: props.isLoading ? '#B7B7BB' : ''
                  }}
                  className={"redBackgroundButton"}
                  onClick={handleYearlyReport}
                >
                  View
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
      :

      <>
        <div className={props.viewReport === '3' ? "col-xs-12 col-sm-12 col-lg-12 col-md-12 teamReportDropdowns pl-md pr-md pt-0" :
          (props.viewReport === '1' && window.innerWidth === 1024) || (props.viewReport === '1' && window.innerWidth === 1280) ? 'col-xs-8 col-sm-8 col-lg-8 col-md-8' :
            (props.viewReport === '1' && window.innerWidth === 1366) || (props.viewReport === '1' && window.innerWidth === 1440) ||
              (props.viewReport === '1' && window.innerWidth === 1152) ? 'col-xs-6 col-sm-6 col-lg-6 col-md-6' :
              props.viewReport === '1' ? 'col-xs-6 col-sm-6 col-lg-6 col-md-6' :
                'col-xs-12 col-sm-12 col-lg-12 col-md-12 mb-md'} style={{
                  backgroundColor: '#fff',
                  paddingLeft: props.viewReport === '1' ? '0' : '',
                  paddingRight: props.viewReport === '1' ? '0' : '',
                  border: props.viewReport === '1' ? '0.5px solid #DBDBDD' : ''
                }} >
          <div
            className={`col-xs-12 col-sm-12 col-lg-12 col-md-12 pl-md pr-md pt-md pb-md`}

            style={{
              backgroundColor: '#fff',
              display: props.viewReport !== "3" ? "flex" : null,
              justifyContent: props.viewReport !== "3" ? "space-between" : null,
              alignItems: props.viewReport !== "3" ? "center" : null,
              borderTop: props.viewReport === 3 || props.viewReport === '1' ? 'none' : '1px solid #DBDBDD',
              borderBottom: props.viewReport === 3 || props.viewReport === '1' ? 'none' : '1px solid #DBDBDD',
              // paddingRight: props.viewReport === '1' ? '0px !important' : '' 
            }}


          >
            <style>{style}</style>
            <div
              className={props.viewReport === "3" ? "col-xs-12 col-sm-12 col-lg-12 col-md-12" : ""}
              id={'leavemonthlyreportID'}
              style={{
                paddingLeft: 0,
                display: props.viewReport !== "3" ? "flex" : null,
                justifyContent: props.viewReport !== "3" ? "flex-start" : null,
                alignItems: props.viewReport !== "3" ? "flex-end" : null,
                width: props.viewReport !== "3" ? "95%" : null,
              }}

            >
              <style>{customStyle}</style>
              { }
              {(props.viewReport === "1" || props.viewReport === "2") && from !== 'yearly' && <div style={{ paddingLeft: 0, flex: props.viewReport === "2" ? "1" : null }}>
                <DatePickerComponent
                  id="Month"
                  name="MonthyErr"
                  data-msg-containerid="MonthErr"
                  value={startMonthMonthlyReport && new Date(startMonthMonthlyReport)}
                  start={'Year'}
                  depth={'Year'}
                  format={'MMM-yyyy'}
                  floatLabelType="Auto"
                  cssClass="e-outline minimize-height"
                  placeholder="Month*"
                  showClearButton={false}
                  allowEdit={false}
                  min={new Date(minDate)}
                  max={new Date(maxDate)}
                  change={(e) => inputChangeHandler(e, 'startMonthMonthlyReport')}

                />
              </div>}
              {(props.viewReport === "3" || props.viewReport === "2") && <div className={applyBootstrapClass} style={{ flex: props.viewReport === "2" ? "1" : null, paddingLeft: props.viewReport === "3" ? 0 : null }}>
                {props.officeCountry.length > 0 && <ComboBoxComponent
                  id="BaseCountryAllTeamReport"
                  dataSource={props.officeCountry}
                  fields={{ text: "Name", value: "Id" }}
                  value={countryOfWork}
                  data-msg-containerid="basecountryAllTeamReportErr"
                  name="basecountryAllTeamReportErr"
                  // allowFiltering={true}
                  floatLabelType="Auto"
                  cssClass="e-outline"
                  placeholder="Country of Work"
                  change={(e) => inputChangeHandler(e, "BaseCountryAllTeamReport")}
                  width={props.viewReport === "2" ? "94%" : "100%"}
                  filtering={(e) => onFiltering(e, "BaseCountryAllTeamReport")}
                  allowFiltering={true}
                />}

              </div>}
              {(from !== 'yearly' && (props.viewReport === "3" || props.viewReport === "2")) && <div className={props.viewReport !== "2" && applyBootstrapClass} style={{ flex: props.viewReport === "2" ? "1" : null, paddingLeft: props.viewReport === "2" ? "0px" : "15px" }}>
                {/* <TooltipComponent title={'Business Unit / Group Function'}> */}
                <ComboBoxComponent
                  id="BusinessUnitAllTeamReport"
                  dataSource={businessUnitData}
                  fields={{ text: "Name", value: "Id" }}
                  value={businessUnit}
                  data-msg-containerid="businessUnitAllTeamReportErr"
                  name="businessUnitAllTeamReportErr"
                  // allowFiltering={true}
                  floatLabelType="Auto"
                  cssClass="e-outline"
                  placeholder={window.innerWidth < 1200 ? "Business Unit / Grou..." : 'Business Unit / Group Function'}
                  showClearButton={true}
                  change={(e) => inputChangeHandler(e, "BusinessUnitAllTeamReport")}
                  width={props.viewReport === "2" ? "110%" : "100%"}
                  filtering={(e) => onFiltering(e, "BusinessUnitAllTeamReport")}
                  allowFiltering={true}
                />
              </div>}
              {(props.viewReport === "3" || props.viewReport === "2") && <div className={props.viewReport !== "2" && applyBootstrapClass} style={{ flex: props.viewReport === "2" ? "1" : null, paddingLeft: props.viewReport === "2" ? window.innerWidth >= 1450 ? "45px" : "35px" : "15px" }} >
                <ComboBoxComponent
                  id="SubBusinessUnitAllTeamReport"
                  dataSource={subPnLData}
                  fields={{ text: "Name", value: "Id" }}
                  value={subBusinessUnit}
                  data-msg-containerid="subBusinessUnitAllTeamReportErr"
                  name="subBusinessUnitAllTeamReportErr"
                  // allowFiltering={true}
                  floatLabelType="Auto"
                  cssClass="e-outline"
                  placeholder={window.innerWidth < 1200 ? "Sub Business Unit / Fun..." : "Sub Business Unit / Function"}
                  showClearButton={true}
                  change={(e) => inputChangeHandler(e, "SubBusinessUnitAllTeamReport")}
                  width={props.viewReport === "2" ? "104%" : "100%"}
                  filtering={(e) => onFiltering(e, "SubBusinessUnitAllTeamReport")}
                  allowFiltering={true}
                />
              </div>}
              {(props.viewReport === "2" || props.viewReport === "3") && teamMemberData !== undefined && teamMemberData.length > 0 && <div
                className={props.viewReport !== "2" && applyBootstrapClass}
                style={{
                  paddingLeft: props.viewReport !== "3" ? "1.5rem" : null,
                  paddingRight: props.viewReport === "3" ? 0 : null,
                  flex: props.viewReport === "2" ? "1" : null,
                }}>
                <MultiSelectComponent
                  id="teamMemberAllTeamReport"
                  name="teamMemberAllTeamReportErr"
                  dataSource={teamMemberData && teamMemberData.length > 0 ? teamMemberData : []}
                  fields={{ text: "Name", value: "Id" }}
                  placeholder="Team Member"
                  cssClass="e-outline ev_dropdown_report"
                  floatLabelType="Auto"
                  selectAllText='All'
                  data-msg-containerid="teamMemberAllTeamReportErr"
                  value={teamMember}
                  showDropDownIcon={true}
                  showSelectAll={true}
                  mode="CheckBox"
                  popupWidth={400}
                  allowFiltering={true}
                  change={(e) => inputChangeHandler(e, "teamMemberAllTeamReport")}
                  width={"100%"}
                >
                  <Inject services={[CheckBoxSelection]} />
                </MultiSelectComponent>
              </div>}
              {(categoryName === VIEWDAILY && props.viewReport === "1") && <div
                className={`${props.viewReport === "1" ? "col-xs-10 col-sm-10 col-lg-10 col-md-10" : "col-xs-6 col-sm-4 col-lg-3 col-md-5"}  `}
                style={{
                  paddingLeft: props.viewReport !== "3" ? "1.5rem" : null,

                }}>
                <ComboBoxComponent
                  id="teamMemberAllTeamReport"
                  fields={{ text: "Name", value: "Id" }}
                  cssClass="e-outline numberInputFont"
                  data-msg-containerid="teamMemberAllTeamReportErr"
                  name="teamMemberAllTeamReportErr"
                  floatLabelType="Auto"
                  placeholder="Team Member *"
                  htmlAttributes={{ maxlength: 100 }}
                  change={(e) => inputChangeHandler(e, "teamMemberSingleSelect")}
                  value={teamId}
                  // dataSource={props.applyForData}
                  dataSource={teamMemberData}
                  filtering={(e) => onFiltering(e, "teamMemberSingleSelect")}
                  allowFiltering={true}
                />
              </div>}
            </div>
            {props.viewReport === "3" && <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12" style={{ marginTop: 15 }}  >
              <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3" style={{ paddingLeft: 0 }}>
                <DatePickerComponent
                  id="effectiveStartDate"
                  fields={{ text: "Name", value: "Id" }}
                  name="effectiveStartDateErr"
                  data-msg-containerid="effectiveStartDateErr"
                  format="dd-MMM-yyyy"
                  value={effectiveStartDate}
                  floatLabelType="Auto"
                  cssClass="e-outline minimize-height"
                  placeholder="From Date *"
                  // firstDayOfWeek={1}
                  showClearButton={false}
                  allowEdit={false}
                  min={new Date(new Date().getFullYear() - 1, 3, 1)}
                  max={new Date(new Date().getFullYear(), 15, 30)}
                  // change={(e) => inputChangeHandler(e, "effectiveStartDate")}
                  change={(e) => {
                    inputChangeHandler(e, "effectiveStartDate");
                    setIsStartDateSelected(true);
                  }}
                />
              </div>

              <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3" >
                <DatePickerComponent
                  id="effectiveEndDate"
                  fields={{ text: "Name", value: "Id" }}
                  value={effectiveEndDate}
                  data-msg-containerid="effectiveEndDateErr"
                  name="effectiveEndDateErr"
                  format="dd-MMM-yyyy"
                  floatLabelType="Auto"
                  cssClass="e-outline minimize-height"
                  placeholder="To Date *"
                  showClearButton={false}
                  allowEdit={false}
                  min={effectiveStartDate ? addDays(new Date(effectiveStartDate), 0) : new Date(new Date().getFullYear() - 1, 3, 1)}
                  max={new Date(new Date().getFullYear(), 15, 30)}
                  change={(e) => inputChangeHandler(e, "effectiveEndDate")}
                  enabled={isStartDateSelected}
                />
              </div>
              <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3" >
                <MultiSelectComponent
                  id="attendanceType"
                  name="attendanceTypeErr"
                  dataSource={attendanceListData}
                  fields={{ text: "Name", value: "Id" }}
                  placeholder="Attendance Type"
                  cssClass="e-outline"
                  floatLabelType="Auto"
                  showSelectAll={false}
                  data-msg-containerid="attendanceTypeErr"
                  value={attendanceList}
                  showDropDownIcon={true}
                  mode="CheckBox"
                  allowFiltering={true}
                  change={(e) => inputChangeHandler(e, "attendanceType")}
                >
                  <Inject services={[CheckBoxSelection]} />
                </MultiSelectComponent>
              </div>
              <div className="col-xs-3 col-sm-3 col-lg-3 col-md-3" style={{ paddingRight: 0 }}>
                <MultiSelectComponent
                  id="leaveType"
                  name="leaveTypeErr"
                  dataSource={leaveListData}
                  fields={{ text: "Name", value: "Id" }}
                  placeholder="Leave Type"
                  cssClass="e-outline"
                  floatLabelType="Auto"
                  showSelectAll={false}
                  data-msg-containerid="leaveTypeErr"
                  value={leaveList}
                  showDropDownIcon={true}
                  mode="CheckBox"
                  allowFiltering={true}
                  change={(e) => inputChangeHandler(e, "leaveType")}
                >
                  <Inject services={[CheckBoxSelection]} />
                </MultiSelectComponent>
              </div>
            </div>}
            {props.viewReport === "3" && <div style={{ float: "right", marginTop: 15 }}>
              <button
                className={

                  (effectiveStartDate &&
                    effectiveEndDate)

                    ? "redBackgroundButton"
                    : "disableBackgroundButton"
                }
                onClick={handleHrTeamReport}
              >
                Generate Report
              </button>
            </div>}
            {(props.viewReport === "2" || props.viewReport === "1") && <>
              <div className="pl-sm" style={{ paddingRight: 0 }}>
                <div style={{ float: "right" }}>
                  <button
                    style={{
                      padding: isThirteenHundred ? ".25rem 1rem" : ".35rem 1rem",
                      cursor: props.isLoading ? 'no-drop' : '',
                      background: props.isLoading ? '#DBDBDD' : '',
                      border: props.isLoading ? '1px solid #DBDBDD' : '',
                      color: props.isLoading ? '#B7B7BB' : ''
                    }}
                    className={countryOfWork || businessUnit || teamId || subBusinessUnit ? "redBackgroundButton" : ""}

                    disabled={props.isLoading} onClick={handleHrTeamReport}
                  >
                    View
                  </button>
                </div>
              </div>
            </>}
          </div>
        </div>
      </>
  )

}

const mapStateToProps = ({ auth, lnareducer, candidateReducer, meetingmaster }) => {

  const { authUser } = auth;

  const { officeCountry, officeLocation, applyForData, teamMemberYearlyDropDown, viewClicked, selectedTeamMemberId, startMonth, endMonth, startMonthMonthlyReport, isLoading } = lnareducer;

  const { othersCheckAvailablity } = meetingmaster;

  const { entityMaster, countryMaster } = candidateReducer;

  return {
    authUser,
    viewClicked,
    countryMaster,
    officeCountry,
    officeLocation,
    entityMaster,
    othersCheckAvailablity,
    applyForData,
    selectedTeamMemberId, startMonth, endMonth, startMonthMonthlyReport, teamMemberYearlyDropDown, isLoading
  }

}

export default connect(mapStateToProps, { updateCandidateData, updateLnAData, saveWeekOffSwap })(ReportFilter)
