import React, { useState, useEffect } from "react";

import { ComboBoxComponent } from "@syncfusion/ej2-react-dropdowns";
import {
  TextBoxComponent,
  NumericTextBoxComponent,
} from "@syncfusion/ej2-react-inputs";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";
import { SwitchComponent } from "@syncfusion/ej2-react-buttons";
import { connect } from "react-redux";
import { FormValidator } from "@syncfusion/ej2-inputs";
import LnAServices from "../../services/lna/lnaservice";
import UploadDocuments from "../okrratingcomponents/uploaddocuments";
import { createImageFilepathForLPView } from "../okrratingcomponents/ratingcommonfun";
import {
  saveLeaveCluster,
  updateLnAData,
} from "../../redux/actions/lna/lnaaction";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import { getMySqlDateFormat } from "../../app/other/commonfunction";
import {
  pleaseEnterLeaveSymbol,
  pleaseSelectColor,
  pleaseEnterLeaveType,
  pleaseEnterLeaveName,
  pleaseEnterNoOfLeave,
  pleaseEnterFrequencyType,
  pleaseEnterMaxLeavesForward,
  pleaseEnterNoOfDaysEligibleForNextYear,
  pleaseEnterReimbursed,
  regexForHoliday,
  gender,
  noOfLeaveTransfer,
  selecteffectivestartdate,
  effectiveStartDateVaidation,
  effectiveenddatevalidation,
  pleaseEnterstrechedOrStaggered,
  pleaseEnterGovtStatutory,
  minimumLeaveCount
} from "../../assets/constants/constants";
import ProgressBar from "../progressbar/progressbar";
const SAMPLECSS = `
input.e-input, textarea.e-input, .e-input-group, .e-input-group.e-control-wrapper {
  font-size: 12px;
}`
const descriptionTextBox = `.e-outline textarea, textarea.e-outline, .e-outline.e-float-input textarea, .e-outline.e-float-input.e-control-wrapper textarea, .e-outline.e-float-input.e-input-group textarea {
  box-sizing: border-box;
  min-height: 80px }`

function LeaveCluster(props) {
  //NOSONAR
  const { authUser, openPopup, editClusterId } = props;
  const [configurationExpanded, setConfigurationExpanded] = useState(true);
  const [policyAndApplication, setPolicyAndApplication] = useState(true);
  const [effectiveDates, setEffectiveDates] = useState(true);
  const [changedData, setChangedData] = useState(false);
  const [initialLoad, setInitialLoad] = useState(false)
  const [state, setState] = useState({
    title: null,
    leaveUid: null,
    leaveName: null,
    leaveSymbolId: null,
    leaveDescription: null,
    leaveType: null,
    noOfLeave: null,
    frequency: null,
    accumulateInSameYear: "0",
    forwardToNextYear: "0",
    maxLeavesForwardNxtYrs: null,
    noOfDaysEligibleForNextYear: null,
    reimbursed: null,
    policyDoc: [],
    fileName: null,
    oldFileStatus: "",
    leaveColorId: null,
    isActive: "1",
    effectiveStartDate: null,
    effectiveEndDate: null,
    eligibilityAsPerGender: null,
    proRate: "0",
    documentsRequired: "0",
    leaveBifurcation: "0",// personal or special leave
    transferAllowed: "0",
    minLeaveTransfer: null,
    compoffAllowed: "0",
    defaultAllocation: "0",
    strechedOrStaggered: "1",
    govtStatutory: "1",
    weekendCounted: "0"
  });

  let validOptions = {
    rules: {
      leaveNameErr: {
        required: [true, pleaseEnterLeaveName],
        regex: [regexForHoliday, pleaseEnterLeaveName],
        minLength: [1, pleaseEnterLeaveName],
        maxLength: [50, "Should be maximum of 50 characters only."]
      },
      leaveSymbolIdErr: {
        required: [true, pleaseEnterLeaveSymbol],
      },
      leaveTypeErr: {
        required: [true, pleaseEnterLeaveType],
      },
      leaveColorIdErr: {
        required: [true, pleaseSelectColor],
      },
      noOfLeaveErr: {
        required: [true, pleaseEnterNoOfLeave],
      },
      frequencyErr: {
        required: [true, pleaseEnterFrequencyType],
      },
      noOfDaysEligibleForNextYearErr: {
        required: [true, pleaseEnterNoOfDaysEligibleForNextYear],
      },
      reimbursedErr: {
        required: [true, pleaseEnterReimbursed],
      },
      eligibilityAsPerGenderErr: {
        required: [true, gender],
      },

      effectiveStartDateErr: {
        required: [true, selecteffectivestartdate],
      },
      strechedOrStaggeredErr: {
        required: [true, pleaseEnterstrechedOrStaggered],
      },
      govtStatutoryErr: {
        required: [true, pleaseEnterGovtStatutory],
      }
    }
  }

  const getData = () => {
    if (openPopup === "add") {
      resetData()
    } else {
      editClusterId !== null &&
        (async () => {
          setInitialLoad(true)
          const leaveClusterData = await LnAServices.getLeaveClusterData(
            authUser,
            editClusterId
          );
          const {
            leaveUid,
            leaveName,
            SymbolId,
            leaveDescription,
            leaveType,
            noOfLeave,
            frequency,
            accumulateInSameYear,
            forwardToNextYear,
            maxLeavesForwardNxtYrs,
            noOfDaysEligibleForNextYear,
            reimbursed,
            fileName,
            isActive,
            effectiveStartDate,
            effectiveEndDate,
            leaveColorId,
            eligibilityAsPerGender,
            proRate,
            documentsRequired,
            leaveBifurcation,
            transferAllowed,
            minLeaveTransfer,
            compoffAllowed,
            defaultAllocation,
            strechedOrStaggered,
            govtStatutory,
            weekendCounted
          } = leaveClusterData.Result[0];
          let data = {
            leaveUid,
            leaveName,
            leaveSymbolId: SymbolId,
            leaveDescription,
            leaveType,
            noOfLeave,
            frequency,
            accumulateInSameYear,
            forwardToNextYear,
            maxLeavesForwardNxtYrs,
            noOfDaysEligibleForNextYear,
            reimbursed,
            fileName,
            oldFileStatus: fileName,
            leaveColorId,
            isActive,
            effectiveStartDate,
            effectiveEndDate,
            eligibilityAsPerGender,
            proRate,
            documentsRequired: documentsRequired === "Yes" ? "1" : "0",
            leaveBifurcation: leaveBifurcation === "Special Leave" ? "1" : "0",
            transferAllowed,
            minLeaveTransfer,
            compoffAllowed,
            defaultAllocation,
            strechedOrStaggered: strechedOrStaggered === 'Staggered' ? "2" : "1",
            govtStatutory: govtStatutory === 'Government' ? "1" : "2",
            weekendCounted
          };
          setState((preState) => ({ ...preState, ...data }));
          setTimeout(() => {
            const addFormObject = new FormValidator("#leaveMasterForm", validOptions);
            addFormObject.validate();
            setInitialLoad(false)
          }, 200);
        })();
    }
  }
  const resetData = () => {
    let data = {
      leaveUid: null,
      leaveName: null,
      leaveSymbolId: null,
      leaveDescription: null,
      leaveType: null,
      noOfLeave: null,
      frequency: null,
      accumulateInSameYear: "0",
      forwardToNextYear: "0",
      maxLeavesForwardNxtYrs: null,
      noOfDaysEligibleForNextYear: null,
      reimbursed: null,
      policyDoc: [],
      fileName: null,
      oldFileStatus: "",
      leaveColorId: null,
      isActive: "1",
      effectiveStartDate: null,
      effectiveEndDate: null,
      eligibilityAsPerGender: null,
      proRate: "0",
      documentsRequired: "0",
      leaveBifurcation: "0",
      transferAllowed: "0",
      minLeaveTransfer: null,
      compoffAllowed: "0",
      defaultAllocation: "0",
      strechedOrStaggered: "1",
      govtStatutory: "1",
      weekendCounted: "0"
    };
    setState((preState) => ({ ...preState, ...data }));
  }
  const documentDetail = (currentTime, file) => {
    setState((prevState) => ({
      ...prevState,
      fileName: file,
      oldFileStatus: file,
    }));
  };

  /**
   * Author: Suresh Sargam
   * @param {*} type : will update the local state based on type params
   * @descirption : call this function to update the local state
   *
   * **/
  const inputChangeHandler = (e, type) => {
    // NOSONAR
    let options = {};
    switch (type) {
      case "LeaveName":

        setState((prevState) => ({ ...prevState, leaveName: e.value.trim() }));
        options = {
          rules: {
            leaveNameErr: {
              required: [true, pleaseEnterLeaveName],
              regex: [regexForHoliday, pleaseEnterLeaveName],
              minLength: [1, pleaseEnterLeaveName],
              maxLength: [50, "Should be maximum of 50 characters only."]
            },
          },
        };
        break;
      case "LeaveSymbolId":
        setState((prevState) => ({
          ...prevState,
          leaveSymbolId: e.itemData.Id,
        }));
        options = {
          rules: {
            leaveSymbolIdErr: {
              required: [true, pleaseEnterLeaveSymbol],
            },
          },
        };
        break;
      case "LeaveDescription":
        setState((prevState) => ({ ...prevState, leaveDescription: e.value && e.value.trim() }));
        break;
      case "LeaveType":
        setState((prevState) => ({
          ...prevState,
          leaveType: e.itemData.Id.toString(),
        }));
        options = {
          rules: {
            leaveTypeErr: {
              required: [true, pleaseEnterLeaveType],
            },
          },
        };
        break;
      case "LeaveColorId":
        setState((prevState) => ({
          ...prevState,
          leaveColorId: e.itemData.Id,
        }));
        options = {
          rules: {
            leaveColorIdErr: {
              required: [true, pleaseSelectColor],
            },
          },
        };
        break;
      case "NoOfLeave":
        if (e.value % .25 === 0) {
          setState((prevState) => ({ ...prevState, noOfLeave: e.value }));
        } else {
          setState((prevState) => ({ ...prevState, noOfLeave: null }));
        }

        options = {
          rules: {
            noOfLeaveErr: {
              required: [true, pleaseEnterNoOfLeave],
            },
          },
        };
        break;
      case "Frequency":
        setState((prevState) => ({
          ...prevState,
          frequency: e.itemData.Id.toString(),
        }));
        options = {
          rules: {
            frequencyErr: {
              required: [true, pleaseEnterFrequencyType],
            },
          },
        };
        break;
      case "EligibilityAsPerGender":
        setState((prevState) => ({
          ...prevState,
          eligibilityAsPerGender: e.itemData.Id.toString(),
        }));
        options = {
          rules: {
            eligibilityAsPerGenderErr: {
              required: [true, gender],
            },
          },
        };
        break;

      case "proRate":
        setState((prevState) => ({
          ...prevState,
          proRate: e.checked === true ? "1" : "0",
        }));
        break;
      case "documentsRequired":
        setState((prevState) => ({
          ...prevState,
          documentsRequired: e.checked === true ? "1" : "0",
        }));
        break;
      case "leaveBifurcation":
        setState((prevState) => ({
          ...prevState,
          leaveBifurcation: e.checked === true ? "1" : "0",
        }));
        break;
      case "AccumulateInSameYear":
        setState((prevState) => ({
          ...prevState,
          accumulateInSameYear: e.checked === true ? "1" : "0",
          forwardToNextYear: "0",
        }));
        break;
      case "ForwardToNextYear":
        setState((prevState) => ({
          ...prevState,
          forwardToNextYear: e.checked === true ? "1" : "0",
          accumulateInSameYear: "0"
        }));
        break;
      case "DefaultAllocation":
        setState((prevState) => ({
          ...prevState,
          defaultAllocation: e.checked === true ? "1" : "0",
        }));
        break;
      case "MaxLeavesForwardNxtYear":
        setState((prevState) => ({
          ...prevState,
          maxLeavesForwardNxtYrs: e.value,
        }));
        if (state.forwardToNextYear === "1") {
          options = {
            rules: {
              maxLeavesForwardNxtYrsErr: {
                required: [true, pleaseEnterMaxLeavesForward],
              },
            },
          };
        }
        break;
      case "NoOfDaysEligibleForNextYear":
        setState((prevState) => ({
          ...prevState,
          noOfDaysEligibleForNextYear: e.value,
        }));
        options = {
          rules: {
            noOfDaysEligibleForNextYearErr: {
              required: [true, pleaseEnterNoOfDaysEligibleForNextYear],
            },
          },
        };
        break;
      case "Reimbursed":
        setState((prevState) => ({
          ...prevState,
          reimbursed: e.itemData.Id.toString(),
        }));
        options = {
          rules: {
            reimbursedErr: {
              required: [true, pleaseEnterReimbursed],
            },
          },
        };
        break;

      case "IsActive":
        setState((prevState) => ({
          ...prevState,
          isActive: e.checked === true ? "1" : "0",
        }));
        break;
      case "EffectiveStartDate":
        setState((prevState) => ({
          ...prevState,
          effectiveStartDate: e.value,
        }));
        if (document.getElementById("effectiveStartDateErr")) {
          document.getElementById("effectiveStartDateErr").innerText = "";
        }
        break;
      case "EffectiveEndDate":
        setState((prevState) => ({ ...prevState, effectiveEndDate: e.value }));
        break;

      case "transferAllowed":
        setState((prevState) => ({
          ...prevState,
          transferAllowed: e.checked === true ? "1" : "0",
        }));
        break;
      case "minLeaveTransfer":
        setState((prevState) => ({
          ...prevState,
          minLeaveTransfer: e.value,
        }));
        break;
      case "compoffAllowed":
        setState((prevState) => ({
          ...prevState,
          compoffAllowed: e.checked === true ? "1" : "0",
        }));
        break;
      case "strechedOrStaggered":
        setState((prevState) => ({
          ...prevState,
          strechedOrStaggered: e.itemData.Id,
        }));
        options = {
          rules: {
            strechedOrStaggeredErr: {
              required: [true, pleaseEnterstrechedOrStaggered],
            },
          },
        };
        break;
      case "govtStatutory":
        setState((prevState) => ({
          ...prevState,
          govtStatutory: e.itemData.Id,
        }));
        options = {
          rules: {
            govtStatutoryErr: {
              required: [true, pleaseEnterGovtStatutory],
            },
          },
        };
        break;
      case "weekendCounted":
        setState((prevState) => ({
          ...prevState,
          weekendCounted: e.checked === true ? "1" : "0",
        }));
        break;
      default:
    }

    setTimeout(() => {
      const addFormObject = new FormValidator("#leaveMasterForm", options);
      addFormObject.validate();
    }, 100);
  };

  const handleExpand = (type) => {
    if (type === "Configuration") {
      setConfigurationExpanded(prevState => !prevState);
    }
    if (type === "PolicyAndApplication") {
      setPolicyAndApplication(prevState => !prevState);
    }
    if (type === "EffectiveDates") {
      setEffectiveDates(prevState => !prevState);
    }
  }

  const saveLeaveData = () => {
    // NOSONAR
    const {
      leaveUid,
      leaveName,
      leaveSymbolId,
      leaveDescription,
      leaveType,
      noOfLeave,
      frequency,
      accumulateInSameYear,
      forwardToNextYear,
      maxLeavesForwardNxtYrs,
      noOfDaysEligibleForNextYear,
      reimbursed,
      fileName,
      leaveColorId,
      isActive,
      effectiveStartDate,
      effectiveEndDate,
      eligibilityAsPerGender,
      proRate,
      documentsRequired,
      leaveBifurcation,
      transferAllowed,
      minLeaveTransfer,
      compoffAllowed,
      defaultAllocation,
      strechedOrStaggered,
      govtStatutory,
      weekendCounted
    } = state;
    if (forwardToNextYear === "1") {
      validOptions.rules["maxLeavesForwardNxtYrsErr"] = {
        required: [true, pleaseEnterMaxLeavesForward],
      }
    }
    if (transferAllowed === "1") {
      validOptions.rules["minLeaveTransferErr"] = {
        required: [true, noOfLeaveTransfer],
        min: [1, minimumLeaveCount],
      }
    }

    let validateDates = true;


    if (state.effectiveStartDate !== null && state.effectiveEndDate !== null
    ) {
      if (new Date(state.effectiveStartDate) > new Date(state.effectiveEndDate)) {
        document.getElementById("effectiveStartDateErr").innerHTML =
          effectiveStartDateVaidation;
        document.getElementById("effectiveEndDateErr").innerHTML =
          effectiveenddatevalidation;
        validateDates = false;
      }
    }

    const addFormObject = new FormValidator("#leaveMasterForm", validOptions);
    if (addFormObject.validate() && validateDates) {
      setInitialLoad(true)
      let leaveSaveData = {
        userId: authUser,
        leaveUid,
        leaveName,
        leaveSymbolId,
        leaveDescription,
        leaveType,
        noOfLeave,
        frequency,
        accumulateInSameYear,
        forwardToNextYear,
        maxLeavesForwardNxtYrs: maxLeavesForwardNxtYrs ? maxLeavesForwardNxtYrs : 0,
        noOfDaysEligibleForNextYear,
        reimbursed,
        fileName,
        leaveColorId,
        isActive,
        effectiveStartDate:
          effectiveStartDate !== "" && effectiveStartDate !== null
            ? getMySqlDateFormat(effectiveStartDate)
            : null,
        effectiveEndDate:
          effectiveEndDate !== "" && effectiveEndDate !== null
            ? getMySqlDateFormat(effectiveEndDate)
            : "",
        eligibilityAsPerGender,
        proRate,
        documentsRequired,
        leaveBifurcation,
        transferAllowed,
        minLeaveTransfer: transferAllowed === "0" ? 0 : minLeaveTransfer,
        compoffAllowed,
        defaultAllocation,
        strechedOrStaggered,
        govtStatutory,
        weekendCounted
      };
      props.saveLeaveCluster(leaveSaveData);
      setTimeout(() => {
        setInitialLoad(false)
      }, 10)
    }
    // else {
    //   getNotify(ERROR, pleaseFillAllTheRequiredFields);
    // }

  };

  const selectedFiles = (fileNms) => {
    try {
      setState((preState) => ({ ...preState, policyDoc: fileNms }));
      setTimeout(() => {
        setChangedData(!changedData);
      });
    } catch (error) { }
  };
  const removeFile = () => {
    setState(preState => ({ ...preState, fileName: "" }))
  }

  const colorTemplateHex = (prop) => {
    const { optional1, Name } = prop;
    return (
      <div
        style={{
          borderRadius: 3,
          width: 100,
          display: "flex",
          alignItems: "center",
          marginLeft: 15,
        }}
      >
        <div
          style={{
            backgroundColor: optional1,
            color: optional1,
            height: 20,
            width: 20,
          }}
        >
          &nbsp;&nbsp;
        </div>
        <div>{Name}</div>
      </div>
    );
  };

  const getOneDayForward = (dt) => {
    let date = dt
    date.setDate(date.getDate() + 1)
    return date
  }

  useEffect(() => {
    getData()
  }, [editClusterId, openPopup]);

  useEffect(() => {
    if (props.lnaMasterFlag === "updated") {
      props.changePopUpHandler("close");
    }
  }, [props.lnaMasterFlag]);

  useEffect(() => {
    props.setSubmitClick(saveLeaveData);
  }, [state]);

  useEffect(() => {
    props.updateLnAData({ popUpSource: 'Leave Master' })
    getData()
  }, []);

  return (
    <div id="leaveMasterForm" style={{ height: "61.5vh", overflowY: "scroll" }}>
      <Accordion expanded={configurationExpanded}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon onClick={() => handleExpand("Configuration")} />}
          aria-controls="panel1a-content"
        >
          <style>{SAMPLECSS}</style>
          <div
            style={{
              font: "normal normal bold 14px/16px Arial",
              color: "#939399",
            }}
          >
            Configuration
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 ">
              <TextBoxComponent
                data-msg-containerid="leaveNameErr"
                id="leaveName"
                value={state.leaveName}
                name="leaveNameErr"
                placeholder="Leave Name *"
                floatLabelType="Auto"
                cssClass="e-outline"
                onChange={(e) => inputChangeHandler(e, "LeaveName")}
                htmlAttributes={{ maxlength: 50 }}
              />
              <div id="leaveNameErr" className="error-div" />
            </div>
            <div className=" col-xs-12 col-sm-12 col-lg-12 col-md-12 mt-lg">
              <ComboBoxComponent
                id="leaveSymbolId"
                dataSource={props.leaveData}
                fields={{ text: "Name", value: "Id" }}
                value={state.leaveSymbolId}
                data-msg-containerid="leaveSymbolIdErr"
                name="leaveSymbolIdErr"
                floatLabelType="Auto"
                cssClass="e-outline"
                placeholder="Leave Symbol *"
                allowCustom={false}
                select={(e) => inputChangeHandler(e, "LeaveSymbolId")}
              />
              <div
                id="leaveSymbolIdErr"
                className="e-input-group e-float-icon-left error-div"
              />
            </div>
            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
              <style>{descriptionTextBox}</style>
              <TextBoxComponent
                style={{ minHeight: 100 }}
                id={'leaveDescription'}
                placeholder={"Description"}
                cssClass="e-outline textarea"
                floatLabelType="Auto"
                name="leaveDescriptionErr"
                data-msg-containerid="leaveDescriptionErr"
                multiline
                // inputProps={{
                //   required: true,
                //   minLength: 0,
                // }}
                htmlAttributes={{ maxlength: 500 }}
                onChange={(e) => inputChangeHandler(e, "LeaveDescription")}
                value={state.leaveDescription}
              />
            </div>
            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 mt-lg atc-no-paddding">
              <div
                className="col-xs-6 col-sm-6 col-lg-6 col-md-6"
                style={{ paddingLeft: 0 }}
              >
                <ComboBoxComponent
                  id="leaveColorId"
                  dataSource={props.colourMaster}
                  fields={{ text: "Name", value: "Id" }}
                  value={state.leaveColorId}
                  data-msg-containerid="leaveColorIdErr"
                  name="leaveColorIdErr"
                  floatLabelType="Auto"
                  cssClass="e-outline"
                  placeholder="Color *"
                  itemTemplate={colorTemplateHex}
                  allowCustom={false}
                  select={(e) => inputChangeHandler(e, "LeaveColorId")}
                />
                <div
                  id="leaveColorIdErr"
                  className="e-input-group e-float-icon-left error-div"
                />
              </div>
              <div
                className="col-xs-6 col-sm-6 col-lg-6 col-md-6"
                style={{ paddingLeft: 0 }}
              >
                <ComboBoxComponent
                  id="leaveType"
                  dataSource={[
                    { Name: "Paid", Id: "1" },
                    { Name: "Unpaid", Id: "0" },
                  ]}
                  fields={{ text: "Name", value: "Id" }}
                  value={state.leaveType}
                  data-msg-containerid="leaveTypeErr"
                  name="leaveTypeErr"
                  floatLabelType="Auto"
                  cssClass="e-outline"
                  placeholder="Leave Type *"
                  allowCustom={false}
                  select={(e) => inputChangeHandler(e, "LeaveType")}
                />
                <div
                  id="leaveTypeErr"
                  className="e-input-group e-float-icon-left error-div"
                />
              </div>
            </div>

            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 ">
              <div
                className="col-xs-6 col-sm-6 col-lg-6 col-md-6"
                style={{ paddingLeft: 0 }}
              >
                <NumericTextBoxComponent
                  data-msg-containerid="noOfLeaveErr"
                  id="noOfLeave"
                  value={state.noOfLeave}
                  min={0}
                  negativePattern="( n)"
                  name="noOfLeaveErr"
                  placeholder="No. of Leaves *"
                  floatLabelType="Auto"
                  format="##.##"
                  decimals={2}
                  onChange={(e) => inputChangeHandler(e, "NoOfLeave")}
                  cssClass="e-outline"
                  showSpinButton={false}
                  max={365}
                />
                <div
                  id="noOfLeaveErr"
                  className="e-input-group e-float-icon-left error-div"
                />
              </div>
              <div
                className="col-xs-6 col-sm-6 col-lg-6 col-md-6"
                style={{ paddingLeft: 0 }}
              >
                <ComboBoxComponent
                  id="frequency"
                  dataSource={[
                    { Name: "Annually", Id: "2" },
                    { Name: "Month End", Id: "1" },
                    { Name: "Month Start", Id: "0" },
                  ]}
                  fields={{ text: "Name", value: "Id" }}
                  value={state.frequency}
                  data-msg-containerid="frequencyErr"
                  name="frequencyErr"
                  floatLabelType="Auto"
                  cssClass="e-outline"
                  placeholder="Frequency Type *"
                  allowCustom={false}
                  select={(e) => inputChangeHandler(e, "Frequency")}
                />
                <div
                  id="frequencyErr"
                  className="e-input-group e-float-icon-left error-div"
                />
              </div>
            </div>
            <div
              className="col-xs-12 col-sm-12 col-lg-12 col-md-12"
              style={{ paddingLeft: 0 }}
            >
              <ComboBoxComponent
                id="EligibilityAsPerGender"
                dataSource={[
                  { Name: "Male", Id: "0" },
                  { Name: "Female", Id: "1" },
                  { Name: "All", Id: "2" },
                ]}
                fields={{ text: "Name", value: "Id" }}
                value={state.eligibilityAsPerGender}
                data-msg-containerid="eligibilityAsPerGenderErr"
                name="eligibilityAsPerGenderErr"
                floatLabelType="Auto"
                cssClass="e-outline"
                placeholder="Eligibility As Per Gender *"
                allowCustom={false}
                select={(e) => inputChangeHandler(e, "EligibilityAsPerGender")}
              />
              <div
                id="eligibilityAsPerGenderErr"
                className="e-input-group e-float-icon-left error-div"
              />
            </div>
            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 ">
              <div className="atc-switch-heading">ProRate</div>
              <div className="col-xs-1 col-sm-1 col-lg-4 col-md-2 atc-switch-inline mt-md atc-no-paddding">
                <div className="mr-md">No</div>
                <div className="atc-switch-margin">
                  <SwitchComponent
                    id="proRate"
                    cssClass="atc-switch-height"
                    change={(e) => inputChangeHandler(e, "proRate")}
                    checked={state.proRate === "1" ? true : false}
                  />
                </div>
                <div className="ml-md">Yes</div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 mt-lg ">
              <div className="atc-switch-heading">Document Required</div>
              <div className="col-xs-1 col-sm-1 col-lg-4 col-md-2 atc-switch-inline mt-md atc-no-paddding">
                <div className="mr-md">No</div>
                <div className="atc-switch-margin">
                  <SwitchComponent
                    id="documentsRequired"
                    cssClass="atc-switch-height"
                    change={(e) => inputChangeHandler(e, "documentsRequired")}
                    checked={state.documentsRequired === "1" ? true : false}
                  />
                </div>
                <div className="ml-md">Yes</div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 mt-lg ">
              <div className="atc-switch-heading">Special Leave</div>
              <div className="col-xs-1 col-sm-1 col-lg-4 col-md-2 atc-switch-inline mt-md atc-no-paddding">
                <div className="mr-md">No</div>
                <div className="atc-switch-margin">
                  <SwitchComponent
                    id="leaveBifurcation"
                    cssClass="atc-switch-height"
                    change={(e) => inputChangeHandler(e, "leaveBifurcation")}
                    checked={state.leaveBifurcation === "1" ? true : false}
                  />
                </div>
                <div className="ml-md">Yes</div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 mt-lg ">
              <div className="atc-switch-heading">Transfer Allowed</div>
              <div className="col-xs-1 col-sm-1 col-lg-4 col-md-2 atc-switch-inline mt-md atc-no-paddding">
                <div className="mr-md">No</div>
                <div className="atc-switch-margin">
                  <SwitchComponent
                    id="transferAllowed"
                    cssClass="atc-switch-height"
                    change={(e) => inputChangeHandler(e, "transferAllowed")}
                    checked={state.transferAllowed === "1" ? true : false}
                  />
                </div>
                <div className="ml-md">Yes</div>
              </div>
            </div>

            {state.transferAllowed == "1" && (
              <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 mt-lg">
                <NumericTextBoxComponent
                  data-msg-containerid="minLeaveTransferErr"
                  id="minLeaveTransfer"
                  value={state.minLeaveTransfer}
                  min={0}
                  max={state.noOfLeave}
                  decimals={0}
                  negativePattern="( n)"
                  name="minLeaveTransferErr"
                  placeholder="Min No. Of Leaves Transfer *"
                  required
                  floatLabelType="Auto"
                  format="##"
                  onChange={(e) => inputChangeHandler(e, "minLeaveTransfer")}
                  cssClass="e-outline"
                  showSpinButton={false}
                />
                <div id="minLeaveTransferErr" className="error-div" />
              </div>
            )}
            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 mt-lg ">
              <div className="atc-switch-heading">Comp Off Allowed</div>
              <div className="col-xs-1 col-sm-1 col-lg-4 col-md-2 atc-switch-inline mt-md atc-no-paddding">
                <div className="mr-md">No</div>
                <div className="atc-switch-margin">
                  <SwitchComponent
                    id="compoffrAllowed"
                    cssClass="atc-switch-height"
                    change={(e) => inputChangeHandler(e, "compoffAllowed")}
                    checked={state.compoffAllowed === "1" ? true : false}
                  />
                </div>
                <div className="ml-md">Yes</div>
              </div>
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      <Accordion expanded={policyAndApplication}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon onClick={() => handleExpand("PolicyAndApplication")} />}
          aria-controls="panel1a-content"
        >
          <div
            style={{
              font: "normal normal bold 14px/16px Arial",
              color: "#939399",
            }}
          >
            Policy & Application
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div>
            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 ">
              <div className="atc-switch-heading">Accumulate in same year</div>
              <div className="col-xs-1 col-sm-1 col-lg-4 col-md-2 atc-switch-inline mt-md atc-no-paddding">
                <div className="mr-md">No</div>
                <div className="atc-switch-margin">
                  <SwitchComponent
                    id="accumulateInSameYear"
                    cssClass="atc-switch-height"
                    change={(e) =>
                      inputChangeHandler(e, "AccumulateInSameYear")
                    }
                    checked={state.accumulateInSameYear === "1" ? true : false}
                  />
                </div>
                <div className="ml-md">Yes</div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 mt-lg atc-no-paddding">
              <div className="atc-switch-heading">Forward to next year</div>
              <div className="col-xs-1 col-sm-1 col-lg-4 col-md-2 atc-switch-inline mt-md atc-no-paddding">
                <div className="mr-md">No</div>
                <div className="atc-switch-margin">
                  <SwitchComponent
                    id="forwardToNextYear"
                    cssClass="atc-switch-height"
                    change={(e) => inputChangeHandler(e, "ForwardToNextYear")}
                    checked={state.forwardToNextYear === "1" ? true : false}
                  />
                </div>
                <div className="ml-md">Yes</div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 mt-lg atc-no-paddding">
              <div className="atc-switch-heading">Default allocation</div>
              <div className="col-xs-1 col-sm-1 col-lg-4 col-md-2 atc-switch-inline mt-md atc-no-paddding">
                <div className="mr-md">No</div>
                <div className="atc-switch-margin">
                  <SwitchComponent
                    id="defaultAllocation"
                    cssClass="atc-switch-height"
                    change={(e) => inputChangeHandler(e, "DefaultAllocation")}
                    checked={state.defaultAllocation === "1" ? true : false}
                  />
                </div>
                <div className="ml-md">Yes</div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 mt-lg atc-no-paddding">
              <div className="atc-switch-heading">Weekend Counted</div>
              <div className="col-xs-1 col-sm-1 col-lg-4 col-md-2 atc-switch-inline mt-md atc-no-paddding">
                <div className="mr-md">No</div>
                <div className="atc-switch-margin">
                  <SwitchComponent
                    id="weekendCounted"
                    cssClass="atc-switch-height"
                    change={(e) => inputChangeHandler(e, 'weekendCounted')}
                    checked={state.weekendCounted === '1' ? true : false}
                  />
                </div>
                <div className="ml-md">Yes</div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 mt-xl" style={{ marginBottom: "-20px" }}>
              <ComboBoxComponent
                id="strechedOrStaggered"
                dataSource={[{ Name: 'Staggered', Id: "2" }, { Name: 'Stretched', Id: "1" }]}
                fields={{ text: "Name", value: "Id" }}
                value={state.strechedOrStaggered}
                data-msg-containerid="strechedOrStaggeredErr"
                name="strechedOrStaggeredErr"
                floatLabelType="Auto"
                cssClass="e-outline"
                placeholder="Stretched / Staggered *"
                select={(e) => inputChangeHandler(e, 'strechedOrStaggered')}
              />
              <div
                id="strechedOrStaggeredErr"
                className="e-input-group e-float-icon-left error-div"
              />
            </div>
            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 mt-xl" style={{ marginBottom: "-20px" }}>
              <ComboBoxComponent
                id="govtStatutory"
                dataSource={[{ Name: 'Statutory', Id: "2" }, { Name: 'Government', Id: "1" }]}
                fields={{ text: "Name", value: "Id" }}
                value={state.govtStatutory}
                data-msg-containerid="govtStatutoryErr"
                name="govtStatutoryErr"
                floatLabelType="Auto"
                cssClass="e-outline"
                placeholder="Government / Statutory *"
                select={(e) => inputChangeHandler(e, 'govtStatutory')}
              />
              <div
                id="govtStatutoryErr"
                className="e-input-group e-float-icon-left error-div"
              />
            </div>
            {state.forwardToNextYear == "1" &&
              <div
                className="col-xs-12 col-sm-12 col-lg-12 col-md-12 mt-lg atc-no-paddding"
                style={{ paddingLeft: 0 }}
              >
                <NumericTextBoxComponent
                  id="maxLeavesForwardNxtYrs"
                  value={state.maxLeavesForwardNxtYrs}
                  data-msg-containerid="maxLeavesForwardNxtYrsErr"
                  name="maxLeavesForwardNxtYrsErr"
                  placeholder="Max Leaves Forwarded to Next Year *"
                  floatLabelType="Auto"
                  cssClass="e-outline"
                  decimals={0}
                  min={0}
                  format="##"
                  max={state.noOfLeave}
                  onChange={(e) =>
                    inputChangeHandler(e, "MaxLeavesForwardNxtYear")
                  }
                  htmlAttributes={{ maxlength: 100 }}
                  enabled={state.forwardToNextYear === "1"}
                  showSpinButton={false}
                />
                <div id="maxLeavesForwardNxtYrsErr" className="error-div" />
              </div>}

            <div
              className="col-xs-12 col-sm-12 col-lg-12 col-md-12 mt-lg atc-no-paddding"
              style={{ paddingLeft: 0 }}
            >
              <NumericTextBoxComponent
                id="noOfDaysEligibleForNextYear"
                data-msg-containerid="noOfDaysEligibleForNextYearErr"
                value={state.noOfDaysEligibleForNextYear}
                name="noOfDaysEligibleForNextYearErr"
                placeholder="No. of Days Forward Eligible For Next Year *"
                floatLabelType="Auto"
                cssClass="e-outline"
                format="##"
                min={0}
                decimals={0}
                max={state.noOfLeave}
                onChange={(e) =>
                  inputChangeHandler(e, "NoOfDaysEligibleForNextYear")
                }
                htmlAttributes={{ maxlength: 100 }}
                showSpinButton={false}
              />
              <div id="noOfDaysEligibleForNextYearErr" className="error-div" />
            </div>
            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 mt-xl">
              <ComboBoxComponent
                id="reimbursed"
                dataSource={[
                  { Name: "Separation", Id: "2" },
                  { Name: "Same Year", Id: "1" },
                  { Name: "No", Id: "0" },
                ]}
                fields={{ text: "Name", value: "Id" }}
                value={state.reimbursed}
                data-msg-containerid="reimbursedErr"
                name="reimbursedErr"
                floatLabelType="Auto"
                cssClass="e-outline"
                placeholder="Reimbursed *"
                allowCustom={false}
                select={(e) => inputChangeHandler(e, "Reimbursed")}
              />
              <div
                id="reimbursedErr"
                className="e-input-group e-float-icon-left error-div"
              />
            </div>

            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 mt-lg">
              <div
                style={{
                  fontSize: ".95rem",
                  color: "#939399",
                  fontWeight: 500,
                  padding: ".5rem 0",
                  marginTop: "-2rem",
                }}
              >
                Attach Policy
              </div>

              <UploadDocuments
                type={"leave"}
                module={'lna'}
                allowmultiple={false}
                documentDetails={documentDetail}
                selectedFiles={selectedFiles}
                // fileList={!state.holidayPolicyDoc ? [] : state.holidayPolicyDoc}
                fileList={[]}
                apiUrl={"lnaApiUrl"}
              />

              {state.fileName !== null && state.oldFileStatus !== null ? (
                <div>
                  {createImageFilepathForLPView(
                    [].concat(state.fileName),
                    "document/lna/leave",
                    "lnaApiUrl", removeFile
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
            {/*
            <div className="atc-text-styling " style={{ font: "normal normal bold 14px/16px Arial", color: "#939399" }}>Application</div>
           <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 mt-lg" style={{ marginBottom: "-20px" }}>
              <ComboBoxComponent
                id="strechedOrStaggered"
                dataSource={[
                  { Name: "Staggered", Id: "1" },
                  { Name: "Stretched", Id: "0" },
                ]}
                fields={{ text: "Name", value: "Id" }}
                value={state.strechedOrStaggered}
                data-msg-containerid="strechedOrStaggeredErr"
                name="strechedOrStaggeredErr"
                floatLabelType="Auto"
                cssClass="e-outline"
                placeholder="No. of Days Prior intimation"
                select={(e) => inputChangeHandler(e, "strechedOrStaggered")}
              />
              <div
                id="strechedOrStaggeredErr"
                className="e-input-group e-float-icon-left error-div"
              />
            </div> */}
            {/* <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 mt-xl">
              <TextBoxComponent
                data-msg-containerid="noOfDaysForwardAllowedErr"
                id="noOfDaysForwardAllowed"
                value={state.noOfDaysForwardAllowed}
                name="noOfDaysForwardAllowedErr"
                placeholder="No. of Days Forward Allowed"
                floatLabelType="Auto"
                cssClass="e-outline"
                onChange={(e) => inputChangeHandler(e, 'noOfDaysForwardAllowed')}
                htmlAttributes={{ maxlength: 100 }}
              />
              <div id="noOfDaysForwardAllowedErr" className="error-div" />
            </div>
            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 mt-xl ">
              <TextBoxComponent
                data-msg-containerid="noOfDaysBackdatedErr"
                id="noOfDaysBackdated"
                value={state.noOfDaysBackdated}
                name="noOfDaysBackdatedErr"
                placeholder="No. of Days Backward Allowed"
                floatLabelType="Auto"
                cssClass="e-outline"
                onChange={(e) => inputChangeHandler(e, 'noOfDaysBackdated')}
                htmlAttributes={{ maxlength: 100 }}
              />
              <div id="noOfDaysBackdatedErr" className="error-div" />
            </div> */}
          </div>
        </AccordionDetails>
      </Accordion>
      {/*<Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"        
        >
          <div style={{ font: "normal normal bold 14px/16px Arial", color: "#939399" }}>Approval Mechanism</div>
        </AccordionSummary>
         <AccordionDetails         
        >
          <div>
            <div
              className="row card-layout pl-sm pb-md"
              style={{ borderBottom: "1px solid #dbdbdd" }}
            >
              <div className="atc-text-styling " style={{ font: "normal normal bold 14px/16px Arial", color: "#939399" }}>Approval</div>
              <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 mt-lg atc-no-paddding">
                <div className="atc-switch-heading">
                  Supervisor Approval Required
                </div>
                <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 atc-switch-inline mt-md atc-no-paddding">
                  <div className="mr-md">No</div>
                  <div className="atc-switch-margin">
                    <SwitchComponent
                      id="reportingSupervisorApprovalStatus"
                      cssClass="atc-switch-height"
                      change={(e) =>
                        inputChangeHandler(
                          e,
                          "reportingSupervisorApprovalStatus"
                        )
                      }
                      checked={
                        state.reportingSupervisorApprovalStatus === "1"
                          ? true
                          : false
                      }
                    />
                  </div>
                  <div className="ml-md">Yes</div>
                </div>
              </div>
              <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 mt-lg">
                <div className="atc-switch-heading">HR Approval Required</div>
                <div className="col-xs-1 col-sm-1 col-lg-4 col-md-2 atc-switch-inline mt-md atc-no-paddding">
                  <div className="mr-md">No</div>
                  <div className="atc-switch-margin">
                    <SwitchComponent
                      id="hrApprovalStatus"
                      cssClass="atc-switch-height"
                      change={(e) => inputChangeHandler(e, "hrApprovalStatus")}
                      checked={state.hrApprovalStatus === "1" ? true : false}
                    />
                  </div>
                  <div className="ml-md">Yes</div>
                </div>
              </div>

              <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12  mt-xl" style={{ marginBottom: "10px" }}>
                <div className="atc-switch-heading">Auto Approval</div>
                <div className="col-xs-12 col-sm-12 col-lg-4 col-md-2 atc-switch-inline mt-xl">
                  <div className="mr-md">No</div>
                  <div className="atc-switch-margin">
                    <SwitchComponent
                      id="leaveClusterIsActive"
                      cssClass="atc-switch-height"
                      change={(e) => inputChangeHandler(e, "isActive")}
                      checked={state.isActive === "1" ? true : false}
                    />
                  </div>
                  <div className="ml-md" >Yes <span>(After 48 Hours of Application)</span></div>
                </div>
              </div>

              <div className="atc-text-styling " style={{ font: "normal normal bold 14px/16px Arial", color: "#939399" }}>
                Cancellation
              </div>
              <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 mt-lg atc-no-paddding">
                <div className="atc-switch-heading">
                  Supervisor Approval Required
                </div>
                <div className="col-xs-1 col-sm-1 col-lg-4 col-md-2 atc-switch-inline mt-md atc-no-paddding">
                  <div className="mr-md">No</div>
                  <div className="atc-switch-margin">
                    <SwitchComponent
                      id="reportingSupervisorApprovalStatus"
                      cssClass="atc-switch-height"
                      change={(e) =>
                        inputChangeHandler(
                          e,
                          "reportingSupervisorApprovalStatus"
                        )
                      }
                      checked={
                        state.reportingSupervisorApprovalStatus === "1"
                          ? true
                          : false
                      }
                    />
                  </div>
                  <div className="ml-md">Yes</div>
                </div>
              </div>
              <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 mt-lg">
                <div className="atc-switch-heading">HR Approval Required</div>
                <div className="col-xs-1 col-sm-1 col-lg-4 col-md-2 atc-switch-inline mt-md atc-no-paddding">
                  <div className="mr-md">No</div>
                  <div className="atc-switch-margin">
                    <SwitchComponent
                      id="hrApprovalStatus"
                      cssClass="atc-switch-height"
                      change={(e) => inputChangeHandler(e, "hrApprovalStatus")}
                      checked={state.hrApprovalStatus === "1" ? true : false}
                    />
                  </div>
                  <div className="ml-md">Yes </div>

                </div>
              </div>
              <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 atc-no-paddding mt-lg">
                <div className="atc-switch-heading">Auto Approval</div>
                <div className="col-xs-1 col-sm-1 col-lg-4 col-md-2 atc-switch-inline mt-lg atc-no-paddding">
                  <div className="mr-md">No</div>
                  <div className="atc-switch-margin">
                    <SwitchComponent
                      id="leaveClusterIsActive"
                      cssClass="atc-switch-height"
                      change={(e) => inputChangeHandler(e, "isActive")}
                      checked={state.isActive === "1" ? true : false}
                    />
                  </div>
                  <div className="ml-md">
                    Yes (After 48 Hours of Application)
                  </div>
                </div>
              </div>
            </div>
          </div>
        </AccordionDetails> 
      </Accordion>
      */}

      <Accordion expanded={effectiveDates}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon onClick={() => handleExpand("EffectiveDates")} />}
          aria-controls="panel1a-content"
        >
          <div
            style={{
              font: "normal normal bold 14px/16px Arial",
              color: "#939399",
            }}
          >
            Effective Dates
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div className="col-sm-12 col-md-12 col-lg-12">
            <div
              className="col-xs-6 col-sm-6 col-lg-6 col-md-6"
              style={{ paddingLeft: 0 }}
            >
              <DatePickerComponent
                style={{ width: "100%" }}
                id="effectiveStartDate"
                name="effectiveStartDateErr"
                data-msg-containerid="effectiveStartDateErr"
                format="dd-MMM-yyyy"
                value={state.effectiveStartDate}
                floatLabelType="Auto"
                cssClass="e-outline"
                firstDayOfWeek={1}
                placeholder="Effective Start Date *"
                change={(e) => inputChangeHandler(e, "EffectiveStartDate")}
              />
              <div
                id="effectiveStartDateErr"
                className="e-input-group e-float-icon-left error-div"
              />
            </div>
            <div
              className="col-xs-6 col-sm-6 col-lg-6 col-md-6"
              style={{ paddingLeft: 0 }}
            >
              <DatePickerComponent
                style={{ width: "100%" }}
                id="effectiveEndDate"
                name="effectiveEndDateErr"
                data-msg-containerid="effectiveStartEndErr"
                format="dd-MMM-yyyy"
                value={state.effectiveEndDate}
                floatLabelType="Auto"
                cssClass="e-outline"
                firstDayOfWeek={1}
                placeholder="Effective End Date "
                min={state.effectiveStartDate ? getOneDayForward(new Date(state.effectiveStartDate)) : null}
                change={(e) => inputChangeHandler(e, "EffectiveEndDate")}
              />
              <div
                id="effectiveEndDateErr"
                className="e-input-group e-float-icon-left error-div"
              />
            </div>
          </div>
        </AccordionDetails>
      </Accordion>
      <div className="loader-new1" id="spinnerContainer" >
        {initialLoad && <ProgressBar />}
      </div>
    </div>
  );
}

const mapStateToProps = ({ auth, lnareducer, meetingmaster }) => {
  const { authUser } = auth;
  const { currentFinancialYearId } = meetingmaster;
  const { symbolData, colourMaster, lnaMasterFlag } = lnareducer;

  const { leaveData } = symbolData;

  return {
    authUser,
    currentFinancialYearId,
    colourMaster,
    leaveData,
    lnaMasterFlag,
  };
};
export default connect(mapStateToProps, {
  saveLeaveCluster,
  updateLnAData,
})(LeaveCluster);
