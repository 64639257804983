// Author by:- Prashant Waphare | 31-08-2020 | home office container load all component here.
import React, { Component } from "react";
import { AccordionComponent } from "@syncfusion/ej2-react-navigations";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { CheckBoxComponent } from "@syncfusion/ej2-react-buttons";
import  NotificationContainer  from "../../../components/notification/notifycontainer";
import HomeOfficeAddressDetails from "../../../components/homeoffice/homeofficeaddressdetails";
import HomeOffinceBasicInfo from "../../../components/homeoffice/homeofficebasicinfo";
import HomeOfficeWorkingSpace from "../../../components/homeoffice/homeofficeworkingspace";
import ElectricitySupply from "../../../components/homeoffice/electricitysupply";
import InternetConnectivity from "../../../components/homeoffice/internetconnectivity";
import HomeOfficeFooterButton from "../../../components/homeoffice/homeofficefooterbutton";
import { addEditHomeOfficeDetails } from "../../../redux/actions/homeoffice/homeofficeaction";

import OtherAssets from "../../../components/homeoffice/otherassets";

class HomeOffce extends Component {
  constructor(props) {
    super(props);
    document.title = "Home Office";
    this.state = {
      height:
        (window.innerHeight ||
          document.documentElement.clientHeight ||
          document.body.clientHeight) - 188,
      //   width:
      //     (window.innerWidth ||
      //       document.documentElement.clientWidth ||
      //       document.body.clientWidth) - 71,
    };
    this.onCreated = this.onCreated.bind(this);
  }

  onCreated() {
    setTimeout(() => {
      if (this.acrdnHOAddress !== null) {
        for (let i = 0; i <= this.acrdnHOAddress.items.length - 1; i += 1) {
          this.acrdnHOAddress.items[i].expanded = true;
        }
      }
      if (this.acrdnHOWS !== null) {
        for (let i = 0; i <= this.acrdnHOWS.items.length - 1; i += 1) {
          this.acrdnHOWS.items[i].expanded = true;
        }
      }
      if (this.acrdnES !== null) {
        for (let i = 0; i <= this.acrdnES.items.length - 1; i += 1) {
          this.acrdnES.items[i].expanded = true;
        }
      }
      if (this.internetConnectivity !== null) {
        for (
          let i = 0;
          i <= this.internetConnectivity.items.length - 1;
          i += 1
        ) {
          this.internetConnectivity.items[i].expanded = true;
        }
      }
      if (this.acrdnOS !== null) {
        for (let i = 0; i <= this.acrdnOS.items.length - 1; i += 1) {
          this.acrdnOS.items[i].expanded = true;
        }
      }
    }, 500);
  }

  componentDidMount = async () => {
    this.updateDimensions();
    const { homeOfficeList } = this.props;
    if (homeOfficeList !== undefined && homeOfficeList.length > 0) {
      const { preRequisitConfirmation, declaration } = homeOfficeList[0];
      this.props.addEditHomeOfficeDetails({ preRequisitConfirmation: preRequisitConfirmation!==undefined ? preRequisitConfirmation : false , declearation: declaration !==undefined ? declaration : false });
    }
    window.addEventListener("resize", this.updateDimensions.bind(this));
  };
  updateDimensions() {
    const updateHeight = window.innerHeight - 110;
    this.setState({
      // width: updateWidth,
      height: updateHeight,
    });
  }

  render() {
    const { preRequisitConfirmation, declearation, homeOfficeList } = this.props;
    const { height } = this.state;
    return (
      <div>
        <div
          style={{
            padding: 10,
            backgroundColor: "#F4F3F5",
            height,
            overflowY: "auto",
          }}
        >
          <NotificationContainer />
          <div>
            <AccordionComponent
              ref={(accordion) => {
                this.acrdnHObasic = accordion;
              }}
              created={this.onCreated}
            >
              <HomeOffinceBasicInfo />
            </AccordionComponent>
          </div>
          <div style={{ paddingTop: 10 }}>
            <AccordionComponent
              ref={(accordion) => {
                this.acrdnHOAddress = accordion;
              }}
              created={this.onCreated}
            >{
                <HomeOfficeAddressDetails homeOfficeList={homeOfficeList} />
              }
            </AccordionComponent>
          </div>
          <div style={{ paddingTop: 10 }}>
            <div
              className=""
              style={{
                backgroundColor: "#fff",
                padding: "20px 20px 10px 10px",
                border: "1px solid #939399",
              }}
            >
              <div style={{ marginLeft: 20 }}>
                <div>
                  <span style={{ color: "#000", fontWeight: 500 }}>
                    PRE-REQUISITES CONFIRMATION *
                  </span>
                </div>
                <div>
                  <span>
                    <CheckBoxComponent
                      change={(e) => {
                        document.getElementById(
                          "preRequisitConfirmation"
                        ).innerHTML = "";
                        // eslint-disable-next-line
                        this.props.addEditHomeOfficeDetails({
                          preRequisitConfirmation: e.checked,
                        });
                      }}
                      checked={preRequisitConfirmation}
                      // eslint-disable-next-line
                      onFocus={() =>
                      (document.getElementById(
                        "preRequisitConfirmation"
                      ).innerHTML = "")
                      }
                    />
                  </span>
                  <span>
                    &nbsp; I confirm that I currently possess, and will continue
                    to maintain all the prerequisites as per below for my
                    home-office.
                  </span>
                </div>
                <div
                  id="preRequisitConfirmation"
                  className="e-input-group e-float-icon-left error-div"
                />
              </div>
            </div>
          </div>
          <div style={{ paddingTop: 10 }}>
            <AccordionComponent
              ref={(accordion) => {
                this.acrdnHOWS = accordion;
              }}
              created={this.onCreated}
            >
              <HomeOfficeWorkingSpace homeOfficeList={homeOfficeList} />
            </AccordionComponent>
          </div>

          <div style={{ paddingTop: 10 }}>
            <AccordionComponent
              ref={(accordion) => {
                this.acrdnES = accordion;
              }}
              created={this.onCreated}
            >
              <ElectricitySupply homeOfficeList={homeOfficeList} />
            </AccordionComponent>
          </div>
          <div style={{ paddingTop: 10 }}>
            <AccordionComponent
              ref={(accordion) => {
                this.internetConnectivity = accordion;
              }}
              created={this.onCreated}
            >
              <InternetConnectivity homeOfficeList={homeOfficeList} />
            </AccordionComponent>
          </div>
          <div style={{ paddingTop: 10 }}>
            <AccordionComponent
              ref={(accordion) => {
                this.acrdnOS = accordion;
              }}
              created={this.onCreated}
            >
              <OtherAssets homeOfficeList={homeOfficeList} />
            </AccordionComponent>
          </div>
          <div style={{ paddingTop: 10 }}>
            <div
              className=""
              style={{
                backgroundColor: "#FFF",
                // padding: 20,
                padding: "20px 20px 10px 10px",
                border: "1px solid #939399",
              }}
            >
              <div style={{ float: "left", marginLeft: 20 }}>
                <CheckBoxComponent
                  change={(e) => {
                    document.getElementById("declearation").innerHTML = "";
                    // eslint-disable-next-line
                    this.props.addEditHomeOfficeDetails({
                      declearation: e.checked,
                    });
                  }}
                  checked={declearation}
                  // eslint-disable-next-line
                  onFocus={() =>
                    (document.getElementById("declearation").innerHTML = "")
                  }
                />
              </div>
              <div style={{ overflow: "hidden", paddingLeft: 10 }}>
                I hereby certify that all details mentioned above are authentic
                and I understand that any misrepresentation could lead to
                relevant action by the organisation. I further declare that any
                change in the abovementioned arrangements would be done only
                after due approvals from the organisation.
              </div>
              <div
                style={{ marginLeft: 20 }}
                id="declearation"
                className="e-input-group e-float-icon-left error-div"
              />
            </div>
          </div>
        </div>
        <div style={{ padding: "6px 27px 0px 0px" }}>
          <HomeOfficeFooterButton homeOfficeList={homeOfficeList} />
        </div>
      </div>
    );
  }
}
HomeOffce.propTypes = {
  declearation: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool
  ]),
  preRequisitConfirmation: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool
  ]),
  homeOfficeList: PropTypes.arrayOf(PropTypes.object)
};
HomeOffce.defaultProps = {
  declearation: false,
  preRequisitConfirmation: false,
  homeOfficeList:[]
};
const mapStateToProps = ({ homeoffice }) => {
  const { preRequisitConfirmation, declearation } = homeoffice;
  return { preRequisitConfirmation, declearation };
};
export default connect(mapStateToProps, {
  addEditHomeOfficeDetails,
})(HomeOffce);
