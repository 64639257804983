/**
 * Author: Vivek Khodade.
 * Last Modify:08-May-2020
 * Modified By:Vivek Khodade
 */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import OnbordingServices from "../../../services/entryexit/onbordingServices";
import { dencrypt } from "../../../app/other/commonfunction";

class Role extends Component {
  constructor(props) {
    super(props);
    this.state = {
      RoleTabData: null,
      empId: "",
      height:
        (window.innerHeight ||
          document.documentElement.clientHeight ||
          document.body.clientHeight) - 188,
      width:
        (window.innerWidth ||
          document.documentElement.clientWidth ||
          document.body.clientWidth) - 71,
    };
    this.errorMessage = "Somthing went wrong! please connect softwere team";
  }

  componentDidMount = () => {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));

    const { search } = window.location;
    const params = new URLSearchParams(search);
    const empId =dencrypt(params.get("empId"));
    this.setState({
      empId,
    });
    this.getRoleData(empId);
  };
    /**
  * Calculate & Update state of new dimensions
  */
  updateDimensions() {
    let update_width = window.innerWidth - 100;
    let update_height = window.innerHeight - 375;
    this.setState({ width: update_width, height: update_height });
  }
  /**
   * function name: getRoleData
   * params
   * candid:candidate id
   * typeid:tab id
   * authUserid: logedin user id
   */
  getRoleData = (empId) => {
    try {
      const candid = empId;
      const tabId = 2;
      const { authUser } = this.props;
      const candidateBasicInfo = Promise.resolve(
        OnbordingServices.getInitiateOrRoleOrlDOrAssetListTabData(
          candid,
          tabId,
          authUser
        )
      );
      candidateBasicInfo.then((result) => {
        if (result !== null) {
          this.setState({
            RoleTabData: result,
          });
        } else {
          this.setState({
            RoleTabData: "error",
          });
        }
      });
    } catch (error) {
      this.setState({
        RoleTabData: "error",
      });
    }
  };

  render() {
    return (
      <div className='tab-container'>
        <div style={{alignSelf: 'center',}}>
                {/* <p style={{position:'absolute', textAlign:'center'}}> In Progress</p> */}
                  <div className='col-12 appointment-table-header'>
        <div className="col-12 appointment-table " >
          </div>
          <div className='col-12 appointment-table'>
              <h4><div style={{color:'#4D4B56'}}><span style={{color:'#EB5465'}}>Work In Progress</span></div></h4>
          </div>    
        </div></div>
      </div>
      // <div style={{height: this.state.height, overflowY: 'auto'  ,width: '100.6%', overflowX:'hidden'}}>
      // {/* <div style={{ display: "block" }} className="tab-container"> */}
      //   {
      //     /* eslint-disable-next-line */
      //     RoleTabData!==null && RoleTabData!=='error' ?
      //     (
      //       <MultiSelectionButtonComponent
      //         tabData={RoleTabData}
      //         tabid={2}
      //         employeeId={empId}
      //       />
      //     ) : RoleTabData !== "error" && RoleTabData === null ? (
      //       <ProgressBar />
      //     ) : (
      //       <ErrorTabMessage message={this.errorMessage} />
      //     )
      //   }
      // </div>
    );
  }
}
Role.propTypes = {
  authUser: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
Role.defaultProps = {
  authUser: "",
};
const mapStateToProps = ({ auth }) => {
  const { authUser } = auth;
  return { authUser };
};

export default connect(mapStateToProps, {})(Role);
