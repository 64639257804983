import React, { Component } from "react";
import { connect } from "react-redux";
import WeeklyReport from "../../../components/lnacomponents/leaves/leaveshrreport/weeklyreport";
import WeeklyReport1 from "../../../components/lnacomponents/leaves/leaveshrreport/weeklyreportmonthview";
import {
  VIEWDAILY,
  VIEWMONTHLY,
  VIEWYEARLY,
  ATTENDANCEHRREPORT,
  VIEWLEAVEBALACE,
} from "../../../redux/constant/meetingcategoryconstant";
import ReportFilter from "../../../components/lnacomponents/leaves/leaveshrreport/reportfilter";
import ReportTabs from "../../../components/lnacomponents/leaves/leaveshrreport/reportTabs";
import NotificationContainer from "../../../components/notification/notifycontainer";
import AllTeamMemberReport from "../../../components/lnacomponents/leaves/leaveshrreport/allteammemberreport";
import LeavesHrReport from "../../../components/lnacomponents/leaves/leaveshrreport/leaveshrreport";
import {
  getLnAData,
  getMonthlyReportDropdownData,
} from "../../../app/other/commonlayoutfunction";
import {
  updateLnAData,
  saveWeekOffSwap,
} from "../../../redux/actions/lna/lnaaction";
import "../../layout/lna/lnaReport.css";
import { getMySqlDateFormat } from "../../../app/other/commonfunction";
import AttendenceBreakup from "../../../components/lnacomponents/leaves/leaveshrreport/attendencebreakup";
import LeaveBalanceGrid from "../../../components/lnacomponents/leaves/leavesbalance/leavebalancegrid";
import { borderRight } from "@material-ui/system";
import ChartReport from "../../../components/redial-chart";

class TeamMonthReportContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showMonths: true,
      height: null,
      width: null,
      content: {
        countryDataContent: [],
        businessUnitContent: [],
        subBusinessUnitContent: [],
        teamMemberContent: [],
        attendanceListContent: [],
        leaveListContent: [],
        effectiveStartDateContent: null,
        effectiveEndDateContent: null,
      },

      // Dropdown State
      monthId: null,
      monthName: "",
      countryOfWork: null,
      businessUnit: null,
      subBusinessUnit: null,
      teamMember: [],
      subPnLData: [],
      teamMemberData: [],
      effectiveStartDate: null,
      effectiveEndDate: null,
      startMonth: null,
      endMonth: null,
      attendanceListData: [],
      leaveListData: [],
      attendanceList: [],
      leaveList: [],
      viewReport: "1", // 1 : Weekly Report, 2 : Monthly Report, 3 : hr Report

      // Dropdown Data
      countryData: null,
      businessUnitData: null,
      subBusinessUnitData: null,
      teamReportTeamMemberData: null, // NOSONAR
      teamReportAttendanceListData: null,
      teamReportLeaveListData: null,
      teamReportEffectiveStartDate: null,
      teamReportEffectiveEndDate: null,
      teamReportAttendanceList: null,
      teamReportLeaveList: null,

      lnaReportMonth: [],
      teamId: null,
      teamMemberSendData: [],

      // All team member report
      teamMemberReportData: [],
      //Access right for export data to csv button in leave balance and my report module
      showDownloadMyReportButton: "N",
      showdownloadLeaveBalanceButton: "N",
      showdownloadLeaveBalanceButtonAllTM: "N",
      minDate: null,
      maxDate:null
    };
  }
  componentDidMount() {
    this.updateDimensions();
    this.getMonthAndYear();
  }

  getFullUserInfo = (userId) => {
    const { othersCheckAvailablity } = this.props;
    return othersCheckAvailablity.find((ele) => ele.UserId === userId);
  };
  componentWillUnmount() {
    this.setState((prevState) => ({
      ...prevState,
      teamMemberReportData: [],
    }));
    this.props.updateLnAData({
      selectedTeamMemberId: null,
      viewClicked: false,
    });
  }

  updateDimensions = () => {
    const updateWidth =
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth;

    const updateHeight =
      window.innerHeight ||
      document.documentElement.clientHeight ||
      document.body.clientHeight;

    this.setState({ height: updateHeight, width: updateWidth });
  };

  onChangeShowMonth = () => {
    // NOSONAR
    this.setState((prevState) => ({
      showMonths: !prevState.showMonths,
    }));
  };
  updateDefaultTeamMember = (data) => {
    this.setState((prevState) => ({
      ...data,
    }));
  };
  getMonthAndYear = () => {
    let date = new Date();
    let month = date.getUTCMonth() + 1; //months from 1-12
    let year = date.getUTCFullYear();
    this.props.updateLnAData({
      startMonthMonthlyReport: `${year}-${month}-01`,
      viewClicked: false
    });
  };
  saveFilterInput = (data) => {
    const {
      businessUnit,
      countryOfWork,
      subBusinessUnit,
      teamMember,
      attendanceList,
      leaveList,
      effectiveStartDate,
      effectiveEndDate,
    } = data;
    let currentDate = new Date(effectiveStartDate);
    let currentEndDate = new Date(effectiveEndDate);
    let offset1 = currentDate.getTimezoneOffset() * 60 * 1000;
    let offset2 = currentEndDate.getTimezoneOffset() * 60 * 1000;
    currentDate.setTime(currentDate.getTime() - offset1);
    currentDate.setUTCHours(0, 0, 0);
    currentEndDate.setTime(currentEndDate.getTime() - offset2);
    currentEndDate.setUTCHours(0, 0, 0);
    try {
      if (this.state.viewReport === "2" || this.state.viewReport === "1") {
        this.props.updateLnAData({
          viewClicked: true,
        });
      } else {
        this.setState((p) => ({
          content: {
            ...p.content,
            countryDataContent: countryOfWork,
            businessUnitContent: businessUnit,
            subBusinessUnitContent: subBusinessUnit,
            teamMemberContent: teamMember,
            attendanceListContent: attendanceList,
            leaveListContent: leaveList,
            // effectiveStartDateContent: effectiveStartDate !== null
            //     ? new Date(getMySqlDateFormat(currentDate)).getTime() / 1000
            //     : null,
            effectiveStartDateContent:
              effectiveStartDate !== null ? currentDate.getTime() / 1000 : null,
            // effectiveEndDateContent: effectiveEndDate !== null
            //     ? new Date(getMySqlDateFormat(currentEndDate)).getTime() / 1000
            //     : null
            effectiveEndDateContent:
              effectiveEndDate !== null
                ? currentEndDate.getTime() / 1000
                : null,
          },
        }));
      }
    } catch (err) {
      console.log(err);
    }
  };

  changeViewReport = (type) => {
    if (type === "weeklyReport") {
      this.setState({
        viewReport: "1",
      });
      this.props.updateLnAData({
        weekOrMonth: "Weekly",
      });
    }
    if (type === "monthlyReportMonth") {
      this.props.updateLnAData({
        weekOrMonth: "Monthly",
      });
    }
    if (type === "monthlyReport") {
      this.setState({
        viewReport: "2",
      });
    }

    if (type === "hrReport") {
      this.setState({
        viewReport: "3",
      });
    }
  };

  changeTeamMemberIdsOnReportScreenChange = () => {
    this.setState((prevState) => ({
      ...prevState,
      teamMember: [],
    }));
  };

  inputChangeHandler = (e, type = "") => {
    /**NOSONAR */
    const {
      subBusinessUnit,
      businessUnit,
      viewReport,
      subBusinessUnitData,
      teamReportTeamMemberData,
    } = this.state;
    switch (type) {
      case "Month":
        if (e.value) {
          this.setState((prevState) => ({
            ...prevState,
            monthId: Math.floor(e.value.getTime() / 1000),
          }));
          // setMonth(value.Name)
        }
        break;
      case "BaseCountryAllTeamReport":
        if (e.itemData) {
          this.setState((prevState) => ({
            ...prevState,
            countryOfWork: e.itemData.Id,
          }));
          this.props.updateLnAData({
            viewClicked: false,
          });
          this.getMasterDropdownData("countryTypeDropdown");
        } else if (e.value === null) {
          this.setState((prevState) => ({
            ...prevState,
            countryOfWork: null,
          }));
          this.props.updateLnAData({
            viewClicked: false,
          });
        }
        break;
      case "BusinessUnitAllTeamReport":
        if (e.itemData) {
          this.setState((prevState) => ({
            ...prevState,
            businessUnit: e.itemData.Id,
            subPnLData: [],
            teamMemberData: [],
            teamMember: [],
            subBusinessUnit: null,
          }));
          setTimeout(() => {
            try {
              document
                .getElementById("teamMemberAllTeamReport")
                .ej2_instances[0].refresh();
            } catch (err) { }
          }, 1000);
          this.getMasterDropdownData("subbusinesspnl", e.itemData.Id);
          this.getMasterDropdownData("teamMemberDropdown", e.itemData.Id);
        } else if (e.value === null) {
          this.setState((prevState) => ({
            ...prevState,
            teamMemberData: teamReportTeamMemberData,
          }));
        } else {
          this.setState((prevState) => ({
            ...prevState,
            subPnLData: subBusinessUnitData,
            teamMember: [],
            subBusinessUnit: null,
          }));

          if (subBusinessUnit > 0) {
            this.getMasterDropdownData(
              "teamMemberDropdown",
              0,
              subBusinessUnit
            );
          } else {
            this.getMasterDropdownData("teamMemberDropdown", 0, 0);
          }
        }
        break;
      case "SubBusinessUnitAllTeamReport":
        if (e.itemData) {
          this.setState((prevState) => ({
            ...prevState,
            subBusinessUnit: e.itemData.Id,
            teamMemberData: [],
            teamMember: [],
          }));

          if (e.itemData.Id > 0) {
            this.getMasterDropdownData(
              "teamMemberDropdown",
              businessUnit,
              e.itemData.Id
            );
          }
        } else {
          this.setState((prevState) => ({
            ...prevState,
            teamMemberData: [],
            teamMember: [],
          }));

          if (businessUnit > 0) {
            this.getMasterDropdownData("teamMemberDropdown", businessUnit, 0);
          } else {
            this.getMasterDropdownData("teamMemberDropdown", 0, 0);
          }
        }
        break;
      case "teamMemberAllTeamReport":
        if (e.value) {
          if (viewReport === "1") {
            this.setState({
              teamMember: e.itemData.Id,
            });
            this.props.updateLnAData({ selectedTeamMemberId: e.itemData.Id });
          } else {
            this.setState((prevState) => ({
              ...prevState,
              teamMember: e.value,
            }));
            this.props.updateLnAData({
              viewClicked: false,
              starting: false,
              // selectedTeamMemberId: e.value
            });
          }
        }
        break;
      case "teamMemberAllTeamReportOnlyTeamId":
        this.setState({
          teamMember: [e],
        });
        break;
      case "teamMemberAuthUser":
        if (e) {
          this.setState((prevState) => ({
            ...prevState,
            teamId: e,
          }));
        }
        break;
      case "teamMemberSingleSelect":
        if (e.itemData) {
          this.setState((prevState) => ({
            ...prevState,
            teamId: e.itemData.Id,
          }));
          this.props.updateLnAData({
            viewClicked: false,
            selectedTeamMemberId: e.itemData.Id,
          });
          this.getYearData();
        }
        break;
      case "effectiveStartDate":
        if (e.value) {
          this.setState((prevState) => ({
            ...prevState,
            effectiveStartDate: e.value,
            effectiveEndDate: null,
          }));
          this.props.updateLnAData({
            viewClicked: false,
          });
        }
        break;
      case "effectiveEndDate":
        if (e.value) {
          this.setState((prevState) => ({
            ...prevState,
            effectiveEndDate: e.value,
          }));
          this.props.updateLnAData({
            viewClicked: false,
          });
        }
        break;
      case "startMonth":
        if (e.value) {
          // this.setState(prevState => ({
          //     ...prevState,
          //     startMonth: e.value,
          // }))
          this.props.updateLnAData({
            viewClicked: false,
            startMonth: getMySqlDateFormat(e.value).split(" ")[0],
          });
        }
        break;
      case "endMonth":
        if (e.value) {
          // this.setState(prevState => ({
          //     ...prevState,
          //     endMonth: e.value,
          // }))
          this.props.updateLnAData({
            viewClicked: false,
            endMonth: getMySqlDateFormat(e.value).split(" ")[0],
          });
        }
        break;

      case "startMonthMonthlyReport":
        if (e.value) {
          // this.setState(prevState => ({
          //     ...prevState,
          //     endMonth: e.value,
          // }))
          this.props.updateLnAData({
            viewClicked: false,
            startMonthMonthlyReport: getMySqlDateFormat(e.value).split(" ")[0],
          });
        }
        break;
      case "attendanceType":
        if (e.value) {
          this.setState((prevState) => ({
            ...prevState,
            attendanceList: e.value,
          }));
        }
        break;
      case "leaveType":
        if (e.value) {
          this.setState((prevState) => ({
            ...prevState,
            leaveList: e.value,
          }));
        }
        break;
      case "teamMemberReportDataChange":
        if (e) {
          this.setState((prevState) => ({
            ...prevState,
            teamMemberReportData: e,
          }));
        }
        break;
      case "teamMemberReportDataChangeFirst": // NOSONAR
        if (e) {
          this.setState((prevState) => ({
            ...prevState,
            teamMemberReportData: e,
          }));
        }
        break;
      case "defaultState":
        this.setState((prevState) => ({
          ...prevState,
          businessUnit: null,
          subBusinessUnit: null,
          teamMember: [],
        }));
        break;

      default:
        break;
    }
  };
//Resetting the filter when we are going to attendance report
  resetFilters = () => {
    this.setState((prevState) => ({
      ...prevState,
      teamMember: [],
      teamMemberSendData: [],
      businessUnit: null,
      subBusinessUnit: null,
    }));
  }

  getYearData = async () => {
    if (this.state.viewReport === "1") return
    const { teamId } = this.state;
    const { fyId } = this.props;
    let lnaData = await getLnAData(teamId, "FY");
    if (lnaData && lnaData.financialYearData.length > 0) {
      lnaData.financialYearData.find((ele) => {
        return ele.CurrYear === 1 || ele.CurrYear === true;
      });
      const obj = lnaData.financialYearData;
      if (obj) {
        this.props.updateLnAData({
          fyData: fyId,
          currentAndFinancialYear: obj,
        });
        return obj.Id;
      } else {
        this.props.updateLnAData({ fyData: fyId });
        return fyId;
      }
    }
  };

  getMasterDropdownData = (type, buId = 0, subId = 0) => {
    // NOSONAR
    const { subBusinessUnitData, teamReportTeamMemberData, countryOfWork } =
      this.state;
    try {
      if (type === "subbusinesspnl") {
        this.setState((prevState) => ({
          ...prevState,
          subPnLData: subBusinessUnitData.filter((item) => +item.buid === buId),
        }));
      }

      if (type === "teamMemberDropdown") {
        this.setState((prevState) => ({
          ...prevState,
          teamMemberData:
            !buId && !subId
              ? teamReportTeamMemberData
              : !buId && subId
                ? teamReportTeamMemberData.filter(
                  (item) => +item.subBuid === subId
                ) // NOSONAR
                : subId
                  ? teamReportTeamMemberData.filter(
                    (item) => +item.buid === buId && +item.subBuid === subId
                  ) // NOSONAR
                  : teamReportTeamMemberData.filter((item) => +item.buid === buId),
          teamMemberSendData:
            !buId && !subId
              ? teamReportTeamMemberData.map((item) => item.Id)
              : !buId && subId
                ? teamReportTeamMemberData.map((item) =>
                  +item.subBuid === subId ? item.Id : []
                ) // NOSONAR
                : subId
                  ? teamReportTeamMemberData.map((item) =>
                    +item.buid === buId && +item.subBuid === subId ? item.Id : []
                  ) // NOSONAR
                  : buId
                    ? teamReportTeamMemberData.map((item) =>
                      +item.buid === buId ? item.Id : []
                    ) // NOSONAR
                    : [], // NOSONAR
        }));
      }
      if (type === "countryTypeDropdown") {
        this.setState((prevState) => ({
          ...prevState,
          teamMemberData: teamReportTeamMemberData.filter(
            (item) =>
              +item.userCountryID === countryOfWork || countryOfWork === 0
          ),
          teamMemberSendData: teamReportTeamMemberData.filter(
            (item) =>
              +item.userCountryID === countryOfWork || countryOfWork === 0
          ), // NOSONAR
        }));
      }

      this.props.updateLnAData({
        viewClicked: false,
      });
    } catch (err) {
      console.log("allTeamMemberReport", err);
    }
  };

  getMasterData = async (type) => {
    const { authUser } = this.props;
    if (type === "lnaReport") {
      const LNAReportMonths = await getLnAData(authUser, "FC");
      if (LNAReportMonths) {
        this.props.updateLnAData({ ...LNAReportMonths })
        this.setState((prevState) => ({
          ...prevState,
          monthId: LNAReportMonths.lnaReportMonths[0]
            ? LNAReportMonths.lnaReportMonths[0].Id
            : "",
          monthName: LNAReportMonths.lnaReportMonths[0]
            ? LNAReportMonths.lnaReportMonths[0].Name
            : "",
          lnaReportMonth: LNAReportMonths.lnaReportMonths,
          showDownloadMyReportButton: LNAReportMonths.downloadMyReport[0].Name,
          showdownloadLeaveBalanceButton:
            LNAReportMonths.downloadLeaveBalance[0].Name,
          showdownloadLeaveBalanceButtonAllTM:
            LNAReportMonths.downloadLeaveBalanceAllTM[0].Name,
          minDate: LNAReportMonths.minDate,
          maxDate: LNAReportMonths.maxDate
        }));
      }
    }

    if (type === "dropDownData") {
      const LNAReportDropdownMaster = await getMonthlyReportDropdownData(
        authUser
      );

      if (LNAReportDropdownMaster) {
        const {
          countryData,
          businessUnitData,
          subBusinessUnitData,
          teamMemberData,
          attendanceList,
          leaveList,
          effectiveStartDate,
          effectiveEndDate,
          attendanceListData,
          leaveListData,
        } = LNAReportDropdownMaster;
        this.props.updateLnAData({ teamMemberYearlyDropDown: teamMemberData });
        this.setState((prevState) => ({
          ...prevState,
          countryOfWork: this.props.baseCountry,
          // countryOfWork: this.props.holidayCountryData,
          subPnLData: subBusinessUnitData,
          teamMemberData: teamMemberData,
          attendanceList: attendanceList,
          leaveList: leaveList,
          effectiveStartDate: effectiveStartDate,
          effectiveEndDate: effectiveEndDate,
          attendanceListData: attendanceListData,
          leaveListData: leaveListData,
          countryData,
          businessUnitData,
          subBusinessUnitData,
          teamReportTeamMemberData: teamMemberData,
          teamReportAttendanceList: attendanceList,
          teamReportLeaveList: leaveList,
          teamReportEffectiveStartDate: effectiveStartDate,
          teamReportEffectiveEndDate: effectiveEndDate,
          teamReportAttendanceListData: attendanceListData,
          teamReportLeaveListData: leaveListData,
        }));
      }
    }
  };

  renderSwitch = () => {
    const { categoryName, weekOrMonth } = this.props;
    const {
      height,
      content,
      viewReport,
      monthId,
      teamId,
      monthName,
      teamMemberReportData,
      teamMemberSendData,
      showDownloadMyReportButton,
      showdownloadLeaveBalanceButton,
      showdownloadLeaveBalanceButtonAllTM
    } = this.state;

    switch (categoryName.toString()) {
      case VIEWDAILY:
        return (
          <>
            <div className="col-12 col-xs-12 col-sm-12 col-lg-12 col-md-12">
              {weekOrMonth === "Weekly" ? (
                <WeeklyReport
                  changeViewReport={this.changeViewReport}
                  type={"weeklyReport"}
                  inputChange={this.inputChangeHandler}
                  getMasterData={this.getMasterData}
                  monthId={monthId}
                  month={monthName}
                  teamId={teamId}
                  startDate={
                    getMySqlDateFormat(this.state.startMonth).split(" ")[0]
                  }
                  categoryName={categoryName.toString()}
                  showDownloadMyReportButton={showDownloadMyReportButton}
                  getFullUserInfo={this.getFullUserInfo}
                />
              ) : (
                <WeeklyReport1
                  changeViewReport={this.changeViewReport}
                  type={"weeklyReport"}
                  inputChange={this.inputChangeHandler}
                  getMasterData={this.getMasterData}
                  monthId={monthId}
                  month={monthName}
                  teamId={teamId}
                  startDate={
                    getMySqlDateFormat(this.state.startMonth).split(" ")[0]
                  }
                  categoryName={categoryName.toString()}
                  showDownloadMyReportButton={showDownloadMyReportButton}
                  getFullUserInfo={this.getFullUserInfo}
                />
              )}
            </div>
          </>
        );

      case VIEWMONTHLY:
        return (
          <div
            className="col-12 col-xs-12 col-sm-12 col-lg-12 col-md-12"
            style={{ height: height, backgroundColor: "#fff" }}
          >
            <NotificationContainer />
            <>
              {viewReport === "1" && (
                <WeeklyReport
                  changeViewReport={this.changeViewReport}
                  type={"weeklyReport"}
                  inputChange={this.inputChangeHandler}
                  getMasterData={this.getMasterData}
                  monthId={monthId}
                  month={monthName}
                  teamId={teamId}
                />
              )}
              {viewReport === "2" && (
                <AllTeamMemberReport
                  changeViewReport={this.changeViewReport}
                  type={"monthlyReport"}
                  inputChange={this.inputChangeHandler}
                  getMasterData={this.getMasterData}
                  teamMemberReportData={teamMemberReportData}
                  parentState={this.state}
                  from={"notyearly"}
                  viewReport={viewReport}
                  categoryName={categoryName.toString()}
                  updateDefaultTeamMember={this.updateDefaultTeamMember}
                  teamMemberSendData={teamMemberSendData}
                  showdownloadLeaveBalanceButton={showdownloadLeaveBalanceButton}
                />
              )}
            </>
          </div>
        );

      case ATTENDANCEHRREPORT:
        return (
          <div
            className="col-12 col-xs-12 col-sm-12 col-lg-12 col-md-12 pl-md pr-md"
            style={{ height: height, backgroundColor: "#fff" }}
          >
            <LeavesHrReport
              content={content}
              changeViewReport={this.changeViewReport}
              type={"hrReport"}
              inputChangeHandler={this.inputChangeHandler}
            />
          </div>
        );
      case VIEWLEAVEBALACE:
        return (
          // <div className="col-12 col-xs-12 col-sm-12 col-lg-12 col-md-12 pl-md pr-md" style={{ height: height, backgroundColor: "#fff" }}>
          <LeaveBalanceGrid
            getFullUserInfo={this.getFullUserInfo}
            parentState={this.state}
            inputChange={this.inputChangeHandler}
            getParentMasterData={this.getMasterData}
            showdownloadLeaveBalanceButton={showdownloadLeaveBalanceButton}
            showdownloadLeaveBalanceButtonAllTM={showdownloadLeaveBalanceButtonAllTM}
          />
          // </div>
        );
      case VIEWYEARLY:
        let apiData = {
          startDate: getMySqlDateFormat(this.state.startMonth).split(" ")[0],
          endDate: getMySqlDateFormat(this.state.endMonth).split(" ")[0],
          id: this.state.teamId,
        };
        return (
          <div
            className="col-12 col-xs-12 col-sm-12 col-lg-12 col-md-12"
            style={{ height: height, backgroundColor: "#fff" }}
          >
            <NotificationContainer />
            <>
              <AllTeamMemberReport
                changeViewReport={this.changeViewReport}
                type={"yearlyReport"}
                inputChange={this.inputChangeHandler}
                getMasterData={this.getMasterData}
                teamMemberReportData={teamMemberReportData}
                parentState={this.state}
                from={"yearly"}
                categoryName={categoryName.toString()}
                apiData={apiData}
                updateDefaultTeamMember={this.updateDefaultTeamMember}
                showdownloadLeaveBalanceButton={showdownloadLeaveBalanceButton}
              />
            </>
          </div>
        );
      default:
        return <div>In Progress</div>;
    }
  };
  renderReportSwitch = () => {
    const { categoryName, weekOrMonth } = this.props;
    const {
      viewReport,
      monthId,
      teamId,
      monthName,
      teamMemberReportData,
      teamMemberSendData,
      showDownloadMyReportButton,
      showdownloadLeaveBalanceButton
    } = this.state;

    switch (categoryName.toString()) {
      case VIEWDAILY:
        return (
          <>
            <div
              className="col-12 col-xs-12 col-sm-12 col-lg-12 col-md-12"
              style={{ backgroundColor: "#fff" }}
            >
              <div className="flex-1">
                <div
                  className="col-lg-12 col-md-12 col-sm-12 col-xs-12 leave-header pr-md"
                  style={{ backgroundColor: "#FFFFFF" }}
                >
                  {/* {weekOrMonth === "Weekly" && 
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 pt-md pb-md "style={{background:'white'}}>
                <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 leave-header p-sm"style={{borderTop:'1px solid #DBDBDD',borderBottom:'1px solid #DBDBDD'}}>
                    <AttendenceBreakup monthId={this.props.startMonthMonthlyReport && this.props.startMonthMonthlyReport.split(' ')[0]} authUser={this.props.authUser} teamId={teamId} />
                </div>
            </div>} */}
                
                  {weekOrMonth === "Weekly" ? (
                    <WeeklyReport
                      changeViewReport={this.changeViewReport}
                      type={"weeklyReport"}
                      inputChange={this.inputChangeHandler}
                      getMasterData={this.getMasterData}
                      monthId={monthId}
                      month={monthName}
                      teamId={teamId}
                      startDate={
                        getMySqlDateFormat(this.state.startMonth).split(" ")[0]
                      }
                      categoryName={categoryName.toString()}
                      showDownloadMyReportButton={showDownloadMyReportButton}
                      getFullUserInfo={this.getFullUserInfo}
                      viewReport={viewReport}
                    />
                  ) : (
                    <WeeklyReport1
                      changeViewReport={this.changeViewReport}
                      type={"weeklyReport"}
                      inputChange={this.inputChangeHandler}
                      getMasterData={this.getMasterData}
                      monthId={monthId}
                      month={monthName}
                      teamId={teamId}
                      startDate={
                        getMySqlDateFormat(this.state.startMonth).split(" ")[0]
                      }
                      categoryName={categoryName.toString()}
                      showDownloadMyReportButton={showDownloadMyReportButton}
                      getFullUserInfo={this.getFullUserInfo}
                      viewReport={viewReport}
                    />
                  )}
                  {
                    this.props.startMonthMonthlyReport && teamId &&
                    <ChartReport
                      startMonthMonthlyReport={this.props.startMonthMonthlyReport}
                      teamId={teamId}
                      categoryName={categoryName}
                      reloadChart={this.props.reloadChart}
                      updateLnAData={this.props.updateLnAData}
                      isLoading={this.props.isLoading}
                    />
                  }

                </div>
              </div>
            </div>
          </>
        );

      case VIEWMONTHLY:
        return (
          <div className="col-12 col-xs-12 col-sm-12 col-lg-12 col-md-12" style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
            <NotificationContainer />
            <>
              {viewReport === "1" && (
                <WeeklyReport
                  changeViewReport={this.changeViewReport}
                  type={"weeklyReport"}
                  inputChange={this.inputChangeHandler}
                  getMasterData={this.getMasterData}
                  monthId={monthId}
                  month={monthName}
                  teamId={teamId}
                />
              )}
              {viewReport === "2" && (
                <AllTeamMemberReport
                  changeViewReport={this.changeViewReport}
                  type={"monthlyReport"}
                  inputChange={this.inputChangeHandler}
                  getMasterData={this.getMasterData}
                  teamMemberReportData={teamMemberReportData}
                  parentState={this.state}
                  from={"notyearly"}
                  viewReport={viewReport}
                  categoryName={categoryName.toString()}
                  updateDefaultTeamMember={this.updateDefaultTeamMember}
                  teamMemberSendData={teamMemberSendData}
                  showdownloadLeaveBalanceButton={showdownloadLeaveBalanceButton}
                />
              )}
            </>
          </div>
        );

      case VIEWYEARLY:
        let apiData = {
          startDate: getMySqlDateFormat(this.state.startMonth).split(" ")[0],
          endDate: getMySqlDateFormat(this.state.endMonth).split(" ")[0],
          id: this.state.teamId,
        };
        return (
          <div className="col-12 col-xs-12 col-sm-12 col-lg-12 col-md-12" style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
            <NotificationContainer />
            <>
              <AllTeamMemberReport
                changeViewReport={this.changeViewReport}
                type={"yearlyReport"}
                inputChange={this.inputChangeHandler}
                getMasterData={this.getMasterData}
                teamMemberReportData={teamMemberReportData}
                parentState={this.state}
                from={"yearly"}
                categoryName={categoryName.toString()}
                apiData={apiData}
                updateDefaultTeamMember={this.updateDefaultTeamMember}
                viewReport={viewReport}
                showdownloadLeaveBalanceButton={showdownloadLeaveBalanceButton}
              />
            </>
          </div>
        );
      default:
        return <div>In Progress</div>;
    }
  };

  render() {
    const { categoryName } = this.props;
    const { viewReport, height } = this.state;
    return (
      <>
        {categoryName.toString() === VIEWLEAVEBALACE ||
          categoryName.toString() === ATTENDANCEHRREPORT ? (
          <>
            {(categoryName.toString() === VIEWDAILY ||
              categoryName.toString() === VIEWMONTHLY ||
              categoryName.toString() === VIEWYEARLY ||
              categoryName.toString() === ATTENDANCEHRREPORT) && (
                <ReportFilter
                  saveFilterInput={this.saveFilterInput}
                  viewReport={viewReport}
                  inputChange={this.inputChangeHandler}
                  changeTeamMemberIdsOnReportScreenChange={
                    this.changeTeamMemberIdsOnReportScreenChange
                  }
                  getMasterData={this.getMasterData}
                  parentState={this.state}
                  from={
                    categoryName.toString() === VIEWYEARLY
                      ? "yearly"
                      : "notyearly"
                  }
                  updateDefaultTeamMember={this.updateDefaultTeamMember}
                  categoryName={categoryName.toString()}
                />
              )}
            {this.renderSwitch()}
          </>
        ) : (
          <div
            style={{
              borderRadius: "4px",
              border: "1px solid #DBDBDD",
              boxSizing: "border-box",
              height: height - 95,
              background: "#FFF",
              margin: "0px 16px 16px 16px",
              display: 'flex',
              flexDirection: 'column',
            }}
          >
              <ReportTabs categoryName={categoryName.toString()} resetFilters={this.resetFilters} />

            {(categoryName.toString() === VIEWDAILY ||
              categoryName.toString() === VIEWMONTHLY ||
              categoryName.toString() === VIEWYEARLY) && (
                <ReportFilter
                  saveFilterInput={this.saveFilterInput}
                  viewReport={viewReport}
                  inputChange={this.inputChangeHandler}
                  changeTeamMemberIdsOnReportScreenChange={
                    this.changeTeamMemberIdsOnReportScreenChange
                  }
                  getMasterData={this.getMasterData}
                  parentState={this.state}
                  from={
                    categoryName.toString() === VIEWYEARLY
                      ? "yearly"
                      : "notyearly"
                  }
                  updateDefaultTeamMember={this.updateDefaultTeamMember}
                  categoryName={categoryName.toString()}
                />
              )}
            {this.renderReportSwitch()}
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = ({
  auth,
  lnareducer,
  candidateReducer,
  meetingmaster,
  usersettingreducer,
  meetingcategory,
}) => {
  const { authUser } = auth;

  const {
    officeCountry,
    officeLocation,
    applyForData,
    viewClicked,
    selectedTeamMemberId,
    weekOrMonth,
    fyId,
    fyData,
    currentAndFinancialYear,
    holidayCountryData,
    startMonth,
    startMonthMonthlyReport,
    isLoading,
    reloadChart
  } = lnareducer;

  const { othersCheckAvailablity } = meetingmaster;

  const { entityMaster, countryMaster } = candidateReducer;
  const { baseCountry } = usersettingreducer;
  const { categoryName } = meetingcategory;

  return {
    authUser,
    viewClicked,
    countryMaster,
    officeCountry,
    officeLocation,
    entityMaster,
    othersCheckAvailablity,
    applyForData,
    selectedTeamMemberId,
    baseCountry,
    weekOrMonth,
    fyId,
    fyData,
    currentAndFinancialYear, categoryName, holidayCountryData,
    startMonth,
    startMonthMonthlyReport,
    isLoading,
    reloadChart
  };
};

export default connect(mapStateToProps, { updateLnAData, saveWeekOffSwap })(
  TeamMonthReportContainer
);
