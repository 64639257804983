/*
 * Author Somesh Dwivedi
 * Date  06-03-2024
 * @description This component is used to render the grid header and grid for all type of leaves.
 * */

//Importing required modules and components
import React, { useEffect, useState } from 'react'
import lnaservice from '../../../services/lna/lnaservice';
import ProgressBar from '../../../components/progressbar/progressbar';
import LeaveGrid from './leavegrid';
import EmptyLeaveGridData from './emptyleavegriddata';
import { connect } from 'react-redux';
import LeaveGridHeader from './leavegridheader';

const LeaveGridParent = ({ activeTab, secActiveTab, authUser }) => {
  //useStates

  //State to mange the loading(used for api call)
  const [isLoading, setIsLoading] = useState(false);
  //State to manage the laeve data used in grid
  const [leaveData, setLeaveData] = useState([]);
  //State to store the leave data used when we are reseting filter
  const [originalLeaveData, setOriginalLeavData] = useState([]);

  //User definrd functions

  //This function called when the data of grid changes
  const dataSourceChanged = (pComponentId) => {
    try {
      setTimeout(() => {
        if (document.getElementById(pComponentId) !== null) { document.getElementById(pComponentId).ej2_instances[0].refresh(); }
      });
    } catch (error) { console.log(error); }
  };


  //Use effect

  useEffect(() => {
    const getMasterData = async () => {
      try {
        setIsLoading(true)
        let onLeaveData = await lnaservice.getOnLeaveDataNew(authUser, secActiveTab, activeTab);
        onLeaveData = onLeaveData.Result.map(ele => ({ ...ele, leaveStartDate: new Date(ele.leaveStartDate) }))
        setLeaveData(onLeaveData);
        setOriginalLeavData(onLeaveData);
      } catch (e) {


      } finally {
        setIsLoading(false);
      }
    }
    getMasterData();
  }, [activeTab, secActiveTab, authUser]);

  return (
    <>
      {/* Rendering the header of leave grid */}
      <LeaveGridHeader
        dataSourceChanged={dataSourceChanged}
        leaveData={leaveData}
        originalLeaveData={originalLeaveData}
        setLeaveData={setLeaveData}
        secActiveTab={secActiveTab}
        activeTab={activeTab}
      />
      <div style={{ height: 'calc(100vh - 370px)' }}>
        {
          !isLoading?
          <>
            {
              leaveData && leaveData.length > 0 ?
                // Rendering Leave grid
                <LeaveGrid leaveData={leaveData} dataSourceChanged={dataSourceChanged} activeTab={activeTab} /> :
                // Rendering empty page component when there is no data avaialable
                <EmptyLeaveGridData />
            }
            </> : <ProgressBar />
        }

      </div>
    </>

  )

}

const mapStateToProps = ({ auth }) => {
  const { authUser } = auth;
  return { authUser };
};

export default connect(mapStateToProps, {
})(LeaveGridParent);
