
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import LnAService from '../../../../services/lna/lnaservice';
import { updateLnAData } from '../../../../redux/actions/lna/lnaaction';
import ProgressBar from '../../../progressbar/progressbar';
import { Button } from "@material-ui/core";
import ErrorTabMessage from '../../../errormessage/errormessage';
import { SPECIAL_ATTENDANCE, APPLYLEAVE } from '../../../../redux/constant/meetingcategoryconstant'
import { pleaseTypeSearchText } from '../../../../assets/constants/constants';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Group,
  Inject
} from "@syncfusion/ej2-react-grids";
import { getYearData } from '../../../../app/other/commonlayoutfunction';
const SAMPLECSS = `
th {
  top: 0;
  position: sticky;
}
.statusData{
  // height:290px !important;
  height:334px !important;
  overflow-y:auto;
}

#leaveBalance .e-headertext {
  margin-left:5px;
  line-height:18px;
}
.e-dropdown-popup ul {
  padding:0px !important
  
}
tr.e-columnheader{
  height:40px !important
}

.e-grid .e-gridheader .e-sortfilter .e-headercelldiv{
  padding:0 !important
}
.attendaceReportYearlyLeaveBalacePopup .e-content {
    height: calc(100vh - 325px) !important;
}
`

const LeavesBalance = (props) => {
  const { authUser, fyId, user, type, categoryName, from, YearlyfyId, applyFor, parent = '' } = props;
  const [leaveBalanceData, setLeaveBalanceData] = useState([])
  const [leaveStatusData, setLeaveStatusData] = useState([]) //NOSONAR
  const [leaveTransferData, setTransferStatusData] = useState([]) //NOSONAR
  const [isLoading, setIsLoading] = useState(true)
  const [allHeight, setAllHeight] = React.useState({
    containerHeight: null
  });
 useEffect(() => {
    if (from === 'yearly') {
      setIsLoading(true)
      getMasterData()
    }
  }, [YearlyfyId]) 


  useEffect(() => {
    if (props.type === "LeaveApply" ) {
      setIsLoading(true)
      getMasterData();
    }
  }, [applyFor])
 useEffect(() => {
  if (
    (props.leaveBalanceStatus === 'updated' ||
      props.compOffClusterFlag === 'updated' ||
      props.compOffClusterFlag === 'saved' ||
      props.leaveTransferFlag === 'updated' 
      ) &&
    type !== 'approvalsubmit'
  ) {
      getMasterData();

      props.updateLnAData({ leaveBalanceStatus: "", compOffClusterFlag: '', leaveTransferFlag: '' })
    }
  }, [props.leaveBalanceStatus === 'updated', props.compOffClusterFlag === 'updated', props.compOffClusterFlag === 'saved', props.leaveTransferFlag === 'updated', categoryName === SPECIAL_ATTENDANCE, categoryName === APPLYLEAVE])

  const getMasterData = async () => {
    const userID = from === 'yearly' ? props.teamId : user
    const yearId = from === 'yearly' ? YearlyfyId : fyId
    if (yearId) {
      const leaveTypeMater = await LnAService.getLeaveApplyMasterData("All", userID, fyId);
      const leaveTypes = leaveTypeMater.Result?.filter((t) => { return t.type === "LeaveType" });
      const onLeaveBalanceData = await LnAService.getLeaveBalanceData
        (userID ? userID : (applyFor ? applyFor : authUser),
          'GETLEAVEMASTER', yearId, 0, 1)

      const leaveBalanceDataList = onLeaveBalanceData.Result

      let leaveBalanceDataListTemp = leaveBalanceDataList.map((data) => (
        { ...data, leaveName: data.leaveSymbol + " - " + data.leaveName }
      ))
      leaveBalanceDataListTemp = await leaveBalanceDataListTemp.filter((ele) => {
        return ele.leaveBifurcation !== null;
      })
      setLeaveBalanceData(leaveBalanceDataListTemp)
      let tempData = {
        leaveTypeList: leaveBalanceDataListTemp
      }
      if (leaveTypes && leaveTypes.length > 0) tempData.leaveTypeData = leaveTypes
      props.updateLnAData({ ...tempData })
      setIsLoading(false)
      setTimeout(() => {
        try {
          const val = document.getElementById("leaveBalanceGrid");
          val && val.ej2_instances[0].refresh();
        } catch (err) {
          console.log(err);
        }
      }, 800);
    }
  }
  useEffect(() => {
    if (fyId) {
      getMasterData()
    }
  }, [fyId])

  useEffect(() => {
    if (!fyId) {
      getYearData(authUser).then(result => {
        props.updateLnAData({ fyId: result })
      })
        .catch(err => {
          console.log('err===', err)
        });
    }
  }, [])


  useEffect(() => {
    window.addEventListener("resize", updateDimensions()); //NOSONAR
  }, []);

  const updateDimensions = () => {
    const containerHeight = window.innerHeight - 306;
    setAllHeight(prevState => ({
      ...prevState,
      containerHeight: containerHeight
    }))
  }

  const leaveNameTemplate = (args) => {
    return <TooltipComponent position="BottomCenter" className="pointer-cursor" isSticky={false}
      content={args.leaveName} cssClass='customtooltip'>
      <div style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', fontSize: "14px" }}>{args.leaveName}</div>
    </TooltipComponent>
  }

  const groupOptions = { showGroupedColumn: false, columns: ['leaveBifurcation'], showDropArea: false }
  const sortingOptions = { columns: [{ field: 'leaveBifurcation', direction: 'Descending' }] };
  return (
    <div >

      <style>{SAMPLECSS}</style>

      {!isLoading && leaveBalanceData && <div className={parent === 'allTeamMembers' ? 'attendaceReportYearlyLeaveBalacePopup leaveTypes' : 'leaveTypes'} style={{ height: parent === 'allTeamMembers' ? 'calc(100vh - 288px)' : '' }}>
        <GridComponent
          id='leaveBalanceGrid'
          dataSource={leaveBalanceData}
          rowHeight={40}
          allowGrouping={true}
          allowSorting={false}
          sortSettings={sortingOptions}
          groupSettings={groupOptions}
          height={from === 'yearly' ? window.innerHeight < 658 ? 324 : allHeight.containerHeight - 50 : (type === "approvalsubmit" || type === "compOffApprovalSubmit") ? allHeight.containerHeight - 20 : allHeight.containerHeight}
        //style={{ width: '100%', borderLeft: "0px", borderRight: "0px", borderBottom: "0px", fontSize: ".8rem", overflowY: 'hidden' }}
        >
          <ColumnsDirective>
            <ColumnDirective template={leaveNameTemplate} field='leaveName' width="88px" headerText='Leave Type' textAlign="Left" />
            <ColumnDirective field='leaveBifurcation' width="60" headerText='Leave' textAlign="Center" />
            <ColumnDirective field='openingBalance' width="40px" textAlign="Center" headerText='Eligible' />
            <ColumnDirective field='consumed' width="80px" textAlign="Center" headerText='Utilized / Approved' />
            <ColumnDirective field='applied' width="40px" textAlign="Center" headerText='Applied' />
            <ColumnDirective field='availableBalance' width="60px" headerText='Available Balance' textAlign="Center" />
          </ColumnsDirective>
          <Inject services={[Group]} />
        </GridComponent>
      </div>}

      {isLoading && (
        <div
          style={{
            zIndex: 999999,
            position: "absolute",
            marginTop: from === 'yearly' ? "50%" : "20%",
            marginLeft: "49%",
          }}
        >
          <ProgressBar />
        </div>
      )}
      {(leaveBalanceData.length === 0 && !isLoading && props.type !== 'approvalsubmit') && (
        <ErrorTabMessage message="No Data Available" />
      )}
      {
        from === 'yearly' &&
        <div style={{ display: 'flex', justifyContent: 'flex-end', flexDirection: 'column', height: isLoading ? 686 : 60 }}>
          <div style={{ borderTop: '1px solid #f2f2f2', height: 60, width: '100%' }}>
            <div style={{ display: 'flex', justifyContent: 'flex-end', marginRight: 3, marginTop: 10 }}>


              <div className='popupSaveButton'
              >

                <Button
                  style={{ width: from === 'yearly' && window.innerHeight < 700 ? 75 : "90px", height: (from === 'yearly' && window.innerHeight < 700) && 32 }}
                  className="remove-button-shadow lna-cancel-button"
                  variant="contained"
                  onClick={() => props.onCancel()}
                >
                  Close
                </Button>
              </div>
            </div>

          </div>
        </div>

      }
    </div>
  )
}

LeavesBalance.propTypes = {
  //   second: PropTypes.third
}

const mapStateToProps = ({ meetingcategory, auth, meetingmaster, lnareducer }) => {
  const { authUser } = auth;
  const { categoryName } = meetingcategory;
  const { leaveApplyStatus, leaveBalanceStatus, compOffClusterFlag, leaveTransferFlag, updateFinancialYear, fyId, applyFor } = lnareducer;
  const { currentFinancialYearId } = meetingmaster;
  return { authUser, currentFinancialYearId, leaveApplyStatus, leaveBalanceStatus, categoryName, compOffClusterFlag, leaveTransferFlag, updateFinancialYear, fyId, applyFor }
}
export default connect(mapStateToProps, { updateLnAData })(LeavesBalance)