import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import ComboBox from '../../utilities/comboboxcomponent'
import { pleaseSelectUserName, pleaseSelectApplication } from '../../assets/constants/constants'
import { FormValidator } from '@syncfusion/ej2-react-inputs'
import ProgressBar from '../progressbar/progressbar'
import { connect } from 'react-redux'
import { getTMLCUsers } from '../../app/other/commonlayoutfunction.js'
import { addEditEmailMapping, emailMappingReduxUpdate } from '../../redux/actions/admin/adminaction';
import { EMAIL_MAPPING_MASTER } from '../../redux/constant/meetingcategoryconstant.js'
import adminservices, { getAdminEmailMappingData } from '../../services/adminservices.js'
import { Query } from '@syncfusion/ej2-data';
import {
  MultiSelectComponent,
  CheckBoxSelection,
  Inject
} from "@syncfusion/ej2-react-dropdowns";
import { refreshMultiSelect } from '../../app/other/commonfunction.js'

const EmailMapping = ({ state: stateData, addEditEmailMapping: saveEmailMapping, handleSaveRef, emailMappingReduxUpdate, loading, authUser, emailMappingStatus, othersCheckAvailablity }) => { //NOSONAR

  const { editItemId, masterId } = stateData
  const [state, setState] = useState({
    notifyTo: "",
    user: "",
    applicationId: ""
  })
  const [userData, setUserData] = useState([])
  const [applicationDataSource, setApplicationDataSource] = useState([])

  useEffect(() => {
    emailMappingReduxUpdate({ loading: true })
    setTimeout(() => {
      getData()
      editItemId != null && getEmailMappingDetails()
    }, 1000)
    initialLoad()
  }, [])
  useEffect(() => {
    emailMappingReduxUpdate({ loading: false })

  }, [emailMappingStatus])

  const onSaveData = () => {
    let options = {
      rules: {
        userErr: {
          required: [true, pleaseSelectUserName],
        },

        applicationErr: {
          required: [true, pleaseSelectApplication],
        },
      },
    }
    setTimeout(() => {
      const addFormObject = new FormValidator(
        "#email-mapping-master",
        options
      );
      if (addFormObject.validate()) {
        const payload = {
          emailMappingId: editItemId != null ? editItemId : 0,
          userId: state.user,
          notifyId: state.notifyTo,
          appId: state.applicationId,
          updatedBy:authUser
        }
        emailMappingReduxUpdate({ loading: true })
        saveEmailMapping(payload);
      }
    }, 300);

  }

  useImperativeHandle(handleSaveRef, () => ({ onSaveData }))

  const initialLoad = async () => {
    emailMappingReduxUpdate({ loading: true })
    const tmlcUsers = await getTMLCUsers(authUser)
    setUserData(tmlcUsers.othersCheckAvailablity)
    emailMappingReduxUpdate({ loading: false })
  }

  const getData = async () => {
    try {
      const result = await adminservices.getMasterAdminModuleData({ userId: authUser, type: "ALLADMINMODULE", id: 0, masterId: 0 });
      if (result !== null) {
        setApplicationDataSource(result.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getEmailMappingDetails = async () => {
    emailMappingReduxUpdate({ loading: true })
    if (masterId == EMAIL_MAPPING_MASTER) {
      setState(prevState => ({ ...prevState, tableName: "Email Mapping" }))
      let res = await getAdminEmailMappingData('Single', editItemId)
      console.log(res.result[0].notifyUserName);
      setState({
        user: res.result[0].userName,
        notifyTo: res.result[0].notifyUserName,
        applicationId: res.result[0].applicationName
      });

    }
    emailMappingReduxUpdate({ loading: false })
  }


  const handleChange = (data, type) => {
    let options = {}
    switch (type) {
      case "user":
        options = {
          rules: {
            userErr: {
              required: [true, pleaseSelectUserName],
            },
          },
        };
        break;
      case "applicationId":
        options = {
          rules: {
            applicationErr: {
              required: [true, pleaseSelectApplication],
            },
          },
        };
        break;
      default:
    }
    if (type == "notifyTo") {
      setState(prevState => ({ ...prevState, notifyTo: (state.notifyTo != '' ? (state.notifyTo + ',' + data.itemData.UserId) : (data.itemData.UserId)).toString() }));
    } else {
      setState(prevState => ({ ...prevState, [type]: typeof data.value === 'string' ? data.value.trim() : data.value }))
    }

    setTimeout(() => {
      const addFormObject = new FormValidator("#email-mapping-master", options);
      addFormObject.validate();
    }, 100);
  }
  const onFilteringUser = (args) => {
    let query = new Query();
    // frame the query based on search string with filter type.
    query = (args.text !== "") ? query.where("FullName", "startswith", args.text, true) : query;
    // pass the filter data source, filter query to updateData method.
    args.updateData(userData, query);
  }
  const onFilteringApplication = (args) => {
    let queryApp = new Query();
    // frame the queryApp based on search string with filter type.
    queryApp = (args.text !== "") ? queryApp.where("FullName", "startswith", args.text, true) : queryApp;
    // pass the filter data source, filter queryApp to updateData method.
    args.updateData(userData, queryApp);
  }

  const removeSelectedMemeber = (e) => {

    if (e.e.target.className === 'e-chips-close e-close-hooker') {
      setState(prevState => ({ ...prevState, notifyTo: '' }));
    }
    let selectTeamMemberIds = state.notifyTo.split(',');
    selectTeamMemberIds = selectTeamMemberIds.filter(el => parseInt(el) != e.itemData.UserId).join(",")
    setState(prevState => ({ ...prevState, notifyTo: selectTeamMemberIds }));
  }
  const created = (pDivId, pComponentId) => {
    try {
      refreshMultiSelect(pDivId, pComponentId);
      setTimeout(() => {
        setState(prevState => ({ ...prevState, showDropDown: true }));
        // document.getElementById(pComponentId).ej2_instances[0].refresh()
      }, 1000)
    } catch (error) {
      console.log("🚀 ~ file: timeinbusiness.js ~ line 433 ~ TimeInBusiness ~ created ~ error", error)
    }
  }

  return (
    <form id='email-mapping-master' style={{overflowY:'auto'}}>
      <ComboBox
        id="application"
        data-msg-containerid=""
        name="applicationErr"
        dataSource={applicationDataSource}
        fields={{ text: "adminModuleName", value: "Id" }}
        value={state.applicationId}
        placeholder="Module Name *"
        floatLabelType="Auto"
        cssClass="e-outline"
        onChange={(e) => handleChange(e, "applicationId")}
        htmlAttributes={{ maxlength: 100 }}
        filtering={onFilteringApplication}
        allowFiltering={true}
      />
      <ComboBox
        id="user"
        fields={{ text: "FullName", value: "UserId" }}
        value={state.user}
        dataSource={userData}
        placeholder="Team Member *"
        floatLabelType="Auto"
        cssClass="e-outline"
        data-msg-containerid="userErr"
        onChange={(e) => handleChange(e, "user")}
        filtering={onFilteringUser}
        allowFiltering={true}
      />

      <MultiSelectComponent
        id="checkboxUsr"
        name="selectTeamMemberErr"
        cssClass="e-outline smallResolutionText"
        // eslint-disable-next-line
        dataSource={othersCheckAvailablity}
        value={state.notifyTo && state.notifyTo.split(",").map(Number)}
        fields={{
          text: "nameWithDepartment",
          value: "UserId",
          groupBy: "Department"
        }}
        placeholder="Notified Team Members *"
        mode="CheckBox"
        showSelectAll={false}
        showDropDownIcon
        select={(e) => handleChange(e, "notifyTo")}
        showClearButton={true}
        filterBarPlaceholder="Notified Team Members *"
        popupHeight="350px"
        // maximumSelectionLength={5}
        removed={removeSelectedMemeber}
        created={() => created("multigroupchkUser", "checkboxUsr")}
        clear={true}
      >
        <Inject services={[CheckBoxSelection]} />
      </MultiSelectComponent>

      {
        loading && (
          <div
            className='loader-new'
          >
            <ProgressBar />
          </div>
        )
      }
    </form>
  )
}
const mapStateToProps = ({ adminreducer, auth, meetingmaster }) => { //nosonar
  const { authUser } = auth;

  const { othersCheckAvailablity } = meetingmaster;
  const { loading, emailMappingStatus } = adminreducer
  return { loading, authUser, emailMappingStatus, othersCheckAvailablity }
}

export default connect(mapStateToProps, { addEditEmailMapping, emailMappingReduxUpdate })(forwardRef(EmailMapping))