/**
 * Author By :- Prashant Waphare
 * Date : 27-05-2020
 * Description :- This file use for load saperation manager approval Tab
 */
import React, { Component } from "react";
import NotificationContainer from "../../../../../components/notification/notifycontainer";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Resignation from "../../../../../components/separation/separationmanagerapprovaltab/resignationgrid";
import Withdrawal from "../../../../../components/separation/separationmanagerapprovaltab/withdrawalgrid";
import separetionServices from "../../../../../services/entryexit/separationServices";
import { updateStatusOnBoardingResucer } from "../../../../../redux/actions/entryexit/onboardingaction";
import { RESIGNATIONMANAGERAPPROVAL } from "../../../../../redux/constant/meetingcategoryconstant";
import Progressbar from '../../../../../components/progressbar/progressbar';
import { getFormatDateForGrid } from "../../../../other/commonfunction";

class ResignationManagerApproval extends Component {
  constructor(props) {
    super(props);
    this.state = {
      resignationData: [],
      withdrawalData: [],
      changedData: false,
      noticePeriodMasterData: [],
      resigTabCount: [],
      reportingType: '',
    };
  }

  componentDidMount() {
    localStorage.removeItem('SelectedTabAction');
    this.getResignationTabData();
    this.getNoticePeriodData();
    this.getTabCount();
    const getTabActive = localStorage.getItem('SelectedTabAction');
    if (getTabActive !== null && getTabActive === 'Withdrawal') {
      this.getWithdrawalTabData();
      document.getElementById(`${getTabActive}`).style.display = "block";
    }
    if (getTabActive === null || getTabActive === 'Resignation') {
      localStorage.setItem('SelectedTabAction', 'Resignation');
      this.getResignationTabData();
      document.getElementById("Resignation").style.display = "block";
    }
  }
  // eslint-disable-next-line
  UNSAFE_componentWillReceiveProps(newProps) {
    const { changedData } = this.state;
    const { categoryName } = this.props;
    if (newProps.status === "updated" || newProps.categoryName !== categoryName) {
      this.setState({ changedData: !changedData });
      // eslint-disable-next-line
      setTimeout(() => {
        this.getResignationTabData();
        this.getTabCount();
      }, 1000);
      this.props.updateStatusOnBoardingResucer({ status: "" });
    }

    if (newProps.UpdateWithdrawlStatus === 'StatusUpdate' || newProps.UpdateResignationStatus === 'StatusUpdate') {
      this.setState({ changedData: !changedData });
      // eslint-disable-next-line
      setTimeout(() => {
        this.getTabCount();
      }, 1000);
    }
  }

  // this finction use for load tab data on click
  openTab = (evt, tabName) => {
    const data = evt;
    localStorage.setItem('SelectedTabAction', tabName);
    if (tabName === "Resignation") {
      this.getResignationTabData();
      this.getTabCount();
    }
    if (tabName === "Withdrawal") {
      this.getWithdrawalTabData();
      this.getTabCount();
    }

    let i;
    const tabcontent = document.getElementsByClassName("tabcontent");
    // eslint-disable-next-line
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }
    const onboardingtab = document.getElementsByClassName("onboardingtab");
    // eslint-disable-next-line
    for (i = 0; i < onboardingtab.length; i++) {
      onboardingtab[i].className = onboardingtab[i].className.replace(
        " active",
        ""
      );
    }
    document.getElementById(tabName).style.display = "block";
    data.currentTarget.className += " active ";
  };

  getTabCount = () => {
    const { authUser, categoryName } = this.props;
    try {
      const getResignationDetail = Promise.resolve(
        separetionServices.getResignationDetail(authUser, (categoryName === RESIGNATIONMANAGERAPPROVAL ? "mgrcount" : "pnlcount"))
      );
      getResignationDetail.then((result) => {
        if (result !== null) {
          this.setState({ resigTabCount: result });
        } else {
          this.data = "error";
        }
      });
    } catch (e) {
      // eslint-disable-next-line
      console.log(e);
    }
  };
  getNoticePeriodData = () => {
    const { authUser } = this.props;
    try {
      const getNoticePeriodMasterData = Promise.resolve(
        separetionServices.getEmployeeResignationMaster(authUser, "nps")
      );
      getNoticePeriodMasterData.then((result) => {
        if (result !== null) {
          this.setState({ noticePeriodMasterData: result });
        } else {
          this.data = "error";
        }
      });
    } catch (e) {
      // eslint-disable-next-line
      console.log(e);
    }
  };

  getResignationTabData = () => {
    const { authUser, categoryName } = this.props;
    try {
      const getResignationDetail = Promise.resolve(
        separetionServices.getResignationDetail(authUser, (categoryName === RESIGNATIONMANAGERAPPROVAL ? "mgrlist" : "pnllist"))
      );
      getResignationDetail.then((result) => {
        if (result !== null) {
          this.setState({ resignationData: getFormatDateForGrid(result) });
        } else {
          this.data = "error";
        }
      });
    } catch (e) {
      // eslint-disable-next-line
      console.log(e);
    }
  };

  getWithdrawalTabData = () => {
    const { authUser, categoryName } = this.props;
    try {
      const getWithdrawalDetail = Promise.resolve(
        separetionServices.getWithdrawalDetail(authUser, (categoryName === RESIGNATIONMANAGERAPPROVAL ? "mgrlist" : "pnllist"))
      );
      getWithdrawalDetail.then((result) => {
        if (result !== null) {
          this.setState({ withdrawalData: getFormatDateForGrid(result), reportingType: (categoryName === RESIGNATIONMANAGERAPPROVAL ? "mgrlist" : "pnllist") });
        } else {
          this.data = "error";
        }
      });
    } catch (e) {
      // eslint-disable-next-line
      console.log(e);
    }
  };

  render() {
    const { resignationData, withdrawalData, reportingType, noticePeriodMasterData, resigTabCount } = this.state;
    return (
      <div
        className="control-pane control-section card-control-section row"
        style={{
          paddingBottom: "10px",
          paddingTop: "10px",
          paddingLeft: "25px",
          paddingRight: "25px",
          background: "#eee",
        }}
      >
        <NotificationContainer />
        <div className="tab row-xs-12 row-sm-12 row-lg-12 row-md-12">
          {resigTabCount.length > 0 && (
            <>
              <button
                type="button"
                className={`col-lg-6 onboardingtab onboarding-tab ${localStorage.getItem('SelectedTabAction') === "Resignation" || localStorage.getItem('SelectedTabAction') === null ? 'active' : ''}`}
                onClick={(e) => this.openTab(e, "Resignation")}
              >
                <b>RESIGNATION</b>{" "}
                <span className="active-tab-count-dot">
                  {resigTabCount[0] !== undefined ? resigTabCount[0].Count : 0}
                </span>
              </button>
              <button
                type="button"
                className={`col-lg-6 onboardingtab onboarding-tab ${localStorage.getItem('SelectedTabAction') === "Withdrawal" ? 'active' : ''}`}
                onClick={(e) => this.openTab(e, "Withdrawal")}
              >
                <b>WITHDRAWAL</b>
                <span className="active-tab-count-dot">
                  {resigTabCount[1] !== undefined ? resigTabCount[1].Count : 0}
                </span>
              </button>
            </>
          )}
        </div>
        <div id="Resignation" className="tabcontent">
          {resignationData !== null && resigTabCount.length > 0 ? (
            <Resignation getResignationTabData={this.getResignationTabData} resignationData={resignationData}
              noticePeriodMasterData={noticePeriodMasterData}
            />
          )
            :
            <div className='progress-bar-div'>
              <Progressbar />
            </div>
          }
        </div>
        <div id="Withdrawal" className="tabcontent">
          {withdrawalData !== null && resigTabCount.length > 0 ? (
            <Withdrawal getWithdrawalTabData={this.getWithdrawalTabData} withdrawalData={withdrawalData} reportingType={reportingType} />
          )
            :
            <div className='progress-bar-div'>
              <Progressbar />
            </div>
          }
        </div>
      </div>
    );
  }
}
ResignationManagerApproval.propTypes = {
  status: PropTypes.string,
  authUser: PropTypes.string,
};
ResignationManagerApproval.defaultProps = {
  authUser: "",
  status: "",
};

const mapStateToProps = ({ auth, onboardingreducer, separationreducer }) => {
  const { status } = onboardingreducer;
  const { authUser } = auth;
  const { UpdateWithdrawlStatus, UpdateResignationStatus } = separationreducer;
  return { authUser, status, UpdateWithdrawlStatus, UpdateResignationStatus };
};
export default connect(mapStateToProps, { updateStatusOnBoardingResucer })(
  ResignationManagerApproval
);
