// Author by:- Prashant Waphare | 31-08-2020 | home office container load all component here.
import React, { Component } from "react";
import { Dialog, DialogContentText,Button } from "@material-ui/core";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { FormValidator } from "@syncfusion/ej2-inputs";
import { saveHomeOfficeDetails, addEditHomeOfficeDetails } from "../../redux/actions/homeoffice/homeofficeaction";
import { getNotify } from "../../app/other/commonfunction";
import { DialogTitle, DialogContent, DialogActions } from "../../components/customdialog/customdialog"
import {
  ERROR,
  // eslint-disable-next-line
  pleaseFillAllTheRequiredFields,
  pleaseSelectBillAmount,
  pleaseSelectBillDate,
  pleaseUploadBillScreenshort
} from "../../assets/constants/constants";
import "./homeofficebasicinformation.css";

class FooterButton extends Component {
  constructor(props) {
    super(props)

    this.state = {
      button2Caption: 'Close',
      open: false,
      title: 'Home-Office Registration Form'
    };
  }

  saveHomeOffice = () => { //NOSONAR
    const {
      requestForWorkDesk,
      requestForHoldingArm,
      requestForOfficeChair,
      ups,
      invertor,
      upsBrand,
      upsModel,
      upsVACapacity,
      upsDuration,
      invertorBrand,
      invertorModel,
      invertorKVACapacity,
      invertorDuration,
      homeOfficeBackup,
      uninterruptedElectricitySupply,
      preRequisitConfirmation,
      deskSpaceDualScreenSetUp,
      spaceIsolatedDistraction,
      plainBackgroundForVideoCall,
      declearation,
      address1,
      address2,
      address3,
      address4,
      city,
      district,
      state,
      selectedcountry,
      pincode,
      personalEmailId,
      name,
      emrgencyEmailId,
      contactNumber,
      companyMobileNumber,
      personalMobileNumber,
      secondaryMobileNumber,
      homeAddress,
      planProvider1,
      brodbandSpeed1,
      planProvider2,
      brodbandSpeed2,
      filepath1,
      filepath2,
      authUser,
      internetConnectivity,
      internetFlag,
      billDate,
      billAmount,
      billFilePath,
      electricityBillFlag
    } = this.props;
    document.getElementById("addressline1").innerHTML = "";
    document.getElementById("addressline2").innerHTML = "";
    // document.getElementById("addressline3").innerHTML = "";
    // document.getElementById("addressline4").innerHTML = "";
    document.getElementById("billDareErr").innerHTML = "";
    document.getElementById("billAmountErr").innerHTML = "";
    document.getElementById("internetConnectivityErr").innerHTML = "";
    document.getElementById("upsBrand").innerHTML = "";
    document.getElementById("upsModel").innerHTML = "";
    document.getElementById("upsVACapacity").innerHTML = "";
    document.getElementById("upsDuration").innerHTML = "";
    document.getElementById("invertorBrand").innerHTML = "";
    document.getElementById("invertorModel").innerHTML = "";
    document.getElementById("invertorKVACapacity").innerHTML = "";
    document.getElementById("invertorDuration").innerHTML = "";
    var addressOption = {
      rules: {
        pincodeErr: {
          required: [true, "Please enter valid pin/zip code"],
        },
        line1Err: {
          required: [true, "Please enter address line 1"],
        },
        line2Err: {
          required: [true, "Please enter address line 2"],
        },
        cityErr: {
          required: [true, "Please enter city "],
        },
        stateErr: {
          required: [true, "Please enter state / county"],
        },
        countryErr: {
          required: [true, "Please select country"],
        },
        emailIdErr: {
          required: [true, "Please enter personal email id"],
          regex: [
            "^[a-zA-Z0-9_+&*-]+(?:\\." +
            "[a-zA-Z0-9_+&*-]+)*@" +
            "(?:[a-zA-Z0-9-]+\\.)+[a-z" +
            "A-Z]{2,7}$",
            "Please enter valid email id",
          ],
        },
        NameErr: {
          required: [true, "Please enter name "],
        },
        EmailErr: {
          required: [true, "Please enter email id"],
          regex: [
            "^[a-zA-Z0-9_+&*-]+(?:\\." +
            "[a-zA-Z0-9_+&*-]+)*@" +
            "(?:[a-zA-Z0-9-]+\\.)+[a-z" +
            "A-Z]{2,7}$",
            "Please enter valid email id",
          ],
        },
        contactNoErr: {
          required: [true, "Please enter contact number"],
          number: [true, "Please enter valid contact number"],
          minLength: [10, "Please enter valid contact number"],
          maxLength: [15, "Please enter valid contact number"],
        },
        personalMobileErr: {
          required: [true, "Please enter mobile number"],
          number: [true, "Please enter valid mobile number"],
          minLength: [10, "Please enter valid mobile number"],
          maxLength: [15, "Please enter valid mobile number"],
        },
        secondaryMobileErr: {
          number: [true, "Please enter valid secondary mobile number"],
          minLength: [10, "Please enter valid secondary mobile number"],
          maxLength: [15, "Please enter valid secondary mobile number"],
        },
        companyMobileErr: {
          number: [true, "Please enter valid company mobile number"],
          minLength: [10, "Please enter valid company mobile number"],
          maxLength: [15, "Please enter valid company mobile number"],
        },
      },
    };
    if (selectedcountry === 101 && pincode.length !== 6) {
      addressOption = {
        rules: {
          pincodeErr: {
            maxLength: [6, "Please enter valid pin/zip code"],
          }
        }
      }
    } else if (selectedcountry !== 101 && pincode.length > 10) {
      addressOption = {
        rules: {
          pincodeErr: {
            maxLength: [10, "Please enter valid pin/zip code"],
          }
        }
      }
    }
    // setTimeout(() => {
    try {
      const addFormObject = new FormValidator("#addbasicinfo", addressOption);
      if (addFormObject.validate() === false) {
        getNotify(ERROR, pleaseFillAllTheRequiredFields);
        return;
      }
    } catch (error) {
      console.log(error);
    }
    if (personalMobileNumber === companyMobileNumber) {
      getNotify(ERROR, "You have added same number's in company mobile number and personal mobile number, please enter different number");
      return
    }
    if (personalMobileNumber === contactNumber) {
      getNotify(ERROR, "You have added same number's in contact number and personal mobile number, please enter different number");
      return
    }
    if (companyMobileNumber === contactNumber) {
      getNotify(ERROR, "You have added same number's in contact number and company mobile number, please enter different number");
      return
    }
    // }, 100);
    document.getElementById("addressTypeErr").innerHTML = "";
    if (homeAddress === "0") {
      getNotify(ERROR, "Please select address type");
      document.getElementById("addressTypeErr").innerHTML =
        "Please select address type";
      return;
    }
    if (preRequisitConfirmation === false) {
      // eslint-disable-next-line
      getNotify(ERROR, pleaseFillAllTheRequiredFields);
      document.getElementById("preRequisitConfirmation").innerHTML =
        "Please select check box for pre-requisite confirmation";
      return;
    }
    if (
      deskSpaceDualScreenSetUp === null ||
      spaceIsolatedDistraction === null ||
      plainBackgroundForVideoCall === null
    ) {
      // eslint-disable-next-line
      getNotify(ERROR, pleaseFillAllTheRequiredFields);
      document.getElementById("homeofficeworkingspace").innerHTML =
        "Please select from above options";
      return;
    }
    // elctricity supply
    if (uninterruptedElectricitySupply === null) {
      // eslint-disable-next-line
      getNotify(ERROR, pleaseFillAllTheRequiredFields);
      document.getElementById("uninterruptedElectricitySupply").innerHTML =
        "Please select option from electricity supply section";
      return;
    }
    if (uninterruptedElectricitySupply === false) {
      if (homeOfficeBackup === null) {
        // eslint-disable-next-line
        getNotify(ERROR, pleaseFillAllTheRequiredFields);
        document.getElementById("homeOfficeBackup").innerHTML =
          "Please select option from power backup arrangement";
        return;
      }
      if (homeOfficeBackup === false || homeOfficeBackup === true) {
        if (ups === null && invertor === null) {
          // eslint-disable-next-line
          getNotify(ERROR, pleaseFillAllTheRequiredFields);
          document.getElementById("upsinvertor").innerHTML =
            "Please select option from UPS/Invertor";
          return;
        }

        if ((ups === null && invertor === false) || (ups === false && invertor === null)) {
          // eslint-disable-next-line
          getNotify(ERROR, pleaseFillAllTheRequiredFields);
          document.getElementById("upsinvertor").innerHTML =
            "Please select Yes option from UPS/Invertor";
          return;
        }
        if (ups === true) {
          if (upsBrand === "") {
            // eslint-disable-next-line
            getNotify(ERROR, pleaseFillAllTheRequiredFields);
            document.getElementById("upsBrand").innerHTML =
              "Please enter ups brand";
            return;
          }
          if (upsModel === "") {
            getNotify(ERROR, pleaseFillAllTheRequiredFields);
            document.getElementById("upsModel").innerHTML =
              "Please enter ups model";
            return;
          }
          if (upsVACapacity === "" || upsVACapacity === null || upsVACapacity < 1) {
            // eslint-disable-next-line
            getNotify(ERROR, pleaseFillAllTheRequiredFields);
            document.getElementById("upsVACapacity").innerHTML =
              "Please enter ups va capacity";
            return;
          }
          if (upsDuration < 1) {
            // eslint-disable-next-line
            getNotify(ERROR, pleaseFillAllTheRequiredFields);
            document.getElementById("upsDuration").innerHTML =
              "Please enter ups backup duration";
            return;
          }
        }
        if (invertor === true) {
          if (invertorBrand === "") {
            // eslint-disable-next-line
            getNotify(ERROR, pleaseFillAllTheRequiredFields);
            document.getElementById("invertorBrand").innerHTML =
              "Please enter invertor brand";
            return;
          }
          if (invertorModel === "") {
            // eslint-disable-next-line
            getNotify(ERROR, pleaseFillAllTheRequiredFields);
            document.getElementById("invertorModel").innerHTML =
              "Please enter invertor model";
            return;
          }
          if (invertorKVACapacity === "" || invertorKVACapacity === null || invertorKVACapacity < 1) {
            // eslint-disable-next-line
            getNotify(ERROR, pleaseFillAllTheRequiredFields);
            document.getElementById("invertorKVACapacity").innerHTML =
              "Please enter invertor kva capacity";
            return;
          }
          if (invertorDuration < 1) {
            // eslint-disable-next-line
            getNotify(ERROR, pleaseFillAllTheRequiredFields);
            document.getElementById("invertorDuration").innerHTML =
              "Please enter invertor backup duration";
            return;
          }
        }
        if (electricityBillFlag === true) {
          if (billDate === "" || billDate === null) {
            getNotify(ERROR, pleaseSelectBillDate);
            document.getElementById("billDareErr").innerHTML =
              pleaseSelectBillDate;
            return;
          }
          if (billAmount === 0 || billAmount === null) {
            getNotify(ERROR, pleaseSelectBillAmount);
            document.getElementById("billAmountErr").innerHTML =
              pleaseSelectBillAmount;
            return;
          }
          if (billFilePath === "" || billFilePath === null) {
            getNotify(ERROR, pleaseUploadBillScreenshort);
            return;
          }
        }
      }
    }
    if (internetConnectivity === "0") {
      document.getElementById("internetConnectivityErr").innerHTML = "Please select role type";
      getNotify(ERROR, "Please select role type");
      return;
    }
    const internetOption = {
      rules: {
        brodbandProviderErr: {
          required: [true, "Please enter brodband provider / plan "],
        },
        brodbandSubscriptionErr: {
          required: [true, "Please enter brodband subscription "],
        },
      },
    };
    // Internet Connectivity
    try {
      const addInternetObject = new FormValidator(
        "#internetConnectivity",
        internetOption
      );
      if (addInternetObject.validate() === false) {
        return;
      }
    } catch (er) {
      console.log(er);
    }
    if (filepath1 === "") {
      getNotify(ERROR, "Please upload screenshot of your internet subscription app, or scanned copy of your internet subscription order.");
      return;
    }
    // Other Assets

    if (
      requestForWorkDesk === null &&
      requestForHoldingArm === null &&
      requestForOfficeChair === null
    ) {
      // eslint-disable-next-line
      getNotify(ERROR, pleaseFillAllTheRequiredFields);
      document.getElementById("otherAssets").innerHTML =
        "Please select from above options.";
      return;
    }
    if (
      (requestForWorkDesk !== null ||
        requestForHoldingArm !== null) &&
      requestForOfficeChair === null
    ) {
      // eslint-disable-next-line
      getNotify(ERROR, pleaseFillAllTheRequiredFields);
      document.getElementById("otherAssets").innerHTML =
        "Please select option from question number 3.";
      return;
    }
    if (
      (requestForWorkDesk === null)
    ) {
      // eslint-disable-next-line
      getNotify(ERROR, pleaseFillAllTheRequiredFields);
      document.getElementById("otherAssets").innerHTML =
        "Please select option from question number 1.";
      return;
    }
    if (requestForHoldingArm === null) {
      // eslint-disable-next-line
      getNotify(ERROR, pleaseFillAllTheRequiredFields);
      document.getElementById("otherAssets").innerHTML =
        "Please select option from question number 2.";
      return;
    }

    if (declearation === false) {
      // eslint-disable-next-line
      getNotify(ERROR, pleaseFillAllTheRequiredFields);
      document.getElementById("declearation").innerHTML =
        "Please select check box of agreement.";
      return;
    }

    // setTimeout(() => {
    let homeOfficeData = {};
    homeOfficeData = {
      userId: authUser,
      assetReqWorkDesk: requestForWorkDesk === true ? "1" : requestForWorkDesk === false ? "2" : "0", //NOSONAR 
      assetReqScreenArm: requestForHoldingArm === true ? "1" : requestForHoldingArm === false ? "2" : "0",//NOSONAR
      assetReqChair: requestForOfficeChair === true ? "1" : "0",
      upsAvailability: ups === true ? "1" : "0",
      inverterAvailability: invertor === true ? "1" : "0",
      upsBrand,
      upsModel,
      upsCapacity: upsVACapacity === null ? "" : upsVACapacity,
      upsBackupDuration: upsDuration === null ? "" : upsDuration.toString(),
      inverterBrand: invertorBrand,
      inverterModel: invertorModel,
      inverterCapacity: invertorKVACapacity === null ? "" : invertorKVACapacity,
      inverterBackupDuration: invertorDuration === null ? "" : invertorDuration.toString(),
      generatorBackup: homeOfficeBackup === true ? "1" : "0",
      electricitySupply: uninterruptedElectricitySupply === true ? "1" : "0",
      prerequisiteConfirmation: preRequisitConfirmation === true ? "1" : "0",
      prerequisiteOfficeDesk: deskSpaceDualScreenSetUp === true ? "1" : "0",
      prerequisiteDistraction: spaceIsolatedDistraction === true ? "1" : "0",
      prerequisitePlainBackground:
        plainBackgroundForVideoCall === true ? "1" : "0",
      declaration: declearation === true ? "1" : "0",
      country: selectedcountry,
      address_1: address1,
      address_2: address2,
      address_3: address3,
      address_4: address4,
      city,
      district: district ? district : "",
      state,
      zip: pincode,
      personalEmailId,
      emergencyContactName: name,
      emergencyPersonalEmailId: emrgencyEmailId,
      emergencyContactNumber: contactNumber,
      emergencyContactCompanyMobileNumber: companyMobileNumber,
      emergencyContactPersonalMobileNumber: personalMobileNumber,
      emergencyContactSecondaryMobileNumber: secondaryMobileNumber,
      addressType: homeAddress.toString(),
      primaryBroadbandPlan: planProvider1,
      primaryBroadbandSpeed: brodbandSpeed1,
      secondaryBroadbandPlan: planProvider2,
      secondaryBroadbandSpeed: brodbandSpeed2,
      primaryBroadbandSpeedTest: filepath1,
      secondaryBroadbandSpeedTest: filepath2,
      internetType: internetConnectivity.toString(),
      internetFlag: internetFlag,
      billDate: billDate,
      billAmount: billAmount,
      billFilePath: billFilePath
    };

    if (
      (deskSpaceDualScreenSetUp === false || spaceIsolatedDistraction === false || plainBackgroundForVideoCall === false) ||
      (uninterruptedElectricitySupply === false && homeOfficeBackup === false && (ups === false && invertor === false)) ||
      (internetFlag === false && (brodbandSpeed1 === '< 200 Mbps' || brodbandSpeed1 === '< 500 Mbps'))
    ) {
      this.setState({ open: true })
    }
    else {
      this.props.saveHomeOfficeDetails({ homeOfficeData });
    }
  };

  clrearHomeOffice = () => {
    // eslint-disable-next-line
    this.props.saveHomeOfficeDetails({
      address1: "",
      address2: "",
      address3: "",
      address4: "",
      city: "",
      district: "",
      state: "",
      selectedcountry: 0,
      pincode: "",
      personalEmailId: "",
      name: "",
      emrgencyEmailId: "",
      contactNumber: "",
      companyMobileNumber: "",
      personalMobileNumber: "",
      secondaryMobileNumber: "",
      homeAddress: "0",
      planProvider1: "",
      brodbandSpeed1: "",
      planProvider2: "",
      brodbandSpeed2: "",
      filepath1: "",
      filepath2: "",
      preRequisitConfirmation: false,
      deskSpaceDualScreenSetUp: null,
      spaceIsolatedDistraction: null,
      plainBackgroundForVideoCall: null,
      uninterruptedElectricitySupply: null,
      homeOfficeBackup: null,
      ups: null,
      invertor: null,
      upsBrand: "",
      upsModel: "",
      upsVACapacity: "",
      upsDuration: 0,
      invertorBrand: "",
      invertorModel: "",
      invertorKVACapacity: "",
      invertorDuration: 0,
      requestForWorkDesk: null,
      requestForHoldingArm: null,
      requestForOfficeChair: null,
      declearation: false,
      radioButtonFalse: true,
      internetConnectivityState: true,
      internetConnectivity: '0',
      internetFlag: false,
      billDate: null,
      billAmount: 0,
      billFilePath: "",
      electricityBillFlag: 0
    });
  };
  handleConfirmNo = () => {
    this.setState({
      open: false
    })
  }
  render() {
    const { declearation, deskSpaceDualScreenSetUp, spaceIsolatedDistraction, plainBackgroundForVideoCall, homeOfficeBackup, ups, invertor, brodbandSpeed1, uninterruptedElectricitySupply, internetFlag, homeOfficeList } = this.props;
    const { open, title, button2Caption } = this.state;
    return (
      <>
        <div className="home-office-submit-button">
          <Button
            variant="contained"
            style={{ color: declearation ? "white" : "#c1baba", height: 34 }}
            color={declearation ? "primary" : "default"}
            onClick={() => this.saveHomeOffice()}
            disabled={!declearation}
          >
            {homeOfficeList && homeOfficeList[0] && homeOfficeList[0].isSubmited === '1' ? "Update" : "Submit"}
          </Button>
        </div>
        {
          homeOfficeList && homeOfficeList[0] && homeOfficeList[0].isSubmited === '1'?
            (<div
              className="home-office-cancel-button"
              style={{ marginRight: "10px" }}
            >
              <Button
                style={{ color: "white" }}
                onClick={() =>
                  // setTimeout(() => {
                    this.props.addEditHomeOfficeDetails({ homeOfficeMode: "viewHome" })
                  // }, 3000)
                }
              >
                Cancel
          </Button>
            </div>)
            :
            (<div
              className="home-office-cancel-button"
              style={{ marginRight: "10px" }}
            >
              <Button
                style={{ color: "white" }}
                onClick={() => this.clrearHomeOffice()}
              >
                Reset
          </Button>
            </div>)
        }
        <Dialog
          open={open}
          // onClose={this.state.handleConfirmClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          maxWidth='md'
        >
          <DialogTitle id="alert-dialog-title" onClose={this.handleConfirmClose}>{title}</DialogTitle>
          <DialogContent style={{ paddingTop: 0, paddingBottom: 0 }}>
            <DialogContentText id="aert-dialog-description">
              {
                <>
                  <h5 style={{ color: '#000' }}>Unfortunately, your form cannot be submitted because of the below shortfall in the pre-requisites as per your responses:</h5>
                  <ul>
                    {
                      (deskSpaceDualScreenSetUp === false || spaceIsolatedDistraction === false || plainBackgroundForVideoCall === false) &&
                      <li> One or more of your answers in the Home Office working Space section is No. All answer s have to be Yes in this section to be eligible for a Home-Office Registration.</li>
                    }
                    {
                      (uninterruptedElectricitySupply === false && homeOfficeBackup === false && ups === false && invertor === false) &&
                      <li> You do not have an appropriate power backup arrangement for continuous uninterrupted power supply to your Home-Office.</li>
                    }
                    {
                      (internetFlag === false && (brodbandSpeed1 === '< 200 Mbps' || brodbandSpeed1 === '< 500 Mbps')) &&
                      <li> The internet speed confirmed by you in the broadband speed (MBPS) field is below the minimum pre-requisite for your role.</li>
                    }
                  </ul>
                  <h5 style={{ color: '#000' }}>While your application cannot be submitted owing to the above shortfall in pre-requisites, please note that you may apply again at any time after arranging the appropriate pre-requisites for your Home-Office.</h5>
                </>
              }
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            {/* <Button onClick={this.state.handleConfirmYes} color="primary" autoFocus>
                            {this.state.button1Caption}
                        </Button> */}
            <Button onClick={this.handleConfirmNo} color="primary" >
              {button2Caption}
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}
FooterButton.propTypes = {
  authUser: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  address1: PropTypes.string,
  address2: PropTypes.string,
  address3: PropTypes.string,
  address4: PropTypes.string,
  city: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  district: PropTypes.string,
  state: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  selectedcountry: PropTypes.number,
  pincode: PropTypes.string,
  personalEmailId: PropTypes.string,
  name: PropTypes.string,
  emrgencyEmailId: PropTypes.string,
  contactNumber: PropTypes.string,
  companyMobileNumber: PropTypes.string,
  personalMobileNumber: PropTypes.string,
  secondaryMobileNumber: PropTypes.string,
  homeAddress: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  planProvider1: PropTypes.string,
  brodbandSpeed1: PropTypes.string,
  planProvider2: PropTypes.string,
  brodbandSpeed2: PropTypes.string,
  filepath1: PropTypes.string,
  filepath2: PropTypes.string,
  preRequisitConfirmation: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool
  ]),
  deskSpaceDualScreenSetUp: PropTypes.bool,
  spaceIsolatedDistraction: PropTypes.bool,
  plainBackgroundForVideoCall: PropTypes.bool,
  uninterruptedElectricitySupply: PropTypes.bool,
  homeOfficeBackup: PropTypes.bool,
  ups: PropTypes.bool,
  invertor: PropTypes.bool,
  upsBrand: PropTypes.string,
  upsModel: PropTypes.string,
  upsVACapacity: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  upsDuration: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  invertorBrand: PropTypes.string,
  invertorModel: PropTypes.string,
  invertorKVACapacity: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  invertorDuration: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  requestForWorkDesk: PropTypes.bool,
  requestForHoldingArm: PropTypes.bool,
  requestForOfficeChair: PropTypes.bool,
  declearation: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool
  ]),
  internetConnectivity: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  internetFlag: PropTypes.bool,
  billDate: PropTypes.number,
  billAmount: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  billFilePath: PropTypes.string,
  electricityBillFlag: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool
  ]),
  homeOfficeMode: PropTypes.string,
};
FooterButton.defaultProps = {
  authUser: "",
  address1: "",
  address2: "",
  address3: "",
  address4: "",
  city: "",
  district: "",
  state: "",
  selectedcountry: 0,
  pincode: "",
  personalEmailId: "",
  name: "",
  emrgencyEmailId: "",
  contactNumber: "",
  companyMobileNumber: "",
  personalMobileNumber: "",
  secondaryMobileNumber: "",
  homeAddress: "0",
  planProvider1: "",
  brodbandSpeed1: "",
  planProvider2: "",
  brodbandSpeed2: "",
  filepath1: "",
  filepath2: "",
  preRequisitConfirmation: false,
  deskSpaceDualScreenSetUp: null,
  spaceIsolatedDistraction: null,
  plainBackgroundForVideoCall: null,
  uninterruptedElectricitySupply: null,
  homeOfficeBackup: null,
  ups: null,
  invertor: null,
  upsBrand: "",
  upsModel: "",
  upsVACapacity: "",
  upsDuration: 0,
  invertorBrand: "",
  invertorModel: "",
  invertorKVACapacity: "",
  invertorDuration: 0,
  requestForWorkDesk: null,
  requestForHoldingArm: null,
  requestForOfficeChair: null,
  declearation: false,
  internetConnectivity: '0',
  internetFlag: false,
  billDate: null,
  billAmount: 0,
  billFilePath: "",
  electricityBillFlag: 0,
  homeOfficeMode: ""
};
const mapStateToProps = ({ auth, meetingmaster, homeoffice }) => {
  const { authUser } = auth;
  const { country } = meetingmaster;
  const {
    address1,
    address2,
    address3,
    address4,
    city,
    district,
    state,
    selectedcountry,
    pincode,
    personalEmailId,
    name,
    emrgencyEmailId,
    contactNumber,
    companyMobileNumber,
    personalMobileNumber,
    secondaryMobileNumber,
    homeAddress,
    rentAddress,
    planProvider1,
    brodbandSpeed1,
    planProvider2,
    brodbandSpeed2,
    filepath1,
    filepath2,
    requestForWorkDesk,
    requestForHoldingArm,
    requestForOfficeChair,
    ups,
    invertor,
    upsBrand,
    upsModel,
    upsVACapacity,
    upsDuration,
    invertorBrand,
    invertorModel,
    invertorKVACapacity,
    invertorDuration,
    homeOfficeBackup,
    uninterruptedElectricitySupply,
    preRequisitConfirmation,
    deskSpaceDualScreenSetUp,
    spaceIsolatedDistraction,
    plainBackgroundForVideoCall,
    declearation,
    radioButtonFalse,
    internetConnectivity,
    internetFlag,
    billDate,
    billAmount,
    billFilePath,
    electricityBillFlag
  } = homeoffice;
  return {
    authUser,
    country,
    address1,
    address2,
    address3,
    address4,
    city,
    district,
    state,
    selectedcountry,
    pincode,
    personalEmailId,
    name,
    emrgencyEmailId,
    contactNumber,
    companyMobileNumber,
    personalMobileNumber,
    secondaryMobileNumber,
    homeAddress,
    rentAddress,
    planProvider1,
    brodbandSpeed1,
    planProvider2,
    brodbandSpeed2,
    filepath1,
    filepath2,
    requestForWorkDesk,
    requestForHoldingArm,
    requestForOfficeChair,
    ups,
    invertor,
    upsBrand,
    upsModel,
    upsVACapacity,
    upsDuration,
    invertorBrand,
    invertorModel,
    invertorKVACapacity,
    invertorDuration,
    homeOfficeBackup,
    uninterruptedElectricitySupply,
    preRequisitConfirmation,
    deskSpaceDualScreenSetUp,
    spaceIsolatedDistraction,
    plainBackgroundForVideoCall,
    declearation,
    radioButtonFalse,
    internetConnectivity,
    internetFlag,
    billDate,
    billAmount,
    billFilePath,
    electricityBillFlag
  };
};
export default connect(mapStateToProps, {
  saveHomeOfficeDetails, addEditHomeOfficeDetails
})(FooterButton);
