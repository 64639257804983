import React from 'react';

function PerformanceCardView(props) {
  const navToGridView = ()=>{
      if(props.rating>0 ){  
        props.openTab("gridView")
      }
  }
  return (
    <div
      className="col-3 col-lg-3 col-md-3 col-sm-3 col-xs-3 single-performance-card-view"
      style={{
        backgroundColor: props.secondaryColor,
        border: `1px solid ${props.primaryColor}`,
        cursor:props.rating>0 ? 'pointer':'no-drop'
      }}
      onClick={navToGridView}
    >
      <div
        className="team-ranking-title"
        style={{ backgroundColor: props.primaryColor }}
      >
        {props.title}
      </div>
      <div className="team-ranking-number">{props.rating}</div>
    </div>
  )
}

export default PerformanceCardView;
