import React, { Component } from "react";
import {
  faCalendarDay,
  faEnvelope,
  faEnvelopeOpen,
  faFileUpload,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  MultiSelectComponent,
  CheckBoxSelection,
} from "@syncfusion/ej2-react-dropdowns";
import { Inject } from "@syncfusion/ej2-react-schedule";
import { Query } from "@syncfusion/ej2-data";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { addUpdateSeparationDetails } from "../../../redux/actions/entryexit/separationaction";
import SeparationServices from "../../../services/entryexit/separationServices";
import { getDisplayDateOnly } from "../../../app/other/commonfunction";
import "./resignationcomponent.css";

class BasicInforesignation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedUserId: [],
      resinationDate: "",
    };
    this.getSelectedAttendee = this.getSelectedAttendee.bind(this);
    this.removeItem = this.removeItem.bind(this);
  }

  componentDidMount = () => {
    const { employeeStatus } = this.props;
    if (employeeStatus === "Resigned") {
      this.getEmployeeResignationData();
    }
    this.getResignationInfo();
    this.getResignationUpperHierarchyData();
  };

  // eslint-disable-next-line
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.updateTerminationStatus === "updated") {
      this.getResignationInfo();
      // eslint-disable-next-line
      this.props.addUpdateSeparationDetails({ updateTerminationStatus: '' })
    }
    if (nextProps.updateResonForResignationStatus === "updated") {
      this.getResignationInfo();
      // eslint-disable-next-line
      this.props.addUpdateSeparationDetails({ updateResonForResignationStatus: '' })
    }
    if (
      nextProps.employeeStatus === "Resigned" ||
      nextProps.employeeStatus === "Resignation - Withdrawal"
    ) {
      this.getEmployeeResignationData();
    }
  }

  getEmployeeResignationData = () => {
    try {
      const { authUser } = this.props;
      const employeeBasicData = Promise.resolve(
        SeparationServices.getEmployeeResignationData(authUser, "dts")
      );
      employeeBasicData.then((result) => {
        if (result !== null) {
          const {
            addCcEmpIds,
            noticePeriodRequested,
            resinationDate,
            willingToBuyback,
            Remarks,
            noOfDays,
          } = result[0];
          this.setState(
            {
              resinationDate,
            },
            () => {
              // eslint-disable-next-line
            this.props.addUpdateSeparationDetails({
                addCcEmpIds,
                noticePeriodRequested,
                resinationDate,
                willingToBuyBackNotice: willingToBuyback,
                Remarks,
                noOfDays:parseInt(noOfDays),
              });
            }
          );
        }
      });
    } catch (error) {}
  };

  getResignationInfo = () => {
    try {
      const { authUser } = this.props;
      const employeeMasterData = Promise.resolve(
        SeparationServices.getEmployeeResignationData(
          authUser,
          // 760,
          "info"
        )
      );
      employeeMasterData.then((result) => {
        if (result !== null && result.length > 0) {
          const {
            employeeStatus,
            noticePeriodInMonth,
            reportingTo,
            ccEmpNm,
            noOfDays,
            resinationDate,
            addCcEmpName,
            clearanceInitiated,
          } = result[0];
    
          this.props.addUpdateSeparationDetails({
            employeeStatus,
            noticePeriodInMonth,
            reportingTo,
            ccEmpNm,
            addCcEmpName,
            noOfDays:parseInt(noOfDays),
            resinationDate,
            clearanceInitiated
          });
        }
      });
    } catch (error) {
      // eslint-disable-next-line
    }
  };
  getResignationUpperHierarchyData  = () => {
    try {
      const { authUser } = this.props;
      const employeeMasterData = Promise.resolve(
        SeparationServices.getEmployeeResignationMaster(
          authUser,
          // 760,
          "AddCc"
        )
      );
      employeeMasterData.then((result) => {
        
        if (result !== null && result.length > 0) {
           this.props.addUpdateSeparationDetails({
              addCcData:result
           });
        }
      });
    } catch (error) {
      // eslint-disable-next-line
    }
  };

  getSelectedAttendee(e) {
  
      let selectedUserIds = [];
      // eslint-disable-next-line
      selectedUserIds = this.state.selectedUserId;
      selectedUserIds.push(e.itemData.Id);
      this.setState(
        {
          selectedUserId:selectedUserIds,
        },
        () =>
          // eslint-disable-next-line
          this.props.addUpdateSeparationDetails({
            // eslint-disable-next-line
            additionalEmailIdsCC: this.state.selectedUserId.toString(),
          })
      );
    // }
  }

  handleFilteringByName = (e) => {
    const newData = [];
    // eslint-disable-next-line
    this.props.othersCheckAvailablity.forEach((eachD) => {
      newData.push(eachD);
    });
    if (newData != null && newData.length > 0) {
      let query = new Query();
      query =
        e.text !== ""
          ? query.where("Name", "contains", e.text, true)
          : query;
      e.updateData(newData, query);
    }
    // }
  };

  removeItem(e) {
    // eslint-disable-next-line
    let selectedUserIds = this.state.selectedUserId;
    selectedUserIds = selectedUserIds.filter((el) => el !== e.itemData.Id);
    this.setState(
      {
        selectedUserId:selectedUserIds,
      },
      () =>/**NOSONAR */
        // eslint-disable-next-line
        this.props.addUpdateSeparationDetails({
          // eslint-disable-next-line
          additionalEmailIdsCC: this.state.selectedUserId.toString(),
        })
    );
  }

  render() {
    const { resinationDate } = this.state;
    const {
      // eslint-disable-next-line
      reportingTo,
      ccEmpNm,
      // eslint-disable-next-line
      // addCcEmpIds,
      employeeStatus,
      addCcEmpName,
      addCcData
    } = this.props;
    return (
      <div>
        <div> BASIC INFORMATION </div>
        <div className="row resignation-basicinformation-row">
          <div className="col-md-3 remove-padding" style={{ padding: 5 }}>
            <div
              className="col-md-2 remove-padding"
              style={{ height: "100%", textAlign: "right" }}
            >
              <div className="row">
                <FontAwesomeIcon
                  icon={faCalendarDay}
                  className="mr-sm resignation-icons-basicinformation"
                />
              </div>
            </div>
            <div className="col-md-10 ">
              <div className="row1 px-sm-2 resignation-basicinformation-icon-header-text-font-style">
                Resignation Date
              </div>
              <div className="row1 px-sm-2 resignation-basicinformation-icon-text-font-style">
                {resinationDate !== ""
                  ? getDisplayDateOnly(new Date(resinationDate))
                  : getDisplayDateOnly(new Date())}
              </div>
            </div>
          </div>
          <div className="col-md-3 remove-padding" style={{ padding: 5 }}>
            <div
              className="col-md-2 remove-padding"
              style={{ height: "100%", textAlign: "right" }}
            >
              <div className="row">
                <FontAwesomeIcon
                  icon={faFileUpload}
                  className="mr-sm resignation-icons-basicinformation"
                />
              </div>
            </div>
            <div className="col-md-10 ">
              <div className="row1 px-sm-2 resignation-basicinformation-icon-header-text-font-style">
                Reporting Head
              </div>
              <div className="row1 px-sm-2 resignation-basicinformation-icon-text-font-style">
                {reportingTo}
              </div>
            </div>
          </div>
          <div className="col-md-3 remove-padding" style={{ padding: 5 }}>
            <div
              className="col-md-2 remove-padding"
              style={{ height: "100%", textAlign: "right" }}
            >
              <div className="row">
                <FontAwesomeIcon
                  icon={faEnvelope}
                  className="mr-sm resignation-icons-basicinformation"
                />
              </div>
            </div>
            <div className="col-md-10 ">
              <div
                className="row1 px-sm-2 resignation-basicinformation-icon-header-text-font-style"
                style={{ padding: 0 }}
              >
                Copy To
              </div>
              <div className="row1 px-sm-2 resignation-basicinformation-icon-text-font-style resignation-text-limit">
                {ccEmpNm}
              </div>
            </div>
          </div>
          <div className="col-md-3 remove-padding" style={{ padding: 5 }}>
            <div
              className="col-md-2 remove-padding"
              style={{ height: "100%", textAlign: "right" }}
            >
              <div className="row">
                <FontAwesomeIcon
                  icon={faEnvelopeOpen}
                  className="mr-sm resignation-icons-basicinformation"
                />
              </div>
            </div>
            <div className="col-md-8 ">
              {(employeeStatus === "Resigned" ||
                employeeStatus === "Resignation - Withdrawal") && (
                <div
                  className="row1 px-sm-2 resignation-basicinformation-icon-header-text-font-style"
                  style={{ padding: 0 }}
                >
                  Additional Copy To
                </div>
              )}
              <div className="row px-sm-2 resignation-basicinformation-icon-text-font-style">
                <div>
                  {employeeStatus === "Resigned" ||
                  employeeStatus === "Resignation - Withdrawal" ? (
                    addCcEmpName
                  ) : (
                    <MultiSelectComponent
                      id="localData"
                      ref={(multiselect) => {
                        this.searchEmail = multiselect;
                      }}
                      cssClass="e-outline"
                      // eslint-disable-next-line
                      dataSource={addCcData}
                      allowFiltering={false}
                      fields={{ text: "Name", value: "Id" }}
                      select={this.getSelectedAttendee}
                      maximumSelectionLength={5}
                      removed={this.removeItem}
                      mode="CheckBox"
                      showDropDownIcon
                      filtering={this.handleFilteringByName}
                      placeholder="Additional Copy To"
                      readonly={
                        !!(
                          employeeStatus === "Resigned" ||
                          employeeStatus === "Resignation - Withdrawal"
                        )
                      }
                      showSelectAll={false}
                    >
                      <Inject services={[CheckBoxSelection]} />
                    </MultiSelectComponent>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
BasicInforesignation.propTypes = {
  authUser: PropTypes.string,
  othersCheckAvailablity: PropTypes.arrayOf(Object).isRequired,
  reportingTo: PropTypes.string,
  employeeStatus: PropTypes.string,
  updateTerminationStatus: PropTypes.string,
  addCcEmpName: PropTypes.string,
  ccEmpNm: PropTypes.string,
  noOfDays: PropTypes.number,
  updateResonForResignationStatus: PropTypes.string,
  addCcData:PropTypes.arrayOf(Object)
};
BasicInforesignation.defaultProps = {
  authUser: "",
  reportingTo: "",
  employeeStatus: "",
  updateTerminationStatus: "",
  addCcEmpName: "",
  ccEmpNm: "",
  updateResonForResignationStatus: "",
  noOfDays: 0,
  addCcData:[]
};
const mapStateToProps = ({ auth, meetingmaster, separationreducer }) => {
  const { authUser } = auth;
  // eslint-disable-next-line
  const { othersCheckAvailablity } = meetingmaster;
  const {
    reportingTo,
    employeeStatus,
    ccEmpNm,
    updateResonForResignationStatus,
    addCcEmpIds,
    // eslint-disable-next-line
    // updateTerminationStatus,
    noOfDays,
    addCcEmpName,
    addCcData
  } = separationreducer;
  return {
    authUser,
    othersCheckAvailablity,
    reportingTo,
    employeeStatus,
    ccEmpNm,
    updateResonForResignationStatus,
    addCcEmpIds,
    noOfDays,
    addCcEmpName,
    addCcData,
  };
};

export default connect(mapStateToProps, { addUpdateSeparationDetails })(
  BasicInforesignation
);
