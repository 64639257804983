// Author : Suresh R. Sargam
// Date : 11-Dec-2019
// Description: Header for for Meeting Details Pop and Add Self Meeting

import React from "react";
import "../../assets/style/index.css";
import PropTypes from "prop-types";
import { OTHERS_CHECK_AVAILABILITY } from "../../redux/constant/meetingcategoryconstant";
import { dencrypt, decodeString } from "../../../src/app/other/commonfunction";
function Header(props) { // NOSONAR
  const {
    elementType,
    PrimaryColor,
    SecondaryColor,
    EditDeleteAccess,
    Subject,
    scheduleObj,
    meetingMode
  } = props;
  const update = (e) => {
    const quickPopup = scheduleObj.element.querySelector(
      ".e-quick-popup-wrapper"
    );
    const getSlotData = () => {
      const cellDetails = scheduleObj.getCellDetails(
        scheduleObj.getSelectedElements()
      );
      const addObj = {};
      addObj.Id = scheduleObj.getEventMaxID();
      addObj.Subject = "";
      addObj.StartTime = new Date(+cellDetails.startTime);
      addObj.EndTime = new Date(+cellDetails.endTime);
      addObj.Location = "";
      return addObj;
    };
    if (e.target.id === "delete") {
      const eventDetails = scheduleObj.activeEventData.event;
      let currentAction = "Delete";
      if (eventDetails.Recurrence === "Y") {
        currentAction = "DeleteSeries";
      }
      scheduleObj.deleteEvent(eventDetails, currentAction);
    } else {
      const isCellPopup = quickPopup.firstElementChild.classList.contains(
        "e-cell-popup"
      );
      const eventDetails = isCellPopup
        ? getSlotData()
        : scheduleObj.activeEventData.event;
      let currentAction = isCellPopup ? "Add" : "Save";
      if (eventDetails.Recurrence === "Y") {
        currentAction = "EditSeries";
      }
      scheduleObj.openEditor(eventDetails, currentAction, true);
    }
    scheduleObj.closeQuickInfoPopup();
  }

  return (
    <div>
      {elementType === "cell" ? (
        <div className="e-cell-header">
          <div className="e-header-icon-wrapper">
            <button style={{ border: 'none', background: 'none', padding: '10px' }} className="e-btn-icon e-icons e-close-icon" title="Close" type="button" onClick={() => {
              scheduleObj.quickPopup.closeClick();
            }} />
          </div>
          <div className="e-subject-wrap pl-sm pr-sm">
            {dencrypt(localStorage.getItem("ops_access")) === "1" ? ( // NOSONAR
              <div className="pt-xs pl-sm">
                <h4>Add Meeting</h4>
              </div>
            ) : (
                <div className="pt-xs pl-sm">
                  <h4>Add Meeting</h4>
                </div>
              )}
          </div>
        </div>
      ) : (
          <div className="e-event-header">
            <div
              className="template-wrap"
              style={{
                background: PrimaryColor,
                cursor: "pointer",
                borderLeft: "5px solid",
                borderColor: SecondaryColor,
                marginLeft: 0,
              }}
            >
              <div className="e-header-icon-wrapper">
                {decodeString(localStorage.getItem("meeting_category")) !==
                  OTHERS_CHECK_AVAILABILITY && (
                    <button
                      className="fas fa-eye"
                      style={{ backgroundColor: "inherit", border: "none" }}
                      title="Preview the Meeting Details"
                      // eslint-disable-next-line
                      onClick={() => props.handleViewMeetingpopup()}
                      type="button"
                    />
                  )}
                <button
                  className="e-btn-icon e-icons e-edit-icon"
                  // id="edit"
                  style={{ border: 'none', background: 'none', padding: '10px', color: EditDeleteAccess === "Y" ? "":"gray" }}
                  title={
                    EditDeleteAccess === "Y" // NOSONAR
                      ? "Edit"
                      : "Only Meeting Setter/Chair Person can Edit"
                  }
                  disabled={EditDeleteAccess === "Y" ? "" : "disabled"} // NOSONAR
                  type="button"
                  onClick={update}
                />
                <button
                  className="e-btn-icon e-icons e-delete-icon"
                  id="delete"
                  style={{ border: 'none', background: 'none', padding: '10px', color: meetingMode === "P" && EditDeleteAccess === "Y" ? "":"gray" }}
                  title={
                    meetingMode === "P" && EditDeleteAccess === "Y" // NOSONAR
                      ? "Delete"
                      : ""
                      // : "Only Meeting Setter/Chair Person can Delete"
                  }
                  disabled={
                    meetingMode === "P" && EditDeleteAccess === "Y" // NOSONAR
                      ? ""
                      : "disabled"
                  }
                  type="button"
                  onClick={update}
                />
                <button style={{ border: 'none', background: 'none', padding: '10px' }} className="e-btn-icon e-icons e-close-icon" title="Close" type="button" onClick={() => {
                  scheduleObj.quickPopup.closeClick();
                }} />
              </div>
              <div className="e-subject-wrap pl-sm pr-sm">
                {Subject.length > 30 ? ( // NOSONAR
                  <div
                    className="e-subject-custom e-text-ellipsis"
                    title={Subject}
                    style={{ cursor: "pointer" }}
                  >
                    {PrimaryColor === SecondaryColor ? "" : null  // NOSONAR
                    } {Subject} 
                  </div>
                ) : (
                    <div
                      className="e-subject e-text-ellipsis"
                      title={Subject}
                      style={{ cursor: "pointer" }}
                    >
                      {PrimaryColor === SecondaryColor ? "" : null // NOSONAR
                      } {Subject}
                    </div>
                  )}
              </div>
            </div>
          </div>
        )}
    </div>
  );
}
Header.propTypes = {
  // eslint-disable-next-line
  elementType: PropTypes.string,
  PrimaryColor: PropTypes.string,
  SecondaryColor: PropTypes.string,
  EditDeleteAccess: PropTypes.string,
  Subject: PropTypes.string,
  meetingMode: PropTypes.string,
};

Header.defaultProps = {
  elementType: "",
  PrimaryColor: "",
  SecondaryColor: "",
  EditDeleteAccess: "",
  Subject: "",
  meetingMode: "",
};
export default Header;
