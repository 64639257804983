import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import Auth from './authreducer';
import MeetingBooking from './meetingbookingreducer'
import MeetingCategory from './meetingcategoryreducer'
import MeetingMaster from './meetingmasterreducer'
import userSetting from './settingreducer';
import tibReducer from './tibreducer';
import Onboardingreducer from './entryexit/onboardingreducer';
import candidateReducer from './entryexit/candidatereducer';
import confirmationReducer from './entryexit/confirmationreducer';
import separationreducer from './entryexit/separationreducer';
import homeoffice from "./homeoffice/homeofficereducer";
import orgchartreducer from './entryexit/orgchartreducer';
import okrreducer from './okr/okrreducer';
import adminreducer from './admin/adminreducer';
import okrratingreducer from './okrrating/okrratingreducer'
import lnareducer from './lna/lnareducer'
import okrdashboardreducer from './okrdashboardreducer/okrdashboardreducer';
import helpdeskreducer from './helpdesk/helpdeskreducer';
// eslint-disable-next-line
export default (history) => combineReducers({
  router: connectRouter(history),
  meetingcategory: MeetingCategory,
  auth: Auth,
  meetingbooking: MeetingBooking,
  meetingmaster: MeetingMaster,
  usersettingreducer: userSetting,
  candidateReducer: candidateReducer,
  onboardingreducer: Onboardingreducer,
  confirmationReducer: confirmationReducer,
  separationreducer: separationreducer,
  tibReducer,
  homeoffice,
  orgchartreducer: orgchartreducer,
  okrreducer: okrreducer,
  adminreducer: adminreducer,
  okrratingreducer: okrratingreducer,
  lnareducer: lnareducer,
  okrdashboardreducer: okrdashboardreducer,
  helpdeskreducer:helpdeskreducer
});
