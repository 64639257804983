/**
 * Author Name :- Prashant Waphare
 * Date : 23-11-2020
 * Description :- This file use for show Team Shared Grid.
 */
import * as React from "react";
import {
    GridComponent,
    ColumnsDirective,
    ColumnDirective
} from "@syncfusion/ej2-react-grids";
import "../../app/containers/tmlc/onboarding/onboarding.css";
import "../../app/containers/tmlc/tmlc.css";
import { connect } from "react-redux";
import {
    ComboBoxComponent
} from "@syncfusion/ej2-react-dropdowns";
import {
    TextBoxComponent
} from "@syncfusion/ej2-react-inputs";
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import okrServices from "../../services/okr/okrservices";
import { setCostSharingDetails } from '../../redux/actions/okr/okractions';
import { FormValidator } from "@syncfusion/ej2-inputs";
import TextField from "@material-ui/core/TextField";

class NewRequestForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            shareDetails: [],
            employeeMasterList: [],
            currencyMaster: [],
            openNewRequest: false,
            redirect: null,
            height: (window.innerHeight ||
                document.documentElement.clientHeight ||
                document.body.clientHeight) - 290,
            gridHeight: (window.innerHeight ||
                document.documentElement.clientHeight ||
                document.body.clientHeight) - 290
        };
        this.selectedStartMonth = new Date();
        this.selectedEndMonth = new Date();
    }
    /**
    * Calculate & Update state of new dimensions
    */
    updateDimensions() {
        let update_width = window.innerWidth - 100;
        let update_height = window.innerHeight - 145;
        let gridHeight = window.innerHeight - 320;
        this.setState({ width: update_width, height: update_height, gridHeight: gridHeight });
    }
    componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }
    onQueryInfo(args) {
        const { cell } = args;
        cell.setAttribute(
            "style",
            "font-size:12px !important; text-align: left !important; padding-left:2px !important"
        );
    }

    statusTemp = (props) => {
        const { enumAcceptanceStatus } = props;
        if (enumAcceptanceStatus === '0') {
            return (
                <div>
                    <span>
                        {'Pending'}
                    </span>
                </div>
            );
        } else if(enumAcceptanceStatus === '1') {
            return (
                <div>
                    <div style={{ color: 'green' }}>
                        {'Shared'}
                    </div>
                </div>
            );
        }else if (enumAcceptanceStatus === '2'){
            return (
                <div>
                    <div style={{ color: 'red' }}>
                        {'Revoke'}
                    </div>
                </div>
            );
        }
    };
    durationTemp = (props) => {
        const { startDate, endDate } = props;
        return (
            <div className="date-format-all-grid" style={{ textAlign: "center" }}>
                <span>{`${startDate} to ${endDate}`}</span>
            </div>
        );
    };
    shareWithDeprtment = (props) => {
        const { sharedWith,pnlHead } = props;
        return (
            <div className="date-format-all-grid">
                <span >{`${sharedWith !=='' && sharedWith!==null ? sharedWith :pnlHead}`}</span>
            </div>
        );
    };
    percSalary = (props) => {
        const { percSalaryShared } = props;
        return (
            <div className="date-format-all-grid">
                <span>{`${percSalaryShared} % of salary`}</span>
            </div>
        );
    };
    getSharingDetails = (e) => {
        if (e.itemData !== undefined && e.itemData !== null) {
            try {
                const getSharedResourceList = Promise.resolve(
                    okrServices.getSharedResourceList(e.itemData.Id, 'PersonalSharedResource')
                );
                getSharedResourceList.then((result) => {
                    if (result !== null) {
                        let total = 0;
                        // eslint-disable-next-line
                        result.result.map((data) => (                            
                            total = total + data.percSalaryShared
                        ));
                        this.props.setCostSharingDetails({ totalCostSharing: total });
                        this.setState({ shareDetails: [] }, () => this.setState({ shareDetails: result.result }));
                    } else {
                        this.data = "error";
                    }
                });
            } catch (error) {
                // eslint-disable-next-line
                console.log(error);
            }
        } else {
            this.setState({ shareDetails: [] });
            this.props.setCostSharingDetails({ employeeId: 0 })

        }
    }
    getformData = (e, type) => {
        const { totalCostSharing } = this.props;
        let options = {};
        switch (type) {
            case "employeeList":
                this.props.setCostSharingDetails({ employeeId: e.itemData.Id });
                options = {
                    rules: {
                        employeeListErr: {
                            required: [true, 'Please select employee'],
                        },
                    },
                };
                break;
            case "sharewith":
                this.props.setCostSharingDetails({ sharedWith: e.itemData.Id });
                options = {
                    rules: {
                        sharewithErr: {
                            required: [true, 'Please select share with'],
                        },
                    },
                };
                break;
            case "from":
                this.props.setCostSharingDetails({ startDate: e.value });
                options = {
                    rules: {
                        sharewithErr: {
                            required: [true, 'Please select start month'],
                        },
                    },
                };
                break;
            case "to":
                this.props.setCostSharingDetails({ endDate: e.value });
                options = {
                    rules: {
                        sharewithErr: {
                            required: [true, 'Please select end month'],
                        },
                    },
                };
                break;
            case "salary":
                document.getElementById('salaryErr').innerHTML = "";
                this.props.setCostSharingDetails({ percSalaryShared: e.value });
                if (totalCostSharing + parseFloat(e.value) > 100) {
                    document.getElementById('salaryErr').innerHTML = "You can not share more than 100 % of salary";
                    this.props.setCostSharingDetails({ percSalaryShared: 0});
                    return;
                }
                options = {
                    rules: {
                        salaryErr: {
                            required: [true, 'Please enter % of salary'],
                            number: [true, 'Please enter valid % of salary'],
                            regex: ["^[0-9]", "Please enter valid % of salary"],
                        },
                    },
                };
                break;
            case "amountid":
                this.props.setCostSharingDetails({ amountShared: e.value });
                options = {
                    rules: {
                        amountErr: {
                            number: [true, 'Please enter valid amount'],
                        },
                    },
                };
                break;
            case "currency":
                this.props.setCostSharingDetails({ currencyId: e.itemData.Id });
                break;
            case "description":
                this.props.setCostSharingDetails({ description: e.value });
                break;
            default:
        }
        setTimeout(() => {
            const addFormObject = new FormValidator("#newRequestForm", options);
            addFormObject.validate();
        }, 100);
    }
    getStartMonth = (e) => {
        this.selectedStartMonth = e.toString();
        let year = e.getFullYear();
        let monthStr = ((e.getMonth() + 1).toString().length === 2) ? (e.getMonth() + 1).toString() : "0" + (e.getMonth() + 1).toString();
        this.month = year + '-' + monthStr;
        this.props.setCostSharingDetails({ startDate: this.month });
    }
    getEndMonth = (e) => {
        this.selectedEndMonth = e.toString();
        let year = e.getFullYear();
        let monthStr = ((e.getMonth() + 1).toString().length === 2) ? (e.getMonth() + 1).toString() : "0" + (e.getMonth() + 1).toString();
        this.month = year + '-' + monthStr;
        this.props.setCostSharingDetails({ endDate: this.month });
    }
    TextFieldComponent = (props) => {
        return <TextField {...props} color="primary" disabled />;
    };
    render() {
        const { shareDetails } = this.state;
        const { employeeMasterList,shareWithMasterList, currencyMaster, employeeId, sharedWith, startDate, endDate, percSalaryShared, amountShared, currencyId, description } = this.props;
        return (
            <div>
                <div style={{ margin: '-5px 0px 15px 0px', fontWeight: 700, lineHeight: 'normal', fontSize: '16px' }}>
                    <span>New Request</span>
                </div>
                <form id="newRequestForm"
                    method="post">
                    <div>
                        <div className="row card-layout" style={{ width: '675px' }}>
                            <div className="col-xs-2 col-sm-2 col-lg-12 col-md-2">
                                {
                                    <div>
                                        <ComboBoxComponent
                                            id="employeeList"
                                            dataSource={employeeMasterList}
                                            value={employeeId}
                                            fields={{ text: "Name", value: "Id" }}
                                            data-msg-containerid="employeeListErr"
                                            name="employeeListErr"
                                            allowFiltering
                                            allowCustom={false}
                                            floatLabelType="Auto"
                                            cssClass="e-outline"
                                            placeholder="Select Employee *"
                                            change={(e) => this.getSharingDetails(e)}
                                            select={(e) => this.getformData(e, 'employeeList')}
                                        />
                                        <div
                                            id="employeeListErr"
                                            className="e-input-group e-float-icon-left error-div"
                                        />
                                    </div>
                                }
                            </div>
                            {
                                shareDetails.length > 0 &&
                                <div className="col-xs-2 col-sm-2 col-lg-12 col-md-2" style={{ paddingBottom: '20px' }}>
                                    <GridComponent dataSource={shareDetails} height={`${shareDetails.length > 3 ? '100' : 'auto'}`}
                                        queryCellInfo={this.onQueryInfo.bind(this)}
                                    >
                                        <ColumnsDirective>
                                            <ColumnDirective width='5' />
                                            <ColumnDirective field='sharedWithId' headerText='Status' width='60' textAlign="Left" template={this.statusTemp} />
                                            <ColumnDirective field='sharedWith' headerText='Shared with' width='180' textAlign="Left" template={this.shareWithDeprtment} />
                                            <ColumnDirective field='startDate' headerText='Duration' width='150' textAlign="Center" template={this.durationTemp} />
                                            <ColumnDirective field='percSalaryShared' headerText='Sharing Denomination' width='130' textAlign="Left" template={this.percSalary} />
                                        </ColumnsDirective>
                                    </GridComponent>
                                </div>
                            }
                            <div>
                                <div className="col-xs-2 col-sm-2 col-lg-12 col-md-2">
                                    <div>
                                        <ComboBoxComponent
                                            id="sharewith"
                                            dataSource={shareWithMasterList}
                                            value={sharedWith}
                                            fields={{ text: "Name", value: "Id" }}
                                            data-msg-containerid="sharewithErr"
                                            name="sharewithErr"
                                            allowFiltering
                                            allowCustom={false}
                                            floatLabelType="Auto"
                                            cssClass="e-outline"
                                            placeholder="Share With *"
                                            select={(e) => this.getformData(e, "sharewith")}
                                        />
                                        <div
                                            id="sharewithErr"
                                            className="e-input-group e-float-icon-left error-div"
                                        />
                                    </div>
                                </div>
                                <div className="col-xs-2 col-sm-2 col-lg-12 col-md-2">
                                    <div style={{ padding: '0px 0px 10px 0px' }}>
                                        Duration
                                                </div>
                                </div>
                                <div className="col-xs-2 col-sm-2 col-lg-6 col-md-2">
                                    <div>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker style={{ width: '100%' }}
                                                openTo="month"
                                                views={["year", "month"]}
                                                id="activeEmpDate"
                                                name="activeEmpDate"
                                                format="MMM-yyyy"
                                                KeyboardButtonProps={{ 'aria-label': 'change date', }}
                                                value={startDate !== null ? new Date(startDate) : new Date()}
                                                onChange={(date) => this.getStartMonth(date)}
                                                TextFieldComponent={this.TextFieldComponent}
                                                inputVariant="outlined"
                                                variant="inline"
                                                autoOk
                                                minDate={new Date()}
                                                label="From Month *"
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                </div>
                                <div className="col-xs-2 col-sm-2 col-lg-6 col-md-2">
                                    <div>
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker style={{ width: '100%' }}
                                                openTo="month"
                                                views={["year", "month"]}
                                                id="activeEmpDate"
                                                name="activeEmpDate"
                                                format="MMM-yyyy"
                                                KeyboardButtonProps={{ 'aria-label': 'change date', }}
                                                value={endDate !== null ? new Date(endDate) : new Date()}
                                                onChange={(date) => this.getEndMonth(date)}
                                                TextFieldComponent={this.TextFieldComponent}
                                                inputVariant="outlined"
                                                variant="inline"
                                                autoOk
                                                minDate={new Date(startDate)}
                                                label="To Month *"
                                            />
                                        </MuiPickersUtilsProvider>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-2 col-sm-2 col-lg-12 col-md-2">
                                <div style={{ padding: '10px 0px 10px 0px' }}>
                                    Sharing Denomination (Monthly)
                                                </div>
                            </div>
                            <div >
                                <div className="col-xs-2 col-sm-2 col-lg-4 col-md-2">
                                    <div>
                                        <TextBoxComponent
                                            id="salary"
                                            data-msg-containerid="salaryErr"
                                            value={percSalaryShared}
                                            name="salaryErr"
                                            floatLabelType="Auto"
                                            placeholder="% Of Salary *"
                                            cssClass="e-outline"
                                            blur={(e) => this.getformData(e, "salary")}
                                        />
                                        <div style={{ fontSize: '12px' }}
                                            id="salaryErr"
                                            className="e-input-group e-float-icon-left error-div"
                                        />
                                    </div>
                                </div>
                                <div className="col-xs-2 col-sm-2 col-lg-1 col-md-2">
                                    <div style={{ textAlign: 'center', padding: '8px 0px 8px 0px' }}>or</div>
                                </div>
                                <div className="col-xs-2 col-sm-2 col-lg-4 col-md-2">
                                    <div>
                                        <TextBoxComponent
                                            id="amountid"
                                            value={amountShared}
                                            data-msg-containerid="amountErr"
                                            name="amountErr"
                                            floatLabelType="Auto"
                                            placeholder="Amount"
                                            cssClass="e-outline"
                                            blur={(e) => this.getformData(e, "amountid")}
                                        />
                                        <div
                                            id="amountErr"
                                            className="e-input-group e-float-icon-left error-div"
                                        />
                                    </div>
                                </div>
                                <div className="col-xs-2 col-sm-2 col-lg-3 col-md-2">
                                    <div>
                                        <ComboBoxComponent
                                            id="currency"
                                            dataSource={currencyMaster}
                                            value={currencyId}
                                            fields={{ text: "Name", value: "Id" }}
                                            name="currencyErr"
                                            data-msg-containerid="currencyErr"
                                            allowFiltering
                                            allowCustom={false}
                                            floatLabelType="Auto"
                                            cssClass="e-outline"
                                            placeholder="Currency"
                                            select={(e) => this.getformData(e, "currency")}
                                        />
                                        <div
                                            style={{ fontSize: '12px' }}
                                            id="currencyErr"
                                            className="e-input-group e-float-icon-left error-div"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-2 col-sm-2 col-lg-12 col-md-2">
                                <div>
                                    <TextBoxComponent
                                        id="description"
                                        placeholder="Description *"
                                        cssClass="e-outline"
                                        data-msg-containerid="descriptionErr"
                                        name="descriptionErr"
                                        multiline
                                        value={description}
                                        change={(e) => this.getformData(e, 'description')}
                                        floatLabelType="Auto"
                                    />
                                    <div
                                        id="descriptionErr"
                                        className="error-div"
                                        style={{ fontSize: "13px" }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}
const mapStateToProps = ({ auth, okrreducer }) => {
    const { authUser } = auth;
    const { employeeId, sharedWith, startDate, endDate, percSalaryShared, amountShared, currencyId, description, totalCostSharing } = okrreducer;
    return { authUser, employeeId, sharedWith, startDate, endDate, percSalaryShared, amountShared, currencyId, description, totalCostSharing };
};
export default connect(mapStateToProps, { setCostSharingDetails })(
    NewRequestForm
);
