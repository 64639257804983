import {
    ADD_UPDATE_OKR_DASHBOARD_REDUX
  } from "../../constant/okrdashboardconstant/okrdashboardconstant";
  
  const INIT_STATE = {
    dashboardTabs:'team-rating',
    performanceBusinessOkrData:[],
    performanceCentralOkrData:[],
    potentialData:[],
    fyId:null,
    empId:null,
    teamMemberList:[],
    dashboardStatus:'',
    dashboardUserList:[],
    teamSizeData:[],
    directReporteesData:[],
    teamStatusSelected:'team-size',
    teamMemberIds:'',
    monthOfRating:0,
    ratingStatus:'',
    dashboardTeamRatingStatus:'',
    xyAxixGridRankingData:[],
    xyAxixCardRankingData:[],
    xyAxixGridDefaultRankingData:[],
    navToSelfRatingFlag:'',
    teamMemberSelected:[],
    monthlyOkrStatusMonth:0,
    empName:'',
    monthlyOkrMonthStatus:'',
    isPeopleLeader:null,
    isEmpName: false,
    selectedMonthName:'',
    boxClassification:'',
    teamMedian:null,
    companyMedian:null,
    viewCard: 0,
    navToTeamMemberFlag:'',
    performanceType:'',
    potentialType:'',
    graphStatus:'',
  };
  // eslint-disable-next-line
  export default (state = INIT_STATE, action) => { //NOSONAR
    switch (action.type) {/**nosonar */
      case ADD_UPDATE_OKR_DASHBOARD_REDUX: {
        return {
          ...state,
          ...action.payload
        };
      }
      default:
        return state;
    }
  };
  