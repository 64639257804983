/**
 * Author: Suresh R. Sargam.
 * Last Modify:15-May-2020
 */

import * as React from "react";
import PropTypes from "prop-types";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faArrowAltToTop,
  faCheckCircle,
  faEye,
  faTimesCircle,
  faPlusCircle,
} from "@fortawesome/pro-light-svg-icons";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
} from "@syncfusion/ej2-react-grids";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../app/containers/tmlc/onboarding/onboarding.css";
import "./documents.css";
import {
  NEW_CANDIDATE,
  EXISTING_CANDIDATE,
  NEW_CANDIDATE_UPLOAD,
  EMPLOYEE_UPLOAD,
} from "../../redux/constant/entryexit/candidateconstant";

library.add(faArrowAltToTop, faEye, faCheckCircle, faTimesCircle, faPlusCircle);

export class Documents extends React.Component {
  constructor(props) {
    super(props);
    this.docObj = null;
    this.onQueryCellInforDocumentation = this.onQueryCellInforDocumentation.bind(
      this
    );
  }

  componentDidMount() {
    const { candidateType } = this.props;
    if (candidateType === NEW_CANDIDATE_UPLOAD) {
      setTimeout(() => {
        if (this.docObj !== null) this.docObj.hideColumns("DocumenytType");
      }, 1000);
    }
  }

  // eslint-disable-next-line
  UNSAFE_componentWillReceiveProps(newProps) {    
    const { changeStatus, id } = this.props;
    if (newProps.changeStatus !== changeStatus && newProps.RefreshId === id) {
      this.docObj.refresh();
    }
    if (newProps.refreshDocStatus === "app_rej_status") {
      this.docObj.refresh();
      this.props.updateDocumentInfo({ refreshDocStatus: "" });
    }
  }

  /**
   * @author Suresh R. Sargam
   * @argument props
   * @description UI of Send Link column section: For sending link to new candidate
   */
  // Send Link template
  sendLinkTemplate = (props) => {
    const { id } = this.props;
    if (props.DefSendLink) {
      return (
        <div style={{ fontSize: "15px" }} className="text-align">
          <FontAwesomeIcon icon={faCheckCircle} style={{ color: "#22AD88" }} />
        </div>
      );
    }
    return (
      <div
        style={{ fontSize: "15px", cursor: "pointer" }}
        className="text-align"
      >
        
        <FontAwesomeIcon
          icon={faCheckCircle}
          style={{ color: props.SendLink ? "#22AD88" : "#4B4C50" }}
          onClick={() => this.props.statusUpdate(id, props.Id, "SendLink")}
        />
      </div>
    );
  };

  /**
   * @author Suresh R. Sargam
   * @argument props
   * @description UI of Preview column section: For Previewing the existing document
   */
  // Preview template
  previewTemlate = (props) => {
    const { id } = this.props;
    if (props.HasFiles && props.docSubmitted!==undefined && props.docSubmitted==='Y') {
      return (
        <div
          style={{ fontSize: "15px", cursor: "pointer" }}
          className="text-align"
        >
          <FontAwesomeIcon
            icon={faEye}
            onClick={() => this.props.statusUpdate(id, props.Id, "PreviewFile")}
          />
        </div>
      );
    }
    return (
      <div style={{ fontSize: "15px" }} className="text-align">
        -
      </div>
    );
  };

  /**
   * @author Suresh R. Sargam
   * @argument props
   * @description UI of Approve column section: For Approving the existing document
   */
  // Approved template
  approvedTemplate = (props) => {
    const { id } = this.props;
    // , candidateType
    if (!props.HasFiles || props.Rejected) {
      return (
        <div style={{ fontSize: "15px" }} className="text-align">
          -
        </div>
      );
    }
    if ((props.docSubmitted===undefined || props.docSubmitted==='N')) {
      return (
        <div style={{ fontSize: "15px" }} className="text-align">
          -
        </div>
      );
    }
    else{
      return (
        <div
          style={{ fontSize: "15px", cursor: "pointer" }}
          className="text-align"
        >
          <FontAwesomeIcon
            icon={faCheckCircle}
            style={{ color: props.Approved ? "#22AD88" : "#4B4C50" }}
            onClick={() => this.props.statusUpdate(id, props.Id, "Approved")}
          />
        </div>
      );
    }
  };

  /**
   * @author Suresh R. Sargam
   * @argument props
   * @description UI of Uplaod column section: For Uploading the document
   */
  // Upload template
  uploadTemplate = (props) => {
    const { id } = this.props;
    /* nosonar if (props.Approved) {
      return (
        <div
          style={{ fontSize: "15px", cursor: "pointer" }}
          className="text-align"
        >
          -
        </div>
      );
    }*/
    return (
      <div
        style={{ fontSize: "15px", cursor: "pointer" }}
        className="text-align"
      >
        <FontAwesomeIcon
          icon={faArrowAltToTop}
          title="Upload"
          onClick={() => this.props.statusUpdate(id, props.Id, "OpenDoc")}
        />
      </div>
    );
  };

  /**
   * @author Suresh R. Sargam
   * @argument props
   * @description UI of Reject column section: For Rejecting the existing document
   */
  // Rejected template
  rejectTemplate = (props) => {
    const { id } = this.props;
    // , candidateType
    // if (!props.HasFiles || props.Approved) {
    if (!props.HasFiles ) {
      return (
        <div style={{ fontSize: "15px" }} className="text-align">
          -
        </div>
      );
    }
    if((props.docSubmitted===undefined || props.docSubmitted==='N')){
      return (
        <div style={{ fontSize: "15px" }} className="text-align">
          -
        </div>
      );
    }else{
      return (
        <div
          style={{ fontSize: "15px", cursor: "pointer" }}
          className="text-align"
        >
          <FontAwesomeIcon
            icon={faTimesCircle}
            style={{ color: props.Rejected ? "#F74E61" : "#4B4C50" }}
            onClick={() => this.props.statusUpdate(id, props.Id, "Rejected")}     
          />
           {/* props.docSubmitted!==undefined &&  props.docSubmitted==='Y' */}
          {props.DocRejectComments === "Y" && props.docSubmitted!==undefined &&  props.docSubmitted==='Y' &&(
            <FontAwesomeIcon
              icon={faEye}
              style={{ color: "#4B4C50", marginLeft: 5 }}
              onClick={() => this.props.statusUpdate(id, props.Id, "ViewRejectedComments")}
            />
          )}
        </div>
      );
    }
  };

  /**
   * @author Suresh R. Sargam
   * @argument props
   * @description UI of Comments column section: For Adding/Viewing the comments against existing document
   */

  // Comments template
  addCommentsTemplate = (props) => {
    const { id } = this.props;
    if (!props.HasFiles) {
      return (
        <div style={{ fontSize: "15px" }} className="text-align">
          -
        </div>
      );
    }
    return (
      <div
        style={{ fontSize: "15px", cursor: "pointer" }}
        className="text-align"
      >
        <FontAwesomeIcon
          icon={faPlusCircle}
          style={{ color: "#4B4C50" }}
          onClick={() => this.props.statusUpdate(id, props.Id, "Comments")}
        />
        { props.DocComments === "Y" && (
          <FontAwesomeIcon
            icon={faEye}
            style={{ color: "#4B4C50", marginLeft: 5 }}
            onClick={() => this.props.statusUpdate(id, props.Id, "ViewComments")}
          />
          )}
          {/* <span>---</span> */}
      </div>
    );
  };

  /**
   * @author Suresh R. Sargam
   * @argument props
   * @description UI of Last Moddified date column section
   */
  // : getDisplayDateFormat(new Date(props.LastModified))}
  // Last Modified date template
  dateFormatTemplate = (props) => {
    return (
      <div className="text-align">
        {(props.LastModified === null ) || (props.docSubmitted===undefined || props.docSubmitted==='N')
          ? "-"
          : props.LastModified}
      </div>
    );
  };

  onQueryCellInforDocumentation = (args) => {
    const { cell } = args;
    cell.setAttribute("style", "font-size:14px !important");
  };

  /**
   * @author Suresh R. Sargam
   * @argument props
   * @description UI of New Candidate uplaoding template column section: For Uplaoding/Previewing/Adding Comments/Viweing Comments in New Candidate upload screen
   */

  // Comments template
  addCandidateUpload = (props) => {
    const { id } = this.props;
    return (
      <div 
        // style={{ fontSize: "14px" }}
      >
         <div>
          <span className="doc-cat-title" style={{ float: "left" }}>{props.DocumenytType} </span>
          {// Show the Approved/ Rejected icon if it is approved or rejected
          }
          {props.HasFiles && (
            <div style={{ float: "left", marginRight: 6 }}>
              {props.Approved && (
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  style={{ color: "#22AD88" }}
                  title="Approved"
                />
              )}
              {props.Rejected && (
                <FontAwesomeIcon
                  icon={faTimesCircle}
                  style={{ color: "#F74E61" }}
                  title="Rejected"
                />
              )}
            </div>
          )}

        </div>

        <div
          className="col-xs-12 col-sm-12 col-lg-12 col-md-12 mt-md mb-md"
          style={{ fontSize: "15px" }}
        >
          <div className="row1" style={{ float: "left" }}>
            <div
              className={props.HasFiles ? "col-sm-4 col-lg-4 col-md-4" : ""}
              style={{ marginLeft: props.HasFiles ? "0" : "19px" }}
            >
              <div className="text-align">
                {// Show the upload icon if the already uploaded documents are rejected or not uploaded 
                }
                <div>
                  {props.Approved ? (
                    "-"
                  ) : (
                      <FontAwesomeIcon
                        icon={faArrowAltToTop}
                        style={{ cursor: "pointer" }}
                        onClick={() => this.props.statusUpdate(id, props.Id, "OpenDoc")}
                      />
                    )}
                </div>
                <div>
                  <span style={{ fontSize: "12px" }}>Upload</span>
                </div>
              </div>
            </div>

            {props.HasFiles && (
              <div className="col-sm-4 col-lg-4 col-md-4">
                <div className="text-align">
                  {//Show the preview icon if document is exist 
                  }
                  <div>
                    {" "}
                    {props.HasFiles ? (
                      <FontAwesomeIcon
                        icon={faEye}
                        style={{
                          cursor: "pointer",
                          color: "#4B4C50",
                          marginRight: 10,
                        }}
                        onClick={() =>
                          this.props.statusUpdate(id, props.Id, "PreviewFile")
                        }
                      />
                    ) : (
                        "-"
                      )}
                  </div>
                  <div>
                    <span style={{ fontSize: "12px" }}>Preview</span>
                  </div>
                </div>
              </div>
            )}

            {props.HasFiles && (
              <div className="col-sm-4 col-lg-4 col-md-4">
                <div className="text-align">
                  {// Show the comments (add/view) icon if document is exist 
                  }
                  <div>
                    {props.HasFiles ? (
                      <div>
                        <FontAwesomeIcon
                          icon={faPlusCircle}
                          style={{ color: "#4B4C50", cursor: "pointer" }}
                          onClick={() => this.props.statusUpdate(id, props.Id, "Comments")}
                        />
                        {props.DocComments === "Y" && (
                          <FontAwesomeIcon
                            icon={faEye}
                            style={{
                              color: "#4B4C50",
                              marginLeft: 5,
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              this.props.statusUpdate(id, props.Id, "ViewComments")
                            }
                          />
                        )}
                      </div>
                    ) : (
                        "-"
                      )}
                  </div>
                  <div>
                    <span style={{ fontSize: "12px" }}>Comments</span>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div> 
      </div>
    );
  };

  render() {
    const { header, candidateType, content } = this.props;    
    return (
      <div>
        <div>{header}</div>
        <div className="control-section col-md-12">
          <div className="card-layout">
            {candidateType === NEW_CANDIDATE && (
              <GridComponent
                ref={(t) => {
                  this.docObj = t;
                }}
                dataSource={content}
                queryCellInfo={this.onQueryCellInforDocumentation}
                width="700px"
                style={{ maxHeight: "800px" }}
              >
                <ColumnsDirective>
                  <ColumnDirective
                    field="DocumenytType"
                    headerText="&nbsp;&nbsp;Category"
                    width="70%"
                    textAlign="Left"
                    style={{ marginLeft: "15px" }}
                  />
                  <ColumnDirective
                    field="SendLink"
                    headerText="Send Link"
                    width="30%"
                    textAlign="Center"
                    filter={this.SendLink}
                    template={this.sendLinkTemplate}
                  />
                </ColumnsDirective>
              </GridComponent>
            )}
            {candidateType === NEW_CANDIDATE_UPLOAD && (
              // <h1>Hello world</h1>
              <GridComponent
                ref={(t) => {
                  this.docObj = t;
                }}
                style={{ maxHeight: "800px" }}
                dataSource={content}
                queryCellInfo={this.onQueryCellInforDocumentation}
              >
                <ColumnsDirective>
                  <ColumnDirective
                    field="DocumenytType"
                    textAlign="Left"
                    template={this.addCandidateUpload}
                  />
                </ColumnsDirective>
              </GridComponent>
            )}
            {(candidateType === EXISTING_CANDIDATE ||
              candidateType === EMPLOYEE_UPLOAD) && (
                <GridComponent
                  id="overviewgrid"
                  ref={(t) => {
                    this.docObj = t;
                  }}
                  style={{ maxHeight: "800px" }}
                  dataSource={content}
                  queryCellInfo={this.onQueryCellInforDocumentation}
                >
                  <ColumnsDirective>
                    <ColumnDirective
                      field="DocumenytType"
                      headerText="&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Category"
                      width="200"
                      textAlign="Left"
                    />
                    <ColumnDirective
                      field="LastModified"
                      headerText="Last Modified"
                      width="180"
                      template={this.dateFormatTemplate}
                    />
                    <ColumnDirective
                      field="Approved"
                      headerText="Approved"
                      width="80"
                      template={this.approvedTemplate}
                    />
                    {
                     localStorage.getItem('SelectedTabAction') === "To Be Offered"&&
                    <ColumnDirective
                      field="Rejected"
                      headerText="Rejected"
                      width="80"
                      template={this.rejectTemplate}
                    />
                    }
                    <ColumnDirective
                      field="Preview"
                      headerText="Preview"
                      width="80"
                      template={this.previewTemlate}
                    />
                    {
                    // getTabActive!== "To Be Offered" && candidateid > 0 &&
                    <ColumnDirective
                      field="Upload"
                      headerText="Upload"
                      width="80"
                      template={this.uploadTemplate}
                    />
                    }
                    <ColumnDirective
                      field="Add"
                      headerText="Add"
                      width="80"
                      template={this.addCommentsTemplate}
                    />
                  </ColumnsDirective>
                </GridComponent>
              )}
          </div>
        </div>
      </div>
    );
  }
}

Documents.propTypes = {
  // statusUpdate: PropTypes.func,
  id: PropTypes.number,
  candidateType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  changeStatus: PropTypes.bool,
  content: PropTypes.arrayOf(PropTypes.object).isRequired,
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  candidateid: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};
Documents.defaultProps = {
  // statusUpdate: {},
  id: 0,
  candidateid: 0,
  changeStatus: false,
};

export default Documents;
