import React, { Component } from "react";
import AdminServices from "../../../services/adminservices";
import OrgcharPopUp from "./orgchartpopup";
import "./orgchartadmin.css";
import { connect } from "react-redux";
import { Button } from "@material-ui/core";
import {
  updateBusinessFunctionRedux,
  saveBusinessUnitFunction,
} from "../../../redux/actions/admin/adminaction";
import { updateCandidateData } from '../../../redux/actions/entryexit/candidateaction'

import ActionTemplate from "./actionTemplate";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  DetailRow,
  Inject,
  Page,
  Filter
} from "@syncfusion/ej2-react-grids";
import Progressbar from "../../../components/progressbar/progressbar";
import ErrorMessage from "../../errormessage/errormessage";
import './orgChartMaster.css';
import {
  getGridColumn,
  BUSINESS_UNIT_FUNCTION,
  HIERARCHY,

  ABBREVATION,

  EMPLOYEE_COUNT,
  ACTION,
  PARENTLAYER_SBU,
  DOMAIN,
  COMPANY_NAME
} from "../../../app/other/gridcolumns";
import "./orgChart.css"

import stylingNewBussinessUnit from "./orgChartStylingGridComponents"
import { TooltipComponent, setSpinner } from '@syncfusion/ej2-react-popups';
import { spinner } from "../../../app/other/commonfunction";


class BusinessFunction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      height:
        (window.innerHeight ||
          document.documentElement.clientHeight ||
          document.body.clientHeight) - 188,
      gridHeight: (window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight) - 290,
      heightPending:
        (window.innerHeight ||
          document.documentElement.clientHeight ||
          document.body.clientHeight) - 188,
      result: [],
      openPopup: false,
      title: "",
      type: "",
      maxHierarchy: 0,
      isLoading: true,
      parentWidth: null
    };
    this.closePopup = this.closePopup.bind(this);
    this.openPopup = this.openPopup.bind(this);
    this.actionTemplate = this.actionTemplate.bind(this);
    this.width =
      (window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth) - 100; // 100
    this.header = "Business Unit / Group Function ";
    this.Filter = {
      type: "CheckBox",
    };
    this.check = { type: 'CheckBox' };
    this.onDataBinding = this.onDataBinding.bind(this)
    this.ondataBound = this.onDataBound.bind(this)
    this.initialLoad = true;
    this.parentRef = React.createRef();
  }

  updateDimensions() {
    const updateHeight = window.innerHeight - 230;
    const updateHeightp = window.innerHeight - 185;
    this.setState({ height: updateHeight, heightPending: updateHeightp });
  }

  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions.bind(this));
    this.getBusinessFunctionData();

    if (this.parentRef && this.parentRef.current) {
      const width = this.parentRef.current.getBoundingClientRect().width;
      this.setState({ parentWidth: width });
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.busiess_unit_function_flag === "updated") {
      this.getBusinessFunctionData();
      this.closePopup();
      this.props.updateBusinessFunctionRedux({
        busiess_unit_function_flag: "",
      });
    }
  }
  makeActiveInActive = (id, status) => {
    const { authUser } = this.props;
    let data = {
      authUser: authUser,
      businessUnitFunctionId: id,
      isActive: status,
      type: "Status",
    };
    this.props.saveBusinessUnitFunction(data);
  };
  getBusinessFunctionData = () => {
    let resultData = []
    let data
    try {
      const businessFunction = Promise.resolve(
        AdminServices.getAdminOrgChartGridData("businessUnitFunction")
      );
      businessFunction.then((result) => {
        if (result !== null) {
          result.result.map((item) => {
            data = {
              Id: item.Id,
              Name: item.businessUnitFunction,
              Type: 'PnL'
            }
            resultData.push(data)
          })
          this.props.updateCandidateData({ entityMaster: resultData })
          const maxHierarchy = result.result.reduce(
            (acc, item) => (acc > item.hierarchy ? acc : item.hierarchy),
            0
          );
          this.setState({
            result: result.result,
            maxHierarchy: maxHierarchy + 1,
            isLoading: false
          });
        }
      });
    } catch (error) {
      // eslint-disable-next-line
      console.error(error);
    }
  };
  openPopup(type, businessUnitFunctionAreaData) {
    let test = {}
    if (typeof businessUnitFunctionAreaData !== "undefined")
      test["businessUnitFunctionId"] = businessUnitFunctionAreaData.Id;
    test = { ...test, ...businessUnitFunctionAreaData }

    this.setState({
      openPopup: true,
      title: this.header,
      type: type,
      businessUnitFunctionAreaData: test,
    });
  }

  closePopup() {
    this.setState({
      openPopup: false,
      title: "",
      type: "",
      businessUnitFunctionAreaData: "",
    });
  }
  actionTemplate = (props) => {
    return (<div style={{ display: "flex", justifyContent: "center", marginLeft: -10, width: "100%" }}>
      <ActionTemplate
        props={props}
        category="BUSINESS_FUNCTION"
        makeActiveInActive={this.makeActiveInActive}
        openPopup={this.openPopup}
      />
    </div>
    )
  }
  onDataBound() {
    clearTimeout(this.clrIntervalFun);
    clearInterval(this.intervalFun);
    this.dtTime = true;
    spinner(false)
  }
  onLoad(args) {
    document
      .getElementById("overviewgrid")
      .ej2_instances[0].on("data-ready", () => {
        this.dReady = true;
        this.stTime = performance.now();
      });
    document
      .getElementById("overviewgrid")
      .addEventListener("DOMSubtreeModified", () => {
        if (this.dReady && this.stTime && this.isDataChanged) {
          this.stTime = null;
          this.dReady = false;
          this.dtTime = false;
          this.isDataChanged = false;
        }
      });
  }
  setHeaderCss = () => {
    setTimeout(() => {
      const ele = document.getElementsByClassName("e-headercelldiv");
      for (let i in ele) {
        if (!isNaN(parseInt(i, 10))) {
          let index = parseInt(i, 10);

          if (ele[index].innerText === ACTION) {
            ele[index].classList.add("grid-center-g-header");
          } else {
            ele[index].classList.add("grid-left-g-header");
          }
        }
      }
    }, 500);
  }

  level1ActionRole = (props) => {
    return (
      <p style={{ textAlign: "center" }}>{props.hierarchy}
      </p>
    )
  }

  level2ActionRole = (props) => {
    return (
      <p style={{ textAlign: "center" }}>{props.empCnt}
      </p>
    )
  }
  baselocationTemp = (props) => {
    return (
      <p style={{ textAlign: "center" }}>{props.abrivation}
      </p>
    )
  }
  parentTemplate = (props) => {
    return (
      <p style={{ textAlign: "left", fontSize: '14px' }}>{props.parentLayerName}</p>
    )
  }

  /**
  * Created on 09-09-2022 || Noel Fredrick
  * Description - For spinner
  */
  onDataBinding() {
    spinner(true)
    // try {
    //   refreshMultiSelect(pComponentId);
    //   setTimeout(() => {
    //     document.getElementById(pComponentId).ej2_instances[0].refresh()
    //   })
    // } catch (error) {
    //   console.log(error)
    // }
  }



  onFirstGridCreated() {
    if (this.initialLoad) {
      this.initialLoad = false;
      setSpinner({
        template: document.getElementById('spinnerContainer').innerHTML,
      });
      document.getElementById('spinnerContainer').remove();
      // this.gridInstance.freezeRefresh(); // re-render the grid component
    }

  }






  // showLoader=()=>{
  //   return (
  //      <div  style={{zIndex:11, position:'absolute', marginTop:'20%', marginLeft:'50%'}}>
  //             <ProgressBar />
  //         </div>
  //   )
  // }


  render() {
    const {
      openPopup,
      height,
      result,
      title,
      type,
      businessUnitFunctionAreaData,
      maxHierarchy,
      gridHeight,
      isLoading,
      parentWidth
    } = this.state;
    this.setHeaderCss();
    var widthColumnConfirm = 0;
    let finalWidthConfirm;
    // eslint-disable-next-line
    getGridColumn("orgChartMasterAdmin").forEach((data) => {
      widthColumnConfirm = widthColumnConfirm + 5 + data.width;
    });
    if (window.innerWidth === 1024) {
      finalWidthConfirm = 1350
    } else if (window.innerWidth === 1920) {
      finalWidthConfirm = Math.min(widthColumnConfirm + 150, this.width);
    } else {
      finalWidthConfirm = this.width + 250;
    }

    let heightAdjustment = 0;
    const windowHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    if (windowHeight > 767) {
      heightAdjustment = 65;
    } else if (windowHeight === 599 || windowHeight === 731) {
      heightAdjustment = 129;
    } else if (windowHeight === 551) {
      heightAdjustment = 100;
    } else {
      heightAdjustment = 60;
    }
    const gridHeightNew = gridHeight - heightAdjustment;
    return (
      <>
        <div
          style={{
            color: "black",
            height: height,
            // height: height + 58,
            padding: "8px 4px 8px 19px ",
            marginBottom: "16px ",
          }}
          className='businessfunc-height' id="businessfunc-id"
        >
          {
            result.length > 0 ? <>
              <div className="row">
                <div
                  className="col-xs-5 col-sm-5 col-lg-5 col-md-5"
                  style={{ marginBottom: 8 }}
                >
                  <span className="add-header">{this.header} </span>
                </div>
                <div
                  className="col-xs-5 col-sm-5 col-lg-5 col-md-5 pr-xl"
                  style={{ paddingBottom: 4, textAlign: 'right' }}
                >
                  <Button
                    id="edit"
                    color="primary"
                    variant="contained"
                    onClick={() => this.openPopup("Add")}
                    className="addbtn"
                  >
                    Add
                  </Button>
                </div>
              </div>
              <div className="row">
                <div className="col-xs-10 col-sm-10 col-lg-10 col-md-10" ref={this.parentRef}>
                  <div style={{ paddingTop: "5px", overflow: "auto" }}
                    className="control-section"
                  ><style>{stylingNewBussinessUnit}</style>
                    <GridComponent
                      className="grid-head-custome"
                      id="overviewgrid"
                      allowPaging={false}
                      allowFiltering
                      filterSettings={this.Filter}
                      rowHeight={40}
                      height={gridHeightNew}
                      // width={finalWidthConfirm}
                      // actionComplete={this.onComplete.bind(this)}
                      load={this.onLoad.bind(this)}
                      dataBound={this.onDataBound.bind(this)}
                      gridLines='Both'
                      pageSettings={{
                        pageSize:
                          (window.innerHeight ||
                            document.documentElement.clientHeight ||
                            document.body.clientHeight) > 767
                            ? 12 //12
                            : 7,
                        pageCount: 5
                      }}
                      dataSource={result}
                      allowTextWrap={true}
                      onDataBinding={this.onDataBinding("businessfunc-id")}
                      created={this.onFirstGridCreated.bind(this)}
                    >
                      <ColumnsDirective>
                        {getGridColumn("orgChartMasterAdminBusinessUnit").map((item) => {
                          switch (item.colName) {
                            case BUSINESS_UNIT_FUNCTION:
                              return (
                                <ColumnDirective
                                  key={item.field}
                                  // template={this.employeeNameTemplate}
                                  textAlign={'left'}
                                  field={item.field}
                                  headerText={item.colName}
                                  width={item.width}
                                  filter={this.check}
                                // className="bussinessName"
                                />
                              );
                            case HIERARCHY:
                              return (
                                <ColumnDirective
                                  key={item.field}
                                  template={this.level1ActionRole}
                                  textAlign={item.align}
                                  field={item.field}
                                  headerText={item.colName}
                                  width={item.width}
                                  filter={this.check}

                                />
                              );

                            case ABBREVATION:
                              return (
                                <ColumnDirective
                                  key={item.field}
                                  template={this.baselocationTemp}
                                  textAlign={`${item.align}`}
                                  field={item.field}
                                  headerText={item.colName}
                                  width={item.width}
                                  filter={this.check}
                                />
                              );

                            case EMPLOYEE_COUNT:
                              return (
                                <ColumnDirective
                                  key={item.field}
                                  template={this.level2ActionRole}
                                  textAlign={item.align}
                                  field={item.field}
                                  headerText={item.colName}
                                  width={item.width}
                                  // filter={false}
                                  filter={this.check}
                                  type={"number"}
                                />
                              );

                            case ACTION:
                              return (
                                <ColumnDirective
                                  key={item.field}
                                  template={this.actionTemplate}
                                  textAlign={item.align}
                                  field={item.field}
                                  headerText={item.colName}
                                  width={item.width}
                                  filter={false}
                                />
                              );

                            case PARENTLAYER_SBU:
                              return (
                                <ColumnDirective
                                  key={item.field}
                                  template={this.parentTemplate}
                                  textAlign={item.align}
                                  field={item.field}
                                  headerText={item.colName}
                                  width={item.width}
                                  filter={this.check}
                                />
                              );

                            case COMPANY_NAME:
                              return (
                                <ColumnDirective
                                  key={item.field}                                  
                                  textAlign={item.align}
                                  field={item.field}
                                  headerText={item.colName}
                                  width={item.width}
                                  filter={this.check}
                                />
                              );

                            case DOMAIN:
                              return (
                                <ColumnDirective
                                  key={item.field}                                  
                                  textAlign={item.align}
                                  field={item.field}
                                  headerText={item.colName}
                                  width={item.width}
                                  filter={this.check}
                                />
                              );

                            default:
                              return (
                                <ColumnDirective
                                  key={item.field}
                                  // template={this.roleTemp}
                                  textAlign={item.align}
                                  field={item.field}
                                  headerText={item.colName}
                                  width={item.width}
                                  filter={this.check}
                                />
                              );

                          }
                        })}
                      </ColumnsDirective>
                      <Inject services={[Page, DetailRow, Filter]} />
                    </GridComponent>
                    <div className="loader-new" id="spinnerContainer" >
                      <Progressbar />
                    </div>
                  </div>
                </div>
              </div>
            </>
              :
              <div className="control-section col-md-12" style={{ height: 75 }}>
                {isLoading && (<div className="loader-new">
                  {/* <Progressbar /> */}
                </div>)
                }
                {(result.length === 0 && isLoading === false) && (
                  <ErrorMessage message="No Data Available" />
                )}
              </div>
          }

        </div>
        <div>
          {openPopup && (
            <OrgcharPopUp
              closePopup={this.closePopup}
              component={"BusinessUnitFunction"}
              title={title}
              type={type}
              businessUnitFunctionAreaData={businessUnitFunctionAreaData}
              maxHierarchy={type === "Add" ? 3 : maxHierarchy}
              result={result}
            />
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ adminreducer, auth }) => {
  const { busiess_unit_function_flag } = adminreducer;
  const { authUser } = auth;

  return { busiess_unit_function_flag, authUser };
};

export default connect(mapStateToProps, {
  updateBusinessFunctionRedux,
  saveBusinessUnitFunction,
  updateCandidateData
})(BusinessFunction);
