/**
 * Author: Abdulla Shaikh.
 * Created:21-Feb-2023
 * @description to view all HelpDesk History
 */
import React, { useEffect } from 'react';
import "./helpdesk.css";
import { HtmlEditor, QuickToolbar } from "@syncfusion/ej2-react-richtexteditor";
import Comments from '../../utilities/comment';
import UploadDocuments from "../okrratingcomponents/uploaddocuments";
import RichTextEditor from '../../utilities/richtexteditorcomponent';
import { getNotify } from '../../app/other/commonfunction';
import { SUCCESS } from '../../assets/constants/constants';

function HistoryHelpDesk({ showAddUpdate, comments, state, setState, ...props }) {

	useEffect(() => {
		showAddUpdate &&
			document
				.getElementById("helpdesk-upload-doc")
				?.scrollIntoView({ behavior: "smooth" });
	}, [showAddUpdate]);
  

  const getFileDetails = (type, file) => {
    if (type === 'add' && file) {
      setState(prevState => ({ ...prevState, file: [...prevState.file, file], fileName: [...prevState.fileName, file.name] }));
      getNotify(SUCCESS, "Document added.");
    }

    if (type === 'remove') {
      const fileList = state.file.filter((s, idx) => idx !== file);
      const fileName = state.fileName.filter((s, idx) => idx !== file);
      setState(prevState => ({ ...prevState, file: [...fileList], fileName: [...fileName] }));
    }
  } 

  return (
    <div id='helpdesk-history-id'>
      <div className="col-sm-12 col-md-12 col-lg-12 pb-md pt-md pl-md pb-md helpdesk-history">
        History
      </div>
      {comments.map((commentData, id) => <Comments key={id} comment={commentData} id={id}/>)} {/*nosonar*/}
      {showAddUpdate &&
        <><div className='helpdesk-upload'  id="helpdesk-upload-doc">
          <div className="helpdesk-text pt-md pr-md  pb-md" >Add Update </div>
          <div>
            <RichTextEditor
              cssClass="refreshUIRichextBox circular-regular"
              id="ticket-comment"
              services={[HtmlEditor, QuickToolbar]}
              value={state.comment}
              maxLength={500}
              onChange={(e) => setState(prevState => ({ ...prevState, comment: e.value }))}
              showCharCount={true}
              actionBegin={(e)=>{
                    if(e.requestType==="Image"){
                        e.cancel=true;
                    }
              }}
              toolbarSettings = {{
                items: ['Formats', 'Alignments', '|', 'OrderedList', '|',
                    'Outdent', 'Indent','|','Bold', 'Italic', 'Underline', '|', 'ClearFormat', 'FullScreen'
                ],
                type: 'Expand'
              }}
            />
          </div>
        </div>
          <div className='helpdesk-upload circular-regular'>
            <div className="helpdesk-text pt-md pr-md  pb-md circular-regular">Upload Document(s) </div>
            <div style={{ height: "50px" }}>
              <UploadDocuments
                type={"comment"}
                allowmultiple={false}
                getFileDetails={getFileDetails}
                fileList={state.fileName}
                apiUrl={'ApiUrl'}
                documentDetails={() => { }}//NOSONAR
                files={state.file}
              />
            </div>
          </div>

        </>}
    </div>
  )
}

export default HistoryHelpDesk;
