// Author :- Prashant Waphare 
// Date :- 09-06-2020
// Description:- This component use for employee template

import React, { Component } from 'react';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { urlConfig } from '../../config/config';

class Employeenametemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    this.navigateToAddBasicInformation = this.navigateToAddBasicInformation.bind(this);
  }
  navigateToAddBasicInformation = (empData) => {
    this.props.navigateToAddBasicInformation(empData)
  }
  addDefaultSrc(ev) {
    ev.target.parentNode.innerHTML = '<span  class="img-circle fas fa-user-circle img-circle-grid-photo" alt="" ></span>'
  }
  render() {
    const { actioned } = this.props.props;
    const {type, empListType}=this.props;
    let imgType= type==="Emp"? "E":"A";
    return (
      <div className='row control-section col-md-12' style={{ flexWrap: 'wrap', display: '-webkit-box' }}>
        <div className="col-xs-2 col-sm-2 col-lg-2 col-md-12">
          {
            this.props.props.photoNm === undefined || this.props.props.photoNm === '' || this.props.props.photoNm === null || this.props.props.photoNm === 'default.png' ?
              <center><span className="img-circle fas fa-user-circle img-circle-grid-photo" alt="" /></center>
              :
              <center><img className="img-circle image-show-in-grid-view"
                onError={this.addDefaultSrc}
                src={
                  urlConfig.tmlcApiUrl + 'document/'+imgType+'/' +// props.authUser + 
                  '0/' + this.props.props.photoNm
                } alt="" /></center>
          }
        </div>
        <div className="col-xs-2 col-sm-2 col-lg-8 col-md-12" style={{ paddingLeft: '5px' }}>
          <span className={actioned !== 'Y' ? !empListType ? "span pointer-cursor" : "span" : "span"} onClick={actioned !== 'Y' ? () => this.navigateToAddBasicInformation(this.props.props) : undefined}>{this.props.props.employeeName !== undefined ? this.props.props.employeeName : this.props.props.EmployeeName}</span><br />
          <TooltipComponent closeOn="Click" position="BottomCenter" isSticky={false}
            content={this.props.props.role !== undefined ? this.props.props.role.replace(/(?:\r\n|\r|\n)/g, '<br>') : this.props.props.Role}
            // content={this.props.props.titleRole ? this.props.props.titleRole : this.props.props.titleRole.replace(/-/g, ' ')}
            cssClass='customtooltip'>
            <span className="span text-limit" style={{ fontSize: '13px' }} >{this.props.props.role !== undefined ? this.props.props.role : this.props.props.Role}</span>
            {/* <span className="span text-limit" style={{ fontSize: '13px' }} >{this.props.props.titleRole ? this.props.props.titleRole : this.props.props.titleRole.replace(/-/g, ' ')}</span> */}
          </TooltipComponent>

        </div>
      </div>
    );
  }
}
export default Employeenametemplate;