import React, { Component } from 'react';
import { connect } from 'react-redux';
import SelfRating from '../../containers/selfrating/selfrating';

import { OKR_RATING_AND_REVIEW } from '../../../redux/constant/meetingcategoryconstant'
import { addUpdateOkrRatingRedux } from '../../../redux/actions/okrrating/okrratingaction';
import { setCentralOkrKPIRedux } from '../../../redux/actions/okr/okractions';
import OKRServices from '../../../services/okr/okrservices';
import './okrrating.css'
import queryString from "query-string";
import { dencrypt } from '../../other/commonfunction'
import { getTMLCUsers } from '../../other/commonlayoutfunction.js';
import { setMeetingMaster } from '../../../redux/actions/meetingmasteraction';

class OkrRatingLayout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isloaded: false,
            moduleId: 0
        }
    }
    componentDidMount() {
        this.setModuleId()
        this.getMasterList();
    }

    setModuleId() {
        try {
            // Set App Id
            const { moduleId } = this.state
            const params = queryString.parse(this.props.location.search);
            if (typeof params.id !== 'undefined' && params.id !== null) {
                if (moduleId !== dencrypt(params.id))
                    this.setState({ moduleId: dencrypt(params.id) })
            }
        } catch (error) {
            console.log("🚀 ~ file: okrratinglayout.jsx ~ line 34 ~ OkrRatingLayout ~ setModuleId ~ error", error)

        }
    }

    getRatingMnth = () => {
        let mnth = new Date().getMonth() + 1;
        return mnth === 1 ? 12 : mnth - 1;
    }
    componentWillReceiveProps(nextProps) {
        try {
            const { categoryName } = this.props
            if (nextProps.categoryName !== categoryName) {
                this.props.addUpdateOkrRatingRedux({ monthOfRating: this.getRatingMnth() })
            }
        } catch (error) {

        }
    }
    getMasterList = async () => {
        const { authUser, othersCheckAvailablity } = this.props;
        try {
            const getMasterData = Promise.resolve(OKRServices.getOKRMaster(authUser));
            getMasterData.then((resultData) => {
                if (resultData !== null && resultData.Result !== false) {
                    this.props.setCentralOkrKPIRedux({ OkrMasters: resultData.Result })
                    // Load the supervisor user list
                    const userListSupervior = resultData.Result.filter((item) => { return item.var_list_name === "RatingSup"; });
                    this.props.addUpdateOkrRatingRedux({ supervisorUsers: userListSupervior })

                    // Load the self user list
                    const userListSelf = resultData.Result.filter((item) => { return item.var_list_name === "RatingSelf"; });
                    this.props.addUpdateOkrRatingRedux({ selfUser: userListSelf })

                    const userListTP = resultData.Result.filter((item) => { return item.var_list_name === "RatingTP"; });
                    this.props.addUpdateOkrRatingRedux({ TPUsers: userListTP })

                    const reviewTP = resultData.Result.filter((item) => { return item.var_list_name === "Review"; });
                    this.props.addUpdateOkrRatingRedux({ reviewUsers: reviewTP })

                } else {
                    this.data = "error";
                }
                setTimeout(() => {
                    this.setState({ isloaded: true })
                }, 200);
            });

            if (othersCheckAvailablity.length === 0) {
                const othersCheckAvailablityData = await getTMLCUsers(authUser)
                this.props.setMeetingMaster(othersCheckAvailablityData);
            }
        } catch (e) {
            console.log(e);
        }
    }


    componentDidUpdate(newProps) {
        try {
            this.setModuleId()
        } catch (error) {

        }
    }

    render() {
        const { categoryName, finacialYearData } = this.props;
        const { isloaded, moduleId } = this.state
        let activeModule = (moduleId == 0 ? categoryName : moduleId).toString()
        return (
            isloaded > 0 &&
            finacialYearData.length > 0 &&
            <div>
                {
                    activeModule === OKR_RATING_AND_REVIEW &&
                    <div>
                        <SelfRating />
                    </div>
                }
            </div>
        )
    }
}

const mapStateToProps = ({ router, meetingcategory, auth, okrreducer, meetingmaster }) => {
    const { location } = router;
    const { authUser } = auth;
    const { categoryName } = meetingcategory;
    const { OkrMasters } = okrreducer;
    const { finacialYearData, othersCheckAvailablity } = meetingmaster;
    return {
        location,
        categoryName,
        authUser,
        OkrMasters,
        finacialYearData, othersCheckAvailablity
    }
}
export default connect(mapStateToProps, { addUpdateOkrRatingRedux, setCentralOkrKPIRedux, setMeetingMaster })(OkrRatingLayout);
