import React from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt, faPlus, faEye } from "@fortawesome/pro-light-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";
import { WARNING, youCannotAddThirdPartyInterimHereIn } from '../../assets/constants/constants';
import { getNotify } from '../../app/other/commonfunction';
library.add(faPencilAlt, faPlus, faEye)

function RatingKR(props) {//NOSONAR
    const { ratingData, ratingTabs, OKRAllocationStatus } = props;
    const renderSelfSup = (item) => {
        try {

            if (ratingTabs === 'self-rating') {
                if (item.selfRecommendationAccess === "Y") {
                    return (
                        <>
                            <td className='kr-self-rating'>
                                <div>
                                    {
                                        item.krSelfRecommendationRating.Type === 'Add' &&
                                        <span title="Add self rating" className="ratingIconColor" onClick={() => props.openKrRatingPopup(item, "self")} >
                                            <FontAwesomeIcon style={{ color: "#F94F5E" }} icon={faPlus} />
                                        </span>
                                    }
                                    {
                                        item.krSelfRecommendationRating.Type === 'Edit' &&
                                        <>
                                            <span className='rating-okr-onhover-text rating-grid-text' style={{ cursor: 'auto' }}>{item.krSelfRecommendationRating.Rating}</span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            <span style={{ color: "#B7B7BB" }}> | </span>&nbsp;&nbsp;&nbsp;
                                            <span title="Edit self rating" className='rating-okr-onhover-text ratingIconColor'>
                                                <FontAwesomeIcon style={{ color: "#F94F5E" }} icon={faPencilAlt} onClick={() => props.openKrRatingPopup(item, "self")} /> </span>
                                        </>
                                    }
                                    {
                                        item.krSelfRecommendationRating.Type === 'View' &&
                                        <>
                                            <span className='rating-okr-onhover-text rating-grid-text' style={{ cursor: 'auto' }}>{item.krSelfRecommendationRating.Rating}</span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            <span style={{ color: "#B7B7BB" }}> | </span> &nbsp;&nbsp;&nbsp;&nbsp;
                                            <span title="View self rating" className='rating-okr-onhover-text ratingIconColor'>
                                                <FontAwesomeIcon style={{ color: "#F94F5E" }} icon={faEye} onClick={() => props.viewKrReviewPopup(item, "Self")} /> </span>
                                        </>
                                    }
                                </div>
                            </td>
                        </>
                    )
                } else {
                    return (
                        <td>
                            {
                                item.krSelfRecommendationRating.Type === "Add" ? <span className="ratingIconColor" ><b></b></span>
                                    :
                                    <>
                                        <span className='rating-okr-onhover-text rating-grid-text' style={{ cursor: 'auto' }}>{item.krSelfRecommendationRating.Rating}</span>
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <span style={{ color: "#B7B7BB" }}> | </span> &nbsp;&nbsp;&nbsp;&nbsp;
                                        <span style={{ color: item.krSupervisorRecommendationRating.IsPass === "Y" ? "#18BC00" : "#B7B7BB" }}>
                                            {item.krSupervisorRecommendationRating.IsPass}</span> &nbsp;&nbsp;&nbsp;&nbsp;<span style={{ color: "#B7B7BB" }}>  |  </span>
                                    </>
                            }
                        </td>
                    )
                }
            } else {
                if (item.supRecommendationAccess === "Y") {
                    return (
                        <>
                            <td className='kr-self-rating'>
                                <div>
                                    {
                                        item.krSupervisorRecommendationRating.Type === 'Add' &&
                                        <span title="Add supervisor rating" className="ratingIconColor" onClick={() => props.openKrRatingPopup(item, "sup")} ><FontAwesomeIcon style={{ color: "#F94F5E" }} icon={faPlus} /></span>
                                    }
                                    {
                                        item.krSupervisorRecommendationRating.Type === 'Edit' &&
                                        <>
                                            <span className='rating-okr-onhover-text rating-grid-text' style={{ cursor: 'auto' }}>{item.krSupervisorRecommendationRating.Rating}</span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            <span style={{ color: "#B7B7BB" }}> | </span>&nbsp;&nbsp;&nbsp;
                                            <span title="Edit supervisor rating" className='rating-okr-onhover-text ratingIconColor'>
                                                <FontAwesomeIcon style={{ color: "#F94F5E" }} icon={faPencilAlt} onClick={() => props.openKrRatingPopup(item, "sup")} /> </span>
                                        </>
                                    }
                                    {
                                        item.krSupervisorRecommendationRating.Type === 'View' &&
                                        <>
                                            <span className='rating-okr-onhover-text rating-grid-text' style={{ cursor: 'auto' }}>{item.krSupervisorRecommendationRating.Rating}</span>
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                            <span style={{ color: "#B7B7BB" }}> | </span> &nbsp;&nbsp;&nbsp;&nbsp;
                                            <span title="View supervisor rating" className='rating-okr-onhover-text ratingIconColor'>
                                                <FontAwesomeIcon style={{ color: "#F94F5E" }} icon={faEye} onClick={() => props.viewKrReviewPopup(item, "Supervisor")} /> </span>
                                        </>
                                    }

                                </div>
                            </td>

                        </>
                    )
                } else {
                    return (
                        <td>
                            {
                                item.krSupervisorRecommendationRating.Type === "Add" ? <span className="ratingIconColor" ><b></b></span>
                                    :
                                    <>
                                        <span className='rating-okr-onhover-text rating-grid-text' style={{ cursor: 'auto' }}>{item.krSupervisorRecommendationRating.Rating}</span>
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <span style={{ color: "#B7B7BB" }}> | </span> &nbsp;&nbsp;&nbsp;&nbsp;
                                        <span style={{ color: item.krSupervisorRecommendationRating.IsPass === "Y" ? "#18BC00" : "#B7B7BB" }}>
                                            {item.krSelfRecommendationRating.IsPass}</span> &nbsp;&nbsp;&nbsp;&nbsp;<span style={{ color: "#B7B7BB" }}>  |  </span>
                                    </>
                            }
                        </td>
                    )
                }
            }
        } catch (error) {
        }
    }

    let addSelfInterimFeedbackIconSupThird = (
        props.ratingTabs === 'supervisor-rating' || props.ratingTabs === 'thirdparty-rating' ?
            '-'
            :
            <span title="Add self interim feedback" className="ratingIconColor">
                <FontAwesomeIcon className="rating-icon-color" icon={faPlus} />
            </span>
    )

    let addSelfInterimFeedbackIconSelfThird = (
        props.ratingTabs === 'self-rating' || props.ratingTabs === 'thirdparty-rating' ?
            '-'
            :
            <span title="Add supervisor interim feedback" className="ratingIconColor">
                <FontAwesomeIcon className="rating-icon-color" icon={faPlus} />
            </span>
    )

    let addSelfInterimFeedbackIconSelfSup = (
        props.ratingTabs === 'self-rating' || props.ratingTabs === 'supervisor-rating' ?
            '-'
            :
            <span title="Add third party interim feedback" className="ratingIconColor" >
                <FontAwesomeIcon className="rating-icon-color" icon={faPlus} />
            </span>
    )

    return (
        <div style={{ margin: 15 }}>
            {
                typeof ratingData !== 'undefined' &&
                Object.keys(ratingData).length > 0 &&
                <table className="table table-hover table-bordered" style={{ maxHeight: "200px" }}>
                    <thead>
                        <tr>
                            <th scope="col" colSpan={OKRAllocationStatus !== 'NA' ? 5 : 4}><div className=""></div></th>
                            <th className='kr-self-header' scope="col" colSpan="3">
                                <div className="okr-rating-objective-grid-kr-header-text">Interim Feedback <span style={{ cursor: 'pointer' }} className="okr-rating-objective-grid-kr-header-text rating-okr-onhover-text" onClick={() => props.openKrIntrimFeedbackPopup(ratingData.krData, ratingData.userObjectiveId, ratingData.objectiveName)} > ({`${ratingData.totalInterimFeedback}`}) </span></div>
                            </th>
                        </tr>
                        <tr>
                            <th className="kr-name-header" scope="col" colSpan=""><div>Leadership Principle</div></th>
                            <th className='kr-weightage-header' scope="col"><div>Description</div></th>
                            <th className='kr-weightage-header' scope="col" ><div>Weightage</div></th>
                            {
                                OKRAllocationStatus !== 'NA' &&
                                <th className='kr-self-rating-header' scope="col"><div>{ratingTabs === "self-rating" ? "Self Rating" : "Supervisor Rating"}</div></th>
                            }
                             <th className='kr-review-self-sup-sync-rating' scope="col"><div >History</div></th>


                            {/* <th className='kr-self-rating-header' scope="col"><div>{ratingTabs === "self-rating" ? "Self Rating" : "Supervisor Rating"}</div></th> */}
                            <th className='kr-self-header' scope="col"><div >Self</div></th>
                            <th className='kr-supervisor-header' scope="col" colSpan=""><div>Supervisor</div></th>
                            <th className='kr-third-party-header' scope="col" colSpan=""><div style={{ cursor: 'pointer' }} title='Third Party'>TP</div></th>
                        </tr>
                    </thead>
                    <tbody style={{ overflowY: 'auto', fontSize: 14 }}>
                        {
                            ratingData.krData?.map((item) => (
                                <tr key={item.ratingId}>
                                    <td className='kr-name rating-okr-onhover-text'><div title="View Leadership Principle description" className='rating-kr-name-limit'><span style={{ cursor: 'pointer' }} onClick={() => props.openLpRatingPopup(item, "sup")} >{item.krName}</span></div></td>
                                    <td className='kr-weightage'><div ><span title="View Leadership Principle descriptions" className='ratingIconColor' ><FontAwesomeIcon className='rating-icon-color' onClick={() => props.openLpRatingPopup(item, "sup")} icon={faEye} /></span></div></td>
                                    <td className='kr-weightage'><div><span style={{ cursor: 'pointer' }} onClick={() => props.openLpRatingPopup(item, "sup")}>{item.weightage}%</span></div></td>
                                    {OKRAllocationStatus !== 'NA' && renderSelfSup(item)}
                                    <td className='kr-self-rating'>
                                    <div title="View past rating" className="" >
                                        {
                                            item.pastRating === 'Y' &&
                                            <div className="" onClick={() => props.openPastRatingPopup(item, 'Final')}>
                                                {
                                                    <span className="ratingIconColor">
                                                        <FontAwesomeIcon className="rating-icon-color" icon={faEye} />
                                                    </span>
                                                }
                                            </div>
                                        }
                                        {
                                            item.pastRating === 'N' && <span>-</span>
                                        }
                                    </div>
                                </td>
                                    <td className='kr-self' width='7%'>
                                        <div className='rating-okr-onhover-text' >
                                            {
                                                item.interimSelf === 0 && item.interimDraftSelf === 0 ?
                                                    <span onClick={() => {
                                                        props.ratingTabs === 'self-rating' ?
                                                            props.openAddEditIntrimFeedbackPopup(item)
                                                            :
                                                            getNotify(WARNING, youCannotAddThirdPartyInterimHereIn)
                                                    }}>
                                                        {addSelfInterimFeedbackIconSupThird}
                                                    </span>
                                                    :
                                                    <>
                                                        {
                                                            item.interimSelf !== 0 &&
                                                            <span className="rating-grid-text rating-okr-onhover-text"
                                                                title="View self interim feedback"
                                                                onClick={() => props.openViewIntrimFeedbackPopup(item, ratingData.userObjectiveId, 'self', false)}
                                                            >
                                                                {item.interimSelf}
                                                            </span>
                                                        }
                                                        {item.interimSelf !== 0 && item.interimDraftSelf !== 0 && props.ratingTabs === 'self-rating' &&
                                                            <span className='rating-okr-onhover-text'> | </span>
                                                        }
                                                        {
                                                            item.interimDraftSelf !== 0 &&
                                                            <span className='rating-grid-text-draft rating-okr-onhover-text'
                                                                onClick={() => props.openViewIntrimFeedbackPopup(item, ratingData.userObjectiveId, 'self', true)}
                                                            >
                                                                {props.ratingTabs === 'self-rating' && item.interimDraftSelf}
                                                            </span>
                                                        }
                                                    </>
                                            }
                                        </div>

                                    </td>
                                    <td className='kr-supervisor' width='7%'>
                                        <div className='rating-okr-onhover-text' >
                                            {
                                                item.interimSupervisor === 0 && item.interimDraftSupervisor === 0 ?
                                                    <span
                                                        onClick={() => {
                                                            props.ratingTabs === 'supervisor-rating' ?
                                                                props.openAddEditIntrimFeedbackPopup(item)
                                                                :
                                                                getNotify(WARNING, youCannotAddThirdPartyInterimHereIn)
                                                        }}>
                                                        {addSelfInterimFeedbackIconSelfThird}

                                                    </span>
                                                    :
                                                    <>
                                                        {
                                                            item.interimSupervisor !== 0 &&
                                                            <span title="View supervisor interim feedback" className='rating-grid-text rating-okr-onhover-text' onClick={() => props.openViewIntrimFeedbackPopup(item, ratingData.userObjectiveId, 'sup', false)}>
                                                                {item.interimSupervisor}
                                                            </span>
                                                        }
                                                        {item.interimSupervisor !== 0 && item.interimDraftSupervisor !== 0 && props.ratingTabs === 'supervisor-rating' &&
                                                            <span className='rating-okr-onhover-text'> | </span>
                                                        }
                                                        {
                                                            item.interimDraftSupervisor !== 0 &&
                                                            <span className='rating-grid-text-draft rating-okr-onhover-text' onClick={() => props.openViewIntrimFeedbackPopup(item, ratingData.userObjectiveId, 'sup', true)}>
                                                                {props.ratingTabs === 'supervisor-rating' && item.interimDraftSupervisor}
                                                            </span>
                                                        }
                                                    </>
                                            }
                                        </div>
                                    </td>
                                    <td className='kr-third-party' width='7%'>
                                        <div className='rating-okr-onhover-text'>
                                            {

                                                item.interimThirdParty === 0 && item.interimDraftThirdParty === 0 ?
                                                    <span
                                                        onClick={() => {
                                                            props.ratingTabs === 'thirdparty-rating' ?
                                                                props.openKrIntrimFeedbackPopup(item, ratingData.userObjectiveId)
                                                                :
                                                                getNotify(WARNING, youCannotAddThirdPartyInterimHereIn)
                                                        }}>
                                                        {addSelfInterimFeedbackIconSelfSup}
                                                    </span>
                                                    :
                                                    <>
                                                        {
                                                            item.interimThirdParty !== 0 &&
                                                            <span title="View third party interim feedback" className='rating-grid-text' onClick={() => props.openViewIntrimFeedbackPopup(item, ratingData.userObjectiveId, 'tp', false)}>
                                                                {item.interimThirdParty}
                                                            </span>
                                                        }
                                                        {item.interimThirdParty !== 0 && item.interimDraftThirdParty !== 0 && props.ratingTabs === 'thirdparty-rating' &&
                                                            <span className='rating-okr-onhover-text'> | </span>
                                                        }
                                                        {
                                                            item.interimDraftThirdParty !== 0 && props.ratingTabs === 'thirdparty-rating' &&
                                                            <span className='rating-grid-text' onClick={() => props.openViewIntrimFeedbackPopup(item, ratingData.userObjectiveId, 'tp', true)}>
                                                                {item.interimDraftThirdParty}
                                                            </span>
                                                        }
                                                    </>
                                            }
                                        </div>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            }
        </div>
    )
}
export default RatingKR

