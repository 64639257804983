import React, { useEffect, useState } from 'react'
import { FormValidator } from "@syncfusion/ej2-inputs";
import { TextBoxComponent, } from "@syncfusion/ej2-react-inputs";
import { EnterSubLayerName } from "../../../../assets/constants/constants";

export default function SubLayerMasterPopup(props) {
  const [subLayerMasterData, setsubLayerMasterData] = useState({
    subLayerName: "",
    layerId: "",
    subLayerId: 0,

  });

  useEffect(() => {
    let data
    if (props.type === 'Add') {
      data = {
        subLayerName: "",
        subLayerId: 0
      }
      setsubLayerMasterData(data);
    }
    if (props.type === 'Edit') {
      const { subLayerName, Id } = props.subLayerMasterAreaData;
      data = {
        subLayerName: subLayerName,
        subLayerId: Id
      }
      setsubLayerMasterData(data);
    }
    // eslint-disable-next-line
  }, [])

  const subLayerMasterFunction = (event) => {
    let options = {}
    subLayerMasterData.subLayerName = event.value;
    setsubLayerMasterData(subLayerMasterData);

    options = {
      rules: {
        subLayerNameErr: {
          required: [true, EnterSubLayerName],
        }
      },
    };
    setTimeout(() => {
      const addFormObject = new FormValidator("#subLayerMasterForm", options);
      addFormObject.validate();
    }, 100);
  }
  props.parentClassFunction(subLayerMasterData, "subLayerMasterData");
  return (
    <div>
      <form id="subLayerMasterForm">
        <div className="form-row">
          <div className="col-md-9">
            <TextBoxComponent
              id="subLayerName"
              placeholder="Sub Layer*"
              floatLabelType="Auto"
              value={subLayerMasterData.subLayerName}
              data-msg-containerid="subLayerNameErr"
              name="subLayerNameErr"
              cssClass="e-outline"
              width="500px"
              onChange={(e) => subLayerMasterFunction(e)}
              htmlAttributes={{ maxlength: 100 }}
            />
            <div
              id="subLayerNameErr"
              className="e-input-group e-float-icon-left error-div"
            />
          </div>
        </div>

      </form>
    </div>
  );
}