
import React, { Component } from "react";
import { connect } from "react-redux";
import { Button } from "@material-ui/core";
import {
  AXIS,
  PERFORMANCE,
  POTENTIAL,
  AXIS_CUTOFF,
  NINE_BLOCKER,
  FREQUENCY,
  POTENTIAL_CALCULATION,
  SUB_POTENTIAL_CALCULATION,
  RATING_MASTER,
  RATING_SYSTEM, TEAM_MEMBER
} from "../../../redux/constant/meetingcategoryconstant";
import { saveAxisMaster } from "../../../redux/actions/okr/okractions";
import PotentialCalculation from "../../../components/okradmincomponents/potentialcalculationmaster";
import "../../layout/lna/lnaReport.css";
import AxisMasterSidebar from "../../../components/okradmincomponents/axismastersidebar";
import PerformanceMaster from "../../../components/okradmincomponents/performancemaster";
import NineBlockMaster from "../../../components/okradmincomponents/nineblockmaster";
import FrequencyMaster from "../../../components/okradmincomponents/frequencymaster";
import SubPotentialCalculationMaster from "../../../components/okradmincomponents/subpotentialcalculationmaster";
import AxisCutOffMaster from "../../../components/okradmincomponents/axiscutoffmaster";
import RatingSystemMaster from "../../../components/okradmincomponents/ratingsystem";
import RatingMaster from "../../../components/okradmincomponents/ratingmaster";
import TeamMemberMatrix from "../../../components/okradmincomponents/teammembermatrix";


class ClusterSetup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      savedata: "",
      height:
        (window.innerHeight ||
          document.documentElement.clientHeight ||
          document.body.clientHeight) - 274,
      contentHeight: null,
      teamMatrixContentHeight: null,
    };
    this.clickChild = null;
  }

  componentDidMount() {
    const isSixteenHundred = window.innerHeight < 782;
    const isOneTwentyFiveScale = window.innerHeight < 747;
    const isOneTwentyFiveInSixteenEighty = window.innerHeight < 723;
    const isThirteenHundred = window.innerHeight < 650;
    const isOneTwentyFiveInSixteenHundred = window.innerHeight < 603;
    const isOneTwentyFiveInThirteenHundred = window.innerHeight < 497;
    
    const isOneTwentyFiveScaleBookmarkOn = window.innerHeight < 715;

    let minusHeigh = this.props.masterId === 25 ? 130
      : isOneTwentyFiveInThirteenHundred ? 190
      : isOneTwentyFiveInSixteenHundred ? 190
      : isThirteenHundred ? 192
      : isOneTwentyFiveScaleBookmarkOn ? 190
      : isOneTwentyFiveInSixteenEighty ? 192
      : isOneTwentyFiveScale ? 192
      : isSixteenHundred ? 193
      : 193.1
    this.setState({
      height: (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight) - minusHeigh,
      contentHeight: isOneTwentyFiveScale ? (window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight) - 315 : (window.innerHeight ||
          document.documentElement.clientHeight ||
          document.body.clientHeight) - 320,
      teamMatrixContentHeight: isOneTwentyFiveScale ? (window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight) - 200 : (window.innerHeight ||
          document.documentElement.clientHeight ||
          document.body.clientHeight) - 202,
    });
  }
  popUpHandler = () => {
    const {
      categoryName,
      changePopUpHandler,
      masterId,
      history,
      openPopup,
      masterItemId,
      // editMasterId,
      // axisMasterState,
      // handleStateChange,
      teamMemberMatrix,
      teammemberData
    } = this.props;
    let activeMasterId = (masterId == 0 ? categoryName : masterId).toString();
    switch (activeMasterId) {
      case AXIS:
        return (
          <AxisMasterSidebar
            categoryName={activeMasterId}
            history={history}
            changePopUpHandler={changePopUpHandler}
            setSubmitClick={click => this.clickChild = click}
            openPopup={openPopup}
            masterItemId={masterItemId}
            masterId={masterId}
          />
        );
      case PERFORMANCE:
      case POTENTIAL:
        return (
          <PerformanceMaster
            categoryName={activeMasterId}
            history={history}
            changePopUpHandler={changePopUpHandler}
            setSubmitClick={click => this.clickChild = click}
            openPopup={openPopup}
            masterItemId={masterItemId}
            masterId={masterId}
          />
        );
      case AXIS_CUTOFF:
        return (
          <AxisCutOffMaster
            categoryName={activeMasterId}
            history={history}
            changePopUpHandler={changePopUpHandler}
            setSubmitClick={click => { this.clickChild = click }}
            openPopup={openPopup}
            masterItemId={masterItemId}
            masterId={masterId}
          />
        );
      case NINE_BLOCKER:
        return (
          <NineBlockMaster
            categoryName={activeMasterId}
            history={history}
            changePopUpHandler={changePopUpHandler}
            setSubmitClick={click => this.clickChild = click}
            openPopup={openPopup}
            masterItemId={masterItemId}
            masterId={masterId}
          />
        );
      case FREQUENCY:
        return (
          <FrequencyMaster
            categoryName={activeMasterId}
            history={history}
            changePopUpHandler={changePopUpHandler}
            setSubmitClick={click => this.clickChild = click}
            openPopup={openPopup}
            masterItemId={masterItemId}
            masterId={masterId}
          />
        );

      case POTENTIAL_CALCULATION:
        return (
          <PotentialCalculation
            categoryName={activeMasterId}
            history={history}
            changePopUpHandler={changePopUpHandler}
            setSubmitClick={click => this.clickChild = click}
            openPopup={openPopup}
            masterId={masterId}
            masterItemId={masterItemId}
          />
        );
      case SUB_POTENTIAL_CALCULATION:
        return (
          <SubPotentialCalculationMaster
            categoryName={activeMasterId}
            history={history}
            changePopUpHandler={changePopUpHandler}
            setSubmitClick={click => this.clickChild = click}
            openPopup={openPopup}
            masterId={masterId}
            masterItemId={masterItemId}
          />
        );
      case RATING_SYSTEM:
        return (
          <RatingSystemMaster
            categoryName={activeMasterId}
            history={history}
            changePopUpHandler={changePopUpHandler}
            setSubmitClick={click => { this.clickChild = click }}
            openPopup={openPopup}
            masterId={masterId}
            masterItemId={masterItemId}
          />
        );
      case RATING_MASTER:
        return (
          <RatingMaster
            categoryName={activeMasterId}
            history={history}
            changePopUpHandler={changePopUpHandler}
            setSubmitClick={click => this.clickChild = click}
            openPopup={openPopup}
            masterId={masterId}
            masterItemId={masterItemId}
          />
        );
      case TEAM_MEMBER:
        return (
          <TeamMemberMatrix
            categoryName={activeMasterId}
            history={history}
            changePopUpHandler={changePopUpHandler}
            setSubmitClick={click => this.clickChild = click}
            openPopup={openPopup}
            masterId={masterId}
            masterItemId={masterItemId}
            teamMemberMatrix={teamMemberMatrix}
            teammemberData={teammemberData}
          />
        );

      default:
        //NOSONAR
    }
  };

  formNameHandler = () => {
    const { categoryName, masterId } = this.props;
    let activeMasterId = (masterId == 0 ? categoryName : masterId).toString();

    switch (activeMasterId) {
      case AXIS:
        document.title = "Axis Master";
        return "AxisMaster";
      case PERFORMANCE:
        document.title = "Performance Master";
        return "PerformanceMaster";
      case POTENTIAL:
        document.title = "Potential Master";
        return "PotentialMaster";
      case AXIS_CUTOFF:
        document.title = "Axis Cut-Off Master";
        return "AxisCutOffMaster";
      case NINE_BLOCKER:
        document.title = "9-Blocker Master";
        return "NineBlockerMaster";
      case FREQUENCY:
        document.title = "Frequency / Period Master";
        return "FrequencyMaster";
      case POTENTIAL_CALCULATION:
        document.title = "Potential Calculation Master";
        return "PotentialCalculation";
      case SUB_POTENTIAL_CALCULATION:
        document.title = "Sub Potential Calculation Master";
        return "SubPotentialCalculation";
      case RATING_SYSTEM:
        document.title = "Rating Scale Master";
        return "ratingSystem";
      case RATING_MASTER:
        document.title = "Rating Master";
        return "ratingMaster";
      default:
        document.title = "Attendance";
        return "AttendanceCluster";
    }
  };

  handleCancel = () => {
    this.props.changePopUpHandler("close");
  }
  render() {
    const { height, contentHeight, teamMatrixContentHeight } = this.state;

    return (
      <>
        <div
          id={`${this.formNameHandler()}Form`}
          className={this.props.masterId === 25 ? "member-matrix-flex" : "other-matrix-flex"}
          style={{
            position: "absolute",
            right: 16,
            border: "1px solid #f2f2f2",
            boxShadow: "0px 0px 6px #00000029",
            top: "7.1rem",
            width: "25rem",
            height: height,
            opacity: 1,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            backgroundColor: 'white',
            zIndex: '999'
          }}
        >
          <div>
            {this.props?.masterId !== 25 ?
              <div >
                <div
                  style={{
                    backgroundColor: "#ffffff",
                    height: "3.8rem",
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: "1rem",
                    fontWeight: 600,
                    fontStyle: "Circular Std Book",
                    fontSize: "1rem",
                    fontFamily: "Arial",
                    borderBottom: "2px solid #DBDBDD",
                    marginBottom: "0px",
                  }}
                >
                  {`${this.props.openPopup === 'add' ? "Add" : "Edit"} ` + `${document.title}`}
                </div>
              </div> : ""
            }


            <div
              className={this.props.masterId === 25 ? "team-modal-scroll" : "other-modal-scroll"}
              style={{height: this.props.masterId === 25 ? teamMatrixContentHeight : contentHeight}}
            >
              <div id={`${this.formNameHandler()}`}>{this.popUpHandler()}</div>
            </div>
          </div>
          <div
            className="col-sm-12 col-md-12 col-lg-12 pb-md pt-md"
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
              borderTop: "1px solid #DBDBDD",
              backgroundColor: "#FAFAFA",
            }}
          >
            <div>
              <Button
                style={{ width: "90px" }}
                className={this.props.masterId === 25 ? "remove-button-shadow okr-admin-popup-cancel-btn matrix-cancel" : "remove-button-shadow okr-admin-popup-cancel-btn"}
                variant="contained"
                onClick={this.handleCancel}
              >
                Cancel
              </Button>
              <Button
                id="okrTourGuideBtnStart"
                style={{ width: "80px" }}
                className={this.props.masterId === 25 ? "remove-button-shadow atc-save-button matrix-save" : "remove-button-shadow fgfgfg atc-save-button"}
                variant="contained"
                onClick={() => this.clickChild()}
              >

                Save
              </Button>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = ({ auth, meetingcategory, meetingmaster }) => {
  const { authUser } = auth;
  const { categoryName } = meetingcategory;
  const { financialYearData } = meetingmaster;
  return {
    categoryName,
    financialYearData,
    authUser
  };
};
export default connect(mapStateToProps, { saveAxisMaster })(ClusterSetup);
