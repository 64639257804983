// Author : Suresh R. Sargam
// Date : 11-Dec-2019
// Description: Detailed Content for Meeting Details Pop

import React from "react";
import "./meetingdetailspopup.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFile } from "@fortawesome/free-solid-svg-icons";
import { Button, Dialog, Paper } from "@material-ui/core";
import Draggable from "react-draggable";
import Slide from "@material-ui/core/Slide";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import "../../assets/style/index.css";
import DateFnsUtils from "@date-io/date-fns";
import { getDisplayDateOnly, getNotify, getDateFormat } from '../../app/other/commonfunction'
import { MuiPickersUtilsProvider, DateTimePicker } from "@material-ui/pickers";
import PropTypes from "prop-types";
import { library } from "@fortawesome/fontawesome-svg-core";
import { ReactSVG } from "react-svg";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import {
  faCalendar,
  faMapMarker,
  faAddressCard,
  faGlobeAmericas,
  faStar,
  faUserCrown,
  faUserShield,
  faTimes,
  faCheck,
  faClock,
  faUserEdit,
  faDesktop,
  faPhone,
  faVideo,
  faUsers,
  faUserClock,
  faSearchPlus,
  faUser,
  faClipboardCheck,
  faDownload,
  faCopy,
  faSquareList
} from "@fortawesome/pro-light-svg-icons";
import {
  WARNING, Holidays,
  timeShouldBeGreaterThanCurrentTime,
  pleaseEnterTemplateName, meetingAcceptanceMsg, acceptanceSpecificMeetingOrSeries, areYouSureAcceptMeetingRequest, areYouSureNotAttendThisMeeting, declineSpecificMeetingOrSeries,
  meetingDeclineMsg, areYouSureDeclineMeetingRequest, meetingDidNotAttendMsg,
} from "../../assets/constants/constants";
import {
  DialogTitle,
  DialogContent,
  DialogActions,
} from "../customdialog/customdialog";
import ErrorBoundary from "../error/errorboundary";
import { OTHERS_CHECK_AVAILABILITY, OTHERS_CALENDAR } from "../../redux/constant/meetingcategoryconstant";
import { replace } from "connected-react-router";
import ConfirmationPopup from "../../utilities/confirmationpopup";

library.add(
  faCalendar,
  faMapMarker,
  faAddressCard,
  faGlobeAmericas,
  faStar,
  faUserCrown,
  faUserShield,
  faTimes,
  faCheck,
  faClock,
  faUserEdit,
  faDesktop,
  faPhone,
  faVideo,
  faUsers,
  faUserClock,
  faSearchPlus,
  faUser,
  faClipboardCheck,
  faDownload
);
const Transition = React.forwardRef(function Transition(props, ref) {
  // eslint-disable-next-line
  return <Slide direction="up" ref={ref} {...props} />;
});

const reqSvgs = require.context("../../assets/svg", true, /\.svg$/);
const svgs = reqSvgs.keys().map((path) => ({ path, file: reqSvgs(path) }));

function PaperComponent(props) {
  return (
    <Draggable cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper
        // eslint-disable-next-line
        {...props}
      />
    </Draggable>
  );
}

class DetailsContent extends React.Component {
  constructor(props) {
    super(props);
    this.getIcon = this.getIcon.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleMeetingStartTimeChange = this.handleMeetingStartTimeChange.bind(
      this
    );
    this.handleStartTimeClose = this.handleStartTimeClose.bind(this);
    this.state = {
      acceptanceStatus: "",
      open: false,
      title: "Meeting Action",
      message: "",
      button1Caption: "",
      button2Caption: "",
      starttime: new Date(),
      starttimeopen: false,
      meetingTemplateOpen: false,
      templateName: '',
      copied: false,
      displayStyle:'inline'
    };
  }

  closeQuickInfo = () => {
    this?.props?.scheduleObj?.closeQuickInfoPopup();
  }

  componentDidMount(){
    this.hideActionButtonsForCertainMeetingType()
  }
  // eslint-disable-next-line
  UNSAFE_componentWillMount() {
    if (this.tooltipObj != null) {
      this.tooltipObj.close();
    }
  }

  hideActionButtonsForCertainMeetingType=()=>{
    if(Holidays.includes(this.props.data.MeetingTypeId)){
      this.setState({displayStyle:'none'})
    }else{
      this.setState({displayStyle:'inline'})
    }
  }

  handleConfirmYes = () => {
    const { acceptanceStatus } = this.state;
    const { authUser, data } = this.props;
    const { MeetingMode, MeetingId } = data;
    switch (acceptanceStatus) {
      case "Accept":
        // eslint-disable-next-line
        this.props.meetingAcceptance({
          meetingmode: MeetingMode,
          meetingId: MeetingId,
          status: 1,
          volume: "single",
          user_id: authUser,
        });
        break;
      case "Decline":
      case "Did Not Attend":
        // eslint-disable-next-line
        this.props.meetingAcceptance({
          meetingmode: MeetingMode,
          meetingId: MeetingId,
          status: 2,
          volume: "single",
          user_id: authUser,
        });
        break;
      case "Suggest":
        break;
      default:
    }
    // eslint-disable-next-line
    this.props.getMeetingDetails({ meetingPopOpen: false });
    this.setState({ open: false });
  };

  handleConfirmNo = () => {
    const { acceptanceStatus } = this.state;
    const { authUser, data } = this.props;
    const { MeetingMode, MeetingId, SeriesId } = data;
    if (SeriesId > 0 && acceptanceStatus !== "Did Not Attend") {
      switch (acceptanceStatus) {
        case "Accept":
          // eslint-disable-next-line
          this.props.meetingAcceptance({
            meetingmode: MeetingMode,
            meetingId: MeetingId,
            status: 1,
            volume: "series",
            user_id: authUser,
          });
          break;
        case "Decline":
        case "Did Not Attend":
          // eslint-disable-next-line
          this.props.meetingAcceptance({
            meetingmode: MeetingMode,
            meetingId: MeetingId,
            status: 2,
            volume: "series",
            user_id: authUser,
          });
          break;
        case "Suggest":
          break;
        default:
      }
    }
    // eslint-disable-next-line
    this.props.getMeetingDetails({ meetingPopOpen: false });
    this.setState({ open: false });
  };

  handleConfirmClose = () => {
    this.setState({ open: false });
  };

  handleMeetingStartTimeChange = (startTime) => {
    const { meetingdate, authUser, data } = this.props;
    const { MeetingMode, MeetingId } = data;

    const start = meetingdate.indexOf("(");
    let meetingdateTime = meetingdate.substring(start + 1, 23);
    if (meetingdateTime.length < 5) {
      meetingdateTime = meetingdate.substring(start + 1, 24);
    }
    const newSuggestTime = startTime.toString().substring(16, 21);
    let meetingdatecomp = meetingdate.substring(5, 17);
    const newSuggestdatecomp = startTime.toString().substring(3, 15);
    let meetingdatecomp1 = meetingdatecomp.replace(",", "");
    let newFinaldate = "";

    if (meetingdatecomp1.trim() === newSuggestdatecomp.trim()) {
      newFinaldate = meetingdatecomp1;
    } else {
      meetingdatecomp = meetingdate.substring(4, 16);
      meetingdatecomp1 = meetingdatecomp.replace(",", "");
      const position = 5;
      newFinaldate = [
        meetingdatecomp1.slice(0, position),
        "0",
        meetingdatecomp1.slice(position),
      ].join("");
    }
    const comptime = meetingdateTime.localeCompare(newSuggestTime);
    const compdate = newFinaldate
      .trim()
      .localeCompare(newSuggestdatecomp.trim());

    if (
      (comptime !== 0 && comptime !== "") ||
      (compdate !== 0)
    )/**NOSONAR */ {
      this.setState({ starttime: startTime, starttimeopen: false });
      // eslint-disable-next-line
      this.props.meetingAcceptance({
        meetingmode: MeetingMode,
        meetingId: MeetingId,
        status: 3,
        starttime: getDateFormat(new Date(startTime), new Date(startTime)),
        user_id: authUser,
        volume: "single",
      });
    } else {
      getNotify(WARNING, timeShouldBeGreaterThanCurrentTime);
    }
  };

  handleStartTimeClose = () => {
    this.setState({ starttimeopen: false });
  };

  getIcon = (icon, MeetingMedia) => {
    let IconContent;
    switch (icon) {
      case "1":
        IconContent = (
          <FontAwesomeIcon
            id="pointer"
            icon={faDesktop}
            title={MeetingMedia}
            style={{ marginLeft: "-1px", cursor: "pointer" }}
          />
        );
        break;
      case "2":
        IconContent = (
          <FontAwesomeIcon
            id="pointer"
            icon={faAddressCard}
            title={MeetingMedia}
            style={{ cursor: "pointer" }}
          />
        );
        break;
      case "3":
        IconContent = (
          <FontAwesomeIcon
            id="pointer"
            icon={faUsers}
            title={MeetingMedia}
            style={{ cursor: "pointer" }}
          />
        );
        break;
      case "4":
        IconContent = (
          <FontAwesomeIcon
            id="pointer"
            icon={faPhone}
            title={MeetingMedia}
            style={{ cursor: "pointer" }}
          />
        );
        break;
      case "":
        IconContent = (
          <FontAwesomeIcon
            id="pointer"
            icon={faUserEdit}
            title="Self"
            style={{ cursor: "pointer" }}
          />
        );
        break;
      default:
        break;
    }
    return IconContent;
  };

  getMediaIcon = (icon) => {
    let IconContent;

    switch (icon) {
      case "1":
        IconContent = (
          <FontAwesomeIcon
            id="pointer"
            icon={faGlobeAmericas}
            title="Webex"
            style={{ cursor: "pointer", fontSize: 13 }}
          />
        );
        break;
      case "2":
        IconContent = (
          <FontAwesomeIcon
            id="pointer"
            icon={faPhone}
            title="Skype"
            style={{ cursor: "pointer" }}
          />
        );
        break;
      case "3":
        IconContent = (
          <FontAwesomeIcon
            id="pointer"
            icon={faVideo}
            title="Zoom"
            style={{ cursor: "pointer" }}
          />
        );
        break;
      case "4":
        IconContent = (
          <FontAwesomeIcon
            id="pointer"
            icon={faUsers}
            title="Teams"
            style={{ cursor: "pointer" }}
          />
        );
        break;
      default:
        IconContent = (
          <FontAwesomeIcon
            id="pointer"
            icon={faPhone}
            title="Meeting Link"
            style={{ cursor: "pointer" }}
          />
        );
        break;
    }
    return IconContent;
  };

  getMediaName = (Name) => {
    let IconContent;

    switch (Name) {
      case "1":
        IconContent = "Webex";
        break;
      case "2":
        IconContent = "Skype";
        break;
      case "3":
        IconContent = "Zoom";
        break;
      case "4":
        IconContent = "Teams";
        break;
      default:
        break;
    }
    return IconContent;
  };

  saveastemplate = () => {
    this.setState({ meetingTemplateOpen: true, templateName: '' });
  };

  handleClose = () => {
    this.setState({ meetingTemplateOpen: false, templateName: '' });
  };

  handleSubmit = () => {
    const { authUser, data } = this.props;
    const { MeetingMode, MeetingId } = data;

    const templateName = this.state.templateName;
    if (templateName.trim() === "") {
      document.getElementById(
        "templateError"
      ).innerHTML = pleaseEnterTemplateName;
    } else {
      // eslint-disable-next-line
      this.props.saveMeetingTemplate({
        templateName,
        meetingmode: MeetingMode,
        meetingId: MeetingId,
        user_id: authUser,
      });

      setTimeout(() => {
        this.props.getMeetingTemplateData();
        this.setState({ meetingTemplateOpen: false });
      }, 1000);
    }
  };

  getAttendeeTitle = (icon) => {
    let attendeeTitle;
    switch (icon) {
      case "faUserImp":
        attendeeTitle = "Important Attendee";
        break;
      case "faUser":
        attendeeTitle = "Attendee";
        break;
      case "faUserClock":
        attendeeTitle = "Partial Attendee";
        break;
      case "faUserShield":
        attendeeTitle = "Optional Attendee";
        break;
      case "faStar":
        attendeeTitle = "Chair Person";
        break;
      case "faTimes":
        attendeeTitle = "Unable to attend";
        break;
      default:
        break;
    }
    return attendeeTitle;
  };

  getAttendeeIcon = (icon) => {
    let IconContent;
    const iconColor = "mr-sm ";
    switch (icon) {
      case "faUserImp":
        IconContent = (
          <FontAwesomeIcon
            title="Important Attendee"
            icon={faUserCrown}
            style={{ width: "7%", cursor: "pointer" }}
            className={iconColor}
          />
        );
        break;
      case "faUserShield":
        IconContent = (
          <FontAwesomeIcon
            title="Optional Attendee"
            icon={faUserShield}
            style={{ width: "7%", cursor: "pointer" }}
            className={iconColor}
          />
        );
        break;
      case "faStar":
        IconContent = (
          <FontAwesomeIcon
            icon={faStar}
            title="Chair Person"
            style={{ width: "7%", cursor: "pointer" }}
            className={iconColor}
          />
        );
        break;
      case "faUserClock":
        IconContent = (
          <FontAwesomeIcon
            icon={faUserClock}
            title="Partial Attendee"
            style={{ width: "7%", cursor: "pointer" }}
            className={iconColor}
          />
        );
        break;
      case "faTimes":
        IconContent = (
          <FontAwesomeIcon
            icon={faTimes}
            title="Unable to attend"
            style={{ width: "7%", cursor: "pointer" }}
            className={iconColor}
          />
        );
        break;
      default:
        IconContent = (
          <FontAwesomeIcon
            style={{ width: "7%" }}
            title="Attendee"
            className="mr-sm"
            icon={faUser}
          />
        );
        break;
    }
    return IconContent;
  };

  handleInputChange = (pType) => { // NOSONAR
    try {
      switch (pType) {
        case "Accept":
          this.setState({
            message:
              // eslint-disable-next-line
              this.props.data.SeriesId > 0
                ? acceptanceSpecificMeetingOrSeries.replace('?', ` from ${getDisplayDateOnly(this.props.data.StartTime)} onwards?`)
                : areYouSureAcceptMeetingRequest,
            title: meetingAcceptanceMsg,
            button1Caption:
              // eslint-disable-next-line
              this.props.data.SeriesId > 0 ? "Accept Meeting" : "Yes",
            // eslint-disable-next-line
            button2Caption: this.props.data.SeriesId > 0 ? "Accept Series" : "No",
          });
          // this.setState({ message: acceptanceSpecificMeetingOrSeries, title: 'Meeting Acceptance', button1Caption:'Accept Meeting', button2Caption:'Accept Series' })
          this.setState({ acceptanceStatus: pType, open: true });
          break;
        case "Decline":
        case "Did Not Attend":
          // eslint-disable-next-line
          if (this.props.data.AttendeeResponse === "ActualRes") {
            this.setState({
              message: areYouSureNotAttendThisMeeting,
              title: pType === "Did Not Attend" ? meetingDidNotAttendMsg : meetingDeclineMsg,
              button1Caption: "Yes",
              button2Caption: "No",
            });
          } else {
            this.setState({
              message:
                // eslint-disable-next-line
                this.props.data.SeriesId > 0
                  ? (pType === "Did Not Attend" ? areYouSureNotAttendThisMeeting :declineSpecificMeetingOrSeries.replace('?', ` from ${getDisplayDateOnly(this.props.data.StartTime)} onwards?`)) // NOSONAR
                  : (pType === "Did Not Attend" ? areYouSureNotAttendThisMeeting : areYouSureDeclineMeetingRequest), // NOSONAR

              title: pType === "Did Not Attend" ? meetingDidNotAttendMsg : meetingDeclineMsg,
              button1Caption:
                // eslint-disable-next-line
                this.props.data.SeriesId > 0 && pType === "Decline" ? "Decline Meeting" : "Yes",
              button2Caption:
                // eslint-disable-next-line
                this.props.data.SeriesId > 0 && pType === "Decline" ? "Decline Series" : "No",
            });
          }
          this.setState({ acceptanceStatus: pType, open: true });
          break;
        case "Suggest":
          setTimeout(() => {
            this.setState({ acceptanceStatus: pType, starttimeopen: true });
            const ele = document.getElementsByClassName(
              "MuiPickersModal-dialog"
            )[0];
            ele.classList.add("MuiPickersModal-dialog-custom");
          }, 1000);
          break;
        default:
      }
    } catch (error) {
    }
  };

  render() { // NOSONAR
    const {displayStyle} = this.state
    const {
      Countries,
      Attendee,
      AttendeeResponse,
      MeetingMode,
      StartTime,
      EditDeleteAccess,
      MeetingMediaIcon,
      MeetingMedia,
      MeetingRooms,
      MeetingType,
      WebLink,
      WebLinkType,
      Agenda,
      ActionItems,
      // agendaDocument,
      ParticipateCode,
      ContactIds,
      MeetingSetter,
      BDContacts,
      SaveTemplate,
      meetingCreationDate,
      meetingModificationDate
      // eslint-disable-next-line
    } = this.props.data;
    const duration = {
      enteringScreen: 500,
      leavingScreen: 500,
    };
    const cCountries = [];
    // eslint-disable-next-line
    Countries.toString()
      .split("$")
      .forEach((item) => {
        if (item !== "") {
          const name = item.split("^")[0];
          const number = item.split("^")[1];
          const svg = item.split("^")[2];

          cCountries.push({ name, number, svg });
        }
      });
    const mArrAttendee = [];

/**
* Author :- Somesh Dwivedi | 01-12-2023
* Description:-Function to get the color of status on the basis of attendee response
*/
    const getAttendeeStatusColor = (attendeeResponse) => {
      let statusColor = '';
      if (attendeeResponse === 'Leave') {
        statusColor = '#92CDDC'
      }
      else if (attendeeResponse === 'Decline') {
        statusColor = '#A52A2A'
      }
      else {
        statusColor = 'inherit'
      }
      return statusColor
    }
    // eslint-disable-next-line
    Attendee.toString()
      .split(",")
      .forEach((item) => {
        //Taking out attendee status response from string
        const attendeeResponse = item.split("$")[2];
        const statusColor = getAttendeeStatusColor(attendeeResponse); //Getting status color for attendee response

        mArrAttendee.push({
          Attendee: item.split("$")[0],
          Icon: item.split("$")[1],
          Status: statusColor,
          Ord: item.split("$")[3],
        });
      });

    mArrAttendee.sort((a, b) => Number(a.Ord) - Number(b.Ord));

    let acceptVis = false;
    let declineVis = false;
    let suggestVis = false;
    // eslint-disable-next-line
    if (AttendeeResponse !== "No") {
      if (
        // eslint-disable-next-line
        MeetingMode === "P" &&
        // eslint-disable-next-line
        new Date(StartTime).getTime() > new Date().getTime()
      ) {
        if (
          // eslint-disable-next-line
          AttendeeResponse === "3" ||
          // eslint-disable-next-line
          AttendeeResponse === "Def"
        ) {
          acceptVis = true;
          declineVis = true;
          suggestVis = true;
        } else {
          if (
            // eslint-disable-next-line
            AttendeeResponse === "2" ||
            // eslint-disable-next-line
            AttendeeResponse === ""
          ) {
            acceptVis = true;
          }

          if (
            // eslint-disable-next-line
            AttendeeResponse === "1" ||
            // eslint-disable-next-line
            AttendeeResponse === ""
          ) {
            declineVis = true;
            suggestVis = true;
          }
          // eslint-disable-next-line
          if (AttendeeResponse === "") {
            suggestVis = true;
          }
          if (MeetingMode === "A" && (EditDeleteAccess === "N" || EditDeleteAccess === "Y" || EditDeleteAccess === "D")) {
            declineVis = true;
          }
        } // eslint-disable-next-line
      } else if (EditDeleteAccess === "D") {
        if (MeetingMode === "A" && (EditDeleteAccess === "N" || EditDeleteAccess === "Y" || EditDeleteAccess === "D")) {
          declineVis = true;
        }
        if (MeetingMode === "P" && (EditDeleteAccess === "N" || EditDeleteAccess === "Y" || EditDeleteAccess === "D") && AttendeeResponse !== '2') {
          declineVis = true;
        }
      } else {
        if (
          // eslint-disable-next-line
          AttendeeResponse === "1" ||
          // eslint-disable-next-line
          AttendeeResponse === "MoveToActual"
        ) {
          declineVis = true;
        }
        if (
          MeetingMode === "P" && (EditDeleteAccess === "N" || EditDeleteAccess === "Y") && (AttendeeResponse === "1" || AttendeeResponse === "")) {
          declineVis = true;
        }
        if (MeetingMode === "A" && (EditDeleteAccess === "N" || EditDeleteAccess === "Y" || EditDeleteAccess === "D")) {
          declineVis = true;
        }
        if (AttendeeResponse === "ActualRes") {
          declineVis = true;
        }
      }
    }
    const { meetingdate, categoryName, readWriteAccess, } = this.props;
    const {
      starttimeopen,
      starttime,
      meetingTemplateOpen,
      open,
      message,
      button1Caption,
      button2Caption,
      copied
    } = this.state;

    let declineAttendee = [];
    let otherAttendee = [];
    let attendeeOnLeave=[]

    mArrAttendee.forEach((item) => {
      if (item.Status === "#92CDDC") {
        attendeeOnLeave.push(item);
      } else if (item.Status === "#A52A2A") {
        declineAttendee.push(item)
      }
      else {
        otherAttendee.push(item)
      }
    })
    const showCopyMessage = copied ? "Link Copied" : "Copy Link to Clipboard"
    return (
      <>
        <div>
          <div>
            <div>
              <div className="e-date-time pt-xs">
                <div
                  style={{ cursor: "pointer" }}
                  className="e-date-time-icon1 e-icons1 pr-sm"
                  title="Meeting date/time"
                >
                  <FontAwesomeIcon
                    id="pointer"
                    icon={faCalendar}
                    style={{ cursor: "pointer" }}
                  />
                </div>
                <div className="e-date-time-wrapper e-text-ellipsis">
                  <div className="e-date-time-details e-text-ellipsis">
                    {meetingdate}
                  </div>
                </div>
              </div>
              <div className="e-location pt-xs">
                <div
                  style={{ color: "#666", cursor: "pointer" }}
                  title="Meeting Rooms"
                  className="e-location-icon1 e-icons1 pr-sm"
                >
                  <FontAwesomeIcon
                    id="pointer"
                    icon={faMapMarker}
                    style={{ cursor: "pointer", marginRight:1.5}}
                  />
                </div>
                <div className="e-location-details e-text-ellipsis">
                  {MeetingRooms}
                </div>
              </div>
              {
                // categoryName !== OTHERS_CHECK_AVAILABILITY &&
                <div className="e-location pt-xs">
                  <div
                    style={{ color: "#999", marginRight: 7 }}
                    className="e-location-icon1"
                  >
                    {this.getIcon(MeetingMediaIcon, MeetingMedia)}
                  </div>
                  <div
                    className="e-location-details e-text-ellipsis"
                    title={MeetingType}
                  >
                    {MeetingType.length > 60
                      ? `${MeetingType.substring(0, 60)}...`
                      : MeetingType}
                  </div>
                </div>}

              {categoryName !== OTHERS_CHECK_AVAILABILITY && WebLink !== "" && (
                <div className="e-location pt-xs">
                  <div
                    style={{ color: "#999", marginRight: 8 }}
                    className="e-location-icon1"
                  >
                    {this.getMediaIcon(WebLinkType)}
                  </div>
                  <div
                    style={{ color: "#999", display: "flex" }}
                    className="e-location-details e-text-ellipsis"
                    title={WebLink}
                  >
                    {WebLink !== "" ? (<>
                      <a
                        className="e-text-ellipsis weblinkStyle"
                        href={WebLink}
                        target="blank"
                      >Open {this.getMediaName(WebLinkType)} Link</a>
                     
						  
                      <span style={{ marginLeft: 5 }} onClick={() => {
                        navigator.clipboard.writeText(WebLink)
                        this.setState({ copied: true })
                      }}
                      >
                        <TooltipComponent
                          id="agendapointer"
                          content={showCopyMessage}
                          cssClass="agendatooltip customtooltip"
                          position="right"

                        >
                          <FontAwesomeIcon
                            icon={faCopy}
                            style={{ cursor: "pointer", fontSize: 13 }}
                          />
                        </TooltipComponent>
                      </span>
                    </>
                    ) : null}
                  </div>
                </div>
              )}
              {((Agenda !== "NA" && Agenda !== "" && Agenda !== null) || (ActionItems !== "NA" && ActionItems !== "" && ActionItems)) && (
                <div className="e-location pt-xs gap-20">
                  {(Agenda !== "NA" && Agenda !== "" && Agenda !== null) && <TooltipComponent
                    id="agendapointer"
                    content={Agenda.replace(/(?:\r\n|\r|\n)/g, "<br>")}
                    cssClass="agendatooltip customtooltip"
                    position="BottomCenter"
                    isSticky
                  >
                    <FontAwesomeIcon
                      icon={faClipboardCheck}
                      style={{ cursor: "pointer", fontSize: 13,marginRight: 5 }}
                    />
                    &nbsp; Agenda
                  </TooltipComponent>}
                  {(ActionItems !== "NA" && ActionItems !== "" && ActionItems) && <p style={{ cursor: 'pointer' }} onClick={() => {
                    this?.props?.handleViewMeetingpopup?.();
                    this.closeQuickInfo();
                  }}>
                    <FontAwesomeIcon
                      icon={faSquareList}
                      style={{ cursor: "pointer", fontSize: 13,marginRight: 5 }}
                    />
                    &nbsp; Action Items
                  </p>}
                </div>
              )}

              {cCountries.length > 0 && (
                <hr style={{ marginTop: "10px", marginBottom: "0px" }} />
              )}
              {categoryName !== OTHERS_CHECK_AVAILABILITY && cCountries.length > 0 && (
                <div className="mt-sm">
                  <h6>Conference Bridge Telephone Number</h6>
                  <ul className="customAttendeeList">
                    {cCountries.map((element, index) =>

                      svgs.map((item) => {
                        if (element.svg === item.path) {
                          return (
                            <li
                              className="e-text-ellipsis"
                              key={
                                // eslint-disable-next-line
                                index}
                              style={{
                                color: element.Status,
                                cursor: "pointer",
                                height: 25,
                              }}
                              title={element.name}
                            >
                              <div style={{ float: "left" }}>
                                <ReactSVG
                                  src={require("../../assets/svg/" + String(item.path).split("./")[1]).default}
                                  style={{ height: 20, width: 20 }}
                                />
                              </div>
                              <div style={{ fontSize: 10, float: "left" }}>
                                {" "}
                                &nbsp; {element.number}
                              </div>
                            </li>
                          );
                        }
                        return null;
                      })
                    )}
                  </ul>
                </div>
              )}

              {categoryName !== OTHERS_CHECK_AVAILABILITY &&
                ParticipateCode !== "" ? (
                <div className="e-location pt-xs">
                  <div className="e-location-icon1">
                    <FontAwesomeIcon
                      id="pointer"
                      icon={faSearchPlus}
                      style={{ cursor: "pointer", fontSize: 13,marginRight:7 }}
                      title="Conference Bridge code"
                    />
                  </div>
                  <div
                    className="e-location-details e-text-ellipsis"
                    title="Conference Bridge code"
                    style={{ cursor: "pointer", fontSize: 13 }}
                  >
                    Conference Bridge code: {ParticipateCode}
                  </div>
                </div>
              ) : null}
              <hr style={{ marginTop: "10px", marginBottom: "0px" }} />
              <div className="mt-sm">
                <h6>Attendee ({otherAttendee.length > 100 ? '100+' : otherAttendee.length}) </h6>
                <ul className="attendeeList">
                  {otherAttendee.map((element) => {
                    return (
                      <li
                        className="e-text-ellipsis attendee-text-font"
                        key={element.Attendee}
                        style={{ color: element.Status, cursor: "pointer", fontSize: '9px !important' }}
                        title={this.getAttendeeTitle(element.Icon)}
                      >


                        {
                          element.Icon === "faStar" || element.Icon === "faUserImp" ?
                            this.getAttendeeIcon(element.Icon) : null
                        }

                        {element.Attendee}
                      </li>
                    );
                  })}
                </ul>
              </div>
              {
                declineAttendee.length > 0 &&
                <>
                  <hr style={{ marginTop: "10px", marginBottom: "0px" }} />
                  <div className="mt-sm">
                    <h6>Declined Attendee ({declineAttendee.length > 100 ? '100+' : declineAttendee.length})</h6>
                    <ul className="attendeeList">
                      {declineAttendee.map((element) => {
                        return (
                          <li
                            className="e-text-ellipsis attendee-text-font"
                            key={element.Attendee}
                            style={{ color: element.Status, cursor: "pointer", fontSize: '9px !important' }}
                            title={this.getAttendeeTitle(element.Icon)}
                          >
                            {element.Attendee}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </>
              }
              {
                attendeeOnLeave.length > 0 &&
                <>
                  <hr style={{ marginTop: "10px", marginBottom: "0px" }} />
                  <div className="mt-sm">
                    <h6>On Leave ({attendeeOnLeave.length > 100 ? '100+' : attendeeOnLeave.length})</h6>
                    <ul className="attendeeList">
                      {attendeeOnLeave.map((elem) => {
                        return (
                          <li
                            className="e-text-ellipsis attendee-text-font"
                            key={elem.Attendee}
                            style={{ color: elem.Status, cursor: "pointer", fontSize: '9px !important' }}
                            title={this.getAttendeeTitle(elem.Icon)}
                          >
                            {elem.Attendee}
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </>
              }

              {ContactIds !== null && categoryName !== OTHERS_CHECK_AVAILABILITY && (
                <>
                  <hr style={{ marginTop: "10px", marginBottom: "0px" }} />
                  <div className="mt-sm">
                    <h6>Client Attendee ({BDContacts.length})</h6>
                    <div
                      style={{
                        height: "auto",
                        overflow: "auto",
                        maxHeight: "77px",
                      }}
                    >
                      <table width="100%" className="clientMettingTable">
                        <thead>
                          <tr>
                            <th className="e-text-ellipsis">Name</th>
                            <th className="e-text-ellipsis">Job Title</th>
                          </tr>
                        </thead>
                        <tbody>
                          {BDContacts.map((element) => {
                            return (
                              <tr key={element.ContactId}>
                                <td
                                  className="e-text-ellipsis"
                                  title={element.ContactName}
                                  style={{ cursor: "pointer" }}
                                >
                                  {element.ContactName}
                                </td>
                                <td
                                  className="e-text-ellipsis"
                                  title={element.JobTitle}
                                  style={{ cursor: "pointer" }}
                                >
                                  {element.JobTitle}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </>
              )}

              {categoryName !== OTHERS_CHECK_AVAILABILITY && (
                <div>
                  <hr style={{ marginTop: "10px", marginBottom: "10px" }} />
                  <div className="mt-sm">
                    <h6 className="m-none pull-left">Meeting Setter &nbsp;&nbsp; &nbsp;:</h6>
                    <h6
                      className="m-none pl-md pull-left"
                      style={{ color: "#676767" }}
                    >
                      {MeetingSetter}
                    </h6>
                  </div>
                  <br />

                  <div className="mt-sm">
                    <h6 className="m-none pull-left">Meeting Creation  :</h6>
                    <h6 className="m-none pl-md pull-left"
                      style={{ color: "#676767" }}>
                      {meetingCreationDate}
                    </h6>
                  </div>
                  {
                    meetingModificationDate !== null && meetingModificationDate !== "" && meetingModificationDate !== meetingCreationDate &&
                    <>
                      <br />
                      <div className="mt-sm">
                        <h6 className="m-none pull-left">Last Modification :</h6>
                        <h6 className="m-none pl-md pull-left"
                          style={{ color: "#676767" }}>
                          {meetingModificationDate}
                        </h6>
                      </div>
                    </>
                  }

                  <div
                    style={{
                      textAlign: "center",
                      width: "100%",
                      display: "table",
                    }}
                  >
                    {acceptVis &&
                      (readWriteAccess === "Y" || readWriteAccess === "") && (
                        // new Date(this.props.data.StartTime).getTime() > new Date().getTime() &&
                        <Button
                          style={{
                            background: "#297125",
                            fontSize: "12x",
                            color: "#fff",
                          }}
                          className="mt-lg mr-sm pl-sm pr-sm"
                          title=""
                          size="small"
                          variant="contained"
                          color="inherit"
                          onClick={() => this.handleInputChange("Accept")}
                        >
                          <FontAwesomeIcon
                            style={{ color: "#fff" }}
                            icon={faCheck}
                            className="mr-xs"
                          />
                          Accept
                        </Button>
                      )}
                    {declineVis &&
                      (readWriteAccess === "Y" || readWriteAccess === "") && (
                        <Button
                          style={{ fontSize: "12px",display:displayStyle }}
                          className="mt-lg mr-sm pl-sm pr-sm"
                          title=""
                          size="small"
                          variant="contained"
                          color="primary"
                          onClick={() => this.handleInputChange(new Date(StartTime).getTime() < new Date().getTime() ? "Did Not Attend" : "Decline")}
                        >
                          <FontAwesomeIcon icon={faTimes} className="mr-xs" />
                          {new Date(StartTime).getTime() < new Date().getTime()
                            ? "Did Not Attend"
                            : " Decline"}
                        </Button>
                      )}
                    {suggestVis &&
                      (readWriteAccess === "Y" || readWriteAccess === "")&& (
                        <Button
                          style={{
                            background: "#146f68",
                            color: "#fff",
                            fontSize: "12px",
                            display:displayStyle
                          }}
                          className="mt-lg pl-sm pr-sm"
                          title=""
                          size="small"
                          variant="contained"
                          color="inherit"
                          onClick={() => this.handleInputChange("Suggest")}
                        >
                          <FontAwesomeIcon
                            style={{ color: "#fff" }}
                            icon={faClock}
                            className="mr-xs"
                          />
                          Suggest New Time
                        </Button>
                      )}
                    {SaveTemplate === "Y" &&
                      typeof categoryName !== "undefined" &&
                      categoryName !== OTHERS_CALENDAR &&
                      categoryName !== OTHERS_CHECK_AVAILABILITY && (
                        <Button
                          style={{
                            background: "#000000",
                            color: "#fff",
                            fontSize: "12px",
                            display:displayStyle
                          }}
                          className="mt-lg pl-sm pr-sm ml-sm"
                          title=""
                          size="small"
                          variant="contained"
                          color="inherit"
                          onClick={this.saveastemplate}
                        >
                          <FontAwesomeIcon
                            style={{ color: "#fff" }}
                            icon={faFile}
                            className="mr-xs"
                          />
                          Save As Template
                        </Button>
                      )}
                    <div style={{ display: "none", width: "376px" }}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DateTimePicker
                          open={starttimeopen}
                          id="MuiPickersModal-dialog-custom"
                          label="DateTimePicker"
                          inputVariant="outlined"
                          value={starttime}
                          onClose={this.handleStartTimeClose}
                          onChange={this.handleMeetingStartTimeChange}
                          minDate={new Date()}
                          clearable
                          ampm={false}
                        />
                      </MuiPickersUtilsProvider>
                    </div>
                    {/* ADD Template - Pop up */}
                    <div style={{ display: "none", width: "376px" }}>
                      <ErrorBoundary>
                        <Dialog
                          onClose={this.handleClose}
                          aria-labelledby="meeting-template-dialog-title"
                          open={meetingTemplateOpen}
                          maxWidth="xs"
                          fullWidth
                          TransitionComponent={Transition}
                          
                          disableEscapeKeyDown
                          transitionDuration={{
                            enter: duration.enteringScreen,
                            exit: duration.leavingScreen,
                          }}
                          PaperComponent={PaperComponent}
                        >
                          <DialogTitle
                            id="meeting-booking-dialog-title"
                            onClose={this.handleClose}
                          >
                            Template Name
                          </DialogTitle>
                          <DialogContent dividers>
                            {/* <form id="meetingtemplatefrm" method="post"> */}
                            <div className="e-input-group e-float-icon-left">
                              <div className="e-input-in-wrap">
                                <TextBoxComponent
                                  placeholder="Template Name"
                                  required
                                  cssClass="e-outline"
                                  floatLabelType="Auto"
                                  name="template-name-txt"
                                  id="template-name-txt"
                                  data-msg-containerid="template-nameError"
                                  change={(e) => this.setState({ templateName: e.value })}
                                  htmlAttributes={{
                                    maxlength: "50",
                                    minLength: 1,
                                  }}
                                />
                              </div>
                            </div>
                            <div
                              id="templateError"
                              className="error-div"
                              style={{ marginLeft: 15 }}
                            />
                            {/* </form> */}
                          </DialogContent>
                          <DialogActions>
                            <Button
                              autoFocus
                              onClick={this.handleSubmit}
                              color="primary"
                              variant="contained"
                              title="Add Template"
                            >
                              Add Template
                            </Button>
                            <Button
                              autoFocus
                              onClick={this.handleClose}
                              color="secondary"
                              variant="contained"
                              title="Close"
                            >
                              Close
                            </Button>
                          </DialogActions>
                        </Dialog>
                      </ErrorBoundary>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <ConfirmationPopup
          showButton={{ type: "1" }}
          open={open}
          button1function={()=>this.handleConfirmYes()}
          button2function={()=>this.handleConfirmNo()}
          headerMessage={message}
          button1Text={button1Caption}
          button2Text={button2Caption}
          handleConfirmClose={this.handleConfirmClose} 
        />
      </>
    );
  }
}
DetailsContent.propTypes = {
  // eslint-disable-next-line
  data: PropTypes.object,
  authUser: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  meetingdate: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.string,
  ]),
  categoryName: PropTypes.string,
  readWriteAccess: PropTypes.string,
  handleViewMeetingpopup: PropTypes.func,
  scheduleObj: PropTypes.object,
};

DetailsContent.defaultProps = {
  data: [],
  authUser: "",
  meetingdate: new Date(),
  categoryName: "",
  readWriteAccess: "",
};
export default DetailsContent;
