import React, { useState } from "react";
import { connect } from "react-redux";
import deleteIcon from "../../../../assets/svg/delete-icon.svg";
import { ComboBoxComponent } from "@syncfusion/ej2-react-dropdowns";
import ConfirmationPopup from "../../../../utilities/confirmationpopup";

export const RegularizationGrid = ({ attendanceType, tibrData, setTibrData, selectedAttendance, setSelectedAttendance }) => {
  const [confirmDelete, setConfirmdelete] = useState({status: false, deleteId: '', deleteDate: ''});

  /**
   * Author: Chandrashekhar Bhagat.
   * Created:05-December-2023
   * @params e.
   * @description to remove column from grid.
   */
  const removeColumn = () => {
    const filtered = tibrData.filter(item => item.Id !== confirmDelete.deleteId);
    setTibrData(filtered);
    // check whether it is presented in Payload Array or not.
    const updatedReg = selectedAttendance.filter(item => !item.includes(confirmDelete.deleteDate));
    setSelectedAttendance(updatedReg);
    setConfirmdelete({...confirmDelete, status: false, deleteId: '', deleteDate: ''});
  }

  const chooseRegularization = (e, rowData) => {
    // Dropdown Temp Value Updated
    const index = tibrData.findIndex(item => item.Id === rowData.Id);
    tibrData[index] = { ...tibrData[index], tempSelected: e.itemData.Id};
    setTibrData(tibrData);

    // Payload Array Updated
    const indexId = selectedAttendance.findIndex(item => item.includes(rowData.date));
    if (indexId !== -1) {
      selectedAttendance[indexId] = rowData.date + '_' + e.itemData.concatValue;
    } else {
      selectedAttendance.push(rowData.date + '_' + e.itemData.concatValue);
    }

    // Remove highlight
    document.getElementById(`attendanceChangedTo${rowData.Id}`).style.border = 'none';
  }
  
  const attandanceChangeToTemplate = (rowData) => {
    const isEditable = rowData.attendanceType === "WO" || rowData.attendanceType === "HO" ? false : true;
    return (
      <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
        <div className="col-xs-10 col-sm-10 col-lg-10 col-md-10 attendance-changed-to">
          <ComboBoxComponent
            id={`attendanceChangedTo${rowData.Id}`}
            dataSource={attendanceType}
            fields={{ text: "Name", value: "Id" }}
            value={rowData.tempSelected}
            data-msg-containerid="attendanceChangedToErr"
            name="attendanceChangedToErr"
            cssClass="e-outline minimize-height circular-regular"
            placeholder="Select"
            allowCustom={false}
            select={(e)=>chooseRegularization(e, rowData)}
            enabled={isEditable}
          />
        </div>
        
        <div
            className="col-xs-2 col-sm-2 col-lg-2 col-md-2"
            style={{
                paddingTop: "10px",
                paddingLeft: 0,
                paddingRight: 0
            }}
            onClick={()=>setConfirmdelete({...confirmDelete, status: true, deleteId: rowData.Id, deleteDate: rowData.date})}
        >
          <img alt="" src={deleteIcon} style={{ height: "1.8rem", cursor:"pointer" }} />
        </div>  
      </div>
    );
  };

  /**
   * Author: Chandrashekhar Bhagat.
   * Created:05-December-2023
   * @params item.
   * @description convert format '2023-11-19' to '19-Nov-2023 Sunday'.
   */
  const dateTemplate = (item) => {
    const date = new Date(item.date);
    const day = date.getDate();
    const weekDay = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'][date.getDay()];
    const month = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(date);
    const year = date.getFullYear();
    const formattedDate = `${day}-${month}-${year}`;
    return (
      <div style={{float:'left', paddingLeft:'15px'}}>{formattedDate} &nbsp; {weekDay} </div>
    )
  }

  return (
    <div  className="circular-regular">
      <table className="regularizationGridId">
        <thead>
            <tr>
                <th>Date</th>
                <th>Attendance</th>
                <th>TIBR</th>
                <th>Attendance Changed To</th>
            </tr>
        </thead>
        <tbody>
            {tibrData.map((item, inx) => (
                <tr key={`tr${inx}`}>
                  <td>{dateTemplate(item)}</td>
                  <td>{item.attendanceType}</td>
                  <td>{item.timeInBusiness}</td>
                  <td>{attandanceChangeToTemplate(item)}</td>
                </tr>
              ))
            }
        </tbody>
      </table>
      <ConfirmationPopup
        headerMessage={'Are you sure want to delete the column?'}
        button1Text={"Yes"}
        button2Text={"No"}
        button2function={()=>setConfirmdelete({...confirmDelete, status: false, deleteId: '', deleteDate: ''})}
        button1function={removeColumn}
        showButton={{ type: "1" }}
        open={confirmDelete.status}
        handleConfirmClose={() => setConfirmdelete({status: false})}
      />
    </div>
  );
};

const mapStateToProps = ({ auth, lnareducer }) => {
  const { authUser } = auth;
  const { attendanceType } = lnareducer;
  return {
    authUser,
    attendanceType
  };
};

export default connect(mapStateToProps, {})(RegularizationGrid);
