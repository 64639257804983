/**
 * Author: Suresh R. Sargam.
 * Last Modify:15-May-2020
 */

 import * as React from "react";
 import PropTypes from "prop-types";
 import { library } from "@fortawesome/fontawesome-svg-core";
 import { connect } from "react-redux";
 import {
   faArrowAltToTop,
   faCheckCircle,
   faEye,
   faTimesCircle,
   faPlusCircle,
 } from "@fortawesome/pro-light-svg-icons";
 import ProgressBar from '../progressbar/progressbar'
 import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
 import "../../app/containers/tmlc/onboarding/onboarding.css";
 import "./documents.css";
 import {
   NEW_CANDIDATE_UPLOAD,
   EMPLOYEE_UPLOAD,
 } from "../../redux/constant/entryexit/candidateconstant";
 import { getNotify } from "../../app/other/commonfunction";
 import {
   WARNING,
   FileSize,
   FileType,
   SUCCESS,
   ERROR
 } from "../../assets/constants/constants";
 import Comments from '../document/comments/newcomments';
 
 import { defFileType, urlConfig, corsallow, maxFileSize } from "../../config/config";
 import { updateDocumentInfo, updateDocumentStatus } from "../../redux/actions/entryexit/onboardingaction";
 library.add(faArrowAltToTop, faEye, faCheckCircle, faTimesCircle, faPlusCircle);
 
 class NewDocuments extends React.Component {
   constructor(props) {
     super(props);
     const { content } = this.props;
     this.state = {
       content: content,
       openDocumentComments: false,
       contentIndex: null,
       isLoading: false
     }
 
     this.folderPath = ''
     this.docIndex = null
     this.selectedFolderId = null
     this.documentType = null;
 
     this.categoryName = null;
     this.showComments = null;
     this.showApprovalRejection = false;
 
     this.fileInputRef = React.createRef();
     this.openFileDialog = this.openFileDialog.bind(this);
     this.fileListToArray = this.fileListToArray.bind(this);
     this.sendRequest = this.sendRequest.bind(this);
   }
   /**
    * @author Vivek Khodade
    * @description to open file dialog
    */
   openFileDialog(folderPath, folderid, docIndex, docType) {
     this.folderPath = folderPath
     this.docIndex = docIndex
     this.selectedFolderId = folderid
     this.documentType=docType
     document.getElementById(`input${docIndex}${folderid}`).click(); // NOSONAR
   }
 
   /**
    * @author Vivek Khodade
    * @description Add files to list of array
    */
   fileListToArray = (list) => {
     const array = [];
     const items = list;
     for (let i = 0; i < items.length; i += 1) {
       items.item(i).folderPath = this.folderPath;
       array.push(items.item(i));
     }
     return array;
   }
   /**
    * @author Vivek Khodade.
    * @description fired this event once the file is added
    */
   onFilesAdded = async (evt) => { //NOSONAR
     if (evt.target.files.length > 0 && typeof evt.target.files[0] !== undefined && typeof evt.target.files[0].size !== undefined && typeof evt.target.files[0].type !== undefined) {
       const size = evt.target.files[0].size / 1024;
       const filetype = evt.target.files[0].type;

      if (!defFileType.includes(filetype) || filetype === ''){
          if(this.documentType!=='other'){
            getNotify(WARNING, FileType)
            return;
          }else{
            getNotify(WARNING, "Please select pdf format files only.")
          }
                  
      }
      else if (!(size >0 && size < maxFileSize)){
         getNotify(WARNING, FileSize);
         return;
      }
      else {
        let otherTyps=['application/pdf'];
         if(this.documentType==='other'){
          if(!otherTyps.includes(filetype)){
            getNotify(WARNING, 'Please select pdf format files only.')
            return;
          }
        }
        const { files } = evt.target;
        const filesArray = this.fileListToArray(files);
        const promises = [];
        filesArray.forEach((file) => {
          // this.setState({isLoading:true})
          promises.push(this.sendRequest(file));
        });
        try {
          await Promise.all(promises);
          getNotify(SUCCESS, "File uploaded successfully");

        } catch (e) {
          getNotify(ERROR, "Error while file upload");
        }
      }
     }
   }
   sendRequest = (file) => {
     return new Promise(async (resolve, reject) => {
       const req = new XMLHttpRequest();
       req.upload.addEventListener("progress", (event) => {
         if (event.lengthComputable) {
           // eslint-disable-next-line
         }
       });
       req.upload.addEventListener("load", () => {
         // eslint-disable-next-line
         setTimeout(() => {
           resolve(req.response);        
         }, 1000)
       });
 
       req.upload.addEventListener("error", () => {
         // eslint-disable-next-line
         reject(req.response);
       });
 
       try {
         this.setState({ isLoading: true })
         const formData = new FormData();
         const { authUser, candidateType, empid, candidateid } = this.props;
         const { content } = this.state;
         formData.append(
           "usertype",
           candidateType === EMPLOYEE_UPLOAD ? "E" : "C"
         );
 
         formData.append("type", "D");
         formData.append("filepath", file.folderPath);
         formData.append("folderid", this.selectedFolderId);
         formData.append("fileSize", file.size);
         formData.append(
           "cand_emp_id",
           candidateType === EMPLOYEE_UPLOAD ? empid : candidateid
         );
         formData.append("file", file, file.name);
 
         if (candidateType === NEW_CANDIDATE_UPLOAD) {
           formData.append("isuploadedbycand", "Y");
           formData.append("userid", 0);
         } else {
           formData.append("userid", authUser);
         }
         // If uploaded by candidate pass this parameter
         req.open("POST", `${corsallow}${urlConfig.tmlcApiUrl}document/upl`);
         if (candidateType !== NEW_CANDIDATE_UPLOAD) {
           req.setRequestHeader("Authorization", localStorage.getItem("jwtToken"));
         }
 
         req.onreadystatechange = () => {
           if (req.readyState === XMLHttpRequest.DONE) {            
             let newFile = {};
               let temp = content;
               newFile = {
                 Approved: '', DocComments: "",
                 DocId: JSON.parse(req.response).DocId,
                 DocName: file.name,
                 DocPath: file.folderPath,
                 LastModified: file.lastModifiedDate,
                 Rejected: ''
               };
               temp[this.docIndex].HasFiles = true;
               temp[this.docIndex].Approved = '';
               temp[this.docIndex].Rejected = '';
               temp[this.docIndex].FileSize = file.size;
               temp[this.docIndex].Files = [newFile];
               this.docIndex = null
               this.setState({ content: temp }, () => this.fileInputRef = React.createRef());
               this.setState({ isLoading: false })            
           }
         }
         req.send(formData);
       } catch (error) {
       }
     });
   }
   getRandomNumberBetween = (min, max) => {
     return Math.floor(Math.random() * (max - min + 1) + min);
   }
   // eslint-disable-next-line
   UNSAFE_componentWillReceiveProps(newProps) {
     if (newProps.openDocumentComments === false) {
       this.setState({
         openDocumentComments: false
       })
       this.categoryName = null;
       this.showComments = null;
       this.showApprovalRejection = false;
       this.selectedFolderId = null;
       this.documentType = null;
     }
     if (newProps.refreshNewDoc === "updated") {
       const { content, contentIndex } = this.state;
       if (contentIndex !== null) {
         let temp = content;
         temp[contentIndex].DocComments = "Y"
         temp[contentIndex].Files[0].DocComments = "Y"
         this.setState({
           content: temp,
           contentIndex: null
         })
         this.props.updateDocumentInfo({
           refreshNewDoc: ""
         });
       }
 
     }
   }
   render() { // NOSONAR
     const { header, candidateType, id, authUser } = this.props;
     const { content, openDocumentComments, isLoading } = this.state;
     return (
       <div className='col-sm-12 col-xs-12 col-lg-12 col-md-12'
         style={{ background: '#F2F2F28C' }}
       >
         <div>{header}</div>
         <div>
           {
             !isLoading ?
               <div className='col-sm-12 col-xs-12 col-lg-12 col-md-12'>
                 {
 
                   id === 1 ? // NOSONAR
                     content !== null &&
                     content.length > 0 &&
                     content.map((data, index) => {
                       let fileurl = '';
                       if (data.HasFiles === true) {
                         fileurl = urlConfig.tmlcApiUrl + 'document/' +
                           (candidateType === EMPLOYEE_UPLOAD ? 'ED' : 'D') + '/'
                           + (candidateType === NEW_CANDIDATE_UPLOAD ? '0' : authUser) + '/' + data.FolderPath.replace(/\//g, '~') + data.Files[0].DocName;
                       }
                       return (<div
                         className='col-sm-10 col-xs-12 col-lg-3 col-md-3 mt-md'
                         // className='col-sm-10 col-xs-12 col-lg-3 col-md-3 mb-md mt-md' 
                         id={`content${data.Id}`}
                         key={`content${data.Id}`}
                         style={{
                           // padding: '0px 10px 0px 10px', 
                           height: '115px'
                         }}
                       >
                         {
                           data.HasFiles === true ?
                             <div className='candupl-box-outline col-lg-12'>
                               <div className='col-sm-5 col-xs-5 col-lg-4 col-md-5'>
                                 {data.Files[0].DocName.split('.').pop() === 'pdf' ? // NOSONAR
                                   <div className='image-view-pdf'>
                                     <a href={fileurl} download target="_blank" rel="noopener noreferrer">
                                       <i className="fas fa-file-pdf" title='Preview file' style={{ fontSize: 54, color: 'red' }}></i>
                                     </a>
                                   </div>
                                   :
                                   <a href={fileurl} download target="_blank" rel="noopener noreferrer">
                                     <img id='tes' className='image-view' title='Preview file' src={fileurl} alt='' />
                                   </a>
                                 }
                               </div>
                               <div className='col-sm-4 col-xs-4 col-lg-5 col-md-4'>
                                 <div className='image-name-box'>
                                   <div className='image-name'>
                                     {data.Files[0].DocName}
                                   </div>
                                   <div className='image-size'>
                                     {
                                       data.FileSize === undefined ? // NOSONAR
                                         0
                                         :
                                         parseInt(parseInt(data.FileSize, 10) / 1024, 10)
                                     }&nbsp;Kb
                                  </div>
                                 </div>
                               </div>
                               <div className='col-sm-3 col-xs-3 col-lg-3 col-md-3'>
                                 <div className='cancel-icon row'>
                                   {
                                     data.Rejected === "Y" &&
                                     <div className='upload-icon-reject'
                                       style={{ cursor: 'pointer' }}
                                       title='File upload'
                                       onClick={() => {
                                         this.openFileDialog(data.FolderPath, data.Id, index, 'Gov');
                                       }}
                                     >
                                       <input
                                         id={`input${index}${data.Id}`}
                                         ref={this.fileInputRef}
                                         className="FileInput"
                                         style={{ display: "none" }}
                                         type="file"
                                         accept="image/*"
                                        //  accept="image/jpeg,image/png,image/jpg,application/pdf"
                                         onChange={this.onFilesAdded}
                                       />
                                     </div>
                                   }
                                   {
                                     data.Rejected === "Y" &&
                                     <i className="far fa-times-circle custom-reject-style pl-xlg"
                                       title="File is rejected please re-upload"
                                     ></i>
                                   }
                                   {
                                     data.Approved === "" && data.Rejected === "" &&
                                     <i className="fas fa-times custom-close-style pl-xlg"
                                       title='Cancel file'
                                       onClick={() => {
                                         let temp = content;
                                         const fileData = {
                                           authUser:0,
                                           docid: temp[index].Files[0].DocId,
                                           status: "D",
                                         };
                                         this.props.updateDocumentStatus(fileData);
 
                                         temp[index].HasFiles = false
                                         this.setState({ content: temp })
                                       }}
                                     >
                                     </i>
                                   }
                                   {
                                     data.Approved === "Y" &&
 
                                     <i className="far fa-check-circle custom-approve-style pl-xlg"
                                       title='Approved'></i>
                                   }
                                 </div>
                               </div>
                             </div>
                             :
                             <div className='candupl-box-outline'>
                               <center>
                                 <input
                                   id={`input${index}${data.Id}`}
                                   ref={this.fileInputRef}
                                   className="FileInput"
                                   style={{ display: "none" }}
                                   type="file"
                                   accept="application/pdf"
                                  //  accept="image/jpeg,image/png,image/jpg,application/pdf"
                                   onChange={this.onFilesAdded}
                                 />
                                 <div className='upload-icon'
                                   onClick={() => {
                                     this.openFileDialog(data.FolderPath, data.Id, index, 'Gov');
                                   }}
                                   style={{ cursor: "pointer" }}
                                 />
                               </center>
                               <div className='candupl-box-outline-headertext'>{data.DocumenytType}</div>
                               <div className='candupl-box-outline-eg-text'>e.g. Aadhaar for India, Passport for other countries, Pan </div>
                             </div>
                         }
                         <div className='col-sm-12 col-xs-12 col-lg-12 col-md-12'>
                           {
                            //  data.HasFiles === true && 
                             data.Rejected === 'Y' ?
                             <>
                               <div className='col-sm-11 col-xs-11 col-lg-11 col-md-11 candupl-reject-message'>
                               Document not approved. Please re-upload.
                                </div>
                                <div className='col-sm-1 col-xs-1 col-lg-1 col-md-1 candupl-add-priview'>
                                <span className=''>
                                  {data.HasFiles === false && (data.Rejected === 'Y') &&
                                    <FontAwesomeIcon
                                      icon={faEye}
                                      title='Preview comments'
                                      onClick={() => { // NOSONAR
                                        this.categoryName = data.DocumenytType;
                                        this.showComments = true;
                                        this.selectedFolderId = data.Files[0].DocId
                                        setTimeout(() => {
                                          this.setState({ openDocumentComments: true, contentIndex: index })
                                        }, 500)
                                        this.props.updateDocumentInfo({ openDocumentComments: true })
                                      }}
                                    />
                                  }
                                </span>
                                </div>
                                </>
                               :
                               <div className='col-sm-10 col-xs-10 col-lg-10 col-md-10 candupl-add-commet'
                                 onClick={() => {
                                   if (data.HasFiles === true) {
                                     this.categoryName = data.DocumenytType;
                                     this.showComments = true;
                                     this.selectedFolderId = data.Files[0].DocId
                                     setTimeout(() => {
                                       this.setState({ openDocumentComments: true, contentIndex: index })
                                     }, 500)
                                     this.props.updateDocumentInfo({ openDocumentComments: true })
                                   }
                                 }}
                               >
 
                                 {
                                   data.DocComments === "Y" ? // NOSONAR
                                     <span>
                                       Comments
                                       &nbsp;
                                      <FontAwesomeIcon
                                         icon={faEye}
                                         style={{ fontSize: 14 }}
                                         title='Preview comments'
                                         onClick={() => {//nosonar
                                           this.categoryName = data.DocumenytType;
                                           this.showComments = true;
                                           this.selectedFolderId = data.Files[0].DocId
                                           setTimeout(() => {
                                             this.setState({ openDocumentComments: true, contentIndex: index })
                                           }, 500)
                                           this.props.updateDocumentInfo({ openDocumentComments: true })
                                         }}
                                       />
                                     </span>
                                     :
                                     data.HasFiles === true ? // NOSONAR
                                       "Add Comments"
                                       :
                                       ''
                                 }
                               </div>
                           }
                           <div className='col-sm-1 col-xs-1 col-lg-1 col-md-1 candupl-add-priview'>
                             <span className=''>
                               {data.HasFiles === true && (data.Rejected === 'Y') &&
                                 <FontAwesomeIcon
                                   icon={faEye}
                                   title='Preview comments'
                                   onClick={() => { // NOSONAR
                                     this.categoryName = data.DocumenytType;
                                     this.showComments = true;
                                     this.selectedFolderId = data.Files[0].DocId
                                     setTimeout(() => {
                                       this.setState({ openDocumentComments: true, contentIndex: index })
                                     }, 500)
                                     this.props.updateDocumentInfo({ openDocumentComments: true })
                                   }}
                                 />
                               }
                             </span>
                           </div>
                         </div>
                       </div>)
                     })
                     :
                     content !== null &&
                     content.length > 0 &&
                     content.map((data, index) => {
                       let fileurl = '';
                       if (data.HasFiles === true) {
                         fileurl = urlConfig.tmlcApiUrl + 'document/' +
                           (candidateType === EMPLOYEE_UPLOAD ? 'ED' : 'D') + '/'
                           + (candidateType === NEW_CANDIDATE_UPLOAD ? '0' : authUser) + '/' + data.FolderPath.replace(/\//g, '~') + data.Files[0].DocName;
                       }
                       return (
                         <div
                           // className='col-sm-10 col-xs-12 col-lg-3 col-md-3 mb-md mt-md' 
                           className='col-sm-10 col-xs-12 col-lg-3 col-md-3 mt-md'
                           id={`content${data.Id}`}
                           key={`content${data.Id}`}
                           style={{
                             // padding: '0px 10px 0px 10px', 
                             height: '115px'
                           }}
                         >
                           {
                             data.HasFiles === true ?
                               <div className='col-lg-12 candupl-box-outline'>
                                 <div className='col-sm-5 col-xs-5 col-lg-4 col-md-5'>
                                   {data.Files[0].DocName.split('.').pop() === 'pdf' ? // NOSONAR
                                     <div className='image-view-pdf'>
                                       <a href={fileurl} download target="_blank" rel="noopener noreferrer">
                                         <i className="fas fa-file-pdf" title='Preview file' style={{ fontSize: 54, color: 'red' }}></i>
                                       </a>
                                     </div>
                                     :
                                     <a href={fileurl} download target="_blank" rel="noopener noreferrer">
                                       <img id='tes' title='Preview file' className='image-view' src={fileurl} alt='' />
                                     </a>
                                   }
                                 </div>
                                 <div className='col-sm-4 col-xs-4 col-lg-5 col-md-4'>
                                   <div className='image-name-box'>
                                     <div className='image-name' >
                                       {data.Files[0].DocName}
                                     </div>
                                     <div className='image-size'>
                                       {
                                         data.FileSize === undefined ? // NOSONAR
                                           0
                                           :
                                           parseInt(parseInt(data.FileSize, 10) / 1024, 10)
                                       }&nbsp;Kb
                                  </div>
                                   </div>
                                 </div>
                                 <div className='col-sm-3 col-xs-3 col-lg-3 col-md-3'>
                                   <div className='cancel-icon row'>
                                     {
                                       data.Rejected === "Y" &&
                                       <div className='upload-icon-reject'
                                         title='File upload'
                                         style={{ cursor: 'pointer' }}
                                         onClick={() => {
                                           this.openFileDialog(data.FolderPath, data.Id, index, 'other')
                                         }}
                                       >
                                         <input
                                           id={`input${index}${data.Id}`}
                                           ref={this.fileInputRef}
                                           className="FileInput"
                                           style={{ display: "none" }}
                                           type="file"
                                           accept="application/pdf"
                                          //  accept="image/jpeg,image/png,image/jpg,application/pdf"
                                           onChange={this.onFilesAdded}
                                         />
                                       </div>
                                     }
                                     {
                                       data.Rejected === "Y" &&
                                       <i className="far fa-times-circle custom-reject-style pl-xlg"
                                         title="File is rejected please re-upload">
                                       </i>
                                     }
                                     {
                                       data.Approved === "" && data.Rejected === "" &&
                                       <i className="fas fa-times custom-close-style pl-xlg"
                                         title='Cancel file'
                                         onClick={() => {
                                           let temp = content;
                                           temp[index].HasFiles = false
                                           this.setState({ content: temp })
                                           const fileData = {
                                             authUser:0,
                                             docid: temp[index].Files[0].DocId,
                                             status: "D",
                                           };
                                           this.props.updateDocumentStatus(fileData);
                                           this.setState({ content: temp })
                                         }}></i>
                                     }
                                     {
                                       data.Approved === "Y" &&
                                       <i className="far fa-check-circle custom-approve-style pl-xlg"
                                         title='Approved'
                                       ></i>
                                     }
                                   </div>
                                 </div>
                               </div>
                               :
                               <div className='candupl-box-outline'>
                                 <center>
                                   <input
                                     id={`input${index}${data.Id}`}
                                     ref={this.fileInputRef}
                                     className="FileInput"
                                     style={{ display: "none" }}
                                     type="file"
                                     accept="application/pdf"
                                    //  accept="image/jpeg,image/png,image/jpg,application/pdf"
                                     onChange={this.onFilesAdded}
                                   />
                                   <div className='upload-icon'
                                     onClick={() => {
                                       this.openFileDialog(data.FolderPath, data.Id, index, 'other')
                                     }}
                                     style={{ cursor: "pointer" }}
                                   />
                                 </center>
                                 <div className='candupl-box-outline-headertext'>{data.DocumenytType}</div>
                               </div>
                           }
                           <div className='col-sm-12 col-xs-12 col-lg-12 col-md-12'>
                             {
                              //  data.HasFiles === true && 
                               data.Rejected === 'Y' ?
                               <>
                                 <div className='col-sm-11 col-xs-11 col-lg-11 col-md-11 candupl-reject-message'>
                                 Document not approved. Please re-upload.
                                </div>
                                   <div className='col-sm-1 col-xs-1 col-lg-1 col-md-1 candupl-add-priview'>
                                     <span className=''>
                                       {data.HasFiles === false && (data.Rejected === 'Y') &&

                                         <FontAwesomeIcon
                                           icon={faEye}
                                           title='Preview comments'
                                           onClick={() => { // NOSONAR
                                             this.categoryName = data.DocumenytType;
                                             this.showComments = true;
                                             this.selectedFolderId = data.Files[0].DocId
                                             setTimeout(() => {
                                               this.setState({ openDocumentComments: true, contentIndex: index })
                                             }, 500)
                                             this.props.updateDocumentInfo({ openDocumentComments: true })
                                           }}
                                         />
                                       }
                                     </span>
                                   </div>
                              </>
                                 :
                                 <div className='col-sm-10 col-xs-10 col-lg-10 col-md-10 candupl-add-commet'
                                   onClick={() => { // NOSONAR
                                     if (data.HasFiles === true) {
                                       this.categoryName = data.DocumenytType;
                                       this.showComments = true;
                                       this.selectedFolderId = data.Files[0].DocId
                                       setTimeout(() => {
                                         this.setState({ openDocumentComments: true, contentIndex: index })
                                       }, 500)
                                       this.props.updateDocumentInfo({ openDocumentComments: true })
                                     }
                                   }}
                                 >
 
                                   {
                                     data.DocComments === "Y" ? // NOSONAR
                                       <span>
                                         Comments
                                         &nbsp;
                                      <FontAwesomeIcon
                                           icon={faEye}
                                           style={{ fontSize: 14 }}
                                           title='Preview comments'
                                           onClick={() => { // NOSONAR
                                             this.categoryName = data.DocumenytType;
                                             this.showComments = true;
                                             this.selectedFolderId = data.Files[0].DocId
                                             setTimeout(() => {
                                               this.setState({ openDocumentComments: true, contentIndex: index })
                                             }, 500)
                                             this.props.updateDocumentInfo({ openDocumentComments: true })
                                           }}
                                         />
                                       </span>
                                       :
                                       data.HasFiles === true ? // NOSONAR
                                         "Add Comments"
                                         :
                                         ''
                                   }
 
 
                                 </div>
                             }
 
                             <div className='col-sm-1 col-xs-1 col-lg-1 col-md-1 candupl-add-priview'>
                               <span className=''>
                                 {data.HasFiles === true && (data.Rejected === 'Y') &&
 
                                   <FontAwesomeIcon
                                     icon={faEye}
                                     title='Preview comments'
                                     onClick={() => { // NOSONAR
                                       this.categoryName = data.DocumenytType;
                                       this.showComments = true;
                                       this.selectedFolderId = data.Files[0].DocId
                                       setTimeout(() => {
                                         this.setState({ openDocumentComments: true, contentIndex: index })
                                       }, 500)
                                       this.props.updateDocumentInfo({ openDocumentComments: true })
                                     }}
                                   />
                                 }
                               </span>
                             </div>
                           </div>
                         </div>
                       )
                     })
                 }
               </div>
               :
               <div style={{ height: 150 }}>
                 <ProgressBar />
               </div>
           }
           {openDocumentComments && (
             <Comments
               categoryName={this.categoryName}
               selectedFolderId={this.selectedFolderId}
               categoryId={id}
               candidateType={candidateType}
               showComments={this.showComments}
               commentFor={'1'}
             />
           )}
         </div>
       </div>
     );
   }
 }
 const mapStateToProps = ({
   onboardingreducer,
 }) => {
   const {
     openDocumentComments,
     refreshNewDoc
   } = onboardingreducer;
   return {
     openDocumentComments,
     refreshNewDoc
   };
 };
 
 NewDocuments.propTypes = {
   // statusUpdate: PropTypes.func,
   id: PropTypes.number,
   refreshNewDoc: PropTypes.string,
   candidateType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
   changeStatus: PropTypes.bool,
   openDocumentComments: PropTypes.bool,
   content: PropTypes.arrayOf(PropTypes.object).isRequired,
   header: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
   candidateid: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
 };
 NewDocuments.defaultProps = {
   // statusUpdate: {},
   id: 0,
   refreshNewDoc: "",
   changeStatus: false,
   candidateid: 0,
   openDocumentComments: false
 };
 
 
 export default connect(mapStateToProps, { updateDocumentInfo, updateDocumentStatus })(NewDocuments);
 
