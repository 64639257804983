import { MEETING_MASTER,MEETING_MASTER_UPDATE } from "../constant/meetingmasterconstant";
// eslint-disable-next-line
export const setMeetingMaster = (data) => {
  return {
    type: MEETING_MASTER,
    payload: data,
  };
};

export const setMeetingMasterUpdate = (data) => {
  return {
    type: MEETING_MASTER_UPDATE,
    payload: data,
  };
};
