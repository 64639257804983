import * as React from 'react';
import { enableRipple } from '@syncfusion/ej2-base';
enableRipple(true);

export class MainClass extends React.PureComponent {
    rendereComplete() { // NOSONAR        
    }
    componentDidMount() {
        setTimeout(() => {
            this.rendereComplete();
        });
    }
}
