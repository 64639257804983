import React, { Component } from "react";
import { connect } from "react-redux";
import { FormValidator } from "@syncfusion/ej2-inputs";
import {
  pleaseEnterSubBusinessUnitFunctionName,
  pleaseEnterPracticeArea,
  pleaseEnterSubLayerName,
  pleaseSetHierarchy,
  pleaseEnterLayerName,
  pleaseSetParentLayer,
  EnterbusinessFunctionUnit,
  SelectHierarchy,
  EnterCompanyName,
  EnterAbbrevation,
  EnterDomainName,
  EnterLayerMasterName,
  EnterDigitalDomainName,
  EnterTeamsDomainName,
  EnterSubLayerName,
  EnterFolder,
  ValidDomainName,
  EnterRole,
  pleaseselectsubpnlhead,
  selectPnlHead,
  setserviceareahead,
  EnterSpecialCharacter,
  pleaseEnterFolderName,
  pleaseSelectNoticePeriod,
  ParentRequired,
} from "../../../assets/constants/constants";
import BusinessUnitFunctionPopup from "./orgchartadminpopup/businessunitfunctionpopup";
import LayerMasterPopup from "./orgchartadminpopup/layermasterpopup";
import SubLayerMasterPopup from "./orgchartadminpopup/sublayermasterpopup";

import SubBusinessUnitFunctionPopup from "./orgchartadminpopup/subbusinessunitfunctionpopup";
import PracticeAreaPopup from "./orgchartadminpopup/practiceareapopup";

import LayerMappingPopup from "./orgchartadminpopup/layermappingpopup";
import UserRoleMasterPopup from "./orgchartadminpopup/userrolepopup";
import SubLayerMappingPopup from "./orgchartadminpopup/sublayermappingpopup";
import { Button, Dialog, DialogTitle } from "@material-ui/core";
import { DialogContent, DialogActions } from "../../customdialog/customdialog";
import {
  addEditPracticeArea,
  addEditSubBusinessUnitFunction,
  addEditLayerMappingFunction,
  addEditLayerSubMappingFunction,
  saveBusinessUnitFunction,
  saveLayerMasterFunction,
  saveSubLayerMasterFunction,
  saveUserRoleMasterFunction,
  saveFolderManageFunction,
} from "../../../redux/actions/admin/adminaction";

import FolderManagerPopup from "./orgchartadminpopup/foldermanagepopup";

class Orgchartpopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
    };
    this.childCompData = null;
    this.formValidationData = null;
  }

  onPracticeAreaFormValidations = (data, formId) => {
    let options;

    data && data.clearenceDept === "1"
      ? (options = {
        // NOSONAR
        rules: {
          practiceAreaErr: {
            required: [true, pleaseEnterPracticeArea],
          },
          serviceHeadErr: {
            required: [true, setserviceareahead],
          },
          hierarchyErr: {
            required: [true, pleaseSetHierarchy],
          },
          usertypeErr: {
            required: [true, "Please select user type."],
          },
          clearenceUserErr: {
            required: [true, "Please select clearance user."],
          },
          ...(data.isParent === "0" &&
            !(data?.parent > 0) && {
            parentErr: {
              required: [true, ParentRequired],
            },
          }),
        },
      })
      : (options = {
        // NOSONAR
        rules: {
          practiceAreaErr: {
            required: [true, pleaseEnterPracticeArea],
          },
          serviceHeadErr: {
            required: [true, setserviceareahead],
          },
          hierarchyErr: {
            required: [true, pleaseSetHierarchy],
          },
          usertypeErr: {
            required: [true, "Please select user type."],
          },
          ...(data.isParent === "0" &&
            !(data?.parent > 1) && {
            parentErr: {
              required: [true, ParentRequired],
            },
          }),

        },
      });
    setTimeout(() => {
      const addFormObject = new FormValidator(formId, options);
      if (addFormObject.validate()) {
        // Assuming you want to remove the key 'keyToRemove'
        const { isParent, ...newData } = data;

        // Now newData is a new object without the keyToRemove
        this.props.addEditPracticeArea(newData);
      } else {
        // call getNotify function.
        return;
      }
    }, 100);
  };

  onSubBusinessUnitFunctionFormValidations = (data, formId) => {
    const options = {
      rules: {
        subBusinessUnitErr: {
          required: [true, pleaseEnterSubBusinessUnitFunctionName],
        },

        subPnlHeadErr: {
          required: [true, pleaseselectsubpnlhead],
        },

        hierarchyErr: {
          required: [true, pleaseSetHierarchy],
        },
        ...(data.parent === '0' && {
          isParentErr: {
            required: [true, ParentRequired],
          },
        }),
      },
    };
    setTimeout(() => {
      const addFormObject = new FormValidator(formId, options);
      if (addFormObject.validate()) {
        this.props.addEditSubBusinessUnitFunction(data);
      } else {
        // call getNotify function.
        return;
      }
    }, 100);
  };

  onLayerMappingFormValidations = (data, formId) => {
    const options = {
      rules: {
        layerErr: {
          required: [true, pleaseEnterLayerName],
        },
        hierarchyErr: {
          required: [true, pleaseSetHierarchy],
        },
      },
    };
    setTimeout(() => {
      const addFormObject = new FormValidator(formId, options);
      if (addFormObject.validate()) {
        this.props.addEditLayerMappingFunction(data);
      } else {
        return;
      }
    }, 100);
  };

  onSubLayerMappingFormValidations = (data, formId) => {
    const options = {
      rules: {
        sublayerErr: {
          required: [true, pleaseEnterSubLayerName],
        },
        hierarchyErr: {
          required: [true, pleaseSetHierarchy],
        },
        noticePeriodErr: {
          required: [true, pleaseSelectNoticePeriod],
        },
      },
    };
    setTimeout(() => {
      const addFormObject = new FormValidator(formId, options);
      if (addFormObject && addFormObject.validate()) {
        this.props.addEditLayerSubMappingFunction(data);
      } else {
        // call getNotify function.
        return;
      }
    }, 100);
  };

  onSavePracticeArea = (savData, authUser) => {
    if (this.props.type === "Add") {
      
      let data;
      if (savData !== null) {
        data = {
          authUser: authUser,
          type: "Add",
          practiceAreaId: 0,
          practiceAreaName: savData.practiceAreaName.trim(),
          subBusinesUnitFunctionId: savData.businessSubFunctionUnitId,
          isActive: "1",
          hierarchy: parseInt(savData.hierarchy),
          clearenceDept: savData.clearenceDept,
          clearenceUid: parseInt(savData.clearenceUid),
          deptEmail: savData.deptEmail === "" ? null : savData.deptEmail,
          isActingHead: savData.isActingHead,
          serviceDivHead: savData.serviceDivHead,
          userType: savData.userType,
          parent: savData.parent,
          isParent: savData.isParent,
        };
      }

      this.onPracticeAreaFormValidations(data, "#practiceAreaForm");
    } else {
      let data = {
        authUser: authUser,
        type: this.props.type,
        practiceAreaId: savData.practiceAreaId,
        practiceAreaName: savData.practiceAreaName.trim(),
        subBusinesUnitFunctionId: savData.businessSubFunctionUnitId,
        isActive: "1",
        hierarchy: parseInt(savData.hierarchy, 10),
        clearenceDept: savData.clearenceDept,
        clearenceUid: parseInt(savData.clearenceUid, 10),
        deptEmail: savData.deptEmail === "" ? null : savData.deptEmail,
        isActingHead: savData.isActingHead,
        serviceDivHead: savData.serviceDivHead,
        userType: savData.userType,
        parent: savData.parent,
        isParent: savData.isParent,
      };
      this.onPracticeAreaFormValidations(data, "#practiceAreaForm");
    }
  };
  onSaveSubBusinessUnitFunction = (savData, authUser) => {
    const { Id } = this.props.subBusinessUnitFunctionData;
    if (this.props.type === "Add") {
      let data = {
        authUser: authUser,
        type: "Add",
        businessUnitFunctionId: Id,
        subBusinessUnitFunctionName: savData.subBusinessUnitFunctionName.trim(),
        subBusinessUnitFunctionId: 0,
        isActive: "1",
        hierarchy: parseInt(savData.hierarchy, 10),
        isActingHead: savData.isActingHead,
        subPnlFuncHead: parseInt(savData.subPnlFuncHead, 10),
        parent: savData.parent ? parseInt(savData.parent, 10) : '0'

      };

      this.onSubBusinessUnitFunctionFormValidations(
        data,
        "#subBusinessUnitFunctionForm"
      );
    } else {
      let data = {
        authUser: authUser,
        type: this.props.type,
        businessUnitFunctionId: Id,
        subBusinessUnitFunctionName: savData.subBusinessUnitFunctionName.trim(),
        subBusinessUnitFunctionId: savData.subBusinessUnitFunctionId,
        isActive: "1",
        hierarchy: parseInt(savData.hierarchy, 10),
        isActingHead: savData.isActingHead,
        subPnlFuncHead: parseInt(savData.subPnlFuncHead, 10),
        parent: savData.parent ? parseInt(savData.parent, 10) : '0'
      };

      this.onSubBusinessUnitFunctionFormValidations(
        data,
        "#subBusinessUnitFunctionForm"
      );
    }
  };

  onSaveLayerMapping = (savData, authUser) => {
    if (this.props.type === "Add") {
      let data = {
        authUser: authUser,
        type: "Add",
        practiceAreaId: savData.practiceAreaId,
        layerId: savData.layerId,
        layerMappingId: 0,
        isActive: "1",
        parentLayerId: savData.parentLayerId,
        hierarchy: parseInt(savData.hierarchy, 10),
      };
      this.onLayerMappingFormValidations(data, "#layerMappingForm");
    } else {
      const { layerMappingId } = this.props.layerMappingData;
      let data = {
        authUser: authUser,
        type: "Edit",
        practiceAreaId: savData.practiceAreaId,
        layerId: savData.layerId,
        layerMappingId: layerMappingId,
        isActive: "1",
        parentLayerId: savData.parentLayerId,
        hierarchy: parseInt(savData.hierarchy, 10),
      };

      this.onLayerMappingFormValidations(data, "#layerMappingForm");
    }
  };

  onSaveSubLayerMapping = (savData, authUser) => {
    if (this.props.type === "Add") {
      let data = {
        authUser: authUser,
        type: "Add",
        layerMappingId: parseInt(savData.layerMappingId, 10),
        subLayerId: savData.subLayerId,
        subLayerMappingId: 0,
        isActive: "1",
        hierarchy: parseInt(savData.hierarchy, 10),
        noticePeriod: savData.noticePeriod,
      };

      this.onSubLayerMappingFormValidations(data, "#sublayerMappingForm");
    } else {
      const { subLayerMappingId } = this.props.subLayerMappingData;
      let data = {
        authUser: authUser,
        type: "Edit",
        layerMappingId: parseInt(savData.layerMappingId, 10),
        subLayerId: savData.subLayerId,
        subLayerMappingId: subLayerMappingId,
        isActive: "1",
        hierarchy: parseInt(savData.hierarchy, 10),
        noticePeriod: savData.noticePeriod,
      };

      this.onSubLayerMappingFormValidations(data, "#sublayerMappingForm");
    }
  };
  onSave = (component) => {
    try {
      const { authUser, type } = this.props;
      const savData = this.childCompData;

      if (component === "PracticeArea") {
        this.onSavePracticeArea(savData, authUser);
      }
      if (component === "SubBusinessUnit") {
        this.onSaveSubBusinessUnitFunction(savData, authUser);
      }

      if (component === "LayerMapping") {
        this.onSaveLayerMapping(savData, authUser);
      }

      if (component === "SubLayerMapping") {
        this.onSaveSubLayerMapping(savData, authUser);
      }

      this.saveBusinessUnitFunction(component, authUser, savData, type);
      this.saveLayerMasterFunction(component, authUser, savData, type);
      this.saveUserRoleMasterFunction(component, authUser, savData, type);

      this.saveSubLayerMasterFunction(component, authUser, savData, type);
      this.saveFolderManageFunction(component, authUser, savData, type);
    } catch (error) {
      console.log(error);
    }
  };

  //  function to get data from child component
  getChildComponentData = (data) => {
    this.childCompData = data;
  };

  onCancel = () => {
    this.childCompData = null;

    this.props.closePopup();
  };

  saveBusinessUnitFunction(component, authUser, savData, type) {
    if (component === "BusinessUnitFunction") {
      let options = {
        rules: {
          businessUnitFunctionErr: {
            required: [true, EnterbusinessFunctionUnit],
          },
          pnlHeadErr: {
            required: [true, selectPnlHead],
          },
          HierarchyErr: {
            required: [true, SelectHierarchy],
          },
          CompanynameErr: {
            required: [true, EnterCompanyName],
          },
          AbbrevationErr: {
            required: [true, EnterAbbrevation],
            regex: [/^[a-z]{2}$/i, "Please enter 2 characters only."],
          },
          DomainErr: {
            required: [true, EnterDomainName],
            // eslint-disable-next-line
            regex: [
              /^((?:(?:(?:\w[\.\-\+]?)*)\w)+)((?:(?:(?:\w[\.\-\+]?){0,62})\w)+)\.(\w{2,6})$/,
              ValidDomainName,
            ],
          },
          DigitalEmailErr: {
            required: [true, EnterDigitalDomainName],
            // eslint-disable-next-line
            regex: [
              /^((?:(?:(?:\w[\.\-\+]?)*)\w)+)((?:(?:(?:\w[\.\-\+]?){0,62})\w)+)\.(\w{2,6})$/,
              ValidDomainName,
            ],
          },
          TeamsEmailErr: {
            required: [true, EnterTeamsDomainName],
            // eslint-disable-next-line
            regex: [
              /^((?:(?:(?:\w[\.\-\+]?)*)\w)+)((?:(?:(?:\w[\.\-\+]?){0,62})\w)+)\.(\w{2,6})$/,
              ValidDomainName,
            ],
          },
          ...(savData.isParent === '1' && {
            parentErr: {
              required: [true, ParentRequired],
            },
          }),
        },
      };

      setTimeout(() => {
        const addFormObject = new FormValidator(
          "#businessUnitFunctionForm",
          options
        );
        if (addFormObject.validate()) {
          const data = {
            authUser: authUser,
            businessUnitFunctionId: savData.businessUnitFunctionId,
            type: type,
            businessUnitFunctionName: savData.businessUnitFunctionName.trim(),
            hierarchy: savData.hierarchy,
            companyName: savData.companyname.trim(),
            abbreviation: savData.abbrevation,
            isActive: "1",
            domainName: savData.domain,
            digitalEmail: savData.DigitalEmail,
            teamsEmail: savData.TeamsEmail,
            isActingHead: savData.IsActingHead,
            logo: savData.logo,
            color: savData.color,
            pnlFuncHead: savData.pnlFuncHead,
            parent: savData.parent,
          };
          this.props.saveBusinessUnitFunction(data);
        }
      }, 100);
    }
  }

  saveLayerMasterFunction(component, authUser, savData, type) {
    if (component === "LayerMaster") {
      const options = {
        rules: {
          layerMasterFunctionErr: {
            required: [true, EnterLayerMasterName],
          },
        },
      };

      setTimeout(() => {
        const addFormObject = new FormValidator("#layermasterForm", options);
        if (addFormObject.validate()) {
          const data = {
            authUser: authUser,
            type: type,
            layerName: savData.layerName.trim(),
            isActive: "1",
            layerId: savData.layerId,
          };
          this.props.saveLayerMasterFunction(data);
        }
      }, 100);
    }
  }

  // Author : Amit kumar | 06 - 09 - 2022 | To save user role data

  saveUserRoleMasterFunction(component, authUser, savData, type) {
    if (component === "UserRoleMaster") {
      const options = {
        rules: {
          userRoleFunctionErr: {
            required: [true, EnterRole],
          },
        },
      };

      setTimeout(async () => {
        const addFormObject = new FormValidator("#userRoleForm", options);
        if (addFormObject.validate()) {
          const data = {
            authUser: authUser,
            type: type,
            role: savData.userRole.trim(),
            isActive: "1",
            roleId: savData.roleId,
          };
          try {
            const result = await this.props.saveUserRoleMasterFunction(data);
            if (result) {
              this.props.closePopup();
            }
          } catch (error) {
            console.error("Error saving data:", error);
          }
        }
      }, 100);
    }
  }

  saveSubLayerMasterFunction(component, authUser, savData, type) {
    if (component === "SubLayerMaster") {
      const options = {
        rules: {
          subLayerNameErr: {
            required: [true, EnterSubLayerName],
          },
        },
      };
      setTimeout(() => {
        const addFormObject = new FormValidator("#subLayerMasterForm", options);
        if (addFormObject.validate()) {
          const data = {
            authUser: authUser,
            type: type,
            isActive: "1",
            subLayerName: savData.subLayerName.trim(),
            subLayerId: savData.subLayerId,
          };
          this.props.saveSubLayerMasterFunction(data);
        }
      }, 100);
    }
  }

  // To Add New Folder In Folder Management
  saveFolderManageFunction(component, authUser, savData, type) {
    if (component === "FolderManagement") {
      const options = {
        rules: {
          folderName: {
            required: [true, EnterFolder],
            // eslint-disable-next-line
            // regex: [/^([\/:*".?<>|])$/,]

            regex: [
              /^[^*|\/":<>[\]{}`\\()';!^#%@&$]+$/,
              "Please enter valid folder name and special char not allowed",
            ],
          },
        },
      };
      setTimeout(() => {
        const addFormObject = new FormValidator("#folderForm", options);
        if (addFormObject.validate()) {
          const data = {
            authUser: authUser,
            type: type,
            isActive: "1",
            folderName: savData.folderName.trim(),
            folderId: savData.folderId,
            parentFolderId: savData.parentFolderId,
            hierarchy: savData.hierarchy,
          };
          this.props.saveFolderManageFunction(data);
        }
      }, 100);
    }
  }

  render() {
    const {
      component,
      title,
      type,
      maxHierarchy,
      hierarchy,
      businessUnitFunctionAreaData,
      layerMasterAreaData,
      subLayerMasterAreaData,
      userRoleData,
      othersCheckAvailablity,
      newFolderData,
      noticePeriod,
      result,
    } = this.props;
    let practiceAreaId, businessSubFunctionUnitId, businessFunctionUnitId;

    if (component === "PracticeArea" && this.props.practiceAreaData) {
      // Check if the properties are available before destructuring
      const {
        practiceAreaId: paId,
        businessSubFunctionUnitId: bsId,
        businessFunctionUnitId: bfId,
      } = this.props.practiceAreaData;

      // Only assign the values if they are available
      if (paId !== undefined && bsId !== undefined && bfId !== undefined) {
        practiceAreaId = paId;
        businessSubFunctionUnitId = bsId;
        businessFunctionUnitId = bfId;
      }
    }

    let isFullWidth = true;
    if (
      component === "SubLayerMaster" ||
      component === "LayerMaster" ||
      component === "UserRoleMaster" ||
      component === "FolderManagement"
    )
      isFullWidth = false;
    return (
      <div>
        <Dialog
          open={true}
          fullWidth={isFullWidth}
          maxWidth="sm"
          onEscapeKeyDown={() => this.onCancel()}
        >
          <DialogTitle>
            <span
              className="add-new-self-header"
            // style={{ margin: "0px 15px" }}
            >
              {type === "Add" ? `Add ${title}` : `Edit ${title}`}{" "}
            </span>
          </DialogTitle>
          <DialogContent
          // style={{ margin: "0px 22px" }}
          >
            {component === "SubBusinessUnit" && (
              <SubBusinessUnitFunctionPopup
                parentComponentfunction={this.getChildComponentData}
                subBusinessUnitFunctionData={
                  this.props.subBusinessUnitFunctionData
                }
                maxHierarchy={maxHierarchy}
                othersCheckAvailablity={othersCheckAvailablity}
                subBusinessFunctionMasterData={
                  this.props.subBusinessFunctionMasterData
                }
              />
            )}
            {component === "PracticeArea" && (
              <PracticeAreaPopup
                parentComponentfunction={this.getChildComponentData}
                practiceAreaData={this.props.practiceAreaData}
                maxHierarchy={maxHierarchy}
                othersCheckAvailablity={othersCheckAvailablity}
                practiceAreaMasterData={this.props.practiceAreaMasterData}
                parents={result
                  .filter(
                    (e) =>
                      e.practiceAreaId !== 0 &&
                      e.practiceAreaId !== practiceAreaId &&
                      e.businessSubFunctionUnitId ===
                      businessSubFunctionUnitId &&
                      e.businessFunctionUnitId === businessFunctionUnitId
                  )
                  .map((e) => ({ Id: e.practiceAreaId, Name: e.practiceArea }))}
                type={this.props.type}
              />
            )}
            {component === "LayerMapping" && (
              <LayerMappingPopup
                parentComponentfunction={this.getChildComponentData}
                layerMappingData={this.props.layerMappingData}
                maxHierarchy={maxHierarchy}
                layerMappingMasterData={this.props.layerMappingMasterData}
                layerMappingParentData={this.props.layerMappingParentData}
              />
            )}
            {component === "SubLayerMapping" && (
              <SubLayerMappingPopup
                parentComponentfunction={this.getChildComponentData}
                subLayerMappingData={this.props.subLayerMappingData}
                maxHierarchy={maxHierarchy}
                noticePeriod={noticePeriod}
                subLayerMappingMasterData={this.props.subLayerMappingMasterData}
              />
            )}

            {component === "BusinessUnitFunction" && (
              <BusinessUnitFunctionPopup
                parentClassFunction={this.getChildComponentData}
                othersCheckAvailablity={othersCheckAvailablity}
                businessUnitFunctionAreaData={businessUnitFunctionAreaData}
                type={type}
                maxHierarchy={maxHierarchy}
                parents={result
                  .filter((e) => e.Id !== businessUnitFunctionAreaData.Id)
                  .map((e) => ({ Id: e.Id, Name: e.businessUnitFunction }))}
                authUser={this.props.authUser}
              />
            )}

            {component === "SubLayerMaster" && (
              <SubLayerMasterPopup
                parentClassFunction={this.getChildComponentData}
                type={type}
                subLayerMasterAreaData={subLayerMasterAreaData}
              />
            )}

            {component === "LayerMaster" && (
              <LayerMasterPopup
                parentClassFunction={this.getChildComponentData}
                layerMasterAreaData={layerMasterAreaData}
                type={type}
              />
            )}

            {component === "UserRoleMaster" && (
              <UserRoleMasterPopup
                parentClassFunction={this.getChildComponentData}
                userRoleData={userRoleData}
                type={type}
              />
            )}

            {component === "FolderManagement" && (
              <FolderManagerPopup
                parentClassFunction={this.getChildComponentData}
                newFolderData={newFolderData}
                type={type}
                hierarchy={hierarchy}
              />
            )}
          </DialogContent>
          <DialogActions>
            <div style={{ float: "right", padding: "0px 23px 16px 0px" }}>
              <Button
                style={{ width: "120px" }}
                className="cancel-button"
                variant="contained"
                onClick={() => this.onCancel()}
              >
                Cancel
              </Button>
              <Button
                style={{ width: "120px" }}
                className="remove-button-shadow"
                color="primary"
                variant="contained"
                onClick={() => this.onSave(component)}
              >
                Save
              </Button>
            </div>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = ({ auth, meetingmaster }) => {
  const { authUser } = auth;
  const { othersCheckAvailablity } = meetingmaster;
  return { authUser, othersCheckAvailablity };
};

export default connect(mapStateToProps, {
  addEditPracticeArea,
  addEditSubBusinessUnitFunction,
  addEditLayerMappingFunction,
  addEditLayerSubMappingFunction,
  saveBusinessUnitFunction,
  saveLayerMasterFunction,
  saveSubLayerMasterFunction,
  saveUserRoleMasterFunction,
  saveFolderManageFunction,
})(Orgchartpopup);
