/**
 * @author: Vivek Khodade.
 * @description This use for okr action.
 * Created: 24-11-2020
 */

import {
  UPDATE_USER_PRODUCTIVE_ALLOCATION,
  SET_COST_SHARING_REDUCER,
  ADD_EDIT_COST_SHARING_NEWREQUEST,
  ACCEPT_REJECT_REQUEST, ADD_UPDATE_OKR_OBJETVE_KPI,
  ADD_EDIT_OKR_OBJETVE_KPI,
  APPROVE_REJECT_OKR_OBJETVE_KPI,
  SET_CENTRAL_OKR_KPI,
  SET_USER_OKR_OBJECTIVE_KPI,
  SET_OKR_USER_KPI,
  CREATE_UPDATE_NEW_OBJ,
  CREATE_UPDATE_NEW_KPI,
  SUBMIT_APPROVAL_ACTION,
  FILL_OBJECTIVE_DETAILS,
  FILL_KPI_DETAILS,
  UPDATE_LP_VARIABLE_LINK,
  UPDATE_OKR_LOCK_STATUS,
  UPDATE_OKR_ORDER_IDS,
  SAVE_OKR_BLOCKER_MASTER,
  UPDATE_OKR_REDUX,
  SAVE_OKR_RATING_SYSTEM,
  ADMIN_EDIT_ADD_CAL,
  UPDATE_OKR_MASTER_ITEM_STATUS,
  SAVE_OKR_RATING_MASTER,
  SAVE_AXIS_CUT_OFF,
  SAVE_OKR_PERFORMANCE_POTENTIAL_MASTER,
  SAVE_OKR_FREQUENCY_MASTER,
  SAVE_SUB_POTENTIAL_CALCULATION,
  SAVE_OKR_AXIS_MASTER,
  SAVE_OKR_POTENTIAL_CALCULATION_MASTER,
  TEAM_MEMBER_SELECTION,
  COMMON_MASTER_SCREEN_LOADING
} from "../../constant/okr/okrconstant";

export const updateUserProductiveAllcation = (data) => {
  return {
    type: UPDATE_USER_PRODUCTIVE_ALLOCATION,
    payload: data,
	  
  };
};
export const setCostSharingDetails = (data) => {
  return {
    type: SET_COST_SHARING_REDUCER,
    payload: data,
	  
  };
};
export const addEditCostsharingNewRequest = (data) => {
  return {
    type: ADD_EDIT_COST_SHARING_NEWREQUEST,
    payload: data.data,
	  
  };
};
export const appceptRevokeRequest = (data) => {
  return {
    type: ACCEPT_REJECT_REQUEST,
    payload: data,
	  
  };
};
export const addUpdateObjectveKpi = (data) => {
  return {
    type: ADD_UPDATE_OKR_OBJETVE_KPI,
    payload: data,
	  
  };
};
export const addEditObjectveKpi = (data) => {

  return {
    type: ADD_EDIT_OKR_OBJETVE_KPI,
    payload: data.data,
	  
  };
};
export const approveRejectObjectveKpi = (data) => {
  return {
    type: APPROVE_REJECT_OKR_OBJETVE_KPI,
    payload: data,
	  
  };
};
export const setCentralOkrKPIRedux = (data) => {
  return {
    type: SET_CENTRAL_OKR_KPI,
    payload: data,
	  
  };
};
export const setUserOKRObjectiveKPI = (data) => {
  return {
    type: SET_USER_OKR_OBJECTIVE_KPI,
    payload: data,
	  
  };
};
export const setOKRUserKPI = (data) => {
  return {
    type: SET_OKR_USER_KPI,
    payload: data,
	  
  };
};
export const addUpdateNewObj = (data) => {
  return {
    type: CREATE_UPDATE_NEW_OBJ,
    payload: data,
	  
  };
};
export const addUpdateNewKPI = (data) => {
  return {
    type: CREATE_UPDATE_NEW_KPI,
    payload: data,
	  
  };
};
export const submitApprovalAction = (data) => {
  return {
    type: SUBMIT_APPROVAL_ACTION,
    payload: data,
	  
  };
};
export const fillObjectiveRequest = (data) => {
  return {
    type: FILL_OBJECTIVE_DETAILS,
    payload: data,
	  
  };
};
export const fillKpiRequest = (data) => {
  return {
    type: FILL_KPI_DETAILS,
    payload: data,
	  
  };
};
export const updateLPVariableLink = (data) => {
  return {
    type: UPDATE_LP_VARIABLE_LINK,
    payload: data,
	  
  };
};
export const updateOKRLockTMRatingMonth = (data) => {
  return {
    type: UPDATE_OKR_LOCK_STATUS,
    payload: data,
	  
  };
};
export const updateOKROrdersIds = (data) => {
  return {
    type: UPDATE_OKR_ORDER_IDS,
    payload: data,
	  
  };
};

/**
* Author: Sanjeev. | 13-01-2023
*/

export const updateOKRData = (data) => {
  return {
    type: UPDATE_OKR_REDUX,
    payload: data
  }
}

export const saveOKRBlockerMaster = (data) => {
  return {
    type: SAVE_OKR_BLOCKER_MASTER,
    payload: data
  }
}

export const saveRatingSystemData = (data) => {
  return {
    type: SAVE_OKR_RATING_SYSTEM,
    payload: data
  }
}

export const saveRatingMaster = (data) => {
  return {
    type: SAVE_OKR_RATING_MASTER,
    payload: data
  }
}

export const saveAxisCutOff = (data) => {
  return {
    type: SAVE_AXIS_CUT_OFF,
    payload: data
  }
}
export const savePerformancePotentialMaster = (data) => {
  return {
    type: SAVE_OKR_PERFORMANCE_POTENTIAL_MASTER,
    payload: data
  }
}

export const savePotentialCalculationMaster = (data) => {
  return {
    type: SAVE_OKR_POTENTIAL_CALCULATION_MASTER,
    payload: data
  }
}


export const saveFrequencyMaster = (data) => {
  return {
    type: SAVE_OKR_FREQUENCY_MASTER,
    payload: data
  }
}



export const updateOkrMasterItemStatus = (data) => {
  return {
    type: UPDATE_OKR_MASTER_ITEM_STATUS,
    payload: data
  }
}

export const saveSubPotentialCalculation = (data) => {
  return {
    type: SAVE_SUB_POTENTIAL_CALCULATION,
    payload: data
  }
}

export const saveAxisMaster = (data) => {
  return {
      type: SAVE_OKR_AXIS_MASTER,
      payload: data
  }
}


export const adminAddEditCalculation = (data) => {
  return {
      type: ADMIN_EDIT_ADD_CAL,
      payload: data
  }
}

export const setTeamMemberSelectionData = (data) => {
  return {
      type: TEAM_MEMBER_SELECTION,
      payload: data
  }
}
export const setCommonMasterLoading = (data) => {
  return {
      type: COMMON_MASTER_SCREEN_LOADING,
      payload: data
  }
}