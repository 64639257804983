/**
 * Author Name : Vinayak Jadhav
 * Created Date: 27 May 2020
 * Modified Date: - .
 * Last Modified Name: - .
 */
// Last Modified: - Sanket Bari

import * as React from "react";
import PropTypes from "prop-types";
import ReactDOM from 'react-dom';
import axios from "axios";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import TextField from "@material-ui/core/TextField";
import downloadCsv from "download-csv";
import "../../layout/tibrlayout/tibrlayout.css";
import Navigation from "./navigation";
import Recentactivity from "./recentactivity.js";
import Customprogressbar from "../customized-progressbar/progressbar";
import Uploadbutton from './uploadbutton';
import { connect } from "react-redux";
import { Button } from "@material-ui/core";
import { corsallow, urlConfig } from "../../../config/config";
import services from "../../../services/services";
import TibServices from "../../../services/tibservices";
import ProgressBar from "../../../components/progressbar/progressbar";
import { formatDateToYYYYMMDD, getDate, getDisplayDateFormat } from "../../other/commonfunction";
import { attendanceFileHeaders, attendanceFilePortionColumnData, maxiNoOfRowAcceptableInAttendanceFile, swapWeeklyOffFileHeaders, templateAttendanceFile, templateSwapWeekOffFile } from "../../../assets/constants/constants";
import ExportData from "./exportData";
import { exportColumns, monthlyColumns, swapWeeklyColumns } from "../../../assets/constants/tibrConstant";
import { DatePickerComponent } from "@syncfusion/ej2-react-calendars";

export class UploadDailyTib extends React.Component {
  constructor(props) {
    super(props);
    // maps the appropriate column to fields property
    this.state = {
      changedData: false,
      loader: false,
      autocorrectionBtn: false,
      exportFlag: false,
      exportDataId: 0,
      dailyView: false,
      monthlyView: true,
      autoView: false,
      prohanceDownloadView: false,
      startDate: new Date(),
      endDate: new Date(),
      dailyDate: null,
      maxAutoDate: true,
      prohanceDate: new Date(),
      descripancyData: [],
      swapweeklyoffview: false,
      heading: '',
      htmlContent: null,
      filename: '',
      headerData: null,
      activityData: null,
      attendanceCorrectionProcess: false,
      swapweeklyoffCorrection: false,
      autoCorrection: false,
      attendanceCorrTabFlag: false,
      swapCorrTabFlag: false,
      disbaleAttendanceCorrectionButton: false,
      disableSwapWeeklyOffButton: false,

    };
    this.prohanceDataFlag = true;
    this.reuploadFlag = false;
    this.attendanceFlag = false;
    this.prohanceFlag = false;
    this.startMonthlyProcessFlag = false;
    this.startSwapWeeklyOffDataProcessingFlag = false;
    this.startDailyDataProcessing = this.startDailyDataProcessing.bind(this);
    this.startMonthlyDataProcessing = this.startMonthlyDataProcessing.bind(this);
    this.startSwapWeeklyOffDataProcessing = this.startSwapWeeklyOffDataProcessing.bind(this);
    this.exportData = this.exportData.bind(this);
    this.errorHandler = this.errorHandler.bind(this);
    this.correctionFileName = "";
    this.processDateAtt = "";
    this.processDatePro = "";
    this.proceedFlag = true;
    this.proceedMonthlyFlag = true;
    this.exportDataInDateRainge = this.exportDataInDateRainge.bind(this);
    this.getUploadedTibDataLog = this.getUploadedTibDataLog.bind(this);
    this.getAutoUploadedTibDataLog = this.getAutoUploadedTibDataLog.bind(this);
    this.exportProhanceData = this.exportProhanceData.bind(this);
    this.uploadStatusRef = React.createRef();

  }

  async componentDidMount() {
    this.getUploadedTibDataLog('monthly');
  }

  setHeaderData = (tabId) => {
    // Update headerData state based on the selected tab

    let headerDatadata;
    if (tabId === 'autoRadio') {
      headerDatadata = (
        <>
          <div className="activity-column-header">Activity Type</div>
          <div className="activity-column-header">Activity Date</div>
          <div className="activity-column-header">Activity Status</div>

        </>
      );
    } else {
      headerDatadata = (
        <>
          {this.state.filename.length !== 0 && <div className="activity-column-header">File Name</div>}
          {this.state.filename.length !== 0 && <div className="activity-column-header">Uploaded Date</div>}
          {/* <div className="activity-column-header">Uploaded Date</div> */}
          <div className="activity-column-header">Activity Type</div>
          <div className="activity-column-header">Activity Date</div>
          <div className="activity-column-header">Activity Status</div>

        </>
      );
    }
    this.setState({ headerData: headerDatadata });
  }


  TextFieldComponent = (props) => {
    // eslint-disable-next-line
    return <TextField {...props} disabled />;
  };

  getUploadedTibDataLog = async (activityType) => {
    const { authUser } = this.props;
    const { autoView, monthlyView, swapweeklyoffview } = this.state;

    const uploadDataLog = await services.getUploadedTibDataLog(authUser, activityType);
    try {
      this.setState(prevState => ({
        activityData: uploadDataLog,
        // Initialized headerData based on default view (monthlyView)
        headerData: (
          <>
            {prevState.filename.length !== 0 && <div className="activity-column-header">File Name</div>}
            {prevState.filename.length !== 0 && <div className="activity-column-header">Uploaded Date</div>}
            {/* <div className="activity-column-header">Uploaded Date</div> */}
            <div className="activity-column-header">Activity Type</div>
            <div className="activity-column-header">Activity Date</div>
            <div className="activity-column-header">Activity Status</div>
          </>
        ),
      }));
    } catch (error) {
      console.error('Error fetching activity data:', error);
    }



    if (uploadDataLog.length > 0 && typeof uploadDataLog[0] !== "undefined") {
      activityType = '';
      if (uploadDataLog[0].enum_activity_type === 'daily') {
        // eslint-disable-next-line
        let jsDateFor = new Date(uploadDataLog[0].var_activity_for);
        const dtFormatFor = `0${jsDateFor.getDate()}`.slice(-2);
        const monthFormatFor = jsDateFor.toLocaleString("default", {
          month: "short",
        });
        const yrFormatFor = jsDateFor.getFullYear();
        const displayActivityDateFor = `${dtFormatFor} ${monthFormatFor} ${yrFormatFor}`;
        // eslint-disable-next-line
        // activityType = '<b>' + uploadDataLog[0].enum_activity_type.charAt(0).toUpperCase() + uploadDataLog[0].enum_activity_type.slice(1) + '</b> upload activity for <b>' + displayActivityDateFor + '</b>';
        activityType = '<b>' + uploadDataLog[0].enum_activity_type.charAt(0).toUpperCase() + uploadDataLog[0].enum_activity_type.slice(1) + '</b> upload activity for <b>' + displayActivityDateFor + '</b>';
      }
      else if (uploadDataLog[0].enum_activity_type === 'monthly' && uploadDataLog[0].enum_activity_status !== "2") {
        activityType = 'Attendance Correction activity';
        this.setState({ exportFlag: true });
      }
      else if (uploadDataLog[0].enum_activity_type === 'monthly' && uploadDataLog[0].enum_activity_status !== "3") {
        activityType = 'Attendance Correction activity';
      }
      else if (uploadDataLog[0].enum_activity_type === 'swapweeklyoff' && uploadDataLog[0].enum_activity_status !== "2") {
        activityType = 'Weekly Swap Off activity';
        this.setState({ exportFlag: true });
      }
      else if (uploadDataLog[0].enum_activity_type === 'swapweeklyoff' && uploadDataLog[0].enum_activity_status !== "3") {
        activityType = 'Weekly Swap Off activity';
      }

      let activityStatus = "";
      let statusColor = "";
      if (uploadDataLog[0].enum_activity_status === "1") {
        activityStatus = "In Process...";
        statusColor = "#FF0000";
      }
      else if (uploadDataLog[0].enum_activity_status === "3") {
        activityStatus = "Process Completed, but error occurred.";
        statusColor = "#FF0000";
      } else {
        activityStatus = "Successfully Completed";
        statusColor = "#169112";
      }
      // eslint-disable-next-line
      if (uploadDataLog[0].enum_activity_status === '2' && uploadDataLog[0].int_export_data_id > 0) {
        // eslint-disable-next-line
        this.setState({ exportFlag: true, exportDataId: uploadDataLog[0].int_export_data_id });
      }
      // eslint-disable-next-line
      let activityDate = uploadDataLog[0].dt_activity_date;
      const time = activityDate.slice(11, 16);
      const jsDate = new Date(activityDate);
      const dtFormat = `0${jsDate.getDate()}`.slice(-2);
      const monthFormat = jsDate.toLocaleString("default", { month: "short" });
      const timeHrMin = jsDate.toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: false,
      });
      const yrFormat = jsDate.getFullYear();
      // const displayActivityDate = `${dtFormat} ${monthFormat} ${yrFormat} ${timeHrMin}`;
      const displayActivityDate = `${dtFormat} ${monthFormat} ${yrFormat}`

      let activityLog = uploadDataLog[0].text_activity_log
        .replace(
          /\n----------------------------------------------\n/g,
          " | "
        )
        .replace(/--- /g, "|")
        .replace(/\n/g, " | ");
      activityLog = activityLog.replace(
        /({"Result":"[^"]*"})/g,
        '$1'
      );

      const errorPattern = /err[^|]*\|/g;
      const sqlErrPattern = /sql[^|]*\|/g;

      // Replace all error message substrings with an empty string
      activityLog = activityLog.replace(errorPattern, '');
      activityLog = activityLog.replace(sqlErrPattern, '');


      let id = "attendanceTab";
      if (this.state.monthlyView) {

        let recentActivityHTML = <Recentactivity filename={this.state.filename} activityCompletionStatus={this.state.activityData[0].enum_activity_status} id={id} headerdata={this.state.headerData} activityType={activityType} displayActivityDate={displayActivityDate} statusColor={statusColor} activityStatus={activityStatus}
          activityLog={activityLog} autoView={autoView} monthlyView={monthlyView} swap={swapweeklyoffview} />;
        // Render the React component into the DOM
        ReactDOM.render(recentActivityHTML, this.uploadStatusRef.current)
      }

      if (this.state.swapweeklyoffview) {
        let recentActivityHTML = <Recentactivity filename={this.state.filename} activityCompletionStatus={this.state.activityData[0].enum_activity_status} id={id} headerdata={this.state.headerData} activityType={activityType} displayActivityDate={displayActivityDate} statusColor={statusColor} activityStatus={activityStatus}
          activityLog={activityLog} autoView={autoView} monthlyView={monthlyView} swap={swapweeklyoffview} />;
        // Render the React component into the DOM
        ReactDOM.render(recentActivityHTML, this.uploadStatusRef.current)
      }


      if (monthlyView) {
        let recentActivityHTML = <Recentactivity filename={this.state.filename} activityCompletionStatus={this.state.activityData[0].enum_activity_status} id={id} headerdata={this.state.headerData} activityType={activityType} displayActivityDate={displayActivityDate} statusColor={statusColor} activityStatus={activityStatus}
          activityLog={activityLog} autoView={autoView} monthlyView={monthlyView} swap={swapweeklyoffview} />;
        // Render the React component into the DOM
        ReactDOM.render(recentActivityHTML, this.uploadStatusRef.current)
      }

      if (swapweeklyoffview) {
        let recentActivityHTML = <Recentactivity filename={this.state.filename} activityCompletionStatus={this.state.activityData[0].enum_activity_status} id={id} headerdata={this.state.headerData} activityType={activityType} displayActivityDate={displayActivityDate} statusColor={statusColor} activityStatus={activityStatus}
          activityLog={activityLog} autoView={autoView} monthlyView={monthlyView} swap={swapweeklyoffview} />;
        // Render the React component into the DOM
        ReactDOM.render(recentActivityHTML, this.uploadStatusRef.current)
      }



      if (this.state.attendanceCorrectionProcess) {
        let recentActivityHTML = <Recentactivity filename={this.state.filename} activityCompletionStatus={this.state.activityData[0].enum_activity_status} id={id} headerdata={this.state.headerData} activityType={activityType} displayActivityDate={displayActivityDate} statusColor={statusColor} activityStatus={activityStatus}
          activityLog={activityLog} autoView={autoView} monthlyView={monthlyView} swap={swapweeklyoffview} />;
        // Render the React component into the DOM
        ReactDOM.render(recentActivityHTML, this.uploadStatusRef.current)
      }
      this.setState({ attendanceCorrectionProcess: false })

      if (this.state.swapweeklyoffCorrection) {
        let recentActivityHTML = <Recentactivity filename={this.state.filename} activityCompletionStatus={this.state.activityData[0].enum_activity_status} id={id} headerdata={this.state.headerData} activityType={activityType} displayActivityDate={displayActivityDate} statusColor={statusColor} activityStatus={activityStatus}
          activityLog={activityLog} autoView={autoView} monthlyView={monthlyView} swap={swapweeklyoffview} />;
        // Render the React component into the DOM
        ReactDOM.render(recentActivityHTML, this.uploadStatusRef.current)
      }
      this.setState({ swapweeklyoffCorrection: false })



      // const recentActivityHTML = <Recentactivity filename = {this.correctionFileName} activityCompletionStatus={this.state.activityData[0].enum_activity_status} id={id} headerdata={this.state.headerData} activityType={activityType} displayActivityDate={displayActivityDate} statusColor={statusColor} activityStatus={activityStatus} activityLog={activityLog} autoView={autoView} monthlyView={monthlyView} swap={swapweeklyoffview} />;

      // ReactDOM.render(recentActivityHTML, this.uploadStatusRef.current);

    }

  };

  getAutoUploadedTibDataLog = async () => {
    const { authUser } = this.props;
    const { autoView, monthlyView, swapweeklyoffview } = this.state;
    const uploadDataLog = await services.getAutoUploadedTibDataLog(authUser);

    this.setState({
      activityData: uploadDataLog,
      // Initialized headerData based on default view (monthlyView)
      headerData: (
        <>
          <div className="activity-column-header">Activity Type</div>
          <div className="activity-column-header">Activity Date</div>
          <div className="activity-column-header">Activity Status</div>
        </>
      ),
    });

    if (uploadDataLog.length > 0 && typeof uploadDataLog[0] !== "undefined") {
      // eslint-disable-next-line
      let jsDateFor = new Date(uploadDataLog[0].var_activity_for);
      const dtFormatFor = `0${jsDateFor.getDate()}`.slice(-2);
      const monthFormatFor = jsDateFor.toLocaleString("default", {
        month: "short",
      });
      const yrFormatFor = jsDateFor.getFullYear();
      const displayActivityDateFor = `${dtFormatFor} ${monthFormatFor} ${yrFormatFor}`;
      // eslint-disable-next-line
      let activityType = 'Auto Script ' + uploadDataLog[0].enum_activity_type.charAt(0).toUpperCase() + uploadDataLog[0].enum_activity_type.slice(1) + ' upload activity for ' + displayActivityDateFor;
      let activityStatus = "";
      let statusColor = "";
      if (uploadDataLog[0].enum_activity_status === "1") {
        activityStatus = "In Process...";
        statusColor = "#FF0000";
        this.setState({ autocorrectionBtn: true })
      }
      else if (uploadDataLog[0].enum_activity_status === "3") {
        activityStatus = "Process Completed, but error occurred.";
        statusColor = "#FF0000";
        this.setState({exportFlag: true, autocorrectionBtn: false })
      } else {
        activityStatus = "Successfully Completed";
        statusColor = "#169112";
        this.setState({ autocorrectionBtn: false })
      }
      // eslint-disable-next-line
      if (uploadDataLog[0].enum_activity_status === '2' && uploadDataLog[0].int_export_data_id > 0) {
        // eslint-disable-next-line
        this.setState({ exportFlag: false, exportDataId: uploadDataLog[0].int_export_data_id });
      }
      // eslint-disable-next-line
      let activityDate = uploadDataLog[0].dt_activity_date;
      const jsDate = new Date(activityDate);
      const dtFormat = `0${jsDate.getDate()}`.slice(-2);
      const monthFormat = jsDate.toLocaleString("default", { month: "short" });
      const timeHrMin = jsDate.toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: false,
      });
      const yrFormat = jsDate.getFullYear();
      const displayActivityDate = `${dtFormat} ${monthFormat} ${yrFormat}`;

      const activityLog = uploadDataLog[0].text_activity_log
        .replace(
          /\n----------------------------------------------\n/g,
          "|"
        )
        .replace(/--- /g, "|")
        .replace(/\n/g, " | ");

      let id = "tibProcessTab";
      const recentActivityHTML = <Recentactivity filename={this.state.filename} activityCompletionStatus={uploadDataLog[0].enum_activity_status} id={id} headerdata={this.state.headerData} activityType={activityType} displayActivityDate={displayActivityDate} statusColor={statusColor} activityStatus={activityStatus} activityLog={activityLog} autoView={autoView} monthlyView={monthlyView} swap={swapweeklyoffview} />;
      // Render the React component into the DOM
      ReactDOM.render(recentActivityHTML, this.uploadStatusRef.current);
      // }
    }
  };
  getLatestExportedFiles = async () => {
    const fileList = await TibServices.getProhanceExportedFileList();
    if (fileList.length > 0 && typeof fileList[0] !== "undefined") {
      let structure = '<table border="1" cellpadding="5" cellspacing="5" style="border-collapse: collapse;border:1px solid #E5E3E3; width:98%">';
      structure += '<th colspan="4" style="text-align:left; background-color:#4B4B55; height: 40px; padding-left: 50px; border-right: none;color:white;font-family:Circular TT Book;font-size:14px;font-style:normal;font-weight:450;">Recent Exported Files</th>';
      structure = `${structure}<tr style="height: 48px;border: 1px solid #DBDBDD;background-color:#DBDBDD;color: #4B4B55;

      font-family: "Circular TT";
      font-size: 14px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;">
          <th style="text-align:left; padding-left:50px; width: auto; border: none;"><b>Selected Date</b></th>
          <th style="text-align:left; padding-left:5px; width: auto; border: none;"><b>File Name</b></th>
          <th style="text-align:center; padding-left:5px; width: auto; border: none;"><b>Exported on</b></th>
          <th style="text-align:center; padding-left:5px; width: auto; border: none;"><b>Action</b></th>
          <tr>`;
      fileList.forEach((file) => {
        let fileUrl = `${urlConfig.tmlcApiUrl}document/prohance/${file.filename}`;
        let fileDate = file.filename.substr(0, 4) + '-' + file.filename.substr(4, 2) + '-' + file.filename.substr(6, 2);
        structure = `${structure}<tr style="border:1px solid #DBDBDD;height: 48px;">
          <td class="dateCell"style="text-align:left; padding-left:50px; "><b>${getDisplayDateFormat(new Date(fileDate))}</b></td>
          <td class="dateCell"style="text-align:left; padding-left:5px;">${file.filename}</td>
          <td class="dateCell"style="text-align:center; padding-left:5px;">${getDisplayDateFormat(new Date(file.date))}</td>
          <td style=" padding-left:32px; width: auto; border:none;display:flex;justify-content:center;align-items: center;">
          <a href="${fileUrl}" target="_blank" download style="color: grey; text-decoration: none; padding-right:5px;padding-top:5px">
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M6.28607 20C5.98292 20 5.6922 19.8796 5.47784 19.6652C5.26349 19.4509 5.14307 19.1601 5.14307 18.857C5.14307 18.5539 5.26349 18.2631 5.47784 18.0488C5.6922 17.8344 5.98292 17.714 6.28607 17.714H17.7141C18.0172 17.714 18.3079 17.8344 18.5223 18.0488C18.7366 18.2631 18.8571 18.5539 18.8571 18.857C18.8571 19.1601 18.7366 19.4509 18.5223 19.6652C18.3079 19.8796 18.0172 20 17.7141 20H6.28607ZM12.8071 15.093C12.5924 15.3073 12.3014 15.4277 11.9981 15.4277C11.6947 15.4277 11.4037 15.3073 11.1891 15.093L6.61807 10.521C6.51163 10.4148 6.42716 10.2886 6.36948 10.1497C6.3118 10.0108 6.28205 9.86194 6.28191 9.71156C6.28177 9.56117 6.31125 9.41224 6.36867 9.27325C6.42609 9.13426 6.51033 9.00794 6.61657 8.9015C6.72281 8.79506 6.84897 8.71059 6.98785 8.65292C7.12674 8.59524 7.27562 8.56548 7.42601 8.56534C7.57639 8.5652 7.72533 8.59468 7.86432 8.6521C8.00331 8.70952 8.12963 8.79376 8.23607 8.9L10.8571 11.521V5.143C10.8571 4.83986 10.9775 4.54913 11.1918 4.33478C11.4062 4.12042 11.6969 4 12.0001 4C12.3032 4 12.5939 4.12042 12.8083 4.33478C13.0226 4.54913 13.1431 4.83986 13.1431 5.143V11.525L15.7641 8.9C15.9786 8.68544 16.2696 8.5649 16.5731 8.5649C16.8765 8.5649 17.1675 8.68544 17.3821 8.9C17.5966 9.11456 17.7172 9.40557 17.7172 9.709C17.7172 10.0124 17.5966 10.3034 17.3821 10.518L12.8111 15.093H12.8071Z" fill="#4B4B55"/>
              </svg> 
          </a><div">Download</div>
      </td>
      
          <tr>`;
      });

      structure += "</table>";
      const structureHTML = `<div style="width: 100% !important;">${structure}</div>`;
      const html = { __html: structureHTML };

      ReactDOM.render(<div dangerouslySetInnerHTML={html} />, this.uploadStatusRef.current);
      // document.getElementById("uploadStatus").innerHTML = `<div style="width: 100% !important;">${structure}</div>`;
    }
  };
  // eslint-disable-next-line
  errorHandler = (reject, error) => {
    reject("operation failed");
  };

  startDailyDataProcessing = async () => {
    const { authUser } = this.props;
    this.setState({ loader: true, exportFlag: false });
    //useRef() class componet createRef()
    document.getElementById("uploadStatus").innerHTML =
      "This may take an hour. You can leave the page and continue with other work<br>Visit this page anytime to check the status of this activity";
    const data = {};
    data.processDate = this.processDateAtt;
    data.dataDuration = "daily";
    data.authUser = authUser;
    data.reuploadFlag = this.reuploadFlag;
    data.prohanceDataFlag = this.prohanceDataFlag;
    this.setState({ loader: false });
    // eslint-disable-next-line
    return new Promise(function onThen(resolve, reject) {
      axios
        .post(`${urlConfig.tibrApiUrl}processtibdata`, data, {
          headers: {
            authorization: localStorage.getItem("jwtToken"),
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        // eslint-disable-next-line
        .then(function (response) {
          return resolve(this.commonReturn(response));
        })
        // eslint-disable-next-line
        .catch(function (error) {
          return null;
        });
    });
  };

  getDescripancy = async () => {
    const { authUser } = this.props;
    const { data } = await axios.get(
      `${urlConfig.tibrApiUrl}descripancy/${authUser}`);
    if (data.length > 0) {
      this.setState({
        exportFlag: true,
        descripancyData: data
      });
    }
  }
  getSwapDescripancy = async () => {
    const { authUser } = this.props;
    const { data } = await axios.get(
      `${urlConfig.tibrApiUrl}swapdescripancy/${authUser}`);
    if (data.length > 0) {
      this.setState({
        exportFlag: true,
        descripancyData: data
      });
    }
  }
  startMonthlyDataProcessing = async () => {
    const { authUser } = this.props;
    this.setState({ loader: true, exportFlag: false });

    // document.getElementById("uploadStatus").innerHTML =
    // "This may take an hour. You can leave the page and continue with other work<br>Visit this page anytime to check the status of this activity";
    ReactDOM.render("This may take an hour. You can leave the page and continue with other work Visit this page anytime to check the status of this activity",
      this.uploadStatusRef.current);
    const data = {};
    data.processDate = this.processDateAtt;
    data.dataDuration = "monthly";
    data.authUser = authUser;
    data.reuploadFlag = false;
    data.prohanceDataFlag = false;
    data.correctionFileName = this.correctionFileName;
    this.setState({ loader: false, disbaleAttendanceCorrectionButton: true });
    const self = this;
    // eslint-disable-next-line
    return new Promise(async function onThen(resolve, reject) {
      const response = await axios.post(
        `${urlConfig.tibrApiUrl}processtibdata`,
        data,
        {
          headers: {
            authorization: localStorage.getItem("jwtToken"),
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      if (response != null) {
        self.setState({ attendanceCorrectionProcess: true, disbaleAttendanceCorrectionButton: false });
        self.setState({ attendanceCorrTabFlag: true });
        self.getUploadedTibDataLog('monthly');
      }
    });
  };
  startSwapWeeklyOffDataProcessing = async () => {
    const { authUser } = this.props;
    this.setState({ loader: true, exportFlag: false, disableSwapWeeklyOffButton: true });
    // document.getElementById("uploadStatus").innerHTML =
    //   "This may take an hour. You can leave the page and continue with other work<br>Visit this page anytime to check the status of this activity";
    ReactDOM.render("This may take an hour. You can leave the page and continue with other work Visit this page anytime to check the status of this activity",
      this.uploadStatusRef.current);
    const data = {};
    data.processDate = this.processDateAtt;
    data.dataDuration = "swapweeklyoff";
    data.authUser = authUser;
    data.reuploadFlag = false;
    data.prohanceDataFlag = false;
    data.correctionFileName = this.correctionFileName;
    this.setState({ loader: false });
    const self = this;
    // eslint-disable-next-line
    return new Promise(async function onThen(resolve, reject) {
      const response = await axios.post(
        `${urlConfig.tibrApiUrl}processtibdata`,
        data,
        {
          headers: {
            authorization: localStorage.getItem("jwtToken"),
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      if (response != null) {
        self.setState({ swapweeklyoffCorrection: true, disableSwapWeeklyOffButton: false });
        self.setState({ swapCorrTabFlag: true });
        self.getUploadedTibDataLog('swapweeklyoff');
      }

    });
  };
  uploadFile = async (e) => { // NOSONAR
    const { authUser } = this.props;
    this.proceedFlag = true;
    this.proceedMonthlyFlag = true;
    // const fileName = e.target.files[0];
    this.fileName = e.target.files[0];
    this.setState({ filename: this.fileName.name })
    // this.state.filename = this.fileName.name;
    let errorDivId = "";
    let fileNamePart = "";
    if (e.target.id === "uploadAttendance") {
      this.proceedMonthlyFlag = false;
      this.startMonthlyProcessFlag = false;
      this.startSwapWeeklyOffDataProcessingFlag = false;
      errorDivId = "attendanceResponse";
      // eslint-disable-next-line
      this.processDateAtt = this.fileName.name.split('_')[0];
      // eslint-disable-next-line
      fileNamePart = this.fileName.name.split('_')[1].split('.')[0];
      if (fileNamePart !== "attendance") {
        document.getElementById(errorDivId).className = "";
        document.getElementById(errorDivId).classList.add("errorStyle");
        document.getElementById(errorDivId).innerText = "File name is wrong";
        return;
      }
      if (!this.reuploadFlag) {
        // ---- Check if data of selected date is already exists or not ----//
        const req1 = new XMLHttpRequest();
        try {
          const formData = new FormData();
          formData.append("filedate", this.processDateAtt);
          req1.addEventListener("load", () => {
            const res = JSON.parse(req1.responseText);
            if (res.result === "Fail") {
              this.proceedFlag = false;
              document.getElementById(errorDivId).className = "";
              document.getElementById(errorDivId).classList.add("errorStyle");
              document.getElementById(errorDivId).innerText =
                "Attendance Data is already exists for this date";
            }
          });
          req1.open(
            "POST",
            `${corsallow}${urlConfig.tibrApiUrl}checktibattendancedate`,
            false
          );
          req1.setRequestHeader(
            "Authorization",
            localStorage.getItem("jwtToken")
          );
          req1.send(formData);
        } catch (error) {
          // eslint-disable-next-line
        }
        // -----//
      }
    } else if (e.target.id === "uploadProhance") {
      this.proceedMonthlyFlag = false;
      this.startMonthlyProcessFlag = false;
      this.startSwapWeeklyOffDataProcessingFlag = false;
      errorDivId = "prohanceResponse";
      // eslint-disable-next-line
      this.processDatePro = this.fileName.name.split('_')[0];
      // eslint-disable-next-line
      fileNamePart = this.fileName.name.split('_')[1].split('.')[0];
      if (fileNamePart !== "activity") {
        this.proceedFlag = false;
        document.getElementById(errorDivId).className = "";
        document.getElementById(errorDivId).classList.add("errorStyle");
        document.getElementById(errorDivId).innerText = "File name is wrong";
        return;
      }
      if (this.attendanceFlag === false) {
        this.proceedFlag = false;
        document.getElementById(errorDivId).className = "";
        document.getElementById(errorDivId).classList.add("errorStyle");
        document.getElementById(errorDivId).innerText =
          "Please upload attendance data first";
        return;
      }
      if (this.processDatePro !== this.processDateAtt) {
        this.proceedFlag = false;
        document.getElementById(errorDivId).className = "";
        document.getElementById(errorDivId).classList.add("errorStyle");
        document.getElementById(errorDivId).innerText =
          "Date of Prohance Data is different from Attendance Data";
        return;
      }
    } else if (e.target.id === "uploadMonthlyAttendance" || e.target.id === 'uploadSwapWeeklyOff') {
      this.proceedFlag = false;
      errorDivId = "monthlyAttendanceResponse";
      // eslint-disable-next-line
      this.correctionFileName = this.fileName.name;
      this.processDateAtt = this.fileName.name.split('_')[0];
      // eslint-disable-next-line

      if (this.proceedMonthlyFlag) {
        if (this.fileName !== undefined) {
          this.getError(this.fileName).then(data => {
            if (data) {
              document.getElementById(errorDivId).className = "";
              document.getElementById(errorDivId).classList.add("errorStyle");
              document.getElementById(errorDivId).innerText = data
            } else {
              // eslint-disable-next-line
              return new Promise((resolve, reject) => {
                const req = new XMLHttpRequest();
                try {
                  const formData = new FormData();
                  formData.append("file", this.fileName, this.fileName.name);
                  req.addEventListener("load", () => {
                    const res = JSON.parse(req.responseText);
                    if (res.message === "Success") {
                      this.startMonthlyProcessFlag = true;
                      this.startSwapWeeklyOffDataProcessingFlag = true;
                      document.getElementById(errorDivId).className = "";
                      document
                        .getElementById(errorDivId)
                        .classList.add("suceessStyle");
                      // document.getElementById(errorDivId).innerText = (!this.state.attendanceCorrectionProcess ? " ": "File uploaded successfully");
                      document.getElementById(errorDivId).innerText =
                        "File uploaded successfully";
                      this.setState({ changedData: true });
                    } else {
                      this.startMonthlyProcessFlag = false;
                      this.startSwapWeeklyOffDataProcessingFlag = false;
                      document.getElementById(errorDivId).className = "";
                      document.getElementById(errorDivId).classList.add("errorStyle");
                      document.getElementById(errorDivId).innerText =
                        "File Upload Failed";
                    }
                  });
                  req.open(
                    "POST",
                    `${corsallow}${urlConfig.tibrApiUrl}uploadtibmonthlydata/${authUser}${this.state.swapweeklyoffview ? '/swap' : '/attendance'}`
                  );
                  req.setRequestHeader(
                    "Authorization",
                    localStorage.getItem("jwtToken")
                  );
                  req.send(formData);
                } catch (error) {
                  // eslint-disable-next-line
                  console.error("File Upload Error : ", error);
                }
              });
            }
          });
        } else {
          return;
        }

      }
    }

    if (this.proceedFlag) {
      if (this.fileName !== undefined) {
        if (this.fileName.type.trim() !== "application/vnd.ms-excel" && this.fileName.type.trim() !== "text/csv") {
          document.getElementById(errorDivId).className = "";
          document.getElementById(errorDivId).classList.add("errorStyle");
          document.getElementById(errorDivId).innerText =
            "Please select csv file only";
          return;
        }
      } else {
        return;
      }
      // eslint-disable-next-line
      return new Promise((resolve, reject) => {
        const req = new XMLHttpRequest();
        try {
          const formData = new FormData();
          formData.append("file", this.fileName, this.fileName.name);
          req.addEventListener("load", () => {
            const res = JSON.parse(req.responseText);
            if (res.message === "Success") {
              if (errorDivId === "attendanceResponse")
                this.attendanceFlag = true;
              if (errorDivId === "prohanceResponse") this.prohanceFlag = true;
              document.getElementById(errorDivId).className = "";
              document.getElementById(errorDivId).classList.add("suceessStyle");
              document.getElementById(errorDivId).innerText =
                "File uploaded successfully";
              this.setState({ changedData: true });
            } else {
              if (errorDivId === "attendanceResponse")
                this.attendanceFlag = false;
              if (errorDivId === "prohanceResponse") this.prohanceFlag = false;
              document.getElementById(errorDivId).className = "";
              document.getElementById(errorDivId).classList.add("errorStyle");
              document.getElementById(errorDivId).innerText =
                "File Upload Failed";
            }
          });
          req.open(
            "POST",
            `${corsallow}${urlConfig.tibrApiUrl}uploadtibdailydata/${authUser}`
          );
          req.setRequestHeader(
            "Authorization",
            localStorage.getItem("jwtToken"),
            "X-Content-Type-Options",
            "nosniff"
          );
          req.send(formData);
        } catch (error) {
          // eslint-disable-next-line
        }
      });
    }
  };

  // For validating attendance correction file
  getError = (file) => {
    return new Promise((resolve, reject) => {
      const fileName = file.name.split('.')[0];

      if (!this.isFileNameCorrect(fileName)) {
        resolve('File type should be only csv')
      }

      if (!this.isFileSizeCorrect(fileName)) {
        resolve('File exceeds 10MB size')
      }


      if (!this.isFileNameCorrect(fileName)) {
        resolve('File name is not correct. File name must be in form of YYYYMMDD.csv')
      }
      if (file.type.trim() !== "application/vnd.ms-excel" && file.type.trim() !== "text/csv") {
        resolve("Please select csv file only");
      }
      this.getCsvData(file).then((csvData) => {
        if (csvData) {
          const csvDataHeader = csvData[0];

          if (csvDataHeader.length < (this.state.swapweeklyoffview ? swapWeeklyOffFileHeaders.length : attendanceFileHeaders.length) || csvDataHeader.length === 0) {
            resolve('No. of columns are less than the required columns in the file.');
          }
          if (csvDataHeader.length > (this.state.swapweeklyoffview ? swapWeeklyOffFileHeaders.length : attendanceFileHeaders.length)) {
            resolve('No. of columns are more than the required columns in the file.');
          }
          if (!this.isColumnNameCorrect(csvDataHeader)) {
            resolve(`Column names are incorrect or their order is not correct. Column names are ${this.state.swapweeklyoffview ? swapWeeklyOffFileHeaders.toString() : attendanceFileHeaders.toString()} and in the same order.`)
          }
          if (!this.isDateColumnDataCorrect(csvData)) {
            resolve('Date column does not contains correct data. It accept date in YYYY-MM-DD format.')
          }
          if (!this.state.swapweeklyoffview) {
            if (csvData.length > maxiNoOfRowAcceptableInAttendanceFile + 1) {
              resolve('Attendance correction will only accept 100 records.')
            }
            if (!this.isPortionColumnDataCorrect(csvData)) {
              resolve('Portion column does not contains correct data. It accept either 0.25, 0.50 or 1.')
            }
            if (!this.isType2ColumnDataCorrectWithPortionColumn(csvData)) {
              resolve('Type2 column does not contains correct data. Please input data in Type2 column for Portion 0.25 and 0.5. And remove if Portion is 1.')
            }
            if (!this.isType1AndType2ValueIsMatching(csvData)) {
              resolve('Type1 column and Type2 column data can not be same.')
            }
            if (!this.checkAttendanceTypeData(csvData, 'type1')) {
              resolve('Type1 data is not correct. Please input data in capital letters and it should not contain any special characters other then "_"')
            }
            if (!this.checkAttendanceTypeData(csvData, 'type2')) {
              resolve('Type2 data is not correct. Please input data in capital letters and it should not contain any special characters other then "_"')
            }
            if (!this.checkPortionColumnWithType1AndType2(csvData)) {
              resolve('If Type1 or Type2 is QL then portion sould be 0.25 and viceversa.')
            }
          }
          // If none of the conditions matched, you can resolve with null or a success message
          resolve(null);
        }
      });
    });
  };
  //To check the column names are correct and in same order
  isColumnNameCorrect = (columnNames) => {
    const fileHeaders = this.state.swapweeklyoffview ? swapWeeklyOffFileHeaders : attendanceFileHeaders;
    for (let i = 0; i < columnNames.length; i++) {
      if (columnNames[i] !== fileHeaders[i]) {
        return false;
      }
    }
    return true;
  }
  //To check data of portion column is correct or not
  isPortionColumnDataCorrect = (csvData) => {
    let portionColumnData = csvData.map(ele => ele[4]);
    portionColumnData = portionColumnData.slice(1);
    for (let data of portionColumnData) {
      if (!attendanceFilePortionColumnData.includes(data)) {
        return false
      }
    }
    return true
  }

  //To check data of Date column is correct or not
  isDateColumnDataCorrect = (csvData) => {
    let dateColumnData = csvData.map(ele => ele[2]);
    dateColumnData = dateColumnData.slice(1);
    for (let data of dateColumnData) {
      if (!this.isValidDate(data)) {
        return false;
      }
    }
    return true;
  }

  checkPortionColumnWithType1AndType2 = (csvData) => {
    let type2ColumnData = csvData.map(ele => ele[5]);
    type2ColumnData = type2ColumnData.slice(1);
    let type1ColumnData = csvData.map(ele => ele[3]);
    type1ColumnData = type1ColumnData.slice(1);
    let portionColumnData = csvData.map(ele => ele[4]);
    portionColumnData = portionColumnData.slice(1);
    for (let i = 0; i < portionColumnData.length; i++) {
      if (
        (portionColumnData[i] === '0.25' && (type2ColumnData[i] !== 'QL' && type1ColumnData[i] !== 'QL')) ||
        (portionColumnData[i] !== '0.25' && (type2ColumnData[i] === 'QL' || type1ColumnData[i] === 'QL'))
      ) {
        return false;
      }
    }
    return true;
  }
  //To check type2 column data are correct or not
  isType2ColumnDataCorrectWithPortionColumn = (csvData) => {
    let type2ColumnData = csvData.map(ele => ele[5]);
    type2ColumnData = type2ColumnData.slice(1);
    let portionColumnData = csvData.map(ele => ele[4]);
    portionColumnData = portionColumnData.slice(1);
    for (let i = 0; i < type2ColumnData.length; i++) {
      if ((portionColumnData[i] !== '1' && type2ColumnData[i] === '') ||
        (type2ColumnData[i] !== '' && portionColumnData[i] === '1')) {
        return false;
      }
    }
    return true;
  }

  isFileTypeCorrect = (fileName) => {
    if (!this.fileName.name.toLowerCase().endsWith('.csv')) {
      return false;
    }
    return true;
  }


  isFileSizeCorrect = (fileName) => {
    const fileSizeMB = this.fileName.size / (1024 * 1024); // Converting bytes to MB
    if (fileSizeMB > 10) {
      // document.getElementById(errorDivId).innerText = "File name is wrong";
      console.error('File size exceeds 10MB limit.');
      return false;
    }
    return true;
  }

  //To check file name is correct or not
  isFileNameCorrect = (fileName) => {
    const datePattern = /^(?:19|20)\d\d(0[1-9]|1[0-2])(0[1-9]|[12][0-9]|3[01])$/
    return datePattern.test(fileName);
  }
  //To validate the date string ex: 04-09-2023
  isValidDate = (dateString) => {
    const dateRegex = /^(?:\d{4}-(?:0[1-9]|1[0-2])-(?:0[1-9]|[12][0-9]|3[01]))$/;
    return dateRegex.test(dateString);
  }

  //To check any value in type and type2 is matching
  isType1AndType2ValueIsMatching = (csvData) => {
    let type2ColumnData = csvData.map(ele => ele[5]);
    type2ColumnData = type2ColumnData.slice(1);
    let type1ColumnData = csvData.map(ele => ele[3]);
    type1ColumnData = type1ColumnData.slice(1);
    for (let i = 0; i < type2ColumnData.length; i++) {
      if (type1ColumnData[i] !== '' && type2ColumnData[i] !== '' && (type1ColumnData[i] === type2ColumnData[i])) {
        return false;
      }
    }
    return true;
  }

  //To check type column data are correct or not
  checkAttendanceTypeData = (csvData, typeColumn) => {
    let checkingData = [];
    if (typeColumn === 'type1') {
      let type1ColumnData = csvData.map(ele => ele[3]);
      checkingData = type1ColumnData.slice(1);
    }
    else if (typeColumn === 'type2') {
      let type2ColumnData = csvData.map(ele => ele[5]);
      checkingData = type2ColumnData.slice(1);
    }
    const regex = /^[A-Z_]+$/;
    for (const data of checkingData) {
      if (data !== '' && !regex.test(data.trim())) {
        return false;
      }
    }
    return true;
  }

  // To get the data of csv file in array
  getCsvData = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      // Ensure that 'this' is accessible within the callback
      const self = this;

      reader.onload = function (e) {
        const csvData = e.target.result;
        // Now 'csvData' contains the contents of the CSV file

        // You can parse the CSV data into an array or perform other operations here
        const parsedData = self.parseCsvData(csvData);
        resolve(parsedData); // Resolve the Promise with the parsed data
      };

      reader.readAsText(file);
    });
  }

  // Function to parse the CSV data into an array
  parseCsvData = (csvData) => {
    const lines = csvData.split('\n');
    const result = [];
    for (let data of lines) {
      const line = data.trim();
      if (line) {
        const values = line.split(',');
        result.push(values);
      }
    }
    return result;
  };




  setFields = async (e) => {
    this.attendanceFlag = false;
    this.prohanceFlag = false;
    this.startMonthlyProcessFlag = false;
    this.startSwapWeeklyOffDataProcessingFlag = false;
    if (e.target.id === "dRadio") {
      this.getUploadedTibDataLog('daily');
      this.setState({

        dailyView: true,
        monthlyView: false,
        exportFlag: false,
        downloadView: false,
        autoView: false,
        prohanceDownloadView: false,
        swapweeklyoffview: false,
      });
    } else if (e.target.id === "mRadio") {
      this.setState({
        filename: '',
      })

      this.setState({
        heading: 'Template for Attendance Data',
        dailyView: false,
        monthlyView: true,
        exportFlag: false,
        downloadView: false,
        autoView: false,
        prohanceDownloadView: false,
        swapweeklyoffview: false,
        attendanceCorrTabFlag: false,

      });
      this.getUploadedTibDataLog('monthly');
    } else if (e.target.id === "wRadio") {
      this.setState({
        filename: '',
      })

      this.setState({
        heading: 'Template for Swap Weekly Off Data',
        dailyView: false,
        monthlyView: false,
        exportFlag: false,
        downloadView: false,
        autoView: false,
        prohanceDownloadView: false,
        swapweeklyoffview: true,
        swapCorrTabFlag: false,

      });
      this.getUploadedTibDataLog('swapweeklyoff');
    }
    else if (e.target.id === "dwRadio") {
      this.setState({
        dailyView: false,
        monthlyView: false,
        exportFlag: false,
        downloadView: true,
        autoView: false,
        prohanceDownloadView: false,
        swapweeklyoffview: false,
        startDate: this.getYesterday(2),
        endDate: this.getYesterday(1),
      });
    } else if (e.target.id === "autoRadio") {

      this.setState({
        heading: 'TIB Process Data',
        dailyView: false,
        monthlyView: false,
        exportFlag: false,
        downloadView: false,
        autoView: true,
        prohanceDownloadView: false,
        swapweeklyoffview: false,
        maxAutoDate: true,
        dailyDate: null,
      });
      this.getAutoUploadedTibDataLog();
    } else if (e.target.id === "prohanceRadio") {
      this.getLatestExportedFiles();
      this.setState({
        dailyView: false,
        monthlyView: false,
        exportFlag: false,
        downloadView: false,
        autoView: false,
        prohanceDownloadView: true,
        swapweeklyoffview: false
      });
    }
  };

  async exportDataInDateRainge() {
    const { startDate, endDate } = this.state;
    this.setState({ loader: true });
    const exportedData = await TibServices.getExportDataInDateRainge(
      getDate(startDate),
      getDate(endDate)
    );
    if (exportedData.length === 0) {
      const ob = {};
      ob.EmpCode = "No Data Available";
      ob.FullName = "";
      ob.AttendanceType = "";
      ob.AttendanceDate = "";
      // eslint-disable-next-line
      ob.tm_time_in_business = '';
      ob.UplAttendanceType = "";
      ob.Portion = "";
      exportedData.push(ob);
    }
    // eslint-disable-next-line
    const columns = { EmpCode: 'Emp Code', FullName: 'Name', AttendanceType: 'Attendance Type', AttendanceDate: 'Attendance Date', tm_time_in_business: 'Input Time in Business', UplAttendanceType: 'Old Attendance Type', Portion: 'Portion' };
    downloadCsv(exportedData, columns);
    this.setState({ loader: false });
  }
  exportProhanceData = async () => {
    const { prohanceDate } = this.state;
    this.setState({ loader: true });
    document.getElementById("uploadStatus").innerHTML =
      "This may take few minutes. You can leave the page and continue with other work.<br>Visit this page anytime to check the status of this activity";
    this.setState({ loader: false });
    await new Promise((resolve, reject) => {
      TibServices.getProhanceExportData(getDate(prohanceDate))
        .then(result => {
          resolve(result);
        })
        .catch(err => {
          reject(err);
        });
    })
      .then(result => {
      })
      .catch(e => {
        console.error('Error in exporting data');
      });
  }
  commonReturn = (response) => {
    if (response != null) {
      document.getElementById(
        "uploadStatus"
      ).innerHTML = response.data.result.replace(/\n/g, "<br />");
      return response.data.result;
    }
    return "RESPONSE IS NULL";
  }
  startAutoScript = async () => {
    const { dailyDate } = this.state;
    this.setState({ loader: true, autocorrectionBtn: true });
    // document.getElementById("uploadStatus").innerHTML =
    //   "This may take an hour. You can leave the page and continue with other work<br>Visit this page anytime to check the status of this activity";
    ReactDOM.render("This may take an hour. You can leave the page and continue with other work Visit this page anytime to check the status of this activity",
      this.uploadStatusRef.current);
    let formattedDate = getDate(dailyDate);
    this.setState({ loader: false });
    // eslint-disable-next-line
    return new Promise(function onThen(resolve, reject) {
      axios
        .get(`${urlConfig.processUrl}tibr/v1/uploadtibjson/${formattedDate}`, {
          headers: {
            authorization: localStorage.getItem("jwtToken"),
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        // eslint-disable-next-line
        .then(function (response) {
          if (response != null) {
            this.setState({ autoCorrection: true, autocorrectionBtn: false });
          }
          return resolve(this.commonReturn(response));
        })
        // eslint-disable-next-line
        .catch(function (error) {
          return null;
        });
    });
  };
  async exportData() {
    const { monthlyView, swapweeklyoffview } = this.state
    if (monthlyView) {
      await this.getDescripancy();
      const { descripancyData } = this.state
      const currentDate = new Date();
      const date = formatDateToYYYYMMDD(currentDate);
      downloadCsv(descripancyData, monthlyColumns, `${date}_attendance_discrepany.csv`);
    }
    else if (swapweeklyoffview) {
      await this.getSwapDescripancy();
      const { descripancyData } = this.state
      const currentDate = new Date();
      const date = formatDateToYYYYMMDD(currentDate);
      downloadCsv(descripancyData, swapWeeklyColumns, `${date}_attendance_discrepany.csv`);
    }
    else {
      const { exportDataId } = this.state;
      const correctedData = await services.getCorrectedTibData(exportDataId);
      downloadCsv(correctedData, exportColumns);
    }
  }
  //To download example template
  downloadTemplate = () => {
    let currentDate = new Date();
    const formattedDate = formatDateToYYYYMMDD(currentDate)
    const fileHeaders = {}
    attendanceFileHeaders.forEach(ele => {
      fileHeaders[ele] = ele
    })
    downloadCsv(this.state.swapweeklyoffview ? templateSwapWeekOffFile : templateAttendanceFile, fileHeaders, formattedDate)
  }

  getDate(date) {
    this.setState({
      dailyDate: date
    })
  }

  getAutoDate = (days) => {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - days);
    return yesterday;
  };

  getYesterday = (days) => {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - days);
    return yesterday;
  };

  getOnemonthDate = (date) => {
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() - 1);
    date.setDate(date.getDate() + 30);
    // Return the minimum of the current date and the modified date
    const res = currentDate < date ? currentDate : date;
    return res;
  };


  render() { // NOSONAR
    const {
      downloadView,
      startDate,
      endDate,
      dailyDate,
      loader,
      autocorrectionBtn,
      exportFlag,
      dailyView,
      monthlyView,
      autoView,
      prohanceDate,
      prohanceDownloadView,
      swapweeklyoffview,
      maxAutoDate,
    } = this.state;



    let maxProhanceDate = new Date();
    maxProhanceDate.setDate(maxProhanceDate.getDate() - 1);
    let minProhanceDate = new Date('2020-10-01');
    let setProhanceDate = prohanceDate;
    if (prohanceDate > maxProhanceDate) {
      setProhanceDate = maxProhanceDate;
    }
    return (
      <div className="attendanceDataForm scroll-bar" >
        <div
          style={{
            marginTop: "25%",
            position: "absolute",
            marginLeft: "49.5%",
          }}
        >
          {loader && <ProgressBar />}
        </div>
        <div>
          <br />
        </div>
        <table style={{ width: '100%' }}>
          <tbody >
            <tr> {/* Navigation bar row start */}
              <td className="navTd">
                <table style={{ marginBottom: 20 }}>
                  {<Navigation setField={this.setFields} setHeaderData={this.setHeaderData} setRecentActivityData={this.setRecentActivityData} />}
                </table >
              </td >
            </tr > {/* Navigation bar row end */}
            {/* Daily view row start */}
            {
              dailyView && (
                <>
                  <tr id="daily_row">
                    <td>
                      <table>
                        <tbody>
                          <tr>
                            <td colSpan="2" style={{ fontWeight: "bold", color: "gray" }}>
                              Use this TAB only if...<br />
                              1. Prohance Data is uploaded successfully by Auto Script <br />
                              2. TIB Data process is NOT completed due to unavailability of Attendance file<br />
                              3. You have Attendance file for that particular day in correct format.<br />
                            </td>
                          </tr>
                          <tr>
                            <td colSpan="2" id="reuploadWarning">
                              &nbsp;
                            </td>
                          </tr>
                          <tr>
                            <td className="left-col">
                              <b>Attendance Data </b>
                            </td>
                            <td className="right-col">
                              <input
                                type="file"
                                id="uploadAttendance"
                                onChange={this.uploadFile}
                              />
                            </td>
                          </tr>
                          <tr>
                            <td colSpan="2" id="attendanceResponse">
                              &nbsp;
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td align="center">
                      {((this.attendanceFlag && this.prohanceFlag) || (this.attendanceFlag && this.prohanceDataFlag) || (!swapweeklyoffview && this.startMonthlyProcessFlag)) && (
                        <Button
                          onClick={this.startDailyDataProcessing}
                          color="primary"
                          variant="contained"
                          title="Start Processing Daily Data"
                        >
                          Start Processing Daily Data
                        </Button>
                      )}
                    </td>
                    <td />
                  </tr>
                </>
              )
            }
            {/* Daily view row end */}
            <b></b>
            {
              monthlyView && (
                <>
                  <tr id="monthly_row" >
                    <td>
                      <table>
                        <tbody>
                          <tr>
                            <td className="left-col monthlyViewData">
                              <div className="tabhead">Template for Attendance Data</div>

                              <Button
                                color="secondary"
                                variant="text"
                                title="Download Template"
                                style={{ paddingLeft: '3px', marginTop: '8px', color: '#c0656f' }}
                                onClick={this.downloadTemplate}
                              >
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                  <path d="M6.28753 20C5.98439 20 5.69366 19.8796 5.47931 19.6652C5.26495 19.4509 5.14453 19.1601 5.14453 18.857C5.14453 18.5539 5.26495 18.2631 5.47931 18.0488C5.69366 17.8344 5.98439 17.714 6.28753 17.714H17.7155C18.0187 17.714 18.3094 17.8344 18.5238 18.0488C18.7381 18.2631 18.8585 18.5539 18.8585 18.857C18.8585 19.1601 18.7381 19.4509 18.5238 19.6652C18.3094 19.8796 18.0187 20 17.7155 20H6.28753ZM12.8085 15.093C12.5939 15.3073 12.3029 15.4277 11.9995 15.4277C11.6962 15.4277 11.4052 15.3073 11.1905 15.093L6.61953 10.521C6.51309 10.4148 6.42863 10.2886 6.37095 10.1497C6.31327 10.0108 6.28351 9.86194 6.28337 9.71156C6.28323 9.56117 6.31272 9.41224 6.37014 9.27325C6.42756 9.13426 6.51179 9.00794 6.61803 8.9015C6.72427 8.79506 6.85043 8.71059 6.98932 8.65292C7.1282 8.59524 7.27709 8.56548 7.42747 8.56534C7.57786 8.5652 7.72679 8.59468 7.86578 8.6521C8.00478 8.70952 8.13109 8.79376 8.23753 8.9L10.8585 11.521V5.143C10.8585 4.83986 10.979 4.54913 11.1933 4.33478C11.4077 4.12042 11.6984 4 12.0015 4C12.3047 4 12.5954 4.12042 12.8098 4.33478C13.0241 4.54913 13.1445 4.83986 13.1445 5.143V11.525L15.7655 8.9C15.9801 8.68544 16.2711 8.5649 16.5745 8.5649C16.878 8.5649 17.169 8.68544 17.3835 8.9C17.5981 9.11456 17.7186 9.40557 17.7186 9.709C17.7186 10.0124 17.5981 10.3034 17.3835 10.518L12.8125 15.093H12.8085Z" fill="#F94F5E" />
                                </svg>
                                <div className="downloadtemplate">Download Template</div>
                              </Button>


                            </td>
                          </tr>
                          <tr className="downloadTempRow">

                          </tr>
                          <tr >
                            <td style={{ height: '50px', width: '300px' }} className="uploadCorrectedDataRow ">
                              <div className="tabhead">Upload Corrected Attendance Data</div>
                            </td>

                          </tr>
                          <tr>
                            {!this.startMonthlyProcessFlag && (
                              <div style={{ marginTop: '10px', pointerEvents: this.state.disbaleAttendanceCorrectionButton ? 'none' : '' }}><Uploadbutton id="uploadMonthlyAttendance" uploadfile={this.uploadFile} /></div>
                            )}
                          </tr>
                          <tr style={{ paddingTop: '0' }}>
                            {(!swapweeklyoffview && this.startMonthlyProcessFlag) && (!this.state.attendanceCorrectionProcess && !this.state.attendanceCorrTabFlag) && (

                              <td style={{ paddingTop: '0' }}>
                                <div className="customprogressbarRow ">
                                  <Customprogressbar filename={this.state.filename} />
                                </div>
                              </td>

                            )}
                            {((this.attendanceFlag && this.prohanceFlag) ||
                              (!swapweeklyoffview && this.startMonthlyProcessFlag)) && (!this.state.attendanceCorrectionProcess && !this.state.attendanceCorrTabFlag) && (
                                <td style={{ paddingLeft: '20px' }}>
                                  <Button
                                    onClick={this.startMonthlyDataProcessing}
                                    color="primary"
                                    variant="contained"
                                    title="Start Processing Monthly Data"
                                    disabled={this.state.disbaleAttendanceCorrectionButton}
                                    // style={{
                                    //   color: this.state.disbaleAttendanceCorrectionButton ? 'rgb(193, 186, 186)' : '',
                                    //   pointerEvents: this.state.disbaleAttendanceCorrectionButton ? 'none' : ''
                                    // }}
                                    style={{ opacity: this.state.disbaleAttendanceCorrectionButton ? "0.5" : "1",color:'white',
                                    fontFamily: "Circular TT Book" ,pointerEvents: this.state.disbaleAttendanceCorrectionButton? 'none' : ''}}
                                  >
                                    Start Attendance Correction
                                  </Button>
                                </td>
                              )}
                          </tr>
                          <tr className="uploadFileRow">

                          </tr>
                          <tr>
                            <td colSpan="2" id="monthlyAttendanceResponse">
                              &nbsp;
                            </td>
                          </tr>

                        </tbody >
                      </table >
                    </td >
                  </tr >
                </>
              )
            }
            {
              swapweeklyoffview && (
                <>
                  <tr id="monthly_row">
                    <td>
                      <table>
                        <tbody>
                          <tr>
                            <td style={{ width: 340, padding: "25px 0 0 0" }}>
                              <div className="tabhead">Template for Swap Weekly Off Data</div>
                              <Button
                                className="left-col"
                                color="secondary"
                                variant="text"
                                title="Download Template"
                                style={{ paddingLeft: '3px', marginTop: '8px', color: '#c0656f' }}
                                onClick={this.downloadTemplate}
                              >
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                  <path d="M6.28753 20C5.98439 20 5.69366 19.8796 5.47931 19.6652C5.26495 19.4509 5.14453 19.1601 5.14453 18.857C5.14453 18.5539 5.26495 18.2631 5.47931 18.0488C5.69366 17.8344 5.98439 17.714 6.28753 17.714H17.7155C18.0187 17.714 18.3094 17.8344 18.5238 18.0488C18.7381 18.2631 18.8585 18.5539 18.8585 18.857C18.8585 19.1601 18.7381 19.4509 18.5238 19.6652C18.3094 19.8796 18.0187 20 17.7155 20H6.28753ZM12.8085 15.093C12.5939 15.3073 12.3029 15.4277 11.9995 15.4277C11.6962 15.4277 11.4052 15.3073 11.1905 15.093L6.61953 10.521C6.51309 10.4148 6.42863 10.2886 6.37095 10.1497C6.31327 10.0108 6.28351 9.86194 6.28337 9.71156C6.28323 9.56117 6.31272 9.41224 6.37014 9.27325C6.42756 9.13426 6.51179 9.00794 6.61803 8.9015C6.72427 8.79506 6.85043 8.71059 6.98932 8.65292C7.1282 8.59524 7.27709 8.56548 7.42747 8.56534C7.57786 8.5652 7.72679 8.59468 7.86578 8.6521C8.00478 8.70952 8.13109 8.79376 8.23753 8.9L10.8585 11.521V5.143C10.8585 4.83986 10.979 4.54913 11.1933 4.33478C11.4077 4.12042 11.6984 4 12.0015 4C12.3047 4 12.5954 4.12042 12.8098 4.33478C13.0241 4.54913 13.1445 4.83986 13.1445 5.143V11.525L15.7655 8.9C15.9801 8.68544 16.2711 8.5649 16.5745 8.5649C16.878 8.5649 17.169 8.68544 17.3835 8.9C17.5981 9.11456 17.7186 9.40557 17.7186 9.709C17.7186 10.0124 17.5981 10.3034 17.3835 10.518L12.8125 15.093H12.8085Z" fill="#F94F5E" />
                                </svg>
                                <div className="downloadtemplate">Download Template</div>
                              </Button>

                            </td>
                            <td  >
                            </td>
                            <td >
                              {/* { ((this.attendanceFlag && this.prohanceFlag) ||
                                  (!monthlyView && this.startSwapWeeklyOffDataProcessingFlag)) && 
                                  (!this.state.swapweeklyoffCorrection)
                                   && (
                                    <Button
                                      onClick={this.startSwapWeeklyOffDataProcessing}
                                      color="primary"
                                      variant="contained"
                                      title="Start Processing Swap Weekly Off Correction"
                                    >
                                      Start Processing Swap Weekly Off Correction
                                    </Button>
                                  )} */}
                              {/* <Button
                                      onClick={this.startSwapWeeklyOffDataProcessing}
                                      color="primary"
                                      variant="contained"
                                      title="Start Processing Swap Weekly Off Correction"
                                    >
                                      Start Processing Swap Weekly Off Correction
                                    </Button> */}
                            </td>
                          </tr>
                          <tr className="downloadTempRow">

                          </tr>

                          <tr >
                            <td style={{ height: '50px', width: '300px' }} className="uploadCorrectedDataRow ">
                              <div className="tabhead">Upload Corrected Swap Weekly Off Data</div>
                            </td>
                          </tr>
                          <tr>
                            {!this.startSwapWeeklyOffDataProcessingFlag && (
                              <div style={{ marginTop: '10px', pointerEvents: this.state.disableSwapWeeklyOffButton ? 'none' : '' }}><Uploadbutton id="uploadSwapWeeklyOff" uploadfile={this.uploadFile} /></div>
                            )}

                          </tr>
                          <tr style={{ paddingTop: '0' }}>
                            {(this.startSwapWeeklyOffDataProcessingFlag) && (!this.state.swapweeklyoffCorrection && !this.state.swapCorrTabFlag) && (

                              <td style={{ paddingTop: '0' }}>
                                <div className="customprogressbarRow ">
                                  <Customprogressbar filename={this.state.filename} />
                                </div>
                              </td>

                            )}

                            {((this.attendanceFlag && this.prohanceFlag) ||
                              (!monthlyView && this.startSwapWeeklyOffDataProcessingFlag)) &&
                              (!this.state.swapweeklyoffCorrection && !this.state.swapCorrTabFlag)
                              && (
                                <td style={{ paddingLeft: '20px' }}>
                                  <Button
                                    onClick={this.startSwapWeeklyOffDataProcessing}
                                    color="primary"
                                    variant="contained"
                                    title="Start Processing Swap Weekly Off Correction"
                                    // style={{
                                    //   color: this.state.disableSwapWeeklyOffButton ? 'rgb(193, 186, 186)' : '',
                                    //   pointerEvents: this.state.disableSwapWeeklyOffButton ? 'none' : ''
                                    // }}
                                    style={{ opacity: this.state.disableSwapWeeklyOffButton ? "0.5" : "1",color:'white',
                          fontFamily: "Circular TT Book" ,pointerEvents: this.state.disableSwapWeeklyOffButton ? 'none' : ''}}
                                  >
                                    Start Swap Weekly off Correction
                                  </Button>
                                </td>
                              )}
                          </tr>
                          <tr className="uploadFileRow">

                          </tr>
                          <tr>
                            <td colSpan="2" id="monthlyAttendanceResponse">
                              &nbsp;
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </>
              )
            }
            {
              downloadView && (
                <div className="control-section col-md-12">
                  <div
                    className="col-12 col-xs-12 col-sm-12 col-lg-12 col-md-2 row"
                    style={{ marginBottom: 20, paddingBottom: 20 }}
                  >
                  </div>
                  <div className="col-12 col-xs-12 col-sm-12 col-lg-12 col-md-12 row">
                    <div className="col-xs-2 col-sm-2 col-lg-3 col-md-2">

                      <MuiPickersUtilsProvider
                        utils={DateFnsUtils}
                        style={{ height: 50 }}
                      >
                        <KeyboardDatePicker
                          className="downloadDateStart"
                          id="lwdCompanyRequest"
                          name="lwdCompanyRequest"
                          format="dd-MMM-yyyy"
                          value={startDate}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                          onChange={(e) => {
                            this.setState({ startDate: e, endDate: this.getOnemonthDate(new Date(e)) });
                          }}
                          // maxDate={new Date()}
                          maxDate={this.getYesterday(1)}

                          TextFieldComponent={this.TextFieldComponent}
                          inputVariant="outlined"
                          variant="inline"
                          InputAdornmentProps={{ position: "end" }}
                          autoOk
                          label="Start Date *"
                        />
                      </MuiPickersUtilsProvider>
                    </div>
                    <div className="col-xs-2 col-sm-2 col-lg-3 col-md-2">
                      <MuiPickersUtilsProvider
                        utils={DateFnsUtils}
                        style={{ height: 50 }}
                      >
                        <KeyboardDatePicker
                          className="downloadDateEnd"
                          id="lwdCompanyRequest"
                          name="lwdCompanyRequest"
                          format="dd-MMM-yyyy"
                          value={endDate}
                          minDate={
                            startDate !== null
                              ? new Date(startDate)
                              : new Date()
                          }
                          maxDate={
                            (startDate !== null && this.getOnemonthDate(new Date(startDate)) < this.getYesterday(1))
                              ?
                              this.getOnemonthDate(new Date(startDate))
                              :
                              this.getYesterday(1)
                          }
                          // maxDate={new Date()}
                          KeyboardButtonProps={{
                            "aria-label": "change date",
                          }}
                          onChange={(e) => {
                            this.setState({ endDate: e });
                          }}
                          TextFieldComponent={this.TextFieldComponent}
                          inputVariant="outlined"
                          variant="inline"
                          InputAdornmentProps={{ position: "end" }}
                          autoOk
                          label="End Date *"
                        />
                      </MuiPickersUtilsProvider>
                    </div>
                    <Button
                      color="primary"
                      variant="contained"
                      // style={{ color: !loader ? "white" : "#c1baba" }}
                      style={{ opacity: loader ? "0.5" : "1",color:'white',
                          fontFamily: "Circular TT Book" }}
                      className="dateButton"
                      title="Export Data"
                      disabled={loader}
                      onClick={this.exportDataInDateRainge}
                    >
                      Export Data
                    </Button>
                  </div>
                  <div className="col-12 col-xs-12 col-sm-12 col-lg-12 col-md-2 row">
                    <h5>
                      <span style={{ color: "rgba(0, 0, 0, .7)", paddingTop: 20 }}>
                        Note: Please select the Start date and End date to
                        export Attendance data.
                      </span>
                    </h5>
                  </div>
                </div>
              )
            }
            {

              (autoView || prohanceDownloadView) && (
                <div className="control-section col-md-12" style={{ marginTop: 20, width: '100%' }}>
                  <div style={{ marginBottom: 20 }}>
                    <div className="tabhead">{autoView ? "TIB Process Data" : "Prohance Data"}</div>
                  </div>

                  <div className="col-12 col-xs-12 col-sm-12 col-lg-12 col-md-12 " style={{ marginBottom: 20 }}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>

                      <div>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            className="autoDate"
                            id={"autoDailyDate"}
                            name={"autoDailyDate"}
                            format="dd-MMM-yyyy"
                            value={((autoView)   ? dailyDate: prohanceDate )}
                            onChange={(e) => (((autoView && !this.state.maxAutoDate)) ? this.setState({ dailyDate: e}) : this.setState({maxAutoDate: false }))}
                            maxDate={autoView ? (new Date() - (1*24*60*60*1000)) : maxProhanceDate}
                            minDate={autoView ? undefined : minProhanceDate}
                            TextFieldComponent={this.TextFieldComponent}
                            inputVariant="outlined"
                            variant="inline"
                            InputAdornmentProps={{ position: "end" }}
                            autoOk
                            label={autoView ? "Attendance Date *" : "Select Date *"}
                            placeholder=" "
                            InputLabelProps={{ shrink: true }}
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                      <div style={{ marginLeft: '30px' }}>
                        <Button
                          color="primary"
                          variant="contained"
                          style={{ opacity: (autocorrectionBtn || (dailyDate===null)) ? "0.5" : "1",color:'white',
                          fontFamily: "Circular TT Book" }}
                          className="autoDateButton"
                          title="Export Data"
                          disabled={autocorrectionBtn || dailyDate===null}
                          onClick={autoView ? this.startAutoScript : this.exportProhanceData}
                        >
                          {autoView ? "Start Process" : "Export Data"}
                        </Button>
                      </div>
                      {/* #c1baba */}
                    </div>
                  </div>


                  {/* <div className="col-12 col-xs-12 col-sm-12 col-lg-12 col-md-12 row" style={{ marginBottom: 20 }}>
                    <div className="col-xs-2 col-sm-2 col-lg-3 col-md-2">

                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                          className="autoDate"
                          id={"autoDailyDate"}
                          name={"autoDailyDate"}
                          format="dd-MMM-yyyy"
                          value={autoView ? (dailyDate || null) : setProhanceDate}
                          onChange={(e) => (autoView ? this.setState({ dailyDate: e }) : this.setState({ prohanceDate: e }))}
                          maxDate={autoView ? (new Date() ): maxProhanceDate}
                          minDate={autoView ? undefined : minProhanceDate}
                          TextFieldComponent={this.TextFieldComponent}
                          inputVariant="outlined"
                          variant="inline"
                          InputAdornmentProps={{ position: "end" }}
                          autoOk
                          label={autoView ? "Attendance Date *" : "Select Date *"}
                          placeholder=" "
                          InputLabelProps={{ shrink: true }}
                        />
                      </MuiPickersUtilsProvider>
                    </div>

                    <Button
                      color="primary"
                      variant="contained"
                      style={{ color: !autocorrectionBtn ? "white" : "#c1baba", fontFamily: "Circular TT Book" }}
                      className="autoDateButton"
                      title="Export Data"
                      disabled={autocorrectionBtn}
                      onClick={autoView ? this.startAutoScript : this.exportProhanceData}
                    >
                      {autoView ? "Start Process" : "Export Data"}
                    </Button>


                  </div> */}
                  {autoView && (
                    <div className="col-12 col-xs-12 col-sm-12 col-lg-12 col-md-2 row">
                      <h5>
                        <span style={{ color: "#000000", fontFamily: "Circular TT Book", }}>
                          Note: Please select date for which auto script to run.
                          Make sure both files (Prohance and Attendance) are available at FTP location with correct format.
                        </span>
                      </h5>
                    </div>
                  )}
                </div>
              )
            }
          </tbody >
        </table >
        {!downloadView && <div id="uploadStatus" ref={this.uploadStatusRef} className="uploadStatus recentactivitydiv" />}


        {(exportFlag) && (
          <ExportData
            monthlyView={monthlyView}
            swapweeklyoffview={swapweeklyoffview}
            authUser={this.props.authUser}
            exportData={this.exportData}
          />
        )
        }
      </div >

    );
  }
}


UploadDailyTib.propTypes = {
  authUser: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  showLoader: PropTypes.bool,
};
UploadDailyTib.defaultProps = {
  authUser: "",
  showLoader: false,
};
const mapStateToProps = ({ auth }) => {
  const { authUser, showLoader } = auth;
  return { authUser, showLoader };
};

export default connect(mapStateToProps, {})(UploadDailyTib);
