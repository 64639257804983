import { PLAN_VS_ACTUAL_MEETING } from "../redux/constant/meetingcategoryconstant"
export const urlConfig = {
  // testing
  // apiurl: "https://connecttestapi.cheersin.com/elc/ec/v1/",
  // tibrApiUrl: "https://connecttestapi.cheersin.com/elc/tibr/v1/",
  // processUrl: "https://connecttest-processapi.cheersin.com/elc/tibr/v1/",
  // tmlcApiUrl: "https://connecttestapi.cheersin.com/elc/tmlc/v1/",
  // lnaApiUrl: "https://connecttestapi.cheersin.com/elc/lna/v1/",
  // carApiUrl: "https://uataccess.cheersin.com/",
  // breApiUrl: "https://testesgbre.cheersin.com/bre",
  // helpDeskUrl: "https://testticket.cheersin.com/",

  // // DEV
  // apiurl: "https://connectdevapi.cheersin.com/elc/ec/v1/",
  // tibrApiUrl: "https://connectdevapi.cheersin.com/elc/tibr/v1/",
  // processUrl: "https://connectdevapi.cheersin.com/elc/",
  // tmlcApiUrl: "https://connectdevapi.cheersin.com/elc/tmlc/v1/",
  // lnaApiUrl: "https://connectdevapi.cheersin.com/elc/lna/v1/",
  // carApiUrl: "https://devaccess.cheersin.com/",
  // breApiUrl: "https://devesgbre.cheersin.com/bre",
  // helpDeskUrl: "https://devticket.cheersin.com/",

  // UAT
  // apiurl: "https://connectuatapi.cheersin.com/elc/ec/v1/",
  // tibrApiUrl: "https://connectuatapi.cheersin.com/elc/tibr/v1/",
  // processUrl: "https://connectuatapi.cheersin.com/elc/",
  // tmlcApiUrl: "https://connectuatapi.cheersin.com/elc/tmlc/v1/",
  // lnaApiUrl: "https://connectuatapi.cheersin.com/elc/lna/v1/",
  // carApiUrl: "https://uataccess.cheersin.com/",
  // breApiUrl: "https://uatesgbre.cheersin.com/bre",
  // helpDeskUrl: "https://uatticket.cheersin.com/",

  // LOCAl
  // apiurl: "http://localhost:3000/elc/ec/v1/",
  // tibrApiUrl: "http://localhost:3000/elc/tibr/v1/",
  // tmlcApiUrl: "http://localhost:3000/elc/tmlc/v1/",
  // lnaApiUrl: "http://localhost:3000/elc/lna/v1/",
  // carApiUrl: "https://uataccess.cheersin.com/",
  // processUrl: "http://localhost:3000/elc/",
  // breApiUrl: "https://devesgbre.cheersin.com/bre",
  // helpDeskUrl: "https://devticket.cheersin.com/",

  // LIVE    
  apiurl: "https://connectapi.cheersin.com/elc/ec/v1/",
  tibrApiUrl: "https://connectapi.cheersin.com/elc/tibr/v1/",
  tmlcApiUrl: "https://connectapi.cheersin.com/elc/tmlc/v1/",
  lnaApiUrl: "https://connectapi.cheersin.com/elc/lna/v1/",
  carApiUrl: "https://accessapi.cheersin.com/",
  breApiUrl: "https://esgbre.cheersin.com/bre",
  processUrl: "https://connect-processapi.cheersin.com/elc/",
  helpDeskUrl: "https://helpdesk.cheersin.com/",

}

export const defTimeZone = { timezone: ['IST', 'CET', 'UK'] }
export const defClientTimeZone = { clientTimeZone: ['CET', 'UK', 'EST'] }

export const appId = '60001804966b4018063d9bed';
export const appAuthPassword = 'md5hash';
export const commonAccessRightsFlag = 'FALSE';

export const defaultTimeScaleConfig = 30;
export const defFirstDayOfWeek = 1;
export const defEnvironment = "LIVE"; // UAT, LIVE, QA
export const defTimeScrollTo = "10:00";
export const defMeetingCategory = PLAN_VS_ACTUAL_MEETING;
export const defworkDays = [1, 2, 3, 4, 5];
export const timeScaleRadio = [5, 10, 15, 30, 60];

export const defWorkHourStart = "10:00";
export const defWorkHourEnd = "20:00";
export const defMaxClientContact = 20;
export const defPnlId = 3;
const weekEndDay = (new Date().getDay() === 7 ? 6 : 7);
export const defActualDaysBefore = (new Date().getDay() === 6 ? 5 : weekEndDay);
export const actualMettingDayDifference = (new Date().getDay() === 6 ? 5 : weekEndDay);
export const defRecurringMaximumCount = 200;
export const defNotificationSetInterval = 300000;
export const defOthersCheckAvailability = 10;
export const defMeetingRoomLimit = 2;
// Allowed file types for uploading
export const defFileType = ['image/jpeg', 'application/pdf', 'image/jpg'];
export const DateFilterformat = { type: 'datetime', format: 'dd-MMM-yy' };
export const defNotificationForEveryMeetingLimit = 5;
export const defNotificationForSheduledMeetingLimit = 5;
export const corsallow = "";// "https://cors-anywhere.herokuapp.com/";
export const addCostSharingLimit = 5;
export const addMaOkrObjectiveLimit = 5;
export const addMaOkrKPILimit = 15;
export const addBusinessOkrOkrKPILimit = 5;
export const webSocketURI = "https://pushnotification.cheersin.com";
export const defaultMeetingRoom = [87];
export const maxMonthRangeForTibAvgReport = 12;
export const maxFileSize = 10000;
export const CPO = 5;
export const serviceUrl = 'https://pdfviewer.cheersin.com/api/pdfviewer'
export const NoOfRowsPerPage = [10, 20, 50, 100, 200, 300, 500, 'All']
export const UserType = [1, 2, 3, 4];
export const minFileSize = 1;
export const GOJSLICENSE = "73f944eab46131b700ca0d2b113f69ef1bb37f3b9ed71ba7595546f1ea08681c3089ef7001d38bc981f91aaf187bc0dd8f956c7c9e4f013ee132d3d446ea84ade13477e7430e45daa2032ec4cca92ba1ac7b75f0c3e765b2dc2ddcf4ebfa939d4ef8f0d54bc811b8367804";