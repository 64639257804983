import React from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { Button, Dialog } from "@material-ui/core";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import IconButton from '@material-ui/core/IconButton';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { withStyles } from '@material-ui/core/styles';
import ReactHtmlParser from 'react-html-parser'
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { DialogContent } from "../customdialog/customdialog";
import EditKPI from "./createkpi";
import CreateObjective from "./createobject"
import { enableRipple } from '@syncfusion/ej2-base';
import "./newobjectkpi.css";
import OKRServices from '../../services/okr/okrservices';
import { urlConfig } from '../../config/config';
import { getDisplayDateOnly } from '../../app/other/commonfunction';
import { approvalPopupHeaderMessage, approvalPopupSubMessage, rejectPopupSubMessage,
    // objdeleteConfernamation, KpideleteConfernamation,
    rjectPopupHeaderMessage } from '../../assets/constants/constants';
import { approveRejectObjectveKpi, addUpdateObjectveKpi, submitApprovalAction } from '../../redux/actions/okr/okractions';
import fileIcon from "../../../src/assets/svg/fileIcon_jpeg.png";
import editIcon from "../../../src/assets/svg/ic-Edit.svg";
import ConfirmationPopup from "../../utilities/confirmationpopup";
enableRipple(true);
// eslint-disable-next-line
const StyledMenu = withStyles({
    paper: {
        border: '1px solid #d3d4d5',
    },
})((props) => (
    <Menu
        elevation={0}
        getContentAnchorEl={null}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}
        {...props}
    />
));
// eslint-disable-next-line
const StyledMenuItem = withStyles((theme) => ({
    root: {
        '&:focus': {
            backgroundColor: theme.palette.primary.main,
            '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
                color: theme.palette.common.white,
            },
        },
    },
}))(MenuItem);
const cssForScroll = `
::-webkit-scrollbar {
    width: 24px;
  }

  ::-webkit-scrollbar-thumb {
    background: white;
    border-right: 13px #8080804f  solid;
    background-clip: padding-box;
  }
`
class PendingForApprovalObjKPI extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openMenu: false,
            openMenuKPI: false,
            heightLayout: 5,
            // pendingForApprovalobjectiveData: [],
            addKPI: false,
            editObjective: false,
            kpiType: '',
            openPopup: false,
            approvalStatus: "",
            objectiveId: 0,
            deleteObjKPI: false
        }
        this.objKpiDeletetitle = "";
        this.objKpiDeletetitleMessage = "";
        this.option = [
            'Edit',
            'Delete',
        ]
        this.menuItems = [
            {
                iconCss: 'fas fa-edit',
                items: [
                    { text: 'Edit', iconCss: 'fas fa-edit' },
                    { text: 'Delete', iconCss: 'fas fa-trash-alt' },
                ],
            },
        ];
    }
    updateDimensions() {
        const updateHeight = window.innerHeight - 240;
        this.setState({ heightLayout: updateHeight });
    }
    componentDidMount() {
        this.updateDimensions();
        this.getPendingForApprovalObjectiveDetails();
        window.addEventListener("resize", this.updateDimensions.bind(this));
    }
    handleClose = () => {
        this.props.addUpdateObjectveKpi({ objId: 0, objectiveName: "", objectiveTags: [], visibility: "", levelL: [],objKpiAddUpdateType:"" });
        this.setState({ openMenu: false });
    }
    handleCloseKPI = () => {
        this.props.addUpdateObjectveKpi({ objId: 0, kpiId: 0, kpiName: "", kpiDescription: "", kpiAttachment: [], kpiNewTags: [], objKpiAddUpdateType: "" });
        this.setState({ openMenuKPI: false });
    }
    handleClickObjective = (e, objdata) => {
        const { objectiveId, objectiveName, objectiveTags, objectiveVisibilityId, levelId } = objdata;
        this.props.addUpdateObjectveKpi({ objId: objectiveId, objectiveName: objectiveName, objectiveTags: objectiveTags !== null ? objectiveTags.split('#') : [], visibility: objectiveVisibilityId, levelL: levelId !== null || levelId !== '' ? levelId.split(',').map(Number) : [], objKpiAddUpdateType: 'PendingForApproval' });
        this.setState({ openMenu: true, anchorEl: e.currentTarget });
    }
    handleClickKPI = (e, kpiData) => {
        const { objectiveId, kpiId, kpiName, kpiTags, description, kpiAttachment } = kpiData;
        this.setState({ openMenuKPI: true, anchorElKPI: e.currentTarget });
        this.props.addUpdateObjectveKpi({ objId: objectiveId, kpiId: kpiId, kpiName: kpiName, kpiDescription: description, kpiAttachment: kpiAttachment, kpiNewTags: kpiTags !== null ? kpiTags.split('#') : [], objKpiAddUpdateType: "PendingForApproval" });
    }
    closeAddKPI = () => {
        this.setState({ addKPI: false, kpiType: '' });
        this.props.addUpdateObjectveKpi({ objId: 0, kpiId: 0, kpiName: "", kpiDescription: "", kpiAttachment: [], kpiNewTags: [], objKpiAddUpdateType: "" });
    }
    openAddKPI = (data, type) => {
        this.setState({ openMenuKPI: false, addKPI: true, kpiType: "Edit" });
    }
    closeAddNewObjective = () => {        
        this.setState({ editObjective: false });
        this.props.addUpdateObjectveKpi({ objId: 0, objectiveName: "", objectiveTags: [], visibility: "", levelL: [],objKpiAddUpdateType:"" });
    }
    openAddNewObjective = () => {
        this.setState({ editObjective: true, openMenu: false });
    }
    getPendingForApprovalObjectiveDetails = () => {
        const { authUser } = this.props;
        const OkrObjectiveData = Promise.resolve(
            OKRServices.getPendingForApproval(authUser)
        );
        OkrObjectiveData.then((result) => {
            if (result.Result !== undefined) {
                this.props.addUpdateObjectveKpi({
                    pendingForApprovalObjectiveData: result.Result,
                });
            }
        });
    };
    openPopUp = (approvalStatus, data) => {
        this.setState({ openPopup: true, approvalStatus: approvalStatus, objectiveId: data.objectiveId })
    }
    handleConfirmClose = () => {
        this.setState({ openPopup: false, approvalStatus: "", objectiveId: 0 })
    }
    handleStatusYes = () => {
        const { authUser } = this.props;
        const { objectiveId, approvalStatus } = this.state;
        const data = {
            objectiveId: objectiveId,
            userId: authUser,
            approverType: "HEAD",
            approvalStatus: approvalStatus === "approve" ? "1" : "2",
        };
        // eslint-disable-next-line
        this.props.approveRejectObjectveKpi(data);
        this.handleConfirmClose();
    }
    handleStatusNo = () => {
        this.handleConfirmClose();
    }
    //WARNING! To be deprecated in React v17. Use new lifecycle static getDerivedStateFromProps instead.
    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.refreshPendingStatus === "updated") {
            this.props.addUpdateObjectveKpi({ refreshPendingStatus: "" });
        }
        if (nextProps.cancelPendingApproval === "cancel") {
            this.getPendingForApprovalObjectiveDetails();
            this.closeAddNewObjective();
            this.closeAddKPI();
            this.props.addUpdateObjectveKpi({ cancelPendingApproval: "" });
        }
    }
    render() { // NOSONAR
        const { openMenu, openMenuKPI, heightLayout, addKPI, editObjective, anchorEl, anchorElKPI, kpiType, openPopup, approvalStatus } = this.state;
        const { employeeId, pendingForApprovalObjectiveData, okrMastersVisibility, levelMaster } = this.props;
        return (
            <>
                <div>
                    <div className="row" style={{ margin: "0px -16px 8px -15px" }}>
                        <div className="col-xs-10 col-sm-10 col-lg-10 col-md-10" style={{ paddingTop: "8px" }}>
                            <span className="draft-name">Pending for Approval ({pendingForApprovalObjectiveData.length})</span>
                        </div>
                    </div>
                    <style>{cssForScroll}</style>
                    <div style={{ height: heightLayout, overflowY: "auto", overflowX: "hidden"}}>

                        {
                            pendingForApprovalObjectiveData.length > 0 &&
                            pendingForApprovalObjectiveData.map((obj, ind) => (
                                <>
                                    <Accordion id={`pendingObjs${ind}`} 
                                               style={{ 
                                               marginTop: ind===0 ?"0px":" 16px", 
                                               marginBottom: pendingForApprovalObjectiveData.length ===ind+1 ?"0px":" 16px", 
                                            //    margin: "16px 0px 16px 0px", 
                                               boxShadow: "none", position: "inherit" }}>
                                        <AccordionSummary style={{ background: "#F2F2F2", paddingLeft: "0px", minHeight: "30px" }}
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-label="Expand"
                                            aria-controls="additional-actions1-content"
                                            id="additional-actions1-header"
                                        >
                                            <div className="col-xs-12 col-sm-8 col-lg-7 col-md-7 bold-header-obj-kpi" style={{ padding: "14px" }}>
                                                {obj.objectiveName}
                                            </div>
                                            <div className="col-xs-12 col-sm-3 col-lg-4 col-md-4 tags-obj-kpi" style={{ paddingTop: "10px" }}> {obj.objectiveTags} </div>
                                            <div className="col-xs-12 col-sm-1 col-lg-1 col-md-1">
                                                <FormControlLabel style={{ margin: "0px", float: "right" }}
                                                    control={
                                                        <div>
                                                            <IconButton
                                                                onClick={(e) => this.handleClickObjective(e, obj)}
                                                            >
                                                                <MoreVertIcon />
                                                            </IconButton>
                                                            <Menu
                                                                id={`objectId${obj.objectiveId}`}
                                                                keepMounted
                                                                anchorEl={anchorEl}
                                                                open={Boolean(openMenu)}
                                                                onClose={this.handleClose}
                                                                style={{ margin: "50px 0px 0px -80px" }}
                                                            >
                                                                <MenuItem id={`obj${obj.objectiveId}`} onClick={this.openAddNewObjective}>
                                                                    <span style={{ padding: "8px 8px 8px 0px" }}>
                                                                        <img
                                                                            alt=""
                                                                            src={editIcon}

                                                                        />
                                                                    </span> <span id={`FormControlLabel${ind}`} style={{ padding: "0px 32px 0px 0px" }} >Edit</span></MenuItem>
                                                                {/* <MenuItem key={`deleteObj${ind}`} onClick={(e) => this.deleteObjectivePopup("DeleteObj")}>
                                                                    <span style={{ padding: "8px 8px 8px 0px" }}>
                                                                        <img
                                                                            alt=""
                                                                            src={deleteIcon}

                                                                        /></span> <span> Delete </span></MenuItem> */}
                                                            </Menu>
                                                        </div>
                                                    }
                                                    onClick={(event) => event.stopPropagation()}
                                                    onFocus={(event) => event.stopPropagation()}
                                                />
                                            </div>
                                        </AccordionSummary>
                                        <AccordionDetails style={{ background: "#8080801c", padding: "0px 0px 8px 0px", minHeight:'30px'}}>
                                            <div className="col-xs-12 col-sm-12 col-lg-12" style={{ padding: "0px 16px 0px 16px" }}>
                                                <span className="normal-visivbility-level-obj-kpi">Visibility :</span><span className="kpi-footer-details" > {obj.objectiveVisibility}</span>
                                                {
                                                    obj.levelName !== "" && obj.levelName !== null &&
                                                    <>
                                                        <span className="normal-visivbility-level-obj-kpi">Level :</span><span className="kpi-footer-details" > { `${obj.levelName.split(',')[0]}`}{obj.levelName.split(',').length > 1 ? ` + ${obj.levelName.split(',').length - 1} more`:""}</span>
                                                    </>
                                                }
                                            </div>
                                        </AccordionDetails>
                                        {
                                            obj.kpis.length > 0 &&
                                            obj.kpis.map((kpiData, kpiInd) => (
                                                <Accordion defaultExpanded={false} style={{ margin: "0px", position: "inherit", borderLeft: "1px solid #00000012", borderRight: "1px solid #00000012", borderBottom: "1px solid #00000012" }}>
                                                    <AccordionSummary style={{ minHeight: "30px"}}
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-label="Expand"
                                                        aria-controls="additional-actions1-content"
                                                        id="additional-actions1-header"
                                                    >
                                                        <div className="col-xs-12 col-sm-8 col-lg-7 col-md-7 bold-header-obj-kpi" style={{ padding: "14px 14px 14px 3px" }}>
                                                            {kpiData.kpiName}
                                                        </div>
                                                        <div className="col-xs-12 col-sm-3 col-lg-4 col-md-4 tags-obj-kpi" style={{ padding: "14px 14px 14px 10px" }}> {kpiData.kpiTags} </div>
                                                        <div className="col-xs-12 col-sm-1 col-lg-1 col-md-1">
                                                            <FormControlLabel style={{ margin: "0px", float: "right" }}
                                                                // aria-label="Acknowledge"
                                                                control={
                                                                    <div>
                                                                        <IconButton
                                                                            id={`${kpiData.kpiId}`}
                                                                            onClick={(e) => this.handleClickKPI(e, kpiData)}
                                                                        >
                                                                            <MoreVertIcon />
                                                                        </IconButton>
                                                                        <Menu
                                                                            id="kpiId"
                                                                            keepMounted
                                                                            anchorEl={anchorElKPI}
                                                                            open={Boolean(openMenuKPI)}
                                                                            onClose={this.handleCloseKPI}
                                                                            style={{ margin: "50px 0px 0px -80px" }}
                                                                        >
                                                                            <MenuItem key={`editKpi${kpiInd}`} onClick={(e) => this.openAddKPI(kpiData, "Edit")}>
                                                                                <span style={{ padding: "8px 8px 8px 0px" }}>
                                                                                    <img
                                                                                        alt=""
                                                                                        src={editIcon}

                                                                                    />
                                                                                </span> <span style={{ padding: "0px 32px 0px 0px" }}>Edit</span></MenuItem>
                                                                            {/* <MenuItem key={`deleteKPI${kpiInd}`} onClick={(e) => this.deleteObjectivePopup("DeleteKPI")}>
                                                                                <span style={{ padding: "8px 8px 8px 0px" }}>
                                                                                    <img
                                                                                        alt=""
                                                                                        src={deleteIcon}

                                                                                    /></span> <span> Delete </span></MenuItem> */}
                                                                        </Menu>
                                                                    </div>
                                                                }
                                                                onClick={(event) => event.stopPropagation()}
                                                                onFocus={(event) => event.stopPropagation()}
                                                            />
                                                        </div>
                                                    </AccordionSummary>
                                                    <AccordionDetails style={{ background: "rgb(72 184 231 / 5%)", padding: "8px 18px 8px 18px" }}>
                                                        <div className="row">
                                                            <div className="col-xs-12 col-sm-12 col-lg-12" style={{ color: "#808080a8", paddingBottom: "8px" }}> Description </div>
                                                            <div className="col-xs-12 col-sm-12 col-lg-12">
                                                                {ReactHtmlParser(kpiData.description)}
                                                            </div>
                                                            <div className="col-xs-12 col-sm-12 col-lg-12" style={{ padding: "8px 14px 0px 14px" }}>
                                                                {
                                                                    kpiData.kpiAttachment.length > 0 ?
                                                                        kpiData.kpiAttachment.split(",").map((name, inx) => (
                                                                            <>
                                                                                <a
                                                                                    target="_blank"
                                                                                    href={
                                                                                        kpiData.kpiAttachment !== null && kpiData.kpiAttachment !== ""
                                                                                            ? `${urlConfig.tmlcApiUrl}document/kpi/${employeeId}/${name}`
                                                                                            : ""
                                                                                    }
                                                                                    rel="noopener noreferrer"
                                                                                    download
                                                                                    style={{textDecoration:'none'}}
                                                                                >
                                                                                    <img
                                                                                        alt=""
                                                                                        src={fileIcon}

                                                                                    />
                                                                                    <span style={{ padding: "0px 8px 0px 8px", color:"#f94f5e" }} title={name}>
                                                                                        {name.substring(11,name.length).length > 35 ? name.substring(11,name.length).substr(0, 32)+'...' : name.substring(11,name.length)}
                                                                                    </span>
                                                                                </a>
                                                                            </>
                                                                        ))
                                                                        :
                                                                        (
                                                                            null
                                                                        )
                                                                }

                                                            </div>
                                                        </div>
                                                    </AccordionDetails>
                                                </Accordion>
                                            ))
                                        }
                                        <AccordionSummary style={{ background: "#F2F2F2", paddingLeft: "0px", minHeight: "30px", margin: "-1px", cursor: "auto", height: "48px" }}>
                                            <div className="col-xs-12 col-sm-12 col-lg-12">
                                                <div style={{ float: 'left', padding: "10px 0px 10px 0px" }}>
                                                    <span className="normal-visivbility-level-obj-kpi">Created by :</span><span className="kpi-footer-details" >{obj.createdBy}</span><span style={{ paddingRight: "12px", borderLeft: "2px solid #DBDBDD" }} />
                                                    <span className="normal-visivbility-level-obj-kpi">Created Date :</span><span className="kpi-footer-details" >{getDisplayDateOnly(new Date(obj.createdOn))}</span><span style={{ paddingRight: "12px", borderLeft: "2px solid #DBDBDD" }} />
                                                    <span className="normal-visivbility-level-obj-kpi">Approver :</span><span className="kpi-footer-details">{obj.approvedBy}</span>
                                                </div>
                                                <div className="row" style={{ float: "right", padding: "8px 0px 0px 0px" }}>
                                                    <Button
                                                        style={{ width: "96px", height: "26px", padding:"6px" }}
                                                        className="mr-sm cancel-button"
                                                        variant="contained"
                                                        onClick={() => this.openPopUp('reject', obj)}
                                                    >
                                                        Reject
                                                </Button>
                                                    <Button
                                                        style={{ width: "96px", height: "26px",padding:"6px" }}
                                                        className="remove-button-shadow"
                                                        color="primary"
                                                        variant="contained"
                                                        onClick={() => this.openPopUp('approve', obj)}
                                                    >
                                                        Approve
                                                </Button>
                                                </div>
                                            </div>
                                        </AccordionSummary>
                                    </Accordion>
                                </>
                            ))
                        }
                    </div>
                    <div>
                        <Dialog
                            open={addKPI}
                            fullWidth
                            width="500"
                            maxWidth="sm"
                        >
                            <DialogContent>
                                <div>
                                    <div>
                                        <span className="add-new-self-header" style={{ paddingLeft: "5px" }}>{kpiType} Key Result </span>
                                        <EditKPI closeAddKPI={() => this.closeAddKPI} />
                                    </div>
                                </div>
                            </DialogContent>
                        </Dialog>
                    </div>
                    <div>
                        <Dialog
                            open={editObjective}
                            fullWidth
                            width="500"
                            maxWidth="sm"
                        >
                            <DialogContent>
                                <div>
                                    <div>
                                        <span className="add-new-self-header" style={{ paddingLeft: "8px" }}>Edit Objective </span>
                                        <CreateObjective closeCreateObj={() => this.closeAddNewObjective} okrMastersVisibility={okrMastersVisibility} levelMaster={levelMaster} />
                                    </div>
                                </div>
                            </DialogContent>
                        </Dialog>
                    </div>
                </div>

                <ConfirmationPopup
                    showButton={{ type: "1" }}
                    open={openPopup}
                    button1function={() => this.handleStatusYes()}
                    button2function={() => this.handleStatusNo()}
                    headerMessage={approvalStatus === "reject" ? rejectPopupSubMessage : approvalPopupSubMessage}
                    button1Text={"Yes"}
              button2Text={"No"}
              handleConfirmClose={this.handleConfirmClose}
                />  
                {/* <ConfirmDialog
                    open={deleteObjKPI}
                    handleConfirmYes={this.handleDeleteYes}
                    handleConfirmNo={this.handleDeleteNo}
                    handleConfirmClose={this.handleDeleteNo}
                    button2Caption={"No"}
                    button1Caption={"Yes"}
                    title={this.objKpiDeletetitle}
                    message={this.objKpiDeletetitleMessage}
                /> */}
            </>
        );
    }
}
PendingForApprovalObjKPI.propTypes = {
    authUser: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    employeeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),   
    kpiId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),   
    objId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),   
    refreshPendingStatus: PropTypes.string,
    pendingForApprovalObjectiveData: PropTypes.arrayOf(PropTypes.object),
    closAlleDialog: PropTypes.string,
    objKpiAddUpdateType: PropTypes.string,
    cancelPendingApproval: PropTypes.string,
};
PendingForApprovalObjKPI.defaultProps = {
    authUser: "",
    employeeId: "",
    refreshPendingStatus: "",
    pendingForApprovalObjectiveData: [],
    closAlleDialog: "",
    objKpiAddUpdateType: "",
    cancelPendingApproval: "", 
    kpiId:0,
    objId:0,
};
const mapStateToProps = ({ auth, okrreducer }) => {
    const { authUser } = auth;
    const { employeeId, refreshPendingStatus, pendingForApprovalObjectiveData, closAlleDialog, objKpiAddUpdateType, cancelPendingApproval,kpiId,objId } = okrreducer;
    return {
        authUser,
        employeeId,
        refreshPendingStatus,
        pendingForApprovalObjectiveData,
        closAlleDialog,
        objKpiAddUpdateType,
        cancelPendingApproval,
        kpiId,
        objId
    };
};

export default connect(mapStateToProps, {
    addUpdateObjectveKpi,
    approveRejectObjectveKpi,
    submitApprovalAction
})(PendingForApprovalObjKPI);
