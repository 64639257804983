import { all, call, fork, takeEvery, put } from "redux-saga/effects";
import axios from "axios";
import { urlConfig } from "../../../config/config";
import { getNotify } from "../../../app/other/commonfunction";
import { updateLnAData } from "../../actions/lna/lnaaction";

import {
  SAVE_HOLIDAY_CLUSTER,
  SAVE_OPTIONAL_HOLIDAY_CLUSTER,
  APPLY_LEAVE,
  ADD_EDIT_WORKINGDAY_CLUSTER_DATA,
  ADD_EDIT_COLOUR_CLUSTER_DATA,
  ADD_EDIT_ROLE_CLUSTER_DATA,
  ADD_EDIT_STATUTORY_CLUSTER_DATA,
  ADD_EDIT_EXCEPTIONAL_CLUSTER_DATA,
  SAVE_ATTENDANCE_CLUSTER,
  SAVE_SOURCE_CLUSTER,
  SAVE_DAY_BREAK_CLUSTER,
  SAVE_LEAVE_CLUSTER,
  SAVE_WEEKDAY_CLUSTER,
  SAVE_WORK_DAY_WINDOW_CLUSTER,
  SAVE_SYMBOL,
  SAVE_LEAVE_TRANSFER_DATA,
  SAVE_REGULARIZATION_DATA,
  SAVE_COMPOFF_DATA,
  CHANGE_LEAVE_APPROVAL_STATUS,
  SAVE_LEAVE_CANCEL_REASON,
  UPDATE_MASTER_ITEM_STATUS,
  SAVE_WEEK_OFF_SWAP_DATES,
  ADD_NOTIFICATION,
  SAVE_LOCK_UNLOCK_CLUSTER,
  SAVE_LEAVE_YEAR_CLUSTER,
  SAVE_PARTIAL_LEAVE_CANCEL_REASON,
  SAVE_WORKDAY_DAY_BREAK_MAP,
  SAVE_LEAVE_YEAR,
} from "../../constant/lna/lnaconstant";
import {
  compOffAddMessage,
  compOffInactivatedMessage,
  compOffEditedMessage,
  exceptionalClusterActiveMessage,
  exceptionalClusterAddMessage,
  exceptionalClusterEditMessage,
  exceptionalClustereInactiveMessage,
  colourInactivatedSuccessfully,
  colourActivatedSuccessfully,
  colourModifiedSuccessfully,
  colourAddedSuccessfully,
  roleModifiedSuccessfully,
  roleAddedSuccessfully,
  ERROR,
  SUCCESS,
  attendanceActiveMessage,
  attendanceInActiveMessage,
  attendanceClusterAddMessage,
  attendanceClusterEditMessage,
  dayBreakClusterAddMessage,
  dayBreakClusterEditMessage,
  dayBreakClusterActiveMessage,
  dayBreakClustereInactiveMessage,
  leaveModuleAddMessage,
  leaveModuleEditMessage,
  leaveModuleActiveMessage,
  leaveModuleInactiveMessage,
  optionalHolidayClusterAddMessage,
  optionalHolidayClusterEditMessage,
  optionalHolidayActiveMessage,
  optionalHolidayInActiveMessage,
  holidayClusterAddMessage,
  holidayActiveMessage,
  holidayInActiveMessage,
  holidayClusterEditMessage,
  statutoryClusterAddMessage,
  statutoryClusterEditMessage,
  statutoryClusterActiveMessage,
  statutoryClustereInactiveMessage,
  workingdayClusterAddMessage,
  workingdayClusterEditMessage,
  workingdayClusterActiveMessage,
  workingdayClustereInactiveMessage,
  workdayClusterAddMessage,
  workdayClusterEditMessage,
  workdayClusterActiveMessage,
  workdayClustereInactiveMessage,
  weekdayClusterAddMessage,
  weekdayClusterEditMessage,
  weekdayClusterActiveMessage,
  weekdayClustereInactiveMessage,
  symbolClusterAddMessage,
  symbolClusterEditMessage,
  symbolClusterActiveMessage,
  symbolClustereInactiveMessage,
  leaveApproved,
  leaveRejected,
  sourceClusterActiveMessage,
  sourceClusterInactiveMessage,
  leaveTransferaddedMessage,
  regularizationUpdateMessage,
  regularizationAddMessage,
  regularizationCancelMessage,
  memberYearAddMessage,
  memberYearEditMessage,
  memberYearActiveMessage,
  memberYearInactiveMessage,
  sourceClusterAddMessage,
  sourceClusterEditMessage,
  roleClusterActiveMessage,
  roleClustereInactiveMessage,
  lockUnlockInActiveMessage,
  lockUnlockActiveMessage,
  lockUnlockClusterAddMessage,
  lockUnlockClusterEditMessage,
  leaveYearActiveMessage,
  leaveYearInActiveMessage,
  leaveYearClusterAddMessage,
  leaveYearClusterEditMessage,
  workingdayDayBreakMapActiveMessage,
  workingdayDayBreakMapInactiveMessage,
  workdayDayBreakEditMessage,
  workdayDayBreakAddMessage,
} from "../../../assets/constants/constants";
import {
  ATTENDANCE,
  WORKDAY_WINDOW,
  WEEKDAY,
  DAYBREAK,
  ATTENDANCE_SOURCE,
  LEAVE,
  HOLIDAY,
  OPTIONAL_HOLIDAY,
  STATUTORY,
  EXCEPTIONAL_ATTENDANCE,
  WORKING_DAY,
  COLOUR,
  ROLE,
  SYMBOL,
  LOCK_UNLOCK,
  LEAVE_YEAR,
  LEAVE_YEAR_CONFIG,
  WORKDAY_DAYBREAK_MAPPING,
} from "../../../redux/constant/meetingcategoryconstant";

const saveHolidayClusterApiReq = async (data) => {
  let id = data.holidayMapID ? data.holidayMapID : 0;
  delete data.holidayMapID;
  return axios
    .post(`${urlConfig.lnaApiUrl}holiday/${id}`, data, {
      headers: {
        authorization: localStorage.getItem("jwtToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((result) => result)
    .catch((error) => error.response);
};

const saveOptionalHolidayClusterApiReq = async (data) => {
  let id = data.optionalHolidayClusterUid ? data.optionalHolidayClusterUid : 0;
  delete data.optionalHolidayClusterUid;
  return axios
    .post(`${urlConfig.lnaApiUrl}optionalholiday/${id}`, data, {
      headers: {
        authorization: localStorage.getItem("jwtToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((result) => result)
    .catch((error) => error.response);
};

const applyLeaveDetailsRequest = async (data) => {
  //
  let url = "";
  let leaveAppId = data.leaveApplicationId;
  let isSpecialAttendance = data.isSpecialAttendance;
  delete data.leaveApplicationId;
  delete data.isSpecialAttendance;
  if (leaveAppId !== null && leaveAppId !== 0) {
    url = `${urlConfig.lnaApiUrl}leaveapply/${leaveAppId}${isSpecialAttendance ? "?isSpecialAttendance=2" : ""
      }`;
  } else {
    url = `${urlConfig.lnaApiUrl}leaveapply${isSpecialAttendance ? "?isSpecialAttendance=2" : ""
      }`;
  }
  return axios
    .post(url, data, {
      headers: {
        authorization: localStorage.getItem("jwtToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((result) => result)
    .catch((error) => error.response);
};

function* saveHolidayClusterDetails({ payload }) {
  const saveHolidayClusterStatus = yield call(
    saveHolidayClusterApiReq,
    payload
  );
  if (saveHolidayClusterStatus.status === 200) {
    if (payload.type === "status" && payload.isActive === "1") {
      getNotify(SUCCESS, holidayActiveMessage);
      yield put(updateLnAData({ lnaMasterFlag: "updated", holidayLoader: false }));
    } else if (payload.type === "status" && payload.isActive === "0") {
      getNotify(SUCCESS, holidayInActiveMessage);
      yield put(updateLnAData({ lnaMasterFlag: "updated", holidayLoader: false }));
    } else if (payload.type === "ADD") {
      getNotify(SUCCESS, holidayClusterAddMessage);
      yield put(updateLnAData({ lnaMasterFlag: "updated", holidayLoader: false }));
    } else if (payload.type === "EDIT") {
      getNotify(SUCCESS, holidayClusterEditMessage);
      yield put(updateLnAData({ lnaMasterFlag: "updated", holidayLoader: false }));
    }
  } else if (saveHolidayClusterStatus.status === 400) {
    getNotify(
      ERROR,
      saveHolidayClusterStatus.data.message.replace("payload: ", "")
    );
    yield put(updateLnAData({ holidayLoader: false }));
  }
}

function* saveOptionalHolidayClusterDetails({ payload }) {
  const saveOptionalHolidayClusterStatus = yield call(
    saveOptionalHolidayClusterApiReq,
    payload
  );
  if (saveOptionalHolidayClusterStatus.status === 200) {
    if (payload.type === "ADD") {
      getNotify(SUCCESS, optionalHolidayClusterAddMessage);
    } else if (payload.type === "EDIT") {
      getNotify(SUCCESS, optionalHolidayClusterEditMessage);
    }
    yield put(updateLnAData({ lnaMasterFlag: "updated" }));
  } else {
    getNotify(
      ERROR,
      saveOptionalHolidayClusterStatus.data.message.replace("payload: ", "")
    );
  }
}

function* applyLeaveDetails({ payload }) {
  // NOSONAR //
  let id = payload.leaveApplicationId;
  let isSpecialAttendance = payload.isSpecialAttendance;
  let sendNotification = true;

  if (
    !(
      payload.sendNotification === null ||
      typeof payload.sendNotification === "undefined"
    )
  ) {
    sendNotification = payload.sendNotification;
    delete payload.sendNotification;
  }
  const leaveApply = yield call(applyLeaveDetailsRequest, payload);
  if (leaveApply.status === 200) {
    if (payload.reason === "Festival holiday apply" && sendNotification) {
      getNotify(
        SUCCESS,
        isSpecialAttendance
          ? "Special attendance(s) applied successfully."
          : "Festival holiday leave(s) applied successfully."
      );
    } else if (payload.reason !== "Festival holiday apply") {
      if (id > 0) {
        getNotify(
          SUCCESS,
          isSpecialAttendance
            ? "Special attendance(s) edited successfully."
            : "Leave(s) edited successfully."
        );
      } else {
        getNotify(
          SUCCESS,
          isSpecialAttendance
            ? "Special attendance(s) applied successfully."
            : "Leave(s) applied successfully."
        );
      }
    }
    if (payload.reason === "Festival holiday apply") {
      yield put(
        updateLnAData({
          backgroundSyncId: leaveApply.data.Result[0].leaveAppId,
        })
      );
    } else {
      yield put(
        updateLnAData({
          leaveApplicationId: leaveApply.data.Result[0].leaveAppId,
          backgroundSyncId: leaveApply.data.Result[0].leaveAppId,
        })
      );
    }

    yield put(
      updateLnAData({
        holidayLoadingStatus: sendNotification ? "updated" : "",
        leaveApplyStatus: "updated",
        leaveBalanceStatus: "updated",
      })
    );
  } else {
    getNotify(ERROR, leaveApply.data.message.replace("payload: ", ""), 8000);
    if ((payload.reason === "Festival holiday apply" && sendNotification) || payload.reason !== "Festival holiday apply") {
      yield put(
        updateLnAData({
          holidayLoadingStatus: "updated",
          leaveApplyStatus: "failed",
        })
      );
    }

  }
}
export function* saveHolidayCluster() {
  yield takeEvery(SAVE_HOLIDAY_CLUSTER, saveHolidayClusterDetails);
}

export function* saveOptionalHolidayCluster() {
  yield takeEvery(
    SAVE_OPTIONAL_HOLIDAY_CLUSTER,
    saveOptionalHolidayClusterDetails
  );
}

/**
 * @author Vivek Khodade
 * @description Apply Leave
 */
export function* applyLeave() {
  yield takeEvery(APPLY_LEAVE, applyLeaveDetails);
}

// Author  @  AMit Kumar

const saveWorkingdayClusterDataRequest = async (data) => {
  let id = data.workingdayClusterUid ? data.workingdayClusterUid : 0;
  delete data.workingdayClusterUid;

  return axios
    .post(`${urlConfig.lnaApiUrl}workingday/${id}`, data, {
      headers: {
        authorization: localStorage.getItem("jwtToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((result) => result)
    .catch((error) => error.response);
};

function* saveWorkingdayClusterData({ payload }) {
  const workingdayClusterDetails = yield call(
    saveWorkingdayClusterDataRequest,
    payload
  );
  if (workingdayClusterDetails.status === 200) {
    if (payload.type === "ADD") {
      getNotify(SUCCESS, workingdayClusterAddMessage);
    } else if (payload.type === "EDIT") {
      getNotify(SUCCESS, workingdayClusterEditMessage);
    }
    yield put(updateLnAData({ lnaMasterFlag: "updated" }));
  } else {
    getNotify(ERROR, workingdayClusterDetails.data.message);
  }
}
export function* addEditWorkingdayClusterData() {
  yield takeEvery(ADD_EDIT_WORKINGDAY_CLUSTER_DATA, saveWorkingdayClusterData);
}

// Author  @  AMit Kumar

const saveColourClusterDataRequest = async (data) => {
  let id = data.colorUid ? data.colorUid : 0;
  delete data.colorUid;
  return axios
    .post(`${urlConfig.lnaApiUrl}color/${id}`, data, {
      headers: {
        authorization: localStorage.getItem("jwtToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((result) => result)
    .catch((error) => error.response);
};

// Amit Kumar
function* saveColourClusterData({ payload }) {
  const colourClusterDetails = yield call(
    saveColourClusterDataRequest,
    payload
  );
  if (colourClusterDetails.status === 200) {
    if (payload.type === "status" && payload.isActive === "1") {
      getNotify(SUCCESS, colourActivatedSuccessfully);
    } else if (payload.type === "status" && payload.isActive === "0") {
      getNotify(SUCCESS, colourInactivatedSuccessfully);
    } else if (payload.type === "ADD") {
      getNotify(SUCCESS, colourAddedSuccessfully);
    } else if (payload.type === "EDIT") {
      getNotify(SUCCESS, colourModifiedSuccessfully);
    }
    yield put(updateLnAData({ lnaMasterFlag: "updated", colourMaster: [] }));
  } else {
    getNotify(ERROR, colourClusterDetails.data.message);
  }
}

// Author  @  AMit Kumar

const saveRoleClusterDataRequest = async (data) => {
  let id = data.masterItemUid ? data.masterItemUid : 0;
  delete data.masterItemUid;
  return axios
    .post(`${urlConfig.lnaApiUrl}role/${id}`, data, {
      headers: {
        authorization: localStorage.getItem("jwtToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((result) => result)
    .catch((error) => error.response);
};
// Amit Kumar
function* saveRoleClusterData({ payload }) {
  const moduleDetails = yield call(saveRoleClusterDataRequest, payload);
  if (moduleDetails.status === 200) {
    if (payload.type === "EDIT") {
      setTimeout(() => {
        getNotify(SUCCESS, roleModifiedSuccessfully);
      }, 1000);
    } else if (payload.type === "ADD") {
      setTimeout(() => {
        getNotify(SUCCESS, roleAddedSuccessfully);
      }, 1000);
    }
    yield put(updateLnAData({ lnaMasterFlag: "updated" }));
  } else {
    getNotify(ERROR, moduleDetails.data.message);
  }
}

// Author  @  AMit Kumar

const saveStatutoryDataRequest = async (data) => {
  let id = data.statutoryClusterUid ? data.statutoryClusterUid : 0;
  delete data.statutoryClusterUid;
  return axios
    .post(`${urlConfig.lnaApiUrl}statutory/${id}`, data, {
      headers: {
        authorization: localStorage.getItem("jwtToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((result) => result)
    .catch((error) => error.response);
};
// Amit Kumar
function* saveStatutoryClusterData({ payload }) {
  const statutoryModuleDetails = yield call(saveStatutoryDataRequest, payload);
  if (statutoryModuleDetails.status === 200) {
    if (payload.type === "ADD") {
      getNotify(SUCCESS, statutoryClusterAddMessage);
    } else if (payload.type === "EDIT") {
      getNotify(SUCCESS, statutoryClusterEditMessage);
    }
    yield put(updateLnAData({ lnaMasterFlag: "updated" }));
  } else {
    getNotify(ERROR, statutoryModuleDetails.data.message);
  }
}

// sanjeev
const saveExceptionalDataRequest = async (data) => {
  let id = data.exceptMasterUid ? data.exceptMasterUid : 0;
  delete data.exceptMasterUid;
  return axios
    .post(`${urlConfig.lnaApiUrl}exceptional/${id}`, data, {
      headers: {
        authorization: localStorage.getItem("jwtToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((result) => result)
    .catch((error) => error.response);
};
// Sanjeev
function* saveExceptionalClusterData({ payload }) {
  const moduleDetails = yield call(saveExceptionalDataRequest, payload);
  if (moduleDetails.status === 200) {
    if (payload.type === "status" && payload.isActive === "1") {
      getNotify(SUCCESS, exceptionalClusterActiveMessage);
    } else if (payload.type === "status" && payload.isActive === "0") {
      getNotify(SUCCESS, exceptionalClustereInactiveMessage);
    } else if (payload.type === "ADD") {
      getNotify(SUCCESS, exceptionalClusterAddMessage);
    } else if (payload.type === "EDIT") {
      getNotify(SUCCESS, exceptionalClusterEditMessage);
    }
    yield put(updateLnAData({ lnaMasterFlag: "updated" }));
  } else {
    getNotify(ERROR, moduleDetails.data.message.replace("payload: ", ""));
  }
}

// Author @ Amit Kumar

export function* addEditColourClusterData() {
  yield takeEvery(ADD_EDIT_COLOUR_CLUSTER_DATA, saveColourClusterData);
}

// Author @ Amit Kumar

export function* addEditRoleClusterData() {
  yield takeEvery(ADD_EDIT_ROLE_CLUSTER_DATA, saveRoleClusterData);
}
//

// Author @ Amit Kumar

export function* addEditStatutoryClusterData() {
  yield takeEvery(ADD_EDIT_STATUTORY_CLUSTER_DATA, saveStatutoryClusterData);
}

export function* addEditExceptionalClusterData() {
  yield takeEvery(
    ADD_EDIT_EXCEPTIONAL_CLUSTER_DATA,
    saveExceptionalClusterData
  );
}

const saveAttendanceClusterApiReq = async (data) => {
  let id = data.attendanceID ? data.attendanceID : 0;
  delete data.attendanceID;
  return axios
    .post(`${urlConfig.lnaApiUrl}attendance/${id}`, data, {
      headers: {
        authorization: localStorage.getItem("jwtToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((result) => result)
    .catch((error) => error.response);
};
function* saveAttendanceClusterDetails({ payload }) {
  const attendanceClusterDetails = yield call(
    saveAttendanceClusterApiReq,
    payload
  );
  if (attendanceClusterDetails.status === 200) {
    if (payload.type === "status" && payload.isActive === "1") {
      getNotify(SUCCESS, attendanceActiveMessage);
    } else if (payload.type === "status" && payload.isActive === "0") {
      getNotify(SUCCESS, attendanceInActiveMessage);
    } else if (payload.type === "ADD") {
      getNotify(SUCCESS, attendanceClusterAddMessage);
    } else if (payload.type === "EDIT") {
      getNotify(SUCCESS, attendanceClusterEditMessage);
    }
    yield put(updateLnAData({ lnaMasterFlag: "updated" }));
  } else {
    getNotify(
      ERROR,
      attendanceClusterDetails.data.message.replace("payload: ", "")
    );
  }
}

export function* saveAttendanceCluster() {
  yield takeEvery(SAVE_ATTENDANCE_CLUSTER, saveAttendanceClusterDetails);
}

// Regularization
const saveRegularizationDetailsApiReq = async (data) => {
  let id = data.regularizationAppliedUid ? data.regularizationAppliedUid : 0;

  delete data.regularizationAppliedUid;
  return axios
    .post(`${urlConfig.lnaApiUrl}regularization/${id}`, data, {
      headers: {
        authorization: localStorage.getItem("jwtToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((result) => result)
    .catch((error) => error.response);
};
// saveCompOffDetailsApiReq
const saveCompOffDetailsApiReq = async (data) => {
  let id = data.leaveApplicationId ? data.leaveApplicationId : 0;
  delete data.leaveApplicationId;
  return axios
    .post(`${urlConfig.lnaApiUrl}compoffleave/${id}`, data, {
      headers: {
        authorization: localStorage.getItem("jwtToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((result) => result)
    .catch((error) => error.response);
};

function* saveRegularizationDetails({ payload }) {
  const RegularizationDataDetails = yield call(
    saveRegularizationDetailsApiReq,
    payload
  );
  if (RegularizationDataDetails.status === 200) {
    if (payload.type === "ADD") {
      yield put(updateLnAData({ leaveRegularizationFlag: "updated" }));
      getNotify(SUCCESS, regularizationAddMessage);
    } else if (payload.type === "EDIT") {
      yield put(updateLnAData({ leaveRegularizationFlag: "updated" }));
      getNotify(SUCCESS, regularizationUpdateMessage);
    } else if (payload.type === "STATUS") {
      yield put(updateLnAData({ leaveRegularizationFlag: "updated" }));
      getNotify(SUCCESS, regularizationCancelMessage);
    }
  } else {
    getNotify(ERROR, RegularizationDataDetails.data.message);
    yield put(updateLnAData({ leaveRegularizationFlag: "errorMsg" }));
  }
}

export function* saveRegularizationData() {
  yield takeEvery(SAVE_REGULARIZATION_DATA, saveRegularizationDetails);
}

// compoff
function* saveCompOffDetails({ payload }) {
  const CompOffDetails = yield call(saveCompOffDetailsApiReq, payload);
  if (CompOffDetails.status === 200) {
    yield put(updateLnAData({ compOffClusterFlag: "saved" }));
    if (payload.type === "ADD") {
      getNotify(SUCCESS, compOffAddMessage);
    } else if (payload.leaveApplicationId !== 0) {
      if (payload.type === "STATUS" && payload.isActive === "0") {
        getNotify(SUCCESS, compOffInactivatedMessage);
      } else {
        getNotify(SUCCESS, compOffEditedMessage);
      }
    }
  } else {
    getNotify(ERROR, CompOffDetails.data.message.replace("payload: ", ""));
  }
}
export function* saveCompOffData() {
  yield takeEvery(SAVE_COMPOFF_DATA, saveCompOffDetails);
}
// leave-transfer

const saveLeaveTransferApiReq = async (data) => {
  let id = data.leaveTransferId ? data.leaveTransferId : 0;
  delete data.leaveTransferId;
  return axios
    .post(`${urlConfig.lnaApiUrl}leavestobetransferred/${id}`, data, {
      headers: {
        authorization: localStorage.getItem("jwtToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((result) => result)
    .catch((error) => error.response);
};

function* saveLeaveTransferDetails({ payload }) {
  const leaveTrasnferDetails = yield call(saveLeaveTransferApiReq, payload);

  if (leaveTrasnferDetails.status === 200) {
    let { result, leaveId } = leaveTrasnferDetails.data.Result[0];
    if (leaveId > 0) {
      yield put(updateLnAData({ leaveTransferFlag: "updated" }));
      getNotify(SUCCESS, leaveTransferaddedMessage);
    } else {
      getNotify(ERROR, result);
      yield put(updateLnAData({ leaveTransferFlag: "errorMsg" }));
    }
  }
  //  else {
  // }
}

export function* saveLeaveTransferData() {
  yield takeEvery(SAVE_LEAVE_TRANSFER_DATA, saveLeaveTransferDetails);
}

// workday window
const saveWorkDayWindowClusterApiReq = async (data) => {
  let id = data.workdayWindowUid ? data.workdayWindowUid : 0;
  delete data.workdayWindowUid;
  return axios
    .post(`${urlConfig.lnaApiUrl}workdaywindow/${id}`, data, {
      headers: {
        authorization: localStorage.getItem("jwtToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((result) => result)
    .catch((error) => error.response);
};

function* saveWorkDayWindowClusterDetails({ payload }) {
  const workDayClusterDetails = yield call(
    saveWorkDayWindowClusterApiReq,
    payload
  );
  if (workDayClusterDetails.status === 200) {
    if (payload.type === "ADD") {
      getNotify(SUCCESS, workdayClusterAddMessage);
    } else if (payload.type === "EDIT") {
      getNotify(SUCCESS, workdayClusterEditMessage);
    } else {
      getNotify(ERROR, workDayClusterDetails.data.message);
    }
    yield put(updateLnAData({ lnaMasterFlag: "updated" }));
  } else {
    getNotify(ERROR, workDayClusterDetails.data.message);
  }
}

export function* saveWorkDayWindowCluster() {
  yield takeEvery(
    SAVE_WORK_DAY_WINDOW_CLUSTER,
    saveWorkDayWindowClusterDetails
  );
}

// workday Day Break Mapping
const saveWorkdayDayBreakMappingApiReq = async (data) => {
  let id = data.workdayBreakMappingUid ? data.workdayBreakMappingUid : 0;
  delete data.workdayBreakMappingUid;
  return axios
    .post(`${urlConfig.lnaApiUrl}workdaybreakmapping/${id}`, data, {
      headers: {
        authorization: localStorage.getItem("jwtToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((result) => result)
    .catch((error) => error.response);
};

function* saveaddEditWorkdayDayBreakMapping({ payload }) {
  const WorkdayDayBreak = yield call(saveWorkdayDayBreakMappingApiReq, payload);
  if (WorkdayDayBreak.status === 200) {
    if (payload.type === "ADD") {
      getNotify(SUCCESS, workdayDayBreakAddMessage);
    } else if (payload.type === "EDIT") {
      getNotify(SUCCESS, workdayDayBreakEditMessage);
    } else {
      getNotify(ERROR, WorkdayDayBreak.data.message);
    }
    yield put(updateLnAData({ lnaMasterFlag: "updated" }));
  } else {
    getNotify(ERROR, WorkdayDayBreak.data.message);
  }
}

export function* WorkdayDayBreakMapping() {
  yield takeEvery(
    SAVE_WORKDAY_DAY_BREAK_MAP,
    saveaddEditWorkdayDayBreakMapping
  );
}

const saveSourceClusterApiReq = async (data) => {
  let id = data.sourceUid ? data.sourceUid : 0;
  delete data.sourceUid;
  return axios
    .post(`${urlConfig.lnaApiUrl}source/${id}`, data, {
      headers: {
        authorization: localStorage.getItem("jwtToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((result) => result)
    .catch((error) => error.response);
};
function* saveSourceClusterDetails({ payload }) {
  const type = payload.type;
  delete payload.type;
  const sourceClusterDetails = yield call(saveSourceClusterApiReq, payload);

  if (sourceClusterDetails.status === 200) {
    if (payload.type === "status" && payload.isActive === "1") {
      getNotify(SUCCESS, sourceClusterActiveMessage);
    } else if (payload.type === "status" && payload.isActive === "0") {
      getNotify(SUCCESS, sourceClusterInactiveMessage);
    } else if (type === "ADD") {
      getNotify(SUCCESS, sourceClusterAddMessage);
    } else if (type === "EDIT") {
      getNotify(SUCCESS, sourceClusterEditMessage);
    }
    yield put(updateLnAData({ lnaMasterFlag: "updated" }));
  } else {
    getNotify(
      ERROR,
      sourceClusterDetails.data.message.replace("payload: ", "")
    );
  }
}
export function* saveSourceCluster() {
  yield takeEvery(SAVE_SOURCE_CLUSTER, saveSourceClusterDetails);
}

const saveDayBreakClusterApiReq = async (data) => {
  let id = data.dayBreakClusterUid ? data.dayBreakClusterUid : 0;
  delete data.dayBreakClusterUid;
  return axios
    .post(`${urlConfig.lnaApiUrl}daybreak/${id}`, data, {
      headers: {
        authorization: localStorage.getItem("jwtToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((result) => result)
    .catch((error) => error.response);
};
function* saveDayBreakClusterDetails({ payload }) {
  const dayBreakClusterDetails = yield call(saveDayBreakClusterApiReq, payload);
  if (dayBreakClusterDetails.status === 200) {
    if (payload.type === "ADD") {
      getNotify(SUCCESS, dayBreakClusterAddMessage);
    } else if (payload.type === "EDIT") {
      getNotify(SUCCESS, dayBreakClusterEditMessage);
    }
    yield put(updateLnAData({ lnaMasterFlag: "updated", daybreakData: [] }));
  } else {
    getNotify(
      ERROR,
      dayBreakClusterDetails.data.message.replace("payload: ", "")
    );
  }
}
export function* saveDayBreakCluster() {
  yield takeEvery(SAVE_DAY_BREAK_CLUSTER, saveDayBreakClusterDetails);
}

const saveLeaveClusterApiReq = async (data) => {
  let id = data.leaveUid ? data.leaveUid : 0;
  delete data.leaveUid;

  let type = data.type ? "/" + data.type : "";
  delete data.type;

  return axios
    .post(`${urlConfig.lnaApiUrl}leave${type}/${id}`, data, {
      headers: {
        authorization: localStorage.getItem("jwtToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((result) => result)
    .catch((error) => error.response);
};
function* saveLeaveClusterDetails({ payload }) {
  const leavesuid = payload.leaveUid;
  const leaveClusterDetails = yield call(saveLeaveClusterApiReq, payload);
  if (leaveClusterDetails.status === 200) {
    if (leavesuid > 0) {
      getNotify(SUCCESS, leaveModuleEditMessage);
    } else {
      getNotify(SUCCESS, leaveModuleAddMessage);
    }
    yield put(updateLnAData({ lnaMasterFlag: "updated" }));
  } else {
    getNotify(
      ERROR,
      leaveClusterDetails.data.message.replace("Source: payload, Error: ", "")
    );
  }
}
export function* saveLeaveCluster() {
  yield takeEvery(SAVE_LEAVE_CLUSTER, saveLeaveClusterDetails);
}

const saveWeekdayClusterApiReq = async (data) => {
  let id = data.weekdayClusterUid ? data.weekdayClusterUid : 0;
  delete data.weekdayClusterUid;
  return axios
    .post(`${urlConfig.lnaApiUrl}weekday/${id}`, data, {
      headers: {
        authorization: localStorage.getItem("jwtToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((result) => result)
    .catch((error) => error.response);
};
function* saveWeekdayClusterDetails({ payload }) {
  const weekdayClusterDetails = yield call(saveWeekdayClusterApiReq, payload);
  if (weekdayClusterDetails.status === 200) {
    if (payload.type === "ADD") {
      getNotify(SUCCESS, weekdayClusterAddMessage);
    } else if (payload.type === "EDIT") {
      getNotify(SUCCESS, weekdayClusterEditMessage);
    }
    yield put(updateLnAData({ lnaMasterFlag: "updated" }));
  } else {
    getNotify(ERROR, weekdayClusterDetails.data.message);
  }
}
export function* saveWeekdayCluster() {
  yield takeEvery(SAVE_WEEKDAY_CLUSTER, saveWeekdayClusterDetails);
}

/**
 * Symbol cluster middleware
 * 18/10/2022
 * Noel
 */

const saveSymbolApiReq = async (data) => {
  let id = data.symbolUid ? data.symbolUid : 0;
  let userId = data.userId;
  delete data.symbolUid;
  delete data.userId;
  return axios
    .post(`${urlConfig.lnaApiUrl}symbol/${userId}/${id}`, data, {
      headers: {
        authorization: localStorage.getItem("jwtToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((result) => result)
    .catch((error) => error.response);
};

function* saveSymbolDetails({ payload }) {
  let symbolUid = payload.symbolUid ? payload.symbolUid : 0;
  const saveSymbolClusterStatus = yield call(saveSymbolApiReq, payload);
  if (saveSymbolClusterStatus.status === 200) {
    if (payload.type === "status" && payload.isActive === "1") {
      getNotify(SUCCESS, symbolClusterActiveMessage);
    } else if (payload.type === "status" && payload.isActive === "0") {
      getNotify(SUCCESS, symbolClustereInactiveMessage);
    } else if (payload.type === "ADD") {
      getNotify(SUCCESS, symbolClusterAddMessage);
      symbolUid = 0;
    } else if (payload.type === "EDIT") {
      getNotify(SUCCESS, symbolClusterEditMessage);
    }
    yield put(
      updateLnAData({ lnaMasterFlag: "updated", lastUpdateMasterId: symbolUid })
    );
  } else {
    getNotify(ERROR, saveSymbolClusterStatus.data.message);
  }
}
export function* saveSymbolCluster() {
  yield takeEvery(SAVE_SYMBOL, saveSymbolDetails);
}

const approveRejectLeaveApplicationApiReq = async (data) => {
  let id = data.leaveId ? data.leaveId : 0;
  delete data.leaveId;
  let type = data.type ? data.type : 0;
  delete data.type;
  return axios
    .post(`${urlConfig.lnaApiUrl}leaveapproval/${type}/${id}`, data, {
      headers: {
        authorization: localStorage.getItem("jwtToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((result) => result)
    .catch((error) => error.response);
};

function* approveRejectLeaveApplicationDetails({ payload }) {
  try {
    yield put(updateLnAData({ leaveApprovelStatus: "processing" }));
    const id = payload.leaveId ? payload.leaveId : 0;
    const response = yield call(approveRejectLeaveApplicationApiReq, payload);

    if (response.status === 200) {
      if (payload.status === "2") {
        getNotify(SUCCESS, leaveApproved);
      }
      if (payload.status === "3") {
        getNotify(SUCCESS, leaveRejected);
      }
      if (payload.reason === "from email.") {
        yield put(updateLnAData({ leaveApprovalMessage: "" }));
      }
      yield put(
        updateLnAData({
          leaveApprovalInfo: response.data.Result,
          leaveApprovelStatus: "updated",
          leaveApplicationId: id,
        })
      );
    }
  } catch (error) { }
}

export function* approveRejectLeaveApplication() {
  yield takeEvery(
    CHANGE_LEAVE_APPROVAL_STATUS,
    approveRejectLeaveApplicationDetails
  );
}

/**
 * Leave Cancel Reason using for also partialLeavecancelApiReq
 * 18/10/2022
 * Noel
 */

const saveLeavecancelApiReq = async (data) => {
  let userId = data.userId;
  delete data.userId;
  let leaveApplicationId = data.leaveApplicationId;
  delete data.leaveApplicationId;
  let type = data.type;
  delete data.type;
  let isSpecialAttendance = data.isSpecialAttendance;
  delete data.isSpecialAttendance;

  return axios
    .post(
      `${urlConfig.lnaApiUrl
      }leaveapply/cancel/${userId}/${leaveApplicationId}/${type}${isSpecialAttendance ? "?isSpecialAttendance=2" : ""
      }`,
      data,
      {
        headers: {
          authorization: localStorage.getItem("jwtToken"),
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    )
    .then((result) => result)
    .catch((error) => error.response);
};

function* saveLeavecancelReason({ payload }) {
  let sendNotification = true;
  if (
    !(
      payload.sendNotification === null ||
      typeof payload.sendNotification === "undefined"
    )
  ) {
    sendNotification = payload.sendNotification;
    delete payload.sendNotification;
  }
  const leaveId = payload.leaveApplicationId;
  const saveLeaveCancelStatus = yield call(saveLeavecancelApiReq, payload);
  if (saveLeaveCancelStatus.status === 200) {
    if (
      payload.cancelReason === "Cancel Festival Holiday" &&
      sendNotification
    ) {
      getNotify(SUCCESS, "Applied festival holiday(s) are cancelled successfully.");
    } else if (payload.cancelReason !== "Cancel Festival Holiday") {
      // NOSONAR
      getNotify(SUCCESS, "Applied leave(s) are cancelled successfully.");
    }
    if (payload.cancelReason === "Cancel Festival Holiday") {
      yield put(updateLnAData({ backgroundSyncId: leaveId }));
    }
    yield put(
      updateLnAData({
        holidayLoadingStatus: sendNotification ? "updated" : "",
        leaveCancelStatus: "updated",
        leaveApplyLoadName: "leave-details",
        backgroundSyncId: leaveId,
      })
    );
  } else {
    getNotify(ERROR, saveLeaveCancelStatus.data.message.replace("payload: ", ""), 8000);
    if ((payload.cancelReason === "Cancel Festival Holiday" && sendNotification) || payload.reason !== "Cancel Festival Holiday") {
      yield put(
        updateLnAData({
          holidayLoadingStatus: sendNotification ? "updated" : "",
          leaveCancelStatus: "failed",
        })
      );
    }

  }
}

export function* saveLeaveCancelResponse() {
  yield takeEvery(SAVE_LEAVE_CANCEL_REASON, saveLeavecancelReason);
}
export function* savePartialLeaveCancelResponse() {
  yield takeEvery(SAVE_PARTIAL_LEAVE_CANCEL_REASON, saveLeavecancelReason);
}

/**
 * ADMIN MODULES ACTIVE/INACTIVE STATUS
 */

const updateMasterItemStatusApiReq = async (data) => {
  let userId = data.userId ? data.userId : 0;
  delete data.userId;
  return axios
    .post(`${urlConfig.lnaApiUrl}master/status/${userId}`, data, {
      headers: {
        authorization: localStorage.getItem("jwtToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((result) => result)
    .catch((error) => error.response);
};

/** New Code optimized due to cognitive complexity error shown in Sonar-Qube
 * Admin Modules ACTIVE, INACTIVE Status UPDATES
 * Noel Fredrick
 * 16-02-2023
 */
function* updateMasterItemStatusDetails({ payload }) {
  // NOSONAR
  const masterItemDetails = yield call(updateMasterItemStatusApiReq, payload);
  if (masterItemDetails.status === 200) {
    switch (masterItemDetails.data.Result.masterId.toString()) {
      case LEAVE:
        if (payload.lnAMasterId === "7" && payload.isActive === "1") {
          getNotify(SUCCESS, leaveModuleActiveMessage);
        } else if (payload.lnAMasterId === "7" && payload.isActive === "0") {
          getNotify(SUCCESS, leaveModuleInactiveMessage);
        }
        yield put(updateLnAData({ lnaMasterFlag: "updated" }));
        break;
      case LOCK_UNLOCK:
        if (payload.lnAMasterId === "41" && payload.isActive === "1") {
          getNotify(SUCCESS, lockUnlockActiveMessage);
        } else if (payload.lnAMasterId === "41" && payload.isActive === "0") {
          getNotify(SUCCESS, lockUnlockInActiveMessage);
        }
        yield put(updateLnAData({ lnaMasterFlag: "updated" }));
        break;
      case LEAVE_YEAR_CONFIG:
        if (payload.lnAMasterId === "42" && payload.isActive === "1") {
          getNotify(SUCCESS, leaveYearActiveMessage);
        } else if (payload.lnAMasterId === "42" && payload.isActive === "0") {
          getNotify(SUCCESS, leaveYearInActiveMessage);
        }
        yield put(updateLnAData({ lnaMasterFlag: "updated" }));
        break;
      case EXCEPTIONAL_ATTENDANCE:
        if (payload.lnAMasterId === "4" && payload.isActive === "1") {
          getNotify(SUCCESS, exceptionalClusterActiveMessage);
        } else if (payload.lnAMasterId === "4" && payload.isActive === "0") {
          getNotify(SUCCESS, exceptionalClustereInactiveMessage);
        }
        yield put(updateLnAData({ lnaMasterFlag: "updated" }));
        break;
      case ATTENDANCE_SOURCE:
        if (payload.lnAMasterId === "6" && payload.isActive === "1") {
          getNotify(SUCCESS, sourceClusterActiveMessage);
        } else if (payload.lnAMasterId === "6" && payload.isActive === "0") {
          getNotify(SUCCESS, sourceClusterInactiveMessage);
        }
        yield put(updateLnAData({ lnaMasterFlag: "updated" }));
        break;
      case COLOUR:
        if (payload.lnAMasterId === "12" && payload.isActive === "1") {
          getNotify(SUCCESS, colourActivatedSuccessfully);
        } else if (payload.lnAMasterId === "12" && payload.isActive === "0") {
          getNotify(SUCCESS, colourInactivatedSuccessfully);
        }
        yield put(
          updateLnAData({ lnaMasterFlag: "updated", colourMaster: [] })
        );
        break;
      case ATTENDANCE:
        if (payload.lnAMasterId === "1" && payload.isActive === "1") {
          getNotify(SUCCESS, attendanceActiveMessage);
        } else if (payload.lnAMasterId === "1" && payload.isActive === "0") {
          getNotify(SUCCESS, attendanceInActiveMessage);
        }
        yield put(updateLnAData({ lnaMasterFlag: "updated" }));
        break;
      case WORKDAY_WINDOW:
        if (payload.lnAMasterId === "2" && payload.isActive === "1") {
          getNotify(SUCCESS, workdayClusterActiveMessage);
        } else if (payload.lnAMasterId === "2" && payload.isActive === "0") {
          getNotify(SUCCESS, workdayClustereInactiveMessage);
        }
        yield put(updateLnAData({ lnaMasterFlag: "updated" }));
        break;
      case WEEKDAY:
        if (payload.lnAMasterId === "3" && payload.isActive === "1") {
          getNotify(SUCCESS, weekdayClusterActiveMessage);
        } else if (payload.lnAMasterId === "3" && payload.isActive === "0") {
          getNotify(SUCCESS, weekdayClustereInactiveMessage);
        }
        yield put(updateLnAData({ lnaMasterFlag: "updated" }));
        break;
      case DAYBREAK:
        if (payload.lnAMasterId === "5" && payload.isActive === "1") {
          getNotify(SUCCESS, dayBreakClusterActiveMessage);
        } else if (payload.lnAMasterId === "5" && payload.isActive === "0") {
          getNotify(SUCCESS, dayBreakClustereInactiveMessage);
        }
        yield put(
          updateLnAData({ lnaMasterFlag: "updated", dayBreakData: [] })
        );
        break;
      case WORKING_DAY:
        if (payload.lnAMasterId === "8" && payload.isActive === "1") {
          getNotify(SUCCESS, workingdayClusterActiveMessage);
        } else if (payload.lnAMasterId === "8" && payload.isActive === "0") {
          getNotify(SUCCESS, workingdayClustereInactiveMessage);
        }
        yield put(updateLnAData({ lnaMasterFlag: "updated" }));
        break;
      case HOLIDAY:
        if (payload.lnAMasterId === "9" && payload.isActive === "1") {
          getNotify(SUCCESS, holidayActiveMessage);
        } else if (payload.lnAMasterId === "9" && payload.isActive === "0") {
          getNotify(SUCCESS, holidayInActiveMessage);
        }
        yield put(updateLnAData({ lnaMasterFlag: "updated" }));
        break;
      case STATUTORY:
        if (payload.lnAMasterId === "11" && payload.isActive === "1") {
          getNotify(SUCCESS, statutoryClusterActiveMessage);
        } else if (payload.lnAMasterId === "11" && payload.isActive === "0") {
          getNotify(SUCCESS, statutoryClustereInactiveMessage);
        }
        yield put(updateLnAData({ lnaMasterFlag: "updated" }));
        break;
      case SYMBOL:
        if (payload.lnAMasterId === "14" && payload.isActive === "1") {
          getNotify(SUCCESS, symbolClusterActiveMessage);
        } else if (payload.lnAMasterId === "14" && payload.isActive === "0") {
          getNotify(SUCCESS, symbolClustereInactiveMessage);
        }
        yield put(updateLnAData({ lnaMasterFlag: "updated" }));
        break;
      case ROLE:
        if (payload.lnAMasterId === "13" && payload.isActive === "1") {
          getNotify(SUCCESS, roleClusterActiveMessage);
        } else if (payload.lnAMasterId === "13" && payload.isActive === "0") {
          getNotify(SUCCESS, roleClustereInactiveMessage);
        }
        yield put(updateLnAData({ lnaMasterFlag: "updated" }));
        break;
      case OPTIONAL_HOLIDAY:
        if (payload.lnAMasterId === "10" && payload.isActive === "1") {
          getNotify(SUCCESS, optionalHolidayActiveMessage);
        } else if (payload.lnAMasterId === "10" && payload.isActive === "0") {
          getNotify(SUCCESS, optionalHolidayInActiveMessage);
        }
        yield put(updateLnAData({ lnaMasterFlag: "updated" }));
        break;
      case LEAVE_YEAR:
        if (payload.lnAMasterId === "40" && payload.isActive === "1") {
          getNotify(SUCCESS, memberYearActiveMessage);
        } else if (payload.lnAMasterId === "40" && payload.isActive === "0") {
          getNotify(SUCCESS, memberYearInactiveMessage);
        }
        yield put(updateLnAData({ lnaMasterFlag: "updated" }));
        break;
      case WORKDAY_DAYBREAK_MAPPING:
        if (payload.lnAMasterId === "46" && payload.isActive === "1") {
          getNotify(SUCCESS, workingdayDayBreakMapActiveMessage);
        } else if (payload.lnAMasterId === "46" && payload.isActive === "0") {
          getNotify(SUCCESS, workingdayDayBreakMapInactiveMessage);
        }
        yield put(updateLnAData({ lnaMasterFlag: "updated" }));
        break;
      default:
    }
  } else {
    getNotify(ERROR, masterItemDetails.data.message);
  }
}

export function* updateMasterItemStatus() {
  yield takeEvery(UPDATE_MASTER_ITEM_STATUS, updateMasterItemStatusDetails);
}

const saveUpdateWeekOffSwapDates = async (data) => {
  return axios
    .post(`${urlConfig.lnaApiUrl}updateweekoff`, data, {
      headers: {
        authorization: localStorage.getItem("jwtToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((result) => result)
    .catch((error) => error.response);
};

function* saveWeekOffSwapDates({ payload }) {
  const saveWeekOffSwapDate = yield call(saveUpdateWeekOffSwapDates, payload);

  if (saveWeekOffSwapDate.status === 200) {
    getNotify(SUCCESS, "Swap weekly off successful.");
    // yield put(updateLnAData({ leaveCancelStatus: "updated", leaveApplyLoadName: 'leave-tabs', leaveApplicationId: 0 }));
  } else {
    getNotify(ERROR, saveWeekOffSwapDate.data.message.replace("payload: ", ""));
  }
}

export function* saveWeekOffSwap() {
  yield takeEvery(SAVE_WEEK_OFF_SWAP_DATES, saveWeekOffSwapDates);
}
// Abdulla Shikah
const saveNotificationData = async (data) => {
  return axios
    .post(`${urlConfig.lnaApiUrl}notifierlist`, data, {
      headers: {
        authorization: localStorage.getItem("jwtToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((result) => result)
    .catch((error) => error.response);
};

function* saveNotification({ payload }) {
  yield put(updateLnAData({ notification: "updated" }));
  const saveNotificationCall = yield call(saveNotificationData, payload);
  if (saveNotificationCall.status === 200) {
    getNotify(SUCCESS, "Notification added successfully.");
    yield put(updateLnAData({ notification: "added" }));
  } else {
    getNotify(
      ERROR,
      saveNotificationCall.data.message.replace("payload: ", "")
    );
  }
}

export function* saveNotificationResponse() {
  yield takeEvery(ADD_NOTIFICATION, saveNotification);
}

const saveLockUnlockApiReq = async (data) => {
  let id = data.lockUnlockUid ? data.lockUnlockUid : 0;
  delete data.lockUnlockUid;
  return axios
    .post(`${urlConfig.lnaApiUrl}lockunlock/${id}`, data, {
      headers: {
        authorization: localStorage.getItem("jwtToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((result) => result)
    .catch((error) => error.response);
};

function* saveLockUnlockDetails({ payload }) {
  const lockUnlockClusterDetails = yield call(saveLockUnlockApiReq, payload);
  if (lockUnlockClusterDetails.status === 200) {
    if (payload.type === "status" && payload.isActive === "1") {
      getNotify(SUCCESS, lockUnlockActiveMessage);
    } else if (payload.type === "status" && payload.isActive === "0") {
      getNotify(SUCCESS, lockUnlockInActiveMessage);
    } else if (payload.type === "ADD") {
      getNotify(SUCCESS, lockUnlockClusterAddMessage);
    } else if (payload.type === "EDIT") {
      getNotify(SUCCESS, lockUnlockClusterEditMessage);
    }
    yield put(updateLnAData({ lnaMasterFlag: "updated" }));
  } else {
    getNotify(
      ERROR,
      lockUnlockClusterDetails.data.message.replace("payload: ", "")
    );
  }
}
const saveLeaveYearApiReq = async (data) => {
  let rowUid = data.rowUid ? data.rowUid : 0;
  delete data.rowUid;

  let userId = data.userId;
  delete data.userId;

  let type = data.type ? "/" + data.type : "";
  delete data.type;

  return axios
    .post(`${urlConfig.lnaApiUrl}leaveyear${type}/${userId}/${rowUid}`, data, {
      headers: {
        authorization: localStorage.getItem("jwtToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((result) => result)
    .catch((error) => error.response);
};

function* saveLeaveYearDetails({ payload }) {
  const rowsuid = payload.rowUid;
  const leaveFinanceDetails = yield call(saveLeaveYearApiReq, payload);
  if (leaveFinanceDetails.status === 200) {
    if (rowsuid > 0) {
      getNotify(SUCCESS, memberYearEditMessage);
    } else {
      getNotify(SUCCESS, memberYearAddMessage);
    }
    yield put(updateLnAData({ lnaMasterFlag: "updated" }));
  } else {
    getNotify(
      ERROR,
      leaveFinanceDetails.data.message.replace("Source: payload, Error: ", "")
    );
  }
}
export function* saveLeaveYear() {
  yield takeEvery(SAVE_LEAVE_YEAR, saveLeaveYearDetails);
}

export function* saveLockUnlockCluster() {
  yield takeEvery(SAVE_LOCK_UNLOCK_CLUSTER, saveLockUnlockDetails);
}

const saveLeaveYearApiReq1 = async (data) => {
  let id = data.leaveYearUid ? data.leaveYearUid : 0;
  delete data.leaveYearUid;
  return axios
    .post(`${urlConfig.lnaApiUrl}leaveyear/${id}`, data, {
      headers: {
        authorization: localStorage.getItem("jwtToken"),
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
    .then((result) => result)
    .catch((error) => error.response);
};

function* saveLeaveYearDetails1({ payload }) {
  const leaveYearClusterDetails = yield call(saveLeaveYearApiReq1, payload);
  if (leaveYearClusterDetails.status === 200) {
    if (payload.type === "status" && payload.isActive === "1") {
      getNotify(SUCCESS, leaveYearActiveMessage);
    } else if (payload.type === "status" && payload.isActive === "0") {
      getNotify(SUCCESS, leaveYearInActiveMessage);
    } else if (payload.type === "ADD") {
      getNotify(SUCCESS, leaveYearClusterAddMessage);
    } else if (payload.type === "EDIT") {
      getNotify(SUCCESS, leaveYearClusterEditMessage);
    }
    yield put(updateLnAData({ lnaMasterFlag: "updated" }));
  } else {
    getNotify(
      ERROR,
      leaveYearClusterDetails.data.message.replace("payload: ", "")
    );
  }
}

export function* saveLeaveYearCluster() {
  yield takeEvery(SAVE_LEAVE_YEAR_CLUSTER, saveLeaveYearDetails1);
}

export default function* rootSaga() {
  yield all([
    fork(saveHolidayCluster),
    fork(saveOptionalHolidayCluster),
    fork(applyLeave),
    fork(addEditWorkingdayClusterData),
    fork(addEditColourClusterData),
    fork(addEditRoleClusterData),
    fork(addEditStatutoryClusterData),
    fork(addEditExceptionalClusterData),
    fork(saveAttendanceCluster),
    fork(saveSourceCluster),
    fork(saveDayBreakCluster),
    fork(saveLeaveCluster),
    fork(saveWeekdayCluster),
    fork(saveWorkDayWindowCluster),
    fork(saveSymbolCluster),
    fork(saveRegularizationData),
    fork(saveCompOffData),
    fork(saveLeaveTransferData),
    fork(approveRejectLeaveApplication),
    fork(saveLeaveCancelResponse),
    fork(savePartialLeaveCancelResponse),
    fork(updateMasterItemStatus),
    fork(saveWeekOffSwap),
    fork(saveNotificationResponse),
    fork(saveLockUnlockCluster),
    fork(saveLeaveYearCluster),
    fork(saveLeaveYear),
    fork(WorkdayDayBreakMapping),
    fork(saveLeaveYear),
  ]);
}
