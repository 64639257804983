import React, { Component } from 'react';
import "./orgchartadmin.css";

class ActionTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
    this.makeActiveInActive = this.makeActiveInActive.bind(this);
    this.openPopup = this.openPopup.bind(this);
  }
  openPopup = (id, item) => {
    if (this.props.category === "BUSINESS_FUNCTION") { this.props.openPopup(id, item) }

    else if (this.props.category === "FolderManagement") {
      this.props.openPopup(item.parentFolderName, id, item)
    }
    else {
      this.props.openPopup(this.props.header, id, item)
    }
  }
  makeActiveInActive = (id, data, parentFolderId) => {
    this.props.makeActiveInActive(id, data, parentFolderId)
  }

  render() {
    const { props } = this.props
    const item = props

    let idNew = item.Id ? item.Id : item.folderId 
    let parentFolderId = (this.props.category === "FolderManagement" && item.folderId === item.parentFolderId)? props.parentFolderId : 0
   


    return (<div style={{ textAlign: 'center' }}
    className="actionTemplate Popup">

      {
        item.isActive !== "1" ? (
          <button
            id="active"
            onClick={() =>
              this.makeActiveInActive(idNew, "1",parentFolderId)
            }

          >
            Active
          </button>
        ) :
          (<>
            {((this.props.category === "FolderManagement" && item.folderId === item.parentFolderId)) &&
              < button className="actionTemplate-btn"
              id="add"
            onClick={() =>
            //  this.openPopup(this.header, 'Add', { parentFolderId: parentFolderId:item.parentFolderId , folderId: 0 })
              this.openPopup('Add', item)
            }

            >
            Add
          </button>
  }


      <button
        id="edit"
        onClick={() => this.openPopup("Edit", item)}
      >
        Edit
      </button>

      <button
        id="inactive" className='masterinactiveMapping'
        onClick={() =>
          this.makeActiveInActive(idNew,"0",parentFolderId)
        }
      >
        Inactive
      </button>

    </>)
  }</div>)

  }
}

export default ActionTemplate

