/**
 * Author: Vivek Khodade.
 * Last Modify:08-May-2020
 * Modified By:Vivek Khodade
 */
import React from "react";
import PropTypes from "prop-types";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCalendarDay,
  faIdCardAlt,
  faMoneyCheckAlt,
  faFlag,
  faEnvelope,
  faFax,
  faPhoneSquareAlt,
  faChalkboardTeacher,
  faTasks,
  faFileAlt,
  faFileUpload,
  faUserTie,
  faMapMarkedAlt,
} from "@fortawesome/free-solid-svg-icons";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import { faUser, faCalendarStar, faArrowAltDown, faArrowAltUp } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { connect } from "react-redux";
import { getDisplayDateOnly, dencrypt } from "../../app/other/commonfunction";
import OnbordingServices from "../../services/entryexit/onbordingServices";
import { urlConfig } from "../../config/config";
import {
  addUpdateBasicInfo,
  saveBasicInformationInRedux,
} from "../../redux/actions/entryexit/onboardingaction";

import { setCentralOkrKPIRedux } from '../../redux/actions/okr/okractions';

import "./basicinformation.css";
import ProgressBar from "../progressbar/progressbar";

library.add(faUser, faCalendarStar, faArrowAltDown, faArrowAltUp);

class BasicInformation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      basicInformation: null,
      showMore: false
    };
  }

  componentDidMount = () => {
    const { search } = window.location;
    const { authUser } = this.props;
    const params = new URLSearchParams(search);
    const empId = dencrypt(params.get("empId"))
    // eslint-disable-next-line
    this.props.saveBasicInformationInRedux({ empid: parseInt(empId) });
    this.props.setCentralOkrKPIRedux({ employeeId: parseInt(empId) });
    const employeeBasicInfo = Promise.resolve(
      OnbordingServices.getEnployeeBasicInformation(empId, authUser)
    );
    employeeBasicInfo.then((result) => {
      if (result !== null) {
        this.setState(
          {
            basicInformation: result[0],
          },
          () => {
            // eslint-disable-next-line
            this.props.addUpdateBasicInfo({
              basicInformation: result[0],
              status: "updated",
              candidateid:result[0].CandidateId,
            });
          }
        );
      }
    });
  };
  addDefaultSrc(ev) {
    ev.target.parentNode.innerHTML = '<span  class="img-circle fas fa-user-circle img-circle-grid-photo" alt="" ></span>'
  }

  render() {
    const { basicInformation, showMore } = this.state;
    const { authUser } = this.props;
    let imgUrl = "";
    if (typeof basicInformation === 'undefined' || basicInformation === null ||
      basicInformation.PhotoNm === "" || typeof basicInformation.PhotoNm === "undefined" || basicInformation.PhotoNm === null) {
      imgUrl = "../../app/containers/tmlc/onboarding/avatar-s-16.png";
    } else {
      imgUrl = `${urlConfig.tmlcApiUrl}document/E/${authUser}/${basicInformation.PhotoNm}`;
    }

    return (
      <div style={{ borderRadius: 50 }}>
        <div>BASIC INFORMATION{basicInformation !== null ? ': ' + basicInformation.EmployeeName : ''}</div>
        {basicInformation !== null ? (
          // <div className='control-section col-md-12'>
          <div className="control-section col-md-12" style={{ height: '130px', width: '100.65%', overflowY: 'auto' }}>
            {
              !showMore &&
              <div className="row basicinformation-row">
                <div className="col-md-2 remove-padding" style={{ padding: 5 }}>
                  <div
                    className="col-md-2 remove-padding"
                    style={{ height: "100%", textAlign: "right" }}
                  >
                    <div className="row">
                      {
                      // basicInformation === undefined || basicInformation === null || basicInformation.PhotoNm === "" && (
                        <img
                          className="img-circle icons-basicinformation"
                          src={imgUrl}
                          onError={this.addDefaultSrc}
                          alt=""
                          style={{
                            width: "35px",
                            height: "35px",
                            borderRadius: "17.5px",
                          }}
                        />
                      // ) 
                      // : (
                      //     <img
                      //       className="img-circle icons-basicinformation"
                      //       src={imgUrl}
                      //       onError={this.addDefaultSrc}
                      //       alt=""
                      //       style={{
                      //         width: "35px",
                      //         height: "35px",
                      //         borderRadius: "17.5px",
                      //       }}
                      //     />
                      //   )
                        }
                    </div>
                  </div>
                  <div className="col-md-10 ">
                    <div className="row1 px-sm-2 basicinformation-icon-header-text-font-style">
                      Name
                  </div>
                    <div
                      className="row1 px-sm-2 basicinformation-icon-text-font-style basicinfo-text-limit"
                      title={basicInformation.EmployeeName}
                    >
                      {/* <TooltipComponent
                        closeOn="Click"
                        content={basicInformation.EmployeeName}
                        position="BottomCenter"
                        isSticky={false}
                        cssClass='customtooltip'
                        style={{ fontWeight: 500 }}
                      > */}
                        {" "}
                        {basicInformation.EmployeeName}
                      {/* </TooltipComponent> */}
                    </div>
                  </div>
                </div>
                <div className="col-md-2 remove-padding" style={{ padding: 5 }}>
                  <div
                    className="col-md-2 remove-padding"
                    style={{ height: "100%", textAlign: "right" }}
                  >
                    <div className="row">
                      <FontAwesomeIcon
                        icon={faIdCardAlt}
                        className="mr-sm icons-basicinformation"
                      />
                    </div>
                  </div>
                  <div className="col-md-10 ">
                    <div className="row1 px-sm-2 basicinformation-icon-header-text-font-style">
                      Team Member Id
                  </div>
                    <div className="row1 px-sm-2 basicinformation-icon-text-font-style" title={basicInformation.EmployeeCode}>
                      {basicInformation.EmployeeCode}
                    </div>
                  </div>
                </div>
                <div className="col-md-2 remove-padding" style={{ padding: 5 }}>
                  <div
                    className="col-md-2 remove-padding"
                    style={{ height: "100%", textAlign: "right" }}
                  >
                    <div className="row">
                      <FontAwesomeIcon
                        icon={faCalendarDay}
                        className="mr-sm icons-basicinformation"
                      />
                    </div>
                  </div>
                  <div className="col-md-10 ">
                    <div className="row1 px-sm-2 basicinformation-icon-header-text-font-style">
                      Date of Joining
                  </div>
                    <div className="row1 px-sm-2 basicinformation-icon-text-font-style" title={getDisplayDateOnly(new Date(basicInformation.JoiningDate))}>
                      {getDisplayDateOnly(new Date(basicInformation.JoiningDate))}
                    </div>
                  </div>
                </div>
                <div className="col-md-2 remove-padding" style={{ padding: 5 }}>
                  <div
                    className="col-md-2 remove-padding"
                    style={{ height: "100%", textAlign: "right" }}
                  >
                    <div className="row">
                      <FontAwesomeIcon
                        icon={faUserTie}
                        className="mr-sm icons-basicinformation"
                      />
                    </div>
                  </div>
                  <div className="col-md-10 ">
                    <div className="row1 px-sm-2 basicinformation-icon-header-text-font-style">
                      Role
                  </div>
                    <div
                      className="row1 px-sm-2 basicinformation-icon-text-font-style basicinfo-text-limit"
                      title={basicInformation.job_title}
                    >
                      {/* <TooltipComponent
                        closeOn="Click"
                        content={basicInformation.job_title}
                        position="BottomCenter"
                        isSticky={false}
                        cssClass='customtooltip'
                        style={{ fontWeight: 500 }}
                      > */}
                        {basicInformation.job_title}
                      {/* </TooltipComponent> */}
                    </div>
                  </div>
                </div>
                {/* <div className="col-md-2 remove-padding" style={{ padding: 5 }}>
                  <div
                    className="col-md-2 remove-padding"
                    style={{ height: "100%", textAlign: "right" }}
                  >
                    <div className="row">
                      <FontAwesomeIcon
                        icon={faLayerGroup}
                        className="mr-sm icons-basicinformation"
                      />
                    </div>
                  </div>
                  <div className="col-md-10 ">
                    <div className="row1 px-sm-2 basicinformation-icon-header-text-font-style">
                      Band
                    </div>
                    <div className="row1 px-sm-2 basicinformation-icon-text-font-style">
                      {basicInformation.Band}
                    </div>
                  </div>
                </div> */}
                <div className="col-md-2 remove-padding" style={{ padding: 5 }}>
                  <div
                    className="col-md-2 remove-padding"
                    style={{ height: "100%", textAlign: "right" }}
                  >
                    <div className="row">
                      <FontAwesomeIcon
                        icon={faMoneyCheckAlt}
                        className="mr-sm icons-basicinformation"
                      />
                    </div>
                  </div>
                  <div className="col-md-10 ">
                    <div className="row1 px-sm-2 basicinformation-icon-header-text-font-style">
                      Employment Type
                  </div>
                    <div className="row1 px-sm-2 basicinformation-icon-text-font-style" title={basicInformation.EmpType}>
                      {basicInformation.EmpType}
                    </div>
                  </div>
                </div>

                <div className="col-md-2 remove-padding" style={{ padding: 5 }}>
                  <div
                    className="col-md-2 remove-padding"
                    style={{ height: "100%", textAlign: "right" }}
                  >
                    <div className="row">
                      <FontAwesomeIcon
                        icon={faFlag}
                        className="mr-sm icons-basicinformation"
                      />
                    </div>
                  </div>
                  <div className="col-md-10 ">
                    <div className="row1 px-sm-2 basicinformation-icon-header-text-font-style">
                      Base Country
                    </div>
                    <div className="row1 px-sm-2 basicinformation-icon-text-font-style" title={basicInformation.BaseCountry}>
                      {basicInformation.BaseCountry}
                    </div>
                  </div>
                </div>
              </div>
            }
            {/* second row */}
            {/* <div className='row basicinformation-middle-row'> */}
            <div className="row basicinformation-row">
              <div className="col-md-2 remove-padding" style={{ padding: 5 }}>
                <div
                  className="col-md-2 remove-padding"
                  style={{ height: "100%", textAlign: "right" }}
                >
                  <div className="row">
                    {/* <p><i class="zmdi zmdi-settings-square "></i></p> */}
                    <FontAwesomeIcon
                      icon={faMapMarkedAlt}
                      className="mr-sm icons-basicinformation"
                    />
                  </div>
                </div>
                <div className="col-md-10 ">
                  <div className="row1 px-sm-2 basicinformation-icon-header-text-font-style">
                    Base Location
                  </div>
                  <div className="row1 px-sm-2 basicinformation-icon-text-font-style" title={basicInformation.BaseLocation}>
                    {basicInformation.BaseLocation}
                  </div>
                </div>
              </div>
              <div className="col-md-2 remove-padding" style={{ padding: 5 }}>
                <div
                  className="col-md-2 remove-padding"
                  style={{ height: "100%", textAlign: "right" }}
                >
                  <div className="row">
                    <FontAwesomeIcon
                      icon={faFileUpload}
                      className="mr-sm icons-basicinformation"
                    />
                  </div>
                </div>
                <div className="col-md-10 ">
                  <div className="row1 px-sm-2 basicinformation-icon-header-text-font-style">
                    Reporting Supervisor
                  </div>
                  <div
                    className="row1 px-sm-2 basicinformation-icon-text-font-style basicinfo-text-limit"
                    title={basicInformation.ReportingTo}
                  >
                    {/* <TooltipComponent
                      closeOn="Click"
                      content={basicInformation.ReportingTo}
                      position="BottomCenter"
                      isSticky={false}
                      cssClass='customtooltip'
                      style={{ fontWeight: 500 }}
                    > */}
                      {basicInformation.ReportingTo}
                    {/* </TooltipComponent> */}
                  </div>
                </div>
              </div>
              <div className="col-md-2 remove-padding" style={{ padding: 5 }}>
                <div
                  className="col-md-2 remove-padding"
                  style={{ height: "100%", textAlign: "right" }}
                >
                  <div className="row">
                    <FontAwesomeIcon
                      icon={faFileAlt}
                      className="mr-sm icons-basicinformation"
                    />
                  </div>
                </div>
                <div className="col-md-10 ">
                  <div className="row1 px-sm-2 basicinformation-icon-header-text-font-style">
                    Business Unit / Group Function 
                  </div>
                  <div className="row1 px-sm-2 basicinformation-icon-text-font-style" title={basicInformation.Pnl}>
                    {basicInformation.Pnl}
                  </div>
                </div>
              </div>
              <div className="col-md-2 remove-padding" style={{ padding: 5 }}>
                <div
                  className="col-md-2 remove-padding"
                  style={{ height: "100%", textAlign: "right" }}
                >
                  <div className="row">
                    <FontAwesomeIcon
                      icon={faTasks}
                      className="mr-sm icons-basicinformation"
                    />
                  </div>
                </div>
                <div className="col-md-10 ">
                  <div className="row1 px-sm-2 basicinformation-icon-header-text-font-style">
                    Sub Business Unit / Function 
                  </div>
                  <div className="row1 px-sm-2 basicinformation-icon-text-font-style basicinfo-text-limit" title={basicInformation.SubPnl}>
                    {basicInformation.SubPnl}
                  </div>
                </div>
              </div>
              <div className="col-md-2 remove-padding" style={{ padding: 5 }}>
                <div
                  className="col-md-2 remove-padding"
                  style={{ height: "100%", textAlign: "right" }}
                >
                  <div className="row">
                    <FontAwesomeIcon
                      icon={faChalkboardTeacher}
                      className="mr-sm icons-basicinformation"
                    />
                  </div>
                </div>
                <div className="col-md-10 ">
                  <div className="row1 px-sm-2 basicinformation-icon-header-text-font-style basicinfo-text-limit">
                    Practice Area / Sales Unit / Sub Function
                  </div>
                  <div className="row1 px-sm-2 basicinformation-icon-text-font-style" title={basicInformation.var_service_division_name}>
                    {basicInformation.var_service_division_name}
                  </div>
                </div>
              </div>
              <div className="col-md-2 remove-padding" style={{ padding: 5 }}>
                <div
                  className="col-md-2 remove-padding"
                  style={{ height: "100%", textAlign: "right" }}
                >
                  <div className="row">
                    <FontAwesomeIcon
                      icon={faFileAlt}
                      className="mr-sm icons-basicinformation"
                    />
                  </div>
                </div>
                <div className="col-md-10 ">
                  <div className="row1 px-sm-2 basicinformation-icon-header-text-font-style">
                    Layer
                  </div>
                  <div className="row1 px-sm-2 basicinformation-icon-text-font-style" title={basicInformation.LayerName}>
                    {basicInformation.LayerName}
                  </div>
                </div>
              </div>
              {/* <div className="col-md-2 remove-padding" style={{ padding: 5 }}>
                <div
                  className="col-md-2 remove-padding"
                  style={{ height: "100%", textAlign: "right" }}
                >
                  <div className="row">
                    <FontAwesomeIcon
                      icon={faChalkboardTeacher}
                      className="mr-sm icons-basicinformation"
                    />
                  </div>
                </div>
                <div className="col-md-10 ">
                  <div className="row1 px-sm-2 basicinformation-icon-header-text-font-style basicinfo-text-limit">
                    Sub P&L / Function Head
                  </div>
                  <div className="row1 px-sm-2 basicinformation-icon-text-font-style">
                    {basicInformation.SubPnlHead}
                  </div>
                </div>
              </div> */}
            </div>
            {/* Third Row */}
            {
              showMore &&
              <div className="row last-basicinformation-row">
                <div className="col-md-2 remove-padding" style={{ padding: 5 }}>
                  <div
                    className="col-md-2 remove-padding"
                    style={{ height: "100%", textAlign: "right" }}
                  >
                    <div className="row">
                      <FontAwesomeIcon
                        icon={faUserTie}
                        className="mr-sm icons-basicinformation"
                      />
                    </div>
                  </div>
                  <div className="col-md-10 ">
                    <div className="row1 px-sm-2 basicinformation-icon-header-text-font-style">
                      Sub Layer
                    </div>
                    <div className="row1 px-sm-2 basicinformation-icon-text-font-style" title={basicInformation.SubLayerName}>
                      {basicInformation.SubLayerName}
                    </div>
                  </div>
                </div>
                <div className="col-md-2 remove-padding" style={{ padding: 5 }}>
                  <div
                    className="col-md-2 remove-padding"
                    style={{ height: "100%", textAlign: "right" }}
                  >
                    <div className="row">
                      <FontAwesomeIcon
                        icon={faEnvelope}
                        className="mr-sm icons-basicinformation"
                      />
                    </div>
                  </div>
                  <div className="col-md-10 ">
                    <div className="row1 px-sm-2 basicinformation-icon-header-text-font-style">
                      Official Email ID
                </div>
                    <div
                      className="row1 px-sm-2 basicinformation-icon-text-font-style basicinfo-text-limit"
                      title={basicInformation.EmailAddress}
                    >
                      {/* <TooltipComponent
                        closeOn="Click"
                        content={basicInformation.EmailAddress}
                        position="BottomCenter"
                        isSticky={false}
                        cssClass='customtooltip'
                        style={{ fontWeight: 500 }}
                      > */}
                        {basicInformation.EmailAddress.length > 26 ? basicInformation.EmailAddress.substr(0, 24) + '...' : basicInformation.EmailAddress // NOSONAR
                        }
                      {/* </TooltipComponent> */}
                    </div>
                  </div>
                </div>
                {/* <div className="col-md-2 remove-padding" style={{ padding: 5 }}>
                  <div
                    className="col-md-2 remove-padding"
                    style={{ height: "100%", textAlign: "right" }}
                  >
                    <div className="row">
                      <FontAwesomeIcon
                        icon={faFax}
                        className="mr-sm icons-basicinformation"
                      />
                    </div>
                  </div>
                  <div className="col-md-10 ">
                    <div className="row1 px-sm-2 basicinformation-icon-header-text-font-style">
                      Official Mobile Number
                  </div>
                    <div className="row1 px-sm-2 basicinformation-icon-text-font-style">

                    </div>
                  </div>
                </div> */}
                <div className="col-md-2 remove-padding" style={{ padding: 5 }}>
                  <div
                    className="col-md-2 remove-padding"
                    style={{ height: "100%", textAlign: "right" }}
                  >
                    <div className="row">
                      <FontAwesomeIcon
                        icon={faPhoneSquareAlt}
                        className="mr-sm icons-basicinformation"
                      />
                    </div>
                  </div>
                  <div className="col-md-10 ">
                    <div className="row1 px-sm-2 basicinformation-icon-header-text-font-style basicinfo-text-limit">
                      Personal Mobile Number
                  </div>
                    <div className="row1 px-sm-2 basicinformation-icon-text-font-style" title={basicInformation.MobileNo}>
                      {basicInformation.MobileNo}
                    </div>
                  </div>
                </div>
                <div className="col-md-2 remove-padding" style={{ padding: 5 }}>
                  <div
                    className="col-md-2 remove-padding"
                    style={{ height: "100%", textAlign: "right" }}
                  >
                    <div className="row">
                      <FontAwesomeIcon
                        icon={faPhoneSquareAlt}
                        className="mr-sm icons-basicinformation"
                      />
                    </div>
                  </div>
                  <div className="col-md-2 ">
                    <div className="row1 px-sm-2 basicinformation-icon-header-text-font-style basicinfo-text-limit">
                      Alternative Mobile Number
                  </div>
                    <div className="row1 px-sm-2 basicinformation-icon-text-font-style" title={basicInformation.AltMobileNo}>
                      {basicInformation.AltMobileNo}
                    </div>
                  </div>
                </div>
              </div>
            }
            {/* <div style={{float:"right"}}> */}
            <div style={{ float: "right", position: 'absolute', bottom: 0, right: 0 }}>
              {
                showMore ? // NOSONAR

                  <div
                    style={{ fontSize: 18, cursor: 'pointer' }}
                    className="mr-sm"
                    onClick={() => this.setState({ showMore: false })}
                    // title={'Hide'}
                  >
                    <TooltipComponent
                      closeOn="Click"
                      content='Hide'
                      position="BottomCenter"
                      isSticky={false}
                      cssClass='customtooltip'
                      width={80}
                    >
                      <span style={{ fontSize: 18, cursor: 'pointer' }}>
                        ...
                      </span>
                    </TooltipComponent>
                  </div>
                  :
                  <div
                    style={{ fontSize: 18, cursor: 'pointer' }}
                    className="mr-sm"
                    onClick={() => this.setState({ showMore: true })}
                    // title={'Show more'}
                  >
                    <TooltipComponent
                      closeOn="Click"
                      content='Show more'
                      position="BottomCenter"
                      isSticky={false}
                      cssClass='customtooltip'
                      width={100}
                    >
                      <span style={{ fontSize: 18, cursor: 'pointer' }}>
                        ...
                      </span>
                    </TooltipComponent>
                  </div>
              }
            </div>
          </div>
        ) : (
            <div className="control-section col-md-12" style={{ height: 130 }}>
              <ProgressBar />
            </div>
          )}
      </div>
    );
  }
}
BasicInformation.propTypes = {
  authUser: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
BasicInformation.defaultProps = {
  authUser: "",
};
const mapStateToProps = ({ auth }) => {
  const { authUser } = auth;
  return { authUser };
};

export default connect(mapStateToProps, {
  addUpdateBasicInfo,
  saveBasicInformationInRedux,
  setCentralOkrKPIRedux
})(BasicInformation);
