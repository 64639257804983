
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux';
import { DropDownButtonComponent } from '@syncfusion/ej2-react-splitbuttons';
import LnAService from '../../../../services/lna/lnaservice';
import { updateLnAData } from '../../../../redux/actions/lna/lnaaction';
import { leaveDateFormat } from '../../../../app/other/commonfunction';
import ProgressBar from '../../../progressbar/progressbar';
import ErrorTabMessage from '../../../errormessage/errormessage';
import { SPECIAL_ATTENDANCE, APPLYLEAVE } from '../../../../redux/constant/meetingcategoryconstant'
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,

} from "@syncfusion/ej2-react-grids";
import { getYearData } from '../../../../app/other/commonlayoutfunction';

const SAMPLECSS = `
.e-grid-min-height .e-gridheader .e-headercell .e-headercelldiv {
  height: auto;
  margin-left: 0px !important;
}
.e-grid .e-rowcell{
  padding:0px !important;
}
@media screen and (max-width: 1366px) and (max-height: 768px),
screen and (max-width: 1440px) and (max-height: 900px) {
  .leave-application-body .e-grid .e-gridcontent tr.e-row .e-rowcell {
      font-size: 0.7rem !important;
    }
    .e-time-icon{
      font-size:0.84rem !important;
      margin-left:10px !important;
      
    }  
}

// th {
//   top: 0;
//   position: sticky;
// }
// .statusData{
//   // height:290px !important;
//   height:334px !important;
//   overflow-y:auto;
// }
// .e-dropdown-popup ul {
//   padding:0px !important
// }
`

const LeavesTransfer = (props) => {
  const { authUser, fyId,  type, categoryName } = props;
  const getMenuData = (types) => {
    switch (types) {
      case 'Applied':
        return [
          { text: 'View', iconCss: 'e-icons e-page-one' },
          { text: 'Edit', iconCss: 'e-icons e-edit' },
          { text: 'Cancel', iconCss: 'e-icons e-cancel-2' },
        ];


      case 'Approved':
      case 'Utilized':
        return [
          { text: 'View', iconCss: 'e-icons e-page-one' },
          { text: 'Cancel', iconCss: 'e-icons e-cancel-2' },
        ];
      case 'Rejected':
        return [
          { text: 'View', iconCss: 'e-icons e-page-one' },
          // { text: 'Cancel', iconCss: 'e-icons e-cancel-2' },
        ];
      default:
        return [
          { text: 'View', iconCss: 'e-icons e-page-one' }
        ];

    }
  };

  const [leaveTransferData, setTransferStatusData] = useState([])
  const [isLoading, setIsLoading] = useState(true)
  const [allHeight, setAllHeight] = React.useState({
    containerHeight: null,
    gridWidth: null
  });
  useEffect(() => {
    setTimeout(() => {
      try {
        const val = document.getElementById('LnAMasterGridLeaveTransfer');
        val && val.ej2_instances[0].refresh();
      } catch (err) {
        console.log(err);
      }
    }, 800);
    setIsLoading(true)
    getTransferStatusData();
  }, [])
  useEffect(() => {
    if (props.updateFinancialYear === "updated") {
      setIsLoading(true)
      getTransferStatusData()
      props.updateLnAData({ updateFinancialYear: "" })
    }

  }, [props.updateFinancialYear === "updated"])

  useEffect(() => {
    if ((props.leaveBalanceStatus === 'updated' || props.compOffClusterFlag === 'updated' || props.compOffClusterFlag === 'saved' || props.leaveTransferFlag === 'updated'
      || categoryName === SPECIAL_ATTENDANCE || categoryName === APPLYLEAVE) && type !== "approvalsubmit") {
      getTransferStatusData()
      props.updateLnAData({ leaveBalanceStatus: "", applyFor: authUser, compOffClusterFlag: '', leaveTransferFlag: '' })
    }
  }, [props.leaveBalanceStatus === 'updated', props.compOffClusterFlag === 'updated', props.compOffClusterFlag === 'saved', props.leaveTransferFlag === 'updated', categoryName === SPECIAL_ATTENDANCE, categoryName === APPLYLEAVE])

  useEffect(() => {
    if (!fyId) {
      getYearData(authUser)
        .then(result => {
          props.updateLnAData({ fyId: result })
        })
        .catch(err => {
          console.log('err===', err)
        });
    }
  }, [])


  const getTransferStatusData = async () => {
    if (fyId) {
      const onTransferStatusData = await LnAService.getLeavesToBeTransferData('Details', authUser, fyId)
      const leaveTransfersDataList = onTransferStatusData.Result
      setTransferStatusData(leaveTransfersDataList)
      setIsLoading(false)
    }
  }


  const onSelect = (args, data) => {
    try {
      if (args.item.text === 'View') {
        props.updateLnAData({
          leaveApplyLoadName: 'leave-view',
          leaveData: data,
          leaveApplicationId: data.leaveId,
          leaveType: '',
          leaveStartDate: null,
          leaveEndDate: null,
          leaveReason: '',
          appliedDays: '',
          leaveClusterId: '',
          approverId: 0,
          attachmentName: '',
        })
      } else if (args.item.text === 'Edit') {
        props.updateLnAData({
          leaveApplyLoadName: 'leave-edit',
          leaveType: data.leaveClusterUid,
          leaveStartDate: leaveDateFormat(data.fromDate),
          leaveEndDate: leaveDateFormat(data.toDate),
          leaveReason: data.reason,
          notificationTo: data.notifyIds,
          reliever: data.relieverId,
          appliedDays: data.noOfDays,
          leaveApplicationId: data.leaveId,
          dayBreakText: data.dayBreak,
          applyFor: data.applyFor,
          attachmentName: data.attachmentName,
          leaveStartDateEdit: data.fromDate,
          leaveEndDateEdit: data.toDate,
          dayBreakBackgroundColor: data.leaveColor,
        })

      } else if (args.item.text === 'Cancel') {
        const { leaveId } = data
        props.updateLnAData({
          leaveApplyLoadName: 'leave-cancel',
          leaveData: data,
          leaveApplicationId: leaveId
        })
      }
    } catch (error) {
      console.log("🚀 ~ file: leavesbalance.jsx:147 ~ onSelect ~ error", error)
    }
  }
  useEffect(() => {
    window.addEventListener("resize", updateDimensions());//NOSONAR
  }, []);

  const updateDimensions = () => {
    const containerHeight = window.innerHeight - 299
    const isThirteenHundred = window.innerWidth <= 1366;
    const gridWidth = isThirteenHundred ? window.innerWidth - 848 : window.innerWidth - 1171
    setAllHeight(prevState => ({
      ...prevState,
      containerHeight: containerHeight,
      gridWidth: gridWidth
    }))
  }

  const actionTemplate = (balance) => {

    return (
      <DropDownButtonComponent
        items={getMenuData(balance.applicationStatus)}
        select={(e) => onSelect(e, balance)}
        iconCss='e-icons e-more-vertical-2'
        cssClass='e-caret-hide e-custom e-vertical'
        iconPosition='center'
      ></DropDownButtonComponent>
    );
  }


  const dataSourceChanged = (pComponentId) => {
    try {
      setTimeout(() => {
        if (document.getElementById(pComponentId) !== null) { document.getElementById(pComponentId).ej2_instances[0].refresh(); }
      });
    } catch (error) { console.log(error); }
  };
  const dataBound = () => {
    setIsLoading(false);
  };

  const onDataBinding = (pComponentId) => {
    // spinner(true)
    setIsLoading(true);
    try {
      setTimeout(() => {
        document.getElementById(pComponentId).ej2_instances[0].refresh();
      });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div id="LnAMasterGrid">
      <style>{SAMPLECSS}</style>
      {(!isLoading && leaveTransferData.length > 0) &&
        <GridComponent
          id='LnAMasterGridLeaveTransfer'
          dataSource={leaveTransferData}
          rowHeight={40}
          // width={allHeight.gridWidth}
          gridLines={'Both'}
          height={allHeight.containerHeight}
          allowTextWrap={true}
          textWrapSettings={{ wrapMode: "Header" }}
          dataSourceChanged={() => dataSourceChanged("LnAMasterGrid")}
          dataBound={dataBound}
          onDataBinding={() => onDataBinding("LnAMasterGrid")}
        >
          <ColumnsDirective>
            <ColumnDirective
              textAlign="Center"
              template={actionTemplate}
              field="applicationStatus"
              headerText="Action"
              width="60"
            />
            <ColumnDirective field='sourceLeave' width='80' headerText='Source Leave Type' textAlign="Center" />
            <ColumnDirective field='destinationLeave' width='100' textAlign="Center" headerText='Destination Leave Type' />
            <ColumnDirective field='noOfDays' width='120' textAlign="Center" headerText='No. Of Leaves To Be Transferred' />
            <ColumnDirective field='status' width='80' textAlign="Center" headerText='Status' />
          </ColumnsDirective>
        </GridComponent>
      }


      {isLoading && (
        <div
          style={{
            zIndex: 999999,
            position: "absolute",
            marginTop: "20%",
            marginLeft: "49%",
          }}
        >
          <ProgressBar />
        </div>
      )}
      {(leaveTransferData.length === 0 && !isLoading && props.type !== 'approvalsubmit') && (
        <ErrorTabMessage message="No Data Available" />
      )}
    </div>
  )
}



const mapStateToProps = ({ meetingcategory, auth, meetingmaster, lnareducer }) => {
  const { authUser } = auth;
  const { categoryName } = meetingcategory;
  const { leaveApplyStatus, leaveBalanceStatus, compOffClusterFlag, leaveTransferFlag, updateFinancialYear, fyId } = lnareducer;
  const { currentFinancialYearId } = meetingmaster;
  return { authUser, currentFinancialYearId, leaveApplyStatus, leaveBalanceStatus, categoryName, compOffClusterFlag, leaveTransferFlag, updateFinancialYear, fyId }
}
export default connect(mapStateToProps, { updateLnAData })(LeavesTransfer)