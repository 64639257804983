import React, { Component } from "react";
import Appointment from "./appointment/appointment";
import Assetlist from "./assetlist/assetlist";
import Checklist from "./checklist/checklist";
import Krakpi from "./krakpi/krakpi";
import Landd from "./landd/landd";
import Role from "./role/role";
import Salary from "./salary/salary";
import { connect } from "react-redux";
import "./employeeprofiletabs.css";
import { EMPLOYEE_UPLOAD } from "../../redux/constant/entryexit/candidateconstant";
import { addUpdateBasicInfo } from "../../redux/actions/entryexit/onboardingaction";
import { dencrypt } from "../../app/other/commonfunction";
import { defEnvironment } from "../../config/config";

const SAMPLECSS_LIVE = `
.tab-button-width{
  width:20% !important;      
}
`
const SAMPLECSS_QA = `
.tab-button-width{
  width:20% !important;      
}
`

export class EmployeeProfileTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false
    };
  }

  componentDidMount() {
    try {
      const { search } = window.location;
      const params = new URLSearchParams(search);
      const empId = dencrypt(params.get("empId"));
      this.props.addUpdateBasicInfo({ empid: empId })

      this.setState({ isLoaded: true }, () => { document.getElementById("Checklist").style.display = "block" })

    } catch (error) {
      console.log(error, 'error')
    }
  }

  openTab = (evt, tabName) => {
    let i;
    const event = evt;
    const tabcontent = document.getElementsByClassName("tabcontent");
    // eslint-disable-next-line
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }
    const tablinks = document.getElementsByClassName("tablinks");
    // eslint-disable-next-line
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace(" active", "");
    }
    document.getElementById(tabName).style.display = "block";
    event.currentTarget.className += " active";
  };

  render() {
    const{expanded} = this.props
    const { isLoaded } = this.state;
    return (
      <div className="tabs-main-div">
        <style>{defEnvironment === "QA" ? SAMPLECSS_QA : SAMPLECSS_LIVE}</style>
        {isLoaded &&
          <>
            <div className="tab col-12">
              <div className="tabs-div-width">
                {/* <button
                  type="submit"
                  className="tablinks tab-button-width active"
                  onClick={(e) => this.openTab(e, "Initiate")}
                >
                  INITIATE
            </button> */}
                {/* <button
                  type="submit"
                  className="tablinks tab-button-width active"
                  onClick={(e) => this.openTab(e, "Role")}
                >
                  ROLE
            </button>
                <button
                  type="submit"
                  className="tablinks tab-button-width"
                  onClick={(e) => this.openTab(e, "Krakpi")}
                >
                  KRA/KPI
            </button> */}
                <button
                  type="submit"
                  className="tablinks tab-button-width active"
                  onClick={(e) => this.openTab(e, "Checklist")}
                >
                  CHECKLIST
            </button>
                <button
                  type="submit"
                  className="tablinks tab-button-width"
                  onClick={(e) => this.openTab(e, "Assetlist")}
                >
                  ASSET LIST
            </button>
                <button
                  type="submit"
                  className="tablinks tab-button-width"
                  onClick={(e) => this.openTab(e, "Salary")}
                >
                  SALARY
            </button>
                <button
                  type="submit"
                  className="tablinks tab-button-width"
                  onClick={(e) => this.openTab(e, "Appointment")}
                >
                  APPOINTMENT
            </button>
                <button
                  type="submit"
                  className="tablinks tab-button-width"
                  onClick={(e) => this.openTab(e, "Landd")}
                >
                  L&D
            </button>
              </div>
            </div>

            {/* <div id="Initiate" className="tabcontent">
              <Initiate />
            </div> */}
            <div id="Role" className="tabcontent">
              <Role />
            </div>
            <div id="Krakpi" className="tabcontent">
              <Krakpi />
            </div>
            <div id="Salary" className="tabcontent">
              <Salary />
            </div>
            <div id="Landd" className="tabcontent">
              <Landd />
            </div>
            <div id="Checklist" className="tabcontent">
              <Checklist candidateType={EMPLOYEE_UPLOAD} expanded={expanded} />
            </div>
            <div id="Assetlist" className="tabcontent">
              <Assetlist />
            </div>
            <div id="Appointment" className="tabcontent">
              <Appointment />
            </div>
          </>}
      </div>

    );
  }
}

const mapStateToProps = ({ onboardingreducer }) => {
  const { empid } = onboardingreducer;
  return { empid };
};
export default connect(mapStateToProps, {
  addUpdateBasicInfo,
})(EmployeeProfileTabs);
