import React from "react";
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import AdminServices from "../../services/adminservices";
import queryString from "query-string";
import { encrypt } from '../../app/other/commonfunction';

// ("@fortawesome/free-solid-svg-icons"); /*NOSONAR*/
const Adminmodule = (props) => {
  const [adminModules, setAdminModules] = useState([]);
  const history = useHistory();  

  useEffect(() => {
    getData()
  }, [])

  const getData = () => {
    const { authUser } = props;
    try {
      const result = Promise.resolve(
        AdminServices.getMasterAdminModuleData({ userId: authUser, type: "main", id: 0, masterId: 0 })
      );
      result.then((result) => {
        if (result !== null) {
          setAdminModules(result.data)
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const openAdminModule = (adminModuleId) => {
    try {
      const params = queryString.parse(props.location.search);
      if (typeof params.id !== 'undefined' && params.id !== null) {
        history.push(`${props.location.pathname}?id=${params.id}&appId=${params.appId}&adminId=${encrypt(adminModuleId)}`)
      } 
    } catch (error) {
      console.log("? ~ file: adminmodule.jsx ~ line 41 ~ openAdminModule ~ error", error)
    }
  }  

  return (
    <div className="row1" >
      <div style={{ fontSize: 30, marginLeft: "20px" }}> Admin Modules</div>
      <div
        className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
        style={{ height: "100vh" }}
      >
        {adminModules.map((items) => (
          <div key={`adminModule${items.Id}`}
            className=" col-lg-3 col-md-3 col-sm-3 col-xs-3"
            style={{ paddingTop: "20px", paddingBottom: "20px" ,}}
            onClick={() => { openAdminModule(items.Id) }}
          >
            <div className="admin-module-box">
              <div
                className="col-lg-12 col-md-12 col-sm-12 col-xs-12"
                style={{
                  fontSize: "1.3vw",
                  marginBottom: "1vw",
                  marginLeft: "1vw",
                }}
              >
                <div className="col-lg-10 col-md-10 col-sm-10 col-xs-10 " style={{ display: "flex", gap: "1vw", alignItems: "center" }}>
                  <div>
                    {" "}
                    <i className={items.iconCss} style={{ color: "#B7B7BB", fontSize: "140%" }} />
                  </div>
                  <div className="module-names">{items.adminModuleName} </div>
                </div>
                <div className="col-lg-2 col-md-2 col-sm-2 col-xs-2"
                  style={{ color: "white", paddingLeft: "0px" }} >
                  <FontAwesomeIcon
                    style={{ color: "white" }}
                    icon={faChevronRight}
                  />{" "}
                </div>
              </div>
              <div style={{
                margin: "30px 30px 70px 2vw",
                fontWeight: "normal",
                fontSize: "111%",
                // color: "#939399",
                height: "7vh",
              }} >
                {" "}
                {items.descrption}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const mapStateToProps = ({ auth, router }) => {
  const { authUser } = auth;
  const { location } = router;
  return { authUser, location };
};

export default connect(mapStateToProps, {
})(Adminmodule);



