import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import OKRMasterSetup from '../../../components/okradmincomponents/setup/commonmastersetup'
import NotificationContainer from "../../../components/notification/notifycontainer";
import { CheckBoxSelection, ComboBoxComponent, Inject, MultiSelectComponent } from "@syncfusion/ej2-react-dropdowns";
import axios from "axios";
import { urlConfig } from "../../../config/config";
import { updateFinancialYear } from "../../../redux/actions/admin/adminaction";
import { setTeamMemberSelectionData, setCommonMasterLoading, updateOKRData } from '../../../redux/actions/okr/okractions'
import { AXIS, AXIS_CUTOFF, FREQUENCY, NINE_BLOCKER, PERFORMANCE, POTENTIAL, RATING_MASTER, RATING_SYSTEM, SUB_POTENTIAL_CALCULATION, POTENTIAL_CALCULATION, TEAM_MEMBER } from "../../../redux/constant/meetingcategoryconstant";
import { Query } from "@syncfusion/ej2-data";
import { setMeetingMasterUpdate } from '../../../redux/actions/meetingmasteraction';

function SetupContainer(props) {
  let { changePopUpHandler, fyId,
    // okrMasterFlag, 
    setCommonMasterLoading, openPopup } = props;
  const [teamMember, setTeamMember] = useState([])
  const [hight, setHight] = useState({
    containerGridHeight: null,
    windowAdjust: null,
    containerHeight: null,
    containerWidth: null,
  });
  useEffect(() => {
    window.addEventListener("resize", updateDimensions()); // NOSONAR
  }, []);
  let { teamMemberSelectionList } = props
  const updateDimensions = () => {
    // if (window.innerHeight < 650) {
    //   const windowAdjusts = window.innerHeight - 150;
    //   const containerGridHeight = window.innerHeight - 912;
    //   const containerHeight = window.innerHeight - 210;
    //   setHight({
    //     containerGridHeight: containerGridHeight,
    //     containerHeight: containerHeight,
    //     windowAdjust: windowAdjusts,
    //   });
    // } else {
    // const isSixteenHundred = window.innerHeight < 750;
    const isOneTwentyFiveScale = window.innerHeight < 715;
    const isThirteenHundred = window.innerHeight < 618;
    // const isOneTwentyFiveInSixteenHundred = window.innerHeight < 571;
    // const isOneTwentyFiveInThirteenHundred = window.innerHeight < 465;

    const windowAdjusts = window.innerHeight - 160;

    const containerGridHeight = ((props.masterId + "") === FREQUENCY) ? window.innerHeight - 800 :
      isThirteenHundred ? hight.containerHeight - 35
        : hight.containerHeight - 40;

    const containerHeight = ((props.masterId + "") === FREQUENCY) ? window.innerHeight - 760 :
      isThirteenHundred ? window.innerHeight - 130
        : isOneTwentyFiveScale ? window.innerHeight - 130
          : window.innerHeight - 133;
    const containerWidth = window.innerWidth - 190;
    setHight({
      containerGridHeight: containerGridHeight,
      containerHeight: containerHeight,
      windowAdjust: windowAdjusts,
      containerWidth: containerWidth,
    });
    // }
  };

  // useEffect(() => {
  //   if (props.teamMemberSelectionList && fyId && props.masterId+'' === TEAM_MEMBER) {
  //     setCommonMasterLoading({commonMasterLoading:true})
  //     axios.get(`${urlConfig.tmlcApiUrl}getteammembermatrixdata/${props.teamMemberSelectionList}/${fyId}`, {
  //       headers: {
  //         authorization: localStorage.getItem("jwtToken"),
  //         Accept: "application/json",
  //         "Content-Type": "application/json",
  //       }
  //     }).then((resp) => {
  //       setTeamMember(resp.data.Result)
  //     })
  //   }
  // }, [props.teamMemberSelectionList, fyId, okrMasterFlag])

  const teamMemberSearch = async()=>{
    setCommonMasterLoading({commonMasterLoading:true})
      axios.get(`${urlConfig.tmlcApiUrl}getteammembermatrixdata/${props.teamMemberSelectionList}/${fyId}`, {
        headers: {
          authorization: localStorage.getItem("jwtToken"),
          Accept: "application/json",
          "Content-Type": "application/json"
        }
      }).then((resp) => {
        setTeamMember(resp.data.Result)
      })
  }

  useEffect(() => {
    props.updateFinancialYear({ fyId: null })
  }, [])
  useEffect(() => {
    if (props.teamMemberMatrixStatus === "updated") {
      teamMemberSearch();
      props.updateOKRData({ teamMemberMatrixStatus: '' })
    }
  }, [props.teamMemberMatrixStatus === "updated"])

  const getGridColumnName = () => {
    switch (props.masterId + "") {
      case AXIS:
        return 'axisMaster'
      case AXIS_CUTOFF:
        return 'axisCutOff'
      case NINE_BLOCKER:
        return "blockermaster"
      case RATING_SYSTEM:
        return "ratingSystem"
      case RATING_MASTER:
        return "ratingMaster"
      case POTENTIAL_CALCULATION:
        return "potentialCalculation"
      case SUB_POTENTIAL_CALCULATION:
        return "subPotentialCalculation"
      case PERFORMANCE:
      case POTENTIAL:
        return "productivityMaster"
      case FREQUENCY:
        return "frequencyMaster"
      case TEAM_MEMBER:
        return "teamMember"
      default:
        return ""
    }
  }
  const renderSwitch = () => {
    let { categoryName, masterId, history } = props;
    let activeMasterId = (masterId == 0 ? categoryName : masterId).toString();
    return (
      <OKRMasterSetup
        categoryName={activeMasterId}
        history={history}
        changePopUpHandler={changePopUpHandler}
        masterId={masterId}
        gridName={getGridColumnName()}
        teamMemberData={(props.teamMemberSelectionList && fyId && teamMember) ? teamMember : []}
        fyId={fyId}
        openPopup={openPopup}
      />
    );

  };
  const handleFilteringByName = (e) => {
    const { othersCheckAvailablity } = props;
    const newData = [...othersCheckAvailablity];
    if (newData != null && newData.length > 0) {
      let query = new Query();
      query =
        e.text !== ""
          ? query.where("nameWithDepartment", "contains", e.text, true)
          : query;
      e.updateData(newData, query);
    }
  };
  const removeItem = (e) => {
    let newSelectedUserIds = teamMemberSelectionList;
    newSelectedUserIds.filter((el) => el !== e.itemData.UserId);
  }

  const onChangedInputFinancialYear = (e) => {
    props.setMeetingMasterUpdate({ currentFinancialYearId: e.itemData ? e.itemData.Id : '' })
  }
  return (
    <div
      className="col-xs-12 col-sm-12 col-lg-12 col-md-12 p-md"
      style={{ backgroundColor: "#F2F2F2" }}
    >
      <NotificationContainer />
      <div
        className="col-xs-12 col-sm-12 col-lg-12 col-md-12"
        id='okrAdminContainer'
        style={{
          backgroundColor: "#ffffff",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          borderRadius: ".4rem",
          height: hight.containerHeight,
          // pointerEvents: (openPopup === "add" || openPopup === "edit") ? "none" : "auto",
        }}
      >
        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
          <div className="col-sm-12 col-md-12 col-lg-12">
            <div
              style={{
                borderStartEndRadius: "0.4em",
                backgroundColor: props.masterId === 25 ? "#fff" : "#343A40",
                borderStartStartRadius: "0.4em",
                height: props?.masterId === 25 ? "4.7rem" : '',
                display: "flex",
                border: "1px solid #DBDBDD",
                alignItems: "center",
                padding: "13px 18px 13px",
                fontWeight: 600,
              }}
            >
              <div
                className={props?.masterId === 25 ? "col-xs-12 col-sm-12 col-lg-12 col-md-12  setting-disable" : "col-xs-12 col-sm-12 col-lg-12 col-md-12 "}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                {props?.masterId !== 25 &&
                  <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <div>

                        <button
                          style={{ borderRadius: "3px", padding: "6px 15px", fontSize: "14px", border: "1px solid #707070" }}
                          className="lna-add-button"
                          onClick={() => changePopUpHandler("open")}
                        >
                          + Add
                        </button>
                      </div>

                      {/* <div>
                      <button className="lna-add-button" style={{ marginLeft: "1rem" }}>
                        <FontAwesomeIcon
                          style={{ fontSize: "1rem" }}
                          icon={faTrashCan}
                        /> Delete
                      </button>
                    </div> */}
                    </div>
                    <div className='col-sm-2 col-md-2 col-lg-2 financialYear-box' style={{ marginRight: '25px', backgroundColor: (openPopup !== 'close') ? 'GrayText' : '', border: "1px solid black" }}>
                      <ComboBoxComponent
                        id='fyYear'
                        name='fyErr'
                        data-msg-containerid='fyErr'
                        allowCustom={false}
                        floatLabelType="Auto"
                        fields={{ value: "Id", text: "Name" }}
                        dataSource={props.finacialYearData}
                        value={props.currentFinancialYearId}
                        change={(e) => onChangedInputFinancialYear(e)}
                        cssClass="e-outline"
                      />
                    </div>
                  </div>
                }


                {props?.masterId === 25 && <div className="matrix-member-com" style={{ display: "flex", alignItems: "center", }}>
                  <MultiSelectComponent
                    id="teamMemberList"
                    onChange={(e) => { props.setTeamMemberSelectionData({ teamMemberSelectionList: e.value }) }}
                    popupHeight='500px'
                    style={{ fontWeight: 100 }}
                    cssClass="e-outline matrix-selection-team"
                    fields={{ value: "UserId", text: "nameWithDepartment", groupBy: 'Department' }}
                    placeholder="Team Member"
                    mode="CheckBox"
                    enableGroupCheckBox={true}
                    filterType="Contains"
                    allowFiltering={true}
                    showSelectAll={true}
                    showDropDownIcon
                    filterBarPlaceholder="Search team member"
                    dataSource={props.othersCheckAvailablity}
                    filtering={handleFilteringByName}
                    removed={removeItem}
                    showClearButton={false}
                    floatLabelType="Auto"
                  >
                    <Inject services={[CheckBoxSelection]} />
                  </MultiSelectComponent>

                  <ComboBoxComponent
                    allowCustom={false}
                    floatLabelType="Auto"
                    fields={{ value: "Id", text: "Name" }}
                    dataSource={props?.finacialYearData}
                    onChange={(e) => props.updateFinancialYear({ fyId: e?.value })}
                    name={`fyIdrError`}
                    id={`fyId`}
                    data-msg-containerid={`fyIdrError`}
                    cssClass="e-outline matrix-selection-financial"
                    placeholder="Financial Year"
                  />

                  <button
                    style={{
                      borderRadius: "3px", border: "1px solid #F94F5E",
                      color: "#FFFFFF", width: "90px", height: '2.5rem', padding: "6px 12px", fontSize: "14px", background: '#F94F5E'
                    }}
                    className="lna-add-button"
                    disabled={!props.teamMemberSelectionList || !fyId}
                    onClick={() => teamMemberSearch()}
                  >
                    View
                  </button>
                </div>}

                {props?.masterId === 25 && props.teamMemberSelectionList && fyId && teamMember.length > 0 && <button
                  style={{ borderRadius: "3px", border: "1px solid #F94F5E", color: "#F94F5E", width: "90px", padding: "6px 12px", fontSize: "14px" }}
                  className="lna-add-button"
                  disabled={!fyId}
                  onClick={() => changePopUpHandler("open", teamMember.map(e => e.userId + '').join(','), teamMember)}
                >
                  Settings
                </button>}

              </div>
            </div>
          </div>
        </div>

        <div
          className={props?.masterId === 25 ? "lna-main-div" : "lna-main-div-sec"}
          style={{
            height: hight.containerGridHeight,
            // width: hight.containerWidth
          }}
        >
          {
            getGridColumnName() !== "" ?
              renderSwitch()
              :
              <>
              </>
          }

        </div>
      </div>
    </div>
  );
}

const mapStateToProps = ({ meetingcategory, meetingmaster, adminreducer, okrreducer }) => {
  const { finacialYearData, currentFinancialYearId, othersCheckAvailablity, financialYearId } = meetingmaster;
  const { categoryName } = meetingcategory;
  const { fyId } = adminreducer;
  const { okrMasterFlag, teamMemberSelectionList, teamMemberMatrixStatus } = okrreducer;
  return {
    categoryName,
    finacialYearData,
    currentFinancialYearId, othersCheckAvailablity, fyId,
    okrMasterFlag,
    teamMemberSelectionList,
    teamMemberMatrixStatus,
    financialYearId
  };
};
export default connect(mapStateToProps, { updateFinancialYear, setTeamMemberSelectionData, setCommonMasterLoading, updateOKRData, setMeetingMasterUpdate })(SetupContainer);
