/*
 * Author Somesh Dwivedi
 * Date  06-03-2024
 * @description This component is used to render the grid header.
 * */

//Importing required module and components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBarsFilter } from '@fortawesome/pro-light-svg-icons';
import { Inject, } from "@syncfusion/ej2-react-lists";
import { CheckBoxSelection, MultiSelectComponent } from "@syncfusion/ej2-react-dropdowns";
import { months } from "../../../assets/constants/constants";
import { useEffect, useState } from 'react';
import './leavegridstyle.css'
const LeaveGridHeader = ({ secActiveTab, dataSourceChanged, setLeaveData, originalLeaveData,activeTab }) => {
  //useStates

  //State to manage the selected months in the dropdown.
  const [selectedMonths, setSelectedMonths] = useState([])
  //State to manage the months dropdown data 

  // Create a new Date object
  var currentDate = new Date();
  const getDate = (timePeriod) => {
    if (timePeriod === 'thisMonth') {
      const startDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
      const endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
      return { startDate, endDate };
    }
    else if (timePeriod === 'nextMonth') {
      const startDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 1);
      const endDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 2, 0);
      return { startDate, endDate };
    }
    else if (timePeriod === 'onword') {
      const startDate = new Date(currentDate.getFullYear(), currentDate.getMonth() + 2, 1);
      const endDate = new Date(currentDate.getFullYear() + 1, 2, 31);
      return { startDate, endDate }
    }
    else if (timePeriod === 'thisWeek') {
      const startDate = currentDate;
      // Get the day of the week (0 for Sunday, 1 for Monday, ..., 6 for Saturday)
      const currentDay = currentDate.getDay();

      // Calculate the difference between the current day and Saturday (7 - currentDay)
      // Add the difference to the current date to get the end of the week
      const endDate = new Date(currentDate.getTime() + (7 - currentDay) * 24 * 60 * 60 * 1000);
      return { startDate, endDate }
    }
    else if (timePeriod === 'nextWeek') {
      const currentDay = currentDate.getDay();

      // Calculate the difference between the current day and Saturday (8 - currentDay)
      // Add the difference to the current date to get the start date of next week
      const startDate = new Date(currentDate.getTime() + (8 - currentDay) * 24 * 60 * 60 * 1000);
      const endDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate() + 6);
      return { startDate, endDate }
    }
  }



  //To filter the leave data on the basis of months selected
  const filterDataOnMonth = (selectedMonths) => {    
    if (selectedMonths && selectedMonths.length > 0) {
      const selectedMonthsName = months.map(ele => {
        if (selectedMonths.includes(ele.Id)) {
          return ele.Name.substring(0, 3);
        }
        return null;
      }).filter(month => month !== null);
      const filteredGridData = originalLeaveData.filter(ele => {
        const leaveMonthName = ele.startDate.substring(3, 6);
        return selectedMonthsName.includes(leaveMonthName)
      })
      setLeaveData(filteredGridData);
    } else {
      setLeaveData(originalLeaveData)
    }
    dataSourceChanged('leaveReportNew');
  }


  const handleChangeMonths = (event) => {
    setSelectedMonths(event.value);

    filterDataOnMonth(event.value);
  };
  const formatDate = (timestamp) => {
    const date = new Date(timestamp);

    const day = date.getDate().toString().padStart(2, '0');
    const month = new Intl.DateTimeFormat('en-US', { month: 'short' }).format(date);
    const year = date.getFullYear();

    return `${day}-${month}-${year}`;
  }
  const headerDate = {
    today: formatDate(currentDate),
    thisWeek: `From ${formatDate(getDate('thisWeek').startDate)} to ${formatDate(getDate('thisWeek').endDate)}`,
    nextWeek: `From ${formatDate(getDate('nextWeek').startDate)} to ${formatDate(getDate('nextWeek').endDate)}`,
    thisMonth: `From ${formatDate(getDate('thisMonth').startDate)} to ${formatDate(getDate('thisMonth').endDate)}`,
    nextMonth: `From ${formatDate(getDate('nextMonth').startDate)} to ${formatDate(getDate('nextMonth').endDate)}`,
    onword: `From ${formatDate(getDate('onword').startDate)} to ${formatDate(getDate('onword').endDate)}`,
  }

  const clearFilter = () => {
    if (document.getElementById('leaveReportNew')) {
      document.getElementById('leaveReportNew').ej2_instances[0].clearFiltering();
      document.getElementById('leaveReportNew').style.border = "1px solid #fff";
      dataSourceChanged('leaveReportNew')
    }
    setSelectedMonths([]);
    setLeaveData(originalLeaveData);
  
  }

  useEffect(() => {
    clearFilter();
  },[activeTab])


  return (
    <div className="allTeamReportHeader pl-md pr-md leave-grid-header-parent" >
      <div className='leave-grid-header-year'>
        Leaves & Holidays - {headerDate[secActiveTab]}
      </div>
      <div className="leave-balance-header-button">
        {secActiveTab === 'onword' && <div id="leaveReportNewMonthsDropdown">
          <style>{dropdownStyle}</style>
          <MultiSelectComponent
            id="month-select"
            name="month-select"
            dataSource={months}
            fields={{ text: 'Name', value: 'Id' }}
            placeholder="Select Month"
            cssClass="e-outline"
            floatLabelType="Auto"
            showSelectAll={true}
            selectAllText="All"
            popupHeight="250px"
            width={'103%'}
            change={handleChangeMonths}
            value={selectedMonths}
            showDropDownIcon
            filterBarPlaceholder="Select Month"
            data-msg-containerid="srvprdError"
            mode="CheckBox"
            allowFiltering={false}
            showClearButton={false}

          >
            <Inject services={[CheckBoxSelection]} />
          </MultiSelectComponent>
        </div>
        }
        <div className="approval-list-element approval-list-filter" style={{ cursor: "pointer", fontFamily: 'Arial, Regular' }} id='leaveReportNewFilter' onClick={clearFilter}>
          <div className="approval-list-filter-icon">
            <FontAwesomeIcon
              icon={faBarsFilter}
              className='leave-grid-header-icon'
            />
          </div>
          <div className='leave-grid-header-filter-button' style={{ fontSize: '15px' }}>Reset Filters</div>
        </div>
      </div>

    </div>
  )
}

export default LeaveGridHeader;

const dropdownStyle = `
#leaveReportNewMonthsDropdown .e-input-group .e-input-group-icon,
.e-input-group.e-control-wrapper .e-input-group-icon {
  background: transparent;
  border-color: rgba(0, 0, 0, 0.42);
  color: white;
}
#leaveReportNewMonthsDropdown .e-input-group .e-input-group-icon,
.e-input-group.e-control-wrapper .e-input-group-icon:hover {
  background: transparent;
  border-color: rgba(0, 0, 0, 0.42);
  color: white;
}

#leaveReportNewMonthsDropdown .e-icon {
  color: white !important;
}
#leaveReportNewMonthsDropdown .e-icon:hover {
  color: white !important;
}

#leaveReportNewMonthsDropdown .e-label-top .e-icon {
  background-color: #4B4B55 !important;
}

#leaveReportNewMonthsDropdown .e-outline.e-input-group:not(.e-input-focus),
.e-outline.e-input-group:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left):not(.e-input-focus),
.e-outline.e-input-group.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left):not(.e-input-focus),
.e-outline.e-input-group.e-float-icon-left:not(.e-input-focus),
.e-outline.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-input-focus) {
  border-color: white !important;
}
#leaveReportNewMonthsDropdown .e-outline.e-input-group:not(.e-input-focus),
.e-outline.e-input-group:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left):not(.e-input-focus),
.e-outline.e-input-group.e-control-wrapper:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left):not(.e-input-focus),
.e-outline.e-input-group.e-float-icon-left:not(.e-input-focus),
.e-outline.e-input-group.e-control-wrapper.e-float-icon-left:not(.e-input-focus):hover {
  border-color: white !important;
}

#leaveReportNewMonthsDropdown .e-multiselect.e-outline .e-multi-select-wrapper {
  min-height: 30px;
  padding: 0;
}

#leaveReportNewMonthsDropdown .e-multi-select-wrapper {
  height: 30px;
}

#leaveReportNewMonthsDropdown .e-multi-select-wrapper .e-delim-values {
  color: white !important;
}
#leaveReportNewMonthsDropdown .e-multi-select-wrapper .e-delim-values:hover{
  color: white !important;
}

#leaveReportNewMonthsDropdown .e-multi-select-wrapper .e-delim-values {
  -webkit-text-fill-color: white !important;
}
#leaveReportNewMonthsDropdown .e-multi-select-wrapper .e-delim-values:hover {
  -webkit-text-fill-color: white !important;
}

#leaveReportNewMonthsDropdown label.e-float-text,
.e-float-input:not(.e-error):not(.e-input-focus) input:not(:focus):valid~label.e-float-text.e-label-top,
.e-float-input.e-control-wrapper:not(.e-error):not(.e-input-focus) input:not(:focus):valid~label.e-float-text.e-label-top {
  color: white !important;
}
#leaveReportNewMonthsDropdown label.e-float-text,
.e-float-input:not(.e-error):not(.e-input-focus) input:not(:focus):valid~label.e-float-text.e-label-top,
.e-float-input.e-control-wrapper:not(.e-error):not(.e-input-focus) input:not(:focus):valid~label.e-float-text.e-label-top:hover {
  color: white !important;
}
`
