/**
* Author Name : Vinayak Jadhav.
* Created Date: - 03-08-2021
*/

import * as React from "react";
import { connect } from "react-redux";
import EmployeeNameTemplate from "../../../../src/components/tobeoffercondicate/employeenametemplate";
// --import adminServices from "../../../services/adminservices";
// import {
//   GridComponent,
//   ColumnsDirective,
//   ColumnDirective,
//   Filter,
//   Inject,
//   VirtualScroll,
//   Sort,
//   Page,
//   Resize
// } from "@syncfusion/ej2-react-grids";

class UserRoleMapping extends React.Component {
  constructor(props) {
    super(props);
    const { dataSource, grayLeaves } = this.props;
    this.state = {
      datasource: []
    };
    this.data = dataSource;
    this.grayLeaves = grayLeaves;
    this.queryCellInfo = this.queryCellInfo.bind(this);
    this.pageOptions = {
      pageSize:
        (window.innerHeight ||
          document.documentElement.clientHeight ||
          document.body.clientHeight) > 700
          ? 11
          : 5,
      pageCount: 5
    };
    this.cellSelection = {
      cellSelectionMode: "Box",
      mode: "Cell",
      // type: 'Multiple'
    };
    this.width =
      (window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth) - 80;
    this.customAttributes = { class: "customcss-sat-sun" };
    this.customAttributesAVG = { class: "customcss-avg" };
    this.customAttributesELPL = { class: "customcss-EL-PL" };
    this.customAttributesAbsent = { class: "customcss-absent" };
  }

  isNumeric = (value) => {
    return /^-{0,1}\d+$/.test(value);
  };

  /**
   *
   * @param {*} args
   * queryCellInfo function for filter data each cell wise.
   */
  queryCellInfo(args) { // NOSONAR
    // const { cell } = args;
    // const { data } = args;
  }

  beforeDataBound(args) {
    this.grid.resizeSettings.mode = "Auto";
  }
  load() {
    let cols = this.columns;
    let width = 0;
    width = cols.reduce(function (prev, cur) { return prev + cur.width; }, 0);
    if (width < 1830) width = width + 21; // setting total columns width to Tree Grid
    else width = 1830;

    if (width < this.width) {
      this.width = width;
    }
  }
  employeeNameTemplate(props) {
    return (
      <EmployeeNameTemplate
        props={props}
        data="test"
        navigateToAddBasicInformation={this.navigateToAddBasicInformation}
      />
    );
  }
  render() {    
    return (
      <div className="control-pane">
        <div className="control-section" style={{ height: 600, marginTop: 200 }}>
          <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6" style={{marginTop: 35, marginLeft: 30, textAlign: 'left'}}>
          <h1>Under Construction</h1>
          
              </div>
        </div>
      </div>
    );
  }
}

UserRoleMapping.propTypes = {
  // dataSource: PropTypes.arrayOf(Object).isRequired,
};
UserRoleMapping.defaultProps = {};

const mapStateToProps = ({ auth, adminreducer }) => {
  const { authUser } = auth;
  const { role_id, role_name, role_description, role_status, role_modules, action_status } = adminreducer;
  return { authUser, role_id, role_name, role_description, role_status, role_modules, action_status };
};

export default connect(mapStateToProps, {})(UserRoleMapping);

