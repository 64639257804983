/**
 * @author: Vivek Khodade.
 * @description This use for okr rating action.
 * Created: 10-10-2021
 */
 import {
    ADD_UPDATE_OKR_DASHBOARD_REDUX
  } from '../../constant/okrdashboardconstant/okrdashboardconstant';
  export const addUpdateOkrDashboardRedux = (data) => {
    return {
      type: ADD_UPDATE_OKR_DASHBOARD_REDUX,
      payload: data,
    };
  };