/**
 * Author: Keval Charla.
 * Created:11-July-2022
 * @description to view all list of holidays for user
 */

import React, { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { ListViewComponent } from '@syncfusion/ej2-react-lists';
import DateFnsUtils from '@date-io/date-fns';
import { Dropdown, DropdownMenu, DropdownToggle, } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { updateMeetingBookingState } from "../../redux/actions/meetingbookingaction";
import ReactHtmlParser from 'react-html-parser';
import "./holidaylist.css";
import { Button } from "@material-ui/core";
import { getDateWithDayName, getNotify } from "../../app/other/commonfunction";
import { ERROR } from "../../assets/constants/constants";
import HolidayListSingleHoliday from './holidaylistsingleholiday';
import { updateLnAData } from '../../redux/actions/lna/lnaaction'
import ProgressBar from '../progressbar/progressbar';


const HolidayList = (props) => { // NOSONAR
  const [iconDropdown, setIconDropdown] = useState(false);
  const [showHolidayRules, setShowHolidayRules] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isOneTwentyFiveScale, setIsOneTwentyFiveScale] = useState(false);
  const [applyFestivalHoliday, setApplyFestivalHoliday] = useState("");
  const [disableFestivalHoliday, setDisableFestivalHoliday] = useState(false);
  const [previousCheckState, setPreviousCheckState] = useState([]);
  const [updateCheckState, setUpdateCheckState] = useState([]);
  const [checkboxCount, setCheckboxCount] = useState(0);
  const [currentTickCheckboxes, setCurrentTickCheckboxes] = useState([]);
  const [hight, setHight] = useState({
    listViewHeaderHeight: null,
    windowAdjust: null,
    listViewHeight: null
  });
  const [count, setCount] = useState(4);
  const holidayFilter = useRef();

  useEffect(() => {
    props.holidayListData.forEach(holiday => {
      if (holiday.id === props.selectedItem) {
        props.handleSelectedItem(holiday.id);
      }
    })
  }, [props.selectedItem]);

  /**
   * Author: Keval Charla  *
   * @description resize dimension
   */
  const { holidayLoadingStatus } = props
  useEffect(() => {
    setIsOneTwentyFiveScale(window.innerHeight < 750);
    window.addEventListener("resize", updateDimensions()); // NOSONAR
    updateAvailHoliday()
  }, [])

  /**
   * Author: Keval Charla  *
   * @description update previous checkbox state
   */

  useEffect(() => {
    const checkedBoxes = [];

    props.holidayListData.forEach(holiday => {
      const holidayId = document.getElementById(`holiday-${holiday.id}`);

      if (holidayId && (holiday.id === holidayId.ej2_instances[0].value)) {
        if (holidayId.ej2_instances[0].checked) {
          checkedBoxes.push(holiday);
        }
      }
    });

    setTimeout(() => {
      setPreviousCheckState(checkedBoxes);
    }, 500);
  }, [applyFestivalHoliday === "3", applyFestivalHoliday === ""]);

  useEffect(() => {
    if (applyFestivalHoliday === "1" || applyFestivalHoliday === "2" || applyFestivalHoliday === "4") {
      const checkAvailedAndAllocated = [
        // { holidayType: 'All', id: '1' },
        { text: 'Fixed Holiday', id: '2', isChecked: false },
        { text: 'Availed Festival Holiday', id: '3', isChecked: true },
        { text: 'Allocated Festival Holiday', id: '4', isChecked: true },
        { text: 'Working Saturday', id: '5', isChecked: false },
      ];

      props.handleMonthlyFilterData(checkAvailedAndAllocated);

      props.handleHolidayFilter(checkAvailedAndAllocated, "availedAndAllocated");
    }

    if (applyFestivalHoliday === "" || applyFestivalHoliday === "3") {
      const checkDefault = [
        // { holidayType: 'All', id: '1' },
        { text: 'Fixed Holiday', id: '2', isChecked: true },
        { text: 'Availed Festival Holiday', id: '3', isChecked: true },
        { text: 'Allocated Festival Holiday', id: '4', isChecked: false },
        { text: 'Working Saturday', id: '5', isChecked: false },
      ];

      props.handleMonthlyFilterData(checkDefault);

      props.handleHolidayFilter(checkDefault, "fixedAndAvailed");
    }
  }, [applyFestivalHoliday]);

  // useEffect(() => {
  //   // handleUpdateData();
  //   let count = 0;
  //   props.holidayListData.forEach((holiday) => {
  //     if (holiday.holidayType === "Availed Festival Holiday") {
  //       setApplyFestivalHoliday("3");
  //       count++;
  //     }
  //   });

  //   if (!count) {
  //     setApplyFestivalHoliday("");
  //   }
  // }, [props.holidayListData.length]);

  /**
   * Author: Keval Charla  *
   * @description update festival holiday button to display if any Availed Festival Holiday
   */

  const updateAvailHoliday = () => {
    props.ogHolidayListData.forEach((holiday) => {
      if (holiday.holidayType === "Availed Festival Holiday") {
        setApplyFestivalHoliday("3");
      }
    });
  }
  useEffect(() => {
    if (holidayLoadingStatus === "updated") {
      setTimeout(() => {
        updateAvailHoliday()
        setIsLoading(false)
      }, 1000);
      props.updateLnAData({ holidayLoadingStatus: "" })
      setApplyFestivalHoliday("3");
    }

    if (holidayLoadingStatus === "holidayLoaderInList") {
      setTimeout(() => {
        setIsLoading(true);
      }, 500);
    }
  }, [holidayLoadingStatus]);
  useEffect(() => {
    if (props.isLoading) {
      setIsLoading(true)
    } else {
      setIsLoading(false)
    }
  }, [props.isLoading]);

  const updateDimensions = () => {
    if (window.innerHeight < 650) {
      const windowAdjusts = window.innerHeight - 150;
      const listViewHeaderHeight = window.innerHeight - 912;
      const listViewHeight = window.innerHeight - 210;
      setHight({
        listViewHeaderHeight: listViewHeaderHeight,
        listViewHeight: listViewHeight,
        windowAdjust: windowAdjusts,
      });
    } else {
      const isOneTwentyFiveScales = window.innerHeight < 750
      const windowAdjusts = window.innerHeight - 160;
      const listViewHeaderHeight = window.innerHeight - 912;
      const listViewHeight = isOneTwentyFiveScales ? window.innerHeight - 201 : window.innerHeight - 213;
      setHight({
        listViewHeaderHeight: listViewHeaderHeight,
        listViewHeight: listViewHeight,
        windowAdjust: windowAdjusts,
      });
    }
  }

  /**
   * Author: Keval Charla  *
   * @description open or hide filter dropdown
   */

  const getHelp = () => {
    setIconDropdown(prevState => !prevState);
  };

  /**
   * @author Keval Charla
   * @description disable holiday list filter ui color
   */

  const cancelEvent = () => {
    const checkedFilter = props.monthViewFilterList;
    setCount(4)
    setIconDropdown(false);

    checkedFilter.forEach(filter => {
      if (filter.isChecked) {
        props.handleDisableFilter("no");
      } else {
        props.handleDisableFilter("yes");
      }
    });
  }

  const onCheckBoxClick = (arg) => {
    props.handleFilterCheckBox(arg.data, 'monthly');

    if (holidayFilter.current.getSelectedItems().data && holidayFilter.current.getSelectedItems().data.length === 0) {
      setCount(0)
    } else {
      setCount(4)
    }
  }

  /**
   * @author Keval Charla
   * @description apply holiday list filter
   */

  const submitEvent = () => {
    setIsLoading(true);
    const checkedHoliday = [];
    const checkedFilter = props.monthViewFilterList;
    if (holidayFilter.current) {
      props.handleHolidayFilter(holidayFilter.current.getSelectedItems().data);
      holidayFilter.current.getSelectedItems().data.forEach(ele => {
        checkedHoliday.push(ele.id)
      });
    }
    // updateAvailHoliday()
    checkedFilter.forEach(filter => {
      if (checkedHoliday.includes(filter.id)) {
        filter.isChecked = true;
      } else {
        filter.isChecked = false;
      }
    })
    props.handleMonthlyFilterData(checkedFilter);
    setIconDropdown(false);
    if (checkedHoliday.length > 0) {
      props.handleDisableFilter("no");
    } else {
      props.handleDisableFilter("yes");
    }

    props.refreshMonthViewCalendar();
    setApplyFestivalHoliday("3");
    setIsLoading(false);
  }

  const handleUpdateData = () => { //NOSONAR
    if (props.holidayListData.length > 0) {
      const defaultCheck = [];

      props.ogHolidayListData.forEach((holiday) => {
        if (holiday.holidayType === "Availed Festival Holiday") {
          setApplyFestivalHoliday("3");
          defaultCheck.push(holiday);
        }
      });

      setTimeout(() => {
        setPreviousCheckState(defaultCheck);
        setUpdateCheckState(defaultCheck);
        setCheckboxCount(defaultCheck.length);

        if (defaultCheck.length >= 2) {
          setDisableFestivalHoliday(true);
        }
      }, 500);
    }
  }

  /**
   * Author: Keval Charla  *
   * @description show hide holiday rules popup
   */

  const handleHolidayRules = () => {
    setShowHolidayRules(prevState => !prevState);
  }

  const handleApplyFestivalHoliday = (type) => {
    setApplyFestivalHoliday(type);
  }

  const handleFestivalHolidayCancel = () => {
    if (applyFestivalHoliday === "4" || applyFestivalHoliday === "5") {
      setApplyFestivalHoliday("3");
    } else {
      setApplyFestivalHoliday("");
    }
  }

  /**
   * Author: Keval Charla  *
   * @description add checked festival holiday to state
   */

  const addToCheckState = (addHoliday) => { // NOSONAR
    setUpdateCheckState(prevState => [...prevState, addHoliday])
    setCheckboxCount(prevState => ++prevState);

    const checkedBoxes = [];
    let userMaxFestivalApply = 0;

    props.holidayListData.forEach((holiday, indx) => {
      if (indx === 0) {
        userMaxFestivalApply = holiday.maxNoOfFestivalHolidayAllowed ? holiday.maxNoOfFestivalHolidayAllowed : 0;
      }

      const holidayId = document.getElementById(`holiday-${holiday.id}`);

      if (holidayId && (holiday.id === holidayId.ej2_instances[0].value)) {
        if (holidayId.ej2_instances[0].checked) {
          checkedBoxes.push(holiday);
        }
      }
    });

    setTimeout(() => {
      if (checkedBoxes.length > userMaxFestivalApply) {
        if (userMaxFestivalApply === 0) {
          getNotify(ERROR, `You are not allowed to apply festival holiday.`);
        } else if (userMaxFestivalApply === 1) {
          getNotify(ERROR, `Please select upto 1 festival holiday.`);
        } else {
          getNotify(ERROR, `Please select upto ${userMaxFestivalApply} festival holidays.`);
        }

        const addHolidayId = document.getElementById(`holiday-${addHoliday.id}`);

        if (addHolidayId && addHolidayId.ej2_instances[0].checked) {
          addHolidayId.ej2_instances[0].checked = false;
        }

        setCurrentTickCheckboxes(checkedBoxes);
        setDisableFestivalHoliday(true);
      }
    }, 500);
  }

  /**
   * Author: Keval Charla  *
   * @description remove checked festival holiday to state
   */

  const removeFromCheckState = (holiday) => {
    setCheckboxCount(prevState => --prevState);
    const checkItems = updateCheckState;

    const index = checkItems.indexOf(holiday);

    if (index > -1) {
      checkItems.splice(index, 1);

      setTimeout(() => {
        setUpdateCheckState(checkItems);
      }, 500);
    }
  }

  /**
   * Author: Keval Charla  *
   * @description handle submit festival holiday
   */

  const handleSubmitFestivalHoliday = (previousCheckState) => {//NOSONAR
    const updatedCheckedState = [];

    props.holidayListData.forEach(holiday => {
      const holidayId = document.getElementById(`holiday-${holiday.id}`);

      if (holidayId && (holiday.id === holidayId.ej2_instances[0].value)) {
        if (holidayId.ej2_instances[0].checked) {
          updatedCheckedState.push(holiday);
        }
      }
    });
    props.handleApply(updatedCheckedState, previousCheckState);
  }

  /**
   * Author: Keval Charla  *
   * @description reset to normal when click on reset button
   */

  const handleFestivalHolidayReset = () => { // NOSONAR
    if (previousCheckState.length) {
      previousCheckState.forEach(holiday => {
        const holidayId = document.getElementById(`holiday-${holiday.id}`);

        if (holidayId && (holiday.id === holidayId.ej2_instances[0].value)) {
          holidayId.ej2_instances[0].checked = true;
        }
      });
      props.holidayListData.forEach(holiday => {
        if (holiday.isApplied === "N") {
          const holidayId = document.getElementById(`holiday-${holiday.id}`);

          if (holidayId && (holiday.id === holidayId.ej2_instances[0].value)) {
            holidayId.ej2_instances[0].checked = false;
          }
        }
      });
    } else {
      props.holidayListData.forEach(holiday => {
        const holidayId = document.getElementById(`holiday-${holiday.id}`);

        if (holidayId && (holiday.id === holidayId.ej2_instances[0].value)) {
          holidayId.ej2_instances[0].checked = false;
        }
      });
    }
  }

  return (
    <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12" style={{ width: "100%", height: hight.windowAdjust, border: "1px solid #DBDBDD" }}>
      <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 apply-button-and-filters" style={{ height: window.innerHeight < 650 ? "7.6vh" : "5.3vh", borderBottom: "1px solid #DBDBDD" }}>
        {!applyFestivalHoliday && <div
          className="apply-festival-holiday ml-md"
          onClick={() => handleApplyFestivalHoliday("1")}
        >
          Apply Festival Holiday
        </div>}

        {applyFestivalHoliday && applyFestivalHoliday !== "3" && <div
          className={`${applyFestivalHoliday === "1" || applyFestivalHoliday === "4"
            ? "disable-holiday-list-cancel-button"
            : applyFestivalHoliday === "2" || applyFestivalHoliday === "5" ? "holiday-list-cancel-button" : "" /** NOSONAR */
            } p-md`}
          onClick={handleFestivalHolidayReset}
        >
          Reset
        </div>}

        {applyFestivalHoliday && applyFestivalHoliday !== "3" && <div
          className="holiday-list-cancel-button p-md"
          onClick={handleFestivalHolidayCancel}
        >
          Cancel
        </div>}

        {applyFestivalHoliday && applyFestivalHoliday !== "3" && <div
          className={`${applyFestivalHoliday === "1" || applyFestivalHoliday === "4" ? "disable-holiday-filter-button" : applyFestivalHoliday === "2" || applyFestivalHoliday === "5" ? "holiday-filter-button" : "" /** NOSONAR */}`}
          onClick={applyFestivalHoliday === "1" || applyFestivalHoliday === "4" ? null : () => handleSubmitFestivalHoliday(previousCheckState)}
        >
          Apply
        </div>}

        {applyFestivalHoliday && applyFestivalHoliday === "3" && <div
          className="apply-festival-holiday ml-md"
          onClick={() => handleApplyFestivalHoliday("4")}
        >
          Update Festival Holiday
        </div>}

        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12 holiday-filters">
          {props.holidayRulesData !== "" &&
            <div className="holiday-rules-container">
              <div className="holiday-rules" style={{ fontSize: isOneTwentyFiveScale ? ".85rem" : ".95rem" }} onClick={handleHolidayRules}>Holiday Rules <FontAwesomeIcon title="holiday-rules-icon" style={{ marginLeft: ".1rem" }} icon={showHolidayRules ? faAngleUp : faAngleDown} /></div>
              <div className={showHolidayRules ? `holiday-rules-text holiday-rules-show` : `holiday-rules-text`}>{ReactHtmlParser(props.holidayRulesData)}</div>
            </div>
          }
          <div>
            <Dropdown className="quick-menu" isOpen={iconDropdown} toggle={getHelp}>
              <DropdownToggle className="holiday-filter-icon-and-text" tag="span">
                <div className='holiday-filter-text' style={{ fontSize: isOneTwentyFiveScale ? ".9rem" : "1rem" }}>
                  Filters
                </div>
                <div className='holiday-filter-icon'>
                  <FontAwesomeIcon title="filter" style={{ color: props.disableFilter ? 'red' : 'black', cursor: "pointer" }} icon={faFilter} />
                </div>
              </DropdownToggle>

              <DropdownMenu className="holiday-filter-menu">
                <div className='drop-menu-3'>
                  {/* <div className='filter-title'>Select Holidays / Workdays</div> */}
                  <ListViewComponent id='filter-checkbox-items'
                    dataSource={props.monthViewFilterList}
                    showCheckBox={true}
                    ref={holidayFilter}
                    showHeader={false}
                    defaultChecked={false}
                    select={onCheckBoxClick}>
                  </ListViewComponent>
                  <div className='holiday-list-filter-buttons'>
                    <div className='holiday-cancel-button' onClick={cancelEvent}>Cancel</div>
                    <Button
                      onClick={submitEvent}
                      style={{ color: !count <= 0 ? "white" : "B7B7BB", backgroundColor: !count <= 0 ? "#f94f5e" : "#DBDBDD", padding: '2px 15px', margin: '0px 10px 0px 0px', minWidth: '74px' }}
                      disabled={count <= 0}
                      variant="contained"
                      title="Apply">
                      Apply
                    </Button>
                  </div>
                </div>
              </DropdownMenu>

            </Dropdown>
          </div>
        </div>
      </div>
      <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12" style={{ height: hight.listViewHeight, overflow: "auto" }}>
        {props.holidayListData.length > 0 ? props.holidayListData.map((holiday) => { // NOSONAR
          let dateStandard = getDateWithDayName(holiday.holidayDate)
          let today = new Date();

          today.setHours(0, 0, 0);
          let todayTimeInHours = today.getTime();

          let actualDate = new Date(holiday.holidayDate);

          let previousHolidayDateInHours = actualDate.getTime();
          actualDate.setHours(0, 0, 0);
          let holidayDateInHours = actualDate.getTime();

          const selectedListItem = holiday.holidayType === "Allocated Festival Holiday" ? "#C8C8C8" : holiday.isApplied === "N" ? "#4B4B55" : `#4B4B55`; // NOSONAR

          return (
            <HolidayListSingleHoliday
              key={holiday.holidayDate + `-${previousHolidayDateInHours}`}
              holiday={holiday}
              holidayDateInHours={holidayDateInHours}
              todayTimeInHours={todayTimeInHours}
              selectedListItem={selectedListItem}
              isOneTwentyFiveScale={isOneTwentyFiveScale}
              dateStandard={dateStandard}
              handleSelectedItem={props.handleSelectedItem}
              handleApply={props.handleApply}
              applyFestivalHoliday={applyFestivalHoliday}
              handleApplyFestivalHoliday={handleApplyFestivalHoliday}
              disableFestivalHoliday={disableFestivalHoliday}
              addToCheckState={addToCheckState}
              removeFromCheckState={removeFromCheckState}
              updateCheckState={updateCheckState}
              checkboxCount={checkboxCount}
              currentTickCheckboxes={currentTickCheckboxes}
            />
          )
          // }
        }) : (<div className="no-data-to-display">
          No Data To Display
        </div>)}
      </div>
      {
        isLoading && <ProgressBar />
      }
    </div>
  )
}

const mapStateToProps = ({ auth, meetingbooking, lnareducer }) => {
  const { authUser } = auth;
  const { switchView } = meetingbooking;
  const { holidayLoadingStatus } = lnareducer;
  return {
    authUser,
    switchView,
    holidayLoadingStatus
  };
};
export default connect(mapStateToProps, {
  updateMeetingBookingState, updateLnAData
})(HolidayList);
