import React, { Component } from "react";
import {
  ComboBoxComponent,
  AutoCompleteComponent,
} from "@syncfusion/ej2-react-dropdowns";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { FormValidator } from "@syncfusion/ej2-inputs";
import { SwitchComponent } from "@syncfusion/ej2-react-buttons";
import { Query, Predicate } from "@syncfusion/ej2-data";
import {
  ParentRequired,
  pleaseEnterPracticeArea,
  pleaseSetHierarchy,
  UserType,
} from "../../../../assets/constants/constants";

class Practicepopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      result: [],
      businessUnitData: [],
      subBusinessUnitData: [],
      hierarchyData: [],
      practiceAreaId: 0,
      practiceAreaName: "",
      subusinesFunctionId: null,
      hierarchy: null,
      maxHierarchy: "",
      clearenceDept: "0",
      clearenceUid: "",
      deptEmail: "",
      isActingHead: "0",
      serviceDivHead: "",
      userType: "",
      isParent: "1",
      parent: 0
    };
    this.serviceDivHeadTitle = {};
    this.clearenceUidTitle = {};
  }

  // Author@Amit Kumar
  // Description:to send  data as props
  onChangePracticeArea(e, type) {
    // NOSONAR
    let options = {};
    switch (type) {
      case "PracticeArea":
        this.setState({
          practiceAreaName: e.value,
        });
        options = {
          rules: {
            practiceAreaErr: {
              required: [true, pleaseEnterPracticeArea],
            },
          },
        };
        break;

      case "Hierarchy":
        this.setState({
          hierarchy: e.value,
        });
        options = {
          rules: {
            hierarchyErr: {
              required: [true, pleaseSetHierarchy],
            },
          },
        };

        break;
      case "UserType":
        if (e.itemData) {
          this.setState({
            userType: e.itemData.Value,
          });
        }
        options = {
          rules: {
            usertypeErr: {
              required: [true, "Please select user type."],
            },
          },
        };

        break;

      case "serviceHead":
        this.setState({
          serviceDivHead: e.itemData !== null ? e.itemData.UserId : "",
        });
        this.serviceDivHeadTitle.text =
          e.itemData !== null ? e.itemData.nameWithDepartment : "";
        options = {
          rules: {
            serviceHeadErr: {
              required: [true, "Please set service head."],
            },
          },
        };

        break;
      case "DeptEmail":
        this.setState({
          deptEmail: e.value,
        });
        //   options = {
        //     rules: {
        //       DeptEmailErr: {
        //             required: [true,'Please enter Department Email.'],
        //             regex: [/^((?:(?:(?:\w[\.\-\+]?)*)\w)+)((?:(?:(?:\w[\.\-\+]?){0,62})\w)+)\.(\w{2,6})$/,
        //             'Valid Email'
        //           ],
        //         },

        //     },
        // };

        break;
      case "isActingHead":
        this.setState({
          isActingHead: e.checked === true ? "1" : "0",
        });
        break;
      case "clearenceDept":
        this.setState({
          clearenceDept: e.checked === true ? "1" : "0",
        });
        if (this.clearenceUidTitle !== null) {
          this.clearenceUidTitle.text = e.itemData
            ? e.itemData.nameWithDepartment
            : "";
        }
        if (this.state.clearenceDept === "0") {
          this.setState({
            clearenceUid: "",
          });
        }

        break;
      case "isParent":
        this.setState({
          isParent: e.checked === true ? "1" : "0", parent: e.checked === true ? 0 : this.state.parent
        });
        break;
      case "parent":
        if (this.state.isParent === '0') {
          options = {
            rules: {
              parentErr: {
                required: [true, ParentRequired],
              }
            },
          };
        }
        this.setState({
          parent: e.value,
        });
        break;
      case "clearenceUser":
        this.setState({
          clearenceUid: e.itemData !== null ? e.itemData.UserId : "",
        });
        options = {
          rules: {
            clearenceUserErr: {
              required: [true, "Please select clearance user."],
            },
          },
        };
        // }

        break;

      default:
        break;
    }
    setTimeout(() => {
      const addFormObject = new FormValidator("#practiceAreaForm", options);
      addFormObject.validate();
    }, 100);

    const {
      businessFunctionUnitId,
      businessSubFunctionUnitId,
      practiceAreaId,
    } = this.props.practiceAreaData;
    const {
      hierarchy,
      practiceAreaName,
      userType,
      serviceDivHead,
      clearenceUid,
      clearenceDept,
      isActingHead,
      deptEmail,
      isParent,
      parent
    } = this.state;

    let data = {
      businessFunctionUnitId: businessFunctionUnitId,
      businessSubFunctionUnitId: businessSubFunctionUnitId,
      practiceAreaName: practiceAreaName,
      practiceAreaId: practiceAreaId,
      hierarchy: hierarchy,
      clearenceDept: clearenceDept,
      clearenceUid: clearenceUid,
      deptEmail: deptEmail,
      isActingHead: isActingHead,
      serviceDivHead: serviceDivHead,
      userType: userType,
      isParent,
      parent
    };

    this.props.parentComponentfunction(data);
  }

  componentDidMount() {
    this.setStateData();
  }

  componentDidUpdate(prevProps, prevState) {
    try {
      if (prevState.serviceDivHead !== this.state.serviceDivHead) {
        if (this.serviceDivHeadTitle.dataSource.length > 0) {
          this.serviceDivHeadTitle.dataSource.forEach((user) => {
            if (user.UserId === parseInt(this.state.serviceDivHead)) {
              this.serviceDivHeadTitle.text = user.nameWithDepartment;
              this.serviceDivHeadTitle.value = user.UserId;
            }
          });
        }
      }
      if (prevState.clearenceUid !== this.state.clearenceUid) {
        if (this.clearenceUidTitle.dataSource.length > 0) {
          this.clearenceUidTitle.dataSource.forEach((user) => {
            if (user.UserId === parseInt(this.state.clearenceUid)) {
              this.clearenceUidTitle.text = user.nameWithDepartment;
              this.clearenceUidTitle.value = user.UserId;
            }
          });
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  async setStateData() {
    const {
      businessFunctionUnitId,
      businessSubFunctionUnitId,
      hierarchy,
      practiceArea,
      practiceAreaId,
      clearenceDept,
      clearenceUid,
      deptEmail,
      isActingHead,
      serviceDivHead,
      userType,
      parent
    } = this.props.practiceAreaData;
    const { maxHierarchy } = this.props;
    const { practiceAreaMasterData } = this.props;
    let hierarchyPrev = hierarchy === null ? 0 : hierarchy.toString();
    try {
      const SubBusinessUnit = practiceAreaMasterData.result.filter((item) => {
        return item.Type === "subBusinessUnitFunction";
      });

      const BusinessUnit = practiceAreaMasterData.result.filter((item) => {
        return item.Type === "businessUnitFunction";
      });

      let hierarchyDS = [];
      for (let i = 1; i <= maxHierarchy; i++) {
        hierarchyDS.push({ Id: i, Name: i.toString() });
      }

      this.setState({
        businessUnitData: BusinessUnit,
        subBusinessUnitData: SubBusinessUnit,
        hierarchyData: hierarchyDS,
        businessFunctionUnitId: businessFunctionUnitId,
        businessSubFunctionUnitId: businessSubFunctionUnitId,
        practiceAreaName: practiceArea,
        practiceAreaId: practiceAreaId,
        hierarchy: hierarchyPrev,
        clearenceDept: clearenceDept,
        clearenceUid: clearenceUid,
        deptEmail: deptEmail,
        isActingHead: isActingHead,
        serviceDivHead: serviceDivHead,
        userType: userType,
        isParent: parseInt(parent) === 0 ? '1' : '0',
        parent:parseInt(parent)
      });
    } catch (error) {
      console.error(error);
    }
  }

  handleFilteringByName = (e) => {
    e.preventDefaultAction = true;
    let predicate = new Predicate(
      "nameWithDepartment",
      "contains",
      e.text,
      true
    );
    predicate = predicate.or("UserId", "contains", e.value, true);

    let query = new Query();
    //frame the query based on search string with filter type.
    query = e.text != "" ? query.where(predicate) : query;

    //pass the filter data source, filter query to updateData method.
    e.updateData(this.props.othersCheckAvailablity, query);
  };

  render() {
    const {
      businessFunctionUnitId,
      practiceAreaName,
      businessSubFunctionUnitId,
      hierarchy,
      businessUnitData,
      subBusinessUnitData,
      hierarchyData,
      deptEmail,
      isActingHead,
      clearenceDept,
      serviceDivHead,
      clearenceUid,
      userType,
      parent
    } = this.state;
    // const { parent } = this.props.practiceAreaData
    return (
      <div>
        <form id="practiceAreaForm">
          <div className="row">
            <div className="col-md-4">
              {businessUnitData.length > 0 && (
                <ComboBoxComponent
                  id="EmpType"
                  dataSource={businessUnitData}
                  fields={{ text: "Name", value: "Id" }}
                  value={businessFunctionUnitId}
                  data-msg-containerid="emptypeErr"
                  name="emptypeErr"
                  allowFiltering
                  allowCustom={false}
                  floatLabelType="Auto"
                  cssClass="e-outline"
                  placeholder="Business Unit / Group Function "
                  readonly={true}
                />
              )}
            </div>
            <div className="col-md-4" style={{ paddingBottom: "20px" }}>
              {subBusinessUnitData.length > 0 && (
                <ComboBoxComponent
                  id="EmpType"
                  dataSource={subBusinessUnitData}
                  fields={{ text: "Name", value: "Id" }}
                  value={parseInt(businessSubFunctionUnitId, 10)}
                  data-msg-containerid="emptypeErr"
                  name="emptypeErr"
                  allowFiltering
                  allowCustom={false}
                  floatLabelType="Auto"
                  cssClass="e-outline"
                  placeholder="Sub Business Unit / Function"
                  readonly={true}
                />
              )}
            </div>
            <div className="col-md-4">
              <TextBoxComponent
                id="PracticeAreaData"
                placeholder="Practice Area / Sales Unit / Sub Function *"
                floatLabelType="Auto"
                fields={{ text: "Name", value: "Id" }}
                value={practiceAreaName}
                data-msg-containerid="practiceAreaErr"
                name="practiceAreaErr"
                cssClass="e-outline"
                change={(e) => this.onChangePracticeArea(e, "PracticeArea")}
                htmlAttributes={{ maxlength: 100 }}
              />
              <div
                id="practiceAreaErr"
                className="e-input-group e-float-icon-left error-div"
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-4">
              {hierarchyData.length > 0 && (
                <ComboBoxComponent
                  id="PracticeAreaData"
                  dataSource={hierarchyData}
                  fields={{ text: "Name", value: "Name" }}
                  value={hierarchy}
                  data-msg-containerid="hierarchyErr"
                  name="hierarchyErr"
                  allowFiltering
                  allowCustom={false}
                  floatLabelType="Auto"
                  cssClass="e-outline"
                  placeholder="Hierarchy *"
                  change={(e) => this.onChangePracticeArea(e, "Hierarchy")}
                />
              )}
              <div
                id="hierarchyErr"
                className="e-input-group e-float-icon-left error-div"
              />
            </div>
            <div className="col-md-4">
              {(userType || this.props.type === "Add") && (
                <ComboBoxComponent
                  id="UserType"
                  dataSource={UserType}
                  fields={{ text: "Name", value: "Value" }}
                  value={userType}
                  data-msg-containerid="usertypeErr"
                  name="usertypeErr"
                  allowFiltering
                  allowCustom={false}
                  floatLabelType="Auto"
                  cssClass="e-outline"
                  placeholder="User Type *"
                  change={(e) => this.onChangePracticeArea(e, "UserType")}
                />
              )}
              <div
                id="usertypeErr"
                className="e-input-group e-float-icon-left error-div"
              />
            </div>
            <div className="col-md-4">
              <TextBoxComponent
                id="DeptEmail"
                placeholder="Department Email Address"
                floatLabelType="Auto"
                fields={{ text: "Name", value: "Id" }}
                value={deptEmail}
                data-msg-containerid="DeptEmailErr"
                name="DeptEmailErr"
                cssClass="e-outline"
                change={(e) => this.onChangePracticeArea(e, "DeptEmail")}
                htmlAttributes={{ maxlength: 100 }}
              />
              <div
                id="DeptEmailErr"
                className="e-input-group e-float-icon-left error-div"
              />
            </div>
            {/*}
          {/*  */}
          </div>
          <div className="row">
            {/* {serviceDivHead && */}
            <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4">
              <AutoCompleteComponent
                id="serviceHead"
                ref={(autocomplete) => {
                  this.serviceDivHeadTitle = autocomplete;
                }}
                cssClass="e-outline"
                floatLabelType="Auto"
                value={serviceDivHead}
                fields={{ text: "nameWithDepartment", value: "UserId" }}
                placeholder="Practice Area / Sales Unit / Sub Function Head *"
                select={(e) => this.onChangePracticeArea(e, "serviceHead")}
                ignoreCase={true}
                // select={(e) => businessUnitFunctionChange(e, "pnlHead")}
                data-msg-containerid="serviceHeadErr"
                name="serviceHeadErr"
                // noRecordsTemplate={this.noRecordsTemplate}
                filtering={this.handleFilteringByName}
                dataSource={this.props.othersCheckAvailablity}
              />
              <div
                id="serviceHeadErr"
                className="e-input-group e-float-icon-left error-div"
              />
            </div>
            {/* } */}

            <div
              className="col-xs-4 col-sm-4 col-lg-4 col-md-4"
              style={{ display: "flex" }}
            >
              <div style={{ marginTop: 10, marginRight: 10, color: "grey" }}>
                Is Parent:
              </div>
              <div className="atc-switch-heading-start">
                <div className=" atc-switch-inline " style={{ marginTop: 8 }}>
                  <div className="mr-md">No</div>
                  <div>
                    <SwitchComponent
                      cssClass="atc-switch-height"
                      change={(e) =>
                        this.onChangePracticeArea(e, "isParent")
                      }
                      checked={this.state.isParent === "0" ? false : true}
                    />
                  </div>
                  <div className="ml-md">Yes</div>
                </div>
              </div>
            </div>
            {this.state.isParent === '0' && <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4">
              <ComboBoxComponent
                id="parent"
                fields={{ text: "Name", value: "Id" }}
                data-msg-containerid="parentErr"
                name="parentErr"
                cssClass="e-outline"
                floatLabelType="Auto"
                placeholder="Parent *"
                dataSource={this.props.parents}
                value={parent === 0 || parent === '0' || parent === null ? '' : parseInt(parent)}
                change={(e) => this.onChangePracticeArea(e, "parent")}
              />
              <div
                id="parentErr"
                className="e-input-group e-float-icon-left error-div"
              />
            </div>}
          </div>
          <div className="row">
            <div
              className="col-xs-4 col-sm-4 col-lg-4 col-md-4"
              style={{ display: "flex" }}
            >
              <div style={{ marginTop: 10, marginRight: 10, color: "grey" }}>
                Is Acting Head:
              </div>
              <div className="atc-switch-heading-start">
                <div className=" atc-switch-inline " style={{ marginTop: 8 }}>
                  <div className="mr-md">No</div>
                  <div>
                    <SwitchComponent
                      cssClass="atc-switch-height"
                      change={(e) =>
                        this.onChangePracticeArea(e, "isActingHead")
                      }
                      checked={isActingHead === "1" ? true : false}
                    />
                  </div>
                  <div className="ml-md">Yes</div>
                </div>
              </div>
            </div>
            <div
              className="col-xs-4 col-sm-4 col-lg-4 col-md-4"
              style={{ display: "flex" }}
            >
              <div style={{ marginTop: 10, marginRight: 6, color: "grey" }}>
                Clearance Department:
              </div>
              <div className="atc-switch-heading-start">
                <div
                  className=" atc-switch-inline "
                  style={{ marginTop: 8, marginLeft: 4 }}
                >
                  <div className="mr-md">No</div>
                  <div>
                    <SwitchComponent
                      cssClass="atc-switch-height"
                      change={(e) =>
                        this.onChangePracticeArea(e, "clearenceDept")
                      }
                      checked={clearenceDept === "1" ? true : false}
                    />
                  </div>
                  <div className="ml-md">Yes</div>
                </div>
              </div>
            </div>
            {clearenceDept === "1" ? (
              <div className="col-xs-4 col-sm-4 col-lg-4 col-md-4">
                <AutoCompleteComponent
                  id="clearenceUser"
                  ref={(autocomplete) => {
                    this.clearenceUidTitle = autocomplete;
                  }}
                  cssClass="e-outline"
                  floatLabelType="Auto"
                  value={clearenceUid}
                  fields={{ text: "nameWithDepartment", value: "UserId" }}
                  placeholder="Clearance User *"
                  ignoreCase={true}
                  change={(e) => this.onChangePracticeArea(e, "clearenceUser")}
                  // select={(e) => businessUnitFunctionChange(e, "pnlHead")}
                  data-msg-containerid="clearenceUserErr"
                  name="clearenceUserErr"
                  // noRecordsTemplate={this.noRecordsTemplate}
                  filtering={this.handleFilteringByName}
                  dataSource={this.props.othersCheckAvailablity}
                />
                <div
                  id="clearenceUserErr"
                  className="e-input-group e-float-icon-left error-div"
                />
              </div>
            ) : (
              ""
            )}
          </div>
        </form>
      </div>
    );
  }
}
export default Practicepopup;
