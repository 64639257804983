import React, { Component } from 'react';
import { Button } from "@material-ui/core";
import RatingObjectivKR from './ratingobjectivekr';
import ReviewObjectivKR from './reviewobjectivekr';
import ViewKRDetailsModal from './viewkrdetailsmodal';
import ActualAchivementModal from './actualachievementmodal';
import OKRRatingServices from '../../services/okrrating/okrratingservices'
import { connect } from 'react-redux'
import { addUpdateOkrRatingRedux, submitOKRRating, okrAccepted } from '../../redux/actions/okrrating/okrratingaction'
import ViewkrInterimFeedbackModel from './viewkrinterimfeedbackmodel'
import PastRatingModal from './pastratingmodal'
import AddRatingModalkr from './addratingmodalkr'
import AddEditInterimReviewModal from './addeditinterimreviewmodal'
import InterimFeedBack from './interimfeedback'
import { getNotify } from "../../app/other/commonfunction";
import { ERROR, OKRRatingSubmitWarning, OKRRatingSubmitedMessage, OKRFinalRatingSubmitWarning } from "../../assets/constants/constants";
import ViewRatingModalKr from './viewratingmodalkr';
import { OKR_SELF_REVIEW, OKR_SUPERVISOR_REVIEW, OKR_REVIEW } from '../../redux/constant/meetingcategoryconstant'
import Progressbar from '../progressbar/progressbar';
import ConfirmationPopup from '../../utilities/confirmationpopup';



const cssForScroll = `
::-webkit-scrollbar {
    width: 24px;
  }

  ::-webkit-scrollbar-thumb {
    background: white;
    border-right: 13px #8080804f  solid;
    background-clip: padding-box;
  }
`
class RatingObjectiveKRContainer extends Component {
    constructor(props) {
        super(props)
        this.state = {
            height: null,
            windowAdjust: null,
            viewKrDetailsPopup: false,
            viewActualAchivementPopup: false,
            viewInterimFeedbackPopup: false,
            addEditInterimReviewPopup: false,
            viewKrInterimFeedbackPopup: false,
            KRData: {},
            viewKrRatingPopup: false,
            ratingType: "",
            actualKRData: {},
            finalRatingPopup: false,
            finalRatingData: {},
            pastRatingPopup: false,
            pastRatingKRData: {},
            selectedColType: '',
            interimFeedbackType: "",
            objId: null,
            objName: null,
            confirmDialog: false,
            ratingSubmitted: false,
            viewReviewKrPopup: false,
            KRReviewData: {},
            type: '',
            currencyInfo: [],
            openCheckAcceptancePopup: false,
            categoryType: "",
            actionType: "",
            userType: '',
            informationMsg: false,
            isLoading: false,
            isHighlightForSubmit: false
        };
        this.updateDimensions = this.updateDimensions.bind(this)
        this.confirmTitle = "";
        this.confirmMessage = "";
    }
    componentDidMount = () => {
        window.addEventListener("resize", this.updateDimensions());
        this.setState({ isLoading: true })
        setTimeout(() => {
            const { ratingTabs } = this.props;
            if (ratingTabs === 'okr-review') {
                this.getDashboardReviewData();
            } else {
                this.getDashboardRatingData();
            }
        }, 400)
        const currencyData = this.props.OkrMasters.filter((t) => t.var_list_name === 'Currency')
        this.setState({ currencyInfo: currencyData })

    };

    getDashboardRatingData = () => {
        const { empId, fyId, monthOfRating, authUser, ratingTabs, OKRType } = this.props;
        const { isHighlightForSubmit } = this.state
        let ratingType = '';
        if (ratingTabs === 'self-rating') {
            ratingType = 'self';
        } else if (ratingTabs === 'supervisor-rating') {
            ratingType = 'sup';
        } else if (ratingTabs === 'thirdparty-rating') {
            ratingType = 'tp';
        }
        try {
            if (ratingType !== '') {
                this.setState({ isLoading: true })
                const okrRatingDashboardData = Promise.resolve(
                    OKRRatingServices.getDashboardData(authUser, empId, fyId, monthOfRating, OKRType, ratingType, 'details')
                );
                okrRatingDashboardData.then((resultData) => {
                    if (OKRType === '1') {
                        this.props.addUpdateOkrRatingRedux({ ratingBusinessOkr: resultData })
                    }
                    if (OKRType === '2') {
                        this.props.addUpdateOkrRatingRedux({ ratingCentralOkr: resultData })
                    }
                    const isRatingSubmitted = this.checkSelfSupRatingDone(resultData, ratingType)
                    this.setState({ ratingSubmitted: isRatingSubmitted, isLoading: false })
                    if (isHighlightForSubmit) {
                        this.setState({ isHighlightForSubmit: false })
                        this.submitOKRRating(true)
                    }
                });
            }
        } catch (err) {
            this.setState({ isLoading: false })
            console.log("OKRRatingServices", err)
        }
    }

    checkSelfSupRatingDone(pOKRData, ratingType) {
        try {
            // check the self / Supervisor rating are done
            if (typeof pOKRData !== 'undefined') {
                if (pOKRData.length > 0) {
                    let filterdData = false;
                    if (ratingType === 'sup')
                        filterdData = pOKRData.filter((item) => { return item.supRatingSubmitted === "Y" }).length > 0
                    else if (ratingType === 'self')
                        filterdData = pOKRData.filter((item) => { return item.selfRatingSubmitted === "Y" }).length > 0
                    else if (ratingType === 'final' || ratingType === 'review')
                        filterdData = pOKRData.filter((item) => { return item.reviewSubmitted === "Y" }).length > 0
                    return filterdData
                }
            }
            return false
        } catch (error) {

        }
    }

    getDashboardReviewData = () => {
        const { empId, fyId, monthOfRating, authUser, OKRType } = this.props;
        try {
            let ratingType = this.ratingType();
            this.setState({ isLoading: true })
            const okrRatingDashboardData = Promise.resolve(
                OKRRatingServices.getReviewDashboardData(authUser, empId, fyId, monthOfRating, OKRType, "rating")
            );
            okrRatingDashboardData.then((resultData) => {
                if (OKRType === '1') {
                    this.props.addUpdateOkrRatingRedux({ reviewBusinessOkr: resultData })
                }
                if (OKRType === '2') {
                    this.props.addUpdateOkrRatingRedux({ reviewCentralOkr: resultData })
                }
                const isRatingSubmitted = this.checkSelfSupRatingDone(resultData, ratingType)
                this.setState({ ratingSubmitted: isRatingSubmitted, isLoading: false })
                this.setState({ isHighlightForSubmit: false })
                this.submitOKRRating(true)

            });
        } catch (err) {
            this.setState({ isLoading: false })
            console.log("OKRRatingServices", err)
        }
    }
    componentDidUpdate = (prevProps, prevState) => {
        const { showStatus, refreshChildVar } = this.props;
        if (refreshChildVar !== prevProps.refreshChildVar) {
            if (this.submitOKRRating(true)) {
                // this.callActiveTab();
            }
        }
        if (showStatus !== prevProps.showStatus) {
            this.updateDimensions()
        }
    };
    updateDimensions() {
        const updateheight = window.innerHeight - 427;
        const windowAdjust = window.innerHeight - 355;
        this.setState({
            height: updateheight,
            windowAdjust: windowAdjust,
        });
    }
    opneKrDetailsPopup = (data) => {
        this.setState({ viewKrDetailsPopup: true, KRData: data })
    }
    closeKrDetailsPopup = () => {
        this.setState({ viewKrDetailsPopup: false, KRData: {} })
    }
    openKrRatingPopup = (data, ratingType) => {
        this.setState({ viewKrRatingPopup: true, KRData: data, ratingType })
    }
    closeKrRatingPopup = () => {
        this.setState({ viewKrRatingPopup: false })
    }
    openActualAchivementPopup = (data, categoryType, actionType) => {
        this.setState({ viewActualAchivementPopup: true, actualKRData: data, categoryType: categoryType, actionType: actionType })
    }
    closeActualAchivementPopup = () => {
        this.setState({ viewActualAchivementPopup: false, actualKRData: {}, categoryType: '', actionType: '', userType: '' })
    }
    openFinalRatingPopup = (data) => {
        this.setState({ finalRatingPopup: true, finalRatingData: data })
    }
    closeFinalRatingPopup = () => {
        this.setState({ finalRatingPopup: false, finalRatingData: {} })
    }
    openPastRatingPopup = (data) => {
        this.setState({ pastRatingPopup: true, pastRatingKRData: data })
    }
    closePastRatingPopup = () => {
        this.setState({ pastRatingPopup: false, pastRatingKRData: {} })
    }
    openAddEditIntrimFeedbackPopup = (data) => {
        this.setState({
            addEditInterimReviewPopup: true,
            KRData: data,

        });
    };
    closeAddEditIntrimFeedbackPopup = () => {
        this.setState({
            addEditInterimReviewPopup: false,
            KRData: {},
        });
    };

    openKrIntrimFeedbackPopup = (data, userObjectiveId, objectiveName) => {
        this.setState({
            viewKrInterimFeedbackPopup: true,
            KRData: data,
            objId: userObjectiveId,
            objectiveName: objectiveName
        });
    };
    closeKrIntrimFeedbackPopup = () => {
        this.setState({
            viewKrInterimFeedbackPopup: false,
            KRData: {},
            objectiveName: ''
        });
    };

    openViewIntrimFeedbackPopup = (data, userObjectiveId, selectedType, draftClick) => {

        this.setState({
            KRData: data,
            viewInterimReviewPopup: true,
            objId: userObjectiveId,
            selectedType: selectedType
        });
        this.props.addUpdateOkrRatingRedux({ krData: data, interimType: selectedType, isDraftClick: draftClick })
    };
    closeViewIntrimFeedbackPopup = () => {
        this.setState({
            viewInterimReviewPopup: false,
            objId: null,
            selectedType: null,
            KRData: {}
        });
        this.props.addUpdateOkrRatingRedux({ krData: {}, interimType: "", isDraftClick: null })
    };
    //WARNING! To be deprecated in React v17. Use new lifecycle static getDerivedStateFromProps instead.
    componentWillReceiveProps(nextProps) {//NOSONAR
        const { OKRType, businessOKRCounter, centralOKRCounter, ratingStatusData } = this.props
        if (
            (nextProps.businessOKRCounter !== businessOKRCounter && OKRType === '1' && typeof nextProps.businessOKRCounter !== 'undefined'
                && businessOKRCounter !== 'undefined')
            ||
            (nextProps.centralOKRCounter !== centralOKRCounter
                && OKRType === '2'
                && typeof nextProps.centralOKRCounter !== 'undefined'
                && centralOKRCounter !== 'undefined')
        ) {
            this.refreshContent();
        }
        if (nextProps.refreshRatingDataFlag === 'ratingUpdated' + OKRType) {
            this.closeKrRatingPopup();
            this.refreshContent();
            this.props.addUpdateOkrRatingRedux({ refreshRatingDataFlag: 'updateSummary' })
        }
        else if (nextProps.refreshRatingDataFlag === 'reviewUpdate' + OKRType) {
            this.closeFinalRatingPopup();
            this.refreshContent();
            this.props.addUpdateOkrRatingRedux({ refreshRatingDataFlag: '' })
        }
        else if (nextProps.refreshInterimDataFlag === "interimUpdated" + OKRType) {
            this.closeAddEditIntrimFeedbackPopup();
            this.refreshContent();
            this.closeKrIntrimFeedbackPopup()
            this.props.addUpdateOkrRatingRedux({ refreshInterimDataFlag: "" });
        } else if (nextProps.refreshRatingDataFlag === 'ratingSubmitted' + OKRType) {

            let OKRTypeName = '';
            switch (OKRType) {
                case "1": OKRTypeName = "Business OKR"; break;
                case "2": OKRTypeName = "Central OKR"; break;
                case "3": OKRTypeName = "Leadership Principles"; break;
                default: OKRTypeName = ''
            }
            let pendingAllocations = '';
            if (ratingStatusData.length > 0) {
                ratingStatusData[0].Allocations.forEach(item => {
                    if (item.allocationStatus === 'Pending' && OKRTypeName !== item.allocationName) {
                        pendingAllocations = pendingAllocations + item.allocationName + ', '
                    }
                })
            }
            this.confirmTitle = "Information"
            if (pendingAllocations !== '') {
                this.confirmMessage = "Thanks for submitting your inputs. Please complete the inputs for " +
                    pendingAllocations.substr(0, pendingAllocations.length - 2) + " as well.";
            } else {
                this.confirmMessage = "Thanks for completing your inputs on the OKR Rating module.";
            }
            this.setState({ informationMsg: true })

            this.refreshContent();
            this.props.addUpdateOkrRatingRedux({ refreshRatingDataFlag: 'updateSummary' })
        }
        if (nextProps.refreshRatingDataFlag === 'refresh' + OKRType) {
            this.refreshContent();
            this.closeActualAchivementPopup();
            this.handleAcceptClose();
            this.props.addUpdateOkrRatingRedux({ refreshRatingDataFlag: '' })
            this.setState({ isHighlightForSubmit: true })
        }
        if ((nextProps.ratingCategoryStatus === 'updated' + OKRType) && nextProps.ratingCategoryStatus === 'updated1') {
            this.props.addUpdateOkrRatingRedux({ ratingCategoryStatus: 'updated2' })
            this.refreshContent();
        }
        if ((nextProps.ratingCategoryStatus === 'updated' + OKRType) && nextProps.ratingCategoryStatus === 'updated2') {
            this.props.addUpdateOkrRatingRedux({ ratingCategoryStatus: 'updated3' })
            this.refreshContent();
        }
    }

    refreshContent() {
        try {
            setTimeout(() => {
                const { ratingTabs } = this.props
                if (ratingTabs === 'okr-review') {
                    this.getDashboardReviewData();
                } else {
                    this.getDashboardRatingData();
                }
            }, 100)
        } catch (error) {

        }
    }

    ratingType = () => {
        const { ratingTabs } = this.props
        let type
        switch (ratingTabs) {
            case "self-rating":
                type = "self"; break;
            case "supervisor-rating":
                type = "sup"; break;
            case "thirdparty-rating":
                type = "tp"; break;
            case "okr-review":
                type = "review"; break;
            default:
        }
        return type;
    }

    submitOkrRatingCheckBool = (OKRType, ratingBusinessOkr, ratingCentralOkr, reviewBusinessOkr, reviewCentralOkr, ratingFlow, isHighlightForSubmit) => {
        let checkBool = false;
        if (this.ratingType() === 'review') {
            checkBool = this.checkActualRatingDone(OKRType === '1' ? reviewBusinessOkr : reviewCentralOkr, ratingFlow, isHighlightForSubmit)
        }
        else {
            checkBool = this.checkActualRatingDone(OKRType === '1' ? ratingBusinessOkr : ratingCentralOkr, ratingFlow, isHighlightForSubmit)
        }
        if (checkBool && !isHighlightForSubmit) {
            this.openConfirmDialog("Submit OKR Ratings");
        }
    }

    submitOKRRating = (isHighlightForSubmit) => {
        try {
            const { OKRType, ratingBusinessOkr, ratingCentralOkr, reviewBusinessOkr, reviewCentralOkr, ratingStatusData } = this.props
            if (OKRType === '1' || OKRType === '2') {
                let ratingFlow = "";
                if (ratingStatusData.length > 0) {
                    ratingStatusData[0].Allocations.forEach(item => {
                        if ((item.allocationName === 'Business OKR' && OKRType === '1') || (item.allocationName === 'Central OKR' && OKRType === '2')) {
                            ratingFlow = item.ratingFlow;
                        }
                    })
                }
                this.submitOkrRatingCheckBool(OKRType, ratingBusinessOkr, ratingCentralOkr, reviewBusinessOkr, reviewCentralOkr, ratingFlow, isHighlightForSubmit);
            }
        } catch (error) { }
    }
    /**
     * @author Vivek Khodade
     * @description Submit The Pending All ratings of Okr
     **/

    checkActualRatingFilterData = (pOKRData, ratingFlow, ratingType, isHighlightForSubmit) => {//NOSONAR        
        const { OKRType } = this.props;
        let filterdData = 0;
        if (ratingType === 'review') {
            filterdData = pOKRData.filter((item) => {
                return item.krData.filter(subItem => { return subItem.krFinalRating.IsDone === "N" }).length
            }).length
        } else {
            if (ratingFlow !== '') {
                if ((ratingType === 'self' && ratingFlow === 'self-sup') || (ratingType === 'sup' && ratingFlow === 'sup-self')) {
                    filterdData = pOKRData.filter((item) => {
                        return item.krData.filter(subItem => { return subItem.krPrimaryActualRating.Rating === '' }).length
                    }).length
                } else if ((ratingType === 'self' && ratingFlow === 'sup-self') || (ratingType === 'sup' && ratingFlow === 'self-sup')) {
                    filterdData = pOKRData.filter((item) => {
                        return item.krData.filter(subItem => { return subItem.krSecondaryActualRating.IsGrayed === 'Y' }).length
                    }).length
                }
            }
        }

        if (isHighlightForSubmit) {
            if (filterdData === 0) {
                if (OKRType === '1') {
                    setTimeout(() => { window.submitRating(`submitObjectiveRating${OKRType}`, OKRType, ratingType) }, 500)
                }
                if (OKRType === '2') {
                    setTimeout(() => { window.submitRating(`submitObjectiveRating${OKRType}`, OKRType, ratingType) }, 500)
                }
            }
        } else {
            if (filterdData > 0) {
                getNotify(ERROR, ratingType !== 'review' ? OKRRatingSubmitWarning : OKRFinalRatingSubmitWarning)
                return false;
            } else {
                return true
            }
        }

    }

    checkActualRatingDone(pOKRData, ratingFlow, isHighlightForSubmit) {
        try {
            // check all the actual ratings are updated
            const ratingType = this.ratingType();
            if (typeof pOKRData !== 'undefined') {
                if (pOKRData.length > 0) {
                    return this.checkActualRatingFilterData(pOKRData, ratingFlow, ratingType, isHighlightForSubmit);
                } else if (!isHighlightForSubmit) {
                    getNotify(ERROR, ratingType !== 'review' ? OKRRatingSubmitWarning : OKRFinalRatingSubmitWarning)
                    return false
                }
            }
            return true
        } catch (error) { }
    }

    handleConfirmClose = () => {
        this.setState({ confirmDialog: false, openCheckAcceptancePopup: false })
        this.confirmTitle = ''
        this.confirmMessage = ""
    };

    handleConfirmNo = () => {
        this.handleConfirmClose();
    };

    openConfirmDialog = (title) => {
        this.confirmTitle = title
        if (title === "Submit OKR Ratings") {
            this.confirmMessage = "Once submitted the inputs cannot be edited. Are you sure, you want to submit your inputs?"
        }
        this.setState({ confirmDialog: true })
    }

    confirmTitleSubmitOkr = () => {
        const { empId, fyId, monthOfRating, authUser, OKRType } = this.props;
        const submitData = {
            userId: authUser,
            empId: empId,
            fyId: fyId,
            mnth: monthOfRating,
            OKRType,
            ratingReviewType: this.ratingType()
        }
        this.props.submitOKRRating(submitData)
        this.setState({ confirmDialog: false })
    }

    confirmTitleInformation = () => {
        const { ratingStatusData, OKRType } = this.props;
        let OKRTypeName = '';
        switch (OKRType) {
            case "1": OKRTypeName = "Business OKR"; break;
            case "2": OKRTypeName = "Central OKR"; break;
            case "3": OKRTypeName = "Leadership Principles"; break;
            default: OKRTypeName = ''
        }
        let pendingAllocations = '';
        if (ratingStatusData.length > 0) {
            ratingStatusData[0].Allocations.forEach(item => {
                if (item.allocationStatus === 'Pending' && OKRTypeName !== item.allocationName) {
                    if (pendingAllocations === '')
                        pendingAllocations = item.allocationName;
                }
            })

            if (pendingAllocations !== '') {
                this.props.addUpdateOkrRatingRedux({ pendingOKRs: pendingAllocations })
            }
            this.setState({ informationMsg: false })
        }
    }

    handleConfirmYes = () => {
        try {
            if (this.confirmTitle === "Submit OKR Ratings") {
                this.confirmTitleSubmitOkr();
            } else if (this.confirmTitle === "Information") {
                this.confirmTitleInformation();
            }
        } catch (error) {
            console.log("error====>", error);
        }
    }

    viewKrReviewPopup = (data, type) => {
        this.setState({
            KRReviewData: data,
            viewReviewKrPopup: true,
            type: type
        })
    }
    closeKrReviewPopup = () => {
        this.setState({
            KRReviewData: {},
            viewReviewKrPopup: false,
            type: ''
        })
    }
    openAcceptConfirmationPopup = (data) => {
        this.confirmTitle ="Information";
        this.setState({
            openCheckAcceptancePopup: true,
            KRData: data
        })
    }
    acceptActualAchievement = () => {
        this.handleAcceptClose()
    }
    handleAcceptYes = () => {
        const { KRData } = this.state;
        const { OKRType, empId, authUser } = this.props;
        let data = {
            userId: authUser,
            empId: empId,
            ratingUId: KRData.ratingId,
            OKRType: OKRType
        }
        this.props.okrAccepted(data);
    }
    handleAcceeptNo = () => {
        this.handleAcceptClose()
    }
    handleAcceptClose = () => {
        this.setState({
            openCheckAcceptancePopup: false,
            KRData: {}
        })
    }
    getCategoryType = (type) => {
        switch (type) {
            case 'self-rating':
                return OKR_SELF_REVIEW;
            case 'supervisor-rating':
                return OKR_SUPERVISOR_REVIEW;
            case 'okr-review':
                return OKR_REVIEW;
            default:
        }
    }



    render() {//NOSONAR
        const { height, windowAdjust, viewKrDetailsPopup, viewActualAchivementPopup, KRData, ratingType,
            viewKrRatingPopup, actualKRData, finalRatingPopup, finalRatingData, pastRatingPopup, pastRatingKRData, objId, addEditInterimReviewPopup,
            viewKrInterimFeedbackPopup,
            viewInterimReviewPopup, currencyInfo,
            objectiveName, confirmDialog, ratingSubmitted, KRReviewData, viewReviewKrPopup, type, selectedType, openCheckAcceptancePopup, categoryType, actionType, informationMsg, isLoading } = this.state;
        const { showStatus, ratingTabs, OKRType, ratingBusinessOkr, ratingCentralOkr, reviewBusinessOkr, reviewCentralOkr, productivityallocationData,
            ratingStatusData, monthOfRating, authUser, empId } = this.props;
        let OKRAllocationStatus = "";
        let isRatingEndDateCompleted = "N";
        if (ratingStatusData.length > 0) {
            ratingStatusData[0].Allocations.forEach(item => {
                if (item.allocationName === 'Business OKR' && OKRType === '1') {
                    OKRAllocationStatus = item.allocationStatus
                }
                if (item.allocationName === 'Central OKR' && OKRType === '2') {
                    OKRAllocationStatus = item.allocationStatus
                }
            })
        }
        let businessOkrCount = 0;
        let centralOkrCount = 0;
        if (ratingTabs === 'self-rating' || ratingTabs === 'supervisor-rating') {
            businessOkrCount = ratingBusinessOkr.length
            centralOkrCount = ratingCentralOkr.length
            if (OKRType === '1' && businessOkrCount > 0) {
                isRatingEndDateCompleted = ratingBusinessOkr[0].isRatingEndDateCompleted
            } else if (OKRType === '2' && centralOkrCount > 0) {
                isRatingEndDateCompleted = ratingCentralOkr[0].isRatingEndDateCompleted
            }

        } else if (ratingTabs === 'okr-review') {
            businessOkrCount = reviewBusinessOkr.length
            centralOkrCount = reviewCentralOkr.length
            if (OKRType === '1' && businessOkrCount > 0) {
                isRatingEndDateCompleted = reviewBusinessOkr[0].isRatingEndDateCompleted
            } else if (OKRType === '2' && centralOkrCount > 0) {
                isRatingEndDateCompleted = reviewCentralOkr[0].isRatingEndDateCompleted
            }
        }
        if (document.getElementById('businessOkrCount') !== null)
            document.getElementById('businessOkrCount').innerHTML = `(${businessOkrCount})`;
        if (document.getElementById('centralOkrCount') !== null)
            document.getElementById('centralOkrCount').innerHTML = `(${centralOkrCount})`;
        let showButtonForSubmit = true;
        if (authUser === empId && ratingTabs === 'okr-review') {
            showButtonForSubmit = false
        }
        if (ratingStatusData.length > 0 && ratingStatusData[1].Ratings[0].allocationStatus === "Pending" && ratingTabs === 'supervisor-rating') {
            showButtonForSubmit = false
        }
        return (

            <div>
                <style>{cssForScroll} </style>
                <div style={{ height: showStatus ? height : windowAdjust, overflowY: 'auto' }}>
                    {/* Rating Componets load */}
                    {/* business okr */}
                    {
                        isLoading &&
                        <Progressbar />
                    }
                    {
                        ratingBusinessOkr !== null &&
                        typeof ratingBusinessOkr !== 'undefined' &&

                        (ratingStatusData !== null &&
                            typeof ratingStatusData !== 'undefined' &&
                            (ratingStatusData.length > 0 || monthOfRating === '-1'))

                        &&
                        (ratingTabs === 'self-rating' || ratingTabs === 'supervisor-rating' || ratingTabs === 'thirdparty-rating') && (ratingBusinessOkr.length > 0) && OKRType === '1' &&
                        ratingBusinessOkr.map((item, inx) => (
                            <div key={`businessOKR_${inx}`} id={`businessOKR_${inx}`}>
                                <RatingObjectivKR objectiveData={item}
                                    opneKrDetailsPopup={this.opneKrDetailsPopup}
                                    openKrRatingPopup={this.openKrRatingPopup}
                                    viewKrReviewPopup={this.viewKrReviewPopup}
                                    openActualAchivementPopup={this.openActualAchivementPopup}
                                    openAddEditIntrimFeedbackPopup={this.openAddEditIntrimFeedbackPopup}
                                    openKrIntrimFeedbackPopup={this.openKrIntrimFeedbackPopup}
                                    openViewIntrimFeedbackPopup={this.openViewIntrimFeedbackPopup}
                                    ratingTabs={ratingTabs}
                                    OKRType={OKRType}
                                    currencyInfo={currencyInfo}
                                    userVariableImpactPerc={productivityallocationData.businessUserVariableImpact}
                                    VariableLinked={productivityallocationData.businessVariableLinked}
                                    OKRAllocationStatus={OKRAllocationStatus}
                                    openAcceptConfirmationPopup={this.openAcceptConfirmationPopup}
                                    categoryName={this.getCategoryType(ratingTabs)}
                                />
                            </div>
                        ))
                    }
                    {/* central okr */}
                    {
                        ratingCentralOkr !== null &&
                        (typeof ratingCentralOkr !== 'undefined') &&
                        ratingStatusData !== null &&
                        typeof ratingStatusData !== 'undefined' &&
                        ratingStatusData.length > 0 &&
                        (ratingTabs === 'self-rating' || ratingTabs === 'supervisor-rating' || ratingTabs === 'thirdparty-rating') && (ratingCentralOkr.length > 0)
                        && OKRType === '2' && ratingCentralOkr.map((item, inx) => (
                            <div key={`centralOKR_${inx}`} id={`centralOKR_${inx}`}>
                                <RatingObjectivKR objectiveData={item}
                                    opneKrDetailsPopup={this.opneKrDetailsPopup}
                                    openKrRatingPopup={this.openKrRatingPopup}
                                    viewKrReviewPopup={this.viewKrReviewPopup}
                                    openActualAchivementPopup={this.openActualAchivementPopup}
                                    openAddEditIntrimFeedbackPopup={this.openAddEditIntrimFeedbackPopup}
                                    openKrIntrimFeedbackPopup={this.openKrIntrimFeedbackPopup}
                                    openViewIntrimFeedbackPopup={this.openViewIntrimFeedbackPopup}
                                    ratingTabs={ratingTabs}
                                    OKRType={OKRType}
                                    currencyInfo={currencyInfo}
                                    userImpactPerc={productivityallocationData.centralUserImpact}
                                    userVariableImpactPerc={productivityallocationData.centralUserVariableImpact}
                                    VariableLinked={productivityallocationData.centralVariableLinked}
                                    frequency={productivityallocationData.centralUserFrequency}
                                    OKRAllocationStatus={OKRAllocationStatus}
                                    openAcceptConfirmationPopup={this.openAcceptConfirmationPopup}
                                    categoryName={this.getCategoryType(ratingTabs)}
                                />
                            </div>
                        ))
                    }
                    {/* Review Components load */}
                    {
                        reviewBusinessOkr !== null &&
                        (typeof reviewBusinessOkr !== 'undefined') &&
                        ratingStatusData !== null &&
                        typeof ratingStatusData !== 'undefined' &&
                        ratingStatusData.length > 0 &&
                        (ratingTabs === 'okr-review') && (reviewBusinessOkr.length > 0) && OKRType === '1' &&
                        reviewBusinessOkr.map((item, inx) => (
                            <div key={`reviewBusinessOKR_${inx}`} id={`reviewBusinessOKR_${inx}`}>
                                <ReviewObjectivKR objectiveData={item}
                                    opneKrDetailsPopup={this.opneKrDetailsPopup}
                                    openFinalRatingPopup={this.openFinalRatingPopup}
                                    openPastRatingPopup={this.openPastRatingPopup}
                                    openActualAchivementPopup={this.openActualAchivementPopup}
                                    openAddEditIntrimFeedbackPopup={this.openAddEditIntrimFeedbackPopup}
                                    openKrIntrimFeedbackPopup={this.openKrIntrimFeedbackPopup}
                                    openViewIntrimFeedbackPopup={this.openViewIntrimFeedbackPopup}
                                    ratingTabs={ratingTabs}
                                    OKRType={OKRType}
                                    currencyInfo={currencyInfo}
                                    viewKrReviewPopup={this.viewKrReviewPopup}
                                    OKRAllocationStatus={OKRAllocationStatus}
                                    categoryName={this.getCategoryType(ratingTabs)}
                                    VariableLinked={productivityallocationData.businessVariableLinked} />
                            </div>
                        ))
                    }
                    {
                        reviewCentralOkr !== null &&
                        (typeof reviewCentralOkr !== 'undefined') &&
                        ratingStatusData !== null &&
                        typeof ratingStatusData !== 'undefined' &&
                        ratingStatusData.length > 0 &&
                        (ratingTabs === 'okr-review') && (reviewCentralOkr.length > 0) && OKRType === '2' &&
                        reviewCentralOkr.map((item, inx) => (
                            <div key={`reviewCentralOKR_${inx}`} id={`reviewCentralOKR_${inx}`}>
                                <ReviewObjectivKR objectiveData={item}
                                    opneKrDetailsPopup={this.opneKrDetailsPopup}
                                    openFinalRatingPopup={this.openFinalRatingPopup}
                                    openPastRatingPopup={this.openPastRatingPopup}
                                    openActualAchivementPopup={this.openActualAchivementPopup}
                                    openAddEditIntrimFeedbackPopup={this.openAddEditIntrimFeedbackPopup}
                                    openKrIntrimFeedbackPopup={this.openKrIntrimFeedbackPopup}
                                    openViewIntrimFeedbackPopup={this.openViewIntrimFeedbackPopup}
                                    ratingTabs={ratingTabs}
                                    OKRType={OKRType}
                                    currencyInfo={currencyInfo}
                                    viewKrReviewPopup={this.viewKrReviewPopup}
                                    OKRAllocationStatus={OKRAllocationStatus}
                                    categoryName={this.getCategoryType(ratingTabs)}
                                    VariableLinked={productivityallocationData.centralVariableLinked} />
                            </div>
                        ))
                    }
                    {/* Common popups load */}
                    {
                        viewKrDetailsPopup &&
                        <ViewKRDetailsModal
                            closeKrDetailsPopup={this.closeKrDetailsPopup}
                            OKRType={OKRType}
                            ratingTabs={ratingTabs}
                            ratingType={ratingType}
                            KRData={KRData}

                        />
                    }
                    {
                        viewActualAchivementPopup &&
                        <ActualAchivementModal
                            closeActualAchivementPopup={this.closeActualAchivementPopup}
                            OKRType={OKRType}
                            ratingTabs={ratingTabs} actualKRData={actualKRData}
                            currencyInfo={currencyInfo}
                            OKRAllocationStatus={OKRAllocationStatus}
                            categoryType={categoryType}
                            actionType={actionType}
                        />
                    }
                    {
                        viewKrRatingPopup &&
                        <AddRatingModalkr
                            closeKrRatingPopup={this.closeKrRatingPopup}
                            OKRType={OKRType}
                            ratingTabs={ratingTabs}
                            KRData={KRData}

                        />}
                    {
                        finalRatingPopup &&
                        <AddRatingModalkr
                            closeKrRatingPopup={this.closeFinalRatingPopup}
                            OKRType={OKRType}
                            ratingTabs={ratingTabs}
                            KRData={finalRatingData}
                            type={"finalRaing"}
                        />
                    }
                    {
                        pastRatingPopup &&
                        <PastRatingModal
                            OKRType={OKRType}
                            KRData={pastRatingKRData}
                            closePastRatingPopup={this.closePastRatingPopup}
                        />
                    }
                    {viewInterimReviewPopup &&
                        <InterimFeedBack
                            closeViewIntrimFeedbackPopup={this.closeViewIntrimFeedbackPopup}
                            ratingTabs={ratingTabs}
                            OKRType={OKRType}
                            KRData={KRData}
                            objId={objId}
                            selectedType={selectedType}
                        />}
                    {viewKrInterimFeedbackPopup &&
                        <ViewkrInterimFeedbackModel
                            KRData={KRData}
                            objId={objId}
                            OKRType={OKRType}
                            ratingTabs={ratingTabs}
                            closeKrIntrimFeedbackPopup={this.closeKrIntrimFeedbackPopup}
                            objectiveName={objectiveName}
                        />
                    }
                    {addEditInterimReviewPopup && (
                        <AddEditInterimReviewModal
                            closeAddEditIntrimFeedbackPopup={this.closeAddEditIntrimFeedbackPopup}
                            type={"Add"}
                            KRData={KRData}
                            OKRType={OKRType}
                            ratingTabs={ratingTabs}
                        />
                    )}
                    {/* View Rating KR  */}
                    {/* <Setmeeting/> */}
                    {
                        viewReviewKrPopup &&
                        <ViewRatingModalKr
                            OKRType={OKRType}
                            KRReviewData={KRReviewData}
                            closeKrReviewPopup={this.closeKrReviewPopup}
                            type={type}
                        />
                    }
                    {/* <InterimReviewModal/> */}
                    <ConfirmationPopup
                        showButton={{ type: "1" }}
                        open={confirmDialog}
                        button1function={() => this.handleConfirmYes()}
                        button2function={() => this.handleConfirmNo()}
                        headerMessage={this.confirmMessage}
                        button1Text={"Yes"}
                button2Text={"No"}
                handleConfirmClose={this.handleConfirmClose}
                    />
                </div>
                {
                    !ratingSubmitted && OKRAllocationStatus === 'Pending' && showButtonForSubmit &&
                    <>
                        {
                            <div style={{ float: 'right', marginRight: 17, padding: '5px 0px 5px 0px', }}>
                                <Button
                                    style={{
                                        width: "120px",
                                        color: isRatingEndDateCompleted === "N" ? "#fff" : "#c1baba"
                                    }}
                                    className="remove-button-shadow"
                                    variant="contained"
                                    id={`submitObjectiveRating${OKRType}`}
                                    color={isRatingEndDateCompleted === "N" ? "primary" : "default"}
                                    disabled={isRatingEndDateCompleted === "Y"}
                                    onClick={(e) => {
                                        if (monthOfRating === '-1') {
                                            this.submitPendingAllRating()
                                        } else {
                                            this.submitOKRRating(false)
                                        }
                                    }}>
                                    Submit
                                </Button>
                            </div>
                        }
                    </>
                }
                {
                    ratingSubmitted && OKRAllocationStatus === 'Completed' &&
                    <div style={{ float: 'right', marginRight: 20, padding: '5px 0px 5px 0px', }}>
                        {OKRRatingSubmitedMessage}
                    </div>
                }
                {
                    openCheckAcceptancePopup &&
                    <>
                        <ConfirmationPopup
                            showButton={{ type: "1" }}
                            open={openCheckAcceptancePopup}
                            button1function={() => this.handleAcceptYes()}
                            button2function={() => this.handleConfirmNo()}
                            headerMessage={"Are you sure, you want to accept the team-member's self-input?"}
                            button1Text={"Yes"}
                            button2Text={"No"}
                            handleConfirmClose={() => this.handleConfirmNo()}
                        />
                    </>
                }
                {
                    informationMsg &&
                    <>
                        <ConfirmationPopup
                            showButton={{ type: "1" }}
                            open={informationMsg}
                            button1function={() => this.handleConfirmYes()}
                            button2function={() => this.handleConfirmYes()}
                            headerMessage={this.confirmMessage}
                            button1Text={"OK"}
                  button2Text={""}
                  handleConfirmClose={() => this.handleConfirmNo()}
                        />
                    </>
                }
            </div>
        )
    }
}

const mapStateToProps = ({ okrratingreducer, auth, okrreducer, meetingcategory }) => {
    const { authUser } = auth;
    const { categoryName } = meetingcategory;
    const { OkrMasters } = okrreducer;
    const { empId, fyId, monthOfRating, refreshRatingDataFlag, ratingBusinessOkr, ratingCentralOkr, reviewBusinessOkr, reviewCentralOkr, refreshInterimDataFlag
        , productivityallocationData, ratingStatusData, ratingCategoryStatus, ratingTabs } = okrratingreducer;
    return {
        empId, fyId, monthOfRating, authUser, refreshRatingDataFlag, ratingBusinessOkr, ratingCentralOkr, reviewBusinessOkr, reviewCentralOkr, refreshInterimDataFlag,
        productivityallocationData, OkrMasters, ratingStatusData, categoryName, ratingCategoryStatus, ratingTabs
    }
};
export default connect(mapStateToProps, { addUpdateOkrRatingRedux, submitOKRRating, okrAccepted })(
    RatingObjectiveKRContainer
);
