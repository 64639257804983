import {
    UPDATE_CANDIDATE_INFO
} from '../../constant/entryexit/candidateconstant';

const INIT_STATE = {
    send_link: [],
    approved_files: [],
    rejected_files: [],
    bandMaster: [],
    highestEducationQualifcationMaster: [],
    entityMaster: [],
    employeeTypeMaster: [],
    countryMaster: [], 
    salutationStateMaster: [],
    genderMaster: [],
    servceDivisionSalesUnitMaster: [],
    stateMasterListMaster: [],
    stateFunctionListMaster: [],
    roleMaster:[],
    jobPortalData:[],
    sourceCategoryData:[],
    jobTitleData:[],
    recruitementMaster:[],
    addressCountryMaster:[]
}
// eslint-disable-next-line
export default (state = INIT_STATE ,action) => { //NOSONAR
    if(action.type === UPDATE_CANDIDATE_INFO){
        return {
            ...state,
            ...action.payload
        }
    }else{
        return state;
    }     
}
