import React from "react";
import PropTypes from "prop-types";

import { HtmlEditor, Inject, Link, QuickToolbar, RichTextEditorComponent, Toolbar, Count, } from "@syncfusion/ej2-react-richtexteditor";

/**
 * The RichTextEditor component allows the user to type data with multiple options.
 *
 * @example
 * <RichTextEditor dataSource={data}/>
 */
const RichTextEditor = (props) => {
    const {  id, cssClass,  value   } = props

    /**
     * Triggers when an item in a popup is selected or when the model value is changed by user.
     * @event change
     */
    const onChangeFn = (e) => {
        props.onChange(e);
    }
    return (
        <div className="common-rich-textbox">
            <RichTextEditorComponent
                className={cssClass}
                value={value}
                name={`${id}Err`}
                data-msg-containerid={`${id}Err`}
                change={onChangeFn}
                {...props}
            >
                <Inject services={[Toolbar, Link, Count, ...props.services]} />
            </RichTextEditorComponent>
            <div
                id={`${id}Err`}
                className="error-div"
            />
        </div>
    );
};

RichTextEditor.propTypes = {
    id: PropTypes.string.isRequired,
    cssClass: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    value: PropTypes.number,
};

RichTextEditor.defaultProps = {
    onChange: () => { },//NOSONAR
};

export default RichTextEditor;
