import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import {
  faCalendarDay,
  faIdCardAlt,
  faMoneyCheckAlt,
  faFlag,
  faEnvelope,
  faFax,
  faPhoneSquareAlt,
  faChalkboardTeacher,
  faTasks,
  faFileAlt,
  faFileUpload,
  faUserTie,
  faMapMarkedAlt,
  faLayerGroup,
  faUserCircle,
} from "@fortawesome/free-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ProgressBar from "../progressbar/progressbar";
import "./homeofficebasicinformation.css";
import Services from "../../services/services";
import { getDisplayDateOnly } from "../../app/other/commonfunction";

class HomeOffinceBasicInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      basicInformation: null,
      showMore: false,
    };
  }

  componentDidMount = () => {
    const { authUser } = this.props;
    const employeeBasicInfo = Promise.resolve(
      Services.getEnployeeBasicInformation(authUser, authUser)
    );
    employeeBasicInfo.then((result) => {
      if (result !== null) {
        this.setState({
          basicInformation: result[0],
        });
      }
    });
  };

  render() {
    const { basicInformation, showMore } = this.state;
    return (
      <div>
        <div className="accordian-header-padding-left"> BASIC INFORMATION </div>
        {basicInformation !== null ? (
          <div
            className="control-section col-md-12"
            style={{ height: "130px", width: "100.65%", overflowY: "auto" }}
          >
            {!showMore && (
              <div className="row basicinformation-row">
                <div className="col-md-2 remove-padding" style={{ padding: 5 }}>
                  <div
                    className="col-md-2 remove-padding"
                    style={{ height: "100%", textAlign: "right" }}
                  >
                    <div className="row">
                      <FontAwesomeIcon
                        icon={faUserCircle}
                        className="mr-sm icons-basicinformation"
                      />
                    </div>
                    {/* <center> */}
                    {/* <span className="img-circle fas fa-user-circle remove-padding icons-basicinformation" alt="" style={{ color: 'gray', fontSize: '33px', width: '75%', height: '100%', marginRight:-30 }} /> */}
                    {/* </center> */}
                  </div>
                  <div className="col-md-10 ">
                    <div className="row1 px-sm-2 basicinformation-icon-header-text-font-style">
                      Name
                    </div>
                    <div
                      className="row1 px-sm-2 basicinformation-icon-text-font-style basicinfo-text-limit"
                      title={basicInformation.EmployeeName}
                    >
                      {" "}
                      {/* Sushant Rajput */}
                      {basicInformation.EmployeeName}
                    </div>
                  </div>
                </div>
                <div className="col-md-2 remove-padding" style={{ padding: 5 }}>
                  <div
                    className="col-md-2 remove-padding"
                    style={{ height: "100%", textAlign: "right" }}
                  >
                    <div className="row">
                      <FontAwesomeIcon
                        icon={faIdCardAlt}
                        className="mr-sm icons-basicinformation"
                      />
                    </div>
                  </div>
                  <div className="col-md-10 ">
                    <div className="row1 px-sm-2 basicinformation-icon-header-text-font-style">
                      Employee Code
                    </div>
                    <div className="row1 px-sm-2 basicinformation-icon-text-font-style" title={basicInformation.EmployeeCode}>
                      {basicInformation.EmployeeCode}
                      {/* CIINFO00805 */}
                    </div>
                  </div>
                </div>
                <div className="col-md-2 remove-padding" style={{ padding: 5 }}>
                  <div
                    className="col-md-2 remove-padding"
                    style={{ height: "100%", textAlign: "right" }}
                  >
                    <div className="row">
                      <FontAwesomeIcon
                        icon={faCalendarDay}
                        className="mr-sm icons-basicinformation"
                      />
                    </div>
                  </div>
                  <div className="col-md-10 ">
                    <div className="row1 px-sm-2 basicinformation-icon-header-text-font-style">
                      Date of Joining
                    </div>
                    <div className="row1 px-sm-2 basicinformation-icon-text-font-style" title={`${getDisplayDateOnly(new Date(basicInformation.JoiningDate))}`}>
                      {getDisplayDateOnly(
                        new Date(basicInformation.JoiningDate)
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-2 remove-padding" style={{ padding: 5 }}>
                  <div
                    className="col-md-2 remove-padding"
                    style={{ height: "100%", textAlign: "right" }}
                  >
                    <div className="row">
                      <FontAwesomeIcon
                        icon={faUserTie}
                        className="mr-sm icons-basicinformation"
                      />
                    </div>
                  </div>
                  <div className="col-md-10 ">
                    <div className="row1 px-sm-2 basicinformation-icon-header-text-font-style">
                      Role
                    </div>
                    <div
                      className="row1 px-sm-2 basicinformation-icon-text-font-style basicinfo-text-limit"
                      title={basicInformation.job_title}
                    >
                      {basicInformation.job_title}
                      {/* Assistant Manager */}
                    </div>
                  </div>
                </div>
                <div className="col-md-2 remove-padding" style={{ padding: 5 }}>
                  <div
                    className="col-md-2 remove-padding"
                    style={{ height: "100%", textAlign: "right" }}
                  >
                    <div className="row">
                      <FontAwesomeIcon
                        icon={faMoneyCheckAlt}
                        className="mr-sm icons-basicinformation"
                      />
                    </div>
                  </div>
                  <div className="col-md-10 ">
                    <div className="row1 px-sm-2 basicinformation-icon-header-text-font-style">
                      Employment Type
                    </div>
                    <div className="row1 px-sm-2 basicinformation-icon-text-font-style" title={basicInformation.EmpType}>
                      {basicInformation.EmpType}
                      {/* Full Time (On Rolls) */}
                    </div>
                  </div>
                </div>
                <div className="col-md-2 remove-padding" style={{ padding: 5 }}>
                  <div
                    className="col-md-2 remove-padding"
                    style={{ height: "100%", textAlign: "right" }}
                  >
                    <div className="row">
                      <FontAwesomeIcon
                        icon={faFlag}
                        className="mr-sm icons-basicinformation"
                      />
                    </div>
                  </div>
                  <div className="col-md-10 ">
                    <div className="row1 px-sm-2 basicinformation-icon-header-text-font-style">
                      Base Country
                    </div>
                    <div className="row1 px-sm-2 basicinformation-icon-text-font-style" title={basicInformation.BaseCountry}>
                      {basicInformation.BaseCountry}
                      {/* India - Chennai */}
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/* second row */}
            {/* <div className='row basicinformation-middle-row'> */}
            <div className="row basicinformation-row">
              <div className="col-md-2 remove-padding" style={{ padding: 5 }}>
                <div
                  className="col-md-2 remove-padding"
                  style={{ height: "100%", textAlign: "right" }}
                >
                  <div className="row">
                    {/* <p><i class="zmdi zmdi-settings-square "></i></p> */}
                    <FontAwesomeIcon
                      icon={faMapMarkedAlt}
                      className="mr-sm icons-basicinformation"
                    />
                  </div>
                </div>
                <div className="col-md-10 ">
                  <div className="row1 px-sm-2 basicinformation-icon-header-text-font-style">
                    Base Location
                  </div>
                  <div className="row1 px-sm-2 basicinformation-icon-text-font-style" title={basicInformation.BaseLocation}>
                    {basicInformation.BaseLocation}
                    {/* Navi Mumbai */}
                  </div>
                </div>
              </div>
              <div className="col-md-2 remove-padding" style={{ padding: 5 }}>
                <div
                  className="col-md-2 remove-padding"
                  style={{ height: "100%", textAlign: "right" }}
                >
                  <div className="row">
                    <FontAwesomeIcon
                      icon={faFileUpload}
                      className="mr-sm icons-basicinformation"
                    />
                  </div>
                </div>
                <div className="col-md-10 ">
                  <div className="row1 px-sm-2 basicinformation-icon-header-text-font-style">
                    Reporting Supervisor
                  </div>
                  <div
                    className="row1 px-sm-2 basicinformation-icon-text-font-style basicinfo-text-limit"
                    title={basicInformation.ReportingTo}
                  >
                    {basicInformation.ReportingTo}
                    {/* Bharat Mohite */}
                  </div>
                </div>
              </div>
              <div className="col-md-2 remove-padding" style={{ padding: 5 }}>
                <div
                  className="col-md-2 remove-padding"
                  style={{ height: "100%", textAlign: "right" }}
                >
                  <div className="row">
                    <FontAwesomeIcon
                      icon={faFileAlt}
                      className="mr-sm icons-basicinformation"
                    />
                  </div>
                </div>
                <div className="col-md-10 ">
                  <div className="row1 px-sm-2 basicinformation-icon-header-text-font-style">
                    Business Unit / Group Function 
                  </div>
                  <div className="row1 px-sm-2 basicinformation-icon-text-font-style" title={basicInformation.Pnl}>
                    {basicInformation.Pnl}
                    {/* Cheers Interactive */}
                  </div>
                </div>
              </div>
              <div className="col-md-2 remove-padding" style={{ padding: 5 }}>
                <div
                  className="col-md-2 remove-padding"
                  style={{ height: "100%", textAlign: "right" }}
                >
                  <div className="row">
                    <FontAwesomeIcon
                      icon={faTasks}
                      className="mr-sm icons-basicinformation"
                    />
                  </div>
                </div>
                <div className="col-md-10 ">
                  <div className="row1 px-sm-2 basicinformation-icon-header-text-font-style">
                    Sub Business Unit / Function 
                  </div>
                  <div className="row1 px-sm-2 basicinformation-icon-text-font-style basicinfo-text-limit" title={basicInformation.SubPnl}>
                    {basicInformation.SubPnl}
                    {/* Enterprise Systems Group */}
                  </div>
                </div>
              </div>
              <div className="col-md-2 remove-padding" style={{ padding: 5 }}>
                <div
                  className="col-md-2 remove-padding"
                  style={{ height: "100%", textAlign: "right" }}
                >
                  <div className="row">
                    <FontAwesomeIcon
                      icon={faChalkboardTeacher}
                      className="mr-sm icons-basicinformation"
                    />
                  </div>
                </div>
                <div className="col-md-10 ">
                  <div className="row1 px-sm-2 basicinformation-icon-header-text-font-style basicinfo-text-limit">
                    Practice Area / Sales Unit / Sub Function
                  </div>
                  <div className="row1 px-sm-2 basicinformation-icon-text-font-style" title={basicInformation.var_service_division_name}>
                    {basicInformation.var_service_division_name}
                    {/* Nasrulla Khan */}
                  </div>
                </div>
              </div>
              <div className="col-md-2 remove-padding" style={{ padding: 5 }}>
                <div
                  className="col-md-2 remove-padding"
                  style={{ height: "100%", textAlign: "right" }}
                >
                  <div className="row">
                    <FontAwesomeIcon
                      icon={faFileAlt}
                      className="mr-sm icons-basicinformation"
                    />
                  </div>
                </div>
                <div className="col-md-10 ">
                  <div className="row1 px-sm-2 basicinformation-icon-header-text-font-style basicinfo-text-limit">
                    Layer
                  </div>
                  <div className="row1 px-sm-2 basicinformation-icon-text-font-style" title={basicInformation.LayerName}>
                    {basicInformation.LayerName}
                    {/* Nasrulla Khan */}
                  </div>
                </div>
              </div>
            </div>
            {/* Third Row */}
            {showMore && (
              <div className="row last-basicinformation-row">
                <div className="col-md-2 remove-padding" style={{ padding: 5 }}>
                  <div
                    className="col-md-2 remove-padding"
                    style={{ height: "100%", textAlign: "right" }}
                  >
                    <div className="row">
                      <FontAwesomeIcon
                        icon={faUserTie}
                        className="mr-sm icons-basicinformation"
                      />
                    </div>
                  </div>
                  <div className="col-md-10 ">
                    <div className="row1 px-sm-2 basicinformation-icon-header-text-font-style">
                      Sub Layer
                    </div>
                    <div
                      className="row1 px-sm-2 basicinformation-icon-text-font-style basicinfo-text-limit"
                      title={basicInformation.SubLayerName}
                    >
                      {basicInformation.SubLayerName}
                      {/* sushant.rajput@cheersin.com */}
                    </div>
                  </div>
                </div>
                <div className="col-md-2 remove-padding" style={{ padding: 5 }}>
                  <div
                    className="col-md-2 remove-padding"
                    style={{ height: "100%", textAlign: "right" }}
                  >
                    <div className="row">
                      <FontAwesomeIcon
                        icon={faEnvelope}
                        className="mr-sm icons-basicinformation"
                      />
                    </div>
                  </div>
                  <div className="col-md-10 ">
                    <div className="row1 px-sm-2 basicinformation-icon-header-text-font-style">
                      Official Email ID
                    </div>
                    <div
                      className="row1 px-sm-2 basicinformation-icon-text-font-style basicinfo-text-limit"
                      title={basicInformation.EmailAddress}
                    >
                      {basicInformation.EmailAddress}
                      {/* sushant.rajput@cheersin.com */}
                    </div>
                  </div>
                </div>
                {/* <div className="col-md-2 remove-padding" style={{ padding: 5 }}>
                  <div
                    className="col-md-2 remove-padding"
                    style={{ height: "100%", textAlign: "right" }}
                  >
                    <div className="row">
                      <FontAwesomeIcon
                        icon={faFax}
                        className="mr-sm icons-basicinformation"
                      />
                    </div>
                  </div>
                  <div className="col-md-10 ">
                    <div className="row1 px-sm-2 basicinformation-icon-header-text-font-style">
                      Official Mobile Number
                    </div>
                    <div className="row1 px-sm-2 basicinformation-icon-text-font-style" />
                  </div>
                </div> */}
                <div className="col-md-2 remove-padding" style={{ padding: 5 }}>
                  <div
                    className="col-md-2 remove-padding"
                    style={{ height: "100%", textAlign: "right" }}
                  >
                    <div className="row">
                      <FontAwesomeIcon
                        icon={faPhoneSquareAlt}
                        className="mr-sm icons-basicinformation"
                      />
                    </div>
                  </div>
                  <div className="col-md-10 ">
                    <div className="row1 px-sm-2 basicinformation-icon-header-text-font-style basicinfo-text-limit">
                      Personal Mobile Number
                    </div>
                    <div className="row1 px-sm-2 basicinformation-icon-text-font-style" title={basicInformation.MobileNo}>
                      {basicInformation.MobileNo}
                      {/* 8793124561 */}
                    </div>
                  </div>
                </div>
                <div className="col-md-2 remove-padding" style={{ padding: 5 }}>
                  <div
                    className="col-md-2 remove-padding"
                    style={{ height: "100%", textAlign: "right" }}
                  >
                    <div className="row">
                      <FontAwesomeIcon
                        icon={faPhoneSquareAlt}
                        className="mr-sm icons-basicinformation"
                      />
                    </div>
                  </div>
                  <div className="col-md-2 ">
                    <div className="row1 px-sm-2 basicinformation-icon-header-text-font-style basicinfo-text-limit">
                      Alternative Mobile Number
                    </div>
                    <div className="row1 px-sm-2 basicinformation-icon-text-font-style" title={basicInformation.AltMobileNo}>
                      {basicInformation.AltMobileNo}
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div
              style={{
                float: "right",
                position: "absolute",
                bottom: 0,
                right: 0,
              }}
            >
              {showMore ? ( //NOSONAR
                // eslint-disable-next-line
                <div
                  style={{ fontSize: 18, cursor: "pointer" }}
                  className="mr-sm"
                  onClick={() => this.setState({ showMore: false })}
                >
                  <TooltipComponent
                    closeOn="Click"
                    content="Hide"
                    position="BottomCenter"
                    isSticky={false}
                    cssClass="customtooltip"
                    width={80}
                  >
                    <span style={{ fontSize: 18, cursor: "pointer" }}>...</span>
                  </TooltipComponent>
                </div>
              ) : (
                // eslint-disable-next-line
                <div
                  style={{ fontSize: 18, cursor: "pointer" }}
                  className="mr-sm"
                  onClick={() => this.setState({ showMore: true })}
                >
                  <TooltipComponent
                    closeOn="Click"
                    content="Show more"
                    position="BottomCenter"
                    isSticky={false}
                    cssClass="customtooltip"
                    width={100}
                  >
                    <span style={{ fontSize: 18, cursor: "pointer" }}>...</span>
                  </TooltipComponent>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="control-section col-md-12" style={{ height: 75 }}>
            <ProgressBar />
          </div>
        )}
      </div>
    );
  }
}
HomeOffinceBasicInfo.propTypes = {
  authUser: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
};
HomeOffinceBasicInfo.defaultProps = {
  authUser: "",
};
const mapStateToProps = ({ auth }) => {
  const { authUser } = auth;
  return { authUser };
};
export default connect(mapStateToProps, {})(HomeOffinceBasicInfo);
