import React, { Component } from 'react';
import { connect } from 'react-redux';
import HelpdeskGrid from './commonsetupcontainer/helpdeskgrid';
import HelpDeskPopup from './helpdesksetuppopupcontainer';

class HelpDeskTicket extends Component {
	constructor(props) {
		super(props);
		this.state = {
			openPopup: "close",
			ticketId: null,
			addUpdate: false,
			height:
				window.innerHeight ||
				document.documentElement.clientHeight ||
				document.body.clientHeight,
			showStatus:
				/**nosonar**/ (window.innerHeight ||
					document.documentElement.clientHeight ||
					document.body.clientHeight) > 767
					? true
					: true,
		};
	}
	changePopUpHandler = (ticketId, type = "close", addUpdate = false) => {
		if (type === "open") {
			this.setState({
				openPopup: "add",
				ticketId,
				addUpdate
			});
		} else if (type === "close") {
			this.setState({ openPopup: "close" });
		} else if (type === "edit") {
			this.setState({ openPopup: "edit" });
		}
	};

	render() {
		const { height } = this.state;
		return (
			<>
				<div style={{ height: height - 66, padding: "1rem" }} className='circular-regular'>
					<div id='helpdesk-container' className='circular-regular'>
						<HelpdeskGrid
							changePopUpHandler={this.changePopUpHandler}
							openPopup={this.state.openPopup}
						/>
					</div>
					{(this.state.openPopup === "add" ||
						this.state.openPopup === "edit") && (
							<HelpDeskPopup
								changePopUpHandler={this.changePopUpHandler}
								openPopup={this.state.openPopup}
								ticketId={this.state.ticketId}
								addUpdate={this.state.addUpdate}
							/>
						)}
				</div>
			</>
		);
	}
}

const mapStateToProps = ({ okrratingreducer }) => {
	const { ratingCategoryStatus } = okrratingreducer;
	return { ratingCategoryStatus }
};
export default connect(mapStateToProps)(
	HelpDeskTicket
);
