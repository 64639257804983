import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Dialog, DialogTitle } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { DialogContent } from "../customdialog/customdialog";
import Graph from './graph';
import ScoringLogicPopup from './scoringlogicpopup'
import services from '../../services/okr/okrservices';
import { getFontStyle } from '@syncfusion/ej2-react-charts';
import ProgressBar from '../progressbar/progressbar';
function GraphView(props) {
  const [openScroringLogicPopup, setOpenScroringLogicPopup] = useState(false);
  const [blueBoxData, setBlueBoxData] = useState({});
  const [loader,setLoader] = useState(true)
  useEffect(() => {
    
    const { authUser, empId, monthOfRating, fyId } = props
    performancePotentialGraphData(fyId, authUser, empId, monthOfRating)
  }, [])


  useEffect(() => {
    const { authUser, empId, monthOfRating, fyId } = props
    performancePotentialGraphData(fyId, authUser, empId, monthOfRating)
  }, [props.graphStatus==='updated'])

  const performancePotentialGraphData = async (fyId, authUser, empId, monthOfRating) => {
    setLoader(true)
    let chartMasterData = await services.getOKRDashboardData('9boxClassification', authUser, empId, fyId, monthOfRating);
    if(chartMasterData.length > 0 ){
      setBlueBoxData(chartMasterData[0]);
    }else{
      setBlueBoxData({});
     
    }
    setTimeout(()=> setLoader(false),300)
  }
const getStyle=()=>{
  return{marginTop: 15,backgroundColor:blueBoxData.Color, border: `1px solid ${blueBoxData.Color}`,maxHeight:document.getElementById('monthlyOkrStatusHeight').offsetHeight}
  
}
const closeScoringogicPopUp = () =>{
  setOpenScroringLogicPopup(false)
}
  return (
    <>
      {(blueBoxData.isShowClassication !== undefined && blueBoxData.isShowClassication==='1') && !loader && <div className='col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 score-logic-box pt-sm pl-sm pr-sm pb-sm' style={getStyle()}>
        <div className='row1'>
          <div className='col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12'>
            <div className='col-2 col-lg-2 col-md-2 col-sm-2 col-xs-2' style={{ borderRight: '1px solid #fff' }}>
              {blueBoxData.performanceScore !== undefined ? <div className='rating-number-text'>{blueBoxData.performanceScore}</div>:<div className='rating-number-text'style={{visibility:'hidden'}}>.</div>}
              <div className='logic-okr-rating-text'>Score</div>
            </div>
            <div className='col-10 col-lg-10 col-md-10 col-sm-10 col-xs-10'>
              <div className='pl-sm performer-text'>{blueBoxData.LogicName}</div>
              <div className='pl-sm logic-okr-rating-text'>9 Box Review</div>
            </div>
          </div>
          <div className='col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pt-sm' >
            <div className='col-3 col-lg-3 col-md-3 col-sm-2 col-xs-3 logic-okr-rating-text' style={{ cursor: 'pointer', zIndex: 11 }} onClick={() => setOpenScroringLogicPopup(true)}>
              Scoring Logic
            </div>
            <div className='col-6 col-lg-6 col-md-6 col-sm-6 col-xs-6'>
            </div>
            <div className='col-3 col-lg-3 col-md-3 col-sm-3 col-xs-3 logic-okr-rating-text'>
              {/* View Details */}
            </div>
          </div>
        </div>
      </div>}
      <div className='col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-md'>

        {/* Chart Section */}
        <div className='row1'>
          {!loader &&
            <Graph showClassification={blueBoxData.isShowClassication} showMonths={props.showMonths} />
          }
          </div>


        <Dialog open={openScroringLogicPopup} fullWidth maxWidth={'sm'}  >
          <DialogTitle>
            <span className="add-new-self-header col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pt-sm " style={{
              color: "black",
              marginLeft: -5,
              paddingBottom: 0,
              fontFamily: "Circular Std ",
              fontSize: 18
            }}
            >
              Scoring Logic {/* (Potential Score - {blueBoxData.potentialAxis}, Performance Score - {blueBoxData.perfomanceAxis})*/}
            </span>
            <span style={{ float: 'right', cursor: 'pointer', marginRight: -1, paddingTop: 12, position: 'absolute' }}>
              <FontAwesomeIcon style={{ color: '#B7B7BB', fontSize: 20 }} icon={faTimes}
                onClick={() => setOpenScroringLogicPopup(false)} />
            </span>
          </DialogTitle>
          <DialogContent style={{ padding: '0px 8px 12px 8px' }}>
            {openScroringLogicPopup && <ScoringLogicPopup authUser={props.authUser} empId={props.empId} monthOfRating={props.monthOfRating} fyId={props.fyId}
           perfomanceAxis={blueBoxData.perfomanceAxis}  potentialAxis={blueBoxData.potentialAxis} fillcolor={blueBoxData.Color} closeScoringogicPopUp={closeScoringogicPopUp}
            />}
          </DialogContent>
        </Dialog>
      </div>
      {
        loader && 
          <div style={{marginTop: 200}}> <ProgressBar /></div>
        }
      
    </>
  )
}
const mapStateToProps = ({ okrdashboardreducer, auth }) => {
  const { authUser } = auth;
  const { dashboardTabs, fyId, teamMemberIds, monthOfRating, empId, monthlyOkrMonthStatus, viewCard, isPeopleLeader, boxClassification, graphStatus } = okrdashboardreducer;
  return {
    boxClassification, viewCard, isPeopleLeader, dashboardTabs, fyId, teamMemberIds, monthOfRating, empId, monthlyOkrMonthStatus, authUser, graphStatus
  }
};
export default connect(mapStateToProps, {})(
  GraphView
);
// export default GraphView;