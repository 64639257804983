import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { addEditHomeOfficeDetails } from "../../redux/actions/homeoffice/homeofficeaction";

// eslint-disable-next-line
class HomeOfficeWorkingSpace extends Component {
  componentDidMount() {
    const { homeOfficeList } = this.props;
    if (homeOfficeList !== undefined && homeOfficeList.length > 0) {
      const { prereqQuiteDesk, prereqNonDistractive, prereqPlainBackground } = homeOfficeList[0];
      this.props.addEditHomeOfficeDetails({
        deskSpaceDualScreenSetUp:prereqQuiteDesk === '1' ? true : prereqQuiteDesk === '0' ? false : null,
        spaceIsolatedDistraction:prereqNonDistractive === '1' ? true : prereqNonDistractive === '0' ? false: null,
        plainBackgroundForVideoCall:prereqPlainBackground === '1' ? true : prereqPlainBackground === '0' ? false : null
      });
    }
  }
  render() {
    const {
      deskSpaceDualScreenSetUp,
      spaceIsolatedDistraction,
      plainBackgroundForVideoCall,
    } = this.props;
    return (
      <div>
        <div className="accordian-header-padding-left">
          {" "}
          HOME-OFFICE WORKING SPACE *{" "}
        </div>
        <div className="control-section col-md-12">
          <div
            className="row "
            style={{ backgroundColor: "#FFF", marginLeft: 0 }}
          >
            <div className="col-xs-12 col-sm-2 col-lg-12 col-md-2">
              <div className="row home-office-working-space-text-padding">
                <div
                  style={{ color: "#6F6E74" }}
                  className="col-xs-6 col-sm-2 col-lg-6 col-md-2"
                >
                  1. Fixed private quiet desk space which can accommodate a dual
                  screen set-up
                </div>
                <div
                  style={{ border: "1px blue" }}
                  className="col-xs-4 col-sm-2 col-lg-4 col-md-2"
                >
                  <div className="row" style={{ marginBottom: 5 }}>
                    {/* eslint-disable-next-line */}
                    <div
                      className={`col-xs-6 col-sm-2 col-lg-4 col-md-2 ${deskSpaceDualScreenSetUp === true
                          ? "button-yes"
                          : "button-unselect"
                        } `}
                      onClick={() => {
                        document.getElementById(
                          "homeofficeworkingspace"
                        ).innerHTML = "";
                        // eslint-disable-next-line
                        this.props.addEditHomeOfficeDetails({
                          deskSpaceDualScreenSetUp: true,
                        });
                      }}
                    >
                      YES
                    </div>
                    {/* eslint-disable-next-line */}
                    <div
                      className={`col-xs-6 col-sm-2 col-lg-4 col-md-2 ${deskSpaceDualScreenSetUp === false
                          ? "button-no"
                          : "button-unselect"
                        }`}
                      onClick={() => {
                        document.getElementById(
                          "homeofficeworkingspace"
                        ).innerHTML = "";
                        // eslint-disable-next-line
                        this.props.addEditHomeOfficeDetails({
                          deskSpaceDualScreenSetUp: false,
                        });
                      }}
                    >
                      NO
                    </div>
                  </div>
                </div>
              </div>
              <div className="row home-office-working-space-text-padding">
                <div
                  style={{ color: "#6F6E74" }}
                  className="col-xs-6 col-sm-2 col-lg-6 col-md-2"
                >
                  2. Space is isolated from potential distractions (television,
                  personal internet, personal calls,music etc.)
                </div>
                <div
                  style={{ border: "1px blue" }}
                  className="col-xs-4 col-sm-2 col-lg-4 col-md-2"
                >
                  <div className="row" style={{ marginBottom: 5 }}>
                    {/* eslint-disable-next-line */}
                    <div
                      className={`col-xs-6 col-sm-2 col-lg-4 col-md-2 ${spaceIsolatedDistraction === true
                          ? "button-yes"
                          : "button-unselect"
                        }`}
                      onClick={() => {
                        document.getElementById(
                          "homeofficeworkingspace"
                        ).innerHTML = "";
                        // eslint-disable-next-line
                        this.props.addEditHomeOfficeDetails({
                          spaceIsolatedDistraction: true,
                        });
                      }}
                    >
                      YES
                    </div>
                    {/* eslint-disable-next-line */}
                    <div
                      className={`col-xs-6 col-sm-2 col-lg-4 col-md-2 ${spaceIsolatedDistraction === false
                          ? "button-no"
                          : "button-unselect"
                        }`}
                      onClick={() => {
                        document.getElementById(
                          "homeofficeworkingspace"
                        ).innerHTML = "";
                        // eslint-disable-next-line
                        this.props.addEditHomeOfficeDetails({
                          spaceIsolatedDistraction: false,
                        });
                      }}
                    >
                      NO
                    </div>
                  </div>
                </div>
              </div>
              <div className="row home-office-working-space-text-padding">
                <div
                  style={{ color: "#6F6E74" }}
                  className="col-xs-6 col-sm-2 col-lg-6 col-md-2"
                >
                  3. Plain backdrop for video enabled digital
                  meeting/presentable background for video coverage area
                </div>
                <div className="col-xs-4 col-sm-2 col-lg-4 col-md-2">
                  <div className="row" style={{ marginBottom: 5 }}>
                    {/* eslint-disable-next-line */}
                    <div
                      className={`col-xs-6 col-sm-2 col-lg-4 col-md-2 ${plainBackgroundForVideoCall === true
                          ? "button-yes"
                          : "button-unselect"
                        }`}
                      onClick={() => {
                        document.getElementById(
                          "homeofficeworkingspace"
                        ).innerHTML = "";
                        // eslint-disable-next-line
                        this.props.addEditHomeOfficeDetails({
                          plainBackgroundForVideoCall: true,
                        });
                      }}
                    >
                      YES
                    </div>
                    {/* eslint-disable-next-line */}
                    <div
                      className={`col-xs-6 col-sm-2 col-lg-4 col-md-2 ${plainBackgroundForVideoCall === false
                          ? "button-no"
                          : "button-unselect"
                        }`}
                      onClick={() => {
                        document.getElementById(
                          "homeofficeworkingspace"
                        ).innerHTML = "";
                        // eslint-disable-next-line
                        this.props.addEditHomeOfficeDetails({
                          plainBackgroundForVideoCall: false,
                        });
                      }}
                    >
                      NO
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              style={{ marginLeft: 15 }}
              id="homeofficeworkingspace"
              className="e-input-group e-float-icon-left error-div"
            />
          </div>
        </div>
        {/* <ConfirmDialog open={open}
                        handleConfirmYes={this.handleConfirmYes}
                        handleConfirmNo={this.handleConfirmNo}
                        // handleConfirmClose={this.handleConfirmClose}
                        button2Caption={button2Caption}
                        button1Caption={button1Caption}
                        title={title}
                        message={message} /> */}
      </div>
    );
  }
}
HomeOfficeWorkingSpace.propTypes = {
  deskSpaceDualScreenSetUp: PropTypes.bool,
  spaceIsolatedDistraction: PropTypes.bool,
  plainBackgroundForVideoCall: PropTypes.bool,
  homeOfficeSuccessMessage: PropTypes.string,
};
HomeOfficeWorkingSpace.defaultProps = {
  deskSpaceDualScreenSetUp: null,
  spaceIsolatedDistraction: null,
  plainBackgroundForVideoCall: null,
  homeOfficeSuccessMessage: '',
};
const mapStateToProps = ({ homeoffice }) => {
  const {
    deskSpaceDualScreenSetUp,
    spaceIsolatedDistraction,
    plainBackgroundForVideoCall,
    homeOfficeSuccessMessage
  } = homeoffice;
  return {
    deskSpaceDualScreenSetUp,
    spaceIsolatedDistraction,
    plainBackgroundForVideoCall,
    homeOfficeSuccessMessage
  };
};
export default connect(mapStateToProps, {
  addEditHomeOfficeDetails,
})(HomeOfficeWorkingSpace);
