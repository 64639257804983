import React, { Component } from "react";
import PropTypes from "prop-types";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import { SwitchComponent } from "@syncfusion/ej2-react-buttons";
import { connect } from "react-redux";
import { Button } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faArrowAltRight,
  faArrowAltLeft,
  faEdit,
  faTrashAlt,
  faPlusCircle,
} from "@fortawesome/pro-light-svg-icons";
import {
  notifyForEveryMeetingData,
  // notifyEveryDayWeek,
  notificationChannel,
  notificationChannelForSchedule,
  defaultNotificationChannel,
  defaultNotificationChannelForSchedule,
  weekDays,
  timeData,
  daysNumber,
  day,
  timeZoneData,
} from "../../assets/constants/constants";
import {
  addNotificationSettings,
  addUpdateNotificationSettings,
} from "../../redux/actions/settingaction";
import {
  defNotificationForEveryMeetingLimit,
  defNotificationForSheduledMeetingLimit,
} from "../../config/config";
import services from "../../services/services";
import Tooltips from "../custommaterialtooltip/custommaterialtooltip";
import { dencrypt } from '../../app/other/commonfunction';

library.add(faArrowAltRight, faArrowAltLeft, faEdit, faTrashAlt);
class NotificationSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      notificationForEveryMeeting: [],
      notificationForScheduledMeeting: [],
      asOnSheduled: false,
      asOnCancelled: false,
      asChangeHappen: false,
    };
    this.handleOnChangeHappen = this.handleOnChangeHappen.bind(this);
    this.handleOsOnCancelled = this.handleOsOnCancelled.bind(this);
    this.handleOnSheduled = this.handleOnSheduled.bind(this);
    this.resetNotificationSettings = this.resetNotificationSettings.bind(this);
    this.getNotificationSettingData = this.getNotificationSettingData.bind(
      this
    );
  }

  componentDidMount = () => {
    this.getNotificationSettingData();
  };
  // eslint-disable-next-line
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.notificationSettingsStatus === "updated") {
      this.getNotificationSettingData();
      // eslint-disable-next-line
      this.props.addUpdateNotificationSettings({
        notificationSettingsStatus: "",
      });
    }
  }

  saveNotificationSettings = async () => {/** NOSONAR*/
    try {
      const {
        notificationForEveryMeeting,
        notificationForScheduledMeeting,
        asOnCancelled,
        asOnSheduled,
        asChangeHappen,
      } = this.state;
      const { authUser } = this.props;
      let minEveryMeetingFlag = false;
      let channelEveryMeetingFlag = false;

      if (notificationForEveryMeeting.length > 0) {
        // eslint-disable-next-line
        notificationForEveryMeeting.forEach((item) => {
          if (item.Minutes === "0") {
            minEveryMeetingFlag = true;
          }
          if (item.Channel === "0") {
            channelEveryMeetingFlag = true;
          }
        });
      }
      let todayNextFlag = false;
      let scheduleChannelFlag = false;
      if (notificationForScheduledMeeting.length > 0) {
        // eslint-disable-next-line
        notificationForScheduledMeeting.forEach((item) => {
          if (item.Day === "0") {
            todayNextFlag = true;
          }
          if (item.Channel === "0") {
            scheduleChannelFlag = true;
          }
        });
      }

      // nosonar if (
      //   asOnCancelled === false &&
      //   asOnSheduled === false &&
      //   asChangeHappen === false
      // ) {
      //   document.getElementById("notificationForEvent").innerHTML =
      //     "Please toggle on at list one notifiaction for event.";
      //   return;
      // }
      if (minEveryMeetingFlag) {
        document.getElementById("notificationForEveryMeeting").innerHTML =
          "Please select the minutes for notification of every meeting.";
        return;
      }
      if (channelEveryMeetingFlag) {
        document.getElementById("notificationForEveryMeeting").innerHTML =
          "Please select the channel for notification of every meeting.";
        return;
      }
      if (todayNextFlag) {
        document.getElementById("notificationForScheduledMeeting").innerHTML =
          "Please select Today/Next for every scheduled meeting.";
        return;
      }
      if (scheduleChannelFlag) {
        document.getElementById("notificationForScheduledMeeting").innerHTML =
          "Please select the channel for notification for scheduled meeting.";
        return;
      }
      let duplicateNotificationForEveryMeetingFlag = false;
      if (notificationForEveryMeeting.length > 0) {
        // eslint-disable-next-line
        notificationForEveryMeeting.forEach((item) => {
          // eslint-disable-next-line
          notificationForEveryMeeting.forEach((data) => {
            if (
              item.Id !== data.Id &&
              item.Minutes === data.Minutes &&
              item.Channel === data.Channel
            ) {
              duplicateNotificationForEveryMeetingFlag = true;
            }
          });
        });
      }
      let duplicateNotificationForScheduledMeetingFlag = false;
      if (notificationForScheduledMeeting.length > 0) {
        // eslint-disable-next-line
        notificationForScheduledMeeting.forEach((item) => {
          // eslint-disable-next-line
          notificationForScheduledMeeting.forEach((data) => {
            if (
              item.Id !== data.Id &&
              item.TimeZone === data.TimeZone &&
              item.Time === data.Time &&
              item.EveryWeekDay === data.EveryWeekDay &&
              item.EveryDayWeek === data.EveryDayWeek &&
              item.Day === data.Day &&
              item.Channel === data.Channel &&
              item.Every === data.Every
            ) {
              duplicateNotificationForScheduledMeetingFlag = true;
            }
          });
        });
      }
      if (duplicateNotificationForEveryMeetingFlag === true) {
        document.getElementById("notificationForEveryMeeting").innerHTML =
          "Please do not set duplicate reminder for notification for every meeting";
        return;
      }
      if (duplicateNotificationForScheduledMeetingFlag === true) {
        document.getElementById("notificationForScheduledMeeting").innerHTML =
          "Please do not set duplicate reminder for scheduled meeting.";
        return;
      }      
      this.props.addNotificationSettings({
        userId: authUser,
        notificationForEveryMeeting,
        notificationForScheduledMeeting,
        asOnCancelled,
        asOnSheduled,
        asChangeHappen,
      });
    } catch (error) {
    }
  };

  getNotificationSettingData = async () => {
    const { authUser } = this.props;
    const notificationSettingDetails = services.getNotificationSettingList(
      authUser
    );
    notificationSettingDetails.then((result) => {
      if (result.length > 0 && result[0].asChangeHappen !== undefined) {
        // eslint-disable-next-line
        result[0].notificationForEveryMeeting.length > 0 &&
          // eslint-disable-next-line
          result[0].notificationForEveryMeeting.map((item) => {
            // eslint-disable-next-line
            item.isExist = "1";
          });
        // eslint-disable-next-line
        result[0].notificationForScheduledMeeting.length > 0 &&
          // eslint-disable-next-line
          result[0].notificationForScheduledMeeting.map((item) => {
            // eslint-disable-next-line
            item.isExist = "1";
          });
        this.setState({
          asChangeHappen: result[0].asChangeHappen,
          asOnCancelled: result[0].asOnCancelled,
          asOnSheduled: result[0].asOnSheduled,
          notificationForEveryMeeting: result[0].notificationForEveryMeeting,
          notificationForScheduledMeeting:
            result[0].notificationForScheduledMeeting,
        });
      }
    });
  };

  resetNotificationSettings = () => {
    document.getElementById("notificationForEvent").innerHTML = "";
    document.getElementById("notificationForEveryMeeting").innerHTML = "";
    document.getElementById("notificationForScheduledMeeting").innerHTML = "";
    this.setState({
      asChangeHappen: true,
      asOnCancelled: true,
      asOnSheduled: true,
      notificationForEveryMeeting: [],
      notificationForScheduledMeeting: [],
    });
  };

  notifictionForEveryMeetingTemplate = (data, index) => {
    const { notificationForEveryMeeting } = this.state;
    return (
      <div
        className="col-xs-12 col-sm-2 col-lg-12 "
        style={{ marginLeft: 0, paddingLeft: 0 }}
        key={`notification_${index}`}
      >
        <div className="col-12 notification-view-font-color ">
          {/** NOSONAR*//* <button
            type="button"
            style={{
              marginTop: 5,
              backgroundColor: "#fff",
              paddingTop: 0,
              paddingBottom: 0,
              height: 32,
              border: "solid 1px #e4d9d9",
              textAlign: "center",
              cursor: "pointer",
              borderRadius: 5,
            }}
            onClick={() => {
              
              let temp = notificationForEveryMeeting;
             
              const removeitem = data;
              const t = [];
              // eslint-disable-next-line
              temp.map((item, i) => {
                if (item.Id !== removeitem.Id) {
                  t.push(item);
                }
              });
              temp = t;
              
              this.setState({ notificationForEveryMeeting: temp });
              document.getElementById("notificationForEveryMeeting").innerHTML =
                "";
            }}
          >
            <TooltipComponent
              className="pointer-cursor"
              content="Delete"
              position="BottomCenter"
              isSticky={false}
              cssClass="customtooltip"
            >
              <FontAwesomeIcon icon={faTrashAlt} style={{ fontSize: "12px", color:'#f94f5e' }} />
            </TooltipComponent>
          </button>
           */}
          &nbsp;
          <div className="notification-text" style={{ float: 'left' }}>
            Notify me&nbsp;
            <select
              name="mins"
              id="mins"
              style={{ cursor: "pointer" }}
              onChange={(e) => {
                e.persist();
                const temp = this.state.notificationForEveryMeeting;
                temp[index].Minutes = e.target.value;
                this.setState({ notificationForEveryMeeting: temp });
                document.getElementById(
                  "notificationForEveryMeeting"
                ).innerHTML = "";
              }}
              value={data.Minutes}
            >
              {notifyForEveryMeetingData.map((item) => {
                return (
                  <option key={item.Value} value={item.Value}>
                    {item.Name}
                  </option>
                );
              })}
            </select>
            &nbsp;before every meeting on&nbsp;
            {/* eslint-disable-next-line */}
            <select
              name="mins"
              id="mins"
              style={{ cursor: "pointer" }}
              // disabled={data.isExist === "1"}
              onChange={(e) => {
                e.persist();
                // eslint-disable-next-line
                const temp = this.state.notificationForEveryMeeting;
                temp[index].Channel = e.target.value;
                this.setState({ notificationForEveryMeeting: temp });
                document.getElementById(
                  "notificationForEveryMeeting"
                ).innerHTML = "";
              }}
              value={data.Channel}
            >
              {dencrypt(localStorage.getItem("telegramNotificationAccess")) === 0
                ? (defaultNotificationChannel.map((defaultNotificationCh) => {
                  return (
                    <option key={defaultNotificationCh.Value} value={defaultNotificationCh.Id}>
                      {defaultNotificationCh.Name}
                    </option>
                  );
                })) : (notificationChannel.map((notificationCh) => {
                  return (
                    <option key={notificationCh.Value} value={notificationCh.Id}>
                      {notificationCh.Name}
                    </option>
                  );
                }))}
            </select>
          </div>
          &nbsp;
          <span style={{ float: 'right' }}>
            <button
              type="button"
              style={{
                // marginTop: 5,
                backgroundColor: "#fff",
                paddingTop: 0,
                paddingBottom: 0,
                height: 32,
                border: "solid 1px #e4d9d9",
                textAlign: "center",
                cursor: "pointer",
                borderRadius: 5,
              }}
              onClick={() => {
             
                let temp = notificationForEveryMeeting;
           
                const removeitem = data;
                const t = [];
                // eslint-disable-next-line
                temp.forEach((item, i) => {
                  if (item.Id !== removeitem.Id) {
                    t.push(item);
                  }
                });
                temp = t;
              
                this.setState({ notificationForEveryMeeting: temp });
                document.getElementById("notificationForEveryMeeting").innerHTML =
                  "";
              }}
            >
              <TooltipComponent
                className="pointer-cursor"
                content="Delete"
                position="BottomCenter"
                isSticky={false}
                cssClass="customtooltip"
              >
                <FontAwesomeIcon icon={faTrashAlt} style={{ fontSize: "12px", color: '#f94f5e' }} />
              </TooltipComponent>
            </button></span>
        </div>
      </div>
    );
  };

  notificationForScheduledMeetingTemplate = (data, index) => {
    return (
      <div className="col-12 notification-view-font-color" key={`notify_${index}`}>

        &nbsp;
        <div className="notification-text" style={{ float: 'left', width: 650 }} key={`div_${index}`}>
          Notify&nbsp;
          {/* eslint-disable-next-line */}
          <select
            key={`cond_${index}`}
            name="mins"
            id="mins"
            style={{ cursor: "pointer" }}
            // disabled={data.isExist === "1"}
            onChange={(e) => {
              e.persist();
              const temp = this.state.notificationForScheduledMeeting;
              temp[index].Day = e.target.value;
              temp[index].Every = e.target.value === 1 ? "0" : "1";
              this.setState({ notificationForScheduledMeeting: temp });
              document.getElementById(
                "notificationForScheduledMeeting"
              ).innerHTML = "";
            }}
            value={data.Day}
          >
            {day.map((item) => {
              return (
                <option key={item.Value} value={item.Id}>
                  {item.Name}
                </option>
              );
            })}
          </select>
          &nbsp;
          {data.Day === "2" && (
            // eslint-disable-next-line
            data.EveryDayWeek === '2' ?
              <select
                key={`dayNo_${index}`}
                name="mins"
                id="mins"
                style={{ cursor: "pointer" }}
                // disabled={data.isExist === "1"}
                onChange={(e) => {
                  e.persist();
                  const temp = this.state.notificationForScheduledMeeting;
                  temp[index].Every = e.target.value;
                  this.setState({ notificationForScheduledMeeting: temp });
                }}
                value={data.Every}
              >
                {daysNumber.slice(0, 2).map((item) => {
                  return (
                    <option key={item.Value} value={item.Value}>
                      {item.Name}
                    </option>
                  );
                })}
              </select>
              :
              <select
                key={`dayNo_${index}`}
                name="mins"
                id="mins"
                style={{ cursor: "pointer" }}
                // disabled={data.isExist === "1"}
                onChange={(event) => {
                  event.persist();
                  // eslint-disable-next-line
                  const temp = this.state.notificationForScheduledMeeting;
                  temp[index].Every = event.target.value;
                  this.setState({ notificationForScheduledMeeting: temp });
                }}
                value={data.Every}
              >
                {daysNumber.map((daysNumberItem) => {
                  return (
                    <option key={daysNumberItem.Value} value={daysNumberItem.Value}>
                      {daysNumberItem.Name}
                    </option>
                  );
                })}
              </select>
          )}
          {data.Day === "2" && (<span>&nbsp;</span>)}
          {data.Day === "2" && (
            /** NOSONAR*/ /*
             // eslint-disable-next-line
             <select
               name="mins"
               id="mins"
               style={{ cursor: "pointer" }}
               // disabled={data.isExist === "1"}
               onChange={(e) => {
                 e.persist();
                 // eslint-disable-next-line
                 const temp = this.state.notificationForScheduledMeeting;
                 temp[index].EveryDayWeek = e.target.value;
                 this.setState({ notificationForScheduledMeeting: temp });
               }}
               value={data.EveryDayWeek}
             >
               {notifyEveryDayWeek.map((item) => {
                 return (
                   <option key={item.Value} value={item.Value}>
                     {item.Name}
                   </option>
                 );
               })}
             </select>
             */
            <span>Day(s)</span>
          )}
          {/* &nbsp; meeting schedule, on every &nbsp; */}
          {data.Day === "2" && (<span>&nbsp;</span>)}
          meeting schedule&nbsp;
          {/* eslint-disable-next-line */}
          {data.Day === "2" && (<span>every&nbsp;</span>)}
          {data.Day === "2" &&
            <select
              key={`weekDays_${index}`}
              name="mins"
              id="mins"
              style={{ cursor: "pointer" }}
              // disabled={data.isExist === "1"}
              onChange={(e) => {
                e.persist();
                // eslint-disable-next-line
                const temp = this.state.notificationForScheduledMeeting;
                temp[index].EveryWeekDay = e.target.value;
                this.setState({ notificationForScheduledMeeting: temp });
              }}
              value={data.EveryWeekDay}
            >
              {weekDays.map((weekDaysItem) => {
                return (
                  <option key={weekDaysItem.Value} value={weekDaysItem.Id}>
                    {weekDaysItem.Name}
                  </option>
                );
              })}
            </select>
          }
          {data.Day === "2" && (<span>&nbsp;</span>)}
          at&nbsp;
          {/* eslint-disable-next-line */}
          <select
            key={`timeData_${index}`}
            name="mins"
            id="mins"
            style={{ cursor: "pointer" }}
            // disabled={data.isExist === "1"}
            onChange={(e) => {
              e.persist();
              // eslint-disable-next-line
              const temp = this.state.notificationForScheduledMeeting;
              temp[index].Time = e.target.value;
              this.setState({ notificationForScheduledMeeting: temp });
            }}
            value={data.Time}
          >
            {timeData.map((item) => {
              return (
                <option key={item.Id} value={item.Value}>
                  {item.Name}
                </option>
              );
            })}
          </select>
          &nbsp;
          {/* eslint-disable-next-line */}
          <select
            key={`timeZoneData_${index}`}
            name="mins"
            id="mins"
            style={{ cursor: "pointer" }}
            // disabled={data.isExist === "1"}
            onChange={(e) => {
              e.persist();
              // eslint-disable-next-line
              const temp = this.state.notificationForScheduledMeeting;
              temp[index].TimeZone = e.target.value;
              this.setState({ notificationForScheduledMeeting: temp });
            }}
            value={data.TimeZone}
          >
            {timeZoneData.length > 0 &&
              timeZoneData.map((item) => {
                return (
                  <option key={item.Id} value={item.Value}>
                    {item.Name}
                  </option>
                );
              })}
          </select>
          &nbsp;on&nbsp;
          {/* eslint-disable-next-line */}
          <select
            key={`notificationChannelForSchedule_${index}`}
            name="mins"
            id="mins"
            style={{ cursor: "pointer" }}
            // disabled={data.isExist === "1"}
            // eslint-disable-next-line
            onChange={(e) => {
              e.persist();
              // eslint-disable-next-line
              const temp = this.state.notificationForScheduledMeeting;
              temp[index].Channel = e.target.value;
              this.setState({ notificationForScheduledMeeting: temp });
              document.getElementById(
                "notificationForScheduledMeeting"
              ).innerHTML = "";
            }}
            value={data.Channel}
          >
            {dencrypt(localStorage.getItem("telegramNotificationAccess")) === 0
              ? (defaultNotificationChannelForSchedule.map((defaultNotificationChForSchedule) => {
                return (
                  <option key={defaultNotificationChForSchedule.Value} value={defaultNotificationChForSchedule.Id}>
                    {defaultNotificationChForSchedule.Name}
                  </option>
                );
              })) : (notificationChannelForSchedule.map((notificationChForScheduleItem) => {
                return (
                  <option key={notificationChForScheduleItem.Value} value={notificationChForScheduleItem.Id}>
                    {notificationChForScheduleItem.Name}
                  </option>
                );
              }))}
          </select>
        </div>
        <span style={{ float: 'right' }}>
          <button
            type="button"
            style={{
              // marginTop: 5,
              backgroundColor: "#fff",
              paddingTop: 0,
              paddingBottom: 0,
              height: 32,
              border: "solid 1px #e4d9d9",
              textAlign: "center",
              cursor: "pointer",
              borderRadius: 5,
            }}
            onClick={() => {
              // eslint-disable-next-line
              let temp = this.state.notificationForScheduledMeeting;
              
              const removeitem = data;
              const t = [];
              // eslint-disable-next-line
              temp.forEach((tempItem, i) => {
                if (tempItem.Id !== removeitem.Id) {
                  t.push(tempItem);
                }
              });
              temp = t;
             
              this.setState({ notificationForScheduledMeeting: temp });
              document.getElementById(
                "notificationForScheduledMeeting"
              ).innerHTML = "";
            }}
          >
            <TooltipComponent
              className="pointer-cursor"
              content="Delete"
              position="BottomCenter"
              isSticky={false}
              cssClass="customtooltip"
            >
              <FontAwesomeIcon icon={faTrashAlt} style={{ fontSize: "12px", color: '#f94f5e' }} />
            </TooltipComponent>
          </button>
        </span>
      </div >
    );
  };

  handleOnChangeHappen() {
    const { asChangeHappen } = this.state;
    this.setState({ asChangeHappen: !asChangeHappen });
    document.getElementById("notificationForEvent").innerHTML = "";
  }

  handleOsOnCancelled() {
    const { asOnCancelled } = this.state;
    this.setState({ asOnCancelled: !asOnCancelled });
    document.getElementById("notificationForEvent").innerHTML = "";
  }

  handleOnSheduled() {
    const { asOnSheduled } = this.state;
    this.setState({ asOnSheduled: !asOnSheduled });
    document.getElementById("notificationForEvent").innerHTML = "";
  }

  render() { // NOSONAR
    return (
      <div className="control-pane" style={{ cursor: "default" }}>
        <div className="notification-settings-scrollbar">

          <div className="col-lg-12 notification-view-background">
            <div className="col-xs-8 col-sm-2 col-lg-8 col-md-12">
              <div className="notification-view-font-color">
                {/* Notifications for every meeting */}
                Upcoming Meeting Alerts
              </div>
            </div>
            <div
              className="col-xs-4 col-sm-2 col-lg-4"
              style={{
                float: "right",
                paddingRight: 0,
                marginRight: 0,
                textAlign: "right",
              }}
            >
              {/* <TooltipComponent className="pointer-cursor" content="Add Reminder" position="BottomCenter" isSticky={false} cssClass='customtooltip'> */}
              <button
                type="button"
                style={{
                  marginTop: 5,
                  backgroundColor: "#fff",
                  paddingTop: 0,
                  paddingBottom: 0,
                  height: 32,
                  border: "solid 1px #e4d9d9",
                  textAlign: "center",
                  cursor: "pointer",
                }}
                onClick={() => {
                  // eslint-disable-next-line
                  const temp = this.state.notificationForEveryMeeting;
                  // eslint-disable-next-line
                  let count = this.state.notificationForEveryMeeting.length;

                  if (count < defNotificationForEveryMeetingLimit) {
                    if (temp.length === 0) {
                      count += 1;
                    } else {
                      count = temp[count - 1].Id + 1;
                    }
                    const data = {
                      Id: count,
                      Minutes: "0",
                      Channel: "0",
                      isExist: "0",
                    };
                    temp.push(data);
                    this.setState({
                      notificationForEveryMeeting: temp,
                    });
                  } else {
                    document.getElementById(
                      "notificationForEveryMeeting"
                    ).innerHTML = `You can set maximum ${defNotificationForEveryMeetingLimit} notification for every meeting.`;
                  }
                }}
              >
                <span>
                  <FontAwesomeIcon
                    icon={faPlusCircle}
                    style={{ fontSize: "12px" }}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;<span>Add Reminder</span>
              </button>
              {/* </TooltipComponent> */}
            </div>
            <div className="col-lg-12 col-md-12 pb-sm" style={{ marginLeft: 0, paddingLeft: 0 }}>
              {/* <div
                className="col-xs-12 col-sm-2 col-lg-12 "
               
              > */}
              {
                // eslint-disable-next-line
                this.state.notificationForEveryMeeting.length > 0 &&
                // eslint-disable-next-line
                this.state.notificationForEveryMeeting.map((item, index) => {
                  return this.notifictionForEveryMeetingTemplate(
                    item,
                    index
                  );
                }
                )
              }
              {/* </div> */}

              <span className="notification-text">{dencrypt(localStorage.getItem("telegramNotificationAccess")) === 0 ? ("Set System reminders for all meetings during the day") : ("Set System / Telegram* reminders for all meetings during the day")}</span>

              <div id="notificationForEveryMeeting" className="error-div" />
            </div>
          </div>

          <div className="col-lg-12 notification-view-background">
            <div className="col-xs-8 col-sm-2 col-lg-8 col-md-12">
              <div className="notification-view-font-color">
                Schedule Notifications
              </div>
            </div>
            <div
              className="col-xs-4 col-sm-2 col-lg-4"
              style={{
                float: "right",
                paddingRight: 0,
                marginRight: 0,
                textAlign: "right",
              }}
            >
              {/* <TooltipComponent className="pointer-cursor" content="Add Reminder" position="BottomCenter" isSticky={false} cssClass='customtooltip'> */}
              <button
                type="button"
                style={{
                  marginTop: 5,
                  backgroundColor: "#fff",
                  paddingTop: 0,
                  paddingBottom: 0,
                  height: 32,
                  border: "solid 1px #e4d9d9",
                  textAlign: "center",
                  cursor: "pointer",
                }}
                onClick={async () => {
                  // eslint-disable-next-line
                  const temp = this.state.notificationForScheduledMeeting;
                  // eslint-disable-next-line
                  let count = this.state.notificationForScheduledMeeting.length;
                  if (count < defNotificationForSheduledMeetingLimit) {
                    if (temp.length === 0) {
                      count += 1;
                    } else {
                      count = temp[count - 1].Id + 1;
                    }
                    const data = {
                      Id: count,
                      EveryDayWeek: "0",
                      Day: "0",
                      Every: "1",
                      EveryWeekDay: "0",
                      TimeZone: "55",
                      Time: "09:00",
                      Channel: "0",
                      isExist: "0",
                    };
                    temp.push(data);
                    
                    await this.setState({
                      notificationForScheduledMeeting: temp,
                    });
                  } else {
                    document.getElementById(
                      "notificationForScheduledMeeting"
                    ).innerHTML = `You can set maximum ${defNotificationForSheduledMeetingLimit} notification for scheduled meeting.`;
                  }
                }}
              >
                <span>
                  <FontAwesomeIcon
                    icon={faPlusCircle}
                    style={{ fontSize: "12px" }}
                  />
                </span>
                &nbsp;&nbsp;&nbsp;<span>Add Reminder</span>
              </button>
              {/* </TooltipComponent> */}
            </div>
            <div className="col-lg-12 col-md-12 pb-sm">
              <div
                className="col-xs-12 col-sm-2 col-lg-12 "
                style={{ marginLeft: 0, paddingLeft: 0 }}
              >
                {
                  // eslint-disable-next-line
                  this.state.notificationForScheduledMeeting.length > 0 &&
                  // eslint-disable-next-line
                  this.state.notificationForScheduledMeeting.map(
                    (item, index) => {
                      return this.notificationForScheduledMeetingTemplate(
                        item,
                        index
                      );
                    }
                  )
                }
              </div>
              <span className='notification-text'>{dencrypt(localStorage.getItem("telegramNotificationAccess")) === 0 ? ("Set Email notifications to view the schedule for the next 1-3 days") : ("Set Email / Telegram* notifications to view the schedule for the next 1-3 days")}</span>
              <div id="notificationForScheduledMeeting" className="error-div" />
            </div>
          </div>

          <div className="col-lg-12 notification-view-background pb-xs">
            <div className="col-xs-6 col-sm-2 col-lg-6 col-md-12">
              <div className="notification-view-font-color">
                Email Notifications
              </div>
            </div>
            <div className="col-lg-12 col-md-12">
              <div
                className="col-xs-4 col-sm-2 col-lg-4 col-md-12"
                style={{ marginLeft: 0, paddingLeft: 0 }}
              >
                <div className="notification-view-font-color">
                  <SwitchComponent
                    id="asOnSheduled"
                    ref={(scope) => {
                      this.asOnSheduled = scope;
                    }}
                    // eslint-disable-next-line
                    checked={this.state.asOnSheduled}
                    change={this.handleOnSheduled}
                  />{" "}
                  &nbsp;&nbsp;&nbsp;
                  <span className="notification-text" title="Send me an email notification when any new meeting is added to my calendar">New Meeting</span>
                  <span style={{ padding: "0px 0px 0px 8px", position: "absolute" }}>
                    <Tooltips titleMessage={"Send me an email notification when any new meeting is added to my calendar"} />
                  </span>
                </div>
              </div>
              <div
                className="col-xs-4 col-sm-2 col-lg-4 col-md-12"
                style={{ marginLeft: 0, paddingLeft: 0 }}
              >
                <div className="notification-view-font-color">
                  <SwitchComponent
                    id="asOnCancelled"
                    ref={(scope) => {
                      this.asOnCancelled = scope;
                    }}
                    // eslint-disable-next-line
                    checked={this.state.asOnCancelled}
                    change={this.handleOsOnCancelled}
                  />{" "}
                  &nbsp;&nbsp;&nbsp;
                  <span className="notification-text" title="Send me an email notification if any scheduled meeting in my calendar is cancelled">Meeting Cancellation</span>
                  <span style={{ padding: "0px 0px 0px 8px", position: "absolute" }}>
                    <Tooltips titleMessage={"Send me an email notification if any scheduled meeting in my calendar is cancelled"} />
                  </span>
                </div>
              </div>
              <div
                className="col-xs-4 col-sm-2 col-lg-4 col-md-12"
                style={{ marginLeft: 0, paddingLeft: 0 }}
              >
                <div className="notification-view-font-color">
                  <SwitchComponent
                    id="asChangeHappen"
                    ref={(scope) => {
                      this.asChangeHappen = scope;
                    }}
                    // eslint-disable-next-line
                    checked={this.state.asChangeHappen}
                    change={this.handleOnChangeHappen}
                  />{" "}
                  &nbsp;&nbsp;&nbsp;
                  <span className="notification-text" title="Send me an email notification when there is any change in a scheduled meeting in my calendar">Meeting Change</span>
                  <span style={{ padding: "0px 0px 0px 8px", position: "absolute" }}>
                    <Tooltips titleMessage={"Send me an email notification when there is any change in a scheduled meeting in my calendar"} />
                  </span>
                </div>
              </div>
              <div id="notificationForEvent" className="error-div" />
            </div>
          </div>
        </div>

        <div
          className="col-xs-12 col-sm-2 col-lg-12 pt-xs pb-xs"
          style={{ marginLeft: 0, paddingLeft: 0 }}
        >
          <div style={{ float: "right", margin: "0px -15px 5px 0px" }}>
            <table>
              <tbody>
                <tr style={{ padding: 10 }}>
                  <td>
                    <Button
                      onClick={() => this.resetNotificationSettings()}
                      color="secondary"
                      variant="contained"
                    >
                      {/* &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Reset&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; */}
                      Reset Default
                    </Button>
                  </td>
                  <td>
                    <Button
                      style={{ marginLeft: 10 }}
                      onClick={() => this.saveNotificationSettings()}
                      color="primary"
                      variant="contained"
                    >
                      Save Settings
                    </Button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  }
}
NotificationSettings.propTypes = {
  authUser: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  notificationSettingsStatus: PropTypes.string,
};
NotificationSettings.defaultProps = {
  authUser: "",
  notificationSettingsStatus: "",
};
const mapStateToProps = ({ auth, usersettingreducer }) => {
  const { authUser } = auth;
  const { notificationSettingsStatus } = usersettingreducer;

  return { authUser, notificationSettingsStatus };
};
export default connect(mapStateToProps, {
  addNotificationSettings,
  addUpdateNotificationSettings,
})(NotificationSettings);
