export const UNUTILIZED_LEAVES = "Unutilized Leaves (In days)";
export const CARRY_FORWARD = "Carried forward";
export const TO_BE_CARRY_FORWARD = "To be carry forward";
export const LAPSE = "Lapse";
export const YEAR = "Year";
export const VIEW = "View";
export const ON_LEAVE = "On Leave";
export const SPECIAL_ATTENDANCE_HISTORY = "Special Attendance History";
export const LEAVE_HISTORY = "Leave History";
export const DOWNLOAD_ALL = "Download All"
export const DOWNLOAD = "Download"
export const LEAVE_RULES = "Leave Rules";
export const RESET_FILTERS = "Reset Filters";
export const VIEW_TO_DISPLAY = "Click on view to display data";
export const MY_LEAVE_PLAN = "My Leave Plan";
export const TEAM_MEMBER_LEAVE_PLAN = "Team Member's Leave Plan";