/**
 * Author: Prashant Waphare.
 * Description: Absconding - To drag/drop or file selection screen
 * Added Date : 02-06-2020
 */
import React, { Component } from "react";
import PropTypes from "prop-types";
import { getNotify } from "../../app/other/commonfunction";
import {
  WARNING,
  // SUCCESS
} from "../../assets/constants/constants";
import "../../../src/components/separation/resignationcomponent/resignationcomponent.css"
import {
  urlConfig,
  // defFileType,
  corsallow
} from "../../config/config";

class KpiUploadFile extends Component {
  constructor(props) {
    super(props);
    const { allowmultiple } = this.props;
    this.state = {
      hightlight: false,
      allowmultiple,
      filePath: '',
      UploadedImage: "images/baseline-cloud_upload-24px.svg",
      fileNames: []
    };
    this.fileInputRef = React.createRef();

    this.openFileDialog = this.openFileDialog.bind(this);
    this.onFilesAdded = this.onFilesAdded.bind(this);
    this.onDragOver = this.onDragOver.bind(this);
    this.onDragLeave = this.onDragLeave.bind(this);
    this.onDrop = this.onDrop.bind(this);
  }

  onDragOver(event) {
    event.preventDefault();
    const { disabled } = this.props;
    if (disabled) return;
    this.setState({ hightlight: true });
  }

  onDragLeave() {
    this.setState({ hightlight: false });
  }

  onDrop(event) {
    event.preventDefault();
    const { disabled } = this.props;
    if (disabled) return;
    const { files } = event.dataTransfer;
    if (this.props.onFilesAdded) {
      const array = this.fileListToArray(files);
      this.props.onFilesAdded(array);
    }
    this.setState({ hightlight: false });
  }

  /**
   * @author Prashant Waphare
   * @description fired this event once the file is added
   */
  onFilesAdded(evt) {
    document.getElementById("uploadErr").innerHTML = '';
    const { disabled, type } = this.props;
    if (disabled) return;
    if (evt.target.files[0] !== undefined) {
      const currentDate = Date.parse(new Date()) / 1000;
      const fileObj = evt.target.files[0];
      const fileNm = currentDate.toString() + '_' + fileObj.name;
      this.setState({ UploadedImage: URL.createObjectURL(evt.target.files[0]), filePath: evt.target.files[0].name }, () => {
        let fileList = this.props.fileList;
        fileList.push(fileNm);
        this.props.selectedFiles(fileList);
      });

      const req = new XMLHttpRequest();
      const formData = new FormData();
      formData.append("usertype", "D");
      formData.append("type", type);
      formData.append("file", fileObj, fileNm);
      req.open("POST", `${corsallow}${urlConfig.tmlcApiUrl}document/upl`);
      req.setRequestHeader("Authorization", localStorage.getItem("jwtToken"));
      req.send(formData);
      if (req.statusText === "Internal Server Error") {
        getNotify(WARNING, "Please select minimum file size(less than 5 MB)");
        return;
      }
      // else {
      //   getNotify(SUCCESS, "Attachment Uploaded Successfully");
      //   return;
      // }
    }
  }

  /**
   * @author Suresh R. Sargam
   * @description Add files to list of array
   */
  fileListToArray(list) {
    const { folderPath } = this.props;
    const array = [];
    const items = list;
    for (let i = 0; i < items.length; i += 1) {
      items.item(i).folderPath = folderPath;
      array.push(items.item(i));
    }
    return array;
  }

  /**
   * @author Suresh R. Sargam
   * @description to open file dialog
   */
  openFileDialog() {
    const { disabled } = this.props;
    if (disabled) return;
    if (this.props.fileList.length >= 3) {
      document.getElementById("uploadErr").innerHTML = "You can add maximum 3 document ";
      return;
    }
    this.fileInputRef.current.click();
  }
  removeFile = (indexToRemove) => {
    let mFileList = this.props.fileList;
    mFileList = [...mFileList.filter((_, index) => index !== indexToRemove)];
    this.props.selectedFiles(mFileList)
  }
  render() {
    const { hightlight, allowmultiple, UploadedImage } = this.state;
    const { disabled } = this.props;
    return (
      <>
        <div
          className={`absconding-upload-document ${hightlight ? "Highlight" : ""}`}
          onDragOver={this.onDragOver}
          onDragLeave={this.onDragLeave}
          onDrop={this.onDrop}
          onClick={this.openFileDialog}
          onKeyDown={this.openFileDialog}
          style={{ cursor: disabled ? "default" : "pointer" }}
          role="button"
          tabIndex={0}
        >
          {allowmultiple ? (
            <input
              ref={this.fileInputRef}
              className="FileInput"
              style={{ display: "none" }}
              type="file"
              multiple
              accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"
              onChange={this.onFilesAdded}
            />
          )
            : (
              <input
                ref={this.fileInputRef}
                className="FileInput"
                style={{ display: "none" }}
                type="file"
                accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf"
                onChange={this.onFilesAdded}
              />
            )
          }
          {
            UploadedImage === "images/baseline-cloud_upload-24px.svg" &&
            <img alt="upload" className="Icon" src={UploadedImage} />
          }
          <div>You can upload maximum 3 files here.</div>
        </div>
        <div>
          <ul id="tags" style={{ marginLeft: 15 }}>
            {
              this.props.fileList.length > 0 &&
              this.props.fileList.map((name, index) => {
                return (
                  <li key={index} className="tag">
                    <span className='tag-title' style={{ marginTop: 0 }}>{name}</span>
                    <span className='tag-close-icon'
                      style={{ height: 18, width: 18 }}
                      onClick={() => this.removeFile(index)}
                    >
                      x
                            </span>
                  </li>
                )
              })
            }
          </ul>
        </div>
      </>
    );
  }
}

KpiUploadFile.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onFilesAdded: PropTypes.func,
  allowmultiple: PropTypes.bool,
  folderPath: PropTypes.string.isRequired,
};
KpiUploadFile.defaultProps = {
  allowmultiple: false,
  disabled: false,
  folderPath: ""
};

export default KpiUploadFile;
