import { React, useState, useEffect } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
import AdminServices from "../../services/adminservices";
import { encrypt, getBencharkDisplayDateOnly } from '../../app/other/commonfunction';
// ("@fortawesome/free-solid-svg-icons"); /*NOSONAR*/

const MasterModules = (props) => {
  const [masterModules, setMasterModules] = useState([]);
  const history = useHistory();
  useEffect(() => {
    getData()
  }, [])

  const getData = () => {
    const { authUser } = props;
    try {//NOSONAR
      const result = Promise.resolve(
        AdminServices.getMasterAdminModuleData({ userId: authUser, type: "Submain", id: props.adminModuleId, masterId: 0 })
      );

      result.then((resultData) => {

        if (resultData !== null) {
          setMasterModules(resultData.data)
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  const openAdminModule = (masterId) => {
    try {

      const params = queryString.parse(props.location.search);
      if (typeof params.id !== 'undefined' && params.id !== null) {
        history.push(`${props.location.pathname}?id=${params.id}&appId=${params.appId}&adminId=${params.adminId}&masterId=${encrypt(masterId)}`)

      }
    } catch (error) {
      console.log("? ~ file: adminmodule.jsx ~ line 41 ~ openAdminModule ~ error", error)
    }
  }

  const isSixteenEighty = window.innerHeight < 900;
  const isOneTwentyFiveScale = window.innerHeight < 715;
  const isOneTwentyFiveInSixteenEighty = window.innerHeight < 691;
  const isThirteenHundred = window.innerHeight < 618;
  const isOneTwentyFiveInSixteenHundred = window.innerHeight < 571;
  const isOneTwentyFiveInThirteenHundred = window.innerHeight < 465;
  const isTenEightyHundred = window.innerHeight > 870 && window.innerHeight < 880;
  const isTenEightyOneTwentyFive = window.innerHeight > 665 && window.innerHeight < 675
  const is1280x720 = window.innerHeight === 563 || window.innerHeight === 595 || window.innerHeight === 551 || window.innerHeight===551 || window.innerHeight===593 || window.innerHeight===559 || window.innerHeight===606;


  return (
    <div style={{
      overflowY: 'scroll',
      height: isOneTwentyFiveInThirteenHundred ? "76vh" : isThirteenHundred ? "81vh"
        : isOneTwentyFiveInSixteenEighty ? "83vh" : isOneTwentyFiveScale ? "85vh" : "87vh",
      backgroundColor: "#F2F2F2"
    }}>

      {masterModules?.map((item) => (

        <div key={`masterModule${item.masterId}`}
          className=" col-lg-3 col-md-3 col-sm-3 col-xs-3 "
          style={{ paddingTop: "20px", paddingBottom: 20 }}
          onClick={() => { openAdminModule(item.masterId) }}
        >
          <div className={isTenEightyHundred ? "admin-module-bolna e-card profile okr-admin-container-style" : "admin-module-bolna e-card profile"} style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexDirection: 'column',
          }} >
            <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 " style={{ marginBottom: "12px", paddingTop: '1.5rem' }}>
              <div className={isOneTwentyFiveInSixteenHundred ? "col-lg-12 col-md-12 col-sm-12 col-xs-12" : "col-lg-10 col-md-10 col-sm-10 col-xs-10"} style={{ fontSize: "115%", font: is1280x720?"normal normal bold 14px/13px Arial":"normal normal bold 16px/14px Arial", paddingLeft: "1vw"  }}>
                {item.masterName}
              </div>
            </div>
            <div
              style={{
                margin: "0px 20px 0px 1vw",
                fontWeight: "normal",
                fontSize: isOneTwentyFiveInSixteenHundred ? "100%" : isTenEightyOneTwentyFive ? "95%" : "110%",
                color: "#757575",
                lineHeight: "25px"
              }}

              title={item.descr}>
              {item.descr.length < 136 ? item.descr.substring(0, 136) : item.descr.substring(0, item.descr.length) + "..."}
            </div>
            <div className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-sm"
              style={{ paddingBottom: "0.5rem", }}>
              <div
                className={`col-7 col-lg-7 col-md-7 col-sm-7 col-xs-7 ${item.lastModifiedBy ? '' : 'invisible'}`}
                style={{ paddingLeft: "1vw", paddingRight: isOneTwentyFiveInThirteenHundred ? 0 : "", padding: isTenEightyOneTwentyFive ? '0 0 0 0.5rem' : '' }}
              >
                <div style={{ fontWeight: "normal", color: "#757575", fontSize: isTenEightyOneTwentyFive ? "80%" : isOneTwentyFiveInSixteenHundred ? '90%' : '100%' }}>Last Updated By</div>
                <div className={(props.adminModuleId === 2 || props.adminModuleId === 6) ? "regular-container-head-res" : "okr-admin-container-head-res"} style={{
                  fontSize: (isOneTwentyFiveInThirteenHundred || isOneTwentyFiveInSixteenHundred) ? "90%" :
                    (isTenEightyOneTwentyFive) ? "90%" :
                      isSixteenEighty ? "100%" : "110%"
                }}>{item.lastModifiedBy}</div>
              </div>
              {item.lastUpdateOn && <div className="col-5 col-lg-5 col-md-5 col-sm-5 col-xs-5" style={{ float: "right", paddingLeft: isOneTwentyFiveInSixteenHundred ? 0 : "", paddingRight: isOneTwentyFiveInSixteenHundred ? 0 : "", padding: isTenEightyOneTwentyFive ? '0 0.5rem 0 0' : '' }}>
                <div style={{ fontWeight: "normal", color: "#757575", fontSize: (isTenEightyOneTwentyFive) ? "80%" : isOneTwentyFiveInSixteenHundred ? '90%' : '100%' }}>Last Updated</div>
                {item.lastUpdateOn !== null && <div className={(props.adminModuleId !== 2 && props.adminModuleId !== 6) ? "regular-container-head-res" : "okr-admin-container-head-res"} style={{ fontSize: (isOneTwentyFiveInThirteenHundred || isOneTwentyFiveInSixteenHundred) ? "90%" : isTenEightyOneTwentyFive ? "85%" : isTenEightyHundred ? '95%' : isSixteenEighty ? "100%" : "110%" }}>{getBencharkDisplayDateOnly(new Date(item.lastUpdateOn))}</div>}
              </div>}
            </div>
          </div>
        </div>
      ))}

    </div >
  );
};

const mapStateToProps = ({ auth, router }) => {
  const { authUser } = auth;
  const { location } = router;
  return { authUser, location };
};

export default connect(mapStateToProps, {
})(MasterModules);
