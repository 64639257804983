import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ComboBoxComponent } from "@syncfusion/ej2-react-dropdowns";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import TextField from "@material-ui/core/TextField";
import { connect } from 'react-redux'
import tibService from "../../services/tibservices";
import { addUpdateTibData } from '../../redux/actions/tibacton';
import { getDate } from "../../app/other/commonfunction";

class UIFILTERS extends Component {
    constructor(props) {
        super(props)
        this.state = {
            changeData: false,
            monthRange: false,
            fyMonthList: [],
            selectedFyMonthsIds: [],
        };
        this.selectedStartDate = new Date();
        this.selectedEndDate = new Date();
        this.onChangeFinancialYear = this.onChangeFinancialYear.bind(this);
        this.getSelectedMonths = this.getSelectedMonths.bind(this);
        this.removeSelectedMonths = this.removeSelectedMonths.bind(this);
        this.onChangeYearOption = this.onChangeYearOption.bind(this);
        this.minStartDate = new Date('2020-04-01');
        this.minEndDate = new Date('2020-04-01');
        this.maxStartDate = new Date();
        this.maxEndDate = new Date();
        this.yearOptions = [{ Id: 1, Name: 'Financial Year' }, { Id: 2, Name: 'Select Range' }];
    }
    componentDidMount() {
        setTimeout(() => {
            const { fyId, tibAvgSelectedYearOption } = this.props;
            var e = {};
            e.value = fyId;
            this.onChangeFinancialYear(e);
            var d = {};
            d.value = tibAvgSelectedYearOption;
            this.onChangeYearOption(d);
            this.onDateChange(new Date(), 'start');
        })
    }
    TextFieldComponent = (props) => {
        return <TextField {...props} disabled />;
    }
    onFilterDataChange(event, type) {
        if (type === "financial") {
            if (event.itemData !== null) {
                this.props.addUpdateTibData({ fyId: event.itemData.Id })
            }
        }
    }
    onChangeYearOption(e) {
        if (e.value !== null) {
            if (e.value === 2) {
                this.setState({ monthRange: true }, () => this.props.addUpdateTibData({ tibAvgSelectedYearOption: e.value }));
            } else {
                this.setState({ monthRange: false }, () => this.props.addUpdateTibData({ tibAvgSelectedYearOption: e.value }));
            }
        }
    }
    async onChangeFinancialYear(e) {
        if (e.value !== null) {
            var monthList = await tibService.getFyMonthList(e.value);
            var selectedMonths = [];
            monthList.forEach(each => {
                selectedMonths.push(each.Id);
            });
            this.setState({ fyMonthList: monthList, selectedFyMonthsIds: selectedMonths }, () => this.props.addUpdateTibData({ tibAvgSelectedFy: e.value, tibAvgSelectedFyMonths: selectedMonths }));
        }
    }
    getSelectedMonths(e) {
        const { selectedFyMonthsIds } = this.state;
        if (!selectedFyMonthsIds.includes(e.itemData.Id)) selectedFyMonthsIds.push(e.itemData.Id);
        this.setState({ selectedFyMonthsIds: selectedFyMonthsIds }, () => this.props.addUpdateTibData({ tibAvgSelectedFyMonths: selectedFyMonthsIds }));
    }
    removeSelectedMonths(e) {
        const { selectedFyMonthsIds } = this.state;
        let selectedFyMonthsIdList = selectedFyMonthsIds.filter(eachId => eachId !== e.itemData.Id);
        this.setState({ selectedFyMonthsIds: selectedFyMonthsIdList }, () => this.props.addUpdateTibData({ tibAvgSelectedFyMonths: selectedFyMonthsIdList }));
    }
    async onDateChange(e, type) {
        var thisDateStr = getDate(e);
        if (type === 'start') {
            if (e <= new Date()) {
                this.selectedStartDate = e.toString();
                this.props.addUpdateTibData({ tibAvgSelectedFromMonth: thisDateStr, tibAvgSelectedToMonth: thisDateStr });
            }
        }
        else if (type === 'end') {
            this.selectedEndDate = e.toString();
            this.props.addUpdateTibData({ tibAvgSelectedToMonth: thisDateStr });
        }
    }
    render() {
        const { monthRange } = this.state;
        const { tibFinancialYearData, fyId, tibAvgSelectedYearOption } = this.props;
        tibFinancialYearData.sort((a, b) => { return b.Id - a.Id; });
        var selectedStartDate = new Date();
        var selectedEndDate = new Date();
        if (this.selectedStartDate !== null) selectedStartDate = new Date(this.selectedStartDate);
        if (this.selectedEndDate !== null) selectedEndDate = new Date(this.selectedEndDate);
        return (
            <div className='col-lg-4 col-sm-4 col-md-4 col-xs-4' style={{ paddingLeft: "6px" }}>
                {/* <div className='row'> */}
                <div className='col-lg-4 col-md-4 col-sm-4 col-xs-4' style={{ paddingLeft: "0px" }}>
                    <ComboBoxComponent
                        allowCustom={false}
                        floatLabelType="Auto"
                        fields={{ value: "Id", text: "Name" }}
                        dataSource={this.yearOptions}
                        change={this.onChangeYearOption}
                        name={`yearOptionError`}
                        id={`yearOption`}
                        data-msg-containerid={`yearOptionError`}
                        cssClass="e-outline"
                        placeholder="Select Year *"
                        value={tibAvgSelectedYearOption}
                        showClearButton={false}
                    />
                </div>
                {/* </div> */}
                {monthRange === null || monthRange === false ?
                    // <div className='row' style={{marginTop:-67, marginLeft:100}}>
                    <div className="col-xs-8 col-sm-8 col-lg-8 col-md-8">
                        {
                            tibFinancialYearData.length > 0 &&
                            <ComboBoxComponent
                                style={{ width: '100%' }}
                                allowCustom={false}
                                floatLabelType="Auto"
                                fields={{ value: "Id", text: "Name" }}
                                dataSource={tibFinancialYearData}
                                change={this.onChangeFinancialYear}
                                name={`fyIdrError`}
                                id={`fyId`}
                                data-msg-containerid={`fyIdrError`}
                                cssClass="e-outline"
                                placeholder="Financial Year *"
                                value={fyId}
                                showClearButton={false}
                            />
                        }
                        <div id="fyIdrError" className="e-input-group e-float-icon-left error-div" />
                    </div>
                    // </div>
                    :
                    <div className='col-xs-8 col-sm-8 col-lg-8 col-md-8' style={{ padding: "0px" }}>
                        <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6" >
                            <MuiPickersUtilsProvider utils={DateFnsUtils} >
                                <KeyboardDatePicker
                                    openTo="month"
                                    views={["year", "month"]}
                                    id="startMonth"
                                    name="startMonth"
                                    format="MMM-yyyy"
                                    KeyboardButtonProps={{ 'aria-label': 'change date', }}
                                    value={selectedStartDate}
                                    onChange={(e) => this.onDateChange(e, 'start')}
                                    inputVariant="outlined"
                                    variant="inline"
                                    autoOk
                                    maxDate={this.maxStartDate}
                                    minDate={this.minStartDate}
                                    label="Start Month"
                                    TextFieldComponent={this.TextFieldComponent}
                                />
                            </MuiPickersUtilsProvider>
                            <div id="fyIdrError" className="e-input-group e-float-icon-left error-div" />
                        </div>
                        <div className="col-xs-6 col-sm-6 col-lg-6 col-md-6" >
                            <MuiPickersUtilsProvider utils={DateFnsUtils} >
                                <KeyboardDatePicker
                                    openTo="month"
                                    views={["year", "month"]}
                                    id="endMonth"
                                    name="endMonth"
                                    format="MMM-yyyy"
                                    KeyboardButtonProps={{ 'aria-label': 'change date', }}
                                    value={selectedEndDate}
                                    onChange={(e) => this.onDateChange(e, 'end')}
                                    inputVariant="outlined"
                                    variant="inline"
                                    autoOk
                                    maxDate={this.maxEndDate}
                                    minDate={this.minEndDate}
                                    label="End Month"
                                    TextFieldComponent={this.TextFieldComponent}
                                />
                            </MuiPickersUtilsProvider>
                            <div id="tib-select-month" className="error-div pl-sm" />
                        </div>
                    </div>
                }
            </div>
        );
    }
}
UIFILTERS.propTypes = {
    authUser: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    fyId: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    tibFinancialYearData: PropTypes.arrayOf(Object),
};
UIFILTERS.defaultProps = {
    authUser: "",
    fyId: 23,
    tibFinancialYearData: [],
};
const mapStateToProps = ({ tibReducer, auth }) => {
    const { authUser } = auth;
    // eslint-disable-next-line
    const {
        tibFinancialYearData, fyId, tibAvgSelectedYearOption, tibAvgSelectedFromMonth, tibAvgSelectedToMonth
    } = tibReducer;
    return {
        authUser,
        tibFinancialYearData, fyId, tibAvgSelectedYearOption, tibAvgSelectedFromMonth, tibAvgSelectedToMonth
    };
};

export default connect(mapStateToProps, { addUpdateTibData })(UIFILTERS);
