import React, { useState, useEffect,useRef } from "react";
import { connect } from "react-redux";
import { Button } from "@material-ui/core";
import { saveCompOffData, updateLnAData } from '../../../../../redux/actions/lna/lnaaction'
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { cracterLimit } from '../../../../../app/other/commonfunction';
import { FormValidator } from "@syncfusion/ej2-inputs";
import { BREModuleActions } from '../../../../../assets/constants/constants'
import BREServices from "../../../../../services/breservice";
import ConfirmationPopup from "../../../../../utilities/confirmationpopup";
export const CompOffDetails = (props) => {
  const { leaveSymbol, reason, noOfDays, leaveId, fromDate, toDate, rejectComments, leaveClusterUid, applicationStatus } = props.leaveData
  const [showConfirm, setShowConform] = useState(false)
  const [dataToSave, setDataToDave] = useState({})
  let [leaveCancelReason, setLeaveCancelReason] = useState('')
  const [height, setHeight] = useState(window.innerHeight - 195);

  const updateDimensions = () => {
    const updateheight = window.innerHeight - 307
    setHeight(updateheight)
  }
  useEffect(() => {
    window.addEventListener("resize", updateDimensions()); // NOSONAR
  }, [])
  const textareaObj = useRef(null);
  const getBREDataForLeave = async (pSouceLeaveId) => {
    const objData = { "int_leave_id": pSouceLeaveId }
    const BREDataForLeave = await BREServices.getBREData(props.authUser, //nosonar
      BREModuleActions.compOffApplication, objData);
    return BREDataForLeave;
  }

  const onCancelSwipe = () => {


    const options = {

      rules: {
        leaveCancelReasonErr: {
          required: [true, 'Please type appropriate reason'],
        },
      },
    };

    setTimeout(async () => {
      const addFormObject = new FormValidator('#leaveCancelForum', options);
      if (addFormObject.validate()) {
        const getBREData = await getBREDataForLeave(leaveClusterUid)
        const data = {
          userId: props.authUser,
          type: 'STATUS',
          leaveApplicationId: leaveId,
          isActive: '0',
          leaveCancelReason,
          supervisorApprovalRequired: getBREData.enum_supervisor_approval,
          hrApprovalRequired: getBREData.enum_HR_approval,
          autoApprovalRequired: getBREData.enum_auto_approval,
          noOfHrsToAutoApprove: getBREData.int_no_hrs_to_approval
        }
        setDataToDave(data)
        setShowConform(true)
      }
    }, 100);

  }

  const handleClose = () => {
    setShowConform(false)
  }
  const handleYes = () => {
    // onSubmitApplyLeave()
    props.saveCompOffData(dataToSave)
    props.updateLnAData({
      leaveApplyLoadName: 'leave-tabs',
    })
    setShowConform(false)
  }
  const leaveReasonHandler = (e, type) => {
    let options = {};
    if (type === "leaveCancel") {
      setLeaveCancelReason(e.value);
      document.getElementById('leaveCancelReasonErr').innerHTML = '';
      options = {
        rules: {
          leaveCancelReasonErr: {
            required: [true, 'Please type appropriate reason'],
          },
        },
      }
    }
    setTimeout(() => {
      const addFormObject = new FormValidator("#leaveCancelForum", options);
      addFormObject.validate();
    }, 100);
  }
  const LeaveReasonBox = `.LeaveCancelBox .e-float-input.e-control-wrapper.e-outline{
  height: 125px !Important;
}`;
const onInput = (e) => {
  try {
      if (textareaObj !== null && typeof textareaObj !== 'undefined') {
          textareaObj.current.respectiveElement.style.height = "auto";
          textareaObj.current.respectiveElement.style.minHeight = "100px";
          textareaObj.current.respectiveElement.style.height = `${textareaObj.current.respectiveElement.scrollHeight}px`;
          textareaObj.current.respectiveElement.style.maxHeight = "100px";
          cracterLimit(e, 'compOffCancelReason', '200')
      }
      if (textareaObj === null && typeof textareaObj === 'undefined'){
          cracterLimit({ value: "" }, 'compOffCancelReason', '200')
      }
  } catch (error) {
  }
};

  return (
    <>

      <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12'>
        <div style={{
          backgroundColor: '#F2F2F2',
          height: "3rem",
          display: "flex",
          borderStartEndRadius: "0.4em",
          borderStartStartRadius: "0.4em",
          alignItems: "center",
          paddingLeft: "1rem",
          fontWeight: 600,
          width: '100%',
        }}
        >
          <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12"
            style={{ color: 'black', fontStyle: 'Circular Std Book', fontSize: '1rem', fontFamily: "Arial" }}
          >
            Comp. Off Details
          </div>
        </div>
      </div>


      <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12' style={{ overflowY: "auto", height: height}} >


        <div className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 " >
          <div
            className="col-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 pt-5"
            style={{ paddingBottom: "20px", paddingLeft: "16px" }}
          >
            <div className="regular-container-head">Attendence Type</div>
            <div className="regular-container-head-res">{leaveSymbol}</div>
          </div>
          <div
            className="col-6 col-lg-6 col-md-6 col-sm-6 col-xs-6"
            style={{ paddingBottom: "20px", paddingLeft: 0 }}
          >
            <div className="regular-container-head">Status</div>
            <div className="regular-container-head-res">{applicationStatus}</div>
          </div>
        </div>

        <div
          className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 "
        >
          <div
            className="col-6 col-lg-6 col-md-6 col-sm-6 col-xs-6"
            style={{ paddingBottom: "20px", paddingLeft: "16px" }}
          >
            <div className="regular-container-head">From Date</div>
            <div className="regular-container-head-res">{fromDate && fromDate.split('T')[0]}</div>
          </div>
          <div
            className="col-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 pt-5"
            style={{ paddingBottom: "20px", paddingLeft: 0 }}
          >
            <div className="regular-container-head">To Date</div>
            <div className="regular-container-head-res">{toDate && toDate.split('T')[0]}</div>
          </div>
          <div className="regular-container-head-res">Applied For {noOfDays} days</div>
        </div>


        <div
          className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12"
          style={{ paddingBottom: "20px", paddingLeft: "16px" }}
        >
          <div className="regular-container-head">Reason</div>
          <div className="regular-container-head-res" style={{ maxHeight: 100, overflowY: 'auto', whiteSpace: 'normal' }}>{reason}</div>
        </div>
        {
          rejectComments && <div
            className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12"
            style={{ paddingBottom: "20px", paddingLeft: "16px" }}
          >
            <div className="regular-container-head"> Rejected/Cancelled Reason</div>
            <div className="regular-container-head-res" style={{ maxHeight: 50, overflowY: 'auto', whiteSpace: 'normal' }}>{rejectComments}</div>
          </div>
        }

        {
          props.leaveApplyLoadName === 'leave-cancel' &&
          <div id = 'leaveCancelForum' className="col-lg-12 col-md-12 col-sm-12 col-xs-12 p-md LeaveCancelBox" style={{ height: '100px' }}>
            <div>
              <style>{LeaveReasonBox}</style>
              <TextBoxComponent
                id="leaveCancelReason"
                data-msg-containerid="leaveCancelReasonErr"
                name="leaveCancelReasonErr"
                cssClass="e-outline"
                ref={textareaObj}
                floatLabelType="Auto"
                input={onInput}
                placeholder="Leave cancel reason *"
                onChange={(e) => leaveReasonHandler(e, 'leaveCancel')}
                htmlAttributes={{ maxlength: 200 }}
                multiline={true}
                height={'75px'}
              />
              <div
                id="leaveCancelReasonErr"
                className="e-input-group e-float-icon-left error-div"
              />
              <div id='compOffCancelReason' style={{ float: 'right', fontSize: 13, margin: '-15px 15px 8px 0px' }} className="error-div" />
            </div>
          </div>
        }
      </div>

      <div className='topBorder' >
        <div
          className='col-sm-12 col-md-12 col-lg-12 pb-lg pt-md'
          style={{
            display: "flex",
            justifyContent: "right",
            alignItems: "center",
            textAlign: 'right',
            height: '60px',
            width: '100%',
            position: 'absolute',
            bottom: '0',
            paddingRight: "10px",
          }}>
          <div>
            <Button
              className="leave-cancel-apply-button"
              style={{ width: "114px" ,marginRight:"10px"}}
              variant="contained"
              onClick={() => props.showViewMode('leave-tabs', '', 'Close')}
            >
              Close
            </Button>

            {props.leaveApplyLoadName === 'leave-cancel' ?
              <Button
                style={{ width: "184px", borderRadius: 0 }}
                variant="contained"
                className="remove-button-shadow"
                color="primary"
                onClick={() => onCancelSwipe()}
              >
                Cancel Appication
              </Button> : ''}

          </div>
          <ConfirmationPopup
            showButton={{ type: "1" }}
            open={showConfirm}
            button1function={() => handleYes()}
            button2function={() => handleClose()}
            headerMessage={'Are you sure, you  want to cancel comp. off ?'}
            button1Text={"Yes"}
            button2Text={"No"}
            handleConfirmClose={handleClose}
          />  
        </div>
      </div>


    </>

  );
};

CompOffDetails.propTypes = {
  //   second: PropTypes.third
};

const mapStateToProps = ({ auth, lnareducer }) => {
  const { authUser } = auth
  const { leaveData, leaveApplyLoadName, leaveType, } = lnareducer
  return {
    authUser, leaveData, leaveApplyLoadName, leaveType,
  }
};

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  { saveCompOffData, updateLnAData }
)(CompOffDetails);
