import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { ComboBoxComponent } from "@syncfusion/ej2-react-dropdowns";
import {
    DialogContent,
    DialogActions
} from "../../customdialog/customdialog";
import { FormValidator } from "@syncfusion/ej2-inputs";
import { Button } from "@material-ui/core";
import {
    ERROR,
    pleaseEnterFY,
} from "../../../assets/constants/constants";
import { updateOKRLockTMRatingMonth } from '../../../redux/actions/okr/okractions';
import { getNotify } from "../../../app/other/commonfunction";
import OKRLockRatingMonth from "./okrlockratingmonth";
import OKRDoesNotApplyRatingMonth from "./okrdoesnotapplyratingmonth";

const cssForPopup = `
    .MuiDialog-paperWidthSm {
        max-width: 600px !important;
        max-height: 32rem !important;
    }
`
function LockOKRRatingPopup(props) {
    const defaultMonthState = {
        "1": null,
        "2": null,
        "3": null,
        "4": null,
        "5": null,
        "6": null,
        "7": null,
        "8": null,
        "9": null,
        "10": null,
        "11": null,
        "12": null,
    }
    const [financialYearId, setFinancialYearId] = useState(props.currentFinancialYearId);
    const [lockMonths, setLockMonths] = useState(defaultMonthState);
    const [doesNotApplyMonths, setDoesNotApplyMonths] = useState(defaultMonthState);
    const [showMonths, setShowMonths] = useState({
        showLockMonths: true,
        showDoesNotApplyMonths: false
    });
    const [selectedFyYears, setSelectedFyYears] = useState({
        year: "",
        nextYear: ""
    });

    useEffect(() => {
        function getFiscalYear() {
            props.finacialYearData.forEach((fy) => {
                if (fy.Id === financialYearId) {
                    const years = fy.Name.split(" - ");
                    setSelectedFyYears({
                        year: years[0],
                        nextYear: years[1]
                    })
                }
            });
         }
         getFiscalYear();
    }, [financialYearId]);

    const editFYInformation = (e, type) => {
        let options = {};
        switch (type) {
            case "LockOKRFinancialYear":
                if (e.itemData) {
                    setFinancialYearId(e.itemData.Id)
                }
                options = {
                    rules: {
                        financialYearErr: {
                            required: [true, pleaseEnterFY],
                        },
                    },
                };
                break;
            default:
        }

        setTimeout(() => {
            const addFormObject = new FormValidator("#editOKRLockTMRatingMonth", options);
            addFormObject.validate();
        }, 100);
    }

    const setLockMonthsHandler = (type, month, e) => {
        if (type === "defaultState") {
            setLockMonths(month);
        }
        if (type === "updateState") {   
            setLockMonths(prevState => ({ ...prevState, [month]: month }))
        }
        if (type === "getCheckBoxes") { 
            if (e.target) {
                setLockMonths(prevState => ({ ...prevState, [month]: e.target.checked ? month : null }))
            }
        }
    }

    const setDoesNotApplyMonthsHandler = (type, month, e) => {
        if (type === "defaultState") {
            setDoesNotApplyMonths(month);
        }
        if (type === "updateState") {
            setDoesNotApplyMonths(prevState => ({ ...prevState, [month]: month }))
        }
        if (type === "getCheckBoxes") {
            if (e.target) {
                setDoesNotApplyMonths(prevState => ({ ...prevState, [month]: e.target.checked ? month : null }))
            }
        }
    }

    const onSave = (type) => {
        
        if (type === "LockMonths") {
            const lockMonthsArr = [];

            Object.values(lockMonths).forEach(month => {
                if (month) {
                    lockMonthsArr.push(month);
                }
            })

            const options = {
                rules: {
                    financialYearErr: {
                        required: [true, pleaseEnterFY],
                    },
                }
            }

            setTimeout(() => {
                const addFormObject = new FormValidator("#editOKRLockTMRatingMonth", options);

                if (addFormObject.validate() === false) {
                    getNotify(ERROR, pleaseEnterFY);
                }
                if (addFormObject.validate()) {
                    const data = {
                        type: "UpdateLockMonths",
                        empId: props.empId,
                        fy: financialYearId,
                        lockMonths: lockMonthsArr.join(),
                        doesNotApplyMonths: "",
                        defaultCalculationMonths:'',
                        userId:props.authUser,
                    }

                    props.updateOKRLockTMRatingMonth(data);
                    props.closeOKRLockPopup();
                }
            }, 100);
        }

        if (type === "DoesNotApplyMonths") {
            const doesNotApplyMonthsArr = [];

            Object.values(doesNotApplyMonths).forEach(month => {
                if (month) {
                    doesNotApplyMonthsArr.push(month);
                }
            })

            const options = {
                rules: {
                    financialYearErr: {
                        required: [true, pleaseEnterFY],
                    },
                }
            }

            setTimeout(() => {
                const addFormObject = new FormValidator("#editOKRLockTMRatingMonth", options);

                if (addFormObject.validate() === false) {
                    getNotify(ERROR, pleaseEnterFY);
                }
                if (addFormObject.validate()) {
                    const data = {
                        type: "UpdateDoesNotApplyMonths",
                        userId:props.authUser,
                        fy: financialYearId,
                        lockMonths: "",
                        doesNotApplyMonths: doesNotApplyMonthsArr.join(),
                        defaultCalculationMonths:'',
                        // userId:props.authUser,
                    }

                    props.updateOKRLockTMRatingMonth(data);
                    props.closeOKRLockPopup();
                }
            }, 100);
        }
    }

    const openTab = (type) => {
        if (type === "LockMonths") {
            setShowMonths(prevState => ({
                ...prevState,
                showLockMonths: true,
                showDoesNotApplyMonths: false
            }))
        }

        if (type === "DoesNotApplyMonths") {
            setShowMonths(prevState => ({
                ...prevState,
                showLockMonths: false,
                showDoesNotApplyMonths: true
            }))
        }
    }

    return (
        <>
            <style>{cssForPopup}</style>
            <DialogContent dividers className='heightOther'>
                <div
                    id="editOKRLockTMRatingMonth"
                >
                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 mt-sm" style={{padding: 0}}>
                        <div>
                            <ComboBoxComponent
                                id="LockOKRFinancialYear"
                                dataSource={props.finacialYearData}
                                fields={{ text: "Name", value: "Id" }}
                                value={financialYearId}
                                data-msg-containerid="financialYearErr"
                                name="financialYearErr"
                                floatLabelType="Auto"
                                cssClass="e-outline"
                                placeholder="Financial Year *"
                                select={(e) => editFYInformation(e, "LockOKRFinancialYear")}
                            />
                            <div
                                id="financialYearErr"
                                className="e-input-group e-float-icon-left error-div"
                            />
                        </div>
                    </div>
                    <div
                        className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mr-3"
                        style={{
                            borderLeft: "1px solid #eee",
                            borderRight: "1px solid #eee",
                            borderBottom: "1px solid #eee",
                            borderRadius: "5px"
                        }}
                    >
                        <div className="tab">
                            <div className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                <button
                                    id="tabOrganisationDetails"
                                    type="submit"
                                    className={`editTablinks col-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 rating-tab-font ${showMonths.showLockMonths ? "active" : ""}`}
                                    onClick={() => openTab("LockMonths")}
                                >
                                    Lock
                                </button>
                                <button
                                    id="tabPersonalDetails"
                                    type="submit"
                                    className={`editTablinks col-6 col-lg-6 col-md-6 col-sm-6 col-xs-6 rating-tab-font ${showMonths.showDoesNotApplyMonths ? "active" : ""}`}
                                    onClick={() => openTab("DoesNotApplyMonths")}
                                >
                                    Does Not Apply
                                </button>
                            </div>
                        </div>
                        <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12 mt-sm">
                            {showMonths.showLockMonths &&
                                <OKRLockRatingMonth
                                    setLockMonthsHandler={setLockMonthsHandler}
                                    financialYearId={financialYearId}
                                    defaultMonthState={defaultMonthState}
                                    empId={props.empId}
                                    selectedFyYears={selectedFyYears}
                                />
                            }
                            {showMonths.showDoesNotApplyMonths &&
                                <OKRDoesNotApplyRatingMonth
                                    setDoesNotApplyMonthsHandler={setDoesNotApplyMonthsHandler}
                                    financialYearId={financialYearId}
                                    defaultMonthState={defaultMonthState}
                                    empId={props.empId}
                                    selectedFyYears={selectedFyYears}
                                />
                            }
                        </div>
                    </div>
                </div>
            </DialogContent>

            <DialogActions style={{ paddingRight: "1.1em", borderTop: "1px solid #c3c3c3" }}>
                {showMonths.showLockMonths && <Button color="primary" variant="contained" onClick={() => onSave("LockMonths")}> Save </Button>}
                {showMonths.showDoesNotApplyMonths && <Button color="primary" variant="contained" onClick={() => onSave("DoesNotApplyMonths")}> Save </Button>}
                <Button color="secondary" variant="contained" onClick={props.closeOKRLockPopup}> Close </Button>
            </DialogActions>
        </>
    )
}

const mapStateToProps = ({ meetingmaster, auth }) => {
    const {
        finacialYearData,
        currentFinancialYearId,
    } = meetingmaster;
    const {authUser}=auth;
    return {
        finacialYearData,
        currentFinancialYearId,
        authUser
    }
};

export default connect(mapStateToProps, { updateOKRLockTMRatingMonth })(LockOKRRatingPopup);
