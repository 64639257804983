import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  DetailRow,
  Inject,
  Page,
  InfiniteScroll,
  Filter,
  Sort,
  getRowHeight,
} from "@syncfusion/ej2-react-grids";
import {
  getGridColumn,
  TEAM_MEMBER_RANKING_TEAM_MEMBER_NAME
} from "../../app/other/gridcolumns";
import {addUpdateOkrDashboardRedux} from '../../redux/actions/okrdashboardaction/okrdashboardaction';
const XYAXISCSS = `.e-grid .e-rowcell{
  border-style: solid;
  border-width: 1px 0 0 0;
  display: table-cell;
  font-size: 14px !important;
  font-weight: 500 !important;
  color: #000000 !important;
  line-height: 1.5rem !important;
  // padding: .5rem 2rem !important;
  vertical-align: middle;
  /* white-space: pre-wrap !important; */
  width: auto;
  text-transform: capitalize;
  padding-left: 11px !important;
}
.e-grid .e-gridcontent tr td {
  font-weight: 500;
}

.e-headertext{
      font-size: 15px !important;
      padding-left: 10px !important;
}

.e-grid .e-gridheader .e-icons:not(.e-icon-hide):not(.e-check):not(.e-stop) {
  color: #212121;
  padding-top: 17px;
}
#team-ranking-grid .e-gridcontent .e-content {
  overflow-y: hidden !important;
}
#team-ranking-grid .e-gridheader.e-lib.e-droppable {
  padding-right: 0 !important;
}
@media screen and (max-width: 1366px) and (max-height: 768px){
  .e-headertext {
    font-size: 11px !important;
    padding-left: 10px !important;
    margin-left:1px
    
}
.e-grid .e-rowcell{
  border-style: solid;
  border-width: 1px 0 0 0;
  display: table-cell;
  font-size: 11px !important;
  font-weight: 500 !important;
  color: #000000 !important;
  line-height: 1.5rem !important;
  // padding: .5rem 2rem !important;
  vertical-align: middle;
  /* white-space: pre-wrap !important; */
  // width: auto;
  text-transform: capitalize;
  padding-left: 21px !important;
}
#team-ranking-gridstring_excelDlg{
  max-height: 300px !important;
  z-index: 100000 !important;
  min-height: 300px !important;
  width: 255px !important;
  top: 37px !important;
}

@media screen and (max-width: 1366px) and (max-height: 768px), screen and (max-width: 1440px) and (max-height: 900px) {
  .e-grid .e-headercell{
    border-style: solid;
    border-width: 1px 0 0 0;
    display: table-cell;
    font-size: 12px !important;
    font-weight: 500 !important;
    color: #000000 !important;
    vertical-align: middle;
    /* white-space: pre-wrap !important; */
    width: auto;
    // text-transform: capitalize;
    background-color: #fbfbfb !important;
  }
  
  .e-grid .e-rowcell{
    border-style: solid;
    border-width: 1px 0 0 0;
    display: table-cell;
    font-size: 12px !important;
    font-weight: 500 !important;
    color: #000000 !important;
    line-height: 1.5rem !important;
    padding: .5rem 2rem !important;
    vertical-align: middle;
    /* white-space: pre-wrap !important; */
    width: auto;
    // text-transform: capitalize;
  }
}
`

function XYAxisGridView(props) {
  const [gridHeight, setGridHeight] = useState(null);
  const [rowHeight, setRowHeight] = useState(null);
  const FilterType = {
    type: "CheckBox",
  };
  const gridInstance = useRef();

  useEffect(() => {
    if (window.innerHeight < 650) {
      setGridHeight((window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight) / (2.04))

      setRowHeight(20);
    } else {
      setGridHeight((window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight) / (1.95))
      
      setRowHeight(44.9);
    }
  }, []);

  const dataBound = () => {
    if (gridInstance.current) {
      gridInstance.current.hideScroll();
    }
  };

  const employeeNameTemplate = (prop) => {
    return (
      <span className="TeamRankingEmployee pointer" onClick={ ()=>props.addUpdateOkrDashboardRedux({navToSelfRatingFlag:'updated', empId:prop.empId, empName:prop.EmployeeName, boxClassification:prop.boxClassification, navToTeamMemberFlag:'updated'})}>{prop.EmployeeName}</span>
    );
  }
  const {xyAxixGridRankingData}=props;
  const getGridHeight = () =>{
    if(window.innerHeight<670){
      return  255.5
    }else{
      if (window.innerHeight <930) {
        return gridHeight-40
      } else if(window.innerHeight <900){
        return gridHeight-42
      }else if(window.innerHeight === 937){
        return gridHeight-43
      }else{
        return gridHeight-61
      }   
    }
  }
  const getHeight = () =>{
    const {showMonths} = props
    if(window.innerHeight<670){
      return  showMonths ? gridHeight-152 : gridHeight-68
    }else{
      if (window.innerHeight <930) {
        return gridHeight-40
      } else if(window.innerHeight <900){
        return gridHeight-42
      }else{
        return gridHeight-42
      }   
    }
  }

  return (
    xyAxixGridRankingData.length >0 ?
    <div className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pr-md pl-md">
      <style>{XYAXISCSS}</style>
      <GridComponent
        id="team-ranking-grid"
        rowHeight={rowHeight}
        allowTextWrap={true}
        allowFiltering
        filterSettings={FilterType}
        height={getGridHeight()}
        // width={'100px'}
        ref={gridInstance}
        dataBound={dataBound}
        allowPaging
        allowSorting={true}
        pageSettings={{
          // pageSizes: NoOfRowsPerPage,
          pageCount: 5,
          pageSize: (window.innerHeight ||
            document.documentElement.clientHeight ||
            document.body.clientHeight) > 767
            ? 9//12
            :5,
        }}
        
        dataSource={xyAxixGridRankingData}
        enableHover={false}
      >
        <ColumnsDirective>
          {getGridColumn("teamMembarRanking").map((item) => {
            if (item.colName === TEAM_MEMBER_RANKING_TEAM_MEMBER_NAME) {
                return (
                  <ColumnDirective
                    key={item.field}
                    template={employeeNameTemplate}
                    textAlign={item.align}
                    field={item.field}
                    headerText={item.colName}
                    // filter={check}
                    // allowFiltering={true}
                    // allowSorting={true}
                    width={window.innerHeight < 670 ? 250 :item.width}
                  />
                );
              } else {
                  return (
                    <ColumnDirective
                      key={item.field}
                      textAlign={item.align}
                      field={item.field}
                      headerText={item.colName}
                      // filter={check}
                      // allowSorting={true}
                      // allowFiltering={true}
                      width={window.innerHeight < 670 ? 220 :item.width}
                    />
                  );
                }
            }
          )}
        </ColumnsDirective>
        <Inject services={[Page,DetailRow, Filter, Sort, InfiniteScroll ]} />
      </GridComponent>
    </div>
    :
    <div className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pr-md pl-md" >
    <div style={{height:getHeight(),textAlign:'Center',fontWeight:'bolder',marginTop:'100px',fontSize:'larger',color:'Grey'}}>No Data</div>
  </div>
  )
}

const mapStateToProps = ({ auth, okrratingreducer, okrreducer, meetingmaster, meetingcategory, okrdashboardreducer }) => {
  const { authUser } = auth;
  const { empId, fyId, monthOfRating, supervisorUsers, selfUser, TPUsers, refreshRatingDataFlag, reviewUsers,
    ratingStatusData, ratingCategoryStatus, ratingTabs } = okrratingreducer;
  const { categoryName } = meetingcategory;
  const { OkrMasters } = okrreducer;
  const { finacialYearData } = meetingmaster;
  const {xyAxixGridRankingData, }=  okrdashboardreducer;
  return {
    authUser, empId, fyId, monthOfRating, OkrMasters, finacialYearData, supervisorUsers, selfUser, TPUsers, categoryName, refreshRatingDataFlag, reviewUsers,
    ratingStatusData, ratingCategoryStatus, ratingTabs, xyAxixGridRankingData
  }
};
export default connect(mapStateToProps, { addUpdateOkrDashboardRedux })(
  XYAxisGridView
);
