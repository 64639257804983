export const UPDATE_USER_PRODUCTIVE_ALLOCATION = 'update_user_productive_allocation';
export const SET_COST_SHARING_REDUCER = 'set_cost_sharing_reducer';
export const ADD_EDIT_COST_SHARING_NEWREQUEST = 'add_edit_cost_sharing_newrequest';
export const ACCEPT_REJECT_REQUEST = 'accept_reject_request';
export const ADD_UPDATE_OKR_OBJETVE_KPI = 'add_update_okr_objective_kpi';
export const ADD_EDIT_OKR_OBJETVE_KPI = 'add_edit_okr_objective_kpi';
export const APPROVE_REJECT_OKR_OBJETVE_KPI = 'approve_reject_okr_objective_kpi';
export const SET_CENTRAL_OKR_KPI = 'set_central_okr_kpi';
export const SET_USER_OKR_OBJECTIVE_KPI = 'set_user_okr_objective_kpi';
export const SET_OKR_USER_KPI = 'set_okr_user_kpi';
export const CREATE_UPDATE_NEW_OBJ = 'create_update_new_obj';
export const CREATE_UPDATE_NEW_KPI = 'create_update_new_kpi';
export const SUBMIT_APPROVAL_ACTION = 'submit_approval_action';
export const FILL_OBJECTIVE_DETAILS = 'fill_objective_details';
export const FILL_KPI_DETAILS = 'fill_kpi_details';
export const UPDATE_LP_VARIABLE_LINK = 'update_lp_variable_link';
export const UPDATE_OKR_LOCK_STATUS = 'update_okr_lock_status';
export const UPDATE_OKR_ORDER_IDS = 'update_okr_order_ids';

export const UPDATE_OKR_REDUX = 'update_okr_redux';

export const SAVE_OKR_AXIS_MASTER= "save_okr_axis_master";
export const SAVE_OKR_BLOCKER_MASTER= "save_okr_blocker_master";
export const SAVE_OKR_RATING_SYSTEM = "save_okr_rating_system";
export const SAVE_OKR_RATING_MASTER = "save_okr_rating_master";
export const SAVE_AXIS_CUT_OFF = "save_axis_cut_off";
export const SAVE_SUB_POTENTIAL_CALCULATION = "save_sub_potential_calculation";
export const SAVE_OKR_PERFORMANCE_POTENTIAL_MASTER= "save_okr_performace_potential_master";
export const SAVE_OKR_POTENTIAL_CALCULATION_MASTER= "save_okr_performace_calculation_master";
export const ADMIN_EDIT_ADD_CAL = "admin_edit_add_cal";
export const SAVE_OKR_FREQUENCY_MASTER = "save_frequency_master";
export const UPDATE_OKR_MASTER_ITEM_STATUS= "update_okr_master_item_status"
export const TEAM_MEMBER_SELECTION = "team_member_selection";
export const COMMON_MASTER_SCREEN_LOADING = "common_master_screen_loading"
