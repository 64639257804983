/* Author : Prashant Waphare
 * Date : 01-05-2020
 * Description: This file use for Absconding HR component.
 */
import * as React from "react";
import {
    GridComponent,
    ColumnsDirective,
    ColumnDirective,
    Filter,
    Inject,
    VirtualScroll,
    Sort,
    Page,
} from "@syncfusion/ej2-react-grids";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import "../../../app/containers/tmlc/tmlc.css";
import "../exitclearancecomponents/exitclearancecomponents.css"
import "../../../../src/components/separation/resignationcomponent/resignationcomponent.css"

import { connect } from "react-redux";
import { Button, Dialog } from "@material-ui/core";
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { Redirect } from "react-router-dom";
import {
    DialogContent,
    DialogActions,
    // DialogTitle,
    DialogTitleTMLC
} from "../../customdialog/customdialog";

import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import TextField from "@material-ui/core/TextField";
import ErrorBoundary from "../../error/errorboundary";
import NotificationContainer from "../../notification/notifycontainer";
import AbscondingUploadFile from "./abscondinguploadfile";
import {
    encrypt,
    getDate,
    encodeString
} from "../../../app/other/commonfunction";
import { setMeetingCategory } from '../../../redux/actions/meetingcategoryaction';
import { EMPLOYEE_PROFILE } from "../../../redux/constant/meetingcategoryconstant";

import EmployeeNameTemplate from "../employeeNameTemplate";
import {
    getGridColumn,
    EMPLOYEE_NAME,
    ACTION_HR,
    JOINING_DATE,
    REPORTED_ON,
    HR_STAGE_HR,
    LAST_COMMUNICATED_DATE,
    DATE_OF_ABSCONDING,
    COMMENT,
    REMARK,
    EXPECTED_JOINING_DATE
} from "../../../app/other/gridcolumns";
import { ComboBoxComponent } from "@syncfusion/ej2-react-dropdowns";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import CommunicatedPreviewGrid from "./communicatedpreviewgrid";
import separetionServices from "../../../services/entryexit/separationServices";
import { submitAbscondingData, submiteExitClearanceData } from "../../../redux/actions/entryexit/separationaction"
import { AllDateFormat } from "../allcommoncomponents";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import {DateFilterformat} from '../../../../src/config/config';

import { faArrowAltToTop, faEye, faPlayCircle, faLongArrowAltRight, faArrowAltRight, faReply, faHandshake, faCommentAltLines } from '@fortawesome/pro-light-svg-icons'
import ConfirmationPopup from "../../../utilities/confirmationpopup";
library.add(faArrowAltToTop, faEye, faPlayCircle, faLongArrowAltRight, faArrowAltRight, faReply, faHandshake);

class AbscondingHrGrid extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            redirect: null,
            actionState: true,
            openCommunication: false,
            openCommunicationPreview: false,
            communicationHrList: [],
            communicationDate: Date.parse(new Date()) / 1000,
            selectCommunication: "",
            documentPath: '',
            imgType: '',
            currentDate: '',
            abscondingEmpId: "",
            filePath: "",
            openCommentBox: false,
            viewCommentList: false,
            commentValue: "",
            actionType: "",
            commentList: [],
            changedData: false,
            button1Caption: '',
            button2Caption: '',
            title: '',
            message: '',
            open: false,
            initiateEmployeeId: '',
            remark: '',
            abscondingReportedBackDate: new Date(),
            abscondingExpectedDate: new Date()
        };
        this.height =
            (window.innerHeight ||
                document.documentElement.clientHeight ||
                document.body.clientHeight) - 188;
        this.width =
            (window.innerWidth ||
                document.documentElement.clientWidth ||
                document.body.clientWidth) - 100;
        this.dReady = false;
        this.dtTime = false;
        this.isDataBound = false;
        this.isDataChanged = true;
        this.dropSlectedIndex = null;
        this.check = {
            type: "CheckBox",
        };
        this.select = {
            persistSelection: true,
            type: "Multiple",
            checkboxOnly: true,
        };
        this.Filter = {
            type: "Menu",
        };
        this.pageOptions = {
            pageSize:
                (window.innerHeight ||
                    document.documentElement.clientHeight ||
                    document.body.clientHeight) > 700
                    ? 13
                    : 7,
        };
        this.name = {
            type: "CheckBox",
        };
        this.employeeNameTemplate = this.employeeNameTemplate.bind(this);
        this.navigateToAddBasicInformation = this.navigateToAddBasicInformation.bind(
            this
        );
        this.setRemark = this.setRemark.bind(this)
    }

    componentDidMount() {
        setTimeout(() => {
            const ele = document.getElementsByClassName("e-headercelldiv");
            for(let i in ele) {
                if(!isNaN(parseInt(i, 10))){
                  let index = parseInt(i, 10);
                  if (
                    ele[index].innerText === JOINING_DATE ||
                    ele[index].innerText === REPORTED_ON ||
                    ele[index].innerText === LAST_COMMUNICATED_DATE ||
                    ele[index].innerText === DATE_OF_ABSCONDING ||
                    ele[index].innerText === EXPECTED_JOINING_DATE ||
                    ele[index].innerText === ACTION_HR
                ) {
                    ele[index].classList.add("grid-center-header");
                } else {
                    ele[index].classList.add("grid-left-header");
                }
                }
              }
        }, 1000);
    }

    getCommunicationList = (e) => {
        const { authUser } = this.props;
        try {
            const getAbscondingListDetail = Promise.resolve(
                separetionServices.getAbscondingListDetail(authUser, "commstage", e)
            );
            getAbscondingListDetail.then((result) => {
                if (result !== null) {
                    this.setState({ communicationHrList: result });
                } else {
                    this.data = "error";
                }
            });
        } catch (error) {
            // eslint-disable-next-line
            console.log(error);
        }
    };
    onQueryCellInfoPreJoining = (args) => {
        const { cell, column } = args;
        const { field } = column;
        if (field === "JoiningDate" || field === "reportedOn" || field === "dateOfAbsconding" || field === "lastCommunicatedDate" || field === 'action') {
            cell.setAttribute(
                "style",
                "font-size:14px !important; text-align: center !important"
            );
        } else {
            cell.setAttribute(
                "style",
                "font-size:14px !important; text-align: left !important; padding-left:18px !important"
            );
        }

    };

    onDataBound() {
        clearTimeout(this.clrIntervalFun);
        clearInterval(this.intervalFun);
        this.dtTime = true;
    }

    commentTemplate = (props) => {
        const { abscUid, commentsUpdStatus } = props;
        const clr = commentsUpdStatus === 'Y' ? 'green' : 'gray';
        return (
            <div className="row" style={{ paddingLeft: '25px', marginTop: '-20px' }}>
                <div className="col-4">
                    <TooltipComponent className="pointer-cursor" content="Add Comments" position="BottomCenter" isSticky={false} cssClass='customtooltip'>
                        <FontAwesomeIcon icon={faCommentAltLines} style={{ fontSize: "20px" }} onClick={(e) => {
                            this.addCommentOpen(abscUid, "add")
                        }} />
                    </TooltipComponent>
                </div>
                <div className="col-4">
                    <TooltipComponent className="pointer-cursor" content="View Comments" position="BottomCenter" isSticky={false} cssClass='customtooltip'>
                        <FontAwesomeIcon icon={faEye} style={{ fontSize: "20px", color: clr }} onClick={(e) => this.viewCommentList(abscUid)} />
                    </TooltipComponent>
                </div>
            </div>
        );
    };

    hrStageTemplate = (props) => {
        const { abscUid, communicationUplStatus } = props;
        const clr = communicationUplStatus === 'Y' ? 'green' : 'gray';
        return (
            <div className="row" style={{ paddingLeft: '30px', marginTop: '-20px' }}>
                <div className="col-3">
                    <TooltipComponent
                        content="Upload Communication Stage"
                        position="BottomCenter"
                        isSticky={false}
                        cssClass='customtooltip'
                    >
                        <FontAwesomeIcon icon={faArrowAltToTop} className="pointer-cursor"
                            style={{ fontSize: "20px", marginLeft: "10px" }}
                            onClick={(e) => this.openCommunication(abscUid)}
                        />
                    </TooltipComponent>
                </div>
                <div className="col-3">
                    <TooltipComponent
                        content="View Communication Stage"
                        position="BottomCenter"
                        isSticky={false}
                        cssClass='customtooltip'
                    >
                        <FontAwesomeIcon icon={faEye} className="pointer-cursor"
                            style={{ fontSize: "20px", marginLeft: "10px", color: clr }}
                            onClick={(e) => this.openCommunicationPreview(abscUid)}
                        />
                    </TooltipComponent>
                </div>
            </div>
        );
    };
    openCommunication = (e) => {
        this.setState({ abscondingEmpId: e });
        this.setState({ openCommunication: true });
        setTimeout(() => {
            this.textareaObjRemark.addAttributes({ rows: 4 });
        }, 100)
    }
    openCommunicationPreview = (e) => {
        this.setState({ abscondingEmpId: e });
        this.getCommunicationList(e);
        this.setState({ openCommunicationPreview: true });
    }
    closeCommunicationDialog = () => {
        this.setState({ openCommunication: false, selectCommunication: "", communicationDate: Date.parse(new Date()) / 1000, fileName: "", remark: "",imgType:"" });
    }
    closeCommunicationPreviewDialog = () => {
        this.setState({ openCommunicationPreview: false });
    }
    actionToggle = () => {
        const { actionState } = this.state;
        this.setState({ actionState: !actionState });
    };
    documentDetail = (currentDate, imgType, filePath) => {
        this.setState({ currentDate: currentDate, imgType: imgType, filePath: filePath })
    }
    uploadDocument = () => {
        const { authUser } = this.props;
        const { remark } = this.state;
        document.getElementById("hrStageErr").innerHTML = "";
        document.getElementById("uploadErr").innerHTML = "";
        if (this.state.selectCommunication === "" || this.state.selectCommunication === null) {
            document.getElementById("hrStageErr").innerHTML = "Please select communication";
            return;
        } 
        if (this.state.imgType === undefined || this.state.imgType === "") {
            document.getElementById("uploadErr").innerHTML = "Please upload required document";
            return;
        }
        if (remark === '') {
            document.getElementById("remarkErr").innerHTML = "Please entar remark";
            return;
        }
        let fileName = this.state.imgType;
        const data = {
            userId: parseInt(authUser),
            abscId: this.state.abscondingEmpId,
            type: "commData",
            communicateData: `${this.state.selectCommunication}~${authUser}~${this.state.communicationDate}~${fileName}`,
            communicationRemark: remark
        }
        this.props.submitAbscondingData(data);
        this.setState({ selectCommunication: "", communicationDate: Date.parse(new Date()) / 1000, fileName: "", openCommunication: false, remark: "",imgType:"" });
    }

    mgrRemarks = (props) => {
        const { remarks } = props;
        return (
            <div>
                <TooltipComponent
                    content={remarks.replace(/(?:\r\n|\r|\n)/g, '<br>')}
                    position="BottomCenter"
                    isSticky={true}
                    cssClass='customtooltip'
                    opensOn="Click"

                >
                    <span
                        style={{
                            fontSize: "14px",
                            display: "block",
                            width: "100px",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            cursor: "pointer"
                        }}
                    >
                        {remarks}
                    </span>
                </TooltipComponent>
            </div>
        );
    }
    action = (props) => {
        const { abscUid, employeeId, employeeStatus, acknowledgebyhr, acknowledgebymgr, clearanceInitiated } = props;

        if (clearanceInitiated === 'Y') {
            return (
                <div className="col">
                    {acknowledgebyhr === "1" &&
                        <div className="col-4">
                            <TooltipComponent content="Acknowledged For Employee Reported Back" position="BottomCenter" isSticky={false}
                                cssClass='customtooltip'>
                                <FontAwesomeIcon icon={faHandshake} style={{ fontSize: "20px", color: 'green' }} />
                            </TooltipComponent>
                        </div>
                    }
                    {acknowledgebymgr === "1" &&
                        <div className="col-4">
                            <TooltipComponent content="Employee Reported Back" position="BottomCenter" isSticky={false}
                                cssClass='customtooltip'>
                                <FontAwesomeIcon icon={faReply} style={{ fontSize: "20px", color: 'green' }} />
                            </TooltipComponent>
                        </div>
                    }
                    {acknowledgebyhr !== "1" && acknowledgebymgr !== "1" &&
                        <div className="col-4">&nbsp;</div>
                    }
                    <div className="col-4">
                        <TooltipComponent content="Clearance Initiated" position="BottomCenter" isSticky={false}
                            cssClass='customtooltip'>
                            <FontAwesomeIcon icon={faPlayCircle} style={{ fontSize: "20px", color: "green" }} />
                        </TooltipComponent>
                    </div>
                    <div className="col-4">
                        &nbsp;
                    </div>
                </div>
            )
        } else {
            return (
                <div className="col">
                    <div className="col-4">
                        {acknowledgebyhr === "1" &&

                            <TooltipComponent content="Acknowledged For Employee Reported Back" position="BottomCenter" isSticky={false}
                                cssClass='customtooltip'>
                                <FontAwesomeIcon icon={faHandshake} style={{ fontSize: "20px", color: 'green' }} />
                            </TooltipComponent>

                        }
                        {acknowledgebymgr === "1" &&

                            <TooltipComponent content="Employee Reported Back" position="BottomCenter" isSticky={false}
                                cssClass='customtooltip'>
                                <FontAwesomeIcon icon={faReply} style={{ fontSize: "20px", color: 'green' }} />
                            </TooltipComponent>

                        }
                        {
                            employeeStatus === 'Reported - HR' && acknowledgebymgr !== "1" &&

                            <TooltipComponent content="Employee Reported Back" position="BottomCenter" isSticky={false}
                                cssClass='customtooltip'>
                                <FontAwesomeIcon icon={faReply} style={{ fontSize: "20px", color: "green" }} />
                            </TooltipComponent>

                        }
                        {
                            employeeStatus === 'Reported - Manager' && acknowledgebyhr !== "1" &&
                            <TooltipComponent content="Acknowledge" position="BottomCenter" isSticky={false}
                                cssClass='customtooltip' className="pointer-cursor">
                                <FontAwesomeIcon icon={faHandshake} style={{ fontSize: "20px" }} onClick={(e) => this.acknowledge(abscUid)} />
                            </TooltipComponent>
                        }
                        {
                            employeeStatus === 'Absconded' &&
                            <TooltipComponent content="Employee Reported Back" position="BottomCenter" isSticky={false} className="pointer-cursor"
                                cssClass='customtooltip'>
                                <FontAwesomeIcon icon={faReply} style={{ fontSize: "20px" }} onClick={(e) => this.addCommentOpen(abscUid, "back")} />
                            </TooltipComponent>

                        }
                    </div>
                    <div className="col-4">
                        <TooltipComponent content="Initiate Clearance" position="BottomCenter" isSticky={false} className="pointer-cursor"
                            cssClass='customtooltip'>
                            <FontAwesomeIcon icon={faPlayCircle} style={{ fontSize: "20px" }} onClick={(e) => this.initiateClearance(abscUid, employeeId)} />
                        </TooltipComponent>
                    </div>
                    <div className="col-4">
                        <TooltipComponent content="Continue Employment" position="BottomCenter" isSticky={false} className="pointer-cursor"
                            cssClass='customtooltip'>
                            <FontAwesomeIcon icon={faArrowAltRight} style={{ fontSize: "20px" }} onClick={(e) => this.contEmployee(abscUid, employeeId)} />
                        </TooltipComponent>
                    </div>
                </div>
            )
        }

        // if (reportingHead === "N") {
        //     if (clearanceInitiated === "N" && employeeStatus === "Absconding" && acknowledgebyhr === null) {
        //         return (
        //             <div className="col">
        //                 <div className="col-4">
        //                     <TooltipComponent content="Employee Reported Back" position="BottomCenter" isSticky={false} className="pointer-cursor"
        //                         cssClass='customtooltip'>
        //                         <FontAwesomeIcon icon={faReply} style={{ fontSize: "20px" }} onClick={(e) => this.addCommentOpen(abscUid, "back")} />
        //                     </TooltipComponent>
        //                 </div>
        //                 <div className="col-4">
        //                     <TooltipComponent content="Initiate Clearance" position="BottomCenter" isSticky={false} className="pointer-cursor"
        //                         cssClass='customtooltip'>
        //                         <FontAwesomeIcon icon={faPlayCircle} style={{ fontSize: "20px" }} onClick={(e) => this.initiateClearance(abscUid, employeeId)} />
        //                     </TooltipComponent>
        //                 </div>
        //                 <div className="col-4">
        //                     <TooltipComponent content="Continue Employment" position="BottomCenter" isSticky={false} className="pointer-cursor"
        //                         cssClass='customtooltip'>
        //                         <FontAwesomeIcon icon={faArrowAltRight} style={{ fontSize: "20px" }} onClick={(e) => this.contEmployee(abscUid, employeeId)} />
        //                     </TooltipComponent>
        //                 </div>

        //             </div>
        //         )
        //     }
        //     if (clearanceInitiated === "Y" && employeeStatus === "Reported - HR" && acknowledgebyhr === null) {
        //         return (
        //             <div className="col">
        //                 <div className="col-4">
        //                     <TooltipComponent content="Employee Reported Back" position="BottomCenter" isSticky={false}
        //                         cssClass='customtooltip'>
        //                         <FontAwesomeIcon icon={faReply} style={{ fontSize: "20px", color: 'green' }} />
        //                     </TooltipComponent>
        //                 </div>
        //                 <div className="col-4">
        //                     <TooltipComponent content="Clearance Initiated" position="BottomCenter" isSticky={false}
        //                         cssClass='customtooltip'>
        //                         <FontAwesomeIcon icon={faPlayCircle} style={{ fontSize: "20px", color: 'green' }} />
        //                     </TooltipComponent>
        //                 </div>
        //                 <div className="col-4">&nbsp;
        //                 </div>
        //             </div>
        //         )
        //     }

        //     if (clearanceInitiated === "Y" && employeeStatus === "Absconding" && acknowledgebyhr === null) {
        //         return (
        //             <div className="col">
        //                 <div className="col-4">
        //                     <TooltipComponent content="Employee Reported Back" position="BottomCenter" isSticky={false} className="pointer-cursor"
        //                         cssClass='customtooltip'>
        //                         <FontAwesomeIcon icon={faReply} style={{ fontSize: "20px" }} onClick={(e) => this.addCommentOpen(abscUid, "back")} />
        //                     </TooltipComponent>
        //                 </div>
        //                 <div className="col-4">
        //                     <TooltipComponent content="Clearance Initiated" position="BottomCenter" isSticky={false}
        //                         cssClass='customtooltip'>
        //                         <FontAwesomeIcon icon={faPlayCircle} style={{ fontSize: "20px", color: 'green' }} />
        //                     </TooltipComponent>
        //                 </div>
        //             </div>
        //         )
        //     }
        // }
        // if (reportingHead === 'Y' && employeeStatus === 'Reported - Manager') {
        //     return (
        //         <div className="row">
        //             <div className="col-4">
        //                 <TooltipComponent content="Initiate Clearance" position="BottomCenter" isSticky={false} className="pointer-cursor"
        //                     cssClass='customtooltip'>
        //                     <FontAwesomeIcon style={{ fontSize: "20px" }} />
        //                 </TooltipComponent>
        //             </div>
        //             <div className="col-4">
        //                 <TooltipComponent content="Initiate Clearance" position="BottomCenter" isSticky={false} className="pointer-cursor"
        //                     cssClass='customtooltip'>
        //                     <FontAwesomeIcon icon={faPlayCircle} style={{ fontSize: "20px" }} onClick={(e) => this.initiateClearance(abscUid, employeeId)} />
        //                 </TooltipComponent>
        //             </div>
        //             <div className="col-4">
        //                 <TooltipComponent content="Continue Employment" position="BottomCenter" isSticky={false} className="pointer-cursor"
        //                     cssClass='customtooltip'>
        //                     <FontAwesomeIcon icon={faArrowAltRight} style={{ fontSize: "20px" }} onClick={(e) => this.contEmployee(abscUid, employeeId)} />
        //                 </TooltipComponent>
        //             </div>
        //         </div>
        //     );
        // } else if (reportingHead === 'N' && employeeStatus === 'Reported - Manager' && acknowledgebyhr !== '1') {
        //     return (
        //         <div lassName="row">
        //             <div className="col-4">
        //                 <TooltipComponent content="Acknowledge" position="BottomCenter" isSticky={false} className="pointer-cursor"
        //                     cssClass='customtooltip'>
        //                     <FontAwesomeIcon icon={faHandshake} style={{ fontSize: "20px" }} onClick={(e) => this.acknowledge(abscUid)} />
        //                 </TooltipComponent>
        //             </div>
        //             <div className="col-4">
        //                 <TooltipComponent content="Initiate Clearance" position="BottomCenter" isSticky={false} className="pointer-cursor"
        //                     cssClass='customtooltip'>
        //                     <FontAwesomeIcon icon={faPlayCircle} style={{ fontSize: "20px" }} onClick={(e) => this.initiateClearance(abscUid, employeeId)} />
        //                 </TooltipComponent>
        //             </div>
        //             <div className="col-4">&nbsp;
        //             </div>
        //         </div>
        //     );
        // } else if (acknowledgebyhr === '1' && reportingHead === 'N') {
        //     return (
        //         <div lassName="row">
        //             <div className="col-4">&nbsp;</div>
        //             {
        //                 clearanceInitiated === "Y" ?
        //                     <div className="col-4">
        //                         {
        //                             clearanceInitiated === "Y" ?
        //                                 <TooltipComponent content="Clearance Initiated" position="BottomCenter" isSticky={false}
        //                                     cssClass='customtooltip'>
        //                                     <FontAwesomeIcon icon={faPlayCircle} style={{ fontSize: "20px", color: 'green' }} />
        //                                 </TooltipComponent>
        //                                 :
        //                                 <TooltipComponent content="Initiate Clearance" position="BottomCenter" isSticky={false} className="pointer-cursor"
        //                                     cssClass='customtooltip'>
        //                                     <FontAwesomeIcon icon={faPlayCircle} style={{ fontSize: "20px" }} onClick={(e) => this.initiateClearance(abscUid, employeeId)} />
        //                                 </TooltipComponent>
        //                         }
        //                     </div>
        //                     :
        //                     <div className="col-4">
        //                         <TooltipComponent content="Continue Employment" position="BottomCenter" isSticky={false} className="pointer-cursor"
        //                             cssClass='customtooltip'>
        //                             <FontAwesomeIcon icon={faArrowAltRight} style={{ fontSize: "20px" }} onClick={(e) => this.contEmployee(abscUid, employeeId)} />
        //                         </TooltipComponent>
        //                     </div>
        //             }

        //             <div className="col-4">&nbsp;</div>
        //         </div>
        //     )
        // } else if (reportingHead === 'N' && clearanceInitiated === 'N') {
        //     return (
        //         <div lassName="row">
        //             <div className="col-4">
        //                 {
        //                     employeeStatus === "Reported - HR" ?
        //                         <TooltipComponent content="Employee Reported Back" position="BottomCenter" isSticky={false}
        //                             cssClass='customtooltip'>
        //                             <FontAwesomeIcon icon={faReply} style={{ fontSize: "20px", color: 'green' }} />
        //                         </TooltipComponent>
        //                         :
        //                         <TooltipComponent content="Employee Reported Back" position="BottomCenter" isSticky={false} className="pointer-cursor"
        //                             cssClass='customtooltip'>
        //                             <FontAwesomeIcon icon={faReply} style={{ fontSize: "20px" }} onClick={(e) => this.addCommentOpen(abscUid, "back")} />
        //                         </TooltipComponent>
        //                 }
        //             </div>
        //             <div className="col-4">
        //                 <TooltipComponent content="Initiate Clearance" position="BottomCenter" isSticky={false} className="pointer-cursor"
        //                     cssClass='customtooltip'>
        //                     <FontAwesomeIcon icon={faPlayCircle} style={{ fontSize: "20px" }} onClick={(e) => this.initiateClearance(abscUid, employeeId)} />
        //                 </TooltipComponent>
        //             </div>
        //             <div className="col-4">
        //                 <TooltipComponent content="Continue Employment" position="BottomCenter" isSticky={false} className="pointer-cursor"
        //                     cssClass='customtooltip'>
        //                     <FontAwesomeIcon icon={faArrowAltRight} style={{ fontSize: "20px" }} onClick={(e) => this.contEmployee(abscUid, employeeId)} />
        //                 </TooltipComponent>
        //             </div>
        //             <div className="col-4">&nbsp;</div>
        //         </div>
        //     )
        // } else if (clearanceInitiated === 'Y' && employeeStatus === 'Reported - HR') {
        //     return (
        //         <div lassName="row">
        //             <div className="col-4">
        //                 <TooltipComponent content="Employee Reported Back" position="BottomCenter" isSticky={false}
        //                     cssClass='customtooltip'>
        //                     <FontAwesomeIcon icon={faReply} style={{ fontSize: "20px", color: 'green' }} />
        //                 </TooltipComponent>
        //             </div>
        //             <div className="col-4">
        //                 <TooltipComponent content="Clearance Initiate" position="BottomCenter" isSticky={false}
        //                     cssClass='customtooltip'>
        //                     <FontAwesomeIcon icon={faPlayCircle} style={{ fontSize: "20px", color: 'green' }} />
        //                 </TooltipComponent>
        //             </div>
        //             <div className="col-4">
        //                 <TooltipComponent content="Continued Employment" position="BottomCenter" isSticky={false}
        //                     cssClass='customtooltip'>
        //                     <FontAwesomeIcon icon={faArrowAltRight} style={{ fontSize: "20px", color: 'green' }} />
        //                 </TooltipComponent>
        //             </div>
        //         </div>
        //     )
        // }
    }
    closeCommentList = (abscId) => {
        this.setState({ viewCommentList: false, abscondingEmpId: "", commentList: [] });
    }
    viewCommentList = (abscId) => {
        const { authUser } = this.props;
        try {
            const getAbscondingListDetail = Promise.resolve(
                separetionServices.getAbscondingListDetail(authUser, "comments", abscId)
            );
            getAbscondingListDetail.then((result) => {
                if (result !== null) {
                    this.setState({ commentList: result });
                } else {
                    this.data = "error";
                }
            });
            this.setState({ viewCommentList: true, abscondingEmpId: abscId });
        } catch (e) {
            // eslint-disable-next-line
            console.log(e);
        }
    }
    addCommentOpen = (abscId, type) => {
        this.setState({ openCommentBox: true, abscondingEmpId: abscId, actionType: type });
        setTimeout(() => {
            this.textareaObj.addAttributes({ rows: 4 });
        }, 100)
    }
    addCommentClose = () => {
        this.setState({ openCommentBox: false, commentValue: "", abscondingEmpId: "", abscondingReportedBackDate: new Date(), abscondingExpectedDate: new Date() });
    }
    getCommentValue = (e) => {
        document.getElementById("addCommentErr").innerHTML = "";
        if (e.value === "") {
            this.setState({ commentValue: "" });
            document.getElementById("addCommentErr").innerHTML = "Please enter comment";
            return;/**NOSONAR */
        } else {
            document.getElementById("addCommentErr").innerHTML = "";
            this.setState({ commentValue: e.value });
        }
    }

    submitComment = () => {
        const { authUser } = this.props;
        const { actionType, abscondingReportedBackDate, abscondingExpectedDate } = this.state;
        document.getElementById("addCommentErr").innerHTML = "";
        if (this.state.commentValue === "" || this.state.commentValue === null) {
            document.getElementById("addCommentErr").innerHTML = "Please enter comment";
            return;
        }
        if (actionType === "add") {
            const data = {
                userId: parseInt(authUser),
                abscId: this.state.abscondingEmpId,
                type: "comments",
                communicateData: `${this.state.commentValue}`,
                reportedbackDate: null,
                expectedJoiningDate: null
            }
            this.props.submitAbscondingData(data);
        } else if (actionType === "back") {
            const data = {
                userId: parseInt(authUser),
                abscId: this.state.abscondingEmpId,
                type: "back",
                communicateData: `${this.state.commentValue}`,
                reportedbackDate: getDate(new Date(abscondingReportedBackDate)),
                expectedJoiningDate: getDate(new Date(abscondingExpectedDate))
            }
            this.props.submitAbscondingData(data);
            this.submitAction();
        }
        this.addCommentClose();
    }
    submitAction = () => {
        const { authUser } = this.props;
        const { abscondingEmpId, commentValue } = this.state;
        const data = {
            userId: parseInt(authUser),
            abscId: abscondingEmpId,
            type: "repByHr",
            communicateData: `${commentValue}`,
        }
        this.props.submitAbscondingData(data);
    }
    limitCaracter = (e) => {
        let word = "";
        let addressCount = 0;
        word = e.value;
        addressCount = word.length;
        document.getElementById("commentLimitErr").innerHTML = addressCount + "/500";
    }
    limitCaracterRemark = (e) => {
        let word = "";
        let addressCount = 0;
        word = e.value;
        addressCount = word.length;
        document.getElementById("remarkLimit").innerHTML = addressCount + "/500";
    }

    acknowledge = (abscUid) => {
        const { authUser } = this.props;
        const data = {
            userId: parseInt(authUser),
            abscId: abscUid,
            type: "acknwByHr",
            communicateData: '1'
        }
        this.props.submitAbscondingData(data);
    }
    handleConfirmNo = () => {
        const { open } = this.state;
        this.setState({
            open: !open,
            button1Caption: '',
            button2Caption: '',
            title: '',
            message: '',
        });
    }
    handleConfirmClose = () => {
        const { open } = this.state;
        this.setState({
            open: !open,
            button1Caption: '',
            button2Caption: '',
            title: '',
            message: '',
            initiateEmployeeId: ''
        });
    }
    handleSubmitYes = () => {
        const { authUser } = this.props;
        const { open, initiateEmployeeId, title } = this.state;

        if (title === "Continue Employment") {
            const data = {
                type: "contiemp",
                userId: parseInt(authUser),
                empId: initiateEmployeeId,
                clsInfo: 'abscond'
            }
            this.props.submiteExitClearanceData(data);

        } else {
            const data = {
                type: "clsinit",
                userId: parseInt(authUser),
                empId: initiateEmployeeId
            }
            this.props.submiteExitClearanceData(data);

        }
        this.setState({
            open: !open,
            button1Caption: '',
            button2Caption: '',
            title: '',
            message: '',
            initiateEmployeeId: ''
        });
    }
    initiateClearance = (initiateId, empId) => {
        const { open } = this.state;
        this.setState({
            initiateEmployeeId: empId,
            open: !open,
            button1Caption: 'Yes',
            button2Caption: 'No',
            title: 'Initiate Clearance',
            message: 'Are you sure you want to initiate clearance?',
        });
    }
    contEmployee = (contId, empId) => {
        const { open } = this.state;
        this.setState({
            initiateEmployeeId: empId,
            open: !open,
            button1Caption: 'Yes',
            button2Caption: 'No',
            title: 'Continue Employment',
            message: 'Are you sure you want to continue his/her employment?',
        });
    }
    joiningDateFormat = (props) => {
        const { joiningDate } = props;
        return (
            <AllDateFormat date={joiningDate} />
        );
    };
    reportedOnDateFormat = (props) => {
        const { lastReportedDate } = props;
        return (
            <AllDateFormat date={lastReportedDate} />
        );
    };
    abscondingDateFormat = (props) => {
        const { absondingDate } = props;
        return (
            <AllDateFormat date={absondingDate} />
        );
    };
    expectedJoiningDate = (props) => {
        const { expectedJoiningDate } = props;
        return (
            <AllDateFormat date={expectedJoiningDate} />
        );
    };
    lastCommunicatedDateFormat = (props) => {
        const { lastCommunicatedDate } = props;
        return (
            <AllDateFormat date={lastCommunicatedDate} />
        );
    };
    navigateToAddBasicInformation = async (args) => {
        try {
            localStorage.setItem('last_meeting_category', localStorage.getItem('meeting_category'))
            localStorage.setItem("meeting_category", encodeString(EMPLOYEE_PROFILE));

            const path = `/onboard?empId=${encrypt(args.employeeId)}`;
            this.setState({
                redirect: path,
                // changedData: !changedData,
            }, () => this.props.setMeetingCategory(EMPLOYEE_PROFILE));
        } catch (error) {
            console.log("error", error);
        }
    };

    TextFieldComponent = (props) => {
        return (
            // eslint-disable-next-line
            <TextField {...props} disabled />
        );
    };

    onFilesAdded(addedfiles) {
        // Clear the alredy uploaded files if multiple is false
        const { allowmultiple, files } = this.state;
        if (!allowmultiple && files.length > 0) {
            this.setState({ files: addedfiles });
        } else {
            this.setState((prevState) => ({
                files: prevState.files.concat(addedfiles),
            }));
        }
    }
    employeeNameTemplate(props) {
        return (
            <EmployeeNameTemplate
                props={props}
                data="test"
                navigateToAddBasicInformation={this.navigateToAddBasicInformation}
            />
        );
    }
    selectCommunicationDate = (e) => {
        this.setState({ communicationDate: Math.floor(Date.UTC(e.getFullYear(), e.getMonth(), e.getDate())) / 1000 });
    }
    getCommunication = (e) => {
        document.getElementById("hrStageErr").innerHTML = "";
        if (e.itemData !== null) {
            this.setState({ selectCommunication: e.itemData.Id });
        } else {
            this.setState({ selectCommunication: "" });
            document.getElementById("hrStageErr").innerHTML = "Please select communication";
            return;/**NOSONAR */
        }
    }
    setRemark = (e) => {
        this.setState({ remark: e.value }, () => document.getElementById("remarkErr").innerHTML = "")
    }
    render() {
        const { redirect, communicationHrList, communicationDate, selectCommunication, remark, abscondingReportedBackDate, abscondingExpectedDate } = this.state;
        const { abscondingHrData, communicationHrMaster } = this.props;
        if (redirect !== null) {
            return <Redirect to={redirect} />;
        }
        var widthColumn = 0;
        var finalWidth = 0;
        // eslint-disable-next-line
        getGridColumn("abscondingHr").forEach((data) => {
            widthColumn = widthColumn + data.width;
        });
        if (widthColumn <= this.width) {
            finalWidth = widthColumn + 20;
        } else {
            finalWidth = this.width
        }
        return (
            <div
                className="row resignation-manager-hr-grid-height"
                style={{
                    // height: "828px" 
                    paddingRight: '15px', paddingLeft: '15px'
                }}
            >
                {/* <div>ABSCONDING REPORT STATUS</div> */}
                <div>
                    <div className="row-xs-12 row-sm-12 row-lg-12 row-md-12">
                        <NotificationContainer />
                        <div>
                            <GridComponent
                                className="grid-head-custome"
                                id="overviewgrid"
                                allowPaging
                                pageSettings={this.pageOptions}
                                // dataSource={}
                                dataSource={abscondingHrData}
                                enableHover={false}
                                enableVirtualization={false}
                                rowHeight={30}
                                height={this.height}
                                ref={(g) => {
                                    this.gridInstance = g;
                                }}
                                allowTextWrap={true}
                                // actionComplete={this.onComplete.bind(this)}
                                // load={this.onLoad}
                                queryCellInfo={this.onQueryCellInfoPreJoining}
                                dataBound={this.onDataBound}
                                filterSettings={this.Filter}
                                allowFiltering
                                allowSorting
                                allowSelection
                                selectionSettings={this.select}
                                width={`${finalWidth}`}
                            >
                                <ColumnsDirective>
                                    {getGridColumn("abscondingHr").map((item) => {
                                        switch (item.colName) {
                                            case EMPLOYEE_NAME:
                                                return (
                                                    <ColumnDirective
                                                        key={item.field}
                                                        template={this.employeeNameTemplate}
                                                        textAlign={`${item.align} !important`}
                                                        field={item.field}
                                                        headerText={item.colName}
                                                        width={item.width}
                                                        filter={this.Filter}
                                                    />
                                                );
                                            case JOINING_DATE:
                                                return (
                                                    <ColumnDirective
                                                        key={item.field}
                                                        template={this.joiningDateFormat}
                                                        // allowFiltering={false}
                                                        filter={this.check}
                                                        textAlign={`${item.align} !important`}
                                                        field={item.field}
                                                        headerText={item.colName}
                                                        width={item.width}
                                                        format={DateFilterformat}
                                                    />
                                                );
                                            case REPORTED_ON:
                                                return (
                                                    <ColumnDirective
                                                        key={item.field}
                                                        template={this.reportedOnDateFormat}
                                                        // allowFiltering={false}
                                                        filter={this.check}
                                                        textAlign={`${item.align} !important`}
                                                        field={item.field}
                                                        headerText={item.colName}
                                                        width={item.width}
                                                        format={DateFilterformat}
                                                    />
                                                );
                                            case DATE_OF_ABSCONDING:
                                                return (
                                                    <ColumnDirective
                                                        key={item.field}
                                                        template={this.abscondingDateFormat}
                                                        // allowFiltering={false}
                                                        filter={this.check}
                                                        textAlign={`${item.align} !important`}
                                                        field={item.field}
                                                        headerText={item.colName}
                                                        width={item.width}
                                                        format={DateFilterformat}
                                                    />
                                                );
                                            case EXPECTED_JOINING_DATE:
                                                return (
                                                    <ColumnDirective
                                                        key={item.field}
                                                        template={this.expectedJoiningDate}
                                                        // allowFiltering={false}
                                                        filter={this.check}
                                                        textAlign={`${item.align} !important`}
                                                        field={item.field}
                                                        headerText={item.colName}
                                                        width={item.width}
                                                        format={DateFilterformat}
                                                    />
                                                );
                                            case LAST_COMMUNICATED_DATE:
                                                return (
                                                    <ColumnDirective
                                                        key={item.field}
                                                        template={this.lastCommunicatedDateFormat}
                                                        // filter={this.Filter}
                                                        // allowFiltering={false}
                                                        filter={this.check}
                                                        textAlign={`${item.align} !important`}
                                                        field={item.field}
                                                        headerText={item.colName}
                                                        width={item.width}
                                                        format={DateFilterformat}
                                                    />
                                                );
                                            case COMMENT:
                                                return (
                                                    <ColumnDirective
                                                        key={item.field}
                                                        template={this.commentTemplate}
                                                        allowFiltering={false}
                                                        textAlign={`${item.align} !important`}
                                                        field={item.field}
                                                        headerText={item.colName}
                                                        width={item.width}
                                                    />
                                                );
                                            case HR_STAGE_HR:
                                                return (
                                                    <ColumnDirective
                                                        key={item.field}
                                                        template={this.hrStageTemplate}
                                                        allowFiltering={false}
                                                        textAlign={`${item.align} !important`}
                                                        field={item.field}
                                                        headerText={item.colName}
                                                        width={item.width}
                                                    />
                                                );
                                            case ACTION_HR:
                                                return (
                                                    <ColumnDirective
                                                        key={item.field}
                                                        template={this.action}
                                                        allowFiltering={false}
                                                        textAlign={`${item.align} !important`}
                                                        field={item.field}
                                                        headerText={item.colName}
                                                        width={item.width}
                                                    />
                                                );
                                            case REMARK:
                                                return (
                                                    <ColumnDirective
                                                        key={item.field}
                                                        template={this.mgrRemarks}
                                                        allowFiltering={false}
                                                        textAlign={`${item.align} !important`}
                                                        field={item.field}
                                                        headerText={item.colName}
                                                        width={item.width}
                                                    />
                                                );
                                            default:
                                                return (
                                                    <ColumnDirective
                                                        key={item.field}
                                                        textAlign={`${item.align} !important`}
                                                        field={item.field}
                                                        headerText={item.colName}
                                                        width={item.width}
                                                        filter={this.check}
                                                    />
                                                );
                                        }
                                    })}
                                </ColumnsDirective>
                                <Inject services={[Page, Filter, VirtualScroll, Sort]} />
                            </GridComponent>
                        </div>
                    </div>
                    <div style={{ width: "350px" }}>
                        <Dialog
                            aria-labelledby="uploading-title"
                            open={this.state.openCommunication}
                            fullWidth
                            width="1000"
                        // PaperComponent={PaperComponent}
                        >
                            <DialogTitleTMLC id="uploading-title"
                                onClose={this.handleClose}
                            >
                                Upload Communication
                                <div style={{ float: 'right' }}>
                                    <IconButton style={{ color: "white", marginRight: '-5px', marginTop: "-7px" }} aria-label="close" onClick={this.closeCommunicationDialog}>
                                        <CloseIcon />
                                    </IconButton>
                                </div>
                            </DialogTitleTMLC>
                            <ErrorBoundary>
                                <DialogContent dividers>
                                    <div>
                                        <div className="row card-layout">
                                            <div className="col-xs-2 col-sm-2 col-lg-6 col-md-2 ">
                                                {/* Select Communication */}
                                                <ComboBoxComponent
                                                    id="hrStage"
                                                    fields={{ text: "Name", value: "Id" }}
                                                    name="hrStage"
                                                    placeholder="Select Communication*"
                                                    allowFiltering={true}
                                                    value={selectCommunication}
                                                    allowCustom={false}
                                                    cssClass="e-outline"
                                                    floatLabelType="Auto"
                                                    dataSource={communicationHrMaster}
                                                    change={(e) => this.getCommunication(e)}
                                                />
                                                <div id="hrStageErr" className="e-input-group e-float-icon-left error-div" />
                                            </div>
                                            <div className="col-xs-2 col-sm-2 col-lg-6 col-md-2 ">
                                                {/* Select Communication Date */}
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <KeyboardDatePicker
                                                        style={{ width: "100%" }}
                                                        id="communicationDate"
                                                        name="communicationDate"
                                                        format="dd-MMM-yyyy"
                                                        KeyboardButtonProps={{
                                                            "aria-label": "change date",
                                                        }}
                                                        value={new Date(0).setUTCSeconds(communicationDate)}
                                                        // value={new Date()}
                                                        onChange={(e) => this.selectCommunicationDate(e)}
                                                        TextFieldComponent={this.TextFieldComponent}
                                                        maxDate={new Date()}
                                                        floatLabelType="Auto"
                                                        inputVariant="outlined"
                                                        variant="inline"
                                                        label="Select Communication Date"
                                                        InputAdornmentProps={{ position: "start" }}
                                                        autoOk
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </div>
                                        </div>
                                        <div className="row card-layout">
                                            <div className="Upload">
                                                <div className="Content">
                                                    <div style={{ textAlign: '-webkit-center' }}>
                                                        <AbscondingUploadFile
                                                            documentPath={this.state.documentPath}
                                                            documentDetail={this.documentDetail}
                                                            type={"communication"}

                                                        />
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                        <div id="uploadErr" className="e-input-group e-float-icon-left error-div" />
                                        <div className="row card-layout">
                                            <div className="col-xs-2 col-sm-2 col-lg-12 col-md-2 Content">
                                                <TextBoxComponent placeholder="Remark *" cssClass="e-outline" floatLabelType="Auto"
                                                    name="magenda-txt"
                                                    multiline={true}
                                                    style={{ backgroundColor: '#fff' }}
                                                    inputProps={{
                                                        required: true,
                                                        minLength: 8,
                                                    }}
                                                    value={remark}
                                                    ref={(scope) => { this.textareaObjRemark = scope; }}
                                                    input={(e) => this.limitCaracterRemark(e)}
                                                    change={(e) => this.setRemark(e)}
                                                    htmlAttributes={{ maxlength: 500 }}
                                                    data-msg-containerid="agendaError"
                                                />
                                                <div>
                                                    <div style={{ float: "left" }} id="remarkErr" className="col-11 e-input-group e-float-icon-left error-div" />
                                                    <div style={{ float: "right" }} id="remarkLimit" className="error-div" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </DialogContent>
                                <DialogActions>
                                    <Button color="default" variant="contained" onClick={this.closeCommunicationDialog}> Cancel </Button>
                                    <Button color="primary" variant="contained" onClick={this.uploadDocument} style={{ marginRight: '6px' }}> Save </Button>
                                </DialogActions>
                            </ErrorBoundary>
                        </Dialog>
                    </div>
                    <div>
                        <Dialog
                            open={this.state.openCommunicationPreview}
                            width="500"
                        >
                            <DialogTitleTMLC id="uploading-title" onClose={this.handleClose}>
                                Communication Stage List
                                <div style={{ float: 'right' }}>
                                    <IconButton style={{ color: "white", marginRight: '-5px', marginTop: "-7px" }} aria-label="close" onClick={this.closeCommunicationPreviewDialog}>
                                        <CloseIcon />
                                    </IconButton>
                                </div>
                                {/* <IconButton style={{ color: "white", position: "absolute", marginLeft: "620px" }} aria-label="close" onClick={this.closeCommunicationPreviewDialog}>
                                    <CloseIcon />
                                </IconButton> */}
                            </DialogTitleTMLC>
                            <ErrorBoundary>
                                <DialogContent dividers>
                                    <div>
                                        <CommunicatedPreviewGrid communicationHrList={communicationHrList} type="communication" />
                                    </div>
                                </DialogContent>
                            </ErrorBoundary>
                        </Dialog>
                    </div>
                    <div style={{ width: "350px" }}>
                        <Dialog
                            aria-labelledby="uploading-title"
                            open={this.state.openCommentBox}
                            // open={true}
                            fullWidth
                            width="1000"
                        // PaperComponent={PaperComponent}
                        >
                            <DialogTitleTMLC id="uploading-title"
                                onClose={this.handleClose}
                            >
                                {
                                    this.state.actionType === "add" ?
                                        'Add Comments'
                                        :
                                        'Add Comment For Employee Reported Back'
                                }
                                <div style={{ float: "right" }}>
                                    <IconButton style={{ color: "white", marginRight: "-5px", marginTop: "-7px" }} aria-label="close" onClick={this.addCommentClose}>
                                        <CloseIcon />
                                    </IconButton>
                                </div>
                            </DialogTitleTMLC>
                            <ErrorBoundary>
                                <DialogContent dividers>
                                    <div>
                                        {
                                            this.state.actionType !== "add" &&
                                            <div className="row card-layout">
                                                <div className="col-xs-2 col-sm-2 col-lg-6 col-md-2" style={{ paddingBottom: '16px' }}>
                                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                        <KeyboardDatePicker style={{ width: '100%' }}
                                                            id="lwdCompanyRequest"
                                                            name="lwdCompanyRequest"
                                                            format="dd-MMM-yyyy"
                                                            KeyboardButtonProps={{
                                                                'aria-label': 'change date',
                                                            }}
                                                            value={abscondingReportedBackDate !== '' ?/**NOSONAR */ new Date(abscondingReportedBackDate) : new Date()}
                                                            onChange={(date) => this.setState({ abscondingReportedBackDate: date })}
                                                            TextFieldComponent={this.TextFieldComponent}
                                                            inputVariant="outlined"
                                                            variant="inline"
                                                            InputAdornmentProps={{ position: "start" }}
                                                            autoOk
                                                            maxDate={new Date()}
                                                            // disabled={employeeStatus === "Absconded" ? true:false}
                                                            label="Reported Back Date *"
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </div>
                                                <div className="col-xs-2 col-sm-2 col-lg-6 col-md-2" style={{ paddingBottom: '16px' }}>
                                                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                        <KeyboardDatePicker style={{ width: '100%' }}
                                                            id="expectedDate"
                                                            name="expectedDate"
                                                            format="dd-MMM-yyyy"
                                                            KeyboardButtonProps={{
                                                                'aria-label': 'change date',
                                                            }}
                                                            value={abscondingExpectedDate !== '' ? /**NOSONAR */new Date(abscondingExpectedDate) : new Date()}
                                                            onChange={(date) => this.setState({ abscondingExpectedDate: date })}
                                                            TextFieldComponent={this.TextFieldComponent}
                                                            inputVariant="outlined"
                                                            variant="inline"
                                                            InputAdornmentProps={{ position: "start" }}
                                                            autoOk
                                                            minDate={new Date()}
                                                            // disabled={employeeStatus === "Absconded" ? true:false}
                                                            label="Expected Joining Date *"
                                                        />
                                                    </MuiPickersUtilsProvider>
                                                </div>
                                            </div>
                                        }
                                        <div className="row card-layout">
                                            <div className="col-xs-2 col-sm-2 col-lg-12 col-md-2">
                                                <TextBoxComponent
                                                    ref={(scope) => { this.textareaObj = scope; }}
                                                    id="addComment"
                                                    name="addComment"
                                                    placeholder="Comment*"
                                                    cssClass="e-outline"
                                                    multiline
                                                    htmlAttributes={{ maxlength: 500 }}
                                                    floatLabelType="Auto"
                                                    change={(e) => this.getCommentValue(e)}
                                                    input={(e) => this.limitCaracter(e)}
                                                />
                                            </div>
                                        </div>
                                        <div >
                                            <div style={{ float: "left" }} id="addCommentErr" className="col-11 e-input-group e-float-icon-left error-div" />
                                            <div style={{ float: "right" }} id="commentLimitErr" className="error-div" />
                                        </div>
                                    </div>
                                </DialogContent>
                                <DialogActions style={{ paddingRight: "15px" }}>
                                    <Button color="default" variant="contained" onClick={this.addCommentClose}> Cancel </Button>
                                    <Button color="primary" variant="contained" onClick={this.submitComment} > Save </Button>
                                </DialogActions>
                            </ErrorBoundary>
                        </Dialog>
                    </div>
                    <div style={{ width: "350px" }}>
                        <Dialog
                            aria-labelledby="uploading-title"
                            open={this.state.viewCommentList}
                            fullWidth
                            width="1000"
                        // PaperComponent={PaperComponent}
                        >
                            <DialogTitleTMLC id="uploading-title"
                                onClose={this.handleClose}
                            >
                                View Comments
                                <div style={{ float: 'right' }}>
                                    <IconButton style={{ color: "white", marginRight: '-5px', marginTop: "-7px" }} aria-label="close" onClick={this.closeCommentList}>
                                        <CloseIcon />
                                    </IconButton>
                                </div>
                                {/* <IconButton style={{ color: "white", position: "absolute", marginLeft: "713px" }} aria-label="close" onClick={this.closeCommentList}>
                                    <CloseIcon />
                                </IconButton> */}
                            </DialogTitleTMLC>
                            <ErrorBoundary>
                                <DialogContent dividers>
                                    {
                                        this.state.commentList.length > 0 ? (
                                            this.state.commentList.map((item, index) => (
                                                <div id={index} className="comment-main-content">
                                                    <div className="comment-main-content-header">
                                                        {item.AddedBy}:{" "}{item.AddedOn}  {item.empReportedBack}
                                                    </div>
                                                    <div className="comment-main-content-content">
                                                        {item.Comments}
                                                    </div>
                                                </div>
                                            )))
                                            :
                                            <div> Comment not available</div>
                                    }
                                </DialogContent>
                                <DialogActions style={{ paddingRight: "20px" }}>
                                    <Button color="default" variant="contained" onClick={this.closeCommentList}> Close </Button>
                                </DialogActions>
                            </ErrorBoundary>
                        </Dialog>
                    </div>
                    <ConfirmationPopup
                        button1function={this.handleSubmitYes}
                        button2function={this.handleConfirmNo}
                        button2Text={this.state.button2Caption}
                        button1Text={this.state.button1Caption}
                        message={this.state.message}
                        showButton={{ type: "1" }}
                open={this.state.open}
                handleConfirmClose={() => this.handleConfirmNo()}
                    />  
                </div>
            </div>
        );
    }
}
const mapStateToProps = ({ auth }) => {
    const { authUser } = auth;
    return { authUser };
};
export default connect(mapStateToProps, { submitAbscondingData, submiteExitClearanceData, setMeetingCategory })(AbscondingHrGrid);
