import { connect } from 'react-redux';
import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faAngleUp, faAngleDown } from "@fortawesome/pro-light-svg-icons";
import { Button, Dialog, DialogTitle } from "@material-ui/core";
import { ComboBoxComponent } from "@syncfusion/ej2-react-dropdowns";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import "../../app/layout/okrratinglayout/okrrating.css";
import { FormValidator } from "@syncfusion/ej2-inputs";
import { DialogContent, DialogActions } from "../customdialog/customdialog";
import UploadDocuments from "./uploaddocuments";
import Tooltips from "../custommaterialtooltip/custommaterialtooltip";
import OKRRatingServices from '../../services/okrrating/okrratingservices'
import { SelectYourRatingRecommendation, YouCanUploadSupportingDocumentForYourFinalRating, SelectYourRatingForTheLeadershipPrinciple, YouCanUploadSupportingDocumentForYourLeadershipPrincipleRating, YouMayUploadSupportingDocumentForTheRating, OKRQuestionRatingData } from '../../assets/constants/constants'
import { addEditSelfSupTPRatingRedux } from '../../redux/actions/okrrating/okrratingaction'
import { RadioButtonComponent } from '@syncfusion/ej2-react-buttons';
import BenchMarkTable from '../okrproductiveallocation/benchmarktable';
import { cracterLimit, benchmarkFormatData, getQuestionRatingName } from '../../app/other/commonfunction';
import ProgressBar from '../progressbar/progressbar';
import ReactHtmlParser from 'react-html-parser'
import Pastratingmodal from './pastratingmodal';
import { TabComponent } from '@syncfusion/ej2-react-navigations';
import ConfirmationPopup from '../../utilities/confirmationpopup';
function AddRatingModalkr(props) {//NOSONAR
  const [remarks, setRemarks] = useState("");
  const [rating, setRating] = useState('')
  const [selfRating, setSelfRating] = useState('')
  const [tableHeader, setTableHeader] = useState('')//NOSONAR
  const [supRating, setSupRating] = useState('')
  const [selfRemark, setSelfRemarks] = useState('')
  const [supRemark, setSupRemarks] = useState('')
  const [ratingFiles, setRatingFiles] = useState([])
  const [selectedFilesTime, setSelectedFilesTime] = useState([]);
  const [changedData, setChangedData] = useState(false)
  const [openBenchmarkPopup, setOpenBenchmarkPopup] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selfRatingQuestions, setSelfRatingQuestions] = useState([]);
  const [openLeadershipQuestions, setOpenLeadershipQuestions] = useState(true);
  const [openLeadershipInfo, setOpenLeadershipInfo] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [selectedTab, setSelectedTab] = useState(0)

  const handleTabSelect = (e) => {
    const { selectedIndex } = e;
    setSelectedTab(Number(selectedIndex));
  }


  const textareaObj = useRef(null);

  useEffect(() => {
    const { authUser, empId } = props
    const { ratingId } = props.KRData
    getKRQuestionData();
    const ratingData = OKRRatingServices.getRatingInfo(authUser, empId, ratingId, ratingType())
    ratingData.then((resultData) => {
      if (typeof resultData !== 'undefined' && resultData !== null) {
        const ratingFilesData = resultData[0].ratingFiles.split(',');
        setRating(resultData[0].rating)
        setSelfRating(resultData[0].selfRating)
        setSupRating(resultData[0].supRating)
        setRating(resultData[0].rating)
        setTableHeader(resultData[0].header)
        setRemarks(resultData[0].remarks)
        setSelfRemarks(resultData[0].selfRemark)
        setSupRemarks(resultData[0].supRemark)
        setTimeout(() => { onInput() }, 10)
        setRatingFiles(ratingFilesData)

        var timeArr = [];
        for (var i = 0; i <= ratingFilesData.length; i++) {
          timeArr.push(new Date());
        }
        setSelectedFilesTime(timeArr);
      }
    });

    // eslint-disable-next-line 
  }, [props.KRData.ratingId])
  /**
   * @description Get the Qusetion data of user Kr wise. 
   */
  const getKRQuestionData = async () => {
    const krQuestionData = await OKRRatingServices.getKRQuestionData(ratingType(), props.authUser, props.empId, props.KRData.userKrId, props.fyId, props.monthOfRating);
    krQuestionData?.map((item) => {
      let data = JSON.stringify(OKRQuestionRatingData);
      let optionData = Object.assign([], JSON.parse(data));
      if (ratingType() === 'self' && item.selfRating !== null) {
        optionData[item.selfRating].value = true;
      }
      if (ratingType() === "sup" && item.supRating !== null) {
        optionData[item.supRating].value = true;
      }
      if (ratingType() === "final" && item.finalRating !== null) {
        optionData[item.finalRating].value = true;
      }
      item.OKRQuestionRatingData = optionData;
      if (item.selfRating === item.supRating && item.supRating !== null) {
        item.OKRQuestionRatingData.map(itemData => {
          if (itemData.Id === item.supRating) {
            itemData.value = true;
          }
        })
      }
      return item;
    })
    setSelfRatingQuestions(krQuestionData);
    setLoading(false)
  }
  useEffect(() => {
    getKRQuestionData();
  }, []);


  const onChange = (e, type) => {
    let options = {};
    if (props.OKRType === '3') {
      if (type === "Remarks") {
        setRemarks(e.value);
        if (rating === 4 || rating === 5) {
          options = { rules: { remarkErr: { required: [false, "Please input at least one scenario in remark box."] } } };
        }
      }
    } else {
      switch (type) {
        case "Remarks":
          setRemarks(e.value);
          options = { rules: { remarkErr: { required: [true, "Please enter remarks *"] } } };
          break;
        case "Rating":
          setRating(e.value);
          options = { rules: { ratingErr: { required: [true, "Please select rating *"] } } };
          break;
        default:
          break;
      }
    }
    setTimeout(() => {
      const addFormObject = new FormValidator("#AddRatingModalForm", options);
      addFormObject.validate()
    }, 100);
  };

  const onCancel = () => {
    props.closeKrRatingPopup()
  };
  /**
   * Submit the  rating data.
   */
  const onSave = () => {

    let options = {};
    if (props.OKRType === '3') {
      if (rating === 4 || rating === 5) {
        options = {
          rules: {
            remarkErr: { required: [false, "Please input at least one scenario in remark box."] },
            // ratingErr: { required: [true, "Please select rating."] },
          },
        };
      }
    } else {
      options = {
        rules: {
          remarkErr: { required: [true, "Please enter remarks *"] },
        },
      };
    }
    const addFormObject = new FormValidator("#AddRatingModalForm", options);
    setTimeout(() => {
      let quesionCondition = props.OKRType === '3' ? validateQuestions() : true;
      if (addFormObject.validate() && quesionCondition) {
        try {
          const type = ratingType()
          let questionRatingData = questionsRatingData();
          const data = {
            type,
            userId: props.authUser,
            empId: props.empId,
            ratingUId: props.KRData.ratingId,
            recommendedRating: props.OKRType === '3' ? 0 : rating,
            remarks,
            recommededFiles: ratingFiles.toString() ? ratingFiles.toString() : null,
            OKRType: props.OKRType,
            questionRatingData: props.OKRType === '3' ? questionRatingData : null
          }
          props.addEditSelfSupTPRatingRedux(data)

        } catch (error) {
        }
      } else {
        if (props.OKRType === '3')
          document.getElementById('questionError').innerHTML = "Please select options of all questions.";
      }
    }, 100);
  };
  /**
   * @description validate the questions 
   * @returns 
   */
  const validateQuestions = () => {
    let count = 0;
    selfRatingQuestions?.forEach((item) => {
      item.OKRQuestionRatingData?.forEach((option) => {
        if (option.value === true) {
          count = count + 1;
        }
      })
    })

    return selfRatingQuestions.length === count
  }
  /**
   * @description Format the question data.
   * @returns 
   */
  const questionsRatingData = () => {
    let ratingQAns = "";
    selfRatingQuestions?.forEach((item) => {
      item.OKRQuestionRatingData?.forEach((option) => {
        if (option.value) {
          ratingQAns = ratingQAns + item.Id + "_" + option.Id + "$$"
        }
      })
    })
    return ratingQAns
  }

  const onInput = (e) => {
    try {
      setTimeout(() => {
        if (textareaObj !== null && typeof textareaObj !== 'undefined' && textareaObj.current) {
          textareaObj.current.respectiveElement.style.height = "auto";
          textareaObj.current.respectiveElement.style.minHeight = "60px";
          textareaObj.current.respectiveElement.style.height = `${textareaObj.current.respectiveElement.scrollHeight}px`;
          cracterLimit(e, 'remarkCount-addrating', '1000')
        }
      }, 100)
    } catch (error) {
    }

  };

  const header = () => {
    switch (props.ratingTabs) {
      case "self-rating":
        return "Self Rating"
      case "supervisor-rating":
        return "Supervisor Rating"
      case "final-rating":
        return "Final Rating"
      case "okr-review":
        return "Final Rating"
      default:
    }
  }

  const ratingType = () => {
    let type
    switch (props.ratingTabs) {
      case "self-rating":
        type = "self"; break;
      case "supervisor-rating":
        type = "sup"; break;
      case "thirdparty-rating":
        type = "tp"; break;
      case "okr-review":
        type = "final"; break;
      default:
    }
    return type;
  }

  const ratingRange = () => {
    try {
      const { ratingMecanismEnd } = props.productivityallocationData
      return Array.from({ length: ratingMecanismEnd }, (_, index) => index + 1)
    } catch (error) {
    }
  }

  const selectedFiles = (fileNms) => {
    try {
      setRatingFiles(fileNms);
      setTimeout(() => {
        setChangedData(!changedData)
      })
    } catch (error) { }
  };
  const { OKRType } = props;
  let benchMarkData = [];
  if (props.KRData.benchmark !== '' && props.KRData.benchmark !== null) {
    benchMarkData = benchmarkFormatData(props.KRData.benchmark)
  }

  const handleLeadershipQuestions = () => {
    setOpenLeadershipQuestions(prevState => !prevState);
  }

  const handleLeadershipInfo = () => {
    setOpenLeadershipInfo(prevState => !prevState);
  }
  const setQuestionData = (e, data, optionInx, questionInx, questionId) => {
    try {
      document.getElementById('questionError').innerHTML = "";
      let questionData = selfRatingQuestions;
      questionData[questionInx].OKRQuestionRatingData?.map((item, inx) => {

        if (inx === optionInx) {
          item.value = true
        } else {
          item.value = false
        }
      })
      setSelfRatingQuestions(questionData);
    } catch (error) {
    }

  }
  /**
   * @description Set the Final rating question dropdown data 
   * @param {*} e Event
   * @param {*} qinx  question Index
   */
  const setFinalRatingQuestionAns = (e, qinx) => {
    try {
      let questionData = selfRatingQuestions;
      questionData[qinx].OKRQuestionRatingData?.map((item, inx) => {
        if (item.Id === e.itemData.Id) {
          item.value = true;
        } else {
          item.value = false;
        }
      })
      setSelfRatingQuestions(questionData);
    } catch (error) {
    }
  }

  /**
   * @description Setiting the exestion value of question.
   * @param {*} qInx 
   * @returns 
   */
  const setValueOfFinalRating = (qInx) => {
    let questionData = selfRatingQuestions;
    let value = null;
    questionData[qInx].OKRQuestionRatingData?.map((item, inx) => {
      if (item.value === true && item.value !== null) {
        value = item.Id
      }
    })
    return value;
  }
  const answerName = (ratingData) => { //NOSONAR
    let ans = "";
    if (ratingData !== null) {
      OKRQuestionRatingData?.map((item) => {/*NOSONAR*/
        if (ratingData === item.Id) {
          ans = item.Name
        }
      })
    }
    else {
      ans = '-';
    }
    return ans;
  }

  const getAddRatingForm = () => {
    return (
      <>
        <div className="form-row">
          <div className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div className="view-kr-modalrating-header-text" style={{ height: 20, fontSize: 15, paddingLeft: 10, color: 'black' }}>
              The below observations are for {props.KRData.frequencyNm}
            </div>
          </div>
        </div>
        <div className="form-row">
          <div
            className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pl-sm pr-sm pb-lg"
          // style={{ padding: "0px 18px 0px 10px" }}
          >
            {header() === "Final Rating" ?
              (<div className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ paddingRight: ".02rem" }}>
                <table id="final-rating-leadership-questions">
                  <tr>
                    <th>Questionnaires</th>
                    <th>Self Rating</th>
                    <th>Supervisor Rating</th>
                    <th>Final Rating</th>
                    <th>
                      <FontAwesomeIcon
                        style={{ color: '#939399', fontSize: 20, cursor: "pointer" }}
                        icon={openLeadershipQuestions ? faAngleUp : faAngleDown}
                        onClick={handleLeadershipQuestions}
                      />
                    </th>
                  </tr>
                  {openLeadershipQuestions && selfRatingQuestions.length > 0 && selfRatingQuestions.map((questionArr, inx) => (
                    <tr key={questionArr.question}>
                      <td>{questionArr.question}</td>
                      <td>{getQuestionRatingName(questionArr.selfRating)}</td>
                      <td>{getQuestionRatingName(questionArr.supRating)}</td>
                      <td>
                        {
                          questionArr.OKRQuestionRatingData.length > 0 &&
                          <ComboBoxComponent
                            style={{ width: 150 }}
                            id={`finalRatingDropdown${questionArr.questionId}`}
                            fields={{ text: "Name", value: "Id" }}
                            data-msg-containerid="finalRatingDropdownErr"
                            name="finalRatingDropdownErr"
                            floatLabelType="Auto"
                            cssClass="e-outline"
                            allowFiltering
                            allowCustom={false}
                            placeholder="Select Final Rating *"
                            dataSource={questionArr.OKRQuestionRatingData}
                            value={setValueOfFinalRating(inx)}
                            select={(e) => {
                              setFinalRatingQuestionAns(e, inx)
                            }}
                          />
                        }
                      </td>
                      <td></td>
                    </tr>
                  ))}
                </table>
                <div id="questionError" className="error-div" />
              </div>
              ) : (<>
                <div className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12" style={{ paddingRight: ".02rem" }}>
                  <table id="self-rating-leadership-questions" className="tabcontent" style={{ display: "block" }}>
                    <tr>
                      <th>Questionnaires</th>
                      <th>Not Observed</th>
                      <th>Never</th>
                      <th>Rarely</th>
                      <th>Sometimes</th>
                      <th>Most of the time</th>
                      <th>Always</th>
                      <th>
                        <FontAwesomeIcon
                          style={{ color: '#939399', fontSize: 20, cursor: "pointer" }}
                          icon={openLeadershipQuestions ? faAngleUp : faAngleDown}
                          onClick={handleLeadershipQuestions}
                        />
                      </th>
                    </tr>
                    {openLeadershipQuestions && selfRatingQuestions.length > 0 && selfRatingQuestions.map((questionArr, questionInx) => (
                      <tr key={questionArr.question}>
                        <td>{questionArr.question}</td>
                        {questionArr.OKRQuestionRatingData?.map((item, optionInx) => {
                          return (<td>
                            <RadioButtonComponent cssClass="questions-radio-button" id={`state-${questionArr.questionId}${optionInx}`} key={`state-${questionArr.questionId}${optionInx}`} name={`state-${questionArr.questionId}`} change={
                              (e) => {
                                setQuestionData(e, item, optionInx, questionInx, questionArr.questionId);
                              }
                            }
                              checked={item.value}
                            />
                          </td>)
                        })}
                        <td></td>
                      </tr>
                    ))}
                  </table>
                  <div id="questionError" className="error-div" />
                </div>
              </>)
            }
          </div>
          {header() === "Final Rating" && <>
            <div
              className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pl-sm pr-sm pb-lg"
            >
              <div className="view-kr-modalrating-header-text">
                Self Remark
              </div>
              <div className="view-kr-modalrating-text-headeing">{selfRemark}</div>
            </div>
            <div className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pl-sm pr-sm pb-lg"
            >
              <div className="view-kr-modalrating-header-text">
                Supervisor Remark
              </div>
              <div className="view-kr-modalrating-text-headeing" style={{ Height: 70 }}>{supRemark}</div>
            </div>
          </>}
          {
            OKRType !== '3' &&
            <div
              className="col-8 col-lg-8 col-md-8 col-sm-8 col-xs-8 pl-lg"
              style={{ paddingLeft: 10 }}>
              <Button title="Measurement"
                onClick={() => setOpenBenchmarkPopup(true)}
              >
                {/* eslint-disable-next-line */}
                <a>View Measurement</a>
              </Button>
            </div>
          }
        </div>
        <div className="form-row">

          <div className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div className="text-box-add-rating pl-sm pr-sm"
            >
              <TextBoxComponent
                id={"Remark"}
                cssClass="e-outline textarea"
                floatLabelType="Auto"
                input={onInput}
                ref={textareaObj}
                name="remarkErr"
                placeholder='Remarks'
                data-msg-containerid="remarkErr"
                multiline
                inputProps={{
                  required: true,
                  minLength: 7,
                }}
                htmlAttributes={{ maxlength: 1000 }}
                value={remarks}
                onChange={(e) => onChange(e, "Remarks")}
              />
            </div>
            <div id='remarkCount-addrating' style={{ float: 'right', fontSize: 12, margin: '0px 8px 8px 0px' }} className="error-div" />
            <div style={{ margin: '0px -52px 4px 0px' }} id="remarkErr" className="e-input-group e-float-icon-left error-div" />
          </div>
        </div>
        <div className="form-row">
          <div className="col-12 col-lg-12 col-md-12 col-sm-12">
            <div className="view-kr-modalrating-header-text pl-sm">
              Upload Document(s)
              <span style={{ padding: "0px 0px 2px 8px", position: 'relative' }}>
                <Tooltips titleMessage={ /*nosonar*/ OKRType === '3' ? YouCanUploadSupportingDocumentForYourLeadershipPrincipleRating : props.ratingTabs === 'okr-review' ? YouCanUploadSupportingDocumentForYourFinalRating : OKRType === '1' ? YouMayUploadSupportingDocumentForTheRating : OKRType === '2' ? YouMayUploadSupportingDocumentForTheRating : ''} />
              </span>
            </div>
            <div
              className="upload-document-link-add-interim add-rating-upload pl-sm pr-sm">
              <UploadDocuments
                type={"rating-self"}
                ratingId={props.KRData.ratingId}
                allowmultiple={true}
                selectedFiles={selectedFiles}
                fileList={ratingFiles}
                filesTimeStamp={selectedFilesTime}
                apiUrl={'tmlcApiUrl'}
                cols={3}
                module={"LPOKR"}
              />
            </div>
          </div>
        </div>
        <div>
        </div>
      </>
    )
  }
  return (
    <div>
      <Dialog
        open={true}
        fullWidth={true}
        maxWidth={header() === "Final Rating" ? "lg" : 'lg'}
        onEscapeKeyDown={() => onCancel()}
        className={props.OKRType === '3' ? 'add-rating-popup' : ""}
      >
        <DialogTitle>
          <span className="add-new-self-header "
            style={{ color: "black", marginLeft: -4, fontFamily: "Circular Std ", fontSize: 18 }}>
            {header()}
          </span>
        </DialogTitle>
        <DialogContent style={{ paddingTop: 0, marginTop: "-10px", overflowX: "hidden", marginRight: 16, minHeight: '100%' }}>
          {(props.OKRType === '3' && isLoading) && <ProgressBar />}
          {(props.OKRType === '3' && !isLoading) &&
            <div className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12" >
              <form id="AddRatingModalForm">
                <div className="form-row">
                  <div
                    className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pl-sm pr-sm pb-lg"
                  // style={{ padding: '0px 16px 16px 10px' }}
                  >
                    <div className="view-kr-modalrating-header-text" style={{ height: 20, fontSize: 15 }}>
                      Leadership Principle
                    </div>
                    <div className="view-kr-modalrating-text">
                      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <div style={{ fontSize: 15, fontWeight: 600, color: "#000" }}>
                          {props.KRData.krName}
                        </div>
                        <div style={{ marginRight: ".4rem" }}>
                          <FontAwesomeIcon
                            style={{ color: '#939399', fontSize: 20, cursor: "pointer" }}
                            icon={openLeadershipInfo ? faAngleUp : faAngleDown}
                            onClick={handleLeadershipInfo}
                          />
                        </div>
                      </div>
                      <div style={{ paddingTop: ".3rem", fontSize: 13, display: openLeadershipInfo ? "block" : "none" }}>
                        {ReactHtmlParser(props.KRData.krDetails)}
                      </div>
                    </div>

                  </div>
                </div>

                {
                  header() === "Final Rating" ?
                    <div className="form-row">
                      <div>
                        <div style={{ padding: '0px 16px 16px 10px', display: "flex", justifyContent: "flex-start", alignItems: "center", float: "left" }}>
                          <div className="view-kr-modalrating-header-text" style={{ marginRight: "1.5rem" }}>
                            Self Rating
                          </div>
                          <div
                            className="view-kr-modalrating-text"
                            style={{ textAlign: "center", width: "70px" }}
                          >{selfRating}</div>
                        </div>
                        <div style={{ padding: '0px 16px 16px 10px', display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                          <div className="view-kr-modalrating-header-text" style={{ marginRight: "1.5rem" }}>
                            Supervisor Rating
                          </div>
                          <div
                            className="view-kr-modalrating-text"
                            style={{ textAlign: "center", width: "70px" }}
                          >{supRating}</div>
                        </div>
                      </div>
                    </div>
                    :
                    ""
                }
                {props.KRData.pastRating === 'Y' ?
                  <div className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <TabComponent cssClass='okr-rating-popup-tab' selected={handleTabSelect}>
                      <div className="e-tab-header">
                        <div> Current Rating </div>
                        <div> Past Rating </div>
                      </div>
                      <div class="e-content">
                        <div>
                          {getAddRatingForm()}
                        </div>
                        <div>
                          <Pastratingmodal
                            OKRType={4}
                            KRData={props.KRData}
                          />
                        </div>
                      </div>
                    </TabComponent>
                  </div> :
                  <>{getAddRatingForm()}</>
                }
              </form>
            </div>

          }
          {props.OKRType !== '3' &&
            <div className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12" >
              <form id="AddRatingModalForm">
                <div className="form-row">
                  <div
                    className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12"
                    style={{ padding: '0px 16px 16px 10px' }}>
                    <div className="view-kr-modalrating-header-text" style={{ height: 20 }}>
                      Key Result
                    </div>
                    <div className="view-kr-modalrating-text">{props.KRData.krName}</div>
                  </div>
                </div>
                {
                  header() === "Final Rating" ?
                    <>
                      <div className="form-row">
                        <div
                          className="col-2 col-lg-2 col-md-2 col-sm-2 col-xs-2"
                          style={{ padding: '0px 16px 16px 10px' }}>
                          <div className="view-kr-modalrating-header-text">
                            Self Rating
                          </div>
                          <div className="view-kr-modalrating-text">{selfRating}</div>
                        </div>
                        <div
                          className="col-10 col-lg-10 col-md-10 col-sm-10 col-xs-10"
                          style={{ padding: '0px 16px 16px 10px' }}>
                          <div className="view-kr-modalrating-header-text">
                            Self Remark
                          </div>
                          <div className="view-kr-modalrating-text-headeing">{selfRemark}</div>
                        </div>
                      </div>
                      <div className="form-row">
                        <div
                          className="col-2 col-lg-2 col-md-2 col-sm-2 col-xs-2"
                          style={{ padding: '0px 16px 16px 10px' }}>
                          <div className="view-kr-modalrating-header-text">
                            Supervisor Rating
                          </div>
                          <div className="view-kr-modalrating-text" >{supRating}</div>
                        </div>
                        <div
                          className="col-10 col-lg-10 col-md-10 col-sm-10 col-xs-10"
                          style={{ padding: '0px 16px 16px 10px' }}>
                          <div className="view-kr-modalrating-header-text">
                            Supervisor Remark
                          </div>
                          <div className="view-kr-modalrating-text-headeing" style={{ Height: 70 }}>{supRemark}</div>
                        </div>
                      </div>
                    </>
                    :
                    ""
                }

                <div className="form-row">
                  <div
                    className="col-2 col-lg-2 col-md-2 col-sm-2 col-xs-2"
                    style={{ paddingLeft: 10 }}>
                    <ComboBoxComponent
                      id="Rating"
                      dataSource={ratingRange()}
                      fields={{ text: "Name", value: "Id" }}
                      value={rating === '' ? '' : parseInt(rating, 10)}
                      data-msg-containerid="ratingErr"
                      name="ratingErr"
                      allowFiltering
                      allowCustom={false}
                      floatLabelType="Auto"
                      cssClass="e-outline"
                      placeholder="Final Rating *"
                      change={(e) => onChange(e, "Rating")}
                    />
                    <span style={{ padding: "4px 0px 0px 8px", position: "absolute" }}><Tooltips
                      titleMessage={OKRType === '1' /*nosonar*/ ? SelectYourRatingRecommendation : OKRType === '2' ? SelectYourRatingRecommendation : OKRType === '3' ? SelectYourRatingForTheLeadershipPrinciple : ""}
                    /></span>
                    <div id="ratingErr" className="e-input-group e-float-icon-left error-div" />
                  </div>
                  {
                    OKRType !== '3' &&
                    <div
                      className="col-8 col-lg-8 col-md-8 col-sm-8 col-xs-8 pl-lg"
                      style={{ paddingLeft: 10 }}>
                      <Button title="Measurement"
                        onClick={() => setOpenBenchmarkPopup(true)}
                      >
                        {/* eslint-disable-next-line */}
                        <a>View Measurement</a>
                      </Button>
                    </div>
                  }
                </div>
                <div className="form-row">
                  {/* <div className="view-kr-modalrating-header-text pl-sm" style={{ paddingTop: 160 }}>
                  Remarks
                </div> */}
                  <div className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <div className="text-box-add-rating" style={{ marginRight: 7 }}>
                      <TextBoxComponent
                        id={"Remark"}
                        cssClass="e-outline textarea"
                        floatLabelType="Auto"
                        input={onInput}
                        ref={textareaObj}
                        name="remarkErr"
                        placeholder='Remarks *'
                        data-msg-containerid="remarkErr"
                        multiline
                        inputProps={{
                          required: true,
                          minLength: 7,
                        }}
                        htmlAttributes={{ maxlength: 1000 }}
                        value={remarks}
                        onChange={(e) => onChange(e, "Remarks")}
                      />
                    </div>
                    <div id='remarkCount-addrating' style={{ float: 'right', fontSize: 12, margin: '0px 8px 8px 0px' }} className="error-div" />
                    <div style={{ margin: '0px -52px 4px 10px' }} id="remarkErr" className="e-input-group e-float-icon-left error-div" />
                  </div>
                </div>
                <div className="form-row">
                  <div className="col-12 col-lg-12 col-md-12 col-sm-12 pt-xs">
                    <div className="view-kr-modalrating-header-text pl-sm">
                      Upload Document(s)
                      <span style={{ padding: "0px 0px 2px 8px", position: 'relative' }}>
                        <Tooltips titleMessage={ /*nosonar*/ OKRType === '3' ? YouCanUploadSupportingDocumentForYourLeadershipPrincipleRating : props.ratingTabs === 'okr-review' ? YouCanUploadSupportingDocumentForYourFinalRating : OKRType === '1' ? YouMayUploadSupportingDocumentForTheRating : OKRType === '2' ? YouMayUploadSupportingDocumentForTheRating : ''} />
                      </span>
                    </div>
                    <div className="upload-document-link-add-interim add-rating-upload" style={{ margin: 10 }}>
                      <UploadDocuments
                        type={"rating-self"}
                        ratingId={props.KRData.ratingId}
                        allowmultiple={true}
                        selectedFiles={selectedFiles}
                        filesTimeStamp={selectedFilesTime}
                        fileList={ratingFiles}
                        apiUrl={'tmlcApiUrl'}
                        module={"LPOKR"}
                        cols={3}
                      />
                    </div>
                  </div>
                </div>
              </form>
            </div>
          }
        </DialogContent>
        <DialogActions>
          <div style={selectedTab !== 1 ? { padding: "0px 34px 16px 0px", float: "right" } : { padding: "0px 17px 16px 0px", float: "right" }}>
            <Button style={{ width: "128px" }} className="cancel-button" variant="contained" onClick={onCancel}>Cancel</Button>
            {selectedTab !== 1 && <Button style={{ width: "128px" }} className="remove-button-shadow" color="primary" variant="contained" onClick={onSave}>Save</Button>}
          </div>
        </DialogActions>
      </Dialog>
      <Dialog open={openBenchmarkPopup} fullWidth maxWidth={'sm'} onEscapeKeyDown={() => setOpenBenchmarkPopup(false)} >
        <DialogTitle>
          <span className="add-new-self-header col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 pt-sm " style={{
            color: "black",
            marginLeft: -5,
            paddingBottom: 0,
            fontFamily: "Circular Std ",
            fontSize: 18
          }}
          >
            Measurement Matrix
          </span>
          <span style={{ float: 'right', cursor: 'pointer', marginRight: -1, paddingTop: 12, position: 'absolute' }}>
            <FontAwesomeIcon style={{ color: '#B7B7BB', fontSize: 20 }} icon={faTimes}
              onClick={() => setOpenBenchmarkPopup(false)} />
          </span>
        </DialogTitle>
        <DialogContent style={{ padding: '0px 8px 24px 8px' }}>
          <BenchMarkTable benchMarkData={benchMarkData} unit={'view'} sortBy={""} />
        </DialogContent>
      </Dialog>
      <ConfirmationPopup
        showButton={{ type: "1" }}
        open={isModalOpen}
        button1function={() => setIsModalOpen(false)}
        button2function={() => setIsModalOpen(true)}
        headerMessage={"Please input at least one scenario in remark box."}
        button1Text={"OK"}
        button2Text={""}
        handleConfirmClose={() => setIsModalOpen(false)}
      />  
    </div>
  );
}
const mapStateToProps = ({ okrratingreducer, auth }) => {
  const { authUser } = auth
  const { empId, fyId, productivityallocationData, monthOfRating } = okrratingreducer
  return { authUser, empId, fyId, productivityallocationData, monthOfRating }
};
export default connect(mapStateToProps, { addEditSelfSupTPRatingRedux })(
  AddRatingModalkr
);


