/**
* Author Name : Somesh Dwivedi.
* Created Date: 26/10/2023
* To show leave type available for user
*/

import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import { useEffect, useState } from "react";
import { LeaveBalanceCard } from "./leaveBalanceCard";
import { updateLnAData } from '../../../../redux/actions/lna/lnaaction';
import { connect } from 'react-redux';
//Pagination button css
const sampleCss = `.leave-balance-pagination-button{
  background-color: white;
  width: 20px;
  height: 20px;
  text-align: center;
  font: normal normal normal 16px/16px Circular TT Book;
  letter-spacing: 0px;
  display:flex;
  justify-content:center;
  align-items:center;
  border: 1px solid #DBDBDD;
border-radius: 2px;
}`
//To render all the leave types
const LeaveTypes = (props) => {
    const { leaveData}=props;
    const [itemsPerPage, setItemsPerPage] = useState(7);
    const [showAll, setShowAll] = useState(false);

    const toggleItems = () => {
        setShowAll(!showAll);
        props.updateLnAData({showMore:!showAll});
    };
    const displayedItems = showAll ? leaveData : leaveData.slice(0, itemsPerPage);

    const getItemsPerPage = (innerWidth) => {
        if (innerWidth < 1540 && innerWidth > 1270) setItemsPerPage(5)
        if (innerWidth <= 1280) setItemsPerPage(4)
    };

    useEffect(() => {
        getItemsPerPage(window.innerWidth)
    }, [window.innerWidth])

    return (
        <>
            <style>{sampleCss}</style>
            <div className="leave-balance-card-container" style={{ display: 'flex', flexWrap: 'wrap' }}>
                {displayedItems && displayedItems.length > 0 && displayedItems.map((leave) => (
                    <LeaveBalanceCard key={leave.leaveName} leave={leave} />
                ))}
                {
                    leaveData.length > itemsPerPage &&
                    <TooltipComponent
                        position="BottomCenter"
                        className="pointer-cursor"
                        isSticky={false}
                        content={showAll ? 'Show Less' : 'Show More'}
                        cssClass='customtooltip'
                    >
                        <div
                            style={{ cursor: 'pointer', textAlign: 'center', display: 'flex', justifyContent: 'center', marginTop:67 }}
                            onClick={toggleItems}
                        >
                            <div style={{ cursor: 'pointer', color: 'black', fontSize: '25px', transform: `${showAll ? 'rotate(270deg)' : 'rotate(90deg)'}` }} className="leave-balance-pagination-button">&gt;</div>
                        </div>
                    </TooltipComponent>
                }
            </div>
        </>
    );
};
const mapStateToProps = ({  lnareducer }) => {
    const { showAll } = lnareducer;
  
    return {
      showAll
    }
  }
export default connect(mapStateToProps, { updateLnAData }) (LeaveTypes);
