// Author : Suresh R. Sargam
// Date : 25-Nov-2019
// Description: To Meeting Booking (Both Planned and actual)

import React, { Suspense } from "react";
import { connect } from "react-redux";
import dataService from "./../../../services/services";
import { AccordionComponent } from "@syncfusion/ej2-react-navigations";
import { RecurrenceEditorComponent } from "@syncfusion/ej2-react-schedule";
import { Button, Dialog } from "@material-ui/core";
import { FormValidator } from "@syncfusion/ej2-inputs";
import Paper from "@material-ui/core/Paper";
import Draggable from "react-draggable";
import NotificationContainer from "../../../components/notification/notifycontainer";
import Slide from "@material-ui/core/Slide";
import MeetingType from "../../../components/meetingtype/meetingtype";
import MeetingDetails from "../../../components/meetingdetails/meetingdetails";
import MeetingRoomAttendee from "../../../components/meetingroomattendee/meetingroomattendee";
import {
  DialogTitle,
  DialogContent,
  DialogActions,
} from "../../../components/customdialog/customdialog";
import { getDateFormat, getDate, getNotify, dencrypt, decodeString } from "../../other/commonfunction";
import {
  saveMeeting,
  updateMeetingBookingState,
  getMeetingDetails
} from "../../../redux/actions/meetingbookingaction";
import ErrorBoundary from "../../../components/error/errorboundary";
import { MainClass } from "../../../mainclass";
import {
  ERROR,
  meetingStartTimeMustBeLessThanEndTime,
  pleaseFillAllTheRequiredFields,
  pleaseSelectAppropriateMeetingServiceProduct,
  pleaseSelectAppropriateMeetingSource,
  pleaseSelectCampaignData,
  pleaseEnterMeetingBTBID,
  pleaseSelectClientName,
  pleaseEnterMeetingAgenda,
  pleaseSelectMeetingRoom,
  pleaseSelectChairPersonForThisMeeting,
  pleaseEnterAppropriateLinkDetail,
  pleaseEnterMeetingTitle,
  checkUnavailabilityOfuser,
  pleaseSelectAppropriateMeetingMedia,
  pleaseselectappropriateMeetingType,
  pleaseselectappropriateMeetingSubType,
  pleaseselectTimeZoneforthismeeting,
  pleaseselectMeetingEndTimeforthismeeting,
  pleaseselectMeetingStartTimeforthismeeting,
  pleaseselectMeetingDateforthismeeting,
  pleaseselectMeetingAttendeeforthismeeting,
  pleaseselectappropriateContactsforthismeeting,
  therearenoContactsfortheselectedEntity,
  endTimeMustBeLesserThanCurrentTime,
  pleaseselectappropriatesubcategory,
  pleaseselectappropriatejob,
  pleaseSelectAppropriateBtb,
  pleaseAddMinimumTwoParticipant,
  pleaseSelectMarketActivityData,
  pleaseSelectPracticeAreaData,
  pleaseEnterClientName,
  Cognition, WhatNext, oneOrMoreAttendeesAreAvailable, areYouSureUWantToSaveMeeting, meetingAcceptanceMsg, ClientMeetingsId, SelfBreakLeave, OPSBTBDropdown, OPSBTBClientMeet, SalesClientMeet
} from "../../../assets/constants/constants";

import ProgressBar from "../../../components/progressbar/progressbar";
import {
  defaultMeetingRoom,
  defMaxClientContact,
  defRecurringMaximumCount,
} from "../../../config/config";
import { OTHERS_CALENDAR } from "../../../redux/constant/meetingcategoryconstant";
import ConfirmationPopup from "../../../utilities/confirmationpopup";

const Transition = React.forwardRef(function Transition(props, ref) {
  // eslint-disable-next-line
  return <Slide direction="up" ref={ref} {...props} />;
});

function PaperComponent(props) {
  return (
    <Draggable cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper
        // eslint-disable-next-line
        {...props} />
    </Draggable>
  );
}

class MeetingBooking extends MainClass {
  constructor() {
    super();
    this.state = {
      counter: 0,
      changeField: "",
      open: false,
      title: meetingAcceptanceMsg,
      message: "",
      button1Caption: "Yes",
      button2Caption: "No",
      loader: true,
      updatedAttendees: []
    };
    this.handleClose = this.handleClose.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFieldChange = this.handleFieldChange.bind(this);
    this.handleEntered = this.handleEntered.bind(this);

  }

  componentDidMount() {
    this.setState({ counter: 0, loader: true });
  }


  handleClose = async () => {
    const { meetingbooking } = this.props
    const { meetingId, zoomMeetingId, oldZoomMeeting, templateListData } = meetingbooking

    // Author : Suresh Patel
    // Date : 04-01-2022
    // Description: Zoom Meeting Controller Function
    //Delete created zoom meeting when cancel //only work in create meeting
    if (meetingId === 0 && zoomMeetingId !== null) {

      await dataService.deleteZoomMeeting(zoomMeetingId, 0, meetingId)

    } else if (oldZoomMeeting !== null && meetingId !== 0 && zoomMeetingId !== null) /**nosonar**/ {
      await dataService.deleteZoomMeeting(zoomMeetingId, 0, meetingId)
    }

    if (oldZoomMeeting !== null && meetingId === 0) {
      await dataService.deleteZoomMeeting(oldZoomMeeting.zoomMeetingId, 0, meetingId)
    }

    if (oldZoomMeeting !== null) {
      const { meetingWebLink, participateCode, zoomMeetingHostEmailId } = oldZoomMeeting
      const zoomMeetingIdRedux = oldZoomMeeting.zoomMeetingId
      this.props.updateMeetingBookingState({ oldZoomMeeting: null, meetingWebLink, participateCode, zoomMeetingId: zoomMeetingIdRedux, zoomMeetingHostEmailId })
    }


    this.props.getMeetingDetails({
      meetingPopOpen: false,
      meetingTitle: "",
      contactsId: [],
      webLinkLoader: "",
      meetingWeblinkType: "",
      meetingAgenda: "",
      meetingActionItems: "",
      meetingRoomsId: defaultMeetingRoom,
      templateListData: templateListData
    });
  };

  getWebLinkType = (weblinktype) => {
    switch (weblinktype) {
      case "webex":
        return "1";
      case "skype":
        return "2";
      case "Zoom":
        return "3";
      case "Teams":
        return "4";
      default:
        return null;
    }
  };

  handleSubmit = async () => { // NOSONAR
    const { meetingbooking } = this.props
    // Author : Suresh Patel
    // Date : 04-01-2022
    // Description: Zoom Meeting Controller Function

    const deleteOldZoomMeeting = async (id, occurrence_id, meetingId) => {
      return await /**nosonar**/ dataService.deleteZoomMeeting(id, occurrence_id, meetingId);

    }
    if (meetingbooking.oldZoomMeeting !== null && meetingbooking.meetingId === 0) {
      const { zoomMeetingId } = meetingbooking.oldZoomMeeting
      await /**nosonar**/ deleteOldZoomMeeting(zoomMeetingId, 0, meetingbooking.meetingId)
    } else if (meetingbooking.oldZoomMeeting !== null && meetingbooking.meetingId !== 0) {
      const { zoomMeetingId } = meetingbooking.oldZoomMeeting
      if (meetingbooking.zoomOccurrenceId) {
        await /**nosonar**/ deleteOldZoomMeeting(zoomMeetingId, meetingbooking.zoomOccurrenceId, meetingbooking.meetingId)
      } else if (!meetingbooking.zoomOccurrenceId) {
        await /**nosonar**/ deleteOldZoomMeeting(zoomMeetingId, 0, meetingbooking.meetingId)
      }
    }
    if (meetingbooking.meetingWeblinkType === "Zoom" && meetingbooking.zoomMeetingId) {
      const validateZoomMeeting = await dataService.validateZoomMeeting(meetingbooking.zoomMeetingId);

      if (validateZoomMeeting !== 200) {
        getNotify(ERROR, 'Please input valid zoom meeting link.')
        return
      }
    }

    try {
      //This block of code is to check weather the partial attendee fall between the limit of meeting time
      if (this.props.meetingbooking.meetingAttendee.length > 1) {
        let out = false;
        const { meetingAttendee, meetingStartTime, meetingEndTime } = this.props.meetingbooking
        const meetingStartDateTime = new Date(meetingStartTime);
        const meetingEndDateTime = new Date(meetingEndTime);
        meetingAttendee.forEach(attendee => {
          if (attendee.split('_').length > 3) {
            let arr = attendee.split('_');
            let startDateTimeStr = arr[3];
            let endDateTimeStr = arr[4];
            let startDate = (startDateTimeStr.split(' ')[0]).split('-');
            let startTime = (startDateTimeStr.split(' ')[1]).split(':');
            let endDate = (endDateTimeStr.split(' ')[0]).split('-')
            let endTime = (endDateTimeStr.split(' ')[1]).split(':')
            const attendeeStartDateTime = new Date(startDate[0], ((startDate[1] * 1) - 1), startDate[2], startTime[0], startTime[1], startTime[2]);
            const attendeeEndDateTime = new Date(endDate[0], ((endDate[1] * 1) - 1), endDate[2], endTime[0], endTime[1], endTime[2]);
            const partialAttendeeOut = !(attendeeStartDateTime >= meetingStartDateTime && attendeeEndDateTime <= meetingEndDateTime)
            if (partialAttendeeOut) {
              out = true
            }
          }
        })
        if (out) {
          getNotify(ERROR, 'Please change the start time and end time of partial attendee.');
          return
        }

      }

      if (this.props.meetingbooking.meetingTypeId !== null && SelfBreakLeave.includes(this.props.meetingbooking.meetingTypeId)) {
        const { meetingAttendee } = this.props.meetingbooking
        if (meetingAttendee.length > 1) {
          getNotify(ERROR, 'Only one attendee is allowed for Break and Leave meeting type.')
          return
        } else if (meetingAttendee.length === 1) {
          let m_attendee = parseInt(meetingAttendee[0].split('_')[0]);
          const activeUser = this.props.categoryName === OTHERS_CALENDAR ? this.props.auth.otherUser : this.props.auth.authUser;
          if (activeUser.toString() !== m_attendee.toString()) {
            getNotify(ERROR, 'Attendee should be logged in user only.')
            return
          }
        }
      }


      const options = {
        rules: {
          "mttitle-txt": {
            required: [true, pleaseEnterMeetingTitle],
            // eslint-disable-next-line
            regex: ["[a-zA-Z0-9][\\sa-zA-Z0-9]", '* Enter valid meeting title.']
          },
          "meeting-date": {
            required: [true, pleaseselectMeetingDateforthismeeting],
          },
          "meeting-start-time": {
            required: [true, pleaseselectMeetingStartTimeforthismeeting],
          },
          "meeting-end-time": {
            required: [true, pleaseselectMeetingEndTimeforthismeeting],
          },
        },
      };

      let cnt = 0;

      if (this.props.meetingbooking.meetingTypeId === 0 || this.props.meetingbooking.meetingTypeId === "") {
        document.getElementById(
          "meetingtypeError"
        ).innerHTML = pleaseselectappropriateMeetingType;
        cnt++;
      }

      if (this.props.meetingbooking.isClientMeeting) {
        options.rules["cltz-select"] = {
          required: [true, pleaseselectTimeZoneforthismeeting],
        };
      } else {
        options.rules["tz-select"] = {
          required: [true, pleaseselectTimeZoneforthismeeting],
        };
      }
      if (!SelfBreakLeave.includes(this.props.meetingbooking.meetingTypeId)) {
        options.rules["magenda-txt"] = {
          required: [true, pleaseEnterMeetingAgenda],
          regex: [
            "[a-zA-Z0-9][\\sa-zA-Z0-9]",
            "* Please Enter valid meeting agenda.",
          ],
        };
      }

      if (document.getElementById("mst-select") !== null) {
        options.rules["mst-select"] = { required: [true, pleaseselectappropriateMeetingSubType], };
      }
      if (this.props.meetingbooking.meetingSourceType === 'Marketing') {
        if (document.getElementById("cd-select") !== null) {
          options.rules["cd-select"] = { required: [true, pleaseSelectCampaignData], };
          if (document.getElementById("pa-select") !== null) {
            options.rules["pa-select"] = { required: [true, pleaseSelectPracticeAreaData], };
          }
          if (document.getElementById("mar-select") !== null) {
            options.rules["mar-select"] = { required: [true, pleaseSelectMarketActivityData], };
          }
        }
      }

      if (document.getElementById("ms-select") !== null) {
        options.rules["ms-select"] = { required: [true, pleaseSelectAppropriateMeetingSource], };
      }

      if (dencrypt(localStorage.getItem('pnlId')) !== WhatNext) {
        if (document.getElementById("sp-select") !== null) {
          options.rules["sp-select"] = {
            required: [true, pleaseSelectAppropriateMeetingServiceProduct],
          };
        }
      }
      if (document.getElementById("btbid") !== null && OPSBTBClientMeet.includes(this.props.meetingbooking.meetingSubTypeId)) {
        options.rules.btbid = {
          required: [true, pleaseEnterMeetingBTBID],
        };
      }

      if (this.props.meetingbooking.meetingmode === "A" &&
        new Date(this.props.meetingbooking.meetingEndTime).getTime() >
        new Date().getTime()) {
        getNotify(ERROR, endTimeMustBeLesserThanCurrentTime);
        return;
      }
      if (!this.props.meetingbooking.isFullDay) {
        if (
          new Date(this.props.meetingbooking.meetingStartTime).getTime() >=
          new Date(this.props.meetingbooking.meetingEndTime).getTime()
        ) {
          options.rules["meeting-end-time"] = {
            required: [true, `*${meetingStartTimeMustBeLessThanEndTime}`],
          };
        }
      }

      if (!this.props.meetingbooking.isFullDay) {
        if (
          (new Date(this.props.meetingbooking.meetingStartTime).getTime() >=
            new Date(this.props.meetingbooking.meetingEndTime).getTime())
        ) {
          getNotify(ERROR, meetingStartTimeMustBeLessThanEndTime);
          document.getElementById(
            "meetingtimeError"
          ).innerHTML = meetingStartTimeMustBeLessThanEndTime;
          return;
        }
      }

      if (!SelfBreakLeave.includes(this.props.meetingbooking.meetingTypeId)) {
        options.rules["mm-select"] = {
          required: [true, pleaseSelectAppropriateMeetingMedia],
        };
      }

      if (ClientMeetingsId.includes(this.props.meetingbooking.meetingTypeId)) {
        if (
          this.props.meetingbooking.meetingSubTypeId === "" ||
          this.props.meetingbooking.meetingSubTypeId === 0
        )
          options.rules.subcategary = {
            required: [true, pleaseselectappropriatesubcategory],
          };
        else if (OPSBTBDropdown.includes(this.props.meetingbooking.meetingSubTypeId)) {
          if (this.props.meetingbooking.btbId === "" || this.props.meetingbooking.btbId === 0) {
            if (document.getElementById("selectbtblist") !== null)
              options.rules['selectbtblist'] = {
                required: [true, pleaseSelectAppropriateBtb],
              };
            else {
              cnt++;
            }
          } else if (
            this.props.meetingbooking.workTypeId === 5 &&
            (this.props.meetingbooking.subBTBId === "" ||
              this.props.meetingbooking.subBTBId === 0) &&
            dencrypt(localStorage.getItem("pnlId")) !== Cognition
          ) {
            options.rules['selectSubBTBId'] = {
              required: [true, pleaseselectappropriatejob],
            };
          }
        }
      }


      if (this.props.meetingbooking.meetingWeblinkType !== "") {
        options.rules["mlink-txt"] = {
          required: [true, pleaseEnterAppropriateLinkDetail],
        };
      }
      if (this.props.meetingbooking.meetingWebLink !== "") {
        options.rules["mlink-txt"] = {
          required: [true, pleaseEnterAppropriateLinkDetail],
        };
      }
      if (this.props.meetingbooking.meetingAttendee.length === 0) {
        document.getElementById(
          "maError"
        ).innerHTML = pleaseselectMeetingAttendeeforthismeeting;
        cnt += 1;
      } else if (this.props.meetingbooking.chairPersonId === 0 && !SelfBreakLeave.includes(this.props.meetingbooking.meetingTypeId)) {
        document.getElementById(
          "maError"
        ).innerHTML = pleaseSelectChairPersonForThisMeeting;
        cnt += 1;
      } else if (
        this.props.meetingbooking.meetingmode === "A" &&
        this.props.meetingbooking.busyUsers.length > 0
      ) {
        document.getElementById("maError").innerHTML = checkUnavailabilityOfuser;
        cnt += 1;
      } else if (
        this.props.meetingbooking.meetingAttendee.length < 2 &&
        this.props.meetingbooking.meetingTypeId === 6
      ) {
        document.getElementById("maError").innerHTML = pleaseAddMinimumTwoParticipant;
        cnt += 1;
      }
      if (OPSBTBClientMeet.includes(this.props.meetingbooking.meetingSubTypeId) || SalesClientMeet.includes(this.props.meetingbooking.meetingSubTypeId)) {
        if (document.getElementById("search-client") !== null && this.props.meetingbooking.client_nm !== "") {
          options.rules["search-client"] = {
            required: [true, pleaseEnterClientName],
          };
        } else if (
          this.props.meetingbooking.isClientMeeting &&
          this.props.meetingbooking.clientId === 0
        ) {
          if (document.getElementById("clientError") !== null) {
            document.getElementById(
              "clientError"
            ).innerHTML = pleaseSelectClientName;
            cnt++;
          }
        }


        if (this.props.meetingbooking.contactsId.length === 0 &&
          this.props.meetingbooking.clientId !== 0
        ) {
          if (document.getElementById("contactError") !== null) {
            document.getElementById(
              "contactError"
            ).innerHTML = pleaseselectappropriateContactsforthismeeting;
            cnt += 1;
          } else if (document.getElementById("clientError") !== null) {
            document.getElementById(
              "clientError"
            ).innerHTML = therearenoContactsfortheselectedEntity;
            cnt += 1;
          }
        }
        if (
          this.props.meetingbooking.contactsId.length > defMaxClientContact &&
          this.props.meetingbooking.clientId !== 0
        ) {
          if (document.getElementById("contactError") !== null) {
            document.getElementById(
              "contactError"
            ).innerHTML = ` * Maximum ${defMaxClientContact.toString()} contact(s) are allowed for selection.`;
            cnt += 1;
          }
        }
      }

      if (dencrypt(localStorage.getItem("pnlId")) !== WhatNext) {
        if (OPSBTBClientMeet.includes(this.props.meetingbooking.meetingSubTypeId)) {
          if (this.props.meetingbooking.btbId === "" || this.props.meetingbooking.btbId === 0) {
            if (document.getElementById("selectbtblist") !== null) {
              options.rules['selectbtblist'] = {
                required: [true, pleaseSelectAppropriateBtb],
              };
            } else {
              cnt++;
            }
          }
          if ((this.props.meetingbooking.workTypeId === 5) &&
            dencrypt(localStorage.getItem("pnlId")) !== Cognition &&
            (this.props.meetingbooking.subBTBId === 0 || this.props.meetingbooking.subBTBId === "" || this.props.meetingbooking.subBTBId === null) && document.getElementById('opsJobError') !== null) {
            options.rules['selectSubBTBId'] = {
              required: [true, pleaseselectappropriatejob],
            };
          }
        }
      }
      // initialize the form validator
      this.formObject = new FormValidator("#meetingbookingfrm", options);
      if (this.formObject.validate() && cnt === 0) {
        let msg = areYouSureUWantToSaveMeeting;
        if (this.props.meetingbooking.attendeeOnBreak.length > 0) {
          msg = oneOrMoreAttendeesAreAvailable;
          this.setState({
            button1Caption: "Yes",
            button2Caption: "No",
            title: "Save Meeting",
            message: msg,
            open: true,
          });
        } else {
          this.handleConfirmYes();
        }
      } else {
        getNotify(ERROR, pleaseFillAllTheRequiredFields);
      }
    } catch (error) {
      console.log(error)
    }
  };

  componentWillReceiveProps(newProps) {
    if (newProps.meetingbooking.meetingStatus === "error") {
      this.setState({ loader: false });
    }

    if (newProps.open === true) {
      this.setState({ loader: true });
    }
  }

  handleFieldChange(field) {
    this.setState({ changeField: field.changeField });
  }

  onCreated = () => {
    setTimeout(() => {
      if (this.acrdnObj !== null) {
        for (let i = 0; i <= this.acrdnObj.items.length - 1; i += 1) {
          this.acrdnObj.items[i].expanded = true;
        }
        this.setState({ loader: false });
      }
    }, 500);
  };

  // Meeting Save
  handleConfirmYes = () => { // NOSONAR
    this.setState({ open: false, loader: true });
    // Save the meeting
    const mdtMeetingStartDate = getDateFormat(
      new Date(this.props.meetingbooking.meetingDate),
      new Date(this.props.meetingbooking.meetingStartTime)
    );
    const mdtMeetingEndDate = getDateFormat(
      new Date(this.props.meetingbooking.meetingDate),
      new Date(this.props.meetingbooking.meetingEndTime)
    );

    let mvarAttendee = "";
    this.props.meetingbooking.meetingAttendee.forEach(
      // eslint-disable-next-line
      (item) =>
      (mvarAttendee = item.split('_').length > 3 ? `${mvarAttendee + item.split("_")[0]}_${item.split("_")[1]
        }_${item.split("_")[2]}_${item.split("_")[3]}_${item.split("_")[4]}$$` : `${mvarAttendee + item.split("_")[0]}_${item.split("_")[1]
        }_${item.split("_")[2]}$$`)
      // (mvarAttendee = `${mvarAttendee + item.split("_")[0]}_${item.split("_")[1]
      // }_${item.split("_")[2]}}$$`)
    );

    const data = {
      bulkAttendeesFlag: this.props.meetingbooking.bulkAttendeesFlag,
      user_id:
        this.props.categoryName === OTHERS_CALENDAR
          ? this.props.auth.otherUser
          : this.props.auth.authUser,
      meeting_id: this.props.meetingbooking.meetingId,
      meeting_title: this.props.meetingbooking.meetingTitle,
      meeting_start_date: mdtMeetingStartDate,
      meeting_end_date: mdtMeetingEndDate,
      meeting_type_id:
        this.props.meetingbooking.meetingSubTypeId === ""
          ? this.props.meetingbooking.meetingTypeId
          : this.props.meetingbooking.meetingSubTypeId,
      meeting_source_id:
        this.props.meetingbooking.meetingSourceId === ""
          ? null
          : this.props.meetingbooking.meetingSourceId,

      meeting_media_id:
        this.props.meetingbooking.meetingMediaId === ""
          ? null
          : this.props.meetingbooking.meetingMediaId,
      meeting_weblink_type: this.getWebLinkType(
        this.props.meetingbooking.meetingWeblinkType
      ),
      meeting_weblink:
        this.props.meetingbooking.meetingWebLink === ""
          ? null
          : this.props.meetingbooking.meetingWebLink,
      participate_code:
        this.props.meetingbooking.participateCode === ""
          ? null
          : this.props.meetingbooking.participateCode,
      time_zone_id: this.props.meetingbooking.timeZoneId,
      meeting_rooms_id:
        // (SelfBreakLeave.includes(this.props.meetingbooking.meetingTypeId)) &&
        this.props.meetingbooking.meetingRoomsId.length === 0
          ? "87"
          : this.props.meetingbooking.meetingRoomsId.toString(),
      other_attendees:
        this.props.meetingbooking.otherAttendees === ""
          ? null
          : this.props.meetingbooking.otherAttendees,
      meeting_agenda: this.props.meetingbooking.meetingAgenda,
      meeting_actionitems: this.props.meetingbooking.meetingActionItems,
      meeting_setter_id: this.props.auth.authUser,
      chairperson_id: this.props.meetingbooking.chairPersonId,
      client_id:
        // eslint-disable-next-line
        parseInt(this.props.meetingbooking.clientId) === 0
          ? null
          : // eslint-disable-next-line
          parseInt(this.props.meetingbooking.clientId),
      contacts_id:
        this.props.meetingbooking.contactsId.length > 0
          ? this.props.meetingbooking.contactsId.toString()
          : null,
      service_products_id:
        this.props.meetingbooking.serviceProductsId.length > 0
          ? this.props.meetingbooking.serviceProductsId.toString()
          : null,
      btb_id:
        this.props.meetingbooking.btbId === ""
          ? null
          : this.props.meetingbooking.btbId,
      sub_btb_id:
        this.props.meetingbooking.subBTBId === ""
          ? null
          : this.props.meetingbooking.subBTBId,

      sub_cat_id:
        this.props.meetingbooking.subCatId === ""
          ? null
          : this.props.meetingbooking.subCatId,
      business_impact:
        this.props.meetingbooking.businessImpact === ""
          ? null
          : this.props.meetingbooking.businessImpact,
      is_marketing_meeting:
        this.props.meetingbooking.clientmode === "MD" ? "1" : null,
      meeting_location:
        this.props.meetingbooking.meetingLocation === ""
          ? null
          : this.props.meetingbooking.meetingLocation,
      meeting_attendee: mvarAttendee,
      meetingmode: this.props.meetingbooking.meetingmode,
      is_full_day: this.props.meetingbooking.isFullDay ? "1" : null,
      user_type: decodeString(localStorage.getItem("user_type")),
      countries_id:
        this.props.meetingbooking.countriesId.length > 0
          ? this.props.meetingbooking.countriesId.toString()
          : null,
      meeting_category_id:
        this.props.meetingbooking.meetingCategoryId === ""
          ? null
          : this.props.meetingbooking.meetingCategoryId,
      // agendaDocument:
      //   this.props.meetingbooking.agendaDocument === ""
      //     ? null
      //     : this.props.meetingbooking.agendaDocument,
      getExcludeSundayHoliday:
        this.props.meetingbooking.getExcludeSundayHoliday === 0 ||
          this.props.meetingbooking.getExcludeSundayHoliday === null
          ? null
          : this.props.meetingbooking.getExcludeSundayHoliday,
      campaign_id: this.props.meetingbooking.campaignId === '' ? null : this.props.meetingbooking.campaignId,
      campaign_name: this.props.meetingbooking.campaignName === '' ? null : this.props.meetingbooking.campaignName,


      zoomMeetingId:
        this.props.meetingbooking.zoomMeetingId === "" || this.props.meetingbooking.zoomMeetingId === null
          ? null
          : this.props.meetingbooking.zoomMeetingId,
      zoomMeetingHostEmailId:
        this.props.meetingbooking.zoomMeetingHostEmailId === "" || this.props.meetingbooking.zoomMeetingHostEmailId === null
          ? null
          : this.props.meetingbooking.zoomMeetingHostEmailId,
      zoomOccurrences: this.props.meetingbooking.zoomOccurrences === "" || this.props.meetingbooking.zoomOccurrences === null
        ? null
        : this.props.meetingbooking.zoomOccurrences,
      zoomOccurrenceId: this.props.meetingbooking.zoomOccurrenceId === "" || this.props.meetingbooking.zoomOccurrenceId === null
        ? null
        : this.props.meetingbooking.zoomOccurrenceId,
    };

    if (this.props.meetingbooking.meetingmode === "P") {
      const arrDates = [];
      if (
        this.props.meetingbooking.meetingFrequency === 2 &&
        this.props.meetingbooking.recurring !== ""
      ) {
        const dates = this.recObject.getRecurrenceDates(
          new Date(this.props.meetingbooking.meetingDate),
          this.props.meetingbooking.recurring,
          null,
          defRecurringMaximumCount,
          null
        );

        for (let dt of dates) {
          arrDates.push(getDate(new Date(dt)));
        }
      }
      data.meeting_frequency = this.props.meetingbooking.meetingFrequency;
      data.recurring =
        this.props.meetingbooking.recurring === ""
          ? null
          : this.props.meetingbooking.recurring;
      data.meeting_dates = arrDates.length > 0 ? arrDates.toString() : null;
    }

    if (
      this.props.meetingbooking.meetingId > 0 &&
      this.props.meetingbooking.meetingmode === "P"
    ) {
      data.series_id =
        this.props.meetingbooking.seriesId === 0
          ? null
          : this.props.meetingbooking.seriesId;
    }
    this.props.saveMeeting(data);
    this.props.updateMeetingBookingState({ webLinkLoader: "2", attendeeOnBreak: [] });
  };

  handleConfirmNo = () => {
    this.setState({ open: false, loader: false });
  };

  handleConfirmClose = () => {

    this.setState({ open: false, loader: false });
  };

  handleEntered = () => {
    setTimeout(() => {
      if (document.getElementById("sp-select") !== null) {
        document.getElementById("sp-select").ej2_instances[0].refresh();
      }

      if (document.getElementById("country-select") !== null) {
        document.getElementById("country-select").ej2_instances[0].refresh();
      }

      if (document.getElementById("mr-select") !== null) {
        document.getElementById("mr-select").ej2_instances[0].refresh();
      }
    }, 500);
  };

  meetingAttendeeList = (attendees) => {
    this.setState({ updatedAttendees: attendees });
  };

  render() {
    const duration = {
      enteringScreen: 500,
      leavingScreen: 500,
    };
    const titleStatus = this.props.meetingbooking.meetingEditAccess === "Y" ? "Edit " : "View "
    return (
      <div>
        <ErrorBoundary>
          <Dialog
            onClose={this.handleClose}
            aria-labelledby="meeting-booking-dialog-title"
            open={this.props.meetingbooking.meetingPopOpen}
            fullWidth
            onEntered={this.handleEntered}
            maxWidth="lg"
            TransitionComponent={Transition}
            disableEscapeKeyDown
            transitionDuration={{
              enter: duration.enteringScreen,
              exit: duration.leavingScreen,
            }}
            PaperComponent={PaperComponent}
          >
            <DialogTitle
              id="meeting-booking-dialog-title"
              onClose={this.handleClose}
            >
              {
                // eslint-disable-next-line
                this.props.meetingbooking.meetingId === 0 ? "Add " : titleStatus
              }{" "}
              Meeting
            </DialogTitle>
            <DialogContent dividers>
              {this.props.meetingbooking.meetingPopOpen && (
                <NotificationContainer />
              )}
              <form id="meetingbookingfrm" method="post">
                <div>{this.state.loader && <ProgressBar />}</div>
                <div style={{ display: this.state.loader ? "none" : "block" }}>
                  <Suspense fallback={<div>Loading..... </div>}>
                    <AccordionComponent
                      // eslint-disable-next-line
                      ref={(accordion) => (this.acrdnObj = accordion)}
                      created={this.onCreated}
                    >
                      {
                        <MeetingType meetingAttendeeList={this.meetingAttendeeList} onCreated={this.onCreated} showTemplate={this.props.meetingbooking.meetingId === 0} />
                      }
                      {
                        <MeetingDetails onChange={this.handleFieldChange} />
                      }
                      {
                        <MeetingRoomAttendee
                          updatedAttendees={this.state.updatedAttendees}
                          getChangedField={this.state.changeField}
                        />
                      }

                    </AccordionComponent>
                  </Suspense>
                </div>
              </form>
            </DialogContent>
            <DialogActions>
              {this.props.meetingbooking.meetingEditAccess === "Y" &&
                !this.state.loader && (
                  <Button
                    autoFocus
                    onClick={this.handleSubmit}
                    color="primary"
                    variant="contained"
                    title="Save changes"
                  >
                    Save changes
                  </Button>
                )}

              <Button
                autoFocus
                onClick={this.handleClose}
                color="secondary"
                variant="contained"
                title="Close"
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>

          <div style={{ display: "none" }}>
            <div id="rule-output" />
            <RecurrenceEditorComponent
              id="RecurrenceEditor"
              // eslint-disable-next-line
              ref={(t) => (this.recObject = t)}
              frequencies={["daily", "weekly", "monthly", "yearly"]}
              dateFormat="DD-MM-YYYY"
              minDate={new Date(this.props.meetingDate)}
              firstDayOfWeek={1}
              maximumCount={defRecurringMaximumCount}
            />
          </div>
          <ConfirmationPopup
            showButton={{ type: "1" }}
            open={this.state.open}
            button1function={() => this.handleConfirmYes()}
            button2function={() => this.handleConfirmNo()}
            headerMessage={this.state.message}
            button1Text={this.state.button1Caption}
            button2Text={this.state.button2Caption}
            handleConfirmClose={this.handleConfirmClose}
          />  
        </ErrorBoundary>
      </div>
    );
  }
}

const mapStateToProps = ({ auth, meetingbooking, meetingcategory }) => {
  return { meetingbooking, auth, meetingcategory };
};

export default connect(mapStateToProps, {
  saveMeeting,
  updateMeetingBookingState,
  getMeetingDetails
})(MeetingBooking);
