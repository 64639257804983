/**
 * Author: Suresh R. Sargam.
 * Description: To drag/drop or file selection screen
 * Last Modify:15-May-2020
 */
import React, { Component } from "react";
import PropTypes from "prop-types";
import "./dropzone.css";
import { getNotify } from "../../../app/other/commonfunction";
import {
  WARNING,
  FileSize,
  FileType,
} from "../../../assets/constants/constants";
import { defFileType, maxFileSize } from "../../../config/config";

class Dropzone extends Component {
  constructor(props) {
    super(props);
    const { allowmultiple } = this.props;
    this.state = {
      hightlight: false,
      allowmultiple,
    };
    this.fileInputRef = React.createRef();

    this.openFileDialog = this.openFileDialog.bind(this);
    this.onFilesAdded = this.onFilesAdded.bind(this);
    this.onDragOver = this.onDragOver.bind(this);
    this.onDragLeave = this.onDragLeave.bind(this);
    this.onDrop = this.onDrop.bind(this);
  }

  onDragOver(event) {
    event.preventDefault();
    const { disabled } = this.props;
    if (disabled) return;
    this.setState({ hightlight: true });
  }

  onDragLeave() {
    this.setState({ hightlight: false });
  }

  onDrop(event) {
    event.preventDefault();
    const { disabled } = this.props;
    if (disabled) return;
    const { files } = event.dataTransfer;
    if (this.props.onFilesAdded) {      
      const array = this.fileListToArray(files);
      this.props.onFilesAdded(array);
    }
    this.setState({ hightlight: false });
  }

  /**
   * @author Suresh R. Sargam
   * @description fired this event once the file is added
   */
  onFilesAdded(evt) { //NOSONAR
    const { disabled, categoryName } = this.props;
    if (disabled) return;
    if (evt.target.files.length > 0 &&
      typeof evt.target.files[0] !== undefined && typeof evt.target.files[0].size !== undefined &&
      typeof evt.target.files[0].type !== undefined) {
      const size = evt.target.files[0].size / 1024;
      const filetype = evt.target.files[0].type;
      if (!defFileType.includes(filetype) || filetype === '') {
        if (categoryName === 'GOVERNMENT DOCUMENTS') {
          getNotify(WARNING, FileType)
          return;
        } else {
          getNotify(WARNING, 'Please select pdf format files only.')
          return;
        }
      }
      else if (!(size > 0 && size < maxFileSize)) {
        getNotify(WARNING, FileSize);
      }
      else {
        if (categoryName !== 'GOVERNMENT DOCUMENTS') {
          let otherTyps = ['application/pdf'];
          if (!otherTyps.includes(filetype)) {
            getNotify(WARNING, 'Please select pdf format files only.')
            return;
          }
        }
        const { files } = evt.target;
        if (this.props.onFilesAdded) {
          const array = this.fileListToArray(files);
          this.props.onFilesAdded(array);
        }
      }
    }
  }

  /**
   * @author Suresh R. Sargam
   * @description Add files to list of array
   */
  fileListToArray(list) {
    const { folderPath } = this.props;
    const array = [];
    const items = list;
    for (let i = 0; i < items.length; i += 1) {
      items.item(i).folderPath = folderPath;
      array.push(items.item(i));
    }
    return array;
  }

  /**
   * @author Suresh R. Sargam
   * @description to open file dialog
   */
  openFileDialog() {
    const { disabled } = this.props;
    if (disabled) return;
    this.fileInputRef.current.click();
  }

  render() {
    const { hightlight, allowmultiple } = this.state;
    const { disabled } = this.props;
    return (
      <div
        className={`Dropzone ${hightlight ? "Highlight" : ""}`}
        onDragOver={this.onDragOver}
        onDragLeave={this.onDragLeave}
        onDrop={this.onDrop}
        onClick={this.openFileDialog}
        onKeyDown={this.openFileDialog}
        style={{ cursor: disabled ? "default" : "pointer" }}
        role="button"
        tabIndex={0}
      >
        {allowmultiple ? (
          <input
            ref={this.fileInputRef}
            className="FileInput"
            style={{ display: "none" }}
            type="file"
            multiple
            accept="image/jpeg,image/png,image/jpg,application/pdf"
            onChange={this.onFilesAdded}
          />
        ) : (
          <input
            ref={this.fileInputRef}
            className="FileInput"
            style={{ display: "none" }}
            type="file"
            accept="image/jpeg,image/png,image/jpg,application/pdf"
            onChange={this.onFilesAdded}
          />
        )}
        <img
          alt="upload"
          className="Icon"
          src="images/baseline-cloud_upload-24px.svg"
        />
        <span>Upload Files</span>
      </div>
    );
  }
}

Dropzone.propTypes = {
  disabled: PropTypes.bool.isRequired,
  onFilesAdded: PropTypes.func.isRequired,
  allowmultiple: PropTypes.bool,
  folderPath: PropTypes.string.isRequired,
};
Dropzone.defaultProps = {
  allowmultiple: false,
};

export default Dropzone;
