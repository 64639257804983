import React, { Component } from "react";
import { connect } from 'react-redux';
import {
    ChartComponent,
    SeriesCollectionDirective,
    SeriesDirective,
    Inject,
    LineSeries,
    DateTime,
    Legend,
    Category,
    ColumnSeries,
    DataLabel,
    Tooltip,
} from '@syncfusion/ej2-react-charts';
import services from '../../services/okr/okrservices';
import { addUpdateOkrDashboardRedux } from '../../redux/actions/okrdashboardaction/okrdashboardaction';
import { getRowHeight } from "@syncfusion/ej2-react-grids";
import ProgressBar from "../progressbar/progressbar";

export let materialColors = [
    '#00bdae',
    '#404041',
    '#357cd2',
    '#e56590',
    '#f8b883',
    '#70ad47',
    '#dd8abd',
    '#7f84e8',
    '#7bb4eb',
    '#ea7a57',
    '#404041',
    '#00bdae',
];

const SAMPLE_CSS = `
    .control-fluid {
        padding: 0px !important;
    }
        .charts {
            align :center
        }`;
class Graph extends Component {
    constructor(props) {
        super(props)
        this.state = {
            chartData: [],
            cummlativeData: '',
            loading: true
        }
        this.months = ["Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec", "Jan", "Feb", "Mar"]
    }

    componentDidMount() {
        const {  fyId } = this.props;
        this.performancePotentialGraphData(fyId);
        // this.getBlueBox(fyId, authUser, empId, monthOfRating)
    }

    componentWillReceiveProps = (newProps) => {
        if (newProps.graphStatus === 'updated') {
            this.setState({ loading: true });
            this.performancePotentialGraphData(newProps.fyId);
            this.props.addUpdateOkrDashboardRedux({ graphStatus: '' })
        }
    }

    performancePotentialGraphData = async (fyId) => {

        const { authUser, empId, monthOfRating } = this.props
        let chartMasterData = await services.getOKRDashboardData('okrchartdata', authUser, empId, fyId, monthOfRating);
        this.setState({ chartData: chartMasterData, loading: false });
    }
    
    getControlHeight=()=>{
        if (this.props.showClassification === '1' && window.innerHeight === 625 && this.props.showMonths) {
            return 263
        }else if (this.props.showClassification === '1' && window.innerHeight === 625 && !this.props.showMonths) {
            return 287
        }else if ((this.props.showClassification === '0' || this.props.showClassification === undefined) && window.innerHeight === 625) {
            return 366
        }else if (this.props.showClassification === '1' && window.innerHeight > 657) {
            return 437
        }else if (this.props.showClassification === '0' && window.innerHeight > 657) {
            return 552
        }else if (this.props.showClassification === '1' && window.innerHeight <= 657) {
            return 295
        }else if ((this.props.showClassification === '0' || this.props.showClassification === undefined) && window.innerHeight === 625 && this.props.showMonths) {
            return 364
        }else if ((this.props.showClassification === '0' || this.props.showClassification === undefined) && window.innerHeight <= 657) {
            return 397
        }
    }

    getHeight = (type) => {
        const {dashboardTabs} = this.props
        if (type === 'Graph') {
            if(window.innerHeight > 670 && this.props.showClassification === '0'){ 
                return 547
            }
            if(window.innerHeight > 670 && this.props.showClassification === '1'){
                return 431
            }
            if (window.innerHeight === 657 && dashboardTabs==='team-member-rating'&& this.props.showClassification === '1' ) {
                return 295
            }
            if ((window.innerHeight === 657 && dashboardTabs==='team-member-rating'&& this.props.showClassification === '0' ) || 
            (window.innerHeight === 657 && dashboardTabs==='self-rating'&& this.props.showClassification === '0')){
                return 396
            }
            if (window.innerHeight === 657 && dashboardTabs==='self-rating'&& this.props.showClassification === '1') {
                return 295
            }
            if( (window.innerHeight === 625 && dashboardTabs==='team-member-rating'&& this.props.showClassification === '1') || 
            (window.innerHeight === 625 && dashboardTabs==='self-rating'&& this.props.showClassification === '1')) {
                return 260
            }
            if ((window.innerHeight === 625 && dashboardTabs==='team-member-rating'&& this.props.showClassification === '0') ||
             (window.innerHeight === 625 && dashboardTabs==='self-rating'&& this.props.showClassification === '0') ) {
                return 362
            }     
        }

    }

    labelRender = (args) => {
        args.fill = materialColors[args.point.index % 10];
    }

    chartLoaded = (args) => {

        let chart = document.getElementById("charts2");

        chart.style.overflow = 'visible';

    };

    render() {
        const { loading } = this.state
        return (<div className='control-pane'>
            <style>
                {SAMPLE_CSS}
            </style>
            {this.state.chartData.length > 0 && !loading &&
                <div className='control-section' style={{ border: "1px solid #CCCCCC", borderRadius: '5px' }}>
                    <ChartComponent
                        className='graphView'
                        id="charts2"
                        style={{ textAlign: "center", width: window.innerHeight < 670 ? "30rem" : '46rem', height: this.getHeight('Graph') }}
                        primaryXAxis={{
                            valueType: 'Category',
                            interval: 1,
                            tickPosition: 'Inside',
                            labelPosition:'Outside',
                            edgeLabelPlacement: 'Shift',
                            majorGridLines: { width: 0 },
                            labelStyle: { color: 'black' },
                        }}
                        primaryYAxis={{
                            rangePadding: 'None',
                            minimum: 0,
                            maximum: 5,
                            interval: 1,
                            majorGridLines: { width: 0 },
                            majorTickLines: { width: 0 },
                            minorTickLines: { width: 0 },
                            lineStyle: { width: 0 },
                            labelStyle: { color: '#000' },
                        }}
                        chartArea={{ border: { width: 0 } }}
                        title='Performance Cumulative Score Trend'
                        legendSettings={{ visible: false }}
                        tooltip={{ enable: true }}
                    >
                        <Inject services={[LineSeries, ColumnSeries, DateTime, Legend, Category, DataLabel, Tooltip]} />
                        <SeriesCollectionDirective>
                            <SeriesDirective dataSource={this.state.chartData}
                                type="Column"
                                xName="x"
                                width={"10px"}
                                columnWidth={0.2}
                                yName="y"
                                name="Score"
                                fill={"#F5F5F5"}
                            >
                            </SeriesDirective>
                            <SeriesDirective dataSource={this.state.chartData}
                                xName="x"
                                yName="y"
                                name="Score"
                                width={2}
                                fill={"#F94F5E"}
                                marker={{
                                    visible: true,
                                    width: 10,
                                    height: 10,
                                    dataLabel: {
                                        visible: true,
                                        template: '<div style="font-size: 1rem; font-weight: 500;"><div>${point.y}</div></div>'
                                    }
                                }}
                                type="Line"
                            >
                            </SeriesDirective>
                        </SeriesCollectionDirective>
                    </ChartComponent>
                </div>}
            {this.state.chartData.length === 0 && !loading && <div className='control-section' style={{
                // border: "1px solid #CCCCCC", 
                borderRadius: '5px', textAlign: 'center', height: this.getHeight()
            }}
            >
                <div style={{ fontWeight: 'bolder', marginTop: '180px', fontSize: 'larger', color: 'Grey' }}>No Data</div>
            </div>}
            {
            loading && 
            <div style={{marginTop: 200}}> <ProgressBar /></div>
            }

        </div>);
    }
}

const mapStateToProps = ({ okrdashboardreducer, auth, meetingmaster }) => {
    const { authUser } = auth;
    const { finacialYearData } = meetingmaster;
    const { dashboardTabs, fyId, teamMemberIds, monthOfRating, empId, monthlyOkrMonthStatus, viewCard, isPeopleLeader, graphStatus } = okrdashboardreducer;
    return { dashboardTabs, authUser, monthOfRating, fyId, teamMemberIds, empId, finacialYearData, monthlyOkrMonthStatus, viewCard, isPeopleLeader, graphStatus }
};
export default connect(mapStateToProps, { addUpdateOkrDashboardRedux })(
    Graph
);
