/**
 * Author: Mukesh.
 * Created:11-Jan-2023
 * Last Modify:11-Jan-2023
 * Modified By:Mukesh
 */
import * as React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import {
  faAngleUp,
  faEyeSlash,
  faAngleDown,
} from "@fortawesome/free-solid-svg-icons";
import "./style.css";
import { ComboBoxComponent } from "@syncfusion/ej2-react-dropdowns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import services from "../../services/services";
import { connect } from "react-redux";
import { setOrhChartDetaila } from "../../redux/actions/entryexit/orgchartaction";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { ReactComponent as ReportviewIcon } from "./../../assets/svg/reporting-org.svg";
import { ReactComponent as TeamviewIcon } from "./../../assets/svg/team-org.svg";
import { ReactComponent as StructureIcon } from "./../../assets/svg/strucure-org.svg";
import { urlConfig } from "../../config/config";
import { decodeString } from "../../app/other/commonfunction";
import { SwitchComponent } from "@syncfusion/ej2-react-buttons";
import ErrorBoundary from "../error/errorboundary";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      <Box p={3}>{children}</Box>
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
  },
  tabs_charttitle: {
    border: "1px solid #ccc",
    borderRadius: "4px",
    color: "#000",
    fontSize: "16px",
    fontWeight: "500",
  },
  MuiTabs_flexContainer: {
    backgroundColor: "#fff !important",
  },

  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const HeaderTab = ({
  setlayout,
  orgView,
  handleViewChange,
  onHandlerView,
  authUser,
  hideShow,
  handleShowChanges,
  filterHandler,
  OrgChartHandler,
  teamOrgChartHandler,
  structOrgChartHandler,
  setLoaderVal,
  setUserAccessHandler,
}) => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [teamMemberList, setTeamMberList] = React.useState([]);
  const [BuGroup, SetBuGroup] = React.useState([]);
  const [showOrgchatHeader, setOrgchatHeader] = React.useState(true);
  const [teamForReport, setTeamForReport] = React.useState(null);
  const [teamNameForReport, setTeamNameForReport] = React.useState(
    decodeString(localStorage.getItem("user_nm"))
  );
  const [groupForReport, setGroupForReport] = React.useState("");
  const [groupNameForReport, setGroupNameForReport] = React.useState("");

  const [team, setTeam] = React.useState(authUser);
  const [teamName, setTeamName] = React.useState(
    decodeString(localStorage.getItem("user_nm"))
  );
  const [group, setGroup] = React.useState("");
  const [groupName, setGroupName] = React.useState("");

  const [groupForStructure, setGroupForStructure] = React.useState("");
  const [groupForStructureName, setGroupForStructureName] = React.useState("");

  const [dateVal, setDateVal] = React.useState(new Date().toDateString());
  const [upComTeam, setupComTeam] = React.useState(0);
  const [dropFlag, setDropFlag] = React.useState(true);
  const [minDate, setMinDate] = React.useState(new Date());
  const [tabAccessRole, setTabAccessRole] = React.useState([
    { Id: 1, Name: "No" },
    { Id: 2, Name: "No" },
    { Id: 3, Name: "No" },
    { Id: 4, Name: "No" },
    { Id: 5, Name: "No" },
    { Id: 6, Name: "No" },
  ]);
  const [firstLoad, setFirstLoad] = React.useState(true);

  const handleChange = (event, newValue) => {
    if (
      tabAccessRole[0].Name === "No" &&
      tabAccessRole[1].Name === "Yes" &&
      tabAccessRole[2].Name === "Yes"
    ) {
      newValue = parseInt(newValue + 1);
    } else if (
      tabAccessRole[0].Name === "No" &&
      tabAccessRole[1].Name === "No" &&
      tabAccessRole[2].Name === "Yes"
    ) {
      newValue = parseInt(newValue + 2);
    } else if (
      tabAccessRole[0].Name === "No" &&
      tabAccessRole[1].Name === "Yes" &&
      tabAccessRole[2].Name === "No"
    ) {
      newValue = parseInt(newValue + 1);
    } else if (
      tabAccessRole[0].Name === "Yes" &&
      tabAccessRole[1].Name === "No" &&
      tabAccessRole[2].Name === "Yes"
    ) {
      newValue = newValue === 0 ? 0 : 2;
    }

    setValue(newValue);
    if (newValue === 2) {
      onHandlerView(3, true);
      setlayout(90);
    } else if (newValue === 1) {
      if (group) {
        setTeam("");
        setTeamName("");
      }
      onHandlerView(2, true);
      setlayout(0);
    } else {
      if (groupForReport) {
        setTeamForReport("");
        setTeamNameForReport("");
      }
      onHandlerView(1, true);
      setlayout(90);
    }
    setFilterValue();
  };

  const getReportingData = (
    teamId = "",
    businessId = "",
    dateVals = "",
    umtype = ""
  ) => {
    let userId = authUser;
    let dateMonth = 0;
    let buId = businessId ? businessId : 0;
    let memberId = teamId ? teamId : userId;
    let memberType = 0;
    let status = "1";
    let umTeam = umtype ? "1" : "0"; // Show 1 hide 0
    if (dateVals) {
      let newdate = new Date(dateVals);
      let mVal =
        newdate.getMonth() + 1 > 9
          ? newdate.getMonth() + 1
          : "0" + (newdate.getMonth() + 1);
      dateMonth = newdate.getFullYear() + "-" + mVal;
    }
    services
      .getOrgChartDataFilter(
        "reportingView",
        userId,
        memberId,
        buId,
        memberType,
        status,
        dateMonth,
        umTeam
      )
      .then((data) => {
        let tempData = data.result.map((ele) => {
          ele.abbr = ele.emailId;
          ele.name = ele.Name;
          ele.office = ele.Designation != null ? ele.Designation : "";
          ele.officeToolTip = ele.Designation;
          ele.ImageUrl =
            ele.nodeType !== "teamMember"
              ? urlConfig.tmlcApiUrl + "document/icon/1/" + ele.businessPnlLogo
              : urlConfig.tmlcApiUrl + "document/E/0/" + ele.ImageUrl;
          ele.category = "TeamView";
          ele.username =
            ele.emailId !== "undefined" && ele.emailId != null
              ? ele.emailId.split("@")[0]
              : "";
          ele.country = ele.country ? ele.country.toUpperCase() : "";
          return ele;
        });
        OrgChartHandler(tempData);
        setLoaderVal(false);
      });
  };

  const getTeamData = (
    teamId = "",
    businessId = "",
    dateVals = 0,
    umtype = ""
  ) => {
    let userId = authUser;
    let dateMonth = 0; // "2022-01"
    let buId = businessId ? businessId : 0; // "1,2"
    let memberId = businessId ? 0 : teamId ? teamId : userId;
    let memberType = 0;
    let status = "1";
    let umTeam = umtype ? "1" : "0"; // Show 1 hide 0
    if (dateVals) {
      let newdate = new Date(dateVals);
      let mVal =
        newdate.getMonth() + 1 > 9
          ? newdate.getMonth() + 1
          : "0" + (newdate.getMonth() + 1);
      dateMonth = newdate.getFullYear() + "-" + mVal;
    }
    services
      .getOrgChartDataFilter(
        "teamView",
        userId,
        memberId,
        buId,
        memberType,
        status,
        dateMonth,
        umTeam
      )
      .then((data) => {
        let tempData = data.result.map((ele) => {
          ele.abbr = ele.emailId;
          ele.name = ele.Name != null ? ele.Name : "";
          ele.office = ele.Designation != null ? ele.Designation : "";
          ele.officeToolTip = ele.Designation;
          ele.ImageUrl =
            ele.nodeType !== "teamMember"
              ? urlConfig.tmlcApiUrl + "document/icon/1/" + ele.ImageUrl
              : urlConfig.tmlcApiUrl + "document/E/0/" + ele.ImageUrl;
          ele.category = "TeamView";
          ele.username =
            ele.emailId !== "undefined" && ele.emailId != null
              ? ele.emailId.split("@")[0]
              : "";
          ele.country = ele.country ? ele.country.toUpperCase() : "";
          return ele;
        });
        teamOrgChartHandler(tempData);
        setLoaderVal(false);
      });
  };

  const getStuctureData = (teamId = "", businessId = "") => {
    let userId = teamId ? teamId : authUser;
    let buId = businessId ? businessId : "3";
    services.getOrgChartStructure(userId, buId).then((data) => {
      let tempData = data.result.map((ele, index) => {
        ele.office = ele.name != null ? ele.name : "";
        ele.officeToolTip = ele.Designation;
        ele.name = ele.title;
        ele.category = "LayerView";
        return ele;
      });
      structOrgChartHandler(tempData);
      setLoaderVal(false);
    });
  };

  const onSubmit = (event) => {
    setLoaderVal(true);
    if (event !== null) event.preventDefault();
    setFilterValue();
    if (value === 0) {
      getReportingData(teamForReport, groupForReport, dateVal, upComTeam);
    } else if (value === 1) {
      getTeamData(team, group, dateVal, upComTeam);
    } else if (value === 2) {
      getStuctureData(team, groupForStructure);
    }
  };
  const setFilterValue = () => {
    let teamMemberName = "";
    let businessUnitName = "";
    if (value === 0) {
      teamMemberName = teamNameForReport;
      businessUnitName = groupNameForReport;
    } else if (value === 1) {
      teamMemberName = teamName;
      businessUnitName = groupName;
    } else {
      businessUnitName = groupForStructureName;
    }

    filterHandler({
      team: team,
      teamName: teamMemberName,
      group: group,
      groupName: businessUnitName,
      dateVal: new Date(dateVal).toDateString(),
      upComTeam: upComTeam,
      value: value,
    });
  };

  const selectTeam = (e, index) => {
    if (index === 0) {
      setTeamForReport(e.value);
      setTeamNameForReport(e.text);
      setGroupForReport("");
      setGroupNameForReport("");
    } else if (index === 1) {
      setTeam(e.value);
      setTeamName(e.text);
      setGroup("");
      setGroupName("");
    }
    setDropFlag(true);
    setFilterValue();
  };

  const selectGroup = (e, index) => {
    if (index === 2) {
      setGroupForStructure(e.value);
      setGroupForStructureName(e.text);
    } else if (index === 0) {
      setGroupNameForReport(e.text);
      setGroupForReport(e.value);
      setTeamForReport("");
      setTeamNameForReport("");
    } else {
      setGroup(e.value);
      setGroupName(e.text);
      setTeam("");
      setTeamName("");
    }
    setDropFlag(true);
    setFilterValue();
  };

  const selectMonth = (val) => {
    setDateVal(new Date(val));
  };
  const selectUpComTeam = (val) => {
    if (!val.target.props.value) {
      setupComTeam(1);
    } else {
      setupComTeam(0);
    }
  };
  function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
  }
  React.useEffect(() => {
    // NOSONAR
    if (firstLoad && dropFlag) {
      let userid = authUser;
      setOrgchatHeader(true);
      services.getOrgChartMasterData(userid).then((data) => {
        if (data?.result) {
          let filterData = data?.result?.filter((ele) => {
            return ele.Type === "BusinessUnit";
          });
          let teamMemberData = data?.result?.filter((ele) => {
            return ele.Type === "TeamMembers";
          });
          let dateData = data?.result?.filter((ele) => {
            return ele.Type === "minLogDate";
          });

          let accessData = data?.result?.filter((ele) => {
            return ele.Type === "orgchartaccess";
          });
          setTabAccessRole(accessData);
          setUserAccessHandler(accessData);
          setTeamMberList(teamMemberData);
          SetBuGroup(filterData);
          if (dateData) {
            setMinDate(formatDate(dateData[0].Name));
          }

          if (
            accessData[0].Name === "No" &&
            accessData[1].Name === "Yes" &&
            accessData[2].Name === "Yes"
          ) {
            setOrgchatHeader(true);
            onHandlerView(2, true);
            setValue(1);
            setlayout(0);
            getTeamData();
          } else if (
            accessData[0].Name === "No" &&
            accessData[1].Name === "No" &&
            accessData[2].Name === "Yes"
          ) {
            setOrgchatHeader(true);
            onHandlerView(3, true);
            setValue(2);
            getStuctureData();
          } else if (
            accessData[0].Name === "No" &&
            accessData[1].Name === "Yes" &&
            accessData[2].Name === "No"
          ) {
            setOrgchatHeader(true);
            onHandlerView(2, true);
            setValue(1);
            setlayout(0);
            getTeamData();
          } else if (
            accessData[0].Name === "No" &&
            accessData[1].Name === "No" &&
            accessData[2].Name === "No"
          ) {
            onHandlerView(-1, true);
            setOrgchatHeader(false);
          } else {
            setOrgchatHeader(true);
            onHandlerView(1, true);
            setValue(0);
            getReportingData();
          }
        }
      });
      setTeam(authUser);
      setTeamName(decodeString(localStorage.getItem("user_nm")));
      setTimeout(() => {
        setTeamForReport(authUser);
      }, 900);
      setTeamNameForReport(decodeString(localStorage.getItem("user_nm")));
      setFirstLoad(false);
    } else {
      if (value === 0) {
        if (!groupForReport && BuGroup.length > 0 && teamForReport === "") {
          setGroupForReport(parseInt(BuGroup[0].Id));
          setGroupNameForReport(BuGroup[0].Name);
          getReportingData(teamForReport, parseInt(BuGroup[0].Id));
        } else {
          getReportingData(teamForReport, groupForReport);
        }
      } else if (value === 1) {
        getTeamData(team, group, dateVal, upComTeam);
      } else if (value === 2) {
        if (!groupForStructure && BuGroup.length > 0) {
          setGroupForStructureName(BuGroup[0].Name);
          setGroupForStructure(BuGroup[0].Id);
          setGroupForStructure(
            parseInt(BuGroup[0].Id),
            getStuctureData(team, parseInt(BuGroup[0].Id)) /*NOSONAR*/
          );
        } else {
          getStuctureData(team, groupForStructure);
        }
      }
    }
    setFilterValue();
  }, [onHandlerView, value]);

  const getValue = (value) => {
    if (
      tabAccessRole[0].Name === "No" &&
      tabAccessRole[1].Name === "Yes" &&
      tabAccessRole[2].Name === "Yes"
    ) {
      return value - 1;
    } else if (
      tabAccessRole[0].Name === "No" &&
      tabAccessRole[1].Name === "Yes" &&
      tabAccessRole[2].Name === "No") {
      return 0;
    } else {
      return value;
    }
  };
  
  return (
    <div>
      {showOrgchatHeader === true ? (
        <div className={classes.root}>
          {hideShow === false ? (
            <div className="tabs-section">
              <AppBar className="tabs-hide" position="static" color="default">
                <Tabs
                  value={getValue(value)}
                  onChange={handleChange}
                  indicatorColor="primary"
                  className="chartview-tabs"
                  textColor="primary"
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs example"
                  id="tabBtn"
                >
                  {tabAccessRole[0].Name === "Yes" && (
                    <Tab
                      className="tabs_charttitle tab_1"
                      icon={
                        <ReportviewIcon
                          fill={value === 0 ? "#f94f5e" : "black"}
                        />
                      }
                      label="Org Chart Reporting View"
                      {...a11yProps(0)}
                    />
                  )}
                  {tabAccessRole[1].Name === "Yes" && (
                    <Tab
                      className="tabs_charttitle tab_2"
                      icon={
                        <TeamviewIcon
                          fill={value === 1 ? "#f94f5e" : "black"}
                        />
                      }
                      label="Org Chart Team View"
                      {...a11yProps(1)}
                    />
                  )}
                  {tabAccessRole[2].Name === "Yes" && (
                    <Tab
                      className={
                        value === 2
                          ? "tabs_charttitle tab_3 orgTabSelected"
                          : "tabs_charttitle tab_3"
                      }
                      icon={
                        <StructureIcon
                          fill={value === 2 ? "#f94f5e" : "black"}
                        />
                      }
                      label="Org Structure"
                      {...a11yProps(2)}
                    />
                  )}
                </Tabs>
                <div>
                  {!hideShow ? (
                    <button onClick={handleShowChanges} className="hide-btn">
                      <FontAwesomeIcon
                        title="Hide"
                        icon={faEyeSlash}
                        style={{ cursor: "pointer" }}
                      />
                      Hide
                    </button>
                  ) : (
                    ""
                  )}
                </div>
              </AppBar>
              {orgView === false ? (
                <>
                  <TabPanel className="main-select-box" value={value} index={0}>
                    <div className="tab-select-content">
                      {teamMemberList && (
                        <div className="select-formControl">
                          <ComboBoxComponent
                            id="teamMember"
                            dataSource={teamMemberList}
                            fields={{ text: "Name", value: "Id" }}
                            data-msg-containerid="teamMemberErr"
                            name="teamMemberErr"
                            floatLabelType="Auto"
                            cssClass="e-outline"
                            allowFiltering
                            allowCustom={true}
                            placeholder="Team Member *"
                            onChange={(e) => selectTeam(e.target, 0)}
                            value={teamForReport}
                          />
                        </div>
                      )}
                      {tabAccessRole[5].Name === "Yes" && (
                        <div className="select-formControl">
                          <ComboBoxComponent
                            id="group"
                            dataSource={BuGroup}
                            rules={{ required: true }}
                            fields={{ text: "Name", value: "Id" }}
                            data-msg-containerid="teamMemberErr"
                            name="teamMemberErr"
                            floatLabelType="Auto"
                            cssClass="e-outline"
                            allowFiltering
                            allowCustom={true}
                            placeholder="Business Unit / Group Function *"
                            value={groupForReport}
                            onChange={(e) => selectGroup(e.target, 0)}
                          />
                        </div>
                      )}
                      <div className="select-formControl" id="orgchart-new-as">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            style={{ width: "100%", marginTop: "-20px" }}
                            openTo="month"
                            views={["year", "month"]}
                            id="orgChartDate"
                            name="orgChartDate"
                            format="MMM-yyyy"
                            value={dateVal}
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                            inputVariant="outlined"
                            variant="inline"
                            autoOk
                            minDate={minDate}
                            maxDate={new Date()}
                            onChange={(e) => selectMonth(e)}
                            label="Org Chart Month *"
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                      <div>
                        <label htmlFor="checked" className="upcoming-toogle">
                          {" "}
                          Upcoming Team Member{" "}
                        </label>
                        <br />
                        <SwitchComponent
                          id="upcomTeam"
                          checked={upComTeam ? true : false}
                          value={upComTeam}
                          onChange={(e) => selectUpComTeam(e)}
                        />{" "}
                        <span
                          className="upcoming-toogle"
                          style={{ cursor: "pointer" }}
                        >
                          {upComTeam === 0 ? "Hide" : "Show"}
                        </span>
                      </div>
                      <div className="select-formControl">
                        <button className="view-btn" onClick={onSubmit}>
                          View
                        </button>
                      </div>
                    </div>
                    <div className="up-arrow" onClick={handleViewChange}>
                      {orgView === false ? (
                        <FontAwesomeIcon
                          style={{ cursor: "pointer" }}
                          title="Hide"
                          icon={faAngleUp}
                        />
                      ) : (
                        <FontAwesomeIcon title="Show" icon={faAngleDown} />
                      )}
                    </div>
                  </TabPanel>
                  <TabPanel className="main-select-box" value={value} index={1}>
                    <div className="tab-select-content">
                      {teamMemberList && (
                        <div className="select-formControl">
                          <ComboBoxComponent
                            id="teamMember1"
                            dataSource={teamMemberList}
                            fields={{ text: "Name", value: "Id" }}
                            data-msg-containerid="teamMemberErr"
                            name="teamMemberErr"
                            floatLabelType="Auto"
                            cssClass="e-outline"
                            allowFiltering
                            allowCustom={true}
                            placeholder="Team Member *"
                            onChange={(e) => selectTeam(e.target, 1)}
                            value={team}
                          />
                        </div>
                      )}
                      {tabAccessRole[5].Name === "Yes" && (
                        <div className="select-formControl">
                          <ComboBoxComponent
                            id="group1"
                            dataSource={BuGroup}
                            rules={{ required: true }}
                            fields={{ text: "Name", value: "Id" }}
                            data-msg-containerid="teamMemberErr"
                            name="teamMemberErr"
                            floatLabelType="Auto"
                            cssClass="e-outline"
                            allowFiltering
                            allowCustom={true}
                            placeholder="Business Unit / Group Function *"
                            onChange={(e) => selectGroup(e.target, 1)}
                            value={group}
                          />
                        </div>
                      )}
                      <div className="select-formControl" id="orgchart-new-as">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            style={{ width: "100%", marginTop: "-20px" }}
                            openTo="month"
                            views={["year", "month"]}
                            id="orgChartDate"
                            name="orgChartDate"
                            format="MMM-yyyy"
                            value={dateVal}
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                            inputVariant="outlined"
                            variant="inline"
                            autoOk
                            onChange={(e) => selectMonth(e)}
                            maxDate={new Date()}
                            label="Org Chart Month *"
                            minDate={minDate}
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                      <div>
                        <label htmlFor="checked" className="upcoming-toogle">                       
                          Upcoming Team Member
                        </label>
                        <br />
                        <SwitchComponent
                          id="upcomTeam"
                          checked={upComTeam ? true : false}
                          value={upComTeam}
                          onChange={(e) => selectUpComTeam(e)}
                        />
                        <span className="upcoming-toogle">
                          {upComTeam === 0 ? "Hide" : "Show"}
                        </span>
                      </div>
                      <div className="select-formControl ">
                        <button className="view-btn" onClick={onSubmit}>
                          View
                        </button>
                      </div>
                    </div>
                    <div className="up-arrow" onClick={handleViewChange}>
                      {orgView === false ? (
                        <FontAwesomeIcon title="Hide" icon={faAngleUp} />
                      ) : (
                        <FontAwesomeIcon title="Show" icon={faAngleDown} />
                      )}
                    </div>
                  </TabPanel>
                  <TabPanel className="main-select-box" value={value} index={2}>
                    <div className="tab-select-content">
                      <div className="select-formControl">
                        <ComboBoxComponent
                          id="groupStrut"
                          dataSource={BuGroup}
                          rules={{ required: true }}
                          fields={{ text: "Name", value: "Id" }}
                          data-msg-containerid="teamMemberErr"
                          name="teamMemberErr"
                          floatLabelType="Auto"
                          cssClass="e-outline"
                          allowFiltering
                          allowCustom={true}
                          value={groupForStructure}
                          placeholder="Business Unit / Group Function *"
                          onChange={(e) => selectGroup(e.target, 2)}
                        />
                      </div>
                      <div className="select-formControl">
                        <button className="view-btn" onClick={onSubmit}>
                          View
                        </button>
                      </div>
                    </div>
                    <div
                      className="up-arrow"
                      onClick={handleViewChange}
                      style={{ cursor: "pointer" }}
                    >
                      {orgView === false ? (
                        <FontAwesomeIcon title="Hide" icon={faAngleUp} />
                      ) : (
                        <FontAwesomeIcon title="Show" icon={faAngleDown} />
                      )}
                    </div>
                  </TabPanel>
                </>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}
        </div>
      ) : (
        <ErrorBoundary />
      )}
    </div>
  );
};

const mapStateToProps = ({ auth, orgchartreducer }) => {
  const { authUser } = auth;
  const { orgchartupdateStatus } = orgchartreducer;
  return { authUser, orgchartupdateStatus };
};

export default connect(mapStateToProps, { setOrhChartDetaila })(HeaderTab);
