
/**
 * Author Name :- Prashant Waphare
 * Date :27-05-2020
 * Description :- This file use for show withdrawal employee list.
 */
import * as React from "react";
import { closest, isNullOrUndefined } from "@syncfusion/ej2-base";
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  Filter,
  Inject,
  VirtualScroll,
  Sort,
  Page,
} from "@syncfusion/ej2-react-grids";
import { TooltipComponent } from '@syncfusion/ej2-react-popups';

import { Button, Dialog } from "@material-ui/core";
import "../../../app/containers/tmlc/tmlc.css";
import "../../../../src/components/separation/resignationcomponent/resignationcomponent.css"
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import EmployeeNameTemplate from "../employeeNameTemplate";
import { PnlStatus, HrStatus, ReportingMgrStatus, AllDateFormat } from "../allcommoncomponents";
import { approveWithdrawl, addUpdateSeparationDetails } from '../../../redux/actions/entryexit/separationaction';
import {
  DialogContent,
  DialogActions,
  DialogTitle,
} from "../../customdialog/customdialog";

import {
  getGridColumn,
  EMPLOYEE_NAME,
  REPORTING_SUPERVISOR_STATUS,
  PL_STATUS,
  HR_STATUS,
  WITH_ACTION,
  JOINING_DATE,
  DATE_OF_RESIGNATION,
  LWD_OFFICIAL,
  LWD_REQUESTED,
  WITH_REASON,
  DATE_OF_WITHDRAWAL,
  DAYS_TO_LWD,
  EMP_REMARK
} from "../../../app/other/gridcolumns";
import { DateFilterformat } from '../../../../src/config/config';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPlayCircle, faCheckCircle, faTimesCircle } from '@fortawesome/pro-light-svg-icons'
import { setMeetingCategory } from '../../../redux/actions/meetingcategoryaction';
import { getNotify } from "../../../app/other/commonfunction";
import { WARNING } from "../../../assets/constants/constants";
library.add(faPlayCircle, faCheckCircle, faTimesCircle);

class Withdrawal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: null,
    };
    this.height =
      (window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight) - 253;
    this.width =
      (window.innerWidth ||
        document.documentElement.clientWidth ||
        document.body.clientWidth) - 100;
    this.dReady = false;
    this.dtTime = false;
    this.isDataBound = false;
    this.isDataChanged = true;
    this.dropSlectedIndex = null;
    this.check = {
      type: "CheckBox",
    };
    this.select = {
      persistSelection: true,
      type: "Multiple",
      checkboxOnly: true,
    };
    this.Filter = {
      type: "Menu",
    };
    this.pageOptions = {
      pageSize:
        (window.innerHeight ||
          document.documentElement.clientHeight ||
          document.body.clientHeight) > 700
          ? 12
          : 6,
    };
    this.name = {
      type: "CheckBox",
    };
    this.employeeNameTemplate = this.employeeNameTemplate.bind(this);
    this.onQueryCellInfoPreJoining = this.onQueryCellInfoPreJoining.bind(this);
    this.onComplete = this.onComplete.bind(this);
    this.onLoad = this.onLoad.bind(this);
    this.onDataBound = this.onDataBound.bind(this);
    this.handleConfirmedYes = this.handleConfirmedYes.bind(this)
    this.handleClose = this.handleClose.bind(this)
    this.withdrawlId = '';
    this.taskId = '';
    this.empId = '';
    this.actioType = '';
  }

  componentDidMount() {
    this.props.getWithdrawalTabData();
    setTimeout(() => {
      const ele = document.getElementsByClassName("e-headercelldiv");
      // eslint-disable-next-line
      for(let i in ele) {
        if(!isNaN(parseInt(i, 10))){
          let index = parseInt(i, 10);
          if (
            ele[index].innerText === JOINING_DATE ||
            ele[index].innerText === DATE_OF_RESIGNATION ||
            ele[index].innerText === LWD_OFFICIAL ||
            ele[index].innerText === LWD_REQUESTED ||
            ele[index].innerText === DATE_OF_WITHDRAWAL
            // ele[index].innerText === WITH_ACTION
          ) {
            ele[index].classList.add("grid-center-header");
          } else {
            ele[index].classList.add("grid-left-header");
          }
        }
      }
    }, 1000);
  }

  componentWillReceiveProps(newProps) {
    if (newProps.UpdateWithdrawlStatus === 'StatusUpdate') {
      this.props.getWithdrawalTabData();
      this.props.addUpdateSeparationDetails({ UpdateWithdrawlStatus: '' })
    }
  }

  onQueryCellInfoPreJoining = (args) => {
    const { cell, column } = args;
    const { field } = column;
    if (field === "action") {
      cell.setAttribute(
        "style",
        "font-size:14px !important; text-align: center !important"
      );
    } else {
      cell.setAttribute(
        "style",
        "font-size:14px !important; text-align: left !important; padding-left:18px !important"
      );
    }
  };

  onDataBound() {
    clearTimeout(this.clrIntervalFun);
    clearInterval(this.intervalFun);
    this.dtTime = true;
  }

  onComplete = (args) => {
    if (args.requestType === "filterchoicerequest") {
      if (
        args.filterModel.options.field === "Trustworthiness" ||
        args.filterModel.options.field === "Rating" ||
        args.filterModel.options.field === "Status"
      ) {
        const span = args.filterModel.dialogObj.element.querySelectorAll(
          ".e-selectall"
        )[0];
        if (!isNullOrUndefined(span)) {
          closest(span, ".e-ftrchk").classList.add("e-hide");
        }
      }
    }
  };

  onChange() {
    this.ddObj.hidePopup();
    this.gridInstance.showSpinner();
    this.dropSlectedIndex = null;
    clearTimeout(this.clrIntervalFun2);
    this.clrIntervalFun2 = setTimeout(() => {
      this.isDataChanged = true;
      this.stTime = null;
      const contentElement = this.gridInstance.contentModule.getPanel()
        .firstChild;
      contentElement.scrollLeft = 0;
      contentElement.scrollTop = 0;
      this.gridInstance.pageSettings.currentPage = 1;
      this.stTime = performance.now();
      this.gridInstance.hideSpinner();
    }, 100);
  }

  onLoad() {
    document
      .getElementById("overviewgrid")
      .ej2_instances[0].on("data-ready", () => {
        this.dReady = true;
        this.stTime = performance.now();
      });
    document
      .getElementById("overviewgrid")
      .addEventListener("DOMSubtreeModified", () => {
        if (this.dReady && this.stTime && this.isDataChanged) {
          this.stTime = null;
          this.dReady = false;
          this.dtTime = false;
          this.isDataChanged = false;
        }
      });
  }

  reportingStatus = (props) => {
    const { reportingMgrStatus } = props;
    return (
      <ReportingMgrStatus reportingMgrStatus={reportingMgrStatus} />
    )
  };

  pnlStatus = (props) => {
    const { pnlHeadStatus } = props;
    return (
      <PnlStatus pnlHeadStatus={pnlHeadStatus}
      />
    );
  };

  hrStatus = (props) => {
    const { hrStatus } = props;
    return (
      <HrStatus hrStatus={hrStatus}
      />
    );
  };
  resignationReason = (props) => {
    const { sepReason } = props;
    return (
      <div>
        <TooltipComponent
          content={sepReason}
          position="BottomCenter"
          isSticky={false}
          cssClass='customtooltip'
        >
          <span
            style={{
              fontSize: "14px",
              display: "block",
              // width: "100px",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
          >
            {sepReason}
          </span>
        </TooltipComponent>
      </div>
    );
  };
  joiningDateFormat = (props) => {
    const { joiningDate } = props;
    return (
      <AllDateFormat date={joiningDate} />
    );
  };
  resignationDateFormat = (props) => {
    const { dateOfResignation } = props;
    return (
      <AllDateFormat date={dateOfResignation} />
    );
  };
  withdrawalDateFormat = (props) => {
    const { dateOfWithdrwal } = props;
    return (
      <AllDateFormat date={dateOfWithdrwal} />
    );
  };
  dysToLwd = (props) => {
    const { daysToLastWorkingDay } = props
    return (
      <div style={{ textAlign: "center" }}>
        <span>{daysToLastWorkingDay}{" Days"}</span>
      </div>
    );
  };
  lwdOfficial = (props) => {
    const { lastWorkingDayOfficial } = props;
    return (
      <AllDateFormat date={lastWorkingDayOfficial} />
    );
  };
  lwdRequested = (props) => {
    const { lastworkingEmpRequested } = props;
    return (
      <AllDateFormat date={lastworkingEmpRequested} />
    );
  };
  mgrRemarks = (props) => {
    const { remarks } = props;
    return (
      <div>
        <TooltipComponent
          content={remarks.replace(/(?:\r\n|\r|\n)/g, '<br>')}
          position="BottomCenter"
          isSticky={true}
          cssClass='customtooltip'
          opensOn="Click"
        >
          <span
            style={{
              fontSize: "14px",
              display: "block",
              width: "100px",
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
              cursor: "pointer"
            }}
          >
            {remarks.replace(/[&\\#,+()$~%.'":*?<>{}]/g, '')}
          </span>
        </TooltipComponent>
      </div>
    );
  }
  actionToggle = (props, type) => {
    const { withdrawlUid, taskId, employeeId, reportingMgrStatus } = props;
    const { reportingType } = this.props;
    if (reportingType !== "mgrlist") {
      if (reportingMgrStatus === "Pending") {
        getNotify(WARNING, "Reporting Supervisor withdrawal acceptance status is pending.");
        return;
      }
    }
    this.withdrawlId = withdrawlUid;
    this.taskId = taskId;
    this.empId = employeeId
    this.actioType = type

    const { actionState } = this.state;
    this.setState({ actionState: !actionState });
  };

  handleAction = (props) => {
    const { reportingType } = this.props;
    const { reportingMgrStatus, pnlHeadStatus } = props;
    if (reportingType === "mgrlist") {
      if (reportingMgrStatus === "Pending") {
        return (
          <div className="row" style={{ paddingLeft: '20px', marginTop: "-10px" }}>
            <div className="col-4" >
              <TooltipComponent closeOn="Click" content="Accept" position="BottomCenter" isSticky={false} cssClass='customtooltip' className="pointer-cursor">
                <FontAwesomeIcon icon={faCheckCircle} style={{ fontSize: "20px" }} onClick={() => this.actionToggle(props, "1")} />
              </TooltipComponent>
            </div>
            <div className="col-4">
              <TooltipComponent closeOn="Click" content="Reject" position="BottomCenter" isSticky={false} cssClass='customtooltip' className="pointer-cursor">
                <FontAwesomeIcon icon={faTimesCircle} style={{ fontSize: "20px" }} onClick={() => this.actionToggle(props, "0")} />
              </TooltipComponent>
            </div>
          </div>
        )
      } else if (reportingMgrStatus === "Accepted") {
        return (
          <div className="col-4" style={{ paddingLeft: "25px" }}>
            <TooltipComponent closeOn="Click" content="Accepted" position="BottomCenter" isSticky={false} cssClass='customtooltip' >
              <FontAwesomeIcon icon={faCheckCircle} style={{ fontSize: "20px", color: "green" }} />
            </TooltipComponent>
          </div>
        )
      } else {
        return (
          <div className="col-4" style={{ paddingLeft: "25px" }}>
            <TooltipComponent closeOn="Click" content="Rejected" position="BottomCenter" isSticky={false} cssClass='customtooltip' >
              <FontAwesomeIcon icon={faTimesCircle} style={{ fontSize: "20px", color: "red" }} />
            </TooltipComponent>
          </div>
        )
      }
    } else {
      if (pnlHeadStatus === "Pending") {
        return (
          <div className="row" style={{ paddingLeft: '20px', marginTop: "-10px" }}>
            <div className="col-4" >
              <TooltipComponent closeOn="Click" content="Accept" position="BottomCenter" isSticky={false} cssClass='customtooltip' className="pointer-cursor" >
                <FontAwesomeIcon icon={faCheckCircle} style={{ fontSize: "20px" }} onClick={() => this.actionToggle(props, "1")} />
              </TooltipComponent>
            </div>
            <div className="col-4">
              <TooltipComponent closeOn="Click" content="Reject" position="BottomCenter" isSticky={false} cssClass='customtooltip' className="pointer-cursor">
                <FontAwesomeIcon icon={faTimesCircle} style={{ fontSize: "20px" }} onClick={() => this.actionToggle(props, "0")} />
              </TooltipComponent>
            </div>
          </div>
        )
      } else if (pnlHeadStatus === "Accepted") {
        return (
          <div className="col-4" style={{ paddingLeft: "25px" }}>
            <TooltipComponent closeOn="Click" content="Accepted" position="BottomCenter" isSticky={false} cssClass='customtooltip' >
              <FontAwesomeIcon icon={faCheckCircle} style={{ fontSize: "20px", color: "green" }} />
            </TooltipComponent>
          </div>
        )
      } else {
        return (
          <div className="col-4" style={{ paddingLeft: "25px" }}>
            <TooltipComponent closeOn="Click" content="Rejected" position="BottomCenter" isSticky={false} cssClass='customtooltip' >
              <FontAwesomeIcon icon={faTimesCircle} style={{ fontSize: "20px", color: "red" }} />
            </TooltipComponent>
          </div>
        )
      }
    }
  };
  navigateToAddBasicInformation = async () => {
    // try {
    //   localStorage.setItem('last_meeting_category', localStorage.getItem('meeting_category'))
    //   localStorage.setItem("meeting_category", EMPLOYEE_PROFILE);

    //   const path = `/onboard?empId=${encrypt(args.employeeId)}`;
    //   this.setState({
    //     redirect: path
    //   }, () => this.props.setMeetingCategory(EMPLOYEE_PROFILE));
    // } catch (error) {
   
    // }
  };

  employeeNameTemplate(props) {
    return (
      <EmployeeNameTemplate
        props={props}
        data="test"
        navigateToAddBasicInformation={this.navigateToAddBasicInformation}
      />
    );
  }

  handleConfirmedYes = () => {
    try {
      const { authUser } = this.props;
      const approveData = {
        userId: authUser,
        withdrawlId: this.withdrawlId,
        taskId: this.taskId,
        empId: this.empId,
        type: "APPR",
        approvalStatus: this.actioType
      };
      this.props.approveWithdrawl(approveData);
    } catch (error) {

    }
    this.setState({ actionState: false });
  };

  handleClose = () => {
    this.setState({ actionState: false });
  }


  render() {
    const { redirect } = this.state;
    const { withdrawalData } = this.props;
    if (redirect !== null) {
      return <Redirect to={redirect} />;
    }
    var widthColumn = 0;
    getGridColumn("withdrawal").forEach((data) => {
      widthColumn = widthColumn + data.width;

    });
    return (
      <div
        className="row resignation-manager-hr-grid-height"
      >
        <div>
          <div className="row-xs-12 row-sm-12 row-lg-12 row-md-12">
            <div
              style={{
                paddingLeft: "15px",
                paddingRight: "15px",
                paddingTop: "5px",
              }}
            >
              <GridComponent
                className="grid-head-custome"
                id="overviewgrid"
                allowPaging
                pageSettings={this.pageOptions}
                dataSource={withdrawalData}
                // dataSource={this.data}
                enableHover={false}
                enableVirtualization={false}
                rowHeight={30}
                height={this.height}
                ref={(g) => {
                  this.gridInstance = g;
                }}
                actionComplete={this.onComplete}
                load={this.onLoad}
                queryCellInfo={this.onQueryCellInfoPreJoining}
                dataBound={this.onDataBound}
                filterSettings={this.Filter}
                allowFiltering
                allowSorting
                allowSelection
                selectionSettings={this.select}
                allowTextWrap={true}
              >
                <ColumnsDirective>
                  {getGridColumn("withdrawal").map((item) => {
                    switch (item.colName) {
                      case EMPLOYEE_NAME:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.employeeNameTemplate}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                            filter={this.Filter}
                          />
                        );
                      case REPORTING_SUPERVISOR_STATUS:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.reportingStatus}
                            filter={this.check}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                          />
                        );
                      case PL_STATUS:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.pnlStatus}
                            filter={this.check}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                          />
                        );
                      case HR_STATUS:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.hrStatus}
                            filter={this.check}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                          />
                        );
                      case WITH_REASON:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.resignationReason}
                            allowFiltering={false}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                          />
                        );
                      case DAYS_TO_LWD:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.dysToLwd}
                            // allowFiltering={false}
                            filter={this.check}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                          />
                        );
                      case DATE_OF_WITHDRAWAL:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.withdrawalDateFormat}
                            // allowFiltering={false}
                            // filter={this.Filter}
                            filter={this.check}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                            format={DateFilterformat}
                          />
                        );
                      case JOINING_DATE:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.joiningDateFormat}
                            // allowFiltering={false}
                            // filter={this.Filter}
                            filter={this.check}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                            format={DateFilterformat}
                          />
                        );
                      case DATE_OF_RESIGNATION:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.resignationDateFormat}
                            // allowFiltering={false}
                            // filter={this.Filter}
                            filter={this.check}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                            format={DateFilterformat}
                          />
                        );
                      case LWD_OFFICIAL:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.lwdOfficial}
                            // allowFiltering={false}
                            // filter={this.Filter}
                            filter={this.check}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                            format={DateFilterformat}
                          />
                        );
                      case LWD_REQUESTED:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.lwdRequested}
                            // allowFiltering={false}
                            // filter={this.Filter}
                            filter={this.check}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                            format={DateFilterformat}
                          />
                        );
                      case EMP_REMARK:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.mgrRemarks}
                            allowFiltering={false}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                          />
                        );
                      case WITH_ACTION:
                        return (
                          <ColumnDirective
                            key={item.field}
                            template={this.handleAction}
                            allowFiltering={false}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                          />
                        );
                      default:
                        return (
                          <ColumnDirective
                            key={item.field}
                            textAlign={`${item.align} !important`}
                            field={item.field}
                            headerText={item.colName}
                            width={item.width}
                            filter={this.check}
                          />
                        );
                    }
                  })}
                </ColumnsDirective>
                <Inject services={[Page, Filter, VirtualScroll, Sort]} />
              </GridComponent>
            </div>
          </div>
          <div style={{ width: "350px" }}>
            <Dialog open={this.state.actionState}>
              <DialogTitle> {this.actioType === "1" ? "Accept Withdrawal" : "Reject Withdrawal"}</DialogTitle>
              <DialogContent>{this.actioType === "1" ? "Are you sure you want to accept the withdrawal?" : "Are you sure you want to reject the withdrawal?"}
              </DialogContent>
              <DialogActions>
                <Button onClick={this.handleConfirmedYes}> Yes </Button>
                <Button onClick={this.handleClose}> No </Button>
              </DialogActions>
            </Dialog>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({ auth, separationreducer, meetingcategory }) => {
  const { authUser } = auth;
  const { UpdateWithdrawlStatus } = separationreducer
  const { categoryName } = meetingcategory;
  return { authUser, categoryName, UpdateWithdrawlStatus };
};
export default connect(mapStateToProps, { approveWithdrawl, addUpdateSeparationDetails, setMeetingCategory })(Withdrawal);

