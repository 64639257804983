import React ,{ useState, useEffect, useRef  } from 'react';
import { TabComponent } from "@syncfusion/ej2-react-navigations";
import { connect } from 'react-redux';
import TeamRatingTabView from './teamratingtabview';
import SelfRatingTabView from './selfratingtabview';
import services from '../../services/okr/okrservices'
import { addUpdateOkrRatingRedux } from '../../redux/actions/okrrating/okrratingaction';
import {addUpdateOkrDashboardRedux} from '../../redux/actions/okrdashboardaction/okrdashboardaction'
const sampaleCss = `.e-tab .e-tab-header .e-toolbar-item .e-tab-text {
  text-transform: capitalize;
  font-size:14px;
}`
function OKRDashboardHeaderTabs(props) {
  const [monthsData,setMonthsData] = useState([])
  let  tabsRef=useRef(null);
  useEffect(() => {
    getMonthsData()
    getTeamMemberData()
  }, [])
 
  useEffect(() => {
    if(props.navToTeamMemberFlag==='updated'){

      props.addUpdateOkrDashboardRedux({
        dashboardTabs: 'team-member-rating',
        navToTeamMemberFlag:''
      })
    }
  }, [props.navToTeamMemberFlag==='updated'])
  const{authUser,empId,fyId} = props
  const getMonthsData=async()=>{
    let monthsData = await services.getOKRDashboardData('okrmonthsdata',authUser,empId,fyId,0)
    setMonthsData(monthsData)
  }
  useEffect(() => {
    if(props.dashboardTabs=== "self-rating"){
      tabsRef.current.selectedItem = 1
      props.addUpdateOkrDashboardRedux({
        dashboardTabs: 'self-rating',
        navToSelfRatingFlag:''
      })
    }
  }, [props.dashboardTabs=== "self-rating"])

const getTeamMemberData = ()=>{
  const getMasterData = Promise.resolve(services.getOKRMaster(props.authUser));
  getMasterData.then((resultData) => {
   const reviewTP = resultData.Result.filter((item) => { return item.var_list_name === "Review"; });
   props.addUpdateOkrRatingRedux({ reviewUsers: reviewTP })
  
})
}

  const changeTab = (args) => {

    if (args.selectedIndex === 1) {
      props.addUpdateOkrDashboardRedux({
        dashboardTabs: 'self-rating',
        empId: props.authUser,
        monthlyOkrMonthStatus: 'updated'
      })
      props.onChangeShowMonth(false)
    } else {
      props.addUpdateOkrDashboardRedux({
        dashboardTabs: 'team-rating'
      })
    }
  }
  const { showMonths,dashboardTabs, isPeopleLeader } = props; 
  return (
    <div className="dashboard-header-tabs" 
      style={{  
              minHeight:showMonths ? 190: dashboardTabs === 'self-rating'? 120: 100  
              }}>
      <div>
        <div style={{ cursor: 'pointer', position: 'absolute', right: 30, top: 15, zIndex: 10 }} onClick={() => props.onChangeShowMonth(!showMonths)}>
          {
            showMonths ?
              <>
                <span style={{ color: '#5A5A5D' }}>Close</span> <i className="fas fa-chevron-up" style={{ color: '#B7B7BB' }}></i>
              </>
              :
              <>
                <span style={{ color: '#5A5A5D' }}>Click to open filters &nbsp;</span>
                <i className="fas fa-chevron-down" style={{ color: '#B7B7BB' }}></i>
              </>
          }
        </div>
        <style>{sampaleCss}</style>
        {
         ( isPeopleLeader!==null && isPeopleLeader === true) && 
            <TabComponent id="defaultTab"
            ref={tabsRef}
            selectedItem={2}
            selected={(arg) => changeTab(arg)}
            animation={{ previous: { effect: "SlideLeftIn", duration: 0, easing: "ease" }, next: { effect: "SlideRightIn", duration: 0, easing: "ease" } }}
          >
            <div className="e-tab-header" style={{ padding: "10px 10px" }} >
              <div> Team Rating </div>
              <div> Self Rating </div>
            </div>

            <div className="e-content" style={{ padding: "10px 10px" }}>
              <div>
                { <div className='tab-horizontal-line' />}
                <TeamRatingTabView monthsData={monthsData} showMonths={showMonths} />
              </div>
              <div>
                { <div className='tab-horizontal-line' />}
                <SelfRatingTabView monthsData={monthsData} showMonths={showMonths}  />
              </div>
            </div>
          </TabComponent>
          }
        
        {
        ( isPeopleLeader!==null && isPeopleLeader === false) && 
          <TabComponent id="defaultTab"
          ref={tabsRef}
          selected={(arg) => changeTab(arg)}
          animation={{ previous: { effect: "SlideLeftIn", duration: 0, easing: "ease" }, next: { effect: "SlideRightIn", duration: 0, easing: "ease" } }}
          >
          <div className="e-tab-header" style={{ padding: "10px 10px" }} >
            <div> Self Rating </div>
          </div>
          <div className="e-content" style={{ padding: "10px 10px" }}>
            <div>
              <div className='tab-horizontal-line' />
              <SelfRatingTabView monthsData={monthsData} showMonths={showMonths}  />
            </div>
          </div>
        </TabComponent>
        }
        
      </div>
    </div>
  )
}

const mapStateToProps = ({ auth, okrratingreducer,okrdashboardreducer, okrreducer, meetingmaster, meetingcategory }) => {
  const { authUser } = auth;
  const { dashboardTabs, navToSelfRatingFlag, isPeopleLeader, navToTeamMemberFlag } = okrdashboardreducer;
  const { empId, fyId, monthOfRating, supervisorUsers, selfUser, TPUsers, refreshRatingDataFlag, reviewUsers,
    ratingStatusData, ratingCategoryStatus, ratingTabs } = okrratingreducer;
  const { categoryName } = meetingcategory;
  const { OkrMasters } = okrreducer;
  const { finacialYearData } = meetingmaster;
  return {
    authUser,dashboardTabs, empId, fyId, monthOfRating, OkrMasters, finacialYearData, supervisorUsers, selfUser, TPUsers, categoryName, refreshRatingDataFlag, reviewUsers,
    ratingStatusData, ratingCategoryStatus, ratingTabs,navToSelfRatingFlag, isPeopleLeader, navToTeamMemberFlag
  }
};
// const mapStateToProps = ({ auth,okrdashboardreducer }) => {
//   const { dashboardTabs } = okrdashboardreducer;
//   const { authUser } = auth;
//   return { dashboardTabs,authUser }
// };
export default connect(mapStateToProps, { addUpdateOkrDashboardRedux ,addUpdateOkrRatingRedux})(
  OKRDashboardHeaderTabs
);
