import {
  SET_TIB_MULTIPLE_USERS_ID,
  TIB_USER_LIST,
  TIB_MONTHS,
  TIB_GRAY_LEAVES,
  SET_TIB_MULTIPLE_MONTHS_IDS,
  ADD_UPDATE_TIB_DATA,
  UTILIZATION_OF_TIME_REPORT_UPDATE,
} from "../constant/tibconstant";
const INIT_STATE = {
  selectedTIBUsersId: [],
  selectedTIBMonthsId: [],
  tibUserList: [],
  tibMonts: [],
  tibGrayLeaves: [],
  tibFinancialYearData: [],
  fyId: null,

  tibAvgBucketData: [],
  tibAvgViewByData: [],
  tibAvgSelectedBucketIds: [],
  tibAvgSelectedViewByIds: [],
  tibAvgSelectedYearOption: 1,
  tibAvgSelectedUserOption: 1,
  tibAvgSelectedFy: null,
  tibAvgSelectedFyMonths: [],
  tibAvgSelectedFromMonth: "",
  tibAvgSelectedToMonth: "",
  tibAvgSelectedSd: [],
  userTibData: [],

  utilizationReportUsersList: [],
  utilizationReportId: 0,
  utilizationViewByData: 1,
  utilisationSelectedDataField: 3,
  utilizationViewByUpdated: '',
  utrLoading: false,
  utilisationMonths: []
};
// eslint-disable-next-line
export default (state = INIT_STATE, action) => { // NOSONAR
  switch (action.type) {
    case SET_TIB_MULTIPLE_USERS_ID: {
      return {
        ...state,
        selectedTIBUsersId: action.payload,
      };
    }
    case SET_TIB_MULTIPLE_MONTHS_IDS: {
      return {
        ...state,
        selectedTIBMonthsId: action.payload,
      };
    }
    case TIB_USER_LIST: {
      return {
        ...state,
        tibUserList: action.payload,
      };
    }
    case TIB_MONTHS: {
      return {
        ...state,
        tibMonts: action.payload,
      };
    }
    case TIB_GRAY_LEAVES: {
      return {
        ...state,
        tibGrayLeaves: action.payload,
      };
    }

    case ADD_UPDATE_TIB_DATA:
    case UTILIZATION_OF_TIME_REPORT_UPDATE: {
      return {
        ...state,
        ...action.payload,
      };
    }
    default:
      return state;
  }
};
