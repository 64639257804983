// Author by:- Prashant Waphare | 31-08-2020 | Add all constant home office.
//Author by;- Yogita Ghodake | 15-09-2021  | Add Business Unit Function.
export const SAVE_ROLE_DATA = 'save_role_data';
export const ADD_EDIT_ROLE_DETAILS = 'add_edit_role_details';
export const SAVE_MODULE_DATA = 'save_module_data';
export const ADD_EDIT_MODULE_DETAILS = 'add_edit_module_details';
export const ADD_EDIT_PRACTICE_AREA = 'add_edit_practice_area';
export const ADD_EDIT_SUB_BUSINESS_UNIT_FUNCTION = 'add_edit_sub_business_unit_function';
export const UPDATE_ADMIN_REDUX_DATA = 'update_admin_redux_data';
export const ADD_EDIT_LAYER_MAPPING_FUNCTION = 'add_edit_layer_mapping_function'
export const ADD_EDIT_SUB_LAYER_MAPPING_FUNCTION = 'add_edit_sub_layer_mapping_function'
export const SAVE_BUSINESS_UNIT_FUNCTION = 'save_business_unit_function';
export const SAVE_LAYER_MASTER_FUNCTION = 'save_layer_master_function';
export const SAVE_USER_ROLE_MASTER_FUNCTION = 'save_user_role_master_function';
export const SAVE_SUB_LAYER_MASTER_FUNCTION = 'save_sub_layer_master_function';
export const UPDATE_BUSINESS_FUNCTION_DATA = 'update_business_function_data';
export const UPDATE_LAYER_MASTER_DATA = "update_layer_master_data";
export const UPDATE_USER_ROLE_MASTER_DATA = "update_layer_master_data";
export const UPDATE_SUB_LAYER_MASTER_DATA = "update_sub_layer_master_data";
export const ADD_EDIT_EMAIL_MAPPING_DATA = 'add_edit_email';

export const UPDATE_FOLDER_MANAGEMENT_DATA = "update_folder_management_data";
export const SAVE_FOLDER_MANAGEMENT_FUNCTION = "save_folder_management_function"
export const UPDATE_FINANCIAL_YEAR = "update_financial_year";
export const ADD_EDIT_EMAIL_MAPPING = "add_edit_email_mapping"

export const HANDLE_TMLC_ADMIN_POPUP = 'handle_tmlc_admin_popup';
