import React, { useState } from 'react';
import PropTypes from 'prop-types'
import DateFnsUtils from "@date-io/date-fns";
import { connect } from "react-redux";
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker
} from "@material-ui/pickers";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faCalendarAlt } from "@fortawesome/pro-light-svg-icons";
import { NumericTextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { addUpdateObjectveKpi } from '../../redux/actions/okr/okractions';
import { getDateFormat, getBencharkDisplayDateOnly, addDays, removeDays } from '../../app/other/commonfunction'
import './productiveallocation.css';
library.add(faCalendarAlt);

function BenchMarkTable(props) {
    const { benchMarkData, unit, sortBy } = props;
    const [changeData, setData] = useState(false)
    //eslint-disable-next-line
    const [currncyData, setCurrencyData] = useState(props.OkrMasters.filter((t) => t.var_list_name === 'Currency'))/**nosonar */
    const onChangeData = (data, type, unitType, inx) => {//NOSONAR
        const { fillKpi } = props;
        document.getElementById("krBenchmkErr").innerHTML = "";
        /**
        * unitType = > Date
        */
        let temp = fillKpi;
        if (unitType === '1') {
            switch (type) {
                case "From":
                    temp.benchmark[inx].From = getDateFormat(data, data);
                    props.addUpdateObjectveKpi({ fillKpi: temp });
                    setData(!changeData);
                    break;
                case "To":
                    if (sortBy === '1') {
                        if (new Date(temp.benchmark[inx].From) >= new Date(data)) {
                            document.getElementById("krBenchmkErr").innerHTML = "The input date in Column 'To' should be greater than From date";
                            temp.benchmark[inx].To = null;
                            //eslint-disable-next-line
                            temp.benchmark.map((item, i) => {
                                if (inx + 1 <= i) {
                                    temp.benchmark[i].From = null;
                                    temp.benchmark[i].To = null;
                                }
                            });
                            props.addUpdateObjectveKpi({ fillKpi: temp });
                            setData(!changeData);
                            return;
                        }
                        temp.benchmark[inx].To = getDateFormat(data, data);
                        let len = benchMarkData.length - 1;
                        if (inx < len) {
                            if (new Date(temp.benchmark[inx].From) >= new Date(data)) {
                                //eslint-disable-next-line
                                temp.benchmark.map((item, i) => {/**nosonar*/
                                    if (inx + 1 <= i) {
                                        temp.benchmark[i].From = null;
                                        temp.benchmark[i].To = null;
                                    }
                                });
                            }
                            temp.benchmark[inx + 1].From = getDateFormat(addDays(data, 1), addDays(data, 1));
                        }
                        props.addUpdateObjectveKpi({ fillKpi: temp });
                    } else {
                        if (new Date(temp.benchmark[inx].From) <= new Date(data) && inx !== 0) {
                            document.getElementById("krBenchmkErr").innerHTML = "The input date in Column 'To' should be less than From date";
                            temp.benchmark[inx].To = null;
                            //eslint-disable-next-line
                            temp.benchmark.map((item, i) => {/**nosonar*/
                                if (inx + 1 <= i) {
                                    temp.benchmark[i].From = null;
                                    temp.benchmark[i].To = null;
                                }
                            });
                            props.addUpdateObjectveKpi({ fillKpi: temp });
                            setData(!changeData);
                            return;
                        }
                        temp.benchmark[inx].To = getDateFormat(data, data);
                        let len = benchMarkData.length - 1;
                        if (inx < len) {
                            temp.benchmark[inx + 1].From = getDateFormat(removeDays(data, 1), removeDays(data, 1));
                            if (new Date(temp.benchmark[inx].From) <= new Date(data)) {
                                //eslint-disable-next-line
                                temp.benchmark.map((item, i) => {/**nosonar*/
                                    if (inx + 1 <= i) {
                                        temp.benchmark[i].From = null;
                                        temp.benchmark[i].To = null;
                                    }
                                });
                            }

                            temp.benchmark[inx + 1].From = getDateFormat(removeDays(data, 1), removeDays(data, 1));
                        }
                        props.addUpdateObjectveKpi({ fillKpi: temp });
                    }
                    setData(!changeData);
                    break;
                case "Benchmark":
                    temp.benchmark[inx].benchMarkForYes = true;
                    //eslint-disable-next-line
                    temp.benchmark.map((item, i) => {
                        if (i === inx) {
                            item.benchMarkForYes = '1';
                        } else {
                            item.benchMarkForYes = '0';
                        }
                    })
                    props.addUpdateObjectveKpi({ fillKpi: temp });
                    setData(!changeData);
                    break;
                default:
            }
        } else {

            switch (type) {

                case "From":

                    temp.benchmark[inx].From = data.value;
                    props.addUpdateObjectveKpi({ fillKpi: temp });
                    setData(!changeData);

                    break;

                case "To":

                    if (sortBy === '1') {
                        if (temp.benchmark[inx].From >= data.value) {
                            document.getElementById("krBenchmkErr").innerHTML = `The input value in Column "To" should be greater than ${temp.benchmark[inx].From}`;
                            document.getElementById('krBenchmkErr').scrollIntoView();
                            temp.benchmark[inx].To = 0;
                            //eslint-disable-next-line
                            temp.benchmark.map((item, i) => {/**nosonar*/
                                if (inx + 1 <= i) {
                                    temp.benchmark[i].From = null;
                                    temp.benchmark[i].To = null;
                                }
                            });
                            props.addUpdateObjectveKpi({ fillKpi: temp });
                            setData(!changeData);
                            return;
                        }
                        if (inx === 0) { temp.benchmark[inx].From = 0; }
                        temp.benchmark[inx].To = data.value;
                        let len = benchMarkData.length - 1;
                        if (inx < len) {
                            temp.benchmark[inx + 1].From = data.value + 1;
                            if (temp.benchmark[inx].From >= data.value) {
                                //eslint-disable-next-line
                                temp.benchmark.map((item, i) => {/**nosonar*/
                                    if (inx + 1 <= i) {
                                        temp.benchmark[i].From = null;
                                        temp.benchmark[i].To = null;
                                    }
                                });
                            }
                            temp.benchmark[inx + 1].From = data.value + 0.01;
                        }
                        props.addUpdateObjectveKpi({ fillKpi: temp });
                    } else {
                        if ((temp.benchmark[inx].From <= data.value) && inx !== 0) {
                            document.getElementById("krBenchmkErr").innerHTML = `The input value in Column "To" should be less than ${temp.benchmark[inx].From}`;
                            document.getElementById('krBenchmkErr').scrollIntoView();
                            temp.benchmark[inx].To = 0;
                            //eslint-disable-next-line
                            temp.benchmark.map((item, i) => {/**nosonar*/
                                if (inx + 1 <= i) {
                                    temp.benchmark[i].From = null;
                                    temp.benchmark[i].To = null;
                                }
                            });
                            props.addUpdateObjectveKpi({ fillKpi: temp });
                            setData(!changeData);
                            return;
                        }
                        temp.benchmark[inx].To = data.value;
                        let len = benchMarkData.length - 1;
                        if (inx < len) {
                            if (temp.benchmark[inx].From <= data.value) {
                                //eslint-disable-next-line
                                temp.benchmark.map((item, i) => {/**nosonar*/
                                    if (inx + 1 <= i) {
                                        temp.benchmark[i].From = null;
                                        temp.benchmark[i].To = null;
                                    }
                                });
                            }
                            temp.benchmark[inx + 1].From = data.value - 0.01;
                        }
                        props.addUpdateObjectveKpi({ fillKpi: temp });
                    }
                    setData(!changeData);
                    break;

                case "Benchmark":
                    temp.benchmark[inx].benchMarkForYes = true;
                    //eslint-disable-next-line
                    temp.benchmark.map((item, ix) => {
                        if (ix === inx) {
                            item.benchMarkForYes = '1';
                        } else {
                            item.benchMarkForYes = '0';
                        }
                    })
                    props.addUpdateObjectveKpi({ fillKpi: temp });
                    setData(!changeData);
                    break;
                default:
            }

        }
    }

    const dateFilter = () => {//NOSONAR
        if (sortBy === '1') {//nosonar
            return (
                <table border='1'>
                    <thead>
                        <tr>
                            <th className={'benchTableHeader'}>Rating</th>
                            <th className={'benchTableHeader'}>From</th>
                            <th className={'benchTableHeader'}>To</th>
                            <th className={'benchTableHeader'}>Benchmark for Yes</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            benchMarkData.length &&
                            benchMarkData.map((item, inx) => (
                                <tr key={`tr${inx}`}>
                                    <td className={'benchTableData'}>{inx + 1}</td>
                                    <td className={''}>
                                        <div className='mt-xs'>
                                            {
                                                inx === 0 ?
                                                    <div style={{ textAlign: 'center' }}>Less than {"(<=)"}</div>
                                                    :
                                                    <div className="">
                                                        <div className="e-input-in-wrap">
                                                            <center>
                                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                    <KeyboardDatePicker
                                                                        style={{ width: "90%", height: "38px" }}
                                                                        inputVariant="outlined"
                                                                        margin="normal"
                                                                        id="benchmarkFrom"
                                                                        name="benchmarkFrom"
                                                                        format="dd-MMM-yyyy"
                                                                        KeyboardButtonProps={{
                                                                            "aria-label": "change date",
                                                                        }}
                                                                        disabled={sortBy === '1' /*nosonar*/ ? true : false}
                                                                        onChange={(e) => onChangeData(e, 'From', item.Type, inx)}
                                                                        value={item.From}
                                                                        data-msg-containerid="benchmarkFromError"
                                                                    />
                                                                </MuiPickersUtilsProvider>
                                                            </center>
                                                        </div>
                                                    </div>
                                            }
                                        </div>
                                    </td>
                                    <td className={''}>
                                        <div className='mt-xs'>
                                            {
                                                benchMarkData.length - 1 === inx ?
                                                    <div style={{ textAlign: 'center' }}>Greater than {"(>=)"}</div>
                                                    :
                                                    <div className="">
                                                        <div className="e-input-in-wrap">
                                                            <center>
                                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                    <KeyboardDatePicker
                                                                        style={{ width: "90%", height: "38px", marginLeft: 5 }}
                                                                        inputVariant="outlined"
                                                                        margin="normal"
                                                                        id="benchmarkFrom"
                                                                        name="benchmarkFrom"
                                                                        format="dd-MMM-yyyy"
                                                                        KeyboardButtonProps={{
                                                                            "aria-label": "change date",
                                                                        }}
                                                                        onChange={(e) => onChangeData(e, 'To', item.Type, inx)}
                                                                        value={item.To}
                                                                        disabled={sortBy === '1' && benchMarkData.length - 1 === inx ?/**nosonar */ true : false}
                                                                        data-msg-containerid="benchmarkFromError"
                                                                    />
                                                                </MuiPickersUtilsProvider>
                                                            </center>
                                                        </div>
                                                    </div>
                                            }
                                        </div>
                                    </td>
                                    <td className={'benchTableData'}>
                                        <div className='benchmarkButtonBlock'>
                                            <span className={item.benchMarkForYes === '1' ? 'benchmarkButton-active' : 'benchmarkButton-inactive'} onClick={(e) => onChangeData(e, 'Benchmark', item.Type, inx)}>Benchmark</span>
                                        </div>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            )
        } else {/**nosonar */
            return (
                <table border='1'>
                    <thead>
                        <tr>
                            <th className={'benchTableHeader'}>Rating</th>
                            <th className={'benchTableHeader'}>From</th>
                            <th className={'benchTableHeader'}>To</th>
                            <th className={'benchTableHeader'}>Benchmark for Yes</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            benchMarkData.length &&
                            benchMarkData.map((item, inx) => (
                                <tr key={`tr${inx}`}>
                                    <td className={'benchTableData'}>{inx + 1}</td>
                                    <td className={''}>
                                        <div className='mt-xs'>
                                            {
                                                inx === 0 ?
                                                    <div style={{ textAlign: 'center' }}>Greater than {"(>=)"}</div>
                                                    :
                                                    <div className="">
                                                        <div className="e-input-in-wrap">
                                                            <center>
                                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                    <KeyboardDatePicker
                                                                        style={{ width: "90%", height: "38px" }}
                                                                        inputVariant="outlined"
                                                                        margin="normal"
                                                                        id="benchmarkFrom"
                                                                        name="benchmarkFrom"
                                                                        format="dd-MMM-yyyy"
                                                                        className={"dateInput"}
                                                                        KeyboardButtonProps={{
                                                                            "aria-label": "change date",
                                                                        }}
                                                                        onChange={(e) => onChangeData(e, 'From', item.Type, inx)}
                                                                        disabled={inx === 0 || sortBy === '2'/**nosonar */ ? true : false}
                                                                        value={item.From}
                                                                        data-msg-containerid="benchmarkFromError"
                                                                    />
                                                                </MuiPickersUtilsProvider>
                                                            </center>
                                                        </div>
                                                    </div>
                                            }
                                        </div>
                                    </td>
                                    <td className={''}>
                                        <div className='mt-xs'>
                                            {
                                                benchMarkData.length - 1 === inx ?
                                                    <div style={{ textAlign: 'center' }}>Less than {"(<=)"}</div>
                                                    :
                                                    <div className="">
                                                        <div className="e-input-in-wrap">
                                                            <center>
                                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                                    <KeyboardDatePicker
                                                                        style={{ width: "90%", height: "38px" }}
                                                                        inputVariant="outlined"
                                                                        margin="normal"
                                                                        className={"dateInput"}
                                                                        id="benchmarkFrom"
                                                                        name="benchmarkFrom"
                                                                        format="dd-MMM-yyyy"
                                                                        KeyboardButtonProps={{
                                                                            "aria-label": "change date",
                                                                        }}
                                                                        onChange={(e) => onChangeData(e, 'To', item.Type, inx)}
                                                                        value={item.To}
                                                                        data-msg-containerid="benchmarkFromError"
                                                                        disabled={inx === benchMarkData.length - 1 && sortBy === '2' ?/**nosonar */ true : false}
                                                                    />
                                                                </MuiPickersUtilsProvider>
                                                            </center>
                                                        </div>
                                                    </div>
                                            }
                                        </div>
                                    </td>
                                    <td className={'benchTableData'}>
                                        <div className='benchmarkButtonBlock'>
                                            <span className={item.benchMarkForYes === '1' ? 'benchmarkButton-active' : 'benchmarkButton-inactive'} onClick={(e) => onChangeData(e, 'Benchmark', item.Type, inx)}>Benchmark</span>
                                        </div>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            )
        }
    }
    const numberFilter = () => {//NOSONAR
        if (sortBy === '1') {//nosonar

            return (
                <table border='1'>
                    <thead>
                        <tr>
                            <th className={'benchTableHeader'}>Rating</th>
                            <th className={'benchTableHeader'}>From</th>
                            <th className={'benchTableHeader'}>To</th>
                            <th className={'benchTableHeader'}>Benchmark for Yes</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            benchMarkData.length &&
                            benchMarkData.map((item, inx) => (
                                <tr key={`tr${inx}`}>
                                    <td className={'benchTableData'}>{inx + 1}</td>
                                    <td className={'benchTableData'}>
                                        <div>
                                            {
                                                inx === 0 ?
                                                    <div style={{ textAlign: 'center' }}>Less than {"(<=)"}</div>
                                                    :
                                                    <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                                        <NumericTextBoxComponent
                                                            // style={{ width: "70%", height: "38px" }}
                                                            id="benchmarkFrom"
                                                            cssClass="e-outline numberInputFont"
                                                            name="benchmarkFromErr"
                                                            // floatLabelType="Auto"
                                                            data-msg-containerid="benchmarkFromErr"
                                                            // placeholder="From "
                                                            disabled={sortBy === '1' ?/**nosonar */ true : false}
                                                            onChange={(e) => onChangeData(e, 'From', item.Type, inx)}
                                                            value={item.From}
                                                            showSpinButton={false}
                                                        />
                                                    </div>
                                            }
                                        </div>
                                    </td>
                                    <td className={'benchTableData'}>
                                        <div>
                                            {
                                                benchMarkData.length - 1 === inx ?
                                                    <div style={{ textAlign: 'center' }}>Greater than {"(>=)"}</div>
                                                    :
                                                    <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                                        <NumericTextBoxComponent
                                                            //  style={{ width: "70%", height: "38px" }}
                                                            id="benchmarkTo"
                                                            cssClass="e-outline numberInputFont"
                                                            name="benchmarkToErr"
                                                            //  floatLabelType="Auto"
                                                            data-msg-containerid="benchmarkToErr"
                                                            min={item.Type === '2' /**nosonar */ ? 0 : ''}
                                                            max={item.Type === '2' /**nosonar */ ? 100 : ''}
                                                            //  placeholder="To "
                                                            disabled={sortBy === '1' && benchMarkData.length - 1 === inx ?/**nosonar */ true : false}
                                                            onChange={(e) => onChangeData(e, 'To', item.Type, inx)}
                                                            value={item.To}
                                                            showSpinButton={false}
                                                        />
                                                    </div>
                                            }
                                        </div>
                                    </td>
                                    <td className={'benchTableData'}>
                                        <div className='benchmarkButtonBlock'>
                                            <span className={item.benchMarkForYes === '1' ? 'benchmarkButton-active' : 'benchmarkButton-inactive'} onClick={(e) => onChangeData(e, 'Benchmark', item.Type, inx)}>Benchmark</span>
                                        </div>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            )
        } else {//nosonar
            return (
                <table border='1'>
                    <thead>
                        <tr>
                            <th className={'benchTableHeader'}>Rating</th>
                            <th className={'benchTableHeader'}>From</th>
                            <th className={'benchTableHeader'}>To</th>
                            <th className={'benchTableHeader'}>Benchmark for Yes</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            benchMarkData.length &&
                            benchMarkData.map((item, inx) => (
                                <tr key={`tr${inx}`}>
                                    <td className={'benchTableData'}>{inx + 1}</td>
                                    <td className={'benchTableData'}>
                                        <div>
                                            {
                                                inx === 0 ?
                                                    <div style={{ textAlign: 'center' }}>Greater than {"(>=)"}</div>
                                                    :
                                                    <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                                        <NumericTextBoxComponent
                                                            id="benchmarkFrom"
                                                            cssClass="e-outline numberInputFont"
                                                            name="benchmarkFromErr"
                                                            //  floatLabelType="Auto"
                                                            data-msg-containerid="benchmarkFromErr"
                                                            min={item.Type === '2' ?/*nosonar*/ 0 : ''}
                                                            max={item.Type === '2' ?/*nosonar*/ 100 : ''}
                                                            showSpinButton={false}
                                                            //  placeholder="From "
                                                            //  disabled={inx === 0 /*nosonar*/? sortBy === '2' ? true : false : true }
                                                            disabled={inx === 0 || sortBy === '2' ?/**nosonar */ true : false}
                                                            onChange={(e) => onChangeData(e, 'From', item.Type, inx)}
                                                            value={item.From}
                                                        />
                                                    </div>
                                            }
                                        </div>
                                    </td>
                                    <td className={'benchTableData'}>
                                        <div>
                                            {
                                                benchMarkData.length - 1 === inx ?
                                                    <div style={{ textAlign: 'center' }}>Less than {"(<=)"}</div>
                                                    :
                                                    <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                                        <NumericTextBoxComponent
                                                            //  style={{ width: "70%", height: "38px" }}
                                                            id="benchmarkTo"
                                                            cssClass="e-outline numberInputFont"
                                                            name="benchmarkToErr"
                                                            //  floatLabelType="Auto"
                                                            data-msg-containerid="benchmarkToErr"
                                                            min={item.Type === '2' ? /*nosonar*/0 : ''}
                                                            max={item.Type === '2' ?/*nosonar*/100 : ''}
                                                            //  placeholder="To "
                                                            disabled={inx === benchMarkData.length - 1 && sortBy === '2' /*nosonar*/ ? true : false}
                                                            onChange={(e) => onChangeData(e, 'To', item.Type, inx)}
                                                            showSpinButton={false}
                                                            value={item.To}
                                                        />
                                                    </div>}

                                        </div>
                                    </td>
                                    <td className={'benchTableData'}>
                                        <div className='benchmarkButtonBlock'>
                                            <span className={item.benchMarkForYes === '1' ? 'benchmarkButton-active' : 'benchmarkButton-inactive'} onClick={(e) => onChangeData(e, 'Benchmark', item.Type, inx)}>Benchmark</span>
                                        </div>
                                    </td>
                                </tr>
                            ))
                        }
                    </tbody>
                </table>
            )

        }
    }
    const getCurrencyUnit = (Id) => {
        let data = currncyData.filter((t) => t.Id === Id);
        return data[0].var_optional1;
    }
    const viewOnlyFilter = () => {//NOSONAR
        let currencyUnit = "";
        if (benchMarkData.length > 0) {
            if (typeof benchMarkData[0].currency !== 'undefined' && benchMarkData[0].currency !== 'null' && benchMarkData[0].Type === '3') {
                currencyUnit = getCurrencyUnit(parseInt(benchMarkData[0].currency));
            } else {
                currencyUnit = "";
            }
        }
        return (
            <div className='col-lg-12 col-md-12 col-sm-12'>
                <table border='1' width='100%;'>
                    <thead>
                        <tr>
                            <th className={'benchTableHeader'}>Rating</th>
                            <th className={'benchTableHeader'}>From</th>
                            <th className={'benchTableHeader'}>To</th>
                            <th className={'benchTableHeader'}>Benchmark for Yes</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            benchMarkData.length > 0 &&
                            benchMarkData.map((item, inx) => (
                                <tr key={`tr${inx}`}>
                                    <td className={'benchTableData'}>{inx + 1}</td>
                                    <td className={'benchTableData'}>
                                        <div>
                                            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                                {item.sortBy === '1' && inx === 0 ? "Less than (<=)" : ""}
                                                {item.sortBy === '2' && inx === 0 ? "Greater than (>=)" : ""}

                                                {
                                                    inx !== 0 && 
                                                    <> 
                                                        { 
                                                        item.Type === '1' ? 
                                                            item.From === null ? /*nosonar*/"" : getBencharkDisplayDateOnly(new Date(item.From)) 
                                                            : 
                                                            item.From === "" || item.From === 0 || parseFloat(item.From) === 0.00 ?/*nosonar*/ item.From : currencyUnit + ' ' + new Intl.NumberFormat('en-IN').format(item.From) 
                                                        } 
                                                        { 
                                                            item.Type === '2' && parseFloat(item.From) !== 0.00 && inx !== 0 ? '%' : '' 
                                                        } 
                                                    </> 
                                                }

                                            </div>
                                        </div>
                                    </td>
                                    <td className={'benchTableData'}>
                                        <div>
                                            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                                {item.sortBy === '1' && inx === benchMarkData.length - 1 ? "Greater than (>=)" : ""}
                                                {item.sortBy === '2' && inx === benchMarkData.length - 1 ? "Less than (<=)" : ""}
                                                {
                                                    inx !== benchMarkData.length - 1 &&
                                                    <>
                                                        {
                                                            item.Type === '1' ?
                                                                item.To === null ? /*nosonar*/"" : getBencharkDisplayDateOnly(new Date(item.To))
                                                                :
                                                                item.To === "" || item.To === 0 || parseFloat(item.To) === 0.00 ?/*nosonar*/ item.To : currencyUnit + ' ' + new Intl.NumberFormat('en-IN').format(item.To)
                                                        }
                                                        {
                                                            item.Type === '2' && parseFloat(item.To) !== 0.00 && inx !== benchMarkData.length - 1 ? '%' : ''
                                                        }
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </td>
                                    <td className={'benchTableData'}>
                                        <div className='benchmarkButtonBlock pt-xs pb-xs'>
                                            <span className={item.benchMarkForYes === '1' ? 'benchmarkButton-active' : 'benchmarkButton-inactive'}>Benchmark</span>
                                        </div>
                                    </td>
                                </tr>
                            ))
                        }
                        {
                            benchMarkData.length === 0 && (
                                <tr key={`tr${1}`}>
                                    <td className={'benchTableData'}></td>
                                    <td className={'benchTableData'}></td>
                                    <td className={'benchTableData'}></td>
                                    <td className={'benchTableData'}></td>
                                </tr>

                            )
                        }
                    </tbody>
                </table>
            </div>
        )
    }
    return (
        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12" style={{ padding: "0px 16px  0px 15px", overflowY: 'auto' }}>
            {
                unit === '1' && dateFilter()
            }
            {
                (unit === '2' || unit === '3' || unit === '4') && numberFilter()
            }
            {
                unit === 'view' && viewOnlyFilter()
            }
        </div>
    )
}
const mapStateToProps = ({
    okrreducer
}) => {

    const { fillKpi, OkrMasters } = okrreducer;

    return {
        fillKpi, OkrMasters
    };
};
BenchMarkTable.propTypes = {
    fillKpi: PropTypes.object
};
BenchMarkTable.defaultProps = {
    fillKpi: {}
};
export default connect(mapStateToProps, { addUpdateObjectveKpi })(BenchMarkTable);
