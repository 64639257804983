import React, { useEffect, useRef, useState } from 'react'
import { HELPDESK_SUb_CATEGORY } from '../../../redux/constant/meetingcategoryconstant';
import ButtonComponent from '../../../utilities/buttoncomponent';
import SubCategory from '../../../components/helpdeskadmincomponents/subcategory';
import { connect } from 'react-redux';

const HelpdeskAdminPopupContainer = ({ changePopUpHandler, state }) => {
    const { masterId, editItemId } = state;
    const [heightWidthState, setState] = useState({ containerHeight: 0, width: 0 })

    const handleSaveRef = useRef(null)

    let handelSave = () => {
        handleSaveRef.current.onSaveData();
    };



    useEffect(() => {
        window.addEventListener("resize", updateDimensions()); // NOSONAR
    }, []);

    const updateDimensions = () => {
        const containerHeight = window.innerHeight - 120
        let width = document.getElementById('helpdesk-admin-container-root')?.clientWidth

        let finalWidth =
            isOneTwentyFiveInThirteenHundred ? width * 45 / 100
                : isOneTwentyFiveInFourteenHundred ? width * 42 / 100 :
                    isOneTwentyFiveInSixteenHundred ? width * 38 / 100 :
                        isThirteenHundred ? width * 36 / 100 :
                            isOneTwentyFiveScale || isSixteenHundred ? width * 25 / 100 : width * 23 / 100;


        setState((preState) => ({ ...preState, width: finalWidth, containerHeight: isThirteenHundred ? containerHeight - 2 : containerHeight }));
    }

    const isSixteenHundred = window.innerHeight < 750;
    const isOneTwentyFiveScale = window.innerHeight < 715;
    const isThirteenHundred = window.innerHeight < 618;
    const isOneTwentyFiveInSixteenHundred = window.innerHeight < 571;
    const isOneTwentyFiveInFourteenHundred = window.innerHeight < 571 && window.innerWidth < 1153;
    const isOneTwentyFiveInThirteenHundred = window.innerHeight < 465;

    const formNameHandler = () => {
        if (masterId === HELPDESK_SUb_CATEGORY) {
            document.title = "Sub Category";
            return "Sub Category";
        }
    };

    const renderSwitch = () => {
        if (masterId === HELPDESK_SUb_CATEGORY) {
            return <SubCategory state={state} handleSaveRef={handleSaveRef} />;
        }
    }

    return (
        <div
            id={`${formNameHandler()}Form`}
            className={"helpdesk-admin-popup-root"}
            style={{
                height: document.getElementById('helpdesk-admin-container-root')?.clientHeight,
                width: heightWidthState.width
            }}
        >
            <div className='helpdesk-admin-popup-header'>
                {`${(editItemId > 0 || editItemId !== null) ? "Edit" : "Add"} ` + `${document.title}`}
            </div>
            {renderSwitch()}
            <div
                className="col-sm-12 col-md-12 col-lg-12 pb-md pt-md helpdesk-admin-popup-footer"
            >
                <ButtonComponent
                    id="helpdesk-admin-popup-cancel-btn"
                    type="cancel"
                    content="Cancel"
                    variant="contained"
                    click={() => { changePopUpHandler({ type: 'close' }) }}
                    style={{
                        width: "90px", 
                        fontFamily: "Circular TT Book, Regular",
                        fontWeight: 500,
                        fontSize: 14
                    }}
                />
                <ButtonComponent
                    id="helpdesk-admin-popup-cancel-btn"
                    type="primary"
                    content="Save"
                    variant="contained"
                    click={() => { handelSave !== null && handelSave() }}
                    style={{
                        width: "90px", 
                        fontFamily: "Circular TT Book, Regular",
                        fontWeight: 500,
                        fontSize: 14
                    }}
                />
            </div>
        </div>
    )
}

const mapStateToProps = () => {
    return {}
}

export default connect(mapStateToProps, {})(HelpdeskAdminPopupContainer)