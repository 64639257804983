import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import {
  AccordionComponent,
  AccordionItemDirective,
  AccordionItemsDirective,
} from "@syncfusion/ej2-react-navigations";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
//NOSONAR import { CheckBoxComponent } from "@syncfusion/ej2-react-buttons";
import Scrollbars from "react-custom-scrollbars";
import { urlConfig } from "../../config/config";
import { setMeetingMaster } from "../../redux/actions/meetingmasteraction";
import {
  meetingAcceptance,
  getMeetingDetails,
} from "../../redux/actions/meetingbookingaction";
import { getDisplayDateOnly } from '../../app/other/commonfunction';
import { newFeaturesResult } from '../../app/other/commonlayoutfunction';

class Newfeature extends React.Component {
  constructor() {
    super();
    this.height = window.innerHeight - 170;
  }
  componentDidMount = async () => {
    const { newFeaturesData } = this.props
    if (newFeaturesData.length === 0) {
      const newFeatures = await newFeaturesResult();
      this.props.setMeetingMaster(newFeatures);
    }

    var elems = document.querySelectorAll(".dropdown-menu-right");

    [].forEach.call(elems, function (el) {
      el.className = el.className.replace("dropdown-menu-right", "");
    });
  }
  // eslint-disable-next-line
  UNSAFE_componentWillReceiveProps = (nextProps) => {
    if (nextProps.newFeatureDataStatus === "updated") {
      this.props.setMeetingMaster({ newFeatureDataStatus: "" });
    }
  };
  addDefaultSrc(ev) {
    ev.target.parentNode.innerHTML = '<span alt="" ></span>'
  }

  content = (data, acknowFlag) => {
    return (
      <div className="content">
        <ul>
          {data.length > 0 &&
            data.map((item, parentIndex) => (
              <div
                // eslint-disable-next-line
                key={`parentn_${parentIndex}`}
              >
                <li className="new-feature-header-text">
                  {item.HeaderText.replace(/(?:\r\n|\r|\n)/g, "\n")}
                </li>
                <ol>
                  <div>
                    {item.HeaderSubPoint.length > 0 &&
                      item.HeaderSubPoint.map((subPoits, index) => {
                        if (!subPoits.Imageflag) {
                          return (
                            <div
                              // eslint-disable-next-line
                              key={index}
                            >
                              <div>{subPoits.HeaderSubPointText}</div>
                              <br />
                            </div>
                          );
                        }
                        return (
                          <div
                            // eslint-disable-next-line
                            key={index}
                          >
                            {subPoits.HeaderSubPointText}
                            <br />
                            <center>
                              <img
                                onError={this.addDefaultSrc}
                                style={{
                                  textAlign: "center",
                                  objectFit: "cover",
                                  maxWidth: "100%",
                                }}
                                src={`${urlConfig.apiurl}newfeatures/${subPoits.ImageUrl}`}
                                alt="Enterprise Calendar"
                                title=""

                              />
                              <br />
                              <br />
                            </center>
                          </div>
                        );
                      })}
                  </div>
                </ol>
                {/*nosonar {
                    data.length === parentIndex+1 && acknowFlag === true &&
                  <div
                    className="col-md-12"
                    style={{ paddingTop: "0px", marginBottom: "-6px" }}
                  >
                    <div className="col-xs-2 col-sm-2 col-lg-5">
                      <div>
                        <CheckBoxComponent
                          style={{ color: "red" }}
                          // checked={radioButtonFalse}
                          // change={this.addressType}
                          label="I have read all the new feature list and I understand all."
                          name=""
                          value={1}
                        />
                      </div>
                    </div>
                  </div>                      
                  } */}
              </div>
            ))}
        </ul>
      </div>
    );
  };
  // eslint-disable-next-line
  closeOnFeatureSelect = () => {
    this.props.closeOnFeatureSelect()
  }
  render() {
    const { newFeaturesData } = this.props;
    return (
      <>
        <ul className="list-unstyled">
          <div>
            <div className="notificationBox">
              <div className='head'>Important Updates
                <div style={{ float: "right" }}>
                  <IconButton
                    style={{
                      color: "white",
                      marginRight: "-13px",
                      marginTop: "-5px",
                    }}
                    aria-label="close"
                    onClick={this.closeOnFeatureSelect.bind(this)}
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
              </div>
              {/* <div className="head">Important Updates</div> */}
              <Scrollbars className="messages-list-scrollbar">
                <AccordionComponent>
                  <AccordionItemsDirective>
                    {newFeaturesData.length > 0 &&
                      newFeaturesData.map((item) => {
                        return (
                          <AccordionItemDirective
                            key={item.VersionName}
                            style={{ backgroundColor: "black" }}
                            expanded="true"
                            header={`${item.VersionName}, Announcement On : ${item.releaseDate === null ? "Date is Not Available" : getDisplayDateOnly(new Date(item.releaseDate))}`}
                            content={() => this.content(item.VersionData, item.acknowFlag)}
                          />
                        );
                      })}
                  </AccordionItemsDirective>
                </AccordionComponent>
              </Scrollbars>
              <div className="bottom">
                For feedback/suggestions:{" "}
                <a
                  style={{ color: '#f94f5e ' }}
                  href="mailto:systems.feedback@cheersin.com"
                  className="mailtag"
                >
                  <u>systems.feedback@cheersin.com</u>
                </a>{" "}
                &nbsp;|&nbsp; For queries/issues:{" "}
                <a
                  style={{ color: '#f94f5e ' }}
                  href="mailto:systems.support@cheersin.com"
                  className="mailtag"
                >
                  <u>systems.support@cheersin.com</u>
                </a>
              </div>
            </div>
          </div>
        </ul>
      </>
    );
  }
}

Newfeature.propTypes = {
  newFeatureDataStatus: PropTypes.string,
  newFeaturesData: PropTypes.arrayOf(Object).isRequired,
};
Newfeature.defaultProps = {
  newFeatureDataStatus: "",
};
const mapStateToProps = ({ meetingmaster }) => {
  const { newFeaturesData, newFeatureDataStatus } = meetingmaster;
  return {
    newFeaturesData,
    newFeatureDataStatus,
  };
};
export default connect(mapStateToProps, {
  meetingAcceptance,
  getMeetingDetails,
  setMeetingMaster,
})(Newfeature);
