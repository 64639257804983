import React from "react";
import { connect } from "react-redux";
import { library } from "@fortawesome/fontawesome-svg-core";
import PropTypes from "prop-types";
import { faPlus, faPaperclip } from "@fortawesome/pro-light-svg-icons";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import { AutoCompleteComponent, ComboBoxComponent } from "@syncfusion/ej2-react-dropdowns";
import { HtmlEditor, Image, Inject, Link, QuickToolbar, RichTextEditorComponent, Toolbar, Count } from '@syncfusion/ej2-react-richtexteditor';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { FormValidator } from "@syncfusion/ej2-inputs";
import {
    pleaseEnterKPIName,
    // pleaseEnterDescription,pleaseEnterMeasure,measureFillDetails, 
    pleaseSelectVariableLink, pleaseEnterWeightage, pleaseSelectScope, pleaseSelectUser, pleaseEnterDescription,
    kpiFillDetails, descriptionFillDetails,
    variableLinkFillDetails, weightageFillDetails, ScopeFillDetails, selectUserFillDetails, Lable1, Lable2,
    variableLink, pleaseEnterMaxWeightage, pleaseEnterMinWeightage, MeasuremenrUnit, MeasuremenrSortBy
    , KRBenchmark
} from '../../assets/constants/constants';
import { addUpdateObjectveKpi, fillObjectiveRequest } from '../../redux/actions/okr/okractions';
import { cracterLimit, benchmarkFormatData } from '../../app/other/commonfunction';
import { Button, Dialog, DialogTitle } from "@material-ui/core";
import ErrorBoundary from "../error/errorboundary";
import KpiUploadFile from "../kpi/okrkpiupload";
import {
    DialogContent,
    DialogActions,
    // DialogTitleKPI,
} from "../customdialog/customdialog";
import fileIcon from "../../../src/assets/svg/fileIcon_jpeg.png"
import Tooltips from "../custommaterialtooltip/custommaterialtooltip";
import AddNewEmployeeInGrid from './addnewemployeeingrid';
import { urlConfig } from '../../config/config';
import BenchMarkTable from './benchmarktable';

const cssForScroll = `
  .MuiDialog-paperWidthSm {
    padding: 0px 8px 0px 0px;
}
`
library.add(faPlus);
class FillKPIDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            kpiTag: [],
            addAttachmentOpen: false,
            documentPath: "",
            scopeData: [],
            rerendarScopeType: false,
            addUsersDataSource: [],
            selectedUser: [],
            selectedUserIds: [],
            selectedKpiFiles: [],
            unit: null,
            sortBy: null,
            currencyData: [],
            currency: null,
            openMeasurement: false
        };
        this.descriptionTextBox = null;
        this.commentTxtbox = null;
        this.buttonKPIDisable = false;
        this.selectEmployee = null;
        this.getSelectedEmployee = this.getSelectedEmployee.bind(this);
        this.removeDataFromGrid = this.removeDataFromGrid.bind(this);
    }
    closeAttachmentPopup = () => {
        this.setState({ addAttachmentOpen: false });

    }
    openAttachmentPopup = () => {
        this.setState({ addAttachmentOpen: true });
    }
    updateDimensions() {
        const updateHeight = window.innerHeight - 150;
        this.setState({ height: updateHeight });
    }
    async componentDidMount() {
        this.updateDimensions();
        window.addEventListener("resize", this.updateDimensions.bind(this));

        try {
            const { OkrMasters, fillKpi, othersCheckAvailablity } = this.props;
            const scope = await OkrMasters.filter((item) => {
                return item.var_list_value === "Scope Type";
            });
            const currencyData = await OkrMasters.filter((item) => {
                return item.var_list_name === "Currency";
            });
            await this.setState({ currencyData: currencyData, scopeData: scope })
            let selectedUsers = [];
            fillKpi.userList.length > 0 &&
                await fillKpi.userList.map(async (item) => {
                    let data = await othersCheckAvailablity.filter((t) => t.UserId === item)
                    if (data.length > 0 && data[0] !== null && typeof data[0] !== 'undefined')
                        selectedUsers.push(data[0]);
                })
            if (typeof selectedUsers !== 'undefined' && selectedUsers !== null)
                this.setState({ selectedUser: selectedUsers })
            this.setAddUsserDataSource(fillKpi.scopeTypeId);
            if (fillKpi.benchmark != null) {
                let temp = fillKpi;
                let benchmarkData = benchmarkFormatData(fillKpi.benchmark);
                temp.benchmark = benchmarkData;
                this.props.addUpdateObjectveKpi({ fillKpi: temp });
                this.setState({
                    unit: benchmarkData[0].Type, sortBy: benchmarkData[0].sortBy,
                    currency: benchmarkData[0].currency === 'null' ? null : parseInt(benchmarkData[0].currency)
                })
            }

        } catch (error) {
            console.log(error);
        }
    }

    fillKPIDetails = (type) => {/**NOSONAR */
        const { fillKpi, authUser, employeeId, fyid, fillObjective, businessUserVariableImpact } = this.props;
        const { unit, sortBy } = this.state;
        if (unit !== null) {
            document.getElementById("krdescripionErr").innerHTML = "";
        }
        let options = {};
        if (businessUserVariableImpact > 0) {
            if ((fillKpi.scopeTypeId === 727 || fillKpi.scopeTypeId === 728)) {
                options = {
                    rules: {
                        kpiIdErr: {
                            required: [true, pleaseEnterKPIName],
                        },
                        // measureErr: {
                        //     required: [true, pleaseEnterMeasure],
                        // },
                        variableLinkErr: {
                            required: [true, pleaseSelectVariableLink],
                        },
                        // benchmarkForYesErr: {
                        //     required: [true, pleaseSelectBenchmarkForYes],
                        // },
                        weightageErr: {
                            required: [true, pleaseEnterWeightage],
                            max: [100, pleaseEnterMaxWeightage],
                            min: [0, pleaseEnterMinWeightage]
                        },
                        ScopeErr: {
                            required: [true, pleaseSelectScope],
                        },
                        UnitErr: {
                            required: [true, "Please select unit of measurement"],
                        },
                        SortByErr: {
                            required: [true, "Please select sort achievement"],
                        },
                        // selectUserErr: {
                        //     required: [true, pleaseSelectUser],
                        // },
                    }
                }
            } else {
                options = {
                    rules: {
                        kpiIdErr: {
                            required: [true, pleaseEnterKPIName],
                        },
                        // measureErr: {
                        //     required: [true, pleaseEnterMeasure],
                        // },
                        variableLinkErr: {
                            required: [true, pleaseSelectVariableLink],
                        },
                        // benchmarkForYesErr: {
                        //     required: [true, pleaseSelectBenchmarkForYes],
                        // },
                        weightageErr: {
                            required: [true, pleaseEnterWeightage],
                            max: [100, pleaseEnterMaxWeightage],
                            min: [0, pleaseEnterMinWeightage]
                        },
                        ScopeErr: {
                            required: [true, pleaseSelectScope],
                        },
                        UnitErr: {
                            required: [true, "Please select unit of measurement"],
                        },
                        SortByErr: {
                            required: [true, "Please select sort achievement "],
                        },
                    },
                };
            }
        } else {
            if ((fillKpi.scopeTypeId === 727 || fillKpi.scopeTypeId === 728)) {
                options = {
                    rules: {
                        kpiIdErr: {
                            required: [true, pleaseEnterKPIName],
                        },
                        // measureErr: {
                        //     required: [true, pleaseEnterMeasure],
                        // },
                        // variableLinkErr: {
                        //     required: [true, pleaseSelectVariableLink],
                        // },
                        // benchmarkForYesErr: {
                        //     required: [true, pleaseSelectBenchmarkForYes],
                        // },
                        weightageErr: {
                            required: [true, pleaseEnterWeightage],
                            max: [100, pleaseEnterMaxWeightage],
                            min: [0, pleaseEnterMinWeightage]
                        },
                        ScopeErr: {
                            required: [true, pleaseSelectScope],
                        },
                        UnitErr: {
                            required: [true, "Please select unit of measurement"],
                        },
                        SortByErr: {
                            required: [true, "Please select sort achievement "],
                        },
                        // selectUserErr: {
                        //     required: [true, pleaseSelectUser],
                        // },
                    }
                }
            } else {
                options = {
                    rules: {
                        kpiIdErr: {
                            required: [true, pleaseEnterKPIName],
                        },
                        // measureErr: {
                        //     required: [true, pleaseEnterMeasure],
                        // },
                        // variableLinkErr: {
                        //     required: [true, pleaseSelectVariableLink],
                        // },
                        // benchmarkForYesErr: {
                        //     required: [true, pleaseSelectBenchmarkForYes],
                        // },
                        weightageErr: {
                            required: [true, pleaseEnterWeightage],
                            max: [100, pleaseEnterMaxWeightage],
                            min: [0, pleaseEnterMinWeightage]
                        },
                        ScopeErr: {
                            required: [true, pleaseSelectScope],
                        },
                        UnitErr: {
                            required: [true, "Please select unit of measurement"],
                        },
                        SortByErr: {
                            required: [true, "Please select sort achievement"],
                        },
                    },
                };
            }
        }
        let benchmarkErr = false;
        if (unit !== null) {

            if (fillKpi.benchmark.length > 0) {
                let cnt = 0;
                //eslint-disable-next-line
                fillKpi.benchmark.map((item, i) => {
                    if (sortBy === '2') {
                        if ((item.From === null && i !== 0) || (item.To === null && i !== fillKpi.benchmark.length - 1)) {
                            benchmarkErr = true;
                        }
                    }
                    if (sortBy === '1') {
                        if ((item.From === null && i !== 0) || (item.To === null && i !== fillKpi.benchmark.length - 1)) {
                            benchmarkErr = true;
                        }
                    }
                    if (item.benchMarkForYes !== '0') {
                        cnt = 1;
                    }
                })
                if (cnt === 0) {
                    benchmarkErr = true;
                }
            }
            if (unit === '3') {
                options.rules["currencyErr"] = {
                    required: [true, 'please select currency'],
                }
            }
        }

        setTimeout(() => {
            const addFormObject = new FormValidator("#addNewKPIForm", options);
            try {
                let data = {};
                let scopeType = null;
                if (fillKpi.scopeTypeId === 725) {
                    scopeType = '1';
                }
                if (fillKpi.scopeTypeId === 726) {
                    scopeType = '2';
                }
                if (fillKpi.scopeTypeId === 727) {
                    scopeType = '3';
                }

                if ((fillKpi.scopeTypeId === 726 || fillKpi.scopeTypeId === 727) && fillKpi.userList.length === 0) {
                    document.getElementById('selectUserErr').innerHTML = pleaseSelectUser
                    return;
                }

                data.userId = authUser;
                data.empId = employeeId;
                data.type = 'kpi';
                data.objectiveLibId = fillObjective.objectiveId.toString();
                data.objectiveId = fillObjective.userObjectiveId.toString();
                data.objectiveTitle = fillObjective.objectiveName;
                data.effectiveMonth = fillObjective.effectiveMonth;
                data.effectiveTo = null;
                data.effectiveFrequency = fillObjective.effectiveFrequency;
                data.variablePercentage = fillObjective.variable;
                data.kpiLibIds = fillKpi.kpiId === null ? '0' : fillKpi.kpiId.toString();
                data.kpiIds = fillKpi.userKpiId === null ? '0' : fillKpi.userKpiId.toString();
                data.kpiTitle = fillKpi.kpiName;
                data.kpiDescription = fillKpi.description;
                data.kpiAttachment = fillKpi.kpiAttachment === null ? "" : fillKpi.kpiAttachment;
                data.kpiWeightage = fillKpi.weightage === null ? '' : fillKpi.weightage.toString();
                data.kpiVariableLinked = fillKpi.varibleLink === '' || fillKpi.varibleLink === null ? null : fillKpi.varibleLink.toString();
                data.kpiBenchmark = fillKpi.benchmark !== null ? JSON.stringify(fillKpi.benchmark) : 0;
                data.kpiScopeType = scopeType;
                data.kpiScopeUsers = fillKpi.userList.toString();
                data.isDraft = '0';
                data.fyid = fyid;
                data.measure = fillKpi.measure === null ? "" : fillKpi.measure;
                data.lable1 = fillKpi.lable1 === null ? "" : fillKpi.lable1;
                data.lable2 = fillKpi.lable2 === null ? "" : fillKpi.lable2;
                if (addFormObject.validate() && (fillKpi.description !== "" && fillKpi.description !== null && !benchmarkErr)) {
                    this.props.fillObjectiveRequest(data);
                    this.props.closeFillKPIDetails()
                } else {
                    if (fillKpi.description === "" || fillKpi.description === null) {
                        document.getElementById('krdescripionErr').innerHTML = pleaseEnterDescription
                        return;
                    }
                    if (benchmarkErr) {
                        document.getElementById('krBenchmkErr').innerHTML = KRBenchmark;
                        document.getElementById('krBenchmkErr').scrollIntoView();
                        return;
                    }
                }
            } catch (error) {
                console.log('error', error);
            }
        }, 100)
    }
    documentDetail = (fileNames) => {
        this.setState({
            fileNames,
        });
    };

    selectedFiles = (file) => {
        const { fillKpi } = this.props;
        const { rerendarScopeType } = this.state;
        let tempFillkpi = fillKpi;
        tempFillkpi.kpiAttachment = file.toString();
        this.props.addUpdateObjectveKpi({ fillKpi: tempFillkpi });
        this.setState({
            rerendarScopeType: !rerendarScopeType,
            // addUsersDataSource:addUsersDataSource
        })
        // this.setState({selectedKpiFiles:file})
    }
    viewUploadedAttachment = (filepath) => {
        const { employeeId } = this.props;
        return (
            <>
                {
                    filepath.map((attachedFile, attInd) => (
                        <span key={`attachment${attInd}`}>
                            <a
                                target="_blank"
                                href={
                                    attachedFile !== null && attachedFile !== ""
                                        ? `${urlConfig.tmlcApiUrl}document/kpi/${employeeId}/${attachedFile}`
                                        : ""
                                }
                                rel="noopener noreferrer"
                                download
                                style={{ textDecoration: 'none' }}
                            >
                                <img
                                    alt=""
                                    src={fileIcon}
                                    key={`img${attInd}`}
                                />
                                <span style={{ padding: "8px 4px 12px", color: '#F94F5E' }} title={attachedFile.substring(11, attachedFile.length).length > 35 ? attachedFile.substring(11, attachedFile.length).substr(0, 32) + '...' : attachedFile.substring(11, attachedFile.length)}>
                                    {attachedFile.substring(11, attachedFile.length).length > 35 ? attachedFile.substring(11, attachedFile.length).substr(0, 32) + '...' : attachedFile.substring(11, attachedFile.length)}
                                </span>
                            </a>
                        </span>



                    ))
                }
            </>
        )
    }
    changesKpiData = (event, type) => {
        const { fillKpi } = this.props;
        const { rerendarScopeType } = this.state;
        let temp = fillKpi;
        const { sortBy, unit, currency } = this.state;
        switch (type) {
            case "kpiName":
                temp.kpiName = event.value;
                this.props.addUpdateObjectveKpi({ fillKpi: temp })
                break;
            case "description":
                temp.description = event.value
                this.props.addUpdateObjectveKpi({ fillKpi: temp })
                break;
            case "weightage":
                temp.weightage = event.value
                this.props.addUpdateObjectveKpi({ fillKpi: temp })
                document.getElementById('krdescripionErr').innerHTML = "";
                break;
            case "benchMark":
                if (event.itemData !== null) {
                    temp.benchmark = event.itemData.Name
                    this.props.addUpdateObjectveKpi({ fillKpi: temp })
                } else {
                    temp.benchmark = null
                    this.props.addUpdateObjectveKpi({ fillKpi: temp })
                }
                break;
            case "variableLink":
                if (event.itemData !== null) {
                    temp.varibleLink = event.itemData.Value
                    this.props.addUpdateObjectveKpi({ fillKpi: temp })
                } else {
                    temp.varibleLink = null
                    this.props.addUpdateObjectveKpi({ fillKpi: temp })
                }
                break;
            case "measureName":
                temp.measure = event.value
                this.props.addUpdateObjectveKpi({ fillKpi: temp })
                break;
            case "lable1":
                temp.lable1 = event.value
                this.props.addUpdateObjectveKpi({ fillKpi: temp })
                break;
            case "lable2":
                temp.lable2 = event.value
                this.props.addUpdateObjectveKpi({ fillKpi: temp })
                break;
            case "scopeType":
                if (event.itemData !== null) {
                    temp.scopeTypeId = event.itemData.Id
                    this.props.addUpdateObjectveKpi({ fillKpi: temp })
                    this.setAddUsserDataSource(event.itemData.Id);
                }
                this.setState({
                    rerendarScopeType: !rerendarScopeType,
                    // addUsersDataSource:addUsersDataSource
                })
                break;
            case "unit":

                this.setBenchmarkData(event.itemData.Value, sortBy, null);
                break;
            case "sortBy":

                this.setBenchmarkData(unit, event.itemData.Value, currency);
                break;
            case "Currency Type":

                this.setBenchmarkData(unit, sortBy, event.itemData.Id);
                break;
            default:
        }
    }
    setBenchmarkData = (unit, sortBy, currency) => {
        const { fillKpi, ratingMecanismEnd } = this.props;
        let benchMarkData = [];
        let temp = fillKpi
        for (let x = 0; x < ratingMecanismEnd; x++) {
            benchMarkData.push({ Id: 0, Type: unit, From: null, To: null, benchMarkForYes: '0', sortBy: sortBy, currency: currency })
        }
        temp.benchmark = benchMarkData;
        this.props.addUpdateObjectveKpi({ fillKpi: temp })
        this.setState({ unit: unit, sortBy: sortBy, currency: currency });
    }
    setAddUsserDataSource = (Id) => {
        const { fillKpi, employeeId, othersCheckAvailablity } = this.props;
        const { rerendarScopeType } = this.state;
        let addUsersDataSource = []
        if (Id === 727) { // Within Function
            // document.getElementById('selectUserErr').innerHTML=""
            let departmentalText = othersCheckAvailablity.filter((el) => employeeId === el.UserId)
            addUsersDataSource = othersCheckAvailablity.filter((el) => el.Department === departmentalText[0].Department && employeeId !== el.UserId);
        } else if (Id === 726) {// Across Function
            // document.getElementById('selectUserErr').innerHTML=""
            addUsersDataSource = othersCheckAvailablity.filter((el) => employeeId !== el.UserId);
        } else {
            let tempFillKpi = fillKpi;
            tempFillKpi.userList = []
            this.props.addUpdateObjectveKpi({ fillKpi: tempFillKpi })
            this.setState({ selectedUser: [] })
        }
        this.setState({
            rerendarScopeType: !rerendarScopeType,
            addUsersDataSource: addUsersDataSource
        })
    }
    async getSelectedEmployee(e) {
        try {
            const { fillKpi } = this.props;
            const { selectedUser } = this.state;
            if (e.itemData !== null) {
                document.getElementById('selectUserErr').innerHTML = "";
                let tempFillData = fillKpi;
                let employeeData = [];
                let userIds = fillKpi.userList;
                if (selectedUser !== null) {

                    employeeData = selectedUser;
                    employeeData = employeeData.filter(
                        (t) => t.UserId !== e.itemData.UserId
                    );
                    userIds = userIds.filter(
                        (t) => t !== e.itemData.UserId
                    );

                    employeeData.push(e.itemData);
                    userIds.push(e.itemData.UserId);
                    tempFillData.userList = userIds;

                    this.setState({ selectedUser: employeeData }, () => {
                        setTimeout(() => {
                            this.selectEmployee.value = null;
                            this.selectEmployee.text = null;
                            // eslint-disable-next-line
                            this.props.addUpdateObjectveKpi({ fillKpi: tempFillData });
                        }, 50);
                    });
                }
            }
        } catch (error) {
            // eslint-disable-next-line
            console.log("error===>", error);
        }
    }
    removeDataFromGrid(data) {
        const { fillKpi } = this.props;
        const { selectedUser } = this.state;
        if (data.UserId !== null) {
            let tempFillData = fillKpi;
            let employeeData = [];
            let userIds = fillKpi.userList;
            if (selectedUser !== null) {

                employeeData = selectedUser;
                employeeData = employeeData.filter(
                    (t) => t.UserId !== data.UserId
                );
                userIds = userIds.filter(
                    (t) => t !== data.UserId
                );
                tempFillData.userList = userIds;
                this.setState({ selectedUser: employeeData }, () => {/**NOSONAR */
                    setTimeout(() => {/**NOSONAR */
                        this.selectEmployee.value = null;
                        this.selectEmployee.text = null;
                        // eslint-disable-next-line
                        this.props.addUpdateObjectveKpi({ fillKpi: tempFillData });
                    }, 50);
                });
            }
        }
    }
    render() {//nosonar
        const { documentPath, addAttachmentOpen, scopeData, addUsersDataSource, selectedUser, unit, sortBy, currencyData, currency, openMeasurement } = this.state;
        const { fillKpi, businessUserVariableImpact, open, showHideComponent } = this.props;
        let fileList = [];
        if (typeof fillKpi.kpiAttachment !== "undefined" && fillKpi.kpiAttachment !== "" && fillKpi.kpiAttachment !== null && fillKpi.kpiAttachment.length > 0) {
            fileList = fillKpi.kpiAttachment.split(",");
        }
        return (
            <div>
                <style>{cssForScroll}</style>
                <Dialog
                    open={open}
                    fullWidth
                    maxWidth="xl"

                >
                    <DialogTitle>
                        <span className="add-new-self-header" style={{ marginTop: 15 }}>{"Key Result"} </span>
                    </DialogTitle>
                    <DialogContent>
                        <form id="addNewKPIForm" method="Post" style={{ margin: "-11px 0px 0px 7px" }}>
                            <div className='col-xs-12 col-sm-12 col-lg-12 col-md-12'>
                                <div className='col-xs- col-sm-12 col-lg-6 col-md-6'>
                                    <div className="row">
                                        <div className="col-xs- col-sm-12 col-lg-12 col-md-12" style={{ padding: "0px 42px 0px 16px" }}>
                                            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                                <TextBoxComponent
                                                    id="kpiId"
                                                    cssClass="e-outline numberInputFont"
                                                    data-msg-containerid="kpiIdErr"
                                                    name="kpiIdErr"
                                                    floatLabelType="Auto"
                                                    placeholder="Key Result *"
                                                    value={fillKpi.kpiName}
                                                    onChange={(event) => this.changesKpiData(event, 'kpiName')}
                                                    htmlAttributes={{ maxlength: "100", minLength: 1 }}
                                                    input={(e) => cracterLimit(e, `kpiTypeCharactor`, '100')}
                                                    readonly={showHideComponent === "draftCentralOKR" || showHideComponent === "activeCentralOKR" ? true : false}
                                                />
                                                <div style={{ margin: '0px -52px 4px 0px' }} id="kpiIdErr" className="e-input-group e-float-icon-left error-div" />
                                                <div id='kpiTypeCharactor' style={{ float: 'right', fontSize: 12, margin: '0px 0px 8px 0px' }} className="error-div" />
                                            </div>
                                            <span style={{ padding: "4px 0px 0px 8px", position: "absolute" }}>
                                                <Tooltips titleMessage={kpiFillDetails} />
                                            </span>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12" style={{ padding: "0px 42px 0px 16px" }}>
                                            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                                {/* <span style={{color:'#999999'}}>Description</span> */}
                                                <RichTextEditorComponent
                                                    className="refreshUIRichextBox numberInputFont"
                                                    placeholder='Description'
                                                    id={`descripion`}
                                                    name={`descripion`}
                                                    data-msg-containerid={`krdescripionErr`}
                                                    value={fillKpi.description}
                                                    floatLabelType="Auto"
                                                    maxLength={1000}
                                                    showCharCount={true}
                                                    readonly={showHideComponent === "draftCentralOKR" || showHideComponent === "activeCentralOKR" ? true : false}
                                                    change={(event) => this.changesKpiData(event, 'description')}
                                                >
                                                    <Inject services={[Toolbar, Image, Link, Count, HtmlEditor, QuickToolbar]} />
                                                </RichTextEditorComponent>
                                                <div style={{ float: 'left' }} id={`krdescripionErr`} className="error-div" />
                                            </div>
                                            <span style={{ padding: "4px 0px 0px 8px", position: "absolute" }}>
                                                <Tooltips titleMessage={descriptionFillDetails} />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                {/* Second Half */}
                                <div className='col-xs-12 col-sm-12 col-lg-6 col-md-6'>
                                    <div className="row">
                                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                            {businessUserVariableImpact > 0 &&
                                                <div className="col-xs-12 col-sm-4 col-lg-3 col-md-3" style={{ padding: "0px 34px 2px 16px" }}>
                                                    <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                                        <ComboBoxComponent
                                                            id="variableLink"
                                                            fields={{ text: "Name", value: "Value" }}
                                                            cssClass="e-outline numberInputFont"
                                                            data-msg-containerid="variableLinkErr"
                                                            name="variableLinkErr"
                                                            floatLabelType="Auto"
                                                            placeholder="Variable Link *"
                                                            htmlAttributes={{ maxlength: 100 }}
                                                            dataSource={variableLink}
                                                            value={fillKpi.varibleLink === undefined || fillKpi.varibleLink === null ? "" : fillKpi.varibleLink.toString()}
                                                            change={(event) => this.changesKpiData(event, 'variableLink')}
                                                        />
                                                        <div
                                                            id="variableLinkErr"
                                                            className="e-input-group e-float-icon-left error-div"
                                                        />

                                                    </div>
                                                    <span style={{ padding: "4px 0px 0px 8px", position: "absolute" }}>
                                                        <Tooltips titleMessage={variableLinkFillDetails} />
                                                    </span>
                                                </div>
                                            }

                                            <div className="col-xs-12 col-sm-4 col-lg-4 col-md-4" style={{ padding: "0px 8px 2px 15px" }}>
                                                <div className="col-xs-12 col-sm-12 col-lg-10 col-md-10" style={{ padding: 0, }}>
                                                    <TextBoxComponent
                                                        id="weightage"
                                                        cssClass="e-outline numberInputFont"
                                                        data-msg-containerid="weightageErr"
                                                        name="weightageErr"
                                                        floatLabelType="Auto"
                                                        placeholder="Weightage % *"
                                                        onChange={(event) => this.changesKpiData(event, 'weightage')}
                                                        value={fillKpi.weightage}
                                                    />
                                                    <div
                                                        // style={{ position: "fixed" }}
                                                        id="weightageErr"
                                                        className="e-input-group e-float-icon-left error-div"
                                                    />
                                                </div>
                                                <span style={{ padding: "4px 8px 4px 8px", position: "absolute" }}>
                                                    <Tooltips titleMessage={weightageFillDetails} />
                                                </span>
                                            </div>
                                            <div className="col-xs-12 col-sm-4 col-lg-4 col-md-4" style={{ padding: "0px 8px 2px 15px" }}>
                                                <div className="col-xs-12 col-sm-12 col-lg-10 col-md-10 measurementHeadingText" style={{ padding: 0, }}>
                                                    Frequency
                                                </div>
                                                <div className="col-xs-12 col-sm-12 col-lg-10 col-md-10" style={{ padding: 0, }}>
                                                    {fillKpi.reviewFrequency}    
                                                </div>
                                        </div>
                                    </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12" style={{ padding: "0px 42px 2px 16px" }}>
                                            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                                <TextBoxComponent
                                                    id="lable"
                                                    cssClass="e-outline numberInputFont"
                                                    data-msg-containerid=""
                                                    name=""
                                                    floatLabelType="Auto"
                                                    placeholder="Reference point for Achievement – 1"
                                                    // htmlAttributes={{ maxlength: "50", minLength: 1 }}
                                                    onChange={(event) => this.changesKpiData(event, 'lable1')}
                                                    value={fillKpi.lable1}
                                                // input={(e) => cracterLimit(e, `reference1`, '50')}
                                                />
                                                <div style={{ margin: '0px -52px 4px 0px' }} id="" className="e-input-group e-float-icon-left error-div" />
                                                {/* <div id='reference1' style={{ float: 'right', fontSize: 12, margin: '0px 0px 8px 0px' }} className="error-div" /> */}
                                            </div>
                                            <span style={{ padding: "4px 0px 0px 8px", position: "absolute" }}>
                                                <Tooltips titleMessage={Lable1} />
                                            </span>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12" style={{ padding: "42px 42px 2px 16px" }}>
                                            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                                <TextBoxComponent
                                                    id=""
                                                    cssClass="e-outline numberInputFont"
                                                    data-msg-containerid=""
                                                    name=""
                                                    floatLabelType="Auto"
                                                    placeholder="Reference point for Achievement – 2"
                                                    // htmlAttributes={{ maxlength: "50", minLength: 1 }}
                                                    // input={(e) => cracterLimit(e, `reference2`, '50')}
                                                    onChange={(event) => this.changesKpiData(event, 'lable2')}
                                                    value={fillKpi.lable2}
                                                />
                                                <div style={{ margin: '0px -52px 4px 0px' }} id="measureErr" className="e-input-group e-float-icon-left error-div" />
                                                {/* <div id='reference2' style={{ float: 'right', fontSize: 12, margin: '0px 0px 8px 0px' }} className="error-div" /> */}
                                            </div>
                                            <span style={{ padding: "4px 0px 0px 8px", position: "absolute" }}>
                                                <Tooltips titleMessage={Lable2} />
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* Second Row*/}
                            <div className='col-xs-12 col-sm-12 col-lg-12 col-md-12 pl-sm'>
                                <div className='row' style={{ marginTop: 0 }}>
                                    <div className='col-xs-12 col-sm-12 col-lg-12 col-md-11 label-new pending-for-approval-accord-header' style={{ paddingLeft: "16px" }}>
                                        <span className='addAttachment-hover'>

                                            <FontAwesomeIcon icon={faPaperclip} className="mr-sm"
                                                onClick={(e) => this.openAttachmentPopup()}
                                                style={{ cursor: 'pointer' }} />
                                            <span style={{ color: '#B7B7BB', cursor: 'pointer' }} onClick={(e) => this.openAttachmentPopup()}>
                                                Add attachment
                                            </span>
                                        </span>

                                        {

                                            fileList !== "" && fileList !== null &&
                                            <div className="col-xs-12 col-sm-12 col-lg-12" style={{ padding: "0px 0px 5px" }}>
                                                {this.viewUploadedAttachment(fileList)}
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            {/* Third Row */}
                            <div className='col-xs-12 col-sm-12 col-lg-12 col-md-12'>
                                <div className='col-xs- col-sm-12 col-lg-6 col-md-6' style={{ borderRight: '1px solid #B7B7BB' }} >
                                    <div className="row">
                                        <span className="measurementHeadingText">Add Measurement Matrix for the Key Result</span>
                                        {/* <span> <Tooltips titleMessage={measureFillDetails} /></span> */}
                                        <span className='pl-xs'><Button title="Previous Measurement" onClick={() => this.setState({ openMeasurement: true })}>
                                            {/* eslint-disable-next-line */}
                                            <a>View Previously updated</a></Button></span>
                                    </div>
                                    <div className="row pt-sm">
                                        <div className='col-lg-4 col-md-4'>
                                            <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                                <ComboBoxComponent
                                                    id="Unit"
                                                    fields={{ text: "Name", value: "Value" }}
                                                    cssClass="e-outline numberInputFont"
                                                    data-msg-containerid="UnitErr"
                                                    name="UnitErr"
                                                    floatLabelType="Auto"
                                                    placeholder="Unit of Measurement *"
                                                    htmlAttributes={{ maxlength: 100 }}
                                                    dataSource={MeasuremenrUnit}
                                                    select={(event) => this.changesKpiData(event, 'unit')}
                                                    value={unit}
                                                    showClearButton={false}
                                                // allowCustom={false}
                                                />
                                                <div
                                                    id="UnitErr"
                                                    className="e-input-group e-float-icon-left error-div"
                                                />
                                            </div>
                                        </div>
                                        <div className='col-lg-4 col-md-4'>
                                            {

                                                unit === '3' &&
                                                <>
                                                    <div className="col-xs-12 col-sm-12 col-lg-11 col-md-11" style={{ padding: '0px 3px 4px 0px' }}>
                                                        <ComboBoxComponent
                                                            id="currency"
                                                            fields={{ text: "var_list_value", value: "Id" }}
                                                            cssClass="e-outline numberInputFont"
                                                            data-msg-containerid="currencyErr"
                                                            name="currencyErr"
                                                            floatLabelType="Auto"
                                                            placeholder="Currency Type *"
                                                            htmlAttributes={{ maxlength: 100 }}
                                                            dataSource={currencyData}
                                                            select={(event) => this.changesKpiData(event, 'Currency Type')}
                                                            value={currency}
                                                            showClearButton={false}
                                                        // allowCustom={false}
                                                        />

                                                        <div
                                                            id="currencyErr"
                                                            className="e-input-group e-float-icon-left error-div"
                                                        />
                                                    </div>
                                                    <span style={{ padding: "4px 3px 0px 5px", position: "absolute" }}>
                                                        <Tooltips titleMessage={"Currency"} />
                                                    </span>
                                                </>
                                            }
                                        </div>
                                        <div className='col-lg-4 col-md-4'>
                                            <div className="col-xs-12 col-sm-12 col-lg-11 col-md-11" style={{ padding: '0px 3px 4px 0px' }}>
                                                <ComboBoxComponent
                                                    id="SortBy"
                                                    fields={{ text: "Name", value: "Value" }}
                                                    cssClass="e-outline numberInputFont"
                                                    data-msg-containerid="SortByErr"
                                                    name="SortByErr"
                                                    floatLabelType="Auto"
                                                    placeholder="Sort Achievement *"
                                                    htmlAttributes={{ maxlength: 100 }}
                                                    dataSource={MeasuremenrSortBy}
                                                    select={(event) => this.changesKpiData(event, 'sortBy')}
                                                    value={sortBy}
                                                    showClearButton={false}
                                                // allowCustom={false}
                                                />

                                                <div
                                                    id="SortByErr"
                                                    className="e-input-group e-float-icon-left error-div"
                                                />
                                            </div>
                                            {/* <span style={{ padding: "0px 3px 0px 5px", position: "absolute" }}>
                                                <Tooltips titleMessage={"Sort Achievement"} />
                                            </span> */}
                                        </div>
                                    </div>
                                    <div className="row">
                                        <span className='pl-md'>Note: Rating 1 - Least Performance & Rating 5 - Best Performance</span>
                                        {
                                            unit !== null && sortBy !== null &&
                                            <>
                                                <BenchMarkTable benchMarkData={fillKpi.benchmark} unit={unit} sortBy={sortBy} />
                                                <div id='krBenchmkErr' className="e-input-group e-float-icon-left error-div pl-md" />
                                            </>
                                        }
                                    </div>
                                </div>
                                {/* Second Half */}
                                < div className='col-xs- col-sm-12 col-lg-6 col-md-6' >
                                    <div className="row">
                                        <span className="measurementHeadingText">Scope</span>
                                        <span> <Tooltips titleMessage={ScopeFillDetails} /></span>
                                    </div>
                                    <div className="row pt-sm">
                                        <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12" style={{ paddingTop: "8px" }}>
                                            <div className="col-xs-12 col-sm-6 col-lg-6 col-md-6" style={{ padding: "0px 34px 2px 16px" }}>
                                                <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                                    <ComboBoxComponent
                                                        id="Scope"
                                                        fields={{ text: "var_optional1", value: "Id" }}
                                                        cssClass="e-outline numberInputFont"
                                                        data-msg-containerid="ScopeErr"
                                                        name="ScopeErr"
                                                        floatLabelType="Auto"
                                                        placeholder="Scope *"
                                                        htmlAttributes={{ maxlength: 100 }}
                                                        dataSource={scopeData}
                                                        value={fillKpi.scopeTypeId === 0 ? "" : fillKpi.scopeTypeId}
                                                        change={(event) => this.changesKpiData(event, 'scopeType')}
                                                    />
                                                    <div
                                                        id="ScopeErr"
                                                        className="e-input-group e-float-icon-left error-div"
                                                    />
                                                </div>
                                                <span style={{ padding: "0px 0px 0px 8px", position: "absolute" }}>
                                                    <Tooltips titleMessage={ScopeFillDetails} />
                                                </span>
                                            </div>
                                            {
                                                (fillKpi.scopeTypeId === 726 || fillKpi.scopeTypeId === 727) &&
                                                <div className="col-xs-12 col-sm-7 col-lg-5 col-md-8" style={{ padding: "0px 2px 0px 10px" }}>
                                                    <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                                        <AutoCompleteComponent
                                                            id="selectUser"
                                                            name="selectUserErr"
                                                            ref={(autocomplete) => {
                                                                this.selectEmployee = autocomplete;
                                                                return this.selectEmployee;
                                                            }}
                                                            cssClass="e-outline numberInputFont"
                                                            floatLabelType="Auto"
                                                            fields={{ value: "nameWithDepartment", Id: "UserId" }}
                                                            placeholder="Select Users *"
                                                            dataSource={addUsersDataSource}
                                                            select={this.getSelectedEmployee}
                                                            data-msg-containerid="selectUserErr"
                                                        />
                                                        <div
                                                            id="selectUserErr"
                                                            className="e-input-group e-float-icon-left error-div pb-sm"
                                                        />
                                                    </div>
                                                    <span style={{ padding: "0px 0px 0px 14px", position: "absolute" }}>
                                                        <Tooltips titleMessage={selectUserFillDetails} />
                                                    </span>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className='col-xs-12 col-sm-12 col-lg-12 col-md-12'>
                                            {
                                                (fillKpi.scopeTypeId === 726 || fillKpi.scopeTypeId === 727) &&
                                                <>
                                                    {
                                                        selectedUser.length > 0 ?
                                                            <div className="row" style={{ margin: "10px 26px 16px 16px" }}>
                                                                <AddNewEmployeeInGrid userSelectedData={selectedUser} removeDataFromGrid={this.removeDataFromGrid} showHideColumn={'show'} />
                                                            </div>
                                                            :
                                                            <div className="row" style={{ margin: "10px 26px 16px 16px", border: "1px solid #DBDBDD" }} >
                                                                <div style={{ padding: '5%', height: "345px" }}>
                                                                    <div className='new-productivity-allocation-no-data-header'>No Data Found</div>
                                                                    <div className='new-productivity-allocation-no-data-sub-header pt-lg'>You don't have any items here. Select User to see the data.</div>
                                                                </div>
                                                            </div>
                                                    }
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div >
                            </div >
                        </form >
                    </DialogContent >
                    <DialogActions>
                        <div style={{ float: "right", padding: "0px 36px 0px 0px" }}>
                            <Button
                                style={{ width: "120px" }}
                                className="cancel-button"
                                variant="contained"
                                onClick={() => this.props.closeFillKPIDetails()}
                            >
                                Cancel
                            </Button>
                            <Button
                                style={{ width: "120px" }}
                                className="remove-button-shadow"
                                color="primary"
                                variant="contained"
                                onClick={this.fillKPIDetails}
                            >
                                Save
                            </Button>

                        </div>
                    </DialogActions>
                </Dialog >

                {/* Kpi File upload */}
                < div style={{ width: "350px" }
                }>
                    <Dialog
                        aria-labelledby="uploading-title"
                        open={addAttachmentOpen}
                        fullWidth
                        width="1000"
                    >
                        <span className="add-new-self-header" style={{ padding: "15px 10px" }}>Upload Key Result Attachment</span>
                        <ErrorBoundary>
                            <DialogContent dividers>
                                <div>
                                    <div className="row card-layout">
                                        <div className="Upload">
                                            <div className="Content">
                                                <div style={{ textAlign: "-webkit-center" }}>
                                                    <KpiUploadFile
                                                        documentPath={documentPath}
                                                        documentDetail={this.documentDetail}
                                                        type="kpi"
                                                        // kpiId={this.rowId}
                                                        allowmultiple
                                                        selectedFiles={this.selectedFiles}
                                                        fileList={fileList}
                                                    />
                                                </div>
                                                <div />
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        id="uploadErr"
                                        className="e-input-group e-float-icon-left error-div"
                                    />
                                </div>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    style={{ width: "120px" }}
                                    className="cancel-button"
                                    variant="contained"
                                    onClick={this.closeAttachmentPopup}
                                >
                                    {" "}
                                    Cancel{" "}
                                </Button>
                                <Button
                                    style={{ width: "120px", margin: "0px 6px 0px 0px" }}
                                    className="remove-button-shadow"
                                    color="primary"
                                    variant="contained"
                                    onClick={this.closeAttachmentPopup}
                                >
                                    {" "}
                                    Save{" "}
                                </Button>
                            </DialogActions>
                        </ErrorBoundary>
                    </Dialog>
                </div >
                <div style={{ width: "350px" }}>
                    <Dialog
                        aria-labelledby="uploading-title"
                        open={openMeasurement}
                        fullWidth
                        maxWidth='sm'
                    >
                        <span className="add-new-self-header" style={{ padding: "15px 10px" }}><b>Previous Measurement</b></span>
                        <ErrorBoundary>
                            <DialogContent dividers>
                                <div className="col-xs-12 col-sm-12 col-lg-12 col-md-12">
                                    <span style={{ color: '#999999' }}>Measurement</span>
                                    <RichTextEditorComponent
                                        className="refreshUIRichextBox"
                                        placeholder='Measurement Matrix for the Key Result'
                                        id={`measureName`}
                                        name={`measureErr`}
                                        data-msg-containerid={`measureErr`}
                                        value={fillKpi.measure}
                                        maxLength={500}
                                        showCharCount={true}
                                        readonly={true}
                                        change={(event) => this.changesKpiData(event, 'measureName')}
                                    >
                                        <Inject services={[Toolbar, Image, Link, Count, HtmlEditor, QuickToolbar]} />
                                    </RichTextEditorComponent>
                                    <div style={{ float: 'left' }} id={`measureErr`} className="error-div" />
                                </div>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    style={{ width: "120px", right: -8 }}
                                    className="cancel-button"
                                    variant="contained"
                                    onClick={() => this.setState({ openMeasurement: false })}
                                >
                                    Cancel
                                </Button>
                            </DialogActions>
                        </ErrorBoundary>
                    </Dialog>
                </div>
            </div >
        );
    }
}
FillKPIDetails.propTypes = {
    authUser: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    employeeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    objId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    kpiId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    kpiName: PropTypes.oneOfType([PropTypes.string]),
    kpiNewTags: PropTypes.oneOfType(PropTypes.object, PropTypes.string),
    kpiAttachment: PropTypes.oneOfType(PropTypes.object, PropTypes.string),
    kpiDescription: PropTypes.oneOfType([PropTypes.string]),
    objKpiAddUpdateType: PropTypes.oneOfType([PropTypes.string]),
    fillKpi: PropTypes.oneOfType([PropTypes.object]),
    OkrMasters: PropTypes.oneOfType(PropTypes.object, PropTypes.string),
    ratingMecanismEnd: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    draftedBusinessObjKpi: PropTypes.oneOfType(PropTypes.object, PropTypes.string),
    othersCheckAvailablity: PropTypes.oneOfType(PropTypes.object, PropTypes.array),
    businessUserVariableImpact: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    open: PropTypes.bool.isRequired,
    showHideComponent: PropTypes.oneOfType([PropTypes.string]),
};
FillKPIDetails.defaultProps = {
    authUser: "",
    employeeId: "",
    kpiName: "",
    kpiNewTags: [],
    kpiDescription: "",
    showHideComponent: "",
    kpiAttachment: [],
    objId: 0,
    kpiId: 0,
    objKpiAddUpdateType: "",
    fillKpi: {},
    OkrMasters: [],
    ratingMecanismStart: 0,
    ratingMecanismEnd: 0,
    draftedBusinessObjKpi: [],
    othersCheckAvailablity: [],
    businessUserVariableImpact: 0,
    open: false,
};
const mapStateToProps = ({ auth, okrreducer, meetingmaster }) => {
    const { authUser } = auth;
    const { othersCheckAvailablity } = meetingmaster;
    const { kpiName, kpiNewTags, kpiDescription, kpiAttachment, objId, kpiId, objKpiAddUpdateType, fillKpi, OkrMasters, ratingMecanismEnd, draftedBusinessObjKpi, employeeId, fyid, fillObjective, businessUserVariableImpact, ratingCentralMecanismEnd } = okrreducer;
    return {
        authUser,
        kpiName, kpiNewTags, kpiDescription, kpiAttachment, objId, kpiId, objKpiAddUpdateType, fillKpi, OkrMasters, ratingMecanismEnd, draftedBusinessObjKpi, employeeId, fyid, othersCheckAvailablity, fillObjective, businessUserVariableImpact, ratingCentralMecanismEnd
    };
};

export default connect(mapStateToProps, { addUpdateObjectveKpi, fillObjectiveRequest })(FillKPIDetails);
