/**
 * Author: Vivek Khodade.
 * Created:08-May-2020
 * Last Modify:08-May-2020
 * Modified By:Vivek Khodade
 */
import React, { Component } from "react";
import NotificationContainer from "../../../../components/notification/notifycontainer";
import { connect } from "react-redux";
import { setOrhChartDetaila } from '../../../../redux/actions/entryexit/orgchartaction';
import OrgChartMasters from '../../../../components/separation/orgchartcomponents/orgchartmasters';

class OrgChartMaster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      gridHeight: (window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight) - 295
    };
  }
  updateDimensions = () => {
    let activeGridHeight = window.innerHeight - 63 ;
    this.setState({ gridHeight: activeGridHeight });
  }
  componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions);
  }
  render() {    
    return (    
      <div
        className="row"
        style={{
          padding: "10px 38px 10px 38px",
          background: "#eee",
          height:this.state.gridHeight,
        }}
      >
      <div
        className="row"
        style={{
          padding: "10px 10px 5px 10px",
          background: "#fff",
          border:'1px solid #c3bbbb',
          height:"100%",
        }}
      >
        <NotificationContainer />
        <div className="row-xs-12 row-sm-12 row-lg-12 row-md-12">
          <>
          <OrgChartMasters /> 
          </>
        </div>
      </div>
      </div>
    );
  }
}
const mapStateToProps = ({ auth, orgchartreducer }) => {
  const { authUser } = auth;
  const { orgchartupdateStatus } = orgchartreducer;
  return { authUser, orgchartupdateStatus };
};

export default connect(mapStateToProps, { setOrhChartDetaila })(
  OrgChartMaster
);
