/**
 * @author ML Vivek Khodade
 * 
 */
import {
    ADD_NEW_SERVICE_REQUEST,
    ADD_UPDATE_HELP_DESK_REDUX,
    HANDLE_HELPDESK_ADMIN_POPUP,
    HELPDESK_CREATE_USER,
    HELPDESK_REDUX_UPDATE,
    UPDATEHELPDESK_USER_INFO
  } from "../../constant/helpdesk/helpdeskconstant";
  
  const INIT_STATE = {
    submitNewRequestStatus:'',
    dataReload: true,
    loading: false,
    dataUpdated: false,
    uploadDocsLoading:false,
    topTicketData:[],
    isRequestFormDataAdded:false
  };
  
  // eslint-disable-next-line
  export default (state = INIT_STATE, action) => { //NOSONAR
    switch (action.type) {
    case HANDLE_HELPDESK_ADMIN_POPUP:
    case  ADD_UPDATE_HELP_DESK_REDUX :
    case  HELPDESK_CREATE_USER :
    case HELPDESK_REDUX_UPDATE:
    case UPDATEHELPDESK_USER_INFO:
    case  ADD_NEW_SERVICE_REQUEST :{
        return {
          ...state,
          ...action.payload,
        };
      }
      default:
        return state;
    }
  };
  