import React, { Component } from 'react';
import { secToTime } from '../../app/other/commonfunction';
import PropTypes from "prop-types";
import { TreeGridComponent, ColumnsDirective, ColumnDirective, Freeze, Inject, Resize, Sort } from '@syncfusion/ej2-react-treegrid';
import { TIB_AVERAGE_REPORT } from "../../redux/constant/meetingcategoryconstant";
import "../../app/containers/tibr/tibr.css";
const SAMPLECSS = `
.e-grid .e-rowcell{
  border-style: solid;
  border-width: 1px 0 0 0;
  display: table-cell;
  font-size: 11px !important;
  line-height: 18px !important;
  overflow: hidden;
  padding: 5px 0px 5px 0px !important;
  vertical-align: middle;
  /* white-space: pre-wrap !important; */
  width: auto;
}
.e-treegrid .e-rowcell.customcss{
    padding-left: 5px !important;
}

.customcss3{
    font-weight:bold;
}
.e-rowcell.day_count_style{
    color: #a6a6a6  !important;
}
.day_count_style > .e-headercelldiv.e-headercelldiv-treegrid-custom > span.e-headertext{    
    padding:1px !important    
}
.month_count_style > .e-headercelldiv.e-headercelldiv-treegrid-custom > span.e-headertext{    
    padding:1px !important    
}
.customcssPA > .e-headercelldiv.e-headercelldiv-treegrid-custom > span.e-headertext{    
    padding:6px !important    
}
`
class TreeGrid extends Component {
    constructor(props) {
        super(props);
        this.state = {
            changeData: false,
            gridWidth: 500,
            gridHeight: 500,
            isLoaded: false,
            height: (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight) - 220,
            width: (window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) - 100
        };
        this.data = {};
        this.aggregateCellInfo = this.aggregateCellInfo.bind();
        this.beforeColumnsRender = this.beforeColumnsRender.bind(this);
        this.queryCellInfo = this.queryCellInfo.bind(this);
        this.onresize = this.onresize.bind(this);
        this.averageData = null;
        this.gridObj = null;
        this.rowHeight = 15;
        this.gridWidth = 500;
        this.gridHeightAccordingToWidth = 0;
        this.customAttributesPA = {class: "customcssPA"};
        this.customAttributes = {class: "customcss"};
        this.customAttributes2 = {class: "day_count_style"};
        this.customAttributes3 = {class: "customcss3"};
        this.customAttributes4= {class: "month_count_style"};
    }
    componentDidMount() {
        const { reportMonths, reportData } = this.props;
        this.commonSetting(reportMonths, reportData);
    }
    UNSAFE_componentWillReceiveProps(newProps) {
        const { reportData, reportMonths } = newProps;
        this.commonSetting(reportMonths, reportData);
    }
    commonSetting(reportMonths, reportData){ // NOSONAR
        this.gridHeightAccordingToWidth = 0;
        this.averageData = reportData;
        var noOfMonths = reportMonths.length;
        // -- var noOfCols = noOfMonths * 2;
        // -- var widthToAdd = noOfCols * 55;
        var widthToAdd = noOfMonths * 100;
        var staticWidth = 555;
        this.gridWidth = staticWidth + widthToAdd;
        if(this.gridWidth > window.innerWidth){
            this.gridWidth = window.innerWidth - 100;
            this.gridHeightAccordingToWidth = 20;
        }
        var expandedRowsNumber = this.averageData.length;
        var adjustableHeight;
        if(expandedRowsNumber === 5) adjustableHeight = 2;
        else adjustableHeight = (expandedRowsNumber < 5 && expandedRowsNumber > 3) ? 1 : 0;
        var customGridHeight = (expandedRowsNumber * 29) + adjustableHeight;
        this.gridHeight = customGridHeight;
        if(this.gridHeight > 500){
            this.gridHeight = 500;
        }
        this.gridHeight = this.gridHeight + this.gridHeightAccordingToWidth;
        this.setState({gridWidth: this.gridWidth, gridHeight: this.gridHeight});
        setTimeout(() => {
            const ele = document.getElementsByClassName("e-headercelldiv");
            for(let i in ele) {
              if(!isNaN(parseInt(i, 10))){
                let index = parseInt(i, 10);
                ele[index].classList.add("e-headercelldiv-treegrid-custom");
              }
            }
            var elem = document.getElementsByClassName("e-content");
            for(let i in elem) {
              if(!isNaN(parseInt(i, 10))){
                let index = parseInt(i, 10);
                if (elem[index].style.getPropertyValue("display") === 'flex')
                  elem[index].style.removeProperty("display");
              }
            }
            if(this.gridObj)    this.gridObj.enableCollapseAll = true;
        }, 1000);
    }
    onresize(e) {      
        var expandedRowsNumber = this.gridObj.getVisibleRecords().length;
        var customGridHeight = (expandedRowsNumber * 29) + 2;
        this.gridHeight = customGridHeight;
        if(customGridHeight > 500){
            this.gridHeight = 500;
        }
        this.gridHeight = this.gridHeight + this.gridHeightAccordingToWidth;
        this.setState({ gridHeight: this.gridHeight});
    } 
    aggregateCellInfo = (args, page) => {
        try {
            if(page === TIB_AVERAGE_REPORT) {            
                if (args.fieldName === 'TIB') {
                    args.skipFormatting = true;
                    args.value = secToTime(parseInt(args.value, 10));
                } else if (args.fieldName === 'User') {
                    args.skipFormatting = true;
                    if (args.row.hasChild === false && args.value === 1) args.value = '';
                }
            }
        } catch (error) {
            console.log('Error in aggregateCellInfo');
        }
    }

    beforeColumnsRender(args) {
        const { column0Width } = this.props;
        args.columns[0].width = column0Width;
    }

    queryCellInfo(args) {
        /* --
        var arr = [8,9,10,11,12];
        const { cell, data } = args;
        if(data[0].colIndex === 0)
            cell.setAttribute("style", "text-align:center; color:black !important;");
        */
    }

    dataBound(){
        // Functionality on data bound
    }
    render() {
        const { reportMonths, reportType } = this.props;
        var reportTypeText = 'Exclude Weekend Report';
        if(reportType === 16)   reportTypeText = 'Include Weekend Report';
        return (
            this.averageData && this.averageData.length > 0 && 
            <>
                <div className="e-label" style={{fontWeight:'bold', color:'gray', paddingBottom:10}}>{reportTypeText}</div>
                <style>{SAMPLECSS}</style>
                <TreeGridComponent ref={(grid) => { this.gridObj = grid }} dataBound={this.dataBound} dataSource={this.averageData}  allowSelection={true}  
                allowResizing={true} allowSorting={true} childMapping='subtask' treeColumnIndex={0} gridLines="Both" rowHeight={this.rowHeight} // frozenColumns={5} 
                // height="500"
                height={this.state.gridHeight}
                enableCollapseAll={true} width={this.state.gridWidth}
                collapsed={this.onresize.bind(this)} expanded={this.onresize.bind(this)}
                >
                  <ColumnsDirective>
                    <ColumnDirective field='entityName' headerText='Bucket' width='180' allowResizing={false}></ColumnDirective>
                    <ColumnDirective field='avgHours' headerText='Average Hours' width='55' textAlign='Center' customAttributes={this.customAttributes3} allowResizing={false}></ColumnDirective>
                    <ColumnDirective field='service_division' headerText='Practice Area / Sales Unit / Sub Function' width='130' format='yMd' textAlign='Left' customAttributes={this.customAttributesPA}  allowResizing={false} clipMode='EllipsisWithTooltip'></ColumnDirective>
                    <ColumnDirective field='reporting_head' headerText='Reporting Head' width='110' format='yMd' textAlign='Left' customAttributes={this.customAttributes} allowResizing={false} clipMode='EllipsisWithTooltip'></ColumnDirective>
                    <ColumnDirective field='empCount' headerText='Team Member Count' width='60' textAlign='Center' allowResizing={false}></ColumnDirective>
                    {
                        reportMonths.map((data, index) => (
                            <ColumnDirective className="specialClass" key={index}
                                columns={[
                                            { field: 'Days'+data.Month+data.Year, headerText: 'Day Count',  textAlign: 'Center', width: '45', customAttributes:this.customAttributes2 },
                                            { field: 'tib'+data.Month+data.Year, headerText: 'Monthly Avg',  textAlign: 'Center', width: '55' ,customAttributes:this.customAttributes4}
                                        ]} 
                                headerText={data.Month.slice(0, 3) +' '+data.Year} textAlign='Center'>
                            </ColumnDirective>
                        ))
                    }
                  </ColumnsDirective>
                  <Inject services={[Freeze, Resize, Sort]}/>
                </TreeGridComponent>
            </>
        )
    }
}

TreeGrid.propTypes = {
    PivotViewId: PropTypes.string,
    dataSourceSettings: PropTypes.arrayOf(Object),
    showFieldList: PropTypes.bool,
    showGroupingBar : PropTypes.bool,
    width: PropTypes.string,
    height: PropTypes.string,
    gridSettings : PropTypes.arrayOf(Object),
    allowConditionalFormatting : PropTypes.bool,
    allowNumberFormatting : PropTypes.bool,
    page: PropTypes.string,
    column0Width: PropTypes.number,
  };
  TreeGrid.defaultProps = {
    PivotViewId: 'PivotView',
    showFieldList: true,
    showGroupingBar: true,
    allowConditionalFormatting: true,
    allowNumberFormatting: true,
    width: '100%',
    height: '500',
    column0Width: 140,
  };
export default TreeGrid