export const MEETING_SAVE = "meeting_save"
export const MEETING_SAVE_ERROR = "meeting_save_error"
export const MEETING_SAVED = "meeting_saved"
export const MEETING_GET_DETAILS = "meeting_get_details"
export const MEETING_BOOK_UPDATE_STATE = "meeting_book_update_state"
export const MEETING_CREATE = "meeting_create"
export const MEETING_ACCEPTANCE = "meeting_acceptance"
export const MEETING_CANCEL = "meeting_cancel"
export const SEND_MEETING_NOTIFICATION_MAIL = "send_meeting_notification_mail"
export const SAVE_MEETING_TEMPLATE = 'save_meeting_template'
export const EC_REPORT_USERS = 'ec_report_users'

 
