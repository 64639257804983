import React, { useState, useEffect, useRef } from 'react';
import { connect } from "react-redux";
import services from '../../services/okr/okrservices';
import {
  GridComponent,
  ColumnsDirective,
  ColumnDirective,
  DetailRow,
  Inject,
  // Page,
  InfiniteScroll,
  Filter,
  Sort,
} from "@syncfusion/ej2-react-grids";
import {
  getGridColumn
} from "../../app/other/gridcolumns";

const XYAXISCSS = `
.e-grid .e-headercell{
  border-style: solid;
  border-width: 1px 0 0 0;
  display: table-cell;
  font-size: 1rem !important;
  font-weight: 500 !important;
  color: #000000 !important;
  vertical-align: middle;
  /* white-space: pre-wrap !important; */
  // width: auto;
  // text-transform: capitalize;
  background-color: #fbfbfb !important;
  // margin-left: 5rem !important;
}

.e-grid .e-rowcell{
  border-style: solid;
  border-width: 1px 0 0 0;
  display: table-cell;
  font-size: 1rem !important;
  font-weight: 500 !important;
  color: #000000 !important;
  line-height: 1.5rem !important;
  // padding: .5rem 2rem !important;
  vertical-align: middle;
  /* white-space: pre-wrap !important; */
  width: auto;
  // text-transform: capitalize;
}

.e-headercontent {
  border-left: .9rem solid #fbfbfb !important;
  border-right: 1.8rem solid #fbfbfb !important;
  background-color: #fbfbfb !important;
  // margin: -.5rem 0 0 0 !important;
  // padding: 0 0 .5rem 0 !important;
  vertical-align: middle;
  // height: 3rem !important;
}

tr.e-columnheader {
  height: 2.9rem !important;
  // margin: -.5rem 0 0 0 !important;
}

@media screen and (max-width: 1366px) and (max-height: 768px), screen and (max-width: 1440px) and (max-height: 900px) {
  .e-grid .e-headercell{
    border-style: solid;
    border-width: 1px 0 0 0;
    display: table-cell;
    font-size: 12px !important;
    font-weight: 500 !important;
    color: #000000 !important;
    vertical-align: middle;
    /* white-space: pre-wrap !important; */
    width: auto;
    // text-transform: capitalize;
    background-color: #fbfbfb !important;
  }
  
  .e-grid .e-rowcell{
    border-style: solid;
    border-width: 1px 0 0 0;
    display: table-cell;
    font-size: 12px !important;
    font-weight: 500 !important;
    color: #000000 !important;
    line-height: 1.5rem !important;
    padding: .5rem 2rem !important;
    // vertical-align: middle;
    /* white-space: pre-wrap !important; */
    // width: auto;
    // text-transform: capitalize;
  }
}
`;

function ComplianceReportGrid(props) {
  const [teamMemberRankingData, setTeamMemberRankingData] = useState([]);
  const [gridHeight, setGridHeight] = useState(null);

  const gridInstance = useRef();

  useEffect(() => {
    if (window.innerHeight < 650) {
      setGridHeight((window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight) / (2.54))
    } else {
      setGridHeight((window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight) / (1.70))
    }

    getOkRCompilanceData()
  }, []);
  const { authUser, } = props
  const getOkRCompilanceData = async () => {
    let data = await services.getOKRCompilanceData(authUser, 44, 23, 5);
    setTeamMemberRankingData(data.Result)
  }

  const dataBound = () => {
    gridInstance.current.hideScroll();
  };


  return (
    <div className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <style>{XYAXISCSS}</style>
      <GridComponent
        id="compliance-report-grid"
        rowHeight={44.9}
        allowTextWrap={true}
        height={gridHeight}
        ref={gridInstance}
        dataBound={dataBound}
        pageSettings={{
          pageSize: 11
        }}

        dataSource={teamMemberRankingData}
        enableHover={false}
      >
        <ColumnsDirective>
          {getGridColumn("complianceReport").map((item) => {
            return (
              <ColumnDirective
                key={item.field}
                textAlign={item.align}
                field={item.field}
                headerText={item.colName}
                width={item.width}
              />
            );
          }
          )}
        </ColumnsDirective>
        <Inject services={[DetailRow, Filter, Sort, InfiniteScroll]} />
      </GridComponent>
    </div>
  )
}
const mapStateToProps = ({ auth, okrratingreducer }) => {
  const { authUser } = auth;
  const { fyId, monthOfRating, empId } = okrratingreducer
  return { authUser, fyId, monthOfRating, empId };
};

export default connect(mapStateToProps, {})(ComplianceReportGrid);
