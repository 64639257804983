import React, { Component } from 'react';
import { connect } from 'react-redux';
import SetupContainer from '../../containers/lna/setupcontainer';
import ClusterSetup from '../../containers/lna/clustersetuppopupcontainer';
import { dencrypt } from '../../other/commonfunction';
import { getLnASymbolData } from '../../../app/other/commonlayoutfunction';
import { updateLnAData } from '../../../redux/actions/lna/lnaaction'
import queryString from "query-string";
import { getLnAData } from '../../other/commonlayoutfunction';

class SetupLayout extends Component {
  constructor(props) {
    super(props)
    this.state = {
      masterId: 0,
      openPopup: "close",
      editClusterId: null,
      weekDayUniqueData: {}
    }
  }

  async componentDidMount() {
    let { authUser, attendanceData } = this.props;
    this.getData()
    this.getCompanyData()
    if (attendanceData.length === 0)
      getLnASymbolData(authUser, this.props.updateLnAData);
  }

  getData = async () => {
    try {
      this.setMasterId()
      const { dayBreakData, authUser, colourMaster } = this.props;
      if (dayBreakData.length === 0 || colourMaster.length === 0) {
        const lnaMastersData = await getLnAData(authUser, "FC");
        if (lnaMastersData) {
          this.props.updateLnAData({ ...lnaMastersData })
        }
      }
      this.setState({ isLoaded: true })
    } catch (error) {
      console.log(error)
    }
  }

  getCompanyData = async () => {
    try {
      this.setMasterId()
      const { companyName, authUser } = this.props;
      if (companyName.length === 0) {
        const lnaMastersData = await getLnAData(authUser, "FC");
        if (lnaMastersData) {
          this.props.updateLnAData({ ...lnaMastersData })
        }
      }
      this.setState({ isLoaded: true })
    } catch (error) {
      console.log(error)
    }
  }

  setMasterId = () => {
    try {
      const { masterId } = this.state
      const params = queryString.parse(this.props.location.search);
      if (typeof params.masterId !== 'undefined' && params.masterId !== null) {
        if (masterId !== dencrypt(params.masterId))
          this.setState({ masterId: dencrypt(params.masterId) })
      }
    } catch (error) {
    }
  }

  componentDidUpdate(newProps) {
    try {
      this.setMasterId();
      if (this.props.lnaMasterFlag === "refresh") {
        const { authUser } = this.props;
        this.props.updateLnAData({ lnaMasterFlag: "" })
        getLnASymbolData(authUser, this.props.updateLnAData);
      }
    } catch (error) {
      console.log("? ~ file: setuplayout.jsx:87 ~ SetupLayout ~ componentDidUpdate ~ error", error)
    }
  }

  changePopUpHandler = (type = "close", editClusterId = null) => {
    if (type === "open") {
      this.setState({ openPopup: "add", editClusterId: null });
    } else if (type === "close") {
      this.setState({ openPopup: "close", editClusterId: null });
    } else if (type === "edit") {
      this.setState({ openPopup: "edit", editClusterId });
    }
  }


  render() {
    const { openPopup, masterId, editClusterId } = this.state
    const { history } = this.props

    return (
      <>
        <div>
          <SetupContainer
            popUpHandler={this.popUpHandler}
            changePopUpHandler={this.changePopUpHandler}
            openPopup={openPopup}
            editClusterId={editClusterId}
            masterId={masterId}
            history={history}
          />
        </div>

        {(openPopup === "add" || openPopup === "edit") &&
          <div>
            <ClusterSetup
              popUpHandler={this.popUpHandler}
              changePopUpHandler={this.changePopUpHandler}
              openPopup={openPopup}
              editClusterId={editClusterId}
              masterId={masterId}
              history={history}
            />
          </div>}

      </>
    );
  }
}





const mapStateToProps = ({ meetingcategory, lnareducer, auth, router }) => {
  const { categoryName } = meetingcategory;
  const { authUser } = auth;
  const { location } = router;
  const {
    dayBreakData, symbolData, lnaMasterFlag, companyName, colourMaster
  } = lnareducer;
  const { attendanceData } = symbolData
  return {
    categoryName,
    authUser,
    dayBreakData, colourMaster,
    location,
    attendanceData,
    lnaMasterFlag,
    companyName
  }
}
export default connect(mapStateToProps, { updateLnAData })(SetupLayout);
