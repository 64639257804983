import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBarsFilter, faCircleXmark } from "@fortawesome/pro-light-svg-icons";
import { faCheckCircle, faEye } from "@fortawesome/free-solid-svg-icons";
import { updateLnAData, changeLeaveApprovalStatus } from '../../../../redux/actions/lna/lnaaction';
import { TooltipComponent } from '@syncfusion/ej2-react-popups';
import { pleaseSelectFyId } from '../../../../assets/constants/constants'
import { DropDownButtonComponent } from "@syncfusion/ej2-react-splitbuttons";

import {
    GridComponent,
    ColumnsDirective,
    ColumnDirective,
    Filter,
    Inject,
    Sort,
    Page,
} from "@syncfusion/ej2-react-grids";
import {
    getGridColumn,
    CANDIDATE_STATUS,
    ADMIN_MODULE_ACTION,
    LEAVE_APPROVAL_EMPLOYEE_NAME,
    DEPARTMENT_NAME,
    LEAVE_CLUSTER_SWIPE_TYPE,
    LEAVE_CLUSTER_LEAVE_TYPE,
    REASON,
    LEAVE_APPROVAL_ROLE
} from "../../../../app/other/gridcolumns";
import LnAService from '../../../../services/lna/lnaservice';
import { connect } from 'react-redux';
import ProgressBar from '../../../progressbar/progressbar';
import ErrorTabMessage from '../../../errormessage/errormessage';
import ConfirmationPopup from '../../../../utilities/confirmationpopup';

function LeaveApprovalTab(props) {
    const [gridHeight, setGridHeight] = useState((window.innerHeight ||
        document.documentElement.clientHeight ||
        document.body.clientHeight) - 348);
    const [gridWidth, setGridWidth] = useState((window.innerWidth || //NOSONAR
        document.documentElement.clientWidth ||
        document.body.clientWidth) - 84);
    const [isLeaveIdsSelected, setLeavesFlag] = useState(false);
    const [leaveIndex, setLeaveIndex] = useState(null);
    const [approvalData, setApprovalData] = useState([]);

    const [state, setState] = useState({
        leaveId: 0,
        status: "",
        open: false,
        title: "",
        message: "",
    });
    const [approvalStates, setApprovalStates] = useState({
        total: 0,
        approved: 0,
        pending: 0,
        rejected: 0
    });

    useEffect(() => {
        const isSixteenEighty = window.innerHeight < 932; //NOSONAR
        const isSixteenHundred = window.innerHeight < 782; //NOSONAR
        const isOneTwentyFiveScale = window.innerHeight < 747; //NOSONAR
        const isOneTwentyFiveInSixteenEighty = window.innerHeight < 723; //NOSONAR
        const isThirteenHundred = window.innerHeight <= 657; //NOSONAR
        const isOneTwentyFiveInSixteenHundred = window.innerHeight < 603; //NOSONAR
        const isOneThousandTwoEighty = window.innerHeight < 569; //NOSONAR

        let minusHeight =
            isOneTwentyFiveInSixteenEighty ? 330 : isOneThousandTwoEighty ? 280 : isOneTwentyFiveInSixteenHundred ? "34px" : isThirteenHundred ? 320 : isOneTwentyFiveScale ? 330 : isFourteenHundred ? 300 : isSixteenHundred ? 320 :
                isSixteenEighty ? 380 : 348;

        setGridHeight((window.innerHeight ||
            document.documentElement.clientHeight ||
            document.body.clientHeight) - minusHeight)

        setGridWidth((window.innerWidth || //NOSONAR
            document.documentElement.clientWidth ||
            document.body.clientWidth) - 84)
        refreshGridFunc();
    }, []);

    useEffect(() => {
        if (leaveIndex != null) {
            if (leaveIndex !== -1) {
                if (Object.keys(props.leaveApprovalInfo).length > 0) { updateLeaveData() }
            } else {
                setState({
                    ...state,
                    leaveId: 0,
                    status: "",
                    open: false, title: "",
                    message: "",
                })
                props.reloadPage()
                setLeaveIndex(null)
            }
        } else {

            setApprovalData(props.approvalData, () => refreshGridFunc())
        }
    }, [props.approvalData, props.leaveApprovalInfo]);

    useEffect(() => {
        getApprovalDataCount(approvalData);
        dataSourceChanged(`${props.type}Grid`)
        refreshGridFunc()
    }, [approvalData])

    useEffect(() => {
        try {
            if (props.leaveApprovelStatus === 'processing') {
                const data = state;
                setState({
                    ...data, leaveId: 0,
                    // status: "",
                    open: false, title: "",
                    message: "",
                });
                // props.updateLnAData({ leaveApprovelStatus: "" })
            } else if (props.leaveApprovelStatus === 'updated' || props.leaveApprovelStatus === 'sync') {
                if (props.leaveApplicationId > 0) {
                    const data = state;
                    const syncData = {
                        userId: props.authUser,
                        actionType: props.type,
                        optionalId: props.leaveApplicationId.toString(),
                        optionalData: data.status
                    }
                    LnAService.syncLeavedetails(syncData)
                    setState({ ...data, status: "", });
                }
                dataSourceChanged(`${props.type}Grid`, 1000)
                props.updateLnAData({ leaveApprovelStatus: "", leaveApplicationId: 0 })
            }
        } catch (error) {
            console.log("🚀 ~ file: leaveapprovaltab.jsx:142 ~ useEffect ~ error:", error)
        }
    }, [props.leaveApprovelStatus])

    const updateLeaveData = () => {
        setState({
            ...state,
            leaveId: 0,
            status: "",
            open: false, title: "",
            message: "",
        })
        let updatedApprovalData = approvalData
        updatedApprovalData.splice(leaveIndex, 1, props.leaveApprovalInfo)
        setApprovalData(updatedApprovalData)
        props.updateLnAData({ leaveApprovalInfo: {} })
        setLeaveIndex(null)
        getApprovalDataCount(approvalData);

        // setTimeout(() => {
        //     document.getElementById(props.type + "LNA-ApprovalAdminId").ej2_instances[0].refresh()

        // }, 100)
    }


    const FilterMenu = {
        // type: "Menu",
        type: 'CheckBox',
    };

    const getApprovalDataCount = (data) => {
        if (data) {
            const total = data.length;
            const approved = data.filter((approve) => approve.status === "Approved").length;
            const pending = data.filter((pendings) => pendings.status === "Pending").length;
            const rejected = data.filter((reject) => reject.status === "Rejected").length;
            setApprovalStates({
                total,
                approved,
                pending,
                rejected,
            });
        }
    }

    const setHeaderCss = () => {
        setTimeout(() => {
            const ele = document.getElementsByClassName("e-headercelldiv");
            for (let i in ele) {
                if (!isNaN(parseInt(i, 10))) {
                    let index = parseInt(i, 10);
                    if (ele[index].innerText === ADMIN_MODULE_ACTION) {
                        ele[index].classList.add("grid-center-header");
                    } else {
                        ele[index].classList.add("grid-left-header");
                    }
                }
            }
        }, 500);
    }

    const actionTemplate = (e, Index) => {
        let visibleMenu = [{ text: "View", iconCss: 'e-icons e-page-one' }];

        const onSelect = async (args) => {
            if (args.item.text === "View") {
                handleView(e)
            } else if (args.item.text === "Approve") {
                handleApproved(e)
            } else if (args.item.text === "Reject") {
                handleReject(e)
            }
        };
        if (e.status === "Pending") {
            visibleMenu = [...visibleMenu,
            { text: "Approve", iconCss: 'e-icons e-check' },
            { text: "Reject", iconCss: 'e-icons e-reject' }]
        }
        return (
            <div
                className="col-12"
                style={{
                    textAlign: "center",
                    padding: "0px 20px 0px 0px",
                }}
            >
                <div className="row1">
                    <DropDownButtonComponent
                        items={visibleMenu}
                        select={onSelect}
                        iconCss="e-icons e-more-vertical-2"
                        cssClass="e-caret-hide e-custom e-vertical"
                        iconPosition="Top"
                    ></DropDownButtonComponent>
                </div>
            </div>
        );
    };

    const handleApproved = (e,) => {
        try {
            const { leaveId, } = e
            setState({
                ...state,
                leaveId,
                status: "2",
                open: true, title: "Once you approve the application, It cannot be edited.",
                message: "Are you sure you want to continue?",
            })
            setLeaveIndex(parseInt(e.index))

        } catch (err) {
            console.log("🚀 ~ file: leaveapprovaltab.jsx:198 ~ handleApproved ~ err", err)

        }
    }
    const handleReject = (e,) => {
        setLeaveIndex(parseInt(e.index))
        props.handleApprovalSubmit(props.type + "Submit", parseInt(e.leaveId), true)
        props.updateLnAData({ leaveApplicationId: parseInt(e.leaveId) })
    }
    const handleMultipleCancel = () => {
        setLeavesFlag(false)
        document.getElementById(props.type + "Grid").ej2_instances[0].clearSelection()
    }

    const selectedLeaveIdsFromIndex = () => {
        let selectedRows = document.getElementById(props.type + "Grid").ej2_instances[0].getSelectedRows().map((e) => e.rowIndex)
        let currentViewData = document.getElementById(props.type + "Grid").ej2_instances[0].currentViewData
        let leaveIds = []
        currentViewData.map((e, ind) => {
            if (selectedRows.includes(ind) && e.status === "Pending") {
                leaveIds.push(e.leaveId)
            }
        })
        return leaveIds.join(",")

    }
    const handleMultipleApproved = () => {
        setLeaveIndex(-1)
        setState({
            leaveId: selectedLeaveIdsFromIndex(),
            status: "2",
            open: true, title: "Approve",
            message: "Are you sure you want to approve?",
        })
    }
    const handleMultipleReject = () => {
        setLeaveIndex(-1)
        setState({
            leaveId: state.leaveId,//selectedLeaveIdsFromIndex(),
            status: "3",
            open: true, title: "Reject",
            message: "Are you sure you want to reject?",
        })
    }
    const handleView = (e, Index) => {
        setLeaveIndex(parseInt(e.index))
        props.handleApprovalSubmit(props.type + "Submit", parseInt(e.leaveId), false)
        props.updateLnAData({ from: 'ViewEye', leaveApplicationId: parseInt(e.leaveId) })
    }

    const statusTemplate = (prop) => {
        let tempStatus = prop.finalStatus
        if (prop.ApprovalType === "Cancelled" && tempStatus === "Cancelled") tempStatus = prop.detailedStatus
        return (
            <div>
                <div className={`leave-approval-${tempStatus}`}>
                    {prop.detailedStatus}
                </div>
            </div>
        );
    }

    const reasonTemplate = (prop) => {
        const { reason } = prop;
        return ellipsisTemplate(reason);
    }

    const employeeTemplate = (prop) => {
        const { tmName } = prop;
        return ellipsisTemplate(tmName);
    }

    const roleTemplate = (prop) => {
        const { role } = prop;
        return ellipsisTemplate(role);
    }

    const departmentTemplate = (prop) => {
        const { department } = prop;
        return ellipsisTemplate(department);
    }

    const swipeTypeTemplate = (prop) => {
        const { swipeType } = prop;
        return ellipsisTemplate(swipeType);
    }

    const leaveTypeTemplate = (prop) => {
        const { leaveType } = prop;
        return ellipsisTemplate(leaveType);
    }

    const ellipsisTemplate = (type) => {
        return (
            <div>
                <TooltipComponent position="BottomCenter" className="pointer-cursor" isSticky={false} content={type}>
                    <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', fontSize: ".8rem" }}>{type}</div>
                </TooltipComponent>
            </div>
        );
    }

    const onComplete = (e) => {
        if (e.requestType === 'filtering') {
            let newGridData = document.getElementById(props.type + "Grid").ej2_instances[0].currentViewData
            getApprovalDataCount(newGridData)
            document.getElementById(props.type + "filter").style.border = "1px solid #eb2828"
        }
    }
    const clearFilter = () => {
        document.getElementById(props.type + "Grid").ej2_instances[0].clearFiltering()
        getApprovalDataCount(approvalData)
        document.getElementById(props.type + "filter").style.border = "1px solid #fff"
        dataSourceChanged(`${props.type}Grid`)
    }

    const handleConfirmYes = () => {
        const { status } = state
        const { authUser, type } = props
        props.changeLeaveApprovalStatus({ leaveId: state.leaveId, userId: authUser, status, type, reason: "" })

        dataSourceChanged(`${props.type}Grid`, 500)
        refreshGridFunc();

    }
    const handleConfirmNo = () => {
        setState({
            leaveId: 0,
            status: "",
            open: false, title: "",
            message: "",
        })
        // setState({...state,state.})
        dataSourceChanged(`${props.type}Grid`)
        refreshGridFunc();
    }
    const headerCellInfo = (args) => {
        let checkboxEle = args.node.querySelector('.e-checkbox-wrapper');
        if (checkboxEle) {
            checkboxEle.classList.add('e-hide');
        }

    }
    let styles = `
        #${props.type}Grid.e-grid .e-hide {
            display: none !important;
        }

        #LNA-ApprovalAdminIdNew .e-grid .e-headercontent {
            border-width: 0px !important;
        }
       
        .e-pagerdropdown  {
            display: inline-block;
            height: 32px;
            margin-left: 12px;
            margin-right: 0;
            margin-top: 9px !important;
            overflow: hidden;
            vertical-align: middle;
            width: 90px;
            position: absolute;
            right: 15%;
        }
        .e-pagerconstant  {
          display: inline-block;
          height: 32px;
          margin-left: 12px;
          margin-right: 0px;
          margin-top: 14px !important;
          overflow: hidden;
          vertical-align: middle;
          width: 90px;
          position: absolute;
          right: 10%;
        }
        .e-input-group e-control-wrapper e-alldrop e-ddl e-lib e-keyboard e-valid-input {
          padding-left:40px ! important;
          width:100% ! important;
        }
        .e-grid.e-wrap .e-columnheader .e-filtermenudiv {
            line-height: 0px !important;
        }
       
        .e-grid .e-filtermenudiv::before {
            position:absolute !important;
            top:35px !important ;
            left :88% !important ; 
            padding:0px !important;
                   
        }

        
    `;

    const dataSourceChanged = (pComponentId, time = 100) => {
        try {
            setTimeout(() => {
                if (document.getElementById(pComponentId) !== null) {
                    document.getElementById(pComponentId).ej2_instances[0].refresh();
                }
            }, time);
        } catch (error) {
            console.log(error);
        }
    };

    const onDataBinding = (pComponentId) => {
        // spinner(true)
        try {
            setTimeout(() => {
                document.getElementById(pComponentId).ej2_instances[0].refresh();
            });
        } catch (error) {
            console.log(error);
        }
    };

    const isSixteenEighty = window.innerHeight < 932;
    const isSixteenHundred = window.innerHeight < 782;
    const isFourteenHundred = window.innerHeight < 782 && window.innerWidth < 1441;
    const is1400 = window.innerHeight < 900 && window.innerWidth < 1441;
    const isOneTwentyFiveScale = window.innerHeight < 747;
    const isThirteenHundred = window.innerHeight <= 657;
    const clientHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;
    const pageSize = isThirteenHundred ? 8 : isFourteenHundred ? 10 : isOneTwentyFiveScale ? 9 : isSixteenHundred ? 10 : isSixteenEighty ? 13 : clientHeight > 767 ? 12 : 6;
    const rowHeight = isThirteenHundred ? 40 : isFourteenHundred ? 43 : isOneTwentyFiveScale ? 45 : isSixteenEighty ? 30 : clientHeight > 767 ? 50.1 : 50;

    if (isFourteenHundred || is1400) {
        styles = styles + `
            .e-grid.e-default.e-bothlines .e-headercell, .e-grid.e-default.e-bothlines .e-detailheadercell {
                border-width: 0 0 0 1px;
                padding-left: 4px !important;
            }
    
            .e-grid .e-filtermenudiv::before {
                position: absolute !important;
                top: 35px !important;
                left: ${is1400 ? '85%' : '82%'} !important; // Use 85% if is1400 is true, otherwise use 82%
                padding: 0px !important;
            }
    
            .e-e-headertext {
                padding-left: 5px !important;
            }
        `;
    }
    setHeaderCss();
    const refreshGridFunc = () => {  // NOSONAR
        try {
            const refreshGrid = (eerr) => {
                if (document.getElementById(`${props.type}Grid`) !== null) {
                    document.getElementById(`${props.type}Grid`).ej2_instances[0].refresh();
                }
                console.log(eerr)
            };
            setTimeout(refreshGrid, 1000);
        } catch (err) {
            console.log(err);
        }
    };

    return (
        <>
            {/** Heading */}
            {props.finacialYearData.length > 0 && <div className="approval-list">
                <style>{styles}</style>
                <div className="approval-list-title">{props.mainTitle}</div>

                <div className="approval-list-right-part">
                    {/* <div className="approval-list-element">Financial Year</div>
                    <div className="approval-list-element" id="approvalFY-parent">
                        <ComboBoxComponent
                            id="approvalFY"
                            dataSource={props.finacialYearData}
                            fields={{ text: "Name", value: "Id" }}
                            cssClass="e-outline"
                            data-msg-containerid="approvalFYErr"
                            name="approvalFY"
                            // floatLabelType={false}
                            placeholder="Financial year *"
                            // htmlAttributes={{ maxlength: 100 }}
                            value={props.fyId}
                            change={(e) => onFyYearChange(e)}
                        />
                    </div> */}
                    <div className="approval-list-element approval-list-states">
                        <div className="approval-list-states-element">Total - {props.isLoading ? 0 : approvalStates.total}</div>
                        <div className="approval-list-states-element">Approved - {props.isLoading ? 0 : approvalStates.approved}</div>
                        <div className="approval-list-states-element">Pending - {props.isLoading ? 0 : approvalStates.pending}</div>
                        <div className="approval-list-states-element">Rejected - {props.isLoading ? 0 : approvalStates.rejected}</div>
                    </div>
                    <div className="approval-list-element approval-list-filter" style={{ cursor: "pointer" }} id={props.type + "filter"} onClick={clearFilter}>
                        <div className="approval-list-filter-icon">
                            <FontAwesomeIcon
                                icon={faBarsFilter}
                                style={{ cursor: "pointer", fontSize: 20, color: "#fff" }}
                            />
                        </div>
                        <div>Reset Filters</div>
                    </div>
                </div>
            </div>}

            {/** Grid */}
            {(props.approvalData.length > 0 && !props.isLoading) &&
                <div id="LNA-ApprovalAdminIdNew" style={{ "--approvalPadding": (props.type === "compOffApproval" ? "16px" : "10px"), overflowY: "auto" }}>
                    <GridComponent
                        id={`${props.type}Grid`}
                        allowPaging
                        gridLines="Both"
                        style={{ borderLeft: "0px", borderRight: "0px", borderBottom: "0px", overflowY: "auto" }}
                        dataSource={approvalData}
                        pageSettings={{
                            // pageSizes: NoOfRowsPerPage,
                            pageCount: 5,
                            pageSize: pageSize
                        }}
                        allowTextWrap={true}
                        rowHeight={rowHeight}
                        height={isLeaveIdsSelected ? gridHeight - 58 : gridHeight}
                        width={gridWidth}
                        allowFiltering
                        filterSettings={FilterMenu}
                        actionComplete={onComplete}
                        allowSelection={false}
                        headerCellInfo={headerCellInfo}
                        dataSourceChanged={() => dataSourceChanged(`${props.type}Grid`)}
                        onDataBinding={() => onDataBinding("LNA-ApprovalAdminId")}

                    >
                        <ColumnsDirective>
                            {getGridColumn(props.type).map((item, Index) => {
                                switch (item.colName) {
                                    // case LEAVE_APPROVAL_CHECK_BOX:
                                    //     return (
                                    //         <ColumnDirective
                                    //             key={item.field}
                                    //             // template={checkedBoxTemplate}
                                    //             textAlign={item.align}
                                    //             field={item.field}
                                    //             headerText={item.colName}
                                    //             width={item.width+20}
                                    //
                                    //             type="checkBox"
                                    //         />
                                    //     );
                                    case ADMIN_MODULE_ACTION:
                                        return (
                                            <ColumnDirective
                                                key={item.field}
                                                // template={(e) => actionTemplate(e, Index)}
                                                template={actionTemplate}
                                                textAlign={item.align}
                                                field={item.field}
                                                headerText={item.colName}
                                                width={item.width + 15}
                                                allowFiltering={false}

                                            // filter={false}
                                            />
                                        );
                                    case CANDIDATE_STATUS:

                                        return (
                                            <ColumnDirective
                                                key={item.field}
                                                template={statusTemplate}
                                                textAlign={item.align}
                                                field={item.field}
                                                headerText={item.colName}
                                                width={item.width + 20}
                                            />
                                        );
                                    case REASON:
                                        return (
                                            <ColumnDirective
                                                key={item.field}
                                                template={reasonTemplate}
                                                textAlign={item.align}
                                                field={item.field}
                                                headerText={item.colName}
                                                width={item.width + 20}
                                            />
                                        );
                                    case LEAVE_APPROVAL_EMPLOYEE_NAME:
                                        return (
                                            <ColumnDirective
                                                key={item.field}
                                                template={employeeTemplate}
                                                textAlign={item.align}
                                                field={item.field}
                                                headerText={item.colName}
                                                width={item.width + 20}
                                            />
                                        );
                                    case DEPARTMENT_NAME:
                                        return (
                                            <ColumnDirective
                                                key={item.field}
                                                template={departmentTemplate}
                                                textAlign={item.align}
                                                field={item.field}
                                                headerText={item.colName}
                                                width={item.width + 20}
                                            />
                                        );
                                    case LEAVE_APPROVAL_ROLE:
                                        return (
                                            <ColumnDirective
                                                key={item.field}
                                                template={roleTemplate}
                                                textAlign={item.align}
                                                field={item.field}
                                                headerText={item.colName}
                                                width={item.width + 20}
                                            />
                                        );
                                    case LEAVE_CLUSTER_SWIPE_TYPE:
                                        return (
                                            <ColumnDirective
                                                key={item.field}
                                                template={swipeTypeTemplate}
                                                textAlign={item.align}
                                                field={item.field}
                                                headerText={item.colName}
                                                width={item.width + 20}
                                            />
                                        );
                                    case LEAVE_CLUSTER_LEAVE_TYPE:
                                        return (
                                            <ColumnDirective
                                                key={item.field}
                                                template={leaveTypeTemplate}
                                                textAlign={item.align}
                                                field={item.field}
                                                headerText={item.colName}
                                                width={item.width + 20}
                                            />
                                        );
                                    // case LEAVE_APPROVAL_MODE && props.type == "swipeApproval":
                                    //     return (
                                    //         <ColumnDirective
                                    //             key={item.field}
                                    //             template={this.employeeNameTemplate}
                                    //             textAlign={`${item.align} !important`}
                                    //             field={item.field}
                                    //             headerText={item.colName}
                                    //             width={item.width+20}
                                    //             filter={this.Filter}
                                    //         />
                                    //     );
                                    default:
                                        return (
                                            <ColumnDirective
                                                key={item.field}
                                                textAlign={item.align}
                                                field={item.field}
                                                headerText={item.colName}
                                                width={item.width + 30}
                                            />
                                        );
                                }
                            })}
                        </ColumnsDirective>
                        <Inject services={[Page, Sort, Filter]} />
                    </GridComponent>

                    <ConfirmationPopup
                        headerMessage={state.title}
                        subMessage={state.message}
                        button1Text={"Yes"}
                        button2Text={"No"}
                        button2function={handleConfirmNo}
                        button1function={handleConfirmYes}
                        showButton={{ type: "1" }}
                        open={state.open}
                        handleConfirmClose={handleConfirmNo}
                    />
                    {isLeaveIdsSelected &&
                        <div style={{ display: "flex", justifyContent: "space-between", padding: 10, background: "white", borderBottomColor: "rgb(224, 224, 224)" }}>
                            <div>
                                <button className="leave-approval-back-button" onClick={handleMultipleCancel}>Cancel</button>
                            </div>
                            <div>
                                <button className="leave-approval-approval-button mr-md" onClick={handleMultipleApproved}>Approve</button>
                                <button className="leave-approval-back-button" onClick={handleMultipleReject}>Reject</button>
                            </div>
                        </div>
                    }
                </div>}
            {
                props.isLoading && (
                    <div
                        style={{
                            zIndex: 1
                        }}
                    >
                        <ProgressBar />
                    </div>
                )
            }
            {
                (props.approvalData.length === 0 && !props.isLoading) && (
                    <ErrorTabMessage message={props.fyId ? "No data available." : pleaseSelectFyId} />
                )
            }
        </>
    )
}


const mapStateToProps = ({ meetingmaster, lnareducer, auth }) => {
    const { finacialYearData } = meetingmaster
    const { fyId, leaveApprovalInfo, leaveApprovelStatus, leaveApplicationId } = lnareducer
    const { authUser } = auth
    return { finacialYearData, fyId, authUser, leaveApprovalInfo, leaveApprovelStatus, leaveApplicationId }
}


const mapDispatchToProps = { updateLnAData, changeLeaveApprovalStatus }

export default connect(mapStateToProps, mapDispatchToProps)(LeaveApprovalTab)