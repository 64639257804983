import React from 'react'
import { connect } from 'react-redux';
import {addUpdateOkrDashboardRedux} from '../../redux/actions/okrdashboardaction/okrdashboardaction'
import '../../app/layout/okrratinglayout/okrrating.css'
function DashboardMonths(props) {

    const getClass = (ratingStatus) => {
        switch (ratingStatus) {
            case 'Pending':
                return 'pending'
            // break;
            case 'Completed':
                return 'complete'
            // break;
            case 'Not Open':
                return 'notopen'
            case 'Not Applicable':
                return 'notapplicable'
            // break;
            case 'Locked':
                return 'locked'
            default:
                break;
        }
    }
    const getClassSelected = (ratingStatus) => {
        switch (ratingStatus) {
            case 'Pending':
                return 'pending-selected'
            // break;
            case 'Completed':
                return 'complete-selected'
            // break;
            case 'Not Open':
                return 'notopen'
            case 'Not Applicable':
                return 'notapplicable'
            // break;
            case 'Locked':
                return 'locked'
            default:
                break;
        }
    }
    const changeMonthlyData = (item) => {
        try {
            
                props.addUpdateOkrDashboardRedux({ monthOfRating: item.Id, ratingStatus: item.ratingStatus, dashboardTeamRatingStatus:'updated',selectedMonthName:item.Name, viewCard: item.viewCard })
            
        } catch (error) {
            console.log("?? ~ file: ratingmonthreviewperiod.jsx ~ line 84 ~ changeMonthlyData ~ error", error)
        }
    }
const{monthOfRating} = props
    return (
        <div className='col-lg-12 col-md-12 col-sm-12 col-xs-12 '>
            <div className='row'>
                {props.Months.slice(0, 6).map((item) => {
                    return (
                        <div key={`ratingMnth${item.Id}`} className={`col-lg-2 col-md-2 col-sm-2 col-xs-2 rating-month-border `} onClick={() => changeMonthlyData(item)} style={{ padding: 4, cursor: 'pointer' }}>
                                <div className={`${item.Id === monthOfRating? 'active-months': getClass(item.ratingStatus)}`}>{item.Name}</div>
                            </div>
                    )
                })}
            </div>
            <div className='row'>
                {props.Months.slice(6, 12).map((item, key) => {//NOSONAR
                   return (
                    <div key={`ratingMnth${item.Id}`} className={`col-lg-2 col-md-2 col-sm-2 col-xs-2 rating-month-border `} onClick={() => changeMonthlyData(item)} style={{ padding: 4, cursor: 'pointer' }}>
                            <div className={`${item.Id === monthOfRating? 'active-months': getClass(item.ratingStatus)}`}>{item.Name}</div>
                        </div>
                )
            })}
            </div>
        </div>
    )
}
const mapStateToProps = ({ auth, okrratingreducer, okrreducer, meetingmaster, meetingcategory, okrdashboardreducer }) => {
    const { authUser } = auth;
    const { supervisorUsers, ratingStatusData } = okrratingreducer;
    const { categoryName } = meetingcategory;
    const { monthOfRating } = okrdashboardreducer;
    const { OkrMasters } = okrreducer;
    const { finacialYearData } = meetingmaster;
    
    return {
        authUser, monthOfRating, OkrMasters, finacialYearData, supervisorUsers, categoryName, 
        ratingStatusData
    }
};
export default connect(mapStateToProps, { addUpdateOkrDashboardRedux })(
    DashboardMonths
);
// export default DashboardMonths
