import getEnployeeBasicInformation from "../../services/services";
import { appId } from '../../config/config';

export const syncUserLocal = async (emp_id, authUser, type) => {
    const emp_info = await getEnployeeBasicInformation.getEnployeeBasicInformation(emp_id, authUser);
        if (typeof emp_info !== "undefined" && emp_info.length > 0) {
      var joiningDate = new Date(emp_info[0].JoiningDate);
      joiningDate = joiningDate.getFullYear() + '-' + ('0' + (joiningDate.getMonth() + 1).toString()).slice(-2) + '-' + ('0' + joiningDate.getDate().toString()).slice(-2);
      let data = {};
      data.app_id = appId;
      data.authUser = authUser;
      data.user_first_name = emp_info[0].FirstName;
      data.user_last_name = emp_info[0].LastName;
      data.user_email = emp_info[0].EmailAddress;
      data.emp_code = emp_info[0].EmployeeCode;
      data.emp_id = emp_info[0].EmployeeId;
      data.user_job_title = emp_info[0].SubLayerName;
      data.user_pnl = emp_info[0].Pnl;
      data.user_role = [{ "app_id": appId, "role_id": "60004c44966b4018063d9cb2", "role_name": "All User" }];
      data.user_id = authUser;
      data.job_title = emp_info[0].JobTitle;
      data.service_division = emp_info[0].SDPA;
      data.user_join_date = joiningDate;
      data.user_layer = emp_info[0].LayerName;
      data.user_sub_layer = emp_info[0].SubLayerName;
      data.user_left_date = emp_info[0].LeftDate;
      data.user_status = type === 1 ? "Active" : emp_info[0].employeeStatus;
      data.admenistrative_reporting_to_id= emp_info[0].admenistrativeReportingToId === null ? "" : emp_info[0].admenistrativeReportingToId.toString();
      data.var_window_login_id =  emp_info[0].windowLoginId;
      data.hdUserName=emp_info[0].helpdeskUserName;
      data.hdPassword=emp_info[0].helpdeskUserPass;
      data.hdUserId=emp_info[0].helpdeskUserId;
      data.almRoleName=emp_info[0].almRoleName
      return data;
    }
}