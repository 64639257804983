import React, { Component } from 'react'
import PropTypes from "prop-types";
import Tooltip from '@material-ui/core/Tooltip';
import {
    withStyles
} from "@material-ui/core/styles";
const CustomMaterialTooltip = withStyles({
    tooltip: {
        color: "#000000",
        backgroundColor: "#F2F2F2",
        font: "normal normal normal 12px/16px Circular TT Book",
        maxWidth: 450,
        padding: 16,
        letterSpacing: '0px',
        whiteSpace: 'pre-line'
    },
    arrow: {
        color: "#F2F2F2"
    }
})(Tooltip);
export default class Tooltips extends Component {
    render() {
        const {titleMessage} =this.props;
        return (
            <CustomMaterialTooltip
                title={titleMessage}
                placement="bottom-end"
                arrow
            >
                <span className="tool-tip-hover-efect">
                    {/* <i className="fa fa-question-circle" aria-hidden="true"></i> */}
                    <i className="fa fa-info-circle" aria-hidden="true"></i>
                </span>
            </CustomMaterialTooltip>
        )
    }
}
Tooltips.propTypes = {
    titleMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
Tooltips.defaultProps = {
    titleMessage:''
};