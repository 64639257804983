import React, { useState, useRef } from "react";
import { connect } from 'react-redux';
import { Button, Dialog, DialogTitle } from "@material-ui/core";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import { faTimes } from "@fortawesome/pro-light-svg-icons";
import { TextBoxComponent } from "@syncfusion/ej2-react-inputs";
import ReactHtmlParser from 'react-html-parser';
import { WARNING, pleaseEnterCorrectURLFormat } from '../../assets/constants/constants'
import { getNotify, cracterLimit } from '../../app/other/commonfunction';
import "../../app/layout/okrratinglayout/okrrating.css";
import { FormValidator } from "@syncfusion/ej2-inputs";
import { DialogContent, DialogActions } from "../customdialog/customdialog";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import UploadDocuments from "./uploaddocuments";
import { addEditInterimReviewFeedback } from '../../redux/actions/okrrating/okrratingaction'

const sampleCss = `.e-outline textarea, textarea.e-outline, .e-outline.e-float-input textarea, .e-outline.e-float-input.e-control-wrapper textarea, .e-outline.e-float-input.e-input-group textarea {
  box-sizing: border-box;
  min-height: 100px;
}`

const AddEditInterimReviewModal = (props) => {//NOSONAR

  const key = props.KRData.krName === undefined ? props.KRData.kpi : props.KRData.krName;
  const description = props.KRData.krDetails === undefined ? props.KRData.kpiDescription : props.KRData.krDetails;
  const [remarks, setRemarks] = useState(props.KRData.interimRemarks === undefined ? '' : props.KRData.interimRemarks)
  const [changedData, setChangedData] = useState(false)
  const [link, setLink] = useState(props.KRData.interimReviewLink === undefined ? '' : props.KRData.interimReviewLink)
  const ratingFilesVar = props.KRData.ratingFiles !== '' && props.KRData.ratingFiles !== undefined ? props.KRData.ratingFiles.split(',') : props.KRData.ratingFiles;
  const [ratingFiles, setRatingFiles] = useState(props.KRData.ratingFiles === undefined ? [] : ratingFilesVar)
  const textareaObj = useRef(null);
  const [disableSubmit, setDisableSubmit] = useState(false)


  const onChange = (e, type) => {
    let options = {};
    switch (type) {
      case "Remarks":
        setRemarks(e.value);
        options = {
          rules: { remarkErr: { required: [true, "Please enter remarks *"] } },
        };
        break;
      case "Links":

        setLink(e.value);




        break;
      default:
        break;
    }
    const addFormObject = new FormValidator("#InterimReviewForm", options);
    addFormObject.validate();
  }
  function validURL(str) {
    var pattern = new RegExp('^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return !!pattern.test(str);
  }
  const onCancel = () => {
    props.closeAddEditIntrimFeedbackPopup();
  };
  const onSave = (save) => {

    const options = {
      rules: {
        remarkErr: { required: [true, "Please enter remarks *"] }
      }
    };
    const addFormObject = new FormValidator("#InterimReviewForm", options);
    if (addFormObject.validate()) {
      try {
        if (link !== '') {
          if (!validURL(link)) {
            getNotify(WARNING, pleaseEnterCorrectURLFormat)
            return
          }
        }
        const type = ratingType()
        const { ratingId } = props.KRData
        const data = {
          userId: parseInt(props.authUser, 10),
          empId: props.empId,
          ratingUId: ratingId,
          interimReviewUId: props.type === 'Add' ? 0 : props.KRData.interimReviewUid,
          reviewRemarks: remarks,
          reviewLinks: link,
          reviewType: type,
          reviewFiles: ratingFiles.length > 0 ? ratingFiles.toString() : null,
          isDraft: save === 'save' ? false : true,
          OKRType: props.OKRType,
          isDelete: false
        }
        props.addEditInterimReviewFeedback(data)
        setDisableSubmit(true)

      } catch (error) {
        console.log(error, "error")
      }
    }
  };

  const onInput = (e) => {
    try {
      if (textareaObj !== null && typeof textareaObj !== 'undefined') {
        textareaObj.current.respectiveElement.style.height = "auto";
        textareaObj.current.respectiveElement.style.minHeight = "100px";
        textareaObj.current.respectiveElement.style.height = `${textareaObj.current.respectiveElement.scrollHeight}px`;
        cracterLimit(e, 'interimremarkCount', '1000')
      }
    } catch (error) {
    }
  };

  const header = () => {
    switch (props.ratingTabs) {
      case "self-rating":
        return `${props.type} New Interim Review - Self`;
      case "supervisor-rating":
        return `${props.type} New Interim Review - Supervisor`
      case "thirdparty-rating":
        return `${props.type} New Interim Review - Third Party`
      default:
    }
  }


  const ratingType = () => {
    let type
    switch (props.ratingTabs) {
      case "self-rating":
        type = "self"; break;
      case "supervisor-rating":
        type = "sup"; break;
      case "thirdparty-rating":
        type = "final"; break;
      default:
    }
    return type;
  }

  const selectedFiles = (fileNms) => {
    try {
      setRatingFiles(fileNms);
      setTimeout(() => {
        setChangedData(!changedData)
      })
    } catch (error) { }
  };


  return (
    <div>
      <Dialog open={true} fullWidth maxWidth="sm" onEscapeKeyDown={() => onCancel()}>
        <DialogTitle>
          <span
            className="add-new-self-header"
            style={{
              color: "black",
              marginLeft: -4,
              fontFamily: "Circular Std ",
              fontSize: 18,
            }}
          >
            {header()}
          </span>
          <span style={{ float: 'right', cursor: 'pointer', marginRight: -8 }} onClick={onCancel}><FontAwesomeIcon style={{ color: '#B7B7BB', fontSize: 20 }} icon={faTimes} /></span>

        </DialogTitle>

        <DialogContent style={{ paddingTop: 0 }}>
          <div className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <form id="InterimReviewForm">
              <div className="form-row">
                <div
                  className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12"
                  style={{
                    paddingBottom: 32,
                    paddingRight: 16,
                    paddingLeft: 10,
                  }}
                >
                  <div className="view-kr-modalrating-header-text">
                    {
                      props.OKRType === '3' ? "Leadership Principle" : "Key Result"
                    }

                  </div>
                  <div className="view-kr-modalrating-text">
                    {key}
                  </div>
                </div>
              </div>

              <div className="form-row">
                <div
                  className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12"
                  style={{
                    paddingLeft: 10,
                    paddingRight: 16,
                    paddingBottom: 4,
                  }}
                >
                  <div className="view-kr-modalrating-header-text">
                    Description
                  </div>
                  <div className="view-kr-modalrating-text">
                    {ReactHtmlParser(description)}
                  </div>
                </div>
                <div
                  style={{ paddingLeft: 10 }}
                  id="descripttErr"
                  className="e-input-group e-float-icon-left error-div"
                />
              </div>

              <div className="form-row">
                <div
                  className="col-6 col-lg-6 col-md-6 col-sm-6 pt-xs"
                  style={{ paddingLeft: 0 }}
                >
                  <div
                    className="view-kr-modalremark-header-text"
                    style={{ paddingBottom: 5 }}
                  >
                    Upload Document(s)
                  </div>

                  <div
                    className="upload-document-link-add-interim"
                    style={{ paddingLeft: 10, paddingBottom: 8 }}
                  >
                    <UploadDocuments
                      type={"interim"}
                      ratingId={props.KRData.ratingId}
                      allowmultiple={true}
                      selectedFiles={selectedFiles}
                      fileList={typeof ratingFiles === "string" ? [] : ratingFiles}
                      apiUrl={'tmlcApiUrl'}
                      cols={6}
                      module={"interim"}
                    />
                  </div>
                </div>
                <div
                  // style={{ paddingLeft: 16, paddingTop: 4 ,paddingBottom:16}}
                  className="col-6 col-lg-6 col-md-6 col-sm-6 pt-xs"
                >
                  <div
                    className="view-kr-modalremark-header-text"
                    style={{ paddingBottom: 5 }}
                  >
                    Add Link
                  </div>

                  <FontAwesomeIcon
                    style={{
                      color: "#F94F5E",
                      position: "absolute",
                      margin: "13px 0px 0px 27.2em",
                    }}
                    icon={faLink}
                  />

                  <TextBoxComponent
                    style={{ paddingRight: "3em" }}
                    id="Links"
                    floatLabelType="Auto"
                    placeholder='Insert link here'
                    fields={{}}
                    name="linkErr"
                    data-msg-containerid="linkErr"
                    value={link}
                    cssClass="e-outline"
                    onChange={(e) => onChange(e, "Links")}
                  ></TextBoxComponent>
                  <div id="linkErr" className="error-div" />
                </div>
              </div>
              <div className="form-row">
                <div
                  className="col-12 col-lg-12 col-md-12 col-sm-12 col-xs-12"
                  style={{ paddingTop: 16 }}
                >

                  <div style={{ paddingLeft: 10, paddingRight: 16, }}>
                    <style>{sampleCss}</style>
                    <TextBoxComponent
                      style={{ minHeight: 100 }}
                      id={'Remark'}
                      placeholder={"Remark *"}
                      cssClass="e-outline textarea"
                      floatLabelType="Auto"
                      input={onInput}
                      ref={textareaObj}
                      name="remarkErr"
                      data-msg-containerid="remarkErr"
                      multiline
                      inputProps={{
                        required: true,
                        minLength: 7,
                      }}
                      htmlAttributes={{ maxlength: 1000 }}
                      value={remarks}
                      change={(e) => onChange(e, "Remarks")}
                    />
                  </div>
                  <div id="remarkErr" className="error-div pl-sm" />
                  <div id='interimremarkCount' style={{ float: 'right', fontSize: 12, margin: '0px 15px 8px 0px' }} className="error-div" />
                </div>
              </div>
            </form>
          </div>
        </DialogContent>
        <DialogActions>
          <div style={{ padding: "0px 24px 16px 0px", float: "right" }}>
            <Button
              style={{ width: "124px" }}
              className="cancel-button"
              variant="contained"
              onClick={() => onSave('draft')}
              disabled={disableSubmit}
            >
              Save as draft
            </Button>
            <Button
              style={{ width: "124px" }}
              className="remove-button-shadow"
              color="primary"
              variant="contained"
              onClick={() => onSave('save')}
              disabled={disableSubmit}
            >
              Submit
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </div>
  );

}
const mapStateToProps = ({ okrratingreducer, auth }) => {
  const { authUser } = auth
  const { empId } = okrratingreducer
  return { authUser, empId }
}

export default connect(mapStateToProps, { addEditInterimReviewFeedback })(AddEditInterimReviewModal)
